import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const IncomeBasedRepayment = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Income-Based Repayment</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay2.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Income-Based Repayment
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repayment-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          Other than the standard, graduated, and extended
                          plans, federal student loans also offer four types of
                          income-based repayment (IBR) plans. As the name
                          sounds, these plans calculate your monthly payments
                          based on the amount of income you earn. That way, your
                          monthly payments are tied to your income versus a
                          fixed payment over time. IBR offers a more affordable
                          option to repaying your student loans with lower
                          payments in the beginning that increase over time with
                          your income.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Let&apos;s use an example to walk through each
                          income-based repayment option. Kyle graduated with
                          $30,000 of federal student loans, has 30 days
                          remaining in his{' '}
                          <Link
                            to="/finance-explained/grace-period"
                            target="_blank">
                            grace period
                          </Link>{' '}
                          before repayment begins, has a 5% interest rate on all
                          loans, and earns $50,000 per year in Pennsylvania.
                          There are three things to consider when comparing
                          repayment options:
                        </p>
                        <p className={classes.grayBoxNew}>
                          1. Monthly payment
                          <br />
                          2. Total repayment amount
                          <br />
                          3. Payoff time
                        </p>
                        <p>
                          <strong>Income-Based Repayment (IBR)</strong>
                          <br />
                          Kyle&apos;s payment is set at 10% of his monthly
                          discretionary income and is repaid over 20 years or
                          less - depending on his future income. His monthly
                          payment is recalculated each year as his income
                          changes but limited to the monthly payment under the
                          standard repayment plan, or $318 in this example.
                        </p>
                        <div className={classes.grayBoxNew}>
                          <div className={classes.paymentRow}>
                            <span>Discretionary Income:</span>
                            <span>
                              Annual income minus 150% of the{' '}
                              <a
                                href="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay2-table.png"
                                target="_blank"
                                rel="noopener noreferrer">
                                poverty guideline
                              </a>
                            </span>
                          </div>
                          <p
                            className={classNames(
                              classes.noMargin,
                              classes.guideLine,
                            )}>
                            <i>(see below table for the poverty guideline)</i>
                          </p>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$261 to $315</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>
                              $40,057 (+$1,873 compared to{' '}
                              <Link
                                to="/finance-explained/student-loan-repayment-plans"
                                target="_blank">
                                standard
                              </Link>
                              )
                            </span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>11 years</span>
                          </div>
                        </div>
                        <p>
                          <strong>Income-Contingent Repayment (ICR)</strong>
                          <br />
                          Kyle&apos;s payment is set at the lesser of 20% of his
                          monthly discretionary income or the monthly payment if
                          his loan was repaid over 12 years with a fixed payment
                          and repaid over 25 years or less - depending on his
                          future income. His monthly payment is recalculated
                          each year as his income changes.
                        </p>
                        <div className={classes.grayBoxNew}>
                          <div className={classes.paymentRow}>
                            <span>Discretionary Income:</span>
                            <span>
                              Annual income minus 100% of the{' '}
                              <a
                                href="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay2-table.png"
                                target="_blank"
                                rel="noopener noreferrer">
                                poverty guideline
                              </a>
                            </span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$256 to $249</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>
                              $41,439 (+$3,255 compared to&nbsp;
                              <Link
                                to="/finance-explained/student-loan-repayment-plans"
                                target="_blank">
                                standard
                              </Link>
                              )
                            </span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>13 years</span>
                          </div>
                        </div>
                        <p>
                          <strong>
                            Revised Pay As You Earn (REPAYE) Repayment
                          </strong>
                          <br />
                          Kyle&apos;s payment is set at 10% of his monthly
                          discretionary income and repaid over 20 years or less
                          - depending on his future income.. His monthly payment
                          is reset each year as his income changes with no
                          limit.
                        </p>
                        <div className={classes.grayBoxNew}>
                          <div className={classes.paymentRow}>
                            <span>Discretionary Income:</span>
                            <span>
                              Annual income minus 150% of the{' '}
                              <a
                                href="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay2-table.png"
                                target="_blank"
                                rel="noopener noreferrer">
                                poverty guideline
                              </a>
                            </span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$261 to $315</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>
                              $40,057 (+$1,873 compared to{' '}
                              <Link
                                to="/finance-explained/student-loan-repayment-plans"
                                target="_blank">
                                standard
                              </Link>
                              )
                            </span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>11 years</span>
                          </div>
                        </div>
                        <p>
                          <strong>Pay As You Earn (PAYE) Repayment</strong>
                          <br />
                          Kyle&apos;s payment is set at 10% of his monthly
                          discretionary income and repaid over 20 years or less
                          - depending on his future income. His monthly payment
                          is reset each year as his income changes. The
                          difference between REPAYE is that, under PAYE, his
                          monthly payment is limited to the standard repayment
                          plan, or $318 in this example.
                        </p>
                        <div className={classes.grayBoxNew}>
                          <div className={classes.paymentRow}>
                            <span>Discretionary Income:</span>
                            <span>
                              Annual income minus 150% of the{' '}
                              <a
                                href="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay2-table.png"
                                target="_blank"
                                rel="noopener noreferrer">
                                poverty guideline
                              </a>
                            </span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$261 to $315</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>
                              $40,057 (+$1,873 compared to{' '}
                              <Link
                                to="/finance-explained/student-loan-repayment-plans"
                                target="_blank">
                                standard
                              </Link>
                              )
                            </span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>10 years</span>
                          </div>
                        </div>
                        <p>
                          <strong>
                            <a
                              href="https://www.thebalance.com/federal-poverty-level-definition-guidelines-chart-3305843"
                              target="_blank"
                              rel="noopener noreferrer">
                              Poverty Guidelines
                            </a>
                          </strong>
                        </p>
                        <div
                          className={classNames(
                            classes.pfImage,
                            classes.pfMargin,
                          )}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay2-table.png"
                            alt=""
                          />
                        </div>
                        <p>
                          There&apos;s a repayment option that fits every
                          financial plan. The first step is to educate yourself
                          on those options. Then, you can make a good decision
                          and repay your loans on time while minimizing total{' '}
                          <Link
                            to="/finance-explained/interest"
                            target="_blank">
                            interest
                          </Link>{' '}
                          paid.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
IncomeBasedRepayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncomeBasedRepayment);
