const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  bannerImage: {
    background: '#3676a2',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  bannerCaption: {
    bottom: 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
    WebkitTransform: 'translateY(-50%)',
    MozTransform: 'translateY(-50%)',
    Otransform: 'translateY(-50%)',
    Mstransform: 'translateY(-50%)',
    zIndex: ' 99',
    textAlign: 'center',
    position: 'absolute',
    left: '0',
    right: '0',
    paddingBottom: '30px',
    '@media (max-width: 767px)': {
      padding: '0px 15px',
    },
    '& h2': {
      fontFamily: '"MuseoSans-300"',
      fontSize: '54px',
      fontWeight: '300',
      paddingTop: '2.5vh',
      textTransform: 'inherit',
      lineHeight: '78px',
      color: '#fff',
      margin: '0',
      '@media (max-width: 768px)': {
        fontSize: '40px !important',
        lineHeight: '50px !important',
      },
      '@media (max-width: 500px)': {
        fontSize: '27px !important',
        lineHeight: '40px !important',
      },
    },
    '& h4': {
      lineHeight: '40px',
      fontSize: '30px',
      paddingBottom: '1vh',
      paddingTop: '6vh',
      maxWidth: '850px',
      margin: '0 auto',
      color: '#fff',
      '& span': {
        display: 'block',
        width: '100%',
      },
      '@media (max-width: 1024px)': {
        paddingTop: '3vh',
        fontSize: '25px',

      },
      '@media (max-width: 768px)': {
        fontSize: '21px',
        width: '95%',
        paddingTop: '40px',
      },
      '@media (max-width: 599px)': {
        fontSize: '18px',
        paddingTop: '4vh',
        lineHeight: '30px',
      },
    },
  },
  bannerBlock: {
    height: '50.8vh',
    minHeight: '300px',
    position: 'relative',
    [theme.breakpoints.down( 'md' )]: {
      height: '380px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      height: '380px',
    },
  },
  modulesBlock: {
    '& h2': {
      textAlign: 'center',
      margin: '40px auto 20px',
      display: 'table',
      fontSize: '30px',
      color: '#0069aa',
    },
  },
  tabsSection: {
    margin: '80px 0 0px',
    [theme.breakpoints.down( 'md' )]: {
      margin: '40px 0 0px',
    },
  },
  tabContainer: {
    padding: '20px 0px 30px',
  },
  tabsRow: {
    listStyle: 'none',
    padding: '0',
    width: '100%',
    textAlign: 'left',
    maxWidth: '1100px',
    display: 'inline-block',
    marginBottom: '0',
    [theme.breakpoints.down( 'md' )]: {
      textAlign: 'center',
    },
    '& li': {
      minWidth: '180px',
      height: '40px',
      padding: '0 20px',
      margin: '0 20px 0 0',
      color: '#888888',
      lineHeight: '40px',
      cursor: 'pointer',
      display: 'inline-block',
      textAlign: 'center',
      border: '1px solid #b7b7b7',
      borderBottom: '0',
      fontSize: '20px',
      borderRadius: '15px 15px 0px 0px',
      position: 'relative',
      listStyle: 'none',
      outline: 'none',
      [theme.breakpoints.down( 'md' )]: {
        minWidth: '150px',
        fontSize: '18px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        minWidth: '80px',
        fontSize: '16px',
        marginRight: '5px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        minWidth: '100%',
        marginRight: '0px',
        borderRadius: '0',
        padding: '5px 20px',
        boxSizing: 'border-box',
        display: 'table',
      },
      '&:last-child': {
        marginRight: '0px',
      },
    },
  },

  activeModule: {
    color: '#0069aa !important',
    borderColor: '#231f20 !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      border: '0',
      background: '#fbfdfd',
      left: '0',
      bottom: '-1px',
    },
  },
  subscribeBtn: {
    width: '100%',
    display: 'flex',
    maxWidth: '600px',
    margin: '25px auto 0',
    position: 'relative',
    verticalAlign: 'middle',
    '& input': {
      height: '40px',
      padding: '0 25px',
      verticalAlign: 'middle',
      border: 'none',
      width: '100%',
      outline: '0',
      fontSize: '17px',
      borderRadius: '20px 0 0 20px',
      textAlign: 'center',
      color: '#333',
      fontFamily: '"MuseoSans-300"',
    },
    '& button': {
      borderRadius: '0 20px 20px 0',
      background: '#f89b22',
      color: '#fff',
      maxWidth: '200px',
      fontWeight: '700',
      fontSize: '15px',
      fontFamily: '"MuseoSans-300"',
      height: '40px',
      padding: '0 25px',
      verticalAlign: 'middle',
      border: 'none',
      width: '100%',
      outline: '0',
      lineHeight: 'inherit',
      cursor: 'pointer',
      '&:hover': {
        background: '#f58e09',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: 'auto',
      margin: '25px auto 0px auto',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '90%',
      margin: '25px auto 0px auto',
    },
  },
  tutorialsBlock: {
    textAlign: 'center',
    borderBottom: '1px solid #000',
  },
  tabContent: {
    padding: '15px 50px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '15px 30px',
    },
  },
  searchInput: {
    marginBottom: '15px',
    '& input': {
      position: 'relative',
      zIndex: '2',
      float: 'left',
      width: '100%',
      marginBottom: '0',
      height: '34px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      padding: '6px 12px',
      boxSizing: 'border-box',
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#555',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      fontFamily: '"MuseoSans-300"',
      '&:focus': {
        borderColor: '#66afe9',
        outline: '0',
        webkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
      },
    },
  },
  inputGroup: {
    width: '63%',
    margin: '0 auto',
    padding: '20px 0 40px 0',
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
    },

  },
  searchTextField: {
    width: '100%',
    '& div': {
      height: '100%',
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
  },
  galleryNewsImg: {
    '& img': {
      width: '100%',
      maxHeight: '223px',
      minHeight: '168px',
      objectFit: 'cover',
    },
  },
  galleryCaption: {
    padding: '0 0 7px',
    textAlign: 'left',
    minHeight: '100px',
    [theme.breakpoints.down( 'xs' )]: {
      minHeight: 'auto',
    },
    '& h3': {
      color: '#000',
      fontSize: '20px',
      lineHeight: '24px',
      margin: '12px 0 12px',
      maxHeight: '75px',
      textAlign: 'left',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
      '& a': {
        color: '#000',
        '&:hover': {
          textDecoration: 'underline',
          color: '#f89b23',
        },
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
      },    
    },
    '& p': {
      width: '100%',
      fontSize: '13px',
      margin: '0 0 10px',
    },
  },
  financialQuickLinks: {
    fontSize: '16px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '12px',
    },
    '@media (max-width: 736px)': {
      // fontSize: '12px',     
      // flexDirection: 'column',
      // position: 'fixed',
      // top: '80px',
      // right: '0',
      textAlign: 'center',
      // marginRight: '5px',
      // display: 'none',
    },
    '& p': {
      color: '#337ab7',
      cursor: 'pointer',
      textDecoration: 'underline',
      display: 'inline-block',
      margin: '0px',
      '@media (max-width: 736px)': {
        lineHeight: '20px',
        textDecoration: 'none',
        borderRadius: '100%',
        marginBottom: '2px',
        background: '#337ab7',
        color: '#fff !important',
        width: '20px',
        height: '20px',
        marginRight: '2px',
      },
    },
    '& span': {
      padding: '0 5px',
      '@media (max-width: 736px)': {
        display: 'none',
      },
    },
  },
  cursorNone: {
    color: '#333 !important',
    textDecoration: 'none !important',
    cursor: 'default !important',
    '@media (max-width: 736px)': {
      background: '#b3b3b3 !important',
    },
  },
  financialRow: {
    '& h3': {
      float: 'left',
      padding: '8px 15px 5px',
      margin: '30px 0 8px 0',
      borderLeft: '3px solid #0069aa',
      lineHeight: '18px',
      width: '100%',
      fontWeight: '500',
      color: '#333',
      fontFamily: 'MuseoSans-300',
      fontSize: '24px',
      [theme.breakpoints.down( 'sm' )]: {
        lineHeight: '30px',
        marginTop: '15px',
        width: 'calc(100% - 30px)',
      },
      '& a': {
        color: '#0069aa',
        '&:hover': {
          color: '#f89b23',
          textDecoration: 'underline',
        },
      },
      '& img': {
        width: '100%',
      },
    },
    '& p': {
      whiteSpace: 'pre-line',
      marginLeft: '17px',
      fontSize: '18px',
      margin: '0 0 10px',
      color: '#333',
      [theme.breakpoints.down( 'sm' )]: {
        whiteSpace: 'initial',
      },
      '& a': {
        padding: '0px 0px',
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
          textDecoration: 'underline',
        },
      },
    },
  },
  subscriptionText: {
    width: '100%',
    '& input': {
      height: '40px',
      padding: '0 25px',
      verticalAlign: 'middle',
      border: 'none',
      width: '100%',
      outline: '0',
      fontSize: '17px',
      borderRadius: '20px 0 0 20px',
      textAlign: 'center',
      color: '#333',
      fontFamily: '"MuseoSans-300"',
      backgroundColor: '#fff',
    },
    '& div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
  },
  quickLinksRow: {
    marginBottom: '10px',
    '& h3': {
      color: '#333333',
      float: 'left',
      padding: '8px 15px 5px',
      borderLeft: '3px solid #0069aa',
      lineHeight: '18px',
      width: '100%',
      margin: '20px 0 10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      fontSize: '24px',
      [theme.breakpoints.down( 'sm' )]: {
        lineHeight: '30px',
      },
    },
    '& p': {
      margin: '0 0 0px',
      marginLeft: '17px',
      fontSize: '18px',
      '& a': {
        display: 'block',
        transition: 'all 0.5s ease 0s',
        '&:hover': {
          color: '#f89b23',
          textDecoration: 'underline',
        },
      },
    },
  },
  inputGroupAddon: {
    padding: '6px 12px',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1',
    color: '#555',
    textAlign: 'center',
    backgroundColor: '#eee',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '1%',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    display: 'table-cell',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeft: '0',
  },
  redoPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  buttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#0069aa',
        borderColor: '#0069aa',
        color: '#fff',
        marginRight: '6px',
      },
      '&:hover':{
        borderColor: '#8c8c8c',
        backgroundColor: '#d4d4d4',
        '&:first-child': {
          borderColor: '#204d74',
          backgroundColor: '#204d74',
        }
      },
    },
  },
  noteError: {
    color: 'red',
  },
  tabButtons: {
    marginBottom: '0px',
  },
  bottomText: {
    textAlign: 'left',
    color: '#0069aa',
    fontStyle: 'italic',
    '& p': {
      fontSize: '14px',
      margin: '0px 0px 10px 0px',
    },
  },
  feGraphTabs: {
    '& > div > div': {
      marginBottom: '0px',
      paddingTop: '0px !important',
    },
    '& p': {
      marginBottom: '0px',
      marginLeft: '0px',
    },
    '& div': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  capitalImage: {
    margin: '60px 0px 30px 0px',
    textAlign: 'center',
    '& img': {
      textAlign: 'center',
      cursor: 'pointer',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
      }, 
    },
  },
  iraImage: {
    textAlign: 'center',
    boxSizing: 'border-box',
    '& img': {
      verticalAlign: 'middle',
      margin: '30px 0px',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
      }, 
    },
  },
  galleryImage: {
    '& img': {
      margin: '30px 0px',
    },
  },
  imageMargin: {
    margin: '30px 0px',
  },
  graphInflection: {
    margintop: '20px',
  },
  sourceBottomText: {
    color: '#0069aa !important',
  },
  graphText: {
    width: '30%',
    '& p': {
      marginBottom: '0',
      fontSize: '16px',
      textAlign: 'left',
    },
  },
  blueBox: {
    border: '1px solid #0069aa',
    padding: '5px 10px',
    color: '#0069aa',
    '& p': {
      color: '#0069aa',
    },
  },
  greenBox: {
    border: '1px solid #39b54a',
    padding: '5px 10px',
    margin: '20px 0',
    '& p': {
      color: '#39b54a',
    },
  },
  redBox: {
    color: 'red',
    padding: '5px 10px',
    border: '1px solid red',
    '& p': {
      color: 'red',
    },
  },
  underImage: {
    '& img': {
      margin: '50px 0px',
    },
  },
  longTermImage: {
    '& img': {
      margin: '30px 0px',
      '&:nth-child(2)': {
        marginTop: '50px',
      },
    },
  },
} );

export default styles;
