import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-existing/styles';

const FederalStudentLoans = ( props ) => {
  const { classes, handleDefinationPopup } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>There are two types of student loans - federal and private student loans.</p>
      <p>There are also two types of federal student loans - federal subsidized and federal unsubsidized. Before you start strategizing and planning on how to repay your loans on time or even early after graduation, you need to learn the difference between subsidized and unsubsidized.</p>
      <Typography variant="h3" component="h3" className={ classes.federalStudent }><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Federal Subsidized Student Loan' ) }><b>Federal Subsidized Student Loans</b></span></Typography>
      <ul className={ classes.federalStudentLoans }>
        <li>Issued by the government</li>
        <li>
          The government pays the&nbsp;
          <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest: Student Loan' ) }>interest</span>
&nbsp;while you are in school and in&nbsp;
          <span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Grace Period' ) }>grace period</span>
        </li>
        <li>
          <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Student Loan' ) }>Term of the loan</span>
&nbsp; is typically 10 years (120 months)
        </li>
        <li>
          No interest will&nbsp;
          <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Accrued Interest' ) }>accrue</span>
&nbsp;on this loan until repayment period begins
        </li>
        <li>Repayment begins six months after graduation or if you stop going to school</li>
        <li>Example: if you borrow $10,000 in subsidized loans, you will have to repay $10,000 after the grace period ends and repayment begins</li>
      </ul>
      <Typography variant="h3" component="h3" className={ classes.federalStudent }><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Federal Unsubsidized Student Loan' ) }><b>Federal Unsubsidized Student Loans</b></span></Typography>
      <ul className={ classes.federalStudentLoans }>
        <li>Issued by the government</li>
        <li>
          The government does not pay the&nbsp;
          <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest: Student Loan' ) }>interest</span>
&nbsp;while you are in school and in&nbsp;
          <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Grace Period' ) }>grace period</span>
        </li>
        <li>
          <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Student Loan' ) }>Term of the loan</span>
&nbsp; is typically 10 years (120 months)
        </li>
        <li>
          Interest will&nbsp;
          <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Accrued Interest' ) }>accrue</span>
&nbsp;on this loan while you are in school and in grace period
        </li>
        <li>Repayment begins six months after graduation or if you stop going to school</li>
        <li>Example: if you borrow $10,000 in unsubsidized loans and make no payments during school, your balance will be higher than $10,000 when the grace period ends and repayment begins</li>
      </ul>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>

  );
};

FederalStudentLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,

};

export default withStyles( styles )( FederalStudentLoans );
