import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
// import Picture5 from '../../assets/img/pwi-existing/401k_1_pic.png';
// import Picture6 from '../../assets/img/pwi-existing/401k_2_pic.png';
// import Picture7 from '../../assets/img/pwi-he/Picture1.png';
// import Picture8 from '../../assets/img/pwi-he/Picture2.png';
// import Picture9 from '../../assets/img/pwi-he/Picture3.png';
// import Picture10 from '../../assets/img/pwi-he/Picture4.png';
import CalculationHighereducation from './calculation-higher-education';

const HigherEducationAttending = ( props ) => {
  const { classes, handleDoubleClickType, handleAssestObject,handleUpdatedObject, setPointerEvent } = props;
  const [answerType, setAnswerType] = React.useState( 'No' );
  const [schoolType, setSchoolType] = React.useState( 0 );
  const [educationCheck,setEducationCheck] = React.useState(false);

  useEffect(()=>{
    const inputValues = {...handleAssestObject};
    let education = ["fed_sub_type_of_higher_education","fed_unsub_type_of_higher_education","private_type_of_higher_education"].some((e)=>{
      return handleAssestObject[e] > 0;
      });
      setEducationCheck(education);
      if(handleAssestObject.fed_sub_type_of_higher_education !== undefined ){
        setSchoolType(handleAssestObject.fed_sub_type_of_higher_education);
        setAnswerType('Yes');
      }else {
        inputValues['fed_sub_type_of_higher_education'] = 0;
      }
      handleUpdatedObject(inputValues);

    // eslint-disable-next-line
  },[]);

  const handleClickType = ( type ) => {
    setAnswerType( type );
  };

  const handleSchoolType = ( type ) => {
    const inputValues = {...handleAssestObject};
    setSchoolType( type );
    inputValues.fed_sub_type_of_higher_education = type;
    handleUpdatedObject( inputValues );
  };

  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>Are you still in school?</p>
      <div className={classes.schoolOptions}>
        <span className = { (answerType === 'Yes' || educationCheck) ? classes.active : classes.unactiveBox }  onClick={ () => handleClickType( 'Yes' ) }>Yes</span>
        <span className = { answerType === 'No' ? classes.active : classes.unactiveBox }  onClick={ () => handleClickType( 'No' ) }>No</span>
      </div>
      {
      (schoolType > 0 || educationCheck || answerType === 'Yes') && (
      <div className={classes.mt30}>
        <p>What type of school are you attending?</p>
        <ul className={classes.retirementList}>
          <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 2 ) } onClick={ () => handleSchoolType( 2 ) } className={ ( schoolType === 2 ) ? classes.active : classes.unactiveBox }>
            College
          </li>
          <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 1 ) } onClick={ () => handleSchoolType( 1 ) } className={ ( schoolType === 1 ) ? classes.active : classes.unactiveBox }>
            Career School
          </li>
          <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 3 ) } onClick={ () => handleSchoolType( 3 ) } className={ ( schoolType === 3 ) ? classes.active : classes.unactiveBox }>
            Military School
          </li>
          <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 4 ) } onClick={ () => handleSchoolType( 4 ) } className={ ( schoolType === 4 ) ? classes.active : classes.unactiveBox }>
            Graduate School
          </li>
        </ul>
        <CalculationHighereducation  handleAssestObject={handleAssestObject} setPointerEvent={setPointerEvent} handleUpdatedObject={handleUpdatedObject}/>
      </div>
      )
    }

    </div>

  );
};

HigherEducationAttending.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleDoubleClickType: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( HigherEducationAttending );