import TickMark from '../../assets/img/pwi-fi/tick_mark_blue.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
  },
  fiHeader: {
    position: 'relative',
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #bf4821',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: 'normal',
      marginLeft: '29px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '24px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      width: '100%',
      // maxWidth: '340px',
      maxWidth: '500px',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
       },
      '& > img': {
        height: '50px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          height: '30px',
        },
      },
    },
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    paddingBottom: '1px',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '19px',   
       },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transiction: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '30px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& i': {
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
      '&:disabled': {
        backgroundColor: '#d5d5d5',
        borderColor: '#d5d5d5',
        cursor: 'not-allowed',
        filter: 'alpha(opacity=65)',
        webkitBoxShadow: 'none',
        boxShadow: 'none',
        opacity: '.65',
        color: theme.palette.common.black,
        pointerEvents: 'all',
        '& span': {
          pointerEvents: 'none',
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
      padding: '10px',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      }
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '@media (max-width: 767px)': {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 185px)',
    },
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '16px',
      '@media (max-width: 767px)': {
        fontSize: '14px',
        marginTop: '15px',
      },
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      position: 'relative',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        color: 'gray',
        flexWrap: 'wrap',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&:hover': {
          color: '#eca041',
        },
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  careerSketchTimeline: {
    textAlign: 'center',
    padding: '0 50px',
    '& h3': {
      textAlign: 'left',
      color: '#0069aa',
      fontWeight: 'normal',
      fontSize: '26px',
      maxWidth: '765px',
      margin: '0px auto 0px',
      '@media (max-width: 767px)': {
        fontSize: '20px',
        textAlign: 'center',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      '& li': {
        background: 'transparent',
        padding: '0',
        display: 'inline-block',
        margin: '5px',
        cursor: 'pointer',
        '& img': {
          maxWidth: '45px',
        },
      },
    },
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& img': {
          width: '3.8vh',
          display: 'block',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  AnnualIncomeGrowth: {
    [theme.breakpoints.down( 'xs' )]: {
      position: 'relative',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '30px',
      marginTop: '0',
      [theme.breakpoints.down( 'xs' )]: {
        flexWrap: 'wrap',
      },
      '& p': {
        margin: '0',
        minWidth: '130px',
        maxWidth: '130px',
        lineHeight: '25px',
        [theme.breakpoints.down( 'xs' )]: {
          minWidth: '100%',
          maxWidth: '100%',
        },
        '& span': {
          fontSize: '20px',
          display: 'initial',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          }
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },

  },
  middleAnnual: {
    width: 'calc(100% - 380px)',
    marginTop: '30px',
    '& img': {
      marginLeft: '25px',
      width: 'calc(100% - 25px)',
    },
  },
  tabButtons: {
    paddingTop: '20px',
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      marginRight: '10px',
      color: '#0069aa',
      textTransform: 'inherit',
      minWidth: 'inherit',
      minHeight: 'inherit',
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      '@media (max-width: 767px)': {
        overflowX: 'auto',
        overflowY: 'hidden',
      }
    },
    '& > div > div + span': {
      display: 'none',
    },

  },
  tabsContent: {
    '& img': {
      maxWidth: '100%',
    },
  },
  graphInflection: {
    '& img': {
      maxWidth: '100%',
    },
  },
  dollarIcon: {
    margin: '0 25px 0 auto',
    color: '#666',
    fontSize: '18px',
    '& input': {
      color: '#666',
    },
  },
  cashFlowChart: {
    marginTop: '35px',
    marginBottom: '15px',
  },
  tooltipInfoTop: {
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.42857143',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
      '@media (max-width: 1199px)': { 
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        left: '-10px',
        top: '40%',
        marginTop: '-4px',
        borderRight: '10px solid #337AB7',
        borderTopColor: 'transparent !important',
      }
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    },
  },
  activeTab: {
    position: 'relative',
    top: '1px',
    background: '#fff',
    borderColor: '#000 !important',
  },
  activeMenu: {
    color: '#000 !important',
  },
  AnnualIncomeGrowthMain: {
    marginBottom: '50px',
    '& > div': {
      height: '60px',
      '& p': {
        lineHeight: '27px',
      },
    },
  },
  incomeGrowth: {
    marginTop: '10px',
  },
  annuvalIncomeGrowthGraph: {
    width: '50%',
    margin: '0 25px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      margin: '0 15px',
    },
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
    },
  },
  annuvalIncomeGrowthInput: {
    width: '100px',
    margin: '-10px 25px 0 25px',
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {     
      margin: '60px 0px 0px 0px',
    },
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 230px)',
    overflow: 'auto',
    marginTop: '20px',
    '& > div':{
      marginBottom: '0 !important',
    },
    '& ul': {
      padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '10px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },

  menuBoxNew: {
    background: '#f2f6ed',
    padding: '0 0 2px',
    borderRadius: '15px',
    margin: '5px 0',
    '& h5': {
      background: '#84a84d',
      borderRadius: '15px',
      color: '#fff',
      padding: '5px 10px',
      margin: '0 0 5px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '14px',
    },

  },
  menuBoxNewBg: {
    background: '#e5f0f6',
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    marginTop: '20px',
    marginBottom: '30px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
     padding: '15px',
    },
    '& p': {
      margin: '0',
    },
  },
  yearsList: {
    listStyle: 'none',
    margin: '0px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingLeft: '0px',     
    },
    '& li': {
      backgroundImage: `url(${TickMark})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '11px',
      backgroundPosition: 'left top 8px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 30px 40px',
      fontSize: '21px',
      '&:last-child': {
        paddingBottom: '0px',
      },
      '@media (max-width: 1024px)': {
        flexWrap: 'wrap',
        display: 'inline-block',
        lineHeight: '22px',
      },
      '@media (max-width: 767px)': {
        flexWrap: 'wrap',
        display: 'inline-block',
        lineHeight: '22px',
        fontSize: '16px',
      },
      '& span': {
        marginRight: '6px',
      },
    },
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 20px',
      height: '29px',
      cursor: 'pointer',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-300',
      fontSize: '21px',
      minWidth: '120px',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        padding: '0 10px',
        minWidth: '90px',
      },
    },
  },
  vacationGrayBox: {
    width: '72%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '80%',     
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  borderDivider: {
    width: '100%',
    height: '3px',
    backgroundImage: 'linear-gradient(to right,#7f7f7f 50%,transparent 50%)',
    backgroundSize: '30px 100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  vacationTable: {
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      maxWidth: '688px',
      margin: '0 auto',
      fontSize: '20px',
      width: '100%',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '& tr': {
        borderTop: '1px solid #fff',
        cursor: 'pointer',
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: '500',
          fontFamily: 'MuseoSans-500',
          padding: '8px',
          lineHeight: '1.2',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            textAlign: 'left',
          },
          '& select': {
            border: 'none',
            background: 'transparent',
            fontSize: '18px',
            outline: 'none',
          },
          '&:last-child': {
            textAlign: 'right',
            '@media (max-width: 767px)': {
              minWidth: '110px',
            }
          },
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.2',
          '&:last-child': {
            textAlign: 'right',
          },
          '& b': {
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
          },
          '& i': {
            color: '#000',
          },
        },
      },
    },
  },
  grayRow: {
    backgroundColor: '#f2f2f2',
    '&:hover': {
      background: '#eceaea',
    },
  },
  livingRow: {
    backgroundColor: '#fce4d6',
    '&:hover': {
      backgroundColor: '#d5b6a4',
    },
  },
  otherExpenses: {
    backgroundColor: '#fff2cc',
    '&:hover': {
      backgroundColor: '#e3d6af',
    },
  },
  blueRow: {
    backgroundColor: '#dae3f3',
    borderTop: '2px solid #000 !important',
  },
  leftPadding: {
    paddingLeft: '40px !important',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '20px !important',
    },
  },
  leftSubPadding: {
    paddingLeft: '60px !important',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '30px !important',
    },
  },
  vacationCategories: {
    display: 'flex',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      width: '100%',
      margin: '20px auto 20px',
      fontSize: '20px',
      maxWidth: '500px',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '& tr': {
        '& th': {
          background: '#dae3f3',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
          borderBottom: '3px solid #fff',
          padding: '8px',
          lineHeight: '1.42857143',
          textAlign: 'left',
          '&:last-child': {
            textAlign: 'right',
          },
        },
        '& td': {
          background: '#f2f2f2',
          borderBottom: '2px solid #fff',
          padding: '8px',
          lineHeight: '1.42857143',
          fontFamily: 'MuseoSans-300',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  amountDollar: {
    textAlign: 'right',
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '20px',
      display: 'inline-block',
      width: '85px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
    },
  },
  specialPurchase: {
    paddingBottom: '30px',
    '& input': {
      outline: '0',
      padding: '0 10px',
      lineHeight: '1.42857143',
      color: '#555',
      backgroundColor: '#fff',
      display: 'inline-flex',
      alignItems: 'center',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      verticalAlign: 'middle',
      width: '100%',
      height: '34px',
      fontFamily: 'MuseoSans-300',
      fontSize: '20px',
      maxWidth: '235px',
      marginLeft: '7px',
      '@media (max-width: 767px)': {
        marginLeft: '0px',
        width: 'calc(100% - 20px)',
        fontSize: '16px',
      }
    },
  },
  spanBlock:{
    '@media (max-width: 767px)': {
      display: 'block',
    }
  },
  summaryTable: {
    maxWidth: '450px',
    paddingLeft: '135px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
    },
    '@media (max-width: 959px)': {
      maxWidth: 'initial',
      paddingLeft: '0', 
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      margin: '20px 0px 20px',
      fontSize: '20px',
      width: '100%',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '@media (max-width: 959px)': {
        margin: '0px auto 20px',
        maxWidth: '688px',
        width: '100%',
      },
      '& tr': {
        '& th': {
          background: '#dae3f3',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
          borderBottom: '3px solid #fff',
          padding: '8px',
          lineHeight: '1.42857143',
          textAlign: 'left',
          '&:last-child': {
            textAlign: 'center',
          },
        },
        '& td': {
          background: '#f2f2f2',
          padding: '8px',
          lineHeight: '1.42857143',
          fontFamily: 'MuseoSans-100',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  vacationImage: {
    width: '100%',
    '& a': {
      cursor: 'pointer',
      '& figure': {
        margin: '0px',
        width: '100%',
        position: 'relative',
        lineHeight: '0px',
        '& img': {
          width: '100%',
          opacity: '1',
          backfaceVisibility: 'hidden',
          height: '200px',
          transition: '.5s ease',
          objectFit: 'cover',
        },
      },
      '&:hover': {
        '& img': {
          opacity: '.2',
        },
        '& div': {
          opacity: '1',
        },
      },
    },
  },
  overlayText: {
    opacity: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    MsTransform: 'translate(-50%,-50%)',
    textAlign: 'center',
    transition: '.5s ease',
    width: '100%',
  },
  vacationGallery: {
    margin: '30px 30px',
  },
  overlayPlace: {
    color: '#000',
    fontSize: '28px',
    fontWeight: '300',
    '& p': {
      '& span': {
        display: 'block',
        width: '100%',
      },
    },
  },
  caretIcon: {
    verticalAlign: 'baseline',
    color: '#0069aa',
    margin: '0 5px 0 0',
    fontFamily: 'MuseoSans-300',
    transition: 'unset',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    borderTop: '7px dashed',
    marginTop: '3px',
    borderRight: '7px solid transparent',
    borderLeft: '7px solid transparent',
  },
  displayNone: {
    display: 'none',
  },
  // pendinglistPopup: {
  //   '& > div > div': {
  //     maxWidth: '750px',
  //     borderRadius: '0px',
  //     boxShadow: '0 5px 15px rgba(0,0,0,.5)',
  //     '& > div ': {
  //       padding: '0 0px 0px 0px',
  //       paddingTop: '0px !important',
  //       '& h4': {
  //         color: '#000',
  //         textAlign: 'center',
  //         fontSize: '30px',
  //         marginTop: '10px',
  //         margin: '0',
  //         lineHeight: '1.42857143',
  //         fontWeight: 'normal',
  //         fontFamily: 'MuseoSans-500',
  //         '@media (max-width: 767px)': {
  //           fontSize: '25px',
  //         }
  //       },
  //       '& p': {
  //         textAlign: 'left',
  //         fontSize: '18px',
  //         lineHeight: '1.4',
  //         '@media (max-width: 767px)': {
  //           fontSize: '16px',
  //         }
  //       },
  //     },
  //   },
  // },
  pendinglistPopup: {
    '& > div > div': {
      maxWidth: '100%',
      '& > div': {
        padding: '0 !important',
      },
      '& span':{
        top: '0px',
      }
    },
  },
  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  placeContent: {
    padding: '0 25px',
    marginBottom: '60px',
    '& h3': {
      fontSize: '25px',
      color: '#000',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      }
    },
  },
  floorDetails: {
    display: 'flex',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  placeDescription: {
    width: '50%',
    padding: '0 15px',
    '@media (max-width: 479px)': {
      width: '100%',
      padding: '0px',
    },
    '& p': {
      '& a': {
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
          cursor: 'pointer',
        },
      },
    },
  },
  estimationDetails: {
    width: '50%',
    padding: '0 15px',
    borderLeft: '2px solid #000',
    '@media (max-width: 479px)': {
      width: '100%',
      padding: '0px',
      borderLeft: '0px solid #000',
    },
    '& h3': {
      textAlign: 'center',
    },
  },
  placeHeader: {
    borderBottom: '2px solid #000',
    padding: '15px',
  },
  estimationTable: {
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        textAlign: 'left',
        verticalAlign: 'middle',
        height: '38px',
        padding: '0 8px',
        lineHeight: '1.2',
        border: '1px solid #000',
        width: '60%',
        fontSize: '18px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
        '&:nth-child(1)': {
          borderLeft: '0px',
          textAlign: 'right',
          color: '#000',
          fontSize: '18px',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          }
        },
        '&:nth-child(2)': {
          borderRight: '0px',
          width: '40%',
          textAlign: 'right',
        },
        '& b': {
          fontWeight: '300',
          fontFamily: 'MuseoSans-500',
        },
      },
    },
  },
  clearBtn: {
    margin: '0 15px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '&:hover': {
      background: '#0d97ec',
    },
    '& img': {
      maxWidth: '30px',
    },
  },
  vacationCategoriesTbale: {
    width: '50%',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  },
  tabsButtonNew: {
    paddingTop: '0 !important',
  },
  annuvalText: {
    marginLeft: 'calc(50% + 220px)',
    textDecoration: 'underline',
    fontFamily: 'MuseoSans-700',
    marginBottom: '0 !important',
    [theme.breakpoints.down( 'xs' )]: {
      marginLeft: '0',
      position: 'absolute',
      top: '100px',
    }
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': { 
        paddingLeft: '50px !important',
      }
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  buttonOne: {
    backgroundColor: '#caa336',
    color: '#fff',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    borderWidth: '2px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    borderColor: '#caa336',
    '&:hover': {
      backgroundColor: '#caa336',
      borderColor: '#caa336',
      color: '#fff',
    },
  },
  buttonTwo: {
    backgroundColor: 'transparent',
    color: '#333',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    marginLeft: '5px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid #ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      border: '2px solid #adadad',
    },
  },
  subListMenu: {
    width: '100%',
    listStyle: 'disc !important',
    listStylePosition: 'outside !important',
    paddingLeft: '42px !important',
    '& li': {
      display: 'list-item !important',
      fontSize: '2.0vh !important',
      paddingLeft: '0px !important',
      '@media (max-width: 3500px)': {
        fontSize: '1.58vh !important',
        lineHeight: '2.3vh',
      },
      '@media (max-width: 1400px)': {
        fontSize: '2.0vh !important',
      },
      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '11px !important',
        lineHeight: '16px',
      },
    },
  },
  moduleToolTip: {
    padding: '3px 8px',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'display !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '5px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  timelineGrayBox: {
    width: '80%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  amountFlexDollar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
    '& input': {
      padding: '0px 0px 5px',
    },
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  disablePointerevents: {
    pointerEvents: 'none',
    // paddingRight: '20px',
  },
  screenHght: {
    height: '110vh',
  },
  vacationGraph: {
    '@media (max-width: 767px)': {
      width: '100%',
    },
    '& img': {
      width: '100%',
    },
  },
  quickSave:{
    position: 'absolute',
    right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    [theme.breakpoints.down( 'xs' )]: {
      right: '85px',
    },
    '&:hover':{
      background: '#0d97ec !important',
    },
    '& img':{
      width:'37px',
      position: 'relative',
      top: '5px',
    }
  },
  quickSavePopupBtn: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        borderColor: '#e6e6e6',
        color: '#333',
        marginRight: '6px',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop:'5px',
        fontStyle:'italic'
      }
    }
  },
  documentsMenu: {
    position:'relative'
  },
  dangerText: {
    color: '#bf0000 !important'
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
} );

export default styles;
