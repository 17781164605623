import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';
import * as moduleServices from '../../../calculations/modules-services';
import IncomeStatementPopup from './income-statement-popup';


const IncomeStatement = (props) => {
  const {
    classes, dispalyObj, rangeList,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogName, setOpenDialogName] = useState(false);
  const [openGrossIncomeModel, setOpenGrossIncomeModel] = useState('');
  const [openHomePropertyTaxesExpensesModel, setOpenHomePropertyTaxesExpensesModel] = useState('');
  let [detailedInfo, setDetailedInfo] = useState({});
  let [assumptionsInfo, setAssumptionsInfo] = useState({});



  const displayTitle = (type, name) => {
    let returnText = '';

    switch (name) {
      case 'Spouses Income':
        returnText = "Spouse's Income";
        break;
      case 'Expected Income':
        returnText = 'Career Path';
        break;
      case 'Excepted Income - Secound':
        returnText = 'Career Advancement';
        break;
      case 'Excepted Income - Third':
        returnText = 'Additional Career Advancement';
        break;
      case 'Excepted Income - Fourth':
        returnText = 'Further Career Advancement';
        break;
      default: {
        const data = name.split('-');
        if (data[data.length - 1].trim() === 'Base Income' || data[data.length - 1].trim() === 'Income' || data[data.length - 1].trim() === 'Second'
          || data[data.length - 1].trim() === 'Third' || data[data.length - 1].trim() === 'Fourth') {
          data.pop();
          if (data.length === 1) {
            returnText = data[0].trim();
          } else {
            if (data[data.length - 1].trim() === 'Income' || data[data.length - 1].trim() === 'Second' || data[data.length - 1].trim() === 'Third' || data[data.length - 1].trim() === 'Fourth') {
              data.pop();
            }
            const arr = [];
            data.forEach((value) => {
              arr.push(value);
            });
            returnText = arr.join('-');
          }
        } else {
          returnText = data[0].trim();
        }
        break;
      }
    }
    return returnText;
  };

  let incomeStatementData = moduleServices.incomeStatementCompleteData();

  const showDetailInfo = (selectedYear, selectedSection, selectedColumn) => {
    detailedInfo.condition = 0;

    if ( selectedYear !== undefined && selectedYear !== '' && selectedYear > 0 &&
       selectedSection !== undefined && selectedSection !== '' &&
       selectedColumn !== undefined && selectedColumn !== '') {

      // let incomeTaxesData = moduleServices.inputTaxesCompleteData();
      // let incomeStatementData = moduleServices.incomeStatementCompleteData();
      let assumptionsData = moduleServices.assumptionsCompleteData();

      setAssumptionsInfo(assumptionsData[selectedYear]);

      // let IncomeGrowth = parseFloat(moduleServices.module1Data.incomeGrowth);
      let Inflation = parseFloat(moduleServices.module1Data.inflation);

      detailedInfo = incomeStatementData[selectedYear];
      detailedInfo.selectedColumn = selectedColumn;
      detailedInfo.Year = selectedYear;

      switch (selectedSection) {
        /********************
     * Income Statement *
     ********************/
        /* Gross Income */
        case 'Gross Income':
          switch (selectedColumn) {
            case 'Income Earned during Higher Education':             
              if ( moduleServices.module2Data.income_start_year !== undefined &&  moduleServices.module2Data.income_end_year !== undefined && selectedYear >= moduleServices.module2Data.income_start_year && selectedYear <= moduleServices.module2Data.end_year &&  moduleServices.module2Data.educationName !== undefined &&  moduleServices.module2Data.yearly_income !== undefined && parseInt(moduleServices.module2Data.yearly_income, 10) > 0) {
                if (moduleServices.module2Data.educationName.name === 'Other' &&  moduleServices.module2Data.CustomName !== undefined) {
                  detailedInfo.headingHigherEducation = moduleServices.module2Data.CustomName + ' - Income Earned during Higher Education';
                } else {
                  detailedInfo.headingHigherEducation = moduleServices.module2Data.educationName.name + ' - Income Earned during Higher Education';
                }
                detailedInfo.firstHigherEducationStartYear = moduleServices.module2Data.income_start_year;
                detailedInfo.firstHigherEducationEndYear = moduleServices.module2Data.income_end_year;
                detailedInfo.firstHigherEducationAnnualIncome = parseInt(moduleServices.module2Data.yearly_income, 10);
              } else {
                if ( moduleServices.module6Data.income_start_year !== undefined &&  moduleServices.module6Data.income_end_year !== undefined && selectedYear >= moduleServices.module6Data.income_start_year && selectedYear <= moduleServices.module6Data.income_end_year &&  moduleServices.module6Data.educationName !== undefined &&  moduleServices.module6Data.yearly_income !== undefined && parseInt(moduleServices.module6Data.yearly_income, 10) > 0) {
                  if (moduleServices.module6Data.educationName.name === 'Other' &&  moduleServices.module6Data.CustomName !== undefined) {
                    detailedInfo.headingHigherEducation = moduleServices.module6Data.CustomName + ' - Income Earned during Higher Education';
                  } else {
                    detailedInfo.headingHigherEducation = moduleServices.module6Data.educationName.name + ' - Income Earned during Higher Education';
                  }
                  detailedInfo.firstHigherEducationStartYear = moduleServices.module6Data.income_start_year;
                  detailedInfo.firstHigherEducationEndYear = moduleServices.module6Data.income_end_year;
                  detailedInfo.firstHigherEducationAnnualIncome = parseInt(moduleServices.module6Data.yearly_income, 10);
                } else {
                  if ( moduleServices.module15Data.income_start_year !== undefined &&  moduleServices.module15Data.income_end_year !== undefined && selectedYear >= moduleServices.module15Data.income_start_year && selectedYear <= moduleServices.module15Data.income_end_year &&  moduleServices.module15Data.educationName !== undefined &&  moduleServices.module15Data.yearly_income !== undefined && parseInt(moduleServices.module15Data.yearly_income, 10) > 0) {
                    if (moduleServices.module15Data.educationName.name === 'Other' &&  moduleServices.module15Data.CustomName !== undefined) {
                      detailedInfo.headingHigherEducation = moduleServices.module15Data.CustomName + ' - Income Earned during Higher Education';
                    } else {
                      detailedInfo.headingHigherEducation = moduleServices.module15Data.educationName.name + ' - Income Earned during Higher Education';
                    }

                    detailedInfo.firstHigherEducationStartYear = moduleServices.module15Data.income_start_year;
                    detailedInfo.firstHigherEducationEndYear = moduleServices.module15Data.income_end_year;
                    detailedInfo.firstHigherEducationAnnualIncome = parseInt(moduleServices.module15Data.yearly_income, 10);
                  } else {
                    detailedInfo.headingHigherEducation = 'Income Earned during Higher Education';
                  }
                }
              }
              detailedInfo.previousYearValueCondition = 0;
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear - 1] !== undefined &&  incomeStatementData[selectedYear - 1]['Gross Income']['Income Earned during Higher Education'] !== undefined && incomeStatementData[selectedYear - 1]['Gross Income']['Income Earned during Higher Education'] > 0) {
                detailedInfo.previousYearValue = incomeStatementData[selectedYear - 1]['Gross Income']['Income Earned during Higher Education'];
                detailedInfo.previousYearValueCondition = 1;
              }
              detailedInfo.finalYearValueCondition = 0;
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['Gross Income']['Income Earned during Higher Education'] !== undefined && incomeStatementData[selectedYear]['Gross Income']['Income Earned during Higher Education'] > 0) {
                detailedInfo.finalYearValue = incomeStatementData[selectedYear]['Gross Income']['Income Earned during Higher Education'];
                detailedInfo.finalYearValueCondition = 1;
              } else {
                detailedInfo.finalYearValue = 0;
              }
              if ( moduleServices.module1Data.incomeGrowth !== undefined) {
                detailedInfo.incomeGrowthValue = moduleServices.module1Data.incomeGrowth;
              }
              // setOpenIncomeEarnedDuringHigher( true );
              setOpenDialog(true);
              setOpenDialogName('homePropertyIncomeEarnedDuringHogher');
              break;
            // case 'Income Earned during ' + $rootScope.educationTitle:
            //   if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
            //      incomeStatementData[selectedYear] !== undefined) {

            //     detailedInfo.StartYear = moduleServices.module2Data.income_start_year;
            //     detailedInfo.EndYear = moduleServices.module2Data.end_year;
            //     detailedInfo.YearlyIncome = parseInt(moduleServices.module2Data.yearly_income, 10);

            //     detailedInfo.condition = incomeStatementData[selectedYear]['Income Earned Condition'];
            //     if (detailedInfo.condition === 2) {
            //       detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
            //     }

            //     detailedInfo.Label = 'Income';

            //     // Show Income Earned Portion
            //     // let nb = $('div.modal-backdrop').length;
            //     // if (nb <= 1) {
            //     //   $('.income-sections').show().siblings().hide();
            //     //   $('#showDetailPopup').modal('show');
            //     // }
            //   }
            //   break;
            // 
            case moduleServices.module4Data.occupation_title:
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module4Data.start_year;
                detailedInfo.EndYear = moduleServices.module4Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module4Data.yearly_income);

                detailedInfo.condition = incomeStatementData[selectedYear]['Income Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                } else {
                  detailedInfo.previousIncomeValue = 0;
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module4Data.start_year;
                detailedInfo.EndYear = moduleServices.module4Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module4Data.bonus_or_tips_or_commission, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus Tips Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module4Data.second_start_year !== null ? moduleServices.module4Data.second_start_year : 0;
                detailedInfo.EndYear = moduleServices.module4Data.second_end_year !== null ? moduleServices.module4Data.second_end_year : 0;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module4Data.second_yearly_income) !== null ? parseInt(moduleServices.module4Data.second_yearly_income) : 0;

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                } else {
                  detailedInfo.previousIncomeValue = 0;
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case moduleServices.module5Data.occupation_title + ' - Second':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module5Data.start_year;
                detailedInfo.EndYear = moduleServices.module5Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module5Data.yearly_income, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Income - Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission - Second':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module5Data.start_year;
                detailedInfo.EndYear = moduleServices.module5Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module5Data.bonus_or_tips_or_commission, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus / Tips / Commission - Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income - Second':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module5Data.second_start_year;
                detailedInfo.EndYear = moduleServices.module5Data.second_end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module5Data.second_yearly_income);

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Income - Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            // case 'Income Earned during ' + $rootScope.higherEducationTitle:
            //   if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
            //      incomeStatementData[selectedYear] !== undefined) {

            //     detailedInfo.StartYear = moduleServices.module6Data.income_start_year;
            //     detailedInfo.EndYear = moduleServices.module6Data.income_end_year;
            //     detailedInfo.YearlyIncome = parseInt(moduleServices.module6Data.yearly_income, 10);

            //     detailedInfo.condition = incomeStatementData[selectedYear]['Income Earned Second Condition'];
            //     if (detailedInfo.condition === 2) {
            //       detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
            //     }

            //     detailedInfo.Label = 'Income';

            //     // Show Income Earned Portion
            //     // let nb = $('div.modal-backdrop').length;
            //     // if (nb <= 1) {
            //     //   $('.income-section').show().siblings().hide();
            //     //   $('#showDetailPopup').modal('show');
            //     // }
            //   }
            //   break;

            case moduleServices.module8Data.occupation_title + ' - Third':

              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module8Data.start_year;
                detailedInfo.EndYear = moduleServices.module8Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module8Data.yearly_income, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Income Second Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission - Third':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module8Data.start_year;
                detailedInfo.EndYear = moduleServices.module8Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module8Data.bonus_or_tips_or_commission, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus / Tips / Commission - Third Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case moduleServices.module17Data.occupation_title + ' - Fourth':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module17Data.start_year;
                detailedInfo.EndYear = moduleServices.module17Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module17Data.yearly_income, 10);

                detailedInfo.condition = incomeStatementData[selectedYear][moduleServices.module17Data.occupation_title + ' - Fourth Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Bonus / Tips / Commission - Fourth':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module8Data.start_year;
                detailedInfo.EndYear = moduleServices.module8Data.end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module17Data.bonus_or_tips_or_commission, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Bonus / Tips / Commission - Fourth Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Bonus / Tips / Commission';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income - Third':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module8Data.second_start_year;
                detailedInfo.EndYear = moduleServices.module8Data.second_end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module8Data.second_yearly_income, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Income - Third Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Supplementary Income - Fourth':

              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.StartYear = moduleServices.module17Data.second_start_year;
                detailedInfo.EndYear = moduleServices.module17Data.second_end_year;
                detailedInfo.YearlyIncome = parseInt(moduleServices.module17Data.second_yearly_income, 10);

                detailedInfo.condition = incomeStatementData[selectedYear]['Supplementary Income - Fourth Condition'];
                if (detailedInfo.condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                }

                detailedInfo.Label = 'Supplementary Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Spouses Income':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {

                detailedInfo.condition = 0;
                let condition = incomeStatementData[selectedYear]['Spouses Income Condition'];
                let condition2 = incomeStatementData[selectedYear]['Spouses Income Second Condition'];
                if (condition === 1 || condition === 2) {
                  detailedInfo.condition = 1;
                  detailedInfo.StartYear = moduleServices.module10Data.start_year;
                  detailedInfo.EndYear = moduleServices.module10Data.end_year;
                  detailedInfo.YearlyIncome = moduleServices.module10Data.totalPreTaxExpectedIncome;

                  if (condition === 2) {
                    detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                    detailedInfo.condition = 2;
                  }
                } else {
                  if (condition2 === 1 || condition2 === 2) {
                    detailedInfo.condition = 1;
                    detailedInfo.StartYear = moduleServices.module10Data.start_year;
                    detailedInfo.EndYear = moduleServices.module10Data.end_year;
                    detailedInfo.YearlyIncome = moduleServices.module10Data.totalMorePreTaxExpectedIncome;

                    if (condition2 === 2) {
                      detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Gross Income'][selectedColumn];
                      detailedInfo.condition = 2;
                    }
                  } else {
                    detailedInfo.StartYear = moduleServices.module10Data.start_year;
                    detailedInfo.EndYear = moduleServices.module10Data.end_year;
                    detailedInfo.YearlyIncome = moduleServices.module10Data.totalPreTaxExpectedIncome;
                  }
                }

                detailedInfo.Label = 'Spouse\'s Income';

                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('income-section');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Net Rental Income':
              let invPropertyRoiCompleteData = moduleServices.invPropertyRoiCompleteData();
              if ( invPropertyRoiCompleteData !== undefined && invPropertyRoiCompleteData !== '' &&
                 invPropertyRoiCompleteData['Data1'][selectedYear] !== undefined) {

                detailedInfo = invPropertyRoiCompleteData['Data1'][selectedYear];
                detailedInfo.Year = selectedYear;
                // let finalValueCondition = 0;
                if (invPropertyRoiCompleteData['Data1'][selectedYear]['Net Income'] !== 0) {
                  detailedInfo.finalValueCondition = 1;
                }
                // setOpenGrossIncomePopup( true );

              }
              setOpenGrossIncomeModel('net-rental-income');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Rental Income':

              let incomeStatementCompleteData = moduleServices.incomeStatementCompleteData();

              //let invPropertyRoiCompleteData = moduleServices.invPropertyRoiCompleteData();
              let incRio = moduleServices.invPropertyRoiCompleteData();
              if ( incRio !== undefined && incRio !== '' &&  incRio['Buying Expenses'] !== undefined) {
                detailedInfo.purchaseYear = incRio['Buying Expenses']['Purchase Year'];
                detailedInfo.sellYear = incRio['Buying Expenses']['Sell Year'];
              }
              if ( moduleServices.module14Data.yearly_rental_income !== undefined && moduleServices.module14Data.yearly_rental_income !== '') {
                detailedInfo.annualRentalIncome = parseInt(moduleServices.module14Data.yearly_rental_income);
              } else {
                detailedInfo.annualRentalIncome = 0;
              }
              if ( moduleServices.module14Data.vacancy_rate_in_percentage !== undefined && moduleServices.module14Data.vacancy_rate_in_percentage !== '') {
                detailedInfo.vacancyRate = moduleServices.module14Data.vacancy_rate_in_percentage;
              } else {
                detailedInfo.vacancyRate = 0;
              }

              //Current year Annual Rental income values
              if ( incomeStatementCompleteData !== undefined && incomeStatementCompleteData !== '' &&  incomeStatementCompleteData[selectedYear] !== undefined && incomeStatementCompleteData[selectedYear] !== '') {
                detailedInfo.annualRentalIncomeCurrent = incomeStatementCompleteData[selectedYear]['Gross Income']['Rental Income'];
              } else {
                detailedInfo.annualRentalIncomeCurrent = 0;
              }
              detailedInfo.purchaseYearCondition = 0;
              if (selectedYear <= detailedInfo.purchaseYear) {
                detailedInfo.annualRentalIncomePrevious = 0;
                detailedInfo.incomeGrowthStarts = 0;
              } else {
                detailedInfo.purchaseYearCondition = 1;
                //Previous year Annual Rental income values     
                if ( incomeStatementCompleteData !== undefined && incomeStatementCompleteData !== '' &&  incomeStatementCompleteData[selectedYear - 1] !== undefined && incomeStatementCompleteData[selectedYear - 1] !== '') {
                  detailedInfo.annualRentalIncomePrevious = incomeStatementCompleteData[selectedYear - 1]['Gross Income']['Rental Income'];
                } else {
                  detailedInfo.annualRentalIncomePrevious = 0;
                }
                //Annual Income Growth Value
                if ( moduleServices.module14Data.annual_income_growth_rate !== undefined && moduleServices.module14Data.annual_income_growth_rate !== '') {
                  detailedInfo.incomeGrowthStarts = moduleServices.module14Data.annual_income_growth_rate;
                } else {
                  detailedInfo.incomeGrowthStarts = 0;
                }
              }
              //Final value
              detailedInfo.annualRentalIncomeFinalCondition = 0;
              detailedInfo.annualRentalIncomeFinal = 0;
              if ( incomeStatementCompleteData !== undefined && incomeStatementCompleteData !== '' &&  incomeStatementCompleteData[selectedYear] !== undefined && incomeStatementCompleteData[selectedYear] !== '' && incomeStatementCompleteData[selectedYear]['Gross Income']['Rental Income'] !== 0) {
                detailedInfo.annualRentalIncomeFinal = incomeStatementCompleteData[selectedYear]['Gross Income']['Rental Income'];
                detailedInfo.annualRentalIncomeFinalCondition = 1;
              } else {
                detailedInfo.annualRentalIncomeFinal = 0;
              }
              // Show Net Rental Income Portion

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('rental-income');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Mortgage Interest, Loan Points & Origination Fees, & Depreciation':
              // let mortgage1Data = moduleServices.mortgage1CompleteData();
              let mortgage2Data = moduleServices.mortgage2CompleteData();
              let REIData = moduleServices.invPropertyRoiCompleteData();
              // let incomeStatementData = moduleServices.incomeStatementCompleteData();
              let taxStatementData = moduleServices.taxStatementCompleteData();
              let mortgageInterestValue = 0;
              let REIInterestValue = 0;
              //let reiPropertyMortgageInterestValueCondition = 0;

              if ( mortgage2Data !== undefined && mortgage2Data !== '' &&  mortgage2Data['Mortage2'][selectedYear] !== undefined &&  mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] !== undefined && mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] !== '') {
                detailedInfo.mortgageBalanceInYearValue = mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] / 12;
              }
              else {
                detailedInfo.mortgageBalanceInYearValue = 0;
              }

              if ( mortgage2Data !== undefined && mortgage2Data !== '' &&  mortgage2Data['Annual Interest Rate'] !== undefined && mortgage2Data['Annual Interest Rate'] !== '') {
                detailedInfo.mortgageInterestRate = mortgage2Data['Annual Interest Rate'];
              }

              if ( mortgage2Data !== undefined && mortgage2Data !== '' &&  mortgage2Data['Summation Data'][selectedYear] !== undefined &&  mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'] !== '') {
                mortgageInterestValue = mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'];
              }
              if ( moduleServices.module14Data.year_of_purchase !== undefined && selectedYear === moduleServices.module14Data.year_of_purchase &&  REIData !== undefined && REIData !== '' &&  REIData['Closing Cost'] !== undefined && REIData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== '') {
                REIInterestValue = REIData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
              } else {
                REIInterestValue = 0;
              }

              detailedInfo.mortgageInterestExpenseValue = mortgageInterestValue + REIInterestValue;
              detailedInfo.mortgageInterestExpenseCondtion = 0;
              if (detailedInfo.mortgageInterestExpenseValue !== 0) {
                detailedInfo.mortgageInterestExpenseCondtion = 1;
              }

              //Loan Points Values
              // if(  moduleServices.module14Data !== undefined && moduleServices.module14Data.year_of_purchase !== '' && selectedYear === parseFloat(moduleServices.module14Data.year_of_purchase-1) && moduleServices.module14Data.mortage !== '' ){
              //     detailedInfo.reiPropertyMortgage = moduleServices.module14Data.mortage;
              // }
              // else{
              //     detailedInfo.reiPropertyMortgage = 0;
              // }
              // if(  moduleServices.module14Data !== undefined && moduleServices.module14Data.year_of_purchase !== '' && selectedYear === parseFloat(moduleServices.module14Data.year_of_purchase-1) && moduleServices.module14Data.points_of_mortgage !== '' ){
              //     detailedInfo.reiPropertyPoints = moduleServices.module14Data.points_of_mortgage;
              // }
              // else{
              //     detailedInfo.reiPropertyPoints = 0;
              // }
              // if(  moduleServices.module14Data !== undefined && moduleServices.module14Data.year_of_purchase !== '' && selectedYear === parseFloat(moduleServices.module14Data.year_of_purchase-1) &&  REIData !== undefined && REIData !== '' &&  REIData['Closing Cost'] !== undefined &&  REIData['Closing Cost']['Loan Acquisition Points'] !== undefined &&  REIData['Closing Cost']['Origination Points'] !== undefined ){
              //     detailedInfo.pointsPaidForrei = REIData['Closing Cost']['Loan Acquisition Points'] + REIData['Closing Cost']['Origination Points'];
              // }
              // else{
              //     detailedInfo.pointsPaidForrei = 0;
              // }
              // //Origination Fees
              // if(  moduleServices.module14Data !== undefined && moduleServices.module14Data.year_of_purchase !== '' && selectedYear === parseFloat(moduleServices.module14Data.year_of_purchase-1) &&  REIData !== undefined && REIData !== '' &&  REIData['Closing Cost'] !== undefined && REIData['Closing Cost']['Origination Points'] !== '' ){
              //     detailedInfo.originationPoints = REIData['Closing Cost']['Origination Points'];
              // }
              // else{
              //     detailedInfo.originationPoints = 0;
              // }

              // detailedInfo.pointsPaidForreiCondition = 0;
              // if( detailedInfo.pointsPaidForrei !== 0 ){
              //     detailedInfo.pointsPaidForreiCondition = 1;
              // }
              detailedInfo.reiPropertyMortgagePopCondition = 0;
              let totalMortageInterestLoanPointsVal = 0;
              if ( moduleServices.module14Data !== undefined
                && moduleServices.module14Data.year_of_purchase !== ''
                && selectedYear === moduleServices.module14Data.year_of_purchase) {
                detailedInfo.reiPropertyMortgagePopCondition = 1;
                detailedInfo.reiPropertyMortgagePop = moduleServices.module14Data.mortage;
                detailedInfo.reiLoanPointsPop = moduleServices.module14Data.points_of_mortgage;
                detailedInfo.reiOriginationFeesPop = moduleServices.module14Data.origination_fees;
                if ( REIData !== undefined && REIData !== '' &&  REIData['Closing Cost'] !== undefined) {
                  if ( REIData['Closing Cost']['Loan Acquisition Points'] !== undefined) {
                    totalMortageInterestLoanPointsVal += REIData['Closing Cost']['Loan Acquisition Points'];
                  }
                  if ( REIData['Closing Cost']['Origination Points'] !== undefined) {
                    totalMortageInterestLoanPointsVal += REIData['Closing Cost']['Origination Points'];
                  }
                }
              }
              detailedInfo.loanPointsAndOrgFeePop = totalMortageInterestLoanPointsVal;


              //Depreciation Values
              if ( moduleServices.module14Data !== undefined
                && moduleServices.module14Data.year_of_purchase !== ''
                && selectedYear >= moduleServices.module14Data.year_of_purchase
                &&  REIData !== undefined && REIData !== ''
                &&  REIData['Real Estate Asset Value'] !== undefined
                && REIData['Real Estate Asset Value'] !== '') {
                detailedInfo.reiPropertyValue = REIData['Real Estate Asset Value'];
              }
              else {
                detailedInfo.reiPropertyValue = 0;
              }
              if ( moduleServices.module14Data !== undefined
                && moduleServices.module14Data.year_of_purchase !== ''
                && selectedYear >= moduleServices.module14Data.year_of_purchase) {
                detailedInfo.usefulValue = 27.5;
              }
              else {
                detailedInfo.usefulValue = 0;
              }
              detailedInfo.annualDepreciationDeductionCondition = 0;
              if (detailedInfo.usefulValue > 0) {
                detailedInfo.annualDepreciationDeductionValue = detailedInfo.reiPropertyValue / detailedInfo.usefulValue;
              }
              else {
                detailedInfo.annualDepreciationDeductionValue = 0;
              }

              if (detailedInfo.usefulValue > 0) {
                detailedInfo.annualDepreciationDeductionCondition = 1;
              }
              //Deduction calculation values
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined
                &&  incomeStatementData[selectedYear]['Gross Income']['Rental Income'] !== undefined
                && incomeStatementData[selectedYear]['Gross Income']['Rental Income'] !== '') {
                detailedInfo.maximumDeductionValue = incomeStatementData[selectedYear]['Gross Income']['Rental Income'];
              }
              else {
                detailedInfo.maximumDeductionValue = 0;
              }
              detailedInfo.annualDepreciationDeductionTotalValue = detailedInfo.annualDepreciationDeductionValue + detailedInfo.pointsPaidForrei + detailedInfo.mortgageInterestExpenseValue;

              detailedInfo.passiveLossCarriedValue = detailedInfo.annualDepreciationDeductionTotalValue - detailedInfo.maximumDeductionValue;
              detailedInfo.passiveLossCarriedCondition = 0;
              if (detailedInfo.passiveLossCarriedValue !== 0) {
                detailedInfo.passiveLossCarriedCondition = 1;
              }

              if ( taxStatementData !== undefined &&  taxStatementData[selectedYear] !== undefined
                &&  taxStatementData[selectedYear]['REI Property'] !== undefined
                &&  taxStatementData[selectedYear]['REI Property']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation'] !== undefined) {
                detailedInfo.MortgageInterestLoanPointsPop = taxStatementData[selectedYear]['REI Property']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation'];
              } else {
                detailedInfo.MortgageInterestLoanPointsPop = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('points-for-mortgage');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            default:
              break;
          }
          break;
        //Real Estate Expenses

        case 'Real Estate Expenses':
          switch (selectedColumn) {
            //Rental Operating Expenses     
            case 'Rental Operating Expenses':
              let incomeStatementCompleteData = moduleServices.incomeStatementCompleteData();
              let REIData = moduleServices.invPropertyRoiCompleteData();

              if ( REIData !== undefined && REIData !== '' &&  REIData['Data1'] !== undefined && REIData['Data1'] !== '' &&  REIData['Data1'][selectedYear] !== undefined && REIData['Data1'][selectedYear] !== '') {
                detailedInfo.managementFee = REIData['Data1'][selectedYear]['Management Fees'];
                detailedInfo.homeOwnersInsurance = REIData['Data1'][selectedYear]['Houseowners Insurance'];
                detailedInfo.propertyMaintenance = REIData['Data1'][selectedYear]['Property Maintenance'];
                detailedInfo.associationFees = REIData['Data1'][selectedYear]['Association Fees'];
                detailedInfo.utilities = REIData['Data1'][selectedYear]['Utilities'];
              } else {
                detailedInfo.managementFee = 0;
                detailedInfo.homeOwnersInsurance = 0;
                detailedInfo.propertyMaintenance = 0;
                detailedInfo.associationFees = 0;
                detailedInfo.utilities = 0;
              }
              detailedInfo.realEstateExpensesFinalValueCondition = 0;
              if ( incomeStatementCompleteData !== undefined && incomeStatementCompleteData !== '' &&  incomeStatementCompleteData[selectedYear] !== undefined && incomeStatementCompleteData[selectedYear] !== '' && incomeStatementCompleteData[selectedYear]['livingExpenses']['Rental Operating Expenses'] !== 0) {
                detailedInfo.realEstateExpensesFinalValue = incomeStatementCompleteData[selectedYear]['livingExpenses']['Rental Operating Expenses'];
                detailedInfo.realEstateExpensesFinalValueCondition = 1;
              } else {
                detailedInfo.realEstateExpensesFinalValue = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('rental-operating-expenses');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Home Property Expenses':
              let BuyingAHomeData = moduleServices.buyingAHomeCompleteData();
              let existingHomeData = moduleServices.existingHomeCompleteData();
              //let incomeStatementCompleteData = moduleServices.incomeStatementCompleteData();
              //First Section
              if ( BuyingAHomeData !== undefined && BuyingAHomeData !== '' &&  BuyingAHomeData['Data1'][selectedYear] !== undefined && BuyingAHomeData['Data1'][selectedYear] !== '') {
                detailedInfo.privateMortgageInsurance = BuyingAHomeData['Data1'][selectedYear]['Private Mortgage Insurance'];
                detailedInfo.homeOwnersInsurance = BuyingAHomeData['Data1'][selectedYear]['Houseowners Insurance'];
                detailedInfo.propertyMaintenance = BuyingAHomeData['Data1'][selectedYear]['Property Maintenance'];
                detailedInfo.associationFees = BuyingAHomeData['Data1'][selectedYear]['Association Fees'];
                detailedInfo.utilities = BuyingAHomeData['Data1'][selectedYear]['Utilities'];
              } else {
                detailedInfo.privateMortgageInsurance = 0;
                detailedInfo.homeOwnersInsurance = 0;
                detailedInfo.propertyMaintenance = 0;
                detailedInfo.associationFees = 0;
                detailedInfo.utilities = 0;
              }
              detailedInfo.totalHomeOperatingExpenses = -(detailedInfo.privateMortgageInsurance +
                detailedInfo.homeOwnersInsurance +
                detailedInfo.propertyMaintenance +
                detailedInfo.associationFees +
                detailedInfo.utilities);
              detailedInfo.totalHomeOperatingExpensesCondition = 0;
              if (detailedInfo.totalHomeOperatingExpenses !== 0) {
                detailedInfo.totalHomeOperatingExpensesCondition = 1;
              }
              //First section Completed

              //Second section starts
              if ( existingHomeData !== undefined && existingHomeData !== '' &&  existingHomeData['Data1'][selectedYear] !== undefined && existingHomeData['Data1'][selectedYear] !== '') {
                detailedInfo.privateMortgageInsurance2 = existingHomeData['Data1'][selectedYear]['Private Mortgage Insurance'];
                detailedInfo.homeOwnersInsurance2 = existingHomeData['Data1'][selectedYear]['Houseowners Insurance'];
                detailedInfo.propertyMaintenance2 = existingHomeData['Data1'][selectedYear]['Maintenance'];
                detailedInfo.associationFees2 = existingHomeData['Data1'][selectedYear]['Association Fees'];
                detailedInfo.utilities2 = existingHomeData['Data1'][selectedYear]['Utilities'];
              } else {
                detailedInfo.privateMortgageInsurance2 = 0;
                detailedInfo.homeOwnersInsurance2 = 0;
                detailedInfo.propertyMaintenance2 = 0;
                detailedInfo.associationFees2 = 0;
                detailedInfo.utilities2 = 0;
              }

              detailedInfo.totalHomeOperatingExpenses2 = -(detailedInfo.privateMortgageInsurance2 +
                detailedInfo.homeOwnersInsurance2 +
                detailedInfo.propertyMaintenance2 +
                detailedInfo.associationFees2 +
                detailedInfo.utilities2);
              detailedInfo.totalHomeOperatingExpensesCondition2 = 0;
              if (detailedInfo.totalHomeOperatingExpenses2 !== 0) {
                detailedInfo.totalHomeOperatingExpensesCondition2 = 1;
              }
              //Second section completed

              detailedInfo.homePropertyExpensesFinalValueCondition = 0;
              if ( incomeStatementCompleteData !== undefined && incomeStatementCompleteData !== '' &&  incomeStatementCompleteData[selectedYear] !== undefined && incomeStatementCompleteData[selectedYear] !== '' && incomeStatementCompleteData[selectedYear]['livingExpenses']['Home Property Expenses'] !== 0) {
                detailedInfo.homepropertyExpensesFinalValue = incomeStatementCompleteData[selectedYear]['livingExpenses']['Home Property Expenses'];
                detailedInfo.homePropertyExpensesFinalValueCondition = 1;
              } else {
                detailedInfo.homepropertyExpensesFinalValue = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('home-property-expenses-new');

              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            /*case 'Home Property Taxes':
                
                let nb = $('div.modal-backdrop').length;
                if(nb <= 1){
                    $('.home-property-expenses-new').show().siblings().hide();
                $('#    showDetailPopup').modal('show');
                    }
                break;*/
            case 'Home Property Taxes':
              // let BuyingAHomeData = moduleServices.buyingAHomeCompleteData();
              //First Section
              //let existingHomeData = moduleServices.existingHomeCompleteData();
              //financial Start year
              if ( moduleServices.module1Data.start_year !== undefined) {
                detailedInfo.financialYear = moduleServices.module1Data.start_year;
              }
              //Existing home value
              if ( moduleServices.module18Data.existigHomeCurrentValue !== undefined && moduleServices.module18Data.existigHomeCurrentValue !== '') {
                detailedInfo.existingHomeValueStartYear = parseInt(moduleServices.module18Data.existigHomeCurrentValue, 10);
              } else {
                detailedInfo.existingHomeValueStartYear = 0;
              }

              //Annual Property Taxes
              if ( moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs !== undefined && moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs !== '') {
                detailedInfo.annualPropertyTaxes = moduleServices.module18Data.existingHomeMonthlyPropertyTaxesCalcs;
              } else {
                detailedInfo.annualPropertyTaxes = 0;
              }
              //Property Tax Rate
              detailedInfo.propertyTaxRate = (detailedInfo.annualPropertyTaxes / detailedInfo.existingHomeValueStartYear) * 100;

              if ( existingHomeData !== undefined && existingHomeData !== '' &&  existingHomeData['Real Estate'][selectedYear] !== undefined && existingHomeData['Real Estate'][selectedYear] !== '') {
                detailedInfo.existingHomeValueInYear = existingHomeData['Real Estate'][selectedYear]['Asset Value'];
              } else {
                detailedInfo.existingHomeValueInYear = 0;
              }
              //Property tax rate second
              if (detailedInfo.existingHomeValueInYear > 0) {
                detailedInfo.propertyTaxRateSecond = (detailedInfo.annualPropertyTaxes / detailedInfo.existingHomeValueStartYear) * 100;
              } else {
                detailedInfo.propertyTaxRateSecond = 0;
              }
              //Existing Home Property Taxes value
              detailedInfo.existingHomePropertyTaxesCondition = 0;
              if ( existingHomeData !== undefined && existingHomeData !== '' &&  existingHomeData['Real Estate'][selectedYear] !== undefined && existingHomeData['Real Estate'][selectedYear] !== '' && existingHomeData['Real Estate'][selectedYear]['Property Tax'] !== 0) {
                detailedInfo.existingHomePropertyTaxes = -existingHomeData['Real Estate'][selectedYear]['Property Tax'];
                detailedInfo.existingHomePropertyTaxesCondition = 1;
              } else {
                detailedInfo.existingHomePropertyTaxes = 0;
              }

              //Second Section
              if ( BuyingAHomeData !== undefined && BuyingAHomeData !== '' &&  BuyingAHomeData['Real Estate Asset Value'] !== undefined && BuyingAHomeData['Real Estate Asset Value'] !== '') {
                detailedInfo.BuyingAHomeVal = BuyingAHomeData['Real Estate Asset Value'];
              }
              detailedInfo.BuyingAHomePuchaseYear = moduleServices.module13Data.year_of_purchase;
              detailedInfo.propertyTax = parseFloat(moduleServices.module13Data.propertyTaxes);
              detailedInfo.propertyTaxesCalcs = moduleServices.module13Data.propertyTaxesCalcs;
              detailedInfo.inflationVal = moduleServices.module1Data.inflation;
              detailedInfo.homePropertyTaxesPreviousValueCondition = 0;
              if ( BuyingAHomeData !== undefined && BuyingAHomeData !== ''
                &&  BuyingAHomeData['Real Estate'][selectedYear] !== undefined
                && existingHomeData['Real Estate'][selectedYear] !== '') {
                detailedInfo.homePropertyTaxesPreviousValue = BuyingAHomeData['Real Estate'][selectedYear]['Asset Value'];
                detailedInfo.homePropertyTaxesPreviousValueCondition = 1;
              }
              else {
                detailedInfo.homePropertyTaxesPreviousValue = 0;
              }
              detailedInfo.buyingaHomePropertyTaxesCondition = 0;
              if ( BuyingAHomeData !== undefined && BuyingAHomeData !== '' &&  BuyingAHomeData['Real Estate'][selectedYear] !== undefined && BuyingAHomeData['Real Estate'][selectedYear] !== '' && BuyingAHomeData['Real Estate'][selectedYear]['Property Tax'] !== 0) {
                detailedInfo.buyingaHomePropertyTaxes = -BuyingAHomeData['Real Estate'][selectedYear]['Property Tax'];
                detailedInfo.buyingaHomePropertyTaxesCondition = 1;
              } else {
                detailedInfo.buyingaHomePropertyTaxes = 0;
              }


              //Final Value
              detailedInfo.homePropertyTaxesFinalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Home Property Taxes'] !== 0) {
                detailedInfo.homePropertyTaxesFinalValueCondition = 1;
              }

              // setOpenHomePropertyTaxesExpensesPopup(true);
              setOpenHomePropertyTaxesExpensesModel('home-property-taxes-expenses');
              setOpenDialog(true);
              setOpenDialogName('homePropertyTaxesExpenses');
              break;
            case 'Rental Property Taxes':
              
              let ReiData = moduleServices.invPropertyRoiCompleteData();
              if ( ReiData !== undefined && ReiData !== '' &&  ReiData['Real Estate Asset Value'] !== undefined) {
                detailedInfo.propertyValue = ReiData['Real Estate Asset Value'];
              }
              if ( ReiData !== undefined && ReiData !== '' &&  ReiData['Buying Expenses'] !== undefined &&
              ReiData['Buying Expenses'] !== '' &&  ReiData['Buying Expenses']['Purchase Year'] !== undefined) {
                detailedInfo.purchaseYear = ReiData['Buying Expenses']['Purchase Year'];
              }
              if ( moduleServices.module14Data.propertytaxesValue !== undefined) {
                detailedInfo.property_tax_rate = moduleServices.module14Data.propertytaxesValue;
              }
              if ( moduleServices.module14Data.realEstatePropertyTaxesCalcs !== undefined) {
                detailedInfo.annula_property_tax = moduleServices.module14Data.realEstatePropertyTaxesCalcs;
              }
              if ( ReiData !== undefined && ReiData !== '' &&  ReiData['Real Estate'] !== undefined && ReiData['Real Estate'] !== '' &&
              ReiData['Real Estate'][selectedYear] !== undefined && ReiData['Real Estate'][selectedYear] !== '' &&  ReiData['Real Estate'][selectedYear]['Asset Value'] !== undefined) {
                detailedInfo.propertyTaxes = ReiData['Real Estate'][selectedYear]['Asset Value'];
              }
              detailedInfo.rentalPropertyTaxesCondition = 0;              
              if ( ReiData !== undefined && ReiData !== '' &&  ReiData['Real Estate'] !== undefined && ReiData['Real Estate'] !== '' &&
              ReiData['Real Estate'][selectedYear] !== undefined && ReiData['Real Estate'][selectedYear]['Property Tax'] !== undefined && ReiData['Real Estate'][selectedYear]['Property Tax'] !== 0) {
                detailedInfo.rentalPropertyTaxes = -ReiData['Real Estate'][selectedYear]['Property Tax'];
                detailedInfo.rentalPropertyTaxesCondition = 1;                
              } else {
                detailedInfo.rentalPropertyTaxes = 0
              }

              // setOpenHomePropertyTaxesExpensesPopup(true);
              setOpenHomePropertyTaxesExpensesModel('rental-property-taxes');
              setOpenDialog(true);
              setOpenDialogName('homePropertyTaxesExpenses');
              break;
            default:
              break;
          }
          break;
        /* End of Gross Income */

        /* Taxes */
        case 'Taxes':
          switch (selectedColumn) {
            case 'Total Taxes':
              let taxStatementData = moduleServices.taxStatementCompleteData();
              let incomeStatementData = moduleServices.incomeStatementCompleteData();
              if ( taxStatementData !== undefined && taxStatementData !== '' &&
                 taxStatementData[selectedYear] !== undefined) {
                detailedInfo.totalTaxesBeforeCredits = taxStatementData[selectedYear]['Total Taxes'];
                detailedInfo.totalTaxCredits = taxStatementData[selectedYear]['Total Tax Credits'];
                detailedInfo.totalTaxRealEstateValues = taxStatementData[selectedYear]['Total Investment Property Sale'];
              } else {
                detailedInfo.totalTaxesBeforeCredits = 0;
                detailedInfo.totalTaxCredits = 0;
                detailedInfo.totalTaxRealEstateValues = 0;
              }
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined) {
                detailedInfo.totalTaxFinalValue = incomeStatementData[selectedYear]['Total Taxes'];
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('total-taxes');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;


            default:
              break;
          }
          break;
        /* End of Taxes */
        /* Income After Taxes */

        case 'Income After Taxes':

          // setOpenGrossIncomePopup( true );
          setOpenGrossIncomeModel('income-after-taxes');
          setOpenDialog(true);
          setOpenDialogName('showDetailPopup');
          break;
        /* End of Income After Taxes */

        case 'LivingExpenses':
          switch (selectedColumn) {
            case 'Higher Education Tuition and Fees':
              detailedInfo.CollegeName = 'Higher Education';

              detailedInfo.condition = 0;
              let condition = 0;
              let condition2 = 0;
              let condition3 = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value1 Condition'] !== undefined && incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value1 Condition'] !== 0) {
                condition = incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value1 Condition'];
              }
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value2 Condition'] !== undefined && incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value2 Condition'] !== 0) {
                condition2 = incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value2 Condition'];
              }
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value3 Condition'] !== undefined && incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value3 Condition'] !== 0) {
                condition3 = incomeStatementData[selectedYear]['Higher Education Tuition and Fees Value3 Condition'];
              }

              if (condition === 1 || condition === 2) {
                detailedInfo.condition = 1;
                detailedInfo.StartYear = moduleServices.module2Data.start_year;
                detailedInfo.EndYear = moduleServices.module2Data.graduation_year;
                detailedInfo.Tuition = parseInt(moduleServices.module2Data.tuition);

                if ((moduleServices.module2Data.educationName && moduleServices.module2Data.educationName.name === 'Other') &&  moduleServices.module2Data.CustomName !== undefined) {
                  detailedInfo.CollegeName = moduleServices.module2Data.CustomName;
                } else {
                  detailedInfo.CollegeName = moduleServices.module2Data.higher_education_name;
                }

                if (condition === 2) {
                  detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Higher Education Tuition and Fees Value1'];
                  detailedInfo.condition = 2;
                }
              } else {
                if (condition2 === 1 || condition2 === 2) {
                  detailedInfo.condition = 1;
                  detailedInfo.StartYear = moduleServices.module6Data.start_year;
                  detailedInfo.EndYear = moduleServices.module6Data.graduation_year;
                  detailedInfo.Tuition = parseInt(moduleServices.module6Data.tuition);

                  if (moduleServices.module6Data.educationName.name === 'Other' &&  moduleServices.module6Data.CustomName !== undefined) {
                    detailedInfo.CollegeName = moduleServices.module6Data.CustomName;
                  } else {
                    detailedInfo.CollegeName = moduleServices.module6Data.higher_education_name;
                  }

                  if (condition2 === 2) {
                    detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Higher Education Tuition and Fees Value2'];
                    detailedInfo.condition = 2;
                  }
                } else {
                  if (condition3 === 1 || condition3 === 2) {
                    detailedInfo.condition = 1;
                    detailedInfo.StartYear = moduleServices.module15Data.start_year;
                    detailedInfo.EndYear = moduleServices.module15Data.graduation_year;
                    detailedInfo.Tuition = parseInt(moduleServices.module15Data.tuition);

                    if (moduleServices.module15Data.educationName.name === 'Other' &&  moduleServices.module15Data.CustomName !== undefined) {
                      detailedInfo.CollegeName = moduleServices.module15Data.CustomName;
                    } else {
                      detailedInfo.CollegeName = moduleServices.module15Data.higher_education_name;
                    }
                    if (condition3 === 2) {
                      detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['Higher Education Tuition and Fees Value3'];
                      detailedInfo.condition = 2;
                    }
                  }
                  else {
                    detailedInfo.StartYear = moduleServices.module10Data.start_year;
                    detailedInfo.EndYear = moduleServices.module10Data.end_year;
                    detailedInfo.YearlyIncome = moduleServices.module10Data.totalPreTaxExpectedIncome;
                  }
                }
              }

              // setEducationTuitionPopup( true );

              setOpenDialog(true);
              setOpenDialogName('educationTuitionPopup');
              break;
            case 'Higher Education Room & Board':
              detailedInfo.CollegeName = 'Higher Education';
              let expenseCalculationsCompletedData = moduleServices.expenseCalculationsCompleteData();
              let expenseCalculationsB7 = 0;
              let expenseCalculationsB8 = 0;
              if ( moduleServices.module2Data.on_campus_start_year !== undefined) {
                expenseCalculationsB7 = moduleServices.module2Data.on_campus_start_year;
              }
              if (expenseCalculationsB7 !== '' &&  moduleServices.module2Data.off_campus_start_year !== undefined && moduleServices.module2Data.off_campus_start_year !== '') {
                expenseCalculationsB8 = (parseInt(moduleServices.module2Data.off_campus_start_year, 10) - 1);
              } else {
                if (expenseCalculationsB7 !== '' &&  moduleServices.module2Data.graduation_year !== undefined) {
                  expenseCalculationsB8 = moduleServices.module2Data.graduation_year;
                }
              }

              let expenseCalculationsB90 = 0;
              let expenseCalculationsB91 = 0;
              if ( moduleServices.module6Data.start_year !== undefined) {
                expenseCalculationsB90 = moduleServices.module6Data.start_year;
              }

              if (expenseCalculationsB90 !== '' &&  moduleServices.module6Data.moreStartYear !== undefined && moduleServices.module6Data.moreStartYear !== '') {
                expenseCalculationsB91 = parseInt(moduleServices.module6Data.start_year, 10) - 1;
              } else {
                if (expenseCalculationsB90 !== '' &&  moduleServices.module6Data.graduation_year !== undefined) {
                  expenseCalculationsB91 = moduleServices.module6Data.graduation_year;
                }
              }
              let expenseCalculationsB152 = 0;
              let expenseCalculationsB153 = 0;
              if ( moduleServices.module17Data.start_year !== undefined) {
                expenseCalculationsB152 = moduleServices.module17Data.start_year;
              }
              if (expenseCalculationsB152 !== '' &&  moduleServices.module15Data.graduation_year !== undefined && moduleServices.module15Data.graduation_year !== '') {
                expenseCalculationsB153 = parseInt(moduleServices.module15Data.graduation_year, 10) - 1;
              } else {
                if (expenseCalculationsB152 !== '' &&  moduleServices.module15Data.graduation_year !== undefined) {
                  expenseCalculationsB153 = moduleServices.module15Data.graduation_year;
                }
              }

              if (selectedYear >= expenseCalculationsB7 && selectedYear <= expenseCalculationsB8) {
                detailedInfo.StartYear = moduleServices.module2Data.on_campus_start_year;
                detailedInfo.EndYear = expenseCalculationsB8;
                detailedInfo.RoomBoard = expenseCalculationsCompletedData['Higher Education - On-Campus Housing'][detailedInfo.StartYear]['Yearly']['Rent'];
                if (moduleServices.module2Data.educationName.name === 'Other' &&  moduleServices.module2Data.CustomName !== undefined) {
                  detailedInfo.CollegeName = moduleServices.module2Data.CustomName;
                } else {
                  detailedInfo.CollegeName = moduleServices.module2Data.higher_education_name;
                }

              }
              else {
                if (selectedYear >= expenseCalculationsB90 && selectedYear <= expenseCalculationsB91) {
                  detailedInfo.StartYear = moduleServices.module6Data.start_year;
                  detailedInfo.EndYear = expenseCalculationsB91;
                  detailedInfo.RoomBoard = expenseCalculationsCompletedData['More Higher Education - On-Campus Housing'][detailedInfo.StartYear]['Yearly']['Rent'];
                  if (moduleServices.module6Data.educationName.name === 'Other' &&  moduleServices.module6Data.CustomName !== undefined) {
                    detailedInfo.CollegeName = moduleServices.module6Data.CustomName;
                  } else {
                    detailedInfo.CollegeName = moduleServices.module6Data.name_of_college;
                  }
                }
                else {
                  if (selectedYear >= expenseCalculationsB152 && selectedYear <= expenseCalculationsB153) {
                    detailedInfo.StartYear = moduleServices.module15Data.start_year;
                    detailedInfo.EndYear = expenseCalculationsB153;
                    detailedInfo.RoomBoard = expenseCalculationsCompletedData['New Higher Education - On-Campus Housing'][detailedInfo.StartYear]['Yearly']['Rent'];
                    if (moduleServices.module15Data.educationName !== undefined && moduleServices.module15Data.educationName.name === 'Other' &&  moduleServices.module15Data.CustomName !== undefined) {
                      detailedInfo.CollegeName = moduleServices.module15Data.CustomName;
                    } else {
                      detailedInfo.CollegeName = moduleServices.module15Data.name_of_college;
                    }
                  }
                  else {
                    detailedInfo.StartYear = '';
                    detailedInfo.EndYear = '';
                    detailedInfo.RoomBoard = '';
                  }
                }
              }
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['Higher Education Room & Board Condition'] !== undefined && incomeStatementData[selectedYear]['Higher Education Room & Board Condition'] !== 0) {
                detailedInfo.condition = incomeStatementData[selectedYear]['Higher Education Room & Board Condition'];
              }
              if (detailedInfo.condition === 2) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Higher Education Room & Board'];
              }
              /*if(detailedInfo.condition === 1 || detailedInfo.condition === 2) {
                  detailedInfo.CollegeName = moduleServices.module2Data.higher_education_name;
              }*/
              detailedInfo.finalValueCondtion = 0;
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['livingExpenses']['Higher Education Room & Board'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Higher Education Room & Board'] !== 0) {
                detailedInfo.finalValueCondtion = 1;
              }

              // setRoomandboardpopup( true );

              setOpenDialog(true);
              setOpenDialogName('roomandboardpopup');
              break;
            case 'Off Campus Food':
              let expenseCalculationsData = moduleServices.expenseCalculationsCompleteData();
              if (selectedYear >= moduleServices.module2Data.on_campus_start_year && selectedYear <= (parseInt(moduleServices.module2Data.off_campus_start_year) - 1)) {
                detailedInfo.StartYear = moduleServices.module2Data.on_campus_start_year;
                detailedInfo.EndYear = moduleServices.module2Data.off_campus_start_year - 1;
                detailedInfo.Food = expenseCalculationsData['Higher Education - On-Campus Housing'][detailedInfo.StartYear]['Yearly']['Food'];

              }
              else {
                if (selectedYear >= moduleServices.module6Data.start_year && selectedYear <= parseInt(moduleServices.module6Data.end_year)) {
                  detailedInfo.StartYear = moduleServices.module2Data.on_campus_start_year;
                  detailedInfo.EndYear = parseInt(moduleServices.module6Data.end_year);
                  detailedInfo.Food = expenseCalculationsData['More Higher Education - On-Campus Housing'][detailedInfo.StartYear]['Yearly']['Food'];

                }
                else {
                  if (moduleServices.module17Data.start_year !== '' && selectedYear >= moduleServices.module17Data.start_year && selectedYear <= parseInt(moduleServices.module17Data.end_year)) {
                    detailedInfo.StartYear = moduleServices.module17Data.start_year;
                    detailedInfo.EndYear = parseInt(moduleServices.module17Data.end_year);
                    // detailedInfo.Food = expenseCalculationsData['New Higher Education - On-Campus Housing'][detailedInfo.StartYear]['Yearly']['Food'] !== undefined
                    // console.log('event03',detailedInfo.StartYear,detailedInfo.EndYear);
                  }
                  else {
                    detailedInfo.StartYear = '';
                    detailedInfo.EndYear = '';
                  }
                }
              }
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['Off Campus Food Condition'] !== undefined && incomeStatementData[selectedYear]['Off Campus Food Condition'] !== 0) {
                detailedInfo.condition = incomeStatementData[selectedYear] && incomeStatementData[selectedYear]['Off Campus Food Condition'];
              }
              if (detailedInfo.condition === 2) {
                detailedInfo.previousIncomeValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Off Campus Food'];
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('off-campus-food');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;

            case 'Rent':
              detailedInfo.Label = 'Rent Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Rent'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Rent'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Rent'] / 12;
              }
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Rent'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Rent Condition'] !== 0) {
                detailedInfo.condition = incomeStatementData[selectedYear]['Rent Condition'];
              }
              if (detailedInfo.condition === 2) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Rent'] / 12;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Rent'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Rent'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }

              detailedInfo.marriageValueCondition = 0;
              let multiplierValue = 1;
              let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.rent_multiplier !== undefined && moduleServices.module10Data.rent_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.rent_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Rent'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }

                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Rent'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Rent'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }
              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Utilities':
              detailedInfo.Label = 'Utilities Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Utilities'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Utilities'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Utilities'] / 12;
                detailedInfo.condition = incomeStatementData[selectedYear]['Utilities Condition'];
              }

              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Utilities'] / 12;
              } else {
                detailedInfo.previousValue = 0;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Utilities'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Utilities'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }

              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              //let multiplierValue = 1;
              //let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.utilities_multiplier !== undefined && moduleServices.module10Data.utilities_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.utilities_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Utilities'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }
                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Utilities'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Utilities'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }

              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Food':
              detailedInfo.Label = 'Food Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Food'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Food'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Food'] / 12;
                detailedInfo.condition = incomeStatementData[selectedYear]['Food Condition'];
              }

              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Food'] / 12;
              } else {
                detailedInfo.previousValue = 0;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Food'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Food'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }

              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              //let multiplierValue = 1;
              //let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.food_multiplier !== undefined && moduleServices.module10Data.food_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.food_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Food'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }

                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Food'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Food'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }
              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Clothing':
              detailedInfo.Label = 'Clothing & Personal Care Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Clothing'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Clothing'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Clothing'] / 12;
                detailedInfo.condition = incomeStatementData[selectedYear]['Clothing Condition'];
              }

              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Clothing'] / 12;
              } else {
                detailedInfo.previousValue = 0;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Clothing'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Clothing'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }
              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              // let multiplierValue = 1;
              // let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.clothing_multiplier !== undefined && moduleServices.module10Data.clothing_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.clothing_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Clothing'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }
                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Clothing'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Clothing'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }
              }
              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Entertainment':
              detailedInfo.Label = 'Entertainment Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Entertainment'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Entertainment'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Entertainment'] / 12;
                detailedInfo.condition = incomeStatementData[selectedYear]['Entertainment Condition'];
              }

              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Entertainment'] / 12;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Entertainment'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Entertainment'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }
              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              // let multiplierValue = 1;
              // let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.entertainment_multiplier !== undefined && moduleServices.module10Data.entertainment_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.entertainment_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Entertainment'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }
                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Entertainment'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  marriageYearCondition = 1;
                  detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Entertainment'] / 12;
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }

              }
              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Technology':
              detailedInfo.Label = 'Technology Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Technology'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Technology'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Technology'] / 12;

                detailedInfo.condition = incomeStatementData[selectedYear]['Technology Condition'];
              }
              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Technology'] / 12;
              } else {
                detailedInfo.previousValue = 0;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Technology'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Technology'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }
              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              // let multiplierValue = 1;
              // let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.technology_multiplier !== undefined && moduleServices.module10Data.technology_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.technology_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Technology'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }
                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Technology'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Technology'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }
              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Transportation':
              detailedInfo.Label = 'Transportation Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Transportation'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Transportation'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Transportation'] / 12;

                detailedInfo.condition = incomeStatementData[selectedYear]['Transportation Condition'];
              }
              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Transportation'] / 12;
              } else {
                detailedInfo.previousValue = 0;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Transportation'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Transportation'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }
              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              // let multiplierValue = 1;
              // let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.transportation_multiplier !== undefined && moduleServices.module10Data.transportation_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.transportation_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Transportation'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }
                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Transportation'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Transportation'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }
              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Car Payment':
              let depreciationScheduleCompleteData = moduleServices.depreciationScheduleCompleteData();
              detailedInfo.finalCarPaymentValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Car Payment'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Car Payment'] !== 0) {
                detailedInfo.finalValueCarPayment = incomeStatementData[selectedYear]['livingExpenses']['Car Payment'];
                detailedInfo.finalCarPaymentValueCondition = 1;
              } else {
                detailedInfo.finalValueCarPayment = 0;
              }
              //Lease car1
              detailedInfo.carPaymentLease1Condition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Car Payment'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Car Payment'] !== 0) {
                detailedInfo.carPaymentLease1 = depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Car Payment'] / 12;
                detailedInfo.carPaymentLease1Condition = 1;
              } else {
                detailedInfo.carPaymentLease1 = 0;
              }
              //Lease car2
              detailedInfo.carPaymentLease2Condition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Car Payment'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Car Payment'] !== 0) {
                detailedInfo.carPaymentLease2 = depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Car Payment'] / 12;
                detailedInfo.carPaymentLease2Condition = 1;
              } else {
                detailedInfo.carPaymentLease2 = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('living-expenses-portion-car-payment');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Car Maintenance':
              //let depreciationScheduleCompleteData = moduleServices.depreciationScheduleCompleteData();
              detailedInfo.finalCarMaintenanceValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Car Maintenance'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Car Maintenance'] !== 0) {
                detailedInfo.finalCarMaintenanceValueCondition = 1;
                detailedInfo.finalCarMaintenanceValue = incomeStatementData[selectedYear]['livingExpenses']['Car Maintenance'];
              } else {
                detailedInfo.finalCarMaintenanceValue = 0;
              }

              //Lease car1 Parameters
              //Previous Year maintenance
              detailedInfo.carMaintenanceLease1FinalCondition = 0;
              detailedInfo.carMaintenanceLease1PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Maintenance'] !== 0) {
                detailedInfo.carMaintenanceLease1Previous = depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Maintenance'] / 12;
                detailedInfo.carMaintenanceLease1PreviousCondition = 1;
              } else {
                detailedInfo.carMaintenanceLease1Previous = 0;
              }
              //Inflation
              if ( moduleServices.module1Data.inflation !== undefined && moduleServices.module1Data.inflation !== '') {
                detailedInfo.inflationVal = moduleServices.module1Data.inflation;
              } else {
                detailedInfo.inflationVal = 0;
              }
              //Conditions for Previous year and Inflation
              detailedInfo.previousYearValuesCondition = 0;
              if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year === selectedYear) {
                detailedInfo.previousYearValuesCondition = 1;
              }
              //Current Year maintenance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Maintenance'] !== '') {
                detailedInfo.carMaintenanceLease1Current = depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Maintenance'] / 12;
                detailedInfo.carMaintenanceLease1Final = -depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Maintenance'];
                detailedInfo.carMaintenanceLease1FinalCondition = 1;
              } else {
                detailedInfo.carMaintenanceLease1Final = 0;
                detailedInfo.carMaintenanceLease1Current = 0;
              }

              //Lease car2 Parameters
              //Previous Year maintenance
              detailedInfo.carMaintenanceLease2FinalCondition = 0;
              detailedInfo.carMaintenanceLease2PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Maintenance'] !== 0) {
                detailedInfo.carMaintenanceLease2Previous = depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Maintenance'] / 12;
                detailedInfo.carMaintenanceLease2PreviousCondition = 1;
              } else {
                detailedInfo.carMaintenanceLease2Previous = 0;
              }
              //Current Year maintenance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Maintenance'] !== '') {
                detailedInfo.carMaintenanceLease2Current = depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Maintenance'] / 12;
                detailedInfo.carMaintenanceLease2Final = -depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Maintenance'];
                detailedInfo.carMaintenanceLease2FinalCondition = 1;
              } else {
                detailedInfo.carMaintenanceLease2Current = 0;
                detailedInfo.carMaintenanceLease2Final = 0;
              }

              //Existing Car Parameters
              //Previous Year maintenance
              detailedInfo.carMaintenanceExistingFinalCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Maintenance'] !== undefined) {
                detailedInfo.carMaintenanceExistingPrevious = depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Maintenance'] / 12;
              } else {
                detailedInfo.carMaintenanceExistingPrevious = 0;
              }
              //Current Year maintenance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Maintenance'] !== '') {
                detailedInfo.carMaintenanceExistingCurrent = depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Maintenance'] / 12;
                detailedInfo.carMaintenanceExistingFinal = -depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Maintenance'];
                detailedInfo.carMaintenanceExistingFinalCondition = 1;
              } else {
                detailedInfo.carMaintenanceExistingCurrent = 0;
                detailedInfo.carMaintenanceExistingFinal = 0;
              }

              //New Car1 Parameters
              //Previous Year maintenance
              detailedInfo.carMaintenanceNewCar1FinalCondition = 0;
              detailedInfo.carMaintenanceNewCar1PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Maintenance'] !== 0) {
                detailedInfo.carMaintenanceNewCar1Previous = depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Maintenance'] / 12;
                detailedInfo.carMaintenanceNewCar1PreviousCondition = 1;
              } else {
                detailedInfo.carMaintenanceNewCar1Previous = 0;
              }
              //Current Year maintenance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Maintenance'] !== '') {
                detailedInfo.carMaintenanceNewCar1Current = depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Maintenance'] / 12;
                detailedInfo.carMaintenanceNewCar1Final = -depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Maintenance'];
                detailedInfo.carMaintenanceNewCar1FinalCondition = 1;
              } else {
                detailedInfo.carMaintenanceNewCar1Current = 0;
                detailedInfo.carMaintenanceNewCar1Final = 0;
              }

              //New Car2 Parameters
              //Previous Year maintenance
              detailedInfo.carMaintenanceNewCar2FinalCondition = 0;
              detailedInfo.carMaintenanceNewCar2PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Maintenance'] !== 0) {
                detailedInfo.carMaintenanceNewCar2Previous = depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Maintenance'] / 12;
                detailedInfo.carMaintenanceNewCar2PreviousCondition = 1;
              } else {
                detailedInfo.carMaintenanceNewCar2Previous = 0;
              }
              //Current Year maintenance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Maintenance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Maintenance'] !== '') {
                detailedInfo.carMaintenanceNewCar2Current = depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Maintenance'] / 12;
                detailedInfo.carMaintenanceNewCar2Final = -depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Maintenance'];
                detailedInfo.carMaintenanceNewCar2FinalCondition = 1;
              } else {
                detailedInfo.carMaintenanceNewCar2Current = 0;
                detailedInfo.carMaintenanceNewCar2Final = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('living-expenses-portion-car-maintenance');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Car Insurance':
              //let depreciationScheduleCompleteData = moduleServices.depreciationScheduleCompleteData();
              detailedInfo.finalCarInsuranceValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Car Insurance'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Car Insurance'] !== 0) {
                detailedInfo.finalCarInsuranceValueCondition = 1;
                detailedInfo.finalCarInsuranceValue = incomeStatementData[selectedYear]['livingExpenses']['Car Insurance'];
              } else {
                detailedInfo.finalCarInsuranceValue = 0;
              }

              //Lease car1 Parameters
              //Previous Year Insurance
              detailedInfo.carInsuranceLease1FinalCondition = 0;
              detailedInfo.carInsuranceLease1PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Insurance'] !== 0) {
                detailedInfo.carInsuranceLease1Previous = depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Insurance'] / 12;
                detailedInfo.carInsuranceLease1PreviousCondition = 1;
              } else {
                detailedInfo.carInsuranceLease1Previous = 0;
              }
              //Inflation
              if ( moduleServices.module1Data.inflation !== undefined && moduleServices.module1Data.inflation !== '') {
                detailedInfo.inflationVal = moduleServices.module1Data.inflation;
              } else {
                detailedInfo.inflationVal = 0;
              }
              //Conditions for Previous year and Inflation
              detailedInfo.previousYearValuesConditionInsurance = 0;
              if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year === selectedYear) {
                detailedInfo.previousYearValuesConditionInsurance = 1;
              }
              //Current Year Insurance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Insurance'] !== '') {
                detailedInfo.carInsuranceLease1Current = depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Insurance'] / 12;
                detailedInfo.carInsuranceLease1Final = -depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Insurance'];
                detailedInfo.carInsuranceLease1FinalCondition = 1;
              } else {
                detailedInfo.carInsuranceLease1Final = 0;
                detailedInfo.carInsuranceLease1Current = 0;
              }

              //Lease car2 Parameters
              //Previous Year Insurance
              detailedInfo.carInsuranceLease2FinalCondition = 0;
              detailedInfo.carInsuranceLease2PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Insurance'] !== 0) {
                detailedInfo.carInsuranceLease2Previous = depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Insurance'] / 12;
                detailedInfo.carInsuranceLease2PreviousCondition = 1;
              } else {
                detailedInfo.carInsuranceLease2Previous = 0;
              }
              //Current Year Insurance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Insurance'] !== '') {
                detailedInfo.carInsuranceLease2Current = depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Insurance'] / 12;
                detailedInfo.carInsuranceLease2Final = -depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Insurance'];
                detailedInfo.carInsuranceLease2FinalCondition = 1;
              } else {
                detailedInfo.carInsuranceLease2Current = 0;
                detailedInfo.carInsuranceLease2Final = 0;
              }

              //Existing Car Parameters
              //Previous Year Insurance
              detailedInfo.carInsuranceExistingFinalCondition = 0;
              detailedInfo.carInsuranceExistingPreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Insurance'] !== 0) {
                detailedInfo.carInsuranceExistingPrevious = depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Insurance'] / 12;
                detailedInfo.carInsuranceExistingPreviousCondition = 1;
              } else {
                detailedInfo.carInsuranceExistingPrevious = 0;
              }
              //Current Year Insurance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Insurance'] !== '') {
                detailedInfo.carInsuranceExistingCurrent = depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Insurance'] / 12;
                detailedInfo.carInsuranceExistingFinal = -depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Insurance'];
                detailedInfo.carInsuranceExistingFinalCondition = 1;
              } else {
                detailedInfo.carInsuranceExistingCurrent = 0;
                detailedInfo.carInsuranceExistingFinal = 0;
              }

              //New Car1 Parameters
              //Previous Year Insurance
              detailedInfo.carInsuranceNewCar1FinalCondition = 0;
              detailedInfo.carInsuranceNewCar1PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Insurance'] !== 0) {
                detailedInfo.carInsuranceNewCar1Previous = depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Insurance'] / 12;
                detailedInfo.carInsuranceNewCar1PreviousCondition = 1;
              } else {
                detailedInfo.carInsuranceNewCar1Previous = 0;
              }
              //Current Year Insurance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Insurance'] !== '') {
                detailedInfo.carInsuranceNewCar1Current = depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Insurance'] / 12;
                detailedInfo.carInsuranceNewCar1Final = -depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Insurance'];
                detailedInfo.carInsuranceNewCar1FinalCondition = 1;
              } else {
                detailedInfo.carInsuranceNewCar1Current = 0;
                detailedInfo.carInsuranceNewCar1Final = 0;
              }

              //New Car1 Parameters
              //Previous Year Insurance
              detailedInfo.carInsuranceNewCar2FinalCondition = 0;
              detailedInfo.carInsuranceNewCar2PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Insurance'] !== 0) {
                detailedInfo.carInsuranceNewCar2Previous = depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Insurance'] / 12;
                detailedInfo.carInsuranceNewCar2PreviousCondition = 1;

              } else {
                detailedInfo.carInsuranceNewCar2Previous = 0;
              }
              //Current Year Insurance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Insurance'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Insurance'] !== '') {
                detailedInfo.carInsuranceNewCar2Current = depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Insurance'] / 12;
                detailedInfo.carInsuranceNewCar2Final = -depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Insurance'];
                detailedInfo.carInsuranceNewCar2FinalCondition = 1;
              } else {
                detailedInfo.carInsuranceNewCar2Current = 0;
                detailedInfo.carInsuranceNewCar2Final = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('living-expenses-portion-car-insurance');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Gas':
              //let depreciationScheduleCompleteData = moduleServices.depreciationScheduleCompleteData();
              detailedInfo.finalCarGasValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Gas'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Gas'] !== 0) {
                detailedInfo.finalCarGasValueCondition = 1;
                detailedInfo.finalCarGasValue = incomeStatementData[selectedYear]['livingExpenses']['Gas'];
              } else {
                detailedInfo.finalCarGasValue = 0;
              }

              //Lease car1 Parameters
              //Previous Year Gas
              detailedInfo.carGasLease1FinalCondition = 0;
              detailedInfo.carGasLease1PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Gas'] !== 0) {
                detailedInfo.carGasLease1Previous = depreciationScheduleCompleteData[selectedYear - 1]['Lease1 Expenses']['Gas'] / 12;
                detailedInfo.carGasLease1PreviousCondition = 1;
              } else {
                detailedInfo.carGasLease1Previous = 0;
              }
              //Inflation
              if ( moduleServices.module1Data.inflation !== undefined && moduleServices.module1Data.inflation !== '') {
                detailedInfo.inflationVal = moduleServices.module1Data.inflation;
              } else {
                detailedInfo.inflationVal = 0;
              }
              //Conditions for Previous year and Inflation
              detailedInfo.previousYearValuesConditionGas = 0;
              if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year === selectedYear) {
                detailedInfo.previousYearValuesConditionGas = 1;
              }
              //Current Year Gas
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Gas'] !== '') {
                detailedInfo.carGasLease1Current = depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Gas'] / 12;
                detailedInfo.carGasLease1Final = -depreciationScheduleCompleteData[selectedYear]['Lease1 Expenses']['Gas'];
                detailedInfo.carGasLease1FinalCondition = 1;
              } else {
                detailedInfo.carGasLease1Final = 0;
                detailedInfo.carGasLease1Current = 0;
              }

              //Lease car2 Parameters
              //Previous Year Gas
              detailedInfo.carGasLease2FinalCondition = 0;
              detailedInfo.carGasLease2PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Gas'] !== 0) {
                detailedInfo.carGasLease2Previous = depreciationScheduleCompleteData[selectedYear - 1]['Lease2 Expenses']['Gas'] / 12;
                detailedInfo.carGasLease2PreviousCondition = 1;
              } else {
                detailedInfo.carGasLease2Previous = 0;
              }
              //Current Year Gas
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Gas'] !== '') {
                detailedInfo.carGasLease2Current = depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Gas'] / 12;
                detailedInfo.carGasLease2Final = -depreciationScheduleCompleteData[selectedYear]['Lease2 Expenses']['Gas'];
                detailedInfo.carGasLease2FinalCondition = 1;
              } else {
                detailedInfo.carGasLease2Current = 0;
                detailedInfo.carGasLease2Final = 0;
              }

              //Existing Car Parameters
              //Previous Year Gas
              detailedInfo.carGasExistingFinalCondition = 0;
              detailedInfo.carGasExistingPreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Gas'] !== 0) {
                detailedInfo.carGasExistingPrevious = depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation Second']['Gas'] / 12;
                detailedInfo.carGasExistingPreviousCondition = 1;
              } else {
                detailedInfo.carGasExistingPrevious = 0;
              }
              //Current Year Insurance
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Gas'] !== '') {
                detailedInfo.carGasExistingCurrent = depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Gas'] / 12;
                detailedInfo.carGasExistingFinal = -depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation Second']['Gas'];
                detailedInfo.carGasExistingFinalCondition = 1;
              } else {
                detailedInfo.carGasExistingCurrent = 0;
                detailedInfo.carGasExistingFinal = 0;
              }

              //New Car1 Parameters
              //Previous Year Gas
              detailedInfo.carGasNewCar1FinalCondition = 0;
              detailedInfo.carGasNewCar1PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Gas'] !== 0) {
                detailedInfo.carGasNewCar1Previous = depreciationScheduleCompleteData[selectedYear - 1]['Understanding Depreciation']['Gas'] / 12;
                detailedInfo.carGasNewCar1PreviousCondition = 1;
              } else {
                detailedInfo.carGasNewCar1Previous = 0;
              }
              //Current Year Gas
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Gas'] !== '') {
                detailedInfo.carGasNewCar1Current = depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Gas'] / 12;
                detailedInfo.carGasNewCar1Final = -depreciationScheduleCompleteData[selectedYear]['Understanding Depreciation']['Gas'];
                detailedInfo.carGasNewCar1FinalCondition = 1;
              } else {
                detailedInfo.carGasNewCar1Current = 0;
                detailedInfo.carGasNewCar1Final = 0;
              }

              //New Car1 Parameters
              //Previous Year Gas
              detailedInfo.carGasNewCar2FinalCondition = 0;
              detailedInfo.carGasNewCar2PreviousCondition = 0;
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear - 1] !== undefined && depreciationScheduleCompleteData[selectedYear - 1] !== '' &&  depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Gas'] !== 0) {
                detailedInfo.carGasNewCar2Previous = depreciationScheduleCompleteData[selectedYear - 1]['Automobile Straight-Line Depreciation']['Gas'] / 12;
                detailedInfo.carGasNewCar2PreviousCondition = 1;
              } else {
                detailedInfo.carGasNewCar2Previous = 0;
              }
              //Current Year Gas
              if ( depreciationScheduleCompleteData !== undefined && depreciationScheduleCompleteData !== '' &&  depreciationScheduleCompleteData[selectedYear] !== undefined && depreciationScheduleCompleteData[selectedYear] !== '' &&  depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Gas'] !== undefined && depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Gas'] !== '') {
                detailedInfo.carGasNewCar2Current = depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Gas'] / 12;
                detailedInfo.carGasNewCar2Final = -depreciationScheduleCompleteData[selectedYear]['Automobile Straight-Line Depreciation']['Gas'];
                detailedInfo.carGasNewCar2FinalCondition = 1;
              } else {
                detailedInfo.carGasNewCar2Current = 0;
                detailedInfo.carGasNewCar2Final = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('living-expenses-portion-car-gas');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Miscellaneous':
              detailedInfo.Label = 'Miscellaneous Expense';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Miscellaneous'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Miscellaneous'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Miscellaneous'] / 12;

                detailedInfo.condition = incomeStatementData[selectedYear]['Miscellaneous Condition'];
              }
              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Miscellaneous'] / 12;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Miscellaneous'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Miscellaneous'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }
              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              // let multiplierValue = 1;
              // let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.miscellaneous_multiplier !== undefined && moduleServices.module10Data.miscellaneous_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.miscellaneous_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Miscellaneous'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }

                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Miscellaneous'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Miscellaneous'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }

              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Healthcare Costs other than Premium':
              detailedInfo.Label = 'Healthcare Costs other than Premium';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Healthcare Costs other than Premium'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Healthcare Costs other than Premium'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Healthcare Costs other than Premium'] / 12;

                detailedInfo.condition = incomeStatementData[selectedYear]['Health Insurance Deductible Condition'];
              }
              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Healthcare Costs other than Premium'] / 12;
              } else {
                detailedInfo.previousValue = 0;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Healthcare Costs other than Premium'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Healthcare Costs other than Premium'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }
              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              // let multiplierValue = 1;
              // let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.health_insurance_costs_multiplier !== undefined && moduleServices.module10Data.health_insurance_costs_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.health_insurance_costs_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Healthcare Costs other than Premium'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }
                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Healthcare Costs other than Premium'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Healthcare Costs other than Premium'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }
              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Health Insurance Premium':
              detailedInfo.Label = 'Health Insurance Premium';
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Health Insurance Premium'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Health Insurance Premium'] !== 0) {
                detailedInfo.Value = incomeStatementData[selectedYear]['livingExpenses']['Health Insurance Premium'] / 12;

                detailedInfo.condition = incomeStatementData[selectedYear]['Health Insurance Premium Condition'];
              }

              if (detailedInfo.condition === 2 &&  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                detailedInfo.previousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Health Insurance Premium'] / 12;
              } else {
                detailedInfo.previousValue = 0;
              }
              detailedInfo.finalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['livingExpenses']['Health Insurance Premium'] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Health Insurance Premium'] !== 0) {
                detailedInfo.finalValueCondition = 1;
              }
              //After marriage values
              detailedInfo.marriageValueCondition = 0;
              // let multiplierValue = 1;
              // let marriageYearCondition = 0;
              if ( moduleServices.module10Data.marriege_year !== undefined && moduleServices.module10Data.marriege_year > 0 && selectedYear >= moduleServices.module10Data.marriege_year) {
                detailedInfo.marriageValueCondition = 1;
                if ( moduleServices.module10Data.health_insurance_premium_multiplier !== undefined && moduleServices.module10Data.health_insurance_premium_multiplier !== '') {
                  multiplierValue = moduleServices.module10Data.health_insurance_premium_multiplier;
                } else {
                  multiplierValue = 1;
                }
                detailedInfo.marriedMonthlyMultiplier = multiplierValue;
                if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear - 1]['livingExpenses']['Health Insurance Premium'] / 12) / multiplierValue;
                } else {
                  detailedInfo.marriedMonthlyRentExpense = 0;
                }
                if (detailedInfo.condition === 1) {
                  detailedInfo.marriedMonthlyRentExpense = (incomeStatementData[selectedYear]['livingExpenses']['Health Insurance Premium'] / 12) / multiplierValue;
                }
                if (selectedYear === moduleServices.module10Data.marriege_year) {
                  marriageYearCondition = 1;
                  if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear - 1] !== undefined) {
                    detailedInfo.marriedMonthlyRentExpense = incomeStatementData[selectedYear - 1]['livingExpenses']['Health Insurance Premium'] / 12;
                  } else {
                    detailedInfo.marriedMonthlyRentExpense = 0;
                  }
                  detailedInfo.previousValue = detailedInfo.marriedMonthlyRentExpense + (detailedInfo.marriedMonthlyRentExpense / 100) * Inflation;
                } else {
                  marriageYearCondition = 0;
                }
              }

              if (marriageYearCondition === 0) {
                // setLivingExpensesPortionPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion');
              } else {
                // setLivingExpensesPortionOnYearPopup( true );
                setOpenDialog(true);
                setOpenDialogName('livingExpensesPortion-onyear');
              }
              break;
            case 'Children':
              detailedInfo.databaseData = moduleServices.databaseCompleteData();
              detailedInfo.childrenFinalValueCondition = 0;
              // let totalChild = 0;
              let firstChild = 0;
              let secondChild = 0;
              let thirdChild = 0;
              let fourthChild = 0;
              let fifthChild = 0;
              let sixthChild = 0;
              let seventhChild = 0;

              if (incomeStatementData[selectedYear]['livingExpenses']['Children']) {
                detailedInfo.childrenFinalValueCondition = 1;
              }
              if ( moduleServices.module10Data !== undefined && selectedYear >= moduleServices.module10Data.firstBorn && moduleServices.module10Data.firstBorn > 0) {
                firstChild = 1;
              }
              if ( moduleServices.module10Data !== undefined && selectedYear >= moduleServices.module10Data.secondBorn && moduleServices.module10Data.secondBorn > 0) {
                secondChild = 1;
              }
              if ( moduleServices.module10Data !== undefined && selectedYear >= moduleServices.module10Data.thirdBorn && moduleServices.module10Data.thirdBorn > 0) {
                thirdChild = 1;
              }
              if ( moduleServices.module10Data !== undefined && selectedYear >= moduleServices.module10Data.fourthBorn && moduleServices.module10Data.fourthBorn > 0) {
                fourthChild = 1;
              }
              if ( moduleServices.module10Data !== undefined && selectedYear >= moduleServices.module10Data.fifthBorn && moduleServices.module10Data.fifthBorn > 0) {
                fifthChild = 1;
              }
              if ( moduleServices.module10Data !== undefined && selectedYear >= moduleServices.module10Data.sixthBorn && moduleServices.module10Data.sixthBorn > 0) {
                sixthChild = 1;
              }
              if ( moduleServices.module10Data !== undefined && selectedYear >= moduleServices.module10Data.seventhBorn && moduleServices.module10Data.seventhBorn > 0) {
                seventhChild = 1;
              }
              detailedInfo.totalChild = firstChild + secondChild + thirdChild + fourthChild + fifthChild + sixthChild + seventhChild;

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('children');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Pet':
              let expenseCalculationData = moduleServices.expenseCalculationsCompleteData();
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined &&  incomeStatementData[selectedYear]['Pet Condition'] !== undefined && incomeStatementData[selectedYear]['Pet Condition'] !== 0) {
                detailedInfo.condition = incomeStatementData[selectedYear]['Pet Condition'];
                //let expenseCalculationsCompletedData = moduleServices.expenseCalculationsCompleteData();              
                detailedInfo.expenseCalculation = expenseCalculationData[selectedYear]['Yearly Costs'];
              }else{
                detailedInfo.expenseCalculation =0;
              }
              detailedInfo.purchaseYear = moduleServices.module10Data.year_of_purchase;

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('pet');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Home Property Expenses':
              let buyingAHomeCompleteData = moduleServices.buyingAHomeCompleteData();
              let existingHomeCompleteData = moduleServices.existingHomeCompleteData();

              if ( buyingAHomeCompleteData !== undefined && buyingAHomeCompleteData !== '' &&
                 buyingAHomeCompleteData['Data1'][selectedYear] !== undefined) {

                detailedInfo = buyingAHomeCompleteData['Data1'][selectedYear];
                detailedInfo.Year = selectedYear;
                detailedInfo.BuyingAHomePuchaseYear = moduleServices.module13Data.year_of_purchase;
                //Condition for new home property
                detailedInfo.totalNewHomeExpenses = buyingAHomeCompleteData['Data1'][selectedYear]['Private Mortgage Insurance'] +
                  buyingAHomeCompleteData['Data1'][selectedYear]['Association Fees'] +
                  buyingAHomeCompleteData['Data1'][selectedYear]['Houseowners Insurance'] +
                  buyingAHomeCompleteData['Data1'][selectedYear]['Property Maintenance'] +
                  buyingAHomeCompleteData['Data1'][selectedYear]['Utilities'];
                detailedInfo.totalNewHomeExpensesCondition = 0;
                if (detailedInfo.totalNewHomeExpenses !== 0) {
                  detailedInfo.totalNewHomeExpensesCondition = 1;
                }

                // setOpenGrossIncomePopup( true );
                setOpenGrossIncomeModel('home-property-expenses');
                setOpenDialog(true);
                setOpenDialogName('showDetailPopup');
              }
              //Exdisting Home Data
              if ( existingHomeCompleteData !== undefined && existingHomeCompleteData !== '' &&
                 existingHomeCompleteData['Data1'][selectedYear] !== undefined) {

                // let detailedInfo1 = existingHomeCompleteData['Data1'][selectedYear];
                //Condition for new home property
                detailedInfo.totalExistingHomeExpenses = existingHomeCompleteData['Data1'][selectedYear]['Private Mortgage Insurance'] +
                  existingHomeCompleteData['Data1'][selectedYear]['Association Fees'] +
                  existingHomeCompleteData['Data1'][selectedYear]['Houseowners Insurance'] +
                  existingHomeCompleteData['Data1'][selectedYear]['Maintenance'] +
                  existingHomeCompleteData['Data1'][selectedYear]['Utilities'];
                detailedInfo.totalExistingHomeExpensesCondition = 0;
                if (detailedInfo.totalExistingHomeExpenses !== 0) {
                  detailedInfo.totalExistingHomeExpensesCondition = 1;
                }

                // setOpenGrossIncomePopup( true );
                setOpenGrossIncomeModel('home-property-expenses');
                setOpenDialog(true);
                setOpenDialogName('showDetailPopup');
              }

              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined) {
                detailedInfo.propertyExpenseFinalValue = incomeStatementData[selectedYear]['livingExpenses']['Home Property Expenses'];
              } else {
                detailedInfo.propertyExpenseFinalValue = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('home-property-expenses');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Home Property Taxes':
              let BuyingAHomeData = moduleServices.buyingAHomeCompleteData();
              detailedInfo.BuyingAHomeVal = BuyingAHomeData['Real Estate Asset Value'];
              detailedInfo.BuyingAHomePuchaseYear = moduleServices.module13Data.year_of_purchase;
              detailedInfo.propertyTax = parseFloat(moduleServices.module13Data.propertyTaxes);
              detailedInfo.propertyTaxesCalcs = moduleServices.module13Data.propertyTaxesCalcs;
              detailedInfo.inflationVal = moduleServices.module1Data.inflation;
              detailedInfo.homePropertyTaxesPreviousValueCondition = 0;

              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear - 1] !== undefined && incomeStatementData[selectedYear - 1]['livingExpenses']['Home Property Taxes'] !== 0) {
                detailedInfo.homePropertyTaxesPreviousValue = incomeStatementData[selectedYear - 1]['livingExpenses']['Home Property Taxes'];
                detailedInfo.homePropertyTaxesPreviousValueCondition = 1;
              }
              else {
                detailedInfo.homePropertyTaxesPreviousValue = 0;
              }
              detailedInfo.homePropertyTaxesFinalValueCondition = 0;
              if ( incomeStatementData !== undefined &&  incomeStatementData[selectedYear] !== undefined && incomeStatementData[selectedYear]['livingExpenses']['Home Property Taxes'] !== 0) {
                detailedInfo.homePropertyTaxesFinalValueCondition = 1;
              }

              //setOpenHomePropertyTaxesExpensesPopup(true);
              setOpenHomePropertyTaxesExpensesModel('home-property-taxes-expenses');

              setOpenDialog(true);
              setOpenDialogName('homePropertyTaxesExpenses');

              break;
            default:
              break;
          }
          break;
        /* End of Living Expenses */
        /* Interest Expenses */

        case 'InterestExpenses':
          switch (selectedColumn) {
            case 'Car Loans':
              let carLoan1Data = moduleServices.carLoan1CompleteData();
              let carLoan2Data = moduleServices.carLoan2CompleteData();
              let existingCarLoanData = moduleServices.existingCarLoanCompleteData();

              detailedInfo.existingCarLoanCondition = 0;
              if ( existingCarLoanData !== undefined && existingCarLoanData !== '' &&
                 existingCarLoanData['Summation Data'][selectedYear] !== undefined) {
                detailedInfo.existingCarLoan = existingCarLoanData['Car Loan1'][selectedYear]['Months Total Balance'] / 12;
                detailedInfo.existingCarLoanInterestRate = existingCarLoanData['Annual Interest Rate'];
                detailedInfo.existingCarLoanInterest = existingCarLoanData['Summation Data'][selectedYear]['LaggingInterest'];
                detailedInfo.existingCarLoanPrincipal = existingCarLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
                detailedInfo.existingCarLoanPayments = existingCarLoanData['Summation Data'][selectedYear]['LaggingInterest'] + existingCarLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.existingCarLoanPayments = 0;
              }
              if (detailedInfo.existingCarLoanPayments !== 0) {
                detailedInfo.existingCarLoanCondition = 1;
              }

              detailedInfo.carLoan1Condition = 0;
              if ( carLoan1Data !== undefined && carLoan1Data !== '' &&
                 carLoan1Data['Summation Data'][selectedYear] !== undefined &&
                parseFloat(carLoan1Data['Summation Data'][selectedYear]['LaggingInterest']) > 0) {
                detailedInfo.carLoan1 = carLoan1Data['Car Loan1'][selectedYear]['Months Total Balance'] / 12;
                detailedInfo.carLoan1InterestRate = carLoan1Data['Annual Interest Rate'];
                detailedInfo.carLoan1Interest = carLoan1Data['Summation Data'][selectedYear]['LaggingInterest'];
                detailedInfo.carLoan1Principal = carLoan1Data['Summation Data'][selectedYear]['LaggingPrincipal'];
                detailedInfo.carLoan1Payments = carLoan1Data['Summation Data'][selectedYear]['LaggingInterest'] + carLoan1Data['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.carLoan1Payments = 0;
              }

              if (detailedInfo.carLoan1Payments !== 0) {
                detailedInfo.carLoan1Condition = 1;
              }

              detailedInfo.carLoan2Condition = 0;
              if ( carLoan2Data !== undefined && carLoan2Data !== '' &&
                 carLoan2Data['Summation Data'][selectedYear] !== undefined &&
                parseFloat(carLoan2Data['Summation Data'][selectedYear]['LaggingInterest']) > 0) {
                detailedInfo.carLoan2 = carLoan2Data['Car Loan2'][selectedYear]['Months Total Balance'] / 12;
                detailedInfo.carLoan2InterestRate = carLoan2Data['Annual Interest Rate'];
                detailedInfo.carLoan2Interest = carLoan2Data['Summation Data'][selectedYear]['LaggingInterest'];
                detailedInfo.carLoan2Principal = carLoan2Data['Summation Data'][selectedYear]['LaggingPrincipal'];
                detailedInfo.carLoan2Payments = carLoan2Data['Summation Data'][selectedYear]['LaggingInterest'] + carLoan2Data['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.carLoan2Payments = 0;
              }

              if (detailedInfo.carLoan2Payments !== 0) {
                detailedInfo.carLoan2Condition = 1;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('carloan-interestexpenses');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Student Loans':

              //let existingStudentLoanData = moduleServices.existingStudentLoanCompleteData();

              let existingFedSubStudentLoanData = moduleServices.fedSubExistingLoanCompleteData();
              let existingFedUnsubStudentLoanData = moduleServices.fedUnSubExistingLoanCompleteData();
              let existingPrivateStudentLoanData = moduleServices.privateExistingLoanCompleteData();
              let fedSubHigherEdLoanData = moduleServices.fedSubHigherEdLoanCompleteData();
              let fedUnSubHigherEdLoanData = moduleServices.fedUnSubHigherEdLoanCompleteData();
              let privateHigherEdLoanData = moduleServices.privateHigherEdLoanCompleteData();
              let fedSubMoreHigherEdLoanData = moduleServices.fedSubMoreHigherEdLoanCompleteData();
              let fedUnSubMoreHigherEdLoanData = moduleServices.fedUnSubMoreHigherEdLoanCompleteData();
              let privateMoreHigherEdLoanData = moduleServices.privateMoreHigherEdLoanCompleteData();
              let fedSubNewHigherEdLoanData = moduleServices.fedSubNewHigherEdLoanCompleteData();
              let fedUnSubNewHigherEdLoanData = moduleServices.fedUnSubNewHigherEdLoanCompleteData();
              let privateNewHigherEdLoanData = moduleServices.privateNewHigherEdLoanCompleteData();
              let spouseEdLoanData = moduleServices.spouseStudentLoanCompleteData();

              detailedInfo.existingStudentLoanInterestExpenseCondition = 0;
              detailedInfo.averageExistingFedSubExpenseValCondition = 0;
              detailedInfo.averageExistingFedUnsubExpenseValCondition = 0;
              detailedInfo.averageExistingPrivateExpenseValCondition = 0;
              detailedInfo.fedSubInterestExpenseCondition = 0;
              detailedInfo.fedUnubInterestExpenseCondition = 0;
              detailedInfo.privateInterestExpenseCondition = 0;
              detailedInfo.fedSubMoreInterestExpenseCondition = 0;
              detailedInfo.fedUnubMoreInterestExpenseCondition = 0;
              detailedInfo.morePrivateInterestExpenseCondition = 0;

              //let existingFederalSubsidizedStudentLoan = 0;
              if ( existingFedSubStudentLoanData !== undefined && existingFedSubStudentLoanData !== '' &&  existingFedSubStudentLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  existingFedSubStudentLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                detailedInfo.averageExistingFedSubLoanVal = existingFedSubStudentLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
              }
              if ( existingFedSubStudentLoanData !== undefined && existingFedSubStudentLoanData !== '' &&  existingFedSubStudentLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.averageExistingFedSubInterestRate = existingFedSubStudentLoanData['Annual Interest Rate'];
              }

              if ( existingFedSubStudentLoanData !== undefined && existingFedSubStudentLoanData !== '' &&  existingFedSubStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && existingFedSubStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.averageExistingFedSubExpenseVal = -existingFedSubStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.averageExistingFedSubExpenseVal = 0;
              }
              if ( existingFedSubStudentLoanData !== undefined && existingFedSubStudentLoanData !== '' &&  existingFedSubStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && existingFedSubStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.averageExistingFedSubPrincipalVal = -existingFedSubStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.averageExistingFedSubPrincipalVal = 0;
              }
              detailedInfo.existingFederalSubsidizedStudentLoan = detailedInfo.averageExistingFedSubExpenseVal + detailedInfo.averageExistingFedSubPrincipalVal;
              if (detailedInfo.existingFederalSubsidizedStudentLoan !== 0) {
                detailedInfo.averageExistingFedSubExpenseValCondition = 1;
              }


              //Second section
              if ( existingFedUnsubStudentLoanData !== undefined && existingFedUnsubStudentLoanData !== '' &&  existingFedUnsubStudentLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  existingFedUnsubStudentLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                detailedInfo.averageExistingFedUnsubLoanVal = existingFedUnsubStudentLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
              }
              if ( existingFedUnsubStudentLoanData !== undefined && existingFedUnsubStudentLoanData !== '' &&  existingFedUnsubStudentLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.averageExistingFedUnsubInterestRate = existingFedUnsubStudentLoanData['Annual Interest Rate'];
              }
              if ( existingFedUnsubStudentLoanData !== undefined && existingFedUnsubStudentLoanData !== '' &&  existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.averageExistingFedUnsubExpenseVal = -existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.averageExistingFedUnsubExpenseVal = 0;
              }
              if ( existingFedUnsubStudentLoanData !== undefined && existingFedUnsubStudentLoanData !== '' &&  existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.averageExistingFedUnsubPrincipalVal = -existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.averageExistingFedUnsubPrincipalVal = 0;
              }
              detailedInfo.existingFederalUnsubStudentLoan = detailedInfo.averageExistingFedUnsubExpenseVal + detailedInfo.averageExistingFedUnsubPrincipalVal;

              if (detailedInfo.existingFederalUnsubStudentLoan !== 0) {
                detailedInfo.averageExistingFedUnsubExpenseValCondition = 1;
              }

              //During Repayments
              detailedInfo.interestExpenseDuringPaymentCondition = 0;
              if ( moduleServices.module18Data !== undefined && moduleServices.module18Data.fedUnSubMonths > 0 && moduleServices.module18Data.fedUnSubPaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.interestExpenseDuringPayment = (detailedInfo.averageExistingFedUnsubLoanVal / 100) * detailedInfo.averageExistingFedUnsubInterestRate;
                detailedInfo.interestExpenseDuringPaymentCondition = 1;
              } else {
                detailedInfo.interestExpenseDuringPayment = 0;
              }
              if (detailedInfo.interestExpenseDuringPayment !== detailedInfo.averageExistingFedUnsubExpenseVal) {
                detailedInfo.interestExpenseBeforePayment = -Math.round(detailedInfo.averageExistingFedUnsubExpenseVal) - Math.round(detailedInfo.interestExpenseDuringPayment);
              } else {
                detailedInfo.interestExpenseBeforePayment = 0;
              }
              detailedInfo.interestExpenseBeforePaymentCondition = 0;
              if (detailedInfo.interestExpenseBeforePayment > 0) {
                detailedInfo.interestExpenseBeforePaymentCondition = 1;
              }
              //During Repayments Completed

              if ( existingPrivateStudentLoanData !== undefined && existingPrivateStudentLoanData !== '' &&  existingPrivateStudentLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  existingPrivateStudentLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                detailedInfo.averageExistingPrivateLoanVal = existingPrivateStudentLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
              }
              if ( existingPrivateStudentLoanData !== undefined && existingPrivateStudentLoanData !== '' &&  existingPrivateStudentLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.averageExistingPrivateInterestRate = existingPrivateStudentLoanData['Annual Interest Rate'];
              }
              if ( existingPrivateStudentLoanData !== undefined && existingPrivateStudentLoanData !== '' &&  existingPrivateStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && existingPrivateStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.averageExistingPrivateExpenseVal = -existingPrivateStudentLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.averageExistingPrivateExpenseVal = 0;
              }
              if ( existingPrivateStudentLoanData !== undefined && existingPrivateStudentLoanData !== '' &&  existingPrivateStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && existingPrivateStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.averageExistingPrivatePrincipalVal = -existingPrivateStudentLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.averageExistingPrivatePrincipalVal = 0;
              }
              detailedInfo.existingPrivateStudentsLoansVal = detailedInfo.averageExistingPrivateExpenseVal + detailedInfo.averageExistingPrivatePrincipalVal;
              if (detailedInfo.existingPrivateStudentsLoansVal !== 0) {
                detailedInfo.averageExistingPrivateExpenseValCondition = 1;
              }

              //During Payments 
              detailedInfo.interestExpenseDuringPrivatePaymentCondition = 0;
              if ( moduleServices.module18Data !== undefined && moduleServices.module18Data.fedUnSubMonths > 0 && moduleServices.module18Data.fedUnSubPaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.interestExpenseDuringPrivatePayment = (detailedInfo.averageExistingPrivateLoanVal / 100) * detailedInfo.averageExistingPrivateInterestRate;
                detailedInfo.interestExpenseDuringPrivatePaymentCondition = 1;
              } else {
                detailedInfo.interestExpenseDuringPrivatePayment = 0;
              }

              if (detailedInfo.interestExpenseDuringPrivatePayment !== detailedInfo.averageExistingPrivateExpenseVal) {
                detailedInfo.interestExpenseBeforePrivatePayment = -Math.round(detailedInfo.averageExistingPrivateExpenseVal) - Math.round(detailedInfo.interestExpenseDuringPrivatePayment);
              } else {
                detailedInfo.interestExpenseBeforePrivatePayment = 0;
              }
              detailedInfo.interestExpenseBeforePrivatePaymentCondition = 0;
              if (detailedInfo.interestExpenseBeforePrivatePayment > 0) {
                detailedInfo.interestExpenseBeforePrivatePaymentCondition = 1;
              }
              //During Payments Completed
              if ( moduleServices.module2Data.fedSubPaymentYearBegins !== undefined && moduleServices.module2Data.fedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module2Data.fedSubPaymentYearBegins &&  fedSubHigherEdLoanData !== undefined && fedSubHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedSubHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageFederalSubsidizedLoanVal = fedSubHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( fedSubHigherEdLoanData !== undefined && fedSubHigherEdLoanData !== '' &&  fedSubHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  fedSubHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageFederalSubsidizedLoanVal = fedSubHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }
              if ( fedSubHigherEdLoanData !== undefined && fedSubHigherEdLoanData !== '' &&  fedSubHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.fedSubinterestRateVal = fedSubHigherEdLoanData['Annual Interest Rate'];
              }
              if ( fedSubHigherEdLoanData !== undefined && fedSubHigherEdLoanData !== '' &&  fedSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && fedSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.fedSubInterestExpenseVal = -fedSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.fedSubInterestExpenseVal = 0;
              }
              if ( fedSubHigherEdLoanData !== undefined && fedSubHigherEdLoanData !== '' &&  fedSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && fedSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.fedSubInterestPrincipalVal = -fedSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.fedSubInterestPrincipalVal = 0;
              }
              detailedInfo.fedSubStudentLoansVal = detailedInfo.fedSubInterestExpenseVal + detailedInfo.fedSubInterestPrincipalVal;
              if (detailedInfo.fedSubStudentLoansVal !== 0) {
                detailedInfo.fedSubInterestExpenseCondition = 1;
              }

              if ( moduleServices.module2Data.fedSubPaymentYearBegins !== undefined && moduleServices.module2Data.fedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module2Data.fedSubPaymentYearBegins &&  fedUnSubHigherEdLoanData !== undefined && fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageFederalUnsubsidizedLoanVal = fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( fedUnSubHigherEdLoanData !== undefined && fedUnSubHigherEdLoanData !== '' &&  fedUnSubHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  fedUnSubHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageFederalUnsubsidizedLoanVal = fedUnSubHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }
              if ( fedUnSubHigherEdLoanData !== undefined && fedUnSubHigherEdLoanData !== '' &&  fedUnSubHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.fedUnsubinterestRateVal = fedUnSubHigherEdLoanData['Annual Interest Rate'];
              }

              //Repayment Values
              detailedInfo.fedUnsubHigherEdMakeInterestpaymentsCondition = 0;
              if ( moduleServices.module2Data !== undefined &&  moduleServices.module2Data.fedUnSubPaymentPlan !== undefined && moduleServices.module2Data.fedUnSubPaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.fedUnsubHigherEdMakeInterestpaymentsCondition = 1;
              }
              detailedInfo.fedUnsubHigherGracePeriodCondition = 0;
              if ( moduleServices.module2Data !== undefined && selectedYear === moduleServices.module2Data.fedSubPaymentYearBegins &&  fedUnSubHigherEdLoanData !== undefined && fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined && fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== 0) {
                detailedInfo.fedUnsubHigherGracePeriodValue = -((fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] / 100) * detailedInfo.fedUnsubinterestRateVal) / 2;
                detailedInfo.fedUnsubHigherGracePeriodCondition = 1;
              }
              else {
                detailedInfo.fedUnsubHigherGracePeriodValue = 0;
              }
              if ( fedUnSubHigherEdLoanData !== undefined && fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined) {
                detailedInfo.fedUnsubHigherRepaymentValue = -fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] - detailedInfo.fedUnsubHigherGracePeriodValue;
              }
              //Repayment values Completed

              if ( fedUnSubHigherEdLoanData !== undefined && fedUnSubHigherEdLoanData !== '' &&  fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.fedUnubInterestExpenseVal = -fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.fedUnubInterestExpenseVal = 0;
              }
              if ( fedUnSubHigherEdLoanData !== undefined && fedUnSubHigherEdLoanData !== '' &&  fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.fedUnubInterestPrincipalVal = -fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.fedUnubInterestPrincipalVal = 0;
              }
              detailedInfo.fedUnubStudentsLoanVal = detailedInfo.fedUnubInterestExpenseVal + detailedInfo.fedUnubInterestPrincipalVal;
              if (detailedInfo.fedUnubStudentsLoanVal !== 0) {
                detailedInfo.fedUnubInterestExpenseCondition = 1;
              }


              if ( moduleServices.module2Data.fedSubPaymentYearBegins !== undefined && moduleServices.module2Data.fedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module2Data.fedSubPaymentYearBegins &&  privateHigherEdLoanData !== undefined && privateHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averagePrivateLoanVal = privateHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( privateHigherEdLoanData !== undefined && privateHigherEdLoanData !== '' &&  privateHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  privateHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averagePrivateLoanVal = privateHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }
              if ( privateHigherEdLoanData !== undefined && privateHigherEdLoanData !== '' &&  privateHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.privateInterestRateVal = privateHigherEdLoanData['Annual Interest Rate'];
              }
              //Repayment Values
              detailedInfo.privateHigherEdMakeInterestpaymentsCondition = 0;
              if ( moduleServices.module2Data !== undefined &&  moduleServices.module2Data.privatePaymentPlan !== undefined && moduleServices.module2Data.privatePaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.privateHigherEdMakeInterestpaymentsCondition = 1;
              }
              detailedInfo.privateHigherGracePeriodCondition = 0;
              if ( moduleServices.module2Data !== undefined && selectedYear === moduleServices.module2Data.fedSubPaymentYearBegins &&  privateHigherEdLoanData !== undefined && privateHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined && privateHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== 0) {
                detailedInfo.privateHigherGracePeriodValue = -((privateHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] / 100) * detailedInfo.privateInterestRateVal) / 2;
                detailedInfo.privateHigherGracePeriodCondition = 1;
              }
              else {
                detailedInfo.privateHigherGracePeriodValue = 0;
              }
              if ( privateHigherEdLoanData !== undefined && privateHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined) {
                detailedInfo.privateHigherRepaymentValue = -privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] - detailedInfo.privateHigherGracePeriodValue;
              }
              //Repayment values Completed
              if ( privateHigherEdLoanData !== undefined && privateHigherEdLoanData !== '' &&  privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.privateInterestExpenseVal = -privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.privateInterestExpenseVal = 0;
              }
              if ( privateHigherEdLoanData !== undefined && privateHigherEdLoanData !== '' &&  privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.privateInterestPrincipalVal = -privateHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.privateInterestPrincipalVal = 0;
              }
              detailedInfo.privateHigherEdStudentLoansVal = detailedInfo.privateInterestExpenseVal + detailedInfo.privateInterestPrincipalVal;
              if (detailedInfo.privateHigherEdStudentLoansVal !== 0) {
                detailedInfo.privateInterestExpenseCondition = 1;
              }


              if ( moduleServices.module6Data.moreFedSubPaymentYearBegins !== undefined && moduleServices.module6Data.moreFedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module6Data.moreFedSubPaymentYearBegins &&  fedSubMoreHigherEdLoanData !== undefined && fedSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageMoreFederalSubsidizedLoanVal = fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( fedSubMoreHigherEdLoanData !== undefined && fedSubMoreHigherEdLoanData !== '' &&  fedSubMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  fedSubMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageMoreFederalSubsidizedLoanVal = fedSubMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }
              if ( fedSubMoreHigherEdLoanData !== undefined && fedSubMoreHigherEdLoanData !== '' &&  fedSubMoreHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.fedSubMoreinterestRateVal = fedSubMoreHigherEdLoanData['Annual Interest Rate'];
              }
              if ( fedSubMoreHigherEdLoanData !== undefined && fedSubMoreHigherEdLoanData !== '' &&  fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.fedSubMoreInterestExpenseVal = -fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.fedSubMoreInterestExpenseVal = 0;
              }
              if ( fedSubMoreHigherEdLoanData !== undefined && fedSubMoreHigherEdLoanData !== '' &&  fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.fedSubMoreInterestPrincipalVal = -fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.fedSubMoreInterestPrincipalVal = 0;
              }
              detailedInfo.fedSubMoreLoanPaymentsVal = detailedInfo.fedSubMoreInterestExpenseVal + detailedInfo.fedSubMoreInterestPrincipalVal;
              if (detailedInfo.fedSubMoreLoanPaymentsVal !== 0) {
                detailedInfo.fedSubMoreInterestExpenseCondition = 1;
              }

              if ( moduleServices.module6Data.moreFedSubPaymentYearBegins !== undefined && moduleServices.module6Data.moreFedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module6Data.moreFedSubPaymentYearBegins &&  fedUnSubMoreHigherEdLoanData !== undefined && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageMoreFederalUnsubsidizedLoanVal = fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( fedUnSubMoreHigherEdLoanData !== undefined && fedUnSubMoreHigherEdLoanData !== '' &&  fedUnSubMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  fedUnSubMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageMoreFederalUnsubsidizedLoanVal = fedUnSubMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }

              if ( fedUnSubMoreHigherEdLoanData !== undefined && fedUnSubMoreHigherEdLoanData !== '' &&  fedUnSubMoreHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.fedUnsubMoreinterestRateVal = fedUnSubMoreHigherEdLoanData['Annual Interest Rate'];
              }
              //Repayment Values
              detailedInfo.fedUnsubMoreHigherEdMakeInterestpaymentsCondition = 0;
              if ( moduleServices.module6Data !== undefined &&  moduleServices.module6Data.fedUnSubPaymentPlan !== undefined && moduleServices.module6Data.fedUnSubPaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.fedUnsubMoreHigherEdMakeInterestpaymentsCondition = 1;
              }
              detailedInfo.fedUnsubMoreHigherGracePeriodCondition = 0;
              if ( moduleServices.module6Data !== undefined && selectedYear === moduleServices.module6Data.moreFedSubPaymentYearBegins &&  fedUnSubMoreHigherEdLoanData !== undefined && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== 0) {
                detailedInfo.fedUnsubMoreHigherGracePeriodValue = -((fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] / 100) * detailedInfo.fedUnsubMoreinterestRateVal) / 2;
                detailedInfo.fedUnsubMoreHigherGracePeriodCondition = 1;
              }
              else {
                detailedInfo.fedUnsubMoreHigherGracePeriodValue = 0;
              }
              if ( fedUnSubMoreHigherEdLoanData !== undefined && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined) {
                detailedInfo.fedUnsubMoreHigherRepaymentValue = -fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] - detailedInfo.fedUnsubMoreHigherGracePeriodValue;
              }
              //Repayment values Completed
              if ( fedUnSubMoreHigherEdLoanData !== undefined && fedUnSubMoreHigherEdLoanData !== '' &&  fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.fedUnubMoreInterestExpenseVal = -fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.fedUnubMoreInterestExpenseVal = 0;
              }
              if ( fedUnSubMoreHigherEdLoanData !== undefined && fedUnSubMoreHigherEdLoanData !== '' &&  fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.fedUnubMoreInterestPrincipalVal = -fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.fedUnubMoreInterestPrincipalVal = 0;
              }
              detailedInfo.fedUnSubMoreLoanPaymentsVal = detailedInfo.fedUnubMoreInterestExpenseVal + detailedInfo.fedUnubMoreInterestPrincipalVal;
              if (detailedInfo.fedUnSubMoreLoanPaymentsVal !== 0) {
                detailedInfo.fedUnubMoreInterestExpenseCondition = 1;
              }


              if ( moduleServices.module6Data.moreFedSubPaymentYearBegins !== undefined && moduleServices.module6Data.moreFedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module6Data.moreFedSubPaymentYearBegins &&  privateMoreHigherEdLoanData !== undefined && privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageMorePrivateLoanVal = privateMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( privateMoreHigherEdLoanData !== undefined && privateMoreHigherEdLoanData !== '' &&  privateMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  privateMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageMorePrivateLoanVal = privateMoreHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }

              if ( privateMoreHigherEdLoanData !== undefined && privateMoreHigherEdLoanData !== '' &&  privateMoreHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.morePrivateInterestRateVal = privateMoreHigherEdLoanData['Annual Interest Rate'];
              }

              if ( privateMoreHigherEdLoanData !== undefined && privateMoreHigherEdLoanData !== '' &&  privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.morePrivateInterestExpenseVal = -privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.morePrivateInterestExpenseVal = 0;
              }
              if ( privateMoreHigherEdLoanData !== undefined && privateMoreHigherEdLoanData !== '' &&  privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.morePrivateInterestPrincipalVal = -privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.morePrivateInterestPrincipalVal = 0;
              }
              detailedInfo.privateMoreStudentsPaymentsVal = detailedInfo.morePrivateInterestExpenseVal + detailedInfo.morePrivateInterestPrincipalVal;
              if (detailedInfo.privateMoreStudentsPaymentsVal !== 0) {
                detailedInfo.morePrivateInterestExpenseCondition = 1;
              }


              //Repayment Values
              if ( moduleServices.module6Data !== undefined &&  moduleServices.module6Data.privatePaymentPlan !== undefined && moduleServices.module6Data.privatePaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.morePrivateMakeInterestpaymentsCondition = 1;
              }
              detailedInfo.morePrivateGracePeriodCondition = 0;
              if ( moduleServices.module6Data !== undefined && selectedYear === moduleServices.module6Data.moreFedSubPaymentYearBegins &&  privateMoreHigherEdLoanData !== undefined && privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined && privateMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== 0) {
                detailedInfo.morePrivateGracePeriodValue = -((privateMoreHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] / 100) * detailedInfo.morePrivateInterestRateVal) / 2;
                detailedInfo.morePrivateGracePeriodCondition = 1;
              }
              else {
                detailedInfo.morePrivateGracePeriodValue = 0;
              }
              if ( privateMoreHigherEdLoanData !== undefined && privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined) {
                detailedInfo.morePrivateRepaymentValue = -privateMoreHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] - detailedInfo.morePrivateGracePeriodValue;
              }
              //Repayment values Completed

              if ( moduleServices.module15Data.newFedSubPaymentYearBegins !== undefined && moduleServices.module15Data.newFedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module15Data.newFedSubPaymentYearBegins &&  fedSubNewHigherEdLoanData !== undefined && fedSubNewHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageNewFederalSubsidizedLoanVal = fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( fedSubNewHigherEdLoanData !== undefined && fedSubNewHigherEdLoanData !== '' &&  fedSubNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  fedSubNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageNewFederalSubsidizedLoanVal = fedSubNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }

              if ( fedSubNewHigherEdLoanData !== undefined && fedSubNewHigherEdLoanData !== '' &&  fedSubNewHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.fedSubNewinterestRateVal = fedSubNewHigherEdLoanData['Annual Interest Rate'];
              }
              if ( fedSubNewHigherEdLoanData !== undefined && fedSubNewHigherEdLoanData !== '' &&  fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.fedSubNewInterestExpenseVal = -fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.fedSubNewInterestExpenseVal = 0;
              }
              if ( fedSubNewHigherEdLoanData !== undefined && fedSubNewHigherEdLoanData !== '' &&  fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.fedSubNewInterestPrincipalVal = -fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.fedSubNewInterestPrincipalVal = 0;
              }
              detailedInfo.fedSubNewHigherPaymentsVal = detailedInfo.fedSubNewInterestExpenseVal + detailedInfo.fedSubNewInterestPrincipalVal;
              if (detailedInfo.fedSubNewHigherPaymentsVal !== 0) {
                detailedInfo.fedSubNewInterestExpenseCondition = 1;
              }


              if ( moduleServices.module15Data.newFedSubPaymentYearBegins !== undefined && moduleServices.module15Data.newFedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module15Data.newFedSubPaymentYearBegins &&  fedUnSubNewHigherEdLoanData !== undefined && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageNewFederalUnsubsidizedLoanVal = fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( fedUnSubNewHigherEdLoanData !== undefined && fedUnSubNewHigherEdLoanData !== '' &&  fedUnSubNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  fedUnSubNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageNewFederalUnsubsidizedLoanVal = fedUnSubNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }

              if ( fedUnSubNewHigherEdLoanData !== undefined && fedUnSubNewHigherEdLoanData !== '' &&  fedUnSubNewHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.fedUnsubNewinterestRateVal = fedUnSubNewHigherEdLoanData['Annual Interest Rate'];
              }
              //Repayment Values
              detailedInfo.furtherFedUnsubMakeInterestpaymentsCondition = 0;
              if ( moduleServices.module15Data !== undefined &&  moduleServices.module15Data.fedUnSubPaymentPlan !== undefined && moduleServices.module15Data.fedUnSubPaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.furtherFedUnsubMakeInterestpaymentsCondition = 1;
              }
              detailedInfo.fedUnsubNewGracePeriodCondition = 0;
              if ( moduleServices.module15Data !== undefined && selectedYear === moduleServices.module15Data.newFedSubPaymentYearBegins &&  fedUnSubNewHigherEdLoanData !== undefined && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== 0) {
                detailedInfo.fedUnsubNewGracePeriodValue = -((fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] / 100) * detailedInfo.fedUnsubNewinterestRateVal) / 2;
                detailedInfo.fedUnsubNewGracePeriodCondition = 1;
              }
              else {
                detailedInfo.fedUnsubNewGracePeriodValue = 0;
              }
              if ( fedUnSubNewHigherEdLoanData !== undefined && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined) {
                detailedInfo.fedUnsubNewRepaymentValue = -fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] - detailedInfo.fedUnsubNewGracePeriodValue;
              }
              //Repayment values Completed
              if ( fedUnSubNewHigherEdLoanData !== undefined && fedUnSubNewHigherEdLoanData !== '' &&  fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.fedUnubNewInterestExpenseVal = -fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.fedUnubNewInterestExpenseVal = 0;
              }
              if ( fedUnSubNewHigherEdLoanData !== undefined && fedUnSubNewHigherEdLoanData !== '' &&  fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.fedUnubNewInterestPrincipalVal = -fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.fedUnubNewInterestPrincipalVal = 0;
              }
              detailedInfo.fedUnsubStudentsLoanPaymentsVal = detailedInfo.fedUnubNewInterestExpenseVal + detailedInfo.fedUnubNewInterestPrincipalVal;
              if (detailedInfo.fedUnsubStudentsLoanPaymentsVal !== 0) {
                detailedInfo.fedUnubNewInterestExpenseCondition = 1;
              }

              if ( moduleServices.module15Data.newFedSubPaymentYearBegins !== undefined && moduleServices.module15Data.newFedSubPaymentYearBegins !== '' && selectedYear < moduleServices.module15Data.newFedSubPaymentYearBegins &&  privateNewHigherEdLoanData !== undefined && privateNewHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined) {
                detailedInfo.averageNewPrivateLoanVal = privateNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
              }
              else {
                if ( privateNewHigherEdLoanData !== undefined && privateNewHigherEdLoanData !== '' &&  privateNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&  privateNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] !== undefined) {
                  detailedInfo.averageNewPrivateLoanVal = privateNewHigherEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                }
              }
              if ( privateNewHigherEdLoanData !== undefined && privateNewHigherEdLoanData !== '' &&  privateNewHigherEdLoanData['Annual Interest Rate'] !== undefined) {
                detailedInfo.newPrivateInterestRateVal = privateNewHigherEdLoanData['Annual Interest Rate'];
              }
              //Repayment Values
              detailedInfo.furtherPrivateMakeInterestpaymentsCondition = 0;
              if ( moduleServices.module15Data !== undefined &&  moduleServices.module15Data.privatePaymentPlan !== undefined && moduleServices.module15Data.privatePaymentPlan.name === 'Make Interest Payments') {
                detailedInfo.furtherPrivateMakeInterestpaymentsCondition = 1;
              }
              detailedInfo.furtherPrivateGracePeriodCondition = 0;
              if ( moduleServices.module15Data !== undefined && selectedYear === moduleServices.module15Data.newFedSubPaymentYearBegins &&  privateNewHigherEdLoanData !== undefined && privateNewHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== undefined && privateNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] !== 0) {
                detailedInfo.furtherPrivateGracePeriodValue = -((privateNewHigherEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] / 100) * detailedInfo.morePrivateInterestRateVal) / 2;
                detailedInfo.furtherPrivateGracePeriodCondition = 1;
              }
              else {
                detailedInfo.furtherPrivateGracePeriodValue = 0;
              }
              if ( privateNewHigherEdLoanData !== undefined && privateNewHigherEdLoanData['Summation Data'][selectedYear] !== undefined &&  privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined) {
                detailedInfo.furtherPrivateRepaymentValue = -privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] - detailedInfo.furtherPrivateGracePeriodValue;
              }
              //Repayment values Completed
              if ( privateNewHigherEdLoanData !== undefined && privateNewHigherEdLoanData !== '' &&  privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== undefined && privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'] !== 0) {
                detailedInfo.newPrivateInterestExpenseVal = -privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingInterest'];
              } else {
                detailedInfo.newPrivateInterestExpenseVal = 0;
              }
              if ( privateNewHigherEdLoanData !== undefined && privateNewHigherEdLoanData !== '' &&  privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== undefined && privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'] !== 0) {
                detailedInfo.newPrivateInterestPrincipalVal = -privateNewHigherEdLoanData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.newPrivateInterestPrincipalVal = 0;
              }
              detailedInfo.privateNewHigherStudentsLoanPaymentsVal = detailedInfo.newPrivateInterestExpenseVal + detailedInfo.newPrivateInterestPrincipalVal;
              if (detailedInfo.privateNewHigherStudentsLoanPaymentsVal !== 0) {
                detailedInfo.newPrivateInterestExpenseCondition = 1;
              }

              //Spouse education Loan values
              detailedInfo.spouseAvarageStudentLoanBalVal = 0;
              if ( moduleServices.module10Data !== undefined && selectedYear < moduleServices.module10Data.marriege_year && moduleServices.module10Data.marriege_year > 0) {
                if ( spouseEdLoanData !== undefined && spouseEdLoanData !== '' &&
                   spouseEdLoanData['Summation Data'][selectedYear] !== undefined &&
                  spouseEdLoanData['Summation Data'][selectedYear] !== '' &&
                  spouseEdLoanData['Summation Data'][selectedYear]['BeginningBalance'] > 0) {
                  detailedInfo.spouseAvarageStudentLoanBalVal = -spouseEdLoanData['Summation Data'][selectedYear]['BeginningBalance'];
                }
              } else {
                if ( spouseEdLoanData !== undefined && spouseEdLoanData !== '' &&
                   spouseEdLoanData['Fed Sub Higher Education'][selectedYear] !== undefined &&
                  spouseEdLoanData['Fed Sub Higher Education'][selectedYear] !== '' &&
                  spouseEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] > 0) {
                  detailedInfo.spouseAvarageStudentLoanBalVal = -spouseEdLoanData['Fed Sub Higher Education'][selectedYear]['Months Total Balance'] / 12;
                } else {
                  detailedInfo.spouseAvarageStudentLoanBalVal = 0;
                }
              }
              detailedInfo.spouseAvarageStudentLoanBalCondition = 0;
              if (detailedInfo.spouseAvarageStudentLoanBalVal > 0) {
                detailedInfo.spouseAvarageStudentLoanBalCondition = 1;
              }

              detailedInfo.spouseAnnualInterestRateCondition = 0;
              if ( spouseEdLoanData !== undefined && spouseEdLoanData !== '' &&
                 spouseEdLoanData['Annual Interest Rate'] !== undefined &&
                spouseEdLoanData['Annual Interest Rate'] > 0) {
                detailedInfo.spouseAnnualInterestRateVal = spouseEdLoanData['Annual Interest Rate'];
                detailedInfo.spouseAnnualInterestRateCondition = 1;
              }

              detailedInfo.spouseStudentLoanInterestExpenseCondition = 0;
              if ( spouseEdLoanData !== undefined && spouseEdLoanData !== '' &&
                 spouseEdLoanData['Summation Data'][selectedYear] !== undefined &&
                spouseEdLoanData['Summation Data'][selectedYear] !== '' &&
                spouseEdLoanData['Summation Data'][selectedYear]['CumulativeInterest'] > 0) {
                detailedInfo.spouseStudentLoanInterestExpenseVal = spouseEdLoanData['Summation Data'][selectedYear]['CumulativeInterest'];
              } else {
                detailedInfo.spouseStudentLoanInterestExpenseVal = 0;
              }
              if ( spouseEdLoanData !== undefined && spouseEdLoanData !== '' &&
                 spouseEdLoanData['Summation Data'][selectedYear] !== undefined &&
                spouseEdLoanData['Summation Data'][selectedYear] !== '' &&
                spouseEdLoanData['Summation Data'][selectedYear]['CumulativePrincipal'] > 0) {
                detailedInfo.spouseStudentLoanInterestPrincipalVal = spouseEdLoanData['Summation Data'][selectedYear]['CumulativePrincipal'];
              } else {
                detailedInfo.spouseStudentLoanInterestPrincipalVal = 0;
              }
              detailedInfo.spouseStudentLoanPaymentsVal = detailedInfo.spouseStudentLoanInterestExpenseVal + detailedInfo.spouseStudentLoanInterestPrincipalVal;
              if (detailedInfo.spouseStudentLoanPaymentsVal !== 0) {
                detailedInfo.spouseStudentLoanInterestExpenseCondition = 1;
              }

              // setShowDetailsPopupWide( true );
              setOpenDialog(true);
              setOpenDialogName('showDetailPopupWide');
              break;
            case 'Mortgages':
              let mortgage1Data = moduleServices.mortgage1CompleteData();
              let mortgage2Data = moduleServices.mortgage2CompleteData();
              detailedInfo.mortgageLoan1Condition = 0;
              detailedInfo.mortgageLoan1YearCondition = 0;

              if ( mortgage1Data !== undefined && mortgage1Data !== '' &&
                 mortgage1Data['Summation Data'][selectedYear] !== undefined &&
                parseFloat(mortgage1Data['Summation Data'][selectedYear]['LaggingInterest']) > 0) {
                detailedInfo.mortgageLoan1Interest = mortgage1Data['Summation Data'][selectedYear]['LaggingInterest'];
                detailedInfo.mortgageLoan1Principal = mortgage1Data['Summation Data'][selectedYear]['LaggingPrincipal'];
                detailedInfo.mortgageLoan1Value = mortgage1Data['Mortage1'][selectedYear]['Months Total Balance'] / 12;
                detailedInfo.mortgageLoan1InterestRate = parseFloat(moduleServices.module13Data.rate_of_mortgage_in_percentage);

                detailedInfo.mortgageLoan1LoanPayments = mortgage1Data['Summation Data'][selectedYear]['LaggingInterest'] + mortgage1Data['Summation Data'][selectedYear]['LaggingPrincipal'];
                if (detailedInfo.mortgageLoan1LoanPayments !== 0) {
                  detailedInfo.mortgageLoan1Condition = 1;
                }

                if ( moduleServices.module13Data !== undefined &&
                   moduleServices.module13Data.year_of_purchase !== undefined && moduleServices.module13Data.year_of_purchase === selectedYear) {
                  detailedInfo.mortgageLoan1InterestExpensesInPurchaseYear = (detailedInfo.mortgageLoan1Value / 100) * parseFloat(moduleServices.module13Data.rate_of_mortgage_in_percentage);
                  if ( mortgage1Data !== undefined && mortgage1Data !== '' && mortgage1Data['Prepaid Interest'] !== undefined) {
                    detailedInfo.mortgageLoan1InterestPaidUpfrontClose = mortgage1Data['Prepaid Interest'];
                  } else {
                    detailedInfo.mortgageLoan1InterestPaidUpfrontClose = 0;
                  }

                  detailedInfo.mortgageLoan1YearCondition = 1;
                }
              }


              detailedInfo.mortgageLoan2Condition = 0;
              detailedInfo.mortgageLoan2YearCondition = 0;
              if ( mortgage2Data !== undefined && mortgage2Data !== '' &&
                 mortgage2Data['Summation Data'][selectedYear] !== undefined &&
                parseFloat(mortgage2Data['Summation Data'][selectedYear]['LaggingInterest']) > 0) {
                detailedInfo.mortgageLoan2Interest = mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'];
                detailedInfo.mortgageLoan2Principal = mortgage2Data['Summation Data'][selectedYear]['LaggingPrincipal'];
                detailedInfo.mortgageLoan2Value = mortgage2Data['Mortage2'][selectedYear]['Months Total Balance'] / 12;
                detailedInfo.mortgageLoan2InterestRate = parseFloat(moduleServices.module14Data.rateOfTheMortgage);

                detailedInfo.mortgageLoan2LoanPayments = mortgage2Data['Summation Data'][selectedYear]['LaggingInterest'] + mortgage2Data['Summation Data'][selectedYear]['LaggingPrincipal'];
                if (detailedInfo.mortgageLoan2LoanPayments !== 0) {
                  detailedInfo.mortgageLoan2Condition = 1;
                }

                if ( moduleServices.module14Data !== undefined &&
                   moduleServices.module14Data.year_of_purchase !== undefined && moduleServices.module14Data.year_of_purchase === selectedYear) {
                  detailedInfo.mortgageLoan2InterestExpensesInPurchaseYear = (detailedInfo.mortgageLoan2Value / 100) * detailedInfo.mortgageLoan2InterestRate;
                  if ( mortgage2Data !== undefined && mortgage2Data !== '' && mortgage2Data['Inv Prepaid Interest'] !== undefined) {
                    detailedInfo.mortgageLoan2InterestPaidUpfrontClose = mortgage2Data['Inv Prepaid Interest'];
                  } else {
                    detailedInfo.mortgageLoan2InterestPaidUpfrontClose = 0;
                  }

                  detailedInfo.mortgageLoan2YearCondition = 1;
                }
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('mortgage-interestexpenses');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            case 'Existing Credit Card Debt':
              let existingCreditCardDebtData = moduleServices.existingCreditCardDebtCompleteData();
              detailedInfo.creditCardInterestExpensesCondition = 0;
              if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' &&
                 existingCreditCardDebtData['Credit Card Data'] !== undefined &&
                 existingCreditCardDebtData['Credit Card Data'][selectedYear] !== undefined &&
                 existingCreditCardDebtData['Credit Card Data'][selectedYear]['Months Total Balance'] !== undefined &&
                existingCreditCardDebtData['Credit Card Data'][selectedYear]['Months Total Balance'] !== '') {
                detailedInfo.totalInterestExpenses = existingCreditCardDebtData['Credit Card Data'][selectedYear]['Months Total Balance'] / 12;
              } else {
                detailedInfo.totalInterestExpenses = 0;
              }
              if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' &&
                 existingCreditCardDebtData['Annual Interest Rate'] !== undefined &&
                existingCreditCardDebtData['Annual Interest Rate'] !== '') {
                detailedInfo.annualInterestRateCcard = existingCreditCardDebtData['Annual Interest Rate'];
              } else {
                detailedInfo.annualInterestRateCcard = 0;
              }

              //Interest and Principal amounts
              // console.log('existingCreditCardDebtData',existingCreditCardDebtData);
              if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' &&
                 existingCreditCardDebtData['Summation Data'] !== undefined &&
                existingCreditCardDebtData['Summation Data'] !== '' &&
                 existingCreditCardDebtData['Summation Data'][selectedYear] !== undefined &&
                existingCreditCardDebtData['Summation Data'][selectedYear] !== '') {
                detailedInfo.creditCardInterestExpense = -existingCreditCardDebtData['Summation Data'][selectedYear]['LaggingInterest'];
                detailedInfo.creditCardInterestPrincipal = -existingCreditCardDebtData['Summation Data'][selectedYear]['LaggingPrincipal'];
              } else {
                detailedInfo.creditCardInterestExpense = 0;
                detailedInfo.creditCardInterestPrincipal = 0;
              }

              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&
                 incomeStatementData[selectedYear] !== undefined &&
                 incomeStatementData[selectedYear]['InterestExpenses']['Existing Credit Card Debt'] !== undefined &&
                incomeStatementData[selectedYear]['InterestExpenses']['Existing Credit Card Debt'] !== '') {
                detailedInfo.creditCardInterestExpenses = incomeStatementData[selectedYear]['InterestExpenses']['Existing Credit Card Debt'];
                detailedInfo.creditCardInterestExpensesCondition = 1;
              } else {
                detailedInfo.creditCardInterestExpenses = 0;
              }

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('existingcreditcard-interestexpenses');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;

            case 'totalExpenses':
              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['Total Interest Expenses'] !== undefined) {
                detailedInfo.totalInterestExpenses = incomeStatementData[selectedYear]['Total Interest Expenses'];
              } else {
                detailedInfo.totalInterestExpenses = 0;
              }

              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['Total Living Expenses'] !== undefined) {
                detailedInfo.totalLivingExpenses = incomeStatementData[selectedYear]['Total Living Expenses'];
              } else {
                detailedInfo.totalLivingExpenses = 0;
              }

              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['Total Car Expenses'] !== undefined) {
                detailedInfo.totalCarExpenses = incomeStatementData[selectedYear]['Total Car Expenses'];
              } else {
                detailedInfo.totalCarExpenses = 0;
              }

              if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['Total Real Estate Expenses'] !== undefined) {
                detailedInfo.totalRealEstateExpenses = incomeStatementData[selectedYear]['Total Real Estate Expenses'];
              } else {
                detailedInfo.totalRealEstateExpenses = 0;
              }

              detailedInfo.TotalExpenses = detailedInfo.totalInterestExpenses + detailedInfo.totalLivingExpenses + detailedInfo.totalCarExpenses + detailedInfo.totalRealEstateExpenses;

              // setOpenGrossIncomePopup( true );
              setOpenGrossIncomeModel('total-expenses');
              setOpenDialog(true);
              setOpenDialogName('showDetailPopup');
              break;
            default:
              break;
          }
          break;
        /* End of Interest Expenses */

        case 'NetIncome':
          if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['Income After Taxes'] !== undefined) {
            detailedInfo.incomeAfterTaxes = incomeStatementData[selectedYear]['Income After Taxes'];
          } else {
            detailedInfo.disposableIncome = 0;
          }

          if ( incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['Total Expenses'] !== undefined) {
            detailedInfo.totalExpenses = incomeStatementData[selectedYear]['Total Expenses'];
          } else {
            detailedInfo.totalExpenses = 0;
          }

          // if(  incomeStatementData !== undefined && incomeStatementData !== '' &&  incomeStatementData[selectedYear]['Total Investment Property Sale'] !== undefined  ){
          //     detailedInfo.totalInvestmentSale = incomeStatementData[selectedYear]['Total Investment Property Sale'];  
          // } else {
          //     detailedInfo.totalInvestmentSale = 0;        
          // }

          // Show Portion
          // let nb = $('div.modal-backdrop').length;
          // if (nb <= 1) {
          //   $('.net-income').show().siblings().hide();
          //   $('#showDetailPopup').modal('show');
          // }
          // setOpenGrossIncomePopup( true );
          setOpenGrossIncomeModel('net-income');
          setOpenDialog(true);
          setOpenDialogName('showDetailPopup');

          break;

        default:
          break;
      }
      setDetailedInfo(detailedInfo);
    }
  }

  return (
    <div className={classes.modalBodyData}>
      <div className={classes.incomeSheetModal}>
        <div className={classes.popupTable}>
          <table className={classes.incomeSheetTable}>
            <thead >
              <tr className={classes.topIncomeBg}>
                <th>Annual Income Statement</th>
                <th>&nbsp;</th>
                {
                  rangeList.map((range) => (
                    <th>{range + (dispalyObj.year)}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              <tr className={classNames(classes.blankSpace, classes.emptyRowdata)}>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Income</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj.incomeStatementGrossIncomeHeadings && Object.keys(dispalyObj.incomeStatementGrossIncomeHeadings).map((grossTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {displayTitle(1, grossTitle)}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>

                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Gross Income'][grossTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Gross Income', grossTitle) }}>
                            
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }

              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Income</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[1]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Total Income'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Taxes</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Taxes</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[2]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Total Taxes'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Taxes', 'Total Taxes') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Income After Taxes</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[3]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Income After Taxes'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Income After Taxes', 'Income After Taxes') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Living Expenses</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj.incomeStatementLivingExpensesHeadings && Object.keys(dispalyObj.incomeStatementLivingExpensesHeadings).map((livingExpensesTitle) => (
                  (livingExpensesTitle !== 'Car Payment' && livingExpensesTitle !== 'Car Maintenance' && livingExpensesTitle !== 'Car Insurance' && livingExpensesTitle !== 'Gas' && livingExpensesTitle !== 'Home Property Expenses' && livingExpensesTitle !== 'Home Property Taxes' && livingExpensesTitle !== 'Rental Operating Expenses' && livingExpensesTitle !== 'Rental Property Taxes') && (
                    <tr>
                      {livingExpensesTitle !== 'Rent' && (
                        <td className={classes.subHeadingData}>
                          {livingExpensesTitle === 'Clothing' ? 'Clothing & Personal Care' : livingExpensesTitle}
                        </td>)}
                      {livingExpensesTitle === 'Rent' && (
                        <td className={classes.subHeadingData}>
                          {"Rent (incl. Renter's Insurance)"}
                        </td>)}
                      <td className={classes.lightBlueBg}>&nbsp;</td>
                      {
                        rangeList.map((range) => {
                          const value = dispalyObj.incomeData[range + (dispalyObj.year)].livingExpenses[livingExpensesTitle];
                          return (
                            <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'LivingExpenses', livingExpensesTitle) }}>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  )
                ))
              }
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Living Expenses</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[4]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Total Living Expenses'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Car Expenses</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj.incomeStatementLivingExpensesHeadings && Object.keys(dispalyObj.incomeStatementLivingExpensesHeadings).map((livingExpensesTitle) => (
                  (livingExpensesTitle === 'Car Payment' || livingExpensesTitle === 'Car Maintenance' || livingExpensesTitle === 'Car Insurance' || livingExpensesTitle === 'Gas') && (
                    <tr>
                      <td className={classes.subHeadingData}>
                        {livingExpensesTitle}
                      </td>
                      <td className={classes.lightBlueBg}>&nbsp;</td>
                      {
                        rangeList.map((range) => {
                          const value = dispalyObj.incomeData[range + (dispalyObj.year)].livingExpenses[livingExpensesTitle];
                          return (
                            <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'LivingExpenses', livingExpensesTitle) }}>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  )
                ))
              }
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Car Expenses</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[5]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Total Car Expenses'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Real Estate Expenses</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.incomeStatementLivingExpensesHeadings && Object.keys(dispalyObj.incomeStatementLivingExpensesHeadings).map((livingExpensesTitle) => (
                  (livingExpensesTitle === 'Home Property Expenses' || livingExpensesTitle === 'Home Property Taxes' || livingExpensesTitle === 'Rental Operating Expenses' || livingExpensesTitle === 'Rental Property Taxes') && (
                    <tr>
                      <td className={classes.subHeadingData}>
                        {livingExpensesTitle}
                      </td>
                      <td className={classes.lightBlueBg}>&nbsp;</td>
                      {
                        rangeList.map((range) => {
                          const value = dispalyObj.incomeData[range + (dispalyObj.year)].livingExpenses[livingExpensesTitle];
                          return (
                            <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Real Estate Expenses', livingExpensesTitle) }}>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  )
                ))
              }
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Real Estate Expenses</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[6]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Total Real Estate Expenses'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Loan Payments</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.incomeStatementInterestExpensesHeadings && Object.keys(dispalyObj.incomeStatementInterestExpensesHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.incomeData[range + (dispalyObj.year)].InterestExpenses[livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'InterestExpenses', livingExpensesTitle) }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }

              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Loan Payments</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[7]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Total Interest Expenses'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Expenses</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[8]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Total Expenses'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'InterestExpenses', 'totalExpenses') }}>

                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Net Income</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[9]}
                  />
                </td>
                {
                  dispalyObj.incomeData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.incomeData[range + (dispalyObj.year)]['Net Income'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'NetIncome', 'NetIncome') }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>

            </tbody>
          </table>
        </div>
      </div>

      {/* {openIncomeEarnedDuringHigher && <IncomeStatementPopup detailedInfo={detailedInfo} openIncomeEarnedDuringHigher ={openIncomeEarnedDuringHigher} setOpenIncomeEarnedDuringHigher={setOpenIncomeEarnedDuringHigher}/>}
        {openGrossIncomePopup && <IncomeStatementPopup detailedInfo={detailedInfo} openGrossIncomePopup ={openGrossIncomePopup} setOpenGrossIncomePopup={setOpenGrossIncomePopup} openGrossIncomeModel={openGrossIncomeModel} assumptionsInfo={assumptionsInfo}/>}
        {openHomePropertyTaxesExpensesPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openHomePropertyTaxesExpensesPopup ={openHomePropertyTaxesExpensesPopup} setOpenHomePropertyTaxesExpensesPopup={setOpenHomePropertyTaxesExpensesPopup} openHomePropertyTaxesExpensesModel={openHomePropertyTaxesExpensesModel}/>}
        {openDetailPopup401kPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openDetailPopup401kPopup ={openDetailPopup401kPopup} setOpenDetailPopup401kPopup={setOpenDetailPopup401kPopup}/>}
        {openShowDetailsPopupFullPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openShowDetailsPopupFullPopup ={openShowDetailsPopupFullPopup} setOpeShowDetailsPopupFullPopup={setOpeShowDetailsPopupFullPopup}/>}
        {openMortgageDeductionsPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openMortgageDeductionsPopup ={openMortgageDeductionsPopup} setOpenMortgageDeductionsPopup={setOpenMortgageDeductionsPopup}/>}
        {openHomePropertyTaxesDeductionsPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openHomePropertyTaxesDeductionsPopup ={openHomePropertyTaxesDeductionsPopup} setOpenHomePropertyTaxesDeductionsPopup={setOpenHomePropertyTaxesDeductionsPopup}/>}
        {openShowDetailPopupExemptionsPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openShowDetailPopupExemptionsPopup ={openShowDetailPopupExemptionsPopup} setOpenShowDetailPopupExemptionsPopup={setOpenShowDetailPopupExemptionsPopup}/>}
        {openTaxCreditsDetailPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openTaxCreditsDetailPopup ={openTaxCreditsDetailPopup} setOpenTaxCreditsDetailPopup={setOpenTaxCreditsDetailPopup} openTaxCreditsDetailModel={openTaxCreditsDetailModel}/>}
        {openChildTaxCreditPopup && <IncomeStatementPopup detailedInfo={detailedInfo} openChildTaxCreditPopup ={openChildTaxCreditPopup} setOpenChildTaxCreditPopup={setOpenChildTaxCreditPopup} />}
        {showDetailsPopupWide && <IncomeStatementPopup detailedInfo={detailedInfo} showDetailsPopupWide ={showDetailsPopupWide} setShowDetailsPopupWide={setShowDetailsPopupWide} />}
        {taxOnSaleDetailPopup && <IncomeStatementPopup detailedInfo={detailedInfo} taxOnSaleDetailPopup ={taxOnSaleDetailPopup} setTaxOnSaleDetailPopup={setTaxOnSaleDetailPopup} />}
        {gainDetailPopup && <IncomeStatementPopup detailedInfo={detailedInfo} gainDetailPopup ={gainDetailPopup} setGainDetailsPopup={setGainDetailsPopup} />}
        {educationTuitionPopup && <IncomeStatementPopup detailedInfo={detailedInfo} educationTuitionPopup ={educationTuitionPopup} setEducationTuitionPopup={setEducationTuitionPopup} />}
        {roomandboardPopup && <IncomeStatementPopup detailedInfo={detailedInfo} roomandboardPopup ={roomandboardPopup} setRoomandboardpopup={setRoomandboardpopup} />}
        {livingExpensesPortionPopup && <IncomeStatementPopup detailedInfo={detailedInfo} livingExpensesPortionPopup ={livingExpensesPortionPopup} setLivingExpensesPortionPopup={setLivingExpensesPortionPopup}  />}
        {livingExpensesPortionOnYearPopup && <IncomeStatementPopup detailedInfo={detailedInfo} livingExpensesPortionOnYearPopup ={livingExpensesPortionOnYearPopup} setLivingExpensesPortionOnYearPopup={setLivingExpensesPortionOnYearPopup}  />} */}

      {(openDialog
      ) &&
        <IncomeStatementPopup
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          openDialogName={openDialogName}
          detailedInfo={detailedInfo}
          openGrossIncomeModel={openGrossIncomeModel}
          assumptionsInfo={assumptionsInfo}
          openHomePropertyTaxesExpensesModel={openHomePropertyTaxesExpensesModel}

        />}

    </div>
  );
};

IncomeStatement.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(IncomeStatement);