import timelineFi from '../../assets/img/build-career-sketch/financial_indep.png';
import timelineInvest from '../../assets/img/build-career-sketch/investments_icon.png';
import timelineCa from '../../assets/img/build-career-sketch/career_advancement.png';
import timelineCp from '../../assets/img/build-career-sketch/career_path.png';
import timelineHe from '../../assets/img/build-career-sketch/higher_education.png';
import timelineMhe from '../../assets/img/build-career-sketch/more_high_edu.png';
import timelineFhe from '../../assets/img/build-career-sketch/further_higher_efducation.png';
import timelineAca from '../../assets/img/build-career-sketch/additional_career.png';
import timelineFca from '../../assets/img/build-career-sketch/further_career.png';
import timelineFamily from '../../assets/img/build-career-sketch/family_icon.png';
import timelineVacation from '../../assets/img/build-career-sketch/vacation.png';
import timelineCar from '../../assets/img/build-career-sketch/buying_car.png';
import timelineHome from '../../assets/img/build-career-sketch/buying_home.png';
import timelineRei from '../../assets/img/build-career-sketch/rei_icon.png';
import timelineExisting from '../../assets/img/build-career-sketch/student_loan.png';

const styles = ( theme ) => ( {
  dropableArea: {
    display: 'block',
    overflowX: 'scroll',
    overflowY: 'hidden',
    zIndex: '9',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      left: '0',
      borderRadius: '100%',
      background: '#808282',
      width: '27px',
      height: '27px',
      bottom: '108px',
    },
  },
  dropArea: {
    minWidth: '99.8%',
    padding: '0',
    margin: '0',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'inline-flex',
    '& li': {
      height: '285px',
      width: '149px',
      display: 'inline-block',
      verticalAlign: 'middle',
      padding: '0',
      margin: '0 0 0 -42px !important',
      position: 'relative',
      cursor: 'pointer',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: 'none !important',
      '&:first-child': {
        margin: '0px !important',
      },
      '& p': {
        color: '#666',
      },
      '& span': {
        display: 'block',
        position: 'relative',
        margin: '0 auto 45px auto',
        opacity: '0.8',
        backgroundSize: 'cover',
        fontSize: '0px',
        lineHeight: '216px',
        color: '#ffffff',
        width: '130px',
        height: '130px',
        '&::after': {
          bottom: '-70px',
          height: '70px',
          border: '1px solid #808282',
          left: '50%',
          content: '""',
          position: 'absolute',
        },
      },
      '&:hover': {
        '& span': {
          fontSize: '14px',
          lineHeight: '20px',
          padding: '0 15px',
          opacity: '1',
          borderRadius: '100%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          boxSizing: 'border-box',
        },
      },
      '&:active': {
        '& span': {
          fontSize: '14px',
          lineHeight: '20px',
          padding: '0 15px',
          opacity: '1',
          borderRadius: '100%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          boxSizing: 'border-box',
        },
      },
    },
    '&::before': {
      bottom: '120px',
      content: '""',
      position: 'absolute',
      left: '0',
      width: '100%',
      border: '1px solid #808282',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 'auto',
      right: '-2px',
      borderRadius: '100%',
      background: '#808282',
      width: '27px',
      height: '27px',
      bottom: '108px',
    },
  },
  timelineDetailsContent: {
    textAlign: 'center',
    position: 'relative',
  },
  timelineDetails: {
    display: 'inline-block',
    textAlign: 'left',
    padding: '0 0 0 0',
    margin: '0 auto',
    position: 'relative',
    marginTop: '23px',
    '& h2': {
      fontSize: '10px',
      height: '40px',
      textTransform: 'uppercase',
      borderRadius: '15px',
      padding: '5px 7px',
      display: 'flex',
      alignItems: 'center',
      width: '104px',
      justifyContent: 'center',
      marginTop: '0px',
      margin: '20px 0 8px 0',
      textAlign: 'center',
      color: '#323232',
      fontFamily: 'MuseoSans-500',
      visibility: 'visible',
      opacity: '1',
      fontWeight: '500',
      background: '#aecbc1',
      lineHeight: ' 1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      textAlign: 'center',
      fontSize: '12px',
      margin: '0 0 10px',
    },
  },
  timelineFi: {
    '& span': {
      backgroundImage: `url(${timelineFi})`,
    },
    '& h2': {
      background: '#aecbc1',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#3e846c',
      },
    },
  },
  timelineInvest: {
    '& span': {
      backgroundImage: `url(${timelineInvest})`,
    },
    '& h2': {
      background: '#ead8b2',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#d6b56d',
      },
    },
  },
  timelineCa: {
    '& span': {
      backgroundImage: `url(${timelineCa})`,
    },
    '& h2': {
      background: '#6cbaea',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#4babe6',
      },
    },
  },
  timelineCp: {
    '& span': {
      backgroundImage: `url(${timelineCp})`,
    },
    '& h2': {
      background: '#adcde3',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#3287bb',
      },
    },
  },
  timelineHe: {
    '& span': {
      backgroundImage: `url(${timelineHe})`,
    },
    '& h2': {
      background: '#cbdbb3',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#84a84d',
      },
    },
  },
  timelineMhe: {
    '& span': {
      backgroundImage: `url(${timelineMhe})`,
    },
    '& h2': {
      background: '#aecbc1',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#6daf8d',
      },
    },
  },
  timelineFhe: {
    '& span': {
      backgroundImage: `url(${timelineFhe})`,
    },
    '& h2': {
      background: '#aecbc1',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#a5bd9a',
      },
    },
  },
  timelineAca: {
    '& span': {
      backgroundImage: `url(${timelineAca})`,
    },
    '& h2': {
      background: '#93cee1',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#93cfe1',
      },
    },
  },
  timelineFca: {
    '& span': {
      backgroundImage: `url(${timelineFca})`,
    },
    '& h2': {
      background: '#9ea6c6',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#8790b8',
      },
    },
  },
  timelineFamily: {
    '& span': {
      backgroundImage: `url(${timelineFamily})`,
    },
    '& h2': {
      background: '#9dd4db',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#32adbb',
      },
    },
  },
  timelineVacation: {
    '& span': {
      backgroundImage: `url(${timelineVacation})`,
    },
    '& h2': {
      background: '#eac1b5',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#cb6c4d',
      },
    },
  },
  timelineCar: {
    '& span': {
      backgroundImage: `url(${timelineCar})`,
    },
    '& h2': {
      background: '#fbd1a7',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#f6a85a',
      },
    },
  },
  timelineHome: {
    '& span': {
      backgroundImage: `url(${timelineHome})`,
    },
    '& h2': {
      background: '#f8c1a9',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#f08b5e',
      },
    },
  },
  timelineRei: {
    '& span': {
      backgroundImage: `url(${timelineRei})`,
    },
    '& h2': {
      background: '#adbfcf',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#33688e',
      },
    },
  },
  timelineExisting: {
    '& span': {
      backgroundImage: `url(${timelineExisting})`,
    },
    '& h2': {
      background: '#e8dfcc',
    },
    '&:hover, &:active': {
      '& span': {
        background: '#c7af80',
      },
    },
  },
  tooltipFi: {
    top: '-166px',
    left: '-74px',
    maxWidth: '282px',
    borderRadius: '20px',
    zIndex: '9',
    pointerEvents: 'all',
    cursor: 'auto',
    position: 'absolute',
    background: '#fff',
    border: '3px solid #0069aa',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down( 'md' )]: {
      left: '10px',
    },
    '& p': {
      fontSize: '16px',
      padding: '10px 20px',
      textAlign: 'left',
      lineHeight: '24px',
      fontFamily: 'MuseoSans-300',
      color: '#000',
      display: 'inline-block',
      margin: '0',
    },
    '&::before': {
      left: '30px',
      content: '""',
      borderWidth: '30px 29px 0 30px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px',
      right: '0',
      top: 'auto',
      transform: 'none',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
      zIndex: '9999',
    },
    '&::after': {
      left: '30px',
      content: '""',
      right: '0',
      top: 'auto',
      transform: 'none',
      zIndex: '9998',
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  timelineHoverDeatils: {
    background: '#fff',
    borderRadius: '10px',
    border: '1px solid #333333 !important',
    textAlign: 'center',
    width: '250px',
    position: 'absolute',
    bottom: '96%',
    zIndex: '999',
    display: 'inline-block',
    left: '18px',
    padding: '5px',
    color: '#333',
    height: '220px',
    overflow: 'hidden',
    overflowY: 'auto',
    '& h6': {
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '1.1',
      marginTop: '10px',
      marginBottom: '10px',
    },
    '& h4': {
      position: 'absolute',
      top: '23%',
      fontSize: '16px',
      fontWeight: 300,
      color: 'inherit',
      lineHeight: '24px',
    },
  },
  clearChange: {
    position: 'absolute',
    right: '0',
    bottom: '5px',
    '& span': {
      cursor: 'pointer',
      '& img': {
        width: '33px',
        padding: '5px',
        verticalAlign: 'middle',
        boxSizing: 'border-box',
      },
    },
  },
  loadScenatio: {
    position: 'absolute',
    right: '0',
    bottom: '5px',
    '& span': {
      cursor: 'pointer',
      '& img': {
        width: '33px',
        padding: '5px',
        verticalAlign: 'middle',
        boxSizing: 'border-box',
      },
    },
  },
  pointerNone: {
    pointerEvents:'none',
    opacity:'0.5'
  }
} );

export default styles;
