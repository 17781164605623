import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import i1 from '../../assets/img/life-event-library/investments-slide1.jpg';
import i2 from '../../assets/img/life-event-library/investments-slide2.jpg';
import i3 from '../../assets/img/life-event-library/investments-slide4.jpg';
import i4 from '../../assets/img/life-event-library/investments-slide5.jpg';
import i5 from '../../assets/img/life-event-library/investments-slide6.jpg';

const LifeEventImageEight = ( props ) => {
  const { classes } = props;

  const getStartYear = () => {
    props.openNoAccessPopup( true );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={ `${classes.containerNew} ${classes.eventEightColor}` }>
      <div className={ classes.testimonilas }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Investments
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          My Short-Term Investments
                        </Typography>
                        <ul>
                          <li>Invest early to build more equity over your lifetime</li>
                          <li>Estimate how much money you can contribute each year, and how your contribution might grow</li>
                          <li>Enter how much money you want to accumulate before investing long-term.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Get Started</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ i1 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Investments
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          My Long-Term Investments
                        </Typography>
                        <ul>
                          <li>Enter how much you might contribute annually to long-term investments</li>
                          <li>By what percentage could your contributions grow over time?</li>
                          <li>Compare outcomes and identify your own best plan for building financial resources.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Get Started</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ i2 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Investments
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          My One-Time Gift
                        </Typography>
                        <ul>
                          <li>Do you anticipate receiving a one-time gift, such as an inheritance?</li>
                          <li>Inkwiry allows you to factor any potential gifts into your financial plan</li>
                          <li>See how putting your gift to work might improve your financial position.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Get Started</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ i3 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Investments
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Raise Your Awareness
                        </Typography>
                        <ul>
                          <li>Learn about different type of investments</li>
                          <li>Understand the inherent risks vs. rewards of investing</li>
                          <li>Check out QI to test your awareness about managing your money</li>
                          <li>Know the meaning of essential financial terms.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Find Out More</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ i4 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Investments
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Check Your Spaces
                        </Typography>
                        <ul>
                          <li>How do your investment goals affect different areas of your life?</li>
                          <li>Where is your comfort zone concerning risk?</li>
                          <li>How might investing benefit your situation over the long term?</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>see my spaces</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ i5 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

LifeEventImageEight.propTypes = {
  classes: PropTypes.object.isRequired,
  openNoAccessPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LifeEventImageEight );
