const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bannerCaption: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    webkitTransform: 'translateY(-50%)',
    mozTransform: 'translateY(-50%)',
    msTransform: 'translateY(-50%)',
    oTransform: 'translateY(-50%)',
    zIndex: '999',
    width: '100%',
    overflow: 'hidden',
  },
  bannerBlock: {
    position: 'relative',
    '& img': {
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        minHeight: '200px',
        objectFit: 'cover',
      },
    },
  },
  bannerImage: {
    position: 'relative',
  },
  bannerTitle: {
    position: 'static',
    top: '0',
    transform: 'translateY(0)',
    webkitTransform: 'translateY(0)',
    msTransform: 'translateY(0)',
    mozTransform: 'translateY(0)',
    background: 'none',
    margin: '0',
    textAlign: 'center',
    display: 'block',
    width: '100%',
    zIndex: '9999',
    '& h3': {
      color: theme.palette.common.white,
      padding: '0',
      lineHeight: '65px',
      fontFamily: '"MuseoSans-100"',
      fontSize: '54px',
      position: 'relative',
      margin: '0 auto',
      letterSpacing: '1.5px',
      '& span': {
        display: 'inline-block',
        width: '100%',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '50px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
    },
  },
  navigationContent: {
    padding: '60px 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 0',
    },
    textAlign: 'center',
    '& h2': {
      fontSize: '48px',
      lineHeight: '48px',
      fontFamily: '"MuseoSans-100"',
      margin: '0',
      padding: '0 0 36px 0',
      fontWeight: '500',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '23px',
        paddingBottom: '10px',
        lineHeight: '35px',
      },
    },
  },
  navigationDesc: {
    fontSize: '36px',
    lineHeight: '36px',
    fontFamily: '"MuseoSans-100"',
    color: theme.palette.common.blue,
    margin: '0',
    padding: '0 0 60px 0',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '33px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
      lineHeight: '26px',
      paddingBottom: '30px',
    },
  },
  navigationArrows: {
    '& p': {
      fontSize: '24px',
      lineHeight: '24px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.blue,
      margin: '0',
      padding: '0 0 75px 0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        padding: '0 0 35px 0',
      },
    },
    '& img': {
      maxWidth: '550px',
    },
  },
  navigationCircle: {
    '& > div': {
      margin: '0 auto',
      maxWidth: '100%',
      '& canvas': {
        width: '100%',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      transform: 'scale(0.8)',
    },
    '& img': {
      width: '100%',
      top: '0px',
      left: '0% !important',
      right: '0',
      margin: 'auto',
    },

  },
  circleImage: {
    width: '100%',
  },
  closeIcon: {
    color: '#fa9c22',
    opacity: '1',
    position: 'absolute',
    right: '0',
    textShadow: 'none',
    top: '-25px',
    zIndex: '9999999',
    marginTop: '-2px',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    outline: 'none',

  },
  modalVideoInner: {
    boxShadow: 'none',
    '& video': {
      width: '100%',
      height: '100%',
    },
    '& div': {
      boxShadow: 'none',
      overflowY: 'inherit',

    },
  },
  modalPopupVideo: {
    '& > div': {
      overflow: 'inherit',
    },
    '& > div > div': {
      background: 'transparent',
      boxShadow: 'none',
      overflow: 'inherit',
      maxWidth: '800px',
    },
  },
} );

export default styles;
