const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    color: '#333',
  },
  topImage: {
    '& img': {
      width: '100%',
      position: 'absolute',
      zIndex: '0',
      verticalAlign: 'middle',
      [theme.breakpoints.down('sm')]: {
        height: '240px',
      },
      [theme.breakpoints.down('xs')]: {
        background: '#004375',
        height: '180px',
      },
    },
  },
  mcsRow: {
    display: 'flex',
    padding: '20px 0 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0px 0px',
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mcsLogoImage: {
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a': {
      '& img': {
        maxWidth: '300px',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '200px',
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '300px',
        },
      },
    },
  },
  mcsLevelNum: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& h3': {
      color: '#fff',
      textTransform: 'uppercase',
      marginRight: '15px',
      marginTop: '0',
      marginBottom: '0',
      fontWeight: '500',
      fontSize: '24px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
  },
  infoIcon: {
    marginRight: '5px',
    borderRadius: '4px',
    padding: '6px 10px',
    display: 'inline-flex',
    cursor:'pointer',
    outline: '0',   
    '&:hover': {
      backgroundColor: '#333',
    },
    '& img': {
      width: '25px',
      verticalAlign: 'middle',
    },
  },
  iconImage: {
    width: '70px',
    verticalAlign: 'middle',
    [theme.breakpoints.down('md')]: {
      width: '40px',
    },
  },
  leftBlock: {
    paddingRight: '115px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
    },
  },
  mcsLevelTitle: {
    color: '#1f96e0',
    fontSize: '85px',
    fontFamily: 'MuseoSans-700',
    margin: '30px 0',
    fontWeight: '500',
    lineHeight: '1.1',
    [theme.breakpoints.down('xs')]: {
      fontSize: '50px',
    },
  },
  qPosition: {
    position: 'relative',
    paddingLeft: '15px',
  },
  questionBlock: {
    marginBottom: '20px',
    '& p': {
      fontSize: '16px',
      fontFamily: 'MuseoSans-100',
      lineHeight: '25px',
      margin: '0 0 10px',
    },
  },
  optionList: {
    marginTop: '0px',
    marginBottom: '10px',
    '& li': {
      fontSize: '16px',
      fontFamily: 'MuseoSans-300',
      lineHeight: '25px',
    },
  },
  noBottomMargin: {
    marginBottom: '0px !important',
  },
  blueColor: {
    color: '#0069aa',
    fontFamily: 'MuseoSans-300',
  },
  rightBlock: {
    paddingLeft: '30px',
    paddingTop: '150px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px',
      paddingTop: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
      paddingTop: '30px',
    },
  },
  questionsImg: {
    width: '100%',
    position: 'relative',
    textAlign: 'center',
  },
  mcsGraphic: {
    position: 'relative',
    // width: '50%',
    // transform: 'scale(0.5)',
    margin: '0 auto',
    padding: '150px 0px 100px 0px',
  },
  mcsGraphicCircle: {
    display: 'block',
    top: '0',
    left: '0',
    borderRadius: '100%',
    textAlign: 'center',
    padding: '5px 2px',
    border: '7px solid transparent',
    cursor: 'pointer',
    boxShadow: '0 0 6px 6px #ddd',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    '& h2': {
      color: '#83a74c',
      margin: '0',
      fontFamily: 'MuseoSans-700',
      fontWeight: '500',
      fontSize: '60px',
      lineHeight: '70px',
    },
    '& h3': {
      color: '#fff',
      margin: '0',
      fontSize: '23px',
      lineHeight: '30px',
      fontFamily: 'MuseoSans-100',
      fontWeight: 'normal',
      '& span': {
        display: 'inline-block',
        color: '#fff',
        lineHeight: '25px',
        margin: '0',
        fontSize: '30px',
        fontFamily: 'MuseoSans-500',
      },
    },
  },
  mcsCircleOne: {
    width: '200px',
    height: '200px',
    zIndex: '1',
    background: 'rgb(1,97,155,.9)',
    margin: '0 auto',
    '&:hover': {
      transform: 'scale(1.2)',
    },
  },
  mcsCircleTwo: {
    width: '200px',
    height: '200px',
    zIndex: '4',
    marginRight: '20px',
    background: 'rgba(143,176,94,.9)',
    '&:hover': {
      transform: 'scale(1.2)',
    },
    '& h2': {
      color: '#006838',
    },
  },
  mcsCircleThree: {
    width: '200px',
    height: '200px',
    zIndex: '4',
    background: 'rgba(70,158,213,.9)',
    '&:hover': {
      transform: 'scale(1.2)',
    },
    '& h2': {
      color: '#83a74c',
    },
  },
  schematicImage: {
    width: '350px',
    height: '350px',
    background: '#0069aa',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& p': {
      padding: '10px',
      display: 'flex',
      margin: 'auto',
      fontSize: '16px',
    },
  },
  startButton: {
    paddingTop: '40px',
    position:'relative',
    '& button': {
      padding: '7px 40px',
      background: '#8ba659',
      textTransform: 'uppercase',
      border: '1px solid #8ba659',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '17px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',
      letterSpacing: '1px',
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  },
  mcsBodyContent: {
    paddingBottom: '50px',
  },
  activities: {
    '& h4': {
      fontSize: '16px',
      color: '#0069aa',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      marginBottom: '5px',
    },
    '& p': {
      marginBottom: '0px',
      fontSize: '16px',
      fontFamily: 'MuseoSans-300',
      lineHeight: '25px',
      marginTop: '0px',
      '& span': {
        paddingLeft: '10px',
      },
    },
  },
  checkboxRadio: {
    margin: '3px 0px',
    '& label': {
      display: 'inline-block',
      maxWidth: '100%',
      position: 'relative',
      fontWeight: '400',
      padding: '0 0 0 30px',
      margin: '0',
      color: '#000',
      fontSize: '16px',
      '&::after': {
        background: '#fff',
        border: '1px solid #898d8f',
        content: '""',
        height: '13px',
        left: '0',
        position: 'absolute',
        top: '5px',
        width: '13px',
        boxSizing: 'border-box',
      },
      '& input[type=checkbox]': {
        display: 'none',
      },
    },
  },
  checkedEmployment: {
    '&::before': {
      borderColor: '#0069aa',
      borderImage: 'none',
      borderStyle: 'solid',
      borderWidth: '0 1px 1px 0',
      content: '""',
      display: 'block',
      left: '4px',
      opacity: '1',
      position: 'absolute',
      top: '5px',
      transform: 'rotate(35deg)',
      visibility: 'visible',
      width: '5px',
      height: '11px',
      zIndex: '9',
      boxSizing: 'border-box',
    },
  },
  myLevelProgress: {
    '& h4': {
      color: '#0069aa',
      fontSize: '18px',
      fontWeight: '500',
      textTransform: 'uppercase',
      marginBottom: '0px',
    },
  },
  progressPuzzle: {
    width: '90%',
    alignItems: 'center',
    display: 'flex',
  },
  progressPercent: {
    marginBottom: '0px',
  },
  puzzleBar: {
    border: 'none',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '10px',
    width: '100%',
    margin: '0px 0px 0px 10px',
    '& img': {
      height: '50px',
      textAlign: 'center',
      verticalAlign: 'middle',
    },
  },
  flexRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '-25px',
    flexWrap: 'wrap',
  },
  activeRow: {
    width: '70%',
    margin: '0 auto',
    position:'relative',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mcsFooter: {
    padding: '20px 0 15px',
    background: '#252525!important',
    alignItems: 'center',
    height: 'auto',
    color: '#fff',
    float: 'left',
    fontSize: '14px',
    left: '0',
    width: '100%',
    '& p': {
      marginTop: '0px',
    },
  },
  mcsFluid: {
    width: '100%',
    borderTop: '1px solid #555',
    margin: '0 auto',
    paddingRight: '15px',
    paddingLeft: '15px',
  },
  termLinks: {
    color: '#fff',
    marginBottom: '0',
    padding: '0px',
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
      fontSize: '16px!important',
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
  footerRow: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  copyRight: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& p': {
      textAlign: 'left',
      fontFamily: 'MuseoSans-100',
      fontSize: '16px',
      color: '#fff',
      margin: '0',
      lineHeight: '35px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '15px',
      },
    },
  },
  informedText: {
    '& p': {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      '& a': {
        '& img': {
          marginLeft: '8px',
          maxWidth: '100px',
          verticalAlign: 'top',
          border: '0',
        },
      },
    },
  },
  goButton: {
    padding: '1px 22px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    fontSize: '13px',
    borderRadius: '10px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    WebkitTransition: 'all .5s ease 0s',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    '&:hover': {
      background: '#8ca659',
      borderColor: '#8ca659',
    },
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        padding: '5px 10px',
      },
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
      textAlign: 'left',
      fontFamily: 'MuseoSans-300',
      '@media (max-width: 767px)': {
        fontSize: '14px',
        lineHeight: '20px',
        padding: '0px 5px',
      },
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiWalkthruOne: {
    maxWidth: '500px',
    top: '128px',
    right: '88px',
    '@media (max-width: 768px)': {
      top: '-90px',
      left: '85px',
    },
    '&::before': {
      top: '-29px',
      left: '43.7%',
      bottom: 'auto',
      transform: 'none',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '43%',
      right: 'auto',
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',   
    '@media (max-width: 767px)': {
      maxWidth: '200px',
    },
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
      borderWidth: '1px',
      '@media (max-width: 767px)': {
        fontSize: '11px',
        padding: '6px',
      },
    },
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  pwiWalkthruTwo: {
    maxWidth: '500px',
    top: '10px',
    left: '340px',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '50px',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '48px',
    },
  },
  pwiWalkthruFour: {
    top: '-200px',
    left: '0',
    right: 'auto',
    minWidth: '450px',
    '&::before': {
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
    },
    '&::after':{
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruFive:{
    top: '-130px',
    minWidth: '450px',
    '&::before': {
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
      left: '130px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
      },
    '&::after': {
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
      left: '130px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
    },
  },
  pwiWalkthruSix:{
    right: 'auto',
    left: '0',
    top: '-240px',
    maxWidth: '500px',
    '&::before': {
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
      },
    '&::after': {
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  walkthruVisible:{
    cursor: 'none',
    position: 'relative',
    zIndex: '9999',
    pointerEvents: 'none',
  },
  walkthruVisibleBlock: {
    background: '#fff',
    paddingLeft: '40px',
    marginLeft: '-10px',
    borderRadius: '4px',
    paddingBottom: '10px',
    cursor: 'none',
    position: 'relative',
    zIndex: '9999',
    pointerEvents: 'none',
  },
  walkthruVisibleDiv:{
    background:' #fff',
    margin:' 0 -15px',
    padding: '0 15px',
    borderRadius: '4px',
    cursor: 'none',
    position: 'relative',
    zIndex: '9999',
    pointerEvents: 'none',
  },
  pwiWalkthruThree:{
    top: '-220px',
    right: '0',
    '&::before': {
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
    },
    '&::after': {
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
      left: '60px',
      right: 'auto',
      top: 'auto',
      transform: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
});

export default styles;
