import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent
} from '@material-ui/core';

import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import YouTube from 'react-youtube';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { loader } from 'graphql.macro';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HCExporting from 'highcharts/modules/exporting';
import NumberFormat from 'react-number-format';
import styles from './styles';
import _ from 'lodash';

import sliderImage from '../../assets/img/life-event-library/financial-slider_new.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import menuImage from '../../assets/img/financial-independence/SideMenuBar.png';
import cashFlowTwo from '../../assets/img/financial-independence/CashFlow Movement_2.png';
import cashFlowOne from '../../assets/img/financial-independence/CashFlow Movement_1.png';
import netWorth from '../../assets/img/financial-independence/net-worth-chart_2.png';
import PwiSave from '../../components/pwi-save/pwi-save';
import IncomeGrowthGraphs from './annual-income-growth-tabs-common';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import fiShowMeGraphData from '../../graph-services/fi-show-me'
import * as moduleServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import MySavedNotes from './my-saved-notes';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import { confirmAlert } from 'react-confirm-alert';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import SummaryDocs from '../../components/documents/documents';
import messages from '../../utilities/pwi-popup-messages';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';


import scrollToComponent from 'react-scroll-to-component';
import IconBar from './IconBar';

const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');
const INCOME_GROWTH_GRAPH_DATA = loader('../../graphql/schema/financial-independence/getIncomeGrowthGraphData.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');


Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  colors: ['#389BD2', '#FF7322', '#A7A39E', '#FFBE00', '#2A70BF', '#58AD4B', '#005F89', '#B04400', '#63625D', '#A46E00', '#1A466F', '#366731', '#66AFDB', '#FF8F4E', '#BBB7B0', '#FFCB30'],
});

HCExporting(Highcharts);

const FI = (props) => {
  const { classes, history } = props;

  const opts = {
    host: 'https://www.youtube-nocookie.com',
    width: '780',
    height: '450',
    playerVars: {
      autoplay: 1,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  const fiIntialValues = {
    start_year: 0,
    long_term_growth_in_percentage: 0,
    inflation_in_percentage: 0,
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
    showmeGraph: false,
  };
  const currentYear = new Date().getFullYear();
  const { data: incomeGrowthGraphData } = useQuery(INCOME_GROWTH_GRAPH_DATA);

  
  const inflationGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[4];

  const [dropdownvalues, setDropdownValues] = React.useState(dropdownIntialValues);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [currentVideo, setCurrentVideo] = React.useState(false);
  const [showMeHoverActive, setShowMeHoverActive] = React.useState(false);
  const [awareHoverActive, setAwareHoverActive] = React.useState(false);
  const [compareHoverActive, setCompareHoverActive] = React.useState(false);
  const [savedHoverActive, setSavedHoverActive] = React.useState(false);
  const [updatedFIValues, setUpdatedFIValues] = React.useState(fiIntialValues);
  const [modalType, setModalType] = useState('');
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const joinInk = modalType === 'video' ? classes.pendinglistPopup : classes.instructionsPopup;
  const [loading, setLoading] = React.useState(true);
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [openPopup, setOpenPopup] = React.useState('');
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [showMeData, setShowMeData] = React.useState({});
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);

  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);

  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [isFocus, setIsFocus] = useState('');
  // const [loadCompareScenario, setLoadCompareScenario] = useState('');

  useEffect(() => {
    setWalkThruNo(1);
  }, []);


  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module1' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          const responseData = data;
          responseData.long_term_growth_in_percentage = parseFloat(responseData.long_term_growth_in_percentage).toFixed(2);
          responseData.inflation_in_percentage = parseFloat(responseData.inflation_in_percentage).toFixed(2);
          setUpdatedFIValues(data);
        } else {
          setActiveScenarioData({});
        }
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');       
      });
    },
    onError() {
      setActiveScenarioData({});
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedFIValues(fiIntialValues);
        }
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
  });

  useEffect(() => {
    getModuleData();
     // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setWalkThruNo(1);
  }, [])

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(()=>{
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module1' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'bottom', duration: 200 });
    }     
  },[loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(()=>{     
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module1' ) {
      dropdownvalues.savedNotes = true;    
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 }); 
    }
  },[myNotes]);
  

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const updateValue = (e, field, modalField, inputType = '') => {
    const valuesUpdated = { ...updatedFIValues };
    let newvalue = 0;
    if (inputType === 'start_year') {
      newvalue = (e.value !== undefined && e.value !== '') ? e.value : 0;
    }
    if (inputType === 'percentage') {
      newvalue = (e.value !== undefined && e.value !== '') ? e.value : 0;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    if ((field === 'long_term_growth_in_percentage') || (field === 'inflation_in_percentage')) {
      if (newvalue > 100) {
        setPopupOpen(true);
        setModalType(modalField);
        newvalue = 100;
      }
    }
    valuesUpdated[field] = newvalue;
    setUpdatedFIValues(valuesUpdated);
    const newupdateValue = { ...valuesUpdated };
    newupdateValue.long_term_growth_in_percentage = parseFloat(newupdateValue.long_term_growth_in_percentage);
    newupdateValue.inflation_in_percentage = parseFloat(newupdateValue.inflation_in_percentage);
    moduleServices.setModule1Data(newupdateValue);
  };

  const setFixedDecimal = (field) => {
    setIsFocus('');
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(fiShowMeGraphData);
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top'});
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setLoadScenarioName({});
    }
    setDropdownValues(dropValues);
  };

  const handleCurrentVideoId = (type) => {
    setModalType(type);
    setCurrentVideo('m1FDvn09MsA');
    setPopupOpen(true);
  };

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module1';
    objectParams.moduleNumber = 'module1Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    if (updatedFIValues.start_year <= 0) {
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3><b>Important Tip</b></h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <div>
                  <p>Every scenario that you build will be linked to the Start Year of the Financial Independence module. Before you can create and save a new scenario in this module or other modules, you need to have a scenario loaded in the Financial Independence module. Why do you need this? Well, without a Start Year, the FC would have no idea what 5, 10, 15 year timeline that you are looking to plan for.</p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
              <button
                type="button"
                onClick={onClose}
              >
                Go to Financial Independence module
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
      setPopupOpen(false);
    }else{
      setPopupOpen(true);
    }
  };


  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module1";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module1', 'quick-save', activeScenarioData);
      finalObject['module1Data'] = pwiObject.getfilteredPWIFI(moduleServices.module1Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  //   useEffect(() => {
  //   if (activeWalkThruSlide === 2) {
  //     scrollToComponent(document.getElementById('slide-one'), { offset: -230, align: 'top', duration: 500 });
  //     setBackgroundBlur( false ); 
  //   }
  //   if (activeWalkThruSlide === 3) {
  //     scrollToComponent(document.getElementById('slide-one'), { offset: -300, align: 'top', duration: 500 });
  //     setBackgroundBlur( true ); 
  //     setSlideCount(1);
  //   }
  //   if (activeWalkThruSlide === 4) {
  //     scrollToComponent(document.getElementById('slide-one'), { offset: -200, align: 'bottom', duration: 500 });
  //     setBackgroundBlur( false ); 

  //   }
  //   if (activeWalkThruSlide === 5 && activeWalkThruSlide === 6 ) {
  //     scrollToComponent(document.getElementById('slide-one'), { offset: 0, align: 'bottom', duration: 0 });
  //     setBackgroundBlur( false ); 
  //   }
  //   if ( activeWalkThruSlide === 7) {
  //     scrollToComponent(document.getElementById('slide-four'), { offset: -260, align: 'bottom', duration: 500 });
  //     setBackgroundBlur( true );
  //     setSlideCount(2);
  //   }
  //   if ( activeWalkThruSlide === 8) {
  //     scrollToComponent(document.getElementById('slide-five'), { offset: 420, align: 'bottom', duration: 500 });
  //     setBackgroundBlur( true );
  //     setSlideCount(3);
  //   }
  //   if ( activeWalkThruSlide === 9) {
  //     scrollToComponent(document.getElementById('slide-six'), { offset: -150, align: 'bottom', duration: 500 });
  //     setBackgroundBlur( true );
  //     setSlideCount(4);
  //     const dropValues = dropdownIntialValues;
  //     dropValues.awareness = true;
  //     dropValues.quickLinks = !dropdownvalues.quickLinks;
  //     setDropdownValues(dropValues);
  //   }
  //   if (activeWalkThruSlide === 10 ) {
  //     scrollToComponent(document.getElementById('slide-seven'), { offset: -550, align: 'bottom', duration: 200 });
  //     setSlideCount(5);
  //     setBackgroundBlur( true );
  //     const dropValues = dropdownIntialValues;
  //     dropValues.awareness = false;
  //     dropValues.quickLinks = !dropdownvalues.quickLinks;
  //     setDropdownValues(dropValues);
  //   }
  //   if ( activeWalkThruSlide === 11) {
  //     scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 200 });
  //     const dropValues = dropdownIntialValues;
  //     dropValues.savedNotes = !dropdownvalues.savedNotes;
  //     setDropdownValues(dropValues);
  //     setBackgroundBlur( true );
  //     setSlideCount(6);
  //   }
  //   if ( activeWalkThruSlide === 12) {
  //     scrollToComponent(document.getElementById('slide-three'), { offset: -200, align: 'middle', duration: 200 });
  //     const dropValues = dropdownIntialValues;
  //     dropValues.savedNotes = !dropdownvalues.savedNotes;
  //     setDropdownValues(dropValues);
  //   }
  // },[activeWalkThruSlide]);

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -300, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -150, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: -550, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -200, align: 'middle', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  };

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    if (activeWalkThruSlide === 9) {
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
    }
  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -200, align: 'middle', duration: 200 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -180, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -200, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 420, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 440, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 400, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 100, align: 'middle', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  const checkStartYearValid = () => {
    if (updatedFIValues.start_year < currentYear) {
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3>Important Tip</h3>
              <div className={classes.impDiv}>
                <p>When developing a financial life plan, it&apos;s always best to start right now. So, we recommend you enter today&apos;s year for the start year. Don&apos;t worry if the year has just begun or is almost over. The FC runs on an annual basis rather than thinking month to month.</p>
              </div>
            </div>
            <div className={classes.impbuttonOkRedo}>
              <button
                type="button"
                onClick={() => { onClose() }}
              >
                OK
              </button>
            </div>
          </div>
        ),
      });
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >
      {/* <Typography variant="body1" component="div" > */}
      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 12 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.financeSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Financial Independence
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your financial independence details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classes.startYear}>START WITH A WALK-THRU</span>
                              <span aria-hidden="true" className={classes.greenBtn} onClick={() => { handleCurrentVideoId('video'); }}>WATCH QUICKSTART TUTORIAL</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImage} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Begin Your Journey
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Financial Independence
                              </Typography>
                              <ul>
                                <li>Enter your start year to set the Simulator Engine in motion</li>
                                <li>Make assumptions about your annual income growth and inflation</li>
                                <li>Enter cash on hand and cash balance desired before investing</li>
                                <li>Change these details at any time – the Simulator Engine will instantly reconfigure your data.</li>
                              </ul>
                              <span aria-hidden="true" className={classes.greenBtn} onClick={() => { handleCurrentVideoId('video'); }}>WATCH QUICKSTART TUTORIAL</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImage} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Begin Your Journey
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Build Your Plan
                              </Typography>
                              <ul>
                                <li>To minimize risk while maximizing reward</li>
                                <li>To test your assumptions, hone your strategies, find your best options</li>
                                <li>To forecast financial decisions before you even make them</li>
                                <li>To strive for financial independence.</li>
                              </ul>
                              <span aria-hidden="true" className={classes.greenBtn} onClick={() => { handleCurrentVideoId('video'); }}>WATCH QUICKSTART TUTORIAL</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImage} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
            {/* {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)} */}
          </div>
        </div>


        <section className={classes.fiContentSection} id="slide-two">
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftPuzzleTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module1" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={0}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      Begin Your Journey
                      {' '}
                      <span>to Financial Independence</span>
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classNames(classes.leftIconPuzzle, classes.existingLeftIcon)}>
                    <Link to="/plan-with-inkwiry/fi" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides}
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes={classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <Grid container spacing={4} className={classNames(classes.scalePortion, classes.scaleOpacity)}>
                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classNames(classes.scrollPortion, classes.scrollPortionInFI)}>
                          <div className={classNames(classes.formGroup, classes.noBottomSpace, classes.formMainRow)}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} className={classes.leftSection}>
                                <div className={classes.leftTitle}>
                                  <div className={classes.currencyText}>
                                    <strong>Today&apos;s Year</strong>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <div className={classes.inputText}>
                                  <div className={classes.currencyInputText}>
                                    {new Date().getFullYear()}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classNames(classes.formGroup, classes.noBottomSpace, classes.formMainRow)}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} className={classes.leftSection}>
                                <div className={classes.leftGrayBox}>
                                  <div className={classes.currencyCntnt}>
                                    <p><strong>Start Year</strong></p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} spacing={0}>
                                <div className={classes.inputRight}>
                                  <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} value={updatedFIValues.start_year} onValueChange={(e) => updateValue(e, 'start_year')} onFocus={(e) => e.target.select()} onBlur={checkStartYearValid} />
                                  <div className={classes.infoIcon}>
                                    <img src={infoIcon} alt="infoicon" data-for="leftTooltip" data-tip="Begin your journey to financial independence by entering your start year. This is the most important input in the entire FC. Why? The start year sets the 5, 10, 15 year timeline you will plan for. To start planning right now, simply enter today's year. Check out the Boost My Awareness section below if you need more details." />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classNames(classes.formGroup, classes.noBottomSpace, classes.formMainRow)}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} className={classes.leftSection}>
                                <div className={classes.leftTitle}>
                                  <div className={classes.currencyText}>
                                    <strong>End Year</strong>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6}>
                                <div className={classes.inputText}>
                                  <div className={classes.currencyInputText}>
                                    {updatedFIValues.start_year > 0 ? updatedFIValues.start_year + 14 : 0}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classes.formTitleRow}>
                            <h3>Assumptions</h3>
                          </div>
                          <div className={classNames(classes.formGroup, classes.formMainRow)}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} className={classes.leftSection}>
                                <div className={classes.leftGrayBox}>
                                  <div className={classes.currencyCntnt}>
                                    <p><strong>Annual Income Growth</strong></p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} spacing={0}>
                                <div className={classes.inputRight}>
                                  <NumberFormat
                                    className={classes.formInput}
                                    allowNegative={false}
                                    customInput={TextField}
                                    value={updatedFIValues.long_term_growth_in_percentage}
                                    onValueChange={(e) => updateValue(e, 'long_term_growth_in_percentage', 'incomegrowth', 'percentage')}
                                    decimalScale = { isFocus!=='long_term_growth_in_percentage'? 2: 5 }
                                    fixedDecimalScale={isFocus!=='long_term_growth_in_percentage'}
                                    onFocus={ ( e ) => { e.target.select(); setIsFocus('long_term_growth_in_percentage'); }}
                                    onBlur={() => { setFixedDecimal('long_term_growth_in_percentage'); }}
                                  />

                                  <span className={classes.percentSymbol}>%</span>
                                  <div className={classes.infoIcon}>
                                    <img
                                      src={infoIcon}
                                      alt="infoicon"
                                      data-for="leftTooltip"
                                      data-tip="To make sure we forecast your future properly, your income will grow each year by the annual income growth.
                                   Go to the Quick Links section and click on: 'Use this link for annual income growth; filter on your industry for a better estimate for your income growth in the future.'
                                    See how different income growths can affect your net worth. Check out the Boost My Awareness section below if you need more details."
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className={classNames(classes.formGroup, classes.formMainRow)}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} className={classes.leftSection}>
                                <div className={classes.leftGrayBox}>
                                  <div className={classes.currencyCntnt}>
                                    <p><strong>Annual Inflation</strong></p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} spacing={0}>
                                <div className={classes.inputRight}>
                                  <NumberFormat
                                    className={classes.formInput}
                                    allowNegative={false}
                                    customInput={TextField}
                                    value={updatedFIValues.inflation_in_percentage}
                                    onValueChange={(e) => updateValue(e, 'inflation_in_percentage', 'inflation', 'percentage')}
                                    decimalScale = { isFocus!=='inflation_in_percentage'? 2: 5 }
                                    fixedDecimalScale={isFocus!=='inflation_in_percentage'}
                                    onFocus={ ( e ) => { e.target.select(); setIsFocus('inflation_in_percentage'); }}
                                    onBlur={() => { setFixedDecimal('inflation_in_percentage'); }}
                                  />
                                  <span className={classes.percentSymbol}>%</span>
                                  <div className={classes.infoIcon}>
                                    <img
                                      src={infoIcon}
                                      alt="infoicon"
                                      data-for="leftTooltip"
                                      data-tip="To make sure we forecast your future properly, your expenses will grow each year by the annual inflation.
                                   The increase (or decrease) in the cost of goods and services each year is called inflation.
                                    A good estimate for annual inflation would be 2.0%. If you want to see the latest inflation number, go to the Quick Links section and click on: 'Use this link for inflation; wait until the chart loads and scroll over the red column.'
                                     Check out the Boost My Awareness section below if you need more details."
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>                         
                          {/* <div className={classNames(classes.formGroup, classes.formMainRow)}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} className={classes.leftSection}>
                                <div className={classes.leftGrayBox}>
                                  <div className={classes.currencyCntnt}>
                                    <p><strong>Current Cash Balance</strong></p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} spacing={0}>
                                <div className={classes.inputRight}>
                                  <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFIValues.current_cash_balance} onValueChange={(e) => updateValue(e, 'current_cash_balance')} onFocus={(e) => e.target.select()} />
                                  <span className={classes.dollarSign}>$</span>
                                  <div className={classes.infoIcon}>
                                    <img src={infoIcon} alt="infoicon" data-for="leftTooltip" data-tip="Enter the amount of money you have in physical cash or in a checking account right now. Don't include any money you have invested in this number as you will input that amount in your investments module. Check out the Boost My Awareness section below for more details." />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div> */}
                          {/* <div className={classNames(classes.formGroup, classes.formMainRow)}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} className={classes.leftSection}>
                                <div className={classes.leftGrayBox}>
                                  <div className={classes.currencyCntnt}>
                                    <p><strong>Max Cash Balance before Investment</strong></p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} spacing={0}>
                                <div className={classes.inputRight}>
                                  <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFIValues.max_cash_balance} onValueChange={(e) => updateValue(e, 'max_cash_balance')} onFocus={(e) => e.target.select()} />
                                  <span className={classes.dollarSign}>$</span>
                                  <div className={classes.infoIcon}>
                                    <img
                                      src={infoIcon}
                                      alt="infoicon"
                                      data-for="leftTooltip"
                                      data-tip="Enter the max amount of money you want to hold in your cash account.
                                  After you reach this amount, the FC will automatically invest the excess amount into short and long-term investments at the end of the year in your financial life plan.
                                  Saving and investing is essential to building wealth. Before you enter a value, go to the Boost My Awareness section below and read the definition for this input."
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div> */}
                          <div className={classes.cashFlowDiv}>
                            <Grid container spacing={0}>
                              <Grid item sm={6} xs={6} spacing={0}>
                                <div className={classes.whiteLeftBox}>
                                  <div className={classes.currencyTextNew}>
                                    <p><strong>Cash Flow Check</strong></p>
                                  </div>
                                </div>
                              </Grid>
                              <Grid item sm={6} xs={6} spacing={0}>
                                {moduleServices.cashFlowCheckVal < 0 && (
                                  <div className={classes.cashFlowGreen}>
                                    <div>
                                      <span>Cash Flow Positive</span>
                                    </div>
                                  </div>
                                )}
                                {moduleServices.cashFlowCheckVal > 0 && (
                                  <div className={classes.cashFlowRed}>
                                    <div>
                                      <span>{moduleServices.cashFlowCheckVal}</span>
                                    </div>
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>
            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll) : classNames(classes.showMe, classes.showSection)} id="slide-five">
              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                <div>
                  {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                    <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                  )}
                  {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Refresh</Button>)}
                </div>
              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {(showMeData.graph1 === true || showMeData.graph2 === true || showMeData.graph3 === true || showMeData.graph4 === true || showMeData.graph5 === true || showMeData.graph6 === true || showMeData.graph7 === true || showMeData.graph8 === true || showMeData.graph9 === true || showMeData.graph10 === true) && (
                    <div className={classes.showDescriptionCntnt}>
                      {showMeData.graph1 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Grow Your Net Worth: What&apos;s Your 5, 10, 15 Year Number?</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph1Data}
                            />
                          </div>
                        </div>
                      )}
                      {showMeData.graph2 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>What Does Your Picasso Look Like?</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph2Data}
                            />
                          </div>
                        </div>
                      )}
                      {showMeData.graph3 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Total Taxes by Category</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph3Data}
                            />
                            <div className={classes.totalValues} >Total 15-Year Taxes Paid: <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={showMeData.graph3Value} prefix={showMeData.graph3Value >= 0 ? '$' : '($'} suffix={showMeData.graph3Value < 0 && ')'} /></div>
                          </div>
                        </div>
                      )}
                      {showMeData.graph4 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Spending Breakdown: Total Expenses by Category</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph4Data}
                            />
                            <div className={classes.totalValues} >Total 15-Year Living Expenses: <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={showMeData.graph4Value} prefix={showMeData.graph4Value >= 0 ? '$' : '($'} suffix={showMeData.graph4Value < 0 && ')'} /></div>
                          </div>
                        </div>
                      )}
                      {showMeData.graph5 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Managing Cash Flow: Short-Term Investments</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph5Data}
                            />
                          </div>
                        </div>
                      )}
                      {showMeData.graph6 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Managing Cash Flow: Long-Term Investments</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph6Data}
                            />
                          </div>
                        </div>
                      )}
                      {showMeData.graph7 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>401(k) Retirement Account: Sensitivity Analysis</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph7Data}
                            />
                            <span className={classes.graphText}>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                          </div>
                        </div>
                      )}
                      {showMeData.graph8 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Roth IRA Retirement Account: Sensitivity Analysis</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph8Data}
                            />
                            <span className={classes.graphText}>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                          </div>
                        </div>
                      )}
                      {showMeData.graph9 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Roth IRA Retirement Account: Sensitivity Analysis</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph9Data}
                            />
                            <span className={classes.graphText}>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                          </div>
                        </div>
                      )}
                      {showMeData.graph10 === true && (
                        <div className={classes.descriptionRow}>
                          <div className={classes.graphBlock}>
                            <h3>Roth IRA Retirement Account: Sensitivity Analysis</h3>
                            <hr className={classes.blueRow} />
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={showMeData.graph10Data}
                            />
                            <span className={classes.graphText}>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Financial Independence
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC forecast in regards to time?</h3>
                          <p>The FC works on an annual basis. We assume that Life Events happen for a number of years rather than thinking from month to month. For example, you plan to start a new job in the Career Advancement module in 2025 and plan to stay at this job for 3 years. You would input a Start Year of of 2025 and an End Year of 2027.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC use Annual Income Growth and Annual Inflation?</h3>
                          <p>Let&apos;s use examples to explain this. If you plan on working a job from 2024-2028 and that job will earn you $100,000 in 2024. The FC will grow your income each year by the Annual Income Growth. If Annual Income Growth is 5% then your income will grow to $105,000 in 2025. The same goes for expenses. If you plan on spending $10,000 on food in year 2024 and Annual Inflation is 3%, your food expense will be $10,300 in year 2025.</p>
                          <p className={classes.spaceRow}>The FC forecasts future income and expenses based off of these two very important values. See the Definitions section for a deeper understanding of Annual Income Growth and Annual Inflation.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>What is the side menu bar that appears on the right side of the page?</h3>
                          <p>See the image below for the following terms and what each of the buttons will take you to upon click.</p>
                          <div className={classes.fcTerms}>
                            <span>
                              <b>FC Home</b>
                              {' '}
                              - See all modules on one page
                              {' '}
                            </span>
                            <span>
                              <b>My Details</b>
                              {' '}
                              - Return to the My Details section of the current module to view and/or edit inputs
                              {' '}
                            </span>
                            <span>
                              <b>Show Me</b>
                              {' '}
                              - Return to the Show Me section of the current module for visual analytics
                              {' '}
                            </span>
                            <span>
                              <b>Questioning Intelligence (QI)</b>
                              {' '}
                              - Return to the QI section of the current module for the most important questions of this life event
                              {' '}
                            </span>
                            <span>
                              <b>Boost My Awareness</b>
                              {' '}
                              - Return to the Boost My Awareness section of the current module for additional learning resources
                              {' '}
                            </span>
                            <span>
                              <b>Compare Saved Scenarios</b>
                              {' '}
                              - Return to the Compare Saved Scenarios section of the current module to compare scenarios side-by-side
                              {' '}
                            </span>
                            <span>
                              <b>My Saved Notes</b>
                              {' '}
                              - Return to the My Saved Notes section of the current module to view, edit, and/or create new notes
                              {' '}
                            </span>
                            <span>
                              <b>Summary Documents</b>
                              {' '}
                              - Open a drop down of options that allow you to look at the summary of your current scenarios. Access your Financial Documents, Summary Output, and other Summary Documents and create PDFs for easy viewing or printing
                              {' '}
                            </span>
                          </div>
                          <div className={classes.menuImage}>
                            <img src={menuImage} alt="sidemenu" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.frbatlanta.org/chcs/wage-growth-tracker.aspx?panel=1" target="_blank" rel="noopener noreferrer">Annual Income Growth</a></h3>
                          <p>Annual income growth allows the FC to forecast your future earnings. For example, if in 2019 you expect to make $100,000 as a software engineer and input 5% annual income growth, the FC will grow your income to $105,000 in 2020. Income growth is very important to understand when thinking about your career. Are you selecting a high growth career or a declining career? Is your industry growing faster than other industries? How will that impact your future?</p>
                          <div className={classes.fcTerms}>
                            <span>Recent wage growth has been in between 3-4%, which would be a good benchmark to use. </span>
                            <span>If your annual income growth is more than annual inflation, that means your purchasing power has increased and your net worth will be positively impacted in years to come. Why is this important and what is purchasing power? Let&apos;s look at a real life example of purchasing a car.</span>
                            <span>
                              In 2019, a car costs $10,000 and you have a job that pays you $50,000 after-tax. In 2019, you can buy 1 car and have $40,000 leftover for living, saving, debt repayment and more. In 2020, that same car now costs $10,200 due to 2% annual inflation and you still make $50,000.
                              You can purchase 1 car, but only have $39,200 leftover for living, saving, debt repayment and more. Your purchasing power has decreased since you can no longer buy the same amount of goods and services.
                            </span>
                            <span>That&apos;s why income growth is so important in real life. The cost of goods and services typically rises each year. Your income growth needs to at least match inflation so that your earnings keep up with the cost of goods and services.</span>
                          </div>
                          <IncomeGrowthGraphs />
                          <div className={classes.bottomText}>
                            <p>
                              Sources:
                              {' '}
                              <a href="https://www.frbatlanta.org/chcs/wage-growth-tracker.aspx" rel="noopener noreferrer" target="_blank"><u>Federal Reserve Bank of Atlanta</u></a>
                              , Current Population Survey, Bureau of Labor Statistics
                            </p>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.khanacademy.org/economics-finance-domain/core-finance/inflation-tutorial/inflation-basics-tutorial/v/what-is-inflation" target="_blank" rel="noopener noreferrer">Annual Inflation</a></h3>
                          <p>
                            The increase in the general level of prices for goods and services in the last twelve months. Annual inflation allows the FC to forecast your expenses in the future. For example, if your monthly rent in 2019 is $1,000 and have 2% annual inflation, the FC will grow your rent expense to $1,020 in 2020.
                            Annual inflation is very important to understand when thinking about the future. It&apos;s important for your income growth to be greater than inflation. Why? If your income grows faster than inflation, that means your income is growing faster than the cost of goods and services and you can save and invest more money each year. See the &apos;Annual Income Growth&apos; definition above for an example
                          </p>
                          <div className={classes.fcTerms}>
                            <span>A good benchmark to use for annual inflation would be between 1.5 and 2.5%. </span>
                            <span>
                              Here is a
                              {' '}
                              {' '}
                              <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/inflation-tutorial/inflation-basics-tutorial/v/what-is-inflation" target="_blank" rel="noopener noreferrer">short video</a>
                              {' '}
                              explaining inflation.
                            </span>
                          </div>
                          <div className={classes.graphImage}>
                            <div className={classes.graphInflection}>
                              {/* <img src={ GraphInfle } alt="" /> */}
                              <div className={classes.graphBox}>
                                <HighchartsReact
                                  highcharts={Highcharts}
                                  options={{
                                    chart: {
                                      type: 'spline',
                                      scrollablePlotArea: {
                                        scrollPositionX: 0,
                                      },
                                    },
                                    navigation: {
                                      menuItemStyle: {
                                        fontSize: '14px',
                                        textAlign: 'left',
                                      },
                                      menuItemHoverStyle: {
                                        background: '#f5f5f5',
                                        color: '#4c4c4c',
                                        fontSize: '14px',
                                      },
                                      buttonOptions: {
                                        height: 40,
                                        width: 48,
                                        symbolSize: 24,
                                        symbolX: 24,
                                        symbolY: 21,
                                        symbolStrokeWidth: 2,
                                        verticalAlign: 'bottom',
                                        _titleKey: 'y',
                                      },
                                    },
                                    exporting: {
                                      buttons: {
                                        contextButton: {
                                          menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                                        },
                                      },
                                    },
                                    credits: {
                                      enabled: false,
                                    },
                                    title: {
                                      text: 'Inflation Rate (12-month percentage change)',
                                    },
                                    xAxis: [{
                                      tickInterval: 24,
                                      categories: inflationGraphData.dates,
                                      labels: {
                                        formatter() {
                                          const chart = this;
                                          return chart.value.split(' ')[1];
                                        },
                                        style: {
                                          color: '#000000',
                                          fontSize: '13px',
                                        },
                                      },
                                    }],
                                    yAxis: [{ // Primary yAxis
                                      tickInterval: 1,
                                      labels: {
                                        format: '{value:.1f}%',
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                      title: {
                                        text: '',
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                    }],
                                    tooltip: {
                                      headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                                      crosshairs: true,
                                      shared: true,
                                      valueDecimals: 1,
                                      valueSuffix: '%',
                                      style: {
                                        color: '#000000',
                                        fontSize: '14px',
                                      },
                                    },
                                    legend: {
                                      itemStyle: {
                                        fontSize: '14px',
                                      },
                                    },
                                    plotOptions: {
                                      spline: {
                                        lineWidth: 2,
                                        states: {
                                          hover: {
                                            lineWidth: 2,
                                          },
                                        },
                                        marker: {
                                          enabled: false,
                                        },

                                      },
                                    },
                                    series: inflationGraphData.details,
                                  }}
                                />
                              </div>
                            </div>
                            <div className={classes.bottomText}>
                              <p>
                                Sources:
                                {' '}
                                <a href="https://www.bls.gov/cpi/" rel="noopener noreferrer" target="_blank"><u>U.S. Bureau of Labor Statistics</u></a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/ask/answers/12/what-is-an-asset.asp" target="_blank" rel="noopener noreferrer">Asset</a></h3>
                          <p>Anything that you own that has value and can be converted into cash. For example, your car is an asset. The cash in your pocket is an asset. In Inkwiry, there are two types of assets: tangible assets and investments. You can find your assets on the balance sheet.</p>
                          <p>
                            <a href="https://www.thesimpledollar.com/personal-finance-101-what-is-an-asset/" target="_blank" rel="noopener noreferrer">Tangible assets</a>
                            {' '}
                            are cash, cars, and real estate property. Investments are retirement accounts such as 401(k), Roth IRA, SEP IRA, or Pension. There are also short-term investments and long-term investments.
                          </p>
                          <p>Remember, assets minus liabilities equals net worth. Or, more simply put, what you own minus what you owe equals net worth.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.bankrate.com/finance/banking/checking-vs-savings-accounts.aspx" target="_blank" rel="noopener noreferrer">Checking Account</a></h3>
                          <p>
                            A bank account, also known as a transactional account, that provides easy access to your money and is most commonly used to pay bills and other frequent financial transactions such as writing a check or setting up direct deposit for your paychecks.
                            A bank account is an arrangement to deposit money with a bank, which will keep that money safe until withdrawn or spent. Since checking accounts typically pay very little to no interest on the money you keep with the bank, the FC treats checking account deposits as cash.
                          </p>
                          <p>
                            These accounts sometimes charge fees for services such as not meeting a minimum balance requirement or using another bank&apos;s ATM to withdraw cash and many more. Selecting a checking account can be tricky; check out this resource for
                            {' '}
                            {' '}
                            <a href="https://www.thebalance.com/checking-accounts-2385969" target="_blank" rel="noopener noreferrer">more info on a checking account</a>
                            {' '}
                            and this resource for
                            {' '}
                            {' '}
                            <a href="https://www.thebalance.com/best-banks-for-checking-accounts-4163073" target="_blank" rel="noopener noreferrer">some options.</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Current Cash Balance</h3>
                          <p>
                            How much money you have or expect to have in a checking account and in actual cash at your Start Year. For example, if you have $20,000 in investments, $10,000 in a checking account, and keep $1,000 hidden under your bed, your Current Cash Balance would be $11,000. Your investments are not part of your Current Cash Balance and will be addressed in the Investments module.
                            Note, current cash balance does not include a savings account. A savings account will also be addressed in the Investments module as well as the definition below.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Current Ratio</h3>
                          <p>Calculated by taking cash and short-term investments at the end of the year divided by total debt payments (principal and interest) in a given year. This ratio represents the ability to service short-term liabilities in case of a financial emergency. You can find the current ratio on the dashboard or on the summary financials.</p>
                          <p>The higher the ratio, the better. Having at least a 1x current ratio means you have enough liquid assets to make your debt payments for the year.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.creditkarma.com/credit-scores" target="_blank" rel="noopener noreferrer">Credit Score</a></h3>
                          <p>
                            A three-digit number ranging from 300 - 850 (higher the better) that relates to how likely you are to repay debt. Your Credit Score is very important to maintain and improve as banks and lenders will use your score when deciding whether or not to approve you for a credit card or loans such as auto loans, student loans, and mortgages. According to Credit Karma, a good credit score is
                            {' '}
                            <a href="https://www.creditkarma.com/what-is-a-good-credit-score" target="_blank" rel="noopener noreferrer" className={classes.noLink}>700 and higher</a>
                            . Usually you can check your credit score with your credit card company. If not, check for free with
                            {' '}
                            <a target="_blank" rel="noopener noreferrer" href="https://www.creditkarma.com/free-credit-score" className={classes.noLink}>Credit Karma</a>
                            {' '}
                            or
                            {' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className={classes.noLink}
                              href="https://www.experian.com/consumer-products/free-credit-report.html"
                            >
                              Experian
                            </a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.investopedia.com/terms/d/dti.asp"
                            >
                              Debt-to-Income Ratio
                            </a>
                          </h3>
                          <p>
                            Used by banks and mortgage companies in order to determine eligibility for loans, specifically mortgages, making this ratio as important as your credit score. Calculated by taking total debt payments (principal and interest) divided by total gross income in a given year. This ratio indicates the percentage of gross income being accounted for debt repayment and the percentage of gross income left over for other household expenses and savings.
                            You can find the debt-to-income ratio on the dashboard or on the summary financials.
                          </p>
                          <p>The lower the ratio, the better. A lower debt-to-income ratio means you have more money left over after debt repayment for living expenses, saving, and investing. A healthy ratio is typically defined as no more than 36%.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Investment Assets to Total Assets</h3>
                          <p>Calculated by taking cash, short-term investments, and long-term investments divided by total assets at the end of the year. This ratio compares liquid (see definition for liquidity) assets against total assets. Investments in cash, short-term investments, and long-term investments are liquid assets. Retirement accounts, cars, and real estate are illiquid assets. You can find the investment assets to total assets on the summary financials.</p>
                          <p>A good benchmark for this ratio is 20%. That means 20% of your total assets is cash or can be easily converted into cash if needed.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Liability</h3>
                          <p>Money you owe to another person or institution. For example, student loans, car loans, and mortgages are all liabilities. In Inkwiry, there are four types of liabilities: credit card debt, student loans, car loans, and mortgages. You can find your liabilities on the balance sheet.</p>
                          <p>Remember, assets minus liabilities equals net worth. Or, more simply put, what you own minus what you owe equals net worth.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Liquidity</h3>
                          <p>Liquidity is a measure of how easily an asset can be converted into cash. For example, your checking account and savings account are highly liquid assets. Why? Because you can go to the bank and withdraw cash in one day.</p>
                          <p>Your home, for example, is highly illiquid. Why? Because you have to hire a real estate agent, list your home for sale, and go through a months long or even years long process to sell your home and receive cash.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Max Cash Balance before Investment</h3>
                          <p>In your career sketch, you will build up cash when you make more money than you spend. Max Cash Balance before Investment tells the Financial Configurator to invest any cash that exceeds that amount.</p>
                          <p>How much cash do you want to hold at any point in time in the future? A good benchmark for max cash balance before investment is anywhere from two to three months of expenses. Check out the graphic below to see how the FC automates your investing.</p>
                          <p>
                            Once you set a start year in the Investments module for Short-Term Investments, the FC will start investing excess cash that year and all years thereafter. For example, let&apos;s say Max Cash Balance before Investment is $25,000 and your Ending Cash Balance in 2025 is $75,000. At the end of 2025, $50,000 would be transferred from your Cash Account into your Short-Term Investments.
                            See the below images for examples of how the FC moves Cash into Investments and vice versa.
                          </p>
                        </div>
                        <div className={classes.galleryImage}>
                          <img src={cashFlowOne} alt="cashFlow" />
                        </div>
                        <div className={classes.galleryImage}>
                          <img src={cashFlowTwo} alt="cashFlow" />
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Monthly Liquidity Ratio</h3>
                          <p>Calculated by taking cash and short-term investments at the end of the year divided by 1 month of living expenses and interest expenses in that same year. This ratio represents the ability to meet committed expenses such as rent, utilities, food, transportation, debt payments, etc. when faced with an emergency. You can find the monthly liquidity ratio on the summary financials.</p>
                          <p>The higher the ratio the better, but maintaining at least 3-6x is ideal. That means you have enough cash and short-term investments to pay 3-6 months of expenses.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a
                              href="http://www.investopedia.com/video/play/what-net-worth/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Net Worth
                            </a>
                          </h3>
                          <p>Net worth is the value of what you own (assets) minus what you owe (liabilities).</p>
                          <p>To calculate your net worth, start by adding up the value of all the things that you own - the value of your checking and savings accounts, investments, retirement accounts, cars and real estate. The value of an asset is the price that you can sell it for today.</p>
                          <p>Then, add up the value of all the things that you owe - credit card balances, student loans, car loans and mortgages. The value of a liability is the outstanding amount on that loan that still needs to be repaid.</p>
                          <p>Lastly, take the total value of your assets and subtract the total value of your liabilities to get your net worth. Check out the example below.</p>
                          <p>
                            Knowing your net worth is important for two main reasons:
                            <span>1. Net worth shows your current financial situation in one, clean number, and</span>
                            <span>2. Net worth provides a reference point for measuring your future financial progress.</span>
                          </p>
                          <p>Net worth allows you to track your financial progress over time. Are you making smart money moves that grow your net worth? Leverage Inkwiry to find out and see what you might be worth in 5, 10, 15 years.</p>
                          <div className={classNames(classes.galleryImage, classes.femImage)}>
                            <img src={netWorth} alt="networth" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.bankrate.com/finance/banking/checking-vs-savings-accounts.aspx" target="_blank" rel="noopener noreferrer">Savings Account</a></h3>
                          <p>A bank account where you can store money that you don&apos;t need right away, but still provides easy access to your money. Savings accounts are typically used as a short-term investment for money that you don&apos;t intend to use for regular expenses. If you have a savings account, use the short-term investments section in the Investments module to account for that asset.</p>
                          <p>
                            Savings accounts almost always pay interest on the money you keep with the bank. Like checking accounts, these accounts sometimes charge fees for services such as not meeting a minimum balance requirement. Selecting a savings account requires some due diligence;
                            {' '}
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.investopedia.com/terms/s/savingsaccount.asp"
                            >
                              watch this video
                            </a>
                            {' '}
                            for more info. And, take a look at this resource
                            {' '}
                            {' '}
                            <a
                              href="https://www.bankrate.com/banking/savings/rates/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              to compare the most competitive savings accounts today
                            </a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Savings Rate</h3>
                          <p>Calculated by taking total savings divided by after-tax income in a given year. Total savings is calculated as net change in cash plus contributions to investment accounts plus retirement contributions. You can find the savings rate on the dashboard or on the summary financials.</p>
                          <p>A higher savings rate will grow your assets and lead to a higher net worth. A good benchmark to use for this KPI is between 12-15%.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Solvency Ratio</h3>
                          <p>Calculated by taking net worth, which is total assets minus total liabilities, divided by total assets at the end of the year. Solvency ratio compares net worth against total assets. Net worth is positive if total assets are worth more than total liabilities. You can find the solvency ratio on the summary financials.</p>
                          <p>This ratio indicates the ability to repay existing debts using existing assets in case of unforeseen events. The closer this ratio is to 1x, the better. The closer to 1x means you have less debt. If this ratio is negative, that means total debt is greater than total assets or net worth is negative.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Start Year</h3>
                          <p>The year the FC will start your financial plan. If you want to start right now - which we highly recommend - input Today&apos;s Year. Don&apos;t worry that it could be the middle, the beginning, or the end of a year - the FC forecasts for a 15 year period and not by months.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Total Assets to Total Debt</h3>
                          <p>Calculated by taking total assets divided by total liabilities at the end of the year. This ratio compares total assets accumulated against the existing liabilities to analyze your financial health. You can find the total assets to total debt ratio on the dashboard or on the summary financials.</p>
                          <p>Having at least a 1x ratio means you have enough assets to pay off your debt in case of a financial emergency.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Annual Income Growth</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.frbatlanta.org/chcs/wage-growth-tracker.aspx?panel=1" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Use this link for annual income growth; filter on your industry for a better estimate for your income growth in the future</a>
                            <a href="https://www.glassdoor.com/research/local-pay-reports/united-states/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Historical income growth by career</a>
                            <a href="https://www.cnbc.com/2017/10/03/10-jobs-with-the-fastest-salary-growth-in-the-u-s.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>10 jobs with fastest income growth by Glassdoor</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Annual Inflation</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.bls.gov/cpi/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Use this link for inflation; wait until the chart loads and scroll over the red column</a>
                            <a href="https://www.federalreserve.gov/faqs/money_12848.htm" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Best estimate for inflation is 2%. Here&apos;s why</a>
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/inflation-tutorial/inflation-basics-tutorial/v/what-is-inflation" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What is inflation? Watch this video</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Credit Score</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalance.com/how-to-read-your-credit-score-960488" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How to read your credit score</a>
                            <a href="https://www.thebalance.com/reasons-why-good-credit-matters-960178" target="_blank" rel="noopener noreferrer" className={classes.noLink}>5 reasons why good credit would matter</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Max Cash Balance before Investment</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.investopedia.com/articles/personal-finance/040915/how-much-cash-should-i-keep-bank.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much cash should you hold at any point in time? Here&apos;s some tips in a short video</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Net Worth</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.investopedia.com/articles/pf/13/importance-of-knowing-your-net-worth.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What is it and why is it important?</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Tax Updates</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://taxfoundation.org/conference-report-tax-cuts-and-jobs-act/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>2018 tax reform and how it affects you</a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Financial Independence" moduleSelected="module1" getModuleData={getModuleData} setUpdatedScenario={setUpdatedFIValues} />
              )}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes
                  handleSaveScenario={handleSaveScenario}
                  loadScenarioName={loadScenarioName}
                  setLoadScenarioName={setLoadScenarioName}
                  savedNotes={dropdownvalues.savedNotes}
                />
              )}
            </div>
          </Container>
        </section>
        <div>
          <Dialog
            open={popupOpen}
            onClose={() => { setPopupOpen(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={(modalType === 'save') ? classes.SavePopup : joinInk}
          >
            <DialogContent className={modalType === 'save' ? classNames(classes.sightPopup, classes.videoPopup) : classes.videoPopup}>
              {(modalType !== 'inflation' && modalType !== 'incomegrowth') && (
                <div className={(modalType === 'save') ? classes.sightPopupClose : classes.popupClose}>
                  <span aria-hidden="true" onClick={() => { setPopupOpen(false); }}>
                    X
                  </span>
                </div>
              )}

              <div>
                {modalType === 'video' && (<YouTube videoId={currentVideo} opts={opts} />)}
                {(modalType === 'save'  ) && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedFIValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg={SetNotesSuccessMsg} />)}
                {
                  modalType === 'inflation' && (
                    <div className={classes.clearPopup}>
                      <div className={classes.clearHeader}>
                        <h3>Important Tip</h3>
                      </div>
                      <div className={classes.clearContent}>
                        <p>
                          When developing a financial life plan, it&apos;s always best to start right now. So, we recommend you enter today&apos;s year for the start year. Don&apos;t worry if the year has just begun or is almost over. The FC runs on an annual basis rather than thinking month to month.
                        </p>
                      </div>
                      <React.Fragment>
                        <div className={classes.clearFooter}>
                          <Button className={classes.buttonOne} onClick={() => { setPopupOpen(false); }}> OK</Button>
                        </div>
                      </React.Fragment>
                    </div>
                  )
                }
                {
                  modalType === 'incomegrowth' && (
                    <div className={classes.clearPopup}>
                      <div className={classes.clearHeader}>
                        <h3>Important Tip</h3>
                      </div>
                      <div className={classes.clearContent}>
                        <p>
                          Annual Income Growth should typically be between 1-8%. Make sure you can support an income growth rate outside these boundaries with research.
                        </p>
                      </div>
                      <React.Fragment>
                        <div className={classes.clearFooter}>
                          <Button className={classes.buttonOne} onClick={() => { setPopupOpen(false); }}> OK</Button>
                        </div>
                      </React.Fragment>
                    </div>
                  )
                }
              </div>

              <div />
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module1' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={classes.modelPopupRow}
      >
        <DialogContent className={classes.modelPopup}>
          <div className={classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

FI.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(FI);
