import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import styles from './styles';
import h1 from '../../assets/img/life-event-library/higher-education-slide1_new.jpg';
import h2 from '../../assets/img/life-event-library/higher-education-slide2.jpg';
import h3 from '../../assets/img/life-event-library/higher-education-slide3.jpg';
import h4 from '../../assets/img/life-event-library/higher-education-slide4.jpg';
import h5 from '../../assets/img/life-event-library/higher-education-slide5.jpg';
import h6 from '../../assets/img/life-event-library/higher-education-slide6.jpg';

const LifeEventImageThree = ( props ) => {
  const { classes } = props;

  const getStartYear = () => {
    props.openNoAccessPopup( true );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={ `${classes.containerNew} ${classes.eventThreeColor}` }>
      <div className={ classes.testimonilas }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Higher Education
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Choose a School
                        </Typography>
                        <ul>
                          <li>Select the type of education that interests you</li>
                          <li>Identify a specific school, research cost of attendance, and input your tuition and fees</li>
                          <li>Fill in your sources of funding such as grants,scholarships, financial aid, and family contributions</li>
                          <li>See real-time results as you change and adjust your selected schools and funding sources.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>GET STARTED</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ h1 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Higher Education
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Enter Your Expenses
                        </Typography>
                        <ul>
                          <li>List expenses for on-campus living</li>
                          <li>List expenses for off-campus housing</li>
                          <li>Compare outcomes to find your own best plan</li>
                          <li>If you are unsure of costs, Inkwiry suggests a value based on collective intelligence</li>
                          <li>Go back and experiment with other schools and funding alternatives to create additional possibilities.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>GET STARTED</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ h2 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Higher Education
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Boost Your Awareness
                        </Typography>
                        <ul>
                          <li>
                            What&apos;s involved in planning for higher education?
                            <ul>
                              <li>Check your QI (Questioning Intelligence)</li>
                              <li>Scan the Frequently Asked Questions</li>
                              <li>Understand what all the financial terms mean</li>
                              <li>Find quick links to additional resources.</li>
                            </ul>
                          </li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>Boost My Awareness</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ h3 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Higher Education
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Check Your Spaces
                        </Typography>
                        <ul>
                          <li>Click on the Spaces that need to be supportive of your higher education plan</li>
                          <li>Activate building blocks to develop your skills and knowledge</li>
                          <li>Open the scheduler to put building blocks in play.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>See My spaces</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ h4 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Higher Education
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Build Your Scenarios
                        </Typography>
                        <ul>
                          <li>Create &quot;what if&quot; possibilities</li>
                          <li>Compare those possibilities</li>
                          <li>Blend possibilities to find hybrid solutions</li>
                          <li>Add individual scenarios to the Simulator Engine</li>
                          <li>Minimize risk while maximizing rewards</li>
                          <li>Find the scenario that works best for you.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>GET STARTED</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ h5 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Higher Education
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Collaborate and Share
                        </Typography>
                        <ul>
                          <li>
                            Use My Notes to:
                            <ul>
                              <li>Sketch out ideas</li>
                              <li>Make to-do lists</li>
                              <li>Share and publish your data to collaborate with family, friends and trusted colleagues.</li>
                            </ul>
                          </li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.startYear }>Collaborate and share</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ h6 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

LifeEventImageThree.propTypes = {
  classes: PropTypes.object.isRequired,
  openNoAccessPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LifeEventImageThree );
