import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';

const OtherValue = ( props ) => {
  const { classes, handleAssestObject, handleUpdatedObject} = props;

  const [isFocus, setIsFocus] = React.useState('');

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    if(type === 'slider'){
      newvalue = value 
    }else{
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>What is the value of art, jewelry, antiques, collections or any other valuable items you own that can be easily sold for cash?</p>
      <p>Enter the estimated cash value for your other valuables below and the estimated growth rate of those valuables in the future.</p>
      <div className={ classNames(classes.tableBox, classes.tableBoxPWINew) }>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Other Valuables</th>
          </thead>
          <tbody>
            <tr>
              <td>Art</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.existing_art } name="existing_art" onValueChange={ ( e, value ) => updateValue( e, value, 'existing_art', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Jewelry</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.existing_jewelry } name="existing_jewelry" onValueChange={ ( e, value ) => updateValue( e, value, 'existing_jewelry', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td>Other</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.existing_other } name="existing_other" onValueChange={ ( e, value ) => updateValue( e, value, 'existing_other', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Valuables</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    {modulesServices.module18Data.existingTotalValuables !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingTotalValuables } prefix={ modulesServices.module18Data.existingTotalValuables >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingTotalValuables < 0 && ')' } />
                      ) : ( '$0' )}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    
      <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p>Expected Growth Rate</p>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ sliderValues.businessArray() }
              min={ 0 }
              max={ 10 }
              step={ 1 }
              value={ handleAssestObject.existing_other_expected_growth_rate }
              valueLabelFormat={ `${commonFunctions.perFormatter( handleAssestObject.existing_other_expected_growth_rate )}` }
              onChange={ ( e, value ) => updateValue( e, value, 'existing_other_expected_growth_rate', 'slider' ) }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            <NumberFormat
              customInput={ TextField }
              thousandSeparator
              value={ handleAssestObject.existing_other_expected_growth_rate }
              decimalScale={isFocus!=='existing_other_expected_growth_rate'?2:5}
              fixedDecimalScale={isFocus!=='existing_other_expected_growth_rate'}
              onValueChange={ ( e ) => updateValue( e, '', 'existing_other_expected_growth_rate', 'number' ) }
              onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_other_expected_growth_rate') } }
              onBlur={emptyIsFocus} />
            %
          </div>
        </div>
      </div>
       
    </div>
  );
};

OtherValue.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( OtherValue );
