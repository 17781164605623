
import _ from 'lodash';
function dashBoardCalcs(module1Data, balanceSheetData, incomeStatementData, cashFlowData, databaseData, inputSheetData, investmentsData, outputbackendData) {
	//Net Worth Growth for Net Worth Chart
	let year1 = 0;
	let yearLimit1 = 0;
	let dashBoardData = {};
	let startYear = parseInt(module1Data.start_year);
	let endYear = parseInt(module1Data.start_year) + 14;
	year1 = parseInt(module1Data.start_year);
	yearLimit1 = parseInt(module1Data.start_year) + 15;
	dashBoardData = {};

	//Assets and Liabilities
	var assetsArr = [];
	var otherValuablesArr = [];
	var businessArr = [];
	var autoPropertyArr = [];
	var realEstateArr = [];
	var k401kArr = [];
	var rothk401kArr = [];
	var traditionalIraArr = [];
	var rothIraArr = [];
	var sephIraArr = [];
	var pensionArr = [];
	var shortTermInvestmentsArr = [];
	var longTermInvestmentsArr = [];
	var hsaArr = [];
	var lifeInsuranceArr = [];
	var plan529Arr = [];
	var existingCreditCardDebtArr = [];
	var existingPersonalLoansArr = [];
	var studentLoansArr = [];
	var carLoansArr = [];
	var mortgagesArr = [];
	var netWorthsArr = [];
	var higherEducationIncomeArray = [];
	var careerIncomeYearArray = [];
	var bonusTipsComissionYearArray = [];
	var supplementaryIncomeYearArray = [];
	var spousesIncomeYearArray = [];
	var netRentalIncomeYearArray = [];
	var totalGrossBreakDownArray = [];
	
	var TaxesExpensesYearArray = [];
	var higherEducationExpensesYearArray = [];
	var livingExpensesYearArray = [];
	var carExpensesYearArray = [];
	var childrenPetExpensesYearArray = [];
	var homesExpensesYearArray = [];
	var interestExpensesYearArray = [];
	var totalExpensesBreakDownArray = [];

	let netWorthDenominator = 0;
	if( balanceSheetData !== undefined && balanceSheetData[startYear] !== undefined && balanceSheetData[startYear] !== '' && balanceSheetData[startYear]['Net Worth'] !== undefined && balanceSheetData[endYear]['Net Worth'] !== undefined ){
		if( balanceSheetData[startYear]['Net Worth'] < 0 ){
			netWorthDenominator = -balanceSheetData[startYear]['Net Worth'];
		} else{
			netWorthDenominator = balanceSheetData[startYear]['Net Worth'];	
		}
		if( netWorthDenominator !== 0 ){
			dashBoardData['Net Worth Growth for Net Worth Chart'] = (Math.pow((balanceSheetData[endYear]['Net Worth']/netWorthDenominator), 1/15) - 1) * 100;
		} else{
			dashBoardData['Net Worth Growth for Net Worth Chart'] = 0;
		}
	} else{
		dashBoardData['Net Worth Growth for Net Worth Chart'] = 0;	
	}
	
	/*	Total Gross Income Breakdown */
	dashBoardData['Total Gross Income Breakdown'] = {};
	var higherEducationIncome = 0;
	var careerIncome = 0;
	var bonusTipsComission = 0;
	var supplementaryIncome = 0;
	var spousesIncome = 0;
	var netRentalIncome = 0;
	
	/*	Total Expenses Breakdown */
	dashBoardData['Total Expenses Breakdown'] = {};
	dashBoardData['Assets Liabilities'] = {};
	dashBoardData['Tax Summary'] = {};
	dashBoardData['Net Worth Graph'] = {};
	dashBoardData['Input Sheet'] = {};
	var higherEducationExpenses = 0;
	var livingExpenses = 0;
	var carExpenses = 0;
	var childrenPetExpenses = 0;
	var homesExpenses = 0;
	var interestExpenses = 0;
	
	_.forEach(incomeStatementData, (value, year)=> {
		if(year > 0){
		if (year >= startYear && year <= endYear) {
			if( incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year] !== undefined && incomeStatementData[year] !== '' ){
				//Higher Education Income
				higherEducationIncome += incomeStatementData[year]['Gross Income']['Income Earned during Higher Education'];
				//Career Income
				// careerIncome += incomeStatementData[year]['Gross Income'][$rootScope.occupationTitle];
				// careerIncome += incomeStatementData[year]['Gross Income'][$rootScope.advanceOccupationTitle+' - Second'];
				// careerIncome += incomeStatementData[year]['Gross Income'][$rootScope.jobTitle+' - Third'];
				// careerIncome += incomeStatementData[year]['Gross Income'][$rootScope.furtherjobTitle+' - Fourth'];
				//Bonus / Tips / Commission
				bonusTipsComission += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission'];
				bonusTipsComission += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second'];
				bonusTipsComission += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third'];
				bonusTipsComission += incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth'];
				//Supplementary Income
				supplementaryIncome += incomeStatementData[year]['Gross Income']['Supplementary Income'];
				supplementaryIncome += incomeStatementData[year]['Gross Income']['Supplementary Income - Second'];
				supplementaryIncome += incomeStatementData[year]['Gross Income']['Supplementary Income - Third'];
				supplementaryIncome += incomeStatementData[year]['Gross Income']['Supplementary Income - Fourth'];
				//Spouse's Income
				spousesIncome += incomeStatementData[year]['Gross Income']['Spouses Income'];
				//Net Rental Income
				netRentalIncome += incomeStatementData[year]['Gross Income']['Rental Income'];
				
				/*	Total Expenses Breakdown */
				//Higher Education
				higherEducationExpenses += incomeStatementData[year]['livingExpenses']['Higher Education Tuition and Fees'];
				higherEducationExpenses += incomeStatementData[year]['livingExpenses']['Higher Education Room & Board'];
				//Living
				livingExpenses += incomeStatementData[year]['livingExpenses']['Off Campus Food'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Rent'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Utilities'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Food'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Clothing'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Entertainment'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Technology'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Transportation'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Miscellaneous'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Healthcare Costs other than Premium'];
				livingExpenses += incomeStatementData[year]['livingExpenses']['Health Insurance Premium'];
				//Car
				carExpenses += incomeStatementData[year]['Total Car Expenses'];
				//Children & Pet
				childrenPetExpenses += incomeStatementData[year]['livingExpenses']['Children'];
				childrenPetExpenses += incomeStatementData[year]['livingExpenses']['Pet'];
				//Real Estate Values
				homesExpenses += incomeStatementData[year]['Total Real Estate Expenses'];
				//Interest
				interestExpenses += incomeStatementData[year]['Total Interest Expenses'];
			}
		}
	}
	});
	dashBoardData['Total Gross Income Breakdown']['Higher Education Income'] = higherEducationIncome;
	dashBoardData['Total Gross Income Breakdown']['Career Income'] = careerIncome;
	dashBoardData['Total Gross Income Breakdown']['Bonus / Tips / Commission'] = bonusTipsComission;
	dashBoardData['Total Gross Income Breakdown']['Supplementary Income'] = supplementaryIncome;
	dashBoardData['Total Gross Income Breakdown']['Spouse Income'] = spousesIncome;
	dashBoardData['Total Gross Income Breakdown']['Net Rental Income'] = netRentalIncome;
	dashBoardData['Total Gross Income Breakdown']['Total'] = higherEducationIncome + careerIncome + bonusTipsComission + supplementaryIncome + spousesIncome + netRentalIncome;
	
	/*	Total Expenses Breakdown */
	dashBoardData['Total Expenses Breakdown']['Higher Education'] = -higherEducationExpenses;
	dashBoardData['Total Expenses Breakdown']['Living'] = -livingExpenses;
	dashBoardData['Total Expenses Breakdown']['Car'] = -carExpenses;
	dashBoardData['Total Expenses Breakdown']['Children Pet'] = -childrenPetExpenses;
	dashBoardData['Total Expenses Breakdown']['Home'] = -homesExpenses;
	dashBoardData['Total Expenses Breakdown']['Interest'] = -interestExpenses;
	dashBoardData['Total Expenses Breakdown']['Total'] = -(higherEducationExpenses + livingExpenses + carExpenses + childrenPetExpenses + homesExpenses + interestExpenses);
	var cashValue = 0;
	var otherValuablesValue = 0;
	var autoPropertyValue = 0;
	var businessValue = 0;
	var realEstateValue = 0;
	var k401Value = 0;
	var rothk401kValue = 0;
	var traditionalIraValue = 0;
	var rothIraValue = 0;
	var sephIraValue = 0;
	var pensionValue = 0;
	var shortTermInvestmentsValue = 0;
	var longTermInvestmentsValue = 0;
	var hsaValue = 0;
	var lifeInsuranceValue = 0;
	var plan529Value = 0;
	var existingCreditCardDebtValue = 0;
	var existingPersonalLoansValue = 0;
	var studentLoansValue = 0;
	var carLoansValue = 0;
	var mortgagesValue = 0;
	var netWorthValue = 0;
	//Tax Summary
	var federalIncomeValue = 0;
	var socialSecurityValue = 0;
	var medicareValue = 0;
	var stateLocalValue = 0;
	var totalFederalIncomeValue = 0;
	var totalSocialSecurityValue = 0;
	var totalMedicareValue = 0;
	var totalStateLocalValue = 0;
	
	//Input Sheet Values
	var debtToIncomeRatioValue = 0;
	var currentRatioValue = 0;
	var savingsRateValue = 0;
	var investmentAssetsValue = 0;
	
	//Investments Value
	var k401InvestmentsValue = 0;
	var rothIraInvestmentsValue = 0;
	var sephIraInvestmentsValue = 0;
	var pensionInvestmentsValue = 0;
	
	var higherEducationIncomeYear = 0;
	var careerIncomeYear = 0;
	var bonusTipsComissionYear = 0;
	var supplementaryIncomeYear = 0;
	var spousesIncomeYear = 0;
	var netRentalIncomeYear = 0;
	var totalGrossIncomeBreakDownValue = 0;
	var finalTotalGrossIncomeBreakDownValue = 0;
	var higherEducationExpensesYear = 0;
	var TaxesExpensesYear = 0;
	var livingExpensesYear = 0;
	var carExpensesYear = 0;
	var childrenPetExpensesYear = 0;
	var homesExpensesYear = 0;
	var interestExpensesYear = 0;
	var totalExpensesBreakDownValue = 0;
	var finalTotalExpensesBreakDownValue = 0;
	//Total Assets Graph
	var totalAssetsArray = [];
	var totalLiabilitiesArray = [];
	var netChangeInCashArray = [];
	var debtToIncomeRatioArray = [];
	var currentRatioArray = [];
	var savingsRateArray = [];
	var totalAssetsDebtArray = [];
	//Total Assets, Total Liabilities, Net Change in Cash
	for (year1; year1 < yearLimit1; year1++) {
		dashBoardData[year1] = {};
		dashBoardData[year1]['Total Assets'] = {};
		dashBoardData[year1]['Tax Summary'] = {};
		dashBoardData[year1]['Input Sheet'] = {};
		dashBoardData[year1]['Investments'] = {};
		dashBoardData[year1]['Total Gross Income Breakdown'] = {};
		dashBoardData[year1]['Total Expenses Breakdown'] = {};
		
		//Total Assets
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Total Assets'] !== undefined ){
			dashBoardData[year1]['Total Assets'] = balanceSheetData[year1]['Total Assets'];
		} else{
			dashBoardData[year1]['Total Assets'] = 0;	
		}
		totalAssetsArray.push(dashBoardData[year1]['Total Assets']);
		//Total Liabilities
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Total Liabilities'] !== undefined ){
			dashBoardData[year1]['Total Liabilities'] = balanceSheetData[year1]['Total Liabilities'];
		} else{
			dashBoardData[year1]['Total Liabilities'] = 0;	
		}
		totalLiabilitiesArray.push(dashBoardData[year1]['Total Liabilities']);
		//Net Change in Cash
		if( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[year1] !== undefined && cashFlowData[year1] !== '' && cashFlowData[year1]['Net Change in Cash'] !== undefined ){
			dashBoardData[year1]['Net Change in Cash'] = cashFlowData[year1]['Net Change in Cash'];
		} else{
			dashBoardData[year1]['Net Change in Cash'] = 0;	
		}
		netChangeInCashArray.push(dashBoardData[year1]['Net Change in Cash']);
		//Assets and Liabilities
		//Cash
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Assets']['Cash'] !== undefined ){
			cashValue = balanceSheetData[year1]['Assets']['Cash'];
		} else{
			cashValue = 0;
		}
		assetsArr.push(cashValue);
		//Other Valuables
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Assets']['Other Valuables'] !== undefined ){
			otherValuablesValue = balanceSheetData[year1]['Assets']['Other Valuables'];
		} else{
			otherValuablesValue = 0;
		}
		otherValuablesArr.push(otherValuablesValue);
		//Auto Property
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Assets']['Auto Property'] !== undefined ){
			autoPropertyValue = balanceSheetData[year1]['Assets']['Auto Property'];
		} else{
			autoPropertyValue = 0;
		}
		autoPropertyArr.push(autoPropertyValue);
		//Real Estate Property
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Business'] !== undefined ){
			businessValue = balanceSheetData[year1]['Investments']['Business'];
		} else{
			businessValue = 0;
		}
		businessArr.push(businessValue);
		//Business
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Assets']['Real Estate Property'] !== undefined ){
			realEstateValue = balanceSheetData[year1]['Assets']['Real Estate Property'];
		} else{
			realEstateValue = 0;
		}
		realEstateArr.push(realEstateValue);
		//401(k)
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['for401k'] !== undefined ){
			k401Value = balanceSheetData[year1]['Investments']['for401k'];
		} else{
			k401Value = 0;
		}
		k401kArr.push(k401Value);
		//Roth 401(k)
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Roth 401(k) / 403b'] !== undefined ){
			rothk401kValue = balanceSheetData[year1]['Investments']['Roth 401(k) / 403b'];
		} else{
			rothk401kValue = 0;
		}
		rothk401kArr.push(rothk401kValue);
		
		//Roth 401(k)
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Traditional IRA'] !== undefined ){
			traditionalIraValue = balanceSheetData[year1]['Investments']['Traditional IRA'];
		} else{
			traditionalIraValue = 0;
		}
		traditionalIraArr.push(traditionalIraValue);
		//Roth IRA
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Roth IRA'] !== undefined ){
			rothIraValue = balanceSheetData[year1]['Investments']['Roth IRA'];
		} else{
			rothIraValue = 0;
		}
		rothIraArr.push(rothIraValue);
		//Seph IRA
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Seph IRA'] !== undefined ){
			sephIraValue = balanceSheetData[year1]['Investments']['Seph IRA'];
		} else{
			sephIraValue = 0;
		}
		sephIraArr.push(sephIraValue);
		//Pension
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Pension'] !== undefined ){
			pensionValue = balanceSheetData[year1]['Investments']['Pension'];
		} else{
			pensionValue = 0;
		}
		pensionArr.push(pensionValue);
		//Short-Term Investments
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Short-Term Investments'] !== undefined ){
			shortTermInvestmentsValue = balanceSheetData[year1]['Investments']['Short-Term Investments'];
		} else{
			shortTermInvestmentsValue = 0;
		}
		shortTermInvestmentsArr.push(shortTermInvestmentsValue);
		//Long-Term Investments
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Long-Term Investments'] !== undefined ){
			longTermInvestmentsValue = balanceSheetData[year1]['Investments']['Long-Term Investments'];
		} else{
			longTermInvestmentsValue = 0;
		}
		longTermInvestmentsArr.push(longTermInvestmentsValue);
		//Health Savings Account (HSA)
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Health Savings Account (HSA)'] !== undefined ){
			hsaValue = balanceSheetData[year1]['Investments']['Health Savings Account (HSA)'];
		} else{
			hsaValue = 0;
		}
		hsaArr.push(hsaValue);
		//Life Insurance Cash Value
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['Life Insurance'] !== undefined ){
			lifeInsuranceValue = balanceSheetData[year1]['Investments']['Life Insurance'];
		} else{
			lifeInsuranceValue = 0;
		}
		lifeInsuranceArr.push(lifeInsuranceValue);
		
		//529 Plan
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Investments']['529 Plan'] !== undefined ){
			plan529Value = balanceSheetData[year1]['Investments']['529 Plan'];
		} else{
			plan529Value = 0;
		}
		plan529Arr.push(plan529Value);
		//Existing Credit Card Debt
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Liabilities']['Existing Credit Card Debt'] !== undefined ){
			existingCreditCardDebtValue = balanceSheetData[year1]['Liabilities']['Existing Credit Card Debt'];
		} else{
			existingCreditCardDebtValue = 0;
		}
		existingCreditCardDebtArr.push(existingCreditCardDebtValue);
		//Existing Personal Loans
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Liabilities']['Existing Personal Loans'] !== undefined ){
			existingPersonalLoansValue = balanceSheetData[year1]['Liabilities']['Existing Personal Loans'];
		} else{
			existingPersonalLoansValue = 0;
		}
		existingPersonalLoansArr.push(existingPersonalLoansValue);
		//Student Loans
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Liabilities']['Student Loans'] !== undefined ){
			studentLoansValue = balanceSheetData[year1]['Liabilities']['Student Loans'];
		} else{
			studentLoansValue = 0;
		}
		studentLoansArr.push(studentLoansValue);
		//Car Loans
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Liabilities']['Car Loans'] !== undefined ){
			carLoansValue = balanceSheetData[year1]['Liabilities']['Car Loans'];
		} else{
			carLoansValue = 0;
		}
		carLoansArr.push(carLoansValue);
		//Mortgages
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Liabilities']['Mortgages'] !== undefined ){
			mortgagesValue = balanceSheetData[year1]['Liabilities']['Mortgages'];
		} else{
			mortgagesValue = 0;
		}
		mortgagesArr.push(mortgagesValue);
		//Net Worth
		if( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year1] !== undefined && balanceSheetData[year1] !== '' && balanceSheetData[year1]['Net Worth'] !== undefined ){
			netWorthValue = balanceSheetData[year1]['Net Worth'];
		} else{
			netWorthValue = 0;
		}
		netWorthsArr.push(netWorthValue);
		
		//Tax Summary
		//Federal Income
		if( incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year1] !== undefined && incomeStatementData[year1] !== '' && incomeStatementData[year1]['Federal Taxable Income'] !== undefined && incomeStatementData[year1]['taxes']['Federal Income Tax (x Federal Taxable Income)'] !== undefined){
			federalIncomeValue = (incomeStatementData[year1]['Federal Taxable Income'] / 100)*incomeStatementData[year1]['taxes']['Federal Income Tax (x Federal Taxable Income)'];
		} else{
			federalIncomeValue = 0;	
		}
		totalFederalIncomeValue += federalIncomeValue;
		dashBoardData[year1]['Tax Summary']['Federal Income'] = federalIncomeValue;
		//Social Security
		if( incomeStatementData !== undefined && 
		   incomeStatementData !== '' && incomeStatementData[year1] !== undefined && 
		   incomeStatementData[year1] !== '' && incomeStatementData[year1]['Total Income'] !== undefined && incomeStatementData[year1]['taxes']['Social Security Tax (x Total Gross Income with limits)'] !== undefined && databaseData !== undefined && databaseData['Data'] !== undefined && 
		   incomeStatementData[year1]['Total Income'] > databaseData['Data']['Social Security Tax Limit']){
			socialSecurityValue = (databaseData['Data']['Social Security Tax Limit'] / 100) * incomeStatementData[year1]['taxes']['Social Security Tax (x Total Gross Income with limits)'];
		} else{
			socialSecurityValue = (incomeStatementData[year1]['Total Income']/100)*incomeStatementData[year1]['taxes']['Social Security Tax (x Total Gross Income with limits)'];
		}
		totalSocialSecurityValue += socialSecurityValue;
		dashBoardData[year1]['Tax Summary']['Social Security'] = socialSecurityValue;
		//Medicare
		if( incomeStatementData !== undefined && 
		   incomeStatementData !== '' && incomeStatementData[year1] !== undefined && 
		   incomeStatementData[year1] !== '' && incomeStatementData[year1]['Total Income'] !== undefined && incomeStatementData[year1]['taxes']['Medicare Tax (x Total Gross Income with limits)'] !== undefined){
			medicareValue = (incomeStatementData[year1]['Total Income']/100)*incomeStatementData[year1]['taxes']['Medicare Tax (x Total Gross Income with limits)'];
		} else{
			medicareValue = 0;	
		}
		totalMedicareValue += medicareValue;
		dashBoardData[year1]['Tax Summary']['Medicare'] = medicareValue;
		//State & Local
		if( incomeStatementData !== undefined && 
		   incomeStatementData !== '' && incomeStatementData[year1] !== undefined && 
		   incomeStatementData[year1] !== '' && incomeStatementData[year1]['Adjusted Gross Income'] !== undefined && incomeStatementData[year1]['taxes']['State & Local Taxes (x Adjusted Gross Income)'] !== undefined){
			stateLocalValue = (incomeStatementData[year1]['Adjusted Gross Income']/100)*incomeStatementData[year1]['taxes']['State & Local Taxes (x Adjusted Gross Income)'];
		} else{
			stateLocalValue = 0;	
		}
		totalStateLocalValue += stateLocalValue;
		dashBoardData[year1]['Tax Summary']['State Local'] = stateLocalValue;
		
		//Input Data Values
		//Debt-to-Income Ratio
		//console.log('year1', year1);
		if( inputSheetData !== undefined && 
		   inputSheetData !== '' &&  
		   inputSheetData[year1] !== undefined && 
		   inputSheetData[year1] !== '' &&  
		   inputSheetData[year1]['Debt-to-Income Ratio Ratio'] !== undefined){
			//console.log('year1', year1);
			debtToIncomeRatioValue = inputSheetData[year1]['Debt-to-Income Ratio Ratio'];
		} else{
			debtToIncomeRatioValue = 0;	
		}
		debtToIncomeRatioArray.push(debtToIncomeRatioValue);
		dashBoardData[year1]['Input Sheet']['Debt-to-Income Ratio'] = debtToIncomeRatioValue;
		//Current Ratio
		if( inputSheetData !== undefined && 
		   inputSheetData !== '' && inputSheetData[year1] !== undefined && 
		   inputSheetData[year1] !== '' && inputSheetData[year1]['Current Ratio'] !== undefined){
			currentRatioValue = inputSheetData[year1]['Current Ratio'];
		} else{
			currentRatioValue = 0;	
		}
		currentRatioArray.push(currentRatioValue);
		dashBoardData[year1]['Input Sheet']['Current Ratio'] = currentRatioValue;
		//Savings Rate
		if( inputSheetData !== undefined && 
		   inputSheetData !== '' && inputSheetData[year1] !== undefined && 
		   inputSheetData[year1] !== '' && inputSheetData[year1]['Savings Rate'] !== undefined){
			savingsRateValue = inputSheetData[year1]['Savings Rate'];
		} else{
			savingsRateValue = 0;	
		}
		savingsRateArray.push(savingsRateValue);
		dashBoardData[year1]['Input Sheet']['Savings Rate'] = savingsRateValue;
		//Total Assets to Total Debt
		if( inputSheetData !== undefined && 
		   inputSheetData !== '' && inputSheetData[year1] !== undefined && 
		   inputSheetData[year1] !== '' && inputSheetData[year1]['Investment Assets to Total Assets'] !== undefined){
			investmentAssetsValue = inputSheetData[year1]['Investment Assets to Total Assets'];
		} else{
			investmentAssetsValue = 0;	
		}
		totalAssetsDebtArray.push(investmentAssetsValue);
		dashBoardData[year1]['Input Sheet']['Investment Assets to Total Assets'] = investmentAssetsValue;
		//401(k) 
		if( investmentsData !== undefined && 
		   investmentsData !== '' && investmentsData['401k Second'] !== undefined && 
		   investmentsData['401k Second'] !== '' && investmentsData['401k Second'][year1] !== undefined && 
		   investmentsData['401k Second'][year1] !== '' && investmentsData['401k Second'][year1]['Ending Total Balance'] !== undefined){
			k401InvestmentsValue = investmentsData['401k Second'][year1]['Ending Total Balance'];
		} else{
			k401InvestmentsValue = 0;	
		}
		dashBoardData[year1]['Investments']['401(k)'] = k401InvestmentsValue;
		//Roth IRA
		if( investmentsData !== undefined && 
		   investmentsData !== '' && investmentsData['Roth IRA Second'] !== undefined && 
		   investmentsData['Roth IRA Second'] !== '' && investmentsData['Roth IRA Second'][year1] !== undefined && 
		   investmentsData['Roth IRA Second'][year1] !== '' && investmentsData['Roth IRA Second'][year1]['Ending Balance'] !== undefined){
			rothIraInvestmentsValue = investmentsData['Roth IRA Second'][year1]['Ending Balance'];
		} else{
			rothIraInvestmentsValue = 0;	
		}
		dashBoardData[year1]['Investments']['Roth IRA'] = rothIraInvestmentsValue;
		//Seph IRA
		if( investmentsData !== undefined && 
		   investmentsData !== ''  && investmentsData['Seph IRA Second'] !== undefined && 
		   investmentsData['Seph IRA Second'] !== '' && investmentsData['Seph IRA Second'][year1] !== undefined && 
		   investmentsData['Seph IRA Second'][year1] !== '' && investmentsData['Seph IRA Second'][year1]['Ending Balance'] !== undefined){
			sephIraInvestmentsValue = investmentsData['Seph IRA Second'][year1]['Ending Balance'];
		} else{
			sephIraInvestmentsValue = 0;
		}
		dashBoardData[year1]['Investments']['Seph IRA'] = sephIraInvestmentsValue;
		//Pension
		if( investmentsData !== undefined && 
		   investmentsData !== ''&& investmentsData['Pension Second'] !== undefined && 
		   investmentsData['Pension Second'] !== '' && investmentsData['Pension Second'][year1] !== undefined && 
		   investmentsData['Pension Second'][year1] !== '' && investmentsData['Pension Second'][year1]['Ending Balance'] !== undefined){
			pensionInvestmentsValue = investmentsData['Pension Second'][year1]['Ending Balance'];
		} else{
			pensionInvestmentsValue = 0;	
		}
		dashBoardData[year1]['Investments']['Pension'] = pensionInvestmentsValue;
		//For Total Gross Income Breakdown and Total Expenses Breakdown
		if( incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year1] !== undefined && incomeStatementData[year1] !== '' ){
			//Higher Education Income
			higherEducationIncomeYear = incomeStatementData[year1]['Gross Income']['Income Earned during Higher Education'];
			dashBoardData[year1]['Total Gross Income Breakdown']['Higher Education Income'] = higherEducationIncomeYear;
			higherEducationIncomeArray.push(higherEducationIncomeYear);
			
			//Career Income
			// careerIncomeYear = 	incomeStatementData[year1]['Gross Income'][$rootScope.occupationTitle] + 
			// 					incomeStatementData[year1]['Gross Income'][$rootScope.advanceOccupationTitle+' - Second'] + 
			// 					incomeStatementData[year1]['Gross Income'][$rootScope.jobTitle+' - Third'] + 
			// 					incomeStatementData[year1]['Gross Income'][$rootScope.furtherjobTitle+' - Fourth'];
			dashBoardData[year1]['Total Gross Income Breakdown']['Career Income'] = careerIncomeYear;
			careerIncomeYearArray.push(careerIncomeYear);
			
			//Bonus / Tips / Commission
			bonusTipsComissionYear =    incomeStatementData[year1]['Gross Income']['Bonus / Tips / Commission'] +
										incomeStatementData[year1]['Gross Income']['Bonus / Tips / Commission - Second'] +
										incomeStatementData[year1]['Gross Income']['Bonus / Tips / Commission - Third'] +
										incomeStatementData[year1]['Gross Income']['Bonus / Tips / Commission - Fourth'];
			dashBoardData[year1]['Total Gross Income Breakdown']['Bonus / Tips / Commission'] = bonusTipsComissionYear;
			bonusTipsComissionYearArray.push(bonusTipsComissionYear);
			
			//Supplementary Income
			supplementaryIncomeYear = 	incomeStatementData[year1]['Gross Income']['Supplementary Income'] + 
										incomeStatementData[year1]['Gross Income']['Supplementary Income - Second'] +
										incomeStatementData[year1]['Gross Income']['Supplementary Income - Third'] +
										incomeStatementData[year1]['Gross Income']['Supplementary Income - Fourth'];
			dashBoardData[year1]['Total Gross Income Breakdown']['Supplementary Income'] = supplementaryIncomeYear;
			supplementaryIncomeYearArray.push(supplementaryIncomeYear);
			
			//Spouse's Income
			spousesIncomeYear = incomeStatementData[year1]['Gross Income']['Spouses Income'];
			dashBoardData[year1]['Total Gross Income Breakdown']['Spouses Income'] = spousesIncomeYear;
			spousesIncomeYearArray.push(spousesIncomeYear);
			
			//Net Rental Income
			netRentalIncomeYear = incomeStatementData[year1]['Gross Income']['Rental Income'];
			dashBoardData[year1]['Total Gross Income Breakdown']['Total Rental Income'] = netRentalIncomeYear;
			netRentalIncomeYearArray.push(netRentalIncomeYear);
			
			totalGrossIncomeBreakDownValue = higherEducationIncomeYear + careerIncomeYear + bonusTipsComissionYear + supplementaryIncomeYear + spousesIncomeYear + netRentalIncomeYear;
			finalTotalGrossIncomeBreakDownValue += totalGrossIncomeBreakDownValue;
			totalGrossBreakDownArray.push(totalGrossIncomeBreakDownValue);
			
			/*	Total Expenses Breakdown */
			//Taxes
			TaxesExpensesYear = 	-incomeStatementData[year1]['Total Taxes'];
			dashBoardData[year1]['Total Expenses Breakdown']['Taxes'] = TaxesExpensesYear;
			TaxesExpensesYearArray.push(TaxesExpensesYear);
			
			//Higher Education
			higherEducationExpensesYear = 	-(incomeStatementData[year1]['livingExpenses']['Higher Education Tuition and Fees'] + 
											incomeStatementData[year1]['livingExpenses']['Higher Education Room & Board']);
			dashBoardData[year1]['Total Expenses Breakdown']['Supplementary Income'] = higherEducationExpensesYear;
			higherEducationExpensesYearArray.push(higherEducationExpensesYear);
			
			//Living
			livingExpensesYear = 	-(incomeStatementData[year1]['livingExpenses']['Off Campus Food'] + 
									incomeStatementData[year1]['livingExpenses']['Rent'] + 
									incomeStatementData[year1]['livingExpenses']['Utilities'] + 
									incomeStatementData[year1]['livingExpenses']['Food'] + 
									incomeStatementData[year1]['livingExpenses']['Clothing'] + 
									incomeStatementData[year1]['livingExpenses']['Entertainment'] + 
									incomeStatementData[year1]['livingExpenses']['Technology'] + 
									incomeStatementData[year1]['livingExpenses']['Transportation'] + 
									incomeStatementData[year1]['livingExpenses']['Miscellaneous'] + 
									incomeStatementData[year1]['livingExpenses']['Healthcare Costs other than Premium'] + 
									incomeStatementData[year1]['livingExpenses']['Health Insurance Premium']);
			dashBoardData[year1]['Total Expenses Breakdown']['Living'] = livingExpensesYear;
			livingExpensesYearArray.push(livingExpensesYear);
			
			//Car
			carExpensesYear = -incomeStatementData[year1]['Total Car Expenses'];
			dashBoardData[year1]['Total Expenses Breakdown']['Total Car Expenses'] = carExpensesYear;
			carExpensesYearArray.push(carExpensesYear);
			
			//Children & Pet
			childrenPetExpensesYear = 	-(incomeStatementData[year1]['livingExpenses']['Children'] + 
									incomeStatementData[year1]['livingExpenses']['Pet']);
			dashBoardData[year1]['Total Expenses Breakdown']['Children and Pet'] = childrenPetExpensesYear;
			childrenPetExpensesYearArray.push(childrenPetExpensesYear);
			
			//Real Estate Values
			homesExpensesYear = -incomeStatementData[year1]['Total Real Estate Expenses'];
			dashBoardData[year1]['Total Expenses Breakdown']['Total Real Estate Expenses'] = homesExpensesYear;
			homesExpensesYearArray.push(homesExpensesYear);
			
			//Interest
			interestExpensesYear = -incomeStatementData[year1]['Total Interest Expenses'];
			dashBoardData[year1]['Total Expenses Breakdown']['Total Interest Expenses'] = interestExpensesYear;
			interestExpensesYearArray.push(interestExpensesYear);
			
			totalExpensesBreakDownValue = higherEducationExpensesYear + TaxesExpensesYear + livingExpensesYear + carExpensesYear + childrenPetExpensesYear + homesExpensesYear + interestExpensesYear;
			finalTotalExpensesBreakDownValue += totalExpensesBreakDownValue;
			totalExpensesBreakDownArray.push(totalExpensesBreakDownValue);
		}
		dashBoardData[year1]['Total Gross Income Breakdown']['Total'] = totalGrossIncomeBreakDownValue;
		dashBoardData[year1]['Total Expenses Breakdown']['Total'] = totalExpensesBreakDownValue;
		
		totalGrossIncomeBreakDownValue = 0;
		totalExpensesBreakDownValue = 0;
	}
	
	//Total Gross Income Breakdown
	dashBoardData['Total Gross Income Breakdown']['Higher Education Income'] = higherEducationIncomeArray;
	dashBoardData['Total Gross Income Breakdown']['Career Income'] = careerIncomeYearArray;
	dashBoardData['Total Gross Income Breakdown']['Bonus/Tips/Commission'] = bonusTipsComissionYearArray;
	dashBoardData['Total Gross Income Breakdown']['Supplementary Income'] = supplementaryIncomeYearArray;
	dashBoardData['Total Gross Income Breakdown']['Spouse Income'] = spousesIncomeYearArray;
	dashBoardData['Total Gross Income Breakdown']['Net Rental Income'] = netRentalIncomeYearArray;
	dashBoardData['Total Gross Income Breakdown']['Total'] = totalGrossBreakDownArray;
	dashBoardData['Total Gross Income Breakdown']['All Total'] = finalTotalGrossIncomeBreakDownValue;
	

	dashBoardData['Total Expenses Breakdown']['Taxes'] = TaxesExpensesYearArray;
	dashBoardData['Total Expenses Breakdown']['Higher Education'] = higherEducationExpensesYearArray;
	dashBoardData['Total Expenses Breakdown']['Living'] = livingExpensesYearArray;
	dashBoardData['Total Expenses Breakdown']['Children & Pet'] = childrenPetExpensesYearArray;
	dashBoardData['Total Expenses Breakdown']['Car'] = carExpensesYearArray;
	dashBoardData['Total Expenses Breakdown']['Real Estate'] = homesExpensesYearArray;
	dashBoardData['Total Expenses Breakdown']['Loan Payments'] = interestExpensesYearArray;
	dashBoardData['Total Expenses Breakdown']['Total'] = totalExpensesBreakDownArray;
	dashBoardData['Total Expenses Breakdown']['All Total'] = finalTotalExpensesBreakDownValue;
	
	dashBoardData['Assets Liabilities']['Cash'] = assetsArr;
	dashBoardData['Assets Liabilities']['Other Valuables'] = otherValuablesArr;
	dashBoardData['Assets Liabilities']['Auto Property'] = autoPropertyArr;
	dashBoardData['Assets Liabilities']['Real Estate Property'] = realEstateArr;
	dashBoardData['Assets Liabilities']['Business'] = businessArr;
	dashBoardData['Assets Liabilities']['401k'] = k401kArr;
	dashBoardData['Assets Liabilities']['Roth 401(k)'] = rothk401kArr;
	dashBoardData['Assets Liabilities']['Traditional IRA'] = traditionalIraArr;
	dashBoardData['Assets Liabilities']['Roth Ira'] = rothIraArr;
	dashBoardData['Assets Liabilities']['Seph Ira'] = sephIraArr;
	dashBoardData['Assets Liabilities']['Pension'] = pensionArr;
	dashBoardData['Assets Liabilities']['Short-Term Investments'] = shortTermInvestmentsArr;
	dashBoardData['Assets Liabilities']['Long-Term Investments'] = longTermInvestmentsArr;
	dashBoardData['Assets Liabilities']['Health Savings Account (HSA)'] = hsaArr;
	dashBoardData['Assets Liabilities']['Life Insurance Cash Value'] = lifeInsuranceArr;
	dashBoardData['Assets Liabilities']['529 Plan'] = plan529Arr;
	dashBoardData['Assets Liabilities']['Existing Credit Card Debt'] = existingCreditCardDebtArr;
	dashBoardData['Assets Liabilities']['Existing Personal Loans'] = existingPersonalLoansArr;
	dashBoardData['Assets Liabilities']['Student Loans'] = studentLoansArr;
	dashBoardData['Assets Liabilities']['Car Loans'] = carLoansArr;
	dashBoardData['Assets Liabilities']['Mortgages'] = mortgagesArr;
	dashBoardData['Assets Liabilities']['Net Worth'] = netWorthsArr;
	
	
	dashBoardData['Tax Summary']['Federal Income'] = totalFederalIncomeValue;
	dashBoardData['Tax Summary']['Social Security'] = totalSocialSecurityValue;
	dashBoardData['Tax Summary']['Medicare'] = totalMedicareValue;
	dashBoardData['Tax Summary']['State Local'] = totalStateLocalValue;
	dashBoardData['Tax Summary']['Total Taxes Paid'] = totalFederalIncomeValue + totalSocialSecurityValue + totalMedicareValue + totalStateLocalValue;
	
	//For Net Worth Graph
	var outputSheetCompleteData = outputbackendData;
	if( outputSheetCompleteData['yearsList'][0] === 0 ){
		outputSheetCompleteData['yearsList'] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
	}
	dashBoardData['Net Worth Graph']['Gross Income']  	= outputSheetCompleteData['grossIncomeGraphData'];
	dashBoardData['Net Worth Graph']['Taxes']  			= outputSheetCompleteData['incomeAfterTaxesGraphData'];
	dashBoardData['Net Worth Graph']['Total Expenses']	= outputSheetCompleteData['totalExpensesGraphData'];
	dashBoardData['Net Worth Graph']['Net Worth']  		= outputSheetCompleteData['netWorthGraphData']; 
	dashBoardData['Net Worth Graph']['Years List']  	= outputSheetCompleteData['yearsList']; 
	dashBoardData['Assets Liabilities']['Years List']  	= outputSheetCompleteData['yearsList']; 
	
	//Total Asets Graph Array
	dashBoardData['Assets Liabilities']['Total Assets']  	= totalAssetsArray; 
	dashBoardData['Assets Liabilities']['Years List']  	= outputSheetCompleteData['yearsList']; 
	
	//Total Liabilities Graph Array
	dashBoardData['Assets Liabilities']['Total Liabilities']  	= totalLiabilitiesArray; 
	
	//Net Change in Cash
	dashBoardData['Assets Liabilities']['Net Change In Cash']  	= netChangeInCashArray; 
	
	//Debt Ration
	dashBoardData['Input Sheet']['Debt To Income Ratio'] = debtToIncomeRatioArray;
	dashBoardData['Input Sheet']['Years List']  	= outputSheetCompleteData['yearsList']; 
	
	//Current Ratio
	dashBoardData['Input Sheet']['Current Ratio'] = currentRatioArray;
	
	//Savings Rate
	dashBoardData['Input Sheet']['Savings Rate'] = savingsRateArray;
	
	//Total Assets to Total Debt 
	dashBoardData['Input Sheet']['Total Assets To Total Debt'] = totalAssetsDebtArray;

	function dashBoardCalcsCompleteData() {
		let dashBoardCalcsData = {};
		dashBoardCalcsData['Data'] = dashBoardData;
		//console.log('dashBoardCalcsData', dashBoardCalcsData);
		return dashBoardCalcsData;
	}
	return dashBoardCalcsCompleteData();
}


export default dashBoardCalcs;
