import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/higher-education/styles';
import { converyIntoThousandsOneDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';

const followersMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 10000,
    label: '$10k',
  },
  {
    value: 20000,
    label: '$20k',
  },
  {
    value: 30000,
    label: '$30k',
  },
  {
    value: 40000,
    label: '$40k',
  },
  {
    value: 50000,
    label: '$50k',
  },
];

const EarningIncome = ( props ) => {
  const {
    classes, inputData, updateData
  } = props;

  const [inputs, setInputs] = React.useState();
  const [yearsDropDown, setYearsDropDown] = React.useState([]);
  const [optionDropDown, setOptionDropDown] = React.useState([]);

  useEffect(()=>{
    let inputValues = {...inputs};
    const expensesYearsArray = [];
    const i = parseInt(inputData.start_year);
    const j = parseInt(inputData.years_diff);
    const max = parseInt(moduleServices.module1Data.start_year) + 15;
    if (i > 0) {
        for (let k = 0; k < j; k+=1) {
            if (i + k < max) {
                expensesYearsArray.push(i + k);
            }
        }
    }
    setYearsDropDown(expensesYearsArray);

    const yearIndex = expensesYearsArray.indexOf(inputData.income_start_year);
    if(yearIndex === -1) {
      inputValues['income_start_year'] = expensesYearsArray[0];    
    } else {
      inputValues['income_start_year'] = inputData.income_start_year;
    }

    const incomeYears = parseInt(inputData['graduation_year'],10)-inputValues['income_start_year']+1;
    const dropDownEndYears = [];
    if(incomeYears > 0) {
        for (let ie = incomeYears ; ie >= 1; ie-=1) {
            dropDownEndYears.unshift(ie);
        }
    }
    setOptionDropDown(dropDownEndYears);

    if(inputData.income_end_year !== undefined && inputData.income_end_year > 0) {
      inputValues['income_year_diff'] = parseInt(inputData.income_end_year)-parseInt(inputData.income_start_year)+1; 

      const yearDiffIndex = dropDownEndYears.indexOf(inputData['income_year_diff']);
      if(yearDiffIndex === -1) {
          inputValues['income_year_diff'] = dropDownEndYears[dropDownEndYears.length-1]; 
      }
    } else {
      inputValues['income_year_diff'] = dropDownEndYears[dropDownEndYears.length-1];
    }

    inputValues['income_end_year'] = inputValues['income_start_year']+inputValues['income_year_diff']-1;

    if(inputData.yearly_income !== undefined) {
      inputValues['yearly_income'] = inputData.yearly_income; 
    } else {
      inputValues['yearly_income'] = 0;
    }
    inputValues['yearly_income_slider'] = inputValues['yearly_income']; 
    setInputs(inputValues);
    updateData('total_obj',{...inputData, ...inputValues})
  }, [])

  const updateValue = ( e, value, field, type ) => {
    let inputValues = {...inputs};
    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else if( type === 'select' ) {
      newvalue = parseInt(e.target.value,10);
    } else {
      newvalue = e.target.value;
      if(newvalue === '') {
        newvalue = 0;
      }
      newvalue = convertCurrencyToInteger(newvalue);
    }
    inputValues[field] =  newvalue;
    if(field === 'yearly_income_slider') {
      inputValues['yearly_income'] = inputValues[field];
    } else if(field === 'yearly_income') {
      inputValues['yearly_income_slider'] = inputValues[field];
    }

    if(field === 'income_start_year') {
      const incomeYears = parseInt(inputData['graduation_year'],10)-inputValues['income_start_year']+1;
      const dropDownEndYears = [];
      if(incomeYears > 0) {
          for (let ie = incomeYears ; ie >= 1; ie-=1) {
              dropDownEndYears.unshift(ie);
          }
      }
      setOptionDropDown(dropDownEndYears);

      const yearDiffIndex = dropDownEndYears.indexOf(inputValues['income_year_diff']);
      if(yearDiffIndex === -1) {
        inputValues['income_year_diff'] = dropDownEndYears[dropDownEndYears.length-1]; 
      }
      inputValues['income_end_year'] = inputValues['income_start_year']+inputValues['income_year_diff']-1;
    } else if(field === 'income_year_diff'){
      inputValues['income_end_year'] = inputValues['income_start_year']+inputValues['income_year_diff']-1;
    }
    setInputs(inputValues);
    updateData('total_obj',{...inputData, ...inputValues})
  }
    
  const estimatedAfterTaxRate = ((inputs !== undefined && inputs.yearly_income !== undefined ? (inputs.yearly_income) : 0)/100)*(100-moduleServices.module2Data.estimatedTaxRateConversation);

  return (
    <div className={ classes.contentBlock }>
      <p>Working during higher education is a great way to earn money, build your resume and minimize student loans. As you change the before-tax income below, we will estimate your taxes so that you plan with after-tax dollars.</p>
      <p>How much income do you plan to make in one year during higher education? This number should include work during the school year and a summer internship.</p>
      {inputs !== undefined &&  inputs.income_start_year !== undefined && (
        <div>
          <ul>
            <li>
              <span>I plan to start earning income in year&nbsp;</span>
              {' '}
              <select value={ inputs.income_start_year } onChange={ (e)=>updateValue( e, '', 'income_start_year', 'select' ) }>
                {yearsDropDown !== undefined && yearsDropDown.map( ( year ) => <option value={ year }>{year}</option> )}
              </select>
            </li>
            <li>
              <span>I plan to earn income for &nbsp;</span>
              {' '}
              <select value={ inputs.income_year_diff } onChange={ (e)=>updateValue( e, '', 'income_year_diff', 'select' ) }>
                {optionDropDown !== undefined && optionDropDown.map( ( year ) => <option value={ year }>{year}</option> )}
              </select>
              <span>
                &nbsp;years.
              </span>
            </li>
          </ul>
        </div>
      )}

      {inputs !== undefined &&  inputs.yearly_income_slider !== undefined && (
        <div className={ classes.innnerFlowGroupMainFull }>
          <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMainFamily}` }>
            <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftFamily}` }>
                <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}` }>
                  <div>
                    <p>
                      Income
                      <br />
                      {' '}
                      (before-tax)
                    </p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.yearly_income_slider }
                        ValueLabelComponent={ ValueLabelComponent }
                        valueLabelFormat={ converyIntoThousandsOneDecimal }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="off"
                        min={ 0 }
                        max={ 50000 }
                        step={ 100 }
                        marks={ followersMarks }
                        onChange={ ( e, value ) => updateValue( e, value, 'yearly_income_slider', 'slider' ) }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.graphRightFamily}` }>
                <div>
                  <h3>Annual</h3>
                  <div className={ classes.inputRightBlock }>
                    $
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.yearly_income } onChange={ ( e ) => updateValue( e, '', 'yearly_income', 'number' ) } onFocus={ ( e ) => e.target.select() } />

                  </div>
                </div>
                <div>
                  <h3>
                    Total (
                    {inputs.income_year_diff}
                    {' '}
                    years)
                  </h3>
                  <div className={ classes.inputRightBlock }>
                    {(inputs.yearly_income !== undefined && inputs.income_year_diff !== undefined &&  inputs.yearly_income > 0 && inputs.income_year_diff > 0) ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.totalBeforeTaxIncome() } displayType="text" thousandSeparator prefix="$" />
                      ): ('$0')}
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}  ${classes.garphLeftFamily}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}` }>
                  <div className={ classes.textRight }>
                    <p>Estimated Tax Rate</p>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.graphRightFamily}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    {moduleServices.module2Data.estimatedTaxRateConversation !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.estimatedTaxRateConversation >= 0  ? moduleServices.module2Data.estimatedTaxRateConversation : -moduleServices.module2Data.estimatedTaxRateConversation } prefix={ moduleServices.module2Data.estimatedTaxRateConversation >= 0 ? '' : '(' } suffix={ moduleServices.module2Data.estimatedTaxRateConversation < 0 ? '%)':'%' } />
                    ) : ( '0.00%' )}
                  </div>
                </div>
                <div>
                  <div className={ classes.inputRightBlock }>
                    {moduleServices.module2Data.estimatedTaxRateConversation !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module2Data.estimatedTaxRateConversation >= 0  ? moduleServices.module2Data.estimatedTaxRateConversation : -moduleServices.module2Data.estimatedTaxRateConversation } prefix={ moduleServices.module2Data.estimatedTaxRateConversation >= 0 ? '' : '(' } suffix={ moduleServices.module2Data.estimatedTaxRateConversation < 0 ? '%)':'%' } />
                    ) : ( '0.00%' )}
                  </div>
                </div>
              </div>
            </div>

            <div className={ `${classes.innnerFlowGroup} ${classes.flowGroupTopGap}` }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.garphLeftFamily}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}` }>
                  <div className={ classes.textRight }>
                    <p>Estimated After-Tax Income</p>
                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.garphRightScholor}  ${classes.graphRightFamily}` }>
                <div>
                  <div className={ `${classes.inputRightBlock} ${classes.inputRightBlockBorder}` }>

                    <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ estimatedAfterTaxRate } displayType="text" prefix="$" />
                  </div>
                </div>
                <div>
                  <div className={ `${classes.inputRightBlock} ${classes.inputRightBlockBorder}` }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ moduleServices.module2Data.incomeEarnedAfterTaxesVal } displayType="text"  prefix="$" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EarningIncome.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( EarningIncome );
