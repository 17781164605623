import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from '../styles';

const WalkThruThree = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruThree)}>
            {walkThruNo === 1 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 2 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 3 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 4 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 5 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 6 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 7 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 8 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 9 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 10 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots  walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 11 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 12 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 13 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 14 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 15 ?
                <div>
                    <h4>Intelligent Timeline Sketcher</h4>
                    <p>The timeline is your big picture vision to the future. The intelligence in this timeline constantly rearranges life events in chronological order. You can use the timeline to check when certain life events are taking place and when they're not. Then, input your start and end years accordingly. Plus, you can click on any icon to go directly to that module.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(3) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(3); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

WalkThruThree.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruThree);