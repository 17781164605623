import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();
const CreditScore101 = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Credit Score 101</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score-left.png" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          A credit score is a three-digit number ranging from 300 – 850 (the higher, the better) that relates to how likely you are to repay debt. If you have a higher score, which means you are more likely to repay your debt on time and in full, banks and other lenders will give you lower interest rates on loans. Lower interest rates can save you tens of thousands of dollars when borrowing money!
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        Your credit score is very important to maintain and improve as a better credit score typically equates to lower interest rates and vice versa. And, banks and lenders will use your credit score when deciding whether to approve you for a credit card or loans such as car loans, student loans and mortgages.
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <p>
                            There are three major credit reporting agencies that track your credit score:
                          </p>
                          <p>
                            1)
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.chimebank.com/no-fees/" target="_blank" rel="budget-fy2020 noopener noreferrer">Equifax</a>
                          </p>
                          <p>
                            2)
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.chimebank.com/no-fees/" target="_blank" rel="budget-fy2020 noopener noreferrer">Experian</a>
                          </p>
                          <p>
                            3)
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.chimebank.com/no-fees/" target="_blank" rel="budget-fy2020 noopener noreferrer">TransUnion</a>
                          </p>
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        According to
                        {' '}
                        <a className={ classes.anchorStyle } href="https://inkwiry.com/pf101/level6/credit-score-101" target="_blank" rel="budget-fy2020 noopener noreferrer">Credit Karma</a>
                        , a good credit score is 700 and higher. The three major credit reporting agencies use the FICO formula in calculating their own proprietary credit scores. The exact details of how a FICO score is calculated is kept secret, but the general formula is made available to the public. Review the graph below to see the factors that go into calculating your credit score.
                      </p>
                    </div>
                    <div className={ classes.pfImage }>
                      <img className={ classes.pfImageBorder } src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/breakdown_pie.png" alt="template" />
                    </div>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        <p>
                          <b>Payment History:</b>
                          {' '}
                          the most important factor in your credit score at 35%, payment history includes which of your debts were paid on time, how much you owed, and if you missed any payments. It&apos;s essential to pay your debts and bills on time to maintain good credit.
                        </p>
                        <p>
                          {' '}
                          <b>Utilization Rate:</b>
                          {' '}
                          you never want your utilization rate to be higher than 30% on any account. For example, if you have a credit card with a $5,000 limit, you never want to carry a balance on that credit card greater than $1,500. The formula for utilization rate is below:
                        </p>
                        <p>
                          {' '}
                          <center>Utilization Rate = Sum of all Credit Balances / Sum of all Credit Limits</center>
                        </p>
                        <p>
                          {' '}
                          For example, if you have two credit cards that both have $5,000 credit limits and you have a $2,000 balance on one credit card and $0 on the other, your utilization rate would be:
                        </p>
                        <p>
                          {' '}
                          <center>Utilization Rate = $2,000 / $10,000 = 20%</center>
                        </p>
                        <p>
                          {' '}
                          <b>Length of Credit History:</b>
                          {' '}
                          the length of time each debt account has been open and how long it has been since you last used that account. A longer credit history is positive for your credit score. A great way to get your credit history started is to apply for a credit card, make a few small purchases a month, and repay that card in full each month.
                        </p>
                        <p>
                          {' '}
                          <b>Credit Mix:</b>
                          {' '}
                          the different kinds of accounts you have such as credit cards, student loans, car loans and mortgages.
                        </p>
                        <p>
                          {' '}
                          <b>New Credit:</b>
                          {' '}
                          this includes new accounts and recent accounts you have repaid. Opening multiple new accounts at the same time such as applying for three credit cards will have a negative impact on your score.
                        </p>
                        <p>
                          {' '}
                          Now that you know the formula, you know the secrets to maintaining and improving your credit score!
                        </p>
                      </div>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level6/credit-score-101/exercise" startTime={ startTime } level={ 6 } exercise={ 1 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CreditScore101.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( CreditScore101 );
