import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';
import CashFlowSheetPopup from './cash-flow-sheet-popup';
import * as modulesServices from '../../../calculations/modules-services';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


const CashFlowStatement = (props) => {
  const {
    classes, dispalyObj, rangeList,
  } = props;

  const [openCashFlowSheetPopup, setOpenCashFlowSheetPopup] = useState(false);
  const [cashFlowSheetDetails, setCashFlowSheetDetails] = useState({});
  const [currentColumn, setCurrentColumn] = useState('');

  const displayTitle = (type, name) => {
    let returnText = '';
    switch (name) {
      case 'Spouses Income':
        returnText = "Spouse's Income";
        break;
      case 'Expected Income':
        returnText = 'Career Path';
        break;
      case 'Excepted Income - Secound':
        returnText = 'Career Advancement';
        break;
      case 'Excepted Income - Third':
        returnText = 'Additional Career Advancement';
        break;
      case 'Excepted Income - Fourth':
        returnText = 'Further Career Advancement';
        break;
      default: {
        const data = name.split('-');
        if (data[data.length - 1].trim() === 'Base Income' || data[data.length - 1].trim() === 'Income' || data[data.length - 1].trim() === 'Second'
          || data[data.length - 1].trim() === 'Third' || data[data.length - 1].trim() === 'Fourth') {
          data.pop();
          if (data.length === 1) {
            returnText = data[0].trim();
          } else {
            if (data[data.length - 1].trim() === 'Income' || data[data.length - 1].trim() === 'Second' || data[data.length - 1].trim() === 'Third' || data[data.length - 1].trim() === 'Fourth') {
              data.pop();
            }
            const arr = [];
            data.forEach((value) => {
              arr.push(value);
            });
            returnText = arr.join('-');
          }
        } else {
          returnText = data[0].trim();
        }
        break;
      }
    }
    return returnText;
  };

  const showDetailInfo = (selectedYear, selectedSection, selectedColumn) => {
    if (typeof selectedYear !== 'undefined' && selectedYear !== '' && selectedYear > 0 &&
      typeof selectedSection !== 'undefined' && selectedSection !== '' &&
      typeof selectedColumn !== 'undefined' && selectedColumn !== '') {
      let detailedInfo = {};      

      detailedInfo.Year = selectedYear;
      setCurrentColumn(selectedColumn);

      switch (selectedSection) {
        case 'Cash from Operating Activities':

          switch (selectedColumn) {
            case 'Net Income':
              let incomeStatementCompleteData = modulesServices.incomeStatementCompleteData();

              if (typeof incomeStatementCompleteData !== 'undefined' && incomeStatementCompleteData !== '' && typeof incomeStatementCompleteData[selectedYear]['Income After Taxes'] !== 'undefined') {
                detailedInfo.incomeAfterTaxes = incomeStatementCompleteData[selectedYear]['Income After Taxes'];
              } else {
                detailedInfo.disposableIncome = 0;
              }

              if (typeof incomeStatementCompleteData !== 'undefined' && incomeStatementCompleteData !== '' && typeof incomeStatementCompleteData[selectedYear]['Total Expenses'] !== 'undefined') {
                detailedInfo.totalExpenses = incomeStatementCompleteData[selectedYear]['Total Expenses'];
              } else {
                detailedInfo.totalExpenses = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Cash from Investing Activities':
          switch (selectedColumn) {
            case 'Retirement Contributions':
              detailedInfo.databaseData = modulesServices.databaseCompleteData();
              let investmentsData = modulesServices.investmentsCompleteData();
              let databaseData = modulesServices.databaseCompleteData();
              let cashFlowCompleteData = modulesServices.cashFlowCompleteData();
              let personalContribution401k1 = 0;
              let personalContributionSephIra1 = 0;
              let personalContributionPension1 = 0;
              let personalContributionRothIra1 = 0;

              //For 401k Base Income
              if (typeof investmentsData != 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts 401k'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear]['Base Income'] !== 'undefined') {
                detailedInfo.baseIncome401k1 = investmentsData['Retirement Accounts 401k'][selectedYear]['Base Income'];
              } else {
                detailedInfo.baseIncome401k1 = 0;
              }
              //base Income Percentage
              if (typeof modulesServices.module4Data.Contribute401k !== 'undefined' && modulesServices.module4Data.Contribute401k === 'Yes'
                && typeof modulesServices.module4Data.startYear != 'undefined' && selectedYear >= modulesServices.module4Data.startYear
                && typeof modulesServices.module4Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module4Data.incomeEndYear
                && typeof modulesServices.module4Data.baseIncome !== 'undefined' && modulesServices.module4Data.baseIncome !== '') {
                personalContribution401k1 = modulesServices.module4Data.baseIncome;
              } else {
                if (typeof modulesServices.module5Data.Contribute401k !== 'undefined' && modulesServices.module5Data.Contribute401k === 'Yes'
                  && typeof modulesServices.module5Data.startYear != 'undefined' && selectedYear >= modulesServices.module5Data.startYear
                  && typeof modulesServices.module5Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module5Data.incomeEndYear
                  && typeof modulesServices.module5Data.baseIncome !== 'undefined' && modulesServices.module5Data.baseIncome !== '') {
                  personalContribution401k1 = modulesServices.module5Data.baseIncome;
                } else {
                  if (typeof modulesServices.module8Data.Contribute401k !== 'undefined' && modulesServices.module8Data.Contribute401k === 'Yes'
                    && typeof modulesServices.module8Data.startYear != 'undefined' && selectedYear >= modulesServices.module8Data.startYear
                    && typeof modulesServices.module8Data.endYear != 'undefined' && selectedYear <= modulesServices.module8Data.endYear
                    && typeof modulesServices.module8Data.baseIncome !== 'undefined' && modulesServices.module8Data.baseIncome !== '') {
                    personalContribution401k1 = modulesServices.module8Data.baseIncome;
                  } else {
                    if (typeof modulesServices.module17Data.Contribute401k !== 'undefined' && modulesServices.module17Data.Contribute401k === 'Yes'
                      && typeof modulesServices.module17Data.startYear != 'undefined' && selectedYear >= modulesServices.module17Data.startYear
                      && typeof modulesServices.module17Data.endYear != 'undefined' && selectedYear <= modulesServices.module17Data.endYear
                      && typeof modulesServices.module17Data.baseIncome !== 'undefined' && modulesServices.module17Data.baseIncome !== '') {
                      personalContribution401k1 = modulesServices.module17Data.baseIncome;
                    } else {
                      personalContribution401k1 = 0;
                    }
                  }
                }
              }
              detailedInfo.personalContribution401k1 = personalContribution401k1;
              //Personal Contribution value
              detailedInfo.personalContributionValue401k1 = (detailedInfo.baseIncome401k1 / 100) * personalContribution401k1;
              //Database Value
              if (typeof databaseData != 'undefined' && databaseData !== '' && typeof databaseData['Data']['401k Limit'] !== 'undefined') {
                detailedInfo.database401k1 = databaseData['Data']['401k Limit'];
              } else {
                detailedInfo.database401k1 = 0;
              }
              //Final 401k Value
              detailedInfo.final401kValueCondition1 = 0;
              if (typeof investmentsData != 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts 401k'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts 401k'][selectedYear]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts 401k'][selectedYear]['Personal Contribution'] !== 0) {
                detailedInfo.final401kValue1 = -investmentsData['Retirement Accounts 401k'][selectedYear]['Personal Contribution'];
                detailedInfo.final401kValueCondition1 = 1;
              } else {
                detailedInfo.final401kValue1 = 0;
              }

              //Seph IRA values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Base Income'] !== 'undefined') {
                detailedInfo.baseIncomeSephIra1 = investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Base Income'];
              } else {
                detailedInfo.baseIncomeSephIra1 = 0;
              }
              //base Income Percentage
              if (typeof modulesServices.module4Data.iraContribute !== 'undefined' && modulesServices.module4Data.iraContribute === 'Yes'
                && typeof modulesServices.module4Data.startYear != 'undefined' && selectedYear >= modulesServices.module4Data.startYear
                && typeof modulesServices.module4Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module4Data.incomeEndYear
                && typeof modulesServices.module4Data.iraPersonalContribution !== 'undefined' && modulesServices.module4Data.iraPersonalContribution !== '') {
                personalContributionSephIra1 = modulesServices.module4Data.iraPersonalContribution;
              } else {
                if (typeof modulesServices.module5Data.iraContribute !== 'undefined' && modulesServices.module5Data.iraContribute === 'Yes'
                  && typeof modulesServices.module5Data.startYear != 'undefined' && selectedYear >= modulesServices.module5Data.startYear
                  && typeof modulesServices.module5Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module5Data.incomeEndYear
                  && typeof modulesServices.module5Data.iraPersonalContribution !== 'undefined' && modulesServices.module5Data.iraPersonalContribution !== '') {
                  personalContributionSephIra1 = modulesServices.module5Data.iraPersonalContribution;
                } else {
                  if (typeof modulesServices.module8Data.iraContribute !== 'undefined' && modulesServices.module8Data.iraContribute === 'Yes'
                    && typeof modulesServices.module8Data.startYear != 'undefined' && selectedYear >= modulesServices.module8Data.startYear
                    && typeof modulesServices.module8Data.endYear != 'undefined' && selectedYear <= modulesServices.module8Data.endYear
                    && typeof modulesServices.module8Data.iraPersonalContribution !== 'undefined' && modulesServices.module8Data.iraPersonalContribution !== '') {
                    personalContributionSephIra1 = modulesServices.module8Data.iraPersonalContribution;
                  } else {
                    if (typeof modulesServices.module17Data.iraContribute !== 'undefined' && modulesServices.module17Data.iraContribute === 'Yes'
                      && typeof modulesServices.module17Data.startYear != 'undefined' && selectedYear >= modulesServices.module17Data.startYear
                      && typeof modulesServices.module17Data.endYear != 'undefined' && selectedYear <= modulesServices.module17Data.endYear
                      && typeof modulesServices.module17Data.iraPersonalContribution !== 'undefined' && modulesServices.module17Data.iraPersonalContribution !== '') {
                      personalContributionSephIra1 = modulesServices.module17Data.iraPersonalContribution;
                    } else {
                      personalContributionSephIra1 = 0;
                    }
                  }
                }
              }
              detailedInfo.personalContributionSephIra1 = personalContributionSephIra1;
              //Personal Contribution value
              detailedInfo.personalContributionValueSephIra1 = (detailedInfo.baseIncomeSephIra1 / 100) * personalContributionSephIra1;
              //Seph IRA Compared Values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Income Based Limit'] !== 'undefined') {
                detailedInfo.firstSephIraValue1 = investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Income Based Limit'];
              } else {
                detailedInfo.firstSephIraValue1 = 0;
              }
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Dollar Based Limit'] !== 'undefined') {
                detailedInfo.secondSephIraValue1 = investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Max Dollar Based Limit'];
              } else {
                detailedInfo.secondSephIraValue1 = 0;
              }
              //Max Contribution by Law
              if (detailedInfo.firstSephIraValue1 < detailedInfo.secondSephIraValue1) {
                detailedInfo.maxContributionByLawSephIra1 = detailedInfo.firstSephIraValue1;
              } else {
                detailedInfo.maxContributionByLawSephIra1 = detailedInfo.secondSephIraValue1;
              }
              //Final Seph IRA Value
              detailedInfo.finalSephIraValueCondition1 = 0;
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Personal Contribution Final'] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Personal Contribution Final'] !== 0) {
                detailedInfo.finalSephIraValue1 = -investmentsData['Retirement Accounts Seph IRA'][selectedYear]['Personal Contribution Final'];
                detailedInfo.finalSephIraValueCondition1 = 1;
              } else {
                detailedInfo.finalSephIraValue1 = 0;
              }

              //Roth IRA Values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Roth IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Roth IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Roth IRA'][selectedYear]['Base Income'] !== 'undefined') {
                detailedInfo.baseIncomeRothIra1 = investmentsData['Retirement Accounts Roth IRA'][selectedYear]['Base Income'];
              } else {
                detailedInfo.baseIncomeRothIra1 = 0;
              }
              //base Income Percentage
              if (typeof modulesServices.module4Data.rothContribute !== 'undefined' && modulesServices.module4Data.rothContribute === 'Yes'
                && typeof modulesServices.module4Data.startYear != 'undefined' && selectedYear >= modulesServices.module4Data.startYear
                && typeof modulesServices.module4Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module4Data.incomeEndYear
                && typeof modulesServices.module4Data.rothPersonalContribution !== 'undefined' && modulesServices.module4Data.rothPersonalContribution !== '') {
                personalContributionRothIra1 = modulesServices.module4Data.rothPersonalContribution;
              } else {
                if (typeof modulesServices.module5Data.rothContribute !== 'undefined' && modulesServices.module5Data.rothContribute === 'Yes'
                  && typeof modulesServices.module5Data.startYear != 'undefined' && selectedYear >= modulesServices.module5Data.startYear
                  && typeof modulesServices.module5Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module5Data.incomeEndYear
                  && typeof modulesServices.module5Data.rothPersonalContribution !== 'undefined' && modulesServices.module5Data.rothPersonalContribution !== '') {
                  personalContributionRothIra1 = modulesServices.module5Data.rothPersonalContribution;
                } else {
                  if (typeof modulesServices.module8Data.rothContribute !== 'undefined' && modulesServices.module8Data.rothContribute === 'Yes'
                    && typeof modulesServices.module8Data.startYear != 'undefined' && selectedYear >= modulesServices.module8Data.startYear
                    && typeof modulesServices.module8Data.endYear != 'undefined' && selectedYear <= modulesServices.module8Data.endYear
                    && typeof modulesServices.module8Data.rothPersonalContribution !== 'undefined' && modulesServices.module8Data.rothPersonalContribution !== '') {
                    personalContributionRothIra1 = modulesServices.module8Data.rothPersonalContribution;
                  } else {
                    if (typeof modulesServices.module17Data.rothContribute !== 'undefined' && modulesServices.module17Data.rothContribute === 'Yes'
                      && typeof modulesServices.module17Data.startYear != 'undefined' && selectedYear >= modulesServices.module17Data.startYear
                      && typeof modulesServices.module17Data.endYear != 'undefined' && selectedYear <= modulesServices.module17Data.endYear
                      && typeof modulesServices.module17Data.rothPersonalContribution !== 'undefined' && modulesServices.module17Data.rothPersonalContribution !== '') {
                      personalContributionRothIra1 = modulesServices.module17Data.rothPersonalContribution;
                    } else {
                      personalContributionRothIra1 = 0;
                    }
                  }
                }
              }
              detailedInfo.personalContributionRothIra1 = personalContributionRothIra1;
              //Personal Contribution value
              detailedInfo.personalContributionValueRothIra1 = (detailedInfo.baseIncomeRothIra1 / 100) * personalContributionRothIra1;
              //Database Value
              if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Data']['Roth IRA Limit'] !== 'undefined') {
                detailedInfo.databaseRothIRA1 = databaseData['Data']['Roth IRA Limit'];
              } else {
                detailedInfo.databaseRothIRA1 = 0;
              }

              //Max Contribution by Law
              if (detailedInfo.personalContributionValueRothIra1 > detailedInfo.databaseRothIRA1) {
                detailedInfo.maxContributionByLawRothIra1 = detailedInfo.databaseRothIRA1;
              } else {
                detailedInfo.maxContributionByLawRothIra1 = detailedInfo.personalContributionValueRothIra1;
              }
              //Exclude Net rental Income
              if (typeof investmentsData != 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Roth IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Roth IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Roth IRA'][selectedYear]['Total Income for Phaseout'] !== 'undefined') {
                detailedInfo.excludingNetRentalIncome1 = investmentsData['Retirement Accounts Roth IRA'][selectedYear]['Total Income for Phaseout'];
              } else {
                detailedInfo.excludingNetRentalIncome1 = 0;
              }

              //Phase Out reduction Due value
              // showDetailInfoMini(selectedYear, 'Retirement Contributions Mini', 'retirement-contribution-mini', detailedInfo.maxContributionByLawRothIra1, 'include');
              // detailedInfo.phaseOutReductionDueValue =
              //   detailedMiniInfo.show_reduction1 +
              //   detailedMiniInfo.show_reduction2 +
              //   detailedMiniInfo.show_reduction3;
              // $('#DetailPopupRetirementMini').modal('hide');

              //Final Roth IRA Value
              detailedInfo.finalRothIraValueCondition1 = 0;
              if (typeof investmentsData != 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Roth IRA'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Roth IRA'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Roth IRA'][selectedYear]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts Roth IRA'][selectedYear]['Personal Contribution'] !== 0) {
                detailedInfo.finalRothIraValue1 = -investmentsData['Retirement Accounts Roth IRA'][selectedYear]['Personal Contribution'];
                detailedInfo.finalRothIraValueCondition1 = 1;
              } else {
                detailedInfo.finalRothIraValue1 = 0;
              }
              //Completed Roth IRA
              //Pension Values
              if (typeof investmentsData != 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear]['Base Income'] !== 'undefined') {
                detailedInfo.baseIncomePension1 = investmentsData['Retirement Accounts 401k'][selectedYear]['Base Income'];
              } else {
                detailedInfo.baseIncomePension1 = 0;
              }
              //base Income Percentage
              if (typeof modulesServices.module4Data.pensionContribute !== 'undefined' && modulesServices.module4Data.pensionContribute === 'Yes'
                && typeof modulesServices.module4Data.startYear != 'undefined' && selectedYear >= modulesServices.module4Data.startYear
                && typeof modulesServices.module4Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module4Data.incomeEndYear
                && typeof modulesServices.module4Data.pensionPersonalContribution !== 'undefined' && modulesServices.module4Data.pensionPersonalContribution !== '') {
                personalContributionPension1 = modulesServices.module4Data.pensionPersonalContribution;
              } else {
                if (typeof modulesServices.module5Data.pensionContribute !== 'undefined' && modulesServices.module5Data.pensionContribute === 'Yes'
                  && typeof modulesServices.module5Data.startYear != 'undefined' && selectedYear >= modulesServices.module5Data.startYear
                  && typeof modulesServices.module5Data.incomeEndYear != 'undefined' && selectedYear <= modulesServices.module5Data.incomeEndYear
                  && typeof modulesServices.module5Data.pensionPersonalContribution !== 'undefined' && modulesServices.module5Data.pensionPersonalContribution !== '') {
                  personalContributionPension1 = modulesServices.module5Data.pensionPersonalContribution;
                } else {
                  if (typeof modulesServices.module8Data.pensionContribute !== 'undefined' && modulesServices.module8Data.pensionContribute === 'Yes'
                    && typeof modulesServices.module8Data.startYear != 'undefined' && selectedYear >= modulesServices.module8Data.startYear
                    && typeof modulesServices.module8Data.endYear != 'undefined' && selectedYear <= modulesServices.module8Data.endYear
                    && typeof modulesServices.module8Data.pensionPersonalContribution !== 'undefined' && modulesServices.module8Data.pensionPersonalContribution !== '') {
                    personalContributionPension1 = modulesServices.module8Data.pensionPersonalContribution;
                  } else {
                    if (typeof modulesServices.module17Data.pensionContribute !== 'undefined' && modulesServices.module17Data.pensionContribute === 'Yes'
                      && typeof modulesServices.module17Data.startYear != 'undefined' && selectedYear >= modulesServices.module17Data.startYear
                      && typeof modulesServices.module17Data.endYear != 'undefined' && selectedYear <= modulesServices.module17Data.endYear
                      && typeof modulesServices.module17Data.pensionPersonalContribution !== 'undefined' && modulesServices.module17Data.pensionPersonalContribution !== '') {
                      personalContributionPension1 = modulesServices.module17Data.pensionPersonalContribution;
                    } else {
                      personalContributionPension1 = 0;
                    }
                  }
                }
              }
              detailedInfo.personalContributionPension1 = personalContributionPension1;
              //Personal Contribution value
              detailedInfo.personalContributionValuePension1 = (detailedInfo.baseIncomePension1 / 100) * personalContributionPension1;
              //Database Value
              if (typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Data']['Pension 2018 Limits'] !== 'undefined') {
                detailedInfo.databasePension1 = databaseData['Data']['Pension 2018 Limits'];
              } else {
                detailedInfo.databasePension1 = 0;
              }
              //Employer Contribution both values
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear]['Employer Contribution'] !== 'undefined') {
                detailedInfo.employerContributionPension1 = investmentsData['Retirement Accounts Pension'][selectedYear]['Employer Contribution'];
              } else {
                detailedInfo.employerContributionPension1 = 0;
              }
              //Final Pension Value
              detailedInfo.finalPensionValueCondition1 = 0;
              if (typeof investmentsData !== 'undefined' && investmentsData !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear] !== '' && typeof investmentsData['Retirement Accounts Pension'][selectedYear]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts Pension'][selectedYear]['Personal Contribution'] !== 0) {
                detailedInfo.finalPensionValue1 = -investmentsData['Retirement Accounts Pension'][selectedYear]['Personal Contribution'];
                detailedInfo.finalPensionValueCondition1 = 1;
              } else {
                detailedInfo.finalPensionValue1 = 0;
              }

              //Total 401k Adjustments value
              if (typeof cashFlowCompleteData !== 'undefined' && cashFlowCompleteData !== '' && typeof cashFlowCompleteData[selectedYear] !== 'undefined' && cashFlowCompleteData[selectedYear]['Retirement Contributions'] !== '') {
                detailedInfo.totalRetirementContriutionsValue = cashFlowCompleteData[selectedYear]['Cash from Investing Activities']['Retirement Contributions'];
              } else {
                detailedInfo.totalRetirementContriutionsValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            // Contribution to Investment Accounts
            case 'Contribution to Investment Accounts':
              detailedInfo.shorttermCashContributionPreviousValueCondition = 0;
              detailedInfo.shorttermCashContributionValueCondition = 0;
              detailedInfo.longtermCashContributionValueCondition = 0;

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.startYear != 'undefined') {
                detailedInfo.shortTermInvestmentsStartYear = modulesServices.module9Data.startYear;
              }
              else {
                detailedInfo.shortTermInvestmentsStartYear = 0;
              }

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.shortTermAnnualContribution != 'undefined') {
                detailedInfo.shortTermAnnualContribution = modulesServices.module9Data.shortTermAnnualContribution;
              }
              else {
                detailedInfo.shortTermAnnualContribution = 0;
              }

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.shortTermAnnualContributionPercentage != 'undefined') {
                detailedInfo.shortTermAnnualContributionGrowthOPercentage = modulesServices.module9Data.shortTermAnnualContributionPercentage;
              }
              else {
                detailedInfo.shortTermAnnualContributionGrowthOPercentage = 0;
              }

              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Cash Contibution'] != 'undefined' &&
                investmentsData['Short-Term Investments'][selectedYear]['Cash Contibution'] !== 0) {
                detailedInfo.shorttermCashContributionValue = -investmentsData['Short-Term Investments'][selectedYear]['Cash Contibution'];
                detailedInfo.shorttermCashContributionValueCondition = 1;
              }
              else {
                detailedInfo.shorttermCashContributionValue = 0;
              }

              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear - 1] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear - 1]['Cash Contibution'] != 'undefined' &&
                investmentsData['Short-Term Investments'][selectedYear - 1]['Cash Contibution'] !== 0) {
                detailedInfo.shorttermCashContributionPreviousValue = -investmentsData['Short-Term Investments'][selectedYear - 1]['Cash Contibution'];
                detailedInfo.shorttermCashContributionPreviousValueCondition = 1;
              }
              else {
                detailedInfo.shorttermCashContributionPreviousValue = 0;
              }

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.long_term_start_year != 'undefined') {
                detailedInfo.longTermInvestmentsStartYear = modulesServices.module9Data.long_term_start_year;
              }
              else {
                detailedInfo.longTermInvestmentsStartYear = 0;
              }

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.longTermAnnualContribution != 'undefined') {
                detailedInfo.longTermAnnualContribution = modulesServices.module9Data.longTermAnnualContribution;
              }
              else {
                detailedInfo.longTermAnnualContribution = 0;
              }

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.shortTermAnnualContributionPercentage != 'undefined') {
                detailedInfo.longTermAnnualContributionGrowthOPercentage = modulesServices.module9Data.longtermAnnualContributionPercentage;
              }
              else {
                detailedInfo.longTermAnnualContributionGrowthOPercentage = 0;
              }

              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Cash Contibution'] != 'undefined' &&
                investmentsData['Long-Term Investments'][selectedYear]['Cash Contibution'] !== 0) {
                detailedInfo.longtermCashContributionValue = -investmentsData['Long-Term Investments'][selectedYear]['Cash Contibution'];
                detailedInfo.longtermCashContributionValueCondition = 1;
              }
              else {
                detailedInfo.longtermCashContributionValue = 0;
              }
              detailedInfo.longtermCashContributionPreviousValueCondition = 0;
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear - 1] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear - 1]['Cash Contibution'] != 'undefined' &&
                investmentsData['Long-Term Investments'][selectedYear - 1]['Cash Contibution'] !== 0) {
                detailedInfo.longtermCashContributionPreviousValue = -investmentsData['Long-Term Investments'][selectedYear - 1]['Cash Contibution'];
                detailedInfo.longtermCashContributionPreviousValueCondition = 1;
              }
              else {
                detailedInfo.longtermCashContributionPreviousValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            // Charitable Contributions
            case 'Charitable Contributions':
              let incomeStatementCompleteData = modulesServices.incomeStatementCompleteData();
              let cashFlowData = modulesServices.cashFlowCompleteData();
              detailedInfo.charitableContributionCondition = 0;
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.charitableStartYear != 'undefined') {
                detailedInfo.charitableStartYear = modulesServices.module9Data.charitableStartYear;
              }
              else {
                detailedInfo.charitableStartYear = 0;
              }
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.charitableEndYear != 'undefined') {
                detailedInfo.charitableEndYear = modulesServices.module9Data.charitableEndYear;
              }
              else {
                detailedInfo.charitableEndYear = 0;
              }
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.charitableYearlyContribution != 'undefined') {
                detailedInfo.grossIncomePercentageValue = modulesServices.module9Data.charitableYearlyContribution;
              }
              else {
                detailedInfo.grossIncomePercentageValue = 0;
              }
              if (typeof incomeStatementCompleteData != 'undefined' &&
                incomeStatementCompleteData !== '' &&
                typeof incomeStatementCompleteData[selectedYear]['Total Income'] != 'undefined') {
                detailedInfo.grossIncomeValue = incomeStatementCompleteData[selectedYear]['Total Income'];
              }
              else {
                detailedInfo.grossIncomeValue = 0;
              }

              if (typeof cashFlowData != 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear]['Cash from Investing Activities'] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Cash from Investing Activities']['Charitable Contributions'] !== 'undefined' && cashFlowData[selectedYear]['Cash from Investing Activities']['Charitable Contributions'] !== 0) {
                detailedInfo.charitableContributionValue = cashFlowData[selectedYear]['Cash from Investing Activities']['Charitable Contributions'];
                detailedInfo.charitableContributionCondition = 1;
              }
              else {
                detailedInfo.charitableContributionValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            // Vacation
            case 'Vacation':
              let CaData = modulesServices.cashFlowCompleteData();
              
              detailedInfo = CaData[selectedYear];
              detailedInfo.vacationLabel = 'Vacation';

              detailedInfo.finalValueCondition = 0;
              detailedInfo.previousYearValueCondition = 0;

              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.vacationStartYear != 'undefined') {
                detailedInfo.vacationStartYear = modulesServices.module11Data.vacationStartYear;
              }
              else {
                detailedInfo.vacationStartYear = 0;
              }
              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.vacationEndYear != 'undefined') {
                detailedInfo.vacationEndYear = modulesServices.module11Data.vacationEndYear;
              }
              else {
                detailedInfo.vacationEndYear = 0;
              }
              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.vacationCost != 'undefined') {
                detailedInfo.vacationCost = modulesServices.module11Data.vacationCost;
              }
              else {
                detailedInfo.vacationCost = 0;
              }
              if (typeof CaData != 'undefined' &&
              CaData !== '' &&
                typeof CaData[selectedYear - 1] != 'undefined' &&
                typeof CaData[selectedYear - 1]['Cash from Investing Activities'] != 'undefined' &&
                CaData[selectedYear - 1]['Cash from Investing Activities']['Vacation'] !== '') {
                detailedInfo.previousYearVacationValue = CaData[selectedYear - 1]['Cash from Investing Activities']['Vacation'];
                detailedInfo.previousYearValueCondition = 1;
              }
              else {
                detailedInfo.previousYearVacationValue = 0;
              }
              if (typeof modulesServices.module1Data != 'undefined' &&
                typeof modulesServices.module1Data.inflation != 'undefined') {
                detailedInfo.annualInflation = modulesServices.module1Data.inflation;
              }
              else {
                detailedInfo.annualInflation = 0;
              }
              if (typeof cashFCaDatalowData != 'undefined' &&
              CaData !== '' &&
                typeof CaData[selectedYear] != 'undefined' &&
                typeof CaData[selectedYear]['Cash from Investing Activities'] != 'undefined' &&
                typeof CaData[selectedYear]['Cash from Investing Activities']['Car and Lease Down Payment & Fees'] != 'undefined' &&
                CaData[selectedYear]['Cash from Investing Activities']['Vacation'] !== '' && CaData[selectedYear]['Cash from Investing Activities']['Vacation'] !== 0) {
                detailedInfo.finalValue = CaData[selectedYear]['Cash from Investing Activities']['Vacation'];
                detailedInfo.finalValueCondition = 1;
              }
              else {
                detailedInfo.finalValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

              
            case modulesServices.module11Data.other_acquisition2+' - Second':
              detailedInfo.vacationLabel = modulesServices.module11Data.other_acquisition2;
              detailedInfo.finalValueCondition = 0;
              detailedInfo.previousYearValueCondition = 0;

              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.customSuitStartYear != 'undefined') {
                detailedInfo.vacationStartYear = modulesServices.module11Data.customSuitStartYear;
              }
              else {
                detailedInfo.vacationStartYear = 0;
              }
              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.customSuitEndYear != 'undefined') {
                detailedInfo.vacationEndYear = modulesServices.module11Data.customSuitEndYear;
              }
              else {
                detailedInfo.vacationEndYear = 0;
              }
              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.swimsuitCost != 'undefined') {
                detailedInfo.vacationCost = modulesServices.module11Data.swimsuitCost;
              }
              else {
                detailedInfo.vacationCost = 0;
              }
              if (typeof cashFlowData != 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear - 1] != 'undefined' &&
                typeof cashFlowData[selectedYear - 1]['Cash from Investing Activities'] != 'undefined' &&
                cashFlowData[selectedYear - 1]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition2 + '- Second'] !== '') {
                detailedInfo.previousYearVacationValue = cashFlowData[selectedYear - 1]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition2 + '- Second'];
                detailedInfo.previousYearValueCondition = 1;
              }
              else {
                detailedInfo.previousYearVacationValue = 0;
              }
              if (typeof modulesServices.module1Data != 'undefined' &&
                typeof modulesServices.module1Data.inflation != 'undefined') {
                detailedInfo.annualInflation = modulesServices.module1Data.inflation;
              }
              else {
                detailedInfo.annualInflation = 0;
              }
              if (typeof cashFlowData != 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Cash from Investing Activities'] != 'undefined' &&
                cashFlowData[selectedYear]['Cash from Investing Activities'] !== '' && cashFlowData[selectedYear]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition2 + '- Second'] !== 0) {
                detailedInfo.finalValue = cashFlowData[selectedYear]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition2 + '- Second'];
                detailedInfo.finalValueCondition = 1;
              }
              else {
                detailedInfo.finalValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            case modulesServices.module11Data.other_acquisition1:
              detailedInfo.vacationLabel = modulesServices.module11Data.other_acquisition1;
              detailedInfo.finalValueCondition = 0;
              detailedInfo.previousYearValueCondition = 0;

              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.watchStartYear != 'undefined') {
                detailedInfo.vacationStartYear = modulesServices.module11Data.watchStartYear;
              }
              else {
                detailedInfo.vacationStartYear = 0;
              }
              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.watchEndYear != 'undefined') {
                detailedInfo.vacationEndYear = modulesServices.module11Data.watchEndYear;
              }
              else {
                detailedInfo.vacationEndYear = 0;
              }
              if (typeof modulesServices.module11Data != 'undefined' &&
                typeof modulesServices.module11Data.watchCost != 'undefined') {
                detailedInfo.vacationCost = modulesServices.module11Data.watchCost;
              }
              else {
                detailedInfo.vacationCost = 0;
              }
              if (typeof cashFlowData != 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear - 1] != 'undefined' &&
                typeof cashFlowData[selectedYear - 1]['Cash from Investing Activities'] != 'undefined' &&
                cashFlowData[selectedYear - 1]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition1] !== '') {
                detailedInfo.previousYearVacationValue = cashFlowData[selectedYear - 1]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition1];
                detailedInfo.previousYearValueCondition = 1;
              }
              else {
                detailedInfo.previousYearVacationValue = 0;
              }
              if (typeof modulesServices.module1Data != 'undefined' &&
                typeof modulesServices.module1Data.inflation != 'undefined') {
                detailedInfo.annualInflation = modulesServices.module1Data.inflation;
              }
              else {
                detailedInfo.annualInflation = 0;
              }
              if (typeof cashFlowData != 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Cash from Investing Activities'] != 'undefined' &&
                cashFlowData[selectedYear]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition1] !== '' && cashFlowData[selectedYear]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition1] !== 0) {
                detailedInfo.finalValue = cashFlowData[selectedYear]['Cash from Investing Activities'][modulesServices.module11Data.other_acquisition1];
                detailedInfo.finalValueCondition = 1;
              }
              else {
                detailedInfo.finalValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            // Pet Buying Costs
            case 'Pet Buying Costs':
              let expenseCalculationsCompleteData = modulesServices.expenseCalculationsCompleteData();
              let cashflowData = modulesServices.cashFlowCompleteData();
              detailedInfo.petBuyingCostsCondition = 0;

              if (typeof cashflowData != 'undefined' &&
              cashflowData !== '' &&
                typeof cashflowData[selectedYear] != 'undefined' &&
                typeof cashflowData[selectedYear]['Cash from Investing Activities']['Pet Buying Costs'] != 'undefined' &&
                cashflowData[selectedYear]['Cash from Investing Activities']['Pet Buying Costs'] !== 0) {
                detailedInfo.petBuyingCostValue = cashflowData[selectedYear]['Cash from Investing Activities']['Pet Buying Costs'];
                detailedInfo.petBuyingCostsCondition = 1;
              }
              else {
                detailedInfo.petBuyingCostValue = 0;
              }

              if (typeof expenseCalculationsCompleteData != 'undefined' &&
                expenseCalculationsCompleteData !== '' &&
                typeof expenseCalculationsCompleteData[selectedYear] != 'undefined' &&
                typeof expenseCalculationsCompleteData[selectedYear]['Initial Costs']['Purchase Price'] != 'undefined') {
                detailedInfo.purchasePriceValue = -expenseCalculationsCompleteData[selectedYear]['Initial Costs']['Purchase Price'];
              }
              else {
                detailedInfo.purchasePriceValue = 0;
              }
              if (typeof expenseCalculationsCompleteData != 'undefined' &&
                expenseCalculationsCompleteData !== '' &&
                typeof expenseCalculationsCompleteData[selectedYear] != 'undefined' &&
                typeof expenseCalculationsCompleteData[selectedYear]['Initial Costs']['First Vet Visit'] != 'undefined') {
                detailedInfo.firstVetVisitValue = -expenseCalculationsCompleteData[selectedYear]['Initial Costs']['First Vet Visit'];
              }
              else {
                detailedInfo.firstVetVisitValue = 0;
              }
              if (typeof expenseCalculationsCompleteData != 'undefined' &&
                expenseCalculationsCompleteData !== '' &&
                typeof expenseCalculationsCompleteData[selectedYear] != 'undefined' &&
                typeof expenseCalculationsCompleteData[selectedYear]['Initial Costs']['New Supplies'] != 'undefined') {
                detailedInfo.newSuppliesValue = -expenseCalculationsCompleteData[selectedYear]['Initial Costs']['New Supplies'];
              }
              else {
                detailedInfo.newSuppliesValue = 0;
              }
              if (typeof expenseCalculationsCompleteData != 'undefined' &&
                expenseCalculationsCompleteData !== '' &&
                typeof expenseCalculationsCompleteData[selectedYear] != 'undefined' &&
                typeof expenseCalculationsCompleteData[selectedYear]['Initial Costs']['Miscellaneous'] != 'undefined') {
                detailedInfo.miscellaneousValue = -expenseCalculationsCompleteData[selectedYear]['Initial Costs']['Miscellaneous'];
              }
              else {
                detailedInfo.miscellaneousValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            // Home & REI Property Down Payments
            case 'Home & REI Property Down Payments':
              let chflowData = modulesServices.cashFlowCompleteData();
              detailedInfo.purchaseYearCondition = 0;
              if (typeof modulesServices.module13Data != 'undefined' &&
                typeof modulesServices.module13Data.yearOfPurchase != 'undefined' &&
                typeof modulesServices.module13Data.purchasePrice != 'undefined' &&
                selectedYear === modulesServices.module13Data.yearOfPurchase) {
                detailedInfo.purchaseYearCondition = 1;
                detailedInfo.purchasePrice = modulesServices.module13Data.purchasePrice;
              }
              else {
                detailedInfo.purchasePrice = 0;
              }

              detailedInfo.downPaymentCondition = 0;
              if (typeof modulesServices.module13Data != 'undefined' &&
                typeof modulesServices.module13Data.yearOfPurchase != 'undefined' &&
                typeof modulesServices.module13Data.downPayment != 'undefined' &&
                modulesServices.module13Data.downPayment > 0 &&
                selectedYear === modulesServices.module13Data.yearOfPurchase) {
                detailedInfo.downPaymentCondition = 1;
                detailedInfo.downPayment = modulesServices.module13Data.downPayment;
              }
              else {
                detailedInfo.downPayment = 0;
              }

              detailedInfo.purchaseYearCondition1 = 0;
              if (typeof modulesServices.module14Data != 'undefined' &&
                typeof modulesServices.module14Data.purchasePrice != 'undefined' &&
                typeof modulesServices.module14Data.purchasingYear != 'undefined' &&
                modulesServices.module14Data.purchasePrice > 0 &&
                selectedYear === modulesServices.module14Data.purchasingYear) {
                detailedInfo.purchaseYearCondition1 = 1;
                detailedInfo.purchasePrice1 = modulesServices.module14Data.purchasePrice;
              }
              else {
                detailedInfo.purchasePrice1 = 0;
              }

              detailedInfo.downPaymentCondition1 = 0;
              if (typeof modulesServices.module14Data != 'undefined' &&
                typeof modulesServices.module14Data.purchasingYear != 'undefined' &&
                typeof modulesServices.module14Data.moreDownPayment != 'undefined' &&
                selectedYear === modulesServices.module14Data.purchasingYear) {
                detailedInfo.downPaymentCondition1 = 1;
                detailedInfo.downPayment1 = modulesServices.module14Data.moreDownPayment;
              }
              else {
                detailedInfo.downPayment1 = 0;
              }
              //Home & REI Property Down Pyment final amount
              if (typeof chflowData !== 'undefined' && chflowData !== '' && typeof chflowData[selectedYear] !== 'undefined' && chflowData[selectedYear] !== '' && typeof chflowData[selectedYear]['Cash from Investing Activities']['Home & REI Property Down Payments'] !== 'undefined') {
                detailedInfo.finalDownPayment = chflowData[selectedYear]['Cash from Investing Activities']['Home & REI Property Down Payments'];
              } else {
                detailedInfo.finalDownPayment = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

              // Home & REI Property Closing Costs
            case 'Home & REI Property Closing Costs':
              let buyingAHomeCompleteData = modulesServices.buyingAHomeCompleteData();
              let reiCompleteData = modulesServices.invPropertyRoiCompleteData();
              detailedInfo.buyingAHomeClosingCostCondition = 0;
              detailedInfo.reiClosingCostCondition = 0;
              if (typeof buyingAHomeCompleteData != 'undefined' &&
                buyingAHomeCompleteData !== '' &&
                typeof buyingAHomeCompleteData['Data1'][selectedYear] != 'undefined' &&
                typeof buyingAHomeCompleteData['Data1'][selectedYear]['Closing Costs'] !== 'undefined' && buyingAHomeCompleteData['Data1'][selectedYear]['Closing Costs'] !== 0) {
                detailedInfo.buyingAHomeClosingCost = -buyingAHomeCompleteData['Data1'][selectedYear]['Closing Costs'];
                detailedInfo.buyingAHomeClosingCostCondition = 1;
              }
              else {
                detailedInfo.buyingAHomeClosingCost = 0;
              }
              if (typeof reiCompleteData != 'undefined' &&
                reiCompleteData !== '' && typeof reiCompleteData['Data1'] != 'undefined' && 
                typeof reiCompleteData['Data1'][selectedYear] != 'undefined' &&
                typeof reiCompleteData['Data1'][selectedYear]['Closing Costs'] !== 'undefined' && reiCompleteData['Data1'][selectedYear]['Closing Costs'] !== 0) {
                detailedInfo.reiClosingCost = -reiCompleteData['Data1'][selectedYear]['Closing Costs'];
                detailedInfo.reiClosingCostCondition = 1;
              }
              else {
                detailedInfo.reiClosingCost = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

              // Home & REI Property Fit Out Costs
            case 'Home & REI Property Fit Out Costs':
              let reicompleteData = modulesServices.invPropertyRoiCompleteData();
              detailedInfo.buyingAHomeFitOutCostCondition = 0;
              detailedInfo.reiFitOutCostCondition = 0;
              if (typeof buyingAHomeCompleteData != 'undefined' &&
                buyingAHomeCompleteData !== '' &&
                typeof buyingAHomeCompleteData['Data1'][selectedYear] != 'undefined' &&
                typeof buyingAHomeCompleteData['Data1'][selectedYear]['Fit Out Costs'] !== 'undefined' && buyingAHomeCompleteData['Data1'][selectedYear]['Fit Out Costs'] !== 0) {
                detailedInfo.buyingAHomeFitOutCost = -buyingAHomeCompleteData['Data1'][selectedYear]['Fit Out Costs'];
                detailedInfo.buyingAHomeFitOutCostCondition = 1;
              }
              else {
                detailedInfo.buyingAHomeFitOutCost = 0;
              }

              if (typeof reicompleteData != 'undefined' &&
              reicompleteData !== '' &&
                typeof reicompleteData['Data1'][selectedYear] != 'undefined' &&
                typeof reicompleteData['Data1'][selectedYear]['Fit Out Costs'] !== 'undefined' && reicompleteData['Data1'][selectedYear]['Fit Out Costs'] !== 0) {
                detailedInfo.reiFitOutCost = -reicompleteData['Data1'][selectedYear]['Fit Out Costs'];
                detailedInfo.reiFitOutCostCondition = 1;
              }
              else {
                detailedInfo.reiFitOutCost = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

              // Home & REI Property Net Cash Flow from Sale
            case 'Home & REI Property Net Cash Flow from Sale':
              let existingHomeData = modulesServices.existingHomeCompleteData();
              let REIData = modulesServices.invPropertyRoiCompleteData();
              let chFlowData = modulesServices.cashFlowCompleteData();

              //Existing home section
              if (typeof modulesServices.module18Data.existingHomeSaleYear !== 'undefined' && modulesServices.module18Data.existingHomeSaleYear !== '') {
                detailedInfo.existingHomeSaleYear = modulesServices.module18Data.existingHomeSaleYear;
              }
              if (typeof modulesServices.module18Data.existingHomeSaleYear !== 'undefined' && modulesServices.module18Data.existingHomeSaleYear !== '' && selectedYear === modulesServices.module18Data.existingHomeSaleYear) {
                if (typeof existingHomeData !== 'undefined' && existingHomeData !== '' && typeof existingHomeData['Buying Expenses'] !== 'undefined') {
                  detailedInfo.existingHomeSellingPrice = existingHomeData['Buying Expenses']['Selling Price'];
                  detailedInfo.existingHomeSellingCost = -existingHomeData['Buying Expenses']['Selling Costs'];
                  detailedInfo.existingHomeMortgageBalanceRemaining = -existingHomeData['Buying Expenses']['Mortgage Balance Remaining'];
                  detailedInfo.existingHomeNetCashFlowFromSale = existingHomeData['Buying Expenses']['Net Cash Flow from Sale'];
                } else {
                  detailedInfo.existingHomeSellingPrice = 0;
                  detailedInfo.existingHomeSellingCost = 0;
                  detailedInfo.existingHomeMortgageBalanceRemaining = 0;
                  detailedInfo.existingHomeNetCashFlowFromSale = 0;
                }
              } else {
                detailedInfo.existingHomeSellingPrice = 0;
                detailedInfo.existingHomeSellingCost = 0;
                detailedInfo.existingHomeMortgageBalanceRemaining = 0;
                detailedInfo.existingHomeNetCashFlowFromSale = 0;
              }
              detailedInfo.existingHomeNetCashFlowFromSaleCondition = 0;
              if (detailedInfo.existingHomeNetCashFlowFromSale !== 0) {
                detailedInfo.existingHomeNetCashFlowFromSaleCondition = 1;
              }
              //Real Estate Investment Property Section
              if (typeof REIData != 'undefined' && REIData !== '' && typeof REIData['Selling Costs'] !== 'undefined') {
                detailedInfo.realEstateInvPropertySaleYear = REIData['Selling Costs']['Selling Year'];
              } else {
                detailedInfo.realEstateInvPropertySaleYear = 0;
              }
              if (typeof detailedInfo.realEstateInvPropertySaleYear !== 'undefined' && detailedInfo.realEstateInvPropertySaleYear !== 0 && selectedYear === detailedInfo.realEstateInvPropertySaleYear) {
                if (typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Selling Costs'] !== 'undefined') {
                  detailedInfo.realEstateInvPropertySellingPrice = REIData['Selling Costs']['Selling Price'];
                  detailedInfo.realEstateInvPropertyClosingCost = -REIData['Selling Costs']['Selling Costs'];
                  detailedInfo.realEstateInvPropertyMortgagePayment = -REIData['Selling Costs']['Mortgage Balance Remaining'];
                  detailedInfo.realEstateInvPropertyNetCashFlowFromSale = REIData['Selling Costs']['Net Cash Flow in Sale Year'];
                } else {
                  detailedInfo.realEstateInvPropertySellingPrice = 0;
                  detailedInfo.realEstateInvPropertyClosingCost = 0;
                  detailedInfo.realEstateInvPropertyMortgagePayment = 0;
                  detailedInfo.realEstateInvPropertyNetCashFlowFromSale = 0;
                }
              } else {
                detailedInfo.realEstateInvPropertySellingPrice = 0;
                detailedInfo.realEstateInvPropertyClosingCost = 0;
                detailedInfo.realEstateInvPropertyMortgagePayment = 0;
                detailedInfo.realEstateInvPropertyNetCashFlowFromSale = 0;
              }
              detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition = 0;
              if (detailedInfo.realEstateInvPropertyNetCashFlowFromSale !== 0) {
                detailedInfo.realEstateInvPropertyNetCashFlowFromSaleCondition = 1;
              }
              //Final Value
              if (typeof chFlowData !== 'undefined' && chFlowData !== '' && typeof chFlowData[selectedYear] !== 'undefined' && chFlowData[selectedYear] !== '') {
                detailedInfo.finalValue = chFlowData[selectedYear]['Cash from Investing Activities']['Home & REI Property Net Cash Flow from Sale'];
              } else {
                detailedInfo.finalValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
             
              default:
                break;

          }
          break;

          // Cash from Financing Activities
        case 'Cash from Financing Activities':
          
          switch (selectedColumn) {
            case 'One-Time Financial Gift':
              let cashFlowData = modulesServices.cashFlowCompleteData();
              detailedInfo.oneTimeGiftValueCondition = 0;
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.financialStartYear != 'undefined') {
                detailedInfo.financialStartYear = modulesServices.module9Data.financialStartYear;
              }
              else {
                detailedInfo.financialStartYear = 0;
              }
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.financialEndYear != 'undefined') {
                detailedInfo.financialEndYear = modulesServices.module9Data.financialEndYear;
              }
              else {
                detailedInfo.financialEndYear = 0;
              }
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.financialStartYear != 'undefined' &&
                typeof modulesServices.module9Data.financialEndYear != 'undefined' &&
                (selectedYear >= modulesServices.module9Data.financialStartYear) !== '' &&
                (selectedYear <= modulesServices.module9Data.financialEndYear) !== '') {
                detailedInfo.oneTimeFinancialGiftValue = modulesServices.module9Data.financialGift;
              }
              else {
                detailedInfo.oneTimeFinancialGiftValue = 0;
              }
              if (typeof cashFlowData !== 'undefined' && cashFlowData !== '' && typeof cashFlowData[selectedYear] !== 'undefined' &&
                typeof cashFlowData[selectedYear]['Cash from Financing Activities']['One-Time Financial Gift'] !== 'undefined' && cashFlowData[selectedYear]['Cash from Financing Activities']['One-Time Financial Gift'] !== 0) {
                detailedInfo.sheetOneTimeFinancialGiftValue = cashFlowData[selectedYear]['Cash from Financing Activities']['One-Time Financial Gift'];
                detailedInfo.oneTimeGiftValueCondition = 1;
              }
              else {
                detailedInfo.sheetOneTimeFinancialGiftValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

              // Scholarships
            case 'Scholarships':
              let cashFlowDataone = modulesServices.cashFlowCompleteData();
              detailedInfo.tableValueCondition = 0;
              if (typeof modulesServices.module2Data != 'undefined'
                && typeof modulesServices.module2Data.startYear &&
                selectedYear >= modulesServices.module2Data.startYear &&
                typeof modulesServices.module2Data.graduationYear != 'undefined' &&
                selectedYear <= modulesServices.module2Data.graduationYear &&
                typeof modulesServices.module2Data.educationName != 'undefined') {
                detailedInfo.label1 = modulesServices.module2Data.educationName.name + ' Scholarships';
                detailedInfo.financialStartYear = modulesServices.module2Data.startYear;
                detailedInfo.financialEndYear = modulesServices.module2Data.graduationYear;
                detailedInfo.tableValue = modulesServices.module2Data.yearlyScholarship;
              }
              else {
                if (typeof modulesServices.module6Data != 'undefined' && typeof modulesServices.module6Data.startYear &&
                  selectedYear >= modulesServices.module6Data.startYear &&
                  typeof modulesServices.module6Data.graduationYear != 'undefined' &&
                  selectedYear <= modulesServices.module6Data.graduationYear &&
                  typeof modulesServices.module6Data.educationName != 'undefined') {
                  detailedInfo.label1 = modulesServices.module6Data.educationName.name + ' Scholarships';
                  detailedInfo.financialStartYear = modulesServices.module6Data.startYear;
                  detailedInfo.financialEndYear = modulesServices.module6Data.graduationYear;
                  detailedInfo.tableValue = modulesServices.module6Data.yearlyScholarship;
                }
                else {
                  if (typeof modulesServices.module15Data != 'undefined' && typeof modulesServices.module15Data.startYear &&
                    selectedYear >= modulesServices.module15Data.startYear &&
                    typeof modulesServices.module15Data.graduationYear != 'undefined' &&
                    selectedYear <= modulesServices.module15Data.graduationYear &&
                    typeof modulesServices.module15Data.educationName != 'undefined') {
                    detailedInfo.label1 = modulesServices.module15Data.educationName.name + ' Scholarships';
                    detailedInfo.financialStartYear = modulesServices.module15Data.startYear;
                    detailedInfo.financialEndYear = modulesServices.module15Data.graduationYear;
                    detailedInfo.tableValue = modulesServices.module15Data.yearlyScholarship;
                  }
                  else {
                    detailedInfo.label1 = 'Higher Education Scholarships';
                    detailedInfo.financialStartYear = '';
                    detailedInfo.financialEndYear = '';
                  }
                }
              }
              if (typeof cashFlowDataone !== 'undefined' && cashFlowDataone !== '' && typeof cashFlowDataone[selectedYear] !== 'undefined' && typeof cashFlowDataone[selectedYear]['Cash from Financing Activities']['Scholarships'] !== 'undefined' && cashFlowDataone[selectedYear]['Cash from Financing Activities']['Scholarships'] !== 0) {
                detailedInfo.financingFinalValue = cashFlowDataone[selectedYear]['Cash from Financing Activities']['Scholarships'];
                detailedInfo.tableValueCondition = 1;
              }
              else {
                detailedInfo.financingFinalValue = 0;
              }
              detailedInfo.label2 = 'Scholarships';
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            case 'Grants':
              let cashFlowDataTwo = modulesServices.cashFlowCompleteData();
              detailedInfo.tableValueCondition = 0;
              if (typeof modulesServices.module2Data != 'undefined' && typeof modulesServices.module2Data.startYear &&
                selectedYear >= modulesServices.module2Data.startYear &&
                typeof modulesServices.module2Data.graduationYear != 'undefined' &&
                selectedYear <= modulesServices.module2Data.graduationYear &&
                typeof modulesServices.module2Data.educationName != 'undefined') {
                detailedInfo.label1 = modulesServices.module2Data.educationName.name + ' Grants';
                detailedInfo.financialStartYear = modulesServices.module2Data.startYear;
                detailedInfo.financialEndYear = modulesServices.module2Data.graduationYear;
                detailedInfo.tableValue = modulesServices.module2Data.yearlyGrant;
              }
              else {
                if (typeof modulesServices.module6Data != 'undefined' && typeof modulesServices.module6Data.startYear &&
                  selectedYear >= modulesServices.module6Data.startYear &&
                  typeof modulesServices.module6Data.graduationYear != 'undefined' &&
                  selectedYear <= modulesServices.module6Data.graduationYear &&
                  typeof modulesServices.module6Data.educationName != 'undefined') {
                  detailedInfo.label1 = modulesServices.module6Data.educationName.name + ' Grants';
                  detailedInfo.financialStartYear = modulesServices.module6Data.startYear;
                  detailedInfo.financialEndYear = modulesServices.module6Data.graduationYear;
                  detailedInfo.tableValue = modulesServices.module6Data.yearlyGrant;
                }
                else {
                  if (typeof modulesServices.module15Data != 'undefined' && typeof modulesServices.module15Data.startYear &&
                    selectedYear >= modulesServices.module15Data.startYear &&
                    typeof modulesServices.module15Data.graduationYear != 'undefined' &&
                    selectedYear <= modulesServices.module15Data.graduationYear &&
                    typeof modulesServices.module15Data.educationName != 'undefined') {
                    detailedInfo.label1 = modulesServices.module15Data.educationName.name + ' Grants';
                    detailedInfo.financialStartYear = modulesServices.module15Data.startYear;
                    detailedInfo.financialEndYear = modulesServices.module15Data.graduationYear;
                    detailedInfo.tableValue = modulesServices.module15Data.yearlyGrant;
                  }
                  else {
                    detailedInfo.label1 = 'Higher Education Grants';
                  }
                }
              }
              if (typeof cashFlowDataTwo !== 'undefined' && cashFlowDataTwo !== '' && typeof cashFlowDataTwo[selectedYear] !== 'undefined' && typeof cashFlowDataTwo[selectedYear]['Cash from Financing Activities']['Grants'] !== 'undefined' && cashFlowDataTwo[selectedYear]['Cash from Financing Activities']['Grants'] !== 0) {
                detailedInfo.financingFinalValue = cashFlowDataTwo[selectedYear]['Cash from Financing Activities']['Grants'];
                detailedInfo.tableValueCondition = 1;
              }
              else {
                detailedInfo.financingFinalValue = 0;
              }
              detailedInfo.label2 = 'Grants';

              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            case 'Other Financial Aid':
              let cashFlowDataThree = modulesServices.cashFlowCompleteData();
              detailedInfo.tableValueCondition = 0;
              if (typeof modulesServices.module2Data != 'undefined' && typeof modulesServices.module2Data.startYear &&
                selectedYear >= modulesServices.module2Data.startYear &&
                typeof modulesServices.module2Data.graduationYear != 'undefined' &&
                selectedYear <= modulesServices.module2Data.graduationYear &&
                typeof modulesServices.module2Data.educationName != 'undefined') {
                detailedInfo.label1 = modulesServices.module2Data.educationName.name + ' Financial Aid';
                detailedInfo.financialStartYear = modulesServices.module2Data.aidBegins;
                detailedInfo.financialEndYear = modulesServices.module2Data.aidEnds;
                detailedInfo.tableValue = modulesServices.module2Data.yearlyAid;
              }
              else {
                if (typeof modulesServices.module6Data != 'undefined' && typeof modulesServices.module6Data.startYear &&
                  selectedYear >= modulesServices.module6Data.startYear &&
                  typeof modulesServices.module6Data.graduationYear != 'undefined' &&
                  selectedYear <= modulesServices.module6Data.graduationYear &&
                  typeof modulesServices.module6Data.educationName != 'undefined') {
                  detailedInfo.label1 = modulesServices.module6Data.educationName.name + ' Financial Aid';
                  detailedInfo.financialStartYear = modulesServices.module6Data.aidBegins;
                  detailedInfo.financialEndYear = modulesServices.module6Data.aidEnds;
                  detailedInfo.tableValue = modulesServices.module6Data.yearlyAid;
                }
                else {
                  if (typeof modulesServices.module15Data != 'undefined' && typeof modulesServices.module15Data.startYear &&
                    selectedYear >= modulesServices.module15Data.startYear &&
                    typeof modulesServices.module15Data.graduationYear != 'undefined' &&
                    selectedYear <= modulesServices.module15Data.graduationYear &&
                    typeof modulesServices.module15Data.educationName != 'undefined') {
                    detailedInfo.label1 = modulesServices.module15Data.educationName.name + ' Financial Aid';
                    detailedInfo.financialStartYear = modulesServices.module15Data.aidBegins;
                    detailedInfo.financialEndYear = modulesServices.module15Data.aidEnds;
                    detailedInfo.tableValue = modulesServices.module15Data.yearlyAid;
                  }
                  else {
                    detailedInfo.label1 = 'Higher Education Financial Aid';
                  }
                }
              }
              if (typeof cashFlowDataThree !== 'undefined' && cashFlowDataThree !== '' && typeof cashFlowDataThree[selectedYear] !== 'undefined' && typeof cashFlowDataThree[selectedYear]['Cash from Financing Activities']['Other Financial Aid'] !== 'undefined' && cashFlowDataThree[selectedYear]['Cash from Financing Activities']['Other Financial Aid'] !== 0) {
                detailedInfo.financingFinalValue = cashFlowDataThree[selectedYear]['Cash from Financing Activities']['Other Financial Aid'];
                detailedInfo.tableValueCondition = 1;
              }
              else {
                detailedInfo.financingFinalValue = 0;
              }
              detailedInfo.label2 = 'Financial Aid';
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;

            case 'Student Loans (Net of Origination Fees)':
              let cashFlowDataFour = modulesServices.cashFlowCompleteData();
              let fedSubHigherEdLoanData = modulesServices.fedSubHigherEdLoanCompleteData();
              let fedUnSubHigherEdLoanData = modulesServices.fedUnSubHigherEdLoanCompleteData();
              let privateHigherEdLoanData = modulesServices.privateHigherEdLoanCompleteData();
              let fedSubMoreHigherEdLoanData = modulesServices.fedSubMoreHigherEdLoanCompleteData();
              let fedUnSubMoreHigherEdLoanData = modulesServices.fedUnSubMoreHigherEdLoanCompleteData();
              let privateMoreHigherEdLoanData = modulesServices.privateMoreHigherEdLoanCompleteData();
              let fedSubNewHigherEdLoanData = modulesServices.fedSubNewHigherEdLoanCompleteData();
              let fedUnSubNewHigherEdLoanData = modulesServices.fedUnSubNewHigherEdLoanCompleteData();
              let privateNewHigherEdLoanData = modulesServices.privateNewHigherEdLoanCompleteData();

              detailedInfo.higherEdFederalSubLoansTakenCondition = 0;
              detailedInfo.higherEdFederalUnSubLoansTakenCondition = 0;
              detailedInfo.higherEdFederalPrivateLoansTakenCondition = 0;
              detailedInfo.totalHigherEducationLoanCondition = 0;
              detailedInfo.moreHigherEdFederalSubLoansTakenCondition = 0;
              detailedInfo.moreHigherEdFederalUnSubLoansTakenCondition = 0;
              detailedInfo.moreHigherEdFederalPrivateLoansTakenCondition = 0;
              detailedInfo.totalMoreHigherEducationLoanCondition = 0;
              detailedInfo.newHigherEdFederalSubLoansTakenCondition = 0;
              detailedInfo.newHigherEdFederalUnSubLoansTakenCondition = 0;
              detailedInfo.newHigherEdFederalPrivateLoansTakenCondition = 0;
              detailedInfo.totalNewHigherEducationLoanCondition = 0;

              if (typeof fedSubHigherEdLoanData !== 'undefined' && fedSubHigherEdLoanData !== '' && typeof fedSubHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined' && fedSubHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] > 0) {
                detailedInfo.higherEdFederalSubValue = fedSubHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.higherEdFederalSubLoansTakenCondition = 1;
              }
              else {
                detailedInfo.higherEdFederalSubValue = 0;
              }
              if (typeof fedSubHigherEdLoanData !== 'undefined' && fedSubHigherEdLoanData !== '' && typeof fedSubHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.higherEdFederalSubPointsPaidValue = -fedSubHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.higherEdFederalSubPointsPaidValue = 0;
              }
              if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== '' && typeof fedUnSubHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined' && fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] > 0) {
                detailedInfo.higherEdFederalUnSubValue = fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.higherEdFederalUnSubLoansTakenCondition = 1;
              }
              else {
                detailedInfo.higherEdFederalUnSubValue = 0;
              }
              if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== '' && typeof fedUnSubHigherEdLoanData['Summation Data'] !== 'undefined' && fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.higherEdFederalUnSubPointsPaidValue = -fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.higherEdFederalUnSubPointsPaidValue = 0;
              }
              if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== '' && typeof privateHigherEdLoanData['Summation Data'] !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined' && privateHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] > 0) {
                detailedInfo.higherEdFederalPrivateValue = privateHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.higherEdFederalPrivateLoansTakenCondition = 1;
              }
              else {
                detailedInfo.higherEdFederalPrivateValue = 0;
              }
              if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== '' && typeof privateHigherEdLoanData['Summation Data'] !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.higherEdFederalPrivatePointsPaidValue = -privateHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.higherEdFederalPrivatePointsPaidValue = 0;
              }
              detailedInfo.totalHigherEducationLoanValue = detailedInfo.higherEdFederalSubValue + detailedInfo.higherEdFederalSubPointsPaidValue + detailedInfo.higherEdFederalUnSubValue + detailedInfo.higherEdFederalUnSubPointsPaidValue + detailedInfo.higherEdFederalPrivateValue + detailedInfo.higherEdFederalPrivatePointsPaidValue;
              if (detailedInfo.totalHigherEducationLoanValue > 0) {
                detailedInfo.totalHigherEducationLoanCondition = 1;
              }
              // Related to More Higher Education values
              if (typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== '' && typeof fedSubMoreHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined' && fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] > 0) {
                detailedInfo.moreHigherEdFederalSubValue = fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.moreHigherEdFederalSubLoansTakenCondition = 1;
              }
              else {
                detailedInfo.moreHigherEdFederalSubValue = 0;
              }
              if (typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== '' && typeof fedSubMoreHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.moreHigherEdFederalSubPointsPaidValue = -fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.moreHigherEdFederalSubPointsPaidValue = 0;
              }
              if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined' && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] > 0) {
                detailedInfo.moreHigherEdFederalUnSubValue = fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.moreHigherEdFederalUnSubLoansTakenCondition = 1;
              }
              else {
                detailedInfo.moreHigherEdFederalUnSubValue = 0;
              }
              if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== '' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.moreHigherEdFederalUnSubPointsPaidValue = -fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.moreHigherEdFederalUnSubPointsPaidValue = 0;
              }
              if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined' && privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] > 0) {
                detailedInfo.moreHigherEdFederalPrivateValue = privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.moreHigherEdFederalPrivateLoansTakenCondition = 1;
              }
              else {
                detailedInfo.moreHigherEdFederalPrivateValue = 0;
              }
              if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== '' && typeof privateMoreHigherEdLoanData['Summation Data'] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.moreHigherEdFederalPrivatePointsPaidValue = -privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.moreHigherEdFederalPrivatePointsPaidValue = 0;
              }
              detailedInfo.totalMoreHigherEducationLoanValue = detailedInfo.moreHigherEdFederalSubValue + detailedInfo.moreHigherEdFederalSubPointsPaidValue + detailedInfo.moreHigherEdFederalUnSubValue + detailedInfo.moreHigherEdFederalUnSubPointsPaidValue + detailedInfo.moreHigherEdFederalPrivateValue + detailedInfo.moreHigherEdFederalPrivatePointsPaidValue;
              if (detailedInfo.totalMoreHigherEducationLoanValue > 0) {
                detailedInfo.totalMoreHigherEducationLoanCondition = 1;
              }
              if (typeof cashFlowDataFour !== 'undefined' && cashFlowDataFour !== '' && typeof cashFlowDataFour[selectedYear] !== 'undefined' && typeof cashFlowDataFour[selectedYear]['Cash from Financing Activities']['Student Loans (Net of Origination Fees)'] !== 'undefined' && cashFlowDataFour[selectedYear]['Cash from Financing Activities']['Student Loans (Net of Origination Fees)'] !== 0) {
                detailedInfo.studentLoansLoanPointsValue = cashFlowDataFour[selectedYear]['Cash from Financing Activities']['Student Loans (Net of Origination Fees)'];
              }
              else {
                detailedInfo.studentLoansLoanPointsValue = 0;
              }
              //Further Higher Education Student Loans
              if (typeof fedSubNewHigherEdLoanData !== 'undefined' && fedSubNewHigherEdLoanData !== '' && typeof fedSubNewHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] != 'undefined' && fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] > 0) {
                detailedInfo.newHigherEdFederalSubValue = fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.newHigherEdFederalSubLoansTakenCondition = 1;
              }
              else {
                detailedInfo.newHigherEdFederalSubValue = 0;
              }
              if (typeof fedSubNewHigherEdLoanData !== 'undefined' && fedSubNewHigherEdLoanData !== '' && typeof fedSubNewHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.newHigherEdFederalSubPointsPaidValue = -fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.newHigherEdFederalSubPointsPaidValue = 0;
              }
              if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== '' && typeof fedUnSubNewHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined' && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] > 0) {
                detailedInfo.newHigherEdFederalUnSubValue = fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.newHigherEdFederalUnSubLoansTakenCondition = 1;
              }
              else {
                detailedInfo.newHigherEdFederalUnSubValue = 0;
              }
              if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== '' && typeof fedUnSubNewHigherEdLoanData['Summation Data'] !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.newHigherEdFederalUnSubPointsPaidValue = -fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.newHigherEdFederalUnSubPointsPaidValue = 0;
              }
              if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== '' && typeof privateNewHigherEdLoanData['Summation Data'] !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined' && privateNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] > 0) {
                detailedInfo.newHigherEdFederalPrivateValue = privateNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.newHigherEdFederalPrivateLoansTakenCondition = 1;
              }
              else {
                detailedInfo.newHigherEdFederalPrivateValue = 0;
              }
              if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== '' && typeof privateNewHigherEdLoanData['Summation Data'] !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'] !== 'undefined') {
                detailedInfo.newHigherEdFederalPrivatePointsPaidValue = -privateNewHigherEdLoanData['Summation Data'][selectedYear]['Points Paid'];
              }
              else {
                detailedInfo.newHigherEdFederalPrivatePointsPaidValue = 0;
              }
              detailedInfo.totalNewHigherEducationLoanValue = detailedInfo.newHigherEdFederalSubValue + detailedInfo.newHigherEdFederalSubPointsPaidValue + detailedInfo.newHigherEdFederalUnSubValue + detailedInfo.newHigherEdFederalUnSubPointsPaidValue + detailedInfo.newHigherEdFederalPrivateValue + detailedInfo.newHigherEdFederalPrivatePointsPaidValue;
              if (detailedInfo.totalNewHigherEducationLoanValue > 0) {
                detailedInfo.totalNewHigherEducationLoanCondition = 1;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              
            case 'Family Contribution':
              let cashFlowDataFive = modulesServices.cashFlowCompleteData();
              detailedInfo.tableValueCondition = 0;
              if (typeof modulesServices.module2Data != 'undefined' && typeof modulesServices.module2Data.startYear &&
                selectedYear >= modulesServices.module2Data.startYear &&
                typeof modulesServices.module2Data.graduationYear != 'undefined' &&
                selectedYear <= modulesServices.module2Data.graduationYear &&
                typeof modulesServices.module2Data.educationName != 'undefined') {
                detailedInfo.label1 = modulesServices.module2Data.educationName.name + ' Family Contribution';
                detailedInfo.financialStartYear = modulesServices.module2Data.familyContriStartYear;
                detailedInfo.financialEndYear = modulesServices.module2Data.familyContriEndYear;
                detailedInfo.tableValue = modulesServices.module2Data.yearlyContribution;
              }
              else {
                if (typeof modulesServices.module6Data != 'undefined' && typeof modulesServices.module6Data.startYear &&
                  selectedYear >= modulesServices.module6Data.startYear &&
                  typeof modulesServices.module6Data.graduationYear != 'undefined' &&
                  selectedYear <= modulesServices.module6Data.graduationYear &&
                  typeof modulesServices.module6Data.educationName != 'undefined') {
                  detailedInfo.label1 = modulesServices.module6Data.educationName.name + ' Family Contribution';
                  detailedInfo.financialStartYear = modulesServices.module6Data.familystartYear;
                  detailedInfo.financialEndYear = modulesServices.module6Data.familyendYear;
                  detailedInfo.tableValue = modulesServices.module6Data.yearlyContribution;
                }
                else {
                  if (typeof modulesServices.module15Data != 'undefined' && typeof modulesServices.module15Data.startYear &&
                    selectedYear >= modulesServices.module15Data.startYear &&
                    typeof modulesServices.module15Data.graduationYear != 'undefined' &&
                    selectedYear <= modulesServices.module15Data.graduationYear &&
                    typeof modulesServices.module15Data.educationName != 'undefined') {
                    detailedInfo.label1 = modulesServices.module15Data.educationName.name + ' Family Contribution';
                    detailedInfo.financialStartYear = modulesServices.module15Data.familystartYear;
                    detailedInfo.financialEndYear = modulesServices.module15Data.familyendYear;
                    detailedInfo.tableValue = modulesServices.module15Data.yearlyContribution;
                  }
                  else {
                    detailedInfo.label1 = 'Higher Education Family Contribution';
                    detailedInfo.financialStartYear = '';
                    detailedInfo.financialEndYear = '';
                    detailedInfo.tableValue = '';
                  }
                }
              }
              if (typeof cashFlowDataFive !== 'undefined' && cashFlowDataFive !== '' && typeof cashFlowDataFive[selectedYear] !== 'undefined' && typeof cashFlowDataFive[selectedYear]['Cash from Financing Activities']['Other Financial Aid'] !== 'undefined' && cashFlowDataFive[selectedYear]['Cash from Financing Activities']['Family Contribution'] !== 0) {
                detailedInfo.financingFinalValue = cashFlowDataFive[selectedYear]['Cash from Financing Activities']['Family Contribution'];
                detailedInfo.tableValueCondition = 1;
              }
              else {
                detailedInfo.financingFinalValue = 0;
              }

              detailedInfo.label2 = 'Family Contribution';
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Net Change in Cash':
          switch (selectedColumn) {
            case 'Net Change in Cash':
              let cashFlowCompleteData = modulesServices.cashFlowCompleteData();
              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Total Cash from Financing Activities'] != 'undefined') {
                detailedInfo.totalCashFromFinancingActivitiesValue = cashFlowCompleteData[selectedYear]['Total Cash from Financing Activities'];
              }
              else {
                detailedInfo.totalCashFromFinancingActivitiesValue = 0;
              }

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Total Cash from Investing Activities'] != 'undefined') {
                detailedInfo.totalCashFromFinancingInvestingValue = cashFlowCompleteData[selectedYear]['Total Cash from Investing Activities'];
              }
              else {
                detailedInfo.totalCashFromFinancingInvestingValue = 0;
              }

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Total Cash from Operating Activities'] != 'undefined') {
                detailedInfo.totalCashFromFinancingOperatingValue = cashFlowCompleteData[selectedYear]['Total Cash from Operating Activities'];
              }
              else {
                detailedInfo.totalCashFromFinancingOperatingValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Ending Cash Surplus / (Deficit)':
          switch (selectedColumn) {
            case 'Ending Cash Surplus / (Deficit)':
              let cashFlowCompleteData = modulesServices.cashFlowCompleteData();
              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Net Change in Cash'] != 'undefined') {
                detailedInfo.netChangeInCashValue = cashFlowCompleteData[selectedYear]['Net Change in Cash'];
              }
              else {
                detailedInfo.netChangeInCashValue = 0;
              }

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Beginning Cash Balance'] != 'undefined') {
                detailedInfo.beginningCashValue = cashFlowCompleteData[selectedYear]['Beginning Cash Balance'];
              }
              else {
                detailedInfo.beginningCashValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Cash Injection from Short-Term Investments':
          switch (selectedColumn) {
            case 'Cash Injection from Short-Term Investments':
              let investmentsData = modulesServices.investmentsCompleteData();
              detailedInfo.cashInjectionShortTermCondition = 0;
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Cash Need'] != 'undefined') {
                detailedInfo.cashNeedValue = investmentsData['Short-Term Investments'][selectedYear]['Cash Need'];
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Balance2'] != 'undefined') {
                detailedInfo.shortTermInvestmentBalanceValue = investmentsData['Short-Term Investments'][selectedYear]['Balance2'];
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Cash Transfer'] != 'undefined' &&
                investmentsData['Short-Term Investments'][selectedYear]['Cash Transfer'] > 0) {
                detailedInfo.cashInjectionShortTermInvestments = investmentsData['Short-Term Investments'][selectedYear]['Cash Transfer'];
                detailedInfo.cashInjectionShortTermCondition = 1;
              }
              else {
                detailedInfo.cashInjectionShortTermInvestments = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Cash Injection from Long-Term Investments':
          switch (selectedColumn) {
            case 'Cash Injection from Long-Term Investments':
              let investmentsData = modulesServices.investmentsCompleteData();
              detailedInfo.cashInjectionLongTermCondition = 0;
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Cash Need'] != 'undefined') {
                detailedInfo.cashNeedValueLongTerm = investmentsData['Long-Term Investments'][selectedYear]['Cash Need'];
              }

              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Balance2'] != 'undefined') {
                detailedInfo.longTermInvestmentBalanceValue = investmentsData['Long-Term Investments'][selectedYear]['Balance2'];
              }

              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Cash Transfer'] != 'undefined' &&
                investmentsData['Long-Term Investments'][selectedYear]['Cash Transfer'] > 0) {
                detailedInfo.cashInjectionLongTermInvestments = investmentsData['Long-Term Investments'][selectedYear]['Cash Transfer'];
                detailedInfo.cashInjectionLongTermCondition = 1;
              }
              else {
                detailedInfo.cashInjectionLongTermInvestments = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Ending Cash Balance after Transfers':
          switch (selectedColumn) {
            case 'Ending Cash Balance after Transfers':
              let cashFlowCompleteData = modulesServices.cashFlowCompleteData();
              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Ending Cash Surplus'] != 'undefined') {
                detailedInfo.endingCashSurplusValue = cashFlowCompleteData[selectedYear]['Ending Cash Surplus'];
              }
              else {
                detailedInfo.endingCashSurplusValue = 0;
              }

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Cash Injection from Short-Term Investments'] != 'undefined') {
                detailedInfo.cashInjectionShortTermValue = cashFlowCompleteData[selectedYear]['Cash Injection from Short-Term Investments'];
              }
              else {
                detailedInfo.cashInjectionShortTermValue = 0;
              }

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Cash Injection from Long-Term Investments'] != 'undefined') {
                detailedInfo.cashInjectionLongTermValue = cashFlowCompleteData[selectedYear]['Cash Injection from Long-Term Investments'];
              }
              else {
                detailedInfo.cashInjectionLongTermValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Excess Cash Put into STI':
          switch (selectedColumn) {
            case 'Excess Cash Put into STI':
              let cashFlowCompleteData = modulesServices.cashFlowCompleteData();
              let module1MaxCashBalance = modulesServices.module1Data.maxCashBalance !== undefined ? modulesServices.module1Data.maxCashBalance : 0 ;
              detailedInfo.excessCashPutintoSTICondition = 0;

              if (typeof modulesServices.module1Data != 'undefined' &&
                typeof modulesServices.module1Data.maxCashBalance != 'undefined') {
                // let module1MaxCashBalance = modulesServices.module1Data.maxCashBalance;
              }
              else {
                // let module1MaxCashBalance = 0;
              }
              detailedInfo.maximumCashBalanceValue = module1MaxCashBalance;

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Excess Cash Put into STI'] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Excess Cash Put into STI'][module1MaxCashBalance] != 'undefined') {
                detailedInfo.excessCashPutintoSTIValue = cashFlowCompleteData[selectedYear]['Excess Cash Put into STI'][module1MaxCashBalance];
              }
              else {
                detailedInfo.excessCashPutintoSTIValue = 0;
              }

              if (detailedInfo.excessCashPutintoSTIValue !== 0) {
                detailedInfo.excessCashPutintoSTICondition = 1;
              }

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.startYear != 'undefined') {
                detailedInfo.shortTermInvestmentBeginYearsti = modulesServices.module9Data.start_year;
              }
              else {
                detailedInfo.shortTermInvestmentBeginYearsti = 0;
              }

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Ending Cash Balance after Transfers'] != 'undefined') {
                detailedInfo.endingCashBalanceAfterTransferValue = cashFlowCompleteData[selectedYear]['Ending Cash Balance after Transfers'];
              }
              else {
                detailedInfo.endingCashBalanceAfterTransferValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Ending Cash Balance':
          switch (selectedColumn) {
            case 'Ending Cash Balance':
              let cashFlowData = modulesServices.cashFlowCompleteData();
              let cashInjectionShortTermInvestments = 0;
              let cashInjectionLongTermInvestments = 0;

              if (typeof cashFlowData != 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Beginning Cash Balance'] != 'undefined') {
                detailedInfo.beginingCashBalance = cashFlowData[selectedYear]['Beginning Cash Balance'];
              }
              else {
                detailedInfo.beginingCashBalance = 0;
              }

              if (typeof cashFlowData != 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Total Cash from Operating Activities'] != 'undefined') {
                detailedInfo.totalCashFromOperatingActivities = cashFlowData[selectedYear]['Total Cash from Operating Activities'];
              }
              else {
                detailedInfo.totalCashFromOperatingActivities = 0;
              }

              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Total Cash from Investing Activities'] != 'undefined') {
                detailedInfo.totalCashFromInvestingActivities = cashFlowData[selectedYear]['Total Cash from Investing Activities'];
              }
              else {
                detailedInfo.totalCashFromInvestingActivities = 0;
              }

              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Total Cash from Financing Activities'] != 'undefined') {
                detailedInfo.totalCashFromFinancingActivities = cashFlowData[selectedYear]['Total Cash from Financing Activities'];
              }
              else {
                detailedInfo.totalCashFromFinancingActivities = 0;
              }

              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Ending Cash Surplus'] != 'undefined') {
                detailedInfo.endingCashSurplus = cashFlowData[selectedYear]['Ending Cash Surplus'];
              }
              else {
                detailedInfo.endingCashSurplus = 0;
              }

              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Cash Injection from Short-Term Investments'] != 'undefined') {
                cashInjectionShortTermInvestments = cashFlowData[selectedYear]['Cash Injection from Short-Term Investments'];
              }
              else {
                cashInjectionShortTermInvestments = 0;
              }

              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Cash Injection from Long-Term Investments'] != 'undefined') {
                cashInjectionLongTermInvestments = cashFlowData[selectedYear]['Cash Injection from Long-Term Investments'];
              }
              else {
                cashInjectionLongTermInvestments = 0;
              }
              detailedInfo.cashInjectionFromInvestments = parseFloat(cashInjectionShortTermInvestments) + parseFloat(cashInjectionLongTermInvestments);
              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Ending Cash Balance after Transfers'] != 'undefined') {
                detailedInfo.endingCashCashBalAfterTransfer = cashFlowData[selectedYear]['Ending Cash Balance after Transfers'];
              }
              else {
                detailedInfo.endingCashCashBalAfterTransfer = 0;
              }
              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof modulesServices.module1Data != 'undefined' && typeof modulesServices.module1Data.maxCashBalance != 'undefined' &&
                typeof cashFlowData[selectedYear]['Excess Cash Put into STI'] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Excess Cash Put into STI'][modulesServices.module1Data.maxCashBalance] != 'undefined') {
                detailedInfo.excessCashPutIntoSTI = cashFlowData[selectedYear]['Excess Cash Put into STI'][modulesServices.module1Data.maxCashBalance];
              }
              else {
                detailedInfo.excessCashPutIntoSTI = 0;
              }

              if (typeof cashFlowData !== 'undefined' &&
                cashFlowData !== '' &&
                typeof cashFlowData[selectedYear] != 'undefined' &&
                typeof cashFlowData[selectedYear]['Ending Cash Balance'] != 'undefined') {
                detailedInfo.finalEndingCashBalance = cashFlowData[selectedYear]['Ending Cash Balance'];
              }
              else {
                detailedInfo.finalEndingCashBalance = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Short-Term Investments Balance':
          switch (selectedColumn) {
            case 'Short-Term Investments Balance':
              let investmentsData = modulesServices.investmentsCompleteData();
              detailedInfo.shortTermBalanceFinalCondition = 0;
              detailedInfo.shortTermInvestmentBalanceCondition = 0;
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.startYear != 'undefined') {
                detailedInfo.shortTermInvestmentBeginYear = modulesServices.module9Data.startYear;
              }
              else {
                detailedInfo.shortTermInvestmentBeginYear = 0;
              }
              detailedInfo.shortTermInvestmentBalanceYear = parseInt(selectedYear) - 1;
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Beg Balance'] !== 'undefined' && investmentsData['Short-Term Investments'][selectedYear]['Beg Balance'] !== 0) {
                detailedInfo.shortTermInvestmentBalanceValue = investmentsData['Short-Term Investments'][selectedYear]['Beg Balance'];
                detailedInfo.shortTermInvestmentBalanceCondition = 1;
              }
              else {
                detailedInfo.shortTermInvestmentBalanceValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Cash Contibution'] != 'undefined') {
                detailedInfo.annualCashContributionValue = investmentsData['Short-Term Investments'][selectedYear]['Cash Contibution'];
              }
              else {
                detailedInfo.annualCashContributionValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Earnings'] != 'undefined') {
                detailedInfo.annualEarningsValue = investmentsData['Short-Term Investments'][selectedYear]['Earnings'];
              }
              else {
                detailedInfo.annualEarningsValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Cash Transfer'] != 'undefined') {
                detailedInfo.transferToCashAmountValue = -investmentsData['Short-Term Investments'][selectedYear]['Cash Transfer'];
              }
              else {
                detailedInfo.transferToCashAmountValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Contribution'] != 'undefined') {
                detailedInfo.contributionExcessCashValue = investmentsData['Short-Term Investments'][selectedYear]['Contribution'];
              }
              else {
                detailedInfo.contributionExcessCashValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Short-Term Investments'] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Short-Term Investments'][selectedYear]['Balance4'] !== 'undefined' && investmentsData['Short-Term Investments'][selectedYear]['Balance4'] !== 0) {
                detailedInfo.shortTermBalanceFinalValue = investmentsData['Short-Term Investments'][selectedYear]['Balance4'];
                detailedInfo.shortTermBalanceFinalCondition = 1;
              }
              else {
                detailedInfo.shortTermBalanceFinalValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Excess STI Put into LTI':
          switch (selectedColumn) {
            case 'Excess STI Put into LTI':
              let cashFlowCompleteData = modulesServices.cashFlowCompleteData();
             
              let module9MaxShortTermInvestmentBal = modulesServices.module9Data.short_term_max_sti_balance_before_lt_investment;
              
              detailedInfo.excessCashPutintoLTICondition = 0;
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.long_term_start_year != 'undefined') {
                detailedInfo.longTermInvestmentBeginYearsti = modulesServices.module9Data.long_term_start_year;
              }
              else {
                detailedInfo.longTermInvestmentBeginYearsti = 0;
              }

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Short-Term Investment Balance'] != 'undefined') {
                detailedInfo.shortTermInvestmentsBalanceValue = cashFlowCompleteData[selectedYear]['Short-Term Investment Balance'];
              }
              else {
                detailedInfo.shortTermInvestmentsBalanceValue = 0;
              }

              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.stiBalanceBeforelt != 'undefined' &&
                modulesServices.module9Data.stiBalanceBeforelt !== '') {
                // let module9MaxShortTermInvestmentBal = modulesServices.module9Data.stiBalanceBeforelt;
              }
              else {
                // let module9MaxShortTermInvestmentBal = 0;
              }
              detailedInfo.maximumShortTermInvestmentBalanceValue = module9MaxShortTermInvestmentBal;

              if (typeof cashFlowCompleteData != 'undefined' &&
                cashFlowCompleteData !== '' &&
                typeof cashFlowCompleteData[selectedYear] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Excess STI Put into LTI'] != 'undefined' &&
                typeof cashFlowCompleteData[selectedYear]['Excess STI Put into LTI'][module9MaxShortTermInvestmentBal] !== 'undefined' && cashFlowCompleteData[selectedYear]['Excess STI Put into LTI'][module9MaxShortTermInvestmentBal] !== 0) {
                detailedInfo.excessCashPutintoLTIValue = cashFlowCompleteData[selectedYear]['Excess STI Put into LTI'][module9MaxShortTermInvestmentBal];
                detailedInfo.excessCashPutintoLTICondition = 1;
              }
              else {
                detailedInfo.excessCashPutintoLTIValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              setOpenCashFlowSheetPopup(true);
              break;
              default:
                break;
          }
          break;

        case 'Long-Term Investments Balance':
          setOpenCashFlowSheetPopup(true);
          switch (selectedColumn) {
            case 'Long-Term Investments Balance':
              let investmentsData = modulesServices.investmentsCompleteData();
              detailedInfo.longTermBalanceFinalValueCondition = 0;
              if (typeof modulesServices.module9Data != 'undefined' &&
                typeof modulesServices.module9Data.long_term_start_year != 'undefined') {
                detailedInfo.longTermInvestmentBeginYear = modulesServices.module9Data.long_term_start_year;
              }
              else {
                detailedInfo.longTermInvestmentBeginYear = 0;
              }
              detailedInfo.longTermInvestmentBalanceYear = parseInt(selectedYear) - 1;
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Beg Balance'] != 'undefined') {
                detailedInfo.longTermInvestmentBalanceValue = investmentsData['Long-Term Investments'][selectedYear]['Beg Balance'];
              }
              else {
                detailedInfo.longTermInvestmentBalanceValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Cash Contibution'] != 'undefined') {
                detailedInfo.longTermAnnualCashContributionValue = investmentsData['Long-Term Investments'][selectedYear]['Cash Contibution'];
              }
              else {
                detailedInfo.longTermAnnualCashContributionValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Earnings'] != 'undefined') {
                detailedInfo.longTermAnnualEarningsValue = investmentsData['Long-Term Investments'][selectedYear]['Earnings'];
              }
              else {
                detailedInfo.longTermAnnualEarningsValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Cash Transfer'] != 'undefined') {
                detailedInfo.longTermTransferToCashAmountValue = -investmentsData['Long-Term Investments'][selectedYear]['Cash Transfer'];
              }
              else {
                detailedInfo.longTermTransferToCashAmountValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Contribution'] != 'undefined') {
                detailedInfo.longTermcontributionExcessCashValue = investmentsData['Long-Term Investments'][selectedYear]['Contribution'];
              }
              else {
                detailedInfo.longTermcontributionExcessCashValue = 0;
              }
              if (typeof investmentsData != 'undefined' &&
                investmentsData !== '' &&
                typeof investmentsData['Long-Term Investments'] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear] != 'undefined' &&
                typeof investmentsData['Long-Term Investments'][selectedYear]['Ending Balance'] !== 'undefined' && investmentsData['Long-Term Investments'][selectedYear]['Ending Balance'] !== 0) {
                detailedInfo.longTermBalanceFinalValue = investmentsData['Long-Term Investments'][selectedYear]['Ending Balance'];
                detailedInfo.longTermBalanceFinalValueCondition = 1;
              }
              else {
                detailedInfo.longTermBalanceFinalValue = 0;
              }
              setCashFlowSheetDetails(detailedInfo);
              break;
              default:
                break;
          }
          break;
          default:
            break;

      }
      
    }
    if(selectedSection === 'HSA Contributions' || selectedSection === 'Car and Lease Down Payment & Fees' || selectedSection === 'Wedding Costs'){
      setOpenCashFlowSheetPopup(false);
    }
  }

  return (
    <div className={classes.modalBodyData}>
      <div className={classes.incomeSheetModal}>
        <div className={classes.popupTable}>
          <table className={classNames( classes.incomeSheetTable, classes.cashFlowSheetTable )}>
            <thead>
              <tr className={classes.topIncomeBg}>
                <th>Annual Cash Flow Statement</th>
                <th>&nbsp;</th>
                {
                  rangeList.map((range) => (
                    <th>{range + (dispalyObj.year)}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              <tr className={classNames(classes.blankSpace, classes.emptyRowdata)}>
                <td className={ classes.grayBlankSpace}>&nbsp;</td>
                <td className={ classes.grayBlankSpace}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classNames( classes.blueBorderLine, classes.noCursorData ) }>
                <td className={classes.headingData}>Beginning Cash Balance</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[1]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Beginning Cash Balance'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>

              <tr className={classNames(classes.blankSpace, classes.emptyRowdata)}>
                <td className={ classes.grayBlankSpace}>&nbsp;</td>
                <td className={ classes.grayBlankSpace}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              <tr className={classes.blueRowData}>
                <td>Cash from Operating Activities</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj.cashFlowCashOperatingHeadings && Object.keys(dispalyObj.cashFlowCashOperatingHeadings).map((grossTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {grossTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Cash from Operating Activities'][grossTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Cash from Operating Activities', grossTitle) }} >
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                              )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }

              <tr className={ classNames( classes.blueBorderLine, classes.noCursorData ) }>
                <td className={classes.headingData}>Total Cash from Operating Activities</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[2]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Total Cash from Operating Activities'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Cash from Investing Activities</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {                
                dispalyObj.cashFlowCashInvestingHeadings && Object.keys(dispalyObj.cashFlowCashInvestingHeadings).map((grossTitle) => (
                  (grossTitle !== 'Rental Property Sale' && (                  
                    <tr>
                      <td className={classes.subHeadingData}>
                        {displayTitle(1, grossTitle)}
                      </td>
                      <td className={classes.lightBlueBg}>&nbsp;</td>
                      {                        
                        rangeList.map((range) => {
                          const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Cash from Investing Activities'][grossTitle];
                          return (
                            <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Cash from Investing Activities', grossTitle) }} >
                              {value >= 0 ? (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                              ) : (
                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                                )}
                            </td>
                          );
                        })
                      }
                    </tr>
                  ))
                ))
              }
              <tr className={ classNames( classes.blueBorderLine, classes.noCursorData ) }>
                <td className={classes.headingData}>Total Cash from Investing Activities</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[3]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Total Cash from Investing Activities'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>

              <tr className={classes.blueBorderLine}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              <tr className={classes.blueRowData}>
                <td>Cash from Financing Activities</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.cashFlowCashFinancingHeadings && Object.keys(dispalyObj.cashFlowCashFinancingHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Cash from Financing Activities'][livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Cash from Financing Activities',livingExpensesTitle )} >
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                              )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }

              <tr className={ classNames( classes.blueBorderLine, classes.noCursorData ) }>
                <td className={classes.headingData}>Total Cash from Financing Activities   </td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[4]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Total Cash from Financing Activities'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Net Change in Cash</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[5]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Net Change in Cash'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Net Change in Cash','Net Change in Cash' )} >
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Funds Flow</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              <tr>
                <td className={classes.headingData}>Ending Cash Balance before Transfers</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Ending Cash Surplus'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Ending Cash Surplus / (Deficit)','Ending Cash Surplus / (Deficit)' )} >
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr>
                <td className={ classes.subHeadingData }>Cash Injection from Short-Term Investments</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Cash Injection from Short-Term Investments'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Cash Injection from Short-Term Investments','Cash Injection from Short-Term Investments' )} >
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr>
                <td className={ classes.subHeadingData }>Cash Injection from Long-Term Investments</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Cash Injection from Long-Term Investments'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Cash Injection from Long-Term Investments','Cash Injection from Long-Term Investments')} >
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr>
                <td className={classes.headingData}>Ending Cash Balance after Transfers</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Ending Cash Balance after Transfers'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Ending Cash Balance after Transfers','Ending Cash Balance after Transfers')}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata, classes.emptyRowdataTop)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.cashFlowCashSTIHeadings && Object.keys(dispalyObj.cashFlowCashSTIHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.headingData}>
                      Excess Cash Moved into Short-Term Investments
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Excess Cash Put into STI'][livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Excess Cash Put into STI','Excess Cash Put into STI')}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                              )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }

              <tr className={classNames(classes.emptyRowdata, classes.emptyRowdataGray)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr>
                <td className={classes.headingData}>Ending Cash Balance</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[6]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Ending Cash Balance'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Ending Cash Balance','Ending Cash Balance')} >
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.emptyRowdata, classes.emptyRowdataGray)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr>
                <td className={classes.headingData}>Short-Term Investments Balance</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[7]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Short-Term Investment Balance'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Short-Term Investments Balance','Short-Term Investments Balance')}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.emptyRowdata, classes.emptyRowdataGray)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.cashFlowCashLIIHeadings && Object.keys(dispalyObj.cashFlowCashLIIHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.headingData}>
                      Excess Short-Term Investments Moved into Long-Term Investments
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Excess STI Put into LTI'][livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Excess STI Put into LTI','Excess STI Put into LTI')}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                                <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                              )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classNames(classes.emptyRowdata, classes.emptyRowdataGray)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Long-Term Investments Balance</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph_data[8]}
                  />
                </td>
                {
                  dispalyObj.cashflowData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.cashflowData[range + (dispalyObj.year)]['Long-Term Investment Balance'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={ () => showDetailInfo(dispalyObj.year + range, 'Long-Term Investments Balance','Long-Term Investments Balance')}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                          )}
                      </td>
                    );
                  })
                }
              </tr>

            </tbody>
          </table>
        </div>
      </div>
      {openCashFlowSheetPopup === true && (
        <CashFlowSheetPopup detailedInfo={cashFlowSheetDetails} openCashFlowSheetPopup={openCashFlowSheetPopup} setOpenCashFlowSheetPopup={setOpenCashFlowSheetPopup} currentColumn={currentColumn} />
      )}
    </div>
  );
};

CashFlowStatement.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(CashFlowStatement);
