import * as moduleServices from './modules-services';

export default function getCalculatedValues() {
  return {
    income_array: ['yearly_income', 'bonus_or_tips_or_commission', 'second_yearly_income', 'spouse_income', 'spouse_bonus_or_tips_or_commission', 'second_spouse_income', 'second_spouse_bonus_or_tips_or_commission'],
    module2: {
      yearly_income: moduleServices.module2Data.yearly_income,
      off_campus_food: moduleServices.module2Data.off_campus_food,
      clothing: moduleServices.module2Data.clothing,
      entertainment: moduleServices.module2Data.entertainment,
      technology: moduleServices.module2Data.technology,
      transportation: moduleServices.module2Data.transportation,
      miscellaneous: moduleServices.module2Data.miscellaneous,
      medical_costs_before_deductible: moduleServices.module2Data.medical_costs_before_deductible,
      health_insurance_premium: moduleServices.module2Data.health_insurance_premium,
      rent: moduleServices.module2Data.rent,
      utilities: moduleServices.module2Data.utilities,
      food: moduleServices.module2Data.food,
      second_clothing: moduleServices.module2Data.second_clothing,
      second_entertainment: moduleServices.module2Data.second_entertainment,
      second_technology: moduleServices.module2Data.second_technology,
      second_transportation: moduleServices.module2Data.second_transportation,
      second_miscellaneous: moduleServices.module2Data.second_miscellaneous,
      second_medical_costs_before_deductible: moduleServices.module2Data.second_medical_costs_before_deductible,
      second_health_insurance_premium: moduleServices.module2Data.second_health_insurance_premium,
    },
    module4: {
      yearly_income: moduleServices.module4Data.yearly_income,
      bonus_or_tips_or_commission: moduleServices.module4Data.bonus_or_tips_or_commission,
      second_yearly_income: moduleServices.module4Data.second_yearly_income,
      rent: moduleServices.module4Data.rent,
      utilities: moduleServices.module4Data.utilities,
      food: moduleServices.module4Data.food,
      grocery_shopping: moduleServices.module4Data.grocery_shopping,
      meals_eaten_not_at_home: moduleServices.module4Data.meals_eaten_not_at_home,
      other_food: moduleServices.module4Data.other_food,
      clothing: moduleServices.module4Data.clothing,
      entertainment: moduleServices.module4Data.entertainment,
      technology: moduleServices.module4Data.technology,
      transportation: moduleServices.module4Data.transportation,
      miscellaneous: moduleServices.module4Data.miscellaneous,
      medical_costs_before_deductible: moduleServices.module4Data.medical_costs_before_deductible,
      health_insurance_premium: moduleServices.module4Data.health_insurance_premium,
    },
    module5: {
      yearly_income: moduleServices.module5Data.yearly_income,
      bonus_or_tips_or_commission: moduleServices.module5Data.bonus_or_tips_or_commission,
      second_yearly_income: moduleServices.module5Data.second_yearly_income,
      rent: moduleServices.module5Data.rent,
      utilities: moduleServices.module5Data.utilities,
      food: moduleServices.module5Data.food,
      grocery_shopping: moduleServices.module5Data.grocery_shopping,
      meals_eaten_not_at_home: moduleServices.module5Data.meals_eaten_not_at_home,
      other_food: moduleServices.module5Data.other_food,
      clothing: moduleServices.module5Data.clothing,
      entertainment: moduleServices.module5Data.entertainment,
      technology: moduleServices.module5Data.technology,
      transportation: moduleServices.module5Data.transportation,
      miscellaneous: moduleServices.module5Data.miscellaneous,
      medical_costs_before_deductible: moduleServices.module5Data.medical_costs_before_deductible,
      health_insurance_premium: moduleServices.module5Data.health_insurance_premium,
    },
    module6: {
      yearly_income: moduleServices.module6Data.yearly_income,
      off_campus_food: moduleServices.module6Data.off_campus_food,
      clothing: moduleServices.module6Data.clothing,
      entertainment: moduleServices.module6Data.entertainment,
      technology: moduleServices.module6Data.technology,
      transportation: moduleServices.module6Data.transportation,
      miscellaneous: moduleServices.module6Data.miscellaneous,
      medical_costs_before_deductible: moduleServices.module6Data.medical_costs_before_deductible,
      health_insurance_premium: moduleServices.module6Data.health_insurance_premium,
      rent: moduleServices.module6Data.rent,
      utilities: moduleServices.module6Data.utilities,
      food: moduleServices.module6Data.food,
      second_clothing: moduleServices.module6Data.second_clothing,
      second_entertainment: moduleServices.module6Data.second_entertainment,
      second_technology: moduleServices.module6Data.second_technology,
      second_transportation: moduleServices.module6Data.second_transportation,
      second_miscellaneous: moduleServices.module6Data.second_miscellaneous,
      second_medical_costs_before_deductible: moduleServices.module6Data.second_medical_costs_before_deductible,
      second_health_insurance_premium: moduleServices.module6Data.second_health_insurance_premium,
    },
    module8: {
      yearly_income: moduleServices.module8Data.yearly_income,
      bonus_or_tips_or_commission: moduleServices.module8Data.bonus_or_tips_or_commission,
      second_yearly_income: moduleServices.module8Data.second_yearly_income,
      rent: moduleServices.module8Data.rent,
      utilities: moduleServices.module8Data.utilities,
      food: moduleServices.module8Data.food,
      grocery_shopping: moduleServices.module8Data.grocery_shopping,
      meals_eaten_not_at_home: moduleServices.module8Data.meals_eaten_not_at_home,
      other_food: moduleServices.module8Data.other_food,
      clothing: moduleServices.module8Data.clothing,
      entertainment: moduleServices.module8Data.entertainment,
      technology: moduleServices.module8Data.technology,
      transportation: moduleServices.module8Data.transportation,
      miscellaneous: moduleServices.module8Data.miscellaneous,
      medical_costs_before_deductible: moduleServices.module8Data.medical_costs_before_deductible,
      health_insurance_premium: moduleServices.module8Data.health_insurance_premium,
    },
    module10: {
      spouse_income: moduleServices.module10Data.spouse_income,
      spouse_bonus_or_tips_or_commission: moduleServices.module10Data.spouse_bonus_or_tips_or_commission,
      second_spouse_income: moduleServices.module10Data.second_spouse_income,
      second_spouse_bonus_or_tips_or_commission: moduleServices.module10Data.second_spouse_bonus_or_tips_or_commission,
    },
    module15: {
      yearly_income: moduleServices.module15Data.yearly_income,
      off_campus_food: moduleServices.module15Data.off_campus_food,
      clothing: moduleServices.module15Data.clothing,
      entertainment: moduleServices.module15Data.entertainment,
      technology: moduleServices.module15Data.technology,
      transportation: moduleServices.module15Data.transportation,
      miscellaneous: moduleServices.module15Data.miscellaneous,
      medical_costs_before_deductible: moduleServices.module15Data.medical_costs_before_deductible,
      health_insurance_premium: moduleServices.module15Data.health_insurance_premium,
      rent: moduleServices.module15Data.rent,
      utilities: moduleServices.module15Data.utilities,
      food: moduleServices.module15Data.food,
      second_clothing: moduleServices.module15Data.second_clothing,
      second_entertainment: moduleServices.module15Data.second_entertainment,
      second_technology: moduleServices.module15Data.second_technology,
      second_transportation: moduleServices.module15Data.second_transportation,
      second_miscellaneous: moduleServices.module15Data.second_miscellaneous,
      second_medical_costs_before_deductible: moduleServices.module15Data.second_medical_costs_before_deductible,
      second_health_insurance_premium: moduleServices.module15Data.second_health_insurance_premium,
    },
    module17: {
      yearly_income: moduleServices.module17Data.yearly_income,
      bonus_or_tips_or_commission: moduleServices.module17Data.bonus_or_tips_or_commission,
      second_yearly_income: moduleServices.module17Data.second_yearly_income,
      rent: moduleServices.module17Data.rent,
      utilities: moduleServices.module17Data.utilities,
      food: moduleServices.module17Data.food,
      grocery_shopping: moduleServices.module17Data.grocery_shopping,
      meals_eaten_not_at_home: moduleServices.module17Data.meals_eaten_not_at_home,
      other_food: moduleServices.module17Data.other_food,
      clothing: moduleServices.module17Data.clothing,
      entertainment: moduleServices.module17Data.entertainment,
      technology: moduleServices.module17Data.technology,
      transportation: moduleServices.module17Data.transportation,
      miscellaneous: moduleServices.module17Data.miscellaneous,
      medical_costs_before_deductible: moduleServices.module17Data.medical_costs_before_deductible,
      health_insurance_premium: moduleServices.module17Data.health_insurance_premium,
    },
  };
}
