import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

const Budget = ( props ) => {
  const { classes } = props;

  const collegeBudgetDefaultValues = {
    scholarships: 0,
    grants: 0,
    family_contribution: 0,
    income_after_taxes: 0,
    federal_loans: 0,
    private_loans: 0,
    personal_savings: 0,
    // Semister values
    scholarships_sem: 0,
    grants_sem: 0,
    family_contribution_sem: 0,
    income_after_taxes_sem: 0,
    federal_loans_sem: 0,
    private_loans_sem: 0,
    personal_savings_sem: 0,
    total_annual: 0,
    total_after_tax_income: 0,
    total_after_tax_income_sem: 0,

    // Second section
    tution_fees: 0,
    tution_fees_sem: 0,
    room_board: 0,
    room_board_sem: 0,
    books_supplies: 0,
    books_supplies_sem: 0,
    parking_pass: 0,
    parking_pass_sem: 0,
    rent: 0,
    rent_sem: 0,
    cable_internet: 0,
    cable_internet_sem: 0,
    electricity_water: 0,
    electricity_water_sem: 0,
    groceries: 0,
    groceries_sem: 0,
    takeout_food: 0,
    takeout_food_sem: 0,
    clothing: 0,
    clothing_sem: 0,
    toiletries: 0,
    toiletries_sem: 0,
    cleaning_supplies: 0,
    cleaning_supplies_sem: 0,
    laundry: 0,
    laundry_sem: 0,
    entertainment: 0,
    entertainment_sem: 0,
    gym_membership: 0,
    gym_membership_sem: 0,
    technology: 0,
    technology_sem: 0,
    miscellanneous: 0,
    miscellanneous_sem: 0,
    public_transportation: 0,
    public_transportation_sem: 0,
    gas: 0,
    gas_sem: 0,
    car_payment: 0,
    car_payment_sem: 0,
    car_insurance: 0,
    car_insurance_sem: 0,
    health_insurance: 0,
    health_insurance_sem: 0,
    other_healthcare: 0,
    other_healthcare_sem: 0,

    car_lease_payment: 0,
    car_insurance_maintenance: 0,
    home_expenses: 0,
    health_insurance_premium: 0,
    other_healthcare_costs: 0,
    pet: 0,
    miscellaneous: 0,
    credit_card_debt: 0,
    student_loans: 0,
    car_loans: 0,
    mortgages: 0,
    rent_utilities_percentage: 0,
    food_drink_percentage: 0,
    clothing_personal_percentage: 0,
    entertainment_tech_percentage: 0,
    transportation_gas_percentage: 0,
    car_lease_payment_percentage: 0,
    car_insurance_maintenance_percentage: 0,
    home_expenses_annual_percentage: 0,
    health_insurance_premium_percentage: 0,
    other_healthcare_costs_percentage: 0,
    pet_percentage: 0,
    miscellaneous_percentage: 0,
    credit_card_debt_percentage: 0,
    student_loans_percentage: 0,
    car_loans_percentage: 0,
    mortgages_percentage: 0,

    tution_fees_percentage: 0,
  };

  const [collegeBudgetValues, setCollegeBudgetValues] = useState( collegeBudgetDefaultValues );

  useEffect( () => {
    if ( typeof collegeBudgetValues.scholarships !== 'undefined' && collegeBudgetValues.scholarships !== '' ) {
      collegeBudgetValues.scholarships_annual = ( parseInt( collegeBudgetValues.scholarships, 10 ) * 12 ) + ( parseInt( collegeBudgetValues.scholarships_sem, 10 ) * 2 );
    }
    if ( typeof collegeBudgetValues.grants !== 'undefined' && collegeBudgetValues.grants !== '' ) {
      collegeBudgetValues.grants_annual = parseInt( collegeBudgetValues.grants, 10 ) * 12 + parseInt( collegeBudgetValues.grants_sem, 10 ) * 2;
    }
    if ( typeof collegeBudgetValues.family_contribution !== 'undefined' && collegeBudgetValues.family_contribution !== '' ) {
      collegeBudgetValues.family_contribution_annual = parseInt( collegeBudgetValues.family_contribution, 10 ) * 12 + parseInt( collegeBudgetValues.family_contribution_sem, 10 ) * 2;
    }
    if ( typeof collegeBudgetValues.income_after_taxes !== 'undefined' && collegeBudgetValues.income_after_taxes !== '' ) {
      collegeBudgetValues.income_after_taxes_annual = parseInt( collegeBudgetValues.income_after_taxes, 10 ) * 12 + parseInt( collegeBudgetValues.income_after_taxes_sem, 10 ) * 2;
    }
    if ( typeof collegeBudgetValues.federal_loans !== 'undefined' && collegeBudgetValues.federal_loans !== '' ) {
      collegeBudgetValues.federal_loans_annual = parseInt( collegeBudgetValues.federal_loans, 10 ) * 12 + parseInt( collegeBudgetValues.federal_loans_sem, 10 ) * 2;
    }
    if ( typeof collegeBudgetValues.private_loans !== 'undefined' && collegeBudgetValues.private_loans !== '' ) {
      collegeBudgetValues.private_loans_annual = parseInt( collegeBudgetValues.private_loans, 10 ) * 12 + parseInt( collegeBudgetValues.private_loans_sem, 10 ) * 2;
    }
    if ( typeof collegeBudgetValues.personal_savings !== 'undefined' && collegeBudgetValues.personal_savings !== '' ) {
      collegeBudgetValues.personal_savings_annual = parseInt( collegeBudgetValues.personal_savings, 10 ) * 12 + parseInt( collegeBudgetValues.personal_savings_sem, 10 ) * 2;
    }
    // Total Annual
    collegeBudgetValues.total_annual = collegeBudgetValues.scholarships_annual + collegeBudgetValues.grants_annual + collegeBudgetValues.family_contribution_annual + collegeBudgetValues.income_after_taxes_annual + collegeBudgetValues.federal_loans_annual + collegeBudgetValues.private_loans_annual + collegeBudgetValues.personal_savings_annual;
    // Total Cash In Month
    if ( typeof collegeBudgetValues.scholarships !== 'undefined' && typeof collegeBudgetValues.grants !== 'undefined' && typeof collegeBudgetValues.family_contribution !== 'undefined' && typeof collegeBudgetValues.income_after_taxes !== 'undefined' && typeof collegeBudgetValues.federal_loans !== 'undefined' && typeof collegeBudgetValues.private_loans !== 'undefined' && typeof collegeBudgetValues.personal_savings !== 'undefined' ) {
      collegeBudgetValues.total_after_tax_income = parseInt( collegeBudgetValues.scholarships, 10 )
        + parseInt( collegeBudgetValues.grants, 10 )
        + parseInt( collegeBudgetValues.family_contribution, 10 )
        + parseInt( collegeBudgetValues.income_after_taxes, 10 )
        + parseInt( collegeBudgetValues.federal_loans, 10 )
        + parseInt( collegeBudgetValues.private_loans, 10 )
        + parseInt( collegeBudgetValues.personal_savings, 10 );
      collegeBudgetValues.total_after_tax_income_annual = ( collegeBudgetValues.total_after_tax_income ) * 12;
    } else {
      collegeBudgetValues.total_after_tax_income_annual = 0;
    }
    // Total Cash In Semister
    if ( typeof collegeBudgetValues.scholarships_sem !== 'undefined' && typeof collegeBudgetValues.grants_sem !== 'undefined' && typeof collegeBudgetValues.family_contribution_sem !== 'undefined' && typeof collegeBudgetValues.income_after_taxes_sem !== 'undefined' && typeof collegeBudgetValues.federal_loans_sem !== 'undefined' && typeof collegeBudgetValues.private_loans_sem !== 'undefined' && typeof collegeBudgetValues.personal_savings_sem !== 'undefined' ) {
      collegeBudgetValues.total_after_tax_income_sem = parseInt( collegeBudgetValues.scholarships_sem, 10 )
        + parseInt( collegeBudgetValues.grants_sem, 10 )
        + parseInt( collegeBudgetValues.family_contribution_sem, 10 )
        + parseInt( collegeBudgetValues.income_after_taxes_sem, 10 )
        + parseInt( collegeBudgetValues.federal_loans_sem, 10 )
        + parseInt( collegeBudgetValues.private_loans_sem, 10 )
        + parseInt( collegeBudgetValues.personal_savings_sem, 10 );
      collegeBudgetValues.total_after_tax_income_annual = ( collegeBudgetValues.total_after_tax_income ) * 12;
    } else {
      collegeBudgetValues.total_after_tax_income_annual = 0;
    }

    // Second section
    if ( typeof collegeBudgetValues.tution_fees !== 'undefined' && collegeBudgetValues.tution_fees !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.tution_fees_annual = parseInt( collegeBudgetValues.tution_fees, 10 ) * 12 + parseInt( collegeBudgetValues.tution_fees_sem, 10 ) * 2;
      if ( collegeBudgetValues.tution_fees_annual ) {
        collegeBudgetValues.tution_fees_percentage = ( collegeBudgetValues.tution_fees_annual / collegeBudgetValues.total_annual ) * 100;
      }
    } else {
      collegeBudgetValues.tution_fees_annual = 0;
      collegeBudgetValues.tution_fees_percentage = 0;
    }
    if ( typeof collegeBudgetValues.room_board !== 'undefined' && collegeBudgetValues.room_board !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.room_board_annual = parseInt( collegeBudgetValues.room_board, 10 ) * 12 + parseInt( collegeBudgetValues.room_board_sem, 10 ) * 2;
      collegeBudgetValues.room_board_percentage = ( collegeBudgetValues.room_board_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.room_board_annual = 0;
      collegeBudgetValues.room_board_percentage = 0;
    }
    if ( typeof collegeBudgetValues.books_supplies !== 'undefined' && collegeBudgetValues.books_supplies !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.books_supplies_annual = parseInt( collegeBudgetValues.books_supplies, 10 ) * 12 + parseInt( collegeBudgetValues.books_supplies_sem, 10 ) * 2;
      collegeBudgetValues.books_supplies_percentage = ( collegeBudgetValues.books_supplies_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.books_supplies_annual = 0;
      collegeBudgetValues.books_supplies_percentage = 0;
    }
    if ( typeof collegeBudgetValues.parking_pass !== 'undefined' && collegeBudgetValues.parking_pass !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.parking_pass_annual = parseInt( collegeBudgetValues.parking_pass, 10 ) * 12 + parseInt( collegeBudgetValues.parking_pass_sem, 10 ) * 2;
      collegeBudgetValues.parking_pass_percentage = ( collegeBudgetValues.parking_pass_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.parking_pass_annual = 0;
      collegeBudgetValues.parking_pass_percentage = 0;
    }
    if ( typeof collegeBudgetValues.rent !== 'undefined' && collegeBudgetValues.rent !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.rent_annual = parseInt( collegeBudgetValues.rent, 10 ) * 12 + parseInt( collegeBudgetValues.rent_sem, 10 ) * 2;
      collegeBudgetValues.rent_percentage = ( collegeBudgetValues.rent_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.rent_annual = 0;
      collegeBudgetValues.rent_percentage = 0;
    }
    if ( typeof collegeBudgetValues.cable_internet !== 'undefined' && collegeBudgetValues.cable_internet !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.cable_internet_annual = parseInt( collegeBudgetValues.cable_internet, 10 ) * 12 + parseInt( collegeBudgetValues.cable_internet_sem, 10 ) * 2;
      collegeBudgetValues.cable_internet_percentage = ( collegeBudgetValues.cable_internet_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.cable_internet_annual = 0;
      collegeBudgetValues.cable_internet_percentage = 0;
    }
    if ( typeof collegeBudgetValues.electricity_water !== 'undefined' && collegeBudgetValues.electricity_water !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.electricity_water_annual = parseInt( collegeBudgetValues.electricity_water, 10 ) * 12 + parseInt( collegeBudgetValues.electricity_water_sem, 10 ) * 2;
      collegeBudgetValues.electricity_water_percentage = ( collegeBudgetValues.electricity_water_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.electricity_water_annual = 0;
      collegeBudgetValues.electricity_water_percentage = 0;
    }
    if ( typeof collegeBudgetValues.groceries !== 'undefined' && collegeBudgetValues.groceries !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.groceries_annual = parseInt( collegeBudgetValues.groceries, 10 ) * 12 + parseInt( collegeBudgetValues.groceries_sem, 10 ) * 2;
      collegeBudgetValues.groceries_percentage = ( collegeBudgetValues.groceries_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.groceries_annual = 0;
      collegeBudgetValues.groceries_percentage = 0;
    }
    if ( typeof collegeBudgetValues.takeout_food !== 'undefined' && collegeBudgetValues.takeout_food !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.takeout_food_annual = parseInt( collegeBudgetValues.takeout_food, 10 ) * 12 + parseInt( collegeBudgetValues.takeout_food_sem, 10 ) * 2;
      collegeBudgetValues.takeout_food_percentage = ( collegeBudgetValues.takeout_food_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.takeout_food_annual = 0;
      collegeBudgetValues.takeout_food_percentage = 0;
    }
    if ( typeof collegeBudgetValues.clothing !== 'undefined' && collegeBudgetValues.clothing !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.clothing_annual = parseInt( collegeBudgetValues.clothing, 10 ) * 12 + parseInt( collegeBudgetValues.clothing_sem, 10 ) * 2;
      collegeBudgetValues.clothing_percentage = ( collegeBudgetValues.clothing_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.clothing_annual = 0;
      collegeBudgetValues.clothing_percentage = 0;
    }
    if ( typeof collegeBudgetValues.toiletries !== 'undefined' && collegeBudgetValues.toiletries !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.toiletries_annual = parseInt( collegeBudgetValues.toiletries, 10 ) * 12 + parseInt( collegeBudgetValues.toiletries_sem, 10 ) * 2;
      collegeBudgetValues.toiletries_percentage = ( collegeBudgetValues.toiletries_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.toiletries_annual = 0;
      collegeBudgetValues.toiletries_percentage = 0;
    }
    if ( typeof collegeBudgetValues.cleaning_supplies !== 'undefined' && collegeBudgetValues.cleaning_supplies !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.cleaning_supplies_annual = parseInt( collegeBudgetValues.cleaning_supplies, 10 ) * 12 + parseInt( collegeBudgetValues.cleaning_supplies_sem, 10 ) * 2;
      collegeBudgetValues.cleaning_supplies_percentage = ( collegeBudgetValues.cleaning_supplies_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.cleaning_supplies_annual = 0;
      collegeBudgetValues.cleaning_supplies_percentage = 0;
    }
    if ( typeof collegeBudgetValues.laundry !== 'undefined' && collegeBudgetValues.laundry !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.laundry_annual = parseInt( collegeBudgetValues.laundry, 10 ) * 12 + parseInt( collegeBudgetValues.laundry_sem, 10 ) * 2;
      collegeBudgetValues.laundry_percentage = ( collegeBudgetValues.laundry_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.laundry_annual = 0;
      collegeBudgetValues.laundry_percentage = 0;
    }
    if ( typeof collegeBudgetValues.entertainment !== 'undefined' && collegeBudgetValues.entertainment !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.entertainment_annual = parseInt( collegeBudgetValues.entertainment, 10 ) * 12 + parseInt( collegeBudgetValues.entertainment_sem, 10 ) * 2;
      collegeBudgetValues.entertainment_percentage = ( collegeBudgetValues.entertainment_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.entertainment_annual = 0;
      collegeBudgetValues.entertainment_percentage = 0;
    }
    if ( typeof collegeBudgetValues.gym_membership !== 'undefined' && collegeBudgetValues.gym_membership !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.gym_membership_annual = parseInt( collegeBudgetValues.gym_membership, 10 ) * 12 + parseInt( collegeBudgetValues.gym_membership_sem, 10 ) * 2;
      collegeBudgetValues.gym_membership_percentage = ( collegeBudgetValues.gym_membership_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.gym_membership_annual = 0;
      collegeBudgetValues.gym_membership_percentage = 0;
    }
    if ( typeof collegeBudgetValues.technology !== 'undefined' && collegeBudgetValues.technology !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.technology_annual = parseInt( collegeBudgetValues.technology, 10 ) * 12 + parseInt( collegeBudgetValues.technology_sem, 10 ) * 2;
      collegeBudgetValues.technology_percentage = ( collegeBudgetValues.technology_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.technology_annual = 0;
      collegeBudgetValues.technology_percentage = 0;
    }
    if ( typeof collegeBudgetValues.miscellanneous !== 'undefined' && collegeBudgetValues.miscellanneous !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.miscellanneous_annual = parseInt( collegeBudgetValues.miscellanneous, 10 ) * 12 + parseInt( collegeBudgetValues.miscellanneous_sem, 10 ) * 2;
      collegeBudgetValues.miscellanneous_percentage = ( collegeBudgetValues.miscellanneous_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.miscellanneous_annual = 0;
      collegeBudgetValues.miscellanneous_percentage = 0;
    }
    if ( typeof collegeBudgetValues.public_transportation !== 'undefined' && collegeBudgetValues.public_transportation !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.public_transportation_annual = parseInt( collegeBudgetValues.public_transportation, 10 ) * 12 + parseInt( collegeBudgetValues.public_transportation_sem, 10 ) * 2;
      collegeBudgetValues.public_transportation_percentage = ( collegeBudgetValues.public_transportation_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.public_transportation_annual = 0;
      collegeBudgetValues.public_transportation_percentage = 0;
    }
    if ( typeof collegeBudgetValues.gas !== 'undefined' && collegeBudgetValues.gas !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.gas_annual = parseInt( collegeBudgetValues.gas, 10 ) * 12 + parseInt( collegeBudgetValues.gas_sem, 10 ) * 2;
      collegeBudgetValues.gas_percentage = ( collegeBudgetValues.gas_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.gas_annual = 0;
      collegeBudgetValues.gas_percentage = 0;
    }
    if ( typeof collegeBudgetValues.car_payment !== 'undefined' && collegeBudgetValues.car_payment !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.car_payment_annual = parseInt( collegeBudgetValues.car_payment, 10 ) * 12 + parseInt( collegeBudgetValues.car_payment_sem, 10 ) * 2;
      collegeBudgetValues.car_payment_percentage = ( collegeBudgetValues.car_payment_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.car_payment_annual = 0;
      collegeBudgetValues.car_payment_percentage = 0;
    }
    if ( typeof collegeBudgetValues.car_insurance !== 'undefined' && collegeBudgetValues.car_insurance !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.car_insurance_annual = parseInt( collegeBudgetValues.car_insurance, 10 ) * 12 + parseInt( collegeBudgetValues.car_insurance_sem, 10 ) * 2;
      collegeBudgetValues.car_insurance_percentage = ( collegeBudgetValues.car_insurance_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.car_insurance_annual = 0;
      collegeBudgetValues.car_insurance_percentage = 0;
    }
    if ( typeof collegeBudgetValues.health_insurance !== 'undefined' && collegeBudgetValues.health_insurance !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.health_insurance_annual = parseInt( collegeBudgetValues.health_insurance, 10 ) * 12 + parseInt( collegeBudgetValues.health_insurance_sem, 10 ) * 2;
      collegeBudgetValues.health_insurance_percentage = ( collegeBudgetValues.health_insurance_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.health_insurance_annual = 0;
      collegeBudgetValues.health_insurance_percentage = 0;
    }
    if ( typeof collegeBudgetValues.other_healthcare !== 'undefined' && collegeBudgetValues.other_healthcare !== '' && collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.other_healthcare_annual = parseInt( collegeBudgetValues.other_healthcare, 10 ) * 12 + parseInt( collegeBudgetValues.other_healthcare_sem, 10 ) * 2;
      collegeBudgetValues.other_healthcare_percentage = ( collegeBudgetValues.other_healthcare_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.other_healthcare_annual = 0;
      collegeBudgetValues.other_healthcare_percentage = 0;
    }
    collegeBudgetValues.total_cashout_month = parseInt( collegeBudgetValues.tution_fees, 10 )
      + parseInt( collegeBudgetValues.room_board, 10 )
      + parseInt( collegeBudgetValues.books_supplies, 10 )
      + parseInt( collegeBudgetValues.parking_pass, 10 )
      + parseInt( collegeBudgetValues.rent, 10 )
      + parseInt( collegeBudgetValues.cable_internet, 10 )
      + parseInt( collegeBudgetValues.electricity_water, 10 )
      + parseInt( collegeBudgetValues.groceries, 10 )
      + parseInt( collegeBudgetValues.takeout_food, 10 )
      + parseInt( collegeBudgetValues.clothing, 10 )
      + parseInt( collegeBudgetValues.toiletries, 10 )
      + parseInt( collegeBudgetValues.cleaning_supplies, 10 )
      + parseInt( collegeBudgetValues.laundry, 10 )
      + parseInt( collegeBudgetValues.entertainment, 10 )
      + parseInt( collegeBudgetValues.gym_membership, 10 )
      + parseInt( collegeBudgetValues.technology, 10 )
      + parseInt( collegeBudgetValues.miscellanneous, 10 )
      + parseInt( collegeBudgetValues.public_transportation, 10 )
      + parseInt( collegeBudgetValues.gas, 10 )
      + parseInt( collegeBudgetValues.car_payment, 10 )
      + parseInt( collegeBudgetValues.car_insurance, 10 )
      + parseInt( collegeBudgetValues.health_insurance, 10 )
      + parseInt( collegeBudgetValues.other_healthcare, 10 );
    collegeBudgetValues.total_cashout_sem = parseInt( collegeBudgetValues.tution_fees_sem, 10 )
      + parseInt( collegeBudgetValues.room_board_sem, 10 )
      + parseInt( collegeBudgetValues.books_supplies_sem, 10 )
      + parseInt( collegeBudgetValues.parking_pass_sem, 10 )
      + parseInt( collegeBudgetValues.rent_sem, 10 )
      + parseInt( collegeBudgetValues.cable_internet_sem, 10 )
      + parseInt( collegeBudgetValues.electricity_water_sem, 10 )
      + parseInt( collegeBudgetValues.groceries_sem, 10 )
      + parseInt( collegeBudgetValues.takeout_food_sem, 10 )
      + parseInt( collegeBudgetValues.clothing_sem, 10 )
      + parseInt( collegeBudgetValues.toiletries_sem, 10 )
      + parseInt( collegeBudgetValues.cleaning_supplies_sem, 10 )
      + parseInt( collegeBudgetValues.laundry_sem, 10 )
      + parseInt( collegeBudgetValues.entertainment_sem, 10 )
      + parseInt( collegeBudgetValues.gym_membership_sem, 10 )
      + parseInt( collegeBudgetValues.technology_sem, 10 )
      + parseInt( collegeBudgetValues.miscellanneous_sem, 10 )
      + parseInt( collegeBudgetValues.public_transportation_sem, 10 )
      + parseInt( collegeBudgetValues.gas_sem, 10 )
      + parseInt( collegeBudgetValues.car_payment_sem, 10 )
      + parseInt( collegeBudgetValues.car_insurance_sem, 10 )
      + parseInt( collegeBudgetValues.health_insurance_sem, 10 )
      + parseInt( collegeBudgetValues.other_healthcare_sem, 10 );
    collegeBudgetValues.total_cashout_annual = collegeBudgetValues.tution_fees_annual
      + collegeBudgetValues.room_board_annual
      + collegeBudgetValues.books_supplies_annual
      + collegeBudgetValues.parking_pass_annual
      + collegeBudgetValues.rent_annual
      + collegeBudgetValues.cable_internet_annual
      + collegeBudgetValues.electricity_water_annual
      + collegeBudgetValues.groceries_annual
      + collegeBudgetValues.takeout_food_annual
      + collegeBudgetValues.clothing_annual
      + collegeBudgetValues.toiletries_annual
      + collegeBudgetValues.cleaning_supplies_annual
      + collegeBudgetValues.laundry_annual
      + collegeBudgetValues.entertainment_annual
      + collegeBudgetValues.gym_membership_annual
      + collegeBudgetValues.technology_annual
      + collegeBudgetValues.miscellanneous_annual
      + collegeBudgetValues.public_transportation_annual
      + collegeBudgetValues.gas_annual
      + collegeBudgetValues.car_payment_annual
      + collegeBudgetValues.car_insurance_annual
      + collegeBudgetValues.health_insurance_annual
      + collegeBudgetValues.other_healthcare_annual;
    collegeBudgetValues.total_cashout_percentage = collegeBudgetValues.tution_fees_percentage
      + collegeBudgetValues.room_board_percentage
      + collegeBudgetValues.books_supplies_percentage
      + collegeBudgetValues.parking_pass_percentage
      + collegeBudgetValues.rent_percentage
      + collegeBudgetValues.cable_internet_percentage
      + collegeBudgetValues.electricity_water_percentage
      + collegeBudgetValues.groceries_percentage
      + collegeBudgetValues.takeout_food_percentage
      + collegeBudgetValues.clothing_percentage
      + collegeBudgetValues.toiletries_percentage
      + collegeBudgetValues.cleaning_supplies_percentage
      + collegeBudgetValues.laundry_percentage
      + collegeBudgetValues.entertainment_percentage
      + collegeBudgetValues.gym_membership_percentage
      + collegeBudgetValues.technology_percentage
      + collegeBudgetValues.miscellanneous_percentage
      + collegeBudgetValues.public_transportation_percentage
      + collegeBudgetValues.gas_percentage
      + collegeBudgetValues.car_payment_percentage
      + collegeBudgetValues.car_insurance_percentage
      + collegeBudgetValues.health_insurance_percentage
      + collegeBudgetValues.other_healthcare_percentage;

    // Cash Left Over for Savings Calculations
    collegeBudgetValues.cash_left_over_month = collegeBudgetValues.total_after_tax_income - collegeBudgetValues.total_cashout_month;
    collegeBudgetValues.cash_left_over_sem = collegeBudgetValues.total_after_tax_income_sem - collegeBudgetValues.total_cashout_sem;
    collegeBudgetValues.cash_left_over_annual = collegeBudgetValues.total_annual - collegeBudgetValues.total_cashout_annual;

    if ( collegeBudgetValues.total_annual !== '' ) {
      collegeBudgetValues.cash_left_over_percentage = ( collegeBudgetValues.cash_left_over_annual / collegeBudgetValues.total_annual ) * 100;
    } else {
      collegeBudgetValues.cash_left_over_percentage = 0;
    }

    if ( typeof collegeBudgetValues.rent_utilities !== 'undefined' && collegeBudgetValues.rent_utilities !== '' ) {
      collegeBudgetValues.rent_utilities_annual = parseInt( collegeBudgetValues.rent_utilities, 10 ) * 12;
      if ( collegeBudgetValues.rent_utilities_annual ) {
        collegeBudgetValues.rent_utilities_percentage = ( collegeBudgetValues.rent_utilities_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.food_drink !== 'undefined' && collegeBudgetValues.food_drink !== '' ) {
      collegeBudgetValues.food_drink_annual = parseInt( collegeBudgetValues.food_drink, 10 ) * 12;
      if ( collegeBudgetValues.food_drink_annual ) {
        collegeBudgetValues.food_drink_percentage = ( collegeBudgetValues.food_drink_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.clothing_personal !== 'undefined' && collegeBudgetValues.clothing_personal !== '' ) {
      collegeBudgetValues.clothing_personal_annual = parseInt( collegeBudgetValues.clothing_personal, 10 ) * 12;
      if ( collegeBudgetValues.clothing_personal_annual ) {
        collegeBudgetValues.clothing_personal_percentage = ( collegeBudgetValues.clothing_personal_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.entertainment_tech !== 'undefined' && collegeBudgetValues.entertainment_tech !== '' ) {
      collegeBudgetValues.entertainment_tech_annual = parseInt( collegeBudgetValues.entertainment_tech, 10 ) * 12;
      if ( collegeBudgetValues.entertainment_tech_annual ) {
        collegeBudgetValues.entertainment_tech_percentage = ( collegeBudgetValues.entertainment_tech_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.transportation_gas !== 'undefined' && collegeBudgetValues.transportation_gas !== '' ) {
      collegeBudgetValues.transportation_gas_annual = parseInt( collegeBudgetValues.transportation_gas, 10 ) * 12;
      if ( collegeBudgetValues.transportation_gas_annual ) {
        collegeBudgetValues.transportation_gas_percentage = ( collegeBudgetValues.transportation_gas_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.car_lease_payment !== 'undefined' && collegeBudgetValues.car_lease_payment !== '' ) {
      collegeBudgetValues.car_lease_payment_annual = parseInt( collegeBudgetValues.car_lease_payment, 10 ) * 12;
      if ( collegeBudgetValues.car_lease_payment_annual ) {
        collegeBudgetValues.car_lease_payment_percentage = ( collegeBudgetValues.car_lease_payment_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.car_insurance_maintenance !== 'undefined' && collegeBudgetValues.car_insurance_maintenance !== '' ) {
      collegeBudgetValues.car_insurance_maintenance_annual = parseInt( collegeBudgetValues.car_insurance_maintenance, 10 ) * 12;
      if ( collegeBudgetValues.car_insurance_maintenance_annual ) {
        collegeBudgetValues.car_insurance_maintenance_percentage = ( collegeBudgetValues.car_insurance_maintenance_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.home_expenses !== 'undefined' && collegeBudgetValues.home_expenses !== '' ) {
      collegeBudgetValues.home_expenses_annual = parseInt( collegeBudgetValues.home_expenses, 10 ) * 12;
      if ( collegeBudgetValues.home_expenses_annual ) {
        collegeBudgetValues.home_expenses_annual_percentage = ( collegeBudgetValues.home_expenses_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.health_insurance_premium !== 'undefined' && collegeBudgetValues.health_insurance_premium !== '' ) {
      collegeBudgetValues.health_insurance_premium_annual = parseInt( collegeBudgetValues.health_insurance_premium, 10 ) * 12;
      if ( collegeBudgetValues.health_insurance_premium_annual ) {
        collegeBudgetValues.health_insurance_premium_percentage = ( collegeBudgetValues.health_insurance_premium_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.other_healthcare_costs !== 'undefined' && collegeBudgetValues.other_healthcare_costs !== '' ) {
      collegeBudgetValues.other_healthcare_costs_annual = parseInt( collegeBudgetValues.other_healthcare_costs, 10 ) * 12;
      if ( collegeBudgetValues.other_healthcare_costs_annual ) {
        collegeBudgetValues.other_healthcare_costs_percentage = ( collegeBudgetValues.other_healthcare_costs_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.pet !== 'undefined' && collegeBudgetValues.pet !== '' ) {
      collegeBudgetValues.pet_annual = parseInt( collegeBudgetValues.pet, 10 ) * 12;
      if ( collegeBudgetValues.pet_annual ) {
        collegeBudgetValues.pet_percentage = ( collegeBudgetValues.pet_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.miscellaneous !== 'undefined' && collegeBudgetValues.miscellaneous !== '' ) {
      collegeBudgetValues.miscellaneous_annual = parseInt( collegeBudgetValues.miscellaneous, 10 ) * 12;
      if ( collegeBudgetValues.miscellaneous_annual ) {
        collegeBudgetValues.miscellaneous_percentage = ( collegeBudgetValues.miscellaneous_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }

    if ( typeof collegeBudgetValues.rent_utilities !== 'undefined'
      && typeof collegeBudgetValues.food_drink !== 'undefined'
      && typeof collegeBudgetValues.clothing_personal !== 'undefined'
      && typeof collegeBudgetValues.entertainment_tech !== 'undefined'
      && typeof collegeBudgetValues.transportation_gas !== 'undefined'
      && typeof collegeBudgetValues.car_lease_payment !== 'undefined'
      && typeof collegeBudgetValues.car_insurance_maintenance !== 'undefined'
      && typeof collegeBudgetValues.home_expenses !== 'undefined'
      && typeof collegeBudgetValues.health_insurance_premium !== 'undefined'
      && typeof collegeBudgetValues.other_healthcare_costs !== 'undefined'
      && typeof collegeBudgetValues.pet !== 'undefined'
      && typeof collegeBudgetValues.miscellaneous !== 'undefined' ) {
      collegeBudgetValues.total_living_expenses = parseInt( collegeBudgetValues.rent_utilities, 10 )
        + parseInt( collegeBudgetValues.food_drink, 10 )
        + parseInt( collegeBudgetValues.clothing_personal, 10 )
        + parseInt( collegeBudgetValues.entertainment_tech, 10 )
        + parseInt( collegeBudgetValues.transportation_gas, 10 )
        + parseInt( collegeBudgetValues.car_lease_payment, 10 )
        + parseInt( collegeBudgetValues.car_insurance_maintenance, 10 )
        + parseInt( collegeBudgetValues.home_expenses, 10 )
        + parseInt( collegeBudgetValues.health_insurance_premium, 10 )
        + parseInt( collegeBudgetValues.other_healthcare_costs, 10 )
        + parseInt( collegeBudgetValues.pet, 10 )
        + parseInt( collegeBudgetValues.miscellaneous, 10 );
      collegeBudgetValues.total_living_expenses_annual = collegeBudgetValues.total_living_expenses * 12;
    }

    // After tax Income
    if ( typeof collegeBudgetValues.total_after_tax_income !== 'undefined' && typeof collegeBudgetValues.total_living_expenses !== 'undefined' && collegeBudgetValues.total_after_tax_income > 0 ) {
      collegeBudgetValues.after_tax_income = ( collegeBudgetValues.total_living_expenses / collegeBudgetValues.total_after_tax_income ) * 100;
    } else {
      collegeBudgetValues.after_tax_income = 0;
    }
    if ( typeof collegeBudgetValues.total_after_tax_income_annual !== 'undefined' && typeof collegeBudgetValues.total_living_expenses_annual !== 'undefined' && collegeBudgetValues.total_after_tax_income_annual > 0 ) {
      collegeBudgetValues.after_tax_income_annual = ( collegeBudgetValues.total_living_expenses_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
    } else {
      collegeBudgetValues.after_tax_income_annual = 0;
    }

    // Loan Payments
    if ( typeof collegeBudgetValues.credit_card_debt !== 'undefined' && collegeBudgetValues.credit_card_debt !== '' ) {
      collegeBudgetValues.credit_card_debt_annual = parseInt( collegeBudgetValues.credit_card_debt, 10 ) * 12;
      if ( collegeBudgetValues.credit_card_debt_annual ) {
        collegeBudgetValues.credit_card_debt_percentage = ( collegeBudgetValues.credit_card_debt_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.student_loans !== 'undefined' && collegeBudgetValues.student_loans !== '' ) {
      collegeBudgetValues.student_loans_annual = parseInt( collegeBudgetValues.student_loans, 10 ) * 12;
      if ( collegeBudgetValues.student_loans_annual ) {
        collegeBudgetValues.student_loans_percentage = ( collegeBudgetValues.student_loans_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.car_loans !== 'undefined' && collegeBudgetValues.car_loans !== '' ) {
      collegeBudgetValues.car_loans_annual = parseInt( collegeBudgetValues.car_loans, 10 ) * 12;
      if ( collegeBudgetValues.car_loans_annual ) {
        collegeBudgetValues.car_loans_percentage = ( collegeBudgetValues.car_loans_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof collegeBudgetValues.mortgages !== 'undefined' && collegeBudgetValues.mortgages !== '' ) {
      collegeBudgetValues.mortgages_annual = parseInt( collegeBudgetValues.mortgages, 10 ) * 12;
      if ( collegeBudgetValues.mortgages_annual ) {
        collegeBudgetValues.mortgages_percentage = ( collegeBudgetValues.mortgages_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
      }
    }

    // After tax Income
    // Total Loan Payments
    if ( typeof collegeBudgetValues.credit_card_debt !== 'undefined'
      && typeof collegeBudgetValues.student_loans !== 'undefined'
      && typeof collegeBudgetValues.car_loans !== 'undefined'
      && typeof collegeBudgetValues.mortgages !== 'undefined' ) {
      collegeBudgetValues.total_loan_payments = parseInt( collegeBudgetValues.credit_card_debt, 10 )
        + parseInt( collegeBudgetValues.student_loans, 10 )
        + parseInt( collegeBudgetValues.car_loans, 10 )
        + parseInt( collegeBudgetValues.mortgages, 10 );
      collegeBudgetValues.total_loan_payments_annual = collegeBudgetValues.total_loan_payments * 12;
    }

    if ( typeof collegeBudgetValues.total_loan_payments !== 'undefined' && typeof collegeBudgetValues.total_after_tax_income !== 'undefined' && collegeBudgetValues.total_after_tax_income > 0 ) {
      collegeBudgetValues.after_tax_income_loans = ( collegeBudgetValues.total_loan_payments / collegeBudgetValues.total_after_tax_income ) * 100;
    } else {
      collegeBudgetValues.after_tax_income_loans = 0;
    }
    if ( typeof collegeBudgetValues.total_loan_payments_annual !== 'undefined' && typeof collegeBudgetValues.total_after_tax_income_annual !== 'undefined' && collegeBudgetValues.total_after_tax_income_annual > 0 ) {
      collegeBudgetValues.after_tax_income_loans_annual = ( collegeBudgetValues.total_loan_payments_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
    } else {
      collegeBudgetValues.after_tax_income_loans_annual = 0;
    }

    // Total Expenses
    if ( typeof collegeBudgetValues.total_loan_payments !== 'undefined' && typeof collegeBudgetValues.total_living_expenses !== 'undefined' ) {
      collegeBudgetValues.total_expenses = collegeBudgetValues.total_loan_payments + collegeBudgetValues.total_living_expenses;
    }
    if ( typeof collegeBudgetValues.total_loan_payments_annual !== 'undefined' && typeof collegeBudgetValues.total_living_expenses_annual !== 'undefined' ) {
      collegeBudgetValues.total_expenses_annual = collegeBudgetValues.total_loan_payments_annual + collegeBudgetValues.total_living_expenses_annual;
    }

    // Total Expenses - % of After-Tax Income
    if ( typeof collegeBudgetValues.total_expenses !== 'undefined' && typeof collegeBudgetValues.total_after_tax_income !== 'undefined' && collegeBudgetValues.total_after_tax_income > 0 ) {
      collegeBudgetValues.after_tax_expenses_loans = ( collegeBudgetValues.total_expenses / collegeBudgetValues.total_after_tax_income ) * 100;
    } else {
      collegeBudgetValues.after_tax_expenses_loans = 0;
    }
    if ( typeof collegeBudgetValues.total_expenses_annual !== 'undefined' && typeof collegeBudgetValues.total_after_tax_income_annual !== 'undefined' && collegeBudgetValues.total_after_tax_income_annual > 0 ) {
      collegeBudgetValues.after_tax_expenses_loans_annual = ( collegeBudgetValues.total_expenses_annual / collegeBudgetValues.total_after_tax_income_annual ) * 100;
    } else {
      collegeBudgetValues.after_tax_expenses_loans_annual = 0;
    }

    // Cash Left Over for Savings / Invest
    if ( typeof collegeBudgetValues.total_after_tax_income !== 'undefined' && typeof collegeBudgetValues.total_expenses !== 'undefined' ) {
      collegeBudgetValues.cash_left_over_savings = collegeBudgetValues.total_after_tax_income - collegeBudgetValues.total_expenses;
    }
    if ( typeof collegeBudgetValues.total_after_tax_income_annual !== 'undefined' && typeof collegeBudgetValues.total_expenses_annual !== 'undefined' ) {
      collegeBudgetValues.cash_left_over_savings_annual = collegeBudgetValues.total_after_tax_income_annual - collegeBudgetValues.total_expenses_annual;
    }

    // To get the Savings Growth Graph
    // $timeout(function () {
    /// /$scope.getSavingGrowthGraph();
    // });
    // $scope.savingsGrowthCalcs = {};
    // $scope.savingsGrowthCalcs.monthlySavings = collegeBudgetValues.cash_left_over_savings;
    // $scope.savingsGrowthCalcs.monthlySavings = $filter('currency')($scope.savingsGrowthCalcs.monthlySavings, '', 0);

    //     $scope.savingsGrowthCalcs.annualReturn = 5;//100 - collegeBudgetValues.after_tax_expenses_loans_annual;
    // setCalculatedValues( collegeBudgetValues );
  }, [collegeBudgetValues] );

  const updateValue = ( e, field ) => {
    const valuesUpdated = { ...collegeBudgetValues };
    valuesUpdated[field] = e.value ? e.floatValue : 0;
    setCollegeBudgetValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>College Budget</li>
            </ul>
            <div className={ classes.powerContent }>
              <div className={ classes.mainRow }>
                <div className={ classes.powerRight }>
                  <div className={ classes.wonderRight }>
                    <div className={ classes.contentWon }>
                      <div className={ classes.budgetTable }>
                        <table cellPadding="20" cellSpacing="20">
                          <thead>
                            <tr>
                              <th className={ classes.lightText }><em>My College Budget</em></th>
                              <th><u>Per Semester</u></th>
                              <th><u>Per Month</u></th>
                              <th><u>Annual</u></th>
                              <th>&nbsp;</th>
                            </tr>
                            <tr className={ classes.cashInRow }>
                              <th className={ classes.cashInRow }><u>Cash In</u></th>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Scholarships</td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.scholarships_sem } onValueChange={ ( e ) => updateValue( e, 'scholarships_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td className={ classes.textBlackInput }>
                                 <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.scholarships } onValueChange={ ( e ) => updateValue( e, 'scholarships' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.scholarships_annual !== undefined ? collegeBudgetValues.scholarships_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Grants</td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.grants_sem } onValueChange={ ( e ) => updateValue( e, 'grants_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td className={ classes.textBlackInput }>                                
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                 <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.grants } onValueChange={ ( e ) => updateValue( e, 'grants' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.grants_annual !== undefined ? collegeBudgetValues.grants_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Family Contribution</td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.family_contribution_sem } onValueChange={ ( e ) => updateValue( e, 'family_contribution_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.family_contribution } onValueChange={ ( e ) => updateValue( e, 'family_contribution' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.family_contribution_annual !== undefined ? collegeBudgetValues.family_contribution_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Income after Taxes</td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.income_after_taxes_sem } onValueChange={ ( e ) => updateValue( e, 'income_after_taxes_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.income_after_taxes } onValueChange={ ( e ) => updateValue( e, 'income_after_taxes' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.income_after_taxes_annual !== undefined ? collegeBudgetValues.income_after_taxes_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Federal Loans</td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.federal_loans_sem } onValueChange={ ( e ) => updateValue( e, 'federal_loans_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                 <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.federal_loans } onValueChange={ ( e ) => updateValue( e, 'federal_loans' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.federal_loans_annual !== undefined ? collegeBudgetValues.federal_loans_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Private Loans</td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.private_loans_sem } onValueChange={ ( e ) => updateValue( e, 'private_loans_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.private_loans } onValueChange={ ( e ) => updateValue( e, 'private_loans' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.private_loans_annual !== undefined ? collegeBudgetValues.private_loans_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Personal Savings</td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.personal_savings_sem } onValueChange={ ( e ) => updateValue( e, 'personal_savings_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td className={ classes.textBlackInput }>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.personal_savings } onValueChange={ ( e ) => updateValue( e, 'personal_savings' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.personal_savings_annual !== undefined ? collegeBudgetValues.personal_savings_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classNames( classes.topBorderRow, classes.spaceRow, classes.spaceAfterRow ) }>
                              <td>Total Cash In</td>
                              {collegeBudgetValues.total_after_tax_income_sem < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_after_tax_income_sem !== undefined ? -( collegeBudgetValues.total_after_tax_income_sem ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {collegeBudgetValues.total_after_tax_income_sem >= 0 && (
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_after_tax_income_sem !== undefined ? collegeBudgetValues.total_after_tax_income_sem : 0 } displayType="text" prefix="$" />
                              </td>
                              )}

                              {collegeBudgetValues.total_after_tax_income < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_after_tax_income !== undefined ? -( collegeBudgetValues.total_after_tax_income ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {collegeBudgetValues.total_after_tax_income >= 0 && (
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_after_tax_income !== undefined ? collegeBudgetValues.total_after_tax_income : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_annual !== undefined ? collegeBudgetValues.total_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classes.emptyRow } />
                            <tr>
                              <th>&nbsp;</th>
                              <th><u>Per Semester</u></th>
                              <th><u>Per Month</u></th>
                              <th><u>Annual</u></th>
                              <th>&nbsp;</th>
                            </tr>
                            <tr>
                              <th colSpan="4" className={ classes.bottomText }><u>Cash Out</u></th>
                              <th className={ classes.italicLightText }>
                                <i>
                                  % of Total
                                  <br />
                                  Cash In
                                </i>
                              </th>
                            </tr>
                            <tr>
                              <td>Tuition and Fees</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.tution_fees_sem } onValueChange={ ( e ) => updateValue( e, 'tution_fees_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.tution_fees } onValueChange={ ( e ) => updateValue( e, 'tution_fees' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.tution_fees_annual > 0 && (
                              <td className={ collegeBudgetValues.tution_fees_annual.length > 9 ? classNames( classes.textRed) : classNames( classes.textRed, classes.countSmall ) }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.tution_fees_annual !== undefined ? collegeBudgetValues.tution_fees_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              
                              <td className={ classes.textRed }>
                              {(collegeBudgetValues.tution_fees_annual === undefined || collegeBudgetValues.tution_fees_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.tution_fees_annual !== undefined ? collegeBudgetValues.tution_fees_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.tution_fees_percentage) ? collegeBudgetValues.tution_fees_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Room and Board</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.room_board_sem } onValueChange={ ( e ) => updateValue( e, 'room_board_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.room_board } onValueChange={ ( e ) => updateValue( e, 'room_board' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.room_board_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.room_board_annual !== undefined ? collegeBudgetValues.room_board_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.room_board_annual === undefined || collegeBudgetValues.room_board_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.room_board_annual !== undefined ? collegeBudgetValues.room_board_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.room_board_percentage) ? collegeBudgetValues.room_board_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Books and Supplies</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.books_supplies_sem } onValueChange={ ( e ) => updateValue( e, 'books_supplies_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.books_supplies } onValueChange={ ( e ) => updateValue( e, 'books_supplies' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.books_supplies_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.books_supplies_annual !== undefined ? collegeBudgetValues.books_supplies_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.books_supplies_annual === undefined || collegeBudgetValues.books_supplies_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.books_supplies_annual !== undefined ? collegeBudgetValues.books_supplies_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.books_supplies_percentage) ? collegeBudgetValues.books_supplies_percentage : 0 } displayType="text" />

                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Parking Pass</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.parking_pass_sem } onValueChange={ ( e ) => updateValue( e, 'parking_pass_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.parking_pass } onValueChange={ ( e ) => updateValue( e, 'parking_pass' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.parking_pass_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.parking_pass_annual !== undefined ? collegeBudgetValues.parking_pass_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.parking_pass_annual === undefined || collegeBudgetValues.parking_pass_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.parking_pass_annual !== undefined ? collegeBudgetValues.parking_pass_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.parking_pass_percentage) ? collegeBudgetValues.parking_pass_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Rent</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.rent_sem } onValueChange={ ( e ) => updateValue( e, 'rent_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.rent } onValueChange={ ( e ) => updateValue( e, 'rent' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.rent_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.rent_annual !== undefined ? collegeBudgetValues.rent_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.rent_annual === undefined || collegeBudgetValues.rent_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.rent_annual !== undefined ? collegeBudgetValues.rent_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.rent_percentage) ? collegeBudgetValues.rent_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Cable and Internet</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.cable_internet_sem } onValueChange={ ( e ) => updateValue( e, 'cable_internet_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.cable_internet } onValueChange={ ( e ) => updateValue( e, 'cable_internet' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.cable_internet_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cable_internet_annual !== undefined ? collegeBudgetValues.cable_internet_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.cable_internet_annual === undefined || collegeBudgetValues.cable_internet_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cable_internet_annual !== undefined ? collegeBudgetValues.cable_internet_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.cable_internet_percentage) ? collegeBudgetValues.cable_internet_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Electricity, Gas and Water</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.electricity_water_sem } onValueChange={ ( e ) => updateValue( e, 'electricity_water_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.electricity_water } onValueChange={ ( e ) => updateValue( e, 'electricity_water' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.electricity_water_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.electricity_water_annual !== undefined ? collegeBudgetValues.electricity_water_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.electricity_water_annual === undefined || collegeBudgetValues.electricity_water_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.electricity_water_annual !== undefined ? collegeBudgetValues.electricity_water_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.electricity_water_percentage) ? collegeBudgetValues.electricity_water_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Groceries</td>                       
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.groceries_sem } onValueChange={ ( e ) => updateValue( e, 'groceries_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.groceries } onValueChange={ ( e ) => updateValue( e, 'groceries' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.groceries_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.groceries_annual !== undefined ? collegeBudgetValues.groceries_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                 {(collegeBudgetValues.groceries_annual === undefined || collegeBudgetValues.groceries_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.groceries_annual !== undefined ? collegeBudgetValues.groceries_annual : 0 } displayType="text" prefix="$" />
                                 )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.groceries_percentage) ? collegeBudgetValues.groceries_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Take Out Food</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.takeout_food_sem } onValueChange={ ( e ) => updateValue( e, 'takeout_food_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.takeout_food } onValueChange={ ( e ) => updateValue( e, 'takeout_food' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.takeout_food_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.takeout_food_annual !== undefined ? collegeBudgetValues.takeout_food_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.takeout_food_annual === undefined || collegeBudgetValues.takeout_food_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.takeout_food_annual !== undefined ? collegeBudgetValues.takeout_food_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.takeout_food_percentage) ? collegeBudgetValues.takeout_food_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Clothing</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.clothing_sem } onValueChange={ ( e ) => updateValue( e, 'clothing_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.clothing } onValueChange={ ( e ) => updateValue( e, 'clothing' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.clothing_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.clothing_annual !== undefined ? collegeBudgetValues.clothing_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.clothing_annual === undefined || collegeBudgetValues.clothing_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.clothing_annual !== undefined ? collegeBudgetValues.clothing_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.clothing_percentage) ? collegeBudgetValues.clothing_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Toiletries</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                 <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.toiletries_sem } onValueChange={ ( e ) => updateValue( e, 'toiletries_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.toiletries } onValueChange={ ( e ) => updateValue( e, 'toiletries' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.toiletries_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.toiletries_annual !== undefined ? collegeBudgetValues.toiletries_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                 {(collegeBudgetValues.toiletries_annual === undefined || collegeBudgetValues.toiletries_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.toiletries_annual !== undefined ? collegeBudgetValues.toiletries_annual : 0 } displayType="text" prefix="$" />
                                 )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.toiletries_percentage) ? collegeBudgetValues.toiletries_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Cleaning Supplies</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.cleaning_supplies_sem } onValueChange={ ( e ) => updateValue( e, 'cleaning_supplies_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.cleaning_supplies } onValueChange={ ( e ) => updateValue( e, 'cleaning_supplies' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.cleaning_supplies_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cleaning_supplies_annual !== undefined ? collegeBudgetValues.cleaning_supplies_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.cleaning_supplies_annual === undefined || collegeBudgetValues.cleaning_supplies_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cleaning_supplies_annual !== undefined ? collegeBudgetValues.cleaning_supplies_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.cleaning_supplies_percentage) ? collegeBudgetValues.cleaning_supplies_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Laundry</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.laundry_sem } onValueChange={ ( e ) => updateValue( e, 'laundry_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.laundry } onValueChange={ ( e ) => updateValue( e, 'laundry' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.laundry_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.laundry_annual !== undefined ? collegeBudgetValues.laundry_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.laundry_annual === undefined || collegeBudgetValues.laundry_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.laundry_annual !== undefined ? collegeBudgetValues.laundry_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.laundry_percentage) ? collegeBudgetValues.laundry_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Entertainment</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.entertainment_sem } onValueChange={ ( e ) => updateValue( e, 'entertainment_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.entertainment } onValueChange={ ( e ) => updateValue( e, 'entertainment' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.entertainment_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.entertainment_annual !== undefined ? collegeBudgetValues.entertainment_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                 {(collegeBudgetValues.entertainment_annual === undefined || collegeBudgetValues.entertainment_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.entertainment_annual !== undefined ? collegeBudgetValues.entertainment_annual : 0 } displayType="text" prefix="$" />
                                 )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.entertainment_percentage) ? collegeBudgetValues.entertainment_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Gym Membership</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.gym_membership_sem } onValueChange={ ( e ) => updateValue( e, 'gym_membership_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.gym_membership } onValueChange={ ( e ) => updateValue( e, 'gym_membership' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.gym_membership_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.gym_membership_annual !== undefined ? collegeBudgetValues.gym_membership_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.gym_membership_annual === undefined || collegeBudgetValues.gym_membership_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.gym_membership_annual !== undefined ? collegeBudgetValues.gym_membership_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.gym_membership_percentage) ? collegeBudgetValues.gym_membership_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Technology</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.technology_sem } onValueChange={ ( e ) => updateValue( e, 'technology_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.technology } onValueChange={ ( e ) => updateValue( e, 'technology' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.technology_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.technology_annual !== undefined ? collegeBudgetValues.technology_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.technology_annual === undefined || collegeBudgetValues.technology_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.technology_annual !== undefined ? collegeBudgetValues.technology_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.technology_percentage) ? collegeBudgetValues.technology_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Miscellaneous</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.miscellanneous_sem } onValueChange={ ( e ) => updateValue( e, 'miscellanneous_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.miscellanneous } onValueChange={ ( e ) => updateValue( e, 'miscellanneous' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.miscellanneous_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.miscellanneous_annual !== undefined ? collegeBudgetValues.miscellanneous_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.miscellanneous_annual === undefined || collegeBudgetValues.miscellanneous_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.miscellanneous_annual !== undefined ? collegeBudgetValues.miscellanneous_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.miscellanneous_percentage) ? collegeBudgetValues.miscellanneous_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Public Transportation and Ride Sharing</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.public_transportation_sem } onValueChange={ ( e ) => updateValue( e, 'public_transportation_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.public_transportation } onValueChange={ ( e ) => updateValue( e, 'public_transportation' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.public_transportation_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.public_transportation_annual !== undefined ? collegeBudgetValues.public_transportation_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.public_transportation_annual === undefined || collegeBudgetValues.public_transportation_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.public_transportation_annual !== undefined ? collegeBudgetValues.public_transportation_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.public_transportation_percentage) ? collegeBudgetValues.public_transportation_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Gas</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.gas_sem } onValueChange={ ( e ) => updateValue( e, 'gas_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.gas } onValueChange={ ( e ) => updateValue( e, 'gas' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.gas_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.gas_annual !== undefined ? collegeBudgetValues.gas_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.gas_annual === undefined || collegeBudgetValues.gas_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.gas_annual !== undefined ? collegeBudgetValues.gas_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.gas_percentage) ? collegeBudgetValues.gas_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Car Payment</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.car_payment_sem } onValueChange={ ( e ) => updateValue( e, 'car_payment_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.car_payment } onValueChange={ ( e ) => updateValue( e, 'car_payment' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.car_payment_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.car_payment_annual !== undefined ? collegeBudgetValues.car_payment_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                 {(collegeBudgetValues.car_payment_annual === undefined || collegeBudgetValues.car_payment_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.car_payment_annual !== undefined ? collegeBudgetValues.car_payment_annual : 0 } displayType="text" prefix="$" />
                                 )}
                              </td>
                              
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.car_payment_percentage) ? collegeBudgetValues.car_payment_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Car Insurance and Maintenance</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.car_insurance_sem } onValueChange={ ( e ) => updateValue( e, 'car_insurance_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.car_insurance } onValueChange={ ( e ) => updateValue( e, 'car_insurance' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.car_insurance_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.car_insurance_annual !== undefined ? collegeBudgetValues.car_insurance_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                 {(collegeBudgetValues.car_insurance_annual === undefined || collegeBudgetValues.car_insurance_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.car_insurance_annual !== undefined ? collegeBudgetValues.car_insurance_annual : 0 } displayType="text" prefix="$" />
                                 )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.car_insurance_percentage) ? collegeBudgetValues.car_insurance_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Health Insurance Premium</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.health_insurance_sem } onValueChange={ ( e ) => updateValue( e, 'health_insurance_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.health_insurance } onValueChange={ ( e ) => updateValue( e, 'health_insurance' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.health_insurance_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.health_insurance_annual !== undefined ? collegeBudgetValues.health_insurance_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.health_insurance_annual === undefined || collegeBudgetValues.health_insurance_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.health_insurance_annual !== undefined ? collegeBudgetValues.health_insurance_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.health_insurance_percentage) ? collegeBudgetValues.health_insurance_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Other Healthcare Costs</td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.other_healthcare_sem } onValueChange={ ( e ) => updateValue( e, 'other_healthcare_sem' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              <td>
                                <div className={ classes.flexInput }>
                                  <span>$</span>
                                  <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ collegeBudgetValues.other_healthcare } onValueChange={ ( e ) => updateValue( e, 'other_healthcare' ) } onFocus={ ( e ) => e.target.select() } />
                                </div>
                              </td>
                              {collegeBudgetValues.other_healthcare_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.other_healthcare_annual !== undefined ? collegeBudgetValues.other_healthcare_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.other_healthcare_annual === undefined || collegeBudgetValues.other_healthcare_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.other_healthcare_annual !== undefined ? collegeBudgetValues.other_healthcare_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.other_healthcare_percentage) ? collegeBudgetValues.other_healthcare_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.topBorderRow }>
                              <td>Total Cash Out</td>
                              {collegeBudgetValues.total_cashout_sem > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_cashout_sem !== undefined ? collegeBudgetValues.total_cashout_sem : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.total_cashout_sem === undefined || collegeBudgetValues.total_cashout_sem === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_cashout_sem !== undefined ? collegeBudgetValues.total_cashout_sem : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              {collegeBudgetValues.total_cashout_month > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_cashout_month !== undefined ? collegeBudgetValues.total_cashout_month : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.total_cashout_month === undefined || collegeBudgetValues.total_cashout_month === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_cashout_month !== undefined ? collegeBudgetValues.total_cashout_month : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              {collegeBudgetValues.total_cashout_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_cashout_annual !== undefined ? collegeBudgetValues.total_cashout_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td className={ classes.textRed }>
                                {(collegeBudgetValues.total_cashout_annual === undefined || collegeBudgetValues.total_cashout_annual === 0) && (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.total_cashout_annual !== undefined ? collegeBudgetValues.total_cashout_annual : 0 } displayType="text" prefix="$" />
                                )}
                              </td>
                              <td>

                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ Number.isFinite(collegeBudgetValues.total_cashout_percentage) ? collegeBudgetValues.total_cashout_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.emptyRow } />
                            <tr className={ classNames( classes.totalBorderRow, classes.totalBorder ) }>
                              <td>Cash Left Over for Savings / Invest</td>

                              {collegeBudgetValues.cash_left_over_sem >= 0 && (
                              <td className={ classes.textRed }>

                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cash_left_over_sem !== undefined ? collegeBudgetValues.cash_left_over_sem : 0 } displayType="text" prefix="$" />

                              </td>
                              )}
                              {collegeBudgetValues.cash_left_over_sem < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cash_left_over_sem !== undefined ? -( collegeBudgetValues.cash_left_over_sem ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}

                              {collegeBudgetValues.cash_left_over_month >= 0 && (
                              <td className={ classes.textRed }>

                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cash_left_over_month !== undefined ? collegeBudgetValues.cash_left_over_month : 0 } displayType="text" prefix="$" />

                              </td>
                              )}
                              {collegeBudgetValues.cash_left_over_month < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cash_left_over_month !== undefined ? -( collegeBudgetValues.cash_left_over_month ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}

                              {collegeBudgetValues.cash_left_over_annual >= 0 && (
                              <td className={ classes.textRed }>

                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cash_left_over_annual !== undefined ? collegeBudgetValues.cash_left_over_annual : 0 } displayType="text" prefix="$" />

                              </td>
                              )}
                              {collegeBudgetValues.cash_left_over_annual < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ collegeBudgetValues.cash_left_over_annual !== undefined ? -( collegeBudgetValues.cash_left_over_annual ) : 0 } displayType="text" prefix="$" />
                                {' '}
                                )
                              </td>
                              )}

                              {collegeBudgetValues.cash_left_over_percentage >= 0 && (
                              <td className={ classes.textRed }>

                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ ( collegeBudgetValues.cash_left_over_percentage ) ? collegeBudgetValues.cash_left_over_percentage : 0 } displayType="text" />
                                %
                              </td>
                              )}
                              {collegeBudgetValues.cash_left_over_percentage < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ ( collegeBudgetValues.cash_left_over_percentage ) ? -( collegeBudgetValues.cash_left_over_percentage ) : 0 } displayType="text" />
                                %)
                              </td>
                              )}

                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

Budget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Budget );
