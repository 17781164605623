import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const OpportunityCost = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Opportunity Cost</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/opportunity-cost.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Opportunity Cost
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div
                        className={classNames(
                          classes.pfSlideImage,
                          classes.ocImage,
                        )}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/opportunity-cost-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noMargin}>
                          Opportunity cost is easiest to understand with an
                          example. You just graduated from college and have two
                          job offers:
                        </p>
                        <ul
                          className={classNames(
                            classes.noTopMargin,
                            classes.jobMargin,
                          )}>
                          <li>
                            Job offer #1 is for a company and in an industry you
                            are passionate about with a salary of $55,000 per
                            year and average benefits.
                          </li>
                          <li>
                            Job offer #2 is for a company that you don&apos;t
                            really care for, but has a salary of $75,000 per
                            year with above average benefits.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          What&apos;s the opportunity cost or, in other words,
                          the trade-off, if you take job offer #1 versus #2? The
                          opportunity cost is the $20,000 of extra income you
                          could be earning with better benefits. But does that
                          extra income outweigh the passion? That&apos;s the
                          trade-off.
                        </p>
                        <p>
                          Every choice we make in life has a next best
                          alternative. Really think about it. Every choice from
                          getting out of bed at a certain time or deciding to
                          hang with friends or to work on your left-handed layup
                          has an opportunity cost.
                        </p>
                        <p>
                          When it comes to your financial life, there are two
                          incredibly valuable resources you must consider when
                          making decisions - time and money. When you make a
                          decision that involves your finances, always recognize
                          the next best alternative and the opportunity cost of
                          that decision. This will help you be more confident in
                          your decision-making abilities.
                        </p>
                        <p>
                          Opportunity cost is the benefit that you miss out on
                          when you make one choice versus another. Next time you
                          go to make a decision, practice analyzing the
                          opportunity costs. What is the next best alternative
                          to this decision I am making? How am I using my most
                          precious resource - time? Where else could I spend,
                          save or invest this money?
                        </p>
                        <p>
                          Start asking yourself, &quot;What if I do this versus
                          that?&quot; You&apos;ll gain confidence and peace of
                          mind in your decisions as you understand and can
                          accept the opportunity costs.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
OpportunityCost.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(OpportunityCost);
