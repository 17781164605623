function taxStatement( module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module9Data, module10Data,
  module13Data, module14Data, module15Data, module17Data, module18Data, existingStudentLoanData, studentLoanTaxData, expenseCalculationsData,
  assumptionsData, inputTaxesData, investmentsData, depreciationSheduleData, existingCarLoanData, carLoan1Data, carLoan2Data, mortgage1Data, mortgage2Data,
  buyingAHomeData, invPropertyRoiData, databaseData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData,
  fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData,
  fedUnSubExistingLoanData, privateExistingLoanData, existingHomeData, existingCreditCardDebtData, existingHomeMortgageData, incomeStatementData, cashFlowData ) {
  /** ******************** Student Loan 1 *********************** */
  let year = 0;
  let yearLimit = 0;

  let effectiveTaxRateValue = 0;
  let socialSecurityTaxesValue = 0;
  let medicareTaxesValue = 0;
  let additionalMedicareTaxesValue = 0;

  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;

  let totalGrossIncome = 0;
  let rentalIncome = 0;
  let operatingExpenses = 0;
  let mortgageInterestPoints1 = 0;
  let mortgageInterestPoints2 = 0;
  let mortgageInterestPoints21 = 0;
  let mortgageInterestPoints22 = 0;
  let mortgageInterestPoints3 = 0;
  let mortgageInterestPoints = 0;
  let reiPropertyTaxableIncome = 0;
  let passiveLossesBeginningBal = 0;
  let passiveLossesCreated = 0;
  let passiveLossesBalance = 0;
  let passiveLossesTaken = 0;
  let remainingPassiveLosses = 0;
  let reiPropertyTaxableIncomeFinal = 0;

  let invPropertyRentalIncomeValue;
  let collegeRoomBoardValue = 0;
  let offCampusFoodValue = 0;
  const grossIncomeTotal = 0;

  let retirementContributionsValue1 = 0;
  let retirementContributionsValue2 = 0;
  let retirementContributionsValue3 = 0;
  let retirementContributionsValue4 = 0;

  let disposableIncome = 0;

  let collegeTuitionValue = 0;

  let PetValue = 0;
  let ChildrenValue = 0;
  let adjustments401kValue = 0;
  let adjustments401kValue1 = 0;
  let adjustments401kValue2 = 0;
  let adjustments401kValue3 = 0;
  let adjustments401kValue4 = 0;
  let adjustmentsStudentLoanInterestValue = 0;
  let selfEmployementTaxValue = 0;
  let healthSavingAccountContributionsValue = 0;
  let selfEmployementTaxValue2 = 0;
  let adjustedGrossIncomeValue = 0;
  let incomeTaxesMedicareValue = 0;
  let itemizedmortgageValue = 0;
  let intemizedPropertyDepreciationValue = 0;
  let intemizedHomePropertyTaxesValue = 0;
  let intemizedHomePropertyTaxesValue1 = 0;
  let deductionsHomePropertyTaxesValue = 0;
  let intemizedHomePropertyTaxesValue2 = 0;
  let intemizedHealthInsuranceValue = 0;
  let intemizedCharitableContributionsValue = 0;
  let totalItemizedDeductionsValue = 0;
  let standardDeductionsValue = 0;
  let totalDeductionValue = 0;
  let totalREIPropertyDeductions = 0;
  let sumOfitemizedProducts = 0;
  let federalTaxableIncomeValue = 0;

  let expenseCalculationsB7 = 0;
  let expenseCalculationsB8 = 0;
  if ( module2Data.on_campus_start_year !== undefined ) {
    expenseCalculationsB7 = module2Data.on_campus_start_year;
  }
  // Expense Calculation B8
  if ( module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' ) {
    expenseCalculationsB8 = module2Data.graduation_year;
  } else if ( module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year ) {
    expenseCalculationsB8 = parseInt( module2Data.off_campus_start_year, 10 ) - 1;
  } else if ( module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' ) {
    expenseCalculationsB8 = module2Data.graduation_year;
  } else {
    expenseCalculationsB8 = 0;
  }

  let expenseCalculationsB27 = 0;
  let expenseCalculationsB28 = 0;
  // Expense Calcualtion B24
  if ( module2Data.on_campus_start_year !== undefined ) {
    expenseCalculationsB27 = module2Data.off_campus_start_year;
  }
  if ( module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' ) {
    expenseCalculationsB28 = module2Data.graduation_year;
  } else if ( module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year ) {
    expenseCalculationsB28 = parseInt( module2Data.on_campus_start_year, 10 ) - 1;
  } else if ( module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' ) {
    expenseCalculationsB28 = module2Data.graduation_year;
  } else {
    expenseCalculationsB28 = 0;
  }

  let expenseCalculationsB48 = 0;
  let expenseCalculationsB49 = 0;
  if ( module4Data.start_year !== undefined ) {
    expenseCalculationsB48 = module4Data.start_year;
  }
  if ( module4Data.end_year !== undefined ) {
    expenseCalculationsB49 = module4Data.end_year;
  }

  let expenseCalculationsB69 = 0;
  let expenseCalculationsB70 = 0;
  if ( module5Data.start_year !== undefined ) {
    expenseCalculationsB69 = module5Data.start_year;
  }
  if ( module5Data.end_year !== undefined ) {
    expenseCalculationsB70 = module5Data.end_year;
  }

  let expenseCalculationsB90 = 0;
  let expenseCalculationsB91 = 0;
  // Expense Calculation B75
  if ( module6Data.on_campus_start_year !== undefined ) {
    expenseCalculationsB90 = module6Data.on_campus_start_year;
  }
  if ( module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year === 0 && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' ) {
    expenseCalculationsB91 = module6Data.graduation_year;
  } else if ( module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.off_campus_start_year > module6Data.on_campus_start_year ) {
    expenseCalculationsB91 = parseInt( module6Data.off_campus_start_year, 10 ) - 1;
  } else if ( module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year > module6Data.off_campus_start_year && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' ) {
    expenseCalculationsB91 = module6Data.graduation_year;
  } else {
    expenseCalculationsB91 = 0;
  }

  let expenseCalculationsB110 = 0;
  let expenseCalculationsB111 = 0;
  if ( module6Data.off_campus_start_year !== undefined ) {
    expenseCalculationsB110 = module6Data.off_campus_start_year;
  }
  // Expense Calculation B91
  if ( module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year === 0 && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' ) {
    expenseCalculationsB111 = module6Data.graduation_year;
  } else if ( module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.on_campus_start_year > module6Data.off_campus_start_year ) {
    expenseCalculationsB111 = parseInt( module6Data.on_campus_start_year, 10 ) - 1;
  } else if ( module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year > module6Data.on_campus_start_year && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' ) {
    expenseCalculationsB111 = module6Data.graduation_year;
  } else {
    expenseCalculationsB111 = 0;
  }

  let expenseCalculationsB131 = 0;
  let expenseCalculationsB132 = 0;
  if ( module8Data.start_year !== undefined ) {
    expenseCalculationsB131 = module8Data.start_year;
  }
  if ( module8Data.end_year !== undefined ) {
    expenseCalculationsB132 = module8Data.end_year;
  }

  let expenseCalculationsB152 = 0;
  const expenseCalculationsB153 = 0;
  if ( module15Data.on_campus_start_year !== undefined ) {
    expenseCalculationsB152 = module15Data.on_campus_start_year;
  }
  // Expense Calculations B125
  if ( module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' ) {
    expenseCalculationsB152 = module15Data.graduation_year;
  } else if ( module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.off_campus_start_year > module15Data.on_campus_start_year ) {
    expenseCalculationsB152 = parseInt( module15Data.off_campus_start_year, 10 ) - 1;
  } else if ( module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year > module15Data.off_campus_start_year && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' ) {
    expenseCalculationsB152 = module15Data.graduation_year;
  } else {
    expenseCalculationsB152 = 0;
  }

  let expenseCalculationsB172 = 0;
  let expenseCalculationsB173 = 0;
  if ( module15Data.off_campus_start_year !== undefined ) {
    expenseCalculationsB172 = module15Data.off_campus_start_year;
  }
  // Expemse Calculations B141
  if ( module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' ) {
    expenseCalculationsB173 = module15Data.graduation_year;
  } else if ( module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.on_campus_start_year > module15Data.off_campus_start_year ) {
    expenseCalculationsB173 = parseInt( module15Data.on_campus_start_year, 10 ) - 1;
  } else if ( module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year > module15Data.on_campus_start_year && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' ) {
    expenseCalculationsB173 = module15Data.graduation_year;
  } else {
    expenseCalculationsB173 = 0;
  }

  let expenseCalculationsB193 = 0;
  let expenseCalculationsB194 = 0;
  if ( module17Data.start_year !== undefined ) {
    expenseCalculationsB193 = module17Data.start_year;
  }
  if ( expenseCalculationsB193 !== '' && module17Data.end_year !== undefined ) {
    expenseCalculationsB194 = module17Data.end_year;
  }

  let rentValue = 0;
  let rentValue1 = 0;
  let rentValue2 = 0;
  let rentValue3 = 0;
  // Individual Values
  let rentValue10 = 0;
  let rentValue11 = 0;
  let rentValue12 = 0;
  let rentValue13 = 0;
  let rentValue14 = 0;
  let rentValue15 = 0;
  let rentValue16 = 0;

  let utilitiesValue = 0;
  let utilitiesValue1 = 0;
  let utilitiesValue2 = 0;
  let utilitiesValue3 = 0;
  // Individual values
  let utilitiesValue10 = 0;
  let utilitiesValue11 = 0;
  let utilitiesValue12 = 0;
  let utilitiesValue13 = 0;
  let utilitiesValue14 = 0;
  let utilitiesValue15 = 0;
  let utilitiesValue16 = 0;

  let FoodValue = 0;
  let FoodValue1 = 0;
  let FoodValue2 = 0;
  let FoodValue3 = 0;
  // Individual values
  let FoodValue10 = 0;
  let FoodValue11 = 0;
  let FoodValue12 = 0;
  let FoodValue13 = 0;
  let FoodValue14 = 0;
  let FoodValue15 = 0;
  let FoodValue16 = 0;

  let clothingValue = 0;
  let clothingValue1 = 0;
  let clothingValue2 = 0;
  let clothingValue3 = 0;
  // Individual Values
  let clothingValue10 = 0;
  let clothingValue11 = 0;
  let clothingValue12 = 0;
  let clothingValue13 = 0;
  let clothingValue14 = 0;
  let clothingValue15 = 0;
  let clothingValue16 = 0;
  let clothingValue17 = 0;
  let clothingValue18 = 0;
  let clothingValue19 = 0;

  let EntertainmentValue = 0;
  let EntertainmentValue1 = 0;
  let EntertainmentValue2 = 0;
  let EntertainmentValue3 = 0;
  // Individual Values
  let EntertainmentValue10 = 0;
  let EntertainmentValue11 = 0;
  let EntertainmentValue12 = 0;
  let EntertainmentValue13 = 0;
  let EntertainmentValue14 = 0;
  let EntertainmentValue15 = 0;
  let EntertainmentValue16 = 0;
  let EntertainmentValue17 = 0;
  let EntertainmentValue18 = 0;
  let EntertainmentValue19 = 0;

  let TechnologyValue = 0;
  let TechnologyValue1 = 0;
  let TechnologyValue2 = 0;
  let TechnologyValue3 = 0;
  // Individual Values
  let TechnologyValue10 = 0;
  let TechnologyValue11 = 0;
  let TechnologyValue12 = 0;
  let TechnologyValue13 = 0;
  let TechnologyValue14 = 0;
  let TechnologyValue15 = 0;
  let TechnologyValue16 = 0;
  let TechnologyValue17 = 0;
  let TechnologyValue18 = 0;
  let TechnologyValue19 = 0;

  let TransportationValue = 0;
  let TransportationValue1 = 0;
  let TransportationValue2 = 0;
  let TransportationValue3 = 0;
  // Individual Values
  let TransportationValue10 = 0;
  let TransportationValue11 = 0;
  let TransportationValue12 = 0;
  let TransportationValue13 = 0;
  let TransportationValue14 = 0;
  let TransportationValue15 = 0;
  let TransportationValue16 = 0;
  let TransportationValue17 = 0;
  let TransportationValue18 = 0;
  let TransportationValue19 = 0;

  let carPaymentValue = 0;
  let carPaymentValue1 = 0;
  let carPaymentValue2 = 0;

  let CarMaintenanceValue = 0;
  let CarMaintenanceValue1 = 0;
  let CarMaintenanceValue2 = 0;
  let CarMaintenanceValue3 = 0;
  let CarMaintenanceValue4 = 0;
  let CarMaintenanceValue5 = 0;

  let CarInsuranceValue = 0;
  let CarInsuranceValue1 = 0;
  let CarInsuranceValue2 = 0;
  let CarInsuranceValue3 = 0;
  let CarInsuranceValue4 = 0;
  let CarInsuranceValue5 = 0;

  let GasValue = 0;
  let GasValue1 = 0;
  let GasValue2 = 0;
  let GasValue3 = 0;
  let GasValue4 = 0;
  let GasValue5 = 0;

  let MiscellaneousValue = 0;
  let MiscellaneousValue1 = 0;
  let MiscellaneousValue2 = 0;
  let MiscellaneousValue3 = 0;
  // Individual values
  let MiscellaneousValue10 = 0;
  let MiscellaneousValue11 = 0;
  let MiscellaneousValue12 = 0;
  let MiscellaneousValue13 = 0;
  let MiscellaneousValue14 = 0;
  let MiscellaneousValue15 = 0;
  let MiscellaneousValue16 = 0;
  let MiscellaneousValue17 = 0;
  let MiscellaneousValue18 = 0;
  let MiscellaneousValue19 = 0;

  let healthInsuranceDeductibleValue = 0;
  let healthInsuranceDeductibleValue1 = 0;
  let healthInsuranceDeductibleValue2 = 0;
  let healthInsuranceDeductibleValue3 = 0;
  // Individual Values
  let healthInsuranceDeductibleValue10 = 0;
  let healthInsuranceDeductibleValue11 = 0;
  let healthInsuranceDeductibleValue12 = 0;
  let healthInsuranceDeductibleValue13 = 0;
  let healthInsuranceDeductibleValue14 = 0;
  let healthInsuranceDeductibleValue15 = 0;
  let healthInsuranceDeductibleValue16 = 0;
  let healthInsuranceDeductibleValue17 = 0;
  let healthInsuranceDeductibleValue18 = 0;
  let healthInsuranceDeductibleValue19 = 0;

  let healthInsurancePremiumValue = 0;
  let healthInsurancePremiumValue1 = 0;
  let healthInsurancePremiumValue2 = 0;
  let healthInsurancePremiumValue3 = 0;
  // Individual Values
  let healthInsurancePremiumValue10 = 0;
  let healthInsurancePremiumValue11 = 0;
  let healthInsurancePremiumValue12 = 0;
  let healthInsurancePremiumValue13 = 0;
  let healthInsurancePremiumValue14 = 0;
  let healthInsurancePremiumValue15 = 0;
  let healthInsurancePremiumValue16 = 0;
  let healthInsurancePremiumValue17 = 0;
  let healthInsurancePremiumValue18 = 0;
  let healthInsurancePremiumValue19 = 0;

  const taxStatementCompleteData = {};

  const totalDisposableGraph = [];
  const totalLivingExpensesGraph = [];
  const totalGrossIncomeGraph = [];
  const reiPropertyTaxableIncomeGraph = [];
  const PassiveLossesTakenGraph = [];
  const reiPropertyTaxableIncomeGraphLoss = [];
  const totalAdjustmentsGraph = [];
  const adjustmentsGrossGraph = [];
  const itemizedDeductionsGraph = [];
  const StandardDeductionsGraph = [];
  const totalDeductionsGraph = [];
  const federalTaxableIncomeGraph = [];
  const totalTaxesBeforeCreditsGraph = [];
  const totalTaxesCreditsGraph = [];
  const totalTaxesGraph = [];
  const incomeAfterTaxesGraph = [];
  const depreciationExpensesGraph = [];
  const totalInterestExpensesGraph = [];
  const totalExpensesGraph = [];
  const netIncomeGraph = [];

  const spousesTotalIncome = 0;
  for ( year; year < yearLimit; year += 1 ) {
    taxStatementCompleteData[year] = {};

    taxStatementCompleteData[year]['Gross Income'] = {};
    taxStatementCompleteData[year]['REI Property'] = {};
    taxStatementCompleteData[year]['REI Property1'] = {};
    taxStatementCompleteData[year].deductionsBeforeFederalIncomeTax = {};
    taxStatementCompleteData[year].livingExpenses = {};
    taxStatementCompleteData[year].taxes = {};
    taxStatementCompleteData[year]['Tax Credits'] = {};
    taxStatementCompleteData[year].DepreciationExpenses = {};
    taxStatementCompleteData[year].InterestExpenses = {};
    taxStatementCompleteData[year].Adjustments = {};
    taxStatementCompleteData[year]['Total Adjustments'] = {};
    taxStatementCompleteData[year]['Adjusted Gross Income'] = {};
    taxStatementCompleteData[year]['Itemized Deductions'] = {};
    taxStatementCompleteData[year]['REI Property Deductions'] = {};
    taxStatementCompleteData[year]['Standard Deduction'] = {};
    taxStatementCompleteData[year]['Personal Exemptions'] = {};
    taxStatementCompleteData[year]['Total Deductions'] = {};
    taxStatementCompleteData[year]['Investment Property Sale'] = {};

    /** **************************************
                 ** Gross Income and Total  Income **
                 **************************************** */
    // Income Earned during Higher Education
    let conditionCheck2 = 0;
    let conditionCheck3 = 0;

    let stateLocalTaxesValue = 0;
    let stateLocalTaxesValue1 = 0;
    let stateLocalTaxesValue2 = 0;
    let stateLocalTaxesValue3 = 0;
    let stateLocalTaxesValue4 = 0;
    let stateLocalTaxesValue5 = 0;

    if ( incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[year]['Gross Income'] !== undefined && incomeStatementData[year]['Gross Income'] !== '' ) {
      totalGrossIncome = incomeStatementData[year]['Gross Income']['Income Earned during Higher Education']
                            + incomeStatementData[year]['Gross Income'][module4Data.occupation_title]
                            + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission']
                            + incomeStatementData[year]['Gross Income']['Supplementary Income']
                            + incomeStatementData[year]['Gross Income'][`${module5Data.occupation_title} - Second`]
                            + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Second']
                            + incomeStatementData[year]['Gross Income']['Supplementary Income - Second']
                            + incomeStatementData[year]['Gross Income'][`${module8Data.occupation_title} - Third`]
                            + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Third']
                            + incomeStatementData[year]['Gross Income']['Supplementary Income - Third']
                            + incomeStatementData[year]['Gross Income'][`${module17Data.occupation_title} - Fourth`]
                            + incomeStatementData[year]['Gross Income']['Bonus / Tips / Commission - Fourth']
                            + incomeStatementData[year]['Gross Income']['Supplementary Income - Fourth']
                            + incomeStatementData[year]['Gross Income']['Spouses Income'];
    }

    // Total Income
    taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] = totalGrossIncome;
    totalGrossIncomeGraph.push( taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] );

    // REI Property
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year] !== '' ) {
      rentalIncome = invPropertyRoiData.Data1[year]['Rental Income'];
    }
    taxStatementCompleteData[year]['REI Property']['Rental Income'] = rentalIncome;

    // Operating Expenses
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1[year] !== undefined && invPropertyRoiData.Data1[year] !== '' ) {
      operatingExpenses = invPropertyRoiData.Data1[year]['Association Fees']
                    + invPropertyRoiData.Data1[year]['Management Fees']
                    + invPropertyRoiData.Data1[year]['Houseowners Insurance']
                    + invPropertyRoiData.Data1[year]['Property Maintenance']
                    + invPropertyRoiData.Data1[year].Utilities
                    + invPropertyRoiData.Data1[year]['Property Taxes'];
    }
    taxStatementCompleteData[year]['REI Property']['Operating Expenses'] = -operatingExpenses;

    // Mortgage Interest, Loan Points & Origination Fees, & Depreciation
    if ( module14Data.year_of_purchase !== undefined && year === module14Data.year_of_purchase ) {
      if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Closing Cost'] !== undefined && invPropertyRoiData['Closing Cost'] !== '' ) {
        mortgageInterestPoints1 = invPropertyRoiData['Closing Cost']['Loan Acquisition Points'] + invPropertyRoiData['Closing Cost']['Origination Points'];
      } else {
        mortgageInterestPoints1 = 0;
      }
      if ( mortgage2Data !== undefined && mortgage2Data !== '' && mortgage2Data['Summation Data'][year] !== undefined && mortgage2Data['Summation Data'][year] !== '' ) {
        mortgageInterestPoints21 = mortgage2Data['Summation Data'][year].LaggingInterest;
      } else {
        mortgageInterestPoints21 = 0;
      }
      if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Closing Cost'] !== undefined && invPropertyRoiData['Closing Cost'] !== '' ) {
        mortgageInterestPoints22 = invPropertyRoiData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
      } else {
        mortgageInterestPoints22 = 0;
      }
      mortgageInterestPoints2 = mortgageInterestPoints21 + mortgageInterestPoints22;
    } else {
      mortgageInterestPoints1 = 0;
      if ( mortgage2Data !== undefined && mortgage2Data !== '' && mortgage2Data['Summation Data'][year] !== undefined && mortgage2Data['Summation Data'][year] !== '' ) {
        mortgageInterestPoints2 = mortgage2Data['Summation Data'][year].LaggingInterest;
      } else {
        mortgageInterestPoints2 = 0;
      }
    }

    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Real Estate'] !== undefined && invPropertyRoiData['Real Estate'][year] !== undefined && invPropertyRoiData['Real Estate'][year] !== '' ) {
      mortgageInterestPoints3 = invPropertyRoiData['Real Estate'][year].Home;
    } else {
      mortgageInterestPoints3 = 0;
    }
    mortgageInterestPoints = -( mortgageInterestPoints1 + mortgageInterestPoints2 + mortgageInterestPoints3 );

    taxStatementCompleteData[year]['REI Property']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation'] = mortgageInterestPoints;

    // REI Property Taxable Income (Passive Loss Created)
    reiPropertyTaxableIncome = rentalIncome + taxStatementCompleteData[year]['REI Property']['Operating Expenses'] + mortgageInterestPoints;
    taxStatementCompleteData[year]['REI Property Taxable Income (Passive Loss Created)'] = reiPropertyTaxableIncome;
    reiPropertyTaxableIncomeGraph.push( taxStatementCompleteData[year]['REI Property Taxable Income (Passive Loss Created)'] );

    // Passive Losses Info in REI Property
    // Passive Losses Beginning Balance
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data3 !== undefined && invPropertyRoiData.Data3[year] !== undefined && invPropertyRoiData.Data3[year] !== '' ) {
      passiveLossesBeginningBal = invPropertyRoiData.Data3[year]['Passive Losses Beginning Balance'];
    } else {
      passiveLossesBeginningBal = 0;
    }
    taxStatementCompleteData[year]['REI Property1']['Passive Losses Beginning Balance'] = passiveLossesBeginningBal;

    // Passive Loss Created
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data3 !== undefined && invPropertyRoiData.Data3[year] !== undefined && invPropertyRoiData.Data3[year] !== '' ) {
      passiveLossesCreated = invPropertyRoiData.Data3[year]['Passive Loss Created Second'];
    } else {
      passiveLossesCreated = 0;
    }
    taxStatementCompleteData[year]['REI Property1']['Passive Loss Created'] = passiveLossesCreated;

    // Passive Losses Balance
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data3 !== undefined && invPropertyRoiData.Data3[year] !== undefined && invPropertyRoiData.Data3[year] !== '' ) {
      passiveLossesBalance = invPropertyRoiData.Data3[year]['Passive Losses Balance'];
    } else {
      passiveLossesBalance = 0;
    }
    taxStatementCompleteData[year]['REI Property1']['Passive Losses Balance'] = passiveLossesBalance;

    // Passive Losses Taken
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data3 !== undefined && invPropertyRoiData.Data3[year] !== undefined && invPropertyRoiData.Data3[year] !== '' ) {
      passiveLossesTaken = -invPropertyRoiData.Data3[year]['Passive Losses Taken'];
    } else {
      passiveLossesTaken = 0;
    }
    taxStatementCompleteData[year]['REI Property1']['Passive Losses Taken'] = passiveLossesTaken;
    PassiveLossesTakenGraph.push( taxStatementCompleteData[year]['REI Property1']['Passive Losses Taken'] );

    // Remaining Passive Losses
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data3 !== undefined && invPropertyRoiData.Data3[year] !== undefined && invPropertyRoiData.Data3[year] !== '' ) {
      remainingPassiveLosses = invPropertyRoiData.Data3[year]['Remaining Passive Losses'];
    } else {
      remainingPassiveLosses = 0;
    }
    taxStatementCompleteData[year]['REI Property1']['Remaining Passive Losses'] = remainingPassiveLosses;

    // REI Property Taxable Income (Loss)
    if ( reiPropertyTaxableIncome > 0 ) {
      reiPropertyTaxableIncomeFinal = reiPropertyTaxableIncome + taxStatementCompleteData[year]['REI Property1']['Passive Losses Taken'];
    } else {
      reiPropertyTaxableIncomeFinal = taxStatementCompleteData[year]['REI Property1']['Passive Losses Taken'];
    }
    taxStatementCompleteData[year]['REI Property Taxable Income (Passive Losses Taken)'] = reiPropertyTaxableIncomeFinal;
    reiPropertyTaxableIncomeGraphLoss.push( taxStatementCompleteData[year]['REI Property Taxable Income (Passive Losses Taken)'] );

    /** End of Gross Income & Total Income * */

    /** **********************************************************
                 ** Adjustments & Total Adjustments & Adjusted Gross Income **
                 ************************************************************ */
    // Social Security
    let socialconditionCheck = 0;
    if ( module4Data.start_year !== undefined
                     && module4Data.end_year !== undefined
                     && module4Data.self_employment !== undefined
                    && year >= module4Data.start_year
                    && year <= module4Data.end_year
                    && module4Data.self_employment === 'Yes' ) {
      socialSecurityTaxesValue = ( 6.20 / 100 ) * 2;
      socialconditionCheck = 1;
    } else if ( module5Data.start_year !== undefined
                         && module5Data.end_year !== undefined
                         && module5Data.self_employment !== undefined
                        && year >= module5Data.start_year
                        && year <= module5Data.end_year
                        && module5Data.self_employment === 'Yes' ) {
      socialSecurityTaxesValue = ( 6.20 / 100 ) * 2;
      socialconditionCheck = 1;
    } else if ( module8Data.start_year !== undefined
                             && module8Data.end_year !== undefined
                             && module8Data.self_employment !== undefined
                            && year >= module8Data.start_year
                            && year <= module8Data.end_year
                            && module8Data.self_employment === 'Yes' ) {
      socialSecurityTaxesValue = ( 6.20 / 100 ) * 2;
      socialconditionCheck = 1;
    } else if ( module17Data.start_year !== undefined
                                 && module17Data.end_year !== undefined
                                 && module17Data.self_employment !== undefined
                                && year >= module17Data.start_year
                                && year <= module17Data.end_year
                                && module17Data.self_employment === 'Yes' ) {
      socialSecurityTaxesValue = ( 6.20 / 100 ) * 2;
      socialconditionCheck = 1;
    } else {
      socialSecurityTaxesValue = ( 6.20 / 100 );
    }
    socialSecurityTaxesValue *= 100;

    // Medicare
    let medicareconditionCheck = 0;
    if ( module4Data.start_year !== undefined
                     && module4Data.end_year !== undefined
                     && module4Data.self_employment !== undefined
                    && year >= module4Data.start_year
                    && year <= module4Data.end_year
                    && module4Data.self_employment === 'Yes' ) {
      if ( module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
        if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                             && inputTaxesData['Federal Income'][year] !== undefined
                             && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '' ) {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                             && inputTaxesData['Federal Income'][year] !== undefined
                             && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '' ) {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if ( module5Data.start_year !== undefined
                         && module5Data.end_year !== undefined
                         && module5Data.self_employment !== undefined
                        && year >= module5Data.start_year
                        && year <= module5Data.end_year
                        && module5Data.self_employment === 'Yes' ) {
      if ( module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
        if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                 && inputTaxesData['Federal Income'][year] !== undefined
                                 && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '' ) {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                 && inputTaxesData['Federal Income'][year] !== undefined
                                 && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '' ) {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if ( module8Data.start_year !== undefined
                             && module8Data.end_year !== undefined
                             && module8Data.self_employment !== undefined
                            && year >= module8Data.start_year
                            && year <= module8Data.end_year
                            && module8Data.self_employment === 'Yes' ) {
      if ( module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
        if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                     && inputTaxesData['Federal Income'][year] !== undefined
                                     && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '' ) {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                     && inputTaxesData['Federal Income'][year] !== undefined
                                     && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '' ) {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if ( module17Data.start_year !== undefined
                                 && module17Data.end_year !== undefined
                                 && module17Data.self_employment !== undefined
                                && year >= module17Data.start_year
                                && year <= module17Data.end_year
                                && module17Data.self_employment === 'Yes' ) {
      if ( module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
        if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                         && inputTaxesData['Federal Income'][year] !== undefined
                                         && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '' ) {
          medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] * 2;
          medicareconditionCheck = 1;
        } else {
          medicareTaxesValue = 0;
        }
      } else if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                         && inputTaxesData['Federal Income'][year] !== undefined
                                         && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '' ) {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] * 2;
        medicareconditionCheck = 1;
      } else {
        medicareTaxesValue = 0;
      }
    } else if ( module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
      if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                         && inputTaxesData['Federal Income'][year] !== undefined
                                         && inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'] !== '' ) {
        medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare2['Medicare Tax Rate'];
      } else {
        medicareTaxesValue = 0;
      }
    } else if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                                         && inputTaxesData['Federal Income'][year] !== undefined
                                         && inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'] !== '' ) {
      medicareTaxesValue = inputTaxesData['Federal Income'][year].Medicare['Federal Income Tax'];
    } else {
      medicareTaxesValue = 0;
    }
    // Medicare Tax value
    if ( inputTaxesData !== undefined && inputTaxesData['Medicare Taxes'] !== undefined
           && inputTaxesData['Medicare Taxes'][year] !== undefined
           && inputTaxesData['Medicare Taxes'][year]['Medicare Tax Rate'] !== '' ) {
      medicareTaxesValue = inputTaxesData['Medicare Taxes'][year]['Medicare Tax Rate'];
      medicareconditionCheck = 1;
    } else {
      medicareTaxesValue = 0;
    }
    
    // 401k Contributions                
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['401k Second'] !== 'undefined' &&
		typeof investmentsData['401k Second'][year] !== 'undefined' &&
		typeof investmentsData['401k Second'][year]['Personal Contribution'] !== 'undefined') {                    
		adjustments401kValue1 = investmentsData['401k Second'][year]['Personal Contribution']; 
						  
	} else {
		adjustments401kValue1 = 0;
	}
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['Retirement Accounts Traditional IRA Second'] !== 'undefined' &&
		typeof investmentsData['Retirement Accounts Traditional IRA Second'][year] !== 'undefined' &&
		typeof investmentsData['Retirement Accounts Traditional IRA Second'][year]['Annual Contribution'] !== 'undefined') {                    
		adjustments401kValue2 = investmentsData['Retirement Accounts Traditional IRA Second'][year]['Annual Contribution'];  
						  
	} else {
		adjustments401kValue2 = 0;
	}
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['Seph IRA Second'] !== 'undefined' &&
		typeof investmentsData['Seph IRA Second'][year] !== 'undefined' &&
		typeof investmentsData['Seph IRA Second'][year]['Annual Contribution'] !== 'undefined') {                    
		adjustments401kValue3 = investmentsData['Seph IRA Second'][year]['Annual Contribution'];   
			  
	} else {
		adjustments401kValue3 = 0;
	}
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['Pension Second'] !== 'undefined' &&
		typeof investmentsData['Pension Second'][year] !== 'undefined' &&
		typeof investmentsData['Pension Second'][year]['Personal Contribution'] !== 'undefined') {                    
		adjustments401kValue4 = investmentsData['Pension Second'][year]['Personal Contribution'];   
	} else {
		adjustments401kValue4 = 0;
	}
    adjustments401kValue = adjustments401kValue1 + adjustments401kValue2 + adjustments401kValue3 + adjustments401kValue4;
    taxStatementCompleteData[year].Adjustments['401k Contributions'] = adjustments401kValue;

    // Higher Education Tuition
    let conditionCheck = 0;
    let collegeTuitionValue1 = 0;
    if ( module2Data.start_year !== undefined
                    && year === module2Data.start_year ) {
      if ( module2Data.tuition !== undefined && module2Data.tuition !== '' ) {
        collegeTuitionValue1 = -module2Data.tuition;
        conditionCheck = 1;
      } else {
        collegeTuitionValue1 = 0;
      }
    } else if ( module2Data.start_year !== undefined && module2Data.start_year !== ''
                         && module2Data.graduation_year !== undefined && module2Data.graduation_year !== ''
                        && year >= module2Data.start_year && year <= module2Data.graduation_year ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        collegeTuitionValue1 = collegeTuitionValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        collegeTuitionValue1 = 0;
      }
    }

    conditionCheck2 = 0;
    let collegeTuitionValue2 = 0;
    if ( module6Data.start_year !== undefined
                    && year === module6Data.start_year ) {
      if ( module6Data.tuition !== undefined && module6Data.tuition !== '' ) {
        collegeTuitionValue2 = -module6Data.tuition;
        conditionCheck2 = 1;
      } else {
        collegeTuitionValue2 = 0;
      }
    } else if ( module6Data.start_year !== undefined && module6Data.start_year !== ''
                         && module6Data.graduation_year !== undefined && module6Data.graduation_year !== ''
                        && year >= module6Data.start_year && year <= module6Data.graduation_year ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        collegeTuitionValue2 = collegeTuitionValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck2 = 2;
      } else {
        collegeTuitionValue2 = 0;
      }
    }

    conditionCheck3 = 0;
    let collegeTuitionValue3 = 0;
    if ( module15Data.start_year !== undefined
                    && year === module15Data.start_year ) {
      if ( module15Data.tuition !== undefined && module15Data.tuition !== '' ) {
        collegeTuitionValue3 = -( module15Data.tuition );
        conditionCheck3 = 1;
      } else {
        collegeTuitionValue3 = 0;
      }
    } else if ( module15Data.start_year !== undefined && module15Data.start_year !== ''
                         && module15Data.graduation_year !== undefined && module15Data.graduation_year !== ''
                        && year >= module15Data.start_year && year <= module15Data.graduation_year ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        collegeTuitionValue3 = collegeTuitionValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck3 = 2;
      } else {
        collegeTuitionValue3 = 0;
      }
    }
    collegeTuitionValue = collegeTuitionValue1 + collegeTuitionValue2 + collegeTuitionValue3;

    taxStatementCompleteData[year]['Higher Education Tuition and Fees Value1'] = collegeTuitionValue1;
    taxStatementCompleteData[year]['Higher Education Tuition and Fees Value2'] = collegeTuitionValue2;
    taxStatementCompleteData[year]['Higher Education Tuition and Fees Value3'] = collegeTuitionValue3;

    taxStatementCompleteData[year]['Higher Education Tuition and Fees Value1 Condition'] = conditionCheck;
    taxStatementCompleteData[year]['Higher Education Tuition and Fees Value2 Condition'] = conditionCheck2;
    taxStatementCompleteData[year]['Higher Education Tuition and Fees Value3 Condition'] = conditionCheck3;

    if ( studentLoanTaxData !== undefined && studentLoanTaxData['Summation Data'][year] !== undefined && studentLoanTaxData['Summation Data'][year] !== '' ) {
      adjustmentsStudentLoanInterestValue = studentLoanTaxData['Summation Data'][year];
    } else {
      adjustmentsStudentLoanInterestValue = 0;
    }
    taxStatementCompleteData[year].Adjustments['Student Loan Interest'] = adjustmentsStudentLoanInterestValue;

    // Calculate Income Taxes !C23
    if ( inputTaxesData !== undefined && inputTaxesData !== '' && inputTaxesData['Medicare Taxes'][year] !== undefined && inputTaxesData['Medicare Taxes'][year] !== '' && inputTaxesData['Medicare Taxes'][year]['Total Medicare Taxes'] !== undefined && inputTaxesData['Medicare Taxes'][year]['Total Medicare Taxes'] !== '' ) {
      incomeTaxesMedicareValue = inputTaxesData['Medicare Taxes'][year]['Total Medicare Taxes'];
    } else {
      incomeTaxesMedicareValue = 0;
    }
	
	

    // Self Employment Tax
    selfEmployementTaxValue = 0;
    if ( databaseData !== undefined && databaseData['Social Security Income Tax Limit'] !== undefined && databaseData['Social Security Income Tax Limit'][year-1] !== undefined && databaseData['Social Security Income Tax Limit'][year-1].Limit !== undefined && databaseData['Social Security Income Tax Limit'][year-1].Limit !== '' && taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] > databaseData['Social Security Income Tax Limit'][year-1].Limit ) {
	  selfEmployementTaxValue2 = ( databaseData['Social Security Income Tax Limit'][year-1].Limit / 100 ) * socialSecurityTaxesValue;
    } else {
      selfEmployementTaxValue2 = ( taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] / 100 ) * socialSecurityTaxesValue;
    }
	
	

    if ( module4Data !== undefined && module4Data.start_year !== undefined && module4Data.end_year !== undefined && year >= module4Data.start_year && year <= module4Data.end_year && module4Data.self_employment !== undefined && module4Data.self_employment === 'Yes' ) {
      selfEmployementTaxValue = ( incomeTaxesMedicareValue + selfEmployementTaxValue2 ) / 2;
    } else {
		if ( module5Data !== undefined && module5Data.start_year !== undefined && module5Data.end_year !== undefined && year >= module5Data.start_year && year <= module5Data.end_year && module5Data.self_employment !== undefined && module5Data.self_employment === 'Yes' ) {
	  	selfEmployementTaxValue = ( incomeTaxesMedicareValue + selfEmployementTaxValue2 ) / 2;
		} else{
			if ( module8Data !== undefined && module8Data.start_year !== undefined && module8Data.end_year !== undefined && year >= module8Data.start_year && year <= module8Data.end_year && module8Data.self_employment !== undefined && module8Data.self_employment === 'Yes' ) {
			  selfEmployementTaxValue = ( incomeTaxesMedicareValue + selfEmployementTaxValue2 ) / 2;
			} else{
				if ( module17Data !== undefined && module17Data.start_year !== undefined && module17Data.end_year !== undefined && year >= module17Data.start_year && year <= module17Data.end_year && module17Data.self_employment !== undefined && module17Data.self_employment === 'Yes' ) {
				  selfEmployementTaxValue = ( incomeTaxesMedicareValue + selfEmployementTaxValue2 ) / 2;
				} else {
				  selfEmployementTaxValue = 0;
				}
			}
		}
	}
    taxStatementCompleteData[year].Adjustments['Self-Employment Tax'] = selfEmployementTaxValue;
	
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['HSA Second'] !== 'undefined' &&

		typeof investmentsData['HSA Second'][year] !=='undefined' &&
		typeof investmentsData['HSA Second'][year]['Annual Contribution'] !== 'undefined') {
		healthSavingAccountContributionsValue = investmentsData['HSA Second'][year]['Annual Contribution'];
	} else{
		healthSavingAccountContributionsValue = 0;	
	}
	
	taxStatementCompleteData[year].Adjustments['Health Savings Account (HSA) Contributions'] = healthSavingAccountContributionsValue;

    // Total Adjustments
    taxStatementCompleteData[year]['Total Adjustments'] = parseFloat( adjustments401kValue )
                + parseFloat( adjustmentsStudentLoanInterestValue )
                + parseFloat( selfEmployementTaxValue ) 
				+ parseFloat( healthSavingAccountContributionsValue );
    totalAdjustmentsGraph.push( taxStatementCompleteData[year]['Total Adjustments'] );

    // Adjusted Gross Income
    if ( parseFloat( taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] )
          - parseFloat( taxStatementCompleteData[year]['Total Adjustments'] ) < 0 ) {
      adjustedGrossIncomeValue = 0;
    } else {
      adjustedGrossIncomeValue = parseFloat( taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] )
                        - parseFloat( taxStatementCompleteData[year]['Total Adjustments'] );
    }
    taxStatementCompleteData[year]['Adjusted Gross Income'] = adjustedGrossIncomeValue;
    adjustmentsGrossGraph.push( taxStatementCompleteData[year]['Adjusted Gross Income'] );
    /** End of Adjustements & Total Adjustments & Adjusted Gross Income * */

    /** **********************
                 ** Itemized Deductions **
                 ************************ */
    // Home Mortgage Interest, Points, & PMI
    itemizedmortgageValue = 0;
    let itemizedmortgageValue1 = 0;
    let itemizedmortgageValue2 = 0;
    let itemizedmortgageValue3 = 0;
    let itemizedmortgageValue5 = 0;
    let itemizedmortgageValue8 = 0;
    let firstSectionval1 = 0;
    let firstSectionval2 = 0;
    let firstSectionval3 = 0;

    if ( mortgage1Data !== undefined
                     && mortgage1Data['Summation Data'] !== undefined
                     && mortgage1Data['Summation Data'][year] !== undefined && mortgage1Data['Summation Data'][year].BeginningBalance !== undefined && mortgage1Data['Summation Data'][year].EndingBalance !== undefined ) {
      firstSectionval1 = ( mortgage1Data['Summation Data'][year].BeginningBalance + mortgage1Data['Summation Data'][year].EndingBalance ) / 2;
    }

    if ( existingHomeMortgageData !== undefined
                     && existingHomeMortgageData['Summation Data'] !== undefined
                     && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].BeginningBalance !== undefined && existingHomeMortgageData['Summation Data'][year].EndingBalance !== undefined ) {
      firstSectionval2 = ( existingHomeMortgageData['Summation Data'][year].BeginningBalance + existingHomeMortgageData['Summation Data'][year].EndingBalance ) / 2;
    }

    // Existing Home Mortgage
    if ( existingHomeMortgageData !== undefined
                   && existingHomeMortgageData !== ''
                    && existingHomeMortgageData['Summation Data'] !== undefined
                    && existingHomeMortgageData['Summation Data'][year] !== undefined
                   && existingHomeMortgageData['Summation Data'][year].LaggingInterest !== '' ) {
      itemizedmortgageValue2 = existingHomeMortgageData['Summation Data'][year].LaggingInterest;
    } else {
      itemizedmortgageValue2 = 0;
    }

    if ( mortgage1Data !== undefined
                     && mortgage1Data['Summation Data'] !== undefined
                     && mortgage1Data['Summation Data'][year] !== undefined
                     && buyingAHomeData !== undefined
                     && buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== undefined ) {
      if ( year === module13Data.year_of_purchase ) {
        itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest
                        + buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
      } else {
        itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest;
      }
    } else {
      itemizedmortgageValue1 = 0;
    }

    if ( databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== '' && databaseData.Data['Mortgage Interest Cap'] !== undefined && databaseData.Data['Mortgage Interest Cap'] !== '' && ( firstSectionval2 + firstSectionval1 ) > databaseData.Data['Mortgage Interest Cap'] ) {
      firstSectionval3 = databaseData.Data['Mortgage Interest Cap'] / ( firstSectionval1 + firstSectionval2 );
      itemizedmortgageValue8 = firstSectionval3 * ( itemizedmortgageValue1 + itemizedmortgageValue2 );
    } else {
      if ( year === module13Data.year_of_purchase ) {
        if ( mortgage1Data !== undefined && mortgage1Data !== '' && mortgage1Data['Summation Data'] !== undefined && mortgage1Data['Summation Data'] !== '' && mortgage1Data['Summation Data'][year] !== undefined && mortgage1Data['Summation Data'][year] !== ''
                             && buyingAHomeData !== undefined && buyingAHomeData !== '' && buyingAHomeData['Closing Cost'] !== undefined && buyingAHomeData['Closing Cost'] !== '' && buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== undefined ) {
          itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest
                            + buyingAHomeData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
        } else {
          itemizedmortgageValue1 = 0;
        }
      } else if ( mortgage1Data !== undefined && mortgage1Data !== '' && mortgage1Data['Summation Data'] !== undefined && mortgage1Data['Summation Data'] !== ''
                             && mortgage1Data['Summation Data'][year] !== undefined && mortgage1Data['Summation Data'][year] !== '' && mortgage1Data['Summation Data'][year].LaggingInterest !== undefined ) {
        itemizedmortgageValue1 = mortgage1Data['Summation Data'][year].LaggingInterest;
      } else {
        itemizedmortgageValue1 = 0;
      }
      itemizedmortgageValue8 = itemizedmortgageValue1 + itemizedmortgageValue2;
    }

    // Buying a Home D25
    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year >= module10Data.marriege_year ) {
      if ( databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined
                         && databaseData.Data['PMI Deduction Third'] !== undefined
                        && taxStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction Third']
                         && buyingAHomeData !== undefined && buyingAHomeData.Data1[year] !== undefined
                         && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined ) {
        itemizedmortgageValue3 = buyingAHomeData.Data1[year]['Private Mortgage Insurance'] + existingHomeData.Data1[year]['Private Mortgage Insurance'];
      } else if ( databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined
                             && databaseData.Data['PMI Deduction'] !== undefined
                            && taxStatementCompleteData[year]['Adjusted Gross Income'] > databaseData.Data['PMI Deduction Third']
                            && taxStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction Fourth']
                             && buyingAHomeData !== undefined && buyingAHomeData.Data1[year] !== undefined
                             && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined ) {
        itemizedmortgageValue3 = ( Math.ceil( taxStatementCompleteData[year]['Adjusted Gross Income'] - databaseData.Data['PMI Deduction Third'], 1000 ) / 10000 ) * ( buyingAHomeData.Data1[year]['Private Mortgage Insurance'] * existingHomeData.Data1[year]['Private Mortgage Insurance'] );
      } else if ( databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data['PMI Deduction'] !== undefined && taxStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction']
        && buyingAHomeData !== undefined && buyingAHomeData.Data1[year] !== undefined && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined
        && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined ) {
        itemizedmortgageValue3 = buyingAHomeData.Data1[year]['Private Mortgage Insurance'] + existingHomeData.Data1[year]['Private Mortgage Insurance'];
      } else if ( databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data['PMI Deduction'] !== undefined && taxStatementCompleteData[year]['Adjusted Gross Income'] > databaseData.Data['PMI Deduction'] && taxStatementCompleteData[year]['Adjusted Gross Income'] <= databaseData.Data['PMI Deduction Second'] && buyingAHomeData !== undefined && buyingAHomeData.Data1[year] !== undefined
        && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined && existingHomeData !== undefined && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined ) {
        itemizedmortgageValue3 = ( Math.ceil( taxStatementCompleteData[year]['Adjusted Gross Income'] - databaseData.Data['PMI Deduction'], 500 ) / 5000 ) * ( buyingAHomeData.Data1[year]['Private Mortgage Insurance'] + existingHomeData.Data1[year]['Private Mortgage Insurance'] );
      } else {
        itemizedmortgageValue3 = 0;
      }
    } else {
      itemizedmortgageValue3 = 0;
    }

    // Second Section
    if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== '' && year === module13Data.year_of_purchase && buyingAHomeData !== undefined && buyingAHomeData['Closing Cost'] !== undefined && buyingAHomeData['Closing Cost']['Loan Acquisition Points'] !== undefined && buyingAHomeData['Closing Cost']['Origination Points'] !== undefined ) {
      itemizedmortgageValue5 = buyingAHomeData['Closing Cost']['Loan Acquisition Points'] + buyingAHomeData['Closing Cost']['Origination Points'];
    } else {
      itemizedmortgageValue5 = 0;
    }

    itemizedmortgageValue = itemizedmortgageValue5 + itemizedmortgageValue8 + itemizedmortgageValue3;

    taxStatementCompleteData[year]['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI'] = itemizedmortgageValue;

    /**

                Redirect To State and Local Taxes in Taxes
                */
    // State Local Taxes
    if ( inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== 0 ) {
      stateLocalTaxesValue3 = inputTaxesData['State Local Taxes'][year]['State tax'];
    }
    if ( inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== 0 ) {
      stateLocalTaxesValue4 = inputTaxesData['State Local Taxes'][year]['City tax'];
    }
    stateLocalTaxesValue5 = ( stateLocalTaxesValue3 + stateLocalTaxesValue4 ) * 100;

    // Home Property Taxes
	if ( buyingAHomeData !== undefined && buyingAHomeData !== '' && buyingAHomeData['Real Estate'] !== undefined && buyingAHomeData['Real Estate'][year] !== undefined && buyingAHomeData['Real Estate'][year]['Property Tax'] !== undefined ) {
        intemizedHomePropertyTaxesValue1 = buyingAHomeData['Real Estate'][year]['Property Tax'];
	} else {
	    intemizedHomePropertyTaxesValue1 = 0;
	 }
	 if ( existingHomeData !== undefined && existingHomeData !== '' && existingHomeData.Data1 !== undefined && existingHomeData.Data1 !== '' && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year] !== '' && existingHomeData.Data1[year]['Property Taxes'] !== undefined ) {
	  intemizedHomePropertyTaxesValue2 = existingHomeData.Data1[year]['Property Taxes'] + ( parseFloat( adjustedGrossIncomeValue ) * ( stateLocalTaxesValue5 / 100 ) );
	} else {
      intemizedHomePropertyTaxesValue2 = 0;
    }
	intemizedHomePropertyTaxesValue = intemizedHomePropertyTaxesValue1 + intemizedHomePropertyTaxesValue2;
	
    if ( databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== '' && databaseData.Data['SALT Limit'] !== undefined && databaseData.Data['SALT Limit'] !== '' && intemizedHomePropertyTaxesValue > databaseData.Data['SALT Limit'] ) {
      deductionsHomePropertyTaxesValue = databaseData.Data['SALT Limit'];
    } else {
      deductionsHomePropertyTaxesValue = intemizedHomePropertyTaxesValue;
    }
    taxStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'] = deductionsHomePropertyTaxesValue;
	

    // Health Insurance Costs outside Premium
    let healthInsuranceCheck = 0;
    if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB7
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Costs outside Premium'];

        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue10 = healthInsuranceDeductibleValue * ( assumptionsData[year].Expenses / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue10 = 0;
      }
    } else {
      healthInsuranceDeductibleValue10 = 0;
    }
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Costs outside Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue11 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue11 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue11 = 0;
      }
    } else {
      healthInsuranceDeductibleValue11 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
             && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
             && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Costs outside Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue12 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue12 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue12 = 0;
      }
    } else {
      healthInsuranceDeductibleValue12 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Costs outside Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue13 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;

        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue13 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue13 = 0;
      }
    } else {
      healthInsuranceDeductibleValue13 = 0;
    }
    if ( year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB90
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Costs outside Premium'];
        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue14 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue14 = 0;
      }
    } else {
      healthInsuranceDeductibleValue14 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Costs outside Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue15 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue15 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue15 = 0;
      }
    } else {
      healthInsuranceDeductibleValue15 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Costs outside Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue16 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;

        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue16 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue16 = 0;
      }
    } else {
      healthInsuranceDeductibleValue16 = 0;
    }
    if ( year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB152
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Costs outside Premium'];
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue17 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        healthInsuranceDeductibleValue17 = 0;
      }
    } else {
      healthInsuranceDeductibleValue17 = 0;
    }
    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB172
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Costs outside Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue18 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;
        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue18 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue18 = 0;
      }
    } else {
      healthInsuranceDeductibleValue18 = 0;
    }
    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
             && expenseCalculationsData['Further Career Advancement'] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Costs outside Premium'] !== undefined ) {
        healthInsuranceDeductibleValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Costs outside Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
          healthInsuranceDeductibleValue2 = module10Data.health_insurance_costs_multiplier;
        } else {
          healthInsuranceDeductibleValue2 = 1;
        }
        healthInsuranceDeductibleValue19 = healthInsuranceDeductibleValue1 * healthInsuranceDeductibleValue2;

        healthInsuranceCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsuranceDeductibleValue19 = healthInsuranceDeductibleValue * ( ( assumptionsData[year].Expenses ) / 100 );
        healthInsuranceCheck = 2;
      } else {
        healthInsuranceDeductibleValue19 = 0;
      }
    } else {
      healthInsuranceDeductibleValue19 = 0;
    }
    healthInsuranceDeductibleValue = healthInsuranceDeductibleValue10 + healthInsuranceDeductibleValue11 + healthInsuranceDeductibleValue12 + healthInsuranceDeductibleValue13 + healthInsuranceDeductibleValue14 + healthInsuranceDeductibleValue15 + healthInsuranceDeductibleValue16 + healthInsuranceDeductibleValue17 + healthInsuranceDeductibleValue18 + healthInsuranceDeductibleValue19;

    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' ) {
      healthInsuranceDeductibleValue3 = module10Data.health_insurance_costs_multiplier;
    } else {
      healthInsuranceDeductibleValue3 = 1;
    }
    healthInsuranceDeductibleValue *= healthInsuranceDeductibleValue3;

    // Health Insurance Premium
    if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB7
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly['Health Insurance Premium'];
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue10 = healthInsurancePremiumValue * ( assumptionsData[year].Expenses / 100 );
      } else {
        healthInsurancePremiumValue10 = 0;
      }
    } else {
      healthInsurancePremiumValue10 = 0;
    }
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly['Health Insurance Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue11 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue11 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        healthInsurancePremiumValue11 = 0;
      }
    } else {
      healthInsurancePremiumValue11 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
                                 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
                                 && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly['Health Insurance Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue12 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue12 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        healthInsurancePremiumValue12 = 0;
      }
    } else {
      healthInsurancePremiumValue12 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
                                     && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
                                     && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly['Health Insurance Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue13 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue13 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        healthInsurancePremiumValue13 = 0;
      }
    } else {
      healthInsurancePremiumValue13 = 0;
    }
    if ( year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB90
                                         && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
                                         && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly['Health Insurance Premium'];
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue14 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        healthInsurancePremiumValue14 = 0;
      }
    } else {
      healthInsurancePremiumValue14 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
                                             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
                                             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly['Health Insurance Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue15 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue15 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        healthInsurancePremiumValue15 = 0;
      }
    } else {
      healthInsurancePremiumValue15 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
                                                 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
                                                 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly['Health Insurance Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue16 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue16 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        healthInsurancePremiumValue16 = 0;
      }
    } else {
      healthInsurancePremiumValue16 = 0;
    }
    if ( year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB152
                         && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
                         && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly['Health Insurance Premium'];
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue17 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        healthInsurancePremiumValue17 = 0;
      }
    } else {
      healthInsurancePremiumValue17 = 0;
    }

    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB172
                                                     && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
                                                     && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
                                                     && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly['Health Insurance Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue18 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue18 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
      } else {
        healthInsurancePremiumValue18 = 0;
      }
    } else {
      healthInsurancePremiumValue18 = 0;
    }
    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
                                                         && expenseCalculationsData['Further Career Advancement'] !== undefined
                                                         && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
                                                         && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Premium'] !== undefined ) {
        healthInsurancePremiumValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly['Health Insurance Premium'];
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
          healthInsurancePremiumValue2 = module10Data.health_insurance_premium_multiplier;
        } else {
          healthInsurancePremiumValue2 = 1;
        }
        healthInsurancePremiumValue19 = healthInsurancePremiumValue1 * healthInsurancePremiumValue2;

        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        healthInsurancePremiumValue19 = healthInsurancePremiumValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        healthInsurancePremiumValue19 = 0;
      }
    } else {
      healthInsurancePremiumValue19 = 0;
    }

    healthInsurancePremiumValue = healthInsurancePremiumValue10 + healthInsurancePremiumValue11 + healthInsurancePremiumValue12 + healthInsurancePremiumValue13 + healthInsurancePremiumValue14 + healthInsurancePremiumValue15 + healthInsurancePremiumValue16 + healthInsurancePremiumValue17 + healthInsurancePremiumValue18 + healthInsurancePremiumValue19;

    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' ) {
      healthInsurancePremiumValue3 = module10Data.health_insurance_premium_multiplier;
    } else {
      healthInsurancePremiumValue3 = 1;
    }
    healthInsurancePremiumValue *= healthInsurancePremiumValue3;

    // Health Insurance Value
    // if( -( parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue) ) > ( ( parseFloat(adjustedGrossIncomeValue) * 10 ) / 100 ) ){
    //     intemizedHealthInsuranceValue = -( parseFloat(healthInsuranceDeductibleValue) + parseFloat(healthInsurancePremiumValue) ) - ( ( parseFloat(adjustedGrossIncomeValue) * 10 ) / 100 ) ;
    // } else {
    //     intemizedHealthInsuranceValue = 0;
    // }

    // Health Insurance Value
    if ( databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== ''
                     && databaseData.Data['Health Insurance Itemized Deduct Year'] !== undefined && databaseData.Data['Health Insurance Itemized Deduct Year'] !== '' ) {
      if ( year === databaseData.Data['Health Insurance Itemized Deduct Year'] ) {
        if ( -( parseFloat( healthInsuranceDeductibleValue ) + parseFloat( healthInsurancePremiumValue ) ) > ( ( databaseData.Data['Health Insurance Itemized Deduct Rate'] / 100 ) * adjustedGrossIncomeValue ) ) {
          intemizedHealthInsuranceValue = -( parseFloat( healthInsuranceDeductibleValue ) + parseFloat( healthInsurancePremiumValue ) ) - ( ( databaseData.Data['Health Insurance Itemized Deduct Rate'] / 100 ) * adjustedGrossIncomeValue );
        } else {
          intemizedHealthInsuranceValue = 0;
        }
      } else if ( -( parseFloat( healthInsuranceDeductibleValue ) + parseFloat( healthInsurancePremiumValue ) ) > ( ( databaseData.Data['Health Insurance Itemized Deduct Rate Second'] / 100 ) * adjustedGrossIncomeValue ) ) {
        intemizedHealthInsuranceValue = -( parseFloat( healthInsuranceDeductibleValue ) + parseFloat( healthInsurancePremiumValue ) ) - ( ( databaseData.Data['Health Insurance Itemized Deduct Rate Second'] / 100 ) * adjustedGrossIncomeValue );
      } else {
        intemizedHealthInsuranceValue = 0;
      }
    }
    taxStatementCompleteData[year]['Itemized Deductions']['Health Insurance'] = intemizedHealthInsuranceValue;

    // Charitable Contributions Value
    if ( module9Data !== undefined && module9Data !== '' && module9Data.charitable_start_year !== undefined && module9Data.charitable_end_year !== undefined
                    && year >= module9Data.charitable_start_year
                    && year <= module9Data.charitable_end_year ) {
      const charitableContri = module9Data.charitable_yearly_contribution;
      if ( databaseData !== undefined && databaseData !== '' && databaseData['Deduction Limit'] !== undefined && databaseData['Deduction Limit'][year] !== undefined && databaseData['Deduction Limit'][year] !== '' && charitableContri > databaseData['Deduction Limit'][year] ) {
        intemizedCharitableContributionsValue = ( incomeStatementData[year]['Total Income'] / 100 ) * databaseData['Deduction Limit'][year];
      } else if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[year] !== undefined && cashFlowData[year] !== '' && cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'] !== undefined && cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'] !== '' ) {
        intemizedCharitableContributionsValue = cashFlowData[year]['Cash from Investing Activities']['Charitable Contributions'];
      }
    } else {
      intemizedCharitableContributionsValue = 0;
    }
    taxStatementCompleteData[year]['Itemized Deductions']['Charitable Contributions'] = -intemizedCharitableContributionsValue;

    // State & Local Taxes

    if ( inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['State tax'] !== 0 ) {
      stateLocalTaxesValue1 = inputTaxesData['State Local Taxes'][year]['State tax'];
    }
    if ( inputTaxesData !== undefined && inputTaxesData['State Local Taxes'] !== undefined && inputTaxesData['State Local Taxes'][year] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== undefined && inputTaxesData['State Local Taxes'][year]['City tax'] !== 0 ) {
      stateLocalTaxesValue2 = inputTaxesData['State Local Taxes'][year]['City tax'];
    }
    stateLocalTaxesValue = ( stateLocalTaxesValue1 + stateLocalTaxesValue2 ) * 100;

    // State and Local Taxes
    // taxStatementCompleteData[year]['Itemized Deductions']['State and Local Taxes'] = ( parseFloat(adjustedGrossIncomeValue) * stateLocalTaxesValue ) / 100 ;
    /** End of Itemized Deductions * */

    /** *********************
                 ** Standard Deduction **
                 *********************** */
    if ( module10Data.marriege_year !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
      if ( databaseData !== undefined && databaseData !== '' && databaseData['Standard Deductions'] !== undefined && databaseData['Standard Deductions'] !== '' && databaseData['Standard Deductions'][year] !== undefined && databaseData['Standard Deductions'][year] !== '' && databaseData['Standard Deductions'][year].Married !== undefined && databaseData['Standard Deductions'][year].Married !== '' ) {
        standardDeductionsValue = databaseData['Standard Deductions'][year].Married;
      } else {
        standardDeductionsValue = 0;
      }
    } else if ( databaseData !== undefined && databaseData !== '' && databaseData['Standard Deductions'] !== undefined && databaseData['Standard Deductions'] !== '' && databaseData['Standard Deductions'][year] !== undefined && databaseData['Standard Deductions'][year] !== '' && databaseData['Standard Deductions'][year].Single !== undefined && databaseData['Standard Deductions'][year].Single !== '' ) {
      standardDeductionsValue = databaseData['Standard Deductions'][year].Single;
    } else {
      standardDeductionsValue = 0;
    }
    taxStatementCompleteData[year]['Standard Deduction'] = standardDeductionsValue;
    StandardDeductionsGraph.push( taxStatementCompleteData[year]['Standard Deduction'] );

    /** *********************
                 ** Total Itemized Deductions **
                 *********************** */
    totalItemizedDeductionsValue = taxStatementCompleteData[year]['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI']
                + taxStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)']
                + taxStatementCompleteData[year]['Itemized Deductions']['Health Insurance']
                + taxStatementCompleteData[year]['Itemized Deductions']['Charitable Contributions'];
    // taxStatementCompleteData[year]['Itemized Deductions']['State and Local Taxes']

    /* if( totalItemizedDeductionsValue1 > standardDeductionsValue ){
                    totalItemizedDeductionsValue = totalItemizedDeductionsValue1 ;
                }
                else{
                    totalItemizedDeductionsValue = 0 ;
                } */
    taxStatementCompleteData[year]['Total Itemized Deductions'] = totalItemizedDeductionsValue;
    itemizedDeductionsGraph.push( taxStatementCompleteData[year]['Total Itemized Deductions'] );

    /** *************
                 ** Exemptions **
                 *************** */
    // DELETED IN NEW TAXES UPDATES
    /* let newVal = 0;
                if( databaseData !== undefined &&
                     databaseData['Personal Exemptions'] !== undefined &&
                     databaseData['Personal Exemptions'][year] !== undefined &&
                     databaseData['Personal Exemptions'][year]['Total Exemptions2'] !== undefined) {
                    newVal = databaseData['Personal Exemptions'][year]['Total Exemptions2'];
                }
                taxStatementCompleteData[year]['Personal Exemptions'] = newVal;
                taxStatementCompleteData[year]['Dependent Exemptions'] = newVal; */
    /** End of Exemptions * */

    /** *******************
                 ** Total Deductions **
                 ********************* */
    sumOfitemizedProducts = parseFloat( taxStatementCompleteData[year]['Itemized Deductions']['Home Mortgage Interest, Loan Points & Origination Fees, & PMI'] )
                + parseFloat( taxStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes, State & Local Income Taxes (SALT)'] )
                + parseFloat( taxStatementCompleteData[year]['Itemized Deductions']['Health Insurance'] )
                + parseFloat( taxStatementCompleteData[year]['Itemized Deductions']['Charitable Contributions'] );
    // parseFloat(taxStatementCompleteData[year]['Itemized Deductions']['State and Local Taxes']);
    if ( sumOfitemizedProducts > standardDeductionsValue ) {
      totalDeductionValue = parseFloat( sumOfitemizedProducts );
      // + parseFloat(taxStatementCompleteData[year]['Personal Exemptions'])
    } else {
      totalDeductionValue = parseFloat( standardDeductionsValue );
      // + parseFloat(taxStatementCompleteData[year]['Personal Exemptions'])
    }
    taxStatementCompleteData[year]['Total Deductions'] = totalDeductionValue;
    totalDeductionsGraph.push( taxStatementCompleteData[year]['Total Deductions'] );
    /** End of Total Deductions * */

    /** ***************************
                 ** REI Property Deductions **
                 **************************** */
    // REI Property Mortgage Interest, Points, & Depreciation
    let intemizedpointsmortgageValue = 0;
    let intemizedpointsmortgageValue1 = 0;
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Closing Cost'] !== undefined
                     && invPropertyRoiData['Closing Cost']['Loan Acquisition Points'] !== undefined
           && invPropertyRoiData['Closing Cost']['Origination Points'] !== undefined
                     && module14Data !== undefined && module14Data !== ''
                     && module14Data.year_of_purchase !== undefined
                    && year === parseFloat( module14Data.year_of_purchase ) - 1 ) {
      intemizedpointsmortgageValue1 = invPropertyRoiData['Closing Cost']['Loan Acquisition Points'] + invPropertyRoiData['Closing Cost']['Origination Points'];
    } else {
      intemizedpointsmortgageValue1 = 0;
    }
    let intemizedpointsmortgageValue2 = 0;
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Closing Cost'] !== undefined
                     && invPropertyRoiData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] !== undefined
                     && module14Data !== undefined && module14Data !== ''
                     && module14Data.year_of_purchase !== undefined
                     && mortgage2Data !== undefined
                     && mortgage2Data['Summation Data'][year] !== undefined
                    && year === parseFloat( module14Data.year_of_purchase ) ) {
      intemizedpointsmortgageValue2 = mortgage2Data['Summation Data'][year].LaggingInterest
                    + invPropertyRoiData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'];
    } else if ( mortgage2Data !== undefined
                         && mortgage2Data['Summation Data'][year] !== undefined ) {
      intemizedpointsmortgageValue2 = mortgage2Data['Summation Data'][year].LaggingInterest;
    } else {
      intemizedpointsmortgageValue2 = 0;
    }
    let intemizedpointsmortgageValue3 = 0;
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Real Estate'] !== undefined
                     && invPropertyRoiData['Real Estate'][year] !== undefined
                     && invPropertyRoiData['Real Estate'][year].Home !== undefined ) {
      intemizedpointsmortgageValue3 = invPropertyRoiData['Real Estate'][year].Home;
    } else {
      intemizedpointsmortgageValue3 = 0;
    }
    intemizedpointsmortgageValue = parseFloat( intemizedpointsmortgageValue1 )
                + parseFloat( intemizedpointsmortgageValue2 )
                + parseFloat( intemizedpointsmortgageValue3 );
    if ( intemizedpointsmortgageValue > invPropertyRentalIncomeValue ) {
      intemizedpointsmortgageValue = invPropertyRentalIncomeValue;
    }
    taxStatementCompleteData[year]['REI Property Deductions']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation'] = intemizedpointsmortgageValue;

    // Passive Losses
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== ''
                     && invPropertyRoiData.Data3 !== undefined
                     && invPropertyRoiData.Data3[year] !== undefined
                     && invPropertyRoiData.Data3[year]['Passive Losses Taken'] !== undefined ) {
      intemizedPropertyDepreciationValue = invPropertyRoiData.Data3[year]['Passive Losses Taken'];
    } else {
      intemizedPropertyDepreciationValue = 0;
    }
    taxStatementCompleteData[year]['REI Property Deductions']['Passive Losses'] = intemizedPropertyDepreciationValue;

    // REI Property Deductions Total
    totalREIPropertyDeductions = taxStatementCompleteData[year]['REI Property Deductions']['Mortgage Interest, Loan Points & Origination Fees, & Depreciation']
                + taxStatementCompleteData[year]['REI Property Deductions']['Passive Losses'];
    taxStatementCompleteData[year]['REI Property Deductions Total'] = totalREIPropertyDeductions;

    /** End of REI Property Deductions * */

    /** *************************
                 ** Federal Taxable Income **
                 *************************** */
    if ( ( ( adjustedGrossIncomeValue + taxStatementCompleteData[year]['REI Property Taxable Income (Passive Losses Taken)'] ) - totalDeductionValue ) < 0 ) {
      federalTaxableIncomeValue = 0;
    } else {
      federalTaxableIncomeValue = parseFloat( adjustedGrossIncomeValue ) + taxStatementCompleteData[year]['REI Property Taxable Income (Passive Losses Taken)']
                    - parseFloat( totalDeductionValue );
    }
    taxStatementCompleteData[year]['Federal Taxable Income'] = federalTaxableIncomeValue;
    federalTaxableIncomeGraph.push( taxStatementCompleteData[year]['Federal Taxable Income'] );
    /** End of Federal Taxable Income * */

    /** ***********************
                 ** Taxes & Total Taxes **
                 ************************ */
    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
                    && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
      if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                             && inputTaxesData['Federal Income'][year] !== undefined
                             && inputTaxesData['Federal Income'][year].Married !== undefined
                             && inputTaxesData['Federal Income'][year].Married['Federal Income Tax'] !== undefined ) {
        effectiveTaxRateValue = inputTaxesData['Federal Income'][year].Married['Federal Income Tax'];
      } else {
        effectiveTaxRateValue = 0;
      }
    } else if ( inputTaxesData !== undefined && inputTaxesData['Federal Income'] !== undefined
                         && inputTaxesData['Federal Income'][year] !== undefined
                         && inputTaxesData['Federal Income'][year].Single !== undefined
                         && inputTaxesData['Federal Income'][year].Single['Federal Income Tax'] !== undefined ) {
      effectiveTaxRateValue = inputTaxesData['Federal Income'][year].Single['Federal Income Tax'];
    } else {
      effectiveTaxRateValue = 0;
    }
    // Federal Income Tax
    taxStatementCompleteData[year].taxes['Federal Income Tax (x Federal Taxable Income)'] = effectiveTaxRateValue;

    // Social Security
    taxStatementCompleteData[year].taxes['Social Security Tax (x Total Gross Income with limits)'] = socialSecurityTaxesValue;
    taxStatementCompleteData[year]['Social Security Tax Condition'] = socialconditionCheck;

    // Medicare Taxes
    taxStatementCompleteData[year].taxes['Medicare Tax (x Total Gross Income with limits)'] = medicareTaxesValue;
    taxStatementCompleteData[year]['Medicare Tax Condition'] = medicareconditionCheck;

    // Additional Medicare Tax (x Total Gross Income in excess of limits)
    if ( inputTaxesData !== undefined && inputTaxesData['Medicare Taxes'] !== undefined
           && inputTaxesData['Medicare Taxes'][year] !== undefined
           && inputTaxesData['Medicare Taxes'][year]['Addn Medicare Tax Rate'] !== '' ) {
      additionalMedicareTaxesValue = inputTaxesData['Medicare Taxes'][year]['Addn Medicare Tax Rate'];
    } else {
      additionalMedicareTaxesValue = 0;
    }
    taxStatementCompleteData[year].taxes['Additional Medicare Tax (x Total Gross Income in excess of limits)'] = additionalMedicareTaxesValue;

    // State and Local taxes (x Adjusted Gross Income)
    taxStatementCompleteData[year].taxes['State & Local Taxes (x Adjusted Gross Income)'] = stateLocalTaxesValue5;

    // Total Taxes
    let value1 = 0;
    if ( effectiveTaxRateValue !== 0 ) {
      value1 = ( effectiveTaxRateValue / 100 ) * federalTaxableIncomeValue;
    }
    let value3 = 0;
    if ( stateLocalTaxesValue !== 0 ) {
      value3 = ( stateLocalTaxesValue / 100 ) * adjustedGrossIncomeValue;
    }
    let lastValue = 0;
    if ( databaseData !== undefined
           && databaseData !== ''
            && databaseData['Social Security Income Tax Limit'] !== undefined
           && databaseData['Social Security Income Tax Limit'] !== ''
            && databaseData['Social Security Income Tax Limit'][year] !== undefined
           && databaseData['Social Security Income Tax Limit'][year] !== ''
            && databaseData['Social Security Income Tax Limit'][year].Limit !== undefined
           && databaseData['Social Security Income Tax Limit'][year].Limit !== ''
           && taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] > databaseData['Social Security Income Tax Limit'][year].Limit ) {
      lastValue = databaseData['Social Security Income Tax Limit'][year].Limit * ( socialSecurityTaxesValue / 100 );
    } else {
      lastValue = ( socialSecurityTaxesValue / 100 ) * taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
    }
    // Final Value
    let finalValue = 0;
    if ( inputTaxesData !== undefined && inputTaxesData['Medicare Taxes'] !== undefined
           && inputTaxesData['Medicare Taxes'][year] !== undefined
           && inputTaxesData['Medicare Taxes'][year]['Total Medicare Taxes'] !== '' ) {
      finalValue = inputTaxesData['Medicare Taxes'][year]['Total Medicare Taxes'];
    }
	taxStatementCompleteData[year]['Total Taxes'] = -( value1
                    + value3
                    + lastValue
          + finalValue );
    totalTaxesBeforeCreditsGraph.push( taxStatementCompleteData[year]['Total Taxes'] );

    // Total Tax Rate
    if ( taxStatementCompleteData[year]['Total Taxes'] !== 0 && taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 0 ) {
      taxStatementCompleteData[year].taxes['Total Tax Rate'] = ( -( taxStatementCompleteData[year]['Total Taxes'] ) / ( taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] ) ) * 100;
    } else {
      taxStatementCompleteData[year].taxes['Total Tax Rate'] = 0;
    }
    /** End of Taxes & Total Taxes * */

    /** *********************
                 ** Tax Credits ********
                 *********************** */
    // American Opportunity
    let americanOpportunity = 0;
    if ( databaseData !== undefined && databaseData['American Opportunity'][year] !== undefined ) {
      americanOpportunity = databaseData['American Opportunity'][year]['Tax Credit Applied'];
    }
    let lifeTimeLearning = 0;
    if ( databaseData !== undefined && databaseData['Lifetime Learning'][year] !== undefined ) {
      lifeTimeLearning = databaseData['Lifetime Learning'][year]['Tax Credit Applied'];
    }
    taxStatementCompleteData[year]['Tax Credits']['American Opportunity or Lifetime Learning'] = americanOpportunity + lifeTimeLearning;

    // Child Tax Credit
    let childTaxCredit = 0;
    if ( databaseData !== undefined && databaseData['Child Tax Credit'][year] !== undefined && databaseData['Child Tax Credit'][year]['Child Tax Credit']['Total Tax Credit Applied'] !== undefined ) {
      childTaxCredit = databaseData['Child Tax Credit'][year]['Child Tax Credit']['Total Tax Credit Applied'];
    }
    taxStatementCompleteData[year]['Tax Credits']['Child Tax Credit'] = childTaxCredit;

    // Total Tax Credits
    taxStatementCompleteData[year]['Total Tax Credits'] = taxStatementCompleteData[year]['Tax Credits']['American Opportunity or Lifetime Learning']
                + taxStatementCompleteData[year]['Tax Credits']['Child Tax Credit'];

    totalTaxesCreditsGraph.push( taxStatementCompleteData[year]['Total Tax Credits'] );
    /** End of Tax Credits * */

    /** *********************
                 ** Income After Taxes **
                 *********************** */
    taxStatementCompleteData[year]['Income After Taxes'] = taxStatementCompleteData[year]['Gross Income']['Total Gross Income (excluding Rental Income)']
                + taxStatementCompleteData[year]['Total Taxes']
                + taxStatementCompleteData[year]['Total Tax Credits'];
    incomeAfterTaxesGraph.push( taxStatementCompleteData[year]['Income After Taxes'] );
    /** End of Income After Taxes * */

    /** ****************************
                 ** Retirement Contributions **
                 ***************************** */
    conditionCheck = 0;
    conditionCheck2 = 0;
    conditionCheck3 = 0;
    if ( investmentsData !== undefined
                     && investmentsData['Retirement Accounts 401k'] !== undefined
                     && investmentsData['Retirement Accounts 401k'][year] !== undefined
                     && investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] !== undefined ) {
      retirementContributionsValue1 = investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue1 = 0;
    }
    if ( investmentsData !== undefined
                     && investmentsData['Retirement Accounts Roth IRA'] !== undefined
                     && investmentsData['Retirement Accounts Roth IRA'][year] !== undefined
                     && investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'] !== undefined ) {
      retirementContributionsValue2 = investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue2 = 0;
    }
    if ( investmentsData !== undefined
                     && investmentsData['Retirement Accounts Seph IRA'] !== undefined
                     && investmentsData['Retirement Accounts Seph IRA'][year] !== undefined
                     && investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'] !== undefined ) {
      retirementContributionsValue3 = investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue3 = 0;
    }
    if ( investmentsData !== undefined
                     && investmentsData['Retirement Accounts Pension'] !== undefined
                     && investmentsData['Retirement Accounts Pension'][year] !== undefined
                     && investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'] !== undefined ) {
      retirementContributionsValue4 = investmentsData['Retirement Accounts Pension'][year]['Personal Contribution'];
      conditionCheck = 1;
    } else {
      retirementContributionsValue4 = 0;
    }
    taxStatementCompleteData[year]['Retirement Contributions'] = -( retirementContributionsValue1 + retirementContributionsValue2 + retirementContributionsValue3 + retirementContributionsValue4 );
    taxStatementCompleteData[year]['Retirement Contribution 1'] = conditionCheck;
    taxStatementCompleteData[year]['Retirement Contribution 2'] = conditionCheck2;
    /** End of Retirement Contributions * */

    /** ********************
                 ** Disposable Income **
                 ********************** */
    disposableIncome = taxStatementCompleteData[year]['Income After Taxes'] + taxStatementCompleteData[year]['Retirement Contributions'];
    taxStatementCompleteData[year].disposableIncome = disposableIncome;
    totalDisposableGraph.push( taxStatementCompleteData[year].disposableIncome );
    /** End of Disposable Income * */

    /** ******************
                 ** Living Expenses **
                 ******************** */
    // Higher Education Tuition
    taxStatementCompleteData[year].livingExpenses['Higher Education Tuition and Fees'] = collegeTuitionValue;
    // Higher Education Room & Board
    conditionCheck = 0;
    if ( year === expenseCalculationsB7 && grossIncomeTotal === 0 ) {
      if ( expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Rent !== undefined ) {
        collegeRoomBoardValue = -parseFloat( expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Rent );
        conditionCheck = 1;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else if ( module2Data.start_year !== undefined && module2Data.start_year !== ''
                        && year >= module2Data.start_year && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        collegeRoomBoardValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else if ( year === expenseCalculationsB90 && grossIncomeTotal === 0 ) {
      if ( expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
                                 && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Rent !== undefined ) {
        collegeRoomBoardValue = -parseFloat( expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Rent );
        conditionCheck = 1;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else if ( year >= module6Data.start_year && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        collegeRoomBoardValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else if ( year === expenseCalculationsB152 && grossIncomeTotal === 0 ) {
      if ( expenseCalculationsData['New Higher Education - On-Campus Housing'] !== undefined
                    && expenseCalculationsData['New Higher Education - On-Campus Housing'] !== ''
                     && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
                     && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== ''
                                         && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Rent !== undefined ) {
        collegeRoomBoardValue = -parseFloat( expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Rent );
        conditionCheck = 1;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else if ( year >= module15Data.start_year && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        collegeRoomBoardValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else {
      collegeRoomBoardValue = 0;
    }
    taxStatementCompleteData[year].livingExpenses['Higher Education Room & Board'] = collegeRoomBoardValue;
    taxStatementCompleteData[year]['Higher Education Room & Board Condition'] = conditionCheck;

    // Off Campus Food
    conditionCheck = 0;
    if ( year === expenseCalculationsB7 && grossIncomeTotal === 0 ) {
      if ( expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Food !== undefined ) {
        offCampusFoodValue = -parseFloat( expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Food );
        conditionCheck = 1;
      } else {
        offCampusFoodValue = 0;
      }
    } else if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        offCampusFoodValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        offCampusFoodValue = 0;
      }
    } else if ( year === expenseCalculationsB90 && grossIncomeTotal === 0 ) {
      if ( expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
                                 && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Food !== undefined ) {
        offCampusFoodValue = -parseFloat( expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Food );
        conditionCheck = 1;
      } else {
        offCampusFoodValue = 0;
      }
    } else if ( year >= module6Data.start_year && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        offCampusFoodValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        offCampusFoodValue = 0;
      }
    } else if ( year === expenseCalculationsB152 && grossIncomeTotal === 0 ) {
      if ( expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
                                         && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Food !== undefined ) {
        offCampusFoodValue = -parseFloat( expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Food );
        conditionCheck = 1;
      } else {
        collegeRoomBoardValue = 0;
      }
    } else if ( year >= module15Data.start_year && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        offCampusFoodValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        offCampusFoodValue = 0;
      }
    } else {
      offCampusFoodValue = 0;
    }
    taxStatementCompleteData[year].livingExpenses['Off Campus Food'] = offCampusFoodValue;
    taxStatementCompleteData[year]['Off Campus Food Condition'] = conditionCheck;

    // Rent
    conditionCheck = 0;
    if ( module13Data.year_of_purchase !== undefined ) {
      if ( year < module13Data.year_of_purchase || module13Data.year_of_purchase === '' ) {
        if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
          if ( year === expenseCalculationsB27
                                 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
                                 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Rent !== undefined ) {
            rentValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Rent;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue10 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            rentValue10 = rentValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            rentValue10 = 0;
          }
        } else {
          rentValue10 = 0;
        }

        if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
          if ( year === expenseCalculationsB48
                 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
                 && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Rent !== undefined ) {
            rentValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Rent;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue11 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            rentValue11 = rentValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            rentValue11 = 0;
          }
        } else {
          rentValue11 = 0;
        }
        if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
          if ( year === expenseCalculationsB69
                 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
                 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Rent !== undefined ) {
            rentValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Rent;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue12 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            rentValue12 = rentValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            rentValue12 = 0;
          }
        } else {
          rentValue12 = 0;
        }
        if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
          if ( year === expenseCalculationsB110
                 && expenseCalculationsData['More Higher Education - Off-Campus Housing'] !== undefined
                 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
                 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Rent !== undefined ) {
            rentValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Rent;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue13 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            rentValue13 = rentValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            rentValue13 = 0;
          }
        } else {
          rentValue13 = 0;
        }
        if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
          if ( year === expenseCalculationsB131
                 && expenseCalculationsData['Additional Career Advancement'] !== undefined
                 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
                 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Rent !== undefined ) {
            rentValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Rent;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue14 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            rentValue14 = rentValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            rentValue14 = 0;
          }
        } else {
          rentValue14 = 0;
        }
        if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
          if ( year === expenseCalculationsB172
                 && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
                 && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
                 && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Rent !== undefined ) {
            rentValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Rent;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue15 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            rentValue15 = rentValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            rentValue15 = 0;
          }
        } else {
          rentValue15 = 0;
        }
        if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
          if ( year === expenseCalculationsB193
                 && expenseCalculationsData['Further Career Advancement'] !== undefined
                 && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
                 && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Rent !== undefined ) {
            rentValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Rent;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
              rentValue2 = module10Data.rent_multiplier;
            } else {
              rentValue2 = 1;
            }
            rentValue16 = rentValue1 * rentValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            rentValue16 = rentValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            rentValue16 = 0;
          }
        } else {
          rentValue16 = 0;
        }

        rentValue = rentValue10 + rentValue11 + rentValue12 + rentValue13 + rentValue14 + rentValue15 + rentValue16;
      } else {
        rentValue = 0;
      }
    } else {
      rentValue = 0;
    }
    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' ) {
      rentValue3 = module10Data.rent_multiplier;
    } else {
      rentValue3 = 1;
    }
    rentValue *= rentValue3;
    taxStatementCompleteData[year].livingExpenses.Rent = rentValue;
    taxStatementCompleteData[year]['Rent Condition'] = conditionCheck;

    // Utilities
    conditionCheck = 0;
    if ( module13Data.year_of_purchase !== undefined ) {
      if ( year < module13Data.year_of_purchase || module13Data.year_of_purchase === '' ) {
        if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
          if ( year === expenseCalculationsB27
                                 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
                                 && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Utilities !== undefined ) {
            utilitiesValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Utilities;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue10 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            utilitiesValue10 = utilitiesValue * ( assumptionsData[year].Expenses / 100 );
            conditionCheck = 2;
          } else {
            utilitiesValue10 = 0;
          }
        } else {
          utilitiesValue10 = 0;
        }
        if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
          if ( year === expenseCalculationsB48
                 && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
                 && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Utilities !== undefined ) {
            utilitiesValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Utilities;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue11 = utilitiesValue1 * utilitiesValue2;

            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            utilitiesValue11 = utilitiesValue * ( assumptionsData[year].Expenses / 100 );
            conditionCheck = 2;
          } else {
            utilitiesValue11 = 0;
          }
        } else {
          utilitiesValue11 = 0;
        }
        if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
          if ( year === expenseCalculationsB69
                 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
                 && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Utilities !== undefined ) {
            utilitiesValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Utilities;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue12 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            utilitiesValue12 = utilitiesValue * ( assumptionsData[year].Expenses / 100 );
            conditionCheck = 2;
          } else {
            utilitiesValue12 = 0;
          }
        } else {
          utilitiesValue12 = 0;
        }
        if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
          if ( year === expenseCalculationsB110
                 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
                 && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Utilities !== undefined ) {
            utilitiesValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Utilities;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue13 = utilitiesValue1 * utilitiesValue2;

            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            utilitiesValue13 = utilitiesValue * ( assumptionsData[year].Expenses / 100 );
            conditionCheck = 2;
          } else {
            utilitiesValue13 = 0;
          }
        } else {
          utilitiesValue13 = 0;
        }
        if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
          if ( year === expenseCalculationsB131
                 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
                 && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Utilities !== undefined ) {
            utilitiesValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Utilities;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue14 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            utilitiesValue14 = utilitiesValue * ( assumptionsData[year].Expenses / 100 );
            conditionCheck = 2;
          } else {
            utilitiesValue14 = 0;
          }
        } else {
          utilitiesValue14 = 0;
        }
        if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
          if ( year === expenseCalculationsB172
                 && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
                 && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
                 && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Utilities !== undefined ) {
            utilitiesValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Utilities;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue15 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            utilitiesValue15 = utilitiesValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            utilitiesValue15 = 0;
          }
        } else {
          utilitiesValue15 = 0;
        }
        if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
          if ( year === expenseCalculationsB193
                 && expenseCalculationsData['Further Career Advancement'] !== undefined
                 && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
                 && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Utilities !== undefined ) {
            utilitiesValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Utilities;
            if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
              utilitiesValue2 = module10Data.utilities_multiplier;
            } else {
              utilitiesValue2 = 1;
            }
            utilitiesValue16 = utilitiesValue1 * utilitiesValue2;
            conditionCheck = 1;
          } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
            utilitiesValue16 = utilitiesValue * ( ( assumptionsData[year].Expenses ) / 100 );
            conditionCheck = 2;
          } else {
            utilitiesValue16 = 0;
          }
        } else {
          utilitiesValue16 = 0;
        }
        utilitiesValue = utilitiesValue10 + utilitiesValue11 + utilitiesValue12 + utilitiesValue13 + utilitiesValue14 + utilitiesValue15 + utilitiesValue16;
      } else {
        utilitiesValue = 0;
      }
    } else {
      utilitiesValue = 0;
    }

    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' ) {
      utilitiesValue3 = module10Data.utilities_multiplier;
    } else {
      utilitiesValue3 = 1;
    }
    utilitiesValue *= utilitiesValue3;

    taxStatementCompleteData[year].livingExpenses.Utilities = utilitiesValue;
    taxStatementCompleteData[year]['Utilities Condition'] = conditionCheck;

    // Food
    conditionCheck = 0;
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
                         && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
                         && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Food !== undefined ) {
        FoodValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Food;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue10 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        FoodValue10 = FoodValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        FoodValue10 = 0;
      }
    } else {
      FoodValue10 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
             && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
             && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Food !== undefined ) {
        FoodValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Food;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue11 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        FoodValue11 = FoodValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        FoodValue11 = 0;
      }
    } else {
      FoodValue11 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Food !== undefined ) {
        FoodValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Food;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue12 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        FoodValue12 = FoodValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        FoodValue12 = 0;
      }
    } else {
      FoodValue12 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Food !== undefined ) {
        FoodValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Food;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue13 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        FoodValue13 = FoodValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        FoodValue13 = 0;
      }
    } else {
      FoodValue13 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Food !== undefined ) {
        FoodValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Food;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue14 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        FoodValue14 = FoodValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        FoodValue14 = 0;
      }
    } else {
      FoodValue14 = 0;
    }
    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB172
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Food !== undefined ) {
        FoodValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Food;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue15 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        FoodValue15 = FoodValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        FoodValue15 = 0;
      }
    } else {
      FoodValue15 = 0;
    }
    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
             && expenseCalculationsData['Further Career Advancement'] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Food !== undefined ) {
        FoodValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Food;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
          FoodValue2 = module10Data.food_multiplier;
        } else {
          FoodValue2 = 1;
        }
        FoodValue16 = FoodValue1 * FoodValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        FoodValue16 = FoodValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        FoodValue16 = 0;
      }
    } else {
      FoodValue16 = 0;
    }
    FoodValue = FoodValue10 + FoodValue11 + FoodValue12 + FoodValue13 + FoodValue14 + FoodValue15 + FoodValue16;
    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' ) {
      FoodValue3 = module10Data.food_multiplier;
    } else {
      FoodValue3 = 1;
    }
    FoodValue *= FoodValue3;

    taxStatementCompleteData[year].livingExpenses.Food = FoodValue;
    taxStatementCompleteData[year]['Food Condition'] = conditionCheck;

    // Clothing
    conditionCheck = 0;
    if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB7
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Clothing !== undefined ) {
        clothingValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Clothing;

        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue10 = clothingValue * ( assumptionsData[year].Expenses / 100 );
        conditionCheck = 2;
      } else {
        clothingValue10 = 0;
      }
    } else {
      clothingValue10 = 0;
    }
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Clothing !== undefined ) {
        clothingValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Clothing;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue11 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue11 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue11 = 0;
      }
    } else {
      clothingValue11 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
             && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
             && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Clothing !== undefined ) {
        clothingValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Clothing;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue12 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue12 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue12 = 0;
      }
    } else {
      clothingValue12 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Clothing !== undefined ) {
        clothingValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Clothing;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue13 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue13 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue13 = 0;
      }
    } else {
      clothingValue13 = 0;
    }
    if ( year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB90
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Clothing !== undefined ) {
        clothingValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Clothing;

        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue14 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue14 = 0;
      }
    } else {
      clothingValue14 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Clothing !== undefined ) {
        clothingValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Clothing;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue15 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue15 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue15 = 0;
      }
    } else {
      clothingValue15 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Clothing !== undefined ) {
        clothingValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Clothing;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue16 = clothingValue1 * clothingValue2;
        conditionCheck = 1;

      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue16 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue16 = 0;
      }
    } else {
      clothingValue16 = 0;
    }
    if ( year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Clothing !== undefined ) {
        clothingValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Clothing;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue17 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue17 = 0;
      }
    } else {
      clothingValue17 = 0;
    }

    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB172
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Clothing !== undefined ) {
        clothingValue18 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Clothing;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue18 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue18 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue18 = 0;
      }
    } else {
      clothingValue18 = 0;
    }
    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
             && expenseCalculationsData['Further Career Advancement'] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Clothing !== undefined ) {
        clothingValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Clothing;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
          clothingValue2 = module10Data.clothing_multiplier;
        } else {
          clothingValue2 = 1;
        }
        clothingValue19 = clothingValue1 * clothingValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        clothingValue19 = clothingValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        clothingValue19 = 0;
      }
    } else {
      clothingValue19 = 0;
    }
    clothingValue = clothingValue10 + clothingValue11 + clothingValue12 + clothingValue13 + clothingValue14 + clothingValue15 + clothingValue16 + clothingValue17 + clothingValue18 + clothingValue19;
    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' ) {
      clothingValue3 = module10Data.clothing_multiplier;
    } else {
      clothingValue3 = 1;
    }
    clothingValue *= clothingValue3;
    taxStatementCompleteData[year].livingExpenses.Clothing = clothingValue;
    taxStatementCompleteData[year]['Clothing Condition'] = conditionCheck;

    // Entertainment
    conditionCheck = 0;
    if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB7
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Entertainment !== undefined ) {
        EntertainmentValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Entertainment;

        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue10 = EntertainmentValue * ( assumptionsData[year].Expenses / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue10 = 0;
      }
    } else {
      EntertainmentValue10 = 0;
    }
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Entertainment !== undefined ) {
        EntertainmentValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Entertainment;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue11 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue11 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue11 = 0;
      }
    } else {
      EntertainmentValue11 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
             && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
             && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Entertainment !== undefined ) {
        EntertainmentValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Entertainment;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue12 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue12 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue12 = 0;
      }
    } else {
      EntertainmentValue12 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Entertainment !== undefined ) {
        EntertainmentValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Entertainment;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue13 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue13 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue13 = 0;
      }
    } else {
      EntertainmentValue13 = 0;
    }
    if ( year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB90
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Entertainment !== undefined ) {
        EntertainmentValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Entertainment;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue14 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue14 = 0;
      }
    } else {
      EntertainmentValue14 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Entertainment !== undefined ) {
        EntertainmentValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Entertainment;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue15 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue15 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue15 = 0;
      }
    } else {
      EntertainmentValue15 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Entertainment !== undefined ) {
        EntertainmentValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Entertainment;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue16 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue16 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue16 = 0;
      }
    } else {
      EntertainmentValue16 = 0;
    }

    if ( year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB152
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Entertainment !== undefined ) {
        EntertainmentValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Entertainment;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue17 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue17 = 0;
      }
    } else {
      EntertainmentValue17 = 0;
    }

    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB172
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Entertainment !== undefined ) {
        EntertainmentValue18 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Entertainment;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue18 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue18 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue18 = 0;
      }
    } else {
      EntertainmentValue18 = 0;
    }

    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
             && expenseCalculationsData['Further Career Advancement'] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Entertainment !== undefined ) {
        EntertainmentValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Entertainment;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
          EntertainmentValue2 = module10Data.entertainment_multiplier;
        } else {
          EntertainmentValue2 = 1;
        }
        EntertainmentValue19 = EntertainmentValue1 * EntertainmentValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        EntertainmentValue19 = EntertainmentValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        EntertainmentValue19 = 0;
      }
    } else {
      EntertainmentValue19 = 0;
    }

    EntertainmentValue = EntertainmentValue10 + EntertainmentValue11 + EntertainmentValue12 + EntertainmentValue13 + EntertainmentValue14 + EntertainmentValue15 + EntertainmentValue16 + EntertainmentValue17 + EntertainmentValue18 + EntertainmentValue19;

    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' ) {
      EntertainmentValue3 = module10Data.entertainment_multiplier;
    } else {
      EntertainmentValue3 = 1;
    }
    EntertainmentValue *= EntertainmentValue3;
    taxStatementCompleteData[year].livingExpenses.Entertainment = EntertainmentValue;
    taxStatementCompleteData[year]['Entertainment Condition'] = conditionCheck;

    // Technology
    conditionCheck = 0;
    if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB7
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Technology !== undefined ) {
        TechnologyValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Technology;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue10 = TechnologyValue * ( assumptionsData[year].Expenses / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue10 = 0;
      }
    } else {
      TechnologyValue10 = 0;
    }
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Technology !== undefined ) {
        TechnologyValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Technology;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue11 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue11 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue11 = 0;
      }
    } else {
      TechnologyValue11 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
             && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
             && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Technology !== undefined ) {
        TechnologyValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Technology;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue12 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue12 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue12 = 0;
      }
    } else {
      TechnologyValue12 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Technology !== undefined ) {
        TechnologyValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Technology;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue13 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue13 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue13 = 0;
      }
    } else {
      TechnologyValue13 = 0;
    }
    if ( year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB90
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Technology !== undefined ) {
        TechnologyValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Technology;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue14 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue14 = 0;
      }
    } else {
      TechnologyValue14 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Technology !== undefined ) {
        TechnologyValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Technology;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue15 = TechnologyValue1 * TechnologyValue2;

        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue15 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue15 = 0;
      }
    } else {
      TechnologyValue15 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Technology !== undefined ) {
        TechnologyValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Technology;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue16 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue16 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue16 = 0;
      }
    } else {
      TechnologyValue16 = 0;
    }
    if ( year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB152
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Technology !== undefined ) {
        TechnologyValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Technology;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue17 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue17 = 0;
      }
    } else {
      TechnologyValue17 = 0;
    }

    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB172
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Technology !== undefined ) {
        TechnologyValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Technology;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue18 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue18 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue18 = 0;
      }
    } else {
      TechnologyValue18 = 0;
    }
    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
             && expenseCalculationsData['Further Career Advancement'] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Technology !== undefined ) {
        TechnologyValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Technology;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
          TechnologyValue2 = module10Data.technology_multiplier;
        } else {
          TechnologyValue2 = 1;
        }
        TechnologyValue19 = TechnologyValue1 * TechnologyValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TechnologyValue19 = TechnologyValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TechnologyValue19 = 0;
      }
    } else {
      TechnologyValue19 = 0;
    }
    TechnologyValue = TechnologyValue10 + TechnologyValue11 + TechnologyValue12 + TechnologyValue13 + TechnologyValue14 + TechnologyValue15 + TechnologyValue16 + TechnologyValue17 + TechnologyValue18 + TechnologyValue19;

    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' ) {
      TechnologyValue3 = module10Data.technology_multiplier;
    } else {
      TechnologyValue3 = 1;
    }
    TechnologyValue *= TechnologyValue3;

    taxStatementCompleteData[year].livingExpenses.Technology = TechnologyValue;
    taxStatementCompleteData[year]['Technology Condition'] = conditionCheck;

    // Transportation
    conditionCheck = 0;
    if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB7
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Transportation !== undefined ) {
        TransportationValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Transportation;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue10 = TransportationValue * ( assumptionsData[year].Expenses / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue10 = 0;
      }
    } else {
      TransportationValue10 = 0;
    }
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Transportation !== undefined ) {
        TransportationValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Transportation;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue11 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue11 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue11 = 0;
      }
    } else {
      TransportationValue11 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
             && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
             && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Transportation !== undefined ) {
        TransportationValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Transportation;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue12 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue12 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue12 = 0;
      }
    } else {
      TransportationValue12 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Transportation !== undefined ) {
        TransportationValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Transportation;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue13 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue13 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue13 = 0;
      }
    } else {
      TransportationValue13 = 0;
    }
    if ( year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB90
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Transportation !== undefined ) {
        TransportationValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Transportation;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue14 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue14 = 0;
      }
    } else {
      TransportationValue14 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Transportation !== undefined ) {
        TransportationValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Transportation;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue15 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue15 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue15 = 0;
      }
    } else {
      TransportationValue15 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Transportation !== undefined ) {
        TransportationValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Transportation;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue16 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue16 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue16 = 0;
      }
    } else {
      TransportationValue16 = 0;
    }
    if ( year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB152
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation !== undefined ) {
        TransportationValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue17 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue17 = 0;
      }
    } else {
      TransportationValue17 = 0;
    }

    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 ) {
      if ( year === expenseCalculationsB172
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Transportation !== undefined ) {
        TransportationValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Transportation;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue18 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue18 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue18 = 0;
      }
    } else {
      TransportationValue18 = 0;
    }
    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
             && expenseCalculationsData['Further Career Advancement'] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Transportation !== undefined ) {
        TransportationValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Transportation;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
          TransportationValue2 = module10Data.transportation_multiplier;
        } else {
          TransportationValue2 = 1;
        }
        TransportationValue19 = TransportationValue1 * TransportationValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        TransportationValue19 = TransportationValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        TransportationValue19 = 0;
      }
    } else {
      TransportationValue19 = 0;
    }
    TransportationValue = TransportationValue10 + TransportationValue11 + TransportationValue12 + TransportationValue13 + TransportationValue14 + TransportationValue15 + TransportationValue16 + TransportationValue17 + TransportationValue18 + TransportationValue19;
    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' ) {
      TransportationValue3 = module10Data.transportation_multiplier;
    } else {
      TransportationValue3 = 1;
    }
    TransportationValue *= TransportationValue3;

    taxStatementCompleteData[year].livingExpenses.Transportation = TransportationValue;
    taxStatementCompleteData[year]['Transportation Condition'] = conditionCheck;

    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'] !== undefined ) {
      carPaymentValue1 = depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'];
    } else {
      carPaymentValue1 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'] !== undefined ) {
      carPaymentValue2 = depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'];
    } else {
      carPaymentValue2 = 0;
    }
    carPaymentValue = -( carPaymentValue1 + carPaymentValue2 );
    taxStatementCompleteData[year].livingExpenses['Car Payment'] = carPaymentValue;
    taxStatementCompleteData[year]['Car Payment Condition'] = conditionCheck;

    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Understanding Depreciation'].Maintenance !== undefined ) {
      CarMaintenanceValue1 = depreciationSheduleData[year]['Understanding Depreciation'].Maintenance;
    } else {
      CarMaintenanceValue1 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Maintenance !== undefined ) {
      CarMaintenanceValue2 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Maintenance;
    } else {
      CarMaintenanceValue2 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Maintenance !== undefined ) {
      CarMaintenanceValue3 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Maintenance;
    } else {
      CarMaintenanceValue3 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses'].Maintenance !== undefined ) {
      CarMaintenanceValue4 = depreciationSheduleData[year]['Lease1 Expenses'].Maintenance;
    } else {
      CarMaintenanceValue4 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses'].Maintenance !== undefined ) {
      CarMaintenanceValue5 = depreciationSheduleData[year]['Lease2 Expenses'].Maintenance;
    } else {
      CarMaintenanceValue5 = 0;
    }
    CarMaintenanceValue = -( CarMaintenanceValue1 + CarMaintenanceValue2 + CarMaintenanceValue3 + CarMaintenanceValue4 + CarMaintenanceValue5 );

    taxStatementCompleteData[year].livingExpenses['Car Maintenance'] = CarMaintenanceValue;
    taxStatementCompleteData[year]['Car Maintenance Condition'] = conditionCheck;

    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Understanding Depreciation'].Insurance !== undefined ) {
      CarInsuranceValue1 = depreciationSheduleData[year]['Understanding Depreciation'].Insurance;
    } else {
      CarInsuranceValue1 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Insurance !== undefined ) {
      CarInsuranceValue2 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Insurance;
    } else {
      CarInsuranceValue2 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Insurance !== undefined ) {
      CarInsuranceValue3 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Insurance;
    } else {
      CarInsuranceValue3 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses'].Insurance !== undefined ) {
      CarInsuranceValue4 = depreciationSheduleData[year]['Lease1 Expenses'].Insurance;
    } else {
      CarInsuranceValue4 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses'].Insurance !== undefined ) {
      CarInsuranceValue5 = depreciationSheduleData[year]['Lease2 Expenses'].Insurance;
    } else {
      CarInsuranceValue5 = 0;
    }
    CarInsuranceValue = -( CarInsuranceValue1 + CarInsuranceValue2 + CarInsuranceValue3 + CarInsuranceValue4 + CarInsuranceValue5 );

    taxStatementCompleteData[year].livingExpenses['Car Insurance'] = CarInsuranceValue;
    taxStatementCompleteData[year]['Car Insurance Condition'] = conditionCheck;

    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Understanding Depreciation'].Gas !== undefined ) {
      GasValue1 = depreciationSheduleData[year]['Understanding Depreciation'].Gas;
    } else {
      GasValue1 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Gas !== undefined ) {
      GasValue2 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation'].Gas;
    } else {
      GasValue2 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Gas !== undefined ) {
      GasValue3 = depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'].Gas;
    } else {
      GasValue3 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease1 Expenses'].Gas !== undefined ) {
      GasValue4 = depreciationSheduleData[year]['Lease1 Expenses'].Gas;
    } else {
      GasValue4 = 0;
    }
    if ( depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData[year] !== undefined && depreciationSheduleData[year] !== '' && depreciationSheduleData[year]['Lease2 Expenses'].Gas !== undefined ) {
      GasValue5 = depreciationSheduleData[year]['Lease2 Expenses'].Gas;
    } else {
      GasValue5 = 0;
    }
    GasValue = -( GasValue1 + GasValue2 + GasValue3 + GasValue4 + GasValue5 );

    taxStatementCompleteData[year].livingExpenses.Gas = GasValue;
    taxStatementCompleteData[year]['Gas Condtion'] = conditionCheck;

    // Miscellaneous
    conditionCheck = 0;
    if ( year >= expenseCalculationsB7 && year <= expenseCalculationsB8 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB7
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7] !== undefined
                         && expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue10 = -expenseCalculationsData['Higher Education - On-Campus Housing'][expenseCalculationsB7].Yearly.Miscellaneous;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue10 = MiscellaneousValue * ( assumptionsData[year].Expenses / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue10 = 0;
      }
    } else {
      MiscellaneousValue10 = 0;
    }
    if ( year >= expenseCalculationsB27 && year <= expenseCalculationsB28 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB27
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27] !== undefined
             && expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue1 = -expenseCalculationsData['Higher Education - Off-Campus Housing'][expenseCalculationsB27].Yearly.Miscellaneous;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB27 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue11 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue11 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue11 = 0;
      }
    } else {
      MiscellaneousValue11 = 0;
    }
    if ( year >= expenseCalculationsB48 && year <= expenseCalculationsB49 ) {
      if ( year === expenseCalculationsB48
             && expenseCalculationsData['Career Path'][expenseCalculationsB48] !== undefined
             && expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue1 = -expenseCalculationsData['Career Path'][expenseCalculationsB48].Yearly.Miscellaneous;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB48 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue12 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue12 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue12 = 0;
      }
    } else {
      MiscellaneousValue12 = 0;
    }
    if ( year >= expenseCalculationsB69 && year <= expenseCalculationsB70 ) {
      if ( year === expenseCalculationsB69
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69] !== undefined
             && expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue1 = -expenseCalculationsData['Career Advancement'][expenseCalculationsB69].Yearly.Miscellaneous;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB69 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue13 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue13 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue13 = 0;
      }
    } else {
      MiscellaneousValue13 = 0;
    }
    if ( year >= expenseCalculationsB90 && year <= expenseCalculationsB91 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB90
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90] !== undefined
             && expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue14 = -expenseCalculationsData['More Higher Education - On-Campus Housing'][expenseCalculationsB90].Yearly.Miscellaneous;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue14 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue14 = 0;
      }
    } else {
      MiscellaneousValue14 = 0;
    }
    if ( year >= expenseCalculationsB110 && year <= expenseCalculationsB111 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB110
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110] !== undefined
             && expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue1 = -expenseCalculationsData['More Higher Education - Off-Campus Housing'][expenseCalculationsB110].Yearly.Miscellaneous;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB110 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue15 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue15 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue15 = 0;
      }
    } else {
      MiscellaneousValue15 = 0;
    }
    if ( year >= expenseCalculationsB131 && year <= expenseCalculationsB132 ) {
      if ( year === expenseCalculationsB131
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131] !== undefined
             && expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue1 = -expenseCalculationsData['Additional Career Advancement'][expenseCalculationsB131].Yearly.Miscellaneous;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB131 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue16 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue16 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue16 = 0;
      }
    } else {
      MiscellaneousValue16 = 0;
    }
    if ( year >= expenseCalculationsB152 && year <= expenseCalculationsB153 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB152
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152] !== undefined
             && expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation !== undefined ) {
        MiscellaneousValue17 = -expenseCalculationsData['New Higher Education - On-Campus Housing'][expenseCalculationsB152].Yearly.Transportation;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue17 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue17 = 0;
      }
    } else {
      MiscellaneousValue17 = 0;
    }

    if ( year >= expenseCalculationsB172 && year <= expenseCalculationsB173 && grossIncomeTotal === 0 ) {
      if ( year === expenseCalculationsB172
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172] !== undefined
             && expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue1 = -expenseCalculationsData['New Higher Education - Off-Campus Housing'][expenseCalculationsB172].Yearly.Miscellaneous;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB172 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue18 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue18 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue18 = 0;
      }
    } else {
      MiscellaneousValue18 = 0;
    }
    if ( year >= expenseCalculationsB193 && year <= expenseCalculationsB194 ) {
      if ( year === expenseCalculationsB193
             && expenseCalculationsData['Further Career Advancement'] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193] !== undefined
             && expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Miscellaneous !== undefined ) {
        MiscellaneousValue1 = -expenseCalculationsData['Further Career Advancement'][expenseCalculationsB193].Yearly.Miscellaneous;
        if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && expenseCalculationsB193 > module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
          MiscellaneousValue2 = module10Data.miscellaneous_multiplier;
        } else {
          MiscellaneousValue2 = 1;
        }
        MiscellaneousValue19 = MiscellaneousValue1 * MiscellaneousValue2;
        conditionCheck = 1;
      } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        MiscellaneousValue19 = MiscellaneousValue * ( ( assumptionsData[year].Expenses ) / 100 );
        conditionCheck = 2;
      } else {
        MiscellaneousValue19 = 0;
      }
    } else {
      MiscellaneousValue19 = 0;
    }
    MiscellaneousValue = MiscellaneousValue10 + MiscellaneousValue11 + MiscellaneousValue12 + MiscellaneousValue13 + MiscellaneousValue14 + MiscellaneousValue15 + MiscellaneousValue16 + MiscellaneousValue17 + MiscellaneousValue18 + MiscellaneousValue19;

    if ( module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && year === module10Data.marriege_year && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' ) {
      MiscellaneousValue3 = module10Data.miscellaneous_multiplier;
    } else {
      MiscellaneousValue3 = 1;
    }
    MiscellaneousValue *= MiscellaneousValue3;

    taxStatementCompleteData[year].livingExpenses.Miscellaneous = MiscellaneousValue;
    taxStatementCompleteData[year]['Miscellaneous Condition'] = conditionCheck;

    // Health Insurance Deductible
    taxStatementCompleteData[year].livingExpenses['Health Insurance Costs outside Premium'] = healthInsuranceDeductibleValue;
    taxStatementCompleteData[year]['Health Insurance Deductible Condition'] = healthInsuranceCheck;

    // Health Insurance Premium
    taxStatementCompleteData[year].livingExpenses['Health Insurance Premium'] = healthInsurancePremiumValue;
    taxStatementCompleteData[year]['Health Insurance Premium Condition'] = healthInsuranceCheck;

    // Children
    let ChildrenValue1 = 0;
    let ChildrenValue2 = 0;
    let ChildrenValue3 = 0;
    let ChildrenValue4 = 0;
    let ChildrenValue5 = 0;
    let ChildrenValue6 = 0;
    let ChildrenValue7 = 0;
    // Child 1
    if ( module10Data.first_born !== undefined
                    && module10Data.first_born !== ''
                    && year >= module10Data.first_born ) {
      if ( databaseData !== undefined && databaseData['Children Info'][year] !== undefined ) {
        ChildrenValue1 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue1 = 0;
      }
    }
    // Child 2
    if ( module10Data.second_born !== undefined
                    && module10Data.second_born !== ''
                    && year >= module10Data.second_born ) {
      if ( databaseData !== undefined && databaseData['Children Info'][year] !== undefined ) {
        ChildrenValue2 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue2 = 0;
      }
    }
    // Child 3
    if ( module10Data.third_born !== undefined
                    && module10Data.third_born !== ''
                    && year >= module10Data.third_born ) {
      if ( databaseData !== undefined && databaseData['Children Info'][year] !== undefined ) {
        ChildrenValue3 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue3 = 0;
      }
    }
    // Child 4
    if ( module10Data.fourth_born !== undefined
                    && module10Data.fourth_born !== ''
                    && year >= module10Data.fourth_born ) {
      if ( databaseData !== undefined && databaseData['Children Info'][year] !== undefined ) {
        ChildrenValue4 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue4 = 0;
      }
    }
    // Child 5
    if ( module10Data.fifth_born !== undefined
                    && module10Data.fifth_born !== ''
                    && year >= module10Data.fifth_born ) {
      if ( databaseData !== undefined && databaseData['Children Info'][year] !== undefined ) {
        ChildrenValue5 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue5 = 0;
      }
    }
    // Child 6
    if ( module10Data.sixth_born !== undefined
                    && module10Data.sixth_born !== ''
                    && year >= module10Data.sixth_born ) {
      if ( databaseData !== undefined && databaseData['Children Info'][year] !== undefined ) {
        ChildrenValue6 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue6 = 0;
      }
    }
    // Child 7
    if ( module10Data.seventh_born !== undefined
                    && module10Data.seventh_born !== ''
                    && year >= module10Data.seventh_born ) {
      if ( databaseData !== undefined && databaseData['Children Info'][year] !== undefined ) {
        ChildrenValue7 = -databaseData['Children Info'][year].value;
      } else {
        ChildrenValue7 = 0;
      }
    }
    ChildrenValue = parseFloat( ChildrenValue1 )
                + parseFloat( ChildrenValue2 )
                + parseFloat( ChildrenValue3 )
                + parseFloat( ChildrenValue4 )
                + parseFloat( ChildrenValue5 )
                + parseFloat( ChildrenValue6 )
                + parseFloat( ChildrenValue7 );
    taxStatementCompleteData[year].livingExpenses.Children = ChildrenValue;

    // Pet
    conditionCheck = 0;
    if ( module10Data.year_of_purchase !== undefined && module10Data.year_of_purchase !== '' ) {
      if ( year >= module10Data.year_of_purchase ) {
        if ( expenseCalculationsData[year] !== undefined
                             && expenseCalculationsData[year]['Yearly Costs'].Total !== undefined ) {
          PetValue = -parseFloat( expenseCalculationsData[year]['Yearly Costs'].Total );
          conditionCheck = 1;
        } else {
          PetValue = 0;
        }
      } else {
        PetValue = 0;
      }
    } else {
      PetValue = 0;
    }
    taxStatementCompleteData[year].livingExpenses.Pet = PetValue;
    taxStatementCompleteData[year]['Pet Condition'] = conditionCheck;

    // Home Property Expenses
    let homePropertyExpensesValue = 0;
    let homePropertyExpensesValue1 = 0;
    let homePropertyExpensesValue2 = 0;
    if ( buyingAHomeData !== undefined && buyingAHomeData !== ''
                     && buyingAHomeData.Data1 !== undefined
                     && buyingAHomeData.Data1[year] !== undefined
                     && buyingAHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined
                     && buyingAHomeData.Data1[year]['Association Fees'] !== undefined
                     && buyingAHomeData.Data1[year]['Houseowners Insurance'] !== undefined
                     && buyingAHomeData.Data1[year]['Property Maintenance'] !== undefined
                     && buyingAHomeData.Data1[year].Utilities !== undefined ) {
      homePropertyExpensesValue1 = -( buyingAHomeData.Data1[year]['Private Mortgage Insurance']
                        + buyingAHomeData.Data1[year]['Association Fees']
                        + buyingAHomeData.Data1[year]['Houseowners Insurance']
                        + buyingAHomeData.Data1[year]['Property Maintenance']
                        + buyingAHomeData.Data1[year].Utilities );
    } else {
      homePropertyExpensesValue1 = 0;
    }
    if ( existingHomeData !== undefined && existingHomeData !== ''
                     && existingHomeData.Data1 !== undefined
                     && existingHomeData.Data1[year] !== undefined
                     && existingHomeData.Data1[year]['Houseowners Insurance'] !== undefined
                     && existingHomeData.Data1[year]['Private Mortgage Insurance'] !== undefined
                     && existingHomeData.Data1[year].Maintenance !== undefined
                     && existingHomeData.Data1[year]['Association Fees'] !== undefined
                     && existingHomeData.Data1[year].Utilities !== undefined ) {
      homePropertyExpensesValue2 = -( existingHomeData.Data1[year]['Houseowners Insurance']
                        + existingHomeData.Data1[year]['Private Mortgage Insurance']
                        + existingHomeData.Data1[year].Maintenance
                        + existingHomeData.Data1[year]['Association Fees']
                        + existingHomeData.Data1[year].Utilities );
    } else {
      homePropertyExpensesValue2 = 0;
    }
    homePropertyExpensesValue = homePropertyExpensesValue1 + homePropertyExpensesValue2;
    taxStatementCompleteData[year].livingExpenses['Home Property Expenses'] = homePropertyExpensesValue;

    // Property Taxes
    let propertyTaxeslivingValue = 0;
    let propertyTaxeslivingValue1 = 0;
    let propertyTaxeslivingValue2 = 0;
    // propertyTaxeslivingValue = taxStatementCompleteData[year]['Itemized Deductions']['Home Property Taxes'];
    // taxStatementCompleteData[year]['livingExpenses']['Home Property Taxes'] = -(propertyTaxeslivingValue);
    if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== ''
                    && year === module13Data.year_of_purchase ) {
      if ( module13Data.propertyTaxesCalcs !== undefined && module13Data.propertyTaxesCalcs !== '' ) {
        propertyTaxeslivingValue1 = module13Data.propertyTaxesCalcs;
      } else {
        propertyTaxeslivingValue1 = 0;
      }
    } else if ( buyingAHomeData !== undefined && buyingAHomeData !== ''
                         && buyingAHomeData['Real Estate'] !== undefined
                         && buyingAHomeData['Real Estate'][year] !== undefined
                         && buyingAHomeData['Real Estate'][year]['Property Tax'] !== undefined ) {
      propertyTaxeslivingValue1 = buyingAHomeData['Real Estate'][year]['Property Tax'];
    } else {
      propertyTaxeslivingValue1 = 0;
    }

    if ( existingHomeData !== undefined && existingHomeData !== '' && existingHomeData.Data1 !== undefined && existingHomeData.Data1 !== ''
                     && existingHomeData.Data1[year] !== undefined && existingHomeData.Data1[year] !== '' && existingHomeData.Data1[year]['Property Taxes'] !== undefined && existingHomeData.Data1[year]['Property Taxes'] !== '' ) {
      propertyTaxeslivingValue2 = existingHomeData.Data1[year]['Property Taxes'];
    } else {
      propertyTaxeslivingValue2 = 0;
    }
    propertyTaxeslivingValue = propertyTaxeslivingValue1 + propertyTaxeslivingValue2;
    taxStatementCompleteData[year].livingExpenses['Home Property Taxes'] = -( propertyTaxeslivingValue );

    // Total Living Expenses
    taxStatementCompleteData[year]['Total Living Expenses'] = taxStatementCompleteData[year].livingExpenses['Higher Education Tuition and Fees']
                + taxStatementCompleteData[year].livingExpenses['Higher Education Room & Board']
                + taxStatementCompleteData[year].livingExpenses['Off Campus Food']
                + taxStatementCompleteData[year].livingExpenses.Rent
                + taxStatementCompleteData[year].livingExpenses.Utilities
                + taxStatementCompleteData[year].livingExpenses.Food
                + taxStatementCompleteData[year].livingExpenses.Clothing
                + taxStatementCompleteData[year].livingExpenses.Entertainment
                + taxStatementCompleteData[year].livingExpenses.Technology
                + taxStatementCompleteData[year].livingExpenses.Transportation
                + taxStatementCompleteData[year].livingExpenses['Car Payment']
                + taxStatementCompleteData[year].livingExpenses['Car Maintenance']
                + taxStatementCompleteData[year].livingExpenses['Car Insurance']
                + taxStatementCompleteData[year].livingExpenses.Gas
                + taxStatementCompleteData[year].livingExpenses.Miscellaneous
                + taxStatementCompleteData[year].livingExpenses['Health Insurance Costs outside Premium']
                + taxStatementCompleteData[year].livingExpenses['Health Insurance Premium']
                + taxStatementCompleteData[year].livingExpenses.Children
                + taxStatementCompleteData[year].livingExpenses.Pet
                + taxStatementCompleteData[year].livingExpenses['Home Property Expenses']
                /* taxStatementCompleteData[year]['livingExpenses']['Rental Property Expenses'] +*/
                + taxStatementCompleteData[year].livingExpenses['Home Property Taxes'];
    totalLivingExpensesGraph.push( taxStatementCompleteData[year]['Total Living Expenses'] );
    /** End of Living Expenses & Total Living Expenses * */

    /** ***********************************************
                 ** Interest Expenses  & Total Interest Expenses **
                 ************************************************* */
    // Existing Credit Card Debt
    let existingCreditCardDebtValue = 0;
    if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' && existingCreditCardDebtData['Summation Data'] !== undefined && existingCreditCardDebtData['Summation Data'] !== '' && existingCreditCardDebtData['Summation Data'][year] !== undefined && existingCreditCardDebtData['Summation Data'][year] !== '' && existingCreditCardDebtData['Summation Data'][year].LaggingInterest !== undefined ) {
      existingCreditCardDebtValue = -existingCreditCardDebtData['Summation Data'][year].LaggingInterest;
    }
    taxStatementCompleteData[year].InterestExpenses['Existing Credit Card Debt'] = existingCreditCardDebtValue;

    // Car Loans
    let carLoansValue = 0;
    let carLoansValue1 = 0;
    if ( existingCarLoanData !== undefined
                     && existingCarLoanData['Summation Data'][year] !== undefined ) {
      carLoansValue1 = existingCarLoanData['Summation Data'][year].LaggingInterest;
    } else {
      carLoansValue1 = 0;
    }
    let carLoansValue2 = 0;
    if ( carLoan1Data !== undefined
                     && carLoan1Data['Summation Data'][year] !== undefined ) {
      carLoansValue2 = carLoan1Data['Summation Data'][year].LaggingInterest;
    } else {
      carLoansValue2 = 0;
    }
    let carLoansValue3 = 0;
    if ( carLoan2Data !== undefined
                     && carLoan2Data['Summation Data'][year] !== undefined ) {
      carLoansValue3 = carLoan2Data['Summation Data'][year].LaggingInterest;
    } else {
      carLoansValue3 = 0;
    }
    carLoansValue = -( carLoansValue1 + carLoansValue2 + carLoansValue3 );
    taxStatementCompleteData[year].InterestExpenses['Car Loans'] = carLoansValue;

    // Student Loans
    let existingfedSub = 0;
    let existingfedUnSub = 0;
    let existingprivate = 0;
    let fedSub = 0;
    let fedUnSub = 0;
    let privateHigher = 0;
    let fedSubMore = 0;
    let fedUnSubMore = 0;
    let privateHigherMore = 0;
    let fedSubNew = 0;
    let fedUnSubNew = 0;
    let privateHigherNew = 0;
    if ( fedSubExistingLoanData !== undefined
                     && fedSubExistingLoanData['Summation Data'] !== undefined
                     && fedSubExistingLoanData['Summation Data'][year] !== undefined ) {
      existingfedSub = fedSubExistingLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( fedUnSubExistingLoanData !== undefined
                     && fedUnSubExistingLoanData['Summation Data'] !== undefined
                     && fedUnSubExistingLoanData['Summation Data'][year] !== undefined ) {
      existingfedUnSub = fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( privateExistingLoanData !== undefined
                     && privateExistingLoanData['Summation Data'] !== undefined
                     && privateExistingLoanData['Summation Data'][year] !== undefined ) {
      existingprivate = privateExistingLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( fedSubHigherEdLoanData !== undefined
                     && fedSubHigherEdLoanData['Summation Data'] !== undefined
                     && fedSubHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedSub = fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( fedUnSubHigherEdLoanData !== undefined
                     && fedUnSubHigherEdLoanData['Summation Data'] !== undefined
                     && fedUnSubHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedUnSub = fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( privateHigherEdLoanData !== undefined
                     && privateHigherEdLoanData['Summation Data'] !== undefined
                     && privateHigherEdLoanData['Summation Data'][year] !== undefined ) {
      privateHigher = privateHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( fedSubMoreHigherEdLoanData !== undefined
                     && fedSubMoreHigherEdLoanData['Summation Data'] !== undefined
                     && fedSubMoreHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedSubMore = fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( fedUnSubMoreHigherEdLoanData !== undefined
                     && fedUnSubMoreHigherEdLoanData['Summation Data'] !== undefined
                     && fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedUnSubMore = fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( privateMoreHigherEdLoanData !== undefined
                     && privateMoreHigherEdLoanData['Summation Data'] !== undefined
                     && privateMoreHigherEdLoanData['Summation Data'][year] !== undefined ) {
      privateHigherMore = privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( fedSubNewHigherEdLoanData !== undefined
                     && fedSubNewHigherEdLoanData['Summation Data'] !== undefined
                     && fedSubNewHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedSubNew = fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( fedUnSubNewHigherEdLoanData !== undefined
                     && fedUnSubNewHigherEdLoanData['Summation Data'] !== undefined
                     && fedUnSubNewHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedUnSubNew = fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }
    if ( privateNewHigherEdLoanData !== undefined
                     && privateNewHigherEdLoanData['Summation Data'] !== undefined
                     && privateNewHigherEdLoanData['Summation Data'][year] !== undefined ) {
      privateHigherNew = privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest;
    }

    let StudentLoanValue = 0;
    StudentLoanValue = -parseFloat(
      parseFloat( existingfedSub )
                    + parseFloat( existingfedUnSub )
                    + parseFloat( existingprivate )
                    + parseFloat( fedSub )
                    + parseFloat( fedUnSub )
                    + parseFloat( privateHigher )
                    + parseFloat( fedSubMore )
                    + parseFloat( fedUnSubMore )
                    + parseFloat( privateHigherMore )
                    + parseFloat( fedSubNew )
                    + parseFloat( fedUnSubNew )
                    + parseFloat( privateHigherNew ),
    );
    taxStatementCompleteData[year].InterestExpenses['Student Loans'] = StudentLoanValue;

    // Mortgages
    let MortgagesValue = 0;
    let MortgagesValue1 = 0;
    let MortgagesValue2 = 0;

    if ( mortgage1Data !== undefined
                     && mortgage1Data['Summation Data'] !== undefined
                     && mortgage1Data['Summation Data'][year] !== undefined
                     && mortgage2Data !== undefined
                     && mortgage2Data['Summation Data'][year] !== undefined ) {
      MortgagesValue1 = ( mortgage1Data['Summation Data'][year].LaggingInterest
                        + mortgage2Data['Summation Data'][year].LaggingInterest );
    } else {
      MortgagesValue1 = 0;
    }
    if ( existingHomeMortgageData !== undefined
           && existingHomeMortgageData !== ''
            && existingHomeMortgageData['Summation Data'] !== undefined
            && existingHomeMortgageData['Summation Data'][year] !== undefined
           && existingHomeMortgageData['Summation Data'][year].LaggingInterest !== '' ) {
      MortgagesValue2 = existingHomeMortgageData['Summation Data'][year].LaggingInterest;
    } else {
      MortgagesValue2 = 0;
    }

    MortgagesValue = -( MortgagesValue1 + MortgagesValue2 );
    taxStatementCompleteData[year].InterestExpenses.Mortgages = MortgagesValue;

    // Total Intereset Expenses
    // existingCreditCardDebtValue +
    taxStatementCompleteData[year]['Total Interest Expenses'] = existingCreditCardDebtValue
        + carLoansValue
                + StudentLoanValue
                + MortgagesValue;
    totalInterestExpensesGraph.push( taxStatementCompleteData[year]['Total Interest Expenses'] );
    /** End of Interest Expenses & Total Interest Expenses * */

    /** *****************
                 ** Total Expenses **
                 ******************* */
    taxStatementCompleteData[year]['Total Expenses'] = taxStatementCompleteData[year]['Total Interest Expenses']
                // taxStatementCompleteData[year]['Total Depreciation Expenses'] +
                + taxStatementCompleteData[year]['Total Living Expenses'];
    totalExpensesGraph.push( taxStatementCompleteData[year]['Total Expenses'] );
    /** End of Total Expenses * */

    /** ***************************
                 ** Investment Property Sale **
                 ***************************** */

    // Tax on Sale
    let taxOnSale = 0;
    let taxOnSale1 = 0;
    let taxOnSale2 = 0;
	let taxOnSale3 = 0;
    conditionCheck = 0;
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== '' && module14Data.year_of_sale !== undefined && year === module14Data.year_of_sale ) {
      const val1 = invPropertyRoiData['Total on Accumulated'];
      const val2 = invPropertyRoiData['Total on Gain'];
      taxOnSale1 = -( val1 + val2 );
      conditionCheck = 1;
    }

    if ( module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && year === module18Data.existing_home_sale_year ) {
      if ( module18Data.taxOnGain !== undefined && module18Data.taxOnGain !== '' ) {
        taxOnSale2 = -module18Data.taxOnGain;
      } else {
        taxOnSale2 = 0;
      }
    }
	if( typeof module18Data.existing_home_sale_year_2 !== 'undefined' && module18Data.existing_home_sale_year_2 !== '' && year === module18Data.existing_home_sale_year_2 ){
		if( typeof module18Data.taxOnGain2 !== 'undefined' && module18Data.taxOnGain2 !== ''){
			taxOnSale3 = -module18Data.taxOnGain2;
		} else{
			taxOnSale3 = 0;	
		}
	}
	
    taxOnSale = taxOnSale1 + taxOnSale2 + taxOnSale3;

    taxStatementCompleteData[year]['Investment Property Sale']['Tax on Sale'] = taxOnSale;
    taxStatementCompleteData[year]['Tax on Sale Condition'] = conditionCheck;

    // Total Investment Property Sale
    taxStatementCompleteData[year]['Total Investment Property Sale'] = taxOnSale;

    /** *************
                 ** Net Income **
                 *************** */
    if ( taxStatementCompleteData[year].disposableIncome !== undefined ) {
      taxStatementCompleteData[year]['Net Income'] = parseFloat( taxStatementCompleteData[year]['Total Taxes'] )
            + parseFloat( taxStatementCompleteData[year]['Total Tax Credits'] ) + parseFloat( taxStatementCompleteData[year]['Total Investment Property Sale'] );
    } else {
      taxStatementCompleteData[year]['Net Income'] = 0;
    }
    totalTaxesGraph.push( taxStatementCompleteData[year]['Net Income'] );
  }
  // To return Income Statement Sheet Complete Data
  function taxStatementData() {
    taxStatementCompleteData['Total Disposable Income Graph'] = totalDisposableGraph;
    taxStatementCompleteData['Total Living Expenses Graph'] = totalLivingExpensesGraph;

    taxStatementCompleteData['Income After Taxes Graph'] = incomeAfterTaxesGraph;
    taxStatementCompleteData['Total Depreciation Expenses Graph'] = depreciationExpensesGraph;
    taxStatementCompleteData['Total Interest Expenses Graph'] = totalInterestExpensesGraph;
    taxStatementCompleteData['Total Expenses Graph'] = totalExpensesGraph;
    taxStatementCompleteData['Net Income Graph'] = netIncomeGraph;
    taxStatementCompleteData['Spouses Total Income'] = spousesTotalIncome;

    // Tax Statement Graphs
    taxStatementCompleteData['Total Gross Income Graph'] = totalGrossIncomeGraph;
    taxStatementCompleteData['REI Property Taxable Income Graph'] = reiPropertyTaxableIncomeGraph;
    taxStatementCompleteData['Passive Losses Taken Graph'] = PassiveLossesTakenGraph;
    taxStatementCompleteData['rei Property Taxable Income Graph Loss'] = reiPropertyTaxableIncomeGraphLoss;

    taxStatementCompleteData['Total Adjustments Graph'] = totalAdjustmentsGraph;
    taxStatementCompleteData['Adjustments Gross Graph'] = adjustmentsGrossGraph;
    taxStatementCompleteData['Itemized Deductions Graph'] = itemizedDeductionsGraph;
    taxStatementCompleteData['Standard Deductions Graph'] = StandardDeductionsGraph;
    taxStatementCompleteData['Total Deductions Graph'] = totalDeductionsGraph;

    taxStatementCompleteData['Federal Taxable Income Graph'] = federalTaxableIncomeGraph;
    taxStatementCompleteData['Total Taxes before Credits Graph'] = totalTaxesBeforeCreditsGraph;
    taxStatementCompleteData['Total Taxes Credits Graph'] = totalTaxesCreditsGraph;
    taxStatementCompleteData['Total Taxes Graph'] = totalTaxesGraph;
    return taxStatementCompleteData;
  }
  return taxStatementData();
}
export default taxStatement;