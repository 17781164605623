const styles = ( ) => ( {
  default: {
    display: 'flex',
    justifyContent: 'center',
  },
  fullHeight: {
    minHeight: '100vh',
    alignItems: 'center',
  },
  fixedMenuDisplayMainNone: {
    position: 'fixed',
    top: '0',
    width: '400px',
    background: 'rgba(226,226,226,.92)',
    padding: '27px 0 0',
    fontSize: '20px',
    height: '100vh',
    right: '-100%',
    opacity: '0',
    transition: 'all ease-in-out 0.3s',
    '& h3': {
      margin: '0 0 15px',
      borderBottom: '1px solid #ccc',
      padding: '0 0 15px 18px',
      fontSize: '26px',
      fontWeight: 'normal',
      lineHeight: '26px',
      position: 'relative',
      color: '#337ab7',
      textDecoration: 'underline',
      fontFamily: 'MuseoSans-500',
      cursor: 'pointer',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& p': {
      padding: '5px 18px',
      margin: '0',
      fontSize: '18px',
      lineHeight: '28px',
      whiteSpace: 'pre-line',
      color: '#333',
    },
  },
  fixedMenuDisplayMain: {
    position: 'fixed',
    right: '0',
    top: '0',
    width: '420px',
    background: 'rgba(226,226,226,.92)',
    padding: '27px 0 0',
    fontSize: '20px',
    height: '100vh',
    zIndex: '99',
    transition: 'all ease-in-out 0.3s',
    maxHeight: 'calc(100vh - 25px)',
    overflowY: 'auto',
    // '@media (max-width: 3500px)': {
    //   width: '400px',
    // },
    // '@media (max-width: 1400px)': {
    //   width: '400px',
    // },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '420px',
    },
    '& h3': {
      margin: '0 0 15px',
      borderBottom: '1px solid #ccc',
      padding: '0 0 15px 18px',
      fontSize: '26px',
      fontWeight: 'normal',
      lineHeight: '26px',
      position: 'relative',
      color: '#337ab7',
      textDecoration: 'underline',
      fontFamily: 'MuseoSans-500',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      },
    },
    '& a':{
      cursor: 'pointer',
      color: '#337ab7',
      '&:hover': {
        color: '#f89b23',
        '& h3':{
          color: '#f89b23',
        }
      },
    },
    '& p': {
      padding: '5px 18px',
      margin: '0',
      fontSize: '18px',
      lineHeight: '28px',
      whiteSpace: 'pre-line',
      color: '#333',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        lineHeight: '24px',
      },
      '& a': {
        textDecoration: 'underline',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
  },
  TitleWithoutLink: {
      margin: '0 0 15px',
      borderBottom: '1px solid #ccc',
      padding: '0 0 15px 18px',
      fontSize: '26px',
      fontWeight: 'normal',
      lineHeight: '26px',
      position: 'relative',
      color: '#337ab7',
      fontFamily: 'MuseoSans-500',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      },
    },
  fixedMenuHeader: {
    position: 'relative',
    right: '4px',
    top: '-25px',
    fontSize: '24px',
    color: '#000',
    zIndex: '9',
    cursor: 'pointer',
    float: 'right',
    '&:hover': {
      color: '#f89b23',
    },
  },
  btnGroup: {
    padding: '15px 18px',
    '& button': {
      lineHeight: '20px',
      '&:first-child': {
        backgroundColor: '#0069aa',
        color: '#fff',
        borderRadius: '3px',
        padding: '8px 20px',
        textTransform: 'uppercase',
        fontSize: '16px',
        border: '2px solid #0069aa',
        '@media (max-width: 767px)': {  
          padding: '8px 10px',
          fontSize: '14px',
        },
        '&:hover': {
          border: '2px solid #d5d5d5',
        },
        '& a':{
          color: '#fff',
        }
      },
      '&:last-child': {
        marginLeft: '5px',
        backgroundColor: '#fff',
        color: '#333',
        border: '2px solid #fff',
        borderRadius: '3px',
        padding: '8px 20px',
        textTransform: 'uppercase',
        fontSize: '16px',
        '@media (max-width: 767px)': {
          padding: '8px 10px',
          fontSize: '14px',
        },
        '&:hover': {
          border: '2px solid #d5d5d5',
        },
      },
    },
  },
  clickHere: {
    color: '#fff',
    cursor: 'pointer',    
  },
} );

export default styles;
