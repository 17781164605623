import discoveryBanner from '../../../assets/img/discovery/discovery_challenge_banner.jpg';
import downArrow from '../../../assets/img/awareness/down-arrow.png';
import checkImg from '../../../assets/img/awareness/arrow-check.png';
import tickMark from '../../../assets/img/discovery/tick_mark.png';
import discoveryChallengeBg from '../../../assets/img/discovery/discovery-challenge_bg.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  awarenessBody: {
    overflowX: 'hidden',
  },
  awarenessBannerSec: {
    backgroundImage: `url(${discoveryBanner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    padding: '14% 0',
    position: 'relative',
    zIndex: '9',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20% 0',
    },
    '&:before': {
      content: '""',
      background: 'rgba(0, 0, 0, 0.1)',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0px',
      top: '0px',
      zIndex: '-1',
    },
  },
  bannerContent: {
    position: 'absolute',
    transform: 'translateY(-45%)',
    MozTransform: 'translateY(-45%)',
    WebkitTransform: 'translateY(-45%)',
    top: '52%',
    width: '100%',
    left: '0px',
    '& h2': {
      fontSize: '54px',
      textAlign: 'center',
      fontWeight: '300',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
      },
    },
  },
  awarenessContentRow: {
    padding: '40px 0 0',
  },
  awarenessTitle: {
    textAlign: 'center',
    '& h2': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '48px',
      lineHeight: '48px',
      color: theme.palette.common.blue,
      padding: '0 0 12px 0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      },
    },
    '& p': {
      fontSize: '29px',
      lineHeight: '44px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      padding: '10px 0px 50px',
      textAlign: 'center',
      maxWidth: '980px',
      margin: 'auto',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '24px',
        lineHeight: '30px',
      },
    },
  },
  textSup: {
    fontSize: '14px',
    top: '-20px',
    position: 'relative',
    fontWeight: '900',
  },
  awarenessForm: {
    padding: '55px 0 70px',
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0 70px',
      width: '100%',
    },
    '& h1': {
      fontSize: '48px',
      lineHeight: '48px',
      margin: '0 0 30px 0',
      padding: '0',
      textAlign: 'center',
      color: theme.palette.common.blue,
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
    },
  },
  awareInput: {
    maxWidth: '485px',
    width: '45%',
    height: 'inherit',
    fontSize: '58px',
    lineHeight: '58px',
    padding: '15px',
    background: 'transparent',
    outline: 'none',
    margin: '0 auto 30px auto',
    color: '#0069aa',
    border: '1px solid #0069aa',
    borderRadius: '4px',
    webkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
    webkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      maxWidth: '340px',
      padding: '0px',
    },
  },
  awareFormInput: {
    textAlign: 'center',
  },
  ageRangeTitle: {
    fontSize: '48px',
    lineHeight: '48px',
    margin: '0 0 30px 0',
    padding: '0',
    textAlign: 'center',
    color: theme.palette.common.blue,
    fontFamily: 'MuseoSans-100',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
  },
  agesUl: {
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0 auto',
    padding: '10px 0 0 0',
    textAlign: 'center',
    width: '80%',
    '& li': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      textAlign: 'center',
      width: 'auto',
    },
  },
  resumeChallenge: {
    textTransform: 'uppercase',
    background: '#ed7d31',
    fontSize: '23px',
    lineHeight: '34px',
    margin: '0',
    padding: '12px 22px',
    borderRadius: '10px',
    color: theme.palette.common.white,
    textDecoration: 'none',
    display: 'inline-block',
    border: '2px solid #ed7d31',
    '&:after': {
      content: '""',
      width: '24px',
      height: '24px',
      backgroundImage: `url(${downArrow})`,
      backgroundSize: 'contain',
      margin: ' 0 auto',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      display: 'table',
    },
    '&:hover': {
      background: '#d47c0a',
      borderColor: '#d47c0a',
    },
  },
  startChallenge: {
    background: '#539cc9',
    borderColor: '#539cc9',
    '&:hover': {
      background: '#3b81ad',
      borderColor: '#3b81ad',
    },
  },
  finishChallenge: {
    background: '#84aa47',
    borderColor: '#84aa47',
    '&:hover': {
      background: '#759840',
      borderColor: '#759840',
    },
  },
  scBtn: {
    textAlign: 'center',
  },
  redoAwarness: {
    display: 'table',
    margin: '10px auto 0',
    fontSize: '15px',
    cursor: 'pointer',
    border: '1px solid  #0069aa',
    padding: '5px 15px',
    borderRadius: '5px',
    verticalAlign: 'middle',
    color: theme.palette.common.blue,
    '&:hover': {
      background: theme.palette.common.blue,
      color: theme.palette.common.white,
    },
  },
  awernessQuestionsSec: {
    backgroundImage: `url(${discoveryChallengeBg})`,
    backgroundRepeat: 'repeat',
    backgroundPosition: 'center top',
    backgroundSize: '100%',
    position: 'relative',
    zIndex: '9',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.6)',
      zIndex: '-1',
    },
  },
  ChallengesQuizHead: {
    background: '#539cc8',
    padding: '8px',
    top: '56px',
    width: '100%',
    zIndex: '9',

    [theme.breakpoints.down( 'sm' )]: {
      top: '70px',
    },
    '& h3': {
      textAlign: 'center',
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      lineHeight: '36px',
      fontSize: '27px',
      fontFamily: '"MuseoSans-500"',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
  },
  ChallengesQuizSub: {
    display: 'none',
    background: 'transparent',
  },
  FixedChallengesQuizHead: {
    position: 'fixed',
  },
  ChallengesNumbers: {
    position: 'fixed',
    top: '52%',
    left: '-11em',
    zIndex: '9999',
    transform: 'translateY(-50%)',
    WebkitTransform: 'translateY(-50%)',
    MozTransform: 'translateY(-50%)',
    MsTransform: 'translateY(-50%)',
    OTransform: 'translateY(-50%)',
    transition: 'all 0.5s ease-in-out',
    WebkitTransition: 'all 0.5s ease-in-out',
    MozTransition: 'all 0.5s ease-in-out',
    MsTransition: 'all 0.5s ease-in-out',
    OTransition: 'all 0.5s ease-in-out',
    [theme.breakpoints.down( 'md' )]: {
      display: 'none',
    },
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      margin: '0',
      '& li': {
        display: 'block',
        padding: '0 0 5px 0',
        margin: '0 5px 0 0',
        fontFamily: '"MuseoSans-100"',
        '& button': {
          padding: '0px',
          minWidth: 'auto',
          '&:hover': {
            background: theme.palette.common.transparent,
          },
        },
        '& span': {
          display: 'block',
          width: '33px',
          height: '33px',
          borderRadius: '100%',
          textAlign: 'center',
          fontSize: '15px',
          transition: 'all 0.5s ease-in-out',
          WebkitTransition: 'all 0.5s ease-in-out',
          MozTransition: 'all 0.5s ease-in-out',
          MsTransition: 'all 0.5s ease-in-out',
          OTransition: 'all 0.5s ease-in-out',
          '& span': {
            border: '0px solid #ffffff',
          },
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
  stickyBottom: {
    '& button': {
      padding: '0px',
      minWidth: 'inherit',
      '&:hover': {
        background: 'transparent',
      },
      '& span': {
        display: 'block',
        width: '33px',
        height: '33px',
        borderRadius: '100%',
        border: '1px solid #ffffff',
        textAlign: 'center',
        fontSize: '15px',
        transition: 'all 0.5s ease-in-out',
        WebkitTransition: 'all 0.5s ease-in-out',
        MozTransition: 'all 0.5s ease-in-out',
        MsTransition: 'all 0.5s ease-in-out',
        OTransition: 'all 0.5s ease-in-out',
        '& span': {
          border: '0px solid #ffffff',
        },
        '&:nth-child(2)': {
          display: 'none',
        },
      },
    },
  },
  feedbackImg: {
    marginTop: '5px',
    '& button': {
      padding: '0px',
      '&:hover': {
        background: 'transparent',
      },
      '& span': {
        width: '33px',
        border: '1px solid #ffffff',
        height: '33px',
        margin: '0px auto',
        borderRadius: '50%',
        '& figure': {
          margin: '0px',
          '& img': {
            width: '100%',
          },
        },
        '& span': {
          border: '0px solid #ffffff',
        },
        '&:nth-child(2)': {
          display: 'none',
        },
      },
    },
  },
  listNumArrow: {
    '& span': {
      background: theme.palette.common.blue,
      color: theme.palette.common.white,
      lineHeight: '33px',
      '& span': {
        '&:hover': {
          background: '#09314a',
        },
      },
    },
  },
  listNum: {
    '& span': {
      background: theme.palette.common.blue,
      color: theme.palette.common.white,
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'none',
        },
        '&:hover': {
          background: '#09314a',
        },
      },
    },
  },
  listNumRight: {
    '& span': {
      background: '#39b54a !important',
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'inline-block',
          fontSize: '10px',
          marginLeft: '1px',
        },
        '&:hover': {
          background: theme.palette.common.green,
        },
      },
    },
  },
  listNumWrong: {
    '& span': {
      background: '#ff0000 !important',
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'inline-block',
          fontSize: '10px',
          marginLeft: '1px',
        },
        '&:hover': {
          background: theme.palette.common.red,
        },
      },
    },
  },
  listNumActive: {
    '& span': {
      background: '#09314a',
      lineHeight: '33px',
      '& i': {
        display: 'inline-block',
        fontSize: '10px',
        marginLeft: '1px',
      },
    },
  },
  arrowUp: {
    '& span': {
      lineHeight: '37px',
      '& i': {
        fontSize: '25px',
        lineHeight: '33px',
        filter: 'brightness(0) invert(1)',
      },
    },
  },
  questionRow: {
    marginTop: '0px',
    [theme.breakpoints.down( 'sm' )]: {
      marginLeft: '0px',
      marginTop: '0px',
    },
  },
  questionBlock: {
    padding: '38px 0 15px 0',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 0 15px 0',
    },
  },
  scrollBlur: {
    opacity: '0.3',
  },
  questionNum: {
    display: 'inline-block',
    fontSize: '30px',
    lineHeight: '48px',
    verticalAlign: 'miiddle',
    background: theme.palette.common.blue,
    color: theme.palette.common.white,
    border: '1px solid #ffffff',
    borderRadius: '100%',
    width: '50px',
    height: '50px',
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'xs' )]: {
      width: '33px',
      height: '33px',
      lineHeight: '33px',
      fontSize: '14px',
    },
  },
  questionTitle: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '80%',
    fontSize: '26px',
    lineHeight: '30px',
    color: theme.palette.common.blue,
    padding: '0 0 0 10px',
    margin: '0',
    textAlign: 'left',
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
      lineHeight: '23px',
    },
  },
  optionList: {
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0',
    padding: '20px 0 0 0px',
    textAlign: 'center',
    fontFamily: '"MuseoSans-300"',
    listStyle: 'none',
    '& li': {
      verticalAlign: 'top',
      padding: '0',
      textAlign: 'center',
      position: 'relative',
      fontSize: '24px',
      lineHeight: '30px',
      width: '80%',
      margin: '0 auto',
      display: 'flex',
      marginBottom: '25px',
      alignItems: 'center',
      color: '#0069aa',
      '& input': {
        position: 'absolute',
        left: '50px',
        opacity: '0',
        width: '125px',
        height: '100px',
        top: '-52px',
        zIndex: '9',
        cursor: 'pointer',
      },
      '& [type="radio"]:not(:checked)+label:after': {
        opacity: '0',
        transform: 'scale(0)',
      },
      '& label': {
        paddingLeft: '25px',
        paddingRight: '20px',
        paddingTop: '55px',
        fontSize: '24px',
        lineHeight: '24px',
        position: 'relative',
        cursor: 'pointer',
        color: theme.palette.common.blue,
        fontFamily: '"museo-sans", sans-serif',
        '&:before': {
          content: '""',
          position: 'absolute',
          left: '50%',
          top: '0',
          width: '40px',
          height: '40px',
          border: '2px solid #7cb1d3',
          background: theme.palette.common.white,
          borderRadius: '100%',
          marginLeft: '-20px',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          left: '50%',
          top: '0',
          transition: 'all .2s',
          textTransform: 'none',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          textShadow: '0px 0px 2px #0069aa',
          width: '42px',
          height: '42px',
          marginLeft: '-19px',
          marginTop: '1px',
          backgroundRepeat: 'no-repeat',
          opacity: '1',
          transform: 'scale(1)',
          borderRadius: '50%',
        },
      },
      '& input[type="radio"]:checked+label:after': {
        opacity: '1',
        transform: 'scale(1)',
      },
    },
  },
  radioGreen: {
    '& input[type="radio"]:checked+label:before': {
      border: '2px solid #39b54a',
    },
    '& input[type="radio"]:checked+label:after': {
      background: '#39b54a',
      textShadow: '0px 0px 2px #39b54a',
    },
  },
  radioRed: {
    '& input[type="radio"]:checked+label:before': {
      border: '2px solid #ff0000',
    },
    '& input[type="radio"]:checked+label:after': {
      background: theme.palette.common.red,
      textShadow: '0px 0px 2px #ff0000',
    },
  },
  senseRatingComments: {
    margin: '30px -25px 0px',
    textAlign: 'center',
    padding: '30px 15px 30px 15px',
    background: '#f8f8f8',
    '& h2': {
      lineHeight: '24px',
      fontFamily: '"MuseoSans-300"',
      margin: '0 0 30px 0',
      fontSize: '20px',
      color: theme.palette.common.blue,
    },
  },
  awernesssOptionList: {
    width: '100%',
    padding: '50px 0 0 0',
    '& li': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      textAlign: 'center',
      width: 'auto',
      position: 'relative',
      [theme.breakpoints.down( 'sm' )]: {
        minHeight: '100px',
        width: '50%',
      },
      '& input': {
        position: 'absolute',
        left: '0',
        opacity: '0',
        width: '170px',
        height: '100px',
        top: '-52px',
        zIndex: '9',
        cursor: 'pointer',
      },
      '& [type="radio"]:not(:checked)+label:after': {
        opacity: '0',
        transform: 'scale(0)',
      },
      '& label': {
        paddingLeft: '25px',
        paddingRight: '20px',
        paddingTop: '55px',
        fontSize: '24px',
        lineHeight: '24px',
        position: 'relative',
        cursor: 'pointer',
        color: theme.palette.common.blue,
        fontFamily: '"museo-sans", sans-serif',
        '&:before': {
          content: '""',
          position: 'absolute',
          left: '50%',
          top: '0',
          width: '40px',
          height: '40px',
          border: '2px solid #7cb1d3',
          background: theme.palette.common.white,
          borderRadius: '100%',
          marginLeft: '-20px',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          left: '50%',
          top: '0',
          transition: 'all .2s',
          textTransform: 'none',
          WebkitFontSmoothing: 'antialiased',
          MozOsxFontSmoothing: 'grayscale',
          textShadow: '0px 0px 2px #0069aa',
          backgroundSize: '27px',
          width: '30px',
          height: '30px',
          marginLeft: '-12px',
          marginTop: '11px',
          backgroundRepeat: 'no-repeat',
          opacity: '1',
          transform: 'scale(1)',
        },
      },
      '& input[type="radio"]:checked+label:after': {
        opacity: '1',
        transform: 'scale(1)',
      },
    },
  },
  ageChecked: {
    '&:after': {
      backgroundImage: `url(${checkImg})`,
    },
  },
  formControl: {
    width: '100%',
  },
  radioGroup: {
    width: '100%',
    flexDirection: 'initial',
    '& span': {
      color: theme.palette.common.blue,
      '&:last-child': {
        display: 'block',
        fontSize: '24px',
        color: theme.palette.common.blue,
        fontFamily: '"museo-sans", sans-serif',
        textAlign: 'left',
        width: 'calc(100% - 60px)',
        marginLeft: '10px',
        lineHeight: '30px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
          lineHeight: '23px',
        },
      },
      '& svg': {
        width: '40px',
        height: '40px',
        // border: '2px solid #7cb1d3',
        // borderRadius: '50%',
        [theme.breakpoints.down( 'xs' )]: {
          width: '25px',
          height: '25px',
        },
        '& path': {
          position: 'relative',
        },
      },
    },
  },
  cotrolLabel: {
    verticalAlign: 'top',
    padding: '0',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 30px',
    paddingLeft: '145px',
    '& > span:first-child': {
      '&:hover': {
        background: 'rgba(0, 105, 170, 0.1)',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      paddingLeft: '40px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0',
      margin: '0px 0px 10px',
    },
    '&:last-child': {
      marginRight: '0px',
    },
    '& span': {
      '&:first-child': {
        padding: '0px',
        width: '40px',
        height: '40px',
        marginLeft: '0px',
      },
    },
  },
  ansWrong: {
    '& span': {
      '& svg': {
        color: theme.palette.common.red,
      },
    },
  },
  ansRight: {
    '& span': {
      '& svg': {
        color: '#39b54a',
      },
    },
  },
  ansRow: {
    margin: '0 auto',
    background: theme.palette.common.white,
    padding: '25px 40px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px 0px',
    },
  },
  ansTitle: {
    fontFamily: '"MuseoSans-500"',
    margin: '0 0 20px 0',
    textAlign: 'center',
    fontSize: '32px',
    lineHeight: '32px',
    fontWeight: '300',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '22px',
    },
  },
  ansCurrect: {
    color: '#39b54a',
  },
  answrong: {
    color: theme.palette.common.red,
  },
  ansBox: {
    padding: '25px 25px 0px 25px',
    border: '1px solid #0069aa',
    textAlign: 'left',
    color: '#333',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '15px 30px 0px 30px',
    },
  },
  boxTitle: {
    fontSize: '30px',
    lineHeight: '30px',
    fontFamily: '"MuseoSans-500"',
    color: theme.palette.common.blue,
    margin: '0 0 20px 0',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
      lineHeight: '23px',
    },
  },
  boxPara: {
    fontSize: '19px',
    lineHeight: '24px',
    fontFamily: '"MuseoSans-300"',
    margin: '0 0 13px 0',
    color: theme.palette.common.black,
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  spanColor: {
    color: '#ed7d31',
    fontWeight: '600',
  },
  textBlue: {
    color: theme.palette.common.blue,
    textDecoration: 'underline',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.orange,
    },
  },
  textBlueLink: {
    color: theme.palette.common.blue,
    fontWeight: '600',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.common.orange,
    },
  },
  spanStrong: {
    fontWeight: '600',
  },
  nextQuestion: {
    textAlign: 'right',
    '& a': {
      fontSize: '24px',
      lineHeight: '24px',
      color: theme.palette.common.blue,
      marginRight: '0',
      marginTop: '30px',
      fontFamily: '"MuseoSans-500"',
      display: 'inline-block',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down( 'xs' )]: {
        lineHeight: '22px',
        fontSize: '16px',
        marginTop: '10px',
      },
    },
  },
  graphBox: {
    '& img': {
      width: '100%',
    },
  },
  BoxBlue: {
    border: '1px solid #0069aa',
    padding: '5px 10px',
    color: theme.palette.common.blue,
    '& p': {
      fontFamily: '"MuseoSans-300"',
      marginTop: '5px',
      marginBottom: '5px',
      '& span': {
        fontWeight: '600',
      },
    },
  },
  BoxGreen: {
    border: '1px solid #39b54a',
    padding: '5px 10px',
    color: theme.palette.common.green,
    margin: '20px 0',
    '& p': {
      fontFamily: '"MuseoSans-300"',
      marginTop: '5px',
      marginBottom: '5px',
      '& span': {
        fontWeight: '600',
      },
    },
  },
  BoxRed: {
    border: '1px solid #ff0000',
    padding: '5px 10px',
    color: theme.palette.common.red,
    [theme.breakpoints.down( 'sm' )]: {
      marginBottom: '20px',
    },
    '& p': {
      fontFamily: '"MuseoSans-300"',
      marginTop: '5px',
      marginBottom: '5px',
      '& span': {
        fontWeight: '600',
      },
    },
  },
  borderLine: {
    borderRight: '2px dashed #a6a6a6',
  },
  GridPad: {
    padding: '15px 0px 25px',
  },
  whatsMyScore: {
    padding: '70px 0',
    textAlign: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '40px 0',
    },
    '& button': {
      display: 'inline-block',
      padding: '13px 24px',
      border: '2px solid #0069aa',
      color: theme.palette.common.blue,
      fontSize: '36px',
      lineHeight: '36px',
      borderRadius: '5px',
      verticalAlign: 'middle',
      fontFamily: '"museo-sans", sans-serif',
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.common.blue,
        textDecoration: 'none',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
  },
  progressBar: {
    background: theme.palette.common.blue,
    padding: '10px 0',
    width: '100%',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    paddingLeft: '30px',
    '& p': {
      maxWidth: '350px',
      width: '100%',
      color: theme.palette.common.white,
      fontSize: '18px',
      lineHeight: '18px',
      margin: '0px 0px 8px',
    },
    '& div': {
      maxWidth: '336px',
      width: '100%',
      display: 'block',
      background: theme.palette.common.white,
      height: '15px',
      '& span': {
        background: '#39b54a',
        display: 'inline-block',
        height: '15px',
      },
    },
  },
  awernessScoreSec: {
    padding: '0 0 25px',
  },
  awernessTitle: {
    padding: '20px 0',
    lineHeight: '0',
    textAlign: 'center',
    color: theme.palette.common.white,
    background: '#014372',
    '& img': {
      maxWidth: '410px',
    },
  },
  awernessBlock: {
    padding: '20px 0px',
  },
  awernessBlockLeft: {
    '& h3': {
      fontSize: '30px',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-500"',
      margin: '10px 0 25px 0',
      textAlign: 'left',
      fontWeight: '500',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
    '& span': {
      color: theme.palette.common.blue,
      fontSize: '40px',
      fontWeight: '600',
      textAlign: 'center',
      display: 'block',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
      '& span': {
        display: 'block',
      },
    },
    '& img': {
      width: '100%',
      padding: '30px 0',
    },
  },
  awernessBlockRight: {
    paddingLeft: '30px',
  },
  moneyfreeRow: {
    marginTop: '30px',
    '& button': {
      display: 'inline-block',
      padding: '10px 24px',
      color: '#ffffff',
      border: '2px solid #f59a34',
      backgroundColor: '#f59a34',
      fontSize: '26px',
      lineHeight: '36px',
      borderRadius: '5px',
      verticalAlign: 'middle',
      width: '100%',
      textAlign: 'center',
      margin: '20px 0',
      '&:hover': {
        backgroundColor: '#ea8d24',
        border: '2px solid #ea8d24',
      },
    },
    '& h3': {
      fontSize: '32px',
      fontFamily: '"MuseoSans-500"',
      margin: '10px 0 25px 0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
    '& p': {
      textAlign: 'center',
      margin: '0 auto 20px',
      padding: '0',
      fontSize: '23px',
      lineHeight: '30px',
      color: '#0069aa',
      maxWidth: '950px',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
      },
    },
    '& h4': {
      fontSize: '25px',
      fontFamily: '"MuseoSans-300"',
      paddingBottom: '23px',
      lineHeight: '1.42857143',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      },
    },
  },
  formInput: {
    marginBottom: '20px',
    maxWidth: '60%',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '100%',
    },
    '& > div': {
      width: '100%',
      '& label': {
        background: theme.palette.common.white,
        padding: '5px',
        width: 'auto',
        fontSize: '20px',
        transform: 'translate(15px, 10px) scale(1)',
        color: theme.palette.common.backlight,
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
        },
      },
      '& input': {
        fontSize: '20px',
        padding: '12px 10px',
        color: theme.palette.common.black,
        border: '1px solid #ccc',
        borderRadius: '0',
        outline: 'none',
        boxShadow: 'none',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
        },
      },
    },
    '& fieldset': {
      display: 'none',
    },
  },
  formBtn: {
    marginBottom: '20px',
    '& button': {
      width: '100%',
      padding: '10px 24px',
      fontSize: '26px',
      lineHeight: '36px',
      borderRadius: '5px',
      display: 'block',
      background: theme.palette.common.orange,
      color: theme.palette.common.white,
      border: '2px solid #ed7d31',
      '&:hover': {
        background: theme.palette.common.orangeDark,
        border: '2px solid #e9782c',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '23px',
      },
    },
  },
  bottomText: {
    fontSize: '20px',
    paddingBottom: '23px',
    fontStyle: 'italic',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
    },
  },
  circles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '30px',
  },
  circleWithText: {
    background: '#0069aa',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    textAlign: 'center',
    margin: '5px 20px',
    padding: '15px',
    display: 'flex',
    height: '180px',
    width: '180px',
    color: '#fff',
    fontSize: '20px',
  },
  halfScreen: {
    fontSize: '38px',
    margin: '30px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.2',
    color: '#ed7d31',
    fontFamily: 'MuseoSans-300',
    '& span': {
      fontSize: '100px',
      marginRight: '70px',
      display: 'inline-block',
      color: '#ed7d31',
      lineHeight: '80px',
      float: 'left',
      fontFamily: 'MuseoSans-500',
    },
  },
  linkText: {
    fontSize: '19px',
    float: 'none',
    textDecoration: 'underline',
    lineHeight: '24px',
    color: '#0069aa',
    marginRight: '0',
    marginTop: '30px',
    fontFamily: 'MuseoSans-500',
    '&:hover': {
      color: '#ed7d31',
    },
  },
  checkMarkImg: {
    width: '13px',
    marginRight: '6px',
  },
  inflationTable: {
    width: '100%',
    border: '1px solid #fff',
    marginBottom: '20px',
    borderCollapse: 'collapse',
    '& thead': {
      '& tr': {
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          fontSize: '18px',
          borderBottom: '0px solid #fff',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          background: '#cfd5ea',
        },
        '&:nth-child(even)': {
          background: '#e9ebf5',
        },
        '& td': {
          '&:nth-child(1)': {
            textAlign: 'left',
          },
          fontFamily: 'MuseoSans-300',
          fontSize: '17px',
          color: '#323232',
          padding: '8px',
          lineHeight: '1.42857143',
          textAlign: 'center',
          border: '1px solid #fff',
        },
      },
    },
  },
  headerRow: {
    background: '#4472c4',
    color: '#fff',
  },
  textCenter: {
    textAlign: 'center',
  },
  errorText: {
    textAlign: 'center',
    color: theme.palette.common.red,
  },
  pendinglistPopup: {
    '& > div > div': {
      maxWidth: '750px',
      '& > div > div': {
        padding: '0 0 15px',
        '& h3': {
          borderBottom: '1px solid #e5e5e5',
          fontSize: '24px',
          margin: '0 -24px',
          paddingLeft: '25px',
          textALign: 'left',
          paddingBottom: '13px',
          color: '#0069aa',
          marginBottom: '20px',
          textAlign: 'left',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
        },
        '& p': {
          textAlign: 'left',
          fontSize: '20px',
        },
      },
    },
  },
  pendingNumList: {
    fontSize: '18px',
    padding: '15px 30px',
    lineHeight: '28px',
    textAlign: 'center',
    '& p': {
      margin: '0px',
    },
  },
  buttonOk: {
    padding: '8px 14px',
    borderTop: '1px solid #e5e5e5',
    '& button': {
      fontSize: '17px',
      color: '#fff',
      background: '#0069aa',
      borderColor: '#0069aa',
      '&:hover': {
        background: '#0069aa',
      },
    },
  },
  boxFifty: {
    borderRight: '3px solid #ed7d31',
    [theme.breakpoints.down( 'sm' )]: {
      borderRight: '0px solid #ed7d31',
    },
    '& h4': {
      textDecoration: 'underline',
      fontSize: '26px',
      textAlign: 'center',
      paddingBottom: '10px',
      lineHeight: '30px',
      fontFamily: '"MuseoSans-500"',
      margin: '0 0 20px 0',
      color: theme.palette.common.blue,
    },
  },
  withStrategyContent: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '72px',
    marginBottom: '35px',
    '& span': {
      fontSize: '35px',
      minWidth: '87px',
      textAlign: 'right',
      fontWeight: '500',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-500"',
    },
    '& p': {
      color: theme.palette.common.blue,
      paddingLeft: '20px',
      paddingRight: '20px',
      marginBottom: '0',
      fontSize: '19px',
      lineHeight: '24px',
      fontFamily: '"MuseoSans-300"',
      margin: '0 0 13px 0',
    },
  },
  textBlack: {
    borderRight: '0',
    '& h4': {
      color: '#000',
    },
  },
  textBlackCnt: {
    '& span': {
      color: '#000',
    },
    '& p': {
      color: '#323232',
    },
  },
  figureImg: {
    margin: '0px',
    marginBottom: '30px',
    '& img': {
      width: '100%',
    },
  },
  arrowList: {
    textAlign: 'left',
    padding: '15px 0',
    '& p': {
      fontSize: '19px',
      textAlign: 'left',
    },
    '& li': {
      width: 'calc(100% - 30px)',
      fontSize: '19px',
      textAlign: 'left',
      paddingBottom: '8px',
      listStyle: 'none',
      background: `url(${tickMark}) no-repeat left 4px`,
      padding: '0 0 13px 30px',
      backgroundSize: '12px',
    },
  },
  boxParaDiv: {
    padding: '10px 0px 20px 30px',
    '& p': {
      marginBottom: '0px',
    },
  },
  questionTitlespan: {
    display: 'block',
    margin: '25px 0px',
  },
  textBlock: {
    display: 'block',
  },
  retirementTable: {
    border: '1px solid #fff',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    borderCollapse: 'collapse',
    '& thead': {
      '& tr': {
        '& th': {
          color: theme.palette.common.white,
          borderBottom: '2px solid #9cc2e5',
          textAlign: 'center',
          fontSize: '18px',
          fontFamily: 'MuseoSans-300',
          background: theme.palette.common.blue,
          padding: '8px',
          borderCollapse: 'collapse',
          '&:first-child': {
            borderLeft: '0',
            borderRight: '0',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          fontFamily: 'MuseoSans-300',
          fontSize: '17px',
          border: '1px solid #fff',
          padding: '8px',
          textAlign: 'right',
          '&:first-child': {
            borderLeft: '0',
            textAlign: 'left',
          },
          '&:last-child': {
            borderRight: '0',
          },
        },
        '&:nth-child(odd)': {
          '& td': {
            backgroundColor: '#d2deef',
          },
        },
        '&:last-child': {
          '& td': {
            background: theme.palette.common.blue,
            color: theme.palette.common.white,
          },
        },
      },
    },
  },
  textRed: {
    color: '#ff0000',
  },
  creditImg: {
    '& figure': {
      margin: '0px',
      width: '60%',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
        marginTop: '30px',
      },
      '& img': {
        maxWidth: '130px',
        marginTop: '-30px',
      },
    },
  },
  boxParaBoldHead: {
    fontSize: '19px',
    lineHeight: '24px',
    fontFamily: '"MuseoSans-500"',
    textDecoration: 'underline',
    margin: '0 0 13px 0',
  },
  AssumptionsImg: {
    '& figure': {
      margin: '0px',
      '& img': {
        width: '100%',
      },
    },
  },
  numberList: {
    fontSize: '19px',
    listStyleType: 'decimal',
  },
  whatmyScoreRow: {
    position: 'relative',
  },
  myScoreTitle: {
    background: '#fd9840',
    padding: '10px 0px',
    margin: '0 0 30px 0',
    '& h3': {
      fontSize: '27px',
      lineHeight: '36px',
      textAlign: 'center',
      fontFamily: 'MuseoSans-500',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
  },
  graphhRow: {
    display: 'block',
    textAlign: 'center',
  },
  inkImportRank: {
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'relative',
    right: '-12px',
    '& span': {
      display: 'block',
      position: 'absolute',
      right: '-130px',
      top: '46%',
      transform: 'translateY(-50%) rotate(-90deg)',
      WebkitTransform: 'translateY(-50%) rotate(-90deg)',
      MozTransform: 'translateY(-50%) rotate(-90deg)',
      MsTransform: 'translateY(-50%) rotate(-90deg)',
      OTransform: 'translateY(-50%) rotate(-90deg)',
      width: '340px',
      fontFamily: '"MuseoSans-300"',
      fontSize: '16px',
      lineHeight: '14px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        right: '-140px',
      },
    },
    '& p': {
      display: 'block',
      height: '73px',
      margin: '0',
      position: 'relative',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        height: '60px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        height: '40px',
      },
      '&:after': {
        position: 'absolute',
        content: '""',
        right: '-22px',
        width: '13px',
        top: '8px',
        borderBottom: '1px dotted #a1a1a1',
      },
    },
  },
  inkAwaerenessScoreCntIn: {
    display: 'inline-block',
    position: 'relative',
    padding: '0 35px',
    margin: '0 0 8px 0',
    height: '100%',
    maxWidth: 'calc(100% - 100px)',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: 'calc(100% - 50px)',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      bottom: '0',
      left: '30px',
      width: '1px',
      height: '100%',
      background: '#a1a1a1',
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: '40px',
      left: '0px',
      height: '1px',
      width: '100%',
      background: '#a1a1a1',
    },
  },
  inkAwaerenessCateCnt: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRight: '2px dotted #a1a1a1',
    padding: '0 7px 0 4px',
  },
  inkAwaerenessCateIn: {
    margin: '0 4px',
    display: 'inline-block',
    verticalAlign: 'bottom',
    textAlign: 'center',
    height: '380px',
    position: 'relative',
    width: '32px',
    [theme.breakpoints.down( 'sm' )]: {
      width: '10px',
      height: '270px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '5px',
      height: '270px',
      margin: '0 2px',
    },
    '& p': {
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '40px',
      color: theme.palette.common.blue,
      margin: '0',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      background: theme.palette.common.white,
    },
  },
  inkAwaerenessCateScoreRed: {
    background: '#fc5c2c',
    display: 'inline-block',
    verticalAlign: 'bottom',
    width: '100%',
    height: '100%',
    position: 'absolute',
    padding: '0',
    bottom: '0',
    left: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  inkAwaerenessCateScoreBlue: {
    background: '#0069aa',
    display: 'inline-block',
    verticalAlign: 'bottom',
    width: '100%',
    height: '100%',
    position: 'absolute',
    padding: '0',
    bottom: '0',
    left: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  inkScoreBottomSec: {
    padding: '0 0 80px 0',
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0',
      padding: '0 0 20px 0',
      '& li': {
        display: 'inline-block',
        padding: '0',
        '& p': {
          fontSize: '16px',
          lineHeight: '14px',
          margin: '0',
          fontFamily: '"MuseoSans-300"',
          color: theme.palette.common.blue,
          '& span': {
            width: '34px',
            height: '30px',
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: '0 11px 0 38px',
          },
        },
      },
    },
  },
  quetionsPara: {
    margin: '0 0 20px 0',
    fontFamily: '"MuseoSans-300"',
    fontSize: '14px',
    lineHeight: '14px',
    color: theme.palette.common.blue,
  },
  correctBox: {
    background: '#0069aa',
  },
  wrongBox: {
    background: '#fc5c2c',
  },
  quetionsParaTwo: {
    display: 'block',
    textAlign: 'center',
    fontFamily: '"MuseoSans-500"',
    fontSize: '14px',
    lineHeight: '14px',
    color: theme.palette.common.blue,
    margin: '0',
  },
  infoIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '24px',
    height: '24px',
    top: '-10px',
    left: '10px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      top: '-2px',
    },
    '& img': {
      width: '100%',
    },
  },
  myScoreCnt: {
    '& p': {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      padding: '10px 20px 50px',
      maxWidth: '980px',
      margin: 'auto',
      color: theme.palette.common.blue,
      textAlign: 'center',
    },
  },
  awarenessGraph: {
    position: 'relative',
    '& p': {
      fontWeight: '600',
    },
  },
  scoreNumbers: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '140px',
    height: '160px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    fontSize: '15px',
    fontWeight: 500,
    textAlign: 'center',
    display: 'none',
    '& font': {
      fontSize: '30px',
      fontWeight: 700,
    },
  },
  scoreDetails: {
    display: 'flex',
    '& font': {
      color: '#84aa47',
    },
  },
  ratingDetails: {
    '& font': {
      color: '#f79b22',
    },
  },
  tableScroll: {
    [theme.breakpoints.down( 'sm' )]: {
      overflowX: 'auto',
      '& table': {
        '& tr': {
          '& th': {
            [theme.breakpoints.down( 'sm' )]: {
              minWidth: '100px',
            },
          },
        },
      },
    },
  },
  quetionsFigure: {
    margin: '30px 0',
    '& img': {
      width: 'auto',
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
    },
  },
  writeCheckQ9: {
    counterReset: 'list',
    paddingLeft: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingLeft: '0px',
    },
  },
  selectList: {
    counterReset: 'none',
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0',
    padding: '10px 0 0 0',
    textAlign: 'center',
    width: '100%',
  },
  listDropdown: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    paddingLeft: '15px',
    verticalAlign: 'top',
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '0px',
    },
  },
  listCountSelect: {
    paddingLeft: '15px',
    width: 'calc(100% - 55px)',
    textAlign: 'left',
    paddingTop: '0',
    display: 'inline-block',
    '& select': {
      fontSize: '20px',
      lineHeight: '28px',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-300"',
      height: '100%',
      width: '100%',
      boxShadow: 'none',
      padding: '6px 12px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      '&:focus': {
        border: '1px solid #66afe9',
        outline: '0',
      },
      '&:disabled': {
        backgroundColor: '#EBEBE4',
        opacity: 1,
        border: '1px solid #aaaaaa',
        cursor: 'no-drop',
      },
    },
  },
  listCount: {
    width: '37px',
    height: '36px',
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.blue,
    border: '2px solid #0069aa',
    borderRadius: '100%',
    display: 'inline-block',
    fontSize: '24px',
    fontWeight: '400',
  },
  listWrong: {
    backgroundColor: '#ff0000',
    color: theme.palette.common.white,
    border: '2px solid #ff0000',
  },
  listWright: {
    backgroundColor: '#39b54a ',
    color: theme.palette.common.white,
    border: '2px solid #39b54a ',
  },
  checkScoreDiv: {
    marginTop: '0px',
    height: 'auto',
    margin: '0',
    paddingTop: '30px',
    paddingBottom: '5px',
  },
  checkScoreBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blue,
    border: '2px solid #0069aa',
    fontSize: '24px',
    lineHeight: '24px',
    fontFamily: 'MuseoSans-500',
    display: 'inline-block',
    padding: '13px 24px',
    borderRadius: '5px',
    verticalAlign: 'middle',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    '&:hover': {
      color: theme.palette.common.blue,
      backgroundColor: theme.palette.common.white,
      '&:disabled': {
        cursor: 'no-drop',
        color: '#0069aa',
        background: '#eee',
        border: '2px solid gray',
        pointerEvents: 'all',
      },
    },
    '&:disabled': {
      cursor: 'no-drop',
      color: '#0069aa',
      background: '#eee',
      border: '2px solid gray',
      pointerEvents: 'all',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '20px',
      lineHeight: '24px',
      padding: '10px 20px',
    },
  },
  notActive: {
    cursor: 'not-allowed',
    background: '#eee',
    border: '2px solid gray',
    color: theme.palette.common.blue,
    '&:hover': {
      background: '#eee',
    },
  },
  listDivNineUl: {
    paddingInlineStart: '22px',
    '& li': {
      fontSize: '19px',
      paddingBottom: '8px',
      color: theme.palette.common.black,
      listStyle: 'decimal',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
  figureGraphBox: {
    margin: '30px 0px',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '0px 0px 30px',
    },
  },
  gridRow: {
    [theme.breakpoints.down( 'sm' )]: {
      margin: '0px',
    },
  },
  tooltipInfo: {
    width: '120px',
    padding: '0px',
    height: '27px',
    marginLeft: '20px !important',
    lineHeight: '27px',
    textTransform: 'none',
    fontSize: '15px',
    opacity: '1 !important',
    background: '#0069aa !important',
    '&::after': {
      borderColor: '#0069aa',
    },
  },
  redoPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      fontFamily: '"MuseoSans-500"',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0 -15px',
      paddingLeft: '15px',
      fontWeight: 'normal',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  buttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#0069aa',
        borderColor: '#0069aa',
        color: '#fff',
        marginRight: '6px',
      },
      '&:last-child': {
        color: '#333',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },

    },
  },
  creditImgNine: {
    '& figure': {
      margin: '0 auto',
    },

  },
  feedbackTooltip: {
    marginLeft: '10px !important',
  },
  tableMike: {
    width: '80%',
    margin: '0 auto',
    paddingLeft: '54px',
    fontSize: '20px',
    '& table': {
      borderCollapse: 'collapse',
      width: '50%',
      textAlign: 'left',
      color: '#333',
      margin: '10px 0',
      fontSize: '18px',
      '& th': {
        padding: '8px',
        borderBottom: '3px solid #fff',
        color: '#000',
      },
      '& td': {
        padding: '8px',
        background: '#f2f2f2',
        borderBottom: '1px solid #fff',
        '&:last-child': {
          textAlign: 'right',
        },
      },
      '& thead': {
        background: '#dae3f3',
      },
    },

  },
  optionListRating: {
    display: 'table',
    maxWidth: '800px',
    margin: '0 auto',
    padding: '8px 0',
    '& li': {
      width: '60px',
      display: 'table-cell',
      border: '1px solid #0069aa',
      cursor: 'pointer',
      '& span': {
        padding: '10px 0',
        width: '100%',
        height: '100%',
        display: 'block',
        outline: 'none',
      },
    },
  },
  activeClass: {
    background: '#0069aa',
    color: '#fff !important',
  },
  agreeNew: {
    display: 'flex',
    maxWidth: '800px',
    margin: '0 auto',
    fontSize: '20px',
    color: '#0069aa',
    marginTop: '35px',
    '& span': {
      fontFamily: 'MuseoSans-500',
    },
    '& span:last-child': {
      margin: '0 0 0 auto',
    },
  },
  tableResponsive: {
    margin: '15px 0 15px 150px',
    border: '1px solid #000',
    maxWidth: '70%',
    '& table': {
      width: '100%',
      fontSize: '18px',
      fontWeight: 'normal',
      borderCollapse: 'collapse',
      color: '#000',
      '& th': {
        fontWeight: 'normal',
        padding: '8px',
        '&:last-child': {
          textAlign: 'right',
        },
      },
    },
  },
  popupHeading: {
    background: '#0069aa',
    color: '#fff',
    '& th': {
      fontWeight: 'normal',
    },
  },
  popupRow: {
    borderTop: '2px solid #000',
    fontFamily: 'MuseoSans-500',
    '& th': {
      fontFamily: 'MuseoSans-700',
      '&:nth-child(2)': {
        textAlign: 'right',
      },
    },
  },
  popupRight: {
    '& th': {
      paddingBottom: '0 !important',
      paddingTop: '0 !important',
      '&:nth-child(2)': {
        textAlign: 'right',
      },
    },
  },
  popupRightTop: {
    '& th': {
      paddingTop: '20px !important',
    },
  },
  flexClass: {
    display: 'flex',
    '& span': {
      width: '50%',
      textAlign: 'right',
      '&:last-child': {
        textAlign: 'right',
      },
    },
  },
  tbodyBorder: {
    margin: '0 30px',
    width: 'calc(100% - 60px) !important',
    tableLayout: 'fixed',
    '& th': {
      fontFamily: 'MuseoSans-500',
    },
    '& tbody': {
      borderTop: '1px solid #000',
      borderBottom: '1px solid #000',
      borderLeft: '1px solid #000',
      '& td': {
        borderRight: '1px solid #000',
        paddingLeft: '5px',
        paddingRight: '5px',
        textAlign: 'left',
        '&:nth-child(3)': {
          textAlign: 'right',
        },
        '&:last-child': {
          textAlign: 'right',
        },
      },
    },
    '& tfoot': {
      paddingBottom: '15px',
      paddingTop: '5px',
      '& tr:first-child': {
        height: '100px',
      },
      '& input': {
        width: '80px',
        padding: '7px 10px',
        margin: '5px 4px',
        border: '2px solid #ccc!important',
        boxShadow: 'inset 0 2px 2px rgba(0,0,0,.075)',
        borderRadius: ' 0!important',
        fontSize: '16px',
      },
      '& td': {
        textAlign: 'right',
      },
    },
  },
  fontBold: {
    fontFamily: 'MuseoSans-500',
  },
  formGroup: {
    width: '100%',
    maxWidth: '74%',
    margin: '0 auto',
    '& textarea': {
      width: '100%',
      minHeight: '250px',
      borderRadius: '4px',
      border: '2px solid #0069aa',
      padding: '10px 15px',
      fontSize: '16px',
      fontFamily: 'MuseoSans-300',
      '&:focus': {
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
    },
  },
  questionBlockOpacity: {

  },
  whatsMyScorePadding: {
    padding: '20px 0 0',
  },
  pendingNumListPopupMain: {
    '& > div > div': {
      width: '100%',
      maxWidth: '600px',
      color: '#000',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      '& a': {
        padding: '12px 15px',
        borderRadius: '6px',
        backgroundColor: ' #84a84d',
        borderColor: '#84a84d',
        color: '#fff',
      },
    },
  },
  pendingNumListPopupNew: {

    '& i': {
      width: '150px',
      border: '4px solid #008000',
      height: '150px',
      borderRadius: '100%',
      textAlign: 'center',
      lineHeight: '50px',
      fontSize: '85px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto 30px',
      color: '#008000',
    },
    '& span': {
      display: 'block',
      textAlign: 'center',
    },

  },
} );

export default styles;
