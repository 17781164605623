import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();

const Budgeting101 = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/budget_FE_image.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>What&apos;s a Budget?</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/budget.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          A budget is a detailed plan for your income and expenses divided into categories such as rent, utilities, food,
                          and more and covering a specific amount of time such as a month or a year. A plan for the future helps you take
                          charge of your finances and a budget is a part of that plan. A budget helps you repay debt, save,
                          invest and grow your net worth.
                        </p>
                        <p>
                          The most effective strategy to building wealth is actually a simple rule:
                          { ' ' }
                          <b>spend less than you earn.</b>
                          { ' ' }
                          How can you consistently spend less than you earn? Build a budget so you can see exactly where you are spending and make smarter choices about your money. Check out an example of a budget below:
                        </p>
                        <div>
                          <div className={ classes.lightGallery }>
                            <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/whats_budget_new.png" alt="template" />
                          </div>
                        </div>
                        <p className={ classes.lotofWork }>
                          Developing a budget might seem like a lot of work. Inkwiry makes it easy by walking you, step by step, through planning and budgeting. Once you have a plan and a budget, you&apos;ll enjoy confidence and peace of mind about your money.
                        </p>
                      </div>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level3/budgeting101/exercise" startTime={ startTime } level={ 3 } exercise={ 1 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Budgeting101.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Budgeting101 );
