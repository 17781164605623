import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
// import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';

const BusinessValue = ( props ) => {
  const { classes, handleAssestObject, handleUpdatedObject} = props;

  const [isFocus, setIsFocus] = React.useState('');

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    if(type === 'slider'){
      newvalue = value 
    }else{
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    inputValues[field] = newvalue;
    handleUpdatedObject(inputValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>If you own or are a part owner of a business, the value of the business if you were to sell today is an asset in your net worth. 
        Not sure what the value of your business is or how to calculate that? Enter 
        the total sales of your business in the latest year and divide that by your ownership percentage to get a rough estimate.</p>
      <p>Enter the estimated cash value for your business below and the expected growth rate of that business in the future.</p>
      <div className={ classNames(classes.tableBox, classes.tableBoxPWINew) }>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Business</th>
          </thead>
          <tbody>
            <tr>
              <td>Estimated Business Value</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.existing_value_of_business } name="existing_value_of_business" onValueChange={ ( e, value ) => updateValue( e, value, 'existing_value_of_business', 'input' ) } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     
      <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p>Expected Growth Rate</p>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ sliderValues.businessArray() }
              min={ 0 }
              max={ 10 }
              step={ 1 }
              value={ handleAssestObject.existing_expected_growth_rate }
              valueLabelFormat={ `${commonFunctions.perFormatter( handleAssestObject.existing_expected_growth_rate )}` }
              onChange={ ( e, value ) => updateValue( e, value, 'existing_expected_growth_rate', 'slider' ) }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            <NumberFormat
              customInput={ TextField }
              decimalScale={ isFocus!=='existing_expected_growth_rate'?2:5 }
              fixedDecimalScale={isFocus!=='existing_expected_growth_rate'}
              thousandSeparator
              value={ handleAssestObject.existing_expected_growth_rate }
              onValueChange={ ( e ) => updateValue( e, '', 'existing_expected_growth_rate', 'number' ) }
              onFocus={ ( e ) => { e.target.select(); setIsFocus('existing_expected_growth_rate') } }
              onBlur={emptyIsFocus} />
            %
          </div>
        </div>
      </div>
       
    </div>
  );
};

BusinessValue.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( BusinessValue );
