import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import mcsTopImage from '../../../assets/img/mcs/level3_bg.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import levelIcon from '../../../assets/img/mcs/MCS-level05.png';
import progressImage from '../../../assets/img/mcs/100.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Pageloader from '../../../components/ui/pageloader';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const SAVEMYCAREERSKETCH = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');

const staticLevelData = [
  {
    "id": "7",
    "user_id": 214,
    "level": 2,
    "option_id": 1,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:29:33"
  },
  {
    "id": "8",
    "user_id": 214,
    "level": 2,
    "option_id": 2,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:30:01"
  }
]

const McsLevelThree = (props) => {
  const { classes, history } = props;
  const [firstActivityData, setFirstActivityData] = React.useState("");
  const [secondActivityData, setSecondActivityData] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [levelPercentage, setLevelPercentage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
    onCompleted({
      saveMyCareerSketch: {
        status,
      },
    }) {
      if (status) {
        setLoading(false);
        history.push('./level3/activity1');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        message
      ));
      setErrorMessage(formatedErrors);
      setLoading(false);
    },
  });

  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "3",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setLevelPercentage(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent);
      setFirstActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 1)[0] : staticLevelData.filter(obj => obj.option_id === 1)[0]);
      setSecondActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 2)[0] : staticLevelData.filter(obj => obj.option_id === 2)[0]);
    },
    onError() {
      setLoading(false);
    },
  });
  React.useEffect(() => {
    if (mcsLevelWiseData) {
      setLoading(false);
    }
  }, [mcsLevelWiseData]);

  const handleCompletedFirstActivity = (activity_id) => {
    setLoading(true);
    submitFirstActivity({
      variables: {
        data: {
          level: "3",
          option_id: activity_id,
        },
      },
    });
  }
  const handleGotoNextLevel = () => {
    history.push('./level4');
  }
  const handleOpenActivityOne = () => {
    history.push('./level3/activity1');
  }
  const handleCompletedSecondActivity = () => {
    history.push('./level3/activity2');
  }
  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.mcsLevelOne}>
        <div className={classes.topImage}>
          <img src={mcsTopImage} alt="mcs" />
        </div>
        <div className={classes.container}>
          <div className={classes.mcsRow}>
            <div className={classes.mcsLogoImage}>
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.mcsLevelNum}>
              <h3>LEVEL 03</h3>
              <img src={levelIcon} alt="info" className={classes.iconImage} />
            </div>
          </div>
          <div className={classes.mcsBodyContent}>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.leftBlock}>
                  <h1 className={classes.mcsLevelTitle}>Identify your stepping stones</h1>
                  <div className={classes.qPosition}>
                    <div className={classes.questionBlock}>
                      <p>Now that you’ve developed your vision, let’s identify your stepping stones. </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Whether it’s completing a course of study or nailing that big promotion, each stepping stone is a strategic advancement on your career path, an important achievement that allows you to advance your career, earn more and leap forward to your goal.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>As you place your stepping stones on your career sketch timeline, you’ll be able to visualize your roadmap to the future. You’ll know how to reach your goals, and you’ll see when each milestone can be accomplished. </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>And there is no pressure. You are working in a simulator, so enjoy the experience. All ideas are welcome. It’s impossible to make a mistake. As you map out your moves, you can make as many changes as you want until you are satisfied with the outcome.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Let’s get started.</p>
                    </div>
                    {secondActivityData && secondActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleGotoNextLevel}>NEXT LEVEL {'>'}</button>
                    </div> : firstActivityData && firstActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleCompletedSecondActivity}>continue</button>
                    </div> : <div className={classes.startButton}>
                      <button type="button" onClick={() => handleCompletedFirstActivity("1")}>start</button>
                    </div>}
                    {errorMessage && <span>{errorMessage}</span>}
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.rightBlock}>
                  <div className={classes.stonesImage}>
                    <video id="bgvid" preload="auto" muted autoPlay="true" loop playsinline>
                      <source src="https://inkwiry-videos.s3.us-east-2.amazonaws.com/mcs-levels/Level-01.mp4" type="video/mp4" />
                    </video>
                  </div>
                  <div className={classes.activeRow}>
                    <div className={classes.activities}>
                      <h4>Level 03 Activities</h4>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={firstActivityData && firstActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Find your career path
                        </label>
                        {firstActivityData && firstActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={handleOpenActivityOne}> Review</button> : ""}
                      </div>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={secondActivityData && secondActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Place your stepping stones
                        </label>
                        {secondActivityData && secondActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={handleCompletedSecondActivity}>Review</button> : ""}
                      </div>
                    </div>
                    <div className={classes.myLevelProgress}>
                      <h4>My Level 03 Progress</h4>
                    </div>
                    <div className={classes.progressPuzzle}>
                      <span className={classes.progressPercent}>{levelPercentage}%</span>
                      <p className={classes.puzzleBar}>
                        <img src={progressImage} alt="info" />
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mcsFooter}>
          <div className={classes.container}>
            <div className={classes.footerRow}>
              <div className={classes.copyRight}>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={classNames(classes.copyRight, classes.informedText)}>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.mcsFluid}>
            <p className={classes.termLinks}>
              <a href="/">Terms of Service</a>
              &nbsp;//&nbsp;
              <a href="/">Privacy Policy</a>
              &nbsp;//&nbsp;
              <a href="/">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsLevelThree.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsLevelThree);