import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const WalkThruDots = ( props ) => {
  const {
    classes, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrus,
  } = props;

  return (
    <ul className={ classes.checkmarksScroll }>
      {
          [0, 1, 2, 3, 4, 5, 6, 7].map( ( data ) => (
            <li className={ classNames( data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList ) } aria-hidden="true" onClick={ () => { handleWalkThrus( data ); } } />
          ) )
        }
    </ul>
  );
};

WalkThruDots.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWalkThrus: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  activeWalkThruSlide: PropTypes.string.isRequired,
};

export default withStyles( styles )( WalkThruDots );
