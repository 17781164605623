import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';

export function investmentsShowMeGraphData() {
  let graphsData = {};
  const investmentsOutputData = moduleServices.investmentsOutputGraphData();
   const outputSheetCompleteData = moduleServices.outputSheetCompleteData();
  //Bar Graph1

  if (outputSheetCompleteData !== undefined && investmentsOutputData['Years List'] !== undefined && investmentsOutputData['Years List'].length > 0) {
    graphsData['graph1'] = true;
    graphsData['graph1Data'] = {
        chart: {
            plotBackgroundColor: '#ffffff',
            plotBorderColor: '#cccccc',
            plotBorderWidth: 1,
            plotShadow: false,
            // height: moduleGraphHeight,
            // width: graphMainWidth
        },
        navigation: {
                menuItemStyle: {
                    fontSize: '14px',
                    textAlign: 'left'
                },
                menuItemHoverStyle: {
                    background: '#f5f5f5',
                    color: '#4c4c4c',
                    fontSize: '14px'
                  },
                buttonOptions: {
                    height: 40,
                    width: 48,
                    symbolSize: 24,
                    symbolX: 24,
                    symbolY: 21,
                    symbolStrokeWidth: 2,
                    verticalAlign: 'bottom',
                    _titleKey: 'y'
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                      menuItems: [{
                            textKey: 'downloadPNG',
                            onclick() {
                                const chart = this;
                                chart.exportChart();
                            },
                        }, {
                            textKey: 'downloadJPEG',
                            onclick() {
                                const chart = this;
                            chart.exportChart({
                                    type: 'image/jpeg'
                                });
                            }
                        },{
                            textKey: 'downloadPDF',
                            onclick() {
                                const chart = this;
                            chart.exportChart({
                                    type: 'application/pdf'
                                });
                            }
                        }, {
                            textKey: 'downloadSVG',
                            onclick() {
                                const chart = this;
                            chart.exportChart({
                                    type: 'image/svg+xml'
                                });
                            }
                        }]
                    }
                }
              },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: [{
            categories: investmentsOutputData['Years List'],
            crosshair: true,
            labels: {
                style: {
                    color: '#000000',
                    fontSize: '14px'
                }
            }
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                formatter() {
                    const chart = this;
                    if( chart.value < 0 ) {
                        return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                    } else { 
                        return formatDollar(Math.round(chart.value));
                    }
                },
                style: {
                    color: '#000000',
                    fontSize: '14px'
                }
            },
            title: {
                text: 'Short-Term Investments',
                style: {
                    color: '#000000',
                    fontSize: '16px'
                }
            },
            opposite: true
        }],
        /*tooltip: {
            pointFormat: '{series.name} <b>${point.y:,.0f}</b><br />'
        },*/
        tooltip: {
            formatter() {
                const chart = this;
                if( chart.y < 0 ){
                    return chart.series.name +'<br/>'+chart.x +': <span style="color:#bf0000; font-weight:bold;">('+formatDollar(-Math.round(chart.y))+')</span>';
                }
                else{ 
                    return chart.series.name +'<br/>'+chart.x +': <b>'+formatDollar(Math.round(chart.y))+'</b>';
                }
            }
            
        },
        legend: {
            itemStyle: {
                fontSize: '14px'
            }
        },
        series: [{
            name: 'Short-Term Funds Flow',
            type: 'column',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsShortTermFundsFlow'],
            tooltip: {
                valuePrefix: '$'
            },
            color: '#FFBE00',
            formatter() {
                const chart = this;
                if( chart.value < 0 ) {
                    return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                } else { 
                    return formatDollar(Math.round(chart.value));
                }
            }
        },{
            name: 'Short-Term Investments Balance',
            type: 'spline',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsShortTermBalance'],
            tooltip: {
                valuePrefix: '$'
            },
            color: '#FFBE50',
            formatter() {
                const chart = this;
                if( chart.value < 0 ) {
                    return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                } else { 
                    return formatDollar(Math.round(chart.value));
                }
            }
        },{
            name: 'Cash Balance',
            type: 'spline',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsCashBalance'],
            tooltip: {
                valuePrefix: '$'
            },
            color: '#00AC6C',
            formatter() {
                const chart = this;
                if( chart.value < 0 ) {
                    return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                } else { 
                    return formatDollar(Math.round(chart.value));
                }
            }
        }]
    }
  } else {
    graphsData['graph1'] = false;
  }

  if (outputSheetCompleteData !== undefined && investmentsOutputData['Years List'] !== undefined && investmentsOutputData['Years List'].length > 0) {
    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
        chart: {
            plotBackgroundColor: '#ffffff',
            plotBorderColor: '#cccccc',
            plotBorderWidth: 1,
            plotShadow: false,
            // height: moduleGraphHeight,
            // width: graphMainWidth
        },
        navigation: {
            menuItemStyle: {
                fontSize: '14px',
                textAlign: 'left'
            },
            menuItemHoverStyle: {
                background: '#f5f5f5',
                color: '#4c4c4c',
                fontSize: '14px'
            },
            buttonOptions: {
                height: 40,
                width: 48,
                symbolSize: 24,
                symbolX: 24,
                symbolY: 21,
                symbolStrokeWidth: 2,
                verticalAlign: 'bottom',
                _titleKey: 'y'
            }
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [{
                        textKey: 'downloadPNG',
                        onclick() {
                            const chart = this;
                            chart.exportChart();
                        },
                    }, {
                        textKey: 'downloadJPEG',
                        onclick() {
                            const chart = this;
                        chart.exportChart({
                                type: 'image/jpeg'
                            });
                        }
                    }, {
                        textKey: 'downloadPDF',
                        onclick() {
                            const chart = this;
                        chart.exportChart({
                                type: 'application/pdf'
                            });
                        }
                    }, {
                        textKey: 'downloadSVG',
                        onclick() {
                            const chart = this;
                        chart.exportChart({
                                type: 'image/svg+xml'
                            });
                        }
                    }]
                }
            }
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        xAxis: [{
            categories: investmentsOutputData['Years List'],
            crosshair: true,
            labels: {
                style: {
                    color: '#000000',
                    fontSize: '14px'
                }
            }
        }],
        yAxis: [{ // Primary yAxis
            labels: {
                formatter() {
                    const chart = this;
                    if (chart.value < 0) {
                        return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                    } else {
                        return formatDollar(Math.round(chart.value));
                    }
                },
                style: {
                    color: '#000000',
                    fontSize: '14px'
                }
            },
            title: {
                text: 'Long-Term Investments',
                style: {
                    color: '#000000',
                    fontSize: '16px'
                }
            },
            opposite: true
        }],
        /*tooltip: {
            pointFormat: '{series.name} <b>${point.y:,.0f}</b><br />'
        },*/
        tooltip: {
            formatter() {
                const chart = this;
                if (chart.y < 0) {
                    return chart.series.name + '<br/>' + chart.x + ': <span style="color:#bf0000; font-weight:bold;">(' + formatDollar(-Math.round(chart.y)) + ')</span>';
                }
                else {
                    return chart.series.name + '<br/>' + chart.x + ': <b>' + formatDollar(Math.round(chart.y)) + '</b>';
                }
            }
        },
        legend: {
            itemStyle: {
                fontSize: '14px'
            }
        },
        series: [{
            name: 'Long-Term Funds Flow',
            type: 'column',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsLongTermFundsFlow'],
            tooltip: {
                valuePrefix: '$'
            },
            color: '#004E71',
            formatter() {
                const chart = this;
                if (chart.value < 0) {
                    return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                } else {
                    return formatDollar(Math.round(chart.value));
                }
            }
        }, {
            name: 'Long-Term Investments Balance',
            type: 'spline',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsLongTermBalance'],
            tooltip: {
                valuePrefix: '$'
            },
            color: '#416C83',
            formatter() {
                const chart = this;
                if (chart.value < 0) {
                    return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                } else {
                    return formatDollar(Math.round(chart.value));
                }
            }
        }, {
            name: 'Cash Balance',
            type: 'spline',
            yAxis: 0,
            data: outputSheetCompleteData['investmentsCashBalance'],
            tooltip: {
                valuePrefix: '$'
            },
            color: '#00AC6C',
            formatter() {
                const chart = this;
                if (chart.value < 0) {
                    return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
                } else {
                    return formatDollar(Math.round(chart.value));
                }
            }
        }]
    }
  } else {
    graphsData['graph2'] = false;
  }
  return graphsData;
}


