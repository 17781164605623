import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';
import BalanceStatementPopup from './balance-sheet-popup';
import * as modulesServices from '../../../calculations/modules-services';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BalanceStatement = (props) => {
  const {
    classes, dispalyObj, rangeList,
  } = props;

  const [openIncomeEarnedDuringHigher, setOpenIncomeEarnedDuringHigher] = useState(false);
  const [balanceSheetDetails, setBalanceSheetDetails] = useState({});
  const [currentColumn, setCurrentColumn] = useState('');

  const displayTitle = (type, name) => {
    let returnText = '';
    switch (name) {
      case 'Spouses Income':
        returnText = "Spouse's Income";
        break;
      case 'Expected Income':
        returnText = 'Career Path';
        break;
      case 'Excepted Income - Secound':
        returnText = 'Career Advancement';
        break;
      case 'Excepted Income - Third':
        returnText = 'Additional Career Advancement';
        break;
      case 'Excepted Income - Fourth':
        returnText = 'Further Career Advancement';
        break;
      default: {
        const data = name.split('-');
        if (data[data.length - 1].trim() === 'Base Income' || data[data.length - 1].trim() === 'Income' || data[data.length - 1].trim() === 'Second'
          || data[data.length - 1].trim() === 'Third' || data[data.length - 1].trim() === 'Fourth') {
          data.pop();
          if (data.length === 1) {
            returnText = data[0].trim();
          } else {
            if (data[data.length - 1].trim() === 'Income' || data[data.length - 1].trim() === 'Second' || data[data.length - 1].trim() === 'Third' || data[data.length - 1].trim() === 'Fourth') {
              data.pop();
            }
            const arr = [];
            data.forEach((value) => {
              arr.push(value);
            });
            returnText = arr.join('-');
          }
        } else {
          returnText = data[0].trim();
        }
        break;
      }
    }
    return returnText;
  };

  const showDetailInfo = (selectedYear, selectedSection, selectedColumn) => {
    if (typeof selectedYear !== 'undefined' && selectedYear !== '' && selectedYear > 0
      && typeof selectedSection !== 'undefined' && selectedSection !== ''
      && typeof selectedColumn !== 'undefined' && selectedColumn !== '') {
      const detailedInfo = {};

      detailedInfo.Year = selectedYear;
      setCurrentColumn(selectedColumn);

      const balanceSheetCompleteData = modulesServices.balanceSheetCompleteData();
      switch (selectedSection) {
        case 'Assets':

          switch (selectedColumn) {
            // Cash
            case 'Cash':
              const cashFlowCompleteData = modulesServices.cashFlowCompleteData();
              detailedInfo.cashFlowCompleteData = cashFlowCompleteData[selectedYear];
              let module1MaxCashBalance = 0;
              if (
                typeof modulesServices.module1Data.maxCashBalance
                !== 'undefined'
                && modulesServices.module1Data.maxCashBalance !== ''
              ) {
                module1MaxCashBalance = modulesServices.module1Data.maxCashBalance;
              } else {
                module1MaxCashBalance = 0;
              }
              detailedInfo.module1MaxCashBalance = module1MaxCashBalance;

              detailedInfo.condition = 0;
              if (
                balanceSheetCompleteData[selectedYear].Assets.Cash !== 0
              ) {
                detailedInfo.condition = 1;
              } else {
                detailedInfo.condition = 0;
              }

              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // Auto Property
            case 'Auto Property':
              const depreciationScheduleCompleteData = modulesServices.depreciationScheduleCompleteData();
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];
              detailedInfo.depreciationScheduleCompleteData = depreciationScheduleCompleteData[selectedYear];

              detailedInfo.depreciation1 = modulesServices.module18Data.existingCarCurrentValue;
              detailedInfo.depreciation2 = modulesServices.totalPurchasePrice();
              detailedInfo.depreciation3 = modulesServices.moreTotalPurchasePrice();

              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // Real Estate Property
            case 'Real Estate Property':
              const buyingAHomeCompleteData = modulesServices.buyingAHomeCompleteData();
              const reiCompleteData = modulesServices.invPropertyRoiCompleteData();
              if (typeof modulesServices.module18Data.existigHomeCurrentValue !== 'undefined') {
                detailedInfo.existingHomeValueFirstYear = modulesServices.module18Data.existigHomeCurrentValue;
              } else {
                detailedInfo.existingHomeValueFirstYear = 0;
              }
              if (typeof modulesServices.module1Data.start_year !== 'undefined') {
                detailedInfo.startYearVal = modulesServices.module1Data.start_year;
              } else {
                detailedInfo.startYearVal = 0;
              }
              detailedInfo.startYearValCondition = 0;
              if (detailedInfo.startYearVal !== selectedYear) {
                detailedInfo.startYearValCondition = 1;
              }
              // Existing Home Values(Value, Appreciation, Final Value)
              const existingHomeData = modulesServices.existingHomeCompleteData();
              if (typeof existingHomeData !== 'undefined' && existingHomeData !== '' && typeof existingHomeData['Real Estate'][selectedYear - 1] !== 'undefined' && typeof existingHomeData['Real Estate'][selectedYear - 1]['Asset Value'] !== 'undefined') {
                detailedInfo.existingHomePreviousYearValue = existingHomeData['Real Estate'][selectedYear - 1]['Asset Value'];
              } else {
                detailedInfo.existingHomePreviousYearValue = 0;
              }
              if (typeof modulesServices.module13Data.appreciation !== 'undefined') {
                detailedInfo.previousYearAppreciation = modulesServices.module13Data.appreciation;
              } else {
                detailedInfo.previousYearAppreciation = 0;
              }
              detailedInfo.existingHomeCurrentYearCondition = 0;
              if (typeof existingHomeData !== 'undefined' && existingHomeData !== '' && typeof existingHomeData['Real Estate'][selectedYear] !== 'undefined' && typeof existingHomeData['Real Estate'][selectedYear]['Asset Value'] !== 'undefined' && existingHomeData['Real Estate'][selectedYear]['Asset Value'] > 0) {
                detailedInfo.existingHomeCurrentYearValue = existingHomeData['Real Estate'][selectedYear]['Asset Value'];
                detailedInfo.existingHomeCurrentYearCondition = 1;
              } else {
                detailedInfo.existingHomeCurrentYearValue = 0;
              }

              detailedInfo.buyingAHomeCompleteData = buyingAHomeCompleteData['Real Estate'];
              detailedInfo.reiCompleteData = reiCompleteData['Real Estate'];
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];

              detailedInfo.condition = 0;
              if ((buyingAHomeCompleteData['Real Estate'] && buyingAHomeCompleteData['Real Estate'][selectedYear]) && buyingAHomeCompleteData['Real Estate'][selectedYear]['Property Tax'] !== 0) {
                detailedInfo.condition = 1;
                detailedInfo.purchaseYear = modulesServices.module13Data.yearOfPurchase;
                detailedInfo.purchasePrice = modulesServices.module13Data.purchasePrice;
                detailedInfo.fitOutCost = modulesServices.module13Data.fitOfCosts;
                detailedInfo.homeValue = detailedInfo.purchasePrice + detailedInfo.fitOutCost;
                detailedInfo.appreciation = modulesServices.module13Data.appreciation;
              }

              detailedInfo.condition1 = 0;
              if ((reiCompleteData['Real Estate'] && reiCompleteData['Real Estate'][selectedYear]) && reiCompleteData['Real Estate'][selectedYear]['Property Tax'] !== 0) {
                detailedInfo.condition1 = 1;
                detailedInfo.purchaseYear1 = modulesServices.module14Data.purchasingYear;
                detailedInfo.purchasePrice1 = modulesServices.module14Data.purchasePrice;
                detailedInfo.fitOutCost1 = modulesServices.module14Data.realEstatefitOfCosts;
                detailedInfo.homeValue1 = detailedInfo.purchasePrice1 + detailedInfo.fitOutCost1;
                detailedInfo.appreciation1 = modulesServices.module14Data.appreciation;
              }
              detailedInfo.previousRealEstateYearValueCondition = 0;
              if (typeof reiCompleteData !== 'undefined' && reiCompleteData !== '' && typeof reiCompleteData['Real Estate'] !== 'undefined' && typeof reiCompleteData['Real Estate'][selectedYear - 1] !== 'undefined' && reiCompleteData['Real Estate'][selectedYear - 1]['Asset Value'] !== 0) {
                detailedInfo.previousRealEstateYearValueCondition = 1;
              }
              detailedInfo.previousHomeValueCondition = 0;
              if (typeof buyingAHomeCompleteData !== 'undefined' && buyingAHomeCompleteData !== '' && typeof buyingAHomeCompleteData['Real Estate'][selectedYear - 1] !== 'undefined' && buyingAHomeCompleteData['Real Estate'][selectedYear - 1]['Asset Value'] !== 0) {
                detailedInfo.previousHomeValueCondition = 1;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // REI Property Accumulated Depreciation
            case 'REI Property Accumulated Depreciation':
              const REIData = modulesServices.invPropertyRoiCompleteData();
              if (typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Real Estate'] !== 'undefined' && typeof REIData['Real Estate'][selectedYear - 1] !== 'undefined') {
                detailedInfo.beginningDepreciationBalanceVal = REIData['Real Estate'][selectedYear - 1].Accumulated;
              } else {
                detailedInfo.beginningDepreciationBalanceVal = 0;
              }
              if (typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Real Estate'] !== 'undefined' && typeof REIData['Real Estate'][selectedYear].Home !== 'undefined') {
                detailedInfo.reiDepreciationValue = REIData['Real Estate'][selectedYear].Home;
              } else {
                detailedInfo.reiDepreciationValue = 0;
              }
              detailedInfo.beginningDepreciationBalanceCurrentCondition = 0;
              if (typeof REIData !== 'undefined' && REIData !== '' && typeof REIData['Real Estate'] !== 'undefined' && typeof REIData['Real Estate'][selectedYear].Accumulated !== 'undefined' && REIData['Real Estate'][selectedYear].Accumulated !== 0) {
                detailedInfo.beginningDepreciationBalanceCurrentVal = REIData['Real Estate'][selectedYear].Accumulated;
                detailedInfo.beginningDepreciationBalanceCurrentCondition = 1;
              } else {
                detailedInfo.beginningDepreciationBalanceCurrentVal = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;
            default:
              break;
          }

          break;
        // new section Invesments
        case 'Investments':
          const investmentsData = modulesServices.investmentsCompleteData();
          switch (selectedColumn) {
            case 'for401k':
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];
              // Beginning 401k Balance
              if (typeof investmentsData !== 'undefined' && investmentsData && typeof investmentsData['401k Second'][selectedYear] !== 'undefined') {
                detailedInfo.totalBeginningBal401k = investmentsData['401k Second'][selectedYear]['Total Beginning Balance'];
                detailedInfo.personalContribution401k = investmentsData['401k Second'][selectedYear]['Personal Contribution'];
                detailedInfo.personalEarnings401k = investmentsData['401k Second'][selectedYear]['Personal Earnings'];
                detailedInfo.employerContribution401k = investmentsData['401k Second'][selectedYear]['Employer Contribution'];
                detailedInfo.employerEarnings401k = investmentsData['401k Second'][selectedYear]['Employer Earnings'];
                detailedInfo.vestingReductions401k = investmentsData['401k Second'][selectedYear]['Vesting Reductions'];
              } else {
                detailedInfo.totalBeginningBal401k = 0;
                detailedInfo.personalContribution401k = 0;
                detailedInfo.personalEarnings401k = 0;
                detailedInfo.employerContribution401k = 0;
                detailedInfo.employerEarnings401k = 0;
                detailedInfo.vestingReductions401k = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // Roth IRA
            case 'Roth IRA':
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];
              if (typeof investmentsData !== 'undefined' && investmentsData && typeof investmentsData['Roth IRA Second'][selectedYear] !== 'undefined') {
                detailedInfo.totalBeginningBalRothIra = investmentsData['Roth IRA Second'][selectedYear]['Beginning Balance'];
                detailedInfo.personalContributionRothIra = investmentsData['Roth IRA Second'][selectedYear]['Annual Contribution'];
                detailedInfo.personalEarningsRothIra = investmentsData['Roth IRA Second'][selectedYear].Earnings;
              } else {
                detailedInfo.totalBeginningBalRothIra = 0;
                detailedInfo.personalContributionRothIra = 0;
                detailedInfo.personalEarningsRothIra = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // Seph IRA
            case 'Seph IRA':
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];
              if (typeof investmentsData !== 'undefined' && investmentsData && typeof investmentsData['Seph IRA Second'][selectedYear] !== 'undefined') {
                detailedInfo.totalBeginningBalSephIra = investmentsData['Seph IRA Second'][selectedYear]['Beginning Balance'];
                detailedInfo.personalContributionSephIra = investmentsData['Seph IRA Second'][selectedYear]['Annual Contribution'];
                detailedInfo.personalEarningsSephIra = investmentsData['Seph IRA Second'][selectedYear].Earnings;
              } else {
                detailedInfo.totalBeginningBalSephIra = 0;
                detailedInfo.personalContributionSephIra = 0;
                detailedInfo.personalEarningsSephIra = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // Pension
            case 'Pension':
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];
              if (typeof investmentsData !== 'undefined' && investmentsData && typeof investmentsData['Pension Second'][selectedYear] !== 'undefined') {
                detailedInfo.totalBeginningBalPension = investmentsData['Pension Second'][selectedYear]['Beginning Balance'];
                detailedInfo.employerContributionPension = investmentsData['Pension Second'][selectedYear]['Employer Contribution'];
                detailedInfo.personalContributionPension = investmentsData['Pension Second'][selectedYear]['Personal Contribution'];
                detailedInfo.personalEarningsPension = investmentsData['Pension Second'][selectedYear].Earnings;
              } else {
                detailedInfo.totalBeginningBalPension = 0;
                detailedInfo.employerContributionPension = 0;
                detailedInfo.personalContributionPension = 0;
                detailedInfo.personalEarningsPension = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // Short-Term Investments
            case 'Short-Term Investments':
              detailedInfo.condition = 0;
              const investmentsCompleteData = modulesServices.investmentsCompleteData();
              if (typeof investmentsCompleteData !== 'undefined' && investmentsCompleteData !== ''
                && typeof investmentsCompleteData['Short-Term Investments'][selectedYear - 1] !== 'undefined'
                && typeof investmentsCompleteData['Short-Term Investments'][selectedYear - 1]['Beg Balance'] !== 'undefined' && investmentsCompleteData['Short-Term Investments'][selectedYear - 1]['Beg Balance'] !== 0) {
                detailedInfo.condition = 1;
              }
              if (balanceSheetCompleteData[selectedYear].Investments['Short-Term Investments'] !== 0 || detailedInfo.condition === 1) {
                detailedInfo.condition = 1;

                detailedInfo.investmentData = modulesServices.investmentsCompleteData();
                detailedInfo.BeginYear = modulesServices.module9Data.startYear;
                detailedInfo.MaxValue = modulesServices.module9Data.stiBalanceBeforelt;
              }
              detailedInfo.finalEndingBalCondition = 0;
              if (typeof investmentsCompleteData !== 'undefined' && investmentsCompleteData !== ''
                && typeof investmentsCompleteData['Short-Term Investments'][selectedYear] !== 'undefined'
                && typeof investmentsCompleteData['Short-Term Investments'][selectedYear]['Ending Balance'] !== 'undefined' && investmentsCompleteData['Short-Term Investments'][selectedYear]['Ending Balance'] !== 0) {
                detailedInfo.finalEndingBalCondition = 1;
                detailedInfo.finalEndingBal = investmentsCompleteData['Short-Term Investments'][selectedYear]['Ending Balance'];
              } else {
                detailedInfo.finalEndingBal = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            // Long-Term Investments
            case 'Long-Term Investments':
              detailedInfo.condition = 0;
              if (typeof investmentsCompleteData !== 'undefined' && investmentsCompleteData !== ''
                && typeof investmentsCompleteData['Long-Term Investments'][selectedYear - 1] !== 'undefined'
                && typeof investmentsCompleteData['Long-Term Investments'][selectedYear - 1]['Beg Balance'] !== 'undefined' && investmentsCompleteData['Short-Term Investments'][selectedYear - 1]['Beg Balance'] !== 0) {
                detailedInfo.condition = 1;
              }

              if (balanceSheetCompleteData[selectedYear].Investments['Long-Term Investments'] !== 0 || detailedInfo.condition === 1) {
                detailedInfo.condition = 1;

                detailedInfo.investmentData = modulesServices.investmentsCompleteData();
                detailedInfo.BeginYear = modulesServices.module9Data.longtermStartYear;
              }
              detailedInfo.finalEndingLongTermBalCondition = 0;
              if (typeof investmentsCompleteData !== 'undefined' && investmentsCompleteData !== ''
                && typeof investmentsCompleteData['Long-Term Investments'][selectedYear] !== 'undefined'
                && typeof investmentsCompleteData['Long-Term Investments'][selectedYear]['Ending Balance'] !== 'undefined' && investmentsCompleteData['Long-Term Investments'][selectedYear]['Ending Balance'] !== 0) {
                detailedInfo.finalEndingLongTermBalCondition = 1;
                detailedInfo.finalEndingLongTermBal = investmentsCompleteData['Long-Term Investments'][selectedYear]['Ending Balance'];
              } else {
                detailedInfo.finalEndingLongTermBal = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);

              break;
            default:
              break;
          }
          break;

        case 'Liabilities':
          switch (selectedColumn) {
            case 'Existing Credit Card Debt':
              const existingCreditCardDebtData = modulesServices.existingCreditCardDebtCompleteData();
              // Beginning Balance
              if (typeof existingCreditCardDebtData !== 'undefined' && existingCreditCardDebtData !== '' && typeof existingCreditCardDebtData['Summation Data'][selectedYear] !== 'undefined' && existingCreditCardDebtData['Summation Data'][selectedYear] !== '' && typeof existingCreditCardDebtData['Summation Data'][selectedYear].BeginningBalance !== 'undefined') {
                detailedInfo.beginningBalanceValue = existingCreditCardDebtData['Summation Data'][selectedYear].BeginningBalance;
              } else {
                detailedInfo.beginningBalanceValue = 0;
              }
              // Principal Payback
              if (typeof existingCreditCardDebtData !== 'undefined' && existingCreditCardDebtData !== '' && typeof existingCreditCardDebtData['Summation Data'][selectedYear] !== 'undefined' && existingCreditCardDebtData['Summation Data'][selectedYear] !== '' && typeof existingCreditCardDebtData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined') {
                detailedInfo.principalPaybackValue = -existingCreditCardDebtData['Summation Data'][selectedYear].LaggingPrincipal;
              } else {
                detailedInfo.principalPaybackValue = 0;
              }
              // Final Value
              if (typeof existingCreditCardDebtData !== 'undefined' && existingCreditCardDebtData !== '' && typeof existingCreditCardDebtData['Summation Data'][selectedYear] !== 'undefined' && existingCreditCardDebtData['Summation Data'][selectedYear] !== '' && typeof existingCreditCardDebtData['Summation Data'][selectedYear].EndingBalance !== 'undefined') {
                detailedInfo.endingBalanceValue = existingCreditCardDebtData['Summation Data'][selectedYear].EndingBalance;
              } else {
                detailedInfo.endingBalanceValue = 0;
              }
              // Condition to Apply
              detailedInfo.endingBalanceValueCondition = 0;
              if (detailedInfo.beginningBalanceValue > 0 || detailedInfo.endingBalanceValue !== 0) {
                detailedInfo.endingBalanceValueCondition = 1;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            case 'Student Loans':
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];

              const existingFedSubStudentLoanData = modulesServices.fedSubExistingLoanCompleteData();
              const existingFedUnsubStudentLoanData = modulesServices.fedUnSubExistingLoanCompleteData();
              const existingPrivateStudentLoanData = modulesServices.privateExistingLoanCompleteData();
              const fedSubHigherEdLoanData = modulesServices.fedSubHigherEdLoanCompleteData();
              const fedUnSubHigherEdLoanData = modulesServices.fedUnSubHigherEdLoanCompleteData();
              const privateHigherEdLoanData = modulesServices.privateHigherEdLoanCompleteData();
              const fedSubMoreHigherEdLoanData = modulesServices.fedSubMoreHigherEdLoanCompleteData();
              const fedUnSubMoreHigherEdLoanData = modulesServices.fedUnSubMoreHigherEdLoanCompleteData();
              const privateMoreHigherEdLoanData = modulesServices.privateMoreHigherEdLoanCompleteData();
              const fedSubNewHigherEdLoanData = modulesServices.fedSubNewHigherEdLoanCompleteData();
              const fedUnSubNewHigherEdLoanData = modulesServices.fedUnSubNewHigherEdLoanCompleteData();
              const privateNewHigherEdLoanData = modulesServices.privateNewHigherEdLoanCompleteData();
              const spouseEdLoanData = modulesServices.spouseStudentLoanCompleteData();

              detailedInfo.existingFedSubStudentLoanEndingBalCondition = 0;

              // Existing FedSub Student Loan
              detailedInfo.existingFedSubStudentLoanBalPreviousYearCondition = 0;
              if (typeof modulesServices.module1Data.start_year !== 'undefined' && modulesServices.module1Data.start_year !== '' && selectedYear === modulesServices.module1Data.start_year && typeof modulesServices.module18Data.existingStudentLoansFedSubBeginningBalance !== 'undefined' && modulesServices.module18Data.existingStudentLoansFedSubBeginningBalance !== '') {
                detailedInfo.existingFedSubStudentLoanBalPreviousYear = modulesServices.module18Data.existingStudentLoansFedSubBeginningBalance;
                detailedInfo.existingFedSubStudentLoanBalPreviousYearCondition = 1;
              } else if (typeof existingFedSubStudentLoanData !== 'undefined' && existingFedSubStudentLoanData !== ''
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(existingFedSubStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.existingFedSubStudentLoanBalPreviousYear = existingFedSubStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.existingFedSubStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.existingFedSubStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.existingFedSubLoansTakenCondition = 0;
              if (typeof existingFedSubStudentLoanData !== 'undefined' && existingFedSubStudentLoanData !== ''
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined'
                && existingFedSubStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 0) {
                detailedInfo.existingFedSubLoansTaken = existingFedSubStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.existingFedSubLoansTakenCondition = 1;
              }
              detailedInfo.existingFedSubPrincipalPaybackCondition = 0;
              if (typeof existingFedSubStudentLoanData !== 'undefined' && existingFedSubStudentLoanData !== ''
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && existingFedSubStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.existingFedSubPrincipalPayback = -existingFedSubStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.existingFedSubPrincipalPaybackCondition = 1;
              }
              detailedInfo.existingFedSubStudentLoanBalCurrentYearCondition = 0;
              if (typeof existingFedSubStudentLoanData !== 'undefined' && existingFedSubStudentLoanData !== ''
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingFedSubStudentLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(existingFedSubStudentLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.existingFedSubStudentLoanBalCurrentYear = existingFedSubStudentLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.existingFedSubStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.existingFedSubStudentLoanBalCurrentYear = 0;
              }
              // Existing FedUnsub Student Loan
              detailedInfo.existingFedUnsubStudentLoanBalPreviousYearCondition = 0;
              if (typeof modulesServices.module1Data.start_year !== 'undefined' && modulesServices.module1Data.start_year !== '' && selectedYear === modulesServices.module1Data.start_year && typeof modulesServices.module18Data.existingStudentLoansFedUnSubCurrentLoan !== 'undefined' && modulesServices.module18Data.existingStudentLoansFedUnSubCurrentLoan !== '') {
                detailedInfo.existingFedUnsubStudentLoanBalPreviousYear = modulesServices.module18Data.existingStudentLoansFedUnSubCurrentLoan;
                detailedInfo.existingFedUnsubStudentLoanBalPreviousYearCondition = 1;
              } else if (typeof existingFedUnsubStudentLoanData !== 'undefined' && existingFedUnsubStudentLoanData !== ''
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(existingFedUnsubStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.existingFedUnsubStudentLoanBalPreviousYear = existingFedUnsubStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.existingFedUnsubStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.existingFedUnsubStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.existingFedUnsubLoansTakenCondition = 0;
              if (typeof existingFedUnsubStudentLoanData !== 'undefined' && existingFedUnsubStudentLoanData !== ''
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined'
                && existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 0) {
                detailedInfo.existingFedUnsubLoansTaken = existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.existingFedUnsubLoansTakenCondition = 1;
              }
              detailedInfo.existingFedUnsubAccruedInterestCondition = 0;
              if (typeof existingFedUnsubStudentLoanData !== 'undefined' && existingFedUnsubStudentLoanData !== ''
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.existingFedUnsubAccruedInterest = existingFedUnsubStudentLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.existingFedUnsubAccruedInterestCondition = 1;
              }
              detailedInfo.existingFedUnsubPrincipalPaybackCondition = 0;
              if (typeof existingFedUnsubStudentLoanData !== 'undefined' && existingFedUnsubStudentLoanData !== ''
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && existingFedUnsubStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.existingFedUnsubPrincipalPayback = -existingFedUnsubStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.existingFedUnsubPrincipalPaybackCondition = 1;
              }
              detailedInfo.existingFedUnsubStudentLoanBalCurrentYearCondition = 0;
              if (typeof existingFedUnsubStudentLoanData !== 'undefined' && existingFedUnsubStudentLoanData !== ''
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingFedUnsubStudentLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(existingFedUnsubStudentLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.existingFedUnsubStudentLoanBalCurrentYear = existingFedUnsubStudentLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.existingFedUnsubStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.existingFedUnsubStudentLoanBalCurrentYear = 0;
              }
              // Existing Private Student Loan
              detailedInfo.existingPrivateStudentLoanBalPreviousYearCondition = 0;
              if (typeof modulesServices.module1Data.start_year !== 'undefined' && modulesServices.module1Data.start_year !== '' && selectedYear === modulesServices.module1Data.start_year && typeof modulesServices.module18Data.existingStudentLoansPrivateCurrentLoan !== 'undefined' && modulesServices.module18Data.existingStudentLoansPrivateCurrentLoan !== '') {
                detailedInfo.existingPrivateStudentLoanBalPreviousYear = modulesServices.module18Data.existingStudentLoansPrivateCurrentLoan;
                detailedInfo.existingPrivateStudentLoanBalPreviousYearCondition = 1;
              } else if (typeof existingPrivateStudentLoanData !== 'undefined' && existingPrivateStudentLoanData !== ''
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(existingPrivateStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.existingPrivateStudentLoanBalPreviousYear = existingPrivateStudentLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.existingPrivateStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.existingPrivateStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.existingPrivateLoansTakenCondition = 0;
              if (typeof existingPrivateStudentLoanData !== 'undefined' && existingPrivateStudentLoanData !== ''
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined'
                && existingPrivateStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 0) {
                detailedInfo.existingPrivateLoansTaken = existingPrivateStudentLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.existingPrivateLoansTakenCondition = 1;
              }
              detailedInfo.existingPrivateAccruedInterestCondition = 0;
              if (typeof existingPrivateStudentLoanData !== 'undefined' && existingPrivateStudentLoanData !== ''
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && existingPrivateStudentLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.existingPrivateAccruedInterest = existingPrivateStudentLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.existingPrivateAccruedInterestCondition = 1;
              }
              detailedInfo.existingPrivatePrincipalPaybackCondition = 0;
              if (typeof existingPrivateStudentLoanData !== 'undefined' && existingPrivateStudentLoanData !== ''
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && existingPrivateStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.existingPrivatePrincipalPayback = -existingPrivateStudentLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.existingPrivatePrincipalPaybackCondition = 1;
              }
              detailedInfo.existingPrivateStudentLoanBalCurrentYearCondition = 0;
              if (typeof existingPrivateStudentLoanData !== 'undefined' && existingPrivateStudentLoanData !== ''
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof existingPrivateStudentLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(existingPrivateStudentLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.existingPrivateStudentLoanBalCurrentYear = existingPrivateStudentLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.existingPrivateStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.existingPrivateStudentLoanBalCurrentYear = 0;
              }
              // Completed
              // Federal Subsidized Higher Education Student Loan
              detailedInfo.fedSubStudentLoanBalPreviousYearCondition = 0;
              if (typeof fedSubHigherEdLoanData !== 'undefined' && fedSubHigherEdLoanData !== ''
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(fedSubHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedSubStudentLoanBalPreviousYear = fedSubHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.fedSubStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.fedSubStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.fedSubLoansTakenCondition = 0;
              if (typeof fedSubHigherEdLoanData !== 'undefined' && fedSubHigherEdLoanData !== ''
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined'
                && fedSubHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 0) {
                detailedInfo.fedSubLoansTaken = fedSubHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.fedSubLoansTakenCondition = 1;
              }
              detailedInfo.fedSubPrincipalPaybackCondition = 0;
              if (typeof fedSubHigherEdLoanData !== 'undefined' && fedSubHigherEdLoanData !== ''
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && fedSubHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.fedSubPrincipalPayback = -fedSubHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.fedSubPrincipalPaybackCondition = 1;
              }
              detailedInfo.fedSubStudentLoanBalCurrentYearCondition = 0;
              if (typeof fedSubHigherEdLoanData !== 'undefined' && fedSubHigherEdLoanData !== ''
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(fedSubHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedSubStudentLoanBalCurrentYear = fedSubHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.fedSubStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.fedSubStudentLoanBalCurrentYear = 0;
              }

              // Federal Unsubsidized Higher Education Student Loan
              detailedInfo.fedUnsubStudentLoanBalPreviousYearCondition = 0;
              if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== ''
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(fedUnSubHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedUnsubStudentLoanBalPreviousYear = fedUnSubHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.fedUnsubStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.fedUnsubStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.fedUnSubLoansTakenCondition = 0;
              if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== ''
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined'
                && fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 0) {
                detailedInfo.fedUnsubLoansTaken = fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.fedUnsubLoansTakenCondition = 1;
              }
              detailedInfo.fedUnsubAccruedInterestCondition = 0;
              if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== ''
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.fedUnsubAccruedInterest = fedUnSubHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.fedUnsubAccruedInterestCondition = 1;
              }
              detailedInfo.fedUnsubPrincipalPaybackCondition = 0;
              if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== ''
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && fedUnSubHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.fedUnsubPrincipalPayback = -fedUnSubHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.fedUnsubPrincipalPaybackCondition = 1;
              }
              detailedInfo.fedUnsubStudentLoanBalCurrentYearCondition = 0;
              if (typeof fedUnSubHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== ''
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(fedUnSubHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedUnsubStudentLoanBalCurrentYear = fedUnSubHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.fedUnsubStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.fedUnsubStudentLoanBalCurrentYear = 0;
              }
              // Private Higher Education Student Loan
              detailedInfo.privateStudentLoanBalPreviousYearCondition = 0;
              if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== ''
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined' && privateHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance.toFixed() !== 0) {
                detailedInfo.privateStudentLoanBalPreviousYear = privateHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.privateStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.privateStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.privateLoansTakenCondition = 0;
              if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== ''
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined'
                && privateHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 0) {
                detailedInfo.privateLoansTaken = privateHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.privateLoansTakenCondition = 1;
              }
              detailedInfo.privateAccruedInterestCondition = 0;
              if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== ''
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && privateHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.privateAccruedInterest = privateHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.privateAccruedInterestCondition = 1;
              }
              detailedInfo.privatePrincipalPaybackCondition = 0;
              if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== ''
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && privateHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.privatePrincipalPayback = -privateHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.privatePrincipalPaybackCondition = 1;
              }
              detailedInfo.privateStudentLoanBalCurrentYearCondition = 0;
              if (typeof privateHigherEdLoanData !== 'undefined' && privateHigherEdLoanData !== ''
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(privateHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.privateStudentLoanBalCurrentYear = privateHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.privateStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.privateStudentLoanBalCurrentYear = 0;
              }
              // Federal Subsidized More Higher Education Student Loan
              detailedInfo.fedSubMoreStudentLoanBalPreviousYearCondition = 0;
              if (typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== ''
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(fedSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedSubMoreStudentLoanBalPreviousYearCondition = 1;
                detailedInfo.fedSubMoreStudentLoanBalPreviousYear = fedSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
              } else {
                detailedInfo.fedSubMoreStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.fedSubMoreLoansTakenCondition = 0;
              if (typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== ''
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined'
                && fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 0) {
                detailedInfo.fedSubMoreLoansTaken = fedSubMoreHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.fedSubMoreLoansTakenCondition = 1;
              }
              detailedInfo.fedSubMorePrincipalPaybackCondition = 0;
              if (typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== ''
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && fedSubMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.fedSubMorePrincipalPayback = -fedSubMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.fedSubMorePrincipalPaybackCondition = 1;
              }
              detailedInfo.fedSubMoreStudentLoanBalCurrentYearCondition = 0;
              if (typeof fedSubMoreHigherEdLoanData !== 'undefined' && fedSubMoreHigherEdLoanData !== ''
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(fedSubMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedSubMoreStudentLoanBalCurrentYear = fedSubMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.fedSubMoreStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.fedSubMoreStudentLoanBalCurrentYear = 0;
              }
              // Federal Unsubsidized More Higher Education Student Loan
              detailedInfo.fedUnsubMoreStudentLoanBalPreviousYearCondition = 0;
              if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== ''
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedUnsubMoreStudentLoanBalPreviousYearCondition = 1;
                detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear = fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
              } else {
                detailedInfo.fedUnsubMoreStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.fedUnSubMoreLoansTakenCondition = 0;
              if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubHigherEdLoanData !== ''
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined'
                && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 0) {
                detailedInfo.fedUnsubMoreLoansTaken = fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.fedUnsubMoreLoansTakenCondition = 1;
              }
              detailedInfo.fedUnsubMoreAccruedInterestCondition = 0;
              if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== ''
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.fedUnsubMoreAccruedInterest = fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.fedUnsubMoreAccruedInterestCondition = 1;
              }
              detailedInfo.fedUnsubMorePrincipalPaybackCondition = 0;
              if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== ''
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.fedUnsubMorePrincipalPayback = -fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.fedUnsubMorePrincipalPaybackCondition = 1;
              }
              detailedInfo.fedUnsubMoreStudentLoanBalCurrentYearCondition = 0;
              if (typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && fedUnSubMoreHigherEdLoanData !== ''
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear = fedUnSubMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.fedUnsubMoreStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.fedUnsubMoreStudentLoanBalCurrentYear = 0;
              }
              // Private More Higher Education Student Loan
              detailedInfo.privateMoreStudentLoanBalPreviousYearCondition = 0;
              if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== ''
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(privateMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.privateMoreStudentLoanBalPreviousYearCondition = 1;
                detailedInfo.privateMoreStudentLoanBalPreviousYear = privateMoreHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
              } else {
                detailedInfo.privateMoreStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.privateMoreLoansTakenCondition = 0;
              if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== ''
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined'
                && privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 0) {
                detailedInfo.privateMoreLoansTaken = privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.privateMoreLoansTakenCondition = 1;
              }
              detailedInfo.privateMoreAccruedInterestCondition = 0;
              if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== ''
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.privateMoreAccruedInterest = privateMoreHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.privateMoreAccruedInterestCondition = 1;
              }
              detailedInfo.privateMorePrincipalPaybackCondition = 0;
              if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== ''
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && privateMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.privateMorePrincipalPayback = -privateMoreHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.privateMorePrincipalPaybackCondition = 1;
              }
              detailedInfo.privateMoreStudentLoanBalCurrentYearCondition = 0;
              if (typeof privateMoreHigherEdLoanData !== 'undefined' && privateMoreHigherEdLoanData !== ''
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(privateMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.privateMoreStudentLoanBalCurrentYear = privateMoreHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.privateMoreStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.privateMoreStudentLoanBalCurrentYear = 0;
              }

              // Federal Subsidized Further Higher Education Student Loan
              if (typeof fedSubNewHigherEdLoanData !== 'undefined' && fedSubNewHigherEdLoanData !== ''
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined') {
                detailedInfo.fedSubNewStudentLoanBalPreviousYear = fedSubNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
              } else {
                detailedInfo.fedSubNewStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.fedSubNewLoansTakenCondition = 0;
              if (typeof fedSubNewHigherEdLoanData !== 'undefined' && fedSubNewHigherEdLoanData !== ''
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 'undefined'
                && fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'] !== 0) {
                detailedInfo.fedSubNewLoansTaken = fedSubNewHigherEdLoanData['Summation Data'][selectedYear]['W/O Accrual'];
                detailedInfo.fedSubNewLoansTakenCondition = 1;
              }
              detailedInfo.fedSubNewPrincipalPaybackCondition = 0;
              if (typeof fedSubNewHigherEdLoanData !== 'undefined' && fedSubNewHigherEdLoanData !== ''
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && fedSubNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.fedSubNewPrincipalPayback = -fedSubNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.fedSubNewPrincipalPaybackCondition = 1;
              }
              detailedInfo.fedSubNewStudentLoanBalCurrentYearCondition = 0;
              if (typeof fedSubNewHigherEdLoanData !== 'undefined' && fedSubNewHigherEdLoanData !== ''
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedSubNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(fedSubNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedSubNewStudentLoanBalCurrentYear = fedSubNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.fedSubNewStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.fedSubNewStudentLoanBalCurrentYear = 0;
              }
              // Federal Unsubsidized More Higher Education Student Loan
              detailedInfo.fedUnsubNewStudentLoanBalPreviousYearCondition = 0;
              if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== ''
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedUnsubNewStudentLoanBalPreviousYear = fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.fedUnsubNewStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.fedUnsubNewStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.fedUnSubNewLoansTakenCondition = 0;
              if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== ''
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined'
                && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 0) {
                detailedInfo.fedUnsubNewLoansTaken = fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.fedUnsubNewLoansTakenCondition = 1;
              }
              detailedInfo.fedUnsubNewAccruedInterestCondition = 0;
              if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== ''
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.fedUnsubNewAccruedInterest = fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.fedUnsubNewAccruedInterestCondition = 1;
              }
              detailedInfo.fedUnsubNewPrincipalPaybackCondition = 0;
              if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== ''
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.fedUnsubNewPrincipalPayback = -fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.fedUnsubNewPrincipalPaybackCondition = 1;
              }

              detailedInfo.fedUnsubNewStudentLoanBalCurrentYearCondition = 0;
              if (typeof fedUnSubNewHigherEdLoanData !== 'undefined' && fedUnSubNewHigherEdLoanData !== ''
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.fedUnsubNewStudentLoanBalCurrentYear = fedUnSubNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.fedUnsubNewStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.fedUnsubNewStudentLoanBalCurrentYear = 0;
              }
              // Private More Higher Education Student Loan
              detailedInfo.privateNewStudentLoanBalPreviousYearCondition = 0;
              if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== ''
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear - 1] !== 'undefined'
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance !== 'undefined'
                && parseFloat(privateNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance).toFixed() !== 0) {
                detailedInfo.privateNewStudentLoanBalPreviousYear = privateNewHigherEdLoanData['Summation Data'][selectedYear - 1].EndingBalance;
                detailedInfo.privateNewStudentLoanBalPreviousYearCondition = 1;
              } else {
                detailedInfo.privateNewStudentLoanBalPreviousYear = 0;
              }
              detailedInfo.privateNewLoansTakenCondition = 0;
              if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== ''
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 'undefined'
                && privateNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'] !== 0) {
                detailedInfo.privateNewLoansTaken = privateNewHigherEdLoanData['Summation Data'][selectedYear]['Loans Taken'];
                detailedInfo.privateNewLoansTakenCondition = 1;
              }
              detailedInfo.privateNewAccruedInterestCondition = 0;
              if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== ''
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 'undefined'
                && privateNewHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'] !== 0) {
                detailedInfo.privateNewAccruedInterest = privateNewHigherEdLoanData['Summation Data'][selectedYear]['Accrued Interest'];
                detailedInfo.privateNewAccruedInterestCondition = 1;
              }
              detailedInfo.privateNewPrincipalPaybackCondition = 0;
              if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== ''
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined'
                && privateNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.privateNewPrincipalPayback = -privateNewHigherEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.privateNewPrincipalPaybackCondition = 1;
              }
              detailedInfo.privateNewStudentLoanBalCurrentYearCondition = 0;
              if (typeof privateNewHigherEdLoanData !== 'undefined' && privateNewHigherEdLoanData !== ''
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && typeof privateNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance !== 'undefined'
                && parseFloat(privateNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance).toFixed() !== 0) {
                detailedInfo.privateNewStudentLoanBalCurrentYear = privateNewHigherEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.privateNewStudentLoanBalCurrentYearCondition = 1;
              } else {
                detailedInfo.privateNewStudentLoanBalCurrentYear = 0;
              }
              // Spouse Education Loan Data
              detailedInfo.spouseStudentLoanBalanceValCondition = 0;
              if (typeof spouseEdLoanData !== 'undefined' && spouseEdLoanData !== ''
                && typeof spouseEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && spouseEdLoanData['Summation Data'][selectedYear] !== ''
                && spouseEdLoanData['Summation Data'][selectedYear].BeginningBalance > 0) {
                detailedInfo.spouseStudentLoanBalanceValue = spouseEdLoanData['Summation Data'][selectedYear].BeginningBalance;
                detailedInfo.spouseStudentLoanBalanceValCondition = 1;
              } else {
                detailedInfo.spouseStudentLoanBalanceValue = 0;
              }
              detailedInfo.spousePrincipalPaybackValCondition = 0;
              if (typeof spouseEdLoanData !== 'undefined' && spouseEdLoanData !== ''
                && typeof spouseEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && spouseEdLoanData['Summation Data'][selectedYear] !== ''
                && spouseEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.spousePrincipalPaybackValue = -spouseEdLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.spousePrincipalPaybackValCondition = 1;
              } else {
                detailedInfo.spousePrincipalPaybackValue = 0;
              }
              detailedInfo.spouseStudentLoanBalanceFinalValCondition = 0;
              if (typeof spouseEdLoanData !== 'undefined' && spouseEdLoanData !== ''
                && typeof spouseEdLoanData['Summation Data'][selectedYear] !== 'undefined'
                && spouseEdLoanData['Summation Data'][selectedYear] !== ''
                && spouseEdLoanData['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.spouseStudentLoanBalanceFinalValue = spouseEdLoanData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.spouseStudentLoanBalanceFinalValCondition = 1;
              } else {
                detailedInfo.spouseStudentLoanBalanceFinalValue = 0;
              }
              // Spouse Education Loan Data Completed
              if (typeof balanceSheetCompleteData !== 'undefined' && balanceSheetCompleteData !== '' && typeof balanceSheetCompleteData[selectedYear].Liabilities['Student Loans'] !== 'undefined') {
                detailedInfo.finalTotalStudentLoans = balanceSheetCompleteData[selectedYear].Liabilities['Student Loans'];
              } else {
                detailedInfo.finalTotalStudentLoans = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            case 'Car Loans':
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];
              detailedInfo.purchasingFirstYear = modulesServices.module12Data.purchasingFirstYear;
              detailedInfo.purchasingFirstPrice = modulesServices.module12Data.totalPurchasePrice;
              detailedInfo.purchasingFirstDownPayment = modulesServices.module12Data.downPayment;
              detailedInfo.purchasingFirstCarLoan = modulesServices.module12Data.carLoan;
              detailedInfo.purchasingSecondYear = modulesServices.module12Data.purchasingSecondYear;
              detailedInfo.purchasingSecondPrice = modulesServices.module12Data.moreTotalPurchasePrice;
              detailedInfo.purchasingSecondDownPayment = modulesServices.module12Data.moreDownPayment;
              detailedInfo.purchasingSecondCarLoan = modulesServices.module12Data.moreCarLoan;

              const carLoan1Data = modulesServices.carLoan1CompleteData();
              const carLoan2Data = modulesServices.carLoan2CompleteData();
              // Existing car Data
              const existingCarLoanData = modulesServices.existingCarLoanCompleteData();
              detailedInfo.existingCarLoanCondition = 0;
              if (typeof existingCarLoanData !== 'undefined' && existingCarLoanData !== ''
                && typeof existingCarLoanData['Summation Data'][selectedYear] !== 'undefined'
                && parseFloat(existingCarLoanData['Summation Data'][selectedYear].EndingBalance) > 0) {
                detailedInfo.existingCarLoanPreviousValue = existingCarLoanData['Summation Data'][selectedYear].BeginningBalance;
                detailedInfo.existingPrincipalPayback = existingCarLoanData['Summation Data'][selectedYear].LaggingPrincipal;
                detailedInfo.existingCarLoanValue = parseFloat(existingCarLoanData['Summation Data'][selectedYear].EndingBalance).toFixed(2);
              }
              if (typeof detailedInfo.existingCarLoanValue !== 'undefined' && detailedInfo.existingCarLoanValue !== 0) {
                detailedInfo.existingCarLoanCondition = 1;
              }
              // Completed
              detailedInfo.firstCarCondition = 0;
              if (typeof carLoan1Data !== 'undefined' && carLoan1Data !== ''
                && typeof carLoan1Data['Summation Data'][selectedYear] !== 'undefined'
                && parseFloat(carLoan1Data['Summation Data'][selectedYear].EndingBalance) > 0) {
                detailedInfo.CarLoan1 = carLoan1Data['Summation Data'][selectedYear].BeginningBalance;
                detailedInfo.CarLoanTotal1 = carLoan1Data['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.firstCarCondition = 1;
              }
              if (carLoan1Data['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                detailedInfo.principalCondition1 = 1;
                detailedInfo.PrincipalPayback1 = carLoan1Data['Summation Data'][selectedYear].LaggingPrincipal;
              }

              detailedInfo.secondCarCondition = 0;
              if (typeof carLoan2Data !== 'undefined' && carLoan2Data !== ''
                && typeof carLoan2Data['Summation Data'][selectedYear] !== 'undefined'
                && parseFloat(carLoan2Data['Summation Data'][selectedYear].EndingBalance) > 0) {
                detailedInfo.CarLoan2 = carLoan2Data['Summation Data'][selectedYear].BeginningBalance;
                detailedInfo.CarLoanTotal2 = carLoan2Data['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.secondCarCondition = 1;
              }

              detailedInfo.condition2 = 0;
              if (typeof carLoan2Data !== 'undefined' && carLoan2Data !== ''
                && typeof carLoan2Data['Summation Data'][selectedYear] !== 'undefined'
                && parseFloat(carLoan2Data['Summation Data'][selectedYear].EndingBalance) > 0) {
                detailedInfo.StudentLoan2 = carLoan2Data['Summation Data'][selectedYear].BeginningBalance;
                detailedInfo.StudentLoanTotal2 = carLoan2Data['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.condition2 = 1;

                detailedInfo.annualCondition2 = 0;
                if (selectedYear >= modulesServices.module6Data.startYear && selectedYear <= modulesServices.module6Data.graduationYear) {
                  detailedInfo.annualCondition2 = 1;
                  detailedInfo.AnnualLoan2 = modulesServices.module6Data.yearlyDistribution;
                  // detailedInfo.AccruedInterest2= studentLoan2Data['Summation Data'][selectedYear]['Yearly Accrued Interest'];
                }

                detailedInfo.principalCondition2 = 0;
                if (carLoan2Data['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                  detailedInfo.principalCondition2 = 1;
                  detailedInfo.PrincipalPayback2 = carLoan2Data['Summation Data'][selectedYear].LaggingPrincipal;
                }
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;

            case 'Mortgages':
              // let balanceSheetCompleteData    = modulesServices.balanceSheetCompleteData();
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];

              detailedInfo.purchasingMortgageFirstYear = modulesServices.module13Data.yearOfPurchase;
              detailedInfo.purchasingMortgageFirstPrice = modulesServices.module13Data.purchasePrice;
              detailedInfo.purchasingMortgageFirstDownPayment = modulesServices.module13Data.downPaymentCalcs;
              detailedInfo.purchasingMortgageFirst = modulesServices.module13Data.Mortage;
              detailedInfo.purchasingMortgageSecondYear = modulesServices.module14Data.purchasingYear;
              detailedInfo.purchasingMortgageSecondPrice = modulesServices.module14Data.purchasePrice;
              detailedInfo.purchasingMortgageSecondDownPayment = modulesServices.module14Data.realEstatedownPaymentCalcs;
              detailedInfo.purchasingMortgageSecond = modulesServices.module14Data.mortage;

              if (typeof modulesServices.module1Data.start_year !== 'undefined') {
                detailedInfo.startYearVal = modulesServices.module1Data.start_year;
              } else {
                detailedInfo.startYearVal = 0;
              }

              if (typeof modulesServices.module18Data.existigHomeCurrentValue !== 'undefined' && modulesServices.module18Data.existigHomeCurrentValue !== '') {
                detailedInfo.firstYearExHomeValue = modulesServices.module18Data.existigHomeCurrentValue;
              } else {
                detailedInfo.firstYearExHomeValue = 0;
              }
              detailedInfo.existingHomeSaleYearPop = modulesServices.module18Data.existingHomeSaleYear;
              if (typeof modulesServices.module18Data.existigHomeCurrentMortgageBalance !== 'undefined' && modulesServices.module18Data.existigHomeCurrentMortgageBalance !== '') {
                detailedInfo.firstYearMortgageValue = modulesServices.module18Data.existigHomeCurrentMortgageBalance;
              } else {
                detailedInfo.firstYearMortgageValue = 0;
              }
              // Existing Home Mportgage values
              var existingHomeMortgageData = modulesServices.existingHomeMortgageCompleteData();

              if (typeof existingHomeMortgageData !== 'undefined' && existingHomeMortgageData !== '' && typeof existingHomeMortgageData['Summation Data'][selectedYear] !== 'undefined' && typeof existingHomeMortgageData['Summation Data'][selectedYear].BeginningBalance !== 'undefined') {
                detailedInfo.beginningMortgageBalance = existingHomeMortgageData['Summation Data'][selectedYear].BeginningBalance;
              } else {
                detailedInfo.beginningMortgageBalance = 0;
              }
              if (typeof existingHomeMortgageData !== 'undefined' && existingHomeMortgageData !== '' && typeof existingHomeMortgageData['Summation Data'][selectedYear] !== 'undefined' && typeof existingHomeMortgageData['Summation Data'][selectedYear].LaggingPrincipal !== 'undefined') {
                detailedInfo.beginningMortgagePrincipal = -existingHomeMortgageData['Summation Data'][selectedYear].LaggingPrincipal;
              } else {
                detailedInfo.beginningMortgagePrincipal = 0;
              }
              detailedInfo.endingMortgageBalanceCondition = 0;
              if (typeof existingHomeMortgageData !== 'undefined' && existingHomeMortgageData !== '' && typeof existingHomeMortgageData['Summation Data'][selectedYear] !== 'undefined' && typeof existingHomeMortgageData['Summation Data'][selectedYear].EndingBalance !== 'undefined' && existingHomeMortgageData['Summation Data'][selectedYear].EndingBalance !== 0) {
                detailedInfo.endingMortgageBalance = existingHomeMortgageData['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.endingMortgageBalanceCondition = 1;
              } else {
                detailedInfo.endingMortgageBalance = 0;
              }
              // Final year Condition
              detailedInfo.finalMortgageCondition = 0;

              const mortgage1Data = modulesServices.mortgage1CompleteData();
              const mortgage2Data = modulesServices.mortgage2CompleteData();

              detailedInfo.purchasingMortgageCondition = 0;
              if (typeof detailedInfo.purchasingMortgageFirstYear !== 'undefined' && detailedInfo.purchasingMortgageFirstYear !== '' && detailedInfo.purchasingMortgageFirstYear <= selectedYear) {
                detailedInfo.purchasingMortgageCondition = 1;
              }
              detailedInfo.propertyPurchasingCondition = 0;
              if (typeof detailedInfo.purchasingMortgageSecondYear !== 'undefined' && detailedInfo.purchasingMortgageSecondYear !== '' && detailedInfo.purchasingMortgageSecondYear <= selectedYear) {
                detailedInfo.propertyPurchasingCondition = 1;
              }

              detailedInfo.condition1 = 0;
              if (typeof mortgage1Data !== 'undefined' && mortgage1Data !== ''
                && typeof mortgage1Data['Summation Data'][selectedYear] !== 'undefined'
                && parseFloat(mortgage1Data['Summation Data'][selectedYear].EndingBalance) > 0) {
                detailedInfo.MortgageLoan1 = mortgage1Data['Summation Data'][selectedYear].BeginningBalance;
                detailedInfo.MortgageLoanTotal1 = mortgage1Data['Summation Data'][selectedYear].EndingBalance;
                detailedInfo.condition1 = 1;

                detailedInfo.annualCondition1 = 0;
                if (selectedYear >= modulesServices.module2Data.startYear && selectedYear <= modulesServices.module2Data.graduationYear) {
                  detailedInfo.annualCondition1 = 1;
                  detailedInfo.AnnualLoan1 = modulesServices.module2Data.yearlyDistribution;
                  // detailedInfo.AccruedInterest1= carLoan1Data['Summation Data'][selectedYear]['Yearly Accrued Interest'];
                }

                detailedInfo.principalCondition1 = 0;
                if (mortgage1Data['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                  detailedInfo.principalCondition1 = 1;
                  detailedInfo.PrincipalPayback1 = mortgage1Data['Summation Data'][selectedYear].LaggingPrincipal;
                }
              }
              detailedInfo.reiSaleYearPop = modulesServices.module14Data.yearOfSale;
              detailedInfo.condition2 = 0;
              if (typeof mortgage2Data !== 'undefined' && mortgage2Data !== ''
                && typeof mortgage2Data['Summation Data'][selectedYear] !== 'undefined'
                && parseFloat(mortgage2Data['Summation Data'][selectedYear].EndingBalance) > 0) {
                detailedInfo.MortgageLoan2 = mortgage2Data['Summation Data'][selectedYear].BeginningBalance;
                detailedInfo.MortgageLoanTotal2 = Math.round(mortgage2Data['Summation Data'][selectedYear].EndingBalance);
                detailedInfo.condition2 = 1;

                detailedInfo.annualCondition2 = 0;
                if (selectedYear >= modulesServices.module6Data.startYear && selectedYear <= modulesServices.module6Data.graduationYear) {
                  detailedInfo.annualCondition2 = 1;
                  detailedInfo.AnnualLoan2 = modulesServices.module6Data.yearlyDistribution;
                  // detailedInfo.AccruedInterest2= studentLoan2Data['Summation Data'][selectedYear]['Yearly Accrued Interest'];
                }

                detailedInfo.principalCondition2 = 0;
                if (mortgage2Data['Summation Data'][selectedYear].LaggingPrincipal !== 0) {
                  detailedInfo.principalCondition2 = 1;
                  detailedInfo.PrincipalPayback2 = mortgage2Data['Summation Data'][selectedYear].LaggingPrincipal;
                }
              }
              if (typeof modulesServices.module14Data.yearOfSale !== 'undefined' && modulesServices.module14Data.yearOfSale !== '' && selectedYear >= modulesServices.module14Data.yearOfSale) {
                detailedInfo.MortgageLoanTotal2 = 0;
              } else if (typeof mortgage2Data['Summation Data'] !== 'undefined' && typeof mortgage2Data['Summation Data'][selectedYear] !== 'undefined' && typeof mortgage2Data['Summation Data'][selectedYear].EndingBalance !== 'undefined') {
                detailedInfo.MortgageLoanTotal2 = Math.round(mortgage2Data['Summation Data'][selectedYear].EndingBalance);
              } else {
                detailedInfo.MortgageLoanTotal2 = 0;
              }
              detailedInfo.MortgageLoanTotal2Condition = 0;
              if (detailedInfo.MortgageLoanTotal2 !== 0) {
                detailedInfo.MortgageLoanTotal2Condition = 1;
              }
              // Final Value
              if (typeof balanceSheetCompleteData !== 'undefined' && balanceSheetCompleteData !== '' && typeof balanceSheetCompleteData[selectedYear] !== 'undefined' && balanceSheetCompleteData[selectedYear] !== '' && typeof balanceSheetCompleteData[selectedYear].Liabilities.Mortgages !== 'undefined' && balanceSheetCompleteData[selectedYear].Liabilities.Mortgages !== 0) {
                detailedInfo.finalMortgageValue = balanceSheetCompleteData[selectedYear].Liabilities.Mortgages;
                detailedInfo.finalMortgageCondition = 1;
              } else {
                detailedInfo.finalMortgageValue = 0;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;
            default:
              break;
          }
          break;
        case 'Net Worth':
          switch (selectedColumn) {
            case 'Net Worth':
              detailedInfo.balanceSheetCompleteData = balanceSheetCompleteData[selectedYear];

              detailedInfo.condition = 0;
              if (detailedInfo.balanceSheetCompleteData['Net Worth'] !== 0) {
                detailedInfo.condition = 1;
              }
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;
            default:
              break;
          }
        case 'Total Assets':
          switch (selectedColumn) {
            case 'Total Assets':
              detailedInfo.balanceSheetCompleteData = dispalyObj.balanceData[selectedYear];

              detailedInfo.condition = 0;
              if(balanceSheetCompleteData[selectedYear]['Total Tangible Assets'] !== undefined && balanceSheetCompleteData[selectedYear]['Total Tangible Assets'] !== '' && balanceSheetCompleteData[selectedYear]['Total Investments'] !== undefined && balanceSheetCompleteData[selectedYear]['Total Investments'] !== '' ){
                detailedInfo.totalAssetsValue = parseFloat(balanceSheetCompleteData[selectedYear]['Total Tangible Assets']) + parseFloat(balanceSheetCompleteData[selectedYear]['Total Investments']);
                detailedInfo.condition = 1;
              }             
              setBalanceSheetDetails(detailedInfo);
              setOpenIncomeEarnedDuringHigher(true);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      // setOpenIncomeEarnedDuringHigher( true );
    }
  };

  return (
    <div className={classes.modalBodyData}>
      <div className={classes.incomeSheetModal}>
        <div className={classes.popupTable}>
          <table className={classes.incomeSheetTable}>
            <thead>
              <tr className={classes.topIncomeBg}>
                <th>Annual Balance Sheet</th>
                <th>&nbsp;</th>
                {
                  rangeList.map((range) => (
                    <th>{range + (dispalyObj.year)}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              <tr className={classNames(classes.blankSpace, classes.emptyRowdata)}>
                <td className={classes.grayBlankSpace}>&nbsp;</td>
                <td className={classes.grayBlankSpace}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Assets</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.balanceSheetAssetsHeadings && Object.keys(dispalyObj.balanceSheetAssetsHeadings).map((grossTitle) => (
                  <tr className={classes.balanceReiTable}>
                    <td className={classes.subHeadingData}>
                      {displayTitle(1, grossTitle)}

                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.balanceData[range + (dispalyObj.year)].Assets[grossTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Assets', grossTitle); }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }

              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Tangible Assets</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[1]}
                  />
                </td>
                {
                  dispalyObj.balanceData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.balanceData[range + (dispalyObj.year)]['Total Tangible Assets'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classes.emptyRowdata}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Investments</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.balanceSheetInvestmentsHeadings && Object.keys(dispalyObj.balanceSheetInvestmentsHeadings).map((grossTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {grossTitle === 'for401k' && '401(k)'}
                      {grossTitle === 'Seph IRA' && 'SEP IRA'}
                      {grossTitle !== 'for401k' && grossTitle !== 'Seph IRA' && grossTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.balanceData[range + (dispalyObj.year)].Investments[grossTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Investments', grossTitle); }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Investments</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[2]}
                  />
                </td>
                {
                  dispalyObj.balanceData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.balanceData[range + (dispalyObj.year)]['Total Investments'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>

              <tr className={classes.blueBorderLine}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Total Assets  </td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[3]}
                  />
                </td>
                {
                  dispalyObj.balanceData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.balanceData[range + (dispalyObj.year)]['Total Assets'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => showDetailInfo(dispalyObj.year + range, 'Total Assets', 'Total Assets')}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueRowData}>
                <td>Liabilities</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj.balanceSheetLiabilitiesHeadings && Object.keys(dispalyObj.balanceSheetLiabilitiesHeadings).map((livingExpensesTitle) => (
                  <tr>
                    <td className={classes.subHeadingData}>
                      {livingExpensesTitle}
                    </td>
                    <td className={classes.lightBlueBg}>&nbsp;</td>
                    {
                      rangeList.map((range) => {
                        const value = dispalyObj.balanceData[range + (dispalyObj.year)].Liabilities[livingExpensesTitle];
                        return (
                          <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Liabilities', livingExpensesTitle); }}>
                            {value >= 0 ? (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                            ) : (
                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                            )}
                          </td>
                        );
                      })
                    }
                  </tr>
                ))
              }
              <tr className={classNames(classes.blueBorderLine, classes.noCursorData)}>
                <td className={classes.headingData}>Total Liabilities</td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[4]}
                  />
                </td>
                {
                  dispalyObj.balanceData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.balanceData[range + (dispalyObj.year)]['Total Liabilities'];
                    return (
                      <td className={value < 0 && classes.minusValue}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>
              <tr className={classNames(classes.blueBorderLine, classes.emptyRowdata)}>
                <td className={classes.subHeadingData}>&nbsp;</td>
                <td className={classes.lightBlueBg}>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={classes.blueBorderLine}>
                <td className={classes.headingData}>Net Worth  </td>
                <td className={classes.lightBlueBg}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={dispalyObj.graph_data[5]}
                  />
                </td>
                {
                  dispalyObj.balanceData !== undefined && rangeList.map((range) => {
                    const value = dispalyObj.balanceData[range + (dispalyObj.year)]['Net Worth'];
                    return (
                      <td className={value < 0 && classes.minusValue} onClick={() => { showDetailInfo(dispalyObj.year + range, 'Net Worth', 'Net Worth'); }}>
                        {value >= 0 ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={value !== null ? value : 0} prefix="$" />
                        ) : (
                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={-(value < 0 && value !== null ? value : 0)} prefix="($" suffix=")" />
                        )}
                      </td>
                    );
                  })
                }
              </tr>

            </tbody>
          </table>
        </div>
      </div>

      {/* {openIncomeEarnedDuringHigher && <BalanceStatementPopup detailedInfo={detailedInfo} openIncomeEarnedDuringHigher ={openIncomeEarnedDuringHigher} setOpenIncomeEarnedDuringHigher={setOpenIncomeEarnedDuringHigher}/>} */}

      {openIncomeEarnedDuringHigher === true && (
        <BalanceStatementPopup detailedInfo={balanceSheetDetails} openIncomeEarnedDuringHigher={openIncomeEarnedDuringHigher} setOpenIncomeEarnedDuringHigher={setOpenIncomeEarnedDuringHigher} currentColumn={currentColumn} />
      )}

    </div>
  );
};

BalanceStatement.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BalanceStatement);
