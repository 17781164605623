import { PMT } from '../../utilities/commonFunctions';

function buyingAHomeMortgageSheetData( module1Data, module13Data ) {
  let mortgage1Data = {};
  let summationData = {};
  let prePaidInterest = 0;

  // Identifier
  let identifier = 0;
  if ( module13Data.Mortage !== undefined && module13Data.Mortage > 0 ) {
    identifier = 1;
  } else {
    identifier = 0;
  }

  // Loan Amount
  let loanAmount = 0;
  if ( identifier === 1 && module13Data.Mortage !== undefined && module13Data.Mortage !== '' ) {
    loanAmount = module13Data.Mortage;
  } else {
    loanAmount = 0;
  }

  // Terms in Years
  let termsOfYear = 0;
  if ( identifier === 1 && module13Data.term_of_mortgage !== undefined && module13Data.term_of_mortgage !== '' ) {
    termsOfYear = module13Data.term_of_mortgage;
  } else {
    termsOfYear = 0;
  }

  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( identifier === 1 && module13Data.rate_of_mortgage_in_percentage !== undefined && module13Data.rate_of_mortgage_in_percentage !== '' ) {
    annualInterestRate = module13Data.rate_of_mortgage_in_percentage;
  } else {
    annualInterestRate = 0;
  }

  // Original Payment
  let originalPayment = 0;

  if ( loanAmount > 0
                && termsOfYear > 0 ) {
    originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( loanAmount ), 0, 0 );
  } else {
    originalPayment = 0;
  }

  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let startYear = 0;
  if ( module13Data.year_of_purchase !== undefined
                && module13Data.year_of_purchase !== '' ) {
    startYear = parseInt( module13Data.year_of_purchase, 10 );
  }
  if ( startYear > 0 ) {
    let year = parseInt( startYear, 10 );
    const yearLimit = year + parseFloat( termsOfYear );

    // Existing Student Loan Data
    mortgage1Data = {};
    mortgage1Data.Identifier = identifier;
    mortgage1Data['Loan Amount'] = loanAmount;
    mortgage1Data['Term in Years'] = termsOfYear;
    mortgage1Data['Annual Interest Rate'] = annualInterestRate;
    mortgage1Data['Original Payment'] = originalPayment;
    let Balance = loanAmount;

    const Payment = originalPayment;
    let Interest = 0;
    if ( annualInterestRate > 0 ) {
      Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
    }
    let Principal = Payment - Interest;
    let Equity = Principal;
    let TotalInterest = Interest;
    const TotalPayment = Equity + TotalInterest;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];

    // For Loop from graduation year to +10 years
    let k = 0;
    for ( year; year < yearLimit; year += 1 ) {
      mortgage1Data[year] = {};

      mortgage1Data[year].LaggingInterest = 0;
      mortgage1Data[year].LaggingPrincipal = 0;
      mortgage1Data[year]['Months Total Balance'] = 0;

      // For Loop for 12 months
      for ( let month = 1; month <= 12; month += 1 ) {
        mortgage1Data[year][month] = {};

        if ( year === startYear && month === 1 ) {
          mortgage1Data[year][month].YearIdentifier = year;
          mortgage1Data[year][month].Balance = Balance;
          mortgage1Data[year][month].Payment = Payment;
          mortgage1Data[year][month].Interest = Interest;
          mortgage1Data[year][month].Principal = Principal;
          mortgage1Data[year][month].Equity = Equity;
          mortgage1Data[year][month].TotalInterest = TotalInterest;
          mortgage1Data[year][month].TotalPayment = TotalPayment;
        } else {
          // Year Identifier
          mortgage1Data[year][month].YearIdentifier = year;

          // Balance
          let tempBalance;
          if ( Balance === 0 ) {
            tempBalance = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempBalance = ( Balance - Principal );
          }
          mortgage1Data[year][month].Balance = tempBalance;

          // Payment
          mortgage1Data[year][month].Payment = Payment;

          // Interest
          let tempInterest;
          if ( Balance === 0 ) {
            tempInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          }
          mortgage1Data[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal;
          if ( Balance === 0 ) {
            tempPrincipal = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempPrincipal = Payment - tempInterest;
          }
          mortgage1Data[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity;
          if ( Balance === 0 ) {
            tempEquity = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempEquity = Equity + tempPrincipal;
          }
          mortgage1Data[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest;
          if ( Balance === 0 ) {
            tempTotalInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalInterest = TotalInterest + tempInterest;
          }
          mortgage1Data[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment;
          if ( Balance === 0 ) {
            tempTotalPayment = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          }
          mortgage1Data[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( mortgage1Data[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( mortgage1Data[year][month].TotalPayment );

        mortgage1Data[year].LaggingInterest = parseFloat( mortgage1Data[year].LaggingInterest ) + parseFloat( mortgage1Data[year][month].Interest );
        mortgage1Data[year].LaggingPrincipal = parseFloat( mortgage1Data[year].LaggingPrincipal ) + parseFloat( mortgage1Data[year][month].Principal );
        mortgage1Data[year]['Months Total Balance'] = parseFloat( mortgage1Data[year]['Months Total Balance'] ) + parseFloat( mortgage1Data[year][month].Balance );
      }
      if ( k === 0 ) {
        prePaidInterest = mortgage1Data[year][1].Interest;
      }
      k += 1;
    }

    // Total Interest Paid
    if ( totalInterestPaidArray.length > 0 ) {
      totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
    }

    // Total Paid
    if ( totalPaidArray.length > 0 ) {
      totalPaid = Math.max.apply( null, totalPaidArray );
    }
  } else {
    mortgage1Data = {};
  }

  // Summation Data
  if ( module1Data.start_year !== undefined
                && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 50;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;
    let yearwithTerm = 0;
    // For Loop from Start year to +14 years
    for ( summationYear; summationYear <= summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== '' && module13Data.term_of_mortgage !== undefined && module13Data.term_of_mortgage !== '' ) {
        yearwithTerm = parseFloat( module13Data.year_of_purchase ) + parseFloat( module13Data.term_of_mortgage );
      } else {
        yearwithTerm = 0;
      }

      // Beginning Balance
      if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== ''
                        && summationYear < module13Data.year_of_purchase ) {
        summationBeginningBalance = 0;
      } else if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== ''
                            && summationYear >= module13Data.year_of_purchase ) {
        if ( module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== ''
                                && summationYear <= yearwithTerm ) {
          if ( mortgage1Data !== undefined
                                     && mortgage1Data[summationYear] !== undefined
                                     && mortgage1Data[summationYear][1].Balance !== undefined ) {
            summationBeginningBalance = mortgage1Data[summationYear][1].Balance;
          } else {
            summationBeginningBalance = 0;
          }
        } else {
          summationBeginningBalance = 0;
        }
      } else {
        summationBeginningBalance = 0;
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( mortgage1Data[summationYear] !== undefined
                             && mortgage1Data[summationYear].LaggingInterest !== undefined ) {
          LaggingVal1 = mortgage1Data[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      if ( summationYear === module13Data.year_of_purchase ) {
        summationLaggingInterest -= prePaidInterest;
      }
      summationData[summationYear].LaggingInterest = summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( mortgage1Data[summationYear] !== undefined && mortgage1Data[summationYear].LaggingPrincipal !== undefined ) {
          summationLaggingPrincipal = mortgage1Data[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      // Total Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }
      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = summationData[summationYear].BeginningBalance - summationData[summationYear].LaggingPrincipal;
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;
    }
  }
  // To return Car Loan Complete Data
  function mortgageFullData() {
    const mortgageOneFullData = {};
    mortgageOneFullData.Mortage1 = mortgage1Data;
    mortgageOneFullData['Summation Data'] = summationData;
    mortgageOneFullData['Prepaid Interest'] = parseFloat( prePaidInterest );
    mortgageOneFullData['Total Paid'] = totalPaid;
    mortgageOneFullData['Total Interest Paid'] = totalInterestPaid;
    mortgageOneFullData['Annual Interest Rate'] = annualInterestRate;
    mortgageOneFullData['Original Payment'] = originalPayment;
    return mortgageOneFullData;
  }
  return mortgageFullData();
}
export default buyingAHomeMortgageSheetData;
