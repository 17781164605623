import React,{ useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import styles from '../../../screens/pwi/higher-education/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import circleArrow from '../../../assets/img/career-path/circle-arrow.png';

const LivingExpenses = ( props ) => {
  const {
    classes, inputData, livingExpensesData, handleDefinationPopup, updateData, listOfStates
  } = props;

  const [inputs, setInputs] = React.useState({});
  const [defaultValues, setDefaultValues] = React.useState({});
  const [resetDefault1, setResetDefault1] = React.useState(false);
  const [resetDefault2, setResetDefault2] = React.useState(false);

  const [onCampusTotal, setOnCampusTotal] = React.useState(0);
  const [offCampusTotal, setOffCampusTotal] = React.useState(0);
  const [roomBoard, setRoomBoard] = React.useState(0);


   useEffect(()=>{
    let inputValues = {...inputs};
    let rent  = 610;
    if(livingExpensesData.LivingExpenses !== undefined &&  livingExpensesData.LivingExpenses.length > 0) {
      if(inputData.state_id !== undefined && inputData.state_id > 0) {
        const stateIndex = _.findIndex( listOfStates, { id: inputData.state_id.toString() } );
        if(stateIndex >= 0) {
          const stateName = listOfStates[stateIndex].state_name;
          const expenseIndex = _.findIndex( livingExpensesData.LivingExpenses, { state: stateName.toString() } );
          if(expenseIndex >= 0) {
            rent = livingExpensesData.LivingExpenses[expenseIndex].off_campus_rent
          }
        }
      }
    }

    inputValues['off_campus_food'] = inputData.off_campus_food !== undefined ? inputData.off_campus_food : 150;
    inputValues['clothing'] = inputData.clothing !== undefined ? inputData.clothing : 60;
    inputValues['entertainment'] = inputData.entertainment !== undefined ? inputData.entertainment : 100;
    inputValues['technology'] =  inputData.technology !== undefined ? inputData.technology : 40;
    inputValues['transportation'] = inputData.transportation !== undefined ? inputData.transportation : 35;
    inputValues['miscellaneous'] = inputData.miscellaneous !== undefined ? inputData.miscellaneous : 120;
    inputValues['medical_costs_before_deductible'] = inputData.medical_costs_before_deductible !== undefined ? inputData.medical_costs_before_deductible : 0;
    inputValues['health_insurance_premium'] =  inputData.health_insurance_premium !== undefined ? inputData.health_insurance_premium : 0;


    inputValues['rent'] = inputData.rent !== undefined ? inputData.rent : rent;
    inputValues['utilities'] = inputData.utilities !== undefined ? inputData.utilities : 100;
    inputValues['food'] = inputData.food !== undefined ? inputData.food : 500;
    inputValues['second_clothing'] = inputData.second_clothing !== undefined ? inputData.second_clothing : 70;
    inputValues['second_entertainment'] = inputData.second_entertainment !== undefined ? inputData.second_entertainment : 125;
    inputValues['second_technology'] = inputData.second_technology !== undefined ? inputData.second_technology : 50;
    inputValues['second_transportation'] = inputData.second_transportation !== undefined ? inputData.second_transportation : 50;
    inputValues['second_miscellaneous'] = inputData.second_miscellaneous !== undefined ? inputData.second_miscellaneous : 150;
    inputValues['second_medical_costs_before_deductible'] = inputData.second_medical_costs_before_deductible !== undefined ? inputData.second_medical_costs_before_deductible : 0;
    inputValues['second_health_insurance_premium'] = inputData.second_health_insurance_premium !== undefined ? inputData.second_health_insurance_premium : 0;

    if(inputData.on_campus_year === undefined || inputData.on_campus_year <= 0) {
      inputValues['off_campus_food'] = 0;
      inputValues['clothing'] = 0;
      inputValues['entertainment'] = 0;
      inputValues['technology'] =  0;
      inputValues['transportation'] = 0;
      inputValues['miscellaneous'] = 0;
      inputValues['medical_costs_before_deductible'] = 0;
      inputValues['health_insurance_premium'] =  0;
    }

    let inputDataValues = {...inputData};
    inputDataValues['off_campus_food'] = inputValues['off_campus_food'];
    inputDataValues['clothing'] = inputValues['clothing'];
    inputDataValues['entertainment'] = inputValues['entertainment'];
    inputDataValues['technology'] = inputValues['technology'];
    inputDataValues['transportation'] = inputValues['transportation'];
    inputDataValues['miscellaneous'] = inputValues['miscellaneous'];
    inputDataValues['medical_costs_before_deductible'] = inputValues['medical_costs_before_deductible'];
    inputDataValues['health_insurance_premium'] = inputValues['health_insurance_premium'];

    inputDataValues['rent'] = inputValues['rent'];
    inputDataValues['utilities'] = inputValues['utilities'];
    inputDataValues['food'] = inputValues['food'];
    inputDataValues['second_clothing'] = inputValues['second_clothing'];
    inputDataValues['second_entertainment'] = inputValues['second_entertainment'];
    inputDataValues['second_technology'] = inputValues['second_technology'];
    inputDataValues['second_transportation'] = inputValues['second_transportation'];
    inputDataValues['second_miscellaneous'] = inputValues['second_miscellaneous'];
    inputDataValues['second_medical_costs_before_deductible'] = inputValues['second_medical_costs_before_deductible'];
    inputDataValues['second_health_insurance_premium'] = inputValues['second_health_insurance_premium'];

    updateData('total_obj',inputDataValues);
    setInputs(inputValues);
    setDefaultValues(inputValues);
    //
  },[])
  

  useEffect(()=>{
    if(inputData['off_campus_food'] !== defaultValues['off_campus_food'] || inputData['clothing'] !== defaultValues['clothing'] || inputData['entertainment'] !== defaultValues['entertainment'] ||
      inputData['technology'] !== defaultValues['technology'] || inputData['transportation'] !== defaultValues['transportation'] || inputData['miscellaneous'] !== defaultValues['miscellaneous'] ||
       inputData['medical_costs_before_deductible'] !== defaultValues['medical_costs_before_deductible'] || inputData['health_insurance_premium'] !== defaultValues['health_insurance_premium']) {
      setResetDefault1(true);
    } else {
      setResetDefault1(false);
    }

    if(inputData['rent'] !== defaultValues['rent'] || inputData['utilities'] !== defaultValues['utilities'] || inputData['food'] !== defaultValues['food'] ||
      inputData['second_clothing'] !== defaultValues['second_clothing'] || inputData['second_entertainment'] !== defaultValues['second_entertainment'] || inputData['second_technology'] !== defaultValues['second_technology'] ||
       inputData['second_transportation'] !== defaultValues['second_transportation'] || inputData['second_medical_costs_before_deductible'] !== defaultValues['second_medical_costs_before_deductible'] || inputData['second_health_insurance_premium'] !== defaultValues['second_health_insurance_premium'] || inputData['second_miscellaneous'] !== defaultValues['second_miscellaneous']) {
      setResetDefault2(true);
    } else {
      setResetDefault2(false);
    }

    setRoomBoard(inputData.room_board !== undefined ? inputData.room_board : 0);

    let onMonthlyTotal = inputData['off_campus_food']+inputData['clothing']+inputData['entertainment']+inputData['technology']+inputData['transportation']+inputData['miscellaneous']+inputData['medical_costs_before_deductible']+inputData['health_insurance_premium'];
    setOnCampusTotal(onMonthlyTotal);

    let offMonthlyTotal = inputData['rent']+inputData['utilities']+inputData['food']+inputData['second_clothing']+inputData['second_entertainment']+inputData['second_technology']+inputData['second_transportation']+inputData['second_medical_costs_before_deductible']+inputData['second_health_insurance_premium']+inputData['second_miscellaneous'];
    setOffCampusTotal(offMonthlyTotal);

  },[inputs])

  const updateInputValue = (field, e) => {
    let inputDataValues = {...inputData};
    let inputValues = {...inputs};
    inputDataValues[field] = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = inputDataValues[field];
    updateData('total_obj',inputDataValues);
    setInputs(inputValues);
  }

  const resetInputs = (type) => {
    if(type === 1) {
      let inputValues = {...inputs};
      inputValues['off_campus_food'] = defaultValues.off_campus_food;
      inputValues['clothing'] = defaultValues.clothing;
      inputValues['entertainment'] = defaultValues.entertainment;
      inputValues['technology'] =  defaultValues.technology;
      inputValues['transportation'] = defaultValues.transportation;
      inputValues['miscellaneous'] = defaultValues.miscellaneous;
      inputValues['medical_costs_before_deductible'] = defaultValues.medical_costs_before_deductible;
      inputValues['health_insurance_premium'] =  defaultValues.health_insurance_premium;

      let inputDataValues = {...inputData};
      inputDataValues['off_campus_food'] = inputValues['off_campus_food'];
      inputDataValues['clothing'] = inputValues['clothing'];
      inputDataValues['entertainment'] = inputValues['entertainment'];
      inputDataValues['technology'] = inputValues['technology'];
      inputDataValues['transportation'] = inputValues['transportation'];
      inputDataValues['miscellaneous'] = inputValues['miscellaneous'];
      inputDataValues['medical_costs_before_deductible'] = inputValues['medical_costs_before_deductible'];
      inputDataValues['health_insurance_premium'] = inputValues['health_insurance_premium'];
      updateData('total_obj',inputDataValues);
      setInputs(inputValues);
    } else {
      let inputValues = {...inputs};
      inputValues['rent'] = defaultValues.rent;
      inputValues['utilities'] = defaultValues.utilities;
      inputValues['food'] = defaultValues.food;
      inputValues['second_clothing'] = defaultValues.second_clothing;
      inputValues['second_entertainment'] = defaultValues.second_entertainment;
      inputValues['second_technology'] = defaultValues.second_technology;
      inputValues['second_transportation'] = defaultValues.second_transportation;
      inputValues['second_miscellaneous'] = defaultValues.second_miscellaneous;
      inputValues['second_medical_costs_before_deductible'] = defaultValues.second_medical_costs_before_deductible;
      inputValues['second_health_insurance_premium'] = defaultValues.second_health_insurance_premium;

      let inputDataValues = {...inputData};  
      inputDataValues['rent'] = inputValues['rent'];
      inputDataValues['utilities'] = inputValues['utilities'];
      inputDataValues['food'] = inputValues['food'];
      inputDataValues['second_clothing'] = inputValues['second_clothing'];
      inputDataValues['second_entertainment'] = inputValues['second_entertainment'];
      inputDataValues['second_technology'] = inputValues['second_technology'];
      inputDataValues['second_transportation'] = inputValues['second_transportation'];
      inputDataValues['second_miscellaneous'] = inputValues['second_miscellaneous'];
      inputDataValues['second_medical_costs_before_deductible'] = inputValues['second_medical_costs_before_deductible'];
      inputDataValues['second_health_insurance_premium'] = inputValues['second_health_insurance_premium'];  
      updateData('total_obj',inputDataValues);
      setInputs(inputValues);  
    }
  }

  return (
    <div className={ classes.contentBlock }>
      <p>
        Based on your school&apos;s location, we estimated your monthly living expenses. Click on the numbers below if you want to edit the values before continuing to the next step.
      </p>

      <p className={ classes.topGapNew }>
        <i>
          Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
          <br />
          Location of Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.location !== undefined && inputData.school_details.location}
        </i>
      </p>
      <Grid container spacing={ 3 } className={ classes.heigherEducationSearchGap }>
        {inputData.on_campus_year !== undefined && inputData.on_campus_year > 0 && (
        <Grid sm={ 8 } xs={ 12 }>
          <ReactTooltip id="compareSchool1" data-arrow-color="#0069aa" data-place="top" place="top" effect="solid" className={ classes.tooltipInfoLiving } />
          <div className={ classes.heigherEducationSearch }>
            <div className={ `${classes.higherEducationStepFour} ${classes.higherEducationStepSix} ${classes.livingExpencesTable}` }>
              <table cellPadding="0" cellSpacing="0">
                <thead>
                  <th colSpan={ 3 }>On Campus Monthly Living Expenses
                    <div className={ `${classes.resetIcon} ${classes.resetIconNew}` }>
                      {resetDefault1 && (
                        <span>
                          <ReactTooltip id="reset1" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
                          <img src={ circleArrow } alt=""  aria-hidden="true" onClick={()=>resetInputs(1)} data-for="reset1" data-tip="Reset to default values" />
                        </span>
                      )}
                    </div>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool1" data-tip="Enter how much money you expect to spend on food outside of your meal plan. Think of this input as a place for all off campus eating, delivery, take out, or any other meals that you would get outside of your meal plan." /></td>
                    <td>Off-Campus Food</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs['off_campus_food'] !== undefined ?  inputs['off_campus_food']:0 } onValueChange={ (e)=>updateInputValue('off_campus_food',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        className={ classes.InfoIconNew }
                        src={ infoIcon }
                        alt=""
                        data-for="compareSchool1"
                        data-tip="Enter how much money you expect to spend on clothing and personal care. This input should account for all clothes such as shoes, shirts, pants, accessories, jewelry, etc. And, any personal care items such as toothbrushes, hair and body washes, facial care, etc.
                If you don't buy clothes and personal care items every month, just estimate what you might spend each year and divide that number by 12 using Inkwiry's calculator above."
                      />
                    </td>
                    <td> Clothing & Personal Care</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale   customInput={ TextField } thousandSeparator value={ inputs['clothing'] !== undefined ?  inputs['clothing']:0 } onValueChange={ (e)=>updateInputValue('clothing',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool1" data-tip="Enter how much money you expect to spend on entertainment activities such as movies, books, golfing, going out at night with friends, concerts, or just about anything you do for fun that costs money." /></td>
                    <td>Entertainment</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['entertainment'] !== undefined ?  inputs['entertainment']:0 } onValueChange={ (e)=>updateInputValue('entertainment',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } fixedDecimalScale alt="" data-for="compareSchool1" data-tip="Enter how much you expect to spend on technology such as a smartphone bill, laptop / desktop / tablet expenses, streaming services, Inkwiry subscription, other subscriptions, or any other dollars you spend on technology." /></td>
                    <td>Technology</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['technology'] !== undefined ?  inputs['technology']:0 } onValueChange={ (e)=>updateInputValue('technology',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool1" data-tip="Enter how much you expect to spend on transportation such as ride sharing services, taxis, subway rides, trains, buses, or any other dollars you spend on transportation. Do not include any expenses related to your own car. Those expenses will be captured in the 'Existing Assets & Liabilities' or 'Buying a Car' modules." /></td>
                    <td>Transportation</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['transportation'] !== undefined ?  inputs['transportation']:0 } onValueChange={ (e)=>updateInputValue('transportation',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool1" data-tip="Enter how much you expect to spend on miscellaneous items such as costs of books and other supplies, household items, gym memberships, monthly parking, or any repeat monthly expense that might not be captured in the above categories." /></td>
                    <td>Miscellaneous*</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['miscellaneous'] !== undefined ?  inputs['miscellaneous']:0 } onValueChange={ (e)=>updateInputValue('miscellaneous',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><font aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Health Insurance Costs outside Premium' ) }>Healthcare Costs other than Premium</font></td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['medical_costs_before_deductible'] !== undefined ?  inputs['medical_costs_before_deductible']:0 } onValueChange={ (e)=>updateInputValue('medical_costs_before_deductible',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><font aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Health Insurance Premium' ) }>Health Insurance Premium</font></td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat  decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['health_insurance_premium'] !== undefined ?  inputs['health_insurance_premium']:0 } onValueChange={ (e)=>updateInputValue('health_insurance_premium',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr className={ classes.trFootNewWhite }>
                    <td colSpan="2"><span>Monthly Living Expenses</span></td>
                    <td>
                      <div className={ classes.amountDollarRight }>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ onCampusTotal } prefix={ onCampusTotal >= 0 ? '$' : '($' } suffix={ onCampusTotal < 0 && ')' } />
                      </div>
                    </td>
                  </tr>
                  <tr className={ classes.trFootNew }>
                    <td colSpan="2">Annual Living Expenses</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ onCampusTotal*12 } prefix={ onCampusTotal >= 0 ? '$' : '($' } suffix={ onCampusTotal < 0 && ')' } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className={ `${classes.firstSpan} ${classes.roomAndBoard}` }><span>Room & Board</span></td>
                    <td>
                      <div className={ classes.amountDollarRight }>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ roomBoard } prefix={ roomBoard >= 0 ? '$' : '($' } suffix={ roomBoard < 0 && ')' } />
                      </div>
                    </td>
                  </tr>
                  <tr className={ classes.trFootNew }>

                    <td colSpan="2"><span>Annual Living Expenses + Room & Board**</span></td>
                    <td>
                      <div className={ classes.amountDollarRight }>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ (onCampusTotal * 12) + roomBoard } prefix="$"/>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>
        </Grid>
        )}
        {inputData.off_campus_year !== undefined && inputData.off_campus_year > 0 && (
        <Grid sm={ 8 } xs={ 12 }>
          <ReactTooltip id="compareSchool2" data-arrow-color="#0069aa" data-place="top" place="top" effect="solid" className={ classes.tooltipInfoLiving } />
          <div className={ classes.heigherEducationSearch }>
            <div className={ `${classes.higherEducationStepFour} ${classes.higherEducationStepSix} ${classes.livingExpencesTable}` }>
              <table cellPadding="0" cellSpacing="0">
                <thead>
                  <th colSpan={ 3 }>Off Campus Monthly Living Expenses
                    <div className={ `${classes.resetIcon} ${classes.resetIconNew}` }>
                      {resetDefault2 && (
                        <span>
                          <ReactTooltip id="reset1" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
                          <img src={ circleArrow } alt=""  aria-hidden="true" onClick={()=>resetInputs(2)} data-for="reset1" data-tip="Reset to default values" />
                        </span>
                      )}           
                    </div>
                  </th>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        className={ classes.InfoIconNew }
                        src={ infoIcon }
                        alt=""
                        data-for="compareSchool2"
                        data-tip="Enter how much you expect to spend on rent when living off campus. This input depends on your location and will require a little research to figure out rent costs in your planned living area during higher education. Check out the Boost My Awareness section below for links to research average rents by location.
                Your planned school will also have links to student housing options for you to explore."
                      />
                    </td>
                    <td>Rent</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['rent'] !== undefined ?  inputs['rent']:0 } onValueChange={ (e)=>updateInputValue('rent',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool2" data-tip="Enter how much you expect to spend on utilities such as cable, internet, electricity, heat, water, etc. Check the Quick Links in the Boost My Awareness section below for tips on estimating your monthly utilities bill." /></td>
                    <td>Utilities</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['utilities'] !== undefined ?  inputs['utilities']:0 } onValueChange={ (e)=>updateInputValue('utilities',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool2" data-tip="Enter how much money you expect to spend on food on a monthly basis. Factor in things such as grocery shopping, dining out, delivery, take out, and any other food. Don't forget, you can use Inkwiry's calculator to do some quick math to get to your total monthly food expense when living off campus." /></td>
                    <td>Food</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['food'] !== undefined ?  inputs['food']:0 } onValueChange={ (e)=>updateInputValue('food',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img
                        className={ classes.InfoIconNew }
                        src={ infoIcon }
                        alt=""
                        data-for="compareSchool2"
                        data-tip="Enter how much money you expect to spend on clothing and personal care. This input should account for all clothes such as shoes, shirts, pants, accessories, jewelry, etc. And, any personal care items such as toothbrushes, hair and body washes, facial care, etc.
                If you don't buy clothes and personal care items every month, just estimate what you might spend each year and divide that number by 12 using Inkwiry's calculator above."
                      />
                    </td>
                    <td> Clothing & Personal Care</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['second_clothing'] !== undefined ?  inputs['second_clothing']:0 } onValueChange={ (e)=>updateInputValue('second_clothing',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool2" data-tip="Enter how much money you expect to spend on entertainment activities such as movies, books, golfing, going out at night with friends, concerts, or just about anything you do for fun that costs money." /></td>
                    <td>Entertainment</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['second_entertainment'] !== undefined ?  inputs['second_entertainment']:0 } onValueChange={ (e)=>updateInputValue('second_entertainment',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool2" data-tip="Enter how much you expect to spend on technology such as a smartphone bill, laptop / desktop / tablet expenses, streaming services, Inkwiry subscription, other subscriptions, or any other dollars you spend on technology." /></td>
                    <td>Technology</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['second_technology'] !== undefined ?  inputs['second_technology']:0 } onValueChange={ (e)=>updateInputValue('second_technology',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool2" data-tip="Enter how much you expect to spend on transportation such as ride sharing services, taxis, subway rides, trains, buses, or any other dollars you spend on transportation. Do not include any expenses related to your own car. Those expenses will be captured in the 'Existing Assets & Liabilities' or 'Buying a Car' modules." /></td>
                    <td>Transportation</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['second_transportation'] !== undefined ?  inputs['second_transportation']:0 } onValueChange={ (e)=>updateInputValue('second_transportation',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="compareSchool2" data-tip="Enter how much you expect to spend on miscellaneous items such as costs of books and other supplies, household items, gym memberships, monthly parking, or any repeat monthly expense that might not be captured in the above categories." /></td>
                    <td>Miscellaneous*</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['second_miscellaneous'] !== undefined ?  inputs['second_miscellaneous']:0 } onValueChange={ (e)=>updateInputValue('second_miscellaneous',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><font aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Health Insurance Costs outside Premium' ) }>Healthcare Costs other than Premium</font></td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['second_medical_costs_before_deductible'] !== undefined ?  inputs['second_medical_costs_before_deductible']:0 } onValueChange={ (e)=>updateInputValue('second_medical_costs_before_deductible',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td><font aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Health Insurance Premium' ) }>Health Insurance Premium</font></td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <span>$</span>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputs['second_health_insurance_premium'] !== undefined ?  inputs['second_health_insurance_premium']:0 } onValueChange={ (e)=>updateInputValue('second_health_insurance_premium',e) } onFocus={ ( e ) => e.target.select() } />
                      </div>
                    </td>
                  </tr>
                  <tr className={ classes.trFootNewWhite }>
                    <td colSpan="2"><span>Monthly Living Expenses</span></td>
                    <td>
                      <div className={ classes.amountDollarRight }>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ offCampusTotal } prefix={ offCampusTotal >= 0 ? '$' : '($' } suffix={ offCampusTotal < 0 && ')' } />
                      </div>
                    </td>
                  </tr>
                  <tr className={ classes.trFootNew }>
                    <td colSpan="2">Annual Living Expenses</td>
                    <td>
                      <div className={ classes.amountDollarNew }>
                        <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ offCampusTotal*12 } prefix={ offCampusTotal >= 0 ? '$' : '($' } suffix={ offCampusTotal < 0 && ')' } />
                      </div>
                    </td>
                  </tr>

                </tbody>
              </table>
            </div>

          </div>
        </Grid>
        )}
      </Grid>

      
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
    </div>
  );
};

LivingExpenses.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  inputData: PropTypes.object.isRequired,
  livingExpensesData: PropTypes.arrayOf( PropTypes.object ).isRequired,
  listOfStates: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

export default withStyles( styles )( LivingExpenses );
