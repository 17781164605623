function balanceSheetData( module1Data, module14Data, module18Data, cashFlowData, investmentsData, existingcarLoanData,
  carLoan1Data, carLoan2Data, depreciationSheduleData, buyingAHomeData, mortgage1Data, mortgage2Data, invPropertyRoiData,
  fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData,
  privateMoreHigherEdLoanData, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData,
  fedUnSubExistingLoanData, privateExistingLoanData, existingHomeData, existingCreditCardDebtData, existingHomeMortgageData, spouseStudentLoanData, carsExistingVehicleDepreciationData ) {
  let year = parseInt( module1Data.start_year, 10 );
  const yearLimit = parseInt( module1Data.start_year, 10 ) + 15;

  const balanceSheetCompleteData = {};
  let cashValue = 0;
  let otherValuablesValue = 0;
  let autoPropertyValue = 0;
  let autoPropertyValue1 = 0;
  let autoPropertyValue2 = 0;
  let autoPropertyValue3 = 0;
  let autoPropertyValue4 = 0;
  let businessValue = 0;
	let traditionalValue = 0;
	let k401Value = 0;
	let rothK401Value = 0;
  let totalTangibleAssetsValue = 0;

  const totalTangibleAssetsGraph = [];
  const totalInvestmentsGraph = [];
  const totalAssetsGraph = [];
  const totalLiabilitiesGraph = [];
  const netWorthGraph = [];

  for ( year; year < yearLimit; year += 1 ) {
    balanceSheetCompleteData[year] = {};

    /** **********
       ** Assets **
       *********** */
    balanceSheetCompleteData[year].Assets = {};
    // Cash
    if ( cashFlowData[year] !== undefined && cashFlowData[year] !== undefined && cashFlowData[year] !== ''
           && cashFlowData[year]['Ending Cash Balance'] !== undefined && cashFlowData[year]['Ending Cash Balance'] !== undefined
          && cashFlowData[year]['Ending Cash Balance'] !== '' ) {
      cashValue = cashFlowData[year]['Ending Cash Balance'];
    } else {
      cashValue = 0;
    }
    balanceSheetCompleteData[year].Assets.Cash = cashValue;
	
	//Other Valuables
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && typeof module18Data.existingTotalValuables !== 'undefined' && module18Data.existingTotalValuables !== '' ){
		otherValuablesValue = module18Data.existingTotalValuables;
	} else{
		if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year > module1Data.start_year && typeof module18Data.existingTotalValuables !== 'undefined' && module18Data.existingTotalValuables > 0 && typeof module18Data.existing_other_expected_growth_rate !== 'undefined' && module18Data.existing_other_expected_growth_rate !== ''){
			otherValuablesValue = otherValuablesValue * ( 1 + (module18Data.existing_other_expected_growth_rate / 100) );
		} else{
			otherValuablesValue = 0;
		}
	}
	balanceSheetCompleteData[year].Assets['Other Valuables'] = otherValuablesValue;

    // Auto property
    if ( depreciationSheduleData !== undefined
           && depreciationSheduleData[year] !== undefined
           && depreciationSheduleData[year]['Understanding Depreciation'] !== undefined
           && depreciationSheduleData[year]['Automobile Straight-Line Depreciation'] !== undefined
           && depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second'] !== undefined ) {
      autoPropertyValue = depreciationSheduleData[year]['Understanding Depreciation']['Ending Value']
          + depreciationSheduleData[year]['Automobile Straight-Line Depreciation']['Ending Value']
          + depreciationSheduleData[year]['Automobile Straight-Line Depreciation Second']['Ending Value'];
    } else {
      autoPropertyValue = 0;
    }
	
	if( typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== '' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Ending Balance'] !== 'undefined'){
		autoPropertyValue1 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 1']['Ending Balance'];
	} else{
		autoPropertyValue1 = 0;
	}
	if( typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== 'undefined' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Ending Balance'] !== 'undefined'){
		autoPropertyValue2 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 2']['Ending Balance'];
	} else{
		autoPropertyValue2 = 0;
	}
	if( typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== 'undefined' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Ending Balance'] !== 'undefined'){
		autoPropertyValue3 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Ending Balance'];
	} else{
		autoPropertyValue3 = 0;
	}
	if( typeof carsExistingVehicleDepreciationData !== 'undefined' && carsExistingVehicleDepreciationData !== '' && typeof carsExistingVehicleDepreciationData[year] !== 'undefined' && carsExistingVehicleDepreciationData[year] !== 'undefined' && typeof carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Ending Balance'] !== 'undefined'){
		autoPropertyValue4 = carsExistingVehicleDepreciationData[year]['Existing Vehicle 3']['Ending Balance'];
	} else{
		autoPropertyValue4 = 0;
	}
    balanceSheetCompleteData[year].Assets['Auto Property'] = autoPropertyValue + autoPropertyValue1 + autoPropertyValue2 + autoPropertyValue3 + autoPropertyValue4;

    // Real Estate Property
    let realEstatePropertyValue = 0;
    let realEstatePropertyValue1 = 0;
    let realEstatePropertyValue2 = 0;
    let realEstatePropertyValue3 = 0;
	let realEstatePropertyValue4 = 0;
    if ( existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Real Estate'] !== undefined
        && existingHomeData['Real Estate'][year] !== undefined && existingHomeData['Real Estate'][year]['Asset Value'] !== undefined ) {
      realEstatePropertyValue3 = existingHomeData['Real Estate'][year]['Asset Value'];
    } else {
      realEstatePropertyValue3 = 0;
    }
    if ( buyingAHomeData !== undefined && buyingAHomeData !== ''
           && buyingAHomeData['Real Estate'] !== undefined
           && buyingAHomeData['Real Estate'][year] !== undefined
           && buyingAHomeData['Real Estate'][year]['Asset Value'] !== undefined ) {
      realEstatePropertyValue1 = buyingAHomeData['Real Estate'][year]['Asset Value'];
    } else {
      realEstatePropertyValue1 = 0;
    }
    if ( module14Data.year_of_sale !== undefined
          && year >= module14Data.year_of_sale ) {
      realEstatePropertyValue2 = 0;
    } else if ( invPropertyRoiData !== undefined && invPropertyRoiData !== ''
               && invPropertyRoiData['Real Estate'] !== undefined
               && invPropertyRoiData['Real Estate'][year] !== undefined
               && invPropertyRoiData['Real Estate'][year]['Asset Value'] !== undefined ) {
      realEstatePropertyValue2 = invPropertyRoiData['Real Estate'][year]['Asset Value'];
    } else {
      realEstatePropertyValue2 = 0;
    }
	if ( existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Real Estate'] !== undefined
        && existingHomeData['Real Estate'][year] !== undefined && existingHomeData['Real Estate'][year]['Asset Value2'] !== undefined ) {
      realEstatePropertyValue4 = existingHomeData['Real Estate'][year]['Asset Value2'];
    } else {
      realEstatePropertyValue4 = 0;
    }
	
    realEstatePropertyValue = parseFloat( realEstatePropertyValue1 ) + parseFloat( realEstatePropertyValue2 ) + parseFloat( realEstatePropertyValue3 ) + parseFloat( realEstatePropertyValue4 );
    balanceSheetCompleteData[year].Assets['Real Estate Property'] = realEstatePropertyValue;

    // Accumulated Depreciation
    let accumulatedDepreciationValue = 0;
    let accumulatedDepreciationValue1 = 0;
    if ( invPropertyRoiData !== undefined && invPropertyRoiData !== ''
           && invPropertyRoiData['Real Estate'] !== undefined
           && invPropertyRoiData['Real Estate'][year] !== undefined
           && invPropertyRoiData['Real Estate'][year].Accumulated !== undefined ) {
      accumulatedDepreciationValue1 = invPropertyRoiData['Real Estate'][year].Accumulated;
    } else {
      accumulatedDepreciationValue1 = 0;
    }
    accumulatedDepreciationValue = accumulatedDepreciationValue1;
    balanceSheetCompleteData[year].Assets['REI Property Accumulated Depreciation'] = accumulatedDepreciationValue;
    /** End Assets * */

    /** *************************
       ** Total Tangible Assets **
       ************************** */
    totalTangibleAssetsValue = parseFloat( cashValue ) + parseFloat( autoPropertyValue ) + parseFloat( realEstatePropertyValue ) + parseFloat( otherValuablesValue );
    balanceSheetCompleteData[year]['Total Tangible Assets'] = totalTangibleAssetsValue;
    totalTangibleAssetsGraph.push( balanceSheetCompleteData[year]['Total Tangible Assets'] );
    /** End of Total Tangile Assets * */

    /** ***************
       ** Investments **
       **************** */
    balanceSheetCompleteData[year].Investments = {};
	//Business'
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year === module1Data.start_year && typeof module18Data.existing_value_of_business !== 'undefined' && module18Data.existing_value_of_business !== '' ){
		businessValue = module18Data.existing_value_of_business;
	} else{
		if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && year > module1Data.start_year && typeof module18Data.existing_value_of_business !== 'undefined' && module18Data.existing_value_of_business > 0 ){
			businessValue = businessValue * ( 1 + (module18Data.existing_expected_growth_rate / 100) );	
		} else{
			businessValue = 0;	
		}
	}
	balanceSheetCompleteData[year].Investments['Business'] = businessValue;
	
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['Retirement Accounts Traditional IRA Second'] !== 'undefined' &&
		typeof investmentsData['Retirement Accounts Traditional IRA Second'][year] !== 'undefined' &&
		typeof investmentsData['Retirement Accounts Traditional IRA Second'][year]['Ending Balance'] !== 'undefined') {
		traditionalValue = investmentsData['Retirement Accounts Traditional IRA Second'][year]['Ending Balance'];
	} else {
		traditionalValue = 0;
	}
	balanceSheetCompleteData[year].Investments['Traditional IRA'] = traditionalValue;
	
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['401k Second'] !== 'undefined' &&
		typeof investmentsData['401k Second'][year] !== 'undefined' &&
		typeof investmentsData['401k Second'][year]['Ending Total Balance'] !== 'undefined') {
		k401Value = investmentsData['401k Second'][year]['Ending Total Balance'];
	} else {
		k401Value = 0;
	}
	balanceSheetCompleteData[year].Investments['401(k) / 403b'] = k401Value;
	
	if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['Roth 401k Second'] !== 'undefined' &&
		typeof investmentsData['Roth 401k Second'][year] !== 'undefined' &&
		typeof investmentsData['Roth 401k Second'][year]['Ending Total Balance'] !== 'undefined') {
		rothK401Value = investmentsData['Roth 401k Second'][year]['Ending Total Balance'];
	} else {
		rothK401Value = 0;
	}
	balanceSheetCompleteData[year].Investments['Roth 401(k) / 403b'] = rothK401Value;
	
    // 401
    let for401kValue = 0;
    if ( investmentsData !== undefined
           && investmentsData['401k Second'] !== undefined
           && investmentsData['401k Second'][year] !== undefined
           && investmentsData['401k Second'][year]['Ending Total Balance'] !== undefined ) {
      for401kValue = investmentsData['401k Second'][year]['Ending Total Balance'];
    } else {
      for401kValue = 0;
    }

    //balanceSheetCompleteData[year].Investments.for401k = for401kValue;

    // Roth IRA
    let rothIRAValue = 0;
    if ( investmentsData !== undefined
           && investmentsData['Roth IRA Second'] !== undefined
           && investmentsData['Roth IRA Second'][year] !== undefined
           && investmentsData['Roth IRA Second'][year]['Ending Balance'] !== undefined ) {
      rothIRAValue = investmentsData['Roth IRA Second'][year]['Ending Balance'];
    } else {
      rothIRAValue = 0;
    }
    balanceSheetCompleteData[year].Investments['Roth IRA'] = rothIRAValue;

    // Seph IRA
    let sephIRAValue = 0;
    if ( investmentsData !== undefined
           && investmentsData['Seph IRA Second'] !== undefined
           && investmentsData['Seph IRA Second'][year] !== undefined
           && investmentsData['Seph IRA Second'][year]['Ending Balance'] !== undefined ) {
      sephIRAValue = investmentsData['Seph IRA Second'][year]['Ending Balance'];
    } else {
      sephIRAValue = 0;
    }
    balanceSheetCompleteData[year].Investments['Seph IRA'] = sephIRAValue;

    // Pension
    let pensionValue = 0;
    if ( investmentsData !== undefined
           && investmentsData['Pension Second'] !== undefined
           && investmentsData['Pension Second'][year] !== undefined
           && investmentsData['Pension Second'][year]['Ending Balance'] !== undefined ) {
      pensionValue = investmentsData['Pension Second'][year]['Ending Balance'];
    } else {
      pensionValue = 0;
    }
    balanceSheetCompleteData[year].Investments.Pension = pensionValue;
	
	let hsaValue = 0;
	 if (typeof investmentsData !== 'undefined' &&
		typeof investmentsData['HSA Second'] !== 'undefined' &&
		typeof investmentsData['HSA Second'][year] !== 'undefined' &&
		typeof investmentsData['HSA Second'][year]['Ending Balance'] !== 'undefined') {
		hsaValue = investmentsData['HSA Second'][year]['Ending Balance'];
	} else {
		hsaValue = 0;
	}
	balanceSheetCompleteData[year].Investments['Health Savings Account (HSA)'] = hsaValue;
	
	let lifeInsuranceVal = 0;
	if( typeof investmentsData !== 'undefined' &&
		typeof investmentsData['Life Insurance'] !== 'undefined' &&
		typeof investmentsData['Life Insurance'][year] !== 'undefined' &&
		typeof investmentsData['Life Insurance'][year]['Balance2'] !== 'undefined'  ){
		lifeInsuranceVal = investmentsData['Life Insurance'][year]['Balance2'];
    }
  balanceSheetCompleteData[year].Investments['Life Insurance'] = lifeInsuranceVal;

    // Short-Term Investments
    let shortTermInvestmentsValue = 0;
    if ( investmentsData !== undefined
           && investmentsData['Short-Term Investments'] !== undefined
           && investmentsData['Short-Term Investments'][year] !== undefined
           && investmentsData['Short-Term Investments'][year]['Ending Balance'] !== undefined ) {
      shortTermInvestmentsValue = investmentsData['Short-Term Investments'][year]['Ending Balance'];
    } else {
      shortTermInvestmentsValue = 0;
    }
    balanceSheetCompleteData[year].Investments['Short-Term Investments'] = shortTermInvestmentsValue;

    // Long-Term Investments
    let longTermInvestmentsValue = 0;
    if ( investmentsData !== undefined
           && investmentsData['Long-Term Investments'] !== undefined
           && investmentsData['Long-Term Investments'][year] !== undefined
           && investmentsData['Long-Term Investments'][year]['Ending Balance'] !== undefined ) {
      longTermInvestmentsValue = investmentsData['Long-Term Investments'][year]['Ending Balance'];
    } else {
      longTermInvestmentsValue = 0;
    }

    balanceSheetCompleteData[year].Investments['Long-Term Investments'] = longTermInvestmentsValue;
	
	let val529 = 0;
	if( typeof investmentsData !== 'undefined' &&
		typeof investmentsData['529 Plan'] !== 'undefined' &&
		typeof investmentsData['529 Plan'][year] !== 'undefined' &&
		typeof investmentsData['529 Plan'][year]['Balance2'] !== 'undefined'  ){
		val529 = investmentsData['529 Plan'][year]['Balance2'];
	}
	balanceSheetCompleteData[year]['Investments']['529 Plan'] = val529;
    /** End of Investments * */

    /** *********************
       ** Total Investments **
       ********************** */
    let totalInvestmentsValue = 0;
    totalInvestmentsValue = parseFloat(k401Value) + parseFloat(rothK401Value) + parseFloat(rothIRAValue) + parseFloat(sephIRAValue) + parseFloat(pensionValue) + parseFloat(shortTermInvestmentsValue) + parseFloat(longTermInvestmentsValue) + parseFloat(businessValue) + parseFloat(val529) + parseFloat(lifeInsuranceVal) + parseFloat(hsaValue) + parseFloat(traditionalValue);
    balanceSheetCompleteData[year]['Total Investments'] = totalInvestmentsValue;

    totalInvestmentsGraph.push( balanceSheetCompleteData[year]['Total Investments'] );
    /** End of Total Investments * */

    /** ****************
       ** Total Assets **
       ***************** */
    let totalAssetsValue = 0;
    totalAssetsValue = parseFloat( balanceSheetCompleteData[year]['Total Tangible Assets'] ) + parseFloat( balanceSheetCompleteData[year]['Total Investments'] );
    balanceSheetCompleteData[year]['Total Assets'] = totalAssetsValue;

    totalAssetsGraph.push( balanceSheetCompleteData[year]['Total Assets'] );
    /** End of Total Assets * */

    /** ***************
       ** Liabilities **
       **************** */
    balanceSheetCompleteData[year].Liabilities = {};

    // Existing Credit Card Debt
    let existingCreditCardDebtValue = 0;
    if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' && existingCreditCardDebtData['Summation Data'] !== undefined && existingCreditCardDebtData['Summation Data'][year] !== undefined && existingCreditCardDebtData['Summation Data'][year].EndingBalance !== undefined ) {
      existingCreditCardDebtValue = existingCreditCardDebtData['Summation Data'][year].EndingBalance;
    } else {
      existingCreditCardDebtValue = 0;
    }
    balanceSheetCompleteData[year].Liabilities['Existing Credit Card Debt'] = existingCreditCardDebtValue;
	
	// Student Loans
	let existingPersonalLoansValue = 0;
	if( typeof existingcarLoanData !== 'undefined' && existingcarLoanData !== '' &&  typeof existingcarLoanData['Summation Data'] !== 'undefined' && typeof existingcarLoanData['Summation Data'][year] !== 'undefined' && existingcarLoanData['Summation Data'][year] !== '' && typeof existingcarLoanData['Summation Data'][year]['EndingBalance5'] !== 'undefined'){
		existingPersonalLoansValue = 	existingcarLoanData['Summation Data'][year]['EndingBalance5'];
	} else{
		existingPersonalLoansValue = 0;	
	}
	balanceSheetCompleteData[year].Liabilities['Existing Personal Loans'] = existingPersonalLoansValue;

    // Student Loans
    let liabilitiesValue = 0;

    let fedSub = 0;
    let fedUnSub = 0;
    let privateHigher = 0;
    let existingfedSub = 0;
    let existingfedUnSub = 0;
    let existingprivate = 0;
    let fedSubMore = 0;
    let fedUnSubMore = 0;
    let privateHigherMore = 0;
    let fedSubNew = 0;
    let fedUnSubNew = 0;
    let privateHigherNew = 0;
    let spouseStudentLoanNew = 0;
    if ( fedSubExistingLoanData !== undefined
           && fedSubExistingLoanData['Summation Data'] !== undefined
           && fedSubExistingLoanData['Summation Data'][year] !== undefined ) {
      existingfedSub = fedSubExistingLoanData['Summation Data'][year].EndingBalance;
    }
    if ( fedUnSubExistingLoanData !== undefined
           && fedUnSubExistingLoanData['Summation Data'] !== undefined
           && fedUnSubExistingLoanData['Summation Data'][year] !== undefined ) {
      existingfedUnSub = fedUnSubExistingLoanData['Summation Data'][year].EndingBalance;
    }
    if ( privateExistingLoanData !== undefined
           && privateExistingLoanData['Summation Data'] !== undefined
           && privateExistingLoanData['Summation Data'][year] !== undefined ) {
      existingprivate = privateExistingLoanData['Summation Data'][year].EndingBalance;
    }

    if ( fedSubHigherEdLoanData !== undefined
           && fedSubHigherEdLoanData['Summation Data'] !== undefined
           && fedSubHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedSub = fedSubHigherEdLoanData['Summation Data'][year].EndingBalance;
    }
    if ( fedUnSubHigherEdLoanData !== undefined
           && fedUnSubHigherEdLoanData['Summation Data'] !== undefined
           && fedUnSubHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedUnSub = fedUnSubHigherEdLoanData['Summation Data'][year].EndingBalance;
    }
    if ( privateHigherEdLoanData !== undefined
           && privateHigherEdLoanData['Summation Data'] !== undefined
           && privateHigherEdLoanData['Summation Data'][year] !== undefined ) {
      privateHigher = privateHigherEdLoanData['Summation Data'][year].EndingBalance;
    }

    if ( fedSubMoreHigherEdLoanData !== undefined
           && fedSubMoreHigherEdLoanData['Summation Data'] !== undefined
           && fedSubMoreHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedSubMore = fedSubMoreHigherEdLoanData['Summation Data'][year].EndingBalance;
    }
    if ( fedUnSubMoreHigherEdLoanData !== undefined
           && fedUnSubMoreHigherEdLoanData['Summation Data'] !== undefined
           && fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedUnSubMore = fedUnSubMoreHigherEdLoanData['Summation Data'][year].EndingBalance;
    }
    if ( privateMoreHigherEdLoanData !== undefined
           && privateMoreHigherEdLoanData['Summation Data'] !== undefined
           && privateMoreHigherEdLoanData['Summation Data'][year] !== undefined ) {
      privateHigherMore = privateMoreHigherEdLoanData['Summation Data'][year].EndingBalance;
    }

    if ( fedSubNewHigherEdLoanData !== undefined
           && fedSubNewHigherEdLoanData['Summation Data'] !== undefined
           && fedSubNewHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedSubNew = fedSubNewHigherEdLoanData['Summation Data'][year].EndingBalance;
    }
    if ( fedUnSubNewHigherEdLoanData !== undefined
           && fedUnSubNewHigherEdLoanData['Summation Data'] !== undefined
           && fedUnSubNewHigherEdLoanData['Summation Data'][year] !== undefined ) {
      fedUnSubNew = fedUnSubNewHigherEdLoanData['Summation Data'][year].EndingBalance;
    }
    if ( privateNewHigherEdLoanData !== undefined
           && privateNewHigherEdLoanData['Summation Data'] !== undefined
           && privateNewHigherEdLoanData['Summation Data'][year] !== undefined ) {
      privateHigherNew = privateNewHigherEdLoanData['Summation Data'][year].EndingBalance;
    }
    if ( spouseStudentLoanData !== undefined
           && spouseStudentLoanData['Summation Data'] !== undefined
           && spouseStudentLoanData['Summation Data'][year] !== undefined ) {
      spouseStudentLoanNew = spouseStudentLoanData['Summation Data'][year].EndingBalance;
    }

    liabilitiesValue = parseFloat( existingfedSub )
      + parseFloat( existingfedUnSub )
      + parseFloat( existingprivate )
      + parseFloat( fedSub )
      + parseFloat( fedUnSub )
      + parseFloat( privateHigher )
      + parseFloat( fedSubMore )
      + parseFloat( fedUnSubMore )
      + parseFloat( privateHigherMore )
      + parseFloat( fedSubNew )
      + parseFloat( fedUnSubNew )
      + parseFloat( privateHigherNew )
      + parseFloat( spouseStudentLoanNew );
    // liabilitiesValue = Math.round(liabilitiesValue);
    if ( liabilitiesValue < 0 ) {
      liabilitiesValue = 0;
    }
    balanceSheetCompleteData[year].Liabilities['Student Loans'] = liabilitiesValue;

    // Car Loans
	let carLoansValue = 0;
	let carLoansValue2 = 0;
	let carLoansValue3 = 0;
	let carLoansValue4 = 0;
	let carLoansValue5 = 0;
	let carLoansValue6 = 0;
	let carLoansValue7 = 0;
	
	if( typeof existingcarLoanData !== 'undefined' && existingcarLoanData !== '' && typeof existingcarLoanData['Summation Data'] !== 'undefined' && typeof existingcarLoanData['Summation Data'][year] !== 'undefined' && existingcarLoanData['Summation Data'][year] !== '' && typeof existingcarLoanData['Summation Data'][year]['EndingBalance'] !== 'undefined' ){
		carLoansValue4 = existingcarLoanData['Summation Data'][year]['EndingBalance'];
	} else{
		carLoansValue4 = 0;
	}
	if( typeof existingcarLoanData !== 'undefined' && existingcarLoanData !== '' && typeof existingcarLoanData['Summation Data'] !== 'undefined' && typeof existingcarLoanData['Summation Data'][year] !== 'undefined' && existingcarLoanData['Summation Data'][year] !== '' && typeof existingcarLoanData['Summation Data'][year]['EndingBalance2'] !== 'undefined' ){
		carLoansValue5 = existingcarLoanData['Summation Data'][year]['EndingBalance2'];
	} else{
		carLoansValue5 = 0;
	}
	if( typeof existingcarLoanData !== 'undefined' && existingcarLoanData !== '' && typeof existingcarLoanData['Summation Data'] !== 'undefined' && typeof existingcarLoanData['Summation Data'][year] !== 'undefined' && existingcarLoanData['Summation Data'][year] !== '' && typeof existingcarLoanData['Summation Data'][year]['EndingBalance3'] !== 'undefined' ){
		carLoansValue6 = existingcarLoanData['Summation Data'][year]['EndingBalance3'];
	} else{
		carLoansValue6 = 0;
	}
	if( typeof existingcarLoanData !== 'undefined' && existingcarLoanData !== '' && typeof existingcarLoanData['Summation Data'] !== 'undefined' && typeof existingcarLoanData['Summation Data'][year] !== 'undefined' && existingcarLoanData['Summation Data'][year] !== '' && typeof existingcarLoanData['Summation Data'][year]['EndingBalance4'] !== 'undefined' ){
		carLoansValue7 = existingcarLoanData['Summation Data'][year]['EndingBalance4'];
	} else{
		carLoansValue7 = 0;
	}
	
	if (typeof carLoan1Data !== 'undefined' &&
		typeof carLoan1Data['Summation Data'] !== 'undefined' &&
		typeof carLoan1Data['Summation Data'][year] !== 'undefined') {
		carLoansValue2 = carLoan1Data['Summation Data'][year]['EndingBalance'];
	} else {
		carLoansValue2 = 0;
	}
	
	
	if (typeof carLoan2Data !== 'undefined' &&
		typeof carLoan2Data['Summation Data'] !== 'undefined' &&
		carLoan2Data['Summation Data'] !== '' &&
		typeof carLoan2Data['Summation Data'][year] !== 'undefined') {
		carLoansValue3 = carLoan2Data['Summation Data'][year]['EndingBalance'];
	} else {
		carLoansValue3 = 0;
	}
	carLoansValue =  carLoansValue2 + carLoansValue3 + carLoansValue4 + carLoansValue5 + carLoansValue6 + carLoansValue7;
	balanceSheetCompleteData[year]['Liabilities']['Car Loans'] = carLoansValue;

    // Mortgages
    let mortgagesValue = 0;
    let mortgagesValue1 = 0;
    let mortgagesValue2 = 0;
    let mortgagesValue3 = 0;
    if ( module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== ''
                && year >= module18Data.existing_home_sale_year ) {
      mortgagesValue3 = 0;
    } else if ( existingHomeMortgageData !== undefined
               && existingHomeMortgageData['Summation Data'] !== undefined
              && existingHomeMortgageData['Summation Data'] !== ''
               && existingHomeMortgageData['Summation Data'][year] !== undefined ) {
      mortgagesValue3 = existingHomeMortgageData['Summation Data'][year].EndingBalance;
    } else {
      mortgagesValue3 = 0;
    }

    if ( mortgage1Data !== undefined
           && mortgage1Data['Summation Data'] !== undefined
           && mortgage1Data['Summation Data'][year] !== undefined ) {
      mortgagesValue1 = mortgage1Data['Summation Data'][year].EndingBalance;
    } else {
      mortgagesValue1 = 0;
    }

    if ( module14Data.year_of_sale !== undefined
          && year >= module14Data.year_of_sale ) {
      mortgagesValue2 = 0;
    } else if ( mortgage2Data !== undefined
               && mortgage2Data['Summation Data'] !== undefined
               && mortgage2Data['Summation Data'] !== ''
               && mortgage2Data['Summation Data'][year] !== undefined ) {
      mortgagesValue2 = mortgage2Data['Summation Data'][year].EndingBalance;
    } else {
      mortgagesValue2 = 0;
    }
    mortgagesValue = mortgagesValue1 + mortgagesValue2 + mortgagesValue3;
    balanceSheetCompleteData[year].Liabilities.Mortgages = mortgagesValue;
    /** End of Liabilities * */

    /** *********************
       ** Total Liabilities **
       ********************** */
    let totalLiabilitiesValue = 0;
    totalLiabilitiesValue = parseFloat( liabilitiesValue ) + parseFloat( carLoansValue ) + parseFloat( mortgagesValue ) + parseFloat( existingCreditCardDebtValue ) + parseFloat( existingPersonalLoansValue );
    balanceSheetCompleteData[year]['Total Liabilities'] = totalLiabilitiesValue;
    totalLiabilitiesGraph.push( balanceSheetCompleteData[year]['Total Liabilities'] );
    /** End of otal Liabilites * */

    /** *************
       ** Net Worth **
       ************** */
    let newWorthValue = 0;
    newWorthValue = parseFloat( balanceSheetCompleteData[year]['Total Assets'] ) - parseFloat( balanceSheetCompleteData[year]['Total Liabilities'] );
    balanceSheetCompleteData[year]['Net Worth'] = newWorthValue;
    netWorthGraph.push( balanceSheetCompleteData[year]['Net Worth'] );
    /** End of Net Worth * */
  }

  // To return Balance Sheet Complete Data
  function balanceSheetTotalData() {
    balanceSheetCompleteData['Total Tangible Assets Graph'] = totalTangibleAssetsGraph;
    balanceSheetCompleteData['Total Investments Graph'] = totalInvestmentsGraph;
    balanceSheetCompleteData['Total Assets Graph'] = totalAssetsGraph;
    balanceSheetCompleteData['Total Liabilities Graph'] = totalLiabilitiesGraph;
    balanceSheetCompleteData['Net Worth Graph'] = netWorthGraph;

    return balanceSheetCompleteData;
  }
  return balanceSheetTotalData();
}
export default balanceSheetData;