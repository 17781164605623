import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';

const Home = ( props ) => {
  const { classes, setHomes, homes, handleAssestObject, handleUpdatedObject} = props;

  const update = (index, type) => {
    const inputValues = {...handleAssestObject}
    if(type === 1){
      if(homes[index].home){
        let newArr = [
          { home: false, type: 1},
          { home: false, type: 2}
        ]
        setHomes([...newArr])
        inputValues.existig_home_current_status = false; 
        inputValues.existig_home_current_status_2 = false; 
      }else{
        let newArr = [...homes];
        newArr[index] = { home: true, type: 1, currentStep: 0 }
        setHomes([...newArr])
        inputValues.existig_home_current_status = true; 
      }
    }else if(homes[0].home){
      if(homes[index].home){
        let newArr = [...homes];
        newArr[index] = { home: false, type: 2, currentStep: 0 }
        setHomes([...newArr])
        inputValues.existig_home_current_status_2 = false; 
      }else{
        let newArr = [...homes];
        newArr[index] = { home: true, type: 2, currentStep: 0 }
        setHomes([...newArr])
        inputValues.existig_home_current_status_2 = true; 
      }
    }
    // inputValues[`vehicle_${index+1}_type`] = type;
    handleUpdatedObject(inputValues)
  }
  console.log("handleAssestObjects", homes)
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        If you own a home or a second home, make that selection below. If you currently rent, skip the below and continue to the next section.
      </p>
    
      <ul className={ classes.retirementList}>
        <li className={  handleAssestObject.existig_home_current_status  ? classes.activeVehicle : classes.activeDisable } onClick={ () => update(0, 1)}>
          Home 
          <span>
            { homes[0].type === 1 && handleAssestObject.existig_home_current_status && <img src={BlueTick} alt={`Home`} />}
          </span>
        </li>
        <li className={  handleAssestObject.existig_home_current_status_2  ? classes.activeVehicle : classes.activeDisable } onClick={ () => update(1, 2)}>
          Second Home 
          <span>
            { homes[1].type === 2 && handleAssestObject.existig_home_current_status_2 && <img src={BlueTick} alt={`Seacond Home`} />}
          </span>
        </li>
      </ul>
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( Home );
