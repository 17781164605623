import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import $ from 'jquery';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-car/styles';
import * as modulesServices from '../../calculations/modules-services';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const arrayValues = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 100,
    scaledValue: 100,
    label: '$100',
  },
  {
    value: 200,
    scaledValue: 200,
    label: '$200',
  },
  {
    value: 300,
    scaledValue: 300,
    label: '$300',
  },
  {
    value: 400,
    scaledValue: 400,
    label: '$400',
  },
  {
    value: 500,
    scaledValue: 500,
    label: '$500',
  },
];

const SecondCarLeaseMonthlyExpenses = ( props ) => {
  const {
    classes, handleBuyingCarObject, handleDefinationPopup, handleUpdatedObject, percentageVal, percentageVal2, finalMnthlyAfterIncome, finalMnthlyExpenses,
  } = props;
  const [maintainanceValue, setCarMaintainance] = React.useState( handleBuyingCarObject.leasing_second_car_maintenance === undefined ? 100 : handleBuyingCarObject.leasing_second_car_maintenance );
  const [carInsurance, setCarInsurance] = React.useState( handleBuyingCarObject.leasing_second_car_insurance === undefined ? 100 : handleBuyingCarObject.leasing_second_car_insurance );
  const [gasValue, setGasValue] = React.useState( handleBuyingCarObject.leasing_second_car_gas === undefined ? 100 : handleBuyingCarObject.leasing_second_car_gas );

  useEffect( () => {
    $( '#percentage1' ).css( 'height', `${percentageVal}%` );
    $( '#percentage2' ).css( 'height', `${percentageVal2}%` );
  }, [percentageVal,percentageVal2] );

  useEffect( () => {
    const monthlyObject = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.leasing_second_car_maintenance !== undefined ) {
      setCarMaintainance( handleBuyingCarObject.leasing_second_car_maintenance );
    }else {
      setCarMaintainance( 100 );
      monthlyObject.leasing_second_car_maintenance = 100;
    }
    if ( handleBuyingCarObject.leasing_second_car_insurance !== undefined ) {
      setCarInsurance( handleBuyingCarObject.leasing_second_car_insurance );
    }else {
      setCarInsurance( 100 );
      monthlyObject.leasing_second_car_insurance = 100;
    }
    if ( handleBuyingCarObject.leasing_second_car_gas !== undefined ) {
      setGasValue( handleBuyingCarObject.leasing_second_car_gas );
    }else {
      setGasValue( 100 );
      monthlyObject.leasing_second_car_gas = 100;
    }
    handleUpdatedObject( monthlyObject );
    // eslint-disable-next-line
  }, []);

  const updateValue = ( e, value, field, type ) => {
    const totalPurchaseObj = { ...handleBuyingCarObject };

    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    totalPurchaseObj[field] = newvalue;
    switch ( field ) {
      case 'leasing_second_car_maintenance': {
        setCarMaintainance( newvalue );
        break;
      }
      case 'leasing_second_car_insurance': {
        setCarInsurance( newvalue );
        break;
      }
      case 'leasing_second_car_gas': {
        setGasValue( newvalue );
        break;
      }

      default: {
        break;
      }
    }
    handleUpdatedObject( totalPurchaseObj );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Leasing a car comes with monthly expenses. Those monthly expenses including your lease payment shouldn&apos;t be greater than 20% of your income and you should target 10% as a maximum.</p>
      <p>Leasing a car requires maintenance. According to AAA, budget about $100 per month for usual repairs. Your leasing company can penalize you for not keeping up with maintenance. Avoid these fees by planning ahead.</p>
      <p>Car insurance is required by your state and each state will have minimum levels of insurance required.</p>
      <p>Lastly, gas. Depending on your state and how often you use your car, gas will vary. A good budgeting benchmark would be roughly $100 per month</p>
      <div className={ classes.priceSlidersScroll }>
        <div className={ classes.priceSlidersScrollDiv }>  
          <div className={ classes.livingExpences }>
            <div className={ classNames( classes.mainBlock, classes.mainRightPadding ) }>
              <div className={ classes.rentGraphBlock }>
                <div className={ classes.livingExpencesRange }>
                  <h3 className={ classes.opacityZero }>Monthly</h3>
                  <span
                    className={ classes.dottedText }
                    aria-hidden="true"
                    data-for="definationTitle"
                    data-tip="Click for a definition."
                    onClick={ () => handleDefinationPopup(
                      'Maintenance',
                    ) }
                  >
                    Maintenance
                  </span>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ arrayValues }
                      min={ 0 }
                      max={ 500 }
                      step={ 10 }
                      value={ maintainanceValue }
                      valueLabelFormat={ `${commonFunctions.numFormatter( maintainanceValue )}` }
                      onChange={ ( e, value ) => { updateValue( e, value, 'leasing_second_car_maintenance', 'slider' ); } }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <h3><span className={ classes.linedText }>Monthly</span></h3>
                  <div className={ classNames( classes.annualInput, classes.rightText ) }>
                    <span>$</span>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      value={ maintainanceValue }
                      decimalScale={0}
                      fixedDecimalScale
                      onValueChange={ ( e ) => updateValue( e, '', 'leasing_second_car_maintenance', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                      } }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <h3 className={ classes.annualEnd }><span className={ classes.linedText }>Annual</span></h3>
                  <div className={ classNames( classes.annualInput, classes.annualRightText ) }>
                    <span>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ maintainanceValue * 12 } prefix="$" />
                    </span>
                  </div>
                </div>
              </div>
              <div className={ classes.rentGraphBlock }>
                <div className={ classes.livingExpencesRange }>
                  <span
                    className={ classes.dottedText }
                    aria-hidden="true"
                    data-for="definationTitle"
                    data-tip="Click for a definition."
                    onClick={ () => handleDefinationPopup(
                      'Car Insurance',
                    ) }
                  >
                    Car Insurance
                  </span>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ arrayValues }
                      min={ 0 }
                      max={ 500 }
                      step={ 10 }
                      value={ carInsurance }
                      valueLabelFormat={ `${commonFunctions.numFormatter( carInsurance )}` }
                      onChange={ ( e, value ) => { updateValue( e, value, 'leasing_second_car_insurance', 'slider' ); } }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ classNames( classes.annualInput, classes.rightText ) }>
                    <span>$</span>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      value={ carInsurance }
                      decimalScale={0}
                      fixedDecimalScale
                      onValueChange={ ( e ) => updateValue( e, '', 'leasing_second_car_insurance', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                      } }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ classNames( classes.annualInput, classes.annualRightText ) }>
                    <span>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ carInsurance * 12 } prefix="$" />
                    </span>
                  </div>
                </div>
              </div>
              <div className={ classes.rentGraphBlock }>
                <div className={ classes.livingExpencesRange }>
                  <span
                    className={ classes.dottedText }
                    aria-hidden="true"
                    data-for="definationTitle"
                    data-tip="Click for a definition."
                    onClick={ () => handleDefinationPopup(
                      'Gas',
                    ) }
                  >
                    Gas
                  </span>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ arrayValues }
                      min={ 0 }
                      max={ 500 }
                      step={ 10 }
                      value={ gasValue }
                      valueLabelFormat={ `${commonFunctions.numFormatter( gasValue )}` }
                      onChange={ ( e, value ) => { updateValue( e, value, 'leasing_second_car_gas', 'slider' ); } }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ classNames( classes.annualInput, classes.rightText ) }>
                    <span>$</span>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      value={ gasValue }
                      decimalScale={0}
                      fixedDecimalScale
                      onValueChange={ ( e ) => updateValue( e, '', 'leasing_second_car_gas', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                      } }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ classNames( classes.annualInput, classes.annualRightText ) }>
                    <span>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ gasValue * 12 } prefix="$" />
                    </span>
                  </div>
                </div>
              </div>
              <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
                <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                  <p className={ classes.textRight }>Lease Payment</p>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ classNames( classes.annualInput, classes.rightBoldText ) }>
                    <span>
                      {handleBuyingCarObject.leasing_second_car_lease_payment !== undefined
                        ? <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ handleBuyingCarObject.leasing_second_car_lease_payment } prefix="$" /> : ( '$0' )}
                    </span>
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ classNames( classes.annualInput, classes.annualRightText, classes.rightBoldText ) }>

                    <span>
                      {handleBuyingCarObject.leasing_second_car_lease_payment !== undefined
                        ? <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ handleBuyingCarObject.leasing_second_car_lease_payment * 12 } prefix="$" /> : ( '$0' )}
                    </span>
                  </div>
                </div>
              </div>
              <div className={ `${classes.rentGraphBlock} ${classes.rentGraphBlockNew}` }>
                <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}` }>
                  <p className={ classes.textRight }>Total Expenses</p>
                </div>
                <div className={ classes.livingExpencesMothly }>

                  <div className={ classNames( classes.annualInput, classes.rightBoldText ) }>
                    <span>
                      {modulesServices.module12Data.leasingCarTotalMonthlyExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module12Data.leasingCarTotalMonthlyExpenses } prefix={ modulesServices.module12Data.leasingCarTotalMonthlyExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module12Data.leasingCarTotalMonthlyExpenses < 0 && ')' } />
                      ) : ( '$0' )}

                    </span>
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ classNames( classes.annualInput, classes.rightBoldText ) }>
                    <span>
                      {modulesServices.module12Data.leasingCarTotalAnnualExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module12Data.leasingCarTotalAnnualExpenses } prefix={ modulesServices.module12Data.leasingCarTotalAnnualExpenses >= 0 ? '$' : '($' } suffix={ modulesServices.module12Data.leasingCarTotalAnnualExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                </div>
              </div>

            </div>

            <div className={ classes.rightBlock }>
              <ul className={ classes.utilitiesChart }>
                <li className={ classes.chartBar }>
                  <div className={ classes.chartText }>
                    Monthly Car Expenses
                    <span  className={ classes.chartSpan }>
                      {finalMnthlyExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ finalMnthlyExpenses } prefix={ finalMnthlyExpenses >= 0 ? '$' : '($' } suffix={ finalMnthlyExpenses < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                  <span className={ classes.percentageBar } id="percentage1" />
                  <div className={ classes.graphPercentage }>
                    {percentageVal !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageVal } prefix={ percentageVal >= 0 ? '' : '(' } suffix={ percentageVal < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </div>
                </li>
                <li className={ classes.chartBar }>
                  <div className={ classes.chartText }>
                    Monthly Income
                    <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                      {finalMnthlyAfterIncome !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ finalMnthlyAfterIncome } prefix={ finalMnthlyAfterIncome >= 0 ? '$' : '($' } suffix={ finalMnthlyAfterIncome < 0 && ')' } />
                      ) : ( '$0' )}
                    </span>
                  </div>
                  <span  className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } id="percentage2" />
                  <div  className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                    {percentageVal2 !== undefined ? (
                      <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageVal2 } prefix={ percentageVal2 >= 0 ? '' : '(' } suffix={ percentageVal2 < 0 ? '%)' : '%' } />
                    ) : ( '0.00%' )}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />

    </div>
  );
};

SecondCarLeaseMonthlyExpenses.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  percentageVal: PropTypes.number.isRequired,
  percentageVal2: PropTypes.number.isRequired,
  finalMnthlyAfterIncome: PropTypes.number.isRequired,
  finalMnthlyExpenses: PropTypes.number.isRequired,
};

export default withStyles( styles )( SecondCarLeaseMonthlyExpenses );
