import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import styles from './styles';
import logo from '../../../assets/img/logo.png';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';

const DOWNLOAD_PDF = loader( '../../../graphql/schema/fc/download-pdf.graphql' );


const StudentLoanPopups = ( props ) => {
  const {
    classes, closeWorkSheet, openWorkSheet
  } = props;

  const closePopup = () => {
    closeWorkSheet( false );
  };

  const  buyingaHomeCompleteData = moduleServices.buyingAHomeCompleteData();
  const reiCompleteData  = buyingaHomeCompleteData;
  const reiYearsList = buyingaHomeCompleteData['Years List'];
  const netCashFlowVal = -(reiCompleteData['Buying Expenses']['Down Payment']+reiCompleteData['Buying Expenses']['Closing Costs']+reiCompleteData['Buying Expenses']['Fit Out Costs']);
  const totalUses =  -(reiCompleteData['Cash Out']['Mortgage Payment']+reiCompleteData['Cash Out']['Property Taxes']+reiCompleteData['Cash Out']['Private Mortgage Insurance (PMI)']+reiCompleteData['Cash Out']['Homeowners Insurance']+reiCompleteData['Cash Out']['Property Maintenance'] +reiCompleteData['Cash Out']['Association Fees']+reiCompleteData['Cash Out']['Utilities'])


  const [donwloadPdf] = useMutation( DOWNLOAD_PDF, {
    onCompleted( response ) {
      if ( response.downloadPDF.status ) {
        window.open( response.downloadPDF.url );        
      }
    },
  } );

  const handleDownloadPDF = () => {
    // window.open( response.downloadPDF.url );
    
    donwloadPdf( {
      variables: {
        data: {type: 'buyingahome-sheet', summary_financial: JSON.stringify(reiCompleteData)},
      },
    } );
  };

  return (
    <Typography variant="body1" component="div">
        
      <Dialog
        open={ openWorkSheet }
        onClose={ closePopup }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.worksheetModal }
        >
        <DialogTitle className={ classes.summayModalHeader }>
          <div >
            <div>
              <Grid container>
                <Grid item sm={ 4 }>
                  <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
                </Grid>
                <Grid item sm={ 4 }>
                 <Button className={ classes.downloadBtn } onClick={ handleDownloadPDF }>Download PDF</Button>
                </Grid>
              </Grid>
              <Button className={ classes.incomePopupClose } onClick={ closePopup }>×</Button>             
            </div>
          </div>          
        </DialogTitle>
          <div className={ classes.borderTitle } ><p className={classes.borderStrip}>Buying a Home Analysis</p></div>
          <DialogContent className={ classes.modalBlock }>           
            <div className={ classes.modalBody }>            
              <table className={ classes.analiseTable }>
                <tr>
                  <td>
                    <table >
                        <tbody>
                          <tr className={ classes.heightTen }></tr>
                          <tr>
                            <td className={ classNames( classes.topTable, classes.buyingHomeOne ) }>
                              <table >
                                <tr className={ classes.blueTableRow }>
                                  <td>Upfront Costs</td>
                                  <td >
                                    {reiCompleteData['Buying Expenses']['Purchase Year'] !== undefined && reiCompleteData['Buying Expenses']['Purchase Year'] > 0 ? (
                                      reiCompleteData['Buying Expenses']['Purchase Year']
                                    ):("&nbsp;") }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Down Payment</td>
                                  <td className={-reiCompleteData['Buying Expenses']['Down Payment'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Buying Expenses']['Down Payment'] } prefix={ -reiCompleteData['Buying Expenses']['Down Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Buying Expenses']['Down Payment'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Closing Costs</td>
                                  <td className={-reiCompleteData['Buying Expenses']['Closing Costs'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Buying Expenses']['Closing Costs'] } prefix={ -reiCompleteData['Buying Expenses']['Closing Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Buying Expenses']['Closing Costs'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Fit Out Costs</td>
                                  <td className={-reiCompleteData['Buying Expenses']['Fit Out Costs'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Buying Expenses']['Fit Out Costs'] } prefix={ -reiCompleteData['Buying Expenses']['Fit Out Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Buying Expenses']['Fit Out Costs'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Net Cash Flow in Purchase Year</td>
                                  <td className={netCashFlowVal < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ netCashFlowVal } prefix={ netCashFlowVal >= 0 ? '$' : '($' } suffix={ netCashFlowVal < 0 && ')' } />
                                  </td>
                                </tr>                               
                                <tr className={ classes.heightTwoZero }></tr>
                                <tr className={ classes.blueTableRow }>
                                  <td>Annual Uses of Cash</td>
                                  <td>
                                    {reiCompleteData['Buying Expenses']['Purchase Year'] > 0 ? (reiCompleteData['Buying Expenses']['Purchase Year']):("&nbsp")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mortgage Payment</td>
                                  <td className={-reiCompleteData['Cash Out']['Mortgage Payment'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Mortgage Payment'] } prefix={ -reiCompleteData['Cash Out']['Mortgage Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Mortgage Payment'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Property Taxes</td>
                                  <td className={-reiCompleteData['Cash Out']['Property Taxes'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Property Taxes'] } prefix={ -reiCompleteData['Cash Out']['Property Taxes'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Property Taxes'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Private Mortgage Insurance (PMI)</td>
                                  <td className={-reiCompleteData['Cash Out']['Private Mortgage Insurance (PMI)'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Private Mortgage Insurance (PMI)'] } prefix={ -reiCompleteData['Cash Out']['Private Mortgage Insurance (PMI)'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Private Mortgage Insurance (PMI)'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Homeowner{"'"}s Insurance</td>
                                  <td className={-reiCompleteData['Cash Out']['Homeowners Insurance'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Homeowners Insurance'] } prefix={ -reiCompleteData['Cash Out']['Homeowners Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Homeowners Insurance'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Property Maintenance</td>
                                  <td className={-reiCompleteData['Cash Out']['Property Maintenance'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Property Maintenance'] } prefix={ -reiCompleteData['Cash Out']['Property Maintenance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Property Maintenance'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Association Fees</td>
                                  <td className={-reiCompleteData['Cash Out']['Association Fees'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Association Fees'] } prefix={ -reiCompleteData['Cash Out']['Association Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Association Fees'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Utilities</td>
                                  <td className={-reiCompleteData['Cash Out']['Utilities'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Utilities'] } prefix={ -reiCompleteData['Cash Out']['Utilities'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Utilities'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Total Uses</td>
                                  <td className={totalUses < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalUses } prefix={ totalUses >= 0 ? '$' : '($' } suffix={ totalUses < 0 && ')' } />
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td className={ classNames( classes.topTable, classes.buyingHomeTwo ) }>
                              <table>
                                <tr  className={ classes.blueTableRow }>
                                  <td>Closing Costs</td>
                                  <td>
                                    {(reiCompleteData['Buying Expenses']['Purchase Year'] !== undefined && reiCompleteData['Buying Expenses']['Purchase Year'] > 0) ? reiCompleteData['Buying Expenses']['Purchase Year'] : '&nbsp;'}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Appraisal Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Appraisal Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Appraisal Fee'] } prefix={ -reiCompleteData['Closing Cost']['Appraisal Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Appraisal Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Credit Report Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Credit Report Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Credit Report Fee'] } prefix={ -reiCompleteData['Closing Cost']['Credit Report Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Credit Report Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Attorney Fee: Title Closing/Escrow Account</td>
                                  <td className={-reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] } prefix={ -reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Flood Determination</td>
                                  <td className={-reiCompleteData['Closing Cost']['Flood Determination'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Flood Determination'] } prefix={ -reiCompleteData['Closing Cost']['Flood Determination'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Flood Determination'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Tax Service Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Tax Service Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Tax Service Fee'] } prefix={ -reiCompleteData['Closing Cost']['Tax Service Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Tax Service Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Title Insurance</td>
                                  <td className={-reiCompleteData['Closing Cost']['Owners Title Insurance'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Owners Title Insurance'] } prefix={ -reiCompleteData['Closing Cost']['Owners Title Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Owners Title Insurance'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Recording Mortgage</td>
                                  <td className={-reiCompleteData['Closing Cost']['Recording Mortgage'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Recording Mortgage'] } prefix={ -reiCompleteData['Closing Cost']['Recording Mortgage'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Recording Mortgage'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Homeowner{"'"}s Insurance (3 months prepaid upfront)*</td>
                                  <td className={-reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] } prefix={ -reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Transfer Taxes</td>
                                  <td className={-reiCompleteData['Closing Cost']['Transfer Taxes'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Transfer Taxes'] } prefix={ -reiCompleteData['Closing Cost']['Transfer Taxes'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Transfer Taxes'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Processing Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Processing Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Processing Fee'] } prefix={ -reiCompleteData['Closing Cost']['Processing Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Processing Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Underwriting Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Underwriting Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Underwriting Fee'] } prefix={ -reiCompleteData['Closing Cost']['Underwriting Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Underwriting Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Loan Points</td>
                                  <td className={-reiCompleteData['Closing Cost']['Loan Acquisition Points'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Loan Acquisition Points'] } prefix={ -reiCompleteData['Closing Cost']['Loan Acquisition Points'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Loan Acquisition Points'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Origination Fees</td>
                                  <td className={-reiCompleteData['Closing Cost']['Origination Points'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Origination Points'] } prefix={ -reiCompleteData['Closing Cost']['Origination Points'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Origination Points'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mortgage Interest (1 month prepaid upfront)*</td>
                                  <td className={-reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] } prefix={ -reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Property Taxes (3 months prepaid upfront)*</td>
                                  <td className={-reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] } prefix={ -reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pest Inspection</td>
                                  <td className={-reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] } prefix={ -reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Home Owners Association Transfer Fees</td>
                                  <td className={-reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] } prefix={ -reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other</td>
                                  <td className={-reiCompleteData['Closing Cost']['Others'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Others'] } prefix={ -reiCompleteData['Closing Cost']['Others'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Others'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Total Closing Costs</td>
                                  <td className={-reiCompleteData['Closing Cost']['Total Initial Costs'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Total Initial Costs'] } prefix={ -reiCompleteData['Closing Cost']['Total Initial Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Total Initial Costs'] < 0 && ')' } />
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td></td>
                          </tr>
                          <tr className={ classes.heightFifty }></tr>
                        </tbody>
                    </table>
                    <hr className={ classes.blueRei }/>
                    <br/>
                    <table className={ classNames( classes.incomeCashTable, classes.annualCashTable ) }>
                      <thead>
                        <tr  className={ classNames( classes.cashFlowBlue, classes.leftSpaceData ) }>
                          <td>Annual Cash Flow</td>
                          {
                            reiYearsList.map((year) => (
                              <td>{year}</td>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={ classes.emptyRow }>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map(() => (
                              <td>&nbsp;</td>    
                            ))
                          }
                        </tr>
                        <tr className={ classes.blueRowBackground}>
                          <td>Annual Cash Flow</td>
                          {
                            reiYearsList.map((year) => (
                              <td>&nbsp;</td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Down Payment</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Down Payment'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Down Payment'] } prefix={ -reiCompleteData['Data1'][year]['Down Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Down Payment'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Fit Out Costs</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Fit Out Costs'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Fit Out Costs'] } prefix={ -reiCompleteData['Data1'][year]['Down Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Down Payment'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Closing Costs</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Closing Costs'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Closing Costs'] } prefix={ -reiCompleteData['Data1'][year]['Closing Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Closing Costs'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Mortgage Payment</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Mortgage Payment'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Mortgage Payment'] } prefix={ -reiCompleteData['Data1'][year]['Mortgage Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Mortgage Payment'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Property Taxes</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Property Taxes'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Property Taxes'] } prefix={ -reiCompleteData['Data1'][year]['Property Taxes'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Property Taxes'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Private Mortgage Insurance (PMI)</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Private Mortgage Insurance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Private Mortgage Insurance'] } prefix={ -reiCompleteData['Data1'][year]['Private Mortgage Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Private Mortgage Insurance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Homeowner{"'"}s Insurance</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Houseowners Insurance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] } prefix={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Property Maintenance</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Property Maintenance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Property Maintenance'] } prefix={ -reiCompleteData['Data1'][year]['Property Maintenance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Property Maintenance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Association Fees</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Association Fees'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Association Fees'] } prefix={ -reiCompleteData['Data1'][year]['Association Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Association Fees'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Utilities</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Utilities'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Utilities'] } prefix={ -reiCompleteData['Data1'][year]['Utilities'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Utilities'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr className={ classNames( classes.borderLine, classes.leftSpaceData, classes.strongText  ) }>
                          <td className={ classes.subHeading }>Total Cash Flow</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Net Cash Flow'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Net Cash Flow'] } prefix={ reiCompleteData['Data1'][year]['Net Cash Flow'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Net Cash Flow'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
              <div className={ classes.notePara }>
                  <p className={ classes.noteRei }>*Prepayments are typically kept in an escrow account. To learn more about an escrow account and how it plays a role in real estate transactions, see the Boost My Awareness section for a deeper understanding.</p>
              </div>
            </div>
          </DialogContent>
        <DialogActions>
        <div className={ classes.modalFooter }>
        <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closePopup }>Close</Button>
        </div>
        </DialogActions>
      </Dialog>

    </Typography>
    );
};

StudentLoanPopups.propTypes = {
  classes: PropTypes.object.isRequired,
  closeWorkSheet:PropTypes.func.isRequired, 
  openWorkSheet:PropTypes.bool.isRequired,
};


export default withStyles( styles )( StudentLoanPopups );

