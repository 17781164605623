import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from './styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );

HCExporting( Highcharts );

const SavingsGrowth = ( props ) => {
  const { classes } = props;

  const savingGrowthDefaultValues = {
    monthlySavings: '250',
    annualReturn: 5,
  };

  const [savingGrowthValues, setSavingGrowthValues] = useState( savingGrowthDefaultValues );
  const [graphView, setGraphView] = useState( {} );
  // eslint-disable-next-line
  const getSavingGrowthGraph = () => {
    if ( typeof savingGrowthValues.monthlySavings !== 'undefined' ) {
      savingGrowthValues.monthlySavings = parseInt( savingGrowthValues.monthlySavings, 10 );
    } else {
      savingGrowthValues.monthlySavings = 250;
    }
    if ( typeof savingGrowthValues.monthlySavings !== 'undefined' ) {
      savingGrowthValues.annualRate = savingGrowthValues.annualReturn;
    } else {
      savingGrowthValues.annualRate = 5;
    }
    const graphArrayValues = [];
    const graphArrayValues1 = [];
    const graphArrayValues2 = [];
    const graphArrayYearsValues = [];
    for ( let y = 1; y <= 30; y += 1 ) {
      // Default Return(5%)
      const endingValue = commonFunctions.FV( ( savingGrowthValues.annualRate / 12 ) / 100, y * 12, -savingGrowthValues.monthlySavings, 0, 0 );
      graphArrayValues.push( endingValue );
      if ( y === 5 ) {
        savingGrowthValues.fifthValue = endingValue;
      }
      if ( y === 10 ) {
        savingGrowthValues.tenthValue = endingValue;
      }
      if ( y === 15 ) {
        savingGrowthValues.fifteenthValue = endingValue;
      }

      savingGrowthValues.firstReturn = savingGrowthValues.annualRate;
      savingGrowthValues.firstReturnLegend = parseFloat( savingGrowthValues.firstReturn ).toFixed( 2 );

      // Second Return(savingGrowthValues.annualRate/2)
      savingGrowthValues.secondReturn = parseInt( savingGrowthValues.annualRate, 10 ) / 2;
      savingGrowthValues.secondReturnLegend = parseFloat( savingGrowthValues.secondReturn ).toFixed( 2 );
      const endingValue1 = commonFunctions.FV( ( savingGrowthValues.secondReturn / 12 ) / 100, y * 12, -savingGrowthValues.monthlySavings, 0, 0 );
      graphArrayValues1.push( endingValue1 );

      // Second Return(savingGrowthValues.annualRate + annualRate/2)
      savingGrowthValues.thirdReturn = parseInt( savingGrowthValues.annualRate, 10 ) + ( parseInt( savingGrowthValues.annualRate, 10 ) / 2 );
      savingGrowthValues.thirdReturnLegend = parseFloat( savingGrowthValues.thirdReturn ).toFixed( 2 );
      const endingValue2 = commonFunctions.FV( ( savingGrowthValues.thirdReturn / 12 ) / 100, y * 12, -savingGrowthValues.monthlySavings, 0, 0 );
      graphArrayValues2.push( endingValue2 );

      graphArrayYearsValues.push( y );
    }

    const graphNew = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          width: 600,
          scrollPositionX: 0,
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      xAxis: [{
        tickInterval: 1,
        categories: graphArrayYearsValues,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: 'Years',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if ( chart.value < 0 ) {
              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
            }
            return formatDollar( Math.round( chart.value ) );
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      tooltip: {
        headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
        crosshairs: true,
        shared: true,
        valueDecimals: 0,
        valuePrefix: '$',
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2,
            },
          },
          marker: {
            enabled: false,
          },

        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        name: `${savingGrowthValues.thirdReturnLegend}% return`,
        data: graphArrayValues2,
        color: '#4472C4',
      }, {
        name: `${savingGrowthValues.firstReturnLegend}% return`,
        data: graphArrayValues,
        color: '#Ed7D31',
      }, {
        name: `${savingGrowthValues.secondReturnLegend}% return`,
        data: graphArrayValues1,
        color: '#A5A5A5',
      }],
    };
    setGraphView( graphNew );
  };

  useEffect( () => {
    getSavingGrowthGraph();
    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...savingGrowthValues };
    newValue = e.value ? e.floatValue : 0;
    valuesUpdated[field] = newValue;
    setSavingGrowthValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Savings Growth</li>
            </ul>
            <div className={ classes.loanProfitCalculator }>
              <div className={ classes.showMeContent }>
                <div className={ classes.showPortion }>
                  <div className={ classes.hiGraph }>
                    <h3 className={ classes.graphHead }><strong>Savings Growth</strong></h3>
                    <hr className={ classes.blueLine } />
                    <div className={ classes.careerInputs }>
                      <div className={ classes.cpBackground }>
                        <div className={ classes.cpInput }>
                          <label htmlFor="savings">
                            Monthly Savings:
                            <div className={ classNames( classes.inputGroup, classes.inputGroupLeft ) }>
                              <span className={ classes.dollarSymbol }>$</span>
                              <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ savingGrowthValues.monthlySavings } onValueChange={ ( e ) => updateValue( e, 'monthlySavings' ) } onFocus={ ( e ) => e.target.select() } />
                            </div>
                          </label>
                        </div>
                        <div className={ classes.cpInput }>
                          <label htmlFor="return">
                            Annual Return:
                            <div className={ classNames( classes.inputGroup, classes.inputGroupRight ) }>
                              <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ savingGrowthValues.annualReturn } onValueChange={ ( e ) => updateValue( e, 'annualReturn' ) } onFocus={ ( e ) => e.target.select() } />
                              <span className={ classNames( classes.dollarSymbol, classes.percentSymbol ) }>%</span>
                            </div>
                          </label>
                        </div>
                        <div className={ classes.cpButton }>
                          <Button onClick={ getSavingGrowthGraph }>Calculate</Button>
                        </div>
                        <div className={ classes.listItems }>
                          <ul>
                            <li>
                              <p>
                                <span>5 Years</span>
                                <font>
                                  <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ savingGrowthValues.fifthValue !== undefined ? savingGrowthValues.fifthValue : 0 } displayType="text" prefix="$" />
                                </font>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>10 Years</span>
                                <font>
                                  <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ savingGrowthValues.tenthValue !== undefined ? savingGrowthValues.tenthValue : 0 } displayType="text" prefix="$" />
                                </font>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>15 Years</span>
                                <font>
                                  <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ savingGrowthValues.fifteenthValue !== undefined ? savingGrowthValues.fifteenthValue : 0 } displayType="text" prefix="$" />
                                </font>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {graphView && graphView.series && (
                    <div className={ classes.graphImage }>
                      <HighchartsReact
                        highcharts={ Highcharts }
                        options={ graphView }
                      />
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

SavingsGrowth.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SavingsGrowth );
