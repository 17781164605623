function messages(moduleName, module) {
  var title = '';
  var content = '';
  switch (moduleName) {
    case 'Worksheet': {
      title = 'Go to Worksheet';
      content = 'The Worksheet view allows you to see all your data and make changes to your assumptions all in one place. Before you go to the Worksheet view, save your current scenario. If you leave without saving, your current scenario will be lost.';
      break;
    }
    case 'Home': {
      title = 'Go to Home Page';
      content = 'If you leave this page before saving, your current scenario will be lost. Are you sure you want to leave this page and go to the home page without saving?';
      break;
    }
    case 'Dashboard': {
      title = 'Go to Dashboard';
      content = 'If you leave this page before saving, your current scenario will be lost. Are you sure you want to leave this page and go to the Dashboard without saving?';
      break;
    }
    case 'Career Sketch Timeline': {
      title = 'Go to Career Sketch Timeline';
      content = 'If you leave this page before saving, your current scenario will be lost. Are you sure you want to leave this page and go to the Timeline without saving?';
      break;
    }
    case 'clearScenario': {
      title = 'Clear and start fresh';
      content = 'Are you sure you want to clear your inputs and start fresh?';
      break;
    }
    case 'loadScenario': {
      title = 'Load a scenario';
      content = 'If you load a scenario before saving, your current data will be lost. Do you want to save this scenario before loading a new scenario? Click Save to save your work. Click Continue to load a new scenario.';
      break;
    }
    case 'clearData': {
      title = 'Clear your inputs or work with loaded scenario';
      content = 'Since you already have a scenario loaded for this module, would you like to continue with that scenario? Or, would you like to clear your inputs and start fresh? Click Continue to keep your scenario loaded or Clear to start building a new scenario.';
      break;
    }
    case 'quickSaveInkwiryScenario': {
      title = 'Inkwiry Scenario';
      content = 'The scenario you are trying to save is an Inkwiry Career Sketch. To make this your own scenario, use the save button in the upper right hand corner and save it to your own folder.';
      break;
    }
    case 'clearworksheetScenario': {
      title = 'Clear';
      content = 'Are you sure you want to clear this scenario? Use the clear button if you want to have no scenario loaded in this module. Note, clearing will not delete this scenario and you can always load that scenario again.';
      break;
    }
    case 'No Fi Module': {
      title = 'Financial Independence scenario needed';
      content = 'Before you can complete the ' + module + ', you need to have a Financial Independence (FI) scenario loaded. The Financial Independence module sets the 5, 10, 15 year timeline and other assumptions needed for the' + module + '.';
      break;
    }
    case 'mcsBuildAScenario': {
      title = 'Build a scenario for Stepping Stone';
      content = 'Double click on your first education or career module on the timeline to build a scenario for Stepping Stone #1. Take the first step towards financial freedom.';
      break;
    }
    default: {
      title = 'Go to ' + moduleName;
      content = 'If you leave this page before saving, your current scenario will be lost. Are you sure you want to leave this page and go to the ' + moduleName + ' without saving?';
      break;
    }
  }
  return { title, content }
}
export default messages;
