import TickMark from '../../../assets/img/pwi-fi/tick_mark_blue.png';
import TickMarkTwo from '../../../assets/img/pwi-he/tick_mark.png';
import FilterIcon from '../../../assets/img/pwi-he/filter-edit.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
  },
  fiHeader: {
    position: 'relative',
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiHeaderInner: {
    display: 'flex',
    paddingTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #8fad81',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: '300',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '24px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
       },
      '& > img': {
        marginRight: '25px',
        height: '50px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          height: '30px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },

  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px',
    },
    '@media (max-width: 767px)': {
      overflowX: 'hidden',
    }
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '19px',   
       },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      padding: '0 5px',
      cursor: 'pointer',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& ul': {
      listStyle: 'none',
      '@media (max-width: 767px)': {
        padding: '0px',
      },
      '& li': {
        backgroundImage: `url(${TickMark})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '11px',
        backgroundPosition: 'left top 8px',
        display: 'flex',
        paddingLeft: '35px',
        alignItems: 'center',
        '@media (max-width: 1024px)': {
          flexWrap: 'wrap',
          display: 'inline-block',
          lineHeight: '22px',
        },
        '@media (max-width: 767px)': {
          flexWrap: 'wrap',
          display: 'inline-block',
          lineHeight: '22px',
        },
      },
      '& select': {
        background: '#fbfdfd',
        border: 'none',
        outline: '0',
        borderBottom: '2px solid #337ab7',
        color: '#337ab7',
        textAlign: 'center',
        padding: '0 20px',
        height: '29px',
        cursor: 'pointer',
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-300',
        fontSize: '21px',
        minWidth: '120px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
          padding: '0 10px',
          minWidth: '90px',
        },
      },
    },

    '& i': {
      color: '#1674b1',
      display: 'block',
      '& span': {
        display: 'inline',
        borderBottom: 'none !important'
      }
    },
    '& font': {
      color: '#1674b1',
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '40px',
    paddingBottom: '10px',
    position: 'relative',
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
      '&:last-child': {
        backgroundColor: '#0069aa',
        border: '2px solid #0069aa',
        color: '#fff',
        margin: '0 0 0 auto',
        '&:disabled': {
          color: '#333',
          backgroundColor: '#d5d5d5',
          border: '2px solid #d5d5d5',
          opacity: '0.8',
          cursor: 'no-drop',
          pointerEvents: 'all',
          '&:hover': {
            backgroundColor: '#fff',
          },
        },

      },
    },
  },
  quickSave:{
    position: 'absolute',
    right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    [theme.breakpoints.down( 'xs' )]: {
      right: '85px',
    },
    '&:hover':{
      background: '#0d97ec !important',
    },
    '& img':{
      width:'37px',
      position: 'relative',
      top: '5px',
    }
  },
  selectCollegeBox: {
    marginTop: '40px',
    '& ul': {
      [theme.breakpoints.down( 'sm' )]: {
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',    
       },
       '@media (max-width: 767px)': {
        width: '100%', 
        paddingLeft: '0px',      
      },
      '& li': {
        '& span': {
          position: 'relative',
          top: '0',
          fontFamily: 'MuseoSans-500',
          paddingTop: '10px',
        },
      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
    boxSizing: 'border-box',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '@media (max-width: 767px)': {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 185px)',
    },
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '18px',
      '@media (max-width: 767px)': {
        fontSize: '14px',
        marginTop: '15px',
      },
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        color: 'gray',
        flexWrap: 'wrap',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&+p': {
          fontStyle: 'italic',
          color: '#1674b1',
          padding: '0 0 6px 57px',
          cursor: 'text',
          lineHeight: '20px',
          margin: '0',
        },
        '&:hover': {
          color: '#eca041 !important',
        },
        '& span': {
          marginRight: '10px',
          '@media (min-width: 1400px)': {
            marginRight: '7px',
          },
        },
      },
    },
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& img': {
          width: '3.8vh',
          display: 'block',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  AnnualIncomeGrowth: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '30px',
      marginTop: '30px',
      '& p': {
        margin: '0',
        minWidth: '220px',
        maxWidth: '220px',
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
          cursor: 'pointer',
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },

  },
  middleAnnual: {
    width: 'calc(100% - 380px)',
    marginTop: '30px',
    '& img': {
      marginLeft: '25px',
      width: 'calc(100% - 25px)',
    },
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    marginTop: '20px',
    marginBottom: '30px',
    '& p': {
      margin: '0',
    },
  },
  graphInflection: {
    '& img': {
      maxWidth: '100%',
    },
  },
  dollarIcon: {
    margin: '0 25px 0 auto',
    color: '#666',
    fontSize: '18px',
    '& input': {
      color: '#666',
    },
  },
  cashFlowChart: {
    marginTop: '35px',
    marginBottom: '15px',
  },
  tooltipInfo: {
    width: '200px',
    background: '#0069aa !important',
    opacity: '1 !important',
    color: '#fff !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px',
    fontSize: '14px',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
      borderBottomColor: '#0069aa !important',
    },
  },

  tooltipInfoTopNew: {
    width: '300px',
    background: '#0069aa !important',
    fontSize: '14px',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    marginTop: '-6px !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    }
  },
  tooltipInfoTopSave: {
    marginTop: '-12px !important',
  },
  tooltipInfoTopSmall: {
    padding: '3px 8px !important',
    fontSize: '14px !important',
    marginTop: '-6px !important',
  },
  tooltipInfoLiving: {
    width: '200px',
    background: '#0069aa !important',
    opacity: '1 !important',
    color: '#fff !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    fontSize: '14px !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
  },
  activeTab: {
    position: 'relative',
    top: '1px',
    background: '#fff',
    borderColor: '#000 !important',
  },
  activeMenu: {
    color: '#000 !important',
  },
  myPlanYear: {
    marginTop: '45px',
    marginBottom: '40px',
  },
  AnnualIncomeGrowthMain: {
    marginBottom: '50px',
    '& > div': {
      height: '60px',
      '& p': {
        lineHeight: '27px',
      },
    },
  },
  incomeGrowth: {
    marginTop: '10px',
  },
  annuvalIncomeGrowthGraph: {
    width: '50%',
    margin: '0 15px 0 0',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
    },
  },
  annuvalIncomeGrowthInput: {
    width: '100px',
    margin: '0 25px 0 auto',
    display: 'flex',

    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 220px)',
    overflow: 'auto',
    marginTop: '20px',
    '& ul': {
      padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '10px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  menuBoxNew: {
    background: '#f2f6ed',
    padding: '0 0 2px',
    borderRadius: '15px',
    margin: '5px 0',
    '& h5': {
      background: '#84a84d',
      borderRadius: '15px',
      color: '#fff',
      padding: '5px 10px',
      margin: '0 0 5px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '14px',
    },

  },
  menuBoxNewBg: {
    background: '#e5f0f6',
  },
  fourIconsBlock: {
    '& ul': {
      padding: '0',
      listStyle: 'none',
      width: '52%',
      margin: '20px auto',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'sm' )]: {
        width: '80%',  
        marginLeft: 'auto',
        marginRight: 'auto',            
      },
      '@media (max-width: 767px)': {
        width: '100%', 
        paddingLeft: '0px',      
      },
      '& li': {
        width: 'calc(50% - 56px)',
        display: 'inline-block',
        background: 'transparent',
        padding: '12px 10px 10px',
        textAlign: 'center',
        border: '3px solid #d5d5d5',
        borderRadius: '25px',
        margin: '8px 15px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-500',
        cursor: 'pointer',
        position: 'relative',       
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%', 
          margin:'15px auto',  
          boxSizing: 'border-box',    
          maxWidth: '350px',
          display: 'block',
        },
        '& span + i': {
          width: '30px',
          border: '2px solid green',
          padding: '5px',
          borderRadius: '50%',
          position: 'absolute',
          background: '#d5d5d5',
          color: 'green',
          fontSize: '30px',
          right: '0px',
          top: '0px',
        },
        '& h4': {
          fontSize: '30px',
          fontWeight: 'normal',
          color: '#000',
          margin: '0 0 20px',

        },
        '& h2': {
          color: '#333',
          fontWeight: 'normal',
          fontSize: '25px',
          lineHeight: '30px',
        },
        '&:hover': {
          background: '#d5d5d5',
        },
        '&:first-child': {
          color: '#0062aa',
        },
        '&:nth-child(2)': {
          color: '#0062aa',
        },
        '&:nth-child(3)': {
          color: '#0062aa',
        },
        '&:nth-child(4)': {
          color: '#0062aa',
        },
        '& figure': {
          margin: '0',
        },
      },
    },
  },
  activeEducationType: {
    backgroundColor: '#d5d5d5 !important',
  },
  clearBtn: {
    margin: '0 15px 0px 20px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '&:hover': {
      background: '#0d97ec',
    },
    '& img': {
      maxWidth: '33px',
    },
  },
  responsiveTable: {
    fontSize: '14px',
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
      '& tr': {
        borderBottom: '1px solid #ccc',
        '&:last-child': {
          border: 'none',
        },
        '& td': {
          padding: '5px',
        },
        '& th': {
          padding: '5px',
        },
      },
    },
    '& thead': {
      textAlign: 'left',
    },
  },
  pagination: {
    textAlign: 'center',
    '& ul': {
      listStyle: 'none',
      padding: '0',
      fontSize: '14px',
      '& li': {
        display: 'inline-block',
        background: 'none',
        padding: '0',
        cursor: 'pointer',
        '& a': {
          position: 'relative',
          float: 'left',
          padding: '6px 12px',
          marginLeft: '-1px',
          lineHeight: '1.42857143',
          color: '#337ab7',
          textDecoration: 'none',
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          '&:hover': {
            textDecoration: 'underline',
            color: '#337ab7',
          },
        },
      },
    },
  },
  activePagination: {
    '& a': {
      background: '#337ab7 !important',
      color: '#fff !important',
      border: '1px solid #337ab7 !important',
    },

  },
  bottomText: {
    color: '#0069aa',
    fontSize: '16px',
    borderTop: '1px solid #ccc',
    paddingTop: '15px',
    paddingBottom: '25px',
    '& p': {
      margin: '0 0 10px',
      lineHeight: '1.42857143',
      fontSize: '15.9px',
    },
    '& a': {
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
        textDecoration: 'underline',
      },
    },
  },
  searchBlock: {
    fontSize: '14px',
    '& font': {
      fontFamily: 'MuseoSans-700',
      paddingRight: '15px',
    },
    '& input': {
      outline: '0',
      borderRadius: '25px',
      border: '1px solid #d5d5d5 !important',
      borderRight: 'none',
      padding: '0 15px',
      marginLeft: '10px',
      fontSize: '16px',
      height: '35px',
      width: '50%',
    },
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    '& i': {
      position: 'relative',
      left: '-30px',
      color: '#000',
    },
  },
  // responsiveTableNew: {
  //   fontSize: '16px',
  //   '@media (max-width: 767px)': {
  //     overflowX: 'hidden',
  //   },
  //   '& table': {
  //     width: '100%',
  //     border: 'none',
  //     '& tr:hover': {
  //       background: '#ddebf7',
  //     },
  //     '& tr:first-child': {
  //       '& td': {
  //         borderTop: 'none',
  //       },
  //     },
  //     '& td': {
  //       verticalAlign: 'middle',
  //       padding: '8px',
  //       borderTop: '1px solid #ddd',
  //       lineHeight: '1.42857143',
  //       color: '#000',
  //       fontSize: '14px',

  //       '& i': {
  //         display: 'inline-block',
  //         margin: ' 0 auto',
  //       },
  //       '& img': {
  //         display: 'flex',
  //         margin: ' 0 auto',
  //         cursor: 'pointer',
  //         maxWidth: '20px',
  //       },
  //       '&:first-child': {
  //         width: '30px',
  //         textAlign: 'center',
  //         minWidth: '30px',
  //       },
  //       '&:nth-child(2)': {
  //         width: '36%',

  //       },
  //     },
  //   },
  //   '& tr:nth-child(even)': {
  //     background: '#f2f2f2',
  //   },
  // },
  responsiveTableNew: {
    fontSize: '16px',
    '@media (max-width: 959px)': {
      overflowX: 'auto',
    },
    '& table': {
      width: '100%',
      border: 'none',
      '& tr:hover': {
        background: '#ddebf7',
      },
      '& td': {
        verticalAlign: 'middle',
        padding: '8px',
        borderTop: '1px solid #ddd',
        lineHeight: '1.42857143',
        color: '#333',
        fontSize: '14px',
        '& i': {
          display: 'inline-block',
          margin: ' 0 auto',
        },
        '& img': {
          display: 'flex',
          margin: ' 0 auto',
          cursor: 'pointer',
          maxWidth: '20px',
        },
        '&:first-child': {
          width: '30px',
          textAlign: 'center',
          minWidth: '30px',
        },
        '&:nth-child(2)': {
          width: '34%',
        },
        '&:nth-child(3)': {
          width: '25%',
        },
        '&:nth-child(4)': {
          width: '16%',
          background: '#d9e1f2',
          borderLeft: '2px solid #000',
          borderRight: '2px solid #000',
          borderColor: '#000',
        },
        '@media (max-width: 959px)': {
          minWidth: '140px',
        }
      },
      '& tr':{
        '&:first-child': {
          '& td': {
            borderTop: 'none',
            '&:nth-child(3)':{
              fontSize: '16px',
            },
            '&:nth-child(4)':{
              fontSize: '16px',
              background: '0 0',
              border: '0px solid #000',
            },
          },
        },
        '&:nth-child(2)':{
          '& td':{
            '&:nth-child(4)':{
              borderTop: '2px solid #000',
            }
          }
        },
        '&:last-child':{
          '& td':{
            '&:nth-child(4)':{
              borderBottom: '2px solid #000',
            }
          }
        },
      }
    },
    '& tr:nth-child(even)': {
      background: '#f2f2f2',
    },
  },
  amountDollar: {
    display: 'flex',
    alignItems: 'center',
    width: '95px',
    margin: '0 0 0 auto',
    justifyContent: 'flex-end',
    '& > div': {
      '& > div': {
        '&::before': {
          borderBottom: '1px solid #0069aa',
        },
        '&::after': {
          borderBottom: '2px solid #f89b22',
        },
        '&:hover': {
          '&::after': {
            borderBottom: '2px solid #f89b22',
          },
          '&::before': {
            borderBottom: '2px solid #f89b22 !important',
          },
        },
      },
    },
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '20px',
      display: 'inline-block',
      width: '95px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
      padding: '0px 0px 2px 5px',
    },
  },
  higherEducationStepFour: {
    '& table': {
      width: '100%',
      maxWidth: '580px',
      margin: '70px auto 20px',
      fontSize: '20px',
      borderCollapse: 'collapse',
      '@media (max-width: 767px)': { 
        fontSize: '16px',
        margin: '20px auto 20px',
      },
      '& tr': {
        backgroundColor: '#f2f2f2',
        borderBottom: '1px solid #fff',
      },
      '& td': {
        padding: '8px',
        lineHeight: '25px',
        fontSize: '20px',
        '@media (max-width: 767px)': { 
          fontSize: '16px',
          margin: '20px auto 20px',
        },
        '& p': {
          display: 'inline-block',
          margin: '0',
          lineHeight: '18px',
          cursor: 'pointer',
        },
      },
    },
  },
  trHead: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '3px solid #fff  !important',
    fontFamily: 'MuseoSans-500',
  },

  amountDollarRight: {
    textAlign: 'right',
  },
  grayBoxHigher: {
    maxWidth: '80%',
    '& p': {
      fontSize: '20px',
    },
    marginTop: '25px',
    '& li': {
      marginBottom: '20px',
      '& img': {
        display: 'inline',
        marginLeft: '10px',
      },
    },
  },
  InfoIconNew: {
    display: 'flex',
    marginRight: '0 !important',
    maxWidth: '20px',
    cursor: 'pointer',
  },
  amountDollarNew: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '85px',
    margin: '0 0 0 auto',
    '& > div': {
      '& > div': {
        '&::before': {
          borderBottom: '1px solid #0069aa',
        },
        '&::after': {
          borderBottom: '2px solid #f89b22',
        },
        '&:hover': {
          '&::after': {
            borderBottom: '2px solid #f89b22',
          },
          '&::before': {
            borderBottom: '2px solid #f89b22 !important',
          },
        },
      },
    },
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '16px',
      display: 'inline-block',
      width: '85px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
    },
  },
  higherEducationStepSix: {
    '& table': {
      maxWidth: '625px',
      color: '#333',
      '& thead': {
        background: '#ccc',
        backgroundColor: '#dae3f3 !important',
        borderBottom: '3px solid #fff  !important',
        fontFamily: 'MuseoSans-700',

        '& th': {
          padding: '7px 8px',
          textAlign: 'left',
          fontWeight: 'normal',
          fontSize: '20px',
          position: 'relative',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            lineHeight: '22px',
          },
          '& img': {
            marginLeft: '5px',
          },
        },
      },
      '& tr': {
        borderBottom: '2px solid #fff',
      },
      '& td': {
        fontSize: '16px',
        padding: '5px 8px',
        lineHeight: '1.42857143',
        '&:last-child': {
          textAlign: 'right',
        },
        '& font': {
          display: 'inline',
          cursor: 'pointer',
          borderBottom: '1px dashed #0069aa',
          color: '#333',
          lineHeight: '20px',
        },
        '&:first-child': {
          width: '20px',
          maxWidth: '20px',
        },
      },
    },
  },

  trFootNew: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '2px solid #5686da !important',
    fontFamily: 'MuseoSans-500',
    '& td': {
      padding: '9px 10px !important',
    },
  },
  trFootNewWhite: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '2px solid #fff !important',
    fontFamily: 'MuseoSans-500',
    '& td': {
      padding: '9px 10px !important',
    },
  },
  trFootNewBorder: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '2px solid #fff !important',
    fontFamily: 'MuseoSans-700',
  },
  graphRight: {
    padding: ' 0 50px',
  },
  inputGroup: {
    display: 'flex',
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },

      },
    },
    '& input': {
      height: '34px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '5px',
      marginLeft: '5px',
      minWidth: '160px',
      maxWidth: '160px',
      padding: '0 10px',
      fontSize: '21px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      '&:focus': {
        borderColor: ' #66afe9',
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
    },
  },
  inputGroupMain: {
    marginTop: '25px',
    marginBottom: '25px',
    '& h4': {
      margin: '8px 0',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',

    },
  },
  paddingLeft: {
    paddingLeft: '50px',
  },
  giftSources: {
    margin: '30px 0',
    '@media (max-width: 767px)': {
      overflow: 'auto',
    },
    '& table': {
      width: '100%',
      maxWidth: '710px',
      margin: '0 auto',
      color: '#333',
      borderCollapse: 'collapse',
      '& thead': {
        background: ' #dae3f3',
        textALlign: 'center',
        borderBottom: '3px solid #fff',
        '& th': {
          padding: '8px',
          lineHeight: '25px',
          minWidth: '210px',
          fontSize: '1.42857143',
          color: '#333',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          }
        },
      },
      '& tr': {
        background: '#f2f2f2',
        borderTop: '2px solid #fff',
        '& td': {
          padding: '8px',
          textAlign: 'center',
          lineHeight: '1.42857143',
          fontSize: '20px',
          fontWeight: '300',
          '@media (max-width: 767px)': {
            fontSize: '16px',
          }
        },

      },
    },
  },
  newFlex: {
    display: 'flex',
  },
  innnerFlowGroup: {
    display: 'flex',
    position: 'relative',
    paddingBottom: '10px',
    alignItems: 'center',
  },
  garphLeft: {
    width: '60%',
    '& h3': {
      marginBottom: '0',
    },

  },
  annuvalIncomeGrowthGraphNew: {
    marginTop: '20px',
    '& img': {
      width: '100%',
      padding: '0 25px',
    },
  },
  garphRight: {
    display: 'flex',
    margin: '0 0 0 auto',
    width: '40%',
    paddingLeft: '0',
    '& h3': {
      marginBottom: '20px',
      marginTop: '0',
      fontSize: '18px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      whiteSpace: 'nowrap',
    },
    '& > div': {
      width: '50%',
      margin: '0 25px',
      position: 'relative',
    },
  },
  annuvalGrowthNew: {
    '& > div': {
      margin: '0',
    },
  },
  inputRightBlock: {
    display: 'flex',
    fontSize: '18px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 10px',
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
      textAlign: 'right',
      '&:focus': {
        borderBottom: '1px solid #f89b22',
      },
    },
  },
  innnerFlowGroupMainFull:{
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    }
  },
  innnerFlowGroupMain: {
    position: 'relative',
    paddingBottom: '0',
    '@media (max-width: 1199px)': { 
      minWidth: '950px',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '176px',
    },
  },
  innnerFlowGroupMainFamily: {
    '&::before': {
      right: '136px',
    },
  },
  borderDevider: {
    width: '100%',
    height: '3px',
    backgroundImage: 'linear-gradient(to right,#7f7f7f 50%,transparent 50%)',
    backgroundSize: '30px 100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  totalBoxTable: {
    fontSize: '20px',
    '& > div':{
      '@media (max-width: 550px)': { 
        display: 'block',
      },
      '& div':{
        '&:first-child':{
          [theme.breakpoints.down( 'xs' )]: {
            width: '100%',
            marginBottom: '20px',
          }
        },
        '&:last-child':{
          [theme.breakpoints.down( 'xs' )]: {
            width: '100%',
          }
        },
      }
    },
    '& table': {
      textAlign: 'left',
      '& tr': {
        '& td': {
          textAlign: 'left',
          '& span': {
            border: 'none !important',
          },
          '&:first-child': {
            width: '10px',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },

    },
  },
  gridSpacingImages: {
    '& > div': {
      '& > div': {
        margin: '0 15px',
      },
    },
  },
  orangeBG: {
    background: '#f89b22 !important',
    color: '#fff',
    fontFamily: 'MuseoSans-500',
    textAlign: 'center !important',
    fontSize: '17px !important',
    lineHeight: '28px !important',
  },
  fourIconsBlockNew: {
    '& ul': {
      width: '77%',
      marginBottom: '100px',
    },
  },
  // fourIconsBlockNew: {
  //   '& ul': {
  //     width: '77%',
  //     marginBottom: '100px',
  //     display: 'flex',
  //     '& li':{
  //       borderRadius: '46px',
  //       padding: '24px 24px 40px',
  //       margin: '0px 20px',
  //       '& h2':{
  //         marginTop: '0px',
  //         fontWeight: '400',
  //       },
  //       '& h4':{
  //         fontWeight: '300',
  //         paddingTop: '10px',
  //         margin: '20px 0 10px',
  //       }
  //     }
  //   },
  // },
  careerSketchTimeline: {
    textAlign: 'center',
    padding: '0 80px',
    '& h3': {
      textAlign: 'left',
      color: '#0069aa',
      fontWeight: 'normal',
      fontSize: '26px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        marginLeft: '5px',
        cursor: 'pointer',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      marginBottom: '60px',
      '& li': {
        background: 'transparent !important',
        padding: '0 !important',
        display: 'inline-block !important',
        margin: '2px',
        cursor: 'pointer',
        '& img': {
          maxWidth: '45px',
        },
      },
    },
  },
  smallText: {
    fontSize: '14px',
    paddingLeft: '20px',
    marginBottom: '5px !important',
  },
  textFieldSearch: {
    fontSize: '14px',
    fontFamily: 'MuseoSans-700',
    paddingLeft: '20px',
    '@media (max-width: 529px)': {
      padding: '15px 0 0 0px',
    },
    '& span': {
      display: 'inline-block',
    },
  },
  searchTextField: {
    width: '70%',
    marginLeft: '15px',
    '@media (max-width: 529px)': {
      width: '100%',
      marginLeft: '0px',
    },
    '&::before': {
      content: '"\\f002"',
      display: 'inline-block',
      font: 'normal normal normal 14px/1 FontAwesome',
      fontSize: 'inherit',
      textRendering: 'auto',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      position: 'absolute',
      right: '10px',
      top: '8px',
      color: '#4a87c0',
    },
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      border: '1px solid #ccc',
      borderRadius: '15px',
      padding: '7px 15px',
    },
  },
  ChooseSchoolTable: {
    '@media (max-width: 767px)': {
      overflow: 'auto',
    },
    '& table': {
      border: 'none',
      width: '100%',
      fontSize: '14px',
      marginTop: '15px',
      // tableLayout: 'fixed',
      borderCollapse: 'collapse',
      '& tr': {
        borderBottom: '1px solid #ccc',
        cursor: 'pointer',
        '&:first-child': {
          cursor: 'inherit',
          '&:hover': {
            background: 'none',
          },
        },
        '&:hover': {
          background: '#f2f2f2',
        },
      },
      '& tr:first-child': {
        fontFamily: 'MuseoSans-700',
      },
      '& td': {
        verticalAlign: 'middle',
        color: '#000',
        padding: '0px 8px',
        lineHeight: '1.2',
        height: '38px',
        '&:first-child': {
          maxWidth: '220px',
        },
        '@media (max-width: 991px)': {
          minWidth: '150px',
        },
        '& span':{
          display: 'inline-block',
          position: 'relative',
          textAlign: 'center',
          width: '25px',
          '& ul': {
            position: 'absolute',
            padding: '0',
            background: '#e9e6e6',
            left: '-46px',
            top: '20px',
            zIndex: '9',
            '&::before': {
              content: "''",
              position: 'absolute',
              borderLeft: '9px solid transparent',
              borderRight: '9px solid transparent',
              width: '0',
              background: '0 0',
              borderBottom: '9px solid #e9e6e6',
              borderTop: '10px solid transparent',
              height: 'auto',
              left: '50%',
              top: '-19px',
              transform: 'translateX(-50%)',
            },
            '& li': {
              background: 'none',
              color: '#333',
              padding: '4px 20px',
              fontSize: '14px',
              lineHeight: '1.4',
              cursor: 'pointer',
              fontFamily: 'MuseoSans-500',
              margin: '0',
              width: '80px',
              '&:hover': {
                color: '#fff',
                background: '#0169aa',
              },
            },
          },
        },
        '& i': {
          display: 'inline-block',
          color: 'transparent',
          backgroundImage: `url(${FilterIcon})`,
          cursor: 'pointer',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right 0px top 0px',
          fontSize: '20px',
          position: 'relative',
          top: '4px',
        },
      },
    },
  },
  adminDrop:{
    left: '-65px !important',
    minWidth: '160px',
    '& li':{
      width: 'auto !important',
    }
  },
  TextFieldGrooup: {
    display: 'flex',
    marginTop: '10px',
    alignItems: 'center',
    paddingLeft: '20px',
    '& > div > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& input': {
        borderBottom: '1px solid #337ab7',
        color: '#337ab7',
        fontSize: '20px',
        padding: '6px 0 6px',
        marginLeft: '5px',
        '&:focus': {
          border: '1px solid #0069aa !important',
        },
      },
    },
  },
  TextFieldGrooupSelect: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingLeft: '20px',
    '& > div': {
      borderBottom: '1px solid #337ab7',
      width: 'auto',
      minWidth: '100px',
      fontSize: '20px',
      color: '#337ab7',
      marginLeft: '5px',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  tabButtons: {
    paddingTop: '50px',
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      marginRight: '10px',
      fontSize: '16px',
      color: '#000',
      textTransform: 'inherit',
      minHeight: 'inherit',
      minWidth: '170px',
      '& span': {
        border: 'none !important',
      },
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      '@media (max-width: 767px)': {
        overflowX: 'auto',
        overflowY: 'hidden',
      }
    },
    '& > div > div + span': {
      display: 'none',
    },

  },
  tabsContent: {
    '& img': {
      maxWidth: '100%',
    },
  },
  inputSelectBoxNew: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    marginTop: '50px',
    marginBottom: '20px',
    // '@media (max-width: 767px)': {
    //   overflowX: 'hidden',
    // },
    '& > div > div': {
      width: '100%',
    },
    '& > div': {
      margin: '0 25px',
      width: '25%',
      overflow: 'hidden',
      '&:nth-child(2)':{
        marginLeft: '100px',
        '@media (max-width: 1023px)': { 
          marginLeft: '0px',
        }
      },
      '@media (max-width: 479px)': { 
        width: '50%',
        margin: '0 5px',
      },
      '@media (min-width: 480px) and (max-width: 767px)': { 
        width: '35%',
        margin: '0 5px',
      },
      '@media (max-width: 1023px)': { 
        position: 'relative',
        overflow: 'initial',
      },
      '& > div > div::before': {
        display: 'none',
      },
      '& > div > div::after': {
        display: 'none',
      },
      '& input': {
        width: '100%',
        height: '34px',
        padding: '0 15px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
        WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
        OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
        '&::placeholder': {
          opacity: '1',
        },
        '&:focus': {
          borderColor: '#66afe9',
          outline: '0',
          WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
          boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        },
      },
      '& > div': {
        '&::before': {
          display: 'none',
        },
        '&::after': {
          display: 'none',
        },
      },
    },
  },
  searchList: {
    border: '1px solid #ccc',
    display: 'block',
    position: 'absolute',
    fontSize: '14px',
    maxWidth: '269px',
    background: '#fff',
    top: '40px',
    padding: '0',
    maxHeight: '300px',
    overflow: 'auto',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12)',
    zIndex: '9',
    width: '100%',
    '& ul': {
      padding: '0',
      margin: '0',
      '& li': {
        padding: '3px 15px',
        background: 'none',
        lineHeight: '21px',
        cursor: 'pointer',
        borderBottom: '1px solid #f2f2f2',
        marginBottom: '0',
        display: 'block',
        '&:hover': {
          background: '#f2f2f2',
        },
      },
    },
  },
  yearOfGradutionList: {
    '& li': {
      marginBottom: '25px',
      '& select + span': {
        paddingLeft: '0',
      },
      '& img': {
        marginLeft: '8px',
        cursor: 'pointer',
        maxWidth: '20px',
      },
    },
  },
  tutionFeesTable: {
    '& td': {
      '& p': {
        borderBottom: '1px dashed #0069aa',
        paddingBottom: '3px',
      },
    },
  },
  grayBoxYearGradu: {
    width: '80%',
    '& ul': {
      padding: '30px 0px 0px',
      marginTop: '0',
      '& li': {
        paddingBottom: '30px',
        marginBottom: '0',
        '@media (max-width: 767px)': {
          display: 'inline-block',
          flexWrap: 'wrap',
          lineHeight: '22px',
        }
      },
    },
  },
  heigherEducationSearchGap: {
    margin: '0 -30px',
    width: 'calc(100% + 60px)',
    justifyContent: 'center',
    flexWrap: 'inherit',
    '@media (max-width: 599px)': {
      display: 'block',
    },
    '& > div': {
      '& > div': {
        padding: '0 30px',
      },
    },
  },
  accorodianTotalCost: {
    display: 'block',
    margin: '0 !important',
    background: '#f2f2f2',
    borderBottom: '2px solid #fffffff5',
    boxShadow: 'none',
    '&::before': {
      display: 'none',
    },
    '& p': {
      margin: '0',
      fontSize: '20px',
      display: 'flex',
      color: '#333',
      paddingLeft: '25px',
      // lineHeight: '0',
      lineHeight: '23px',
      '@media (max-width: 767px)': {
        lineHeight: '20px',
        fontSize: '16px',
      },
      '& span': {
        margin: '0 0 0 auto',
        '@media (min-width: 600px) and (max-width: 767px)': {
          marginLeft: '15px',
          minWidth: '80px',
          textAlign: 'right',
        }
      },
      '& font': {
        color: '#000',
      },
    },
  },
  totalCotBox: {
    display: 'block',
    padding: '0',
    '& > div': {
      display: 'flex',
      background: '#fce4d6',
      borderBottom: '2px solid #fff',
      padding: '5px 18px 5px 43px',
      '& span': {
        margin: '0 0 0 auto',
      },
    },
  },
  annuvalGrowthScholor: {
    '& > div': {
      '& p': {
        maxWidth: '150px',
        minWidth: '150px',
        lineHeight: '26px',
      },
      '& span': {
        // border: 'none !important',
      },
    },
  },
  annuvalGrowthScholorScolorAndGrants: {
    '& > div': {
      alignItems: 'inherit',
      '& p': {
        maxWidth: '150px',
        minWidth: '150px',
        lineHeight: '26px',
      },
      '& span': {
        // border: 'none !important',
      },
    },
  },
  annuvalIncomeGrowthGraphScholor: {
    width: '100%',
    '& span': {
      color: '#1672b4',
      minHeight: '5px',

    },
  },
  innnerFlowGroupScholor: {
    marginBottom: '25px',
  },
  garphRightScholor: {
    paddingLeft: '0',
  },
  garphLeftScholor: {
    width: '77%',
  },
  trFoot: {
    background: '#ccc',
    // cursor: 'pointer',
    backgroundColor: '#dae3f3 !important',
    borderTop: '2px solid #5686da !important',
    fontFamily: 'MuseoSans-500',
    '& td': {
      padding: '8px !important',
      lineHeight: '1.42857143',
      fontFamily: 'MuseoSans-500',
    },
  },
  forStufentLonas: {
    '& a': {
      padding: '0 5px',
      cursor: 'pointer',
    },
    '& span': {
      borderBottom: '1px dashed #0069aa!important',
      marginLeft: '5px',
      marginRight: '5px',
      cursor: 'pointer',
      display: 'inline',
    },

    '& ul': {
      padding: '0',
      marginTop: '10px',
      '& li': {
        fontSize: '19px',
        lineHeight: '1.42857143',
        marginBottom: '8px',
        backgroundImage: `url(${TickMarkTwo})`,
      },
    },
  },
  headStudentLoan: {
    marginBottom: '0 !important',
    '& span': {
      borderBottom: '1px dashed #0069aa!important',
      marginLeft: '0',

      display: 'inline-block',
      fontSize: '19px',
      fontFamily: 'MuseoSans-500',
    },
  },
  totalCostImage: {
    '& img': {
      display: 'inline-block',
      verticalAlign: 'middle',
      cursor: 'pointer',
      maxWidth: '20px',
    },
  },
  TextFieldGrooupSpan: {
    '& span': {
      minWidth: '180px',
      fontSize: '20px',
    },
    '& > div': {
      width: '100%',
    },
    '& input': {
      color: '#555 !important',
      padding: '6px 13px 6px !important',
      fontSize: '14px !important',
      marginLeft: '5px',
      border: '1px solid #ccc !important',
      lineHeight: '20px',
      borderRadius: '5px',
      width: '100%',
      '&::placeholder': {
        opacity: '1',
        color: '#555 ',
      },
    },
  },
  TextFieldGrooupSpanSelect: {
    display: 'flex',
    '& > div > div': {
      maxWidth: '200px',
      padding: '0',

    },
    '& span': {
      minWidth: '180px',
      fontSize: '20px',
    },
    '& > div': {
      width: '100%',
      border: '1px solid #ccc',
      padding: '0',
      borderRadius: '5px',
      '& > div': {
        minWidth: '100%',
        fontSize: '14px',
        paddingLeft: '12px',
        color: '#000',
      },
    },
  },
  tableBox: {
    display: 'table',
    width: '100%',
    margin: '60px auto',
    '& table': {
      width: '100%',
      maxWidth: '550px',
      margin: '0 auto',
      fontSize: '20px',
      borderCollapse: 'collapse',
      '@media (max-width: 767px)': {
        fontSize: '16px',
      },
      '& th': {
        backgroundColor: '#dae3f3',
        borderBottom: '3px solid #fff',
        fontSize: '20px',
        textAlign: 'center',
        fontWeight: '500',
        padding: '8px',
        fontFamily: 'MuseoSans-500',
        lineHeight: '1.42857143',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
      },
      '& tr': {
        backgroundColor: '#f2f2f2',
        borderTop: '1px solid #fff',
        '& td': {
          padding: '8px',
          '& input': {
            width: '95px',
          },
          '&:last-child': {
            textAlign: 'right',
            '& > div > div': {
              '&:hover': {
                '&:before': {
                  borderColor: '#5686da',
                },
              },
              '&:before': {
                borderColor: '#5686da',
              },
              '&:after': {
                borderColor: '#f89b22',
              },

            },
          },
        },
      },
    },
  },
  chooseYourSchoolBox: {
    maxWidth: '480px',
  },
  trFootChoose: {
    backgroundColor: '#dae3f3 !important',
    borderTop: ' 2px solid #5686da !important',
  },
  noData: {
    textAlign: 'center',
    padding: '30px',
    fontFamily: 'MuseoSans-500',
    borderBottom: 'none !important',
    '& td': {
      height: '70px !important',
    },
  },
  compareSchoolSideBySide: {
    '& td': {
      '&:first-child': {
        minWidth: '50px',
      },
      '&:nth-child(4)': {
        width: '28%',
      },
    },
  },
  TotalCostAttendence: {
    marginTop: '70px',
    '& > div':{
      '@media (max-width: 599px)': {
        display: 'block',
      }
    },
    '& table': {
      margin: '0',
      '& thead': {
        fontFamily: 'MuseoSans-500',
      },
      '& i': {
        fontSize: '20px',
      },
    },
  },
  totalCostAttendenceNew: {
    display: 'block',
    background: ' #dae3f3',
    padding: '8px',
    fontSize: '20px',
    color: '#333',
    borderTop: '2px solid #5b9bd5',
    '& span': {
      float: 'right',
    },
  },
  totalCostAttendenceNewLeftSpace: {
    fontFamily: 'MuseoSans-500',
    paddingLeft: '40px',
    '@media (max-width: 767px)': {
      display: 'flex',
      position: 'relative',
      paddingRight: '110px',
      lineHeight: '20px',
    },
    '& span':{
      '@media (max-width: 767px)': {
        position: 'absolute',
        right: '20px',
      }
    }
  },
  totalCostAttendenceFlex:{
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalBoldText: {
    fontFamily: 'MuseoSans-500',
  },
  listActive: {
    backgroundColor: '#d5d5d5',
  },
  tooltipInfoTextWalk: {
    width: '180px !important',
  },
  moreGap: {
    paddingTop: '15px',
  },
  navigatorHover: {
    '&:hover': {
      color: '#f89b23 !important',
    },
  },
  iconsTextLeft: {
    '& > div': {
      '& + div': {
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
        margin: 'auto',
      },
    },
  },
  annuvalGrowthNewFamily: {
    '& > div': {
      '& p': {
        margin: '0',
        minWidth: '150px',
        maxWidth: '150px',
        fontSize: '20px',
        lineHeight: '26px',
      },
    },
  },
  garphLeftFamily: {
    width: '70%',
  },
  graphRightFamily: {
    width: '30%',
    '& > div': {
      margin: '0 25px',
      '&:first-child': {
        width: '40%',
      },
      '&:last-child': {
        marginRight: '0',
        marginLeft: '10px',
      },
    },
    '& h3': {
      fontSize: '18px',
    },
  },
  textRight: {
    '& p': {
      width: '100%',
      textAlign: 'right',
      maxWidth: '100% !important',
    },
  },
  inputRightBlockBorder: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '88px',
      height: '3px',
      background: 'linear-gradient(to bottom,#7db9e8 0,#f07521 0,#a14c13 100%)',
      right: '0',
      margin: 'auto',
      top: '-8px',
    },
  },
  flowGroupTopGap: {
    marginTop: '30px',
  },
  totalCostNew: {
    display: 'flex',
    fontSize: '18px',
    fontFamily: 'MuseoSans-500',
    '& span:first-child': {
      width: '70%',
      textAlign: 'right',
    },
    '& span:last-child': {
      margin: '0 0 0 auto',
      paddingRight: '7px',
    },
  },
  giftSourcesNewHalf: {
    '& th': {
      textAlign: 'left',
    },

  },
  flexContainer: {
    display: 'flex',
  },
  innnerFlowGroupMainWithoutBorder: {
    '&::before': {
      display: 'none',
    },
    border: 'none',
  },
  garphRightFamily: {
    width: '24%',
    justifyContent: 'flex-end',
    '& > div > div > div > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& img': {
      marginLeft: '8px',
    },
    '& input': {
      marginRight: '8px',
      minWidth: '80px',
    },
  },
  flexContainerNew: {
    position: 'absolute',
    display: 'flex',
    right: '29px',
  },
  loanAmountTable: {
    margin: '50px 0',
    padding: '0 40px',
    '& table': {
      width: '100%',
      maxWidth: '920px',
      margin: '0 auto',
      color: '#000',
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      '& thead': {
        background: ' #dae3f3',
        textALlign: 'center',
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          minWidth: '210px',
          fontSize: '20px',
          fontWeight: 'normal',
          color: '#333',
          fontFamily: 'MuseoSans-500',
          '&:last-child': {
            width: '240px',
          },
        },
      },
      '& tr': {
        background: '#f2f2f2',
        '& td': {
          padding: '8px',
          textAlign: 'center',
          borderBottom: '2px solid #fff ',
          fontSize: '20px',
          fontWeight: 'normal',
          lineHeight: '24px',
          color: '#333',
        },

      },
    },
  },
  BgWhite: {
    background: '#fff !important',
  },
  BgGray: {
    background: '#fbe5d6 !important',
    '& td': {
      borderBottom: '2px solid #5686da !important',
    },
  },
  tooltipInfoTopNewText: {
    width: 'auto',
    maxWidth: '200px',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px',
    textAlign: 'left',
    background: '#0069aa !important',
    fontSize: '14px',
    opacity: '1',
    boxSizing: 'border-box',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
  },
  tooltipInfoTopNewText2: {
    width: '180px',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'left',
    background: '#0069aa !important',
    fontSize: '14px !important',
    opacity: '1',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
  },
  topselectSchoolManuval: {
    marginBottom: '25px',
  },
  topGapNew: {
    paddingTop: '20px',
  },
  totalCostImageLeftGap: {
    paddingLeft: '0 !important',
    textAlign: 'center !important',
  },
  financialAid: {
    borderBottom: '1px dashed #0069aa!important',
    display: 'inline !important',
    cursor: 'pointer',
  },
  tagPaddingZero: {
    padding: '0 !important',
  },
  topBottomGap: {
    padding: '20px 0 15px',
    display: 'block',
  },
  italicText: {
    fontStyle: 'italic',
    paddingBottom: '15px',
  },
  rightBoxNogap: {
    marginRight: '0 !important',
  },
  garphRightScholorNewP: {},
  TopGapNew: {
    paddingTop: '15px',
  },
  giftSourcesNew: {
    '& th': {
      verticalAlign: 'bottom',
      fontSize: '20px',
      color: '#333',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-700',
    },
  },
  innnerFlowGroupMaingrantsScroll: {
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  innnerFlowGroupMaingrants: {
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    },
    '&::before': {
      right: '150px',
    },
  },
  borderBottomLine: {
    borderBottom: '1px dashed #0069aa !important',
  },
  annuvalGrowthScholorNewPrivate: {
    '& span': {
      display: 'inherit',
      border: 'none !important',
    },
    '& p': {
      minWidth: '220px !important',
      maxWidth: '220px !important',
      '& span': {
        borderBottom: '1px dashed #0069aa !important',
      },
    },
  },
  annuvalGrowthScholorNewPrivateFederal: {
    '& p': {
      minWidth: '180px !important',
      maxWidth: '180px !important',
    },
  },
  innnerFlowGroupMainWithoutBorderNew: {
    '& > div': {
      marginBottom: '25px',
    },

  },
  flexContainerDollat: {
    position: 'absolute',
    display: 'flex',
    right: '-48px',

  },
  flexContainerDollatNew: {
    position: 'relative',
    display: 'flex',
    right: '-23px',
    width: '30px',
    justifyContent: 'flex-end',
  },
  innnerFlowGroupMainWithoutBorderNewGap: {
    marginTop: '80px',
  },
  innnerFlowGroupMainWithoutBorderNewGapTwenty: {
    marginTop: '30px',
  },
  floatRightBpx: {
    position: 'absolute',
    right: '95px',
    top: '-65px',
  },
  totalCostNewFlex: {
    display: 'flex',
    fontSize: '18px',
    '& span': {
      border: 'none !important',
      fontFamily: 'MuseoSans-500',
    },
    '& span:first-child': {
      width: '77%',
      textAlign: 'right',

    },
    '& span:last-child': {
      margin: '0 0 0 auto',
      paddingRight: '30px',
    },
  },
  flexNewFederal: {
    '& span:first-child': {
      width: '70%',
    },
    '& span:last-child': {
      margin: '0',
      minWidth: '125px',
      textAlign: 'right',
      paddingRight: '0px',
      width: '12%',
    },
  },
  DefinationPopup: {
    lineHeight: '20px',
    padding: '5px',
  },
  maxWidthBox: {
    marginRight: '0 !important',
    marginLeft: '0 !important',
  },
  maxWidthBoxNew: {
    minWidth: '141px',
  },
  borderGapFlexGroup: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '136px',
    },
  },
  annuvalGrowthScholorFederal: {
    '& p': {
      margin: '0 0 0 auto !important',
      textAlign: 'right',
    },
  },
  inputRightBlockGap: {
    width: '80%',
  },
  inputRightBlockFlex: {
    width: '100%',
    maxWidth: '110px',
    minWidth: '110px',
    '& img': {
      marginLeft: '8px',
    },
  },
  marginTopZero: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  marginTopMore: {
    marginTop: '35px !important',
  },
  definationTittle: {
    borderBottom: '1px dashed #0069aa',
    display: 'inline-block !important',
    cursor: 'pointer',
    margin: '0',
    lineHeight: '25px',
  },
  firstYearTutuin: {
    fontFamily: 'MuseoSans-300',
  },
  unBoldText: {
    '& td': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-300',
    },
  },
  graphBoxNew: {
    border: '1px solid #2f75b5',
    marginTop: '15px',
    '& h3': {
      textAlign: 'center',
      fontSize: '23px',
      color: '#333',
      fontWeight: 'normal',
      marginTop: '0',
      borderBottom: '2px solid #2f75b5',
      paddingBottom: '10px',
    },
    '& h4': {
      border: '1px solid #2f75b5',
      margin: '13px auto 0',
      padding: '10px',
      color: '#000',
      width: '575px',
      fontWeight: '700',
      fontSize: '22px',
      textAlign: 'center',
      fontFamily: 'MuseoSans-500',
      position: 'relative',
      top: '20px',
    },
  },
  graphBoxNewGraph: {
    padding: '20px',
    '& h4': {
      top: '0px',
      '& span': {
        display: 'inline'
      }
    }
  },
  marginBottomZero: {
    marginBottom: '0 !important',
  },
  gridSpacingImagesNew: {
    margin: '0 -26px',
    width: 'calc(100% + 50px)',
  },
  giftSourcesRepayment: {
    '& table': {
      '& thead': {
        '& th': {
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
          lineHeight: '28px',
        },
      },
      '& tr': {
        '& td': {
          textAlign: 'left',
          '& i': {
            color: '#333',
          },
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
      '& tfoot': {
        '& th': {
          textAlign: 'left',
          backgroundColor: '#dae3f3',
          borderTop: '2px solid #5686da',
          padding: '8px',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  devidedBy: {
    paddingLeft: '50px',
  },
  garphLeftScholorFederal: {
    width: '70%',
  },
  garphRightScholorFederal: {
    width: '30%',
  },
  opacityZero: {
    opacity: '0',
  },
  totalCostNewSavings: {
    '& span:first-child': {
      width: '83.5%',
    },
  },
  roomAndBoard: {
    height: '30px',
  },
  livingExpencesTable: {
    '& table': {
      marginTop: '20px',
    },
  },
  financialAidGaps: {
    paddingTop: '20px',
    paddingBottom: '15px',
    '& li': {
      paddingBottom: '15px',
    },
  },
  inputGroupSpan: {
    paddingLeft: '10px',
  },
  ScholarshipGrants: {
    '& th': {
      '&:last-child': {
        paddingRight: '32px',
      },
    },
  },
  earningIncomeUL: {
    paddingTop: '25px',
    paddingBottom: '25px',
    '& li': {
      paddingBottom: '15px',
    },
  },
  totalFourYears: {
    position: 'relative',
    left: '-27px',
  },
  inputRightBlockFlexFederal: {
    width: '110px',
  },
  numberCountPadding: {
    paddingRight: '0px',
    display: 'flex',
  },
  borderBottomDashed: {
    borderBottom: '1px dashed #1672b4',
    textDecoration: 'none !important',

  },
  garphRightFederalNew: {
    justifyContent: 'flex-start',
    paddingLeft: '35px',
  },
  totalCostNewFlexTotal: {
    '& span:first-child': {
      width: '74%',
    },
    '& span:last-child': {
      paddingRight: '90px',
      '@media (max-width: 767px)': {
        paddingRight: '00px',
      }
    },
  },
  pwisliderInputs: {
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    '& > span': {
      margin: '0',
      width: '95%',
    },
    '& p': {
      fontSize: '18px !important',
      textAlign: 'right',
      width: '100%',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  flexConatineNew: {
    alignItems: 'center',
    '& p': {
      paddingRight: '50px',
      maxWidth: '180px',
    },
  },
  flexNewContainer: {
    display: 'flex',
  },
  duringScholl: {
    '& table': {
      '& th': {
        '&:last-child': {
          textAlign: 'right !important',
        },
        '&:nth-child(2)': {
          textAlign: 'right !important',
        },
      },
      '& td': {
        textAlign: 'left !important',
        '&:nth-child(2)': {
          textAlign: 'right !important',
        },
        '&:last-child': {
          textAlign: 'right !important',
          // background: '#e2f0d9',
        },
      },
    },
  },

  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  selectedTable: {
    '& table': {
      '& thead': {
        '& tr': {
          '& td': {
            '&:nth-child(1)': {
              width: 'auto',
            },
          },
        },
      },
    },
  },
  TextFieldSchool: {
    '& select': {
      outline: '0',
      fontSize: '14px',
      lineHeight: '1.42857143',
      height: '34px',
      color: '#555',
      backgroundColor: '#fff',
      backgroundImage: 'none',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      width: '100%',
      padding: '4px',
      fontFamily: 'inherit',
      marginLeft: '5px',
    },
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa !important',
    display: 'inline-block',
  },
  dottedTitle: {
    textDecoration: 'none !important',
    paddingBottom: '4px',
    '& span': {
      borderBottom: '1px dashed #0069aa !important',
      height: '25px',
      // whiteSpace: 'break-spaces',
      overflow: 'hidden',
    },
  },
  graphButtn: {
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    msUserSelect: 'none',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    borderWidth: '2px',
    padding: '5px 10px',
    marginLeft: '10px',
    '@media (max-width: 414px)': {
      fontSize: '14px',
      margin: '3px 5px',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
    },
  },
  graphButtnDiv:{
    width: 'calc(85% - -60px)',
    height: '36px',
    '@media (max-width: 414px)': {
      height: '50px',
    },
    '& button':{
      float: 'right',
    }
  },
  bdNone: {
    borderBottom: '0px dashed #0069aa!important',
    '& span':{
      borderBottom: '0px dashed #0069aa!important',
    }
  },
  fsLoans: {
    display: 'inline-flex',
    position: 'absolute',
    bottom: '11px',
    left: 'calc(100% - 870px)',
  },
  fsFirstLoan: {
    bottom: '11px',
    left: 'calc(100% - 870px)',
  },
  deferTable: {
    padding: '20px 40px 10px',
    margin: '0',
    '@media (max-width: 479px)': {
      padding: '20px 0px 10px',
    },
    '& table': {
      width: '100%',
      maxWidth: '100%',
      marginBottom: '20px',
      '& thead': {
        '& tr': {
          '& th': {
            fontWeight: '500',
            fontFamily: '"MuseoSans-500"',
            backgroundColor: '#dae3f3',
            borderBottom: '3px solid #fff',
            '&:first-child': {
              width: '450px',
            },
          },
        },
      },
      '& td': {
        '& span': {
          borderBottom: 'none !important'
        }
      }
    },
  },
  blueBottomBorder: {
    borderBottom: '2px solid #5686da',
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': { 
        paddingLeft: '50px !important',
      }
    },
  },
  makeDeferTable: {
    padding: '20px 40px 10px',
    margin: '0',
    '@media (max-width: 767px)': {
      padding: '20px 0px 10px',
      overflow: 'auto',
    },
    '& table': {
      width: '100%',
      maxWidth: '100%',
      '& thead': {
        '& tr': {
          '& th': {
            backgroundColor: '#dae3f3',
            borderBottom: '3px solid #fff',
            fontSize: '20px',
            textAlign: 'right',
            fontWeight: '500',
            fontFamily: '"MuseoSans-500"',
            verticalAlign: 'bottom',
            padding: '8px',
            lineHeight: '1.42857143',
            '@media (max-width: 767px)': {
              fontSize: '16px',
              minWidth: '210px',
            },
            '&:first-child': {
              textAlign: 'left',
              width: '450px',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            borderTop: '1px solid #fff',
            fontSize: '20px',
            verticalAlign: 'middle',
            backgroundColor: '#f2f2f2',
            padding: '8px',
            lineHeight: '1.42857143',
            textAlign: 'right',
            '@media (max-width: 767px)': {
              fontSize: '16px',
            },
            '&:first-child': {
              textAlign: 'left',
            },
            '& span': {
              borderBottom: 'none'
            }
          },
        },
      },
    },
  },
  inputRightBlok: {
    justifyContent: 'center !important',
  },
  deferText: {
    fontSize: '20px',
    float: 'left',
    width: '100%',
    display: 'flex',
    marginBottom: '15px',
    alignItems: 'center',
    '& p':{
      paddingRight: '0',
      marginBottom: '0px',
      marginTop: '20px',
      lineHeight: '1.42857143',
    }
  },
  deferDesc: {
    width: '180px',
    display: 'inline-block',
    border: 'none !important',
    color: '#333',
    '& font': {
      color: '#333 !important',
    },
  },
  radioDefer: {
    height: '30px',
    lineHeight: 'inherit',
    marginTop: '25px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#333',
    marginLeft: '5%',
    width: '175px',
    textAlign: 'center',
    listStyleType: 'none',
    paddingLeft: '0px',
    display: 'flex',
    '& li': {
      width: '50%',
      color: '#333',
      backgroundImage: 'none !important',
      textAlign: 'center !important',
      padding: '0px !important',
      margin: '0px !important',
      display: 'block !important',
    },
  },
  activeList: {
    backgroundColor: '#d9d9d9',
    fontFamily: 'MuseoSans-500',
    fontweight: '500',
  },
  moduleToolTip: {
    padding: '3px 8px',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  makeBg: {
    '& tbody': {
      '& tr': {
        '& td': {
          '&:nth-child(2)': {
            background: '#e2f0d9',
          },
        },
      },
    },
  },
  deferBg: {
    '& tbody': {
      '& tr': {
        '& td': {
          '&:nth-child(3)': {
            background: '#e2f0d9',
          },
        },
      },
    },
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '& button': {
      margin: '0px 2px !important',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
  },
  closeWalkThru: {
    backgroundColor: '#fff !important',
    borderColor: '#ccc !important',
    color: '#333 !important',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeLists: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '5px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  definitionTooltips: {
    maxWidth: '200px',
    padding: '3px 8px !important',
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: '#0069aa !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px !important',
    boxSizing: 'border-box',
    textAlign: 'left',
    width: 'auto',
    marginTop: '-5px !important',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    },
  },
  totalValues: {
    fontFamily: 'MuseoSans-300 !important',
  },
  paddingLeftNew: {
    paddingLeft: '3px !important',
  },
  marginBottom: {
    marginBottom: '8px',
    fontWeight: 'normal',
    fontFamily: 'MuseoSans-700 !important',
    display: 'block',
  },
  marginBottomZeroNew: {
    marginBottom: '5px !important',
  },
  marginTopMoreNew: {
    marginBottom: '30px !important',
  },
  totalCostNewSavingsGap: {
    marginTop: '20px',
  },
  moneyNeddedTD: {
    padding: '45px 30px',
    '& table': {
      '& td:first-child': {
        display: 'none !important',
      },
    },
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  screenHght: {
    height: '110vh',
  },
  privateLoans: {
    '& li': {
      padding: '0 0 13px 30px',
      marginBottom: '0px !important',
      '& span': {
        marginLeft: '0px',
        marginRight: '0px',
      },
    },
  },
  privateLoanOptions: {
    marginTop: '20px !important',
    marginBottom: '10px !important',
  },
  schoolTopSpace: {
    padding: '20px 20px 20px 0',
  },
  heSources: {
    margin: '0px 0px !important',
    paddingRight: '15px',
  },
  heSourcesNew: {
    paddingLeft: '15px',
    margin: '0px 0px !important',
    '@media (max-width: 599px)': {
      paddingLeft: '0px',
    }
  },
  costPositive: {
    background: 'green',
    color: '#fff',
    fontSize: '15px !important',
    lineHeight: '28px !important',
    paddingLeft: '0 !important',
    textAlign: 'center !important',
    paddingRight: '0',
  },
  borderGapFlexGroupLess: {
    '&::before': {
      right: '120px',
    },
  },
  tooltipInfoNew: {
    width: '200px',
    background: '#0069aa !important',
    opacity: '1 !important',
    color: '#fff !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px',
    fontSize: '14px !important',
  },
  filterActive: {
    transform: 'rotate(180deg)',
    position: 'initial !important',
  },
  filterSelected: {
    color:'#0069aa'
  },
  resetIcon: {
    '& span':{
      textAlign: 'right',
    },
    '& img':{
      maxWidth: '20px',
      cursor: 'pointer'
    }
  },
  resetIconNew:{
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  dangerText: {
    color: '#bf0000 !important'
  },
  clickCustomeSchool: {
    '& span': {
      color: '#337ab7',
      cursor: 'pointer',
      padding: '0 5px',
      textDecoration: 'underline',
      display: 'inline',
      '&:hover': {
        color: '#f89b23',
      },
    }
  },
  cursorPointer: {
    cursor: 'pointer',
    '&:hover':{
      background: '#d5d5d5',
    }
  },
  disableActiveMenu: {
    color: 'gray !important',
    pointerEvents: 'none',
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop:'5px',
        fontStyle:'italic'
      }
    }
  },
  studentLoansAnalysisTable: {
    '& span':{
      borderBottom: 'none !important'
    }
  },
  padding30pxRight: {
    paddingLeft: '30px !important'
  },
  compareDefaultColor: {
    '& td': {
      background: '#ddebf7 !important'
    }
  },
  spanPerc:{
    position: 'relative',
    left: '-8px',
    color: '#333 !important',
  },
  spanYrs:{
    color: '#333 !important',
  },
  padLeft30:{
    paddingLeft: '30px',
  },
  spaceLeft:{
    marginLeft: '-5px',
    textDecoration: 'none',
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
} );

export default styles;
