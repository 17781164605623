import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { Link, scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import parentsBanner from '../../assets/img/students-parents/Inkwiry-for-Parents.png';
import parentsFinancial from '../../assets/img/students-parents/parents-financial.jpg';
import parentsTruth from '../../assets/img/students-parents/parents-truth.jpg';

const Parents = ( props ) => {
  const { classes, history } = props;

  const [age, setAge] = useState( '' );
  const [studentReadMore, setStudentReadMore] = useState( true );
  const [parentsReadMore, setParentsReadMore] = useState( true );
  const [sectionclass1, setSectionclass1] = useState( false );
  const [sectionclass2, setSectionclass2] = useState( false );
  const [sectionclass3, setSectionclass3] = useState( false );
  const [sectionclass4, setSectionclass4] = useState( false );
  const [sectionclass5, setSectionclass5] = useState( false );
  const [sectionclass6, setSectionclass6] = useState( false );
  const [sectionclass7, setSectionclass7] = useState( false );
  const [bottomReadMore, setBottomReadMore] = useState( false );
  const [leftMenuClass, setLeftmenuClass] = useState( false );
  const [schoolLeftMenu, setSchoolLeftMenu] = useState( false );

  const parentsRef = useRef( null );
  const rummyRef = useRef( null );

  const joinInk = bottomReadMore ? `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen} ${classes.studentBottomFixed}` : `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen}`;
  const ReadMoreCondition = parentsReadMore ? joinInk : classes.studentsPageReadmoreOpen;
  const getschoolLeftMenu = schoolLeftMenu ? `${classes.readmoreLftCnt} ${classes.parentleftMenuClass}` : `${classes.readmoreLftCnt}`;

  const handleChange = ( event ) => {    
    setAge( event.target.value );
    var element = document.getElementById(event.target.value);
    var elemRect = element.offsetTop + 170;
    console.log(elemRect);
    setTimeout( () => {
      scroller.scrollTo( 'scroll-to-element', {
        duration: 700,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: elemRect,
      } );
    }, 300 );
  };

  useScrollPosition( ( { currPos } ) => {
    if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top > 54 ) {
      setParentsReadMore( false );
      setSectionclass1( false );
    }
    if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < 54 && parentsRef.current.getBoundingClientRect( ).top > -6271 ) {
      setParentsReadMore( true );
      setSchoolLeftMenu( false );
    }
    if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < 54 && parentsRef.current.getBoundingClientRect( ).top > -360 ) {
      setSectionclass2( false );
      setSectionclass1( true );
      setSchoolLeftMenu( false );
      setLeftmenuClass( false );
    } else if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < -446 && parentsRef.current.getBoundingClientRect( ).top > -1460 ) {
      setSectionclass1( false );
      setSectionclass2( true );
      setSectionclass3( false );
      setSchoolLeftMenu( false );
      setLeftmenuClass( false );
    } else if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < -1622 && parentsRef.current.getBoundingClientRect( ).top > -2748 ) {
      setSectionclass2( false );
      setSectionclass3( true );
      setSectionclass4( false );
      setSchoolLeftMenu( false );
      setLeftmenuClass( false );
    } else if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < -2946 && parentsRef.current.getBoundingClientRect( ).top > -3722 ) {
      setSectionclass3( false );
      setSectionclass4( true );
      setSectionclass5( false );
      setSchoolLeftMenu( false );
      setLeftmenuClass( false );
    } else if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < -3937 && parentsRef.current.getBoundingClientRect( ).top > -4273 ) {
      setSectionclass4( false );
      setSectionclass5( true );
      setSectionclass6( false );
      setSchoolLeftMenu( false );
      setLeftmenuClass( false );
    } else if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < -4448 && parentsRef.current.getBoundingClientRect( ).top > -4859 ) {
      setSectionclass5( false );
      setSectionclass6( true );
      setSchoolLeftMenu( false );
      setSectionclass7( false );
      if ( parentsRef.current.getBoundingClientRect( ).width < 1000 ) {
        setLeftmenuClass( true );
        setSchoolLeftMenu( false );
      } else {
        setLeftmenuClass( false );
        setSchoolLeftMenu( true );
      }
    } else if ( parentsRef.current.getBoundingClientRect( ).top !== 0 && parentsRef.current.getBoundingClientRect( ).top < -4984 && parentsRef.current.getBoundingClientRect( ).top > -6445 ) {
      setSectionclass1( false );
      setSectionclass2( false );
      setSectionclass3( false );
      setSectionclass4( false );
      setSectionclass5( false );
      setSectionclass6( false );
      setSectionclass7( true );
      if ( parentsRef.current.getBoundingClientRect( ).width < 1000 ) {
        setSchoolLeftMenu( false );
        setLeftmenuClass( true );
      } else {
        setLeftmenuClass( false );
        setSchoolLeftMenu( true );
      }
    }

    if ( currPos.y > -1500 ) {
      setParentsReadMore( true );
    }
    if ( currPos.y < -8000 && currPos.y > -8500 ) {
      setBottomReadMore( true );
    } else {
      setBottomReadMore( false );
    }
  } );

  const scrollToReadMore = () => {
    setTimeout( () => {
      scroller.scrollTo( 'scroll-to-element', {
        duration: 600,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -57,
      } );
    }, 300 );
    setParentsReadMore( true );
  };

  const handleReadMore = () => {
    setStudentReadMore( false );
    scrollToReadMore();
  };

  const redirectToHHigherEducation = () => {
    history.push( '/plan-with-inkwiry/he' );
  };

  const redirectToPricing = () => {
    history.push( '/pricing' );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.parentsPage }>
        <div className={ classes.parentstBanner }>
          <img src={ parentsBanner } alt="Parents Banner" />
          <div className={ classes.container }>
            <div className={ classes.bannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography variant="h3" component="h3">Inkwiry for Parents</Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.parentstContent }>
          <div className={ classes.container }>
            <div className={ classes.parentstContentRow }>
              <Typography variant="h3" component="h3">You know this: Being a parent is really tough.</Typography>
              <Typography variant="h4" component="h4">Take the guesswork out of finances and build a sound strategy.</Typography>
              <p>Yup, being a parent can be really tough. You want the best for your kids, and you&apos;d do just about anything for them.</p>
              <p>But sometimes they need something and you&apos;re not sure how to provide it. Or even if you should? Or where to begin to figure it out?</p>
              <p>Decisions can get particularly gut-wrenching when your students reach high school and/or college-age. There&apos;s a new set of issues around higher education, its associated loans and career preparation. Substantial costs that, if you aren&apos;t careful, can wipe out your family&apos;s resources.</p>
              <p>How do you open this conversation with your student? How can you plan for this phase of your family life ... together?</p>
              <p>That&apos;s where Inkwiry comes in. Inkwiry can help facilitate the family financial conversation and clarify your own best options. With Inkwiry, you can build a solid strategy that rewards you and your children with financial independence, confidence and peace of mind.</p>
              <p>Jump right in and test drive our Higher Education module. Experience why planning with Inkwiry can help you and your child build a path to financial independence.</p>
              <div className={ classes.parantMoreInfo }>
                <Button onClick={ redirectToHHigherEducation } className={ classes.testDriveBtn }>
                  <span>Take a Test Drive</span>
                  <i className="las la-greater-than" />
                </Button>
                <Button className={ classes.moreInfoBtn } aria-hidden="true" onClick={ handleReadMore }>
                  Read More
                  <i className="las la-greater-than" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={ !studentReadMore ? ReadMoreCondition : classes.studentsPageReadmore } name="scroll-to-element">
          <Grid container>
            <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
              <div className={ leftMenuClass ? `${classes.readmoreLftCnt} ${classes.leftmenuClass}` : getschoolLeftMenu }>
                <Typography variant="h4" component="h4">Inkwiry for Parents</Typography>
                <ul className={ classes.inkwiryListTab }>
                  <li>
                    <Link
                      to="section1"
                      spy
                      delay={ 0 }
                      smooth
                      duration={ 1000 }
                      offset={ -55 }
                      className={ sectionclass1 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Let&apos;s face it. You need a plan.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section2"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass2 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Inkwiry is revolutionary financial planning software for parents.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section3"
                      spy
                      delay={ 0 }
                      smooth
                      duration={ 1000 }
                      offset={ -55 }
                      className={ sectionclass3 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Illuminate your path.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section4"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass4 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Your data, organized and smarter.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section5"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass5 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>In truth, we all want financial independence.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section6"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass6 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>How can Inkwiry help?</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section7"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -90 }
                      duration={ 1000 }
                      className={ sectionclass7 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Here&apos;s the most important part.</p>
                    </Link>
                  </li>
                </ul>
                <div className={ classes.selectDiv }>
                  <FormControl className={ classes.formControl }>
                    <Select
                      value={ age }
                      onChange={ handleChange }
                      displayEmpty
                      className={ classes.selectEmpty }
                      inputProps={ { 'aria-label': 'Without label' } }
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem className={ classes.listSelect } value={"section1" }>Let&apos;s face it. You need a plan.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={"section2" }>Inkwiry is revolutionary financial planning software for parents.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={"section3" }>Illuminate your path.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={"section4" }>Your data, organized and smarter.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={"section5" }>In truth, we all want financial independence.</MenuItem>
                      <MenuItem className={ classes.listSelect } value={"section6" }>How can Inkwiry help?</MenuItem>
                      <MenuItem className={ classes.listSelect } value={"section7" }>Here&apos;s the most important part.</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 9 }>
              <div className={ classes.readmoreRightCnt } ref={ parentsRef }>
                <div className={ classes.readmoreRightCntSec } id="section1">
                  <div className={ classes.whereStartCnt }>
                    <Typography variant="h4" component="h4">Let&apos;s face it. You need a plan.</Typography>
                    <p>The traditional approach would be to spend untold hours organizing your data and pay between $1,500 and $2,500 to hire a financial planner to devise a one-time, static plan.</p>
                    <p>And you&apos;d be on the right track, until now.</p>
                    <p>With Inkwiry, it&apos;s easy to build an effective financial plan that you can update as needed. Best of all, for less than a quarter per day, you are in control of your own plan and your own future.</p>
                  </div>
                </div>
                <div className={ classes.readmoreRightCntSec } id="section2" ref={ rummyRef }>
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">Inkwiry is revolutionary financial planning software for parents.</Typography>
                    <p>Inkwiry&apos;s intelligent software is designed for parents as well as students. And if anything can make planning quick, easy and eye-opening, it&apos;s Inkwiry.</p>
                    <p>The Financial Configurator does all the hard work for you (and in a click, you can see the calculations, too). You just type in your information, aligned with your student&apos;s goals and your own priorities, to reveal as many different scenarios as you desire. Then compare and contrast to find your best options.</p>
                    <p>The cool thing is your plan is dynamic and can change as your student&apos;s circumstances evolve. Offered a new scholarship? Need further education? Off-campus living expenses on the rise? Just plug in new data and Inkwiry goes to work.</p>
                  </div>
                </div>
                <div className={ classes.imgBanner }>
                  <img src={ parentsFinancial } alt="" />
                </div>
                <div className={ classes.readmoreRightCntSec } id="section3">
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">Illuminate your path.</Typography>
                    <p>Even though Inkwiry can help you in all areas of your personal financial planning, an important focus is to help you aid your high school and college students in planning higher education choices, identifying available resources, weighing your investment against the viability of career choices and minimizing risk.</p>
                    <p>Why would you put one penny into a plan that doesn&apos;t pencil out?</p>
                    <p>
                      In one evening using Inkwiry, you can illuminate your path, your chilld&apos;s path, and know what you need to know. Leverage Inkwiry&apos;s Career Sketch Stories – a collection of career sketches filled with valuable insights and powerful strategies. Explore our examples and learn how to build your own. Maybe you&apos;ll discover a new path to reach your goals. The best part – the career sketches are free.
                    </p>
                    <p>Working through just two of the 15 modules - Higher Education and Career Path - can answer major questions about sources of funds, expenses to consider and the payback to expect from a variety of higher education choices.</p>
                    <p>And if you wish you knew more about financial information – like how taxes work, the difference between types of student loans, or even saving for retirement – financial literacy education is built right into Inkwiry.</p>
                  </div>
                </div>

                <div className={ classes.testimonialSecDark }>
                  <Typography variant="h5" component="h5">
                    <span />
                    <p>Planning is so important. And, the earlier you start, the better. Before Inkwiry, we did not think comprehensive financial life planning and navigating personal finance issues such as understanding and managing debt was possible for young people. But, Inkwiry has truly revolutionized personal finance and planning making it easy for young people to navigate their financial future with confidence.”</p>
                  </Typography>
                  <Typography variant="h6" component="h6">– John and Sarita, CPAs</Typography>
                </div>
                <div className={ classes.readmoreRightCntSec } id="section4">
                  <div className={ classes.whereStartCnt }>
                    <Typography variant="h4" component="h4">Your data, organized and smarter.</Typography>
                    <p>Can you imagine trying to manually organize all the financial data needed to make the decisions you are facing? Right. And you&apos;re not alone. Most people have their information all over the place. Inkwiry helps you make sense of your data, identify the things that really matter and organize your information.</p>
                    <p>And here&apos;s where it gets exciting – once entered into Inkwiry, your data becomes smarter. When your data is in the Financial Configurator, you can ask it questions and instantly get answers: What if we sought federal loans instead of private loans? How much would two years at a community college save? What if I contributed 10% more to my son/daughter&apos;s tuition and fees?</p>
                  </div>
                </div>
                <div className={ classes.imgBanner }>
                  <img src={ parentsTruth } alt="" />
                </div>
                <div className={ classes.readmoreRightCntSec } id="section5">
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">In truth, we all want financial independence.</Typography>
                    <p>Is one of your primary goals in raising kids to equip them to be independent? To be able to stand on their own two feet? To think critically and make informed decisions? To have goals and a plan to reach those targets? And to be financially independent?</p>
                    <p>If so, Inkwiry is built for you.</p>
                    <p>We know that planning is key to financial independence. As a matter of fact, just writing down your goals makes it 42% more likely you will achieve them. Successful people everywhere have one thing in common: a strategy and a plan that works for them.</p>
                  </div>
                </div>
                <div className={ `${classes.findSolutions} ${classes.findSolutionsBlue}` } id="section6">
                  <Typography variant="h3" component="h3">How can Inkwiry help?</Typography>
                  <Typography variant="h4" component="h4">Inkwiry can help you:</Typography>
                  <ul>
                    <li>Evaluate what type of higher education might be best for your child – a four-year college, a community college, a trade or specialty school, graduate school or the military.</li>
                    <li>Determine what higher education will cost.</li>
                    <li>Figure out who will pay for higher education, and how.</li>
                    <li>Discover exactly which scenarios will help you avoid crippling debt – for yourself and for your child.</li>
                    <li>Think ahead to opportunities down the line – and help your child match his/her talents, goals and aspirations with a career that provides sufficient income.</li>
                    <li>Know how to protect your resources so you can continue saving for retirement and make sure your own financial independence is secure. And this applies to you as well as your sons and daughters.</li>
                  </ul>
                </div>
                <div className={ classes.readmoreRightCntSec }>
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` } id="section7">
                    <Typography variant="h4" component="h4">Here&apos;s the most important part.</Typography>
                    <p>There is no question all of this takes planning – financial planning and soul-searching. You want to know exactly how things will play out. You want to build a partnership with your kids to launch them into a financially independent future.</p>
                    <p>Being a parent can be really tough, but planning for higher education, your children&apos;s future and your own financial stability doesn&apos;t have to be. Inkwiry makes it quick, easy and effective, providing eye-opening solutions you might never have imagined before.</p>
                    <p>Review the sample career sketches, and then create three possible scenarios. Design your dynamic plan for the next 5, 10, 15 years and make your next moves with confidence. Over the long run, your $9 investment in Inkwiry can save you a great deal of money, time and uncertainty.</p>
                    <p>All in all, the power of knowing your family&apos;s best path and building a solid strategy can reward all of you with financial independence, confidence and peace of mind.</p>
                    <Typography variant="h6" component="h6">Now, make it happen. Sign up today and join Inkwiry.</Typography>
                  </div>
                </div>
                <div className={ classes.joinBtn }>
                  <Button onClick={ redirectToPricing }>
                    <span>JOIN INKWIRY</span>
                    <i className="las la-greater-than" />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Parents.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Parents );
