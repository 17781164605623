import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-career-advancement/styles';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';

const RetirementSummary = ( props ) => {
  const {
    classes, handleDefinationPopup
  } = props;

  return (
    <div className={classes.contentBlock}>
      <p>You're on your way to building your retirement savings. Review your 401(k) contributions during this career move.</p>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
          <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70}` }>
              <table cellPadding="0" cellSpacing="0">
                  <thead>
                      <tr className={ classes.trHead }>
                          <td>401(k) Retirement Accounts</td>
                          <td>401(k)</td>
                          <td>Roth 401(k)</td>
                      </tr>
                  </thead>
                <tbody>
                  <tr>
                    <td>
                      <span><strong>Beginning 401(k) Balance in Year {(moduleServices.module17Data.start_year !== undefined && moduleServices.module17Data.start_year !== null )&& moduleServices.module17Data.start_year}</strong></span>
                    </td>
                    <td>
                      <span>{moduleServices.module17Data.beginningBalanceInYearFourth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.beginningBalanceInYearFourth} prefix={moduleServices.module17Data.beginningBalanceInYearFourth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.beginningBalanceInYearFourth < 0 && ')'} />
                        ) : ('$0')}</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.beginningBalanceInYearFourthRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.beginningBalanceInYearFourthRoth} prefix={moduleServices.module17Data.beginningBalanceInYearFourthRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.beginningBalanceInYearFourthRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Personal Contributions After {(moduleServices.module17Data.end_year > 0 && moduleServices.module17Data.start_year > 0) ? (moduleServices.module17Data.end_year - moduleServices.module17Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.further401kSummaryPersonalContribution !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryPersonalContribution} prefix={moduleServices.module17Data.further401kSummaryPersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryPersonalContribution < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.further401kSummaryPersonalContributionRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryPersonalContributionRoth} prefix={moduleServices.module17Data.further401kSummaryPersonalContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryPersonalContributionRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Personal Contributions After {(moduleServices.module17Data.end_year > 0 && moduleServices.module17Data.start_year > 0) ? (moduleServices.module17Data.end_year - moduleServices.module17Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution} prefix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth} prefix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Total Personal Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings} prefix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth} prefix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Employer Contributions After {(moduleServices.module17Data.end_year > 0 && moduleServices.module17Data.start_year > 0) ? (moduleServices.module17Data.end_year - moduleServices.module17Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module17Data.further401kSummaryEmployerContribution !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEmployerContribution} prefix={moduleServices.module17Data.further401kSummaryEmployerContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEmployerContribution < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module17Data.further401kSummaryEmployerContributionRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEmployerContributionRoth} prefix={moduleServices.module17Data.further401kSummaryEmployerContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEmployerContributionRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Employer Contributions After {(moduleServices.module17Data.end_year > 0 && moduleServices.module17Data.start_year > 0) ? (moduleServices.module17Data.end_year - moduleServices.module17Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>{moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution} prefix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>{moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth} prefix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Total Employer Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings} prefix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings < 0 && ')'} />
                        ) : ('$0')}  
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth} prefix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      401(k) Balance before <span onClick={ () => handleDefinationPopup('Vesting') }>Vesting</span> Reductions
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.furtherBalanceBeforeVestingReductions !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherBalanceBeforeVestingReductions} prefix={moduleServices.module17Data.furtherBalanceBeforeVestingReductions >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherBalanceBeforeVestingReductions < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.furtherBalanceBeforeVestingReductionsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherBalanceBeforeVestingReductionsRoth} prefix={moduleServices.module17Data.furtherBalanceBeforeVestingReductionsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherBalanceBeforeVestingReductionsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      % of Employer Contributions & Earnings Not Vested
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur} prefix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth} prefix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Ending 401(k) Balance in Year {moduleServices.module17Data.end_year > 0 ? moduleServices.module17Data.end_year  : 0}
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.furtherCareer401kEndingBalance !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401kEndingBalance} prefix={moduleServices.module17Data.furtherCareer401kEndingBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401kEndingBalance < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module17Data.furtherCareer401kEndingBalanceRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401kEndingBalanceRoth} prefix={moduleServices.module17Data.furtherCareer401kEndingBalanceRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401kEndingBalanceRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
      </div>
  </div>

  );
};

RetirementSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( RetirementSummary );
