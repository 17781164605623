import TickMark from '../../assets/img/pwi-investments/tick_mark_blue.png';

const styles = (theme) => ({
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
  },
  fiHeader: {
    position: 'relative',
  },
  fiHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #caa336',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: 'normal',
      marginLeft: '29px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      [theme.breakpoints.down('xs')]: {
        fontSize: '19px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      maxWidth: '500px',
      width: '100%',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '10px',
      },
      '& > img': {
        height: '50px',
        cursor: 'pointer',
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
          height: '30px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    paddingBottom: '1px',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '1.1',
      [theme.breakpoints.down('xs')]: {
        fontSize: '19px',
        lineHeight: '24px',
      },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 10px 0',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },
  clickHere: {
    color: '#333 !important',
    textDecoration: 'none',
    cursor: 'pointer',
    borderBottom: '1px dashed #0069aa',
    '&:hover': {
      color: '#333 !important',
    }
  },
  btnGroup: {
    display: 'flex',
    marginTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down('xs')]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  potentialGrowth: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& > div:last-child': {
      '@media (max-width: 991px)': {
        minWidth: '850px',
      }
    },
    '@media (max-width: 991px)': {
      overflow: 'auto',
    },
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      color: theme.palette.common.black,
      marginBottom: '0',
      fontWeight: '300',
      fontFamily: '"MuseoSans-300"',
      '@media (max-width: 991px)': {
        minWidth: '850px',
      }
    },
  },
  blueLine: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
    '@media (max-width: 991px)': {
      minWidth: '850px',
    }
  },
  clearBtn: {
    margin: '0 15px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '&:hover': {
      background: '#0d97ec',
    },
    '& img': {
      maxWidth: '30px',
    },
  },
  careerInput: {
    width: '170px',
    marginRight: '18px',
    marginTop: '30px',
    marginBottom: '30px',
    '& label': {
      fontSize: '18px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        fontSize: '18px',
      }
    },
  },
  potentialInputs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    alignItems: 'center',
    '@media (max-width: 991px)': {
      minWidth: '850px',
    }
  },
  inputGroup: {
    position: 'relative',
    display: 'table',
    borderCollapse: 'separate',
    '& span': {
      padding: '6px 6px',
      backgroundColor: 'white',
      boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, .075)',
      fontSize: '20px',
      paddingRight: '0px',
      fontWeight: '400',
      lineHeight: '1',
      color: '#555',
      textAlign: 'center',
      width: '1%',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      display: 'table-cell',
    },
  },
  dollarSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderRight: '0',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },
  formInput: {
    fontSize: '20px',
    zIndex: '0',
    outline: '0',
    lineHeight: '1.42857143',
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
    transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
    width: '100%',
    padding: '4px',
    display: 'table-cell',
    position: 'relative',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeftWidth: '0px',
    height: 'auto',
    '& >div': {
      '&:before': {
        content: 'none',
      },
      '&:after': {
        content: 'none',
      },
    },
    '& input': {
      fontSize: '20px',
    },
  },
  returnInput: {
    borderLeftWidth: '1px',
    borderRightWidth: '0px',
    paddingLeft: '6px',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    paddingRight: '0px',
    '& > div': {
      '& input': {
        padding: '0px 0px 0px !important',
      },
    },
  },
  annlInput: {
    '& > div': {
      '& input': {
        padding: '0px 0px 0px !important',
      },
    },
  },
  percentSymbol: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    borderLeft: '0',
    paddingRight: '6px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    borderRightWidth: '1px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  },
  calculateButton: {
    backgroundColor: '#fd9840',
    fontSize: '21px',
    color: '#ffffff',
    lineHeight: '19px',
    borderColor: '#fd9840',
    boxShadow: 'none',
    borderRadius: '3px',
    padding: '10px 20px',
    marginLeft: '0px',
    borderWidth: '2px',
    marginTop: '34px',
    outline: '0',
    '&:hover': {
      backgroundColor: '#f28735',
      borderColor: '#f28735',
      boxShadow: 'none',
    },
  },
  clickNone: {
    pointerEvents: 'none',
  },
  netWorth: {
    margin: '37px 0 0 18px',
    [theme.breakpoints.down('xs')]: {
      margin: '37px 10px 0 10px',
    },
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0',
      padding: '0',
      width: '100%',
      '& li': {
        display: 'block',
        padding: '0 0 8px',
        margin: '0',
        '& p': {
          fontFamily: 'MuseoSans-300',
          fontSize: '18px',
          lineHeight: '18px',
          padding: '0',
          margin: '0',
          '& > span': {
            display: 'inline-block',
            verticalAlign: 'middle',
            fontFamily: 'MuseoSans-500',
            fontSize: '12px',
            lineHeight: '12px',
            padding: '4px 13px',
            margin: '0 14px 0 0',
            background: '#ebebeb',
            borderRadius: '12px',
            minWidth: '72px',
            textAlign: 'center',
            boxSizing: 'border-box',
          },
          '& font': {
            '& span': {
              width: 'auto',
              fontSize: '20px',
              display: 'inline-block',
              verticalAlign: 'middle',
              color: theme.palette.common.black,
            },
          },
        },
      },
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': {
      maxWidth: '130px',
    }
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
    display: 'inline-block',
  },
  tableScroll: {
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  shortTermTable: {
    fontSize: '14px',
    lineHeight: '1.42857143',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    marginBottom: '20px',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    },
    '& th': {
      textAlign: 'center',
      verticalAlign: 'middle',
      width: '16.6%',
      background: '#4472c4',
      color: '#fff',
      padding: '8px 10px',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      borderBottom: '2px solid #ddd',
      [theme.breakpoints.down('xs')]: {
        padding: '3px',
      },
      '&:first-child': {
        visibility: 'hidden',
      },
    },
    '& td': {
      textAlign: 'center',
      verticalAlign: 'middle',
      padding: '8px',
      fontFamily: 'MuseoSans-300',
      [theme.breakpoints.down('xs')]: {
        padding: '3px',
      },
    },
    '& tbody': {
      '& tr': {
        background: '#f2f2f2',
        '& td': {
          border: '1px solid #fff',
          '&:first-child': {
            background: '#cfd5ea',
          },
        },
      },
    },
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    marginTop: '10px',
    marginBottom: '30px',
    position: 'relative',
    '& p': {
      margin: '0',
    },
  },
  balanceBox: {
    border: '1px solid #f0f0f0',
    padding: '30px',
    background: '#f5f5f5',
    borderTop: '1px solid #f0f0f0',
    marginBottom: '30px',
    '@media (max-width: 1199px)': {
      margin: '20px 0 30px 0',
      padding: '30px 30px 30px',
    },
    '& h3': {
      textAlign: 'left',
      lineHeight: '30px',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
    },
    '& p': {
      margin: '0',
    },
  },
  yearsList: {
    listStyle: 'none',
    padding: '0px',
    margin: '10px 0px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
    },
    '& li': {
      backgroundImage: `url(${TickMark})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '11px',
      backgroundPosition: 'left top 8px',
      display: 'flex',
      paddingLeft: '35px',
      alignItems: 'center',
      '@media (max-width: 991px)': {
        display: 'inline-block',
        flexWrap: 'wrap',
      }
    },
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 20px',
      height: '29px',
      cursor: 'pointer',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-300',
      fontSize: '21px',
      minWidth: '120px',
      marginLeft: '5px',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        padding: '0 10px',
        minWidth: '90px',
      },
    },
  },
  careerSketchTimeline: {
    textAlign: 'center',
    padding: '0 50px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 25px',
    },
    '& h3': {
      textAlign: 'left',
      color: '#0069aa',
      fontWeight: 'normal',
      fontSize: '26px',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0px',
        fontSize: '20px',
      },
      '& img': {
        marginLeft: '5px',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      '& li': {
        background: 'transparent',
        padding: '0',
        display: 'inline-block',
        margin: '5px',
        '& img': {
          maxWidth: '45px',
        },
      },
    },
  },
  blueText: {
    color: '#0069aa',
  },
  monthlyExpenses: {
    marginTop: '10px',
    fontSize: '21px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      lineHeight: '24px',
    },
    '& span': {
      display: 'block',
    },
  },
  benchMark: {
    position: 'absolute',
    padding: '5px 10px',
    fontSize: '14px',
    lineHeight: '24px',
    background: '#046bab',
    borderColor: '#046bab',
    borderRadius: '3px',
    bottom: '25px',
    right: '30px',
    color: '#fff !important',
    textDecoration: 'none !important',
    '&:hover': {
      background: '#046bab',
      borderColor: '#046bab',
      color: '#fff',
    },
  },
  borderDivider: {
    width: '100%',
    height: '3px',
    backgroundImage: 'linear-gradient(to right,#7f7f7f 50%,transparent 50%)',
    backgroundSize: '30px 100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  totalAttendance: {
    // maxWidth: '83%',
    maxWidth: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    '& table': {
      width: '100%',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      '& th': {
        backgroundColor: '#dae3f3',
        borderBottom: '3px solid #fff',
        fontSize: '20px',
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
        padding: '8px',
        textAlign: 'left',
        lineHeight: '23px',
        color: '#333',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
        '&:nth-child(2)': {
          textAlign: 'right',
          '@media (max-width: 767px)': {
            minWidth: '85px',
          }
        },
      },
      '& td': {
        fontSize: '20px',
        verticalAlign: 'middle',
        fontFamily: 'MuseoSans-300',
        padding: '8px',
        lineHeight: '23px',
        '@media (max-width: 767px)': {
          fontSize: '16px',
        },
        '& b': {
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
        },
        '&:nth-child(2)': {
          textAlign: 'right',
        },
      },
      '& tr': {
        borderBottom: '1px solid #fff',
      },
    },
  },
  leftPadding: {
    paddingLeft: '50px !important',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '30px !important',
    },
  },
  blueRow: {
    '& td': {
      backgroundColor: '#dae3f3',
    },
  },
  grayRow: {
    '& td': {
      backgroundColor: '#f2f2f2',
    },
  },
  contributionTable: {
    width: '100%',
    maxWidth: '580px',
    margin: '50px auto 20px',
    fontSize: '20px',
    borderCollapse: 'collapse',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    '& tr': {
      backgroundColor: '#f2f2f2',
      borderBottom: '1px solid #fff',
    },
    '& td': {
      padding: '8px',
      [theme.breakpoints.down('xs')]: {
        padding: '4px',
      },
    },
    '& tfoot': {
      '& td': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      }
    }
  },
  trHead: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderBottom: '3px solid #fff  !important',
    fontFamily: 'MuseoSans-500',
    '& th': {
      fontWeight: 'normal',
    },
  },
  trFoot: {
    background: '#ccc',
    backgroundColor: '#dae3f3 !important',
    borderTop: '2px solid #5686da',
    fontFamily: 'MuseoSans-300',
  },
  tableView: {
    padding: '50px 10px 26px',
    '& table': {
      width: '100%',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      '& th': {
        backgroundColor: '#dae3f3',
        borderBottom: '3px solid #fff',
        fontSize: '20px',
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
        padding: '8px',
        textAlign: 'center',
        '&:nth-child(3)': {
          textAlign: 'right',
        },
      },
      '& td': {
        fontSize: '20px',
        verticalAlign: 'middle',
        fontFamily: 'MuseoSans-100',
        padding: '8px',
        lineHeight: '1.42857143',
        '& b': {
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
        },
        '& i': {
          color: theme.palette.common.black,
        },
        '&:nth-child(3)': {
          textAlign: 'right',
        },
      },
      '& tr': {
        borderBottom: '1px solid #fff',
        backgroundColor: '#f2f2f2',
      },
    },
  },
  blueBottomBorder: {
    '& td': {
      borderBottom: '2px solid #5686da',
    },
  },
  leftIndent: {
    paddingLeft: '25px !important',
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
    boxSizing: 'border-box',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
      padding: '10px',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '18px',
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      position: 'relative',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        color: 'gray',
        flexWrap: 'wrap',
        position: 'relative',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&:hover': {
          color: '#eca041',
        },
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& img': {
          width: '3.8vh',
          display: 'block',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  bottomText: {
    // color: '#0069aa',
    // fontStyle: 'italic',
    // padding: '30px 0',
    // borderTop: '1px solid #d5d5d5',
    // fontSize: '16px',
    color: '#333',
    padding: '10px 0 0px',
    fontStyle: 'italic',
    '& p': {
      margin: '0px 0px 10px 0px',
    },
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    marginTop: '20px',
    '& ul': {
      padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '10px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  menuBoxNew: {
    background: '#f2f6ed',
    padding: '0 0 2px',
    borderRadius: '15px',
    margin: '5px 0',
    '& h5': {
      background: '#84a84d',
      borderRadius: '15px',
      color: '#fff',
      padding: '5px 10px',
      margin: '0 0 5px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '14px',
    },

  },
  menuBoxNewBg: {
    background: '#e5f0f6',
  },
  annuvalIncomeGrowthGraph: {
    width: '50%',
    margin: '0 25px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
    },
  },
  annuvalIncomeGrowthInput: {
    width: '100px',
    margin: '0 25px 0 auto',
    display: 'flex',
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },

  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  percentPadding: {
    paddingRight: '6px !important',
  },
  bottomBlueBorder: {
    borderBottom: '2px solid #0069aa !important',
  },
  lefBlock: {
    width: '70%',
    display: 'inline-block',
    float: 'left',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '20px',
    },
    '@media (min-width: 600px) and (max-width: 767px)': {
      width: 'calc(100% - 110px)',
    }
  },
  sliderName: {
    fontSize: '20px',
    paddingRight: '20px',
    minWidth: '150px',
    maxWidth: '150px',
    boxSizing: 'border-box',
    lineHeight: '1.42857143',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
      paddingRight: '0px',
      fontSize: '17px',
    },
  },
  sliderTitle: {
    fontSize: '18px',
    paddingRight: '20px',
    minWidth: '150px',
    maxWidth: '150px',
    boxSizing: 'border-box',
    lineHeight: '1.42857143',
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      maxWidth: '100%',
      fontSize: '15px',
    },
  },
  rzSlider: {
    width: '100%',
    margin: '0px 25px 0px 0px',
    [theme.breakpoints.down('xs')]: {
      margin: '0px 25px 0px 15px',
    },
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      // color: '#0069aa',
      minHeight: '4px',
    },
  },
  lefSlider: {
    display: 'flex',
    alignItems: 'center',
    height: '70px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      marginBottom: '25px',
    },
  },
  annualSlider: {
    display: 'table-cell',
    position: 'relative',
    paddingRight: '30px',
    paddingLeft: '15px',
    boxSizing: 'border-box',
    marginTop: '-10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px',
      marginTop: '20px',
      paddingLeft: '0px',
    },
    '&  div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '&:hover': {
        '&::before': {
          display: 'none',
        },
      },
    },
  },
  annualInput: {
    alignItems: 'center',
    marginTop: '0',
    fontSize: '18px',
    position: 'relative',
    height: '60px',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: '-20px',
    },
    '& input': {
      float: 'right',
      fontSize: '18px',
      width: '80px',
      textAlign: 'right',
      background: '0 0',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
      paddingBottom: '0px',
      '&:focus': {
        borderBottom: '1px solid #f89b22',
      }
    },
    '& div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  annualBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& span': {
      textAlign: 'right',
      width: '100%',
    },
    '& input': {
      border: 'none',
      borderBottom: '1px solid #0069aa',
      fontSize: '20px',
      textAlign: 'right',
      width: '80px',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  dollarInput: {
    marginLeft: '2%',
    lineHeight: '25px',
    position: 'relative',
    left: '0',
    fontSize: '18px',
  },
  investSlider: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: '30px',
      display: 'block',
    },
  },
  charitableTable: {
    width: '83.33333333%',
    // marginLeft: '8.33333333%',
    padding: '50px 10px 26px',
    boxSizing: 'border-box',
    maxWidth: '633px',
    margin: '0px auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
    },
    '& table': {
      maxWidth: '100%',
      margin: '0px auto 20px',
      '& tr': {
        '& td': {
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
    },
  },
  percentSymbl: {
    position: 'absolute',
    right: '-19px',
    bottom: '18px',
    top: '18px',
    [theme.breakpoints.down('xs')]: {
      right: '-15px',
    },
  },
  titleRight: {
    height: '30px',
    lineHeight: '30px',
    fontSize: '18px',
    marginBottom: '15px',
    fontWeight: '700',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    '& span': {
      borderBottom: '1px solid',
    },
  },
  educationValues: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%',
    marginRight: '5px',
  },
  sliderRight: {
    position: 'relative',
    display: 'flex',
    width: '28%',
    marginLeft: '1%',
    '&::before': {
      top: '-15px',
      content: "''",
      position: 'absolute',
      width: '4px',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      left: '140px',
      height: 'calc(100% + 40px)',
    },
  },
  sliderRightNew: {
    position: 'relative',
    display: 'flex',
    width: '28%',
    marginLeft: '1%',
    '&::before': {
      top: '-8px',
      content: "''",
      position: 'absolute',
      width: '4px',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      left: '140px',
      height: 'calc(100% + 20px)',
    },
  },
  annualValues: {
    position: 'relative',
    paddingRight: '30px',
    paddingLeft: '15px',
    boxSizing: 'border-box',
    marginTop: '0px',
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px',
    },
    '&  div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '&:hover': {
        '&::before': {
          display: 'none',
        },
      },
    },
  },
  summarySlider: {
    display: 'flex',
    alignItems: 'center',
    margin: '40px 0px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  sliderTax: {
    maxWidth: '250px',
  },
  livingExpencesMothly: {
    padding: '0 25px',
    width: '100px',
    position: 'relative',
    '& h3': {
      textAlign: 'center',
      fontFamily: 'MuseoSans-700',
      textDecoration: 'underline',
      marginLeft: '34px',
    },
  },
  opacityZero: {
    opacity: '0',
  },
  livingExpencesRange: {
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
      flexWrap: 'wrap',
    },
    '& p': {
      margin: '-19px 0 0',
      fontSize: '18px',
    },
    '& img': {
      width: '100%',
    },
  },
  rangeTitle: {
    paddingRight: '20px',
    marginTop: '-6px',
    minWidth: '150px',
    maxWidth: '150px',
    fontSize: '18px',
    lineHeight: '1.4',
    [theme.breakpoints.down('xs')]: {
      fontSize: '17px',
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
  rentGraphBlock: {
    display: 'flex',
    marginBottom: '30px',
    alignItems: 'center',
    '& h3': {
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  termGraphBlock: {
    display: 'flex',
    marginBottom: '15px',
    alignItems: 'center',
    '& h3': {
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  newGraphBlock: {
    display: 'flex',
    marginBottom: '0px',
    alignItems: 'center',
    '& h3': {
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  annuvalIncomeGraph: {
    width: '100%',
    margin: '8px 25px 0 15px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
      // color: '#0069aa',
      minHeight: '4px',
    },
  },
  giftSlider: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '30px',
      display: 'block',
    },
  },
  gitInputs: {
    display: 'flex',
    position: 'relative',
    paddingRight: '30px',
    boxSizing: 'border-box',
    marginTop: '-10px',
    width: '140px',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      marginTop: '40px',
      width: '90px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingRight: '25px',
    },
    '@media (min-width: 600px) and (max-width: 959px)': {
      marginTop: '0',
    },
    '&  div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '&:hover': {
        '&::before': {
          display: 'none',
        },
      },
    },
  },
  emptyTitle: {
    width: '100%',
    fontSize: '18px',
    textAlign: 'right',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  tabButtons: {
    paddingTop: '0px',
    margin: '25px 0px 0px',
    '@media (max-width: 479px)': {
      margin: '20px 0px 0px',
    },
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      marginRight: '10px',
      color: '#888',
      textTransform: 'inherit',
      minHeight: 'inherit',
      padding: '8px 25px',
      minWidth: '180px',
      fontSize: '16px',
      [theme.breakpoints.down('xs')]: {
        minWidth: '150px',
      },
      '&:hover': {
        '& span': {
          color: '#3f51b5',
        }
      }
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      '@media (max-width: 767px)': {
        overflow: 'auto',
        overflowY: 'hidden',
      }
    },
    '& > div > div + span': {
      display: 'none',
    },

  },
  tabsContent: {
    // overflowX: 'visible !important',
    // '& > div': {
    //   willChange: 'unset !important',
    // },
    // '& > div > div ': {
    //   overflowX: 'visible !important',
    // },
    // '& > div > div > div > div': {
    //   paddingLeft: '0px',
    //   paddingRight: '0px',
    //   paddingTop: '0px',
    // },
    '& img': {
      maxWidth: '100%',
    },
  },
  activeTab: {
    position: 'relative',
    top: '1px',
    background: '#fff',
    borderColor: '#000 !important',
  },
  showWorkSheet: {
    float: 'right',
    color: '#fff !important',
    backgroundColor: '#f0ad4e',
    borderRadius: '5px !important',
    position: 'relative',
    top: '0',
    textTransform: 'uppercase !important',
    padding: '4px 12px',
    fontSize: '16px',
    margin: '0 !important',
    border: '2px solid #eea236 !important',
    [theme.breakpoints.down('xs')]: {
      float: 'none',
    },
    '&:hover': {
      backgroundColor: '#ec971f !important',
      borderColor: '#d58512 !important',
      color: '#fff !important',
    },
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        paddingLeft: '50px !important',
      }
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
    boxShadow: 'none',
  },
  maxModule: {
    '& > div': {
      alignItems: 'baseline',
    },
    '& > div > div ': {
      width: '60%',
      maxWidth: '100%',
      verticalAlign: 'top',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  buttonOne: {
    backgroundColor: '#caa336',
    color: '#fff',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    borderWidth: '2px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    borderColor: '#caa336',
    '&:hover': {
      backgroundColor: '#caa336',
      borderColor: '#caa336',
      color: '#fff',
    },
  },
  buttonTwo: {
    backgroundColor: 'transparent',
    color: '#333',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    marginLeft: '5px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid #ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      border: '2px solid #adadad',
    },
  },
  pendinglistPopup: {
    boxShadow: 'none',
  },
  investTable: {
    width: '100%',
    maxWidth: '100%',
    '& table': {
      '& thead': {
        '& tr': {
          '& th': {
            '&:nth-child(2)': {
              textAlign: 'center !important',
              color: '#333 !important',
              fontSize: '18px',
              [theme.breakpoints.down('xs')]: {
                fontSize: '16px',
              },
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            color: '#333',
            textAlign: 'left !important',
            fontSize: '19px',
            fontWeight: '300',
            fontFamily: 'MuseoSans-300',
            willChange: 'unset !important',
            transition: 'none !important',
            [theme.breakpoints.down('xs')]: {
              fontSize: '15px',
            },
            '&:last-child': {
              textAlign: 'right !important',
            },
            '& i': {
              color: '#333',
            },
            '& span': {
              transition: 'none !important',
            },
          },
        },
      },
    },
  },
  leftdataPadding: {
    '& td': {
      '&:nth-child(1)': {
        paddingLeft: '25px !important',
      },
    },
  },
  mainRow: {
    '& th': {
      '&:nth-child(2)': {
        textAlign: 'center !important',
      },
    },
  },
  definitionTooltips: {
    maxWidth: '200px',
    padding: '3px 8px !important',
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: '#0069aa !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px !important',
    boxSizing: 'border-box',
    textAlign: 'left',
    width: 'auto',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': {
      maxWidth: '130px',
    },
  },
  tableTooltip: {
    width: '200px',
  },
  maxHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '18px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  moduleToolTip: {
    padding: '3px 8px',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  rewardText: {
    display: 'inline-block',
  },
  subListMenu: {
    width: '100%',
    listStyle: 'disc !important',
    listStylePosition: 'outside !important',
    paddingLeft: '42px !important',
    '& li': {
      display: 'list-item !important',
      fontSize: '2.0vh !important',
      paddingLeft: '0px !important',
      '@media (max-width: 3500px)': {
        fontSize: '1.58vh !important',
        lineHeight: '2.3vh',
      },
      '@media (max-width: 1400px)': {
        fontSize: '2.0vh !important',
      },
      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '11px !important',
        lineHeight: '16px',
      },
    },
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'display !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '5px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  investGrayDesign: {
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '20px',
    },
  },
  maxModalBody: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  maxClose: {
    fontSize: '21px',
    lineHeight: '1',
    color: '#000',
    textShadow: '0 1px 0 #fff',
    opacity: '.5',
    fontWeight: '700',
    top: '15px',
  },
  charitableInput: {
    marginTop: '0px',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  isummaryTable: {
    margin: '20px 0px 0px 0px',
    width: 'auto',
    '& table': {
      '& thead': {
        '& tr': {
          '& th': {
            '&:last-child': {
              minWidth: '130px',
            }
          }
        }
      },
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '16px',
          }
        }
      }
    }
  },

  pointerRow: {
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: '20px',
    },
  },
  sliderBottomSpace: {
    marginBottom: '100px',
  },
  potentialGrowthNew: {
    marginTop: '0px !important',
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  screenHght: {
    height: '110vh',
  },
  livingExpences: {
    display: 'flex',
    overflow: 'hidden',
  },
  livingExpencesFlexEnd: {
    alignItems: 'flex-end',
    marginTop: '50px',
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
    },
    '@media (max-width: 767px)': {
      marginTop: '10px',
    }
  },
  mainBlockRow: {
    alignItems: 'flex-end',
    display: 'flex',
    width: '99%',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    }
  },
  mainBlock: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&::before': {
      top: '10px',
      left: 'auto',
      right: '120px',
      width: '4px',
      height: '100%',
      content: "''",
      position: 'absolute',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      [theme.breakpoints.down('xs')]: {
        right: '90px',
      },
    },
  },
  leftBlock: {
    width: '70%',
    [theme.breakpoints.down('xs')]: {
      width: '60%',
    },
  },
  rightBlock: {
    width: '30%',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
    },
  },
  blockInput: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    marginLeft: '1%',
  },
  blockSlider: {
    height: '70px',
  },
  investInput: {
    height: '70px',
  },
  contributionSliderRow: {
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  contributionSlider: {
    position: 'relative',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    },
    '&::before': {
      top: '10px',
      left: 'auto',
      right: '130px',
      width: '4px',
      height: '100%',
      content: "''",
      position: 'absolute',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      '@media (max-width: 950px)': {
        right: 'calc(100% - 850px)',
      },
    },
  },
  sliderRightContribution: {
    position: 'relative',
    display: 'flex',
    width: '28%',
    marginLeft: '1%',
    [theme.breakpoints.down('xs')]: {
      width: '40%',
      marginLeft: '0%',
    },
  },
  quickSave: {
    position: 'absolute',
    right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    [theme.breakpoints.down('xs')]: {
      right: '85px',
    },
    '&:hover': {
      background: '#0d97ec !important',
    },
    '& img': {
      width: '37px',
      position: 'relative',
      top: '5px',
    }
  },
  quickSavePopupBtn: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        borderColor: '#e6e6e6',
        color: '#333',
        marginRight: '6px',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop: '5px',
        fontStyle: 'italic'
      }
    }
  },
  documentsMenu: {
    position: 'relative'
  },
  tabSectionNew: {
    position: 'relative',
  },
  btnGroupWorksheet: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 9
  },
  csaveScenario: {
    boxSizing: 'border-box',
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  // tableScroll:{
  //   overflow: 'scroll',
  //   overflowY: 'hidden',
  //   '& table':{
  //     border: '2px solid #000',
  //     borderCollapse: 'collapse',
  //   }
  // },
  dangerText: {
    color: '#bf0000 !important'
  },
  tabContentBaseIncome: {
    '& > div > div > div > div': {
      padding: '0',
    },
    '& > div > div': {
      overflow: 'initial !important',
    }
  },
  existingstudentloanFlex: {
    marginTop: '20px',
    border: '2px solid #0069aa',
    marginBottom: '20px',
    display: 'flex',
  },
  existingstudentloanGraphLeft: {
    borderRight: '2px solid #0069aa',
    paddingTop: '18px',
    background: '#e7f1f9',
    padding: '0px',
    flexGrow: '0',
    maxWidth: '25%',
    flexBasis: '25%',
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tr': {
        '& td': {
          paddingLeft: '10px',
          fontSize: '17px',
          paddingRight: '10px',
          color: '#333',
        }
      }
    }
  },
  existingstudentloanGraphRight: {
    flexGrow: '0',
    maxWidth: 'calc(75% - 30px)',
    flexBasis: 'calc(75% - 30px)',
    padding: '0px 15px 15px',
    '& h3': {
      fontWeight: '300',
      margin: '20px 0 10px',
      textAlign: 'center',
      lineHeight: '30px',
      color: '#333',
      fontSize: '22px',
    }
  },
  existingstudentInput: {
    position: 'relative',
    paddingLeft: '2px',
    height: '45px',
    background: '#fff',
    fontSize: '17px',
    display: 'flex',
    color: '#333',
    '& span': {
      position: 'absolute',
      left: '9px',
      top: '10px',
    },
    '& div': {
      width: '100%',
      height: '100%',
      border: '0px',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
      '& input': {
        padding: '0px 20px',
        border: '0px',
        fontSize: '17px',
      }
    }
  },
  h18: {
    height: '18px',
  },
  tdBorder: {
    height: 'calc(2px - 2px)',
    background: '#2f75b5',
  },
  inputRight: {
    '& span': {
      position: 'absolute',
      right: '15px',
      top: '10px',
      // position: 'absolute',
      left: 'initial',
    },
    '& div': {
      '& input': {
        fontSize: '16px',
        padding: '0px 10px',
        border: '0px',
      }
    }
  },
  potentialGrowthFull: {
    padding: '15px 15px',
    '& h3': {
      fontFamily: 'MuseoSans-500',
    }
  },
  blueLineNew: {
    margin: '10px 0px',
  },
  fieldText: {
    height: '45px',
    fontFamily: 'MuseoSans-500',
    padding: '0px 20px !important',
  },
  anchorFont: {
    '& a': {
      color: '#333 !important',
      textDecoration: 'none',
      borderBottom: '1px dashed #0069aa',
      '&:hover': {
        color: '#333 !important',
      }
    }
  },
  borderDividerMT30: {
    marginTop: '30px',
    marginBottom: '20px',
  },
  innnerFlowGroupMainFull: {
    '@media (max-width: 1199px)': {
      overflow: 'auto',
    }
  },
  innnerFlowGroupMain: {
    position: 'relative',
    paddingBottom: '0',
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '176px',
    },
  },
  innnerFlowGroupMaingrants: {
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    },
    '&::before': {
      right: '150px',
    },
  },
  innnerBorderNone: {
    '&::before': {
      display: 'none',
    },
  },
  innnerFlowGroup: {
    display: 'flex',
    position: 'relative',
    paddingBottom: '10px',
    alignItems: 'center',
  },
  innnerFlowGroupScholor: {
    marginBottom: '25px',
  },
  garphLeft: {
    width: '60%',
    '& h3': {
      marginBottom: '0',
    },
  },
  garphLeftScholor: {
    width: '77%',
  },
  AnnualIncomeGrowth: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '30px',
      marginTop: '30px',
      '& p': {
        margin: '0',
        minWidth: '220px',
        maxWidth: '220px',
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },

  },
  annuvalGrowthNew: {
    '& > div': {
      margin: '0',
    },
  },
  annuvalGrowthScholorScolorAndGrants: {
    '& > div': {
      alignItems: 'center',
      '& p': {
        maxWidth: '150px',
        minWidth: '150px',
        lineHeight: '26px',
        marginRight: '20px',
      },
      '& span': {
        // border: 'none !important',
      },
    },
  },
  annuvalGrowthLongTerm: {
    '& > div': {
      alignItems: 'center',
      '& p': {
        maxWidth: '250px',
        minWidth: '250px',
        lineHeight: '26px',
        marginRight: '20px',
      },
    },
  },
  pwisliderInputs: {
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    '& > span': {
      margin: '0',
      width: '95%',
    },
    '& p': {
      fontSize: '18px !important',
      textAlign: 'right',
      width: '100%',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
    },
  },
  garphRight: {
    display: 'flex',
    margin: '0 0 0 auto',
    width: '40%',
    paddingLeft: '0',
    '& h3': {
      marginBottom: '20px',
      marginTop: '0',
      fontSize: '18px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      whiteSpace: 'nowrap',
    },
    '& > div': {
      width: '50%',
      margin: '0 25px',
      position: 'relative',
    },
  },
  garphRightScholor: {
    paddingLeft: '0',
  },
  inputRightBlock: {
    // display: 'flex',
    fontSize: '18px',
    alignItems: 'center',
    // justifyContent: 'flex-end',
    padding: '0 10px',
    width: '90px',
    textAlign: 'right',
    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
      textAlign: 'right',
      '&:focus': {
        borderBottom: '1px solid #f89b22',
      },
    },
  },
  rightBoxNogap: {
    marginRight: '0 !important',
  },
  annuvalGrowthScholorBT0: {
    '& > div': {
      '& p': {
        '& span': {
          borderBottom: '0px dashed #0069aa',
        }
      }
    }
  },
  isummaryTableLeft: {
    paddingRight: '5px !important',
  },
  isummaryTableRight: {
    paddingLeft: '5px !important',
  },
  textCenter: {
    textAlign: 'center !important',
  },
  isummaryTablePlan: {
    '& table': {
      '& thead': {
        '& th': {
          fontSize: '18px',
        }
      },
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '19px',
          }
        }
      }
    }
  },
  isummaryTableInvestNew: {
    '& table': {
      '& thead': {
        '& th': {
          fontSize: '18px',
        }
      }
    }
  },
  tabButtonsHistoryInvestments: {
    '& button': {
      fontSize: '12px',
      padding: '8px 18px',
    }
  },
  tabButtonsHistoryInvestmentsNew: {
    '& button': {
      fontSize: '14px',
      padding: '8px 10px',
    }
  },
  tabsContentShortLong: {
    '& > div': {
      padding: '24px 0px',
    },
    '& > div > div > div > div': {
      padding: '0px',
    }
  },
  // tableScroll: {
  //   overflow: 'scroll',
  //   overflowY: 'hidden',
  // },
  historicaYearTable: {
    border: '2px solid #000',
    marginBottom: '5px',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    fontSize: '14px',
    lineHeight: '1.42857143',
    color: '#333',
    '& tr': {
      '& th': {
        textAlign: 'center',
        border: '1px solid #000',
      },
    },
    '& td': {
      textAlign: 'center',
      border: '1px solid #000',
      minWidth: '80px',
      fontSize: '12px',
      height: '55px',
    },
  },
  tableAllocation: {
    borderCollapse: 'collapse',
    borderSpacing: '0',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    color: '#333',
    '& thead': {
      '& tr': {
        '& th': {
          verticalAlign: 'bottom',
          borderBottom: '2px solid #ddd',
          padding: '8px',
          lineHeight: '1.42857143',
          backgroundColor: '#dae3f3',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
        }
      }
    },
    '& tbody': {
      '& tr': {
        backgroundColor: '#f2f2f2',
        '& td': {
          borderTop: '1px solid #fff',
          fontSize: '20px',
          verticalAlign: 'middle',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:first-child': {
            textAlign: 'left',
          },
          '&:last-child': {
            textAlign: 'right',
          },
          '& input': {
            marginRight: '5px',
            height: '30px',
            width: '150px',
            padding: '1px 2px !important',
            background: '#fff',
            border: '1px solid #8d8989',
            borderRadius: '3px',
            fontSize: '20px',
          },
          '& a': {
            transition: 'all 0.5s ease 0s',
            textDecoration: 'underline',
            color: '#337ab7',
            '&:hover': {
              color: '#f89b23',
              textDecoration: 'underline',
            }
          },
          '& div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            }
          }
        },
        '&:last-child': {
          '& td': {
            backgroundColor: '#dae3f3',
            borderTop: '2px solid #5686da',
            fontWeight: 'normal',
            fontFamily: 'MuseoSans-500',
          }
        }
      }
    }
  },
  mt0: {
    marginTop: '0px !important',
  },
  lefBlockPara: {
    textAlign: 'right',
    marginRight: '4%',
    fontSize: '18px',
    marginBottom: '0px !important',
    fontFamily: 'MuseoSans-500',
  },
  lefBlockCount: {
    fontFamily: 'MuseoSans-500',
    margin: '5px 0px 0px !important',
    fontSize: '18px',
    '& span': {
      fontSize: '18px',
    }
  },
  selectYear: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#333',
    fontWeight: 'normal',
    margin: '10px 0px 10px',
    fontFamily: 'MuseoSans-100',
    // fontWeight: 'normal',
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 20px',
      height: '29px',
      cursor: 'pointer',
      fontFamily: 'MuseoSans-100',
      fontWeight: 'normal',
      fontSize: '18px',
    }
  },
  tableSmoothScrool: {
    '@media (max-width: 1199px)': {
      overflow: 'auto !important',
    },
    '& > div': {
      position: 'initial !important',
    }
  },
  tabSectionScroll: {
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
    },
    '& > div': {
      '@media (max-width: 1199px)': {
        minWidth: '950px',
      }
    }
  },
  shortTermTableScroll: {
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
    },
  },
  tableAllocationScroll: {
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
    },
    '& table': {
      '@media (max-width: 1199px)': {
        minWidth: '950px',
      }
    }
  }
});

export default styles;
