import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-vacation/styles';
import * as modulesServices from '../../calculations/modules-services';

const VacationSummary = ( props ) => {
  const {
    classes
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Vacations and those special purchases are things worth planning for. Use the drop down in the table below and make sure you’re cash flow positive and reaching your savings targets.</p>
      <div className={ classes.summaryTable }>
        <table>
          <thead>
            <tr>
              <th colSpan="2">Vacation + Special Purchases Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Amount Spent</td>
              <td>
                {modulesServices.module11Data.vacationTotalAmountSpent !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module11Data.vacationTotalAmountSpent } prefix={ modulesServices.module11Data.vacationTotalAmountSpent >= 0 ? '$' : '($' } suffix={ modulesServices.module11Data.vacationTotalAmountSpent < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

VacationSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( VacationSummary );
