import financialBanner from '../../assets/img/financial-calculators/fc-banner.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  moduleIcons: {
    display: 'block',
    padding: '40px 0 30px',
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 0 30px',
    },
  },
  claculatorLinks: {
    padding: '15px 50px',
    margin: '0 -15px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px',
    },
  },
  financialQuickLinks: {
    textAlign: 'left',
    boxSizing: 'border-box',
    '& h3': {
      color: '#333333',
      float: 'left',
      padding: '8px 15px 5px',
      borderLeft: '3px solid #0069aa',
      width: '100%',
      margin: '20px 0 10px',
      fontSize: '24px',
      lineHeight: '1.42857143',
      fontFamily: '"MuseoSans-500"',
      fontWeight: '500',
      '@media (max-width: 767px)': {
        margin: '10px 0 10px',   
        fontSize: '18px',
      },
      '& a': {
        color: '#333333',
        transition: 'all 0.5s ease 0s',
        '&:hover': {
          color: '#f89b22',
          textDecoration: 'underline',
        },
      },
    },
  },
  financeBanner: {
    height: '50.8vh',
    minHeight: '380px',
    position: 'relative',   
    '@media (max-width: 1024px)': {
      height: '30vh',
      minHeight: '250px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      height: '29.8vh',
      minHeight: '200px',
    },
  },
  heroImage: {
    backgroundSize: 'cover',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundImage: `url(${financialBanner})`,
    backgroundRepeat: 'no-repeat',
    boxSizing: 'border-box',   
    '&::after': {
      position: 'absolute',
      top: '0',
      left: '0',
      content: '""',
      background: '0 0',
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
    },
  },
  bannerText: {
    top: '52.5%',
    bottom: 'auto',
    transform: 'translateY(-50%)',
    WebkitTransform: 'translateY(-50%)',
    zIndex: '99',
    textAlign: 'center',
    position: 'absolute',
    left: '0',
    right: '0',
    '@media (max-width: 767px)': {
      padding: '0px 15px',
    },
    '& h2': {
      fontSize: '54px',
      fontWeight: '300',
      paddingTop: '2.5vh',
      textTransform: 'inherit',
      lineHeight: '78px',
      color: theme.palette.common.white,
      margin: '0',
      fontFamily: '"MuseoSans-300"',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '40px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
      },
      '@media (max-width: 300px)': {
        fontSize: '28px',
      },
    },
    '& h4': {
      maxWidth: '925px',
      lineHeight: '40px',
      fontSize: '30px',
      paddingBottom: '1vh',
      paddingTop: '6vh',
      margin: '0 auto',
      color: '#fff',
      fontWeight: '500',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '23px',       
        whiteSpace: 'inherit',
      },
      '@media (max-width: 767px)': {
        fontSize: '20px', 
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
        lineHeight: '25px',
        padding: '4vw 2vw 1vw 2vw',
        whiteSpace: 'pre-wrap',
      },
    },
  },

} );

export default styles;
