import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/rei/styles';

const InvestmentProperty = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Welcome to the Real Estate Investment Property module, or REI for short.</p>
      <p>Enter the details about an investment property and the REI module will show you your total cash needed for purchase, monthly and annual cash flow, and your potential returns.</p>
      <p>Getting Started in the Real Estate Investment Property module:</p>
      <ul className={ classes.tickList }>
        <li>Discover how much real estate you can afford and when</li>
        <li>Compare different properties side-by-side</li>
        <li>Calculate how much cash is needed to buy the property</li>
        <li>Detail the monthly costs of owning real estate</li>
        <li>Run &quot;what if&quot; scenarios on purchase price, rental income, mortgage terms and other buying factors to see cash flow analysis and returns instantaneously.</li>
      </ul>
      <p>Buying an investment property requires proper due diligence, an investment strategy, and a plan built on details. With a plan in hand, you&apos;ll be confident in your decision. Let&apos;s get started.</p>
    </div>
  );
};

InvestmentProperty.propTypes = {
  classes: PropTypes.object.isRequired,

};

export default withStyles( styles )( InvestmentProperty );
