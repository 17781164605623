import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const MoneyResolutions = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Money Resolutions</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/new-year-plan.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Money Resolutions
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/new-year-plan-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          According to a{' '}
                          <a
                            href="https://www.fidelity.com/bin-public/060_www_fidelity_com/documents/about-fidelity/annual-financial-resolutions-factsheet.pdf"
                            target="_blank"
                            rel="noopener noreferrer">
                            recent Fidelity study,
                          </a>{' '}
                          67% of Americans are considering a financial
                          resolution for the new year versus 61% who made one
                          for 2019. The top three responses for those who are
                          considering a financial resolution aren&apos;t
                          surprising:
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p className={classes.savePercentage}>
                          <b>53%</b> want to save more
                        </p>
                        <p
                          className={classNames(
                            classes.savePercentage,
                            classes.textCenter,
                          )}>
                          <b>51%</b> want to pay down debt
                        </p>
                        <p
                          className={classNames(
                            classes.savePercentage,
                            classes.textRight,
                          )}>
                          <b>35% </b>
                          want to spend less
                        </p>
                        <p>
                          The biggest motivators for these resolutions? Living a
                          debt-free life was the number one motivator followed
                          closely by taking control of daily expenses. These are
                          great goals. But how do you actually make progress and
                          stick to your resolution? There&apos;s only one way -
                          you need a plan of attack!
                        </p>
                        <p>
                          As the French writer and pioneer in aviation de Saint
                          - Exupery once said, &quot;A goal without a plan is
                          just a wish.&quot;
                        </p>
                        <p>
                          Setting a money resolution for the New Year is a great
                          idea. But just saying you want to save more isn&apos;t
                          going to cut it. If you want to save more, you need a
                          plan. How exactly are you going to earn more or spend
                          less? Will you invest in a coffee machine and brew
                          your own coffee for $1 a day versus the average $3 to
                          $5 spent at coffee shops? Will you sharpen your skills
                          or learn new skills to boost your earnings? Combine
                          those two - save a little more and earn a little more
                          - and you&apos;ve got yourself a recipe for achieving
                          your New Year financial resolutions.
                        </p>
                        <p>
                          With a plan, you make things happen. The possibilities
                          are endless. Happy New Year (a little early, I know).
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
MoneyResolutions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MoneyResolutions);
