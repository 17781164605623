import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import car1 from '../../assets/img/life-event-library/buyingACar-slide1.jpg';
import car2 from '../../assets/img/life-event-library/buyingACar-slide2.jpg';

const LifeEventImageEleven = ( props ) => {
  const { classes } = props;

  const getStartYear = () => {
    props.openNoAccessPopup( true );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={ `${classes.containerNew} ${classes.eventElevenColor}` }>
      <div className={ classes.testimonilas }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Buying A Car
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Find Your Best Deal
                        </Typography>
                        <ul>
                          <li>Enter your purchase price, down payment and the value of any rebate, discount, trade-in</li>
                          <li>Specify your loan terms and rate; find the best scenario for you</li>
                          <li>Employ competitive market analysis</li>
                          <li>Perform diligent research so you know when you&apos;re getting a good deal.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Get Started</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ car1 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Buying A Car
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Check Spaces, Awareness
                        </Typography>
                        <ul>
                          <li>Consider what type of car is right for your situation, explore your options</li>
                          <li>Assess how your vehicle choice might affect your personal and professional priorities</li>
                          <li>Learn the basics of auto loans</li>
                          <li>Decide how much money to spend on a car as you maximize reward and maximize risk.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>See my spaces</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ car2 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

LifeEventImageEleven.propTypes = {
  classes: PropTypes.object.isRequired,
  openNoAccessPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LifeEventImageEleven );
