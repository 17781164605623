import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
} from '@material-ui/core';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import logoIcon from '../../../assets/img/financial-calculators/higher_education.png';
import styles from './styles';
import Pageloader from '../../../components/ui/pageloader';

const COMPARE_SCHOOL_DETAILS = loader('../../../graphql/schema/higher-education/getCompareSchoolDetails.graphql');
const COLLEGE_LIST = loader('../../../graphql/schema/higher-education/getCollegeList.graphql');

const CompareSchools = (props) => {
  const { classes, history } = props;

  const collegeObj = {
    act_average_scores: '--',
    admission_rate: '67%',
    after_federal_loans: '$19,159',
    avg_annual_cost: '$16,395 ',
    avg_federal_loans_amount: '$4,925',
    avg_gift_aid_amount: '$7,278',
    created_date: '2020-09-24 06:02:10',
    enrollment: '4,187',
    expected_monthly_payment: '$199',
    federal_loans_default_rate: '10.1%',
    first_federal_loans: '47.3%',
    first_gift_aid: '49%',
    freshman_retention_rate: '69%',
    future_earnings: '2.3x',
    graduation_rate: '45%',
    id: '2020',
    in_state_fee: '$16,917',
    location: '--',
    median_earnings: '$38,463',
    out_state_fee: '$20,194 ',
    range1: '$13,570',
    range2: '$14,121',
    range3: '$16,320',
    range4: '$18,965',
    range5: '$21,891',
    room_board: '$5,934 ',
    sat_averate_math_scores: '--',
    sat_averate_reading_scores: '--',
    school_name: 'All Colleges',
    school_type: 'college',
  };

  const careerSchoolObj = {
    act_average_scores: '--',
    admission_rate: '77%',
    after_federal_loans: '$9,864',
    avg_annual_cost: '$16,227',
    avg_federal_loans_amount: '$5,623',
    avg_gift_aid_amount: '$672',
    created_date: '2020-09-24 06:02:10',
    enrollment: '219',
    expected_monthly_payment: '$102',
    federal_loans_default_rate: '13.4%',
    first_federal_loans: '58.9%',
    first_gift_aid: '8.9%',
    freshman_retention_rate: '73%',
    future_earnings: '1.5x',
    graduation_rate: '69%',
    id: '2020',
    in_state_fee: '$16,411',
    location: '--',
    median_earnings: '$25,091',
    out_state_fee: '$16,411',
    range1: '$15,734',
    range2: '$16,703',
    range3: '$18,550',
    range4: '$20,745',
    range5: '$21,056',
    room_board: '$93',
    sat_averate_math_scores: '--',
    sat_averate_reading_scores: '--',
    school_name: 'All Career Schools',
    school_type: 'Career Schools',
  };

  const [hideSearch, setHideSearch] = useState(false);
  const [hideSearchCollege, setHideSearchCollege] = useState(false);
  const [search, setSearch] = useState('');
  const [searchCollege, setSearchCollege] = useState('');
  const [collegeSearchList, setCollegeSearchList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [typeOfHe, setTypeOfHE] = useState('college');

  const typeOfHeData = typeOfHe === 'college' ? collegeObj : careerSchoolObj;

  const { loading } = useQuery(COLLEGE_LIST, {
    variables: {
      education_type: 'college',
      order_column: '',
      order_type: '',
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      const list = _.sortBy(res && res.getCollegeList, 'school_name');
      setCollegeList(list);
    },
  });

  const { data: searchData } = useQuery(COMPARE_SCHOOL_DETAILS, {
    variables: {
      type: 'name',
      details: search,
      education_type: 'college',
    },
    skip: search === '',
    fetchPolicy: 'network-only',
  });

  const { data: otherSearchData } = useQuery(COMPARE_SCHOOL_DETAILS, {
    variables: {
      type: 'name',
      details: searchCollege,
      education_type: 'college',
    },
    fetchPolicy: 'network-only',
    skip: searchCollege === '',
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setHideSearch(e.target.value !== '');
    setHideSearchCollege(false);
    const dataFilter = collegeList.filter((user) => user.school_name.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
    setCollegeSearchList(dataFilter);
  };

  const handleSearchCollege = (e) => {
    setSearchCollege(e.target.value);
    setHideSearchCollege(e.target.value !== '');
    setHideSearch(false);
    const dataFilter = collegeList.filter((user) => user.school_name.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
    setCollegeSearchList(dataFilter);
  };

  const handleSearchSelect = (searchText) => {
    setSearch(searchText);
    setHideSearch(false);
  };

  const handleSearchSelectCollege = (searchSelect) => {
    setSearchCollege(searchSelect);
    setHideSearchCollege(false);
  };

  const redirectToHigherEducation = () => {
    history.push('/plan-with-inkwiry/he');
  };

  const handleTypeOfHE = (type) => {
    setTypeOfHE(type);
    setSearch('');
    setSearchCollege('');
  };

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.bondProfitPage}>
        <div className={classes.formHorizontal}>
          <div className={classes.container}>
            <ul className={classes.breadCrumbs}>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Compare Schools</li>
            </ul>
            <div className={classes.csBox}>
              <div className={classes.blueBorder}>
                <div className={classes.csBreadcrumbs}>
                  <div>
                    <h3 className={classes.csTitle}>Compare Schools Side-By-Side</h3>
                  </div>
                  <div className={classes.csLogo} onClick={redirectToHigherEducation} aria-hidden="true">
                    <h3>
                      <span>
                        <img src={logoIcon} alt="" />
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      Higher Education
                    </h3>
                  </div>
                </div>
                <div className={classes.mainContainer}>
                  <div className={classes.compareSchool}>
                    <h3>Use Inkwiry&apos;s comparison tool to explore thousands of schools and see how they stack up by comparing them side-by-side.</h3>
                    <div className={classes.typeOfHe}>
                      <div className={classes.labelHe}>Type of Higher Education:</div>
                      <div className={classes.radioWrapper}>
                        <div className={typeOfHe === 'college' && classes.activeClass} onClick={() => handleTypeOfHE('college')} aria-hidden="true">
                          College
                        </div>
                        <div className={typeOfHe === 'school' && classes.activeClass} onClick={() => handleTypeOfHE('school')} aria-hidden="true">
                          Career School
                        </div>
                      </div>
                    </div>
                    <div className={classes.selectSection}>
                      <div className={classes.titleOne} />
                      <div className={classes.titleTwo}>
                        <input type="text" placeholder="Type the school name here" value={search} onChange={handleSearch} />
                        {hideSearch && (
                          <div className={classes.searchList}>
                            <ul>
                              {collegeSearchList.length > 0 ? collegeSearchList.map((list) => <li aria-hidden="true" onClick={() => handleSearchSelect(list.school_name)}>{list.school_name}</li>) : <li>No data found</li>}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className={classes.titleFour}>&nbsp;</div>
                      <div className={classes.titleThree}>
                        <input type="text" placeholder="Type the school name here" value={searchCollege} onChange={handleSearchCollege} />
                        {hideSearchCollege && (
                          <div className={classes.searchList}>
                            <ul>
                              {collegeSearchList.length > 0 ? collegeSearchList.map((dataList) => <li aria-hidden="true" onClick={() => handleSearchSelectCollege(dataList.school_name)}>{dataList.school_name}</li>): <li>No data found</li>}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={classes.tableScrollHidden}>
                      <div className={classes.federalFlow}>

                        <table className={classes.tableStriped}>
                          <tbody>
                            <tr className={classes.schoolName}>
                              <td>&nbsp;</td>
                              <td>Name of School</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.school_name ? searchData.getCompareSchoolDetails.school_name : ''}</td>
                              <td className={classes.noWrap}>{typeOfHeData.school_name}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.school_name}</td>
                            </tr>
                            <tr className={classes.tableTopBorder}>
                              <td>&nbsp;</td>
                              <td>Location</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.location ? searchData.getCompareSchoolDetails.location : ''}</td>
                              <td>{typeOfHeData.location}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.location ? otherSearchData.getCompareSchoolDetails.location : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of students that got accepted to the school calculated by dividing the number of accepted students by the number of total applicants." /></td>
                              <td>Admission Rate</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.admission_rate ? searchData.getCompareSchoolDetails.admission_rate : ''}</td>
                              <td>{typeOfHeData.admission_rate}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.admission_rate ? otherSearchData.getCompareSchoolDetails.admission_rate : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Total number of undegraduate students" /></td>
                              <td>Enrollment</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.enrollment ? <NumberFormat value={searchData.getCompareSchoolDetails.enrollment} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.enrollment}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.enrollment ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.enrollment} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of students who graduated within 150% of the expected time to graduation. For example, for a 4-year degree, the graduation rate is calculated based on the number of students who graduated in six years. And, for a 2-year degree, the number of students who graduated in three years." /></td>
                              <td>Graduation Rate</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.graduation_rate ? searchData.getCompareSchoolDetails.graduation_rate : ''}</td>
                              <td>{typeOfHeData.graduation_rate}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.graduation_rate ? otherSearchData.getCompareSchoolDetails.graduation_rate : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of students who return after their first year." /></td>
                              <td>Freshman Retention Rate</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.freshman_retention_rate ? searchData.getCompareSchoolDetails.freshman_retention_rate : ''}</td>
                              <td>{typeOfHeData.freshman_retention_rate}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.freshman_retention_rate ? otherSearchData.getCompareSchoolDetails.freshman_retention_rate : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="50% of students attending this school scored in this range." /></td>
                              <td>SAT Average Math Scores</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.sat_averate_math_scores ? searchData.getCompareSchoolDetails.sat_averate_math_scores : ''}</td>
                              <td>{typeOfHeData.sat_averate_math_scores}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.sat_averate_math_scores ? otherSearchData.getCompareSchoolDetails.sat_averate_math_scores : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="50% of students attending this school scored in this range." /></td>
                              <td>SAT Average Reading Scores</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.sat_averate_reading_scores ? searchData.getCompareSchoolDetails.sat_averate_reading_scores : ''}</td>
                              <td>{typeOfHeData.sat_averate_reading_scores}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.sat_averate_reading_scores ? otherSearchData.getCompareSchoolDetails.sat_averate_reading_scores : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="50% of students attending this school scored in this range." /></td>
                              <td>ACT Average Scores</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.act_average_scores ? searchData.getCompareSchoolDetails.act_average_scores : ''}</td>
                              <td>{typeOfHeData.act_average_scores}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.act_average_scores ? otherSearchData.getCompareSchoolDetails.act_average_scores : ''}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>In-State Tuition & Fees</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.in_state_fee ? <NumberFormat value={searchData.getCompareSchoolDetails.in_state_fee} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.in_state_fee}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.in_state_fee ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.in_state_fee} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>Out-of-State Tuition & Fees</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.out_state_fee ? <NumberFormat value={searchData.getCompareSchoolDetails.out_state_fee} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.out_state_fee}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.out_state_fee ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.out_state_fee} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>Room & Board</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.room_board ? <NumberFormat value={searchData.getCompareSchoolDetails.room_board} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.room_board}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.room_board ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.room_board} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            </tr>
                            <tr>
                              <td>
                                <img
                                  src={infoIcon}
                                  alt=""
                                  data-for="compareSchool"
                                  data-tip="Calculated as Median Earnings divided by Average Annual Cost. What does that mean? This ratio shows you how much income you can expect to make 10 years after you start school with respect to how much you are investing in school each year.
                  This ratio is specific to Inkwiry and is a great comparative tool. For example, out of all the schools you're considering, which school has the best earnings to cost ratio?"
                                />
                              </td>
                              <td>Future Earnings / Average Cost</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.future_earnings ? searchData.getCompareSchoolDetails.future_earnings : ''}</td>
                              <td>{typeOfHeData.future_earnings}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.future_earnings ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.future_earnings} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The median earnings of students who received federal financial aid 10 years after entering the school. Important to note that this is not 10 years after graduation, but 10 years after the first year in school." /></td>
                              <td>Median Earnings (10 yrs after entry)</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.median_earnings ? <NumberFormat value={searchData.getCompareSchoolDetails.median_earnings} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.median_earnings}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.median_earnings ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.median_earnings} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            </tr>

                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for students who received federal financial aid. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                              <td>Average Annual Cost</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.avg_annual_cost ? <NumberFormat value={searchData.getCompareSchoolDetails.avg_annual_cost} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.avg_annual_cost}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.avg_annual_cost ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.avg_annual_cost} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td>Average Annual Cost by Family Income</td>
                              <td>--</td>
                              <td>--</td>
                              <td>--</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn less than $30,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                              <td className={classes.leftIndent}>$0 - $30,000</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.range1 ? searchData.getCompareSchoolDetails.range1 >= 0 ? <NumberFormat value={searchData.getCompareSchoolDetails.range1} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                              <td>{typeOfHeData.range1}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.range1 ? otherSearchData.getCompareSchoolDetails.range1 >= 0 ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.range1} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn between $30,000 and $48,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                              <td className={classes.leftIndent}>$30,001 - $48,000</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.range2 ? searchData.getCompareSchoolDetails.range2 >= 0 ? <NumberFormat value={searchData.getCompareSchoolDetails.range2} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                              <td>{typeOfHeData.range2}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.range2 ? otherSearchData.getCompareSchoolDetails.range2 >= 0 ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.range2} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn between $48,000 and $75,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                              <td className={classes.leftIndent}>$48,001 - $75,000</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.range3 ? searchData.getCompareSchoolDetails.range3 >= 0 ? <NumberFormat value={searchData.getCompareSchoolDetails.range3} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                              <td>{typeOfHeData.range3}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.range3 ? otherSearchData.getCompareSchoolDetails.range3 >= 0 ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.range3} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn between $75,000 and $110,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                              <td className={classes.leftIndent}>$75,001 - $110,000</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.range4 ? searchData.getCompareSchoolDetails.range4 >= 0 ? <NumberFormat value={searchData.getCompareSchoolDetails.range4} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                              <td>{typeOfHeData.range4}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.range4 ? otherSearchData.getCompareSchoolDetails.range4 >= 0 ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.range4} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The average net price for families who earn more than $110,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." /></td>
                              <td className={classes.leftIndent}>$110,001+</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.range5 ? searchData.getCompareSchoolDetails.range5 >= 0 ? <NumberFormat value={searchData.getCompareSchoolDetails.range5} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                              <td>{typeOfHeData.range5}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.range5 ? otherSearchData.getCompareSchoolDetails.range5 >= 0 ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.range5} displayType="text" thousandSeparator prefix="$" /> : 'N/A' : ''}</td>
                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of first-time undergraduate students who received some amount of scholarships and grants in their first year." /></td>
                              <td>Students Receiving Gift Aid from School</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.first_gift_aid ? searchData.getCompareSchoolDetails.first_gift_aid : ''}</td>
                              <td>{typeOfHeData.first_gift_aid}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.first_gift_aid ? otherSearchData.getCompareSchoolDetails.first_gift_aid : ''}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td className={classes.leftIndent}>Average Amount of Gift Aid</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.avg_gift_aid_amount ? <NumberFormat value={searchData.getCompareSchoolDetails.avg_gift_aid_amount} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.avg_gift_aid_amount}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.avg_gift_aid_amount ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.avg_gift_aid_amount} displayType="text" thousandSeparator prefix="$" /> : ''}</td>

                            </tr>

                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of first-time undergraduate students who received some amount of federal student loans in their first year." /></td>
                              <td>Students Receiving Federal Loans</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.first_federal_loans ? searchData.getCompareSchoolDetails.first_federal_loans : ''}</td>
                              <td>{typeOfHeData.first_federal_loans}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.first_federal_loans ? otherSearchData.getCompareSchoolDetails.first_federal_loans : ''}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                              <td className={classes.leftIndent}>Average Federal Loans in First Year</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.avg_federal_loans_amount ? <NumberFormat value={searchData.getCompareSchoolDetails.avg_federal_loans_amount} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.avg_federal_loans_amount}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.avg_federal_loans_amount ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.avg_federal_loans_amount} displayType="text" thousandSeparator prefix="$" /> : ''}</td>

                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The median amount of federal student loans after a student graduates. Please note that this does not include any private loans or parent loans." /></td>
                              <td>Federal Student Loans after Graduation</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.after_federal_loans ? <NumberFormat value={searchData.getCompareSchoolDetails.after_federal_loans} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.after_federal_loans}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.after_federal_loans ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.after_federal_loans} displayType="text" thousandSeparator prefix="$" /> : ''}</td>

                            </tr>
                            <tr>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The expected monthly payment if the above federal student loans amount was repaid over 10 years with a 5.05% interest rate." /></td>
                              <td className={classes.leftIndent}>Expected Monthly Payment</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.expected_monthly_payment ? <NumberFormat value={searchData.getCompareSchoolDetails.expected_monthly_payment} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                              <td>{typeOfHeData.expected_monthly_payment}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.expected_monthly_payment ? <NumberFormat value={otherSearchData.getCompareSchoolDetails.expected_monthly_payment} displayType="text" thousandSeparator prefix="$" /> : ''}</td>

                            </tr>
                            <tr className={classes.tableBottomBorder}>
                              <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="This figure represents the percentage of federal student loan borrowers that began repaying their student loans in 2015 and have defaulted on those loans in 2015, 2016, or 2017. Default means a student loan borrower has not made a payment in 360 days or more." /></td>
                              <td className={classes.leftIndent}>Federal Student Loan Default Rate</td>
                              <td>{searchData && searchData.getCompareSchoolDetails && searchData.getCompareSchoolDetails.federal_loans_default_rate ? searchData.getCompareSchoolDetails.federal_loans_default_rate : ''}</td>
                              <td>{typeOfHeData.federal_loans_default_rate}</td>
                              <td>{otherSearchData && otherSearchData.getCompareSchoolDetails && otherSearchData.getCompareSchoolDetails.federal_loans_default_rate ? otherSearchData.getCompareSchoolDetails.federal_loans_default_rate : ''}</td>
                            </tr>

                          </tbody>
                          <ReactTooltip id="compareSchool" place="top" effect="solid" className={classes.tooltipInfoTop} />
                        </table>
                      </div>
                    </div>
                    <div className={classes.bottomText}>
                      <p>
                        Additional Resources:&nbsp;
                        <a href="https://nces.ed.gov/collegenavigator/" target="_blank" rel="noopener noreferrer">College Navigator,</a>
                        {' '}
                        <a href="https://collegescorecard.ed.gov/" target="_blank" rel="noopener noreferrer">College Scorecard,</a>
                        {' '}
                        <a href="https://www.niche.com/colleges/search/best-colleges/" target="_blank" rel="noopener noreferrer">Best Colleges by Niche,</a>
                        {' '}
                        <a href="https://www.niche.com/colleges/search/best-trade-schools/" target="_blank" rel="noopener noreferrer">Best Career Schools by Niche</a>
                      </p>
                      <p>Source: IPEDS (Integrated Postsecondary Education Data System), NCES (National Center for Education Statistics), U.S. Department of Education, College Scorecard Data</p>
                      <p>Assumptions: Latest data approved and published by IPEDS is 2018-19. To provide up to date, estimated tuition and fees and average annual cost, Inkwiry has grown the 2018-19 figures by 4% each year to estimate 2019-20 tuition and fees and average annual cost. For room and board and median earnings, Inkwiry has grown the 2018-19 figures by 2% each year to estimate 2019-20 room and board and median earnings. Assume all other data shown is as of 2018-19.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

CompareSchools.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(CompareSchools);
