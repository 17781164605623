import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-existing/styles';

const ExistingStudentLoansSummary = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>That&apos;s it for existing student loans. Now you have a strategy and a plan to effectively manage your existing student loans! Give the details below one last look before clicking continue.</p>
      <div className={ classNames( classes.studentAnalytics, classes.loanSummary ) }>
        <table>
          <thead>
            <tr>
              <th colSpan="2">Existing Student Loans Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Total Beginning Balance</td>
              <td>
                {moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance * 12 } prefix={ moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryTotalBeginningBalance < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>Total Monthly Payment (Principal & Interest)</td>
              <td>
                {moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment * 12 } prefix={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryMonthlyPayment < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>Total Annual Paid (Principal & Interest)</td>
              <td>
                {moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment * 12 } prefix={ moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryTotalAnnualPayment < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>Total Interest</td>
              <td>
                {moduleServices.module18Data.existingStudentLoansSummaryTotalInterest !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryTotalInterest * 12 } prefix={ moduleServices.module18Data.existingStudentLoansSummaryTotalInterest >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryTotalInterest < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr className={ classes.blueTopBorder }>
              <th>Total Repayment Amount (Principal & Interest)</th>
              <th>
                {moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest * 12 } prefix={ moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingStudentLoansSummaryTotalPrincipalInterest < 0 && ')' } /> ) : ( '$0' )}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

ExistingStudentLoansSummary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( ExistingStudentLoansSummary );
