const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  inkwiryBenfitsBannerOne: {
    position: 'relative',
    textAlign: 'center',
    padding: '0',
    '& img': {
      width: '100%',
      display: 'flex',
    },
    '& h3': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      margin: 'auto',
      left: '0',
      right: '0',
      zIndex: '999',
      letterSpacing: '1.3px',
      color: theme.palette.common.white,
      fontSize: '53px',
      background: ' rgba(0, 105, 170,0.7)',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'normal',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& h3': {
        fontSize: '25px',
      },
    },
  },
  benfitsContent: {
    textAlign: 'center',
    padding: '60px 0',
    '& h2': {
      padding: ' 0 0 38px 0',
      fontSize: '48px',
      lineHeight: '48px',
      fontFamily: 'MuseoSans-100',
      fontWeight: 'normal',
      color: theme.palette.common.blue,
    },
    '& h3': {
      fontSize: '36px',
      padding: ' 0 0 0 0',
      lineHeight: '48px',
      fontFamily: 'MuseoSans-100',
      fontWeight: 'normal',
      color: theme.palette.common.blue,
      '& span': {
        display: 'block',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 0',
      '& h2': {
        fontSize: '30px',
        lineHeight: '35px',
        padding: ' 0 0 20px 0',
      },
      '& h3': {
        fontSize: '25px',
        lineHeight: '35px',
      },
    },
  },
  benfitBox: {
    padding: '15px 20px',
    cursor: 'pointer',
    '& figure': {
      margin: '0',
      '& img': {
        width: '100%',
        transition: 'ease-in-out 0.3s all',
        transform: 'none',
      },
      '&:hover': {
        '& img': {
          transform: 'scale(1.08)',
        },
      },
    },

  },
  showBenfit: {
    textAlign: 'center',
    paddingBottom: '60px',
    '& > div > div': {
      justifyContent: 'center',
    },
    [theme.breakpoints.down( 'xs' )]: {
      paddingBottom: '30px',
    },
  },
  galleryRow: {
    width: '100%',
    display: 'inline-block',
    '& > div': {
      margin: '0px auto',
      maxWidth: '1170px',
      '& > div': {
        width: '348px',
        padding: '0 19px 20px 19px !important',
        background: 'transparent !important',
        height: '196px',
        '@media (max-width: 399px)': {
          width: '100%',
          height: '100%',
          padding: '0px !important',
        },
        '@media (min-width: 400px) and (max-width: 1217px)': {
          width: 'calc(50% - 42px)',
          height: '100%',
        },
        '& div': {
          height: '100% !important',
          width: '100% !important',
          overflow: 'visible !important',
          '& img': {
            width: '100% !important',
            height: 'auto !important',
            transition: 'all 0.3s ease-in-out',
            WebkitTransition: 'all 0.3s ease-in-out',
            MozTransition: 'all 0.3s ease-in-out',
            MsTransition: 'all 0.3s ease-in-out',
            OTransition: 'all 0.3s ease-in-out',
            transform: 'scale(1, 1)',
            WebkitTransform: 'scale(1, 1)',
            MozTransform: 'scale(1, 1)',
            MsTransform: 'scale(1, 1)',
            OTransform: 'scale(1, 1)',
            marginLeft: '0px !important',
          },
        },
        '&:hover': {
          '& img': {
            transform: 'scale(1.1, 1.1)',
            WebkitTransform: 'scale(1.1, 1.1)',
            MozTransform: 'scale(1.1, 1.1)',
            MsTransform: 'scale(1.1, 1.1)',
            OTransform: 'scale(1.1, 1.1)',
          },
        },
      },
    },
  },
  galleyPopupBox: {
    display: 'block',
  },
} );

export default styles;
