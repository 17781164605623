import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Link,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import Pageloader from '../../../components/ui/pageloader';
import { withStyles } from '@material-ui/core/styles';
import homeImg from '../../../assets/img/pwi-home/buy_home.png';
import LogoImage from '../../../assets/img/logo.png';
import styles from './styles';
import ClearTime from '../../../assets/img/pwi-investments/clear-timeline-blue.png';
import Walk from '../../../assets/img/pwi-investments/walk.png';
import BuyingHomeModule from '../../../components/pwi/buying-a-home/home-buying-home-module';
import AffordabilityCalculator from '../../../components/pwi/buying-a-home/home-affordability-Calculator';
import ComparativeMarketAnalysis from '../../../components/pwi/buying-a-home/home-comparative-market-analysis';
import PurchasePriceDownPayment from '../../../components/pwi/buying-a-home/home-purchase-rice-and-down-payment';
import Mortgage from '../../../components/pwi/buying-a-home/home-mortgage';
import LoanPoints from '../../../components/pwi/buying-a-home/home-loan-points';
import PrivateMortgageInsurance from '../../../components/pwi/buying-a-home/home-private-mortgage-insurance';
import TransferTaxesTitleInsurance from '../../../components/pwi/buying-a-home/home-transfer-taxes-title-insurance';
import PropertyTaxes from '../../../components/pwi/buying-a-home/home-property-taxes';
import HomeownersInsurance from '../../../components/pwi/buying-a-home/home-homeowners-insurance';
import MaintenanceFeesUtilities from '../../../components/pwi/buying-a-home/home-maintenance-association-fees-and-utilities';
import ClosingCosts from '../../../components/pwi/buying-a-home/home-closing-costs';
import FitOutCosts from '../../../components/pwi/buying-a-home/home-fit-out-costs';
import Appreciation from '../../../components/pwi/buying-a-home/home-appreciation';
import Summary from '../../../components/pwi/buying-a-home/home- summary';
import HomeBottom from '../../../components/pwi/buying-a-home/home-bottom';
import PwiSave from '../../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../../components/common/save-popup-success';
import DefinationPopup from '../../../components/ui/pwi-sidebar/defination-popup';
import BuyingAHomeWorkSheet from '../../../components/documents/worksheet-tables/buying-a-home-worksheet';
import PwiLeftMenu from '../../../components/pwi/pwi-left-menu';
import WalkThruOne from '../wallk-thru/walkThruOne';
import WalkThruTwo from '../wallk-thru/walkThruTwo';
import WalkThruThree from '../wallk-thru/walkThruThree';
import WalkThruFour from '../wallk-thru/walkThruFour';
import WalkThruFive from '../wallk-thru/walkThruFive';
import WalkThruSix from '../wallk-thru/walkThruSix';
import WalkThruSeven from '../wallk-thru/walkThruSeven';
import WalkThruEight from '../wallk-thru/walkThruEight';
import * as modulesServices from '../../../calculations/modules-services';
import LeaveDialogPopup from '../../../components/pwi/pwi-left-menu/leave-dialog-popup';
import SimulatorPanel from '../../../components/documents/simulator-panel';
import SummaryDocs from '../../../components/documents/documents';
import * as timeLineActions from '../../../calculations/time-line';
import * as moduleServices from '../../../calculations/modules-services';
import * as pwiObject from '../../../utilities/pwiObjectFormat';
import { initializeExcelSheetsData, workSheetCalc } from '../../../calculations';
import messages from '../../../utilities/pwi-popup-messages';
import Sidebar from './sidebar';
import save from '../../../assets/img/build-career-sketch/icon-save-blue.png';

const SAVESCENARIO = loader('../../../graphql/schema/pwi/save.graphql');
const PWI_DEFINATION = loader('../../../graphql/schema/financial-independence/getPWiDefination.graphql');
const MODULE_DATA = loader('../../../graphql/schema/fc/moduleData.graphql');
const CHECK_MODULE_EXISTS = loader('../../../graphql/schema/fc/check-module-exists.graphql');

const titleText = [
  'Getting Started in the Buying a Home module',
  'Affordability Calculator',
  'Comparative Market Analysis (CMA)',
  'Purchase Price and Down Payment',
  'Mortgage',
  'Loan Points',
  'Mortgage Summary',
  'Private Mortgage Insurance (PMI)',
  'Transfer Taxes & Title Insurance',
  'Property Taxes',
  "Homeowner's Insurance",
  'Maintenance, Association Fees (HOA), and Utilities',
  'Closing Costs',
  'Fit Out Costs',
  'Appreciation',
  'Summary',
];

const PwiBuyingHome = (props) => {
  const { classes, history } = props;
  let bodySection = useRef(null);

  const defaultInputDetails = {
    cmaData: [],
  };

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [saveKey, setSaveKey] = useState('');
  const [popupClose, setPopupClose] = useState(false);
  const [definationPopup, setDefinationPopup] = useState(false);
  const [titleDefination, setTitleDefination] = useState('');
  const [inputData, setInputData] = React.useState(defaultInputDetails);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [completedSlides, setCompletedSlides] = useState({ 1: true });
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: true });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [percentageFirstVal, setPercentageFirstVal] = useState(0);
  const [percentageSecondVal, setPercentageSecondVal] = useState(0);
  const [clearModule, setClearModule] = React.useState(false);
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [summaryYear, setSummaryYear] = React.useState('');
  const [openWorkSheet, setOpenWorkSheet] = React.useState(false);
  const [loadValue, setLoadValue] = React.useState(false);

  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module13' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 1; c <= 16; c += 1) {
            completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          let responseData = data;
          responseData.affordability_home_value = (data.purchase_price !== undefined && data.purchase_price !== null ) ? data.purchase_price : 400000;
          responseData.affordability_interest_rate = 4.00;
          responseData.affordability_debt_to_income = 36;
          responseData.other_loan_monthly = 0;
          setInputData(responseData);
        } else if(moduleServices.module1Data.start_year <= 0){
          const popupdetails = messages('No Fi Module','Buying a home');
          let saveContinueBtn = true;
          setBeforeLeavePopup({...{popupStatus: true,type:'confirm',no_pwi_fi: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        } else {
          let completedSlide = {};
          for (let c = 1; c <= 16; c += 1) {
            completedSlide[c] = false;
          }
          setCompletedSlides(completedSlide);
          setInputData({});
          setActiveScenarioData({});
        }
        setActiveStep(1);
        setLoading(false);
      });
    },
    onError() {
      let completedSlide = {};
      for (let c = 1; c <= 16; c += 1) {
        completedSlide[c] = false;
      }
      setCompletedSlides(completedSlide);
      setClearModule(false);
      timeLineActions.assignModulesData('', (data) => {
        if(moduleServices.module1Data.start_year <= 0) {
          const popupdetails = messages('No Fi Module','Buying a home');
          let saveContinueBtn = true;
          setBeforeLeavePopup({...{popupStatus: true,type:'no_pwi_fi',url: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        }
        if (data !== '') {
          setInputData({});
        }
        setActiveStep(1);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });

  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module13',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/buying-a-home' : url, saveContinueBtn }, ...popupdetails })
  }

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  }

  useEffect(() => {
    let progressBar = (activeStep) * (100 / 16);
    progressBar = progressBar.toFixed(0);
    setProgress(progressBar);
  }, [activeStep]);


  const getSliderIncomeGraph = () => {
    const finalMonthlyAfterTaxIncome =  modulesServices.homeTotalIncomeinYear();
    const finalMonthlyHomeExpenses = modulesServices.totalMonthlyExpenses();
    if (finalMonthlyAfterTaxIncome === 0 && finalMonthlyHomeExpenses === 0) {
      setPercentageFirstVal(0);
      setPercentageSecondVal(0);
    } else if (finalMonthlyAfterTaxIncome === 0) {
      setPercentageFirstVal(0);
      setPercentageSecondVal(100);
    } else {
      let percentageValue = 0;
      let percentageValue1 = 100;
      percentageValue = (finalMonthlyHomeExpenses / finalMonthlyAfterTaxIncome) * 100;
      if (percentageValue >= 100) {
        percentageValue1 = (percentageValue1 / percentageValue) * 100;
        percentageValue = 100;
      }
      setPercentageFirstVal(percentageValue);
      setPercentageSecondVal(percentageValue1);
    }
  };

  useEffect(()=>{
    getSliderIncomeGraph();
  },[inputData])

  const updateInputValue = (field, value) => {
    getSliderIncomeGraph();
    let inputDetails = { ...inputData };
    if(field === 'total_obj') {
      inputDetails = value;
    } else {
      inputDetails[field] = value;
    }
    modulesServices.setModule13Data(inputDetails);
    setInputData(inputDetails);
  };

  const handleSlide = (slide) => {
    const completedSlide = { ...completedSlides };
    completedSlide[slide] = true;
    setCompletedSlides(completedSlide);
    window.scrollTo(0, 0);
    setActiveStep(slide);
    setMenu(false);
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleMainMenu = () => {
    setMainMenu(!mainMenu);
  };

  const handleHomeSave = (data) => {
    const objectParams = inputData;
    if (data === 'save') {
      objectParams.start_year = (inputData.year_of_purchase !== undefined) ? inputData.year_of_purchase : '';
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = 'module13';
      objectParams.moduleNumber = 'module13Data';
      setmoduleObjectParams(objectParams);
    }
    setSaveKey(data);
    setPopupClose(true);
    setMenu(false);
    setMainMenu(false);
  };

  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleHomeSave('save');
  }

  const handlePopUpClose = () => {
    setPopupClose(false);
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };

  const handleDefinationPopup = (def) => {
    setTitleDefination(def);
    setDefinationPopup(true);
  };

  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 20,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 3 || slide === 4) {
      setMenu(true);
    } else if (slide === 5) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module13";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module13', 'quick-save', activeScenarioData);
      finalObject['module13Data'] = pwiObject.getfilteredBuyingHomeObject(moduleServices.module13Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>
        {loading && <Pageloader loading={loading} />}
        <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleSlide} openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={setMenu} completedSlides={completedSlides} />
        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={mainMenu === true ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={homeImg} alt="" aria-hidden="true" onClick={handleMainMenu} />
                <h3>Buying a Home</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" className={classes.menuIcon} onClick={handleMenu}>
                  <span />
                  <span />
                  <span />
                </div>
                <h3>{titleText[activeStep - 1]}</h3>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''}>
                <img src={Walk} alt="" data-for="walk" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>            
            {(activeStep === 1) && (
              <BuyingHomeModule />
            )}
            {(activeStep === 2) && (
              <AffordabilityCalculator handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 3) && (
              <ComparativeMarketAnalysis inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 4) && (
              <PurchasePriceDownPayment setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 5) && (
              <Mortgage percentageFirstVal={percentageFirstVal} percentageSecondVal={percentageSecondVal} slideType="mortgage" handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 6) && (
              <LoanPoints handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 7) && (
              <Mortgage percentageFirstVal={percentageFirstVal} percentageSecondVal={percentageSecondVal} slideType="summary" handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 8) && (
              <PrivateMortgageInsurance percentageFirstVal={percentageFirstVal} percentageSecondVal={percentageSecondVal} handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 9) && (
              <TransferTaxesTitleInsurance handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 10) && (
              <PropertyTaxes percentageFirstVal={percentageFirstVal} percentageSecondVal={percentageSecondVal} handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 11) && (
              <HomeownersInsurance percentageFirstVal={percentageFirstVal} percentageSecondVal={percentageSecondVal} handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 12) && (
              <MaintenanceFeesUtilities percentageFirstVal={percentageFirstVal} percentageSecondVal={percentageSecondVal} handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 13) && (
              <ClosingCosts inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 14) && (
              <FitOutCosts inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 15) && (
              <Appreciation handleDefinationPopup={handleDefinationPopup} inputData={inputData} updateData={updateInputValue} />
            )}
            {(activeStep === 16) && (
              <Summary />
            )}

            {definationResult && definationResult.getPWiDefination
              && (
                <DefinationPopup
                  definationPopup={definationPopup}
                  handleDefinationPopupClose={handleDefinationPopupClose}
                  getPWiDefination={definationResult.getPWiDefination}
                />
              )}
          </div>


          {
            activeStep >= 5 && activeStep !== 6 && (
              <div>
                {activeStep !== 16 && (<div className={classes.borderDivider}>&nbsp;</div>)}
                <HomeBottom setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} handleSlide={handleSlide} activeStep={activeStep} inputData={inputData} summaryYear={summaryYear} setSummaryYear={setSummaryYear} />
              </div>
            )
          }
          <div className={classes.btnGroup}>
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="home" />)}
            {activeStep === 1 ? '' : <Button className={classes.cancel} onClick={() => handleSlide(activeStep - 1, 'back')}>Back</Button>}
            <Button className={classes.clearBtn} onClick={clearEntireScenario} aria-hidden="true" data-for="walk" data-tip="Clear your scenario and start fresh."><img src={ClearTime} alt="" /></Button>
            
            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <React.Fragment>
                <ReactTooltip id="quicksaveBtntoolTip" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.tooltipInfoTopSave ) }/>
                <Button onClick ={handleQuickSave} className = {classes.quickSave}><span><img src={ save } alt="" data-for="quicksaveBtntoolTip" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page."/></span></Button>
              </React.Fragment>
            )}

            {activeStep === 16
              ? (
                <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleHomeSave('save'); }}>Save</Button>

              ) : <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => handleSlide(activeStep + 1, 'next')}>Next</Button>}
          </div>
          <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />

          {(activeStep === 2) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Assumptions: Affordability is calculated with the following details: 20% down payment, 3.5% closing costs, 3% fit out costs,
                  80% mortgage value, 4.00% interest rate, 30 years term of the mortgage, property taxes at 1.5%, homeowner&apos;s insurance at 0.35%, and a 36%
                  debt-to-income ratio.
              </i>
              </p>
            </div>
          )}
          {(activeStep === 3) && (
            <div className={classes.bottomText}>
              <p><i>Quick Links: Search for homes on Zillow, your area on ADT&apos;s crime map, and your school on Niche.</i></p>
              <p>
                <i>
                  Zillow:
                {' '}
                  <a rel="noopener noreferrer" href="https://www.zillow.com/" target="_blank">https://www.zillow.com/</a>
                  <br />
                  ADT&apos;s crime map::
                {' '}
                  <a rel="noopener noreferrer" href="https://www.adt.com/crime" target="_blank">https://www.adt.com/crime</a>
                  <br />
                  Niche::
                {' '}
                  <a rel="noopener noreferrer" href="https://www.niche.com/" target="_blank">https://www.niche.com/</a>
                  <br />
                </i>
              </p>
            </div>
          )}

        </div>
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={saveKey === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={saveKey === 'clear' ? classes.clearModule : ''}>
              {saveKey === 'save' && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={handlePopUpClose}>
                      ×
                  </span>
                  </div>
                  <PwiSave setPopupOpen={setPopupClose} moduleParams={moduleObjectParams} getSaveData={inputData} setLoadValue={setLoadValue}/>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module13' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      {openWorkSheet === true && <BuyingAHomeWorkSheet closeWorkSheet={setOpenWorkSheet} openWorkSheet={openWorkSheet} />}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
          <SavePopupSucess setLoadValue={setLoadValue}/>
           </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};

PwiBuyingHome.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
)
export default enhance(PwiBuyingHome);
