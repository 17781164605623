import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Button, TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import styles from '../../../screens/pwi-career-advancement/styles';
import { FV } from '../../../utilities/commonFunctions';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

const BuildingBudget = (props) => {
  const {
    classes, handleCareerObject, handleUpdatedObject,
  } = props;
  const [monthlySavings, setMonthlySavings] = React.useState(handleCareerObject.monthly_savings === undefined ? 500 : handleCareerObject.monthly_savings);
  const [annualReturn, setAnnualReturn] = React.useState(handleCareerObject.return === undefined ? 5 : handleCareerObject.return);
  const [fifthValue, setFifthValue] = React.useState(0);
  const [tenthValue, setTenthValue] = React.useState(0);
  const [fifteenthValue, setFifteenthValue] = React.useState(0);
  const [annualReturnGraphData, setAnnualReturnGraphData] = React.useState({});
  const [begningBal, setBeginingBal] = React.useState(handleCareerObject.beginningBalance === undefined ? 10000 : handleCareerObject.beginningBalance);
  const [isFocus, setIsFocus] = React.useState('');

  const updateInputValue = (e, inputType) => {
    let value = e.floatValue !== undefined ? e.floatValue : 0;
    if (inputType === 'savings') {
      setMonthlySavings(value);
    } else if (inputType === 'balance') {
      setBeginingBal(value);
    }else {
      if (value > 100) {
        value = 100;
      }
      setAnnualReturn(value);
    }
  };

  const updateGraph = () => {
    const graphArray = [];
    const graphArray1 = [];
    const graphArray2 = [];
    const graphArrayYears = [];
    const secondReturn = annualReturn / 2;
    const thirdReturn = annualReturn + secondReturn;
    for (let y = 1; y <= 30; y += 1) {
      const endingValue = FV((annualReturn / 12) / 100, y * 12, -monthlySavings, -begningBal, 0, 0);
      graphArray.push(endingValue);
      if (y === 5) {
        setFifthValue(endingValue);
      }
      if (y === 10) {
        setTenthValue(endingValue);
      }
      if (y === 15) {
        setFifteenthValue(endingValue);
      }
      const endingValue1 = FV((secondReturn / 12) / 100, y * 12, -monthlySavings, -begningBal, 0, 0);
      graphArray1.push(endingValue1);
      const endingValue2 = FV((thirdReturn / 12) / 100, y * 12, -monthlySavings, -begningBal, 0, 0);
      graphArray2.push(endingValue2);

      graphArrayYears.push(y);
    }
    setAnnualReturnGraphData(
      {
        list: graphArrayYears,
        data: [{
          name: `${parseFloat(thirdReturn).toFixed(2)}% return`,
          data: graphArray2,
          color: '#4472C4',
        }, {
          name: `${parseFloat(annualReturn).toFixed(2)}% return`,
          data: graphArray,
          color: '#Ed7D31',
        }, {
          name: `${parseFloat(secondReturn).toFixed(2)}% return`,
          data: graphArray1,
          color: '#A5A5A5',
        }],
      },
    );
  };

  useEffect(() => {
    updateGraph();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const powerObj = { ...handleCareerObject };
    if (handleCareerObject.monthly_savings === undefined) {
      powerObj.monthly_savings = 500;
    }
    if (handleCareerObject.return === undefined) {
      powerObj.return = 5;
    }
    handleUpdatedObject(powerObj);
    // eslint-disable-next-line
  }, []);
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>
      <p>A budget is a crucial tool for taking charge of your finances and building wealth. Want to save for graduate school, pay down debt, invest, or buy a home? Build a budget so you can see exactly where you are spending, how you can save more, and make smarter choices about your money.</p>
      <p>With a budget, which is just one part of a solid plan, you stop guessing and strengthen your chances of achieving your goals.</p>
      <p>Let&apos;s start building your budget for this career move. But before we do, use the calculator below to see how saving money each month can add up over time.</p>
      <div className={classes.potentialGrowth}>
        <Typography variant="h3" component="h3">Potential Savings Growth</Typography>
        <hr className={classes.blueLine} />
        <div className={classes.potentialInputs}>
          <div className={classes.careerInput}>
            <label htmlFor="month">
              {' '}
              Beginning Balance:
            </label>
            <div className={classes.inputGroup}>
              <span className={classes.dollarSymbol}>$</span>
              <NumberFormat id="beginning" decimalScale={0} fixedDecimalScale className={classNames(classes.formInput, classes.annlInput)} customInput={TextField} thousandSeparator value={begningBal} onValueChange={(e) => updateInputValue(e, 'balance', 'beginning_balance')} onFocus={(e) => e.target.select()} />
            </div>
          </div>
          <div className={classes.careerInput}>
            <label htmlFor="month">
              {' '}
              Monthly Savings:
              <div className={classes.inputGroup}>
                <span className={classes.dollarSymbol}>$</span>
                <NumberFormat id="annual" decimalScale={0} fixedDecimalScale className={classNames(classes.formInput, classes.annlInput)} customInput={TextField} thousandSeparator value={monthlySavings} onValueChange={(e) => updateInputValue(e, 'savings', 'monthly_savings')} onFocus={(e) => e.target.select()} />
              </div>
            </label>
          </div>
          <div className={classes.careerInput}>
            <label htmlFor="annual">
              {' '}
              Annual Return
              <div className={classes.inputGroup}>
                <NumberFormat
                  id="annual"
                  className={classNames(classes.formInput, classes.returnInput)}
                  customInput={TextField}
                  thousandSeparator
                  value={annualReturn}
                  decimalScale={isFocus!=='return'?2:5}
                  fixedDecimalScale={isFocus!=='return'}
                  onValueChange={(e) => updateInputValue(e, 'return', 'return')}
                  onFocus={(e) => { e.target.select(); setIsFocus('return') }}
                  onBlur={emptyIsFocus} />
                <span className={classNames(classes.percentSymbol, classes.percentPadding)}>%</span>
              </div>
            </label>
          </div>
          <Button variant="contained" onClick={updateGraph} className={classes.calculateButton}>
            Calculate
          </Button>
          <div className={classes.netWorth}>
            <ul>
              <li>
                {' '}
                <p>
                  {' '}
                  <span>5 Years</span>
                  {' '}
                  <font><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={fifthValue} prefix="$" /></font>
                  {' '}
                </p>
                {' '}
              </li>
              <li>
                {' '}
                <p>
                  {' '}
                  <span>10 Years</span>
                  {' '}
                  <font><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={tenthValue} prefix="$" /></font>
                  {' '}
                </p>
                {' '}
              </li>
              <li>
                {' '}
                <p>
                  {' '}
                  <span>15 Years</span>
                  {' '}
                  <font><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={fifteenthValue} prefix="$" /></font>
                  {' '}
                </p>
                {' '}
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.graphImage}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'spline',
                scrollablePlotArea: {
                  width: 600,
                  scrollPositionX: 0,
                },
              },
              credits: {
                enabled: false,
              },
              title: {
                text: '',
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              exporting: {
                enabled: false,
              },
              xAxis: [{
                tickInterval: 1,
                categories: annualReturnGraphData.list,
                labels: {
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                title: {
                  text: 'Years',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
              }],
              yAxis: [{
                labels: {
                  formatter() {
                    const chart = this;
                    if (chart.value < 0) {
                      return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                    }
                    return formatDollar(Math.round(chart.value));
                  },
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
                title: {
                  text: '',
                  style: {
                    color: '#000000',
                    fontSize: '14px',
                  },
                },
              }],
              tooltip: {
                headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
                crosshairs: true,
                shared: true,
                valueDecimals: 0,
                valuePrefix: '$',
                style: {
                  color: '#000000',
                  fontSize: '13px',
                },
              },
              plotOptions: {
                spline: {
                  lineWidth: 2,
                  states: {
                    hover: {
                      lineWidth: 2,
                    },
                  },
                  marker: {
                    enabled: false,
                  },

                },
              },
              legend: {
                itemStyle: {
                  fontSize: '14px',
                },
              },
              series: annualReturnGraphData.data,
            }}
          />
        </div>
      </div>
    </div>
  );
};

BuildingBudget.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
};

export default withStyles(styles)(BuildingBudget);
