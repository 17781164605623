import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const LeasingBuyingCar = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Leasing vs Buying a Car</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/buy-vs-lease-car.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Leasing vs Buying a Car
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/lease-vs-buy-car-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          Whether you&apos;re buying your first car or your
                          fifth car, adding a vehicle to your life is a big
                          financial decision. Car shopping is the easy part.
                          Paying for the vehicle upfront and managing the
                          monthly expenses over time is the part that requires
                          good planning and a little bit of car knowledge.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          There are two options when it comes to driving a car:
                          leasing and buying. When you lease a car, you&apos;re
                          renting the vehicle from the owner, which is usually a
                          car dealership. That{' '}
                          <Link
                            to="/finance-explained/car-lease"
                            target="_blank">
                            lease
                          </Link>{' '}
                          comes with a required monthly payment and strict rules
                          such as an annual mileage limit and other maintenance
                          conditions. When you lease a car, you will always have
                          a monthly payment.
                        </p>
                        <p>
                          When you buy the vehicle, no one can tell you how many
                          miles to drive or tell you not to put new wheels on
                          your car. It&apos;s your car! If you finance the
                          purchase, which means you pay for some of the vehicle
                          in cash and pay for the other portion with a car loan,
                          you will also have a monthly payment. But, when you
                          repay the car loan in full, that monthly payment goes
                          away.
                        </p>
                        <p>
                          Check out the table below highlighting the major pros
                          and cons of each option.
                        </p>
                        <div className={classes.pfMargin}>
                          <div className={classes.pfImage}>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/Lease-vs-Buy-Pros-and-Consv2-needed.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
LeasingBuyingCar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeasingBuyingCar);
