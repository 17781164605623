import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';
import NumberFormat from 'react-number-format';
import * as moduleServices from '../../../calculations/modules-services';

const StudentLoanRepayment = ( props ) => {
  const {
    classes
  } = props;

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      <p>How do I know if I am taking too much in student loans? Great question! As a benchmark, your total student loan payments in one full year should not be more than 12.5% of your total gross income after graduation.</p>
      <p>See below for how much income this rule would suggest you need to make in your first career move after graduation to support your student loan payments.</p>
      <div className={ `${classes.giftSources} ${classes.giftSourcesRepayment}` }>
        <table cellPadding="0" cellSpacing="0" className={ classes.studentLoansAnalysisTable }>
          <thead>
            <th colSpan="2">Income Needed to Support Student Loans Analysis</th>
          </thead>
          <tbody>
            <tr>
              <td>
                Total Student Loans (incl. Existing Student Loans)
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module15Data.newHigherTotalStudentLoanBalance } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Total Monthly Payment (incl. Existing Student Loans)
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module15Data.newHigherTotalMonthlyPayment } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Total Annual Paid
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module15Data.newHigherTotalAnnualPaymentPaid } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                <i className={ classes.devidedBy }>Divided by 12.50%</i>
              </td>
              <td>
                <i>12.50%</i>
              </td>
            </tr>

          </tbody>
          <tfoot>
            <tr>
              <th>Income Needed to Support Student Loans</th>
              <th>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module15Data.newHigherIncomeNeedToSupportLoans } displayType="text" thousandSeparator prefix="$" />
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

StudentLoanRepayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( StudentLoanRepayment );
