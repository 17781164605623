import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import styles from './success-style';
import Pageloader from '../../components/ui/pageloader';

const VERIFY_EMAIL = loader( '../../graphql/schema/register/verify-email.graphql' );
const EmailVarification = ( props ) => {
  const { classes, location, history } = props;
  const parsed = queryString.parse( location.search );

  const [success, setSuccess] = useState( false );

  const [verifyEmail, { loading }] = useMutation( VERIFY_EMAIL, {
    onCompleted( {
      verifyUserEmail: {
        status,
      },
    } ) {
      if ( status === true ) {
        setSuccess( true );
      } else {
        history.push( '/' );
      }
    },
    onError( ) {
      history.push( '/' );
    },
  } );

  useEffect( () => {
    if ( parsed.token_id !== undefined && parsed.token_id !== '' ) {
      verifyEmail( {
        variables: {
          token: parsed.token_id,
        },
      } );
    } else {
      history.push( '/' );
    }
  }, [history, parsed.token_id, verifyEmail] );

  return (
    <Typography variant="body1" component="div" className={ classes.container }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.succesPopup }>
        {success === true && (
          <div className={ classes.successPopupDesign }>
            <div>
              <span className={ classes.popupCheck }>
                <i className="las la-check" />
              </span>
              <h2>Account activated sucessfully!</h2>
              <p>
                Click Here to
                {' '}
                <Link to="/login">login.</Link>
              </p>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};

EmailVarification.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( EmailVarification );
