import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';

import styles from './styles';

const InterestRate = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>What&apos;s an Interest Rate?</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/interest-rate.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      What&apos;s an Interest Rate?
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div
                        className={classNames(
                          classes.pfSlideImage,
                          classes.ocImage,
                        )}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/interest-rate-300.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          An interest rate is the amount a lender charges you
                          for using their money, typically expressed as an
                          annual percentage of the principal - often referred to
                          as Annual Percentage Rate or APR. Think of borrowing
                          money just like renting an apartment. In exchange for
                          giving you a place to live, you pay your landlord a
                          fee, which is a monthly rent payment. When you borrow
                          money from a bank, it&apos;s very similar to renting
                          an apartment.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Instead of borrowing a place to live, you are
                          borrowing money usually from a bank. In exchange for
                          the money, you pay your bank a fee, which is called{' '}
                          <Link
                            to="/finance-explained/interest"
                            target="_blank">
                            interest
                          </Link>
                          . To calculate your interest each month, the bank uses
                          the interest rate in the formula below:
                        </p>
                        <p>
                          <b>
                            Outstanding Loan Amount x Interest Rate ÷ 12 (to
                            make the interest rate on a monthly basis) = Monthly
                            Interest
                          </b>
                        </p>
                        <p>
                          Interest rates determine how much it costs to borrow
                          money. How does a lower or higher interest rate impact
                          you when borrowing money?
                        </p>
                        <div
                          className={classNames(
                            classes.galleryImage,
                            classes.topPadding,
                          )}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/Interest_Rates_Changing_v2.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Interest rates determine how expensive it is to borrow
                          money. A bank or other lender will calculate your
                          interest rate on a loan based on your credit score.
                          What is a credit score?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
InterestRate.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InterestRate);
