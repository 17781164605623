function cashFlowStatementSheet( module1Data, module2Data, module6Data, module9Data, module10Data,
  module11Data, module12Data, module18Data, incomeStatementnetIncomeData, investmentsData,
  assumptionsData, buyingAHomeData, invPropertyRoiData, expenseCalculationsData, fedSubHigherEdLoanData,
  fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData,
  fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, module15Data, fedSubNewHigherEdLoanData,
  fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, depreciationSheduleData, existingHomeData ) {
  let year = 0;
  let yearLimit = 0;

  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;

  let beginningCashBalanceValue = 0;
  const cashFlowStatementData = {};
  let endingCashBalance = 0;
  let vacationValue = 0;
  let vacationValueTotal = 0;
  let watchValue = 0;
  let watchValueTotal = 0;
  let customSuitValue = 0;
  let customSuitValueTotal = 0;
  cashFlowStatementData['Cash Flow Check'] = -1;

  const beginningCashBalanceGraph = [];
  const totalCashFromOperatingActivitiesGraph = [];
  const totalCashFromInvestingActivitiesGraph = [];
  const totalCashFromFinancingActivitiesGraph = [];
  const netChangeInCashGraph = [];
  const endingCashBalanceGraph = [];
  const shortTermInvestmentBalanceGraph = [];
  const longTermInvestmentBalanceGraph = [];
  let charitableContributionsTotal = 0;

  let k = 0;
  for ( year; year < yearLimit; year += 1 ) {
    cashFlowStatementData[year] = {};

    /** *************************
    ** Beginning Cash Balance **
    *************************** */
    if ( k === 0 ) {
       if ( module18Data.existingCurrentCashBalance !== 'undefined' && module18Data.existingCurrentCashBalance !== '' ) {
         beginningCashBalanceValue = module18Data.existingCurrentCashBalance;
       } else {
		   beginningCashBalanceValue = 0;
       }
    } else {
      beginningCashBalanceValue = endingCashBalance;
    }
	
    cashFlowStatementData[year]['Beginning Cash Balance'] = beginningCashBalanceValue;
    beginningCashBalanceGraph.push( cashFlowStatementData[year]['Beginning Cash Balance'] );
    /** End of Beginning Cash Balance * */
    k += 1;

    /** *********************************
    ** Cash From Operating Activities **
    *********************************** */
    // Net Income
    let netIncomeValue = 0;
    cashFlowStatementData[year]['Cash from Operating Activities'] = {};
    if ( typeof incomeStatementnetIncomeData[year]['Net Income'] !== 'undefined' && incomeStatementnetIncomeData[year]['Net Income'] !== '' ) {
      netIncomeValue = incomeStatementnetIncomeData[year]['Net Income'];
    } else {
      netIncomeValue = 0;
    }
    cashFlowStatementData[year]['Cash from Operating Activities']['Net Income'] = netIncomeValue;

    /** ***************************************
    ** Total Cash from Operating Activities **
    ***************************************** */
    cashFlowStatementData[year]['Total Cash from Operating Activities'] = parseFloat( cashFlowStatementData[year]['Cash from Operating Activities']['Net Income'] );
    totalCashFromOperatingActivitiesGraph.push( cashFlowStatementData[year]['Total Cash from Operating Activities'] );
    /** Total Cash from Operating Activities * */

    /** *********************************
    ** Cash from Investing Activities **
    *********************************** */
    cashFlowStatementData[year]['Cash from Investing Activities'] = {};
    // Retirement Contributions
    let retirementContributions = 0;
    if ( typeof investmentsData !== 'undefined' && investmentsData !== ''
      && typeof investmentsData['Retirement Accounts 401k'] !== 'undefined' && investmentsData['Retirement Accounts 401k'] !== ''
      && typeof investmentsData['Retirement Accounts 401k'][year] !== 'undefined' && investmentsData['Retirement Accounts 401k'][year] !== ''
      && typeof investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'] !== '' ) {	
      retirementContributions += investmentsData['Retirement Accounts 401k'][year]['Personal Contribution'];
    }
    if ( typeof investmentsData !== 'undefined' && investmentsData !== ''
      && typeof investmentsData['Retirement Accounts Roth IRA'] !== 'undefined' && investmentsData['Retirement Accounts Roth IRA'] !== ''
      && typeof investmentsData['Retirement Accounts Roth IRA'][year] !== 'undefined' && investmentsData['Retirement Accounts Roth IRA'][year] !== ''
      && typeof investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'] !== '' ) {
      retirementContributions += investmentsData['Retirement Accounts Roth IRA'][year]['Personal Contribution'];
    }
    if ( typeof investmentsData !== 'undefined' && investmentsData !== ''
      && typeof investmentsData['Retirement Accounts Seph IRA'] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'] !== ''
      && typeof investmentsData['Retirement Accounts Seph IRA'][year] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][year] !== ''
      && typeof investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'] !== 'undefined' && investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'] !== '' ) {
      retirementContributions += investmentsData['Retirement Accounts Seph IRA'][year]['Personal Contribution'];
    }
    if ( typeof investmentsData !== 'undefined' && investmentsData !== ''
      && typeof investmentsData['Retirement Accounts Pension'] !== 'undefined' && investmentsData['Retirement Accounts Pension'] !== ''
      && typeof investmentsData['Retirement Accounts Pension'][year] !== 'undefined' && investmentsData['Retirement Accounts Pension'][year] !== ''
      && typeof investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'] !== 'undefined' && investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'] !== '' ) {
      retirementContributions += investmentsData['Retirement Accounts Pension'][year]['Employer Contribution'];
    }
    retirementContributions = -retirementContributions;
    cashFlowStatementData[year]['Cash from Investing Activities']['Retirement Contributions'] = retirementContributions;
	
	// Contribution to Investment Accounts
    let contributionToInvestmentAccountsValue = 0;
    if ( typeof investmentsData !== 'undefined'
      && typeof investmentsData['Short-Term Investments'] !== 'undefined'
      && typeof investmentsData['Short-Term Investments'][year] !== 'undefined'
      && typeof investmentsData['Short-Term Investments'][year]['Cash Contibution'] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year]['Cash Contibution'] !== 'undefined' ) {
      contributionToInvestmentAccountsValue = -( investmentsData['Short-Term Investments'][year]['Cash Contibution']
        + investmentsData['Long-Term Investments'][year]['Cash Contibution'] );
    } else {
      contributionToInvestmentAccountsValue = 0;
    }
    cashFlowStatementData[year]['Cash from Investing Activities']['Contribution to Investment Accounts'] = contributionToInvestmentAccountsValue;
	
	//Contribution to 529 Plan
	var fiveTwentyPlanContributions = 0;
    if(typeof investmentsData !== 'undefined' && investmentsData !== '' &&
      typeof investmentsData['529 Plan'] !== 'undefined' && investmentsData['529 Plan'] !== '' && 
      typeof investmentsData['529 Plan'][year] !== 'undefined' && investmentsData['529 Plan'][year] !== '' && 
      typeof investmentsData['529 Plan'][year]['Cash Contibution'] !== 'undefined' && investmentsData['529 Plan'][year]['Cash Contibution'] !== ''  ) {
      fiveTwentyPlanContributions = -investmentsData['529 Plan'][year]['Cash Contibution'];
    }

    cashFlowStatementData[year]['Cash from Investing Activities']['Contribution to 529 Plan'] = fiveTwentyPlanContributions;
	
	//Life Insurance Premiums
	var lifeInsurancePremiumContributions = 0;
	if ( typeof module9Data.monthly_premium_percentage !== 'undefined' && module9Data.monthly_premium_percentage === '' || module9Data.monthly_premium_percentage === 0 ){
	    lifeInsurancePremiumContributions = 0;
    } else{
	    if(typeof investmentsData !== 'undefined' && investmentsData !== '' &&
        typeof investmentsData['529 Plan'] !== 'undefined' && investmentsData['529 Plan'] !== '' && 
        typeof investmentsData['529 Plan'][year] !== 'undefined' && investmentsData['529 Plan'][year] !== '' && 
        typeof investmentsData['529 Plan'][year]['Cash Contibution'] !== 'undefined' && investmentsData['529 Plan'][year]['Cash Contibution'] !== ''  ) {
        lifeInsurancePremiumContributions = -investmentsData['529 Plan'][year]['Cash Contibution'] / module9Data.monthly_premium_percentage;
      } else{
		  lifeInsurancePremiumContributions = 0;  
	  }
	}
    cashFlowStatementData[year]['Cash from Investing Activities']['Life Insurance Premiums'] = lifeInsurancePremiumContributions;
	
				
    var hsaContributions = 0;
    if(typeof investmentsData !== 'undefined' && investmentsData !== '' &&
      typeof investmentsData['HSA Second'] !== 'undefined' && investmentsData['HSA Second'] !== '' && 
      typeof investmentsData['HSA Second'][year] !== 'undefined' && investmentsData['HSA Second'][year] !== '' && 
      typeof investmentsData['HSA Second'][year]['Annual Contribution'] !== 'undefined' && investmentsData['HSA Second'][year]['Annual Contribution'] !== ''  ) {
      hsaContributions = -investmentsData['HSA Second'][year]['Annual Contribution'];
    }
    cashFlowStatementData[year]['Cash from Investing Activities']['HSA Contributions'] = hsaContributions;

    

    // Charitable Contributions
    let charitableContributions = 0;
    if ( typeof module9Data.charitable_start_year !== 'undefined' && module9Data.charitable_start_year !== ''
      && typeof module9Data.charitable_end_year !== 'undefined' && module9Data.charitable_end_year !== '' ) {
      if ( year >= module9Data.charitable_start_year && year <= module9Data.charitable_end_year ) {
        if ( typeof module9Data.charitable_yearly_contribution !== 'undefined' && module9Data.charitable_yearly_contribution !== 'undefined'
          && typeof incomeStatementnetIncomeData !== 'undefined' && incomeStatementnetIncomeData !== ''
          && typeof incomeStatementnetIncomeData[year] !== 'undefined' && incomeStatementnetIncomeData[year] !== ''
          && typeof incomeStatementnetIncomeData[year]['Total Income'] !== 'undefined' && incomeStatementnetIncomeData[year]['Total Income'] !== ''
        ) {
          charitableContributions = -( incomeStatementnetIncomeData[year]['Total Income'] * module9Data.charitable_yearly_contribution ) / 100;
        }
      }
    }
    cashFlowStatementData[year]['Cash from Investing Activities']['Charitable Contributions'] = charitableContributions;
    charitableContributionsTotal += charitableContributions;

    // Car Down Payments
    let carDownPaymentsValue = 0;
    let carDownPaymentsValue1 = 0;
    let carDownPaymentsValue2 = 0;
    let carDownPaymentsValue3 = 0;
    let carDownPaymentsValue4 = 0;
    
	if ( typeof module12Data.purchase_year !== 'undefined' && module12Data.purchase_year !== ''
      && typeof module12Data.cashNeededAtPurchase !== 'undefined' && year === module12Data.purchase_year ) {
      carDownPaymentsValue1 = module12Data.cashNeededAtPurchase;
    } else {
      carDownPaymentsValue1 = 0;
    }
    
    if ( typeof module12Data.second_purchase_year !== 'undefined' && module12Data.second_purchase_year !== ''
      && year === module12Data.second_purchase_year && typeof module12Data.moreCashNeededAtPurchase !== 'undefined' ) {
      carDownPaymentsValue2 = module12Data.moreCashNeededAtPurchase;
    } else {
      carDownPaymentsValue2 = 0;
    }

    if ( typeof depreciationSheduleData !== 'undefined' && depreciationSheduleData !== '' && typeof depreciationSheduleData[year] !== 'undefined' && depreciationSheduleData[year] !== '' && typeof depreciationSheduleData[year]['Lease1 Expenses'] !== 'undefined' ) {
      carDownPaymentsValue3 = depreciationSheduleData[year]['Lease1 Expenses']['Down Payment'] + depreciationSheduleData[year]['Lease1 Expenses']['Drive-Off Fees'];
    } else {
      carDownPaymentsValue3 = 0;
    }
    if ( typeof depreciationSheduleData !== 'undefined' && depreciationSheduleData !== '' && typeof depreciationSheduleData[year] !== 'undefined' && depreciationSheduleData[year] !== '' && typeof depreciationSheduleData[year]['Lease2 Expenses'] !== 'undefined' ) {
      carDownPaymentsValue4 = depreciationSheduleData[year]['Lease2 Expenses']['Down Payment'] + depreciationSheduleData[year]['Lease2 Expenses']['Drive-Off Fees'];
    } else {
      carDownPaymentsValue4 = 0;
    }
	carDownPaymentsValue = -( parseFloat( carDownPaymentsValue1 ) + parseFloat( carDownPaymentsValue2 ) + parseFloat( carDownPaymentsValue3 ) + parseFloat( carDownPaymentsValue4 ) );
    cashFlowStatementData[year]['Cash from Investing Activities']['Car and Lease Down Payment & Fees'] = carDownPaymentsValue;

    // Vacation
    if ( module11Data.start_year !== 'undefined' && module11Data.start_year !== ''
      && module11Data.end_year !== 'undefined' && module11Data.end_year !== '' ) {
      if ( year >= module11Data.start_year && year <= module11Data.end_year ) {
        if ( year === module11Data.start_year ) {
          vacationValue = -module11Data.yearly_cost;
        } else if ( typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined' ) {
          vacationValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          vacationValue = 0;
        }
      } else {
        vacationValue = 0;
      }
    } else {
      vacationValue = 0;
    }
    cashFlowStatementData[year]['Cash from Investing Activities'].Vacation = vacationValue;
    vacationValueTotal = vacationValue + vacationValueTotal;

    // Other Acquisition
    if ( module11Data.second_start_year !== 'undefined' && module11Data.second_start_year !== ''
      && module11Data.second_end_year !== 'undefined' && module11Data.second_end_year !== '' ) {
      if ( year >= module11Data.second_start_year && year <= module11Data.second_end_year ) {
        if ( year === module11Data.second_start_year ) {
          watchValue = -module11Data.second_yearly_cost;
        } else if ( typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined' ) {
          watchValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          watchValue = 0;
        }
      } else {
        watchValue = 0;
      }
    } else {
      watchValue = 0;
    }
    if ( module11Data.other_acquisition1 !== undefined ) {
      cashFlowStatementData[year]['Cash from Investing Activities'][module11Data.other_acquisition1] = watchValue;
    } else {
      cashFlowStatementData[year]['Cash from Investing Activities']['Special Purchase 1'] = watchValue;
    }
    watchValueTotal = watchValue + watchValueTotal;

    // Other Acquisition1
    if ( module11Data.third_start_year !== 'undefined' && module11Data.third_start_year !== ''
      && module11Data.third_end_year !== 'undefined' && module11Data.third_end_year !== '' ) {
      if ( year >= module11Data.third_start_year && year <= module11Data.third_end_year ) {
        if ( year === module11Data.third_start_year ) {
          customSuitValue = -module11Data.third_yearly_cost;
        } else if ( typeof assumptionsData !== 'undefined' && typeof assumptionsData[year] !== 'undefined' ) {
          customSuitValue *= ( ( assumptionsData[year].Expenses ) / 100 );
        } else {
          customSuitValue = 0;
        }
      } else {
        customSuitValue = 0;
      }
    } else {
      customSuitValue = 0;
    }
    if ( module11Data.other_acquisition2 !== undefined ) {
      cashFlowStatementData[year]['Cash from Investing Activities'][`${module11Data.other_acquisition2} - Second`] = customSuitValue;
    } else {
      cashFlowStatementData[year]['Cash from Investing Activities']['Special Purchase 2 - Second'] = customSuitValue;
    }
    customSuitValueTotal = customSuitValue + customSuitValueTotal;

    // Pet Buying Costs
    let petBuyingCostsValue = 0;
    if ( module10Data.year_of_purchase !== 'undefined' && module10Data.year_of_purchase !== '' ) {
      if ( year === module10Data.year_of_purchase ) {
        if ( typeof expenseCalculationsData[year] !== 'undefined'
          && typeof expenseCalculationsData[year]['Initial Costs'].Total !== 'undefined' ) {
          petBuyingCostsValue = -( expenseCalculationsData[year]['Initial Costs'].Total );
        } else {
          petBuyingCostsValue = 0;
        }
      } else {
        petBuyingCostsValue = 0;
      }
    } else {
      petBuyingCostsValue = 0;
    }
    cashFlowStatementData[year]['Cash from Investing Activities']['Pet Buying Costs'] = petBuyingCostsValue;

    // Wedding Costs
    let weddingCostsValue = 0;
    if ( module10Data.marriege_year !== 'undefined' && module10Data.marriege_year !== '' ) {
      if ( year === module10Data.marriege_year ) {
        weddingCostsValue = -module10Data.wedding_costs;
      } else {
        weddingCostsValue = 0;
      }
    } else {
      weddingCostsValue = 0;
    }
    cashFlowStatementData[year]['Cash from Investing Activities']['Wedding Costs'] = weddingCostsValue;

    // Home & Rental Property Down Payment
    let homeRentalPropertyDownPaymentValue = 0;
    let homeRentalPropertyDownPaymentValue1 = 0;
    let homeRentalPropertyDownPaymentValue2 = 0;
    if ( typeof buyingAHomeData !== 'undefined' && buyingAHomeData !== ''
      && typeof buyingAHomeData.Data1 !== 'undefined'
      && typeof buyingAHomeData.Data1[year] !== 'undefined'
      && typeof buyingAHomeData.Data1[year]['Down Payment'] !== 'undefined' ) {
      homeRentalPropertyDownPaymentValue1 = buyingAHomeData.Data1[year]['Down Payment'];
    } else {
      homeRentalPropertyDownPaymentValue1 = 0;
    }
    if ( typeof invPropertyRoiData !== 'undefined' && invPropertyRoiData !== ''
      && typeof invPropertyRoiData.Data1 !== 'undefined'
      && typeof invPropertyRoiData.Data1[year] !== 'undefined'
      && typeof invPropertyRoiData.Data1[year]['Down Payment'] !== 'undefined' ) {
      homeRentalPropertyDownPaymentValue2 = invPropertyRoiData.Data1[year]['Down Payment'];
    } else {
      homeRentalPropertyDownPaymentValue2 = 0;
    }
    homeRentalPropertyDownPaymentValue = -( homeRentalPropertyDownPaymentValue1 + homeRentalPropertyDownPaymentValue2 );
    cashFlowStatementData[year]['Cash from Investing Activities']['Home & REI Property Down Payments'] = homeRentalPropertyDownPaymentValue;

    // Home & Rental Property Closing Costs
    let homeRentalPropertyClosingCostsValue = 0;
    let homeRentalPropertyClosingCostsValue1 = 0;
    let homeRentalPropertyClosingCostsValue2 = 0;
    if ( typeof buyingAHomeData !== 'undefined' && buyingAHomeData !== ''
      && typeof buyingAHomeData.Data1 !== 'undefined'
      && typeof buyingAHomeData.Data1[year] !== 'undefined'
      && typeof buyingAHomeData.Data1[year]['Closing Costs'] !== 'undefined' ) {
      homeRentalPropertyClosingCostsValue1 = buyingAHomeData.Data1[year]['Closing Costs'];
    } else {
      homeRentalPropertyClosingCostsValue1 = 0;
    }
    if ( typeof invPropertyRoiData !== 'undefined' && invPropertyRoiData !== ''
      && typeof invPropertyRoiData.Data1 !== 'undefined'
      && typeof invPropertyRoiData.Data1[year] !== 'undefined'
      && typeof invPropertyRoiData.Data1[year]['Closing Costs'] !== 'undefined' ) {
      homeRentalPropertyClosingCostsValue2 = invPropertyRoiData.Data1[year]['Closing Costs'];
    } else {
      homeRentalPropertyClosingCostsValue2 = 0;
    }
    homeRentalPropertyClosingCostsValue = -( homeRentalPropertyClosingCostsValue1 + homeRentalPropertyClosingCostsValue2 );
    cashFlowStatementData[year]['Cash from Investing Activities']['Home & REI Property Closing Costs'] = homeRentalPropertyClosingCostsValue;

    // External & Internal Fit Out Costs
    let externalInternalFitOutCostsValue = 0;
    let externalInternalFitOutCostsValue1 = 0;
    let externalInternalFitOutCostsValue2 = 0;
    if ( typeof buyingAHomeData !== 'undefined' && buyingAHomeData !== ''
      && typeof buyingAHomeData.Data1 !== 'undefined'
      && typeof buyingAHomeData.Data1[year] !== 'undefined'
      && typeof buyingAHomeData.Data1[year]['Fit Out Costs'] !== 'undefined' ) {
      externalInternalFitOutCostsValue1 = buyingAHomeData.Data1[year]['Fit Out Costs'];
    } else {
      externalInternalFitOutCostsValue1 = 0;
    }
    if ( typeof invPropertyRoiData !== 'undefined' && invPropertyRoiData !== ''
      && typeof invPropertyRoiData.Data1 !== 'undefined'
      && typeof invPropertyRoiData.Data1[year] !== 'undefined'
      && typeof invPropertyRoiData.Data1[year]['Fit Out Costs'] !== 'undefined' ) {
      externalInternalFitOutCostsValue2 = invPropertyRoiData.Data1[year]['Fit Out Costs'];
    } else {
      externalInternalFitOutCostsValue2 = 0;
    }
    externalInternalFitOutCostsValue = -( externalInternalFitOutCostsValue1 + externalInternalFitOutCostsValue2 );
    cashFlowStatementData[year]['Cash from Investing Activities']['Home & REI Property Fit Out Costs'] = externalInternalFitOutCostsValue;

    // Home & REI Property Net Cash Flow from Sale
    let REIPropertyCashFlowfromSale = 0;
    if ( typeof invPropertyRoiData !== 'undefined' && invPropertyRoiData !== ''
      && typeof invPropertyRoiData.Data1 !== 'undefined'
      && typeof invPropertyRoiData.Data1[year] !== 'undefined'
      && typeof invPropertyRoiData.Data1[year]['Net Cash Flow from Sale'] !== 'undefined' ) {
      REIPropertyCashFlowfromSale += invPropertyRoiData.Data1[year]['Net Cash Flow from Sale'];
    }
    if ( typeof existingHomeData !== 'undefined' && existingHomeData !== ''
      && typeof existingHomeData.Data1 !== 'undefined'
      && typeof existingHomeData.Data1[year] !== 'undefined'
      && typeof existingHomeData.Data1[year]['Sale Value'] !== 'undefined' ) {
      REIPropertyCashFlowfromSale += existingHomeData.Data1[year]['Sale Value'];
    }
    cashFlowStatementData[year]['Cash from Investing Activities']['Home & REI Property Net Cash Flow from Sale'] = REIPropertyCashFlowfromSale;

    // Rental Property Sale
    let rentalPropertySaleValue = 0;
    if ( typeof invPropertyRoiData !== 'undefined' && invPropertyRoiData !== ''
      && typeof invPropertyRoiData.Data1 !== 'undefined'
      && typeof invPropertyRoiData.Data1[year] !== 'undefined'
      && typeof invPropertyRoiData.Data1[year]['Property Taxes'] !== 'undefined' ) {
      rentalPropertySaleValue = -( invPropertyRoiData.Data1[year]['Property Taxes'] );
    } else {
      rentalPropertySaleValue = 0;
    }
    cashFlowStatementData[year]['Cash from Investing Activities']['Rental Property Sale'] = rentalPropertySaleValue;
    /** End of Cash from Investing Activities * */

    /** ***************************************
    ** Total Cash from Investing Activities **
    ***************************************** */
    let totalCashfromInvestingActivitiesValue = 0;
    totalCashfromInvestingActivitiesValue = parseFloat( contributionToInvestmentAccountsValue )
      + parseFloat( retirementContributions )
	  + parseFloat( fiveTwentyPlanContributions )
	  + parseFloat( lifeInsurancePremiumContributions )
      + parseFloat( charitableContributions )
      + parseFloat( carDownPaymentsValue )
      // parseFloat(carBuyingCostsValue) +
      + parseFloat( vacationValue )
      + parseFloat( watchValue )
      + parseFloat( customSuitValue )
      + parseFloat( petBuyingCostsValue )
      + parseFloat( weddingCostsValue )
      + parseFloat( homeRentalPropertyDownPaymentValue )
      + parseFloat( homeRentalPropertyClosingCostsValue )
      + parseFloat( externalInternalFitOutCostsValue )
      + parseFloat( REIPropertyCashFlowfromSale );
    cashFlowStatementData[year]['Total Cash from Investing Activities'] = totalCashfromInvestingActivitiesValue;
    totalCashFromInvestingActivitiesGraph.push( cashFlowStatementData[year]['Total Cash from Investing Activities'] );
    /** End of Total Cash from Investing Activities * */

    /** *********************************
    ** Cash from Financing Activities **
    *********************************** */
    cashFlowStatementData[year]['Cash from Financing Activities'] = {};
    // One-Time Financial Gift
    let oneTimeFinancialGiftValue = 0;
    if ( module9Data.financial_gift_start_year !== 'undefined' && module9Data.financial_gift_start_year !== ''
      && module9Data.financial_gift_end_year !== 'undefined' && module9Data.financial_gift_end_year !== '' ) {
      if ( year >= module9Data.financial_gift_start_year && year <= module9Data.financial_gift_end_year ) {
        oneTimeFinancialGiftValue = module9Data.yearly_financial_gift;
      } else {
        oneTimeFinancialGiftValue = 0;
      }
    } else {
      oneTimeFinancialGiftValue = 0;
    }
    cashFlowStatementData[year]['Cash from Financing Activities']['One-Time Financial Gift'] = oneTimeFinancialGiftValue;

    // Scholarships
    let scholarshipsValue = 0;
    let scholarshipsValue1 = 0;
    let scholarshipsValue2 = 0;
    let scholarshipsValue3 = 0;
    if ( module2Data.start_year !== 'undefined' && module2Data.start_year !== '' && module2Data.graduation_year !== 'undefined' && module2Data.graduation_year !== '' && module2Data.yearly_scholarship_amount !== 'undefined' && module2Data.yearly_scholarship_amount !== '' ) {
      if ( year >= module2Data.start_year && year <= module2Data.graduation_year ) {
        scholarshipsValue1 = module2Data.yearly_scholarship_amount;
      } else { scholarshipsValue1 = 0; }
    } else { scholarshipsValue1 = 0; }

    if ( typeof module6Data.start_year !== 'undefined' && module6Data.start_year !== '' && module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' && module6Data.yearly_scholarship_amount !== 'undefined' && module6Data.yearly_scholarship_amount !== '' ) {
      if ( year >= module6Data.start_year && year <= module6Data.graduation_year ) {
        scholarshipsValue2 = module6Data.yearly_scholarship_amount;
      } else {
        scholarshipsValue2 = 0;
      }
    } else { scholarshipsValue2 = 0; }

    if ( typeof module15Data.start_year !== 'undefined' && module15Data.start_year !== ''
      && module15Data.graduation_year !== 'undefined' && module15Data.graduation_year !== ''
      && module15Data.yearly_scholarship_amount !== 'undefined' && module15Data.yearly_scholarship_amount !== '' ) {
      if ( year >= module15Data.start_year && year <= module15Data.graduation_year ) {
        scholarshipsValue3 = module15Data.yearly_scholarship_amount;
      } else { scholarshipsValue3 = 0; }
    } else { scholarshipsValue3 = 0; }

    scholarshipsValue = parseFloat( scholarshipsValue1 ) + parseFloat( scholarshipsValue2 ) + parseFloat( scholarshipsValue3 );
    cashFlowStatementData[year]['Cash from Financing Activities'].Scholarships = scholarshipsValue;

    // Grants
    let grantsValue = 0;
    let grantsValue1 = 0;
    let grantsValue2 = 0;
    let grantsValue3 = 0;
    if ( module2Data.start_year !== 'undefined' && module2Data.start_year !== ''
      && module2Data.graduation_year !== 'undefined' && module2Data.graduation_year !== ''
      && module2Data.yearly_grant_amount !== 'undefined' && module2Data.yearly_grant_amount !== '' ) {
      if ( year >= module2Data.start_year && year <= module2Data.graduation_year ) {
        grantsValue1 = module2Data.yearly_grant_amount;
      } else {
        grantsValue1 = 0;
      }
    } else {
      grantsValue1 = 0;
    }
    if ( typeof module6Data.start_year !== 'undefined' && module6Data.start_year !== ''
      && module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== ''
      && module6Data.yearly_grant_amount !== 'undefined' && module6Data.yearly_grant_amount !== '' ) {
      if ( year >= module6Data.start_year && year <= module6Data.graduation_year ) {
        grantsValue2 = module6Data.yearly_grant_amount;
      } else {
        grantsValue2 = 0;
      }
    } else {
      grantsValue2 = 0;
    }
    if ( typeof module15Data.start_year !== 'undefined' && module15Data.start_year !== ''
      && module15Data.graduation_year !== 'undefined' && module15Data.graduation_year !== ''
      && module15Data.yearly_grant_amount !== 'undefined' && module15Data.yearly_grant_amount !== '' ) {
      if ( year >= module15Data.start_year && year <= module15Data.graduation_year ) {
        grantsValue3 = module15Data.yearly_grant_amount;
      } else {
        grantsValue3 = 0;
      }
    } else {
      grantsValue3 = 0;
    }
    grantsValue = parseFloat( grantsValue1 ) + parseFloat( grantsValue2 ) + parseFloat( grantsValue3 );
    cashFlowStatementData[year]['Cash from Financing Activities'].Grants = grantsValue;

    // Financial Aid
    let financialAidValue1 = 0;
    let financialAidValue2 = 0;
    let financialAidValue3 = 0;
    let financialAidValue = 0;
    if ( module2Data.start_year !== 'undefined' && module2Data.start_year !== ''
      && module2Data.graduation_year !== 'undefined' && module2Data.graduation_year !== '' ) {
      if ( year >= module2Data.start_year && year <= module2Data.graduation_year ) {
        if ( module2Data.year_aid_begins !== 'undefined' && module2Data.year_aid_begins !== '' ) {
          financialAidValue1 = module2Data.year_aid_begins;
        } else {
          financialAidValue1 = 0;
        }
      } else {
        financialAidValue1 = 0;
      }
    } else {
      financialAidValue1 = 0;
    }
    if ( typeof module6Data.start_year !== 'undefined' && module6Data.start_year !== ''
      && module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' ) {
      if ( year >= module6Data.start_year && year <= module6Data.graduation_year ) {
        if ( typeof module6Data.year_aid_begins !== 'undefined' && module6Data.year_aid_begins !== '' ) {
          financialAidValue2 = module6Data.year_aid_begins;
        } else {
          financialAidValue2 = 0;
        }
      } else {
        financialAidValue2 = 0;
      }
    } else {
      financialAidValue2 = 0;
    }
    if ( typeof module15Data.start_year !== 'undefined' && module15Data.start_year !== ''
      && module15Data.graduation_year !== 'undefined' && module15Data.graduation_year !== '' ) {
      if ( year >= module15Data.start_year && year <= module15Data.graduation_year ) {
        if ( typeof module15Data.year_aid_begins !== 'undefined' && module15Data.year_aid_begins !== '' ) {
          financialAidValue3 = module15Data.year_aid_begins;
        } else {
          financialAidValue3 = 0;
        }
      } else {
        financialAidValue3 = 0;
      }
    } else {
      financialAidValue3 = 0;
    }
    financialAidValue = parseFloat( financialAidValue1 ) + parseFloat( financialAidValue2 ) + parseFloat( financialAidValue3 );
    cashFlowStatementData[year]['Cash from Financing Activities']['Other Financial Aid'] = financialAidValue;

    // Student Loans
    let studentLoansValue = 0;
    let fedSub = 0;
    let fedUnSub = 0;
    let privateHigher = 0;
    let fedSubMore = 0;
    let fedUnSubMore = 0;
    let privateHigherMore = 0;
    let fedSubNew = 0;
    let fedUnSubNew = 0;
    let privateHigherNew = 0;
    if ( typeof fedSubHigherEdLoanData !== 'undefined'
      && typeof fedSubHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedSubHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      fedSub = fedSubHigherEdLoanData['Summation Data'][year]['W/O Accrual'] - fedSubHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }
    if ( typeof fedUnSubHigherEdLoanData !== 'undefined'
      && typeof fedUnSubHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedUnSubHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      fedUnSub = fedUnSubHigherEdLoanData['Summation Data'][year]['Loans Taken'] - fedUnSubHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }
    if ( typeof privateHigherEdLoanData !== 'undefined'
      && typeof privateHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof privateHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      privateHigher = privateHigherEdLoanData['Summation Data'][year]['Loans Taken'] - privateHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }

    if ( typeof fedSubMoreHigherEdLoanData !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      fedSubMore = fedSubMoreHigherEdLoanData['Summation Data'][year]['W/O Accrual'] - fedSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }
    if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      fedUnSubMore = fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'] - fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }
    if ( typeof privateMoreHigherEdLoanData !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      privateHigherMore = privateMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'] - privateMoreHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }

    if ( typeof fedSubNewHigherEdLoanData !== 'undefined'
      && typeof fedSubNewHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedSubNewHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      fedSubNew = fedSubNewHigherEdLoanData['Summation Data'][year]['W/O Accrual'] - fedSubNewHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }
    if ( typeof fedUnSubNewHigherEdLoanData !== 'undefined'
      && typeof fedUnSubNewHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof fedUnSubNewHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      fedUnSubNew = fedUnSubNewHigherEdLoanData['Summation Data'][year]['Loans Taken'] - fedUnSubNewHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }
    if ( typeof privateNewHigherEdLoanData !== 'undefined'
      && typeof privateNewHigherEdLoanData['Summation Data'] !== 'undefined'
      && typeof privateNewHigherEdLoanData['Summation Data'][year] !== 'undefined' ) {
      privateHigherNew = privateNewHigherEdLoanData['Summation Data'][year]['Loans Taken'] - privateNewHigherEdLoanData['Summation Data'][year]['Points Paid'];
    }

    studentLoansValue = parseFloat( fedSub )
      + parseFloat( fedUnSub )
      + parseFloat( privateHigher )
      + parseFloat( fedSubMore )
      + parseFloat( fedUnSubMore )
      + parseFloat( privateHigherMore )
      + parseFloat( fedSubNew )
      + parseFloat( fedUnSubNew )
      + parseFloat( privateHigherNew );

    cashFlowStatementData[year]['Cash from Financing Activities']['Student Loans (Net of Origination Fees)'] = studentLoansValue;

    // Family Contribution
    let familyContributionValue = 0;
    let familyContributionValue1 = 0;
    let familyContributionValue2 = 0;
    let familyContributionValue3 = 0;
    if ( module2Data.start_year !== 'undefined' && module2Data.start_year !== '' && module2Data.graduation_year !== 'undefined' && module2Data.graduation_year !== '' && module2Data.yearly_contribution !== 'undefined' && module2Data.yearly_contribution !== '' ) {
      if ( year >= module2Data.start_year && year <= module2Data.graduation_year ) {
        familyContributionValue1 = module2Data.yearly_contribution;
      } else { familyContributionValue1 = 0; }
    } else { familyContributionValue1 = 0; }

    if ( module6Data.start_year !== 'undefined' && module6Data.start_year !== '' && module6Data.graduation_year !== 'undefined' && module6Data.graduation_year !== '' && module6Data.yearly_contribution !== 'undefined' && module6Data.yearly_contribution !== '' ) {
      if ( year >= module6Data.start_year && year <= module6Data.graduation_year ) { familyContributionValue2 = module6Data.yearly_contribution; } else { familyContributionValue2 = 0; }
    } else { familyContributionValue2 = 0; }

    if ( module15Data.start_year !== 'undefined' && module15Data.start_year !== ''
      && module15Data.graduation_year !== 'undefined' && module15Data.graduation_year !== ''
      && module15Data.yearly_contribution !== 'undefined' && module15Data.yearly_contribution !== '' ) {
      if ( year >= module15Data.start_year && year <= module15Data.graduation_year ) { familyContributionValue3 = module15Data.yearly_contribution; } else { familyContributionValue3 = 0; }
    } else { familyContributionValue3 = 0; }

    familyContributionValue = parseFloat( familyContributionValue1 ) + parseFloat( familyContributionValue2 ) + parseFloat( familyContributionValue3 );
    cashFlowStatementData[year]['Cash from Financing Activities']['Family Contribution'] = familyContributionValue;

    /** ***************************************
    ** Total Cash from Financing Activities **
    ***************************************** */
    let totalCashFromFinancingActivities = 0;
    cashFlowStatementData[year]['Total Cash from Financing Activities'] = {};
    totalCashFromFinancingActivities = parseFloat( oneTimeFinancialGiftValue )
      + parseFloat( scholarshipsValue )
      + parseFloat( grantsValue )
      + parseFloat( financialAidValue )
      + parseFloat( studentLoansValue )
      + parseFloat( familyContributionValue );
    cashFlowStatementData[year]['Total Cash from Financing Activities'] = totalCashFromFinancingActivities;
    totalCashFromFinancingActivitiesGraph.push( cashFlowStatementData[year]['Total Cash from Financing Activities'] );
    /** End of Total Cash from Financing Activities * */

    /** *********************
    ** Net Change in Cash **
    *********************** */
    cashFlowStatementData[year]['Net Change in Cash'] = {};
    let netChangeinCashValue = 0;
    netChangeinCashValue = parseFloat( cashFlowStatementData[year]['Total Cash from Financing Activities'] ) + parseFloat( cashFlowStatementData[year]['Total Cash from Investing Activities'] ) + parseFloat( cashFlowStatementData[year]['Total Cash from Operating Activities'] );
    cashFlowStatementData[year]['Net Change in Cash'] = netChangeinCashValue;
    netChangeInCashGraph.push( cashFlowStatementData[year]['Net Change in Cash'] );
    /** End of Net Change in Cash * */

    /** **********************************
    ** Ending Cash Surplus / (Deficit) **
    ************************************ */
    cashFlowStatementData[year]['Ending Cash Surplus'] = {};
    let endingCashSurplusValue = 0;
    endingCashSurplusValue = parseFloat( cashFlowStatementData[year]['Net Change in Cash'] ) + parseFloat( cashFlowStatementData[year]['Beginning Cash Balance'] );
    cashFlowStatementData[year]['Ending Cash Surplus'] = endingCashSurplusValue;
    /** End of Ending Cash Surplus / (Deficit) * */

    /** *********************************************
    ** Cash Injection from Short-Term Investments **
    *********************************************** */
    cashFlowStatementData[year]['Cash Injection from Short-Term Investments'] = {};
    let cashInjectionfromShortTermInvestmentsValue = 0;
    if ( typeof investmentsData !== 'undefined'
      && typeof investmentsData['Short-Term Investments'] !== 'undefined'
      && typeof investmentsData['Short-Term Investments'][year] !== 'undefined'
      && typeof investmentsData['Short-Term Investments'][year]['Cash Transfer'] !== 'undefined' ) {
      cashInjectionfromShortTermInvestmentsValue = investmentsData['Short-Term Investments'][year]['Cash Transfer'];
    } else {
      cashInjectionfromShortTermInvestmentsValue = 0;
    }
    cashFlowStatementData[year]['Cash Injection from Short-Term Investments'] = cashInjectionfromShortTermInvestmentsValue;
    /** End of Cash Injection from Short-Term Investments * */

    /** ********************************************
    ** Cash Injection from Long-Term Investments **
    ********************************************** */
    cashFlowStatementData[year]['Cash Injection from Long-Term Investments'] = {};
    let cashInjectionfromLongTermInvestmentsValue = 0;
    if ( typeof investmentsData !== 'undefined'
      && typeof investmentsData['Long-Term Investments'] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year]['Cash Transfer'] !== 'undefined' ) {
      cashInjectionfromLongTermInvestmentsValue = investmentsData['Long-Term Investments'][year]['Cash Transfer'];
    } else {
      cashInjectionfromLongTermInvestmentsValue = 0;
    }
    cashFlowStatementData[year]['Cash Injection from Long-Term Investments'] = cashInjectionfromLongTermInvestmentsValue;
    /** End of Cash Injection from Long-Term Investments * */

    /** **************************************
    ** Ending Cash Balance after Transfers **
    **************************************** */
    cashFlowStatementData[year]['Ending Cash Balance after Transfers'] = {};
    let endingCashBalanceafterTransfersValue = 0;
    endingCashBalanceafterTransfersValue = parseFloat( cashFlowStatementData[year]['Ending Cash Surplus'] ) + parseFloat( cashFlowStatementData[year]['Cash Injection from Short-Term Investments'] ) + parseFloat( cashFlowStatementData[year]['Cash Injection from Long-Term Investments'] );
    cashFlowStatementData[year]['Ending Cash Balance after Transfers'] = endingCashBalanceafterTransfersValue;
    /** Ending Cash Balance after Transfers * */

    /** ***************************
    ** Excess Cash Put into STI **
    ***************************** */
    let excessCashPutintoSTIValue = 0;
    cashFlowStatementData[year]['Excess Cash Put into STI'] = {};
    let module1MaxCashBalance = 0;
    // if ( typeof module1Data.max_cash_balance !== 'undefined' && module1Data.max_cash_balance !== '' ) {
    //   module1MaxCashBalance = module1Data.max_cash_balance;
    // } else {
    //   module1MaxCashBalance = 0;
    // }
    if ( typeof module9Data.short_term_start_year !== 'undefined' && module9Data.short_term_start_year !== '' && module9Data.short_term_start_year > 0
      && year >= module9Data.short_term_start_year
      && parseFloat( cashFlowStatementData[year]['Ending Cash Balance after Transfers'] ) > parseFloat( module1MaxCashBalance ) ) {
      excessCashPutintoSTIValue = module1MaxCashBalance - parseFloat( cashFlowStatementData[year]['Ending Cash Balance after Transfers'] );
    } else {
      excessCashPutintoSTIValue = 0;
    }
    cashFlowStatementData[year]['Excess Cash Put into STI'][module1MaxCashBalance] = excessCashPutintoSTIValue;
    /** End of Excess Cash Put into STI * */

    /** **********************
    ** Ending Cash Balance **
    ************************ */
    cashFlowStatementData[year]['Ending Cash Balance'] = {};
    endingCashBalance = parseFloat( cashFlowStatementData[year]['Ending Cash Balance after Transfers'] )
      + parseFloat( cashFlowStatementData[year]['Excess Cash Put into STI'][module1MaxCashBalance] );
    cashFlowStatementData[year]['Ending Cash Balance'] = endingCashBalance;

    endingCashBalanceGraph.push( cashFlowStatementData[year]['Ending Cash Balance'] );
    /** End of Ending Cash Balance * */

    /** ********************************
    ** Short-Term Investment Balance **
    ********************************** */
    cashFlowStatementData[year]['Short-Term Investment Balance'] = {};
    let shortTermInvestmentBalanceValue = 0;
    if ( typeof investmentsData !== 'undefined'
      && typeof investmentsData['Short-Term Investments'] !== 'undefined'
      && typeof investmentsData['Short-Term Investments'][year] !== 'undefined'
      && typeof investmentsData['Short-Term Investments'][year].Balance4 !== 'undefined' ) {
      shortTermInvestmentBalanceValue = investmentsData['Short-Term Investments'][year].Balance4;
    } else {
      shortTermInvestmentBalanceValue = 0;
    }
    cashFlowStatementData[year]['Short-Term Investment Balance'] = shortTermInvestmentBalanceValue;
    shortTermInvestmentBalanceGraph.push( cashFlowStatementData[year]['Short-Term Investment Balance'] );
    /** End of Short-Term Investment Balance * */

    /** **************************
    ** Excess STI Put into LTI **
    **************************** */
    let excessSTIPutintoLTIValue = 0;
    cashFlowStatementData[year]['Excess STI Put into LTI'] = {};
    if ( typeof investmentsData !== 'undefined'
      && typeof investmentsData['Long-Term Investments'] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year].Contribution !== 'undefined' ) {
      excessSTIPutintoLTIValue = -( investmentsData['Long-Term Investments'][year].Contribution );
    } else {
      excessSTIPutintoLTIValue = 0;
    }
    let ltiInvestment = 0;
    if ( typeof module9Data.short_term_max_sti_balance_before_lt_investment !== 'undefined' && module9Data.short_term_max_sti_balance_before_lt_investment !== '' ) {
      ltiInvestment = module9Data.short_term_max_sti_balance_before_lt_investment;
    }
    cashFlowStatementData[year]['Excess STI Put into LTI'][ltiInvestment] = excessSTIPutintoLTIValue;
    /** End of Excess STI Put into LTI * */

    /** ********************************
    ** Long-Term Investment Balance **
    ********************************** */
    cashFlowStatementData[year]['Long-Term Investment Balance'] = {};
    let longTermInvestmentBalanceValue = 0;
    if ( typeof investmentsData !== 'undefined'
      && typeof investmentsData['Long-Term Investments'] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year] !== 'undefined'
      && typeof investmentsData['Long-Term Investments'][year]['Ending Balance'] !== 'undefined' ) {
      longTermInvestmentBalanceValue = investmentsData['Long-Term Investments'][year]['Ending Balance'];
    } else {
      longTermInvestmentBalanceValue = 0;
    }
    cashFlowStatementData[year]['Long-Term Investment Balance'] = longTermInvestmentBalanceValue;
    longTermInvestmentBalanceGraph.push( cashFlowStatementData[year]['Long-Term Investment Balance'] );
    /** End of Short-Term Investment Balance * */

    /** ******************
    ** Cash Flow Check **
    ******************** */
    if ( endingCashBalance < 0 && cashFlowStatementData['Cash Flow Check'] === -1 ) {
      cashFlowStatementData['Cash Flow Check'] = year;
    }
    /** Cash Flow Check * */
  }

  function cashFlowStatementDataFunc() {
    cashFlowStatementData['Beginning Cash Balance Graph'] = beginningCashBalanceGraph;
    cashFlowStatementData['Total Cash from Operating Activities Graph'] = totalCashFromOperatingActivitiesGraph;
    cashFlowStatementData['Total Cash from Investing Activities Graph'] = totalCashFromInvestingActivitiesGraph;
    cashFlowStatementData['Total Cash from Financing Activities Graph'] = totalCashFromFinancingActivitiesGraph;
    cashFlowStatementData['Net Change In Cash Graph'] = netChangeInCashGraph;
    cashFlowStatementData['Ending Cash Balance Graph'] = endingCashBalanceGraph;
    cashFlowStatementData['Short-Term Investment Balance'] = shortTermInvestmentBalanceGraph;
    cashFlowStatementData['Long-Term Investment Balance'] = longTermInvestmentBalanceGraph;
    cashFlowStatementData['Charitable Contributions Total'] = charitableContributionsTotal;
    cashFlowStatementData['Vacation Total'] = vacationValueTotal;
    cashFlowStatementData['Other Acquisition Total'] = watchValueTotal;
    cashFlowStatementData['Other Acquisition2 Total'] = customSuitValueTotal;
    return cashFlowStatementData;
  }
  return cashFlowStatementDataFunc();
}
export default cashFlowStatementSheet;