import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import styles from '../../../screens/pwi/higher-education/styles';
import _ from 'lodash';

const ChooseYourSchool = ( props ) => {
  const {
    classes, inputData, updateData, handleSlide, collegeList, listOfStates
  } = props;

  const [sortType, setSortType] = useState( '' );
  const [sortingData, setSortingData] = useState({});
  const [search, setSearch] = useState( '' );
  const [collegeSearchList, setCollegeSearchList] = useState( [] );
  const [collegeListData, setCollegeListData] = useState( [] );
  const [displayList,setDisplayList] = useState( [] );
  const [schoolId, setSchoolId] = useState( inputData.school !== undefined ? inputData.school : ''  );
  const [selectedPage, setSelectedPage] = useState(0)

  useEffect( () => {
    let collegeData = [];
    if(inputData.type_of_higher_education === 1) {
      collegeData = collegeList.career;
    } else if(inputData.type_of_higher_education === 2) {
      collegeData = collegeList.college;
    } else if(inputData.type_of_higher_education === 3) {
      collegeData = collegeList.graduate;
    } else if(inputData.type_of_higher_education === 4) {
      collegeData = collegeList.military;
    }
    collegeData = _.orderBy(collegeData,['school_name'],['asc']);
    setCollegeListData(collegeData);
    setCollegeSearchList(collegeData);
    setDisplayList(collegeData.slice(0,15));
  }, [] );

  const handleSearch = ( e ) => {
    setSearch( e.target.value );
    const data = collegeListData.filter( ( user ) => user.school_name.toLowerCase().search( e.target.value.toLowerCase() ) !== -1 );
    setCollegeSearchList( data );
    setDisplayList(data.slice(0,15));
    setSelectedPage(0)
  };

  const pageCount = collegeSearchList && Math.ceil( collegeSearchList.length / 15 );

  const handlePageClick = ( data ) => {
    const { selected } = data;
    setDisplayList(collegeSearchList.slice(selected,selected+15));
    setSelectedPage(selected)
  };


  const handleSchoolNameSingle = ( data ) => {

    let inputDetails = {...inputData};
    inputDetails['school'] = data.id;
    inputDetails['name_of_college'] = data.school_name;
    inputDetails['school_details'] = data;
    inputDetails['tuition'] = (data.in_state_tuition_fee !== undefined && data.in_state_tuition_fee !== null) ?  data.in_state_tuition_fee : data.tuition_fee;
    inputDetails['room_board'] = (inputDetails.type_of_higher_education !== 1 && data.room_board !== undefined) ? data.room_board : 0;
    if(data.state_name !== undefined && data.state_name !== '') {
        const stateIndex = _.findIndex(listOfStates,{'state_name':data.state_name});
        if(stateIndex >= 0) {
          inputDetails['state_id'] = listOfStates[stateIndex]['id'];
        } 
    }
    updateData('total_obj',inputDetails);
    setSchoolId(data.id);
  };

  const handleSort = (type) => {
    setSortType(sortType === type ? '' : type );
  }

  const handleSorting = ( name, order ) => {
    setSortingData({'column':name, 'order':order});
    const collegeData = _.orderBy(collegeSearchList,[name],[order.toLowerCase()]);
    setCollegeSearchList(collegeData);
    setDisplayList(collegeData.slice(0,15));
    setSortType('')
    setSelectedPage(0)
  };
  return (
    <div className={ classes.contentBlock }>
      {/*Career School*/}
      {inputData.type_of_higher_education === 1 ? <p>Where are you planning to go to career school? Use the search tool below to find and select your school.</p> : ''}
      {/*College*/}
      {inputData.type_of_higher_education === 2 ? <p>Where are you planning to go to college? Use the search tool below to find and select your school.</p> : ''}
      {/*Graduate School*/}
      {inputData.type_of_higher_education === 3 ? <p>Where are you planning to go to graduate school? Use the search tool below to find and select your school.</p> : ''}
      {/*Military School*/}
      {inputData.type_of_higher_education === 4 ? <p>Where are you planning to go to military school? Use the search tool below to find and select your school.</p> : ''}

      <p className={ classes.moreGap }>
        <i>
          Selected School:
          {' '}
          {inputData.name_of_college !== undefined && inputData.name_of_college}
        </i>
      </p>

      <p className={ `${classes.smallText} ${classes.clickCustomeSchool}` }>
        Use the search tool below and find your school. If you can&apos;t find your school,
        <span aria-hidden="true" onClick={ ()=>{handleSlide( 3, 'new' );} } className={ classes.paddingLeftNew }>click here.</span>
      </p>

      <div className={ classes.textFieldSearch }>
        {collegeSearchList && <NumberFormat value={ collegeSearchList.length } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator />}
        {' '}
        schools
        <TextField className={ classes.searchTextField } id="standard-basic" placeholder="Search" value={ search } onChange={ handleSearch } />
      </div>

      <div className={ classes.heigherEducationSearch }>
            <div className={ classNames( classes.ChooseSchoolTable, classes.selectedTable ) }>
              <table cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td>
                      Name of School
                      <span>
                        <i className={classNames(sortingData.column === 'school_name' && classes.filterActive,"fa fa-filter")}  aria-hidden="true" onClick={ () => handleSort( 'school_name' ) }></i>
                          {sortType === 'school_name' && (
                          <ul>
                            <li className={sortingData.column === 'school_name' && sortingData.order === 'ASC' && classes.filterSelected} aria-hidden="true" onClick={ () => handleSorting( 'school_name', 'ASC' ) }>Sort A to Z</li>
                            <li className={sortingData.column === 'school_name' && sortingData.order === 'DESC' && classes.filterSelected} aria-hidden="true" onClick={ () => handleSorting( 'school_name', 'DESC' ) }>Sort Z to A</li>
                          </ul>
                          )}
                      </span>
                    </td>
                    <td>
                      Location
                      <span>
                        <i className={classNames("fa fa-filter", sortingData.column === 'location' && classes.filterActive)} aria-hidden="true" onClick={ () => handleSort( 'location' ) }></i>
                          {sortType === 'location' && (
                          <ul>
                            <li className={sortingData.column === 'location' && sortingData.order === 'ASC' && classes.filterSelected}  onClick={ () => handleSorting( 'location', 'ASC' ) }>Sort A to Z</li>
                            <li className={sortingData.column === 'location' && sortingData.order === 'DESC' && classes.filterSelected}  onClick={ () => handleSorting( 'location', 'DESC' ) }>Sort Z to A</li>
                          </ul>
                          )}
                      </span>
                    </td>
                    {inputData.type_of_higher_education === 1 ? (
                      <td>
                        Tuition & Fees
                        <span>
                          <i className={classNames("fa fa-filter", sortingData.column === 'tuition_fee' && classes.filterActive)} aria-hidden="true" onClick={ () => handleSort( 'tuition_fees' ) }></i>
                            {sortType === 'tuition_fees' && (
                            <ul className={classes.adminDrop}>
                              <li className={sortingData.column === 'tuition_fee' && sortingData.order === 'DESC' && classes.filterSelected} onClick={ () => handleSorting( 'tuition_fee', 'DESC' ) }>Highest to Lowest</li>
                              <li className={sortingData.column === 'tuition_fee' && sortingData.order === 'ASC' && classes.filterSelected} onClick={ () => handleSorting( 'tuition_fee', 'ASC' ) }>Lowest to Highest</li>
                            </ul>
                            )}
                        </span>
                      </td>
                    ) : ''}
                    {inputData.type_of_higher_education !== 1 ? (
                      <td>
                        In-State Tuition & Fees
                        <span>
                          <i className={classNames("fa fa-filter", sortingData.column === 'in_state_tuition_fee' && classes.filterActive)} aria-hidden="true" onClick={ () => handleSort( 'in_state_tuition' ) }></i>
                          {sortType === 'in_state_tuition' && (
                          <ul className={classes.adminDrop}>
                            <li className={sortingData.column === 'in_state_tuition_fee' && sortingData.order === 'DESC' && classes.filterSelected} onClick={ () => handleSorting( 'in_state_tuition_fee', 'DESC' ) }>Highest to Lowest</li>
                            <li className={sortingData.column === 'in_state_tuition_fee' && sortingData.order === 'ASC' && classes.filterSelected} onClick={ () => handleSorting( 'in_state_tuition_fee', 'ASC' ) }>Lowest to Highest</li>
                          </ul>
                          )}
                        </span>
                      </td>
                    ) : ''}
                    {inputData.type_of_higher_education !== 1 ? (
                      <td>
                        Out-of-State Tuition & Fees
                        <span>
                          <i className={classNames("fa fa-filter", sortingData.column === 'out_state_tuition_fee' && classes.filterActive)} aria-hidden="true" onClick={ () => handleSort( 'out_state_tuition' ) }></i>
                          {sortType === 'out_state_tuition' && (
                          <ul className={classes.adminDrop}>
                            <li className={sortingData.column === 'out_state_tuition_fee' && sortingData.order === 'DESC' && classes.filterSelected} onClick={ () => handleSorting( 'out_state_tuition_fee', 'DESC' ) }>Highest to Lowest</li>
                            <li className={sortingData.column === 'out_state_tuition_fee' && sortingData.order === 'ASC' && classes.filterSelected} onClick={ () => handleSorting( 'out_state_tuition_fee', 'ASC' ) }>Lowest to Highest</li>
                          </ul>
                          )}
                        </span>
                      </td>
                    ) : ''}
                    {( inputData.type_of_higher_education === 2 || inputData.type_of_higher_education === 4 ) ? (
                      <td>
                        Room & Board
                        <span>
                          <i className={classNames("fa fa-filter", sortingData.column === 'room_board' && classes.filterActive)} aria-hidden="true" onClick={ () => handleSort( 'room_board' ) }></i>
                            {sortType === 'room_board' && (
                            <ul className={classes.adminDrop}>
                              <li className={sortingData.column === 'room_board' && sortingData.order === 'DESC' && classes.filterSelected} onClick={ () => handleSorting( 'room_board', 'DESC' ) }>Highest to Lowest </li>
                              <li className={sortingData.column === 'room_board' && sortingData.order === 'ASC' && classes.filterSelected} onClick={ () => handleSorting( 'room_board', 'ASC' ) }>Lowest to Highest</li>
                            </ul>
                            )}
                        </span>
                      </td>
                    ) : ''}
                  </tr>
                  {( displayList && displayList.length > 0 ) ? displayList.map( ( data ) => (
                    <tr className={ schoolId !== undefined && schoolId === data.id ? classes.listActive : '' } onClick={ () => handleSchoolNameSingle( data ) } onDoubleClick={ () => {handleSlide( 3, 'next' );} }>
                      <td>{data.school_name}</td>
                      <td>{data.location}</td>
                      {inputData.type_of_higher_education === 1 ? <td><NumberFormat value={ data.tuition_fee } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /></td> : ''}
                      {inputData.type_of_higher_education !== 1 ? <td><NumberFormat value={ data.in_state_tuition_fee } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /></td> : ''}
                      {inputData.type_of_higher_education !== 1 ? <td><NumberFormat value={ data.out_state_tuition_fee } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /></td> : ''}
                      {( inputData.type_of_higher_education === 2 || inputData.type_of_higher_education === 4 ) ? <td><NumberFormat value={ data.room_board } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /></td> : ''}
                    </tr>
                  ) ) : <tr className={ classes.noData }><td colSpan="5">No data found...</td></tr>}

                </tbody>
              </table>
              {( collegeSearchList && collegeSearchList.length > 0 )
              && (
              <div className={ classes.pagination }>
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel="..."
                  breakClassName="break-me"
                  pageCount={ pageCount }
                  forcePage={selectedPage}
                  marginPagesDisplayed={ 2 }
                  pageRangeDisplayed={ 5 }
                  onPageChange={ handlePageClick }
                  containerClassName="pagination"
                  subContainerClassName="pages pagination"
                  activeClassName="active"
                />
              </div>
              )}
            </div>
          </div>
    </div>
  );
};

ChooseYourSchool.propTypes = {
  classes: PropTypes.object.isRequired,
  collegeList: PropTypes.object.isRequired,
  listOfStates: PropTypes.arrayOf( PropTypes.object ).isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleSlide: PropTypes.func.isRequired,

};

export default withStyles( styles )( ChooseYourSchool );
