function ExpenseCalculations( module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, assumptionsData, module15Data, module17Data, module10Data ) {
  /** ******************** Expense Calculations *********************** */
  const expenseCalculationsDataObj = {};
  const multiplier = 12;
  let year = 0;
  let yearLimit = 0;
  expenseCalculationsDataObj['Higher Education - On-Campus Housing'] = {};
  expenseCalculationsDataObj['Higher Education - Off-Campus Housing'] = {};
  expenseCalculationsDataObj['Career Path'] = {};
  expenseCalculationsDataObj['Career Advancement'] = {};
  expenseCalculationsDataObj['More Higher Education - On-Campus Housing'] = {};
  expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'] = {};
  expenseCalculationsDataObj['Additional Career Advancement'] = {};
  expenseCalculationsDataObj['Further Career Advancement'] = {};
  expenseCalculationsDataObj['One-Time Acquisitions'] = {};

  /** ************************************
    * Higher Education-On-Campus-Housing **
    ************************************** */
  if ( module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year !== '' ) {
    expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year] = {};
    expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly = {};
    expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly = {};

    // Room & Board
    if ( module2Data.fetchRoomBoard !== undefined && module2Data.fetchRoomBoard !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Rent = module2Data.fetchRoomBoard;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Rent = parseFloat( module2Data.fetchRoomBoard * multiplier );
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Rent = 0;
    }

    // Food
    if ( module2Data.off_campus_food !== undefined && module2Data.off_campus_food !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Food = module2Data.off_campus_food;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Food = module2Data.off_campus_food * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Food = 0;
    }

    // Clothing
    if ( module2Data.clothing !== undefined && module2Data.clothing !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Clothing = module2Data.clothing;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Clothing = module2Data.clothing * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Clothing = 0;
    }

    // Entertainment
    if ( module2Data.entertainment !== undefined && module2Data.entertainment !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Entertainment = module2Data.entertainment;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Entertainment = module2Data.entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Entertainment = 0;
    }

    // Technology
    if ( module2Data.technology !== undefined && module2Data.technology !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Technology = module2Data.technology;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Technology = module2Data.technology * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Technology = 0;
    }

    // Transportation
    if ( module2Data.transportation !== undefined && module2Data.transportation !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Transportation = module2Data.transportation;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Transportation = module2Data.transportation * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Transportation = 0;
    }

    // Car Payment
    /*if ( module2Data.onCarPayment !== undefined && module2Data.onCarPayment !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Car Payment'] = module2Data.onCarPayment;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Car Payment'] = module2Data.onCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Car Payment'] = 0;
    }

    // Car Maitenance
    if ( module2Data.onCampusCarMaintenance !== undefined && module2Data.onCampusCarMaintenance !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Car Maintenance'] = module2Data.onCampusCarMaintenance;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Car Maintenance'] = module2Data.onCampusCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Car Maintenance'] = 0;
    }

    // Car Insurance
    if ( module2Data.onCampusCarInsurance !== undefined && module2Data.onCampusCarInsurance !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Car Insurance'] = module2Data.onCampusCarInsurance;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Car Insurance'] = module2Data.onCampusCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Car Insurance'] = 0;
    }

    // Gas
    if ( module2Data.onCampusGas !== undefined && module2Data.onCampusGas !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Gas = module2Data.onCampusGas;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Gas = module2Data.onCampusGas * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Gas = 0;
    }*/

    // Miscellaneous
    if ( module2Data.miscellaneous !== undefined && module2Data.miscellaneous !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Miscellaneous = module2Data.miscellaneous;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Miscellaneous = module2Data.miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly.Miscellaneous = 0;
    }

    // Health Insurance Costs outside Premium
    if ( module2Data.medical_costs_before_deductible !== undefined && module2Data.medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Health Insurance Costs outside Premium'] = module2Data.medical_costs_before_deductible;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Health Insurance Costs outside Premium'] = module2Data.medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    // Health Insurance Premium
    if ( module2Data.health_insurance_premium !== undefined && module2Data.health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Health Insurance Premium'] = module2Data.health_insurance_premium;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Health Insurance Premium'] = module2Data.health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['Higher Education - On-Campus Housing'][module2Data.on_campus_start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['Higher Education - On-Campus Housing'] = {};
  }

  /** *************************************
    * Higher Education-Off-Campus-Housing **
    *************************************** */
  if ( module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year !== '' ) {
    expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year] = {};
    expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly = {};
    expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly = {};

    // Rent
    if ( module2Data.rent !== undefined && module2Data.rent !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Rent = module2Data.rent;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Rent = module2Data.rent * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Rent = 0;
    }

    // Utilities
    if ( module2Data.utilities !== undefined && module2Data.utilities !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Utilities = module2Data.utilities;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Utilities = module2Data.utilities * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Utilities = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Utilities = 0;
    }

    // Food
    if ( module2Data.food !== undefined && module2Data.food !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Food = module2Data.food;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Food = module2Data.food * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Food = 0;
    }

    // Clothing
    if ( module2Data.second_clothing !== undefined && module2Data.second_clothing !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Clothing = module2Data.second_clothing;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Clothing = module2Data.second_clothing * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Clothing = 0;
    }

    // Entertainment
    if ( module2Data.second_entertainment !== undefined && module2Data.second_entertainment !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Entertainment = module2Data.second_entertainment;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Entertainment = module2Data.second_entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Entertainment = 0;
    }

    // Technology
    if ( module2Data.second_technology !== undefined && module2Data.second_technology !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Technology = module2Data.second_technology;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Technology = module2Data.second_technology * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Technology = 0;
    }

    // Transportation
    if ( module2Data.second_transportation !== undefined && module2Data.second_transportation !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Transportation = module2Data.second_transportation;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Transportation = module2Data.second_transportation * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Transportation = 0;
    }

    // Car Payment
    /*if ( module2Data.offCarPayment !== undefined && module2Data.offCarPayment !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Car Payment'] = module2Data.offCarPayment;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Car Payment'] = module2Data.offCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Car Payment'] = 0;
    }

    // Car Maintenance
    if ( module2Data.offCampusMaintenance !== undefined && module2Data.offCampusMaintenance !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Car Maintenance'] = module2Data.offCampusMaintenance;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Car Maintenance'] = module2Data.offCampusMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Car Maintenance'] = 0;
    }

    // Car Insurance
    if ( module2Data.offCampusCarInsurance !== undefined && module2Data.offCampusCarInsurance !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Car Insurance'] = module2Data.offCampusCarInsurance;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Car Insurance'] = module2Data.offCampusCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Car Insurance'] = 0;
    }

    // Gas
    if ( module2Data.offCampusGas !== undefined && module2Data.offCampusGas !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Gas = module2Data.offCampusGas;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Gas = module2Data.offCampusGas * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Gas = 0;
    }*/

    // Miscellaneous
    if ( module2Data.second_miscellaneous !== undefined && module2Data.second_miscellaneous !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Miscellaneous = module2Data.second_miscellaneous;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Miscellaneous = module2Data.second_miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly.Miscellaneous = 0;
    }

    // Health Insurance Costs outside Premium
    if ( module2Data.second_medical_costs_before_deductible !== undefined && module2Data.second_medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Health Insurance Costs outside Premium'] = module2Data.second_medical_costs_before_deductible;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Health Insurance Costs outside Premium'] = module2Data.second_medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    // Health Insurance Premium
    if ( module2Data.second_health_insurance_premium !== undefined && module2Data.second_health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Health Insurance Premium'] = module2Data.second_health_insurance_premium;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Health Insurance Premium'] = module2Data.second_health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['Higher Education - Off-Campus Housing'][module2Data.off_campus_start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['Higher Education - Off-Campus Housing'] = {};
  }

  /** *************
    * Career Path **
    *************** */
  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' ) {
    expenseCalculationsDataObj['Career Path'][module4Data.start_year] = {};
    expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly = {};
    expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly = {};

    // Rent
    if ( module4Data.rent !== undefined && module4Data.rent !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Rent = module4Data.rent;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Rent = module4Data.rent * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Rent = 0;
    }

    // Utilities
    if ( module4Data.utilities !== undefined && module4Data.utilities !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Utilities = module4Data.utilities;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Utilities = module4Data.utilities * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Utilities = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Utilities = 0;
    }

    // Food
    if ( module4Data.food !== undefined && module4Data.food !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Food = module4Data.food;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Food = module4Data.food * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Food = 0;
    }

    // Clothing
    if ( module4Data.clothing !== undefined && module4Data.clothing !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Clothing = module4Data.clothing;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Clothing = module4Data.clothing * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Clothing = 0;
    }

    // Entertainment
    if ( module4Data.entertainment !== undefined && module4Data.entertainment !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Entertainment = module4Data.entertainment;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Entertainment = module4Data.entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Entertainment = 0;
    }

    // Technology
    if ( module4Data.technology !== undefined && module4Data.technology !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Technology = module4Data.technology;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Technology = module4Data.technology * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Technology = 0;
    }

    // Transportation
    if ( module4Data.transportation !== undefined && module4Data.transportation !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Transportation = module4Data.transportation;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Transportation = module4Data.transportation * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Transportation = 0;
    }

    // Car Payment
    /*if ( module4Data.monthlyLivingExpensesCarPayment !== undefined && module4Data.monthlyLivingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Car Payment'] = module4Data.monthlyLivingExpensesCarPayment;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Car Payment'] = module4Data.monthlyLivingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Car Payment'] = 0;
    }

    // Car Maitenance
    if ( module4Data.monthlyLivingExpensesCarMaintenance !== undefined && module4Data.monthlyLivingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Car Maintenance'] = module4Data.monthlyLivingExpensesCarMaintenance;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Car Maintenance'] = module4Data.monthlyLivingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    // Car Insurance
    if ( module4Data.monthlyLivingExpensesCarInsurance !== undefined && module4Data.monthlyLivingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Car Insurance'] = module4Data.monthlyLivingExpensesCarInsurance;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Car Insurance'] = module4Data.monthlyLivingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Car Insurance'] = 0;
    }

    // Gas
    if ( module4Data.monthlyLivingExpensesGas !== undefined && module4Data.monthlyLivingExpensesGas !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Gas = module4Data.monthlyLivingExpensesGas;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Gas = module4Data.monthlyLivingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Gas = 0;
    }*/

    // Miscellaneous
    if ( module4Data.miscellaneous !== undefined && module4Data.miscellaneous !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Miscellaneous = module4Data.miscellaneous;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Miscellaneous = module4Data.miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly.Miscellaneous = 0;
    }

    // Health Insurance Costs outside Premium
    if ( module4Data.medical_costs_before_deductible !== undefined && module4Data.medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Health Insurance Costs outside Premium'] = module4Data.medical_costs_before_deductible;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Health Insurance Costs outside Premium'] = module4Data.medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    // Health Insurance Premium
    if ( module4Data.health_insurance_premium !== undefined && module4Data.health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Health Insurance Premium'] = module4Data.health_insurance_premium;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Health Insurance Premium'] = module4Data.health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['Career Path'][module4Data.start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['Career Path'] = {};
  }

  /** ********************
    * Career Advancement **
    ********************** */
  if ( module5Data.start_year !== undefined && module5Data.start_year !== '' ) {
    expenseCalculationsDataObj['Career Advancement'][module5Data.start_year] = {};
    expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly = {};
    expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly = {};

    // Rent
    if ( module5Data.rent !== undefined && module5Data.rent !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Rent = module5Data.rent;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Rent = module5Data.rent * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Rent = 0;
    }

    // Utilities
    if ( module5Data.utilities !== undefined && module5Data.utilities !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Utilities = module5Data.utilities;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Utilities = module5Data.utilities * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Utilities = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Utilities = 0;
    }

    // Food
    if ( module5Data.food !== undefined && module5Data.food !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Food = module5Data.food;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Food = module5Data.food * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Food = 0;
    }

    // Clothing
    if ( module5Data.clothing !== undefined && module5Data.clothing !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Clothing = module5Data.clothing;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Clothing = module5Data.clothing * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Clothing = 0;
    }

    // Entertainment
    if ( module5Data.entertainment !== undefined && module5Data.entertainment !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Entertainment = module5Data.entertainment;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Entertainment = module5Data.entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Entertainment = 0;
    }

    // Technology
    if ( module5Data.technology !== undefined && module5Data.technology !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Technology = module5Data.technology;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Technology = module5Data.technology * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Technology = 0;
    }

    // Transportation
    if ( module5Data.transportation !== undefined && module5Data.transportation !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Transportation = module5Data.transportation;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Transportation = module5Data.transportation * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Transportation = 0;
    }

    // Car Payment
    /*if ( module5Data.monthlyLivingExpensesCarPayment !== undefined && module5Data.monthlyLivingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Car Payment'] = module5Data.monthlyLivingExpensesCarPayment;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Car Payment'] = module5Data.monthlyLivingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Car Payment'] = 0;
    }

    // Car Maintenance
    if ( module5Data.monthlyLivingExpensesCarMaintenance !== undefined && module5Data.monthlyLivingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Car Maintenance'] = module5Data.monthlyLivingExpensesCarMaintenance;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Car Maintenance'] = module5Data.monthlyLivingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    // Car Insurance
    if ( module5Data.monthlyLivingExpensesCarInsurance !== undefined && module5Data.monthlyLivingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Car Insurance'] = module5Data.monthlyLivingExpensesCarInsurance;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Car Insurance'] = module5Data.monthlyLivingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Car Insurance'] = 0;
    }

    // Gas
    if ( module5Data.monthlyLivingExpensesGas !== undefined && module5Data.monthlyLivingExpensesGas !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Gas = module5Data.monthlyLivingExpensesGas;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Gas = module5Data.monthlyLivingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Gas = 0;
    }*/

    // Miscellaneous
    if ( module5Data.miscellaneous !== undefined && module5Data.miscellaneous !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Miscellaneous = module5Data.miscellaneous;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Miscellaneous = module5Data.miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly.Miscellaneous = 0;
    }

    // Health Insurance Costs outside Premium
    if ( module5Data.medical_costs_before_deductible !== undefined && module5Data.medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Health Insurance Costs outside Premium'] = module5Data.medical_costs_before_deductible;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Health Insurance Costs outside Premium'] = module5Data.medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    // Health Insurance Premium
    if ( module5Data.health_insurance_premium !== undefined && module5Data.health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Health Insurance Premium'] = module5Data.health_insurance_premium;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Health Insurance Premium'] = module5Data.health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['Career Advancement'][module5Data.start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['Career Advancement'] = {};
  }

  /** ***************************************
    * More Higher Education-Monthly Expenes **
    ***************************************** */
  if ( module6Data.start_year !== undefined && module6Data.start_year !== '' ) {
    expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year] = {};
    expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly = {};
    expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly = {};
    // below function is commented due to lack of moduleServices by chandrakala
    // moduleServices.moreFetchRoomBoard();
    if ( module6Data.fetchRoomBoard !== undefined && module6Data.fetchRoomBoard !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Rent = module6Data.fetchRoomBoard;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Rent = module6Data.fetchRoomBoard * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Rent = 0;
    }

    if ( module6Data.off_campus_food !== undefined && module6Data.off_campus_food !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Food = module6Data.off_campus_food;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Food = module6Data.off_campus_food * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Food = 0;
    }

    if ( module6Data.clothing !== undefined && module6Data.clothing !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Clothing = module6Data.clothing;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Clothing = module6Data.clothing * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Clothing = 0;
    }

    if ( module6Data.entertainment !== undefined && module6Data.entertainment !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Entertainment = module6Data.entertainment;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Entertainment = module6Data.entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Entertainment = 0;
    }

    if ( module6Data.technology !== undefined && module6Data.technology !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Technology = module6Data.technology;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Technology = module6Data.technology * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Technology = 0;
    }

    if ( module6Data.transportation !== undefined && module6Data.transportation !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Transportation = module6Data.transportation;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Transportation = module6Data.transportation * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Transportation = 0;
    }

    /*if ( module6Data.livingExpensesCarPayment !== undefined && module6Data.livingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Car Payment'] = module6Data.livingExpensesCarPayment;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Car Payment'] = module6Data.livingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Car Payment'] = 0;
    }

    if ( module6Data.livingExpensesCarMaintenance !== undefined && module6Data.livingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Car Maintenance'] = module6Data.livingExpensesCarMaintenance;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Car Maintenance'] = module6Data.livingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    if ( module6Data.livingExpensesCarInsurance !== undefined && module6Data.livingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Car Insurance'] = module6Data.livingExpensesCarInsurance;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Car Insurance'] = module6Data.livingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Car Insurance'] = 0;
    }

    if ( module6Data.livingExpensesGas !== undefined && module6Data.livingExpensesGas !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Gas = module6Data.livingExpensesGas;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Gas = module6Data.livingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Gas = 0;
    }*/

    if ( module6Data.miscellaneous !== undefined && module6Data.miscellaneous !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Miscellaneous = module6Data.miscellaneous;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Miscellaneous = module6Data.miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly.Miscellaneous = 0;
    }

    if ( module6Data.medical_costs_before_deductible !== undefined && module6Data.medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Health Insurance Costs outside Premium'] = module6Data.medical_costs_before_deductible;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Health Insurance Costs outside Premium'] = module6Data.medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    if ( module6Data.health_insurance_premium !== undefined && module6Data.health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Health Insurance Premium'] = module6Data.health_insurance_premium;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Health Insurance Premium'] = module6Data.health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['More Higher Education - On-Campus Housing'][module6Data.start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['More Higher Education - On-Campus Housing'] = {};
  }

  /** ******************************************
    * More Higher Education Off Campus Housing **
    ******************************************** */
  if ( module6Data.start_year !== undefined && module6Data.start_year !== '' ) {
    expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year] = {};
    expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly = {};
    expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly = {};

    if ( module6Data.rent !== undefined && module6Data.rent !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Rent = module6Data.rent;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Rent = module6Data.rent * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Rent = 0;
    }

    if ( module6Data.utilities !== undefined && module6Data.utilities !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Utilities = module6Data.utilities;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Utilities = module6Data.utilities * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Utilities = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Utilities = 0;
    }

    if ( module6Data.food !== undefined && module6Data.food !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Food = module6Data.food;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Food = module6Data.food * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Food = 0;
    }

    if ( module6Data.second_clothing !== undefined && module6Data.second_clothing !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Clothing = module6Data.second_clothing;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Clothing = module6Data.second_clothing * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Clothing = 0;
    }

    if ( module6Data.second_entertainment !== undefined && module6Data.second_entertainment !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Entertainment = module6Data.second_entertainment;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Entertainment = module6Data.second_entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Entertainment = 0;
    }

    if ( module6Data.second_technology !== undefined && module6Data.second_technology !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Technology = module6Data.second_technology;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Technology = module6Data.second_technology * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Technology = 0;
    }

    if ( module6Data.second_transportation !== undefined && module6Data.second_transportation !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Transportation = module6Data.second_transportation;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Transportation = module6Data.second_transportation * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Transportation = 0;
    }

    /*if ( module6Data.morelivingExpensesCarPayment !== undefined && module6Data.morelivingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly['Car Payment'] = module6Data.morelivingExpensesCarPayment;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly['Car Payment'] = module6Data.morelivingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly['Car Payment'] = 0;
    }

    if ( module6Data.morelivingExpensesCarMaintenance !== undefined && module6Data.morelivingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly['Car Maintenance'] = module6Data.morelivingExpensesCarMaintenance;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly['Car Maintenance'] = module6Data.morelivingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    if ( module6Data.morelivingExpensesCarInsurance !== undefined && module6Data.morelivingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly['Car Insurance'] = module6Data.morelivingExpensesCarInsurance;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly['Car Insurance'] = module6Data.morelivingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly['Car Insurance'] = 0;
    }

    if ( module6Data.morelivingExpensesGas !== undefined && module6Data.morelivingExpensesGas !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly.Gas = module6Data.morelivingExpensesGas;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly.Gas = module6Data.morelivingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.start_year].Yearly.Gas = 0;
    }*/

    if ( module6Data.second_miscellaneous !== undefined && module6Data.second_miscellaneous !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Miscellaneous = module6Data.second_miscellaneous;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Miscellaneous = module6Data.second_miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly.Miscellaneous = 0;
    }

    if ( module6Data.second_medical_costs_before_deductible !== undefined && module6Data.second_medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly['Health Insurance Costs outside Premium'] = module6Data.second_medical_costs_before_deductible;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly['Health Insurance Costs outside Premium'] = module6Data.second_medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    if ( module6Data.second_health_insurance_premium !== undefined && module6Data.second_health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly['Health Insurance Premium'] = module6Data.second_health_insurance_premium;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly['Health Insurance Premium'] = module6Data.second_health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'][module6Data.off_campus_start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['More Higher Education - Off-Campus Housing'] = {};
  }

  /** *******************************
    * Additional Career Advancement **
    ********************************* */
  if ( module8Data.start_year !== undefined && module8Data.start_year !== '' ) {
    expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year] = {};
    expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly = {};
    expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly = {};

    // Rent
    if ( module8Data.rent !== undefined && module8Data.rent !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Rent = module8Data.rent;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Rent = module8Data.rent * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Rent = 0;
    }

    // Utilities
    if ( module8Data.utilities !== undefined && module8Data.utilities !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Utilities = module8Data.utilities;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Utilities = module8Data.utilities * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Utilities = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Utilities = 0;
    }

    // Food
    if ( module8Data.food !== undefined && module8Data.food !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Food = module8Data.food;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Food = module8Data.food * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Food = 0;
    }

    // Clothing
    if ( module8Data.clothing !== undefined && module8Data.clothing !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Clothing = module8Data.clothing;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Clothing = module8Data.clothing * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Clothing = 0;
    }

    // Entertainment
    if ( module8Data.entertainment !== undefined && module8Data.entertainment !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Entertainment = module8Data.entertainment;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Entertainment = module8Data.entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Entertainment = 0;
    }

    // Technology
    if ( module8Data.technology !== undefined && module8Data.technology !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Technology = module8Data.technology;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Technology = module8Data.technology * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Technology = 0;
    }

    //  Transportation
    if ( module8Data.transportation !== undefined && module8Data.transportation !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Transportation = module8Data.transportation;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Transportation = module8Data.transportation * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Transportation = 0;
    }

    // Car Payment
    /*if ( module8Data.monthlyLivingExpensesCarPayment !== undefined && module8Data.monthlyLivingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Car Payment'] = module8Data.monthlyLivingExpensesCarPayment;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Car Payment'] = module8Data.monthlyLivingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Car Payment'] = 0;
    }

    // Car Maintanance
    if ( module8Data.monthlyLivingExpensesCarMaintenance !== undefined && module8Data.monthlyLivingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Car Maintenance'] = module8Data.monthlyLivingExpensesCarMaintenance;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Car Maintenance'] = module8Data.monthlyLivingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    // Car Insurance
    if ( module8Data.monthlyLivingExpensesCarInsurance !== undefined && module8Data.monthlyLivingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Car Insurance'] = module8Data.monthlyLivingExpensesCarInsurance;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Car Insurance'] = module8Data.monthlyLivingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Car Insurance'] = 0;
    }

    // Gas
    if ( module8Data.monthlyLivingExpensesGas !== undefined && module8Data.monthlyLivingExpensesGas !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Gas = module8Data.monthlyLivingExpensesGas;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Gas = module8Data.monthlyLivingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Gas = 0;
    }*/

    // Miscellaneous
    if ( module8Data.miscellaneous !== undefined && module8Data.miscellaneous !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Miscellaneous = module8Data.miscellaneous;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Miscellaneous = module8Data.miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly.Miscellaneous = 0;
    }

    // Health Insurance Costs outside Premium
    if ( module8Data.medical_costs_before_deductible !== undefined && module8Data.medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Health Insurance Costs outside Premium'] = module8Data.medical_costs_before_deductible;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Health Insurance Costs outside Premium'] = module8Data.medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    // Health Insurance Premium
    if ( module8Data.health_insurance_premium !== undefined && module8Data.health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Health Insurance Premium'] = module8Data.health_insurance_premium;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Health Insurance Premium'] = module8Data.health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['Additional Career Advancement'][module8Data.start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['Additional Career Advancement'] = {};
  }

  /** ***************************************
    * New Higher Education-Monthly Expenes **
    ***************************************** */
  if ( module15Data.start_year !== undefined && module15Data.start_year !== '' ) {
    // below statement added by chandrakala for reading the values from object
    expenseCalculationsDataObj['New Higher Education - On-Campus Housing'] = {};
    expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year] = {};
    expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly = {};
    expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly = {};
    // below function is commented due to lack of moduleServices by chandrakala
    // moduleServices.newFetchRoomBoard();
    if ( module15Data.fetchRoomBoard !== undefined && module15Data.fetchRoomBoard !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Rent = module15Data.fetchRoomBoard;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Rent = module15Data.fetchRoomBoard * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Rent = 0;
    }

    if ( module15Data.off_campus_food !== undefined && module15Data.off_campus_food !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Food = module15Data.off_campus_food;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Food = module15Data.off_campus_food * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Food = 0;
    }

    if ( module15Data.clothing !== undefined && module15Data.clothing !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Clothing = module15Data.clothing;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Clothing = module15Data.clothing * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Clothing = 0;
    }

    if ( module15Data.entertainment !== undefined && module15Data.entertainment !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Entertainment = module15Data.entertainment;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Entertainment = module15Data.entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Entertainment = 0;
    }

    if ( module15Data.technology !== undefined && module15Data.technology !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Technology = module15Data.technology;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Technology = module15Data.technology * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Technology = 0;
    }

    if ( module15Data.transportation !== undefined && module15Data.transportation !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Transportation = module15Data.transportation;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Transportation = module15Data.transportation * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Transportation = 0;
    }

    /*if ( module15Data.livingExpensesCarPayment !== undefined && module15Data.livingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Car Payment'] = module15Data.livingExpensesCarPayment;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Car Payment'] = module15Data.livingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Car Payment'] = 0;
    }

    if ( module15Data.livingExpensesCarMaintenance !== undefined && module15Data.livingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Car Maintenance'] = module15Data.livingExpensesCarMaintenance;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Car Maintenance'] = module15Data.livingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    if ( module15Data.livingExpensesCarInsurance !== undefined && module15Data.livingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Car Insurance'] = module15Data.livingExpensesCarInsurance;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Car Insurance'] = module15Data.livingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Car Insurance'] = 0;
    }

    if ( module15Data.livingExpensesGas !== undefined && module15Data.livingExpensesGas !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Gas = module15Data.livingExpensesGas;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Gas = module15Data.livingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Gas = 0;
    }*/

    if ( module15Data.miscellaneous !== undefined && module15Data.miscellaneous !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Miscellaneous = module15Data.miscellaneous;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Miscellaneous = module15Data.miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly.Miscellaneous = 0;
    }

    if ( module15Data.medical_costs_before_deductible !== undefined && module15Data.medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Costs outside Premium'] = module15Data.medical_costs_before_deductible;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Costs outside Premium'] = module15Data.medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    if ( module15Data.health_insurance_premium !== undefined && module15Data.health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Premium'] = module15Data.health_insurance_premium;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Premium'] = module15Data.health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['New Higher Education - On-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['New Higher Education - On-Campus Housing'] = {};
  }

  /** ******************************************
    * New Higher Education Off Campus Housing **
    ******************************************** */
  if ( module15Data.start_year !== undefined && module15Data.start_year !== '' ) {
    // below statement added by chandrakala for reading the values from object
    expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'] = {};
    expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year] = {};
    expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly = {};
    expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly = {};

    if ( module15Data.rent !== undefined && module15Data.rent !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Rent = module15Data.rent;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Rent = module15Data.rent * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Rent = 0;
    }

    if ( module15Data.utilities !== undefined && module15Data.utilities !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Utilities = module15Data.utilities;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Utilities = module15Data.utilities * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Utilities = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Utilities = 0;
    }

    if ( module15Data.food !== undefined && module15Data.food !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Food = module15Data.food;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Food = module15Data.food * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Food = 0;
    }

    if ( module15Data.second_clothing !== undefined && module15Data.second_clothing !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Clothing = module15Data.second_clothing;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Clothing = module15Data.second_clothing * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Clothing = 0;
    }

    if ( module15Data.second_entertainment !== undefined && module15Data.second_entertainment !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Entertainment = module15Data.second_entertainment;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Entertainment = module15Data.second_entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Entertainment = 0;
    }

    if ( module15Data.second_technology !== undefined && module15Data.second_technology !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Technology = module15Data.second_technology;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Technology = module15Data.second_technology * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Technology = 0;
    }

    if ( module15Data.second_transportation !== undefined && module15Data.second_transportation !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Transportation = module15Data.second_transportation;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Transportation = module15Data.second_transportation * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Transportation = 0;
    }

    /*if ( module15Data.morelivingExpensesCarPayment !== undefined && module15Data.morelivingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Car Payment'] = module15Data.morelivingExpensesCarPayment;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Car Payment'] = module15Data.morelivingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Car Payment'] = 0;
    }

    if ( module15Data.morelivingExpensesCarMaintenance !== undefined && module15Data.morelivingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Car Maintenance'] = module15Data.morelivingExpensesCarMaintenance;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Car Maintenance'] = module15Data.morelivingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    if ( module15Data.morelivingExpensesCarInsurance !== undefined && module15Data.morelivingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Car Insurance'] = module15Data.morelivingExpensesCarInsurance;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Car Insurance'] = module15Data.morelivingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Car Insurance'] = 0;
    }

    if ( module15Data.morelivingExpensesGas !== undefined && module15Data.morelivingExpensesGas !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Gas = module15Data.morelivingExpensesGas;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Gas = module15Data.morelivingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Gas = 0;
    }*/

    if ( module15Data.second_miscellaneous !== undefined && module15Data.second_miscellaneous !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Miscellaneous = module15Data.second_miscellaneous;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Miscellaneous = module15Data.second_miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly.Miscellaneous = 0;
    }

    if ( module15Data.second_medical_costs_before_deductible !== undefined && module15Data.second_medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Costs outside Premium'] = module15Data.second_medical_costs_before_deductible;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Costs outside Premium'] = module15Data.second_medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    if ( module15Data.second_health_insurance_premium !== undefined && module15Data.second_health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Premium'] = module15Data.second_health_insurance_premium;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Premium'] = module15Data.second_health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'][module15Data.start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['New Higher Education - Off-Campus Housing'] = {};
  }

  /** *******************************
    * Further Career Advancement **
    ********************************* */
  if ( module17Data.start_year !== undefined && module17Data.start_year !== '' ) {
    expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year] = {};
    expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly = {};
    expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly = {};

    // Rent
    if ( module17Data.rent !== undefined && module17Data.rent !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Rent = module17Data.rent;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Rent = module17Data.rent * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Rent = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Rent = 0;
    }

    // Utilities
    if ( module17Data.utilities !== undefined && module17Data.utilities !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Utilities = module17Data.utilities;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Utilities = module17Data.utilities * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Utilities = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Utilities = 0;
    }

    // Food
    if ( module17Data.food !== undefined && module17Data.food !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Food = module17Data.food;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Food = module17Data.food * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Food = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Food = 0;
    }

    // Clothing
    if ( module17Data.clothing !== undefined && module17Data.clothing !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Clothing = module17Data.clothing;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Clothing = module17Data.clothing * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Clothing = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Clothing = 0;
    }

    // Entertainment
    if ( module17Data.entertainment !== undefined && module17Data.entertainment !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Entertainment = module17Data.entertainment;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Entertainment = module17Data.entertainment * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Entertainment = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Entertainment = 0;
    }

    // Technology
    if ( module17Data.technology !== undefined && module17Data.technology !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Technology = module17Data.technology;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Technology = module17Data.technology * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Technology = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Technology = 0;
    }

    //  Transportation
    if ( module17Data.transportation !== undefined && module17Data.transportation !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Transportation = module17Data.transportation;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Transportation = module17Data.transportation * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Transportation = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Transportation = 0;
    }

    // Car Payment
    /*if ( module17Data.monthlyLivingExpensesCarPayment !== undefined && module17Data.monthlyLivingExpensesCarPayment !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Car Payment'] = module17Data.monthlyLivingExpensesCarPayment;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Car Payment'] = module17Data.monthlyLivingExpensesCarPayment * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Car Payment'] = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Car Payment'] = 0;
    }

    // Car Maintanance
    if ( module17Data.monthlyLivingExpensesCarMaintenance !== undefined && module17Data.monthlyLivingExpensesCarMaintenance !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Car Maintenance'] = module17Data.monthlyLivingExpensesCarMaintenance;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Car Maintenance'] = module17Data.monthlyLivingExpensesCarMaintenance * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Car Maintenance'] = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Car Maintenance'] = 0;
    }

    // Car Insurance
    if ( module17Data.monthlyLivingExpensesCarInsurance !== undefined && module17Data.monthlyLivingExpensesCarInsurance !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Car Insurance'] = module17Data.monthlyLivingExpensesCarInsurance;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Car Insurance'] = module17Data.monthlyLivingExpensesCarInsurance * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Car Insurance'] = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Car Insurance'] = 0;
    }

    // Gas
    if ( module17Data.monthlyLivingExpensesGas !== undefined && module17Data.monthlyLivingExpensesGas !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Gas = module17Data.monthlyLivingExpensesGas;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Gas = module17Data.monthlyLivingExpensesGas * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Gas = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Gas = 0;
    }*/

    // Miscellaneous
    if ( module17Data.miscellaneous !== undefined && module17Data.miscellaneous !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Miscellaneous = module17Data.miscellaneous;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Miscellaneous = module17Data.miscellaneous * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly.Miscellaneous = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly.Miscellaneous = 0;
    }

    // Health Insurance Costs outside Premium
    if ( module17Data.medical_costs_before_deductible !== undefined && module17Data.medical_costs_before_deductible !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Health Insurance Costs outside Premium'] = module17Data.medical_costs_before_deductible;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Health Insurance Costs outside Premium'] = module17Data.medical_costs_before_deductible * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Health Insurance Costs outside Premium'] = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Health Insurance Costs outside Premium'] = 0;
    }

    // Health Insurance Premium
    if ( module17Data.health_insurance_premium !== undefined && module17Data.health_insurance_premium !== '' ) {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Health Insurance Premium'] = module17Data.health_insurance_premium;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Health Insurance Premium'] = module17Data.health_insurance_premium * multiplier;
    } else {
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Monthly['Health Insurance Premium'] = 0;
      expenseCalculationsDataObj['Further Career Advancement'][module17Data.start_year].Yearly['Health Insurance Premium'] = 0;
    }
  } else {
    expenseCalculationsDataObj['Further Career Advancement'] = {};
  }

  /** ***********************
    * One-Time Acquisitions **
    ************************* */
  expenseCalculationsDataObj['One-Time Acquisitions'] = {};
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet'] = {};
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs'] = {};
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['Purchase Price'] = {};
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['First Vet Visit'] = {};
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['New Supplies'] = {};
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['Apartment Pet Fee'] = {};

  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['Purchase Price'].Costs = 500;

  let petFirstVetVisitCost = 200;
  let newSupplies = 150;
  let newMiscellaneous = 200;

  if ( module10Data.save_buying_pet_new_values !== undefined && module10Data.save_buying_pet_new_values === 'Yes' ) {
    if ( module10Data.default_pet_first_vet_visit !== undefined && module10Data.default_pet_first_vet_visit !== '' ) {
      petFirstVetVisitCost = module10Data.default_pet_first_vet_visit;
    }
    if ( module10Data.default_pet_new_supplies !== undefined && module10Data.default_pet_new_supplies !== '' ) {
      newSupplies = module10Data.default_pet_new_supplies;
    }
    if ( module10Data.default_pet_miscellaneous !== undefined && module10Data.default_pet_miscellaneous !== '' ) {
      newMiscellaneous = module10Data.default_pet_miscellaneous;
    }
  }

  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['First Vet Visit'].Costs = petFirstVetVisitCost;
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['New Supplies'].Costs = newSupplies;
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['Apartment Pet Fee'].Costs = newMiscellaneous;
  expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Total Buying Costs'] = 1050;

  expenseCalculationsDataObj['Yearly Costs'] = {};
  expenseCalculationsDataObj['Yearly Costs']['Vet Visits'] = {};
  expenseCalculationsDataObj['Yearly Costs'].Food = {};
  expenseCalculationsDataObj['Yearly Costs'].Toys = {};
  expenseCalculationsDataObj['Yearly Costs']['Emergency Fund'] = {};
  expenseCalculationsDataObj['Yearly Costs']['Pet Health Insurance'] = {};
  expenseCalculationsDataObj['Yearly Costs'].Miscellaneous = {};
  expenseCalculationsDataObj['Yearly Costs'].Registration = {};

  let annualVetVisitsCost = 100;
  let annualFoodCost = 200;
  let annualToysCost = 150;
  let annualEmergencyFundCost = 150;
  let annualPetHealthInsurance = 275;
  let annualMiscellaneous = 200;
  let annualRegistration = 20;

  if ( module10Data.save_annual_pet_new_values !== undefined && module10Data.save_annual_pet_new_values === 'Yes' ) {
    if ( module10Data.default_annual_pet_vet_visits !== undefined && module10Data.default_annual_pet_vet_visits !== '' ) {
      annualVetVisitsCost = module10Data.default_annual_pet_vet_visits;
    }

    if ( module10Data.default_annual_pet_food !== undefined && module10Data.default_annual_pet_food !== '' ) {
      annualFoodCost = module10Data.default_annual_pet_food;
    }

    if ( module10Data.default_annual_pet_toys !== undefined && module10Data.default_annual_pet_toys !== '' ) {
      annualToysCost = module10Data.default_annual_pet_toys;
    }

    if ( module10Data.default_annual_pet_emergency_fund !== undefined && module10Data.default_annual_pet_emergency_fund !== '' ) {
      annualEmergencyFundCost = module10Data.default_annual_pet_emergency_fund;
    }

    if ( module10Data.default_annual_pet_health_insurance !== undefined && module10Data.default_annual_pet_health_insurance !== '' ) {
      annualPetHealthInsurance = module10Data.default_annual_pet_health_insurance;
    }

    if ( module10Data.default_annual_pet_registration !== undefined && module10Data.default_annual_pet_registration !== '' ) {
      annualRegistration = module10Data.default_annual_pet_registration;
    }

    if ( module10Data.default_annual_pet_miscellaneous !== undefined && module10Data.default_annual_pet_miscellaneous !== '' ) {
      annualMiscellaneous = module10Data.default_annual_pet_miscellaneous;
    }
  }

  expenseCalculationsDataObj['Yearly Costs']['Vet Visits'].Costs = annualVetVisitsCost;
  expenseCalculationsDataObj['Yearly Costs'].Food.Costs = annualFoodCost;
  expenseCalculationsDataObj['Yearly Costs'].Toys.Costs = annualToysCost;
  expenseCalculationsDataObj['Yearly Costs']['Emergency Fund'].Costs = annualEmergencyFundCost;
  expenseCalculationsDataObj['Yearly Costs']['Pet Health Insurance'].Costs = annualPetHealthInsurance;
  expenseCalculationsDataObj['Yearly Costs'].Miscellaneous.Costs = annualMiscellaneous;
  expenseCalculationsDataObj['Yearly Costs'].Registration.Costs = annualRegistration;

  expenseCalculationsDataObj['Total Yearly Costs'] = {};
  expenseCalculationsDataObj['Total Yearly Costs'].Registration = {};
  expenseCalculationsDataObj['Total Yearly Costs'].Registration.Costs = 20;

  expenseCalculationsDataObj['Total Yearly Cost of Owning a Pet'] = {};
  expenseCalculationsDataObj['Total Yearly Cost of Owning a Pet'].Costs = 1095;

  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;
  let k = 0;

  let purchasePrice = 0;
  let firstVetVisit = 0;
   newSupplies = 0;
  let miscellaneous = 0;

  let vetVisits = 0;
  let food = 0;
  let toys = 0;
  let emergencyFund = 0;
  let petInsurance = 0;
  let miscellaneous2 = 0;
  let registration = 0;

  for ( year; year < yearLimit; year += 1 ) {
    expenseCalculationsDataObj[year] = {};

    expenseCalculationsDataObj[year]['Initial Costs'] = {};
    expenseCalculationsDataObj[year]['Yearly Costs'] = {};
    if ( module10Data.year_of_purchase !== undefined && module10Data.year_of_purchase !== '' && year === module10Data.year_of_purchase && module10Data.purchase_price !== undefined && module10Data.purchase_price !== '' ) {
      const yrOfPurchse = module10Data.purchase_price;
      purchasePrice = yrOfPurchse;
    } else {
      purchasePrice = 0;
    }
    if ( k === 0 ) {
       firstVetVisit = expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['First Vet Visit'].Costs;
       newSupplies = expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['New Supplies'].Costs;
       miscellaneous = expenseCalculationsDataObj['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['Apartment Pet Fee'].Costs;
      
       vetVisits = expenseCalculationsDataObj['Yearly Costs']['Vet Visits'].Costs;
       food = expenseCalculationsDataObj['Yearly Costs'].Food.Costs;
       toys = expenseCalculationsDataObj['Yearly Costs'].Toys.Costs;
       emergencyFund = expenseCalculationsDataObj['Yearly Costs']['Emergency Fund'].Costs;
       petInsurance = expenseCalculationsDataObj['Yearly Costs']['Pet Health Insurance'].Costs;
       miscellaneous2 = expenseCalculationsDataObj['Yearly Costs'].Miscellaneous.Costs;
       registration = expenseCalculationsDataObj['Yearly Costs'].Registration.Costs;
      
      /*purchasePrice   = module10Data.purchase_price;
      firstVetVisit = module10Data.default_pet_first_vet_visit;
      newSupplies = module10Data.default_pet_new_supplies;
      miscellaneous = module10Data.default_pet_miscellaneous;
      
      vetVisits = module10Data.default_annual_pet_vet_visits;
      food = module10Data.default_annual_pet_food;
      toys = module10Data.default_annual_pet_toys;
      emergencyFund = module10Data.default_annual_pet_emergency_fund;
      petInsurance = module10Data.default_annual_pet_health_insurance;
      miscellaneous2 = module10Data.default_annual_pet_miscellaneous;
      registration = module10Data.default_annual_pet_registration;*/
    } else if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
      //purchasePrice *= ( ( assumptionsData[year].Expenses ) / 100 );
      firstVetVisit *= ( ( assumptionsData[year].Expenses ) / 100 );
      newSupplies *= ( ( assumptionsData[year].Expenses ) / 100 );
      miscellaneous *= ( ( assumptionsData[year].Expenses ) / 100 );

      vetVisits *= ( ( assumptionsData[year].Expenses ) / 100 );
      food *= ( ( assumptionsData[year].Expenses ) / 100 );
      toys *= ( ( assumptionsData[year].Expenses ) / 100 );
      emergencyFund *= ( ( assumptionsData[year].Expenses ) / 100 );
      petInsurance *= ( ( assumptionsData[year].Expenses ) / 100 );
      miscellaneous2 *= ( ( assumptionsData[year].Expenses ) / 100 );
      registration *= ( ( assumptionsData[year].Expenses ) / 100 );
    } else {
      firstVetVisit = 0;
      newSupplies = 0;
      miscellaneous = 0;

      vetVisits = 0;
      food = 0;
      toys = 0;
      emergencyFund = 0;
      petInsurance = 0;
      miscellaneous2 = 0;
      registration = 0;
    }
    k += 1;
    
    expenseCalculationsDataObj[year]['Initial Costs']['Purchase Price'] = purchasePrice;
    expenseCalculationsDataObj[year]['Initial Costs']['First Vet Visit'] = firstVetVisit;
    expenseCalculationsDataObj[year]['Initial Costs']['New Supplies'] = newSupplies;
    expenseCalculationsDataObj[year]['Initial Costs'].Miscellaneous = miscellaneous;
    expenseCalculationsDataObj[year]['Initial Costs'].Total = purchasePrice+firstVetVisit + newSupplies + miscellaneous;
    
    expenseCalculationsDataObj[year]['Yearly Costs']['Vet Visits'] = vetVisits;
    expenseCalculationsDataObj[year]['Yearly Costs'].Food = food;
    expenseCalculationsDataObj[year]['Yearly Costs'].Toys = toys;
    expenseCalculationsDataObj[year]['Yearly Costs']['Emergency Fund'] = emergencyFund;
    expenseCalculationsDataObj[year]['Yearly Costs']['Pet Health Insurance'] = petInsurance;
    expenseCalculationsDataObj[year]['Yearly Costs'].Miscellaneous = miscellaneous2;
    expenseCalculationsDataObj[year]['Yearly Costs'].Registration = registration;
    expenseCalculationsDataObj[year]['Yearly Costs'].Total = vetVisits + food + toys + emergencyFund + petInsurance + miscellaneous2 + registration;
  }
  // To return Expense Calculations Complete Data
  function expenseCalculationsData() {
    return expenseCalculationsDataObj;
  }

  // below statement added by chandrakala to console output data
  return expenseCalculationsData();
}

export default ExpenseCalculations;