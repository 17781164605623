import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import powerImg from '../../assets/img/get-powerup-lite/power_img.png';
import greenCheck from '../../assets/img/get-powerup-lite/poweruplite_sales_check_bg.png';
import lessonOne from '../../assets/img/get-powerup-lite/powerup_lite_five_1.jpg';
import lessonTwo from '../../assets/img/get-powerup-lite/powerup_lite_five_2.jpg';
import lessonThree from '../../assets/img/get-powerup-lite/powerup_lite_five_3.jpg';
import lessonFour from '../../assets/img/get-powerup-lite/powerup_lite_five_4.jpg';
import lessonFive from '../../assets/img/get-powerup-lite/powerup_lite_five_5.jpg';
import certificateImg from '../../assets/img/get-powerup-lite/poweruplite_sales.png';

const GetPowerUpLite = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.getPowerUpLite }>
        <div className={ classes.powerBanner }>
          <div className={ classes.container }>
            <div className={ classes.bannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography variant="h3" component="h3" className={ classes.bannerTitleNew }>
                  Where else can you earn $25,000+ per hour?
                </Typography>
                <p>Ignite your future in PowerUp Lite.</p>
                <Link to="/">
                  SHOW ME THE MONEY
                  {' '}
                  {' '}
                  {'>'}
                  {' '}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Container className={ classes.container }>
          <div className={ classes.powerUpSmartMoves }>
            <div className={ classes.container }>
              <Typography variant="h2" component="h2">
                Spend just four hours in Inkwiry&apos;s short online course,
                <span className={ classes.blockSpan }>
                  PowerUp Lite, and learn a
                  {' '}
                  <b>few smart moves</b>
                  {' '}
                  that can help you
                </span>
                <span className={ classes.blockSpan }>
                  <b>add $100,000+</b>
                  {' '}
                  to your 15-year net worth.
                </span>
              </Typography>
            </div>
          </div>
          <div className={ classes.powerUpClaim }>
            <Grid container>
              <Grid item sm={ 4 } xs={ 12 }>
                <div className={ classes.leftImg }>
                  <figure>
                    <img src={ powerImg } alt="" />
                  </figure>
                </div>
              </Grid>
              <Grid item sm={ 8 } xs={ 12 }>
                <div className={ classes.rightContent }>
                  <p>
                    <span className={ classes.bluesSpanColor }>Sounds like a pretty big claim.</span>
                    {' '}
                    But if you’re a student – either in high school, college, career school or graduate school – or a young professional,
                    PowerUp Lite will reveal surprising strategies that can supercharge your financial moves. When you get smarter about your planning, you end up with more money made and less money lost, positioning yourself to achieve financial freedom.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.circlesBlock }>
            <div className={ classes.circlesSection }>
              <div className={ classNames( classes.roundCircle, classes.circleOne ) }>
                <div className={ classes.circleText }>
                  <span>Maybe I&apos;m lazy but </span>
                  <span>is there any way I</span>
                  <span>can put this off?</span>
                </div>
              </div>
              <div className={ classNames( classes.roundCircle, classes.circletwo ) }>
                <div className={ classes.circleText }>
                  <span>I don&apos;t have a crystal ball.</span>
                  <span>Things are always changing.</span>
                  <span>How can I plan for next</span>
                  <span>week let alone years?</span>
                </div>
              </div>
              <div className={ classNames( classes.roundCircle, classes.circleThree ) }>
                <div className={ classes.circleText }>
                  <span>I&apos;ve tried to plan</span>
                  <span>but it&apos;s just too difficult</span>
                  <span>to organize my data.</span>
                </div>
              </div>
            </div>
            <div className={ classes.circlesBottom }>
              Planning is bringing the future into the present so that you can do something about it now.
              <span>— Alan Lakein</span>
            </div>
          </div>
          <div className={ classes.financialFuture }>
            <Typography variant="h2" component="h2">
              How can PowerUp Lite
              {' '}
              <b>dramatically</b>
              {' '}
              <span className={ classes.blockSpan }>
                <b>improve</b>
                {' '}
                your financial future?
              </span>
            </Typography>
            <div className={ classes.skillsKnowledge }>
              <div className={ classes.skillsContent }>
                <img className={ classes.checkImage } src={ greenCheck } alt="" />
                PowerUp Lite builds a foundation of skills and knowledge that empower you to leverage Inkwiry&apos;s suite of Apps + Tools to develop your own financial life plan. Although it&apos;s the lite version of our more comprehensive online course, Inkwiry Personal Finance PowerUp, there&apos;s nothing light-weight about what you&apos;ll learn.
              </div>
              <p>If you&apos;re a high school student thinking about college and career, or already in some type of higher education, the smart moves you&apos;ll learn in PowerUp Lite will change the way you think about managing your money and debt. We&apos;re not kidding - the proverbial light bulb will switch on in your head!</p>
              <div className={ classes.financialList }>
                <Typography variant="h3" component="h3">In PowerUp Lite, you&apos;ll:</Typography>
                <ul>
                  <li>Understand how you can efficiently plan for as many as 15 years into the future.</li>
                  <li>Discover smart moves you can start using immediately to avoid life&apos;s traps and pitfalls, which means you end up with money saved plus money not lost.</li>
                  <li>Venture into Career Sketching and try your hand with editing data inputs and seeing instant results.</li>
                  <li>Figure out where to start and how to assemble all your information in a meaningful way.</li>
                  <li>Raise your awareness about money, education, current trends and how these factors can affect your financial life plan.</li>
                  <li>Have fun with compounding interest, everyday budgets and socking money away for the things that matter most.</li>
                  <li>Learn how to ask &ldquo;what if?&rdquo; and use Inkwiry&apos;s tools to find your own best answers, minimizing your risk and maximizing reward.</li>
                  <li>Grow your confidence about planning and how it can provide peace of mind.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={ classes.powerUpSalesFive }>
            <Typography variant="h2" component="h2">
              Five
              {' '}
              <b>information-charged</b>
              {' '}
              lessons
              <span className={ classes.blockSpan }>
                to
                {' '}
                <b>power your financial life planning</b>
              </span>
            </Typography>
            <div className={ classes.powerUpSalesCntnt }>
              <h3>Here&apos;s what you&apos;ll learn:</h3>
              <div className={ classNames( classes.infoLesson, classes.lessonOne ) }>
                <div className={ classNames( classes.lessonLeft, classes.firstLesson ) }>
                  <Typography variant="h3" component="h3">LESSON 01</Typography>
                  <img src={ lessonOne } alt="" />
                </div>
                <div className={ classes.lessonDesc }>
                  <span>Gain Awareness</span>
                  <ul>
                    <li>Raise your awareness about the power of planning.</li>
                    <li>Consider the role of education, current trends in the workforce and other topics than can affect your financial life going forward</li>
                    <li>Review the Big Question</li>
                    <li>Explore the 10 most important financial life questions</li>
                  </ul>
                </div>
              </div>
              <div className={ classNames( classes.infoLesson, classes.lessonTwo ) }>
                <div className={ classNames( classes.lessonLeft, classes.secondLesson ) }>
                  <Typography variant="h3" component="h3">LESSON 02</Typography>
                  <img src={ lessonTwo } alt="" />
                </div>
                <div className={ classes.lessonDesc }>
                  <span>Career Sketching</span>
                  <ul>
                    <li>Learn what Career Sketching is and how it works</li>
                    <li>Discover what goes into a complete financial life plan</li>
                    <li>Get valuable insights and powerful strategies</li>
                    <li>See how you can create a roadmap to your future</li>
                  </ul>
                </div>
              </div>
              <div className={ classNames( classes.infoLesson, classes.lessonThree ) }>
                <div className={ classNames( classes.lessonLeft, classes.thirdLesson ) }>
                  <Typography variant="h3" component="h3">LESSON 03</Typography>
                  <img src={ lessonThree } alt="" />
                </div>
                <div className={ classes.lessonDesc }>
                  <span>Dig Into the Details</span>
                  <ul>
                    <li>Make big picture assumptions about your life</li>
                    <li>Build your own Career Sketching timeline</li>
                    <li>Learn how Inkwiry&apos;s Financial Configurator (FC) facilitates planning</li>
                    <li>Start to build your financial skills and knowledge</li>
                  </ul>
                </div>
              </div>
              <div className={ classNames( classes.infoLesson, classes.lessonFour ) }>
                <div className={ classNames( classes.lessonLeft, classes.fourthLesson ) }>
                  <Typography variant="h3" component="h3">LESSON 04</Typography>
                  <img src={ lessonFour } alt="" />
                </div>
                <div className={ classes.lessonDesc }>
                  <span>Test and Analyze</span>
                  <ul>
                    <li>Navigate the Dashboard – visually track and analyze your data in real time</li>
                    <li>Examine personal financial statements</li>
                    <li>Understand Key Performance Indicators (KPIs)</li>
                    <li>Evaluate interactive visualizations</li>
                  </ul>
                </div>
              </div>
              <div className={ classNames( classes.infoLesson, classes.lessonFive ) }>
                <div className={ classNames( classes.lessonLeft, classes.fifthLesson ) }>
                  <Typography variant="h3" component="h3">LESSON 05</Typography>
                  <img src={ lessonFive } alt="" />
                </div>
                <div className={ classes.lessonDesc }>
                  <span>Experience the Power of Planning</span>
                  <ul>
                    <li>Try your hand in Inkwiry modules as you modify a career sketch</li>
                    <li>Follow tutorials to make smart moves in real time</li>
                    <li>See how small changes can add up to big savings</li>
                    <li>Consider the smart moves you can make in your own life to add to your 15-year net worth.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className={ classes.salesCertificate }>
          <Container className={ classes.container }>
            <div className={ classes.powerToday }>
              <Typography variant="h2" component="h2">Get PowerUp Lite today!</Typography>
              <p>
                <span className={ classes.bluesSpanColor }>PowerUp Lite comes with your $42 Gold Membership to Inkwiry.</span>
                {' '}
                You’ll find the return on investment is remarkable considering you can use what you learn to
                {' '}
                <span className={ classes.bluesSpanColor }>add $100,00 or more to your 15-year net worth.</span>
                {' '}
                You’ll have plenty of time during your six-month membership to complete the four-hour course
                {' '}
                <b>PLUS</b>
                {' '}
                use Plan With Inkwiry to quickly build a financial life plan. (We truly hope you’ll stick around to learn even more smart moves so that in 5, 10, 15 years, you’ll end up with additional money saved plus money not lost.)
              </p>
              <div className={ classes.completeCertificate }>
                <div className={ classes.certificateLeft }>
                  <p>
                    And when you’ve finished PowerUp Lite, you&apos;ll receive a
                    {' '}
                    <b>Certificate of Completion</b>
                    {' '}
                    and join the growing ranks of Inkwiry users with the financial smarts to be their own best advisor on the road to financial independence.
                    {' '}
                  </p>
                  <p>Just click on the button to sign up!</p>
                </div>
                <div className={ classes.certificateRight }>
                  <img src={ certificateImg } alt="" />
                </div>
              </div>
              <a href="/" className={ classes.powerLite }>GET POWERUP LITE</a>
            </div>
          </Container>
        </div>
        <Container className={ classes.container }>
          <div className={ classes.powerUpSaleslast }>
            <div className={ classes.powerUpSalesBoxes }>
              <p className={ classes.salesBoxOne }>
                <span>Inkwiry enabled me to confidently navigate</span>
                {' '}
                <span>getting my master&apos;s in engineering. That</span>
                {' '}
                <span>smart move led to a 55% increase in salary</span>
                {' '}
                <span>and unlocked a lifetime of potential.&quot;</span>
                <span className={ classes.authorQuote }>– Matt P., Master&apos;s in Civil Engineering Student, Temple University</span>
              </p>
              <p className={ classes.salesBoxTwo }>
                <span>After graduating with tens of thousands</span>
                {' '}
                <span> of dollars in student loans, I was overwhelmed to</span>
                {' '}
                <span>say the least. But, once I entered my data into</span>
                <span> Inkwiry&apos;s Financial Configurator, I discovered that</span>
                {' '}
                <span>not only could I repay my loans on schedule, I</span>
                {' '}
                <span>could have additional savings as well.&quot;</span>
                <span className={ classes.authorQuote }>
                  – Alex V., in PhD program, Nursing Practice
                  <span> for Nurse Anesthetists</span>
                </span>
              </p>
              <p className={ classes.salesBoxThree }>
                <span>I received a couple of job offers across the</span>
                {' '}
                <span>country – San Francisco and New York City.</span>
                {' '}
                <span>I didn&apos;t know where to begin to figure out what</span>
                {' '}
                <span>was best for me. With Inkwiry, I could develop</span>
                {' '}
                <span>multiple scenarios and career sketches, analyze</span>
                {' '}
                <span>each job offer and make my best move.&quot;</span>
                <span className={ classes.authorQuote }>– Dean B., Director of Brand Partnerships</span>
              </p>
            </div>
            <div className={ classes.loseNothing }>
              <h2>
                There&apos;s nothing to
                {' '}
                <b>lose</b>
                , everything to
                {' '}
                <b>gain</b>
              </h2>
              <h3>Our 100% Satisfaction Guarantee</h3>
              <p>PowerUp Lite comes with a 100% Satisfaction Guarantee. We hope your financial life is forever transformed with the beginning financial skills and knowledge you develop through this course, and that you&apos;ll continue on in Inkwiry to build your financial life plan. We also expect you to give PowerUp Lite your very best effort. That&apos;s why in order to be eligible for our 30-Day Satisfaction Guarantee, you must submit proof that you did the coursework.</p>
              <p>To be clear, if you fully complete PowerUp Lite (within the 30-day window) and believe it&apos;s not for you, you may request a refund. We&apos;ll check to confirm you&apos;ve completed PowerUp Lite, and if you worked through all five lessons, we&apos;ll give you your money back.</p>
            </div>
            <div className={ classes.fewMoves }>
              <div className={ classes.movesImg }>
                <img src={ powerImg } alt="" />
              </div>
              <div className={ classes.fewMovesRight }>
                <h2>
                  <span>Light up your financial future </span>
                  with a few smart moves
                </h2>
                <p className={ classes.bottommargin }>When it comes to building your financial life plan and managing your financial future, the earlier you start planning, the better. </p>
                <p className={ classes.noMargin }>Ignite your future with PowerUp Lite today!</p>
              </div>
            </div>
            <a href="/" className={ classes.powerUpLite }>I WANT POWERUP LITE!</a>
          </div>
        </Container>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

GetPowerUpLite.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( GetPowerUpLite );
