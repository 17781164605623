import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Typography, Table } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const WillHigherInterestRatesCooltheStockMarket = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Will Higher Interest Rates Cool the Stock Market? </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/Interest-rates-stock-market.png "
                      alt=""
                    />
                  </div>
                  <div
                    className={classNames(
                      classes.pfHeadingTitle,
                      classes.pfHeadingTitleTwo,
                    )}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Will Higher Interest Rates Cool the Stock Market?
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/fed-reserve-187px.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          As anticipation builds for the first interest rate
                          increase in four years, history shows US stocks and
                          bonds tend to perform positively in the 12 months that
                          follow. But how do they perform over the next five
                          years?{' '}
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          US stock market indices fell today (2/11/22) as
                          inflation continues to set new records.{' '}
                          <Link
                            to="/finance-explained/inflation-and-your-money"
                            target="_blank">
                            Inflation
                          </Link>{' '}
                          rose 7.5% in the 12 months ending in January, which is
                          the largest annual increase since February 1982.
                          Remember, inflation is the rise in price of goods and
                          services expressed as a percentage over a specific
                          period. If you compare this month&apos;s grocery bill
                          or cost at the pump to last year, you'll come to
                          understand inflation very quickly.
                        </p>
                        <p>
                          When{' '}
                          <Link
                            to="/finance-explained/keeping-up-with-inflation"
                            target="_blank">
                            inflation runs too hot
                          </Link>
                          , the{' '}
                          <Link
                            to="/finance-explained/federal-reserve"
                            target="_blank">
                            Federal Reserve
                          </Link>{' '}
                          (Fed) typically raises interest rates to slow down the
                          economy. When the cost of borrowing money increases,
                          businesses borrow less money, hire fewer people and
                          produce fewer goods and services. In addition,
                          consumers' cost of borrowing increases, which means
                          they have less money to spend on goods and services.
                          In theory, when the Fed raises interest rates,
                          spending on goods and services should slow down and
                          the economy should slow down as well.
                        </p>
                        <p>
                          If the economy slows down, isn&apos;t that bad for
                          investing? Let&apos;s check the history book.
                        </p>
                      </div>
                    </div>
                    <div className={classes.feTextTitle}>
                      <h3>12 months after the first interest rate increase</h3>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item sm={5} md={5}>
                        <div
                          className={classes.feTextGraph}
                          style={{ border: '1px solid black' }}>
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: '200',
                              color: 'black',
                              padding: '1px 4px',
                            }}>
                            Average US stocks and bonds performance 12 months
                            following the first interest rate hike
                          </p>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'column',
                              },
                              credits: {
                                enabled: false,
                              },
                              tooltip: {
                                enabled: false,
                              },
                              exporting: {
                                enabled: false,
                              },
                              legend: false,
                              title: {
                                text: '',
                              },
                              yAxis: {
                                min: 0,
                                tickInterval: 1,

                                labels: {
                                  formatter: function () {
                                    return (
                                      this.axis.defaultLabelFormatter.call(
                                        this,
                                      ) + ' %'
                                    );
                                  },
                                  style: {
                                    color: 'black',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                },
                              },
                              xAxis: {
                                categories: ['US Stocks', 'US Bonds'],
                                labels: {
                                  style: {
                                    color: 'black',
                                  },
                                },
                                title: {
                                  text: '',
                                },
                              },

                              series: [
                                {
                                  data: [
                                    {
                                      name: 'US Stocks',
                                      y: 7.3,
                                      color: '#0069aa',
                                    },
                                    {
                                      name: 'US Bonds',
                                      y: 3.2,
                                      color: '#f89b22',
                                    },
                                  ],
                                },

                                // {
                                //   name: 'US Stocks',
                                //   color: '#0069aa',
                                //   data: [7.3, null],
                                // },
                                // {
                                //   name: 'US Bonds',
                                //   color: '#f89b22',
                                //   data: [null, 3.2],
                                // },
                              ],
                              plotOptions: {
                                column: {
                                  lineWidth: 2,
                                  states: {
                                    hover: {
                                      lineWidth: 2,
                                    },
                                  },
                                  dataLabels: {
                                    enabled: true,
                                    format: '{y} %',
                                  },
                                  marker: {
                                    enabled: false,
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                        <i>Source: Morningstar as of 12/31/21</i>
                      </Grid>
                      <Grid item sm={7} md={7}>
                        <div className={classes.feTexttable}>
                          <table>
                            <thead>
                              <tr>
                                <th>1st Interest Rate Hike </th>
                                <th colspan="2">US Stocks </th>
                                <th colspan="2">US Stocks </th>
                              </tr>
                              <tr>
                                <th>Period</th>
                                <th className={classes.bdr0}>Next 6 months </th>
                                <th className={classes.bdl0}>
                                  Next 12 months{' '}
                                </th>
                                <th className={classes.bdr0}>Next 6 months </th>
                                <th className={classes.bdl0}>
                                  Next 12 months{' '}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>2/4/94 </td>
                                <td>-2.6% </td>
                                <td>-2.5% </td>
                                <td>-2.7% </td>
                                <td>-1.2%</td>
                              </tr>
                              <tr>
                                <td>6/30/99 </td>
                                <td>9.0% </td>
                                <td>8.0% </td>
                                <td>1.3% </td>
                                <td>5.1%</td>
                              </tr>
                              <tr>
                                <td>6/30/04</td>
                                <td>7.8% </td>
                                <td>7.5% </td>
                                <td>4.2% </td>
                                <td>7.1%</td>
                              </tr>
                              <tr>
                                <td>12/17/15 </td>
                                <td>1.4% </td>
                                <td>11.3% </td>
                                <td>4.9% </td>
                                <td>1.7%</td>
                              </tr>
                              <tr>
                                <td>Average </td>
                                <td>3.9% </td>
                                <td>7.3% </td>
                                <td>7.3% </td>
                                <td>3.2%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Grid>
                    </Grid>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          On average, US stocks and bonds perform positively
                          after the first interest rate hike. Let&apos;s widen
                          the lens and see how the{' '}
                          <Link
                            to="/finance-explained/sandp-500"
                            target="_blank">
                            S&P 500
                          </Link>{' '}
                          (benchmark for large US stocks) performs on a longer
                          timeline.
                        </p>
                        <p>
                          <h3 className={classes.paraTitle}>
                            Interest rates and stock market performance since
                            1980
                          </h3>
                          <p>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/big-graph-line.png"
                              alt=""
                              className={classes.imgFull}
                            />
                            <br />
                            <i>
                              Source: Federal Reserve Bank of St. Louis, Robert
                              Shiller online data
                            </i>
                          </p>
                        </p>
                        <p>
                          <h3 className={classes.paraTitle}>
                            A) January 1999 to December 2000 rate hikes
                          </h3>
                          Interest rates (Fed Funds Rate) climbed from 4.5% in
                          January 1999 to a peak of 6.5% in December 2000.
                          During that same period, S&P 500 climbed from $1,250
                          to almost $1,500 (20% gain in August 2000) and closed
                          at $1,330 in December 2000 for a gain of 6.4% (not
                          including{' '}
                          <Link
                            to="/finance-explained/dividend"
                            target="_blank">
                            dividends
                          </Link>
                          )
                        </p>
                        <p>
                          What happens after? The S&P 500 closes February 2003
                          at $837 for a loss of 33.0% from the $1,250 starting
                          point in January 1999. The Fed is forced to start
                          cutting rates to help the economy recover and grow.
                        </p>
                        <p>
                          <h3 className={classes.paraTitle}>
                            B) July 2004 to July 2007 rate hikes
                          </h3>
                          Interest rates rose rapidly from 1.0% in July 2004 to
                          a peak of 5.3% in July 2007. During that same period,
                          S&P 500 climbed from $1,100 to close at $1,520 in July
                          2007 for a gain of 38.2% (not including dividends).
                        </p>
                        <p>
                          What happens after? The S&P 500 closes March 2009 at
                          $757 for a loss of 31.2% from the $1,100 starting
                          point in July 2004. The Fed is forced to start cutting
                          rates aggressively to help the economy recover and
                          grow.
                        </p>
                        <p>
                          Do you see the cycle? What will happen in section C
                          above?
                        </p>
                        <p>
                          It&apos;s important to note that history does not
                          perfectly predict the future. If history was a
                          reliable guide, the stock market would be very
                          predictable, and we would all be legendary investors.
                          In reality, nothing happens the same way twice. We
                          learn from history and apply what we learned to
                          minimize risk and maximize reward.
                        </p>
                      </div>
                    </div>

                    <div className={classes.hitTapRow}>
                      <h3>Just hit the tape</h3>

                      <ul>
                        <li>
                          {' '}
                          <a
                            href="https://www.visualcapitalist.com/visualizing-the-global-semiconductor-supply-chain/"
                            target="_blank"
                            rel="noopener noreferrer">
                            The world is short on semiconductors - let&apos;s
                            take a look at the chip supply chain
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://www.npr.org/2022/01/13/1072529477/more-than-1-million-fewer-students-are-in-college-the-lowest-enrollment-numbers-#:~:text=More%20than%201%20million%20fewer%20students%20are%20enrolled%20in%20college,that%20began%20the%20previous%20fall."
                            target="_blank"
                            rel="noopener noreferrer">
                            More than 1 million fewer students are enrolled in
                            college now than before the pandemic began - why?
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://wallethub.com/edu/best-worst-cities-to-start-a-career/3626"
                            target="_blank"
                            rel="noopener noreferrer">
                            Best cities to launch your career ranked
                          </a>{' '}
                        </li>
                      </ul>

                      <p>
                        Get smarter about your money. Explore more financial
                        terms on our website in{' '}
                        <Link to="/finance-explained" rel="noopener noreferrer">
                          Finance Explained.
                        </Link>{' '}
                      </p>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
WillHigherInterestRatesCooltheStockMarket.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WillHigherInterestRatesCooltheStockMarket);
