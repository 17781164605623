import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import * as commonFunctions from '../../utilities/commonFunctions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import styles from './styles';
import Logo from '../../assets/img/logo.png';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Pageloader from '../../components/ui/pageloader';
// import { DirectiveLocation } from 'graphql';

const SENT_INVITATION = loader('../../graphql/schema/share-earn/sentInvitation.graphql');
const INVITATION_LINK = loader('../../graphql/schema/share-earn/Invitation-link.graphql');

const ShareEarn = (props) => {
    const { classes, history } = props;
    const [loading, setLoading] = React.useState(true);
    const [Email, setEmail] = useState('');
    const [open, setOpen] = useState(false);
    const [copylink, setCopyLink] = useState(false);
    const [referal, setReferal] = useState(false);
    const [url, setUrl] = useState('');
    const [id, setId] = useState('');
    const [invitationdata, setInvitationData] = useState('');

    const [sentInvitaion] = useMutation(SENT_INVITATION, {
        onCompleted() {
            setReferal(true);
            setEmail('');
        },
        onError() {
            setLoading(false);
        }
    });

    const [InvitationLink] = useLazyQuery(INVITATION_LINK, {
        fetchPolicy: 'no-cache',
        onCompleted(response) {
            setId(response.getInvitationLink.code);
            setUrl(response.getInvitationLink.link);
            setInvitationData(response.getInvitationLink);
            setLoading(false);
        },
        onError() {
            setLoading(false);
        }
    });

    useEffect(() => {
        InvitationLink();
    }, [])

    const validate = (values) => {
        const errors = {};
        if (values.email) {
            if (!commonFunctions.validateEmail(values.email)) {
                errors.email = 'Enter valid email.';
            }
            setEmail(values.email);
        }
        if (!values.email) {
            errors.email = 'email is required.';
            setEmail('');
        }
        return errors;
    };

    const onSubmit = (values) => {
        sentInvitaion({
            variables: {
                email: Email
            }
        })
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(url);
        setCopyLink(true)
    }

    return (
        <div>
            {loading && <Pageloader loading={loading} />}
            <div className={classes.senseInfoHeader}>
                <div className={classes.container}>
                    <div className={classes.shareInvite}>
                        <div className={classes.joinInkwiry}>
                            <Typography variant="h1" component="h1">Invite your friends to join Inkwiry.</Typography>
                            <p>
                                <span>We'll waive your membership fee for 30 days for each friend who joins inkwiry.</span>
                                <span>And we'll waive your friend's membership fee for 30 days when they enter your unique code.</span>
                            </p>
                        </div>
                    </div>
                    <div className={classes.bannerText}>
                        {copylink === true ?
                            <div className={classes.bannerTextSuccess}>
                                <p>Link Copy Successfully</p>
                                <div className={classes.linkClose}>
                                    <IconButton onClick={() => { setCopyLink(false) }}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </div>
                            :
                            ''
                        }
                        {referal === true ?
                            <div className={classes.bannerTextSuccess}>
                                <p>Referal notification sent successfully!</p>
                                <span className={classes.linkClose}>
                                    <IconButton onClick={() => { setReferal(false) }}>
                                        <CloseIcon />
                                    </IconButton>
                                </span>
                            </div>
                            :
                            ''
                        }
                        <div className={classes.bannerTextOne}>
                            <p>Invite via email</p>
                            <span className={classes.spanRight}>
                                <Button onClick={() => { setOpen(true) }}>Preview your invitation email</Button>
                            </span>
                        </div>
                        <div className={classes.bannerTextTwo}>
                            <Form
                                validate={validate}
                                onSubmit={onSubmit}
                                render={({ handleSubmit }) => (
                                    <form
                                        onSubmit={handleSubmit}
                                        noValidate
                                    >
                                        <div className={classes.fieldLeft}>
                                            <Field
                                                id="filled-password-input"
                                                placeholder="ex.mike@inkwiry.com"
                                                type="text"
                                                autoComplete="current-password"
                                                variant="filled"
                                                name="email"
                                                fullWidth
                                                required
                                                component={TextField}
                                            />
                                        </div>
                                        <div className={classes.fieldRight}>
                                            <Button
                                                type="submit"
                                            >
                                                Send Invitation
                                            </Button>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                        <div className={classes.copyReferalLink}>
                            <b>Or share your unique personal code</b>
                            <span>{id ? id : 'N/A'}</span>
                            <Button onClick={handleCopyLink}>Copy Link</Button>
                        </div>
                    </div>

                </div>
            </div>
            <div className={classes.container}>
                <div className={classes.membershipShareFees}>
                    <p>Invite your friends to Inkwiry and earn more 30-days fee waivers</p>
                    <div className={classes.invitesList}>
                        <div className={classes.invitesListLeft}>
                            <span>{invitationdata ? invitationdata.invites_accepted : 0}</span>
                            <p>Shares accepted</p>
                        </div>
                        <div className={classes.invitesListRight}>
                            <span>{invitationdata ? invitationdata.days : 0}</span>
                            <p>Days earned</p>
                        </div>
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={() => { setOpen(false) }}
                    className={classes.addGroup}
                >
                    <DialogContent>
                        <div className={classes.popupHeader}>
                            <span aria-hidden="true" onClick={() => { setOpen(false) }}>
                                <i className="la la-times" />
                            </span>
                            <Typography variant="h4" component="h4">{invitationdata ? invitationdata.name : 'inkwiry'} is inviting you to join Inkwiry</Typography>
                        </div>
                        <div className={classes.modelBody}>
                            <div className={classes.logoDiv}>
                                <figure>
                                    <img src={Logo} alt="" />
                                </figure>
                                <div className={classes.logoCnt}>
                                    <Typography variant="h2" component="h2">{invitationdata ? invitationdata.name : 'inkwiry'} is inviting you to join Inkwiry</Typography>
                                    <p>{invitationdata ? invitationdata.name : 'inkwiry'} would like to invite you to Inkwiry. <span>Join inkwiry via the link below.</span></p>
                                    <span className={classes.logoAnch}><Link href={url}>{url}</Link></span>
                                </div>
                            </div>
                            <div className={classes.footerCnt}>
                                <Typography variant="h3" component="h3">Need help?</Typography>
                                <p>Get in touch by emailing us at</p>
                                <a href='mailto:support@inkwiry.com' className={classes.footerAnch}>support@inkwiry.com.</a>
                                <span className={classes.footerPara}>
                                    <span onClick={() => { history.push("/termsofservice") }} className={classes.footerAnchNew}>Terms of Service</span> |
                                    <span onClick={() => { history.push("/privacy") }} className={classes.footerAnchNew}>Privacy Policy</span>
                                    |  20 Terry Drive, PO Box 1426, Newtown, Pennsylvania 18940
                                </span>
                            </div>
                        </div>
                        <div className={classes.popupFooter}>
                            <Button onClick={() => { setOpen(false) }}>Close</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}

ShareEarn.propTypes = {
    classes: PropTypes.object.isRequired,
};

const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(ShareEarn);
