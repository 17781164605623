import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import styles from './styles';
import mcsTopImage from '../../../../assets/img/mcs/level1_image.png';
import logoImage from '../../../../assets/img/mcs/my-career-sketch.png';
// import logoInkwiry from '../../../../assets/img/mcs/logo.png';
import Pageloader from '../../../../components/ui/pageloader';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import $ from 'jquery';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import scrollToComponent from 'react-scroll-to-component';
import confirmStyles from '../../../../common-styles/confirm-styles';
import combineStyles from '../../../../utilities/combineStyles';
import TimeLineList from './time-line-list';
import arrow from '../../../../assets/img/build-career-sketch/timeline_arrow.png';
import WalkThruDotsAll from '../../../pwi/wallk-thru/walkThruDotsAll';
import { initializeExcelSheetsData, workSheetCalc } from '../../../../calculations';
import * as timeLineActions from '../../../../calculations/time-line';
import * as modulesServices from '../../../../calculations/modules-services';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/draggable';

const MCS_MODULE_DATA = loader('../../../../graphql/schema/career-sketch/MCS-Level3-Data.graphql');

const SAVE_MCS_TIMELINE = loader('../../../../graphql/schema/career-sketch/save-mcs-timeline.graphql');
// const SAVEMYCAREERSKETCH = loader('../../../../graphql/schema/career-sketch/save-careersketch.graphql');

const totalWalkthru = [];
for (let i = 0; i <= 11; i += 1) {
    totalWalkthru.push(i);
}

const McsLevelThreeActivityTwoTimline = (props) => {
    const { classes } = props;
    const [activeWalkThru, setActiveWalkThru] = React.useState('');
    const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [moduleTimeLine, setModuleTimeLine] = React.useState([]);
    const [dragDisable, setDragDisable] = React.useState({});
    const [showStartYearInfo, setShowStartYearInfo] = React.useState(false);

    const [saveTimeLine] = useMutation(SAVE_MCS_TIMELINE, {
        onCompleted(response) {
            getModuleData();
        },
        onError(errors) {
            getModuleData();
        },
    });
    // const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
    //     onCompleted({
    //         saveMyCareerSketch: {
    //             status,
    //         },
    //     }) {
    //         if (status) {
    //             setLoading(false);
    //         }
    //     },
    //     onError(errors) {
    //         setLoading(false);
    //     },
    // });
    useEffect(() => {
        if (Object.keys(dragDisable).length > 0) {
            for (let i = 1; i <= 18; i += 1) {
                if (i !== 3 && i !== 7 && i !== 16) {
                    if (dragDisable[`module${i}`] !== undefined && dragDisable[`module${i}`] === true) {
                        $(`.life_events_block li[data-module="module${i}"]`).draggable({
                            disabled: true,
                        });
                    } else {
                        $(`.life_events_block li[data-module="module${i}"]`).draggable({
                            disabled: false,
                        });
                    }
                }
            }
            const mainWidth = ($('#drop-area li').width()) * ($('#drop-area li').length) - (($('#drop-area li').length - 1) * 42) + 50;
            $('#drop-area').css('width', mainWidth);
        }
        // eslint-disable-next-line
    }, [dragDisable])

    const placeTimeLine = (type, status = '') => {
        if (type === 'default') {
            const displayTimeLine = [];
            const dragDisableData = {};
            if (status === 'not-empty') {
                const order = timeLineActions.moduleTimelineData;
                order.forEach((value) => {
                    if (parseInt(value.scenario_id, 10) > 0 || value.action === 'CS') {
                        displayTimeLine.push(value);
                        dragDisableData[value.selected_module] = true;
                    }
                });
            } else {
                dragDisableData.module1 = true;
                displayTimeLine.push({
                    action: 'CS',
                    module_time_line_order: 1,
                    scenario_id: 0,
                    selected_module: 'module1',
                    time_line_order: 0,
                }, {
                    action: 'CS',
                    module_time_line_order: 1,
                    scenario_id: 0,
                    selected_module: 'module18',
                    time_line_order: 0,
                });
            }

            setDragDisable(dragDisableData);

            setModuleTimeLine(displayTimeLine);
        }
    };

    const [getModuleData] = useLazyQuery(MCS_MODULE_DATA, {
        fetchPolicy: 'no-cache',
        variables: {
            data: {
                level: "3",
                option_id: "2"
            }
        },
        onCompleted(response) {
            if (response.getMCSLevel3Data.timeLine) {
                timeLineActions.assignModulesData(response.getMCSLevel3Data, (data) => {
                    placeTimeLine('default', 'not-empty');
                    setLoading(false);
                    if (!(modulesServices.module1Data.start_year !== undefined && parseInt(modulesServices.module1Data.start_year)) > 0) {
                        setShowStartYearInfo(true);
                    }
                });
            } else {
                timeLineActions.assignModulesData('', (data) => {
                    placeTimeLine('default', 'empty');
                    setLoading(false);
                    setShowStartYearInfo(true);
                });
            }
        },
        onError() {
            timeLineActions.assignModulesData('', (data) => {
                placeTimeLine('default', 'empty');
                setLoading(false);
                setShowStartYearInfo(true);
            });
        },
    });
    const saveTimeLineData = (data) => {
        const timeLineVal = data.map((module) => _.pick(module, ['action', 'module_time_line_order', 'selected_module', 'time_line_order', 'scenario_id']));
        setLoading(true);
        saveTimeLine({
            variables: {
                data: {
                    name: 'Active Selection',
                    order: timeLineVal,
                    level: "3",
                    option_id: "2",
                    status: data.length >= 5 ? "1" : "0",
                },
            },
        });
        // if (data.length >= 4) {
        //     submitFirstActivity({
        //         variables: {
        //             data: {
        //                 level: "3",
        //                 option_id: "2",
        //             },
        //         },
        //     });
        // }
    };

    useEffect(() => {
        document.body.classList.remove('pwiAnimation');
        document.body.classList.remove('pwiAnimationActive');
        getModuleData();

        $('.life_events_block li[data-module]').draggable({
            connectToSortable: '#drop-area',
            helper: 'clone',
            revert: 'invalid',
            containment: 'window',
            scroll: true,
            scrollSensitivity: 80,
            scrollSpeed: 150,
            stop(event, ui) {
                const mainWidth = ($('#drop-area li').width()) * ($('#drop-area li').length) - (($('#drop-area li').length - 1) * 42) + 50;
                $('#drop-area').css('width', mainWidth);
            },
        });

        $('#drop-area').sortable({
            scroll: true,
            refreshPositions: true,
            revert: true,
            hoverClass: 'ui-state-active',
            cursor: 'move',
            tolerance: 'intersect',
            items: 'li:not(.fixed)',
            scrollSpeed: 100,
            scrollSensitivity: 80,
            over(event, ui) {
                // Drag it here image placeholder
                $('.ui-sortable-placeholder').html('<span class="drop_here">Drop Here</span>').css('visibility', 'visible').css('z-index', '2');

                const mainWidth = ($('#drop-area li').width()) * ($('#drop-area li').length) - (($('#drop-area li').length - 1) * 42) + 50;

                $('#drop-area').css('width', mainWidth);
                // Hide dragging component
                $(ui.helper).css('display', 'none').removeClass('active').removeClass('component')
                    .addClass('component-sorting');
            },
            stop(event, ui) {
                // Hide Drag Item
                ui.item.hide();
                const selectedModule = ui.item.attr('data-module');
                const dropIndex = ui.item.index();
                const dropList = [];
                $('#drop-area').find('li').each((n, module) => {
                    const moduleInformation = {
                        action: $(module).data('actionfrom'),
                        module_time_line_order: n,
                        time_line_order: n,
                        selected_module: $(module).data('module'),
                        scenario_id: $(module).data('scenarioid'),
                        startYear: $(module).data('startYear'),
                        endYear: $(module).data('endYear'),
                    };
                    if ($(module).hasClass('component-sorting')) {
                        moduleInformation.action = 'CS';
                        moduleInformation.scenario_id = 0;
                        moduleInformation.selected_module = selectedModule;
                        moduleInformation.module_time_line_order = dropIndex;
                        moduleInformation.time_line_order = dropIndex;
                        moduleInformation.startYear = '';
                        moduleInformation.endYear = '';
                    }
                    dropList.push(moduleInformation);
                });
                ui.item.remove();
                saveTimeLineData(dropList);
            },
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        initializeExcelSheetsData();
        workSheetCalc();
        // eslint-disable-next-line
    });

    useEffect(() => {
        if (activeWalkThru !== '') {
            const walkthrus = { ...completedWalkThruSlides };
            walkthrus[activeWalkThru] = true;
            setCompletedWalkThruSlides(walkthrus);
            document.body.classList.add('pwiAnimation');
        } else {
            document.body.classList.remove('pwiAnimation');
        }
        if (activeWalkThru === 0 || activeWalkThru === 1) {
            document.body.classList.add('pwiAnimationActive');
        } else {
            document.body.classList.remove('pwiAnimationActive');
        }

        switch (activeWalkThru) {
            case 0:
            case 3:
            case 4:
            case 5:
            case 11:
                scrollToComponent(document.getElementById('time_line_life_events'), { offset: -56, align: 'top', duration: 500 });
                break;
            case 1:
            case 7:
            case 8:
            case 9:
            case 10:
                scrollToComponent(document.getElementById('time_line_drag_events'), { offset: -100, align: 'top', duration: 500 });
                break;
            case 2:
            case 6:
                scrollToComponent(document.getElementById('cs_time_line'), { offset: -100, align: 'top', duration: 500 });
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [activeWalkThru]);



    return (
        <Typography variant="body1" component="div">
            {loading && <Pageloader loading={loading} />}
            <div className={classes.mcsLevelOne}>
                <div className={classes.topImage}>
                    <img src={mcsTopImage} alt="mcs" />
                </div>
                <div className={classes.container}>
                    <div className={classes.mcsRow}>
                        <div className={classes.mcsLogoImage}>
                            <Link to="/my-career-sketch/dashboard">
                                <img src={logoImage} alt="logo" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={classes.timelineSketcher}>
                    <div className={classes.timelineBanner}>
                        <div className={classes.container}>
                            <p>Timeline Sketcher</p>
                        </div>
                    </div>
                    <div className={classes.lifeEventsBlock} id="time_line_life_events">
                        <ReactTooltip id="modulesTooltip" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfo, classes.timelineInfo)} />
                        <div className={classes.container}>
                            <div className={classes.lifeEventsContent}>
                                <div className={classes.lifeEveentScroll}>
                                    <ul className={classNames(classes.lifeEventsImages, 'life_events_block')}>

                                        <li
                                            data-for="modulesTooltip"
                                            data-tip="Higher Education"
                                            data-offset="{'bottom': -2}"
                                            data-module="module2"
                                        >
                                            <font className={(dragDisable.module2 !== undefined && dragDisable.module2 === true) ? classes.noDropHe : classes.higherEducation}><span /></font>
                                        </li>
                                        <li
                                            data-for="modulesTooltip"
                                            data-tip="More Higher Education"
                                            data-offset="{'bottom': -2}"
                                            data-module="module6"
                                        >
                                            <font className={(dragDisable.module6 !== undefined && dragDisable.module6 === true) ? classes.noDropMhe : classes.moreHigherEducation}><span /></font>
                                        </li>
                                        <li
                                            data-for="modulesTooltip"
                                            data-tip="Further Higher Education"
                                            data-offset="{'bottom': -2}"
                                            data-module="module15"
                                        >
                                            <font className={(dragDisable.module15 !== undefined && dragDisable.module15 === true) ? classes.noDropFhe : classes.noDropFhe}><span /></font>
                                        </li>
                                        <li
                                            data-for="modulesTooltip"
                                            data-tip="Career Path"
                                            data-offset="{'bottom': -2}"
                                            data-module="module4"
                                        >
                                            <font className={(dragDisable.module4 !== undefined && dragDisable.module4 === true) ? classes.noDropCp : classes.careerPath}><span /></font>
                                        </li>
                                        <li
                                            data-for="modulesTooltip"
                                            data-tip="Career Advancement"
                                            data-offset="{'bottom': -2}"
                                            data-module="module5"
                                        >
                                            <font className={(dragDisable.module5 !== undefined && dragDisable.module5 === true) ? classes.noDropCa : classes.careerAdvancement}><span /></font>
                                        </li>
                                        <li
                                            data-for="modulesTooltip"
                                            data-tip="Additional Career Advancement"
                                            data-offset="{'bottom': -2}"
                                            data-module="module8"
                                        >
                                            <font className={(dragDisable.module8 !== undefined && dragDisable.module8 === true) ? classes.noDropAca : classes.addAdvancement}><span /></font>
                                        </li>
                                        <li
                                            data-for="modulesTooltip"
                                            data-tip="Further Career Advancement"
                                            data-offset="{'bottom': -2}"
                                            data-module="module17"
                                        >
                                            <font className={(dragDisable.module17 !== undefined && dragDisable.module17 === true) ? classes.noDropFca : classes.furtherAdvancement}><span /></font>
                                        </li>
                                    </ul>
                                </div>
                                {activeWalkThru === 3 && (
                                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruFour)}>
                                        <h4>Step 1: Drag and drop life events to the timeline</h4>
                                        <p>Step 1: Think career first. What education will best prepare you for your career? How many education moves are you planning to make? For example, if you plan to go to college or career school, that would be one higher education module. Graduate school? Drag another higher education module to the timeline.</p>
                                        <div className={classes.walkthruBottom}>
                                            <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                            <div className={classes.walkThruBtns}>
                                                <Button onClick={() => { setActiveWalkThru(4); }} className={classes.startWalkThru}>Next</Button>
                                                <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeWalkThru === 4 && (
                                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruFive)}>
                                        <h4>Step 1: Drag and drop life events to the timeline</h4>
                                        <p>Step 1: How many career moves will you make in the next 5, 10, 15 years? You don’t plan to stay in the same job for 10 years, do you? Create a map to your future professional self. Inkwiry calls this building your Stepping Stones. Drag and drop as many career modules as needed for your 15 year professional self and Inkwiry will help you find your Stepping Stones.</p>
                                        <div className={classes.walkthruBottom}>
                                            <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                            <div className={classes.walkThruBtns}>
                                                <Button onClick={() => { setActiveWalkThru(5); }} className={classes.startWalkThru}>Next</Button>
                                                <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeWalkThru === 5 && (
                                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruSix)}>
                                        <h4>Step 1: Drag and drop life events to the timeline</h4>
                                        <p>Step 1: Now that you have your education and career moves on the timeline, fill in the pieces. Drag and drop the other life events into place and build your future. Ask yourself, “When do I want to own a home, buy a car, start investing, and more?” Build your timeline chronologically as best as you can, and we’ll handle the details in Step 2.</p>
                                        <div className={classes.walkthruBottom}>
                                            <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                            <div className={classes.walkThruBtns}>
                                                <Button onClick={() => { setActiveWalkThru(6); }} className={classes.startWalkThru}>Next</Button>
                                                <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={classes.dragBlock} id="time_line_drag_events">
                        <div className={classes.container}>
                            <div className={classes.timelineArrow}>
                                <img src={arrow} alt="" />
                            </div>
                            <Grid container className={classes.relativeBlock}>
                                <ReactTooltip id="walkThruToolTip" place="top" type="info" effect="solid" className={classes.walkThruIcon} />

                                {activeWalkThru === 0 && (
                                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruOne)}>
                                        <h4>Get Started in the Timeline Sketcher with an Inkwiry Walk-Thru</h4>
                                        <p>Welcome to the Timeline Sketcher, a complete picture of your career sketch. This Walk-Thru will detail the tools available to you as you build your best future. To get started, click through these quick tips to help you navigate the sketcher with confidence!</p>
                                        <div className={classes.walkthruBottom}>
                                            <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                            <div className={classes.walkThruBtns}>
                                                <Button onClick={() => { setActiveWalkThru(1); }} className={classes.startWalkThru}>Start</Button>
                                                <Button onClick={() => { setActiveWalkThru(''); }} className={classes.skipWalkthru}>Skip walk-thru</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeWalkThru === 11 && (
                                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruTwelve)}>
                                        <h4>Timeline Sketcher Walk-Thru Complete!</h4>
                                        <p>Now you know the tools, time to get to planning! If you want to access this Walk-Thru again, just click on this module icon. Now, get started, drag and drop to build your timeline, double click on life events to capture your details, set goals in Plan With Inkwiry, and illuminate your best path into the future.</p>
                                        <div className={classes.walkthruBottom}>
                                            <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                            <div className={classes.walkThruBtns}>
                                                <Button onClick={() => { setActiveWalkThru(''); }} className={classes.skipWalkthru}>Close</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <Grid item sm={10} xs={12} className={classes.timelineSpacing}>
                                    <div className={classNames(classes.timelineEvents, activeWalkThru === 1 && classes.pwiActiveModule)}>
                                        <h3>Drag and drop life events to the timeline.</h3>
                                        <p>
                                            Then, double-click on the life event and enter details to create
                                            <span>your roadmap to the future.</span>
                                        </p>
                                        {activeWalkThru === 1 && (
                                            <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruTwo)}>
                                                <h4>Building your Career Sketch is as easy as 1, 2, 3</h4>
                                                <p>Step 1: Drag and drop life events to your timeline. Step 2: Double click on modules and capture your details. Step 3: Inkwiry’s Simulator Engine builds your career sketch</p>
                                                <div className={classes.walkthruBottom}>
                                                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                                    <div className={classes.walkThruBtns}>
                                                        <Button onClick={() => { setActiveWalkThru(2); }} className={classes.startWalkThru}>Next</Button>
                                                        <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </Grid>
                                {activeWalkThru === 9 && (
                                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruTen)}>
                                        <h4>Menu options</h4>
                                        <p>Click on the menu to access your Simulator Panel, Summary Documents, and Dashboard. The Simulator Panel allows you to collaborate with family, friends, or trusted colleagues. The Summary Documents includes your Income Statement, Balance Sheet, and more. The Dashboard transforms your data into intelligent visualizations so you can understand your data at a deeper level.</p>
                                        <div className={classes.walkthruBottom}>
                                            <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                            <div className={classes.walkThruBtns}>
                                                <Button onClick={() => { setActiveWalkThru(10); }} className={classes.startWalkThru}>Next</Button>
                                                <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activeWalkThru === 10 && (
                                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruEleven)}>
                                        <h4>Menu options</h4>
                                        <p>A Career Sketch is a complete 5, 10, 15 year financial life plan. Once you have multiple scenarios built, save them as a career sketch so that you can reload those scenarios together. Want to start fresh? Use the Clear Timeline and build a new career sketch. That&apos;s the beauty of planning at the speed of thought.</p>
                                        <div className={classes.walkthruBottom}>
                                            <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                            <div className={classes.walkThruBtns}>
                                                <Button onClick={() => { setActiveWalkThru(11); }} className={classes.startWalkThru}>Next</Button>
                                                <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                        </div>
                    </div>

                    <div className={classes.csTimeline} id="cs_time_line">
                        <div className={classNames(classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock)}>

                            <TimeLineList timeLineData={moduleTimeLine} page="time-line" showStartYearInfo={showStartYearInfo} />

                            {activeWalkThru === 8 && (<div className={classNames(classes.timelineDesc, classes.timelineDescActive)} />)}

                            {activeWalkThru === 2 && (
                                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruThree)}>
                                    <h4>Set your plan in motion with a start year</h4>
                                    <p>The Financial Independence module will always be on the timeline because that module sets your plan in motion with an essential detail – the start year of your career sketch. If you have an existing asset such as a car or home, or existing debt such as student loans or car loans, drag the Existing Assets + Liabilities module to the timeline, too! These two modules handle all your current data.</p>
                                    <div className={classes.walkthruBottom}>
                                        <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                        <div className={classes.walkThruBtns}>
                                            <Button onClick={() => { setActiveWalkThru(3); }} className={classes.startWalkThru}>Next</Button>
                                            <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeWalkThru === 6 && (
                                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruSeven)}>
                                    <h4>Step 2: Double-click on life events and capture your details</h4>
                                    <p>Step 2: With your timeline fully sketched out, you can move to Step 2. Before you start on future modules, capture your current details in the Financial Independence and Existing Assets + Liabilities modules. Then, come back to your timeline and plan your next move.</p>
                                    <div className={classes.walkthruBottom}>
                                        <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                        <div className={classes.walkThruBtns}>
                                            <Button onClick={() => { setActiveWalkThru(7); }} className={classes.startWalkThru}>Next</Button>
                                            <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeWalkThru === 7 && (
                                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruEight)}>
                                    <h4>Step 2: Double-click on life events and capture your details</h4>
                                    <p>Once you enter your start year in the FI module and capture your existing assets and liabilities, start moving through your timeline module by module and build a roadmap to your future. As you build scenarios, the timeline will always rearrange itself in chronological order.</p>
                                    <div className={classes.walkthruBottom}>
                                        <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                        <div className={classes.walkThruBtns}>
                                            <Button onClick={() => { setActiveWalkThru(8); }} className={classes.startWalkThru}>Next</Button>
                                            <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {activeWalkThru === 8 && (
                                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruNine)}>
                                    <h4>Step 2: Double-click on life events and capture your details</h4>
                                    <p>After you build a scenario, hover on that module to see the key details. In the bottom right corner of the box, you’ll see two icons. Click to load a different scenario or to clear the current scenario and remove the module from your timeline.</p>
                                    <div className={classes.walkthruBottom}>
                                        <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                                        <div className={classes.walkThruBtns}>
                                            <Button onClick={() => { setActiveWalkThru(9); }} className={classes.startWalkThru}>Next</Button>
                                            <Button onClick={() => { setActiveWalkThru(''); }} className={classes.closeWalkThru}>Close</Button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <div className={classes.startButton}>
                            <Link to="/my-career-sketch/level3">
                                <Button >Go To Level 03</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </Typography >
    );
};

McsLevelThreeActivityTwoTimline.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(combineStyles(styles, confirmStyles)),
    withRouter,
);

export default enhance(McsLevelThreeActivityTwoTimline);