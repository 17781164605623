import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const RetirementAccounts = ( props ) => {
  const { classes, active, handleAssestObject, handleUpdatedObject} = props;
  const [inputFields] = React.useState({
      "Traditional IRA": "existing_traditional_ra",
      "401(k) / 403b": "existing_401k_403b",
      "Roth IRA": "existing_roth_ira",
      "Roth 401(k) / 403b": "existing_roth_401k_403b",
      "SEP IRA": "existing_sep_ira",
      "Pension":  "existing_pension",
    },
  )

  // const [plans, setPlans] = React.useState({})

  const handleUpdate = (e, name) => {
    const inputValues = {...handleAssestObject};
    inputValues[name] = e.floatValue !== undefined ? e.floatValue : 0;
    handleUpdatedObject(inputValues)
  }
  return (
    <div className={ classes.contentBlock }>
      <p>Enter the value for each of your accounts below.</p>
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Retirement Accounts</th>
          </thead>
          <tbody>
            {
              active.map((account, index) =>(
                <tr>
                  <td>{account}</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <span>$</span>
                      <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject[inputFields[account]] } name={`${inputFields[account]}`} onValueChange={(e)=> handleUpdate(e, inputFields[account])} onFocus={ ( e ) => e.target.select() } />
                    </div>
                  </td>
                </tr>
              ))
            }
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Retirement Savings</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    {modulesServices.module18Data.existingTotalRetirementAccounts !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingTotalRetirementAccounts } prefix={ modulesServices.module18Data.existingTotalRetirementAccounts >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingTotalRetirementAccounts < 0 && ')' } />
                    ) : ( '$0' )}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

RetirementAccounts.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( RetirementAccounts );
