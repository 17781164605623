import selectDrop from '../../../assets/img/build-career-sketch/select_drop.png';

const styles = ( theme ) => ( {
  modalHeader: {
    background: '#0f6aac',
    padding: '19px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '& h4': {
      color: '#fff',
      fontSize: '35px',
      marginRight: '45px',
      margin: '0',
      lineHeight: '1.42857143',
      fontWeight: '500',
      width: '50%',
      textAlign: 'right',
      fontFamily: '"MuseoSans-500"',
    },
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  modalBody: {
    padding: '0 15px',
  },
  simulatorPopup: {
    '& > div > div': {
      maxWidth: '88%',
      width: '88%',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: 'calc(100% - 30px)',
      transform: 'translate(0,0)',
      margin: '0 auto',
      boxSizing: 'border-box',
      overflowY: 'hidden',
      borderRadius: '0',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  cancelButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc !important',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '17px',
    fontWeight: '400',
    lineHeight: '15px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '8px 14px',
    textTransform: 'capitalize',
    '&:last-child': {
      marginLeft: '5px',
    },
  },
  modalFooter: {
    borderTop: '1px solid #e5e5e5',
    position: 'relative',
    padding: '10px 15px',
    textAlign: 'right',
    boxSizing: 'border-box',
    width: '100%',
    '& h3': {
      color: '#0069aa',
      fontSize: '14px',
    },
  },
  modalLogo: {
    width: '50%',
  },
  clearButton: {
    background: '#caa336',
    borderColor: '#caa336',
    color: '#fff',
    '&:hover': {
      background: '#caa336',
      borderColor: '#caa336',
      color: '#fff',
    },
  },
  simulatorButton: {
    float: 'left',
    maxHeight: '32px',
    background: '#AFAFAF',
    border: '0px solid #7f7f7f',
    borderRadius: '0 0 0 6px',
    margin: '0 1px',
    color: '#fff',
    fontSize: '14px',
    padding: '7px 10px',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    textTransform: 'uppercase',
    '&:hover': {
      background: '#7f7f7f',
    },
  },
  simluatorBtns: {
    width: '100%',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        float: 'right',
        padding: '0',
      },
    },
  },
  simulatorSticky: {
    position: 'relative',
    zIndex: '999',
    background: '#fff',
    width: '100%',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tbody': {
      '& tr': {
        '& td': {
          textAlign: 'center',
          textTransform: 'uppercase',
        },
      },
    },
  },
  leftSimulator: {
    width: '70%',
    padding: '15px 0px 8px',
    '& table': {
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tr': {
        '& td': {
          '&:first-child': {
            paddingLeft: '102px !important',
            width: '38%',
          },
          '&:nth-child(2)': {
            width: '11%',
          },
          '&:nth-child(3)': {
            width: '30%',
          },
        },
      },
    },
  },
  infoImage: {
    maxWidth: '20px',
    position: 'relative',
    top: '-3px',
    marginLeft: '0',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  shareImage: {
    maxWidth: '30px',
    position: 'relative',
    marginLeft: '0',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  rightSimulator: {
    width: '27%',
    padding: '15px 12px 8px',
    '& table': {
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
    },
  },
  shareBlock: {
    width: '13%',
  },
  detailsBlock: {
    width: '81%',
    paddingLeft: '0',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      verticalAlign: 'middle',
      display: 'inline-block',
      width: '25px',
      cursor: 'pointer',
    },
  },
  simulatorClose: {
    right: '12px',
    top: '9px',
    color: '#fff',
    position: 'absolute',
    marginTop: '-2px',
    textDecoration: 'none',
    cursor: 'pointer',
    opacity: '.2',
    padding: '0',
    background: '0 0',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    textTransform: 'lowercase',
    fontWeight: '700',
    fontFamily: '"MuseoSans-300"',
    minWidth: 'auto',
    '&:hover':{
      opacity: '.8',
    }
  },
  simulatorModal: {
    zIndex: '9999',
  },
  simulatorTable: {
    borderSpacing: '0px 20px',
    marginTop: '-20px',
    borderCollapse: 'separate',
    borderBottom: 'none !important',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '20px',
    '& tbody': {
      '& tr': {
        background: '#fafafa',
      },
    },
  },
  leftModule: {
    width: '73%',
    borderRight: '0',
    paddingRight: '0',
    padding: '10px 10px 0px',
    position: 'relative',
    border: '1px solid #333',
    '& figure': {
      width: '23%',
      display: 'block',
      verticalAlign: 'top',
      float: 'left',
      paddingRight: '15px',
      margin: '0',
      '& img': {
        maxWidth: '80px',
        position: 'absolute',
        width: '50%',
        top: '0',
        bottom: '0',
        left: '10px',
        margin: 'auto',
        cursor: 'pointer',
        verticalAlign: 'middle',
      },
    },
    '& table': {
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
    },
  },
  moduleHead: {
    width: '100%',
    display: 'inline-block',
    verticalAlign: 'top',
    paddingLeft: '90px',
    boxSizing: 'border-box',
  },
  moduleDiv: {
    width: '38% !important',
    paddingLeft: '0px !important',
    position: 'unset !important',
    paddingRight: '0',
  },
  simPanel: {
    width: '100%',
    '& tbody': {
      '& tr': {
        '& td': {
          paddingRight: '0',
          verticalAlign: 'top',
          border: '0',
          padding: '0 10px',
          '&:nth-child(2)': {
            width: '11% !important',
            textAlign: 'center',
          },
          '& span': {
            height: '43px',
            lineHeight: '24px',
            overflow: 'hidden',
            float: 'left',
            padding: '10px 10px',
            background: '#f0f0f0',
            display: 'inline-block',
            width: '100%',
            border: '1px solid #969696',
            boxSizing: 'border-box',
            fontSize: '15px',
          },
        },
      },
    },
  },
  smallFont: {
    width: '32% !important',
    paddingRight: '0px',
    '& small': {
      display: 'block',
      height: '43px',
      padding: '0 27px 0 15px',
      width: '100%',
      border: '1px solid #969696',
      borderRadius: '0',
      backgroundSize: '12px',
      backgroundPosition: 'right 8px center',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '99',
      lineHeight: '41px',
      fontSize: '14px',
      fontFamily: 'MuseoSans-300',
      overflow: 'hidden',
      wordBreak: 'break-all',
      backgroundImage: `url(${selectDrop})`,
      boxSizing: 'border-box',
    },
  },
  simPanelLineCount: {
    paddingLeft: '91px',
    display: 'inline-block',
    width: '100%',
    borderRight: '1px dashed rgba(0, 0, 0, 0.1)',
    float: 'left',
    margin: '1px 0 0',
    boxSizing: 'border-box',
    '& > div': {
      width: '6.666%',
      height: '50px',
      float: 'left',
      borderWidth: '0px 0px 0px 1px',
      borderColor: 'rgba(0, 0, 0, 0.1)',
      borderStyle: 'dashed',
      position: 'relative',
      padding: '16px 0px',
      fontSize: '12px',
      lineHeight: '42px',
      zIndex: '1',
      boxSizing: 'border-box',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '103%',
        height: '22px',
        top: '0',
        left: '0',
        right: '0',
        zIndex: '0',
      },
      '& i': {
        position: 'absolute',
        right: '0',
        fontSize: '10.5px',
        fontStyle: 'normal',
        width: '15px',
        zIndex: '9',
      },
    },
  },
  rightModule: {
    width: '27% !important',
    padding: '10px 10px 0px',
    border: '1px solid #333',
    borderLeft: '0',
    verticalAlign: 'top',
    position: 'relative!important',
    lineHeight: '1.42857143',
    '& table': {
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tbody': {
        '& tr': {
          '& td': {
            verticalAlign: 'top',
            border: '0',
            padding: '0 10px',
            fontSize: '14px',
            '&:nth-child(1)': {
              width: '13%',
              padding: '0',
              textAlign: 'center',
              background: '0 0',
              position: 'relative',
            },
            '&:nth-child(2)': {
              paddingRight: '0',
              width: '85%',
            },
          },
        },
      },
    },
  },
  detailsGrowth: {
    width: '100%',
    background: '#e6e6e6',
    border: '1px solid #969696',
    marginBottom: '1px',
    '& span': {
      background: 'transparent !important',
      border: '0px solid #969696',
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '100%',
      height: '40px',
      padding: '0px 10px !important',
      fontSize: '12px',
    },
  },
  detailsGrowthWrap: {
    position: 'relative',
    display: 'flex',
    '& p': {
      margin: '3px 0 0',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '12px',
      padding: '5px 0 5px 18px !important',
      width: '40% !important',
      display: 'inline-block !important',
      float: 'left',
    },
    '& span': {
      display: 'inline-block !important',
      float: 'left',
      width: '60%',
      textAlign: 'right',
      background: '#c8c8c8',
      padding: '5px 15px',
      border: '1px solid #969696',
      boxSizing: 'border-box',

    },
  },
  infoFig: {
    width: 'auto',
    padding: '0',
    position: 'absolute',
    top: '5px',
    left: '0',
    display: 'inline-block !important',
    float: 'left',
    cursor: 'pointer',
    '& img': {
      padding: '0',
      width: 'auto',
      float: 'none',
      maxWidth: '20px',
      verticalAlign: 'middle',
    },
  },
  fiColor: {
    '&::before': {
      background: '#0F6648',
    },
  },
  heColor: {
    '&::before': {
      background: '#86a940',
    },
  },
  mheColor: {
    '&::before': {
      background: '#529c6f',
    },
  },
  fheColor: {
    '&::before': {
      background: '#8fad81',
    },
  },
  cpColor: {
    '&::before': {
      background: '#046aa9',
    },
  },
  caColor: {
    '&::before': {
      background: '#3795e6',
    },
  },
  acaColor: {
    '&::before': {
      background: '#81c3dc',
    },
  },
  fcaColor: {
    '&::before': {
      background: '#8790b8',
    },
  },
  vacationColor: {
    '&::before': {
      background: '#c76341',
    },
  },
  investmentColor: {
    '&::before': {
      background: '#d3b063',
    },
  },
  familyColor: {
    '&::before': {
      background: '#25a7b6',
    },
  },
  carColor: {
    '&::before': {
      background: '#f7a24f',
    },
  },
  homeColor: {
    '&::before': {
      background: '#ef8353',
    },
  },
  reiColor: {
    '&::before': {
      background: '#004372',
    },
  },
  existingColor: {
    '&::before': {
      background: '#cab488',
    },
  },
  tooltipInfoLeft: {
    maxWidth: '340px',
    background: '#0069aa !important',
    fontSize: '16px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.42857143',
    borderRadius: '4px',
    '&::after': {
      opacity: '1 !important',
      borderLeftColor: '#0069aa !important',
      borderLeftWidth: '5px !important',
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    boxSizing: 'border-box',
    background: '#0069aa !important',
    fontSize: '14px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    zIndex: '9999',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
  },
  detailTooltip: {
    maxWidth: '300px',
    width: '300px',
    zIndex: '9999',
  },
  activeTooltip: {
    background: 'rgba(0, 105, 170, 0.9) !important',
    width: '300px',
    maxWidth: '300px',
    fontSize: '15px',
    zIndex: '9999',
  },
  shareTooltip: {
    maxWidth: '200px !important',
    width: '200px !important',
    fontSize: '16px !important',
    zIndex: '9999',
    '&::after': {
      opacity: '1 !important',
      borderRightColor: '#0069aa !important',
      borderRightWidth: '5px !important',
      borderTopColor: 'transparent !important',
    },
  },
  scenarioTooltip: {
    maxWidth: '250px',
    width: '250px',
  },
  timelineInfo: {
    background: 'transparent',
    lineHeight: '20px',
    color: '#333333',
    fontFamily: 'MuseoSans-100',
    fontWeight: 'normal',
    fontSize: '15px',
    maxWidth: '250px',
  },
  sightHeader: {
    textAlign: 'right',
    padding: '8px 15px',
    borderBottom: '1px solid #e5e5e5',
    '& button': {
      color: '#000',
      width: '28px',
      border: '1px solid',
      filter: 'alpha(opacity=20)',
      margin: '0',
      display: 'block',
      opacity: '.2',
      fontSize: '20px',
      textAlign: 'center',
      fontFamily: 'MuseoSans-100',
      fontWeight: '700',
      lineHeight: '22px',
      textShadow: '0 1px 0 #fff',
      borderRadius: '2px',
      paddingBottom: '2px',
      float: 'right',
      cursor: 'pointer',
    },
  },
  impPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '750px',
    borderRadius: '6px',
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  impDiv: {
    padding: '15px 0 5px',
    display: 'flex',
    '& p': {
      padding: '0 0 0 24px',
      marginTop: 0,
      fontSize: '16px',
    },
    '& span': {
      paddingTop: '6px',
    },
  },
  impbuttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#337ab7',
        borderColor: '#337ab7',
        color: '#fff',
        marginRight: '6px',
        '&:hover': {
          background: '#285e8c',
          borderColor: '#285e8c',
        },
      },
      '&:last-child': {
        color: '#fff',
        background: '#d9534f',
        borderColor: '#d9534f',
        '&:hover': {
          background: '#c9302c',
          borderColor: '#c9302c',
        },
      },
    },
  },
  impbuttonOkDefault: {
    '& button': {
      '&:last-child': {
        color: '#333',
        background: '#fff',
        border: '2px solid #ccc',
        '&:hover': {
          backgroundColor: '#e6e6e6',
          borderColor: '#ccc',
        },
      },
    },
  },
  noPadding: {
    '& p': {
      padding: 0,
    },
  },
  hideButton: {
    display: 'none',
  },
  successText: {
    textAlign: 'center',
    display: 'block',
    fontSize: '14px !important',
    width: '100%',
    color: '#008000 !important',
  },
  minPopupDiv: {
    minWidth: '500px',
  },
  activeList: {
    background: '#337ab7',
    color: '#fff',
  },
  spResponsive: {
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'md' )]: {
      '& > div > div': {
        maxWidth: '100%',
        width: '95%',
        overflowX: 'auto',
      },
      '& > div > div > div': {
        minWidth: '1300px',
      },
    },
  },
  shareScenarioPopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '52%',
      border: '2px solid #347ab6',
      maxHeight: 'calc(100% - 58px)',
      margin:'29px',
      [theme.breakpoints.down( 'md' )]: {
        maxWidth: '65%',
        width: '65%',     
       
      },
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '100%',
        width: '100%',      
        margin:'15px',
      },
    },
  },
  shareTitle: {
    paddingTop: '8px',
    paddingBottom: '8px',    
    borderRadius: '0',
    borderBottom: '1px solid #e5e5e5',
    position: 'relative',
    '& h3': {
      fontSize: '28px',
      lineHeight: '28px',
      margin: '0',
      fontWeight: '500',
      color: '#0069aa',
      fontFamily: 'inherit',
    }, 
  },
  shareModal: {  
    borderRadius: '0',
    borderTop: 'none',
    padding: '15px 0px 15px 0px',
    boxSizing: 'border-box',
  },
  shareFirst: {
    '& p': {
      fontSize: '18px',
      lineHeight: '18px',
      margin: '0 0 15px',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '17px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '15px',
      },
    },  
    '& b': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
    '& select': {
      height: '30px',
      margin: '0 0 20px',
      lineHeight: '30px',
      padding: '0 10px',      
      backgroundSize: '8px',
      appearance: 'none',
      WebkitAppearance: 'none',
      width: '100%',
      outline: '0',
      border: '1px solid #ababab',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      // backgroundImage: `url(${selectDrop})`,
      background: `url(${selectDrop}) right 8px center no-repeat`,
      boxSizing: 'border-box',
      '& option': {
        padding: '8px 0',
        margin: '0 0 8px 15px',
        backgroundPosition: '10px 5px',
        backgroundSize: '38px',
      },
    },
    '& input': {
      width: '100%',
      display: 'block',
      margin: '0 0 25px',
      border: '1px solid #ccc',
      borderLeft: 'none',
      borderRight: 'none',
      outline: '0',
      borderTop: 'none',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '0 0 0px',
      },
    },
  },
  commonButton: {
    display: 'inline-block',
    marginBottom: '0',    
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    MsTouchAction: 'manipulation',
    touchAction: 'manipulation',
    cursor: 'pointer',
    WebkitUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    backgroundImage: 'none',
    fontFamily: 'inherit',
  },
  backToSimulator: {    
    position: 'absolute',
    left: '50%',
    bottom: '26px',
    float: 'none',
    padding: '8px 18px',
    fontSize: '15px',
    transform: 'translateX(-50%)',
    WebkitTransform: 'translateX(-50%)',
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    textTransform: 'none',
    [theme.breakpoints.down( 'xs' )]: {
      position: 'relative',
      left: 'auto',
      bottom: 'auto',
      transform: 'none',
      WebkitTransform: 'none',
      marginBottom: '20px',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#204d74',
      borderColor: '#122b40',
    },    
  }, 
  shareContent: {
    position: 'relative',
  },
  shareGrid: {
    height: '100%',
    minHeight: '133px',
    position: 'relative',
    padding: '0 15px',
    [theme.breakpoints.down( 'xs' )]: {
      minHeight: 'auto',
    },
  }, 
  shareUnList: {
    width: '100%',
    height: '325px',
    outline: '0',
    padding: '8px 0 8px 10px',
    background: '#fff',
    border: '1px solid #ababab',
    boxSizing: 'border-box',
    margin: '0px',
    listStyleType: 'none',
    overflowY: 'scroll',
  },
  addRemoveBtn: {
    padding: '108px 10px 0 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 15px 0 15px',
    },
    '& button': {
      width: '100px',
      margin: '0 0 12px',
      background: '#fff',
      border: '1px solid #ababab',
      outline: '0',
      padding: '8px 0',
      cursor: 'pointer',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      textTransform: 'none',
      boxSizing: 'border-box',
      borderRadius: '0',
      [theme.breakpoints.down( 'xs' )]: {
        marginRight: '10px',
      },
      '@media (max-width: 767px)': {
        width: '90px',
      },
      '&:hover': {
        background: '#fff',
      },
    },
  },
  selectedUsers: {
    padding: '0 15px 0 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0 15px 0 15px',
    },
  },
  shareButton: {
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    textTransform: 'none',
    '&:hover': {    
      backgroundColor: '#286090',
      borderColor: '#204d74',
    },
  },
  modalFooterNew:{
    padding: '15px',
    textAlign: 'right',
    border: 'none',
    '& button': {     
      fontSize: '14px',
    },
  },
  simulatorBlack: {
    color: '#000',
    opacity: '0.3',
  },
  sightSavePopup: {
    '& > div > div': {
      width: '98%',
      maxWidth: '100%',
      margin: '30px auto',
    },
  },
  sightSaveContent: {
    padding: '0px',
  },
  sightSaveFooter: {
    padding: '0px',
  },
  csSaveBody:{
    overflow: 'auto',
    paddingBottom: '0',
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
    height: 'calc(100vh - 200px)',
    alignItems: 'center',
    background: '#fff',
    '@media (max-width: 420px)': {
      flexWrap: 'wrap',
    },
  },
  csSaveImage: {
    width: '45%',
    padding: '50px 50px 30px',
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      padding: '10px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      padding: '30px 20px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 50px 10px',
    },
    '& > div':{
      width: '100%',
    }
  },
  greenCircleImage: {
    '& img': {
      maxWidth: '200px',
      marginBottom: '95px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',
        marginBottom: '30px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '120px',  
        marginBottom: '70px',     
      }, 
      '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '120px',  
        marginBottom: '50px',     
      },     
      '@media (max-width: 420px)': {
        maxWidth: '75px',
        marginBottom: '20px',
      },
    },
  },
  pwLogo: {
    '& img': {
      maxWidth: '160px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',       
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '100px',       
      },     
    },
  },
  puzzleText: {
    fontSize: '26px',
    color: '#0069aa',
    fontFamily: 'MuseoSans-100',
    paddingTop: '10px',
    margin: '0 0 10px',
    '@media (max-width: 767px)': {
      fontSize: '23px',   
    }, 
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '23px', 
    },
    '@media (max-width: 420px)': {
      fontSize: '20px',
    },
  },
  csSaveMessage: {
    width: '55%',
    padding: '50px 50px 30px',
    background: '#0069aa',
    color: '#fff',
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    '@media (max-width: 767px)': {
      padding: '20px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {   
      padding: '30px 10px 17px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 10px 10px',
    }, 
    '& > div':{
      height: '100%',
    }, 
    '& h1': {
      fontSize: '40px',
      paddingTop: '60px',
      marginBottom: '50px',
      color: '#fff',
      fontWeight: '400',
      marginTop: '20px',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-300',
      '@media (max-width: 767px)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '0px',
        marginBottom: '10px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '50px',
        marginBottom: '20px',
      },
      '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '20px',
      },
      '@media (max-width: 420px)': {
        paddingTop: '10px',
        marginBottom: '10px',
        fontSize: '30px',
      },    
    },
    '& h3': {
      fontSize: '27px',
      marginBottom: '20px',
      color: '#fff',
      fontWeight: '400',
      fontFamily: 'MuseoSans-300',
      marginTop: '20px',
      lineHeight: '1.1',
      '@media (max-width: 767px)': {
        paddingTop: '0px',
        fontSize: '22px',
        marginTop: '10px',
        marginBottom: '10px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '22px',
        marginTop: '20px',
        marginBottom: '20px',
      },
      '@media (max-width: 420px)': {
        margin: '15px auto',
        fontSize: '20px',
      },
    },   
  },
  goTimeline: {
    marginTop: '90px',
    color: '#fff',
    display: 'table',
    fontSize: '20px',
    fontFamily: 'MuseoSans-300',
    lineHeight: 'normal',
    margin: '25px auto',
    textTransform: 'uppercase',
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '10px',
    marginBottom: '5px', 
    '@media (max-width: 767px)': {
      marginTop: '20px',
      fontSize: '15px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      marginTop: '25px',
      fontSize: '15px',
    }, 
    '@media (max-width: 420px)': {
      marginTop: '20px',
    },
    '&:hover': {
      background: '#f69a34',
      borderColor: '#f69a34',
    },   
  },
  backToModule: {
    paddingTop: '5px',
    fontSize: '18px',
    fontFamily: 'MuseoSans-300',
    margin: '0 0 10px',
    '& a': {
      color: '#fff',
      textTransform: 'uppercase',
      fontSize: '14px',
      textDecoration: 'underline',
      paddingTop: '0',
      '&:hover': {
        color: '#f69a34',
      },
    },
  },
    videoPopup: {
    paddingTop: '0px',
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  closeSaveBtn: {
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    position: 'absolute',
    right: '10px',
    top: '10px',
    left: 'auto',
    float: 'right',
    color: '#fff',
    opacity: '1',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontWeight: '700',
    textTransform: 'none',
    fontFamily: 'MuseoSans-300',
    minWidth: 'auto',
    boxSizing: 'border-box',
    zIndex: '9',
    '@media (max-width: 420px)': {
      color:'#333',
    },
    '&:hover, &:focus': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
    },
  },
  careerListItems: {
    paddingLeft: '26px',
    listStylePosition:'outside',
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '0',
    '& li': {
      marginBottom: '10px',
      fontSize: '18px',
      fontFamily: '"MuseoSans-300"',
      lineHeight: '1.42857143',
      '@media screen and (min-width: 500px) and (max-width: 767px)': {
        fontSize: '14px',  
        marginBottom: '8px',     
      },
      '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        fontSize: '16px',
        marginBottom: '5px', 
      },
      '@media (max-width: 320px)': {
        fontSize: '15px',  
      }, 
    },
  },
  gotoDashboard: {
    color: '#fff',
    display: 'table',
    fontSize: '20px',
    fontFamily: '"MuseoSans-300"',
    lineHeight: 'normal',
    margin: '25px auto',
    textTransform: 'uppercase',
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '10px',
    outline: '0',
    textDecoration: 'none',
    '@media screen and (min-width: 500px) and (max-width: 767px)': {
      fontSize: '14px',
      margin: '10px auto 0px auto',
    },
    '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '16px',
      margin: '10px auto 0px auto',
    },
    '@media (max-width: 320px)': {
      margin: '10px auto 10px auto',  
      fontSize: '20px',      
    },
    '&:hover': {
      background: '#f69a34',
      borderColor: '#f69a34',
    },
  },
  shareText:{
    padding: '0px 15px',
    color: 'red',
  },
  shareText2:{
    padding: '0px 15px',
    color: 'green',
  },
  formInput:{
    display: 'initial !important'
  },
  colorRed:{
    '&::before': {
      background: 'red',
    },
  }
} );

export default styles;
