const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  MyAccount: {
    background: '#e9eff3',
  },
  myAccountLeft: {
    background: '#f4f4f4',
    paddingTop: '20px',
    minHeight: 'calc(100vh - 86px)',
    '& h3': {
      margin: '0 20px 20px',
      color: theme.palette.common.black,
    },
  },
  tabs: {
    '&$tabSelected': {
      color: 'red',
      fontWeight: theme.typography.fontWeightMedium,
    },
    padding: '0',
    selectedBackgroundColor: '#000000',
    '& button': {
      textAlign: 'left',
      minHeight: 'inherit',
      padding: '10px 20px',
      color: theme.palette.common.black,
      opacity: '1',
      lineHeight: 'inherit',
      maxWidth: 'inherit',
      borderBottom: '1px solid #ccc',
      '& :hover': {
        background: 'none',
      },
      '& span': {
        alignItems: 'flex-start',
      },
      '&:hover': {
        background: theme.palette.common.white,

      },
    },
  },
  accountHead: {
    display: 'flex',
    alignItems: 'center',
    background: '#016aab',
    justifyContent: 'center',
    color: '#fff',
    '& h3': {
      fontSize: '30px',
      fontWeight: '100',
      fontFamily: 'MuseoSans-100',
    },
    '& figure': {
      margin: '0',
      maxWidth: '60px',
      width: '60px',
      height: '60px',
      overflow: 'hidden',
      background: '#ccc',
      borderRadius: '50%',
      marginRight: '10px',
      position: 'relative',
      '& input': {
        position: 'absolute',
        left: '0',
        width: '100%',
        height: '100%',
        opacity: '0',
        cursor: 'pointer',
        zIndex: '99',
      },
      '& img': {
        width: '100%',
      },
    },
  },
  tabPanelContent: {
    '& > div': {
      padding: '50px 0',
    },

  },
  accountInformationContent: {
    background: '#fff',
    padding: '20px',
  },
  informationNewHead: {
    display: 'flex',
    marginBottom: '15px',
    '& h3': {
      fontSize: '20px',
      color: '#0069aa',
    },
    '& span': {
      margin: '0 0 0 auto',
      background: '#f89b23',
      border: 'none',
      borderBottom: '2px solid #cb7002',
      color: '#fff',
      lineHeight: 'normal',
      paddingBottom: '5px',
      paddingTop: '8px',
      borderRadius: '0',
      textTransform: 'uppercase',
      fontSize: '14px',
      fontFamily: 'MuseoSans-500',
      paddingLeft: '15px',
      paddingRight: '15px',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  accountBox: {
    maxWidth: '600px',
  },
  formGroup: {
    marginBottom: '15px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    },
    '& > div': {
      margin: '0',
    },
    '& fieldset': {
      display: 'none',
    },
    '& span': {
      width: '175px',
      minWidth: '170px',
      display: 'block',
      fontFamily: 'MuseoSans-300',
      fontSize: '16px',
      color: '#000',
      lineHeight: '17px',
      fontWeight: '700',
      paddingRight: '15px',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'block',
        width: '175px',
        marginBottom: '6px',

      },
    },
    '& select': {
      background: '#fff',
      width: '100%',
      borderRadius: '4px',
      cursor:'pointer',
      padding: '9px 15px',
      border: '1px solid #ccc',
      outline: 'none',
      color: '#555',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      fontFamily: 'MuseoSans-300',
      [theme.breakpoints.down( 'xs' )]: {
        color: '#555',
      },
      '&:disabled': {
        background: '#eee',
        opacity: '1',
      },
      '&:focus': {
        borderColor: '#66afe9',
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
    },
    '& input': {
      background: '#fff',
      width: '100%',
      maxWidth: '100%',
      borderRadius: '4px',
      padding: '9px 15px',
      border: '1px solid #aaa',
      outline: 'none',
      color: '#555',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      fontFamily: 'MuseoSans-300',
      '&:disabled': {
        background: '#ebebe4',
      },
      '&:focus': {
        borderColor: '#66afe9',
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
      '&::placeholder': {
        opacity: '1',
      },
    },
  },
  billingContentNew: {
    background: '#fff',
    marginTop: '0px',
    marginBottom: '40px',
    boxShadow: '0 2px 2px rgba(0,0,0,.25)',
    borderRadius: '4px',
    '& h3': {
      margin: '0 0 38px',
      fontWeight: 'normal',
      padding: '0 0 20px',
      fontSize: '30px',
      lineHeight: '30px',
      color: '#0069aa',
      borderBottom: '1px solid #bbb',
    },
  },
  billingContent: {
    display: 'flex',
    width: '100%',
    marginBottom: '15px',
    alignItems: 'start',
    '& > div': {
      display: 'table-cell',
      width: '33.33%',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'block',
        width: 'initial',
      },
      '& h5': {
        margin: '0 0 8px',
        fontSize: '18px',
        lineHeight: '18px',
        color: '#0069aa',
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
      '& p': {
        margin: '0',
      },
    },
  },
  paymentDetails: {
    background: '#f2f2f2',
    padding: '15px',
    position:'relative',
    '& h5': {
      color: '#000 !important',
    },
  },
  billingHistoryNew: {
    marginTop: '25px',
    borderTop: '1px solid #ccc',
    paddingTop: '20px',
    '& h4': {
      margin: '0 0 8px',
      fontSize: '18px',
      lineHeight: '18px',
      color: '#0069aa',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  billingHistoryNew2:{
    borderTop: '0px solid #ccc',
    paddingTop: '0px',
  },
  billingHead: {
    '& table': {
      width: '100%',
      textAlign: 'left',
      borderCollapse: 'collapse',
      '& thead': {
        borderBottom: '1px solid #000',
        '& th': {
          padding: '10px 15px',
          fontFamily: 'MuseoSans-700',
          fontSize: '16px',
          color: '#000',
          fontWeight: 'normal',
          '&:first-child': {
            minWidth: '250px',
          },
        },
      },

      '& tr': {
        borderBottom: '1px solid #ccc',
        borderTop: '1px solid #ccc',

        '& td': {
          padding: '10px 15px',
          fontSize: '16px',
          color: '#000',
          '&:nth-child(3)':{
            textAlign: 'center',
          },
          '&:nth-child(4)':{
            textAlign: 'center',
          },
          '&:nth-child(5)':{
            textAlign: 'center',
          }
        },
      },
    },
  },
  notFound: {
    background: '#f2f2f2',
  },
  formGroupCheckBox: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      padding: '0 5px 0 0',
      background: 'transparent !important',
      '&:hover': {
        background: 'transparent',
      },
    },
    '& svg': {
      color: theme.palette.common.blue,
    },
    '& p': {
      color: '#000',
      margin: '0',
      fontFamily: 'MuseoSans-300',
    },
  },
  hidle: {
    fontSize: '20px',
    lineHeight: '26px',
    color: '#000',
  },
  borderLine: {
    background: '#046cab',
    height: '2px',
    width: '80%',
  },
  checkBoxBlock: {
    marginTop: '0',
    '& > div': {
      margin: '10px 0',
      '& > div': {
        margin: '10px 0',
      },
    },
  },
  billingContentNewHead: {
    '& h3': {
      marginBottom: '0',
      border: 'none',
    },
    '& p': {
      margin: '0',
    },
  },
  deviderNew: {
    margin: '20px -25px 0',
    height: '1px',
    background: '#ccc',
  },
  btnGrouop: {
    textAlign: 'center',
    marginLeft: '175px',
    [theme.breakpoints.down( 'xs' )]: {
      marginLeft: '0',
    },
  },
  saveChanges: {
    margin: '0 5px 0',
    background: '#f89b23',
    border: 'none',
    borderBottom: '2px solid #cb7002',
    color: '#fff',
    lineHeight: 'normal',
    paddingBottom: '5px',
    paddingTop: '8px',
    borderRadius: '0',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'MuseoSans-500',
    paddingLeft: '15px',
    paddingRight: '15px',
    textDecoration: 'none',
    '&:hover': {
      background: '#e78c1e',
      color: '#fff',
    },
  },
  CancelBtn: {

    margin: '0 5px 0',
    background: '#a19c9c',
    border: 'none',
    borderBottom: '2px solid #5d5c5c',
    color: '#fff',
    lineHeight: 'normal',
    paddingBottom: '5px',
    paddingTop: '8px',
    borderRadius: '0',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'MuseoSans-500',
    paddingLeft: '15px',
    paddingRight: '15px',
    textDecoration: 'none',
    '&:hover': {
      background: '#5d5c5c',
      color: '#fff',
    },
  },
  informationNewHeadMargin: {
    marginTop: '30px',
  },
  EditIcon: {
    position: 'absolute',
    bottom: '4px',
    right: '3px',
    cursor: 'pointer',
    fontSize: '21px',
  },
  securitySelect: {
    width: '25%',
    height: '34px',
    outline: 'none',
    backgroundColor: '#fff',
    borderRadius: '5px',
    marginTop: '20px',
    padding: '5px 10px',
    border: '1px solid #ccc',
    color: '#555',
    [theme.breakpoints.down( 'xs' )]: {
      width: '75%',
    },
    '&:focus': {
      borderColor: '#66afe9',
      outline: '0',
      WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
    },
  },
  collbrationCheckGap: {
    marginTop: '20px',
  },
  tooltipInfo: {
    width: '200px !important',
    background: '#0069aa !important',
    fontSize: '14px !important',
    fontWeight: '500',
    padding: '7px !important',
    textAlign: 'initial',
    color: '#fff !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
  },
  manageAccount: {
    '& > div': {
      display: 'inherit',
      maxWidth: '300px',
    },
    borderBottom: '1px solid #ccc',
    paddingBottom: '20px',
    '& p': {
      color: '#0069aa',
    },
  },

  billingHistoryMargin: {
    marginTop: '15px',
  },
  tooltipIcon: {
    verticalAlign: 'middle',
    marginLeft: '5px',
    width: '20px',
    cursor: 'pointer',
  },
  borderTopColor: {
    width: '300px',
    '&:after': {
      borderTopColor: '#0069aa !important',
    },
  },
  passwordValidation: {
    position: 'absolute',
    width: '200px',
    // height: '155px',
    padding: '30px 20px 30px !important',
    top: '-60px',
    right: '-293px',
    border: '2px solid #0069aa',
    background: '#fff',
    zIndex: '99',
    borderRadius: '15px',
    opacity: '1',
    visibility: 'visibility',
    transition: 'all 0.3s ease-in-out',
    WebkitTransition: 'all 0.3s ease-in-out',
    MozTransition: 'all 0.3s ease-in-out',
    margin: '0',
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '58px',
      left: '-22px',
      background: '#fff',
      width: '40px',
      height: '40px',
      border: '2px solid #0069aa',
      transform: 'rotate(45deg) skew(-6deg, -6deg)',
      WebkitTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      MozTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      MsTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      OTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      borderRight: 'none',
      borderTop: 'none',
      zIndex: '-1',
    },
    '& li': {
      lineHeight: '14px',
      color: '#999',
      display: 'flex',
      alignItems: 'center',
      margin: '0 0px 7px 0',
      fontSize: '90%',
      '& img': {
        width: '12px',
        marginRight: '5px',
      },
    },
  },
  disableCheckbox: {
    opacity: '0.5',
  },
  billingTable: {
    overflow: 'auto',
    '& table': {
      minWidth: '800px',
    },
  },
  generalTooltip: {
    width: '300px !important',
    fontSize: '14px !important',
    maxWidth: '300px !important',
    textAlign: 'left',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    '@media (max-width: 420px)': {
      width: '180px !important',
      maxWidth: '180px !important',
    },
  },
  modalHeader: {    
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    position: 'relative',
    '& h4': {
      color: '#0069aa',  
      margin: '0',
      lineHeight: '1.42857143',
      fontWeight: '500',
      fontSize: '18px',
      fontFamily: '"MuseoSans-300"',
    },
  },
  closeButton: {
    color: '#000',   
    marginTop: '-2px',
    webkitAppearance: 'none',
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontFamily: 'inherit',
    fontWeight: '700',
    filter: 'alpha(opacity=20)',
    opacity: '.2',
  },
  modalBody: {
    position: 'relative',
    padding: '15px 15px 20px',
    boxSizing: 'border-box',
    fontSize: '14px',
    display:'flex',
    alignItems: 'baseline',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      flexWrap:'wrap',
    },
    '& form':{
      display: 'flex',
      width: '100%',
    }
  },
  modalContent:{
    position: 'relative',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,.2)',
    borderRadius: '6px',
    outline: '0',
    WebkitBoxShadow: '0 3px 9px rgb(0 0 0 / 50%)',
    boxShadow: '0 3px 9px rgb(0 0 0 / 50%)',
  },
  paymentPageRight:{
    padding: '0 28px 0 0',
    margin: '0',
    // borderRight: '1px solid #c2c2c2',
    // width: '49%',
    width: '50%',
    position: 'relative',
    minHeight: '350px',
   boxSizing:'border-box',
    display: 'inline-block',
    verticalAlign: 'top',
    '&:before':{
      content: '""',
      height: '85%',
      background: '#c2c2c2',
      position: 'absolute',
      width: '1px',
      right: '0px',
      top: '0px',
    }
  },
  paymentPageMain:{
    marginTop: '0',
    maxWidth: 'inherit',
    margin: '45px auto 20px auto',
    '& h2':{
      fontSize: '20px',
      lineHeight: '20px',
      color: '#0069aa',
      margin: '10px 10px 10px 0',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
    '& p':{
      fontSize: '14px',
      lineHeight: '14px',
      color: '#000',
      margin: '0 0px 7px 0',
    },
  },
  paymentPage:{
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
    margin: '0 auto 20px auto',
    padding: '0',
  },
  paymentPageHeader:{
    borderBottom: '1px solid #d9d9d9',
    padding: '12px 15px',
    margin: '0 0 20px 0',
    display:'flex',
    justifyContent:'space-between',
    '& h3':{
      display: 'inline-block',      
      margin: '3px 0 0 0',
      color: '#616161',
      fontSize: '18px',
      lineHeight: '18px',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
    '& ul':{
      display: 'inline-block',      
      padding: '2px 0 0 0',
      margin: '0',
      '& li':{
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '0',
        '& img':{
          filter:'grayscale(100%)',
          WebkitFilter:'grayscale(100%)',
          MozFilter:'grayscale(100%)',
          MsFilter:'grayscale(100%)',
          OFilter:'grayscale(100%)',
          verticalAlign:'middle',
        },
        '& p':{
          fontSize: '12px',
          lineHeight: '12px',
          margin: '0',
          color: '#5f5f5f',
        },
      },
    },
  },
  fielsSet:{
    padding: '0 15px',
    minWidth: '0',
    margin: '0',
    border: '0',
    '& ul':{
      padding: '0',
      margin: '0',
      '& li':{
        padding: '0 10px 10px 0',
        display: 'block',
        width: '100%',
        float: 'left',
        position: 'relative',
        boxSizing:'border-box',
        '& div':{
          '&:before':{
            display: 'none',
          },
          '&:after':{
            display: 'none',
          }
        },
        '& input':{
          width: '100%',
          borderRadius: '4px',
          outline: 'none',
          border: '1px solid #d9d9d9',
          height: '35px',
          padding: '0 8px',
          fontFamily: 'inherit',
          fontSize: '14px',
          lineHeight: 'inherit',
          margin: '0',
          font: 'inherit',
          color: 'inherit',
          boxSizing:'border-box',
          background: '#fff',
          '&::placeholder':{
            color: '#000',
            opacity: '0.8',
          }
        },
      },
    },
  },
  infoIcon:{
    position: 'absolute',
    top: '6px',
    right: '12px',
    width: 'auto',
    display: 'inline-block',
    '& img':{
      cursor: 'default',
      marginTop: '0',
      maxWidth: '20px',
      verticalAlign: 'middle',
      border:'0px',
    },
  },
  cardName:{
    width: '50% !important',
    '& input':{
      width: '100%',
      borderRadius: '4px',
      outline: 'none',
      border: '1px solid #d9d9d9',
      height: '35px',
      padding: '0 8px',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      margin: '0',
      font: 'inherit',
      color: 'inherit',
    },
  },
  expMonth:{
    width: '15.5% !important',
  },
  expYear:{
    width: '15.5% !important',
  },
  cvvNumber:{
    width: '25% !important',
    // paddingRight: '0 !important',
    display: 'flex !important',
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    padding: '0px 8px !important',
    '& > div':{
      '&:first-child':{
        width: 'calc(100% - 30px)',
        height: '35px',
        '& iframe':{
          position: 'relative',
          top: '8px',
        }
      }
    },
  },
  cardNumber:{
    paddingRight: '0 !important',
    '& > div':{
      '&:first-child':{
        height: '35px',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        padding: '0px 8px',
        '& iframe':{
          position: 'relative',
          top: '8px',
        }
      }
    },
  },
  cardMonth: {
    width: '25% !important',
    '& > div':{
      '&:first-child':{
        height: '35px',
        border: '1px solid #d9d9d9',
        borderRadius: '4px',
        padding: '0px 8px',
        '& iframe':{
          position: 'relative',
          top: '8px',
        }
      }
    },
  },
  confirmBlock:{
    textAlign: 'right',
    margin: '20px 0 0 0',
    '& a':{
      display: 'inline-block',
      float: 'left',
      margin: '13px 0 0 0',
      padding: '0 0 0 0',
      color: '#0069aa',
      position: 'relative',
      fontSize: '12px',
      lineHeight: '12px',
      textDecoration: 'underline',
      border: '0',
      outline: '0',
      '& img':{
        maxWidth: '200px',
        verticalAlign: 'middle',
        border: '0',
      },
    },
  },
  editDetailsPopup:{
    boxSizing:'border-box',
    '& > div > div':{
      width:'82%',
      maxWidth:'100%',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  existingCardContent:{
    display: 'inline-block',
    width: '48.5%',
    // width: '50%',
    position: 'relative',
    minHeight: '350px',
    float: 'left',
    padding: '0 0 0 28px',
    boxSizing:'border-box',
  },
  billingAddress:{
    marginBottom: '15px',
    padding: '0 0 0px 0',
    display: 'block',
    boxSizing:'border-box',
    '& h3':{
      margin: '10px 10px 43px -2px',
      boxSizing:'border-box',
      display: 'block',
      fontSize: '20px',
      lineHeight: '20px',
      color: '#0069aa', 
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  billingFields:{
    padding: '0 0 18px',
  },
  inputHolder:{
    position: 'relative',
    '& span':{
      top: '15px',
      position: 'absolute',   
      left: '10px',
      color: '#a0a0a0',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '14px',
      transition: 'all 0.3s ease-in-out',
      WebkitTransition: 'all 0.3s ease-in-out',
      MozTransition: 'all 0.3s ease-in-out',
      MsTransition: 'all 0.3s ease-in-out',
      OTransition: 'all 0.3s ease-in-out',
      zIndex: '9',
    },
    '& div':{
      width: '100%',
      '&:before':{
        display: 'none',
      },
      '&:after':{
        display: 'none',
      }
    },
    '& input':{
      width: '100%',
      borderRadius: '4px',
      outline: 'none',
      border: '1px solid #d9d9d9',
      height: '40px',
      padding: '13px 10px 0 10px',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      margin: '0',
      font: 'inherit',
      color: 'inherit',
      boxSizing:'border-box',
      background: '#fff',
      // fontSize: '16px',
      // color: '#000',
    },
    // '& input':{
    //   width: '100%',
    //   borderRadius: '4px',
    //   outline: 'none',
    //   border: '1px solid #d9d9d9',
    //   height: '40px',
    //   padding: '13px 10px 0 10px',
    //   background: 'transparent',
    //   zIndex: '2',
    //   position: 'relative',
    //   margin: '0',
    //   font: 'inherit',
    //   color: '#000',
    //   fontFamily: 'inherit',
    //   fontSize: '16px',
    //   lineHeight: 'inherit',
    //   boxSizing:'border-box',  
    // },
    '&:focus':{
      '& span':{
        top:'5px !important',
      },
    },
  },
  focusedInputReq:{
    '& label':{
      '& span':{
        position: 'initial',
      }
    }
  },
  billingLeft:{
    width: '35%',
    display: 'inline-block',
    verticalAlign: 'top',
    padding: '0 5px 0 0',
    boxSizing:'border-box',
    '&:last-child':{
      width:'30% !important',
      padding:'0px !important',
    },
    '& select':{
      width: '100%',
      borderRadius: '4px',
      outline: 'none',
      border: '1px solid #d9d9d9',
      height: '40px',
      padding: '13px 10px 0 10px',
      WebkitAppearance: 'textfield',
      backgroundColor: '#fff',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#555',
      display: 'block',
      fontFamily: 'inherit',
      textTransform: 'none',
      boxSizing: 'border-box',
    },
  },
  focusedInput:{
    '& span':{
      top:'5px !important',
    },
  },
  updateButton:{
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    position: 'absolute',
    bottom: '0',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',   
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',
    backgroundImage: 'none',
    textTransform:'uppercase',
    right:'0px',
    '&:hover':{
      color: '#fff',
      backgroundColor: '#286090',
      borderColor: '#204d74',
    },
  },
  editButton:{
    background: '#f89b23',
    border: 'none',
    borderBottom: '2px solid #cb7002',
    color: '#fff',
    lineHeight: 'normal',
    margin: '10px 5px 0 0',
    padding:'6px 12px',
    borderRadius: '0',
    textTransform: 'uppercase',
    fontSize: '14px',
    fontFamily: 'MuseoSans-500',  
    cursor:'pointer',   
    '&:active, &:hover':{
      color: '#fff',
      backgroundColor: '#204d74',
      borderColor: '#122b40',
    },
  },
  upgradeBtn:{
    background: '#337ab7 !important',
    borderColor: '#337ab7 !important',
  },
  editSmallBtn:{
    top: '-4px',
    right: '2px', 
    position: 'absolute', 
  },
  buttonsBlock:{
    marginTop:'10px',
  },
  renewPopup:{
    '& h4':{
      color: '#0069aa',
      fontSize: '24px !important',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
    '& p':{
      fontSize:'16px',
      fontFamily: 'MuseoSans-300',  
      margin:'0px !important',
      color:'#000',
    },
  },
  modalFooter:{
    padding: '15px',
    textAlign: 'right',
    borderTop: '1px solid #e5e5e5',
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',    
    textTransform: 'uppercase',
    transition: 'all .5s ease 0s',
  },
  modalBodyNew: {
    position: 'relative',
    padding: '15px 15px',
    boxSizing: 'border-box',
    fontSize: '14px',
    display:'flex',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
      flexWrap:'wrap',
    },
  },
  confirmButton:{
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    '&:hover, &:active':{
      color: '#fff',
      backgroundColor: '#286090',
      borderColor: '#204d74',
    },
  },
  cancelButton:{
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    marginBottom: '0',
    marginLeft: '5px',
    '&:hover, &:active':{
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  subDetails:{
    padding:' 8px 0 0 0',
    borderTop: '1px solid #ccc',
    margin: '10px 0 0 0',
    display:'flex',
  },
  detailsSlide:{
    padding:' 0 0 10px 0',
  
  },
  leftData:{
    paddingBottom: '10px !important',
  },
  leftBlock:{
    display: 'inline-block',
    width: '85%',
    padding: '0 8px 0 0',
    '& p':{
      fontFamily: 'MuseoSans-700', 
    },
  },
  rightBlock:{
    width: '14%',
    padding: '0',
    textAlign: 'right',   
  },
  boldFont:{
    fontFamily: 'MuseoSans-700 !important', 
  },
  modalNew: {
    position: 'relative',
    padding: '15px',
    boxSizing: 'border-box',
    fontSize: '14px',   
    [theme.breakpoints.down('xs')]: {
      padding: '10px',     
    },
  },
  upgradePopup:{
    '& h4':{
      color: '#0069aa',      
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
    '& p':{
      fontSize:'16px',
      fontFamily: 'MuseoSans-300',  
      margin:'0px',
      color:'#000',
    },
  },
  controlGroup:{
    '& label': {
      padding: '3px 0',
      fontSize: '16px',
      margin: '0',
      display: 'inline-block',
      fontFamily:'inherit',
      color:'#000',
      '& svg': {
        width: '20px',
        color: '#0069aa',
        height: '20px',
      },
      '& span': {
        padding: '0 3px 0 0',
        fontSize: '16px',
        color: '#000',
        background: 'transparent !important',
        '&:hover': {
          background: 'transparent',
        },
        '&:focus': {
          background: 'transparent',
        },
      },
    },
  },
  spanText:{
    top: '3px',
    position: 'relative',
    color:'#000',
    fontSize:'16px',
    fontFamily: 'MuseoSans-300', 
  },
  btmMargin:{
    marginBottom:'10px !important',
  },
  flexDiv:{
    display:'flex',
  },
  accountIDBox:{
    marginBottom: '25px',
    minHeight: '53px',
  },
  paymentDetailspara: {
    '& p':{
      marginBottom: '10px !important',
    }
  },
  paymentSelect: {
    '& select':{
      border: '1px solid #ccc',
      borderRadius: '0',
      padding: '5px 10px',
      outline: '0',
      background: '#fff',
      fontSize: '13px',
      width: '150px',
      position: 'relative',
      zIndex: '99',
      marginLeft: '4px',
      marginTop: '1px',
    }
  },
  paymentBtn:{
    color:' #fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    fontSize: '14px',
    lineHeight: '1.42857143',
    borderRadius: '4px',
    padding: '6px 12px',
    '&:hover':{
      backgroundColor: '#286090',
      borderColor: '#204d74',
    }
  },
  updateBtn:{
    textAlign: 'right',
  }
 } );

export default styles;
