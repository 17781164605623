import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../../screens/pwi/buying-a-home/styles';

const BuyingHomeModule = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Welcome to the Buying a Home module.</p>
      <p>The home buying module is designed to show you your purchasing power when buying a home at any point in time.</p>
      <p className={ classes.nomarginBottom }>Here&apos;s a list of all things the Buying a Home module can help you do:</p>
      <ul className={ classes.tickList }>
        <li>Discover how much house can you afford and when</li>
        <li>Compare different homes and see if the purchase price is worth it</li>
        <li>Calculate how much cash is needed to buy a home</li>
        <li>Detail the monthly costs of owning a home</li>
        <li>Run &quot;what if&quot; scenarios on mortgage terms and other home buying factors.</li>
      </ul>
      <p>Strategize on home buying and see what works best for you. With a plan in hand, you&apos;ll be confident in your decision. Let&apos;s get started.</p>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>

  );
};

BuyingHomeModule.propTypes = {
  classes: PropTypes.object.isRequired,

};

export default withStyles( styles )( BuyingHomeModule );
