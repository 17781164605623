const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    boxSizing: 'border-box',
  },
  dashboardLogo:{
    width: '150px',
    '& img':{
      width: '100%',
    }
  },
  dashboardPage: {
    background: '#014372',
    padding: '30px',
    minHeight: '100vh',
    [theme.breakpoints.down('xs')]: {
      padding: '30px 0px',
    }
  },
  mcsLogo: {
    maxWidth: '450px',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  containerFluid: {
    paddingBottom: '100px',
  },
  rowLevels: {
    width: '94%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  csLevel: {
    width: '230px',
    margin: '30px 50px 30px 0',
    position: 'relative',
    border: '3px solid #fff',
    borderRadius: '10px',
    padding: '0',
    boxSizing: 'border-box',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
    },
    '@media (min-width: 600px) and (max-width: 1199px)': {
      width: 'calc(50% - 20px)',
      margin: '20px 10px',
    }
  },
  levelDesc: {
    color: '#fff',
    padding: '10px',
    '& img': {
      maxWidth: '70px',
      verticalAlign: 'middle',
    },
    '& p': {
      fontSize: '13px',
      textTransform: 'uppercase',
      margin: '0',
      paddingTop: '20px',
      fontFamily: '"MuseoSans-300"',
      fontWeight: '400',
    },
    '& h3': {
      fontSize: '23px',
      color: '#fff',
      marginTop: '3px',
      fontFamily: '"MuseoSans-300"',
      fontWeight: '400',
      marginBottom: '10px',
      lineHeight: '1.1',
      '& span': {
        display: 'block',
      },
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  goButton: {
    padding: '1px 22px',
    textTransform: 'uppercase',
    margin: '14px 0',
    fontSize: '13px',
    borderRadius: '10px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    WebkitTransition: 'all .5s ease 0s',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    '&:hover': {
      background: '#8ca659',
      borderColor: '#8ca659',
    },
  },
  progressLevels: {
    background: '#8ca659',
    padding: '25px 30px 40px',
    borderBottomLeftRadius: '7px',
    borderBottomRightRadius: '7px',
  },
  csProgress: {
    border: '2px solid #fff',
    borderRadius: '10px',
    height: '22px',
    background: '#fff',
    boxSizing: 'border-box',
    lineHeight: '1.42857143',
  },
  csProgressBar: {
    height: '18px',
    // background: '#0069aa',
    // width: '0',
    background: '#fff',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    boxSizing: 'border-box',
    lineHeight: '1.42857143',
  },
  csWidthHundred: {
    width: '100%',
  },
  progressValue: {
    textAlign: 'center',
    color: '#fff',
    margin: '0',
    paddingTop: '5px',
    fontSize: '12px',
  },
  taskComplete: {
    position: 'absolute',
    top: '-25px',
    right: '-25px',
    '& img': {
      width: '48px',
      height: '48px',
    },
  },
  csBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    '@media (min-width: 600px) and (max-width: 959px)': {
      maxWidth: '600px',
      margin: '0px auto',
    },
    '@media (min-width: 960px) and (max-width: 1199px)': {
      maxWidth: '100%',
    }
  },
  rowTopMargin: {
    marginTop: '40px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0px',
    },
  },
  inactiveModule: {
    opacity: '.5',
    cursor: 'not-allowed',
    '& button': {
      opacity: '.65',
      cursor: 'not-allowed',
      background: '#a19c9c',
      borderColor: '#a19c9c',
      pointerEvents: 'none',
      '&:hover': {
        background: '#a19c9c',
        borderColor: '#a19c9c',
      },
    },
  },
  titleBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '20px',
    marginBottom: '20px',
    '& h3': {
      fontSize: '26px',
      color: '#fff',
      margin: '0',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
      marginRight: '20px',
    },
  },
  csFinal: {
    marginRight: '0px !important',
  },
  notificationIcon: {
    position: 'relative',
    margin: '0px 25px 0px 15px',
    '& i': {
      color: '#fff',
      fontSize: '30px',
    },
  },
  profileMenu: {
    position: 'relative',
    marginLeft: '10px',
    '& span': {
      cursor: 'pointer',
      '& img': {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        maxWidth: 'inherit',
        boxShadow: '0 0 0 2px rgb(255 255 255 / 80%)',
      },
    },
  },
  notifyCount: {
    background: '#f89b23',
    borderRadius: '50%',
    color: '#fff',
    content: '""',
    fontSize: '13px',
    height: '22px',
    lineHeight: '22px',
    padding: '0',
    position: 'absolute',
    right: '5px',
    textAlign: 'center',
    top: '-2px',
    width: '22px',
  },
  profileDropdown: {
    top: '35px',
    background: '#fff',
    width: '380px',
    zIndex: '9999',
    left: 'inherit',
    marginTop: '10px',
    right: '0',
    maxWidth: '255px',
    position: 'absolute',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    border: '1px solid rgba(0,0,0,.15)',
    borderRadius: '4px',
    boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
    '&::before': {
      borderBottom: '11px solid #fff',
      left: '91%',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
    '& li': {
      fontFamily: '"MuseoSans-500"',
      fontSize: '16px',
      '& a': {
        padding: '7px 10px',
        clear: 'both',
        fontWeight: '400',
        color: '#333',
        display: 'block',
        lineHeight: '1.42857143',
        whiteSpace: 'nowrap',
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: '#e5e5e5',
          color: '#262626',
        },
      },
    },
  },
  notificationDropDown: {
    display: 'none',
    paddingTop: '0',
    border: '0',
    color: '#000',
    right: '-22px',
    left: 'auto',
    top: '45px',
    background: '#fff',
    width: '380px',
    zIndex: '99999',
    position: 'absolute',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    boxShadow: '0 6px 12px rgb(0 0 0 / 18%)',
    '&::before': {
      left: '88%',
      borderBottom: '11px solid #0069aa',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
  resetNotifications: {
    marginRight: '10px',
  },
  notifyTitle: {
    background: '#0069aa',
    color: '#fff',
    fontSize: '18px',
    padding: '8px 6px',
    '& span': {
      background: '#fff',
      borderRadius: '2px',
      color: '#0082c0',
      cursor: 'pointer',
      float: 'right',
      fontSize: '11px',
      marginTop: '3px',
      padding: '3px 5px',
    },
  },
  notifyDate: {
    display: 'block',
    backgroundColor: '#f1f3f7',
    color: '#93a0b2',
    padding: '14px 8px',
    fontSize: '12px',
    lineHeight: '12px',
  },
  notiPortion: {
    padding: '15px 10px',
    borderBottom: '1px solid rgba(0,0,0,.1)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  profileImg: {
    width: 'auto',
    height: 'auto',
    display: 'inline-block',
    verticalAlign: 'middle',
    '& img': {
      width: '36px',
      height: '36px',
      cursor: 'default',
      display: 'inline-block',
      marginTop: '0',
      borderRadius: '50%',
      maxWidth: 'inherit',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
    },
  },
  notificationMid: {
    width: '88%',
    padding: '0 9px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  noteDate: {
    display: 'inline-block',
    fontSize: '12px',
    marginRight: '5px',
    textAlign: 'right',
    position: 'absolute',
    width: 'auto',
    right: '10px',
    bottom: '0',
    color: '#f89b23',
  },
  univestSpan:{
    position: 'relative',
    display: 'block',
    width: '320px',
    '& img':{
      maxWidth: '100%',
      display: 'block',
      height: 'auto',
    }
  },
  inwiryLink:{
    position: 'absolute',
    top: '0',
    width: '50%',
    height: '100%',
    left: '0px',
  },
  univestLink:{
    position: 'absolute',
    top: '0',
    width: '50%',
    height: '100%',
    right: '0px',
    cursor: 'pointer',
  },

  mcsIntroBlock:{
    margin: '0 auto',
    width: '65%',
    color: '#fff',
    '@media (max-width: 767px)': {
      width: '100%',
    }
  },
  mcsTargetIcon:{
    maxWidth: '155px',
    margin: '0px auto',
    '& img':{
      width: '100%',
    }
  },
  mcsTargetRight:{
    paddingLeft: '35px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0px',
    }
  },
  pageTitle:{
    fontSize: '27px',
    fontFamily: '"MuseoSans-300"',
    margin: '20px 0 30px',
    fontWeight: 'normal',
  },
  pageDesc:{
    fontSize: '24px',
    fontFamily: '"MuseoSans-300"',
    fontWeight: 'normal',
    margin: '0px 0 30px',
  },
  pageSubDesc:{
    fontSize: '22px',
    fontFamily: '"MuseoSans-100"',
    margin: '0px 0 30px',
  },
  gridRow:{
    alignItems: 'center',
  },
  mcsNextBtn:{
    margin: '30px auto',
    textAlign: 'center',
    '& button':{
      background: '#cea347',
      border: '2px solid #fff',
      color: '#fff',
      padding: '7px 45px',
      borderRadius: '6px',
      textTransform: 'uppercase',
      fontSize: '20px',
      display: 'inline-flex',
      WebkitTransition: 'all .5s ease 0s',
      MozTransition: 'all .5s ease 0s',
      transition: 'all .5s ease 0s',
      lineHeight: '1.42857143',
      fontFamily: '"MuseoSans-100"',
      cursor: 'pointer',
      '&:hover':{
        background: '#d09924',
        border: '2px solid #d09924',
      }
    }
  }
});

export default styles;
