import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import styles from './styles';
import mcsTopImage from '../../../assets/img/mcs/level7_bg.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import levelIcon from '../../../assets/img/mcs/level5.png';
import compareGraphic from '../../../assets/img/mcs/compare-careers.png';
import progressImage from '../../../assets/img/mcs/100.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import { loader } from 'graphql.macro';
import { useQuery,useLazyQuery } from '@apollo/react-hooks';

import Pageloader from '../../../components/ui/pageloader';
const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
// const SAVEMYCAREERSKETCH = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');
const MCS_MODULE_DATA = loader('../../../graphql/schema/career-sketch/MCS-Level3-Data.graphql');

const staticLevelData = [
  {
    "id": "7",
    "user_id": 214,
    "level": 2,
    "option_id": 1,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:29:33"
  },
  {
    "id": "8",
    "user_id": 214,
    "level": 2,
    "option_id": 2,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:30:01"
  }
]

const McsLevelFive = (props) => {
  const { classes, history } = props;
  const [firstActivityData, setFirstActivityData] = React.useState("");
  const [secondActivityData, setSecondActivityData] = React.useState("");
  // const [errorMessage, setErrorMessage] = React.useState("");
  const [levelPercentage, setLevelPercentage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [moduleTimeLineData, setModuleTimeLineData] = React.useState([]);

  // const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
  //   onCompleted({
  //     saveMyCareerSketch: {
  //       status,
  //     },
  //   }) {
  //     if (status) {
  //       history.push('./dashboard');
  //       setLoading(false);
  //     }
  //   },
  //   onError(errors) {
  //     const formatedErrors = errors.graphQLErrors.map(({ message }) => (
  //       message
  //     ));
  //     setErrorMessage(formatedErrors);
  //     setLoading(false);
  //   },
  // });

  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "5",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setLevelPercentage(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent);
      setFirstActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 1)[0] : staticLevelData.filter(obj => obj.option_id === 1)[0]);
      setSecondActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 2)[0] : staticLevelData.filter(obj => obj.option_id === 2)[0]);
    },
  });
  React.useEffect(() => {
    if (mcsLevelWiseData) {
      setLoading(false);
    }
  }, [mcsLevelWiseData]);

  const handleGotoNextLevel = () => {
    history.push('./level6');
  }

  const [getModuleData] = useLazyQuery(MCS_MODULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
        data: {
            level: "3",
            option_id: "2"
        }
    },
    onCompleted(response) {
        if (response.getMCSLevel3Data.timeLine) {
          let timelineObj = response.getMCSLevel3Data.timeLine;
          timelineObj = timelineObj.map((obj,index)=>{
            switch ( obj.selected_module ) {
              case 'module2':
                  obj['routePath'] ='/plan-with-inkwiry/he';
                  break;
              case 'module4':
                obj['routePath'] ='/plan-with-inkwiry/cp';
                break;
              case 'module5':
                obj['routePath'] ='/plan-with-inkwiry/ca';
                break;
              case 'module6':
                obj['routePath'] ='/plan-with-inkwiry/mhe';
                break;
                case 'module8':
                obj['routePath'] ='/plan-with-inkwiry/aca';
                break;
                case 'module15':
                obj['routePath'] ='/plan-with-inkwiry/fhe';
                break;
                case 'module17':
                obj['routePath'] ='/plan-with-inkwiry/fca';
                break;
              default:
                break;
            }
          return obj;
          });
          setModuleTimeLineData(timelineObj);
        }
    }
});

useEffect(()=>{
  getModuleData();
    // eslint-disable-next-line
  },[]);
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={loading} />}
      <div className={classes.mcsLevelOne}>
        <div className={classes.topImage}>
          <img src={mcsTopImage} alt="mcs" />
        </div>
        <div className={classes.container}>
          <div className={classes.mcsRow}>
            <div className={classes.mcsLogoImage}>
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.mcsLevelNum}>
              <h3>LEVEL 05</h3>
              <img src={levelIcon} alt="info" className={classes.iconImage} />
            </div>
          </div>
          <div className={classes.mcsBodyContent}>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.leftBlock}>
                  <h1 className={classes.mcsLevelTitle}>Build your stepping stones</h1>
                  <div className={classes.qPosition}>
                    <div className={classes.questionBlock}>
                      <p>As you create a roadmap to the future that excites you, you&apos;ll see the financial outcome of your decisions before you make them. And knowing you&apos;re on a path to reach your goals, you can enjoy confidence and peace of mind.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Now that you&apos;ve set your timeline, entered your current details, and built a scenario for your first stepping stone, continue to build two more stepping stones toward your career goal. </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Plan and explore your next career move or more higher education. Discover how much you might earn after taxes and how much you could spend, save and invest. The goal? Make sure you are cash flow positive in all of your stepping stones so that you can save, start investing early and grow your wealth.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>You may need to revisit some of these resources. Here are some quick links:</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p><a href="/">Compare Schools</a></p>
                      <p><a href="/">Compare Careers</a></p>
                      <p><a href="/">Career Sketch Stories</a></p>
                      <p><a href="/">MyNextMove</a></p>
                      <p><a href="/">Payscale</a></p>
                    </div>
                    {secondActivityData && secondActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleGotoNextLevel}>NEXT LEVEL {'>'}</button>
                    </div> : firstActivityData && firstActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={()=>{history.push(moduleTimeLineData[3]['routePath'] +'?type=mcs5#activity=2')}}>continue</button>
                    </div> : <div className={classes.startButton}>
                      <button type="button" onClick={() => {history.push('/timeline-sketcher?type=mcs5');}}>start</button>
                    </div>}
                    {/* {errorMessage && <span>{errorMessage}</span>} */}
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.rightBlock}>
                  <div className={classes.compareImage}>
                    <img src={compareGraphic} alt="compare" />
                  </div>
                  <div className={classes.activeRow}>
                    <div className={classes.activities}>
                      <h4>Level 05 Activities</h4>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={firstActivityData && firstActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Build a scenario for Stepping Stone #2
                        </label>
                      {firstActivityData && firstActivityData.status === 1 ? <button type="button" onClick={()=>{history.push(moduleTimeLineData[2]['routePath'] +'?type=mcs5#activity=1')}} className={classes.goButton}> Review</button> : ""}
                      </div>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={secondActivityData && secondActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Build a scenario for Stepping Stone #3
                        </label>
                        {secondActivityData && secondActivityData.status === 1 ? <button type="button" onClick={()=>{history.push(moduleTimeLineData[3]['routePath'] +'?type=mcs5#activity=2')}} className={classes.goButton}>Review</button> : ""}
                      </div>
                    </div>
                    <div className={classes.myLevelProgress}>
                      <h4>My Level 05 Progress</h4>
                    </div>
                    <div className={classes.progressPuzzle}>
                      <span className={classes.progressPercent}>{levelPercentage}%</span>
                      <p className={classes.puzzleBar}>
                        <img src={progressImage} alt="info" />
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mcsFooter}>
          <div className={classes.container}>
            <div className={classes.footerRow}>
              <div className={classes.copyRight}>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={classNames(classes.copyRight, classes.informedText)}>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.mcsFluid}>
            <p className={classes.termLinks}>
              <a href="/">Terms of Service</a>
&nbsp;//&nbsp;
              <a href="/">Privacy Policy</a>
&nbsp;//&nbsp;
              <a href="/">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsLevelFive.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsLevelFive);