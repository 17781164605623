function existingAssetsAndLiabilitiesSheetData( module1Data, fedSubExistingLoanData, fedUnSubExistingLoanData, privateExistingLoanData, existingHomeData, existingHomeMortgageData, existingCarLoanData, existingCreditCardDebtData ) {
  // Variables for Existing Assets and Liabilties Backend data
  let year = 0;
  let yearLimit = 0;
  let higherEdOutputData = {};

  let studentLoanBalanceVal = 0;
  let principalPaybackVal = 0;
  let fedInterestVal = 0;
  let unsubStudentLoanBalanceVal = 0;
  let unsubPrincipalPaybackVal = 0;
  let unsubFedInterestVal = 0;
  let privateStudentLoanBalanceVal = 0;
  let privatePrincipalPaybackVal = 0;
  let privateFedInterestVal = 0;

  // Existing Home
  let existingHomehomeValue = 0;
  let existingHomemortgageBalanceValue = 0;
  let existingHomepaymentValue = 0;
  let existingHomeprincipalpaybackvalue = 0;
  let existingHomeInterestValue = 0;

  //Second Existing home
  let existingHomehomeValue2 = 0;
  let existingHomemortgageBalanceValue2 = 0;
  let existingHomepaymentValue2 = 0;
  let existingHomeprincipalpaybackvalue2 = 0;
  let existingHomeInterestValue2 = 0;

  // Existing Car
  let existingCarLoanBalValue = 0;
  let existingCarPricipalPaybackValue = 0;
  let existingCarInterestValue = 0;
  let existingCarPaymentValue = 0;

  // Credit Card Debt Graph
  let creditCardDebtBalanceVal = 0;
  let creditCardDebtPaymentVal = 0;
  let creditCardDebtPrincipalPaybackVal = 0;
  let creditCardDebtInterestVal = 0;

  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;
  higherEdOutputData = {};
  /* let studentLoanBalanceData = [];
  let paymentData = [];
  let principalPaybackData = [];
  let interestData = [];
  let yearsListData = []; */

  const fedMoreStudentLoanBalArr = [];
  const fedMorePrincipalPaybackArr = [];
  const fedMoreInterestArr = [];
  const fedMorePaymentArr = [];
  const fedMoreYearsList = [];
  const fedMoreUnStudentLoanBalArr = [];
  const fedMoreUnPrincipalPaybackArr = [];
  const fedMoreUnInterestArr = [];
  const fedMoreUnPaymentArr = [];
  const fedMoreUnYearsList = [];
  const privateMoreStudentLoanBalArr = [];
  const privateMorePrincipalPaybackArr = [];
  const privateMoreInterestArr = [];
  const privateMorePaymentArr = [];
  const privateMoreYearsList = [];
  const creditCardDebt = {};
  const creditCardBalance = [];
  const creditCardPayment = [];
  const creditCardPrincipalPayback = [];
  const creditCardInterest = [];
  const creditCardMonths = [];

  // Existing Home
  const existingHomeYearsList = [];
  const existingHomeValue = [];
  const existingHomeHomeMortgageBalance = [];
  const existingHomePayment = [];
  const existingHomePrincipalPayback = [];
  const existingHomeInterest = [];

  const existingHomeYearsList2 = [];
  const existingHomeValue2	  = [];
  const existingHomeHomeMortgageBalance2 = [];
  const existingHomePayment2 = [];
  const existingHomePrincipalPayback2 = [];
  const existingHomeInterest2 = [];
  
  // Existing Car
  const existingCarYearsList = [];
  const existingCarLoanBal = [];
  const existingCarPricipalPayback = [];
  const existingCarInterest = [];
  const existingCarPayment = [];
  let creditCardNullVal = 0;
  // let existingCarNullVal = 0;

  for ( year; year <= yearLimit; year += 1 ) {
    higherEdOutputData[year] = {};
    higherEdOutputData[year].Year1 = {};
    higherEdOutputData[year].Year1.Sources = {};

    // Federal Subsidized Values
    higherEdOutputData[year]['Federal Subsidized'] = {};

    // Student Loan Balance Value
    if ( fedSubExistingLoanData !== undefined && fedSubExistingLoanData !== '' && fedSubExistingLoanData['Summation Data'] !== undefined && fedSubExistingLoanData['Summation Data'] !== '' && fedSubExistingLoanData['Summation Data'][year] !== undefined && fedSubExistingLoanData['Summation Data'][year].EndingBalance !== undefined ) {
      studentLoanBalanceVal = fedSubExistingLoanData['Summation Data'][year].EndingBalance;
    } else {
      studentLoanBalanceVal = 0;
    }
    higherEdOutputData[year]['Federal Subsidized']['Student Loan Balance'] = studentLoanBalanceVal;
    // Principal Payback

    if ( fedSubExistingLoanData !== undefined && fedSubExistingLoanData !== '' && fedSubExistingLoanData['Summation Data'] !== undefined && fedSubExistingLoanData['Summation Data'] !== '' && fedSubExistingLoanData['Summation Data'][year] !== undefined && fedSubExistingLoanData['Summation Data'][year].LaggingPrincipal !== undefined ) {
      principalPaybackVal = fedSubExistingLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      principalPaybackVal = 0;
    }
    higherEdOutputData[year]['Federal Subsidized']['Principal Payback'] = principalPaybackVal;
    // Interest Value
    if ( fedSubExistingLoanData !== undefined && fedSubExistingLoanData !== '' && fedSubExistingLoanData['Summation Data'] !== undefined && fedSubExistingLoanData['Summation Data'] !== '' && fedSubExistingLoanData['Summation Data'][year] !== undefined && fedSubExistingLoanData['Summation Data'][year].LaggingInterest !== undefined ) {
      fedInterestVal = fedSubExistingLoanData['Summation Data'][year].LaggingInterest;
    } else {
      fedInterestVal = 0;
    }
    higherEdOutputData[year]['Federal Subsidized'].Interest = fedInterestVal;
    // Payment Value
    higherEdOutputData[year]['Federal Subsidized'].Payment = principalPaybackVal + fedInterestVal;

    if ( higherEdOutputData[year]['Federal Subsidized']['Student Loan Balance'] > 0 || higherEdOutputData[year]['Federal Subsidized']['Principal Payback'] > 0 || higherEdOutputData[year]['Federal Subsidized'].Interest > 0 || higherEdOutputData[year]['Federal Subsidized'].Payment ) {
      fedMoreYearsList.push( year );
      fedMoreStudentLoanBalArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized']['Student Loan Balance'] ) );
      fedMorePrincipalPaybackArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized']['Principal Payback'] ) );
      fedMoreInterestArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized'].Interest ) );
      fedMorePaymentArr.push( Math.round( higherEdOutputData[year]['Federal Subsidized'].Payment ) );
    }
    // Federal Subsidized Values Completed

    // Federal Unsubsidized Values
    higherEdOutputData[year]['Federal Unsubsidized'] = {};
    if ( fedUnSubExistingLoanData !== undefined && fedUnSubExistingLoanData !== '' && fedUnSubExistingLoanData['Summation Data'] !== undefined && fedUnSubExistingLoanData['Summation Data'] !== '' && fedUnSubExistingLoanData['Summation Data'][year] !== undefined && fedUnSubExistingLoanData['Summation Data'][year].EndingBalance !== undefined ) {
      unsubStudentLoanBalanceVal = fedUnSubExistingLoanData['Summation Data'][year].EndingBalance;
    } else {
      unsubStudentLoanBalanceVal = 0;
    }
    higherEdOutputData[year]['Federal Unsubsidized']['Student Loan Balance'] = unsubStudentLoanBalanceVal;
    // Principal Payback
    if ( fedUnSubExistingLoanData !== undefined && fedUnSubExistingLoanData !== '' && fedUnSubExistingLoanData['Summation Data'] !== undefined && fedUnSubExistingLoanData['Summation Data'] !== '' && fedUnSubExistingLoanData['Summation Data'][year] !== undefined && fedUnSubExistingLoanData['Summation Data'][year].LaggingPrincipal !== undefined ) {
      unsubPrincipalPaybackVal = fedUnSubExistingLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      unsubPrincipalPaybackVal = 0;
    }
    higherEdOutputData[year]['Federal Unsubsidized']['Principal Payback'] = unsubPrincipalPaybackVal;
    // Interest Value
    if ( fedUnSubExistingLoanData !== undefined && fedUnSubExistingLoanData !== '' && fedUnSubExistingLoanData['Summation Data'] !== undefined && fedUnSubExistingLoanData['Summation Data'] !== '' && fedUnSubExistingLoanData['Summation Data'][year] !== undefined && fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest !== undefined ) {
      unsubFedInterestVal = fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest;
    } else {
      unsubFedInterestVal = 0;
    }
    higherEdOutputData[year]['Federal Unsubsidized'].Interest = unsubFedInterestVal;
    // Payment Value
    higherEdOutputData[year]['Federal Unsubsidized'].Payment = unsubPrincipalPaybackVal + unsubFedInterestVal;

    if ( higherEdOutputData[year]['Federal Unsubsidized']['Student Loan Balance'] > 0 || higherEdOutputData[year]['Federal Unsubsidized']['Principal Payback'] || higherEdOutputData[year]['Federal Unsubsidized'].Interest > 0 || higherEdOutputData[year]['Federal Unsubsidized'].Payment > 0 ) {
      fedMoreUnYearsList.push( year );
      fedMoreUnStudentLoanBalArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized']['Student Loan Balance'] ) );
      fedMoreUnPrincipalPaybackArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized']['Principal Payback'] ) );
      fedMoreUnInterestArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized'].Interest ) );
      fedMoreUnPaymentArr.push( Math.round( higherEdOutputData[year]['Federal Unsubsidized'].Payment ) );
    }

    // Private Values
    higherEdOutputData[year].Private = {};
    if ( privateExistingLoanData !== undefined && privateExistingLoanData !== '' && privateExistingLoanData['Summation Data'] !== undefined && privateExistingLoanData['Summation Data'] !== '' && privateExistingLoanData['Summation Data'][year] !== undefined && privateExistingLoanData['Summation Data'][year].EndingBalance !== undefined ) {
      privateStudentLoanBalanceVal = privateExistingLoanData['Summation Data'][year].EndingBalance;
    } else {
      privateStudentLoanBalanceVal = 0;
    }
    higherEdOutputData[year].Private['Student Loan Balance'] = privateStudentLoanBalanceVal;
    // Principal Payback
    if ( privateExistingLoanData !== undefined && privateExistingLoanData !== '' && privateExistingLoanData['Summation Data'] !== undefined && privateExistingLoanData['Summation Data'] !== '' && privateExistingLoanData['Summation Data'][year] !== undefined && privateExistingLoanData['Summation Data'][year].LaggingPrincipal !== undefined ) {
      privatePrincipalPaybackVal = privateExistingLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      privatePrincipalPaybackVal = 0;
    }
    higherEdOutputData[year].Private['Principal Payback'] = privatePrincipalPaybackVal;
    // Interest Value
    if ( privateExistingLoanData !== undefined && privateExistingLoanData !== '' && privateExistingLoanData['Summation Data'] !== undefined && privateExistingLoanData['Summation Data'] !== '' && privateExistingLoanData['Summation Data'][year] !== undefined && privateExistingLoanData['Summation Data'][year].LaggingInterest !== undefined ) {
      privateFedInterestVal = privateExistingLoanData['Summation Data'][year].LaggingInterest;
    } else {
      privateFedInterestVal = 0;
    }
    higherEdOutputData[year].Private.Interest = privateFedInterestVal;

    // Payment Value
    higherEdOutputData[year].Private.Payment = privatePrincipalPaybackVal + privateFedInterestVal;
    if ( higherEdOutputData[year].Private['Student Loan Balance'] > 0 || higherEdOutputData[year].Private['Principal Payback'] > 0 || higherEdOutputData[year].Private.Interest > 0 || higherEdOutputData[year].Private.Payment > 0 ) {
      privateMoreYearsList.push( year );
      privateMoreStudentLoanBalArr.push( Math.round( higherEdOutputData[year].Private['Student Loan Balance'] ) );
      privateMorePrincipalPaybackArr.push( Math.round( higherEdOutputData[year].Private['Principal Payback'] ) );
      privateMoreInterestArr.push( Math.round( higherEdOutputData[year].Private.Interest ) );
      privateMorePaymentArr.push( Math.round( higherEdOutputData[year].Private.Payment ) );
    }

    // Existing Home Data
    if ( existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Real Estate'] !== undefined && existingHomeData['Real Estate'][year] !== undefined && existingHomeData['Real Estate'][year]['Asset Value'] !== undefined ) {
      existingHomehomeValue = existingHomeData['Real Estate'][year]['Asset Value'];
    } else {
      existingHomehomeValue = 0;
    }
    // Mortgage Balance
    if ( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].EndingBalance !== undefined ) {
      existingHomemortgageBalanceValue = existingHomeMortgageData['Summation Data'][year].EndingBalance;
    } else {
      existingHomemortgageBalanceValue = 0;
    }
    // Payment Value
    if ( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].LaggingInterest !== undefined && existingHomeMortgageData['Summation Data'][year].LaggingPrincipal !== undefined ) {
      existingHomepaymentValue = existingHomeMortgageData['Summation Data'][year].LaggingInterest + existingHomeMortgageData['Summation Data'][year].LaggingPrincipal;
    } else {
      existingHomepaymentValue = 0;
    }
    // Principal Payback value
    if ( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].LaggingPrincipal !== undefined ) {
      existingHomeprincipalpaybackvalue = existingHomeMortgageData['Summation Data'][year].LaggingPrincipal;
    } else {
      existingHomeprincipalpaybackvalue = 0;
    }
    // Interest Value
    if ( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].LaggingInterest !== undefined ) {
      existingHomeInterestValue = existingHomeMortgageData['Summation Data'][year].LaggingInterest;
    } else {
      existingHomeInterestValue = 0;
    }

    // Push all these values into arrays
    if ( existingHomehomeValue > 0 || existingHomemortgageBalanceValue > 0 || existingHomepaymentValue > 0 || existingHomeprincipalpaybackvalue > 0 || existingHomeInterestValue > 0 ) {
      existingHomeYearsList.push( year );
      existingHomeValue.push( Math.round( existingHomehomeValue ) );
      existingHomeHomeMortgageBalance.push( Math.round( existingHomemortgageBalanceValue ) );
      existingHomePayment.push( Math.round( existingHomepaymentValue ) );
      existingHomePrincipalPayback.push( Math.round( existingHomeprincipalpaybackvalue ) );
      existingHomeInterest.push( Math.round( existingHomeInterestValue ) );
    }

				//Second Home Graph Data
				if( existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Real Estate'] !== undefined && existingHomeData['Real Estate'][year] !== undefined && existingHomeData['Real Estate'][year]['Asset Value2'] !== undefined){
					existingHomehomeValue2 = existingHomeData['Real Estate'][year]['Asset Value2'];
				} else{
					existingHomehomeValue2 = 0;	
				}
				//Mortgage Balance
				if( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year]['EndingBalance2'] !== undefined){
					existingHomemortgageBalanceValue2 = existingHomeMortgageData['Summation Data'][year]['EndingBalance2'];
				} else{
					existingHomemortgageBalanceValue2 = 0;	
				}
				//Payment Value
				if( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year]['LaggingInterest2'] !== undefined  && existingHomeMortgageData['Summation Data'][year]['LaggingPrincipal2'] !== undefined){
					existingHomepaymentValue2 = existingHomeMortgageData['Summation Data'][year]['LaggingInterest2'] + existingHomeMortgageData['Summation Data'][year]['LaggingPrincipal2'];
				} else{
					existingHomepaymentValue2 = 0;	
				}
				//Principal Payback value
				if( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year]['LaggingPrincipal2'] !== undefined){
					existingHomeprincipalpaybackvalue2 = existingHomeMortgageData['Summation Data'][year]['LaggingPrincipal2'];
				} else{
					existingHomeprincipalpaybackvalue2 = 0;	
				}
				//Interest Value
				if( existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year]['LaggingInterest2'] !== undefined){
					existingHomeInterestValue2 = existingHomeMortgageData['Summation Data'][year]['LaggingInterest2'];
				} else{
					existingHomeInterestValue2 = 0;	
				}
				//Push all these values into arrays
				if( existingHomehomeValue2 > 0 || existingHomemortgageBalanceValue2 > 0 || existingHomepaymentValue2 > 0 || existingHomeprincipalpaybackvalue2 > 0 || existingHomeInterestValue2 > 0 ){
					existingHomeYearsList2.push(year);
					existingHomeValue2.push(Math.round(existingHomehomeValue2));
					existingHomeHomeMortgageBalance2.push(Math.round(existingHomemortgageBalanceValue2));
					existingHomePayment2.push(Math.round(existingHomepaymentValue2));
					existingHomePrincipalPayback2.push(Math.round(existingHomeprincipalpaybackvalue2));
					existingHomeInterest2.push(Math.round(existingHomeInterestValue2));
					
				}
				
    // Existing car Graph Values
    if ( existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'][year] !== undefined && existingCarLoanData['Summation Data'][year].BeginningBalance !== undefined ) {
      existingCarLoanBalValue = existingCarLoanData['Summation Data'][year].BeginningBalance;
    } else {
      existingCarLoanBalValue = 0;
    }
    // Principal Payback
    if ( existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'][year] !== undefined && existingCarLoanData['Summation Data'][year].LaggingPrincipal !== undefined ) {
      existingCarPricipalPaybackValue = existingCarLoanData['Summation Data'][year].LaggingPrincipal;
    } else {
      existingCarPricipalPaybackValue = 0;
    }
    // Interest
    if ( existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'][year] !== undefined && existingCarLoanData['Summation Data'][year].LaggingInterest !== undefined ) {
      existingCarInterestValue = existingCarLoanData['Summation Data'][year].LaggingInterest;
    } else {
      existingCarInterestValue = 0;
    }
    // Interest
    existingCarPaymentValue = existingCarPricipalPaybackValue + existingCarInterestValue;

    if ( existingCarLoanBalValue <= 0 && existingCarPricipalPaybackValue <= 0 && existingCarInterestValue <= 0 && existingCarPaymentValue <= 0 ) {
      // existingCarNullVal++;
    }
    // Push all these values into arrays
    if ( existingCarLoanBalValue !== 0 || existingCarPricipalPaybackValue !== 0 || existingCarInterestValue !== 0 || existingCarPaymentValue !== 0 ) {
      existingCarYearsList.push( year );
      existingCarLoanBal.push( Math.round( existingCarLoanBalValue ) );
      existingCarPricipalPayback.push( Math.round( existingCarPricipalPaybackValue ) );
      existingCarInterest.push( Math.round( existingCarInterestValue ) );
      existingCarPayment.push( Math.round( existingCarPaymentValue ) );
    }

    // For Credit Card Debt
    creditCardDebt[year] = {};
    for ( let month = 1; month <= 12; month += 1 ) {
      creditCardDebtBalanceVal = 0;
      creditCardDebtPaymentVal = 0;
      creditCardDebtPrincipalPaybackVal = 0;
      creditCardDebtInterestVal = 0;
      creditCardDebt[year][month] = {};
      // Balance Value
      if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== ''
                                            && existingCreditCardDebtData['Credit Card Data'] !== undefined && existingCreditCardDebtData['Credit Card Data'] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year] !== undefined && existingCreditCardDebtData['Credit Card Data'][year] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month] !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month].Balance !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month].Balance !== '' ) {
        creditCardDebtBalanceVal = existingCreditCardDebtData['Credit Card Data'][year][month].Balance;
      }
      creditCardDebt[year][month].Balance = creditCardDebtBalanceVal;
      // Payment
      if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== ''
                                            && existingCreditCardDebtData['Credit Card Data'] !== undefined && existingCreditCardDebtData['Credit Card Data'] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year] !== undefined && existingCreditCardDebtData['Credit Card Data'][year] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month] !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month].Payment !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month].Payment !== '' && creditCardDebtBalanceVal > 0 ) {
        creditCardDebtPaymentVal = existingCreditCardDebtData['Credit Card Data'][year][month].Payment;
      }
      creditCardDebt[year][month].Payment = creditCardDebtPaymentVal;

      // Principal Payback
      if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== ''
                                            && existingCreditCardDebtData['Credit Card Data'] !== undefined && existingCreditCardDebtData['Credit Card Data'] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year] !== undefined && existingCreditCardDebtData['Credit Card Data'][year] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month] !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month].Principal !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month].Principal !== '' ) {
        creditCardDebtPrincipalPaybackVal = existingCreditCardDebtData['Credit Card Data'][year][month].Principal;
      }
      creditCardDebt[year][month]['Principal Payback'] = creditCardDebtPrincipalPaybackVal;

      // Interest
      if ( existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== ''
                                            && existingCreditCardDebtData['Credit Card Data'] !== undefined && existingCreditCardDebtData['Credit Card Data'] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year] !== undefined && existingCreditCardDebtData['Credit Card Data'][year] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month] !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month] !== ''
                                            && existingCreditCardDebtData['Credit Card Data'][year][month].Interest !== undefined && existingCreditCardDebtData['Credit Card Data'][year][month].Interest !== '' ) {
        creditCardDebtInterestVal = existingCreditCardDebtData['Credit Card Data'][year][month].Interest;
      }
      if ( creditCardDebtBalanceVal <= 0 && creditCardDebtPaymentVal <= 0 && creditCardDebtPrincipalPaybackVal <= 0 && creditCardDebtInterestVal <= 0 ) {
        creditCardNullVal += 1;
      }
      creditCardDebt[year][month].Interest = creditCardDebtInterestVal;
      if ( creditCardDebtBalanceVal > 0 || creditCardDebtPaymentVal > 0 || creditCardDebtPrincipalPaybackVal > 0 || creditCardDebtInterestVal > 0 || creditCardNullVal === 1 ) {
        creditCardBalance.push( Math.round( creditCardDebtBalanceVal ) );
        creditCardPayment.push( Math.round( creditCardDebtPaymentVal ) );
        creditCardPrincipalPayback.push( Math.round( creditCardDebtPrincipalPaybackVal ) );
        creditCardInterest.push( Math.round( creditCardDebtInterestVal ) );
        creditCardMonths.push( month );
      }
    }
  }
  // To return Output backend Complete Data
  // Commented by Sateesh
  function existingStudentLoanBackendData() {
    const graphvaluesData = {};
    graphvaluesData['Federal Subsidized Graph'] = {};
    graphvaluesData['Federal Subsidized Graph'].yearsList = fedMoreYearsList;
    graphvaluesData['Federal Subsidized Graph']['Student Loan Balance'] = fedMoreStudentLoanBalArr;
    graphvaluesData['Federal Subsidized Graph']['Principal Payback'] = fedMorePrincipalPaybackArr;
    graphvaluesData['Federal Subsidized Graph'].Interest = fedMoreInterestArr;
    graphvaluesData['Federal Subsidized Graph'].Payment = fedMorePaymentArr;

    graphvaluesData['Federal Unsubsidized Graph'] = {};
    graphvaluesData['Federal Unsubsidized Graph'].yearsList = fedMoreUnYearsList;
    graphvaluesData['Federal Unsubsidized Graph']['Student Loan Balance'] = fedMoreUnStudentLoanBalArr;
    graphvaluesData['Federal Unsubsidized Graph']['Principal Payback'] = fedMoreUnPrincipalPaybackArr;
    graphvaluesData['Federal Unsubsidized Graph'].Interest = fedMoreUnInterestArr;
    graphvaluesData['Federal Unsubsidized Graph'].Payment = fedMoreUnPaymentArr;
    graphvaluesData['Private Graph'] = {};
    graphvaluesData['Private Graph'].yearsList = privateMoreYearsList;
    graphvaluesData['Private Graph']['Student Loan Balance'] = privateMoreStudentLoanBalArr;
    graphvaluesData['Private Graph']['Principal Payback'] = privateMorePrincipalPaybackArr;
    graphvaluesData['Private Graph'].Interest = privateMoreInterestArr;
    graphvaluesData['Private Graph'].Payment = privateMorePaymentArr;
    // Existing Home
    graphvaluesData['Existing Home1'] = {};
    graphvaluesData['Existing Home1'].yearsList = existingHomeYearsList;
    graphvaluesData['Existing Home1']['Home Value'] = existingHomeValue;
    graphvaluesData['Existing Home1']['Mortgage Balance'] = existingHomeHomeMortgageBalance;
    graphvaluesData['Existing Home1'].Payment = existingHomePayment;
    graphvaluesData['Existing Home1']['Principal Payback'] = existingHomePrincipalPayback;
    graphvaluesData['Existing Home1'].Interest = existingHomeInterest;

    //Second Home
    graphvaluesData['Existing Home2'] = {};
    graphvaluesData['Existing Home2']['yearsList'] = existingHomeYearsList2;
    graphvaluesData['Existing Home2']['Home Value'] = existingHomeValue2;
    graphvaluesData['Existing Home2']['Mortgage Balance'] = existingHomeHomeMortgageBalance2;
    graphvaluesData['Existing Home2']['Payment'] = existingHomePayment2;
    graphvaluesData['Existing Home2']['Principal Payback'] = existingHomePrincipalPayback2;
    graphvaluesData['Existing Home2']['Interest'] = existingHomeInterest2;

    // Existing Car
    graphvaluesData['Existing Car'] = {};
    graphvaluesData['Existing Car'].yearsList = existingCarYearsList;
    graphvaluesData['Existing Car']['Car Loan Balance'] = existingCarLoanBal;
    graphvaluesData['Existing Car']['Principal Payback'] = existingCarPricipalPayback;
    graphvaluesData['Existing Car'].Interest = existingCarInterest;
    graphvaluesData['Existing Car'].Payment = existingCarPayment;
    graphvaluesData['Credit Card Debt'] = {};
    graphvaluesData['Credit Card Debt'].Balance = creditCardBalance;
    graphvaluesData['Credit Card Debt'].Payment = creditCardPayment;
    graphvaluesData['Credit Card Debt']['Principal Payback'] = creditCardPrincipalPayback;
    graphvaluesData['Credit Card Debt'].Interest = creditCardInterest;
    graphvaluesData['Credit Card Debt'].Months = creditCardMonths;
    return graphvaluesData;
  }
  // console.log( 'higherEdOutputData', higherEdOutputData );
  return existingStudentLoanBackendData();
}
export default existingAssetsAndLiabilitiesSheetData;
