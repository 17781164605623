import { PMT } from '../../utilities/commonFunctions';
// eslint-disable-next-line
let summationData = {};

function existingCarLoanSheet(module1Data, module12Data, module18Data) {
  let carLoan1Data = {};

  let identifier = 0;
  let identifier2 = 0;
  let identifier3 = 0;
  let identifier4 = 0;
  let identifier5 = 0;
  if (typeof module18Data.existing_car_current_loan_balance !== 'undefined' && module18Data.existing_car_current_loan_balance > 0) {
    identifier = 1;
  } else {
    identifier = 0;
  }

  // Loan Amount
  let loanAmount5 = 0;
  if ( typeof module18Data.personal_loan_current_personal_balance !== 'undefined'
    && module18Data.personal_loan_current_personal_balance !== '') {
    loanAmount5 = module18Data.personal_loan_current_personal_balance;
  } else {
    loanAmount5 = 0;
  }

  // Vehicle 1 Own Loan Amount
  let loanAmount = 0;
  if (typeof module18Data.existing_car_current_loan_balance !== undefined && module18Data.existing_car_current_loan_balance !== null &&
    module18Data.existing_car_current_loan_balance !== '') {
    loanAmount = module18Data.existing_car_current_loan_balance;
  } else {
    loanAmount = 0;
  }
  // Vehicle 2 Own Loan Amount
  let loanAmount2 = 0;
  if (typeof module18Data.existing_car_current_loan_balance_2 !== undefined && module18Data.existing_car_current_loan_balance_2 !== null &&
    module18Data.existing_car_current_loan_balance_2 !== '') {
    loanAmount2 = module18Data.existing_car_current_loan_balance_2;
  } else {
    loanAmount2 = 0;
  }
  // Vehicle 3 Own Loan Amount
  let loanAmount3 = 0;
  if (typeof module18Data.existing_car_current_loan_balance_3 !== undefined && module18Data.existing_car_current_loan_balance_3 !== null &&
    module18Data.existing_car_current_loan_balance_3 !== '') {
    loanAmount3 = module18Data.existing_car_current_loan_balance_3;
  } else {
    loanAmount3 = 0;
  }
  // Vehicle 4 Own Loan Amount
  let loanAmount4 = 0;
  if (typeof module18Data.existing_car_current_loan_balance_4 !== undefined && module18Data.existing_car_current_loan_balance_4 !== null &&
    module18Data.existing_car_current_loan_balance_4 !== '') {
    loanAmount4 = module18Data.existing_car_current_loan_balance_4;
  } else {
    loanAmount4 = 0;
  }

  // Terms in Years
  let yearsArray = [];
  let termsOfYear5 = 0;
  if (typeof module18Data.personal_loan_months_remaining_on_loan !== 'undefined' && module18Data.personal_loan_months_remaining_on_loan !== '') {
    termsOfYear5 = parseInt(module18Data.personal_loan_months_remaining_on_loan, 10) / 12;
  } else {
    termsOfYear5 = 0;
  }
  yearsArray.push(termsOfYear5);

  let termsOfYear = 0;
  if (typeof module18Data.existing_car_months_to_maturity !== 'undefined' && module18Data.existing_car_months_to_maturity !== '') {
    termsOfYear = parseInt(module18Data.existing_car_months_to_maturity) / 12;
  } else {
    termsOfYear = 0;
  }
  yearsArray.push(termsOfYear);
  // Vehicle 2 Terms in Years
  let termsOfYear2 = 0;
  if (typeof module18Data.existing_car_months_to_maturity_2 !== 'undefined' && module18Data.existing_car_months_to_maturity_2 !== '') {
    termsOfYear2 = parseInt(module18Data.existing_car_months_to_maturity_2) / 12;
  } else {
    termsOfYear2 = 0;
  }
  yearsArray.push(termsOfYear2);
  // Vehicle 3 Terms in Years
  let termsOfYear3 = 0;
  if (typeof module18Data.existing_car_months_to_maturity_3 !== 'undefined' && module18Data.existing_car_months_to_maturity_3 !== '') {
    termsOfYear3 = parseInt(module18Data.existing_car_months_to_maturity_3) / 12;
  } else {
    termsOfYear3 = 0;
  }
  yearsArray.push(termsOfYear3);
  // Vehicle 4 Terms in Years
  let termsOfYear4 = 0;
  if (typeof module18Data.existing_car_months_to_maturity_4 !== 'undefined' && module18Data.existing_car_months_to_maturity_4 !== '') {
    termsOfYear4 = parseInt(module18Data.existing_car_months_to_maturity_4) / 12;
  } else {
    termsOfYear4 = 0;
  }
  yearsArray.push(termsOfYear4);

  // Annual Interest Rate
  let annualInterestRate5 = 0;
  if (typeof module18Data.personal_loans_interest_rate !== 'undefined' && module18Data.personal_loans_interest_rate !== '') {
    annualInterestRate5 = module18Data.personal_loans_interest_rate;
  } else {
    annualInterestRate5 = 0;
  }

  // Vehicle 1 Annual Interest Rate
  let annualInterestRate = 0;
  if (typeof module18Data.existing_car_interest_rate !== 'undefined' && module18Data.existing_car_interest_rate !== '') {
    annualInterestRate = module18Data.existing_car_interest_rate;
  } else {
    annualInterestRate = 0;
  }
  // Vehicle 2 Annual Interest Rate
  let annualInterestRate2 = 0;
  if (typeof module18Data.existing_car_interest_rate_2 !== 'undefined' && module18Data.existing_car_interest_rate_2 !== '') {
    annualInterestRate2 = module18Data.existing_car_interest_rate_2;
  } else {
    annualInterestRate2 = 0;
  }
  // Vehicle 3 Annual Interest Rate
  let annualInterestRate3 = 0;
  if (typeof module18Data.existing_car_interest_rate_3 !== 'undefined' && module18Data.existing_car_interest_rate_3 !== '') {
    annualInterestRate3 = module18Data.existing_car_interest_rate_3;
  } else {
    annualInterestRate3 = 0;
  }
  // Vehicle 4 Annual Interest Rate
  let annualInterestRate4 = 0;
  if (typeof module18Data.existing_car_interest_rate_4 !== 'undefined' && module18Data.existing_car_interest_rate_4 !== '') {
    annualInterestRate4 = module18Data.existing_car_interest_rate_4;
  } else {
    annualInterestRate4 = 0;
  }


  // Original Payment
  let originalPayment5 = 0;
  if (loanAmount5 > 0
    && termsOfYear5 > 0) {
    originalPayment5 = PMT((parseFloat(annualInterestRate5) / 100) / 12, termsOfYear5 * 12, -(loanAmount5), 0, 0);
  }

  // Vehicle 1 Original Payment
  let originalPayment = 0;
  if (loanAmount > 0 &&
    termsOfYear > 0) {
    originalPayment = PMT((parseFloat(annualInterestRate) / 100) / 12, termsOfYear * 12, -(loanAmount), 0, 0);
  }
  // Vehicle 2 Original Payment
  let originalPayment2 = 0;
  if (loanAmount2 > 0 &&
    termsOfYear2 > 0) {
    originalPayment2 = PMT((parseFloat(annualInterestRate2) / 100) / 12, termsOfYear2 * 12, -(loanAmount2), 0, 0);
  }
  // Vehicle 3 Original Payment
  let originalPayment3 = 0;
  if (loanAmount3 > 0 &&
    termsOfYear3 > 0) {
    originalPayment3 = PMT((parseFloat(annualInterestRate3) / 100) / 12, termsOfYear3 * 12, -(loanAmount3), 0, 0);
  }
  // Vehicle 4 Original Payment
  let originalPayment4 = 0;
  if (loanAmount4 > 0 &&
    termsOfYear4 > 0) {
    originalPayment4 = PMT((parseFloat(annualInterestRate4) / 100) / 12, termsOfYear4 * 12, -(loanAmount4), 0, 0);
  }

  // Total Interest Paid
  let totalInterestPaid = 0;
  let totalInterestPaid2 = 0;
  let totalInterestPaid3 = 0;
  let totalInterestPaid4 = 0;
  let totalInterestPaid5 = 0;

  // Total Paid
  let totalPaid = 0;
  let totalPaid2 = 0;
  let totalPaid3 = 0;
  let totalPaid4 = 0;
  let totalPaid5 = 0;

  // Tabular Data            
  let startYear = 0;
  // let startYear2 = 0;
  // let startYear3 = 0;
  // let startYear4 = 0;
  // let startYear5 = 0;

  // Tabular Data
  startYear = parseInt(module1Data.start_year, 10);
  if (startYear > 0) {
    let year = parseInt(startYear, 10);
    let endYear = Math.max(5, 10);
    const yearLimit = year + parseFloat(endYear);

    // Existing Student Loan Data
    carLoan1Data = {};
    carLoan1Data.Identifier = identifier;
    carLoan1Data['Loan Amount'] = loanAmount;
    carLoan1Data['Term in Years'] = termsOfYear;
    carLoan1Data['Annual Interest Rate'] = annualInterestRate;
    carLoan1Data['Original Payment'] = originalPayment;

    carLoan1Data.Identifier2 = identifier2;
    carLoan1Data['Loan Amount2'] = loanAmount2;
    carLoan1Data['Term in Years2'] = termsOfYear2;
    carLoan1Data['Annual Interest Rate2'] = annualInterestRate2;
    carLoan1Data['Original Payment2'] = originalPayment2;

    carLoan1Data.Identifier3 = identifier3;
    carLoan1Data['Loan Amount3'] = loanAmount3;
    carLoan1Data['Term in Years3'] = termsOfYear3;
    carLoan1Data['Annual Interest Rate3'] = annualInterestRate3;
    carLoan1Data['Original Payment3'] = originalPayment3;

    carLoan1Data.Identifier4 = identifier4;
    carLoan1Data['Loan Amount4'] = loanAmount4;
    carLoan1Data['Term in Years4'] = termsOfYear4;
    carLoan1Data['Annual Interest Rate4'] = annualInterestRate4;
    carLoan1Data['Original Payment4'] = originalPayment4;

    carLoan1Data.Identifier5 = identifier5;
    carLoan1Data['Loan Amount5'] = loanAmount5;
    carLoan1Data['Term in Years5'] = termsOfYear5;
    carLoan1Data['Annual Interest Rate5'] = annualInterestRate5;
    carLoan1Data['Original Payment5'] = originalPayment5;


    let Balance = loanAmount;
    let Balance2 = loanAmount2;
    let Balance3 = loanAmount3;
    let Balance4 = loanAmount4;
    let Balance5 = loanAmount5;

    const Payment = originalPayment;
    const Payment2 = originalPayment2;
    const Payment3 = originalPayment3;
    const Payment4 = originalPayment4;
    const Payment5 = originalPayment5;

    let Interest = 0;
    let Interest2 = 0;
    let Interest3 = 0;
    let Interest4 = 0;
    let Interest5 = 0;

    if (annualInterestRate > 0) {
      Interest = Balance * ((annualInterestRate / 100) / 12);
    }
    if (annualInterestRate2 > 0) {
      Interest2 = Balance2 * ((annualInterestRate2 / 100) / 12);
    }
    if (annualInterestRate3 > 0) {
      Interest3 = Balance3 * ((annualInterestRate3 / 100) / 12);
    }
    if (annualInterestRate4 > 0) {
      Interest4 = Balance4 * ((annualInterestRate4 / 100) / 12);
    }
    if (annualInterestRate5 > 0) {
      Interest5 = Balance5 * ((annualInterestRate5 / 100) / 12);
    }

    let Principal = Payment - Interest;
    let Principal2 = Payment2 - Interest2;
    let Principal3 = Payment3 - Interest3;
    let Principal4 = Payment4 - Interest4;
    let Principal5 = Payment5 - Interest5;

    let Equity = Principal;
    let Equity2 = Principal2;
    let Equity3 = Principal3;
    let Equity4 = Principal4;
    let Equity5 = Principal5;

    let TotalInterest = Interest;
    let TotalInterest2 = Interest2;
    let TotalInterest3 = Interest3;
    let TotalInterest4 = Interest4;
    let TotalInterest5 = Interest5;

    const TotalPayment = Equity + TotalInterest;
    const TotalPayment2 = Equity2 + TotalInterest2;
    const TotalPayment3 = Equity3 + TotalInterest3;
    const TotalPayment4 = Equity4 + TotalInterest4;
    const TotalPayment5 = Equity5 + TotalInterest5;

    const totalInterestPaidArray = [];
    const totalInterestPaidArray2 = [];
    const totalInterestPaidArray3 = [];
    const totalInterestPaidArray4 = [];
    const totalInterestPaidArray5 = [];

    const totalPaidArray = [];
    const totalPaidArray2 = [];
    const totalPaidArray3 = [];
    const totalPaidArray4 = [];
    const totalPaidArray5 = [];

    // For Loop from graduation year to +10 years
    for (year; year <= yearLimit; year += 1) {
      carLoan1Data[year] = {};

      carLoan1Data[year].LaggingInterest = 0;
      carLoan1Data[year].LaggingInterest2 = 0;
      carLoan1Data[year].LaggingInterest3 = 0;
      carLoan1Data[year].LaggingInterest4 = 0;
      carLoan1Data[year].LaggingInterest5 = 0;

      carLoan1Data[year].LaggingPrincipal = 0;
      carLoan1Data[year].LaggingPrincipal2 = 0;
      carLoan1Data[year].LaggingPrincipal3 = 0;
      carLoan1Data[year].LaggingPrincipal4 = 0;
      carLoan1Data[year].LaggingPrincipal5 = 0;

      carLoan1Data[year]['Months Total Balance'] = 0;
      carLoan1Data[year]['Months Total Balance2'] = 0;
      carLoan1Data[year]['Months Total Balance3'] = 0;
      carLoan1Data[year]['Months Total Balance4'] = 0;
      carLoan1Data[year]['Months Total Balance5'] = 0;

      // For Loop for 12 months
      for (let month = 1; month <= 12; month += 1) {
        carLoan1Data[year][month] = {};
        if (year === startYear && month === 1) {
          carLoan1Data[year][month].YearIdentifier = year;
          carLoan1Data[year][month].Balance = Balance;
          carLoan1Data[year][month].Payment = Payment;
          carLoan1Data[year][month].Interest = Interest;
          carLoan1Data[year][month].Principal = Principal;
          carLoan1Data[year][month].Equity = Equity;
          carLoan1Data[year][month].TotalInterest = TotalInterest;
          carLoan1Data[year][month].TotalPayment = TotalPayment;

          carLoan1Data[year][month].YearIdentifier2 = year;
          carLoan1Data[year][month].Balance2 = Balance2;
          carLoan1Data[year][month].Payment2 = Payment2;
          carLoan1Data[year][month].Interest2 = Interest2;
          carLoan1Data[year][month].Principal2 = Principal2;
          carLoan1Data[year][month].Equity2 = Equity2;
          carLoan1Data[year][month].TotalInterest2 = TotalInterest2;
          carLoan1Data[year][month].TotalPayment2 = TotalPayment2;

          carLoan1Data[year][month].YearIdentifier3 = year;
          carLoan1Data[year][month].Balance3 = Balance3;
          carLoan1Data[year][month].Payment3 = Payment3;
          carLoan1Data[year][month].Interest3 = Interest3;
          carLoan1Data[year][month].Principal3 = Principal3;
          carLoan1Data[year][month].Equity3 = Equity3;
          carLoan1Data[year][month].TotalInterest3 = TotalInterest3;
          carLoan1Data[year][month].TotalPayment3 = TotalPayment3;

          carLoan1Data[year][month].YearIdentifier4 = year;
          carLoan1Data[year][month].Balance4 = Balance4;
          carLoan1Data[year][month].Payment4 = Payment4;
          carLoan1Data[year][month].Interest4 = Interest4;
          carLoan1Data[year][month].Principal4 = Principal4;
          carLoan1Data[year][month].Equity4 = Equity4;
          carLoan1Data[year][month].TotalInterest4 = TotalInterest4;
          carLoan1Data[year][month].TotalPayment4 = TotalPayment4;

          carLoan1Data[year][month].YearIdentifier5 = year;
          carLoan1Data[year][month].Balance5 = Balance5;
          carLoan1Data[year][month].Payment5 = Payment5;
          carLoan1Data[year][month].Interest5 = Interest5;
          carLoan1Data[year][month].Principal5 = Principal5;
          carLoan1Data[year][month].Equity5 = Equity5;
          carLoan1Data[year][month].TotalInterest5 = TotalInterest5;
          carLoan1Data[year][month].TotalPayment5 = TotalPayment5;


        } else {
          // Year Identifier
          carLoan1Data[year][month].YearIdentifier = year;
          carLoan1Data[year][month].YearIdentifier2 = year;
          carLoan1Data[year][month].YearIdentifier3 = year;
          carLoan1Data[year][month].YearIdentifier4 = year;
          carLoan1Data[year][month].YearIdentifier5 = year;

          // Balance
          let tempBalance;
          if (Balance === 0) {
            tempBalance = 0;
          } else {
            if ((Number(Balance).toFixed(6) - Number(Principal).toFixed(6)) > 1) {
              tempBalance = (Balance - Principal);
            } else {
              tempBalance = 0;
            }
          }
          carLoan1Data[year][month].Balance = tempBalance;

          // Balance
          let tempBalance2;
          if (Balance2 === 0) {
            tempBalance2 = 0;
          } else {
            if ((Number(Balance2).toFixed(6) - Number(Principal2).toFixed(6)) > 1) {
              tempBalance2 = (Balance2 - Principal2);
            } else {
              tempBalance2 = 0;
            }
          }
          carLoan1Data[year][month].Balance2 = tempBalance2;

          // Balance
          let tempBalance3;
          if (Balance3 === 0) {
            tempBalance3 = 0;
          } else {
            if ((Number(Balance3).toFixed(6) - Number(Principal3).toFixed(6)) > 1) {
              tempBalance3 = (Balance3 - Principal3);
            } else {
              tempBalance3 = 0;
            }
          }
          carLoan1Data[year][month].Balance3 = tempBalance3;

          // Balance
          let tempBalance4;
          if (Balance4 === 0) {
            tempBalance4 = 0;
          } else {
            if ((Number(Balance4).toFixed(6) - Number(Principal4).toFixed(6)) > 1) {
              tempBalance4 = (Balance4 - Principal4);
            } else {
              tempBalance4 = 0;
            }
          }
          carLoan1Data[year][month].Balance4 = tempBalance4;

          // Balance
          let tempBalance5;
          if (Balance5 === 0) {
            tempBalance5 = 0;
          } else {
            if ((Number(Balance5).toFixed(6) - Number(Principal5).toFixed(6)) > 1) {
              tempBalance5 = (Balance5 - Principal5);
            } else {
              tempBalance5 = 0;
            }
          }
          carLoan1Data[year][month].Balance5 = tempBalance5;



          // Payment
          carLoan1Data[year][month].Payment = Payment;
          carLoan1Data[year][month].Payment2 = Payment2;
          carLoan1Data[year][month].Payment3 = Payment3;
          carLoan1Data[year][month].Payment4 = Payment4;
          carLoan1Data[year][month].Payment5 = Payment5;

          // Interest
          let tempInterest;
          if (Balance === 0) {
            tempInterest = 0;
          } else {
            if ((Number(Balance).toFixed(6) - Number(Principal).toFixed(6)) > 1) {
              tempInterest = tempBalance * ((annualInterestRate / 100) / 12);
            } else {
              tempInterest = 0;
            }
          }
          carLoan1Data[year][month].Interest = tempInterest;

          // Interest
          let tempInterest2;
          if (Balance2 === 0) {
            tempInterest2 = 0;
          } else {
            if ((Number(Balance2).toFixed(6) - Number(Principal2).toFixed(6)) > 1) {
              tempInterest2 = tempBalance2 * ((annualInterestRate2 / 100) / 12);
            } else {
              tempInterest2 = 0;
            }
          }
          carLoan1Data[year][month].Interest2 = tempInterest2;

          // Interest
          let tempInterest3;
          if (Balance3 === 0) {
            tempInterest3 = 0;
          } else {
            if ((Number(Balance3).toFixed(6) - Number(Principal3).toFixed(6)) > 1) {
              tempInterest3 = tempBalance3 * ((annualInterestRate3 / 100) / 12);
            } else {
              tempInterest3 = 0;
            }
          }
          carLoan1Data[year][month].Interest3 = tempInterest3;

          // Interest
          let tempInterest4;
          if (Balance4 === 0) {
            tempInterest4 = 0;
          } else {
            if ((Number(Balance4).toFixed(6) - Number(Principal4).toFixed(6)) > 1) {
              tempInterest4 = tempBalance4 * ((annualInterestRate4 / 100) / 12);
            } else {
              tempInterest4 = 0;
            }
          }
          carLoan1Data[year][month].Interest4 = tempInterest4;

          // Interest
          let tempInterest5;
          if (Balance5 === 0) {
            tempInterest5 = 0;
          } else {
            if ((Number(Balance5).toFixed(6) - Number(Principal5).toFixed(6)) > 1) {
              tempInterest5 = tempBalance5 * ((annualInterestRate5 / 100) / 12);
            } else {
              tempInterest5 = 0;
            }
          }
          carLoan1Data[year][month].Interest5 = tempInterest5;


          // Principal
          let tempPrincipal;
          if (Balance === 0) {
            tempPrincipal = 0;
          } else {
            if ((Number(Balance).toFixed(6) - Number(Principal).toFixed(6)) > 1) {
              tempPrincipal = Payment - tempInterest;
            } else {
              tempPrincipal = 0;
            }
          }
          carLoan1Data[year][month].Principal = tempPrincipal;

          // Principal
          let tempPrincipal2;
          if (Balance2 === 0) {
            tempPrincipal2 = 0;
          } else {
            if ((Number(Balance2).toFixed(6) - Number(Principal2).toFixed(6)) > 1) {
              tempPrincipal2 = Payment2 - tempInterest2;
            } else {
              tempPrincipal2 = 0;
            }
          }

          carLoan1Data[year][month].Principal2 = tempPrincipal2;

          // Principal
          let tempPrincipal3;
          if (Balance3 === 0) {
            tempPrincipal3 = 0;
          } else {
            if ((Number(Balance3).toFixed(6) - Number(Principal3).toFixed(6)) > 1) {
              tempPrincipal3 = Payment3 - tempInterest3;
            } else {
              tempPrincipal3 = 0;
            }
          }
          carLoan1Data[year][month].Principal3 = tempPrincipal3;

          // Principal
          let tempPrincipal4;
          if (Balance4 === 0) {
            tempPrincipal4 = 0;
          } else {
            if ((Number(Balance4).toFixed(6) - Number(Principal4).toFixed(6)) > 1) {
              tempPrincipal4 = Payment4 - tempInterest4;
            } else {
              tempPrincipal4 = 0;
            }
          }
          carLoan1Data[year][month].Principal4 = tempPrincipal4;

          // Principal
          let tempPrincipal5;
          if (Balance5 === 0) {
            tempPrincipal5 = 0;
          } else {
            if ((Number(Balance5).toFixed(6) - Number(Principal5).toFixed(6)) > 1) {
              tempPrincipal5 = Payment5 - tempInterest5;
            } else {
              tempPrincipal5 = 0;
            }
          }
          carLoan1Data[year][month].Principal5 = tempPrincipal5;



          // Equity
          let tempEquity;
          if (Balance === 0) {
            tempEquity = 0;
          } else {
            if ((Number(Balance).toFixed(6) - Number(Principal).toFixed(6)) > 1) {
              tempEquity = Equity + tempPrincipal;
            } else {
              tempEquity = 0;
            }
          }
          carLoan1Data[year][month].Equity = tempEquity;

          // Equity
          let tempEquity2;
          if (Balance2 === 0) {
            tempEquity2 = 0;
          } else {
            if ((Number(Balance2).toFixed(6) - Number(Principal2).toFixed(6)) > 1) {
              tempEquity2 = Equity2 + tempPrincipal2;
            } else {
              tempEquity2 = 0;
            }
          }
          carLoan1Data[year][month].Equity2 = tempEquity2;

          // Equity
          let tempEquity3;
          if (Balance3 === 0) {
            tempEquity3 = 0;
          } else {
            if ((Number(Balance3).toFixed(6) - Number(Principal3).toFixed(6)) > 1) {
              tempEquity3 = Equity3 + tempPrincipal3;
            } else {
              tempEquity3 = 0;
            }
          }
          carLoan1Data[year][month].Equity3 = tempEquity3;

          // Equity
          let tempEquity4;
          if (Balance4 === 0) {
            tempEquity4 = 0;
          } else {
            if ((Number(Balance4).toFixed(6) - Number(Principal4).toFixed(6)) > 1) {
              tempEquity4 = Equity4 + tempPrincipal4;
            } else {
              tempEquity4 = 0;
            }
          }
          carLoan1Data[year][month].Equity4 = tempEquity4;

          // Equity
          let tempEquity5;
          if (Balance5 === 0) {
            tempEquity5 = 0;
          } else {
            if ((Number(Balance5).toFixed(6) - Number(Principal5).toFixed(6)) > 1) {
              tempEquity5 = Equity5 + tempPrincipal5;
            } else {
              tempEquity5 = 0;
            }
          }
          carLoan1Data[year][month].Equity5 = tempEquity5;



          // Total Interest
          let tempTotalInterest;
          if (Balance === 0) {
            tempTotalInterest = 0;
          } else {
            if ((Number(Balance).toFixed(6) - Number(Principal).toFixed(6)) > 1) {
              tempTotalInterest = TotalInterest + tempInterest;
            } else {
              tempTotalInterest = 0;
            }
          }
          carLoan1Data[year][month].TotalInterest = tempTotalInterest;

          // Total Interest
          let tempTotalInterest2;
          if (Balance2 === 0) {
            tempTotalInterest2 = 0;
          } else {
            if ((Number(Balance2).toFixed(6) - Number(Principal2).toFixed(6)) > 1) {
              tempTotalInterest2 = TotalInterest2 + tempInterest2;
            } else {
              tempTotalInterest2 = 0;
            }
          }
          carLoan1Data[year][month].TotalInterest2 = tempTotalInterest2;

          // Total Interest
          let tempTotalInterest3;
          if (Balance3 === 0) {
            tempTotalInterest3 = 0;
          } else {
            if ((Number(Balance3).toFixed(6) - Number(Principal3).toFixed(6)) > 1) {
              tempTotalInterest3 = TotalInterest3 + tempInterest3;
            } else {
              tempTotalInterest3 = 0;
            }
          }
          carLoan1Data[year][month].TotalInterest3 = tempTotalInterest3;

          // Total Interest
          let tempTotalInterest4;
          if (Balance4 === 0) {
            tempTotalInterest4 = 0;
          } else {
            if ((Number(Balance4).toFixed(6) - Number(Principal4).toFixed(6)) > 1) {
              tempTotalInterest4 = TotalInterest4 + tempInterest4;
            } else {
              tempTotalInterest4 = 0;
            }
          }
          carLoan1Data[year][month].TotalInterest4 = tempTotalInterest4;

          // Total Interest
          let tempTotalInterest5;
          if (Balance5 === 0) {
            tempTotalInterest5 = 0;
          } else {
            if ((Number(Balance5).toFixed(6) - Number(Principal5).toFixed(6)) > 1) {
              tempTotalInterest5 = TotalInterest5 + tempInterest5;
            } else {
              tempTotalInterest5 = 0;
            }
          }
          carLoan1Data[year][month].TotalInterest5 = tempTotalInterest5;

          // Total Payments
          let tempTotalPayment;
          if (Balance === 0) {
            tempTotalPayment = 0;
          } else {
            if ((Number(Balance).toFixed(6) - Number(Principal).toFixed(6)) > 1) {
              tempTotalPayment = tempEquity + tempTotalInterest;
            } else {
              tempTotalPayment = 0;
            }
          }
          carLoan1Data[year][month].TotalPayment = tempTotalPayment;

          // Total Payments
          let tempTotalPayment2;
          if (Balance2 === 0) {
            tempTotalPayment2 = 0;
          } else {
            if ((Number(Balance2).toFixed(6) - Number(Principal2).toFixed(6)) > 1) {
              tempTotalPayment2 = tempEquity2 + tempTotalInterest2;
            } else {
              tempTotalPayment2 = 0;
            }
          }
          carLoan1Data[year][month].TotalPayment2 = tempTotalPayment2;

          // Total Payments
          let tempTotalPayment3;
          if (Balance3 === 0) {
            tempTotalPayment3 = 0;
          } else {
            if ((Number(Balance3).toFixed(6) - Number(Principal3).toFixed(6)) > 1) {
              tempTotalPayment3 = tempEquity3 + tempTotalInterest3;
            } else {
              tempTotalPayment3 = 0;
            }
          }
          carLoan1Data[year][month].TotalPayment3 = tempTotalPayment3;

          // Total Payments
          let tempTotalPayment4;
          if (Balance4 === 0) {
            tempTotalPayment4 = 0;
          } else {
            if ((Number(Balance4).toFixed(6) - Number(Principal4).toFixed(6)) > 1) {
              tempTotalPayment4 = tempEquity4 + tempTotalInterest4;
            } else {
              tempTotalPayment4 = 0;
            }
          }
          carLoan1Data[year][month].TotalPayment4 = tempTotalPayment4;

          // Total Payments
          let tempTotalPayment5;
          if (Balance5 === 0) {
            tempTotalPayment5 = 0;
          } else {
            if ((Number(Balance5).toFixed(6) - Number(Principal5).toFixed(6)) > 1) {
              tempTotalPayment5 = tempEquity5 + tempTotalInterest5;
            } else {
              tempTotalPayment5 = 0;
            }
          }
          carLoan1Data[year][month].TotalPayment5 = tempTotalPayment5;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;

          Balance2 = tempBalance2;
          Principal2 = tempPrincipal2;
          Equity2 = tempEquity2;
          TotalInterest2 = tempTotalInterest2;

          Balance3 = tempBalance3;
          Principal3 = tempPrincipal3;
          Equity3 = tempEquity3;
          TotalInterest3 = tempTotalInterest3;

          Balance4 = tempBalance4;
          Principal4 = tempPrincipal4;
          Equity4 = tempEquity4;
          TotalInterest4 = tempTotalInterest4;

          Balance5 = tempBalance5;
          Principal5 = tempPrincipal5;
          Equity5 = tempEquity5;
          TotalInterest5 = tempTotalInterest5;

        }

        // Total Interest Paid Array
        totalInterestPaidArray.push(carLoan1Data[year][month].TotalInterest);

        // Total Interest Paid Array
        totalInterestPaidArray2.push(carLoan1Data[year][month].TotalInterest2);

        // Total Interest Paid Array
        totalInterestPaidArray3.push(carLoan1Data[year][month].TotalInterest3);

        // Total Interest Paid Array
        totalInterestPaidArray4.push(carLoan1Data[year][month].TotalInterest4);

        // Total Interest Paid Array
        totalInterestPaidArray5.push(carLoan1Data[year][month].TotalInterest5);



        // Total Paid Array
        totalPaidArray.push(carLoan1Data[year][month].TotalPayment);

        // Total Paid Array
        totalPaidArray2.push(carLoan1Data[year][month].TotalPayment2);

        // Total Paid Array
        totalPaidArray3.push(carLoan1Data[year][month].TotalPayment3);

        // Total Paid Array
        totalPaidArray4.push(carLoan1Data[year][month].TotalPayment4);

        // Total Paid Array
        totalPaidArray5.push(carLoan1Data[year][month].TotalPayment5);



        carLoan1Data[year].LaggingInterest = parseFloat(carLoan1Data[year].LaggingInterest) + parseFloat(carLoan1Data[year][month].Interest);
        carLoan1Data[year].LaggingInterest2 = parseFloat(carLoan1Data[year].LaggingInterest2) + parseFloat(carLoan1Data[year][month].Interest2);
        carLoan1Data[year].LaggingInterest3 = parseFloat(carLoan1Data[year].LaggingInterest3) + parseFloat(carLoan1Data[year][month].Interest3);
        carLoan1Data[year].LaggingInterest4 = parseFloat(carLoan1Data[year].LaggingInterest4) + parseFloat(carLoan1Data[year][month].Interest4);
        carLoan1Data[year].LaggingInterest5 = parseFloat(carLoan1Data[year].LaggingInterest5) + parseFloat(carLoan1Data[year][month].Interest5);

        carLoan1Data[year].LaggingPrincipal = parseFloat(carLoan1Data[year].LaggingPrincipal) + parseFloat(carLoan1Data[year][month].Principal);
        carLoan1Data[year].LaggingPrincipal2 = parseFloat(carLoan1Data[year].LaggingPrincipal2) + parseFloat(carLoan1Data[year][month].Principal2);
        carLoan1Data[year].LaggingPrincipal3 = parseFloat(carLoan1Data[year].LaggingPrincipal3) + parseFloat(carLoan1Data[year][month].Principal3);
        carLoan1Data[year].LaggingPrincipal4 = parseFloat(carLoan1Data[year].LaggingPrincipal4) + parseFloat(carLoan1Data[year][month].Principal4);
        carLoan1Data[year].LaggingPrincipal5 = parseFloat(carLoan1Data[year].LaggingPrincipal5) + parseFloat(carLoan1Data[year][month].Principal5);

        carLoan1Data[year]['Months Total Balance'] = parseFloat(carLoan1Data[year]['Months Total Balance']) + parseFloat(carLoan1Data[year][month].Balance);
        carLoan1Data[year]['Months Total Balance2'] = parseFloat(carLoan1Data[year]['Months Total Balance2']) + parseFloat(carLoan1Data[year][month].Balance2);
        carLoan1Data[year]['Months Total Balance3'] = parseFloat(carLoan1Data[year]['Months Total Balance3']) + parseFloat(carLoan1Data[year][month].Balance3);
        carLoan1Data[year]['Months Total Balance4'] = parseFloat(carLoan1Data[year]['Months Total Balance4']) + parseFloat(carLoan1Data[year][month].Balance4);
        carLoan1Data[year]['Months Total Balance5'] = parseFloat(carLoan1Data[year]['Months Total Balance5']) + parseFloat(carLoan1Data[year][month].Balance5);
      }
    }

    // Total Interest Paid
    if (totalInterestPaidArray.length > 0) {
      totalInterestPaid = Math.max.apply(null, totalInterestPaidArray);
    }
    if (totalInterestPaidArray2.length > 0) {
      totalInterestPaid2 = Math.max.apply(null, totalInterestPaidArray2);
    }
    if (totalInterestPaidArray3.length > 0) {
      totalInterestPaid3 = Math.max.apply(null, totalInterestPaidArray3);
    }
    if (totalInterestPaidArray4.length > 0) {
      totalInterestPaid4 = Math.max.apply(null, totalInterestPaidArray4);
    }
    if (totalInterestPaidArray5.length > 0) {
      totalInterestPaid5 = Math.max.apply(null, totalInterestPaidArray5);
    }


    // Total Paid
    if (totalPaidArray.length > 0) {
      totalPaid = Math.max.apply(null, totalPaidArray);
    }
    if (totalPaidArray2.length > 0) {
      totalPaid2 = Math.max.apply(null, totalPaidArray2);
    }
    if (totalPaidArray3.length > 0) {
      totalPaid3 = Math.max.apply(null, totalPaidArray3);
    }
    if (totalPaidArray4.length > 0) {
      totalPaid4 = Math.max.apply(null, totalPaidArray4);
    }
    if (totalPaidArray5.length > 0) {
      totalPaid5 = Math.max.apply(null, totalPaidArray5);
    }
  }

  // Summation Data
  if (typeof module1Data.start_year !== 'undefined'
    && module1Data.start_year !== '' && parseInt(module1Data.start_year, 10) > 0) {
    let summationYear = parseInt(module1Data.start_year, 10);
    const summationYearLimit = summationYear + 30;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;

    let summationBeginningBalance2 = 0;
    let summationLaggingInterest2 = 0;
    let summationLaggingPrincipal2 = 0;
    let summationTotalInterest2 = 0;
    let summationTotalPrincipal2 = 0;
    let summationEndingBalance2 = 0;

    let summationBeginningBalance3 = 0;
    let summationLaggingInterest3 = 0;
    let summationLaggingPrincipal3 = 0;
    let summationTotalInterest3 = 0;
    let summationTotalPrincipal3 = 0;
    let summationEndingBalance3 = 0;

    let summationBeginningBalance4 = 0;
    let summationLaggingInterest4 = 0;
    let summationLaggingPrincipal4 = 0;
    let summationTotalInterest4 = 0;
    let summationTotalPrincipal4 = 0;
    let summationEndingBalance4 = 0;

    let summationBeginningBalance5 = 0;
    let summationLaggingInterest5 = 0;
    let summationLaggingPrincipal5 = 0;
    let summationTotalInterest5 = 0;
    let summationTotalPrincipal5 = 0;
    let summationEndingBalance5 = 0;

    // let LaggingVal1 = 0;
    let LaggingVal12 = 0;
    let LaggingVal13 = 0;
    let LaggingVal14 = 0;
    let LaggingVal15 = 0;



    // For Loop from Start year to +14 years
    
    for (summationYear; summationYear < summationYearLimit; summationYear += 1) {
      summationData[summationYear] = {};
      
      // Beginning Balance
      if (typeof module12Data.purchase_year !== 'undefined'
        && module12Data.purchase_year > 0
        && summationYear >= module12Data.purchase_year
        && typeof module12Data.tradein_existing_car !== 'undefined'
        && module12Data.tradein_existing_car === 'Yes') {
        summationBeginningBalance = 0;
      } else {
        if (typeof module12Data.second_purchase_year !== 'undefined' &&
          module12Data.second_purchase_year > 0 &&
          summationYear >= module12Data.second_purchase_year &&
          typeof module12Data.moretradein_existing_car !== 'undefined' &&
          module12Data.moretradein_existing_car === 'Yes') {
          summationBeginningBalance = 0;
        } else {
          if (loanAmount > 0) {
            if (typeof carLoan1Data !== 'undefined'
              && typeof carLoan1Data[summationYear] !== 'undefined'
              && typeof carLoan1Data[summationYear][1].Balance !== 'undefined') {
              summationBeginningBalance = carLoan1Data[summationYear][1].Balance;
            } else {
              summationBeginningBalance = 0;
            }
          } else {
            summationBeginningBalance = 0;
          }
        }
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      if (typeof module12Data.purchase_year !== 'undefined'
        && module12Data.purchase_year > 0
        && summationYear >= module12Data.purchase_year
        && typeof module12Data.tradein_existing_car_2 !== 'undefined'
        && module12Data.tradein_existing_car_2 === 'Yes') {
        summationBeginningBalance2 = 0;
      } else {
        if (typeof module12Data.second_purchase_year != 'undefined' &&
          module12Data.second_purchase_year > 0 &&
          summationYear >= module12Data.second_purchase_year &&
          typeof module12Data.moretradein_existing_car_2 != 'undefined' &&
          module12Data.moretradein_existing_car_2 === 'Yes') {
          summationBeginningBalance2 = 0;
        } else {
          if (loanAmount2 > 0) {
            if (typeof carLoan1Data !== 'undefined'
              && typeof carLoan1Data[summationYear] !== 'undefined'
              && typeof carLoan1Data[summationYear][1].Balance2 !== 'undefined') {
              summationBeginningBalance2 = carLoan1Data[summationYear][1].Balance2;
            } else {
              summationBeginningBalance2 = 0;
            }
          } else {
            summationBeginningBalance2 = 0;
          }
        }
      }
      summationData[summationYear].BeginningBalance2 = summationBeginningBalance2;

      if (typeof module12Data.purchase_year !== 'undefined'
        && module12Data.purchase_year > 0
        && summationYear >= module12Data.purchase_year
        && typeof module12Data.tradein_existing_car_3 !== 'undefined'
        && module12Data.tradein_existing_car_3 === 'Yes') {
        summationBeginningBalance3 = 0;
      } else {
        if (typeof module12Data.second_purchase_year !== 'undefined' &&
          module12Data.second_purchase_year > 0 &&
          summationYear >= module12Data.second_purchase_year &&
          typeof module12Data.moretradein_existing_car_3 !== 'undefined' &&
          module12Data.moretradein_existing_car_3 === 'Yes') {
          summationBeginningBalance3 = 0;
        } else {
          if (loanAmount3 > 0) {
            if (typeof carLoan1Data !== 'undefined'
              && typeof carLoan1Data[summationYear] !== 'undefined'
              && typeof carLoan1Data[summationYear][1].Balance3 !== 'undefined') {
              summationBeginningBalance3 = carLoan1Data[summationYear][1].Balance3;
            } else {
              summationBeginningBalance3 = 0;
            }
          } else {
            summationBeginningBalance3 = 0;
          }
        }
      }
      summationData[summationYear].BeginningBalance3 = summationBeginningBalance3;

      if (typeof module12Data.purchase_year !== 'undefined'
        && module12Data.purchase_year > 0
        && summationYear >= module12Data.purchase_year
        && typeof module12Data.tradein_existing_car_4 !== 'undefined'
        && module12Data.tradein_existing_car_4 === 'Yes') {
        summationBeginningBalance4 = 0;
      } else {
        if (typeof module12Data.second_purchase_year !== 'undefined' &&
          module12Data.second_purchase_year > 0 &&
          summationYear >= module12Data.second_purchase_year &&
          typeof module12Data.moretradein_existing_car_4 !== 'undefined' &&
          module12Data.moretradein_existing_car_4 === 'Yes') {
          summationBeginningBalance4 = 0;
        } else {
          if (loanAmount4 > 0) {
            if (typeof carLoan1Data !== 'undefined'
              && typeof carLoan1Data[summationYear] !== 'undefined'
              && typeof carLoan1Data[summationYear][1].Balance4 !== 'undefined') {
              summationBeginningBalance4 = carLoan1Data[summationYear][1].Balance4;
            } else {
              summationBeginningBalance4 = 0;
            }
          } else {
            summationBeginningBalance4 = 0;
          }
        }
      }
      summationData[summationYear].BeginningBalance4 = summationBeginningBalance4;

      // Personal Loans Beginning Balance

      if (typeof module1Data.start_year !== 'undefined' &&
        module1Data.start_year > 0 &&
        summationYear === module1Data.start_year) {
        summationBeginningBalance5 = loanAmount5;
      } else {
        if (loanAmount5 > 0 &&
          typeof module1Data.start_year !== 'undefined' &&
          module1Data.start_year > 0 &&
          summationYear > module1Data.start_year) {
          summationBeginningBalance5 = summationData[summationYear - 1]['EndingBalance5'];
        } else {
          summationBeginningBalance5 = 0;
        }
      }
      summationData[summationYear]['BeginningBalance5'] = summationBeginningBalance5;



      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if (summationData[summationYear].BeginningBalance > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingInterest !== 'undefined') {
          LaggingVal1 = carLoan1Data[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;


      if (summationData[summationYear].BeginningBalance2 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingInterest2 !== 'undefined') {
          LaggingVal12 = carLoan1Data[summationYear].LaggingInterest2;
        } else {
          LaggingVal12 = 0;
        }
      } else {
        LaggingVal12 = 0;
      }
      summationLaggingInterest2 = LaggingVal12;
      summationData[summationYear].LaggingInterest2 = summationLaggingInterest2;

      if (summationData[summationYear].BeginningBalance3 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingInterest3 !== 'undefined') {
          LaggingVal13 = carLoan1Data[summationYear].LaggingInterest3;
        } else {
          LaggingVal13 = 0;
        }
      } else {
        LaggingVal13 = 0;
      }
      summationLaggingInterest3 = LaggingVal13;
      summationData[summationYear].LaggingInterest3 = summationLaggingInterest3;

      if (summationData[summationYear].BeginningBalance4 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingInterest4 !== 'undefined') {
          LaggingVal14 = carLoan1Data[summationYear].LaggingInterest4;
        } else {
          LaggingVal14 = 0;
        }
      } else {
        LaggingVal14 = 0;
      }
      summationLaggingInterest4 = LaggingVal14;
      summationData[summationYear].LaggingInterest4 = summationLaggingInterest4;

      if (summationData[summationYear].BeginningBalance5 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingInterest5 !== 'undefined') {
          LaggingVal15 = carLoan1Data[summationYear].LaggingInterest5;
        } else {
          LaggingVal15 = 0;
        }
      } else {
        LaggingVal15 = 0;
      }
      summationLaggingInterest5 = LaggingVal15;
      summationData[summationYear].LaggingInterest5 = summationLaggingInterest5;




      // Lagging 12Mo. Principal
      if (summationData[summationYear].BeginningBalance > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingPrincipal !== 'undefined') {
          summationLaggingPrincipal = carLoan1Data[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      if (summationData[summationYear].BeginningBalance2 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingPrincipal2 !== 'undefined') {
          summationLaggingPrincipal2 = carLoan1Data[summationYear].LaggingPrincipal2;
        } else {
          summationLaggingPrincipal2 = 0;
        }
      } else {
        summationLaggingPrincipal2 = 0;
      }
      summationData[summationYear].LaggingPrincipal2 = summationLaggingPrincipal2;

      if (summationData[summationYear].BeginningBalance3 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingPrincipal3 !== 'undefined') {
          summationLaggingPrincipal3 = carLoan1Data[summationYear].LaggingPrincipal3;
        } else {
          summationLaggingPrincipal3 = 0;
        }
      } else {
        summationLaggingPrincipal3 = 0;
      }
      summationData[summationYear].LaggingPrincipal3 = summationLaggingPrincipal3;

      if (summationData[summationYear].BeginningBalance4 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingPrincipal4 !== 'undefined') {
          summationLaggingPrincipal4 = carLoan1Data[summationYear].LaggingPrincipal4;
        } else {
          summationLaggingPrincipal4 = 0;
        }
      } else {
        summationLaggingPrincipal4 = 0;
      }
      summationData[summationYear].LaggingPrincipal4 = summationLaggingPrincipal4;

      if (summationData[summationYear].BeginningBalance5 > 1) {
        if (typeof carLoan1Data[summationYear] !== 'undefined' && typeof carLoan1Data[summationYear].LaggingPrincipal5 !== 'undefined') {
          summationLaggingPrincipal5 = carLoan1Data[summationYear].LaggingPrincipal5;
        } else {
          summationLaggingPrincipal5 = 0;
        }
      } else {
        summationLaggingPrincipal5 = 0;
      }
      summationData[summationYear].LaggingPrincipal5 = summationLaggingPrincipal5;



      // Total Interest
      if (summationData[summationYear].BeginningBalance > 1) {
        summationTotalInterest = summationTotalInterest + summationData[summationYear].LaggingInterest + summationTotalInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;

      if (summationData[summationYear].BeginningBalance2 > 1) {
        summationTotalInterest2 = summationTotalInterest2 + summationData[summationYear].LaggingInterest2 + summationTotalInterest2;
      } else {
        summationTotalInterest2 = 0;
      }
      summationData[summationYear].TotalInterest2 = summationTotalInterest2;

      if (summationData[summationYear].BeginningBalance3 > 1) {
        summationTotalInterest3 = summationTotalInterest3 + summationData[summationYear].LaggingInterest3 + summationTotalInterest3;
      } else {
        summationTotalInterest3 = 0;
      }
      summationData[summationYear].TotalInterest3 = summationTotalInterest3;

      if (summationData[summationYear].BeginningBalance4 > 1) {
        summationTotalInterest4 = summationTotalInterest4 + summationData[summationYear].LaggingInterest4 + summationTotalInterest4;
      } else {
        summationTotalInterest4 = 0;
      }
      summationData[summationYear].TotalInterest4 = summationTotalInterest4;

      if (summationData[summationYear].BeginningBalance5 > 1) {
        summationTotalInterest5 = summationTotalInterest5 + summationData[summationYear].LaggingInterest5 + summationTotalInterest5;
      } else {
        summationTotalInterest5 = 0;
      }
      summationData[summationYear].TotalInterest5 = summationTotalInterest5;


      // Cumulative Principal

      if (summationData[summationYear].BeginningBalance > 1) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }
      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;

      if (summationData[summationYear].BeginningBalance2 > 1) {
        summationTotalPrincipal2 += summationData[summationYear].LaggingPrincipal2;
      } else {
        summationTotalPrincipal2 = 0;
      }
      summationData[summationYear].TotalPrincipal2 = summationTotalPrincipal2;

      if (summationData[summationYear].BeginningBalance3 > 1) {
        summationTotalPrincipal3 += summationData[summationYear].LaggingPrincipal3;
      } else {
        summationTotalPrincipal3 = 0;
      }
      summationData[summationYear].TotalPrincipal3 = summationTotalPrincipal3;

      if (summationData[summationYear].BeginningBalance4 > 1) {
        summationTotalPrincipal4 += summationData[summationYear].LaggingPrincipal4;
      } else {
        summationTotalPrincipal4 = 0;
      }
      summationData[summationYear].TotalPrincipal4 = summationTotalPrincipal4;

      if (summationData[summationYear].BeginningBalance5 > 1) {
        summationTotalPrincipal5 += summationData[summationYear].LaggingPrincipal5;
      } else {
        summationTotalPrincipal5 = 0;
      }
      summationData[summationYear].TotalPrincipal5 = summationTotalPrincipal5;



      // Ending Balance
      if (summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal) {
        summationEndingBalance = parseFloat(summationData[summationYear].BeginningBalance) - parseFloat(summationData[summationYear].LaggingPrincipal);
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;
      if (summationData[summationYear].BeginningBalance2 > summationData[summationYear].LaggingPrincipal2) {
        summationEndingBalance2 = parseFloat(summationData[summationYear].BeginningBalance2) - parseFloat(summationData[summationYear].LaggingPrincipal2);
      } else {
        summationEndingBalance2 = 0;
      }
      summationData[summationYear].EndingBalance2 = summationEndingBalance2;

      if (summationData[summationYear].BeginningBalance3 > summationData[summationYear].LaggingPrincipal3) {
        summationEndingBalance3 = parseFloat(summationData[summationYear].BeginningBalance3) - parseFloat(summationData[summationYear].LaggingPrincipal3);
      } else {
        summationEndingBalance3 = 0;
      }
      summationData[summationYear].EndingBalance3 = summationEndingBalance3;

      if (summationData[summationYear].BeginningBalance4 > summationData[summationYear].LaggingPrincipal4) {
        summationEndingBalance4 = parseFloat(summationData[summationYear].BeginningBalance4) - parseFloat(summationData[summationYear].LaggingPrincipal4);
      } else {
        summationEndingBalance4 = 0;
      }
      summationData[summationYear].EndingBalance4 = summationEndingBalance4;

      if (summationData[summationYear].BeginningBalance5 > summationData[summationYear].LaggingPrincipal5) {
        summationEndingBalance5 = parseFloat(summationData[summationYear].BeginningBalance5) - parseFloat(summationData[summationYear].LaggingPrincipal5);
      } else {
        summationEndingBalance5 = 0;
      }
      summationData[summationYear].EndingBalance5 = summationEndingBalance5;

      // k += 1;
    }
  }

  function existingCarLoanData() {
    const CarLoanOneFullData = {};
    CarLoanOneFullData['Car Loan1'] = carLoan1Data;
    CarLoanOneFullData['Summation Data'] = summationData;

    CarLoanOneFullData['Total Paid'] = totalPaid;
    CarLoanOneFullData['Total Interest Paid'] = totalInterestPaid;
    CarLoanOneFullData['Annual Interest Rate'] = annualInterestRate;

    CarLoanOneFullData['Total Paid2'] = totalPaid2;
    CarLoanOneFullData['Total Interest Paid2'] = totalInterestPaid2;
    CarLoanOneFullData['Annual Interest Rate2'] = annualInterestRate2;

    CarLoanOneFullData['Total Paid3'] = totalPaid3;
    CarLoanOneFullData['Total Interest Paid3'] = totalInterestPaid3;
    CarLoanOneFullData['Annual Interest Rate3'] = annualInterestRate3;

    CarLoanOneFullData['Total Paid4'] = totalPaid4;
    CarLoanOneFullData['Total Interest Paid4'] = totalInterestPaid4;
    CarLoanOneFullData['Annual Interest Rate4'] = annualInterestRate4;

    CarLoanOneFullData['Total Paid5'] = totalPaid5;
    CarLoanOneFullData['Total Interest Paid5'] = totalInterestPaid5;
    CarLoanOneFullData['Annual Interest Rate5'] = annualInterestRate5;

    return CarLoanOneFullData;
  }
  return existingCarLoanData();
}
export default existingCarLoanSheet;