const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  senseInfoHeader:{
    height: '80vh',
    position: 'relative',
    background: '#0069aa',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      height: 'auto',
      padding: '100px 0px',
    }
  },
  shareInvite:{
    textAlign: 'center',
  },
  joinInkwiry:{
    color: '#fff',
    textAlign: 'center',
    '& h1':{
      color: '#fff',
      fontWeight: '100',
      fontSize: '45px',
      margin: '20px 0px 10px',
      fontFamily: '"MuseoSans-100"',
    },
    '& p':{
      fontSize: '20px',
      fontWeight: '100',
      padding: '10px 0',
      margin: '0px 0px 10px',
      fontFamily: '"MuseoSans-100"',  
      '& span':{
        display: 'block',
        lineHeight: '30px',
      }
    }
  },
  bannerText:{
    position: 'relative',
    top: 'auto',
    transform: 'translateY(-50%)',
    WebkitTransform: 'none',
    background: '#fff',
    left: '0',
    right: '0',
    zIndex: '99',
    textAlign: 'center',
    bottom: 'auto',
    maxWidth: '850px',
    margin: '0 auto',
    padding: '40px',
    '@media (max-width: 767px)': {
      padding: '25px',
    },
  },
  bannerTextOne:{
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'block',
    },
    '& p':{
      fontWeight: '300',
      fontSize: '16px',
      width: '66.66666667%',
      textAlign: 'left',
      margin: '0px',
      '@media (max-width: 767px)': {
        width: '100%',
      }
    }
  },
  spanRight:{
    display: 'block',
    width: '33.33333%',
    textAlign: 'right',
    '@media (max-width: 767px)': {
      width: '100%',
      textAlign: 'left',
    },
    '& button':{
      color: '#0069aa',
      cursor: 'pointer',
      fontSize: '16px',
      textTransform: 'initial',
      margin: '0px 0px 10px',
      padding: '0px',
      '&:hover':{
        backgroundColor: 'transparent',
      },
    }
  },
  linkClose:{
    position: 'absolute',
      right: '10px',
      top: '16px',
      opacity: '1',
    '& button':{
      color: '#0069aa',
      cursor: 'pointer',
      fontSize: '16px',
      textTransform: 'initial',
      margin: '0px 0px 10px',
      padding: '0px',
      '&:hover':{
        backgroundColor: 'transparent',
      },
    }
  },
  bannerTextTwo:{
    paddingTop: '20px',
    '& form':{
      display: 'flex',
      '@media (max-width: 767px)': {
        display: 'block',
      }
    }
  },
  fieldLeft:{
    width: '66.66666667%',
    paddingRight: '15px',
    '@media (max-width: 767px)': {
      width: '100%',
      paddingRight: '0px',
      paddingBottom: '20px',
    },
    '& div':{
      backgroundColor: 'transparent',
      '&:before':{
        display: 'none',
      },
      '&:after':{
        display: 'none',
      }
    },
    '& input':{
      padding: '25px 22px',
      fontSize: '18px',
      borderWidth: '2px',
      background: 'transparent',
      backgroundColor: '#fff',
      backgroundImage: 'none',
      border: '1px solid #ccc',
      borderRadius: '4px',
      WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      '&:focus':{
        borderColor: '#66afe9',
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
      }
    }
  },
  fieldRight:{
    width: '33.33333%',
    paddingLeft: '15px',
    '@media (max-width: 767px)': {
      width: '100%',
      paddingLeft: '0px',
    },
    '& button':{
      width: '100%',
      background: '#f2f2f2',
      boxShadow: 'none',
      border: '2px solid #ccc',
      padding: '20px 22px',
      fontSize: '18px',
      display: 'block',
      cursor: 'pointer',
      textTransform: 'capitalize',
      '&:hover':{
        background: '#ccc',
        color: '#fff',
      }
    }
  },
  copyReferalLink:{
    display: 'flex',
    paddingTop: '25px',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      display: 'block',
    },
    '& span':{
      display: 'inline-block',
      margin: '0px 25px',
      '@media (max-width: 767px)': {
        display: 'block',
        margin: '10px 25px',
      },
    },
    '& button':{
      color: '#337ab7',
      padding: '0px',
      textTransform: 'capitalize',
      '& span':{
        margin: '0px',
      }
    }
  },
  membershipShareFees:{
    margin: '50px auto 30px',
    textAlign: 'center',
    maxWidth: '850px',
    width: '100%',
    '& p':{
      fontSize: '18px',
      fontWeight: '100',
      padding: '10px 0',
      margin: '0 0 10px',
      fontFamily: '"MuseoSans-100"',
    }
  },
  invitesList:{
    marginTop: '20px',
    marginBottom: '60px',
    display: 'flex',
  },
  invitesListLeft:{
    width: '50%',
    '& span':{
      margin: '0',
      fontSize: '70px',
      fontWeight: '400',
      color: '#000',
      fontFamily: '"MuseoSans-100"',
    },
    '& p':{
      margin: '0 0 10px',
      fontSize: '18px',
    }
  },
  invitesListRight:{
    width: '50%',
    '& span':{
      margin: '0',
      fontSize: '70px',
      fontWeight: '400',
      color: '#000',
      fontFamily: '"MuseoSans-100"',
    },
    '& p':{
      margin: '0 0 10px',
      fontSize: '18px',
    }
  },
  addGroup: {
    // overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
      maxHeight: 'calc(100vh - 50px)',
    },
    '& > div > div': {
      minWidth: '700px',
      width: '700px',
      maxHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      }
    },
    '& > div > div > div': {
      padding: '0px',
      paddingTop: '0px !important',
    }
  },
  popupHeader: {
    display: 'inline-block',
    width: 'calc(100% - 30px)',
    borderBottom: '1px solid #e5e5e5',
    padding: '15px',
    '& h4': {
      fontSize: '18px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
      fontFamily: '"MuseoSans-500"',
    },
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& i': {
      opacity: '0.3',
      float: 'right',
      cursor: 'pointer',
      fontSize: '20px',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  popupContent: {
    padding: '35px 0px 0px',
    '& p': {
      padding: '0 0 18px 0',
      fontSize: '16px',
      margin: '0',
      color: '#000',
      fontFamily: 'MuseoSans-300',
    },
  },
  popupFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: '1px solid #e5e5e5',
    '& p': {
      margin: '0',
      maxWidth: '640px',
      fontSize: '16px',
      lineHeight: '26px',
    },
    '& button': {
      margin: '0 0 0 auto',
      background: theme.palette.common.white,
      border: '2px solid #ccc',
      padding: '6px 12px',
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '1.4',
      textTransform: 'capitalize',
      borderRadius: '4px',
      color: theme.palette.common.black,
      outline: 'none',
      '&:hover': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:focus': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      '& button': {
        margin: '15px auto',
      },
    },
  },
  modelBody:{
    position: 'relative',
    padding: '15px',
  },
  logoDiv:{
    width: '525px',
    margin: 'auto',
    fontSize: '14px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      maxWidth: '525px',
    },
    '& figure':{
      padding: '15px 0',
      textAlign: 'center',
      margin: '0px',
      borderBottom: '2px solid #d7d7d7',
      '& img':{
        height: 'auto',
        maxWidth: '140px',
        width: '100%',
      }
    }
  },
  logoCnt:{
    textAlign: 'center',
    '& h2':{
      color: '#333',
      paddingBottom: '10px',
      margin: '20px 0px',
      fontFamily: '"MuseoSans-500"',
      fontSize: '1.5em',
    },
    '& p':{
      margin: '0 0 10px',
      fontSize: '16px',
      lineHeight: '24px',
      '& span':{
        display: 'block',
      }
    }
  },
  logoAnch:{
    textAlign: 'center',
    display: 'block',
    margin: '0 0 10px',
    '& a':{
      color: '#0069aa',
      textDecoration: 'none',
      fontSize: '13px',
      fontStyle: 'italic',
      '&:hover':{
        textDecoration: 'none',
      }
    }
  },
  footerCnt:{
    background: '#f5f5f5',
    textAlign: 'center',
    color: '#a1a1a1',
    padding: '25px 0 20px',
    fontFamily: '"MuseoSans-300"',
    fontSize: '14px',
    marginBottom: '30px',
    '& h3':{
      color: '#333',
      fontSize: '22px',
      marginTop: '20px',
      marginBottom: '10px',
    },
    '& p':{
      margin: '0px',
      color: '#333',
      fontFamily: '"MuseoSans-300"',
      lineHeight: '1.42857143',
    }
  },
  footerAnch:{
    color: '#0069aa',
    textDecoration: 'none',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    '&:hover':{
      textDecoration: 'none',
    }
  },
  footerPara:{
    fontSize: '13px',
    marginTop: '50px',
    marginBottom: '10px',
    color: '#a1a1a1',
    display: 'block',
    '& span':{
      color: '#a1a1a1',
      margin: '0 5px',
      textDecoration: 'none',            
      '&:hover': {
        textDecoration:'underline',
        cursor:'pointer',
      },
    }
  },
  bannerTextSuccess:{
    color: '#3c763d',
    backgroundColor: '#dff0d8',
    borderColor: '#d6e9c6',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '15px',
    textAlign: 'center',
    position: 'relative',
    display: 'block',
    '& p':{
      margin: '0px',
      width: '100%',
    },
    '& span':{
      position: 'absolute',
      right: '15px',
      opacity: '0.2',
      '&:hover':{
        opacity: '1',
      },
      '& button':{
        marginBottom: '0px',
      }
    }
  }
} );

export default styles;
