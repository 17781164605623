import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-car/styles';

const BuyingLeasingConsPros = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>There&apos;s two options when it comes to having a vehicle: buying and leasing. Check out the pros and cons below as you think about planning for your next vehicle.</p>
      <div className={ classes.carLeaseTable }>
        <table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th colSpan="2">Leasing a Car</th>
              <th colSpan="2">Buying a Car</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><span>Category</span></td>
              <td><span>Pros</span></td>
              <td><span>Cons</span></td>
              <td><span>Pros</span></td>
              <td><span>Cons</span></td>
            </tr>
            <tr>
              <td>Ownership</td>
              <td>New car every few years</td>
              <td>Don&apos;t own the car</td>
              <td>Own the car outright</td>
              <td>--</td>
            </tr>
            <tr>
              <td>Next Car Purchase</td>
              <td>--</td>
              <td>Every few years a new lease will require a new cash down payment</td>
              <td>Can trade in existing car when purchasing a new one</td>
              <td>--</td>
            </tr>
            <tr>
              <td>Mileage Limitation</td>
              <td>--</td>
              <td>Mileage limit between 8,000 and 15,000 per year; ~$0.15 per mile after mileage limit</td>
              <td>No mileage limit</td>
              <td>--</td>
            </tr>
            <tr>
              <td>Down Payment</td>
              <td>Smaller cash down payment</td>
              <td>--</td>
              <td>--</td>
              <td>Larger cash down payment</td>
            </tr>
            <tr>
              <td>Size of Monthly Payment</td>
              <td>Usually lower monthly payment when compared to a car loan</td>
              <td>--</td>
              <td>--</td>
              <td>Financing purchase typically results in higher monthly payment</td>
            </tr>
            <tr>
              <td>Monthly Payment Length</td>
              <td>--</td>
              <td>Will always have a monthly payment</td>
              <td>Once car loan is repaid, no monthly payment</td>
              <td>--</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        Want to learn more about the pros and cons of leasing and buying? Click
        {' '}
        {' '}
        <a href="https://www.thebalance.com/pros-and-cons-of-leasing-vs-buying-a-car-527145" target="_blank" rel="noopener noreferrer">here</a>
        . Or, explore
        {' '}
        {' '}
        <a href="https://www.khanacademy.org/college-careers-more/personal-finance/pf-auto/buying-vs-leasing/v/buying-vs-leasing-a-car" target="_blank" rel="noopener noreferrer">Khan Academy&apos;s leasing vs buying example</a>
        {' '}
        in terms of which option is more expensive.
      </p>
    </div>
  );
};

BuyingLeasingConsPros.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( BuyingLeasingConsPros );
