import gradientBlue from '../../assets/img/desktop/gradient_bg.png';

const styles = ( theme ) => ( {
  closeIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 1,
    padding: '0px',
    margin: '0px',
    color: "#f5f5f5",
    fontSize: '20px'
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  welcomeContent: {
    border: '2px solid #d7d7d7',
    padding: '0',
    margin: '20px 0 25px',
    borderTopWidth: '15px',
    borderRadius: '12px',
    display: 'flex',   
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      flexWrap: 'wrap',     
      marginLeft: '0px',
    },
  },
  dashboardImage: {
    display: 'flex',
    width: 'calc(34% - 33px)',
    margin: '10px 10px 10px 23px',
    // borderRight: '3px solid #d7d7d7',
    boxSizing: 'border-box',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',     
      boxSizing: 'border-box',
      alignItems: 'center',
      borderRight: 'none',
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      width: '30%',
    },
    '& h2': {
      display: 'table-cell',
      verticalAlign: 'top',
      color: '#0069aa',
      fontSize: '24px',
      padding: '17px 0 0 10px',
      textTransform: 'capitalize',
      lineHeight: '24px',
      fontFamily: '"MuseoSans-500"',
      fontWeight: '400',    
      '@media (max-width: 400px)': {
        fontSize: '22px',
      },
      '@media (max-width: 320px)': {
        fontSize: '18px',
      },  
      '@media screen and (min-width: 600px) and (max-width: 700px)': {
        fontSize: '12px',
      }, 
      '@media screen and (min-width: 701px) and (max-width: 767px)': {
        fontSize: '14px',
      },  
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        fontSize: '18px',
      },     
    },
    '&:after':{
      content: '""',
      position: 'absolute',
      width: '3px',
      right: '0',
      top: '50%',
      height: '80%',
      transform: 'translateY(-50%)',
      WebkitTransform: 'translateY(-50%)',
      MozTransform: 'translateY(-50%)',
      MsTransform: 'translateY(-50%)',
      OTransform: 'translateY(-50%)',
      background: '#d7d7d7',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'none',
      }
    }
  },
  userProfile: {
    width: '115px',
    verticalAign: 'top',
    [theme.breakpoints.down( 'xs' )]: {
      width: '85px',      
    },
    '@media (max-width: 320px)': {
      width: '60px',
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      width: '50px',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      width: '60px',
    },
    '@media screen and (min-width: 992px) and (max-width: 1024px)': {
      width: '90px',
    },
    '& img': {
      display: 'block',
      width: '108px',
      height: '108px',
      borderRadius: '100%',
      border: '1px solid #ccc',
      cursor: 'pointer',
      [theme.breakpoints.down( 'xs' )]: {
        width: '85px',
        height: '85px', 
      },
      '@media (max-width: 320px)': {
        width: '60px',
        height: '60px', 
      },
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '50px',
        height: '50px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        width: '60px',
        height: '60px',
      },
      '@media screen and (min-width: 992px) and (max-width: 1024px)': {
        width: '90px',
        height: '90px',
      },
    },
  },
  dashboardContent: {
    width: '66%',
    display: 'inline-block',
    padding: '29px 0 0 8px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%', 
      paddingLeft: '15px',   
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      width: '70%',      
    },
  },
  activeSketch: {
    '& h2': {
      margin: '0',
      padding: '0 0 6px',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      color: '#717171',
      '@media screen and (min-width: 600px) and (max-width: 700px)': {
        fontSize: '13px',
      },
      '@media screen and (min-width: 701px) and (max-width: 992px)': {
        fontSize: '15px',
      },      
    },
    '& p': {
      margin: '0',
      padding: '0 0 15px',
      fontFamily: '"MuseoSans-100"',
      fontSize: '14px',
      lineHeight: '14px',
      color: theme.palette.common.black,
      '@media screen and (min-width: 600px) and (max-width: 700px)': {
        fontSize: '13px',
      },
      '@media screen and (min-width: 701px) and (max-width: 767px)': {
        fontSize: '15px',
      },  
    },
  },
  dashboardNetWorth: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block', 
    },
    '& h2': {
      padding: '0 12px 0 0',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      color: '#717171',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
      },
      '@media screen and (min-width: 600px) and (max-width: 700px)': {
        fontSize: '14px',
      },
      '@media screen and (min-width: 701px) and (max-width: 767px)': {
        fontSize: '15px',
      },  
    },
    '& ul': {
      padding: '0px',
      margin: '0px',
      listStyle: 'none',
      '& li': {
        display: 'flex',
        padding: '0 0 8px',
        color: '#000',
        margin: '0',
        '& font': {
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '45%',
          fontFamily: '"MuseoSans-300"',
          fontSize: '18px',
          lineHeight: '18px',
          boxSizing: 'border-box',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '16px',
          },
          '@media screen and (min-width: 600px) and (max-width: 767px)': {
            fontSize: '14px',
          },
        },
      },
    },
  },
  yearCount:{
    display: 'inline-block',
    verticalAlign: 'middle',
    fontFamily: '"MuseoSans-500"',
    fontSize: '12px',
    lineHeight: '12px',
    padding: '4px 13px',
    margin: '0 14px 0 0',
    background: '#ebebeb',
    borderRadius: '12px',
    minWidth: '72px',
    textAlign: 'center',
    boxSizing: 'border-box',
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      fontSize: '10px',
      padding: '4px 8px',
      margin: '0 4px 0 0',
      minWidth: '60px',
    },
  },
  formInput:{
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '45%',
    fontFamily: '"MuseoSans-300"',
    fontSize: '18px',
    lineHeight: '18px',
    boxSizing: 'border-box',
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      fontSize: '14px',
    }
  },
  formInputLeft:{ 
    fontFamily: '"MuseoSans-300"',
    fontSize: '18px',
    lineHeight: '18px',
  },
  desktopArrow: {
    maxWidth: '88%',
    position: 'relative',
    margin: '0 auto',
    '@media (min-width: 1400px)': {
      maxWidth: '82%',
    },
    '@media (min-width: 2000px)': {
      maxWidth: '80%',
    },  
    '@media screen and (min-width: 700px) and (max-width: 767px)': {
      maxWidth: '100%',
    }, 
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      maxWidth: '100%',
    }, 
    '& img': {
      position: 'absolute',
      maxWidth: '100%',
      top: '65px',
      bottom: '0',
      display: 'block',
      height: 'auto',
      paddingTop: '0px',
      '@media (min-width: 1400px)': {
        top: '130px',
      },
      '@media (min-width: 1600px)': {
        top: '93px',
      },
      '@media (min-width: 2001px)': {
        top: '74px',
      },
      '@media (max-width: 599px)': {
        top: '0px',
      },
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
       top: '50px',
      },
      '@media screen and (min-width: 700px) and (max-width: 767px)': {
        top: '125px',
      },
      '@media screen and (min-width: 768px) and (max-width: 800px)': {
        top: '180px',
      }, 
      '@media screen and (min-width: 801px) and (max-width: 992px)': {
        top: '135px',
      }, 
    },
  },
  uncoverPath: {
    backgroundImage: `url(${gradientBlue})`,
    backgroundSize: 'cover',
    display: 'block',
    padding: '45px 0px',
    '@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait)  and (-webkit-min-device-pixel-ratio: 2)': {
      padding: '45px 0px 400px 0px',
    },
  },
  arrowContent: {
    position: 'relative',
    '& h4': {
      color: '#fff',
      fontSize: '45px',
      lineHeight: '45px',
      textAlign: 'center',
      padding: '10px 0px 10px',
      fontFamily: 'MuseoSans-100',
      '@media (max-width: 1500px)': {
        fontSize: '36px',
      },
      '@media (max-width: 767px)': {
        fontSize: '29px',
        paddingTop: '0px',
      },
      '@media (max-width: 320px)': {
        fontSize: '25px',        
      },
    },
  },
  desktopLogos: {
    // margin: '8px 0',
    margin: '60px 0px 30px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'relative',
    padding: '50px 0 100px',
    boxSizing: 'border-box',
    // '@media (min-width: 1500px)': {
    //   marginTop: '60px',
    // },
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
      paddingBottom: '50px',
      paddingTop: '10px',
    },
    '@media screen and (min-width: 700px) and (max-width: 767px)': {
     paddingTop: '20px',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      paddingTop: '30px',
    }, 
    '@media screen and (min-width: 960px) and (max-width: 1500px)': {
      margin: '0px 0',
    }
  },
  powerUp: {
    padding: '10px 20px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transform: 'scale(.9)',
    '@media (min-width: 1401px)': {
      height: '180px!important',
    },
    '@media screen and (min-width: 700px) and (max-width: 767px)': {
      height: '130px',
      marginRight: '40px',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      height: '120px',
    }, 
    '&:hover': {
      background: '#789',
    },
    '& img': {
      maxWidth: '280px',
      float: 'right',
      '@media (min-width: 1401px)': {
        maxWidth: '300px',
      },
      '@media (min-width: 1600px)': {
        maxWidth: '350px',
      },
      '@media screen and (min-width: 700px) and (max-width: 767px)': {
        maxWidth: '230px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        maxWidth: '200px',
      },
    },
  },
  careerSketch: {
    padding: '10px 20px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    margin: '110px 10px 0',
    cursor: 'pointer',
    boxSizing: 'border-box',
    transform: 'scale(.9)',
    '@media (min-width: 1401px)': {
      height: '180px!important',
    },
    '@media (max-width: 767px)': {
      margin: '10px',
    },
    '@media screen and (min-width: 700px) and (max-width: 767px)': {
      marginLeft: '50px',
      height: '130px',
      marginTop: '-15px',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      height: '120px',
    },
    '&:hover': {
      background: '#789',
    },
    '& img': {
      maxWidth: '280px',
      float: 'right',
      '@media (min-width: 1401px)': {
        maxWidth: '300px',
      },
      '@media (min-width: 1600px)': {
        maxWidth: '350px',
      },
      '@media screen and (min-width: 700px) and (max-width: 767px)': {
        maxWidth: '230px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        maxWidth: '200px',
      },
    },
  },
  planInkwiry: {
    marginLeft: '0',
    padding: '24px 40px',
    marginTop: '-25px',
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    borderRadius: '10px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    transform: 'scale(.9)',
    '@media (min-width: 1401px)': {
      height: '180px!important',
    },
    '@media (max-width: 767px)': {
      marginTop: '0px',
    },
    '@media screen and (min-width: 700px) and (max-width: 767px)': {
      height: '130px',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      height: '120px',
    },
    '&:hover': {
      background: '#789',
    },
    '& img': {
      maxWidth: '240px',
      display: 'flex',
      margin: '0 auto',
      '@media (min-width: 1401px)': {
        maxWidth: '260px',
      },
      '@media (min-width: 1600px)': {
        maxWidth: '310px',
      },
      '@media screen and (min-width: 700px) and (max-width: 767px)': {
        maxWidth: '190px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        maxWidth: '160px',
      },
    },
  },
  quickTourButton: {
    background: '#014372',
    width: '280px',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 auto',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#fff',
    justifyContent: 'center',
    padding: '10px 0',
    borderRadius: '10px',
    position: 'relative',
  },
  quickTourLogo: {
    width: '100%',
    '& img': {
      maxWidth: '140px',
      border: '0',
    },
  },
  quickTourLine: {
    position: 'relative',
    height: '30px',
    margin: '10px 0',
    width: '100%',
    '& img': {
      position: 'absolute',
      width: '100%',
      left: '15px',
      top: '-29px',
      maxWidth: '250px',
      right: 'auto',
      display: 'inline-block',
      border: '0',
    },
  },
  quickText: {
    width: '100%',
    zIndex: '99',
    '& h3': {
      fontWeight: '500',
      fontSize: '24px',
      lineHeight: '1.1',
      color: '#fff',
      margin: '0',
      fontFamily: '"MuseoSans-500"',
    },
  },
  videoIcon: {
    position: 'absolute',
    top: '0',
    width: '100%',
    maxWidth: '40px',
    left: '0',
    right: '0',
    margin: 'auto',
    height: '40px',
    bottom: '0',
    '& img': {
      width: '100%',
    },
    '& span':{
      cursor: 'pointer',
    }

  },
  powerUpButton: {
    padding: '0px',
    '&:hover': {
      background: 'transparent',
    },
  },
  currentNetworth: {
    '& h2': {
      margin: '0',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      padding: '0 0 6px',
      color: '#717171',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        fontSize: '13px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {
        fontSize: '16px',
      },
      '@media screen and (min-width: 993px) and (max-width: 1024px)': {
        fontSize: '15px',
      },
    },
    '& p': {
      padding: '0 0 15px',
      color: '#333',
      margin: '0',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      '@media (min-width: 600px)': {
        fontSize: '12px',
      },
    },
  },
  noPadding: {
    [theme.breakpoints.down( 'xs' )]: {     
      width: '100%',
      flexBasis: '100%',
      maxWidth: '100%',
      padding: '12px 5px !important',
    },   
    '@media screen and (min-width: 600px) and (max-width: 992px)': {
      width: '33%',
      flexBasis: '33%',
      maxWidth: '33%',
      padding: '12px 5px !important',
    },
  },
  welcomeSection:{
    '@media (min-width: 1200px)': {
      margin: '0px -15px',
      '& > div':{
        padding: '0px',
      }
    }
  }
} );

export default styles;
