import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Dialog } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as sliderValues from '../../utilities/existingsliders';
import styles from '../../screens/pwi-existing/styles';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../calculations/modules-services';
import downArrow from '../../assets/img/pwi-existing/weighted-interest-rate-arrow.png'

const interestRateValues = [
  {
    value: 0.00,
    scaledValue: 0.00,
    label: '0%',
  },
  {
    value: 5.00,
    scaledValue: 5.00,
    label: '5%',
  },
  {
    value: 10.00,
    scaledValue: 10.00,
    label: '10%',
  },
  {
    value: 15.00,
    scaledValue: 15.00,
    label: '15%',
  },
  {
    value: 20.00,
    scaledValue: 20.00,
    label: '20%',
  },
  {
    value: 25.00,
    scaledValue: 25.00,
    label: '25%',
  },
  {
    value: 30.00,
    scaledValue: 30.00,
    label: '30%',
  },

];

const ExistingCreditCardBalance = ( props ) => {
  const {
    classes, handleDefinationPopup, handleAssestObject, handleUpdatedObject,
  } = props;

  const [weightedAverageRate, setWeightedAverageRate] = React.useState([
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
  ])

  const [ previousWeightedAverageRate, setPreviousWeightedAverageRate] = React.useState([
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
    { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
  ])

  const [totalBalanceInterest, setTotalBalanceInterest] = React.useState( 0 );
  const [totalWeight, setTotalWeight] = React.useState(6.00);

  const [ptotalBalanceInterest, setPTotalBalanceInterest] = React.useState( 0 );
  const [ptotalWeight, setPTotalWeight] = React.useState(6.00);

  const [repaymentValue, setRepaymentValue] = React.useState( 6 );
  const [creditCardInterestRate, setCreditCardInterestRate] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const inputValues = {...handleAssestObject};
    if ( handleAssestObject.existing_credit_card_balance !== undefined ) {
      setTotalBalanceInterest( handleAssestObject.existing_credit_card_balance );
    } else {
      inputValues['existing_credit_card_balance'] = 0;
    }
    if ( handleAssestObject.creditcard_interest_rate !== undefined ) {
      setTotalWeight( handleAssestObject.creditcard_interest_rate );
    } else {
      inputValues['creditcard_interest_rate'] = 6.00;
    }
    if ( handleAssestObject.ideal_repayment_schedule !== undefined ) {
      setRepaymentValue( handleAssestObject.ideal_repayment_schedule );
    } else {
      inputValues['ideal_repayment_schedule'] = 6;
    }
    handleUpdatedObject( inputValues );
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const inputValues = {...handleAssestObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value !== undefined ? e.target.value : 0;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'existing_credit_card_balance': {
        setTotalBalanceInterest( newvalue );
        inputValues[field] = newvalue;
        break;
      }
      case 'creditcard_interest_rate': {
        setTotalWeight( newvalue > 100 ? 100 : newvalue );
        inputValues[field] = newvalue;
        break;
      }
      case 'ideal_repayment_schedule': {
        setRepaymentValue( newvalue );
        inputValues[field] = newvalue;
        break;
      }
      default:
        break;
    }
    handleUpdatedObject( inputValues );
  };
  const handleChange = (e, index, field) => {
    let newArr = [...weightedAverageRate];
    newArr[index][field] = e.floatValue;
    
    let totalAmount = 0;
    let totalInterest = 0;
    let totalWeight = 0;

    for (let i = 0; i < 6; i++) {
      totalAmount += newArr[i].balance;
      if(i === 5 ){
        for (let j = 0; j < 6; j++) {
          let balance = newArr[j].balance
          let interest = newArr[j].interest_rate;

          let interestVal = 0;
          if(balance > 0){
            interestVal = ( balance / totalAmount)*100;
            newArr[j].total_balance_rate = interestVal;
            totalInterest = totalInterest + interestVal;
          }
          if(field === 'interest_rate' && interest > 0){
            let weight = (interestVal * interest)/100;
            newArr[j].total_interest_rate = weight;
            totalWeight += parseFloat(weight).toFixed( 2 );
          }
        }
      }
    }
    setTotalBalanceInterest(totalInterest)
    setTotalWeight(parseFloat(totalWeight))
    setTotalBalanceInterest(totalAmount)
    setWeightedAverageRate([...newArr])
    
  }
  const handleCancel = () =>{
    setWeightedAverageRate(previousWeightedAverageRate);
    setTotalBalanceInterest(ptotalBalanceInterest);
    setTotalWeight(parseFloat(ptotalWeight));
    setCreditCardInterestRate(!creditCardInterestRate)
  }
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        Carrying a balance on your credit card(s) can be really expensive. Why? Out of all personal loans - student loans, car loans, and mortgages - credit cards have the
        {' '}
        {' '}
        <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>
          highest interest rates
        </span>
        . Let&apos;s develop a plan to repay your credit card(s). Learn more about a credit card
        {' '}
        {' '}
        <a href="/finance-explained/credit-card" target="_blank" rel="noreferrer">
          {' '}
          {' '}
          here
        </a>
        .
      </p>
      If you have more than one credit cards with different interest rates, <span className={classes.clickHere} onClick={ ()=> {
        setCreditCardInterestRate(!creditCardInterestRate)
      }}>click here</span> so that we can calculate 
      your <span  className={ classes.dottedText } onClick={ () => handleDefinationPopup( 'Weighted Average Interest Rate' ) }>weighted average interest</span> rate. 
      If you have just one credit card you are repaying, enter those details below.
      <p>

      </p>
      <div className={ classes.sliderBottomSpaceNewOneScroll }>
        <div className={ classes.sliderBottomSpaceNewOne }>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p className={ classes.twentyFont }>Credit Card Balance</p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.loanArray() }
                  min={ 0 }
                  max={ 150000 }
                  step={ 200 }
                  value={ totalBalanceInterest }
                  valueLabelFormat={ `${commonFunctions.thousandFormatter( totalBalanceInterest )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'existing_credit_card_balance', 'slider' ) }
                />
              </div>
              <div className={ classes.annuvalIncomeGrowthInput }>
                $
                <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ totalBalanceInterest } onValueChange={ ( e ) => updateValue( e, '', 'existing_credit_card_balance', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ interestRateValues }
                  min={ 0 }
                  max={ 30 }
                  step={ 0.05 }
                  value={ totalWeight }
                  valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( totalWeight ).toFixed( 2 ) )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'creditcard_interest_rate', 'slider' ) }
                />
              </div>
              <div className={ classNames( classes.annuvalIncomeGrowthInput, classes.relativePosition ) }>
                <span className={ classes.noDisplay }>$</span>
                <NumberFormat
                  customInput={ TextField }
                  value={totalWeight}
                  decimalScale={isFocus!=='creditcard_interest_rate'?2:5}
                  fixedDecimalScale={isFocus!=='creditcard_interest_rate'}
                  onValueChange={ ( e ) => updateValue( e, '', 'creditcard_interest_rate', 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('creditcard_interest_rate') } }
                  onBlur={emptyIsFocus} />
                <span className={ classes.perSentSymbol }>%</span>
              </div>
            </div>
          </div>
          <div className={ classes.AnnualIncomeGrowth }>
            <div>
              <p aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Months Until Repayment Begins: Student Loans' ) }><span>Ideal Repayment Schedule (in Months)</span></p>
              <div className={ classes.annuvalIncomeGrowthGraph }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ sliderValues.idealRepayment() }
                  min={ 0 }
                  max={ 78 }
                  step={ 1 }
                  value={ repaymentValue }
                  valueLabelFormat={ `${commonFunctions.mosFormatter( repaymentValue )}` }
                  onChange={ ( e, value ) => updateValue( e, value, 'ideal_repayment_schedule', 'slider' ) }
                />
              </div>
              <div className={ classes.loanInput }>
                <span className={ classes.inputDollar }>&nbsp;</span>
                <NumberFormat
                  customInput={ TextField }
                  thousandSeparator
                  value={ repaymentValue }
                  decimalScale={isFocus!=='ideal_repayment_schedule'?2:5}
                  fixedDecimalScale={isFocus!=='ideal_repayment_schedule'}
                  onValueChange={ ( e ) => updateValue( e, '', 'ideal_repayment_schedule', 'number' ) }
                  onFocus={ ( e ) => { e.target.select(); setIsFocus('ideal_repayment_schedule') } }
                  onBlur={emptyIsFocus} />
                <span className={ classes.inputPercent }>
                  {' '}
                  {' ' }
                  months
                </span>
              </div>
              <div className={ `${classes.newInput} ${classes.newInputRightNew}` }>
                <span>
                  {( repaymentValue / 12 ).toFixed( 2 )}
                  {' '}
                  years
                </span>
              </div>
            </div>
          </div>

          <div className={ classes.loanRow }>
            <div className={ classes.loanBalance }>
              <div className={ classes.loanText }>
                <p><span>&nbsp;</span></p>
              </div>
              <div className={ classes.principalIntrest }>
                <p><b>Credit Card Monthly Payment (Principal & Interest)</b></p>
              </div>
              <div className={ classes.loanInput }>
                <p>
                  <span className={ classes.loanNumber }>
                    <b>
                      {moduleServices.module18Data.existingCreditCardMonthlyPayment !== undefined ? ( <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingCreditCardMonthlyPayment } prefix={ moduleServices.module18Data.existingCreditCardMonthlyPayment >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingCreditCardMonthlyPayment < 0 && ')' } />
                      ) : ( '$0' )}
                    </b>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className={ classes.loanRow }>
            <div className={ classes.loanBalance }>
              <div className={ classes.loanText }>
                <p><span>&nbsp;</span></p>
              </div>
              <div className={ classes.principalIntrest }>
                <p><b>Total Repayment Amount (Principal & Interest)</b></p>
              </div>
              <div className={ classes.loanInput }>
                <p>
                  <span className={ classes.loanNumber }>
                    <b>
                      {moduleServices.module18Data.existingCreditCardTotalPrincipalInterest !== undefined ? ( <NumberFormat decimalScale={ 0 } className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.existingCreditCardTotalPrincipalInterest } prefix={ moduleServices.module18Data.existingCreditCardTotalPrincipalInterest >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.existingCreditCardTotalPrincipalInterest < 0 && ')' } />
                      ) : ( '$0' )}
                    </b>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="popup-model loan-popup-model"
        open={creditCardInterestRate}
        onClose={handleCancel}
        aria-labelledby="note-dialog-title"
        aria-describedby="note-dialog-description"
      >
        <div className="model-content">
          <div className="modal-header">
            <button onClick={handleCancel}>X</button>
            <h3>Credit Card Weighted Average Interest Rate</h3>
          </div>
          <div className={classes.mobileScroll}>
            <div className={ `${classes.moduleBodyScroll} "model-body"` } >
              <div className={classes.moduleBodyhead}>
                <div className={classes.BoxOne}>
                  <h4>&nbsp;</h4>
                </div>
                <div className={classes.BoxTwo}>
                  <h4>Balance</h4>
                </div>
                <div className={classes.BoxThree}>
                  <h4>% of Total</h4>
                </div>
                <div className={classes.BoxFour}>
                  <h4>Interest Rate</h4>
                </div>
                <div className={classes.BoxFive}>
                  <h4>Weighted Interest Rate</h4>
                </div>
              </div>
              
              { 
                weightedAverageRate.map((l, index) => (
                  <div className={classes.moduleBody}>
                    <div className={classes.BoxOne}>
                      <label>Credit Card {index+1}</label>
                    </div>
                    <div className={classes.BoxTwo}>
                      <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ l.balance } onValueChange={ ( e ) => handleChange( e, index, 'balance' ) } onFocus={ ( e ) => { e.target.select(); } }/>
                      <span className={classes.dollerCount}>$</span>
                    </div>
                    <div className={classes.BoxThree}>
                      <label>{<NumberFormat decimalScale={ 2 } fixedDecimalScale displayType="text" allowNegative={ false } value={ l.total_balance_rate } prefix={ l.total_balance_rate < 0 && '(' } suffix={ l.total_balance_rate >= 0 ? '%' : '%)' } />}</label>
                    </div>
                    <div className={classes.BoxFour}>
                      <NumberFormat
                        customInput={ TextField }
                        value={ l.interest_rate }
                        decimalScale={isFocus!=='interest_rate'?2:5}
                        fixedDecimalScale={isFocus!=='interest_rate'}
                        onValueChange={ ( e ) => handleChange( e, index, 'interest_rate' ) }
                        onFocus={ ( e ) => { e.target.select(); setIsFocus('interest_rate') } }
                        onBlur={emptyIsFocus} />
                      <span className={classes.dollerPercenatge}>%</span>
                    </div>
                    <div className={classes.BoxFive}>
                      <label>{<NumberFormat decimalScale={ 2 } fixedDecimalScale displayType="text" allowNegative={ false } value={ l.total_interest_rate } prefix={ l.total_interest_rate < 0 && '(' } suffix={ l.total_interest_rate >= 0 ? '%' : '%)' } />}</label>
                    </div>
                  </div>
                ))
              }
              <div className={ `${classes.moduleBody} ${classes.modelBodyFooter}` }>
                <div className={ `${classes.BoxOne} ${classes.BoxOneFooter}` }>
                  <label>Total</label>
                </div>
                <div className={ `${classes.BoxTwo} ${classes.textRight}` }>
                  <label>
                    {totalBalanceInterest !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalBalanceInterest } prefix={ totalBalanceInterest >= 0 ? '$' : '($' } suffix={ totalBalanceInterest < 0 && ')' } />
                    ) : ( '$0' )}
                  </label>
                </div>
                <div className={classes.BoxThree}>
                  <label>
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } value={ 100.00 } suffix={'%'} />
                  </label>
                </div>
                <div className={ `${classes.BoxFour} ${classes.textRight}` }>
                  <label>--</label>
                  </div>
                <div className={classes.BoxFive}>
                  <img src={downArrow} alt="downArrow" />
                </div>
              </div>
              <div className={classes.boxTotal}>
                <p>Weighted Average Interest Rate
                  <span>
                    {totalWeight !== undefined ? ( <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } value={ totalWeight } prefix={ totalWeight < 0 && '(' } suffix={ totalWeight >= 0 ? '%' : '%)' } />
                    ) : ( '0%' )}
                  </span></p>
              </div>
            </div>
          </div>
          <div className={classes.buttonFooter}>
            <button className={classes.clearDatbtn} onClick={() => {
              setWeightedAverageRate([
                { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
                { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
                { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
                { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
                { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
                { balance: 0, total_balance_rate: 0, interest_rate: 0,  total_interest_rate: 0,},
              ])
              setTotalBalanceInterest(handleAssestObject.existing_credit_card_balance);
              setTotalWeight(handleAssestObject.creditcard_interest_rate)
            }}>Clear Data</button>
            <button className={classes.okbtn} onClick={()=> {
              setPreviousWeightedAverageRate(weightedAverageRate);
              setPTotalBalanceInterest(totalBalanceInterest);
              setPTotalWeight(totalWeight);
              setCreditCardInterestRate(!creditCardInterestRate)}
            }>Ok</button>
            <button className={classes.cancelbtn} onClick ={handleCancel}>Cancel</button>
          </div>
        </div>
      </Dialog>
    </div>

  );
};

ExistingCreditCardBalance.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( ExistingCreditCardBalance );
