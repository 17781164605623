const data = [
    {
      title: "Vacation",
      children: [
        {
          label: 'Start year',
          type: 'date',
          prefix: '',
          suffix: '',
          key: "start_year"
        },
        {
          label: 'End year',
          type: 'date',
          prefix: '',
          suffix: "",
          key: "end_year"
        },
        {
            label: 'Annual Cost',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "yearly_cost"
          }
      ]
    },
    {
      title: "Special Purchase 1",
      children: [
        {
          label: 'Start year',
          type: 'date',
          prefix: '',
          suffix: "",
          key: "second_start_year"
        },{
          label: 'End year',
          type: 'date',
          prefix: '',
          suffix: "",
          key: "second_end_year"
        },
        {
            label: 'Annual Cost',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "second_yearly_cost"
          }
      ]
    },
    {
        title: "Special Purchase 2",
        children: [
          {
            label: 'Start year',
            type: 'date',
            prefix: '',
            suffix: '',
            key: "third_start_year"
          },{
            label: 'End year',
            type: 'date',
            prefix: '',
            suffix: "",
            key: "third_end_year"
          },
          {
              label: 'Annual Cost',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "third_yearly_cost"
            }
        ]
      },
      {
        title: "Summary",
        children: [
          {
            label: 'Total Amount Spent',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "vacation_total_amount"
          }
        ]
      }
  ]; 
  
  export default data;