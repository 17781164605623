import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const MonetaryPolicy = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Monetary Policy</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/monetary-policy.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Monetary Policy
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/monetary-policy-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Actions by the Federal Reserve that impact the money
                          supply are known as monetary policy. Before we get to
                          that and before we get to the tools at the Fed&apos;s
                          disposal, let&apos;s define money supply.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p className={classes.grayBox}>
                          Money supply is the total amount of money in
                          circulation in the United States. Money refers to
                          cash, coins, credit and balances in bank accounts that
                          belong to households and businesses.
                        </p>
                        <p>
                          Credit is the most important form of money that the
                          Federal Reserve can impact. Let&apos;s look at a
                          real-life example of how the Fed&apos;s monetary
                          policy can impact your personal finances.
                        </p>
                        <p>
                          <strong>Reserve requirements</strong>
                          <br />
                          The reserve requirement is how much a bank must hold
                          in cash reserves as a percentage of deposits. For
                          example, if you deposit $10,000 at a bank and the
                          reserve requirement is 10%, the bank must hold $1,000
                          in cash reserves. What happens to the other $9,000?
                          The bank lends it to other people and businesses
                          looking for loans.
                        </p>
                        <p>
                          Continuing with the example, what happens if the Fed
                          raises the reserve requirement to 20%? When you
                          deposit $10,000 at a bank, the bank must now hold
                          $2,000 in cash reserves. The bank can only lend $8,000
                          now. Now imagine this on a nationwide scale and in the
                          trillions of dollars. If the Fed raises the reserve
                          requirement, do you think that would increase or
                          decrease economic activity?
                        </p>
                        <p>
                          Economic activity would most likely decrease because
                          banks can lend less money. If banks lend less money,
                          that means fewer mortgages available for people buying
                          houses. When people stop buying houses, builders build
                          fewer houses and employ fewer people. With fewer
                          people employed, people would spend less money on
                          goods and services. Economic activity would decrease.
                        </p>
                        <p>
                          Vice versa. If the Fed lowered the reserve
                          requirement, banks could lend more money to businesses
                          and consumers and the economy would most likely grow.
                          The Fed has many tools at its disposal to try and spur
                          growth and also control growth.
                        </p>
                        <p>
                          Why would the Fed want to discourage economic growth?
                          Isn&apos;t economic growth always good?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
MonetaryPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MonetaryPolicy);
