import * as moduleServices from '../calculations/modules-services';


function cashFlowStatementGraph() {

  var cashFlowCompleteData = moduleServices.cashFlowCompleteData();
  let graphsData = {};
      //Beginning Cash Balance
      graphsData[1] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },

        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Beginning Cash Balance Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Total Cash from Operating Activities 
      graphsData[2] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },

        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Total Cash from Operating Activities Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Total Cash from Investing Activities  
      graphsData[3] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },

        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Total Cash from Investing Activities Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Total Cash from Financing Activities  
      graphsData[4] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },

        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Total Cash from Financing Activities Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Net Change in Cash  
      graphsData[5] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 30,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },

        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Net Change In Cash Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Ending Cash Balance  
      graphsData[6] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 23,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },
        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },

        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Ending Cash Balance Graph'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }
      
      //Short-Term Investments Balance  
      graphsData[7] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 23,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },

        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Short-Term Investment Balance'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      //Long-Term Investments Balance  
      graphsData[8] = {
        chart: {
          renderTo: "container",
          defaultSeriesType: 'bar',
          type: 'column',
          width: 100,
          height: 23,
          spacingBottom: -8,
          spacingTop: -10,
          spacingLeft: 0,
          spacingRight: 0,
          verticalAlign: 'top',
          top: 0,
          plotBackgroundColor: '#DDDAD6'
        },
        exporting: {
          enabled: false
        },

        title: {
          text: null
        },
        xAxis: {
          labels:
          {
            enabled: false
          }
        },
        yAxis: {
          gridLineWidth: 0,
          title: {
            text: null
          },
          labels:
          {
            enabled: false
          }
        },

        legend: {
          enabled: false
        },
        credits: {
          verticalAlign: 'top',
          enabled: false
        },
        plotOptions: {
          allowPointSelect: false,
          series: {
            negativeColor: '#BF0000'
          }
        },

        tooltip: {
          enabled: false
        },
        series: [{
          data: cashFlowCompleteData['Long-Term Investment Balance'],
          color: '#2F75B5',
          pointWidth: 5
        }]
      }

      return graphsData; 
    }
    export default cashFlowStatementGraph;
