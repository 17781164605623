import tutorialBanner from '../../assets/img/tutorials/tutorial_banner.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  bannerImage: {
    backgroundImage: `url(${tutorialBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'inherit',
    position: 'relative',
    backgroundPosition: 'center top',
    width: '100%',
    height: '100%',
    '&:after': {
      content: '""',
      background: 'rgba(0,0,0,.3)',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
    },
  },
  bannerCaption: {
    bottom: 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
    WebkitTransform: 'translateY(-50%)',
    MozTransform: 'translateY(-50%)',
    Otransform: 'translateY(-50%)',
    Mstransform: 'translateY(-50%)',
    zIndex: ' 99',
    textAlign: 'center',
    position: 'absolute',
    left: '0',
    right: '0',
    '@media (max-width: 767px)': {
      padding: '0px 15px',
    },
    '& h2': {
      fontFamily: '"MuseoSans-300"',
      fontSize: '60px',
      lineHeight: '78px',
      color: '#fff',
      margin: '0',
      textTransform: 'capitalize',
      fontWeight: '500',
    },
  },
  bannerBlock: {
    height: '53vh',
    position: 'relative',
    [theme.breakpoints.down( 'md' )]: {
      height: '445px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      height: '330px',
    },
  },
  modulesBlock: {
    '& h2': {
      textAlign: 'center',
      margin: '40px auto 20px',
      display: 'table',
      fontSize: '30px',
      color: '#0069aa',
    },
  },

  iconsRow: {
    padding: '20px 0 30px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0 0px',
    },
    '& ul': {
      display: 'flex',
      verticalAlign: 'top',
      justifyContent: 'center',
      margin: '0',
      padding: '0',
      [theme.breakpoints.down( 'sm' )]: {
        flexWrap: 'wrap',
      },
      '& li': {
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '0 8px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'sm' )]: {
          padding: '0 4px',
        },
        '& a': {
          display: 'block',
          transition: 'all 0.5s ease 0s',
          MozTransition: 'all 0.5s ease 0s',
          WebkitTransition: 'all 0.5s ease 0s',
          border: '0',
          outline: '0',
          '& img': {
            width: '50px',
            height: 'auto',
            verticalAlign: 'middle',
            border: '0',
            [theme.breakpoints.down( 'sm' )]: {
              width: '40px',
            },
          },
        },
      },
    },
  },
  modulesList: {
    padding: '0 0 0 42px',
    marginBottom: '10px',
    '& li': {
      listStyleType: 'decimal',
      color: '#0069aa',
      padding: '0 0 20px',
      fontSize: '15px',
      lineHeight: '15px',
      fontWeight: '400',
      fontFamily: '"MuseoSans-300"',
      [theme.breakpoints.down( 'sm' )]: {
        lineHeight: '18px',
      },
    },
  },
  modulesImage: {
    maxHeight: '557px',
    top: '0',
    overflow: 'hidden',
    position: 'relative',
    padding: '0px 15px',
    cursor: 'pointer',
    '& figure': {
      display: 'block',
      margin: '0px',
      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
      },
    },
  },
  careerPathContent: {
    padding: '30px 0 0 0',
  },
  videoIcon: {
    position: 'absolute',
    top: '0',
    maxWidth: '50px',
    left: '0',
    right: '0',
    margin: 'auto',
    bottom: '0',
    display: 'block',
    width: '100%',
    height: 'auto',
    cursor: 'pointer',
  },
  careerRow: {
    padding: '0px 0px 60px 0px',
    '@media (max-width: 767px)': {
      padding: '0px 0px 40px 0px',
    },
  },
  mainTitle: {
    margin: '0',
    padding: '5px 0px 8px 0px',
    display: 'inline-block',
    fontSize: '36px',
    lineHeight: '36px',
    fontFamily: '"MuseoSans-100"',
    color: '#0069aa',
    cursor: 'pointer',
    [theme.breakpoints.down( 'md' )]: {
      fontSize: '30px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '21px',
    },
    '&:hover': {
      color: '#f89b22',
    },
  },
  subTitle: {
    margin: '0px 0px 15px 0px',
    '& span': {
      color: '#f89b22',
      lineHeight: '20px',
      margin: '0',
      fontSize: '20px',
      fontFamily: '"MuseoSans-500"',
      fontWeight: '400',
      display: 'inline-block',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: '#333',
        textDecoration: 'underline',
      },
    },
    '& a': {
      color: '#f89b22',
      lineHeight: '20px',
      margin: '0',
      fontSize: '20px',
      fontFamily: '"MuseoSans-500"',
      fontWeight: '400',
      display: 'inline-block',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: '#333',
        textDecoration: 'underline',
      },
    },
  },
  toolsRow: {
    '& h2': {
      textAlign: 'center',
      margin: '40px auto 20px',
      display: 'table',
      fontSize: '30px',
      color: '#0069aa',
    },
  },
  toolsContent: {
    padding: '30px 0px 60px 0px',
    '@media (max-width: 767px)': {
      padding: '0px 0px 50px 0px',
    },
  },
  financeImage: {
    maxHeight: '557px',
    top: '0',
    overflow: 'hidden',
    position: 'relative',
    padding: '0px 15px',
    '& figure': {
      display: 'block',
      margin: '0px',
      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
        border: '1px solid #cab488',
      },
    },
  },
  financeList: {
    padding: '0 3px 0 27px',
    marginBottom: '10px',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 3px 0 15px',
    },
    '& li': {
      listStyle: 'none',
      display: 'flex',
      textAlign: 'left',
      lineHeight: '20px',
      color: '#0069aa',
      padding: '0 0 20px',
      fontSize: '15px',
      fontWeight: '400',
      '& span': {
        minWidth: '50px',
        paddingLeft: '10px',
        // cursor:'pointer',
      },

    },
  },
  financeCareer: {
    padding: '0 3px 0 27px',
    marginBottom: '10px',
    '& li': {
      listStyle: 'none',
      display: 'flex',
      textAlign: 'left',
      lineHeight: '20px',
      color: '#0069aa',
      padding: '0 0 20px',
      fontSize: '15px',
      fontWeight: '400',
      '& span': {
        minWidth: '50px',
        paddingLeft: '10px',
        cursor: 'pointer',
      },
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
  tutorialsIntro: {
    cursor: 'pointer',
    '&:hover': {
      color: '#f89b22',
      textDecoration: 'underline',
    },
  },
  tabsSection: {
    margin: '80px 0 0px',
    [theme.breakpoints.down( 'md' )]: {
      margin: '40px 0 0px',
    },
  },
  tabsRow: {
    listStyle: 'none',
    padding: '0',
    width: '100%',
    textAlign: 'left',
    maxWidth: '1100px',
    display: 'inline-block',
    marginBottom: '0',
    [theme.breakpoints.down( 'md' )]: {
      textAlign: 'center',
    },
    '& li': {
      minWidth: '180px',
      height: '40px',
      padding: '0 20px',
      margin: '0 20px 0 0',
      color: '#888888',
      lineHeight: '40px',
      cursor: 'pointer',
      display: 'inline-block',
      textAlign: 'center',
      border: '1px solid #b7b7b7',
      borderBottom: '0',
      fontSize: '20px',
      borderRadius: '15px 15px 0px 0px',
      position: 'relative',
      listStyle: 'none',
      outline: 'none',
      [theme.breakpoints.down( 'md' )]: {
        minWidth: '150px',
        fontSize: '18px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        minWidth: '80px',
        fontSize: '14px',
        marginRight: '5px',
        padding: '0 10px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        minWidth: '100%',
        marginRight: '0px',
        borderRadius: '0',
        padding: '5px 20px',
        boxSizing: 'border-box',
        display: 'table',
      },
      '&:last-child': {
        marginRight: '0px',
      },
    },
  },
  tutorialsBlock: {
    textAlign: 'center',
    borderBottom: '1px solid #000',
  },
  financialBlock: {
    '& h2': {
      textAlign: 'center',
      margin: '40px auto 20px',
      display: 'table',
      fontSize: '30px',
      color: '#0069aa',
    },
    '& h3': {
      cursor: 'default',
      '&:hover': {
        color: '#0069aa',
      },
    },
  },
  activeModule: {
    color: '#0069aa !important',
    borderColor: '#231f20 !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '2px',
      border: '0',
      background: '#fbfdfd',
      left: '0',
      bottom: '-1px',
    },

  },
  unActiveModule: {
    '&:hover': {
      color: '#0069aa',
    },
  },
  pendinglistPopup: {
    padding: '0px',
    paddingTop: '0px',
    boxShadow: 'none',
    '& div': {
      maxWidth: '100%',
      padding: '0px',
      paddingTop: '0px',
      boxShadow: 'none',
      overflowY: 'inherit',
    },
    '& > div > div': {
      background: 'transparent',
      boxShadow: 'none',
      overflow: 'inherit',
      [theme.breakpoints.down( 'sm' )]: {
        overflow: 'hidden',     
      },
    },
  },
  iframe: {
    border: '0px',
  },
  videoPopup: {
    paddingTop: '0px',
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  popupClose: {
    top: '-10px',
    background: '#fff',
    borderColor: '#fff',
    color: '#000',
    width: '26px',
    right: '-10px',
    height: '26px',
    fontSize: '13px',
    lineHeight: '26px',
    outline: 'none',
    zIndex: '99',
    float: 'none',
    position: 'absolute',
    opacity: '1',
    border: '1px solid #f59331',
    textShadow: 'none',
    borderRadius: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down( 'sm' )]: {
      top:'0px',
      right: '0px',
    },
  },
  tooltipScoreInfo: {
    height: 'auto',
    textAlign: 'left',
    fontSize: '15px',
    maxWidth: '350px',
    width: '350px',
    padding: '0px',
    fontWeight: '400',
    lineHeight: '1.42857143',
  },
  tooltipInfo: {
    fontSize: '12px !important',
    padding: '3px 8px !important',
    borderRadius: '4px',
    fontWeight: '400',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
  },
} );

export default styles;