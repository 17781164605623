
const styles = ( theme ) => ( {
  container: {
    maxWidth: 'calc(1170px - 30px)',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  aboutBanner:{
    backgroundColor: '#0069aa',
    padding: '50px 0',
    textAlign: 'center',
    position: 'relative',
  },
  bannerContent:{
    position: 'relative',
    '& figure':{
      width: '89%',
      margin: '0 auto',
      '& img':{
        marginTop: '-30px',
        paddingLeft: '20px',
        width: '100%',
        [theme.breakpoints.down( 'sm' )]: {
          marginTop: '0px',
          paddingLeft: '0px',
        }
      }
    }
  },
  bannerCaption:{
    position: 'absolute',
    width: '100%',
    left: '0',
    top: '0',
    transform: 'translateY(20%)',
    [theme.breakpoints.down( 'sm' )]: {
      transform: 'translateY(0%)',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }
  },
  aboutLogo:{
    marginBottom: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      marginBottom: '0px',
    },
    '& img':{
      maxWidth: '200px',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '150px',
      }
    }
  },
  bannerTitle:{
    '& h2':{
      margin: '50px 0px',
      fontSize: '54px',
      color: '#fff',
      lineHeight: '70px',
      fontWeight: '400',
      marginRight: '0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
        lineHeight: '40px',
        margin: '10px 0px',
      },
      '@media (max-width: 374px)': {
        fontSize: '24px',
        lineHeight: '30px',
      },
      '& span':{
        display: 'block',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'initial',
        }
      }
    }
  },
  empowerInnovation:{
    margin: '60px 0 70px',
    '& h2':{
      color: '#333',
      fontWeight: '400',
      marginBottom: '40px',
      fontSize: '36px',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      }
    },
    '& p':{
      color: '#333',
      fontSize: '24px',
      fontFamily: 'MuseoSans-100',
      fontWeight: '400',
      margin: '0px 0px 30px',
      lineHeight: '36px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '28px',
      },
      '& b':{
        fontWeight: '400',
        fontSize: '30px',
        fontFamily: 'MuseoSans-300',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '22px',
        }
      }
    },
    '& h4':{
      color: '#333',
      fontSize: '24px',
      fontWeight: '400',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      }
    }
  },
  awarenessImages:{
    marginBottom: '70px',
  },
  awarenessImagesBox:{
    boxShadow: '0 0 30px #ccc',
    background: '#fff',
    maxWidth: '80%',
    margin: '0 auto',
    float: 'none',
    padding: '30px',
    borderRadius: '15px',
  },
  imageBox:{
    '& img':{
      width: '100%',
    },
    '& p':{
      textAlign: 'center',
      textTransform: 'uppercase',
      marginTop: '10px',
    }
  },
  imageMargin:{
    marginTop: '30px',
    '& p':{
      marginBottom: '0px',
    }
  },
  inkwryTeam:{
    textAlign: 'center',
    '& > div':{
      maxWidth: '52%',
      margin: '0 auto',
      paddingBottom: '50px',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
      }
    },
    '& h3':{
      color: '#333',
      margin: '0',
      fontSize: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '24px',
      }
    },
    '& h4':{
      fontSize: '24px',
      color: '#333',
      marginBottom: '30px',
      marginTop: '10px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      }
    },
    '& p':{
      color: '#333',
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      fontWeight: '400',
      textAlign: 'left',
      lineHeight: '36px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      }
    }
  },
  leaderImage:{
    margin: '70px 0',
    [theme.breakpoints.down( 'md' )]: {
      margin: '40px 0 20px',
    },
    '& img':{
      maxWidth: '280px',
      boxShadow: '0 0 30px #ccc',
      width: '100%',
    }
  },
  teamMembers:{
    marginBottom: '50px',
  },
  teamRow:{
    margin: '70px auto',
    [theme.breakpoints.down( 'md' )]: {
      width: '100% !important',
      margin: '0px auto',
    }
  },
  teamMembersLeft:{
    '& img':{
      boxShadow: '0 0 30px #ccc',
      width: '100%',
    }
  },
  teamMembersRight:{
    paddingLeft: '50px',
    [theme.breakpoints.down( 'md' )]: {
      paddingLeft: '0px',
    },
    '& h2':{
      color: '#333',
      fontWeight: 'normal',
      margin: '0',
      fontSize: '30px',
      fontFamily: 'MuseoSans-300',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
      },
      '@media (max-width: 374px)': {
        fontSize: '25px',
      }
    },
    '& h3':{
      color: '#333',
      fontWeight: '400',
      margin: '8px 0',
      fontSize: '24px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
      }
    },
    '& p':{
      color: '#333',
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      fontWeight: '400',
      textAlign: 'left',
      lineHeight: '36px',
      margin: '20px 0 10px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      }
    }
  }
} );

export default styles;
