import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { Dialog } from '@material-ui/core';
import {
  Button,
  Typography,
} from '@material-ui/core';
import { parseGraphQLErrorMessage } from '../../../../utilities/commonFunctions';
import styles from '../styles';
import globalStyles from '../../../layout/globalStyles';
// import UserUtils from '../../../../utilities/userUtils';
import * as commonFunctions from '../../../../utilities/commonFunctions';
import combineStyles from '../../../../utilities/combineStyles';
import Logo from '../../../../assets/img/login/logo.png';
import Fail from '../../../../assets/img/login/fail.png';
import Pass from '../../../../assets/img/login/pass.png';
import Pageloader from '../../../ui/pageloader';
import { compose } from 'recompose';

const RESET_PASSWORD = loader('../../../../graphql/schema/auth/reset-password.graphql');
const TOKEN_CHECK = loader('../../../../graphql/schema/auth/reset-password-token.graphql');

const ResetPasswordForm = (props) => {
  const {
    classes, history
  } = props;

  const [resetPasswordError, setResetPasswordError] = useState('');
  const [resetTokens, setResetTokens] = useState({ access: '' });
  const [minLength, setMinLength] = useState(false);
  const [alphabets, setAlphabets] = useState(false);
  const [numeric, setNumeric] = useState(false);
  const [matchPassword, setMatchPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [validOpen, setValidOpen] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const path = window.location.pathname;
    const token = path.split('/');
    setResetTokens({ access: token[2] });
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      setMinLength(false);
      setNumeric(false);
      setAlphabets(false);
      setMatchPassword(false);
    }
    if (values.password) {
      if (values.password.length > 8) {
        setMinLength(true);
      } else {
        setMinLength(false);
      }
      const lowerCase = /[a-z]/;
      const UpperCase = /[A-Z]/;
      if (values.password !== 'undefined' && values.password.match(lowerCase) && values.password.match(UpperCase)) {
        setAlphabets(true);
      } else {
        setAlphabets(false);
      }
      if (!commonFunctions.validateNumeric(values.password)) {
        setNumeric(true);
      } else {
        setNumeric(false);
      }
    } else {
      setAlphabets(false);
    }

    if (values.password && values.confirmPassword) {
      if (values.password === values.confirmPassword) {
        setMatchPassword(true);
      } else {
        setMatchPassword(false);
      }
    } else {
      setMatchPassword(false);
    }

    return errors;
  };

  const processErrors = (errors) => {
    if (!errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`reset-password-error-${Math.random()}`}>{message}</span>
      ));

      setResetPasswordError(formatedErrors);
    } else {
      const typeError = parseGraphQLErrorMessage(errors);
      setResetPasswordError(typeError);
    }
  };

  const [submitResetPassword, { loading: resetPasswordLoading, error }] = useMutation(RESET_PASSWORD, {
    onCompleted({
      updateForgottenPassword: {
        status
      }
    }) {
      if(status === 'PASSWORD_UPDATED'){
        setOpen(true);
      }else{
        setOpen(false);
      }     
    },
    onError(errors) {
      processErrors(errors);
    },
  });

  const [tokenCheck] = useMutation(TOKEN_CHECK, {
    onCompleted({
      checkToken: {
        status,
        message
      }
    }) {
      if (status === false) {
        setValidOpen(true)
      } else {
        setEmail(message)
      }
    }
  })



  const onSubmit = (values) => {
    const { access: accessToken } = resetTokens;
    if (email !== null && email !== '' && email !== undefined) {
      submitResetPassword({
        variables: {
          data: {
            token: accessToken,
            password: values.password,
            email: email,
          },
        },
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    history.push('/login');
  }

  const handleValidClose = () => {
    setValidOpen(false);
    history.push('/');
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false);
        history.push('/login')
      }, 5000);
    }
  }, [open]);

  useEffect(() => {
    const { access: accessToken } = resetTokens;
    tokenCheck({
      variables: {
        token: accessToken,
      },
    })
  }, [resetTokens])

  return (
    <Typography variant="body1" component="div" className={`${classes.loginBg} ${classes.resetPassword}`}>
      {resetPasswordLoading && <Pageloader loading={resetPasswordLoading} />}
      <div>

        <div className={classNames(classes.loginForm, classes.resetPass)}>
          <div className={classes.loginFormHeader}>
            <img src={Logo} alt="" />
          </div>
          <Form
            validate={validate}
            onSubmit={onSubmit}
            render={({ handleSubmit }) => (
              <form
                className={classes.formContact}
                onSubmit={handleSubmit}
                noValidate
              >
                <div className={classNames(classes.loginFields, classes.resetPassFields)}>
                  <div>
                    <Typography variant="h3">Reset Password</Typography>
                    <div className={classes.formGroup}>
                      <Field
                        id="password"
                        placeholder="New Password"
                        type="password"
                        name="password"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <Field
                        id="confirmPassword"
                        placeholder="Confirm New Password"
                        type="password"
                        name="confirmPassword"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        component={TextField}
                      />
                    </div>
                    {
                      (resetPasswordError || error) && (
                        <Typography >
                          {resetPasswordError}
                        </Typography>
                      )
                    }
                    <div className={`${classes.formGroup} ${classes.resetPasswordBtn}`}>
                      <Button
                        variant="contained"
                        type="submit"
                        disabled={(!minLength || !alphabets || !numeric || !matchPassword)}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </div>
                  <div className={classes.confirmValidation}>
                    <ul>
                      <li>
                        <img src={minLength ? Pass : Fail} alt="" />
                        {' '}
                        Include at least 8 characters
                      </li>
                      <li>
                        <img src={alphabets ? Pass : Fail} alt="" />
                        {' '}
                        Include upper and lower case characters
                      </li>
                      <li>
                        <img src={numeric ? Pass : Fail} alt="" />
                        {' '}
                        Include at least 1 number
                      </li>
                      <li>
                        <img src={matchPassword ? Pass : Fail} alt="" />
                        {' '}
                        Passwords match
                      </li>
                    </ul>
                  </div>

                </div>
              </form>
            )}
          />
        </div>

      </div>
      <Dialog
        open={open}
        className={classes.addGroup}
      >
        <div className={classes.popupHeader}>
          <div aria-hidden="true" onClick={handleClose}>
            <i className="la la-times" />
            <h3>Reset password</h3>
          </div>
        </div>
        <div className={classes.successText}>
          <p>Your password has been reset successfully</p>
        </div>
        <div className={classes.popupFooter}>
          <Button onClick={handleClose}>Close</Button>
        </div>
      </Dialog>
      <Dialog
        open={validOpen}
        className={classes.addGroup}
      >
        <div className={classes.popupHeader}>
          <div aria-hidden="true" onClick={handleValidClose}>
            <i className="la la-times" />
            <h3>Reset password</h3>
          </div>
        </div>
        <div className={classes.resetText}>
          <p>This page is outdated and cannot be used anymore. Please click on the link in the most recent email from Inkwiry Support to change your password.</p>
        </div>
        <div className={classes.popupFooter}>
          <Button onClick={handleValidClose}>Close</Button>
        </div>
      </Dialog>
    </Typography>
  );
};

ResetPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(globalStyles, styles);

const enhance = compose(
  withStyles(combinedStyles),
  withRouter
)

export default enhance(ResetPasswordForm);
