import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Table,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();

const CheckingSavings = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/checking-savings.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Checking vs Savings Account</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/checking-v-savings.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }><b>What&apos;s the difference?</b></p>
                        <p>
                          Checking accounts and savings accounts are both bank accounts that safely store your money for later use. You need both accounts, but what&apos;s the difference? Explore the table below and find out the major distinctions.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.savingsTable }>
                      <Table>
                        <thead>
                          <tr>
                            <th>&nbsp;</th>
                            <th>Checking Account</th>
                            <th>Savings Account</th>
                            <th>High-Yield Savings</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Designed for everyday spending</td>
                            <td>Yes</td>
                            <td>No</td>
                            <td>No</td>
                          </tr>
                          <tr>
                            <td>Designed for short and long-term saving</td>
                            <td>No</td>
                            <td>Yes</td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>Guaranteed by government up to $250k</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                          </tr>
                          <tr>
                            <td>Debit card included</td>
                            <td>Usually</td>
                            <td>No</td>
                            <td>No</td>
                          </tr>
                          <tr>
                            <td>Restrictions on your money</td>
                            <td>Very few</td>
                            <td>More*</td>
                            <td>More*</td>
                          </tr>
                          <tr>
                            <td><b>Earns interest</b></td>
                            <td><b>Very little</b></td>
                            <td><b>Very little</b></td>
                            <td><b>Higher</b></td>
                          </tr>
                          <tr>
                            <td>Fees</td>
                            <td>More fees</td>
                            <td>Less fees</td>
                            <td>Less fees</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        <i><small>* Up to six transfers or withdrawals a month before fees (varies by bank, but federal law limits the number of monthly transactions to six before fees)</small></i>
                        <p>
                          Since you use money in your checking more frequently than your savings via your debit card, checks, and ATM withdrawals, banks charge more fees and pay less interest on money in your checking account.
                          On the other hand, you make way fewer transactions in your savings account so banks can pay you more interest on your money and charge you fewer fees. The number one reason to keep more money in a savings account is to earn interest while you save money for emergencies or longer-term needs.
                        </p>
                        <p>
                          A high-yield savings account offers a much higher interest rate than standard savings accounts, allowing you to grow your money faster without taking any additional risk. How much higher? The national average interest rate for a standard savings account is
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.fdic.gov/regulations/resources/rates/" target="_blank">roughly 0.09%.</a>
                          {' '}
                          A high-yield savings account can be
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.nerdwallet.com/best/banking/savings-accounts?trk=nw_gn2_4.0" target="_blank">22x higher</a>
                          , or 2.00% annually!
                          {' '}
                          <b>Grow your money faster without taking any additional risk.</b>
                        </p>
                      </div>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>Checking and savings accounts charge fees for maintaining your account. Some common types of fees and how to avoid paying them are below:</p>
                          <ul>
                            <li>
                              <b>Annual fee:</b>
                              {' '}
                              one-time fee of $25 that the bank charges for managing your account. This fee can be avoided by selecting an account with no annual fee or meeting a minimum balance requirement.
                            </li>
                            <li>
                              <b>Monthly maintenance fees:</b>
                              {' '}
                              in addition to the annual fee, up to $10 monthly fee that the bank charges for managing your account. This fee can be avoided by maintaining the minimum balance, having a checking and savings account with your bank, or selecting an account with no maintenance fees.
                            </li>
                            <li>
                              <b>Transaction fees:</b>
                              {' '}
                              after you make more than six transfers or withdrawals in a month, the bank will usually charge you a fee for your next transfer or withdrawal.
                            </li>
                            <li>
                              <b>Overdraft fees:</b>
                              {' '}
                              when you spend more than you have in your account. Usually around $35 each time you overdraft on your account.
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level4/checking-savings/exercise" startTime={ startTime } level={ 4 } exercise={ 1 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CheckingSavings.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( CheckingSavings );
