import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  TextField, Typography, Tabs, Tab, Box,
} from '@material-ui/core';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
import SwipeableViews from 'react-swipeable-views';
import NumberFormat from 'react-number-format';
import GreyBox from './grey-common-box';
import styles from '../../../screens/pwi-career-advancement/styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import Pageloader from '../../ui/pageloader';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';

const OCCUPATION_DATA = loader('../../../graphql/schema/pwi-career-path/occupation-data.graphql');

function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `vertical-tabpanel-${index}` }
      aria-labelledby={ `vertical-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box p={ 3 }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps( index ) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const marks = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 30000,
    scaledValue: 30000,
    label: '$30k',
  },
  {
    value: 60000,
    scaledValue: 60000,
    label: '$60k',
  },
  {
    value: 90000,
    scaledValue: 90000,
    label: '$90k',
  },
  {
    value: 120000,
    scaledValue: 120000,
    label: '$120k',
  },
  {
    value: 150000,
    scaledValue: 150000,
    label: '$150k',
  },
];

const SupplementaryIncome = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;
  const [sliderValue, setSliderValue] = React.useState( 0 );

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.second_yearly_income !== undefined ) {
      setSliderValue( handleCareerObject.second_yearly_income );
    } else {
      updatedValues['second_yearly_income'] =  0;
    }
    handleUpdatedObject(updatedValues);
  // eslint-disable-next-line
  },[]);

  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState( 0 );
  const [offset, setOffset] = useState( 0 );
  const [occupationType, setOccupationType] = useState( false );
  const [totalEmployed, setTotalEmployed] = useState( false );
  const [annualWageMean, setAnnualWageMean] = useState( false );
  const [AnnualWageMedian, setAnnualWageMedian] = useState( false );
  const [AnnualWageLowend, setAnnualWageLowend] = useState( false );
  const [AnnualWageHighend, setAnnualWageHighend] = useState( false );
  const [hourlyMean, setHourlyMean] = useState( false );
  const [hourlyMedian, setHourlyMedian] = useState( false );
  const [hourlyLowend, setHourlyLowend] = useState( false );
  const [hourlyHighend, setHourlyHighend] = useState( false );
  const [search, setSearch] = useState( '' );
  const [collegeSearchList, setCollegeSearchList] = useState( [] );
  const [selectType, setSelectType] = useState('all');
  const [educationOrder, setEducationOrder] = useState({ name: 'occupation', order: 'ASC' });
  const [occupationData, setOccupationData] = useState([]);
  const [pageCount, setPageCount] = useState('');
  const [slice, setSlice] = useState([]);

  useQuery(OCCUPATION_DATA, {
    variables: {
      occupation_type: selectType,
      order_column: educationOrder.name,
      order_type: educationOrder.order,
    },
    fetchPolicy: 'network-only',
    // skip: educationType === '',
    onCompleted(res) {
      setOccupationData(res && res.getOccupationsData);
      setLoading(false)
    },
    onError() {
      setLoading(false)
    }
  });

  React.useEffect( () => {
    setCollegeSearchList( occupationData );
  }, [occupationData] );

  React.useEffect(() => {
    console.log('count')
    setPageCount(collegeSearchList && Math.ceil(collegeSearchList.length / 10));
    setSlice(collegeSearchList && collegeSearchList.slice(offset, offset + 10));
  }, [collegeSearchList]);

  const handleChange = (event, newValue) => {
    setLoading(true);
    setSearch('');
    if ( newValue === 0 ) {
      setSelectType( 'all' );
      setEducationOrder( { name: 'occupation', order: 'ASC' } );
    } else if ( newValue === 1 ) {
      setSelectType( 'industry' );
      setEducationOrder( { name: 'industry', order: 'ASC' } );
    }
  };

  const handleChangeIndex = ( index ) => {
    setValue( index );
  };

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    setSliderValue( newvalue );
    updatedValues[field] = newvalue;
    handleUpdatedObject( updatedValues );
  };

  const handlePageClick = ( data ) => {
    const { selected } = data;
    const off = Math.ceil( selected * 10 );
    setOffset( off );
    setPageCount(collegeSearchList && Math.ceil(collegeSearchList.length / 10));
    setSlice(collegeSearchList && collegeSearchList.slice(off, off + 10));
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (value === 0) {
      setCollegeSearchList(occupationData.filter((user) => user.occupation.toLowerCase().search(e.target.value.toLowerCase()) !== -1));
    }
    if (value === 1) {
      setCollegeSearchList(occupationData.filter((user) => user.industry.toLowerCase().search(e.target.value.toLowerCase()) !== -1));
    }
  };

  const handleSort = ( type ) => {
    if ( type === 'occupation' ) {
      setOccupationType( !occupationType );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'total_employed' ) {
      setOccupationType( false );
      setTotalEmployed( !totalEmployed );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_mean' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( !annualWageMean );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_median' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( !AnnualWageMedian );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_lowend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( !AnnualWageLowend );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'annual_wage_highend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( !AnnualWageHighend );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_mean' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( !hourlyMean );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_median' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( !hourlyMedian );
      setHourlyLowend( false );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_lowend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( !hourlyLowend );
      setHourlyHighend( false );
    } else if ( type === 'hourly_wage_highend' ) {
      setOccupationType( false );
      setTotalEmployed( false );
      setAnnualWageMean( false );
      setAnnualWageMedian( false );
      setAnnualWageLowend( false );
      setAnnualWageHighend( false );
      setHourlyMean( false );
      setHourlyMedian( false );
      setHourlyLowend( false );
      setHourlyHighend( !hourlyHighend );
    }
  };

  const handleSorting = ( name, order ) => {
    if (educationOrder.name !== name) {
      setLoading(true)
    }
    setEducationOrder( { name, order } );
  };

  return (
    <div className={ classes.contentBlock }>
       {loading && <Pageloader loading={loading} />}
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
      {handleCareerObject.role === 'futureMove' && (

      <div>
        <p>Do you plan on having more than one income stream such as a second or even third job? Outside of your career move, will you earn any supplementary income?</p>
        <p>If yes, how much do you plan to make in the first year in total supplementary income? Do not include income from investments or rental income.</p>
        <p>
          Use the table below to search for different jobs and what those jobs might pay.
        </p>
      </div>
      )}
      {handleCareerObject.role === 'currentRole' && (
      <div>
        <p>Do you currently have more than one income stream such as a second or even third job? Outside of your career, do you earn any supplementary income?</p>

        <p>If yes, how much do you make in supplementary income in one year? Do not include income from investments or rental income. That will be accounted for in the Investments and the Real Estate Investment modules.</p>
        <p>
          Use the table below to search for different jobs and what those jobs might pay.
        </p>
      </div>
      )}
      <div className={ classes.garyBoxSeventy }><GreyBox handleCareerObject={ handleCareerObject } /></div>
      <div className={ classes.annuvalIncomeTittle }>
        {' '}
        <span>Annual</span>
        {' '}
      </div>

      <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <div className={ classes.MaxWidth200 }>
            <span
              className={ classes.dottedText }
              aria-hidden="true"
              data-for="definationTitle"
              data-tip="Click for a definition."
              onClick={ () => handleDefinationPopup(
                'Supplementary Income',
              ) }
            >
              Supplementary Income
            </span>
          </div>
          <div className={ classes.annuvalIncomeGrowthGraph }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ marks }
              min={ 0 }
              max={ 150000 }
              step={ 500 }
              value={ sliderValue }
              valueLabelFormat={ `${commonFunctions.numFormatter( sliderValue )}` }
              onChange={ ( e, value ) => updateValue( e, value, 'second_yearly_income', 'slider' ) }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            $
            <NumberFormat
              customInput={ TextField }
              decimalScale={0}
              thousandSeparator
              value={ sliderValue }
              onValueChange={ ( e ) => updateValue( e, '', 'second_yearly_income', 'number' ) }
              onFocus={ ( e ) => {
                e.target.select();
              } }
            />
          </div>
        </div>
      </div>
      <div className={ classes.tabSection }>
        <Tabs
          value={ value }
          onChange={ handleChange }
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={ classes.tabButtons }
          aria-label="full width tabs example"
        >
          <Tab
            className={ value === 0 ? classes.activeTab : '' }
            label="All Occupations"
            { ...a11yProps( 0 ) }
          />
          <Tab
            className={ value === 1 ? classes.activeTab : '' }
            label="By Industry"
            { ...a11yProps( 1 ) }
          />
        </Tabs>
        <SwipeableViews
          axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
          index={ value }
          className={ `${classes.tabsContent} ${classes.tabContentBaseIncome}` }
          onChangeIndex={ handleChangeIndex }
        >
          <TabPanel value={ value } index={ 0 } dir={ theme.direction }>
            <div className={ classes.textFieldSearch }>
              {collegeSearchList && (
              <NumberFormat
                value={ collegeSearchList.length }
                displayType="text"
                thousandSeparator
              />
              )}
              {' '}
              occupations
              <TextField
                className={ classes.searchTextField }
                id="standard-basic"
                placeholder="Search"
                value={ search }
                onChange={ handleSearch }
              />
            </div>

            <div className={ classes.heigherEducationSearch }>
              <div className={ classNames( classes.ChooseSchoolTable, classes.wagesTable ) }>
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th colSpan="4">Annual Wages</th>
                      <th colSpan="4">Hourly Wages</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className={ classes.headSection }>
                      <td>
                        Occupation
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'occupation' ) }
                        >
                          {occupationType && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'occupation', 'ASC' ) }
                            >
                              Sort A to Z
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'occupation', 'DESC' ) }
                            >
                              Sort Z to A
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        # Employed
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'total_employed' ) }
                        >
                          {totalEmployed && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'total_employed', 'ASC' ) }
                            >
                              Sort A to Z
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'total_employed', 'DESC' ) }
                            >
                              Sort Z to A
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i className="fa fa-filter" aria-hidden="true" onClick={ () => handleSort( 'annual_wage_mean' ) }>
                          {annualWageMean && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_mean', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_mean', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_median' ) }
                        >
                          {AnnualWageMedian && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_median', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_median', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Low End
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_lowend' ) }
                        >
                          {AnnualWageLowend && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_lowend', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_lowend', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        High End
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_highend' ) }
                        >
                          {AnnualWageHighend && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_highend', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'annual_wage_highend', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_mean' ) }
                        >
                          {hourlyMean && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_mean', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_mean', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_median' ) }
                        >
                          {hourlyMedian && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_median', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_median', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Low End
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_lowend' ) }
                        >
                          {hourlyLowend && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_lowend', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_lowend', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        High End
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_highend' ) }
                        >
                          {hourlyHighend && (
                          <ul>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_highend', 'ASC' ) }
                            >
                              Highest to Lowest
                            </li>
                            <li
                              aria-hidden="true"
                              onClick={ () => handleSorting( 'hourly_wage_highend', 'DESC' ) }
                            >
                              Lowest to Highest
                            </li>
                          </ul>
                          )}
                        </i>
                      </td>
                    </tr>
                    {slice && slice.length > 0 ? (
                      slice.map( ( data ) => (
                        <tr key = {data.total_employed}>
                          <td>{data.occupation}</td>
                          <td>
                            <NumberFormat
                              value={ data.total_employed }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_lowend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_highend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_lowend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>

                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_highend }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                        </tr>
                      ) )
                    ) : (
                      <tr className={ classes.noData }>
                        <td colSpan="5">No data found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {slice && slice.length > 0 && (
                <div className={ classes.pagination }>
                  <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    breakLabel="..."
                    breakClassName="break-me"
                    pageCount={ pageCount }
                    marginPagesDisplayed={ 1 }
                    pageRangeDisplayed={ 7 }
                    onPageChange={ handlePageClick }
                    containerClassName="pagination"
                    subContainerClassName="pages pagination"
                    activeClassName="active"
                  />
                </div>
                )}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={ value } index={ 1 } dir={ theme.direction }>
            <div className={ classes.textFieldSearch }>
              {collegeSearchList && (
                <NumberFormat
                  value={ collegeSearchList.length }
                  displayType="text"
                  thousandSeparator
                />
              )}
              {' '}
              industries
              <TextField
                className={ classes.searchTextField }
                id="standard-basic"
                placeholder="Search"
                value={ search }
                onChange={ handleSearch }
              />
            </div>

            <div className={ classes.heigherEducationSearch }>
              <div className={ classNames( classes.ChooseSchoolTable, classes.wagesTable, classes.industryTable ) }>
                <table cellPadding="0" cellSpacing="0">
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th colSpan="2">Annual Wages</th>
                      <th colSpan="2">Hourly Wages</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Industry
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'occupation' ) }
                        >
                          {occupationType && (
                            <ul>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'occupation', 'ASC' ) }
                              >
                                Sort A to Z
                              </li>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'occupation', 'DESC' ) }
                              >
                                Sort Z to A
                              </li>
                            </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        # Employed
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'total_employed' ) }
                        >
                          {totalEmployed && (
                            <ul>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'total_employed', 'ASC' ) }
                              >
                                Sort A to Z
                              </li>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'total_employed', 'DESC' ) }
                              >
                                Sort Z to A
                              </li>
                            </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_mean' ) }
                        >
                          {annualWageMean && (
                            <ul>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'annual_wage_mean', 'ASC' ) }
                              >
                                Highest to Lowest
                              </li>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'annual_wage_mean', 'DESC' ) }
                              >
                                Lowest to Highest
                              </li>
                            </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'annual_wage_median' ) }
                        >
                          {AnnualWageMedian && (
                            <ul>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'annual_wage_median', 'ASC' ) }
                              >
                                Highest to Lowest
                              </li>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'annual_wage_median', 'DESC' ) }
                              >
                                Lowest to Highest
                              </li>
                            </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Mean
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_mean' ) }
                        >
                          {hourlyMean && (
                            <ul>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'hourly_wage_mean', 'ASC' ) }
                              >
                                Highest to Lowest
                              </li>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'hourly_wage_mean', 'DESC' ) }
                              >
                                Lowest to Highest
                              </li>
                            </ul>
                          )}
                        </i>
                      </td>
                      <td>
                        Median
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          onClick={ () => handleSort( 'hourly_wage_median' ) }
                        >
                          {hourlyMedian && (
                            <ul>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'hourly_wage_median', 'ASC' ) }
                              >
                                Highest to Lowest
                              </li>
                              <li
                                aria-hidden="true"
                                onClick={ () => handleSorting( 'hourly_wage_median', 'DESC' ) }
                              >
                                Lowest to Highest
                              </li>
                            </ul>
                          )}
                        </i>
                      </td>
                    </tr>
                    {slice && slice.length > 0 ? (
                      slice.map( ( data ) => (
                        <tr key = {data.total_employed}>
                          <td>{data.industry}</td>
                          <td>
                            <NumberFormat
                              value={ data.total_employed }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.annual_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_mean }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                          <td>
                            <NumberFormat
                              value={ data.hourly_wage_median }
                              displayType="text"
                              thousandSeparator
                              prefix="$"
                            />
                          </td>
                        </tr>
                      ) )
                    ) : (
                      <tr className={ classes.noData }>
                        <td colSpan="5">No data found...</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                {slice && slice.length > 0 && (
                  <div className={ classes.pagination }>
                    <ReactPaginate
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={ pageCount }
                      marginPagesDisplayed={ 2 }
                      pageRangeDisplayed={ 5 }
                      onPageChange={ handlePageClick }
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      activeClassName="active"
                    />
                  </div>
                )}
              </div>
            </div>
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>

  );
};

SupplementaryIncome.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // occupationData: PropTypes.arrayOf( PropTypes.object ).isRequired,
  // setEducationOrder: PropTypes.func.isRequired,
  // setSelectType: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,

};

export default withStyles( styles )( SupplementaryIncome );
