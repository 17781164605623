import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();
const ThreeTypesOfIncome = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/3-types-of-income.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>3 Types of Income</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/types-income-left.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>There are three different ways to earn income in life: earned, portfolio, and passive income. The key difference between these three types is what produces the income.</p>
                        <p>
                          <b>Earned income</b>
                          {' '}
                          is money derived from spending your time working. Examples are jobs that pay salary and wages, bonuses, tips and commission. Your time is what produces earned income. Earned income is taxed at the highest rate – anywhere from 20-40%. In the next activity, you’ll discover how income taxes are calculated.
                        </p>
                        <p>
                          <b>Portfolio income</b>
                          {' '}
                          is money you receive from selling an investment such as a stock, bond, or other fund for more than you bought it for. For example, if you bought Apple stock at $100 and sold for $150, the $50 gain would be portfolio income. Your initial investment of money is what produces portfolio income. Portfolio income is usually taxed at a lower rate than earned income, which means you keep more of the money you make.
                        </p>
                        <p>
                          <b>Passive income</b>
                          {' '}
                          is money generated from assets you own that you don&apos;t actively work on. For example, if you own real estate and receive rent payments each month, the rent would be passive income. The asset that you own (not your time or selling the asset) is what produces passive income. Passive income is taxed at the lowest rate of the three.
                        </p>
                        <p>The benefit of portfolio and passive income is that neither types of income require your time! Earned income always requires your time and is limited because there are only so many hours in the day.</p>
                      </div>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level2/3-types-of-income/exercise" startTime={ startTime } level={ 2 } exercise={ 1 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
ThreeTypesOfIncome.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( ThreeTypesOfIncome );
