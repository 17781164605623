function existingHomeSheetData( module1Data, module18Data, assumptionsData, mortgage1Data, existingHomeMortgageData ) {
  /* Buying Expenses */
  const buyingExpenses = {};

  // Selling Year
  let purchaseYear = 0;
  let purchaseYear2 = 0;
  
  let netCashFlowFromSale = 0;
  let netCashFlowFromSale2 = 0;
  
  if ( module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' ) {
    purchaseYear = module18Data.existing_home_sale_year;
  }
  buyingExpenses['Selling Year'] = purchaseYear;
  if ( module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 !== '' ) {
    purchaseYear2 = module18Data.existing_home_sale_year_2;
  }
  buyingExpenses['Selling Year2'] = purchaseYear2;
  

  /* Cash Out */
  const cashOut = {};

  // Mortgage Payment
  let mortgagePayment = 0;
  if ( module18Data.existingHomeMortgageAnnualPayment !== undefined && module18Data.existingHomeMortgageAnnualPayment !== '' ) {
    mortgagePayment = module18Data.existingHomeMortgageAnnualPayment;
  }
  cashOut['Mortgage Payment'] = mortgagePayment;
  let mortgagePayment2 = 0;
  if ( module18Data.existingHomeMortgageAnnualPayment2 !== undefined && module18Data.existingHomeMortgageAnnualPayment2 !== '' ) {
    mortgagePayment2 = module18Data.existingHomeMortgageAnnualPayment2;
  }
  cashOut['Mortgage Payment2'] = mortgagePayment2;

  // Property Mortgage Insurance(PMI)
  let propertyMortgageInsurance = 0;
  if ( module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs !== undefined && module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs !== '' ) {
    propertyMortgageInsurance = module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs;
  }
  cashOut['Private Mortgage Insurance (PMI)'] = propertyMortgageInsurance;
  let propertyMortgageInsurance2 = 0;
  if ( module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 !== undefined && module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 !== '' ) {
    propertyMortgageInsurance2 = module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2;
  }
  cashOut['Private Mortgage Insurance (PMI)2'] = propertyMortgageInsurance2;

  // Insurance
  let insurance = 0;
  if ( module18Data.existingHomeMonthlyHomeownerInsuranceCalcs !== undefined && module18Data.existingHomeMonthlyHomeownerInsuranceCalcs !== '' ) {
    insurance = module18Data.existingHomeMonthlyHomeownerInsuranceCalcs;
  }
  cashOut['Homeowners Insurance'] = insurance;
  let insurance2 = 0;
  if ( module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 !== undefined && module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 !== '' ) {
    insurance2 = module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2;
  }
  cashOut['Homeowners Insurance2'] = insurance2;

  // Maintenance
  let maintenance = 0;
  if ( module18Data.existingHomeMonthlyMaintenanceCalcs !== undefined && module18Data.existingHomeMonthlyMaintenanceCalcs !== '' ) {
    maintenance = module18Data.existingHomeMonthlyMaintenanceCalcs;
  }
  cashOut.Maintenance = maintenance;
  let maintenance2 = 0;
  if ( module18Data.existingHomeMonthlyMaintenanceCalcs2 !== undefined && module18Data.existingHomeMonthlyMaintenanceCalcs2 !== '' ) {
    maintenance2 = module18Data.existingHomeMonthlyMaintenanceCalcs2;
  }
  cashOut.Maintenance2 = maintenance2;

  // Property Taxes
  let propertyTaxes = 0;
  if ( module18Data.existingHomeMonthlyPropertyTaxesCalcs !== undefined && module18Data.existingHomeMonthlyPropertyTaxesCalcs !== '' ) {
    propertyTaxes = module18Data.existingHomeMonthlyPropertyTaxesCalcs;
  }
  cashOut['Property Taxes'] = propertyTaxes;
  let propertyTaxes2 = 0;
  if ( module18Data.existingHomeMonthlyPropertyTaxesCalcs2 !== undefined && module18Data.existingHomeMonthlyPropertyTaxesCalcs2 !== '' ) {
    propertyTaxes2 = module18Data.existingHomeMonthlyPropertyTaxesCalcs2;
  }
  cashOut['Property Taxes2'] = propertyTaxes2;

  // Association Fees
  let associationFees = 0;
  if ( module18Data.existingHomeMonthlyAssociationFeesCalcs !== undefined && module18Data.existingHomeMonthlyAssociationFeesCalcs !== '' ) {
    associationFees = module18Data.existingHomeMonthlyAssociationFeesCalcs;
  }
  cashOut['Association Fees'] = associationFees;
  let associationFees2 = 0;
  if ( module18Data.existingHomeMonthlyAssociationFeesCalcs2 !== undefined && module18Data.existingHomeMonthlyAssociationFeesCalcs2 !== '' ) {
    associationFees2 = module18Data.existingHomeMonthlyAssociationFeesCalcs2;
  }
  cashOut['Association Fees2'] = associationFees2;

  // Utilities
  let utilities = 0;
  if ( module18Data.existingHomeMonthlyUtilitiesCalcs !== undefined && module18Data.existingHomeMonthlyUtilitiesCalcs !== '' ) {
    utilities = module18Data.existingHomeMonthlyUtilitiesCalcs;
  }
  cashOut.Utilities = utilities;
  
  let utilities2 = 0;
  if ( module18Data.existingHomeMonthlyUtilitiesCalcs2 !== undefined && module18Data.existingHomeMonthlyUtilitiesCalcs2 !== '' ) {
    utilities2 = module18Data.existingHomeMonthlyUtilitiesCalcs2;
  }
  cashOut.Utilities2 = utilities2;

  let year = parseInt( module1Data.start_year, 10 );
  const yearLimit = parseInt( module1Data.start_year, 10 ) + 30;
  /* Data1 */
  const data1 = {};
  let dataAssociationFees = 0;
  let dataInsurance = 0;
  let dataMaintenance = 0;
  let dataUtilities = 0;
  
  let dataAssociationFees2 = 0;
  let dataInsurance2 = 0;
  let dataMaintenance2 = 0;
  let dataUtilities2 = 0;

  /* Data 2 */
  const data2 = {};

  /* Real Estate Value */
  const realEstateValue = {};
  let realEstateAssetValue = 0;
  let realEstateAssetValue2 = 0;

  // Purchase Price
  let purchasePrice = 0;
  if ( module18Data.existig_home_current_value !== undefined && module18Data.existig_home_current_value !== '' ) {
    purchasePrice = module18Data.existig_home_current_value;
  } else {
    purchasePrice = 0;
  }
  let purchasePrice2 = 0;
  if ( module18Data.existig_home_current_value_2 !== undefined && module18Data.existig_home_current_value_2 !== '' ) {
    purchasePrice2 = module18Data.existig_home_current_value_2;
  } else {
    purchasePrice2 = 0;
  }

  // Depreciation
  const depreciation = 27.50;
  const depreciation2 = 27.50;

  //  Asset Value
  let assetValueCal = 0;
  let assetValueCal2 = 0;

  assetValueCal = purchasePrice;
  assetValueCal2 = purchasePrice2;

  // Cal Value
  let calVal = 0;
  if ( assetValueCal > 0 ) {
    calVal = ( assetValueCal / depreciation );
  }
  let calVal2 = 0;
  if ( assetValueCal2 > 0 ) {
    calVal2 = ( assetValueCal2 / depreciation2 );
  }

  let realEstateAccumulated = 0;
  let realEstateAccumulated2 = 0;
  let k = 0;
  let realEstatePropertyTax = 0;
  let realEstatePropertyTax2 = 0;
  for ( year; year <= yearLimit; year += 1 ) {
    /* Real Estate Value */
    realEstateValue[year] = {};

    // Asset Value
    if ( k === 0 ) {
      if ( year <= purchaseYear && assetValueCal > 0 ) {
        realEstateAssetValue = assetValueCal;
      } else {
        realEstateAssetValue = 0;
      }
    } else {
		if ( year <= purchaseYear && assetValueCal > 0 && module18Data.existing_home_annual_appreciation !== undefined && module18Data.existing_home_annual_appreciation !== '' ) {
      realEstateAssetValue = ( ( module18Data.existing_home_annual_appreciation / 100 ) + 1 ) * realEstateAssetValue;
    } else {
      realEstateAssetValue = 0;
    }
	}
    realEstateValue[year]['Asset Value'] = realEstateAssetValue;
	
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && module1Data.start_year === year && year <= purchaseYear2 && assetValueCal2 > 0){
		realEstateAssetValue2 = assetValueCal2;
	} else{
		if( year <= purchaseYear2 && assetValueCal2 > 0 && typeof module18Data.existing_home_annual_appreciation_2 !== 'undefined' && module18Data.existing_home_annual_appreciation_2 !== ''){
			realEstateAssetValue2 = ( ( module18Data.existing_home_annual_appreciation_2 / 100 ) + 1 ) * realEstateAssetValue2;
		} else{
			realEstateAssetValue2 = 0;	
		}
	}
    realEstateValue[year]['Asset Value2'] = realEstateAssetValue2;
	

    // Property Tax
    if ( k === 0 ) {
      if ( assetValueCal > 0 && year <= purchaseYear ) {
        realEstatePropertyTax = cashOut['Property Taxes'];
      } else {
        realEstatePropertyTax = 0;
      }
    } else if ( assetValueCal > 0 && year <= purchaseYear && module18Data.existing_home_annual_appreciation !== undefined && module18Data.existing_home_annual_appreciation !== '' ) {
      // eslint-disable-next-line
      realEstatePropertyTax = ( ( module18Data.existing_home_annual_appreciation / 100 ) + 1 ) * realEstatePropertyTax;
    } else {
      // eslint-disable-next-line
      realEstatePropertyTax = 0;
    }
    realEstateValue[year]['Property Tax'] = realEstatePropertyTax;
	
	//console.log('propertyTaxes2', propertyTaxes2);
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && module1Data.start_year === year && year <= purchaseYear2 && assetValueCal2 > 0){
		realEstatePropertyTax2 = propertyTaxes2;
	} else{
		if( year <= purchaseYear2 && assetValueCal2 > 0 && typeof module18Data.existing_home_annual_appreciation_2 !== 'undefined' && module18Data.existing_home_annual_appreciation_2 !== ''){
			realEstatePropertyTax2 = ( ( module18Data.existing_home_annual_appreciation_2 / 100 ) + 1 ) * realEstatePropertyTax2;
		} else{
			realEstatePropertyTax2 = 0;	
		}
	}
    realEstateValue[year]['Property Tax2'] = realEstatePropertyTax2;

    // Home
    let realEstateHome = 0;
    if ( realEstateValue[year]['Asset Value'] > 0 ) {
      realEstateHome = calVal;
    } else {
      realEstateHome = 0;
    }
    realEstateValue[year].Home = realEstateHome;
	
	let realEstateHome2 = 0;
    if ( realEstateValue[year]['Asset Value2'] > 0 ) {
      realEstateHome2 = calVal2;
    } else {
      realEstateHome2 = 0;
    }
    realEstateValue[year].Home2 = realEstateHome2;

    // Accumulated
    realEstateAccumulated += realEstateHome;
	realEstateAccumulated2 += realEstateHome2;
    realEstateValue[year].Accumulated = realEstateAccumulated;
	realEstateValue[year].Accumulated2 = realEstateAccumulated2;

    /* Data 1 */
    data1[year] = {};

    // Dowm Payment
    let dataDownPayment = 0;
    if ( purchaseYear > 0 && year <= purchaseYear ) {
      dataDownPayment = cashOut['Mortgage Payment'];
    }
    data1[year]['Mortgage Payment'] = dataDownPayment;
	
	let dataDownPayment2 = 0;
    if ( purchaseYear2 > 0 && year <= purchaseYear2 ) {
      dataDownPayment2 = cashOut['Mortgage Payment2'];
    }
    data1[year]['Mortgage Payment2'] = dataDownPayment2;
	

    // Property Taxes
    const dataPropertyTaxes = realEstateValue[year]['Property Tax'];
    data1[year]['Property Taxes'] = dataPropertyTaxes;
	const dataPropertyTaxes2 = realEstateValue[year]['Property Tax2'];
    data1[year]['Property Taxes2'] = dataPropertyTaxes2;

    // Home Owners Insurance

    if ( k === 0 ) {
      if ( purchaseYear > 0 && year <= purchaseYear ) {
        dataInsurance = cashOut['Homeowners Insurance'];
      } else {
        dataInsurance = 0;
      }
    } else if ( purchaseYear > 0 && year <= purchaseYear ) {
      if ( module18Data.existing_home_annual_appreciation !== undefined && module18Data.existing_home_annual_appreciation !== '' ) {
        dataInsurance *= ( 1 + ( module18Data.existing_home_annual_appreciation / 100 ) );
      } else {
        dataInsurance = 0;
      }
    } else {
      dataInsurance = 0;
    }
    data1[year]['Houseowners Insurance'] = dataInsurance;
	
	
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && module1Data.start_year === year && year <= purchaseYear2 && assetValueCal2 > 0){
		dataInsurance2 = insurance2; 
	} else{
		if( year <= purchaseYear2 && assetValueCal2 > 0 && typeof module18Data.existing_home_annual_appreciation_2 !== 'undefined' && module18Data.existing_home_annual_appreciation_2 !== ''){
			dataInsurance2 = ( ( module18Data.existing_home_annual_appreciation_2 / 100 ) + 1 ) * dataInsurance2;
		} else{
			dataInsurance2 = 0;	
		}
	}
    data1[year]['Houseowners Insurance2'] = dataInsurance2;
	
	

    // Private Mortgage Insurance
    let dataPrivateInsurance = 0;
    // moduleServices.privateMortgageInsuranceCalcs();
    if ( realEstateValue[year]['Asset Value'] > 0 ) {
      if ( existingHomeMortgageData !== undefined && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].BeginningBalance !== undefined && ( existingHomeMortgageData['Summation Data'][year].BeginningBalance / realEstateValue[year]['Asset Value'] ) >= ( 80 / 100 ) ) {
        dataPrivateInsurance = cashOut['Private Mortgage Insurance (PMI)'];
      }
    }
    data1[year]['Private Mortgage Insurance'] = dataPrivateInsurance;
	
	let dataPrivateInsurance2 = 0;
    // moduleServices.privateMortgageInsuranceCalcs();
    if ( realEstateValue[year]['Asset Value2'] > 0 ) {
      if ( existingHomeMortgageData !== undefined && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][year] !== undefined && existingHomeMortgageData['Summation Data'][year].BeginningBalance2 !== undefined && ( existingHomeMortgageData['Summation Data'][year].BeginningBalance2 / realEstateValue[year]['Asset Value2'] ) >= ( 80 / 100 ) ) {
        dataPrivateInsurance2 = cashOut['Private Mortgage Insurance (PMI)2'];
      }
    }
    data1[year]['Private Mortgage Insurance2'] = dataPrivateInsurance2;

    // Maintenance
    if ( k === 0 ) {
      if ( purchaseYear > 0 && year <= purchaseYear ) {
        dataMaintenance = cashOut.Maintenance;
      } else {
        dataMaintenance = 0;
      }
    } else if ( purchaseYear > 0 && year <= purchaseYear ) {
      if ( module18Data.existing_home_annual_appreciation !== undefined && module18Data.existing_home_annual_appreciation !== '' ) {
        dataMaintenance *= ( 1 + ( module18Data.existing_home_annual_appreciation / 100 ) );
      } else {
        dataMaintenance = 0;
      }
    } else {
      dataMaintenance = 0;
    }
    data1[year].Maintenance = dataMaintenance;
	
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && module1Data.start_year === year && year <= purchaseYear2 && assetValueCal2 > 0){
		dataMaintenance2 = maintenance2; 
	} else{
		if( year <= purchaseYear2 && assetValueCal2 > 0 && typeof module18Data.existing_home_annual_appreciation_2 !== 'undefined' && module18Data.existing_home_annual_appreciation_2 !== ''){
			dataMaintenance2 = ( ( module18Data.existing_home_annual_appreciation_2 / 100 ) + 1 ) * dataMaintenance2;
		} else{
			dataMaintenance2 = 0;	
		}
	}
    data1[year].Maintenance2 = dataMaintenance2;
	

    // Association Fees
    if ( k === 0 ) {
      if ( purchaseYear > 0 && year <= purchaseYear ) {
        dataAssociationFees = cashOut['Association Fees'];
      } else {
        dataAssociationFees = 0;
      }
    } else if ( purchaseYear > 0 && year <= purchaseYear ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Expenses !== undefined ) {
        dataAssociationFees *= ( assumptionsData[year].Expenses / 100 );
      } else {
        dataAssociationFees = 0;
      }
    } else {
      dataAssociationFees = 0;
    }
    data1[year]['Association Fees'] = dataAssociationFees;
	
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && module1Data.start_year === year && year <= purchaseYear2 && assetValueCal2 > 0){
		dataAssociationFees2 = associationFees2; 
	} else{
		if( year <= purchaseYear2 && assetValueCal2 > 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Expenses !== undefined){
			dataAssociationFees2 = ( assumptionsData[year].Expenses / 100 ) * dataAssociationFees2;
			
		} else{
			dataAssociationFees2 = 0;	
		}
	}
    data1[year]['Association Fees2'] = dataAssociationFees2;

    // Utilities
    if ( k === 0 ) {
      if ( purchaseYear > 0 && year <= purchaseYear ) {
        dataUtilities = cashOut.Utilities;
      } else {
        dataUtilities = 0;
      }
    } else if ( purchaseYear > 0 && year <= purchaseYear ) {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Expenses !== undefined ) {
        dataUtilities *= ( assumptionsData[year].Expenses / 100 );
      } else {
        dataUtilities = 0;
      }
    } else {
      dataUtilities = 0;
    }
    data1[year].Utilities = dataUtilities;
	
	
	if( typeof module1Data.start_year !== 'undefined' && module1Data.start_year !== '' && module1Data.start_year === year && year <= purchaseYear2 && assetValueCal2 > 0){
		dataUtilities2 = utilities2; 
	} else{
		if( year <= purchaseYear2 && assetValueCal2 > 0 && assumptionsData !== undefined && assumptionsData[year] !== undefined && assumptionsData[year].Expenses !== undefined){
			dataUtilities2 = ( assumptionsData[year].Expenses / 100 ) * dataUtilities2;
		} else{
			dataUtilities2 = 0;	
		}
	}
    data1[year].Utilities2 = dataUtilities2;

    // Tax On Gain
    let taxOnGain = 0;
    if ( year === purchaseYear2 && module18Data.taxOnGain !== undefined && module18Data.taxOnGain !== '' ) {
      // eslint-disable-next-line
      taxOnGain = module18Data.taxOnGain;
    }
    data1[year]['Tax On Gain'] = taxOnGain;
	
	// let taxOnGain2 = 0;
    if ( year === purchaseYear2 && module18Data.taxOnGain !== undefined && module18Data.taxOnGain !== '' ) {
      // eslint-disable-next-line
      taxOnGain = module18Data.taxOnGain;
    }
    data1[year]['Tax On Gain2'] = taxOnGain;
	
    k += 1;

    if ( year === purchaseYear ) {
      // Selling Price
      let sellingPrice = 0;
      if ( realEstateValue !== undefined && realEstateValue[purchaseYear] !== undefined && realEstateValue[purchaseYear]['Asset Value'] !== '' ) {
        sellingPrice = realEstateValue[purchaseYear]['Asset Value'];
      }
      buyingExpenses['Selling Price'] = sellingPrice;

      // Selling Costs
      if ( module18Data.existing_home_selling_costs !== undefined && module18Data.existing_home_selling_costs !== '' ) {
        buyingExpenses['Selling Costs'] = ( module18Data.existing_home_selling_costs / 100 ) * buyingExpenses['Selling Price'];
      } else {
        buyingExpenses['Selling Costs'] = 0;
      }

      // Mortgage Balance Remaining
      let mortgageBalanceRemaining = 0;
      if ( existingHomeMortgageData !== undefined && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][purchaseYear] !== undefined && existingHomeMortgageData['Summation Data'][purchaseYear].EndingBalance !== undefined ) {
        mortgageBalanceRemaining = existingHomeMortgageData['Summation Data'][purchaseYear].EndingBalance;
      }
      buyingExpenses['Mortgage Balance Remaining'] = mortgageBalanceRemaining;

      // Net Cash Flow from Sale
      netCashFlowFromSale = buyingExpenses['Selling Price'] - buyingExpenses['Selling Costs'] - buyingExpenses['Mortgage Balance Remaining'];
      buyingExpenses['Net Cash Flow from Sale'] = netCashFlowFromSale;

      // Sale Value
      let saleValue = 0;
      if ( purchaseYear === year ) {
        saleValue = netCashFlowFromSale;
      } else {
        saleValue = 0;
      }
      data1[year]['Sale Value'] = saleValue;
    } else {
      data1[year]['Sale Value'] = 0;
    }
	
	if ( year === purchaseYear2 ) {
      // Selling Price
	  
      let sellingPrice2 = 0;
      if ( realEstateValue !== undefined && realEstateValue[purchaseYear] !== undefined && realEstateValue[purchaseYear]['Asset Value2'] !== '' ) {
        sellingPrice2 = realEstateValue[purchaseYear2]['Asset Value2'];
      }
	  
      buyingExpenses['Selling Price2'] = sellingPrice2;

      // Selling Costs
      if ( module18Data.existing_home_selling_costs_2 !== undefined && module18Data.existing_home_selling_costs_2 !== '' ) {
        buyingExpenses['Selling Costs2'] = ( module18Data.existing_home_selling_costs_2 / 100 ) * buyingExpenses['Selling Price2'];
      } else {
        buyingExpenses['Selling Costs2'] = 0;
      }

      // Mortgage Balance Remaining
      let mortgageBalanceRemaining2 = 0;
	  
      if ( existingHomeMortgageData !== undefined && existingHomeMortgageData['Summation Data'] !== undefined && existingHomeMortgageData['Summation Data'][purchaseYear2] !== undefined && existingHomeMortgageData['Summation Data'][purchaseYear2].EndingBalance2 !== undefined ) {
        mortgageBalanceRemaining2 = existingHomeMortgageData['Summation Data'][purchaseYear2].EndingBalance2;
      }
      buyingExpenses['Mortgage Balance Remaining2'] = mortgageBalanceRemaining2;

      // Net Cash Flow from Sale
      netCashFlowFromSale2 = buyingExpenses['Selling Price2'] - buyingExpenses['Selling Costs2'] - buyingExpenses['Mortgage Balance Remaining2'];
      buyingExpenses['Net Cash Flow from Sale2'] = netCashFlowFromSale2;

      // Sale Value
      let saleValue2 = 0;
      if ( purchaseYear2 === year ) {
        saleValue2 = netCashFlowFromSale2;
      } else {
        saleValue2 = 0;
      }
      data1[year]['Sale Value2'] = saleValue2;
    } else {
      data1[year]['Sale Value2'] = 0;
    }
	
		
	
    // Net Cash Flow
    data1[year]['Net Cash Flow'] = parseFloat( data1[year]['Net Cash Flow from Sale'] )
                    - ( parseFloat( data1[year]['Mortgage Payment'] )
                    + parseFloat( data1[year]['Property Taxes'] )
                    + parseFloat( data1[year]['Houseowners Insurance'] )
                    + parseFloat( data1[year]['Private Mortgage Insurance'] )
                    + parseFloat( data1[year].Maintenance )
                    + parseFloat( data1[year]['Association Fees'] )
                    + parseFloat( data1[year].Utilities )
                    + parseFloat( data1[year]['Tax On Gain'] ) );
	
	data1[year]['Net Cash Flow2'] = parseFloat( data1[year]['Sale Value2'] )
                    - ( parseFloat( data1[year]['Mortgage Payment2'] )
                    + parseFloat( data1[year]['Property Taxes2'] )
                    + parseFloat( data1[year]['Houseowners Insurance2'] )
                    + parseFloat( data1[year]['Private Mortgage Insurance2'] )
                    + parseFloat( data1[year].Maintenance2 )
                    + parseFloat( data1[year]['Association Fees2'] )
                    + parseFloat( data1[year].Utilities2 )
                    + parseFloat( data1[year]['Tax On Gain2'] ) );
					
  }
  // To return Student Loan Complete Data
  function existingHomeMainData() {
    const buyingAHomeInfo = {};
    buyingAHomeInfo['Buying Expenses'] = buyingExpenses;
    buyingAHomeInfo['Cash Out'] = cashOut;
    buyingAHomeInfo.Data1 = data1;
    buyingAHomeInfo.Data2 = data2;
    buyingAHomeInfo['Real Estate'] = realEstateValue;
    buyingAHomeInfo['Real Estate Asset Value'] = assetValueCal;
	buyingAHomeInfo['Real Estate Asset Value2']     = assetValueCal2;
	//console.log('buyingAHomeInfo', buyingAHomeInfo);
    return buyingAHomeInfo;
  }
  return existingHomeMainData();
}
export default existingHomeSheetData;
