import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import styles from './styles';
import mcsTopImage from '../../../assets/img/mcs/level4_bg.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import levelIcon from '../../../assets/img/mcs/MCS-level04.png';
import progressImage from '../../../assets/img/mcs/100.png';
import fiGraphic from '../../../assets/img/mcs/level-04-insta-ad.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Pageloader from '../../../components/ui/pageloader';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const SAVEMYCAREERSKETCH = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');

const staticLevelData = [
  {
    "id": "7",
    "user_id": 214,
    "level": 2,
    "option_id": 1,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:29:33"
  }
]

const McsLevelSix = (props) => {
  const { classes, history } = props;
  const [firstActivityData, setFirstActivityData] = React.useState("");
  const [startActivity] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [levelPercentage, setLevelPercentage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
    onCompleted({
      saveMyCareerSketch: {
        status,
      },
    }) {
      if (status) {
        setLoading(false);
        history.push('./dashboard');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        message
      ));
      setErrorMessage(formatedErrors);
      setLoading(false);
    },
  });
  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "6",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setLevelPercentage(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent);
      setFirstActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 1)[0] : staticLevelData.filter(obj => obj.option_id === 1)[0]);
    },
  });
  React.useEffect(() => {
    if (mcsLevelWiseData) {
      setLoading(false);
    }
  }, [mcsLevelWiseData]);
  // const handleStartActivity = () => {
  //   setStartActivity(true);
  // }
  const handleCompletedFirstActivity = (activity_id) => {
    setLoading(true);
    submitFirstActivity({
      variables: {
        data: {
          level: "6",
          option_id: activity_id,
        },
      },
    });
  }
  const handleGotoNextLevel = () => {
    history.push('./level7');
  }
  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={loading} />}
      <div className={classes.mcsLevelOne}>
        <div className={classes.topImage}>
          <img src={mcsTopImage} alt="mcs" />
        </div>
        <div className={classes.container}>
          <div className={classes.mcsRow}>
            <div className={classes.mcsLogoImage}>
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.mcsLevelNum}>
              <h3>LEVEL 06</h3>
              <img src={levelIcon} alt="info" className={classes.iconImage} />
            </div>
          </div>
          <div className={classes.mcsBodyContent}>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.leftBlock}>
                  <h1 className={classes.mcsLevelTitle}>Power of career sketching</h1>
                  <div className={classes.qPosition}>
                    <div className={classes.questionBlock}>
                      <p>Congrats on building your stepping stones! Do you see how you can achieve your vision more clearly?</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>
                        Now, what if you could make adjustments to your career sketch so you can reach your goals and dreams in less time using less money while growing your net worth? As a matter of fact,
                        <span className={classes.blueColor}>there are a few smart moves you can make that will add $100k to your net worth.&nbsp;</span>
                        How do you do that?
                      </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>At any stage of your life, implementing the guidelines of smarter tomorrows can accelerate your earnings and add to your bottom line. All smart moves start with a simple question, “What if?” For example, what if I paid off my loans sooner? Or, what if I acquired in-demand career skills and leveraged that new knowledge to earn more?</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Let&apos;s see how a few smart moves can add as much as $100,000 (yes, really one hundred thousand dollars) to the net worth of a career sketch.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>And once you learn these smart moves, you&apos;ll want to put them to work in your own career sketch.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Let’s dive in.</p>
                    </div>
                    {startActivity ? <div className={classes.startButton}>
                      <button type="button" onClick={() => handleCompletedFirstActivity("1")}>complete first Activity</button>
                    </div> : firstActivityData && firstActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleGotoNextLevel}>NEXT LEVEL {'>'}</button>
                    </div> : <div className={classes.startButton}>
                      <button type="button" onClick={() => {history.push('/my-career-sketch/level06/activity/power-of-career-sketching')}}>start</button>
                    </div>}
                    {errorMessage && <span>{errorMessage}</span>}
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.rightBlock}>
                  <div className={classes.networthImage}>
                    <img src={fiGraphic} alt="graph" />
                  </div>
                  <div className={classes.timelineImage}>
                    <video id="bgvid" preload="auto" muted autoPlay="true" loop playsinline>
                      <source src="https://inkwiry-videos.s3.us-east-2.amazonaws.com/mcs-levels/Level-01.mp4" type="video/mp4" />
                    </video>
                  </div>
                  {/* <div className={ classes.savingsImage }>
                    <img src={ savingsGraphic } alt="savings" />
                  </div> */}
                  <div className={classes.activeRow}>
                    <div className={classes.activities}>
                      <h4>Level 06 Activity</h4>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={firstActivityData && firstActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Explore Lumen/Neal&apos;s smart moves
                        </label>
                        {firstActivityData && firstActivityData.status === 1 ? <button type="button" onClick={()=>{history.push('/my-career-sketch/level06/activity/power-of-career-sketching')}} className={classes.goButton}> Review</button> : ""}
                      </div>
                    </div>
                    <div className={classes.myLevelProgress}>
                      <h4>My Level 06 Progress</h4>
                    </div>
                    <div className={classes.progressPuzzle}>
                      <span className={classes.progressPercent}>{levelPercentage}%</span>
                      <p className={classes.puzzleBar}>
                        <img src={progressImage} alt="info" />
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mcsFooter}>
          <div className={classes.container}>
            <div className={classes.footerRow}>
              <div className={classes.copyRight}>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={classNames(classes.copyRight, classes.informedText)}>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.mcsFluid}>
            <p className={classes.termLinks}>
              <a href="/">Terms of Service</a>
&nbsp;//&nbsp;
              <a href="/">Privacy Policy</a>
&nbsp;//&nbsp;
              <a href="/">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsLevelSix.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsLevelSix);