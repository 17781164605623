import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const Interest = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Interest</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/interest_new.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      Interest
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/interest-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Interest is the cost of borrowing money from a bank,
                          person, or other entity. When you take out a{' '}
                          <Link to="/finance-explained/loan" target="_blank">
                            loan
                          </Link>
                          , you agree to repay the loan amount plus interest.
                          The amount of interest you pay depends on the interest
                          rate,{' '}
                          <Link
                            to="/finance-explained/loan-principal"
                            target="_blank">
                            principal
                          </Link>{' '}
                          (loan amount), the term of the loan (time to repay),
                          and how interest accrues on your loan. The best way to
                          understand how interest is calculated is to view an
                          example.
                        </p>
                        <p className={classes.smallText}>
                          Let&apos;s say you borrow $25,000 to buy a car with a
                          four-year term (48 monthly payments), a 4% annual
                          interest rate, and interest accrues on a monthly
                          basis. Given these loan terms, you would owe $564.48
                          each month. But what portion of that monthly payment
                          is interest? Use the formula below to find out:
                        </p>
                        <p className={classes.smallText}>
                          <strong>
                            Outstanding Loan Amount x Interest Rate ÷ 12
                          </strong>
                          <br />
                          <strong>
                            (to make the interest rate on a monthly basis) =
                            Monthly Interest
                          </strong>
                        </p>
                        <p className={classes.smallText}>
                          <strong>
                            $25,000 x 4% / 12 = $83.33 interest expense
                          </strong>
                        </p>
                        <p className={classes.smallText}>
                          Interest is calculated as a percentage of the
                          outstanding loan amount. Since the loan amount
                          decreases with every monthly payment, the amount of
                          interest you pay each month also decreases. As more
                          time passes, more of each monthly payment goes toward
                          repaying your loan balance and less goes toward
                          interest.
                        </p>
                        <p className={classes.smallText}>
                          Check out the difference between the first month
                          payment and the last month payment broken down by
                          principal and interest for the $25,000 car loan.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <div className={classes.galleryImage}>
                          <img
                            src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/Whats_in_a_Loan_Payment.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Interest is the cost of borrowing money. What are some
                          ways you can lower your cost of borrowing?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Interest.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Interest);
