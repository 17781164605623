import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();
const AssetInsurance = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/auto-home-rent-insurance.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Auto, Homeowner&apos;s, and Renter&apos;s Insurance</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/auto-home-renters-left.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          Auto insurance is required by law in most states, homeowner&apos;s insurance is required by your mortgage lender, and renter&apos;s insurance is almost always required by your landlord. If you own a car or home or you rent an apartment, you will need to have these types of insurance to protect your assets and personal belongings. That&apos;s why auto, homeowner&apos;s and renter&apos;s insurance are so important to your financial life plan.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>Auto Insurance</b>
                        <br />
                        Auto insurance is a type of insurance that is required by law in most states if you own a car. Auto insurance helps pay for repairs and bodily injuries in case of an auto accident. Instead of paying for auto accidents out of your own pocket, you pay an annual or semi-annual premium to an insurance provider.
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b className={ classes.didYouKnow }>DID YOU KNOW:</b>
                          {' '}
                          The national car insurance average rate is about $1,470 per year of coverage according to
                          {' '}
                          <a className={ classes.anchorStyle } href="https://www.thezebra.com/states/" target="_blank" rel="budget-fy2020 noopener noreferrer">The Insurance Zebra</a>
                          .
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        Factors that influence your auto insurance premiums are:
                        <ul>
                          <li>
                            <b>Deductible:</b>
                            {' '}
                            just like health insurance, a higher deductible means you pay more for accident expenses before your auto insurer starts paying. Since a higher deductible means you are responsible for more of these expenses, your premium is lower.
                          </li>
                          <li>
                            <b>Your driving history:</b>
                            {' '}
                            if you have a history of accidents or speeding tickets, auto insurers will classify you as a higher risk driver and charge you more for insurance. Driving safely and obeying the law can save you big money when it comes to auto insurance. How big? One speeding ticket
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.carinsurance.com/how-much-insurance-goes-up-for-speeding-ticket.aspx" target="_blank" rel="budget-fy2020 noopener noreferrer">can raise your auto insurance rate by 30% or more</a>
                            .
                            Slow down, give your wallet a break.
                          </li>
                          <li>
                            <b>Your vehicle:</b>
                            {' '}
                            {' '}
                            a newer, more valuable car typically costs more to insure as repairs will be more expensive. But a safer vehicle could lower your insurance premium as well.
                          </li>
                          <li>
                            <b>How many miles you drive:</b>
                            {' '}
                            {' '}
                            the more time you spend on the road, the higher the chances are that you get into an accident, and the more your auto insurer will charge you.
                          </li>
                        </ul>
                        <p>
                          Auto insurance coverage is typically shown in a three-number format like this 20/40/15. What this stands for is $20,000 bodily injury per person per accident, $40,000 bodily injury for all persons per accident, and $15,000 property damage liability per accident.
                          {' '}
                          <a className={ classes.anchorStyle } href="https://www.carinsurance.com/Articles/how-much-car-insurance-should-you-buy.aspx" target="_blank" rel="budget-fy2020 noopener noreferrer">Carinsurance.com</a>
                          {' '}
                          recommends that you get at least 100/300/100.
                        </p>
                        <ul>
                          <li>
                            <b>Bodily injury per person per accident:</b>
                            {' '}
                            how much your insurance company will help pay for another person&apos;s injuries if an accident is your fault.
                          </li>
                          <li>
                            <b>Bodily injury for all persons per accident:</b>
                            {' '}
                            how much your insurance company will help pay for the total personal injuries if an accident is your fault. For example, if your coverage is $300,000, four people are injured in an accident that is your fault, and each person requires $25,000 of medical expenses, your insurance will cover the bill.
                          </li>
                          <li>
                            <b>Property damage liability per accident:</b>
                            {' '}
                            how much your insurance company will help pay for the total property damage if an accident is your fault. For example, if you have $100,000 of coverage, rear end another car, and cause $10,000 of damage to that car, your insurance will cover the bill.
                          </li>
                        </ul>
                        <p>Your car insurance provider will cover your costs up to those amounts. Anything greater than those amounts will be your responsibility.</p>
                        <p>
                          <b>Homeowner&apos;s Insurance</b>
                          <br />
                          Homeowner&apos;s insurance is a type of insurance that is not required by law but always required by your mortgage provider. Home insurance covers your home and the assets in your home from losses and damages in three main categories: interior and exterior damage, loss or damage of personal belongings, and injury that might happen on your property.
                          Instead of paying for accidents or natural disasters out of your own pocket, you pay an annual premium to a homeowner&apos;s insurance provider to help cover these unexpected costs.
                        </p>
                        <p>Examples of homeowner&apos;s insurance coverage are below.</p>
                        <ul>
                          <li>
                            <b>Interior or exterior damage:</b>
                            {' '}
                            if your washing machine suddenly breaks and spews water all over your house, homeowner&apos;s insurance will help with the cost of repairs to your floors and walls after you pay your deductible for the year.
                          </li>
                          <li>
                            <b>Loss or damage of personal belongings:</b>
                            {' '}
                            if your furniture is destroyed in a fire, homeowner&apos;s insurance will help with the cost to replace your furniture after you pay your deductible for the year.
                          </li>
                          <li>
                            <b>Personal injury:</b>
                            {' '}
                            If your dog bites a guest or a guest trips and falls down your stairs, you may be held legally responsible for those injuries. Homeowner&apos;s insurance can help cover the cost of your guest&apos;s injuries and legal costs as well.
                          </li>
                        </ul>
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <p>
                            <b className={ classes.didYouKnow }>DID YOU KNOW:</b>
                            {' '}
                            The national homeowner&apos;s insurance average rate is about $1,300 per year of coverage according to
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.insurance.com/home-and-renters-insurance/home-insurance-basics/average-homeowners-insurance-rates-by-state" target="_blank" rel="budget-fy2020 noopener noreferrer">Insurance.com</a>
                            .
                          </p>
                          <p>A quick way to estimate homeowner&apos;s insurance is to take $3.50 for every $1,000 of property value. For example, homeowner&apos;s insurance for a $500,000 home would cost roughly:</p>
                          <p>$3.50 x $500,000 home ÷ 1,000 = $1,750 annual premium</p>
                          <p>If you own a home or other real estate, insurance is a must-have. Homes are expensive and paying for repairs out of your own pocket could be an unexpected financial disaster.</p>
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>Renter&apos;s Insurance</b>
                        <br />
                        Renter&apos;s insurance is a type of insurance that is almost always required by your landlord. Renter&apos;s insurance covers your personal belongings up to a certain dollar limit if they are stolen or damaged while you are renting an apartment. Instead of paying to replace or repair your personal belongings out of your own pocket, you pay an annual premium to an insurance provider.
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b className={ classes.didYouKnow }>DID YOU KNOW:</b>
                          {' '}
                          The national renter&apos;s insurance average rate is about $200 per year of coverage according to
                          {' '}
                          <a className={ classes.anchorStyle } href="https://www.insurance.com/average-renters-insurance-rates" target="_blank" rel="budget-fy2020 noopener noreferrer">Insurance.com</a>
                          .
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        If you rent an apartment, insurance is a must-have. If your apartment burns down and all your personal belongings are destroyed, renter&apos;s insurance will help you replace them. For just a couple hundred dollars a year, you can sleep soundly at night knowing your belongings are covered in case of an unexpected disaster.
                      </p>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level8/asset-insurance/exercise" startTime={ startTime } level={ 8 } exercise={ 3 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
AssetInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( AssetInsurance );
