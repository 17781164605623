import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import scrollToComponent from 'react-scroll-to-component';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { loader } from 'graphql.macro';
import Sidebar from './sidebar';
import Pageloader from '../../components/ui/pageloader';
import { withStyles } from '@material-ui/core/styles';
import headerFI from '../../assets/img/pwi-investments/buy_car.png';
import LogoImage from '../../assets/img/logo.png';
import styles from './styles';
import Walk from '../../assets/img/pwi-investments/walk.png';
import ClearTime from '../../assets/img/pwi-investments/clear-timeline-blue.png';
import AffordabilityCalculator from '../../components/pwi-buying-a-car/affordability-calculator';
import BuyingLeasingConsPros from '../../components/pwi-buying-a-car/buying-leasing-cons-pros';
import CarDashboard from '../../components/pwi-buying-a-car/dashboard';
import SettingTimeline1 from '../../components/pwi-buying-a-car/setting-timelines1';
import FirstbuyingCarTotalPurchase from '../../components/pwi-buying-a-car/first_buying-car-total-purchase';
import FirstBuyingCarDiscountDebate from '../../components/pwi-buying-a-car/first-buying-car-discount-rebate';
import FirstCarTradeIn from '../../components/pwi-buying-a-car/first-car-trade-in';
import FirstBuyingSalesTaxDocumentationFee from '../../components/pwi-buying-a-car/first-buying-car-sales-tax-documentation-fees';
import FirstBuyingDownloadPayment from '../../components/pwi-buying-a-car/first-download-payment';
import FirstCarLoan from '../../components/pwi-buying-a-car/first-car-loan';
import FirstBuyingCarMonthlyExpenses from '../../components/pwi-buying-a-car/first-buying-car-monthly-expenses';
import FirstbuyingCarSummary from '../../components/pwi-buying-a-car/first-buying-car-summary';
import FirstLeasingSettingTmelines from '../../components/pwi-buying-a-car/first-car-leasing-setting-timlines';
import FirstCarLeasingDownPayment from '../../components/pwi-buying-a-car/first-car-leasing-down-payment';
import FirstCarLeasePayment from '../../components/pwi-buying-a-car/first-car-lease-payment';
import FirstCarLeaseMonthlyExpenses from '../../components/pwi-buying-a-car/first-car-lease-monthly-expenses';
import FirstCarLeaseSummary from '../../components/pwi-buying-a-car/first-car-lease-summary';
import SecondBuyingCarSettingTimelines from '../../components/pwi-buying-a-car/second-car-buying-setting-timelines';
import SecondBuyingCarSummary from '../../components/pwi-buying-a-car/second-car-summary';
import SecondCarBuyingTotalPurchase from '../../components/pwi-buying-a-car/second-car-buying-total-purchase';
import SecondCarDiscountRebate from '../../components/pwi-buying-a-car/second-car-discount-rebate';
import SecondCarTradeIn from '../../components/pwi-buying-a-car/second-car-trade-in';
import SecondCarSalesRegDocumentation from '../../components/pwi-buying-a-car/second-car-sales-registration-documentation-fees';
import SecondCarDownpayment from '../../components/pwi-buying-a-car/second-car-down-payment';
import SecondCarLoan from '../../components/pwi-buying-a-car/second-car-loan';
import SecondCarMonthlyExpenses from '../../components/pwi-buying-a-car/second-car-monthly-expenses';
import SecondCarLeaseSettingTimelines from '../../components/pwi-buying-a-car/second-car-leasing-setting-timelines';
import SecondCarLeasingDownPayment from '../../components/pwi-buying-a-car/second-car-leasing-downpayment';
import SecondCarLeasePaymentMode from '../../components/pwi-buying-a-car/second-car-lease-payment-mode';
import SecondCarLeaseMonthlyExpenses from '../../components/pwi-buying-a-car/second-car-lease-monthly-expenses';
import SecondcarLeaseSummary from '../../components/pwi-buying-a-car/second-car-lease-summary';
import PwiSave from '../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../components/common/save-popup-success';
import DefinationPopup from '../../components/ui/pwi-sidebar/defination-popup';
import PwiLeftMenu from '../../components/pwi/pwi-left-menu';
import WalkThruOne from '../pwi/wallk-thru/walkThruOne';
import WalkThruTwo from '../pwi/wallk-thru/walkThruTwo';
import WalkThruThree from '../pwi/wallk-thru/walkThruThree';
import WalkThruFour from '../pwi/wallk-thru/walkThruFour';
import WalkThruFive from '../pwi/wallk-thru/walkThruFive';
import WalkThruSix from '../pwi/wallk-thru/walkThruSix';
import WalkThruSeven from '../pwi/wallk-thru/walkThruSeven';
import WalkThruEight from '../pwi/wallk-thru/walkThruEight';
import * as modulesServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import * as moduleServices from '../../calculations/modules-services';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import save from '../../assets/img/build-career-sketch/icon-save-blue.png';
import messages from '../../utilities/pwi-popup-messages';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import SimulatorPanel from '../../components/documents/simulator-panel';
import SummaryDocs from '../../components/documents/documents';

const PWI_DEFINATION = loader('../../graphql/schema/financial-independence/getPWiDefination.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const CHECK_MODULE_EXISTS = loader('../../graphql/schema/fc/check-module-exists.graphql');

const titleText = [
  'Affordability Calculator',
  'Buying vs Leasing Pros and Cons',
  'Dashboard',
  'Setting Timelines',
  'Total Purchase Price',
  'Discount and Rebate',
  'Trade-In',
  'Sales Tax and Registration and Documentation Fees',
  'Down Payment',
  'Car Loan',
  'Monthly Expenses',
  'Summary',
  'Setting Timelines',
  'Down Payment and Drive-Off Fees',
  'Lease Payment',
  'Monthly Expenses',
  'Summary',
  'Setting Timelines',
  'Total Purchase Price',
  'Discount and Rebate',
  'Trade-In',
  'Sales Tax and Registration and Documentation Fees',
  'Down Payment',
  'Car Loan',
  'Monthly Expenses',
  'Summary',
  'Setting Timelines',
  'Down Payment and Drive-Off Fees',
  'Lease Payment',
  'Monthly Expenses',
  'Summary',
];

const BuyingACar = (props) => {
  const { classes, history } = props;
  let bodySection = useRef(null);

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [buyingCarData, setBuyingCarData] = useState({});
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [saveKey, setSaveKey] = useState('');
  const [popupClose, setPopupClose] = useState(false);
  const [dashboardType, setDashboardType] = useState('');
  const [titleDefination, setTitleDefination] = useState('');
  const [definationPopup, setDefinationPopup] = useState(false);
  const [firstBuyCar, setFirstBuyCar] = useState(false);
  const [firstLeaseCar, setFirstLeasecar] = useState(false);
  const [secondBuyCar, setSecondBuyCar] = useState(false);
  const [secondLeaseCar, setSecondLeaseCar] = useState(false);
  const [completedSlides, setCompletedSlides] = useState({ 1: true });
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: false });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [percentageVal, setPercentageVal] = useState(0);
  const [percentageVal2, setPercentageVal2] = useState(0);
  const [finalMnthlyAfterIncome, setFinalMnthlyAfterIncome] = useState(0);
  const [finalMnthlyExpenses, setFinalMnthlyExpenses] = useState(0);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [loadValue, setLoadValue] = React.useState(false);

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module12' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 1; c <= 33; c += 1) {
            completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setBuyingCarData(data);
        } else {
          setBuyingCarData({});
          setActiveScenarioData({});
        }
        setActiveStep(1);
        setLoading(false);
      });
    },
    onError() {
      setClearModule(false);
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setBuyingCarData({});
        }
        setActiveStep(1);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });

  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module12',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/buying-a-car' : url, saveContinueBtn }, ...popupdetails })
  }

  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
  } );

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  }

  useEffect(() => {
    const progressBar = (activeStep) * (100 / 31);
    setProgress(progressBar);
  }, [activeStep]);

  const handleNext = (index) => {
    const completedSlide = { ...completedSlides };
    completedSlide[index] = true;
    setCompletedSlides(completedSlide);
    window.scrollTo(0, 0);
    if (activeStep === 3 && (dashboardType === 'firstCar' || buyingCarData.existingDashType === 'firstCar')) {
      setActiveStep(4);
    } else if (activeStep === 3 && (dashboardType === 'firstLeaseCar' || buyingCarData.existingDashType === 'firstLeaseCar')) {
      setActiveStep(13);
    } else if (activeStep === 3 && (dashboardType === 'SecondCar' || buyingCarData.existingDashType === 'SecondCar')) {
      setActiveStep(18);
    } else if (activeStep === 3 && (dashboardType === 'SecondLeaseCar' || buyingCarData.existingDashType === 'SecondLeaseCar')) {
      setActiveStep(27);
    } else if (activeStep === 12) {
      setFirstBuyCar(true);
      setActiveStep(3);
    } else if (activeStep === 17) {
      setFirstLeasecar(true);
      setActiveStep(3);
    } else if (activeStep === 26) {
      setSecondBuyCar(true);
      setActiveStep(3);
    } else if (activeStep === 31) {
      setSecondLeaseCar(true);
      setActiveStep(3);
    } else {
      setActiveStep(activeStep + 1);
    }
    setMenu(false);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    if ((activeStep === 13 || activeStep === 18 || activeStep === 27) && (dashboardType === 'firstLeaseCar' || buyingCarData.existingDashType === 'firstLeaseCar' || dashboardType === 'SecondCar' || buyingCarData.existingDashType === 'SecondCar' || dashboardType === 'SecondLeaseCar' || buyingCarData.existingDashType === 'SecondLeaseCar')) {
      setActiveStep(3);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleSideMenu = (index) => {
    setActiveStep(index);
    setMenu(false);
  };


  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 20,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 3 || slide === 4) {
      setMenu(true);
    } else if (slide === 5) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };
  const getSliderIncomeGraph = () => {
    let purchaseYear = parseInt(modulesServices.module1Data.start_year);
    let finalMonthlyHomeExpenses = 0;
    if (activeStep >= 1 && activeStep <= 12) {
      if (buyingCarData.purchase_year !== undefined) {
        purchaseYear = buyingCarData.purchase_year;
      }
      finalMonthlyHomeExpenses = modulesServices.totalMonthlyExpensesFirstCar() + modulesServices.monthlyPayment();
    } else if (activeStep >= 13 && activeStep <= 17) {
      if (buyingCarData.leasing_car_start_year !== undefined) {
        purchaseYear = buyingCarData.leasing_car_start_year;
      }
      finalMonthlyHomeExpenses = modulesServices.leasingCarTotalMonthlyExpenses();
    } else if (activeStep >= 18 && activeStep <= 23) {
      if (buyingCarData.leasing_second_car_start_year !== undefined) {
        purchaseYear = buyingCarData.leasing_second_car_start_year;
      }
      finalMonthlyHomeExpenses = modulesServices.leasingSecondCarTotalMonthlyExpenses();
    } else {
      if (buyingCarData.second_purchase_year !== undefined) {
        purchaseYear = buyingCarData.second_purchase_year;
      }
      finalMonthlyHomeExpenses = modulesServices.totalMonthlyExpensesSecondCar() + modulesServices.moreMonthlyPayment();
    }
    setFinalMnthlyExpenses(finalMonthlyHomeExpenses);
    let percentageValue = 0;
    let percentageValue1 = 100;
    let incomeYearData = 0;
    let finalMonthlyAfterTaxIncome = 0;
    const incomeData = modulesServices.incomeStatementCompleteData();
    if (typeof incomeData !== 'undefined' && incomeData !== '' && typeof incomeData[purchaseYear] !== 'undefined' && incomeData[purchaseYear] !== '' && typeof incomeData[purchaseYear]['Total Income'] !== 'undefined' && incomeData[purchaseYear]['Total Income'] !== '') {
      incomeYearData = incomeData[purchaseYear]['Total Income'];
    }
    finalMonthlyAfterTaxIncome = incomeYearData / 12;
    if (finalMonthlyAfterTaxIncome === 0 && finalMonthlyHomeExpenses === 0) {
      percentageValue1 = 0;
      percentageValue = 0;
      setPercentageVal(percentageValue);
      setPercentageVal2(percentageValue1);
    } else if (finalMonthlyAfterTaxIncome === 0) {
      percentageValue1 = 0;
      percentageValue = 100;
      setPercentageVal(percentageValue);
      setPercentageVal2(percentageValue1);
    } else {
      percentageValue = (finalMonthlyHomeExpenses / finalMonthlyAfterTaxIncome) * 100;
      setPercentageVal(percentageValue);
      setPercentageVal2(percentageValue1);
      if (percentageVal >= 100) {
        percentageValue1 = (percentageValue1 / percentageValue) * 100;
        percentageValue = 100;
      }
    }
    setFinalMnthlyAfterIncome(finalMonthlyAfterTaxIncome);
  };

  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);

  const handleCareerSave = (data) => {
    const objectParams = {};
    if (data === 'save') {
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = 'module12';
      objectParams.moduleNumber = 'module12Data';
      setmoduleObjectParams(objectParams);
    }
    setSaveKey(data);
    setPopupClose(true);
  };

  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleCareerSave('save');
  }

  const handlePopUpClose = () => {
    setPopupClose(false);
  };

  useEffect(()=>{
    getSliderIncomeGraph();
  },[buyingCarData])

  const handleUpdatedObject = (data) => {
    getSliderIncomeGraph();
    setBuyingCarData(data);
    modulesServices.setModule12Data(data);
  };

  const handleDoubleClickType = (type) => {
    setDashboardType(type);
    if (type === 'firstCar') {
      setActiveStep(4);
    } else if (type === 'firstLeaseCar') {
      setActiveStep(13);
    } else if (type === 'SecondCar') {
      setActiveStep(18);
    } else if (type === 'SecondLeaseCar') {
      setActiveStep(27);
    }
  };

  const handleDefinationPopup = (def) => {
    setTitleDefination(def);
    setDefinationPopup(true);
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module12";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module12', 'quick-save', activeScenarioData);
      finalObject['module12Data'] = pwiObject.getfilteredBuyingCarObject(moduleServices.module12Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>
        <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleSideMenu} openBeforeLeavePopup={openBeforeLeavePopup} setMenu={setMenu} completedSlides={completedSlides} />
        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={headerFI} alt="" aria-hidden="true" onClick={()=>{setMainMenu(!mainMenu)}} />
                <h3>Buying a Car</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" onClick={()=>{setMenu(!menu);}} className={classes.menuIcon}>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                <h3>{titleText[activeStep - 1]}</h3>
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''}>
                <img src={Walk} alt="" data-for="walk" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>
            {(activeStep === 1) && (
              <AffordabilityCalculator
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 2) && (
              <BuyingLeasingConsPros />
            )}
            {(activeStep === 3) && (
              <CarDashboard
                setDashboardType={setDashboardType}
                dashboardType={dashboardType}
                handleBuyingCarObject={buyingCarData}
                handleDoubleClickType={handleDoubleClickType}
                handleUpdatedObject={handleUpdatedObject}
                firstBuyCar={firstBuyCar}
                firstLeaseCar={firstLeaseCar}
                secondBuyCar={secondBuyCar}
                secondLeaseCar={secondLeaseCar}
              />
            )}
            {(activeStep === 4) && (
              <SettingTimeline1
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 5) && (
              <FirstbuyingCarTotalPurchase
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 6) && (
              <FirstBuyingCarDiscountDebate
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 7) && (
              <FirstCarTradeIn
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 8) && (
              <FirstBuyingSalesTaxDocumentationFee
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 9) && (
              <FirstBuyingDownloadPayment
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
                activeStep={activeStep}
              />
            )}
            {(activeStep === 10) && (
              <FirstCarLoan
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
                activeStep={activeStep}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 11) && (
              <FirstBuyingCarMonthlyExpenses
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                activeStep={activeStep}
                handleUpdatedObject={handleUpdatedObject}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 12) && (
              <FirstbuyingCarSummary
                activeStep={activeStep}
                handleBuyingCarObject={buyingCarData}
              />
            )}
            {(activeStep === 13) && (
              <FirstLeasingSettingTmelines
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 14) && (
              <FirstCarLeasingDownPayment
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 15) && (
              <FirstCarLeasePayment
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 16) && (
              <FirstCarLeaseMonthlyExpenses
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 17) && (
              <FirstCarLeaseSummary
                handleBuyingCarObject={buyingCarData}
              />
            )}
            {(activeStep === 18) && (
              <SecondBuyingCarSettingTimelines
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 19) && (
              <SecondCarBuyingTotalPurchase
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 20) && (
              <SecondCarDiscountRebate
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 21) && (
              <SecondCarTradeIn
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 22) && (
              <SecondCarSalesRegDocumentation
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                activeStep={activeStep}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 23) && (
              <SecondCarDownpayment
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                activeStep={activeStep}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 24) && (
              <SecondCarLoan
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                activeStep={activeStep}
                handleUpdatedObject={handleUpdatedObject}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 25) && (
              <SecondCarMonthlyExpenses
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                activeStep={activeStep}
                handleUpdatedObject={handleUpdatedObject}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 26) && (
              <SecondBuyingCarSummary handleBuyingCarObject={buyingCarData} activeStep={activeStep} />
            )}
            {(activeStep === 27) && (
              <SecondCarLeaseSettingTimelines
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 28) && (
              <SecondCarLeasingDownPayment
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 29) && (
              <SecondCarLeasePaymentMode
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 30) && (
              <SecondCarLeaseMonthlyExpenses
                handleDefinationPopup={handleDefinationPopup}
                handleBuyingCarObject={buyingCarData}
                handleUpdatedObject={handleUpdatedObject}
                percentageVal={percentageVal}
                percentageVal2={percentageVal2}
                finalMnthlyAfterIncome={finalMnthlyAfterIncome}
                finalMnthlyExpenses={finalMnthlyExpenses}
              />
            )}
            {(activeStep === 31) && (
              <SecondcarLeaseSummary
                handleBuyingCarObject={buyingCarData}
              />
            )}
            {definationResult && definationResult.getPWiDefination
              && (
                <DefinationPopup
                  definationPopup={definationPopup}
                  handleDefinationPopupClose={handleDefinationPopupClose}
                  getPWiDefination={definationResult.getPWiDefination}
                />
              )}
          </div>
          <div className={classes.btnGroup}>
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="car" />)}
            {activeStep === 1 ? '' : <Button className={classes.cancel} onClick={handleBack}>Back</Button>}
            <Button className={classes.clearBtn} onClick={clearScenario} aria-hidden="true" data-for="walk" data-tip="Clear your scenario and start fresh."><img src={ClearTime} alt="" /></Button>
            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <Button onClick={handleQuickSave} className={classes.quickSave}><span><img src={save} alt="" data-for="walk" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page." /></span></Button>
            )}
            {activeStep === 3 && (firstBuyCar || firstLeaseCar || secondBuyCar || secondLeaseCar)
              ? (
                <div className={classes.btnGroupSpan}>
                  <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleCareerSave('save'); }}>Save</Button>
                  {' '}
                  <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleNext(activeStep + 1); }}>Next</Button>
                </div>
              ) : <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleNext(activeStep + 1); }}>Next</Button>}
          </div>
          {activeStep === 1 && (
            <div className={classes.bottomText}>
              <p><i>Assumptions: Affordability is calculated with the following details: 20% down payment, 5% sales tax, $500 registration and documentation fees, 80% loan value, 5% interest rate, 4 years term of the loan, $100 maintenance, $150 car insurance, $100 gas expense, and a 10% benchmark for car expenses as a percentage of total income.</i></p>
            </div>
          )}
          <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        </div>
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={saveKey === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={saveKey === 'clear' ? classes.clearModule : ''}>
              {saveKey === 'save' && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={handlePopUpClose}>
                      ×
                  </span>
                  </div>
                  <PwiSave setPopupOpen={setPopupClose} moduleParams={moduleObjectParams} getSaveData={buyingCarData} setLoadValue={setLoadValue}/>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module12' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
    
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
          <SavePopupSucess setLoadValue={setLoadValue}/>
           </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};

BuyingACar.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BuyingACar);
