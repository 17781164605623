import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';
import template from '../../../../assets/img/pf101/img-1.jpg';
import newemail from '../../../../assets/img/pf101/new-email-img.jpg';
import tableFi from '../../../../assets/img/pf101/table_fi.png';

const startTime = new Date();

const PersonalFinance101 = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );

  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>What is Personal Finance?</h3>
                    </div>
                    <div className={ classes.pfVideo }>
                      <YouTube videoId="F89eycANUrQ" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src={ template } alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>What is Personal Finance?</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src={ newemail } alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>Personal finance covers anything that has to do with managing your money and financial life planning:</p>
                          <ul className={ classes.pfList }>
                            <li>Budgeting income, taxes, expenses, and cash flow</li>
                            <li>Saving and investing</li>
                            <li>Receiving and repaying loans</li>
                            <li>Retirement planning.</li>
                          </ul>
                          <p>Imagine trying to play a sport without knowing the rules. Or, living in a foreign country without knowing the language. You wouldn&apos;t play the sport too well and you wouldn&apos;t last too long in the foreign country. The same applies to your life. Personal finance skills and knowledge are used every day by all people at every age. You need to know the rules of the game and be able to speak the language!</p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>
                            <b>In Personal Finance 101, you will learn the rules of the game, the language of money, and have a financial life toolkit to use for the rest of your life!</b>
                            {' '}
                            Let&apos;s start with an essential equation:
                          </p>
                          <div className={ classes.grayBox }>
                            <p><b>Net Worth = Assets (what you own) - Liabilities (what you owe)</b></p>
                            <ul>
                              <li>
                                <b>Asset:</b>
                                {' '}
                                anything that you own that has value and can be converted into cash. For example, if you own a car, it&apos;s an asset. The cash in your pocket is an asset.
                              </li>
                              <li>
                                <b>Liability:</b>
                                {' '}
                                money you owe to another person, a bank, or any other institution. For example, student loans are liabilities. A mortgage is a liability.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={ classes.fiImage }>
                        <div className={ classes.lightGallery }>
                          <img className={ classes.imageFi } src={ tableFi } alt="table" />
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>Knowing your net worth is important for two main reasons:</p>
                          <ol>
                            <li>Net worth shows your current financial situation in one, clean number, and</li>
                            <li>Net worth provides a reference point for measuring your future financial progress.</li>
                          </ol>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level1/personal-finance-101/exercise" startTime={ startTime } level={ 1 } exercise={ 1 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
PersonalFinance101.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( PersonalFinance101 );
