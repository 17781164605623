import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from './styles';

const InputSheet = ( props ) => {
  const {
    classes, dispalyObj, rangeList,
  } = props;

  return (
    <div className={ classes.modalBodyData }>
      <div className={ classes.incomeSheetModal }>
        <div className={ classes.popupTable }>
          <table className={ classNames( classes.incomeSheetTable, classes.incomeTablePopup ) }>
            <thead>
              <tr className={ classes.topIncomeBg }>
                <th>Year Ending</th>
                <th>&nbsp;</th>
                {
                  rangeList.map( ( range ) => (
                    <th>{range + ( dispalyObj.year )}</th>
                  ) )
                }
              </tr>
            </thead>
            <tbody>
              <tr className={ classNames( classes.blankSpace, classes.emptyRowdata ) }>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classes.blueRowData }>
                <td>Balance Sheet</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>

              {
                dispalyObj['Balance Sheet'] && Object.keys( dispalyObj['Balance Sheet'] ).map( ( grossTitle ) => (
                  <tr className={ classes.blueBorderRow }>
                    <td className={ classes.subHeadingData }>
                      {grossTitle}
                    </td>
                    <td className={ classes.lightBlueBg }>&nbsp;</td>
                    {
                        rangeList.map( ( range ) => {
                          const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Balance Sheet'][grossTitle];
                          return (
                            <td className={ value < 0 && classes.minusValue }>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0 } prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        } )
}
                  </tr>
                ) )
              }
              <tr className={ classes.emptyRowdata }>
                <td className={ classes.subHeadingData }>&nbsp;</td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classes.blueRowData }>
                <td>Income Statement</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj['Income Statement'] && Object.keys( dispalyObj['Income Statement'] ).map( ( grossTitle ) => (
                  <tr className={ classes.incomeStmnt }>
                    <td className={ classes.subHeadingData }>
                      {grossTitle}
                    </td>
                    <td className={ classes.lightBlueBg }>&nbsp;</td>
                    {
                        rangeList.map( ( range ) => {
                          const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Income Statement'][grossTitle];
                          return (
                            <td className={ value < 0 && classes.minusValue }>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        } )
}
                  </tr>
                ) )
              }
              <tr className={ classes.emptyRowdata }>
                <td className={ classes.subHeadingData }>&nbsp;</td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classes.blueRowData }>
                <td>Tax Statement</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj['Tax Statement'] && Object.keys( dispalyObj['Tax Statement'] ).map( ( grossTitle ) => (
                  <tr>
                    <td className={ classes.subHeadingData }>
                      {grossTitle}
                    </td>
                    <td className={ classes.lightBlueBg }>&nbsp;</td>
                    {
                        rangeList.map( ( range ) => {
                          const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Tax Statement'][grossTitle];
                          return (
                            <td className={ value < 0 && classes.minusValue }>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        } )
}
                  </tr>
                ) )
              }

              <tr className={ classes.emptyRowdata }>
                <td className={ classes.subHeadingData }>&nbsp;</td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classes.blueRowData }>
                <td>Cash Flow</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj['Cash Flow'] && Object.keys( dispalyObj['Cash Flow'] ).map( ( grossTitle ) => (
                  <tr>
                    <td className={ classes.subHeadingData }>
                      {grossTitle}
                    </td>
                    <td className={ classes.lightBlueBg }>&nbsp;</td>
                    {
                        rangeList.map( ( range ) => {
                          const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Cash Flow'][grossTitle];
                          return (
                            <td className={ value < 0 && classes.minusValue }>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        } )
}
                  </tr>
                ) )
              }
              <tr className={ classes.emptyRowdata }>
                <td className={ classes.subHeadingData }>&nbsp;</td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classes.blueRowData }>
                <td>Tax & Interest Expenses</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              {
                dispalyObj['Tax - Interest Expenses'] && Object.keys( dispalyObj['Tax - Interest Expenses'] ).map( ( grossTitle ) => (
                  <tr className={ classes.italicText }>
                    <td className={ classes.subHeadingData }>
                      {grossTitle}
                    </td>
                    <td className={ classes.lightBlueBg }>&nbsp;</td>
                    {
                        rangeList.map( ( range ) => {
                          const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Tax - Interest Expenses'][grossTitle];
                          return (
                            <td className={ value < 0 && classes.minusValue }>
                              {value >= 0 ? (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="$" />
                              ) : (
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="($" suffix=")" />
                              )}
                            </td>
                          );
                        } )
}
                  </tr>
                ) )
              }
              <tr className={ classes.emptyRowdata }>
                <td className={ classes.subHeadingData }>&nbsp;</td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classes.blueRowData }>
                <td>Key Performance Indicators (KPIs)</td>
                <td>&nbsp;</td>
                <td colSpan="15">&nbsp;</td>
              </tr>
              <tr className={ classes.italicText }>
                <td className={ classes.subHeadingData }>
                  <i>Debt-to-Income Ratio</i>
                </td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                {
                    rangeList.map( ( range ) => {
                      const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Debt-to-Income Ratio Ratio'];
                      return (
                        <td className={ value < 0 && classes.minusValue }>
                          {value >= 0 ? (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="" suffix="%" />
                          ) : (
                            <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="(" suffix="%)" />
                          )}
                        </td>
                      );
                    } )
}
              </tr>
              <tr className={ classes.italicText }>
                <td className={ classes.subHeadingData }>
                  <i>Monthly Liquidity Ratio</i>
                </td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                {
                    rangeList.map( ( range ) => {
                      const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Monthly Liquidity Ratio'];
                      return (
                        <td className={ value < 0 && classes.minusValue }>
                          {value >= 0 ? (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="" suffix="x" />
                          ) : (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="(" suffix="x)" />
                          )}
                        </td>
                      );
                    } )
}
              </tr>
              <tr className={ classes.italicText }>
                <td className={ classes.subHeadingData }>
                  <i>Current Ratio</i>
                </td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                {
                    rangeList.map( ( range ) => {
                      const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Current Ratio'];
                      return (
                        <td className={ value < 0 && classes.minusValue }>
                          {value >= 0 ? (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="" suffix="x" />
                          ) : (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="(" suffix="x)" />
                          )}
                        </td>
                      );
                    } )
}
              </tr>
              <tr className={ classes.italicText }>
                <td className={ classes.subHeadingData }>
                  <i>Savings Rate</i>
                </td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                {
                    rangeList.map( ( range ) => {
                      const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Savings Rate'];
                      return (
                        <td className={ value < 0 && classes.minusValue }>
                          {value >= 0 ? (
                            <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="" suffix="%" />
                          ) : (
                            <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="(" suffix="%)" />
                          )}
                        </td>
                      );
                    } )
}
              </tr>
              <tr className={ classes.italicText }>
                <td className={ classes.subHeadingData }>
                  <i>Investment Assets to Total Assets</i>
                </td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                {
                    rangeList.map( ( range ) => {
                      const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Investment Assets to Total Assets'];
                      return (
                        <td className={ value < 0 && classes.minusValue }>
                          {value >= 0 ? (
                            <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="" suffix="%" />
                          ) : (
                            <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="(" suffix="%)" />
                          )}
                        </td>
                      );
                    } )
}
              </tr>
              <tr className={ classes.italicText }>
                <td className={ classes.subHeadingData }>
                  <i>Total Assets to Total Debt</i>
                </td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                {
                    rangeList.map( ( range ) => {
                      const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Total Assets to Total Debt'];
                      return (
                        <td className={ value < 0 && classes.minusValue }>
                          {value >= 0 ? (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="" suffix="x" />
                          ) : (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="(" suffix="x)" />
                          )}
                        </td>
                      );
                    } )
}
              </tr>
              <tr className={ classes.italicText }>
                <td className={ classes.subHeadingData }>
                  <i>Solvency Ratio</i>
                </td>
                <td className={ classes.lightBlueBg }>&nbsp;</td>
                {
                    rangeList.map( ( range ) => {
                      const value = dispalyObj.inputData[range + ( dispalyObj.year )]['Solvency Ratio'];
                      return (
                        <td className={ value < 0 && classes.minusValue }>
                          {value >= 0 ? (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ value !== null ? value : 0  } prefix="" suffix="x" />
                          ) : (
                            <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ -(value < 0 && value !== null ? value : 0) } prefix="(" suffix="x)" />
                          )}
                        </td>
                      );
                    } )
}
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

InputSheet.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.string.isRequired,
  rangeList: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( InputSheet );
