import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import styles from './styles';
import mcsTopImage from '../../../assets/img/mcs/level2_bg.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import levelIcon from '../../../assets/img/mcs/MCS-level03.png';
import fiGraphic from '../../../assets/img/mcs/mcs-financial-independence.png';
import progressImage from '../../../assets/img/mcs/100.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import { loader } from 'graphql.macro';
import { useQuery, useMutation } from '@apollo/react-hooks';
import Pageloader from '../../../components/ui/pageloader';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const SAVEMYCAREERSKETCH = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');

const staticLevelData = [
  {
    "id": "7",
    "user_id": 214,
    "level": 2,
    "option_id": 1,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:29:33"
  },
]
const McsLevelTwo = (props) => {
  const { classes, history } = props;
  const [firstActivityData, setFirstActivityData] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [levelPercentage, setLevelPercentage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
    onCompleted({
      saveMyCareerSketch: {
        status,
      },
    }) {
      if (status) {
        setLoading(false);
        history.push('./level2/activity1');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        message
      ));
      setErrorMessage(formatedErrors);
      setLoading(false);
    },
  });
  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "2",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setLevelPercentage(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent);
      setFirstActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 1)[0] : staticLevelData.filter(obj => obj.option_id === 1)[0]);
    },
    onError() {
      setLoading(false);
    },
  });
  React.useEffect(() => {
    if (mcsLevelWiseData) {
      setLoading(false);
    }
  }, [mcsLevelWiseData]);

  const handleCompletedFirstActivity = (activity_id) => {
    setLoading(true);
    submitFirstActivity({
      variables: {
        data: {
          level: "2",
          option_id: activity_id,
        },
      },
    });
  }
  const handleGotoNextLevel = () => {
    history.push('./level3');
  }
  const handleOpenLevelOneActivity = () => {
    history.push('./level2/activity1');
  }
  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.mcsLevelOne}>
        <div className={classes.topImage}>
          <img src={mcsTopImage} alt="mcs" />
        </div>
        <div className={classes.container}>
          <div className={classes.mcsRow}>
            <div className={classes.mcsLogoImage}>
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.mcsLevelNum}>
              <h3>LEVEL 02</h3>
              <img src={levelIcon} alt="info" className={classes.iconImage} />
            </div>
          </div>
          <div className={classes.mcsBodyContent}>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.leftBlock}>
                  <h1 className={classes.mcsLevelTitle}>Develop your vision</h1>
                  <div className={classes.qPosition}>
                    <div className={classes.questionBlock}>
                      <p>Developing your vision for the future is the most important guiding principle of smarter tomorrows. </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>When you have a clear idea of where you want to be, it gives purpose to your planning. And when you listen to your inner voice and align it with your vision and goals, you’ll find it easier to stay on your chosen path and move forward with confidence and peace of mind.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>You’ve reviewed at least one career sketch story, traced the stepping stones, and noted valuable insights and powerful strategies. The first step in building your career sketch is to write your own story. This story does not have to be your one and only definitive story – it could be one of many potential stories of your next 10 years. Use My Career Sketch as a chance to explore your potential scenarios.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>You may know exactly where you want to be in 10 years. Or your vision might be a little cloudy as you compare options. And maybe you’ve never given a single thought to your future self. Wherever you find yourself, it’s all good. We’ve got you covered.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>This is the time to dream big. </p>
                    </div>
                    {firstActivityData && firstActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleGotoNextLevel}>NEXT LEVEL {'>'}</button>
                    </div> : <div className={classes.startButton}>
                      <button type="button" onClick={() => handleCompletedFirstActivity("1")}>start</button>
                    </div>}
                    {errorMessage && <span>{errorMessage}</span>}
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.rightBlock}>
                  <div className={classes.fiGraphicImage}>
                    <img src={fiGraphic} alt="fi" />
                  </div>
                  <div className={classes.visionVideo}>
                    <video id="bgvid" preload="auto" muted autoPlay="true" loop playsinline>
                      <source src="https://inkwiry-videos.s3.us-east-2.amazonaws.com/mcs-levels/Level-01.mp4" type="video/mp4" />
                    </video>
                  </div>
                  <div className={classes.activeRow}>
                    <div className={classes.activities}>
                      <h4>Level 02 Activity</h4>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={firstActivityData && firstActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Write your own story
                        </label>
                        {firstActivityData && firstActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={handleOpenLevelOneActivity}> Review</button> : ""}
                      </div>
                    </div>
                    <div className={classes.myLevelProgress}>
                      <h4>My Level 02 Progress</h4>
                    </div>
                    <div className={classes.progressPuzzle}>
                      <span className={classes.progressPercent}>{levelPercentage}%</span>
                      <p className={classes.puzzleBar}>
                        <img src={progressImage} alt="info" />
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mcsFooter}>
          <div className={classes.container}>
            <div className={classes.footerRow}>
              <div className={classes.copyRight}>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={classNames(classes.copyRight, classes.informedText)}>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.mcsFluid}>
            <p className={classes.termLinks}>
              <a href="/">Terms of Service</a>
              &nbsp;//&nbsp;
              <a href="/">Privacy Policy</a>
              &nbsp;//&nbsp;
              <a href="/">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsLevelTwo.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsLevelTwo);