import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from './styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import ModuleNavBar from '../../../components/documents/module-nav-bar';


const CareerSketchTimeline = ( props ) => {
  const {
    classes,moduleName
  } = props;

  return (
    <div className={ classes.careerSketchTimeline }>
      <ReactTooltip id="career-timeline-title" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop}` } />
      <h3>
        Career Sketch Timeline
        <img src={ infoIcon } alt="" data-for="career-timeline-title" data-tip="The Career Sketch Timeline shows you your complete financial life plan in chronological order. Modules shown in color with timelines below are activated modules, which means you have a scenario loaded in that module. Modules that are grey are inactive, which means you do not have a scenario loaded in that module. Click on any icon to navigate to that module. Don't forget to save your work before you leave the page!" />
      </h3>
      <ModuleNavBar moduleName={moduleName} fromPageType={'pwi'}/>
    </div>
  );
};

CareerSketchTimeline.propTypes = {
  classes: PropTypes.object.isRequired,
  moduleName: PropTypes.string,
};
CareerSketchTimeline.defaultProps = {
  moduleName: 'module1'
}

export default withStyles( styles )( CareerSketchTimeline );
