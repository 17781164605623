import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const ExtraLoanPaymentt = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Extra Loan Payment </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src=" https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/extra-loan-payment.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Extra Loan Payment
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/extra-loan-payment-left.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          One of the ways you can lower the cost of a loan is by{' '}
                          <Link
                            to="/finance-explained/lower-cost-of-a-loan"
                            target="_blank">
                            making extra payments on the loan.
                          </Link>{' '}
                          But is that a smart move?
                        </p>
                        <p>
                          When considering making extra payments on your loan to
                          lower the cost of your loan, there are three factors
                          to consider:
                          <ul className={classes.listStyleDecimal}>
                            <li>1) &nbsp;Interest rate on the loan,</li>
                            <li>
                              2) &nbsp;Potential return on an investment, and
                            </li>
                            <li>3) &nbsp;Risk tolerance.</li>
                          </ul>
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          You have two options when considering making an extra
                          loan payment. Do you use your extra cash to pay down
                          the loan or invest that money instead? It depends.
                          Every dollar you use to make extra payments on your
                          loan is an immediate, positive return to you. How? The
                          interest on your loan is calculated based on your
                          principal. If you make an extra $100 payment with a 4%
                          interest rate, that&apos;s $100 less of principal
                          multiplied by 4% equals $4 less of interest per year.
                          That&apos;s an extra $4 in your pocket, guaranteed.
                          Plus, you'll repay your loan faster, too. Check out{' '}
                          <Link to="/financial-calculators/extra-loan-payment">
                            this tool to see how fast.
                          </Link>{' '}
                        </p>
                        <p>
                          But what about an investment? If the investment you
                          plan to make can achieve a higher after-tax rate of
                          return than the interest rate on your loan, you should
                          make that investment under one condition: you manage
                          the risk.
                        </p>
                        <p>
                          Making an extra payment on your loan is a guaranteed
                          way to lower the cost of your loan, plain and simple.
                          But it&apos;s not that simple when it comes to a
                          potential investment. Investments carry risk and
                          aren&apos;t guaranteed to make a return. What if your
                          investment goes wrong? Risk tolerance is a huge factor
                          in this analysis. You take a risk to make an
                          investment, and as long as you understand the risk
                          fully, you are prepared to make that investment.
                        </p>
                        <p>
                          Now let&apos;s get into the dollars and cents.
                          Here&apos;s the example: a $100,000 loan with 10 years
                          remaining and a 4.0% interest rate. You have $400
                          extra per month. You can either invest the money into
                          an ETF that has earned 5.5% per year over the last 15
                          years or make an extra payment on your loan. Assuming
                          a 15% capital gains tax, what would be the better
                          option?
                        </p>
                        <p>
                          We need to calculate the after-tax rate of return on
                          that potential investment:
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp; After-Tax Rate of Return =
                          Pre-Tax Return × (1 - Tax Rate) <br />
                          &nbsp;&nbsp;&nbsp;&nbsp; After-Tax Rate of Return =
                          5.5% × (1 - 15%)
                          <br />
                          &nbsp;&nbsp;&nbsp;&nbsp; After-Tax Rate of Return =
                          4.675%
                        </p>
                        <p>
                          Should you make an extra payment on your loan? It
                          depends on the interest rate of that loan, the
                          potential return on investment and your risk
                          tolerance. Calculate your after-tax return on
                          investment and consider the risk. Then, make your
                          decision.
                        </p>
                      </div>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link href="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
ExtraLoanPaymentt.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ExtraLoanPaymentt);
