import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import inkwiryConnectLftBanner from '../../assets/img/connect/inkwiry_connect_lft_banner.jpg';
import PF101IconSquare from '../../assets/img/connect/PF101-icon-square.png';
import financeExplained from '../../assets/img/connect/finance-explained.png';
import MCSIconSquare from '../../assets/img/connect/MCS-icon-square.png';

const Connect = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.connectBody }>
        <div className={ classes.connectBanner }>
          <div className={ classes.container }>
            <div className={ classes.connectBannerCnt }>
              <div className={ classes.connectbannerTitle }>
                <Typography variant="h2" component="h2">Inkwiry Connect</Typography>
              </div>
              <div className={ classes.connectbannerImg }>
                <figure>
                  <img src={ inkwiryConnectLftBanner } alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.connectCntSec }>
          <div className={ classes.container }>
            <div className={ classes.connectCntTitle }>
              <Typography variant="h3" component="h3">Connect with your world and your future.</Typography>
              <p>
                Inkwiry Connect is a growing collection of videos and online courses designed
                { ' ' }
                <span>to help you broaden your understanding, shape your vision and develop essential knowledge and skills.</span>
              </p>
            </div>
            <div className={ classes.connectPowerup }>
              <div className={ classes.connectPowerupLeft }>
                <Link href="/finance-explained">
                  <figure>
                    <img src={ financeExplained } alt="" />
                  </figure>
                </Link>
              </div>
              <div className={ classes.connectPowerupRight }>
                <Typography variant="h4" component="h4">Finance Explained by Inkwiry</Typography>
                <p>
                  <span className={ classes.italic }>
                    Get smarter about your money with just one email per week. The best part? It&apos;s completely free to sign up.
                  </span>
                </p>
                <p>Finance Explained by Inkwiry is a weekly series that demystifies the language of finance and helps you get smarter about your money. Every week, Inkwiry delivers valuable insights and powerful financial life strategies in an easy-to-understand, bite-sized email. Ready to grow your financial knowledge and take control of your financial future? Finance Explained is a great place to start.</p>
                <p>
                  <Link href="/finance-explained">
                    SEND ME FINANCE EXPLAINED
                    { ' ' }
                    <i className="las la-greater-than" />
                  </Link>
                </p>
              </div>
            </div>
            <div className={ classes.connectPowerup }>
              <div className={ classes.connectPowerupLeft }>
                <Link href="/personal-finance-101">
                  <figure>
                    <img src={ PF101IconSquare } alt="" />
                  </figure>
                </Link>
              </div>
              <div className={ classes.connectPowerupRight }>
                <Typography variant="h4" component="h4">Personal Finance 101</Typography>
                <p><span className={ classes.italic }>In five hours or less, learn the language of finance along with concepts and strategies for a lifetime of smart money management, all in a gamified experience.</span></p>
                <p>Earn points and move up the ranks as you build your money skills and knowledge. In 10 brief, information-packed levels, you’ll gain essential personal finance skills and knowledge that you can use right now and for the rest of your life. Personal Finance 101 breaks it down into bite-size lessons, making it easy for anyone to learn about money.</p>
                <p>Inkwiry&apos;s Personal Finance 101 course is included in all membership plans.</p>
                <p>
                  <Link href="/personal-finance-101">
                    IMPROVE MY MONEY SKILLS
                    { ' ' }
                    <i className="las la-greater-than" />
                  </Link>
                </p>
              </div>
            </div>
            <div className={ classes.connectPowerup }>
              <div className={ classes.connectPowerupLeft }>
                <Link href="/my-career-sketch">
                  <figure>
                    <img src={ MCSIconSquare } alt="" />
                  </figure>
                </Link>
              </div>
              <div className={ classes.connectPowerupRight }>
                <Typography variant="h4" component="h4">My Career Sketch</Typography>
                <p>
                  <span className={ classes.italic }>
                    Spend just four hours in My Career Sketch, and learn a few smart moves that can help you
                    { ' ' }
                    <span className={ classes.bold }>add $100,000+ to your 15-year net worth.</span>
                  </span>
                </p>
                <p>
                  My Career Sketch will jumpstart your career sketch and your financial life plan. With career sketching, you&apos;ll know exactly where your education, your career and your wealth
                  will be 5, 10, 15 years from now, unlike those who leave their futures to chance, because you will write down your dreams and goals, make a plan to reach them and set your personal finance skills and knowledge into action. The smart moves you’ll learn will change the way you think about managing your money and your debt, and help you build a life you’ll love.
                </p>
                <p>My Career Sketch is included in all membership plans.  </p>
                <p>
                  <Link href="/my-career-sketch">
                    JUMPSTART MY CAREER SKETCH
                    { ' ' }
                    <i className="las la-greater-than" />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Connect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Connect );
