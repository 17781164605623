import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField
} from '@material-ui/core';
// import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi-career-advancement/styles';
// import * as sliderValues from '../../../utilities/existingsliders';
import * as commonFunctions from '../../../utilities/commonFunctions';
// import TickIcon from '../../../assets/img/career-path/401k_1_pic.png';
// import WrongIcon from '../../../assets/img/career-path/401k_2_pic.png';
// import tableImage from '../../../assets/img/pwi-car/pwi-retirement-table.png';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
// import * as moduleServices from '../../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3%',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6%',
  },
  {
    value: 9,
    scaledValue: 9,
    label: '9%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 15,
    scaledValue: 15,
    label: '15%',
  },
];

const PersonalArray = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
  {
    value: 40,
    scaledValue: 40,
    label: '40%',
  },
  {
    value: 60,
    scaledValue: 60,
    label: '60%',
  },
  {
    value: 80,
    scaledValue: 80,
    label: '80%',
  },
  {
    value: 100,
    scaledValue: 100,
    label: '100%',
  },
];

const RetirementPlanning401 = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;

  // const [iconStatus, setIconStatus] = React.useState( false );
  const [Annual_expected401k, setAnnual_expected401k] = React.useState( 0 );
  const [vested_amount_at_end_year, setvested_amount_at_end_year] = React.useState( 0 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.Annual_expected401k !== undefined ) {
      setAnnual_expected401k( handleCareerObject.Annual_expected401k );
    } else {
      updatedValues['Annual_expected401k'] = 5.00;
    }
    if ( handleCareerObject.vested_amount_at_end_year !== undefined ) {
      setvested_amount_at_end_year( handleCareerObject.vested_amount_at_end_year );
    } else {
      updatedValues['vested_amount_at_end_year'] = 0 ;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue;
      newvalue = newvalue > 100 ? 100 : newvalue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'Annual_expected401k': {
        setAnnual_expected401k( newvalue );
        break;
      }
      case 'vested_amount_at_end_year': {
        setvested_amount_at_end_year( newvalue );
        break;
      }
      default:
        break;
    }
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('')
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        When your employer contributes to your retirement, the money that they contribute becomes yours over a certain period of time called a vesting schedule. For example, in the first year, 25% of your employer contributions are yours. Then, 50% in the second, 75% in the third, and 100% in the fourth. That’s an example of a vesting schedule.
      </p>
      <p>
        How much of your employer contributions will be vested at the end of this career move? Enter that percentage below.
      </p><p>
        How much do you expect to earn on your 401(k) contributions? Enter your annual return below.
      </p>
      <div className={ classes.livingExpencesRow }>
        <div className={ classes.livingExpencesDiv }>
          <div className={ classes.livingExpences }>
            <div className={ `${classes.livingExpencesRangeFlex} ${classes.mainFullBlock} ${classes.mainFullBlockFull}` }>
              <div className={ classes.rentGraphBlock }>
                <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew}` }>
                  <p>
                    <span>
                      Vested Amount at End of Career Move
                    </span>
                  </p>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ PersonalArray }
                      min={ 0 }
                      max={ 100 }
                      step={ 0.1 }
                      value={ vested_amount_at_end_year }
                      valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( vested_amount_at_end_year ).toFixed( 2 ) )}` }
                      onChange={ ( e, value ) => updateValue( e, value, 'vested_amount_at_end_year', 'slider' ) }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ `${classes.annualInput} ${classes.annualInputRight}` }>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      value={ vested_amount_at_end_year }
                      decimalScale = { isFocus!=='vested_amount_at_end_year'?2:5 }
                      fixedDecimalScale={isFocus!=='vested_amount_at_end_year'}
                      onValueChange={ ( e ) => updateValue( e, '', 'vested_amount_at_end_year', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                        setIsFocus('vested_amount_at_end_year')
                      } }
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>
              <div className={ classes.rentGraphBlock }>
                <div className={ `${classes.livingExpencesRange} ${classes.livingExpencesRangeFlexNew}` }>
                  <p>
                    <span
                      className={ classes.dottedText }
                      aria-hidden="true"
                      data-for="definationTitle"
                      data-tip="Click for a definition."
                      onClick={ () => handleDefinationPopup(
                        'Annual Expected Pre-Tax Return: Retirement Account',
                      ) }
                    >
                      Annual Expected Pre-Tax Return
                    </span>
                  </p>
                  <div className={ classes.annuvalIncomeGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ arrayValuesMatching }
                      min={ 0 }
                      max={ 20 }
                      step={ 0.1 }
                      value={ Annual_expected401k }
                      valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( Annual_expected401k ).toFixed( 2 ) )}` }
                      onChange={ ( e, value ) => updateValue( e, value, 'Annual_expected401k', 'slider' ) }
                    />
                  </div>
                </div>
                <div className={ classes.livingExpencesMothly }>
                  <div className={ `${classes.annualInput} ${classes.annualInputRight}` }>
                    <NumberFormat
                      customInput={ TextField }
                      thousandSeparator
                      value={ Annual_expected401k }
                      decimalScale = { isFocus!=='Annual_expected401k'?2:5 }
                      fixedDecimalScale={isFocus!=='Annual_expected401k'}
                      onValueChange={ ( e ) => updateValue( e, '', 'Annual_expected401k', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                        setIsFocus('Annual_expected401k')
                      } }
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />
    </div>
  );
};

RetirementPlanning401.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // setRetirementConfirmation: PropTypes.func.isRequired,
  // retirementConfirmation: PropTypes.bool.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,

};

export default withStyles( styles )( RetirementPlanning401 );
