import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi-family/styles';
import * as modulesServices from '../../../calculations/modules-services';

const IncomeAfterTaxesFirst = ( props ) => {
  const { classes, inputData } = props;

  const myIncome = ( type, from='table' ) => {
    let displayValue = 0;
    if(inputData.start_year !== undefined && inputData.start_year !== null && inputData.start_year > 0) {
      const incomeData = modulesServices.incomeStatementCompleteData();
      let myincome = 0;
      let sposuesIncome = 0;
      let year = inputData.start_year;
      if ( typeof incomeData !== 'undefined' && incomeData !== '' && typeof incomeData[year] !== 'undefined' && incomeData[year] !== '' ) {
        if ( typeof incomeData[year]['Total Income'] !== 'undefined' && incomeData[year]['Total Income'] !== '' ) {
          myincome = incomeData[year]['Total Income'];
        }
        if ( typeof incomeData[year]['Gross Income'] !== 'undefined' && incomeData[year]['Gross Income'] !== '' && typeof incomeData[year]['Gross Income']['Spouses Income'] !== 'undefined' && incomeData[year]['Gross Income']['Spouses Income'] !== '' ) {
          sposuesIncome = incomeData[year]['Gross Income']['Spouses Income'];
        }
      }
      displayValue = myincome - sposuesIncome;  
    }
    if(type === 'monthly') {
      displayValue = displayValue/12;
    }

    if(from === 'table') {
      return (
        <span className={displayValue < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ displayValue } prefix={ displayValue >= 0 ? '$' : '($' } suffix={ displayValue < 0 && ')' } />
        </span>
      )
    } else {
      return displayValue;
    }

  };

  const totalTaxes = ( type, from='table', label ) => {
    let displayValue = 0;
    let year = inputData.start_year;
    if ( typeof year !== 'undefined' && year !== '' ) {
      const incomeData = modulesServices.incomeStatementCompleteData();
      if ( typeof incomeData !== 'undefined' && incomeData !== '' && typeof incomeData[year] !== 'undefined' && incomeData[year] !== ''
          && typeof incomeData[year][label] !== 'undefined' && incomeData[year][label] !== '' ) {
        displayValue =  incomeData[year][label];
      }
    }
    if(type === 'monthly') {
      displayValue = displayValue/12;
    }

    if(from === 'table') {
      return (
        <span className={displayValue < 0 && classes.dangerText}>
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ displayValue } prefix={ displayValue >= 0 ? '$' : '($' } suffix={ displayValue < 0 && ')' } />
        </span>
      )
    } else {
      return displayValue;
    }
  };

  const taxamount = myIncome('yearly','')+modulesServices.module10Data.preTaxExpectedIncome;

  return (
    <div className={ classes.contentBlock }>
      <p>Based on your inputs, we’ve calculated your total annual and monthly income before taxes and after taxes during your spouse’s first career move. Review the figures below before continuing.</p>
      <div className={ classes.incomeTaxesSec }>
        <table className={ classes.incomeTaxesTable }>
          <thead>
            <tr>
              <th>Income After Taxes</th>
              <th>
                Year&nbsp;{inputData.start_year}
              </th>
              <th>Monthly</th>
            </tr>
          </thead>
          <tbody>
            <tr className={ classes.grayRow }>
              <td>My Income</td>
              <td>{
                myIncome('yearly')
              }
              </td>
              <td>
              {
                myIncome('monthly')
              }
              </td>
            </tr>
            <tr className={ classes.grayRow }>
              <td>Spouse&apos;s Income</td>
              <td>
                <span className={modulesServices.module10Data.preTaxExpectedIncome < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.preTaxExpectedIncome } prefix={ modulesServices.module10Data.preTaxExpectedIncome >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.preTaxExpectedIncome < 0 && ')' } />
                </span>
               </td>
              <td>
                <span className={modulesServices.module10Data.preTaxExpectedIncome < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ modulesServices.module10Data.preTaxExpectedIncome/12 } prefix={ modulesServices.module10Data.preTaxExpectedIncome >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.preTaxExpectedIncome < 0 && ')' } />
                </span>
              </td>
            </tr>
            <tr className={ classes.blueRow }>
              <td>Total Before Tax Income</td>
              <td>
                <span className={taxamount < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ taxamount } prefix={ taxamount >= 0 ? '$' : '($' } suffix={ taxamount < 0 && ')' } />
                </span>
               </td>
              <td>
                <span className={taxamount < 0 && classes.dangerText}>
                    <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ taxamount/12 } prefix={ taxamount >= 0 ? '$' : '($' } suffix={ taxamount < 0 && ')' } />
                </span>
              </td>
            </tr>

            <tr className={ classes.grayRow }>
              <td>Taxes</td>
              <td>
              {
                totalTaxes('yearly','table','Total Taxes')
              }
              </td>
              <td>
              {
                totalTaxes('monthly','table','Total Taxes')
              }
              </td>

            </tr>
            <tr className={ classes.blueRow }>
              <td>Total Income After Taxes</td>
              <td>
              {
                totalTaxes('yearly','table','Income After Taxes')
              }
              </td>
              <td>
              {
                totalTaxes('monthly','table','Income After Taxes')
              }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

IncomeAfterTaxesFirst.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
};
export default withStyles( styles )( IncomeAfterTaxesFirst );
