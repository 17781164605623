import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  Input,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/financial-independence/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const marks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 4,
    label: '4%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 8,
    label: '8%',
  },
  {
    value: 10,
    label: '10%',
  },
];

// const followersCurrentMarks = [
//   {
//     value: 0,
//     label: '0k',
//   },
//   {
//     value: 25000,
//     label: '25k',
//   },
//   {
//     value: 50000,
//     label: '50k',
//   },
//   {
//     value: 75000,
//     label: '75k',
//   },
//   {
//     value: 100000,
//     label: '100k',
//   },
// ];

// const followersAutomaticMarks = [
//   {
//     value: 0,
//     label: '0k',
//   },
//   {
//     value: 5000,
//     label: '5k',
//   },
//   {
//     value: 10000,
//     label: '10k',
//   },
//   {
//     value: 15000,
//     label: '15k',
//   },
//   {
//     value: 20000,
//     label: '20k',
//   },
//   {
//     value: 25000,
//     label: '25k',
//   },
// ];

const SummerySave = ( props ) => {
  const {
    classes, handleDefinationPopup, inputData, updateData,
  } = props;

  const [growthValue, setGrowthValue] = React.useState( 3.5 );
  const [inflation, setInflation] = React.useState( 2.0 );
  // const [cash, setCash] = React.useState( 0 );
  // const [maxCash, setMaxCash] = React.useState( 5000 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    if ( inputData.long_term_growth_in_percentage !== undefined
      || inputData.inflation_in_percentage !== undefined
      // || inputData.current_cash_balance !== undefined
      // || inputData.max_cash_balance !== undefined
    ) {
      setGrowthValue( inputData.long_term_growth_in_percentage );
      setInflation( inputData.inflation_in_percentage );
      // setCash( inputData.current_cash_balance );
      // setMaxCash( inputData.max_cash_balance );
    }
    // inputData.current_cash_balance, , inputData.max_cash_balance
  }, [inputData.inflation_in_percentage, inputData.long_term_growth_in_percentage] );

  const updateValue = ( e, value, field, type ) => {
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue > 100 ? 100 : e.floatValue;
      if ( newvalue === '' ) {
        newvalue = 0;
      }
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    switch ( field ) {
      case 'long_term_growth_in_percentage': {
        setGrowthValue( newvalue );
        break;
      }
      case 'inflation_in_percentage': {
        setInflation( newvalue );
        break;
      }
      // case 'current_cash_balance': {
      //   setCash( newvalue );
      //   break;
      // }
      // case 'max_cash_balance': {
      //   setMaxCash( newvalue );
      //   break;
      // }
      default: {
        break;
      }
    }
    updateData( field, newvalue );
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        Congratulations! You&apos;ve taken the first step towards building your
        career sketch by setting your 5, 10, 15 year timeline. Plus,
        you made some big assumptions and formed the foundation of your plan.
        Review the details below before saving this scenario.
      </p>
      <ul>
        <li>
          My journey to financial independence starts in year
          {' '}
          {inputData.start_year}
          .
        </li>
      </ul>

      <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Annual Income Growth' ) }>Annual Income Growth</span></p>
          <div className={ classes.pwisliderInputs }>
            <PwiSlider
              value={ growthValue }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              valueLabelFormat={ percentFormatWithTwoDecimal }
              min={ 0 }
              max={ 10 }
              step={ 0.1 }
              marks={ marks }
              ValueLabelComponent={ ValueLabelComponent }
              onChange={ ( e, value ) => updateValue( e, value, 'long_term_growth_in_percentage', 'slider' ) }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            <span className={ classes.opacityZero }>$</span>
            <NumberFormat
              className={ classes.input }
              value={ growthValue }
              decimalScale={isFocus!=='long_term_growth_in_percentage'?2:5}
              fixedDecimalScale={isFocus!=='long_term_growth_in_percentage'}
              onValueChange={ ( e ) => updateValue( e, '', 'long_term_growth_in_percentage', 'number' ) }
              onFocus={ ( e ) => {e.target.select();setIsFocus('long_term_growth_in_percentage')} }
              onBlur={emptyIsFocus}
              // margin="dense"
              // inputProps={ {
              //   step: 0.1,
              //   min: 0,
              //   max: 10,
              //   type: 'text',
              //   'aria-labelledby': 'input-slider',
              // } }
            />
            {' '}
            %
          </div>
        </div>
      </div>
      <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Annual Inflation' ) }>Annual Inflation</span></p>
          <div className={ classes.pwisliderInputs }>
            <PwiSlider
              value={ inflation }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              valueLabelFormat={ percentFormatWithTwoDecimal }
              min={ 0 }
              max={ 10 }
              step={ 0.1 }
              marks={ marks }
              ValueLabelComponent={ ValueLabelComponent }
              onChange={ ( e, value ) => updateValue( e, value, 'inflation_in_percentage', 'slider' ) }
            />
          </div>
          <div className={ classes.annuvalIncomeGrowthInput }>
            <span className={ classes.opacityZero }>$</span>
            <NumberFormat
              className={ classes.input }
              value={ inflation }
              // margin="dense"
              decimalScale={isFocus!=='inflation_in_percentage'?2:5}
              fixedDecimalScale={isFocus!=='inflation_in_percentage'}
              onValueChange={ ( e ) => updateValue( e, '', 'inflation_in_percentage', 'number' ) }
              onFocus={ ( e ) => {
                e.target.select();
                setIsFocus('inflation_in_percentage');
              } }
              onBlur={emptyIsFocus}
              // inputProps={ {
              //   step: 0.1,
              //   min: 0,
              //   max: 10,
              //   type: 'text',
              //   'aria-labelledby': 'input-slider',
              // } }
            />
            {' '}
            %
          </div>
        </div>
      </div>
      {/* <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Current Cash Balance' ) }>Current Cash Balance</span></p>
          <div className={ classes.pwisliderInputs }>
            <PwiSlider
              value={ cash }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              valueLabelFormat={ converyIntoThousandsOneDecimal }
              min={ 0 }
              max={ 100000 }
              step={ 100 }
              marks={ followersCurrentMarks }
              ValueLabelComponent={ ValueLabelComponent }
              onChange={ ( e, value ) => updateValue( e, value, 'current_cash_balance', 'slider' ) }
            />
          </div>
          <div className={ classes.rightGroup }>
            <div>
              <div className={ classes.inputRightBlock }>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cash } onValueChange={ ( e ) => updateValue( e, '', 'current_cash_balance', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                <span className={ classes.opacityZero }>%</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className={ classes.AnnualIncomeGrowth }>
        <div>
          <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Max Cash Balance before Investment' ) }>Max Cash Balance before Investment</span></p>
          <div className={ classes.pwisliderInputs }>
            <PwiSlider
              value={ maxCash }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              valueLabelFormat={ converyIntoThousandsOneDecimal }
              min={ 0 }
              max={ 25000 }
              step={ 100 }
              marks={ followersAutomaticMarks }
              ValueLabelComponent={ ValueLabelComponent }
              onChange={ ( e, value ) => updateValue( e, value, 'max_cash_balance', 'slider' ) }
            />
          </div>
          <div className={ classes.rightGroup }>
            <div>
              <div className={ classes.inputRightBlock }>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ maxCash } onValueChange={ ( e ) => updateValue( e, '', 'max_cash_balance', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                <span className={ classes.opacityZero }>%</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

SummerySave.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( SummerySave );
