import selectArrow from '../../assets/img/select_ic.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  bannerTitle: {
    fontSize: '36px',
    padding: '10px 0!important',
    fontFamily: 'MuseoSans-100',
    lineHeight: '55px',
    margin: '0',
    color: theme.palette.common.blue,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '27px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '24px',      
    },
  },
  contactRow: {
    padding: '40px 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0',   
    },
  },
  img: {
    verticalAlign: 'middle',
  },
  bannerIntro: {
    fontSize: '29px',
    fontFamily: 'MuseoSans-100',
    padding: '0 0 30px 0',
    color: theme.palette.common.black,
    lineHeight: '40px',
    margin: '0 auto',
    maxWidth: '79%',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '17px',
      lineHeight: '30px',
    },
  },
  bannerBlock: {
    textAlign: 'center',
  },
  contactRightBlock: {
    background: theme.palette.common.contactBlue,
    color: theme.palette.common.white,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    padding: '80px 50px',
    marginTop: '-30px',
    width: '41%',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '0px',
      width: '100%',
      padding: '50px 30px',
    },
    '@media (max-width: 320px)': {
      padding: '50px 10px',
    },
    '& p': {
      color: theme.palette.common.white,
      fontFamily: 'MuseoSans-500',
      textAlign: 'left',
      maxWidth: '100%',
      padding: '0 0 10px 0',
      fontSize: '30px',
      lineHeight: '40px',
      margin: '0 auto',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '26px',
      },
    },
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'middle',
      margin: '0',
      padding: '0',
      '& li': {
        paddingBottom: '14px',
        fontSize: '19px',
        lineHeight: '30px',
        fontFamily: 'MuseoSans-300',
        display: 'flex',
        verticalAlign: 'top',
        margin: '0',
        padding: '0 0 42px 0',
        textAlign: 'left',
        float: 'left',
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '17px',
        },
      },
    },
  },
  listImage: {
    '& img': {
      width: '25px',
      marginRight: '10px',
      verticalAlign: 'middle',
    },
  },
  getInTouch: {
    background: theme.palette.common.contactGray,
    padding: '15px 50px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    borderRadius: '10px',
    width: '59%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      padding: '15px 30px',
    },
    '@media (max-width: 320px)': {
      width: '90%',
      padding: '15px 10px',
      margin:'0 auto',
    },
    '& h3': {
      fontSize: '36px',
      fontWeight: '300',
      margin: '10px 0px',
      padding: '15px 0px',
      textAlign: 'left',
      color: theme.palette.common.blue,

    },
    '& p': {
      fontSize: '20px',
      maxWidth: '100%',
      padding: '0',
      textAlign: 'left',
      width: 'auto',
      lineHeight: '30px',
      color: '#333333',
      fontFamily: 'MuseoSans-100',
      margin: '0 auto',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '17px',
      },
    },
  },
  contactGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  contactContnt: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
  },
  contactForm: {
    display: 'table',
    width: '100%',
    paddingTop: '30px',
  },

  mailingAddress: {
    width: '59%',
    fontSize: '20px',
    margin: '0px 0px',
    padding: '0 15px',
    color: theme.palette.common.black,
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      fontSize: '17px',
    },
    [theme.breakpoints.down( 'xs' )]: {
     paddingLeft: '0px',
    },
  },
  contactPage: {
    padding: '0px 0px 70px 0px',
  },
  contactBtn: {
    display: 'flex',
    margin: '10px auto 0px auto',
    width: 'auto',
    background: '#f79844',
    color: '#fff',
    border: 'none',
    textTransform: 'uppercase',
    padding: '4px 15px',
    fontSize: '18px',
    borderRadius: '4px',
    fontFamily: 'MuseoSans-100',
    fontWeight: '400',
    boxShadow: 'none',
    '&:hover': {
      background: '#f79844',
      boxShadow: 'none',
    },
  },
  formInput: {
    width: '100%',
    padding: '7px 15px',
    borderRadius: '7px',
    border: '1px solid #b2b2b1',
    outline: '0',
    fontSize: '18px',
    transition: 'box-shadow .3s ease-in-out',
    WebkitTransition: 'box-shadow .3s ease-in-out',
    MozTransition: 'box-shadow .3s ease-in-out',
    color: '#898a8a',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: 'MuseoSans-100',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '16px',
    },
  },
  formGroup: {
    margin: '0px 0px 10px',
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
      margin: '0px 0px 0px',
    },
    '& div': {
      marginTop: '0',
      marginBottom: '0px',
    },
    '& fieldset': {
      display: 'none',
    },
    '& input': {
      padding: '0px 15px',
      fontSize: '18px',
      height: '38px',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #b2b2b1',
      borderRadius: '7px',
      fontFamily: 'MuseoSans-100',
      color: '#909391',
      '&:focus': {
        boxShadow: '0 0 1px 1px #0069aa',
      },
      '&::placeholder': {
        opacity: '0.99',
        color: '#757c82',
      },
      [theme.breakpoints.down( 'sm' )]: {
        margin: '0px 0px 10px',
      },
    },
  },
  leftInput: {
    width: '50%',
    paddingLeft: '0px',
    paddingRight: '12.5px',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      paddingRight: '0px',
      marginBottom: '10px',
    },
    '& input': {
      padding: '0px 15px',
      fontSize: '18px',
      height: '38px',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #b2b2b1',
      borderRadius: '7px',
      color: '#909391',
    },
  },
  rightInput: {
    width: '50%',
    paddingLeft: '12.5px',
    paddingRight: '0px',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      paddingLeft: '0px',
    },
    '& input': {
      padding: '0px 15px',
      fontSize: '18px',
      height: '38px',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #b2b2b1',
      borderRadius: '7px',
      color: '#909391',
    },
    '& select': {
      padding: '0px 15px',
      fontSize: '18px',
      height: '40px',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #8a8a88',
      borderRadius: '7px',
      '&:focus': {
        boxShadow: '0 0 1px 1px #0069aa',
      },
      [theme.breakpoints.down( 'sm' )]: {
        marginBottom: '10px',
      },
    },
  },
  supportMessage: {
    fontSize: '20px',
    maxWidth: '100%',
    padding: '0',
    textAlign: 'left',
    width: 'auto',
    lineHeight: '30px',
    color: '#898a8a',
  },
  formtextAreaIn: {
    outline: '0',
    width: '100%',
    '& > div': {
      padding: '0px',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    '& textarea': {
      backgroundColor: theme.palette.common.white,
      height: '130px',
      width: '100%',
      padding: '7px 15px',
      borderRadius: '7px',
      border: '1px solid #b2b2b1',
      transition: 'box-shadow .3s ease-in-out',
      WebkitTransition: 'box-shadow .3s ease-in-out',
      MozTransition: 'box-shadow .3s ease-in-out',
      color: '#898a8a',
      position: 'relative',
      boxSizing: 'border-box',
      fontFamily: 'MuseoSans-100',
      fontSize: '18px',
      '&:focus': {
        boxShadow: '0 0 1px 1px #0069aa',
      },
      '&::placeholder': {
        fontSize: '18px',
        opacity: '0.99',
        color: '#909391',
      },
    },
  },
  formSelect: {
    width: '100%',
    padding: '7px 15px',
    borderRadius: '7px',
    border: '1px solid #8a8a88',
    outline: '0',
    fontSize: '18px',
    transition: 'box-shadow .3s ease-in-out',
    WebkitTransition: 'box-shadow .3s ease-in-out',
    MozTransition: 'box-shadow .3s ease-in-out',
    color: '#898a8a',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: 'MuseoSans-100',
    backgroundImage: `url(${selectArrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '98% center',
    backgroundSize: '20px',
    MsAppearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
  },
  formContact: {
    paddingTop: '30px',
  },
  messagePopup: {
    position: 'relative',
    padding: '0px',
    minWidth: '600px',
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: '100%',
    },
    '&:first-child': {
      paddingTop: '0px',
    },
  },

  popupClose: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    '& span': {
      fontSize: '21px',
      textShadow: '0 1px 0 #fff',
      opacity: '0.2',
      cursor: 'pointer',
      '&:hover': {
        opacity: '1',
      },
    },
  },
  popupBody: {
    padding: '35px',
    textAlign: 'center',
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      margin: '0px 0 20px',
    },
  },
  popupCheck: {
    width: '142px',
    height: '142px',
    border: '4px solid #008000',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: '#008000',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid #008000',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
  captcha: {
    marginTop: '20px',
    textAlign: 'center',
  },
  contactTopicformGroup: {
    '& > div': {
      width: '100%',
    },
  },
  contactTopicSelect: {
    backgroundColor: theme.palette.common.white,
    '&:focus': {
      boxShadow: '0 0 1px 1px #0069aa',
    },
    '& > div': {
      width: '100%',
      paddingTop: '0',
      paddingBottom: '0',
    },
    '& svg': {
      display: 'none',
    },
  },
  captchaError:{
    color: '#bf0000',
    textAlign: 'center',
    fontSize: '13.5px',
    marginBottom: '10px',
  }
} );

export default styles;
