import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import benefitsBanner from '../../assets/img/students-parents/benefits_banner.jpg';
// import CareerImageSeven from '../../assets/img/home_page/see_and_build_trns_img_7.png';
// import CareerImageEight from '../../assets/img/home_page/see_and_build_trns_img_8.png';

const Benefits = ( props ) => {
  const { classes, history } = props;

  const redirectToPricing = () => {
    history.push( '/pricing' );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.benefitsPage }>
        <div className={ classes.parentstBanner }>
          <img src={ benefitsBanner } alt="Benefits Banner" />
          <div className={ classes.container }>
            <div className={ classes.bannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography variant="h3" component="h3" className={ classes.bannerTitleNew }>
                  Uncover Your Path
                  {' '}
                  <span className={ classes.spanBlock }>to Financial Independence</span>
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.benefitsContent }>
          <div className={ classes.container }>
            <div className={ classes.benefitsContentRow }>
              <Typography className={ classes.benefitsTitle } variant="h3" component="h3">What is financial independence? Why do I need it?</Typography>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 12 } md={ 8 }>
                  <div className={ classes.inkwiryBelieve }>
                    <Typography variant="p" component="p">At Inkwiry, we believe everyone can achieve the peace of mind that comes with not having to worry about money. We want you to be able to pursue your dreams and go forward with your eyes open, knowing the costs, the return on investment and your ability to enjoy life while making the most of it.</Typography>
                    <Typography variant="p" component="p">
                      Whether you&apos;re
                      <span> a student, a parent or a young professional,</span>
                      {' '}
                      we want you to be able to build your own dynamic plan that can change and serve you well as you move into an uncertain future.
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                  <div className={ classes.benefitsDefinitionCnt }>
                    <Typography variant="h2" component="h2">OUR DEFINITION:</Typography>
                    <Typography variant="p" component="p">You achieve financial independence when your:</Typography>
                    <Typography variant="p" component="p">
                      INCOME &gt; EXPENSES
                      {' '}
                      <span>EXPENSES = WISHES</span>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className={ classes.inkwiryWays }>
          <div className={ classes.container }>
            <div className={ classes.inkwiryWaysRow }>
              <Typography variant="h2" component="h2">10 ways Inkwiry can help you up your game.</Typography>
              <Typography variant="h3" component="h3">It&apos;s your life – be in charge of it. Inkwiry can help you:</Typography>
              <ul>
                {' '}
                <li>
                  {' '}
                  <p>Design a strategy and a plan aligned with your vision. Plan for as many as 15 years into the future.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Discover what makes you tick. When you know and are comfortable with your own priorities you have a solid foundation for making your own best decisions.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Avoid life&apos;s traps and pitfalls, which means you end up with money saved plus money not lost.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Figure out where to start and how to assemble all your information in a meaningful way.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Raise your awareness. Expand your universe and be prepared to take advantage of opportunities as they arise.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Minimize risk while maximizing reward – use Inkwiry&apos;s tools to find and evaluate investments of your time and money, so you avoid risky moves and focus on the most advantageous ones.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Test your scenarios, find your best options and hone your strategy with Inkwiry&apos;s Financial Configurator.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Become a self-learner. Inkwiry builds critical thinking skills and gives you the tools to enhance financial literacy.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Grow your confidence. Knowing your path and proving its viability banishes worry about money and fear of failure.</p>
                  {' '}
                </li>
                {' '}
                <li>
                  {' '}
                  <p>Take full control of your future.</p>
                  {' '}
                </li>
                {' '}
              </ul>
            </div>
          </div>
        </div>

        <div className={ classes.superAffordable }>
          <div className={ classes.container }>
            <div className={ classes.affordableRow }>
              <div className={ classes.affordableCntnt }>
                <Typography variant="h2" component="h2">
                  Super affordable. Easy. Effective.
                </Typography>
                <ul>
                  <li>
                    <p>
                      <b>Inkwiry is super affordable:</b>
                      An annual subscription costs just $5 per month.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Inkwiry is easy to use:</b>
                      Just drag and drop life events to a timeline, enter your details, and Inkwiry does the rest.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Inkwiry is effective:</b>
                      Create a dynamic plan that adjusts to changing situations. Evaluate your options to minimize risk and maximize reward.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Inkwiry is visual and intuitive:</b>
                      Charts, graphs and other visualizations provide deeper understanding of your data. Download reports and financial statements.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Inkwiry is fast:</b>
                      Change a value and Inkwiry instantly reconfigures your data.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Inkwiry is accessible, anytime, anywhere:</b>
                      Your data and scenarios are within your grasp whenever you have questions to ask and ideas to test out.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>Inkwiry is shareable:</b>
                      Create career sketches, collaborate with friends and family to find your own best number.
                    </p>
                  </li>
                </ul>
                <Button onClick={ redirectToPricing } className={ classes.joinInkwiryBtn }>
                  JOIN INKWIRY
                  <i className="las la-greater-than" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Benefits.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Benefits );
