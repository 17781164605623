import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Tabs,
  Tab,
  Grid,
} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from '../../../screens/pwi/higher-education/styles';
import * as modulesServices from '../../../calculations/modules-services';
import { formatDollar } from '../../../utilities/chartCommonFunctions';
import MoneyNeeded from './moneyNeeded';
import FundingSources from './fundingSources';


/* eslint-disable no-template-curly-in-string */

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );


const Summery = ( props ) => {
  const {
    classes, handleSlide, inputData
  } = props;

 
  const [value, setValue] = useState( 0 );

  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };

  const higherEducationOutputData = modulesServices.moreInvestmentOutputBackendCompleteData(); 
  const higherEducationOutputBarGraph2Data = higherEducationOutputData['Bar Graph2'];

  const heightVal = window.outerHeight;

  const scholarshipsTotal = ((inputData.yearly_scholarship_amount !== undefined ? inputData.yearly_scholarship_amount : 0 )*((inputData.years_diff !== undefined && inputData.years_diff > 0) ? inputData.years_diff : 0 )); 
  const grandTotal = ((inputData.yearly_grant_amount !== undefined ? inputData.yearly_grant_amount : 0 )*((inputData.years_diff !== undefined && inputData.years_diff > 0) ? inputData.years_diff : 0 ));

  let familyYearsDiff = 0;
  if(inputData.family_start_year !== undefined && inputData.family_start_year > 0 && inputData.family_end_year !== undefined && inputData.family_end_year > 0) {
    familyYearsDiff = inputData.family_end_year-inputData.family_start_year+1;
  }
  const totalFamily = ((inputData.yearly_contribution !== undefined && inputData.yearly_contribution > 0) ? inputData.yearly_contribution : 0)*familyYearsDiff;  
  const totalCash = ((inputData.cash_and_investments !== undefined ? inputData.cash_and_investments : 0 )*((inputData.years_diff !== undefined && inputData.years_diff > 0) ? inputData.years_diff : 0 ));
  const totalEstimated = modulesServices.module6Data.moreHigherinvestmentAnalysisIncomeEarned;
  const federalLoan = (modulesServices.module6Data.moreFedSubBeginningBalance !== undefined ? modulesServices.module6Data.moreFedSubBeginningBalance : 0) + (modulesServices.module6Data.moreFedUnSubPrincipal !== undefined ? modulesServices.module6Data.moreFedUnSubPrincipal : 0);
  const privateLoan = modulesServices.module6Data.morePrivatePrincipal !== undefined ? modulesServices.module6Data.morePrivatePrincipal : 0;
  const originationFees = modulesServices.module6Data.moreSummaryLoanPoints !== undefined ? modulesServices.module6Data.moreSummaryLoanPoints : 0;
  const fundingTotal = scholarshipsTotal+grandTotal+totalFamily+totalCash+totalEstimated+federalLoan+privateLoan+originationFees;
  
  const remainingAmount = fundingTotal-modulesServices.module6Data.educationTotalAttendance;

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      {
        remainingAmount >= 0 ? (
          <p>Well done! You have successfully developed a plan to finance your education. Double check the details below before clicking save and completing your higher education scenario.</p>
        ) : (
          <React.Fragment>
            <p>Before you can save and continue to your output, you need to make sure your total financing sources are greater than your total cost of attendance. Click on the labels below to go back to previous sections and make sure you have enough money to pay for school.</p>
            <p>Once your financing sources are greater than your total cost of attendance, you can save this scenario and continue to your output.</p>
          </React.Fragment>
        ) 
      }
      <div className={ classes.tabSection }>
        <Tabs
          value={ value }
          onChange={ handleChange }
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={ classes.tabButtons }
          aria-label="full width tabs example"
        >
          <Tab className={ value === 0 ? classes.activeTab : '' } label="Table View" />
          <Tab className={ value === 1 ? classes.activeTab : '' } label="Graph View" />

        </Tabs>
        <div className={ `${classes.tabsContent} ${classes.moneyNeddedTD}` }>
          {value === 0 ? (
            <div className={ classes.totalBoxTable }>
              <Grid container className={ `${classes.gridSpacingImages} ${classes.gridSpacingImagesNew}` }>
                <MoneyNeeded
                  summery
                  handleSlide={handleSlide}
                />
                <FundingSources
                  summery
                  handleSlide={handleSlide}
                  inputData={inputData}
                />
              </Grid>
            </div>
          ):(
            <div className={ classes.graphBox }>
              <HighchartsReact
                highcharts={ Highcharts }
                options={ {
                chart: {
                    plotBackgroundColor: '#ffffff',
                    plotBorderColor: '#cccccc',
                    plotBorderWidth: 1,
                    plotShadow: false,
                    type: 'column',
                    height: heightVal-100 > 600 ? 600 : (heightVal-100)  
                  },
                  navigation: {
                      menuItemStyle: {
                        fontSize: '14px',
                        textAlign: 'left'
                      },
                      menuItemHoverStyle: {
                        background: '#f5f5f5',
                        color: '#4c4c4c',
                        fontSize: '14px'
                        },
                      buttonOptions: {
                        height: 40,
                        width: 48,
                        symbolSize: 24,
                        symbolX: 24,
                        symbolY: 21,
                        symbolStrokeWidth: 2,
                        verticalAlign: 'bottom',
                        _titleKey: 'y'
                      }
                    },
                    exporting: {
                      buttons: {
                        contextButton: {
                          menuItems: [{
                            textKey: 'downloadPNG',
                            onclick: function () {
                              const chart = this;
                              chart.exportChart();
                            },
                          }, {
                            textKey: 'downloadJPEG',
                            onclick: function () {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/jpeg'
                              });
                            }
                          },{
                            textKey: 'downloadPDF',
                            onclick: function () {
                              const chart = this;
                              chart.exportChart({
                                type: 'application/pdf'
                              });
                            }
                          }, {
                            textKey: 'downloadSVG',
                            onclick: function () {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/svg+xml'
                              });
                            }
                          }]
                        }
                      }
                      },
                          credits: {
                              enabled: false
                          },
                          title: {
                              text: 'Sources and Uses of Funds during Higher Education'
                          },
                          xAxis: {
                              categories: higherEducationOutputData['Bar Graph2 YearsList'] !== undefined ? higherEducationOutputData['Bar Graph2 YearsList'] : [],
                              labels: {
                                  style: {
                                      color: '#000000',
                                      fontSize: '14px'
                                  }
                              }
                          },    
                          yAxis: [{ // Primary yAxis
                              labels: {
                                  formatter: function() {
                                    const chart = this;
                                      if( chart.value < 0 ) {
                                          return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                                      } else { 
                                          return formatDollar(Math.round(chart.value));
                                      }
                                  },
                                  style: {
                                      color: '#000000',
                                      fontSize: '14px'
                                  }
                              },
                              title: {
                                  text: '',
                                  style: {
                                      color: '#000000'
                                  }
                              }
                          }],
                          tooltip: {
                              formatter: function () {
                                const chart = this;
                                  return '<b>' + chart.x + '</b><br/>' +
                                  chart.series.name + ': $' + chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + '<br/>' +
                                  'Total: $' + chart.point.stackTotal.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
                              }
                          },
                          legend: {
                              align:'center',
                              itemStyle: {
                                  fontSize: '14px'
                              }
                          },
                          plotOptions: {
                              column: {
                                  stacking: 'normal'
                              }
                          },
                          series: [{
                              name: 'Private Student Loan',
                              data: higherEducationOutputBarGraph2Data['Private Student Loan'],
                              stack: 'male',
                              color: '#FFC000',
                              legendIndex:14
                          }, {
                              name: 'Federal Unsubsidized Student Loan',
                              data: higherEducationOutputBarGraph2Data['Federal Unsubsidized Student Loan'],
                              stack: 'male',
                              color: '#F8CBAD',
                              legendIndex:12
                          }, {
                              name: 'Federal Subsidized Student Loan',
                              data: higherEducationOutputBarGraph2Data['Federal Subsidized Student Loan'],
                              stack: 'male',
                              color: '#ED7D31',
                              legendIndex:10
                          }, {
                              name: 'Family Contribution',
                              data: higherEducationOutputBarGraph2Data['Family Contribution'],
                              stack: 'male',
                              color: '#2E75B6',
                              legendIndex:8
                          }, {
                              name: 'Income Earned during Higher Education',
                              data: higherEducationOutputBarGraph2Data['Income Earned During'],
                              stack: 'male',
                              color: '#BDD7EE',
                              legendIndex:6
                          }, {
                              name: 'Financial Aid',
                              data: higherEducationOutputBarGraph2Data['Financial Aid'],
                              stack: 'male',
                              color: '#E2F0D7',
                              legendIndex:4
                          }, {
                              name: 'Grants',
                              data: higherEducationOutputBarGraph2Data['Grants'],
                              stack: 'male',
                              color: '#A9D18E',
                              legendIndex:2

                          }, {
                              name: 'Scholarships',
                              data: higherEducationOutputBarGraph2Data['Scholarships'],
                              stack: 'male',
                              color: '#548235',
                              legendIndex:0
                          }, {
                              name: 'Tuition & Fees',
                              data: higherEducationOutputBarGraph2Data['Tuition Cost'],
                              stack: 'female',
                              color: '#7F7F7F',
                              legendIndex:1
                          }, {
                              name: 'Living Expenses',
                              data: higherEducationOutputBarGraph2Data['Living Expenses'],
                              stack: 'female',
                              color: '#996600',
                              legendIndex:3
                          }, {
                              name: 'Estimated Taxes',
                              data: higherEducationOutputBarGraph2Data['Estimated Taxes'],
                              stack: 'female',
                              color: '#C80000',
                              legendIndex:5
                          }, {
                              name: 'Student Loan Payments during Higher Education',
                              data: higherEducationOutputBarGraph2Data['Student Loan Payments during School'],
                              stack: 'female',
                              color: '#A00FF1',
                              legendIndex:7
                          }, {
                              name: 'Other Loan Payments on Other Debt',
                              data: higherEducationOutputBarGraph2Data['Other Loan Payments on Other Debt'],
                              stack: 'female',
                              color: '#01FFCC',
                              legendIndex:9
                          }, {
                              name: 'Car Expenses',
                              data: higherEducationOutputBarGraph2Data['Car Expenses'],
                              stack: 'female',
                              color: '#FF66FF',
                              legendIndex:11
                          }, {
                              name: 'Real Estate Expenses',
                              data: higherEducationOutputBarGraph2Data['Real Estate Expenses'],
                              stack: 'female',
                              color: '#9999FF',
                              legendIndex:13
                          }, {
                              name: 'Children and Pet Expenses',
                              data: higherEducationOutputBarGraph2Data['Children and Pet Expenses'],
                              stack: 'female',
                              color: '#FFFFFF',
                              legendIndex:15,
                              borderColor: '#000',
                          }]
                      } }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Summery.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleSlide: PropTypes.func.isRequired,
};

export default withStyles( styles )( Summery );
