import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../../calculations/modules-services';
// import { defaultValues } from '../../../calculations/default-module-values';
import styles from '../styles';

const PetBuyingCosts = (props) => {
  const {
    classes, updatedFamilyValues, setPopupOpen, setUpdatedFamilyValues,defaultData,
  } = props;

  const [familyValues, setFamilyValues] = useState(updatedFamilyValues);

  useEffect(() => {
    setFamilyValues(updatedFamilyValues);
  }, [updatedFamilyValues]);

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...familyValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
    }
    moduleServices.setModule10Data(valuesUpdated);
    setFamilyValues(valuesUpdated);
  };

  const resetToDefaultPet = () => {
    const valuesUpdated = { ...familyValues };
    valuesUpdated.default_pet_first_vet_visit = defaultData.default_pet_first_vet_visit;
    valuesUpdated.default_pet_new_supplies = defaultData.default_pet_new_supplies;
    valuesUpdated.default_pet_miscellaneous = defaultData.default_pet_miscellaneous;
    moduleServices.setModule10Data(valuesUpdated);
    setFamilyValues(valuesUpdated);
    setUpdatedFamilyValues(valuesUpdated);
    setPopupOpen(false);
  }

  const handleOk = () => {
    setUpdatedFamilyValues(familyValues);
    setPopupOpen(false);
  }
  
  return (
    <div>
      <div className={classes.modalHeader}>
        <h3>Buying a Pet Costs</h3>
      </div>
      <div className={classes.modalBody}>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        First Vet Visit
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>
                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.default_pet_first_vet_visit} onValueChange={(e) => updateValue(e, 'default_pet_first_vet_visit', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        New Supplies
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>
                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.default_pet_new_supplies} onValueChange={(e) => updateValue(e, 'default_pet_new_supplies', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.moduleRowNew}>
          <Grid container>
            <Grid item sm={12} xs={12} className={classes.inputBlock}>
              <div className={classes.inputGroup}>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classes.insideSpan}>
                      <div className={classes.spanText}>
                        Miscellaneous
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={6} className={classes.noSpace}>
                    <div className={classNames(classes.inputRight, classes.fullInput)}>
                      <span className={classes.dollarSign}>$</span>
                      <NumberFormat className={classes.formInput} allowNegative={false} customInput={TextField} thousandSeparator value={familyValues.default_pet_miscellaneous} onValueChange={(e) => updateValue(e, 'default_pet_miscellaneous', 'number')} onFocus={(e) => e.target.select()} />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

      </div>
      <div className={classes.modalFooter}>
        <h3>
          Total Pet Buying Costs:
          {moduleServices.module10Data.annualCostOfOwingPet !== undefined ? (
            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.annualCostOfOwingPet} prefix={moduleServices.module10Data.annualCostOfOwingPet >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.annualCostOfOwingPet < 0 && ')'} />
          ) : ('$0')}
        </h3>
        <div>
          <Button className={classNames(classes.resetButton, classes.footerButton)} onClick={resetToDefaultPet}>Reset to default values</Button>
          <Button className={classNames(classes.okButton, classes.footerButton)} onClick={handleOk}>Ok</Button>
          <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={() => { setPopupOpen(false); }}>Cancel</Button>
        </div>
      </div>

    </div>
  );
};

PetBuyingCosts.propTypes = {
  classes: PropTypes.object.isRequired,
  updatedFamilyValues: PropTypes.object.isRequired,
  setUpdatedFamilyValues: PropTypes.func.isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  defaultData:  PropTypes.object.isRequired,
};

export default withStyles(styles)(PetBuyingCosts);
