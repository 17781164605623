import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';

const Home = ( props ) => {
  const { classes, setDebts, debts, handleAssestObject, handleUpdatedObject} = props;
  const debtsArr = [
    'Credit Card Debt',
    'Personal Loans',
    'Federal Student Loans',
    'Private Student Loans',
  ]
  React.useEffect(()=>{
  },[])
  const update = (index) => {
    const newArr = {...debts};
    newArr[index].status = !newArr[index].status;
    setDebts(newArr)
    const inputValues = {...handleAssestObject}
    inputValues[newArr[index].field] = newArr[index].status;
    handleUpdatedObject(inputValues)
  }
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        If you have a credit card and do not repay your balance in full each month, that would be carrying credit card debt. If you repay in full each month, do not select credit card.
      </p>
      <p className={ classes.existingSpace }>
        Select the other liabilities that you have below.
      </p>
      <ul className={ classes.retirementList}>
        {
          debtsArr.map(((l, index) => (
            <li className={  debts[index].status ? classes.activeVehicle : classes.activeDisable } onClick = { () => update(index)}>
              {l} 
              <span>
              { debts[index].status &&  <img src={BlueTick} alt={`Home`} />}
              </span>
            </li>
          )))
        }
      </ul>
    </div>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( Home );
