import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';

const startTime = new Date();
const SafeOnlineBankingTips = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>7 Tips to Safe Online Banking</h3>
                    </div>
                    <div className={ classes.pfVideo }>
                      <YouTube videoId="DGo-OhDWIKQ" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/7-tips-safe-online-banking.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>7 Tips to Safe Online Banking</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/safe-online-banking-left.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            Online and mobile banking allows you to access, manage, and review your accounts while maximizing convenience, flexibility, and control. You can manage your financial life on the go or from the comfort of your own home. But what are some tips to safe online banking? How can you protect your financial life online?
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>
                            <b>Tip #1 – Create strong passwords</b>
                            <br />
                            A strong password should be at least 10 characters long, a mix of upper- and lower-case letters, numbers, and special characters ($#?), and should not contain any personal information such as your birthday, first or last name, or a part of your social security number. Use the random password generator from
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.avast.com/random-password-generator" target="_blank" rel="budget-fy2020 noopener noreferrer">Avast</a>
                            , an industry leader in security and privacy for over 30 years, if you need help.
                          </p>
                          <p>
                            {' '}
                            <b>Tip #2 – Don&apos;t save your passwords in your browser or in a file</b>
                            <br />
                            Imagine you lose your computer or phone. If you save your passwords in your browser or in a file such as word or excel, it could be very easy to gain access to your online or mobile banking apps with your computer or phone. Instead, keep your passwords on paper in a secure place or use a password manager such as
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.lastpass.com/hp" target="_blank" rel="budget-fy2020 noopener noreferrer">LastPass</a>
                            {' '}
                            to safely store and manage all your passwords in one place.
                          </p>
                          <p>
                            {' '}
                            <b>Tip #3 – Protect your smartphone</b>
                            <br />
                            According to the
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.aba.com/about-us/press-room/press-releases/survey-93-rate-banks-online-and-mobile-app-experience-highly" target="_blank" rel="budget-fy2020 noopener noreferrer">American Bankers Association</a>
                            , 70% of Americans use a mobile device to manage their bank account at least once a month. But, according to
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.pewresearch.org/fact-tank/2017/03/15/many-smartphone-owners-dont-take-steps-to-secure-their-devices/" target="_blank" rel="budget-fy2020 noopener noreferrer">PEW Research</a>
                            , 28% of smartphone owners have no lock screen on their phones! At a minimum, use a passcode, fingerprint ID, or face ID to lock your screen.
                          </p>
                          <p>
                            {' '}
                            <b>Tip #4 – Beware of phishing</b>
                            <br />
                            Phishing is a common tactic used to try and steal your sensitive information such as usernames and passwords by posing as a legitimate person or company. For example, you may get a call that says, “Hello, we’ve detected fraud on your checking account. To secure your account, please enter your social security number followed by the pound key.” What should you do? Immediately hang up, check your bank account for any unusual activity and call your bank to be sure.
                            <p>
                              Don&apos;t reply to an e-mail or click on e-mail links, phone call, text, or any other message asking you for financial or personal information. Even if the message looks like it&apos;s from your bank, financial institution, or other legitimate organization, never give out your information. No bank or reputable institution is going to approach you asking for personal information in these ways.
                            </p>
                          </p>
                          <p>
                            {' '}
                            <b>Tip #5 – Keep your devices, applications, and internet browsers up to date</b>
                            <br />
                            Regularly updating the software on your devices and keeping applications and internet browsers up to date will help protect your personal information. Old versions are easier targets to people trying to steal your information. Use
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.avast.com/free-antivirus-download-v4?utm_expid=.8HYdGtT9RbmYqgzgCgxLuw.1&utm_referrer=https%3A%2F%2Finkwiry.com%2F#pc" target="_blank" rel="budget-fy2020 noopener noreferrer">anti-virus software</a>
                            {' '}
                            to secure your devices.
                          </p>
                          <p>
                            {' '}
                            <b>Tip #6 – Beware of unsecure wireless networks</b>
                            <br />
                            Accessing banking information using the local coffee shop&apos;s Wi-Fi or in a hotel lobby might not be the best idea. Public wireless networks that you connect to can be unsecure.
                          </p>
                          <p>
                            {' '}
                            <b>Tip #7 – Smart online shopping</b>
                            <br />
                            Make sure you know the company you are shopping with. Look for a padlock symbol in your web browser or check that the URL starts with “https” to make sure you are on a secure website and safe to enter payment information.
                            <p>
                              {' '}
                              How do you know if your information might have been stolen? Monitor your financial account statements! At least twice a month, check your credit card, checking, and savings accounts for any unusual activity. Always check your monthly statements in detail and report any unusual activity to your bank or financial institution.
                            </p>
                          </p>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level5/safe-online-banking-tips/exercise" startTime={ startTime } level={ 5 } exercise={ 3 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
SafeOnlineBankingTips.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SafeOnlineBankingTips );
