import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import NumberFormat from 'react-number-format';
import Pageloader from '../../../../../components/ui/pageloader';
import styles from './styles';
import eachQuestionXp, { xpPoints, getTotalTime, getUserXPRank } from '../../pf101-service';
import Pf101Popups from '../../popups';
import headerLogo from '../../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import book from '../../../../../assets/img/pf101/icon-book-blue.png';

const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );
const startTime = new Date();

/* eslint react/prop-types: 0 */

const LowerCostOfLoan = ( props ) => {
  const { classes, history } = props;
  const defaultCorrectAnswer = ['1', '2', '1', '1', '1'];
  const defaultQuestionList = [];

  for ( let i = 0; i <= 4; i += 1 ) {
    defaultQuestionList.push( {
      id: i + 1,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: defaultCorrectAnswer[i],
      status: '',
      answer: '',
    } );
  }

  let bodySection = useRef( null );
  const [loading, setLoading] = React.useState( true );
  const [activeSlide, setActiveSlide] = React.useState( 1 );
  const [questionList, setQuestionList] = React.useState( defaultQuestionList );
  const [progress, setProgress] = React.useState( 1 );
  const [questionProgressData, setQuestionProgressData] = React.useState( {
    questions_progress_val: eachQuestionXp, questions_progress: 100, total_progress: 0, total_progress_val: 0,
  } );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );
  const [inputData, setInputData] = React.useState( [
    { status: '', input: '', correct: 3 },
    { status: '', input: '', correct: 541.70 },
    { status: '', input: '', correct: 1.3 },
    { status: '', input: '', correct: 78822.82 },
    { status: '', input: '', correct: 6.8 },
  ] );

  useQuery( GET_EXERCISE, {
    variables: {
      level: 6,
      exercise: 6,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questions = [...questionList];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questions[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questions[data.question_id - 1].xp_earned = xpPoints( questions[data.question_id - 1].question_attempts );
          questions[data.question_id - 1].status = 'C';
          questions[data.question_id - 1].answer = questions[data.question_id - 1].correct_answer;
        } );

        let inputDataDetails = [...inputData];
        inputDataDetails = inputDataDetails.map( ( data ) => {
          const data1 = data;
          data1.status = 'C';
          data1.input = data1.correct;
          return data1;
        } );
        setInputData( inputDataDetails );
        setQuestionList( questions );
        setExerciseStatus( 'review' );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const backToDashBoard = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Back to Dashboard</h3>
            <p>If you go back to the Dashboard, your progress will be lost. Are you sure you want to exit the activity and go back to the Dashboard?</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => { onClose(); history.push( '/pf101/dashboard' ); } }
            >
              CONTINUE
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  useEffect( () => {
    const questionsData = questionList;

    scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } );

    setProgress( parseFloat( ( 100 / questionsData.length ) * activeSlide ) );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questionsData[activeSlide - 1].xp_earned;
    progressData.questions_progress = ( questionsData[activeSlide - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questionsData, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( questionsData.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
    // eslint-disable-next-line
  }, [activeSlide, exerciseStatus] );

  const answerQuestion = ( e ) => {
    const questions = [...questionList];
    questions[activeSlide - 1].answer = e.target.value;
    questions[activeSlide - 1].status = '';
    setQuestionList( questions );
  };

  const checkAnswer = ( question ) => {
    const questions = [...questionList];

    if ( question === 1 || question === 3 || question === 4 || question === 5 ) {
      questions[question - 1].answer = questions[question - 1].correct_answer;
    }

    if ( questions[question - 1].answer === questions[question - 1].correct_answer ) {
      questions[question - 1].status = 'C';
    } else {
      questions[question - 1].status = 'W';
      questions[question - 1].question_attempts = questions[question - 1].question_attempts + 1;
    }
    questions[question - 1].xp_earned = xpPoints( questions[question - 1].question_attempts );
    setQuestionList( questions );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questions[question - 1].xp_earned;
    progressData.questions_progress = ( questions[question - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questions, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( defaultQuestionList.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
  };

  const handleInputChange = ( e, index ) => {
    const inputDetails = [...inputData];
    inputDetails[index].input = e.floatValue !== undefined ? e.floatValue : 0;
    if ( inputDetails[index].input === 0 ) {
      inputDetails[index].status = '';
    } else if ( parseFloat( inputDetails[index].input ) === parseFloat( inputDetails[index].correct ) ) {
      inputDetails[index].status = 'C';
    } else {
      inputDetails[index].status = 'W';
    }
    if ( activeSlide === 3 ) {
      if ( inputDetails[0].status === 'C' ) {
        checkAnswer( activeSlide );
      }
    } else if ( activeSlide === 4 ) {
      if ( inputDetails[1].status === 'C' && inputDetails[2].status === 'C' ) {
        checkAnswer( activeSlide );
      }
    } else if ( inputDetails[3].status === 'C' && inputDetails[4].status === 'C' ) {
      checkAnswer( activeSlide );
    }
    setInputData( inputDetails );
  };

  const finishActivity = () => {
    if ( exerciseStatus === 'review' ) {
      history.push( '/pf101/dashboard' );
      return false;
    }
    setLoading( true );
    const popupValue = {};

    const totalTime = getTotalTime( startTime );
    let totalPoints = _.sumBy( questionList, 'xp_earned' );
    const totalAttempts = _.sumBy( questionList, 'question_attempts' );
    const questionData = questionList.map( ( questions ) => _.pick( questions, ['id', 'question_attempts', 'xp_earned'] ) );

    popupValue.exercisePoints = totalPoints;

    // Adding 50 points for all questions answered single attempt
    if ( totalAttempts === defaultQuestionList.length ) {
      totalPoints += 50;
      popupValue.precision = true;
      popupValue.precisionPoints = 50;
    } else {
      popupValue.precision = false;
      popupValue.precisionPoints = 0;
    }

    popupValue.level = true;
    popupValue.levelPoints = 500;
    popupValue.levelNumber = 6;

    totalPoints += 500;

    const presentRank = getUserXPRank( pf101Points );
    const nextRank = getUserXPRank( pf101Points + totalPoints );
    if ( presentRank !== nextRank ) {
      popupValue.rankChangeStatus = true;
      popupValue.rank = nextRank;
    } else {
      popupValue.rankChangeStatus = false;
      popupValue.rank = '';
    }

    setPopupDetails( popupValue );

    saveExercise( {
      variables: {
        data: {
          level: 6,
          exercise: 6,
          exercise_time: totalTime,
          points: totalPoints,
          total_attempts: totalAttempts,
          questionData,
        },
      },
    } );
    return true;
  };

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.pfHeader }>
        <div className={ classes.container }>
          <div className={ classes.pfHeaderInner }>
            <h3>
              <span className={ classes.breadcrumbTitle }>Level 6:</span>
              {' '}
              Credit Score + Debt
            </h3>
            <Button onClick={ () => { backToDashBoard(); } }>
              <img src={ headerLogo } alt="" />
            </Button>
          </div>
        </div>
      </div>
      <div className={ classes.pfContent }>
        <div className={ classes.stepOne }>
          <div className={ classes.pfContentHeader }>
            <div>
              <h3>
                <span>Activity:</span>
                {' '}
                How to Lower the Cost of a Loan (
                {activeSlide}
                {' '}
                of
                {' '}
                {defaultQuestionList.length}
                )
              </h3>
            </div>
            <Link to="/pf101/level6/lower-cost-of-loan" target="_blank">
              <figure>
                <img
                  src={ book }
                  alt="infoicon"
                  data-for="infoLeftIcon"
                  data-tip="Click here to open the activity's reading in a new tab."
                  data-offset="{'left':10}"
                />
                <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
              </figure>
            </Link>

          </div>
          <div className={ classes.ProgressBar }>
            <div className={ classes.ProgressBarInner } style={ { width: `${progress}%` } } />
          </div>
          { activeSlide === 1 && (
            <div className={ classes.contentBlock }>
              <div className={ classes.grayBox }>
                <h3>
                  To complete this activity, answer the following questions. Each question is worth
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP.</span>
                  {' '}
                  If you get the question right on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP</span>
                  . On the second try,
                  {' '}
                  <span className={ classes.bonusXp }>+15 XP</span>
                  . Third try,
                  {' '}
                  <span className={ classes.bonusXp }>+12 XP</span>
                  . And fourth try,
                  {' '}
                  <span className={ classes.bonusXp }>+10 XP</span>
                  .
                </h3>
                <h3>
                  <span className={ classes.bonusXp }>BONUS XP:</span>
                  {' '}
                  If you answer all questions correctly on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+50 XP</span>
                  {' '}
                  at the end of the activity. Good luck!
                </h3>
                <h3>A green check mark will appear once you answer correctly and the Next button will turn blue once you&apos;ve answered all correctly.</h3>
              </div>
              <h3>
                In this activity, you&apos;ll need to use one of Inkwiry&apos;s Financial Calculators to run some scenarios. Use the following link to open the
                {' '}
                <Link onClick={ () => checkAnswer( activeSlide ) } to="/financial-calculators/extra-loan-payment" target="_blank">Extra Loan Payment Calculator</Link>
                . Then, come back to this page and use the calculator to answer the questions.
              </h3>
            </div>
          )}

          { activeSlide === 2 && (
            <div className={ classes.contentBlock }>
              <h3>There are two common ways to lower the cost of a loan. The first is to make extra payments each month for that loan. What&apos;s the second way you can lower the cost of a loan?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Take a personal loan instead since they&apos;re one of the cheapest types of debt
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Refinance your loan to a lower interest rate
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        Make the term of the loan longer so your monthly payments are smaller, which means less interest
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        There is no other way to lower the cost of the loan
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 3 && (
            <div className={ classes.contentBlock }>
              <h3>Mary bought her $30,000 car and is about to start repaying her $20,000 loan. She double checked her budget and realized that she can afford to make extra payments on her loan.</h3>
              <h3>
                Use the
                {' '}
                <Link to="/financial-calculators/extra-loan-payment" target="_blank">Loan Repayment Calculator</Link>
                {' '}
                to figure out the below:
              </h3>
              <div className={ classes.loanCalculator }>
                <table>
                  <thead>
                    <tr>
                      <th>Terms</th>
                      <th>Loan A</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Loan Amount</td>
                      <td>$20,000</td>
                    </tr>
                    <tr>
                      <td>Interest Rate</td>
                      <td>4.00%</td>
                    </tr>
                    <tr>
                      <td>Term of the loan</td>
                      <td>4 years</td>
                    </tr>
                    <tr>
                      <td>Monthly Payment</td>
                      <td>$451.58</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={ classes.repayInput }>
                <p>If Mary makes $130 of extra payments each month, how long does it take her to repay her auto loan?</p>
                <NumberFormat disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[0].input } onValueChange={ ( e ) => handleInputChange( e, 0 ) } onFocus={ ( e ) => e.target.select() } />
                years
                {inputData[0].status === 'W' && (
                <span className={ classes.redTimes }>
                  <i className="las la-times" />
                </span>
                )}
                {inputData[0].status === 'C' && (
                <span className={ classes.greenCheck }><i className="las la-check" /></span>
                )}
              </div>
            </div>
          )}

          { activeSlide === 4 && (
            <div className={ classes.contentBlock }>
              <h3>Mary can now see that by making an extra payment, she gets out of debt one whole year earlier. She&apos;s wondering if she made a $200 extra payment each month how much she could save on total interest paid.</h3>
              <h3>
                Use the
                {' '}
                <Link to="/financial-calculators/extra-loan-payment" target="_blank">Loan Repayment Calculator</Link>
                {' '}
                to figure out the total interest paid and time savings with the $200 extra payment:
              </h3>
              <div className={ classNames( classes.loanCalculator, classes.loanTable, classes.extraLoan ) }>
                <table>
                  <thead>
                    <tr>
                      <th>Terms</th>
                      <th>Loan A</th>
                      <th>Loan A w/ Extra Payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Loan Amount</td>
                      <td>$20,000</td>
                      <td>$20,000</td>
                    </tr>
                    <tr>
                      <td>Interest Rate</td>
                      <td>4.00%</td>
                      <td>4.00%</td>
                    </tr>
                    <tr>
                      <td>Term of the loan</td>
                      <td>4 years</td>
                      <td>??</td>
                    </tr>
                    <tr>
                      <td>Monthly Payment</td>
                      <td>$451.58</td>
                      <td>$651.58</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={ classes.repayInput }>
                <p>What&apos;s the total interest savings for loan A with the extra $200 payment?</p>
                <span>$</span>
                <NumberFormat disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[1].input } onValueChange={ ( e ) => handleInputChange( e, 1 ) } onFocus={ ( e ) => e.target.select() } />
                {inputData[1].status === 'W' && (
                <span className={ classes.redTimes }>
                  <i className="las la-times" />
                </span>
                )}
                {inputData[1].status === 'C' && (
                <span className={ classes.greenCheck }><i className="las la-check" /></span>
                )}
              </div>
              <div className={ classes.repayInput }>
                <p className={ classes.topTwenty }>How much faster does Mary repay her loan by making the extra $200 payment? (Enter the Time Savings from the calculator)</p>
                <NumberFormat disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[2].input } onValueChange={ ( e ) => handleInputChange( e, 2 ) } onFocus={ ( e ) => e.target.select() } />
                years
                {inputData[2].status === 'W' && (
                <span className={ classes.redTimes }>
                  <i className="las la-times" />
                </span>
                )}
                {inputData[2].status === 'C' && (
                <span className={ classes.greenCheck }><i className="las la-check" /></span>
                )}
              </div>
            </div>
          )}
          { activeSlide === 5 && (
            <div className={ classes.contentBlock }>
              <h3>Mike is buying a new construction $437,500 house in the suburbs. He&apos;s so excited to finally purchase his dream home. He&apos;s been approved for a $350,000 mortgage with the terms as shown below. Mike wants to get out of debt as fast as possible and can afford to make $250 in extra payments on his mortgage.</h3>
              <h3>
                Use the
                {' '}
                <Link to="/financial-calculators/extra-loan-payment" target="_blank">Loan Repayment Calculator</Link>
                {' '}
                to figure out the below:
              </h3>
              <div className={ classNames( classes.loanCalculator, classes.loanNewTable ) }>
                <table className={ classes.proTable }>
                  <thead>
                    <tr>
                      <th>Terms</th>
                      <th>Mortgage</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Loan Amount</td>
                      <td>$350,000</td>
                    </tr>
                    <tr>
                      <td>Interest Rate</td>
                      <td>4.75%</td>
                    </tr>
                    <tr>
                      <td>Term of the loan</td>
                      <td>30 years</td>
                    </tr>
                    <tr>
                      <td>Monthly Payment</td>
                      <td>$1,825.77</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={ classes.repayInput }>
                <p>
                  If Mike makes $250 of extra payments each month on his mortgage, what&apos;s his total interest savings?
                  {' '}
                  {' '}
                  <b>Imagine if he invested these savings over time how much money that could grow to.</b>
                </p>

                <span>$</span>
                <NumberFormat disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[3].input } onValueChange={ ( e ) => handleInputChange( e, 3 ) } onFocus={ ( e ) => e.target.select() } />
                {inputData[3].status === 'W' && (
                <span className={ classes.redTimes }>
                  <i className="las la-times" />
                </span>
                )}
                {inputData[3].status === 'C' && (
                <span className={ classes.greenCheck }><i className="las la-check" /></span>
                )}
              </div>
              <div className={ classes.repayInput }>
                <p className={ classes.topTwenty }>How much faster does Mike repay his mortgage by making the extra $250 payment each month?</p>
                <NumberFormat disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[4].input } onValueChange={ ( e ) => handleInputChange( e, 4 ) } onFocus={ ( e ) => e.target.select() } />
                years
                {inputData[4].status === 'W' && (
                <span className={ classes.redTimes }>
                  <i className="las la-times" />
                </span>
                )}
                {inputData[4].status === 'C' && (
                <span className={ classes.greenCheck }><i className="las la-check" /></span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={ classes.answerStatus }>
          {questionList[activeSlide - 1].status === 'C' && (
          <h2 className={ classes.correctAnswer }>
            Correct! You earned +
            {questionList[activeSlide - 1].xp_earned}
            {' '}
            XP.
          </h2>
          )}
          {questionList[activeSlide - 1].status === 'W' && <h2 className={ classes.wrongAnswer }>Incorrect. Try again!</h2>}
        </div>
        <div className={ classes.btnGroup }>
          {activeSlide !== 1 && <Button className={ classNames( classes.manageButton, classes.backButton ) } onClick={ () => { setActiveSlide( activeSlide - 1 ); } }>Back</Button>}
          <div className={ classes.progressXp }>
            <div className={ classes.questionXp }>
              <p>
                <span>Question XP:</span>
                {' '}
                +
                {questionList[activeSlide - 1].xp_earned}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.questions_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp}
                {' '}
                XP
              </span>
            </div>
            <div className={ classNames( classes.questionXp, classes.totalQuestion ) }>
              <p>
                <span>Total XP:</span>
                {' '}
                +
                {questionProgressData.total_progress_val}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.total_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp * defaultQuestionList.length}
                {' '}
                XP
              </span>
            </div>
          </div>
          {activeSlide === 2 && (
            <Button className={ `${classes.manageButton} ${classes.checkAnswer}` } disabled={ questionList[activeSlide - 1].answer === '' } onClick={ () => checkAnswer( activeSlide ) }>Check Answer</Button>
          ) }
          {defaultQuestionList.length === activeSlide ? (
            <Button className={ classNames( classes.manageButton, classes.nextButton ) } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => finishActivity() }>FINISH</Button>
          ) : (
            <Button className={ classNames( classes.manageButton, classes.nextButton ) } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => { setActiveSlide( activeSlide + 1 ); } }>Next</Button>
          )}

        </div>
      </div>
      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } level={6}/>
       )}
    </Typography>
  );
};

LowerCostOfLoan.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( LowerCostOfLoan );
