const styles = ( theme ) => ( {
  careerSketchTimeline: {
    textAlign: 'center',
    padding: '0 50px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 20px',
    },
    '& h3': {
      textAlign: 'left',
      color: '#0069aa',
      fontWeight: 'normal',
      fontSize: '26px',
      maxWidth: '765px',
      margin: '0px auto',
      marginBottom: '24px',
      '@media (max-width: 767px)': {
        fontSize: '20px',
        textAlign: 'center',
        marginBottom: '12px',
      },
      '& img': {
        marginLeft: '5px',
        cursor: 'pointer',
        maxWidth: '20px',
        position: 'relative',
        top: '3px',
      },
    },
    '& ul': {
      listStyle: 'none',
      padding: '0',
      marginBottom: '25px',
      '& li': {
        bordr: 'none !important',
        background: 'none !important',
        padding: '0 !important',
        display: 'inline-block !important',
        margin: '2px 2px !important',
        cursor: 'pointer',
        '& a': {
          padding: "0",
        },
        '& img': {
          maxWidth: '45px',
          '@media (max-width: 767px)': {
            maxWidth: '28px',
          }
        },
        '& ul': {
          paddingTop: '10px',
          margin: 0,
          '& li': {
            fontSize: '12px',
            display: 'list-item !important',
            margin: '2px',
            verticalAlign: 'top'
          }
        }
      },
    },
  },
  tooltipInfoTop: {
    padding: '3px 8px !important',
    maxWidth: '300px',
    fontSize: '14px !important',
    textAlign:'left',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    opacity: '1 !important',
    background: '#0069aa !important',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
  },
  tooltipInfoTopNewText: {
    width: '300px',
    padding: '3px 8px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'left',
    background: '#0069aa !important',
    fontSize: '14px !important',
    opacity: '1',
    [theme.breakpoints.down( 'xs' )]: {
      width: '200px',
    },
    '&::before': {
      [theme.breakpoints.down( 'xs' )]: {
        borderLeft: '8px solid #0069aa !important',
        borderTop: '10px solid transparent !important',
        borderBottom: '10px solid transparent !important',
        right: '-9px !important',
        top: '49% !important',
      }
    },
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
  },
} );

export default styles;
