import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import BuyCar from '../../assets/img/pwi-fi/buy_car.png';
import WorkSheet from '../../assets/img/pwi-fi/worksheet-icon.png';
import fixMenuImage from '../../assets/img/pwi-fi/mega-menu-pricing_img.png';
import Simulator from '../../assets/img/pwi-fi/simulator-engine-blue.png';
import TimeLine from '../../assets/img/pwi-fi/timeline_icon.png';
import Dollar from '../../assets/img/pwi-fi/dollar-icon.png';
import Dashboard from '../../assets/img/pwi-fi/dashboard.png';
import Clear from '../../assets/img/pwi-fi/clear-timeline.png';
import Load from '../../assets/img/pwi-fi/load.png';
import Download from '../../assets/img/pwi-fi/download.png';
import Save from '../../assets/img/pwi-fi/save.png';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DocumentsList from '../../components/pwi/pwi-left-menu/documents-list';

const Sidebar = (props) => {
  const {
    classes, menu, setMenu, handleSideMenu, completedSlides, openBeforeLeavePopup, setOpenPopup, activeScenario, sketchName, setOpenDocsPopup, clearScenario, saveScenario, firstBuyCar, secondLeaseCar, firstLeaseCar, secondBuyCar,
  } = props;

  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = React.useState(false);

  const usePathname = (event, url, moduleName) => {
    event.preventDefault();
    openBeforeLeavePopup(url, moduleName);
  }

  const handleToggle = (type) => {
    const dropValues = dropdownIntialValues;
    if (type === 'firstCar') {
      dropValues.firstCar = !dropdownvalues.firstCar;
    } else if (type === 'secondCar') {
      dropValues.secondCar = !dropdownvalues.secondCar;
    } else if (type === 'firstCarLease') {
      dropValues.firstLeaseCar = !dropdownvalues.firstLeaseCar;
    } else if (type === 'secondCarLease') {
      dropValues.secondLeaseCar = !dropdownvalues.secondLeaseCar;
    }
    setDropdownValues(dropValues);
  };

  const dropdownIntialValues = {
    loans: false,
    card: false,
    car: false,
    home: false,
  };
  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);

  return (
    <div className={menu ? classes.fixedMenuDisplay : classes.fixedMenu}>
      <ReactTooltip id="worksheetView" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.worksheetToolTip)} />
      <div className={classes.fixedMenuHeader}>
        <Link href="/plan-with-inkwiry/fi" onClick={(event) => { usePathname(event, '/worksheet', 'Worksheet') }}>
          <img src={WorkSheet} alt="" data-for="topTooltipTopIcon" data-tip="Switch to Worksheet view" />
        </Link>
        <i className="la la-close" aria-hidden="true" onClick={() => setMenu(false)} />
      </div>
      <div className={classes.planWithInkwiry}>
        <div className={classes.powerUpLogo}>
          <img src={fixMenuImage} alt="" />
        </div>
        <h1>Plan With Inkwiry</h1>
      </div>
      <div className={classes.powerUpCnt}>
        <h4>
          <figure>
            <img src={BuyCar} alt="" />
          </figure>
          Buying a Car
      </h4>
        <ul className={classes.homeMenuList}>
          <li className={classes.activeMenu} aria-hidden="true" onClick={() => handleSideMenu(1)}>
            <span>01</span>
            <font>Affordability Calculator</font>
            <span className={classes.menuCheck}>
              {completedSlides[1] !== undefined && completedSlides[1] === true
                && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={classes.activeMenu} aria-hidden="true" onClick={() => handleSideMenu(2)}>
            <span>02</span>
            <font>Buying vs Leasing a Car</font>
            <span className={classes.menuCheck}>
              {completedSlides[2] !== undefined && completedSlides[2] === true
                && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li className={classes.activeMenu} aria-hidden="true" onClick={() => handleSideMenu(3)}>
            <span>03</span>
            <font>Dashboard</font>
          </li>
          <li>
            <span>04</span>
            <font>
              <span aria-hidden="true" onClick={() => handleSideMenu(4)}>Buying a Car &nbsp;</span>
              <span className={classes.caretIcon} aria-hidden="true" onClick={() => { handleToggle('firstCar'); }}>{' '}</span>
            </font>
            <span className={classes.menuCheck}>
              {firstBuyCar
                && <CheckIcon fontSize="small" />}
            </span>
            {dropdownvalues.firstCar && (

              <ul className={classes.subListMenu}>
                <li aria-hidden="true" onClick={() => handleSideMenu(4)}>
                  <font>Setting Timelines</font>
                </li>
                <li className={(completedSlides[5] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(5)}>
                  <font>Total Purchase Price</font>
                </li>
                <li className={(completedSlides[5] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(6)}>
                  <font>Discount and Rebate</font>
                  {' '}
                </li>
                <li className={(completedSlides[5] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(7)}>
                  <font>Trade-In</font>
                  {' '}
                </li>
                <li className={(completedSlides[5] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(8)}>
                  <font>Sales Tax and Fees</font>
                  {' '}
                </li>
                <li className={(completedSlides[5] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(9)}>
                  <font>Down Payment</font>
                  {' '}
                </li>
                <li className={(completedSlides[10] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(10)}>
                  <font>Car Loan</font>
                  {' '}
                </li>
                <li className={(completedSlides[11] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(11)}>
                  <font>Monthly Expenses</font>
                  {' '}
                </li>
                <li className={(completedSlides[11] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(12)}>
                  <font>Summary </font>
                </li>
              </ul>
            )}
          </li>
          <li>
            <span>05</span>
            <font>
              <span aria-hidden="true" onClick={() => handleSideMenu(18)}><font>Buying a Second Car &nbsp;</font></span>
              <span className={classes.caretIcon} aria-hidden="true" onClick={() => { handleToggle('secondCar'); }}>{' '}</span>
            </font>
            <span className={classes.menuCheck}>
              {secondBuyCar
                && <CheckIcon fontSize="small" />}
            </span>
            {dropdownvalues.secondCar && (

              <ul className={classes.subListMenu}>
                <li aria-hidden="true" onClick={() => handleSideMenu(18)}>
                  <font>Setting Timelines</font>
                </li>
                <li className={(completedSlides[19] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(19)}>
                  <font>Total Purchase Price</font>
                </li>
                <li className={(completedSlides[19] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(20)}>
                  <font>Discount and Rebate</font>
                </li>
                <li className={(completedSlides[19] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(21)}>
                  <font>Trade-In</font>
                </li>
                <li className={(completedSlides[19] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(22)}>
                  <font>Sales Tax and Fees</font>
                </li>
                <li className={(completedSlides[19] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(23)}>
                  <font>Down Payment</font>
                </li>
                <li className={(completedSlides[24] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(24)}>
                  <font>Car Loan</font>
                </li>
                <li className={(completedSlides[25] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(25)}>
                  <font>Monthly Expenses</font>
                </li>
                <li className={(completedSlides[25] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(26)}>
                  <font>Summary</font>
                </li>
              </ul>
            )}

          </li>
          <li>
            <span>06</span>
            <font>
              <span aria-hidden="true" onClick={() => handleSideMenu(13)}>Leasing a Car</span>
              <span className={classes.caretIcon} aria-hidden="true" onClick={() => { handleToggle('firstCarLease'); }}>{' '}</span>
            </font>
            <span className={classes.menuCheck}>
              {firstLeaseCar
                && <CheckIcon fontSize="small" />}
            </span>
            {dropdownvalues.firstLeaseCar && (

              <ul className={classes.subListMenu}>
                <li aria-hidden="true" onClick={() => handleSideMenu(13)}>
                  <font>Setting Timelines</font>
                </li>
                <li className={(completedSlides[14] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(14)}>
                  <font>Down Payment and Drive-Off Fees</font>
                </li>
                <li className={(completedSlides[14] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(15)}>
                  <font>Lease Payment</font>
                </li>
                <li className={(completedSlides[14] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(16)}>
                  <font>Monthly Expenses</font>
                </li>
                <li className={(completedSlides[14] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(17)}>
                  <font>Summary</font>
                </li>
              </ul>
            )}
          </li>
          <li>
            <span>07</span>
            <font>
              <span aria-hidden="true" onClick={() => handleSideMenu(27)}>Leasing a Second Car &nbsp;</span>
              <span className={classes.caretIcon} aria-hidden="true" onClick={() => { handleToggle('secondCarLease'); }}>{' '}</span>
            </font>
            <span className={classes.menuCheck}>
              {secondLeaseCar
                && <CheckIcon fontSize="small" />}
            </span>
            {dropdownvalues.secondLeaseCar && (
              <ul className={classes.subListMenu}>
                <li aria-hidden="true" onClick={() => handleSideMenu(27)}>
                  <font>Setting Timelines</font>
                </li>
                <li className={(completedSlides[28] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(28)}>
                  <font>Down Payment and Drive-Off Fees</font>
                </li>
                <li className={(completedSlides[28] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(29)}>
                  <font>Lease Payment</font>
                </li>
                <li className={(completedSlides[28] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(30)}>
                  <font>Monthly Expenses</font>
                </li>
                <li className={(completedSlides[28] === true) ? '' : classes.clickNone} aria-hidden="true" onClick={() => handleSideMenu(31)}>
                  <font>Summary</font>
                </li>
              </ul>
            )}
          </li>
          <li>
            <div>
              {activeScenario.id !== undefined && activeScenario.id !== '' && (
                <div className={classes.scenarioNameBlock}>
                  <span>Active Scenario</span>
                  <span>{activeScenario.name}</span>
                </div>
              )}
              {sketchName !== '' && sketchName !== 'Active Selection' && (
                <div className={classes.scenarioNameBlock}>
                  <span>Active Career Sketch</span>
                  <span>{sketchName}</span>
                </div>
              )}
            </div>
          </li>
        </ul>
        <div className={classes.footerMenuFixed}>
          <ul>
            <li>
              <Link component="button" onClick={() => { setMenu(false); setOpenPopup('simulator'); }} data-for="bottomTooltip" data-tip="Simulator Panel">
                <img src={Simulator} alt="" />
              </Link>
            </li>
            <li>
              <Link href="/timeline-sketcher" onClick={(event) => { usePathname(event, '/timeline-sketcher', 'Career Sketch Timeline') }} data-for="bottomTooltip" data-tip="Go to Timeline">
                <img src={TimeLine} alt="" />
              </Link>
            </li>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => { setSummaryDocumentsOpen(false) }}
            >
              <li className={classes.documentsMenu} >
                <Link component="button" onClick={() => { setSummaryDocumentsOpen(!summaryDocumentsOpen); }} data-for="bottomTooltip" data-tip="Summary Documents">
                  <img src={Dollar} alt="" />
                </Link>
                {summaryDocumentsOpen && (
                  <DocumentsList hideMenu={()=>{setMenu(!menu);}} setOpenDocsPopup={setOpenDocsPopup} setSummaryDocumentsOpen={setSummaryDocumentsOpen} />
                )}
              </li>
            </ClickAwayListener>
            <li>
              <Link href="/dashboard" onClick={(event) => { usePathname(event, '/dashboard', 'Dashboard') }} data-for="bottomTooltip" data-tip="Go to Dashboard">
                <img src={Dashboard} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={clearScenario} data-for="bottomTooltip" data-tip="Clear Inputs">
                <img src={Clear} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={() => openBeforeLeavePopup('', 'loadScenario', 'load')} data-for="bottomTooltip" data-tip="Load">
                <img src={Load} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" data-for="bottomTooltip" data-tip="Download">
                <img src={Download} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={saveScenario} data-for="bottomTooltip" data-tip="Save">
                <img src={Save} alt="" />
              </Link>
            </li>
          </ul>
        </div>
        <ReactTooltip id="topTooltipTopIcon" place="right" type="info" effect="solid" className={ classNames( classes.tooltipInfoTopNew, classes.worksheetToolTip ) } />
        <ReactTooltip id="bottomTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.moduleToolTip)} />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  menu: PropTypes.bool.isRequired,
  setMenu: PropTypes.func.isRequired,
  handleSideMenu: PropTypes.func.isRequired,
  completedSlides: PropTypes.object.isRequired,
  sketchName: PropTypes.string.isRequired,
  activeScenario: PropTypes.object.isRequired,
  setOpenDocsPopup: PropTypes.func.isRequired,
  openBeforeLeavePopup: PropTypes.func.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  clearScenario: PropTypes.func.isRequired,
  saveScenario: PropTypes.func.isRequired,
  firstBuyCar: PropTypes.func.isRequired,
  secondLeaseCar: PropTypes.func.isRequired,
  firstLeaseCar: PropTypes.func.isRequired,
  secondBuyCar: PropTypes.func.isRequired,
};

export default withStyles(styles)(Sidebar);
