import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import powerImg from '../../assets/img/my-career-sketch/my-career-sketch-cover.png';
import greenLine from '../../assets/img/my-career-sketch/green-hand-drawn-line.png';
import certificateImg from '../../assets/img/my-career-sketch/my-career-sketch-certificate-jay.png';
import quoteMark from '../../assets/img/my-career-sketch/quote_mark.png';
import greenCheck from '../../assets/img/get-powerup-lite/poweruplite_sales_check_bg.png';
import powerLogo from '../../assets/img/my-career-sketch/my-career-sketch-blue.png';
import csIconOne from '../../assets/img/my-career-sketch/MCS-level01.png';
import csIconTwo from '../../assets/img/my-career-sketch/MCS-level02.png';
import csIconThree from '../../assets/img/my-career-sketch/MCS-level03.png';
import csIconFour from '../../assets/img/my-career-sketch/MCS-level04.png';
import csIconFive from '../../assets/img/my-career-sketch/MCS-level05.png';
import csIconSix from '../../assets/img/my-career-sketch/MCS-level06.png';
import csIconSeven from '../../assets/img/my-career-sketch/MCS-level07.png';
import csIconEight from '../../assets/img/my-career-sketch/MCS-level08.png';
import csIconNine from '../../assets/img/my-career-sketch/MCS-level09.png';
import powerupliteCheck from '../../assets/img/get-powerup-lite/poweruplite_sales_check.png';

const MyCareerSketch = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.getPowerUpLite }>
        <div className={ classes.powerBanner }>
          <div className={ classes.container }>
            <div className={ classes.bannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography variant="h3" component="h3" className={ classes.bannerTitleNew }>
                  Where else can you earn $25,000+ per hour?
                </Typography>
                <p>Ignite your future in My Career Sketch.</p>
                <Link to="/join">
                  <span className={ classes.showMoney }>SHOW ME THE MONEY</span>
                  {'>'}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Container className={ classes.container }>
          <div className={ classes.powerUpSmartMoves }>
            <div className={ classes.container }>
              <Typography variant="h2" component="h2">
                Spend just four hours in Inkwiry&apos;s short online course,
                <span className={ classes.blockSpan }>
                  My Career Sketch and learn a
                  {' '}
                  <b>few smart moves</b>
                  {' '}
                  that can help you
                </span>
                <span className={ classes.blockSpan }>
                  <b>add $100,000+</b>
                  {' '}
                  to your 15-year net worth.
                </span>
              </Typography>
            </div>
          </div>
          <div className={ classes.powerUpClaim }>
            <Grid container>
              <Grid item sm={ 4 } xs={ 12 }>
                <div className={ classes.leftImg }>
                  <figure>
                    <img src={ powerImg } alt="" />
                  </figure>
                </div>
              </Grid>
              <Grid item sm={ 8 } xs={ 12 }>
                <div className={ classes.rightContent }>
                  <p>
                    <span className={ classes.bluesSpanColor }>Sounds like a pretty big claim.</span>
                    {' '}
                    But if you’re a student – either in high school, college, career school or graduate school – or a young professional, My Career Sketch will reveal surprising strategies that can supercharge your financial moves. When you get smarter about your planning, you end up with more money made and less money lost, positioning yourself to achieve financial freedom, and get there faster.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.circlesBlock }>
            <div className={ classes.circlesSection }>
              <div className={ classNames( classes.roundCircle, classes.circleOne ) }>
                <img src={ quoteMark } alt="" />
                <span>Maybe I&apos;m lazy but is there any way I can put this off?</span>
                <img className={ classes.closeQuote } src={ quoteMark } alt="" />
              </div>
              <div className={ classNames( classes.roundCircle, classes.circletwo ) }>
                <img src={ quoteMark } alt="" />
                <span>I don&apos;t have a crystal ball. Things are always changing. How can I plan for next week let alone years?</span>
                <img className={ classes.closeQuote } src={ quoteMark } alt="" />
              </div>
              <div className={ classNames( classes.roundCircle, classes.circleThree ) }>
                <img src={ quoteMark } alt="" />
                <span>I&apos;ve tried to plan but it&apos;s just too difficult to organize my data.</span>
                <img className={ classes.closeQuote } src={ quoteMark } alt="" />
              </div>
            </div>
            <div className={ classes.circlesBottom }>
              <img className={ classes.greenLine } src={ greenLine } alt="" />
              <div className={ classes.bestIntrest }>
                Planning is bringing the future into the present so that you can do something about it now.
                <span>— Alan Lakein</span>
              </div>
            </div>
          </div>
          <div className={ classes.financialFuture }>
            <Typography variant="h2" component="h2">
              How can My Career Sketch
              {' '}
              <b>dramatically</b>
              {' '}
              <span className={ classes.blockSpan }>
                <b>improve</b>
                {' '}
                your financial future?
              </span>
            </Typography>
            <div className={ classes.skillsKnowledge }>
              <div className={ classes.skillsContent }>
                <img className={ classes.checkImage } src={ greenCheck } alt="" />
                With career sketching, you&apos;ll know exactly where your education, your career and your wealth will be 5, 10, 15 years from now, unlike those who leave their futures to chance, because you will write down your dreams and goals, make a plan to reach them and set your personal finance skills and knowledge into action.
              </div>
              <p className={ classes.wealthText }>My Career Sketch will jumpstart your career sketch and financial life plan.</p>
              <p>If you’re a high school student thinking about college and career, or already in some type of higher education, the smart moves you’ll learn in My Career Sketch will change the way you think about managing your money and your debt. We’re not kidding - the proverbial light bulb will switch on in your head!</p>
              <div className={ classes.financialList }>
                <Typography variant="h3" component="h3">In My Career Sketch, you&apos;ll:</Typography>
                <ul>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Take the first steps to design, build and launch your financial life plan as you complete the Higher Education and Career Path modules.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Understand why and how to efficiently plan for as many as 15 years into the future.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Figure out the stepping stones – strategic advancements – to achieve your career and life goals.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Discover smart moves you can start using immediately to avoid life&apos;s traps and pitfalls, which means you end up with money saved plus money not lost.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Raise your awareness about money, education, current trends and how these factors can affect your financial life plan.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Learn how to ask “what if?” and use Inkwiry&apos;s tools to find your own best answers, minimizing your risk and maximizing reward.</li>
                  <li><span className={classes.listIcon}><img src={ powerupliteCheck } alt="" /></span> Grow your confidence about planning and how it can provide peace of mind.</li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
        <div className={ classes.powerLife }>
          <div className={ classes.eightBlocks }>
            <Container>
              <p className={ classes.titleText }>
                Eight information-charged levels plus a toolkit
                <span>will power your financial life planning</span>
              </p>
              <p className={ classes.subTitle }>Here&apos;s what you&apos;ll cover:</p>
              <div className={ classes.elBlocks }>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconTwo } alt="" />
                    <p>LEVEL 01</p>
                    <h3>Questions Reveal the Ultimate You</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconOne } alt="" />
                    <p>LEVEL 02</p>
                    <h3 className={ classes.plWorks }>Planning Works</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconThree } alt="" />
                    <p>LEVEL 03</p>
                    <h3>What&apos;s a Career Sketch?</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={ classes.elBlocks }>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconFour } alt="" />
                    <p>LEVEL 04</p>
                    <h3>Power of Career Sketching</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconFive } alt="" />
                    <p>LEVEL 05</p>
                    <h3>Identify Your Stepping Stones</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconSix } alt="" />
                    <p>LEVEL 06</p>
                    <h3>Capture Your Details</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={ classes.elBlocks }>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconSeven } alt="" />
                    <p>LEVEL 07</p>
                    <h3>Build Your Stepping Stones</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconEight } alt="" />
                    <p>LEVEL 08</p>
                    <h3>Ask Your Data Questions</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
                <div className={ classes.csLevel }>
                  <div className={ classes.csLevelDesc }>
                    <img className={ classes.csIcon } src={ csIconNine } alt="" />
                    <p className={ classes.hiddenDisplay }>Level 09</p>
                    <h3 className={ classes.plWorks }>Wrapup + Review</h3>
                    <button type="button" className={ classes.goButton }>Go</button>
                  </div>
                  <div className={ classes.progressLevel }>
                    <div className={ classes.csProgress }>
                      <div className={ classes.progressBar } />
                      <p className={ classes.progressValue }>100%</p>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className={ classes.salesCertificate }>
          <Container className={ classes.container }>
            <div className={ classes.powerToday }>
              <div className={ classes.powerGold }>
                <img className={ classes.powerLogo } src={ powerLogo } alt="" />
              </div>
              <Typography variant="h2" component="h2">Start My Career Sketch today!</Typography>
              <p>
                <span className={ classes.bluesSpanColor }>My Career Sketch is bundled with your Inkwiry membership</span>
                {' '}
                Choose one of three plans ranging in cost from an affordable $5 to $7 to $9 per month. Your return on investment can be remarkable: you can use what you learn to
                {' '}
                <span className={ classes.bluesSpanColor }>add $100,000 or more to your 15-year net worth!</span>
                {' '}
                If you choose the 30-day membership plan for $9, you’ll have plenty of time to complete this four-hour course PLUS use Plan With Inkwiry to quickly build a financial life plan. (We truly hope you’ll stick around to learn even more smart moves so that in 5, 10, 15 years, you’ll end up with additional money saved plus money not lost.)
              </p>
              <div className={ classes.completeCertificate }>
                <div className={ classes.certificateLeft }>
                  <p>
                    And when you’ve finished My Career Sketch, you’ll receive a personalized
                    {' '}
                    <b>Certificate of Completion</b>
                    {' '}
                    with a reminder of the goals and dreams you specified along with the stepping stones you outlined on your career path.
                    {' '}
                  </p>
                  <p>Just click on the button to sign up!</p>
                </div>
                <div className={ classes.certificateRight }>
                  <img src={ certificateImg } alt="" />
                </div>
              </div>
              <Link to="/join" className={ classes.startPf101 }>
                <span>START MY CAREER SKETCH</span>
                {'>'}
                {' '}
              </Link>
            </div>
          </Container>
        </div>
        <Container className={ classes.container }>
          <div className={ classes.powerUpSaleslast }>
            <div className={ classes.powerUpSalesBoxes }>
              <p className={ classes.salesBoxOne }>
                <div className={classes.salesBoxOneFlex}>
                  <div className={classes.salesBoxOneLeft}></div>
                  <div className={classes.salesBoxOneRight}>
                    <span>Inkwiry enabled me to confidently navigate</span>
                    {' '}
                    <span>getting my master&apos;s in engineering. That</span>
                    {' '}
                    <span>smart move led to a 55% increase in salary</span>
                    {' '}
                    <span>and unlocked a lifetime of potential.&quot;</span>
                    <span className={ classes.authorQuote }>– Matt P., Temple Master&apos;s in Civil Engineering Student</span>
                  </div>
                </div>
              </p>
              <p className={ classes.salesBoxTwo }>
                <div className={classes.salesBoxOneFlex}>
                  <div className={classes.salesBoxOneLeft}></div>
                  <div className={classes.salesBoxOneRight}>
                    <span>After graduating with tens of thousands</span>
                    {' '}
                    <span> of dollars in student loans, I was overwhelmed to</span>
                    {' '}
                    <span>say the least. But, once I entered my data into</span>
                    <span> Inkwiry&apos;s Financial Configurator, I discovered that</span>
                    {' '}
                    <span>not only could I repay my loans on schedule, I</span>
                    {' '}
                    <span>could have additional savings as well.&quot;</span>
                    <span className={ classes.authorQuote }>
                      – Alex V., in PhD program, Nursing Practice
                      <span> for Nurse Anesthetists</span>
                    </span>
                  </div>
                </div>
              </p>
              <p className={ classes.salesBoxThree }>
                <div className={classes.salesBoxOneFlex}>
                  <div className={classes.salesBoxOneLeft}></div>
                  <div className={classes.salesBoxOneRight}>
                    <span>I received a couple of job offers across the</span>
                    {' '}
                    <span>country – San Francisco and New York City.</span>
                    {' '}
                    <span>I didn&apos;t know where to begin to figure out what</span>
                    {' '}
                    <span>was best for me. With Inkwiry, I could develop</span>
                    {' '}
                    <span>multiple scenarios and career sketches, analyze</span>
                    {' '}
                    <span>each job offer and make my best move.&quot;</span>
                    <span className={ classes.authorQuote }>– Dean B., Director of Brand Partnerships</span>
                  </div>
                </div>
              </p>
            </div>
            <div className={ classes.loseNothing }>
              <h2>
                There&apos;s nothing to
                {' '}
                <b>lose</b>
                , everything to
                {' '}
                <b>gain</b>
              </h2>
              <h3>Our 100% Satisfaction Guarantee</h3>
              <p>
                My Career Sketch and your Inkwiry membership comes with a {' '}
                <b>100% Satisfaction Guarantee.</b>
              </p>
              <p>We hope your financial life is forever transformed with the beginning financial skills and knowledge you develop through this course, and that you’ll continue on in Inkwiry to build your financial life plan. That said, we’ll give you seven days to request a full refund if you believe My Career Sketch and an Inkwiry membership is not for you.</p>
            </div>
            <div className={ classes.fewMoves }>
              <div className={ classes.movesImg }>
                <img src={ powerImg } alt="" />
              </div>
              <div className={ classes.fewMovesRight }>
                <h2>
                  <span>Light up your financial future </span>
                  with a few smart moves
                </h2>
                <p className={ classes.bottommargin }>When it comes to building your financial life plan and managing your financial future, the earlier you start planning, the better. </p>
                <p className={ classes.noMargin }>Ignite your future with My Career Sketch today!</p>
              </div>
            </div>
            <Link to="/join" className={ classes.startPf101 }>
              <span>GET MY CAREER SKETCH TODAY </span>
              {'>'}
              {' '}
            </Link>
          </div>
        </Container>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

MyCareerSketch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( MyCareerSketch );
