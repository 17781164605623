import React from 'react';
import styled from 'styled-components';
import compose from 'recompose/compose';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import LogoMain from '../../../../assets/img/menu/mega-menu-pricing_img.png';
import { withRouter } from 'react-router-dom';

const PricingDropdownEl = styled.div`
  width: 60rem;
  padding: 0;
  
  ul {
    padding: 0 !important;
  }
  li {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 5px 0;
    margin: 0;
    font-size: 15px;
    margin-bottom: 10px;
  }
  h3 {
    margin:0 0 0;
  }
`;

const PricingDropdown = ( props ) => {
  const { classes, history } = props;
  
  const redirectToSignupFreePrice = () => {
    history.push('/join');
  };

  return (
    <PricingDropdownEl className={ classes.marginLeftNewCompany }>
      <Grid container>
        <Grid sm={ 6 }>
          <Grid container className={ classes.megamenuContainer }>
            <Grid sm={ 12 }>
              <div className={ classes.LeftMegamenu }>
                <div className={ classes.paddingTopFisty } />
                <div className={ classes.listGroup }>
                  <ul>
                    <li><Link to="/pricing">Pricing for Individuals</Link></li>
                    <li><Link to="/pricing/schools">Pricing for Schools + Universities</Link></li>
                    <li><Link to="/pricing/business">Pricing for Businesses</Link></li>
                  </ul>
                </div>

              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid sm={ 6 } className={ classes.rightMegamenu }>
          <div className={ `${classes.aboutMegaMenu} ${classes.priceDropdownImg}` }>
            <img src={ LogoMain } alt="" />
            <h4>Inkwiry | special offer</h4>
            <p>
              Sign up now and get one month free! Use the code
              {' '}
              {' '}
              <span className={ classes.blueText }>Ink2022</span>
              {' '}
              when you join to get your free month.
            </p>
            <Button className={ classes.btnstartQuizNews } onClick={redirectToSignupFreePrice}>Join Now</Button>
          </div>
        </Grid>

      </Grid>
    </PricingDropdownEl>
  );
};

PricingDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( PricingDropdown );
