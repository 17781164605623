import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import crossMark from '../../../assets/img/pwi-family/crossmark.png';
import equalImage from '../../../assets/img/pwi-family/equals.png';

const LivingExpensesAdjustment = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Plan With Inkwiry enables you to quickly and easily make some assumptions about your future combined expenses. When you get married, the Financial Configurator will multiply your living expenses each year going forward by a number called a multiple.</p>
      <p>For example, you input a 1.5x multiple for your monthly food expense. Beginning in your marriage year and continuing to the end of your 5, 10, 15 year plan, your monthly food expense will be multiplied by 1.5x.</p>
      <Typography component="h3" variant="h3" className={ classes.foodExpenses }>
        <span>$1,000</span>
        <img src={ crossMark } alt="" />
        <span>1.5</span>
        <img src={ equalImage } alt="" />
        <span>$1,500 food expense in marriage year</span>
      </Typography>
      <p>Enter your marriage multiples for your monthly living expenses on the next page and see how that affects your net cash flow.</p>
    </div>
  );
};

LivingExpensesAdjustment.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles( styles )( LivingExpensesAdjustment );
