const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  accountInformation: {
    margin: '30px auto',
    maxWidth: '1000px',
    '& > div > div': {
      maxWidth: '48%',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
      },
    },
  },
  registrationPage: {
    '& h3': {
      display: 'block',
      fontSize: '20px',
      lineHeight: '20px',
      color: '#0069aa',
      margin: '10px 10px 10px 0',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
    '& p': {
      color: '#000',
    },
  },
  formGroup: {
    marginBottom: '10px',
    position: 'relative',
    '& > div': {
      width: 'calc(100% - 10px)',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
      },
    },
    '& > div > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    '& label': {
      transform: 'translate(12px, 15px) scale(1)',
      color: '#a0a0a0',
      '& span': {
        color: '#bf4927',
        paddingLeft: '2px',
      },
    },
    '& input': {
      padding: '15px 12px 8px',
      background: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '14px',
      width: '100%',
      color: '#333',
      fontFamily: 'MuseoSans-300',
    },
    '& select': {
      padding: '15px 12px 6px',
      background: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      fontSize: '14px',
      oiutine: 'none',
      width: 'calc(100% - 10px)',
      color: '#555',
      fontFamily: 'MuseoSans-300',
      '&:focus': {
        borderColor: '#66afe9',
        outline: 0,
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)',
      },
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
      },
    },
  },
  cardHolder: {
    '& input': {
      padding: '12px 12px 10px',
      '&::placeholder': {
        color: '#76767a',
        opacity: 1,
      },
    },
  },
  tooltipScoreInfo: {
    height: 'auto',
    textAlign: 'left',
    fontSize: '14px',
    maxWidth: '210px',
    width: '350px',
    padding: '3px 8px',
    fontWeight: 500,
    lineHeight: 1.42857143,
  },
  successCheck: {
    position: 'absolute',
    right: '15px',
    width: '10px',
    top: '20px',
  },
  radioButtonGroup: {
    color: '#000',
    '& p': {
      fontSize: '16px',
      '& span': {
        color: '#bf4927',
        paddingLeft: '2px',
      },
    },
    '& ul': {
      padding: '0 0 0 6px',
      listStyle: 'none',
      '& label': {
        padding: '3px 0',
        fontSize: '16px',
        '& svg': {
          width: '20px',
          color: '#0069aa',
          height: '20px',
        },
        '& span': {
          padding: '0 3px 0 0',
          fontSize: '16px',
          color: '#000',
          background: 'transparent !important',
          '&:hover': {
            background: 'transparent',
          },
          '&:focus': {
            background: 'transparent',
          },
        },
      },
    },
  },
  registrationPageTop: {
    marginTop: '30px',

    '& h3': {
      display: 'block',
      fontSize: '20px',
      lineHeight: '20px',
      color: '#0069aa',
      margin: '10px 10px 10px 0',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
    '& p': {
      color: '#000',
    },
  },
  selectLable: {
    position: 'absolute',
    fontSize: '14px',
    top: '1px',
    paddingLeft: '12px',
    color: '#a0a0a0',
    '& span': {
      color: '#bf4927',
    },
  },
  borderLeft: {
    borderLeft: '1px solid #ccc',
    height: '100%',
    paddingLeft: '30px',
    marginLeft: '0px',
    [theme.breakpoints.down( 'sm' )]: {
      borderLeft: 'none',
      paddingLeft: '0',
      marginLeft: '0',
    },
  },
  cardDetails: {
    border: '1px solid #d9d9d9',
    borderRadius: '6px',
  },
  cardDetailsHeader: {
    display: 'flex',
    borderBottom: '1px solid #d9d9d9',
    padding: '12px 15px',
    margin: '0 0 20px 0',
    '& h4': {
      margin: '0',
      color: '#616161',
      fontSize: '18px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
    '& ul': {
      listStyle: 'none',
      margin: '0 0 0 auto',
      padding: '0',
      [theme.breakpoints.down( 'sm' )]: {
        display: 'none',
      },
      '& li': {
        display: 'inline-block',
        margin: '0 2.5px',
        fontSize: '12px',
        verticalAlign: 'middle',
        '& img': {
          filter: 'grayscale(100%)',
          display: 'flex',

        },
      },
    },
  },
  selectedCard: {
    '& img': {
      filter: 'none !important',
    },
  },
  cardDetailsContent: {
    paddingLeft: '25px',
    paddingRight: '10px',
  },
  PoweredByImg: {
    marginTop: '10px',
  },
  MeberShip: {
    marginTop: '30px',
    borderBottom: '1px solid #ccc',

  },
  memberShipMain: {
    '& ul': {
      fontFamily: 'MuseoSans-500',
      '& fieldset > div > div': {
        marginBottom: '5px',
      },
      '& small': {
        position: 'relative',
      },
      '& label +  span': {
        top: '2px',
        position: 'relative',
      },
      '& label +  span  span span': {
        top: '0',
        position: 'relative',
        display: 'inline-block',
        width: 'calc(100% - 30px)',
        '& font': {
          float: 'right',
          fontFamily: 'MuseoSans-500',
        },
      },
      '& label': {
        display: 'inline-block',
        margin: '0',
        paddingRight: '5px',
        '& span': {
          padding: '0',
        },

      },
    },
  },
  applyCoupon: {
    display: 'flex',
    marginTop: '10px',
    '& button': {
      color: '#fff',
      backgroundColor: '#0069aa',
      borderColor: '#0069aa',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      '&:hover': {
        backgroundColor: '#0069aa',
        borderColor: '#0069aa',
      },
    },
    '& input': {
      padding: '9px 12px 9px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      color: '#000 !important',
      '&:focus': {
        borderColor: '#66afe9',
        outline: 0,
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, .6)',
      },
    },
  },
  totalBill: {
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    marginTop: '0',
    '& h4': {
      margin: '0',
      padding: '8px 0',
      display: 'flex',
      '& span': {
        margin: '0 0 0 auto',
      },
    },
  },
  billNoBorder: {
    borderTop: 'none',
    borderBottom: 'none',
    '& h4': {
      fontWeight: 300,
    },
  },
  agreeCondition: {
    '& ul': {
      padding: '0 0 20px',
    },
    '& ul li': {
      fontSize: '14px',
      fontFamily: 'MuseoSans-100',
      marginBottom: '7px',
      '& span': {
        padding: '0 3px 0 0',
      },
    },
    '& svg': {
      color: '#0069aa',
      width: '20px',
      height: '20px',
      boderRadis: '5px',
    },
  },
  btnGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  backBtn: {
    display: 'inline-block',
    float: 'left',
    padding: '0 0 0 0',
    color: '#0069aa',
    position: 'relative',
    fontSize: '12px',
    lineHeight: '12px',
    margin: '0',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      background: 'none',
      color: '#f89b23',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '11px',
    },
  },
  payment: {
    background: '#0069aa',
    border: 'none',
    outline: 'none',
    margin: '0 0 0 auto',
    padding: '6px 20px',
    color: '#fff',
    fontSize: '16px',
    borderRadius: '4px',
    '&:hover': {
      background: '#0069aa',
    },
    '&:disabled': {
      background: '#a19c9c',
      color: '#fff',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '14px',
      padding: '5px 10px',
    },
  },
  messagePopup: {
    position: 'relative',
    padding: '0px',
    minWidth: '600px',
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: '100%',
    },
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  popupClose: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    '& a': {
      fontSize: '21px',
      textShadow: '0 1px 0 #fff',
      opacity: '0.2',
      cursor: 'pointer',
      '&:hover': {
        opacity: '1',
      },
    },
  },
  popupBody: {
    padding: '35px',
    textAlign: 'center',
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      margin: '0px 0 20px',
    },
  },
  popupCheck: {
    width: '142px',
    height: '142px',
    border: '4px solid #008000',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: '#008000',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid #008000',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
  passwordValidation: {
    position: 'absolute',
    width: '240px',
    // height: '155px',
    padding: '30px 20px 30px !important',
    top: '-60px',
    right: '-320px',
    border: '2px solid #0069aa',
    background: '#fff',
    zIndex: '99',
    borderRadius: '15px',
    opacity: '1',
    visibility: 'visibility',
    transition: 'all 0.3s ease-in-out',
    WebkitTransition: 'all 0.3s ease-in-out',
    MozTransition: 'all 0.3s ease-in-out',
    margin: '0',
    '&:before': {
      content: "''",
      position: 'absolute',
      top: '58px',
      left: '-22px',
      background: '#fff',
      width: '40px',
      height: '40px',
      border: '2px solid #0069aa',
      transform: 'rotate(45deg) skew(-6deg, -6deg)',
      WebkitTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      MozTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      MsTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      OTransform: 'rotate(45deg) skew(-6deg, -6deg)',
      borderRight: 'none',
      borderTop: 'none',
      zIndex: '-1',
    },
    '& li': {
      lineHeight: '14px',
      color: '#999',
      display: 'flex',
      alignItems: 'center',
      margin: '0 0px 7px 0',
      fontSize: '90%',
      '& img': {
        width: '12px',
        marginRight: '5px',
      },
    },

  },
  logoImage: {
    marginBottom: '15px',
    marginTop: '15px',
    display: 'inline-block',
    '& img': {
      maxWidth: '140px',
    },
  },
  footerJoin: {
    borderTop: '1px solid #ccc',
    padding: '18px 0',
    '& ul': {
      display: 'block',
      padding: '0',
      margin: '0',
      textAlign: 'center',
      '& li': {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0 0 0 8px',
        padding: '0 12px 0 0',
        position: 'relative',
        color: ' #9c9c9c',
        '&::before': {
          content: "''",
          position: 'absolute',
          top: '0',
          right: '0',
          background: '#ccc',
          width: '2px',
          height: '100%',
        },
        '& a': {
          textDecoration: 'underline',
          color: ' #9c9c9c',
        },
        '&:last-child': {
          '&::before': {
            display: 'none',
          },
        },

      },
    },
  },
  cardnumber: {
    border: '1px solid #ccc',
    padding: '12px 12px 10px',
    fontSize: '14px',
    background: '#fff',
    borderRadius: '4px',
    width: 'calc(100% - 40px) !important',
    '& input': {
      fontSize: '14px',
    },
  },
  cardBorder: {
    border: '1px solid red',
  },
  successText: {
    color: '#008000',
    fontSize: '13.5px !important',
    marginTop: 0,
  },
  errorText: {
    color: '#bf0000 !important',
    fontSize: '13.5px !important',
    marginTop: 0,
    fontFamily: 'MuseoSans-700',
    marginBottom: '10px',
    '& span': {
      color: '#bf0000 !important',
      fontSize: '13.5px !important',
      fontFamily: 'MuseoSans-700',
    },
  },
  memberShipDetail: {

  },
  rightGap: {
    paddingRight: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingRight: '0',
    },
  },
  topGap: {
    position: 'relative',
    marginLeft: '27px',
    top: '-7px',
  },
  trusteImage: {
    maxWidth: '199px',
    paddingTop: '50px',
  },
  lockIcon: {
    position: 'absolute',
    top: '0',
    right: '26px',
    bottom: '0',
    margin: 'auto',
  },
  blackFont: {
    color: '#000',
    marginTop: 0,
  },
  cardNumberStar: {
    color: '#bf4927',
    position: 'absolute',
    top: '7px',
    left: '100px',
  },
  cardMMYYStar: {
    color: '#bf4927',
    position: 'absolute',
    top: '7px',
    left: '70px',
  },
  cardCVVStar: {
    color: '#bf4927',
    position: 'absolute',
    top: '7px',
    left: '45px',
  },

  cardHolderName: {
    color: '#bf4927',
    position: 'absolute',
    top: '7px',
    left: '127px',
  },
  cotrolLabel:{
    '& span span div svg':{
      width: '25px',
      height: '25px',
    },
    '& span p':{
      margin: '0px',
    },
    '& span span':{
      margin:'0',padding:'2px',
    },
    '& span span span font':{
      float: 'right',
      fontFamily: 'MuseoSans-500',
    }
  }

} );

export default styles;
