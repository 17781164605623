import { PMT } from '../../utilities/excelLibrary';
// eslint-disable-next-line
let summationData = {};

function ExistingCreditCardDebt( module1Data, module18Data ) {
  /** ******************** Student Loan 1 *********************** */
  // Student Loan Excel Sheet
  let carLoan1Data = {};
  

  // Identifier
  let identifier = 0;
  if ( module18Data.existing_credit_card_balance !== undefined && module18Data.existing_credit_card_balance !== '' && module18Data.existing_credit_card_balance > 0 ) {
    identifier = 1;
  } else {
    identifier = 0;
  }

  // Loan Amount
  let loanAmount = 0;
  let loanAmountVal = 0;
  if ( identifier === 1 && module18Data.existing_credit_card_balance !== undefined && module18Data.existing_credit_card_balance !== '' ) {
    loanAmount = module18Data.existing_credit_card_balance;
	loanAmountVal = module18Data.existing_credit_card_balance;
  } else {
    loanAmount = 0;
	loanAmountVal = 0;
  }

  // Terms in Years
  let termsOfYear = 0;
  if ( identifier === 1 && module18Data.ideal_repayment_schedule !== undefined && module18Data.ideal_repayment_schedule !== '' ) {
    termsOfYear = module18Data.ideal_repayment_schedule / 12;
  } else {
    termsOfYear = 0;
  }

  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( identifier === 1 && module18Data.creditcard_interest_rate !== undefined && module18Data.creditcard_interest_rate !== '' ) {
    annualInterestRate = module18Data.creditcard_interest_rate;
  } else {
    annualInterestRate = 0;
  }

  // Original Payment
  let originalPayment = 0;

  if ( loanAmount > 0
      && termsOfYear > 0 ) {
    originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( loanAmount ), 0, 0 );
  }
  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let startYear = 0;
  if ( module1Data.start_year !== undefined
        && module1Data.start_year !== '' ) {
    startYear = parseInt( module1Data.start_year, 10 );
  }
  if ( startYear > 0 ) {
    let year = parseInt( startYear, 10 );
    const yearLimit = year + parseFloat( termsOfYear );

    // Existing Student Loan Data
    carLoan1Data = {};
    carLoan1Data.Identifier = identifier;
    carLoan1Data['Loan Amount'] = loanAmount;
    carLoan1Data['Term in Years'] = termsOfYear;
    carLoan1Data['Annual Interest Rate'] = annualInterestRate;
    carLoan1Data['Original Payment'] = originalPayment;
    let Balance = loanAmount;

    const Payment = originalPayment;
    let Interest = 0;
    if ( annualInterestRate > 0 ) {
      Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
    }
    let Principal = Payment - Interest;
    let Equity = Principal;
    let TotalInterest = Interest;
    const TotalPayment = Equity + TotalInterest;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];

    // For Loop from graduation year to +10 years
    for ( year; year <= yearLimit; year += 1 ) {
      carLoan1Data[year] = {};

      carLoan1Data[year].LaggingInterest = 0;
      carLoan1Data[year].LaggingPrincipal = 0;
      carLoan1Data[year]['Months Total Balance'] = 0;

      // For Loop for 12 months
      for ( let month = 1; month <= 12; month += 1 ) {
        carLoan1Data[year][month] = {};
        if ( year === startYear && month === 1 ) {
          carLoan1Data[year][month].YearIdentifier = year;
          carLoan1Data[year][month].Balance = Balance;
          carLoan1Data[year][month].Payment = Payment;
          carLoan1Data[year][month].Interest = Interest;
          carLoan1Data[year][month].Principal = Principal;
          carLoan1Data[year][month].Equity = Equity;
          carLoan1Data[year][month].TotalInterest = TotalInterest;
          carLoan1Data[year][month].TotalPayment = TotalPayment;
        } else {
          // Year Identifier
          carLoan1Data[year][month].YearIdentifier = year;

          // Balance
          let tempBalance;
          if ( Balance === 0 ) {
            tempBalance = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempBalance = ( Balance - Principal );
          } else {
            tempBalance = 0;
          }
          carLoan1Data[year][month].Balance = tempBalance;

          // Payment
          carLoan1Data[year][month].Payment = Payment;

          // Interest
          let tempInterest;
          if ( Balance === 0 ) {
            tempInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          } else {
            tempInterest = 0;
          }
          carLoan1Data[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal;
          if ( Balance === 0 ) {
            tempPrincipal = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempPrincipal = Payment - tempInterest;
          } else {
            tempPrincipal = 0;
          }
          carLoan1Data[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity;
          if ( Balance === 0 ) {
            tempEquity = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempEquity = Equity + tempPrincipal;
          } else {
            tempEquity = 0;
          }
          carLoan1Data[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest;
          if ( Balance === 0 ) {
            tempTotalInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempTotalInterest = TotalInterest + tempInterest;
          } else {
            tempTotalInterest = 0;
          }
          carLoan1Data[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment;
          if ( Balance === 0 ) {
            tempTotalPayment = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          } else {
            tempTotalPayment = 0;
          }
          carLoan1Data[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( carLoan1Data[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( carLoan1Data[year][month].TotalPayment );
		carLoan1Data[year].LaggingInterest = parseFloat( carLoan1Data[year].LaggingInterest ) + parseFloat( carLoan1Data[year][month].Interest );
        carLoan1Data[year].LaggingPrincipal = parseFloat( carLoan1Data[year].LaggingPrincipal ) + parseFloat( carLoan1Data[year][month].Principal );
        carLoan1Data[year]['Months Total Balance'] = parseFloat( carLoan1Data[year]['Months Total Balance'] ) + parseFloat( carLoan1Data[year][month][Balance] );
      }
    }

    // Total Interest Paid
    if ( totalInterestPaidArray.length > 0 ) {
      totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
    }

    // Total Paid
    if ( totalPaidArray.length > 0 ) {
      totalPaid = Math.max.apply( null, totalPaidArray );
    }
  }

  // Summation Data
  if ( module1Data.start_year !== undefined
        && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 30;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;
    loanAmount = 0;

    // For Loop from Start year to +14 years
    for ( summationYear; summationYear < summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // Beginning Balance
	  if ( loanAmountVal > 0 ) {
        if ( carLoan1Data !== undefined
                    && carLoan1Data[summationYear] !== undefined
                    && carLoan1Data[summationYear][1].Balance !== undefined ) {
          summationBeginningBalance = carLoan1Data[summationYear][1].Balance;
        } else {
          summationBeginningBalance = 0;
        }
      }
	  
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( carLoan1Data[summationYear] !== undefined && carLoan1Data[summationYear].LaggingInterest !== undefined ) {
          LaggingVal1 = carLoan1Data[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( carLoan1Data[summationYear] !== undefined && carLoan1Data[summationYear].LaggingPrincipal !== undefined ) {
          summationLaggingPrincipal = carLoan1Data[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      // Total Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }
      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = parseFloat( summationData[summationYear].BeginningBalance ) - parseFloat( summationData[summationYear].LaggingPrincipal );
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;
    }
  }

  // To return Car Loan Complete Data
  function existingCreditCardDebtData() {
    const creditCardLoanFullData = {};
    creditCardLoanFullData['Credit Card Data'] = carLoan1Data;
    creditCardLoanFullData['Summation Data'] = summationData;
    creditCardLoanFullData['Total Paid'] = totalPaid;
    creditCardLoanFullData['Total Interest Paid'] = totalInterestPaid;
    creditCardLoanFullData['Annual Interest Rate'] = annualInterestRate;
	return creditCardLoanFullData;
  }
  return existingCreditCardDebtData();
}
export default ExistingCreditCardDebt;
