import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const Ipo = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>IPO</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/ipo.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      IPO
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/ipo-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          When a business starts out, there might be one, two,
                          or a few owners of the company. That business would be
                          a private company, which means its stock would not be
                          available to the public. You can&apos;t just walk into
                          your local hardware store and say, &quot;I would like
                          to buy one share of ownership in your business,
                          please!&quot;
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Imagine. Over time, the same local hardware company
                          grows to thousands of stores nationwide and becomes a
                          very profitable, large, and growing business. That
                          hardware store would like to make an initial public
                          offering and create new shares of the company, shares
                          that would be made available to the public to own and
                          trade. Why would they want to do this? What&apos;s the
                          benefit of an IPO? What are some drawbacks?
                        </p>
                        <p>
                          Remember, when the business started out there were a
                          few owners. Over time, other investors would have
                          given the hardware store money to grow the business.
                          How do those investors and the initial owners earn a
                          return on their money? They have two main options.
                          They either sell the business privately (meaning,
                          another company buys the business) or make an IPO to
                          sell a portion of the business.
                        </p>
                        <p>
                          Before an IPO, 100% of the business is owned by
                          private investors. Let&apos;s say the business is
                          worth $1 billion. In the IPO, owners plan to issue 10
                          million shares at $30 per share. That&apos;s $300
                          million less expenses the business can use to make new
                          investments and continue to grow and thrive.
                          That&apos;s the immediate benefit: Cash to continue to
                          grow the business.
                        </p>
                        <p>
                          But what about the owners and investors looking to
                          earn a return on all their hard work over the years?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Ipo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Ipo);
