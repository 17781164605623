import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import * as moduleServices from '../../calculations/modules-services';
import NumberFormat from 'react-number-format';
// import classNames from 'classnames';

const FinancialProfile = ( props ) => {
  const { classes} = props;
  
  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
       How does your net worth compare to other Americans your age?
      </p>
      <div  className={ classes.studentAnalytics }>
        <table>
            <thead>
                <tr>
                    <th colspan="2">My Net Worth</th>
                </tr>
            </thead>
          <tbody>
            <tr class="">
              <td><b>Net Worth</b></td>
              <td>
                {moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth } prefix={ moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth >= 0 ? '$' : '($' } suffix={ moduleServices.module18Data.currentNetWorthTotalCurrentNetWorth < 0 && ')' } />
                  ) : ( '$0' )}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
              <tr>
                  <th>Net Worth by Age</th>
                  <th>Median</th>
                  <th>Average</th>
              </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                  &lt;35 years old
              </td>
              <td>$13,900</td>
              <td>$76,300 </td>
            </tr>
            <tr>
              <td>
                  35 to 44 years old
              </td>
              <td>$91,300 </td>
              <td>$436,200 </td>
            </tr>
            <tr>
              <td>
                  45 to 54 years old
              </td>
              <td>$168,600</td>
              <td>$833,200</td>
            </tr>
            <tr>
              <td>
                  55 to 64 years old
              </td>
              <td>$212,500</td>
              <td>$1,175,900 </td>
            </tr>
              <tr>
              <td>
                  65 to 74 years old
              </td>
              <td>$266,400 </td>
              <td>$1,217,700 </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p> <i><a href="https://www.federalreserve.gov/publications/files/scf20.pdf" target="_blank" rel="noreferrer">Source: 2019 Survey of Consumer Finances</a></i> </p>
    </div>
  );
};

FinancialProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleAssestObject: PropTypes.object.isRequired,
  // handleUpdatedObject: PropTypes.func.isRequired,
};

export default withStyles( styles )( FinancialProfile );
