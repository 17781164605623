import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import Sidebar from './sidebar';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import Pageloader from '../../components/ui/pageloader';
import scrollToComponent from 'react-scroll-to-component';
import headerFI from '../../assets/img/pwi-investments/assets_liabilities.png';
import LogoImage from '../../assets/img/logo.png';
import styles from './styles';
import ClearTime from '../../assets/img/pwi-investments/clear-timeline-blue.png';
import Walk from '../../assets/img/pwi-investments/walk.png';
import ExistingDashboard from '../../components/pwi-existing/existing-dashboard';
import ExistingCreditCardBalance from '../../components/pwi-existing/existing-credit-card-balance';
import ExistingHomeSummary from '../../components/pwi-existing/existing-home-summary';
import ExistingStudLoansRepayment from '../../components/pwi-existing/existing-student-loans-repayment';
import ExistingStudentLoansSummary from '../../components/pwi-existing/existing-student-loans-summary';
import FederalStudentLoans from '../../components/pwi-existing/federal-student-loans';
import FederalSubsidizedStudLoan from '../../components/pwi-existing/Federal-subsidized-student-loan';
import FederalUnsubStudentLoans from '../../components/pwi-existing/federal-unsubsidized-student-loan';
import PrivateStudentLoans from '../../components/pwi-existing/private-student-loans1';
import PrivateStudentLoans2 from '../../components/pwi-existing/private-student-loans2';
//New slides
import Cash from '../../components/pwi-existing/cash';
import MaxCashBalance from '../../components/pwi-existing/max-cash-balance';
import ShortTermInvestment from '../../components/pwi-existing/short-term-investments';
import LongTermInvestment from '../../components/pwi-existing/long-term-investments';
import MaxShortTermInvestmentsBalance from '../../components/pwi-existing/max-short-term-investments-balance';
import OtherInvestments from '../../components/pwi-existing/other-investments';
import LifeInsurance from '../../components/pwi-existing/life-insurance';
import CashSummary from '../../components/pwi-existing/cash-summary';
import RetirementAccounts from '../../components/pwi-existing/retirement-accounts';
import RetirementAccountsList from '../../components/pwi-existing/retirement-accounts-list';
import RetirementSavings from '../../components/pwi-existing/retirement-savings';
import BusinessValue from '../../components/pwi-existing/BusinessValue';
import OtherValue from '../../components/pwi-existing/other-value';
import Vehicles from '../../components/pwi-existing/vehicles';
import VehicleOwn1 from '../../components/pwi-existing/vehicles-own';
import VehicleOwn2 from '../../components/pwi-existing/vehicles-own2';
import VehicleLease from '../../components/pwi-existing/vehicles-lease';
import VehicleSummary from '../../components/pwi-existing/vehicles-summary';
import Home from '../../components/pwi-existing/home';
import Homes1 from '../../components/pwi-existing/homes-1';
import HomesLoan from '../../components/pwi-existing/home-loan';
import HomeSale1 from '../../components/pwi-existing/home-sale1';
import HomeSale2 from '../../components/pwi-existing/home-sale2';
import HomeSummary from '../../components/pwi-existing/home-summary';
import PersonalLoan from '../../components/pwi-existing/personal-loan';
import OtherDebt from '../../components/pwi-existing/other-debt';
import NetWorth from '../../components/pwi-existing/net-worth';
import FinancialProfile from '../../components/pwi-existing/financial-profile';
import HigherEducationAttending from '../../components/pwi-existing/higher-education';
//New slides Done

import PwiSave from '../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../components/common/save-popup-success';
import DefinationPopup from '../../components/ui/pwi-sidebar/defination-popup';
import PwiLeftMenu from '../../components/pwi/pwi-left-menu';
import WalkThruOne from '../pwi/wallk-thru/walkThruOne';
import WalkThruTwo from '../pwi/wallk-thru/walkThruTwo';
import WalkThruThree from '../pwi/wallk-thru/walkThruThree';
import WalkThruFour from '../pwi/wallk-thru/walkThruFour';
import WalkThruFive from '../pwi/wallk-thru/walkThruFive';
import WalkThruSix from '../pwi/wallk-thru/walkThruSix';
import WalkThruSeven from '../pwi/wallk-thru/walkThruSeven';
import WalkThruEight from '../pwi/wallk-thru/walkThruEight';
import * as modulesServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import * as moduleServices from '../../calculations/modules-services';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import save from '../../assets/img/build-career-sketch/icon-save-blue.png';
import messages from '../../utilities/pwi-popup-messages';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import SimulatorPanel from '../../components/documents/simulator-panel';
import SummaryDocs from '../../components/documents/documents';
import defaultValues from "../../calculations/default-module-values"

const PWI_DEFINATION = loader('../../graphql/schema/financial-independence/getPWiDefination.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const CHECK_MODULE_EXISTS = loader('../../graphql/schema/fc/check-module-exists.graphql');

const titleText = [
  //New
  'Getting started',
  'Cash',
  'Max cash Balance',
  'Short-Term Investments',
  'Max Short-Term Investments Balance',
  'Long-Term Investments',
  'Other Investments',
  'Life Insurance Cash Value',
  'Cash and Investments Summary',
  'Retirement Accounts',
  'Retirement Accounts',
  'Retirement Savings by Age',
  'Business Value',
  'Other Value',
  'Vehicles',
  'Vehicle1: Own',
  'Vehicle1: Own',
  'Vehicle1: Lease',
  'Vehicle2: Own',
  'Vehicle2: Own',
  'Vehicle2: Lease',
  'Vehicle3: Own',
  'Vehicle3: Own',
  'Vehicle3: Lease',
  'Vehicle4: Own',
  'Vehicle4: Own',
  'Vehicle4: Lease',
  'Vehicles Summary',
  'Home',
  'Current Home',
  'Current Home Loan',
  'Current Home Expenses',
  'Current Home Sale',
  'Current Home Summary',
  'Second Home',
  'Second Home Loan',
  'Second Home Expenses',
  'Second Home Sale',
  'Second Home Summary',
  'Other Debt',
  'Credit Card Debt',
  'Personal Loans',
  'Student Loans',
  'Federal Student Loans',
  'Federal Subsidized Student Loan', 
  'Federal Unsubsidized Student Loan',
  'Private Student Loans',
  'Private Student Loans',
  'Existing Student Loan Repayment',
  'Student Loan Recap',
  'My Net Worth',
  'My Financial Profile',
  //New DOne
];

const Existing = (props) => {
  const { classes, history } = props;
  let bodySection = useRef(null);

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [saveKey, setSaveKey] = useState('');
  const [popupClose, setPopupClose] = useState(false);
  const [titleDefination, setTitleDefination] = useState('');
  const [definationPopup, setDefinationPopup] = useState(false);
  const [pointerEvent, setPointerEvent] = useState(true);
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: false });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [completedSlides, setCompletedSlides] = useState({ 1: true });
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [assestsData, setAssestsdata] = useState({...defaultValues.module18,...{'existingDashType':""}});
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [displayContinue, setDisplayContinue] = React.useState(false);
  const [loadValue, setLoadValue] = React.useState(false);

  const [retirementList] = React.useState([
    'Traditional IRA',
    '401(k) / 403b',
    'Roth IRA',
    'Roth 401(k) / 403b',
    'SEP IRA',
    'Pension',
  ])
  const [activeRetirementList, setActiveRetirementList] = React.useState([
    'Traditional IRA',
    '401(k) / 403b',
    'Roth IRA',
    'Roth 401(k) / 403b',
    'SEP IRA',
    'Pension',
  ])

  const [vehicles, setVehicles] = React.useState([
    {
      vehicle: false,
      type: "",
      stepOne: false
    },{
      vehicle: false,
      type: "",
      stepOne: false
    },{
      vehicle: false,
      type: "",
      stepOne: false
    },{
      vehicle: false,
      type: "",
      stepOne: false
    },
  ])
  const [homes, setHomes] = React.useState([
    {
      home: false, 
      type: 1,
      currentStep: 0,
    },{
      home: false, 
      type: 2,
      currentStep: 0,
    }
  ])

  const [debts, setDebts] = React.useState([
    {
      status: false,
      field: 'credit_card_status'
    }, {
      status: false,
      field: 'personal_loans_status'
    },{
      status: false,
      field: 'federal_student_loans_status'
    },{
      status: false,
      field: 'private_student_loans_status'
    },
  ])

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module18' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 1; c <= 52; c += 1) {
            completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
        setAssestsdata(data);
        let newArrVehicle = []
          newArrVehicle[0] = {
            vehicle: (data.vehicle_1_type !== undefined && data.vehicle_1_type !== "" && data.vehicle_1_type !== null) ? true : false,
            type: data.vehicle_1_type,
            stepOne: false
          }
          newArrVehicle[1] = {
            vehicle: (data.vehicle_2_type !== undefined && data.vehicle_2_type !== "" && data.vehicle_2_type !== null) ? true : false,
            type: data.vehicle_2_type,
            stepOne: false
          }
          newArrVehicle[2] = {
            vehicle: (data.vehicle_3_type !== undefined && data.vehicle_3_type !== "" && data.vehicle_3_type !== null) ? true : false,
            type: data.vehicle_3_type,
            stepOne: false
          }
          newArrVehicle[3] = {
            vehicle: (data.vehicle_4_type !== undefined && data.vehicle_4_type !== "" && data.vehicle_4_type !== null) ? true : false,
            type: data.vehicle_4_type,
            stepOne: false
          }
          setVehicles([...newArrVehicle])
          let newArrDebt = [
            {
              status: data.credit_card_status !== null && data.credit_card_status !== undefined ? data.credit_card_status : 0,
              field: 'credit_card_status'
            }, {
              status: data.personal_loans_status !== null && data.personal_loans_status !== undefined ? data.personal_loans_status : 0,
              field: 'personal_loans_status'
            },{
              status: data.federal_student_loans_status !== null && data.federal_student_loans_status !== undefined ? data.federal_student_loans_status : 0,
              field: 'federal_student_loans_status'
            },{
              status: data.private_student_loans_status !== null && data.private_student_loans_status !== undefined ? data.private_student_loans_status : 0,
              field: 'private_student_loans_status'
            },
          ]
          setDebts([...newArrDebt])

          let newArrHome = [
            {
              home: data.existig_home_current_status ? true : false, 
              type: 1,
              currentStep: 0,
            },{
              home: (data.existig_home_current_status_2 && data.existig_home_current_status) ? true : false, 
              type: 2,
              currentStep: 0,
            }
          ]
          setHomes(newArrHome)
          console.log("handleAssestObjects", data, newArrHome)

          setDisplayContinue(true);
        } else {
          setAssestsdata({...defaultValues.module18,...{'existingDashType':""}});
          setActiveScenarioData({});
        }
        setActiveStep(1);
        setLoading(false);
      });
    },
    onError(error) {
      setClearModule(false);
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setAssestsdata({...defaultValues.module18,...{'existingDashType':""}});
        }
        setActiveStep(1);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });
  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module18',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/existing-assets-liabilities' : url, saveContinueBtn }, ...popupdetails })
  }

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    setAssestsdata({...defaultValues.module18,...{'existingDashType':""}});
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setAssestsdata({...defaultValues.module18,...{'existingDashType':""}});
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  }

  useEffect(() => {
    const totalSlides = 52;
    const progressBarPx = 21.5;
    if (activeStep === 1) {
      if (activeStep === 1 && (completedSlides[26] || completedSlides[13] || completedSlides[17] || completedSlides[22])) {
        setProgress((2 / 8) * progressBarPx);
      } else {
        setProgress(0);
      }
    } else {
      let progressBar = 0;
      if (activeStep > 1 && activeStep <= totalSlides) {
        progressBar = (activeStep - 1) * (progressBarPx / 11);
      }else {
        progressBar = (activeStep - 30) * (progressBarPx / 5);
      }
      setProgress(progressBar);
    }
  }, [activeStep,completedSlides]);

  const handleNext = () => {
    window.scrollTo(0, 0);    

    //Next vehicle slides
    if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19 || activeStep === 20 || activeStep === 21 || activeStep === 22 || activeStep === 23 || activeStep === 24 || activeStep === 25 || activeStep === 26)){
      
      if( vehicles[0].vehicle || vehicles[1].vehicle || vehicles[2].vehicle || vehicles[3].vehicle){
        if(activeStep === 15 && vehicles[0].vehicle && vehicles[0].type === 'Own' && !vehicles[0].stepOne) {
          let newArr = [...vehicles]
          newArr[0].stepOne = true;
          setVehicles([...newArr])
          setActiveStep(16)
        }else if((activeStep === 15 || activeStep === 16) && vehicles[0].vehicle && vehicles[0].type === 'Own' && vehicles[0].stepOne) {
          let newArr = [...vehicles]
          newArr[0].stepOne = false;
          setVehicles([...newArr])
          setActiveStep(17)
        }else if((activeStep === 15) && vehicles[0].vehicle && vehicles[0].type === 'Lease'){
          setActiveStep(18)
        }else
        
        if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18) && vehicles[1].vehicle && vehicles[1].type === 'Own' && !vehicles[1].stepOne) {
          let newArr = [...vehicles]
          newArr[1].stepOne = true;
          setVehicles([...newArr])
          setActiveStep(19)
        }else if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19) && vehicles[1].vehicle && vehicles[1].type === 'Own' && vehicles[1].stepOne) {
          let newArr = [...vehicles]
          newArr[1].stepOne = false;
          setVehicles([...newArr])
          setActiveStep(20)
        }else if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18) && vehicles[1].vehicle && vehicles[1].type === 'Lease'){
          setActiveStep(21)
        }else
        
        if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19 || activeStep === 20 || activeStep === 21)&& vehicles[2].vehicle && vehicles[2].type === 'Own' && !vehicles[2].stepOne) {
          let newArr = [...vehicles]
          newArr[2].stepOne = true;
          setVehicles([...newArr])
          setActiveStep(22)
        }else if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19 || activeStep === 20 || activeStep === 21 || activeStep === 22)&& vehicles[2].vehicle && vehicles[2].type === 'Own' && vehicles[2].stepOne) {
          let newArr = [...vehicles]
          newArr[2].stepOne = false;
          setVehicles([...newArr])
          setActiveStep(23)
        }else if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19 || activeStep === 20 || activeStep === 21) && vehicles[2].vehicle && vehicles[2].type === 'Lease'){
          setActiveStep(24)
        }else
    
        if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19 || activeStep === 20 || activeStep === 21 || activeStep === 22 || activeStep === 23 || activeStep === 24)&& vehicles[3].vehicle && vehicles[3].type === 'Own' && !vehicles[3].stepOne) {
          let newArr = [...vehicles]
          newArr[3].stepOne = true;
          setVehicles([...newArr]);
          setActiveStep(25)
        }else if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19 || activeStep === 20 || activeStep === 21 || activeStep === 22 || activeStep === 23 || activeStep === 24 || activeStep === 25)&& vehicles[3].vehicle && vehicles[3].type === 'Own' && vehicles[3].stepOne) {
          let newArr = [...vehicles]
          newArr[3].stepOne = false;
          setVehicles([...newArr]);
          setActiveStep(26)
        }else if((activeStep === 15 || activeStep === 16 || activeStep === 17 || activeStep === 18 || activeStep === 19 || activeStep === 20 || activeStep === 21 || activeStep === 22 || activeStep === 23 || activeStep === 24 || activeStep === 25 || activeStep === 26) && vehicles[3].vehicle && vehicles[3].type === 'Lease'){
          setActiveStep(27)
        }else{
          if( vehicles[0].vehicle || vehicles[1].vehicle || vehicles[2].vehicle || vehicles[3].vehicle){
            let newArr = [...vehicles]
            newArr[0].stepOne = false;
            newArr[1].stepOne = false;
            newArr[2].stepOne = false;
            newArr[3].stepOne = false;
            setActiveStep([...newArr])
            setActiveStep(28)
          }
        }
      }else{
        setActiveStep(29)
      }
    }else if(activeStep === 29 || activeStep === 30 || activeStep === 31 || activeStep === 32 || activeStep === 33 || activeStep === 34 || activeStep === 35 || activeStep === 36 || activeStep === 37 || activeStep === 38 || activeStep === 39){

      // Current Home next
      if(activeStep === 29 && homes[0].home && homes[0].currentStep === 0 && homes[0].type === 1){
        const newArr = [...homes]
        newArr[0].currentStep = 1;
        setHomes([...newArr])
        setActiveStep(30)
      }else if(activeStep === 30 && homes[0].home && homes[0].currentStep === 1 && homes[0].type === 1){
        const newArr = [...homes]
        newArr[0].currentStep = 2;
        setHomes([...newArr])
        setActiveStep(31)
      }else if(activeStep === 31 && homes[0].home && homes[0].currentStep === 2 && homes[0].type === 1){
        const newArr = [...homes]
        newArr[0].currentStep = 3;
        setHomes([...newArr])
        setActiveStep(32)
      }else if(activeStep === 32 && homes[0].home && homes[0].currentStep === 3 && homes[0].type === 1){
        const newArr = [...homes]
        newArr[0].currentStep = 4;
        setHomes([...newArr])
        setActiveStep(33)
      }else if(activeStep === 33 && homes[0].home && homes[0].currentStep === 4 && homes[0].type === 1){
        const newArr = [...homes]
        newArr[0].currentStep = 5;
        setHomes([...newArr])
        setActiveStep(34)
      }else 
      //Second home next
      if(activeStep === 34 && homes[1].home && homes[1].currentStep === 0 && homes[1].type === 2){
        const newArr = [...homes]
        newArr[1].currentStep = 1;
        setHomes([...newArr])
        setActiveStep(35)
      }else if(activeStep === 35 && homes[1].home && homes[1].currentStep === 1 && homes[1].type === 2){
        const newArr = [...homes]
        newArr[1].currentStep = 2;
        setHomes([...newArr])
        setActiveStep(36)
      }else if(activeStep === 36 && homes[1].home && homes[1].currentStep === 2 && homes[1].type === 2){
        const newArr = [...homes]
        newArr[1].currentStep = 3;
        setHomes([...newArr])
        setActiveStep(37)
      }else if(activeStep === 37 && homes[1].home && homes[1].currentStep === 3 && homes[1].type === 2){
        const newArr = [...homes]
        newArr[1].currentStep = 4;
        setHomes([...newArr])
        setActiveStep(38)
      }else if(activeStep === 38 && homes[1].home && homes[1].currentStep === 4 && homes[1].type === 2){
        const newArr = [...homes]
        newArr[1].currentStep = 5;
        setHomes([...newArr])
        setActiveStep(39)
      }else{
        setActiveStep(40)
      }
    }else if(activeStep === 40 || activeStep === 41 || activeStep === 42 || activeStep === 46){
      if(activeStep === 40 && debts[0].status)
        setActiveStep(41)
      else if((activeStep === 40 || activeStep === 41) && debts[1].status)
        setActiveStep(42)
      else if((activeStep === 40 || activeStep === 41 || activeStep === 42) && debts[2].status)
        setActiveStep(43)
      else if((activeStep === 40 || activeStep === 41 || activeStep === 42) && !debts[2].status &&  debts[3].status)
        setActiveStep(47)
      else if((activeStep === 40 || activeStep === 41 || activeStep === 42 || activeStep === 46) && debts[3].status)
        setActiveStep(47)
      else
        setActiveStep(49)
    }else{
      if(activeStep <= 52){
        setActiveStep(activeStep + 1);
      }
    }
    
    setMenu(false);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);

    //Back vehicle slides
    if(( activeStep === 18 || activeStep === 19 || activeStep === 21 || activeStep === 22 || activeStep === 24 || activeStep === 25 || activeStep === 27)){
      if(activeStep === 18 && vehicles[0].vehicle && vehicles[0].type === "Lease"){
        //Vehicle 1 Lease
        setActiveStep(15);

      }else if((activeStep === 19 || activeStep === 21) && vehicles[1].vehicle && (vehicles[1].type === 'Own' || vehicles[1].type === 'Lease')){

        //Vehicle 2 Own
        if(vehicles[0].vehicle && vehicles[0].type === "Own"){
          let newArr = [...vehicles]
          newArr[0].stepOne = false;
          setActiveStep([...newArr])
          setActiveStep(17);
        }else if(vehicles[0].vehicle && vehicles[0].type === "Lease"){
          setActiveStep(18);
        }else{
          setActiveStep(15);
        }

      }else if((activeStep === 22 || activeStep === 24) && vehicles[2].vehicle && (vehicles[2].type === 'Own' || vehicles[2].type === 'Lease')){
        
        //Vehicle 3 Own
        if(vehicles[1].vehicle && vehicles[1].type === "Own"){
          let newArr = [...vehicles]
          newArr[1].stepOne = false;
          setActiveStep([...newArr])
          setActiveStep(20);
        }else if(vehicles[1].vehicle && vehicles[1].type === "Lease"){
          setActiveStep(21);
        }else{
          setActiveStep(15);
        }
      }else if((activeStep === 25 || activeStep === 27) && vehicles[3].vehicle && (vehicles[3].type === 'Own' || vehicles[3].type === 'Lease')){
        
        //Vehicle 4 Own
        if(vehicles[2].vehicle && vehicles[2].type === "Own"){
          let newArr = [...vehicles]
          newArr[2].stepOne = false;
          setActiveStep([...newArr])
          setActiveStep(23);
        }else if(vehicles[2].vehicle && vehicles[2].type === "Lease"){
          setActiveStep(24);
        }else{
          setActiveStep(15);
        }
      }
    }else if(activeStep === 28){
        if( vehicles[0].vehicle || vehicles[1].vehicle || vehicles[2].vehicle || vehicles[3].vehicle){
          //Vehicle 1
          if(vehicles[0].vehicle && vehicles[0].type === "Own"){
            let newArr = [...vehicles]
            newArr[0].stepOne = false;
            setActiveStep([...newArr])
            setActiveStep(17);
          }else if(vehicles[0].vehicle && vehicles[0].type === "Lease"){
            setActiveStep(18);
          }
          //Vehicle 2
          if(vehicles[1].vehicle && vehicles[1].type === "Own"){
            let newArr = [...vehicles]
            newArr[1].stepOne = false;
            setActiveStep([...newArr])
            setActiveStep(20);
          }else if(vehicles[1].vehicle && vehicles[1].type === "Lease"){
            setActiveStep(21);
          }
          //Vehicle 3
          if(vehicles[2].vehicle && vehicles[2].type === "Own"){
            let newArr = [...vehicles]
            newArr[2].stepOne = false;
            setActiveStep([...newArr])
            setActiveStep(23);
          }else if(vehicles[2].vehicle && vehicles[2].type === "Lease"){
            setActiveStep(24);
          }
          //Vehicle 4
          if(vehicles[3].vehicle && vehicles[3].type === "Own"){
            let newArr = [...vehicles]
            newArr[3].stepOne = false;
            setActiveStep([...newArr])
            setActiveStep(26);
          }else if(vehicles[3].vehicle && vehicles[3].type === "Lease"){
            setActiveStep(27);
          }
        }else{
          setActiveStep(15);
        }

      }else if (activeStep === 29 && (!vehicles[0].vehicle && !vehicles[1].vehicle && !vehicles[2].vehicle && !vehicles[3].vehicle)){
        setActiveStep(15);
    }else if(activeStep === 35 && !assestsData.existig_home_current_status){
      setActiveStep(29)
    }else if(activeStep === 40 && !assestsData.existig_home_current_status_2 && !assestsData.existig_home_current_status){
      setActiveStep(29)
    }else if(activeStep === 40 && !assestsData.existig_home_current_status_2 && assestsData.existig_home_current_status){
      setActiveStep(34)
    }else if (activeStep === 42 ||activeStep === 43 || activeStep === 47 || activeStep === 49){
      if(activeStep === 42 && debts[0].status){
        setActiveStep(41)
      }else if(activeStep === 42 && !debts[0].status){
        setActiveStep(40)
      }else if(activeStep === 43 && debts[1].status){
        setActiveStep(42)
      }else if(activeStep === 43 && debts[0].status && !debts[1].status){
        setActiveStep(41)
      }else if(activeStep === 43 && !debts[0].status && !debts[1].status){
        setActiveStep(40)
      }else if(activeStep === 47 && debts[2].status){
        setActiveStep(46)
      }else if(activeStep === 47 && debts[1].status && !debts[2].status){
        setActiveStep(42)
      }else if(activeStep === 47 && debts[0].status && !debts[1].status && !debts[2].status){
        setActiveStep(41)
      }else if(activeStep === 47 && !debts[0].status && !debts[1].status && !debts[2].status){
        setActiveStep(40)
      }else if(activeStep === 49 && debts[3].status){
        setActiveStep(47)
      }else if(activeStep === 49 && debts[2].status && !debts[3].status){
        setActiveStep(46)
      }else if(activeStep === 49 && debts[1].status && !debts[2].status && !debts[3].status){
        setActiveStep(42)
      }else if(activeStep === 49 && debts[0].status && !debts[1].status && !debts[2].status && !debts[3].status){
        setActiveStep(41)
      }else if(activeStep === 49 && !debts[0].status && !debts[1].status && !debts[2].status && !debts[3].status){
        setActiveStep(41)
      }
    }else{
      if(activeStep > 0){
        if(activeStep <= 34 && activeStep >= 30){
          let h = [...homes];
          h[0].currentStep -= 1; 
          setHomes([...homes])
        }
        if(activeStep <= 39 && activeStep >= 35){
          let h = [...homes];
          h[1].currentStep -= 1; 
          setHomes([...homes])
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    }
  };

  const handleSideMenu = (index) => {
    setActiveStep(index);
    setMenu(false);
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleMainMenu = () => {
    setMainMenu(!mainMenu);
  };

  const handleCareerSave = (data) => {
    const objectParams = {};
    if (data === 'save') {
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = 'module18';
      objectParams.moduleNumber = 'module18Data';
      setmoduleObjectParams(objectParams);
    }
    setSaveKey(data);
    setPopupClose(true);
  };

  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleCareerSave('save');
  }

  const handlePopUpClose = () => {
    setPopupClose(false);
  };

  const handleDoubleClickType = (type) => {
    setAssestsdata({...assestsData,...{'existingDashType':type}});
    if (type === 'studentLoans') {
      setActiveStep(2);
    } else if (type === 'creditCards') {
      setActiveStep(13);
    } else if (type === 'car') {
      setActiveStep(15);
    } else if (type === 'home') {
      setActiveStep(18);
    } else if (type === 1 || type === 2 || type === 3 || type === 4) {
      setActiveStep(3);
    }
  };

  const handleUpdatedObject = (data) => {
    modulesServices.setModule18Data(data);
    setAssestsdata(data);
  };

  const handleDefinationPopup = (def) => {
    setTitleDefination(def);
    setDefinationPopup(true);
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 20,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 3 || slide === 4) {
      setMenu(true);
    } else if (slide === 5) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };
  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module18";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module18', 'quick-save', activeScenarioData);
      finalObject['module18Data'] = pwiObject.getfilteredExistingObject(moduleServices.module18Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }
  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>
        <Sidebar debts={debts} setVehicles={setVehicles} activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleSideMenu} openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={setMenu} pointerEvent={pointerEvent} completedSlides={completedSlides} />
        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={headerFI} alt="" aria-hidden="true" onClick={handleMainMenu} />
                <h3>Existing Assets & Liabilities</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" className={classes.menuIcon} onClick={handleMenu}>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                <h3>{titleText[activeStep - 1]}</h3>
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''}>
                <img src={Walk} alt="" data-for="walk" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>
            {(activeStep === 1) && (
              <ExistingDashboard
                handleAssestObject={assestsData}
                handleDoubleClickType={handleDoubleClickType}
                handleUpdatedObject={handleUpdatedObject}
                displayContinue= {displayContinue}
                completedSlides={completedSlides}
              />
            )}
            {(activeStep === 2) && (
              <Cash
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 3) && (
              <MaxCashBalance
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 4) && (
              <ShortTermInvestment
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 5) && (
              <MaxShortTermInvestmentsBalance
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
              
            )}
            {(activeStep === 6) && (
              <LongTermInvestment
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
              
            )}
            {(activeStep === 7) && (
              <OtherInvestments
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
              />
              
            )}
            {(activeStep === 8) && (
              <LifeInsurance
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 9) && (
              <CashSummary
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                setActiveStep={setActiveStep}
              />
            )}

            {(activeStep === 10) && (
              <RetirementAccounts
                retirementList={retirementList}
                setActiveRetirementList={setActiveRetirementList}
                activeRetirementList={activeRetirementList}
              />
            )}
            {(activeStep === 11) && (
              <RetirementAccountsList
                active= {activeRetirementList.filter(v => v !== undefined)}
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 12) && (
              <RetirementSavings handleAssestObject={assestsData}/>
            )}

            {(activeStep === 13) && (
              <BusinessValue 
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 14) && (
              <OtherValue 
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 15) && (
              <Vehicles
                setVehicles={setVehicles}
                vehicles={vehicles}
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 16) && (
              <VehicleOwn1
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={""}
              />
            )}
            {(activeStep === 17) && (
              <VehicleOwn2
                handleDefinationPopup={handleDefinationPopup}
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={1}
              />
            )}
            {(activeStep === 18) && (
              <VehicleLease
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={1}
              />
            )}
            {(activeStep === 19) && (
              <VehicleOwn1
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={2}
              />
            )}
            {(activeStep === 20) && (
              <VehicleOwn2
                handleDefinationPopup={handleDefinationPopup}
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={2}
              />
            )}
            {(activeStep === 21) && (
              <VehicleLease
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={2}
              />
            )}
            {(activeStep === 22) && (
              <VehicleOwn1
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={3}
              />
            )}
            {(activeStep === 23) && (
              <VehicleOwn2
                handleDefinationPopup={handleDefinationPopup}
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={3}
              />
            )}
            {(activeStep === 24) && (
              <VehicleLease
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={3}
              />
            )}
            {(activeStep === 25) && (
              <VehicleOwn1
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={4}
              />
            )}
            {(activeStep === 26) && (
              <VehicleOwn2
                handleDefinationPopup={handleDefinationPopup}
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={4}
              />
            )}
            {(activeStep === 27) && (
              <VehicleLease
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                vehicle={4}
              />
            )}
            {(activeStep === 28) && (
              <VehicleSummary/>
            )}
            {(activeStep === 29) && (
              <Home
              setHomes = {setHomes}
              homes = {homes}
              handleAssestObject = {assestsData}
              handleUpdatedObject = {handleUpdatedObject}/>
            )}
            {(activeStep === 30) && (
              <Homes1
                home = {1}
                handleAssestObject = {assestsData}
                handleUpdatedObject = {handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 31) && (
              <HomesLoan
                home = {1}
                handleAssestObject = {assestsData}
                handleUpdatedObject = {handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 32) && (
              <ExistingHomeSummary 
              home={1} 
              handleUpdatedObject = {handleUpdatedObject} 
              handleAssestObject={assestsData} />
            )}
            {(activeStep === 33) && (
              <HomeSale1 
                handleDefinationPopup={handleDefinationPopup}
                handleUpdatedObject = {handleUpdatedObject} 
                handleAssestObject={assestsData} 
              />
            )}
            {(activeStep === 34) && (
              <HomeSummary 
              home={1} 
              handleAssestObject={assestsData} />
            )}
            {(activeStep === 35) && (
              <Homes1
                home = {2}
                handleAssestObject = {assestsData}
                handleUpdatedObject = {handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 36) && (
              <HomesLoan
                home = {2}
                handleAssestObject = {assestsData}
                handleUpdatedObject = {handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 37) && (
              <ExistingHomeSummary 
              home={2} 
              handleUpdatedObject = {handleUpdatedObject} 
              handleAssestObject={assestsData} />
            )}
            {(activeStep === 38) && (
              <HomeSale2
                handleDefinationPopup={handleDefinationPopup}
                handleUpdatedObject = {handleUpdatedObject} 
                handleAssestObject={assestsData} 
              />
            )}
            {(activeStep === 39) && (
              <HomeSummary 
              home={2} 
              handleAssestObject={assestsData} />
            )}
            {(activeStep === 40) && (
              <OtherDebt 
              debts= { debts}
              setDebts = {setDebts}
              handleUpdatedObject = {handleUpdatedObject} 
              handleAssestObject={assestsData} 
              />
            )}
            {(activeStep === 41) && (
              <ExistingCreditCardBalance
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 42) && (
              <PersonalLoan 
              handleUpdatedObject = {handleUpdatedObject} 
              handleAssestObject={assestsData} 
              handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 43) && (
              <HigherEducationAttending 
              handleUpdatedObject = {handleUpdatedObject} 
              handleAssestObject={assestsData} 
              handleDefinationPopup={handleDefinationPopup}
              setPointerEvent={setPointerEvent}/>
            )}
            {(activeStep === 44) && (
              <FederalStudentLoans
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 45) && (
              <FederalSubsidizedStudLoan
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 46) && (
              <FederalUnsubStudentLoans
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 47) && (
              <PrivateStudentLoans
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 48) && (
              <PrivateStudentLoans2
                handleAssestObject={assestsData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 49) && (
              <ExistingStudLoansRepayment />
            )}
            {(activeStep === 50) && (
              <ExistingStudentLoansSummary />
            )}
            {(activeStep === 51) && (
              <NetWorth />
            )}
            {(activeStep === 52) && (
              <FinancialProfile />
            )}
              
            {definationResult && definationResult.getPWiDefination
              && (
                <DefinationPopup
                  definationPopup={definationPopup}
                  getPWiDefination={definationResult.getPWiDefination}
                  handleDefinationPopupClose={handleDefinationPopupClose}
                />
              )}
          </div>
          <div className={classes.btnGroup}>
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="existing" />)}
            {activeStep === 1 ? '' : <Button className={classes.cancel} onClick={handleBack}>Back</Button>}
            <Button className={classes.clearBtn} onClick={clearScenario} aria-hidden="true" data-for="walk" data-tip="Clear your scenario and start fresh."><img src={ClearTime} alt="" /></Button>
            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <Button onClick={handleQuickSave} className={classes.quickSave}><span><img src={save} alt="" data-for="walk" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page." /></span></Button>
            )}
            {(activeStep === 1) && (completedSlides[52])
              ? (
                <div>
                  {/* <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleCareerSave('save'); }}>Save</Button>
                  {' '} */}
                  <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleNext(activeStep + 1); }}>Next</Button>
                </div>
              ) : (activeStep === 52) ?
                <div>
                  <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleCareerSave('save'); }}>Save</Button>
                </div>
              : <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleNext(activeStep + 1); }} disabled={activeStep === 1 && assestsData.existingDashType === undefined }>Next</Button>}
          </div>
          <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        </div>
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={saveKey === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={saveKey === 'clear' ? classes.clearModule : ''}>
              {saveKey === 'save' && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={handlePopUpClose}>
                      ×
                  </span>
                  </div>
                  <PwiSave setPopupOpen={setPopupClose} moduleParams={moduleObjectParams} getSaveData={assestsData} setLoadValue={setLoadValue}/>
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module18' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
          <SavePopupSucess setLoadValue={setLoadValue}/>
           </DialogContent>
        </Dialog>
      </div>
      
       </Typography>
  );
};

Existing.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Existing);
 