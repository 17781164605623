const data = [
  {
    title: 'Purchase Analysis',
    children: [
      {
        label: '# of Years Invested',
        type: 'date',
        prefix: '',
        suffix: '',
        key: 'years_invested',
      },
      {
        label: 'Purchase Price',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'purchase_price',
      },
      {
        label: 'Down Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'downpayment_calcs',
      },
      {
        label: 'Closing Costs',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'closing_costs',
      },
      {
        label: 'Fit Out Costs',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'external_and_internal_fit_out_costs',
      },
      {
        label: 'Cash Needed at Purchase',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'cashneeded_at_purchase',
      },
    ],
  },
  {
    title: 'Mortgage',
    children: [
      {
        label: 'Mortgage',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'mortgage',
      },
      {
        label: 'Term of the Loan',
        type: 'date',
        prefix: '',
        suffix: '',
        key: 'term_of_mortgage',
      },
      // {
      //     label: 'Interest Rate',
      //     prefix: '',
      //     suffix: "%",
      //     key: "rate_of_mortgage_prcentage"
      //   },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'mortgage_total_principle_and_interest',
      },
    ],
  },
  {
    title: 'Investment Analysis',
    children: [
      {
        label: 'Total Cash Invested',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'total_cash_invested',
      },
      {
        label: 'Total Cash Returned',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'total_cash_returned',
      },
      {
        label: 'Return on Investment (before taxes)',
        type: 'number',
        prefix: '',
        suffix: '%',
        key: 'return_on_investment_before_taxes',
      },
      {
        label: 'Rent',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'return_on_investment_before_taxes',
      },
      {
        label: 'Cash-on-Cash Return (before taxes)',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'cash_on_cash_return_before_taxes',
      },
      {
        label: 'Internal Rate of Return (before taxes)',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'internal_ate_of_return_before_taxes',
      },
    ],
  },

  {
    title: 'Monthly Cash Flow Statement',
    children: [
      {
        label: 'Adjusted Rental Income',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'additional_rental_income',
      },
      {
        label: 'PITI',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'piti',
      },
      {
        label: 'Total Monthly Expenses',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'total_monthly_expenses',
      },
      {
        label: 'Monthly Net Cash Flow',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'monthly_net_cash_flow',
      },
    ],
  },

  {
    title: 'Sale',
    children: [
      {
        label: 'Net Selling Price',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'more_rei_net_selling_price',
      },
      {
        label: 'Gain (loss) on Sale',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'more_rei_gain',
      },
    ],
  },
];

export default data;
