import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const WriteCheck = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>How to Write a Check</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/write-check.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigword}>
                      Finance Explained
                      <span> | </span>
                      Write a Check
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/pay-to-the-order.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          A check is a written, dated and signed agreement that
                          instructs your bank to pay a specific amount of money
                          from your checking account to a person, company,
                          organization or any type of entity. Checks allow two
                          or more parties to transact without physically using
                          cash.
                        </p>
                        <p className={classes.smallText}>
                          Understanding how to write a check is crucial to your
                          financial life. Some businesses and landlords still
                          require checks as a form of payment. Review the
                          graphic below and discover the components of a check.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <div className={classes.galleryImage}>
                          <img
                            className={classes.checkImage}
                            src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/check-v2.png"
                            alt=""
                          />
                        </div>
                        <ol className={classes.writeCheck}>
                          <li>
                            <strong>Check number:</strong> a reference number
                            that allows you to track your payments by writing
                            down the check number in a check register with the
                            date, description, and amount paid.
                          </li>
                          <li>
                            <strong>Today&apos;s date:</strong> helps you keep
                            accurate records of your previous checks and tells
                            the bank when funds can be paid out.
                          </li>
                          <li>
                            <strong>Person or entity you are paying:</strong>{' '}
                            write the name of the person or entity you are
                            paying next to where it says, &quot;PAY TO THE ORDER
                            OF&quot;
                          </li>
                          <li>
                            <strong>Amount written in numbers:</strong> write
                            the amount you are paying in numbers in the box next
                            to the &quot;$&quot; as close to the left side of
                            the box as possible. Why? To prevent someone from
                            changing the dollar amount by adding another number.
                          </li>
                          <li>
                            <strong>Amount written in words:</strong> write the
                            amount you are paying in words starting as close to
                            the left of the line as possible to avoid fraud. If
                            there&apos;s extra space, draw a line after so that
                            no one has space to change the amount you wrote.
                          </li>
                          <li>
                            <strong>Reason for check:</strong> although this
                            field is not required, it&apos;s good practice for
                            your records. And, the person or entity you are
                            paying may require you to write an invoice or
                            account number.
                          </li>
                          <li>
                            <strong>Your signature:</strong> sign your name in
                            the bottom right to make the check valid. Make sure
                            you use the same name and signature you have on file
                            with your bank.
                          </li>
                          <li>
                            <strong>Your bank&apos;s routing number:</strong>{' '}
                            think of this number as your bank&apos;s address.
                            Other banks use this number to contact your bank and
                            make the transaction. It&apos;s usually the first
                            set of numbers at the bottom of the check.
                          </li>
                          <li>
                            <strong>Your personal bank account number:</strong>{' '}
                            a specific number that identifies your personal
                            account. It&apos;s usually the second set of numbers
                            at the bottom of the check.
                          </li>
                        </ol>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
WriteCheck.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WriteCheck);
