import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const FederalIncomeTaxes2021 = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>2021 Federal Income Taxes</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/2021-fed-in-tax.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      2021 Federal Income Taxes
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/2021-fed-inc-tax-187px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The IRS just released the updated federal income tax
                          brackets for 2021. Federal income tax brackets
                          determine how much you pay in taxes to the government
                          in any given year. Typically, each year, these tax
                          brackets are adjusted for inflation.
                        </p>
                        <p>
                          Use Inkwiry&apos;s{' '}
                          <Link to="/financial-calculators/federal-income-tax">
                            Federal Income Tax Calculator
                          </Link>{' '}
                          to see how much you might pay in taxes for 2021 versus
                          2020. While this tax calculator doesn&apos;t take into
                          account all the different scenarios that could affect
                          your taxes, it gives you a good estimate.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          If you are single, the 2021 federal income tax
                          brackets are as follows:
                        </p>
                        <Table className={classes.federalTable}>
                          <thead>
                            <tr>
                              <th>Rate</th>
                              <th colSpan="2">Income Ranges (Single)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>10.00%</td>
                              <td>$0.00</td>
                              <td>$9,950.00</td>
                            </tr>
                            <tr>
                              <td>12.00%</td>
                              <td>$9,950.00</td>
                              <td>$40,525.00</td>
                            </tr>
                            <tr>
                              <td>22.00%</td>
                              <td>$40,525.00</td>
                              <td>$86,375.00</td>
                            </tr>
                            <tr>
                              <td>24.00%</td>
                              <td>$86,375.00</td>
                              <td>$164,925.00</td>
                            </tr>
                            <tr>
                              <td>32.00%</td>
                              <td>$164,925.00</td>
                              <td>$209,425.00</td>
                            </tr>
                            <tr>
                              <td>35.00%</td>
                              <td>$209,425.00</td>
                              <td>$523,600.00</td>
                            </tr>
                            <tr>
                              <td>37.00%</td>
                              <td>$523,600.00</td>
                              <td />
                            </tr>
                          </tbody>
                        </Table>
                        <p>If you are married, the brackets change:</p>
                        <Table className={classes.federalTable}>
                          <thead>
                            <tr>
                              <th>Rate</th>
                              <th colSpan="2">Income Ranges (Married)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>10.00%</td>
                              <td>$0.00</td>
                              <td>$19,900.00</td>
                            </tr>
                            <tr>
                              <td>12.00%</td>
                              <td>$19,900.00</td>
                              <td>$81,050.00</td>
                            </tr>
                            <tr>
                              <td>22.00%</td>
                              <td>$80,050.00</td>
                              <td>$172,750.00</td>
                            </tr>
                            <tr>
                              <td>24.00%</td>
                              <td>$172,750.00</td>
                              <td>$329,850.00</td>
                            </tr>
                            <tr>
                              <td>32.00%</td>
                              <td>$329,850.00</td>
                              <td>$418,850.00</td>
                            </tr>
                            <tr>
                              <td>35.00%</td>
                              <td>$418,850.00</td>
                              <td>$628,300.00</td>
                            </tr>
                            <tr>
                              <td>37.00%</td>
                              <td>$628,300.00</td>
                              <td />
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          The{' '}
                          <Link
                            to="/finance-explained/personal-income-taxes"
                            target="_blank">
                            standard deduction
                          </Link>
                          , which is a portion of your income that is not
                          subject to federal income taxes, has also been updated
                          for 2021:
                        </p>
                        <ul>
                          <li>If single, increased from $12,400 to $12,500</li>
                          <li>If married, increased from $24,800 to $25,100</li>
                        </ul>
                        <p>
                          How do the brackets actually work? Check out this
                          example of a single $75,000 earner filing their taxes
                          for 2021.
                        </p>
                        <div className={classes.pfImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/2021-Income-Tax-Brackets.png"
                            alt="template"
                          />
                        </div>
                        <p>
                          As you can see in this example, your income is not
                          taxed at one rate. The US tax system is a{' '}
                          <a
                            href="https://taxfoundation.org/tax-basics/progressive-tax/"
                            target="_blank"
                            rel="noopener noreferrer">
                            progressive tax
                          </a>
                          , which means higher tax rates for higher income
                          earners and vice versa.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FederalIncomeTaxes2021.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FederalIncomeTaxes2021);
