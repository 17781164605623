import quotesWhite from '../../assets/img/students-parents/quotes_img_white.png';
import quotesBlack from '../../assets/img/students-parents/quotes_img_black.png';
import tickMark from '../../assets/img/students-parents/tick_mark.png';
import tickMarkWhite from '../../assets/img/students-parents/tick_mark_white.png';
import powerupliteCheck from '../../assets/img/students-parents/poweruplite_sales_check.png';
import parentsBanner from '../../assets/img/parents/parents-new-slider.png';
import blueGreenImg from '../../assets/img/parents/blue_green_bg.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  parentstBanner: {
    position: 'relative',
    lineHeight: '0px',
    '& img': {
      width: '100%',
    },
  },
  bannerCaption: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    '& h3': {
      fontSize: '54px',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      width: '100%',      
      '@media (max-width: 767px)': {
        fontSize: '45px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
      },
    },
  },
  bannerCaptionTitle: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: '9',
    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1',
      background: 'rgba(51, 51, 51, 0.2)',
    },
  },
  bannerBgtransparent: {
    '&:before': {
      background: 'none',
    },
  },
  bannerTitleNew: {
    padding: '0px 20px',
  },
  bannerCaptionTitleFlex: {
    width: '100%',
    '& p': {
      fontSize: '48px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-100"',
      textAlign: 'center',
      lineHeight: '75px',
      maxWidth: '830px',
      margin: '0px auto',
      padding: '0px 20px',
      fontWeight: '500',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
        lineHeight: '46px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '26px',
      },
    },
  },
  parentstContent: {
    padding: '60px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 0px',
    },
  },
  parentstContentRow: {
    padding: '0 40px 0 60px',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 0px 0 0px',
    },
    '& h3': {
      fontSize: '44px',
      padding: '0 0 28px 0',
      fontFamily: '"MuseoSans-100"',
      lineHeight: '48px',
      color: theme.palette.common.blue,
      textAlign: 'center',
      '@media (max-width: 767px)': {
        fontSize: '30px',
        lineHeight: '35px',
        padding: '0 0 15px 0',
      },
    },
    '& h4': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '30px',
      lineHeight: '40px',
      padding: '0 0 55px 0',
      textAlign: 'center',
      color: theme.palette.common.blue,
      '@media (max-width: 767px)': {
        fontSize: '22px',
        padding: '0 0 20px 0',
        lineHeight: '30px',
      },
    },
    '& p': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '24px',
      lineHeight: '36px',
      padding: '0 0 27px 0',
      maxWidth: '950px',
      margin: '0px auto',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
        lineHeight: '30px', 
        padding: '0 0 15px 0',     
      },
      '@media (max-width: 767px)': {
        fontSize: '16px',
        lineHeight: '23px',       
      },
    },
  },
  titleNew: {
    '& h3': {
      color: theme.palette.common.blue,
    },
    '& h4': {
      color: theme.palette.common.blue,
      fontSize: '32px',
      lineHeight: '43px',
      paddingTop: '0',
      paddingBottom: '25px',
      '@media (max-width: 767px)': {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
  },
  spanBlock: {
    display: 'block',
    [theme.breakpoints.down( 'md' )]: {
      display: 'initial',
    },
  },
  italic: {
    fontStyle: 'italic',
  },
  parantMoreInfo: {
    margin: '40px auto 0 auto',
    display: 'flex',
    textAlign: 'center',
    maxWidth: '950px',
    '@media (max-width: 992px)': {
      justifyContent: 'center',
    },
    '@media (max-width: 767px)': {
      display: 'flex',
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      margin: '20px auto 0 auto',
    },
  },
  testDriveBtn: {
    fontSize: '36px',
    lineHeight: '36px',
    borderRadius: '5px',
    padding: '18px 40px',
    background: theme.palette.common.greenlight,
    color: theme.palette.common.white,
    border: '2px solid #84a84d',
    display: 'inline-block',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    cursor: 'pointer',
    width: 'calc(50% - 80px)',
    marginRight: '80px',
    textTransform: 'uppercase',
    minWidth: '470px',
    [theme.breakpoints.down( 'md' )]: {
      padding: '18px 30px',
      width: 'calc(50% - 0px)',
      marginRight: '20px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '16px',
      lineHeight: '20px',
      padding: '10px 20px',
    },
    '@media (max-width: 992px)': {
      minWidth: 'auto',
      width: 'auto',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '20px',
      padding: '10px 20px',
      display: 'block',     
      width: '80%',     
      minWidth: 'auto',
      margin:'0px auto 20px auto',
    },
    '&:hover': {
      backgroundColor: '#f89b22',
      borderColor: '#f89b22',
      border: '2px solid #d47c0a !important',
    },
    '& i': {
      marginLeft: '20px',
      fontSize: '28px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '14px',
      },
    },
  },
  moreInfoBtn: {
    fontSize: '36px',
    lineHeight: '36px',
    borderRadius: '5px',
    padding: '18px 40px',
    border: '2px solid #84a84d',
    display: 'inline-block',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    color: theme.palette.common.greenlight,
    cursor: 'pointer',
    width: 'calc(50% - 60px)',
    marginLeft: '20px',
    textTransform: 'uppercase',
    [theme.breakpoints.down( 'md' )]: {
      padding: '18px 30px',
      width: 'calc(50% - 30px)',
    },
    '@media (max-width: 992px)': {
      minWidth: 'auto',
      width: 'auto',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '16px',
      lineHeight: '20px',
      padding: '10px 20px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '20px',
      padding: '10px 20px',
      display: 'block',
      width: '80%',     
      minWidth: 'auto',
      margin:'0px auto 20px auto',
    },

    '&:hover': {
      background: theme.palette.common.greenlight,
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
    '& i': {
      marginLeft: '20px',
      fontSize: '28px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '14px',
      },
    },
  },
  studentsPageReadmore: {
    display: 'none',
  },
  readmoreLftCnt: {
    textAlign: 'center',
    padding: '50px 0',
    background: theme.palette.common.white,
    position: 'relative',
    marginRight: '-30px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '50px 0 0px',
      marginRight: '0px',
      zIndex: '999',
    },
    '& h4': {
      margin: '0',
      fontSize: '24px',
      lineHeight: '24px',
      padding: '0 0 20px 50px',
      fontFamily: '"MuseoSans-300"',
      textAlign: 'left',
      [theme.breakpoints.down( 'sm' )]: {
        textAlign: 'center',
        padding: '0 0 20px 0px',
      },
    },
    '& ul': {
      paddingBottom: '100px',
      paddingLeft: '0px',
      margin: '0px',
      overflowY: 'auto',
      '& li': {
        display: 'block',
        padding: '0',
        textAlign: 'left',
        position: 'relative',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          minHeight: '94px',
          borderTop: '1px solid transparent',
          borderBottom: '1px solid transparent',
          position: 'relative',
          padding: '0px 5px 0px 40px',
          transition: 'all 0.5s ease 0s',
          MozTransition: 'all 0.5s ease 0s',
          WebkitTransition: 'all 0.5s ease 0s',
          color: theme.palette.common.greenlight,
          '@media screen and (min-width: 992px) and (max-width: 1024px)': {
            padding: '0px 5px 0px 10px',
          },
          cursor: 'pointer',
          '&:hover': {
            background: theme.palette.common.blacklightTwo,
            borderTop: '1px solid #dbdbdb',
            borderBottom: '1px solid #dbdbdb',
            textDecoration: 'none',
          },
          '&:before': {
            position: 'absolute',
            content: "''",
            background: theme.palette.common.darkGray,
            width: '7px',
            height: '100%',
            left: '58px',
            top: '60%',
            zIndex: '1',
            '@media screen and (min-width: 992px) and (max-width: 1024px)': {
                left: '28px',
            },
          },
          '& span': {
            borderRadius: '50%',
            border: '5px solid #8a8a8a',
            background: theme.palette.common.white,
            width: '30px',
            height: '30px',
            display: 'inline-block',
            margin: '0 20px 0 0',
            verticalAlign: 'middle',
            position: 'relative',
            zIndex: '2',
            '&:before': {
              position: 'absolute',
              top: '8px',
              left: '10px',
              content: '""',
              borderTop: '7px solid transparent',
              borderBottom: '7px solid transparent',
              borderLeft: '14px solid #8a8a8a',
            },
          },
          '& p': {
            display: 'inline-block',
            verticalAlign: 'middle',
            width: 'calc(100% - 110px)',
            fontSize: '18px',
            lineHeight: '24px',
            margin: '0px',
            color: theme.palette.common.black,
          },
        },
      },
    },
  },
  readmoreLftCntFixed: {
    position: 'fixed',
    top: '55px',
    width: '27.5%',
    left: '0',
  },
  benefitsContent: {
    padding: '60px 0px',
    [theme.breakpoints.down( 'md' )]: {
      padding: '20px 15px',
    },
  },
  benefitsContentRow: {
    maxWidth: '1100px',
    margin: ' 0 auto',
  },
  benefitsTitle: {
    fontFamily: 'MuseoSans-100',
    fontSize: '48px',
    lineHeight: ' 48px',
    margin: '0',
    padding: '0 0 40px 0',
    textAlign: 'center',
    color: theme.palette.common.blue,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '30px',
      lineHeight: '40px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '24px',
      lineHeight: '30px',
      padding: '10px 0px',
    },
  },
  inkwiryBelieve: {
    '& p': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      lineHeight: '36px',
      margin: '0 auto',
      padding: '0 0 27px 0',
      maxWidth: '675px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '25px',
        padding: '0 0 10px 0',
      },
      '& span': {
        fontFamily: 'MuseoSans-300',
        color: theme.palette.common.green,
      },
    },
  },
  benefitsDefinitionCnt: {
    background: '#d1e4f0',
    padding: '45px 35px',
    borderRadius: '30px',
    textAlign: 'center',
    margin: '0px 8px',
    '@media screen and (min-width: 992px) and (max-width: 1024px)': {
      padding: '45px 22px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '18px 10px',
    },
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '30px',
      lineHeight: '30px',
      margin: '0',
      padding: '0 0 40px 0',
      textAlign: 'center',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '23px',
        padding: '0 10px 15px 10px',
      },
    },
    '& p': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      lineHeight: '36px',
      color: theme.palette.common.blue,
      margin: '0 auto',
      padding: '0 0 27px 0',
      '&:last-child': {
        padding: '0 0 0px 0',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '25px',
      },
      '& span': {
        display: 'block',
      },
    },
  },
  inkwiryWays: {
    background: theme.palette.common.blue,
    padding: '80px 0',
    textAlign: 'left',
    border: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0',
    },
  },
  inkwiryWaysRow: {
    padding: '0 7%',
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '48px',
      lineHeight: '48px',
      color: theme.palette.common.white,
      margin: '0',
      padding: '0 0 45px 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '23px',
        lineHeight: '24px',
        padding: '0 0 20px 0px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 4%',
    },
    '& h3': {
      color: theme.palette.common.white,
      fontFamily: 'MuseoSans-100',
      fontSize: '30px',
      lineHeight: ' 30px',
      margin: '0',
      padding: '0 0 65px 0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '26px',
        padding: '0 0 25px 0',
      },
      [theme.breakpoints.down( 'sm' )]: {
        padding: '0 0 24px 0',
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
    '& ul': {
      padding: '0 0 0 58px',
      margin: '0',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '0 0 0 25px',
      },
      '& li': {
        listStyleType: 'decimal',
        textAlign: 'left',
        color: theme.palette.common.white,
        fontFamily: 'MuseoSans-100',
        fontSize: '24px',
        padding: '0 0 15px 15px',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '18px',
          paddingBottom: '8px',
          paddingLeft: '0px',
        },
        '& p': {
          fontFamily: 'MuseoSans-100',
          fontSize: '24px',
          lineHeight: '36px',
          color: theme.palette.common.white,
          margin: '0 auto',
          padding: '0 0 0px 0',
          maxWidth: 'inherit',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '16px',
            lineHeight: '23px',
          },
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '18px',
            lineHeight: '28px',
          },
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0 0 0 22px',
      },
    },
  },
  superAffordable: {
    padding: '50px 0 55px 0',
  },
  affordableCntnt: {
    maxWidth: '1100px',
    margin: '0 auto',
    textAlign: 'center',
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '48px',
      lineHeight: '48px',
      margin: '0',
      textAlign: 'left',
      padding: '0 0 60px 5%',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0 0 20px 5%',
        fontSize: '23px',
        lineHeight: '25px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '30px',
        padding: '0 0 10px 5%',
      },
    },
    '& ul': {
      padding: ' 0 0 35px 7%',
      display: 'inline-block',
      textAlign: 'left',
      [theme.breakpoints.down( 'xs' )]: {
        marginTop: '0px',
      },
      '& li': {
        fontSize: '24px',
        padding: '0 0 30px 0',
        color: '#000',
        [theme.breakpoints.down( 'sm' )]: {
          padding: '0 0 10px 0',
        },
        '& p': {
          fontFamily: 'MuseoSans-100',
          fontSize: '24px',
          lineHeight: '36px',
          margin: '0 auto',
          padding: '0',
          maxWidth: 'inherit',
          textAlign: 'left',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '16px',
            lineHeight: '23px',
          },
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '18px',
            lineHeight: '28px',
          },
          '& b': {
            fontFamily: 'MuseoSans-300',
            fontWeight: 'normal',
            paddingRight: '5px',
          },
        },
      },
    },
  },
  joinInkwiryBtn: {
    fontSize: '36px',
    lineHeight: '36px',
    borderRadius: '5px',
    padding: '18px 60px',
    border: '2px solid #84a84d',
    display: 'inline-block',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    background: theme.palette.common.greenlight,
    color: theme.palette.common.white,
    cursor: 'pointer',
    marginLeft: '20px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '20px',
      padding: '10px 30px',
      display: 'inline-block',
      margin: '0px auto',
      width: 'auto',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '16px',
      lineHeight: '20px',
      padding: '10px 20px',
    },
    '&:hover': {
      backgroundColor: '#799e41',
      textDecoration: 'none',
      color: theme.palette.common.white,
    },
    '& i': {
      marginLeft: '17px',
      fontSize: '28px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '14px',
      },
    },
  },
  ypContent: {
    padding: '60px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 0px',
    },
  },
  ypContentRow: {
    padding: '0 40px 0 60px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 20px 0 20px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    },
    textAlign: 'center',
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '44px',
      lineHeight: '48px',
      margin: '0',
      padding: '0 0 28px 0',
      textAlign: 'center',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '29px',
        lineHeight: '35px',
      },      
    },
    '& h3': {
      fontFamily: 'MuseoSans-100',
      fontSize: '30px',
      lineHeight: '30px',
      margin: '0',
      padding: '0 0 55px 0',
      textAlign: 'center',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        paddingBottom: '23px',
      },
    },
    '& p': {
      fontFamily: 'MuseoSans-100',
      fontSize: '24px',
      lineHeight: '36px',
      margin: '0 auto',
      padding: '0 0 27px 0',
      textAlign: 'left',
      maxWidth: '950px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
    parantMoreInfo: {
      maxWidth: '950px',
    },
  },
  studentsPageReadmoreOpen: {
    display: 'block',
    borderTop: '83px solid #0069aa',
    position: 'relative',
    '& > div > div': {
      '&:first-child': {
        maxWidth: '22.5%',
        flexBasis: '22.5%',
        [theme.breakpoints.down( 'sm' )]: {
          maxWidth: '100%',
          flexBasis: '100%',
        },
      },
    },
  },
  parentsReadmore: {
    position: 'relative',
    '& > div > div': {
      '&:nth-child(1)': {
        '& > div': {
          position: 'sticky',
          top: '49px',
          left: '0',
        },
      },
    },
  },
  studentBottomFixed: {
    '& > div > div': {
      '&:nth-child(1)': {
        '& > div': {
          top: '-15px',
          paddingBottom: '0',
          overflow: 'hidden',
          '& ul': {

          },
        },
      },
    },
  },
  inkwiryListTab: {
    paddingBottom: '50px',
    paddingLeft: '0px',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
    '& li': {
      display: 'block',
      padding: '0',
      textAlign: 'left',
      position: 'relative',
      '& a': {
        display: 'flex',
        alignItems: 'center',
        minHeight: '94px',
        borderTop: '1px solid transparent',
        borderBottom: '1px solid transparent',
        position: 'relative',
        padding: '10px 40px 10px 40px',
        transition: 'all 0.5s ease 0s',
        MozTransition: 'all 0.5s ease 0s',
        WebkitTransition: 'all 0.5s ease 0s',
        color: theme.palette.common.greenlight,
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.common.blacklightTwo,
          borderTop: '1px solid #dbdbdb',
          borderBottom: '1px solid #dbdbdb',
          textDecoration: 'none',
        },
        '&:before': {
          position: 'absolute',
          content: "''",
          background: theme.palette.common.darkGray,
          width: '7px',
          height: '60%',
          left: '62px',
          top: '70%',
          zIndex: '1',
        },
        '& span': {
          borderRadius: '50%',
          border: '5px solid #8a8a8a',
          background: theme.palette.common.white,
          width: '40px',
          height: '40px',
          display: 'inline-block',
          margin: '0 20px 0 0',
          verticalAlign: 'middle',
          position: 'relative',
          zIndex: '2',
          '&:before': {
            position: 'absolute',
            top: '13px',
            left: '14px',
            content: '""',
            borderTop: '7px solid transparent',
            borderBottom: '7px solid transparent',
            borderLeft: '14px solid #8a8a8a',
          },
        },
        '& p': {
          display: 'inline-block',
          verticalAlign: 'middle',
          width: '70%',
          fontSize: '18px',
          lineHeight: '24px',
          margin: '0px',
          color: theme.palette.common.black,
        },
      },
      '&:last-child': {
        '& a': {
          '&:before': {
            height: '0',
          },
        },
      },
    },
  },
  activeClass: {
    background: '#e7e8e8',
    borderTop: '1px solid #dbdbdb',
    borderBottom: '1px solid #dbdbdb',
  },
  unActiveClass: {
    background: '#fffff',
  },
  selectDiv: {
    display: 'none',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      maxWidth: '500px',
      margin: '0px auto',
      [theme.breakpoints.down( 'xs' )]: {
        width: '85%',
        '& > div > div > div': {
          padding: '5px 20px',
          marginRight: '25px',
          background: 'transparent',
          '&:focus':{
            backgroundColor: 'transparent',
          }
        },
      },
      '& div': {
        display: 'block',
        background: theme.palette.common.graylight,
        padding: '4px 0px',
        [theme.breakpoints.down( 'sm' )]: {
          width: 'auto',
        },
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& svg': {
          right: '20px',
        },
      },
    },
  },
  selectDivFixed:{
    [theme.breakpoints.down( 'sm' )]: {
      position: 'fixed',
      top: '65px',
      width: 'calc(100% - 40px)',
      maxWidth: 'initial',
      background: '#fff',
      padding: '10px 20px',
    }
  },
  readmoreRightCnt: {
    borderLeft: '2px solid #8a8a8a',
    width: '98%',
    margin: '0px auto',
    background: theme.palette.common.white,
    position: 'relative',
    zIndex: '9',
    height: '100%',
    [theme.breakpoints.down( 'md' )]: {
      width: '95%',
    },
    [theme.breakpoints.down( 'sm' )]: {
      borderLeft: '0px solid #8a8a8a',
      marginRight: '0',
      width: '100%',
    },
  },
  readmoreRightCntSec: {
    padding: '40px 0 20px 0',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0 30px 0',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0 20px 0 !important',
    },
  },
  readmoreRightCntSecNew: {
    padding: '30px 0 20px 0',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 0 30px 0',
    },
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px 0 20px 0',
    },
  },
  whereStartCnt: {
    padding: '0 40px 0 70px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 20px 0px 20px',
    },
    '& h4': {
      color: theme.palette.common.blue,
      display: 'table',
      margin: '0 auto',
      width: '100%',
      maxWidth: '1010px',
      padding: '0 0 45px 0',
      fontFamily: '"MuseoSans-100"',
      fontSize: '48px',
      lineHeight: '48px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '40px',
        padding: '0 0 30px 0',
      },
    },
    '& p': {
      maxWidth: '1010px',
      display: 'table',
      margin: '0 auto',
      width: '100%',
      fontFamily: '"MuseoSans-100"',
      fontSize: '24px',
      lineHeight: '36px',
      padding: '0 0 38px 0',
      color: theme.palette.common.black,
      '@media (max-width: 992px)': {
        fontSize: '20px',
        lineHeight: '30px',  
        padding: '0 0 20px 0px',      
      },
      '@media (max-width: 767px)': {
        fontSize: '18px',
        lineHeight: '26px',
        paddingBottom: '15px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
        padding: '0 0 20px 0',
      },
      '&:last-child': {
        paddingBottom: '0px',
      },
    },
    '& h6': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '30px',
      lineHeight: '30px',
      padding: '0 0 55px 0',
      color: theme.palette.common.black,
      maxWidth: '1010px',
      margin: '0px auto',
      '@media (max-width: 992px)': {
        padding: '0 0 25px 0',
        fontSize: '27px',
      },
      '@media (max-width: 767px)': {
        fontSize: '25px',
        paddingBottom: '5px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '25px',
        padding: '0 0 30px 0',
      },
    },
    '& h5': {
      padding: '0 0 32px 0',
      lineHeight: '46px',
      fontSize: '30px',
      color: theme.palette.common.black,
      margin: '0 auto',
      width: '100%',
      maxWidth: '1010px',
      fontFamily: '"MuseoSans-100"',
      '@media (max-width: 767px)': {
        fontSize: '24px',
        lineHeight: '33px',
      },
    },
  },
  titleFontsize: {
    '& h4': {
      fontSize: '47px',
      lineHeight: '55px',
      paddingBottom: '28px',     
      '@media (max-width: 1024px)': {
        fontSize: '40px',
        lineHeight: '45px',
      },      
      '@media (max-width: 767px)': {
        fontSize: '28px',
        lineHeight: '40px',
        padding: '0 0 25px 0',
      }, 
      '@media (max-width: 400px)': {
        fontSize: '22px',
        lineHeight: '30px',
        paddingBottom: '20px',
      },    
    },
  },
  FinanceCnt: {
    '& h4': {
      fontSize: '44px',
      padding: '0 0 20px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '29px',
        lineHeight: '40px',
        padding: '0 0 30px 0',
      },
    },
  },
  schoolRoleCnt: {
    '& h4': {
      fontSize: '44px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '29px',
        lineHeight: '40px',
        padding: '0 0 30px 0',
      },
    },
  },
  usStudentsLiteracy: {
    padding: '0 40px 0 70px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 20px 0px 20px',
    },
    '& h4': {
      color: theme.palette.common.blue,
      display: 'table',
      margin: '0 auto',
      width: '100%',
      maxWidth: '920px',
      padding: '0 0 20px 0',
      fontFamily: '"MuseoSans-100"',
      fontSize: '44px',
      lineHeight: '55px',
      '@media (max-width: 767px)': {
        fontSize: '33px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '40px',
        padding: '0 0 30px 0',
      },
    },
    '& h5': {
      fontSize: '36px',
      lineHeight: '40px',
      padding: '0 0 27px 0',
      color: theme.palette.common.blue,
      fontWeight: '700',
      maxWidth: '920px',
      margin: '0px auto',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
        lineHeight: '36px',
      },
    },
    '& p': {
      maxWidth: '920px',
      display: 'table',
      margin: '0 auto',
      width: '100%',
      fontFamily: '"MuseoSans-100"',
      fontSize: '24px',
      lineHeight: '36px',
      padding: '0 0 27px 0',
      color: theme.palette.common.black,
      '&:last-child': {
        paddingBottom: '0px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
        lineHeight: '30px',
        padding: '0 0 20px 0',
      },      
      '@media (max-width: 767px)': {
        fontSize: '18px',       
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',   
      },
      '& span': {
        fontWeight: '600',
      },
      '& a': {
        color: '#337ab7',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline',
          color: '#f89b22',
        },
      },
    },
    '& ul': {
      color: theme.palette.common.black,
      maxWidth: '920px',
      margin: '0px auto',
      '& li': {
        listStyle: 'disc',
        fontFamily: '"MuseoSans-100"',
        fontSize: '24px',
        lineHeight: '36px',
        padding: '0 0 15px 0',
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '18px',
          lineHeight: '25px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
          lineHeight: '22px',
        },
        '& span': {
          fontWeight: '700',
        },
      },
    },
    '& img': {
      width: '100%',
      marginBottom: '20px',
    },
  },
  sketchinImg: {
    '& img': {
      marginBottom: '60px',
      marginTop: '40px',
    },
  },
  studentsGraduateCnt: {
    padding: '0 0px 0 0px',
  },
  joinBtn: {
    textAlign: 'center',
    margin: '20px 0 80px 0',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '0px 0 80px 0',
    },
    '& button': {
      fontSize: '36px',
      lineHeight: '36px',
      borderRadius: '5px',
      padding: '18px 60px',
      display: 'inline-block',
      background: theme.palette.common.greenlight,
      color: theme.palette.common.white,
      transition: 'all 0.5s ease 0s',
      MozTransition: 'all 0.5s ease 0s',
      WebkitTransition: 'all 0.5s ease 0s',
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.common.greenDark,
        textDecoration: 'none',
      },
      '@media (max-width: 992px)': {
        padding: '10px 40px',
        fontSize: '25px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        padding: '10px 40px',
      },
    },
    '& i': {
      marginLeft: '20px',
      fontSize: '28px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      },
    },
  },
  joinBtnNew: {
    margin: '60px 0 80px 0',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '30px 0 80px 0',
    },
    '& a': {
      padding: '18px 25px',
      fontSize: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        padding: '10px 40px',
      },
    },
  },
  testimonialSec: {
    background: theme.palette.common.blue,
    padding: '66px 74px',
    marginTop: '30px',
    '@media (max-width: 768px)': {
      padding: '66px 20px',
    },
    '& h4': {
      fontSize: '38px',
      lineHeight: '38px',
      color: theme.palette.common.white,
      padding: '0 0 40px 0',
      textAlign: 'center',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '28px',
        lineHeight: '35px',
        padding: '0 0 20px 0',
      },
    },
    '& h5': {
      position: 'relative',
      fontFamily: '"MuseoSans-100"',
      maxWidth: '1010px',
      margin: '0px auto',
      '& span': {
        backgroundImage: `url(${quotesWhite})`,
        width: '128px',
        height: '100px',
        left: '10px',
        top: '6px',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '128px',
        '@media (max-width: 992px)': {
          backgroundSize: '100px',
        },
        '@media (max-width: 767px)': {
          backgroundSize: '80px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '22px',
          lineHeight: '30px',
          padding: '0 0 20px 0',
          backgroundSize: '70px',
          width: '70px',
          height: '66px',
        },
      },
      '& p': {
        fontSize: '30px',
        lineHeight: '36px',
        padding: '0 0 0 160px',
        color: theme.palette.common.white,
        '@media (max-width: 767px)': {
          padding: '0 0 0 130px',
          fontSize: '25px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '18px',
          lineHeight: '26px',
          padding: '0 0 0 100px',
        },
      },
      '& h6': {
        textAlign: 'right',
        padding: '0px 0 0 0',
        fontSize: '21px',
        lineHeight: '24px',
        fontStyle: 'italic',
        color: theme.palette.common.white,
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '18px',
          lineHeight: '26px',
        },
        '& span': {
          display: 'block',
          position: 'initial',
          width: '100%',
          backgroundImage: 'none',
          height: 'auto',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '18px',
            lineHeight: '26px',
            display: 'initial',
          },
        },
      },
    },
  },
  testimonialSecDark: {
    background: theme.palette.common.graylight,
    padding: '66px 74px',
    marginTop: '30px',
    '@media (max-width: 1024px)': {
      padding: '66px 30px',
    },
    '@media (max-width: 992px)': {
      padding: '66px 15px',
    },
    '& h5': {
      position: 'relative',
      fontFamily: '"MuseoSans-100"',
      margin: '0px auto',
      maxWidth: '1010px',
      '& span': {
        backgroundImage: `url(${quotesBlack})`,
        width: '128px',
        height: '95px',
        left: '10px',
        top: '6px',
        position: 'absolute',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '128px',
        '@media (max-width: 1024px)': {
          backgroundSize: '105px',
        },
        '@media (max-width: 992px)': {
          backgroundSize: '80px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '22px',
          lineHeight: '30px',
          padding: '0 0 20px 0',
          backgroundSize: '70px',
          width: '70px',
          height: '66px',
        },
      },
      '& p': {
        fontSize: '30px',
        lineHeight: '36px',
        padding: '0 0 0 151px',
        color: '#000',
        margin: '0px',
        '@media (max-width: 992px)': {          
          fontSize: '26px',
          padding: '0 0 0 110px',
        },
        '@media (max-width: 767px)': {       
          fontSize: '24px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '18px',
          lineHeight: '26px',
          padding: '0 0 0 100px',
        },
      },
    },
    '& h6': {
      textAlign: 'right',
      padding: '20px 0 0 0',
      fontSize: '21px',
      lineHeight: '24px',
      fontStyle: 'italic',
      color: theme.palette.common.black,
      margin: '0px auto',
      maxWidth: '1010px',
      '@media (max-width: 767px)': {
        fontSize: '19px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '26px',
        marginLeft: '40px',
        marginTop: '20px',
      },
    },
  },
  imgBanner: {
    marginTop: '44px',
    '& img': {
      width: '100%',
    },
  },
  findSolutions: {
    padding: '50px 60px 55px 60px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '50px 20px 55px 20px',
    },
    '& h3': {
      color: theme.palette.common.blue,
      fontSize: '47px',
      lineHeight: '55px',
      padding: '0 0 28px 0',
      fontFamily: '"MuseoSans-100"',
      maxWidth: '1010px',
      margin: '0px auto',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '40px',
        textAlign: 'center',
      },
    },
    '& h4': {
      padding: '0 0 32px 0',
      lineHeight: '46px',
      fontSize: '30px',
      fontFamily: '"MuseoSans-100"',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '25px',
      },
    },
    '& ul': {
      padding: '0px',
      maxWidth: '1010px',
      margin: 'auto',
      display: 'table',
      '& li': {
        listStyle: 'none',
        padding: '0 0 13px 40px',
        backgroundSize: '16px',
        fontSize: '24px',
        backgroundImage: `url(${tickMark})`,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
          lineHeight: '22px',
        },
        '& span': {
          fontSize: '10px',
          lineHeight: '0',
          paddingLeft: '1px',
          position: 'relative',
          top: '-7px',
        },
      },
    },
  },
  findSolutionsList: {
    padding: '0px 60px 0px 60px',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0px 20px 0px 20px',
    },
    '& h3': {
      color: theme.palette.common.blue,
      fontSize: '47px',
      margin: '0 auto',
      lineHeight: '55px',
      padding: '0 0 28px 0',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '40px',
        textAlign: 'center',
      },
    },
    '& h4': {
      padding: '0 0 32px 0',
      lineHeight: '46px',
      fontSize: '30px',
      fontFamily: '"MuseoSans-100"',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '25px',
      },
    },
    '& ul': {
      maxWidth: '1010px',
      margin: 'auto',
      display: 'table',
      paddingLeft: '100px',
      paddingTop: '10px',
      '@media (max-width: 767px)': {
        paddingLeft: '70px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0px',
      },
      '& li': {
        listStyle: 'none',
        padding: '0 40px 20px 70px',
        backgroundSize: '40px',
        fontSize: '24px',
        backgroundImage: `url(${powerupliteCheck})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0px 0px',
        fontFamily: '"MuseoSans-100"',
        color: theme.palette.common.black,
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
          lineHeight: '22px',
          backgroundSize: '20px',
          padding: '0 20px 20px 45px',
        },
        '& span': {
          fontWeight: '600',
        },
      },
    },
  },
  solutionsUlist: {
    '& ul': {
      padding: '0px',
      maxWidth: '1010px',
      margin: 'auto',
      display: 'table',
      '& li': {
        listStyle: 'none',
        padding: '0 0 13px 40px',
        backgroundSize: '16px',
        fontSize: '24px',
        backgroundImage: `url(${tickMark})`,
        backgroundRepeat: 'no-repeat',
        '@media (max-width: 767px)': {
          padding: '0 0 13px 30px',
          fontSize: '19px',
          listStyle: 'none',
          backgroundSize: '14px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
          lineHeight: '22px',
        },
        '& span': {
          fontSize: '10px',
          lineHeight: '0',
          paddingLeft: '1px',
        },
      },
    },
  },
  findSolutionsBlue: {
    background: theme.palette.common.blue,
    marginTop: '30px',
    '& h3': {
      color: theme.palette.common.white,
    },
    '& h4': {
      color: theme.palette.common.white,
    },
    '& ul': {
      '& li': {
        backgroundImage: `url(${tickMarkWhite})`,
        color: theme.palette.common.white,
      },
    },
  },
  studentGraphSec: {
    padding: '50px 60px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 20px 30px 20px',
    },
    '& h4': {
      padding: '0 0 18px 0',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.blue,
    },
    '& img': {
      maxWidth: '800px',
      width: '100%',
      margin: '0px 30px',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '30px auto',
      },
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '26px',
      maxWidth: '630px',
      ontFamily: '"MuseoSans-300"',
      margin: '0px auto',
      color: theme.palette.common.black,
    },
  },
  studentGraphicsecNew: {
    '& p': {
      maxWidth: '800px',
    },
  },
  padtb0: {
    padding: '0px 60px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 20px !important',
    },
  },
  studentGraphSecTwo: {
    textAlign: 'center',
    padding: '0px 40px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 20px',
    },
    '& img': {
      maxWidth: '500px',
      width: '100%',
      margin: '0px auto',
    },
    '& p': {
      fontSize: '16px',
      lineHeight: '26px',
      maxWidth: '630px',
      fontFamily: '"MuseoSans-300"',
      margin: '0px auto',
      textAlign: 'left',
      color: theme.palette.common.black,
      '& span': {
        display: 'block',
      },
    },
  },
  incomeExpensiveBox: {
    margin: '0 auto',
    maxWidth: '413px',
    marginBottom: '40px',
    padding: '45px 20px',
    background: theme.palette.common.bluelightTwo,
    borderRadius: '30px',
    textAlign: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 20px',
    },
    '& p': {
      fontSize: '36px',
      lineHeight: '48px',
      paddingBottom: '0px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
  },
  InkwiryImgCnt: {
    '& p': {
      maxWidth: '920px',
      display: 'table',
      margin: '0 auto',
      width: '100%',
      fontFamily: '"MuseoSans-100"',
      fontSize: '24px',
      lineHeight: '36px',
      padding: '0 0 38px 0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '20px',
        padding: '0 0 20px 0',
      },
      '& span': {
        fontWeight: '600',
      },
    },
  },
  InkwiryImgCntTwo: {
    padding: '0px 40px',
    textAlign: 'center',
    '& p': {
      marginTop: '0px',
      textAlign: 'center',
      fontSize: '18px',
      lineHeight: '24px',
      maxWidth: '580px',
      margin: '0 auto',
      fontStyle: 'italic',
      padding: '0px 30px',
      [theme.breakpoints.down( 'xs' )]: {       
        padding: '0px 10px',
      },
    },
  },
  arrowTransform: {
    transform: 'rotate(270deg)',
    margin: '30px 0px',
  },
  dashboardImg: {
    width: '100%',
  },
  textSup: {
    position: 'relative',
    top: '-8px',
    fontSize: '10px',
  },
  textBold: {
    fontWeight: '600',
  },
  figureImg: {
    maxWidth: '800px',
    margin: '0px auto',
    cursor: 'pointer',
  },
  figureImgTwo: {
    maxWidth: '920px',
  },
  PopupSchool: {
    overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
    },
    '& > div > div': {
      maxWidth: '70%',
      width: '70%',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  popupHeader: {
    padding: '15px',
    textAlign: 'right',
    fontSize: '21px',
    color: '#000',
    borderBottom: '1px solid #e5e5e5',
    '& i': {
      opacity: '0.3',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  popupContent: {
    padding: '15px',
    '& p': {
      textAlign: 'center',
      padding: '0 0 18px 0',
      fontSize: '24px',
      margin: '0',
      color: theme.palette.common.blue,
      fontFamily: 'MuseoSans-100',
    },
    '& img': {
      width: '100%',
    },
  },
  popupFooter: {
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    borderTop: ' 1px solid #e5e5e5',
    '& p': {
      margin: '0',
      maxWidth: '640px',
      fontSize: '16px',
      lineHeight: '26px',
    },
    '& button': {
      margin: '0 0 0 auto',
      background: theme.palette.common.white,
      border: '2px solid #ccc',
      padding: '4px 20px',
      cursor: 'pointer',
      textTransform: 'captilize',
      borderRadius: '7px',
      color: theme.palette.common.black,
      outline: 'none',
      '&:hover': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:focus': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      '& button': {
        margin: '15px auto',
      },
    },

  },
  figureImgFull: {
    margin: '0',
  },
  leftmenuClass: {
    top: '-250px !important',
  },
  schoolLeftmenuClass: {
    top: '-360px !important',
  },
  studentleftMenuClass: {
    top: '-36px !important',
  },
  parentleftMenuClass: {
    top: '-36px !important',
    paddingBottom: '0px',
  },
  listSelect: {   
    [theme.breakpoints.down( 'xs' )]: {
      whiteSpace: 'pre-wrap',
    },   
  },
  /* parents new banner css */
  bannerBlock: {
    backgroundImage: `url(${parentsBanner})`,
    backgroundSize: '100% 100%',
    backgroundPosition: 'inherit',
    padding: '21.5% 0',
    position: 'relative',
    backgroundRepeat: 'no-repeat',
    '&:before':{
      background: 'rgba(51, 51, 51, 0.2)',
      position: 'absolute',
      left: '0',
      top: '0',
      content: '""',
      width: '100%',
      height: '100%',
    }
  },
  bannerCaptionNew:{
    position: 'absolute',
    width: '100%',
    top: '34%',
    transform: 'translateY(-34%)',
    MozTransform: 'translateY(-34%)',
    WebkitTransform: 'translateY(-34%)',
    [theme.breakpoints.down( 'sm' )]: {
      top: '28%',
    }
  },
  bannerIntro:{
    background: 'rgba(0,105,170,.73)',
    width: '92%',
    margin: '0 4%',
    padding: '30px 0',
    borderRadius: '10px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '5vw 0',
    },
    '& h3':{
      margin: '0',
      fontSize: '48px',
      fontWeight: 'normal',
      lineHeight: '1.2',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
      }
    },
    '& p':{
      color: theme.palette.common.white,
      textAlign: 'center',
      fontSize: '28.2px',
      margin: '30px 0 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '4vw',
        margin: '2vw 0 0',
        lineHeight: '16px',
      }
    }
  },
  bannerBtn:{
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '& a':{
      fontSize: '24px',
      lineHeight: '24px',
      padding: '16px 40px',
      color: theme.palette.common.white,
      display: 'inline-block',
      border: '2px solid #84a84d',
      borderRadius: '10px',
      fontFamily: '"MuseoSans-300"',
      background: '#84a84d',
      position: 'absolute',
      top: '100px',
      fontWeight: 'normal',
      transition: 'all 0.5s ease 0s',
      MozTransition: 'all 0.5s ease 0s',
      WebkitTransition: 'all 0.5s ease 0s',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '2vw 5vw',
        fontSize: '3vw',
        top: '2vw',
      },
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0.5vw 5vw',
        fontSize: '3vw',
        top: '2vw',
      },
      '@media (min-width: 960px) and (max-width: 1199px)': {
        top: '70px',
      },
      '&:hover':{
        background: '#f69a34',
        borderColor: '#f69a34',
      },
      '& i':{
        marginLeft: '15px',
        [theme.breakpoints.down( 'sm' )]: {
          marginLeft: '10px',
        }
      }
    }
  },
  textBlock:{
    display: 'block',
  },
  choosePathRow:{
    padding: '60px 0',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '40px 0',
    }
  },
  mobileGridLeft:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '25%',
      flexBasis: '25%',
    }
  },
  mobileGridRight:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '75%',
      flexBasis: '75%',
    }
  },
  choosePathLeft:{
    [theme.breakpoints.down( 'sm' )]: {
      height: '100%',
    },
    '& p':{
      textAlign: 'center',
      color: theme.palette.common.blue,
      fontSize: '30px',
      margin: '0px 0px 10px',
      lineHeight: '1.32857143',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '4vw',
      }
    }
  },
  puzzleIcon:{
    '& img':{
      width: '220px',
      paddingLeft: '14px',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
        paddingLeft: '0',
        maxWidth: '220px',
      }
    },
  },
  chooseArrow:{
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      height: '65%',
    },
    '& img':{
      width: '88%',
      marginLeft: '12%',
      position: 'absolute',
      top: '-8px',
      [theme.breakpoints.down( 'sm' )]: {
        top: 'auto',
        bottom: '0px',
      }
    }
  },
  choosePathRight:{
    '& p':{
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      fontSize: '23px',
      lineHeight: '36px',
      marginTop: '10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '4vw',
        marginTop: '5px',
        lineHeight: '6.5vw',
      }
    }
  },
  paraSpace:{
    margin: '40px 0 !important',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '20px 0 !important',
    }
  },
  choosePathImg:{
    paddingTop: '50px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingTop: '20px',
    },
    '& img':{
      width: '100%',
      border: '1px solid #0069aa',
      borderRadius: '10px',
      boxShadow: '4px 4px 10px rgb(25 25 25 / 50%)',
      background: theme.palette.common.blue,
    }
  },
  textBlue:{
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-300"',
    color: theme.palette.common.blue,
  },
  textBlueNew:{
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-100"',
    color: theme.palette.common.blue,
  },
  instantAccessRow:{
    background: '#84a84d',
    color: '#fff',
    padding: '50px 0',
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0',
    }
  },
  instantContent:{
    width: '96%',
    justifyContent: 'center',
    display: 'inline-flex',
    flexWrap: 'wrap',
    marginTop: '0',
    '& h3':{
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      fontSize: '44px',
      margin: '15px 0px 10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
      }
    },
    '& p':{
      fontSize: '29px',
      margin: '10px 0 0',
      color: theme.palette.common.white,
      lineHeight: '1.42857143',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '4vw',
      }
    }
  },
  gridHalf:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '50%',
      flexBasis: '50%',
    }
  },
  textItalic:{
    fontStyle: 'italic',
  },
  parentToolkitRowRight:{
    marginLeft: '-7px',
    paddingRight: '20px',
    paddingLeft: '0',
    '& img':{
      width: '100%',
    }
  },
  parentToolkitRow:{
    backgroundImage: `url(${blueGreenImg})`,
    backgroundColor: '#0054a6',
    backgroundPosition: 'center top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  parentToolkitRowLeft:{
    paddingTop: '60px',
    width: '80%',
    [theme.breakpoints.down( 'sm' )]: {
      paddingTop: '30px',
    }
  },
  parentToolkitRowOne:{
    '& h3':{ 
      fontSize: '38px',
      marginBottom: '30px',
      lineHeight: '50px',
      marginTop: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
      }
    },
    '& p':{
      fontSize: '23px',
      lineHeight: '34px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      }
    }
  },
  downloadToolkit:{
    marginTop: '50px',
    '& h2':{
      fontSize: '32px',
      lineHeight: '42px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-300"',
      marginBottom: '10px',
      marginTop: '0',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
      }
    },
    '& p':{
      color: theme.palette.common.white,
      fontSize: '23px',
      lineHeight: '34px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      margin: '0px 0px 15px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      }
    }
  },
  parentToolkitRowRightNew:{
    marginLeft: '-2px',
    paddingRight: '20px',
    paddingLeft: '0',
    '& img':{
      width: '100%',
    }
  },
  toolkitBtn:{
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: '300px',
    },
    '& button':{
      padding: '0px',
      boxShadow: 'none !important',
      backgroundColor: '#fff !important',
    },
    '& span':{
      fontSize: '24px',
      lineHeight: '24px',
      padding: '16px 40px',
      color: theme.palette.common.white,
      display: 'inline-block',
      border: '2px solid #84a84d',
      borderRadius: '10px',
      fontFamily: '"MuseoSans-300"',
      background: '#84a84d',
      fontWeight: 'normal',
      transition: 'all 0.5s ease 0s',
      MozTransition: 'all 0.5s ease 0s',
      WebkitTransition: 'all 0.5s ease 0s',
      textTransform: 'uppercase',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '2vw 3vw',
        fontSize: '3vw',
        top: '2vw',
      },
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0.5vw 3vw',
        fontSize: '3vw',
        top: '2vw',
      },
      '&:hover':{
        background: '#f69a34',
        borderColor: '#f69a34',
      },
      '& i':{
        marginLeft: '15px',
        [theme.breakpoints.down( 'sm' )]: {
          marginLeft: '10px',
        }
      }
    },
  },
  toolkitBtnSend:{
    marginTop: '35px',
    '& span':{
      fontSize: '16px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
      }
    }
  },
  videoImageRowOne:{
    padding: '70px 0px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0px',
    }
  },
  videoImageRowTwo:{
    width: '95%',
    margin: '70px auto',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '30px auto',
    }
  },
  videoImageLeft:{
    width: '63%',
    maxWidth: '63%',
    flexBasis: '63%',
    '& video':{
      width: '100%',
      marginTop: '30px',
      borderRadius: '10px',
    }
  },
  videoImageRight:{
    width: '32%',
    marginLeft: '5%',
    maxWidth: '32%',
    flexBasis: '32%',
    '& h3':{
      fontSize: '38px',
      marginBottom: '30px',
      lineHeight: '50px',
      marginTop: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
        marginBottom: '20px',
      },
    },
    '& p':{
      color: theme.palette.common.white,
      fontSize: '23px',
      lineHeight: '34px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      }
    }
  },
  videoImageRowThree:{
    width: '97.8%',
    marginRight: '2%',
    padding: '70px 0px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0px',
    }
  },
  titleFull:{
    fontSize: '38px',
    marginBottom: '30px',
    lineHeight: '50px',
    marginTop: '0',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-300"',
    color: theme.palette.common.white,
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '5vw',
      lineHeight: '7vw',
      marginBottom: '20px',
    },
  },
  paraText:{
    color: theme.palette.common.white,
    fontSize: '23px',
    lineHeight: '34px',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-100"',
    margin: '0px 0px 10px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '3vw',
      lineHeight: '5vw',
    }
  },
  videoImageLeftTwo:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '41.666667%',
      flexBasis: '41.666667%',
    }
  },
  textWidth80:{
    width: '78%',
  },
  videoImageRightTwo:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '58.333333%',
      flexBasis: '58.333333%',
    },
    '& img':{
      width: '100%',
    }
  },
  videoImageRowFour:{
    width: '90%',
    margin: '0 auto',
  },
  videoImageLeftFour:{
    width: '54%',
    maxWidth: '54%',
    flexBasis: '54%',
    '& img':{
      width: '100%',
    },
    '& p':{
      fontSize: '14px',
      fontStyle: 'italic',
      lineHeight: '34px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      margin: '0px',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      },
    }
  },
  videoImageRightFour:{
    width: '40%',
    maxWidth: '40%',
    flexBasis: '40%',
    marginLeft: '6%',
    '& h3':{
      fontSize: '38px',
      marginBottom: '30px',
      lineHeight: '50px',
      marginTop: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      paddingTop: '55px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
        marginBottom: '10px',
        paddingTop: '20px',
      },
    },
    '& p':{
      color: theme.palette.common.white,
      fontSize: '23px',
      lineHeight: '34px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      },
    }
  },
  videoImageRowFive:{
    margin: '70px 0',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '30px 0',
    }
  },
  videoImageLeftFive:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '41.666667%',
      flexBasis: '41.666667%',
    },
    '& h3':{
      fontSize: '38px',
      marginBottom: '30px',
      lineHeight: '50px',
      marginTop: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
        marginBottom: '10px',
      },
    },
    '& p':{
      color: theme.palette.common.white,
      fontSize: '23px',
      lineHeight: '34px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      },
    }
  },
  videoImageRightFive:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '58.333333%',
      flexBasis: '58.333333%',
    },
    '& img':{
      width: '100%',
    }
  },
  videoImageRowSix:{
    padding: '0px 0 40px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0px 0 20px',
    },
    '& h3':{
      marginBottom: '40px',
      [theme.breakpoints.down( 'sm' )]: {
        marginBottom: '20px',
      }
    }
  },
  videoImageLeftSix:{
    marginLeft: '.3%',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '58.333333%',
      flexBasis: '58.333333%',
    },
    '& img':{
      width: '100%',
    }
  },
  videoImageRightSix:{
    width: '35%',
    marginLeft: '5%',
    maxWidth: '35%',
    flexBasis: '35%',
    '& p':{
      color: theme.palette.common.white,
      fontSize: '23px',
      lineHeight: '34px',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      },
    }
  },
  parentTestimonial:{
    background: '#84a84d',
    padding: '50px 0px',
  },
  testimonialBlock:{
    background: theme.palette.common.white,
    borderRadius: '10px',
    width: 'calc(65% - 80px)',
    margin: '0px auto',
    position: 'relative',
    padding: '45px 40px 30px',
    display: 'flex',
    minHeight: '200px',
    fontFamily: '"MuseoSans-100"',
    fontWeight: 'normal',
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(90% - 40px)',
      padding: '45px 20px 30px',
      minHeight: 'initial',
    }
  },
  mb50:{
    marginBottom: '40px !important',
    [theme.breakpoints.down( 'sm' )]: {
      marginBottom: '20px !important',
    }
  },
  blockQuote:{
    width: '30px',
    marginRight: '40px',
    [theme.breakpoints.down( 'sm' )]: {
      marginRight: '20px',
      width: '7vw',
    },
    '& img':{
      width: '100%',
    }
  },
  blockQuoteText:{
    width: 'calc(100% - 70px)',
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% - 50px)',
    },
   '& p':{
    fontSize: '23.04px',
    color: '#333',
    margin: '0 0 10px',
    fontFamily: '"MuseoSans-100"',
    fontWeight: 'normal',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '3vw',
      lineHeight: '5vw',
    }
   },
   '& h5':{
    fontFamily: '"MuseoSans-100"',
    fontWeight: 'normal',
    fontSize: '17.28px',
    textAlign: 'right',
    color: '#333',
    fontStyle: 'italic',
    margin: '0px 0px 10px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '3vw',
      lineHeight: '5vw',
    }
   }
  },
  parentPlanHe:{
    margin: '40px 0',
    '& h2':{
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
      fontSize: '50px',
      lineHeight: '60px',
      color: theme.palette.common.blue,
      textAlign: 'center',
      margin: '20px 0px 10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
      lineHeight: '7vw',
      '& span':{
          display: 'initial',
        }
      }
    }
  },
  planList:{
    margin: '50px 0 0px',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '20px 0 0px',
    }
  },
  parentPlanHeBox:{
    background: theme.palette.common.blue,
    color: theme.palette.common.white,
    borderRadius: '10px',
    padding: '20px 10px',
    textAlign: 'center',
    fontSize: '17px',
    lineHeight: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '3vw',
      lineHeight: '5vw',
    },
    '& p':{
      color: theme.palette.common.white,
    }
  },
  mt30:{
    marginTop: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '15px',
    }
  },
  puzzleIconSpace:{
    paddingTop: '350px',
    '& img':{
      width: '195px',
      marginLeft: '17px',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
        marginLeft: '0px',
        maxWidth: '195px',
      }
    },
    '& p':{
      color: theme.palette.common.blue,
      fontSize: '30px',
      textAlign: 'center',
      margin: '0px 0px 10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '4vw',
        lineHeight: '6vw',
      }
    }
  },
  chooseIconSpace:{
    padding: '0px 15px',
    [theme.breakpoints.down( 'sm' )]: {
      position: 'absolute',
      bottom: '0px',
    },
    '& img':{
      width: '65%',
      marginLeft: '8%',
    }
  },
  planListLeft:{
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '30%',
      flexBasis: '30%',
      position: 'relative',
    }
  },
  planHeDesc:{
    paddingLeft: '50px',
    paddingRight: '15px',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '70%',
      flexBasis: '70%',
      paddingLeft: '20px',
      paddingRight: '0px',
    },
    '& p':{
      fontSize: '23px',
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      lineHeight: '36px',
      color: '#333',
      margin: '0px 0px 10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      }
    }
  },
  justSpace:{
    marginTop: '20px !important',
  },
  hePlansList:{
    paddingLeft: '0',
    '& li':{
      marginBottom: '30px',
      fontSize: '23px',
      lineHeight: '36px',
      display: 'flex',
      fontFamily: '"MuseoSans-100"',
      color: '#333',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      }
    }
  },
  listLeft:{
    width: '30px',
    display: 'block',
    marginRight: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      width: '20px',
      marginRight: '10px',
    },
    '& img':{
      width: '100%',
    }
  },
  listRight:{
    width: 'calc(100% - 60px)',
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% - 30px)',
    }
  },
  parentTryInkwiryPlan:{
    width: '70%',
    margin: '0 auto',
    '& h2':{
      fontSize: '50px',
      marginBottom: '20px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      marginTop: '0px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
      }
    },
    '& p':{
      fontSize: '23px',
      fontFamily: '"MuseoSans-100"',
      lineHeight: '36px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      }
    }
  },
  textCenter:{
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: 'auto',
    }
  },
  /* popu box css */
  toolKitPopup:{
    position: 'relative',
    '& > div > div':{
      width: '800px',
      margin: '30px 0',
      maxWidth: 'initial',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
        margin: '30px 20px',
        maxWidth: '800px',
      }
    }
  },
  toolKitPopupHeader:{
    padding: '15px 15px 10px',
    display: 'flex',
    justifyContent: 'end',
    '& > div':{
      color: theme.palette.common.blue,
      fontSize: '26px',
      marginTop: '-2px',
      cursor: 'pointer',
      display: 'inline-block',
      fontFamily: '"MuseoSans-700"',
      '&:hover':{
        opacity: '0.8',
      }
    }
  },
  popupBodyNew:{
    padding: '0 20px 20px',
    position: 'relative',
  },
  popupBluebox:{
    background: theme.palette.common.blue,
    padding: '30px',
    textAlign: 'center',
    borderRadius: '10px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px',
    },
    '& h2':{
      fontSize: '40px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.white,
      marginBottom: '10px',
      lineHeight: '60px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
      }
    },
    '& h3':{
      fontSize: '30px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.white,
      lineHeight: '30px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
        lineHeight: '7vw',
      }
    }
  },
  popupCnt:{
    maxWidth: '650px',
    margin: '50px auto 30px',
  },
  parentsToolkitBlock:{
    display: 'flex',
  },
  parentsToolImg:{
    width: '45%',
    marginRight: '5%',
    '& img':{
      width: '100%',
    }
  },
  parentsToolCnt:{
    width: '50%',
    '& p':{
      fontSize: '22px',
      lineHeight: '32px',
      color: '#333',
      fontFamily: '"MuseoSans-100"',
      margin: '0 0 10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '3vw',
        lineHeight: '5vw',
      },
      '&:last-child':{
        marginTop: '40px',
        [theme.breakpoints.down( 'sm' )]: {
          marginTop: '20px',
        }
      }
    }
  },
  popupPara:{
    fontSize: '16px',
    textAlign: 'center',
    padding: '30px 0',
    lineHeight: '32px',
    color: '#333',
    fontFamily: '"MuseoSans-100"',
    fontWeight: 'normal',
    margin: '0px 0px 20px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '3vw',
      lineHeight: '5vw',
      padding: '10px 0',
    },
  },
  formContact:{
    maxWidth: '350px',
    margin: '0 auto 10px',
    textAlign: 'center',
  }, 
  formIn:{
    '& div':{
      margin: '0px',
    },
    '& fieldset':{
      display: 'none',
    },
    '& input':{
      width: 'calc(100% - 24px)',
      height: 'calc(55px - 12px)',
      marginBottom: '20px',
      borderRadius: '10px',
      border: '1px solid #848181',
      textIndent: '15px',
      fontSize: '16px',
      padding: '6px 12px',
      backgroundColor: '#fff',
      WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      color: '#555',
      '&:focus':{
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
      },
      '&::placeholder':{
        color: '#a39c9c',
      },
      [theme.breakpoints.down( 'sm' )]: {
        height: 'calc(45px - 12px)',
      }
    }
  },
  messagePopup: {
    position: 'relative',
    padding: '0px',
    minWidth: '600px',
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: '100%',
    },
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  popupClose: {
    top: '10px',
    color: '#000',
    float: 'none',
    right: '0',
    width: 'calc(100% - 17px)',
    border: '1px solid #f59331',
    cursor: 'pointer',
    height: '35px',
    opacity: '1',
    outline: 'none',
    zIndex: '99',
    position: 'relative',
    fontSize: '25px',
    background: '#fff',
    textAlign: 'right',
    lineHeight: '26px',
    textShadow: 'none',
    borderColor: '#fff',
    borderRadius: '0',
    borderBottom: '1px solid #ccc',
    left: '0',
    overflow: 'hidden',
    paddingRight: '15px',
  },
  popupBody: {
    padding: '35px',
    textAlign: 'center',
    '& p': {
      fontSize: '22px',
      lineHeight: '30px',
      color: '#000000',
      margin: '0px 0 20px',
    },
  },
  popupRejectCheck:{
    width: '142px',
    height: '142px',
    border: '4px solid red',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: 'red',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid red',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
  popupCheck: {
    width: '142px',
    height: '142px',
    border: '4px solid #008000',
    borderRadius: '50%',
    textAlign: 'center',
    lineHeight: '150px',
    color: '#008000',
    fontWight: '600',
    margin: '0 auto 30px auto',
    display: 'block',
    fontSize: '110px',
    position: 'relative',
    '& i::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '13px',
      left: '60px',
      width: '27px',
      height: '92px',
      border: 'solid #008000',
      borderWidth: '0 4px 4px 0',
      transform: 'rotate(45deg)',
    },
  },
} );

export default styles;
