function BuyingCarBackend( module12Data, carLoan1Data, carLoan2Data ) {
  /** ******************** Investments Output Backend *********************** */
  let year = 0;
  let yearLimit = 0;
  let secondYear = 0;
  let secondYearLimit = 0;
  const buyingaCarData = {};
  const yearsList = [];
  const firstCarLoansBalArr = [];
  const firstPaymentArr = [];
  const firstPrincipalPaybackArr = [];
  const firstInterestArr = [];

  const secondYearsList = [];
  const secondCarLoansBalArr = [];
  const secondPaymentArr = [];
  const secondPrincipalPaybackArr = [];
  const secondInterestArr = [];

  year = parseInt( module12Data.purchase_year, 10 );
  yearLimit = parseInt( module12Data.purchase_year, 10 ) + 15;
  for ( year; year < yearLimit; year += 1 ) {
    buyingaCarData[year] = {};
    buyingaCarData[year].FIRST = {};
    /*
   * First Car Information
   */

    // Car Loan balance Value
    if ( carLoan1Data !== undefined && carLoan1Data['Summation Data'][year] !== undefined && carLoan1Data['Summation Data'][year].BeginningBalance !== undefined ) {
      buyingaCarData[year].FIRST['Car Loan Balance'] = carLoan1Data['Summation Data'][year].BeginningBalance;
    } else {
      buyingaCarData[year].FIRST['Car Loan Balance'] = 0;
    }
    if ( buyingaCarData[year].FIRST['Car Loan Balance'] > 0 ) {
      firstCarLoansBalArr.push( Math.round( buyingaCarData[year].FIRST['Car Loan Balance'] ) );
    }
    // Payment Value
    if ( carLoan1Data !== undefined && carLoan1Data['Summation Data'][year] !== undefined && carLoan1Data['Summation Data'][year].LaggingInterest && carLoan1Data['Summation Data'][year].LaggingPrincipal !== undefined ) {
      buyingaCarData[year].FIRST.Payment = parseFloat( carLoan1Data['Summation Data'][year].LaggingInterest ) + parseFloat( carLoan1Data['Summation Data'][year].LaggingPrincipal );
    } else {
      buyingaCarData[year].FIRST.Payment = 0;
    }
    if ( buyingaCarData[year].FIRST.Payment > 0 ) {
      firstPaymentArr.push( Math.round( buyingaCarData[year].FIRST.Payment ) );
    }
    // Principal payback Value
    if ( carLoan1Data !== undefined && carLoan1Data['Summation Data'][year] !== undefined && carLoan1Data['Summation Data'][year].LaggingPrincipal !== undefined ) {
      buyingaCarData[year].FIRST['Principal Payback'] = carLoan1Data['Summation Data'][year].LaggingPrincipal;
    } else {
      buyingaCarData[year].FIRST['Principal Payback'] = 0;
    }
    if ( buyingaCarData[year].FIRST['Principal Payback'] > 0 ) {
      firstPrincipalPaybackArr.push( Math.round( buyingaCarData[year].FIRST['Principal Payback'] ) );
    }
    // Interest Value
    if ( carLoan1Data !== undefined && carLoan1Data['Summation Data'][year] !== undefined && carLoan1Data['Summation Data'][year].LaggingInterest !== undefined ) {
      buyingaCarData[year].FIRST.Interest = carLoan1Data['Summation Data'][year].LaggingInterest;
    } else {
      buyingaCarData[year].FIRST.Interest = 0;
    }
    if ( buyingaCarData[year].FIRST.Interest > 0 ) {
      firstInterestArr.push( Math.round( buyingaCarData[year].FIRST.Interest ) );
    }

    if ( buyingaCarData[year].FIRST['Car Loan Balance'] > 0 || buyingaCarData[year].FIRST.Payment > 0 || buyingaCarData[year].FIRST['Principal Payback'] > 0 || buyingaCarData[year].FIRST.Interest > 0 ) {
      yearsList.push( year );
    }
  }

  secondYear = parseInt( module12Data.second_purchase_year, 10 );
  secondYearLimit = parseInt( module12Data.second_purchase_year, 10 ) + 15;
  for ( secondYear; secondYear < secondYearLimit; secondYear += 1 ) {
    buyingaCarData[secondYear] = {};
    buyingaCarData[secondYear].SECOND = {};
    /*
     * Second Car Information
     */

    // Car Loan balance Value
    if ( carLoan2Data !== undefined && carLoan2Data['Summation Data'][secondYear] !== undefined && carLoan2Data['Summation Data'][secondYear].BeginningBalance !== undefined ) {
      buyingaCarData[secondYear].SECOND['Car Loan Balance'] = carLoan2Data['Summation Data'][secondYear].BeginningBalance;
    } else {
      buyingaCarData[secondYear].SECOND['Car Loan Balance'] = 0;
    }
    if ( buyingaCarData[secondYear].SECOND['Car Loan Balance'] > 0 ) {
      secondCarLoansBalArr.push( Math.round( buyingaCarData[secondYear].SECOND['Car Loan Balance'] ) );
    }
    // Payment Value
    if ( carLoan2Data !== undefined && carLoan2Data['Summation Data'][secondYear] !== undefined && carLoan2Data['Summation Data'][secondYear].LaggingInterest && carLoan2Data['Summation Data'][secondYear].LaggingPrincipal !== undefined ) {
      buyingaCarData[secondYear].SECOND.Payment = parseFloat( carLoan2Data['Summation Data'][secondYear].LaggingInterest ) + parseFloat( carLoan2Data['Summation Data'][secondYear].LaggingPrincipal );
    } else {
      buyingaCarData[secondYear].SECOND.Payment = 0;
    }
    if ( buyingaCarData[secondYear].SECOND.Payment > 0 ) {
      secondPaymentArr.push( Math.round( buyingaCarData[secondYear].SECOND.Payment ) );
    }

    // Principal payback Value
    if ( carLoan2Data !== undefined && carLoan2Data['Summation Data'][secondYear] !== undefined && carLoan2Data['Summation Data'][secondYear].LaggingPrincipal !== undefined ) {
      buyingaCarData[secondYear].SECOND['Principal Payback'] = carLoan2Data['Summation Data'][secondYear].LaggingPrincipal;
    } else {
      buyingaCarData[secondYear].SECOND['Principal Payback'] = 0;
    }
    if ( buyingaCarData[secondYear].SECOND['Principal Payback'] > 0 ) {
      secondPrincipalPaybackArr.push( Math.round( buyingaCarData[secondYear].SECOND['Principal Payback'] ) );
    }
    // Interest Value
    if ( carLoan2Data !== undefined && carLoan2Data['Summation Data'][secondYear] !== undefined && carLoan2Data['Summation Data'][secondYear].LaggingInterest !== undefined ) {
      buyingaCarData[secondYear].SECOND.Interest = carLoan2Data['Summation Data'][secondYear].LaggingInterest;
    } else {
      buyingaCarData[secondYear].SECOND.Interest = 0;
    }
    if ( buyingaCarData[secondYear].SECOND.Interest > 0 ) {
      secondInterestArr.push( Math.round( buyingaCarData[secondYear].SECOND.Interest ) );
    }
    if ( buyingaCarData[secondYear].SECOND['Car Loan Balance'] > 0 || buyingaCarData[secondYear].SECOND.Payment > 0 || buyingaCarData[secondYear].SECOND['Principal Payback'] > 0 || buyingaCarData[secondYear].SECOND.Interest > 0 ) {
      secondYearsList.push( secondYear );
    }
  }

  // To return Cash Flow Statement Complete Data
  function buyingaCarBackendData() {
    const buyingaCarInfo = {};
    buyingaCarInfo['First Car Bar Graph'] = {};
    buyingaCarInfo['First Car Bar Graph']['Years List'] = yearsList;
    buyingaCarInfo['First Car Bar Graph']['Car Loan balance'] = firstCarLoansBalArr;
    buyingaCarInfo['First Car Bar Graph'].Payment = firstPaymentArr;
    buyingaCarInfo['First Car Bar Graph']['Principal Payback'] = firstPrincipalPaybackArr;
    buyingaCarInfo['First Car Bar Graph'].Interest = firstInterestArr;

    // Second Car Graph
    buyingaCarInfo['Second Car Bar Graph'] = {};
    buyingaCarInfo['Second Car Bar Graph']['Years List'] = secondYearsList;
    buyingaCarInfo['Second Car Bar Graph']['Car Loan balance'] = secondCarLoansBalArr;
    buyingaCarInfo['Second Car Bar Graph'].Payment = secondPaymentArr;
    buyingaCarInfo['Second Car Bar Graph']['Principal Payback'] = secondPrincipalPaybackArr;
    buyingaCarInfo['Second Car Bar Graph'].Interest = secondInterestArr;
    return buyingaCarInfo;
  }
  return buyingaCarBackendData();
}
export default BuyingCarBackend;
