import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-investments/styles';
import InvestmentBottomThree from './Investment-bottomThree';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';

const marks = [
	{
		value: 0,
		scaledValue: 0,
		label: '0%',
	},
	{
		value: 4,
		scaledValue: 4,
		label: '4%',
	},
	{
		value: 8,
		scaledValue: 8,
		label: '8%',
	},
	{
		value: 12,
		scaledValue: 12,
		label: '12%',
	},
	{
		value: 16,
		scaledValue: 16,
		label: '16%',
	},
	{
		value: 20,
		scaledValue: 20,
		label: '20%',
	},
];

const marksTwo = [
	{
		value: 0,
		scaledValue: 0,
		label: '0%',
	},
	{
		value: 2,
		scaledValue: 2,
		label: '2%',
	},
	{
		value: 4,
		scaledValue: 4,
		label: '4%',
	},
	{
		value: 6,
		scaledValue: 6,
		label: '6%',
	},
	{
		value: 8,
		scaledValue: 8,
		label: '8%',
	},
	{
		value: 10,
		scaledValue: 10,
		label: '10%',
	},
];

const PlanInvestments = (props) => {
	const {
		classes, handleDefinationPopup, getInvestmentObject, handleInvestmentData, setOpenWorkSheet
	} = props;
	const [begBalPercentage, setBegBalPercentage] = React.useState(getInvestmentObject.plan_529_annual_contribution_percentage !== undefined ? getInvestmentObject.plan_529_annual_contribution_percentage : 0)
	const [begBalValue, setBegBalValue] = React.useState(0);
	const [isFocus, setIsFocus] = React.useState('');
	const [sliderValue, setSliderValue] = React.useState(getInvestmentObject.plan_529_annual_expected_pre_tax_return !== undefined ? getInvestmentObject.plan_529_annual_expected_pre_tax_return : 7);

    React.useEffect(() => {
        let bignningBalance = getInvestmentObject.long_term_annual_contribution_in_percentage_growth;
        var preamount = modulesServices.afterTaxIncomeInYear529Balance();
        let value = (preamount/100)*bignningBalance;
        let sValue = 0;
        if (value >= 0) {
            sValue = value;
        } else {
            sValue = 0;
        }
        setBegBalValue(sValue/12);
    }, []);

    const updateValue = (e, value, field, type) => {
        const beginingBalanceObj = { ...getInvestmentObject };
        var preamount = modulesServices.afterTaxIncomeInYear529Balance();           
        if (type === 'slider') {
            let bignningBalance = begBalPercentage;
            if (bignningBalance > 0 && preamount > 0) {
                setBegBalValue(((preamount/100)*bignningBalance)/12);                
            } 
            setBegBalPercentage(value);
            beginingBalanceObj['plan_529_annual_contribution_percentage'] = value;
        } else if (type === 'input') {
            let Invalue = e.floatValue ? e.floatValue : 0;
            let bignningBalance = Invalue*12;
            if (bignningBalance > 0 && preamount > 0) {
                setBegBalPercentage((bignningBalance/preamount) * 100);
                beginingBalanceObj['plan_529_annual_contribution_percentage'] = (bignningBalance/preamount) * 100;
            } else {
                setBegBalPercentage(0);
                beginingBalanceObj['plan_529_annual_contribution_percentage'] = 0;
            }
            setBegBalValue(Invalue);            
        }else if(type === 'sliderTwo'){
            setSliderValue(value);
            beginingBalanceObj['plan_529_annual_expected_pre_tax_return'] = value;
        }else if(type === 'inputTwo'){
            let Invalue = e.floatValue ? e.floatValue : 0;
            let ivalue = 0;
            if(Invalue >= 100){
                ivalue =100;
            }else{
                ivalue = Invalue
            }
            setSliderValue(ivalue);
            beginingBalanceObj['plan_529_annual_expected_pre_tax_return'] = ivalue;
        }
        handleInvestmentData(beginingBalanceObj);
    };

	const emptyisFocus = () => {
		setIsFocus('');
	};

	return (
		<div className={classes.contentBlock}>
			<p>
				How much are you contributing to 529 Plan as a percentage of after-tax income?
			</p>
			<p> When you open a 529 Plan, you will have to choose an investing strategy. You have <a href="https://www.savingforcollege.com/intro-to-529s/how-do-i-select-the-right-investments-for-my-529-plan" target="_blank" rel="noreferrer">two options usually:</a>
				<ul>
					<li><b>Age-based portfolio:</b> investments will be automatically adjusted based on your child’s age. Investments will be riskier the younger your child is and get less risky as they get older and closer to higher education.</li>
					<li>
						<b>Target portfolio:</b> the same as making any investment. A portfolio that aims to achieve a return with a unique strategy. This can really vary based on how much risk you want to take. More risk equals higher potential reward.
					</li>
				</ul>
			</p>
			<p>Both are good strategies. How much do you expect to earn on your investments?</p>
			<div className={classes.balanceBox}>
				<Typography variant="h3" component="h3">
					After-Tax Income in year {getInvestmentObject.plan_529_start_year}&nbsp; : {''}
					<span className={modulesServices.module9Data.afterTaxIncomeInYear529Balance < 0 ? classes.dangerText : ''}><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={modulesServices.module9Data.afterTaxIncomeInYear529Balance ? modulesServices.module9Data.afterTaxIncomeInYear529Balance : 0} prefix="$" /></span>					
				</Typography>
			</div>
			<div className={classes.innnerFlowGroupMainFull}>
				<div className={`${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}`}>
					<div className={`${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}`}>
						<div className={`${classes.garphLeft} ${classes.garphLeftScholor}`}>
							<h3>&nbsp;</h3>
							<div className={`${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants} ${classes.annuvalGrowthScholorBT0}`}>
								<div>
									<p><span>Contribution as a % of After-Tax Income</span></p>
									<div className={classes.pwisliderInputs}>
										<PwiSlider
											ValueLabelComponent={ValueLabelComponent}
											valueLabelFormat={percentFormatWithTwoDecimal}
											aria-labelledby="discrete-slider-custom"
											valueLabelDisplay="auto"
											marks={marks}
											min={0}
											max={20}
											step={0.60}
											value={begBalPercentage}
											onChange={(e, value) => { updateValue(e, value, 'plan_529_annual_contribution_percentage', 'slider'); }}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className={`${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}`}>
							<div>
								<h3>Monthly</h3>
								<div className={classes.inputRightBlock}>
									<div className={classes.annualInput}>
										<span className={classes.dollarInput}>$</span>
										<NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={begBalValue} onValueChange={(e) => updateValue(e, '', 'plan_529_annual_contribution', 'input')} />
									</div>
								</div>
							</div>
							<div className={classes.rightBoxNogap}>
								<h3>
									Annual
								</h3>
								<div className={classes.inputRightBlock}>
									<span className={classes.dollarInput}>$</span>
									<NumberFormat decimalScale={0} fixedDecimalScale displayType={'text'} customInput={TextField} thousandSeparator value={begBalValue * 12} />
								</div>
							</div>
						</div>
					</div>
					<div className={classes.innnerFlowGroup}>
						<div className={`${classes.garphLeft} ${classes.garphLeftScholor}`}>
							<div className={`${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}`}>
								<div>
									<p><span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Expected Pre-Tax Return: Investments')}>Annual Expected Pre-Tax Return</span></p>
									<div className={classes.pwisliderInputs}>
										<PwiSlider
											ValueLabelComponent={ValueLabelComponent}
											valueLabelFormat={percentFormatWithTwoDecimal}
											aria-labelledby="discrete-slider-custom"
											valueLabelDisplay="auto"
											marks={marksTwo}
											min={0}
											max={10}
											step={0.1}
											value={sliderValue}
											onChange={(e, value) => { updateValue(e, value, 'plan_529_annual_expected_pre_tax_return', 'sliderTwo'); }}
										/>
									</div>

								</div>
							</div>
						</div>
						<div className={`${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}`}>
							<div>
								<div className={classes.inputRightBlock}>
									<div className={classes.annualInput}>
										<NumberFormat
											customInput={TextField}
											decimalScale={isFocus!=='plan_529_annual_expected_pre_tax_return'?2:5}
											fixedDecimalScale={isFocus!=='plan_529_annual_expected_pre_tax_return'}
											value={sliderValue !== null ? sliderValue : 0}
											onValueChange={(e) => updateValue(e, '', 'plan_529_annual_expected_pre_tax_return', 'inputTwo')}
											onFocus={ (e) => { e.target.select(); setIsFocus('plan_529_annual_expected_pre_tax_return') }}
											onBlur={emptyisFocus}
										/>
										<span className={classes.percentSymbl}>%</span>
									</div>
								</div>
							</div>
							<div className={classes.rightBoxNogap}>
								<div className={classes.inputRightBlock}>
									&nbsp;
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className={classNames(classes.borderDivider, classes.borderDividerMT30)} />
			{getInvestmentObject &&
				<InvestmentBottomThree activeSlide={12} getInvestmentObject={getInvestmentObject} setOpenWorkSheet={setOpenWorkSheet} />
			}
			<ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
		</div>
	);
};

PlanInvestments.propTypes = {
	classes: PropTypes.object.isRequired,
	handleDefinationPopup: PropTypes.func.isRequired,
	handleInvestmentData: PropTypes.func.isRequired,
	getInvestmentObject: PropTypes.object.isRequired,
	setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles(styles)(PlanInvestments);
