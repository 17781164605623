import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import styles from '../../../screens/pwi-fca/styles';
import careerPathBanner from '../../../assets/img/career-path/careerpath.png';

const NotAboutNumbers = ( props ) => {
  const {
    classes,
  } = props;

  return (
    <div>
      <div className={ classes.contentBlock }>
        <p className={ classes.paddingTop5 }>
          Numbers are important, but what&apos;s behind them? What are you planning for? What&apos;s your &quot;why&quot;?

        </p>
        <p className={ classes.paddingTop20 }>
          Before you start to build your career path, pause to consider this important life question and its 10 major supporting questions:

        </p>
        <div className={ classes.careerPathBanner }>
          <img src={ careerPathBanner } alt="" />
        </div>
      </div>
      <div className={ classes.careerPathCircles }>
        <ul className={ classes.quickstartCircle }>
          <li aria-hidden="true" className={ classes.circleOne }>
            <Link to="/">
              <Typography variant="h3" component="h3">1</Typography>
              <Typography variant="h4" component="h4">
                Will my
                <span className={ classes.spanBold }>
                  {' '}
                  career
                  {' '}
                </span>
                <span className={ classes.spanBlock }>support the life </span>
                <span className={ classes.spanBlock }>I want to live?</span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleTwo }>
            <Link to="/">
              <Typography variant="h3" component="h3">6</Typography>
              <Typography variant="h4" component="h4">
                <span className={ classes.spanBlock }>How much can I</span>
                <span className={ classes.spanBold }>
                  {' '}
                  spend
                  {' '}
                </span>
                on my
                {' '}
                <span className={ classes.spanBold }>vacations ?</span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleThree }>
            <Link to="/">
              <Typography variant="h3" component="h3">2</Typography>
              <Typography variant="h4" component="h4">
                <span className={ classes.spanBlock }> How do I acquire</span>
                the
                <span className={ classes.spanBold }>
                  {' '}
                  higher
                  {' '}
                </span>
                <span className={ classes.spanBold }>education </span>
                <span className={ classes.spanBlock }>I need?</span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleFour }>
            <Link to="/">
              <Typography variant="h3" component="h3">7</Typography>
              <Typography variant="h4" component="h4">
                Will I have the time
                <br />
                <span>
                  {' '}
                  and money for
                  {' '}
                </span>
                <span className={ classes.spanBold }>family and</span>
                <span className={ classes.spanBold }> friends?</span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleFive }>
            <Link to="/">
              <Typography variant="h3" component="h3">3</Typography>
              <Typography variant="h4" component="h4">
                <span className={ classes.spanBlock }>Where is the</span>
                <span className={ classes.spanBold }>best place</span>
                <br />
                for me to
                <span className={ classes.spanBold }>
                  {' '}
                  live
                  {' '}
                </span>
                <br />
                and
                {' '}
                <span className={ classes.spanBold }>work?</span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleSix }>
            <Link to="/">
              <Typography variant="h3" component="h3">8</Typography>
              <Typography variant="h4" component="h4">
                Do I save
                <span className={ classes.spanBold }>
                  {' '}
                  money
                  {' '}
                </span>
                <br />
                <span>if I </span>
                <span className={ classes.spanBold }>marry?</span>
                <br />
                <span>
                  is it expensive to
                  <br />
                  have
                </span>
                <span className={ classes.spanBold }>
                  {' '}
                  kids?
                </span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleSeven }>
            <Link to="/">
              <Typography variant="h3" component="h3">4</Typography>
              <Typography variant="h4" component="h4">
                What type of
                <br />
                <span className={ classes.spanBold }>
                  {' '}
                  home
                  {' '}
                </span>
                <span>will I be</span>
                <br />
                <span>
                  {' '}
                  able to afford and when?
                </span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleEight }>
            <Link to="/">
              <Typography variant="h3" component="h3">9</Typography>
              <Typography variant="h4" component="h4">
                Will I be able to
                {' '}
                <br />
                <span>
                  {' '}
                  buy those
                  {' '}
                </span>
                <span className={ classes.spanBold }>special things</span>
                <span>
                  {' '}
                  I&apos;ve
                  <br />
                  {' '}
                  {' '}
                  always wanted?
                </span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleNine }>
            <Link to="/">
              <Typography variant="h3" component="h3">5</Typography>
              <Typography variant="h4" component="h4">
                <span>

                  Should I get
                  {' '}
                </span>
                <br />
                <span className={ classes.spanBold }>my own wheels</span>
                <span className={ classes.spanBlock }> or find another way</span>
                <span className={ classes.spanBlock }>to get around?</span>
              </Typography>
            </Link>
          </li>
          <li aria-hidden="true" className={ classes.circleTen }>
            <Link to="/">
              <Typography variant="h3" component="h3">10</Typography>
              <Typography variant="h4" component="h4">
                Will I be able to
                <br />
                <span className={ classes.spanBold }>save enough</span>
                <br />
                <span className={ classes.spanBold }>to retire</span>
                <span>
                  {' '}
                  to my
                  {' '}
                </span>
                <span>dream location?</span>
              </Typography>
            </Link>
          </li>
        </ul>

      </div>
      <p className={ classes.paraSlide }>
        Keep your full range of goals in mind as you build your career path. Now let&apos;s get started!
      </p>
    </div>

  );
};

NotAboutNumbers.propTypes = {
  classes: PropTypes.object.isRequired,

};

export default withStyles( styles )( NotAboutNumbers );
