import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../../screens/pwi/buying-a-home/styles';

const Summary = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Buying a home is the largest purchase you might make in your life. With a plan to buy and manage your home, you can make that purchase with confidence.</p>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

Summary.propTypes = {
  classes: PropTypes.object.isRequired,

};

export default withStyles( styles )( Summary );
