import React from 'react';

const PNF = () =>{  
    return (
      <div style={{   
        height: 'calc(100vh - 135px)',
        display: 'flex',
        alignItems: 'start',
        justifyContent: 'center',
        background: '#f1f1f1',
        paddingTop: '10%',
      }}>
        <h1 style={{  
          textAlign: 'center',
          margin: '0px',
          fontFamily: 'MuseoSans-700',
          fontWeight: 'normal',
          }}><span style={{
            display: 'block',
            fontSize: '60px',
            fontFamily: 'MuseoSans-900',
          }}>404</span> Page Note Found</h1>
      </div>
    );
}

export default PNF;
