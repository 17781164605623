import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'html-react-parser';
import { withStyles } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import styles from './style';

const PWISidebar = ( props ) => {
  const {
      classes, definationPopup, handleDefinationPopupClose, getPWiDefination
  } = props;
  const redirectToFinance = () => {
    var url = window.location.origin;
    window.open( `${url}/finance-explained?type=definition&id=${getPWiDefination.id}`);
  };

  return (

    <div className={ definationPopup ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone }>
      <div className={ classes.fixedMenuHeader }>
        <i className="la la-close" aria-hidden="true" onClick={ handleDefinationPopupClose } />
      </div>
      {
        getPWiDefination.link  ? (
          <a href={getPWiDefination.link} target="_blank" rel="noreferrer">
            <h3>{getPWiDefination.title}</h3>
          </a>
        ) : <Typography variant="h3" component="h3" className={classes.TitleWithoutLink}>{getPWiDefination.title}</Typography>
      }
      {Parse( `
      <p>${getPWiDefination.description.length > 600 ? `${getPWiDefination.description.substring( 0, 600 - 3 )}...` : getPWiDefination.description}</p>
      ` )}
      <div className={ classes.btnGroup }>
        <Button className={ classes.cancel } onClick={ redirectToFinance }><span className={classes.clickHere}>GO TO FINANCE EXPLAINED</span></Button>
        <Button className={ classes.next } onClick={ handleDefinationPopupClose }>Close</Button>
      </div>
    </div>

  );
};

PWISidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  definationPopup: PropTypes.bool.isRequired,
  handleDefinationPopupClose: PropTypes.func.isRequired,
  definationResult: PropTypes.string.isRequired,
  titleDefination: PropTypes.string.isRequired,
};

export default withStyles( styles )( PWISidebar );
