import React from 'react';
import Typography from '@material-ui/core/Typography';
import estimatedIncomeTaxesSheet from '../../components/sheets/estimated-income-taxes-sheet';
import * as intialData from '../../components/sheets/pwi-intial-values';

const EstimatedIncomeTaxes = ( ) => (
  <Typography variant="body1" component="div">
    {/* {console.log( 'intialData', intialData )} */}
    {JSON.stringify( estimatedIncomeTaxesSheet(
      intialData.modulesData().module1Data,
      intialData.modulesData().module2Data,
      intialData.modulesData().module4Data,
      intialData.modulesData().module5Data,
      intialData.modulesData().module6Data,
      intialData.modulesData().module8Data,
      intialData.modulesData().module10Data,
      intialData.modulesData().module15Data,
      intialData.modulesData().module17Data,
      intialData.modulesData().databaseData,
      intialData.modulesData().incomeTaxData,
    ) )}
  </Typography>
);

export default EstimatedIncomeTaxes;
