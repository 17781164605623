import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  Button
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';
import styles from '../../../screens/pwi/higher-education/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import { percentFormatWithTwoDecimal, percentFormatWithZeroDecimal, yearFormatter } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';

const originationMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 1,
    label: '1%',
  },
  {
    value: 2,
    label: '2%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 4,
    label: '4%',
  },
  {
    value: 5,
    label: '5%',
  },
];

const termOfLoanMarks = [
  {
    value: 0,
    label: '0 yrs',
  },
  {
    value: 3,
    label: '3 yrs',
  },
  {
    value: 6,
    label: '6 yrs',
  },
  {
    value: 9,
    label: '9 yrs',
  },
  {
    value: 12,
    label: '12 yrs',
  },
  {
    value: 15,
    label: '15 yrs',
  },
];

const interestRateMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 3,
    label: '3%',
  },
  {
    value: 6,
    label: '6%',
  },
  {
    value: 9,
    label: '9%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 15,
    label: '15%',
  },
];

const followersMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 100,
    label: '100%',
  },
];

const PrivateStudentLoansContinue = ( props ) => {
  const {
    classes, handleDefinationPopup, updateData, inputData, setStudentLoanType
  } = props;

  const [inputs, setInputs] = React.useState();
  const [originationAmount,setOriginationAmount] = React.useState(0);
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(()=>{
    if(inputs !== undefined) {
      const amount = inputs.private_loans !== undefined ? inputs.private_loans : 0;
      const origination_fee =  inputs.private_origination_fee !== undefined ? inputs.private_origination_fee : 0;
      const originationFees = ((amount)/100)*origination_fee;
      setOriginationAmount(originationFees)
    } else {
      setOriginationAmount(0)
    }
  },[inputs])

  useEffect(()=>{
    let inputValues = {...inputs};
    let privateLoans = 0;
    if(inputData.private_firstyear !== undefined) {
      privateLoans += inputData.private_firstyear;
    }
    if(inputData.private_secondyear !== undefined) {
      privateLoans += inputData.private_secondyear;
    }
    if(inputData.private_thirdyear !== undefined) {
      privateLoans += inputData.private_thirdyear;
    }
    if(inputData.private_fourthyear !== undefined) {
      privateLoans += inputData.private_fourthyear;
    }
    if(inputData.private_fifthyear !== undefined) {
      privateLoans += inputData.private_fifthyear;
    }
    if(inputData.private_sixthyear !== undefined) {
      privateLoans += inputData.private_sixthyear;
    }
    inputValues['private_loans'] = privateLoans;

    inputValues['private_load_percentage'] =  inputData.private_load_percentage !== undefined ? inputData.private_load_percentage : 0;

    const totalAttendance =  moduleServices.module6Data.educationTotalAttendance/inputData.years_diff;
    if(inputData.private_load_percentage === undefined && inputValues['private_loans'] > 0) {
      if(totalAttendance > 0) {
        let loadSlider = (inputValues['private_loans']/totalAttendance)*100;
        inputValues['private_load_percentage'] = loadSlider; 
      }
    }

    if(inputData.private_origination_fee !== undefined) {
      inputValues['private_origination_fee'] = inputData.private_origination_fee; 
    } else {
      inputValues['private_origination_fee'] = 1; 
    }

    if(inputData.private_interest_rate !== undefined) {
      inputValues['private_interest_rate'] = inputData.private_interest_rate;  
    } else {
      inputValues['private_interest_rate'] = 6;  
    }

    if(inputData.private_term !== undefined) {
      inputValues['private_term'] = inputData.private_term; 
    } else {
      inputValues['private_term'] = 10; 
    }

    setInputs(inputValues);
    updateData({...inputData,...inputValues});
  },[])

  const updateValue = ( e, value, field, type ) => {
    let inputValues = {...inputs};
    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.target.value;
      if(newvalue === '') {
        newvalue = 0;
      }
      newvalue = convertCurrencyToInteger(newvalue);
    }
    inputValues[field] =  newvalue;
    if(field === 'private_load_percentage' || field === 'private_loans') {
      const totalAttendance =  moduleServices.module6Data.educationTotalAttendance;
      if(field === 'private_load_percentage') {
        inputValues['private_loans'] = (totalAttendance/100)*inputValues[field];
      } else {
        if(totalAttendance < inputValues['private_loans']) {
          inputValues['private_loans'] = totalAttendance;
        }
        if(totalAttendance > 0) {
           inputValues['private_load_percentage'] = (inputValues['private_loans']/totalAttendance)*100;
        } else {
          inputValues['private_load_percentage'] = 0;
        }
      }

      let private_amount = inputValues['private_loans'];

      if(inputData.years_diff >= 1) {
          let eachYear = private_amount/inputData.years_diff;
          if(eachYear > 0 && inputData.years_diff >= 1) {
            inputValues['private_firstyear'] = eachYear;
          } else {
            inputValues['private_firstyear'] = 0;
          }

          if(eachYear > 0 && inputData.years_diff >= 2) {
             inputValues['private_secondyear'] = eachYear;
          } else {
             inputValues['private_secondyear'] = eachYear;
          }

          if(eachYear > 0 && inputData.years_diff >= 3) {
            inputValues['private_thirdyear'] = eachYear;
          } else {
            inputValues['private_thirdyear'] = eachYear;
          }

          if(eachYear > 0 && inputData.years_diff >= 4) {
            inputValues['private_fourthyear'] = eachYear;
          } else {
            inputValues['private_fourthyear'] = eachYear;
          }

          if(eachYear > 0 && inputData.years_diff >= 5) {
            inputValues['private_fifthyear'] = eachYear;
          } else {
            inputValues['private_fifthyear'] = eachYear;
          }

          if(eachYear > 0 && inputData.years_diff >= 6) {
            inputValues['private_sixthyear'] = eachYear;
          } else {
            inputValues['private_sixthyear'] = eachYear;
          }
      }
    }
    setInputs(inputValues);
    updateData( 'total_obj' ,{...inputData,...inputValues} );
  };
  const emptyIsFocus = (field) => {
    const in_value = {...inputData}
    if(field=='private_interest_rate'){
    in_value['private_interest_rate'] = parseFloat(parseFloat(inputData.private_interest_rate).toFixed(2));
    setInputs(in_value);
    }
    setIsFocus('');
  };

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      <p>Private student loans should only be used as a last resort to finance your education. Run through the check list below before adding them to your plan:</p>
      <div>
        <ul>
          <li>You have completed the Free Application FAFSA</li>
          <li>You have borrowed the maximum amount of federal subsidized and unsubsidized student loans</li>
          <li>You or your co-signer have good credit. Most private student loans require a co-signer or another person to take shared responsibility of your loans.</li>
          <li>You borrow only what you need. Private loans are expensive.</li>
        </ul>
      </div>
      <p>Check the total cost of attendance and total funding sources at the bottom of the page. Do you have cash leftover? Or do you need more funding?</p>
      <p>Private student loans should be used as a last resort to finance your education since they are typically more expensive loans. Use the following links to explore private loan options:</p>
      <p className={ classes.privateLoanOptions }>
        <a className={ classes.navigatorHover } target="_blank" rel="college_navigator noopener noreferrer" href="https://www.nerdwallet.com/best/loans/student-loans/private-student-loans">15 Private Loan Options</a>
      </p>
      <p className={ classes.privateLoanOptions }>
        <a className={ classes.navigatorHover } target="_blank" rel="college_navigator noopener noreferrer" href="https://www.bankrate.com/loans/student-loans/rates/">Other Private Loan Options</a>
      </p>
      <div className={ classes.schoolTopSpace }>
        <p className={ `${classes.marginTopZero}` }>
          <i>
            Selected School:
            {' '}
            {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
          </i>
        </p>
        <p className={ classes.marginTopZero }>
          <i>
            % of First-Time Students Receiving Private Loans:
            {(inputData.school_details !== undefined && inputData.school_details.other_loans_percentage !== undefined) ? (
              <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.school_details.other_loans_percentage } displayType="text" thousandSeparator suffix="%" />
            ):('N/A')}
          </i>
        </p>
        <p className={ classes.marginTopZero }>
          <i>
            Average Amount of Private Loans per First-Time Student per Year:
            {
              (inputData.school_details !== undefined && inputData.school_details.other_loans_average_amount !== undefined) ? (
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.school_details.other_loans_average_amount } displayType="text" thousandSeparator prefix="$" />
              ):('N/A')
            }
          </i>
        </p>
      </div>
      {
        inputs !== undefined && inputs.private_loans !== undefined && (
          <div className={ classes.innnerFlowGroupMainFull }>
            <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTopNewText } />
            <ReactTooltip id="definationTitleNew" place="top" type="info" effect="solid" className={ classes.definitionTooltips } />
            <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMainWithoutBorder} ${classes.innnerFlowGroupMainWithoutBorderNew} ${classes.innnerFlowGroupMainWithoutBorderNewGap}` }>
              <div className={ classes.innnerFlowGroup }>
                <div className={ `${classes.garphLeft} ${classes.garphLeftScholor} ${classes.annuvalGrowthScholorNewPrivate} ${classes.annuvalGrowthScholorNewPrivateFederal}` }>
                  <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor}  ${classes.annuvalGrowthScholorNewPrivate}` }>
                    <div>
                      <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Private Loans' ) }>Private Loans</span></p>
                      <div className={ classes.pwisliderInputs }>
                        <PwiSlider
                          value={ inputs.private_load_percentage }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          valueLabelFormat={ percentFormatWithZeroDecimal }
                          min={ 0 }
                          max={ 100 }
                          step={ 1 }
                          marks={ followersMarks }
                          ValueLabelComponent={ ValueLabelComponent }
                          onChange={ (e, value)=>updateValue( e, value, 'private_load_percentage', 'slider' ) }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightFamily} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew}` }>
                  <h3 className={ classes.floatRightBpx }>Total (4 years)</h3>
                  <div>
                    <div className={ classes.inputRightBlock }>
                      $
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.private_loans } onChange={ ( e ) => updateValue( e, '', 'private_loans', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                    </div>
                  </div>
                </div>
              </div>
              <div className={ classes.innnerFlowGroup }>
                <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                  <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor} ${classes.annuvalGrowthScholorNewPrivate} ${classes.annuvalGrowthScholorNewPrivateFederal}` }>
                    <div>
                      <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Origination Fees' ) }>Origination Fees</span></p>
                      <div className={ classes.pwisliderInputs }>
                        <PwiSlider
                          value={ inputs.private_origination_fee }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          valueLabelFormat={ percentFormatWithTwoDecimal }
                          min={ 0 }
                          max={ 5 }
                          step={ 0.1 }
                          marks={ originationMarks }
                          ValueLabelComponent={ ValueLabelComponent }
                          onChange={ (e, value)=>updateValue( e, value, 'private_origination_fee', 'slider' ) }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightFamily} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew} ${classes.garphRightFamily} ${classes.bdNone}` }>
                  <div>
                    <div className={ classes.inputRightBlock }>
                      <font className={ classes.opacityZero }>$</font>
                      { originationAmount !== undefined && originationAmount > 0 ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ originationAmount } displayType="text" thousandSeparator prefix="($" suffix=")" className={ `${classes.dangerText} ${classes.bdNone}` }/>
                      ):(
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ originationAmount !== undefined ? originationAmount : 0 } displayType="text" thousandSeparator prefix="$" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={ classes.innnerFlowGroup }>
                <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                  <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor} ${classes.annuvalGrowthScholorNewPrivate} ${classes.annuvalGrowthScholorNewPrivateFederal}` }>
                    <div>
                      <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan' ) }>Term of the Loan</span></p>
                      <div className={ classes.pwisliderInputs }>
                        <PwiSlider
                          value={ inputs.private_term }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          valueLabelFormat={ yearFormatter }
                          min={ 0 }
                          max={ 15 }
                          step={ 1 }
                          marks={ termOfLoanMarks }
                          ValueLabelComponent={ ValueLabelComponent }
                          onChange={ (e, value)=>updateValue( e, value, 'private_term', 'slider' ) }
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightFamily} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew}` }>
                  <div>
                    <div className={ classes.inputRightBlock }>
                      <font className={ classes.opacityZero }>$</font>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.private_term } onChange={ ( e ) => updateValue( e, '', 'private_term', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                      <div className={ classes.flexContainerDollat }>
                         <font className={ classes.spanYrs}>yrs</font>
                        <img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="definationTitle" data-tip="Private student loans can range from 5 years to 20 years. The longer the term of the loan, the more interest you will pay over the life of the loan. Change the term of the loan and see how that affects your monthly payment and total repayment amount." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={ classes.innnerFlowGroup }>
                <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
                  <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor} ${classes.annuvalGrowthScholorNewPrivate} ${classes.annuvalGrowthScholorNewPrivateFederal}` }>
                    <div>
                      <p><span aria-hidden="true" data-for="definationTitleNew" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest Rate</span></p>
                      <div className={ classes.pwisliderInputs }>
                        <PwiSlider
                          value={ inputs.private_interest_rate }
                          aria-labelledby="discrete-slider-custom"
                          valueLabelDisplay="auto"
                          valueLabelFormat={ percentFormatWithTwoDecimal }
                          min={ 0 }
                          max={ 15 }
                          step={ 0.1 }
                          marks={ interestRateMarks }
                          ValueLabelComponent={ ValueLabelComponent }
                          onChange={ (e, value)=>updateValue( e, value, 'private_interest_rate', 'slider' ) }
                        />
                      </div>

                    </div>
                  </div>
                </div>
                <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightFamily} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew} ${classes.garphRightFederalNew}` }>
                  <div>
                    <div className={ classes.inputRightBlock }>
                      <font className={ classes.opacityZero }>$</font>
                      <NumberFormat
                        decimalScale={ isFocus!=='private_interest_rate'?2:5 }
                        fixedDecimalScale={isFocus!=='private_interest_rate'}
                        allowNegative={ false }
                        customInput={ TextField }
                        thousandSeparator
                        value={ inputs.private_interest_rate }
                        onChange={ ( e ) => updateValue( e, '', 'private_interest_rate', 'number' ) }
                        onFocus={ ( e ) => { e.target.select(); setIsFocus('private_interest_rate') }}
                        onBlur={()=>emptyIsFocus('private_interest_rate')} />
                      <div className={ classes.flexContainerDollat }>
                        <font className={ classes.spanPerc}>%</font>
                        <img className={ classes.InfoIconNew } src={ infoIcon } alt="" data-for="definationTitle" data-tip="Private student loans' interest rates can range anywhere from 7% to 10% and even higher. See how changing the interest rate affects your monthly payment and total repayment amount." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className={ `${classes.totalCostNewFlex} ${classes.totalCostNewFlexTotal}` }>
        <span>Private Student Loan Monthly Payment</span>
        {' '}
        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module6Data.morePrivateMonthlyPayment !== undefined ? moduleServices.module6Data.morePrivateMonthlyPayment : 0 } displayType="text" thousandSeparator prefix="$" />
      </div>
      <div className={ `${classes.totalCostNewFlex} ${classes.totalCostNewFlexTotal}` }>
        <span>Total Repayment Amount (Principal & Interest)</span>
        {' '}
        <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ moduleServices.module6Data.morePrivateTotalPrincipalInterest !== undefined ? moduleServices.module6Data.morePrivateTotalPrincipalInterest : 0 } displayType="text" thousandSeparator prefix="$" />
      </div>
      {inputs !== undefined && inputs['private_loans'] !== undefined && inputs['private_loans'] > 0 && (
        <div className={ classes.graphButtnDiv }>
          <Button className={ `${classes.graphButtn} ${classes.bdNone}` } onClick={()=>{setStudentLoanType('private-graph')}}>Repayment graph</Button>
          <Button className={ `${classes.graphButtn} ${classes.bdNone}` } onClick={()=>{setStudentLoanType('private-table')}}>Repayment table</Button>
        </div>
      )}
      
    </div>
  );
};

PrivateStudentLoansContinue.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  updateData: PropTypes.func.isRequired,
  setStudentLoanType: PropTypes.func.isRequired,
  inputData: PropTypes.object.isRequired,
};

export default withStyles( styles )( PrivateStudentLoansContinue );
