import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
} from '@material-ui/core';
import styles from './styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import { formatDollar } from '../../../utilities/chartCommonFunctions';
// import graphImage from '../../../assets/img/financial-calculators/mortgage.png';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );

HCExporting( Highcharts );

const MortgageRepayment = ( props ) => {
  const { classes } = props;

  const mortgageRepaymentDefaultValues = {
    homePurchasePrice: 300000,
    maxhomePurchasePrice: 300000,
    downPayment: 20.00,
    mortgageValue: 240000,
    interestRate: 3.75,
    termOfTheLoan: 30,
  };

  const [mortgageRepaymentValues, setMortgageRepaymentValues] = useState( mortgageRepaymentDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );

  useEffect( () => {
    let refinancedStudentLoanData = {};
    let summationData = {};
    let mortgageAnalysisValue = 0;

    // commeneted by mahananda--- because inputType is not there
    // if (typeof inputType !== 'undefined' && inputType !== '') {
    //   if (inputType !== 'mortgage') {
    //     // mortgageRepaymentValues.mortgageValue = $filter('currency')((converters.convertCurrencyToInteger(mortgageRepaymentValues.homePurchasePrice) -  mortgageRepaymentValues.downPaymentValue), '', 0);
    //     // console.log('100-mortgageRepaymentValues.downPayment',100-mortgageRepaymentValues.downPayment)
    //     // mortgageRepaymentValues.mortgageValue = $filter('currency')(((parseInt(mortgageRepaymentValues.homePurchasePrice, 10) / 100) * (100 - mortgageRepaymentValues.downPayment)), '', 0); --- commented by mahananda
    //     mortgageRepaymentValues.mortgageValue = (parseInt(mortgageRepaymentValues.homePurchasePrice, 10) / 100) * (100 - mortgageRepaymentValues.downPayment);
    //     // console.log('mortgageRepaymentValues.mortgageValue',mortgageRepaymentValues.mortgageValue)
    //     mortgageRepaymentValues.downPaymentValue = parseInt(mortgageRepaymentValues.homePurchasePrice, 10) - parseInt(mortgageRepaymentValues.mortgageValue, 10);
    //   } else {
    //     let downpaymentVal = 100 - (parseInt(mortgageRepaymentValues.mortgageValue, 10) / parseInt(mortgageRepaymentValues.homePurchasePrice, 10)) * 100;
    //     downpaymentVal = parseFloat(downpaymentVal).toFixed(3);
    //     mortgageRepaymentValues.downPayment = downpaymentVal;
    //     //mortgageRepaymentValues.downPaymentValue = ( converters.convertCurrencyToInteger(mortgageRepaymentValues.homePurchasePrice) / 100 ) * mortgageRepaymentValues.downPayment;
    //     // console.log(converters.convertCurrencyToInteger(mortgageRepaymentValues.homePurchasePrice));
    //     // console.log(converters.convertCurrencyToInteger(mortgageRepaymentValues.mortgageValue));
    //     mortgageRepaymentValues.downPaymentValue = parseInt(mortgageRepaymentValues.homePurchasePrice, 10) - parseInt(mortgageRepaymentValues.mortgageValue, 10);
    //   }
    // } else {
      
    console.log('event',mortgageRepaymentValues.homePurchasePrice,mortgageRepaymentValues.mortgageValue);
    mortgageRepaymentValues.downPaymentValue = parseInt( mortgageRepaymentValues.homePurchasePrice) - parseInt( mortgageRepaymentValues.mortgageValue);
    // }

    mortgageRepaymentValues.maxhomePurchasePrice = parseInt( mortgageRepaymentValues.homePurchasePrice, 10 );

    if ( typeof mortgageRepaymentValues.mortgageValue !== 'undefined' ) {
      mortgageAnalysisValue = parseInt( mortgageRepaymentValues.mortgageValue, 10 );
    } else {
      mortgageAnalysisValue = parseInt( mortgageRepaymentValues.homePurchasePrice, 10 ) - mortgageRepaymentValues.downPaymentValue;
      // mortgageRepaymentValues.mortgageValue = $filter('currency')(mortgageAnalysisValue, '', 0); --- commented by mahananda
      mortgageRepaymentValues.mortgageValue = mortgageAnalysisValue;
    }

    // Start Sheet Calculations
    let loanAmount = 0;
    if ( typeof mortgageAnalysisValue !== 'undefined' && mortgageAnalysisValue !== '' ) {
      loanAmount = mortgageAnalysisValue;
    }

    // Beginning Balance
    const beginningBalance = loanAmount;

    // Terms in Years
    let termsOfYear = 0;
    if ( beginningBalance > 0 ) {
      termsOfYear = parseFloat( mortgageRepaymentValues.termOfTheLoan );
    }

    // Annual Interest Rate
    let annualInterestRate = 0;
    if ( typeof mortgageRepaymentValues.interestRate !== 'undefined' && mortgageRepaymentValues.interestRate !== '' ) {
      annualInterestRate = parseFloat( mortgageRepaymentValues.interestRate );
    }

    // this.annualInterestRate = 4.50;

    // Original Payment
    let originalPayment = 0;
    if ( beginningBalance > 0
      && termsOfYear > 0
      && annualInterestRate > 0 ) {
      originalPayment = commonFunctions.PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( beginningBalance ), 0, 0 );
    }
    mortgageRepaymentValues.originalPaymentValue = originalPayment;
    mortgageRepaymentValues.originalPaymentAnnualValue = originalPayment * 12;
    // Interest Accrued
    // let interestAccrued = 0;
    // if (paymentPlanIdentifier === 3 &&
    //   beginningBalance > 0 &&
    //   loanAmount > 0) {
    //   interestAccrued = beginningBalance - loanAmount;
    // }

    // Total Interest Paid
    let totalInterestPaid = 0;

    // Total Paid
    let totalPaid = 0;

    // Total Points Pais
    // const totalPointsPaid = 0;

    // Tabular Data
    let graduationYear = 0;
    /* moduleServices.fedSubPaymentYearBegins();
    if(typeof module18Data.fedSubPaymentYearBegins !== 'undefined') {
        graduationYear = module18Data.fedSubPaymentYearBegins;
    } */
    graduationYear = 2019;
    if ( graduationYear > 0 ) {
      let year = parseInt( graduationYear, 10 );
      const yearLimit = year + termsOfYear;

      // Student Loan Data
      refinancedStudentLoanData = {};

      let Balance = 0;
      let Payment = 0;
      let Interest = 0;
      let Principal = 0;
      let Equity = 0;
      let TotalInterest = 0;
      let TotalPayment = 0;

      const totalInterestPaidArray = [];
      const totalPaidArray = [];

      // For Loop from graduation year to +10 years
      for ( year; year <= yearLimit; year += 1 ) {
        refinancedStudentLoanData[year] = {};

        refinancedStudentLoanData[year].LaggingInterest = 0;
        refinancedStudentLoanData[year].LaggingPrincipal = 0;
        refinancedStudentLoanData[year]['Months Total Balance'] = 0;

        for ( let month = 1; month <= 12; month += 1 ) {
          refinancedStudentLoanData[year][month] = {};

          if ( year === graduationYear && month === 1 ) {
            refinancedStudentLoanData[year][month].YearIdentifier = year;

            Balance = beginningBalance;
            refinancedStudentLoanData[year][month].Balance = Balance;
            Payment = originalPayment;
            refinancedStudentLoanData[year][month].Payment = originalPayment;

            if ( Balance > 0 && annualInterestRate > 0 ) {
              Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              Interest = 0;
            }
            refinancedStudentLoanData[year][month].Interest = Interest;

            if ( Balance > 0 ) {
              Principal = Payment - Interest;
            } else {
              Principal = 0;
            }
            refinancedStudentLoanData[year][month].Principal = Principal;

            if ( Balance > 0 ) {
              Equity = Principal;
            } else {
              Equity = 0;
            }
            refinancedStudentLoanData[year][month].Equity = Equity;

            if ( Balance > 0 ) {
              TotalInterest = Interest;
            } else {
              TotalInterest = 0;
            }
            refinancedStudentLoanData[year][month].TotalInterest = TotalInterest;

            if ( Balance > 0 ) {
              TotalPayment = Equity + TotalInterest;
            } else {
              TotalPayment = 0;
            }
            refinancedStudentLoanData[year][month].TotalPayment = TotalPayment;
          } else { // Year Identifier
            refinancedStudentLoanData[year][month].YearIdentifier = year;

            // Balance
            let tempBalance;
            if ( Balance === 0 ) {
              tempBalance = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempBalance = ( Balance - Principal );
            } else {
              tempBalance = 0;
            }
            refinancedStudentLoanData[year][month].Balance = tempBalance;

            // Payment
            refinancedStudentLoanData[year][month].Payment = Payment;

            // Interest
            let tempInterest;
            if ( Balance === 0 ) {
              tempInterest = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              tempInterest = 0;
            }
            refinancedStudentLoanData[year][month].Interest = tempInterest;

            // Principal
            let tempPrincipal;

            if ( Balance === 0 ) {
              tempPrincipal = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempPrincipal = Payment - tempInterest;
            } else {
              tempPrincipal = 0;
            }
            refinancedStudentLoanData[year][month].Principal = tempPrincipal;

            // Equity
            let tempEquity;
            if ( Balance === 0 ) {
              tempEquity = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempEquity = Equity + tempPrincipal;
            } else {
              tempEquity = 0;
            }
            refinancedStudentLoanData[year][month].Equity = tempEquity;

            // Total Interest
            let tempTotalInterest;
            if ( Balance === 0 ) {
              tempTotalInterest = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempTotalInterest = TotalInterest + tempInterest;
            } else {
              tempTotalInterest = 0;
            }
            refinancedStudentLoanData[year][month].TotalInterest = tempTotalInterest;

            // Total Payments
            let tempTotalPayment;
            if ( Balance === 0 ) {
              tempTotalPayment = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempTotalPayment = tempEquity + tempTotalInterest;
            } else {
              tempTotalPayment = 0;
            }
            refinancedStudentLoanData[year][month].TotalPayment = tempTotalPayment;

            // Assign Values
            Balance = tempBalance;
            Principal = tempPrincipal;
            Equity = tempEquity;
            TotalInterest = tempTotalInterest;
          }

          // Total Interest Paid Array
          totalInterestPaidArray.push( refinancedStudentLoanData[year][month].TotalInterest );

          // Total Paid Array
          totalPaidArray.push( refinancedStudentLoanData[year][month].TotalPayment );

          refinancedStudentLoanData[year].LaggingInterest = parseFloat( refinancedStudentLoanData[year].LaggingInterest ) + parseFloat( refinancedStudentLoanData[year][month].Interest );
          refinancedStudentLoanData[year].LaggingPrincipal = parseFloat( refinancedStudentLoanData[year].LaggingPrincipal ) + parseFloat( refinancedStudentLoanData[year][month].Principal );
          refinancedStudentLoanData[year]['Months Total Balance'] = parseFloat( refinancedStudentLoanData[year]['Months Total Balance'] ) + parseFloat( refinancedStudentLoanData[year][month].Balance );

          // Total Interest Paid
          if ( totalInterestPaidArray.length > 0 ) {
            totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
          }
          mortgageRepaymentValues.totalInterestPaidValue = totalInterestPaid;

          // Total Paid
          if ( totalPaidArray.length > 0 ) {
            totalPaid = Math.max.apply( null, totalPaidArray );
          }
          mortgageRepaymentValues.totalPaidValue = totalPaid;
        }
      }
    }

    // Summation Data
    // Summation Data
    let summationYear = 2019;
    const firstModuleYear = 2019;
    const summationYearLimit = summationYear + termsOfYear;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;
    let summationHomeValue = 0;

    const endingBalanceGraph = [];
    const paymentGraph = [];
    const interestGraph = [];
    const principalPaybackGraph = [];
    const homeValueGraph = [];
    const yearsListGraph = [];
    const graphData = {};

    // For Loop from Start year to +14 years
    for ( summationYear; summationYear < summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // Beginning Balance
      if ( summationYear === firstModuleYear && loanAmount > 0 ) {
        summationBeginningBalance = refinancedStudentLoanData[summationYear][1].Balance;
      } else if ( summationYear > firstModuleYear ) {
        if ( loanAmount > 0 && typeof summationData[summationYear - 1] !== 'undefined' && typeof summationData[summationYear - 1].EndingBalance !== 'undefined' && summationData[summationYear - 1].EndingBalance > 0 ) {
          summationBeginningBalance = summationData[summationYear - 1].EndingBalance;
        } else {
          summationBeginningBalance = 0;
        }
      } else {
        summationBeginningBalance = 0;
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof refinancedStudentLoanData[summationYear] !== 'undefined' && typeof refinancedStudentLoanData[summationYear].LaggingInterest !== 'undefined' ) {
          LaggingVal1 = refinancedStudentLoanData[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof refinancedStudentLoanData[summationYear] !== 'undefined' && typeof refinancedStudentLoanData[summationYear].LaggingPrincipal !== 'undefined' ) {
          summationLaggingPrincipal = refinancedStudentLoanData[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      // Total Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }
      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = summationData[summationYear].BeginningBalance - summationData[summationYear].LaggingPrincipal;
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;

      // Home Value
      if ( summationYear === firstModuleYear ) {
        summationHomeValue = parseInt( mortgageRepaymentValues.homePurchasePrice, 10 );
      } else {
        summationHomeValue *= 1.02;
      }
      summationData[summationYear]['Home Value'] = summationHomeValue;

      // Graph Data
      if ( summationEndingBalance > 0 || summationLaggingInterest > 0 || summationLaggingPrincipal > 0 || ( summationLaggingInterest + summationLaggingPrincipal ) > 0 || summationHomeValue > 0 ) {
        endingBalanceGraph.push( summationEndingBalance );
        interestGraph.push( summationLaggingInterest );
        principalPaybackGraph.push( summationLaggingPrincipal );
        paymentGraph.push( summationLaggingInterest + summationLaggingPrincipal );
        homeValueGraph.push( summationHomeValue );
        yearsListGraph.push( summationYear );
      }
    }

    // Display the Graph
    graphData.endingBalanceGraphData = endingBalanceGraph;
    graphData.paymentGraphData = paymentGraph;
    graphData.principalPaybackGraphData = principalPaybackGraph;
    graphData.interestGraphData = interestGraph;
    graphData.homeValueGraphData = homeValueGraph;
    graphData.yearsListGraph = yearsListGraph;

    setCalculatedValues( graphData );

    // eslint-disable-next-line
  }, [mortgageRepaymentValues]);

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...mortgageRepaymentValues };
    if ( field === 'interestRate' || field === 'termOfTheLoan' || field === 'downPayment' ) {
      newValue = e.target.value;
    } else {
      newValue = e.value !== '' ? e.value : 0;
    }

    valuesUpdated[field] = newValue;
    setMortgageRepaymentValues( valuesUpdated );
  };

  const setFixedDecimal = ( field ) => {
    const valuesUpdated = { ...mortgageRepaymentValues };
    valuesUpdated[field] = parseFloat( valuesUpdated[field] ).toFixed( 2 );
    setMortgageRepaymentValues( valuesUpdated );
  };

  return (
    <Typography letiant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Mortgage Repayment</li>
            </ul>
            <div className={ classes.csBox }>
              <div className={ classes.blueBorder }>
                <div className={ classes.mortgageRow }>
                  <div className={ classes.leftPanel }>
                    <table>
                      <tbody>
                        <tr>
                          <td>Home Purchase Price</td>
                        </tr>
                        <tr>
                          <td className={ classes.dollarField }>
                            <span className={ classes.dollarSymbol }>$</span>
                            <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ mortgageRepaymentValues.homePurchasePrice } onValueChange={ ( e ) => updateValue( e, 'homePurchasePrice' ) } onFocus={ ( e ) => e.target.select() } />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Down Payment %</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.percentField ) }>
                            <TextField
                              className={ classes.formInput }
                              value={ mortgageRepaymentValues.downPayment >0 ||  mortgageRepaymentValues.downPayment <=0 ? mortgageRepaymentValues.downPayment : 0}
                              onChange={ ( e ) => updateValue( e, 'downPayment' ) }
                              onFocus={ ( e ) => e.target.select() }
                              onBlur={ () => { setFixedDecimal( 'downPayment' ); } }
                            />
                            {' '}
                            <span className={ classes.currencySym }>%</span>
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Down Payment</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ mortgageRepaymentValues.downPaymentValue >0 ||  mortgageRepaymentValues.downPaymentValue <=0 ? mortgageRepaymentValues.downPaymentValue : 0 } displayType="text" prefix="$" />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Mortgage</td>
                        </tr>
                        <tr>
                          <td className={ classes.dollarField }>
                            <span className={ classes.dollarSymbol }>$</span>
                            <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ mortgageRepaymentValues.mortgageValue } onValueChange={ ( e ) => updateValue( e, 'mortgageValue' ) } onFocus={ ( e ) => e.target.select() } />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Interest Rate</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.percentField ) }>
                            <TextField
                              className={ classes.formInput }
                              value={ mortgageRepaymentValues.interestRate>0 || mortgageRepaymentValues.interestRate<=0 ? mortgageRepaymentValues.interestRate :0}
                              onChange={ ( e ) => updateValue( e, 'interestRate' ) }
                              onFocus={ ( e ) => e.target.select() }
                              onBlur={ () => { setFixedDecimal( 'interestRate' ); } }
                            />
                            <span className={ classes.currencySym }>%</span>
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Term of the Loan</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.percentField ) }>
                            <TextField className={ classes.formInput } value={ mortgageRepaymentValues.termOfTheLoan } onChange={ ( e ) => updateValue( e, 'termOfTheLoan' ) } onFocus={ ( e ) => e.target.select() } />
                            <span className={ classes.currencySym }>years</span>
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td className={ classes.blueDiv } />
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Monthly Payment</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ mortgageRepaymentValues.originalPaymentValue } displayType="text" prefix="$" />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td className={ classes.noWrap }>Annual Paid (Principal & Interest)</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ mortgageRepaymentValues.originalPaymentValue * 12 } displayType="text" prefix="$" />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Total Interest Paid</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ mortgageRepaymentValues.totalInterestPaidValue } displayType="text" prefix="$" />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Total Repayment Amount (Principal & Interest)</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ mortgageRepaymentValues.totalPaidValue } displayType="text" prefix="$" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={ classes.rightPanel }>
                    <h3>Home Value and Mortgage Repayment</h3>
                    <hr className={ classes.hrLine } />
                    <div className={ classes.graphImage }>
                      <HighchartsReact
                        highcharts={ Highcharts }
                        options={ {
                          chart: {
                            plotBackgroundColor: '#ffffff',
                            plotBorderColor: '#cccccc',
                            plotBorderWidth: 1,
                            plotShadow: false,
                            type: 'column',
                          // height: windowHeight,
                          // width: graphMainWidth
                          },
                          credits: {
                            enabled: false,
                          },
                          navigation: {
                            menuItemStyle: {
                              fontSize: '14px',
                              textAlign: 'left',
                            },
                            menuItemHoverStyle: {
                              background: '#f5f5f5',
                              color: '#4c4c4c',
                              fontSize: '14px',
                            },
                            buttonOptions: {
                              height: 40,
                              width: 48,
                              symbolSize: 24,
                              symbolX: 24,
                              symbolY: 21,
                              symbolStrokeWidth: 2,
                              verticalAlign: 'bottom',
                              _titleKey: 'y',
                            },
                          },
                          exporting: {
                            buttons: {
                              contextButton: {
                                menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                              },
                            },
                          },
                          title: {
                            text: '',
                            enabled: true,
                          },
                          xAxis: {
                            categories: calculatedValues.yearsListGraph,
                            labels: {
                              style: {
                                color: '#000000',
                                fontSize: '14px',
                              },
                            },
                          },
                          yAxis: [{ // Primary yAxis
                            min: 0,
                            // tickInterval: 10000,
                            labels: {
                              formatter() {
                                const chart1 = this;
                                if ( chart1.value < 0 ) {
                                  return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart1.value ) )})</span>`;
                                }
                                return formatDollar( Math.round( chart1.value ) );
                              },
                              style: {
                                color: '#000000',
                                fontSize: '14px',
                              },
                            },
                            title: {
                              text: 'Income & Expenses',
                              enabled: false,
                              style: {
                                color: '#000000',
                                fontSize: '14px',
                              },
                            },
                          }, { // Tertiary yAxis
                          /* min: 0,
                          tickInterval: 10000,
                          gridLineWidth: 0, */
                            title: {
                              text: 'Net Worth',
                              enabled: false,
                              style: {
                                color: '#000000',
                                fontSize: '14px',
                              },
                            },
                            labels: {
                              formatter() {
                                const chart = this;
                                if ( chart.value < 0 ) {
                                  return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                                }
                                return formatDollar( Math.round( chart.value ) );
                              },
                              style: {
                                color: '#000000',
                                fontSize: '14px',
                              },
                            },
                            opposite: true,
                          }],
                          tooltip: {
                            headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                            crosshairs: true,
                            shared: true,
                            valueDecimals: 1,
                            valueSuffix: '%',
                            style: {
                              color: '#000000',
                              fontSize: '14px',
                            },
                          },
                          plotOptions: {
                            column: {
                              stacking: 'normal',
                            },
                          },
                          legend: {
                            itemStyle: {
                              fontSize: '14px',
                            },
                          },
                          series: [{
                            name: 'Ending Balance',
                            yAxis: 0,
                            data: calculatedValues.endingBalanceGraphData,
                            stack: 'male',
                            color: '#C00000',
                          }, {
                            name: 'Home Value',
                            yAxis: 0,
                            data: calculatedValues.homeValueGraphData,
                            stack: 'female',
                            color: '#4472C4',
                          }, {
                            name: 'Interest',
                            type: 'spline',
                            yAxis: 1,
                            data: calculatedValues.interestGraphData,
                            stack: 'female',
                            color: '#C5E0B4',
                          }, {
                            type: 'spline',
                            name: 'Principal Payback',
                            yAxis: 1,
                            data: calculatedValues.principalPaybackGraphData,
                            color: '#548235',
                          }],
                          responsive: {
                            rules: [{
                              condition: {
                                maxWidth: 500
                              },
                              chartOptions: {
                                legend: {
                                  align: 'center',
                                  verticalAlign: 'bottom',
                                  layout: 'horizontal'
                                }
                              }
                            }]
                          },
                        } }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

MortgageRepayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( MortgageRepayment );
