import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-career-advancement/styles';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';

const RetirementSummary = ( props ) => {
  const {
    classes, handleDefinationPopup
  } = props;

  return (
    <div className={classes.contentBlock}>
      <p>You're on your way to building your retirement savings. Review your 401(k) contributions during this career move.</p>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
          <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70}` }>
              <table cellPadding="0" cellSpacing="0">
                  <thead>
                      <tr className={ classes.trHead }>
                          <td>401(k) Retirement Accounts</td>
                          <td>401(k)</td>
                          <td>Roth 401(k)</td>
                      </tr>
                  </thead>
                <tbody>
                  <tr>
                    <td>
                      <span><strong>Beginning 401(k) Balance in Year {(moduleServices.module5Data.start_year !== undefined && moduleServices.module5Data.start_year !== null )&& moduleServices.module5Data.start_year}</strong></span>
                    </td>
                    <td>
                      <span>{moduleServices.module5Data.beginningBalanceInYearNext !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.beginningBalanceInYearNext} prefix={moduleServices.module5Data.beginningBalanceInYearNext >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.beginningBalanceInYearNext < 0 && ')'} />
                        ) : ('$0')}</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.beginningBalanceInYearNextRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.beginningBalanceInYearNextRoth} prefix={moduleServices.module5Data.beginningBalanceInYearNextRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.beginningBalanceInYearNextRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Personal Contributions After {(moduleServices.module5Data.end_year > 0 && moduleServices.module5Data.start_year > 0) ? (moduleServices.module5Data.end_year - moduleServices.module5Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.personalContributionAetrFewYears !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.personalContributionAetrFewYears} prefix={moduleServices.module5Data.personalContributionAetrFewYears >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.personalContributionAetrFewYears < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.personalContributionAetrFewYearsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.personalContributionAetrFewYearsRoth} prefix={moduleServices.module5Data.personalContributionAetrFewYearsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.personalContributionAetrFewYearsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Personal Contributions After {(moduleServices.module5Data.end_year > 0 && moduleServices.module5Data.start_year > 0) ? (moduleServices.module5Data.end_year - moduleServices.module5Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.earningsOnPersonalContributions !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.earningsOnPersonalContributions} prefix={moduleServices.module5Data.earningsOnPersonalContributions >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.earningsOnPersonalContributions < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.earningsOnPersonalContributionsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.earningsOnPersonalContributionsRoth} prefix={moduleServices.module5Data.earningsOnPersonalContributionsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.earningsOnPersonalContributionsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Total Personal Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdv !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdv} prefix={moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdv < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth} prefix={moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Employer Contributions After {(moduleServices.module5Data.end_year > 0 && moduleServices.module5Data.start_year > 0) ? (moduleServices.module5Data.end_year - moduleServices.module5Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module5Data.employerContributionAfterFewYears !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.employerContributionAfterFewYears} prefix={moduleServices.module5Data.employerContributionAfterFewYears >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.employerContributionAfterFewYears < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span className={classes.noInputText}>
                        {moduleServices.module5Data.employerContributionAfterFewYearsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.employerContributionAfterFewYearsRoth} prefix={moduleServices.module5Data.employerContributionAfterFewYearsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.employerContributionAfterFewYearsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span>Earnings on Employer Contributions After {(moduleServices.module5Data.end_year > 0 && moduleServices.module5Data.start_year > 0) ? (moduleServices.module5Data.end_year - moduleServices.module5Data.start_year) + 1 : 0} Years</span>
                    </td>
                    <td>
                      <span>{moduleServices.module5Data.earningsOnEmployerContributionsAfter !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.earningsOnEmployerContributionsAfter} prefix={moduleServices.module5Data.earningsOnEmployerContributionsAfter >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.earningsOnEmployerContributionsAfter < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>{moduleServices.module5Data.earningsOnEmployerContributionsAfterRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.earningsOnEmployerContributionsAfterRoth} prefix={moduleServices.module5Data.earningsOnEmployerContributionsAfterRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.earningsOnEmployerContributionsAfterRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Total Employer Contributions & Earnings
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.totalEmployerContributionAndEarnings !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.totalEmployerContributionAndEarnings} prefix={moduleServices.module5Data.totalEmployerContributionAndEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.totalEmployerContributionAndEarnings < 0 && ')'} />
                        ) : ('$0')}  
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.totalEmployerContributionAndEarningsRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.totalEmployerContributionAndEarningsRoth} prefix={moduleServices.module5Data.totalEmployerContributionAndEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.totalEmployerContributionAndEarningsRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      401(k) Balance before <span onClick={ () => handleDefinationPopup('Vesting') }>Vesting</span> Reductions
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdv !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdv} prefix={moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdv < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth} prefix={moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.balanceBeforeVestingReductionsCareerAdvRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      % of Employer Contributions & Earnings Not Vested
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.employerContributionsAndEarningsNotVested !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.employerContributionsAndEarningsNotVested} prefix={moduleServices.module5Data.employerContributionsAndEarningsNotVested >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.employerContributionsAndEarningsNotVested < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.employerContributionsAndEarningsNotVestedRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.employerContributionsAndEarningsNotVestedRoth} prefix={moduleServices.module5Data.employerContributionsAndEarningsNotVestedRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.employerContributionsAndEarningsNotVestedRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                  <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                    <td>
                      Ending 401(k) Balance in Year {(moduleServices.module5Data.end_year > 0 ) ? moduleServices.module5Data.end_year : 0}
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.endingBalanceInyearLast !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.endingBalanceInyearLast} prefix={moduleServices.module5Data.endingBalanceInyearLast >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.endingBalanceInyearLast < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                    <td>
                      <span>
                        {moduleServices.module5Data.endingBalanceInyearLastRoth !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.endingBalanceInyearLastRoth} prefix={moduleServices.module5Data.endingBalanceInyearLastRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.endingBalanceInyearLastRoth < 0 && ')'} />
                        ) : ('$0')}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
      </div>
  </div>

  );
};

RetirementSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( RetirementSummary );
