import React, { useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import { withRouter } from 'react-router-dom';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import {
  Button,
  Typography,
} from '@material-ui/core';
import styles from '../styles';
import Pageloader from '../../../ui/pageloader';
import globalStyles from '../../../layout/globalStyles';
import combineStyles from '../../../../utilities/combineStyles';
import Logo from '../../../../assets/img/login/logo.png';
import { useLazyQuery } from '@apollo/react-hooks';
import * as moduleServices from '../../../../calculations/modules-services';

const LOGIN = loader('../../../../graphql/schema/auth/login.graphql');
const LOGIN_DETAILS = loader('../../../../graphql/schema/welcome/get-loginUser-details.graphql');

const LoginForm = (props) => {
  const {
    classes, history,
  } = props;

  const apolloClient = useApolloClient();

  const [loginError, setLoginError] = useState('');
  const [redirect,setRedirect] = useState('');

  const [checkLoginDetails] = useLazyQuery(LOGIN_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      let loginData = response.getLoginUserDetails;
      if (loginData.student === "yes") {
        if (loginData.expired === "no" && loginData.sequence_status !== 6 && loginData.sequence_status !== 0 && loginData.sequence_status === 1) {
          history.push('/new-student-welcome');
        } else {
          if (redirect !== '' && redirect !== undefined && redirect !== null) {
            history.push(redirect);
          } else {
            history.push('/desktop');
          }
        }
      } else {
        if (loginData.expired === "no" && loginData.sequence_status !== 6 && loginData.sequence_status !== 0 && loginData.sequence_status === 1) {
          history.push('/new-member-welcome');
        } else {
          if (redirect !== '' && redirect !== undefined && redirect !== null) {
            history.push(redirect);
          } else {
            history.push('/desktop');
          }
        }
      }

    },
    onError(errors) {
    },

  });

  const postLogin = (postLoginData) => {
    localStorage.setItem('app-token', postLoginData.access_token);
    apolloClient.writeData({ data: { isLoggedIn: true } });
    setRedirect(postLoginData.redirect_page);
    checkLoginDetails();
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Password is required.';
    }

    if (!values.email) {
      errors.email = 'Email is required.';
    }

    return errors;
  };

  const [submitLogin, { loading: loginLoading }] = useMutation(LOGIN, {
    onCompleted(response) {
      postLogin(response.userLogin);
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        <span key={`login-error-${Math.random()}`}>{message}</span>
      ));

      setLoginError(formatedErrors);
    },
  });

  const onSubmit = (values) => {
    submitLogin({
      variables: {
        data: {
          username: values.email,
          password: values.password,
          moduleData: moduleServices.beforeLoginModuleData,
          preset_date: '',
        },
      },
    });
  };

  const redirectToForgotPassword = () => {
    history.push('forgot-password');
  };

  return (
    <Typography variant="body1" component="div" className={classes.loginBg}>
      {loginLoading && <Pageloader loading={loginLoading} />}
      <div>
        <div className={classes.loginForm}>
          <div className={classes.loginFormHeader}>
            <img src={Logo} alt="" />
          </div>
          <Form
            validate={validate}
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
              <form
                className={classes.formContact}
                onSubmit={handleSubmit}
                noValidate
              >
                <div className={classes.loginFields}>
                  <Typography variant="h3">Sign In</Typography>
                  <div className={classes.formGroup}>
                    <Field
                      id="email"
                      placeholder="Username or Email Address"
                      type="text"
                      name="email"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={TextField}
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <Field
                      id="password"
                      placeholder="Password"
                      type="password"
                      name="password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={TextField}
                    />
                  </div>
                  {loginError && <div className={classes.textDanger}> <span>{loginError}</span></div>}
                  <div className={classes.formGroup}>
                    <Button disabled={submitting} variant="contained" type="submit">
                      Sign in
                    </Button>
                  </div>
                  <span aria-hidden="true" className={`${classes.forgotPass} ${classes.forgotPassNew}`} onClick={redirectToForgotPassword}>Forgot Password</span>
                </div>
              </form>
            )}
          />
        </div>

      </div>
    </Typography>
  );
};

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(globalStyles, styles);

const enhance = compose(
  withStyles(combinedStyles),
  withRouter,
);

export default enhance(LoginForm);
