import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruFive = ( props ) => {
  const {
    classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide,
  } = props;

  return (
    <div className={ classNames( classes.pwiWalkthru, classes.pwiWalkthruFive, classes.pwiActiveWalkthru ) }>
      <h4>Activity Icons</h4>
      <p>At the bottom of the navigation menu, you can save your inputs or load an existing scenario. You can visit the dashboard or view your summary documents such as the income statement or balance sheet to track your net worth. Plus, head back to your Timeline Sketcher to see your big picture or use the Simulator Panel to mix and match scenarios without leaving the page.</p>
      <div className={ classes.walkthruBottom }>
        <WalkThruDots completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } handleWalkThrus={ handleWalkThrus } />
        <div className={ classes.walkThruBtns }>
          <Button className={ classes.startWalkThru } onClick={ () => { handleWalkThrus( 5 ); } }>Next</Button>
          <Button className={ classes.skipWalkthru } onClick={ handleWalkThruClose }>Close</Button>
        </div>
      </div>
    </div>
  );
};

WalkThruFive.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWalkThrus: PropTypes.func.isRequired,
  handleWalkThruClose: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  activeWalkThruSlide: PropTypes.string.isRequired,
};

export default withStyles( styles )( WalkThruFive );
