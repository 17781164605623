import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import { Link } from 'react-router-dom';
import styles from './styles';

const GoingLongStocks = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Going Long Stocks</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/going-long.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Going Long Stocks
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/going-long-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The most common investment strategy for making money
                          off stocks is going long. The process goes something
                          like this: You think a company's stock price is going
                          to rise over the next one to five years and you want
                          to profit from that rise. You buy the stock today and
                          plan to hold it for the long term (in finance, long
                          term means greater than one year). When the stock
                          price rises over time, you can sell at the higher
                          price and the difference is your profit.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Buy low and sell high is an expression that sums up
                          taking long position in stocks. The goal is to buy the
                          stock when the price is low and sell in the future.
                          The difference between the purchase price and the
                          selling price is your profit before taxes. When you
                          realize a gain making investments, you get charged a{' '}
                          <a
                            href="https://www.investopedia.com/terms/c/capital_gains_tax.asp"
                            target="_blank"
                            rel="noopener noreferrer">
                            capital gains tax.
                          </a>{' '}
                          Basically, you get taxed a percentage of your profit.{' '}
                        </p>

                        <div className={classes.fetext}>
                          <p>
                            Let's say Company A is trading at $67 per share and
                            you believe strongly that the price of the stock is
                            going to rise over the long term. You think the
                            business will make better sales in the future and
                            continue to grow, which should lead to a higher
                            stock price.
                          </p>
                          <p>
                            This is really important to note. With any
                            investment, make sure you do your due diligence and
                            develop what's called an{' '}
                            <a
                              href="https://www.investopedia.com/terms/i/investment-thesis.asp#:~:text=An%20investment%20thesis%20is%20a,investors%20or%20an%20investment%20committee."
                              target="_blank"
                              rel="noopener noreferrer">
                              investment thesis.
                            </a>{' '}
                            In other words, you need to do some research and
                            support your claim as to why you think the price of
                            the stock is going to rise. Otherwise, you're just
                            gambling. And gambling in the stock market is never
                            a prudent strategy.
                          </p>

                          <p>
                            The process for going long and potentially making a
                            profit is:
                            <ol>
                              <li>
                                Buy one share of Company A stock from a{' '}
                                <a
                                  href="https://www.investopedia.com/terms/b/brokerageaccount.asp"
                                  target="_blank"
                                  rel="noopener noreferrer">
                                  brokerage firm
                                </a>{' '}
                                (in finance terms, a brokerage firm is a company
                                that provides access to the stock market),
                              </li>
                              <li>Wait,</li>
                              <li>
                                Company A stock price rises to $140 over the
                                next 13 months,
                              </li>
                              <li>
                                Sell your one share of Company A stock for $140,
                                and
                              </li>
                              <li>
                                Pocket the difference as your profit, or $73
                                before taxes.
                              </li>
                            </ol>
                          </p>

                          <div className={classes.pfImage}>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/going-long-graph.png"
                              alt=""
                            />
                          </div>

                          <p>
                            There are advantages of going long a stock when
                            compared to trying to make short-term profits. When
                            you take a long position, as the name suggests,
                            you're planning to hold the stock for more than a
                            year. The time period you plan to hold the stock is
                            known as the{' '}
                            <a
                              href="https://www.investopedia.com/terms/i/investment_horizon.asp"
                              target="_blank"
                              rel="noopener noreferrer">
                              {' '}
                              investment horizon.
                            </a>{' '}
                            The longer the horizon, the better your chance of
                            making a profit.
                          </p>
                          <p>
                            Just think about that for a second. Why are your
                            chances better over the long term? Because on a
                            day-to-day basis, who knows what's going to happen?
                            The price can go up, down or sideways. No one has a
                            crystal ball to say that a stock price is going to
                            rise or fall tomorrow. And, if someone ever tells
                            you they do know, they're either lying or doing
                            something illegal called{' '}
                            <a
                              href="https://www.investopedia.com/terms/i/insidertrading.asp"
                              target="_blank"
                              rel="noopener noreferrer">
                              insider trading.
                            </a>{' '}
                          </p>
                          <p>
                            Taking a long position in a stock can be a great
                            investment. Making that investment requires a really
                            good investment thesis, a long-term investment
                            horizon and, above all, discipline and patience.
                          </p>
                          <p>
                            <i>
                              Disclaimer: It's important to note that Inkwiry
                              does not provide investment advice. These examples
                              are academic and hypothetical. All investments
                              carry the risk of losing some or all your money
                              and there is no guarantee of earning a return.
                              Consulting a professional or doing your own due
                              diligence is essential before making investment
                              decisions.
                            </i>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
GoingLongStocks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoingLongStocks);
