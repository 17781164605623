import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import compose from 'recompose/compose';
import { Button } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import styles from './common-dailog-style';
import MegaImage from '../../assets/img/life-event-library/mega-menu-pricing_img.png';
import MegaImageRight from '../../assets/img/life-event-library/guest_popup_img_right.png';

const CommonDialogCareer = ( props ) => {
  const { classes, history } = props;

  const goToJoin = () => {
    history.push( '/join' );
  };

  return (
    <div className={ classes.DailogLifeEvent }>
      <Grid container>
        <Grid md={ 5 } sm={ 12 } xs={ 12 }>
          <div className={ classes.continuePlanLeft }>
            <img src={ MegaImage } alt="" />
            <h3>
              Continue to 
              <br />
              {' '}
              Plan With Inkwiry
            </h3>
            <p>and find your own best path into the future. </p>
            <p>For $9, save an unlimited number of scenarios and build your financial life plan for the next 5, 10, 15 years.</p>
            <Button onClick={ goToJoin }>Join for 30 Days</Button>
            <Link to="/finance-explained">
              Not right now, but I do want to get
              <br />
              smarter about my money.
            </Link>
          </div>

        </Grid>
        <Grid md={ 7 } sm={ 12 } xs={ 12 }>
          <div className={ classes.continuePlanRight }>
            <img src={ MegaImageRight } alt="" />
            <h2>Plan With Inkwiry</h2>
            <p>
              Know the financial outcome of your
              {' '}
              <br />
              decisions
              <i>
                {' '}
                before
              </i>
              {' '}
              you even make them.
            </p>
            <ul>
              <li>Build a plan, budget, save, invest, repay debt, learn and grow your wealth</li>
              <li>Plan for life events such as higher education, career path, buying a home, buying a car and create Stepping Stones to achieve your goals</li>
              <li>Using Inkwiry&apos;s tools, develop the lifelong skills and knowledge you need to reach financial independence.</li>
            </ul>
            <span>
              I&apos;m already a member,
              <Link to="/login">
                {' '}
                sign in
              </Link>
            </span>
            <span>
              <Link to="/pricing">
                {' '}
                {' '}
                Compare membership levels
              </Link>
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

CommonDialogCareer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( CommonDialogCareer );
