import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import professionalsBanner from '../../assets/img/students-parents/young_professional_banner.png';
import youngProfessional from '../../assets/img/students-parents/young_professional.jpg';

const YoungProfessionals = ( props ) => {
  const { classes, history } = props;
  const [age, setAge] = useState( '' );
  const [studentReadMore, setStudentReadMore] = useState( true );
  const [parentsReadMore, setParentsReadMore] = useState( true );
  const [sectionclass1, setSectionclass1] = useState( false );
  const [sectionclass2, setSectionclass2] = useState( false );
  const [sectionclass3, setSectionclass3] = useState( false );
  const [sectionclass4, setSectionclass4] = useState( false );
  const [sectionclass5, setSectionclass5] = useState( false );
  const [bottomReadMore, setBottomReadMore] = useState( false );
  const youngProfRef = useRef( null );

  const joinInk = bottomReadMore ? `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen} ${classes.studentBottomFixed}` : `${classes.parentsReadmore} ${classes.studentsPageReadmoreOpen}`;
  const ReadMoreCondition = parentsReadMore ? joinInk : classes.studentsPageReadmoreOpen;
  const handleChange = ( event ) => {
    setAge( event.target.value );
    var element = document.getElementById(event.target.value);
    var elemRect = element.offsetTop + 170;
    setTimeout( () => {
      scroller.scrollTo( 'scroll-to-element', {
        duration: 700,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: elemRect,
      } );
    }, 300 );
  };

  useScrollPosition( ( { currPos } ) => {
    if ( youngProfRef.current.getBoundingClientRect( ).top !== 0 && youngProfRef.current.getBoundingClientRect( ).top > 54 ) {
      setParentsReadMore( false );
      setSectionclass1( false );
    }
    if ( youngProfRef.current.getBoundingClientRect( ).top !== 0 && youngProfRef.current.getBoundingClientRect( ).top < 54 && youngProfRef.current.getBoundingClientRect( ).top > -4060 ) {
      setParentsReadMore( true );
    }
    if ( youngProfRef.current.getBoundingClientRect( ).top !== 0 && youngProfRef.current.getBoundingClientRect( ).top < 54 && youngProfRef.current.getBoundingClientRect( ).top > -109 ) {
      setSectionclass2( false );
      setSectionclass1( true );
    } else if ( youngProfRef.current.getBoundingClientRect( ).top !== 0 && youngProfRef.current.getBoundingClientRect( ).top < -359 && youngProfRef.current.getBoundingClientRect( ).top > -984 ) {
      setSectionclass1( false );
      setSectionclass2( true );
      setSectionclass3( false );
    } else if ( youngProfRef.current.getBoundingClientRect( ).top !== 0 && youngProfRef.current.getBoundingClientRect( ).top < -1234 && youngProfRef.current.getBoundingClientRect( ).top > -2109 ) {
      setSectionclass2( false );
      setSectionclass3( true );
      setSectionclass4( false );
    } else if ( youngProfRef.current.getBoundingClientRect( ).top !== 0 && youngProfRef.current.getBoundingClientRect( ).top < -2234 && youngProfRef.current.getBoundingClientRect( ).top > -3109 ) {
      setSectionclass3( false );
      setSectionclass4( true );
      setSectionclass5( false );
    } else if ( youngProfRef.current.getBoundingClientRect( ).top !== 0 && youngProfRef.current.getBoundingClientRect( ).top < -3234 && youngProfRef.current.getBoundingClientRect( ).top > -4060 ) {
      setSectionclass1( false );
      setSectionclass2( false );
      setSectionclass3( false );
      setSectionclass4( false );
      setSectionclass5( true );
    }

    if ( currPos.y > -1500 ) {
      setParentsReadMore( true );
    }
    if ( currPos.y < -5653 && currPos.y > -7000 ) {
      setBottomReadMore( true );
    } else {
      setBottomReadMore( false );
    }
  } );

  const scrollToReadMore = () => {
    setTimeout( () => {
      scroller.scrollTo( 'scroll-to-element', {
        duration: 600,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -57,
      } );
    }, 700 );
    setParentsReadMore( true );
  };

  const handleReadMore = () => {
    setStudentReadMore( false );
    scrollToReadMore();
  };

  const redirectToHHigherEducation = () => {
    history.push( '/plan-with-inkwiry/cp' );
  };

  const redirectToPricing = () => {
    history.push( '/pricing' );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.professionalsPage }>
        <div className={ classes.parentstBanner }>
          <img src={ professionalsBanner } alt="Young Professionals Banner" />
          <div className={ classes.container }>
            <div className={ classes.bannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography variant="h3" component="h3">
                  Inkwiry for
                  <br />
                  {' '}
                  Young Professionals
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.ypContent }>
          <div className={ classes.container }>
            <div className={ classes.ypContentRow }>
              <Typography variant="h2" component="h2">The power of knowing your best path into the future.</Typography>
              <Typography variant="h3" component="h3">Stop worrying and wondering – it&apos;s easy to build a sound strategy.</Typography>
              <Typography variant="p" component="p">
                Hey, where&apos;s the safety net? Admit it, your balancing act of managing career, living expenses and loan payments is getting old … fast.
              </Typography>
              <Typography variant="p" component="p">Where&apos;s the fun in being out on your own? How can you possibly figure out what comes next?</Typography>
              <Typography variant="p" component="p">It&apos;s proven that, if you wish to get control of life events, planning is key. As a matter of fact, just writing down your goals makes it 42% more likely you will achieve them. Successful people everywhere have one thing in common: a strategy and a plan that works for them.</Typography>
              <Typography variant="p" component="p">Are you ready to take control of your future? Inkwiry will help you do the personal and financial planning you never thought possible, and do it quickly, effectively and inexpensively.</Typography>
              <Typography variant="p" component="p">The power of knowing your best path and building a solid strategy can reward you with financial independence, confidence and peace of mind. Join Inkwiry today!</Typography>
              <Typography variant="p" component="p">Test drive the Career Path module and build a financial plan for your career in 15 minutes or less.</Typography>
              <div className={ classes.parantMoreInfo }>
                <Button onClick={ redirectToHHigherEducation } className={ classes.testDriveBtn }>
                  <span name="testDrive">Take a Test Drive</span>
                  <i className="las la-greater-than" />
                </Button>
                <Button className={ classes.moreInfoBtn } onClick={ handleReadMore }>
                  Read More
                  <i className="las la-greater-than" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={ !studentReadMore ? ReadMoreCondition : classes.studentsPageReadmore } name="scroll-to-element">
          <Grid container>
            <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
              <div className={ classes.readmoreLftCnt }>
                <Typography variant="h4" component="h4">Inkwiry for Young Professionals</Typography>
                <ul className={ classes.inkwiryListTab }>
                  <li>
                    <Link
                      to="section1"
                      spy
                      delay={ 0 }
                      smooth
                      duration={ 1000 }
                      offset={ -55 }
                      className={ sectionclass1 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Your future on your own terms.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section2"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass2 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Inkwiry is revolutionary financial planning software.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section3"
                      spy
                      delay={ 0 }
                      smooth
                      duration={ 1000 }
                      offset={ -55 }
                      className={ sectionclass3 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Your data, organized and smarter.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section4"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass4 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>Find your solutions.</p>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="section5"
                      spy
                      delay={ 0 }
                      smooth
                      offset={ -55 }
                      duration={ 1000 }
                      className={ sectionclass5 ? classes.activeClass : classes.unActiveClass }
                    >
                      <span />
                      <p>It all comes down to this.</p>
                    </Link>
                  </li>

                </ul>
                <div className={ classes.selectDiv }>
                  <FormControl className={ classes.formControl }>
                    <Select
                      value={ age }
                      onChange={ handleChange }
                      displayEmpty
                      className={ classes.selectEmpty }
                      inputProps={ { 'aria-label': 'Without label' } }
                    >
                      <MenuItem value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value={ "section1" }>Your future on your own terms.</MenuItem>
                      <MenuItem value={ "section2" }>Inkwiry is revolutionary financial planning software.</MenuItem>
                      <MenuItem value={ "section3" }>Your data, organized and smarter.</MenuItem>
                      <MenuItem value={ "section4" }>Find your solutions.</MenuItem>
                      <MenuItem value={ "section5" }>It all comes down to this.</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>
            </Grid>
            <Grid item xs={ 12 } sm={ 12 } md={ 9 }>
              <div className={ classes.readmoreRightCnt } ref={ youngProfRef }>
                <div className={ classes.readmoreRightCntSec } id="section1">
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">Your future on your own terms.</Typography>
                    <p>A traditional approach to planning is to spend untold hours organizing your data and to pay between $1,500 and $2,500 to hire a financial planner to devise a one-time, static plan.</p>
                    <p>And you&apos;d be on the right track, until now.</p>
                    <p>With Inkwiry, it&apos;s easy to build an effective personal and financial plan that you can update as needed. Best of all, for less than 25 cents per day, you can be in control of your own plan and your own future.</p>
                  </div>
                </div>
                <div className={ classes.readmoreRightCntSec } id="section2">
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">Inkwiry is revolutionary financial planning software for young professsionals.</Typography>
                    <p>If anything can make planning interesting and eye-opening, it&apos;s Inkwiry. (In fact, users say it&apos;s actually fun when you start geeking out with it.)</p>
                    <p>The Financial Configurator does all the hard work for you (and in a click, you can see the calculations, too). You just type in your information, aligned with your goals, to reveal as many different scenarios as you desire. Then compare and contrast to find your best options.</p>
                    <p>The cool thing is your plan is dynamic and can change as your circumstances evolve. Offered a new position? Need further education? Getting married? Just plug in new data and Inkwiry goes to work.</p>
                  </div>
                </div>
                <div className={ classes.testimonialSecDark }>
                  <Typography variant="h5" component="h5">
                    <span />
                    <p>After graduating with tens of thousands of dollars in student loans, I was overwhelmed to say the least. But, once I entered my data into Inkwiry&apos;s Financial Configurator, I discovered that not only could I repay my loans on schedule, I could have additional savings as well.”</p>
                  </Typography>
                  <Typography variant="h6" component="h6">– Alex V., in PhD program, Nursing Practice for Nurse Anesthetists</Typography>
                </div>
                <div className={ classes.readmoreRightCntSec } id="section3">
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">Your data, organized and smarter.</Typography>
                    <p>Can you imagine trying to manually organize your financial data? Right. That&apos;s why you haven&apos;t done it.</p>
                    <p>Inkwiry helps you make sense of your data, identify the things that really matter and organize your information.</p>
                    <p>And here&apos;s where it gets exciting … once entered into Inkwiry, your data becomes smarter – ask it questions and instantly get answers. What if I changed my career path? What if I paid off my loans? What if I saved 10% more?</p>
                  </div>
                </div>
                <div className={ classes.imgBanner }>
                  <img src={ youngProfessional } alt="" />
                </div>
                <div className={ classes.readmoreRightCntSec } id="section4">
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">Find your solutions.</Typography>
                    <Typography variant="h5" component="h5">Inkwiry can help you nail down the answers to your most pressing questions:</Typography>
                    <div className={ classes.solutionsUlist }>
                      <ul>
                        <li>Where will I be in 5, 10, 15 years?</li>
                        <li>Will I ever get past student loan (and other) debt?</li>
                        <li>Can I afford to go on vacation with my friends?</li>
                        <li>Is my career moving in the right direction?</li>
                        <li>What&apos;s my next big move? How will it play out?</li>
                        <li>Should I buy a house?</li>
                        <li>When can I buy that car I want?</li>
                        <li>Do I need more skills and knowledge to master my career?</li>
                        <li>How can I afford graduate school?</li>
                        <li>Am I saving enough?</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={ classes.testimonialSecDark }>
                  <Typography variant="h5" component="h5">
                    <span />
                    <p>I received a couple of job offers across the country – San Francisco and New York City. I didn&apos;t know where to begin to figure out what was best for me. With Inkwiry, I could develop multiple scenarios and career sketches, analyze each job offer and make my best move.”</p>
                  </Typography>
                  <Typography variant="h6" component="h6">– Dean B., Director of Brand Partnerships</Typography>
                </div>

                <div className={ classes.readmoreRightCntSec } id="section5">
                  <div className={ `${classes.whereStartCnt} ${classes.titleFontsize}` }>
                    <Typography variant="h4" component="h4">It all comes down to this.</Typography>
                    <p>In short, Inkwiry allows you to do personal and financial planning you never thought possible, and do it quickly, effectively and inexpensively.</p>
                    <p>Just 20 minutes in Inkwiry can reveal eye-opening insights and solutions, leaving you hungry for more. Run scenarios and compare options. See exactly how your ideas and aspirations add up.</p>
                    <p>Design your dynamic plan for the next 5, 10, 15 years and make your next move with confidence. Over the long run, your $9 investment in Inkwiry can save you a great deal of money, time and uncertainty.</p>
                    <p>All in all, the power of knowing your best path and building a solid strategy can reward you with financial independence, confidence and peace of mind.</p>
                    <Typography variant="h6" component="h6">Now it&apos;s your turn. You&apos;re ready. Sign up today and join Inkwiry.</Typography>
                  </div>
                </div>
                <div className={ classes.joinBtn }>
                  <Button onClick={ redirectToPricing }>
                    JOIN INKWIRY
                    <i className="las la-greater-than" />
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

YoungProfessionals.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( YoungProfessionals );
