import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-career-advancement/styles';

const inputElementArray = ['Rent', 'Utilities', 'Food', 'Clothing', 'Entertainment', 'Technology', 'Transportation', 'Miscellaneous', 'Healthcare Costs other than Premium', 'Health Insurance Premium'];

const CareerBottom = (props) => {
  const { classes, handleCareerObject } = props;
  const [expandLivingExpenses, setExpandLivingExpenses] = React.useState(false);
  const [otherExpenses, setOtherExpenses] = React.useState(false);
  const [tableObj, setTableObj] = React.useState({});

 
  const individualLivingExpensesCalc = function () {
    let incomeSheetData = moduleServices.incomeStatementCompleteData();
    let valueData = {};
    if (incomeSheetData !== undefined && incomeSheetData !== '') {
      inputElementArray.forEach(element => {
        if (incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== ''
          && incomeSheetData[handleCareerObject.start_year] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== undefined && incomeSheetData[handleCareerObject.start_year]['livingExpenses'] !== ''
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== undefined
          && incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element] !== '') {
          valueData[element] = incomeSheetData[handleCareerObject.start_year]['livingExpenses'][element];
        } else {
          valueData[element] = 0
        }

        let yearsAmount = 0;
        let startYear = parseInt(handleCareerObject.start_year);
        let endYear = parseInt(handleCareerObject.end_year);
        for (let i = startYear; i <= endYear; i++) {
          if (incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i] !== undefined && incomeSheetData[i] !== ''
            && incomeSheetData[i]['livingExpenses'] !== undefined && incomeSheetData[i]['livingExpenses'] !== ''
            && incomeSheetData[i]['livingExpenses'][element] !== undefined && incomeSheetData[i]['livingExpenses'][element] !== '') {
            yearsAmount = yearsAmount + incomeSheetData[i]['livingExpenses'][element];
            valueData[element + 'year'] = yearsAmount;
          }
        }
        valueData[element + 'year'] = yearsAmount;
      });

    }
    setTableObj(valueData);
  }
  const d= new Date();

  useEffect(() => {
    individualLivingExpensesCalc();
    // eslint-disable-next-line
  },[handleCareerObject]);
  return (

    <div className={classNames(classes.vacationTable, classes.budgetTable)}>
      <table>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>My Budget</th>
            <th>Monthly</th>
            <th>Annual</th>
            <th>
              {parseInt(handleCareerObject.start_year) > 0 ? parseInt(handleCareerObject.start_year) : d.getFullYear()}
              {' '}
              -
              {' '}
              {parseInt(handleCareerObject.end_year) > 0 ? parseInt(handleCareerObject.end_year) : d.getFullYear()+(2-1) }
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td><b>Total Income</b></td>
            <td>
              <span>
                <b>
                  { moduleServices.module5Data.totalMonthlyIncomeCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.totalMonthlyIncomeCareerAdv } prefix={ moduleServices.module5Data.totalMonthlyIncomeCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.totalMonthlyIncomeCareerAdv < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>
                  { moduleServices.module5Data.totalIncomeCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.totalIncomeCareerAdv } prefix={ moduleServices.module5Data.totalIncomeCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.totalIncomeCareerAdv < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { moduleServices.module5Data.advanceIncomeEarned !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.advanceIncomeEarned } prefix={ moduleServices.module5Data.advanceIncomeEarned >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.advanceIncomeEarned < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Total Taxes Paid
            </td>
            <td>
              <span>

                { moduleServices.module5Data.totalTaxesPaidCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.totalTaxesPaidCareerAdv } prefix={ moduleServices.module5Data.totalTaxesPaidCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.totalTaxesPaidCareerAdv < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </td>
            <td>
              <span>

                { moduleServices.module5Data.annualTotalTaxesPaidCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.annualTotalTaxesPaidCareerAdv } prefix={ moduleServices.module5Data.annualTotalTaxesPaidCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.annualTotalTaxesPaidCareerAdv < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </td>
            <td>
              <span>

                { moduleServices.module5Data.advanceEstimatedTaxesPaid !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.advanceEstimatedTaxesPaid } prefix={ moduleServices.module5Data.advanceEstimatedTaxesPaid >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.advanceEstimatedTaxesPaid < 0 && ')' } />
                ) : ( '$0' )}
              </span>
            </td>
          </tr>
          <tr className={classNames(classes.livingRow, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setExpandLivingExpenses(!expandLivingExpenses)}}>
            <td>
              <span>
                {!expandLivingExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {expandLivingExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Living Expenses</b>
            </td>
            <td>
              <span>
                <b>

                  { moduleServices.module5Data.livingExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.livingExpensesCareerAdv } prefix={ moduleServices.module5Data.livingExpensesCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.livingExpensesCareerAdv < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { moduleServices.module5Data.careerAdvLivingExpenses !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.careerAdvLivingExpenses } prefix={ moduleServices.module5Data.careerAdvLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.careerAdvLivingExpenses < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </td>
            <td>
              <span>
                <b>

                  { moduleServices.module5Data.advanceLivingExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.advanceLivingExpensesPaid } prefix={ moduleServices.module5Data.advanceLivingExpensesPaid >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.advanceLivingExpensesPaid < 0 && ')' } />
                  ) : ( '$0' )}
                </b>
              </span>
            </td>
          </tr>
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Rent
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent / 12} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rent !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rent} prefix={tableObj.Rent >= 0 ? '$' : '($'} suffix={tableObj.Rent < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span />
                <span>

                  {tableObj.Rentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Rentyear} prefix={tableObj.Rentyear >= 0 ? '$' : '($'} suffix={tableObj.Rentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Utilities
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities / 12} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilities !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilities} prefix={tableObj.Utilities >= 0 ? '$' : '($'} suffix={tableObj.Utilities < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Utilitiesyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Utilitiesyear} prefix={tableObj.Utilitiesyear >= 0 ? '$' : '($'} suffix={tableObj.Utilitiesyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Food
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food / 12} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Food !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Food} prefix={tableObj.Food >= 0 ? '$' : '($'} suffix={tableObj.Food < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Foodyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Foodyear} prefix={tableObj.Foodyear >= 0 ? '$' : '($'} suffix={tableObj.Foodyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Clothing & Personal Care
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing / 12} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothing !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothing} prefix={tableObj.Clothing >= 0 ? '$' : '($'} suffix={tableObj.Clothing < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Clothingyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Clothingyear} prefix={tableObj.Clothingyear >= 0 ? '$' : '($'} suffix={tableObj.Clothingyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Entertainment
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment / 12} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainment !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainment} prefix={tableObj.Entertainment >= 0 ? '$' : '($'} suffix={tableObj.Entertainment < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Entertainmentyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Entertainmentyear} prefix={tableObj.Entertainmentyear >= 0 ? '$' : '($'} suffix={tableObj.Entertainmentyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Technology
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology / 12} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technology !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technology} prefix={tableObj.Technology >= 0 ? '$' : '($'} suffix={tableObj.Technology < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Technologyyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Technologyyear} prefix={tableObj.Technologyyear >= 0 ? '$' : '($'} suffix={tableObj.Technologyyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Transportation
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation / 12} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportation !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportation} prefix={tableObj.Transportation >= 0 ? '$' : '($'} suffix={tableObj.Transportation < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Transportationyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Transportationyear} prefix={tableObj.Transportationyear >= 0 ? '$' : '($'} suffix={tableObj.Transportationyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Miscellaneous
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous / 12} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneous !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneous} prefix={tableObj.Miscellaneous >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneous < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj.Miscellaneousyear !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj.Miscellaneousyear} prefix={tableObj.Miscellaneousyear >= 0 ? '$' : '($'} suffix={tableObj.Miscellaneousyear < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Costs outside Premium
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium'] / 12} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premium']} prefix={tableObj['Healthcare Costs other than Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  {tableObj['Healthcare Costs other than Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Healthcare Costs other than Premiumyear']} prefix={tableObj['Healthcare Costs other than Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Healthcare Costs other than Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {expandLivingExpenses && (
            <tr className={classNames(classes.livingRow, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Health Insurance Premium
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium'] / 12} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premium'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premium']} prefix={tableObj['Health Insurance Premium'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premium'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  {tableObj['Health Insurance Premiumyear'] !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={tableObj['Health Insurance Premiumyear']} prefix={tableObj['Health Insurance Premiumyear'] >= 0 ? '$' : '($'} suffix={tableObj['Health Insurance Premiumyear'] < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classNames(classes.otherExpenses, classes.cursorPointer)} aria-hidden="true" onClick={()=>{setOtherExpenses(!otherExpenses)}}>
            <td>
              <span>
                {!otherExpenses && (<i className="fa fa-plus" aria-hidden="true" />)}
                {otherExpenses && (<i className="fa fa-minus" aria-hidden="true" />)}
              </span>
            </td>
            <td className={classes.leftPadding}>
              <b>Other Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                { moduleServices.module5Data.advCareerExpenses !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module5Data.advCareerExpenses}
                    prefix={moduleServices.module5Data.advCareerExpenses >= 0 ? '$' : '($'}
                    suffix={moduleServices.module5Data.advCareerExpenses < 0 && ')'} />
                    ) : ('$0')}
                    </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { moduleServices.module5Data.otherAnnaulLivingExpensesCareerPath !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator
                    value={moduleServices.module5Data.otherAnnaulLivingExpensesCareerPath} prefix={moduleServices.module5Data.otherAnnaulLivingExpensesCareerPath >= 0 ? '$' : '($'}
                    suffix={moduleServices.module5Data.otherAnnaulLivingExpensesCareerPath < 0 && ')'} />
                    ) : ('$0')}
                    </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                { moduleServices.module5Data.otherEducationLivingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.otherEducationLivingExpensesPaid} prefix={moduleServices.module5Data.otherEducationLivingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.otherEducationLivingExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Higher Education Expenses
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.higherEducationExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.higherEducationExpensesCareerAdv} prefix={moduleServices.module5Data.higherEducationExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.higherEducationExpensesCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.careerAdvHigherEducationExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvHigherEducationExpenses} prefix={moduleServices.module5Data.careerAdvHigherEducationExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvHigherEducationExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.advanceHigherEducationExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advanceHigherEducationExpensesPaid} prefix={moduleServices.module5Data.advanceHigherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advanceHigherEducationExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Children Expenses
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.childrenExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.childrenExpensesCareerAdv} prefix={moduleServices.module5Data.childrenExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.childrenExpensesCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.careerAdvChildrenExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvChildrenExpenses} prefix={moduleServices.module5Data.careerAdvChildrenExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvChildrenExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.childrenExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.childrenExpensesPaid} prefix={moduleServices.module5Data.childrenExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.childrenExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
             <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Pet Expenses
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.petExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.petExpensesCareerAdv} prefix={moduleServices.module5Data.petExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.petExpensesCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.careerAdvPetExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvPetExpenses} prefix={moduleServices.module5Data.careerAdvPetExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvPetExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.advancePetExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advancePetExpensesPaid} prefix={moduleServices.module5Data.advancePetExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advancePetExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Car Expenses
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.carExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.carExpensesCareerAdv} prefix={moduleServices.module5Data.carExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.carExpensesCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.careerAdvCarExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvCarExpenses} prefix={moduleServices.module5Data.careerAdvCarExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvCarExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span />
                <span>

                  { moduleServices.module5Data.advanceCarExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advanceCarExpensesPaid} prefix={moduleServices.module5Data.advanceCarExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advanceCarExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          {otherExpenses && (
            <tr className={classNames(classes.otherExpenses, classes.cursorPointer)}>
              <td>&nbsp;</td>
              <td className={classes.leftSubPadding}>
                Real Estate Expenses
              </td>
              <td>
                <span>

                  { moduleServices.module5Data.realEstateExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.realEstateExpensesCareerAdv} prefix={moduleServices.module5Data.realEstateExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.realEstateExpensesCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { moduleServices.module5Data.careerAdvHomeExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvHomeExpenses} prefix={moduleServices.module5Data.careerAdvHomeExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvHomeExpenses < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
              <td>
                <span>

                  { moduleServices.module5Data.advanceRealEstateExpensesPaid !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advanceRealEstateExpensesPaid} prefix={moduleServices.module5Data.advanceRealEstateExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advanceRealEstateExpensesPaid < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td>
            </tr>
          )}
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              Loan Payments
            </td>
            <td>
              <span>

                { moduleServices.module5Data.interestExpensesCareerAdv !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.interestExpensesCareerAdv} prefix={moduleServices.module5Data.interestExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.interestExpensesCareerAdv < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { moduleServices.module5Data.careerAdvFinancingExpenses !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvFinancingExpenses} prefix={moduleServices.module5Data.careerAdvFinancingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvFinancingExpenses < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
            <td>
              <span>

                { moduleServices.module5Data.advanceFinancingExpensesPaid !== undefined ? (
                  <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advanceFinancingExpensesPaid} prefix={moduleServices.module5Data.advanceFinancingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advanceFinancingExpensesPaid < 0 && ')'} />
                ) : ('$0')}
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Total Expenses</b>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { moduleServices.module5Data.totalMonthlyExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.totalMonthlyExpensesCareerAdv} prefix={moduleServices.module5Data.totalMonthlyExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.totalMonthlyExpensesCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { moduleServices.module5Data.careerAdvTotalAnnualExpenses !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvTotalAnnualExpenses} prefix={moduleServices.module5Data.careerAdvTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvTotalAnnualExpenses < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { moduleServices.module5Data.totalExpensesCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.totalExpensesCareerAdv} prefix={moduleServices.module5Data.totalExpensesCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.totalExpensesCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td>
              <b>Cash Flow before Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { moduleServices.module5Data.monthlyNetIncomeCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.monthlyNetIncomeCareerAdv} prefix={moduleServices.module5Data.monthlyNetIncomeCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.monthlyNetIncomeCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { moduleServices.module5Data.annualNetIncomeCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.annualNetIncomeCareerAdv} prefix={moduleServices.module5Data.annualNetIncomeCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.annualNetIncomeCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>

                  { moduleServices.module5Data.advanceNetIncome !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advanceNetIncome} prefix={moduleServices.module5Data.advanceNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advanceNetIncome < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>

          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>Retirement Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { moduleServices.module5Data.monthlyRetirementContributionsCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.monthlyRetirementContributionsCareerAdv} prefix={moduleServices.module5Data.monthlyRetirementContributionsCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.monthlyRetirementContributionsCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { moduleServices.module5Data.annualRetirementContributionsCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.annualRetirementContributionsCareerAdv} prefix={moduleServices.module5Data.annualRetirementContributionsCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.annualRetirementContributionsCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { moduleServices.module5Data.finalRetirementContributionsCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.finalRetirementContributionsCareerAdv} prefix={moduleServices.module5Data.finalRetirementContributionsCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.finalRetirementContributionsCareerAdv < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.grayRow}>
            <td>&nbsp;</td>
            <td>
              <b>HSA Contributions</b>
            </td>
            <td>
              <span />
              <span>
                <b>
                  {moduleServices.module5Data.careerAdvHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvHSAContributions / 12} prefix={moduleServices.module5Data.careerAdvHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  {moduleServices.module5Data.careerAdvHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.careerAdvHSAContributions} prefix={moduleServices.module5Data.careerAdvHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.careerAdvHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
            <td>
              <span />
              <span>
                <b>
                  { moduleServices.module5Data.finalcareerAdvHSAContributions !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.finalcareerAdvHSAContributions} prefix={moduleServices.module5Data.finalcareerAdvHSAContributions >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.finalcareerAdvHSAContributions < 0 && ')'} />
                  ) : ('$0')}
                </b>
              </span>
            </td>
          </tr>
          <tr className={classes.blueRow}>
            <td>&nbsp;</td>
            <td className={classes.leftPadding}>
              <i><b>% of Total Income</b></i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( moduleServices.module5Data.totalMonthlyIncomeCareerAdv !== 0 && moduleServices.module5Data.monthlyNetIncomeCareerAdv !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module5Data.totalMonthlyIncomeCareerAdv / moduleServices.module5Data.monthlyNetIncomeCareerAdv) * 100} prefix={moduleServices.module5Data.totalMonthlyIncomeCareerAdv / moduleServices.module5Data.monthlyNetIncomeCareerAdv >= 0 ? '' : '('} suffix={moduleServices.module5Data.totalMonthlyIncomeCareerAdv / moduleServices.module5Data.monthlyNetIncomeCareerAdv < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( moduleServices.module5Data.totalIncomeCareerAdv !== 0 && moduleServices.module5Data.annualNetIncomeCareerAdv !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module5Data.totalIncomeCareerAdv / moduleServices.module5Data.annualNetIncomeCareerAdv) * 100} prefix={moduleServices.module5Data.totalIncomeCareerAdv / moduleServices.module5Data.annualNetIncomeCareerAdv >= 0 ? '' : '('} suffix={moduleServices.module5Data.totalIncomeCareerAdv / moduleServices.module5Data.annualNetIncomeCareerAdv < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
            <td>
              <i>
                <b>
                  <span />
                  <span> {( moduleServices.module5Data.finalNetCashFlowCareerAdv !== 0 &&  moduleServices.module5Data.advanceIncomeEarned !== 0)  ? (
                    <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(moduleServices.module5Data.finalNetCashFlowCareerAdv / moduleServices.module5Data.advanceIncomeEarned) * 100} prefix={moduleServices.module5Data.finalNetCashFlowCareerAdv / moduleServices.module5Data.advanceIncomeEarned >= 0 ? '' : '('} suffix={moduleServices.module5Data.finalNetCashFlowCareerAdv / moduleServices.module5Data.advanceIncomeEarned < 0 ? '%)' : '%'} />
                  ) : ('0.00%')}</span>
                </b>
              </i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

CareerBottom.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,

};
export default withStyles(styles)(CareerBottom);
