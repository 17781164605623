import TickMark from '../../../../assets/img/pwi-fi/tick_mark_blue.png';
import greenCheckDiscovery from '../../../../assets/img/questions/green-check-discovery.png';
import oneBefore from '../../../../assets/img/questions/1-before.png';
import oneAfter from '../../../../assets/img/questions/1-after.png';
import twoBefore from '../../../../assets/img/questions/2-before.png';
// import twoAfter from '../../../../assets/img/questions/2-after.png';
import threeBefore from '../../../../assets/img/questions/3-before.png';
import threeAfter from '../../../../assets/img/questions/3-after.png';
import fourBefore from '../../../../assets/img/questions/4-before.png';
import fourAfter from '../../../../assets/img/questions/4-after.png';
import fiveBefore from '../../../../assets/img/questions/5-before.png';
import fiveAfter from '../../../../assets/img/questions/5-after.png';
import sixBefore from '../../../../assets/img/questions/6-before.png';
import sixAfter from '../../../../assets/img/questions/6-after.png';
import sevenBefore from '../../../../assets/img/questions/7-before.png';
import sevenAfter from '../../../../assets/img/questions/7-after.png';
import eightBefore from '../../../../assets/img/questions/8-before.png';
import eightAfter from '../../../../assets/img/questions/8-after.png';
import nineBefore from '../../../../assets/img/questions/9-before.png';
import nineAfter from '../../../../assets/img/questions/9-after.png';
import tenBefore from '../../../../assets/img/questions/10-before.png';
import tenAfter from '../../../../assets/img/questions/10-after.png';

const styles = (theme) => ({
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
    color: '#333',
  },
  fiHeader: {
    position: 'relative',
  },
  MarginTopZero: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '25%',
      padding: '10px',
    }
  },
  fiHeaderInner: {
    display: 'flex',
    paddingTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #006547',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: '300',
      color: theme.palette.common.black,
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      maxWidth: '500px',
      width: '100%',
      '& > img': {
        marginRight: '25px',
        height: '50px',
        cursor: 'pointer',
        verticalAlign: 'middle',
        [theme.breakpoints.down('xs')]: {
          height: '40px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
    '@media (max-width: 767px)': {
      width: '20px',
      '& span': {
        height: '3px',
        width: '20px',
      }
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '19px',
        lineHeight: '20px',
      },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transiction: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
    '@media (max-width: 767px)': {
      margin: '0 auto 0',
    },
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
      color: '#333',
      lineHeight: '25px',
    },
    '& p': {
      margin: '20px 0 10px 0',
      '@media (max-width: 767px)': {
        margin: '15px 0 0 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& ul': {
      listStyle: 'none',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '0px',
      },
      '& li': {
        backgroundImage: `url(${TickMark})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '11px',
        backgroundPosition: 'left top 8px',
        display: 'flex',
        paddingLeft: '35px',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
          paddingLeft: '20px',
          fontSize: '18px',
        },
      },
      '& select': {
        background: '#fbfdfd',
        border: 'none',
        outline: '0',
        borderBottom: '2px solid #337ab7',
        color: '#337ab7',
        textAlign: 'center',
        padding: '0 20px',
        height: '29px',
        cursor: 'pointer',
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-300',
        fontSize: '21px',
        [theme.breakpoints.down('xs')]: {
          fontSize: '18px',
        },
      },
    },

    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
  },
  storyBox: {
    borderRadius: '2px',
    padding: '40px',
    border: '1px solid #337ab7',
    textAlign: 'center',
    margin: '36px',
    cursor: 'pointer',
  },
  startButton: {
    paddingTop: '40px',
    float: 'right',
    '& button': {
      padding: '7px 40px',
      background: '#337ab7',
      textTransform: 'uppercase',
      border: '1px solid #337ab7',
      color: '#fff',
      borderRadius: '4px',
      fontSize: '17px',
      marginBottom: '20px',
      boxShadow: 'none',
      cursor: 'pointer',
      outline: 'none',
      letterSpacing: '1px',
      '&:hover': {
        background: '#f69a34',
        border: '1px solid #f69a34',
      },
    },
  },
  topImage: {
    '& img': {
      width: '100%',
      position: 'absolute',
      zIndex: '0',
      verticalAlign: 'middle',
      [theme.breakpoints.down('sm')]: {
        height: '240px',
      },
      [theme.breakpoints.down('xs')]: {
        background: '#004375',
        height: '180px',
      },
    },
  },
  mcsRow: {
    display: 'flex',
    padding: '20px 0 0',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      padding: '10px 0 0px 0px',
      alignItems: 'baseline',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mcsFooter: {
    padding: '20px 0 15px',
    background: '#252525!important',
    alignItems: 'center',
    height: 'auto',
    color: '#fff',
    float: 'left',
    fontSize: '14px',
    left: '0',
    width: '100%',
    '& p': {
      marginTop: '0px',
    },
  },
  mcsFluid: {
    width: '100%',
    borderTop: '1px solid #555',
    margin: '0 auto',
    paddingRight: '15px',
    paddingLeft: '15px',
    boxSizing:'border-box',
  },
  termLinks: {
    color: '#fff',
    marginBottom: '0',
    padding: '0px',
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '16px',
    '& a': {
      color: '#fff',
      textDecoration: 'underline',
      fontSize: '16px!important',
      '&:hover': {
        color: '#f89b22',
        textDecoration: 'underline',
      },
    },
  },
  footerRow: {
    display: 'inline-flex',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
    },
  },
  copyRight: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    '& p': {
      textAlign: 'left',
      fontFamily: 'MuseoSans-100',
      fontSize: '16px',
      color: '#fff',
      margin: '0',
      lineHeight: '35px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
        fontSize: '15px',
      },
    },
  },
  informedText: {
    '& p': {
      textAlign: 'right',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
      '& a': {
        '& img': {
          marginLeft: '8px',
          maxWidth: '100px',
          verticalAlign: 'top',
          border: '0',
        },
      },
    },
  },
  goButton: {
    padding: '1px 22px',
    textTransform: 'uppercase',
    marginLeft: '10px',
    fontSize: '13px',
    borderRadius: '10px',
    borderWidth: '2px',
    transition: 'all .5s ease 0s',
    WebkitTransition: 'all .5s ease 0s',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    '&:hover': {
      background: '#8ca659',
      borderColor: '#8ca659',
    },
  },
  questionVideoSec: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0',
    width: '100%',
    '& video': {
      position: 'absolute',
      top: '-90px',
      left: '0',
      width: '100%',
      height: ' 100%',
      border: 'none',
      '@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        top: '0px',
      },
      [theme.breakpoints.down('md')]: {
        top: '0px',
      },
    },
  },
  bannerVideo: {
    overflow: 'hidden',
    position: 'relative',
    height: 'auto',
    maxHeight: 'calc(100vh - 69px)',
    '@media only screen and (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      maxHeight: '100%',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
    },
  },
  bigQuestionCnt: {
    background: theme.palette.common.greenlight,
    textAlign: 'center',
    padding: '80px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '50px 0',
    },
    '& h2': {
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      fontWeight: '400',
      lineHeight: '45px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '26px',
        lineHeight: '40px',
      },
      '& span': {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
          display: 'initial',
        },
      },
    },
    '& p': {
      fontSize: '24px',
      paddingTop: '50px',
      lineHeight: '35px',
      fontFamily: '"MuseoSans-100"',
      maxWidth: '800px',
      margin: '0px auto',
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        paddingTop: '20px',
        lineHeight: '26px',
      },
    },
  },
  zoomDetails: {
    background: theme.palette.common.blue,
    padding: '32px 0',
    textAlign: 'center',
    position: 'relative',
  },
  greenLineArrow: {
    padding: '60px 130px 60px 120px',
    [theme.breakpoints.down('md')]: {
      padding: '130px 30px 60px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '60px 30px 25px 30px',
    },
    '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      padding: '70px 30px 30px 30px !important',
    },
    '@media screen and (min-width: 768px) and (max-width: 992px)': {
      padding: '130px 30px 0px 30px',
    },
    '@media screen and (min-width: 600px) and (max-width: 767px)': {
      padding: '190px 30px 10px 30px',
    },
    '@media screen and (min-width: 361px) and (max-width: 420px)': {
      padding: '158px 30px 25px 30px',
    },
    '@media (max-width: 360px)': {
      padding: '190px 30px 25px 30px',
    },
    '& figure': {
      margin: '0px',
      '& img': {
        width: '100%',
      },
    },
  },
  buildSketchSec: {
    position: 'absolute',
    top: '60px',
    width: '60%',
    left: '20%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      left: '10%',
      right: '10%',
    },
    '@media (max-width: 767px)': {
      width: '80%',
      left: '10%',
      right: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      left: '5%',
      right: '5%',
      top: '30px',
    },
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      fontWeight: '400',
      fontSize: '36px',
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
    },
    '& p': {
      fontSize: '26px',
      margin: '20px auto 0px',
      fontFamily: '"MuseoSans-100"',
      fontWeight: '400',
      lineHeight: '35px',
      color: theme.palette.common.white,
      maxWidth: '750px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
  },
  puzzleIconSectn: {
    position: 'relative',
  },
  puzzleLiveWork: {
    boxShadow: '0 0 18px #999',
    width: '62%',
    margin: '-150px auto 0',
    background: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      margin: '-70px auto 0',
      width: '90%',
    },
  },
  puzzleLiveWorkDiv: {
    padding: '15px 60px 50px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 20px 50px',
    },
  },
  puzzlePieceIcn: {
    '& figure': {
      margin: '0px',
      '& img': {
        width: '100px',
        display: 'flex',
        margin: '0 auto',
      },
    },
  },
  uzzleLivCnt: {
    '& h3': {
      fontSize: '25px',
      color: theme.palette.common.black,
      fontWeight: '400',
      fontFamily: 'MuseoSans-500',
      margin: '40px 0 20px',
    },
    '& p': {
      marginBottom: '20px',
      fontSize: '16px',
      lineHeight: '25px',
      fontFamily: 'MuseoSans-100',
      color: theme.palette.common.black,
    },
  },
  uzzleLivCntTwo: {
    '& h3': {
      color: theme.palette.common.black,
      fontSize: '18px',
      fontWeight: '400',
      fontFamily: 'MuseoSans-500',
      margin: '40px 0 20px',
    },
    '& p': {
      marginBottom: '20px',
      fontSize: '16px',
      lineHeight: '25px',
      fontFamily: 'MuseoSans-100',
      color: theme.palette.common.black,
    },
    '& ul': {
      padding: '0px',
      listStyle: 'decimal',
      paddingLeft: '15px',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '20px',
      },
      '& li': {
        fontSize: '15px',
        lineHeight: '25px',
        fontFamily: 'MuseoSans-100',
        marginBottom: '5px',
        color: theme.palette.common.black,
      },
    },
  },
  discoveryCnt: {
    padding: '30px 0 35px',
  },
  displayNone:{
    display: 'none',
  },
  quickstartMainCircle: {
    maxWidth: '436px',
    margin: '0 auto',
    position: 'relative',
    zIndex: '9',
    '& figure': {
      margin: '0px',
      '& img': {
        width: '100%',
        borderRadius: '50%',
      },
    },
  },
  quickstartMainCircleActive: {
    '& figure': {
      margin: '0px',
      '& img': {
        border: '0px solid transparent',
        boxShadow: '0 0 0 15px #7db0cd',
      },
    },
  },
  quickstartCriclesBlock: {
    margin: '30px auto 0',
    position: 'relative',
    width: '1170px',
    transform: 'scale(0.84)',   
    height: '480px',
    [theme.breakpoints.down('md')]: {
      left: '0px',
      top: '0px',
      width: '100%',
      height: 'auto',
      transform: 'none',
    },
  },
  tooltipPopup: {
    left: '20px',
    top: '-115px',
    maxWidth: '294px',
    borderRadius: '20px',
    position: 'absolute',
    borderColor: theme.palette.common.blue,
    border: '3px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    borderStyle: 'solid',
    zIndex: '999',
    [theme.breakpoints.down('md')]: {
      left: 'auto',
      top: '0px',
      right: 'auto',
    },
    '&:before': {
      content: '""',
      borderWidth: '31px 31px 0 30px',
      borderColor: '#fff transparent',
      bottom: '-27px',
      left: 'auto',
      right: '63px',
      top: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
      width: '0',
      height: '0',
      transition: 'none',
      zIndex: '999',
    },
    '&:after': {
      content: '""',
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent',
      bottom: '-32px',
      left: 'auto',
      right: '63px',
      top: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
      width: '0',
      height: '0',
      transition: 'none',
      zIndex: '998',
    },
    '& p': {
      lineHeight: '1.3',
      fontSize: '20px',
      padding: '10px 20px',
      color: theme.palette.common.black,
      margin: '0px',
    },
  },
  quickstartCircle: {
    padding: '0px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingTop: '140px',
      display: 'inline-block',
      width: '100%',
    },
    '& li': {
      display: 'inline-block',     
      width: '250px',
      height: '250px',
      borderRadius: '100%',
      textAlign: 'center',
      border: 'none',
      // transition: 'all .5s ease 0s',
      // MozTransition: 'all .5s ease 0s',
      // WebkitTransition: 'all .5s ease 0s',
      margin: '0 40px 0px 0px',
      '&:last-child': {
        marginRight: '0px',
      },
      [theme.breakpoints.down('md')]: {
        position: 'initial',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'inline-grid',
      },
      '& a': {
        padding: '12px 7px',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
        },
        '& h3': {
          fontFamily: '"MuseoSans-900"',
          fontSize: '70px',
          lineHeight: '70px',
          margin: '0',
        },
        '& h4': {
          color: theme.palette.common.white,
          fontSize: '23px',
          lineHeight: '27px',
          fontFamily: 'MuseoSans-100',
        },
      },
    },
  },
  spanBlock: {
    display: 'block',
  },
  circleOne: {
    top: '20px',
    left: '0',
    zIndex: '1',
    background: '#0069aa',
    '&:hover': {
      transform: 'scale(1.2)',
      MozTransform: 'scale(1.2)',
      WebkitTransform: 'scale(1.2)',
      zIndex: '999',
    },
    '& h3': {
      color: '#83a74c',
    },
  },
  circleOneActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(-30deg)',
      zIndex: '-99999',
      left: '180px',
      right: 'auto',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: '95px',
      content: `url(${oneBefore})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      top: '149px',
      transform: 'scale(0.55)',
      width: '0',
      left: '200px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      content: `url(${oneAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },

  circleTwo: {
    // left: '112px',
    // top: '200px',
    background: 'rgba(143, 176, 94, 0.9)',
    '&:hover': {
      transform: 'scale(1.2)',
      MozTransform: 'scale(1.2)',
      WebkitTransform: 'scale(1.2)',
      zIndex: '999',
    },
    '& h3': {
      color: '#006838',
    },
  },
  circleTwoActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(-12deg)',
      zIndex: '-2',
      left: '459px',
      right: 'auto',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 209px)',
      content: `url(${twoBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.62)',
      width: '0',
      left: 'auto',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '17px',
      content: `url(${threeAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },

  circleThree: {
    // left: '235px',
    // top: '20px',
    zIndex: '1',
    background: 'rgba(70, 158, 213, 0.9)',
    '&:hover': {
      transform: 'scale(1.2)',
      MozTransform: 'scale(1.2)',
      WebkitTransform: 'scale(1.2)',
      zIndex: '999',
    },
    '& h3': {
      color: '#83a74c',
    },
  },
  circleThreeActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(130deg)',
      zIndex: '-2',
      left: 'auto',
      right: '345px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - -350px)',
      content: `url(${threeBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      top: '160px',
      transform: 'scale(0.55) rotate(45deg)',
      width: '0',
      left: 'auto',
      right: '427px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '20px',
      content: `url(${threeAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  circleFour: {
    left: '350px',
    top: '200px',
    background: 'rgba(238, 125, 72, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#bf4927',
    },
  },
  circleFourActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: 'auto',
      height: 'auto',
      position: 'absolute',
      width: '0',
      right: '0',
      bottom: 'calc(100% - 84px)',
      content: `url(${fourBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      width: '0',
      left: '-45px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '18px',
      content: `url(${fourAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  circleFive: {
    left: '474px',
    top: '20px',
    zIndex: '1',
    background: 'rgba(246, 157, 67, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#f05a28',
    },
  },
  circleFiveActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: 'auto',
      height: 'auto',
      position: 'absolute',
      width: '0',
      right: '10px',
      bottom: 'calc(100% - 60px)',
      content: `url(${fiveBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      width: '0',
      left: '-175px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '30px',
      content: `url(${fiveAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  circleSix: {
    left: '590px',
    top: '200px',
    background: 'rgba(204, 109, 82, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#cea348',
    },
  },
  circleSixActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '-330px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 60px)',
      content: `url(${sixBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.62)',
      width: '0',
      left: 'auto',
      right: '30px',
      height: '0',
      zIndex: '-2',
      position: 'absolute',
      bottom: '94px',
      content: `url(${sixAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  circleSeven: {
    left: '708px',
    top: '20px',
    zIndex: '1',
    background: 'rgba(159, 184, 147, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#006838',
    },
  },
  circleSevenActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(5deg)',
      zIndex: '-2',
      left: 'auto',
      right: '110px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - -5px)',
      content: `url(${sevenBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '70px',
      height: '0',
      position: 'absolute',
      bottom: '40px',
      content: `url(${sevenAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  circleEight: {
    left: '830px',
    top: '200px',
    background: 'rgba(40, 168, 183, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#006aab',
    },
  },
  circleEightActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '0',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: '210px',
      content: `url(${eightBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '-80px',
      height: '0',
      position: 'absolute',
      bottom: '-10px',
      content: `url(${eightAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  circleNine: {
    left: '946px',
    top: '20px',
    zIndex: '1',
    background: 'rgba(42, 86, 128, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#83a74c',
    },
  },
  circleNineActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '-140px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 15px)',
      content: `url(${nineBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '-170px',
      height: '0',
      position: 'absolute',
      bottom: '0px',
      content: `url(${nineAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  circleTen: {
    left: '1050px',
    top: '200px',
    background: 'rgba(211, 172, 90, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#bf4927',
    },
  },
  circleTenActive: {
    border: '7px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    zIndex: 'auto',
    '&:hover': {
      transform: 'none',
      MozTransform: 'none',
      WebkitTransform: 'none',
      zIndex: 'auto',
    },
    '&:before': {
      transform: 'scale(0.55) rotate(0deg)',
      zIndex: '-2',
      left: '-220px',
      height: 'auto',
      position: 'absolute',
      width: '0',
      bottom: 'calc(100% - 15px)',
      content: `url(${tenBefore})`,
      transformOrigin: 'left bottom',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    '&:after': {
      transform: 'scale(0.55)',
      zIndex: '-2',
      width: '0',
      left: '-260px',
      height: '0',
      position: 'absolute',
      bottom: '-7px',
      content: `url(${tenAfter})`,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  videoDownArrow: {
    position: 'absolute',
    width: '80px',
    height: '50px',
    bottom: '40px',
    margin: 'auto',
    left: '0',
    right: '0',
    borderRadius: '25px',
    textAlign: 'center',
    cursor: 'pointer',
    zIndex: '3',
    '@media (max-width: 450px)': {
      bottom: '0px',
    },
    '& i': {
      zIndex: '99',
      fontSize: '80px',
      width: '80px',
      height: '80px',
      color: '#fff',
      position: 'relative',
      WebkitAnimation: ' myfirst 1.5s infinite',
      WebkitAnimationDirection: 'alternate',
      animation: 'myfirst 1s infinite',
      animationDirection: 'alternate',
      fontFamily: 'fontawesome',
      '&:hover': {
        color: theme.palette.common.orange,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '60px',
      },
    },
  },
  quickstartCriclesBtm: {
    position: 'relative',
    top: '0',
    [theme.breakpoints.down('md')]: {
      top: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      top: '20px',
    },
  },
  quickstartCareer: {
    border: '0px solid transparent',
    boxShadow: '0 0 0 15px #7db0cd',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100%',
    width: '268px',
    height: '268px',
    background: '#e9e9ea',
    margin: '0 auto',
    position: 'relative',
    '& h3': {
      margin: 'auto',
      fontSize: '27px',
      lineHeight: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.blue,
    },
    '& figure': {
      position: 'absolute',
      bottom: '-60px',
      maxWidth: '85px',
      left: '50%',
      margin: '0px 0px 0px -44px',
      '& img': {
        width: '100%',
      },
    },
  },
  spanBlockTwo: {
    display: 'block',
    color: theme.palette.common.blue,
  },
  spanBoldTwo: {
    display: 'inline',
    fontFamily: '"MuseoSans-900"',
    fontSize: '37px',
    lineHeight: '37px',
    color: theme.palette.common.blue,
  },
  spanBoldText: {
    display: 'inline',
    fontFamily: '"MuseoSans-500"',
    fontSize: '15px',
    lineHeight: '37px',
    fontWeight: '400',
    color: theme.palette.common.black,
  },
  quickstartCricleBbtmCnt: {
    paddingTop: '90px',
    position: 'relative',
    margin: '0 auto',
    marginTop: '50px',
  },
  quickstartInput: {
    maxWidth: '700px',
    display: 'table',
    width: '100%',
    margin: '-120px auto 30px',
  },
  qestionInputTop: {
    width: '50%',
    display: 'inline-block',
    float: 'left',
    fontSize: '24px',
    lineHeight: '24px',
    verticalAlign: 'middle',
    margin: '0',
    position: 'relative',
    color: theme.palette.common.blue,
    textAlign: 'center',
    '& input': {
      position: 'absolute',
      width: '0px',
      height: '0px',
      borderRadius: '50%',
      margin: '0px',
      opacity: '0',
    },
    '& [type="radio"]:not(:checked)+label:after': {
      opacity: '0',
      transform: 'scale(0)',
    },
    '& label': {
      display: 'table',
      margin: 'auto auto 15px',
      position: 'relative',
      fontFamily: '"MuseoSans-100"',
      fontSize: '26px',
      width: '40px',
      height: '40px',
      border: '2px solid #0069aa',
      borderRadius: '50%',
      cursor: 'pointer',
      animation: 'pulse-animation 2s infinite',
      '&:before': {
        content: '""',
        // backgroundImage: `url(${greenCheckDiscovery})`,
        width: '20px',
        height: '26px',
        position: 'absolute',
        left: '10px',
        top: '7px',
        backgroundSize: '17px',
        backgroundRepeat: 'no-repeat',
        transition: 'all 0.3s ease-in-out',
      },
    },
    '& input[type="radio"]:checked+label:after': {
      opacity: '1',
      transform: 'scale(1)',
    },
  },
  mainOptionSelect: {
    '&:before': {
      backgroundImage: `url(${greenCheckDiscovery})`,
    },
  },
  largeStripeBar: {
    padding: '10px',
    background: '#00A1E1',
    borderRadius: '6px',
    color: theme.palette.common.white,
    fontSize: '24px',
    lineHeight: '24px',
    margin: '0',
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-300"',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      lineHeight: '28px',
    },
  },
  questionNumberDis: {
    position: 'relative',
    display: 'flex',
    '& span': {
      lineHeight: '70px',
      fontSize: '50px',
      fontFamily: '"MuseoSans-700"',
      width: '70px',
      display: 'inline-block',
      height: '70px',
      borderRadius: '50%',
      textAlign: 'center',
      marginTop: '34px',
      position: 'relative',
      left: '50px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        left: '0px',
        width: '40px',
        height: '40px',
        lineHeight: '40px',
      },
    },
    '& h4': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '28px',
      maxWidth: '900px',
      margin: '20px auto 20px',
      lineHeight: '1.3',
      color: theme.palette.common.blue,
      [theme.breakpoints.down('md')]: {
        margin: '20px 0px 20px auto',
        width: 'calc(100% - 180px)',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
        width: 'calc(100% - 70px)',
      },
    },
  },
  circleSpanOne: {
    background: theme.palette.common.blue,
    color: '#83a74c',
  },
  circleSpanTwo: {
    background: '#83a74c',
    color: '#006838',
  },
  circleSpanThree: {
    background: theme.palette.common.blue,
    color: '#83a74c',
  },
  circleSpanFour: {
    background: '#ee7d48',
    color: '#bf4927',
  },
  circleSpanFive: {
    background: '#f69d43',
    color: '#f05a28',
  },
  circleSpanSix: {
    background: '#cc6d52',
    color: '#cea348',
  },
  circleSpanSeven: {
    background: '#9fb893',
    color: '#006838',
  },
  circleSpanEight: {
    background: '#28a8b7',
    color: '#006aab',
  },
  circleSpanNine: {
    background: '#2a5680',
    color: '#83a74c',
  },
  circleSpanTen: {
    background: '#d3ac5a',
    color: '#bf4927',
  },
  quickstartCriclesBtmCnt: {
    padding: '0 0 0px 156px',
    maxWidth: '1100px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 0px 0px',
    },
    '& li': {
      marginBottom: '20px',
      display: 'block',
      fontSize: '20px',
      lineHeight: '20px',
      color: '#000',
      clear: 'both',
    },
  },
  qestionInputHeading: {
    fontSize: '15px',
    width: '190px',
    display: 'flex',
    lineHeight: '24px',
    verticalAlign: 'middle',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
    '& span': {
      float: 'left',
      textAlign: 'center',
      padding: '0 5px',
      color: theme.palette.common.blue,
      width: '33.333%',
    },
  },
  qestionInCnt: {
    width: 'calc(100% - 240px)',
    color: theme.palette.common.blue,
    display: 'inline-block',
    fontSize: '24px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    margin: '0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: '26px',
      width: 'calc(100% - 140px)',
    },
  },
  qestionInputTopTwo: {
    width: '190px',
    display: 'inline-flex',
    justifyContent: 'space-between',
    paddingRight: '50px',
    float: 'left',
    fontSize: '24px',
    lineHeight: '24px',
    verticalAlign: 'middle',
    margin: '0',
    position: 'relative',
    color: theme.palette.common.blue,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '120px',
      paddingRight: '20px',
    },
    '& label': {
      display: 'table',
      margin: 'auto auto 15px',
      position: 'relative',
      fontFamily: '"MuseoSans-100"',
      fontSize: '26px',
      width: '35px',
      height: '35px',
      border: '1px solid #0069aa',
      borderRadius: '50%',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        width: '25px',
        height: '25px',
      },
      '& input': {
        position: 'absolute',
        width: '0px',
        height: '0px',
        borderRadius: '50%',
        margin: '0px',
        opacity: '0',
      },
      '& i': {
        '&:before': {
          content: '""',
          width: '20px',
          height: '26px',
          position: 'absolute',
          left: '9px',
          top: '6px',
          backgroundSize: '15px',
          backgroundRepeat: 'no-repeat',
          transition: 'all 0.3s ease-in-out',
          [theme.breakpoints.down('sm')]: {
            left: '6px',
            top: '4px',
            backgroundSize: '10px',
          },
        },
      },
    },
  },
  subOptionSelect: {
    '& i': {
      '&:before': {
        backgroundImage: `url(${greenCheckDiscovery})`,
      },
    },
  },
  quickstartCriclesBtmCntTwo: {
    margin: '0 auto',
    marginTop: '50px',
  },
  largeStripeBarTwo: {
    padding: '10px',
    margin: '0px',
    background: '#00A1E1',
    borderRadius: '6px',
    color: theme.palette.common.blue,
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 'normal',
    fontFamily: '"MuseoSans-300"',
  },
  feedbckSec: {
    marginBottom: '30px',
    width: '100%',
    margin: 'auto',
    background: '#e9eef3',
    '& h4': {
      margin: '0 auto',
      fontSize: '40px',
      textAlign: 'center',
      padding: '40px 0',
      color: theme.palette.common.blue,
      '@media (max-width: 767px)': {
        fontSize: '35px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '30px',
      },
    },
  },
  feedbckSecDiv: {
    padding: '0 170px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 40px 40px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px 20px',
    },
  },
  emojiBlock: {
    '& h5': {
      fontSize: '24px',
      fontWeight: '300',
      marginBottom: '20px',
      lineHeight: '1.42857143',
      color: theme.palette.common.blue,
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    '& p': {
      fontSize: '24px',
      fontWeight: '300',
      marginBottom: '20px',
      lineHeight: '1.42857143',
      color: '#333',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
  },
  lookNextQustn: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    '& button': {
      display: 'inline-block',
      padding: '14px 22px',
      fontSize: '25px',
      lineHeight: '25px',
      color: theme.palette.common.white,
      borderRadius: '10px',
      background: '#fd9840',
      marginTop: '30px',
      '@media (max-width: 767px)': {
        fontSize: '19px',
        lineHeight: '30px',
      },
      '@media (max-width: 320px)': {
        fontSize: '15px',
        lineHeight: '25px',
      },
      '&:hover': {
        background: '#f89b23',
      },
      '& i': {
        marginLeft: '20px',
        fontSize: '20px',
      },
    },
  },
  feedbackImg: {
    marginTop: '5px',
    '& button': {
      padding: '0px',
      minWidth: 'initial',
      '&:hover': {
        background: 'transparent',
      },
      '& span': {
        width: '33px',
        border: '1px solid #ffffff',
        height: '33px',
        margin: '0px auto',
        borderRadius: '50%',
        '& figure': {
          margin: '0px',
          '& img': {
            width: '100%',
          },
        },
        '& span': {
          border: '0px solid #ffffff',
        },
        '&:nth-child(2)': {
          display: 'none',
        },
      },
    },
  },
  listNumArrow: {
    '& span': {
      background: theme.palette.common.blue,
      color: theme.palette.common.white,
      lineHeight: '33px',
      '& span': {
        '&:hover': {
          background: '#09314a',
        },
      },
    },
  },
  whatsMyScore: {
    padding: '20px 0',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: '20px 0 30px 0px',
    },
    '& button': {
      display: 'inline-block',
      padding: '13px 24px',
      border: '2px solid #0069aa',
      color: theme.palette.common.blue,
      fontSize: '36px',
      lineHeight: '36px',
      borderRadius: '5px',
      verticalAlign: 'middle',
      fontFamily: '"museo-sans", sans-serif',
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.common.blue,
        textDecoration: 'none',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '22px',
      },
    },
  },
  ChallengesNumbers: {
    position: 'fixed',
    top: '300px',
    left: '-11em',
    zIndex: '999',
    transform: 'translateY(-50%)',
    WebkitTransform: 'translateY(-50%)',
    MozTransform: 'translateY(-50%)',
    MsTransform: 'translateY(-50%)',
    OTransform: 'translateY(-50%)',
    transition: 'all 0.5s ease-in-out',
    WebkitTransition: 'all 0.5s ease-in-out',
    MozTransition: 'all 0.5s ease-in-out',
    MsTransition: 'all 0.5s ease-in-out',
    OTransition: 'all 0.5s ease-in-out',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      top: '55%',
      left: '15px !important',
      display: 'none',
    },
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      margin: '0',
      '& li': {
        display: 'block',
        padding: '0 0 5px 0',
        margin: '0',
        fontFamily: '"MuseoSans-100"',
        '& button': {
          padding: '0px',
          minWidth: 'auto',
          '&:hover': {
            background: theme.palette.common.transparent,
          },
        },
        '& span': {
          display: 'block',
          width: '31px',
          height: '31px',
          borderRadius: '100%',
          border: '1px solid #ffffff',
          textAlign: 'center',
          fontSize: '15px',
          transition: 'all 0.5s ease-in-out',
          WebkitTransition: 'all 0.5s ease-in-out',
          MozTransition: 'all 0.5s ease-in-out',
          MsTransition: 'all 0.5s ease-in-out',
          OTransition: 'all 0.5s ease-in-out',
          '&:hover': {
            background: '#09314a',
          },
          '& span': {
            border: '0px solid #ffffff',
          },
          '& i': {
            fontSize: '10px',
          },
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
  listNum: {
    '& span': {
      background: theme.palette.common.blue,
      color: theme.palette.common.white,
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'none',
        },
        '&:hover': {
          background: '#09314a',
        },
      },
    },
  },
  listNumWright: {
    '& span': {
      background: '#39b54a',
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'inline-block',
          fontSize: '10px',
          marginLeft: '1px',
        },
        '&:hover': {
          background: theme.palette.common.green,
        },
      },
    },
  },
  listNumWrong: {
    '& span': {
      background: theme.palette.common.red,
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'inline-block',
          fontSize: '10px',
          marginLeft: '1px',
        },
        '&:hover': {
          background: theme.palette.common.red,
        },
      },
    },
  },
  listNumActive: {
    '& span': {
      background: '#09314a',
      lineHeight: '33px',
      '& i': {
        display: 'inline-block',
        fontSize: '10px',
        marginLeft: '1px',
      },
    },
  },
  arrowUp: {
    '& span': {
      lineHeight: '37px',
      '& span': {
        '& i': {
          display: 'block',
          filter: 'brightness(0) invert(1)',
        },
      },
      '& i': {
        fontSize: '20px !important',
      },
    },
  },
  watchTourvideo: {
    marginBottom: '50px',
    '& h1': {
      fontSize: '30px',
      lineHeight: '30px',
      color: '#fff',
      background: '#539cc8',
      padding: '15px',
      margin: '0',
      textAlign: 'center',
      '@media (max-width: 767px)': {
        fontSize: '30px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '25px',
      },
    },
    '& h2': {
      color: '#0069aa',
      fontFamily: 'MuseoSans-300',
      fontSize: '35px',
      marginBottom: '30px',
    },
    '& img': {
      margin: '25px auto',
      display: 'table',
      maxWidth: '100%',
    },
  },
  awareNessPopup: {
    maxWidth: '650px',
    margin: '40px auto 25px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '0px auto 25px',
    },
    '& p': {
      fontSize: '23px',
      lineHeight: '35px',
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
      },
    },
    '& button': {
      background: '#83a947',
      fontSize: '20px',
      lineHeight: '24px',
      padding: '16px 40px ',
      color: ' #fff',
      display: 'table',
      borderRadius: '10px',
      margin: '28px auto 0',
      textTransform: 'uppercase',
      [theme.breakpoints.down('xs')]: {
        padding: '16px 20px ',
        fontSize: '17px',
      },
    },
    '& button:hover': {
      background: '#f89b22',
      fontSize: '20px',
      lineHeight: '24px',
      padding: '16px 40px',
      color: ' #fff',
      display: 'table',
      borderRadius: '10px',
      margin: '28px auto 0',
      textTransform: 'uppercase',

    },
  },
  spanBold: {
    fontFamily: '"MuseoSans-900"',
    fontSize: '30px',
    lineHeight: '27px',
    '@media (max-width: 1024px)': {
      fontSize: '29px',
    },
    '@media (max-width: 360px)': {
      fontSize: '28px',
    },
  },
  tooltipInfo: {
    width: '120px',
    padding: '0px 5px',
    height: '27px',
    marginLeft: '10px',
    lineHeight: '27px',
    textTransform: 'none',
    fontSize: '15px',
    background: '#0069aa !important',
    fontWeight: '500',
    textAlign: 'initial',
  },

  spanBoldNew: {
    fontFamily: '"MuseoSans-900"',
    fontSize: '27px',
    lineHeight: '27px',
  },
  stopAnimationClass: {
    '& label': {
      animation: 'none',
    },

  },
  graphBox: {
    maxWidth: '700px',
    margin: '40px auto 0',
  },
  whatmyScoreRow: {
    position: 'relative',
  },
  myScoreTitle: {
    background: theme.palette.common.bluelight,
    margin: '0 0 30px 0',
    '& h3': {
      fontSize: '30px',
      lineHeight: '36px',
      textAlign: 'center',
      color: theme.palette.common.white,
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
  },
  graphhRow: {
    display: 'block',
    textAlign: 'center',
  },
  inkImportRank: {
    display: 'inline-block',
    verticalAlign: 'middle',
    position: 'relative',
    right: '-7px',
    '& span': {
      display: 'block',
      position: 'absolute',
      right: '-100px',
      top: '46%',
      transform: 'translateY(-50%) rotate(-90deg)',
      WebkitTransform: 'translateY(-50%) rotate(-90deg)',
      MozTransform: 'translateY(-50%) rotate(-90deg)',
      MsTransform: 'translateY(-50%) rotate(-90deg)',
      OTransform: 'translateY(-50%) rotate(-90deg)',
      width: '340px',
      fontFamily: '"MuseoSans-300"',
      fontSize: '16px',
      lineHeight: '14px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down('sm')]: {
        right: '-140px',
      },
      [theme.breakpoints.down('xs')]: {
        right: '-100px',
      },
    },
    '& p': {
      display: 'block',
      height: '73px',
      margin: '0',
      position: 'relative',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '18px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down('sm')]: {
        height: '60px',
      },
      [theme.breakpoints.down('xs')]: {
        height: '40px',
      },
      '&:after': {
        position: 'absolute',
        content: '""',
        right: '-15px',
        width: '13px',
        top: '8px',
        borderBottom: '1px dotted #a1a1a1',
      },
    },
  },
  inkAwaerenessScoreCntIn: {
    display: 'inline-block',
    position: 'relative',
    padding: '0 35px',
    margin: '0 0 8px 0',
    height: '100%',
    maxWidth: 'calc(100% - 100px)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 50px)',
      overflow: 'hidden',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      bottom: '0',
      left: '30px',
      width: '1px',
      height: '100%',
      background: '#a1a1a1',
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      bottom: '40px',
      left: '0px',
      height: '1px',
      width: '100%',
      background: '#a1a1a1',
    },
  },
  inkAwaerenessCateCnt: {
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRight: '2px dotted #a1a1a1',
    padding: '0 7px 0 4px',
  },
  inkAwaerenessCateIn: {
    margin: '0 8px',
    display: 'inline-block',
    verticalAlign: 'bottom',
    textAlign: 'center',
    height: '380px',
    position: 'relative',
    width: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '10px',
      height: '270px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '10px',
      height: '270px',
      margin: '0 2px',
    },
    '@media (max-width: 320px)': {
      width: '5px',
    },
    '& p': {
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      fontSize: '18px',
      lineHeight: '40px',
      color: theme.palette.common.blue,
      margin: '0',
      position: 'absolute',
      bottom: '0',
      left: '0',
      right: '0',
      background: theme.palette.common.white,
    },
  },
  inkAwaerenessCateInSub: {
    position: 'absolute',
    bottom: 41,
    width: '100%',
    background: '#0069aa',
    outline: 'none',
    cursor: 'pointer',
    '&:hover': {
      '& div': {
        display: 'block',
      },
    },
  },
  inkAwaerenessCateInSubColor: {
    background: '#fed27f',
  },
  inkAwaerenessCateScoreRed: {
    background: '#fc5c2c',
    display: 'inline-block',
    verticalAlign: 'bottom',
    width: '100%',
    height: '100%',
    position: 'absolute',
    padding: '0',
    bottom: '0',
    left: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  inkAwaerenessCateScoreBlue: {
    background: '#0069aa',
    display: 'inline-block',
    verticalAlign: 'bottom',
    width: '100%',
    height: '100%',
    position: 'absolute',
    padding: '0',
    bottom: '0',
    left: '0',
    cursor: 'pointer',
    outline: 'none',
  },
  inkScoreBottomSec: {
    padding: '0 0 80px 0',
    '@media (max-width: 768px)': {
      padding: '0 0 40px 0',
    },
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      margin: '0',
      padding: '0 0 20px 0',
      '& li': {
        display: 'inline-block',
        padding: '0',
        '& p': {
          fontSize: '16px',
          lineHeight: '14px',
          margin: '0',
          fontFamily: '"MuseoSans-300"',
          color: theme.palette.common.blue,
          '& span': {
            width: '34px',
            height: '30px',
            display: 'inline-block',
            verticalAlign: 'middle',
            margin: '0 11px 0 38px',
          },
        },
      },
    },
  },
  quetionsPara: {
    margin: '0 0 20px 0',
    fontFamily: '"MuseoSans-300"',
    fontSize: '14px',
    lineHeight: '14px',
    color: theme.palette.common.blue,
  },
  correctBox: {
    background: '#0069aa',
  },
  wrongBox: {
    background: '#fed27f',
  },
  quetionsParaTwo: {
    display: 'block',
    fontFamily: '"MuseoSans-500"',
    fontSize: '16px',
    lineHeight: '26px',
    color: theme.palette.common.blue,
    margin: '0 auto',
  },
  infoIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '24px',
    height: '24px',
    top: '-10px',
    left: '10px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      top: '-2px',
    },
    '& img': {
      width: '100%',
      filter: 'brightness(0) invert(1)',
    },
  },
  scoreToolTip: {
    display: 'none',
    position: 'absolute',
    top: '-38px',
    width: '140px',
    background: '#0069aa',
    color: '#fff',
    padding: '4px 2px',
    borderRadius: '7px',
    zIndex: '99',
    left: '50%',
    transform: 'translateX(-50%)',
    '&:before': {
      borderTop: '8px solid #0069aa',
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      content: '""',
      height: 0,
      left: '45%',
      position: 'absolute',
      top: '100%',
      width: 0,
    },
  },
  pendinglistPopup: {
    padding: '0px',
    paddingTop: '0px',
    boxShadow: 'none',
    '& div': {
      maxWidth: '100%',
      padding: '0px',
      paddingTop: '0px',
      boxShadow: 'none',
      overflowY: 'inherit',
    },
    '& > div > div': {
      background: 'transparent',
      boxShadow: 'none',
      overflow: 'inherit',
    },
  },
  iframe: {
    border: '0px',
  },
  videoPopup: {
    paddingTop: '0px',
    '&:first-child': {
      paddingTop: '0px',
    },
    '& iframe': {
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
  },
  popupClose: {
    background: 'transparent',
    borderColor: '#fff',
    color: '#fa9c22',
    width: '26px',
    height: '26px',
    fontSize: '21px',
    lineHeight: '26px',
    outline: 'none',
    zIndex: '99',
    float: 'none',
    right: 0,
    position: 'absolute',
    opacity: '1',
    textShadow: 'none',
    borderRadius: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  questionsAnimation: {
    animation: 'pulse-animation 2s infinite',
  },
  mcsDevelopVision: {
    background:'#004375',
    padding: '10px 50px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '10px 10px',
    },
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexWrap:'wrap',
      justifyContent: 'center',
    },
  },
  activityImage: {
    cursor: 'pointer',
    '& img': {
      width: '30px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  greenTick: {
    '& img': {
      width: '20px',
      verticalAlign: 'middle',
      marginright: '5px',
    },
  },
  mcsLogoImage: {   
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& a': {
      '& img': {
        maxWidth: '200px',
        verticalAlign: 'middle',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '180px',
        },
        [theme.breakpoints.down('xs')]: {
          maxWidth: '180px',
        },
      },
    },
  },
  activityStatus: {
    '& h4': {
      fontSize:'22px',
      color: '#fff !important',
      margin: '0px',
      fontFamily: 'MuseoSans-300',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 'normal',
      [theme.breakpoints.down('sm')]: {
        fontSize:'16px',
      },
      [theme.breakpoints.down('xs')]: {
        display:'block',
        fontSize:'16px',
      },
      '& a': {
        color: '#fff !important',
        borderBottom: '1px solid',
        lineHeight: 'normal',
        marginLeft: '5px', 
        '&:hover':{
          color: '#f89b22 !important',
        },
      },
    },    
  },
  tenQuestions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0px',
    '& p': {
      background: '#7db0cd',
      borderRadius: '50%',
      width: '150px',
      height: '150px',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      fontSize: '23px',
      fontFamily: 'MuseoSans-300',
      lineHeight: '27px',
      textAlign: 'center',
    },
  },
  reviewLevels: {   
    position: 'absolute',
    right: '0',
    minWidth: '300px',
    padding: '10px 0',
    background: '#fff',
    marginTop: '20px',
    zIndex: '9999',
    color: '#333',
    '&::before': {
      borderBottom: '11px solid #fff',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      left: '92%',
      position: 'absolute',
      top: '-10px',
      width: '0',
    },
    '& i': {
      position: 'absolute',
      top: '3px',
      right: '3px',
      cursor: 'pointer',
    },
  },
  bookBlock: {
    position: 'relative',
  },
  panelDefault: {
    borderColor: '#0069aa',
    textAlign: 'left',
    background: '0 0',
    width: '90%',
    margin: '0 auto 3px',
    borderRadius: '0',
    border: '1px solid transparent',   
    webkitBoxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
    boxShadow: '0 1px 1px rgb(0 0 0 / 5%)',
  },
  panelBody: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  titleName: {
    width: '96%',
    display: 'flex',
  },
  mcsNumber: {
    paddingRight: '5px',
  },
  greentickMark: {
    width: '4%',
    '& img': {
      width: '10px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    background: '#0069aa !important',
    fontSize: '12px !important',
    textAlign: 'center',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    maxWidth: '200px',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },    
  },

  quickstartCircleTen:{
    display: 'block',
    padding: '0px',
    position: 'relative',
    [theme.breakpoints.down( 'md' )]: {
      textAlign: 'center',
      paddingTop: '140px',
      display: 'inline-block',
      width: '100%',
    },    
    '& li': {
      display: 'inline-block',
      position: 'absolute',
      width: '232px',
      height: '232px',
      borderRadius: '100%',
      textAlign: 'center',
      border: 'none',
      transition: 'all .5s ease 0s',
      MozTransition: 'all .5s ease 0s',
      WebkitTransition: 'all .5s ease 0s',
      [theme.breakpoints.down( 'md' )]: {
        position: 'initial',
        marginBottom: '20px',
        marginLeft: '10px',
        marginRight: '10px',
        display: 'inline-grid',
      },
      '& a': {
        padding: '12px 7px',
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'none',
        },
        '& h3': {
          fontFamily: '"MuseoSans-900"',
          fontSize: '70px',
          lineHeight: '70px',
          margin: '0',
        },
        '& h4': {
          color: theme.palette.common.white,
          fontSize: '23px',
          lineHeight: '27px',
          fontFamily: 'MuseoSans-100',
        },
      },
    },
  },
  tooltipPopupTen:{
    left: '-82px',
    top: '-115px',
    maxWidth: '294px',
    borderRadius: '20px',
    position: 'absolute',
    borderColor: theme.palette.common.blue,
    border: '3px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    borderStyle: 'solid',
    zIndex: '999',
    [theme.breakpoints.down( 'md' )]: {
      left: 'auto',
      top: '0px',
      right: 'auto',
    },
    '&:before': {
      content: '""',
      borderWidth: '31px 31px 0 30px',
      borderColor: '#fff transparent',
      bottom: '-27px',
      left: 'auto',
      right: '63px',
      top: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
      width: '0',
      height: '0',
      transition: 'none',
      zIndex: '999',
    },
    '&:after': {
      content: '""',
      borderWidth: '32px 31px 0 32px',
      borderColor: '#0069aa transparent',
      bottom: '-32px',
      left: 'auto',
      right: '63px',
      top: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
      width: '0',
      height: '0',
      transition: 'none',
      zIndex: '998',
    },
    '& p': {
      lineHeight: '1.3',
      fontSize: '20px',
      padding: '10px 20px',
      color: theme.palette.common.black,
      margin: '0px',
    },
  },
  circleTwoTen: {
    left: '112px',
    top: '200px',
    background: 'rgba(143, 176, 94, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#006838',
    },
  },
  circleThreeTen: {
    left: '235px',
    top: '20px',
    zIndex: '1',
    background: 'rgba(70, 158, 213, 0.9)',
    '&:hover': {
      transform: 'scale(1.5)',
      MozTransform: 'scale(1.5)',
      WebkitTransform: 'scale(1.5)',
      zIndex: '999',
    },
    '& h3': {
      color: '#83a74c',
    },
  },
});

export default styles;
