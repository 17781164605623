import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';



export function existingShowMeGraphData() {
  let graphsData = {};
  const existingStudentLoanOutputData = moduleServices.existingStudentLoanOutputGraphData();
  //Existing Federal Subsidized Student Loan
  if (existingStudentLoanOutputData['Federal Subsidized Graph'] !== undefined && existingStudentLoanOutputData['Federal Subsidized Graph']['yearsList'] !== undefined && existingStudentLoanOutputData['Federal Subsidized Graph']['yearsList'].length > 0) {
    graphsData['graph1'] = true;
    let subNewPaymentDataValue = moduleServices.existingStudentLoansFedSubMonthlyPayment();
    subNewPaymentDataValue = subNewPaymentDataValue.toFixed(0);
    if (subNewPaymentDataValue === '-Infinity') {
      subNewPaymentDataValue = '-';
    }
    graphsData['graph1Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        // height: moduleGraphHeight,
        // width: graphMainWidth
        events: {
          load() {
            const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: ' + formatDollar(Math.round(subNewPaymentDataValue)) + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
              ).attr({
                zIndex: 5
              }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: existingStudentLoanOutputData['Federal Subsidized Graph']['yearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Student Loan Balance and Payment',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Student Loan Balance',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Federal Subsidized Graph']['Student Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        }
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Federal Subsidized Graph']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#ed7d31'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        data: existingStudentLoanOutputData['Federal Subsidized Graph']['Principal Payback'],
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: existingStudentLoanOutputData['Federal Subsidized Graph']['Interest'],
        marker: {
          enabled: true
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }]
    }
  } else {
    graphsData['graph1'] = false;
  }
  //Existing Federal Unsubsidized Student Loan

  if (existingStudentLoanOutputData['Federal Unsubsidized Graph'] !== undefined && existingStudentLoanOutputData['Federal Unsubsidized Graph']['yearsList'].length > 0) {
    let subNewPaymentDataValue = moduleServices.existingStudentLoansFedUnSubMonthlyPayment();
    subNewPaymentDataValue = subNewPaymentDataValue.toFixed(0)
    if (subNewPaymentDataValue === '-Infinity') {
      subNewPaymentDataValue = '-';
    }
    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        // height: moduleGraphHeight,
        // width: graphMainWidth
        events: {
          load() {
            const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: ' + formatDollar(Math.round(subNewPaymentDataValue)) + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
              ).attr({
                zIndex: 5
              }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: existingStudentLoanOutputData['Federal Unsubsidized Graph']['yearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Student Loan Balance and Payment',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Student Loan Balance',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Federal Unsubsidized Graph']['Student Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        }
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Federal Unsubsidized Graph']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#F8CBAD'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        data: existingStudentLoanOutputData['Federal Unsubsidized Graph']['Principal Payback'],
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: existingStudentLoanOutputData['Federal Unsubsidized Graph']['Interest'],
        marker: {
          enabled: true
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }]
    }
  } else {
    graphsData['graph2'] = false;
  }

  //Existing Private Student Loan

  if (existingStudentLoanOutputData['Private Graph'] !== undefined && existingStudentLoanOutputData['Private Graph']['yearsList'].length > 0) {
    graphsData['graph3'] = true;

    let subNewPaymentDataValue = moduleServices.existingStudentLoansPrivateMonthlyPayment();
    subNewPaymentDataValue = subNewPaymentDataValue.toFixed(0);
    if (subNewPaymentDataValue === '-Infinity') {
      subNewPaymentDataValue = '-';
    }
    graphsData['graph3Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        // height: moduleGraphHeight,
        // width: graphMainWidth
        events: {
          load() {
            const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: ' + formatDollar(Math.round(subNewPaymentDataValue)) + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
              ).attr({
                zIndex: 5
              }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: existingStudentLoanOutputData['Private Graph']['yearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Student Loan Balance and Payment',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Student Loan Balance',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Private Graph']['Student Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        }
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Private Graph']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#FFBE00'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        data: existingStudentLoanOutputData['Private Graph']['Principal Payback'],
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: existingStudentLoanOutputData['Private Graph']['Interest'],
        marker: {
          enabled: true
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }]
    }
  } else {
    graphsData['graph3'] = false;

  }
  //Existing Credit Card Debt

  if (existingStudentLoanOutputData['Credit Card Debt'] !== undefined && existingStudentLoanOutputData['Credit Card Debt']['Months'] !== undefined && existingStudentLoanOutputData['Credit Card Debt']['Months'].length > 0) {

    let subNewCardPaymentDataValue = Math.max.apply(null, existingStudentLoanOutputData['Credit Card Debt']['Principal Payback']).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    if (subNewCardPaymentDataValue === '-Infinity') {
      subNewCardPaymentDataValue = '-';
    }

    let monthFull = ['Jan\'', 'Feb\'', 'Mar\'', 'Apr\'', 'May\'', 'Jun\'', 'Jul\'', 'Aug\'', 'Sept\'', 'Oct\'', 'Nov\'', 'Dec\''];
    let monthsLength = existingStudentLoanOutputData['Credit Card Debt']['Months'].length;
    let monthsList = [];
    let mainYear = parseInt(moduleServices.module1Data.start_year);
    let i = 0;
    for (let k = 1; k <= monthsLength; k++) {
      let axisVal = monthFull[i] + '' + mainYear;
      monthsList.push(axisVal);
      if (i === 11) {
        i = 0;
        mainYear++;
      } else {
        i++;
      }
    }
    graphsData['graph4'] = true;

    graphsData['graph4Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        // height: moduleGraphHeight,
        // width: graphMainWidth
        events: {
          load() {
            const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: $' + subNewCardPaymentDataValue + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
              ).attr({
                zIndex: 5
              }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
      },
      title: {
        text: ''
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: [{
        categories: monthsList,
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Existing Credit Card Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Balance',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Credit Card Debt']['Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#5B9BD5'
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Credit Card Debt']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#ED7D31'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        data: existingStudentLoanOutputData['Credit Card Debt']['Principal Payback'],
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: existingStudentLoanOutputData['Credit Card Debt']['Interest'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }]
    }
  } else {
    graphsData['graph4'] = false;
  }

  //Existing Car Loan
  //Five Bar Graph - Existing Car Loan

  if (existingStudentLoanOutputData['Existing Car'] !== undefined && existingStudentLoanOutputData['Existing Car']['yearsList'].length > 0) {
    let existingCarPaymentDataValue = 1200;
    if (existingCarPaymentDataValue === '-Infinity') {
      existingCarPaymentDataValue = '-';
    }
    graphsData['graph5'] = true;
    graphsData['graph5Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        events: {
          load() {
            const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: ' + formatDollar(Math.round(existingCarPaymentDataValue)) + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
              ).attr({
                zIndex: 5
              }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      title: {
        text: ''
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: [{
        categories: existingStudentLoanOutputData['Existing Car']['yearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Existing Car Loan Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Car Loan Balance',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Existing Car']['Car Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#5B9BD5'
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Existing Car']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#ED7D31'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        data: existingStudentLoanOutputData['Existing Car']['Principal Payback'],
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: existingStudentLoanOutputData['Existing Car']['Interest'],
        marker: {
          enabled: true
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }]
    }
  }
  else {
    graphsData['graph5'] = false;
  }
  //Existing Home Value and Mortgage Repayment

  if (existingStudentLoanOutputData['Existing Home'] !== undefined && existingStudentLoanOutputData['Existing Home']['yearsList'].length > 0) {
    let existingHomePaymentDataValue = 2000;
    if (existingHomePaymentDataValue === '-Infinity') {
      existingHomePaymentDataValue = '-';
    }
    graphsData['graph6'] = true;
    graphsData['graph6Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        events: {
          load() {
            const chart = this;
              const text = chart.renderer.text(
                '<strong>Monthly Payment: ' + formatDollar(Math.round(existingHomePaymentDataValue)) + '</strong>',
                chart.plotLeft + 30,
                chart.plotTop + 35
              ).attr({
                zIndex: 5
              }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: existingStudentLoanOutputData['Existing Home']['yearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Existing Home Mortgage Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Mortgage Balance',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Existing Home']['Mortgage Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#CC0000'
      }, {
        name: 'Home Value',
        type: 'column',
        yAxis: 1,
        data: existingStudentLoanOutputData['Existing Home']['Home Value'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#0070C0'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        data: existingStudentLoanOutputData['Existing Home']['Principal Payback'],
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: existingStudentLoanOutputData['Existing Home']['Interest'],
        marker: {
          enabled: true
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }]
    }
  }
  else {
    graphsData['graph6'] = false;
  }
  return graphsData;
}


