import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { Link } from 'react-router-dom';

const TurbulentMarkets = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Investing in Turbulent Markets</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investing-turbulent.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Investing in Turbulent Markets
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/turbulent-markets-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Happy new year. I know. It&apos;s a little late, but
                          happy new year nonetheless.
                        </p>
                        <p>
                          With Covid-19 continuing to persist into 2021, a new
                          administration taking office and the vaccine just
                          starting to roll out, there&apos;s a lot of
                          uncertainty in the world right now. Uncertainty in the
                          world makes it even more difficult to think about your
                          future. Especially when it comes to making smart
                          investments.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Regardless of the money in your bank account or your
                          age, here are some simple strategies to keep in mind
                          when investing in turbulent markets:
                        </p>
                        <p className={classes.noBottomMargin}>
                          <u>
                            <strong>Set an investing schedule:</strong>
                          </u>
                        </p>
                        <p className={classes.noTopMargin}>
                          Investing is easy, right? You buy when the price is
                          low and sell when the price is high. In reality,
                          timing the highs and lows is really hard. Instead of
                          trying to time the market, set an investing schedule.
                          At least once a month, on a specific date, you should
                          invest a specific amount of money. Whether you can
                          afford to invest $20 or $500, set a schedule and stick
                          to it.
                        </p>
                        <p>
                          When you invest on a specific schedule, you remove the
                          urge to try and time the market. Over time, you will
                          buy at lows and buy at highs. But investing
                          consistently over time has been a winning strategy and
                          actually has a name:{' '}
                          <Link
                            to="/finance-explained/dollar-cost-averaging"
                            target="_blank">
                            dollar cost averaging
                          </Link>
                          .
                        </p>
                        <p className={classes.noBottomMargin}>
                          <u>
                            <strong>Diversify your investments:</strong>
                          </u>
                        </p>
                        <p className={classes.noTopMargin}>
                          Have you ever heard of the expression,
                          &ldquo;Don&apos;t put all your eggs in one
                          basket?&rdquo; This expression is very good investing
                          advice. Let&apos;s look at a simple example. You have
                          $10,000. Tomorrow, you invest all $10,000 in Company
                          A. The following day, Company A goes bankrupt and you
                          lose all your investment. You put all your eggs in one
                          basket and that basket broke.
                        </p>
                        <p>
                          But, if you took the $10,000 and invested into 10
                          different companies including Company A, you would
                          only lose $1,000. And, you would still have nine other
                          investments that could earn you a return. That&apos;s
                          the power of{' '}
                          <Link
                            to="/finance-explained/diversification"
                            target="_blank">
                            diversification
                          </Link>
                          .
                        </p>
                        <p>
                          <Link
                            to="/finance-explained/funds-alternative-investments"
                            target="_blank">
                            ETFs or exchange-traded funds
                          </Link>{' '}
                          allow to invest in one stock while gaining exposure to
                          multiple different companies. For example, the SPY is
                          an ETF that you can buy. Buying one share of SPY gives
                          you exposure to 500 of the largest US public companies
                          at once. That&apos;s just one example of an ETF. Talk
                          about diversified.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <u>
                            <strong>Invest for the long-term:</strong>
                          </u>
                        </p>
                        <p className={classes.noTopMargin}>
                          Last, but not least, invest for the long-term.
                          We&apos;ve already established that trying to time the
                          market is very difficult even for the pros. Trying to
                          buy a stock low and sell the stock high in the same
                          day or even the same month is challenging to say the
                          least. Over the long term, investments tend to rise in
                          value. For example, over the last 100 years, the{' '}
                          <Link
                            to="/finance-explained/sandp-500"
                            target="_blank">
                            S&P500
                          </Link>{' '}
                          has returned 10% on average each year. Why hasn&apos;t
                          everyone just invested in the S&P500 and watched their
                          investments rise over time?
                        </p>
                        <p>
                          We tend to favor instant gratification and short-term
                          excitement over long-term gains. Think about that for
                          a second. Over the last 100 years, an investment has
                          returned 10% on average each year. That&apos;s a solid
                          historical track record. When you have a long-term
                          investment horizon, which would be 20 years or more,
                          you can be patient and play the long game.
                        </p>
                        <p>
                          When it comes to investing, sticking to a consistent
                          investing schedule, diversifying your investments and
                          investing for the long-term are historically proven
                          strategies that can help you minimize risk and earn a
                          reasonable return.
                        </p>
                        <p>
                          <i>
                            Disclaimer: It&apos;s important to note that Inkwiry
                            does not provide investment advice. These examples
                            are purely academic and hypothetical. All
                            investments carry the risk of losing some or all
                            your money and there is no guarantee of earning a
                            return. Always consult a professional before making
                            investment decisions.
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
TurbulentMarkets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TurbulentMarkets);
