import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Ordinal from 'ordinal';
import { withStyles } from '@material-ui/core/styles';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import styles from '../../../screens/pwi-family/styles';
import * as modulesServices from '../../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const childNumberLable = ['first_born','second_born','third_born','fourth_born','fifth_born','sixth_born','seventh_born'];

const StartingFamily = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [childrenCount, setChildrenCount] = useState(( inputData.number_of_children !== undefined && inputData.number_of_children !== null && inputData.number_of_children > 0) ?  inputData.number_of_children : 0  );

  const yearsListTicksValues = [];
  const year = modulesServices.module1Data.start_year;
  for ( let j = 0; j <= 14; j += 1 ) {
    if ( j % 2 === 0 ) {
      yearsListTicksValues.push( {
        value: year + j,
        label: year + j,
      } );
    }
  }


  const handleChildrenCount = ( e ) => {
    let inputDetails = {...inputData};
    inputDetails['number_of_children'] = parseInt(e.target.value, 10);
    setChildrenCount( inputDetails['number_of_children'] );
    for ( let i = 1; i <= 7; i += 1 ) {
      if(i > inputDetails['number_of_children']) {
        inputDetails[childNumberLable[i-1]] = 0; 
      } else {
        if(inputDetails[childNumberLable[i-1]] === undefined || inputDetails[childNumberLable[i-1]] === 0) {
          inputDetails[childNumberLable[i-1]] = yearsListTicksValues[0]['value'];
        }
      }
    }

    updateData('total_obj', inputDetails);
  };

  const handleMarriageYearSlider = ( e, newValue, id ) => {
    updateData(id, parseInt(newValue, 10));
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Whether you plan on having a child or adopting, starting a family is a major life event to consider when building your financial life plan. Leverage your timeline below and put some dates on starting a family.</p>
      <p>How many kids will you have and when?</p>

      <CareerSketchTimeline moduleName="module10"/>

      <div className={ classes.planList }>
        <ul className={ classes.yearsList }>
          <li>
            <span>I plan to have &nbsp;</span>
            <select value={ childrenCount } onChange={ handleChildrenCount }>
              {
                [0,1,2,3,4,5,6,7].map((value)=>(
                  <option value={value}>{value}</option>
                ))
              }
            </select>
                      &nbsp;children.
          </li>
        </ul>
      </div>

      {
        [1,2,3,4,5,6,7].map((value)=>(
          (value <= childrenCount) &&(
            <div className={ classes.startingFamilyGroup }>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor} ${classes.marrigeYear}` }>
                <div>
                  <p>
                    {Ordinal( value )}
                    {' '}
                    Child
                  </p>
                  <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor} ${classes.marrigeGraph}` }>
                    
                    <PwiSlider
                      value={ inputData[childNumberLable[value-1]] }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ yearsListTicksValues[0]['value'] }
                      max={ yearsListTicksValues[yearsListTicksValues.length - 1]['value'] }
                      step={ 1 }
                      marks={ yearsListTicksValues }
                      ValueLabelComponent={ ValueLabelComponent }
                      onChange={ ( e, newValue ) => handleMarriageYearSlider( e, newValue, childNumberLable[value-1] ) }
                    />
                  </div>
                  <div className={ classes.yearNumber }>
                    <span>{inputData[childNumberLable[value-1]]}</span>
                  </div>
                </div>
              </div>
            </div>
          )
        ))
      }

    </div>
  );
};

StartingFamily.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};
export default withStyles( styles )( StartingFamily );
