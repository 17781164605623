function buyingaHomeOutputBackend( module13Data, buyingAHomeData, mortgage1Data ) {
  /** ******************** Student Loan 1 *********************** */
  let year = 0;
  let yearLimit = 0;
  let buyingaHomeOutputBackend = {};
  year = parseInt(module13Data.year_of_purchase, 10);
  yearLimit = parseInt(module13Data.year_of_purchase, 10) + 30;
  let homeValue = 0;
  let mortgageBalanceValue = 0;
  let paymentValue = 0;
  let principalPaybackValue = 0;
  let interestValue = 0;

  let homeValueArr = [];
  let mortgageBalanceValueArr = [];
  let paymentValueArr = [];
  let principalPaybackValueArr = [];
  let interestValueArr = [];
  let yearsList = [];

  for (year; year < yearLimit; year+=1) {
      buyingaHomeOutputBackend[year] = {};

      //Home Value
      if( typeof buyingAHomeData !== 'undefined' && typeof buyingAHomeData['Real Estate'][year] !== 'undefined' && typeof buyingAHomeData['Real Estate'][year]['Asset Value'] !== 'undefined' ){
          homeValue = buyingAHomeData['Real Estate'][year]['Asset Value'];
      } else {
          homeValue = homeValue * ( 1 + ( module13Data.appreciation_in_percentage / 100 ) );
      }
      homeValueArr.push(Math.round(homeValue));

      //Mortgage Balance
      if( typeof mortgage1Data !== 'undefined' && typeof mortgage1Data['Summation Data'][year] !== 'undefined' && typeof mortgage1Data['Summation Data'][year]['EndingBalance'] !== 'undefined' ){
          mortgageBalanceValue = mortgage1Data['Summation Data'][year]['EndingBalance'];
      } else {
          mortgageBalanceValue = '';
      }
      mortgageBalanceValueArr.push(Math.round(mortgageBalanceValue));

      //Payment Value
      if( typeof mortgage1Data !== 'undefined' && typeof mortgage1Data['Summation Data'][year] !== 'undefined' && typeof mortgage1Data['Summation Data'][year]['LaggingInterest'] !== 'undefined' && typeof mortgage1Data['Summation Data'][year]['LaggingPrincipal'] !== 'undefined'){
          paymentValue = mortgage1Data['Summation Data'][year]['LaggingInterest'] + mortgage1Data['Summation Data'][year]['LaggingPrincipal'];
      } else {
          paymentValue = '';
      }
      if(paymentValue !== '' && paymentValue > 0) {
          paymentValueArr.push(Math.round(paymentValue));
      }

      //Principal payback value
      if( typeof mortgage1Data !== 'undefined' && typeof mortgage1Data['Summation Data'][year] !== 'undefined' && typeof mortgage1Data['Summation Data'][year]['LaggingPrincipal'] !== 'undefined'){
          principalPaybackValue = mortgage1Data['Summation Data'][year]['LaggingPrincipal'];
      } else {
          principalPaybackValue = '';
      }
      principalPaybackValueArr.push(Math.round(principalPaybackValue));

      //Interest value
      if( typeof mortgage1Data !== 'undefined' && typeof mortgage1Data['Summation Data'][year] !== 'undefined' && typeof mortgage1Data['Summation Data'][year]['LaggingInterest'] !== 'undefined'){
          interestValue = mortgage1Data['Summation Data'][year]['LaggingInterest'];
      } else {
          interestValue = '';
      }
      interestValueArr.push(Math.round(interestValue));
      if( homeValue > 0 || mortgageBalanceValue > 0 || principalPaybackValue > 0 || interestValue > 0 ){
        yearsList.push(year);
      }
    }

    let downPaymentVal = 0;
    let fitOutCostsVal = 0;
    let closingCostsVal = 0;

    let graphvaluesData = {};
    graphvaluesData['Bar Graph'] = {};
    graphvaluesData['Pie Chart'] = {};

    graphvaluesData['Bar Graph']['Years List'] = yearsList;
    graphvaluesData['Bar Graph']['Home Value'] = homeValueArr;
    graphvaluesData['Bar Graph']['Mortgage Balance'] = mortgageBalanceValueArr;
    graphvaluesData['Bar Graph']['Payment'] = paymentValueArr;
    graphvaluesData['Bar Graph']['Principal Payback'] = principalPaybackValueArr;
    graphvaluesData['Bar Graph']['Interest'] = interestValueArr;

    //Down Payment Val
    if( typeof buyingAHomeData !== 'undefined' && typeof buyingAHomeData['Buying Expenses'] !== 'undefined' && typeof buyingAHomeData['Buying Expenses']['Down Payment'] !== 'undefined' ){
        downPaymentVal = buyingAHomeData['Buying Expenses']['Down Payment'];  
    }
    graphvaluesData['Pie Chart']['Down Payment'] = downPaymentVal;

    //Fit Out Costs Val
    if( typeof buyingAHomeData !== 'undefined' && typeof buyingAHomeData['Buying Expenses'] !== 'undefined' && typeof buyingAHomeData['Buying Expenses']['Fit Out Costs'] !== 'undefined' ){
        fitOutCostsVal = buyingAHomeData['Buying Expenses']['Fit Out Costs'];  
    }
    graphvaluesData['Pie Chart']['Fit Out Costs'] = fitOutCostsVal;
    
    //Closing Costs Val
    if( typeof buyingAHomeData !== 'undefined' && typeof buyingAHomeData['Buying Expenses'] !== 'undefined' && typeof buyingAHomeData['Buying Expenses']['Closing Costs'] !== 'undefined' ){
        closingCostsVal = buyingAHomeData['Buying Expenses']['Closing Costs'];  
    }
    graphvaluesData['Pie Chart']['Closing Costs'] = closingCostsVal;
    
    graphvaluesData['Pie Chart']['Total'] =  parseFloat(graphvaluesData['Pie Chart']['Down Payment'])+
    parseFloat(graphvaluesData['Pie Chart']['Fit Out Costs'])+
    parseFloat(graphvaluesData['Pie Chart']['Closing Costs']);
    
    return graphvaluesData;

}

export default buyingaHomeOutputBackend;
