import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const EmergencyFund = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li> Emergency Fund</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/emergency-fund.png "
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Emergency Fund
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/emergency-fund-left.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          You're on your way to work and your car breaks down.
                          You call a tow truck, wait for the tow, and then grab
                          a cab to work. The repair shop calls you and notifies
                          you the repair will cost $2,500. Life is unexpected
                          and sometimes unfortunate.{' '}
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          An emergency fund is money set aside for the
                          unexpected events life throws at you. Whether
                          it&apos;s an untimely car bill, an unfortunate medical
                          expense or a brief period of unemployment, having an
                          emergency fund provides peace of mind and allows you
                          to navigate a life emergency with confidence in your
                          finances.
                        </p>

                        <p>
                          So an emergency fund is important. The next question
                          is: how much money set aside is enough? The general
                          rule of thumb for an emergency fund is four to six
                          months of expenses. If your monthly expenses to live
                          and repay your debts on time is $2,500 per month, your
                          emergency fund target should be $10,000 to $15,000.
                        </p>

                        <p>
                          Having an emergency fund is the first step towards
                          financial security and peace of mind. If you
                          don&apos;t have one, the best time to start was
                          yesterday. So why not today?
                        </p>

                        <p>
                          Now you know the benefits of an emergency fund and you
                          have a target of how much you might need, the next
                          question is: where I should store this money? Under
                          the bed in a safe? Believe it or not, that&apos;s not
                          as safe as with a bank.
                        </p>

                        <p>
                          Since an emergency fund is money you depend on to pay
                          for an unexpected expense, you don&apos;t want to
                          jeopardize those savings by making risky investments.
                          There should be two goals when choosing where to save
                          your money - very low risk and good liquidity.
                        </p>

                        <p>
                          For example, a high-yield savings account meets both
                          of these goals. A{' '}
                          <Link
                            to="/finance-explained/high-yield-savings-account"
                            target="_blank">
                            high-yield savings account
                          </Link>{' '}
                          is federally guaranteed up to $250,000 (in other
                          words, it&apos;s very safe) and offers good liquidity
                          (finance jargon for how quickly you can convert
                          something to cash). You can transfer funds from your
                          savings account to your checking account usually
                          within a day. That&apos;s pretty liquid.
                        </p>

                        <p>
                          Take your first step to financial security and start
                          building your emergency fund. Then, once you have
                          peace of mind in your short-term financial health,
                          start looking longer term and save and invest for the
                          big things in the future such as a home, a car and
                          retirement.
                        </p>

                        <p>
                          <i>
                            Disclaimer: It&apos;s important to note that Inkwiry
                            does not provide investment advice. These examples
                            are academic and hypothetical. All investments carry
                            the risk of losing some or all your money and there
                            is no guarantee of earning a return. Consulting a
                            professional or doing your own due diligence is
                            essential before making investment decisions.
                          </i>
                        </p>
                      </div>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
EmergencyFund.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EmergencyFund);
