import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import compose from 'recompose/compose';
import styles from './styles';
import scrollToComponent from 'react-scroll-to-component';
import mcsTopImage from '../../../assets/img/mcs/level1_image.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import infoIcon from '../../../assets/img/mcs/walk.png';
import levelIcon from '../../../assets/img/mcs/MCS-level08.png';
import progressImage from '../../../assets/img/mcs/100.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Pageloader from '../../../components/ui/pageloader';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
// const SAVEMYCAREERSKETCH = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');
const staticLevelData = [
  {
    "id": "7",
    "user_id": 214,
    "level": 2,
    "option_id": 1,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:29:33"
  },
  {
    "id": "8",
    "user_id": 214,
    "level": 2,
    "option_id": 2,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:30:01"
  }
]

const McsLevelOne = (props) => {
  const { classes, history } = props;
  let bodySection = React.useRef(null);
  const [firstActivityData, setFirstActivityData] = React.useState("");
  const [secondActivityData, setSecondActivityData] = React.useState("");
  const [levelPercentage, setLevelPercentage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [activeWalkThruSlide, setActiveWalkThruSlide] = React.useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({ 0: true });
  const [backgroundBlur, setBackgroundBlur] = React.useState(false);

 
  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "1",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setLevelPercentage(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent);
      setFirstActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 1)[0] : staticLevelData.filter(obj => obj.option_id === 1)[0]);
      setSecondActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 2)[0] : staticLevelData.filter(obj => obj.option_id === 2)[0])
    },
    onError() {
      setLoading(false);
    },
  });
  React.useEffect(() => {
    if (mcsLevelWiseData) {
      setLoading(false);
    }
  }, [mcsLevelWiseData]);
  
  const handleCompletedFirstActivity = (level) => {
    history.push('./level1/activity' + level);
  }
  const handleGotoNextLevel = () => {
    history.push('./level2');
  }

  const handleWalkThrus =(level)=>{
    switch (level) {
      case 1:
        scrollToComponent(bodySection, { offset: 0, align: 'top', duration: 500,overflowY: 'hidden'});
      
      break;
      case 2:
      scrollToComponent(bodySection, { offset: 20, align: 'top', duration: 500 });
      break;
      case 3:
      scrollToComponent(bodySection, { offset: -20, align: 'bottom', duration: 500});
      break;
      case 4:
      scrollToComponent(bodySection, { offset: -20, align: 'bottom', duration: 500 });
      break;
      case 5:
      scrollToComponent(bodySection, { offset: 0, align: 'bottom', duration: 500 });
      break;
      case 6:
      scrollToComponent(bodySection, { offset: 0, align: 'bottom', duration: 500 });
      break;
      default:
      break;
    }
    let updatedValue = 0;
    const completedWalkSlides = { ...completedWalkThruSlides };
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(level + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if(level === 6) {
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    }else {
      setBackgroundBlur(true);
    }
  }
  React.useEffect(() => {
    if(backgroundBlur && activeWalkThruSlide === 3) {
      document.body.classList.remove('pwiAnimation');
    } else if(backgroundBlur ){
      document.body.classList.add('pwiAnimation');
    }else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [activeWalkThruSlide]);

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={classes.mcsLevelOne}>
        <div className={classes.topImage}>
          <img src={mcsTopImage} alt="mcs" />
        </div>
        <div className={classes.container}>
          <div className={classes.mcsRow}>
            <div className={classNames(classes.mcsLogoImage,activeWalkThruSlide === 2 && classes.walkthruVisible )} id="walk_thru_slide_2">
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.mcsLevelNum}>
              <span className={classes.infoIcon}>
                <img src={infoIcon} alt="info" onClick={()=>{setBackgroundBlur(true);handleWalkThrus( 0 );}} />
              </span>
              <h3>LEVEL 01</h3>
              <img src={levelIcon} alt="info" className={classes.iconImage} />
            </div>
            {activeWalkThruSlide === 2 && (
            <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruTwo)}>
                <h4>Go back to the dashboard</h4>
                <p>You can always click on the My Career Sketch logo in the top left to go back to the dashboard.</p>
                <div className={classes.walkthruBottom}>                   
                   
                    <ul className={ classes.checkmarksScroll }> 
                      
                    {
                      [0, 1, 2, 3, 4, 5].map( ( data ) => (
            <li className={ classNames( data+1 === activeWalkThruSlide && classes.currentTab, (completedWalkThruSlides[data] === true && (data+1) !== activeWalkThruSlide) && classes.activeList ) } aria-hidden="true" onClick={ () => { handleWalkThrus( data ); } } />
          ) )
                    }
                    </ul>
                    <div className={classes.walkThruBtns}>
                        <Button  className={classes.startWalkThru} onClick={ () => { handleWalkThrus( 2 ); } }>Next</Button>                    
                    </div>
                </div>
            </div>
            )}
          </div>
          <div className={classes.mcsBodyContent}>
            <Grid container>
            {activeWalkThruSlide === 1 && (
            <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruOne)} id="walk_thru_slide_1">
                <h4>Step 1: Drag and drop life events to the timeline</h4>
                <p>This Inkwiry Walk-Thru will get you started in My Career Sketch and give you a quick tour around the interface. Click on this icon at any time in Level 01 to revisit this Walk-Thru.</p>
                <div className={classes.walkthruBottom}>                   
                    
                    <ul className={ classes.checkmarksScroll }>
                    {
                      [0, 1, 2, 3, 4, 5].map( ( data ) => (
            <li className={ classNames( data+1 === activeWalkThruSlide && classes.currentTab, (completedWalkThruSlides[data] === true && (data+1) !== activeWalkThruSlide) && classes.activeList ) } aria-hidden="true" onClick={ () => { handleWalkThrus( data ); } } >
            </li>
          ) )
                    }
                    </ul>
                    <div className={classes.walkThruBtns}>
                        <Button  className={classes.startWalkThru} onClick={ () => { handleWalkThrus( 1 ); } }>Next</Button>                       
                    </div>
                </div>
            </div>
                )}
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.leftBlock}>
                  <h1 className={classes.mcsLevelTitle}>Smarter tomorrows</h1>
                  <div className={classNames(classes.qPosition, activeWalkThruSlide === 3 && classes.walkthruVisibleDiv )} id="walk_thru_slide_3">
            {activeWalkThruSlide === 3 && (
                   
                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruThree)}>
                        <h4>Level overview</h4>
                        <p>Start the level by reading the level overview and explore what you&apos;re going to achieve.</p>
                        <div className={classes.walkthruBottom}>                    
                           
                            <ul className={ classes.checkmarksScroll }>
                    {
                      [0, 1, 2, 3, 4, 5].map( ( data ) => (
            <li className={ classNames( data+1 === activeWalkThruSlide && classes.currentTab, (completedWalkThruSlides[data] === true && (data+1) !== activeWalkThruSlide) && classes.activeList ) } aria-hidden="true" onClick={ () => { handleWalkThrus( data ); } } />
          ) )
                    }
                    </ul>
                      <div className={classes.walkThruBtns}>
                            <Button  className={classes.startWalkThru} onClick={ () => { handleWalkThrus( 3 ); } }>Next</Button>                        
                      </div>
                        </div>
                    </div>
            )}
                    <div className={classes.questionBlock}>
                      <p>Let&apos;s begin with a few concepts that lay the foundation for your career sketch.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Exactly what is a career sketch? It&apos;s an actionable financial life plan for your next 5, 10, 15 years. In My Career Sketch, we&apos;ll make a plan for the next 10 years, shaping your career and the education you need to achieve your goals.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>
                        <span className={classes.blueColor}>Stepping stones</span>
                        {' '}
                        are vital to your career sketch. Your stepping stones represent up to five major advancements along your career path like your first job, career promotions, and educational degrees and certifications. Just as you steadily move from rock to rock to get across a creek, stepping stones help you leap forward in life.
                        {' '}
                      </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>
                        My Career Sketch gets you to think about
                        {' '}
                        <span className={classes.blueColor}>smarter tomorrows.</span>
                        {' '}
                        What are smarter tomorrows? A future you find rewarding and filled with great happiness. You get to choose what will bring you joy and fulfillment.
                        {' '}
                      </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p className={classes.noBottomMargin}>
                        Inkwiry identifies
                        {' '}
                        <span className={classes.blueColor}>11 essential guiding principles of smarter tomorrows</span>
                        {' '}
                        that work together to expedite your journey. We’ll weave these common threads throughout your planning:
                      </p>
                      <ul className={classes.optionList}>
                        <li>Develop a vision for the future</li>
                        <li>Know your purpose for planning</li>
                        <li>Build your stepping stones</li>
                        <li>Set and adhere to efficient timelines </li>
                        <li>Enhance talents, skills and knowledge</li>
                        <li>Exceed above-the-median pay</li>
                        <li>Budget income and expenses</li>
                        <li>Manage debt</li>
                        <li>Save and invest</li>
                        <li>Capitalize on compound interest</li>
                        <li>Know the financial outcome of your decisions before you make them.</li>
                      </ul>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>
                        Finally, asking questions is vital to discovering your path and making your dreams come alive. We&apos;ll introduce you to the
                        <span className={classes.blueColor}>
                          {' '}
                          Big Question
                        </span>
                        {' '}
                        and the first three supporting questions.
                      </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>But first, let’s review a couple of career sketch stories to see how these concepts come into play.</p>
                    </div>   
                    
                    {/* {errorMessage && <span>{errorMessage}</span>} */}
                  </div>  
                  <div className={ classes.relativeBlock }>
            {activeWalkThruSlide === 6 && (
                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruSix)} id="walk_thru_slide_6">
                          <h4>Start</h4>
                          <p>After you review the level page in detail, click Start to begin completing the activities. My Career Sketch will guide you through each activity and keep you on track. Now, you’re ready to get started with Level 01.</p>
                          <div className={classes.walkthruBottom}>                   
                              
                              <ul className={ classes.checkmarksScroll }>
                    {
                      [0, 1, 2, 3, 4, 5].map( ( data ) => (
            <li className={ classNames( data+1 === activeWalkThruSlide && classes.currentTab, (completedWalkThruSlides[data] === true && (data+1) !== activeWalkThruSlide) && classes.activeList ) } aria-hidden="true" onClick={ () => { handleWalkThrus( data ); } } />
          ) )
                    }
                    </ul>
                          <div className={classes.walkThruBtns}>
                              <Button  className={classes.startWalkThru} onClick={ () => { handleWalkThrus( 6 ); } }>DONE</Button>                      
                              </div>
                          </div>
                      </div>
            )}
                      {secondActivityData && secondActivityData.status === 1 ? <div className={ classNames(classes.startButton,activeWalkThruSlide === 6 && classes.walkthruVisible)}>                    
                        <button type="button" onClick={handleGotoNextLevel}>NEXT LEVEL {'>'}</button>
                      </div> : firstActivityData && firstActivityData.status === 1 ? <div className={classes.startButton}>
                        <button type="button" onClick={() => handleCompletedFirstActivity("2")}>continue</button>
                      </div> : <div className={classes.startButton}>
                        <button type="button" onClick={() => handleCompletedFirstActivity("1")}>start</button>
                      </div>}
                    </div>            

                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.rightBlock}>
                  <div className={classes.schematicImage}>
                    <p>
                      New schematic illustrating
                      <br />
                      the 11 guiding principles
                      {' '}
                    </p>
                  </div>
                  <div className={classes.questionsImg}>
                    <div className={classes.mcsGraphic}>
                      <div className={classNames(classes.mcsGraphicCircle, classes.mcsCircleOne)}>
                        <h2>1</h2>
                        <h3>
                          Will my
                          {' '}
                          <span>career</span>
                          <br />
                          {' '}
                          support the life
                          <br />
                          {' '}
                          I want to live?
                        </h3>
                      </div>
                      <div className={classes.flexRow}>
                        <div className={classNames(classes.mcsGraphicCircle, classes.mcsCircleTwo)}>
                          <h2>2</h2>
                          <h3>
                            How do I acquire
                            <br />
                            {' '}
                            the
                            {' '}
                            <span>higher</span>
                            <br />
                            <span> education</span>
                            <br />
                            {' '}
                            I need?
                          </h3>
                        </div>
                        <div className={classNames(classes.mcsGraphicCircle, classes.mcsCircleThree)}>
                          <h2>3</h2>
                          <h3>
                            {' '}
                            Where is the
                            <br />
                            {' '}
                            <span>best place</span>
                            <br />
                            {' '}
                            for me to
                            {' '}
                            <span>live</span>
                            <br />
                            {' '}
                            and
                            {' '}
                            <span>work?</span>
                            {' '}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.activeRow}>
                    <div className={ classNames(classes.activities,activeWalkThruSlide === 4 && classes.walkthruVisibleBlock  )} id="walk_thru_slide_4">
                      <h4>Level 01 Activities</h4>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={firstActivityData && firstActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Understanding career sketches
                        </label>
                        {firstActivityData && firstActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={() => handleCompletedFirstActivity("1")}>Review</button> : ""}
                      </div>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={secondActivityData && secondActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Four Powerful questions
                        </label>
                        {secondActivityData && secondActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={() => handleCompletedFirstActivity("2")}>Review</button> : ""}
                      </div>
                    </div>
            {activeWalkThruSlide === 4 && (
                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruFour)}>
                        <h4>Level activities</h4>
                        <p>Next, review the activities needed to complete the level.</p>
                        <div className={classes.walkthruBottom}>                            
                            
                            <ul className={ classes.checkmarksScroll }>
                    {
                      [0, 1, 2, 3, 4, 5].map( ( data ) => (
            <li className={ classNames( data+1 === activeWalkThruSlide && classes.currentTab, (completedWalkThruSlides[data] === true && (data+1) !== activeWalkThruSlide) && classes.activeList ) } aria-hidden="true" onClick={ () => { handleWalkThrus( data ); } } />
          ) )
                    }
                    </ul>
                        <div className={classes.walkThruBtns}>
                           <Button  className={classes.startWalkThru} onClick={ () => { handleWalkThrus( 4 ); } }>Next</Button>                              
                        </div>
                        </div>
                    </div>
            )}
                   <div className={classNames(activeWalkThruSlide === 5 && classes.walkthruVisibleBlock)} id="walk_thru_slide_5">
                        <div className={classes.myLevelProgress}>
                          <h4>My Level 01 Progress</h4>
                        </div>
                        <div className={classes.progressPuzzle}>
                          <span className={classes.progressPercent}>{levelPercentage}%</span>
                          <p className={classes.puzzleBar}>
                            <img src={progressImage} alt="info" />
                          </p>
                        </div>
                    </div>
            {activeWalkThruSlide === 5 && (
                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruFive)} id="walk_thru_slide_5">
                        <h4>Progress bar</h4>
                        <p>As you complete the level activities, the progress bar will update.</p>
                        <div className={classes.walkthruBottom}>                           
                            
                            <ul className={ classes.checkmarksScroll }>
                            {
                      [0, 1, 2, 3, 4, 5].map( ( data ) => (
            <li className={ classNames( data+1 === activeWalkThruSlide && classes.currentTab, (completedWalkThruSlides[data] === true && (data+1) !== activeWalkThruSlide) && classes.activeList ) } aria-hidden="true" onClick={ () => { handleWalkThrus( data ); } } />
          ) )
                    }
                    </ul>
                        <div className={classes.walkThruBtns}>
                              <Button  className={classes.startWalkThru} onClick={ () => { handleWalkThrus( 5 ); } }>Next</Button>                                
                            </div>
                        </div>
                    </div>                    
            )}
            </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mcsFooter}>
          <div className={classes.container}>
            <div className={classes.footerRow}>
              <div className={classes.copyRight}>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={classNames(classes.copyRight, classes.informedText)}>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.mcsFluid}>
            <p className={classes.termLinks}>
              <a href="/">Terms of Service</a>
              &nbsp;//&nbsp;
              <a href="/">Privacy Policy</a>
              &nbsp;//&nbsp;
              <a href="/">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsLevelOne.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsLevelOne);