import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi-family/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import { multiplerValue } from '../../common/pwi/slider-functions';
import * as modulesServices from '../../../calculations/modules-services';

const followersMarks = [
  {
    value: 0,
    label: '0.0x',
  },
  {
    value: 0.5,
    label: '0.5x',
  },
  {
    value: 1.0,
    label: '1.0x',
  },
  {
    value: 1.5,
    label: '1.5x',
  },
  {
    value: 2.0,
    label: '2.0x',
  },
  {
    value: 2.5,
    label: '2.5x',
  },
];


const UpdatingCombinedBudgetSecond = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [inputs, setInputs] = React.useState();

  useEffect( () => {
    let inputValues = {...inputs};
    let inputDetails = {...inputData};
    if(inputData.rent_multiplier === undefined) {
      inputDetails['rent_multiplier'] = 1; 
    }
    inputValues['rent_multiplier'] = inputDetails['rent_multiplier'];
    if(inputData.utilities_multiplier === undefined) {
      inputDetails['utilities_multiplier'] = 1; 
    }
    inputValues['utilities_multiplier'] = inputDetails['utilities_multiplier'];
    if(inputData.food_multiplier === undefined) {
      inputDetails['food_multiplier'] = 1; 
    }
    inputValues['food_multiplier'] = inputDetails['food_multiplier'];
    if(inputData.clothing_multiplier === undefined) {
      inputDetails['clothing_multiplier'] = 1; 
    }
    inputValues['clothing_multiplier'] = inputDetails['clothing_multiplier'];
    if(inputData.entertainment_multiplier === undefined) {
      inputDetails['entertainment_multiplier'] = 1; 
    }
    inputValues['entertainment_multiplier'] = inputDetails['entertainment_multiplier'];
    updateData('total_obj',inputDetails);
    setInputs(inputValues);
  }, [] );

  const updateValue = ( e, value, field, type ) => {

    let inputDetails = {...inputData};
    inputDetails[field] = value;

    let inputValues = {...inputs};
    inputValues[field] = value;
    setInputs(inputValues)
    updateData('total_obj',inputDetails);
  };

  const individualLivingExpensesCalc = (label,  from='table') => {
    const incomeSheetData = modulesServices.incomeStatementCompleteData();
    let displayValue = 0;
      if(typeof incomeSheetData !== 'undefined' && incomeSheetData !== ''){
          const startYear = parseInt(modulesServices.module10Data.marriege_year, 10);
          if(typeof incomeSheetData[startYear] !== 'undefined' && incomeSheetData[startYear] !== '' 
              && typeof incomeSheetData[startYear] !== 'undefined' && incomeSheetData[startYear] !== ''
              && typeof incomeSheetData[startYear]['livingExpenses'] !== 'undefined' && incomeSheetData[startYear]['livingExpenses'] !== ''
              && typeof incomeSheetData[startYear]['livingExpenses'][label] !== 'undefined' 
              && incomeSheetData[startYear]['livingExpenses'][label] !== '') {
                 displayValue = -incomeSheetData[startYear]['livingExpenses'][label];    
          }
      }
      if(from === 'table') {
        return (
          <span className={displayValue < 0 && classes.dangerText}>
              <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale displayType="text" thousandSeparator value={ displayValue } prefix={ displayValue >= 0 ? '$' : '($' } suffix={ displayValue < 0 && ')' } />
          </span>
        )
      } else {
        return displayValue;
      }   
  }

  const sumOfOtherFamilyMultiplier = modulesServices.module10Data.familyTechnologyMultiplier+
  modulesServices.module10Data.familyTransportationMultiplier+
  modulesServices.module10Data.familyMiscellaneousMultiplier+
  modulesServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplier+
  modulesServices.module10Data.familyHealthInsurancePremiumMultiplier;
  const sumOfOtherFamilyMultiplierCalc = modulesServices.module10Data.familyTechnologyMultiplierCalc+
  modulesServices.module10Data.familyTransportationMultiplierCalc+
  modulesServices.module10Data.familyMiscellaneousMultiplierCalc+
  modulesServices.module10Data.familyHealthInsurancePremiumMultiplierCalc+
  modulesServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc;

  const otherLivingExpensesTotal = individualLivingExpensesCalc('Technology','')+individualLivingExpensesCalc('Transportation','')+individualLivingExpensesCalc('Miscellaneous','')+individualLivingExpensesCalc('Healthcare Costs other than Premium','')+individualLivingExpensesCalc('Health Insurance Premium','');
  
  return (
    <div className={ classes.contentBlock }>
      <p>Enter your marriage multiples for your monthly living expenses below.</p>
      {inputs !== undefined && (
        <div className={ classes.livingExpenses }>
          <div className={ classes.expensesGroup }>
            <div className={ classes.leftBlock }>
              <h3>&nbsp;</h3>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <h3>Monthly Before</h3>
              </div>
              <div>
                <div>
                  <h3>Monthly After</h3>
                </div>
              </div>
              <div>
                <div>
                  <h3>Annual</h3>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.expensesGroup }>
            <div className={ classes.leftBlock }>
              <div className={ `${classes.expensesGrowth} ${classes.expensesGrowthNew}` }>
                <div>
                  <p>Rent</p>
                  <div className={ classes.expensesGraphNew }>
                    <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                      <PwiSlider
                        value={ inputs['rent_multiplier'] }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ multiplerValue }
                        min={ 0 }
                        max={ 2.5 }
                        step={ 0.1 }
                        marks={ followersMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ ( e, value ) => updateValue( e, value, 'rent_multiplier', 'slider' ) }
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyRentMultiplier } prefix={ modulesServices.module10Data.familyRentMultiplier >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyRentMultiplier < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyRentMultiplierCalc } prefix={ modulesServices.module10Data.familyRentMultiplierCalc >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyRentMultiplierCalc < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  {individualLivingExpensesCalc('Rent')}
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.expensesGroup }>
            <div className={ classes.leftBlock }>
              <div className={ `${classes.expensesGrowth} ${classes.expensesGrowthNew}` }>
                <div>
                  <p>Utilities</p>
                  <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                    <PwiSlider
                      value={ inputs['utilities_multiplier'] }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={ multiplerValue }
                      min={ 0 }
                      max={ 2.5 }
                      step={ 0.1 }
                      marks={ followersMarks }
                      ValueLabelComponent={ ValueLabelComponent }
                      onChange={ ( e, value ) => updateValue( e, value, 'utilities_multiplier', 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyUtilitiesMultiplier } prefix={ modulesServices.module10Data.familyUtilitiesMultiplier >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyUtilitiesMultiplier < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyUtilitiesMultiplierCalc } prefix={ modulesServices.module10Data.familyUtilitiesMultiplierCalc >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyUtilitiesMultiplierCalc < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  {individualLivingExpensesCalc('Utilities')}
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.expensesGroup }>
            <div className={ classes.leftBlock }>
              <div className={ `${classes.expensesGrowth} ${classes.expensesGrowthNew}` }>
                <div>
                  <p>Food</p>
                  <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                    <PwiSlider
                      value={ inputs['food_multiplier'] }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={ multiplerValue }
                      min={ 0 }
                      max={ 2.5 }
                      step={ 0.1 }
                      marks={ followersMarks }
                      ValueLabelComponent={ ValueLabelComponent }
                      onChange={ ( e, value ) => updateValue( e, value, 'food_multiplier', 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyFoodMultiplier } prefix={ modulesServices.module10Data.familyFoodMultiplier >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyFoodMultiplier < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyFoodMultiplierCalc } prefix={ modulesServices.module10Data.familyFoodMultiplierCalc >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyFoodMultiplierCalc < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  {individualLivingExpensesCalc('Food')}
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.expensesGroup }>
            <div className={ classes.leftBlock }>
              <div className={ `${classes.expensesGrowth} ${classes.expensesGrowthNew}` }>
                <div>
                  <p>Clothing & Personal Care</p>
                  <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                    <PwiSlider
                      value={ inputs['clothing_multiplier'] }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={ multiplerValue }
                      min={ 0 }
                      max={ 2.5 }
                      step={ 0.1 }
                      marks={ followersMarks }
                      ValueLabelComponent={ ValueLabelComponent }
                      onChange={ ( e, value ) => updateValue( e, value, 'clothing_multiplier', 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyClothingMultiplier } prefix={ modulesServices.module10Data.familyClothingMultiplier >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyClothingMultiplier < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyClothingMultiplierCalc } prefix={ modulesServices.module10Data.familyClothingMultiplierCalc >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyClothingMultiplierCalc < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  {individualLivingExpensesCalc('Clothing')}
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.expensesGroup }>
            <div className={ classes.leftBlock }>
              <div className={ `${classes.expensesGrowth} ${classes.expensesGrowthNew}` }>
                <div>
                  <p>Entertainment</p>
                  <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                    <PwiSlider
                      value={ inputs['entertainment_multiplier'] }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={ multiplerValue }
                      min={ 0 }
                      max={ 2.5 }
                      step={ 0.1 }
                      marks={ followersMarks }
                      ValueLabelComponent={ ValueLabelComponent }
                      onChange={ ( e, value ) => updateValue( e, value, 'entertainment_multiplier', 'slider' ) }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyEntertainmentMultiplier } prefix={ modulesServices.module10Data.familyEntertainmentMultiplier >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyEntertainmentMultiplier < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.familyEntertainmentMultiplierCalc } prefix={ modulesServices.module10Data.familyEntertainmentMultiplierCalc >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.familyEntertainmentMultiplierCalc < 0 && ')' } />
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  {individualLivingExpensesCalc('Entertainment')}
                </div>
              </div>
            </div>
          </div>
          <div className={ `${classes.expensesGroup} ${classes.updatingExpenses}` }>
            <div className={ classes.leftBlock }>
              <div className={ `${classes.expensesGrowth} ${classes.expensesGrowthNew}` }>
                <div>
                  <h3>Other Living Expenses</h3>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <div className={ classes.graphInputBlock }>
                  <b><NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ sumOfOtherFamilyMultiplier } prefix={ sumOfOtherFamilyMultiplier >= 0 ? '$' : '($' } suffix={ sumOfOtherFamilyMultiplier < 0 && ')' } /></b>
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  <b><NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ sumOfOtherFamilyMultiplierCalc } prefix={ sumOfOtherFamilyMultiplierCalc >= 0 ? '$' : '($' } suffix={ sumOfOtherFamilyMultiplierCalc < 0 && ')' } /></b>
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                  <b><NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ otherLivingExpensesTotal } prefix={ otherLivingExpensesTotal >= 0 ? '$' : '($' } suffix={ otherLivingExpensesTotal < 0 && ')' } /></b>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.expensesGroup }>
            <div className={ classes.leftBlock }>
              <div className={ `${classes.expensesGrowth} ${classes.expensesGrowthNew}` }>
                <div>
                  <h3>Total Living Expenses</h3>
                </div>
              </div>
            </div>
            <div className={ classes.rightBlock }>
              <div>
                <div className={ classes.graphInputBlock }>
                <b><NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.sumOfFamilyMultiplier } prefix={ modulesServices.module10Data.sumOfFamilyMultiplier >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.sumOfFamilyMultiplier < 0 && ')' } /></b>
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                <b><NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module10Data.sumOfFamilyMultiplierCalc } prefix={ modulesServices.module10Data.sumOfFamilyMultiplierCalc >= 0 ? '$' : '($' } suffix={ modulesServices.module10Data.sumOfFamilyMultiplierCalc < 0 && ')' } /></b>
                </div>
              </div>
              <div>
                <div className={ classes.graphInputBlock }>
                
                <b><NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module10Data.marritalExpensesPaidMain } prefix={ -modulesServices.module10Data.marritalExpensesPaidMain >= 0 ? '$' : '($' } suffix={ -modulesServices.module10Data.marritalExpensesPaidMain < 0 && ')' } /></b>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

UpdatingCombinedBudgetSecond.propTypes = {
  classes: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default withStyles( styles )( UpdatingCombinedBudgetSecond );
