import * as sheets from '../components/sheets';
import * as FcCommonFunctions from '../utilities/FcCommonFunctions';
const statesList = [
  "New York", "Pennsylvania", "California", "Illinois", "New Jersey", "Wisconsin", "Maryland", "Minnesota", "Rhode Island", "Oregon", "Vermont", "Massachusetts", "Maine", "Hawaii", "Delaware", "Arkansas", "West Virginia", "Ohio", "North Carolina", "Utah", "Indiana", "Kansas", "Kentucky", "Michigan", "Idaho", "Virginia", "Washington", "Missouri", "Nebraska", "Iowa", "Georgia", "North Dakota", "Florida", "Colorado", "Arizona", "New Mexico", "Montana", "Alabama", "Oklahoma", "Mississippi", "South Carolina", "Nevada", "New Hampshire", "Louisiana", "Texas", "Tennessee", "Wyoming", "South Dakota", "Alaska", "Connecticut", "Washington D.C."
]
// Define Each Module Data constiables
// eslint-disable-next-line
export let module1Data = {};
export let beforeLoginModuleData = {}
// eslint-disable-next-line
export let module2Data = {};
// eslint-disable-next-line
export let module4Data = {};
// eslint-disable-next-line
export let module5Data = {};
// eslint-disable-next-line
export let module6Data = {};
// eslint-disable-next-line
export let module8Data = {};
// eslint-disable-next-line
export let module9Data = {};
// eslint-disable-next-line
export let module10Data = {};
// eslint-disable-next-line
export let module11Data = {};
// eslint-disable-next-line
export let module12Data = {};
// eslint-disable-next-line
export let module13Data = {};
// eslint-disable-next-line
export let module14Data = {};
// eslint-disable-next-line
export let module15Data = {};
// eslint-disable-next-line
export let module17Data = {};
// eslint-disable-next-line
export let module18Data = {};

// Excel Sheets Data
// eslint-disable-next-line
export let inputSheetData = {};
// eslint-disable-next-line
export let incomeStatementData = {};
// eslint-disable-next-line
export let balanceSheetData = {};
// eslint-disable-next-line
export let cashFlowData = {};
// eslint-disable-next-line
export let investmentsData = {};
// eslint-disable-next-line
export let existingStudentLoanData = {};
// eslint-disable-next-line
export let fedSubHigherEdLoanData = {};
// eslint-disable-next-line
export let fedUnSubHigherEdLoanData = {};
// eslint-disable-next-line
export let privateHigherEdLoanData = {};
// eslint-disable-next-line
export let fedSubMoreHigherEdLoanData = {};
// eslint-disable-next-line
export let fedUnSubMoreHigherEdLoanData = {};
// eslint-disable-next-line
export let privateMoreHigherEdLoanData = {};
// eslint-disable-next-line
export let fedSubNewHigherEdLoanData = {};
// eslint-disable-next-line
export let fedUnSubNewHigherEdLoanData = {};
// eslint-disable-next-line
export let privateNewHigherEdLoanData = {};
// eslint-disable-next-line
export let fedSubExistingLoanData = {};
// eslint-disable-next-line
export let fedUnSubExistingLoanData = {};
// eslint-disable-next-line
export let privateExistingLoanData = {};
// eslint-disable-next-line
export let studentLoan1Data = {};
// eslint-disable-next-line
export let studentLoan2Data = {};
// eslint-disable-next-line
export let studentLoanTaxData = {};
// eslint-disable-next-line
export let outputbackendData = {};
// eslint-disable-next-line
export let expenseCalculationsData = {};
// eslint-disable-next-line
export let assumptionsData = {};
// eslint-disable-next-line
export let inputTaxesData = {};
// eslint-disable-next-line
export let existingCarLoanData = {};
// eslint-disable-next-line
export let carLoan1Data = {};
// eslint-disable-next-line
export let carLoan2Data = {};
// eslint-disable-next-line
export let mortgage1Data = {};
// eslint-disable-next-line
export let mortgage2Data = {};
// eslint-disable-next-line
export let depreciationSheduleData = {};
// eslint-disable-next-line
export let buyingAHomeData = {};
// eslint-disable-next-line
export let invPropertyRoiData = {};
// eslint-disable-next-line
export let higherEdPaybackData = {};
// eslint-disable-next-line
export let higherEdOutputData = {};
// eslint-disable-next-line
export let careerOutputBackendData = {};
// eslint-disable-next-line
export let careerWhatIfBackendFullData = {};
// eslint-disable-next-line
export let careerAdvOutputBackendData = {};
// eslint-disable-next-line
export let moreHigherStudentLoanData = {};
// eslint-disable-next-line
export let moreHigherEdPaybackData = {};
// eslint-disable-next-line
export let moreHigherEdOutputData = {};
// eslint-disable-next-line
export let addnCareerAdvOutputBackendData = {};
// eslint-disable-next-line
export let investmentsOutputBackendData = {};
// eslint-disable-next-line
export let buyingaCarBackendData = {};
// eslint-disable-next-line
export let buyingaHomeOutputBackendData = {};
// eslint-disable-next-line
export let reiOutputBackendData = {};
// eslint-disable-next-line
export let newHigherEdOutputData = {};
// eslint-disable-next-line
export let newHigherEdPaybackData = {};
// eslint-disable-next-line
export let furtherCareerAdvOutputBackendData = {};
// eslint-disable-next-line
export let simulatorEngineData = {};
// eslint-disable-next-line
export let databaseData = {};
// eslint-disable-next-line
export let fcMasterOutputData = {};
// eslint-disable-next-line
export let investmentsShowTableData = {};
// eslint-disable-next-line
export let existingStudentLoanBackendData = {};
// eslint-disable-next-line
export let existingStudentLoanOutputBackendData = {};
// eslint-disable-next-line
export let refinedStudentLoansData = {};
// eslint-disable-next-line
export let existingHomeMortgageData = {};
// eslint-disable-next-line
export let existingHomeData = {};
// eslint-disable-next-line
export let existingCreditCardDebtData = {};
// eslint-disable-next-line
export let simPanelCalcsData = {};
// eslint-disable-next-line
export let dashBoardCalcsData = {};
// eslint-disable-next-line
export let taxStatementData = {};
// eslint-disable-next-line
export let spouseStudentLoanData = {};
// eslint-disable-next-line
export let carsExistingVehicleDepreciationData = {};

// eslint-disable-next-line
export let shortTermTableData = {};
// eslint-disable-next-line
export let longTermTableData = {};
// eslint-disable-next-line
export let startYearGraphData = {};
// eslint-disable-next-line
export let familyOutputBackendData = {};
// eslint-disable-next-line
export let newHigherStudentLoanData = {};
// eslint-disable-next-line
export let cashFlowCheckVal = '';

// eslint-disable-next-line
export let databas5Data = {};
// eslint-disable-next-line
export let inputSh5etData = {};
// eslint-disable-next-line
export let investm5ntsData = {};


/** ******* Initialize Excel Sheets Data *********** */
// Existing Student Loan Excel Sheet
export function existingStudentLoanInitialize() {
  // existingStudentLoanData = existingStudentLoan.existingStudentLoanData();
}

export function existingStudentLoanCompleteData() {
  return existingStudentLoanData;
}

// Fed Sub Higher Education Loan Excel Sheet
export function fedSubHigherEdLoanInitialize() {
  fedSubHigherEdLoanData = sheets.fedSubHigherEdLoan(module1Data, module2Data);
}
export function fedSubHigherEdLoanCompleteData() {
  return fedSubHigherEdLoanData;
}

// Fed UnSub Higher Education Loan Excel Sheet
export function fedUnSubHigherEdLoanInitialize() {
  fedUnSubHigherEdLoanData = sheets.fedUnSubHigherEdLoan(module1Data, module2Data);
}
export function fedUnSubHigherEdLoanCompleteData() {
  return fedUnSubHigherEdLoanData;
}

// Private Higher Education Loan Excel Sheet
export function privateHigherEdLoanInitialize() {
  privateHigherEdLoanData = sheets.privateHigherEdLoan(module1Data, module2Data);
}
export function privateHigherEdLoanCompleteData() {
  return privateHigherEdLoanData;
}

// Fed Sub More Higher Education Loan Excel Sheet
export function fedSubMoreHigherEdLoanInitialize() {
  fedSubMoreHigherEdLoanData = sheets.fedSubMoreHigherEdLoan(module1Data, module6Data);
}
export function fedSubMoreHigherEdLoanCompleteData() {
  return fedSubMoreHigherEdLoanData;
}

// Fed UnSub More Higher Education Loan Excel Sheet
export function fedUnSubMoreHigherEdLoanInitialize() {
  fedUnSubMoreHigherEdLoanData = sheets.fedUnSubMoreHigherEdLoan(module1Data, module6Data);
}
export function fedUnSubMoreHigherEdLoanCompleteData() {
  return fedUnSubMoreHigherEdLoanData;
}

// Private More Higher Education Loan Excel Sheet
export function privateMoreHigherEdLoanInitialize() {
  privateMoreHigherEdLoanData = sheets.privateMoreHigherEdLoan(module1Data, module6Data);
}
export function privateMoreHigherEdLoanCompleteData() {
  return privateMoreHigherEdLoanData;
}

// Fed Sub New Higher Education Loan Excel Sheet
export function fedSubNewHigherEdLoanInitialize() {
  fedSubNewHigherEdLoanData = sheets.fedSubNewHigherEdLoan(module1Data, module15Data);
}
export function fedSubNewHigherEdLoanCompleteData() {
  return fedSubNewHigherEdLoanData;
}

// Fed UnSub New Higher Education Loan Excel Sheet
export function fedUnSubNewHigherEdLoanInitialize() {
  fedUnSubNewHigherEdLoanData = sheets.fedUnSubNewHigherEdLoan(module1Data, module15Data);
}
export function fedUnSubNewHigherEdLoanCompleteData() {
  return fedUnSubNewHigherEdLoanData;
}

// Private New Higher Education Loan Excel Sheet
export function privateNewHigherEdLoanInitialize() {
  privateNewHigherEdLoanData = sheets.privateNewHigherEdLoan(module1Data, module15Data);
}
export function privateNewHigherEdLoanCompleteData() {
  return privateNewHigherEdLoanData;
}

// Fed Sub Existing Loan Excel Sheet
export function fedSubExistingLoanInitialize() {
  fedSubExistingLoanData = sheets.fedSubExistingLoan(module1Data, module18Data);
}
export function fedSubExistingLoanCompleteData() {
  return fedSubExistingLoanData;
}

// Fed UnSub Existing Loan Excel Sheet
export function fedUnSubExistingLoanInitialize() {
  fedUnSubExistingLoanData = sheets.fedUnSubExistingLoan(module1Data, module18Data);
}
export function fedUnSubExistingLoanCompleteData() {
  return fedUnSubExistingLoanData;
}

// Private Existing Loan Excel Sheet
export function privateExistingLoanInitialize() {
  privateExistingLoanData = sheets.privateExistingLoan(module1Data, module18Data);
}
export function privateExistingLoanCompleteData() {
  return privateExistingLoanData;
}

// Student Loan 1 Excel Sheet
export function studentLoan1Initialize() {
  // studentLoan1.initialize(module1Dmodule2Data);
  // student5oan1Data = studentLoan1.studentLoanData();
}
export function studentLoan1CompleteData() {
  return studentLoan1Data;
}

// Student Loan 2 Excel Sheet
export function studentLoan2Initialize() {
  // studentLoan2.initialize(module1Data, module6Data);
  // student5oan2Data = studentLoan2.studentLoanData();
}
export function studentLoan2CompleteData() {
  return studentLoan2Data;
}

// Student Loan Tax Deductions Excel Sheet
export function studentLoanTaxInitialize() {
  studentLoanTaxData = sheets.studentLoanTax(module1Data, module10Data, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData,
    privateMoreHigherEdLoanData, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData, fedUnSubExistingLoanData, privateExistingLoanData, taxStatementData, spouseStudentLoanData);
}
export function studentLoanTaxCompleteData() {
  return studentLoanTaxData;
}

// Estimated Income Taxes Excel Sheet
export function estimatedIncomeTaxesInitialize() {
  sheets.estimatedIncomeTaxes(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module10Data, module15Data, module17Data, databaseData, inputTaxesData);
}

// Database Sheet
export function databaseSheetInitialize() {
  databaseData = sheets.database(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module10Data, module18Data, assumptionsData, fedSubHigherEdLoanData,
    fedUnSubHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, module15Data, module17Data, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData,
    incomeStatementData, taxStatementData);
  // dataBaseMaxLimits();
  // dataBaseMaxLimitsMore();
  // dataBaseMaxLimitsNew();
}
export function databaseCompleteData() {
  return databaseData;
}

// For simulator panel Calculation
export function simPanelCalcsInitialize() {
 // simPanelCalcs.initialize();
 simPanelCalcsData = sheets.simPanelCalcs(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module10Data, module18Data, module12Data, module17Data, assumptionsData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, module15Data, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, incomeStatementData, existingHomeData, existingStudentLoanBackendData, depreciationSheduleData, higherEdOutputData, moreHigherEdOutputData, newHigherEdOutputData, careerOutputBackendData, careerAdvOutputBackendData, addnCareerAdvOutputBackendData, furtherCareerAdvOutputBackendData, cashFlowData, familyOutputBackendData, carLoan1Data, carLoan2Data, buyingAHomeData, invPropertyRoiData)
}
export function simPanelCalcsCompleteData() {
  return simPanelCalcsData;
}

// For Dashboard Calculation
export function dashBoardCalcsInitialize() {
  dashBoardCalcsData = sheets.dashBoardCalcs(module1Data, balanceSheetData, incomeStatementData, cashFlowData, databas5Data, inputSheetData, investmentsData, outputbackendData);
}
export function dashBoardCalcsCompleteData() {
  return dashBoardCalcsData;
}

// Income Taxes Excel Sheet
export function incomeTaxesInitialize() {
  inputTaxesData = sheets.incomeTaxes(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module15Data, module17Data, databaseData, taxStatementData);
}
export function inputTaxesCompleteData() {
  return inputTaxesData;
}

// Inputs Sheet
export function inputsSheetInitialize() {
  inputSheetData = sheets.inputsSheet(module1Data, module9Data, incomeStatementData, cashFlowData, balanceSheetData, taxStatementData, investmentsData);
}
export function inputSheetCompleteData() {
  return inputSheetData;
}

// Income Statement Excel Sheet
export function incomeStatementInitialize() {
  incomeStatementData = sheets.incomeStatement(module1Data, module2Data, module4Data, module5Data, module6Data,
    module8Data, module9Data, module10Data, module13Data, module14Data, module15Data, module17Data,
    module18Data, existingStudentLoanData, studentLoanTaxData, expenseCalculationsData, assumptionsData, inputTaxesData,
    investmentsData, depreciationSheduleData, existingCarLoanData, carLoan1Data, carLoan2Data, mortgage1Data, mortgage2Data,
    buyingAHomeData, invPropertyRoiData, databaseData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData,
    privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, fedSubNewHigherEdLoanData,
    fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData, fedUnSubExistingLoanData, privateExistingLoanData,
    existingHomeData, existingCreditCardDebtData, existingHomeMortgageData, taxStatementData, spouseStudentLoanData, carsExistingVehicleDepreciationData);
}
export function incomeStatementCompleteData() {
  return incomeStatementData;
}

// Cashflow Sheet
export function cashflowStatementInitialize() {
  cashFlowData = sheets.cashflowStatement(module1Data, module2Data, module6Data, module9Data, module10Data, module11Data, module12Data, module18Data, incomeStatementData, investmentsData, assumptionsData,
    buyingAHomeData, invPropertyRoiData, expenseCalculationsData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData,
    privateMoreHigherEdLoanData, module15Data, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, depreciationSheduleData, existingHomeData);
}
export function cashFlowCompleteData() {
  return cashFlowData;
}

// Balance Sheet
export function balanceSheetInitialize() {
  balanceSheetData = sheets.balanceSheet(module1Data, module14Data, module18Data, cashFlowData, investmentsData, existingCarLoanData,
    carLoan1Data, carLoan2Data, depreciationSheduleData, buyingAHomeData, mortgage1Data, mortgage2Data, invPropertyRoiData,
    fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData,
    privateMoreHigherEdLoanData, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData,
    fedUnSubExistingLoanData, privateExistingLoanData, existingHomeData, existingCreditCardDebtData, existingHomeMortgageData, spouseStudentLoanData, carsExistingVehicleDepreciationData);
}
export function balanceSheetCompleteData() {
  return balanceSheetData;
}

// Expense Calculations Sheet
export function expenseCalculationsInitialize() {
  expenseCalculationsData = sheets.expenseCalculations(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, assumptionsData, module15Data, module17Data, module10Data);
}
export function expenseCalculationsCompleteData() {
  return expenseCalculationsData;
}

// Investments Sheet
export function investmentsInitialize() {
  investmentsData = sheets.investments(module1Data, module4Data, module5Data, module8Data, module9Data, module10Data, module17Data, module18Data, cashFlowData, incomeStatementData, databaseData, assumptionsData);
}
export function investmentsCompleteData() {
  return investmentsData;
}

// Assumptions Sheet
export function assumptionsSheetInitialize() {
  assumptionsData = sheets.assumptionsSheet(module1Data, module10Data);
}
export function assumptionsCompleteData() {
  return assumptionsData;
}

// FC Master Output Sheet
export function fcMasterOutputInitialize() {
  fcMasterOutputData = sheets.fcMasterOutput(module1Data, outputbackendData, balanceSheetData);
}
export function fcMasterCompleteData() {
  return fcMasterOutputData;
}

// Existing Car Loan Sheet
export function existingCarLoanInitialize() {
  existingCarLoanData = sheets.existingCarLoan(module1Data, module12Data, module18Data);
}
export function existingCarLoanCompleteData() {
  return existingCarLoanData;
}

// Existing Home Mortgage Sheet
export function existingHomeMortgageLoanInitialize() {
  existingHomeMortgageData = sheets.existingHomeMortgage(module1Data, module18Data);
}
export function existingHomeMortgageCompleteData() {
  return existingHomeMortgageData;
}

// Existing Home Sheet
export function existingHomeInitialize() {
  existingHomeData = sheets.existingHome(module1Data, module18Data, assumptionsData, mortgage1Data, existingHomeMortgageData);
}
export function existingHomeCompleteData() {
  return existingHomeData;
}

// Car Loan 1 Sheet
export function carLoan1Initialize() {
  carLoan1Data = sheets.carLoan1(module1Data, module12Data);
}
export function carLoan1CompleteData() {
  return carLoan1Data;
}

// Car Loan 2 Sheet
export function carLoan2Initialize() {
  carLoan2Data = sheets.carLoan2(module1Data, module12Data);
}
export function carLoan2CompleteData() {
  return carLoan2Data;
}

// Existing Credit Card Debt Sheet
export function existingCreditCardDebtInitialize() {
  existingCreditCardDebtData = sheets.existingCreditCardDebt(module1Data, module18Data);
}
export function existingCreditCardDebtCompleteData() {
  return existingCreditCardDebtData;
}

// Mortage 1 Sheet
export function mortage1Initialize() {
  mortgage1Data = sheets.mortage1(module1Data, module13Data);
}
export function mortgage1CompleteData() {
  return mortgage1Data;
}

// Mortage 2 Sheet
export function mortage2Initialize() {
  mortgage2Data = sheets.mortage2(module1Data, module14Data);
}
export function mortgage2CompleteData() {
  return mortgage2Data;
}

// Depreciation Schedule Sheet
export function depreciationSheduleInitialize() {
  depreciationSheduleData = sheets.DepreciationShedule(module1Data, module12Data, module18Data, assumptionsData);
}
export function depreciationScheduleCompleteData() {
  return depreciationSheduleData;
}

// Buying a Home Sheet
export function buyingAHome() {
  buyingAHomeData = sheets.buyingAHome(module1Data, module13Data, assumptionsData, mortgage1Data);
}
export function buyingAHomeCompleteData() {
  return buyingAHomeData;
}

// Inv Property Roi Sheet
export function invPropertyRoi() {
  invPropertyRoiData = sheets.invPropertyRoi(module1Data, module14Data, assumptionsData, mortgage2Data, reiOutputBackendData, taxStatementData, databaseData);
}
export function invPropertyRoiCompleteData() {
  return invPropertyRoiData;
}

// Output Backend Sheet
export function outputBackendInitialize() {
  outputbackendData = sheets.outputBackend(module1Data, module4Data, module5Data, module8Data, module9Data, module17Data, incomeStatementData, balanceSheetData, cashFlowData, investmentsData, databaseData, taxStatementData);
}
export function outputSheetCompleteData() {
  return outputbackendData;
}

// Higher Education payback Sheet
export function higherEducationPaybackInitialize(inputData) {
  higherEdPaybackData = sheets.higherEdPayback(module1Data, module2Data, inputData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, databaseData, inputTaxesData);
  return higherEdPaybackData;
}
export function investmentPaybackBackendCompleteData() {
  return higherEdPaybackData;
}

// Higher Education Output backend Sheet
export function higherEducationOutputInitialize() {
  higherEdOutputData = sheets.higherEdOutput(module1Data, module2Data, studentLoan1Data, cashFlowData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, databaseData, incomeStatementData, inputTaxesData);
}

export function investmentOutputBackendCompleteData() {
  return higherEdOutputData;
}

// Career What If Output Backend Sheet
export function careerWhatIfOutputInitialize(inputData3) {
  careerWhatIfBackendFullData = sheets.careerWhatIfBackend(module1Data, module4Data, inputData3, inputTaxesData);
  return careerWhatIfBackendFullData;
}
export function careerWhatIfBackend() {
  return careerWhatIfBackendFullData;
}

// Career Output Backend Sheet
export function careerOutputBackendInitialize() {
  careerOutputBackendData = sheets.careerOutputBackend(module4Data, incomeStatementData, investmentsData, expenseCalculationsData, cashFlowData);
}
export function careerOutputGraphData() {
  return careerOutputBackendData;
}

// Career Advancement Output Backend Sheet
export function careerAdvOutputBackendInitialize() {
  careerAdvOutputBackendData = sheets.careerAdvOutputBackend(module5Data, incomeStatementData, investmentsData, cashFlowData, expenseCalculationsData);
}
export function careerAdvOutputGraphData() {
  return careerAdvOutputBackendData;
}

// More Higher Student Loan Excel Sheet
export function moreHigherStudentLoanInitialize() {
  // sheets.moreHigherStudentLoan(module1Data, module6Data);
  // moreHig5erStudentLoanData = moreHigherStudentLoan.studentLoanData();
}
// More Higher Education payback Sheet
export function moreHigherEducationPaybackInitialize(inputData) {
  moreHigherEdPaybackData = sheets.moreHigherEdPayback(module6Data, inputData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, databaseData);
  return moreHigherEdPaybackData;
}

export function moreInvestmentPaybackBackendCompleteData() {
  return moreHigherEdPaybackData;
}

// More Higher Education Output backend Sheet
export function moreHigherEducationOutputInitialize() {
  moreHigherEdOutputData = sheets.moreHigherEdOutput(module6Data, moreHigherStudentLoanData, incomeStatementData, cashFlowData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, databaseData, inputTaxesData);
}
export function moreInvestmentOutputBackendCompleteData() {
  return moreHigherEdOutputData;
}

// Additional Career Advancement Output Backend Sheet
export function addnCareerAdvOutputBackendInitialize() {
  addnCareerAdvOutputBackendData = sheets.addnCareerAdvOutputBackend(module8Data, incomeStatementData, investmentsData, cashFlowCompleteData, expenseCalculationsData);
}
export function addnCareerAdvOutputGraphData() {
  return addnCareerAdvOutputBackendData;
}

// Investments Output Backend Sheet
export function investmentsOutputBackendInitialize() {
  investmentsOutputBackendData = sheets.investmentsOutputBackend(module1Data, module9Data, investmentsData);
}
export function investmentsOutputGraphData() {
  return investmentsOutputBackendData;
}

// Family Output Backend Sheet
export function familyBackendInitialize() {
  familyOutputBackendData = sheets.familyBackend(module1Data, module10Data, incomeStatementData, assumptionsData, cashFlowData, spouseStudentLoanData);
}
export function familyOutputGraphData() {
  return familyOutputBackendData;
}

// Buying A Home Output Backend
export function buyingaHomeOutputBackendInitialize() {
  buyingaHomeOutputBackendData = sheets.buyingaHomeOutputBackend(module13Data, buyingAHomeData, mortgage1Data);
}
export function buyingaHomeOutputBackendGraphData() {
  return buyingaHomeOutputBackendData;
}

// REI Output Backend
export function reiOutputBackendInitialize() {
  reiOutputBackendData = sheets.reiOutputBackend(module14Data, module13Data, invPropertyRoiData, mortgage2Data);
}
export function reiOutputBackendGraphData() {
  return reiOutputBackendData;
}

// Buying A car Backend
export function buyingaCarBackendInitialize() {
  buyingaCarBackendData = sheets.buyingaCarBackend(module12Data, carLoan1Data, carLoan2Data);
}
export function buyingaarGraphData() {
  return buyingaCarBackendData;
}

// Investments Show Table Backend Sheet
// export function investmentsShowTableInitialize(inputData) {
// investmentsShowTableData = sheets.investmentsShowTable(module1Data, module9Data, incomeStatementData, investm5ntsData, investm5ntsOutputBackendData);
// }

export function investmentsShowTableGraphData() {
  return investmentsShowTableData;
}

// New Higher Education Output backend Sheet // today
export function newHigherEducationOutputInitialize() {
  newHigherEdOutputData = sheets.newHigherEdOutput(module15Data, newHigherStudentLoanData, incomeStatementData, cashFlowData, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, databaseData, inputTaxesData);
}
export function newInvestmentOutputBackendCompleteData() {
  return newHigherEdOutputData;
}

// New Higher Education payback Sheet
export function newHigherEducationPaybackInitialize(inputData) {
  newHigherEdPaybackData = sheets.newHigherEdPayback(module15Data, inputData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, databaseData);
  return newHigherEdPaybackData;
}

export function newInvestmentPaybackBackendCompleteData() {
  return newHigherEdPaybackData;
}

// Further Career Advancement Output Backend Sheet
export function furtherCareerAdvOutputBackendInitialize() {
  furtherCareerAdvOutputBackendData = sheets.furtherCareerAdvOutputBackend(module17Data, incomeStatementData, investmentsData, expenseCalculationsData);
}
export function furtherCareerAdvOutputGraphData() {
  return furtherCareerAdvOutputBackendData;
}

// Existing Student Loans Output backend Sheet
export function existingStudentLoanBackendOutputInitialize() {
  existingStudentLoanBackendData = sheets.existingStudentLoanBackend(module1Data, fedSubExistingLoanData, fedUnSubExistingLoanData, privateExistingLoanData, existingHomeData, existingHomeMortgageData, existingCarLoanData, existingCreditCardDebtData);
}
export function existingStudentLoanOutputGraphData() {
  return existingStudentLoanBackendData;
}

// Existing Student Loans whatif output backend Sheet
// export function existingStudentLoanOutputInitialize = function(inputData4) {
// existingStudentLoanOutputBackend.initialize(module1Data, module15Data, fedSubExistingLoanData, fedUnSubExistingLoanData, privateExistingLoanData, incomeStatementData, investm5ntsData, inputData4);
// existin5StudentLoanOutputBackendData = existingStudentLoanOutputBackend.existingStudentLoanOutputBackendOutputData();
// return existin5StudentLoanOutputBackendData;
// }
export function existingStudentLoanOutputWhatIfOutputData() {
  return existingStudentLoanOutputBackendData;
}

// Refined Student Loans
// export function refinedStudentLoansInitialize(inputData5) {
//     refinedStudentLoans.initialize(module1Data, module15Data, inputData5);
//     refined5tudentLoansData = refinedStudentLoans.refinedStudentLoansDataData();
//     return refined5tudentLoansData;
// }

export function refinedStudentLoansOutputData() {
  return refinedStudentLoansData;
}

// Tax Statement Excel Sheet
export function taxStatementInitialize() {
  taxStatementData = sheets.taxStatement(module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module9Data, module10Data,
    module13Data, module14Data, module15Data, module17Data, module18Data, existingStudentLoanData, studentLoanTaxData, expenseCalculationsData,
    assumptionsData, inputTaxesData, investmentsData, depreciationSheduleData, existingCarLoanData, carLoan1Data, carLoan2Data,
    mortgage1Data, mortgage2Data, buyingAHomeData, invPropertyRoiData, databaseData, fedSubHigherEdLoanData,
    fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData,
    fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData, fedUnSubExistingLoanData,
    privateExistingLoanData, existingHomeData, existingCreditCardDebtData, existingHomeMortgageData, incomeStatementData, cashFlowData);
}
export function taxStatementCompleteData() {
  return taxStatementData;
}

//Start year graph data for financial independence conversation graph data
export function carsExistingVehicleDepreciationInitialize() {
  carsExistingVehicleDepreciationData = sheets.carsExistingVehicleDepreciation(module1Data, module12Data, module18Data, assumptionsData);
}
export function carsExistingVehicleDepreciation() {
  return carsExistingVehicleDepreciationData;
}


// Spouse Student Loans
export function spouseStudentLoanDataInitialize() {
  spouseStudentLoanData = sheets.spouseStudentLoan(module1Data, module10Data);
}
export function spouseStudentLoanCompleteData() {
  return spouseStudentLoanData;
}

// Short Term Table Data
export function shortTermTableInitialize() {
  shortTermTableData = sheets.shortTermTable(module1Data, module9Data, investmentsData, cashFlowData);
}
export function shortTermTableCompleteData() {
  return shortTermTableData;
}

// Long Term Table Data
export function longTermTableInitialize() {
  longTermTableData = sheets.longTermTable(module1Data, module9Data, investmentsData, cashFlowData);
}
export function longTermTableCompleteData() {
  return longTermTableData;
}

// Start year graph data for financial independence conversation graph data
export function startYearGraphInitialize() {
  // startYearGraph.initialize(moduleServices);
  // startYe5rGraphData = startYearGraph.firstYearGraphData();
}
export function firstYearGraphData() {
  return startYearGraphData;
}

/** ******* End of Initializing Excel Sheets Data *********** */

export function cashFlowCheck() {
  cashFlowCheckVal = 0;
  if (cashFlowData !== undefined && cashFlowData['Cash Flow Check'] !== undefined && cashFlowData['Cash Flow Check'] !== '') {
    cashFlowCheckVal = cashFlowData['Cash Flow Check'];
  }
  return cashFlowCheckVal;
}

// set Financial independence values
export function setModule1Data(data) {
  module1Data = { ...module1Data, ...data };
}

// set Financial independence default values
export function setDefault1Data(data) {
  setModule1Data(data);
}

// set Higher education values
export function setModule2Data(data) {
  let s = statesList[data.state_id];
  data.state = s
  module2Data = { ...module2Data, ...data };
}

// set Higher education default values
export function setDefault2Data(data) {
  setModule2Data(data);
}

export function setBeforeLoginData(data) {
  beforeLoginModuleData = { ...data };
}

// set Career path values
export function setModule4Data(data) {
  let s = (data.state_id > 0 && data.state_id !== null) ? statesList[data.state_id - 1] : "";
  data.state = s
  const updateData = data;
  if (updateData.self_employment === 'Yes') {
    updateData.contribute_401k = "No";
    updateData.roth_contribute_401k = "No";
    updateData.contribute_hsa_savings_account = "Yes";
    updateData.pension_contribute = "No";
  } else {
    updateData.ira_contribute = "No";
    updateData.contribute_hsa_savings_account = "Yes";
  }
  if (updateData.occupation !== '') {
    updateData.occupation_title = `${updateData.occupation} - Income`;
    updateData.occupation_title1 = `${updateData.occupation} - Monthly Living Expenses`;
  } else {
    updateData.occupation_title = 'Expected Income';
    updateData.occupation_title1 = 'Monthly Living Expenses';
  }
  module4Data = { ...module4Data, ...updateData };
}

// set Career path default values
export function setDefault4Data(data) {
  setModule4Data(data);
}

// set Career advancement values
export function setModule5Data(data) {
  let s = (data.state_id > 0 && data.state_id !== null) ? statesList[data.state_id - 1] : "";
  data.state = s
  const updateData = data;
  if (updateData.self_employment === 'Yes') {
    updateData.contribute_401k = "No";
    updateData.roth_contribute_401k = "No";
    updateData.contribute_hsa_savings_account = "Yes";
    updateData.pension_contribute = "No";
  } else {
    updateData.contribute_hsa_savings_account = "Yes";
    updateData.ira_contribute = "No";
  }
  if (updateData.occupation !== '') {
    updateData.occupation_title = `${updateData.occupation} - Income`;
    updateData.occupation_title1 = `${updateData.occupation} - Monthly Living Expenses`;
  } else {
    updateData.occupation_title = 'Expected Income';
    updateData.occupation_title1 = 'Monthly Living Expenses';
  }
  module5Data = { ...module5Data, ...updateData };
}
// set Career advencement default values
export function setDefault5Data(data) {
  setModule5Data(data);
}

// set More higher education values
export function setModule6Data(data) {
  let s = (data.state_id > 0 && data.state_id !== null) ? statesList[data.state_id - 1] : "";
  data.state = s
  module6Data = { ...module6Data, ...data };
}

// set More higher education default values
export function setDefault6Data(data) {
  setModule6Data(data);
}

// set additional career advancement values
export function setModule8Data(data) {
  let s = (data.state_id > 0 && data.state_id !== null) ? statesList[data.state_id - 1] : "";
  data.state = s
  const updateData = data;
  if (updateData.self_employment === 'Yes') {
    updateData.contribute_hsa_savings_account = "Yes";
    updateData.contribute_401k = "No";
    updateData.roth_contribute_401k = "No";
    updateData.pension_contribute = "No";
  } else {
    updateData.contribute_hsa_savings_account = "Yes";
    updateData.ira_contribute = "No";
  }
  if (updateData.occupation !== '') {
    updateData.occupation_title = `${updateData.occupation} - Income`;
    updateData.occupation_title1 = `${updateData.occupation} - Monthly Living Expenses`;
  } else {
    updateData.occupation_title = 'Expected Income';
    updateData.occupation_title1 = 'Monthly Living Expenses';
  }
  module8Data = { ...module8Data, ...updateData };
}

// set additional career advancement default values
export function setDefault8Data(data) {
  setModule8Data(data);
}

// set investments values
export function setModule9Data(data) {
  module9Data = { ...module9Data, ...data };
}

// set investments default values
export function setDefault9Data(data) {
  setModule9Data(data);
}

// set Family values
export function setModule10Data(data) {
  module10Data = { ...module10Data, ...data };
}

// set Family default values
export function setDefault10Data(data) {
  setModule10Data(data);
}

// set Vacation values
export function setModule11Data(data) {
  const loadData = data;
  if (loadData.purchase_item !== undefined && loadData.purchase_item) {
    loadData.other_acquisition1 = loadData.purchase_item;
  } else {
    loadData.other_acquisition1 = 'Special Purchase 1';
  }
  if (loadData.second_purchase_item !== undefined && loadData.second_purchase_item) {
    loadData.other_acquisition2 = loadData.second_purchase_item;
  } else {
    loadData.other_acquisition2 = 'Special Purchase 2';
  }
  module11Data = { ...module11Data, ...loadData };
}

// set Vacation default values
export function setDefault11Data(data) {
  setModule10Data(data);
}

// set Car values
export function setModule12Data(data) {
  module12Data = { ...module12Data, ...data };
}

// set Car default values
export function setDefault12Data(data) {
  setModule12Data(data);
}

// set Home values
export function setModule13Data(data) {
  module13Data = { ...module13Data, ...data };
}

// set Home default values
export function setDefault13Data(data) {
  setModule13Data(data);
}

// Set REI values
export function setModule14Data(data) {
  module14Data = { ...module14Data, ...data };
}

// Set REI default values
export function setDefault14Data(data) {
  setModule14Data(data);
}

// Set Further Higher education values
export function setModule15Data(data) {
  let s = (data.state_id > 0 && data.state_id !== null) ? statesList[data.state_id - 1] : "";
  data.state = s
  module15Data = { ...module15Data, ...data };
}

// Set Further Higher education default values
export function setDefault15Data(data) {
  setModule15Data(data);
}

// Set Further Career values
export function setModule17Data(data) {
  let s = (data.state_id > 0 && data.state_id !== null) ? statesList[data.state_id - 1] : "";
  data.state = s
  const updateData = data;
  if (updateData.self_employment === 'Yes') {
    updateData.contribute_hsa_savings_account = "Yes";
    updateData.contribute_401k = "No";
    updateData.roth_contribute_401k = "No";
    updateData.pension_contribute = "No";
  } else {
    updateData.contribute_hsa_savings_account = "Yes";
    updateData.ira_contribute = "No";
  }
  if (updateData.occupation !== '') {
    updateData.occupation_title = `${updateData.occupation} - Income`;
    updateData.occupation_title1 = `${updateData.occupation} - Monthly Living Expenses`;
  } else {
    updateData.occupation_title = 'Expected Income';
    updateData.occupation_title1 = 'Monthly Living Expenses';
  }
  module17Data = { ...module17Data, ...updateData };
}

// Set Further Career  default values
export function setDefault17Data(data) {
  setModule17Data(data);
}

// Set existing assets  values
export function setModule18Data(data) {
  module18Data = { ...module18Data, ...data };
}

// Set existing assets  default values
export function setDefault18Data(data) {
  setModule18Data(data);
}

// Generate Start year dropdown list
export function startYearDropdownList() {
  // Start Years List (1 to 10)
  const startYearsArray = [];
  let i = 0;
  if (module1Data.start_year !== undefined && module1Data.start_year !== '') {
    for (i; i < 15; i += 1) {
      startYearsArray.push(parseInt(module1Data.start_year, 10) + i);
    }
  }
  if (startYearsArray !== '') {
    startYearsArray.unshift(0);
  }
  return startYearsArray;
}

/* Vacation calculations start here */
export function vacationTotalAmountSpent() {
  module11Data.vacationTotalAmountSpent = 0;
  if (cashFlowData !== undefined && cashFlowData !== '' && cashFlowData['Vacation Total'] !== undefined
    && cashFlowData['Other Acquisition Total'] !== undefined && cashFlowData['Other Acquisition2 Total'] !== undefined) {
    module11Data.vacationTotalAmountSpent = -(cashFlowData['Vacation Total'] + cashFlowData['Other Acquisition Total'] + cashFlowData['Other Acquisition2 Total']);
  }
  return module11Data.vacationTotalAmountSpent;
}

export function higherEduMarriageYear() {
  let higherEduMarriageYearVal = '';
  if (module2Data.start_year !== undefined
    && module2Data.start_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module2Data.start_year >= module10Data.marriege_year) {
    higherEduMarriageYearVal = module2Data.start_year;
  } else if (module2Data.start_year !== undefined
    && module2Data.start_year !== ''
    && module2Data.graduation_year !== undefined
    && module2Data.graduation_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module2Data.start_year < module10Data.marriege_year
    && module2Data.graduation_year >= module10Data.marriege_year) {
    higherEduMarriageYearVal = module10Data.marriege_year;
  } else {
    higherEduMarriageYearVal = '';
  }
  module2Data.higherEduMarriageYearVal = higherEduMarriageYearVal;
  return module2Data.higherEduMarriageYearVal;
}

// higher Education Expenses Rent Multiplier
export function higherEducationOffCampusRentMultiplier() {
  module2Data.higherEducationOffCampusRentMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.rent_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusRentMultiplier = module10Data.rent_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusRentMultiplier = module10Data.rent_multiplier;
    } else {
      module2Data.higherEducationOffCampusRentMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusRentMultiplier;
}

// Rent Expenses
export function higherEducationOffCampusRentExpenses() {
  let rentExpenses = 0;
  module2Data.higherEducationOffCampusRentExpenses = 0;
  if (module2Data.higherEducationOffCampusRentMultiplier !== undefined
    && module2Data.higherEducationOffCampusRentMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.rent !== undefined
      && module2Data.rent !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Rent !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Rent !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Rent) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.rent !== '' && module2Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Rent) / 12);
      } else {
        rentExpenses = 0;
      }
    }
  } else {
    rentExpenses = '';
  }
  module2Data.higherEducationOffCampusRentExpenses = rentExpenses;

  return module2Data.higherEducationOffCampusRentExpenses;
}

// higher Education Expenses Utilities Multiplier
export function higherEducationOffCampusUtilitiesMultiplier() {
  module2Data.higherEducationOffCampusUtilitiesMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.utilities_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else {
      module2Data.higherEducationOffCampusUtilitiesMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusUtilitiesMultiplier;
}

// Utilities Expenses
export function higherEducationOffCampusUtilitiesExpenses() {
  let utilitiesExpense = 0;
  module2Data.higherEducationOffCampusUtilitiesExpenses = 0;
  if (module2Data.higherEducationOffCampusUtilitiesMultiplier !== undefined
    && module2Data.higherEducationOffCampusUtilitiesMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.utilities !== undefined
      && module2Data.utilities !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Utilities !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Utilities !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Utilities) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.utilities !== '' && module2Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities) / 12);
      } else {
        utilitiesExpense = 0;
      }
    }
  } else {
    utilitiesExpense = '';
  }
  module2Data.higherEducationOffCampusUtilitiesExpenses = utilitiesExpense;

  return module2Data.higherEducationOffCampusUtilitiesExpenses;
}

// higher Education Expenses Food Multiplier
export function higherEducationOffCampusFoodMultiplier() {
  module2Data.higherEducationOffCampusFoodMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.food_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusFoodMultiplier = module10Data.food_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusFoodMultiplier = module10Data.food_multiplier;
    } else {
      module2Data.higherEducationOffCampusFoodMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusFoodMultiplier;
}

// Food Expenses
export function higherEducationOffCampusFoodExpenses() {
  let foodExpenses = 0;
  module2Data.higherEducationOffCampusFoodExpenses = 0;
  if (module2Data.higherEducationOffCampusFoodMultiplier !== undefined
    && module2Data.higherEducationOffCampusFoodMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.food !== undefined
      && module2Data.food !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Food !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Food !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.food > 0) {
        foodExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Food) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.food !== '' && module2Data.food > 0) {
        foodExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Food) / 12);
      } else {
        foodExpenses = 0;
      }
    }
  } else {
    foodExpenses = '';
  }
  module2Data.higherEducationOffCampusFoodExpenses = foodExpenses;

  return module2Data.higherEducationOffCampusFoodExpenses;
}

// Higher Education Clothing Multiplier
export function higherEducationOffCampusClothingMultiplier() {
  module2Data.higherEducationOffCampusClothingMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.clothing_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusClothingMultiplier = module10Data.clothing_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusClothingMultiplier = module10Data.clothing_multiplier;
    } else {
      module2Data.higherEducationOffCampusClothingMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusClothingMultiplier;
}

// Clothing Expenses
export function higherEducationOffCampusClothingExpenses() {
  let clothingExpenses = 0;
  module2Data.higherEducationOffCampusClothingExpenses = 0;
  if (module2Data.higherEducationOffCampusClothingMultiplier !== undefined
    && module2Data.higherEducationOffCampusClothingMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.clothing !== undefined
      && module2Data.clothing !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Clothing !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Clothing !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Clothing) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.clothing !== '' && module2Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing) / 12);
      } else {
        clothingExpenses = 0;
      }
    }
  } else {
    clothingExpenses = '';
  }
  module2Data.higherEducationOffCampusClothingExpenses = clothingExpenses;

  return module2Data.higherEducationOffCampusClothingExpenses;
}

// Higher Education Entertainment Multiplier
export function higherEducationOffCampusEntertainmentMultiplier() {
  module2Data.higherEducationOffCampusEntertainmentMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.entertainment_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else {
      module2Data.higherEducationOffCampusEntertainmentMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusEntertainmentMultiplier;
}

// Off campus Entertainment Expenses
export function higherEducationOffCampusEntertainmentExpenses() {
  let entertainmentExpenses = 0;
  module2Data.higherEducationOffCampusEntertainmentExpenses = 0;
  if (module2Data.higherEducationOffCampusEntertainmentMultiplier !== undefined
    && module2Data.higherEducationOffCampusEntertainmentMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.entertainment !== undefined
      && module2Data.entertainment !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Entertainment !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Entertainment !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Entertainment) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.entertainment !== '' && module2Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment) / 12);
      } else {
        entertainmentExpenses = 0;
      }
    }
  } else {
    entertainmentExpenses = '';
  }
  module2Data.higherEducationOffCampusEntertainmentExpenses = entertainmentExpenses;

  return module2Data.higherEducationOffCampusEntertainmentExpenses;
}

// Higher Education Technology Multiplier
export function higherEducationOffCampusTechnologyMultiplier() {
  module2Data.higherEducationOffCampusTechnologyMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.technology_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusTechnologyMultiplier = module10Data.technology_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusTechnologyMultiplier = module10Data.technology_multiplier;
    } else {
      module2Data.higherEducationOffCampusTechnologyMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusTechnologyMultiplier;
}

// Off campus Technology Expenses
export function higherEducationOffCampusTechnologyExpenses() {
  let technologyExpenses = 0;
  module2Data.higherEducationOffCampusTechnologyExpenses = 0;
  if (module2Data.higherEducationOffCampusTechnologyMultiplier !== undefined
    && module2Data.higherEducationOffCampusTechnologyMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.technology !== undefined
      && module2Data.technology !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Technology) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.technology !== '' && module2Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Technology) / 12);
      } else {
        technologyExpenses = 0;
      }
    }
  } else {
    technologyExpenses = '';
  }
  module2Data.higherEducationOffCampusTechnologyExpenses = technologyExpenses;

  return module2Data.higherEducationOffCampusTechnologyExpenses;
}

// Higher Education Transportation Multiplier
export function higherEducationOffCampusTransportationMultiplier() {
  module2Data.higherEducationOffCampusTransportationMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.transportation_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusTransportationMultiplier = module10Data.transportation_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusTransportationMultiplier = module10Data.transportation_multiplier;
    } else {
      module2Data.higherEducationOffCampusTransportationMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusTransportationMultiplier;
}

// Off campus Transportation Expenses
export function higherEducationOffCampusTransportationExpenses() {
  let transportationExpenses = 0;
  module2Data.higherEducationOffCampusTransportationExpenses = 0;
  if (module2Data.higherEducationOffCampusTransportationMultiplier !== undefined
    && module2Data.higherEducationOffCampusTransportationMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.transportation !== undefined
      && module2Data.transportation !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Transportation !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Transportation !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Transportation) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.transportation !== '' && module2Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation) / 12);
      } else {
        transportationExpenses = 0;
      }
    }
  } else {
    transportationExpenses = '';
  }
  module2Data.higherEducationOffCampusTransportationExpenses = transportationExpenses;

  return module2Data.higherEducationOffCampusTransportationExpenses;
}

// Higher Education miscellaneous Multiplier
export function higherEducationOffCampusMiscellaneousMultiplier() {
  module2Data.higherEducationOffCampusMiscellaneousMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.miscellaneous_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else {
      module2Data.higherEducationOffCampusMiscellaneousMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusMiscellaneousMultiplier;
}

// Off campus Miscellaneous Expenses
export function higherEducationOffCampusMiscellaneousExpenses() {
  let miscellaneousExpenses = 0;
  module2Data.higherEducationOffCampusMiscellaneousExpenses = 0;
  if (module2Data.higherEducationOffCampusMiscellaneousMultiplier !== undefined
    && module2Data.higherEducationOffCampusMiscellaneousMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.miscellaneous !== undefined
      && module2Data.miscellaneous !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Miscellaneous !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Miscellaneous !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses.Miscellaneous) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.miscellaneous !== '' && module2Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous) / 12);
      } else {
        miscellaneousExpenses = 0;
      }
    }
  } else {
    miscellaneousExpenses = '';
  }
  module2Data.higherEducationOffCampusMiscellaneousExpenses = miscellaneousExpenses;

  return module2Data.higherEducationOffCampusMiscellaneousExpenses;
}

// Higher Education health Insurance Multiplier
export function higherEducationOffCampusHealthInsuranceMultiplier() {
  module2Data.higherEducationOffCampusHealthInsuranceMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.health_insurance_costs_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusHealthInsuranceMultiplier = module10Data.health_insurance_costs_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusHealthInsuranceMultiplier = module10Data.health_insurance_costs_multiplier;
    } else {
      module2Data.higherEducationOffCampusHealthInsuranceMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusHealthInsuranceMultiplier;
}

// Higher Education off Campus Outside Premium Expenses
export function higherEducationOffCampusOutsidePremiumExpenses() {
  let outsidePremiumExpenses = 0;
  module2Data.higherEducationOffCampusOutsidePremiumExpenses = 0;
  if (module2Data.higherEducationOffCampusHealthInsuranceMultiplier !== undefined
    && module2Data.higherEducationOffCampusHealthInsuranceMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.medical_costs_before_deductible !== undefined
      && module2Data.medical_costs_before_deductible !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses['Healthcare Costs other than Premium'] !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.medical_costs_before_deductible > 0) {
        outsidePremiumExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.medical_costs_before_deductible !== '' && module2Data.medical_costs_before_deductible > 0) {
        outsidePremiumExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else {
        outsidePremiumExpenses = 0;
      }
    }
  } else {
    outsidePremiumExpenses = '';
  }
  module2Data.higherEducationOffCampusOutsidePremiumExpenses = outsidePremiumExpenses;

  return module2Data.higherEducationOffCampusOutsidePremiumExpenses;
}

// Higher Education health Insurance Premium Multiplier
export function higherEducationOffCampusHealthInsurancePremiumMultiplier() {
  module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier = 0;
  if (module2Data.off_campus_start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.health_insurance_premium_multiplier !== undefined) {
    if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else if (module2Data.off_campus_start_year !== '' && module10Data.marriege_year !== '' && module2Data.graduation_year !== '' && module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year) {
      module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else {
      module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier = '';
    }
  }
  return module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier;
}

// Higher Education off Campus Premium Expenses
export function higherEducationOffCampusPremiumExpenses() {
  let premiumExpenses = 0;
  module2Data.higherEducationOffCampusPremiumExpenses = 0;
  if (module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier !== undefined
    && module2Data.higherEducationOffCampusHealthInsurancePremiumMultiplier !== '') {
    if (module2Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module2Data.health_insurance_premium !== undefined
      && module2Data.health_insurance_premium !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year] !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses !== ''
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses['Health Insurance Premium'] !== undefined
      && incomeStatementData[module2Data.off_campus_start_year].livingExpenses['Health Insurance Premium'] !== '') {
      if (module2Data.off_campus_start_year !== '' && module2Data.off_campus_start_year >= module10Data.marriege_year && module10Data.marriege_year !== '' && module2Data.health_insurance_premium > 0) {
        premiumExpenses = -((incomeStatementData[module2Data.off_campus_start_year].livingExpenses['Health Insurance Premium']) / 12);
      } else if (module2Data.off_campus_start_year < module10Data.marriege_year && module2Data.graduation_year >= module10Data.marriege_year && module2Data.off_campus_start_year !== '' && module2Data.graduation_year !== '' && module10Data.marriege_year !== '' && module2Data.health_insurance_premium !== '' && module2Data.health_insurance_premium > 0) {
        premiumExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium']) / 12);
      } else {
        premiumExpenses = 0;
      }
    }
  } else {
    premiumExpenses = '';
  }
  module2Data.higherEducationOffCampusPremiumExpenses = premiumExpenses;

  return module2Data.higherEducationOffCampusPremiumExpenses;
}

export function higherEducationOffCampusTotalExpenses() {
  module2Data.higherEducationOffCampusTotalExpenses = 0;
  let totalExpenses = 0;
  if (module2Data.higherEducationOffCampusRentExpenses !== undefined
    && module2Data.higherEducationOffCampusRentExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusRentExpenses;
  }
  if (module2Data.higherEducationOffCampusUtilitiesExpenses !== undefined && module2Data.higherEducationOffCampusUtilitiesExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusUtilitiesExpenses;
  }
  if (module2Data.higherEducationOffCampusFoodExpenses !== undefined && module2Data.higherEducationOffCampusFoodExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusFoodExpenses;
  }
  if (module2Data.higherEducationOffCampusClothingExpenses !== undefined && module2Data.higherEducationOffCampusClothingExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusClothingExpenses;
  }
  if (module2Data.higherEducationOffCampusEntertainmentExpenses !== undefined && module2Data.higherEducationOffCampusEntertainmentExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusEntertainmentExpenses;
  }
  if (module2Data.higherEducationOffCampusTechnologyExpenses !== undefined && module2Data.higherEducationOffCampusTechnologyExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusTechnologyExpenses;
  }
  if (module2Data.higherEducationOffCampusTransportationExpenses !== undefined && module2Data.higherEducationOffCampusTransportationExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusTransportationExpenses;
  }
  if (module2Data.higherEducationOffCampusMiscellaneousExpenses !== undefined && module2Data.higherEducationOffCampusMiscellaneousExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusMiscellaneousExpenses;
  }
  if (module2Data.higherEducationOffCampusOutsidePremiumExpenses !== undefined && module2Data.higherEducationOffCampusOutsidePremiumExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusOutsidePremiumExpenses;
  }
  if (module2Data.higherEducationOffCampusPremiumExpenses !== undefined && module2Data.higherEducationOffCampusPremiumExpenses !== '') {
    totalExpenses += module2Data.higherEducationOffCampusPremiumExpenses;
  }
  module2Data.higherEducationOffCampusTotalExpenses = totalExpenses;
  return module2Data.higherEducationOffCampusTotalExpenses;
}

// Offcampus Total Living Expenses
export function offCampusTotalExpenses() {
  module2Data.offCampusTotalExpenses = 0;
  if (module2Data.rent !== undefined
    && module2Data.utilities !== undefined
    && module2Data.food !== undefined
    && module2Data.second_clothing !== undefined
    && module2Data.second_entertainment !== undefined
    && module2Data.second_technology !== undefined
    && module2Data.second_transportation !== undefined
    && module2Data.second_miscellaneous !== undefined
    && module2Data.second_medical_costs_before_deductible !== undefined
    && module2Data.second_health_insurance_premium !== undefined) {
    module2Data.offCampusTotalExpenses = parseFloat(module2Data.rent)
      + parseFloat(module2Data.utilities)
      + parseFloat(module2Data.food)
      + parseFloat(module2Data.second_clothing)
      + parseFloat(module2Data.second_entertainment)
      + parseFloat(module2Data.second_technology)
      + parseFloat(module2Data.second_transportation)
      + parseFloat(module2Data.second_miscellaneous)
      + parseFloat(module2Data.second_medical_costs_before_deductible)
      + parseFloat(module2Data.second_health_insurance_premium);
  }
  return module2Data.offCampusTotalExpenses;
}

// Oncampus Total Living Expenses
export function onCampusTotalExpenses() {
  module2Data.onCampusTotalExpenses = 0;
  if (module2Data.fetchRoomBoard !== undefined
    && module2Data.off_campus_food !== undefined
    && module2Data.clothing !== undefined
    && module2Data.entertainment !== undefined
    && module2Data.technology !== undefined
    && module2Data.transportation !== undefined
    && module2Data.miscellaneous !== undefined
    && module2Data.medical_costs_before_deductible !== undefined
    && module2Data.health_insurance_premium !== undefined) {
    module2Data.onCampusTotalExpenses = parseFloat(module2Data.fetchRoomBoard)
      + parseFloat(module2Data.off_campus_food)
      + parseFloat(module2Data.clothing)
      + parseFloat(module2Data.entertainment)
      + parseFloat(module2Data.technology)
      + parseFloat(module2Data.transportation)
      + parseFloat(module2Data.miscellaneous)
      + parseFloat(module2Data.medical_costs_before_deductible)
      + parseFloat(module2Data.health_insurance_premium);
  }
  return module2Data.onCampusTotalExpenses;
}

/** Portion 1 * */
// Room and Board
export function fetchRoomBoard() {
  module2Data.fetchRoomBoard = 0;
  if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year !== ''
    && module2Data.room_board !== undefined && module2Data.room_board !== '') {
    module2Data.fetchRoomBoard = parseFloat(module2Data.room_board / 12).toFixed(2);
  }
  return module2Data.fetchRoomBoard;
}

// Years
export function onCampusMonthlyExpensesYears() {
  module2Data.onCampusYearsDiff = 0;
  var expenseCalculationb8 = 0;
  if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    expenseCalculationb8 = module2Data.graduation_year;
  } else {
    if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year) {
      expenseCalculationb8 = parseInt(module2Data.off_campus_start_year) - 1;
    } else {
      if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
        expenseCalculationb8 = module2Data.graduation_year;
      } else {
        expenseCalculationb8 = 0;
      }
    }
  }

  if (expenseCalculationb8 > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year !== '') {
    module2Data.onCampusYearsDiff = expenseCalculationb8 - module2Data.on_campus_start_year + 1;
  }

  return module2Data.onCampusYearsDiff;
}

// Monthly Price off Campus
export function monthlyPriceOffCampus() {
  module2Data.monthlyPriceOffCampus = 0;
  if (module2Data.onCampusTotalExpenses !== undefined
    && module2Data.offCampusTotalExpenses !== undefined) {
    module2Data.monthlyPriceOffCampus = module2Data.offCampusTotalExpenses - module2Data.onCampusTotalExpenses;
  }
  return module2Data.monthlyPriceOffCampus;
}
// Monthly Percentage off Campus
export function monthlyPercentageOffCampus() {
  module2Data.monthlyPercentageOffCampus = 0;
  if (module2Data.onCampusTotalExpenses !== undefined && module2Data.onCampusTotalExpenses !== '' && module2Data.onCampusTotalExpenses > 0
    && module2Data.offCampusTotalExpenses !== undefined) {
    module2Data.monthlyPercentageOffCampus = ((module2Data.offCampusTotalExpenses - module2Data.onCampusTotalExpenses) / module2Data.onCampusTotalExpenses) * 100;
  }
  return module2Data.monthlyPercentageOffCampus;
}

// Existing Student Loan Payments
export function existingSubLoanPayments() {
  let existingSubLoanPaymentss = 0;
  if (module2Data.start_year !== undefined && parseInt(module2Data.start_year, 10) > 0 && module2Data.graduation_year !== undefined && parseInt(module2Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module2Data.start_year, 10); i <= parseInt(module2Data.graduation_year, 10); i += 1) {
      if (fedSubExistingLoanData !== undefined && fedSubExistingLoanData !== '' && fedSubExistingLoanData['Summation Data'] !== undefined && fedSubExistingLoanData['Summation Data'] !== ''
        && fedSubExistingLoanData['Summation Data'][i] !== undefined && fedSubExistingLoanData['Summation Data'][i] !== ''
        && fedSubExistingLoanData['Summation Data'][i].LaggingInterest !== undefined && fedSubExistingLoanData['Summation Data'][i].LaggingInterest !== '') {
        existingSubLoanPaymentss += fedSubExistingLoanData['Summation Data'][i].LaggingInterest;
        existingSubLoanPaymentss += fedSubExistingLoanData['Summation Data'][i].LaggingPrincipal;
      }
      if (fedUnSubExistingLoanData !== undefined && fedUnSubExistingLoanData !== '' && fedUnSubExistingLoanData['Summation Data'] !== undefined && fedUnSubExistingLoanData['Summation Data'] !== ''
        && fedUnSubExistingLoanData['Summation Data'][i] !== undefined && fedUnSubExistingLoanData['Summation Data'][i] !== ''
        && fedUnSubExistingLoanData['Summation Data'][i].LaggingInterest !== undefined && fedUnSubExistingLoanData['Summation Data'][i].LaggingInterest !== '') {
        existingSubLoanPaymentss += fedUnSubExistingLoanData['Summation Data'][i].LaggingInterest;
        existingSubLoanPaymentss += fedUnSubExistingLoanData['Summation Data'][i].LaggingPrincipal;
      }
      if (privateExistingLoanData !== undefined && privateExistingLoanData !== '' && privateExistingLoanData['Summation Data'] !== undefined && privateExistingLoanData['Summation Data'] !== ''
        && privateExistingLoanData['Summation Data'][i] !== undefined && privateExistingLoanData['Summation Data'][i] !== ''
        && privateExistingLoanData['Summation Data'][i].LaggingInterest !== undefined && privateExistingLoanData['Summation Data'][i].LaggingInterest !== '') {
        existingSubLoanPaymentss += privateExistingLoanData['Summation Data'][i].LaggingInterest;
        existingSubLoanPaymentss += privateExistingLoanData['Summation Data'][i].LaggingPrincipal;
      }
    }
  }
  module2Data.existingSubLoanPayments = existingSubLoanPaymentss;
  return module2Data.existingSubLoanPayments;
}

// College Student Loan Payments
export function collegeStudentLoanPayments() {
  module2Data.collegeStudentLoanPayments = 0;
  if (module2Data.totalStudentsLoanPayments !== undefined && module2Data.existingSubLoanPayments !== undefined) {
    module2Data.collegeStudentLoanPayments = module2Data.totalStudentsLoanPayments - module2Data.existingSubLoanPayments;
  }
  return module2Data.collegeStudentLoanPayments;
}

export function totalStudentsLoanPayments() {
  let totalStudentsLoanPaymentss = 0;
  if (module2Data.start_year !== undefined && parseInt(module2Data.start_year, 10) > 0 && module2Data.graduation_year !== undefined && parseInt(module2Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module2Data.start_year, 10); i <= parseInt(module2Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].InterestExpenses !== undefined && incomeStatementData[i].InterestExpenses !== ''
        && incomeStatementData[i].InterestExpenses['Student Loans'] !== undefined && incomeStatementData[i].InterestExpenses['Student Loans'] !== '') {
        totalStudentsLoanPaymentss += incomeStatementData[i].InterestExpenses['Student Loans'];
      }
    }
  }
  module2Data.totalStudentsLoanPayments = -(totalStudentsLoanPaymentss);
  return module2Data.totalStudentsLoanPayments;
}

export function totalTuitionAndFees() {
  module2Data.totalTuitionAndFees = 0;
  let higherEducationTotalTuitionFees = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year !== ''
    && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    const start_year = parseInt(module2Data.start_year, 10);
    const endYear = parseInt(module2Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (data[year] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined) {
            higherEducationTotalTuitionFees += data[year].livingExpenses['Higher Education Tuition and Fees'];
          }
        }
      });
    }
    module2Data.totalTuitionAndFees = -higherEducationTotalTuitionFees;
  }
  return module2Data.totalTuitionAndFees;
}

export function totalRoomAndBoard() {
  module2Data.totalRoomAndBoard = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year !== ''
    && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    const start_year = parseInt(module2Data.start_year, 10);
    const endYear = parseInt(module2Data.graduation_year, 10);
    const data = incomeStatementData;
    let higherEducationTotalRoomAndBoard = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (data[year] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Room & Board'] !== undefined) {
            higherEducationTotalRoomAndBoard += data[year].livingExpenses['Higher Education Room & Board'];
          }
        }
      });
    }
    module2Data.totalRoomAndBoard = -higherEducationTotalRoomAndBoard;
  }
  return module2Data.totalRoomAndBoard;
}

export function higherOnCampusLivingExpenses() {
  module2Data.higherOnCampusLivingExpenses = 0;
  // Expense calculation B8
  let expenseCalculationb8 = 0;
  let higherTotalLivingExpenses = 0;
  let higherRoomandBoardValue = 0;
  let higherTuitionAndFeesValue = 0;

  if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    expenseCalculationb8 = module2Data.graduation_year;
  } else if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year) {
    expenseCalculationb8 = parseInt(module2Data.off_campus_start_year, 10) - 1;
  } else if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    expenseCalculationb8 = module2Data.graduation_year;
  } else {
    expenseCalculationb8 = 0;
  }

  if (module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0) {
    const start_year = parseInt(module2Data.on_campus_start_year, 10);
    const endYear = parseInt(expenseCalculationb8, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          higherTotalLivingExpenses += data[year]['Total Living Expenses'];
          higherRoomandBoardValue += data[year].livingExpenses['Higher Education Room & Board'];
          higherTuitionAndFeesValue += data[year].livingExpenses['Higher Education Tuition and Fees'];
        }
      });
    }
  }
  module2Data.higherOnCampusLivingExpenses = -(higherTotalLivingExpenses - higherRoomandBoardValue - higherTuitionAndFeesValue);
  return module2Data.higherOnCampusLivingExpenses;
}

export function higherOffCampusLivingExpenses() {
  module2Data.higherOffCampusLivingExpenses = 0;
  // Expense calculation B8
  let expenseCalculationb24 = 0;
  let higherTotalLivingExpenses = 0;
  let higherRoomandBoardValue = 0;
  let higherTuitionAndFeesValue = 0;

  if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    expenseCalculationb24 = module2Data.graduation_year;
  } else if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year) {
    expenseCalculationb24 = parseInt(module2Data.on_campus_start_year, 10) - 1;
  } else if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    expenseCalculationb24 = module2Data.graduation_year;
  } else {
    expenseCalculationb24 = 0;
  }
  if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0) {
    const start_year = parseInt(module2Data.off_campus_start_year, 10);
    const endYear = parseInt(expenseCalculationb24, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (data[year] !== undefined) {
            higherTotalLivingExpenses += data[year]['Total Living Expenses'];
          }
          if (data[year] !== undefined && data[year].livingExpenses !== undefined) {
            higherRoomandBoardValue += data[year].livingExpenses['Higher Education Room & Board'];
            higherTuitionAndFeesValue += data[year].livingExpenses['Higher Education Tuition and Fees'];
          }
        }
      });
    }
  }
  module2Data.higherOffCampusLivingExpenses = -(higherTotalLivingExpenses - higherRoomandBoardValue - higherTuitionAndFeesValue)
  return module2Data.higherOffCampusLivingExpenses;
}

export function higherHighertTotalCostOfAttendance() {
  module2Data.educationTotalAttendance = module2Data.totalTuitionAndFees
    + module2Data.totalRoomAndBoard
    + module2Data.higherOnCampusLivingExpenses
    + module2Data.higherOffCampusLivingExpenses;
  /*+module15Data.totalStudentsLoanPayments
  +module15Data.otherLoanPaymentsonOtherDebt
  +module15Data.higherCarExpense
  +module15Data.higherRealEstateExpenses
  +module15Data.higherChildrenAndPetExpenses;*/
  return module2Data.educationTotalAttendance;
}

// Estimate Income After Tax
export function calEstimateIncomeAfterTax() {
  module2Data.estimateIncomeAfterTax = 0;
  if (module2Data.yearly_income !== undefined && module2Data.yearly_income !== ''
    && module2Data.estimateTaxPaid !== 0) {
    module2Data.estimateIncomeAfterTax = module2Data.yearly_income - module2Data.estimateTaxPaid;
  }
  return module2Data.estimateIncomeAfterTax;
}

// Cal Beginning Balance
export function fedSubBeginningBalance() {
  module2Data.fedSubBeginningBalance = (module2Data.fed_sub_firstyear !== undefined ? module2Data.fed_sub_firstyear : 0)
    + (module2Data.fed_sub_secondyear !== undefined ? module2Data.fed_sub_secondyear : 0)
    + (module2Data.fed_sub_thirdyear !== undefined ? module2Data.fed_sub_thirdyear : 0)
    + (module2Data.fed_sub_fourthyear !== undefined ? module2Data.fed_sub_fourthyear : 0)
    + (module2Data.fed_sub_fifthyear !== undefined ? module2Data.fed_sub_fifthyear : 0)
    + (module2Data.fed_sub_sixthyear !== undefined ? module2Data.fed_sub_sixthyear : 0);
  return module2Data.fedSubBeginningBalance;
}

// Cal Monthly Payment
export function fedSubMonthlyPayment() {
  module2Data.fedSubMonthlyPayment = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedSubMonthlyPayment = fedSubHigherEdLoanData['Original Payment'];
  }
  return module2Data.fedSubMonthlyPayment;
}

// Cal Yearly Payment
export function fedSubYearlyPayment() {
  module2Data.fedSubYearlyPayment = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedSubYearlyPayment = module2Data.fedSubMonthlyPayment * 12;
  }
  return module2Data.fedSubYearlyPayment;
}

// Cal Total Interest Paid
export function fedSubTotalInterestPaid() {
  module2Data.fedSubTotalInterestPaid = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedSubTotalInterestPaid = fedSubHigherEdLoanData['Total Interest Paid'];
  }
  return module2Data.fedSubTotalInterestPaid;
}

// Cal Total Prinicipal & Interest
export function fedSubTotalPrincipalInterest() {
  module2Data.fedSubTotalPrincipalInterest = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedSubTotalPrincipalInterest = fedSubHigherEdLoanData['Total Paid'];
  }
  return module2Data.fedSubTotalPrincipalInterest;
}
// Cal Principal
export function fedUnSubPrincipal() {
  module2Data.fedUnSubPrincipal = (module2Data.fed_unsub_firstyear !== undefined ? module2Data.fed_unsub_firstyear : 0)
    + (module2Data.fed_unsub_secondyear !== undefined ? module2Data.fed_unsub_secondyear : 0)
    + (module2Data.fed_unsub_thirdyear !== undefined ? module2Data.fed_unsub_thirdyear : 0)
    + (module2Data.fed_unsub_fourthyear !== undefined ? module2Data.fed_unsub_fourthyear : 0)
    + (module2Data.fed_unsub_fifthyear !== undefined ? module2Data.fed_unsub_fifthyear : 0)
    + (module2Data.fed_unsub_sixthyear !== undefined ? module2Data.fed_unsub_sixthyear : 0);
  return module2Data.fedUnSubPrincipal;
}
// Cal Accrued Interest
export function fedUnSubAccruedInterest() {
  module2Data.fedUnSubAccruedInterest = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedUnSubAccruedInterest = fedUnSubHigherEdLoanData['Accrued Interest'];
  }
  return module2Data.fedUnSubAccruedInterest;
}
// Cal Begginning Balance
export function fedUnSubBeginningBalance() {
  module2Data.fedUnSubBeginningBalance = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedUnSubBeginningBalance = fedUnSubHigherEdLoanData['Beginning Balance'];
  }
  return module2Data.fedUnSubBeginningBalance;
}
// Cal Monthly Payment
export function fedUnSubMonthlyPayment() {
  module2Data.fedUnSubMonthlyPayment = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedUnSubMonthlyPayment = fedUnSubHigherEdLoanData['Original Payment'];
  }
  return module2Data.fedUnSubMonthlyPayment;
}
// Cal Yearly Payment
export function fedUnSubYearlyPayment() {
  module2Data.fedUnSubYearlyPayment = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedUnSubYearlyPayment = module2Data.fedUnSubMonthlyPayment * 12;
  }
  return module2Data.fedUnSubYearlyPayment;
}
// Cal Total Interest Paid
export function fedUnSubTotalInterestPaid() {
  module2Data.fedUnSubTotalInterestPaid = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedUnSubTotalInterestPaid = fedUnSubHigherEdLoanData['Total Interest Paid'];
  }
  return module2Data.fedUnSubTotalInterestPaid;
}
// Cal Total Principal & Interest
export function fedUnSubTotalPrincipalInterest() {
  module2Data.fedUnSubTotalPrincipalInterest = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.fedUnSubTotalPrincipalInterest = fedUnSubHigherEdLoanData['Total Paid'];
  }
  return module2Data.fedUnSubTotalPrincipalInterest;
}
// Estimate Tax Rate
export function calEstimateTaxRate() {
  module2Data.estimateTaxRate = 0;
  if (module2Data.yearly_income !== undefined && module2Data.yearly_income !== '' &&
    module2Data.yearly_income > 0) {
    module2Data.estimateTaxRate = sheets.estimatedIncomeTaxes();
  }
  module2Data.estimateTaxRate = 12.65;
  return module2Data.estimateTaxRate;
}
// Estimate Tax Paid
export function calEstimateTaxPaid() {
  module2Data.estimateTaxPaid = 0;
  if (module2Data.yearly_income !== undefined && module2Data.yearly_income !== ''
    && module2Data.estimateTaxRate !== 0) {
    module2Data.estimateTaxPaid = (module2Data.yearly_income) * (module2Data.estimateTaxRate / 100);
  }
  return module2Data.estimateTaxPaid;
}

// Year Payment Begins
export function fedSubPaymentYearBegins() {
  module2Data.fedSubPaymentYearBegins = 0;
  if (module2Data.graduation_year !== undefined && module2Data.graduation_year > 0
    && module6Data.start_year !== undefined && module6Data.start_year > 0
    && module6Data.start_year === (parseInt(module2Data.graduation_year, 10) + 1)
    && module6Data.graduation_year !== undefined && module6Data.graduation_year > 0) {
    module2Data.fedSubPaymentYearBegins = module6Data.graduation_year + 1;
  } else if (module2Data.graduation_year !== undefined && module2Data.graduation_year > 0
    && module15Data.start_year !== undefined && module15Data.start_year > 0
    && module15Data.start_year === (parseInt(module2Data.graduation_year, 10) + 1)
    && module15Data.graduation_year !== undefined && module15Data.graduation_year > 0) {
    module2Data.fedSubPaymentYearBegins = module15Data.graduation_year + 1;
  } else if (module2Data.graduation_year !== undefined && module2Data.graduation_year > 0) {
    module2Data.fedSubPaymentYearBegins = parseInt(module2Data.graduation_year, 10) + 1;
  } else {
    module2Data.fedSubPaymentYearBegins = 0;
  }
  return module2Data.fedSubPaymentYearBegins;
}

// Fed Unsub organization value
export function fedUnSubOrganizationValue() {
  module2Data.fedUnSubOrganizationValue = 0;
  let totalFedUnsubMaxLimits = 0;
  if (module2Data.fed_unsub_firstyear !== undefined && module2Data.fed_unsub_firstyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 1) {
    totalFedUnsubMaxLimits = module2Data.fed_unsub_firstyear;
  }
  if (module2Data.fed_unsub_secondyear !== undefined && module2Data.fed_unsub_secondyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 2) {
    totalFedUnsubMaxLimits = module2Data.fed_unsub_secondyear + totalFedUnsubMaxLimits;
  }
  if (module2Data.fed_unsub_thirdyear !== undefined && module2Data.fed_unsub_thirdyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 3) {
    totalFedUnsubMaxLimits = module2Data.fed_unsub_thirdyear + totalFedUnsubMaxLimits;
  }
  if (module2Data.fed_unsub_fourthyear !== undefined && module2Data.fed_unsub_fourthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 4) {
    totalFedUnsubMaxLimits = module2Data.fed_unsub_fourthyear + totalFedUnsubMaxLimits;
  }
  if (module2Data.fed_unsub_fifthyear !== undefined && module2Data.fed_unsub_fifthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 5) {
    totalFedUnsubMaxLimits = module2Data.fed_unsub_fifthyear + totalFedUnsubMaxLimits;
  }
  if (module2Data.fed_unsub_sixthyear !== undefined && module2Data.fed_unsub_sixthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 6) {
    totalFedUnsubMaxLimits = module2Data.fed_unsub_sixthyear + totalFedUnsubMaxLimits;
  }

  if (module2Data.fed_unsub_origination_fee !== undefined && module2Data.fed_unsub_origination_fee !== '') {
    module2Data.fedUnSubOrganizationValue = (totalFedUnsubMaxLimits / 100) * module2Data.fed_unsub_origination_fee;
  }
  return module2Data.fedUnSubOrganizationValue;
}

export function higherCarExpense() {
  let higherCarExpenses = 0;
  if (module2Data.start_year !== undefined && parseInt(module2Data.start_year, 10) > 0 && module2Data.graduation_year !== undefined && parseInt(module2Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module2Data.start_year, 10); i <= parseInt(module2Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Car Expenses'] !== undefined && incomeStatementData[i]['Total Car Expenses'] !== '') {
        higherCarExpenses += incomeStatementData[i]['Total Car Expenses'];
      }
    }
  }
  module2Data.higherCarExpense = -(higherCarExpenses);
  return module2Data.higherCarExpense;
}

export function higherChildrenAndPetExpenses() {
  let higherChildrenAndPetExpense = 0;
  if (module2Data.start_year !== undefined && parseInt(module2Data.start_year, 10) > 0 && module2Data.graduation_year !== undefined && parseInt(module2Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module2Data.start_year, 10); i <= parseInt(module2Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].livingExpenses !== undefined && incomeStatementData[i].livingExpenses !== '') {
        if (incomeStatementData[i].livingExpenses.Children !== undefined && incomeStatementData[i].livingExpenses.Children !== '') {
          higherChildrenAndPetExpense += incomeStatementData[i].livingExpenses.Children;
        }
        if (incomeStatementData[i].livingExpenses.Pet !== undefined && incomeStatementData[i].livingExpenses.Pet !== '') {
          higherChildrenAndPetExpense += incomeStatementData[i].livingExpenses.Pet;
        }
      }
    }
  }
  module2Data.higherChildrenAndPetExpenses = -(higherChildrenAndPetExpense);
  return module2Data.higherChildrenAndPetExpenses;
}

export function higherRealEstateExpenses() {
  let higherRealEstateExpense = 0;
  if (module2Data.start_year !== undefined && parseInt(module2Data.start_year, 10) > 0 && module2Data.graduation_year !== undefined && parseInt(module2Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module2Data.start_year, 10); i <= parseInt(module2Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Real Estate Expenses'] !== undefined && incomeStatementData[i]['Total Real Estate Expenses'] !== '') {
        higherRealEstateExpense += incomeStatementData[i]['Total Real Estate Expenses'];
      }
    }
  }
  module2Data.higherRealEstateExpenses = -(higherRealEstateExpense);
  return module2Data.higherRealEstateExpenses;
}

export function higherTotalExpenses() {
  module2Data.higherTotalExpenses = 0;
  let higherTotalExpenses1 = 0;
  let higherTotalExpenses2 = 0;
  let higherTotalExpenses3 = 0;
  let higherTotalExpenses4 = 0;
  let higherTotalExpenses5 = 0;
  let higherTotalExpenses6 = 0;
  let higherTotalExpenses7 = 0;
  let higherTotalExpenses8 = 0;
  let higherTotalExpenses9 = 0;

  if (module2Data.totalTuitionAndFees !== undefined) {
    higherTotalExpenses1 = module2Data.totalTuitionAndFees;
  }
  if (module2Data.totalRoomAndBoard !== undefined) {
    higherTotalExpenses2 = module2Data.totalRoomAndBoard;
  }
  if (module2Data.higherOnCampusLivingExpenses !== undefined) {
    higherTotalExpenses3 = module2Data.higherOnCampusLivingExpenses;
  }
  if (module2Data.higherOffCampusLivingExpenses !== undefined) {
    higherTotalExpenses4 = module2Data.higherOffCampusLivingExpenses;
  }
  if (module2Data.totalStudentsLoanPayments !== undefined) {
    higherTotalExpenses5 = module2Data.totalStudentsLoanPayments;
  }

  if (module2Data.otherLoanPaymentsonOtherDebt !== undefined) {
    higherTotalExpenses6 = module2Data.otherLoanPaymentsonOtherDebt;
  }
  if (module2Data.higherCarExpense !== undefined) {
    higherTotalExpenses7 = module2Data.higherCarExpense;
  }
  if (module2Data.higherRealEstateExpenses !== undefined) {
    higherTotalExpenses8 = module2Data.higherRealEstateExpenses;
  }
  if (module2Data.higherChildrenAndPetExpenses !== undefined) {
    higherTotalExpenses9 = module2Data.higherChildrenAndPetExpenses;
  }
  module2Data.higherTotalExpenses = higherTotalExpenses1 + higherTotalExpenses2 + higherTotalExpenses3 + higherTotalExpenses4 + higherTotalExpenses5 + higherTotalExpenses6 + higherTotalExpenses7 + higherTotalExpenses8 + higherTotalExpenses9;
  return module2Data.higherTotalExpenses;
}

export function otherLoanPaymentsonOtherDebt() {
  let otherLoanPaymentsonOtherDebts = 0;
  let collegeStudentLoanPayment = 0;

  if (module2Data.start_year !== undefined && parseInt(module2Data.start_year, 10) > 0 && module2Data.graduation_year !== undefined && parseInt(module2Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module2Data.start_year, 10); i <= parseInt(module2Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Interest Expenses'] !== undefined && incomeStatementData[i]['Total Interest Expenses'] !== '') {
        otherLoanPaymentsonOtherDebts += incomeStatementData[i]['Total Interest Expenses'];
      }

      if (cashFlowData !== undefined && cashFlowData !== ''
        && cashFlowData[i] !== undefined && cashFlowData[i] !== ''
        && cashFlowData[i]['Cash from Financing Activities'] !== undefined && cashFlowData[i]['Cash from Financing Activities'] !== ''
        && cashFlowData[i]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== undefined && cashFlowData[i]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== '') {
        otherLoanPaymentsonOtherDebts += cashFlowData[i]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'];
      }
      if (cashFlowData !== undefined && cashFlowData !== ''
        && cashFlowData[i] !== undefined && cashFlowData[i] !== ''
        && cashFlowData[i]['Cash from Financing Activities'] !== undefined && cashFlowData[i]['Cash from Financing Activities'] !== ''
        && cashFlowData[i]['Cash from Financing Activities']['Car Loans Principal Payback'] !== undefined && cashFlowData[i]['Cash from Financing Activities']['Car Loans Principal Payback'] !== '') {
        otherLoanPaymentsonOtherDebts += cashFlowData[i]['Cash from Financing Activities']['Car Loans Principal Payback'];
      }
      if (cashFlowData !== undefined && cashFlowData !== ''
        && cashFlowData[i] !== undefined && cashFlowData[i] !== ''
        && cashFlowData[i]['Cash from Financing Activities'] !== undefined && cashFlowData[i]['Cash from Financing Activities'] !== ''
        && cashFlowData[i]['Cash from Financing Activities']['Mortgages Principal Payback'] !== undefined && cashFlowData[i]['Cash from Financing Activities']['Mortgages Principal Payback'] !== '') {
        otherLoanPaymentsonOtherDebts += cashFlowData[i]['Cash from Financing Activities']['Mortgages Principal Payback'];
      }
    }
  }
  if (module2Data.totalStudentsLoanPayments !== undefined) {
    collegeStudentLoanPayment = module2Data.totalStudentsLoanPayments;
  }

  module2Data.otherLoanPaymentsonOtherDebt = -(otherLoanPaymentsonOtherDebts) - collegeStudentLoanPayment;
  return module2Data.otherLoanPaymentsonOtherDebt;
}

export function totalCostOfAttendance() {
  module2Data.totalCostOfAttendance = 0;
  let totalCostOfAttenenace1 = 0;
  let totalCostOfAttenenace2 = 0;
  let totalCostOfAttenenace3 = 0;
  let totalCostOfAttenenace4 = 0;
  let totalCostOfAttenenace5 = 0;

  if (module2Data.totalTuitionAndFees !== undefined) {
    totalCostOfAttenenace1 = module2Data.totalTuitionAndFees;
  }
  if (module2Data.totalRoomAndBoard !== undefined) {
    totalCostOfAttenenace2 = module2Data.totalRoomAndBoard;
  }
  if (module2Data.higherOnCampusLivingExpenses !== undefined) {
    totalCostOfAttenenace3 = module2Data.higherOnCampusLivingExpenses;
  }
  if (module2Data.higherOffCampusLivingExpenses !== undefined) {
    totalCostOfAttenenace4 = module2Data.higherOffCampusLivingExpenses;
  }
  if (module2Data.totalStudentsLoanPayments !== undefined) {
    totalCostOfAttenenace5 = module2Data.totalStudentsLoanPayments;
  }
  module2Data.totalCostOfAttendance = totalCostOfAttenenace1 + totalCostOfAttenenace2 + totalCostOfAttenenace3 + totalCostOfAttenenace4 + totalCostOfAttenenace5;
  return module2Data.totalCostOfAttendance;
}

export function totalBeforeTaxIncome() {
  module2Data.totalBeforeTaxIncome = 0;
  let totalBeforeTaxIncomeValue = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year !== '' && module2Data.start_year > 0 &&
    module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' && module2Data.graduation_year > 0) {
    let start_year = parseInt(module2Data.start_year);
    let endYear = parseInt(module2Data.graduation_year);
    let data = incomeStatementData;
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalBeforeTaxIncomeValue += data[year]['Gross Income']['Income Earned during Higher Education'];
      }
    });
  }
  module2Data.totalBeforeTaxIncome = totalBeforeTaxIncomeValue;
  return module2Data.totalBeforeTaxIncome;
}

export function estimatedTaxRateConversation() {
  module2Data.estimatedTaxRateConversation = 0;
  if (module2Data.totalBeforeTaxIncome !== undefined && module2Data.totalBeforeTaxIncome !== '' && module2Data.totalBeforeTaxIncome > 0 && module2Data.incomeEarnedAfterTaxesVal !== undefined && module2Data.incomeEarnedAfterTaxesVal !== '' && module2Data.incomeEarnedAfterTaxesVal > 0) {
    module2Data.estimatedTaxRateConversation = (1 - (module2Data.incomeEarnedAfterTaxesVal / module2Data.totalBeforeTaxIncome)) * 100;
  }
  return module2Data.estimatedTaxRateConversation;
}



// Custom Room Board Value
export function customRoomBoardVal() {
  module2Data.room_board = '0';
  if (module2Data.customRoomBoardValue !== undefined) {
    module2Data.room_board = module2Data.customRoomBoardValue.toString();
  }
  return module2Data.room_board;
}

// Cal Existing Student Loan Balance
export function existingStudentLoansBalance() {
  module2Data.existingStudentLoansBalance = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    let val1 = 0;
    if (fedSubExistingLoanData['Summation Data'] !== undefined
      && fedSubExistingLoanData['Summation Data'][module2Data.graduation_year] !== undefined
      && fedSubExistingLoanData['Summation Data'][module2Data.graduation_year].EndingBalance !== undefined) {
      val1 = fedSubExistingLoanData['Summation Data'][module2Data.graduation_year].EndingBalance;
    }
    let val2 = 0;
    if (fedUnSubExistingLoanData['Summation Data'] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][module2Data.graduation_year] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][module2Data.graduation_year].EndingBalance !== undefined) {
      val2 = fedUnSubExistingLoanData['Summation Data'][module2Data.graduation_year].EndingBalance;
    }
    let val3 = 0;
    if (privateExistingLoanData['Summation Data'] !== undefined
      && privateExistingLoanData['Summation Data'][module2Data.graduation_year] !== undefined
      && privateExistingLoanData['Summation Data'][module2Data.graduation_year].EndingBalance !== undefined) {
      val3 = privateExistingLoanData['Summation Data'][module2Data.graduation_year].EndingBalance;
    }
    module2Data.existingStudentLoansBalance = val1 + val2 + val3;
  }
  return module2Data.existingStudentLoansBalance;
}

// For conversation changes
export function totalStudentLoanBalance() {
  module2Data.totalStudentLoanBalance = 0;
  let privateLoanBal = 0;
  let UnsubLoanBal = 0;
  let subLoanBal = 0;
  let existingBal = 0;
  let finalValueHigher = 0;

  if (module2Data.privateBeginningBalance !== undefined) {
    privateLoanBal = module2Data.privateBeginningBalance;
  }
  if (module2Data.fedUnSubBeginningBalance !== undefined) {
    UnsubLoanBal = module2Data.fedUnSubBeginningBalance;
  }
  if (module2Data.fedSubBeginningBalance !== undefined) {
    subLoanBal = module2Data.fedSubBeginningBalance;
  }
  if (module2Data.existingStudentLoansBalance !== undefined) {
    existingBal = module2Data.existingStudentLoansBalance;
  }

  if (module2Data.graduation_year !== undefined && module2Data.graduation_year !== ''
    && module2Data.start_year !== undefined && module2Data.start_year !== '') {
    finalValueHigher = privateLoanBal + UnsubLoanBal + subLoanBal + existingBal;
  } else {
    finalValueHigher = 0;
  }
  module2Data.totalStudentLoanBalance = finalValueHigher;
  return module2Data.totalStudentLoanBalance;
}

export function totalMonthlyPayment() {
  module2Data.totalMonthlyPayment = 0;
  let privatePaymentBal = 0;
  let UnsubPaymentBal = 0;
  let subPaymentBal = 0;

  // DZ 14 DZ 31 DZ 48
  let value1 = 0;
  let value2 = 0;
  let value3 = 0;
  let valueTotal = 0;
  let finalTotal = 0;

  if (module2Data.privateMonthlyPayment !== undefined) {
    privatePaymentBal = module2Data.privateMonthlyPayment;
  }
  if (module2Data.fedUnSubMonthlyPayment !== undefined) {
    UnsubPaymentBal = module2Data.fedUnSubMonthlyPayment;
  }
  if (module2Data.fedSubMonthlyPayment !== undefined) {
    subPaymentBal = module2Data.fedSubMonthlyPayment;
  }

  if (module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    if (fedSubExistingLoanData['Summation Data'] !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance > 0
      && fedSubExistingLoanData['Original Payment'] !== undefined) {
      value1 = fedSubExistingLoanData['Original Payment'];
    } else {
      value1 = 0;
    }

    if (fedUnSubExistingLoanData['Summation Data'] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && fedUnSubExistingLoanData['Original Payment'] !== undefined) {
      value2 = fedUnSubExistingLoanData['Original Payment'];
    } else {
      value2 = 0;
    }

    if (privateExistingLoanData['Summation Data'] !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && privateExistingLoanData['Original Payment'] !== undefined) {
      value3 = privateExistingLoanData['Original Payment'];
    } else {
      value3 = 0;
    }
    valueTotal = value1 + value2 + value3;
  } else {
    valueTotal = 0;
  }

  if (module2Data.graduation_year !== undefined && module2Data.graduation_year !== ''
    && module2Data.start_year !== undefined && module2Data.start_year !== '') {
    finalTotal = privatePaymentBal + UnsubPaymentBal + subPaymentBal + valueTotal;
  } else {
    finalTotal = 0;
  }
  module2Data.totalMonthlyPayment = finalTotal;
  return module2Data.totalMonthlyPayment;
}
export function totalAnnualPaymentPaid() {
  module2Data.totalAnnualPaymentPaid = 0;
  if (module2Data.graduation_year !== undefined && module2Data.graduation_year !== ''
    && module2Data.start_year !== undefined && module2Data.start_year !== ''
    && module2Data.totalMonthlyPayment !== undefined) {
    module2Data.totalAnnualPaymentPaid = module2Data.totalMonthlyPayment * 12;
  }
  return module2Data.totalAnnualPaymentPaid;
}
export function incomeNeedToSupportLoans() {
  module2Data.incomeNeedToSupportLoans = 0;
  if (module2Data.graduation_year !== undefined && module2Data.graduation_year !== ''
    && module2Data.start_year !== undefined && module2Data.start_year !== ''
    && module2Data.totalAnnualPaymentPaid !== undefined) {
    module2Data.incomeNeedToSupportLoans = ((module2Data.totalAnnualPaymentPaid) / 12.5) * 100;
  }
  return module2Data.incomeNeedToSupportLoans;
}

// Income Earned after Taxes
export function incomeEarnedAfterTaxesVal() {
  module2Data.incomeEarnedAfterTaxesVal = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '') {
    const start_year = parseInt(module2Data.start_year, 10);
    const endYear = parseInt(module2Data.graduation_year, 10);
    const tuitionCostData = incomeStatementData;
    let incomeEarnedAfterTaxesValCalue = 0;
    if (Object.keys(tuitionCostData).length !== 0) {
      Object.keys(tuitionCostData).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          incomeEarnedAfterTaxesValCalue += tuitionCostData[year]['Income After Taxes'];
        }
      });
    }
    module2Data.incomeEarnedAfterTaxesVal = incomeEarnedAfterTaxesValCalue;
  } else {
    module2Data.incomeEarnedAfterTaxesVal = 0;
  }
  return module2Data.incomeEarnedAfterTaxesVal;
}
// Fedsub organization value
export function fedSubOrganizationValue() {
  module2Data.fedSubOrganizationValue = 0;
  let totalMaxLimits = 0;

  if (module2Data.fed_sub_firstyear !== undefined && module2Data.fed_sub_firstyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 1) {
    totalMaxLimits = module2Data.fed_sub_firstyear;
  }
  if (module2Data.fed_sub_secondyear !== undefined && module2Data.fed_sub_secondyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 2) {
    totalMaxLimits = module2Data.fed_sub_secondyear + totalMaxLimits;
  }
  if (module2Data.fed_sub_thirdyear !== undefined && module2Data.fed_sub_thirdyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 3) {
    totalMaxLimits = module2Data.fed_sub_thirdyear + totalMaxLimits;
  }
  if (module2Data.fed_sub_fourthyear !== undefined && module2Data.fed_sub_fourthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 4) {
    totalMaxLimits = module2Data.fed_sub_fourthyear + totalMaxLimits;
  }
  if (module2Data.fed_sub_fifthyear !== undefined && module2Data.fed_sub_fifthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 5) {
    totalMaxLimits = module2Data.fed_sub_fifthyear + totalMaxLimits;
  }
  if (module2Data.fed_sub_sixthyear !== undefined && module2Data.fed_sub_sixthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 6) {
    totalMaxLimits = module2Data.fed_sub_sixthyear + totalMaxLimits;
  }

  if (module2Data.fed_sub_origination_fee !== undefined && module2Data.fed_sub_origination_fee !== '') {
    module2Data.fedSubOrganizationValue = (totalMaxLimits / 100) * module2Data.fed_sub_origination_fee;
  }
  return module2Data.fedSubOrganizationValue;
}

// Fed Private organization value
export function fedPrivateOrganizationValue() {
  module2Data.fedPrivateOrganizationValue = 0;
  let totalPrivateMaxLimits = 0;
  if (module2Data.private_firstyear !== undefined && module2Data.private_firstyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 1) {
    totalPrivateMaxLimits = module2Data.private_firstyear;
  }
  if (module2Data.private_secondyear !== undefined && module2Data.private_secondyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 2) {
    totalPrivateMaxLimits = module2Data.private_secondyear + totalPrivateMaxLimits;
  }
  if (module2Data.private_thirdyear !== undefined && module2Data.private_thirdyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 3) {
    totalPrivateMaxLimits = module2Data.private_thirdyear + totalPrivateMaxLimits;
  }
  if (module2Data.private_fourthyear !== undefined && module2Data.private_fourthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 4) {
    totalPrivateMaxLimits = module2Data.private_fourthyear + totalPrivateMaxLimits;
  }
  if (module2Data.private_fifthyear !== undefined && module2Data.private_fifthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 5) {
    totalPrivateMaxLimits = module2Data.private_fifthyear + totalPrivateMaxLimits;
  }
  if (module2Data.private_sixthyear !== undefined && module2Data.private_sixthyear !== '' && module2Data.years_diff !== undefined && module2Data.years_diff >= 6) {
    totalPrivateMaxLimits = module2Data.private_sixthyear + totalPrivateMaxLimits;
  }

  if (module2Data.private_origination_fee !== undefined && module2Data.private_origination_fee !== '') {
    module2Data.fedPrivateOrganizationValue = (totalPrivateMaxLimits / 100) * module2Data.private_origination_fee;
  }
  return module2Data.fedPrivateOrganizationValue;
}

// Cal Summary Loan Points
export function summaryLoanPoints() {
  module2Data.summaryLoanPoints = 0;
  if (module2Data.fedSubOrganizationValue !== undefined && module2Data.fedUnSubOrganizationValue !== undefined && module2Data.fedPrivateOrganizationValue !== undefined) {
    module2Data.summaryLoanPoints = -(module2Data.fedSubOrganizationValue + module2Data.fedUnSubOrganizationValue + module2Data.fedPrivateOrganizationValue);
  }
  return module2Data.summaryLoanPoints;
}

// Investment Analysis Total Cash Available
export function investmentAnalysisTotalCashAvailable() {
  module2Data.aidYearsDiff = 0;
  module2Data.investmentAnalysisFamilyContribution = 0;
  if (module2Data.year_aid_begins > 0 && module2Data.year_aid_ends > 0) {
    module2Data.aidYearsDiff = (module2Data.year_aid_ends - module2Data.year_aid_begins) + 1;
  }

  if (module2Data.family_end_year > 0 && module2Data.family_start_year > 0) {
    module2Data.investmentAnalysisFamilyContribution = (module2Data.yearly_contribution) * ((module2Data.family_end_year - module2Data.family_start_year) + 1);
  }
  module2Data.investmentAnalysisTotalCashAvailable = (module2Data.yearly_scholarship_amount * module2Data.years_diff)
    + (module2Data.yearly_grant_amount * module2Data.years_diff)
    + (module2Data.expected_yearly_finance_aid * module2Data.aidYearsDiff)
    + module2Data.fedSubBeginningBalance
    + module2Data.fedUnSubPrincipal
    + module2Data.privatePrincipal
    + module2Data.summaryLoanPoints
    + module2Data.incomeEarnedAfterTaxesVal
    + (module2Data.investmentAnalysisFamilyContribution);
  return module2Data.investmentAnalysisTotalCashAvailable;
}

export function higherTotalExpensesFinal() {
  module2Data.higherTotalExpenses = 0;
  if (module2Data.higherTotalExpenses !== undefined) {
    module2Data.higherTotalExpenses = -(module2Data.higherTotalExpenses);
  }
  return module2Data.higherTotalExpenses;
}

// Investment Analysis Total Liquid Assets Available
export function investmentAnalysisTotalLiquidAssetsAvailable() {
  module2Data.investmentAnalysisTotalLiquidAssetsAvailable = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year !== '' && module2Data.start_year > 0) {
    if (module1Data.start_year !== undefined && module1Data.start_year !== ''
      && module2Data.start_year === module1Data.start_year) {
      // module1Data.current_cash_balance !== undefined && module1Data.current_cash_balance !== ''&&
      if (module9Data.short_term_begining_balance !== undefined && module9Data.short_term_begining_balance !== ''
        && module9Data.long_term_begining_balance !== undefined && module9Data.long_term_begining_balance !== '') {
        // module1Data.current_cash_balance+ 
        module2Data.investmentAnalysisTotalLiquidAssetsAvailable = module9Data.short_term_begining_balance
          + module9Data.long_term_begining_balance;
      }
    } else if (balanceSheetData[parseInt(module2Data.start_year, 10) - 1] !== undefined
      && balanceSheetData[parseInt(module2Data.start_year, 10) - 1].Investments['Short-Term Investments'] !== undefined
      && balanceSheetData[parseInt(module2Data.start_year, 10) - 1].Investments['Long-Term Investments'] !== undefined
      && balanceSheetData[parseInt(module2Data.start_year, 10) - 1].Assets.Cash !== undefined) {
      module2Data.investmentAnalysisTotalLiquidAssetsAvailable = parseFloat(balanceSheetData[parseInt(module2Data.start_year, 10) - 1].Assets.Cash)
        + parseFloat(balanceSheetData[parseInt(module2Data.start_year, 10) - 1].Investments['Short-Term Investments'])
        + parseFloat(balanceSheetData[parseInt(module2Data.start_year, 10) - 1].Investments['Long-Term Investments']);
    }
  }
  return module2Data.investmentAnalysisTotalLiquidAssetsAvailable;
}

// Cal Accrued Interest
export function privateAccruedInterest() {
  module2Data.privateAccruedInterest = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.privateAccruedInterest = privateHigherEdLoanData['Accrued Interest'];
  }
  return module2Data.privateAccruedInterest;
}

// Cal Begginning Balance
export function privateBeginningBalance() {
  module2Data.privateBeginningBalance = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.privateBeginningBalance = privateHigherEdLoanData['Beginning Balance'];
  }
  return module2Data.privateBeginningBalance;
}

// Cal Monthly Payment
export function privateMonthlyPayment() {
  module2Data.privateMonthlyPayment = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.privateMonthlyPayment = privateHigherEdLoanData['Original Payment'];
  }
  return module2Data.privateMonthlyPayment;
}

// Cal Principal
export function privatePrincipal() {
  module2Data.privatePrincipal = (module2Data.private_firstyear !== undefined ? module2Data.private_firstyear : 0)
    + (module2Data.private_secondyear !== undefined ? module2Data.private_secondyear : 0)
    + (module2Data.private_thirdyear !== undefined ? module2Data.private_thirdyear : 0)
    + (module2Data.private_fourthyear !== undefined ? module2Data.private_fourthyear : 0)
    + (module2Data.private_fifthyear !== undefined ? module2Data.private_fifthyear : 0)
    + (module2Data.private_sixthyear !== undefined ? module2Data.private_sixthyear : 0);
  return module2Data.privatePrincipal;
}

// Cal Total Interest Paid
export function privateTotalInterestPaid() {
  module2Data.privateTotalInterestPaid = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.privateTotalInterestPaid = privateHigherEdLoanData['Total Interest Paid'];
  }
  return module2Data.privateTotalInterestPaid;
}

// Cal Total Principal & Interest
export function privateTotalPrincipalInterest() {
  module2Data.privateTotalPrincipalInterest = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.privateTotalPrincipalInterest = privateHigherEdLoanData['Total Paid'];
  }
  return module2Data.privateTotalPrincipalInterest;
}

// Cal Yearly Payment
export function privateYearlyPayment() {
  module2Data.privateYearlyPayment = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year > 0) {
    module2Data.privateYearlyPayment = module2Data.privateMonthlyPayment * 12;
  }
  return module2Data.privateYearlyPayment;
}

// REI module starts and working by Sateesh
// Down Payment (%)
export function realEstatedownPaymentCalcs() {
  module14Data.realEstatedownPaymentCalcs = 0;
  if (module14Data.down_payment_in_percentage !== undefined && module14Data.down_payment_in_percentage > 0 && module14Data.purchase_price !== undefined && module14Data.purchase_price !== '') {
    module14Data.realEstatedownPaymentCalcs = (parseFloat(module14Data.down_payment_in_percentage) * module14Data.purchase_price) / 100;
  }
  return module14Data.realEstatedownPaymentCalcs;
}
// Financed Amount Value
export function realEstateFinancedAmount() {
  module14Data.realEstateFinancedAmountVal = 0;
  if (module14Data.purchase_price !== undefined && module14Data.purchase_price !== '' && module14Data.realEstatedownPaymentCalcs !== undefined && module14Data.realEstatedownPaymentCalcs !== '') {
    module14Data.realEstateFinancedAmountVal = module14Data.purchase_price - parseFloat(module14Data.realEstatedownPaymentCalcs);
  }
  return module14Data.realEstateFinancedAmountVal;
}
// Transfer Taxes (%)
export function realEstateTransferTaxesCalcs() {
  module14Data.realEstateTransferTaxesCalcs = 0;
  if (module14Data.transfer_taxes_in_percentage !== undefined && module14Data.transfer_taxes_in_percentage > 0 && module14Data.purchase_price !== undefined && module14Data.purchase_price !== '') {
    module14Data.realEstateTransferTaxesCalcs = (parseFloat(module14Data.transfer_taxes_in_percentage) * module14Data.purchase_price) / 100;
  }
  return module14Data.realEstateTransferTaxesCalcs;
}
// Title Insurance(%) Calcs
export function reiTitleInsuranceCalcs() {
  module14Data.reiTitleInsuranceCalcs = 0;
  if (module14Data.title_insurance !== undefined && module14Data.title_insurance > 0) {
    module14Data.reiTitleInsuranceCalcs = (parseFloat(module14Data.title_insurance) * module14Data.purchase_price) / 100;
  }
  return module14Data.reiTitleInsuranceCalcs;
}
// Fitout Cost Calcs
export function realEstateFitOutCostsCalcs() {
  module14Data.realEstateFitOutCostsCalcs = 0;
  if (module14Data.purchase_price !== undefined) {
    module14Data.realEstateFitOutCostsCalcs = module14Data.purchase_price;
  }
  return module14Data.realEstateFitOutCostsCalcs;
}
export function realEstatePropertyTaxesCalcs() {
  module14Data.realEstatePropertyTaxesCalcs = 0;
  if (module14Data.property_taxes_in_percentage !== undefined && module14Data.property_taxes_in_percentage > 0 && module14Data.realEstateFitOutCostsCalcs !== undefined && module14Data.realEstateFitOutCostsCalcs !== '') {
    module14Data.realEstatePropertyTaxesCalcs = (parseFloat(module14Data.property_taxes_in_percentage) * module14Data.realEstateFitOutCostsCalcs) / 100;
  }
  return module14Data.realEstatePropertyTaxesCalcs;
}
// Mortgage
export function realEstateMortage() {
  module14Data.mortage = 0;
  if (module14Data.purchase_price !== undefined && module14Data.purchase_price > 0) {
    module14Data.mortage = module14Data.purchase_price - parseFloat(module14Data.realEstatedownPaymentCalcs);
  }
  return module14Data.mortage;
}

// Outstanding Mortgage
export function reiOutstandingMortgage() {
  module14Data.reiOutstandingMortgage = 0;
  if (module14Data.mortage !== undefined && module14Data.mortage > 0) {
    module14Data.reiOutstandingMortgage = parseFloat(module14Data.mortage);
  }
  return module14Data.reiOutstandingMortgage;
}

// Total Principal
export function reiTotalPrincipal() {
  module14Data.reiTotalPrincipal = 0;
  if (module14Data.mortage !== undefined && module14Data.mortage > 0) {
    if (mortgage2Data['Total Paid'] !== undefined && mortgage2Data['Total Paid'] !== '') {
      module14Data.reiTotalPrincipal = mortgage2Data['Total Paid'];
    }
  }
  return module14Data.reiTotalPrincipal;
}
// Loan Points Calcs
export function loanPointsCalculation() {
  module14Data.loanPointsCalculation = 0;
  if (module14Data.mortage !== undefined && module14Data.mortage !== '' && module14Data.points_of_mortgage !== undefined) {
    module14Data.loanPointsCalculation = (module14Data.mortage / 100) * module14Data.points_of_mortgage;
  }
  return module14Data.loanPointsCalculation;
}
// Origination Calcs
export function originationFeesCalculation() {
  module14Data.originationFeesCalculation = 0;
  if (module14Data.mortage !== undefined && module14Data.mortage !== '' && module14Data.origination_fees !== undefined) {
    module14Data.originationFeesCalculation = (module14Data.mortage / 100) * module14Data.origination_fees;
  }
  return module14Data.originationFeesCalculation;
}
// Monthly Payment (Principal & Interest)
export function realEstateMonthlyPayment() {
  module14Data.realEstateMonthlyPayment = 0;
  if (mortgage2Data !== undefined && mortgage2Data !== '' && mortgage2Data.Mortage2 !== undefined && mortgage2Data.Mortage2 !== '' && mortgage2Data.Mortage2['Original Payment'] !== undefined) {
    module14Data.realEstateMonthlyPayment = mortgage2Data.Mortage2['Original Payment'];
  }
  return module14Data.realEstateMonthlyPayment;
}
// Annual Paid (Principal & Interest)
export function realEstateYearlyPayment() {
  module14Data.realEstateYearlyPayment = 0;
  if (module14Data.realEstateMonthlyPayment !== undefined && module14Data.realEstateMonthlyPayment !== '') {
    module14Data.realEstateYearlyPayment = parseFloat(module14Data.realEstateMonthlyPayment) * 12;
  }
  return module14Data.realEstateYearlyPayment;
}
// Total Interest Value
export function totalInterest() {
  module14Data.totalInterest = 0;
  if (mortgage2Data !== undefined && mortgage2Data['Total Interest Paid'] !== undefined && mortgage2Data['Total Interest Paid'] !== '') {
    module14Data.totalInterest = mortgage2Data['Total Interest Paid'];
  }
  return module14Data.totalInterest;
}
// Total Principal And Interest Value
export function totalPrincipalInterest() {
  module14Data.totalPrincipalInterest = 0;
  if (mortgage2Data !== undefined && mortgage2Data['Total Paid'] !== undefined && mortgage2Data['Total Paid'] !== '') {
    module14Data.totalPrincipalInterest = mortgage2Data['Total Paid'];
  }
  return module14Data.totalPrincipalInterest;
}
//Annual Rental Income Calcs
export function annualRentalIncomeCalcs() {
  module14Data.annualRentalIncomeCalcs = 0;
  if (module14Data.yearly_rental_income !== undefined) {
    module14Data.annualRentalIncomeCalcs = module14Data.yearly_rental_income * 12;
  }
  return module14Data.annualRentalIncomeCalcs;
}
// Annual Vacancy Rate Calcs
export function vacancyRateCalcs() {
  module14Data.vacancyRateCalcs = 0;
  if (module14Data.vacancy_rate_in_percentage !== undefined && module14Data.vacancy_rate_in_percentage > 0
    && module14Data.annualRentalIncomeCalcs !== undefined && module14Data.annualRentalIncomeCalcs !== '') {
    module14Data.vacancyRateCalcs = module14Data.annualRentalIncomeCalcs * (parseFloat(module14Data.vacancy_rate_in_percentage) / 100);
  }
  return module14Data.vacancyRateCalcs;
}
// Adjusted Annual Rental Income
export function realEstateAdjustedRentalIncome() {
  module14Data.realEstateAdjustedRentalIncome = 0;
  if (module14Data.annualRentalIncomeCalcs !== undefined) {
    module14Data.realEstateAdjustedRentalIncome = module14Data.annualRentalIncomeCalcs;
    if (module14Data.vacancyRateCalcs !== undefined && module14Data.vacancyRateCalcs !== '') {
      module14Data.realEstateAdjustedRentalIncome = module14Data.annualRentalIncomeCalcs - parseFloat(module14Data.vacancyRateCalcs)
    }
  }
  return module14Data.realEstateAdjustedRentalIncome;
}

// Adjusted Annual Rental Income
export function annualRentalIncomeGrowthRateCalcs() {
  module14Data.annualRentalIncomeGrowthRateCalcs = 0;
  if (module14Data.realEstateAdjustedRentalIncome !== undefined && module14Data.annual_income_growth_rate !== undefined) {
    module14Data.annualRentalIncomeGrowthRateCalcs = module14Data.realEstateAdjustedRentalIncome + (module14Data.realEstateAdjustedRentalIncome / 100) * module14Data.annual_income_growth_rate;
  }
  return module14Data.annualRentalIncomeGrowthRateCalcs;
}

export function adjustedRentalIncomeForGraph() {
  module14Data.adjustedRentalIncomeForGraph = 0;
  if (taxStatementData !== undefined && taxStatementData !== '' &&
    taxStatementData[module14Data.year_of_purchase] !== undefined && taxStatementData[module14Data.year_of_purchase] !== '' &&
    taxStatementData[module14Data.year_of_purchase]['REI Property'] !== undefined && taxStatementData[module14Data.year_of_purchase]['REI Property'] !== '' &&
    taxStatementData[module14Data.year_of_purchase]['REI Property'] !== undefined && taxStatementData[module14Data.year_of_purchase]['REI Property'] !== '' &&
    taxStatementData[module14Data.year_of_purchase]['REI Property']['Rental Income'] !== undefined && taxStatementData[module14Data.year_of_purchase]['REI Property']['Rental Income'] !== '') {
    module14Data.adjustedRentalIncomeForGraph = taxStatementData[module14Data.year_of_purchase]['REI Property']['Rental Income'];
  } else {
    module14Data.adjustedRentalIncomeForGraph = 0;
  }
  return module14Data.adjustedRentalIncomeForGraph;
}

// Annual Maintenance
export function realEstateYearlyMaintenanceCalcs() {
  module14Data.realEstateYearlyMaintenanceCalcs = 0;
  if (module14Data.yearly_maintanance_percantage !== undefined && module14Data.yearly_maintanance_percantage > 0 && module14Data.realEstateFitOutCostsCalcs !== undefined && module14Data.realEstateFitOutCostsCalcs !== '') {
    module14Data.realEstateYearlyMaintenanceCalcs = (parseFloat(module14Data.yearly_maintanance_percantage) * module14Data.realEstateFitOutCostsCalcs) / 100;
  }
  return module14Data.realEstateYearlyMaintenanceCalcs;
}
// Management Fee (%)
export function managementFeeCalcs() {
  module14Data.managementFeeCalcs = 0;
  if (module14Data.managemtnt_fee_in_percentage !== undefined && module14Data.managemtnt_fee_in_percentage > 0 && module14Data.annualRentalIncomeCalcs !== undefined && module14Data.annualRentalIncomeCalcs !== '') {
    module14Data.managementFeeCalcs = (parseFloat(module14Data.managemtnt_fee_in_percentage) * module14Data.annualRentalIncomeCalcs) / 100;
  }
  return module14Data.managementFeeCalcs;
}
// Monthly Utilities Calcs
export function monthlyUtilitiesCalcs() {
  module14Data.monthlyUtilitiesCalcs = 0;
  if (module14Data.monthly_utilities !== undefined && module14Data.monthly_utilities > 0) {
    module14Data.monthlyUtilitiesCalcs = module14Data.monthly_utilities * 12;
  }
  return module14Data.monthlyUtilitiesCalcs;
}
// Homeowner's Insurance (%) Calcs
export function realEstatehomeownerInsuranceCalcs() {
  module14Data.realEstatehomeownerInsuranceCalcs = 0;
  if (module14Data.home_owners_insurance_in_percentage !== undefined && module14Data.home_owners_insurance_in_percentage > 0 && module14Data.realEstateFitOutCostsCalcs !== undefined) {
    module14Data.realEstatehomeownerInsuranceCalcs = (parseFloat(module14Data.home_owners_insurance_in_percentage) * module14Data.realEstateFitOutCostsCalcs) / 100;
  }
  return module14Data.realEstatehomeownerInsuranceCalcs;
}
// Property Taxes
export function realEstatepropertyTaxesRentingVal() {
  module14Data.realEstatepropertyTaxesRentingVal = 0;
  if (module14Data.property_taxes_in_percentage !== undefined) {
    module14Data.realEstatepropertyTaxesRentingVal = parseFloat(module14Data.property_taxes_in_percentage);
  }
  return module14Data.realEstatepropertyTaxesRentingVal;
}

// Property Taxes Cals
export function realEstatePropertyTaxesExpensesCalcs() {
  module14Data.realEstatePropertyTaxesExpensesCalcs = 0;
  if (module14Data.realEstatepropertyTaxesRentingVal !== undefined
    && module14Data.realEstatepropertyTaxesRentingVal > 0
    && module14Data.realEstateFitOutCostsCalcs !== undefined
    && module14Data.realEstateFitOutCostsCalcs !== '' && module14Data.realEstateFitOutCostsCalcs > 0) {
    module14Data.realEstatePropertyTaxesExpensesCalcs = (parseFloat(module14Data.realEstatepropertyTaxesRentingVal) * module14Data.realEstateFitOutCostsCalcs) / 100;
  }
  return module14Data.realEstatePropertyTaxesExpensesCalcs;
}

// Closing Costs
export function realEstateClosingCosts() {
  module14Data.realEstateClosingCosts = 0;
  if (module14Data.purchase_price !== undefined && module14Data.purchase_price > 0) {
    if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Buying Expenses'] !== undefined) {
      module14Data.realEstateClosingCosts = invPropertyRoiData['Buying Expenses']['Closing Costs'];
    } else {
      module14Data.realEstateClosingCosts = 0;
    }
  }
  return module14Data.realEstateClosingCosts;
}
// Closing Costs
export function reiClosingCosts() {
  module14Data.reiClosingCosts = 0;
  if (module14Data.realEstateClosingCosts !== undefined && module14Data.realEstateClosingCosts !== '') {
    module14Data.reiClosingCosts = parseFloat(module14Data.realEstateClosingCosts);
  }
  return module14Data.reiClosingCosts;
}
// Purchase Price
export function reiPurchasePrice() {
  module14Data.reiPurchasePrice = 0;
  if (module14Data.purchase_price !== undefined && module14Data.purchase_price !== '') {
    module14Data.reiPurchasePrice = module14Data.purchase_price;
  }
  return module14Data.reiPurchasePrice;
}
// Down Payment
export function reiDownPayment() {
  module14Data.reiDownPayment = 0;
  if (module14Data.realEstatedownPaymentCalcs !== undefined && module14Data.realEstatedownPaymentCalcs !== '') {
    module14Data.reiDownPayment = parseFloat(module14Data.realEstatedownPaymentCalcs);
  }
  return module14Data.reiDownPayment;
}
// Fit Out Costs
export function reiFitOutCosts() {
  module14Data.reiFitOutCosts = 0;
  if (module14Data.external_and_internal_fit_out_costs !== undefined && module14Data.external_and_internal_fit_out_costs !== '') {
    module14Data.reiFitOutCosts = module14Data.external_and_internal_fit_out_costs;
  }
  return module14Data.reiFitOutCosts;
}
// Cash Needed at Purchase
export function realEstatecashNeededAtPurchase() {
  module14Data.realEstatecashNeededAtPurchase = 0;
  if (module14Data.year_of_purchase !== undefined && parseInt(module14Data.year_of_purchase, 10) > 0) {
    module14Data.realEstatecashNeededAtPurchase = module14Data.reiDownPayment + module14Data.reiClosingCosts + module14Data.reiFitOutCosts;
  }
  return module14Data.realEstatecashNeededAtPurchase;
}
// Total Cash Available
export function realEstatetotalCashAvailable() {
  module14Data.realEstatetotalCashAvailable = 0;
  //  && module1Data.max_cash_balance !== undefined && module1Data.max_cash_balance !== '' 
  if (module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase !== '' && cashFlowData[module14Data.year_of_purchase] !== undefined) {
    module14Data.realEstatetotalCashAvailable = cashFlowData[module14Data.year_of_purchase]['Beginning Cash Balance'];
  }
  return module14Data.realEstatetotalCashAvailable;
}
// Total Liquid Assets Available
export function realEstatetotalLiquid() {
  module14Data.realEstatetotalLiquid = 0;
  if (module14Data.year_of_purchase !== undefined && module1Data.start_year !== undefined && module14Data.year_of_purchase === module1Data.start_year) {
    if (module9Data.short_term_begining_balance !== undefined && module9Data.long_term_begining_balance !== undefined) {
      module14Data.realEstatetotalLiquid = module9Data.short_term_begining_balance + module9Data.long_term_begining_balance;
    }
  } else if (module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase !== '') {
    if (balanceSheetData[(parseInt(module14Data.year_of_purchase, 10)) - 1] !== undefined && balanceSheetData[(parseInt(module14Data.year_of_purchase, 10)) - 1].Investments['Short-Term Investments'] !== undefined && balanceSheetData[parseInt(module14Data.year_of_purchase, 10) - 1].Investments['Long-Term Investments'] !== undefined) {
      module14Data.realEstatetotalLiquid = parseFloat(balanceSheetData[parseInt(module14Data.year_of_purchase, 10) - 1].Investments['Short-Term Investments']) + parseFloat(balanceSheetData[parseInt(module14Data.year_of_purchase, 10) - 1].Investments['Long-Term Investments']);
    }
  }
  return module14Data.realEstatetotalLiquid;
}
// Debt
export function reiDebt() {
  let totalInterestExpensesDebt1 = 0;
  module14Data.reiDebt = 0;

  if (incomeStatementData[module14Data.year_of_purchase] !== undefined && incomeStatementData[module14Data.year_of_purchase]['Total Interest Expenses'] !== '') {
    totalInterestExpensesDebt1 = incomeStatementData[module14Data.year_of_purchase]['Total Interest Expenses'];
  }
  if (incomeStatementData[module14Data.year_of_purchase] !== undefined && incomeStatementData[module14Data.year_of_purchase]['Total Income'] !== undefined && incomeStatementData[module14Data.year_of_purchase]['Total Income'] !== 0) {
    module14Data.reiDebt = (-(parseFloat(totalInterestExpensesDebt1)) / parseFloat(incomeStatementData[module14Data.year_of_purchase]['Total Income'])) * 100;
  }
  return module14Data.reiDebt;
}
// Appreciation (%) Calcs
export function appreciationCalcs() {
  module14Data.appreciationCalcs = 0;
  if (module14Data.appreciation_in_percentage !== undefined && module14Data.appreciation_in_percentage > 0 && module14Data.purchase_price !== undefined && module14Data.purchase_price !== '') {
    module14Data.appreciationCalcs = (parseFloat(module14Data.appreciation_in_percentage) * module14Data.purchase_price) / 100;
  }
  return module14Data.appreciationCalcs;
}
// Sale Price in Year
export function salesPrice() {
  module14Data.salesPrice = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Selling Costs'] !== undefined) {
    module14Data.salesPrice = invPropertyRoiData['Selling Costs']['Selling Price'];
  } else {
    module14Data.salesPrice = 0;
  }
  return module14Data.salesPrice;
}
// Selling Costs (%)
export function sellingCostCalcs() {
  module14Data.sellingCostCalcs = 0;
  if (module14Data.selling_costs_in_percentage !== undefined && module14Data.selling_costs_in_percentage > 0 && module14Data.salesPrice !== undefined && module14Data.salesPrice !== '') {
    module14Data.sellingCostCalcs = parseFloat((parseFloat(module14Data.selling_costs_in_percentage) / 100) * parseFloat(module14Data.salesPrice));
  }
  return module14Data.sellingCostCalcs;
}
// Mortgage Balance Remaining at Sale
export function mortgageBalanceRemaining() {
  module14Data.mortgageBalanceRemaining = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Selling Costs'] !== undefined && invPropertyRoiData['Selling Costs']['Mortgage Balance Remaining'] !== undefined && invPropertyRoiData['Selling Costs']['Mortgage Balance Remaining'] !== '') {
    module14Data.mortgageBalanceRemaining = invPropertyRoiData['Selling Costs']['Mortgage Balance Remaining'];
  }
  return module14Data.mortgageBalanceRemaining;
}
// Net Cash Flow From Sale (before taxes)
export function netCashFlowFromSale() {
  module14Data.netCashFlowFromSale = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Selling Costs'] !== undefined && invPropertyRoiData['Selling Costs']['Net Cash Flow in Sale Year'] !== undefined && invPropertyRoiData['Selling Costs']['Net Cash Flow in Sale Year'] !== '') {
    module14Data.netCashFlowFromSale = invPropertyRoiData['Selling Costs']['Net Cash Flow in Sale Year'];
  }
  return module14Data.netCashFlowFromSale;
}
// Total Rental
export function investmentSummaryTotalRental() {
  module14Data.investmentSummaryTotalRentalVal = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['Rental Income Sum'] !== undefined && invPropertyRoiData['Rental Income Sum'] !== '') {
    module14Data.investmentSummaryTotalRentalVal = invPropertyRoiData['Rental Income Sum'];
  }
  return module14Data.investmentSummaryTotalRentalVal;
}
// Total Property
export function investmentSummaryTotalProperty() {
  module14Data.investmentSummaryTotalPropertyVal = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1 !== '' && invPropertyRoiData.Data1['REI Total Operating Expenses'] !== undefined && invPropertyRoiData.Data1['REI Total Operating Expenses'] !== '') {
    module14Data.investmentSummaryTotalPropertyVal = -(invPropertyRoiData.Data1['REI Total Operating Expenses']);
  }
  return module14Data.investmentSummaryTotalPropertyVal;
}
// Total Financing Expenses (Principal & Interest)
export function investmentSummaryTotalFinancingExpenses() {
  module14Data.investmentSummaryTotalFinancingExpensesVal = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1 !== '' && invPropertyRoiData.Data1['REI Total Financing Expenses'] !== undefined && invPropertyRoiData.Data1['REI Total Financing Expenses'] !== '') {
    module14Data.investmentSummaryTotalFinancingExpensesVal = -(invPropertyRoiData.Data1['REI Total Financing Expenses']);
  }
  return module14Data.investmentSummaryTotalFinancingExpensesVal;
}
// Total Net Cash Flow
export function investmentSummaryNetRental() {
  module14Data.investmentSummaryNetRentalVal = 0;
  module14Data.investmentSummaryNetRentalVal = module14Data.investmentSummaryTotalRentalVal + module14Data.investmentSummaryTotalPropertyVal + module14Data.investmentSummaryTotalFinancingExpensesVal;
  return module14Data.investmentSummaryNetRentalVal;
}
// totalCashInvested
export function totalCashInvested() {
  module14Data.totalCashInvestedVal = 0;
  module14Data.totalCashInvestedVal = module14Data.realEstatecashNeededAtPurchase;
  return module14Data.totalCashInvestedVal;
}
// totalCashReturned
export function totalCashReturned() {
  module14Data.totalCashReturnedVal = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.Data1 !== undefined && invPropertyRoiData.Data1 !== '' && invPropertyRoiData.Data1['Net Cash Flow Before Taxes Sum'] !== undefined && invPropertyRoiData.Data1['Net Cash Flow Before Taxes Sum'] !== '') {
    module14Data.totalCashReturnedVal = (invPropertyRoiData.Data1['Net Cash Flow Before Taxes Sum']);
  }
  return module14Data.totalCashReturnedVal;
}
// Total Annual Expenses
export function totalyearlyExpenses() {
  module14Data.totalyearlyExpensesVal = 0;
  let totalExpenses = 0;

  if (module14Data.realEstateYearlyMaintenanceCalcs !== undefined) {
    totalExpenses = totalExpenses + parseFloat(module14Data.realEstateYearlyMaintenanceCalcs);
  }
  if (module14Data.managementFeeCalcs !== undefined) {
    totalExpenses = totalExpenses + parseFloat(module14Data.managementFeeCalcs);
  }
  if (module14Data.monthlyUtilitiesCalcs !== undefined) {
    totalExpenses = totalExpenses + parseFloat(module14Data.monthlyUtilitiesCalcs);
  }
  if (module14Data.realEstatehomeownerInsuranceCalcs !== undefined) {
    totalExpenses = totalExpenses + parseFloat(module14Data.realEstatehomeownerInsuranceCalcs);
  }
  if (module14Data.realEstatePropertyTaxesExpensesCalcs !== undefined) {
    totalExpenses = totalExpenses + parseFloat(module14Data.realEstatePropertyTaxesExpensesCalcs);
  }
  if (module14Data.yearly_association_fees !== undefined) {
    totalExpenses = totalExpenses + parseFloat(module14Data.yearly_association_fees);
  }
  module14Data.totalyearlyExpensesVal = totalExpenses;
  return module14Data.totalyearlyExpensesVal;
}

// Sale Price
export function investmentSummarySalePrice() {
  module14Data.investmentSummarySalePriceVal = module14Data.salesPrice;
  return module14Data.investmentSummarySalePriceVal;
}

// ROI
export function ROICal() {
  module14Data.ROICalVal = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.ROI !== undefined && invPropertyRoiData.ROI !== '') {
    module14Data.ROICalVal = invPropertyRoiData.ROI;
  }
  return module14Data.ROICalVal;
}
// MoM
export function MoMCal() {
  module14Data.MoMCalVal = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.MoM !== undefined && invPropertyRoiData.MoM !== '') {
    module14Data.MoMCalVal = invPropertyRoiData.MoM;
  }
  return module14Data.MoMCalVal;
}
// Cash on Cash return
export function cashOnCashReturn() {
  module14Data.cashOnCashReturn = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.CoC !== undefined && invPropertyRoiData.CoC !== '') {
    module14Data.cashOnCashReturn = invPropertyRoiData.CoC;
  }
  return module14Data.cashOnCashReturn;
}
// IRR
export function IRRCal() {
  module14Data.IRRCalVal = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData.IRR !== undefined && invPropertyRoiData.IRR !== '') {
    module14Data.IRRCalVal = invPropertyRoiData.IRR;
  }
  return module14Data.IRRCalVal;
}
// Adjusted Rental Income
export function adjustedRentalIncome() {
  module14Data.adjustedRentalIncome = 0;
  if (module14Data.realEstateAdjustedRentalIncome !== undefined && module14Data.realEstateAdjustedRentalIncome !== '') {
    module14Data.adjustedRentalIncome = module14Data.realEstateAdjustedRentalIncome / 12;
  }
  return module14Data.adjustedRentalIncome;
}
// Mortgage Payment (Principal & Interest)
export function mortgagepaymentInterest() {
  module14Data.mortgagepaymentInterest = 0;
  if (module14Data.realEstateMonthlyPayment !== undefined && module14Data.realEstateMonthlyPayment !== '') {
    module14Data.mortgagepaymentInterest = -module14Data.realEstateMonthlyPayment;
  }
  return module14Data.mortgagepaymentInterest;
}
// Property Taxes
export function propertytaxesValue() {
  module14Data.propertytaxesValue = 0;
  if (module14Data.realEstatePropertyTaxesExpensesCalcs !== undefined && module14Data.realEstatePropertyTaxesExpensesCalcs !== '') {
    module14Data.propertytaxesValue = -module14Data.realEstatePropertyTaxesExpensesCalcs / 12;
  }
  return module14Data.propertytaxesValue;
}
// Homeowner's Insurance
export function homeOwnersInsuranceValue() {
  module14Data.homeOwnersInsuranceValue = 0;
  if (module14Data.realEstatehomeownerInsuranceCalcs !== undefined && module14Data.realEstatehomeownerInsuranceCalcs !== '') {
    module14Data.homeOwnersInsuranceValue = -module14Data.realEstatehomeownerInsuranceCalcs / 12;
  }
  return module14Data.homeOwnersInsuranceValue;
}
// PITI (Principal, Interest, Taxes, & Insurance)
export function reiPitiValue() {
  module14Data.reiPitiValue = 0;
  if (module14Data.mortgagepaymentInterest !== undefined && module14Data.propertytaxesValue !== undefined && module14Data.homeOwnersInsuranceValue !== undefined) {
    module14Data.reiPitiValue = module14Data.mortgagepaymentInterest + module14Data.propertytaxesValue + module14Data.homeOwnersInsuranceValue;
  }
  return module14Data.reiPitiValue;
}

// Outstanding Home Mortgage
export function homeOutstandingMortgage() {
  module13Data.homeOutstandingMortgage = 0;
  if (module13Data.Mortage !== undefined && module13Data.Mortage !== '' && module13Data.Mortage > 0) {
    module13Data.homeOutstandingMortgage = module13Data.Mortage;
  }
  return module13Data.homeOutstandingMortgage;
}

export function homeTotalIncomeinYear() {
  module13Data.totalIncomeYear = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== ''
    && incomeStatementData[module13Data.year_of_purchase] !== undefined
    && incomeStatementData[module13Data.year_of_purchase]['Total Income'] !== undefined && incomeStatementData[module13Data.year_of_purchase]['Total Income'] !== '') {
    module13Data.totalIncomeYear = incomeStatementData[module13Data.year_of_purchase]['Total Income'] / 12;
  }
  return module13Data.totalIncomeYear;
}

// Management Fee
export function managementValue() {
  module14Data.managementValue = 0;
  if (module14Data.managementFeeCalcs !== undefined && module14Data.managementFeeCalcs !== '') {
    module14Data.managementValue = -module14Data.managementFeeCalcs / 12;
  }
  return module14Data.managementValue;
}

// Maintenance
export function maintenanceValue() {
  module14Data.maintenanceValue = 0;
  if (module14Data.realEstateYearlyMaintenanceCalcs !== undefined && module14Data.realEstateYearlyMaintenanceCalcs !== '') {
    module14Data.maintenanceValue = -module14Data.realEstateYearlyMaintenanceCalcs / 12;
  }
  return module14Data.maintenanceValue;
}
//Yearly Maintenance (%)
export function realEstateYearlyAssociationFeesCalcs() {
  module14Data.realEstateYearlyAssociationFeesCalcs = 0;
  if (module14Data.yearly_association_fees !== undefined) {
    module14Data.realEstateYearlyAssociationFeesCalcs = module14Data.yearly_association_fees;
  }
  return module14Data.realEstateYearlyAssociationFeesCalcs;
}
// Association Fees
export function associationFeesValue() {
  module14Data.associationFeesValue = 0;
  if (module14Data.realEstateYearlyAssociationFeesCalcs !== undefined && module14Data.realEstateYearlyAssociationFeesCalcs !== '') {
    module14Data.associationFeesValue = -module14Data.realEstateYearlyAssociationFeesCalcs / 12;
  }
  return module14Data.associationFeesValue;
}
// Utilities
export function utilitiesExpenseValue() {
  module14Data.utilitiesExpenseValue = 0;
  if (module14Data.monthlyUtilitiesCalcs !== undefined && module14Data.monthlyUtilitiesCalcs !== '') {
    module14Data.utilitiesExpenseValue = -module14Data.monthlyUtilitiesCalcs / 12;
  }
  return module14Data.utilitiesExpenseValue;
}
// Total Monthly Expenses
export function reiTotalMonthlyExpenses() {
  module14Data.reiTotalMonthlyExpenses = 0;
  if (module14Data.reiPitiValue !== undefined && module14Data.managementValue !== undefined && module14Data.maintenanceValue !== undefined && module14Data.associationFeesValue !== undefined && module14Data.utilitiesExpenseValue !== undefined) {
    module14Data.reiTotalMonthlyExpenses = module14Data.reiPitiValue + module14Data.managementValue + module14Data.maintenanceValue + module14Data.associationFeesValue + module14Data.utilitiesExpenseValue;
  }
  return module14Data.reiTotalMonthlyExpenses;
}
// Monthly Net Income
export function monthlyNetIncomeExpenses() {
  module14Data.monthlyNetIncomeExpenses = 0;
  if (module14Data.adjustedRentalIncome !== undefined && module14Data.reiTotalMonthlyExpenses !== undefined) {
    module14Data.monthlyNetIncomeExpenses = module14Data.adjustedRentalIncome + module14Data.reiTotalMonthlyExpenses;
  }
  return module14Data.monthlyNetIncomeExpenses;
}
// Selling Price
export function moreREISellingPrice() {
  module14Data.moreREISellingPrice = module14Data.salesPrice;
  return module14Data.moreREISellingPrice;
}
// Selling Cost
export function moreREISellingCost() {
  module14Data.moreREISellingCost = 0;
  if (module14Data.sellingCostCalcs !== undefined && module14Data.sellingCostCalcs !== '') {
    module14Data.moreREISellingCost = parseFloat(module14Data.sellingCostCalcs);
  }
  return module14Data.moreREISellingCost;
}
// Net Selling Cost
export function moreREINetSellingPrice() {
  module14Data.moreREINetSellingPrice = 0;
  if (module14Data.moreREISellingPrice !== undefined && module14Data.moreREISellingPrice !== '' && module14Data.moreREISellingCost !== undefined && module14Data.moreREISellingCost !== '') {
    module14Data.moreREINetSellingPrice = parseFloat(module14Data.moreREISellingPrice) - parseFloat(module14Data.moreREISellingCost);
  }
  return module14Data.moreREINetSellingPrice;
}
// Purchase Price
export function moreREIPurchasePrice() {
  module14Data.moreREIPurchasePrice = 0;
  if (module14Data.purchase_price !== undefined && module14Data.purchase_price !== '') {
    module14Data.moreREIPurchasePrice = module14Data.purchase_price;
  }
  return module14Data.moreREIPurchasePrice;
}
// Closing Costs
export function moreREIClosingCost() {
  module14Data.moreREIClosingCost = 0;
  let totalClosingCosts = 0;

  if (module14Data.realEstateClosingCosts !== undefined) {
    totalClosingCosts = totalClosingCosts + module14Data.realEstateClosingCosts;
  }
  if (module14Data.external_and_internal_fit_out_costs !== undefined) {
    totalClosingCosts = totalClosingCosts + parseFloat(module14Data.external_and_internal_fit_out_costs);
  }
  module14Data.moreREIClosingCost = totalClosingCosts;
  return module14Data.moreREIClosingCost;
}
// Gain
export function moreREIGain() {
  module14Data.moreREIGain = 0;
  if (module14Data.moreREINetSellingPrice !== undefined && module14Data.moreREINetSellingPrice !== '' && module14Data.moreREIPurchasePrice !== undefined && module14Data.moreREIPurchasePrice !== '' && module14Data.moreREIClosingCost !== undefined && module14Data.moreREIClosingCost !== '') {
    module14Data.moreREIGain = parseFloat(module14Data.moreREINetSellingPrice) - parseFloat(module14Data.moreREIPurchasePrice) - parseFloat(module14Data.moreREIClosingCost);
  }
  return module14Data.moreREIGain;
}
// Tax Capital
export function moreREITaxCapital() {
  module14Data.moreREITaxCapital = 0;
  if (module14Data.moreREIGain !== undefined && module14Data.moreREIGain !== '' && module14Data.moreREIGain > 0) {
    module14Data.moreREITaxCapital = parseFloat(module14Data.moreREIGain) * (15 / 100);
  }
  return module14Data.moreREITaxCapital;
}

// TmoreREITaxAccumulatedValue
export function moreREITaxAccumulatedValue(action) {
  module14Data.moreREITaxAccumulatedValue = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' &&
    invPropertyRoiData['REI Total'] !== undefined && invPropertyRoiData['REI Total'] !== '') {
    module14Data.moreREITaxAccumulatedValue = invPropertyRoiData['REI Total'];
  }
  if (action !== undefined && action === 'convert') {
    // return $filter('currency')(module14Data.moreREITsaxAccumulatedValue, '', 0);
    return FcCommonFunctions.currency(module14Data.moreREITsaxAccumulatedValue);
  } else {
    return module14Data.moreREITaxAccumulatedValue;
  }
}

// Tax Accumulated
export function moreREITaxAccumulated() {
  module14Data.moreREITaxAccumulated = 0;
  if (invPropertyRoiData !== undefined && invPropertyRoiData !== '' && invPropertyRoiData['REI Total'] !== undefined && invPropertyRoiData['REI Total'] !== '') {
    module14Data.moreREITaxAccumulated = parseFloat(invPropertyRoiData['REI Total']) * (25 / 100);
  }
  return module14Data.moreREITaxAccumulated;
}
/** ******************** Module 14 Completed *********************** */

/** ******************** Module 13 *********************** */
// Down Payment (%)
export function downPaymentCalcs() {
  module13Data.downPaymentCalcs = 0;
  if (module13Data.down_payment_in_percentage !== undefined && module13Data.down_payment_in_percentage > 0) {
    module13Data.downPaymentCalcs = (parseFloat(module13Data.down_payment_in_percentage) * module13Data.purchase_price) / 100;
  }
  return module13Data.downPaymentCalcs;
}
// Transfer Taxes (%) Calcs
export function transferTaxesCalcs() {
  module13Data.transferTaxesCalcs = 0;
  if (module13Data.transfer_taxes_in_percentage !== undefined && module13Data.transfer_taxes_in_percentage > 0) {
    module13Data.transferTaxesCalcs = (parseFloat(module13Data.transfer_taxes_in_percentage) * module13Data.purchase_price) / 100;
  }
  return module13Data.transferTaxesCalcs;
}
// Title Insurance(%) Calcs
export function titleInsuranceCalcs() {
  module13Data.titleInsuranceCalcs = 0;
  if (module13Data.title_insurance !== undefined && module13Data.title_insurance > 0) {
    module13Data.titleInsuranceCalcs = (parseFloat(module13Data.title_insurance) * module13Data.purchase_price) / 100;
  }
  return module13Data.titleInsuranceCalcs;
}
// Buying a Home Value
export function buyingHomeValue() {
  module13Data.buyingHomeValue = 0;
  let purchasePrice = 0;
  if (module13Data.purchase_price !== undefined && module13Data.purchase_price !== '') {
    purchasePrice = module13Data.purchase_price;
  } else {
    purchasePrice = 0;
  }
  module13Data.buyingHomeValue = purchasePrice;
  return module13Data.buyingHomeValue;
}
// Property Tax (%) Calcs
export function propertyTaxesCalcs() {
  module13Data.propertyTaxesCalcs = 0;
  if (module13Data.property_tax_in_percentage !== undefined && module13Data.property_tax_in_percentage > 0 && module13Data.buyingHomeValue !== undefined) {
    module13Data.propertyTaxesCalcs = (parseFloat(module13Data.property_tax_in_percentage) * module13Data.buyingHomeValue) / 100;
  }
  return module13Data.propertyTaxesCalcs;
}
// Homeowner's Insurance (%) Calcs
export function homeownerInsuranceCalcs() {
  module13Data.homeownerInsuranceCalcs = 0;
  if (module13Data.home_owners_insurance_in_percentage !== undefined && module13Data.home_owners_insurance_in_percentage > 0 && module13Data.buyingHomeValue !== undefined) {
    module13Data.homeownerInsuranceCalcs = (parseFloat(module13Data.home_owners_insurance_in_percentage) * module13Data.buyingHomeValue) / 100;
  }
  return module13Data.homeownerInsuranceCalcs;
}
// Yearly Maintenance (%) Calcs
export function yearlyMaintenanceCalcs() {
  module13Data.yearlyMaintenanceCalcs = 0;
  if (module13Data.yearly_maintanance_in_percentage !== undefined && module13Data.yearly_maintanance_in_percentage > 0 && module13Data.buyingHomeValue !== undefined) {
    module13Data.yearlyMaintenanceCalcs = (parseFloat(module13Data.yearly_maintanance_in_percentage) * module13Data.buyingHomeValue) / 100;
  }
  return module13Data.yearlyMaintenanceCalcs;
}
// Yearly Association Calcs
export function yearlyAssociationCalcs() {
  module13Data.yearlyAssociationCalcs = 0;
  if (module13Data.yearly_association_fees !== undefined && module13Data.yearly_association_fees !== '') {
    module13Data.yearlyAssociationCalcs = module13Data.yearly_association_fees;
  }
  return module13Data.yearlyAssociationCalcs;
}
// Monthly Utilities Calcs
export function homeMonthlyUtilitiesCalcs() {
  module13Data.homeMonthlyUtilitiesCalcs = 0;
  if (module13Data.monthly_utilities !== undefined && module13Data.monthly_utilities !== '') {
    module13Data.homeMonthlyUtilitiesCalcs = module13Data.monthly_utilities * 12;
  }
  return module13Data.homeMonthlyUtilitiesCalcs;
}
//appreciation
export function appreciation_in_percentage() {
  module13Data.appreciation_in_percentage = 0;
  if (module13Data.monthly_utilities !== undefined && module13Data.monthly_utilities !== '') {
    module13Data.appreciation_in_percentage = module13Data.monthly_utilities * 12;
  }
  return module13Data.appreciation_in_percentage;
}
// Mortage
export function Mortage() {
  module13Data.Mortage = 0;
  if (module13Data.purchase_price !== undefined && module13Data.purchase_price > 0) {
    module13Data.Mortage = module13Data.purchase_price - parseFloat(module13Data.downPaymentCalcs);
  }
  return module13Data.Mortage;
}
// Private Mortgage Insurance
export function privateMortgageInsuranceCalcs() {
  module13Data.privateMortgageInsuranceCalcs = 0;
  if (module13Data.Mortage !== undefined && module13Data.Mortage !== '' && module13Data.private_mortgage_insurance !== undefined && module13Data.private_mortgage_insurance > 0) {
    module13Data.privateMortgageInsuranceCalcs = module13Data.Mortage * (module13Data.private_mortgage_insurance / 100);
  }
  return module13Data.privateMortgageInsuranceCalcs;
}
// Loan Points Calcs
export function buyingAhomeLoanPointsCalcs() {
  module13Data.buyingAhomeLoanPointsCalcs = 0;
  if (module13Data.Mortage !== undefined && module13Data.Mortage !== '' && module13Data.points_for_the_mortgage !== undefined && module13Data.points_for_the_mortgage > 0) {
    module13Data.buyingAhomeLoanPointsCalcs = module13Data.Mortage * (module13Data.points_for_the_mortgage / 100);
  }
  return module13Data.buyingAhomeLoanPointsCalcs;
}
// Origination Calcs
export function originationFeesCalcs() {
  module13Data.originationFeesCalcs = 0;
  if (module13Data.Mortage !== undefined && module13Data.Mortage !== '' && module13Data.origination_fees !== undefined && module13Data.origination_fees !== '') {
    module13Data.originationFeesCalcs = module13Data.Mortage * (module13Data.origination_fees / 100);
  }
  return module13Data.originationFeesCalcs;
}
// Monthly Payment
export function moreHighermonthlyPayment() {
  module13Data.moreHighermonthlyPayment = 0;
  const mortage1FirstData = mortgage1Data;
  if (mortage1FirstData.Mortage1 !== undefined && mortage1FirstData.Mortage1['Original Payment'] !== undefined && mortage1FirstData.Mortage1['Original Payment'] !== '') {
    module13Data.moreHighermonthlyPayment = mortage1FirstData.Mortage1['Original Payment'];
  }
  return module13Data.moreHighermonthlyPayment;
}
// Yearly Payment
export function yearlyPayment() {
  module13Data.yearlyPayment = 0;
  if (module13Data.moreHighermonthlyPayment !== undefined && module13Data.moreHighermonthlyPayment !== '') {
    module13Data.yearlyPayment = parseFloat(module13Data.moreHighermonthlyPayment) * 12;
  }
  return module13Data.yearlyPayment;
}
// Buying A Home Total Interest
export function biyingaHomeTotalInterest() {
  module13Data.biyingaHomeTotalInterest = 0;
  if (mortgage1Data !== undefined && mortgage1Data !== '' && mortgage1Data['Total Interest Paid'] !== undefined && mortgage1Data['Total Interest Paid'] !== '') {
    module13Data.biyingaHomeTotalInterest = mortgage1Data['Total Interest Paid'];
  }
  return module13Data.biyingaHomeTotalInterest;
}
// Buying A Home Total Principal Interest
export function biyingaHomeTotalPrncipalInterest() {
  module13Data.biyingaHomeTotalPrncipalInterest = 0;
  if (mortgage1Data !== undefined && mortgage1Data !== '' && mortgage1Data['Total Paid'] !== undefined && mortgage1Data['Total Paid'] !== '') {
    module13Data.biyingaHomeTotalPrncipalInterest = mortgage1Data['Total Paid'];
  }
  return module13Data.biyingaHomeTotalPrncipalInterest;
}
// Closing Costs
export function closingCosts() {
  module13Data.closingCosts = 0;
  if (module13Data.purchase_price !== undefined && module13Data.purchase_price !== '' && module13Data.down_payment_in_percentage !== undefined && module13Data.down_payment_in_percentage !== '') {
    if (buyingAHomeData !== undefined && buyingAHomeData !== '' && buyingAHomeData['Closing Cost']['Total Initial Costs'] !== undefined) {
      module13Data.closingCosts = buyingAHomeData['Closing Cost']['Total Initial Costs'];
    } else {
      module13Data.closingCosts = 0;
    }
  }
  return module13Data.closingCosts;
}
// Mortgage Payment (Principal & Interest)
export function mortgagePaymentExpenses() {
  module13Data.mortgagePaymentExpenses = 0;
  if (module13Data.purchase_price !== undefined && module13Data.purchase_price !== '') {
    module13Data.mortgagePaymentExpenses = module13Data.moreHighermonthlyPayment;
  }
  return module13Data.mortgagePaymentExpenses;
}
// Mortgage Payment (Principal & Interest)
export function propertyTaxesExpenses() {
  module13Data.propertyTaxesExpenses = 0;
  if (module13Data.propertyTaxesCalcs !== undefined && module13Data.propertyTaxesCalcs !== '') {
    module13Data.propertyTaxesExpenses = module13Data.propertyTaxesCalcs / 12;
  }
  return module13Data.propertyTaxesExpenses;
}
// Private Mortgage Insurance (PMI)
export function privateMortgageInsExpenses() {
  module13Data.privateMortgageInsExpenses = 0;
  if (module13Data.privateMortgageInsuranceCalcs !== undefined && module13Data.privateMortgageInsuranceCalcs !== '') {
    module13Data.privateMortgageInsExpenses = module13Data.privateMortgageInsuranceCalcs / 12;
  }
  return module13Data.privateMortgageInsExpenses;
}
// Homeowner's Insurance
export function homeOwnersInsExpenses() {
  module13Data.homeOwnersInsExpenses = 0;
  if (module13Data.homeownerInsuranceCalcs !== undefined && module13Data.homeownerInsuranceCalcs !== '') {
    module13Data.homeOwnersInsExpenses = module13Data.homeownerInsuranceCalcs / 12;
  }
  return module13Data.homeOwnersInsExpenses;
}
// PITI (Principal, Interest, Taxes, & Insurance)
export function pitiExpenses() {
  module13Data.pitiExpenses = 0;
  if (module13Data.mortgagePaymentExpenses !== undefined && module13Data.propertyTaxesExpenses !== undefined && module13Data.privateMortgageInsExpenses !== undefined && module13Data.homeOwnersInsExpenses !== undefined) {
    module13Data.pitiExpenses = module13Data.mortgagePaymentExpenses + module13Data.propertyTaxesExpenses + module13Data.privateMortgageInsExpenses + module13Data.homeOwnersInsExpenses;
  }
  return module13Data.pitiExpenses;
}
// Maintenance
export function maintenanceExpenses() {
  module13Data.maintenanceExpenses = 0;
  if (module13Data.yearlyMaintenanceCalcs !== undefined && module13Data.yearlyMaintenanceCalcs !== '') {
    module13Data.maintenanceExpenses = module13Data.yearlyMaintenanceCalcs / 12;
  }
  return module13Data.maintenanceExpenses;
}
// Association Fees
export function assiciationFeesExpenses() {
  module13Data.assiciationFeesExpenses = 0;
  if (module13Data.yearlyAssociationCalcs !== undefined && module13Data.yearlyAssociationCalcs !== '') {
    module13Data.assiciationFeesExpenses = module13Data.yearlyAssociationCalcs / 12;
  }
  return module13Data.assiciationFeesExpenses;
}
// Utilities
export function utilitiesExpenses() {
  module13Data.utilitiesExpenses = 0;
  if (module13Data.homeMonthlyUtilitiesCalcs !== undefined && module13Data.homeMonthlyUtilitiesCalcs !== '') {
    module13Data.utilitiesExpenses = module13Data.homeMonthlyUtilitiesCalcs / 12;
  }
  return module13Data.utilitiesExpenses;
}
// Total Monthly Expenses
export function totalMonthlyExpenses() {
  module13Data.totalMonthlyExpenses = 0;
  if (module13Data.pitiExpenses !== undefined && module13Data.maintenanceExpenses !== undefined && module13Data.assiciationFeesExpenses !== undefined && module13Data.utilitiesExpenses !== undefined) {
    module13Data.totalMonthlyExpenses = module13Data.pitiExpenses + module13Data.maintenanceExpenses + module13Data.assiciationFeesExpenses
      + module13Data.utilitiesExpenses;
  }
  return module13Data.totalMonthlyExpenses;
}
// Total Income Before Taxes
export function totalIncomeBeforweTaxesBuyingHomeValue() {
  module13Data.totalIncomeBeforweTaxesBuyingHomeValue = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module13Data.year_of_purchase] !== undefined && incomeStatementData[module13Data.year_of_purchase]['Total Interest Expenses'] !== '') {
    module13Data.totalIncomeBeforweTaxesBuyingHomeValue = incomeStatementData[module13Data.year_of_purchase]['Total Income'] / 12;
  }
  return module13Data.totalIncomeBeforweTaxesBuyingHomeValue;
}
// % of Total Income Before Taxes
export function totalIncomeBeforweTaxesBuyingHomePercentage() {
  module13Data.totalIncomeBeforweTaxesBuyingHomePercentage = 0;
  if (module13Data.totalMonthlyExpenses === 0 || module13Data.totalIncomeBeforweTaxesBuyingHomeValue === 0) {
    module13Data.totalIncomeBeforweTaxesBuyingHomePercentage = 0;
  } else {
    module13Data.totalIncomeBeforweTaxesBuyingHomePercentage = (module13Data.totalMonthlyExpenses / module13Data.totalIncomeBeforweTaxesBuyingHomeValue) * 100;
  }
  return module13Data.totalIncomeBeforweTaxesBuyingHomePercentage;
}
// Purchase Price
export function homePurchasePrice() {
  module13Data.homePurchasePrice = 0;
  if (module13Data.purchase_price !== undefined && module13Data.purchase_price !== '') {
    module13Data.homePurchasePrice = module13Data.purchase_price;
  }
  return module13Data.homePurchasePrice;
}
// Down Payment
export function homeDownPayment() {
  module13Data.homeDownPayment = 0;
  if (module13Data.downPaymentCalcs !== undefined && module13Data.downPaymentCalcs !== '') {
    module13Data.homeDownPayment = parseFloat(module13Data.downPaymentCalcs);
  }
  return module13Data.homeDownPayment;
}
// Closing Costs
export function homeClosingCosts() {
  module13Data.homeClosingCosts = 0;
  if (module13Data.closingCosts !== undefined && module13Data.closingCosts !== '') {
    module13Data.homeClosingCosts = parseFloat(module13Data.closingCosts);
  }
  return module13Data.homeClosingCosts;
}
// Fit Out Costs
export function homeFitOutCosts() {
  module13Data.homeFitOutCosts = 0;
  if (module13Data.external_and_internal_fit_out_costs !== undefined && module13Data.external_and_internal_fit_out_costs !== '') {
    module13Data.homeFitOutCosts = module13Data.external_and_internal_fit_out_costs;
  }
  return module13Data.homeFitOutCosts;
}
// Cash Needed at Purchase
export function cashNeedsPurchase() {
  module13Data.cashNeedsPurchase = 0;
  if (module13Data.purchase_price !== undefined && module13Data.purchase_price > 0) {
    module13Data.cashNeedsPurchase = module13Data.homeFitOutCosts + module13Data.homeClosingCosts + module13Data.homeDownPayment;
  }
  return module13Data.cashNeedsPurchase;
}
// Total Cash Available
export function moreHigherTotalCashAvailable() {
  module13Data.moreHigherTotalCashAvailable = 0;
  if (module13Data.year_of_purchase !== undefined && cashFlowData[module13Data.year_of_purchase] !== undefined) {
    module13Data.moreHigherTotalCashAvailable = cashFlowData[module13Data.year_of_purchase]['Beginning Cash Balance'];
  }
  return module13Data.moreHigherTotalCashAvailable;
}
// Total Liquid Assets Available
export function moreHighermoreTotalLiquid() {
  module13Data.moreHighermoreTotalLiquid = 0;
  if (module13Data.year_of_purchase !== undefined && module1Data.start_year !== undefined && module13Data.year_of_purchase === module1Data.start_year) {
    if (module9Data.short_term_begining_balance !== undefined && module9Data.long_term_begining_balance !== undefined) {
      module13Data.moreHighermoreTotalLiquid = module9Data.short_term_begining_balance + module9Data.long_term_begining_balance;
    }
  } else if (module13Data.year_of_purchase !== undefined && module13Data.year_of_purchase !== '' && module13Data.year_of_purchase > 0) {
    const calYear = parseInt(module13Data.year_of_purchase, 10) - 1;
    if (balanceSheetData !== undefined && balanceSheetData[calYear] !== undefined && balanceSheetData[calYear].Investments !== undefined) {
      module13Data.moreHighermoreTotalLiquid = parseFloat(balanceSheetData[parseInt(module13Data.year_of_purchase, 10) - 1].Investments['Short-Term Investments']) + parseFloat(balanceSheetData[parseInt(module13Data.year_of_purchase, 10) - 1].Investments['Long-Term Investments']);
    }
  }
  return module13Data.moreHighermoreTotalLiquid;
}
// Debt-to-Income
export function debtToIncomeRatio() {
  module13Data.debtToIncomeRatio = 0;
  let totalInterestExpensesDebt5 = 0;
  if (incomeStatementData[module13Data.year_of_purchase] !== undefined && incomeStatementData[module13Data.year_of_purchase]['Total Interest Expenses'] !== '') {
    totalInterestExpensesDebt5 = incomeStatementData[module13Data.year_of_purchase]['Total Interest Expenses'];
  }

  if (incomeStatementData[module13Data.year_of_purchase] !== undefined && incomeStatementData[module13Data.year_of_purchase]['Total Income'] !== undefined && incomeStatementData[module13Data.year_of_purchase]['Total Income'] !== '' && incomeStatementData[module13Data.year_of_purchase]['Total Income'] > 0) {
    module13Data.debtToIncomeRatio = (-(parseFloat(totalInterestExpensesDebt5)) / parseFloat(incomeStatementData[module13Data.year_of_purchase]['Total Income'])) * 100;
  } else {
    module13Data.debtToIncomeRatio = 0;
  }
  return module13Data.debtToIncomeRatio;
}
/** ******************** Module 13 Completed *********************** */

/** ******************** Module 7 *********************** */

// Years
export function moreOnCampusMonthlyExpensesYears() {
  module6Data.onCampusYearsDiff = 0;
  var expenseCalculationb8 = 0;
  if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year === 0 && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationb8 = module6Data.graduation_year;
  } else {
    if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.off_campus_start_year > module6Data.on_campus_start_year) {
      expenseCalculationb8 = parseInt(module6Data.off_campus_start_year) - 1;
    } else {
      if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year > module6Data.off_campus_start_year && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
        expenseCalculationb8 = module6Data.graduation_year;
      } else {
        expenseCalculationb8 = 0;
      }
    }
  }

  if (expenseCalculationb8 > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year !== '') {
    module6Data.onCampusYearsDiff = expenseCalculationb8 - module6Data.on_campus_start_year + 1;
  }

  return module6Data.onCampusYearsDiff;
}

// Room and Board
export function moreFetchRoomBoard() {
  module6Data.fetchRoomBoard = 0;
  if (module6Data.on_campus_start_year !== undefined
    && module6Data.room_board !== undefined && module6Data.room_board !== '') {
    module6Data.fetchRoomBoard = parseFloat(module6Data.room_board / 12);
  }
  return module6Data.fetchRoomBoard;
}

/** Portion 2 * */
// Offcampus Years Diff
export function offCampusYearDiff() {
  module2Data.offCampusYearDiff = 0;
  let expenseCalculationb24 = 0;
  if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year === 0 && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    expenseCalculationb24 = module2Data.graduation_year;
  } else if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.on_campus_start_year > module2Data.off_campus_start_year) {
    expenseCalculationb24 = parseInt(module2Data.on_campus_start_year, 10) - 1;
  } else if (module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year > 0 && module2Data.on_campus_start_year !== undefined && module2Data.on_campus_start_year > 0 && module2Data.off_campus_start_year > module2Data.on_campus_start_year && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    expenseCalculationb24 = module2Data.graduation_year;
  } else {
    expenseCalculationb24 = 0;
  }

  if (expenseCalculationb24 > 0 && module2Data.off_campus_start_year !== undefined && module2Data.off_campus_start_year !== '') {
    module2Data.offCampusYearDiff = (expenseCalculationb24 - module2Data.off_campus_start_year) + 1;
  }
  return module2Data.offCampusYearDiff;
}

// Student Loan payment Value
export function studentLoanPayment() {
  module6Data.studentLoanPaymentVal = 0;
  const incomeStatementTotalDepreciationExpensesData = incomeStatementData;
  if (incomeStatementTotalDepreciationExpensesData[module6Data.on_campus_start_year] !== undefined
    && incomeStatementTotalDepreciationExpensesData[module6Data.on_campus_start_year] !== '') {
    module6Data.studentLoanPaymentVal = -(incomeStatementTotalDepreciationExpensesData[module6Data.on_campus_start_year].InterestExpenses['Student Loans']) / 12 - (cashFlowData[module6Data.on_campus_start_year]['Cash from Financing Activities']['Student Loans Principal Payback']) / 12;
  }
  return module6Data.studentLoanPaymentVal;
}
// Total Expenses Value
export function livingExpensestotal() {
  module6Data.livingExpensesTotalExpenses = 0;
  if (module6Data.fetchRoomBoard !== undefined && module6Data.fetchRoomBoard >= 0
    && module6Data.off_campus_food !== undefined && module6Data.off_campus_food >= 0
    && module6Data.clothing !== undefined && module6Data.clothing >= 0
    && module6Data.entertainment !== undefined && module6Data.entertainment >= 0
    && module6Data.technology !== undefined && module6Data.technology >= 0
    && module6Data.transportation !== undefined && module6Data.transportation >= 0
    && module6Data.miscellaneous !== undefined && module6Data.miscellaneous >= 0
    && module6Data.medical_costs_before_deductible !== undefined && module6Data.medical_costs_before_deductible >= 0
    && module6Data.health_insurance_premium !== undefined && module6Data.health_insurance_premium >= 0) {
    module6Data.livingExpensesTotalExpenses = module6Data.fetchRoomBoard
      + module6Data.off_campus_food
      + module6Data.clothing
      + module6Data.entertainment
      + module6Data.technology
      + module6Data.transportation
      + module6Data.miscellaneous
      + module6Data.medical_costs_before_deductible
      + module6Data.health_insurance_premium;
  }
  return module6Data.livingExpensesTotalExpenses;
}
// Years
export function newOnCampusMonthlyExpensesYears() {
  module15Data.onCampusYearsDiff = 0;
  let expenseCalculationb125 = 0;
  if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb125 = module15Data.graduation_year;
  } else if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.off_campus_start_year > module15Data.on_campus_start_year) {
    expenseCalculationb125 = parseInt(module15Data.off_campus_start_year, 10) - 1;
  } else if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year > module15Data.off_campus_start_year && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb125 = module15Data.graduation_year;
  } else {
    expenseCalculationb125 = 0;
  }
  if (expenseCalculationb125 > 0 && module15Data.start_year !== undefined && module15Data.start_year !== '') {
    module15Data.onCampusYearsDiff = expenseCalculationb125 - module15Data.start_year + 1;
  }

  return module15Data.onCampusYearsDiff;
}

// Years
export function moreOffCampusMonthlyExpensesYears() {
  module6Data.offCampusYearsDiff = 0;
  let expenseCalculationb91 = 0;
  if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year === 0 && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationb91 = module6Data.graduation_year;
  } else if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.on_campus_start_year > module6Data.off_campus_start_year) {
    expenseCalculationb91 = parseInt(module6Data.on_campus_start_year, 10) - 1;
  } else if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year > module6Data.on_campus_start_year && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationb91 = module6Data.graduation_year;
  } else {
    expenseCalculationb91 = 0;
  }
  if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year !== '') {
    module6Data.offCampusYearsDiff = expenseCalculationb91 - module6Data.off_campus_start_year + 1;
  }
  return module6Data.offCampusYearsDiff;
}
// Student Loan payment Value
export function morelivingExpensesStudentLoanPayment() {
  module6Data.morelivingExpensesStudentLoanPaymentVal = 0;
  const incomeStatementTotalDepreciationExpensesData = incomeStatementData;
  if (incomeStatementTotalDepreciationExpensesData[module6Data.off_campus_start_year] !== undefined
    && incomeStatementTotalDepreciationExpensesData[module6Data.off_campus_start_year] !== '') {
    module6Data.morelivingExpensesStudentLoanPaymentVal = -(incomeStatementTotalDepreciationExpensesData[module6Data.off_campus_start_year].InterestExpenses['Student Loans']) / 12 - (cashFlowData[module6Data.off_campus_start_year]['Cash from Financing Activities']['Student Loans Principal Payback']) / 12;
  }
  return module6Data.morelivingExpensesStudentLoanPaymentVal;
}
// Total Expenses Value
export function morelivingExpensestotal() {
  module6Data.morelivingExpensestotal = 0;
  if (module6Data.rent !== undefined && module6Data.rent >= 0
    && module6Data.utilities !== undefined && module6Data.utilities >= 0
    && module6Data.food !== undefined && module6Data.food >= 0
    && module6Data.second_clothing !== undefined && module6Data.second_clothing >= 0
    && module6Data.second_entertainment !== undefined && module6Data.second_entertainment >= 0
    && module6Data.second_technology !== undefined && module6Data.second_technology >= 0
    && module6Data.second_transportation !== undefined && module6Data.second_transportation >= 0
    && module6Data.second_miscellaneous !== undefined && module6Data.second_miscellaneous >= 0
    && module6Data.second_medical_costs_before_deductible !== undefined && module6Data.second_medical_costs_before_deductible >= 0
    && module6Data.second_health_insurance_premium !== undefined && module6Data.second_health_insurance_premium >= 0) {
    module6Data.morelivingExpensestotal = module6Data.rent
      + module6Data.utilities
      + module6Data.food
      + module6Data.second_clothing
      + module6Data.second_entertainment
      + module6Data.second_technology
      + module6Data.second_transportation
      + module6Data.second_miscellaneous
      + module6Data.second_medical_costs_before_deductible
      + module6Data.second_health_insurance_premium;
  }
  return module6Data.morelivingExpensestotal;
}
// Monthly Price off Campus
export function moremonthlyPriceOffCampus() {
  module6Data.moremonthlyPriceOffCampus = 0;
  if (module6Data.livingExpensesTotalExpenses !== undefined && module6Data.livingExpensesTotalExpenses !== '' && module6Data.livingExpensesTotalExpenses > 0
    && module6Data.morelivingExpensestotal !== undefined && module6Data.morelivingExpensestotal !== '') {
    module6Data.moremonthlyPriceOffCampus = module6Data.morelivingExpensestotal - module6Data.livingExpensesTotalExpenses;
  }
  return module6Data.moremonthlyPriceOffCampus;
}
// Monthly Percentage off Campus
export function moremonthlyPercentageOffCampus() {
  module6Data.moremonthlyPercentageOffCampus = 0;
  if (module6Data.livingExpensesTotalExpenses !== undefined && module6Data.livingExpensesTotalExpenses !== '' && module6Data.livingExpensesTotalExpenses > 0
    && module6Data.morelivingExpensestotal !== undefined && module6Data.morelivingExpensestotal !== '') {
    module6Data.moremonthlyPercentageOffCampus = ((module6Data.morelivingExpensestotal - module6Data.livingExpensesTotalExpenses) / module6Data.livingExpensesTotalExpenses) * 100;
  }

  return parseFloat(module6Data.moremonthlyPercentageOffCampus).toFixed(1);
}
// Student Loan payment Value
export function livingExpensesCashExcess() {
  module6Data.livingExpensesCashExcessVal = 0;
  if (module6Data.moreHigherinvestmentAnalysisCashExcess !== undefined
    && module6Data.moreHigherinvestmentAnalysisCashExcess !== '') {
    module6Data.livingExpensesCashExcessVal = module6Data.moreHigherinvestmentAnalysisCashExcess;
  }
  return module6Data.livingExpensesCashExcessVal;
}
// Total Living Expenses Value
export function moreHigherlivingExpensesTotal() {
  module6Data.moreHigherlivingExpensesTotal = 0;
  // const start_year = parseInt( module6Data.start_year, 10 );
  // const endYear = parseInt( module6Data.graduation_year, 10 );
  // const data = incomeStatementData;
  const Val1 = 0;
  const Val2 = 0;
  // if ( Object.keys( data ).length !== 0 ) {
  //   data.forEach( ( value,year ) => {
  //     if ( year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Total Living Expenses'] !== undefined && data[year].InterestExpenses['Student Loans'] !== undefined ) {
  //       if ( data[year] !== undefined ) {
  //         Val1 += data[year]['Total Living Expenses'];
  //         Val2 += data[year].InterestExpenses['Student Loans'];
  //       }
  //     }
  //   } );
  // }
  // const cashFlowdata = cashFlowData;
  const Val3 = 0;
  // if ( Object.keys( cashFlowdata ).length !== 0 ) {
  //   cashFlowdata.forEach( ( year ) => {
  //     if ( year >= start_year && year <= endYear ) {
  //       if ( cashFlowdata[year] !== undefined
  //         && cashFlowdata[year]['Cash from Financing Activities'] !== undefined ) {
  //         Val3 += cashFlowdata[year]['Cash from Financing Activities']['Student Loans Principal Payback'];
  //       }
  //     }
  //   } );
  // }
  module6Data.moreHigherlivingExpensesTotal = Val1 + Val2 - module6Data.moreHigherinvestmentAnalysisTuitionCosts + Val3;
  return module6Data.moreHigherlivingExpensesTotal;
}
// Cash Excess (Deficit) AFTER Living Expenses
export function moreHigherCashExcess() {
  module6Data.moreHigherCashExcessVal = module6Data.livingExpensesCashExcessVal
    + module6Data.moreHigherlivingExpensesTotal;
  return module6Data.moreHigherCashExcessVal;
}

// Year Payment Begins
export function moreFedSubPaymentYearBegins() {
  module6Data.moreFedSubPaymentYearBegins = 0;
  if (module6Data.graduation_year !== undefined && module6Data.graduation_year > 0
    && module2Data.start_year !== undefined && module2Data.start_year > 0
    && module2Data.start_year === (parseInt(module6Data.graduation_year, 10) + 1)
    && module2Data.graduation_year !== undefined && module2Data.graduation_year > 0) {
    module6Data.moreFedSubPaymentYearBegins = module2Data.graduation_year + 1;
  } else if (module6Data.graduation_year !== undefined && module6Data.graduation_year > 0
    && module17Data.start_year !== undefined && module17Data.start_year > 0
    && module17Data.start_year === (parseInt(module6Data.graduation_year, 10) + 1)
    && module15Data.graduation_year !== undefined && module15Data.graduation_year > 0) {
    module6Data.moreFedSubPaymentYearBegins = module15Data.graduation_year + 1;
  } else if (module6Data.graduation_year !== undefined && module6Data.graduation_year > 0) {
    module6Data.moreFedSubPaymentYearBegins = module6Data.graduation_year + 1;
  } else {
    module6Data.moreFedSubPaymentYearBegins = 0;
  }
  return module6Data.moreFedSubPaymentYearBegins;
}

// Fedsub organization value
export function moreFedSubOrganizationValue() {
  module6Data.moreFedSubOrganizationValue = 0;
  let moreTotalMaxLimits = 0;

  if (module6Data.fed_sub_firstyear !== undefined && module6Data.fed_sub_firstyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 1) {
    moreTotalMaxLimits = module6Data.fed_sub_firstyear;
  }
  if (module6Data.fed_sub_secondyear !== undefined && module6Data.fed_sub_secondyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 2) {
    moreTotalMaxLimits = module6Data.fed_sub_secondyear + moreTotalMaxLimits;
  }
  if (module6Data.fed_sub_thirdyear !== undefined && module6Data.fed_sub_thirdyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 3) {
    moreTotalMaxLimits = module6Data.fed_sub_thirdyear + moreTotalMaxLimits;
  }
  if (module6Data.fed_sub_fourthyear !== undefined && module6Data.fed_sub_fourthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 4) {
    moreTotalMaxLimits = module6Data.fed_sub_fourthyear + moreTotalMaxLimits;
  }
  if (module6Data.fed_sub_fifthyear !== undefined && module6Data.fed_sub_fifthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 5) {
    moreTotalMaxLimits = module6Data.fed_sub_fifthyear + moreTotalMaxLimits;
  }
  if (module6Data.fed_sub_sixthyear !== undefined && module6Data.fed_sub_sixthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 6) {
    moreTotalMaxLimits = module6Data.fed_sub_sixthyear + moreTotalMaxLimits;
  }

  if (module6Data.fed_sub_origination_fee !== undefined && module6Data.fed_sub_origination_fee !== '') {
    module6Data.moreFedSubOrganizationValue = (moreTotalMaxLimits / 100) * module6Data.fed_sub_origination_fee;
  }
  return module6Data.moreFedSubOrganizationValue;
}
// Fed Unsub organization value
export function moreFedUnSubOrganizationValue() {
  module6Data.moreFedUnSubOrganizationValue = 0;
  let moreFedUnsubTotalMaxLimits = 0;

  if (module6Data.fed_unsub_firstyear !== undefined && module6Data.fed_unsub_firstyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 1) {
    moreFedUnsubTotalMaxLimits = module6Data.fed_unsub_firstyear;
  }
  if (module6Data.fed_unsub_secondyear !== undefined && module6Data.fed_unsub_secondyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 2) {
    moreFedUnsubTotalMaxLimits = module6Data.fed_unsub_secondyear + moreFedUnsubTotalMaxLimits;
  }
  if (module6Data.fed_unsub_thirdyear !== undefined && module6Data.fed_unsub_thirdyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 3) {
    moreFedUnsubTotalMaxLimits = module6Data.fed_unsub_thirdyear + moreFedUnsubTotalMaxLimits;
  }
  if (module6Data.fed_unsub_fourthyear !== undefined && module6Data.fed_unsub_fourthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 4) {
    moreFedUnsubTotalMaxLimits = module6Data.fed_unsub_fourthyear + moreFedUnsubTotalMaxLimits;
  }
  if (module6Data.fed_unsub_fifthyear !== undefined && module6Data.fed_unsub_fifthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 5) {
    moreFedUnsubTotalMaxLimits = module6Data.fed_unsub_fifthyear + moreFedUnsubTotalMaxLimits;
  }
  if (module6Data.fed_unsub_sixthyear !== undefined && module6Data.fed_unsub_sixthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 6) {
    moreFedUnsubTotalMaxLimits = module6Data.fed_unsub_sixthyear + moreFedUnsubTotalMaxLimits;
  }

  if (module6Data.fed_unsub_origination_fee !== undefined && module6Data.fed_unsub_origination_fee !== '') {
    module6Data.moreFedUnSubOrganizationValue = (moreFedUnsubTotalMaxLimits / 100) * module6Data.fed_unsub_origination_fee;
  }
  return module6Data.moreFedUnSubOrganizationValue;
}

// Fed Private organization value
export function morePrivateOrganizationValue() {
  module6Data.morePrivateOrganizationValue = 0;
  let totalMorePrivateMaxLimits = 0;
  if (module6Data.private_firstyear !== undefined && module6Data.private_firstyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 1) {
    totalMorePrivateMaxLimits = module6Data.private_firstyear;
  }
  if (module6Data.private_secondyear !== undefined && module6Data.private_secondyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 2) {
    totalMorePrivateMaxLimits = module6Data.private_secondyear + totalMorePrivateMaxLimits;
  }
  if (module6Data.private_thirdyear !== undefined && module6Data.private_thirdyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 3) {
    totalMorePrivateMaxLimits = module6Data.private_thirdyear + totalMorePrivateMaxLimits;
  }
  if (module6Data.private_fourthyear !== undefined && module6Data.private_fourthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 4) {
    totalMorePrivateMaxLimits = module6Data.private_fourthyear + totalMorePrivateMaxLimits;
  }
  if (module6Data.private_fifthyear !== undefined && module6Data.private_fifthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 5) {
    totalMorePrivateMaxLimits = module6Data.private_fifthyear + totalMorePrivateMaxLimits;
  }
  if (module6Data.private_sixthyear !== undefined && module6Data.private_sixthyear !== '' && module6Data.years_diff !== undefined && module6Data.years_diff >= 6) {
    totalMorePrivateMaxLimits = module6Data.private_sixthyear + totalMorePrivateMaxLimits;
  }

  if (module6Data.private_origination_fee !== undefined && module6Data.private_origination_fee !== '') {
    module6Data.morePrivateOrganizationValue = (totalMorePrivateMaxLimits / 100) * module6Data.private_origination_fee;
  }
  return module6Data.morePrivateOrganizationValue;
}

// Cal Beginning Balance
export function moreFedSubBeginningBalance() {
  if (module6Data.fed_sub_firstyear !== undefined && module6Data.fed_sub_secondyear !== undefined && module6Data.fed_sub_thirdyear !== undefined && module6Data.fed_sub_fourthyear !== undefined && module6Data.fed_sub_fifthyear !== undefined && module6Data.fed_sub_sixthyear !== undefined) {
    module6Data.moreFedSubBeginningBalance = module6Data.fed_sub_firstyear
      + module6Data.fed_sub_secondyear
      + module6Data.fed_sub_thirdyear
      + module6Data.fed_sub_fourthyear
      + module6Data.fed_sub_fifthyear
      + module6Data.fed_sub_sixthyear;
  } else {
    module6Data.moreFedSubBeginningBalance = 0;
  }
  return module6Data.moreFedSubBeginningBalance;
}

// Cal Monthly Payment
export function moreFedSubMonthlyPayment() {
  module6Data.moreFedSubMonthlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedSubMonthlyPayment = fedSubMoreHigherEdLoanData['Original Payment'];
  }
  return module6Data.moreFedSubMonthlyPayment;
}

// Cal Yearly Payment
export function moreFedSubYearlyPayment() {
  module6Data.moreFedSubYearlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedSubYearlyPayment = module6Data.moreFedSubMonthlyPayment * 12;
  }
  return module6Data.moreFedSubYearlyPayment;
}

// Cal Total Interest Paid
export function moreFedSubTotalInterestPaid() {
  module6Data.moreFedSubTotalInterestPaid = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedSubTotalInterestPaid = fedSubMoreHigherEdLoanData['Total Interest Paid'];
  }
  return module6Data.moreFedSubTotalInterestPaid;
}

// Cal Total Prinicipal & Interest
export function moreFedSubTotalPrincipalInterest() {
  module6Data.moreFedSubTotalPrincipalInterest = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedSubTotalPrincipalInterest = fedSubMoreHigherEdLoanData['Total Paid'];
  }
  return module6Data.moreFedSubTotalPrincipalInterest;
}

// Cal Principal
export function moreFedUnSubPrincipal() {
  if (module6Data.fed_unsub_firstyear !== undefined && module6Data.fed_unsub_secondyear !== undefined && module6Data.fed_unsub_thirdyear !== undefined && module6Data.fed_unsub_fourthyear !== undefined && module6Data.fed_unsub_fifthyear !== undefined && module6Data.fed_unsub_sixthyear !== undefined) {
    module6Data.moreFedUnSubPrincipal = module6Data.fed_unsub_firstyear
      + module6Data.fed_unsub_secondyear
      + module6Data.fed_unsub_thirdyear
      + module6Data.fed_unsub_fourthyear
      + module6Data.fed_unsub_fifthyear
      + module6Data.fed_unsub_sixthyear;
  } else {
    module6Data.moreFedUnSubPrincipal = 0;
  }
  return module6Data.moreFedUnSubPrincipal;
}

// Cal Accrued Interest
export function moreFedUnSubAccruedInterest() {
  module6Data.moreFedUnSubAccruedInterest = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedUnSubAccruedInterest = fedUnSubMoreHigherEdLoanData['Accrued Interest'];
  }
  return module6Data.moreFedUnSubAccruedInterest;
}

// Cal Begginning Balance
export function moreFedUnSubBeginningBalance() {
  module6Data.moreFedUnSubBeginningBalance = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedUnSubBeginningBalance = fedUnSubMoreHigherEdLoanData['Beginning Balance'];
  }
  return module6Data.moreFedUnSubBeginningBalance;
}

// Cal Monthly Payment
export function moreFedUnSubMonthlyPayment() {
  module6Data.moreFedUnSubMonthlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedUnSubMonthlyPayment = fedUnSubMoreHigherEdLoanData['Original Payment'];
  }
  return module6Data.moreFedUnSubMonthlyPayment;
}

// Cal Yearly Payment
export function moreFedUnSubYearlyPayment() {
  module6Data.moreFedUnSubYearlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedUnSubYearlyPayment = module6Data.moreFedUnSubMonthlyPayment * 12;
  }
  return module6Data.moreFedUnSubYearlyPayment;
}

// Cal Total Interest Paid
export function moreFedUnSubTotalInterestPaid() {
  module6Data.moreFedUnSubTotalInterestPaid = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedUnSubTotalInterestPaid = fedUnSubMoreHigherEdLoanData['Total Interest Paid'];
  }
  return module6Data.moreFedUnSubTotalInterestPaid;
}

// Cal Total Principal & Interest
export function moreFedUnSubTotalPrincipalInterest() {
  module6Data.moreFedUnSubTotalPrincipalInterest = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.moreFedUnSubTotalPrincipalInterest = fedUnSubMoreHigherEdLoanData['Total Paid'];
  }
  return module6Data.moreFedUnSubTotalPrincipalInterest;
}

// Cal Principal
export function morePrivatePrincipal() {
  if (module6Data.private_firstyear !== undefined && module6Data.private_secondyear !== undefined && module6Data.private_thirdyear !== undefined && module6Data.private_fourthyear !== undefined && module6Data.private_fifthyear !== undefined && module6Data.private_sixthyear !== undefined) {
    module6Data.morePrivatePrincipal = module6Data.private_firstyear
      + module6Data.private_secondyear
      + module6Data.private_thirdyear
      + module6Data.private_fourthyear
      + module6Data.private_fifthyear
      + module6Data.private_sixthyear;
  } else {
    module6Data.morePrivatePrincipal = 0;
  }

  return module6Data.morePrivatePrincipal;
}

// Cal Accrued Interest
export function morePrivateAccruedInterest() {
  module6Data.morePrivateAccruedInterest = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.morePrivateAccruedInterest = privateMoreHigherEdLoanData['Accrued Interest'];
  }
  return module6Data.morePrivateAccruedInterest;
}

// Cal Begginning Balance
export function morePrivateBeginningBalance() {
  module6Data.morePrivateBeginningBalance = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.morePrivateBeginningBalance = privateMoreHigherEdLoanData['Beginning Balance'];
  }
  return module6Data.morePrivateBeginningBalance;
}

// Cal Monthly Payment
export function morePrivateMonthlyPayment() {
  module6Data.morePrivateMonthlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.morePrivateMonthlyPayment = privateMoreHigherEdLoanData['Original Payment'];
  }
  return module6Data.morePrivateMonthlyPayment;
}

// Cal Yearly Payment
export function morePrivateYearlyPayment() {
  module6Data.morePrivateYearlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.morePrivateYearlyPayment = module6Data.morePrivateMonthlyPayment * 12;
  }
  return module6Data.morePrivateYearlyPayment;
}

// Cal Total Interest Paid
export function morePrivateTotalInterestPaid() {
  module6Data.morePrivateTotalInterestPaid = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.morePrivateTotalInterestPaid = privateMoreHigherEdLoanData['Total Interest Paid'];
  }
  return module6Data.morePrivateTotalInterestPaid;
}

// Cal Total Principal & Interest
export function morePrivateTotalPrincipalInterest() {
  module6Data.morePrivateTotalPrincipalInterest = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.morePrivateTotalPrincipalInterest = privateMoreHigherEdLoanData['Total Paid'];
  }
  return module6Data.morePrivateTotalPrincipalInterest;
}

// Cal Current Student Loans Balance
export function moreHigherCurrentStudentLoansBalance() {
  module6Data.moreHigherCurrentStudentLoansBalance = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    if (balanceSheetData[parseInt(module6Data.start_year, 10) - 1] !== undefined
      && balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Liabilities['Student Loans'] !== undefined
      && balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Liabilities['Student Loans'] !== '') {
      module6Data.moreHigherCurrentStudentLoansBalance = parseFloat(balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Liabilities['Student Loans']).toFixed(2);
    }
  }
  return module6Data.moreHigherCurrentStudentLoansBalance;
}

// Cal Summary Loan Points
export function moreSummaryLoanPoints() {
  module6Data.moreSummaryLoanPoints = 0;
  if (module6Data.moreFedSubOrganizationValue !== undefined && module6Data.moreFedUnSubOrganizationValue !== undefined && module6Data.morePrivateOrganizationValue !== undefined) {
    module6Data.moreSummaryLoanPoints = -(module6Data.moreFedSubOrganizationValue + module6Data.moreFedUnSubOrganizationValue + module6Data.morePrivateOrganizationValue);
  }
  return module6Data.moreSummaryLoanPoints;
}

// Cal Principal
export function moreHigherStudentLoanPrincipal() {
  module6Data.studentLoanPrincipal = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0
    && module6Data.graduation_year !== undefined && module6Data.graduation_year > 0
    && module6Data.yearlyDistribution !== undefined && module6Data.yearlyDistribution !== '') {
    module6Data.studentLoanPrincipal = module6Data.yearlyDistribution * ((module6Data.graduation_year - module6Data.start_year) + 1);
  }
  return module6Data.studentLoanPrincipal;
}

// Cal Accrued Interest
export function moreHigherStudentLoanAccruedInterest() {
  module6Data.studentLoanAccruedInterest = 0;
  if (module6Data.start_year !== undefined && parseInt(module6Data.start_year, 10) > 0) {
    module6Data.studentLoanAccruedInterest = studentLoan2Data['Accrued Interest'];
  }
  return module6Data.studentLoanAccruedInterest;
}
// Cal Beginning Balance
export function moreHigherStudentLoanBeginningBalance() {
  module6Data.studentLoanBeginningBalance = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.studentLoanBeginningBalance = studentLoan2Data['Beginning Balance'];
  }
  return module6Data.studentLoanBeginningBalance;
}

// Cal Monthly Payment
export function moreHigherStudentLoanMonthlyPayment() {
  module6Data.studentLoanMonthlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.studentLoanMonthlyPayment = studentLoan2Data['Original Payment'];
  }
  return module6Data.studentLoanMonthlyPayment;
}
// Cal Yearly Payment
export function moreHigherStudentLoanYearlyPayment() {
  module6Data.studentLoanYearlyPayment = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    module6Data.studentLoanYearlyPayment = module6Data.studentLoanMonthlyPayment * 12;
  }
  return module6Data.studentLoanYearlyPayment;
}
// Cal Total Interest Paid
export function moreHigherStudentLoanTotalInterestPaid() {
  module6Data.studentLoanTotalInterestPaid = 0;
  let val1 = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year > 0) {
    val1 = studentLoan2Data['Total Interest Paid'];
  }
  module6Data.studentLoanTotalInterestPaid = val1;
  return module6Data.studentLoanTotalInterestPaid;
}

// Cal Current Balance
export function moreHigherStudentLoanCurrentBalance() {
  module6Data.studentLoanCurrentBalance = 0;
  if (module6Data.start_year !== undefined) {
    let val1 = 0;
    if (fedSubHigherEdLoanData['Summation Data'] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][module6Data.start_year] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance !== undefined) {
      val1 = fedSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance;
    }
    let val2 = 0;
    if (fedUnSubHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][module6Data.start_year] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance !== undefined) {
      val2 = fedUnSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance;
    }
    let val3 = 0;
    if (privateHigherEdLoanData['Summation Data'] !== undefined
      && privateHigherEdLoanData['Summation Data'][module6Data.start_year] !== undefined
      && privateHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance !== undefined) {
      val3 = privateHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance;
    }
    let val4 = 0;
    if (existingStudentLoanData['Summation Data'] !== undefined
      && existingStudentLoanData['Summation Data'][module15Data.start_year] !== undefined
      && existingStudentLoanData['Summation Data'][module15Data.start_year].BeginningBalance !== undefined) {
      val4 = existingStudentLoanData['Summation Data'][module15Data.start_year].BeginningBalance;
    }
    module6Data.studentLoanCurrentBalance = val1 + val2 + val3 + val4;
  }
  return module6Data.studentLoanCurrentBalance;
}

// Cal Current Student Loans Monthly Payment
export function moreHigherStudentLoanMoreMonthlyPayment() {
  module6Data.studentLoanMoreMonthlyPayment = 0;
  let val1 = 0;
  if (fedSubHigherEdLoanData['Summation Data'] !== undefined
    && fedSubHigherEdLoanData['Summation Data'][module6Data.start_year] !== undefined
    && fedSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance !== undefined
    && fedSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance > 0) {
    val1 = fedSubHigherEdLoanData['Original Payment'];
  }
  let val2 = 0;
  if (fedUnSubHigherEdLoanData['Summation Data'] !== undefined
    && fedUnSubHigherEdLoanData['Summation Data'][module6Data.start_year] !== undefined
    && fedUnSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance !== undefined
    && fedUnSubHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance > 0) {
    val2 = fedUnSubHigherEdLoanData['Original Payment'];
  }
  let val3 = 0;
  if (privateHigherEdLoanData['Summation Data'] !== undefined
    && privateHigherEdLoanData['Summation Data'][module6Data.start_year] !== undefined
    && privateHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance !== undefined
    && privateHigherEdLoanData['Summation Data'][module6Data.start_year].BeginningBalance > 0) {
    val3 = privateHigherEdLoanData['Original Payment'];
  }
  let val4 = 0;
  if (existingStudentLoanData['Summation Data'] !== undefined
    && existingStudentLoanData['Summation Data'][module6Data.start_year] !== undefined
    && existingStudentLoanData['Summation Data'][module6Data.start_year].BeginningBalance !== undefined
    && existingStudentLoanData['Summation Data'][module6Data.start_year].BeginningBalance > 0) {
    val4 = existingStudentLoanData['Original Payment'];
  }

  module6Data.studentLoanMoreMonthlyPayment = val1 + val2 + val3 + val4;
  return module6Data.studentLoanMoreMonthlyPayment;
}

// Cal Current Student Loans Annual Payment
export function moreHigherStudentLoanMoreAnnualPayment() {
  module6Data.moreHigherStudentLoanMoreAnnualPayment = module6Data.studentLoanMoreMonthlyPayment * 12;
  return module6Data.moreHigherStudentLoanMoreAnnualPayment;
}

// Cal Estimated Tax Rate
export function moreHigherEarnedEstimatedTaxRate() {
  module6Data.estimatedTaxRate = 0;
  if (module6Data.yearly_income !== undefined
    && module6Data.yearly_income > 0) {
    // module6Data.estimatedTaxRate = parseFloat(estimatedIncomeTaxes.singleIteration7());
  }

  return parseFloat(module6Data.estimatedTaxRate).toFixed(1);
}
// Cal Estimated tax Paid
export function moreHigherestimatedTaxesPaid() {
  module6Data.estimatedTaxesPaid = 0;
  if (module6Data.yearly_income !== undefined && module6Data.yearly_income > 0
    && module6Data.estimatedTaxRate !== undefined && module6Data.estimatedTaxRate > 0) {
    module6Data.estimatedTaxesPaid = module6Data.yearly_income * (module6Data.estimatedTaxRate / 100);
  }
  return module6Data.estimatedTaxesPaid;
}

// Cal Estimated tax Paid
export function moreHigherEstimatedIncomeafterTaxes() {
  module6Data.estimatedIncomeafterTaxes = 0;
  if (module6Data.yearly_income !== undefined && module6Data.yearly_income > 0
    && module6Data.estimatedTaxesPaid !== undefined && module6Data.estimatedTaxesPaid > 0) {
    module6Data.estimatedIncomeafterTaxes = module6Data.yearly_income - module6Data.estimatedTaxesPaid;
  }
  return module6Data.estimatedIncomeafterTaxes;
}
/** Portion 8 * */
// Investment Analysis Scholships
export function moreHigherinvestmentAnalysisScholships() {
  module6Data.moreHigherinvestmentAnalysisScholships = 0;
  if (module6Data.yearly_scholarship_amount !== undefined && module6Data.yearly_scholarship_amount !== ''
    && module6Data.years_diff !== undefined && module6Data.years_diff !== '') {
    module6Data.moreHigherinvestmentAnalysisScholships = module6Data.yearly_scholarship_amount * module6Data.years_diff;
  }
  return module6Data.moreHigherinvestmentAnalysisScholships;
}
// Investment Grants
export function moreHigherinvestmentAnalysisGrants() {
  module6Data.moreHigherinvestmentAnalysisGrants = 0;
  if (module6Data.yearly_grant_amount !== undefined && module6Data.yearly_grant_amount !== ''
    && module6Data.years_diff !== undefined && module6Data.years_diff !== '') {
    module6Data.moreHigherinvestmentAnalysisGrants = module6Data.yearly_grant_amount * module6Data.years_diff;
  }
  return module6Data.moreHigherinvestmentAnalysisGrants;
}
// Investment Analysis Financial Aid
export function moreHigherinvestmentAnalysisFinancialAid() {
  module6Data.moreHigherinvestmentAnalysisFinancialAid = 0;
  if (module6Data.expected_yearly_finance_aid !== undefined && module6Data.expected_yearly_finance_aid !== ''
    && module6Data.aidYearsDiff !== undefined && module6Data.aidYearsDiff !== '') {
    module6Data.moreHigherinvestmentAnalysisFinancialAid = module6Data.expected_yearly_finance_aid * module6Data.aidYearsDiff;
  }
  return module6Data.moreHigherinvestmentAnalysisFinancialAid;
}
export function earnedYearsDiff1() {
  module6Data.earnedYearsDiff1 = 0;
  if (module6Data.income_end_year !== undefined && module6Data.income_end_year > 0 && module6Data.income_start_year !== undefined && module6Data.income_start_year > 0) {
    module6Data.earnedYearsDiff1 = parseInt(module6Data.income_end_year, 10) - parseInt(module6Data.income_start_year, 10) + 1;
  }
  return module6Data.earnedYearsDiff1;
}
// Investment Analysis Income Earned
export function moreHigherinvestmentAnalysisIncomeEarned() {
  module6Data.moreHigherinvestmentAnalysisIncomeEarned = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '') {
    const start_year = parseInt(module6Data.start_year, 10);
    const endYear = parseInt(module6Data.graduation_year, 10);
    const tuitionCostData = incomeStatementData;
    let incomeEarnedAfterTaxesValCalue = 0;
    if (Object.keys(tuitionCostData).length !== 0) {
      Object.keys(tuitionCostData).forEach((year) => {
        if (year >= start_year && year <= endYear && tuitionCostData[year] !== undefined && tuitionCostData[year]['Income After Taxes'] !== undefined) {
          incomeEarnedAfterTaxesValCalue += tuitionCostData[year]['Income After Taxes'];
        }
      });
    }
    module6Data.moreHigherinvestmentAnalysisIncomeEarned = incomeEarnedAfterTaxesValCalue;
  } else {
    module6Data.moreHigherinvestmentAnalysisIncomeEarned = 0;
  }
  return module6Data.moreHigherinvestmentAnalysisIncomeEarned;
}

// Investment Analysis Family Contribution
export function moreHigherinvestmentAnalysisFamilyContribution() {
  module6Data.moreHigherinvestmentAnalysisFamilyContribution = 0;
  if (module6Data.yearly_contribution !== undefined && module6Data.yearly_contribution !== ''
    && module6Data.income_start_year !== undefined && module6Data.income_start_year !== ''
    && module6Data.income_end_year !== undefined && module6Data.income_end_year !== '') {
    module6Data.moreHigherinvestmentAnalysisFamilyContribution = module6Data.yearly_contribution * ((module6Data.income_end_year - module6Data.income_start_year) + 1);
  }
  return module6Data.moreHigherinvestmentAnalysisFamilyContribution;
}
// Investment Analysis Total Cash Available
export function moreHigherinvestmentAnalysisTotalCashAvailable() {
  module6Data.moreHigherinvestmentAnalysisTotalCashAvailable = 0;

  module6Data.moreHigherinvestmentAnalysisTotalCashAvailable = module6Data.moreHigherinvestmentAnalysisScholships
    + module6Data.moreHigherinvestmentAnalysisGrants
    + module6Data.moreHigherinvestmentAnalysisFinancialAid
    + module6Data.moreFedSubBeginningBalance
    + module6Data.moreFedUnSubPrincipal
    + module6Data.morePrivatePrincipal
    + module6Data.moreSummaryLoanPoints
    + module6Data.moreHigherinvestmentAnalysisIncomeEarned
    + module6Data.moreHigherinvestmentAnalysisFamilyContribution;
  return module6Data.moreHigherinvestmentAnalysisTotalCashAvailable;
}

// Investment Analysis Total Liquid Assets Available
export function moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable() {
  module6Data.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable = 0;
  let value1 = 0;
  if (module6Data.start_year === module1Data.start_year) {
    let val1 = 0;
    // if (module1Data.current_cash_balance !== undefined && module1Data.current_cash_balance !== '') {
    //   val1 = module1Data.current_cash_balance;
    // }
    let val2 = 0;
    if (module9Data.shortTermBeginningBalance !== undefined && module9Data.shortTermBeginningBalance !== '') {
      val2 = module9Data.shortTermBeginningBalance;
    }
    let val3 = 0;
    if (module9Data.longTermBeginningBalance !== undefined && module9Data.longTermBeginningBalance !== '') {
      val3 = module9Data.longTermBeginningBalance;
    }
    value1 = val1 + val2 + val3;
  } else {
    if (module6Data.start_year !== undefined && module6Data.start_year !== '') {
      if (balanceSheetData[parseInt(module6Data.start_year, 10) - 1] !== undefined
        && balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Assets.Cash !== undefined
        && balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Investments['Long-Term Investments'] !== undefined
        && balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Investments['Short-Term Investments'] !== undefined) {
        value1 = parseFloat(balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Assets.Cash)
          + parseFloat(balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Investments['Long-Term Investments'])
          + parseFloat(balanceSheetData[parseInt(module6Data.start_year, 10) - 1].Investments['Short-Term Investments']);
      } else {
        value1 = 0;
      }
    } else {
      value1 = 0;
    }
  }

  module6Data.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable = value1;
  return module6Data.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable;
}

// Investment Analysis Tuition Cost
export function moreHigherinvestmentAnalysisTuitionCosts() {
  module6Data.moreHigherinvestmentAnalysisTuitionCosts = 0;
  if (module6Data.tuition !== undefined
    && module6Data.tuition !== '' && module6Data.tuition > 0) {
    if (incomeStatementData !== undefined && incomeStatementData !== '') {
      const start_year = parseInt(module6Data.start_year, 10);
      const endYear = parseInt(module6Data.graduation_year, 10);
      const tuitionCostData = incomeStatementData;
      let moreInvestmentAnalysisTuitionCostsCalVal = 0;
      if (Object.keys(tuitionCostData).length !== 0) {
        Object.keys(tuitionCostData).forEach((year) => {
          if (year >= start_year && year <= endYear && tuitionCostData[year] !== undefined && tuitionCostData[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined) {
            moreInvestmentAnalysisTuitionCostsCalVal += tuitionCostData[year].livingExpenses['Higher Education Tuition and Fees'];
          }
        });
      }
      module6Data.moreHigherinvestmentAnalysisTuitionCosts = moreInvestmentAnalysisTuitionCostsCalVal;
    } else {
      module6Data.moreHigherinvestmentAnalysisTuitionCosts = 0;
    }
  }
  return module6Data.moreHigherinvestmentAnalysisTuitionCosts;
}
// Investment Analysis Cash Excess
export function moreHigherinvestmentAnalysisCashExcess() {
  module6Data.moreHigherinvestmentAnalysisCashExcess = 0;
  if (module6Data.moreHigherinvestmentAnalysisTotalCashAvailable !== undefined && module6Data.moreHigherinvestmentAnalysisTotalCashAvailable !== ''
    && module6Data.moreHigherinvestmentAnalysisTuitionCosts !== undefined && module6Data.moreHigherinvestmentAnalysisTuitionCosts !== '') {
    module6Data.moreHigherinvestmentAnalysisCashExcess = module6Data.moreHigherinvestmentAnalysisTotalCashAvailable
      + module6Data.moreHigherinvestmentAnalysisTuitionCosts;
  }
  return module6Data.moreHigherinvestmentAnalysisCashExcess;
}
// Investment Analysis Outstanding Loan
export function moreHigherinvestmentAnalysisOutstandingLoan() {
  module6Data.moreHigherinvestmentAnalysisOutstandingLoan = 0;
  module6Data.moreHigherinvestmentAnalysisOutstandingLoan = module6Data.moreFedSubBeginningBalance
    + module6Data.moreFedUnSubPrincipal
    + module6Data.morePrivatePrincipal;
  return module6Data.moreHigherinvestmentAnalysisOutstandingLoan;
}

// Investment Analysis Total Principal & Interest
export function moreHigherStudentLoanTotalPrincipalInterest() {
  module6Data.moreHigherStudentLoanTotalPrincipalInterest = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year !== '' && parseInt(module6Data.start_year, 10) > 0) {
    module6Data.moreHigherStudentLoanTotalPrincipalInterest = module6Data.moreFedSubTotalPrincipalInterest
      + module6Data.moreFedUnSubTotalPrincipalInterest
      + module6Data.morePrivateTotalPrincipalInterest;
  }
  return module6Data.moreHigherStudentLoanTotalPrincipalInterest;
}

// More Higher Education Expenses Rent Multiplier
export function moreHigherOffCampusRentMultiplier() {
  module6Data.moreHigherOffCampusRentMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusRentMultiplier = module10Data.rent_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusRentMultiplier = module10Data.rent_multiplier;
    } else {
      module6Data.moreHigherOffCampusRentMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusRentMultiplier;
}

// More Higher Education Expenses Rent Expenses
export function moreHigherOffCampusRentExpenses() {
  let rentExpenses = 0;
  module6Data.moreHigherOffCampusRentExpenses = 0;
  if (module6Data.moreHigherOffCampusRentMultiplier !== undefined
    && module6Data.moreHigherOffCampusRentMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.rent !== undefined
      && module6Data.rent !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Rent) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.rent !== ''
        && module6Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Rent) / 12);
      } else {
        rentExpenses = 0;
      }
    }
  } else {
    rentExpenses = '';
  }
  module6Data.moreHigherOffCampusRentExpenses = rentExpenses;

  return module6Data.moreHigherOffCampusRentExpenses;
}

// More Higher Education Expenses Utilities Multiplier
export function moreHigherOffCampusUtilitiesMultiplier() {
  module6Data.moreHigherOffCampusUtilitiesMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.utilities_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else {
      module6Data.moreHigherOffCampusUtilitiesMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusUtilitiesMultiplier;
}

// More Higher Education  Utilities Expenses
export function moreHigherOffCampusUtilitiesExpenses() {
  let utilitiesExpense = 0;
  module6Data.moreHigherOffCampusUtilitiesExpenses = 0;
  if (module6Data.moreHigherOffCampusUtilitiesMultiplier !== undefined
    && module6Data.moreHigherOffCampusUtilitiesMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.utilities !== undefined
      && module6Data.utilities !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Utilities) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.utilities !== ''
        && module6Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities) / 12);
      } else {
        utilitiesExpense = 0;
      }
    }
  } else {
    utilitiesExpense = '';
  }
  module6Data.moreHigherOffCampusUtilitiesExpenses = utilitiesExpense;

  return module6Data.moreHigherOffCampusUtilitiesExpenses;
}

// More Higher Education Expenses Food Multiplier
export function moreHigherOffCampusFoodMultiplier() {
  module6Data.moreHigherOffCampusFoodMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.food_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusFoodMultiplier = module10Data.food_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusFoodMultiplier = module10Data.food_multiplier;
    } else {
      module6Data.moreHigherOffCampusFoodMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusFoodMultiplier;
}

// More Higher Education  Food Expenses
export function moreHigherOffCampusFoodExpenses() {
  let foodExpenses = 0;
  module6Data.moreHigherOffCampusFoodExpenses = 0;
  if (module6Data.moreHigherOffCampusFoodMultiplier !== undefined
    && module6Data.moreHigherOffCampusFoodMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.food !== undefined
      && module6Data.food !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.food > 0) {
        foodExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Food) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.food !== ''
        && module6Data.food > 0) {
        foodExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Food) / 12);
      } else {
        foodExpenses = 0;
      }
    }
  } else {
    foodExpenses = '';
  }
  module6Data.moreHigherOffCampusFoodExpenses = foodExpenses;

  return module6Data.moreHigherOffCampusFoodExpenses;
}

// More Higher Education Expenses Clothing Multiplier
export function moreHigherOffCampusClothingMultiplier() {
  module6Data.moreHigherOffCampusClothingMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.clothing_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusClothingMultiplier = module10Data.clothing_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusClothingMultiplier = module10Data.clothing_multiplier;
    } else {
      module6Data.moreHigherOffCampusClothingMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusClothingMultiplier;
}

// More Higher Education  Clothing Expenses
export function moreHigherOffCampusClothingExpenses() {
  let clothingExpenses = 0;
  module6Data.moreHigherOffCampusClothingExpenses = 0;
  if (module6Data.moreHigherOffCampusClothingMultiplier !== undefined
    && module6Data.moreHigherOffCampusClothingMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.second_clothing !== undefined
      && module6Data.second_clothing !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.second_clothing > 0) {
        clothingExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Clothing) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.second_clothing !== ''
        && module6Data.second_clothing > 0) {
        clothingExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing) / 12);
      } else {
        clothingExpenses = 0;
      }
    }
  } else {
    clothingExpenses = '';
  }
  module6Data.moreHigherOffCampusClothingExpenses = clothingExpenses;

  return module6Data.moreHigherOffCampusClothingExpenses;
}

// More Higher Education Expenses Entertainment Multiplier
export function moreHigherOffCampusEntertainmentMultiplier() {
  module6Data.moreHigherOffCampusEntertainmentMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.entertainment_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else {
      module6Data.moreHigherOffCampusEntertainmentMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusEntertainmentMultiplier;
}

// More Higher Education  Entertainment Expenses
export function moreHigherOffCampusEntertainmentExpenses() {
  let entertainmentExpenses = 0;
  module6Data.moreHigherOffCampusEntertainmentExpenses = 0;
  if (module6Data.moreHigherOffCampusEntertainmentMultiplier !== undefined
    && module6Data.moreHigherOffCampusEntertainmentMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.second_entertainment !== undefined
      && module6Data.second_entertainment !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.second_entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Entertainment) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.second_entertainment !== ''
        && module6Data.second_entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment) / 12);
      } else {
        entertainmentExpenses = 0;
      }
    }
  } else {
    entertainmentExpenses = '';
  }
  module6Data.moreHigherOffCampusEntertainmentExpenses = entertainmentExpenses;

  return module6Data.moreHigherOffCampusEntertainmentExpenses;
}

// More Higher Education Expenses Technology Multiplier
export function moreHigherOffCampusTechnologyMultiplier() {
  module6Data.moreHigherOffCampusTechnologyMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.technology_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusTechnologyMultiplier = module10Data.technology_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusTechnologyMultiplier = module10Data.technology_multiplier;
    } else {
      module6Data.moreHigherOffCampusTechnologyMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusTechnologyMultiplier;
}

// More Higher Education  Technology Expenses
export function moreHigherOffCampusTechnologyExpenses() {
  let technologyExpenses = 0;
  module6Data.moreHigherOffCampusTechnologyExpenses = 0;
  if (module6Data.moreHigherOffCampusTechnologyMultiplier !== undefined
    && module6Data.moreHigherOffCampusTechnologyMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.second_technology !== undefined
      && module6Data.second_technology !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.second_technology > 0) {
        technologyExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Technology) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.second_technology !== ''
        && module6Data.second_technology > 0) {
        technologyExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Technology) / 12);
      } else {
        technologyExpenses = 0;
      }
    }
  } else {
    technologyExpenses = '';
  }
  module6Data.moreHigherOffCampusTechnologyExpenses = technologyExpenses;

  return module6Data.moreHigherOffCampusTechnologyExpenses;
}

// More Higher Education Expenses Transportation Multiplier
export function moreHigherOffCampusTransportationMultiplier() {
  module6Data.moreHigherOffCampusTransportationMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.transportation_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusTransportationMultiplier = module10Data.transportation_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusTransportationMultiplier = module10Data.transportation_multiplier;
    } else {
      module6Data.moreHigherOffCampusTransportationMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusTransportationMultiplier;
}

// More Higher Education  Transportation Expenses
export function moreHigherOffCampusTransportationExpenses() {
  let transportationExpenses = 0;
  module6Data.moreHigherOffCampusTransportationExpenses = 0;
  if (module6Data.moreHigherOffCampusTransportationMultiplier !== undefined
    && module6Data.moreHigherOffCampusTransportationMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.second_transportation !== undefined
      && module6Data.second_transportation !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.second_transportation > 0) {
        transportationExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Transportation) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.second_transportation !== ''
        && module6Data.second_transportation > 0) {
        transportationExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation) / 12);
      } else {
        transportationExpenses = 0;
      }
    }
  } else {
    transportationExpenses = '';
  }
  module6Data.moreHigherOffCampusTransportationExpenses = transportationExpenses;

  return module6Data.moreHigherOffCampusTransportationExpenses;
}

// More Higher Education Expenses Miscellaneous Multiplier
export function moreHigherOffCampusMiscellaneousMultiplier() {
  module6Data.moreHigherOffCampusMiscellaneousMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.miscellaneous_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else {
      module6Data.moreHigherOffCampusMiscellaneousMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusMiscellaneousMultiplier;
}

// More Higher Education  Miscellaneous Expenses
export function moreHigherOffCampusMiscellaneousExpenses() {
  let miscellaneousExpenses = 0;
  module6Data.moreHigherOffCampusMiscellaneousExpenses = 0;
  if (module6Data.moreHigherOffCampusMiscellaneousMultiplier !== undefined
    && module6Data.moreHigherOffCampusMiscellaneousMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.second_miscellaneous !== undefined
      && module6Data.second_miscellaneous !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.second_miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses.Miscellaneous) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.second_miscellaneous !== ''
        && module6Data.second_miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous) / 12);
      } else {
        miscellaneousExpenses = 0;
      }
    }
  } else {
    miscellaneousExpenses = '';
  }
  module6Data.moreHigherOffCampusMiscellaneousExpenses = miscellaneousExpenses;

  return module6Data.moreHigherOffCampusMiscellaneousExpenses;
}

// More Higher Education Expenses Health Insurance Multiplier
export function moreHigherOffCampusHealthInsuranceMultiplier() {
  module6Data.moreHigherOffCampusHealthInsuranceMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.health_insurance_costs_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusHealthInsuranceMultiplier = module10Data.health_insurance_costs_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusHealthInsuranceMultiplier = module10Data.health_insurance_costs_multiplier;
    } else {
      module6Data.moreHigherOffCampusHealthInsuranceMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusHealthInsuranceMultiplier;
}

// More Higher Education off Campus Outside Premium Expenses
export function moreHigherOffCampusOutsidePremiumExpenses() {
  let healthExpenses = 0;
  module6Data.moreHigherOffCampusOutsidePremiumExpenses = 0;
  if (module6Data.moreHigherOffCampusHealthInsuranceMultiplier !== undefined
    && module6Data.moreHigherOffCampusHealthInsuranceMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.second_medical_costs_before_deductible !== undefined
      && module6Data.second_medical_costs_before_deductible !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.second_medical_costs_before_deductible > 0) {
        healthExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.second_medical_costs_before_deductible !== ''
        && module6Data.second_medical_costs_before_deductible > 0) {
        healthExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module6Data.moreHigherOffCampusOutsidePremiumExpenses = healthExpenses;

  return module6Data.moreHigherOffCampusOutsidePremiumExpenses;
}

// More Higher Education Expenses Health Insurance Multiplier
export function moreHigherOffCampusHealthInsurancePremiumMultiplier() {
  module6Data.moreHigherOffCampusHealthInsurancePremiumMultiplier = 0;
  if (module6Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.health_insurance_premium_multiplier !== undefined) {
    if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.off_campus_start_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusHealthInsurancePremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else if (module6Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module6Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module6Data.moreHigherOffCampusHealthInsurancePremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else {
      module6Data.moreHigherOffCampusHealthInsurancePremiumMultiplier = '';
    }
  }
  return module6Data.moreHigherOffCampusHealthInsurancePremiumMultiplier;
}

// More Higher Education off Campus Outside Premium Expenses
export function moreHigherOffCampusPremiumExpenses() {
  let healthExpenses = 0;
  module6Data.moreHigherOffCampusPremiumExpenses = 0;
  if (module6Data.moreHigherOffCampusHealthInsurancePremiumMultiplier !== undefined
    && module6Data.moreHigherOffCampusHealthInsurancePremiumMultiplier !== '') {
    if (module6Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module6Data.second_health_insurance_premium !== undefined
      && module6Data.second_health_insurance_premium !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module6Data.off_campus_start_year] !== undefined
      && incomeStatementData[module6Data.off_campus_start_year] !== ''
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module6Data.off_campus_start_year].livingExpenses !== '') {
      if (module6Data.off_campus_start_year !== ''
        && module6Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module6Data.second_health_insurance_premium > 0) {
        healthExpenses = -((incomeStatementData[module6Data.off_campus_start_year].livingExpenses['Health Insurance Premium']) / 12);
      } else if (module6Data.off_campus_start_year < module10Data.marriege_year
        && module6Data.graduation_year >= module10Data.marriege_year
        && module6Data.off_campus_start_year !== ''
        && module6Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module6Data.second_health_insurance_premium !== ''
        && module6Data.second_health_insurance_premium > 0) {
        healthExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium']) / 12);
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module6Data.moreHigherOffCampusPremiumExpenses = healthExpenses;

  return module6Data.moreHigherOffCampusPremiumExpenses;
}

export function moreEducationOffCampusTotalExpenses() {
  module6Data.moreEducationOffCampusTotalExpenses = 0;
  let totalExpenses = 0;
  if (module6Data.moreHigherOffCampusRentExpenses !== undefined && module6Data.moreHigherOffCampusRentExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusRentExpenses;
  }
  if (module6Data.moreHigherOffCampusUtilitiesExpenses !== undefined && module6Data.moreHigherOffCampusUtilitiesExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusUtilitiesExpenses;
  }
  if (module6Data.moreHigherOffCampusFoodExpenses !== undefined && module6Data.moreHigherOffCampusFoodExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusFoodExpenses;
  }
  if (module6Data.moreHigherOffCampusClothingExpenses !== undefined && module6Data.moreHigherOffCampusClothingExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusClothingExpenses;
  }
  if (module6Data.moreHigherOffCampusEntertainmentExpenses !== undefined && module6Data.moreHigherOffCampusEntertainmentExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusEntertainmentExpenses;
  }
  if (module6Data.moreHigherOffCampusTechnologyExpenses !== undefined && module6Data.moreHigherOffCampusTechnologyExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusTechnologyExpenses;
  }
  if (module6Data.moreHigherOffCampusTransportationExpenses !== undefined && module6Data.moreHigherOffCampusTransportationExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusTransportationExpenses;
  }
  if (module6Data.moreHigherOffCampusMiscellaneousExpenses !== undefined && module6Data.moreHigherOffCampusMiscellaneousExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusMiscellaneousExpenses;
  }
  if (module6Data.moreHigherOffCampusOutsidePremiumExpenses !== undefined && module6Data.moreHigherOffCampusOutsidePremiumExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusOutsidePremiumExpenses;
  }
  if (module6Data.moreHigherOffCampusPremiumExpenses !== undefined && module6Data.moreHigherOffCampusPremiumExpenses !== '') {
    totalExpenses += module6Data.moreHigherOffCampusPremiumExpenses;
  }
  module6Data.moreEducationOffCampusTotalExpenses = totalExpenses;
  return module6Data.moreEducationOffCampusTotalExpenses;
}

export function moreHigherMarriageYear() {
  let moreHigherMarriageYearVal = '';
  if (module6Data.start_year !== undefined
    && module6Data.start_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module6Data.start_year >= module10Data.marriege_year) {
    moreHigherMarriageYearVal = module6Data.start_year;
  } else if (module6Data.start_year !== undefined
    && module6Data.start_year !== ''
    && module6Data.graduation_year !== undefined
    && module6Data.graduation_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module6Data.start_year < module10Data.marriege_year
    && module6Data.graduation_year >= module10Data.marriege_year) {
    moreHigherMarriageYearVal = module10Data.marriege_year;
  } else {
    moreHigherMarriageYearVal = '';
  }
  module6Data.moreHigherMarriageYearVal = moreHigherMarriageYearVal;
  return moreHigherMarriageYearVal;
}

export function moreTotalTuitionAndFees() {
  module6Data.moreTotalTuitionAndFees = 0;
  let higherEducationTotalTuitionFees = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year !== ''
    && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    const start_year = parseInt(module6Data.start_year, 10);
    const endYear = parseInt(module6Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined) {
          higherEducationTotalTuitionFees += data[year].livingExpenses['Higher Education Tuition and Fees'];
        }
      });
    }
    module6Data.moreTotalTuitionAndFees = -higherEducationTotalTuitionFees;
  }
  return module6Data.moreTotalTuitionAndFees;
}

export function moreTotalRoomAndBoard() {
  module6Data.moreTotalRoomAndBoard = 0;
  let higherEducationTotalRoomAndBoard = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year !== '' && module6Data.start_year > 0
    && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' && module6Data.graduation_year > 0) {
    const start_year = parseInt(module6Data.start_year, 10);
    const endYear = parseInt(module6Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Room & Board'] !== undefined) {
          higherEducationTotalRoomAndBoard += data[year].livingExpenses['Higher Education Room & Board'];
        }
      });
    }
    module6Data.moreTotalRoomAndBoard = -higherEducationTotalRoomAndBoard;
  }
  return module6Data.moreTotalRoomAndBoard;
}
export function moreHigherOnCampusLivingExpenses() {
  module6Data.moreHigherOnCampusLivingExpenses = 0;
  // Expense calculation B8
  let expenseCalculationb8 = 0;
  let higherTotalLivingExpenses = 0;
  let higherRoomandBoardValue = 0;
  let higherTuitionAndFeesValue = 0;

  if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year === 0 && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationb8 = module6Data.graduation_year;
  } else if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.off_campus_start_year > module6Data.on_campus_start_year) {
    expenseCalculationb8 = parseInt(module6Data.off_campus_start_year, 10) - 1;
  } else if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0 && module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0 && module6Data.on_campus_start_year > module6Data.off_campus_start_year && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationb8 = module6Data.graduation_year;
  } else {
    expenseCalculationb8 = 0;
  }

  if (module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0) {
    const start_year = parseInt(module6Data.on_campus_start_year, 10);
    const endYear = parseInt(expenseCalculationb8, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Total Living Expenses'] !== undefined) {
          higherTotalLivingExpenses += data[year]['Total Living Expenses'];
        }
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Higher Education Room & Board'] !== undefined) {
          higherRoomandBoardValue += data[year].livingExpenses['Higher Education Room & Board'];
        }
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Higher Education Tuition and Fees'] !== undefined) {
          higherTuitionAndFeesValue += data[year].livingExpenses['Higher Education Tuition and Fees'];
        }

      });
    }
  }
  module6Data.moreHigherOnCampusLivingExpenses = -(higherTotalLivingExpenses - higherRoomandBoardValue - higherTuitionAndFeesValue);
  return module6Data.moreHigherOnCampusLivingExpenses;
}

export function moreHigherOffCampusLivingExpenses() {
  module6Data.moreHigherOffCampusLivingExpenses = 0;
  // Expense calculation B8
  let expenseCalculationb24 = 0;
  let higherTotalLivingExpenses = 0;
  let higherRoomandBoardValue = 0;
  let higherTuitionAndFeesValue = 0;
  if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0
    && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year === 0
    && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    expenseCalculationb24 = module6Data.graduation_year;
  } else if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0
    && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0
    && module6Data.on_campus_start_year > module6Data.off_campus_start_year) {
    expenseCalculationb24 = parseInt(module6Data.on_campus_start_year, 10) - 1;
  } else if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0
    && module6Data.on_campus_start_year !== undefined && module6Data.on_campus_start_year > 0
    && module6Data.off_campus_start_year > module6Data.on_campus_start_year && module6Data.graduation_year !== undefined
    && module6Data.graduation_year !== '') {
    expenseCalculationb24 = module6Data.graduation_year;
  } else {
    expenseCalculationb24 = 0;
  }
  if (module6Data.off_campus_start_year !== undefined && module6Data.off_campus_start_year > 0) {
    const start_year = parseInt(module6Data.off_campus_start_year, 10);
    const endYear = parseInt(expenseCalculationb24, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Total Living Expenses'] !== undefined) {
          higherTotalLivingExpenses += data[year]['Total Living Expenses'];
        }
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Higher Education Room & Board'] !== undefined) {
          higherRoomandBoardValue += data[year].livingExpenses['Higher Education Room & Board'];
        }
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Higher Education Tuition and Fees'] !== undefined) {
          higherTuitionAndFeesValue += data[year].livingExpenses['Higher Education Tuition and Fees'];
        }

      });
    }
  }
  module6Data.moreHigherOffCampusLivingExpenses = -(higherTotalLivingExpenses - higherRoomandBoardValue - higherTuitionAndFeesValue);
  return module6Data.moreHigherOffCampusLivingExpenses;
}

export function moreHigherTotalStudentsLoanPayments() {
  let moreHigherTotalStudentsLoanPaymentss = 0;
  if (module6Data.start_year !== undefined
    && parseInt(module6Data.start_year, 10) > 0
    && module6Data.graduation_year !== undefined
    && parseInt(module6Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module6Data.start_year, 10); i <= parseInt(module6Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].InterestExpenses !== undefined && incomeStatementData[i].InterestExpenses !== ''
        && incomeStatementData[i].InterestExpenses['Student Loans'] !== undefined && incomeStatementData[i].InterestExpenses['Student Loans'] !== '') {
        moreHigherTotalStudentsLoanPaymentss += incomeStatementData[i].InterestExpenses['Student Loans'];
      }
    }
  }

  module6Data.moreHigherTotalStudentsLoanPayments = -(moreHigherTotalStudentsLoanPaymentss);
  return module6Data.moreHigherTotalStudentsLoanPayments;
}

export function morehigherHighertTotalCostOfAttendance() {
  module6Data.educationTotalAttendance = module6Data.moreTotalTuitionAndFees
    + module6Data.moreTotalRoomAndBoard
    + module6Data.moreHigherOnCampusLivingExpenses
    + module6Data.moreHigherOffCampusLivingExpenses
    + module6Data.moreHigherTotalStudentsLoanPayments
    + module6Data.moreHigherOtherLoanPaymentsonOtherDebt
    + module6Data.moreHigherCarExpense
    + module6Data.moreHigherRealEstateExpenses
    + module6Data.moreHigherChildrenAndPetExpenses;
  return module6Data.educationTotalAttendance;
}

export function furtherHigherHighertTotalCostOfAttendance() {
  module15Data.educationTotalAttendance = module15Data.newTotalTuitionAndFees
    + module15Data.newTotalRoomAndBoard
    + module15Data.newOnCampusLivingExpenses
    + module15Data.newOffCampusLivingExpenses
    + module15Data.newHigherTotalStudentsLoanPayments
    + module15Data.newHigherOtherLoanPaymentsonOtherDebt
    + module15Data.newHigherCarExpense
    + module15Data.newHigherRealEstateExpenses
    + module15Data.newHigherChildrenAndPetExpenses;
  return module15Data.educationTotalAttendance;
}

export function moreHighertTotalCostOfAttendance() {
  module6Data.moreHighertTotalCostOfAttendance = 0;
  let totalCostOfAttenenace1 = 0;
  let totalCostOfAttenenace2 = 0;
  let totalCostOfAttenenace3 = 0;
  let totalCostOfAttenenace4 = 0;
  let totalCostOfAttenenace5 = 0;

  if (module6Data.moreTotalTuitionAndFees !== undefined) {
    totalCostOfAttenenace1 = module6Data.moreTotalTuitionAndFees;
  }
  if (module6Data.moreTotalRoomAndBoard !== undefined) {
    totalCostOfAttenenace2 = module6Data.moreTotalRoomAndBoard;
  }
  if (module6Data.moreHigherOnCampusLivingExpenses !== undefined) {
    totalCostOfAttenenace3 = module6Data.moreHigherOnCampusLivingExpenses;
  }
  if (module6Data.moreHigherOffCampusLivingExpenses !== undefined) {
    totalCostOfAttenenace4 = module6Data.moreHigherOffCampusLivingExpenses;
  }
  if (module6Data.moreHigherTotalStudentsLoanPayments !== undefined) {
    totalCostOfAttenenace5 = module6Data.moreHigherTotalStudentsLoanPayments;
  }
  module6Data.moreHighertTotalCostOfAttendance = totalCostOfAttenenace1 + totalCostOfAttenenace2 + totalCostOfAttenenace3 + totalCostOfAttenenace4 + totalCostOfAttenenace5;
  return module6Data.moreHighertTotalCostOfAttendance;
}


export function moreHigherOtherLoanPaymentsonOtherDebt() {
  let otherLoanPaymentsonOtherDebts = 0;
  // let collegeStudentLoanPayments = 0;

  if (module6Data.start_year !== undefined && parseInt(module6Data.start_year, 10) > 0 && module6Data.graduation_year !== undefined && parseInt(module6Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module6Data.start_year, 10); i <= parseInt(module6Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Interest Expenses'] !== undefined && incomeStatementData[i]['Total Interest Expenses'] !== '') {
        otherLoanPaymentsonOtherDebts += incomeStatementData[i]['Total Interest Expenses'];
      }

      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].InterestExpenses !== undefined && incomeStatementData[i].InterestExpenses !== ''
        && incomeStatementData[i].InterestExpenses['Student Loans'] !== undefined && incomeStatementData[i].InterestExpenses['Student Loans'] !== '') {
        otherLoanPaymentsonOtherDebts -= incomeStatementData[i].InterestExpenses['Student Loans'];
      }
    }
  }

  module6Data.moreHigherOtherLoanPaymentsonOtherDebt = -(otherLoanPaymentsonOtherDebts);
  return module6Data.moreHigherOtherLoanPaymentsonOtherDebt;
}

export function moreHigherCarExpense() {
  let moreHigherCarExpenses = 0;
  if (module6Data.start_year !== undefined && parseInt(module6Data.start_year, 10) > 0
    && module6Data.graduation_year !== undefined && parseInt(module6Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module6Data.start_year, 10); i <= parseInt(module6Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Car Expenses'] !== undefined && incomeStatementData[i]['Total Car Expenses'] !== '') {
        moreHigherCarExpenses += incomeStatementData[i]['Total Car Expenses'];
      }
    }
  }
  module6Data.moreHigherCarExpense = -(moreHigherCarExpenses);
  return module6Data.moreHigherCarExpense;
}

export function moreHigherRealEstateExpenses() {
  let moreHigherRealEstateExpense = 0;
  if (module6Data.start_year !== undefined && parseInt(module6Data.start_year, 10) > 0 && module6Data.graduation_year !== undefined && parseInt(module6Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module6Data.start_year, 10); i <= parseInt(module6Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Real Estate Expenses'] !== undefined && incomeStatementData[i]['Total Real Estate Expenses'] !== '') {
        moreHigherRealEstateExpense += incomeStatementData[i]['Total Real Estate Expenses'];
      }
    }
  }
  module6Data.moreHigherRealEstateExpenses = -(moreHigherRealEstateExpense);
  return module6Data.moreHigherRealEstateExpenses;
}

export function moreHigherChildrenAndPetExpenses() {
  let moreHigherChildrenAndPetExpense = 0;
  if (module2Data.start_year !== undefined && parseInt(module2Data.start_year, 10) > 0 && module2Data.graduation_year !== undefined && parseInt(module2Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module2Data.start_year, 10); i <= parseInt(module2Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].livingExpenses !== undefined && incomeStatementData[i].livingExpenses !== '') {
        if (incomeStatementData[i].livingExpenses.Children !== undefined && incomeStatementData[i].livingExpenses.Children !== '') {
          moreHigherChildrenAndPetExpense += incomeStatementData[i].livingExpenses.Children;
        }
        if (incomeStatementData[i].livingExpenses.Pet !== undefined && incomeStatementData[i].livingExpenses.Pet !== '') {
          moreHigherChildrenAndPetExpense += incomeStatementData[i].livingExpenses.Pet;
        }
      }
    }
  }
  module6Data.moreHigherChildrenAndPetExpenses = -(moreHigherChildrenAndPetExpense);
  return module6Data.moreHigherChildrenAndPetExpenses;
}

export function moreHigherTotalExpenses() {
  module6Data.moreHigherTotalExpenses = 0;
  let higherTotalExpenses1 = 0;
  let higherTotalExpenses6 = 0;
  let higherTotalExpenses7 = 0;
  let higherTotalExpenses8 = 0;
  let higherTotalExpenses9 = 0;

  if (module6Data.moreHighertTotalCostOfAttendance !== undefined) {
    higherTotalExpenses1 = module6Data.moreHighertTotalCostOfAttendance;
  }
  if (module6Data.moreHigherOtherLoanPaymentsonOtherDebt !== undefined) {
    higherTotalExpenses6 = module6Data.moreHigherOtherLoanPaymentsonOtherDebt;
  }
  if (module6Data.moreHigherCarExpense !== undefined) {
    higherTotalExpenses7 = module6Data.moreHigherCarExpense;
  }
  if (module6Data.moreHigherRealEstateExpenses !== undefined) {
    higherTotalExpenses8 = module6Data.moreHigherRealEstateExpenses;
  }
  if (module6Data.moreHigherChildrenAndPetExpenses !== undefined) {
    higherTotalExpenses9 = module6Data.moreHigherChildrenAndPetExpenses;
  }
  module6Data.moreHigherTotalExpenses = -(higherTotalExpenses1 + higherTotalExpenses6 + higherTotalExpenses7 + higherTotalExpenses8 + higherTotalExpenses9);
  return module6Data.moreHigherTotalExpenses;
}

export function moreCashLeftover() {
  module6Data.cashLeftover = 0;
  module6Data.cashLeftover = moreHigherinvestmentAnalysisTotalCashAvailable();
  module6Data.cashLeftover -= (moreHigherTotalExpenses());
  return module6Data.cashLeftover;
}

// For conversation changes
export function moreHigherTotalStudentLoanBalance() {
  module6Data.moreHigherTotalStudentLoanBalance = 0;

  if (module6Data.graduation_year !== undefined && module6Data.graduation_year !== ''
    && module6Data.start_year !== undefined && module6Data.start_year !== ''
    && balanceSheetData !== undefined && balanceSheetData !== ''
    && balanceSheetData[module6Data.graduation_year] !== undefined && balanceSheetData[module6Data.graduation_year] !== ''
    && balanceSheetData[module6Data.graduation_year].Liabilities !== undefined && balanceSheetData[module6Data.graduation_year].Liabilities !== ''
    && balanceSheetData[module6Data.graduation_year].Liabilities['Student Loans'] !== undefined && balanceSheetData[module6Data.graduation_year].Liabilities['Student Loans'] !== '') {
    module6Data.moreHigherTotalStudentLoanBalance = balanceSheetData[module6Data.graduation_year].Liabilities['Student Loans'];
  }
  return module6Data.moreHigherTotalStudentLoanBalance;
}

export function moreHigherTotalMonthlyPayment() {
  module6Data.moreHigherTotalMonthlyPayment = 0;
  let privatePaymentBal = 0;
  let UnsubPaymentBal = 0;
  let subPaymentBal = 0;

  let totalPaymentBals = 0;
  let value1 = 0;
  let value2 = 0;
  let value3 = 0;
  let value4 = 0;
  let value5 = 0;
  let value6 = 0;
  let valueTotal = 0;
  let finalTotal = 0;

  if (module6Data.morePrivateMonthlyPayment !== undefined) {
    privatePaymentBal = module6Data.morePrivateMonthlyPayment;
  }
  if (module6Data.moreFedUnSubMonthlyPayment !== undefined) {
    UnsubPaymentBal = module6Data.moreFedUnSubMonthlyPayment;
  }
  if (module6Data.moreFedSubMonthlyPayment !== undefined) {
    subPaymentBal = module6Data.moreFedSubMonthlyPayment;
  }
  totalPaymentBals = privatePaymentBal + UnsubPaymentBal + subPaymentBal;

  if (module2Data.graduation_year !== undefined && module2Data.graduation_year !== '') {
    if (fedSubExistingLoanData['Summation Data'] !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && fedSubExistingLoanData['Original Payment'] !== undefined) {
      value1 = fedSubExistingLoanData['Original Payment'];
    } else {
      value1 = 0;
    }

    if (fedUnSubExistingLoanData['Summation Data'] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && fedUnSubExistingLoanData['Original Payment'] !== undefined) {
      value2 = fedUnSubExistingLoanData['Original Payment'];
    } else {
      value2 = 0;
    }

    if (privateExistingLoanData['Summation Data'] !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && privateExistingLoanData['Original Payment'] !== undefined) {
      value3 = privateExistingLoanData['Original Payment'];
    } else {
      value3 = 0;
    }

    if (fedSubHigherEdLoanData['Summation Data'] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedSubHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && fedSubHigherEdLoanData['Original Payment'] !== undefined) {
      value4 = fedSubHigherEdLoanData['Original Payment'];
    } else {
      value4 = 0;
    }

    if (fedUnSubHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && fedUnSubHigherEdLoanData['Original Payment'] !== undefined) {
      value5 = fedUnSubHigherEdLoanData['Original Payment'];
    } else {
      value5 = 0;
    }

    if (privateHigherEdLoanData['Summation Data'] !== undefined
      && privateHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1] !== undefined
      && privateHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && privateHigherEdLoanData['Summation Data'][parseInt(module2Data.graduation_year, 10) + 1].BeginningBalance !== 0
      && privateHigherEdLoanData['Original Payment'] !== undefined) {
      value6 = privateHigherEdLoanData['Original Payment'];
    } else {
      value6 = 0;
    }
    valueTotal = value1 + value2 + value3 + value4 + value5 + value6;
  }

  if (module6Data.graduation_year !== undefined && module6Data.graduation_year !== ''
    && module6Data.start_year !== undefined && module6Data.start_year !== '') {
    finalTotal = totalPaymentBals + valueTotal;
  } else {
    finalTotal = 0;
  }

  module6Data.moreHigherTotalMonthlyPayment = finalTotal;
  return module6Data.moreHigherTotalMonthlyPayment;
}

export function moreHigherTotalAnnualPaymentPaid() {
  module6Data.moreHigherTotalAnnualPaymentPaid = 0;
  if (module6Data.graduation_year !== undefined && module6Data.graduation_year !== ''
    && module6Data.start_year !== undefined && module6Data.start_year !== ''
    && module6Data.moreHigherTotalMonthlyPayment !== undefined && module6Data.moreHigherTotalMonthlyPayment !== '') {
    module6Data.moreHigherTotalAnnualPaymentPaid = module6Data.moreHigherTotalMonthlyPayment * 12;
  }
  return module6Data.moreHigherTotalAnnualPaymentPaid;
}
export function moreHigherIncomeNeedToSupportLoans() {
  module6Data.moreHigherIncomeNeedToSupportLoans = 0;
  if (module6Data.graduation_year !== undefined && module6Data.graduation_year !== ''
    && module6Data.start_year !== undefined && module6Data.start_year !== ''
    && module6Data.moreHigherTotalAnnualPaymentPaid !== undefined && module6Data.moreHigherTotalAnnualPaymentPaid !== '') {
    module6Data.moreHigherIncomeNeedToSupportLoans = (module6Data.moreHigherTotalAnnualPaymentPaid * 100) / 12.5;
  }
  return module6Data.moreHigherIncomeNeedToSupportLoans;
}
export function moreTotalBeforeTaxIncome() {
  module6Data.moreTotalBeforeTaxIncome = 0;
  let totalBeforeTaxIncomeValue = 0;
  if (module6Data.start_year !== undefined && module6Data.start_year !== ''
    && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '') {
    const start_year = parseInt(module6Data.start_year, 10);
    const endYear = parseInt(module6Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year]['Gross Income'] !== undefined && data[year]['Gross Income']['Income Earned during Higher Education'] !== undefined) {
          totalBeforeTaxIncomeValue += data[year]['Gross Income']['Income Earned during Higher Education'];
        }
      });
    }
  }
  module6Data.moreTotalBeforeTaxIncome = totalBeforeTaxIncomeValue;
  return module6Data.moreTotalBeforeTaxIncome;
}

export function moreEstimatedTaxRateConversation() {
  module6Data.moreEstimatedTaxRateConversation = 0;
  if (module6Data.moreTotalBeforeTaxIncome !== undefined && module6Data.moreTotalBeforeTaxIncome !== '' && module6Data.moreTotalBeforeTaxIncome !== 0 && module6Data.moreHigherinvestmentAnalysisIncomeEarned !== undefined && module6Data.moreHigherinvestmentAnalysisIncomeEarned !== '') {
    module6Data.moreEstimatedTaxRateConversation = (1 - (module6Data.moreHigherinvestmentAnalysisIncomeEarned / module6Data.moreTotalBeforeTaxIncome)) * 100;
  }
  return module6Data.moreEstimatedTaxRateConversation;
}

// Room and Board
export function newFetchRoomBoard() {
  module15Data.fetchRoomBoard = 0;
  if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year !== '' && parseInt(module15Data.on_campus_start_year, 10) > 0
    && module15Data.room_board !== undefined && module15Data.room_board !== '') {
    module15Data.fetchRoomBoard = parseFloat(module15Data.room_board / 12);
  }
  return module15Data.fetchRoomBoard;
}
// Student Loan payment Value
// export function newstudentLoanPayment() {
//     module16Data.studentLoanPaymentVal = 0;
//     let incomeStatementTotalDepreciationExpensesData = incomeStatementData;
//     if (incomeStatementTotalDepreciationExpensesData[module15Data.on_campus_start_year] !== undefined &&
//         incomeStatementTotalDepreciationExpensesData[module15Data.on_campus_start_year] !== '') {
//         module16Data.studentLoanPaymentVal = - (incomeStatementTotalDepreciationExpensesData[module15Data.on_campus_start_year]['InterestExpenses']['Student Loans']) / 12 - (cashFlowData[module15Data.on_campus_start_year]['Cash from Financing Activities']['Student Loans Principal Payback']) / 12;
//     }
//     return module16Data.studentLoanPaymentVal;
// }
// Total Expenses Value
export function newlivingExpensestotal() {
  module15Data.livingExpensesTotalExpenses = 0;
  if (module15Data.fetchRoomBoard !== undefined && module15Data.fetchRoomBoard >= 0
    && module15Data.off_campus_food !== undefined && module15Data.off_campus_food >= 0
    && module15Data.clothing !== undefined && module15Data.clothing >= 0
    && module15Data.entertainment !== undefined && module15Data.entertainment >= 0
    && module15Data.technology !== undefined && module15Data.technology >= 0
    && module15Data.transportation !== undefined && module15Data.transportation >= 0
    && module15Data.miscellaneous !== undefined && module15Data.miscellaneous >= 0
    && module15Data.medical_costs_before_deductible !== undefined && module15Data.medical_costs_before_deductible >= 0
    && module15Data.health_insurance_premium !== undefined && module15Data.health_insurance_premium >= 0) {
    module15Data.livingExpensesTotalExpenses = module15Data.fetchRoomBoard
      + module15Data.off_campus_food
      + module15Data.clothing
      + module15Data.entertainment
      + module15Data.technology
      + module15Data.transportation
      + module15Data.miscellaneous
      + module15Data.medical_costs_before_deductible
      + module15Data.health_insurance_premium;
  }
  return module15Data.livingExpensesTotalExpenses;
}
// Years
export function newOffCampusMonthlyExpensesYears() {
  module15Data.offCampusYearsDiff = 0;
  let expenseCalculationb141 = 0;
  if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb141 = module15Data.graduation_year;
  } else if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.on_campus_start_year > module15Data.off_campus_start_year) {
    expenseCalculationb141 = parseInt(module15Data.on_campus_start_year, 10) - 1;
  } else if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year > module15Data.on_campus_start_year && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb141 = module15Data.graduation_year;
  } else {
    expenseCalculationb141 = 0;
  }
  if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year !== '') {
    module15Data.offCampusYearsDiff = expenseCalculationb141 - module15Data.off_campus_start_year + 1;
  }
  return module15Data.offCampusYearsDiff;
}
// Student Loan payment Value
export function newlivingExpensesStudentLoanPayment() {
  module15Data.morelivingExpensesStudentLoanPaymentVal = 0;
  const incomeStatementTotalDepreciationExpensesData = incomeStatementData;
  if (incomeStatementTotalDepreciationExpensesData[module15Data.off_campus_start_year] !== undefined
    && incomeStatementTotalDepreciationExpensesData[module15Data.off_campus_start_year] !== '') {
    module15Data.morelivingExpensesStudentLoanPaymentVal = -(incomeStatementTotalDepreciationExpensesData[module15Data.off_campus_start_year].InterestExpenses['Student Loans']) / 12 - (cashFlowData[module15Data.off_campus_start_year]['Cash from Financing Activities']['Student Loans Principal Payback']) / 12;
  }
  return module15Data.morelivingExpensesStudentLoanPaymentVal;
}
// Total Expenses Value
export function newmorelivingExpensestotal() {
  module15Data.morelivingExpensestotal = 0;
  if (module15Data.rent !== undefined && module15Data.rent >= 0
    && module15Data.utilities !== undefined && module15Data.utilities >= 0
    && module15Data.food !== undefined && module15Data.food >= 0
    && module15Data.second_clothing !== undefined && module15Data.second_clothing >= 0
    && module15Data.second_entertainment !== undefined && module15Data.second_entertainment >= 0
    && module15Data.second_technology !== undefined && module15Data.second_technology >= 0
    && module15Data.second_transportation !== undefined && module15Data.second_transportation >= 0
    && module15Data.second_miscellaneous !== undefined && module15Data.second_miscellaneous >= 0
    && module15Data.second_medical_costs_before_deductible !== undefined && module15Data.second_medical_costs_before_deductible >= 0
    && module15Data.second_health_insurance_premium !== undefined && module15Data.second_health_insurance_premium >= 0) {
    module15Data.morelivingExpensestotal = module15Data.rent
      + module15Data.utilities
      + module15Data.food
      + module15Data.second_clothing
      + module15Data.second_entertainment
      + module15Data.second_technology
      + module15Data.second_transportation
      + module15Data.second_miscellaneous
      + module15Data.second_medical_costs_before_deductible
      + module15Data.second_health_insurance_premium;
  }

  return module15Data.morelivingExpensestotal;
}
// Monthly Price off Campus
export function newmonthlyPriceOffCampus() {
  module15Data.moremonthlyPriceOffCampus = 0;
  if (module15Data.livingExpensesTotalExpenses !== undefined && module15Data.livingExpensesTotalExpenses !== '' && module15Data.livingExpensesTotalExpenses > 0
    && module15Data.morelivingExpensestotal !== undefined && module15Data.morelivingExpensestotal !== '') {
    module15Data.moremonthlyPriceOffCampus = module15Data.morelivingExpensestotal - module15Data.livingExpensesTotalExpenses;
  }
  return module15Data.moremonthlyPriceOffCampus;
}
// Monthly Percentage off Campus
export function newmonthlyPercentageOffCampus() {
  module15Data.moremonthlyPercentageOffCampus = 0;
  if (module15Data.livingExpensesTotalExpenses !== undefined && module15Data.livingExpensesTotalExpenses !== '' && module15Data.livingExpensesTotalExpenses > 0
    && module15Data.morelivingExpensestotal !== undefined && module15Data.morelivingExpensestotal !== '') {
    module15Data.moremonthlyPercentageOffCampus = ((module15Data.morelivingExpensestotal - module15Data.livingExpensesTotalExpenses) / module15Data.livingExpensesTotalExpenses) * 100;
  }

  return module15Data.moremonthlyPercentageOffCampus;
}
// Student Loan payment Value
export function newlivingExpensesCashExcess() {
  module15Data.livingExpensesCashExcessVal = 0;
  if (module15Data.newHigherinvestmentAnalysisCashExcess !== undefined
    && module15Data.newHigherinvestmentAnalysisCashExcess !== '') {
    module15Data.livingExpensesCashExcessVal = module15Data.newHigherinvestmentAnalysisCashExcess;
  }
  return module15Data.livingExpensesCashExcessVal;
}
// Total Living Expenses Value
export function newHigherlivingExpensesTotal() {
  module15Data.newHigherlivingExpensesTotal = 0;
  const start_year = parseInt(module15Data.start_year, 10);
  const endYear = parseInt(module15Data.graduation_year, 10);
  const data = incomeStatementData;
  let Val1 = 0;
  let Val2 = 0;
  if (data !== undefined && data !== '') {
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Total Living Expenses'] !== undefined && data[year].InterestExpenses !== undefined && data[year].InterestExpenses['Student Loans'] !== undefined) {
          if (data[year] !== undefined) {
            Val1 += data[year]['Total Living Expenses'];
            Val2 += data[year].InterestExpenses['Student Loans'];
          }
        }
      });
    }
  } else {
    Val1 = 0;
    Val2 = 0;
  }
  const cashFlowdata = cashFlowData;
  let Val3 = 0;
  if (cashFlowdata !== undefined && cashFlowdata !== '') {
    if (Object.keys(cashFlowdata).length !== 0) {
      Object.keys(cashFlowdata).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (cashFlowdata[year] !== undefined
            && cashFlowdata[year]['Cash from Financing Activities'] !== undefined) {
            Val3 += cashFlowdata[year]['Cash from Financing Activities']['Student Loans Principal Payback'];
          }
        }
      });
    }
  } else {
    Val3 = 0;
  }
  module15Data.newHigherlivingExpensesTotal = Val1 + Val2 - module15Data.newHigherinvestmentAnalysisTuitionCosts + Val3;
  return module15Data.newHigherlivingExpensesTotal;
}

// Cash Excess (Deficit) AFTER Living Expenses
export function newHigherCashExcess() {
  module15Data.newHigherCashExcessVal = 0;
  if (module15Data.livingExpensesCashExcessVal !== undefined && module15Data.newHigherlivingExpensesTotal !== undefined) {
    module15Data.newHigherCashExcessVal = module15Data.livingExpensesCashExcessVal + module15Data.newHigherlivingExpensesTotal;
  }
  return module15Data.newHigherCashExcessVal;
}

export function furtherTotalBeforeTaxIncome() {
  module15Data.furtherTotalBeforeTaxIncome = 0;
  let totalBeforeTaxIncomeValue = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    const start_year = parseInt(module15Data.start_year, 10);
    const endYear = parseInt(module15Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year]['Gross Income'] !== undefined && data[year]['Gross Income']['Income Earned during Higher Education'] !== undefined) {
          totalBeforeTaxIncomeValue += data[year]['Gross Income']['Income Earned during Higher Education'];
        }
      });
    }
  }
  module15Data.furtherTotalBeforeTaxIncome = totalBeforeTaxIncomeValue;
  return module15Data.furtherTotalBeforeTaxIncome;
}

export function newTotalTuitionAndFees() {
  module15Data.newTotalTuitionAndFees = 0;
  let higherEducationTotalTuitionFees = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    const start_year = parseInt(module15Data.start_year, 10);
    const endYear = parseInt(module15Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined) {
          higherEducationTotalTuitionFees += data[year].livingExpenses['Higher Education Tuition and Fees'];
        }
      });
    }
    module15Data.newTotalTuitionAndFees = -higherEducationTotalTuitionFees;
  }
  return module15Data.newTotalTuitionAndFees;
}
export function newTotalRoomAndBoard() {
  module15Data.newTotalRoomAndBoard = 0;
  let higherEducationTotalRoomAndBoard = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    const start_year = parseInt(module15Data.start_year, 10);
    const endYear = parseInt(module15Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Room & Board'] !== undefined) {
          higherEducationTotalRoomAndBoard += data[year].livingExpenses['Higher Education Room & Board'];
        }
      });
    }
    module15Data.newTotalRoomAndBoard = -higherEducationTotalRoomAndBoard;
  }
  return module15Data.newTotalRoomAndBoard;
}

export function newOnCampusLivingExpenses() {
  module15Data.newOnCampusLivingExpenses = 0;
  // Expense calculation B8
  let expenseCalculationb8 = 0;
  let higherTotalLivingExpenses = 0;
  let higherRoomandBoardValue = 0;
  let higherTuitionAndFeesValue = 0;

  if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb8 = module15Data.graduation_year;
  } else if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.off_campus_start_year > module15Data.on_campus_start_year) {
    expenseCalculationb8 = parseInt(module15Data.off_campus_start_year, 10) - 1;
  } else if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year > module15Data.off_campus_start_year && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb8 = module15Data.graduation_year;
  } else {
    expenseCalculationb8 = 0;
  }
  if (module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0) {
    const start_year = parseInt(module15Data.on_campus_start_year, 10);
    const endYear = parseInt(expenseCalculationb8, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Total Living Expenses'] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Room & Board'] !== undefined && data[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined) {
          higherTotalLivingExpenses += data[year]['Total Living Expenses'];
          higherRoomandBoardValue += data[year].livingExpenses['Higher Education Room & Board'];
          higherTuitionAndFeesValue += data[year].livingExpenses['Higher Education Tuition and Fees'];
        }
      });
    }
  }
  module15Data.newOnCampusLivingExpenses = -(higherTotalLivingExpenses - higherRoomandBoardValue - higherTuitionAndFeesValue);
  return module15Data.newOnCampusLivingExpenses;
}

export function newOffCampusLivingExpenses() {
  module15Data.newOffCampusLivingExpenses = 0;
  // Expense calculation B8
  let expenseCalculationb24 = 0;
  let higherTotalLivingExpenses = 0;
  let higherRoomandBoardValue = 0;
  let higherTuitionAndFeesValue = 0;

  if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year === 0 && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb24 = module15Data.graduation_year;
  } else if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.on_campus_start_year > module15Data.off_campus_start_year) {
    expenseCalculationb24 = parseInt(module15Data.on_campus_start_year, 10) - 1;
  } else if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0 && module15Data.on_campus_start_year !== undefined && module15Data.on_campus_start_year > 0 && module15Data.off_campus_start_year > module15Data.on_campus_start_year && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    expenseCalculationb24 = module15Data.graduation_year;
  } else {
    expenseCalculationb24 = 0;
  }
  if (module15Data.off_campus_start_year !== undefined && module15Data.off_campus_start_year > 0) {
    const start_year = parseInt(module15Data.off_campus_start_year, 10);
    const endYear = parseInt(expenseCalculationb24, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Total Living Expenses'] !== undefined && data[year].livingExpenses !== undefined && data[year].livingExpenses['Higher Education Room & Board'] !== undefined && data[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined) {
          higherTotalLivingExpenses += data[year]['Total Living Expenses'];
          higherRoomandBoardValue += data[year].livingExpenses['Higher Education Room & Board'];
          higherTuitionAndFeesValue += data[year].livingExpenses['Higher Education Tuition and Fees'];
        }
      });
    }
  }
  module15Data.newOffCampusLivingExpenses = -(higherTotalLivingExpenses - higherRoomandBoardValue - higherTuitionAndFeesValue);
  return module15Data.newOffCampusLivingExpenses;
}

export function newHigherTotalStudentsLoanPayments() {
  let newHigherTotalStudentsLoanPayment = 0;
  if (module15Data.start_year !== undefined && parseInt(module15Data.start_year, 10) > 0 && module15Data.graduation_year !== undefined
    && parseInt(module15Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module15Data.start_year, 10); i <= parseInt(module15Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].InterestExpenses !== undefined && incomeStatementData[i].InterestExpenses !== ''
        && incomeStatementData[i].InterestExpenses['Student Loans'] !== undefined && incomeStatementData[i].InterestExpenses['Student Loans'] !== '') {
        newHigherTotalStudentsLoanPayment += incomeStatementData[i].InterestExpenses['Student Loans'];
      }
    }
  }
  module15Data.newHigherTotalStudentsLoanPayments = -(newHigherTotalStudentsLoanPayment);
  return module15Data.newHigherTotalStudentsLoanPayments;
}

export function newTotalCostOfAttendance() {
  module15Data.totalCostOfAttendance = 0;
  let totalCostOfAttenenace1 = 0;
  let totalCostOfAttenenace2 = 0;
  let totalCostOfAttenenace3 = 0;
  let totalCostOfAttenenace4 = 0;
  let totalCostOfAttenenace5 = 0;

  if (module15Data.newTotalTuitionAndFees !== undefined) {
    totalCostOfAttenenace1 = module15Data.newTotalTuitionAndFees;
  }
  if (module15Data.newTotalRoomAndBoard !== undefined) {
    totalCostOfAttenenace2 = module15Data.newTotalRoomAndBoard;
  }
  if (module15Data.newOnCampusLivingExpenses !== undefined) {
    totalCostOfAttenenace3 = module15Data.newOnCampusLivingExpenses;
  }
  if (module15Data.newOffCampusLivingExpenses !== undefined) {
    totalCostOfAttenenace4 = module15Data.newOffCampusLivingExpenses;
  }
  if (module15Data.newHigherTotalStudentsLoanPayments !== undefined) {
    totalCostOfAttenenace5 = module15Data.newHigherTotalStudentsLoanPayments;
  }
  module15Data.totalCostOfAttendance = totalCostOfAttenenace1 + totalCostOfAttenenace2 + totalCostOfAttenenace3 + totalCostOfAttenenace4 + totalCostOfAttenenace5;
  return module15Data.totalCostOfAttendance;
}

export function newHigherOtherLoanPaymentsonOtherDebt() {
  let otherLoanPaymentsonOtherDebts = 0;
  // let collegeStudentLoanPayments = 0;

  if (module15Data.start_year !== undefined && parseInt(module15Data.start_year, 10) > 0 && module15Data.graduation_year !== undefined && parseInt(module15Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module15Data.start_year, 10); i <= parseInt(module15Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Interest Expenses'] !== undefined && incomeStatementData[i]['Total Interest Expenses'] !== '') {
        otherLoanPaymentsonOtherDebts += incomeStatementData[i]['Total Interest Expenses'];
      }

      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].InterestExpenses !== undefined && incomeStatementData[i].InterestExpenses !== ''
        && incomeStatementData[i].InterestExpenses['Student Loans'] !== undefined && incomeStatementData[i].InterestExpenses['Student Loans'] !== '') {
        otherLoanPaymentsonOtherDebts -= incomeStatementData[i].InterestExpenses['Student Loans'];
      }
    }
  }

  module15Data.newHigherOtherLoanPaymentsonOtherDebt = -(otherLoanPaymentsonOtherDebts);
  return module15Data.newHigherOtherLoanPaymentsonOtherDebt;
}

export function newHigherCarExpense() {
  let newHigherCarExpenses = 0;
  if (module15Data.start_year !== undefined && parseInt(module15Data.start_year, 10) > 0
    && module15Data.graduation_year !== undefined && parseInt(module15Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module15Data.start_year, 10); i <= parseInt(module15Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Car Expenses'] !== undefined && incomeStatementData[i]['Total Car Expenses'] !== '') {
        newHigherCarExpenses += incomeStatementData[i]['Total Car Expenses'];
      }
    }
  }
  module15Data.newHigherCarExpense = -(newHigherCarExpenses);
  return module15Data.newHigherCarExpense;
}

export function newHigherRealEstateExpenses() {
  let newHigherRealEstateExpense = 0;
  if (module15Data.start_year !== undefined && parseInt(module15Data.start_year, 10) > 0
    && module15Data.graduation_year !== undefined && parseInt(module15Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module15Data.start_year, 10); i <= parseInt(module15Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i]['Total Real Estate Expensess'] !== undefined && incomeStatementData[i]['Total Real Estate Expensess'] !== '') {
        newHigherRealEstateExpense += incomeStatementData[i]['Total Real Estate Expensess'];
      }
    }
  }
  module15Data.newHigherRealEstateExpenses = -(newHigherRealEstateExpense);
  return module15Data.newHigherRealEstateExpenses;
}

export function newHigherChildrenAndPetExpenses() {
  let newHigherChildrenAndPetExpense = 0;
  if (module15Data.start_year !== undefined && parseInt(module15Data.start_year, 10) > 0
    && module15Data.graduation_year !== undefined && parseInt(module15Data.graduation_year, 10) > 0) {
    for (let i = parseInt(module15Data.start_year, 10); i <= parseInt(module15Data.graduation_year, 10); i += 1) {
      if (incomeStatementData !== undefined && incomeStatementData !== ''
        && incomeStatementData[i] !== undefined && incomeStatementData[i] !== ''
        && incomeStatementData[i].livingExpenses !== undefined && incomeStatementData[i].livingExpenses !== '') {
        if (incomeStatementData[i].livingExpenses.Children !== undefined && incomeStatementData[i].livingExpenses.Children !== '') {
          newHigherChildrenAndPetExpense += incomeStatementData[i].livingExpenses.Children;
        }
        if (incomeStatementData[i].livingExpenses.Pet !== undefined && incomeStatementData[i].livingExpenses.Pet !== '') {
          newHigherChildrenAndPetExpense += incomeStatementData[i].livingExpenses.Pet;
        }
      }
    }
  }
  module15Data.newHigherChildrenAndPetExpenses = -(newHigherChildrenAndPetExpense);
  return module15Data.newHigherChildrenAndPetExpenses;
}

export function newHigherTotalExpenses() {
  module15Data.newHigherTotalExpenses = 0;
  let higherTotalExpenses1 = 0;
  let higherTotalExpenses6 = 0;
  let higherTotalExpenses7 = 0;
  let higherTotalExpenses8 = 0;
  let higherTotalExpenses9 = 0;

  if (module15Data.totalCostOfAttendance !== undefined) {
    higherTotalExpenses1 = module15Data.totalCostOfAttendance;
  }

  if (module15Data.newHigherOtherLoanPaymentsonOtherDebt !== undefined) {
    higherTotalExpenses6 = module15Data.newHigherOtherLoanPaymentsonOtherDebt;
  }
  if (module15Data.newHigherCarExpense !== undefined) {
    higherTotalExpenses7 = module15Data.newHigherCarExpense;
  }
  if (module15Data.newHigherRealEstateExpenses !== undefined) {
    higherTotalExpenses8 = module15Data.newHigherRealEstateExpenses;
  }
  if (module15Data.newHigherChildrenAndPetExpenses !== undefined) {
    higherTotalExpenses9 = module15Data.newHigherChildrenAndPetExpenses;
  }
  module15Data.newHigherTotalExpenses = higherTotalExpenses1 + higherTotalExpenses6 + higherTotalExpenses7 + higherTotalExpenses8 + higherTotalExpenses9;
  return module15Data.newHigherTotalExpenses;
}

export function newHigherTotalStudentLoanBalance() {
  module15Data.newHigherTotalStudentLoanBalance = 0;

  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== ''
    && balanceSheetData !== undefined && balanceSheetData !== ''
    && balanceSheetData[module15Data.graduation_year] !== undefined && balanceSheetData[module15Data.graduation_year] !== ''
    && balanceSheetData[module15Data.graduation_year].Liabilities !== undefined && balanceSheetData[module15Data.graduation_year].Liabilities !== ''
    && balanceSheetData[module15Data.graduation_year].Liabilities['Student Loans'] !== undefined && balanceSheetData[module15Data.graduation_year].Liabilities['Student Loans'] !== '') {
    module15Data.newHigherTotalStudentLoanBalance = balanceSheetData[module15Data.graduation_year].Liabilities['Student Loans'];
  }
  return module15Data.newHigherTotalStudentLoanBalance;
}
export function newHigherTotalMonthlyPayment() {
  module15Data.newHigherTotalMonthlyPayment = 0;
  let privatePaymentBal = 0;
  let UnsubPaymentBal = 0;
  let subPaymentBal = 0;

  // DZ 14 DZ 31 DZ 48
  // let existingUnsubPaymentBal = 0;
  // let existingsubPaymentBal = 0;
  // let existingprivatePaymentBal = 0;
  let totalPaymentBals = 0;
  let value1 = 0;
  let value2 = 0;
  let value3 = 0;
  let value4 = 0;
  let value5 = 0;
  let value6 = 0;
  let valueTotal = 0;
  let finalValue = 0;

  if (module6Data.morePrivateMonthlyPayment !== undefined) {
    privatePaymentBal = module15Data.newPrivateMonthlyPayment;
  }
  if (module6Data.moreFedUnSubMonthlyPayment !== undefined) {
    UnsubPaymentBal = module15Data.newFedUnSubMonthlyPayment;
  }
  if (module6Data.moreFedSubMonthlyPayment !== undefined) {
    subPaymentBal = module15Data.newFedSubMonthlyPayment;
  }
  totalPaymentBals = privatePaymentBal + UnsubPaymentBal + subPaymentBal;

  if (module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && (parseInt(module15Data.graduation_year, 10) + 1) > (parseInt(module1Data.start_year, 10) + 14)) {
    valueTotal = 0;
  } else if (module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    if (fedSubExistingLoanData['Summation Data'] !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1] !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedSubExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance > 0
      && fedSubExistingLoanData['Original Payment'] !== undefined) {
      value1 = fedSubExistingLoanData['Original Payment'];
    } else {
      value1 = 0;
    }

    if (fedUnSubExistingLoanData['Summation Data'] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1] !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedUnSubExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance > 0
      && fedUnSubExistingLoanData['Original Payment'] !== undefined) {
      value2 = fedUnSubExistingLoanData['Original Payment'];
    } else {
      value2 = 0;
    }

    if (privateExistingLoanData['Summation Data'] !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1] !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && privateExistingLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance > 0
      && privateExistingLoanData['Original Payment'] !== undefined) {
      value3 = privateExistingLoanData['Original Payment'];
    } else {
      value3 = 0;
    }

    if (fedSubHigherEdLoanData['Summation Data'] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedSubHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance > 0
      && fedSubHigherEdLoanData['Original Payment'] !== undefined) {
      value4 = fedSubHigherEdLoanData['Original Payment'];
    } else {
      value4 = 0;
    }

    if (fedUnSubHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance > 0
      && fedUnSubHigherEdLoanData['Original Payment'] !== undefined) {
      value5 = fedUnSubHigherEdLoanData['Original Payment'];
    } else {
      value5 = 0;
    }

    if (privateHigherEdLoanData['Summation Data'] !== undefined
      && privateHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1] !== undefined
      && privateHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance !== undefined
      && privateHigherEdLoanData['Summation Data'][parseInt(module15Data.graduation_year, 10) + 1].BeginningBalance > 0
      && privateHigherEdLoanData['Original Payment'] !== undefined) {
      value6 = privateHigherEdLoanData['Original Payment'];
    } else {
      value6 = 0;
    }
    valueTotal = value1 + value2 + value3 + value4 + value5 + value6;
  } else {
    valueTotal = 0;
  }

  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    finalValue = totalPaymentBals + valueTotal;
  } else {
    finalValue = 0;
  }
  module15Data.newHigherTotalMonthlyPayment = finalValue;
  return module15Data.newHigherTotalMonthlyPayment;
}

export function newHigherTotalAnnualPaymentPaid() {
  module15Data.newHigherTotalAnnualPaymentPaid = 0;

  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== ''
    && module15Data.newHigherTotalMonthlyPayment !== undefined && module15Data.newHigherTotalMonthlyPayment !== '') {
    module15Data.newHigherTotalAnnualPaymentPaid = module15Data.newHigherTotalMonthlyPayment * 12;
  }
  return module15Data.newHigherTotalAnnualPaymentPaid;
}

export function newHigherIncomeNeedToSupportLoans() {
  module15Data.newHigherIncomeNeedToSupportLoans = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== ''
    && module15Data.newHigherTotalAnnualPaymentPaid !== undefined && module15Data.newHigherTotalAnnualPaymentPaid !== '') {
    module15Data.newHigherIncomeNeedToSupportLoans = (module15Data.newHigherTotalAnnualPaymentPaid * 100) / 12.5;
  }
  return module15Data.newHigherIncomeNeedToSupportLoans;
}

// Net Gain
export function moreREINetGain() {
  module14Data.moreREINetGain = 0;
  if (module14Data.moreREIGain !== undefined && module14Data.moreREIGain !== ''
    && module14Data.moreREITaxCapital !== undefined && module14Data.moreREITaxCapital !== ''
    && module14Data.moreREITaxAccumulated !== undefined && module14Data.moreREITaxAccumulated !== '') {
    module14Data.moreREINetGain = parseFloat(module14Data.moreREIGain) - parseFloat(module14Data.moreREITaxCapital) - parseFloat(module14Data.moreREITaxAccumulated);
  }
  return module14Data.moreREINetGain;
}
/** ******************** Module 15 *********************** */
// Room & Board Value
export function newRoomBoardVal() {
  module15Data.room_board = '0';
  if (module15Data.name_of_college !== undefined
    && module15Data.name_of_college.room_board !== undefined) {
    module15Data.room_board = module15Data.name_of_college.room_board.toString();
  }
  return module15Data.room_board;
}
// Custom Room Board Value
export function newCustomRoomBoardVal() {
  module15Data.room_board = '0';
  if (module15Data.newCustomRoomBoardValue !== undefined) {
    module15Data.room_board = module15Data.newCustomRoomBoardValue;
  }
  return module15Data.room_board;
}
// Tuition Value
export function newTuitionVal() {
  module15Data.tuition = '0';
  if (module15Data.name_of_college !== undefined
    && module15Data.name_of_college.tuition !== undefined) {
    module15Data.tuition = module15Data.name_of_college.tuition.toString();
  }
  return module15Data.tuition;
}
// Custom Tuition Value
export function newCustomTuitionVal() {
  module15Data.tuition = '0';
  if (module15Data.newCustomTuitionValue !== undefined) {
    module15Data.tuition = module15Data.newCustomTuitionValue;
  }
  return module15Data.tuition;
}
// Fedsub organization value
export function furFedSubOrganizationValue() {
  module15Data.furFedSubOrganizationValue = 0;
  let furTotalMaxLimits = 0;

  if (module15Data.fed_sub_firstyear !== undefined && module15Data.fed_sub_firstyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 1) {
    furTotalMaxLimits = module15Data.fed_sub_firstyear;
  }
  if (module15Data.fed_sub_secondyear !== undefined && module15Data.fed_sub_secondyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 2) {
    furTotalMaxLimits = module15Data.fed_sub_secondyear + furTotalMaxLimits;
  }
  if (module15Data.fed_sub_thirdyear !== undefined && module15Data.fed_sub_thirdyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 3) {
    furTotalMaxLimits = module15Data.fed_sub_thirdyear + furTotalMaxLimits;
  }
  if (module15Data.fed_sub_fourthyear !== undefined && module15Data.fed_sub_fourthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 4) {
    furTotalMaxLimits = module15Data.fed_sub_fourthyear + furTotalMaxLimits;
  }
  if (module15Data.fed_sub_fifthyear !== undefined && module15Data.fed_sub_fifthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 5) {
    furTotalMaxLimits = module15Data.fed_sub_fifthyear + furTotalMaxLimits;
  }
  if (module15Data.fed_sub_sixthyear !== undefined && module15Data.fed_sub_sixthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 6) {
    furTotalMaxLimits = module15Data.fed_sub_sixthyear + furTotalMaxLimits;
  }

  if (module15Data.fed_sub_origination_fee !== undefined && module15Data.fed_sub_origination_fee !== '') {
    module15Data.furFedSubOrganizationValue = (furTotalMaxLimits / 100) * module15Data.fed_sub_origination_fee;
  }
  return module15Data.furFedSubOrganizationValue;
}
// Fed Unsub organization value
export function furFedUnSubOrganizationValue() {
  module15Data.furFedUnSubOrganizationValue = 0;
  let furFedUnsubTotalMaxLimits = 0;

  if (module15Data.fed_unsub_firstyear !== undefined && module15Data.fed_unsub_firstyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 1) {
    furFedUnsubTotalMaxLimits = module15Data.fed_unsub_firstyear;
  }
  if (module15Data.fed_unsub_secondyear !== undefined && module15Data.fed_unsub_secondyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 2) {
    furFedUnsubTotalMaxLimits = module15Data.fed_unsub_secondyear + furFedUnsubTotalMaxLimits;
  }
  if (module15Data.fed_unsub_thirdyear !== undefined && module15Data.fed_unsub_thirdyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 3) {
    furFedUnsubTotalMaxLimits = module15Data.fed_unsub_thirdyear + furFedUnsubTotalMaxLimits;
  }
  if (module15Data.fed_unsub_fourthyear !== undefined && module15Data.fed_unsub_fourthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 4) {
    furFedUnsubTotalMaxLimits = module15Data.fed_unsub_fourthyear + furFedUnsubTotalMaxLimits;
  }
  if (module15Data.fed_unsub_fifthyear !== undefined && module15Data.fed_unsub_fifthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 5) {
    furFedUnsubTotalMaxLimits = module15Data.fed_unsub_fifthyear + furFedUnsubTotalMaxLimits;
  }
  if (module15Data.fed_unsub_sixthyear !== undefined && module15Data.fed_unsub_sixthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 6) {
    furFedUnsubTotalMaxLimits = module15Data.fed_unsub_sixthyear + furFedUnsubTotalMaxLimits;
  }

  if (module15Data.fed_unsub_origination_fee !== undefined && module15Data.fed_unsub_origination_fee !== '') {
    module15Data.furFedUnSubOrganizationValue = (furFedUnsubTotalMaxLimits / 100) * module15Data.fed_unsub_origination_fee;
  }
  return module15Data.furFedUnSubOrganizationValue;
}

// Fed Private organization value
export function furPrivateOrganizationValue() {
  module15Data.furPrivateOrganizationValue = 0;
  let totalFurPrivateMaxLimits = 0;
  if (module15Data.private_firstyear !== undefined && module15Data.private_firstyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 1) {
    totalFurPrivateMaxLimits = module15Data.private_firstyear;
  }
  if (module15Data.private_secondyear !== undefined && module15Data.private_secondyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 2) {
    totalFurPrivateMaxLimits = module15Data.private_secondyear + totalFurPrivateMaxLimits;
  }
  if (module15Data.private_thirdyear !== undefined && module15Data.private_thirdyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 3) {
    totalFurPrivateMaxLimits = module15Data.private_thirdyear + totalFurPrivateMaxLimits;
  }
  if (module15Data.private_fourthyear !== undefined && module15Data.private_fourthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 4) {
    totalFurPrivateMaxLimits = module15Data.private_fourthyear + totalFurPrivateMaxLimits;
  }
  if (module15Data.private_fifthyear !== undefined && module15Data.private_fifthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 5) {
    totalFurPrivateMaxLimits = module15Data.private_fifthyear + totalFurPrivateMaxLimits;
  }
  if (module15Data.private_sixthyear !== undefined && module15Data.private_sixthyear !== '' && module15Data.years_diff !== undefined && module15Data.years_diff >= 6) {
    totalFurPrivateMaxLimits = module15Data.private_sixthyear + totalFurPrivateMaxLimits;
  }

  if (module15Data.private_origination_fee !== undefined && module15Data.private_origination_fee !== '') {
    module15Data.furPrivateOrganizationValue = (totalFurPrivateMaxLimits / 100) * module15Data.private_origination_fee;
  }
  return module15Data.furPrivateOrganizationValue;
}

// Year Payment Begins
export function newFedSubPaymentYearBegins() {
  module15Data.newFedSubPaymentYearBegins = 0;
  if (module15Data.graduation_year !== undefined && module15Data.graduation_year > 0
    && module2Data.start_year !== undefined && module2Data.start_year > 0
    && module2Data.start_year === (parseInt(module15Data.graduation_year, 10) + 1)
    && module2Data.graduation_year !== undefined && module2Data.graduation_year > 0) {
    module15Data.newFedSubPaymentYearBegins = module2Data.graduation_year + 1;
  } else if (module15Data.graduation_year !== undefined && module15Data.graduation_year > 0
    && module6Data.start_year !== undefined && module6Data.start_year > 0
    && module6Data.start_year === (parseInt(module15Data.graduation_year, 10) + 1)
    && module6Data.graduation_year !== undefined && module6Data.graduation_year > 0) {
    module15Data.newFedSubPaymentYearBegins = module6Data.graduation_year + 1;
  } else if (module15Data.graduation_year !== undefined && module15Data.graduation_year > 0) {
    module15Data.newFedSubPaymentYearBegins = module15Data.graduation_year + 1;
  } else {
    module15Data.newFedSubPaymentYearBegins = 0;
  }
  return module15Data.newFedSubPaymentYearBegins;
}
// Cal Beginning Balance
export function newFedSubBeginningBalance() {
  module15Data.newFedSubBeginningBalance = module15Data.fed_sub_firstyear
    + module15Data.fed_sub_secondyear
    + module15Data.fed_sub_thirdyear
    + module15Data.fed_sub_fourthyear
    + module15Data.fed_sub_fifthyear
    + module15Data.fed_sub_sixthyear;
  return module15Data.newFedSubBeginningBalance;
}
// Cal Monthly Payment
export function newFedSubMonthlyPayment() {
  module15Data.newFedSubMonthlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0 && fedSubNewHigherEdLoanData !== undefined && fedSubNewHigherEdLoanData['Original Payment'] !== undefined) {
    module15Data.newFedSubMonthlyPayment = fedSubNewHigherEdLoanData['Original Payment'];
  }
  return module15Data.newFedSubMonthlyPayment;
}
// Cal Yearly Payment
export function newFedSubYearlyPayment() {
  module15Data.newFedSubYearlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedSubYearlyPayment = module15Data.newFedSubMonthlyPayment * 12;
  }
  return module15Data.newFedSubYearlyPayment;
}
// Cal Total Interest Paid
export function newFedSubTotalInterestPaid() {
  module15Data.newFedSubTotalInterestPaid = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedSubTotalInterestPaid = fedSubNewHigherEdLoanData['Total Interest Paid'];
  }
  return module15Data.newFedSubTotalInterestPaid;
}
// Cal Total Prinicipal & Interest
export function newFedSubTotalPrincipalInterest() {
  module15Data.newFedSubTotalPrincipalInterest = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedSubTotalPrincipalInterest = fedSubNewHigherEdLoanData['Total Paid'];
  }
  return module15Data.newFedSubTotalPrincipalInterest;
}
// Cal Principal
export function newFedUnSubPrincipal() {
  module15Data.newFedUnSubPrincipal = module15Data.fed_unsub_firstyear
    + module15Data.fed_unsub_secondyear
    + module15Data.fed_unsub_thirdyear
    + module15Data.fed_unsub_fourthyear
    + module15Data.fed_unsub_fifthyear
    + module15Data.fed_unsub_sixthyear;
  return module15Data.newFedUnSubPrincipal;
}
// Cal Accrued Interest
export function newFedUnSubAccruedInterest() {
  module15Data.newFedUnSubAccruedInterest = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedUnSubAccruedInterest = fedUnSubNewHigherEdLoanData['Accrued Interest'];
  }
  return module15Data.newFedUnSubAccruedInterest;
}
// Cal Begginning Balance
export function newFedUnSubBeginningBalance() {
  module15Data.newFedUnSubBeginningBalance = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedUnSubBeginningBalance = fedUnSubNewHigherEdLoanData['Beginning Balance'];
  }
  return module15Data.newFedUnSubBeginningBalance;
}
// Cal Monthly Payment
export function newFedUnSubMonthlyPayment() {
  module15Data.newFedUnSubMonthlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0 && fedUnSubNewHigherEdLoanData['Original Payment'] !== undefined) {
    module15Data.newFedUnSubMonthlyPayment = fedUnSubNewHigherEdLoanData['Original Payment'];
  }
  return module15Data.newFedUnSubMonthlyPayment;
}
// Cal Yearly Payment
export function newFedUnSubYearlyPayment() {
  module15Data.newFedUnSubYearlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedUnSubYearlyPayment = module15Data.newFedUnSubMonthlyPayment * 12;
  }
  return module15Data.newFedUnSubYearlyPayment;
}
// Cal Total Interest Paid
export function newFedUnSubTotalInterestPaid() {
  module15Data.newFedUnSubTotalInterestPaid = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedUnSubTotalInterestPaid = fedUnSubNewHigherEdLoanData['Total Interest Paid'];
  }
  return module15Data.newFedUnSubTotalInterestPaid;
}
// Cal Total Principal & Interest
export function newFedUnSubTotalPrincipalInterest() {
  module15Data.newFedUnSubTotalPrincipalInterest = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newFedUnSubTotalPrincipalInterest = fedUnSubNewHigherEdLoanData['Total Paid'];
  }
  return module15Data.newFedUnSubTotalPrincipalInterest;
}
// Cal Principal
export function newPrivatePrincipal() {
  module15Data.newPrivatePrincipal = module15Data.private_firstyear
    + module15Data.private_secondyear
    + module15Data.private_thirdyear
    + module15Data.private_fourthyear
    + module15Data.private_fifthyear
    + module15Data.private_sixthyear;
  return module15Data.newPrivatePrincipal;
}
// Cal Accrued Interest
export function newPrivateAccruedInterest() {
  module15Data.newPrivateAccruedInterest = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newPrivateAccruedInterest = privateNewHigherEdLoanData['Accrued Interest'];
  }
  return module15Data.newPrivateAccruedInterest;
}
// Cal Begginning Balance
export function newPrivateBeginningBalance() {
  module15Data.newPrivateBeginningBalance = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newPrivateBeginningBalance = privateNewHigherEdLoanData['Beginning Balance'];
  }
  return module15Data.newPrivateBeginningBalance;
}
// Cal Monthly Payment
export function newPrivateMonthlyPayment() {
  module15Data.newPrivateMonthlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newPrivateMonthlyPayment = privateNewHigherEdLoanData['Original Payment'];
  }
  return module15Data.newPrivateMonthlyPayment;
}
// Cal Yearly Payment
export function newPrivateYearlyPayment() {
  module15Data.newPrivateYearlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newPrivateYearlyPayment = module15Data.newPrivateMonthlyPayment * 12;
  }
  return module15Data.newPrivateYearlyPayment;
}
// Cal Total Interest Paid
export function newPrivateTotalInterestPaid() {
  module15Data.newPrivateTotalInterestPaid = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newPrivateTotalInterestPaid = privateNewHigherEdLoanData['Total Interest Paid'];
  }
  return module15Data.newPrivateTotalInterestPaid;
}
// Cal Total Principal & Interest
export function newPrivateTotalPrincipalInterest() {
  module15Data.newPrivateTotalPrincipalInterest = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.newPrivateTotalPrincipalInterest = privateNewHigherEdLoanData['Total Paid'];
  }
  return module15Data.newPrivateTotalPrincipalInterest;
}
// Cal Total Principal & Interest
export function newSummaryLoanPoints() {
  module15Data.newSummaryLoanPoints = 0;
  if (module15Data.furFedSubOrganizationValue !== undefined && module15Data.furFedUnSubOrganizationValue !== undefined && module15Data.furPrivateOrganizationValue !== undefined) {
    module15Data.newSummaryLoanPoints = -(module15Data.furFedSubOrganizationValue + module15Data.furFedUnSubOrganizationValue + module15Data.furPrivateOrganizationValue);
  }
  /* module15Data.newSummaryLoanPoints = - (fedSubNewHigherEdLoanData.totalPointsPaid +
              fedUnSubNewHigherEdLoanData.totalPointsPaid +
              privateNewHigherEdLoanData.totalPointsPaid); */
  return module15Data.newSummaryLoanPoints;
}
// Cal Principal
export function newHigherStudentLoanPrincipal() {
  module15Data.studentLoanPrincipal = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0
    && module15Data.graduation_year !== undefined && module15Data.graduation_year > 0
    && module15Data.yearlyDistribution !== undefined && module15Data.yearlyDistribution !== '') {
    module15Data.studentLoanPrincipal = module15Data.yearlyDistribution * ((module15Data.graduation_year - module15Data.start_year) + 1);
  }
  return module15Data.studentLoanPrincipal;
}
// Cal Accrued Interest
export function newHigherStudentLoanAccruedInterest() {
  module15Data.studentLoanAccruedInterest = 0;
  if (module15Data.start_year !== undefined && parseInt(module15Data.start_year, 10) > 0) {
    module15Data.studentLoanAccruedInterest = studentLoan2Data['Accrued Interest'];
  }
  return module15Data.studentLoanAccruedInterest;
}
// Cal Beginning Balance
export function newHigherStudentLoanBeginningBalance() {
  module15Data.studentLoanBeginningBalance = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.studentLoanBeginningBalance = studentLoan2Data['Beginning Balance'];
  }
  return module15Data.studentLoanBeginningBalance;
}
// Cal Monthly Payment
export function newHigherStudentLoanMonthlyPayment() {
  module15Data.studentLoanMonthlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.studentLoanMonthlyPayment = studentLoan2Data['Original Payment'];
  }
  return module15Data.studentLoanMonthlyPayment;
}
// Cal Yearly Payment
export function newHigherStudentLoanYearlyPayment() {
  module15Data.studentLoanYearlyPayment = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    module15Data.studentLoanYearlyPayment = module15Data.studentLoanMonthlyPayment * 12;
  }
  return module15Data.studentLoanYearlyPayment;
}
// Cal Total Interest Paid
export function newHigherStudentLoanTotalInterestPaid() {
  module15Data.studentLoanTotalInterestPaid = 0;
  let val1 = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year > 0) {
    val1 = studentLoan2Data['Total Interest Paid'];
  }
  module15Data.studentLoanTotalInterestPaid = val1;
  return module15Data.studentLoanTotalInterestPaid;
}
// Cal Current Balance
export function newHigherStudentLoanCurrentBalance() {
  module15Data.studentLoanCurrentBalance = 0;
  if (module15Data.start_year !== undefined) {
    if (balanceSheetData[parseInt(module15Data.start_year, 10) - 1] !== undefined
      && balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Liabilities['Student Loans'] !== undefined
      && balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Liabilities['Student Loans'] !== '') {
      module15Data.studentLoanCurrentBalance = balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Liabilities['Student Loans'];
    }
  }
  return module15Data.studentLoanCurrentBalance;
}
// Cal Estimated Tax Rate
export function newHigherEarnedEstimatedTaxRate() {
  module15Data.estimatedTaxRate = 0;
  if (module15Data.yearly_income !== undefined
    && module15Data.yearly_income > 0) {
    // module15Data.estimatedTaxRate = parseFloat(estimatedIncomeTaxes.singleIteration8());
  }

  return parseFloat(module15Data.estimatedTaxRate).toFixed(1);
}
// Cal Estimated tax Paid
export function newHigherestimatedTaxesPaid() {
  module15Data.estimatedTaxesPaid = 0;
  if (module15Data.yearly_income !== undefined && module15Data.yearly_income > 0
    && module15Data.estimatedTaxRate !== undefined && module15Data.estimatedTaxRate > 0) {
    module15Data.estimatedTaxesPaid = module15Data.yearly_income * (module15Data.estimatedTaxRate / 100);
  }
  return module15Data.estimatedTaxesPaid;
}
// Cal Estimated tax Paid
export function newHigherEstimatedIncomeafterTaxes() {
  module15Data.estimatedIncomeafterTaxes = 0;
  if (module15Data.yearly_income !== undefined && module15Data.yearly_income > 0
    && module15Data.estimatedTaxesPaid !== undefined && module15Data.estimatedTaxesPaid > 0) {
    module15Data.estimatedIncomeafterTaxes = module15Data.yearly_income - module15Data.estimatedTaxesPaid;
  }
  return module15Data.estimatedIncomeafterTaxes;
}
/** Portion 8 * */
// Investment Analysis Scholships
export function newHigherinvestmentAnalysisScholships() {
  module15Data.newHigherinvestmentAnalysisScholships = 0;
  if (module15Data.yearly_scholarship_amount !== undefined && module15Data.yearly_scholarship_amount !== ''
    && module15Data.years_diff !== undefined && module15Data.years_diff !== '') {
    module15Data.newHigherinvestmentAnalysisScholships = module15Data.yearly_scholarship_amount * module15Data.years_diff;
  }
  return module15Data.newHigherinvestmentAnalysisScholships;
}
// Investment Grants
export function newHigherinvestmentAnalysisGrants() {
  module15Data.newHigherinvestmentAnalysisGrants = 0;
  if (module15Data.yearly_grant_amount !== undefined && module15Data.yearly_grant_amount !== ''
    && module15Data.years_diff !== undefined && module15Data.years_diff !== '') {
    module15Data.newHigherinvestmentAnalysisGrants = module15Data.yearly_grant_amount * module15Data.years_diff;
  }
  return module15Data.newHigherinvestmentAnalysisGrants;
}
// Investment Analysis Financial Aid
export function newHigherinvestmentAnalysisFinancialAid() {
  module15Data.newHigherinvestmentAnalysisFinancialAid = 0;
  if (module15Data.expected_yearly_finance_aid !== undefined && module15Data.expected_yearly_finance_aid !== ''
    && module15Data.aidYearsDiff !== undefined && module15Data.aidYearsDiff !== '') {
    module15Data.newHigherinvestmentAnalysisFinancialAid = module15Data.expected_yearly_finance_aid * module15Data.aidYearsDiff;
  }
  return module15Data.newHigherinvestmentAnalysisFinancialAid;
}
// Investment Analysis Income Earned
export function newHigherinvestmentAnalysisIncomeEarned() {
  module15Data.newHigherinvestmentAnalysisIncomeEarned = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '') {
    const start_year = parseInt(module15Data.start_year, 10);
    const endYear = parseInt(module15Data.graduation_year, 10);
    const tuitionCostData = incomeStatementData;
    let incomeEarnedAfterTaxesValCalue = 0;
    if (Object.keys(tuitionCostData).length !== 0) {
      Object.keys(tuitionCostData).forEach((year) => {
        if (year >= start_year && year <= endYear && tuitionCostData[year] !== undefined && tuitionCostData[year]['Income After Taxes'] !== undefined) {
          incomeEarnedAfterTaxesValCalue += tuitionCostData[year]['Income After Taxes'];
        }
      });
    }
    module15Data.newHigherinvestmentAnalysisIncomeEarned = incomeEarnedAfterTaxesValCalue;
  } else {
    module15Data.newHigherinvestmentAnalysisIncomeEarned = 0;
  }
  return module15Data.newHigherinvestmentAnalysisIncomeEarned;
}
// Investment Analysis Family Contribution
export function newHigherinvestmentAnalysisFamilyContribution() {
  module15Data.newHigherinvestmentAnalysisFamilyContribution = 0;
  if (module15Data.yearly_contribution !== undefined && module15Data.yearly_contribution !== ''
    && module15Data.family_start_year !== undefined && module15Data.family_start_year !== ''
    && module15Data.family_end_year !== undefined && module15Data.family_end_year !== '') {
    module15Data.newHigherinvestmentAnalysisFamilyContribution = module15Data.yearly_contribution * ((module15Data.family_end_year - module15Data.family_start_year) + 1);
  }
  return module15Data.newHigherinvestmentAnalysisFamilyContribution;
}
// Investment Analysis Total Cash Available
export function newHigherinvestmentAnalysisTotalCashAvailable() {
  module15Data.newHigherinvestmentAnalysisTotalCashAvailable = module15Data.newHigherinvestmentAnalysisScholships
    + module15Data.newHigherinvestmentAnalysisGrants
    + module15Data.newHigherinvestmentAnalysisFinancialAid
    + module15Data.newFedSubBeginningBalance
    + module15Data.newFedUnSubPrincipal
    + module15Data.newPrivatePrincipal
    + module15Data.newSummaryLoanPoints
    + module15Data.newHigherinvestmentAnalysisIncomeEarned
    + module15Data.newHigherinvestmentAnalysisFamilyContribution;
  return module15Data.newHigherinvestmentAnalysisTotalCashAvailable;
}
// Investment Analysis Total Liquid Assets Available
export function newHigherinvestmentAnalysisTotalLiquidAssetsAvailable() {
  module15Data.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable = 0;

  let value1 = 0;
  if (module15Data.start_year === module1Data.start_year) {
    let val1 = 0;
    // if (module1Data.current_cash_balance !== undefined && module1Data.current_cash_balance !== '') {
    //   val1 = module1Data.current_cash_balance;
    // }
    let val2 = 0;
    if (module9Data.shortTermBeginningBalance !== undefined && module9Data.shortTermBeginningBalance !== '') {
      val2 = module9Data.shortTermBeginningBalance;
    }
    let val3 = 0;
    if (module9Data.longTermBeginningBalance !== undefined && module9Data.longTermBeginningBalance !== '') {
      val3 = module9Data.longTermBeginningBalance;
    }
    value1 = val1 + val2 + val3;
  } else if (module15Data.start_year !== undefined && module15Data.start_year !== '') {
    if (balanceSheetData[parseInt(module15Data.start_year, 10) - 1] !== undefined
      && balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Assets.Cash !== undefined
      && balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Investments['Short-Term Investments'] !== undefined
      && balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Investments['Long-Term Investments'] !== undefined) {
      value1 = parseFloat(balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Assets.Cash)
        + parseFloat(balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Investments['Short-Term Investments'])
        + parseFloat(balanceSheetData[parseInt(module15Data.start_year, 10) - 1].Investments['Long-Term Investments']);
    } else {
      value1 = 0;
    }
  } else {
    value1 = 0;
  }
  module15Data.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable = value1;
  return module15Data.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable;
}
// Investment Analysis Tuition Cost
export function newHigherinvestmentAnalysisTuitionCosts() {
  module15Data.newHigherinvestmentAnalysisTuitionCosts = 0;
  if (module15Data.tuition !== undefined
    && module15Data.tuition !== '' && module15Data.tuition > 0) {
    if (incomeStatementData !== undefined && incomeStatementData !== '') {
      const start_year = parseInt(module15Data.start_year, 10);
      const endYear = parseInt(module15Data.graduation_year, 10);
      const tuitionCostData = incomeStatementData;
      let newInvestmentAnalysisTuitionCostsCalVal = 0;
      if (Object.keys(tuitionCostData).length !== 0) {
        Object.keys(tuitionCostData).forEach((year) => {
          if (year >= start_year && year <= endYear && tuitionCostData[year] !== undefined && tuitionCostData[year].livingExpenses !== undefined && tuitionCostData[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined) {
            newInvestmentAnalysisTuitionCostsCalVal += tuitionCostData[year].livingExpenses['Higher Education Tuition and Fees'];
          }
        });
      }
      module15Data.newHigherinvestmentAnalysisTuitionCosts = newInvestmentAnalysisTuitionCostsCalVal;
    } else {
      module15Data.newHigherinvestmentAnalysisTuitionCosts = 0;
    }
  }
  return module15Data.newHigherinvestmentAnalysisTuitionCosts;
}
// Investment Analysis Cash Excess
export function newHigherinvestmentAnalysisCashExcess() {
  module15Data.newHigherinvestmentAnalysisCashExcess = 0;
  if (module15Data.newHigherinvestmentAnalysisTotalCashAvailable !== undefined && module15Data.newHigherinvestmentAnalysisTotalCashAvailable !== ''
    && module15Data.newHigherinvestmentAnalysisTuitionCosts !== undefined && module15Data.newHigherinvestmentAnalysisTuitionCosts !== '') {
    module15Data.newHigherinvestmentAnalysisCashExcess = module15Data.newHigherinvestmentAnalysisTotalCashAvailable
      + module15Data.newHigherinvestmentAnalysisTuitionCosts;
  }
  return module15Data.newHigherinvestmentAnalysisCashExcess;
}
// Investment Analysis Outstanding Loan
export function newHigherinvestmentAnalysisOutstandingLoan() {
  module15Data.newHigherinvestmentAnalysisOutstandingLoan = 0;
  module15Data.newHigherinvestmentAnalysisOutstandingLoan = module15Data.newFedSubBeginningBalance
    + module15Data.newFedUnSubPrincipal
    + module15Data.newPrivatePrincipal;
  return module15Data.newHigherinvestmentAnalysisOutstandingLoan;
}
// Investment Analysis Total Principal & Interest
export function newHigherStudentLoanTotalPrincipalInterest() {
  module15Data.newHigherStudentLoanTotalPrincipalInterest = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year !== '' && parseInt(module15Data.start_year, 10) > 0) {
    module15Data.newHigherStudentLoanTotalPrincipalInterest = module15Data.newFedSubTotalPrincipalInterest
      + module15Data.newFedUnSubTotalPrincipalInterest
      + module15Data.newPrivateTotalPrincipalInterest;
  }
  return module15Data.newHigherStudentLoanTotalPrincipalInterest;
}

// Further Higher Education Expenses Rent Multiplier
export function furtherHigherOffCampusRentMultiplier() {
  module15Data.furtherHigherOffCampusRentMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusRentMultiplier = module10Data.rent_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusRentMultiplier = module10Data.rent_multiplier;
    } else {
      module15Data.furtherHigherOffCampusRentMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusRentMultiplier;
}

// More Higher Education Expenses Rent Expenses
export function furtherHigherOffCampusRentExpenses() {
  let rentExpenses = 0;
  module15Data.furtherHigherOffCampusRentExpenses = 0;
  if (module15Data.furtherHigherOffCampusRentMultiplier !== undefined
    && module15Data.furtherHigherOffCampusRentMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.rent !== undefined
      && module15Data.rent !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Rent) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.rent !== ''
        && module15Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Rent) / 12);
      } else {
        rentExpenses = 0;
      }
    }
  } else {
    rentExpenses = '';
  }
  module15Data.furtherHigherOffCampusRentExpenses = rentExpenses;

  return module15Data.furtherHigherOffCampusRentExpenses;
}

// further Higher Education Expenses Utilities Multiplier
export function furtherHigherOffCampusUtilitiesMultiplier() {
  module15Data.furtherHigherOffCampusUtilitiesMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.utilities_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else {
      module15Data.furtherHigherOffCampusUtilitiesMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusUtilitiesMultiplier;
}

// further Higher Education Utilities Expenses
export function furtherHigherOffCampusUtilitiesExpenses() {
  let utilitiesExpense = 0;
  module15Data.furtherHigherOffCampusUtilitiesExpenses = 0;
  if (module15Data.furtherHigherOffCampusUtilitiesMultiplier !== undefined
    && module15Data.furtherHigherOffCampusUtilitiesMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.utilities !== undefined
      && module15Data.utilities !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Utilities) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.utilities !== ''
        && module15Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities) / 12);
      } else {
        utilitiesExpense = 0;
      }
    }
  } else {
    utilitiesExpense = '';
  }
  module15Data.furtherHigherOffCampusUtilitiesExpenses = utilitiesExpense;

  return module15Data.furtherHigherOffCampusUtilitiesExpenses;
}

// further Higher Education Food  Multiplier
export function furtherHigherOffCampusFoodMultiplier() {
  module15Data.furtherHigherOffCampusFoodMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.food_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusFoodMultiplier = module10Data.food_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusFoodMultiplier = module10Data.food_multiplier;
    } else {
      module15Data.furtherHigherOffCampusFoodMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusFoodMultiplier;
}

// further Higher Education Food Expenses
export function furtherHigherOffCampusFoodExpenses() {
  let foodExpenses = 0;
  module15Data.furtherHigherOffCampusFoodExpenses = 0;
  if (module15Data.furtherHigherOffCampusFoodMultiplier !== undefined
    && module15Data.furtherHigherOffCampusFoodMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.food !== undefined
      && module15Data.food !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.food > 0) {
        foodExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Food) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.food !== ''
        && module15Data.food > 0) {
        foodExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Food) / 12);
      } else {
        foodExpenses = 0;
      }
    }
  } else {
    foodExpenses = '';
  }
  module15Data.furtherHigherOffCampusFoodExpenses = foodExpenses;

  return module15Data.furtherHigherOffCampusFoodExpenses;
}

// further Higher Education Clothing Multiplier
export function furtherHigherOffCampusClothingMultiplier() {
  module15Data.furtherHigherOffCampusClothingMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.clothing_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusClothingMultiplier = module10Data.clothing_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusClothingMultiplier = module10Data.clothing_multiplier;
    } else {
      module15Data.furtherHigherOffCampusClothingMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusClothingMultiplier;
}

// further Higher Education Clothing Expenses
export function furtherHigherOffCampusClothingExpenses() {
  let clothingExpenses = 0;
  module15Data.furtherHigherOffCampusClothingExpenses = 0;
  if (module15Data.furtherHigherOffCampusClothingMultiplier !== undefined
    && module15Data.furtherHigherOffCampusClothingMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.second_clothing !== undefined
      && module15Data.second_clothing !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.second_clothing > 0) {
        clothingExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Clothing) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.second_clothing !== ''
        && module15Data.second_clothing > 0) {
        clothingExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing) / 12);
      } else {
        clothingExpenses = 0;
      }
    }
  } else {
    clothingExpenses = '';
  }
  module15Data.furtherHigherOffCampusClothingExpenses = clothingExpenses;

  return module15Data.furtherHigherOffCampusClothingExpenses;
}

// further Higher Education Entertainment Multiplier
export function furtherHigherOffCampusEntertainmentMultiplier() {
  module15Data.furtherHigherOffCampusEntertainmentMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.entertainment_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else {
      module15Data.furtherHigherOffCampusEntertainmentMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusEntertainmentMultiplier;
}

// further Higher Education off Campus Entertainment Expenses
export function furtherHigherOffCampusEntertainmentExpenses() {
  let entertainmentExpenses = 0;
  module15Data.furtherHigherOffCampusEntertainmentExpenses = 0;
  if (module15Data.furtherHigherOffCampusEntertainmentMultiplier !== undefined
    && module15Data.furtherHigherOffCampusEntertainmentMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.second_entertainment !== undefined
      && module15Data.second_entertainment !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.second_entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Entertainment) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.second_entertainment !== ''
        && module15Data.second_entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment) / 12);
      } else {
        entertainmentExpenses = 0;
      }
    }
  } else {
    entertainmentExpenses = '';
  }
  module15Data.furtherHigherOffCampusEntertainmentExpenses = entertainmentExpenses;

  return module15Data.furtherHigherOffCampusEntertainmentExpenses;
}

// further Higher Education Technology Multiplier
export function furtherHigherOffCampusTechnologyMultiplier() {
  module15Data.furtherHigherOffCampusTechnologyMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.technology_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusTechnologyMultiplier = module10Data.technology_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusTechnologyMultiplier = module10Data.technology_multiplier;
    } else {
      module15Data.furtherHigherOffCampusTechnologyMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusTechnologyMultiplier;
}

// further Higher Education off Campus Technology Expenses
export function furtherHigherOffCampusTechnologyExpenses() {
  let technologyExpenses = 0;
  module15Data.furtherHigherOffCampusTechnologyExpenses = 0;
  if (module15Data.furtherHigherOffCampusTechnologyMultiplier !== undefined
    && module15Data.furtherHigherOffCampusTechnologyMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.second_technology !== undefined
      && module15Data.second_technology !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.second_technology > 0) {
        technologyExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Technology) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.second_technology !== ''
        && module15Data.second_technology > 0) {
        technologyExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Technology) / 12);
      } else {
        technologyExpenses = 0;
      }
    }
  } else {
    technologyExpenses = '';
  }
  module15Data.furtherHigherOffCampusTechnologyExpenses = technologyExpenses;

  return module15Data.furtherHigherOffCampusTechnologyExpenses;
}

// further Higher Education transportation Multiplier
export function furtherHigherOffCampusTransportationMultiplier() {
  module15Data.furtherHigherOffCampusTransportationMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.transportation_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusTransportationMultiplier = module10Data.transportation_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusTransportationMultiplier = module10Data.transportation_multiplier;
    } else {
      module15Data.furtherHigherOffCampusTransportationMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusTransportationMultiplier;
}

// further Higher Education off Campus Transportation Expenses
export function furtherHigherOffCampusTransportationExpenses() {
  let transportationExpenses = 0;
  module15Data.furtherHigherOffCampusTransportationExpenses = 0;
  if (module15Data.furtherHigherOffCampusTransportationMultiplier !== undefined
    && module15Data.furtherHigherOffCampusTransportationMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.second_transportation !== undefined
      && module15Data.second_transportation !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''

      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.second_transportation > 0) {
        transportationExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Transportation) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.second_transportation !== ''
        && module15Data.second_transportation > 0) {
        transportationExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation) / 12);
      } else {
        transportationExpenses = 0;
      }
    }
  } else {
    transportationExpenses = '';
  }
  module15Data.furtherHigherOffCampusTransportationExpenses = transportationExpenses;

  return module15Data.furtherHigherOffCampusTransportationExpenses;
}

// further Higher Education miscellaneous Multiplier
export function furtherHigherOffCampusMiscellaneousMultiplier() {
  module15Data.furtherHigherOffCampusMiscellaneousMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.miscellaneous_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else {
      module15Data.furtherHigherOffCampusMiscellaneousMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusMiscellaneousMultiplier;
}

// further Higher Education off Campus Miscellaneous Expenses
export function furtherHigherOffCampusMiscellaneousExpenses() {
  let miscellaneousExpenses = 0;
  module15Data.furtherHigherOffCampusMiscellaneousExpenses = 0;
  if (module15Data.furtherHigherOffCampusMiscellaneousMultiplier !== undefined
    && module15Data.furtherHigherOffCampusMiscellaneousMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.second_miscellaneous !== undefined
      && module15Data.second_miscellaneous !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.second_miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses.Miscellaneous) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.second_miscellaneous !== ''
        && module15Data.second_miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous) / 12);
      } else {
        miscellaneousExpenses = 0;
      }
    }
  } else {
    miscellaneousExpenses = '';
  }
  module15Data.furtherHigherOffCampusMiscellaneousExpenses = miscellaneousExpenses;

  return module15Data.furtherHigherOffCampusMiscellaneousExpenses;
}

// further Higher Education health Insurance Multiplier
export function furtherHigherOffCampusHealthInsuranceMultiplier() {
  module15Data.furtherHigherOffCampusHealthInsuranceMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.health_insurance_costs_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusHealthInsuranceMultiplier = module10Data.health_insurance_costs_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusHealthInsuranceMultiplier = module10Data.health_insurance_costs_multiplier;
    } else {
      module15Data.furtherHigherOffCampusHealthInsuranceMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusHealthInsuranceMultiplier;
}

// further Higher Education off Campus Outside Premium Expenses
export function furtherHigherOffCampusOutsidePremiumExpenses() {
  let medicalExpenses = 0;
  module15Data.furtherHigherOffCampusOutsidePremiumExpenses = 0;
  if (module15Data.furtherHigherOffCampusMiscellaneousMultiplier !== undefined
    && module15Data.furtherHigherOffCampusMiscellaneousMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.second_medical_costs_before_deductible !== undefined
      && module15Data.second_medical_costs_before_deductible !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.second_medical_costs_before_deductible > 0) {
        medicalExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.second_medical_costs_before_deductible !== ''
        && module15Data.second_medical_costs_before_deductible > 0) {
        medicalExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else {
        medicalExpenses = 0;
      }
    }
  } else {
    medicalExpenses = '';
  }
  module15Data.furtherHigherOffCampusOutsidePremiumExpenses = medicalExpenses;

  return module15Data.furtherHigherOffCampusOutsidePremiumExpenses;
}

// further Higher Education health Insurance Premium Multiplier
export function furtherHigherOffCampusHealthInsurancePremiumMultiplier() {
  module15Data.furtherHigherOffCampusHealthInsurancePremiumMultiplier = 0;
  if (module15Data.off_campus_start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.health_insurance_premium_multiplier !== undefined) {
    if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module15Data.off_campus_start_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusHealthInsurancePremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else if (module15Data.off_campus_start_year !== ''
      && module10Data.marriege_year !== ''
      && module6Data.graduation_year !== ''
      && module15Data.off_campus_start_year < module10Data.marriege_year
      && module6Data.graduation_year >= module10Data.marriege_year) {
      module15Data.furtherHigherOffCampusHealthInsurancePremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else {
      module15Data.furtherHigherOffCampusHealthInsurancePremiumMultiplier = '';
    }
  }
  return module15Data.furtherHigherOffCampusHealthInsurancePremiumMultiplier;
}

// further Higher Education off Campus  Premium Expenses
export function furtherHigherOffCampusPremiumExpenses() {
  let medicalExpenses = 0;
  module15Data.furtherHigherOffCampusPremiumExpenses = 0;
  if (module15Data.furtherHigherOffCampusMiscellaneousMultiplier !== undefined
    && module15Data.furtherHigherOffCampusMiscellaneousMultiplier !== '') {
    if (module15Data.off_campus_start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module15Data.second_health_insurance_premium !== undefined
      && module15Data.second_health_insurance_premium !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module15Data.off_campus_start_year] !== undefined
      && incomeStatementData[module15Data.off_campus_start_year] !== ''
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== undefined
      && incomeStatementData[module15Data.off_campus_start_year].livingExpenses !== '') {
      if (module15Data.off_campus_start_year !== ''
        && module15Data.off_campus_start_year >= module10Data.marriege_year
        && module10Data.marriege_year !== ''
        && module15Data.second_health_insurance_premium > 0) {
        medicalExpenses = -((incomeStatementData[module15Data.off_campus_start_year].livingExpenses['Health Insurance Premium']) / 12);
      } else if (module15Data.off_campus_start_year < module10Data.marriege_year
        && module15Data.graduation_year >= module10Data.marriege_year
        && module15Data.off_campus_start_year !== ''
        && module15Data.graduation_year !== ''
        && module10Data.marriege_year !== ''
        && module15Data.second_health_insurance_premium !== ''
        && module15Data.second_health_insurance_premium > 0) {
        medicalExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium']) / 12);
      } else {
        medicalExpenses = 0;
      }
    }
  } else {
    medicalExpenses = '';
  }
  module15Data.furtherHigherOffCampusPremiumExpenses = medicalExpenses;

  return module15Data.furtherHigherOffCampusPremiumExpenses;
}

export function furtherEducationOffCampusTotalExpenses() {
  module15Data.furtherEducationOffCampusTotalExpenses = 0;
  let totalExpenses = 0;
  if (module15Data.furtherHigherOffCampusRentExpenses !== undefined && module15Data.furtherHigherOffCampusRentExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusRentExpenses;
  }
  if (module15Data.furtherHigherOffCampusUtilitiesExpenses !== undefined && module15Data.furtherHigherOffCampusUtilitiesExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusUtilitiesExpenses;
  }
  if (module15Data.furtherHigherOffCampusFoodExpenses !== undefined && module15Data.furtherHigherOffCampusFoodExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusFoodExpenses;
  }
  if (module15Data.furtherHigherOffCampusClothingExpenses !== undefined && module15Data.furtherHigherOffCampusClothingExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusClothingExpenses;
  }
  if (module15Data.furtherHigherOffCampusEntertainmentExpenses !== undefined && module15Data.furtherHigherOffCampusEntertainmentExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusEntertainmentExpenses;
  }
  if (module15Data.furtherHigherOffCampusTechnologyExpenses !== undefined && module15Data.furtherHigherOffCampusTechnologyExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusTechnologyExpenses;
  }
  if (module15Data.furtherHigherOffCampusTransportationExpenses !== undefined && module15Data.furtherHigherOffCampusTransportationExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusTransportationExpenses;
  }
  if (module15Data.furtherHigherOffCampusMiscellaneousExpenses !== undefined && module15Data.furtherHigherOffCampusMiscellaneousExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusMiscellaneousExpenses;
  }
  if (module15Data.furtherHigherOffCampusOutsidePremiumExpenses !== undefined && module15Data.furtherHigherOffCampusOutsidePremiumExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusOutsidePremiumExpenses;
  }
  if (module15Data.furtherHigherOffCampusPremiumExpenses !== undefined && module15Data.furtherHigherOffCampusPremiumExpenses !== '') {
    totalExpenses += module15Data.furtherHigherOffCampusPremiumExpenses;
  }
  module15Data.furtherEducationOffCampusTotalExpenses = totalExpenses;
  return module15Data.furtherEducationOffCampusTotalExpenses;
}

export function furtherHigherMarriageYear() {
  let furtherHigherMarriageYearVal = '';
  if (module15Data.start_year !== undefined
    && module15Data.start_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module15Data.start_year >= module10Data.marriege_year) {
    furtherHigherMarriageYearVal = module15Data.start_year;
  } else if (module15Data.start_year !== undefined
    && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined
    && module15Data.graduation_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module15Data.start_year < module10Data.marriege_year
    && module15Data.graduation_year >= module10Data.marriege_year) {
    furtherHigherMarriageYearVal = module10Data.marriege_year;
  } else {
    furtherHigherMarriageYearVal = '';
  }
  module15Data.furtherHigherMarriageYearVal = furtherHigherMarriageYearVal;
  return furtherHigherMarriageYearVal;
}

export function newTotalBeforeTaxIncome() {
  module15Data.newTotalBeforeTaxIncome = 0;
  let totalBeforeTaxIncomeValue = 0;
  if (module15Data.start_year !== undefined && module15Data.start_year !== ''
    && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '') {
    const start_year = parseInt(module15Data.start_year, 10);
    const endYear = parseInt(module15Data.graduation_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear && data[year] !== undefined && data[year]['Gross Income'] !== undefined && data[year]['Gross Income']['Income Earned during Higher Education'] !== undefined) {
          totalBeforeTaxIncomeValue += data[year]['Gross Income']['Income Earned during Higher Education'];
        }
      });
    }
  }
  module15Data.newTotalBeforeTaxIncome = totalBeforeTaxIncomeValue;
  return module15Data.newTotalBeforeTaxIncome;
}

export function newEstimatedTaxRateConversation() {
  module15Data.newEstimatedTaxRateConversation = 0;
  if (module15Data.newTotalBeforeTaxIncome !== undefined && module15Data.newTotalBeforeTaxIncome !== '' && module15Data.newHigherinvestmentAnalysisIncomeEarned !== undefined && module15Data.newHigherinvestmentAnalysisIncomeEarned !== '' && module15Data.newTotalBeforeTaxIncome !== 0) {
    module15Data.newEstimatedTaxRateConversation = (1 - (module15Data.newHigherinvestmentAnalysisIncomeEarned / module15Data.newTotalBeforeTaxIncome)) * 100;
  }
  return module15Data.newEstimatedTaxRateConversation;
}

/** ******************** Module 4 *********************** */
// Career path Main title
export function careerPathMainTitle() {
  module4Data.careerPathMainTitle = 'Career Path';
  if (module2Data.type_of_higher_education !== undefined && module2Data.type_of_higher_education !== '' && module6Data.type_of_higher_education !== undefined && module6Data.type_of_higher_education !== '' && module15Data.type_of_higher_education !== undefined && module15Data.type_of_higher_education !== '') {
    module4Data.careerPathMainTitle = 'Career Path after Higher Education';
  }
  return module4Data.careerPathMainTitle;
}
// Total PreTax Expected
export function totalPreTaxExpected() {
  module4Data.totalPreTaxExpected_val = 0;
  if (module4Data.yearly_income !== undefined && module4Data.yearly_income !== ''
    && module4Data.bonus_or_tips_or_commission !== undefined && module4Data.bonus_or_tips_or_commission !== '') {
    module4Data.totalPreTaxExpected_val = module4Data.yearly_income + module4Data.bonus_or_tips_or_commission;
  }
  return module4Data.totalPreTaxExpected_val;
}
export function careerPathsuppYearsDiff() {
  module4Data.supplementaryyearsDiff = 0;
  if (module4Data.second_end_year > 0 && module4Data.second_start_year > 0) {
    module4Data.supplementaryyearsDiff = (module4Data.second_end_year - module4Data.second_start_year) + 1;
  }
  return module4Data.supplementaryyearsDiff;
}

// Supplementary Total PreTax Expected
export function supplementarytotalPreTaxExpected() {
  module4Data.supplementarytotalPreTaxExpected_val = 0;
  if (module4Data.supplementaryyearsDiff !== undefined && module4Data.supplementaryyearsDiff !== ''
    && module4Data.second_yearly_income !== undefined && module4Data.second_yearly_income !== '') {
    module4Data.supplementarytotalPreTaxExpected_val = module4Data.supplementaryyearsDiff * module4Data.second_yearly_income;
  }
  return module4Data.supplementarytotalPreTaxExpected_val;
}

// Expenses Start Year
export function careerPathExpensesStartYear() {
  module4Data.careerPathExpensesStartYear = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '') {
    module4Data.careerPathExpensesStartYear = `${parseFloat(module4Data.start_year) - 1} Expenses`;
  }
  return module4Data.careerPathExpensesStartYear;
}

// Car Payment Expenses
export function careerPathCarPaymentExpenses() {
  let carPaymentExpenses = 0;
  module4Data.careerPathCarPaymentExpenses = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && module1Data.start_year !== undefined
    && module1Data.start_year !== ''
    && (parseFloat(module4Data.start_year) - 1) >= module1Data.start_year) {
    if (incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== undefined
      && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== ''
      && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Payment'] !== undefined
      && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Payment'] !== '') {
      carPaymentExpenses = -(incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Payment'] / 12);
    } else {
      carPaymentExpenses = 0;
    }
  } else {
    carPaymentExpenses = 0;
  }
  module4Data.careerPathCarPaymentExpenses = carPaymentExpenses;
  return module4Data.careerPathCarPaymentExpenses;
}

// Car Maintenance Expenses
export function careerPathCarMaintenanceExpenses() {
  let carMaintenanceExpenses = 0;
  module4Data.careerPathCarMaintenanceExpenses = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && module1Data.start_year !== undefined
    && module1Data.start_year !== ''
    && (parseFloat(module4Data.start_year) - 1) >= module1Data.start_year) {
    if (module4Data.monthlyLivingExpensesCarPayment !== undefined && module4Data.monthlyLivingExpensesCarPayment > 0) {
      if (incomeStatementData !== undefined
        && incomeStatementData !== ''
        && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== undefined
        && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== ''
        && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Maintenance'] !== undefined
        && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Maintenance'] !== '') {
        carMaintenanceExpenses = -(incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Maintenance'] / 12);
      } else {
        carMaintenanceExpenses = 0;
      }
    } else {
      carMaintenanceExpenses = 0;
    }
  } else {
    carMaintenanceExpenses = 0;
  }
  module4Data.careerPathCarMaintenanceExpenses = carMaintenanceExpenses;
  return module4Data.careerPathCarMaintenanceExpenses;
}

// Car Insurance Expenses
export function careerPathCarInsuranceExpenses() {
  let carInsuranceExpenses = 0;
  module4Data.careerPathCarInsuranceExpenses = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && module1Data.start_year !== undefined
    && module1Data.start_year !== ''
    && (parseFloat(module4Data.start_year) - 1) >= module1Data.start_year) {
    if (module4Data.monthlyLivingExpensesCarMaintenance !== undefined && module4Data.monthlyLivingExpensesCarMaintenance > 0) {
      if (incomeStatementData !== undefined
        && incomeStatementData !== ''
        && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== undefined
        && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== ''
        && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Insurance'] !== undefined
        && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Insurance'] !== '') {
        carInsuranceExpenses = -(incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses['Car Insurance'] / 12);
      } else {
        carInsuranceExpenses = 0;
      }
    } else {
      carInsuranceExpenses = 0;
    }
  } else {
    carInsuranceExpenses = 0;
  }
  module4Data.careerPathCarInsuranceExpenses = carInsuranceExpenses;
  return module4Data.careerPathCarInsuranceExpenses;
}

// Gas Expenses
export function careerPathGasExpenses() {
  let gasExpenses = 0;
  module4Data.careerPathCarInsuranceExpenses = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && module1Data.start_year !== undefined
    && module1Data.start_year !== ''
    && (parseFloat(module4Data.start_year) - 1) >= module1Data.start_year) {
    if (module4Data.monthlyLivingExpensesCarInsurance !== undefined && module4Data.monthlyLivingExpensesCarInsurance > 0) {
      if (incomeStatementData !== undefined
        && incomeStatementData !== ''
        && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== undefined
        && incomeStatementData[parseFloat(module4Data.start_year) - 1] !== ''
        && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses.Gas !== undefined
        && incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses.Gas !== '') {
        gasExpenses = -(incomeStatementData[parseFloat(module4Data.start_year) - 1].livingExpenses.Gas / 12);
      } else {
        gasExpenses = 0;
      }
    } else {
      gasExpenses = 0;
    }
  } else {
    gasExpenses = 0;
  }
  module4Data.careerPathGasExpenses = gasExpenses;
  return module4Data.careerPathGasExpenses;
}

// Total Monthly Living Expenses
export function totalMonthlyLivingExpenses() {
  module4Data.totalMonthlyLivingExpenses_val = 0;
  if (module4Data.rent !== undefined && module4Data.rent !== ''
    && module4Data.utilities !== undefined && module4Data.utilities !== ''
    && module4Data.food !== undefined && module4Data.food !== ''
    && module4Data.clothing !== undefined && module4Data.clothing !== ''
    && module4Data.entertainment !== undefined && module4Data.entertainment !== ''
    && module4Data.technology !== undefined && module4Data.technology !== ''
    && module4Data.transportation !== undefined && module4Data.transportation !== ''
    && module4Data.miscellaneous !== undefined && module4Data.miscellaneous !== ''
    && module4Data.medical_costs_before_deductible !== undefined && module4Data.medical_costs_before_deductible !== ''
    && module4Data.health_insurance_premium !== undefined && module4Data.health_insurance_premium !== '') {
    module4Data.totalMonthlyLivingExpenses_val = module4Data.rent
      + module4Data.utilities
      + module4Data.food
      + module4Data.clothing
      + module4Data.entertainment
      + module4Data.technology
      + module4Data.transportation
      + module4Data.miscellaneous
      + module4Data.medical_costs_before_deductible
      + module4Data.health_insurance_premium;
  }
  return module4Data.totalMonthlyLivingExpenses_val;
}

// Existing Creditcard Interest Value
export function existingCreditCardInterestInterest() {
  module4Data.existingCreditCardInterestInterest = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let existingCreditCardVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          existingCreditCardVal += data[year].InterestExpenses['Existing Credit Card Debt'];
        }
      });
    }
    module4Data.existingCreditCardInterestInterest = existingCreditCardVal;
  }
  return module4Data.existingCreditCardInterestInterest;
}

// Student Loan Principal Interest
export function studentLoanPrincipalInterest() {
  module4Data.studentLoanPrincipalInterest = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let studentLoanPrinicipalVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          studentLoanPrinicipalVal += data[year].InterestExpenses['Student Loans'];
        }
      });
    }
    module4Data.studentLoanPrincipalInterest = studentLoanPrinicipalVal;
  }
  return module4Data.studentLoanPrincipalInterest;
}
// Car Loan Interest
export function carLoanInterest() {
  module4Data.carLoanInterest = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let carLoanInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          carLoanInterestVal += data[year].InterestExpenses['Car Loans'];
        }
      });
    }

    module4Data.carLoanInterest = carLoanInterestVal;
  }
  return module4Data.carLoanInterest;
}
// Mortgage Interest
export function mortgageInterest() {
  module4Data.mortgageInterest = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let mortgageInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          mortgageInterestVal += data[year].InterestExpenses.Mortgages;
        }
      });
    }

    module4Data.mortgageInterest = mortgageInterestVal;
  }
  return module4Data.mortgageInterest;
}
// Total Financing Expenses
export function totalFinancingExpenses() {
  module4Data.totalFinancingExpenses = module4Data.existingCreditCardInterestInterest
    + module4Data.studentLoanPrincipalInterest
    + module4Data.carLoanInterest
    + module4Data.mortgageInterest;
  return module4Data.totalFinancingExpenses;
}

// Income Earned
export function incomeEarned() {
  module4Data.incomeEarned = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let incomeEarnedVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          incomeEarnedVal += data[year]['Total Income'];
        }
      });
    }

    module4Data.incomeEarned = incomeEarnedVal;
  }
  return module4Data.incomeEarned;
}
// Living Expenses Paid
export function livingExpensesPaid() {
  module4Data.livingExpensesPaid = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let livingExpensesRent = 0;
    let livingExpensesUtilities = 0;
    let livingExpensesFood = 0;
    let livingExpensesClothing = 0;
    let livingExpensesEntertainment = 0;
    let livingExpensesTechnology = 0;
    let livingExpensesTransportation = 0;
    let livingExpensesMiscellaneous = 0;
    let livingExpensesHealthCare = 0;
    let livingExpensesHealthInsurance = 0;
    let livingExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          livingExpensesRent += data[year].livingExpenses.Rent;
          livingExpensesUtilities += data[year].livingExpenses.Utilities;
          livingExpensesFood += data[year].livingExpenses.Food;
          livingExpensesClothing += data[year].livingExpenses.Clothing;
          livingExpensesEntertainment += data[year].livingExpenses.Entertainment;
          livingExpensesTechnology += data[year].livingExpenses.Technology;
          livingExpensesTransportation += data[year].livingExpenses.Transportation;
          livingExpensesMiscellaneous += data[year].livingExpenses.Miscellaneous;
          livingExpensesHealthCare += data[year].livingExpenses['Healthcare Costs other than Premium'];
          livingExpensesHealthInsurance += data[year].livingExpenses['Health Insurance Premium'];
        }
      });
    }
    livingExpensesPaidVal = livingExpensesRent + livingExpensesUtilities + livingExpensesFood + livingExpensesClothing + livingExpensesEntertainment + livingExpensesTechnology + livingExpensesTransportation + livingExpensesMiscellaneous + livingExpensesHealthCare + livingExpensesHealthInsurance;
    module4Data.livingExpensesPaid = livingExpensesPaidVal;
  }
  return module4Data.livingExpensesPaid;
}

// Higher Education Expenses
export function higherEducationExpensesPaid() {
  module4Data.higherEducationExpensesPaid = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let higherEducationExpensesTuition = 0;
    let higherEducationExpensesRoom = 0;
    let higherEducationExpensesTuitionOffCampusFood = 0;
    let higherEducationExpensesVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          higherEducationExpensesTuition += data[year].livingExpenses['Higher Education Tuition and Fees'];
          higherEducationExpensesRoom += data[year].livingExpenses['Higher Education Room & Board'];
          higherEducationExpensesTuitionOffCampusFood += data[year].livingExpenses['Off Campus Food'];
        }
      });
    }
    higherEducationExpensesVal = higherEducationExpensesTuition + higherEducationExpensesRoom + higherEducationExpensesTuitionOffCampusFood;
    module4Data.higherEducationExpensesPaid = higherEducationExpensesVal;
  }
  return module4Data.higherEducationExpensesPaid;
}

// Children Expenses
export function childrenExpensesPaid() {
  module4Data.childrenExpensesPaid = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let childrenExpensesTotal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          childrenExpensesTotal += data[year].livingExpenses.Children;
        }
      });
    }
    module4Data.childrenExpensesPaid = childrenExpensesTotal;
  }
  return module4Data.childrenExpensesPaid;
}

// Children Expenses
export function petExpensesPaid() {
  module4Data.petExpensesPaid = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let petExpensesTotal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          petExpensesTotal += data[year].livingExpenses.Pet;
        }
      });
    }
    module4Data.petExpensesPaid = petExpensesTotal;
  }
  return module4Data.petExpensesPaid;
}

// Car Expenses Paid
export function carExpensesPaid() {
  module4Data.carExpensesPaid = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let carExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          carExpensesPaidVal += data[year]['Total Car Expenses'];
        }
      });
    }

    module4Data.carExpensesPaid = carExpensesPaidVal;
  }
  return module4Data.carExpensesPaid;
}
// Real Estate Expenses Paid
export function realEstateExpensesPaid() {
  module4Data.realEstateExpensesPaid = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let realEstateExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          realEstateExpensesPaidVal += data[year]['Total Real Estate Expenses'];
        }
      });
    }

    module4Data.realEstateExpensesPaid = realEstateExpensesPaidVal;
  }
  return module4Data.realEstateExpensesPaid;
}
// Financing Expenses Paid
export function financingExpensesPaid() {
  module4Data.financingExpensesPaid = 0;
  let financingPaid1 = 0;
  let financingPaidFinal = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          financingPaid1 += data[year]['Total Interest Expenses'];
        }
      });
    }

    financingPaidFinal = financingPaid1;
  }
  module4Data.financingExpensesPaid = financingPaidFinal;

  return module4Data.financingExpensesPaid;
}
// Estimated Taxes Paid
export function estimatedTaxesPaid() {
  module4Data.estimatedTaxesPaid = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = incomeStatementData;
    let estimatedPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          estimatedPaidVal += data[year]['Total Taxes'];
        }
      });
    }

    module4Data.estimatedTaxesPaid = estimatedPaidVal;
  }
  return module4Data.estimatedTaxesPaid;
}
// Net Income
export function netIncomePaid() {
  module4Data.netIncomePaid = 0;
  if (module4Data.incomeEarned !== undefined && module4Data.totalExpensesPaid !== undefined) {
    module4Data.netIncomePaid = module4Data.incomeEarned + module4Data.totalExpensesPaid;
  }
  return module4Data.netIncomePaid;
}

// Retirement Contributions
export function finalRetirementContributionsCareerPath() {
  module4Data.finalRetirementContributionsCareerPath = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['Retirement Contributions'];
        }
      });
    }

    module4Data.finalRetirementContributionsCareerPath = -retirementContributionValue;
  }
  return module4Data.finalRetirementContributionsCareerPath;
}

// Retirement Contributions
export function finalHsaContributionsCareerPath() {
  module4Data.finalHsaContributionsCareerPath = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== '') {
    const start_year = parseInt(module4Data.start_year, 10);
    const endYear = parseInt(module4Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['HSA Contributions'];
        }
      });
    }

    module4Data.finalHsaContributionsCareerPath = -retirementContributionValue;
  }
  return module4Data.finalHsaContributionsCareerPath;
}

// Net Cash Flow for Savings and Investments
export function finalNetCashFlowCareerPath() {
  module4Data.finalNetCashFlowCareerPath = 0;
  if (module4Data.netIncomePaid !== undefined && module4Data.finalRetirementContributionsCareerPath !== undefined) {
    module4Data.finalNetCashFlowCareerPath = module4Data.netIncomePaid - module4Data.finalRetirementContributionsCareerPath;
  }
  return module4Data.finalNetCashFlowCareerPath;
}

// Single Estimated Tax Rate
export function singleEstimatedTaxRate() {
  module4Data.singleEstimatedTaxRate = 0;
  if (module4Data.monthlyLivingExpenses !== undefined
    && module4Data.monthlyLivingExpenses > 0) {
    // module4Data.singleEstimatedTaxRate = estimatedIncomeTaxes.singleIteration2();
  }

  return parseFloat(module4Data.singleEstimatedTaxRate).toFixed(1);
}
// Single Estimated Taxes Paid
export function singleEstimatedTaxesPaid() {
  module4Data.singleEstimatedTaxesPaid = '0';
  if (module4Data.incomeEarned !== undefined && module4Data.incomeEarned !== ''
    && module4Data.singleEstimatedTaxRate !== undefined && module4Data.singleEstimatedTaxRate !== '') {
    module4Data.singleEstimatedTaxesPaid = -module4Data.incomeEarned * (module4Data.singleEstimatedTaxRate / 100);
  }
  return module4Data.singleEstimatedTaxesPaid;
}
// Single Income After Living Expenses
export function singleIncomeAfterLivingExpenses() {
  module4Data.singleIncomeAfterLivingExpenses = module4Data.incomeEarned
    + module4Data.livingExpensesPaid
    + module4Data.financingExpensesPaid
    + module4Data.singleEstimatedTaxesPaid;
  return module4Data.singleIncomeAfterLivingExpenses;
}

// Married Estimated Tax Rate
export function marriedEstimatedTaxRate() {
  module4Data.marriedEstimatedTaxRate = 0;
  if (module4Data.monthlyLivingExpenses !== undefined
    && module4Data.monthlyLivingExpenses > 0) {
    // module4Data.marriedEstimatedTaxRate = estimatedIncomeTaxes.marriedIteration2();
  }

  return parseFloat(module4Data.marriedEstimatedTaxRate).toFixed(1);
}
// Married Estimated Taxes Paid
export function marriedEstimatedTaxesPaid() {
  module4Data.marriedEstimatedTaxesPaid = '0';
  if (module4Data.incomeEarned !== undefined && module4Data.incomeEarned !== ''
    && module4Data.singleEstimatedTaxRate !== undefined && module4Data.singleEstimatedTaxRate !== '') {
    module4Data.marriedEstimatedTaxesPaid = -module4Data.incomeEarned * (module4Data.marriedEstimatedTaxRate / 100);
  }
  return module4Data.marriedEstimatedTaxesPaid;
}
// Married Income After Living Expenses
export function marriedIncomeAfterLivingExpenses() {
  module4Data.marriedIncomeAfterLivingExpenses = module4Data.incomeEarned
    + module4Data.livingExpensesPaid
    + module4Data.financingExpensesPaid
    + module4Data.marriedEstimatedTaxesPaid;
  return module4Data.marriedIncomeAfterLivingExpenses;
}

//New Updates on Retirement accounts add by phrudhvi

export function BeginningRothBalanceOf401k() {
  module4Data.BeginningRothBalanceOf401k = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k Second'] !== undefined && investmentsData['Roth 401k Second'] !== '' && investmentsData['Roth 401k Second'][module4Data.start_year] !== undefined && investmentsData['Roth 401k Second'][module4Data.start_year] !== '') {
      module4Data.BeginningRothBalanceOf401k = investmentsData['Roth 401k Second'][module4Data.start_year]['Total Beginning Balance'];

    }
  }
  return module4Data.BeginningRothBalanceOf401k;
}

//Personal Contribution as a % of Base Income Calcs
export function personalContributionBaseIncomeRothCalcs() {
  module4Data.personalContributionBaseIncomeRothCalcs = 0;
  let firstEle = 0;
  let firstEle1 = 0;
  let firstEle2 = 0;
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.base_roth_income !== undefined && module4Data.base_roth_income !== '') {
    firstEle1 = (module4Data.totalPreTaxExpected_val / 100) * module4Data.base_roth_income;
  }
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.base_income !== undefined && module4Data.base_income !== '') {
    firstEle2 = (module4Data.totalPreTaxExpected_val / 100) * module4Data.base_income;
  }
  firstEle = firstEle1 + firstEle2;
  if (module4Data.roth_contribute_401k !== undefined && module4Data.roth_contribute_401k === 'Yes' && module4Data.careerPathPersonalContribution !== undefined && module4Data.careerPathPersonalContribution !== '' && firstEle <= module4Data.careerPathPersonalContribution) {
    module4Data.personalContributionBaseIncomeRothCalcs = firstEle1;
  } else {
    let base_income = 0;
    if (module4Data.base_income !== undefined && module4Data.base_income !== '') {
      base_income = parseFloat(module4Data.base_income);
    }
    let base_roth_income = 0;
    if (module4Data.base_roth_income !== undefined && module4Data.base_roth_income !== '') {
      base_roth_income = parseFloat(module4Data.base_roth_income);
    }

    if (module4Data.careerPathPersonalContribution !== undefined && module4Data.careerPathPersonalContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module4Data.personalContributionBaseIncomeRothCalcs = (base_roth_income / (base_income + base_roth_income)) * module4Data.careerPathPersonalContribution;
      } else {
        module4Data.personalContributionBaseIncomeRothCalcs = 0;
      }
    } else {
      module4Data.personalContributionBaseIncomeRothCalcs = 0;
    }

  }
  return module4Data.personalContributionBaseIncomeRothCalcs;
}

export function careerPathRothIRAPersonalContributionCalcsRoth() {
  module4Data.careerPathRothIRAPersonalContributionCalcsRoth = 0;
  var firstEle = 0;
  var firstEle1 = 0;
  var firstEle2 = 0;
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.roth_personal_contribution !== undefined && module4Data.roth_personal_contribution !== '') {
    firstEle1 = (module4Data.totalPreTaxExpected_val / 100) * module4Data.roth_personal_contribution;
  }
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.ira_roth_personal_contribution !== undefined && module4Data.ira_roth_personal_contribution !== '') {
    firstEle2 = (module4Data.totalPreTaxExpected_val / 100) * module4Data.ira_roth_personal_contribution;
  }
  firstEle = firstEle1 + firstEle2;
  if (module4Data.rothIraContribute !== undefined && module4Data.rothIraContribute === 'Yes' && module4Data.careerPathRothIRAPersonalContribution !== undefined && module4Data.careerPathRothIRAPersonalContribution !== '' && firstEle <= module4Data.careerPathRothIRAPersonalContribution) {
    module4Data.careerPathRothIRAPersonalContributionCalcsRoth = firstEle2;
  } else {
    var base_income = 0;
    var base_roth_income = 0;
    if (module4Data.roth_personal_contribution !== undefined && module4Data.roth_personal_contribution !== '') {
      base_income = parseFloat(module4Data.roth_personal_contribution);
    }
    if (module4Data.ira_roth_personal_contribution !== undefined && module4Data.ira_roth_personal_contribution !== '') {
      base_roth_income = parseFloat(module4Data.ira_roth_personal_contribution);
    }
    if (module4Data.careerPathRothIRAPersonalContribution !== undefined && module4Data.careerPathRothIRAPersonalContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module4Data.careerPathRothIRAPersonalContributionCalcsRoth = (base_roth_income / (base_income + base_roth_income)) * module4Data.careerPathRothIRAPersonalContribution;
      } else {
        module4Data.careerPathRothIRAPersonalContributionCalcsRoth = 0;
      }
    } else {
      module4Data.careerPathRothIRAPersonalContributionCalcsRoth = 0;
    }
  }
  return module4Data.careerPathRothIRAPersonalContributionCalcsRoth;
}

export function careerPathTotalIraContributions() {
  module4Data.careerPathTotalIraContributions = 0;
  if (module4Data.careerPathRothIRAPersonalContributionCalcs !== undefined && module4Data.careerPathRothIRAPersonalContributionCalcsRoth !== undefined) {
    module4Data.careerPathTotalIraContributions = module4Data.careerPathRothIRAPersonalContributionCalcs + module4Data.careerPathRothIRAPersonalContributionCalcsRoth;
  }
  return module4Data.careerPathTotalIraContributions;
}

/* SEP IRA Retirement Summary */

export function careerPathiraBeginningBalance() {
  module4Data.iraBeginningBalance = 0;
  if (module4Data.start_year !== undefined &&
    module4Data.start_year !== '' &&
    investmentsData !== undefined &&
    investmentsData !== '' &&
    investmentsData['Seph IRA Second'] !== undefined &&
    investmentsData['Seph IRA Second'] !== '' &&
    investmentsData['Seph IRA Second'][module4Data.start_year] !== undefined &&
    investmentsData['Seph IRA Second'][module4Data.start_year]['Beginning Balance'] !== undefined) {
    module4Data.iraBeginningBalance = investmentsData['Seph IRA Second'][module4Data.start_year]['Beginning Balance'];
  }
  return module4Data.iraBeginningBalance;
}

/*Pension Summary*/
//Beginning Balance Value
export function careerPathPensionBalance() {
  module4Data.pensionBeginningBalance = 0;
  if (module4Data.start_year !== undefined &&
    module4Data.start_year !== '' &&
    investmentsData !== undefined &&
    investmentsData !== '' &&
    investmentsData['Pension Second'] !== undefined &&
    investmentsData['Pension Second'] !== '' &&
    investmentsData['Pension Second'][module4Data.start_year] !== undefined &&
    investmentsData['Pension Second'][module4Data.start_year]['Beginning Balance'] !== undefined) {
    module4Data.pensionBeginningBalance = investmentsData['Pension Second'][module4Data.start_year]['Beginning Balance'];
  }
  return module4Data.pensionBeginningBalance;

}

export function rothBeginningBalance() {
  module4Data.rothBeginningBalance = 0;
  if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Traditional IRA Second'] !== undefined && investmentsData['Retirement Accounts Traditional IRA Second'] !== '' && investmentsData['Retirement Accounts Traditional IRA Second'][module4Data.start_year] !== undefined && investmentsData['Retirement Accounts Traditional IRA Second'][module4Data.start_year] !== '') {
    module4Data.rothBeginningBalance = investmentsData['Retirement Accounts Traditional IRA Second'][module4Data.start_year]['Beginning Balance'];
  }
  return module4Data.rothBeginningBalance;
}

// Roth IRA Beginning Balance
export function rothBeginningBalanceCal() {
  module9Data.rothBeginningBalanceCal = 0;
  if (module9Data.rothBeginningBalance !== undefined && module9Data.rothBeginningBalance !== '') {
    module9Data.rothBeginningBalanceCal = module9Data.rothBeginningBalance;
  }
  return module9Data.rothBeginningBalanceCal;
}

export function rothBeginningBalanceIra() {
  module4Data.rothBeginningBalanceIra = 0;
  if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts Traditional IRA Second'] !== undefined && investmentsData['Retirement Accounts Traditional IRA Second'] !== '' && investmentsData['Retirement Accounts Traditional IRA Second'][module4Data.start_year] !== undefined && investmentsData['Retirement Accounts Traditional IRA Second'][module4Data.start_year] !== '') {
    module4Data.rothBeginningBalanceIra = investmentsData['Retirement Accounts Traditional IRA Second'][module4Data.start_year]['Beginning Balance'];
  }
  return module4Data.rothBeginningBalanceIra;
}

export function pathCareerHsaBeginningBalance() {
  module4Data.pathCareerHsaBeginningBalance = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['HSA Second'] !== undefined && investmentsData['HSA Second'] !== '' && investmentsData['HSA Second'][module4Data.start_year] !== undefined && investmentsData['HSA Second'][module4Data.start_year] !== '' && investmentsData['HSA Second'][module4Data.start_year]['Beginning Balance'] !== undefined) {
    module4Data.pathCareerHsaBeginningBalance = investmentsData['HSA Second'][module4Data.start_year]['Beginning Balance'];
  }
  return module4Data.pathCareerHsaBeginningBalance;
}

export function careerPathHsaTotalEndingBalanceSummary() {
  module4Data.careerPathHsaTotalEndingBalanceSummary = 0;
  if (module4Data.contribute_hsa_savings_account === 'Yes') {
    module4Data.careerPathHsaTotalEndingBalanceSummary = module4Data.careerPathHsaBeginningBalanceSummary + module4Data.careerPathHsaTotalContributionSummary + module4Data.careerPathHsaTotalEarningsSummary;
  }
  return module4Data.careerPathHsaTotalEndingBalanceSummary;
}

export function careerPathHsaPersonalContributionCalcs() {
  module4Data.careerPathHsaPersonalContributionCalcs = 0;
  var contributionVal = 0;
  if (module4Data.hsa_personal_contribution !== undefined && module4Data.hsa_personal_contribution !== '' && module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '') {
    contributionVal = module4Data.totalPreTaxExpected_val * (module4Data.hsa_personal_contribution / 100)
  }
  if (module4Data.maxHsaContributionByLawCareerPath !== undefined && contributionVal > module4Data.maxHsaContributionByLawCareerPath) {
    module4Data.careerPathHsaPersonalContributionCalcs = module4Data.maxHsaContributionByLawCareerPath;
  } else {
    module4Data.careerPathHsaPersonalContributionCalcs = contributionVal;
  }

  return module4Data.careerPathHsaPersonalContributionCalcs;
}

export function furtherCareerSEPIRAPersonalContributionCalcsNew() {
  module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew = 0;
  var incomeValue = 0;
  if (module17Data.hsa_personal_contribution !== undefined && module17Data.hsa_personal_contribution !== '' && module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '') {
    incomeValue = ((module17Data.jobtotalPreTaxExpected_val) / 100) * module17Data.hsa_personal_contribution;
  }
  if (module17Data.maxHsaContributionByLawFurtherCareer !== undefined && incomeValue > module17Data.maxHsaContributionByLawFurtherCareer) {
    module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew = module17Data.maxHsaContributionByLawFurtherCareer;
  } else {
    module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew = incomeValue;
  }
  return module17Data.furtherCareerSEPIRAPersonalContributionCalcsNew;
}

export function maxHsaContributionByLawCareerPath() {
  module4Data.maxHsaContributionByLawCareerPath = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['HSA Limits'][module4Data.start_year] !== undefined) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && parseInt(module4Data.start_year) >= parseInt(module10Data.marriege_year)) {
      if (databaseData['HSA Limits'][module4Data.start_year]['Married'] !== undefined && databaseData['HSA Limits'][module4Data.start_year]['Married'] !== '') {
        module4Data.maxHsaContributionByLawCareerPath = databaseData['HSA Limits'][module4Data.start_year]['Married'];
      }
    } else {
      if (databaseData['HSA Limits'][module4Data.start_year]['Single'] !== undefined && databaseData['HSA Limits'][module4Data.start_year]['Single'] !== '') {
        module4Data.maxHsaContributionByLawCareerPath = databaseData['HSA Limits'][module4Data.start_year]['Single'];
      }
    }
  }
  return module4Data.maxHsaContributionByLawCareerPath;
}

export function careerPathHsaBeginningBalanceSummary() {
  module4Data.careerPathHsaBeginningBalanceSummary = 0;
  if (module4Data.contribute_hsa_savings_account !== undefined && module4Data.contribute_hsa_savings_account === 'Yes') {
    module4Data.careerPathHsaBeginningBalanceSummary = module4Data.pathCareerHsaBeginningBalance;
  }
  return module4Data.careerPathHsaBeginningBalanceSummary;
}

export function careerPathHsaTotalContributionSummary() {
  module4Data.careerPathHsaTotalContributionSummary = 0;
  if (module4Data.contribute_hsa_savings_account === 'Yes') {
    var personalContributionVal = 0;
    var data = investmentsData['HSA Second'];
    var start_year = module4Data.start_year;
    var endYear = module4Data.end_year;

    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalContributionVal += data[year]['Annual Contribution'];
        }
      })
    }
    module4Data.careerPathHsaTotalContributionSummary = personalContributionVal;
  }
  return module4Data.careerPathHsaTotalContributionSummary;
}
export function careerPathHsaTotalEarningsSummary() {
  module4Data.careerPathHsaTotalEarningsSummary = 0;
  if (module4Data.contribute_hsa_savings_account === 'Yes') {
    var personalContributionVal = 0;
    var data = investmentsData['HSA Second'];
    var start_year = module4Data.start_year;
    var endYear = module4Data.end_year;

    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalContributionVal += data[year]['Earnings'];
        }
      })
    }
    module4Data.careerPathHsaTotalEarningsSummary = personalContributionVal;
  }
  return module4Data.careerPathHsaTotalEarningsSummary;
}

export function beginningBalanceInYearRoth() {
  module4Data.beginningBalanceInYearRoth = 0;
  if (module4Data.roth_contribute_401k !== undefined && module4Data.roth_contribute_401k === 'Yes' && module4Data.BeginningRothBalanceOf401k !== undefined) {
    module4Data.beginningBalanceInYearRoth = module4Data.BeginningRothBalanceOf401k;
  }

  return module4Data.beginningBalanceInYearRoth;
}

export function personalContribuitionafterYearsRoth() {
  module4Data.personalContribuitionafterYearsRoth = 0;
  var personalContributionVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module4Data.start_year;
  var endYear = module4Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        personalContributionVal += data[year]['Personal Contribution'];
      }
    })
  }
  module4Data.personalContribuitionafterYearsRoth = personalContributionVal;

  return module4Data.personalContribuitionafterYearsRoth;
}

export function earningOnPersonalContributionRoth() {
  module4Data.earningOnPersonalContributionRoth = 0;
  var personalEarningsVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module4Data.start_year;
  var endYear = module4Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        personalEarningsVal += data[year]['Personal Earnings'];
      }
    })
  }
  module4Data.earningOnPersonalContributionRoth = personalEarningsVal;

  return module4Data.earningOnPersonalContributionRoth;
}

export function totalPersonalContributionEarningsRoth() {
  module4Data.totalPersonalContributionEarningsRoth = 0;
  module4Data.totalPersonalContributionEarningsRoth = module4Data.personalContribuitionafterYearsRoth + module4Data.earningOnPersonalContributionRoth;
  return module4Data.totalPersonalContributionEarningsRoth;
}

export function employerContributionAfterYearsRoth() {
  module4Data.employerContributionAfterYearsRoth = 0;
  var personalEarningsVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module4Data.start_year;
  var endYear = module4Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        personalEarningsVal += data[year]['Employer Contribution'];
      }
    })
  }
  module4Data.employerContributionAfterYearsRoth = personalEarningsVal;

  return module4Data.employerContributionAfterYearsRoth;
}

export function earningsOnEmployerContributionAfterYearsRoth() {
  module4Data.earningsOnEmployerContributionAfterYearsRoth = 0;
  var employerEarningsVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module4Data.start_year;
  var endYear = module4Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        employerEarningsVal += data[year]['Employer Earnings'];
      }
    })
  }

  module4Data.earningsOnEmployerContributionAfterYearsRoth = employerEarningsVal;

  return module4Data.earningsOnEmployerContributionAfterYearsRoth;
}

export function totalEmployersContributionsEarningsRoth() {
  module4Data.totalEmployersContributionsEarningsRoth = 0;
  module4Data.totalEmployersContributionsEarningsRoth = module4Data.employerContributionAfterYearsRoth + module4Data.earningsOnEmployerContributionAfterYearsRoth;
  return module4Data.totalEmployersContributionsEarningsRoth;
}

export function balanceBeforeVestingReductionsCareerPathRoth() {
  module4Data.balanceBeforeVestingReductionsRoth = 0;
  module4Data.balanceBeforeVestingReductionsRoth = module4Data.beginningBalanceInYearRoth + module4Data.totalPersonalContributionEarningsRoth + module4Data.totalEmployersContributionsEarningsRoth;
  return module4Data.balanceBeforeVestingReductionsRoth;
}

export function employerContributionsEarningsNotVestedRoth() {
  module4Data.employerContributionsEarningsNotVestedRoth = 0;
  if (module4Data.roth_contribute_401k !== undefined && module4Data.roth_contribute_401k === 'Yes' && module4Data.vested_amount_at_end_year !== undefined) {
    module4Data.employerContributionsEarningsNotVestedRoth = ((module4Data.totalEmployersContributionsEarningsRoth / 100) * - (1 - (module4Data.vested_amount_at_end_year / 100))) * 100;
  }
  return module4Data.employerContributionsEarningsNotVestedRoth;
}

export function ending401BalanceRoth() {
  module4Data.ending401BalanceRoth = 0;
  module4Data.ending401BalanceRoth = module4Data.balanceBeforeVestingReductionsRoth + module4Data.employerContributionsEarningsNotVestedRoth;
  return module4Data.ending401BalanceRoth;
}

export function careerPathRothIRATotalContributionRoth() {
  module4Data.careerPathRothIRATotalContributionRoth = 0;
  var totalContributionsVal = 0;
  var data = investmentsData['Roth IRA Second'];
  var start_year = module4Data.start_year;
  var endYear = module4Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalContributionsVal += data[year]['Annual Contribution'];
      }
    })
  }
  module4Data.careerPathRothIRATotalContributionRoth = totalContributionsVal;

  return module4Data.careerPathRothIRATotalContributionRoth;
}

export function careerPathRothIRATotalEarningsRoth() {
  module4Data.careerPathRothIRATotalEarningsRoth = 0;
  var totalEarningsVal = 0;
  var data = investmentsData['Roth IRA Second'];
  var start_year = module4Data.start_year;
  var endYear = module4Data.incomeEndYear;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalEarningsVal += data[year]['Earnings'];
      }
    })
  }

  module4Data.careerPathRothIRATotalEarningsRoth = totalEarningsVal;

  return module4Data.careerPathRothIRATotalEarningsRoth;
}

export function careerPathRothIRAEndingBalanceRoth() {
  module4Data.careerPathRothIRAEndingBalanceRoth = 0;
  if (module4Data.careerPathRothIRATotalEarningsRoth !== undefined && module4Data.careerPathRothIRATotalContributionRoth !== undefined && module4Data.rothBeginningBalanceIra !== undefined) {
    module4Data.careerPathRothIRAEndingBalanceRoth = module4Data.careerPathRothIRATotalEarningsRoth + module4Data.careerPathRothIRATotalContributionRoth + module4Data.rothBeginningBalanceIra;
  }
  return module4Data.careerPathRothIRAEndingBalanceRoth;
}
//end of adding methods

//Personal Contribution as a % of Base Income Calcs
export function personalContributionBaseIncomeCalcs() {
  module4Data.personalContributionBaseIncomeCalcs = 0;
  var firstEle = 0;
  var firstEle1 = 0;
  var firstEle2 = 0;
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.base_income !== undefined && module4Data.base_income !== '') {
    firstEle1 = (module4Data.totalPreTaxExpected_val / 100) * module4Data.base_income;
  }
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.base_roth_income !== undefined && module4Data.base_roth_income !== '') {
    firstEle2 = (module4Data.totalPreTaxExpected_val / 100) * module4Data.base_roth_income;
  }
  firstEle = firstEle1 + firstEle2;
  if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.careerPathPersonalContribution !== undefined && module4Data.careerPathPersonalContribution !== '' && firstEle <= module4Data.careerPathPersonalContribution) {
    module4Data.personalContributionBaseIncomeCalcs = firstEle1;
  } else {
    var base_income = 0;
    if (module4Data.base_income !== undefined && module4Data.base_income !== '') {
      base_income = parseFloat(module4Data.base_income);
    }
    var base_roth_income = 0;
    if (module4Data.base_roth_income !== undefined && module4Data.base_roth_income !== '') {
      base_roth_income = parseFloat(module4Data.base_roth_income);
    }

    if (module4Data.careerPathPersonalContribution !== undefined && module4Data.careerPathPersonalContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module4Data.personalContributionBaseIncomeCalcs = (base_income / (base_income + base_roth_income)) * module4Data.careerPathPersonalContribution;
      } else {
        module4Data.personalContributionBaseIncomeCalcs = 0;
      }
    } else {
      module4Data.personalContributionBaseIncomeCalcs = 0;
    }
  }
  return module4Data.personalContributionBaseIncomeCalcs;
}
// Employer Matching Rate on Personal Contribution
export function employerMatchingrateCalcs() {
  module4Data.employerMatchingrateCalcs = 0;
  if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.personalContributionBaseIncomeCalcs !== undefined && module4Data.total401kContributions !== '' && module4Data.employer_matching_rate !== undefined && module4Data.employer_matching_rate !== '') {
    module4Data.employerMatchingrateCalcs = (module4Data.total401kContributions / 100) * module4Data.employer_matching_rate;
  }
  return module4Data.employerMatchingrateCalcs;
}

// Employer Matching Limit as a % of Base Income
export function employerMatchingLimitCalcs() {
  module4Data.employerMatchingLimitCalcs = 0;
  if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.employer_matching_limit !== undefined && module4Data.employer_matching_limit !== '') {
    module4Data.employerMatchingLimitCalcs = (module4Data.totalPreTaxExpected_val / 100) * module4Data.employer_matching_limit;
  }
  return module4Data.employerMatchingLimitCalcs;
}

// Personal Contribution (Max of $00 by law)
export function careerPathPersonalContribution401k() {
  module4Data.careerPathPersonalContribution_401k = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year]['Max Personal Limit'] !== undefined) {
    module4Data.careerPathPersonalContribution_401k = databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year]['Max Personal Limit'];
  } else {
    module4Data.careerPathPersonalContribution_401k = 0;
  }
  return module4Data.careerPathPersonalContribution_401k;
}

export function careerPathPersonalContribution() {
  module4Data.careerPathPersonalContribution = 0;
  if (module4Data.personalContributionBaseIncomeCalcs !== undefined && module4Data.personalContributionBaseIncomeCalcs !== ''
    && module4Data.start_year !== undefined && module4Data.start_year !== ''
    && databaseData !== undefined && databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year]['Max Personal Limit'] !== undefined) {
    module4Data.careerPathPersonalContribution = databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year]['Max Personal Limit'];
  }
  return module4Data.careerPathPersonalContribution;
}

//Total Contributions
export function total401kContributions() {
  module4Data.total401kContributions = 0;
  if (module4Data.personalContributionBaseIncomeCalcs !== undefined && module4Data.personalContributionBaseIncomeRothCalcs !== undefined) {
    module4Data.total401kContributions = module4Data.personalContributionBaseIncomeCalcs + module4Data.personalContributionBaseIncomeRothCalcs;
  }
  return module4Data.total401kContributions;
}

// Employer Contribution
export function employerContribution() {
  module4Data.employerContribution = 0;
  let employerContribution1 = 0;
  let employerContribution2 = 0;
  let employerContribution3 = 0;

  if (module4Data.employerMatchingrateCalcs !== undefined && module4Data.employerMatchingrateCalcs !== ''
    && module4Data.employerMatchingLimitCalcs !== undefined && module4Data.employerMatchingLimitCalcs !== ''
    && module4Data.employerMatchingrateCalcs > module4Data.employerMatchingLimitCalcs) {
    employerContribution1 = module4Data.employerMatchingLimitCalcs;
  } else {
    employerContribution1 = module4Data.employerMatchingrateCalcs;
  }
  if (module4Data.careerPathPersonalContribution !== undefined && module4Data.careerPathPersonalContribution !== '') {
    employerContribution2 = module4Data.careerPathPersonalContribution;
  }
  employerContribution3 = employerContribution1 + employerContribution2;

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && databaseData !== undefined && databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year]['Total Limit'] !== undefined
    && employerContribution3 > databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year]['Total Limit']) {
    module4Data.employerContribution = databaseData['401(k) 2018 Contribution Limits'][module4Data.start_year]['Total Limit'] - employerContribution2;
  } else if (module4Data.employerMatchingrateCalcs !== undefined && module4Data.employerMatchingrateCalcs !== ''
    && module4Data.employerMatchingLimitCalcs !== undefined && module4Data.employerMatchingLimitCalcs !== ''
    && module4Data.employerMatchingrateCalcs > module4Data.employerMatchingLimitCalcs) {
    module4Data.employerContribution = module4Data.employerMatchingLimitCalcs;
  } else {
    module4Data.employerContribution = module4Data.employerMatchingrateCalcs;
  }
  return module4Data.employerContribution;
}

// Total Annual Contribution Value
export function totalAnnualContribution() {
  module4Data.totalAnnualContribution = 0;
  if (module4Data.total401kContributions !== undefined
    && module4Data.employerContribution !== undefined) {
    module4Data.totalAnnualContribution = module4Data.total401kContributions + module4Data.employerContribution;
  }
  return module4Data.totalAnnualContribution;
}

// Beginning Balance In year
export function beginningBalanceInYear() {
  module4Data.beginningBalanceInYear = 0;
  if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.BeginningBalanceOf401k !== undefined && module4Data.BeginningBalanceOf401k !== undefined) {
    module4Data.beginningBalanceInYear = module4Data.BeginningBalanceOf401k;
  }
  return module4Data.beginningBalanceInYear;
}

// Beginning Balance In year
export function personalContribuitionafterYears() {
  module4Data.personalContribuitionafterYears = 0;
  let personalContributionVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalContributionVal += data[year]['Personal Contribution'];
        }
      });
    }
  }
  module4Data.personalContribuitionafterYears = personalContributionVal;

  return module4Data.personalContribuitionafterYears;
}

// Earnings on Personal Contributions After 4 Years
export function earningOnPersonalContribution() {
  module4Data.earningOnPersonalContribution = 0;
  let personalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalEarningsVal += data[year]['Personal Earnings'];
        }
      });
    }
  }

  module4Data.earningOnPersonalContribution = personalEarningsVal;

  return module4Data.earningOnPersonalContribution;
}

// Total Personal Contributions & Earnings
export function totalPersonalContributionEarnings() {
  module4Data.totalPersonalContributionEarnings = 0;
  module4Data.totalPersonalContributionEarnings = module4Data.personalContribuitionafterYears + module4Data.earningOnPersonalContribution;
  return module4Data.totalPersonalContributionEarnings;
}

// Employer Contributions After 4 Years
export function employerContributionAfterYears() {
  module4Data.employerContributionAfterYears = 0;
  let personalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalEarningsVal += data[year]['Employer Contribution'];
        }
      });
    }
  }
  module4Data.employerContributionAfterYears = personalEarningsVal;

  return module4Data.employerContributionAfterYears;
}

// Earnings on Employer Contributions After 4 Years
export function earningsOnEmployerContributionAfterYears() {
  module4Data.earningsOnEmployerContributionAfterYears = 0;
  let employerEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerEarningsVal += data[year]['Employer Earnings'];
        }
      });
    }
  }
  module4Data.earningsOnEmployerContributionAfterYears = employerEarningsVal;

  return module4Data.earningsOnEmployerContributionAfterYears;
}

// Total Employer Contributions & Earnings
export function totalEmployersContributionsEarnings() {
  module4Data.totalEmployersContributionsEarnings = 0;
  module4Data.totalEmployersContributionsEarnings = module4Data.employerContributionAfterYears + module4Data.earningsOnEmployerContributionAfterYears;
  return module4Data.totalEmployersContributionsEarnings;
}

// 401(k) Balance before Vesting Reductions
export function balanceBeforeVestingReductionsCareerPath() {
  module4Data.balanceBeforeVestingReductions = 0;
  module4Data.balanceBeforeVestingReductions = module4Data.beginningBalanceInYear + module4Data.totalPersonalContributionEarnings + module4Data.totalEmployersContributionsEarnings;
  return module4Data.balanceBeforeVestingReductions;
}

// Vested After Few years
export function vestedAfterFewyears() {
  module4Data.vestedAfterFewyears = 0;
  if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.start_year !== undefined && module4Data.start_year !== '') {
    if (databaseData !== undefined && databaseData !== '') {
      module4Data.vestedAfterFewyears = databaseData.Data['401(k) Vesting Schedule'][(parseInt(module4Data.end_year, 10) - parseInt(module4Data.start_year, 10)) + 1];
    }
  }
  return module4Data.vestedAfterFewyears;
}

// % of Employer Contributions & Earnings Not Vested
export function employerContributionsEarningsNotVested() {
  module4Data.employerContributionsEarningsNotVested = 0;
  if (module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.vested_amount_at_end_year !== undefined) {

    module4Data.employerContributionsEarningsNotVested = ((module4Data.totalEmployersContributionsEarnings / 100) * -(1 - (module4Data.vested_amount_at_end_year / 100))) * 100;
  }
  return module4Data.employerContributionsEarningsNotVested;
}

// Ending 401(k) Balance in Year 2024
export function ending401Balance() {
  module4Data.ending401Balance = 0;
  module4Data.ending401Balance = module4Data.balanceBeforeVestingReductions + module4Data.employerContributionsEarningsNotVested;
  return module4Data.ending401Balance;
}

// Roth IRA Retirement Personal Contribution as Base Income
export function careerPathRothIRAPersonalContributionCalcs() {
  module4Data.careerPathRothIRAPersonalContributionCalcs = 0;
  let firstEle = 0;
  let firstEle1 = 0;
  let firstEle2 = 0;
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.roth_personal_contribution !== undefined && module4Data.roth_personal_contribution !== '') {
    firstEle1 = (module4Data.totalPreTaxExpected_val / 100) * parseFloat(module4Data.roth_personal_contribution);
  }
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.ira_roth_personal_contribution !== undefined && module4Data.ira_roth_personal_contribution !== '') {
    firstEle2 = (module4Data.totalPreTaxExpected_val / 100) * parseFloat(module4Data.ira_roth_personal_contribution);
  }
  firstEle = firstEle1 + firstEle2;
  if (module4Data.roth_contribute !== undefined && module4Data.roth_contribute === 'Yes' && module4Data.careerPathRothIRAPersonalContribution !== undefined && module4Data.careerPathRothIRAPersonalContribution !== '' && firstEle <= module4Data.careerPathRothIRAPersonalContribution) {
    module4Data.careerPathRothIRAPersonalContributionCalcs = firstEle1;
  } else {

    let base_income = 0;
    let base_roth_income = 0;
    if (module4Data.roth_personal_contribution !== undefined && module4Data.roth_personal_contribution !== '') {
      base_income = parseFloat(module4Data.roth_personal_contribution);
    }
    if (module4Data.ira_roth_personal_contribution !== undefined && module4Data.ira_roth_personal_contribution !== '') {
      base_roth_income = parseFloat(module4Data.ira_roth_personal_contribution);
    }
    if (module4Data.careerPathRothIRAPersonalContribution !== undefined && module4Data.careerPathRothIRAPersonalContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module4Data.careerPathRothIRAPersonalContributionCalcs = (base_income / (base_income + base_roth_income)) * module4Data.careerPathRothIRAPersonalContribution;
      } else {
        module4Data.careerPathRothIRAPersonalContributionCalcs = 0;
      }
    } else {
      module4Data.careerPathRothIRAPersonalContributionCalcs = 0;
    }
  }
  return module4Data.careerPathRothIRAPersonalContributionCalcs;
}

// Roth IRA Retirement Personal Contribution
export function careerPathRothIRAPersonalContributionrothIra() {
  module4Data.careerPathRothIRAPersonalContribution_rothIra = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Roth IRA 2018 Contribution Limit'][module4Data.start_year] !== undefined
    && databaseData['Roth IRA 2018 Contribution Limit'][module4Data.start_year]['Max Personal Limit'] !== undefined) {
    module4Data.careerPathRothIRAPersonalContribution_rothIra = databaseData['Roth IRA 2018 Contribution Limit'][module4Data.start_year]['Max Personal Limit'];
  } else {
    module4Data.careerPathRothIRAPersonalContribution_rothIra = 0;
  }
  return module4Data.careerPathRothIRAPersonalContribution_rothIra;
}

export function careerPathRothIRAPersonalContribution() {
  module4Data.careerPathRothIRAPersonalContribution = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && databaseData !== undefined && databaseData['Roth IRA 2018 Contribution Limit'][module4Data.start_year] !== undefined
    && databaseData['Roth IRA 2018 Contribution Limit'][module4Data.start_year]['Max Personal Limit'] !== undefined) {
    module4Data.careerPathRothIRAPersonalContribution = databaseData['Roth IRA 2018 Contribution Limit'][module4Data.start_year]['Max Personal Limit'];

  }
  return module4Data.careerPathRothIRAPersonalContribution;
}

// Roth IRA Retirement Account Summary

// Roth IRA Retirement Total Contribution
export function careerPathRothIRATotalContribution() {
  module4Data.careerPathRothIRATotalContribution = 0;
  let totalContributionsVal = 0;
  if (investmentsData && investmentsData['Retirement Accounts Traditional IRA Second']) {
    const data = investmentsData['Retirement Accounts Traditional IRA Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContributionsVal += data[year]['Annual Contribution'];
        }
      });
    }
  }
  module4Data.careerPathRothIRATotalContribution = totalContributionsVal;

  return module4Data.careerPathRothIRATotalContribution;
}

// Roth IRA Retirement Total Earnings
export function careerPathRothIRATotalEarnings() {
  module4Data.careerPathRothIRATotalEarnings = 0;

  let totalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['Roth IRA Second']) {
    const data = investmentsData['Roth IRA Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarningsVal += data[year].Earnings;
        }
      });
    }
  }
  module4Data.careerPathRothIRATotalEarnings = totalEarningsVal;

  return module4Data.careerPathRothIRATotalEarnings;
}

// Roth IRA Retirement Total Balance
export function careerPathRothIRAEndingBalance() {
  module4Data.careerPathRothIRAEndingBalance = 0;
  if (module4Data.careerPathRothIRATotalEarnings !== undefined && module4Data.careerPathRothIRATotalContribution !== undefined && module4Data.rothBeginningBalance !== undefined) {
    module4Data.careerPathRothIRAEndingBalance = module4Data.careerPathRothIRATotalEarnings + module4Data.careerPathRothIRATotalContribution + module4Data.rothBeginningBalance;
  }
  return module4Data.careerPathRothIRAEndingBalance;
}

/* SEP IRA Retirement Account */
// SEP IRA Retirement Personal Contribution as Base Income
export function careerPathSEPIRAPersonalContributionCalcs() {
  module4Data.careerPathSEPIRAPersonalContributionCalcs = 0;
  if (module4Data.ira_contribute !== undefined && module4Data.ira_contribute === 'Yes') {
    if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && module4Data.ira_personal_contribution !== undefined && module4Data.ira_personal_contribution !== '') {
      if (module4Data.totalPreTaxExpected_val > databaseData.Data['SEP IRA Limits Second'] && databaseData.Data['SEP IRA Limits Rate'] === module4Data.ira_personal_contribution) {
        module4Data.careerPathSEPIRAPersonalContributionCalcs = databaseData.Data['SEP IRA Limits Rate'] * ((databaseData.Data['SEP IRA Limits Second']) / 100);
      } else {
        module4Data.careerPathSEPIRAPersonalContributionCalcs = ((module4Data.totalPreTaxExpected_val) / 100) * (module4Data.ira_personal_contribution);
      }
    }
  } else {
    module4Data.careerPathSEPIRAPersonalContributionCalcs = 0;
  }
  return module4Data.careerPathSEPIRAPersonalContributionCalcs;
}

// Personal Contribution (Max of the Lesser of 0% of Income or $00 by law)
export function careerPathSEPIRAPersonalContributionsepIra() {
  module5Data.careerPathSEPIRAPersonalContribution_sepIra = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][module4Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module4Data.start_year]['Max Personal Limit'] !== undefined) {
    module4Data.careerPathSEPIRAPersonalContribution_sepIra = databaseData['SEP IRA Contribution Limit'][module4Data.start_year]['Max Personal Limit'];
  } else {
    module4Data.careerPathSEPIRAPersonalContribution_sepIra = 0;
  }
  return module4Data.careerPathSEPIRAPersonalContribution_sepIra;
}

export function careerPathSEPIRAPersonalContribution() {
  module4Data.careerPathSEPIRAPersonalContribution = 0;

  let sepPersonalContribution1 = 0;
  let sepPersonalContribution2 = 0;
  let sepPersonalContribution3 = 0;
  let sepPersonalContribution = 0;

  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== ''
    && module4Data.start_year !== undefined && module4Data.start_year !== ''
    && databaseData !== undefined && databaseData['SEP IRA Contribution Limit'][module4Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module4Data.start_year]['Total Limit'] !== undefined
  ) {
    if (module4Data.totalPreTaxExpected_val > databaseData['SEP IRA Contribution Limit'][module4Data.start_year]['Total Limit']) {
      sepPersonalContribution1 = (databaseData['SEP IRA Contribution Limit'][module4Data.start_year]['Total Limit'] / 100) * databaseData['SEP IRA Contribution Limit'][module4Data.start_year].Return;
    } else {
      sepPersonalContribution1 = (module4Data.totalPreTaxExpected_val / 100) * databaseData['SEP IRA Contribution Limit'][module4Data.start_year].Return;
    }
  } else {
    sepPersonalContribution1 = 0;
  }

  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && databaseData !== undefined && databaseData['SEP IRA Contribution Limit'][module4Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module4Data.start_year]['Max Personal Limit'] !== undefined) {
    sepPersonalContribution2 = databaseData['SEP IRA Contribution Limit'][module4Data.start_year]['Max Personal Limit'];
  } else {
    sepPersonalContribution2 = 0;
  }

  if (sepPersonalContribution1 > sepPersonalContribution2) {
    sepPersonalContribution3 = sepPersonalContribution2;
  } else {
    sepPersonalContribution3 = sepPersonalContribution1;
  }

  if (module4Data.careerPathSEPIRAPersonalContributionCalcs !== undefined && module4Data.careerPathSEPIRAPersonalContributionCalcs !== '') {
    if (module4Data.careerPathSEPIRAPersonalContributionCalcs > sepPersonalContribution3) {
      sepPersonalContribution = sepPersonalContribution3;
    } else {
      sepPersonalContribution = module4Data.careerPathSEPIRAPersonalContributionCalcs;
    }
  } else {
    sepPersonalContribution = 0;
  }
  module4Data.careerPathSEPIRAPersonalContribution = sepPersonalContribution;
  return module4Data.careerPathSEPIRAPersonalContribution;
}

/* SEP IRA Retirement Summary */

// Beginning Balance Value
export function careerPathSEPIRABeginningBalance() {
  module4Data.careerPathSEPIRABeginningBalance = 0;
  if (module4Data.ira_contribute !== undefined && module4Data.ira_contribute === 'Yes') {
    if (module4Data.ira_beginning_balance !== undefined && module4Data.ira_beginning_balance !== '') {
      module4Data.careerPathSEPIRABeginningBalance = module4Data.ira_beginning_balance;
    } else {
      module4Data.careerPathSEPIRABeginningBalance = 0;
    }
  }
  return module4Data.careerPathSEPIRABeginningBalance;
}

// Total Contributions
export function careerPathSEPIRATotalContribution() {
  module4Data.careerPathSEPIRATotalContribution = 0;
  if (module4Data.ira_contribute !== undefined && module4Data.ira_contribute === 'Yes') {
    let totalContribution = 0;
    const data = investmentsData['Seph IRA Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContribution += data[year]['Annual Contribution'];
        }
      });
    }

    module4Data.careerPathSEPIRATotalContribution = totalContribution;
  }
  return module4Data.careerPathSEPIRATotalContribution;
}

// Total Earnings
export function careerPathSEPIRATotalEarnings() {
  module4Data.careerPathSEPIRATotalEarnings = 0;
  if (module4Data.ira_contribute !== undefined && module4Data.ira_contribute === 'Yes') {
    let totalEarnings = 0;
    const data = investmentsData['Seph IRA Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarnings += data[year].Earnings;
        }
      });
    }
    module4Data.careerPathSEPIRATotalEarnings = totalEarnings;
  }
  return module4Data.careerPathSEPIRATotalEarnings;
}

// Ending Sep IRA BALANCE
export function careerPathEndingSEPIRABalance() {
  module4Data.careerPathEndingSEPIRABalance = 0;
  if (module4Data.ira_contribute !== undefined && module4Data.ira_contribute === 'Yes') {
    if (module4Data.careerPathSEPIRATotalEarnings !== undefined && module4Data.careerPathSEPIRATotalContribution !== undefined && module4Data.careerPathSEPIRABeginningBalance !== undefined) {
      module4Data.careerPathEndingSEPIRABalance = module4Data.careerPathSEPIRATotalEarnings + module4Data.careerPathSEPIRATotalContribution + module4Data.careerPathSEPIRABeginningBalance;
    }
  }
  return module4Data.careerPathEndingSEPIRABalance;
}

/* Pension */

// Employer Contribution Calculations
export function careerPathPensionEmployerContributionCalc() {
  module4Data.careerPathPensionEmployerContributionCalc = 0;
  if (module4Data.pension_contribute !== undefined && module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.pension_employer_contribution !== undefined && module4Data.pension_employer_contribution !== '') {
    if (module4Data.pension_contribute === 'Yes') {
      module4Data.careerPathPensionEmployerContributionCalc = ((module4Data.totalPreTaxExpected_val) / 100) * module4Data.pension_employer_contribution;
    }
  }
  return module4Data.careerPathPensionEmployerContributionCalc;
}

// Personal Contribution Calculations
export function careerPathPensionPersonalContributionCalc() {
  module4Data.careerPathPensionPersonalContributionCalc = 0;
  if (module4Data.pension_contribute !== undefined && module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.pension_personal_contribution !== undefined && module4Data.pension_personal_contribution !== '') {
    if (module4Data.pension_contribute === 'Yes') {
      module4Data.careerPathPensionPersonalContributionCalc = ((module4Data.totalPreTaxExpected_val) / 100) * module4Data.pension_personal_contribution;
    }
  }
  return module4Data.careerPathPensionPersonalContributionCalc;
}
// export function careerPathPensionPersonalContributionCalc() {
//   module4Data.careerPathPensionPersonalContributionCalc = 0;
//   let contributionVal1 = 0;
//   let contributionVal2 = 0;
//   if (module4Data.pension_contribute !== undefined && module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '' && module4Data.pension_personal_contribution !== undefined
//     && module4Data.pension_personal_contribution !== '' && module4Data.careerPathPensionEmployerContributionCalc !== undefined && module4Data.careerPathPensionEmployerContributionCalc !== '' && databaseData !== undefined
//     && databaseData !== '' && databaseData.Data !== undefined) {
//     if (module4Data.pension_contribute === 'Yes') {
//       contributionVal1 = (((module4Data.totalPreTaxExpected_val) / 100) * module4Data.pension_personal_contribution) + module4Data.careerPathPensionEmployerContributionCalc;
//       contributionVal2 = databaseData.Data['Pension 2018 Limits'];
//       if (contributionVal1 > contributionVal2) {
//         module4Data.careerPathPensionPersonalContributionCalc = contributionVal2 - module4Data.careerPathPensionEmployerContributionCalc;
//       } else {
//         module4Data.careerPathPensionPersonalContributionCalc = ((module4Data.totalPreTaxExpected_val) / 100) * module4Data.pension_personal_contribution;
//       }
//     }
//   }
//   return module4Data.careerPathPensionPersonalContributionCalc;
// }

// Personal Contribution Calculations
export function careerPathPensionPersonalContribution() {
  module4Data.careerPathPensionPersonalContribution = 0;
  //   if(module4Data !== undefined && [module4Data.start_year] !== undefined && module4Data.careerPathPensionPersonalContributionCalc !== undefined &&
  // module4Data.careerPathPensionPersonalContributionCalc !== '' &&
  // module4Data.careerPathPensionEmployerContributionCalc !== undefined &&
  // module4Data.careerPathPensionEmployerContributionCalc !== '' &&
  // module4Data.start_year !== undefined &&
  // module4Data.start_year !== '' &&
  // databaseData !== undefined &&
  // databaseData !== '' &&
  // databaseData['Pension 2018 Contribution Limit'] !== undefined &&
  // databaseData['Pension 2018 Contribution Limit'][module4Data.start_year] !== undefined &&
  // databaseData['Pension 2018 Contribution Limit'][module4Data.start_year]['Total Limit'] !== undefined ) {
  //       if((module4Data.careerPathPensionPersonalContributionCalc+module4Data.careerPathPensionEmployerContributionCalc) > databaseData['Pension 2018 Contribution Limit'][module4Data.start_year]['Total Limit']){
  //           module4Data.careerPathPensionPersonalContribution = databaseData['Pension 2018 Contribution Limit'][module4Data.start_year]['Total Limit']-module4Data.careerPathPensionEmployerContributionCalc;
  //       } else {
  //           module4Data.careerPathPensionPersonalContribution = module4Data.careerPathPensionPersonalContributionCalc;
  //       }
  //   }
  return module4Data.careerPathPensionPersonalContribution;
}

// Total Annual Contribution
export function careerPathPensionTotalContributionpension() {
  module4Data.careerPathPensionTotalContribution_pension = 0;
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Pension 2018 Contribution Limit'][module4Data.start_year] !== undefined
    && databaseData['Pension 2018 Contribution Limit'][module4Data.start_year]['Total Limit'] !== undefined) {
    module4Data.careerPathPensionTotalContribution_pension = databaseData['Pension 2018 Contribution Limit'][module4Data.start_year]['Total Limit'];
  } else {
    module4Data.careerPathPensionTotalContribution_pension = 0;
  }
  return module4Data.careerPathPensionTotalContribution_pension;
}

export function careerPathPensionTotalContribution() {
  module4Data.careerPathPensionTotalContribution = 0;
  if (module4Data.careerPathPensionPersonalContributionCalc != undefined && module4Data.careerPathPensionPersonalContributionCalc != '') {
    module4Data.careerPathPensionTotalContribution += module4Data.careerPathPensionPersonalContributionCalc;
  }
  if (module4Data.careerPathPensionEmployerContributionCalc != undefined && module4Data.careerPathPensionEmployerContributionCalc != '') {
    module4Data.careerPathPensionTotalContribution += module4Data.careerPathPensionEmployerContributionCalc;
  }
  return module4Data.careerPathPensionTotalContribution;
}

/* Pension Summary */
// Beginning Balance Value
export function careerPathPensionBeginningBalance() {
  module4Data.careerPathPensionBeginningBalance = 0;
  if (module4Data.pension_beginning_balance !== undefined && module4Data.pension_beginning_balance !== null) {
    module4Data.careerPathPensionBeginningBalance = module4Data.pension_beginning_balance;
  }
  return module4Data.careerPathPensionBeginningBalance;
}

// Total Annual Employer Contribution
export function careerPathPensionTotalEmployerContributions() {
  module4Data.careerPathPensionTotalEmployerContributions = 0;
  let totalEmployerContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEmployerContribution += data[year]['Employer Contribution'];
        }
      }
      );
    }
  }
  module4Data.careerPathPensionTotalEmployerContributions = totalEmployerContribution;

  return module4Data.careerPathPensionTotalEmployerContributions;
}

// Total Annual Personal Contribution
export function careerPathPensionTotalPersonalContributions() {
  module4Data.careerPathPensionTotalPersonalContributions = 0;
  let totalPersonalContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalPersonalContribution += data[year]['Personal Contribution'];
        }
      });
    }
  }
  module4Data.careerPathPensionTotalPersonalContributions = totalPersonalContribution;

  return module4Data.careerPathPensionTotalPersonalContributions;
}

// Total Annual Personal Contribution
export function careerPathPensionTotalEarnings() {
  module4Data.careerPathPensionTotalEarnings = 0;
  let totalEanings = 0;
  if (investmentsData && investmentsData['Pension Second']) {
    const data = investmentsData['Pension Second'];
    const start_year = module4Data.start_year;
    const endYear = module4Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEanings += data[year].Earnings;
        }
      });
    }
  }
  module4Data.careerPathPensionTotalEarnings = totalEanings;
  return module4Data.careerPathPensionTotalEarnings;
}

export function careerPathEndingPensionBalance() {
  module4Data.careerPathEndingPensionBalance = 0;
  if (module4Data.careerPathPensionTotalEarnings !== undefined
    && module4Data.careerPathPensionTotalPersonalContributions !== undefined
    && module4Data.careerPathPensionTotalEmployerContributions !== undefined
    && module4Data.careerPathPensionBeginningBalance !== undefined) {
    module4Data.careerPathEndingPensionBalance = module4Data.careerPathPensionBeginningBalance + module4Data.careerPathPensionTotalEmployerContributions + module4Data.careerPathPensionTotalPersonalContributions + module4Data.careerPathPensionTotalEarnings;
  }
  return module4Data.careerPathEndingPensionBalance;
}

// career Path Expenses Rent Multiplier
export function careerPathExpensesRentMultiplier() {
  module4Data.careerPathExpensesRentMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.rent_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesRentMultiplier = module10Data.rent_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesRentMultiplier = module10Data.rent_multiplier;
    } else {
      module4Data.careerPathExpensesRentMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesRentMultiplier;
}

// Rent Expenses
export function careerPathRentExpenses() {
  let rentExpenses = 0;
  module4Data.careerPathRentExpenses = 0;
  if (module4Data.careerPathExpensesRentMultiplier !== undefined && module4Data.careerPathExpensesRentMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.rent !== undefined
      && module4Data.rent !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module4Data.start_year] !== undefined
      && incomeStatementData[module4Data.start_year].livingExpenses !== undefined) {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year && module4Data.rent > 0 && incomeStatementData[module4Data.start_year] !== undefined) {
        rentExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses.Rent) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.rent !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Rent) / 12);
      } else {
        rentExpenses = 0;
      }
    }
  } else {
    rentExpenses = '';
  }

  module4Data.careerPathRentExpenses = rentExpenses;

  return module4Data.careerPathRentExpenses;
}

// career Path Expenses Utilities Multiplier
export function careerPathExpensesUtilitiesMultiplier() {
  module4Data.careerPathExpensesUtilitiesMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.utilities_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else {
      module4Data.careerPathExpensesUtilitiesMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesUtilitiesMultiplier;
}

// Utilities Expenses
export function careerPathUtilitiesExpenses() {
  let utilitiesExpense = 0;
  module4Data.careerPathUtilitiesExpenses = 0;
  if (module4Data.careerPathExpensesUtilitiesMultiplier !== undefined && module4Data.careerPathExpensesUtilitiesMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module10Data.marriege_year > 0
      && module4Data.utilities !== undefined
      && module4Data.utilities !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module4Data.start_year].livingExpenses.Utilities) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.utilities !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities) / 12);
      } else {
        utilitiesExpense = 0;
      }
    }
  } else {
    utilitiesExpense = '';
  }

  module4Data.careerPathUtilitiesExpenses = utilitiesExpense;

  return module4Data.careerPathUtilitiesExpenses;
}

// career Path Expenses Food Multiplier
export function careerPathExpensesFoodMultiplier() {
  module4Data.careerPathExpensesFoodMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.food_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesFoodMultiplier = module10Data.food_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesFoodMultiplier = module10Data.food_multiplier;
    } else {
      module4Data.careerPathExpensesFoodMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesFoodMultiplier;
}

// Food Expenses
export function careerPathFoodExpenses() {
  let foodExpenses = 0;
  module4Data.careerPathFoodExpenses = 0;
  if (module4Data.careerPathExpensesFoodMultiplier !== undefined && module4Data.careerPathExpensesFoodMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.food !== undefined
      && module4Data.food !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.food > 0) {
        foodExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses.Food) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.food !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.food > 0) {
        foodExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Food) / 12);
      } else {
        foodExpenses = 0;
      }
    }
  } else {
    foodExpenses = '';
  }
  module4Data.careerPathFoodExpenses = foodExpenses;
  return module4Data.careerPathFoodExpenses;
}

// career Path Expenses Clothing Multiplier
export function careerPathExpensesClothingMultiplier() {
  module4Data.careerPathExpensesClothingMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.clothing_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesClothingMultiplier = module10Data.clothing_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesClothingMultiplier = module10Data.clothing_multiplier;
    } else {
      module4Data.careerPathExpensesClothingMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesClothingMultiplier;
}

// Clothing Expenses
export function careerPathClothingExpenses() {
  let clothingExpenses = 0;
  module4Data.careerPathClothingExpenses = 0;
  if (module4Data.careerPathExpensesClothingMultiplier !== undefined && module4Data.careerPathExpensesClothingMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.clothing !== undefined
      && module4Data.clothing !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses.Clothing) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.clothing !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing) / 12);
      } else {
        clothingExpenses = 0;
      }
    }
  } else {
    clothingExpenses = '';
  }
  module4Data.careerPathClothingExpenses = clothingExpenses;
  return module4Data.careerPathClothingExpenses;
}
// career Path Expenses Entertainment Multiplier
export function careerPathExpensesEntertainmentMultiplier() {
  module4Data.careerPathExpensesEntertainmentMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.entertainment_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else {
      module4Data.careerPathExpensesEntertainmentMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesEntertainmentMultiplier;
}

// Entertainment Expenses
export function careerPathEntertainmentExpenses() {
  let entertainmentExpenses = 0;
  module4Data.careerPathEntertainmentExpenses = 0;
  if (module4Data.careerPathExpensesEntertainmentMultiplier !== undefined && module4Data.careerPathExpensesEntertainmentMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.entertainment !== undefined
      && module4Data.entertainment !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses.Entertainment) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.entertainment !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment) / 12);
      } else {
        entertainmentExpenses = 0;
      }
    }
  } else {
    entertainmentExpenses = '';
  }
  module4Data.careerPathEntertainmentExpenses = entertainmentExpenses;
  return module4Data.careerPathEntertainmentExpenses;
}

// career Path Expenses Technology Multiplier
export function careerPathExpensesTechnologyMultiplier() {
  module4Data.careerPathExpensesTechnologyMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.technology_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesTechnologyMultiplier = module10Data.technology_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesTechnologyMultiplier = module10Data.technology_multiplier;
    } else {
      module4Data.careerPathExpensesTechnologyMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesTechnologyMultiplier;
}

// Technology Expenses
export function careerPathTechnologyExpenses() {
  let technologyExpenses = 0;
  module4Data.careerPathTechnologyExpenses = 0;
  if (module4Data.careerPathExpensesTechnologyMultiplier !== undefined && module4Data.careerPathExpensesTechnologyMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.technology !== undefined
      && module4Data.technology !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses.Technology) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.technology !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Technology) / 12);
      } else {
        technologyExpenses = 0;
      }
    }
  } else {
    technologyExpenses = '';
  }
  module4Data.careerPathTechnologyExpenses = technologyExpenses;
  return module4Data.careerPathTechnologyExpenses;
}

// career Path Expenses Transportation Multiplier
export function careerPathExpensesTransportationMultiplier() {
  module4Data.careerPathExpensesTransportationMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.transportation_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesTransportationMultiplier = module10Data.transportation_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesTransportationMultiplier = module10Data.transportation_multiplier;
    } else {
      module4Data.careerPathExpensesTransportationMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesTransportationMultiplier;
}

// Transportation Expenses
export function careerPathTransportationExpenses() {
  let transportationExpenses = 0;
  module4Data.careerPathTransportationExpenses = 0;
  if (module4Data.careerPathExpensesTransportationMultiplier !== undefined && module4Data.careerPathExpensesTransportationMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.transportation !== undefined
      && module4Data.transportation !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses.Transportation) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.transportation !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation) / 12);
      } else {
        transportationExpenses = 0;
      }
    }
  } else {
    transportationExpenses = '';
  }
  module4Data.careerPathTransportationExpenses = transportationExpenses;
  return module4Data.careerPathTransportationExpenses;
}

// career Path Expenses Miscellaneous Multiplier
export function careerPathExpensesMiscellaneousMultiplier() {
  module4Data.careerPathExpensesMiscellaneousMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.miscellaneous_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else {
      module4Data.careerPathExpensesMiscellaneousMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesMiscellaneousMultiplier;
}

// Miscellaneous Expenses
export function careerPathMiscellaneousExpenses() {
  let miscellaneousExpenses = 0;
  module4Data.careerPathMiscellaneousExpenses = 0;
  if (module4Data.careerPathExpensesMiscellaneousMultiplier !== undefined && module4Data.careerPathExpensesMiscellaneousMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.miscellaneous !== undefined
      && module4Data.miscellaneous !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses.Miscellaneous) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.miscellaneous !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous) / 12);
      } else {
        miscellaneousExpenses = 0;
      }
    }
  } else {
    miscellaneousExpenses = '';
  }
  module4Data.careerPathMiscellaneousExpenses = miscellaneousExpenses;
  return module4Data.careerPathMiscellaneousExpenses;
}

// career Path Expenses Medical Multiplier
export function careerPathExpensesMedicalMultiplier() {
  module4Data.careerPathExpensesMedicalMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.health_insurance_costs_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesMedicalMultiplier = module10Data.health_insurance_costs_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesMedicalMultiplier = module10Data.health_insurance_costs_multiplier;
    } else {
      module4Data.careerPathExpensesMedicalMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesMedicalMultiplier;
}

// Health Insurance Outside Premium Expenses
export function careerPathOutsidePremiumExpenses() {
  let outsidePremiumExpenses = 0;
  module4Data.careerPathOutsidePremiumExpenses = 0;
  if (module4Data.careerPathExpensesMedicalMultiplier !== undefined && module4Data.careerPathExpensesMedicalMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.medical_costs_before_deductible !== undefined
      && module4Data.medical_costs_before_deductible !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.medical_costs_before_deductible > 0) {
        outsidePremiumExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.medical_costs_before_deductible !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.medical_costs_before_deductible > 0) {
        outsidePremiumExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else {
        outsidePremiumExpenses = 0;
      }
    }
  } else {
    outsidePremiumExpenses = '';
  }
  module4Data.careerPathOutsidePremiumExpenses = outsidePremiumExpenses;
  return module4Data.careerPathOutsidePremiumExpenses;
}

// career Path Expenses Health Multiplier
export function careerPathExpensesHealthMultiplier() {
  module4Data.careerPathExpensesHealthMultiplier = 0;
  if (module4Data.start_year !== undefined && module10Data.marriege_year !== undefined && module10Data.health_insurance_premium_multiplier !== undefined) {
    if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.start_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesHealthMultiplier = module10Data.health_insurance_premium_multiplier;
    } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
      module4Data.careerPathExpensesHealthMultiplier = module10Data.health_insurance_premium_multiplier;
    } else {
      module4Data.careerPathExpensesHealthMultiplier = '';
    }
  }
  return module4Data.careerPathExpensesHealthMultiplier;
}

// Health Insurance Premium Expenses
export function careerPathPremiumExpenses() {
  let premiumExpenses = 0;
  module4Data.careerPathPremiumExpenses = 0;
  if (module4Data.careerPathExpensesHealthMultiplier !== undefined && module4Data.careerPathExpensesHealthMultiplier !== '') {
    if (module4Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module4Data.health_insurance_premium !== undefined
      && module4Data.health_insurance_premium !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== '') {
      if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && incomeStatementData[module4Data.start_year] !== undefined && module4Data.start_year >= module10Data.marriege_year && module4Data.health_insurance_premium > 0) {
        premiumExpenses = -((incomeStatementData[module4Data.start_year].livingExpenses['Health Insurance Premium']) / 12);
      } else if (module4Data.start_year !== '' && module10Data.marriege_year !== '' && module4Data.end_year !== '' && module4Data.health_insurance_premium !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year && module4Data.health_insurance_premium > 0) {
        premiumExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium']) / 12);
      } else {
        premiumExpenses = 0;
      }
    }
  } else {
    premiumExpenses = '';
  }
  module4Data.careerPathPremiumExpenses = premiumExpenses;
  return module4Data.careerPathPremiumExpenses;
}

// Annual Expense
export function careerPathLivingExpenses() {
  module4Data.careerPathLivingExpenses = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Rent !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Utilities !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Food !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Clothing !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Entertainment !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Technology !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Transportation !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Miscellaneous !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses['Health Insurance Premium'] !== undefined) {
    module4Data.careerPathLivingExpenses = incomeStatementData[module4Data.start_year].livingExpenses.Rent
      + incomeStatementData[module4Data.start_year].livingExpenses.Utilities
      + incomeStatementData[module4Data.start_year].livingExpenses.Food
      + incomeStatementData[module4Data.start_year].livingExpenses.Clothing
      + incomeStatementData[module4Data.start_year].livingExpenses.Entertainment
      + incomeStatementData[module4Data.start_year].livingExpenses.Technology
      + incomeStatementData[module4Data.start_year].livingExpenses.Transportation
      + incomeStatementData[module4Data.start_year].livingExpenses.Miscellaneous
      + incomeStatementData[module4Data.start_year].livingExpenses['Healthcare Costs other than Premium']
      + incomeStatementData[module4Data.start_year].livingExpenses['Health Insurance Premium'];
  }
  return module4Data.careerPathLivingExpenses;
}

// Hgher Education Expenses Value
export function careerPathHigherEducationExpenses() {
  module4Data.careerPathHigherEducationExpenses = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses['Higher Education Room & Board'] !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses['Off Campus Food'] !== undefined) {
    module4Data.careerPathHigherEducationExpenses = incomeStatementData[module4Data.start_year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementData[module4Data.start_year].livingExpenses['Higher Education Room & Board']
      + incomeStatementData[module4Data.start_year].livingExpenses['Off Campus Food'];
  }
  return module4Data.careerPathHigherEducationExpenses;
}

// Car Expenses Value
export function careerPathCarExpenses() {
  module4Data.careerPathCarExpenses = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined
    && incomeStatementData[module4Data.start_year]['Total Car Expenses'] !== undefined) {
    module4Data.careerPathCarExpenses = incomeStatementData[module4Data.start_year]['Total Car Expenses'];
  }
  return module4Data.careerPathCarExpenses;
}

// Children Expenses Value
export function careerPathChildrenExpenses() {
  module4Data.careerPathChildrenExpenses = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Children !== undefined) {
    module4Data.careerPathChildrenExpenses = incomeStatementData[module4Data.start_year].livingExpenses.Children;
  }
  return module4Data.careerPathChildrenExpenses;
}
// Pet Expenses Value
export function careerPathPetExpenses() {
  module4Data.careerPathPetExpenses = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined
    && incomeStatementData[module4Data.start_year].livingExpenses.Pet !== undefined) {
    module4Data.careerPathPetExpenses = incomeStatementData[module4Data.start_year].livingExpenses.Pet;
  }
  return module4Data.careerPathPetExpenses;
}

// Home Expenses Value
export function careerPathHomeExpenses() {
  module4Data.careerPathHomeExpenses = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined
    && incomeStatementData[module4Data.start_year]['Total Real Estate Expenses'] !== undefined) {
    module4Data.careerPathHomeExpenses = incomeStatementData[module4Data.start_year]['Total Real Estate Expenses'];
  }
  return module4Data.careerPathHomeExpenses;
}

// Financing Expenses Value
export function careerPathFinancingExpenses() {
  module4Data.careerPathFinancingExpenses = 0;
  let loanPayments1 = 0;
  let loanPaymentsFinal = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined
    && incomeStatementData[module4Data.start_year]['Total Interest Expenses'] !== undefined) {
    loanPayments1 = incomeStatementData[module4Data.start_year]['Total Interest Expenses'];
  }
  loanPaymentsFinal = loanPayments1;
  module4Data.careerPathFinancingExpenses = loanPaymentsFinal;

  return module4Data.careerPathFinancingExpenses;
}

// Total Annual Expenses
export function careerPathTotalAnnualExpenses() {
  module4Data.careerPathTotalAnnualExpenses = 0;
  if (module4Data.annualTotalTaxesPaidCareerPath !== undefined
    && module4Data.careerPathLivingExpenses !== undefined
    && module4Data.careerPathHigherEducationExpenses !== undefined
    && module4Data.careerPathCarExpenses !== undefined
    && module4Data.careerPathChildrenExpenses !== undefined
    && module4Data.careerPathPetExpenses !== undefined
    && module4Data.careerPathHomeExpenses !== undefined
    && module4Data.careerPathFinancingExpenses !== undefined) {
    module4Data.careerPathTotalAnnualExpenses = module4Data.annualTotalTaxesPaidCareerPath
      + module4Data.careerPathLivingExpenses
      + module4Data.careerPathHigherEducationExpenses
      + module4Data.careerPathCarExpenses
      + module4Data.careerPathChildrenExpenses
      + module4Data.careerPathPetExpenses
      + module4Data.careerPathHomeExpenses
      + module4Data.careerPathFinancingExpenses;
  }
  return module4Data.careerPathTotalAnnualExpenses;
}

// Monthly income from Career Path
export function monthlyIncomeFromCareerPath() {
  module4Data.monthlyIncomeFromCareerPath = 0;
  if (module4Data.totalPreTaxExpected_val !== undefined && module4Data.totalPreTaxExpected_val !== '') {
    module4Data.monthlyIncomeFromCareerPath = module4Data.totalPreTaxExpected_val / 12;
  }
  return module4Data.monthlyIncomeFromCareerPath;
}

// Total Onthly Income
export function totalMonthlyIncomeCareerPath() {
  module4Data.totalMonthlyIncomeCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year]['Total Income'] !== undefined) {
    module4Data.totalMonthlyIncomeCareerPath = (incomeStatementData[module4Data.start_year]['Total Income']) / 12;
  }
  return module4Data.totalMonthlyIncomeCareerPath;
}

// Other Monthly Income
export function otherMonthlyIncomeCareerPath() {
  module4Data.otherMonthlyIncomeCareerPath = 0;
  if (module4Data.totalMonthlyIncomeCareerPath !== undefined && module4Data.monthlyIncomeFromCareerPath !== undefined) {
    module4Data.otherMonthlyIncomeCareerPath = module4Data.totalMonthlyIncomeCareerPath - module4Data.monthlyIncomeFromCareerPath;
  }
  return module4Data.otherMonthlyIncomeCareerPath;
}

// Total Taxes Paid
export function totalTaxesPaidCareerPath() {
  module4Data.totalTaxesPaidCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year]['Total Taxes'] !== undefined) {
    module4Data.totalTaxesPaidCareerPath = (incomeStatementData[module4Data.start_year]['Total Taxes']) / 12;
  }
  return module4Data.totalTaxesPaidCareerPath;
}

// Living Expenses
export function livingExpensesCareerPath() {
  module4Data.livingExpensesCareerPath = 0;
  if (module4Data.totalMonthlyLivingExpenses_val !== undefined) {
    module4Data.livingExpensesCareerPath = -module4Data.totalMonthlyLivingExpenses_val;
  }
  return module4Data.livingExpensesCareerPath;
}

// Higher Education Expenses
export function higherEducationExpensesCareerPath() {
  module4Data.higherEducationExpensesCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year].livingExpenses !== undefined) {
    module4Data.higherEducationExpensesCareerPath = (incomeStatementData[module4Data.start_year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementData[module4Data.start_year].livingExpenses['Higher Education Room & Board']
      + incomeStatementData[module4Data.start_year].livingExpenses['Off Campus Food']) / 12;
  }
  return module4Data.higherEducationExpensesCareerPath;
}

// Children Expenses
export function childrenExpensesCareerPath() {
  module4Data.childrenExpensesCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year].livingExpenses !== undefined) {
    module4Data.childrenExpensesCareerPath = incomeStatementData[module4Data.start_year].livingExpenses.Children / 12;
  }
  return module4Data.childrenExpensesCareerPath;
}

// Pet Expenses
export function petExpensesCareerPath() {
  module4Data.petExpensesCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year].livingExpenses !== undefined) {
    module4Data.petExpensesCareerPath = incomeStatementData[module4Data.start_year].livingExpenses.Pet / 12;
  }
  return module4Data.petExpensesCareerPath;
}
// Car Expenses
export function carExpensesCareerPath() {
  module4Data.carExpensesCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year]['Total Car Expenses'] !== undefined) {
    module4Data.carExpensesCareerPath = incomeStatementData[module4Data.start_year]['Total Car Expenses'] / 12;
  }
  return module4Data.carExpensesCareerPath;
}

// Real Estate Expenses
export function realEstateExpensesCareerPath() {
  module4Data.realEstateExpensesCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year]['Total Real Estate Expenses'] !== undefined) {
    module4Data.realEstateExpensesCareerPath = incomeStatementData[module4Data.start_year]['Total Real Estate Expenses'] / 12;
  }
  return module4Data.realEstateExpensesCareerPath;
}

// Internet Expenses
export function internetExpensesCareerPath() {
  module4Data.internetExpensesCareerPath = 0;
  let loanPayments1 = 0;
  let loanPayments2 = 0;
  let loanPayments3 = 0;
  let loanPayments4 = 0;
  let loanPayments5 = 0;
  let loanPaymentsFinal = 0;

  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year]['Total Interest Expenses'] !== undefined) {
    loanPayments1 = incomeStatementData[module4Data.start_year]['Total Interest Expenses'] / 12;
  }
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module4Data.start_year] !== undefined && cashFlowData[module4Data.start_year] !== '') {
    if (cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== '') {
      loanPayments2 = cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] / 12;
    }
    if (cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] !== '') {
      loanPayments3 = cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] / 12;
    }
    if (cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] !== '') {
      loanPayments4 = cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] / 12;
    }
    if (cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] !== '') {
      loanPayments5 = cashFlowData[module4Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] / 12;
    }
  }
  loanPaymentsFinal = loanPayments1 + loanPayments2 + loanPayments3 + loanPayments4 + loanPayments5;
  module4Data.internetExpensesCareerPath = loanPaymentsFinal;

  return module4Data.internetExpensesCareerPath;
}

// Total Monthly Expenses
export function totalMonthlyExpensesCareerPath() {
  module4Data.totalMonthlyExpensesCareerPath = 0;
  module4Data.totalMonthlyExpensesCareerPath = module4Data.totalTaxesPaidCareerPath
    + module4Data.livingExpensesCareerPath
    + module4Data.higherEducationExpensesCareerPath
    + module4Data.childrenExpensesCareerPath
    + module4Data.petExpensesCareerPath
    + module4Data.carExpensesCareerPath
    + module4Data.realEstateExpensesCareerPath
    + module4Data.internetExpensesCareerPath;
  return module4Data.totalMonthlyExpensesCareerPath;
}

// Monthly net Income
export function monthlyNetIncomeCareerPath() {
  module4Data.monthlyNetIncomeCareerPath = 0;
  if (module4Data.totalMonthlyIncomeCareerPath !== undefined && module4Data.totalMonthlyExpensesCareerPath !== undefined) {
    module4Data.monthlyNetIncomeCareerPath = module4Data.totalMonthlyIncomeCareerPath + module4Data.totalMonthlyExpensesCareerPath;
  }
  return module4Data.monthlyNetIncomeCareerPath;
}

// Retirement Contributions
export function retirementContributionsCareerPath() {
  module4Data.retirementContributionsCareerPath = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module4Data.start_year] !== undefined && cashFlowData[module4Data.start_year] !== '' && cashFlowData[module4Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== '') {
    module4Data.retirementContributionsCareerPath = -cashFlowData[module4Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] / 12;
  }
  return module4Data.retirementContributionsCareerPath;
}

//New Updates on Retirement accounts
export function BeginningBalanceOf401k() {
  module4Data.BeginningBalanceOf401k = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '') {

    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Retirement Accounts 401k Second'] !== undefined && investmentsData['Retirement Accounts 401k Second'] !== '' && investmentsData['Retirement Accounts 401k Second'][module4Data.start_year] !== undefined && investmentsData['Retirement Accounts 401k Second'][module4Data.start_year] !== '') {

      module4Data.BeginningBalanceOf401k = investmentsData['Retirement Accounts 401k Second'][module4Data.start_year]['Total Beginning Balance'];
    }
  }
  module4Data.beginning_balance_of401k = module4Data.BeginningBalanceOf401k;
  return module4Data.BeginningBalanceOf401k;
}

// Net Cash Flow for Savings and Investments
export function monthlyNetCashFlowCareerPath() {
  module4Data.monthlyNetCashFlowCareerPath = 0;
  if (module4Data.monthlyNetIncomeCareerPath !== undefined && module4Data.retirementContributionsCareerPath !== undefined) {
    module4Data.monthlyNetCashFlowCareerPath = module4Data.monthlyNetIncomeCareerPath - module4Data.retirementContributionsCareerPath;
  }
  return module4Data.monthlyNetCashFlowCareerPath;
}

// Total Income
export function totalIncomeCareerPath() {
  module4Data.totalIncomeCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year]['Total Income'] !== undefined) {
    module4Data.totalIncomeCareerPath = incomeStatementData[module4Data.start_year]['Total Income'];
  }
  return module4Data.totalIncomeCareerPath;
}
// Total Income Calcs
export function totalIncomeCareerPathCalcs() {
  module4Data.totalIncomeCareerPathCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year]['Total Income'] !== undefined) {
      module4Data.totalIncomeCareerPathCalcs = incomeStatementData[module10Data.marriege_year]['Total Income'];
    }
  }
  return module4Data.totalIncomeCareerPathCalcs;
}

// Total taxes paid
export function annualTotalTaxesPaidCareerPath() {
  module4Data.annualTotalTaxesPaidCareerPath = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module4Data.start_year] !== undefined && incomeStatementData[module4Data.start_year] !== '' && incomeStatementData[module4Data.start_year]['Total Income'] !== undefined) {
    module4Data.annualTotalTaxesPaidCareerPath = incomeStatementData[module4Data.start_year]['Total Taxes'];
  }
  return module4Data.annualTotalTaxesPaidCareerPath;
}

// Total Taxes Paid Calcs
export function annualTotalExpensesCareerPathCalcs() {
  module4Data.annualTotalExpensesCareerPathCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year]['Total Taxes'] !== undefined) {
      module4Data.annualTotalExpensesCareerPathCalcs = incomeStatementData[module10Data.marriege_year]['Total Taxes'];
    }
  }
  return module4Data.annualTotalExpensesCareerPathCalcs;
}

// Family Iccone
export function careerPathIconCalcs() {
  module4Data.careerPathIconCalcs = '';
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    module4Data.careerPathIconCalcs = module10Data.marriege_year;
  } else {
    module4Data.careerPathIconCalcs = '';
  }
  return module4Data.careerPathIconCalcs;
}

// Living Expense Calcs
export function careerPathLivingExpensesCalcs() {
  module4Data.careerPathLivingExpensesCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] !== undefined) {
      module4Data.careerPathLivingExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Rent
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Food
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Technology
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'];
    } else {
      module4Data.careerPathLivingExpensesCalcs = 0;
    }
  } else {
    module4Data.careerPathLivingExpensesCalcs = 0;
  }
  return module4Data.careerPathLivingExpensesCalcs;
}

// Family Iccone
export function careerPathHigherEducationExpensesCalcs() {
  module4Data.careerPathHigherEducationExpensesCalcs = 0;
  if (module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module5Data.start_year < module10Data.marriege_year && module5Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Room & Board'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Off Campus Food'] !== undefined) {
      module4Data.careerPathHigherEducationExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Tuition and Fees']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Room & Board']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Off Campus Food'];
    } else {
      module4Data.careerPathHigherEducationExpensesCalcs = 0;
    }
  } else {
    module4Data.careerPathHigherEducationExpensesCalcs = 0;
  }
  return module4Data.careerPathHigherEducationExpensesCalcs;
}

// Car Expenses Calcs
export function careerPathCarExpensesCalcs() {
  module4Data.careerPathCarExpensesCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].careerPathCarExpensesCalcs !== undefined) {
      module4Data.careerPathCarExpensesCalcs = incomeStatementData[module10Data.marriege_year].careerPathCarExpensesCalcs;
    } else {
      module4Data.careerPathCarExpensesCalcs = 0;
    }
  } else {
    module4Data.careerPathCarExpensesCalcs = 0;
  }
  return module4Data.careerPathCarExpensesCalcs;
}

// Children Expenses Calcs
export function careerPathChildrenExpensesCalcs() {
  module4Data.careerPathChildrenExpensesCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Children !== undefined) {
      module4Data.careerPathChildrenExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Children;
    } else {
      module4Data.careerPathChildrenExpensesCalcs = 0;
    }
  } else {
    module4Data.careerPathChildrenExpensesCalcs = 0;
  }
  return module4Data.careerPathChildrenExpensesCalcs;
}

// Children Expenses Calcs
export function careerPathPetExpensesCalcs() {
  module4Data.careerPathPetExpensesCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Pet !== undefined) {
      module4Data.careerPathPetExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Pet;
    } else {
      module4Data.careerPathPetExpensesCalcs = 0;
    }
  } else {
    module4Data.careerPathPetExpensesCalcs = 0;
  }
  return module4Data.careerPathPetExpensesCalcs;
}

// Home Expenses Calcs
export function careerPathHomeExpensesCalcs() {
  module4Data.careerPathHomeExpensesCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Real Estate Expenses'] !== undefined) {
      module4Data.careerPathHomeExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Real Estate Expenses'];
    } else {
      module4Data.careerPathHomeExpensesCalcs = 0;
    }
  } else {
    module4Data.careerPathHomeExpensesCalcs = 0;
  }
  return module4Data.careerPathHomeExpensesCalcs;
}

// Financing Expenses Calcs
export function careerPathFinancingExpensesCalcs() {
  module4Data.careerPathFinancingExpensesCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Interest Expenses'] !== undefined) {
      module4Data.careerPathFinancingExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Interest Expenses'];
    } else {
      module4Data.careerPathFinancingExpensesCalcs = 0;
    }
  } else {
    module4Data.careerPathFinancingExpensesCalcs = 0;
  }
  return module4Data.careerPathFinancingExpensesCalcs;
}

// Total Annual Expenses Calcs
export function careerPathTotalAnnualExpensesCalcs() {
  module4Data.careerPathTotalAnnualExpensesCalcs = 0;
  if (module4Data.annualTotalExpensesCareerPathCalcs !== undefined
    && module4Data.careerPathLivingExpensesCalcs !== undefined
    && module4Data.careerPathHigherEducationExpensesCalcs !== undefined
    && module4Data.careerPathCarExpensesCalcs !== undefined
    && module4Data.careerPathChildrenExpensesCalcs !== undefined
    && module4Data.careerPathPetExpensesCalcs !== undefined
    && module4Data.careerPathHomeExpensesCalcs !== undefined
    && module4Data.careerPathFinancingExpensesCalcs !== undefined) {
    module4Data.careerPathTotalAnnualExpensesCalcs = module4Data.annualTotalExpensesCareerPathCalcs
      + module4Data.careerPathLivingExpensesCalcs
      + module4Data.careerPathHigherEducationExpensesCalcs
      + module4Data.careerPathCarExpensesCalcs
      + module4Data.careerPathChildrenExpensesCalcs
      + module4Data.careerPathPetExpensesCalcs
      + module4Data.careerPathHomeExpensesCalcs
      + module4Data.careerPathFinancingExpensesCalcs;
  } else {
    module4Data.careerPathTotalAnnualExpensesCalcs = 0;
  }
  return module4Data.careerPathTotalAnnualExpensesCalcs;
}

// Annual Net Income
export function careerPathAnnualNetIncome() {
  module4Data.careerPathAnnualNetIncome = 0;
  if (module4Data.totalIncomeCareerPath !== undefined && module4Data.careerPathTotalAnnualExpenses !== undefined) {
    module4Data.careerPathAnnualNetIncome = module4Data.totalIncomeCareerPath + module4Data.careerPathTotalAnnualExpenses;
  }
  return module4Data.careerPathAnnualNetIncome;
}

// Annual Net Income Cacls
export function careerPathAnnualNetIncomeCalcs() {
  module4Data.careerPathAnnualNetIncomeCalcs = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module4Data.start_year < module10Data.marriege_year && module4Data.end_year >= module10Data.marriege_year) {
    if (module4Data.totalIncomeCareerPathCalcs !== undefined && module4Data.careerPathTotalAnnualExpensesCalcs !== undefined) {
      module4Data.careerPathAnnualNetIncomeCalcs = module4Data.totalIncomeCareerPathCalcs + module4Data.careerPathTotalAnnualExpensesCalcs;
    } else {
      module4Data.careerPathAnnualNetIncomeCalcs = 0;
    }
  } else {
    module4Data.careerPathAnnualNetIncomeCalcs = 0;
  }
  return module4Data.careerPathAnnualNetIncomeCalcs;
}

// Retirement Contributions
export function annualRetirementContributionsCareerPath() {
  module4Data.annualRetirementContributionsCareerPath = 0;
  if (module4Data.start_year !== undefined && module4Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module4Data.start_year] !== undefined && cashFlowData[module4Data.start_year] !== '' && cashFlowData[module4Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== '') {
    module4Data.annualRetirementContributionsCareerPath = -cashFlowData[module4Data.start_year]['Cash from Investing Activities']['Retirement Contributions'];
  }
  return module4Data.annualRetirementContributionsCareerPath;
}

//Retirement Contributions
export function annualHsaContributionsCareerPath(){
	module4Data.annualHsaContributionsCareerPath = 0;
	if(	module4Data.start_year !== undefined && module4Data.start_year !== '' && 
		cashFlowData !== undefined && cashFlowData !== '' && 
		cashFlowData[module4Data.start_year] !== undefined && cashFlowData[module4Data.start_year] !== '' && cashFlowData[module4Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== '' ){
		module4Data.annualHsaContributionsCareerPath = -cashFlowData[module4Data.start_year]['Cash from Investing Activities']['HSA Contributions'];
	}
	return module4Data.annualHsaContributionsCareerPath;
}

// Net Cash Flow for Savings and Investments
export function annualNetCashFlowCareerPath() {
  module4Data.annualNetCashFlowCareerPath = 0;
  if (module4Data.careerPathAnnualNetIncome !== undefined && module4Data.annualRetirementContributionsCareerPath !== undefined) {
    module4Data.annualNetCashFlowCareerPath = module4Data.careerPathAnnualNetIncome - module4Data.annualRetirementContributionsCareerPath;
  }
  return module4Data.annualNetCashFlowCareerPath;
}

export function careerPathTotalMonthlyLivingExpenses() {
  module4Data.careerPathTotalMonthlyLivingExpenses = 0;
  let totalExpenses = 0;
  if (module4Data.careerPathRentExpenses !== undefined && module4Data.careerPathRentExpenses !== '') {
    totalExpenses += module4Data.careerPathRentExpenses;
  }
  if (module4Data.careerPathUtilitiesExpenses !== undefined && module4Data.careerPathUtilitiesExpenses !== '') {
    totalExpenses += module4Data.careerPathUtilitiesExpenses;
  }
  if (module4Data.careerPathFoodExpenses !== undefined && module4Data.careerPathFoodExpenses !== '') {
    totalExpenses += module4Data.careerPathFoodExpenses;
  }
  if (module4Data.careerPathClothingExpenses !== undefined && module4Data.careerPathClothingExpenses !== '') {
    totalExpenses += module4Data.careerPathClothingExpenses;
  }
  if (module4Data.careerPathEntertainmentExpenses !== undefined && module4Data.careerPathEntertainmentExpenses !== '') {
    totalExpenses += module4Data.careerPathEntertainmentExpenses;
  }
  if (module4Data.careerPathTechnologyExpenses !== undefined && module4Data.careerPathTechnologyExpenses !== '') {
    totalExpenses += module4Data.careerPathTechnologyExpenses;
  }
  if (module4Data.careerPathTransportationExpenses !== undefined && module4Data.careerPathTransportationExpenses !== '') {
    totalExpenses += module4Data.careerPathTransportationExpenses;
  }
  if (module4Data.careerPathMiscellaneousExpenses !== undefined && module4Data.careerPathMiscellaneousExpenses !== '') {
    totalExpenses += module4Data.careerPathMiscellaneousExpenses;
  }
  if (module4Data.careerPathOutsidePremiumExpenses !== undefined && module4Data.careerPathOutsidePremiumExpenses !== '') {
    totalExpenses += module4Data.careerPathOutsidePremiumExpenses;
  }
  if (module4Data.careerPathPremiumExpenses !== undefined && module4Data.careerPathPremiumExpenses !== '') {
    totalExpenses += module4Data.careerPathPremiumExpenses;
  }
  module4Data.careerPathTotalMonthlyLivingExpenses = totalExpenses;
  return module4Data.careerPathTotalMonthlyLivingExpenses;
}

export function pensionTotalAnnualContributionLabel() {
  let label = 0;
  if (databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && databaseData.Data !== '' && databaseData.Data['Pension 2018 Limits'] !== undefined && databaseData.Data['Pension 2018 Limits'] !== '') {
    label = databaseData.Data['Pension 2018 Limits'];
  }

  return `Total Annual Contribution (Max of $${label} by law)`;
}

export function careerPathMarriageYear() {
  let careerPathMarriageYearVal = '';
  if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module4Data.start_year >= module10Data.marriege_year) {
    careerPathMarriageYearVal = module4Data.start_year;
  } else if (module4Data.start_year !== undefined
    && module4Data.start_year !== ''
    && module4Data.end_year !== undefined
    && module4Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module4Data.start_year < module10Data.marriege_year
    && module4Data.end_year >= module10Data.marriege_year) {
    careerPathMarriageYearVal = module10Data.marriege_year;
  } else {
    careerPathMarriageYearVal = '';
  }
  module4Data.careerPathMarriageYearVal = careerPathMarriageYearVal;
  return careerPathMarriageYearVal;
}

/** ******************** Module 4 Career Path End *********************** */

/** ******************** Module 5 Career Advancement Start *********************** */

export function careerAdvsuppYearsDiff() {
  module5Data.supplementaryyearsDiff = 0;
  if (
    module5Data.second_end_year > 0
    && module5Data.second_start_year > 0
  ) {
    module5Data.supplementaryyearsDiff = module5Data.second_end_year - module5Data.second_start_year + 1;
  }
  return module5Data.supplementaryyearsDiff;
}

// Advance Total PreTax Expected
export function advanceTotalPreTaxExpected() {
  module5Data.advanceTotalPreTaxExpected_val = 0;
  if (
    module5Data.yearly_income !== undefined
    && module5Data.yearly_income !== ''
    && module5Data.bonus_or_tips_or_commission !== undefined
    && module5Data.bonus_or_tips_or_commission !== ''
  ) {
    module5Data.advanceTotalPreTaxExpected_val = module5Data.yearly_income + module5Data.bonus_or_tips_or_commission;
  }
  return module5Data.advanceTotalPreTaxExpected_val;
}

// Advance Supplementary Total PreTax Expected
export function advanceSupplementaryTotalPreTaxExpected() {
  module5Data.advanceSupplementaryTotalPreTaxExpected = 0;
  if (
    module5Data.supplementaryyearsDiff !== undefined
    && module5Data.supplementaryyearsDiff !== ''
    && module5Data.second_yearly_income !== undefined
    && module5Data.second_yearly_income !== ''
  ) {
    module5Data.advanceSupplementaryTotalPreTaxExpected = module5Data.supplementaryyearsDiff * module5Data.second_yearly_income;
  }
  return module5Data.advanceSupplementaryTotalPreTaxExpected;
}

// Advance Total Monthly Living Expenses
export function advanceTotalMonthlyLivingExpenses() {
  module5Data.advanceTotalMonthlyLivingExpenses = 0;
  if (
    module5Data.rent !== undefined
    && module5Data.utilities !== undefined
    && module5Data.food !== undefined
    && module5Data.clothing !== undefined
    && module5Data.entertainment !== undefined
    && module5Data.technology !== undefined
    && module5Data.transportation !== undefined
    && module5Data.miscellaneous !== undefined
    && module5Data.medical_costs_before_deductible !== undefined
    && module5Data.health_insurance_premium !== undefined
  ) {
    module5Data.advanceTotalMonthlyLivingExpenses = module5Data.rent
      + module5Data.utilities
      + module5Data.food
      + module5Data.clothing
      + module5Data.entertainment
      + module5Data.technology
      + module5Data.transportation
      + module5Data.miscellaneous
      + module5Data.medical_costs_before_deductible
      + module5Data.health_insurance_premium;
  }
  return module5Data.advanceTotalMonthlyLivingExpenses;
}

// Credit Card Interest Data
export function advanceExistingCreditCardInterestInterest() {
  module5Data.advanceExistingCreditCardInterestInterest = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceCreditCardInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceCreditCardInterestVal
            += data[year].InterestExpenses['Existing Credit Card Debt'];
        }
      });
    }
    module5Data.advanceExistingCreditCardInterestInterest = advanceCreditCardInterestVal;
  }
  return module5Data.advanceExistingCreditCardInterestInterest;
}

// Student Loan Principal Interest
export function advanceStudentLoanPrincipalInterest() {
  module5Data.advanceStudentLoanPrincipalInterest = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceStudentLoanPrincipalInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceStudentLoanPrincipalInterestVal
            += data[year].InterestExpenses['Student Loans'];
        }
      });
    }

    module5Data.advanceStudentLoanPrincipalInterest = advanceStudentLoanPrincipalInterestVal;
  }
  return module5Data.advanceStudentLoanPrincipalInterest;
}

// Car Loan Interest
export function advanceCarLoanInterest() {
  module5Data.advanceCarLoanInterest = 0;
  if (module5Data.start_year !== undefined && module5Data.start_year !== ''
    && module5Data.end_year !== undefined && module5Data.end_year !== '') {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceCarLoanInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceCarLoanInterestVal += data[year].InterestExpenses['Car Loans'];
        }
      });
    }
    module5Data.advanceCarLoanInterest = advanceCarLoanInterestVal;
  }
  return module5Data.advanceCarLoanInterest;
}

// Mortgage Interest
export function advanceMortgageInterest() {
  module5Data.advanceMortgageInterest = 0;
  if (module5Data.start_year !== undefined && module5Data.start_year !== ''
    && module5Data.end_year !== undefined && module5Data.end_year !== '') {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceMortgageInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceMortgageInterestVal += data[year].InterestExpenses.Mortgages;
        }
      });
    }

    module5Data.advanceMortgageInterest = advanceMortgageInterestVal;
  }
  return module5Data.advanceMortgageInterest;
}

// Total Financing Expenses
export function advanceTotalFinancingExpenses() {
  module5Data.advanceTotalFinancingExpenses = module5Data.advanceExistingCreditCardInterestInterest
    + module5Data.advanceStudentLoanPrincipalInterest
    + module5Data.advanceCarLoanInterest
    + module5Data.advanceMortgageInterest;
  return module5Data.advanceTotalFinancingExpenses;
}

// Income Earned
export function advanceIncomeEarned() {
  module5Data.advanceIncomeEarned = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceIncomeEarnedVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceIncomeEarnedVal += data[year]['Total Income'];
        }
      });
    }

    module5Data.advanceIncomeEarned = advanceIncomeEarnedVal;
  }
  return module5Data.advanceIncomeEarned;
}
// Living Expenses Paid
export function advanceLivingExpensesPaid() {
  module5Data.advanceLivingExpensesPaid = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let livingExpensesRentAdvance = 0;
    let livingExpensesUtilitiesAdvance = 0;
    let livingExpensesFoodAdvance = 0;
    let livingExpensesClothingAdvance = 0;
    let livingExpensesEntertainmentAdvance = 0;
    let livingExpensesTechnologyAdvance = 0;
    let livingExpensesTransportationAdvance = 0;
    let livingExpensesMiscellaneousAdvance = 0;
    let livingExpensesHealthCareAdvance = 0;
    let livingExpensesHealthInsuranceAdvance = 0;
    let livingExpensesPaidValAdvance = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          livingExpensesRentAdvance += data[year].livingExpenses.Rent;
          livingExpensesUtilitiesAdvance
            += data[year].livingExpenses.Utilities;
          livingExpensesFoodAdvance += data[year].livingExpenses.Food;
          livingExpensesClothingAdvance
            += data[year].livingExpenses.Clothing;
          livingExpensesEntertainmentAdvance
            += data[year].livingExpenses.Entertainment;
          livingExpensesTechnologyAdvance
            += data[year].livingExpenses.Technology;
          livingExpensesTransportationAdvance
            += data[year].livingExpenses.Transportation;
          livingExpensesMiscellaneousAdvance
            += data[year].livingExpenses.Miscellaneous;
          livingExpensesHealthCareAdvance
            += data[year].livingExpenses['Healthcare Costs other than Premium'];
          livingExpensesHealthInsuranceAdvance
            += data[year].livingExpenses['Health Insurance Premium'];
        }
      });
    }

    livingExpensesPaidValAdvance = livingExpensesRentAdvance
      + livingExpensesUtilitiesAdvance
      + livingExpensesFoodAdvance
      + livingExpensesClothingAdvance
      + livingExpensesEntertainmentAdvance
      + livingExpensesTechnologyAdvance
      + livingExpensesTransportationAdvance
      + livingExpensesMiscellaneousAdvance
      + livingExpensesHealthCareAdvance
      + livingExpensesHealthInsuranceAdvance;

    module5Data.advanceLivingExpensesPaid = livingExpensesPaidValAdvance;
  }
  return module5Data.advanceLivingExpensesPaid;
}

// Higher Education Expenses
export function advanceHigherEducationExpensesPaid() {
  module5Data.advanceHigherEducationExpensesPaid = 0;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let higherEducationExpensesTuitionAdvance = 0;
    let higherEducationExpensesRoomAdvance = 0;
    let higherEducationExpensesTuitionOffCampusFoodAdvance = 0;
    let higherEducationExpensesVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          higherEducationExpensesTuitionAdvance
            += data[year].livingExpenses['Higher Education Tuition and Fees'];
          higherEducationExpensesRoomAdvance
            += data[year].livingExpenses['Higher Education Room & Board'];
          higherEducationExpensesTuitionOffCampusFoodAdvance
            += data[year].livingExpenses['Off Campus Food'];
        }
      });
    }

    higherEducationExpensesVal = higherEducationExpensesTuitionAdvance
      + higherEducationExpensesRoomAdvance
      + higherEducationExpensesTuitionOffCampusFoodAdvance;
    module5Data.advanceHigherEducationExpensesPaid = higherEducationExpensesVal;
  }
  return module5Data.advanceHigherEducationExpensesPaid;
}

// Children Expenses
export function advanceChildrenExpensesPaid() {
  module5Data.advanceChildrenExpensesPaid = 0;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let childrenExpensesValAdvance = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          childrenExpensesValAdvance
            += data[year].livingExpenses.Children;
        }
      });
    }

    module5Data.advanceChildrenExpensesPaid = childrenExpensesValAdvance;
  }
  return module5Data.advanceChildrenExpensesPaid;
}

// Pet Expenses
export function advancePetExpensesPaid() {
  module5Data.advancePetExpensesPaid = 0;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let petExpensesValAdvance = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          petExpensesValAdvance += data[year].livingExpenses.Pet;
        }
      });
    }

    module5Data.advancePetExpensesPaid = petExpensesValAdvance;
  }
  return module5Data.advancePetExpensesPaid;
}

// Car Expenses Paid
export function advanceCarExpensesPaid() {
  module5Data.advanceCarExpensesPaid = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceCarExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceCarExpensesPaidVal += data[year]['Total Car Expenses'];
        }
      });
    }

    module5Data.advanceCarExpensesPaid = advanceCarExpensesPaidVal;
  }
  return module5Data.advanceCarExpensesPaid;
}
// Real Estate Expenses Paid
export function advanceRealEstateExpensesPaid() {
  module5Data.advanceRealEstateExpensesPaid = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceRealEstateExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceRealEstateExpensesPaidVal
            += data[year]['Total Real Estate Expenses'];
        }
      });
    }

    module5Data.advanceRealEstateExpensesPaid = advanceRealEstateExpensesPaidVal;
  }
  return module5Data.advanceRealEstateExpensesPaid;
}
// Financing Expenses Paid
export function advanceFinancingExpensesPaid() {
  module5Data.advanceFinancingExpensesPaid = 0;
  let advanceFinancingExpensesPaidVal1 = 0;
  let advanceFinancingExpensesPaidValFinal = 0;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    // let cashFlowData = cashFlowData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceFinancingExpensesPaidVal1
            += data[year]['Total Interest Expenses'];
        }
      });
    }

    advanceFinancingExpensesPaidValFinal = advanceFinancingExpensesPaidVal1;
    module5Data.advanceFinancingExpensesPaid = advanceFinancingExpensesPaidValFinal;
  }
  return module5Data.advanceFinancingExpensesPaid;
}
// Estimated Taxes Paid
export function advanceEstimatedTaxesPaid() {
  module5Data.advanceEstimatedTaxesPaid = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = incomeStatementData;
    let advanceEstimatedTaxesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          advanceEstimatedTaxesPaidVal += data[year]['Total Taxes'];
        }
      });
    }

    module5Data.advanceEstimatedTaxesPaid = advanceEstimatedTaxesPaidVal;
  }
  return module5Data.advanceEstimatedTaxesPaid;
}
// Total Expenses Paid
export function totalExpensesPaid() {
  module4Data.totalExpensesPaid = 0;
  module4Data.totalExpensesPaid = module4Data.estimatedTaxesPaid
    + module4Data.livingExpensesPaid
    + module4Data.higherEducationExpensesPaid
    + module4Data.childrenExpensesPaid
    + module4Data.petExpensesPaid
    + module4Data.carExpensesPaid
    + module4Data.realEstateExpensesPaid
    + module4Data.financingExpensesPaid;
  return module4Data.totalExpensesPaid;
}
// Net Income
export function advanceNetIncome() {
  module5Data.advanceNetIncome = 0;
  if (
    module5Data.totalExpensesCareerAdv !== undefined
    && module5Data.advanceIncomeEarned !== undefined
  ) {
    module5Data.advanceNetIncome = module5Data.totalExpensesCareerAdv + module5Data.advanceIncomeEarned;
  }
  return module5Data.advanceNetIncome;
}

// Retirement Contributions
export function finalRetirementContributionsCareerAdv() {
  module5Data.finalRetirementContributionsCareerAdv = 0;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue
            += data[year]['Cash from Investing Activities'][
            'Retirement Contributions'
            ];
        }
      });
    }

    module5Data.finalRetirementContributionsCareerAdv = -retirementContributionValue;
  }
  return module5Data.finalRetirementContributionsCareerAdv;
}

export function finalHsaContributionsCareerAdv() {
  module5Data.finalHsaContributionsCareerAdv = 0;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
  ) {
    const start_year = parseInt(module5Data.start_year, 10);
    const endYear = parseInt(module5Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue
            += data[year]['Cash from Investing Activities'][
            'HSA Contributions'
            ];
        }
      });
    }

    module5Data.finalHsaContributionsCareerAdv = -retirementContributionValue;
  }
  return module5Data.finalHsaContributionsCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function finalNetCashFlowCareerAdv() {
  module5Data.finalNetCashFlowCareerAdv = 0;
  if (
    module5Data.advanceNetIncome !== undefined
    && module5Data.finalRetirementContributionsCareerAdv !== undefined
  ) {
    module5Data.finalNetCashFlowCareerAdv = module5Data.advanceNetIncome
      - module5Data.finalRetirementContributionsCareerAdv;
  }
  return module5Data.finalNetCashFlowCareerAdv;
}

// Single Estimated Tax Rate
export function advanceSingleEstimatedTaxRate() {
  module5Data.advanceSingleEstimatedTaxRate = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year > 0
  ) {
    // module5Data.advanceSingleEstimatedTaxRate = estimatedIncomeTaxes.singleIteration3();
  }
  return parseFloat(module5Data.advanceSingleEstimatedTaxRate).toFixed(2);
}
// Single Estimated Taxes Paid
export function advanceSingleEstimatedTaxesPaid() {
  module5Data.advanceSingleEstimatedTaxesPaid = 0;
  if (
    module5Data.advanceIncomeEarned !== undefined
    && module5Data.advanceIncomeEarned !== ''
    && module5Data.advanceSingleEstimatedTaxRate !== undefined
    && module5Data.advanceSingleEstimatedTaxRate !== ''
  ) {
    module5Data.advanceSingleEstimatedTaxesPaid = -module5Data.advanceIncomeEarned
      * (module5Data.advanceSingleEstimatedTaxRate / 100);
  }
  return module5Data.advanceSingleEstimatedTaxesPaid;
}
// Single Income After Living Expenses
export function advanceSingleIncomeAfterLivingExpenses() {
  module5Data.advanceSingleIncomeAfterLivingExpenses = module5Data.advanceIncomeEarned
    + module5Data.advanceLivingExpensesPaid
    + module5Data.advanceFinancingExpensesPaid
    + module5Data.advanceSingleEstimatedTaxesPaid;
  return module5Data.advanceSingleIncomeAfterLivingExpenses;
}

// Married Estimated Tax Rate
export function advanceMarriedEstimatedTaxRate() {
  module5Data.advanceMarriedEstimatedTaxRate = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year > 0
  ) {
    // module5Data.advanceMarriedEstimatedTaxRate = estimatedIncomeTaxes.marriedIteration3();
  }
  return parseFloat(module5Data.advanceMarriedEstimatedTaxRate).toFixed(2);
}
// Married Estimated Taxes Paid
export function advanceMarriedEstimatedTaxesPaid() {
  module5Data.advanceMarriedEstimatedTaxesPaid = 0;
  if (
    module5Data.advanceIncomeEarned !== undefined
    && module5Data.advanceIncomeEarned !== ''
    && module5Data.advanceMarriedEstimatedTaxRate !== undefined
    && module5Data.advanceMarriedEstimatedTaxRate !== ''
  ) {
    module5Data.advanceMarriedEstimatedTaxesPaid = -module5Data.advanceIncomeEarned
      * (module5Data.advanceMarriedEstimatedTaxRate / 100);
  }
  return module5Data.advanceMarriedEstimatedTaxesPaid;
}
// Married Income After Living Expenses
export function advanceMarriedIncomeAfterLivingExpenses() {
  module5Data.advanceMarriedIncomeAfterLivingExpenses = module5Data.advanceIncomeEarned
    + module5Data.advanceLivingExpensesPaid
    + module5Data.advanceFinancingExpensesPaid
    + module5Data.advanceMarriedEstimatedTaxesPaid;
  return module5Data.advanceMarriedIncomeAfterLivingExpenses;
}

// Employer Matching Rate on Personal Contribution
export function careerAdv401EmployerMatchingrateCalcs() {
  module5Data.careerAdv401EmployerMatchingrateCalcs = 0;
  if (
    module5Data.contribute_401k !== undefined
    && module5Data.contribute_401k === 'Yes'
    && module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs
    !== undefined
    && module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs !== ''
    && module5Data.employer_matching_rate !== undefined
    && module5Data.employer_matching_rate !== ''
  ) {
    module5Data.careerAdv401EmployerMatchingrateCalcs = (module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs / 100)
      * module5Data.employer_matching_rate;
  }
  return module5Data.careerAdv401EmployerMatchingrateCalcs;
}

// Employer Matching Limit as a % of Base Income
export function careerAdv401EmployerMatchingLimitCalcs() {
  module5Data.careerAdv401EmployerMatchingLimitCalcs = 0;
  if (
    module5Data.contribute_401k !== undefined
    && module5Data.contribute_401k === 'Yes'
    && module5Data.advanceTotalPreTaxExpected_val !== undefined
    && module5Data.advanceTotalPreTaxExpected_val !== ''
    && module5Data.employer_matching_limit !== undefined
    && module5Data.employer_matching_limit !== ''
  ) {
    module5Data.careerAdv401EmployerMatchingLimitCalcs = (module5Data.advanceTotalPreTaxExpected_val / 100)
      * module5Data.employer_matching_limit;
  }
  return module5Data.careerAdv401EmployerMatchingLimitCalcs;
}

// Personal Contribution (Max of $00 by law)
export function careerAdv401PersonalContribution401k() {
  module5Data.careerAdv401PersonalContribution_401k = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['401(k) 2018 Contribution Limits'][
    module5Data.start_year
    ] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][
    module5Data.start_year
    ]['Max Personal Limit'] !== undefined
  ) {
    module5Data.careerAdv401PersonalContribution_401k = databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year][
      'Max Personal Limit'
    ];
  } else {
    module5Data.careerAdv401PersonalContribution_401k = 0;
  }
  return module5Data.careerAdv401PersonalContribution_401k;
}

export function careerAdv401PersonalContribution() {
  module5Data.careerAdv401PersonalContribution = 0;

  if (module5Data.start_year !== undefined &&
    module5Data.start_year !== '' &&
    databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year] !== undefined &&
    databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year]['Max Personal Limit'] !== undefined) {
    module5Data.careerAdv401PersonalContribution = databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year]['Max Personal Limit'];
  }
  else {
    module5Data.careerAdv401PersonalContribution = 0;

  }
  return module5Data.careerAdv401PersonalContribution;
}

// Employer Contribution
export function careerAdv401EmployerContribution() {
  module5Data.careerAdv401EmployerContribution = 0;
  let employerContribution1 = 0;
  let employerContribution2 = 0;
  let employerContribution3 = 0;

  if (
    module5Data.careerAdv401EmployerMatchingrateCalcs !== undefined
    && module5Data.careerAdv401EmployerMatchingrateCalcs !== ''
    && module5Data.careerAdv401EmployerMatchingLimitCalcs !== undefined
    && module5Data.careerAdv401EmployerMatchingLimitCalcs !== ''
    && module5Data.careerAdv401EmployerMatchingrateCalcs
    > module5Data.careerAdv401EmployerMatchingLimitCalcs
  ) {
    employerContribution1 = module5Data.careerAdv401EmployerMatchingLimitCalcs;
  } else {
    employerContribution1 = module5Data.careerAdv401EmployerMatchingrateCalcs;
  }
  if (
    module5Data.careerAdv401PersonalContribution !== undefined
    && module5Data.careerAdv401PersonalContribution !== ''
  ) {
    employerContribution2 = module5Data.careerAdv401PersonalContribution;
  }
  employerContribution3 = employerContribution1 + employerContribution2;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['401(k) 2018 Contribution Limits'][
    module5Data.start_year
    ] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][
    module5Data.start_year
    ]['Total Limit'] !== undefined
    && employerContribution3
    > databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year][
    'Total Limit'
    ]
  ) {
    module5Data.careerAdv401EmployerContribution = databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year][
      'Total Limit'
    ] - employerContribution2;
  } else if (
    module5Data.careerAdv401EmployerMatchingrateCalcs
    !== undefined
    && module5Data.careerAdv401EmployerMatchingrateCalcs !== ''
    && module5Data.careerAdv401EmployerMatchingLimitCalcs
    !== undefined
    && module5Data.careerAdv401EmployerMatchingLimitCalcs !== ''
    && module5Data.careerAdv401EmployerMatchingrateCalcs
    > module5Data.careerAdv401EmployerMatchingLimitCalcs
  ) {
    module5Data.careerAdv401EmployerContribution = module5Data.careerAdv401EmployerMatchingLimitCalcs;
  } else {
    module5Data.careerAdv401EmployerContribution = module5Data.careerAdv401EmployerMatchingrateCalcs;
  }
  return module5Data.careerAdv401EmployerContribution;
}

// Total Annual Contribution Value
export function careerAdv401TotalAnnualContribution() {
  module5Data.careerAdv401TotalAnnualContribution = 0;

  if (
    module5Data.careerAdv401PersonalContribution !== undefined
    && module5Data.careerAdv401EmployerContribution !== undefined
  ) {
    module5Data.careerAdv401TotalAnnualContribution = module5Data.careerAdv401PersonalContribution
      + module5Data.careerAdv401EmployerContribution;
  }
  return module5Data.careerAdv401TotalAnnualContribution;
}
// Beginning Balance In year After
export function beginningBalanceInYearSecond() {
  module5Data.beginningBalanceInYearSecond = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
  ) {
    if (
      investmentsData !== undefined
      && investmentsData !== ''
      && investmentsData['401k Second'] !== undefined
      && investmentsData['401k Second'] !== ''
      && investmentsData['401k Second'][module5Data.start_year]
      !== undefined
      && investmentsData['401k Second'][module5Data.start_year] !== ''
    ) {
      module5Data.beginningBalanceInYearSecond = investmentsData['401k Second'][module5Data.start_year]['Total Beginning Balance'];
    }
  }
  return module5Data.beginningBalanceInYearSecond;
}

//New by SRINU BeginningRothBalanceOf401kAdv
export function careerAdvpersonal401ContributionBaseIncomeCalcs() {
  module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs = 0;
  var firstEle = 0;
  var firstEle1 = 0;
  var firstEle2 = 0;
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.base_income !== undefined && module5Data.base_income !== '') {
    firstEle1 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.base_income;
  }
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.base_roth_income !== undefined && module5Data.base_roth_income !== '') {
    firstEle2 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.base_roth_income;
  }
  firstEle = firstEle1 + firstEle2;
  if (module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.careerAdv401PersonalContribution !== undefined && module5Data.careerAdv401PersonalContribution !== '' && firstEle <= module5Data.careerAdv401PersonalContribution) {
    module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs = firstEle1;
  } else {
    var base_income = 0;
    if (module5Data.base_income !== undefined && module5Data.base_income !== '') {
      base_income = parseFloat(module5Data.base_income);
    }
    var base_roth_income = 0;
    if (module5Data.base_roth_income !== undefined && module5Data.base_roth_income !== '') {
      base_roth_income = parseFloat(module5Data.base_roth_income);
    }
    if (module5Data.careerAdv401PersonalContribution !== undefined && module5Data.careerAdv401PersonalContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs = (base_income / (base_income + base_roth_income)) * module5Data.careerAdv401PersonalContribution;
      } else {
        module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs = 0;
      }
    } else {
      module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs = 0;
    }
  }
  return module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs;
}
export function BeginningRothBalanceOf401kAdv() {
  module5Data.BeginningRothBalanceOf401kAdv = 0;
  if (module5Data.start_year !== undefined && module5Data.start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k Second'] !== undefined && investmentsData['Roth 401k Second'] !== '' && investmentsData['Roth 401k Second'][module5Data.start_year] !== undefined && investmentsData['Roth 401k Second'][module5Data.start_year] !== '') {
      module5Data.BeginningRothBalanceOf401kAdv = investmentsData['Roth 401k Second'][module5Data.start_year]['Total Beginning Balance'];
    }
  }
  return module5Data.BeginningRothBalanceOf401kAdv;
}
export function careerAdvpersonal401ContributionBaseIncomeRothCalcs() {
  module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs = 0;
  var firstEle = 0;
  var firstEle1 = 0;
  var firstEle2 = 0;
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.base_income !== undefined && module5Data.base_income !== '') {
    firstEle1 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.base_income;
  }
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.base_roth_income !== undefined && module5Data.base_roth_income !== '') {
    firstEle2 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.base_roth_income;
  }
  firstEle = firstEle1 + firstEle2;
  if (module5Data.ContributeRoth401kAdv !== undefined && module5Data.ContributeRoth401kAdv === 'Yes' && module5Data.careerAdv401PersonalContribution !== undefined && module5Data.careerAdv401PersonalContribution !== '' && firstEle <= module5Data.careerAdv401PersonalContribution) {
    module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs = firstEle2;
  } else {
    var base_income = 0;
    if (module5Data.base_income !== undefined && module5Data.base_income !== '') {
      base_income = parseFloat(module5Data.base_income);
    }
    var base_roth_income = 0;
    if (module5Data.base_roth_income !== undefined && module5Data.base_roth_income !== '') {
      base_roth_income = parseFloat(module5Data.base_roth_income);
    }
    if (module5Data.careerAdv401PersonalContribution !== undefined && module5Data.careerAdv401PersonalContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs = (base_roth_income / (base_income + base_roth_income)) * module5Data.careerAdv401PersonalContribution;
      } else {
        module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs = 0;
      }
    } else {
      module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs = 0;
    }
  }

  return module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs;
}

export function advCareerRothIRAEndingBalanceRoth() {
  module5Data.advCareerRothIRAEndingBalanceRoth = 0;
  if (module5Data.advCareerRothIRATotalEarningsRoth !== undefined && module5Data.advCareerRothIRATotalContributionRoth !== undefined && module5Data.advCareerRothIRABeginningBalanceIra !== undefined) {
    module5Data.advCareerRothIRAEndingBalanceRoth = module5Data.advCareerRothIRATotalEarningsRoth + module5Data.advCareerRothIRATotalContributionRoth + module5Data.advCareerRothIRABeginningBalanceIra;
  }
  return module5Data.advCareerRothIRAEndingBalanceRoth;
}
export function advCareerRothIRABeginningBalanceIra() {
  module5Data.advCareerRothIRABeginningBalanceIra = 0;
  if (module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && investmentsData !== undefined
    && investmentsData !== ''
    && investmentsData['Roth IRA Second'] !== undefined
    && investmentsData['Roth IRA Second'] !== ''
    && investmentsData['Roth IRA Second'][module5Data.start_year] !== undefined
    && investmentsData['Roth IRA Second'][module5Data.start_year] !== ''
    && investmentsData['Roth IRA Second'][module5Data.start_year]['Beginning Balance'] !== undefined) {
    module5Data.advCareerRothIRABeginningBalanceIra = investmentsData['Roth IRA Second'][module5Data.start_year]['Beginning Balance'];
  }
  return module5Data.advCareerRothIRABeginningBalanceIra;
}

export function advCareerTotalContribution() {
  module5Data.advCareerTotalContribution = 0;
  module5Data.advCareerTotalContribution = module5Data.advCareerRothIRAPersonalContributionCalcs + module5Data.advCareerRothIRAPersonalContributionCalcsRoth;
  return module5Data.advCareerTotalContribution;
}

export function careerAdv401PersonalContributionTotal() {
  module5Data.careerAdv401PersonalContributionTotal = 0;
  if (module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs !== undefined) {
    module5Data.careerAdv401PersonalContributionTotal += module5Data.careerAdvpersonal401ContributionBaseIncomeCalcs;
  }
  if (module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs !== undefined) {
    module5Data.careerAdv401PersonalContributionTotal += module5Data.careerAdvpersonal401ContributionBaseIncomeRothCalcs;
  }
  return module5Data.careerAdv401PersonalContributionTotal;
}
export function advCareerHsaBeginningBalance() {
  module5Data.advCareerHsaBeginningBalance = 0;
  if (module5Data.start_year !== undefined && module5Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['HSA Second'] !== undefined && investmentsData['HSA Second'] !== '' && investmentsData['HSA Second'][module5Data.start_year] !== undefined && investmentsData['HSA Second'][module5Data.start_year] !== '' && investmentsData['HSA Second'][module5Data.start_year]['Beginning Balance'] !== undefined) {
    module5Data.advCareerHsaBeginningBalance = investmentsData['HSA Second'][module5Data.start_year]['Beginning Balance'];
  }
  return module5Data.advCareerHsaBeginningBalance;
}
export function advCareerHsaPersonalContributionCalcs() {
  module5Data.advCareerHsaPersonalContributionCalcs = 0;
  var incomeValue = 0;
  if (module5Data.hsa_personal_contribution !== undefined && module5Data.hsa_personal_contribution !== '' && module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '') {
    incomeValue = ((module5Data.advanceTotalPreTaxExpected_val) / 100) * module5Data.hsa_personal_contribution;
  }
  if (module5Data.maxHsaContributionByLaw !== undefined && incomeValue > module5Data.maxHsaContributionByLaw) {
    module5Data.advCareerHsaPersonalContributionCalcs = module5Data.maxHsaContributionByLaw;
  } else {
    module5Data.advCareerHsaPersonalContributionCalcs = incomeValue;
  }
  return module5Data.advCareerHsaPersonalContributionCalcs;
}

export function maxHsaContributionByLaw() {
  module5Data.maxHsaContributionByLaw = 0;
  if (module5Data.start_year !== undefined && module5Data.start_year !== '' && databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['HSA Limits'][module5Data.start_year] !== undefined) {
    if (module10Data.marriage_year !== undefined && module10Data.marriage_year !== '' && parseInt(module5Data.start_year) >= parseInt(module10Data.marriage_year)) {
      if (databaseData['HSA Limits'][module5Data.start_year]['Married'] !== undefined && databaseData['HSA Limits'][module5Data.start_year]['Married'] !== '') {
        module5Data.maxHsaContributionByLaw = databaseData['HSA Limits'][module5Data.start_year]['Married'];
      }
    } else {
      if (databaseData['HSA Limits'][module5Data.start_year]['Single'] !== undefined && databaseData['HSA Limits'][module5Data.start_year]['Single'] !== '') {
        module5Data.maxHsaContributionByLaw = databaseData['HSA Limits'][module5Data.start_year]['Single'];
      }
    }
  }
  return module5Data.maxHsaContributionByLaw;
}
export function advCareerHsaBeginningBalanceSummary() {
  module5Data.advCareerHsaBeginningBalanceSummary = 0;
  if (module5Data.contribute_hsa_savings_account !== undefined && module5Data.contribute_hsa_savings_account === 'Yes' && module5Data.advCareerHsaBeginningBalance !== undefined && module5Data.advCareerHsaBeginningBalance !== '') {
    module5Data.advCareerHsaBeginningBalanceSummary = module5Data.advCareerHsaBeginningBalance;
  }
  return module5Data.advCareerHsaBeginningBalanceSummary;
}
export function advCareerHsaTotalContributionSummary() {
  module5Data.advCareerHsaTotalContributionSummary = 0;
  var totalContribution = 0;
  var data = investmentsData['HSA Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalContribution += data[year]['Annual Contribution'];
      }
    })
  }

  module5Data.advCareerHsaTotalContributionSummary = totalContribution;
  return module5Data.advCareerHsaTotalContributionSummary;
}
export function advCareerHsaTotalEarningsSummary() {
  module5Data.advCareerHsaTotalEarningsSummary = 0;

  var totalEarningsVal = 0;
  var data = investmentsData['HSA Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalEarningsVal += data[year]['Earnings'];
      }
    })
  }
  module5Data.advCareerHsaTotalEarningsSummary = totalEarningsVal;
  return module5Data.advCareerHsaTotalEarningsSummary;
}
export function advCareerHsaTotalEndingBalanceSummary() {
  module5Data.advCareerHsaTotalEndingBalanceSummary = 0;
  module5Data.advCareerHsaTotalEndingBalanceSummary = module5Data.advCareerHsaBeginningBalanceSummary + module5Data.advCareerHsaTotalContributionSummary + module5Data.advCareerHsaTotalEarningsSummary;
  return module5Data.advCareerHsaTotalEndingBalanceSummary;
}
export function beginningBalanceInYearNextRoth() {
  module5Data.beginningBalanceInYearNextRoth = 0;
  if (module5Data.roth_contribute_401k !== undefined && module5Data.roth_contribute_401k === 'Yes' && module5Data.BeginningRothBalanceOf401kAdv !== undefined && module5Data.BeginningRothBalanceOf401kAdv !== '') {
    module5Data.beginningBalanceInYearNextRoth = module5Data.BeginningRothBalanceOf401kAdv;
  }
  return module5Data.beginningBalanceInYearNextRoth;
}
export function personalContributionAetrFewYearsRoth() {
  module5Data.personalContributionAetrFewYearsRoth = 0;
  var personalContributionVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        personalContributionVal += data[year]['Personal Contribution'];
      }
    })
  }

  module5Data.personalContributionAetrFewYearsRoth = personalContributionVal;
  return module5Data.personalContributionAetrFewYearsRoth;
}
export function earningsOnPersonalContributionsRoth() {
  module5Data.earningsOnPersonalContributionsRoth = 0;
  var personalEarningsVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        personalEarningsVal += data[year]['Personal Earnings'];
      }
    })
  }

  module5Data.earningsOnPersonalContributionsRoth = personalEarningsVal;

  return module5Data.earningsOnPersonalContributionsRoth;
}
export function totalPersonalContributionsAndEarningsCareerAdvRoth() {
  module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth = 0;
  module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth = module5Data.personalContributionAetrFewYearsRoth + module5Data.earningsOnPersonalContributionsRoth;
  return module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth;
}
export function employerContributionAfterFewYearsRoth() {
  module5Data.employerContributionAfterFewYearsRoth = 0;
  var employerContributionVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        employerContributionVal += data[year]['Employer Contribution'];
      }
    })
  }

  module5Data.employerContributionAfterFewYearsRoth = employerContributionVal;

  return module5Data.employerContributionAfterFewYearsRoth;
}

export function earningsOnEmployerContributionsAfterRoth() {
  module5Data.earningsOnEmployerContributionsAfterRoth = 0;
  var employerEarningsVal = 0;
  var data = investmentsData['Roth 401k Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        employerEarningsVal += data[year]['Employer Earnings'];
      }
    })
  }
  module5Data.earningsOnEmployerContributionsAfterRoth = employerEarningsVal;

  return module5Data.earningsOnEmployerContributionsAfterRoth;
}
export function totalEmployerContributionAndEarningsRoth() {
  module5Data.totalEmployerContributionAndEarningsRoth = 0;
  module5Data.totalEmployerContributionAndEarningsRoth = module5Data.employerContributionAfterFewYearsRoth + module5Data.earningsOnEmployerContributionsAfterRoth;
  return module5Data.totalEmployerContributionAndEarningsRoth;
}
export function balanceBeforeVestingReductionsCareerAdvRoth() {
  module5Data.balanceBeforeVestingReductionsCareerAdvRoth = 0;
  module5Data.balanceBeforeVestingReductionsCareerAdvRoth = module5Data.beginningBalanceInYearNextRoth + module5Data.totalPersonalContributionsAndEarningsCareerAdvRoth + module5Data.totalEmployerContributionAndEarningsRoth;
  return module5Data.balanceBeforeVestingReductionsCareerAdvRoth;
}
export function employerContributionsAndEarningsNotVestedRoth() {
  module5Data.employerContributionsAndEarningsNotVestedRoth = 0;
  if (module5Data.roth_contribute_401k !== undefined && module5Data.roth_contribute_401k === 'Yes') {
    module5Data.employerContributionsAndEarningsNotVestedRoth = ((module5Data.totalEmployerContributionAndEarningsRoth / 100) * - (1 - (module5Data.vested_amount_at_end_year / 100))) * 100;
  }
  return module5Data.employerContributionsAndEarningsNotVestedRoth;
}
export function endingBalanceInyearLastRoth() {
  module5Data.endingBalanceInyearLastRoth = 0;
  module5Data.endingBalanceInyearLastRoth = module5Data.balanceBeforeVestingReductionsCareerAdvRoth + module5Data.employerContributionsAndEarningsNotVestedRoth;
  return module5Data.endingBalanceInyearLastRoth;
}
export function advCareerRothIRATotalContributionRoth() {
  module5Data.advCareerRothIRATotalContributionRoth = 0;
  var totalContributionsVal = 0;
  var data = investmentsData['Roth IRA Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.start_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalContributionsVal += data[year]['Annual Contribution'];
      }
    })
  }
  module5Data.advCareerRothIRATotalContributionRoth = totalContributionsVal;

  return module5Data.advCareerRothIRATotalContributionRoth;
}
export function advCareerRothIRATotalEarningsRoth() {
  module5Data.advCareerRothIRATotalEarningsRoth = 0;

  var totalEarningsVal = 0;
  var data = investmentsData['Roth IRA Second'];
  var start_year = module5Data.start_year;
  var endYear = module5Data.start_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalEarningsVal += data[year]['Earnings'];
      }
    })
  }
  module5Data.advCareerRothIRATotalEarningsRoth = totalEarningsVal;

  return module5Data.advCareerRothIRATotalEarningsRoth;
}
//New Done

// Beginning Balance In year After
export function beginningBalanceInYearNext() {
  module5Data.beginningBalanceInYearNext = 0;
  if (
    module5Data.beginningBalanceInYearSecond !== undefined
    && module5Data.beginningBalanceInYearSecond !== ''
  ) {
    module5Data.beginningBalanceInYearNext = module5Data.beginningBalanceInYearSecond;
  }
  return module5Data.beginningBalanceInYearNext;
}

// Personal Contributions After 2 Years
export function personalContributionAetrFewYears() {
  module5Data.personalContributionAetrFewYears = 0;
  let personalContributionVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalContributionVal += data[year]['Personal Contribution'];
        }
      });
    }
  }
  module5Data.personalContributionAetrFewYears = personalContributionVal;
  return module5Data.personalContributionAetrFewYears;
}

// Earnings on Personal Contributions After 2 Years
export function earningsOnPersonalContributions() {
  module5Data.earningsOnPersonalContributions = 0;
  let personalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalEarningsVal += data[year]['Personal Earnings'];
        }
      });
    }
  }

  module5Data.earningsOnPersonalContributions = personalEarningsVal;

  return module5Data.earningsOnPersonalContributions;
}

// Earnings on Personal Contributions After 2 Years
export function totalPersonalContributionsAndEarningsCareerAdv() {
  module5Data.totalPersonalContributionsAndEarningsCareerAdv = 0;
  module5Data.totalPersonalContributionsAndEarningsCareerAdv = module5Data.personalContributionAetrFewYears
    + module5Data.earningsOnPersonalContributions;
  return module5Data.totalPersonalContributionsAndEarningsCareerAdv;
}

// Employer Contributions After 2 Years
export function employerContributionAfterFewYears() {
  module5Data.employerContributionAfterFewYears = 0;
  let employerContributionVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerContributionVal += data[year]['Employer Contribution'];
        }
      });
    }
  }

  module5Data.employerContributionAfterFewYears = employerContributionVal;

  return module5Data.employerContributionAfterFewYears;
}

// Earnings on Employer Contributions After 2 Years
export function earningsOnEmployerContributionsAfter() {
  module5Data.earningsOnEmployerContributionsAfter = 0;
  let employerEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerEarningsVal += data[year]['Employer Earnings'];
        }
      });
    }
  }
  module5Data.earningsOnEmployerContributionsAfter = employerEarningsVal;

  return module5Data.earningsOnEmployerContributionsAfter;
}

// Total Employer Contributions & Earnings
export function totalEmployerContributionAndEarnings() {
  module5Data.totalEmployerContributionAndEarnings = 0;
  module5Data.totalEmployerContributionAndEarnings = module5Data.employerContributionAfterFewYears
    + module5Data.earningsOnEmployerContributionsAfter;
  return module5Data.totalEmployerContributionAndEarnings;
}

// 401(k) Balance before Vesting Reductions
export function balanceBeforeVestingReductionsCareerAdv() {
  module5Data.balanceBeforeVestingReductionsCareerAdv = 0;
  module5Data.balanceBeforeVestingReductionsCareerAdv = module5Data.beginningBalanceInYearSecond
    + module5Data.totalPersonalContributionsAndEarningsCareerAdv
    + module5Data.totalEmployerContributionAndEarnings;
  return module5Data.balanceBeforeVestingReductionsCareerAdv;
}

// % Vested after 2 years at 25%/yr Schedule
export function vestedAfteryearAtSchedule() {
  module5Data.vestedAfteryearAtSchedule = 0;
  if (
    module5Data.contribute_401k !== undefined
    && module5Data.contribute_401k === 'Yes'
    && module5Data.end_year !== undefined
  ) {
    if (databaseData !== undefined && databaseData !== '') {
      module5Data.vestedAfteryearAtSchedule = databaseData.Data['401(k) Vesting Schedule'][(parseInt(module5Data.end_year, 10) - parseInt(module5Data.start_year, 10)) + 1];
    }
  }
  return module5Data.vestedAfteryearAtSchedule;
}

// % of Employer Contributions & Earnings Not Vested
export function employerContributionsAndEarningsNotVested() {
  module5Data.employerContributionsAndEarningsNotVested = 0;
  if (
    module5Data.contribute_401k !== undefined
    && module5Data.contribute_401k === 'Yes'
  ) {
    module5Data.employerContributionsAndEarningsNotVested = (module5Data.totalEmployerContributionAndEarnings / 100)
      * -(1 - module5Data.vestedAfteryearAtSchedule / 100)
      * 100;
  }
  return module5Data.employerContributionsAndEarningsNotVested;
}

// Ending 401(k) Balance in Year 2026
export function endingBalanceInyearLast() {
  module5Data.endingBalanceInyearLast = 0;
  module5Data.endingBalanceInyearLast = module5Data.balanceBeforeVestingReductionsCareerAdv
    + module5Data.employerContributionsAndEarningsNotVested;
  return module5Data.endingBalanceInyearLast;
}

// Roth IRA Retirement Account
// Roth IRA Retirement Beginning Balance
export function advCareerRothIRABeginningBalance() {
  module5Data.advCareerRothIRABeginningBalance = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && investmentsData !== undefined
    && investmentsData !== ''
    && investmentsData['Roth IRA Second'] !== undefined
    && investmentsData['Roth IRA Second'] !== ''
    && investmentsData['Roth IRA Second'][module5Data.start_year] !== undefined
    && investmentsData['Roth IRA Second'][module5Data.start_year] !== ''
    && investmentsData['Roth IRA Second'][module5Data.start_year]['Beginning Balance'] !== undefined
  ) {
    module5Data.advCareerRothIRABeginningBalance = investmentsData['Roth IRA Second'][module5Data.start_year]['Beginning Balance'];
  }
  return module5Data.advCareerRothIRABeginningBalance;
}

// Roth IRA Retirement Personal Contribution as Base Income
export function advCareerRothIRAPersonalContributionCalcs() {
  module5Data.advCareerRothIRAPersonalContributionCalcs = 0;
  var firstEle = 0;
  var firstEle1 = 0;
  var firstEle2 = 0;
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.roth_personal_contribution !== undefined && module5Data.roth_personal_contribution !== '') {
    firstEle1 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.roth_personal_contribution;
  }
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.ira_roth_personal_contribution !== undefined && module5Data.ira_roth_personal_contribution !== '') {
    firstEle2 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.ira_roth_personal_contribution;
  }
  if (firstEle1 > 0) {
    firstEle = firstEle1 + firstEle2;
    if (module5Data.roth_contribute !== undefined && module5Data.roth_contribute === 'Yes' && module5Data.advCareerRothIRAPersonalContribution !== undefined && module5Data.careerAdv401PersonalContribution !== '' && firstEle <= module5Data.advCareerRothIRAPersonalContribution) {
      module5Data.advCareerRothIRAPersonalContributionCalcs = firstEle1;
    } else {
      var baseIncome = 0;
      if (module5Data.roth_personal_contribution !== undefined && module5Data.roth_personal_contribution !== '') {
        baseIncome = parseFloat(module5Data.roth_personal_contribution);
      }
      var baseIncomeRoth = 0;
      if (module5Data.ira_roth_personal_contribution !== undefined && module5Data.ira_roth_personal_contribution !== '') {
        baseIncomeRoth = parseFloat(module5Data.ira_roth_personal_contribution);
      }
      if (module5Data.advCareerRothIRAPersonalContribution !== undefined && module5Data.advCareerRothIRAPersonalContribution !== '') {
        if (baseIncome > 0 || baseIncomeRoth > 0) {
          module5Data.advCareerRothIRAPersonalContributionCalcs = (baseIncome / (baseIncome + baseIncomeRoth)) * module5Data.advCareerRothIRAPersonalContribution;
        } else {
          module5Data.advCareerRothIRAPersonalContributionCalcs = 0;
        }
      } else {
        module5Data.advCareerRothIRAPersonalContributionCalcs = 0;
      }
    }
  }
  return module5Data.advCareerRothIRAPersonalContributionCalcs;
}
// Roth IRA Retirement Personal Contribution
export function advCareerRothIRAPersonalContributionrothIra() {
  module5Data.advCareerRothIRAPersonalContribution_rothIra = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Roth IRA 2018 Contribution Limit'][
    module5Data.start_year
    ] !== undefined
    && databaseData['Roth IRA 2018 Contribution Limit'][
    module5Data.start_year
    ]['Max Personal Limit'] !== undefined
  ) {
    module5Data.advCareerRothIRAPersonalContribution_rothIra = databaseData['Roth IRA 2018 Contribution Limit'][module5Data.start_year][
      'Max Personal Limit'
    ];
  } else {
    module5Data.advCareerRothIRAPersonalContribution_rothIra = 0;
  }
  return module5Data.advCareerRothIRAPersonalContribution_rothIra;
}
export function advCareerRothIRAPersonalContribution() {
  module5Data.advCareerRothIRAPersonalContribution = 0;
  if (module5Data.start_year !== undefined && module5Data.start_year !== '' && databaseData !== undefined && databaseData !== '' &&
    databaseData['Roth IRA 2018 Contribution Limit'][module5Data.start_year] !== undefined &&
    databaseData['Roth IRA 2018 Contribution Limit'][module5Data.start_year]['Max Personal Limit'] !== undefined) {
    module5Data.advCareerRothIRAPersonalContribution = databaseData['Roth IRA 2018 Contribution Limit'][module5Data.start_year]['Max Personal Limit'];
  }
  return module5Data.advCareerRothIRAPersonalContribution;
}

// Roth IRA Retirement Account Summary

// Roth IRA Retirement Total Contribution
export function advCareerRothIRATotalContribution() {
  module5Data.advCareerRothIRATotalContribution = 0;
  let totalContributionsVal = 0;
  if (investmentsData !== undefined && investmentsData['Roth IRA Second']) {
    const data = investmentsData['Roth IRA Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContributionsVal += data[year]['Annual Contribution'];
        }
      });
    }
  }

  module5Data.advCareerRothIRATotalContribution = totalContributionsVal;

  return module5Data.advCareerRothIRATotalContribution;
}

// Roth IRA Retirement Total Earnings
export function advCareerRothIRATotalEarnings() {
  module5Data.advCareerRothIRATotalEarnings = 0;

  let totalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['Roth IRA Second']) {
    const data = investmentsData['Roth IRA Second'];
    const start_year = module5Data.start_year;

    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarningsVal += data[year].Earnings;
        }
      });
    }
  }

  module5Data.advCareerRothIRATotalEarnings = totalEarningsVal;

  return module5Data.advCareerRothIRATotalEarnings;
}

// Roth IRA Retirement Total Balance
export function advCareerRothIRAEndingBalance() {
  module5Data.advCareerRothIRAEndingBalance = 0;
  if (
    module5Data.advCareerRothIRATotalEarnings !== undefined
    && module5Data.advCareerRothIRATotalContribution !== undefined
    && module5Data.advCareerRothIRABeginningBalance !== undefined
  ) {
    module5Data.advCareerRothIRAEndingBalance = module5Data.advCareerRothIRATotalEarnings
      + module5Data.advCareerRothIRATotalContribution
      + module5Data.advCareerRothIRABeginningBalance;
  }
  return module5Data.advCareerRothIRAEndingBalance;
}

/* SEP IRA */

// Beginning Balance
export function advCareerSEPIRABeginningBalance() {
  module5Data.advCareerSEPIRABeginningBalance = 0;
  if (
    module5Data.start_year !== undefined
    && investmentsData !== undefined
    && investmentsData !== ''
    && investmentsData['Seph IRA Second'] !== undefined
    && investmentsData['Seph IRA Second'][module5Data.start_year]
    !== undefined
    && investmentsData['Seph IRA Second'][module5Data.start_year] !== ''
    && investmentsData['Seph IRA Second'][module5Data.start_year][
    'Beginning Balance'
    ] !== undefined
  ) {
    module5Data.advCareerSEPIRABeginningBalance = investmentsData['Seph IRA Second'][module5Data.start_year][
      'Beginning Balance'
    ];
  }
  return module5Data.advCareerSEPIRABeginningBalance;
}

// SEP IRA Retirement Personal Contribution as Base Income
export function advCareerSEPIRAPersonalContributionCalcs() {
  module5Data.advCareerSEPIRAPersonalContributionCalcs = 0;
  if (module5Data.ira_contribute !== undefined && module5Data.ira_contribute === 'Yes') {
    if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && databaseData !== undefined
      && databaseData !== '' && databaseData.Data !== undefined && module5Data.ira_personal_contribution !== undefined && module5Data.ira_personal_contribution !== '') {
      if (module5Data.advanceTotalPreTaxExpected_val > databaseData.Data['SEP IRA Limits Second'] && databaseData.Data['SEP IRA Limits Rate'] === module5Data.ira_personal_contribution) {
        module5Data.advCareerSEPIRAPersonalContributionCalcs = databaseData.Data['SEP IRA Limits Rate'] * (databaseData.Data['SEP IRA Limits Second'] / 100);
      } else {
        module5Data.advCareerSEPIRAPersonalContributionCalcs = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.ira_personal_contribution;
      }
    }
  } else {
    module5Data.advCareerSEPIRAPersonalContributionCalcs = 0;
  }
  return module5Data.advCareerSEPIRAPersonalContributionCalcs;
}

// Personal Contribution (Max of the Lesser of 0% of Income or $00 by law)
export function advCareerSEPIRAPersonalContributionsepIra() {
  module5Data.advCareerSEPIRAPersonalContribution_sepIra = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][
    module5Data.start_year
    ] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module5Data.start_year][
    'Max Personal Limit'
    ] !== undefined
  ) {
    module5Data.advCareerSEPIRAPersonalContribution_sepIra = databaseData['SEP IRA Contribution Limit'][module5Data.start_year][
      'Max Personal Limit'
    ];
  } else {
    module5Data.advCareerSEPIRAPersonalContribution_sepIra = 0;
  }
  return module5Data.advCareerSEPIRAPersonalContribution_sepIra;
}

export function advCareerSEPIRAPersonalContribution() {
  module5Data.advCareerSEPIRAPersonalContribution = 0;
  let sepPersonalContribution1 = 0;
  let sepPersonalContribution2 = 0;
  let sepPersonalContribution3 = 0;
  let sepPersonalContribution = 0;

  if (
    module5Data.advanceTotalPreTaxExpected_val !== undefined
    && module5Data.advanceTotalPreTaxExpected_val !== ''
    && module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][
    module5Data.start_year
    ] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module5Data.start_year][
    'Total Limit'
    ] !== undefined
  ) {
    if (
      module5Data.advanceTotalPreTaxExpected_val
      > databaseData['SEP IRA Contribution Limit'][module5Data.start_year][
      'Total Limit'
      ]
    ) {
      sepPersonalContribution1 = (databaseData['SEP IRA Contribution Limit'][module5Data.start_year][
        'Total Limit'
      ]
        / 100)
        * databaseData['SEP IRA Contribution Limit'][module5Data.start_year].Return;
    } else {
      sepPersonalContribution1 = (module5Data.advanceTotalPreTaxExpected_val / 100)
        * databaseData['SEP IRA Contribution Limit'][module5Data.start_year].Return;
    }
  } else {
    sepPersonalContribution1 = 0;
  }

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][
    module5Data.start_year
    ] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module5Data.start_year][
    'Max Personal Limit'
    ] !== undefined
  ) {
    sepPersonalContribution2 = databaseData['SEP IRA Contribution Limit'][module5Data.start_year][
      'Max Personal Limit'
    ];
  } else {
    sepPersonalContribution2 = 0;
  }

  if (sepPersonalContribution1 > sepPersonalContribution2) {
    sepPersonalContribution3 = sepPersonalContribution2;
  } else {
    sepPersonalContribution3 = sepPersonalContribution1;
  }

  if (
    module5Data.advCareerSEPIRAPersonalContributionCalcs
    !== undefined
    && module5Data.advCareerSEPIRAPersonalContributionCalcs !== ''
  ) {
    if (
      module5Data.advCareerSEPIRAPersonalContributionCalcs
      > sepPersonalContribution3
    ) {
      sepPersonalContribution = sepPersonalContribution3;
    } else {
      sepPersonalContribution = module5Data.advCareerSEPIRAPersonalContributionCalcs;
    }
  } else {
    sepPersonalContribution = 0;
  }
  module5Data.advCareerSEPIRAPersonalContribution = sepPersonalContribution;
  return module5Data.advCareerSEPIRAPersonalContribution;
}

/* SEP IRA Retirement Summary */
// Total Contributions
export function advCareerSEPIRATotalContribution() {
  module5Data.advCareerSEPIRATotalContribution = 0;
  let totalContribution = 0;
  if (investmentsData !== undefined && investmentsData['Seph IRA Second']) {
    const data = investmentsData['Seph IRA Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContribution += data[year]['Annual Contribution'];
        }
      });
    }
  }

  module5Data.advCareerSEPIRATotalContribution = totalContribution;
  return module5Data.advCareerSEPIRATotalContribution;
}

// Total Earnings
export function advCareerSEPIRATotalEarnings() {
  module5Data.advCareerSEPIRATotalEarnings = 0;
  let totalEarnings = 0;
  if (investmentsData !== undefined && investmentsData['Seph IRA Second']) {
    const data = investmentsData['Seph IRA Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarnings += data[year].Earnings;
        }
      });
    }
  }

  module5Data.advCareerSEPIRATotalEarnings = totalEarnings;
  return module5Data.advCareerSEPIRATotalEarnings;
}

// Ending Sep IRA BALANCE
export function advCareerEndingSEPIRABalance() {
  module5Data.advCareerEndingSEPIRABalance = 0;
  if (
    module5Data.advCareerSEPIRATotalEarnings !== undefined
    && module5Data.advCareerSEPIRATotalContribution !== undefined
    && module5Data.advCareerSEPIRABeginningBalance !== undefined
  ) {
    module5Data.advCareerEndingSEPIRABalance = module5Data.advCareerSEPIRATotalEarnings
      + module5Data.advCareerSEPIRATotalContribution
      + module5Data.advCareerSEPIRABeginningBalance;
  }
  return module5Data.advCareerEndingSEPIRABalance;
}

/* Pension */
// Beginning Balance
export function advCareerPensionBeginningBalance() {
  module5Data.advCareerPensionBeginningBalance = 0;
  if (module5Data.start_year !== undefined && investmentsData !== undefined && investmentsData['Seph IRA Second'] !== undefined
    && investmentsData['Pension Second'][module5Data.start_year] !== undefined && investmentsData['Pension Second'][module5Data.start_year][
    'Beginning Balance'] !== undefined) {
    module5Data.advCareerPensionBeginningBalance = investmentsData['Pension Second'][module5Data.start_year]['Beginning Balance'];
  } else {
    module5Data.advCareerPensionBeginningBalance = 0;
  }
  return module5Data.advCareerPensionBeginningBalance;
}

// Employer Contribution Calculations
export function advCareerPensionEmployerContributionCalc() {
  module5Data.advCareerPensionEmployerContributionCalc = 0;
  if (
    module5Data.pension_contribute !== undefined
    && module5Data.advanceTotalPreTaxExpected_val !== undefined
    && module5Data.advanceTotalPreTaxExpected_val !== ''
    && module5Data.pension_employer_contribution !== undefined
    && module5Data.pension_employer_contribution !== ''
    && module5Data.pension_contribute === 'Yes'
  ) {
    module5Data.advCareerPensionEmployerContributionCalc = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.pension_employer_contribution;
  }
  return module5Data.advCareerPensionEmployerContributionCalc;
}

// Personal Contribution Calculations
export function advCareerPensionPersonalContributionCalc() {
  module5Data.advCareerPensionPersonalContributionCalc = 0;
  if (module5Data.pension_contribute !== undefined
    && module5Data.advanceTotalPreTaxExpected_val !== undefined
    && module5Data.pension_contribute === 'Yes'
    && module5Data.pension_personal_contribution !== undefined) {
    module5Data.advCareerPensionPersonalContributionCalc = ((module5Data.advanceTotalPreTaxExpected_val) / 100) * module5Data.pension_personal_contribution;
  }
  return module5Data.advCareerPensionPersonalContributionCalc;
}

// Employer Contribution
export function advCareerPensionEmployerContribution() {
  module5Data.advCareerPensionEmployerContribution = 0;
  if (
    module5Data.advCareerPensionPersonalContributionCalc
    !== undefined
    && module5Data.advCareerPensionPersonalContributionCalc !== ''
    && module5Data.advCareerPensionEmployerContributionCalc
    !== undefined
    && module5Data.advCareerPensionEmployerContributionCalc !== ''
    && module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Pension 2018 Contribution Limit'][
    module5Data.start_year
    ] !== undefined
    && databaseData['Pension 2018 Contribution Limit'][
    module5Data.start_year
    ]['Total Limit'] !== undefined
  ) {
    if (
      module5Data.advCareerPensionPersonalContributionCalc
      + module5Data.advCareerPensionEmployerContributionCalc
      > databaseData['Pension 2018 Contribution Limit'][module5Data.start_year][
      'Total Limit'
      ]
    ) {
      module5Data.advCareerPensionEmployerContribution = databaseData['Pension 2018 Contribution Limit'][module5Data.start_year][
        'Total Limit'
      ] - module5Data.advCareerPensionEmployerContributionCalc;
    } else {
      module5Data.advCareerPensionEmployerContribution = module5Data.advCareerPensionPersonalContributionCalc;
    }
  }
  return module5Data.advCareerPensionEmployerContribution;
}

// Total Annual Contribution
export function advCareerPensionTotalContributionpension() {
  module5Data.advCareerPensionTotalContribution_pension = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Pension 2018 Contribution Limit'][
    module5Data.start_year
    ] !== undefined
    && databaseData['Pension 2018 Contribution Limit'][
    module5Data.start_year
    ]['Total Limit'] !== undefined
  ) {
    module5Data.advCareerPensionTotalContribution_pension = databaseData['Pension 2018 Contribution Limit'][module5Data.start_year][
      'Total Limit'
    ];
  } else {
    module5Data.advCareerPensionTotalContribution_pension = 0;
  }
  return module5Data.advCareerPensionTotalContribution_pension;
}

export function advCareerPensionTotalContribution() {
  module5Data.advCareerPensionTotalContribution = 0;
  if (module5Data.advCareerPensionPersonalContributionCalc !== undefined && module5Data.advCareerPensionEmployerContributionCalc !== undefined) {
    module5Data.advCareerPensionTotalContribution = module5Data.advCareerPensionPersonalContributionCalc + module5Data.advCareerPensionEmployerContributionCalc;
  }
  return module5Data.advCareerPensionTotalContribution;
}

// Total Annual Employer Contribution
export function advCareerPensionTotalEmployerContributions() {
  module5Data.advCareerPensionTotalEmployerContributions = 0;
  let totalEmployerContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEmployerContribution += data[year]['Employer Contribution'];
        }
      });
    }
  }
  module5Data.advCareerPensionTotalEmployerContributions = totalEmployerContribution;

  return module5Data.advCareerPensionTotalEmployerContributions;
}

// Total Annual Personal Contribution
export function advCareerPensionTotalPersonalContributions() {
  module5Data.advCareerPensionTotalPersonalContributions = 0;
  let totalPersonalContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalPersonalContribution += data[year]['Personal Contribution'];
        }
      });
    }
  }
  module5Data.advCareerPensionTotalPersonalContributions = totalPersonalContribution;

  return module5Data.advCareerPensionTotalPersonalContributions;
}

// Total Annual Personal Contribution
export function advCareerPensionTotalEarnings() {
  module5Data.advCareerPensionTotalEarnings = 0;
  let totalEanings = 0;
  if (investmentsData !== undefined && investmentsData['Pension Second']) {
    const data = investmentsData['Pension Second'];
    const start_year = module5Data.start_year;
    const endYear = module5Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEanings += data[year].Earnings;
        }
      });
    }
  }

  module5Data.advCareerPensionTotalEarnings = totalEanings;

  return module5Data.advCareerPensionTotalEarnings;
}

export function advCareerEndingPensionBalance() {
  module5Data.advCareerEndingPensionBalance = 0;
  if (
    module5Data.advCareerPensionTotalEarnings !== undefined
    && module5Data.advCareerPensionTotalPersonalContributions
    !== undefined
    && module5Data.advCareerPensionTotalEmployerContributions
    !== undefined
    && module5Data.advCareerPensionBeginningBalance !== undefined
  ) {
    module5Data.advCareerEndingPensionBalance = module5Data.advCareerPensionBeginningBalance
      + module5Data.advCareerPensionTotalEmployerContributions
      + module5Data.advCareerPensionTotalPersonalContributions
      + module5Data.advCareerPensionTotalEarnings;
  }
  return module5Data.advCareerEndingPensionBalance;
}

// career Advancement Expenses Rent Multiplier
export function careerAdvRentMultiplier() {
  module5Data.careerAdvRentMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvRentMultiplier = module10Data.rent_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvRentMultiplier = module10Data.rent_multiplier;
    } else {
      module5Data.careerAdvRentMultiplier = 0;
    }
  }
  return module5Data.careerAdvRentMultiplier;
}

// Rent Expenses
export function careerAdvanceRentExpenses() {
  let rentExpenses = 0;
  module5Data.careerAdvanceRentExpenses = 0;
  if (
    module5Data.careerAdvRentMultiplier !== undefined
    && module5Data.careerAdvRentMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.rent !== undefined
      && module5Data.rent !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.rent > 0
      ) {
        rentExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Rent / 12          
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.rent !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.rent > 0
      ) {
        rentExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Rent / 12
        );
      } else {
        rentExpenses = 0;
      }
    }
  } else {
    rentExpenses = 0;
  }

  module5Data.careerAdvanceRentExpenses = rentExpenses;

  return module5Data.careerAdvanceRentExpenses;
}

// career Path Expenses Utilities Multiplier
export function careerAdvUtilitiesMultiplier() {
  module5Data.careerAdvUtilitiesMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.utilities_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else {
      module5Data.careerAdvUtilitiesMultiplier = '';
    }
  }
  return module5Data.careerAdvUtilitiesMultiplier;
}

// Utilities Expenses
export function careerAdvanceUtilitiesExpenses() {
  let UtilitiesExpenses = 0;
  module5Data.careerAdvanceUtilitiesExpenses = 0;
  if (
    module5Data.careerAdvUtilitiesMultiplier !== undefined
    && module5Data.careerAdvUtilitiesMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.utilities !== undefined
      && module5Data.utilities !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.utilities > 0
      ) {
        UtilitiesExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Utilities / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.utilities !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.utilities > 0
      ) {
        UtilitiesExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities / 12
        );
      } else {
        UtilitiesExpenses = 0;
      }
    }
  } else {
    UtilitiesExpenses = '';
  }

  module5Data.careerAdvanceUtilitiesExpenses = UtilitiesExpenses;

  return module5Data.careerAdvanceUtilitiesExpenses;
}

// career Advancement Expenses Food Multiplier
export function careerAdvFoodMultiplier() {
  module5Data.careerAdvFoodMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvFoodMultiplier = module10Data.food_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvFoodMultiplier = module10Data.food_multiplier;
    } else {
      module5Data.careerAdvFoodMultiplier = '';
    }
  }
  return module5Data.careerAdvFoodMultiplier;
}

// Food Expenses
export function careerAdvanceFoodExpenses() {
  let foodExpenses = 0;
  module5Data.careerAdvanceFoodExpenses = 0;
  if (
    module5Data.careerAdvFoodMultiplier !== undefined
    && module5Data.careerAdvFoodMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.food !== undefined
      && module5Data.food !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.food > 0
      ) {
        foodExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Food / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.food !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.food > 0
      ) {
        foodExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Food / 12
        );
      } else {
        foodExpenses = 0;
      }
    }
  } else {
    foodExpenses = '';
  }

  module5Data.careerAdvanceFoodExpenses = foodExpenses;

  return module5Data.careerAdvanceFoodExpenses;
}

// career Advancement Expenses Clothing Multiplier
export function careerAdvClothingMultiplier() {
  module5Data.careerAdvClothingMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvClothingMultiplier = module10Data.clothing_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvClothingMultiplier = module10Data.clothing_multiplier;
    } else {
      module5Data.careerAdvClothingMultiplier = '';
    }
  }
  return module5Data.careerAdvClothingMultiplier;
}

// Clothing Expenses
export function careerAdvanceClothingExpenses() {
  let clothingExpenses = 0;
  module5Data.careerAdvanceClothingExpenses = 0;
  if (
    module5Data.careerAdvClothingMultiplier !== undefined
    && module5Data.careerAdvClothingMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.clothing !== undefined
      && module5Data.clothing !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.clothing > 0
      ) {
        clothingExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Clothing / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.clothing !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.clothing > 0
      ) {
        clothingExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing / 12
        );
      } else {
        clothingExpenses = 0;
      }
    }
  } else {
    clothingExpenses = '';
  }

  module5Data.careerAdvanceClothingExpenses = clothingExpenses;

  return module5Data.careerAdvanceClothingExpenses;
}

// career Advancement Expenses Entertainment Multiplier
export function careerAdvEntertainmentMultiplier() {
  module5Data.careerAdvEntertainmentMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else {
      module5Data.careerAdvEntertainmentMultiplier = '';
    }
  }
  return module5Data.careerAdvEntertainmentMultiplier;
}

// Entertainment Expenses
export function careerAdvanceEntertainmentExpenses() {
  let entertainmentExpenses = 0;
  module5Data.careerAdvanceEntertainmentExpenses = 0;
  if (
    module5Data.careerAdvEntertainmentMultiplier !== undefined
    && module5Data.careerAdvEntertainmentMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.entertainment !== undefined
      && module5Data.entertainment !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.entertainment > 0
      ) {
        entertainmentExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Entertainment / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.entertainment !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.entertainment > 0
      ) {
        entertainmentExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment / 12
        );
      } else {
        entertainmentExpenses = 0;
      }
    }
  } else {
    entertainmentExpenses = '';
  }

  module5Data.careerAdvanceEntertainmentExpenses = entertainmentExpenses;

  return module5Data.careerAdvanceEntertainmentExpenses;
}

// career Advancement Expenses Technology Multiplier
export function careerAdvTechnologyMultiplier() {
  module5Data.careerAdvTechnologyMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvTechnologyMultiplier = module10Data.technology_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvTechnologyMultiplier = module10Data.technology_multiplier;
    } else {
      module5Data.careerAdvTechnologyMultiplier = '';
    }
  }
  return module5Data.careerAdvTechnologyMultiplier;
}

// Technology Expenses
export function careerAdvanceTechnologyExpenses() {
  let technologyExpenses = 0;
  module5Data.careerAdvanceTechnologyExpenses = 0;
  if (
    module5Data.careerAdvTechnologyMultiplier !== undefined
    && module5Data.careerAdvTechnologyMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.technology !== undefined
      && module5Data.technology !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.technology > 0
      ) {
        technologyExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Technology / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.technology !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.technology > 0
      ) {
        technologyExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Technology / 12
        );
      } else {
        technologyExpenses = 0;
      }
    }
  } else {
    technologyExpenses = '';
  }

  module5Data.careerAdvanceTechnologyExpenses = technologyExpenses;

  return module5Data.careerAdvanceTechnologyExpenses;
}
// career Advancement Expenses Transportation Multiplier
export function careerAdvTransportationMultiplier() {
  module5Data.careerAdvTransportationMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvTransportationMultiplier = module10Data.transportation_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvTransportationMultiplier = module10Data.transportation_multiplier;
    } else {
      module5Data.careerAdvTransportationMultiplier = '';
    }
  }
  return module5Data.careerAdvTransportationMultiplier;
}

// Transportation Expenses
export function careerAdvanceTransportationExpenses() {
  let transportationExpenses = 0;
  module5Data.careerAdvanceTransportationExpenses = 0;
  if (
    module5Data.careerAdvTransportationMultiplier !== undefined
    && module5Data.careerAdvTransportationMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.transportation !== undefined
      && module5Data.transportation !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.transportation > 0
      ) {
        transportationExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Transportation / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.transportation !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.transportation > 0
      ) {
        transportationExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation / 12
        );
      } else {
        transportationExpenses = 0;
      }
    }
  } else {
    transportationExpenses = '';
  }
  module5Data.careerAdvanceTransportationExpenses = transportationExpenses;

  return module5Data.careerAdvanceTransportationExpenses;
}

// career Advancement Expenses Miscellaneous Multiplier
export function careerAdvMiscellaneousMultiplier() {
  module5Data.careerAdvMiscellaneousMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else {
      module5Data.careerAdvMiscellaneousMultiplier = '';
    }
  }
  return module5Data.careerAdvMiscellaneousMultiplier;
}

// Miscellaneous Expenses
export function careerAdvanceMiscellaneousExpenses() {
  let miscellaneousExpenses = 0;
  module5Data.careerAdvanceMiscellaneousExpenses = 0;
  if (
    module5Data.careerAdvMiscellaneousMultiplier !== undefined
    && module5Data.careerAdvMiscellaneousMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.miscellaneous !== undefined
      && module5Data.miscellaneous !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.miscellaneous > 0
      ) {
        miscellaneousExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses.Miscellaneous / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.miscellaneous !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.miscellaneous > 0
      ) {
        miscellaneousExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous / 12
        );
      } else {
        miscellaneousExpenses = 0;
      }
    }
  } else {
    miscellaneousExpenses = '';
  }
  module5Data.careerAdvanceMiscellaneousExpenses = miscellaneousExpenses;

  return module5Data.careerAdvanceMiscellaneousExpenses;
}

// career Advancement Expenses Medical Multiplier
export function careerAdvMedicalMultiplier() {
  module5Data.careerAdvMedicalMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvMedicalMultiplier = module10Data.health_insurance_costs_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvMedicalMultiplier = module10Data.health_insurance_costs_multiplier;
    } else {
      module5Data.careerAdvMedicalMultiplier = '';
    }
  }
  return module5Data.careerAdvMedicalMultiplier;
}

// Health Insurance Costs outside Premium
export function careerAdvanceOutsidePremiumExpenses() {
  let healthExpenses = 0;
  module5Data.careerAdvanceOutsidePremiumExpenses = 0;
  if (
    module5Data.careerAdvMedicalMultiplier !== undefined
    && module5Data.careerAdvMedicalMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.medical_costs_before_deductible !== undefined
      && module5Data.medical_costs_before_deductible !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.medical_costs_before_deductible > 0
      ) {
        healthExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses[
          'Healthcare Costs other than Premium'
          ] / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.medical_costs_before_deductible !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.medical_costs_before_deductible > 0
      ) {
        healthExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses[
          'Healthcare Costs other than Premium'
          ] / 12
        );
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module5Data.careerAdvanceOutsidePremiumExpenses = healthExpenses;

  return module5Data.careerAdvanceOutsidePremiumExpenses;
}

// career Advancement Expenses Health Multiplier
export function careerAdvHealthMultiplier() {
  module5Data.careerAdvHealthMultiplier = 0;

  if (
    module5Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined
  ) {
    if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.start_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvHealthMultiplier = module10Data.health_insurance_premium_multiplier;
    } else if (
      module5Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module5Data.end_year !== ''
      && module5Data.start_year < module10Data.marriege_year
      && module5Data.end_year >= module10Data.marriege_year
    ) {
      module5Data.careerAdvHealthMultiplier = module10Data.health_insurance_premium_multiplier;
    } else {
      module5Data.careerAdvHealthMultiplier = '';
    }
  }
  return module5Data.careerAdvHealthMultiplier;
}

// Health Insurance Costs outside Premium
export function careerAdvancePremiumExpenses() {
  let healthExpenses = 0;
  module5Data.careerAdvancePremiumExpenses = 0;
  if (
    module5Data.careerAdvHealthMultiplier !== undefined
    && module5Data.careerAdvHealthMultiplier !== ''
  ) {
    if (
      module5Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module5Data.health_insurance_premium !== undefined
      && module5Data.health_insurance_premium !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses
      !== ''
      && incomeStatementData[module5Data.start_year] !== undefined
      && incomeStatementData[module5Data.start_year] !== ''
      && incomeStatementData[module5Data.start_year].livingExpenses
      !== undefined
      && incomeStatementData[module5Data.start_year].livingExpenses !== ''
    ) {
      if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.start_year >= module10Data.marriege_year
        && module5Data.health_insurance_premium > 0
      ) {
        healthExpenses = -(
          incomeStatementData[module5Data.start_year].livingExpenses[
          'Health Insurance Premium'
          ] / 12
        );
      } else if (
        module5Data.start_year !== ''
        && module10Data.marriege_year !== ''
        && module5Data.end_year !== ''
        && module5Data.health_insurance_premium !== ''
        && module5Data.start_year < module10Data.marriege_year
        && module5Data.end_year >= module10Data.marriege_year
        && module5Data.health_insurance_premium > 0
      ) {
        healthExpenses = -(
          incomeStatementData[module10Data.marriege_year].livingExpenses[
          'Health Insurance Premium'
          ] / 12
        );
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module5Data.careerAdvancePremiumExpenses = healthExpenses;

  return module5Data.careerAdvancePremiumExpenses;
}

// Annual Expense
export function careerAdvLivingExpenses() {
  module5Data.careerAdvLivingExpenses = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Rent !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Utilities !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Food !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Clothing !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Entertainment !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Technology !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Transportation !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Miscellaneous !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses[
    'Healthcare Costs other than Premium'
    ] !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses[
    'Health Insurance Premium'
    ] !== undefined
  ) {

    module5Data.careerAdvLivingExpenses = incomeStatementData[module5Data.start_year].livingExpenses.Rent
      + incomeStatementData[module5Data.start_year].livingExpenses.Utilities
      + incomeStatementData[module5Data.start_year].livingExpenses.Food
      + incomeStatementData[module5Data.start_year].livingExpenses.Clothing
      + incomeStatementData[module5Data.start_year].livingExpenses.Entertainment
      + incomeStatementData[module5Data.start_year].livingExpenses.Technology
      + incomeStatementData[module5Data.start_year].livingExpenses.Transportation
      + incomeStatementData[module5Data.start_year].livingExpenses.Miscellaneous
      + incomeStatementData[module5Data.start_year].livingExpenses[
      'Healthcare Costs other than Premium'
      ]
      + incomeStatementData[module5Data.start_year].livingExpenses[
      'Health Insurance Premium'
      ];
  }

  return module5Data.careerAdvLivingExpenses;
}

// Hgher Education Expenses Value
export function careerAdvHigherEducationExpenses() {
  module5Data.careerAdvHigherEducationExpenses = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses[
    'Higher Education Tuition and Fees'
    ] !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses[
    'Higher Education Room & Board'
    ] !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses[
    'Off Campus Food'
    ] !== undefined
  ) {
    module5Data.careerAdvHigherEducationExpenses = incomeStatementData[module5Data.start_year].livingExpenses[
      'Higher Education Tuition and Fees'
    ]
      + incomeStatementData[module5Data.start_year].livingExpenses[
      'Higher Education Room & Board'
      ]
      + incomeStatementData[module5Data.start_year].livingExpenses[
      'Off Campus Food'
      ];
  }
  return module5Data.careerAdvHigherEducationExpenses;
}

// Car Expenses Value
export function careerAdvCarExpenses() {
  module5Data.careerAdvCarExpenses = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year]['Total Car Expenses']
    !== undefined
  ) {
    module5Data.careerAdvCarExpenses = incomeStatementData[module5Data.start_year]['Total Car Expenses'];
  }
  return module5Data.careerAdvCarExpenses;
}

// Children Expenses Value
export function careerAdvChildrenExpenses() {
  module5Data.careerAdvChildrenExpenses = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Children !== undefined
  ) {
    module5Data.careerAdvChildrenExpenses = incomeStatementData[module5Data.start_year].livingExpenses.Children;
  }
  return module5Data.careerAdvChildrenExpenses;
}
// Pet Expenses Value
export function careerAdvPetExpenses() {
  module5Data.careerAdvPetExpenses = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year].livingExpenses.Pet !== undefined
  ) {
    module5Data.careerAdvPetExpenses = incomeStatementData[module5Data.start_year].livingExpenses.Pet;
  }
  return module5Data.careerAdvPetExpenses;
}

// Home Expenses Value
export function careerAdvHomeExpenses() {
  module5Data.careerAdvHomeExpenses = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year][
    'Total Real Estate Expenses'
    ] !== undefined
  ) {
    module5Data.careerAdvHomeExpenses = incomeStatementData[module5Data.start_year]['Total Real Estate Expenses'];
  }
  return module5Data.careerAdvHomeExpenses;
}

// Financing Expenses Value
export function careerAdvFinancingExpenses() {
  module5Data.careerAdvFinancingExpenses = 0;
  let annualLoanPatments1 = 0;
  let annualLoanPatmentsFinal = 0;

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year][
    'Total Interest Expenses'
    ] !== undefined
  ) {
    annualLoanPatments1 = incomeStatementData[module5Data.start_year]['Total Interest Expenses'];
  }
  annualLoanPatmentsFinal = annualLoanPatments1;
  module5Data.careerAdvFinancingExpenses = annualLoanPatmentsFinal;

  return module5Data.careerAdvFinancingExpenses;
}

// Total Annual Expenses
export function careerAdvTotalAnnualExpenses() {
  module5Data.careerAdvTotalAnnualExpenses = 0;
  if (
    module5Data.annualTotalTaxesPaidCareerAdv !== undefined
    && module5Data.careerAdvLivingExpenses !== undefined
    && module5Data.careerAdvHigherEducationExpenses !== undefined
    && module5Data.careerAdvCarExpenses !== undefined
    && module5Data.careerAdvChildrenExpenses !== undefined
    && module5Data.careerAdvPetExpenses !== undefined
    && module5Data.careerAdvHomeExpenses !== undefined
    && module5Data.careerAdvFinancingExpenses !== undefined
  ) {
    module5Data.careerAdvTotalAnnualExpenses = module5Data.annualTotalTaxesPaidCareerAdv
      + module5Data.careerAdvLivingExpenses
      + module5Data.careerAdvHigherEducationExpenses
      + module5Data.careerAdvCarExpenses
      + module5Data.careerAdvChildrenExpenses
      + module5Data.careerAdvPetExpenses
      + module5Data.careerAdvHomeExpenses
      + module5Data.careerAdvFinancingExpenses;
  }
  return module5Data.careerAdvTotalAnnualExpenses;
}

// Family Iccone
export function careerAdvIconCalcs() {
  module5Data.careerAdvIconCalcs = '';
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    module5Data.careerAdvIconCalcs = module10Data.marriege_year;
  } else {
    module5Data.careerAdvIconCalcs = '';
  }
  return module5Data.careerAdvIconCalcs;
}

// Monthly Income from Career Path
export function monthlyIncomeFromCareerAdv() {
  module5Data.monthlyIncomeFromCareerAdv = 0;
  if (
    module5Data.advanceTotalPreTaxExpected_val !== undefined
    && module5Data.advanceTotalPreTaxExpected_val !== ''
  ) {
    module5Data.monthlyIncomeFromCareerAdv = module5Data.advanceTotalPreTaxExpected_val / 12;
  }
  return module5Data.monthlyIncomeFromCareerAdv;
}

// Total Monthly Income
export function totalMonthlyIncomeCareerAdv() {
  module5Data.totalMonthlyIncomeCareerAdv = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year]['Total Income']
    !== undefined
  ) {
    module5Data.totalMonthlyIncomeCareerAdv = incomeStatementData[module5Data.start_year]['Total Income'] / 12;
  }
  return module5Data.totalMonthlyIncomeCareerAdv;
}
// Other Monthly Income
export function otherMonthlyIncomeCareerAdv() {
  module5Data.otherMonthlyIncomeCareerAdv = 0;
  if (
    module5Data.totalMonthlyIncomeCareerAdv !== undefined
    && module5Data.monthlyIncomeFromCareerAdv !== undefined
  ) {
    module5Data.otherMonthlyIncomeCareerAdv = module5Data.totalMonthlyIncomeCareerAdv
      - module5Data.monthlyIncomeFromCareerAdv;
  }
  return module5Data.otherMonthlyIncomeCareerAdv;
}

// Total Taxes paid
export function totalTaxesPaidCareerAdv() {
  module5Data.totalTaxesPaidCareerAdv = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year]['Total Taxes']
    !== undefined
  ) {
    module5Data.totalTaxesPaidCareerAdv = incomeStatementData[module5Data.start_year]['Total Taxes'] / 12;
  }
  return module5Data.totalTaxesPaidCareerAdv;
}

// Living Expenses
export function livingExpensesCareerAdv() {
  module5Data.livingExpensesCareerAdv = 0;
  if (module5Data.advanceTotalMonthlyLivingExpenses !== undefined) {
    module5Data.livingExpensesCareerAdv = -module5Data.advanceTotalMonthlyLivingExpenses;
  }
  return module5Data.livingExpensesCareerAdv;
}

// Higher Education Expenses
export function higherEducationExpensesCareerAdv() {
  module5Data.higherEducationExpensesCareerAdv = 0;
  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year].livingExpenses
    !== undefined
  ) {
    module5Data.higherEducationExpensesCareerAdv = (incomeStatementData[module5Data.start_year].livingExpenses[
      'Higher Education Tuition and Fees'
    ]
      + incomeStatementData[module5Data.start_year].livingExpenses[
      'Higher Education Room & Board'
      ]
      + incomeStatementData[module5Data.start_year].livingExpenses[
      'Off Campus Food'
      ])
      / 12;
  }
  return module5Data.higherEducationExpensesCareerAdv;
}
// Children Expenses
export function childrenExpensesCareerAdv() {
  module5Data.childrenExpensesCareerAdv = 0;
  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year].livingExpenses
    !== undefined
  ) {
    module5Data.childrenExpensesCareerAdv = incomeStatementData[module5Data.start_year].livingExpenses.Children / 12;
  }
  return module5Data.childrenExpensesCareerAdv;
}
// Pet Expenses
export function petExpensesCareerAdv() {
  module5Data.petExpensesCareerAdv = 0;
  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year].livingExpenses
    !== undefined
  ) {
    module5Data.petExpensesCareerAdv = incomeStatementData[module5Data.start_year].livingExpenses.Pet / 12;
  }
  return module5Data.petExpensesCareerAdv;
}
// Pet Expenses
export function carExpensesCareerAdv() {
  module5Data.carExpensesCareerAdv = 0;
  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year].livingExpenses
    !== undefined
  ) {
    module5Data.carExpensesCareerAdv = incomeStatementData[module5Data.start_year]['Total Car Expenses'] / 12;
  }
  return module5Data.carExpensesCareerAdv;
}
// Real estate Expenses
export function realEstateExpensesCareerAdv() {
  module5Data.realEstateExpensesCareerAdv = 0;
  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year].livingExpenses
    !== undefined
  ) {
    module5Data.realEstateExpensesCareerAdv = incomeStatementData[module5Data.start_year][
      'Total Real Estate Expenses'
    ] / 12;
  }
  return module5Data.realEstateExpensesCareerAdv;
}

// Real estate Expenses
export function interestExpensesCareerAdv() {
  module5Data.interestExpensesCareerAdv = 0;
  let loanPayments1 = 0;
  let loanPayments2 = 0;
  let loanPayments3 = 0;
  let loanPayments4 = 0;
  let loanPayments5 = 0;
  let loanPaymentsFinal = 0;

  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year].livingExpenses
    !== undefined
  ) {
    loanPayments1 = incomeStatementData[module5Data.start_year]['Total Interest Expenses']
      / 12;
  }

  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && cashFlowData !== undefined
    && cashFlowData !== ''
    && cashFlowData[module5Data.start_year] !== undefined
    && cashFlowData[module5Data.start_year] !== ''
  ) {
    if (
      cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities']
      !== ''
      && cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ]['Existing Credit Card Debt Principal Payback'] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
      'Existing Credit Card Debt Principal Payback'
      ] !== ''
    ) {
      loanPayments2 = cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
        'Existing Credit Card Debt Principal Payback'
      ] / 12;
    }
    if (
      cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities']
      !== ''
      && cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ]['Car Loans Principal Payback'] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
      'Car Loans Principal Payback'
      ] !== ''
    ) {
      loanPayments3 = cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
        'Car Loans Principal Payback'
      ] / 12;
    }
    if (
      cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities']
      !== ''
      && cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ]['Student Loans Principal Payback'] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
      'Student Loans Principal Payback'
      ] !== ''
    ) {
      loanPayments4 = cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
        'Student Loans Principal Payback'
      ] / 12;
    }
    if (
      cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities']
      !== ''
      && cashFlowData[module5Data.start_year][
      'Cash from Financing Activities'
      ]['Mortgages Principal Payback'] !== undefined
      && cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
      'Mortgages Principal Payback'
      ] !== ''
    ) {
      loanPayments5 = cashFlowData[module5Data.start_year]['Cash from Financing Activities'][
        'Mortgages Principal Payback'
      ] / 12;
    }
  }
  loanPaymentsFinal = loanPayments1
    + loanPayments2
    + loanPayments3
    + loanPayments4
    + loanPayments5;
  module5Data.interestExpensesCareerAdv = loanPaymentsFinal;

  return module5Data.interestExpensesCareerAdv;
}

export function careerOtherLivingExpensesCareerPath() {
  module4Data.careerOtherLivingExpensesCareerPath = 0;
  module4Data.careerOtherLivingExpensesCareerPath = module4Data.higherEducationExpensesCareerPath + module4Data.childrenExpensesCareerPath + module4Data.petExpensesCareerPath + module4Data.carExpensesCareerPath + module4Data.realEstateExpensesCareerPath;
  return module4Data.careerOtherLivingExpensesCareerPath;
}
export function careerOtherAnnaulLivingExpensesCareerPath() {
  module4Data.careerOtherAnnaulLivingExpensesCareerPath = 0;
  module4Data.careerOtherAnnaulLivingExpensesCareerPath = module4Data.careerPathHigherEducationExpenses + module4Data.careerPathChildrenExpenses + module4Data.careerPathPetExpenses + module4Data.careerPathCarExpenses + module4Data.careerPathHomeExpenses;
  return module4Data.careerOtherAnnaulLivingExpensesCareerPath;
}
export function careerOtherEducationLivingExpensesPaid() {
  module4Data.careerOtherEducationLivingExpensesPaid = 0;
  module4Data.careerOtherEducationLivingExpensesPaid = module4Data.higherEducationExpensesPaid + module4Data.childrenExpensesPaid + module4Data.petExpensesPaid + module4Data.carExpensesPaid + module4Data.realEstateExpensesPaid;
  return module4Data.careerOtherEducationLivingExpensesPaid;
}

export function careerAdvOtherExpenses() {
  module5Data.advCareerExpenses = 0;
  module5Data.advCareerExpenses = module5Data.higherEducationExpensesCareerAdv + module5Data.childrenExpensesCareerAdv + module5Data.petExpensesCareerAdv + module5Data.carExpensesCareerAdv + module5Data.realEstateExpensesCareerAdv;
  return module5Data.advCareerExpenses;
}

export function otherAnnaulLivingExpensesCareerPath() {
  module5Data.otherAnnaulLivingExpensesCareerPath = 0;
  module5Data.otherAnnaulLivingExpensesCareerPath = module5Data.careerAdvHigherEducationExpenses + module5Data.careerAdvChildrenExpenses + module5Data.careerAdvPetExpenses + module5Data.careerAdvCarExpenses + module5Data.careerAdvHomeExpenses;
  return module5Data.otherAnnaulLivingExpensesCareerPath;
}

export function otherEducationLivingExpensesPaid() {
  module5Data.otherEducationLivingExpensesPaid = 0;
  module5Data.otherEducationLivingExpensesPaid = module5Data.advanceHigherEducationExpensesPaid + module5Data.advanceChildrenExpensesPaid + module5Data.advancePetExpensesPaid + module5Data.advanceCarExpensesPaid + module5Data.advanceRealEstateExpensesPaid;
  return module5Data.otherEducationLivingExpensesPaid;
}

export function addCareerAdvOtherExpenses() {
  module8Data.addCareerAdvOtherExpenses = 0;
  module8Data.addCareerAdvOtherExpenses = module8Data.higherEducationExpensesAddCareerAdv + module8Data.childrenExpensesAddCareerAdv + module8Data.petExpensesAddCareerAdv + module8Data.carExpensesAddCareerAdv + module8Data.realEstateExpensesAddCareerAdv;
  return module8Data.addCareerAdvOtherExpenses;
}

export function addOtherAnnaulLivingExpensesCareerPath() {
  module8Data.addOtherAnnaulLivingExpensesCareerPath = 0;
  module8Data.addOtherAnnaulLivingExpensesCareerPath = module8Data.addCareerAdvHigherEducationExpenses + module8Data.addCareerAdvChildrenExpenses + module8Data.addCareerAdvPetExpenses + module8Data.addCareerAdvCarExpenses + module8Data.addCareerAdvHomeExpenses;
  return module8Data.addOtherAnnaulLivingExpensesCareerPath;
}

export function addOtherEducationLivingExpensesPaid() {
  module8Data.addOtherEducationLivingExpensesPaid = 0;
  module8Data.addOtherEducationLivingExpensesPaid = module8Data.moreHigherEducationExpensesPaid + module8Data.moreChildrenExpensesPaid + module8Data.morePetExpensesPaid + module8Data.moreHigherCarExpensesPaid + module8Data.moreHigherRealEstateExpensesPaid;
  return module8Data.addOtherEducationLivingExpensesPaid;
}

export function furCareerAdvOtherExpenses() {
  module17Data.furCareerAdvOtherExpenses = 0;
  module17Data.furCareerAdvOtherExpenses = module17Data.higherEducationExpensesFurCareerAdv + module17Data.childrenExpensesFurCareerAdv + module17Data.petExpensesFurCareerAdv + module17Data.carExpensesFurCareerAdv + module17Data.realEstateExpensesFurCareerAdv;
}

export function furOtherAnnaulLivingExpensesCareerPath() {
  module17Data.furOtherAnnaulLivingExpensesCareerPath = 0;
  module17Data.furOtherAnnaulLivingExpensesCareerPath = module17Data.furCareerAdvHigherEducationExpenses + module17Data.furCareerAdvChildrenExpenses + module17Data.furCareerAdvPetExpenses + module17Data.furCareerAdvCarExpenses + module17Data.furCareerAdvHomeExpenses;
  return module17Data.furOtherAnnaulLivingExpensesCareerPath;
}

export function furOtherEducationLivingExpensesPaid() {
  module17Data.furOtherEducationLivingExpensesPaid = 0;
  module17Data.furOtherEducationLivingExpensesPaid = module17Data.furthermoreHigherEducationExpensesPaid + module17Data.furthermoreHigherChildrenExpensesPaid + module17Data.furthermoreHigherPetExpensesPaid + module17Data.furthermoreHigherCarExpensesPaid + module17Data.furthermoreHigherRealEstateExpensesPaid;
  return module17Data.furOtherEducationLivingExpensesPaid;
}

// Total Monthly Expenses
export function totalMonthlyExpensesCareerAdv() {
  module5Data.totalMonthlyExpensesCareerAdv = 0;
  module5Data.totalMonthlyExpensesCareerAdv = module5Data.totalTaxesPaidCareerAdv
    + module5Data.livingExpensesCareerAdv
    + module5Data.higherEducationExpensesCareerAdv
    + module5Data.childrenExpensesCareerAdv
    + module5Data.petExpensesCareerAdv
    + module5Data.carExpensesCareerAdv
    + module5Data.realEstateExpensesCareerAdv
    + module5Data.interestExpensesCareerAdv;
  return module5Data.totalMonthlyExpensesCareerAdv;
}
// Monthly net Income
export function monthlyNetIncomeCareerAdv() {
  module5Data.monthlyNetIncomeCareerAdv = 0;
  if (
    module5Data.totalMonthlyIncomeCareerAdv !== undefined
    && module5Data.totalMonthlyExpensesCareerAdv !== undefined
  ) {
    module5Data.monthlyNetIncomeCareerAdv = module5Data.totalMonthlyIncomeCareerAdv
      + module5Data.totalMonthlyExpensesCareerAdv;
  }
  return module5Data.monthlyNetIncomeCareerAdv;
}

// Retirement Contributions
export function monthlyRetirementContributionsCareerAdv() {
  module5Data.monthlyRetirementContributionsCareerAdv = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && cashFlowData !== undefined
    && cashFlowData !== ''
    && cashFlowData[module5Data.start_year] !== undefined
    && cashFlowData[module5Data.start_year] !== ''
    && cashFlowData[module5Data.start_year][
    'Cash from Investing Activities'
    ]['Retirement Contributions'] !== undefined
    && cashFlowData[module5Data.start_year]['Cash from Investing Activities'][
    'Retirement Contributions'
    ] !== ''
  ) {
    module5Data.monthlyRetirementContributionsCareerAdv = -cashFlowData[module5Data.start_year]['Cash from Investing Activities'][
      'Retirement Contributions'
    ] / 12;
  }
  return module5Data.monthlyRetirementContributionsCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function monthlyNetCashFlowCareerAdv() {
  module5Data.monthlyNetCashFlowCareerAdv = 0;
  if (
    module5Data.monthlyNetIncomeCareerAdv !== undefined
    && module5Data.monthlyRetirementContributionsCareerAdv !== undefined
  ) {
    module5Data.monthlyNetCashFlowCareerAdv = module5Data.monthlyNetIncomeCareerAdv
      - module5Data.monthlyRetirementContributionsCareerAdv;
  }
  return module5Data.monthlyNetCashFlowCareerAdv;
}

// Total Income
export function totalIncomeCareerAdv() {
  module5Data.totalIncomeCareerAdv = 0;
  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year]['Total Income']
    !== undefined
  ) {
    module5Data.totalIncomeCareerAdv = incomeStatementData[module5Data.start_year]['Total Income'];
  }
  return module5Data.totalIncomeCareerAdv;
}

// Total Income Calcs
export function totalIncomeCareerAdvCalcs() {
  module5Data.totalIncomeCareerAdvCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Income']
      !== undefined
    ) {
      module5Data.totalIncomeCareerAdvCalcs = incomeStatementData[module10Data.marriege_year]['Total Income'];
    } else {
      module5Data.totalIncomeCareerAdvCalcs = 0;
    }
  } else {
    module5Data.totalIncomeCareerAdvCalcs = 0;
  }
  return module5Data.totalIncomeCareerAdvCalcs;
}

// Total Taxes Paid
export function annualTotalTaxesPaidCareerAdv() {
  module5Data.annualTotalTaxesPaidCareerAdv = 0;
  if (
    incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module5Data.start_year] !== undefined
    && incomeStatementData[module5Data.start_year] !== ''
    && incomeStatementData[module5Data.start_year].livingExpenses
    !== undefined
  ) {
    module5Data.annualTotalTaxesPaidCareerAdv = incomeStatementData[module5Data.start_year]['Total Taxes'];
  }
  return module5Data.annualTotalTaxesPaidCareerAdv;
}

// Total Taxes Paid Cacls
export function annualTotalTaxesPaidCareerAdvCalcs() {
  module5Data.annualTotalTaxesPaidCareerAdvCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Taxes']
      !== undefined
    ) {
      module5Data.annualTotalTaxesPaidCareerAdvCalcs = incomeStatementData[module10Data.marriege_year]['Total Taxes'];
    } else {
      module5Data.annualTotalTaxesPaidCareerAdvCalcs = 0;
    }
  } else {
    module5Data.annualTotalTaxesPaidCareerAdvCalcs = 0;
  }
  return module5Data.annualTotalTaxesPaidCareerAdvCalcs;
}

// Living Expense Calcs
export function careerAdvLivingExpensesCalcs() {
  module5Data.careerAdvLivingExpensesCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses[
      'Healthcare Costs other than Premium'
      ] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses[
      'Health Insurance Premium'
      ] !== undefined
    ) {
      module5Data.careerAdvLivingExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Rent
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Food
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Technology
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous
        + incomeStatementData[module10Data.marriege_year].livingExpenses[
        'Healthcare Costs other than Premium'
        ]
        + incomeStatementData[module10Data.marriege_year].livingExpenses[
        'Health Insurance Premium'
        ];
    } else {
      module5Data.careerAdvLivingExpensesCalcs = 0;
    }
  } else {
    module5Data.careerAdvLivingExpensesCalcs = 0;
  }
  return module5Data.careerAdvLivingExpensesCalcs;
}

// Family Iccone
export function careerAdvHigherEducationExpensesCalcs() {
  module5Data.careerAdvHigherEducationExpensesCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses[
      'Higher Education Tuition and Fees'
      ] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses[
      'Higher Education Room & Board'
      ] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses[
      'Off Campus Food'
      ] !== undefined
    ) {
      module5Data.careerAdvHigherEducationExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses[
        'Higher Education Tuition and Fees'
      ]
        + incomeStatementData[module10Data.marriege_year].livingExpenses[
        'Higher Education Room & Board'
        ]
        + incomeStatementData[module10Data.marriege_year].livingExpenses[
        'Off Campus Food'
        ];
    } else {
      module5Data.careerAdvHigherEducationExpensesCalcs = 0;
    }
  } else {
    module5Data.careerAdvHigherEducationExpensesCalcs = 0;
  }
  return module5Data.careerAdvHigherEducationExpensesCalcs;
}

// Car Expenses Calcs
export function careerAdvCarExpensesCalcs() {
  module5Data.careerAdvCarExpensesCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year][
      'Total Car Expenses'
      ] !== undefined
    ) {
      module5Data.careerAdvCarExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Car Expenses'];
    } else {
      module5Data.careerAdvCarExpensesCalcs = 0;
    }
  } else {
    module5Data.careerAdvCarExpensesCalcs = 0;
  }
  return module5Data.careerAdvCarExpensesCalcs;
}

// Children Expenses Calcs
export function careerAdvChildrenExpensesCalcs() {
  module5Data.careerAdvChildrenExpensesCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Children !== undefined
    ) {
      module5Data.careerAdvChildrenExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Children;
    } else {
      module5Data.careerAdvChildrenExpensesCalcs = 0;
    }
  } else {
    module5Data.careerAdvChildrenExpensesCalcs = 0;
  }
  return module5Data.careerAdvChildrenExpensesCalcs;
}

// Children Expenses Calcs
export function careerAdvPetExpensesCalcs() {
  module5Data.careerAdvPetExpensesCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Pet !== undefined
    ) {
      module5Data.careerAdvPetExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Pet;
    } else {
      module5Data.careerAdvPetExpensesCalcs = 0;
    }
  } else {
    module5Data.careerAdvPetExpensesCalcs = 0;
  }
  return module5Data.careerAdvPetExpensesCalcs;
}

// Home Expenses Calcs
export function careerAdvHomeExpensesCalcs() {
  module5Data.careerAdvHomeExpensesCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year][
      'Total Real Estate Expenses'
      ] !== undefined
    ) {
      module5Data.careerAdvHomeExpensesCalcs = incomeStatementData[module10Data.marriege_year][
        'Total Real Estate Expenses'
      ];
    } else {
      module5Data.careerAdvHomeExpensesCalcs = 0;
    }
  } else {
    module5Data.careerAdvHomeExpensesCalcs = 0;
  }
  return module5Data.careerAdvHomeExpensesCalcs;
}

// Financing Expenses Calcs
export function careerAdvFinancingExpensesCalcs() {
  module5Data.careerAdvFinancingExpensesCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module10Data.marriege_year !== undefined
      && module10Data.marriege_year !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year][
      'Total Interest Expenses'
      ] !== undefined
    ) {
      module5Data.careerAdvFinancingExpensesCalcs = incomeStatementData[module10Data.marriege_year][
        'Total Interest Expenses'
      ];
    } else {
      module5Data.careerAdvFinancingExpensesCalcs = 0;
    }
  } else {
    module5Data.careerAdvFinancingExpensesCalcs = 0;
  }
  return module5Data.careerAdvFinancingExpensesCalcs;
}

// Total Annual Expenses Calcs
export function careerAdvTotalAnnualExpensesCalcs() {
  module5Data.careerAdvTotalAnnualExpensesCalcs = 0;
  if (
    module5Data.annualTotalTaxesPaidCareerAdvCalcs !== undefined
    && module5Data.careerAdvLivingExpensesCalcs !== undefined
    && module5Data.careerAdvHigherEducationExpensesCalcs !== undefined
    && module5Data.careerAdvCarExpensesCalcs !== undefined
    && module5Data.careerAdvChildrenExpensesCalcs !== undefined
    && module5Data.careerAdvPetExpensesCalcs !== undefined
    && module5Data.careerAdvHomeExpensesCalcs !== undefined
    && module5Data.careerAdvFinancingExpensesCalcs !== undefined
  ) {
    module5Data.careerAdvTotalAnnualExpensesCalcs = module5Data.annualTotalTaxesPaidCareerAdvCalcs
      + module5Data.careerAdvLivingExpensesCalcs
      + module5Data.careerAdvHigherEducationExpensesCalcs
      + module5Data.careerAdvCarExpensesCalcs
      + module5Data.careerAdvChildrenExpensesCalcs
      + module5Data.careerAdvPetExpensesCalcs
      + module5Data.careerAdvHomeExpensesCalcs
      + module5Data.careerAdvFinancingExpensesCalcs;
  } else {
    module5Data.careerAdvTotalAnnualExpensesCalcs = 0;
  }
  return module5Data.careerAdvTotalAnnualExpensesCalcs;
}

// Annual Net Income
export function annualNetIncomeCareerAdv() {
  module5Data.annualNetIncomeCareerAdv = 0;
  if (
    module5Data.careerAdvTotalAnnualExpenses !== undefined
    && module5Data.totalIncomeCareerAdv !== undefined
  ) {
    module5Data.annualNetIncomeCareerAdv = module5Data.careerAdvTotalAnnualExpenses
      + module5Data.totalIncomeCareerAdv;
  }
  return module5Data.annualNetIncomeCareerAdv;
}

// Annual Net Income Calcs
export function annualNetIncomeCareerAdvCalcs() {
  module5Data.annualNetIncomeCareerAdvCalcs = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    if (
      module5Data.totalIncomeCareerAdvCalcs !== undefined
      && module5Data.careerAdvTotalAnnualExpensesCalcs !== undefined
    ) {
      module5Data.annualNetIncomeCareerAdvCalcs = module5Data.totalIncomeCareerAdvCalcs
        + module5Data.careerAdvTotalAnnualExpensesCalcs;
    } else {
      module5Data.annualNetIncomeCareerAdvCalcs = 0;
    }
  } else {
    module5Data.annualNetIncomeCareerAdvCalcs = 0;
  }
  return module5Data.annualNetIncomeCareerAdvCalcs;
}

// Retirement Contributions
export function annualRetirementContributionsCareerAdv() {
  module5Data.annualRetirementContributionsCareerAdv = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && cashFlowData !== undefined
    && cashFlowData !== ''
    && cashFlowData[module5Data.start_year] !== undefined
    && cashFlowData[module5Data.start_year] !== ''
    && cashFlowData[module5Data.start_year][
    'Cash from Investing Activities'
    ]['Retirement Contributions'] !== undefined
    && cashFlowData[module5Data.start_year]['Cash from Investing Activities'][
    'Retirement Contributions'
    ] !== ''
  ) {
    module5Data.annualRetirementContributionsCareerAdv = -cashFlowData[
      module5Data.start_year
    ]['Cash from Investing Activities']['Retirement Contributions'];
  }
  return module5Data.annualRetirementContributionsCareerAdv;
}

export function annualHsaContributionsCareerAdv() {
  module5Data.annualHsaContributionsCareerAdv = 0;
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && cashFlowData !== undefined
    && cashFlowData !== ''
    && cashFlowData[module5Data.start_year] !== undefined
    && cashFlowData[module5Data.start_year] !== ''
    && cashFlowData[module5Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined && cashFlowData[module5Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== '' ) {
    module5Data.annualHsaContributionsCareerAdv = -cashFlowData[module5Data.start_year]['Cash from Investing Activities']['HSA Contributions'];
  }
  return module5Data.annualHsaContributionsCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function annualNetCashFlowCareerAdv() {
  module5Data.annualNetCashFlowCareerAdv = 0;
  if (
    module5Data.annualNetIncomeCareerAdv !== undefined
    && module5Data.annualRetirementContributionsCareerAdv !== undefined
  ) {
    module5Data.annualNetCashFlowCareerAdv = module5Data.annualNetIncomeCareerAdv
      - module5Data.annualRetirementContributionsCareerAdv;
  }
  return module5Data.annualNetCashFlowCareerAdv;
}

// Total Expenses Paid
export function totalExpensesCareerAdv() {
  module5Data.totalExpensesCareerAdv = 0;
  if (
    module5Data.advanceEstimatedTaxesPaid !== undefined
    && module5Data.advanceLivingExpensesPaid !== undefined
    && module5Data.advanceCarExpensesPaid !== undefined
    && module5Data.advanceRealEstateExpensesPaid !== undefined
    && module5Data.advanceFinancingExpensesPaid !== undefined
  ) {
    module5Data.totalExpensesCareerAdv = module5Data.advanceEstimatedTaxesPaid
      + module5Data.advanceLivingExpensesPaid
      + module5Data.advanceCarExpensesPaid
      + module5Data.advanceRealEstateExpensesPaid
      + module5Data.advanceFinancingExpensesPaid;
  }
  return module5Data.totalExpensesCareerAdv;
}
export function careerAdvanceTotalMonthlyLivingExpenses() {
  module5Data.careerAdvanceTotalMonthlyLivingExpenses = 0;
  let totalExpenses = 0;
  if (
    module5Data.careerAdvanceRentExpenses !== undefined
    && module5Data.careerAdvanceRentExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceRentExpenses;
  }
  if (
    module5Data.careerAdvanceUtilitiesExpenses !== undefined
    && module5Data.careerAdvanceUtilitiesExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceUtilitiesExpenses;
  }
  if (
    module5Data.careerAdvanceFoodExpenses !== undefined
    && module5Data.careerAdvanceFoodExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceFoodExpenses;
  }
  if (
    module5Data.careerAdvanceClothingExpenses !== undefined
    && module5Data.careerAdvanceClothingExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceClothingExpenses;
  }
  if (
    module5Data.careerAdvanceEntertainmentExpenses !== undefined
    && module5Data.careerAdvanceEntertainmentExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceEntertainmentExpenses;
  }
  if (
    module5Data.careerAdvanceTechnologyExpenses !== undefined
    && module5Data.careerAdvanceTechnologyExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceTechnologyExpenses;
  }
  if (
    module5Data.careerAdvanceTransportationExpenses !== undefined
    && module5Data.careerAdvanceTransportationExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceTransportationExpenses;
  }
  if (
    module5Data.careerAdvanceMiscellaneousExpenses !== undefined
    && module5Data.careerAdvanceMiscellaneousExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceMiscellaneousExpenses;
  }
  if (
    module5Data.careerAdvanceOutsidePremiumExpenses !== undefined
    && module5Data.careerAdvanceOutsidePremiumExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvanceOutsidePremiumExpenses;
  }
  if (
    module5Data.careerAdvancePremiumExpenses !== undefined
    && module5Data.careerAdvancePremiumExpenses !== ''
  ) {
    totalExpenses += module5Data.careerAdvancePremiumExpenses;
  }
  module5Data.careerAdvanceTotalMonthlyLivingExpenses = totalExpenses;
  return module5Data.careerAdvanceTotalMonthlyLivingExpenses;
}

export function careerAdvMarriageYear() {
  let careerAdvMarriageYearVal = '';
  if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year >= module10Data.marriege_year
  ) {
    careerAdvMarriageYearVal = module5Data.start_year;
  } else if (
    module5Data.start_year !== undefined
    && module5Data.start_year !== ''
    && module5Data.end_year !== undefined
    && module5Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module5Data.start_year < module10Data.marriege_year
    && module5Data.end_year >= module10Data.marriege_year
  ) {
    careerAdvMarriageYearVal = module10Data.marriege_year;
  } else {
    careerAdvMarriageYearVal = '';
  }
  module5Data.careerAdvMarriageYearVal = careerAdvMarriageYearVal;
  return careerAdvMarriageYearVal;
}

/** ******************** Module 5 career Advancement Ends *********************** */

/** ******************** Module 8 Additional career Advancement Starts *********************** */

export function careerAddAdvsuppYearsDiff() {
  module8Data.supplementaryyearsDiff = 0;
  if (module8Data.second_end_year > 0 && module8Data.second_start_year > 0) {
    module8Data.supplementaryyearsDiff = module8Data.second_end_year - module8Data.second_start_year + 1;
  }
  return module8Data.supplementaryyearsDiff;
}
// Total PreTax Expected
export function jobtotalPreTaxExpected() {
  module8Data.jobtotalPreTaxExpected_val = 0;
  if (module8Data.yearly_income !== undefined && module8Data.yearly_income !== ''
    && module8Data.bonus_or_tips_or_commission !== undefined && module8Data.bonus_or_tips_or_commission !== '') {
    module8Data.jobtotalPreTaxExpected_val = module8Data.yearly_income + module8Data.bonus_or_tips_or_commission;
  }
  return module8Data.jobtotalPreTaxExpected_val;
}
// Supplementary Total PreTax Expected
export function moreSupplementarytotalPreTaxExpected() {
  module8Data.moreSupplementarytotalPreTaxExpected_val = 0;
  if (module8Data.supplementaryYearsDiff !== undefined && module8Data.supplementaryYearsDiff !== ''
    && module8Data.second_yearly_income !== undefined && module8Data.second_yearly_income !== '') {
    module8Data.moreSupplementarytotalPreTaxExpected_val = module8Data.supplementaryYearsDiff * module8Data.second_yearly_income;
  }
  return module8Data.moreSupplementarytotalPreTaxExpected_val;
}
// Total Monthly Living Expenses
export function livingTotalMonthlyLivingExpenses() {
  module8Data.livingTotalMonthlyLivingExpenses_val = 0;
  if (module8Data.rent !== undefined && module8Data.rent !== ''
    && module8Data.utilities !== undefined && module8Data.utilities !== ''
    && module8Data.food !== undefined && module8Data.food !== ''
    && module8Data.clothing !== undefined && module8Data.clothing !== ''
    && module8Data.entertainment !== undefined && module8Data.entertainment !== ''
    && module8Data.technology !== undefined && module8Data.technology !== ''
    && module8Data.transportation !== undefined && module8Data.transportation !== ''
    && module8Data.miscellaneous !== undefined && module8Data.miscellaneous !== ''
    && module8Data.medical_costs_before_deductible !== undefined && module8Data.medical_costs_before_deductible !== ''
    && module8Data.health_insurance_premium !== undefined && module8Data.health_insurance_premium !== '') {
    module8Data.livingTotalMonthlyLivingExpenses_val = module8Data.rent
      + module8Data.utilities
      + module8Data.food
      + module8Data.clothing
      + module8Data.entertainment
      + module8Data.technology
      + module8Data.transportation
      + module8Data.miscellaneous
      + module8Data.medical_costs_before_deductible
      + module8Data.health_insurance_premium;
  }
  return module8Data.livingTotalMonthlyLivingExpenses_val;
}
// Existing Credit Card Interest
export function moreHigherExistingCreditCardInterestInterest() {
  module8Data.moreHigherExistingCreditCardInterestInterest = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const moreData = incomeStatementData;
    let moreCreditCradInterestVal = 0;
    if (Object.keys(moreData).length !== 0) {
      Object.keys(moreData).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          moreCreditCradInterestVal += moreData[year].InterestExpenses['Existing Credit Card Debt'];
        }
      });
    }

    module8Data.moreHigherExistingCreditCardInterestInterest = moreCreditCradInterestVal;
  }
  return module8Data.moreHigherExistingCreditCardInterestInterest;
}
// Student Loan Principal Interest
export function moreHigherstudentLoanPrincipalInterest() {
  module8Data.moreHigherstudentLoanPrincipalInterest = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const moreData = incomeStatementData;
    let moreStudentLoanPrinicipalVal = 0;
    if (Object.keys(moreData).length !== 0) {
      Object.keys(moreData).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          moreStudentLoanPrinicipalVal += moreData[year].InterestExpenses['Student Loans'];
        }
      });
    }
    module8Data.moreHigherstudentLoanPrincipalInterest = moreStudentLoanPrinicipalVal;
  }
  return module8Data.moreHigherstudentLoanPrincipalInterest;
}
// Car Loan Interest
export function moreHighercarLoanInterest() {
  module8Data.moreHighercarLoanInterest = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let carLoanInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          carLoanInterestVal += data[year].InterestExpenses['Car Loans'];
        }
      });
    }

    module8Data.moreHighercarLoanInterest = carLoanInterestVal;
  }
  return module8Data.moreHighercarLoanInterest;
}
// Mortgage Interest
export function moreHighermortgageInterest() {
  module8Data.moreHighermortgageInterest = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let mortgageInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          mortgageInterestVal += data[year].InterestExpenses.Mortgages;
        }
      });
    }

    module8Data.moreHighermortgageInterest = mortgageInterestVal;
  }
  return module8Data.moreHighermortgageInterest;
}
// Total Financing Expenses
export function moreHighertotalFinancingExpenses() {
  module8Data.totalFinancingExpenses = 0;
  module8Data.totalFinancingExpenses = module8Data.moreHigherExistingCreditCardInterestInterest
    + module8Data.moreHigherstudentLoanPrincipalInterest
    + module8Data.moreHighercarLoanInterest
    + module8Data.moreHighermortgageInterest;
  return module8Data.totalFinancingExpenses;
}
// Income Earned
export function moreHigherincomeEarned() {
  module8Data.moreHigherincomeEarned = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let incomeEarnedVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          incomeEarnedVal += data[year]['Total Income'];
        }
      });
    }

    module8Data.moreHigherincomeEarned = incomeEarnedVal;
  }
  return module8Data.moreHigherincomeEarned;
}
// Living Expenses Paid
export function moreHigherlivingExpensesPaid() {
  module8Data.moreHigherlivingExpensesPaid = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let livingExpensesRentAdditional = 0;
    let livingExpensesUtilitiesAdditional = 0;
    let livingExpensesFoodAdditional = 0;
    let livingExpensesClothingAdditional = 0;
    let livingExpensesEntertainmentAdditional = 0;
    let livingExpensesTechnologyAdditional = 0;
    let livingExpensesTransportationAdditional = 0;
    let livingExpensesMiscellaneousAdditional = 0;
    let livingExpensesHealthCareAdditional = 0;
    let livingExpensesHealthInsuranceAdditional = 0;
    let livingExpensesPaidValAdditional = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          livingExpensesRentAdditional += data[year].livingExpenses.Rent;
          livingExpensesUtilitiesAdditional += data[year].livingExpenses.Utilities;
          livingExpensesFoodAdditional += data[year].livingExpenses.Food;
          livingExpensesClothingAdditional += data[year].livingExpenses.Clothing;
          livingExpensesEntertainmentAdditional += data[year].livingExpenses.Entertainment;
          livingExpensesTechnologyAdditional += data[year].livingExpenses.Technology;
          livingExpensesTransportationAdditional += data[year].livingExpenses.Transportation;
          livingExpensesMiscellaneousAdditional += data[year].livingExpenses.Miscellaneous;
          livingExpensesHealthCareAdditional += data[year].livingExpenses['Healthcare Costs other than Premium'];
          livingExpensesHealthInsuranceAdditional += data[year].livingExpenses['Health Insurance Premium'];
        }
      });
    }

    livingExpensesPaidValAdditional = livingExpensesRentAdditional + livingExpensesUtilitiesAdditional + livingExpensesFoodAdditional + livingExpensesClothingAdditional + livingExpensesEntertainmentAdditional + livingExpensesTechnologyAdditional + livingExpensesTransportationAdditional + livingExpensesMiscellaneousAdditional + livingExpensesHealthCareAdditional + livingExpensesHealthInsuranceAdditional;
    module8Data.moreHigherlivingExpensesPaid = livingExpensesPaidValAdditional;
  }
  return module8Data.moreHigherlivingExpensesPaid;
}

// Higher Education Expenses
export function moreHigherEducationExpensesPaid() {
  module8Data.moreHigherEducationExpensesPaid = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let higherEducationExpensesTuition = 0;
    let higherEducationExpensesRoom = 0;
    let higherEducationExpensesTuitionOffCampusFood = 0;
    let higherEducationExpensesVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          higherEducationExpensesTuition += data[year].livingExpenses['Higher Education Tuition and Fees'];
          higherEducationExpensesRoom += data[year].livingExpenses['Higher Education Room & Board'];
          higherEducationExpensesTuitionOffCampusFood += data[year].livingExpenses['Off Campus Food'];
        }
      });
    }

    higherEducationExpensesVal = higherEducationExpensesTuition + higherEducationExpensesRoom + higherEducationExpensesTuitionOffCampusFood;
    module8Data.moreHigherEducationExpensesPaid = higherEducationExpensesVal;
  }
  return module8Data.moreHigherEducationExpensesPaid;
}

// Children Expenses
export function moreChildrenExpensesPaid() {
  module8Data.moreChildrenExpensesPaid = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let childHigherExpensesVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          childHigherExpensesVal += data[year].livingExpenses.Children;
        }
      });
    }

    module8Data.moreChildrenExpensesPaid = childHigherExpensesVal;
  }
  return module8Data.moreChildrenExpensesPaid;
}

// Pet Expenses
export function morePetExpensesPaid() {
  module8Data.morePetExpensesPaid = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let petHigherExpensesVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          petHigherExpensesVal += data[year].livingExpenses.Pet;
        }
      });
    }

    module8Data.morePetExpensesPaid = petHigherExpensesVal;
  }
  return module8Data.morePetExpensesPaid;
}

// Car Expenses Paid
export function moreHigherCarExpensesPaid() {
  module8Data.moreHigherCarExpensesPaid = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let carExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          carExpensesPaidVal += data[year]['Total Car Expenses'];
        }
      });
    }

    module8Data.moreHigherCarExpensesPaid = carExpensesPaidVal;
  }
  return module8Data.moreHigherCarExpensesPaid;
}
// Real Estate Expenses Paid
export function moreHigherRealEstateExpensesPaid() {
  module8Data.moreHigherRealEstateExpensesPaid = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let realEstateExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          realEstateExpensesPaidVal += data[year]['Total Real Estate Expenses'];
        }
      });
    }

    module8Data.moreHigherRealEstateExpensesPaid = realEstateExpensesPaidVal;
  }
  return module8Data.moreHigherRealEstateExpensesPaid;
}
// Estimated Taxes Paid
export function moreHigherEstimatedTaxesPaid() {
  module8Data.moreHigherEstimatedTaxesPaid = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    let estimatedTaxesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          estimatedTaxesPaidVal += data[year]['Total Taxes'];
        }
      });
    }

    module8Data.moreHigherEstimatedTaxesPaid = estimatedTaxesPaidVal;
  }
  return module8Data.moreHigherEstimatedTaxesPaid;
}
// Financing Expenses Paid
export function moreHigherfinancingExpensesPaid() {
  module8Data.moreHigherfinancingExpensesPaid = 0;
  let financingExpensesPaidVal1 = 0;
  let financingExpensesPaidValFinal = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = incomeStatementData;
    // let cashFlowdata = cashFlowData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          financingExpensesPaidVal1 += data[year]['Total Interest Expenses'];
        }
      });
    }
  }
  financingExpensesPaidValFinal = financingExpensesPaidVal1;
  module8Data.moreHigherfinancingExpensesPaid = financingExpensesPaidValFinal;

  return module8Data.moreHigherfinancingExpensesPaid;
}
// Net Income
export function moreHigherNetIncome() {
  module8Data.moreHigherNetIncome = 0;
  if (module8Data.totalExpensesPaidFromAddCareerAdv !== undefined && module8Data.moreHigherincomeEarned !== undefined) {
    module8Data.moreHigherNetIncome = module8Data.totalExpensesPaidFromAddCareerAdv + module8Data.moreHigherincomeEarned;
  }
  return module8Data.moreHigherNetIncome;
}

// Retirement Contributions
export function finalRetirementContributionsAddCareerAdv() {
  module8Data.finalRetirementContributionsAddCareerAdv = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['Retirement Contributions'];
        }
      });
    }

    module8Data.finalRetirementContributionsAddCareerAdv = -retirementContributionValue;
  }
  return module8Data.finalRetirementContributionsAddCareerAdv;
}

// Retirement Contributions
export function finalHsaContributionsAddCareerAdv() {
  module8Data.finalHsaContributionsAddCareerAdv = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== '') {
    const start_year = parseInt(module8Data.start_year, 10);
    const endYear = parseInt(module8Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['HSA Contributions'];
        }
      });
    }

    module8Data.finalHsaContributionsAddCareerAdv = -retirementContributionValue;
  }
  return module8Data.finalHsaContributionsAddCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function finalNetCashFlowAddCareerAdv() {
  module8Data.finalNetCashFlowAddCareerAdv = 0;
  if (module8Data.moreHigherNetIncome !== undefined && module8Data.finalRetirementContributionsAddCareerAdv !== undefined) {
    module8Data.finalNetCashFlowAddCareerAdv = module8Data.moreHigherNetIncome - module8Data.finalRetirementContributionsAddCareerAdv;
  }
  return module8Data.finalNetCashFlowAddCareerAdv;
}

// Single Estimated Tax Rate
export function moreHighersingleEstimatedTaxRate() {
  module8Data.moreHighersingleEstimatedTaxRate = 0;
  if (module8Data.monthlyLivingExpenses !== undefined
    && module8Data.monthlyLivingExpenses > 0) {
    // module8Data.moreHighersingleEstimatedTaxRate = estimatedIncomeTaxes.singleIteration4();
  }
  return module8Data.moreHighersingleEstimatedTaxRate;
}
// Single Estimated Taxes Paid
export function moreHighersingleEstimatedTaxesPaid() {
  module8Data.moreHighersingleEstimatedTaxesPaid = '0';
  if (module8Data.moreHigherincomeEarned !== undefined && module8Data.moreHigherincomeEarned !== ''
    && module8Data.moreHighersingleEstimatedTaxRate !== undefined && module8Data.moreHighersingleEstimatedTaxRate !== '') {
    module8Data.moreHighersingleEstimatedTaxesPaid = -module8Data.moreHigherincomeEarned * (module8Data.moreHighersingleEstimatedTaxRate / 100);
  }
  return module8Data.moreHighersingleEstimatedTaxesPaid;
}
// Single Income After Living Expenses
export function moreHighersingleIncomeAfterLivingExpenses() {
  module8Data.moreHighersingleIncomeAfterLivingExpenses = 0;
  module8Data.moreHighersingleIncomeAfterLivingExpenses = module8Data.moreHigherincomeEarned
    + module8Data.moreHigherlivingExpensesPaid
    + module8Data.moreHigherfinancingExpensesPaid
    + module8Data.moreHighersingleEstimatedTaxesPaid;
  return module8Data.moreHighersingleIncomeAfterLivingExpenses;
}
// Married Estimated Tax Rate
export function moreHighermarriedEstimatedTaxRate() {
  module8Data.moreHighermarriedEstimatedTaxRate = 0;
  if (module8Data.monthlyLivingExpenses !== undefined
    && module8Data.monthlyLivingExpenses > 0) {
    // module8Data.moreHighermarriedEstimatedTaxRate = estimatedIncomeTaxes.marriedIteration4();
  }
  return module8Data.moreHighermarriedEstimatedTaxRate;
}
// Married Estimated Taxes Paid
export function moreHighermarriedEstimatedTaxesPaid() {
  module8Data.moreHighermarriedEstimatedTaxesPaid = 0;
  if (module8Data.moreHigherincomeEarned !== undefined && module8Data.moreHigherincomeEarned !== ''
    && module8Data.moreHighermarriedEstimatedTaxRate !== undefined && module8Data.moreHighermarriedEstimatedTaxRate !== '') {
    module8Data.moreHighermarriedEstimatedTaxesPaid = -module8Data.moreHigherincomeEarned * (module8Data.moreHighermarriedEstimatedTaxRate / 100);
  }
  return module8Data.moreHighermarriedEstimatedTaxesPaid;
}
// Married Income After Living Expenses
export function moreHighermarriedIncomeAfterLivingExpenses() {
  module8Data.moreHighermarriedIncomeAfterLivingExpenses = module8Data.moreHigherincomeEarned
    + module8Data.moreHigherlivingExpensesPaid
    + module8Data.moreHigherfinancingExpensesPaid
    + module8Data.moreHighermarriedEstimatedTaxesPaid;
  return module8Data.moreHighermarriedIncomeAfterLivingExpenses;
}

// export function BeginningBalanceOf401k() {
// 	module4Data.BeginningBalanceOf401k = 0;
// 	if( module4Data.start_year !== undefined && module4Data.start_year !== '' ){
// 		if( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== '' && investmentsData['401k Second'][module4Data.start_year] !== undefined && investmentsData['401k Second'][module4Data.start_year] !== ''){
// 			module4Data.BeginningBalanceOf401k = 	investmentsData['401k Second'][module4Data.start_year]['Total Beginning Balance'];
// 		}
// 	}
// 	return module4Data.BeginningBalanceOf401k;
// }


// Personal Contribution as a % of Base Income Calcs
export function addCareerpersonal401ContributionBaseIncomeCalcs() {
  module8Data.addCareerpersonal401ContributionBaseIncomeCalcs = 0;
  if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.base_income !== undefined && module8Data.base_income !== '') {
    module8Data.addCareerpersonal401ContributionBaseIncomeCalcs = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.base_income;
  }
  return module8Data.addCareerpersonal401ContributionBaseIncomeCalcs;
}

// Employer Matching Rate on Personal Contribution
export function AddCareer401EmployerMatchingrateCalcs() {
  module8Data.AddCareer401EmployerMatchingrateCalcs = 0;
  if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.addCareer401PersonalContribution !== undefined && module8Data.addCareer401PersonalContribution !== '' && module8Data.employer_matching_rate !== undefined && module8Data.employer_matching_rate !== '') {
    module8Data.AddCareer401EmployerMatchingrateCalcs = (module8Data.addCareer401PersonalContribution / 100) * module8Data.employer_matching_rate;
  }
  return module8Data.AddCareer401EmployerMatchingrateCalcs;
}

// Employer Matching Limit as a % of Base Income
export function AddCareer401EmployerMatchingLimitCalcs() {
  module8Data.AddCareer401EmployerMatchingLimitCalcs = 0;
  if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.employer_matching_limit !== undefined && module8Data.employer_matching_limit !== '') {
    module8Data.AddCareer401EmployerMatchingLimitCalcs = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.employer_matching_limit;
  }
  return module8Data.AddCareer401EmployerMatchingLimitCalcs;
}

// Personal Contribution (Max of $00 by law)
export function addCareer401PersonalContribution401k() {
  module8Data.addCareer401PersonalContribution_401k = 0;
  if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year]['Max Personal Limit'] !== undefined) {
    module8Data.addCareer401PersonalContribution_401k = databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year]['Max Personal Limit'];
  } else {
    module8Data.addCareer401PersonalContribution_401k = 0;
  }
  return module8Data.addCareer401PersonalContribution_401k;
}


// Employer Contribution
export function AddCareer401EmployerContribution() {
  module8Data.AddCareer401EmployerContribution = 0;
  let employerContribution1 = 0;
  let employerContribution2 = 0;
  let employerContribution3 = 0;

  if (module8Data.AddCareer401EmployerMatchingrateCalcs !== undefined && module8Data.AddCareer401EmployerMatchingrateCalcs !== ''
    && module8Data.AddCareer401EmployerMatchingLimitCalcs !== undefined && module8Data.AddCareer401EmployerMatchingLimitCalcs !== ''
    && module8Data.AddCareer401EmployerMatchingrateCalcs > module8Data.AddCareer401EmployerMatchingLimitCalcs) {
    employerContribution1 = module8Data.AddCareer401EmployerMatchingLimitCalcs;
  } else {
    employerContribution1 = module8Data.AddCareer401EmployerMatchingrateCalcs;
  }
  if (module8Data.addCareer401PersonalContribution !== undefined && module8Data.addCareer401PersonalContribution !== '') {
    employerContribution2 = module8Data.addCareer401PersonalContribution;
  }
  employerContribution3 = employerContribution1 + employerContribution2;
  if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year]['Total Limit'] !== undefined
    && employerContribution3 > databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year]['Total Limit']) {
    module8Data.AddCareer401EmployerContribution = databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year]['Total Limit'] - employerContribution2;
  } else if (module8Data.AddCareer401EmployerMatchingrateCalcs !== undefined && module8Data.AddCareer401EmployerMatchingrateCalcs !== ''
    && module8Data.AddCareer401EmployerMatchingLimitCalcs !== undefined && module8Data.AddCareer401EmployerMatchingLimitCalcs !== ''
    && module8Data.AddCareer401EmployerMatchingrateCalcs > module8Data.AddCareer401EmployerMatchingLimitCalcs) {
    module8Data.AddCareer401EmployerContribution = module8Data.AddCareer401EmployerMatchingLimitCalcs;
  } else {
    module8Data.AddCareer401EmployerContribution = module8Data.AddCareer401EmployerMatchingrateCalcs;
  }
  return module8Data.AddCareer401EmployerContribution;
}

//Total Annual Contribution Value
export function addCareer401TotalAnnualContribution() {
  module8Data.addCareer401TotalAnnualContribution = 0;


  if (module8Data.addCareer401PersonalContribution !== undefined &&
    module8Data.AddCareer401EmployerContribution !== undefined) {
    module8Data.addCareer401TotalAnnualContribution = module8Data.addCareer401PersonalContribution + module8Data.AddCareer401EmployerContribution;
  }
  return module8Data.addCareer401TotalAnnualContribution;
}

// Beginning Balance In year After
export function beginningBalanceInYearThird() {
  module8Data.beginningBalanceInYearThird = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== '' && investmentsData['401k Second'][module8Data.start_year] !== undefined && investmentsData['401k Second'][module8Data.start_year] !== '') {
      module8Data.beginningBalanceInYearThird = investmentsData['401k Second'][module8Data.start_year]['Total Beginning Balance'];
    }
  }
  return module8Data.beginningBalanceInYearThird;
}

//Beginning Balance In year After
export function beginningBalanceInYearThirdRoth() {
  module8Data.beginningBalanceInYearThirdRoth = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k Second'] !== undefined && investmentsData['Roth 401k Second'] !== '' && investmentsData['Roth 401k Second'][module8Data.start_year] !== undefined && investmentsData['Roth 401k Second'][module8Data.start_year] !== '') {
      module8Data.beginningBalanceInYearThirdRoth = investmentsData['Roth 401k Second'][module8Data.start_year]['Total Beginning Balance'];
    }
  }
  return module8Data.beginningBalanceInYearThirdRoth;
}

export function maxContributionByLawAddCarrAdv() {
  module8Data.maxContributionByLawAddCarrAdv = 0;
  if (module8Data.start_year !== undefined &&
    module8Data.start_year !== '' &&
    databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year] !== undefined &&
    databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year]['Max Personal Limit'] !== undefined) {
    module8Data.maxContributionByLawAddCarrAdv = databaseData['401(k) 2018 Contribution Limits'][module8Data.start_year]['Max Personal Limit'];
  }
  return module8Data.maxContributionByLawAddCarrAdv;
}

// Total Annual Contribution Value
export function addCareer401PersonalContribution() {
  module8Data.addCareer401PersonalContribution = 0;
  if (module8Data.addCareerContributionBaseIncomeCalcs !== undefined &&
    module8Data.addCareer401ContributionBaseIncomeCalcs !== undefined) {
    module8Data.addCareer401PersonalContribution = module8Data.addCareerContributionBaseIncomeCalcs + module8Data.addCareer401ContributionBaseIncomeCalcs;
  }
  return module8Data.addCareer401PersonalContribution;
}
// Copy Beginning Balance In year After
export function beginningBalanceYearThird() {
  module8Data.beginningBalanceYearThird = 0;
  module8Data.beginningBalanceYearThird = module8Data.beginningBalanceInYearThird;
  return module8Data.beginningBalanceYearThird;
}

export function beginningBalanceYearThirdRoth() {
  module8Data.beginningBalanceYearThirdRoth = 0;
  module8Data.beginningBalanceYearThirdRoth = module8Data.beginningBalanceInYearThirdRoth;
  return module8Data.beginningBalanceYearThirdRoth;
}

// Personal Contributions After 2 Years
export function personalContributionAddCareerAdv() {
  module8Data.personalContributionAddCareerAdv = 0;
  let personalContributionVal = 0;
  if (investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalContributionVal += data[year]['Personal Contribution'];
        }
      });
    }
  }
  module8Data.personalContributionAddCareerAdv = personalContributionVal;

  return module8Data.personalContributionAddCareerAdv;
}

export function personalContributionAddCareerAdvRoth() {
  module8Data.personalContributionAddCareerAdvRoth = 0;
  let personalContributionVal = 0;
  if (investmentsData['Roth 401k Second']) {
    const data = investmentsData['Roth 401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalContributionVal += data[year]['Personal Contribution'];
        }
      });
    }
  }
  module8Data.personalContributionAddCareerAdvRoth = personalContributionVal;

  return module8Data.personalContributionAddCareerAdvRoth;
}

// Earnings on Personal Contributions After 2 Years
export function earningsOnPersonalContributionsAfter() {
  module8Data.earningsOnPersonalContributionsAfter = 0;
  let personalEarningsVal = 0;
  if (investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalEarningsVal += data[year]['Personal Earnings'];
        }
      });
    }
  }

  module8Data.earningsOnPersonalContributionsAfter = personalEarningsVal;

  return module8Data.earningsOnPersonalContributionsAfter;
}

export function earningsOnPersonalContributionsAfterRoth() {
  module8Data.earningsOnPersonalContributionsAfterRoth = 0;
  let personalEarningsVal = 0;
  if (investmentsData['Roth 401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalEarningsVal += data[year]['Personal Earnings'];
        }
      });
    }
  }
  module8Data.earningsOnPersonalContributionsAfterRoth = personalEarningsVal;

  return module8Data.earningsOnPersonalContributionsAfterRoth;
}

// Total Personal Contributions & Earnings
export function totalPersonalContributionsAndEarnings() {
  module8Data.totalPersonalContributionsAndEarnings = 0;
  module8Data.totalPersonalContributionsAndEarnings = module8Data.earningsOnPersonalContributionsAfter + module8Data.personalContributionAddCareerAdv;
  return module8Data.totalPersonalContributionsAndEarnings;
}

export function totalPersonalContributionsAndEarningsRoth() {
  module8Data.totalPersonalContributionsAndEarningsRoth = 0;
  module8Data.totalPersonalContributionsAndEarningsRoth = module8Data.personalContributionAddCareerAdvRoth + module8Data.earningsOnPersonalContributionsAfterRoth;
  return module8Data.totalPersonalContributionsAndEarningsRoth;
}

// Employer Contributions After 2 Years
export function employerContributionAddCarrerAdv() {
  module8Data.employerContributionAddCarrerAdv = 0;
  let employerContributionVal = 0;
  if (investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerContributionVal += data[year]['Employer Contribution'];
        }
      });
    }
  }

  module8Data.employerContributionAddCarrerAdv = employerContributionVal;

  return module8Data.employerContributionAddCarrerAdv;
}

export function employerContributionAddCarrerAdvRoth() {
  module8Data.employerContributionAddCarrerAdvRoth = 0;
  let employerContributionVal = 0;
  if (investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerContributionVal += data[year]['Employer Contribution'];
        }
      });
    }
  }
  module8Data.employerContributionAddCarrerAdvRoth = employerContributionVal;

  return module8Data.employerContributionAddCarrerAdvRoth;
}

// Earnings on Employer Contributions After 2 Years
export function earningsOnEmployerContributionsAdd() {
  module8Data.earningsOnEmployerContributionsAdd = 0;
  let employerEarningsVal = 0;
  if (investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerEarningsVal += data[year]['Employer Earnings'];
        }
      });
    }
  }

  module8Data.earningsOnEmployerContributionsAdd = employerEarningsVal;

  return module8Data.earningsOnEmployerContributionsAdd;
}


export function earningsOnEmployerContributionsAddRoth() {
  module8Data.earningsOnEmployerContributionsAddRoth = 0;
  let employerEarningsVal = 0;
  if (investmentsData['Roth 401k Second']) {
    const data = investmentsData['Roth 401k Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.endYear;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerEarningsVal += data[year]['Employer Earnings'];
        }
      });
    }
  }
  module8Data.earningsOnEmployerContributionsAddRoth = employerEarningsVal;

  return module8Data.earningsOnEmployerContributionsAddRoth;
}

// Total Employer Contributions & Earnings
export function totalEmployerContributionsAndEarnings() {
  module8Data.totalEmployerContributionsAndEarnings = 0;
  module8Data.totalEmployerContributionsAndEarnings = module8Data.employerContributionAddCarrerAdv + module8Data.earningsOnEmployerContributionsAdd;
  return module8Data.totalEmployerContributionsAndEarnings;
}

export function totalEmployerContributionsAndEarningsRoth() {
  module8Data.totalEmployerContributionsAndEarningsRoth = 0;
  module8Data.totalEmployerContributionsAndEarningsRoth = module8Data.employerContributionAddCarrerAdvRoth + module8Data.earningsOnEmployerContributionsAddRoth;

  return module8Data.totalEmployerContributionsAndEarningsRoth;
}

// 401(k) Balance before Vesting Reductions
export function balanceBeforeVestingReductionsAdd() {
  module8Data.balanceBeforeVestingReductionsAdd = 0;
  module8Data.balanceBeforeVestingReductionsAdd = module8Data.beginningBalanceInYearThird + module8Data.totalPersonalContributionsAndEarnings + module8Data.totalEmployerContributionsAndEarnings;
  return module8Data.balanceBeforeVestingReductionsAdd;
}

export function balanceBeforeVestingReductionsAddRoth() {
  module8Data.balanceBeforeVestingReductionsAddRoth = 0;
  module8Data.balanceBeforeVestingReductionsAddRoth = module8Data.beginningBalanceInYearThirdRoth + module8Data.totalPersonalContributionsAndEarningsRoth + module8Data.totalEmployerContributionsAndEarningsRoth;
  return module8Data.balanceBeforeVestingReductionsAddRoth;
}

// % Vested after 2 years at 25%/yr Schedule
export function vestedAfterFewYearsScheduleAdd() {
  module8Data.vestedAfterFewYearsScheduleAdd = 0;
  if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined) {
    if (databaseData !== undefined && databaseData !== '') {
      module8Data.vestedAfterFewYearsScheduleAdd = databaseData.Data['401(k) Vesting Schedule'][(parseInt(module8Data.end_year, 10) - parseInt(module8Data.start_year, 10)) + 1];
    }
  }
  return module8Data.vestedAfterFewYearsScheduleAdd;
}

//% of Employer Contributions & Earnings Not Vested
export function employerContributionsAndEarningsNotVestedAdd() {
  module8Data.employerContributionsAndEarningsNotVestedAdd = 0;
  if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.vested_amount_at_end_year !== undefined) {
    module8Data.employerContributionsAndEarningsNotVestedAdd = (-(1 - (module8Data.vested_amount_at_end_year / 100)) * (module8Data.totalEmployerContributionsAndEarnings / 100)) * 100;
  }

  return module8Data.employerContributionsAndEarningsNotVestedAdd;
}

export function employerContributionsAndEarningsNotVestedAddRoth() {
  module8Data.employerContributionsAndEarningsNotVestedAddRoth = 0;
  if (module8Data.roth_contribute_401k !== undefined && module8Data.roth_contribute_401k === 'Yes' && module8Data.vested_amount_at_end_year !== undefined) {
    module8Data.employerContributionsAndEarningsNotVestedAddRoth = (-(1 - (module8Data.vested_amount_at_end_year / 100)) * (module8Data.totalEmployerContributionsAndEarningsRoth / 100)) * 100;
  }
  return module8Data.employerContributionsAndEarningsNotVestedAddRoth;
}

// Ending 401(k) Balance in Year 2026
export function endingBalanceInYearAddCareerAdv() {
  module8Data.endingBalanceInYearAddCareerAdv = 0;
  module8Data.endingBalanceInYearAddCareerAdv = module8Data.balanceBeforeVestingReductionsAdd + module8Data.employerContributionsAndEarningsNotVestedAdd;
  return module8Data.endingBalanceInYearAddCareerAdv;
}

export function endingBalanceInYearAddCareerAdvRoth() {
  module8Data.endingBalanceInYearAddCareerAdvRoth = 0;
  module8Data.endingBalanceInYearAddCareerAdvRoth = module8Data.balanceBeforeVestingReductionsAddRoth + module8Data.employerContributionsAndEarningsNotVestedAddRoth;
  return module8Data.endingBalanceInYearAddCareerAdvRoth;
}
// Roth IRA Retirement Account

// Roth IRA Retirement Beginning Balance
export function addCareerRothIRABeginningBalance() {
  module8Data.addCareerRothIRABeginningBalance = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && investmentsData !== undefined && investmentsData !== ''
    && investmentsData['Retirement Accounts Traditional IRA Second'] !== undefined && investmentsData['Retirement Accounts Traditional IRA Second'] !== ''
    && investmentsData['Retirement Accounts Traditional IRA Second'][module8Data.start_year] !== undefined && investmentsData['Retirement Accounts Traditional IRA Second'][module8Data.start_year] !== ''
    && investmentsData['Retirement Accounts Traditional IRA Second'][module8Data.start_year]['Beginning Balance'] !== undefined) {
    module8Data.addCareerRothIRABeginningBalance = investmentsData['Retirement Accounts Traditional IRA Second'][module8Data.start_year]['Beginning Balance'];
  }
  return module8Data.addCareerRothIRABeginningBalance;
}

export function addCareerRothIRABeginningBalanceRoth() {
  module8Data.addCareerRothIRABeginningBalanceRoth = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth IRA Second'] !== undefined && investmentsData['Roth IRA Second'] !== '' && investmentsData['Roth IRA Second'][module8Data.start_year] !== undefined && investmentsData['Roth IRA Second'][module8Data.start_year] !== '' && investmentsData['Roth IRA Second'][module8Data.start_year]['Beginning Balance'] !== undefined) {
    module8Data.addCareerRothIRABeginningBalanceRoth = investmentsData['Roth IRA Second'][module8Data.start_year]['Beginning Balance'];
  }
  return module8Data.addCareerRothIRABeginningBalanceRoth;
}

export function addCareerMaxIraContribution() {
  module8Data.addCareerMaxIraContribution = 0;
  if (module8Data.start_year !== undefined &&
    module8Data.start_year !== '' &&
    databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year] !== undefined &&
    databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit'] !== undefined) {
    module8Data.addCareerMaxIraContribution = databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit'];
  }
  return module8Data.addCareerMaxIraContribution;
}


// Roth IRA Retirement Personal Contribution as Base Income
// export function addCareerRothIRAPersonalContributionCalcs() {
//   module8Data.addCareerRothIRAPersonalContributionCalcs = 0;
//   if (module8Data.roth_contribute !== undefined && module8Data.roth_contribute === 'Yes' && module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
//     module8Data.addCareerRothIRAPersonalContributionCalcs = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.roth_personal_contribution;
//   }
//   return module8Data.addCareerRothIRAPersonalContributionCalcs;
// }
export function addCareerRothIRAPersonalContributionCalcs() {
  module8Data.addCareerRothIRAPersonalContributionCalcs = 0;
  let firstEle = 0;
  let firstEle1 = 0;
  let firstEle2 = 0;
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
    firstEle1 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.roth_personal_contribution;
  }
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.ira_roth_personal_contribution !== undefined && module8Data.ira_roth_personal_contribution !== '') {
    firstEle2 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.ira_roth_personal_contribution;
  }
  firstEle = firstEle1 + firstEle2;
  if (module8Data.roth_contribute !== undefined && module8Data.roth_contribute === 'Yes' && module8Data.addCareerMaxIraContribution !== undefined && module8Data.addCareerMaxIraContribution !== '' && firstEle <= module8Data.addCareerMaxIraContribution) {
    module8Data.addCareerRothIRAPersonalContributionCalcs = firstEle1;
  } else {
    let base_income = 0;
    if (module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
      base_income = parseFloat(module8Data.roth_personal_contribution);
    }
    let base_roth_income = 0;
    if (module8Data.ira_roth_personal_contribution !== undefined && module8Data.ira_roth_personal_contribution !== '') {
      base_roth_income = parseFloat(module8Data.ira_roth_personal_contribution);
    }

    if (module8Data.addCareerMaxIraContribution !== undefined && module8Data.addCareerMaxIraContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module8Data.addCareerRothIRAPersonalContributionCalcs = (base_income / (base_income + base_roth_income)) * module8Data.addCareerMaxIraContribution;
      } else {
        module8Data.addCareerRothIRAPersonalContributionCalcs = 0;
      }
    } else {
      module8Data.addCareerRothIRAPersonalContributionCalcs = 0;
    }
  }
  return module8Data.addCareerRothIRAPersonalContributionCalcs;
}


export function addCareerRothIRAPersonalContributionCalcsRoth() {
  module8Data.addCareerRothIRAPersonalContributionCalcsRoth = 0;
  let firstEle = 0;
  let firstEle1 = 0;
  let firstEle2 = 0;
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
    firstEle1 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.roth_personal_contribution;
  }
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.ira_roth_personal_contribution !== undefined && module8Data.ira_roth_personal_contribution !== '') {
    firstEle2 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.ira_roth_personal_contribution;
  }
  firstEle = firstEle1 + firstEle2;

  if (module8Data.ira_roth_contribute !== undefined && module8Data.ira_roth_contribute === 'Yes' && module8Data.addCareerMaxIraContribution !== undefined && module8Data.addCareerMaxIraContribution !== '' && firstEle <= module8Data.addCareerMaxIraContribution) {
    module8Data.addCareerRothIRAPersonalContributionCalcsRoth = firstEle2;
  } else {
    let base_income = 0;
    if (module8Data.roth_personal_contribution !== undefined && module8Data.roth_personal_contribution !== '') {
      base_income = parseFloat(module8Data.roth_personal_contribution);
    }
    let base_roth_income = 0;
    if (module8Data.ira_roth_personal_contribution !== undefined && module8Data.ira_roth_personal_contribution !== '') {
      base_roth_income = parseFloat(module8Data.ira_roth_personal_contribution);
    }
    if (module8Data.addCareerMaxIraContribution !== undefined && module8Data.addCareerMaxIraContribution !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module8Data.addCareerRothIRAPersonalContributionCalcsRoth = (base_roth_income / (base_income + base_roth_income)) * module8Data.addCareerMaxIraContribution;
      } else {
        module8Data.addCareerRothIRAPersonalContributionCalcsRoth = 0;
      }
    } else {
      module8Data.addCareerRothIRAPersonalContributionCalcsRoth = 0;
    }
  }
  return module8Data.addCareerRothIRAPersonalContributionCalcsRoth;
}
// Roth IRA Retirement Personal Contribution
// Personal Contribution (Max of $00 by law)
export function addCareerRothIRAPersonalContributionrothIra() {
  module8Data.addCareerRothIRAPersonalContribution_rothIra = 0;
  if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year] !== undefined
    && databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit'] !== undefined) {
    module8Data.addCareerRothIRAPersonalContribution_rothIra = databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit'];
  } else {
    module8Data.addCareerRothIRAPersonalContribution_rothIra = 0;
  }
  return module8Data.addCareerRothIRAPersonalContribution_rothIra;
}

// export function addCareerRothIRAPersonalContribution() {
//   module8Data.addCareerRothIRAPersonalContribution = 0;
//   if (module8Data.addCareerRothIRAPersonalContributionCalcs !== undefined
//     && module8Data.addCareerRothIRAPersonalContributionCalcs !== ''
//     && module8Data.start_year !== undefined
//     && module8Data.start_year !== ''
//     && databaseData !== undefined
//     && databaseData !== ''
//     && databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year] !== undefined
//     && databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit'] !== undefined) {
//     module8Data.addCareerRothIRAPersonalContribution = databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit'];
//     if (module8Data.addCareerRothIRAPersonalContributionCalcs > databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit']) {
//       module8Data.addCareerRothIRAPersonalContribution = databaseData['Roth IRA 2018 Contribution Limit'][module8Data.start_year]['Max Personal Limit'];
//     } else {
//       module8Data.addCareerRothIRAPersonalContribution = module8Data.addCareerRothIRAPersonalContributionCalcs;
//     }
//   }
//   return module8Data.addCareerRothIRAPersonalContribution;
// }

export function addCareerRothIRAPersonalContribution() {
  module8Data.addCareerRothIRAPersonalContribution = 0;
  if (module8Data.addCareerRothIRAPersonalContributionCalcs !== undefined &&
    module8Data.addCareerRothIRAPersonalContributionCalcsRoth !== '') {
    module8Data.addCareerRothIRAPersonalContribution = module8Data.addCareerRothIRAPersonalContributionCalcs + module8Data.addCareerRothIRAPersonalContributionCalcsRoth;
  }
  return module8Data.addCareerRothIRAPersonalContribution;
}

// Roth IRA Retirement Total Contribution
export function addCareerRothIRATotalContribution() {
  module8Data.addCareerRothIRATotalContribution = 0;
  let totalContributionsVal = 0;
  if (investmentsData !== undefined && investmentsData['Roth IRA Second']) {
    const data = investmentsData['Roth IRA Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContributionsVal += data[year]['Annual Contribution'];
        }
      });
    }
  }
  module8Data.addCareerRothIRATotalContribution = totalContributionsVal;

  return module8Data.addCareerRothIRATotalContribution;
}

// Roth IRA Retirement Total Earnings
export function addCareerRothIRATotalEarnings() {
  module8Data.addCareerRothIRATotalEarnings = 0;

  let totalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['Roth IRA Second']) {
    const data = investmentsData['Roth IRA Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarningsVal += data[year].Earnings;
        }
      });
    }
  }

  module8Data.addCareerRothIRATotalEarnings = totalEarningsVal;

  return module8Data.addCareerRothIRATotalEarnings;
}

// Roth IRA Retirement Total Balance
export function addCareerRothIRAEndingBalance() {
  module8Data.addCareerRothIRAEndingBalance = 0;
  if (module8Data.addCareerRothIRATotalEarnings !== undefined && module8Data.addCareerRothIRATotalContribution !== undefined && module8Data.addCareerRothIRABeginningBalance !== undefined) {
    module8Data.addCareerRothIRAEndingBalance = module8Data.addCareerRothIRATotalEarnings + module8Data.addCareerRothIRATotalContribution + module8Data.addCareerRothIRABeginningBalance;
  }
  return module8Data.addCareerRothIRAEndingBalance;
}

/* SEP IRA */

// Beginning Balance
export function addCareerSEPIRABeginningBalance() {
  module8Data.addCareerSEPIRABeginningBalance = 0;
  if (module8Data.start_year !== undefined && investmentsData !== undefined && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'][module8Data.start_year] !== undefined && investmentsData['Seph IRA Second'][module8Data.start_year]['Beginning Balance'] !== undefined) {
    module8Data.addCareerSEPIRABeginningBalance = investmentsData['Seph IRA Second'][module8Data.start_year]['Beginning Balance'];
  }
  return module8Data.addCareerSEPIRABeginningBalance;
}
// SEP IRA Retirement Personal Contribution as Base Income
export function addCareerSEPIRAPersonalContributionCalcs() {
  module8Data.addCareerSEPIRAPersonalContributionCalcs = 0;
  if (module8Data.ira_contribute !== undefined && module8Data.ira_contribute === 'Yes') {
    if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && module8Data.ira_personal_contribution !== undefined && module8Data.ira_personal_contribution !== '') {
      if (module8Data.jobtotalPreTaxExpected_val > databaseData.Data['SEP IRA Limits Second'] && databaseData.Data['SEP IRA Limits Rate'] === module8Data.ira_personal_contribution) {
        module8Data.addCareerSEPIRAPersonalContributionCalcs = databaseData.Data['SEP IRA Limits Rate'] * ((databaseData.Data['SEP IRA Limits Second']) / 100);
      } else {
        module8Data.addCareerSEPIRAPersonalContributionCalcs = ((module8Data.jobtotalPreTaxExpected_val) / 100) * (module8Data.ira_personal_contribution);
      }
    }
  } else {
    module8Data.addCareerSEPIRAPersonalContributionCalcs = 0;
  }
  return module8Data.addCareerSEPIRAPersonalContributionCalcs;
}

// Personal Contribution (Max of the Lesser of 0% of Income or $00 by law)
export function addCareerSEPIRAPersonalContributionsepIra() {
  module8Data.addCareerSEPIRAPersonalContribution_sepIra = 0;
  if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][module8Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module8Data.start_year]['Max Personal Limit'] !== undefined) {
    module8Data.addCareerSEPIRAPersonalContribution_sepIra = databaseData['SEP IRA Contribution Limit'][module8Data.start_year]['Max Personal Limit'];
  } else {
    module8Data.addCareerSEPIRAPersonalContribution_sepIra = 0;
  }
  return module8Data.addCareerSEPIRAPersonalContribution_sepIra;
}

export function addCareerSEPIRAPersonalContribution() {
  module8Data.addCareerSEPIRAPersonalContribution = 0;

  let sepPersonalContribution1 = 0;
  let sepPersonalContribution2 = 0;
  let sepPersonalContribution3 = 0;
  let sepPersonalContribution = 0;

  if (module8Data.jobtotalPreTaxExpected_val !== undefined
    && module8Data.jobtotalPreTaxExpected_val !== ''
    && module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][module8Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module8Data.start_year]['Total Limit'] !== undefined) {
    if (module8Data.jobtotalPreTaxExpected_val > databaseData['SEP IRA Contribution Limit'][module8Data.start_year]['Total Limit']) {
      sepPersonalContribution1 = (databaseData['SEP IRA Contribution Limit'][module8Data.start_year]['Total Limit'] / 100) * databaseData['SEP IRA Contribution Limit'][module8Data.start_year].Return;
    } else {
      sepPersonalContribution1 = (module8Data.jobtotalPreTaxExpected_val / 100) * databaseData['SEP IRA Contribution Limit'][module8Data.start_year].Return;
    }
  } else {
    sepPersonalContribution1 = 0;
  }

  if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][module8Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module8Data.start_year]['Max Personal Limit'] !== undefined) {
    sepPersonalContribution2 = databaseData['SEP IRA Contribution Limit'][module8Data.start_year]['Max Personal Limit'];
  } else {
    sepPersonalContribution2 = 0;
  }

  if (sepPersonalContribution1 > sepPersonalContribution2) {
    sepPersonalContribution3 = sepPersonalContribution2;
  } else {
    sepPersonalContribution3 = sepPersonalContribution1;
  }

  if (module8Data.addCareerSEPIRAPersonalContributionCalcs !== undefined && module8Data.addCareerSEPIRAPersonalContributionCalcs !== '') {
    if (module8Data.addCareerSEPIRAPersonalContributionCalcs > sepPersonalContribution3) {
      sepPersonalContribution = sepPersonalContribution3;
    } else {
      sepPersonalContribution = module8Data.addCareerSEPIRAPersonalContributionCalcs;
    }
  } else {
    sepPersonalContribution = 0;
  }
  module8Data.addCareerSEPIRAPersonalContribution = sepPersonalContribution;
  return module8Data.addCareerSEPIRAPersonalContribution;
}

/* SEP IRA Retirement Summary */
// Total Contributions
export function addCareerSEPIRATotalContribution() {
  module8Data.addCareerSEPIRATotalContribution = 0;
  let totalContribution = 0;
  if (investmentsData !== undefined && investmentsData['Seph IRA Second']) {
    const data = investmentsData['Seph IRA Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContribution += data[year]['Annual Contribution'];
        }
      });
    }
  }
  module8Data.addCareerSEPIRATotalContribution = totalContribution;
  return module8Data.addCareerSEPIRATotalContribution;
}

// Total Earnings
export function addCareerSEPIRATotalEarnings() {
  module8Data.addCareerSEPIRATotalEarnings = 0;
  let totalEarnings = 0;
  if (investmentsData !== undefined && investmentsData['Seph IRA Second']) {
    const data = investmentsData['Seph IRA Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarnings += data[year].Earnings;
        }
      });
    }
  }
  module8Data.addCareerSEPIRATotalEarnings = totalEarnings;
  return module8Data.addCareerSEPIRATotalEarnings;
}

// Ending Sep IRA BALANCE
export function addCareerEndingSEPIRABalance() {
  module8Data.addCareerEndingSEPIRABalance = 0;
  if (module8Data.addCareerSEPIRATotalEarnings !== undefined && module8Data.addCareerSEPIRATotalContribution !== undefined && module8Data.addCareerSEPIRABeginningBalance !== undefined) {
    module8Data.addCareerEndingSEPIRABalance = module8Data.addCareerSEPIRATotalEarnings + module8Data.addCareerSEPIRATotalContribution + module8Data.addCareerSEPIRABeginningBalance;
  }
  return module8Data.addCareerEndingSEPIRABalance;
}

/* Pension */
// Beginning Balance
export function addCareerPensionBeginningBalance() {
  module8Data.addCareerPensionBeginningBalance = 0;
  if (module8Data.start_year !== undefined && investmentsData !== undefined && investmentsData !== '' && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Pension Second'] !== '' && investmentsData['Pension Second'][module8Data.start_year] !== undefined && investmentsData['Pension Second'][module8Data.start_year] !== '' && investmentsData['Pension Second'][module8Data.start_year]['Beginning Balance'] !== undefined) {
    module8Data.addCareerPensionBeginningBalance = investmentsData['Pension Second'][module8Data.start_year]['Beginning Balance'];
  }
  return module8Data.addCareerPensionBeginningBalance;
}

// Personal Contribution Calculations
// export function addCareerPensionPersonalContributionCalc() {
//   module8Data.addCareerPensionPersonalContributionCalc = 0;
//   let contributionVal1 = 0;
//   let contributionVal2 = 0;
//   if (module8Data.pension_contribute !== undefined && module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== ''
//     && module8Data.pension_personal_contribution !== undefined && module8Data.pension_personal_contribution !== '' && module8Data.addCareerPensionEmployerContributionCalc !== undefined
//     && module8Data.addCareerPensionEmployerContributionCalc !== '' && databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined) {
//     if (module8Data.pension_contribute === 'Yes') {
//       contributionVal1 = (((module8Data.jobtotalPreTaxExpected_val) / 100) * module8Data.pension_personal_contribution) + module8Data.addCareerPensionEmployerContributionCalc;
//       contributionVal2 = databaseData.Data['Pension 2018 Limits'];
//       if (contributionVal1 > contributionVal2) {
//         module8Data.addCareerPensionPersonalContributionCalc = contributionVal2 - module8Data.addCareerPensionEmployerContributionCalc;
//       } else {
//         module8Data.addCareerPensionPersonalContributionCalc = ((module8Data.jobtotalPreTaxExpected_val) / 100) * module8Data.pension_personal_contribution;
//       }
//     }
//   }
//   return module8Data.addCareerPensionPersonalContributionCalc;
// }
export function addCareerPensionPersonalContributionCalc() {
  module8Data.addCareerPensionPersonalContributionCalc = 0;
  if (module8Data.pension_contribute !== undefined && module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.pension_personal_contribution !== undefined && module8Data.pension_personal_contribution !== '') {
    if (module8Data.pension_contribute === 'Yes') {
      module8Data.addCareerPensionPersonalContributionCalc = ((module8Data.jobtotalPreTaxExpected_val) / 100) * module8Data.pension_personal_contribution;
    }
  }
  return module8Data.addCareerPensionPersonalContributionCalc;
}
// Employer Contribution Calculations
export function addCareerPensionEmployerContributionCalc() {
  module8Data.addCareerPensionEmployerContributionCalc = 0;
  if (module8Data.pension_contribute !== undefined && module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.pension_employer_contribution !== undefined && module8Data.pension_employer_contribution !== '') {
    if (module8Data.pension_contribute === 'Yes') {
      module8Data.addCareerPensionEmployerContributionCalc = ((module8Data.jobtotalPreTaxExpected_val) / 100) * module8Data.pension_employer_contribution;
    }
  }
  return module8Data.addCareerPensionEmployerContributionCalc;
}

// Employer Contribution
export function addCareerPensionEmployerContribution() {
  module8Data.addCareerPensionEmployerContribution = 0;
  if (module8Data.addCareerPensionPersonalContributionCalc !== undefined
    && module8Data.addCareerPensionPersonalContributionCalc !== ''
    && module8Data.addCareerPensionEmployerContributionCalc !== undefined
    && module8Data.addCareerPensionEmployerContributionCalc !== ''
    && module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Pension 2018 Contribution Limit'][module8Data.start_year] !== undefined
    && databaseData['Pension 2018 Contribution Limit'][module8Data.start_year]['Total Limit'] !== undefined) {
    if ((module8Data.addCareerPensionPersonalContributionCalc + module8Data.addCareerPensionEmployerContributionCalc) > databaseData['Pension 2018 Contribution Limit'][module8Data.start_year]['Total Limit']) {
      module8Data.addCareerPensionEmployerContribution = databaseData['Pension 2018 Contribution Limit'][module8Data.start_year]['Total Limit'] - module8Data.addCareerPensionEmployerContributionCalc;
    } else {
      module8Data.addCareerPensionEmployerContribution = module8Data.addCareerPensionPersonalContributionCalc;
    }
  }
  return module8Data.addCareerPensionEmployerContribution;
}

// Total Annual Contribution
export function addCareerPensionTotalContributionpension() {
  module8Data.addCareerPensionTotalContribution_pension = 0;
  if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Pension 2018 Contribution Limit'][module8Data.start_year] !== undefined
    && databaseData['Pension 2018 Contribution Limit'][module8Data.start_year]['Total Limit'] !== undefined) {
    module8Data.addCareerPensionTotalContribution_pension = databaseData['Pension 2018 Contribution Limit'][module8Data.start_year]['Total Limit'];
  } else {
    module8Data.addCareerPensionTotalContribution_pension = 0;
  }
  return module8Data.addCareerPensionTotalContribution_pension;
}

export function addCareerPensionTotalContribution() {
  module8Data.addCareerPensionTotalContribution = 0;
  if (module8Data.advCareerPensionPersonalContributionCalc !== undefined && module8Data.addCareerPensionEmployerContributionCalc !== undefined) {
    module8Data.addCareerPensionTotalContribution = module8Data.addCareerPensionPersonalContributionCalc + module8Data.addCareerPensionEmployerContributionCalc;
  }
  return module8Data.addCareerPensionTotalContribution;
}

// Total Annual Employer Contribution
export function addCareerPensionTotalEmployerContributions() {
  module8Data.addCareerPensionTotalEmployerContributions = 0;
  let totalEmployerContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEmployerContribution += data[year]['Employer Contribution'];
        }
      });
    }
  }
  module8Data.addCareerPensionTotalEmployerContributions = totalEmployerContribution;

  return module8Data.addCareerPensionTotalEmployerContributions;
}

// Total Annual Personal Contribution
export function addCareerPensionTotalPersonalContributions() {
  module8Data.addCareerPensionTotalPersonalContributions = 0;
  let totalPersonalContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalPersonalContribution += data[year]['Personal Contribution'];
        }
      });
    }
  }

  module8Data.addCareerPensionTotalPersonalContributions = totalPersonalContribution;

  return module8Data.addCareerPensionTotalPersonalContributions;
}

// Total Annual Personal Contribution
export function addCareerPensionTotalEarnings() {
  module8Data.addCareerPensionTotalEarnings = 0;
  let totalEanings = 0;
  if (investmentsData !== undefined && investmentsData['Pension Second']) {
    const data = investmentsData['Pension Second'];
    const start_year = module8Data.start_year;
    const endYear = module8Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEanings += data[year].Earnings;
        }
      });
    }
  }
  module8Data.addCareerPensionTotalEarnings = totalEanings;

  return module8Data.addCareerPensionTotalEarnings;
}

// Total  Pension Ending Balance
export function addCareerEndingPensionBalance() {
  module8Data.addCareerEndingPensionBalance = 0;
  if (module8Data.addCareerPensionTotalEarnings !== undefined
    && module8Data.addCareerPensionTotalPersonalContributions !== undefined
    && module8Data.addCareerPensionTotalEmployerContributions !== undefined
    && module8Data.addCareerPensionBeginningBalance !== undefined) {
    module8Data.addCareerEndingPensionBalance = module8Data.addCareerPensionBeginningBalance + module8Data.addCareerPensionTotalEmployerContributions + module8Data.addCareerPensionTotalPersonalContributions + module8Data.addCareerPensionTotalEarnings;
  }
  return module8Data.addCareerEndingPensionBalance;
}

export function addCareerCareerHsaBeginningBalance() {
  module8Data.addCareerCareerHsaBeginningBalance = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['HSA Second'] !== undefined && investmentsData['HSA Second'] !== '' && investmentsData['HSA Second'][module8Data.start_year] !== undefined && investmentsData['HSA Second'][module8Data.start_year] !== '' && investmentsData['HSA Second'][module8Data.start_year]['Beginning Balance'] !== undefined) {
    module8Data.addCareerCareerHsaBeginningBalance = investmentsData['HSA Second'][module8Data.start_year]['Beginning Balance'];
  }
  return module8Data.addCareerCareerHsaBeginningBalance;
}

export function maxHsaContributionByLawAddCareerAdv() {
  module8Data.maxHsaContributionByLawAddCareerAdv = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['HSA Limits'][module8Data.start_year] !== undefined) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && parseInt(module8Data.start_year) >= parseInt(module10Data.marriege_year)) {
      if (databaseData['HSA Limits'][module8Data.start_year]['Married'] !== undefined && databaseData['HSA Limits'][module8Data.start_year]['Married'] !== '') {
        module8Data.maxHsaContributionByLawAddCareerAdv = databaseData['HSA Limits'][module8Data.start_year]['Married'];
      }
    } else {
      if (databaseData['HSA Limits'][module8Data.start_year]['Single'] !== undefined && databaseData['HSA Limits'][module8Data.start_year]['Single'] !== '') {
        module8Data.maxHsaContributionByLawAddCareerAdv = databaseData['HSA Limits'][module8Data.start_year]['Single'];
      }
    }
  }
  return module8Data.maxHsaContributionByLawAddCareerAdv;
}


export function additionalCareerHsaBeginningBalanceSummary() {
  module8Data.additionalCareerHsaBeginningBalanceSummary = 0;
  if (module8Data.contribute_hsa_savings_account !== undefined && module8Data.contribute_hsa_savings_account === 'Yes' && module8Data.addCareerCareerHsaBeginningBalance !== undefined && module8Data.addCareerCareerHsaBeginningBalance !== '') {
    module8Data.additionalCareerHsaBeginningBalanceSummary = module8Data.addCareerCareerHsaBeginningBalance;
  }
  return module8Data.additionalCareerHsaBeginningBalanceSummary;
}

export function additionalCareerHsaTotalContributionSummary() {
  module8Data.additionalCareerHsaTotalContributionSummary = 0;
  var totalContribution = 0;
  var data = investmentsData['HSA Second'];
  var start_year = module8Data.start_year;
  var endYear = module8Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalContribution += data[year]['Annual Contribution'];
      }
    });
  }
  module8Data.additionalCareerHsaTotalContributionSummary = totalContribution;
  return module8Data.additionalCareerHsaTotalContributionSummary;
}

export function additionalCareerHsaTotalEndingBalanceSummary() {
  module8Data.additionalCareerHsaTotalEndingBalanceSummary = 0;
  module8Data.additionalCareerHsaTotalEndingBalanceSummary = module8Data.additionalCareerHsaBeginningBalanceSummary + module8Data.additionalCareerHsaTotalContributionSummary + module8Data.additionalCareerHsaTotalEarningsSummary;
  return module8Data.additionalCareerHsaTotalEndingBalanceSummary;
}

export function additionalCareerHsaTotalEarningsSummary() {
  module8Data.additionalCareerHsaTotalEarningsSummary = 0;

  let totalEarningsVal = 0;
  let data = investmentsData['HSA Second'];
  let start_year = module8Data.start_year;
  let endYear = module8Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalEarningsVal += data[year]['Earnings'];
      }
    });
  }
  module8Data.additionalCareerHsaTotalEarningsSummary = totalEarningsVal;
  return module8Data.additionalCareerHsaTotalEarningsSummary;
}

export function additionalCareerSEPIRAPersonalContributionCalcs() {
  module8Data.additionalCareerSEPIRAPersonalContributionCalcs = 0;
  let incomeValue = 0;
  if (module8Data.hsa_personal_contribution !== undefined && module8Data.hsa_personal_contribution !== '' && module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '') {
    incomeValue = ((module8Data.jobtotalPreTaxExpected_val) / 100) * module8Data.hsa_personal_contribution;
  }
  if (module8Data.maxHsaContributionByLawAddCareerAdv !== undefined && incomeValue > module8Data.maxHsaContributionByLawAddCareerAdv) {
    module8Data.additionalCareerSEPIRAPersonalContributionCalcs = module8Data.maxHsaContributionByLawAddCareerAdv;
  } else {
    module8Data.additionalCareerSEPIRAPersonalContributionCalcs = incomeValue;
  }
  return module8Data.additionalCareerSEPIRAPersonalContributionCalcs;
}

export function addCareer401ContributionBaseIncomeCalcs() {
  module8Data.addCareer401ContributionBaseIncomeCalcs = 0;
  let firstEle = 0;
  let firstEle1 = 0;
  let firstEle2 = 0;
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.base_income !== undefined && module8Data.base_income !== '') {
    firstEle1 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.base_income;
  }
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.base_roth_income !== undefined && module8Data.base_roth_income !== '') {
    firstEle2 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.base_roth_income;
  }
  firstEle = firstEle1 + firstEle2;
  if (module8Data.roth_contribute_401k !== undefined && module8Data.roth_contribute_401k === 'Yes' && module8Data.maxContributionByLawAddCarrAdv !== undefined && module8Data.maxContributionByLawAddCarrAdv !== '' && firstEle <= module8Data.maxContributionByLawAddCarrAdv) {
    module8Data.addCareer401ContributionBaseIncomeCalcs = firstEle2;
  } else {
    let base_income = 0;
    if (module8Data.base_income !== undefined && module8Data.base_income !== '') {
      base_income = parseFloat(module8Data.base_income);
    }
    let base_roth_income = 0;
    if (module8Data.base_roth_income !== undefined && module8Data.base_roth_income !== '') {
      base_roth_income = parseFloat(module8Data.base_roth_income);
    }

    if (module8Data.maxContributionByLawAddCarrAdv !== undefined && module8Data.maxContributionByLawAddCarrAdv !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module8Data.addCareer401ContributionBaseIncomeCalcs = (base_roth_income / (base_income + base_roth_income)) * module8Data.maxContributionByLawAddCarrAdv;
      } else {
        module8Data.addCareer401ContributionBaseIncomeCalcs = 0;
      }
    } else {
      module8Data.addCareer401ContributionBaseIncomeCalcs = 0;
    }
  }
  return module8Data.addCareer401ContributionBaseIncomeCalcs;
}

export function addCareerContributionBaseIncomeCalcs() {
  module8Data.addCareerContributionBaseIncomeCalcs = 0;
  let firstEle = 0;
  let firstEle1 = 0;
  let firstEle2 = 0;
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.base_income !== undefined && module8Data.base_income !== '') {
    firstEle1 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.base_income;
  }
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '' && module8Data.base_roth_income !== undefined && module8Data.base_roth_income !== '') {
    firstEle2 = (module8Data.jobtotalPreTaxExpected_val / 100) * module8Data.base_roth_income;
  }
  firstEle = firstEle1 + firstEle2;
  if (module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.maxContributionByLawAddCarrAdv !== undefined && module8Data.maxContributionByLawAddCarrAdv !== '' && firstEle <= module8Data.maxContributionByLawAddCarrAdv) {
    module8Data.addCareerContributionBaseIncomeCalcs = firstEle1;
  } else {

    let base_income = 0;
    if (module8Data.base_income !== undefined && module8Data.base_income !== '') {
      base_income = parseFloat(module8Data.base_income);
    }
    let base_roth_income = 0;
    if (module8Data.base_roth_income !== undefined && module8Data.base_roth_income !== '') {
      base_roth_income = parseFloat(module8Data.base_roth_income);
    }

    if (module8Data.maxContributionByLawAddCarrAdv !== undefined && module8Data.maxContributionByLawAddCarrAdv !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module8Data.addCareerContributionBaseIncomeCalcs = (base_income / (base_income + base_roth_income)) * module8Data.maxContributionByLawAddCarrAdv;
      } else {
        module8Data.addCareerContributionBaseIncomeCalcs = 0;
      }
    } else {
      module8Data.addCareerContributionBaseIncomeCalcs = 0;
    }

  }
  return module8Data.addCareerContributionBaseIncomeCalcs;
}


// Additional Career  Rent Multiplier
export function additionalCareerRentMultiplier() {
  module8Data.additionalCareerRentMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerRentMultiplier = module10Data.rent_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerRentMultiplier = module10Data.rent_multiplier;
    } else {
      module8Data.additionalCareerRentMultiplier = '';
    }
  }
  return module8Data.additionalCareerRentMultiplier;
}

// Rent Expenses
export function additionalCareerRentExpenses() {
  let rentExpenses = 0;
  module8Data.additionalCareerRentExpenses = 0;
  if (module8Data.additionalCareerRentMultiplier !== undefined
    && module8Data.additionalCareerRentMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.rent !== undefined
      && module8Data.rent !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses.Rent) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.rent !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Rent) / 12);
      } else {
        rentExpenses = 0;
      }
    }
  } else {
    rentExpenses = '';
  }
  module8Data.additionalCareerRentExpenses = rentExpenses;

  return module8Data.additionalCareerRentExpenses;
}

// Additional Career  Utilities Multiplier
export function additionalCareerUtilitiesMultiplier() {
  module8Data.additionalCareerUtilitiesMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else {
      module8Data.additionalCareerUtilitiesMultiplier = '';
    }
  }
  return module8Data.additionalCareerUtilitiesMultiplier;
}

// Utilities Expenses
export function additionalCareerUtilitiesExpenses() {
  let utilitiesExpense = 0;
  module8Data.additionalCareerUtilitiesExpenses = 0;
  if (module8Data.additionalCareerUtilitiesMultiplier !== undefined
    && module8Data.additionalCareerUtilitiesMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.utilities !== undefined
      && module8Data.utilities !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module8Data.start_year].livingExpenses.Utilities) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.utilities !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities) / 12);
      } else {
        utilitiesExpense = 0;
      }
    }
  } else {
    utilitiesExpense = '';
  }
  module8Data.additionalCareerUtilitiesExpenses = utilitiesExpense;

  return module8Data.additionalCareerUtilitiesExpenses;
}

// Additional Career  Food Multiplier
export function additionalCareerFoodMultiplier() {
  module8Data.additionalCareerFoodMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerFoodMultiplier = module10Data.food_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerFoodMultiplier = module10Data.food_multiplier;
    } else {
      module8Data.additionalCareerFoodMultiplier = '';
    }
  }
  return module8Data.additionalCareerFoodMultiplier;
}

// Food Expenses
export function additionalCareerFoodExpenses() {
  let foodExpenses = 0;
  module8Data.additionalCareerFoodExpenses = 0;
  if (module8Data.additionalCareerFoodMultiplier !== undefined
    && module8Data.additionalCareerFoodMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.food !== undefined
      && module8Data.food !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.food > 0) {
        foodExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses.Food) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.food !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.food > 0) {
        foodExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Food) / 12);
      } else {
        foodExpenses = 0;
      }
    }
  } else {
    foodExpenses = '';
  }
  module8Data.additionalCareerFoodExpenses = foodExpenses;

  return module8Data.additionalCareerFoodExpenses;
}

// Additional Career  Clothing Multiplier
export function additionalCareerClothingMultiplier() {
  module8Data.additionalCareerClothingMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerClothingMultiplier = module10Data.clothing_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerClothingMultiplier = module10Data.clothing_multiplier;
    } else {
      module8Data.additionalCareerClothingMultiplier = '';
    }
  }
  return module8Data.additionalCareerClothingMultiplier;
}

// Clothing Expenses
export function additionalCareerClothingExpenses() {
  let clothingExpenses = 0;
  module8Data.additionalCareerClothingExpenses = 0;
  if (module8Data.additionalCareerClothingMultiplier !== undefined
    && module8Data.additionalCareerClothingMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.clothing !== undefined
      && module8Data.clothing !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses.Clothing) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.clothing !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing) / 12);
      } else {
        clothingExpenses = 0;
      }
    }
  } else {
    clothingExpenses = '';
  }
  module8Data.additionalCareerClothingExpenses = clothingExpenses;

  return module8Data.additionalCareerClothingExpenses;
}

// Additional Career Entertainment Multiplier
export function additionalCareerEntertainmentMultiplier() {
  module8Data.additionalCareerEntertainmentMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else {
      module8Data.additionalCareerEntertainmentMultiplier = '';
    }
  }
  return module8Data.additionalCareerEntertainmentMultiplier;
}

// Entertainment Expenses
export function additionalCareerEntertainmentExpenses() {
  let entertainmentExpenses = 0;
  module8Data.additionalCareerEntertainmentExpenses = 0;
  if (module8Data.additionalCareerEntertainmentMultiplier !== undefined
    && module8Data.additionalCareerEntertainmentMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.entertainment !== undefined
      && module8Data.entertainment !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses.Entertainment) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.entertainment !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment) / 12);
      } else {
        entertainmentExpenses = 0;
      }
    }
  } else {
    entertainmentExpenses = '';
  }
  module8Data.additionalCareerEntertainmentExpenses = entertainmentExpenses;

  return module8Data.additionalCareerEntertainmentExpenses;
}

// Additional Career Technology Multiplier
export function additionalCareerTechnologyMultiplier() {
  module8Data.additionalCareerTechnologyMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerTechnologyMultiplier = module10Data.technology_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerTechnologyMultiplier = module10Data.technology_multiplier;
    } else {
      module8Data.additionalCareerTechnologyMultiplier = '';
    }
  }
  return module8Data.additionalCareerTechnologyMultiplier;
}

// Technology Expenses
export function additionalCareerTechnologyExpenses() {
  let technologyExpenses = 0;
  module8Data.additionalCareerTechnologyExpenses = 0;
  if (module8Data.additionalCareerTechnologyMultiplier !== undefined
    && module8Data.additionalCareerTechnologyMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.technology !== undefined
      && module8Data.technology !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses.Technology) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.technology !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Technology) / 12);
      } else {
        technologyExpenses = 0;
      }
    }
  } else {
    technologyExpenses = '';
  }
  module8Data.additionalCareerTechnologyExpenses = technologyExpenses;

  return module8Data.additionalCareerTechnologyExpenses;
}

// Additional Career Transportation Multiplier
export function additionalCareerTransportationMultiplier() {
  module8Data.additionalCareerTransportationMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerTransportationMultiplier = module10Data.transportation_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerTransportationMultiplier = module10Data.transportation_multiplier;
    } else {
      module8Data.additionalCareerTransportationMultiplier = '';
    }
  }
  return module8Data.additionalCareerTransportationMultiplier;
}

// Transportation Expenses
export function additionalCareerTransportationExpenses() {
  let transportationExpenses = 0;
  module8Data.additionalCareerTransportationExpenses = 0;
  if (module8Data.additionalCareerTransportationMultiplier !== undefined
    && module8Data.additionalCareerTransportationMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.transportation !== undefined
      && module8Data.transportation !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses.Transportation) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.transportation !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation) / 12);
      } else {
        transportationExpenses = 0;
      }
    }
  } else {
    transportationExpenses = '';
  }
  module8Data.additionalCareerTransportationExpenses = transportationExpenses;

  return module8Data.additionalCareerTransportationExpenses;
}

// Additional Career Miscellaneous Multiplier
export function additionalCareerMiscellaneousMultiplier() {
  module8Data.additionalCareerMiscellaneousMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else {
      module8Data.additionalCareerMiscellaneousMultiplier = '';
    }
  }
  return module8Data.additionalCareerMiscellaneousMultiplier;
}

// Miscellaneous Expenses
export function additionalCareerMiscellaneousExpenses() {
  let miscellaneousExpenses = 0;
  module8Data.additionalCareerMiscellaneousExpenses = 0;
  if (module8Data.additionalCareerMiscellaneousMultiplier !== undefined
    && module8Data.additionalCareerMiscellaneousMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.miscellaneous !== undefined
      && module8Data.miscellaneous !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses.Miscellaneous) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.miscellaneous !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous) / 12);
      } else {
        miscellaneousExpenses = 0;
      }
    }
  } else {
    miscellaneousExpenses = '';
  }
  module8Data.additionalCareerMiscellaneousExpenses = miscellaneousExpenses;

  return module8Data.additionalCareerMiscellaneousExpenses;
}

// Additional Career Medical Multiplier
export function additionalCareerOutsidePremiumMultiplier() {
  module8Data.additionalCareerOutsidePremiumMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerOutsidePremiumMultiplier = module10Data.health_insurance_costs_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerOutsidePremiumMultiplier = module10Data.health_insurance_costs_multiplier;
    } else {
      module8Data.additionalCareerOutsidePremiumMultiplier = '';
    }
  }
  return module8Data.additionalCareerOutsidePremiumMultiplier;
}

// Outside Premium Expenses
export function additionalCareerOutsidePremiumExpenses() {
  let healthExpenses = 0;
  module8Data.additionalCareerOutsidePremiumExpenses = 0;
  if (module8Data.additionalCareerOutsidePremiumMultiplier !== undefined
    && module8Data.additionalCareerOutsidePremiumMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.medical_costs_before_deductible !== undefined
      && module8Data.medical_costs_before_deductible !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.medical_costs_before_deductible > 0) {
        healthExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.medical_costs_before_deductible !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.medical_costs_before_deductible > 0) {
        healthExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module8Data.additionalCareerOutsidePremiumExpenses = healthExpenses;

  return module8Data.additionalCareerOutsidePremiumExpenses;
}

// Additional Career Health Multiplier
export function additionalCareerPremiumMultiplier() {
  module8Data.additionalCareerPremiumMultiplier = 0;
  if (module8Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.start_year >= module10Data.marriege_year) {
      module8Data.additionalCareerPremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else if (module8Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module8Data.end_year !== ''
      && module8Data.start_year < module10Data.marriege_year
      && module8Data.end_year >= module10Data.marriege_year) {
      module8Data.additionalCareerPremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else {
      module8Data.additionalCareerPremiumMultiplier = '';
    }
  }
  return module8Data.additionalCareerPremiumMultiplier;
}

// Health Insurances Premium Expenses
export function additionalCareerPremiumExpenses() {
  let healthExpenses = 0;
  module8Data.additionalCareerPremiumExpenses = 0;
  if (module8Data.additionalCareerPremiumMultiplier !== undefined
    && module8Data.additionalCareerPremiumMultiplier !== '') {
    if (module8Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module8Data.health_insurance_premium !== undefined
      && module8Data.health_insurance_premium !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module8Data.start_year] !== undefined
      && incomeStatementData[module8Data.start_year] !== ''
      && incomeStatementData[module8Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module8Data.start_year].livingExpenses !== '') {
      if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.start_year >= module10Data.marriege_year
        && module8Data.health_insurance_premium > 0) {
        healthExpenses = -((incomeStatementData[module8Data.start_year].livingExpenses['Health Insurance Premium']) / 12);
      } else if (module8Data.start_year !== '' && module10Data.marriege_year !== ''
        && module8Data.end_year !== ''
        && module8Data.health_insurance_premium !== ''
        && module8Data.start_year < module10Data.marriege_year
        && module8Data.end_year >= module10Data.marriege_year
        && module8Data.health_insurance_premium > 0) {
        healthExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium']) / 12);
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module8Data.additionalCareerPremiumExpenses = healthExpenses;

  return module8Data.additionalCareerPremiumExpenses;
}

// Annual Expense
export function addCareerAdvLivingExpenses() {
  module8Data.addCareerAdvLivingExpenses = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Rent !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Utilities !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Food !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Clothing !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Entertainment !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Technology !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Transportation !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Miscellaneous !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses['Health Insurance Premium'] !== undefined) {
    module8Data.addCareerAdvLivingExpenses = incomeStatementData[module8Data.start_year].livingExpenses.Rent
      + incomeStatementData[module8Data.start_year].livingExpenses.Utilities
      + incomeStatementData[module8Data.start_year].livingExpenses.Food
      + incomeStatementData[module8Data.start_year].livingExpenses.Clothing
      + incomeStatementData[module8Data.start_year].livingExpenses.Entertainment
      + incomeStatementData[module8Data.start_year].livingExpenses.Technology
      + incomeStatementData[module8Data.start_year].livingExpenses.Transportation
      + incomeStatementData[module8Data.start_year].livingExpenses.Miscellaneous
      + incomeStatementData[module8Data.start_year].livingExpenses['Healthcare Costs other than Premium']
      + incomeStatementData[module8Data.start_year].livingExpenses['Health Insurance Premium'];
  }
  return module8Data.addCareerAdvLivingExpenses;
}

// Hgher Education Expenses Value
export function addCareerAdvHigherEducationExpenses() {
  module8Data.addCareerAdvHigherEducationExpenses = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses['Higher Education Room & Board'] !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses['Off Campus Food'] !== undefined) {
    module8Data.addCareerAdvHigherEducationExpenses = incomeStatementData[module8Data.start_year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementData[module8Data.start_year].livingExpenses['Higher Education Room & Board']
      + incomeStatementData[module8Data.start_year].livingExpenses['Off Campus Food'];
  }
  return module8Data.addCareerAdvHigherEducationExpenses;
}

// Car Expenses Value
export function addCareerAdvCarExpenses() {
  module8Data.addCareerAdvCarExpenses = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined
    && incomeStatementData[module8Data.start_year]['Total Car Expenses'] !== undefined) {
    module8Data.addCareerAdvCarExpenses = incomeStatementData[module8Data.start_year]['Total Car Expenses'];
  }
  return module8Data.addCareerAdvCarExpenses;
}

// Children Expenses Value
export function addCareerAdvChildrenExpenses() {
  module8Data.addCareerAdvChildrenExpenses = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses !== undefined && incomeStatementData[module8Data.start_year].livingExpenses.Children !== undefined) {
    module8Data.addCareerAdvChildrenExpenses = incomeStatementData[module8Data.start_year].livingExpenses.Children;
  }
  return module8Data.addCareerAdvChildrenExpenses;
}
// Pet Expenses Value
export function addCareerAdvPetExpenses() {
  module8Data.addCareerAdvPetExpenses = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined
    && incomeStatementData[module8Data.start_year].livingExpenses.Pet !== undefined) {
    module8Data.addCareerAdvPetExpenses = incomeStatementData[module8Data.start_year].livingExpenses.Pet;
  }
  return module8Data.addCareerAdvPetExpenses;
}

// Home Expenses Value
export function addCareerAdvHomeExpenses() {
  module8Data.addCareerAdvHomeExpenses = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined
    && incomeStatementData[module8Data.start_year]['Total Real Estate Expenses'] !== undefined) {
    module8Data.addCareerAdvHomeExpenses = incomeStatementData[module8Data.start_year]['Total Real Estate Expenses'];
  }
  return module8Data.addCareerAdvHomeExpenses;
}

// Financing Expenses Value
export function addCareerAdvFinancingExpenses() {
  module8Data.addCareerAdvFinancingExpenses = 0;
  let annualLoanPayments1 = 0;
  let annualLoanPaymentsFinal = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined
    && incomeStatementData[module8Data.start_year]['Total Interest Expenses'] !== undefined) {
    annualLoanPayments1 = incomeStatementData[module8Data.start_year]['Total Interest Expenses'];
  }
  annualLoanPaymentsFinal = annualLoanPayments1;
  module8Data.addCareerAdvFinancingExpenses = annualLoanPaymentsFinal;

  return module8Data.addCareerAdvFinancingExpenses;
}

// Total Annual Expenses
export function addCareerAdvTotalAnnualExpenses() {
  module8Data.addCareerAdvTotalAnnualExpenses = 0;
  if (module8Data.annualTotalTaxesPaidAddCareerAdv !== undefined
    && module8Data.addCareerAdvLivingExpenses !== undefined
    && module8Data.addCareerAdvHigherEducationExpenses !== undefined
    && module8Data.addCareerAdvCarExpenses !== undefined
    && module8Data.addCareerAdvChildrenExpenses !== undefined
    && module8Data.addCareerAdvPetExpenses !== undefined
    && module8Data.addCareerAdvHomeExpenses !== undefined
    && module8Data.addCareerAdvFinancingExpenses !== undefined) {
    module8Data.addCareerAdvTotalAnnualExpenses = module8Data.annualTotalTaxesPaidAddCareerAdv
      + module8Data.addCareerAdvLivingExpenses
      + module8Data.addCareerAdvHigherEducationExpenses
      + module8Data.addCareerAdvCarExpenses
      + module8Data.addCareerAdvChildrenExpenses
      + module8Data.addCareerAdvPetExpenses
      + module8Data.addCareerAdvHomeExpenses
      + module8Data.addCareerAdvFinancingExpenses;
  }
  return module8Data.addCareerAdvTotalAnnualExpenses;
}

// Family Iccone
export function addCareerAdvIconCalcs() {
  module8Data.addCareerAdvIconCalcs = '';
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    module8Data.addCareerAdvIconCalcs = module10Data.marriege_year;
  } else {
    module8Data.addCareerAdvIconCalcs = '';
  }
  return module8Data.addCareerAdvIconCalcs;
}

// Monthly Income from career path
export function monthlyIncomeFromAddCareerAdv() {
  module8Data.monthlyIncomeFromAddCareerAdv = 0;
  if (module8Data.jobtotalPreTaxExpected_val !== undefined && module8Data.jobtotalPreTaxExpected_val !== '') {
    module8Data.monthlyIncomeFromAddCareerAdv = module8Data.jobtotalPreTaxExpected_val / 12;
  }
  return module8Data.monthlyIncomeFromAddCareerAdv;
}

// Total Monthly Income
export function totalMonthlyIncomeAddCareerAdv() {
  module8Data.totalMonthlyIncomeAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year]['Total Income'] !== undefined) {
    module8Data.totalMonthlyIncomeAddCareerAdv = (incomeStatementData[module8Data.start_year]['Total Income']) / 12;
  }
  return module8Data.totalMonthlyIncomeAddCareerAdv;
}

// Other Monthly Income
export function otherMonthlyIncomeAddCareerAdv() {
  module8Data.otherMonthlyIncomeAddCareerAdv = 0;
  if (module8Data.totalMonthlyIncomeAddCareerAdv !== undefined && module8Data.monthlyIncomeFromAddCareerAdv !== undefined) {
    module8Data.otherMonthlyIncomeAddCareerAdv = module8Data.totalMonthlyIncomeAddCareerAdv - module8Data.monthlyIncomeFromAddCareerAdv;
  }
  return module8Data.otherMonthlyIncomeAddCareerAdv;
}

// Total Taxes Paid
export function totalTaxesPaidAddCareerAdv() {
  module8Data.totalTaxesPaidAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year]['Total Taxes'] !== undefined) {
    module8Data.totalTaxesPaidAddCareerAdv = (incomeStatementData[module8Data.start_year]['Total Taxes']) / 12;
  }
  return module8Data.totalTaxesPaidAddCareerAdv;
}
// Living Expenses
export function livingExpensesAddCareerAdv() {
  module8Data.livingExpensesAddCareerAdv = 0;
  if (module8Data.livingTotalMonthlyLivingExpenses_val !== undefined) {
    module8Data.livingExpensesAddCareerAdv = -module8Data.livingTotalMonthlyLivingExpenses_val;
  }
  return module8Data.livingExpensesAddCareerAdv;
}

// Higher Education Expenses
export function higherEducationExpensesAddCareerAdv() {
  module8Data.higherEducationExpensesAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year].livingExpenses !== undefined) {
    module8Data.higherEducationExpensesAddCareerAdv = (incomeStatementData[module8Data.start_year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementData[module8Data.start_year].livingExpenses['Higher Education Room & Board']
      + incomeStatementData[module8Data.start_year].livingExpenses['Off Campus Food']) / 12;
  }
  return module8Data.higherEducationExpensesAddCareerAdv;
}
// Children Expenses
export function childrenExpensesAddCareerAdv() {
  module8Data.childrenExpensesAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year].livingExpenses !== undefined) {
    module8Data.childrenExpensesAddCareerAdv = incomeStatementData[module8Data.start_year].livingExpenses.Children / 12;
  }
  return module8Data.childrenExpensesAddCareerAdv;
}

// Pet Expenses
export function petExpensesAddCareerAdv() {
  module8Data.petExpensesAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year].livingExpenses !== undefined) {
    module8Data.petExpensesAddCareerAdv = incomeStatementData[module8Data.start_year].livingExpenses.Pet / 12;
  }
  return module8Data.petExpensesAddCareerAdv;
}
// Car Expenses
export function carExpensesAddCareerAdv() {
  module8Data.carExpensesAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year]['Total Car Expenses'] !== undefined) {
    module8Data.carExpensesAddCareerAdv = incomeStatementData[module8Data.start_year]['Total Car Expenses'] / 12;
  }
  return module8Data.carExpensesAddCareerAdv;
}

// Real Estate Expenses
export function realEstateExpensesAddCareerAdv() {
  module8Data.realEstateExpensesAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year]['Total Real Estate Expenses'] !== undefined) {
    module8Data.realEstateExpensesAddCareerAdv = incomeStatementData[module8Data.start_year]['Total Real Estate Expenses'] / 12;
  }
  return module8Data.realEstateExpensesAddCareerAdv;
}

// Internet Expenses
export function internetExpensesAddCareerPathAdv() {
  module8Data.internetExpensesAddCareerPathAdv = 0;
  let loanPayments1 = 0;
  let loanPayments2 = 0;
  let loanPayments3 = 0;
  let loanPayments4 = 0;
  let loanPayments5 = 0;
  let loanPaymentsFinal = 0;

  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year]['Total Interest Expenses'] !== undefined) {
    loanPayments1 = incomeStatementData[module8Data.start_year]['Total Interest Expenses'] / 12;
  }
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module8Data.start_year] !== undefined && cashFlowData[module8Data.start_year] !== '') {
    if (cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== '') {
      loanPayments2 = cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] / 12;
    }
    if (cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] !== '') {
      loanPayments3 = cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] / 12;
    }
    if (cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] !== '') {
      loanPayments4 = cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] / 12;
    }
    if (cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] !== '') {
      loanPayments5 = cashFlowData[module8Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] / 12;
    }
  }
  loanPaymentsFinal = loanPayments1 + loanPayments2 + loanPayments3 + loanPayments4 + loanPayments5;
  module8Data.internetExpensesAddCareerPathAdv = loanPaymentsFinal;

  return module8Data.internetExpensesAddCareerPathAdv;
}

// Total Monthly Expenses
export function totalMonthlyExpensesAddCareerAdv() {
  module8Data.totalMonthlyExpensesAddCareerAdv = 0;
  module8Data.totalMonthlyExpensesAddCareerAdv = module8Data.totalTaxesPaidAddCareerAdv
    + module8Data.livingExpensesAddCareerAdv
    + module8Data.higherEducationExpensesAddCareerAdv
    + module8Data.childrenExpensesAddCareerAdv
    + module8Data.petExpensesAddCareerAdv
    + module8Data.carExpensesAddCareerAdv
    + module8Data.realEstateExpensesAddCareerAdv
    + module8Data.internetExpensesAddCareerPathAdv;
  return module8Data.totalMonthlyExpensesAddCareerAdv;
}

// Monthly net Income
export function monthlyNetIncomeAddCareerAdv() {
  module8Data.monthlyNetIncomeAddCareerAdv = 0;
  if (module8Data.totalMonthlyIncomeAddCareerAdv !== undefined && module8Data.totalMonthlyExpensesAddCareerAdv !== undefined) {
    module8Data.monthlyNetIncomeAddCareerAdv = module8Data.totalMonthlyIncomeAddCareerAdv + module8Data.totalMonthlyExpensesAddCareerAdv;
  }
  return module8Data.monthlyNetIncomeAddCareerAdv;
}

// Retirement Contributions
export function retirementContributionsAddCareerAdv() {
  module8Data.retirementContributionsAddCareerAdv = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module8Data.start_year] !== undefined && cashFlowData[module8Data.start_year] !== '' && cashFlowData[module8Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== '') {
    module8Data.retirementContributionsAddCareerAdv = -cashFlowData[module8Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] / 12;
  }
  return module8Data.retirementContributionsAddCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function monthlyNetCashFlowAddCareerAdv() {
  module8Data.monthlyNetCashFlowAddCareerAdv = 0;
  if (module8Data.monthlyNetIncomeAddCareerAdv !== undefined && module8Data.retirementContributionsAddCareerAdv !== undefined) {
    module8Data.monthlyNetCashFlowAddCareerAdv = module8Data.monthlyNetIncomeAddCareerAdv - module8Data.retirementContributionsAddCareerAdv;
  }
  return module8Data.monthlyNetCashFlowAddCareerAdv;
}

// Total Income
export function totalIncomeAddCareerAdv() {
  module8Data.totalIncomeAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year]['Total Income'] !== undefined) {
    module8Data.totalIncomeAddCareerAdv = incomeStatementData[module8Data.start_year]['Total Income'];
  }
  return module8Data.totalIncomeAddCareerAdv;
}

// Total Income Calcs
export function totalIncomeAddCareerAdvCalcs() {
  module8Data.totalIncomeAddCareerAdvCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.end_year) {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year]['Total Income'] !== undefined) {
      module8Data.totalIncomeAddCareerAdvCalcs = incomeStatementData[module10Data.marriege_year]['Total Income'];
    }
  }
  return module8Data.totalIncomeAddCareerAdvCalcs;
}

// Total taxes paid
export function annualTotalTaxesPaidAddCareerAdv() {
  module8Data.annualTotalTaxesPaidAddCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module8Data.start_year] !== undefined && incomeStatementData[module8Data.start_year] !== '' && incomeStatementData[module8Data.start_year]['Total Income'] !== undefined) {
    module8Data.annualTotalTaxesPaidAddCareerAdv = incomeStatementData[module8Data.start_year]['Total Taxes'];
  }
  return module8Data.annualTotalTaxesPaidAddCareerAdv;
}

// Total Taxes Paid Calcs

export function annualTotalExpensesAddCareerAdvCalcs() {
  module8Data.annualTotalExpensesAddCareerAdvCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year]['Total Taxes'] !== undefined) {
      module8Data.annualTotalExpensesAddCareerAdvCalcs = incomeStatementData[module10Data.marriege_year]['Total Taxes'];
    }
  }
  return module8Data.annualTotalExpensesAddCareerAdvCalcs;
}

// Annual Net Income
export function addCareerAdvAnnualNetIncome() {
  module8Data.addCareerAdvAnnualNetIncome = 0;
  if (module8Data.addCareerAdvTotalAnnualExpenses !== undefined && module8Data.totalIncomeAddCareerAdv !== undefined) {
    module8Data.addCareerAdvAnnualNetIncome = module8Data.addCareerAdvTotalAnnualExpenses + module8Data.totalIncomeAddCareerAdv;
  }
  return module8Data.addCareerAdvAnnualNetIncome;
}

export function AddCareerAdvAnnualNetIncomeCalcs() {
  module8Data.AddCareerAdvAnnualNetIncomeCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module8Data.totalIncomeAddCareerAdvCalcs !== undefined && module8Data.addCareerAdvTotalAnnualExpensesCalcs !== undefined) {
      module8Data.AddCareerAdvAnnualNetIncomeCalcs = module8Data.totalIncomeAddCareerAdvCalcs + module8Data.addCareerAdvTotalAnnualExpensesCalcs;
    }
  }
  return module8Data.AddCareerAdvAnnualNetIncomeCalcs;
}

// Retirement Contributions
export function annualRetirementContributionsAddCareerAdv() {
  module8Data.annualRetirementContributionsAddCareerAdv = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module8Data.start_year] !== undefined && cashFlowData[module8Data.start_year] !== '' && cashFlowData[module8Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== '') {
    module8Data.annualRetirementContributionsAddCareerAdv = -cashFlowData[module8Data.start_year]['Cash from Investing Activities']['Retirement Contributions'];
  }
  return module8Data.annualRetirementContributionsAddCareerAdv;
}

export function annualHsaContributionsAddCareerAdv() {
  module8Data.annualHsaContributionsAddCareerAdv = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module8Data.start_year] !== undefined && cashFlowData[module8Data.start_year] !== '' && cashFlowData[module8Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== '') {
    module8Data.annualHsaContributionsAddCareerAdv = -cashFlowData[module8Data.start_year]['Cash from Investing Activities']['HSA Contributions'];
  }
  return module8Data.annualHsaContributionsAddCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function annualNetCashFlowAddCareerAdv() {
  module8Data.annualNetCashFlowAddCareerAdv = 0;
  if (module8Data.addCareerAdvAnnualNetIncome !== undefined && module8Data.annualRetirementContributionsAddCareerAdv !== undefined) {
    module8Data.annualNetCashFlowAddCareerAdv = module8Data.addCareerAdvAnnualNetIncome - module8Data.annualRetirementContributionsAddCareerAdv;
  }
  return module8Data.annualNetCashFlowAddCareerAdv;
}

// Total Expenses
export function totalExpensesPaidFromAddCareerAdv() {
  module8Data.totalExpensesPaidFromAddCareerAdv = 0;
  if (module8Data.moreHigherEstimatedTaxesPaid !== undefined
    && module8Data.moreHigherlivingExpensesPaid !== undefined
    && module8Data.moreHigherEducationExpensesPaid !== undefined
    && module8Data.moreChildrenExpensesPaid !== undefined
    && module8Data.morePetExpensesPaid !== undefined
    && module8Data.moreHigherCarExpensesPaid !== undefined
    && module8Data.moreHigherRealEstateExpensesPaid !== undefined
    && module8Data.moreHigherfinancingExpensesPaid !== undefined) {
    module8Data.totalExpensesPaidFromAddCareerAdv = module8Data.moreHigherEstimatedTaxesPaid
      + module8Data.moreHigherlivingExpensesPaid
      + module8Data.moreHigherEducationExpensesPaid
      + module8Data.moreChildrenExpensesPaid
      + module8Data.morePetExpensesPaid
      + module8Data.moreHigherCarExpensesPaid
      + module8Data.moreHigherRealEstateExpensesPaid
      + module8Data.moreHigherfinancingExpensesPaid;
  }
  return module8Data.totalExpensesPaidFromAddCareerAdv;
}

// Living Expense Calcs
export function addCareerAdvLivingExpensesCalcs() {
  module8Data.addCareerAdvLivingExpensesCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] !== undefined) {
      module8Data.addCareerAdvLivingExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Rent
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Food
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Technology
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'];
    } else {
      module8Data.addCareerAdvLivingExpensesCalcs = 0;
    }
  } else {
    module8Data.addCareerAdvLivingExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvLivingExpensesCalcs;
}

// Family Iccone
export function addCareerAdvHigherEducationExpensesCalcs() {
  module8Data.addCareerAdvHigherEducationExpensesCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Room & Board'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Off Campus Food'] !== undefined) {
      module8Data.addCareerAdvHigherEducationExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Tuition and Fees']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Room & Board']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Off Campus Food'];
    } else {
      module8Data.addCareerAdvHigherEducationExpensesCalcs = 0;
    }
  } else {
    module8Data.addCareerAdvHigherEducationExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvHigherEducationExpensesCalcs;
}

// Car Expenses Calcs
export function addCareerAdvCarExpensesCalcs() {
  module8Data.addCareerAdvCarExpensesCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Car Expenses'] !== undefined) {
      module8Data.addCareerAdvCarExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Car Expenses'];
    } else {
      module8Data.addCareerAdvCarExpensesCalcs = 0;
    }
  } else {
    module8Data.addCareerAdvCarExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvCarExpensesCalcs;
}

// Children Expenses Calcs
export function addCareerAdvChildrenExpensesCalcs() {
  module8Data.addCareerAdvChildrenExpensesCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Children !== undefined) {
      module8Data.addCareerAdvChildrenExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Children;
    } else {
      module8Data.addCareerAdvChildrenExpensesCalcs = 0;
    }
  } else {
    module8Data.addCareerAdvChildrenExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvChildrenExpensesCalcs;
}

// Children Expenses Calcs
export function addCareerAdvPetExpensesCalcs() {
  module8Data.addCareerAdvPetExpensesCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Pet !== undefined) {
      module8Data.addCareerAdvPetExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Pet;
    } else {
      module8Data.addCareerAdvPetExpensesCalcs = 0;
    }
  } else {
    module8Data.addCareerAdvPetExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvPetExpensesCalcs;
}

// Home Expenses Calcs
export function addCareerAdvHomeExpensesCalcs() {
  module8Data.addCareerAdvHomeExpensesCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Real Estate Expenses'] !== undefined) {
      module8Data.addCareerAdvHomeExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Real Estate Expenses'];
    } else {
      module8Data.addCareerAdvHomeExpensesCalcs = 0;
    }
  } else {
    module8Data.addCareerAdvHomeExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvHomeExpensesCalcs;
}

// Financing Expenses Calcs
export function addCareerAdvFinancingExpensesCalcs() {
  module8Data.addCareerAdvFinancingExpensesCalcs = 0;
  if (module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module8Data.start_year < module10Data.marriege_year && module8Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Interest Expenses'] !== undefined) {
      module8Data.addCareerAdvFinancingExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Interest Expenses'];
    } else {
      module8Data.addCareerAdvFinancingExpensesCalcs = 0;
    }
  } else {
    module8Data.addCareerAdvFinancingExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvFinancingExpensesCalcs;
}

// Total Annual Expenses Calcs
export function addCareerAdvTotalAnnualExpensesCalcs() {
  module8Data.addCareerAdvTotalAnnualExpensesCalcs = 0;
  if (module8Data.addCareerAdvLivingExpensesCalcs !== undefined
    && module8Data.addCareerAdvHigherEducationExpensesCalcs !== undefined
    && module8Data.addCareerAdvCarExpensesCalcs !== undefined
    && module8Data.addCareerAdvChildrenExpensesCalcs !== undefined
    && module8Data.addCareerAdvPetExpensesCalcs !== undefined
    && module8Data.addCareerAdvHomeExpensesCalcs !== undefined
    && module8Data.addCareerAdvFinancingExpensesCalcs !== undefined) {
    module8Data.addCareerAdvTotalAnnualExpensesCalcs = module8Data.addCareerAdvLivingExpensesCalcs
      + module8Data.addCareerAdvHigherEducationExpensesCalcs
      + module8Data.addCareerAdvCarExpensesCalcs
      + module8Data.addCareerAdvChildrenExpensesCalcs
      + module8Data.addCareerAdvPetExpensesCalcs
      + module8Data.addCareerAdvHomeExpensesCalcs
      + module8Data.addCareerAdvFinancingExpensesCalcs;
  } else {
    module8Data.addCareerAdvTotalAnnualExpensesCalcs = 0;
  }
  return module8Data.addCareerAdvTotalAnnualExpensesCalcs;
}

export function additionalCareerTotalMonthlyLivingExpenses() {
  module8Data.additionalCareerTotalMonthlyLivingExpenses = 0;
  let totalExpenses = 0;
  if (module8Data.additionalCareerRentExpenses !== undefined && module8Data.additionalCareerRentExpenses !== '') {
    totalExpenses += module8Data.additionalCareerRentExpenses;
  }
  if (module8Data.additionalCareerUtilitiesExpenses !== undefined && module8Data.additionalCareerUtilitiesExpenses !== '') {
    totalExpenses += module8Data.additionalCareerUtilitiesExpenses;
  }
  if (module8Data.additionalCareerFoodExpenses !== undefined && module8Data.additionalCareerFoodExpenses !== '') {
    totalExpenses += module8Data.additionalCareerFoodExpenses;
  }
  if (module8Data.additionalCareerClothingExpenses !== undefined && module8Data.additionalCareerClothingExpenses !== '') {
    totalExpenses += module8Data.additionalCareerClothingExpenses;
  }
  if (module8Data.additionalCareerEntertainmentExpenses !== undefined && module8Data.additionalCareerEntertainmentExpenses !== '') {
    totalExpenses += module8Data.additionalCareerEntertainmentExpenses;
  }
  if (module8Data.additionalCareerTechnologyExpenses !== undefined && module8Data.additionalCareerTechnologyExpenses !== '') {
    totalExpenses += module8Data.additionalCareerTechnologyExpenses;
  }
  if (module8Data.additionalCareerTransportationExpenses !== undefined && module8Data.additionalCareerTransportationExpenses !== '') {
    totalExpenses += module8Data.additionalCareerTransportationExpenses;
  }
  if (module8Data.additionalCareerMiscellaneousExpenses !== undefined && module8Data.additionalCareerMiscellaneousExpenses !== '') {
    totalExpenses += module8Data.additionalCareerMiscellaneousExpenses;
  }
  if (module8Data.additionalCareerOutsidePremiumExpenses !== undefined && module8Data.additionalCareerOutsidePremiumExpenses !== '') {
    totalExpenses += module8Data.additionalCareerOutsidePremiumExpenses;
  }
  if (module8Data.additionalCareerPremiumExpenses !== undefined && module8Data.additionalCareerPremiumExpenses !== '') {
    totalExpenses += module8Data.additionalCareerPremiumExpenses;
  }
  module8Data.additionalCareerTotalMonthlyLivingExpenses = totalExpenses;
  return module8Data.additionalCareerTotalMonthlyLivingExpenses;
}

export function addCareerMarriageYear() {
  let addCareerMarriageYearVal = '';
  if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module8Data.start_year >= module10Data.marriege_year) {
    addCareerMarriageYearVal = module8Data.start_year;
  } else if (module8Data.start_year !== undefined
    && module8Data.start_year !== ''
    && module8Data.end_year !== undefined
    && module8Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module8Data.start_year < module10Data.marriege_year
    && module8Data.end_year >= module10Data.marriege_year) {
    addCareerMarriageYearVal = module10Data.marriege_year;
  } else {
    addCareerMarriageYearVal = '';
  }
  module8Data.addCareerMarriageYearVal = addCareerMarriageYearVal;
  return addCareerMarriageYearVal;
}
/** ******************** Module 8 Additional Career Advancement Ends *********************** */

/** ******************** Module 17 Further Career Advancement Starts *********************** */
export function careerFurAdvsuppYearsDiff() {
  module17Data.supplementaryyearsDiff = 0;
  if (module17Data.second_end_year > 0 && module17Data.second_start_year > 0) {
    module17Data.supplementaryyearsDiff = module17Data.second_end_year - module17Data.second_start_year + 1;
  }
  return module17Data.supplementaryyearsDiff;
}

// Total PreTax Expected
export function furtherjobtotalPreTaxExpected() {
  module17Data.jobtotalPreTaxExpected_val = 0;
  if (module17Data.yearly_income !== undefined && module17Data.bonus_or_tips_or_commission !== undefined) {
    module17Data.jobtotalPreTaxExpected_val = module17Data.yearly_income + module17Data.bonus_or_tips_or_commission;
  }
  return module17Data.jobtotalPreTaxExpected_val;
}
// Supplementary Total PreTax Expected
export function furthermoreSupplementarytotalPreTaxExpected() {
  module17Data.moreSupplementarytotalPreTaxExpected_val = 0;
  if (module17Data.supplementaryYearsDiff !== undefined && module17Data.supplementaryYearsDiff !== ''
    && module17Data.second_yearly_income !== undefined && module17Data.second_yearly_income !== '') {
    module17Data.moreSupplementarytotalPreTaxExpected_val = module17Data.supplementaryYearsDiff * module17Data.second_yearly_income;
  }
  return module17Data.moreSupplementarytotalPreTaxExpected_val;
}
// Total Monthly Living Expenses
export function furtherlivingTotalMonthlyLivingExpenses() {
  module17Data.livingTotalMonthlyLivingExpenses_val = 0;
  if (module17Data.rent !== undefined && module17Data.rent !== ''
    && module17Data.utilities !== undefined && module17Data.utilities !== ''
    && module17Data.food !== undefined && module17Data.food !== ''
    && module17Data.clothing !== undefined && module17Data.clothing !== ''
    && module17Data.entertainment !== undefined && module17Data.entertainment !== ''
    && module17Data.technology !== undefined && module17Data.technology !== ''
    && module17Data.transportation !== undefined && module17Data.transportation !== ''
    && module17Data.miscellaneous !== undefined && module17Data.miscellaneous !== ''
    && module17Data.medical_costs_before_deductible !== undefined && module17Data.medical_costs_before_deductible !== ''
    && module17Data.health_insurance_premium !== undefined && module17Data.health_insurance_premium !== '') {
    module17Data.livingTotalMonthlyLivingExpenses_val = module17Data.rent
      + module17Data.utilities
      + module17Data.food
      + module17Data.clothing
      + module17Data.entertainment
      + module17Data.technology
      + module17Data.transportation
      + module17Data.miscellaneous
      + module17Data.medical_costs_before_deductible
      + module17Data.health_insurance_premium;
  }
  return module17Data.livingTotalMonthlyLivingExpenses_val;
}
// Existing Credit Card Interest
export function furthermoreHigherExistingCreditCardInterestInterest() {
  module17Data.furthermoreHigherExistingCreditCardInterestInterest = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const moreData = incomeStatementData;
    let moreExistingCreditCardVal = 0;
    if (Object.keys(moreData).length !== 0) {
      Object.keys(moreData).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            moreExistingCreditCardVal += moreData[year].InterestExpenses['Existing Credit Card Debt'];
          }
        }
      });
    }

    module17Data.furthermoreHigherExistingCreditCardInterestInterest = moreExistingCreditCardVal;
  }
  return module17Data.furthermoreHigherExistingCreditCardInterestInterest;
}
// Student Loan Principal Interest
export function furthermoreHigherstudentLoanPrincipalInterest() {
  module17Data.furthermoreHigherstudentLoanPrincipalInterest = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const moreData = incomeStatementData;
    let moreStudentLoanPrinicipalVal = 0;
    if (Object.keys(moreData).length !== 0) {
      Object.keys(moreData).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          moreStudentLoanPrinicipalVal += moreData[year].InterestExpenses['Student Loans'];
        }
      });
    }

    module17Data.furthermoreHigherstudentLoanPrincipalInterest = moreStudentLoanPrinicipalVal;
  }
  return module17Data.furthermoreHigherstudentLoanPrincipalInterest;
}
// Car Loan Interest
export function furthermoreHighercarLoanInterest() {
  module17Data.furthermoreHighercarLoanInterest = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let carLoanInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          carLoanInterestVal += data[year].InterestExpenses['Car Loans'];
        }
      });
    }

    module17Data.furthermoreHighercarLoanInterest = carLoanInterestVal;
  }
  return module17Data.furthermoreHighercarLoanInterest;
}
// Mortgage Interest
export function furthermoreHighermortgageInterest() {
  module17Data.furthermoreHighermortgageInterest = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let mortgageInterestVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          mortgageInterestVal += data[year].InterestExpenses.Mortgages;
        }
      });
    }

    module17Data.furthermoreHighermortgageInterest = mortgageInterestVal;
  }
  return module17Data.furthermoreHighermortgageInterest;
}
// Total Financing Expenses
export function furthermoreHighertotalFinancingExpenses() {
  module17Data.totalFinancingExpenses = 0;
  module17Data.totalFinancingExpenses = module17Data.furthermoreHigherExistingCreditCardInterestInterest
    + module17Data.furthermoreHigherstudentLoanPrincipalInterest
    + module17Data.furthermoreHighercarLoanInterest
    + module17Data.furthermoreHighermortgageInterest;
  return module17Data.totalFinancingExpenses;
}
// Income Earned
export function furthermoreHigherincomeEarned() {
  module17Data.furthermoreHigherincomeEarned = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let realEstateExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          realEstateExpensesPaidVal += data[year]['Total Income'];
        }
      });
    }

    module17Data.furthermoreHigherincomeEarned = realEstateExpensesPaidVal;
  }
  return module17Data.furthermoreHigherincomeEarned;
}
// Living Expenses Paid
export function furthermoreHigherlivingExpensesPaid() {
  module17Data.furthermoreHigherlivingExpensesPaid = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let livingExpensesRentFurther = 0;
    let livingExpensesUtilitiesFurther = 0;
    let livingExpensesFoodFurther = 0;
    let livingExpensesClothingFurther = 0;
    let livingExpensesEntertainmentFurther = 0;
    let livingExpensesTechnologyFurther = 0;
    let livingExpensesTransportationFurther = 0;
    let livingExpensesMiscellaneousFurther = 0;
    let livingExpensesHealthCareFurther = 0;
    let livingExpensesHealthInsuranceFurther = 0;
    let livingExpensesPaidValFurther = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          livingExpensesRentFurther += data[year].livingExpenses.Rent;
          livingExpensesUtilitiesFurther += data[year].livingExpenses.Utilities;
          livingExpensesFoodFurther += data[year].livingExpenses.Food;
          livingExpensesClothingFurther += data[year].livingExpenses.Clothing;
          livingExpensesEntertainmentFurther += data[year].livingExpenses.Entertainment;
          livingExpensesTechnologyFurther += data[year].livingExpenses.Technology;
          livingExpensesTransportationFurther += data[year].livingExpenses.Transportation;
          livingExpensesMiscellaneousFurther += data[year].livingExpenses.Miscellaneous;
          livingExpensesHealthCareFurther += data[year].livingExpenses['Healthcare Costs other than Premium'];
          livingExpensesHealthInsuranceFurther += data[year].livingExpenses['Health Insurance Premium'];
        }
      });
    }

    livingExpensesPaidValFurther = livingExpensesRentFurther + livingExpensesUtilitiesFurther + livingExpensesFoodFurther + livingExpensesClothingFurther + livingExpensesEntertainmentFurther + livingExpensesTechnologyFurther + livingExpensesTransportationFurther + livingExpensesMiscellaneousFurther + livingExpensesHealthCareFurther + livingExpensesHealthInsuranceFurther;
    module17Data.furthermoreHigherlivingExpensesPaid = livingExpensesPaidValFurther;
  }
  return module17Data.furthermoreHigherlivingExpensesPaid;
}

// Higher education Expenses Paid
export function furthermoreHigherEducationExpensesPaid() {
  module17Data.furthermoreHigherEducationExpensesPaid = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let higherExpensesTuitionFurther = 0;
    let higherExpensesRoomAndBoardFurther = 0;
    let higherExpensesOffCampusFurther = 0;
    let higherExpensesTotalValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          higherExpensesTuitionFurther += data[year].livingExpenses['Higher Education Tuition and Fees'];
          higherExpensesRoomAndBoardFurther += data[year].livingExpenses['Higher Education Room & Board'];
          higherExpensesOffCampusFurther += data[year].livingExpenses['Off Campus Food'];
        }
      });
    }

    higherExpensesTotalValue = higherExpensesTuitionFurther + higherExpensesRoomAndBoardFurther + higherExpensesOffCampusFurther;
    module17Data.furthermoreHigherEducationExpensesPaid = higherExpensesTotalValue;
  }
  return module17Data.furthermoreHigherEducationExpensesPaid;
}

// Children Expenses Paid
export function furthermoreHigherChildrenExpensesPaid() {
  module17Data.furthermoreHigherChildrenExpensesPaid = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let childrenExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          childrenExpensesPaidVal += data[year].livingExpenses.Children;
        }
      });
    }
    module17Data.furthermoreHigherChildrenExpensesPaid = childrenExpensesPaidVal;
  }
  return module17Data.furthermoreHigherChildrenExpensesPaid;
}

// Pet Expenses Paid
export function furthermoreHigherPetExpensesPaid() {
  module17Data.furthermoreHigherPetExpensesPaid = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let petExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          petExpensesPaidVal += data[year].livingExpenses.Pet;
        }
      });
    }

    module17Data.furthermoreHigherPetExpensesPaid = petExpensesPaidVal;
  }
  return module17Data.furthermoreHigherPetExpensesPaid;
}

// Car Expenses Paid
export function furthermoreHigherCarExpensesPaid() {
  module17Data.furthermoreHigherCarExpensesPaid = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let carExpensesPaidVal = 0;

    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          carExpensesPaidVal += data[year]['Total Car Expenses'];
        }
      });
    }
    module17Data.furthermoreHigherCarExpensesPaid = carExpensesPaidVal;
  }
  return module17Data.furthermoreHigherCarExpensesPaid;
}
// Real Estate Expenses Paid
export function furthermoreHigherRealEstateExpensesPaid() {
  module17Data.furthermoreHigherRealEstateExpensesPaid = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let realEstateExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          realEstateExpensesPaidVal += data[year]['Total Real Estate Expenses'];
        }
      });
    }

    module17Data.furthermoreHigherRealEstateExpensesPaid = realEstateExpensesPaidVal;
  }
  return module17Data.furthermoreHigherRealEstateExpensesPaid;
}
// Estimated Taxes Paid
export function furthermoreHigherEstimatedTaxesPaid() {
  module17Data.furthermoreHigherEstimatedTaxesPaid = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    let estimatedTaxedPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          estimatedTaxedPaidVal += data[year]['Total Taxes'];
        }
      });
    }

    module17Data.furthermoreHigherEstimatedTaxesPaid = estimatedTaxedPaidVal;
  } else {
    module17Data.furthermoreHigherEstimatedTaxesPaid = 0;
  }
  return module17Data.furthermoreHigherEstimatedTaxesPaid;
}
// Net Income
export function furthermoreHigherNetIncome() {
  module17Data.furthermoreHigherNetIncome = 0;
  if (module17Data.furCareerAdvTotalExpenses !== undefined && module17Data.furthermoreHigherincomeEarned !== undefined) {
    module17Data.furthermoreHigherNetIncome = module17Data.furCareerAdvTotalExpenses + module17Data.furthermoreHigherincomeEarned;
  }
  return module17Data.furthermoreHigherNetIncome;
}
// Retirement Contributions
export function finalRetirementContributionsFurCareerAdv() {
  module17Data.finalRetirementContributionsFurCareerAdv = 0;

  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['Retirement Contributions'];
        }
      });
    }

    module17Data.finalRetirementContributionsFurCareerAdv = -retirementContributionValue;
  }
  return module17Data.finalRetirementContributionsFurCareerAdv;
}

// Retirement Contributions
export function finalHsaContributionsFurCareerAdv() {
  module17Data.finalHsaContributionsFurCareerAdv = 0;

  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = cashFlowData;
    let retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['HSA Contributions'];
        }
      });
    }

    module17Data.finalHsaContributionsFurCareerAdv = -retirementContributionValue;
  }
  return module17Data.finalHsaContributionsFurCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function finalNetCashFlowFurCareerAdv() {
  module17Data.finalNetCashFlowFurCareerAdv = 0;
  if (module17Data.furthermoreHigherNetIncome !== undefined && module17Data.finalRetirementContributionsFurCareerAdv !== undefined) {
    module17Data.finalNetCashFlowFurCareerAdv = module17Data.furthermoreHigherNetIncome - module17Data.finalRetirementContributionsFurCareerAdv;
  }
  return module17Data.finalNetCashFlowFurCareerAdv;
}
// Financing Expenses Paid
export function furthermoreHigherfinancingExpensesPaid() {
  module17Data.furthermoreHigherfinancingExpensesPaid = 0;
  let financingExpensesPaidVal1 = 0;
  let financingExpensesPaidValFinal = 0;

  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== '') {
    const start_year = parseInt(module17Data.start_year, 10);
    const endYear = parseInt(module17Data.end_year, 10);
    const data = incomeStatementData;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          financingExpensesPaidVal1 += data[year]['Total Interest Expenses'];
        }
      });
    }
  }
  financingExpensesPaidValFinal = financingExpensesPaidVal1;
  module17Data.furthermoreHigherfinancingExpensesPaid = financingExpensesPaidValFinal;

  return module17Data.furthermoreHigherfinancingExpensesPaid;
}
// Single Estimated Tax Rate
export function furthermoreHighersingleEstimatedTaxRate() {
  module17Data.furthermoreHighersingleEstimatedTaxRate = 0;
  if (module17Data.monthlyLivingExpenses !== undefined
    && module17Data.monthlyLivingExpenses > 0) {
    // module17Data.furthermoreHighersingleEstimatedTaxRate = estimatedIncomeTaxes.singleIteration9();
  }
  return module17Data.furthermoreHighersingleEstimatedTaxRate;
}
// Single Estimated Taxes Paid
export function furthermoreHighersingleEstimatedTaxesPaid() {
  module17Data.furthermoreHighersingleEstimatedTaxesPaid = '0';
  if (module17Data.furthermoreHigherincomeEarned !== undefined && module17Data.furthermoreHigherincomeEarned !== ''
    && module17Data.furthermoreHighersingleEstimatedTaxRate !== undefined && module17Data.furthermoreHighersingleEstimatedTaxRate !== '') {
    module17Data.furthermoreHighersingleEstimatedTaxesPaid = -module17Data.furthermoreHigherincomeEarned * (module17Data.furthermoreHighersingleEstimatedTaxRate / 100);
  }
  return module17Data.furthermoreHighersingleEstimatedTaxesPaid;
}
// Single Income After Living Expenses
export function furthermoreHighersingleIncomeAfterLivingExpenses() {
  module17Data.furthermoreHighersingleIncomeAfterLivingExpenses = 0;
  module17Data.furthermoreHighersingleIncomeAfterLivingExpenses = module17Data.furthermoreHigherincomeEarned
    + module17Data.furthermoreHigherlivingExpensesPaid
    + module17Data.furthermoreHigherfinancingExpensesPaid
    + module17Data.furthermoreHighersingleEstimatedTaxesPaid;
  return module17Data.furthermoreHighersingleIncomeAfterLivingExpenses;
}
// Married Estimated Tax Rate
export function furthermoreHighermarriedEstimatedTaxRate() {
  module17Data.furthermoreHighermarriedEstimatedTaxRate = 0;
  if (module17Data.monthlyLivingExpenses !== undefined
    && module17Data.monthlyLivingExpenses > 0) {
    // module17Data.furthermoreHighermarriedEstimatedTaxRate = estimatedIncomeTaxes.marriedIteration9();
  }
  return module17Data.furthermoreHighermarriedEstimatedTaxRat;
}
// Married Estimated Taxes Paid
export function furthermoreHighermarriedEstimatedTaxesPaid() {
  module17Data.furthermoreHighermarriedEstimatedTaxesPaid = 0;
  if (module17Data.furthermoreHigherincomeEarned !== undefined && module17Data.furthermoreHigherincomeEarned !== ''
    && module17Data.furthermoreHighermarriedEstimatedTaxRate !== undefined && module17Data.furthermoreHighermarriedEstimatedTaxRate !== '') {
    module17Data.furthermoreHighermarriedEstimatedTaxesPaid = -module17Data.furthermoreHigherincomeEarned * (module17Data.furthermoreHighermarriedEstimatedTaxRate / 100);
  }
  return module17Data.furthermoreHighermarriedEstimatedTaxesPaid;
}
// Married Income After Living Expenses
export function furthermoreHighermarriedIncomeAfterLivingExpenses() {
  module17Data.furthermoreHighermarriedIncomeAfterLivingExpenses = module17Data.furthermoreHigherincomeEarned
    + module17Data.furthermoreHigherlivingExpensesPaid
    + module17Data.furthermoreHigherfinancingExpensesPaid
    + module17Data.furthermoreHighermarriedEstimatedTaxesPaid;
  return module17Data.furthermoreHighermarriedIncomeAfterLivingExpenses;
}

// Beginning Balance In year After
export function beginningBalanceInYearFourth() {
  module17Data.beginningBalanceInYearFourth = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== '' && investmentsData['401k Second'][module17Data.start_year] !== undefined && investmentsData['401k Second'][module17Data.start_year] !== '') {
      module17Data.beginningBalanceInYearFourth = investmentsData['401k Second'][module17Data.start_year]['Total Beginning Balance'];
    }
  }
  return module17Data.beginningBalanceInYearFourth;
}

// Methods by Akhil
export function addCareerRothIRATotalContributionRoth() {
  module8Data.addCareerRothIRATotalContributionRoth = 0;
  let totalContributionsVal = 0;
  let data = investmentsData['Roth IRA Second'];
  let start_year = module8Data.start_year;
  let endYear = module8Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalContributionsVal += data[year]['Annual Contribution'];
      }
    });
  }
  module8Data.addCareerRothIRATotalContributionRoth = totalContributionsVal;

  return module8Data.addCareerRothIRATotalContributionRoth;
}

export function addCareerRothIRATotalEarningsRoth() {
  module8Data.addCareerRothIRATotalEarningsRoth = 0;

  var totalEarningsVal = 0;
  var data = investmentsData['Roth IRA Second'];
  var start_year = module8Data.start_year;
  var endYear = module8Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalEarningsVal += data[year]['Earnings'];
      }
    });
  }
  module8Data.addCareerRothIRATotalEarningsRoth = totalEarningsVal;

  return module8Data.addCareerRothIRATotalEarningsRoth;
}

export function addCareerRothIRAEndingBalanceRoth() {
  module8Data.addCareerRothIRAEndingBalanceRoth = 0;
  if (module8Data.addCareerRothIRATotalEarningsRoth !== undefined && module8Data.addCareerRothIRATotalContributionRoth !== undefined && module8Data.addCareerRothIRABeginningBalanceRoth !== undefined) {
    module8Data.addCareerRothIRAEndingBalanceRoth = module8Data.addCareerRothIRATotalEarningsRoth + module8Data.addCareerRothIRATotalContributionRoth + module8Data.addCareerRothIRABeginningBalanceRoth;
  }
  return module8Data.addCareerRothIRAEndingBalanceRoth;
}
export function beginningBalanceInYearFourthRoth() {
  module17Data.beginningBalanceInYearFourthRoth = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth 401k Second'] !== undefined && investmentsData['Roth 401k Second'] !== '' && investmentsData['Roth 401k Second'][module17Data.start_year] !== undefined && investmentsData['Roth 401k Second'][module17Data.start_year] !== '') {
      module17Data.beginningBalanceInYearFourthRoth = investmentsData['Roth 401k Second'][module17Data.start_year]['Total Beginning Balance'];
    }
  }
  return module17Data.beginningBalanceInYearFourthRoth;
}

// furtherCareerPersonal401ContributionBaseIncomeCalcs = function() {
//   module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs = 0;
//   let firstEle = 0;
// let firstEle1 = 0;
// let firstEle2 = 0;
//   if( module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.base_income !== undefined && module17Data.base_income !== '' ){
//       firstEle1 = ( module17Data.jobtotalPreTaxExpected_val / 100 ) * module17Data.base_income;
//   }
// if( module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.base_roth_income !== undefined && module17Data.base_roth_income !== '' ){
//       firstEle2 = ( module17Data.jobtotalPreTaxExpected_val / 100 ) * module17Data.base_roth_income;
//   }
// firstEle = firstEle1 + firstEle2;

// if(module17Data.contribute_401k !== undefined && module17Data.contribute_401k == 'Yes' && module17Data.furtherCareer401PersonalContributionRoth !== undefined && module17Data.furtherCareer401PersonalContributionRoth !== '' && firstEle <= module17Data.furtherCareer401PersonalContributionRoth){
// module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs = firstEle1;
// } else{
//       let base_income = 0;
//       if(module17Data.base_income !== undefined && module17Data.base_income !== '') {
//           base_income = parseFloat(module17Data.base_income);
//       }
//       let base_roth_income = 0;
//       if(module17Data.base_roth_income !== undefined && module17Data.base_roth_income !== '') {
//           base_roth_income = parseFloat(module17Data.base_roth_income);
//       }

//       if( module17Data.furtherCareer401PersonalContributionRoth !== undefined && module17Data.furtherCareer401PersonalContributionRoth !== ''  ){
//           if(base_income > 0 || base_roth_income > 0) {
//               module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs = ( base_income / (base_income + base_roth_income) ) * module17Data.furtherCareer401PersonalContributionRoth  ;
//           } else {
//               module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs = 0;    
//           }
//       } else{
//           module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs = 0;    
//       }
// }
//   return module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs;
// }

export function furtherCareerPersonal401ContributionBaseIncomeCalcsRoth() {
  module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth = 0;
  let firstEle = 0;
  let firstEle1 = 0;
  let firstEle2 = 0;
  if (module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.base_income !== undefined && module17Data.base_income !== '') {
    firstEle1 = (module17Data.jobtotalPreTaxExpected_val / 100) * module17Data.base_income;
  }
  if (module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.base_roth_income !== undefined && module17Data.base_roth_income !== '') {
    firstEle2 = (module17Data.jobtotalPreTaxExpected_val / 100) * module17Data.base_roth_income;
  }
  firstEle = firstEle1 + firstEle2;

  if (module17Data.roth_contribute_401k !== undefined && module17Data.roth_contribute_401k === 'Yes' && module17Data.furtherCareer401PersonalContributionRoth !== undefined && module17Data.furtherCareer401PersonalContributionRoth !== '' && firstEle <= module17Data.furtherCareer401PersonalContributionRoth) {
    module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth = firstEle2;
  } else {
    let base_income = 0;
    if (module17Data.base_income !== undefined && module17Data.base_income !== '') {
      base_income = parseFloat(module17Data.base_income);
    }
    let base_roth_income = 0;
    if (module17Data.base_roth_income !== undefined && module17Data.base_roth_income !== '') {
      base_roth_income = parseFloat(module17Data.base_roth_income);
    }

    if (module17Data.furtherCareer401PersonalContributionRoth !== undefined && module17Data.furtherCareer401PersonalContributionRoth !== '') {
      if (base_income > 0 || base_roth_income > 0) {
        module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth = (base_roth_income / (base_income + base_roth_income)) * module17Data.furtherCareer401PersonalContributionRoth;
      } else {
        module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth = 0;
      }
    } else {
      module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth = 0;
    }
  }
  return module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth;
}

export function furtherCareer401PersonalContributionRoth() {
  module17Data.furtherCareer401PersonalContributionRoth = 0;
  if (module17Data.start_year !== undefined &&
    module17Data.start_year !== '' &&
    databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year] !== undefined &&
    databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year]['Max Personal Limit'] !== undefined) {
    module17Data.furtherCareer401PersonalContributionRoth = databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year]['Max Personal Limit'];
  }
  return module17Data.furtherCareer401PersonalContributionRoth;
}

export function further401kSummaryPersonalContributionRoth() {
  module17Data.further401kSummaryPersonalContributionRoth = 0;
  let personalContributionVal = 0;
  let data = investmentsData['Roth 401k Second'];
  let start_year = module17Data.start_year;
  let endYear = module17Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        personalContributionVal += data[year]['Personal Contribution'];
      }
    });
  }
  module17Data.further401kSummaryPersonalContributionRoth = personalContributionVal;

  return module17Data.further401kSummaryPersonalContributionRoth;
}

export function further401kSummaryEarningsOnPersonalContributionRoth() {
  module17Data.further401kSummaryEarningsOnPersonalContributionRoth = 0;
  let personalEarningsVal = 0;
  let data = investmentsData['Roth 401k Second'];
  let start_year = module17Data.start_year;
  let endYear = module17Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        personalEarningsVal += data[year]['Personal Earnings'];
      }
    });
  }
  module17Data.further401kSummaryEarningsOnPersonalContributionRoth = personalEarningsVal;

  return module17Data.further401kSummaryEarningsOnPersonalContributionRoth;
}


export function further401kSummaryTotalPersonalContributionsAndEarningsRoth() {
  module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth = 0;
  module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth = module17Data.further401kSummaryPersonalContributionRoth + module17Data.further401kSummaryEarningsOnPersonalContributionRoth;
  return module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth;
}

export function further401kSummaryEmployerContributionRoth() {
  module17Data.further401kSummaryEmployerContributionRoth = 0;
  let employerContributionVal = 0;
  let data = investmentsData['Roth 401k Second'];
  let start_year = module17Data.start_year;
  let endYear = module17Data.end_year;

  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        employerContributionVal += data[year]['Employer Contribution'];
      }
    });
  }
  module17Data.further401kSummaryEmployerContributionRoth = employerContributionVal;

  return module17Data.further401kSummaryEmployerContributionRoth;
}

export function furtherCareerRothIRABeginningBalanceRoth() {
  module17Data.furtherCareerRothIRABeginningBalanceRoth = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth IRA Second'] !== undefined && investmentsData['Roth IRA Second'] !== '' && investmentsData['Roth IRA Second'][module17Data.start_year] !== undefined && investmentsData['Roth IRA Second'][module17Data.start_year] !== '' && investmentsData['Roth IRA Second'][module17Data.start_year]['Beginning Balance'] !== undefined) {
    module17Data.furtherCareerRothIRABeginningBalanceRoth = investmentsData['Roth IRA Second'][module17Data.start_year]['Beginning Balance'];
  }
  return module17Data.furtherCareerRothIRABeginningBalanceRoth;
}

export function furtherCareerRothIRATotalContributionIra() {
  module17Data.furtherCareerRothIRATotalContributionIra = 0;
  module17Data.furtherCareerRothIRATotalContributionIra = module17Data.furtherCareerRothIRAPersonalContributionCalcs + module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth;
  return module17Data.furtherCareerRothIRATotalContributionIra;
}

export function furtherCareerHsaBeginningBalance() {
  module17Data.furtherCareerHsaBeginningBalance = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['HSA Second'] !== undefined && investmentsData['HSA Second'] !== '' && investmentsData['HSA Second'][module17Data.start_year] !== undefined && investmentsData['HSA Second'][module17Data.start_year] !== '' && investmentsData['HSA Second'][module17Data.start_year]['Beginning Balance'] !== undefined) {
    module17Data.furtherCareerHsaBeginningBalance = investmentsData['HSA Second'][module17Data.start_year]['Beginning Balance'];
  }
  return module17Data.furtherCareerHsaBeginningBalance;
}

export function maxHsaContributionByLawFurtherCareer() {
  module17Data.maxHsaContributionByLawFurtherCareer = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['HSA Limits'][module17Data.start_year] !== undefined) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && parseInt(module17Data.start_year) >= parseInt(module10Data.marriege_year)) {
      if (databaseData['HSA Limits'][module17Data.start_year]['Married'] !== undefined && databaseData['HSA Limits'][module17Data.start_year]['Married'] !== '') {
        module17Data.maxHsaContributionByLawFurtherCareer = databaseData['HSA Limits'][module17Data.start_year]['Married'];
      }
    } else {
      if (databaseData !== undefined && module17Data !== undefined && module17Data.start_year !== undefined && databaseData['HSA Limits'] !== undefined && databaseData['HSA Limits'][module17Data.start_year] !== undefined && databaseData['HSA Limits'][module17Data.start_year]['Single'] !== undefined && databaseData['HSA Limits'][module17Data.start_year]['Single'] !== '') {
        module17Data.maxHsaContributionByLawFurtherCareer = databaseData['HSA Limits'][module17Data.start_year]['Single'];
      }
    }
  }
  return module17Data.maxHsaContributionByLawFurtherCareer;
}

export function furtherCareerHsaBeginningBalanceSummary() {
  module17Data.furtherCareerHsaBeginningBalanceSummary = 0;
  if (module17Data.contribute_hsa_savings_account !== undefined && module17Data.contribute_hsa_savings_account === 'Yes' && module17Data.furtherCareerHsaBeginningBalance !== undefined && module17Data.furtherCareerHsaBeginningBalance !== '') {
    module17Data.furtherCareerHsaBeginningBalanceSummary = module17Data.furtherCareerHsaBeginningBalance;
  }
  return module17Data.furtherCareerHsaBeginningBalanceSummary;
}

export function furtherCareerHsaTotalContributionSummary() {
  module17Data.furtherCareerHsaTotalContributionSummary = 0;
  let totalContribution = 0;
  let data = investmentsData['HSA Second'];
  let start_year = module17Data.start_year;
  let endYear = module17Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalContribution += data[year]['Annual Contribution'];
      }
    });
  }
  module17Data.furtherCareerHsaTotalContributionSummary = totalContribution;
  return module17Data.furtherCareerHsaTotalContributionSummary;
}

export function furtherCareerHsaTotalEarningsSummary() {
  module17Data.furtherCareerHsaTotalEarningsSummary = 0;

  let totalEarningsVal = 0;
  let data = investmentsData['HSA Second'];
  let start_year = module17Data.start_year;
  let endYear = module17Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalEarningsVal += data[year]['Earnings'];
      }
    });
  }
  module17Data.furtherCareerHsaTotalEarningsSummary = totalEarningsVal;
  return module17Data.furtherCareerHsaTotalEarningsSummary;
}

// furtherCareerHsaTotalEndingBalanceSummary = function() {
//   module17Data.furtherCareerHsaTotalEndingBalanceSummary = 0;
// module17Data.furtherCareerHsaTotalEndingBalanceSummary = module17Data.furtherCareerHsaBeginningBalanceSummary + module17Data.furtherCareerHsaTotalContributionSummary + module17Data.furtherCareerHsaTotalEarningsSummary;
// return module17Data.furtherCareerHsaTotalEndingBalanceSummary;
// }

export function furtherCareerHsaTotalEndingBalanceSummary() {
  module17Data.furtherCareerHsaTotalEndingBalanceSummary = 0;
  module17Data.furtherCareerHsaTotalEndingBalanceSummary = module17Data.furtherCareerHsaBeginningBalanceSummary + module17Data.furtherCareerHsaTotalContributionSummary + module17Data.furtherCareerHsaTotalEarningsSummary;
  return module17Data.furtherCareerHsaTotalEndingBalanceSummary;
}

export function further401kSummaryTotalEmployerContributionsAndEarningsRoth() {
  module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth = 0;
  if (module17Data.Contribute401k !== undefined && module17Data.Contribute401k === 'Yes') {
    module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth = module17Data.further401kSummaryEmployerContributionRoth + module17Data.further401kSummaryEarningsOnEmployerContributionRoth;
  }
  return module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth;
}

export function furtherBalanceBeforeVestingReductionsRoth() {
  module17Data.furtherBalanceBeforeVestingReductionsRoth = 0;
  module17Data.furtherBalanceBeforeVestingReductionsRoth = module17Data.beginningBalanceInYearFourthRoth + module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth + module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth;
  return module17Data.furtherBalanceBeforeVestingReductionsRoth;
}


export function furtherCareer401kEndingBalanceRoth() {
  module17Data.furtherCareer401kEndingBalanceRoth = 0;
  module17Data.furtherCareer401kEndingBalanceRoth = module17Data.furtherBalanceBeforeVestingReductionsRoth + module17Data.employerContributionsAndEarningsNotVestedFurRoth;
  return module17Data.furtherCareer401kEndingBalanceRoth;
}

export function employerContributionsAndEarningsNotVestedFurRoth() {
  module17Data.employerContributionsAndEarningsNotVestedFurRoth = 0;
  if (module17Data.Contribute401kRoth !== undefined && module17Data.Contribute401kRoth === 'Yes' && module17Data.yearsDiff !== undefined) {
    module17Data.employerContributionsAndEarningsNotVestedFurRoth = (-(1 - (module17Data.vestedAmountMove / 100)) * (module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth / 100)) * 100;
  }
  return module17Data.employerContributionsAndEarningsNotVestedFurRoth;
}


export function furtherCareerRothIRATotalContributionRoth() {
  module17Data.furtherCareerRothIRATotalContributionRoth = 0;
  let totalContributionsVal = 0;
  let data = investmentsData['Roth IRA Second'];
  let start_year = module17Data.start_year;
  let endYear = module17Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalContributionsVal += data[year]['Annual Contribution'];
      }
    });
  }
  module17Data.furtherCareerRothIRATotalContributionRoth = totalContributionsVal;

  return module17Data.furtherCareerRothIRATotalContributionRoth;
}

export function furtherCareerRothIRATotalEarningsRoth() {
  module17Data.furtherCareerRothIRATotalEarningsRoth = 0;

  var totalEarningsVal = 0;
  var data = investmentsData['Roth IRA Second'];
  var start_year = module17Data.start_year;
  var endYear = module17Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        totalEarningsVal += data[year]['Earnings'];
      }
    });
  }
  module17Data.furtherCareerRothIRATotalEarningsRoth = totalEarningsVal;

  return module17Data.furtherCareerRothIRATotalEarningsRoth;
}

export function furtherCareerRothIRAEndingBalanceRoth() {
  module17Data.furtherCareerRothIRAEndingBalanceRoth = 0;
  if (module17Data.furtherCareerRothIRATotalEarningsRoth !== undefined && module17Data.furtherCareerRothIRATotalContributionRoth !== undefined && module17Data.furtherCareerRothIRABeginningBalanceRoth !== undefined) {
    module17Data.furtherCareerRothIRAEndingBalanceRoth = module17Data.furtherCareerRothIRATotalEarningsRoth + module17Data.furtherCareerRothIRATotalContributionRoth + module17Data.furtherCareerRothIRABeginningBalanceRoth;
  }
  return module17Data.furtherCareerRothIRAEndingBalanceRoth;
}
// end methods for FCA (Akhil)


// Personal Contribution as a % of Base Income Calcs
export function furtherCareerPersonal401ContributionBaseIncomeCalcs() {
  module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs = 0;
  if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.base_income !== undefined && module17Data.base_income !== '') {
    module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs = (module17Data.jobtotalPreTaxExpected_val / 100) * module17Data.base_income;
  }
  return module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs;
}

// Employer Matching Rate on Personal Contribution
export function furtherCareer401EmployerMatchingrateCalcs() {
  module17Data.furtherCareer401EmployerMatchingrateCalcs = 0;

  if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.furtherCareer401PersonalContribution !== undefined && module17Data.furtherCareer401PersonalContribution !== '' && module17Data.employer_matching_rate !== undefined && module17Data.employer_matching_rate !== '') {
    module17Data.furtherCareer401EmployerMatchingrateCalcs = (module17Data.furtherCareer401PersonalContribution / 100) * module17Data.employer_matching_rate;
  }
  return module17Data.furtherCareer401EmployerMatchingrateCalcs;
}

// Employer Matching Limit as a % of Base Income
export function furtherCareer401EmployerMatchingLimitCalcs() {
  module17Data.furtherCareer401EmployerMatchingLimitCalcs = 0;
  if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.employer_matching_limit !== undefined && module17Data.employer_matching_limit !== '') {
    module17Data.furtherCareer401EmployerMatchingLimitCalcs = (module17Data.jobtotalPreTaxExpected_val / 100) * module17Data.employer_matching_limit;
  }
  return module17Data.furtherCareer401EmployerMatchingLimitCalcs;
}

export function furtherCareer401PersonalContribution401k() {
  module17Data.furtherCareer401PersonalContribution_401k = 0;
  if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year]['Max Personal Limit'] !== undefined) {
    module17Data.furtherCareer401PersonalContribution_401k = databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year]['Max Personal Limit'];
  } else {
    module17Data.furtherCareer401PersonalContribution_401k = 0;
  }
  return module17Data.furtherCareer401PersonalContribution_401k;
}
// Personal Contribution (Max of $00 by law)
export function furtherCareer401PersonalContribution() {
  module17Data.furtherCareer401PersonalContribution = 0;
  module17Data.furtherCareer401PersonalContribution = module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs + module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth;
  return module17Data.furtherCareer401PersonalContribution;
}

// Employer Contribution
export function furtherCareer401EmployerContribution() {
  module17Data.furtherCareer401EmployerContribution = 0;
  let employerContribution1 = 0;
  let employerContribution2 = 0;
  let employerContribution3 = 0;

  if (module17Data.furtherCareer401EmployerMatchingrateCalcs !== undefined && module17Data.furtherCareer401EmployerMatchingrateCalcs !== ''
    && module17Data.furtherCareer401EmployerMatchingLimitCalcs !== undefined && module17Data.furtherCareer401EmployerMatchingLimitCalcs !== ''
    && module17Data.furtherCareer401EmployerMatchingrateCalcs > module17Data.furtherCareer401EmployerMatchingLimitCalcs) {
    employerContribution1 = module17Data.furtherCareer401EmployerMatchingLimitCalcs;
  } else {
    employerContribution1 = module17Data.furtherCareer401EmployerMatchingrateCalcs;
  }
  if (module17Data.furtherCareer401PersonalContribution !== undefined && module17Data.furtherCareer401PersonalContribution !== '') {
    employerContribution2 = module17Data.furtherCareer401PersonalContribution;
  }
  employerContribution3 = employerContribution1 + employerContribution2;

  if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year] !== undefined
    && databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year]['Total Limit'] !== undefined
    && employerContribution3 > databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year]['Total Limit']) {
    module17Data.furtherCareer401EmployerContribution = databaseData['401(k) 2018 Contribution Limits'][module17Data.start_year]['Total Limit'] - employerContribution2;
  } else if (module17Data.furtherCareer401EmployerMatchingrateCalcs !== undefined && module17Data.furtherCareer401EmployerMatchingrateCalcs !== ''
    && module17Data.furtherCareer401EmployerMatchingLimitCalcs !== undefined && module17Data.furtherCareer401EmployerMatchingLimitCalcs !== ''
    && module17Data.furtherCareer401EmployerMatchingrateCalcs > module17Data.furtherCareer401EmployerMatchingLimitCalcs) {
    module17Data.furtherCareer401EmployerContribution = module17Data.furtherCareer401EmployerMatchingLimitCalcs;
  } else {
    module17Data.furtherCareer401EmployerContribution = module17Data.furtherCareer401EmployerMatchingrateCalcs;
  }
  return module17Data.furtherCareer401EmployerContribution;
}

// Total Annual Contribution Value
export function furtherCareer401TotalAnnualContribution() {
  module17Data.furtherCareer401TotalAnnualContribution = 0;

  if (module17Data.furtherCareer401PersonalContribution !== undefined
    && module17Data.furtherCareer401EmployerContribution !== undefined) {
    module17Data.furtherCareer401TotalAnnualContribution = module17Data.furtherCareer401PersonalContribution + module17Data.furtherCareer401EmployerContribution;
  }
  return module17Data.furtherCareer401TotalAnnualContribution;
}

export function further401kSummaryPersonalContribution() {
  module17Data.further401kSummaryPersonalContribution = 0;
  let personalContributionVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalContributionVal += data[year]['Personal Contribution'];
        }
      });
    }
  }
  module17Data.further401kSummaryPersonalContribution = personalContributionVal;

  return module17Data.further401kSummaryPersonalContribution;
}

// Earnings on Personal Contributions After 1 Years
export function further401kSummaryEarningsOnPersonalContribution() {
  module17Data.further401kSummaryEarningsOnPersonalContribution = 0;
  let personalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          personalEarningsVal += data[year]['Personal Earnings'];
        }
      });
    }
  }
  module17Data.further401kSummaryEarningsOnPersonalContribution = personalEarningsVal;

  return module17Data.further401kSummaryEarningsOnPersonalContribution;
}

// Earnings on Personal Contributions After 2 Years
export function further401kSummaryTotalPersonalContributionsAndEarnings() {
  module17Data.further401kSummaryTotalPersonalContributionsAndEarnings = 0;
  if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes') {
    module17Data.further401kSummaryTotalPersonalContributionsAndEarnings = module17Data.further401kSummaryPersonalContribution + module17Data.further401kSummaryEarningsOnPersonalContribution;
  }
  return module17Data.further401kSummaryTotalPersonalContributionsAndEarnings;
}

// Employer Contributions After 2 Years
export function further401kSummaryEmployerContribution() {
  module17Data.further401kSummaryEmployerContribution = 0;
  let employerContributionVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerContributionVal += data[year]['Employer Contribution'];
        }
      });
    }
  }
  module17Data.further401kSummaryEmployerContribution = employerContributionVal;

  return module17Data.further401kSummaryEmployerContribution;
}

// Earnings on Employer Contributions After 2 Years
export function further401kSummaryEarningsOnEmployerContribution() {
  module17Data.further401kSummaryEarningsOnEmployerContribution = 0;
  let employerEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['401k Second']) {
    const data = investmentsData['401k Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          employerEarningsVal += data[year]['Employer Earnings'];
        }
      });
    }
  }

  module17Data.further401kSummaryEarningsOnEmployerContribution = employerEarningsVal;

  return module17Data.further401kSummaryEarningsOnEmployerContribution;
}

export function further401kSummaryEarningsOnEmployerContributionRoth() {
  module17Data.further401kSummaryEarningsOnEmployerContributionRoth = 0;
  let employerEarningsVal = 0;
  let data = investmentsData['Roth 401k Second'];
  let start_year = module17Data.start_year;
  let endYear = module17Data.end_year;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        employerEarningsVal += data[year]['Employer Earnings'];
      }
    });
  }
  module17Data.further401kSummaryEarningsOnEmployerContributionRoth = employerEarningsVal;

  return module17Data.further401kSummaryEarningsOnEmployerContributionRoth;
}

/* Total Employer Earnings and contributations */
export function further401kSummaryTotalEmployerContributionsAndEarnings() {
  module17Data.further401kSummaryTotalEmployerContributionsAndEarnings = 0;
  if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes') {
    module17Data.further401kSummaryTotalEmployerContributionsAndEarnings = module17Data.further401kSummaryEmployerContribution + module17Data.further401kSummaryEarningsOnEmployerContribution;
  }
  return module17Data.further401kSummaryTotalEmployerContributionsAndEarnings;
}


// 401(k) Balance before Vesting Reductions
export function furtherBalanceBeforeVestingReductions() {
  module17Data.furtherBalanceBeforeVestingReductions = 0;
  module17Data.furtherBalanceBeforeVestingReductions = module17Data.beginningBalanceInYearFourth + module17Data.further401kSummaryTotalPersonalContributionsAndEarnings + module17Data.further401kSummaryTotalEmployerContributionsAndEarnings;
  return module17Data.furtherBalanceBeforeVestingReductions;
}

// % Vested after 2 years at 25%/yr Schedule
export function vestedAfterFewYearsScheduleFurther() {
  module17Data.vestedAfterFewYearsScheduleFurther = 0;
  if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined) {
    if (databaseData !== undefined && databaseData !== '') {
      module17Data.vestedAfterFewYearsScheduleFurther = databaseData.Data['401(k) Vesting Schedule'][(parseInt(module17Data.end_year, 10) - parseInt(module17Data.start_year, 10)) + 1];
    }
  }
  return module17Data.vestedAfterFewYearsScheduleFurther;
}

// % of Employer Contributions & Earnings Not Vested
export function employerContributionsAndEarningsNotVestedFur() {
  module17Data.employerContributionsAndEarningsNotVestedFur = 0;
  if (module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes') {
    module17Data.employerContributionsAndEarningsNotVestedFur = (-(1 - (module17Data.vestedAfterFewYearsScheduleFurther / 100)) * (module17Data.further401kSummaryTotalEmployerContributionsAndEarnings / 100)) * 100;
  }
  return module17Data.employerContributionsAndEarningsNotVestedFur;
}

// Ending 401(k) Balance in Year 2026
export function furtherCareer401kEndingBalance() {
  module17Data.furtherCareer401kEndingBalance = 0;
  module17Data.furtherCareer401kEndingBalance = module17Data.furtherBalanceBeforeVestingReductions + module17Data.employerContributionsAndEarningsNotVestedFur;
  return module17Data.furtherCareer401kEndingBalance;
}

// Roth IRA Retirement Beginning Balance
export function furtherCareerRothIRABeginningBalance() {
  module17Data.furtherCareerRothIRABeginningBalance = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth IRA Second'] !== undefined
    && investmentsData['Roth IRA Second'] !== '' && investmentsData['Roth IRA Second'][module17Data.start_year] !== undefined && investmentsData['Roth IRA Second'][module17Data.start_year] !== '' && investmentsData['Roth IRA Second'][module17Data.start_year]['Beginning Balance'] !== undefined) {
    module17Data.furtherCareerRothIRABeginningBalance = investmentsData['Roth IRA Second'][module17Data.start_year]['Beginning Balance'];
  }
  return module17Data.furtherCareerRothIRABeginningBalance;
}

// Roth IRA Retirement Personal Contribution as Base Income
export function furtherCareerRothIRAPersonalContributionCalcs() {
  module17Data.furtherCareerRothIRAPersonalContributionCalcs = 0;
  if (module17Data.roth_contribute !== undefined && module17Data.roth_contribute === 'Yes' && module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.roth_personal_contribution !== undefined && module17Data.roth_personal_contribution !== '') {
    module17Data.furtherCareerRothIRAPersonalContributionCalcs = (module17Data.jobtotalPreTaxExpected_val / 100) * module17Data.roth_personal_contribution;
  }
  return module17Data.furtherCareerRothIRAPersonalContributionCalcs;
}

export function furtherCareerRothIRAPersonalContributionCalcsRoth() {
  module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth = 0;
  var firstEle = 0;
  var firstEle1 = 0;
  var firstEle2 = 0;
  if (module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.roth_personal_contribution !== undefined && module17Data.roth_personal_contribution !== '') {
    firstEle1 = (module17Data.jobtotalPreTaxExpected_val / 100) * module17Data.roth_personal_contribution;
  }
  if (module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.ira_roth_personal_contribution !== undefined && module17Data.ira_roth_personal_contribution !== '') {
    firstEle2 = (module17Data.jobtotalPreTaxExpected_val / 100) * module17Data.ira_roth_personal_contribution;
  }
  firstEle = firstEle1 + firstEle2;
  if (module17Data.rothContributeRoth !== undefined && module17Data.rothContributeRoth === 'Yes' && module17Data.furtherCareerRothIRAPersonalContribution !== undefined && module17Data.furtherCareerRothIRAPersonalContribution !== '' && firstEle <= module17Data.furtherCareerRothIRAPersonalContribution) {
    module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth = firstEle2;
  } else {
    var roth_personal_contribution = 0;
    if (module17Data.roth_personal_contribution !== undefined && module17Data.roth_personal_contribution !== '') {
      roth_personal_contribution = parseFloat(module17Data.roth_personal_contribution);
    }
    var ira_roth_personal_contribution = 0;
    if (module17Data.ira_roth_personal_contribution !== undefined && module17Data.ira_roth_personal_contribution !== '') {
      ira_roth_personal_contribution = parseFloat(module17Data.ira_roth_personal_contribution);
    }

    if (module17Data.furtherCareerRothIRAPersonalContribution !== undefined && module17Data.furtherCareerRothIRAPersonalContribution !== '') {
      if (roth_personal_contribution > 0 || ira_roth_personal_contribution > 0) {
        module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth = (ira_roth_personal_contribution / (roth_personal_contribution + ira_roth_personal_contribution)) * module17Data.furtherCareerRothIRAPersonalContribution;
      } else {
        module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth = 0;
      }
    } else {
      module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth = 0;
    }
  }
  return module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth;
}

export function furtherCareerRothIRAPersonalContributionrothIra() {
  module17Data.furtherCareerRothIRAPersonalContribution_rothIra = 0;
  if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year] !== undefined
    && databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year]['Max Personal Limit'] !== undefined) {
    module17Data.furtherCareerRothIRAPersonalContribution_rothIra = databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year]['Max Personal Limit'];
  } else {
    module17Data.furtherCareerRothIRAPersonalContribution_rothIra = 0;
  }
  return module17Data.furtherCareerRothIRAPersonalContribution_rothIra;
}

// Roth IRA Retirement Personal Contribution
export function furtherCareerRothIRAPersonalContribution() {
  module17Data.furtherCareerRothIRAPersonalContribution = 0;
  if (module17Data.furtherCareerRothIRAPersonalContributionCalcs !== undefined
    && module17Data.furtherCareerRothIRAPersonalContributionCalcs !== ''
    && module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year] !== undefined
    && databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year]['Max Personal Limit'] !== undefined) {
    module17Data.furtherCareerRothIRAPersonalContribution = databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year]['Max Personal Limit'];
    if (module17Data.furtherCareerRothIRAPersonalContributionCalcs > databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year]['Max Personal Limit']) {
      module17Data.furtherCareerRothIRAPersonalContribution = databaseData['Roth IRA 2018 Contribution Limit'][module17Data.start_year]['Max Personal Limit'];
    } else {
      module17Data.furtherCareerRothIRAPersonalContribution = module17Data.furtherCareerRothIRAPersonalContributionCalcs;
    }
  }
  return module17Data.furtherCareerRothIRAPersonalContribution;
}

// Roth IRA Retirement Total Contribution
export function furtherCareerRothIRATotalContribution() {
  module17Data.furtherCareerRothIRATotalContribution = 0;
  let totalContributionsVal = 0;
  if (investmentsData !== undefined && investmentsData['Roth IRA Second']) {
    const data = investmentsData['Roth IRA Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContributionsVal += data[year]['Annual Contribution'];
        }
      });
    }
  }
  module17Data.furtherCareerRothIRATotalContribution = totalContributionsVal;

  return module17Data.furtherCareerRothIRATotalContribution;
}

// Roth IRA Retirement Total Earnings
export function furtherCareerRothIRATotalEarnings() {
  module17Data.furtherCareerRothIRATotalEarnings = 0;

  let totalEarningsVal = 0;
  if (investmentsData !== undefined && investmentsData['Roth IRA Second']) {
    const data = investmentsData['Roth IRA Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarningsVal += data[year].Earnings;
        }
      });
    }
  }

  module17Data.furtherCareerRothIRATotalEarnings = totalEarningsVal;

  return module17Data.furtherCareerRothIRATotalEarnings;
}

// Roth IRA Retirement Total Balance
export function furtherCareerRothIRAEndingBalance() {
  module17Data.furtherCareerRothIRAEndingBalance = 0;
  if (module17Data.furtherCareerRothIRATotalEarnings !== undefined && module17Data.furtherCareerRothIRATotalContribution !== undefined && module17Data.furtherCareerRothIRABeginningBalance !== undefined) {
    module17Data.furtherCareerRothIRAEndingBalance = module17Data.furtherCareerRothIRATotalEarnings + module17Data.furtherCareerRothIRATotalContribution + module17Data.furtherCareerRothIRABeginningBalance;
  }
  return module17Data.furtherCareerRothIRAEndingBalance;
}

/* SEP IRA */

// Beginning Balance
export function furtherCareerSEPIRABeginningBalance() {
  module17Data.furtherCareerSEPIRABeginningBalance = 0;
  if (module17Data.start_year !== undefined && investmentsData !== undefined && investmentsData !== '' && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'] !== '' && investmentsData['Seph IRA Second'][module17Data.start_year] !== undefined && investmentsData['Seph IRA Second'][module17Data.start_year] !== '' && investmentsData['Seph IRA Second'][module17Data.start_year]['Beginning Balance'] !== undefined) {
    module17Data.furtherCareerSEPIRABeginningBalance = investmentsData['Seph IRA Second'][module17Data.start_year]['Beginning Balance'];
  }
  return module17Data.furtherCareerSEPIRABeginningBalance;
}

// SEP IRA Retirement Personal Contribution as Base Income
export function furtherCareerSEPIRAPersonalContributionCalcs() {
  module17Data.furtherCareerSEPIRAPersonalContributionCalcs = 0;
  if (module17Data.ira_contribute !== undefined && module17Data.ira_contribute === 'Yes') {
    if (module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && databaseData !== undefined && databaseData !== '' && databaseData.Data !== undefined && module4Data.ira_personal_contribution !== undefined && module4Data.ira_personal_contribution !== '') {
      if (module17Data.jobtotalPreTaxExpected_val > databaseData.Data['SEP IRA Limits Second'] && databaseData.Data['SEP IRA Limits Rate'] === module17Data.ira_personal_contribution) {
        module17Data.furtherCareerSEPIRAPersonalContributionCalcs = databaseData.Data['SEP IRA Limits Rate'] * ((databaseData.Data['SEP IRA Limits Second']) / 100);
      } else {
        module17Data.furtherCareerSEPIRAPersonalContributionCalcs = ((module17Data.jobtotalPreTaxExpected_val) / 100) * (module17Data.ira_personal_contribution);
      }
    }
  } else {
    module17Data.furtherCareerSEPIRAPersonalContributionCalcs = 0;
  }
  return module17Data.furtherCareerSEPIRAPersonalContributionCalcs;
}

// Personal Contribution (Max of the Lesser of 0% of Income or $00 by law)
export function furtherCareerSEPIRAPersonalContributionsepIra() {
  module17Data.furtherCareerSEPIRAPersonalContribution_sepIra = 0;
  if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][module17Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module17Data.start_year]['Max Personal Limit'] !== undefined) {
    module17Data.furtherCareerSEPIRAPersonalContribution_sepIra = databaseData['SEP IRA Contribution Limit'][module17Data.start_year]['Max Personal Limit'];
  } else {
    module17Data.furtherCareerSEPIRAPersonalContribution_sepIra = 0;
  }
  return module17Data.furtherCareerSEPIRAPersonalContribution_sepIra;
}

export function furtherCareerSEPIRAPersonalContribution() {
  module17Data.furtherCareerSEPIRAPersonalContribution = 0;

  let sepPersonalContribution1 = 0;
  let sepPersonalContribution2 = 0;
  let sepPersonalContribution3 = 0;
  let sepPersonalContribution = 0;

  if (module17Data.jobtotalPreTaxExpected_val !== undefined
    && module17Data.jobtotalPreTaxExpected_val !== ''
    && module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][module17Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module17Data.start_year]['Total Limit'] !== undefined) {
    if (module17Data.jobtotalPreTaxExpected_val > databaseData['SEP IRA Contribution Limit'][module17Data.start_year]['Total Limit']) {
      sepPersonalContribution1 = (databaseData['SEP IRA Contribution Limit'][module17Data.start_year]['Total Limit'] / 100) * databaseData['SEP IRA Contribution Limit'][module17Data.start_year].Return;
    } else {
      sepPersonalContribution1 = (module17Data.jobtotalPreTaxExpected_val / 100) * databaseData['SEP IRA Contribution Limit'][module17Data.start_year].Return;
    }
  } else {
    sepPersonalContribution1 = 0;
  }

  if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['SEP IRA Contribution Limit'][module17Data.start_year] !== undefined
    && databaseData['SEP IRA Contribution Limit'][module17Data.start_year]['Max Personal Limit'] !== undefined) {
    sepPersonalContribution2 = databaseData['SEP IRA Contribution Limit'][module17Data.start_year]['Max Personal Limit'];
  } else {
    sepPersonalContribution2 = 0;
  }

  if (sepPersonalContribution1 > sepPersonalContribution2) {
    sepPersonalContribution3 = sepPersonalContribution2;
  } else {
    sepPersonalContribution3 = sepPersonalContribution1;
  }

  if (module17Data.furtherCareerSEPIRAPersonalContributionCalcs !== undefined && module17Data.furtherCareerSEPIRAPersonalContributionCalcs !== '') {
    if (module17Data.furtherCareerSEPIRAPersonalContributionCalcs > sepPersonalContribution3) {
      sepPersonalContribution = sepPersonalContribution3;
    } else {
      sepPersonalContribution = module17Data.furtherCareerSEPIRAPersonalContributionCalcs;
    }
  } else {
    sepPersonalContribution = 0;
  }
  module17Data.furtherCareerSEPIRAPersonalContribution = sepPersonalContribution;
  return module17Data.furtherCareerSEPIRAPersonalContribution;
}

/* SEP IRA Retirement Summary */
// Total Contributions
export function furtherCareerSEPIRATotalContribution() {
  module17Data.furtherCareerSEPIRATotalContribution = 0;
  let totalContribution = 0;
  if (investmentsData !== undefined && investmentsData['Seph IRA Second']) {
    const data = investmentsData['Seph IRA Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalContribution += data[year]['Annual Contribution'];
        }
      });
    }
  }
  module17Data.furtherCareerSEPIRATotalContribution = totalContribution;
  return module17Data.furtherCareerSEPIRATotalContribution;
}

// Total Earnings
export function furtherCareerSEPIRATotalEarnings() {
  module17Data.furtherCareerSEPIRATotalEarnings = 0;
  let totalEarnings = 0;
  if (investmentsData !== undefined && investmentsData['Seph IRA Second']) {
    const data = investmentsData['Seph IRA Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEarnings += data[year].Earnings;
        }
      });
    }
  }
  module17Data.furtherCareerSEPIRATotalEarnings = totalEarnings;
  return module17Data.furtherCareerSEPIRATotalEarnings;
}

// Ending Sep IRA BALANCE
export function furtherCareerEndingSEPIRABalance() {
  module17Data.furtherCareerEndingSEPIRABalance = 0;
  if (module17Data.furtherCareerSEPIRATotalEarnings !== undefined && module17Data.furtherCareerSEPIRATotalContribution !== undefined && module17Data.furtherCareerSEPIRABeginningBalance !== undefined) {
    module17Data.furtherCareerEndingSEPIRABalance = module17Data.furtherCareerSEPIRATotalEarnings + module17Data.furtherCareerSEPIRATotalContribution + module17Data.furtherCareerSEPIRABeginningBalance;
  }
  return module17Data.furtherCareerEndingSEPIRABalance;
}

/* Pension */
// Beginning Balance
export function furtherCareerPensionBeginningBalance() {
  module17Data.furtherCareerPensionBeginningBalance = 0;
  if (module17Data.start_year !== undefined && investmentsData !== undefined && investmentsData !== '' && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'] !== '' && investmentsData['Pension Second'][module17Data.start_year] !== undefined && investmentsData['Pension Second'][module17Data.start_year] !== '' && investmentsData['Pension Second'][module17Data.start_year]['Beginning Balance'] !== undefined) {
    module17Data.furtherCareerPensionBeginningBalance = investmentsData['Pension Second'][module17Data.start_year]['Beginning Balance'];
  }
  return module17Data.furtherCareerPensionBeginningBalance;
}

// Employer Contribution Calculations
export function furtherCareerPensionEmployerContributionCalc() {
  module17Data.furtherCareerPensionEmployerContributionCalc = 0;
  if (module17Data.pension_contribute !== undefined && module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.pension_employer_contribution !== undefined && module17Data.pension_employer_contribution !== '') {
    if (module17Data.pension_contribute === 'Yes') {
      module17Data.furtherCareerPensionEmployerContributionCalc = ((module17Data.jobtotalPreTaxExpected_val) / 100) * module17Data.pension_employer_contribution;
    }
  }
  return module17Data.furtherCareerPensionEmployerContributionCalc;
}

// Personal Contribution Calculations
export function furtherCareerPensionPersonalContributionCalc() {
  module17Data.furtherCareerPensionPersonalContributionCalc = 0;
  if (module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '' && module17Data.pension_personal_contribution !== undefined && module17Data.pension_contribute !== undefined && module17Data.pension_contribute !== '') {
    if (module17Data.pension_contribute === 'Yes') {
      module17Data.furtherCareerPensionPersonalContributionCalc = ((module17Data.jobtotalPreTaxExpected_val) / 100) * module17Data.pension_personal_contribution;
    }
  }
  return module17Data.furtherCareerPensionPersonalContributionCalc;
}

// Employer Contribution
export function furtherCareerPensionEmployerContribution() {
  module17Data.furtherCareerPensionEmployerContribution = 0;
  if (module17Data.furtherCareerPensionPersonalContributionCalc !== undefined
    && module17Data.furtherCareerPensionPersonalContributionCalc !== ''
    && module17Data.furtherCareerPensionEmployerContributionCalc !== undefined
    && module17Data.furtherCareerPensionEmployerContributionCalc !== ''
    && module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Pension 2018 Contribution Limit'][module17Data.start_year] !== undefined
    && databaseData['Pension 2018 Contribution Limit'][module17Data.start_year]['Total Limit'] !== undefined) {
    if ((module17Data.furtherCareerPensionPersonalContributionCalc + module17Data.furtherCareerPensionEmployerContributionCalc) > databaseData['Pension 2018 Contribution Limit'][module17Data.start_year]['Total Limit']) {
      module17Data.furtherCareerPensionEmployerContribution = databaseData['Pension 2018 Contribution Limit'][module17Data.start_year]['Total Limit'] - module17Data.furtherCareerPensionEmployerContributionCalc;
    } else {
      module17Data.furtherCareerPensionEmployerContribution = module17Data.furtherCareerPensionPersonalContributionCalc;
    }
  }

  return module17Data.furtherCareerPensionEmployerContribution;
}

// Total Annual Contribution
// Personal Contribution (Max of the Lesser of 0% of Income or $00 by law)
export function furtherCareerPensionTotalContributionpension() {
  module17Data.furtherCareerPensionTotalContribution_pension = 0;
  if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && databaseData !== undefined
    && databaseData !== ''
    && databaseData['Pension 2018 Contribution Limit'][module17Data.start_year] !== undefined
    && databaseData['Pension 2018 Contribution Limit'][module17Data.start_year]['Total Limit'] !== undefined) {
    module17Data.furtherCareerPensionTotalContribution_pension = databaseData['Pension 2018 Contribution Limit'][module17Data.start_year]['Total Limit'];
  } else {
    module17Data.furtherCareerPensionTotalContribution_pension = 0;
  }
  return module17Data.furtherCareerPensionTotalContribution_pension;
}

export function furtherCareerPensionTotalContribution() {
  module17Data.furtherCareerPensionTotalContribution = 0;
  if (module17Data.furtherCareerPensionPersonalContributionCalc !== undefined && module17Data.furtherCareerPensionEmployerContributionCalc !== undefined) {
    module17Data.furtherCareerPensionTotalContribution = module17Data.furtherCareerPensionPersonalContributionCalc + module17Data.furtherCareerPensionEmployerContributionCalc;
  }

  return module17Data.furtherCareerPensionTotalContribution;
}

// Total Annual Employer Contribution
export function furtherCareerPensionTotalEmployerContributions() {
  module17Data.furtherCareerPensionTotalEmployerContributions = 0;
  let totalEmployerContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalEmployerContribution += data[year]['Employer Contribution'];
        }
      });
    }
  }
  module17Data.furtherCareerPensionTotalEmployerContributions = totalEmployerContribution;

  return module17Data.furtherCareerPensionTotalEmployerContributions;
}

// Total Annual Personal Contribution
export function furtherCareerPensionTotalPersonalContributions() {
  module17Data.furtherCareerPensionTotalPersonalContributions = 0;
  let totalPersonalContribution = 0;
  if (investmentsData !== undefined && investmentsData['Retirement Accounts Pension']) {
    const data = investmentsData['Retirement Accounts Pension'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          totalPersonalContribution += data[year]['Personal Contribution'];
        }
      });
    }
  }

  module17Data.furtherCareerPensionTotalPersonalContributions = totalPersonalContribution;

  return module17Data.furtherCareerPensionTotalPersonalContributions;
}

// Total Annual Personal Contribution
export function furtherCareerPensionTotalEarnings() {
  module17Data.furtherCareerPensionTotalEarnings = 0;
  let totalEanings = 0;
  if (investmentsData !== undefined && investmentsData['Pension Second']) {
    const data = investmentsData['Pension Second'];
    const start_year = module17Data.start_year;
    const endYear = module17Data.end_year;
    if (data !== undefined && data !== '') {
      if (Object.keys(data).length !== 0) {
        Object.keys(data).forEach((year) => {
          if (year >= start_year && year <= endYear) {
            totalEanings += data[year].Earnings;
          }
        });
      }
    } else {
      totalEanings = 0;
    }
  }
  module17Data.furtherCareerPensionTotalEarnings = totalEanings;

  return module17Data.furtherCareerPensionTotalEarnings;
}

export function furtherCareerEndingPensionBalance() {
  module17Data.furtherCareerEndingPensionBalance = 0;
  if (module17Data.furtherCareerPensionTotalEarnings !== undefined
    && module17Data.furtherCareerPensionTotalPersonalContributions !== undefined
    && module17Data.furtherCareerPensionTotalEmployerContributions !== undefined
    && module17Data.furtherCareerPensionBeginningBalance !== undefined) {
    module17Data.furtherCareerEndingPensionBalance = module17Data.furtherCareerPensionBeginningBalance + module17Data.furtherCareerPensionTotalEmployerContributions + module17Data.furtherCareerPensionTotalPersonalContributions + module17Data.furtherCareerPensionTotalEarnings;
  }
  return module17Data.furtherCareerEndingPensionBalance;
}

// Further Career  Rent Multiplier
export function furtherCareerRentMultiplier() {
  module17Data.furtherCareerRentMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerRentMultiplier = module10Data.rent_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerRentMultiplier = module10Data.rent_multiplier;
    } else {
      module17Data.furtherCareerRentMultiplier = '';
    }
  }
  return module17Data.furtherCareerRentMultiplier;
}

// Rent Expenses
export function furtherCareerRentExpenses() {
  let rentExpenses = 0;
  module17Data.furtherCareerRentExpenses = 0;
  if (module17Data.furtherCareerRentMultiplier !== undefined
    && module17Data.furtherCareerRentMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.rent !== undefined
      && module17Data.rent !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses.Rent) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.rent !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.rent > 0) {
        rentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Rent) / 12);
      } else {
        rentExpenses = 0;
      }
    }
  } else {
    rentExpenses = '';
  }
  module17Data.furtherCareerRentExpenses = rentExpenses;

  return module17Data.furtherCareerRentExpenses;
}

// Further Career  Utilities Multiplier
export function furtherCareerUtilitiesMultiplier() {
  module17Data.furtherCareerUtilitiesMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerUtilitiesMultiplier = module10Data.utilities_multiplier;
    } else {
      module17Data.furtherCareerUtilitiesMultiplier = '';
    }
  }
  return module17Data.furtherCareerUtilitiesMultiplier;
}

// Utilities Expenses
export function furtherCareerUtilitiesExpenses() {
  let utilitiesExpense = 0;
  module17Data.furtherCareerUtilitiesExpenses = 0;
  if (module17Data.furtherCareerUtilitiesMultiplier !== undefined
    && module17Data.furtherCareerUtilitiesMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.utilities !== undefined
      && module17Data.utilities !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module17Data.start_year].livingExpenses.Utilities) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.utilities !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.utilities > 0) {
        utilitiesExpense = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities) / 12);
      } else {
        utilitiesExpense = 0;
      }
    }
  } else {
    utilitiesExpense = '';
  }
  module17Data.furtherCareerUtilitiesExpenses = utilitiesExpense;

  return module17Data.furtherCareerUtilitiesExpenses;
}

// Further Career  Food Multiplier
export function furtherCareerFoodMultiplier() {
  module17Data.furtherCareerFoodMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerFoodMultiplier = module10Data.food_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerFoodMultiplier = module10Data.food_multiplier;
    } else {
      module17Data.furtherCareerFoodMultiplier = '';
    }
  }
  return module17Data.furtherCareerFoodMultiplier;
}

// Food Expenses
export function furtherCareerFoodExpenses() {
  let foodExpenses = 0;
  module17Data.furtherCareerFoodExpenses = 0;
  if (module17Data.furtherCareerFoodMultiplier !== undefined
    && module17Data.furtherCareerFoodMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.food !== undefined
      && module17Data.food !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.food > 0) {
        foodExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses.Food) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.food !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.food > 0) {
        foodExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Food) / 12);
      } else {
        foodExpenses = 0;
      }
    }
  } else {
    foodExpenses = '';
  }
  module17Data.furtherCareerFoodExpenses = foodExpenses;

  return module17Data.furtherCareerFoodExpenses;
}

// Further Career  Clothing Multiplier
export function furtherCareerClothingMultiplier() {
  module17Data.furtherCareerClothingMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerClothingMultiplier = module10Data.clothing_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerClothingMultiplier = module10Data.clothing_multiplier;
    } else {
      module17Data.furtherCareerClothingMultiplier = '';
    }
  }
  return module17Data.furtherCareerClothingMultiplier;
}

// Clothing Expenses
export function furtherCareerClothingExpenses() {
  let clothingExpenses = 0;
  module17Data.furtherCareerClothingExpenses = 0;
  if (module17Data.furtherCareerClothingMultiplier !== undefined
    && module17Data.furtherCareerClothingMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.clothing !== undefined
      && module17Data.clothing !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses.Clothing) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.clothing !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.clothing > 0) {
        clothingExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing) / 12);
      } else {
        clothingExpenses = 0;
      }
    }
  } else {
    clothingExpenses = '';
  }
  module17Data.furtherCareerClothingExpenses = clothingExpenses;

  return module17Data.furtherCareerClothingExpenses;
}

// Further Career  Entertainment Multiplier
export function furtherCareerEntertainmentMultiplier() {
  module17Data.furtherCareerEntertainmentMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerEntertainmentMultiplier = module10Data.entertainment_multiplier;
    } else {
      module17Data.furtherCareerEntertainmentMultiplier = '';
    }
  }
  return module17Data.furtherCareerEntertainmentMultiplier;
}

// Entertainment Expenses
export function furtherCareerEntertainmentExpenses() {
  let entertainmentExpenses = 0;
  module17Data.furtherCareerEntertainmentExpenses = 0;
  if (module17Data.furtherCareerEntertainmentMultiplier !== undefined
    && module17Data.furtherCareerEntertainmentMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.entertainment !== undefined
      && module17Data.entertainment !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses.Entertainment) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.entertainment !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.entertainment > 0) {
        entertainmentExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment) / 12);
      } else {
        entertainmentExpenses = 0;
      }
    }
  } else {
    entertainmentExpenses = '';
  }
  module17Data.furtherCareerEntertainmentExpenses = entertainmentExpenses;

  return module17Data.furtherCareerEntertainmentExpenses;
}

// Further Career  Technology Multiplier
export function furtherCareerTechnologyMultiplier() {
  module17Data.furtherCareerTechnologyMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerTechnologyMultiplier = module10Data.technology_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerTechnologyMultiplier = module10Data.technology_multiplier;
    } else {
      module17Data.furtherCareerTechnologyMultiplier = '';
    }
  }
  return module17Data.furtherCareerTechnologyMultiplier;
}

// Technology Expenses
export function furtherCareerTechnologyExpenses() {
  let technologyExpenses = 0;
  module17Data.furtherCareerTechnologyExpenses = 0;
  if (module17Data.furtherCareerTechnologyMultiplier !== undefined
    && module17Data.furtherCareerTechnologyMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.technology !== undefined
      && module17Data.technology !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses.Technology) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.technology !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.technology > 0) {
        technologyExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Technology) / 12);
      } else {
        technologyExpenses = 0;
      }
    }
  } else {
    technologyExpenses = '';
  }
  module17Data.furtherCareerTechnologyExpenses = technologyExpenses;

  return module17Data.furtherCareerTechnologyExpenses;
}

// Further Career  Transportation Multiplier
export function furtherCareerTransportationMultiplier() {
  module17Data.furtherCareerTransportationMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerTransportationMultiplier = module10Data.transportation_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerTransportationMultiplier = module10Data.transportation_multiplier;
    } else {
      module17Data.furtherCareerTransportationMultiplier = '';
    }
  }
  return module17Data.furtherCareerTransportationMultiplier;
}

// Transportation Expenses
export function furtherCareerTransportationExpenses() {
  let transportationExpenses = 0;
  module17Data.furtherCareerTransportationExpenses = 0;
  if (module17Data.furtherCareerTransportationMultiplier !== undefined
    && module17Data.furtherCareerTransportationMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.transportation !== undefined
      && module17Data.transportation !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses.Transportation) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.transportation !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.transportation > 0) {
        transportationExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation) / 12);
      } else {
        transportationExpenses = 0;
      }
    }
  } else {
    transportationExpenses = '';
  }
  module17Data.furtherCareerTransportationExpenses = transportationExpenses;

  return module17Data.furtherCareerTransportationExpenses;
}

// Further Career  Miscellaneous Multiplier
export function furtherCareerMiscellaneousMultiplier() {
  module17Data.furtherCareerMiscellaneousMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerMiscellaneousMultiplier = module10Data.miscellaneous_multiplier;
    } else {
      module17Data.furtherCareerMiscellaneousMultiplier = '';
    }
  }
  return module17Data.furtherCareerMiscellaneousMultiplier;
}

// Miscellaneous Expenses
export function furtherCareerMiscellaneousExpenses() {
  let miscellaneousExpenses = 0;
  module17Data.furtherCareerMiscellaneousExpenses = 0;
  if (module17Data.furtherCareerMiscellaneousMultiplier !== undefined
    && module17Data.furtherCareerMiscellaneousMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.miscellaneous !== undefined
      && module17Data.miscellaneous !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses.Miscellaneous) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.miscellaneous !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.miscellaneous > 0) {
        miscellaneousExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous) / 12);
      } else {
        miscellaneousExpenses = 0;
      }
    }
  } else {
    miscellaneousExpenses = '';
  }
  module17Data.furtherCareerMiscellaneousExpenses = miscellaneousExpenses;

  return module17Data.furtherCareerMiscellaneousExpenses;
}

// Further Career Medical Multiplier
export function furtherCareerOutsidePremiumMultiplier() {
  module17Data.furtherCareerOutsidePremiumMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerOutsidePremiumMultiplier = module10Data.health_insurance_costs_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerOutsidePremiumMultiplier = module10Data.health_insurance_costs_multiplier;
    } else {
      module17Data.furtherCareerOutsidePremiumMultiplier = '';
    }
  }
  return module17Data.furtherCareerOutsidePremiumMultiplier;
}

// Outside Premium Expenses
export function furtherCareerOutsidePremiumExpenses() {
  let healthExpenses = 0;
  module17Data.furtherCareerOutsidePremiumExpenses = 0;
  if (module17Data.furtherCareerOutsidePremiumMultiplier !== undefined
    && module17Data.furtherCareerOutsidePremiumMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.medical_costs_before_deductible !== undefined
      && module17Data.medical_costs_before_deductible !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.medical_costs_before_deductible > 0) {
        healthExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.medical_costs_before_deductible !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.medical_costs_before_deductible > 0) {
        healthExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']) / 12);
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module17Data.furtherCareerOutsidePremiumExpenses = healthExpenses;

  return module17Data.furtherCareerOutsidePremiumExpenses;
}

// Further Career Health Multiplier
export function furtherCareerPremiumMultiplier() {
  module17Data.furtherCareerPremiumMultiplier = 0;
  if (module17Data.start_year !== undefined
    && module10Data.marriege_year !== undefined
    && module10Data.rent_multiplier !== undefined) {
    if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.start_year >= module10Data.marriege_year) {
      module17Data.furtherCareerPremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else if (module17Data.start_year !== ''
      && module10Data.marriege_year !== ''
      && module17Data.end_year !== ''
      && module17Data.start_year < module10Data.marriege_year
      && module17Data.end_year >= module10Data.marriege_year) {
      module17Data.furtherCareerPremiumMultiplier = module10Data.health_insurance_premium_multiplier;
    } else {
      module17Data.furtherCareerPremiumMultiplier = '';
    }
  }
  return module17Data.furtherCareerPremiumMultiplier;
}

// Premium Expenses
export function furtherCareerPremiumExpenses() {
  let healthExpenses = 0;
  module17Data.furtherCareerPremiumExpenses = 0;
  if (module17Data.furtherCareerPremiumMultiplier !== undefined
    && module17Data.furtherCareerPremiumMultiplier !== '') {
    if (module17Data.start_year !== undefined
      && module10Data.marriege_year !== undefined
      && module17Data.health_insurance_premium !== undefined
      && module17Data.health_insurance_premium !== ''
      && incomeStatementData !== undefined
      && incomeStatementData !== ''
      && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year] !== ''
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses !== ''
      && incomeStatementData[module17Data.start_year] !== undefined
      && incomeStatementData[module17Data.start_year] !== ''
      && incomeStatementData[module17Data.start_year].livingExpenses !== undefined
      && incomeStatementData[module17Data.start_year].livingExpenses !== '') {
      if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.start_year >= module10Data.marriege_year
        && module17Data.health_insurance_premium > 0) {
        healthExpenses = -((incomeStatementData[module17Data.start_year].livingExpenses['Health Insurance Premium']) / 12);
      } else if (module17Data.start_year !== '' && module10Data.marriege_year !== ''
        && module17Data.end_year !== ''
        && module17Data.health_insurance_premium !== ''
        && module17Data.start_year < module10Data.marriege_year
        && module17Data.end_year >= module10Data.marriege_year
        && module17Data.health_insurance_premium > 0) {
        healthExpenses = -((incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium']) / 12);
      } else {
        healthExpenses = 0;
      }
    }
  } else {
    healthExpenses = '';
  }
  module17Data.furtherCareerPremiumExpenses = healthExpenses;

  return module17Data.furtherCareerPremiumExpenses;
}

// Annual Expense
export function furCareerAdvLivingExpenses() {
  module17Data.furCareerAdvLivingExpenses = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Rent !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Utilities !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Food !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Clothing !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Entertainment !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Technology !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Transportation !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Miscellaneous !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses['Health Insurance Premium'] !== undefined) {
    module17Data.furCareerAdvLivingExpenses = incomeStatementData[module17Data.start_year].livingExpenses.Rent
      + incomeStatementData[module17Data.start_year].livingExpenses.Utilities
      + incomeStatementData[module17Data.start_year].livingExpenses.Food
      + incomeStatementData[module17Data.start_year].livingExpenses.Clothing
      + incomeStatementData[module17Data.start_year].livingExpenses.Entertainment
      + incomeStatementData[module17Data.start_year].livingExpenses.Technology
      + incomeStatementData[module17Data.start_year].livingExpenses.Transportation
      + incomeStatementData[module17Data.start_year].livingExpenses.Miscellaneous
      + incomeStatementData[module17Data.start_year].livingExpenses['Healthcare Costs other than Premium']
      + incomeStatementData[module17Data.start_year].livingExpenses['Health Insurance Premium'];
  }
  return module17Data.furCareerAdvLivingExpenses;
}

// Hgher Education Expenses Value
export function furCareerAdvHigherEducationExpenses() {
  module17Data.furCareerAdvHigherEducationExpenses = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses['Higher Education Room & Board'] !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses['Off Campus Food'] !== undefined) {
    module17Data.furCareerAdvHigherEducationExpenses = incomeStatementData[module17Data.start_year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementData[module17Data.start_year].livingExpenses['Higher Education Room & Board']
      + incomeStatementData[module17Data.start_year].livingExpenses['Off Campus Food'];
  }
  return module17Data.furCareerAdvHigherEducationExpenses;
}

// Car Expenses Value
export function furCareerAdvCarExpenses() {
  module17Data.furCareerAdvCarExpenses = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined
    && incomeStatementData[module17Data.start_year]['Total Car Expenses'] !== undefined) {
    module17Data.furCareerAdvCarExpenses = incomeStatementData[module17Data.start_year]['Total Car Expenses'];
  }
  return module17Data.furCareerAdvCarExpenses;
}

// Children Expenses Value
export function furCareerAdvChildrenExpenses() {
  module17Data.furCareerAdvChildrenExpenses = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Children !== undefined) {
    module17Data.furCareerAdvChildrenExpenses = incomeStatementData[module17Data.start_year].livingExpenses.Children;
  }
  return module17Data.furCareerAdvChildrenExpenses;
}
// Pet Expenses Value
export function furCareerAdvPetExpenses() {
  module17Data.furCareerAdvPetExpenses = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined
    && incomeStatementData[module17Data.start_year].livingExpenses.Pet !== undefined) {
    module17Data.furCareerAdvPetExpenses = incomeStatementData[module17Data.start_year].livingExpenses.Pet;
  }
  return module17Data.furCareerAdvPetExpenses;
}

// Home Expenses Value
export function furCareerAdvHomeExpenses() {
  module17Data.furCareerAdvHomeExpenses = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined
    && incomeStatementData[module17Data.start_year]['Total Real Estate Expenses'] !== undefined) {
    module17Data.furCareerAdvHomeExpenses = incomeStatementData[module17Data.start_year]['Total Real Estate Expenses'];
  }
  return module17Data.furCareerAdvHomeExpenses;
}

// Financing Expenses Value
export function furCareerAdvFinancingExpenses() {
  module17Data.furCareerAdvFinancingExpenses = 0;
  let loanPayments1 = 0;

  let loanPaymentsFinal = 0;

  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined
    && incomeStatementData[module17Data.start_year]['Total Interest Expenses'] !== undefined) {
    loanPayments1 = incomeStatementData[module17Data.start_year]['Total Interest Expenses'];
  }

  loanPaymentsFinal = loanPayments1;
  module17Data.furCareerAdvFinancingExpenses = loanPaymentsFinal;

  return module17Data.furCareerAdvFinancingExpenses;
}

// Total Annual Expenses
export function furCareerAdvTotalAnnualExpenses() {
  module17Data.furCareerAdvTotalAnnualExpenses = 0;
  if (module17Data.annualTotalTaxesPaidFurCareerAdv !== undefined
    && module17Data.furCareerAdvLivingExpenses !== undefined
    && module17Data.furCareerAdvHigherEducationExpenses !== undefined
    && module17Data.furCareerAdvCarExpenses !== undefined
    && module17Data.furCareerAdvChildrenExpenses !== undefined
    && module17Data.furCareerAdvPetExpenses !== undefined
    && module17Data.furCareerAdvHomeExpenses !== undefined
    && module17Data.furCareerAdvFinancingExpenses !== undefined) {
    module17Data.furCareerAdvTotalAnnualExpenses = module17Data.annualTotalTaxesPaidFurCareerAdv + module17Data.furCareerAdvLivingExpenses
      + module17Data.furCareerAdvHigherEducationExpenses
      + module17Data.furCareerAdvCarExpenses
      + module17Data.furCareerAdvChildrenExpenses
      + module17Data.furCareerAdvPetExpenses
      + module17Data.furCareerAdvHomeExpenses
      + module17Data.furCareerAdvFinancingExpenses;
  }
  return module17Data.furCareerAdvTotalAnnualExpenses;
}

// Family Iccone
export function furCareerAdvIconCalcs() {
  module17Data.furCareerAdvIconCalcs = '';
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    module17Data.furCareerAdvIconCalcs = module10Data.marriege_year;
  } else {
    module17Data.furCareerAdvIconCalcs = '';
  }
  return module17Data.furCareerAdvIconCalcs;
}

// Living Expense Calcs
export function furCareerAdvLivingExpensesCalcs() {
  module17Data.furCareerAdvLivingExpensesCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] !== undefined) {
      module17Data.furCareerAdvLivingExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Rent
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Food
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Technology
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation
        + incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'];
    } else {
      module17Data.furCareerAdvLivingExpensesCalcs = 0;
    }
  } else {
    module17Data.furCareerAdvLivingExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvLivingExpensesCalcs;
}

// Family Iccone
export function furCareerAdvHigherEducationExpensesCalcs() {
  module17Data.furCareerAdvHigherEducationExpensesCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Room & Board'] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses['Off Campus Food'] !== undefined) {
      module17Data.furCareerAdvHigherEducationExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Tuition and Fees']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Room & Board']
        + incomeStatementData[module10Data.marriege_year].livingExpenses['Off Campus Food'];
    } else {
      module17Data.furCareerAdvHigherEducationExpensesCalcs = 0;
    }
  } else {
    module17Data.furCareerAdvHigherEducationExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvHigherEducationExpensesCalcs;
}

// Car Expenses Calcs
export function furCareerAdvCarExpensesCalcs() {
  module17Data.furCareerAdvCarExpensesCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Car Expenses'] !== undefined) {
      module17Data.furCareerAdvCarExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Car Expenses'];
    } else {
      module17Data.furCareerAdvCarExpensesCalcs = 0;
    }
  } else {
    module17Data.furCareerAdvCarExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvCarExpensesCalcs;
}

// Children Expenses Calcs
export function furCareerAdvChildrenExpensesCalcs() {
  module17Data.furCareerAdvChildrenExpensesCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Children !== undefined) {
      module17Data.furCareerAdvChildrenExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Children;
    } else {
      module17Data.furCareerAdvChildrenExpensesCalcs = 0;
    }
  } else {
    module17Data.furCareerAdvChildrenExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvChildrenExpensesCalcs;
}

// Children Expenses Calcs
export function furCareerAdvPetExpensesCalcs() {
  module17Data.furCareerAdvPetExpensesCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year].livingExpenses.Pet !== undefined) {
      module17Data.furCareerAdvPetExpensesCalcs = incomeStatementData[module10Data.marriege_year].livingExpenses.Pet;
    } else {
      module17Data.furCareerAdvPetExpensesCalcs = 0;
    }
  } else {
    module17Data.furCareerAdvPetExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvPetExpensesCalcs;
}

// Home Expenses Calcs
export function furCareerAdvHomeExpensesCalcs() {
  module17Data.furCareerAdvHomeExpensesCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Real Estate Expenses'] !== undefined) {
      module17Data.furCareerAdvHomeExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Real Estate Expenses'];
    } else {
      module17Data.furCareerAdvHomeExpensesCalcs = 0;
    }
  } else {
    module17Data.furCareerAdvHomeExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvHomeExpensesCalcs;
}

// Financing Expenses Calcs
export function furCareerAdvFinancingExpensesCalcs() {
  module17Data.furCareerAdvFinancingExpensesCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined
      && incomeStatementData[module10Data.marriege_year]['Total Interest Expenses'] !== undefined) {
      module17Data.furCareerAdvFinancingExpensesCalcs = incomeStatementData[module10Data.marriege_year]['Total Interest Expenses'];
    } else {
      module17Data.furCareerAdvFinancingExpensesCalcs = 0;
    }
  } else {
    module17Data.furCareerAdvFinancingExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvFinancingExpensesCalcs;
}

// Total Annual Expenses Calcs
export function furCareerAdvTotalAnnualExpensesCalcs() {
  module17Data.furCareerAdvTotalAnnualExpensesCalcs = 0;
  if (module17Data.furCareerAdvLivingExpensesCalcs !== undefined
    && module17Data.furCareerAdvHigherEducationExpensesCalcs !== undefined
    && module17Data.furCareerAdvCarExpensesCalcs !== undefined
    && module17Data.furCareerAdvChildrenExpensesCalcs !== undefined
    && module17Data.furCareerAdvPetExpensesCalcs !== undefined
    && module17Data.furCareerAdvHomeExpensesCalcs !== undefined
    && module17Data.furCareerAdvFinancingExpensesCalcs !== undefined) {
    module17Data.furCareerAdvTotalAnnualExpensesCalcs = module17Data.furCareerAdvLivingExpensesCalcs
      + module17Data.furCareerAdvHigherEducationExpensesCalcs
      + module17Data.furCareerAdvCarExpensesCalcs
      + module17Data.furCareerAdvChildrenExpensesCalcs
      + module17Data.furCareerAdvPetExpensesCalcs
      + module17Data.furCareerAdvHomeExpensesCalcs
      + module17Data.furCareerAdvFinancingExpensesCalcs;
  } else {
    module17Data.furCareerAdvTotalAnnualExpensesCalcs = 0;
  }
  return module17Data.furCareerAdvTotalAnnualExpensesCalcs;
}

export function furtherCareerTotalMonthlyLivingExpenses() {
  module17Data.furtherCareerTotalMonthlyLivingExpenses = 0;
  let totalExpenses = 0;
  if (module17Data.furtherCareerRentExpenses !== undefined && module17Data.furtherCareerRentExpenses !== '') {
    totalExpenses += module17Data.furtherCareerRentExpenses;
  }
  if (module17Data.furtherCareerUtilitiesExpenses !== undefined && module17Data.furtherCareerUtilitiesExpenses !== '') {
    totalExpenses += module17Data.furtherCareerUtilitiesExpenses;
  }
  if (module17Data.furtherCareerFoodExpenses !== undefined && module17Data.furtherCareerFoodExpenses !== '') {
    totalExpenses += module17Data.furtherCareerFoodExpenses;
  }
  if (module17Data.furtherCareerClothingExpenses !== undefined && module17Data.furtherCareerClothingExpenses !== '') {
    totalExpenses += module17Data.furtherCareerClothingExpenses;
  }
  if (module17Data.furtherCareerEntertainmentExpenses !== undefined && module17Data.furtherCareerEntertainmentExpenses !== '') {
    totalExpenses += module17Data.furtherCareerEntertainmentExpenses;
  }
  if (module17Data.furtherCareerTechnologyExpenses !== undefined && module17Data.furtherCareerTechnologyExpenses !== '') {
    totalExpenses += module17Data.furtherCareerTechnologyExpenses;
  }
  if (module17Data.furtherCareerTransportationExpenses !== undefined && module17Data.furtherCareerTransportationExpenses !== '') {
    totalExpenses += module17Data.furtherCareerTransportationExpenses;
  }
  if (module17Data.furtherCareerMiscellaneousExpenses !== undefined && module17Data.furtherCareerMiscellaneousExpenses !== '') {
    totalExpenses += module17Data.furtherCareerMiscellaneousExpenses;
  }
  if (module17Data.furtherCareerOutsidePremiumExpenses !== undefined && module17Data.furtherCareerOutsidePremiumExpenses !== '') {
    totalExpenses += module17Data.furtherCareerOutsidePremiumExpenses;
  }
  if (module17Data.furtherCareerPremiumExpenses !== undefined && module17Data.furtherCareerPremiumExpenses !== '') {
    totalExpenses += module17Data.furtherCareerPremiumExpenses;
  }
  module17Data.furtherCareerTotalMonthlyLivingExpenses = totalExpenses;
  return module17Data.furtherCareerTotalMonthlyLivingExpenses;
}

export function furtherCareerMarriageYear() {
  let furtherCareerMarriageYearVal = '';
  if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module17Data.start_year >= module10Data.marriege_year) {
    furtherCareerMarriageYearVal = module17Data.start_year;
  } else if (module17Data.start_year !== undefined
    && module17Data.start_year !== ''
    && module17Data.end_year !== undefined
    && module17Data.end_year !== ''
    && module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && module17Data.start_year < module10Data.marriege_year
    && module17Data.end_year >= module10Data.marriege_year) {
    furtherCareerMarriageYearVal = module10Data.marriege_year;
  } else {
    furtherCareerMarriageYearVal = '';
  }
  module17Data.furtherCareerMarriageYearVal = furtherCareerMarriageYearVal;
  return furtherCareerMarriageYearVal;
}

// Monthly income from Career Path
export function monthlyIncomeFromFurCareerAdv() {
  module17Data.monthlyIncomeFromFurCareerAdv = 0;
  if (module17Data.jobtotalPreTaxExpected_val !== undefined && module17Data.jobtotalPreTaxExpected_val !== '') {
    module17Data.monthlyIncomeFromFurCareerAdv = module17Data.jobtotalPreTaxExpected_val / 12;
  }
  return module17Data.monthlyIncomeFromFurCareerAdv;
}
// Total Onthly Income
export function totalMonthlyIncomeFurCareerAdv() {
  module17Data.totalMonthlyIncomeFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year]['Total Income'] !== undefined) {
    module17Data.totalMonthlyIncomeFurCareerAdv = (incomeStatementData[module17Data.start_year]['Total Income']) / 12;
  }
  return module17Data.totalMonthlyIncomeFurCareerAdv;
}
// Other Monthly Income
export function otherMonthlyIncomeFurCareerAdv() {
  module17Data.otherMonthlyIncomeFurCareerAdv = 0;
  if (module17Data.totalMonthlyIncomeFurCareerAdv !== undefined && module17Data.monthlyIncomeFromFurCareerAdv !== undefined) {
    module17Data.otherMonthlyIncomeFurCareerAdv = module17Data.totalMonthlyIncomeFurCareerAdv - module17Data.monthlyIncomeFromFurCareerAdv;
  }
  return module17Data.otherMonthlyIncomeFurCareerAdv;
}
// Total Taxes Paid
export function totalTaxesPaidFurCareerAdv() {
  module17Data.totalTaxesPaidFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year]['Total Taxes'] !== undefined) {
    module17Data.totalTaxesPaidFurCareerAdv = (incomeStatementData[module17Data.start_year]['Total Taxes']) / 12;
  }
  return module17Data.totalTaxesPaidFurCareerAdv;
}

// Living Expenses
export function livingExpensesFurCareerAdv() {
  module17Data.livingExpensesFurCareerAdv = 0;
  if (module17Data.livingTotalMonthlyLivingExpenses_val !== undefined) {
    module17Data.livingExpensesFurCareerAdv = -module17Data.livingTotalMonthlyLivingExpenses_val;
  }
  return module17Data.livingExpensesFurCareerAdv;
}
// Higher Education Expenses
export function higherEducationExpensesFurCareerAdv() {
  module17Data.higherEducationExpensesFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year].livingExpenses !== undefined) {
    module17Data.higherEducationExpensesFurCareerAdv = (incomeStatementData[module17Data.start_year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementData[module17Data.start_year].livingExpenses['Higher Education Room & Board']
      + incomeStatementData[module17Data.start_year].livingExpenses['Off Campus Food']) / 12;
  }
  return module17Data.higherEducationExpensesFurCareerAdv;
}

// Children Expenses
export function childrenExpensesFurCareerAdv() {
  module17Data.childrenExpensesFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year].livingExpenses !== undefined) {
    module17Data.childrenExpensesFurCareerAdv = incomeStatementData[module17Data.start_year].livingExpenses.Children / 12;
  }
  return module17Data.childrenExpensesFurCareerAdv;
}

// Pet Expenses
export function petExpensesFurCareerAdv() {
  module17Data.petExpensesFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year].livingExpenses !== undefined) {
    module17Data.petExpensesFurCareerAdv = incomeStatementData[module17Data.start_year].livingExpenses.Pet / 12;
  }
  return module17Data.petExpensesFurCareerAdv;
}
// Car Expenses
export function carExpensesFurCareerAdv() {
  module17Data.carExpensesFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year]['Total Car Expenses'] !== undefined) {
    module17Data.carExpensesFurCareerAdv = incomeStatementData[module17Data.start_year]['Total Car Expenses'] / 12;
  }
  return module17Data.carExpensesFurCareerAdv;
}

// Real Estate Expenses
export function realEstateExpensesFurCareerAdv() {
  module17Data.realEstateExpensesFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year]['Total Real Estate Expenses'] !== undefined) {
    module17Data.realEstateExpensesFurCareerAdv = incomeStatementData[module17Data.start_year]['Total Real Estate Expenses'] / 12;
  }
  return module17Data.realEstateExpensesFurCareerAdv;
}

// Internet Expenses
export function internetExpensesFurCareerAdv() {
  module17Data.internetExpensesFurCareerAdv = 0;
  let loanPayments1 = 0;
  let loanPayments2 = 0;
  let loanPayments3 = 0;
  let loanPayments4 = 0;
  let loanPayments5 = 0;
  let loanPaymentsFinal = 0;

  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year]['Total Interest Expenses'] !== undefined) {
    loanPayments1 = incomeStatementData[module17Data.start_year]['Total Interest Expenses'] / 12;
  }
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module17Data.start_year] !== undefined && cashFlowData[module17Data.start_year] !== '') {
    if (cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] !== '') {
      loanPayments2 = cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Existing Credit Card Debt Principal Payback'] / 12;
    }
    if (cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] !== '') {
      loanPayments3 = cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Car Loans Principal Payback'] / 12;
    }
    if (cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] !== '') {
      loanPayments4 = cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Student Loans Principal Payback'] / 12;
    }
    if (cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities'] !== ''
      && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] !== '') {
      loanPayments5 = cashFlowData[module17Data.start_year]['Cash from Financing Activities']['Mortgages Principal Payback'] / 12;
    }
  }
  loanPaymentsFinal = loanPayments1 + loanPayments2 + loanPayments3 + loanPayments4 + loanPayments5;
  module17Data.internetExpensesFurCareerAdv = loanPaymentsFinal;

  return module17Data.internetExpensesFurCareerAdv;
}

// Total Monthly Expenses
export function totalMonthlyExpensesFurCareerAdv() {
  module17Data.totalMonthlyExpensesFurCareerAdv = 0;
  module17Data.totalMonthlyExpensesFurCareerAdv = module17Data.totalTaxesPaidFurCareerAdv
    + module17Data.livingExpensesFurCareerAdv
    + module17Data.higherEducationExpensesFurCareerAdv
    + module17Data.childrenExpensesFurCareerAdv
    + module17Data.petExpensesFurCareerAdv
    + module17Data.carExpensesFurCareerAdv
    + module17Data.realEstateExpensesFurCareerAdv
    + module17Data.internetExpensesFurCareerAdv;
  return module17Data.totalMonthlyExpensesFurCareerAdv;
}

// Monthly Net Income
export function monthlyNetIncomeFurCareerAdv() {
  module17Data.monthlyNetIncomeFurCareerAdv = 0;
  if (module17Data.totalMonthlyIncomeFurCareerAdv !== undefined && module17Data.totalMonthlyExpensesFurCareerAdv !== undefined) {
    module17Data.monthlyNetIncomeFurCareerAdv = module17Data.totalMonthlyIncomeFurCareerAdv + module17Data.totalMonthlyExpensesFurCareerAdv;
  }
  return module17Data.monthlyNetIncomeFurCareerAdv;
}

// Retirement Contributions
export function retirementContributionsFurCareerAdv() {
  module17Data.retirementContributionsFurCareerAdv = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module17Data.start_year] !== undefined && cashFlowData[module17Data.start_year] !== '' && cashFlowData[module17Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== '') {
    module17Data.retirementContributionsFurCareerAdv = -cashFlowData[module17Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] / 12;
  }
  return module17Data.retirementContributionsFurCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function monthlyNetCashFlowFurCareerAdv() {
  module17Data.monthlyNetCashFlowFurCareerAdv = 0;
  if (module17Data.monthlyNetIncomeFurCareerAdv !== undefined && module17Data.retirementContributionsFurCareerAdv !== undefined) {
    module17Data.monthlyNetCashFlowFurCareerAdv = module17Data.monthlyNetIncomeFurCareerAdv - module17Data.retirementContributionsFurCareerAdv;
  }
  return module17Data.monthlyNetCashFlowFurCareerAdv;
}

// Total Income
export function totalIncomeFurCareerAdv() {
  module17Data.totalIncomeFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year]['Total Income'] !== undefined) {
    module17Data.totalIncomeFurCareerAdv = incomeStatementData[module17Data.start_year]['Total Income'];
  }
  return module17Data.totalIncomeFurCareerAdv;
}

// Total Income Calcs
export function totalIncomeFurCareerAdvCalcs() {
  module17Data.totalIncomeFurCareerAdvCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year]['Total Income'] !== undefined) {
      module17Data.totalIncomeFurCareerAdvCalcs = incomeStatementData[module10Data.marriege_year]['Total Income'];
    }
  }
  return module17Data.totalIncomeCareerPathCalcs;
}

// Total taxes paid
export function annualTotalTaxesPaidFurCareerAdv() {
  module17Data.annualTotalTaxesPaidFurCareerAdv = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module17Data.start_year] !== undefined && incomeStatementData[module17Data.start_year] !== '' && incomeStatementData[module17Data.start_year]['Total Income'] !== undefined) {
    module17Data.annualTotalTaxesPaidFurCareerAdv = incomeStatementData[module17Data.start_year]['Total Taxes'];
  }
  return module17Data.annualTotalTaxesPaidFurCareerAdv;
}

// Total Taxes Paid Calcs
export function annualTotalExpensesFurCareerAdvCalcs() {
  module17Data.annualTotalExpensesFurCareerAdvCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year]['Total Taxes'] !== undefined) {
      module17Data.annualTotalExpensesFurCareerAdvCalcs = incomeStatementData[module10Data.marriege_year]['Total Taxes'];
    }
  }
  return module17Data.annualTotalExpensesFurCareerAdvCalcs;
}

// Annual Net Income
export function furCareerAdvAnnualNetIncome() {
  module17Data.furCareerAdvAnnualNetIncome = 0;
  if (module17Data.furCareerAdvTotalAnnualExpenses !== undefined && module17Data.totalIncomeFurCareerAdv !== undefined) {
    module17Data.furCareerAdvAnnualNetIncome = module17Data.furCareerAdvTotalAnnualExpenses + module17Data.totalIncomeFurCareerAdv;
  }
  return module17Data.furCareerAdvAnnualNetIncome;
}

export function furCareerAdvAnnualNetIncomeCalcs() {
  module17Data.furCareerAdvAnnualNetIncomeCalcs = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module17Data.start_year < module10Data.marriege_year && module17Data.end_year >= module10Data.marriege_year) {
    if (module17Data.totalIncomeFurCareerAdvCalcs !== undefined && module17Data.furCareerAdvTotalAnnualExpensesCalcs !== undefined) {
      module17Data.furCareerAdvAnnualNetIncomeCalcs = module17Data.totalIncomeFurCareerAdvCalcs + module17Data.furCareerAdvTotalAnnualExpensesCalcs;
    }
  }
  return module17Data.furCareerAdvAnnualNetIncomeCalcs;
}

// Retirement Contributions
export function annualRetirementContributionsFurCareerAdv() {
  module17Data.annualRetirementContributionsFurCareerAdv = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module17Data.start_year] !== undefined && cashFlowData[module17Data.start_year] !== '' && cashFlowData[module17Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Investing Activities']['Retirement Contributions'] !== '') {
    module17Data.annualRetirementContributionsFurCareerAdv = -cashFlowData[module17Data.start_year]['Cash from Investing Activities']['Retirement Contributions'];
  }
  return module17Data.annualRetirementContributionsFurCareerAdv;
}

// Hsa Contributions
export function annualHsaContributionsFurCareerAdv() {
  module17Data.annualHsaContributionsFurCareerAdv = 0;
  if (module17Data.start_year !== undefined && module17Data.start_year !== ''
    && cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData[module17Data.start_year] !== undefined && cashFlowData[module17Data.start_year] !== '' && cashFlowData[module17Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== '') {
    module17Data.annualHsaContributionsFurCareerAdv = -cashFlowData[module17Data.start_year]['Cash from Investing Activities']['HSA Contributions'];
  }
  return module17Data.annualHsaContributionsFurCareerAdv;
}

// Net Cash Flow for Savings and Investments
export function annualNetCashFlowFurCareerAdv() {
  module17Data.annualNetCashFlowFurCareerAdv = 0;
  if (module17Data.furCareerAdvAnnualNetIncome !== undefined && module17Data.annualRetirementContributionsFurCareerAdv !== undefined) {
    module17Data.annualNetCashFlowFurCareerAdv = module17Data.furCareerAdvAnnualNetIncome - module17Data.annualRetirementContributionsFurCareerAdv;
  }
  return module17Data.annualNetCashFlowFurCareerAdv;
}

// Total Expenses Paid
export function furCareerAdvTotalExpenses() {
  module17Data.furCareerAdvTotalExpenses = 0;
  module17Data.furCareerAdvTotalExpenses = module17Data.furthermoreHigherEstimatedTaxesPaid
    + module17Data.furthermoreHigherlivingExpensesPaid
    + module17Data.furthermoreHigherEducationExpensesPaid
    + module17Data.furthermoreHigherChildrenExpensesPaid
    + module17Data.furthermoreHigherPetExpensesPaid
    + module17Data.furthermoreHigherCarExpensesPaid
    + module17Data.furthermoreHigherRealEstateExpensesPaid
    + module17Data.furthermoreHigherfinancingExpensesPaid;
  return module17Data.furCareerAdvTotalExpenses;
}
/** ******************** Module 17 Further Career Advanment Ends *********************** */

/** ******************** Module 18  Existing and Liabilities Starts *********************** */

// Beginning Balance
export function existingStudentLoansFedSubBeginningBalance() {
  module18Data.existingStudentLoansFedSubBeginningBalance = 0;
  if (module18Data.fed_sub_current_balance !== undefined && module18Data.fed_sub_current_balance !== '') {
    module18Data.existingStudentLoansFedSubBeginningBalance = module18Data.fed_sub_current_balance;
  }

  return module18Data.existingStudentLoansFedSubBeginningBalance;
}
// Monthly Payment
export function existingStudentLoansFedSubMonthlyPayment() {
  module18Data.existingStudentLoansFedSubMonthlyPayment = 0;
  if (module18Data.existingStudentLoansFedSubBeginningBalance !== undefined
    && module18Data.existingStudentLoansFedSubBeginningBalance > 0
    && fedSubExistingLoanData !== undefined
    && fedSubExistingLoanData !== '' && fedSubExistingLoanData['Original Payment'] !== undefined && fedSubExistingLoanData['Original Payment'] !== '') {
    module18Data.existingStudentLoansFedSubMonthlyPayment = fedSubExistingLoanData['Original Payment'];
  }

  return module18Data.existingStudentLoansFedSubMonthlyPayment;
}
// Annual Payment
export function existingStudentLoansFedSubAnnualPayment() {
  module18Data.existingStudentLoansFedSubAnnualPayment = 0;
  if (module18Data.existingStudentLoansFedSubBeginningBalance !== undefined
    && module18Data.existingStudentLoansFedSubBeginningBalance > 0) {
    module18Data.existingStudentLoansFedSubAnnualPayment = module18Data.existingStudentLoansFedSubMonthlyPayment * 12;
  }

  return module18Data.existingStudentLoansFedSubAnnualPayment;
}
// Total Interest
export function existingStudentLoansFedSubTotalInterest() {
  module18Data.existingStudentLoansFedSubTotalInterest = 0;
  if (module18Data.existingStudentLoansFedSubBeginningBalance !== undefined
    && module18Data.existingStudentLoansFedSubBeginningBalance > 0) {
    module18Data.existingStudentLoansFedSubTotalInterest = fedSubExistingLoanData['Total Interest Paid'];
  }

  return module18Data.existingStudentLoansFedSubTotalInterest;
}
// Total Principal & Interest
export function existingStudentLoansFedSubTotalPrincipalInterest() {
  module18Data.existingStudentLoansFedSubTotalPrincipalInterest = 0;
  if (module18Data.existingStudentLoansFedSubBeginningBalance !== undefined
    && module18Data.existingStudentLoansFedSubBeginningBalance > 0) {
    module18Data.existingStudentLoansFedSubTotalPrincipalInterest = fedSubExistingLoanData['Total Paid'];
  }

  return module18Data.existingStudentLoansFedSubTotalPrincipalInterest;
}
// Current Loan Balance
export function existingStudentLoansFedUnSubCurrentLoan() {
  module18Data.existingStudentLoansFedUnSubCurrentLoan = 0;
  if (module18Data.fed_unsub_loan_amount !== undefined && module18Data.fed_unsub_loan_amount !== ''
    && module18Data.fed_unsub_accrued_interest !== undefined && module18Data.fed_unsub_accrued_interest !== '') {
    module18Data.existingStudentLoansFedUnSubCurrentLoan = module18Data.fed_unsub_loan_amount
      + module18Data.fed_unsub_accrued_interest;
  }

  return module18Data.existingStudentLoansFedUnSubCurrentLoan;
}
// Total Prinicipal
export function existingStudentLoansFedUnSubTotalPrincipal() {
  module18Data.existingStudentLoansFedUnSubTotalPrincipal = 0;
  if (module18Data.fed_unsub_loan_amount !== undefined && module18Data.fed_unsub_loan_amount !== '') {
    module18Data.existingStudentLoansFedUnSubTotalPrincipal = module18Data.fed_unsub_loan_amount;
  }

  return module18Data.existingStudentLoansFedUnSubTotalPrincipal;
}
// Total Accrued Interest

export function existingStudentLoansFedUnSubTotalAccruedInterest() {

  module18Data.existingStudentLoansFedUnSubTotalAccruedInterest = 0;
  if (module18Data.existingStudentLoansFedUnSubTotalPrincipal !== undefined && module18Data.existingStudentLoansFedUnSubTotalPrincipal > 0) {
    module18Data.existingStudentLoansFedUnSubTotalAccruedInterest = fedUnSubExistingLoanData['Accrued Interest'];
  }

  return module18Data.existingStudentLoansFedUnSubTotalAccruedInterest;
}
// Beginning Balance
export function existingStudentLoansFedUnSubBeginningBalance() {
  module18Data.existingStudentLoansFedUnSubBeginningBalance = 0;
  if (module18Data.existingStudentLoansFedUnSubTotalPrincipal !== undefined
    && module18Data.existingStudentLoansFedUnSubTotalPrincipal > 0) {
    module18Data.existingStudentLoansFedUnSubBeginningBalance = fedUnSubExistingLoanData['Beginning Balance'];
  }

  return module18Data.existingStudentLoansFedUnSubBeginningBalance;
}
// Monthly Payment
export function existingStudentLoansFedUnSubMonthlyPayment() {
  module18Data.existingStudentLoansFedUnSubMonthlyPayment = 0;
  if (module18Data.existingStudentLoansFedUnSubTotalPrincipal !== undefined
    && module18Data.existingStudentLoansFedUnSubTotalPrincipal > 0) {
    module18Data.existingStudentLoansFedUnSubMonthlyPayment = fedUnSubExistingLoanData['Original Payment'];
  }

  return module18Data.existingStudentLoansFedUnSubMonthlyPayment;
}
// Annual Payment
export function existingStudentLoansFedUnSubAnnualPayment() {
  module18Data.existingStudentLoansFedUnSubAnnualPayment = 0;
  if (module18Data.existingStudentLoansFedUnSubTotalPrincipal !== undefined
    && module18Data.existingStudentLoansFedUnSubTotalPrincipal > 0) {
    module18Data.existingStudentLoansFedUnSubAnnualPayment = module18Data.existingStudentLoansFedUnSubMonthlyPayment * 12;
  }

  return module18Data.existingStudentLoansFedUnSubAnnualPayment;
}
// Total Interest
export function existingStudentLoansFedUnSubTotalInterest() {
  module18Data.existingStudentLoansFedUnSubTotalInterest = 0;
  if (module18Data.existingStudentLoansFedUnSubTotalPrincipal !== undefined
    && module18Data.existingStudentLoansFedUnSubTotalPrincipal > 0) {
    module18Data.existingStudentLoansFedUnSubTotalInterest = fedUnSubExistingLoanData['Total Interest Paid'];
  }

  return module18Data.existingStudentLoansFedUnSubTotalInterest;
}
// Total Principal & Interest
export function existingStudentLoansFedUnSubTotalPrincipalInterest() {
  module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest = 0;
  if (module18Data.existingStudentLoansFedUnSubTotalPrincipal !== undefined
    && module18Data.existingStudentLoansFedUnSubTotalPrincipal > 0) {
    module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest = fedUnSubExistingLoanData['Total Paid'];
  }

  return module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest;
}
// Current Loan Balance
export function existingStudentLoansPrivateCurrentLoan() {
  module18Data.existingStudentLoansPrivateCurrentLoan = 0;
  if (module18Data.private_loan_amount !== undefined && module18Data.private_loan_amount !== ''
    && module18Data.private_accrued_interest !== undefined && module18Data.private_accrued_interest !== '') {
    module18Data.existingStudentLoansPrivateCurrentLoan = module18Data.private_loan_amount
      + module18Data.private_accrued_interest;
  }

  return module18Data.existingStudentLoansPrivateCurrentLoan;
}
// Total Prinicipal
export function existingStudentLoansPrivateTotalPrincipal() {
  module18Data.existingStudentLoansPrivateTotalPrincipal = 0;
  if (module18Data.private_loan_amount !== undefined && module18Data.private_loan_amount !== '') {
    module18Data.existingStudentLoansPrivateTotalPrincipal = module18Data.private_loan_amount;
  }

  return module18Data.existingStudentLoansPrivateTotalPrincipal;
}
// Total Accrued Interest
export function existingStudentLoansPrivateTotalAccruedInterest() {
  module18Data.existingStudentLoansPrivateTotalAccruedInterest = 0;
  if (module18Data.existingStudentLoansPrivateTotalPrincipal !== undefined
    && module18Data.existingStudentLoansPrivateTotalPrincipal > 0) {
    module18Data.existingStudentLoansPrivateTotalAccruedInterest = privateExistingLoanData['Accrued Interest'];
  }

  return module18Data.existingStudentLoansPrivateTotalAccruedInterest;
}
// Beginning Balance
export function existingStudentLoansPrivateBeginningBalance() {
  module18Data.existingStudentLoansPrivateBeginningBalance = 0;
  if (module18Data.existingStudentLoansPrivateTotalPrincipal !== undefined
    && module18Data.existingStudentLoansPrivateTotalPrincipal > 0) {
    module18Data.existingStudentLoansPrivateBeginningBalance = privateExistingLoanData['Beginning Balance'];
  }

  return module18Data.existingStudentLoansPrivateBeginningBalance;
}
// Monthly Payment
export function existingStudentLoansPrivateMonthlyPayment() {
  module18Data.existingStudentLoansPrivateMonthlyPayment = 0;
  if (module18Data.existingStudentLoansPrivateTotalPrincipal !== undefined
    && module18Data.existingStudentLoansPrivateTotalPrincipal > 0) {
    module18Data.existingStudentLoansPrivateMonthlyPayment = privateExistingLoanData['Original Payment'];
  }

  return module18Data.existingStudentLoansPrivateMonthlyPayment;
}
// Annual Payment
export function existingStudentLoansPrivateAnnualPayment() {
  module18Data.existingStudentLoansPrivateAnnualPayment = 0;
  if (module18Data.existingStudentLoansPrivateTotalPrincipal !== undefined
    && module18Data.existingStudentLoansPrivateTotalPrincipal > 0) {
    module18Data.existingStudentLoansPrivateAnnualPayment = module18Data.existingStudentLoansPrivateMonthlyPayment * 12;
  }

  return module18Data.existingStudentLoansPrivateAnnualPayment;
}
// Total Interest
export function existingStudentLoansPrivateTotalInterest() {
  module18Data.existingStudentLoansPrivateTotalInterest = 0;
  if (module18Data.existingStudentLoansPrivateTotalPrincipal !== undefined
    && module18Data.existingStudentLoansPrivateTotalPrincipal > 0) {
    module18Data.existingStudentLoansPrivateTotalInterest = privateExistingLoanData['Total Interest Paid'];
  }

  return module18Data.existingStudentLoansPrivateTotalInterest;
}
// Total Principal & Interest
export function existingStudentLoansPrivateTotalPrincipalInterest() {
  module18Data.existingStudentLoansPrivateTotalPrincipalInterest = 0;
  if (module18Data.existingStudentLoansPrivateTotalPrincipal !== undefined
    && module18Data.existingStudentLoansPrivateTotalPrincipal > 0) {
    module18Data.existingStudentLoansPrivateTotalPrincipalInterest = privateExistingLoanData['Total Paid'];
  }

  return module18Data.existingStudentLoansPrivateTotalPrincipalInterest;
}

// Total Beginning Balance
export function existingStudentLoansSummaryTotalBeginningBalance() {
  module18Data.existingStudentLoansSummaryTotalBeginningBalance = 0;
  if (module18Data.existingStudentLoansFedSubBeginningBalance !== undefined
    && module18Data.existingStudentLoansFedUnSubBeginningBalance !== undefined
    && module18Data.existingStudentLoansPrivateBeginningBalance !== undefined) {
    module18Data.existingStudentLoansSummaryTotalBeginningBalance = module18Data.existingStudentLoansFedSubBeginningBalance
      + module18Data.existingStudentLoansFedUnSubBeginningBalance
      + module18Data.existingStudentLoansPrivateBeginningBalance;
  }
  return module18Data.existingStudentLoansSummaryTotalBeginningBalance;
}

// Total Monthly payment
export function existingStudentLoansSummaryMonthlyPayment() {
  module18Data.existingStudentLoansSummaryMonthlyPayment = 0;
  if (module18Data.existingStudentLoansFedSubMonthlyPayment !== undefined
    && module18Data.existingStudentLoansFedUnSubMonthlyPayment !== undefined
    && module18Data.existingStudentLoansPrivateMonthlyPayment !== undefined) {
    module18Data.existingStudentLoansSummaryMonthlyPayment = module18Data.existingStudentLoansFedSubMonthlyPayment
      + module18Data.existingStudentLoansFedUnSubMonthlyPayment
      + module18Data.existingStudentLoansPrivateMonthlyPayment;
  }
  return module18Data.existingStudentLoansSummaryMonthlyPayment;
}

// Total Annual Payment
export function existingStudentLoansSummaryTotalAnnualPayment() {
  module18Data.existingStudentLoansSummaryTotalAnnualPayment = 0;
  if (module18Data.existingStudentLoansFedSubAnnualPayment !== undefined
    && module18Data.existingStudentLoansFedUnSubAnnualPayment !== undefined
    && module18Data.existingStudentLoansPrivateAnnualPayment !== undefined) {
    module18Data.existingStudentLoansSummaryTotalAnnualPayment = module18Data.existingStudentLoansFedSubAnnualPayment
      + module18Data.existingStudentLoansFedUnSubAnnualPayment
      + module18Data.existingStudentLoansPrivateAnnualPayment;
  }
  return module18Data.existingStudentLoansSummaryTotalAnnualPayment;
}
// Summary Total Interest
export function existingStudentLoansSummaryTotalInterest() {
  module18Data.existingStudentLoansSummaryTotalInterest = 0;
  if (module18Data.existingStudentLoansFedSubTotalInterest !== undefined
    && module18Data.existingStudentLoansFedUnSubTotalInterest !== undefined
    && module18Data.existingStudentLoansPrivateTotalInterest !== undefined) {
    module18Data.existingStudentLoansSummaryTotalInterest = module18Data.existingStudentLoansFedSubTotalInterest
      + module18Data.existingStudentLoansFedUnSubTotalInterest
      + module18Data.existingStudentLoansPrivateTotalInterest;
  }
  return module18Data.existingStudentLoansSummaryTotalInterest;
}
// Summary Total Principal & Interest
export function existingStudentLoansSummaryTotalPrincipalInterest() {
  module18Data.existingStudentLoansSummaryTotalPrincipalInterest = 0;
  if (module18Data.existingStudentLoansFedSubTotalPrincipalInterest !== undefined
    && module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest !== undefined
    && module18Data.existingStudentLoansPrivateTotalPrincipalInterest !== undefined) {
    module18Data.existingStudentLoansSummaryTotalPrincipalInterest = module18Data.existingStudentLoansFedSubTotalPrincipalInterest
      + module18Data.existingStudentLoansFedUnSubTotalPrincipalInterest
      + module18Data.existingStudentLoansPrivateTotalPrincipalInterest;
  }
  return module18Data.existingStudentLoansSummaryTotalPrincipalInterest;
}
// Summary Debt-to-Income Ratio
export function existingStudentLoansSummaryIncomeRatio() {
  module18Data.existingStudentLoansSummaryIncomeRatio = 0;
  let totalInterestExpensesDebt9 = 0;
  if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module1Data.start_year] !== undefined && incomeStatementData[module1Data.start_year]['Total Interest Expenses'] !== '') {
    totalInterestExpensesDebt9 = -incomeStatementData[module1Data.start_year]['Total Interest Expenses'];
  }

  if (incomeStatementData[module1Data.start_year] !== undefined
    && incomeStatementData[module1Data.start_year]['Total Income'] !== undefined
    && incomeStatementData[module1Data.start_year]['Total Income'] !== '' && incomeStatementData[module1Data.start_year]['Total Income'] !== 0) {
    module18Data.existingStudentLoansSummaryIncomeRatio = ((parseFloat(totalInterestExpensesDebt9)) / parseFloat(incomeStatementData[module1Data.start_year]['Total Income'])) * 100;
  }

  return module18Data.existingStudentLoansSummaryIncomeRatio;
}

// Existing Credit Card Monthly Payment
export function existingCreditCardMonthlyPayment() {
  module18Data.existingCreditCardMonthlyPayment = 0;
  if (existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' && existingCreditCardDebtData['Credit Card Data'] !== undefined && existingCreditCardDebtData['Credit Card Data']['Original Payment'] !== '') {
    module18Data.existingCreditCardMonthlyPayment = existingCreditCardDebtData['Credit Card Data']['Original Payment'];
  }
  return module18Data.existingCreditCardMonthlyPayment;
}

// existing Current Cash Balance
export function existingCurrentCashBalance() {
  module18Data.existingCurrentCashBalance = 0;
  // let existingCash = 0;
  // let existingCheckingAccount = 0;
  let totalAmount = 0;
  if (module18Data.existing_cash !== undefined) {
    totalAmount = module18Data.existing_cash;
  }
  if (module18Data.existing_checking_accounts !== undefined) {
    totalAmount = totalAmount + module18Data.existing_checking_accounts;
  }
  module18Data.existingCurrentCashBalance = totalAmount;
  return module18Data.existingCurrentCashBalance;
}

//existing Total Investments
export function existingTotalInvestments() {
  module18Data.existingTotalInvestments = 0;
  if (module18Data.existing_total_short_term_investments !== undefined && module18Data.existing_total_long_term_investments !== undefined) {
    module18Data.existingTotalInvestments = module18Data.existing_total_short_term_investments + module18Data.existing_total_long_term_investments;
  }
  return module18Data.existingTotalInvestments;
}

//existing Total Other Investments
export function existingTotalOtherInvestments() {
  module18Data.existingTotalOtherInvestments = 0;
  if (module18Data.existing_total_value_of_hsa !== undefined) {
    module18Data.existingTotalOtherInvestments = module18Data.existing_total_value_of_hsa;
  }
  if (module18Data.existing_529_plan !== undefined) {
    module18Data.existingTotalOtherInvestments += module18Data.existing_529_plan;
  }
  return module18Data.existingTotalOtherInvestments;
}

//existing Total Retirement Accounts
export function existingTotalRetirementAccounts() {
  module18Data.existingTotalRetirementAccounts = 0;
  if (module18Data.existing_traditional_ra !== undefined) {
    module18Data.existingTotalRetirementAccounts = module18Data.existing_traditional_ra;
  }
  if (module18Data.existing_401k_403b !== undefined) {
    module18Data.existingTotalRetirementAccounts += module18Data.existing_401k_403b;
  }
  if (module18Data.existing_roth_ira !== undefined) {
    module18Data.existingTotalRetirementAccounts += module18Data.existing_roth_ira;
  }
  if (module18Data.existing_roth_401k_403b !== undefined) {
    module18Data.existingTotalRetirementAccounts += module18Data.existing_roth_401k_403b;
  }
  if (module18Data.existing_sep_ira !== undefined) {
    module18Data.existingTotalRetirementAccounts += module18Data.existing_sep_ira;
  }
  if (module18Data.existing_pension !== undefined) {
    module18Data.existingTotalRetirementAccounts += module18Data.existing_pension;
  }
  return module18Data.existingTotalRetirementAccounts;
}

//Total Valuables
export function existingTotalValuables() {
  module18Data.existingTotalValuables = 0;
  if (module18Data.existing_art !== undefined) {
    module18Data.existingTotalValuables = module18Data.existing_art;
  }
  if (module18Data.existing_jewelry !== undefined) {
    module18Data.existingTotalValuables += module18Data.existing_jewelry;
  }
  if (module18Data.existing_other !== undefined) {
    module18Data.existingTotalValuables += module18Data.existing_other;
  }
  return module18Data.existingTotalValuables;
}

//Existing Car Expenses Lease Payment
export function existingCarLoansLoanLeasePaymentCalcs() {
  module18Data.existingCarLoansLoanLeasePaymentCalcs = 0;
  if (module18Data.existing_car_loans_lease_payment !== undefined) {
    module18Data.existingCarLoansLoanLeasePaymentCalcs = module18Data.existing_car_loans_lease_payment * 12;
  }
  return module18Data.existingCarLoansLoanLeasePaymentCalcs;
}
export function existingCarLoansLoanLeasePaymentCalcs2() {
  module18Data.existingCarLoansLoanLeasePaymentCalcs2 = 0;
  if (module18Data.existing_car_loans_lease_payment_2 !== undefined) {
    module18Data.existingCarLoansLoanLeasePaymentCalcs2 = module18Data.existing_car_loans_lease_payment_2 * 12;
  }
  return module18Data.existingCarLoansLoanLeasePaymentCalcs2;
}
export function existingCarLoansLoanLeasePaymentCalcs3() {
  module18Data.existingCarLoansLoanLeasePaymentCalcs3 = 0;
  if (module18Data.existing_car_loans_lease_payment_3 !== undefined) {
    module18Data.existingCarLoansLoanLeasePaymentCalcs3 = module18Data.existing_car_loans_lease_payment_3 * 12;
  }
  return module18Data.existingCarLoansLoanLeasePaymentCalcs3;
}
export function existingCarLoansLoanLeasePaymentCalcs4() {
  module18Data.existingCarLoansLoanLeasePaymentCalcs4 = 0;
  if (module18Data.existing_car_loans_lease_payment_4 !== undefined) {
    module18Data.existingCarLoansLoanLeasePaymentCalcs4 = module18Data.existing_car_loans_lease_payment_4 * 12;
  }
  return module18Data.existingCarLoansLoanLeasePaymentCalcs4;
}
//Existing Car Expenses Maintenance
export function existingCarLoansLoanMaintenanceCalcs() {
  module18Data.existingCarLoansLoanMaintenanceCalcs = 0;
  if (module18Data.existing_car_loans_lease_maintenance !== undefined) {
    module18Data.existingCarLoansLoanMaintenanceCalcs = module18Data.existing_car_loans_lease_maintenance * 12;
  }
  return module18Data.existingCarLoansLoanMaintenanceCalcs;
}
export function existingCarLoansLoanMaintenanceCalcs2() {
  module18Data.existingCarLoansLoanMaintenanceCalcs2 = 0;
  if (module18Data.existing_car_loans_lease_maintenance_2 !== undefined) {
    module18Data.existingCarLoansLoanMaintenanceCalcs2 = module18Data.existing_car_loans_lease_maintenance_2 * 12;
  }
  return module18Data.existingCarLoansLoanMaintenanceCalcs2;
}
export function existingCarLoansLoanMaintenanceCalcs3() {
  module18Data.existingCarLoansLoanMaintenanceCalcs3 = 0;
  if (module18Data.existing_car_loans_lease_maintenance_3 !== undefined) {
    module18Data.existingCarLoansLoanMaintenanceCalcs3 = module18Data.existing_car_loans_lease_maintenance_3 * 12;
  }
  return module18Data.existingCarLoansLoanMaintenanceCalcs3;
}
export function existingCarLoansLoanMaintenanceCalcs4() {
  module18Data.existingCarLoansLoanMaintenanceCalcs4 = 0;
  if (module18Data.existing_car_loans_lease_maintenance_4 !== undefined) {
    module18Data.existingCarLoansLoanMaintenanceCalcs4 = module18Data.existing_car_loans_lease_maintenance_4 * 12;
  }
  return module18Data.existingCarLoansLoanMaintenanceCalcs4;
}

//Existing Car Expenses Insurance
export function existingCarLoansLoanInsuranceCalcs() {
  module18Data.existingCarLoansLoanInsuranceCalcs = 0;
  if (module18Data.existing_car_loans_lease_insurance !== undefined) {
    module18Data.existingCarLoansLoanInsuranceCalcs = module18Data.existing_car_loans_lease_insurance * 12;
  }
  return module18Data.existingCarLoansLoanInsuranceCalcs;
}
export function existingCarLoansLoanInsuranceCalcs2() {
  module18Data.existingCarLoansLoanInsuranceCalcs2 = 0;
  if (module18Data.existing_car_loans_lease_insurance_2 !== undefined) {
    module18Data.existingCarLoansLoanInsuranceCalcs2 = module18Data.existing_car_loans_lease_insurance_2 * 12;
  }
  return module18Data.existingCarLoansLoanInsuranceCalcs2;
}
export function existingCarLoansLoanInsuranceCalcs3() {
  module18Data.existingCarLoansLoanInsuranceCalcs3 = 0;
  if (module18Data.existing_car_loans_lease_insurance_3 !== undefined) {
    module18Data.existingCarLoansLoanInsuranceCalcs3 = module18Data.existing_car_loans_lease_insurance_3 * 12;
  }
  return module18Data.existingCarLoansLoanInsuranceCalcs3;
}
export function existingCarLoansLoanInsuranceCalcs4() {
  module18Data.existingCarLoansLoanInsuranceCalcs4 = 0;
  if (module18Data.existing_car_loans_lease_insurance_4 !== undefined) {
    module18Data.existingCarLoansLoanInsuranceCalcs4 = module18Data.existing_car_loans_lease_insurance_4 * 12;
  }
  return module18Data.existingCarLoansLoanInsuranceCalcs4;
}


//Existing Car Expenses Gas
export function existingCarLoansLoanGasCalcs() {
  module18Data.existingCarLoansLoanGasCalcs = 0;
  if (module18Data.existing_car_loans_lease_gas !== undefined) {
    module18Data.existingCarLoansLoanGasCalcs = module18Data.existing_car_loans_lease_gas * 12;
  }
  return module18Data.existingCarLoansLoanGasCalcs;
}
export function existingCarLoansLoanGasCalcs2() {
  module18Data.existingCarLoansLoanGasCalcs2 = 0;
  if (module18Data.existing_car_loans_lease_gas_2 !== undefined) {
    module18Data.existingCarLoansLoanGasCalcs2 = module18Data.existing_car_loans_lease_gas_2 * 12;
  }
  return module18Data.existingCarLoansLoanGasCalcs2;
}
export function existingCarLoansLoanGasCalcs3() {
  module18Data.existingCarLoansLoanGasCalcs3 = 0;
  if (module18Data.existing_car_loans_lease_gas_3 !== undefined) {
    module18Data.existingCarLoansLoanGasCalcs3 = module18Data.existing_car_loans_lease_gas_3 * 12;
  }
  return module18Data.existingCarLoansLoanGasCalcs3;
}
export function existingCarLoansLoanGasCalcs4() {
  module18Data.existingCarLoansLoanGasCalcs4 = 0;
  if (module18Data.existing_car_loans_lease_gas_4 !== undefined) {
    module18Data.existingCarLoansLoanGasCalcs4 = module18Data.existing_car_loans_lease_gas_4 * 12;
  }
  return module18Data.existingCarLoansLoanGasCalcs4;
}

//Existing Car Expenses total expenses
export function existingCarLoansTotalLeaseExpenses() {
  module18Data.existingCarLoansTotalLeaseExpensesVal = 0;
  if (module18Data.existing_car_loans_lease_payment !== undefined && module18Data.existing_car_loans_lease_maintenance !== undefined && module18Data.existing_car_loans_lease_insurance !== undefined && module18Data.existing_car_loans_lease_gas !== undefined) {
    module18Data.existingCarLoansTotalLeaseExpenses =
      module18Data.existing_car_loans_lease_payment +
      module18Data.existing_car_loans_lease_maintenance +
      module18Data.existing_car_loans_lease_insurance +
      module18Data.existing_car_loans_lease_gas;
  }
  return module18Data.existingCarLoansTotalLeaseExpenses;
}
export function existingCarLoansTotalLeaseExpenses2() {
  module18Data.existingCarLoansTotalLeaseExpenses2 = 0;
  if (module18Data.existing_car_loans_lease_payment_2 !== undefined && module18Data.existing_car_loans_lease_maintenance_2 !== undefined && module18Data.existing_car_loans_lease_insurance_2 !== undefined && module18Data.existing_car_loans_lease_gas_2 !== undefined) {
    module18Data.existingCarLoansTotalLeaseExpenses2 =
      module18Data.existing_car_loans_lease_payment_2 +
      module18Data.existing_car_loans_lease_maintenance_2 +
      module18Data.existing_car_loans_lease_insurance_2 +
      module18Data.existing_car_loans_lease_gas_2;
  }
  return module18Data.existingCarLoansTotalLeaseExpenses2;
}
export function existingCarLoansTotalLeaseExpenses3() {
  module18Data.existingCarLoansTotalLeaseExpenses3 = 0;
  if (module18Data.existing_car_loans_lease_payment_3 !== undefined && module18Data.existing_car_loans_lease_maintenance_3 !== undefined && module18Data.existing_car_loans_lease_insurance_3 !== undefined && module18Data.existing_car_loans_lease_gas_3 !== undefined) {
    module18Data.existingCarLoansTotalLeaseExpenses3 =
      module18Data.existing_car_loans_lease_payment_3 +
      module18Data.existing_car_loans_lease_maintenance_3 +
      module18Data.existing_car_loans_lease_insurance_3 +
      module18Data.existing_car_loans_lease_gas_3;
  }
  return module18Data.existingCarLoansTotalLeaseExpenses3;
}
export function existingCarLoansTotalLeaseExpenses4() {
  module18Data.existingCarLoansTotalLeaseExpenses4 = 0;
  if (module18Data.existing_car_loans_lease_payment_4 !== undefined && module18Data.existing_car_loans_lease_maintenance_4 !== undefined && module18Data.existing_car_loans_lease_insurance_4 !== undefined && module18Data.existing_car_loans_lease_gas_4 !== undefined) {
    module18Data.existingCarLoansTotalLeaseExpenses4 =
      module18Data.existing_car_loans_lease_payment_4 +
      module18Data.existing_car_loans_lease_maintenance_4 +
      module18Data.existing_car_loans_lease_insurance_4 +
      module18Data.existing_car_loans_lease_gas_4;
  }
  return module18Data.existingCarLoansTotalLeaseExpenses4;
}

//Existing Car Expenses total expenses
export function existingCarLoansLoanAnnualTotalExpensesCalcs() {
  module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs = 0;
  if (module18Data.existingCarLoansTotalLeaseExpenses !== undefined) {
    module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs = module18Data.existingCarLoansTotalLeaseExpenses * 12;
  }
  return module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs;
}
export function existingCarLoansLoanAnnualTotalExpensesCalcs2() {
  module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs2 = 0;
  if (module18Data.existingCarLoansTotalLeaseExpenses2 !== undefined) {
    module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs2 = module18Data.existingCarLoansTotalLeaseExpenses2 * 12;
  }
  return module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs2;
}
export function existingCarLoansLoanAnnualTotalExpensesCalcs3() {
  module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs3 = 0;
  if (module18Data.existingCarLoansTotalLeaseExpenses3 !== undefined) {
    module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs3 = module18Data.existingCarLoansTotalLeaseExpenses3 * 12;
  }
  return module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs3;
}
export function existingCarLoansLoanAnnualTotalExpensesCalcs4() {
  module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs4 = 0;
  if (module18Data.existingCarLoansTotalLeaseExpenses4 !== undefined) {
    module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs4 = module18Data.existingCarLoansTotalLeaseExpenses4 * 12;
  }
  return module18Data.existingCarLoansLoanAnnualTotalExpensesCalcs4;
}

// Existing Credit Card Annual Payment
export function existingCreditCardAnnualPayment() {
  module18Data.existingCreditCardAnnualPayment = 0;
  if (module18Data.existingCreditCardMonthlyPayment !== undefined && module18Data.existingCreditCardMonthlyPayment !== '' && module18Data.ideal_repayment_schedule !== undefined && module18Data.ideal_repayment_schedule > 12) {
    module18Data.existingCreditCardAnnualPayment = module18Data.existingCreditCardMonthlyPayment * 12;
  } else if (module18Data.ideal_repayment_schedule !== undefined && module18Data.ideal_repayment_schedule !== '') {
    module18Data.existingCreditCardAnnualPayment = module18Data.existingCreditCardMonthlyPayment * module18Data.ideal_repayment_schedule;
  } else {
    module18Data.existingCreditCardAnnualPayment = 0;
  }
  return module18Data.existingCreditCardAnnualPayment;
}

// Existing Credit Card Total Interest
export function existingCreditCardTotalInterest() {
  module18Data.existingCreditCardTotalInterest = 0;
  if (existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' && existingCreditCardDebtData['Total Interest Paid'] !== undefined && existingCreditCardDebtData['Total Interest Paid'] !== '') {
    module18Data.existingCreditCardTotalInterest = existingCreditCardDebtData['Total Interest Paid'];
  }
  return module18Data.existingCreditCardTotalInterest;
}

// Existing Credit Card Total Principal And Interest
export function existingCreditCardTotalPrincipalInterest() {
  module18Data.existingCreditCardTotalPrincipalInterest = 0;
  if (existingCreditCardDebtData !== undefined && existingCreditCardDebtData !== '' && existingCreditCardDebtData['Total Paid'] !== undefined && existingCreditCardDebtData['Total Paid'] !== '') {
    module18Data.existingCreditCardTotalPrincipalInterest = existingCreditCardDebtData['Total Paid'];
  }
  return module18Data.existingCreditCardTotalPrincipalInterest;
}

// Existing Car Monthly Payment
export function existingCarLoansMonthlyPayment() {
  module18Data.existingCarLoansMonthlyPayment = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Car Loan1'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment'] !== '') {
    module18Data.existingCarLoansMonthlyPayment = existingCarLoanData['Car Loan1']['Original Payment'];
  }
  return module18Data.existingCarLoansMonthlyPayment;
}

// Existing Car Monthly Payment
export function existingCarLoansMonthlyPayment2() {
  module18Data.existingCarLoansMonthlyPayment2 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Car Loan1'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment2'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment2'] !== '') {
    module18Data.existingCarLoansMonthlyPayment2 = existingCarLoanData['Car Loan1']['Original Payment2'];
  }
  return module18Data.existingCarLoansMonthlyPayment2;
}

export function existingCarLoansMonthlyPayment3() {
  module18Data.existingCarLoansMonthlyPayment3 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Car Loan1'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment3'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment3'] !== '') {
    module18Data.existingCarLoansMonthlyPayment3 = existingCarLoanData['Car Loan1']['Original Payment3'];
  }
  return module18Data.existingCarLoansMonthlyPayment3;
}

export function existingCarLoansMonthlyPayment4() {
  module18Data.existingCarLoansMonthlyPayment4 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Car Loan1'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment4'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment4'] !== '') {
    module18Data.existingCarLoansMonthlyPayment4 = existingCarLoanData['Car Loan1']['Original Payment4'];
  }
  return module18Data.existingCarLoansMonthlyPayment4;
}

//For Personal Loan
export function personalLoansMonthlyPayment() {
  module18Data.personalLoansMonthlyPayment = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Car Loan1'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment5'] !== undefined && existingCarLoanData['Car Loan1']['Original Payment5'] !== '') {
    module18Data.personalLoansMonthlyPayment = existingCarLoanData['Car Loan1']['Original Payment5'];
  }
  return module18Data.personalLoansMonthlyPayment;
}

export function personalLoansAnnualPaid() {
  module18Data.personalLoansAnnualPaid = 0;
  if (personalLoansMonthlyPayment !== undefined) {
    module18Data.personalLoansAnnualPaid = module18Data.personalLoansMonthlyPayment * 12;
  }
  return module18Data.personalLoansAnnualPaid;
}

export function personalLoansTotalInterest() {
  module18Data.personalLoansTotalInterest = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Interest Paid5'] !== undefined && existingCarLoanData['Total Interest Paid5'] !== undefined && existingCarLoanData['Total Interest Paid5'] !== '') {
    module18Data.personalLoansTotalInterest = existingCarLoanData['Total Interest Paid5'];
  }
  return module18Data.personalLoansTotalInterest;
}

export function personalLoansTotalRepaymentAmount() {
  module18Data.personalLoansTotalRepaymentAmount = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Paid5'] !== undefined && existingCarLoanData['Total Paid5'] !== undefined && existingCarLoanData['Total Paid5'] !== '') {
    module18Data.personalLoansTotalRepaymentAmount = existingCarLoanData['Total Paid5'];
  }
  return module18Data.personalLoansTotalRepaymentAmount;
}

// Existing Car Monthly Payment
export function existingCarLoansAnnualPayment() {
  module18Data.existingCarLoansAnnualPayment = 0;
  if (module18Data.existingCarLoansMonthlyPayment !== undefined && module18Data.existingCarLoansMonthlyPayment !== '') {
    module18Data.existingCarLoansAnnualPayment = module18Data.existingCarLoansMonthlyPayment * 12;
  } else {
    module18Data.existingCarLoansAnnualPayment = 0;
  }
  return module18Data.existingCarLoansAnnualPayment;
}

// Existing Car Monthly Payment
export function existingCarLoansAnnualPayment2() {
  module18Data.existingCarLoansAnnualPayment2 = 0;
  if (module18Data.existingCarLoansMonthlyPayment2 !== undefined && module18Data.existingCarLoansMonthlyPayment2 !== '') {
    module18Data.existingCarLoansAnnualPayment2 = module18Data.existingCarLoansMonthlyPayment2 * 12;
  } else {
    module18Data.existingCarLoansAnnualPayment2 = 0;
  }
  return module18Data.existingCarLoansAnnualPayment2;
}
export function existingCarLoansAnnualPayment3() {
  module18Data.existingCarLoansAnnualPayment3 = 0;
  if (module18Data.existingCarLoansMonthlyPayment3 !== undefined && module18Data.existingCarLoansMonthlyPayment3 !== '') {
    module18Data.existingCarLoansAnnualPayment3 = module18Data.existingCarLoansMonthlyPayment3 * 12;
  } else {
    module18Data.existingCarLoansAnnualPayment3 = 0;
  }
  return module18Data.existingCarLoansAnnualPayment3;
}

export function existingCarLoansAnnualPayment4() {
  module18Data.existingCarLoansAnnualPayment4 = 0;
  if (module18Data.existingCarLoansMonthlyPayment4 !== undefined && module18Data.existingCarLoansMonthlyPayment4 !== '') {
    module18Data.existingCarLoansAnnualPayment4 = module18Data.existingCarLoansMonthlyPayment4 * 12;
  } else {
    module18Data.existingCarLoansAnnualPayment4 = 0;
  }
  return module18Data.existingCarLoansAnnualPayment4;
}

// Existing Car Total Interest
export function existingCarLoansTotalInterest() {
  module18Data.existingCarLoansTotalInterest = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Interest Paid'] !== undefined && existingCarLoanData['Total Interest Paid'] !== undefined && existingCarLoanData['Total Interest Paid'] !== '') {
    module18Data.existingCarLoansTotalInterest = existingCarLoanData['Total Interest Paid'];
  }
  return module18Data.existingCarLoansTotalInterest;
}

export function existingCarLoansTotalInterest2() {
  module18Data.existingCarLoansTotalInterest2 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Interest Paid2'] !== undefined && existingCarLoanData['Total Interest Paid2'] !== undefined && existingCarLoanData['Total Interest Paid2'] !== '') {
    module18Data.existingCarLoansTotalInterest2 = existingCarLoanData['Total Interest Paid2'];
  }
  return module18Data.existingCarLoansTotalInterest2;
}
export function existingCarLoansTotalInterest3() {
  module18Data.existingCarLoansTotalInterest3 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Interest Paid3'] !== undefined && existingCarLoanData['Total Interest Paid3'] !== undefined && existingCarLoanData['Total Interest Paid3'] !== '') {
    module18Data.existingCarLoansTotalInterest3 = existingCarLoanData['Total Interest Paid3'];
  }
  return module18Data.existingCarLoansTotalInterest3;
}

export function existingCarLoansTotalInterest4() {
  module18Data.existingCarLoansTotalInterest4 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Interest Paid4'] !== undefined && existingCarLoanData['Total Interest Paid4'] !== undefined && existingCarLoanData['Total Interest Paid4'] !== '') {
    module18Data.existingCarLoansTotalInterest4 = existingCarLoanData['Total Interest Paid4'];
  }
  return module18Data.existingCarLoansTotalInterest4;
}

// Existing Car Total Principal Interest
export function existingCarLoansTotalPrincipalInterest() {
  module18Data.existingCarLoansTotalPrincipalInterest = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Paid'] !== undefined && existingCarLoanData['Total Paid'] !== undefined && existingCarLoanData['Total Paid'] !== '') {
    module18Data.existingCarLoansTotalPrincipalInterest = existingCarLoanData['Total Paid'];
  }
  return module18Data.existingCarLoansTotalPrincipalInterest;
}
export function existingCarLoansTotalPrincipalInterest2() {
  module18Data.existingCarLoansTotalPrincipalInterest2 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Paid2'] !== undefined && existingCarLoanData['Total Paid2'] !== undefined && existingCarLoanData['Total Paid2'] !== '') {
    module18Data.existingCarLoansTotalPrincipalInterest2 = existingCarLoanData['Total Paid2'];
  }
  return module18Data.existingCarLoansTotalPrincipalInterest2;
}
export function existingCarLoansTotalPrincipalInterest3() {
  module18Data.existingCarLoansTotalPrincipalInterest3 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Paid3'] !== undefined && existingCarLoanData['Total Paid3'] !== undefined && existingCarLoanData['Total Paid3'] !== '') {
    module18Data.existingCarLoansTotalPrincipalInterest3 = existingCarLoanData['Total Paid3'];
  }
  return module18Data.existingCarLoansTotalPrincipalInterest3;
}
export function existingCarLoansTotalPrincipalInterest4() {
  module18Data.existingCarLoansTotalPrincipalInterest4 = 0;
  if (existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Total Paid4'] !== undefined && existingCarLoanData['Total Paid4'] !== undefined && existingCarLoanData['Total Paid4'] !== '') {
    module18Data.existingCarLoansTotalPrincipalInterest4 = existingCarLoanData['Total Paid4'];
  }
  return module18Data.existingCarLoansTotalPrincipalInterest4;
}

// Existing Car Expenses Maintenance value
export function existingCarLoansMaintenanceCalcs() {
  module18Data.existingCarLoansMaintenanceCalcs = 0;
  if (module18Data.existing_car_loans_own_maintenance !== undefined && module18Data.existing_car_loans_own_maintenance !== '') {
    module18Data.existingCarLoansMaintenanceCalcs = module18Data.existing_car_loans_own_maintenance * 12;
  }
  return module18Data.existingCarLoansMaintenanceCalcs;
}
export function existingCarLoansMaintenanceCalcs2() {
  module18Data.existingCarLoansMaintenanceCalcs2 = 0;
  if (module18Data.existing_car_loans_own_maintenance_2 !== undefined && module18Data.existing_car_loans_own_maintenance_2 !== '') {
    module18Data.existingCarLoansMaintenanceCalcs2 = module18Data.existing_car_loans_own_maintenance_2 * 12;
  }
  return module18Data.existingCarLoansMaintenanceCalcs2;
}
export function existingCarLoansMaintenanceCalcs3() {
  module18Data.existingCarLoansMaintenanceCalcs3 = 0;
  if (module18Data.existing_car_loans_own_maintenance_3 !== undefined && module18Data.existing_car_loans_own_maintenance_3 !== '') {
    module18Data.existingCarLoansMaintenanceCalcs3 = module18Data.existing_car_loans_own_maintenance_3 * 12;
  }
  return module18Data.existingCarLoansMaintenanceCalcs3;
}
export function existingCarLoansMaintenanceCalcs4() {
  module18Data.existingCarLoansMaintenanceCalcs4 = 0;
  if (module18Data.existing_car_loans_own_maintenance_4 !== undefined && module18Data.existing_car_loans_own_maintenance_4 !== '') {
    module18Data.existingCarLoansMaintenanceCalcs4 = module18Data.existing_car_loans_own_maintenance_4 * 12;
  }
  return module18Data.existingCarLoansMaintenanceCalcs4;
}

// Existing Car Expenses Insurance value
export function existingCarLoansInsuranceCalcs() {
  module18Data.existingCarLoansInsuranceCalcs = 0;
  if (module18Data.existing_car_loans_own_insurance !== undefined && module18Data.existing_car_loans_lnsurance !== '') {
    module18Data.existingCarLoansInsuranceCalcs = module18Data.existing_car_loans_own_insurance * 12;
  }
  return module18Data.existingCarLoansInsuranceCalcs;
}
export function existingCarLoansInsuranceCalcs2() {
  module18Data.existingCarLoansInsuranceCalcs2 = 0;
  if (module18Data.existing_car_loans_own_insurance_2 !== undefined && module18Data.existing_car_loans_own_insurance_2 !== '') {
    module18Data.existingCarLoansInsuranceCalcs2 = module18Data.existing_car_loans_own_insurance_2 * 12;
  }
  return module18Data.existingCarLoansInsuranceCalcs2;
}
export function existingCarLoansInsuranceCalcs3() {
  module18Data.existingCarLoansInsuranceCalcs3 = 0;
  if (module18Data.existing_car_loans_own_insurance_3 !== undefined && module18Data.existing_car_loans_own_insurance_3 !== '') {
    module18Data.existingCarLoansInsuranceCalcs3 = module18Data.existing_car_loans_own_insurance_3 * 12;
  }
  return module18Data.existingCarLoansInsuranceCalcs3;
}
export function existingCarLoansInsuranceCalcs4() {
  module18Data.existingCarLoansInsuranceCalcs4 = 0;
  if (module18Data.existing_car_loans_own_insurance_4 !== undefined && module18Data.existing_car_loans_own_insurance_4 !== '') {
    module18Data.existingCarLoansInsuranceCalcs4 = module18Data.existing_car_loans_own_insurance_4 * 12;
  }
  return module18Data.existingCarLoansInsuranceCalcs4;
}

// Existing Car Expenses Gas value
export function existingCarLoansGasCalcs() {
  module18Data.existingCarLoansGasCalcs = 0;
  if (module18Data.existing_car_loans_own_gas !== undefined && module18Data.existing_car_loans_own_gas !== '') {
    module18Data.existingCarLoansGasCalcs = module18Data.existing_car_loans_own_gas * 12;
  }
  return module18Data.existingCarLoansGasCalcs;
}
export function existingCarLoansGasCalcs2() {
  module18Data.existingCarLoansGasCalcs2 = 0;
  if (module18Data.existing_car_loans_own_gas_2 !== undefined && module18Data.existing_car_loans_own_gas_2 !== '') {
    module18Data.existingCarLoansGasCalcs2 = module18Data.existing_car_loans_own_gas_2 * 12;
  }
  return module18Data.existingCarLoansGasCalcs2;
}
export function existingCarLoansGasCalcs3() {
  module18Data.existingCarLoansGasCalcs3 = 0;
  if (module18Data.existing_car_loans_own_gas_3 !== undefined && module18Data.existing_car_loans_own_gas_3 !== '') {
    module18Data.existingCarLoansGasCalcs3 = module18Data.existing_car_loans_own_gas_3 * 12;
  }
  return module18Data.existingCarLoansGasCalcs3;
}
export function existingCarLoansGasCalcs4() {
  module18Data.existingCarLoansGasCalcs4 = 0;
  if (module18Data.existing_car_loans_own_gas_4 !== undefined && module18Data.existing_car_loans_own_gas_4 !== '') {
    module18Data.existingCarLoansGasCalcs4 = module18Data.existing_car_loans_own_gas_4 * 12;
  }
  return module18Data.existingCarLoansGasCalcs4;
}


//Existing Car Expenses Loan Payments value
export function existingCarLoansLoanPaymentsCalcs() {
  module18Data.existingCarLoansLoanPaymentsCalcs = 0;
  if (module18Data.existingCarLoansMonthlyPayment !== undefined && module18Data.existingCarLoansMonthlyPayment !== '') {
    module18Data.existingCarLoansLoanPaymentsCalcs = module18Data.existingCarLoansMonthlyPayment;
  }
  return module18Data.existingCarLoansLoanPaymentsCalcs;
}
export function existingCarLoansLoanPaymentsCalcs2() {
  module18Data.existingCarLoansLoanPaymentsCalcs2 = 0;
  if (module18Data.existingCarLoansMonthlyPayment2 !== undefined && module18Data.existingCarLoansMonthlyPayment2 !== '') {
    module18Data.existingCarLoansLoanPaymentsCalcs2 = module18Data.existingCarLoansMonthlyPayment2;
  }
  return module18Data.existingCarLoansLoanPaymentsCalcs2;
}
export function existingCarLoansLoanPaymentsCalcs3() {
  module18Data.existingCarLoansLoanPaymentsCalcs3 = 0;
  if (module18Data.existingCarLoansMonthlyPayment3 !== undefined && module18Data.existingCarLoansMonthlyPayment3 !== '') {
    module18Data.existingCarLoansLoanPaymentsCalcs3 = module18Data.existingCarLoansMonthlyPayment3;
  }
  return module18Data.existingCarLoansLoanPaymentsCalcs3;
}
export function existingCarLoansLoanPaymentsCalcs4() {
  module18Data.existingCarLoansLoanPaymentsCalcs4 = 0;
  if (module18Data.existingCarLoansMonthlyPayment4 !== undefined && module18Data.existingCarLoansMonthlyPayment4 !== '') {
    module18Data.existingCarLoansLoanPaymentsCalcs4 = module18Data.existingCarLoansMonthlyPayment4;
  }
  return module18Data.existingCarLoansLoanPaymentsCalcs4;
}

//Existing Car Expenses Loan Payments value
export function existingCarLoansLoanPaymentsYearCalcs() {
  module18Data.existingCarLoansLoanPaymentsYearCalcs = 0;
  if (module18Data.existingCarLoansMonthlyPayment !== undefined && module18Data.existingCarLoansMonthlyPayment !== '') {
    module18Data.existingCarLoansLoanPaymentsYearCalcs = module18Data.existingCarLoansMonthlyPayment * 12;
  }
  return module18Data.existingCarLoansLoanPaymentsYearCalcs;
}
export function existingCarLoansLoanPaymentsYearCalcs2() {
  module18Data.existingCarLoansLoanPaymentsYearCalcs2 = 0;
  if (module18Data.existingCarLoansMonthlyPayment2 !== undefined && module18Data.existingCarLoansMonthlyPayment2 !== '') {
    module18Data.existingCarLoansLoanPaymentsYearCalcs2 = module18Data.existingCarLoansMonthlyPayment2 * 12;
  }
  return module18Data.existingCarLoansLoanPaymentsYearCalcs2;
}
export function existingCarLoansLoanPaymentsYearCalcs3() {
  module18Data.existingCarLoansLoanPaymentsYearCalcs3 = 0;
  if (module18Data.existingCarLoansMonthlyPayment3 !== undefined && module18Data.existingCarLoansMonthlyPayment3 !== '') {
    module18Data.existingCarLoansLoanPaymentsYearCalcs3 = module18Data.existingCarLoansMonthlyPayment3 * 12;
  }
  return module18Data.existingCarLoansLoanPaymentsYearCalcs3;
}
export function existingCarLoansLoanPaymentsYearCalcs4() {
  module18Data.existingCarLoansLoanPaymentsYearCalcs4 = 0;
  if (module18Data.existingCarLoansMonthlyPayment4 !== undefined && module18Data.existingCarLoansMonthlyPayment4 !== '') {
    module18Data.existingCarLoansLoanPaymentsYearCalcs4 = module18Data.existingCarLoansMonthlyPayment4 * 12;
  }
  return module18Data.existingCarLoansLoanPaymentsYearCalcs4;
}


// Existing Car Expenses Total Monthly Expenses

export function existingCarLoansTotalMonthlyExpenses() {
  module18Data.existingCarLoansTotalMonthlyExpenses = 0;
  if (module18Data.existing_car_loans_own_maintenance !== undefined && module18Data.existing_car_loans_own_insurance !== undefined && module18Data.existing_car_loans_own_gas !== undefined && module18Data.existingCarLoansLoanPaymentsCalcs !== undefined) {
    module18Data.existingCarLoansTotalMonthlyExpenses = module18Data.existing_car_loans_own_maintenance
      + module18Data.existing_car_loans_own_insurance
      + module18Data.existing_car_loans_own_gas
      + module18Data.existingCarLoansLoanPaymentsCalcs;
  }
  return module18Data.existingCarLoansTotalMonthlyExpenses;
}
export function existingCarLoansTotalMonthlyExpenses2() {
  module18Data.existingCarLoansTotalMonthlyExpenses2 = 0;
  if (module18Data.existing_car_loans_own_maintenance_2 !== undefined && module18Data.existing_car_loans_own_insurance_2 !== undefined && module18Data.existing_car_loans_own_gas_2 !== undefined && module18Data.existingCarLoansLoanPaymentsCalcs2 !== undefined) {
    module18Data.existingCarLoansTotalMonthlyExpenses2 = module18Data.existing_car_loans_own_maintenance_2
      + module18Data.existing_car_loans_own_insurance_2
      + module18Data.existing_car_loans_own_gas_2
      + module18Data.existingCarLoansLoanPaymentsCalcs2;
  }
  return module18Data.existingCarLoansTotalMonthlyExpenses2;
}

export function existingCarLoansTotalMonthlyExpenses3() {
  module18Data.existingCarLoansTotalMonthlyExpenses3 = 0;
  if (module18Data.existing_car_loans_own_maintenance_3 !== undefined && module18Data.existing_car_loans_own_insurance_3 !== undefined && module18Data.existing_car_loans_own_gas_3 !== undefined && module18Data.existingCarLoansLoanPaymentsCalcs3 !== undefined) {
    module18Data.existingCarLoansTotalMonthlyExpenses3 = module18Data.existing_car_loans_own_maintenance_3
      + module18Data.existing_car_loans_own_insurance_3
      + module18Data.existing_car_loans_own_gas_3
      + module18Data.existingCarLoansLoanPaymentsCalcs3;
  }
  return module18Data.existingCarLoansTotalMonthlyExpenses3;
}

export function existingCarLoansTotalMonthlyExpenses4() {
  module18Data.existingCarLoansTotalMonthlyExpenses4 = 0;
  if (module18Data.existing_car_loans_own_maintenance_4 !== undefined && module18Data.existing_car_loans_own_insurance_4 !== undefined && module18Data.existing_car_loans_own_gas_4 !== undefined && module18Data.existingCarLoansLoanPaymentsCalcs4 !== undefined) {
    module18Data.existingCarLoansTotalMonthlyExpenses4 = module18Data.existing_car_loans_own_maintenance_4
      + module18Data.existing_car_loans_own_insurance_4
      + module18Data.existing_car_loans_own_gas_4
      + module18Data.existingCarLoansLoanPaymentsCalcs4;
  }
  return module18Data.existingCarLoansTotalMonthlyExpenses4;
}

// Existing Car Expenses Total Annual Expenses
export function existingCarLoansTotalAnnualExpenses() {
  module18Data.existingCarLoansTotalAnnualExpenses = 0;
  if (module18Data.existingCarLoansTotalMonthlyExpenses !== undefined && module18Data.existingCarLoansTotalMonthlyExpenses !== '') {
    module18Data.existingCarLoansTotalAnnualExpenses = module18Data.existingCarLoansTotalMonthlyExpenses * 12;
  }
  return module18Data.existingCarLoansTotalAnnualExpenses;
}
export function existingCarLoansTotalAnnualExpenses2() {
  module18Data.existingCarLoansTotalAnnualExpenses2 = 0;
  if (module18Data.existingCarLoansTotalMonthlyExpenses2 !== undefined && module18Data.existingCarLoansTotalMonthlyExpenses2 !== '') {
    module18Data.existingCarLoansTotalAnnualExpenses2 = module18Data.existingCarLoansTotalMonthlyExpenses2 * 12;
  }
  return module18Data.existingCarLoansTotalAnnualExpenses2;
}
export function existingCarLoansTotalAnnualExpenses3() {
  module18Data.existingCarLoansTotalAnnualExpenses3 = 0;
  if (module18Data.existingCarLoansTotalMonthlyExpenses3 !== undefined && module18Data.existingCarLoansTotalMonthlyExpenses3 !== '') {
    module18Data.existingCarLoansTotalAnnualExpenses3 = module18Data.existingCarLoansTotalMonthlyExpenses3 * 12;
  }
  return module18Data.existingCarLoansTotalAnnualExpenses3;
}
export function existingCarLoansTotalAnnualExpenses4() {
  module18Data.existingCarLoansTotalAnnualExpenses4 = 0;
  if (module18Data.existingCarLoansTotalMonthlyExpenses4 !== undefined && module18Data.existingCarLoansTotalMonthlyExpenses4 !== '') {
    module18Data.existingCarLoansTotalAnnualExpenses4 = module18Data.existingCarLoansTotalMonthlyExpenses4 * 12;
  }
  return module18Data.existingCarLoansTotalAnnualExpenses4;
}
//Total Loan Payments
export function totalVehicleLoanPayments() {
  module18Data.totalVehicleLoanPayments = 0;
  var carLoanPayments = 0;
  var carLoanPayments1 = 0;
  var carLoanPayments2 = 0;
  var carLoanPayments3 = 0;
  var carLoanPayments4 = 0;
  if (module18Data.existingCarLoansLoanPaymentsYearCalcs !== undefined && module18Data.existingCarLoansLoanPaymentsYearCalcs !== '') {
    carLoanPayments1 = module18Data.existingCarLoansLoanPaymentsYearCalcs;
  }
  if (module18Data.existingCarLoansLoanPaymentsYearCalcs2 !== undefined && module18Data.existingCarLoansLoanPaymentsYearCalcs2 !== '') {
    carLoanPayments2 = module18Data.existingCarLoansLoanPaymentsYearCalcs2;
  }
  if (module18Data.existingCarLoansLoanPaymentsYearCalcs3 !== undefined && module18Data.existingCarLoansLoanPaymentsYearCalcs3 !== '') {
    carLoanPayments3 = module18Data.existingCarLoansLoanPaymentsYearCalcs3;
  }
  if (module18Data.existingCarLoansLoanPaymentsYearCalcs4 !== undefined && module18Data.existingCarLoansLoanPaymentsYearCalcs4 !== '') {
    carLoanPayments4 = module18Data.existingCarLoansLoanPaymentsYearCalcs4;
  }
  carLoanPayments = carLoanPayments1 + carLoanPayments2 + carLoanPayments3 + carLoanPayments4;
  module18Data.totalVehicleLoanPayments = carLoanPayments;
  return module18Data.totalVehicleLoanPayments;
}
//Existing Car calculation for PWI Existing module
export function totalVehicleValues() {
  module18Data.totalVehicleValues = 0;
  var carValue = 0;
  var carValue1 = 0;
  var carValue2 = 0;
  var carValue3 = 0;
  var carValue4 = 0;
  if (module18Data.existing_car_current_value !== undefined) {
    carValue1 = (module18Data.existing_car_current_value);
  }
  if (module18Data.existing_car_current_value_2 !== undefined) {
    carValue2 = (module18Data.existing_car_current_value_2);
  }
  if (module18Data.existing_car_current_value_3 !== undefined) {
    carValue3 = (module18Data.existing_car_current_value_3);
  }
  if (module18Data.existing_car_current_value_4 !== undefined) {
    carValue4 = (module18Data.existing_car_current_value_4);
  }
  carValue = carValue1 + carValue2 + carValue3 + carValue4;
  module18Data.totalVehicleValues = carValue;
  return module18Data.totalVehicleValues;
}
//Total Loan Values
export function totalVehicleLoanValues() {
  module18Data.totalVehicleLoanValues = 0;
  var carLoanBalance = 0;
  var carLoanBalance1 = 0;
  var carLoanBalance2 = 0;
  var carLoanBalance3 = 0;
  var carLoanBalance4 = 0;
  if (module18Data.existing_car_current_loan_balance !== undefined && module18Data.existing_car_current_loan_balance !== '') {
    carLoanBalance1 = (module18Data.existing_car_current_loan_balance);
  }
  if (module18Data.existing_car_current_loan_balance_2 !== undefined && module18Data.existing_car_current_loan_balance_2 !== '') {
    carLoanBalance2 = (module18Data.existing_car_current_loan_balance_2);
  }
  if (module18Data.existing_car_current_loan_balance_3 !== undefined && module18Data.existing_car_current_loan_balance_3 !== '') {
    carLoanBalance3 = (module18Data.existing_car_current_loan_balance_3);
  }
  if (module18Data.existing_car_current_loan_balance_4 !== undefined && module18Data.existing_car_current_loan_balance_4 !== '') {
    carLoanBalance4 = (module18Data.existing_car_current_loan_balance_4);
  }
  carLoanBalance = carLoanBalance1 + carLoanBalance2 + carLoanBalance3 + carLoanBalance4;
  module18Data.totalVehicleLoanValues = carLoanBalance;
  return module18Data.totalVehicleLoanValues;
}
export function totalVehicleLeaseValues() {
  module18Data.totalVehicleLeaseValues = 0;
  var carLeasePayments = 0;
  var carLeasePayments1 = 0;
  var carLeasePayments2 = 0;
  var carLeasePayments3 = 0;
  var carLeasePayments4 = 0;
  if (module18Data.existingCarLoansLoanLeasePaymentCalcs !== undefined && module18Data.existingCarLoansLoanLeasePaymentCalcs !== '') {
    carLeasePayments1 = module18Data.existingCarLoansLoanLeasePaymentCalcs;
  }
  if (module18Data.existingCarLoansLoanLeasePaymentCalcs2 !== undefined && module18Data.existingCarLoansLoanLeasePaymentCalcs2 !== '') {
    carLeasePayments2 = module18Data.existingCarLoansLoanLeasePaymentCalcs2;
  }
  if (module18Data.existingCarLoansLoanLeasePaymentCalcs3 !== undefined && module18Data.existingCarLoansLoanLeasePaymentCalcs3 !== '') {
    carLeasePayments3 = module18Data.existingCarLoansLoanLeasePaymentCalcs3;
  }
  if (module18Data.existingCarLoansLoanLeasePaymentCalcs4 !== undefined && module18Data.existingCarLoansLoanLeasePaymentCalcs4 !== '') {
    carLeasePayments4 = module18Data.existingCarLoansLoanLeasePaymentCalcs4;
  }
  carLeasePayments = carLeasePayments1 + carLeasePayments2 + carLeasePayments3 + carLeasePayments4;
  module18Data.totalVehicleLeaseValues = carLeasePayments;
  return module18Data.totalVehicleLeaseValues;
}

//Lease vehicle Maintenance values
export function totalVehicleMaintenanceValues() {
  module18Data.totalVehicleMaintenanceValues = 0;
  var carLeaseMaintenance = 0;
  var carLeaseMaintenance1 = 0;
  var carLeaseMaintenance2 = 0;
  var carLeaseMaintenance3 = 0;
  var carLeaseMaintenance4 = 0;
  var carLeaseMaintenance5 = 0;
  var carLeaseMaintenance6 = 0;
  var carLeaseMaintenance7 = 0;
  var carLeaseMaintenance8 = 0;

  if (module18Data.existingCarLoansMaintenanceCalcs !== undefined && module18Data.existingCarLoansMaintenanceCalcs !== '') {
    carLeaseMaintenance1 = module18Data.existingCarLoansMaintenanceCalcs;
  }
  if (module18Data.existingCarLoansMaintenanceCalcs2 !== undefined && module18Data.existingCarLoansMaintenanceCalcs2 !== '') {
    carLeaseMaintenance2 = module18Data.existingCarLoansMaintenanceCalcs2;
  }
  if (module18Data.existingCarLoansMaintenanceCalcs3 !== undefined && module18Data.existingCarLoansMaintenanceCalcs3 !== '') {
    carLeaseMaintenance3 = module18Data.existingCarLoansMaintenanceCalcs3;
  }
  if (module18Data.existingCarLoansMaintenanceCalcs4 !== undefined && module18Data.existingCarLoansMaintenanceCalcs4 !== '') {
    carLeaseMaintenance4 = module18Data.existingCarLoansMaintenanceCalcs4;
  }
  //Lease Maintenance
  if (module18Data.existingCarLoansLoanMaintenanceCalcs !== undefined && module18Data.existingCarLoansLoanMaintenanceCalcs !== '') {
    carLeaseMaintenance5 = module18Data.existingCarLoansLoanMaintenanceCalcs;
  }
  if (module18Data.existingCarLoansLoanMaintenanceCalcs2 !== undefined && module18Data.existingCarLoansLoanMaintenanceCalcs2 !== '') {
    carLeaseMaintenance6 = module18Data.existingCarLoansLoanMaintenanceCalcs2;
  }
  if (module18Data.existingCarLoansLoanMaintenanceCalcs3 !== undefined && module18Data.existingCarLoansLoanMaintenanceCalcs3 !== '') {
    carLeaseMaintenance7 = module18Data.existingCarLoansLoanMaintenanceCalcs3;
  }
  if (module18Data.existingCarLoansLoanMaintenanceCalcs4 !== undefined && module18Data.existingCarLoansLoanMaintenanceCalcs4 !== '') {
    carLeaseMaintenance8 = module18Data.existingCarLoansLoanMaintenanceCalcs4;
  }

  carLeaseMaintenance = carLeaseMaintenance1 + carLeaseMaintenance2 + carLeaseMaintenance3 + carLeaseMaintenance4 + carLeaseMaintenance5 + carLeaseMaintenance6 + carLeaseMaintenance7 + carLeaseMaintenance8;
  module18Data.totalVehicleMaintenanceValues = carLeaseMaintenance;
  return module18Data.totalVehicleMaintenanceValues;
}
//Lease vehicle Maintenance values
export function totalVehicleInsuranceValues() {
  module18Data.totalVehicleInsuranceValues = 0;
  var carLeaseInsurance = 0;
  var carLeaseInsurance1 = 0;
  var carLeaseInsurance2 = 0;
  var carLeaseInsurance3 = 0;
  var carLeaseInsurance4 = 0;
  var carLeaseInsurance5 = 0;
  var carLeaseInsurance6 = 0;
  var carLeaseInsurance7 = 0;
  var carLeaseInsurance8 = 0;
  if (module18Data.existingCarLoansInsuranceCalcs !== undefined && module18Data.existingCarLoansInsuranceCalcs !== '') {
    carLeaseInsurance1 = module18Data.existingCarLoansInsuranceCalcs;
  }
  if (module18Data.existingCarLoansInsuranceCalcs2 !== undefined && module18Data.existingCarLoansInsuranceCalcs2 !== '') {
    carLeaseInsurance2 = module18Data.existingCarLoansInsuranceCalcs2;
  }
  if (module18Data.existingCarLoansInsuranceCalcs3 !== undefined && module18Data.existingCarLoansInsuranceCalcs3 !== '') {
    carLeaseInsurance3 = module18Data.existingCarLoansInsuranceCalcs3;
  }
  if (module18Data.existingCarLoansInsuranceCalcs4 !== undefined && module18Data.existingCarLoansInsuranceCalcs4 !== '') {
    carLeaseInsurance4 = module18Data.existingCarLoansInsuranceCalcs4;
  }
  //For Lease
  if (module18Data.existingCarLoansLoanInsuranceCalcs !== undefined && module18Data.existingCarLoansLoanInsuranceCalcs !== '') {
    carLeaseInsurance5 = module18Data.existingCarLoansLoanInsuranceCalcs;
  }
  if (module18Data.existingCarLoansLoanInsuranceCalcs2 !== undefined && module18Data.existingCarLoansLoanInsuranceCalcs2 !== '') {
    carLeaseInsurance6 = module18Data.existingCarLoansLoanInsuranceCalcs2;
  }
  if (module18Data.existingCarLoansLoanInsuranceCalcs3 !== undefined && module18Data.existingCarLoansLoanInsuranceCalcs3 !== '') {
    carLeaseInsurance7 = module18Data.existingCarLoansLoanInsuranceCalcs3;
  }
  if (module18Data.existingCarLoansLoanInsuranceCalcs4 !== undefined && module18Data.existingCarLoansLoanInsuranceCalcs4 !== '') {
    carLeaseInsurance8 = module18Data.existingCarLoansLoanInsuranceCalcs4;
  }
  carLeaseInsurance = carLeaseInsurance1 + carLeaseInsurance2 + carLeaseInsurance3 + carLeaseInsurance4 + carLeaseInsurance5 + carLeaseInsurance6 + carLeaseInsurance7 + carLeaseInsurance8;
  module18Data.totalVehicleInsuranceValues = carLeaseInsurance;
  return module18Data.totalVehicleInsuranceValues;
}

//Lease vehicle Gas values
export function totalVehicleGasValues() {
  module18Data.totalVehicleGasValues = 0;
  var carLeaseGas = 0;
  var carLeaseGas1 = 0;
  var carLeaseGas2 = 0;
  var carLeaseGas3 = 0;
  var carLeaseGas4 = 0;
  var carLeaseGas5 = 0;
  var carLeaseGas6 = 0;
  var carLeaseGas7 = 0;
  var carLeaseGas8 = 0;
  if (module18Data.existingCarLoansGasCalcs !== undefined && module18Data.existingCarLoansGasCalcs !== '') {
    carLeaseGas1 = module18Data.existingCarLoansGasCalcs;
  }
  if (module18Data.existingCarLoansGasCalcs2 !== undefined && module18Data.existingCarLoansGasCalcs2 !== '') {
    carLeaseGas2 = module18Data.existingCarLoansGasCalcs2;
  }
  if (module18Data.existingCarLoansGasCalcs3 !== undefined && module18Data.existingCarLoansGasCalcs3 !== '') {
    carLeaseGas3 = module18Data.existingCarLoansGasCalcs;
  }
  if (module18Data.existingCarLoansGasCalcs4 !== undefined && module18Data.existingCarLoansGasCalcs4 !== '') {
    carLeaseGas4 = module18Data.existingCarLoansGasCalcs4;
  }
  //For Lease
  if (module18Data.existingCarLoansLoanGasCalcs !== undefined && module18Data.existingCarLoansLoanGasCalcs !== '') {
    carLeaseGas5 = module18Data.existingCarLoansLoanGasCalcs;
  }
  if (module18Data.existingCarLoansLoanGasCalcs2 !== undefined && module18Data.existingCarLoansLoanGasCalcs2 !== '') {
    carLeaseGas6 = module18Data.existingCarLoansLoanGasCalcs2;
  }
  if (module18Data.existingCarLoansLoanGasCalcs3 !== undefined && module18Data.existingCarLoansLoanGasCalcs3 !== '') {
    carLeaseGas7 = module18Data.existingCarLoansLoanGasCalcs3;
  }
  if (module18Data.existingCarLoansLoanGasCalcs4 !== undefined && module18Data.existingCarLoansLoanGasCalcs4 !== '') {
    carLeaseGas8 = module18Data.existingCarLoansLoanGasCalcs4;
  }
  carLeaseGas = carLeaseGas1 + carLeaseGas2 + carLeaseGas3 + carLeaseGas4 + carLeaseGas5 + carLeaseGas6 + carLeaseGas7 + carLeaseGas8;
  module18Data.totalVehicleGasValues = carLeaseGas;
  return module18Data.totalVehicleGasValues;
}


// Existing home monthly property taxes calcs
export function existingHomeMonthlyPropertyTaxesCalcs() {
  module18Data.existingHomeMonthlyPropertyTaxesCalcs = 0;
  if (module18Data.existing_home_monthly_property_taxes !== undefined && module18Data.existing_home_monthly_property_taxes !== '') {
    module18Data.existingHomeMonthlyPropertyTaxesCalcs = module18Data.existing_home_monthly_property_taxes * 12;
  }
  return module18Data.existingHomeMonthlyPropertyTaxesCalcs;
}
export function existingHomeMonthlyPropertyTaxesCalcs2() {
  module18Data.existingHomeMonthlyPropertyTaxesCalcs2 = 0;
  if (module18Data.existing_home_monthly_property_taxes_2 !== undefined && module18Data.existing_home_monthly_property_taxes_2 !== '') {
    module18Data.existingHomeMonthlyPropertyTaxesCalcs2 = module18Data.existing_home_monthly_property_taxes_2 * 12;
  }
  return module18Data.existingHomeMonthlyPropertyTaxesCalcs2;
}

// Existing home monthly home owners insurance calcs
export function existingHomeMonthlyHomeownerInsuranceCalcs() {
  module18Data.existingHomeMonthlyHomeownerInsuranceCalcs = 0;
  if (module18Data.existing_home_monthly_homeowner_insurance !== undefined && module18Data.existing_home_monthly_homeowner_insurance !== '') {
    module18Data.existingHomeMonthlyHomeownerInsuranceCalcs = module18Data.existing_home_monthly_homeowner_insurance * 12;
  }
  return module18Data.existingHomeMonthlyHomeownerInsuranceCalcs;
}

export function existingHomeMonthlyHomeownerInsuranceCalcs2() {
  module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 = 0;
  if (module18Data.existing_home_monthly_homeowner_insurance_2 !== undefined && module18Data.existing_home_monthly_homeowner_insurance_2 !== '') {
    module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 = module18Data.existing_home_monthly_homeowner_insurance_2 * 12;
  }
  return module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2;
}

// Existing Home Monthly Private Mortgage Insurance Calcs
export function existingHomeMonthlyPrivateMortgageInsuranceCalcs() {
  module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs = 0;
  if (module18Data.existing_home_monthly_private_mortgage_insurance !== undefined && module18Data.existing_home_monthly_private_mortgage_insurance !== '') {
    module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs = module18Data.existing_home_monthly_private_mortgage_insurance * 12;
  }
  return module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs;
}
export function existingHomeMonthlyPrivateMortgageInsuranceCalcs2() {
  module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 = 0;
  if (module18Data.existing_home_monthly_private_mortgage_insurance_2 !== undefined && module18Data.existing_home_monthly_private_mortgage_insurance_2 !== '') {
    module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 = module18Data.existing_home_monthly_private_mortgage_insurance_2 * 12;
  }
  return module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2;
}

// Existing Home Monthly Monthly Maintenance Calcs
export function existingHomeMonthlyMaintenanceCalcs() {
  module18Data.existingHomeMonthlyMaintenanceCalcs = 0;
  if (module18Data.existing_home_monthly_maintenance !== undefined && module18Data.existing_home_monthly_maintenance !== '') {
    module18Data.existingHomeMonthlyMaintenanceCalcs = module18Data.existing_home_monthly_maintenance * 12;
  }
  return module18Data.existingHomeMonthlyMaintenanceCalcs;
}
export function existingHomeMonthlyMaintenanceCalcs2() {
  module18Data.existingHomeMonthlyMaintenanceCalcs2 = 0;
  if (module18Data.existing_home_monthly_maintenance_2 !== undefined && module18Data.existing_home_monthly_maintenance_2 !== '') {
    module18Data.existingHomeMonthlyMaintenanceCalcs2 = module18Data.existing_home_monthly_maintenance_2 * 12;
  }
  return module18Data.existingHomeMonthlyMaintenanceCalcs2;
}

// Existing Home Monthly Monthly Association Calcs
export function existingHomeMonthlyAssociationFeesCalcs() {
  module18Data.existingHomeMonthlyAssociationFeesCalcs = 0;
  if (module18Data.existing_home_monthly_association_fees !== undefined && module18Data.existing_home_monthly_association_fees !== '') {
    module18Data.existingHomeMonthlyAssociationFeesCalcs = module18Data.existing_home_monthly_association_fees * 12;
  }
  return module18Data.existingHomeMonthlyAssociationFeesCalcs;
}
export function existingHomeMonthlyAssociationFeesCalcs2() {
  module18Data.existingHomeMonthlyAssociationFeesCalcs2 = 0;
  if (module18Data.existing_home_monthly_association_fees_2 !== undefined && module18Data.existing_home_monthly_association_fees_2 !== '') {
    module18Data.existingHomeMonthlyAssociationFeesCalcs2 = module18Data.existing_home_monthly_association_fees_2 * 12;
  }
  return module18Data.existingHomeMonthlyAssociationFeesCalcs2;
}

// Existing Home Monthly Monthly Utilities Calcs
export function existingHomeMonthlyUtilitiesCalcs() {
  module18Data.existingHomeMonthlyUtilitiesCalcs = 0;
  if (module18Data.existing_home_monthly_utilities !== undefined && module18Data.existing_home_monthly_utilities !== '') {
    module18Data.existingHomeMonthlyUtilitiesCalcs = module18Data.existing_home_monthly_utilities * 12;
  }
  return module18Data.existingHomeMonthlyUtilitiesCalcs;
}
export function existingHomeMonthlyUtilitiesCalcs2() {
  module18Data.existingHomeMonthlyUtilitiesCalcs2 = 0;
  if (module18Data.existing_home_monthly_utilities_2 !== undefined && module18Data.existing_home_monthly_utilities_2 !== '') {
    module18Data.existingHomeMonthlyUtilitiesCalcs2 = module18Data.existing_home_monthly_utilities_2 * 12;
  }
  return module18Data.existingHomeMonthlyUtilitiesCalcs2;
}

// Mortgage Payment (Principal & Interest)
export function mortgagePaymentPrincipalInterest() {
  module18Data.mortgagePaymentPrincipalInterest = 0;
  if (module18Data.existingHomeMortgageMonthlyPayment !== undefined && module18Data.existingHomeMortgageMonthlyPayment !== '') {
    module18Data.mortgagePaymentPrincipalInterest = module18Data.existingHomeMortgageMonthlyPayment;
  }
  return module18Data.mortgagePaymentPrincipalInterest;
}
//Mortgage Payment (Principal & Interest) Annual
export function mortgagePaymentPrincipalInterestAnnual() {
  module18Data.mortgagePaymentPrincipalInterestAnnual = 0;
  if (module18Data.existingHomeMortgageMonthlyPayment !== undefined) {
    module18Data.mortgagePaymentPrincipalInterestAnnual = module18Data.existingHomeMortgageMonthlyPayment * 12;
  }
  return module18Data.mortgagePaymentPrincipalInterestAnnual;
}
export function mortgagePaymentPrincipalInterest2() {
  module18Data.mortgagePaymentPrincipalInterest2 = 0;
  if (module18Data.existingHomeMortgageMonthlyPayment2 !== undefined && module18Data.existingHomeMortgageMonthlyPayment2 !== '') {
    module18Data.mortgagePaymentPrincipalInterest2 = module18Data.existingHomeMortgageMonthlyPayment2;
  }
  return module18Data.mortgagePaymentPrincipalInterest2;
}
//Mortgage Payment (Principal & Interest) Annual
export function mortgagePaymentPrincipalInterest2Annual() {
  module18Data.mortgagePaymentPrincipalInterest2Annual = 0;
  if (module18Data.mortgagePaymentPrincipalInterest2 !== undefined) {
    module18Data.mortgagePaymentPrincipalInterest2Annual = module18Data.mortgagePaymentPrincipalInterest2 * 12;
  }
  return module18Data.mortgagePaymentPrincipalInterest2Annual;
}


// Total Monthly Expenses
export function mortgagetotalMonthlyExpenses() {
  module18Data.mortgagetotalMonthlyExpenses = 0;
  if (module18Data.existing_home_monthly_property_taxes !== undefined
    && module18Data.existing_home_monthly_homeowner_insurance !== undefined
    && module18Data.existing_home_monthly_private_mortgage_insurance !== undefined
    && module18Data.existing_home_monthly_maintenance !== undefined
    && module18Data.existing_home_monthly_association_fees !== undefined
    && module18Data.existing_home_monthly_utilities !== undefined
    && module18Data.mortgagePaymentPrincipalInterest !== undefined) {
    module18Data.mortgagetotalMonthlyExpenses = module18Data.existing_home_monthly_property_taxes
      + module18Data.existing_home_monthly_homeowner_insurance
      + module18Data.existing_home_monthly_private_mortgage_insurance
      + module18Data.existing_home_monthly_maintenance
      + module18Data.existing_home_monthly_association_fees
      + module18Data.existing_home_monthly_utilities
      + module18Data.mortgagePaymentPrincipalInterest;
  }
  return module18Data.mortgagetotalMonthlyExpenses;
}

//Total Annual Expenses
export function mortgagetotalMonthlyExpensesAnnual() {
  module18Data.mortgagetotalMonthlyExpensesAnnual = 0;
  if (module18Data.existingHomeMonthlyPropertyTaxesCalcs !== undefined &&
    module18Data.existingHomeMonthlyHomeownerInsuranceCalcs !== undefined &&
    module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs !== undefined &&
    module18Data.existingHomeMonthlyMaintenanceCalcs !== undefined &&
    module18Data.existingHomeMonthlyAssociationFeesCalcs !== undefined &&
    module18Data.existingHomeMonthlyUtilitiesCalcs !== undefined &&
    module18Data.mortgagePaymentPrincipalInterestAnnual !== undefined) {
    module18Data.mortgagetotalMonthlyExpensesAnnual = module18Data.existingHomeMonthlyPropertyTaxesCalcs +
      module18Data.existingHomeMonthlyHomeownerInsuranceCalcs +
      module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs +
      module18Data.existingHomeMonthlyMaintenanceCalcs +
      module18Data.existingHomeMonthlyAssociationFeesCalcs +
      module18Data.existingHomeMonthlyUtilitiesCalcs +
      module18Data.mortgagePaymentPrincipalInterestAnnual;
  }
  return module18Data.mortgagetotalMonthlyExpensesAnnual;
}

export function mortgagetotalMonthlyExpenses2() {
  module18Data.mortgagetotalMonthlyExpenses2 = 0;
  if (module18Data.existing_home_monthly_property_taxes_2 !== undefined
    && module18Data.existing_home_monthly_homeowner_insurance_2 !== undefined
    && module18Data.existing_home_monthly_private_mortgage_insurance_2 !== undefined
    && module18Data.existing_home_monthly_maintenance_2 !== undefined
    && module18Data.existing_home_monthly_association_fees_2 !== undefined
    && module18Data.existing_home_monthly_utilities_2 !== undefined
    && module18Data.mortgagePaymentPrincipalInterest2 !== undefined) {
    module18Data.mortgagetotalMonthlyExpenses2 = module18Data.existing_home_monthly_property_taxes_2
      + module18Data.existing_home_monthly_homeowner_insurance_2
      + module18Data.existing_home_monthly_private_mortgage_insurance_2
      + module18Data.existing_home_monthly_maintenance_2
      + module18Data.existing_home_monthly_association_fees_2
      + module18Data.existing_home_monthly_utilities_2
      + module18Data.mortgagePaymentPrincipalInterest2;
  }
  return module18Data.mortgagetotalMonthlyExpenses2;
}

//Total Annual Expenses Annual
export function mortgagetotalMonthlyExpenses2Annual() {
  module18Data.mortgagetotalMonthlyExpenses2Annual = 0;
  if (module18Data.existingHomeMonthlyPropertyTaxesCalcs2 !== undefined &&
    module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 !== undefined &&
    module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 !== undefined &&
    module18Data.existingHomeMonthlyMaintenanceCalcs2 !== undefined &&
    module18Data.existingHomeMonthlyAssociationFeesCalcs2 !== undefined &&
    module18Data.existingHomeMonthlyUtilitiesCalcs2 !== undefined &&
    module18Data.mortgagePaymentPrincipalInterest2Annual !== undefined) {
    module18Data.mortgagetotalMonthlyExpenses2Annual = module18Data.existingHomeMonthlyPropertyTaxesCalcs2 +
      module18Data.existingHomeMonthlyHomeownerInsuranceCalcs2 +
      module18Data.existingHomeMonthlyPrivateMortgageInsuranceCalcs2 +
      module18Data.existingHomeMonthlyMaintenanceCalcs2 +
      module18Data.existingHomeMonthlyAssociationFeesCalcs2 +
      module18Data.existingHomeMonthlyUtilitiesCalcs2 +
      module18Data.mortgagePaymentPrincipalInterest2Annual;
  }
  return module18Data.mortgagetotalMonthlyExpenses2Annual;
}

// Existing Home Mortgage Current Mortgage Balance Calcs
export function existigHomeCurrentMortgageBalanceCalcs() {
  let existigHomeCurrent = '';
  module18Data.existigHomeCurrentMortgageBalanceCalcs = '';
  if (module18Data.existig_home_current_mortgage_balance !== undefined && module18Data.existig_home_current_mortgage_balance !== '' && module18Data.existig_home_current_value !== undefined && module18Data.existig_home_current_value > 0) {
    existigHomeCurrent = (1 - (module18Data.existig_home_current_mortgage_balance / module18Data.existig_home_current_value)) * 100;
    if (existigHomeCurrent >= 0) {
      existigHomeCurrent = parseFloat(existigHomeCurrent).toFixed(1) + '% Home Equity';
    } else {
      existigHomeCurrent = -(parseFloat(existigHomeCurrent)).toFixed(1);
      existigHomeCurrent = '(' + existigHomeCurrent + ')% Home Equity';
    }
  }
  module18Data.existigHomeCurrentMortgageBalanceCalcs = existigHomeCurrent;
  return module18Data.existigHomeCurrentMortgageBalanceCalcs;
}

// Existing Home Mortgage Monthly Payment
export function existingHomeMortgageMonthlyPayment() {
  module18Data.existingHomeMortgageMonthlyPayment = 0;
  if (existingHomeMortgageData !== undefined && existingHomeMortgageData !== ''
    && existingHomeMortgageData['Existing Home Data'] !== undefined
    && existingHomeMortgageData['Existing Home Data'] !== ''
    && existingHomeMortgageData['Existing Home Data']['Original Payment'] !== undefined
    && existingHomeMortgageData['Existing Home Data']['Original Payment'] !== '') {
    module18Data.existingHomeMortgageMonthlyPayment = existingHomeMortgageData['Existing Home Data']['Original Payment'];
  }
  return module18Data.existingHomeMortgageMonthlyPayment;
}
export function existingHomeMortgageMonthlyPayment2() {
  module18Data.existingHomeMortgageMonthlyPayment2 = 0;
  if (existingHomeMortgageData !== undefined && existingHomeMortgageData !== ''
    && existingHomeMortgageData['Existing Home Data'] !== undefined
    && existingHomeMortgageData['Existing Home Data'] !== ''
    && existingHomeMortgageData['Existing Home Data']['Original Payment2'] !== undefined
    && existingHomeMortgageData['Existing Home Data']['Original Payment2'] !== '') {
    module18Data.existingHomeMortgageMonthlyPayment2 = existingHomeMortgageData['Existing Home Data']['Original Payment2'];
  }
  return module18Data.existingHomeMortgageMonthlyPayment2;
}
// Existing Home Mortgage Annual Payment
export function existingHomeMortgageAnnualPayment() {
  module18Data.existingHomeMortgageAnnualPayment = 0;
  if (module18Data.existingHomeMortgageMonthlyPayment !== undefined && module18Data.existingHomeMortgageMonthlyPayment !== '' && module18Data.existig_home_mortgage_months_to_maturity !== undefined && module18Data.existig_home_mortgage_months_to_maturity > 12) {
    module18Data.existingHomeMortgageAnnualPayment = module18Data.existingHomeMortgageMonthlyPayment * 12;
  } else if (module18Data.existig_home_mortgage_months_to_maturity !== undefined && module18Data.existig_home_mortgage_months_to_maturity !== '') {
    module18Data.existingHomeMortgageAnnualPayment = module18Data.existingHomeMortgageMonthlyPayment * module18Data.existig_home_mortgage_months_to_maturity;
  }
  return module18Data.existingHomeMortgageAnnualPayment;
}
export function existingHomeMortgageAnnualPayment2() {
  module18Data.existingHomeMortgageAnnualPayment2 = 0;
  if (module18Data.existingHomeMortgageMonthlyPayment2 !== undefined && module18Data.existingHomeMortgageMonthlyPayment2 !== '' && module18Data.existig_home_mortgage_months_to_maturity_2 !== undefined && module18Data.existig_home_mortgage_months_to_maturity_2 > 12) {
    module18Data.existingHomeMortgageAnnualPayment2 = module18Data.existingHomeMortgageMonthlyPayment2 * 12;
  } else {
    if (module18Data.existig_home_mortgage_months_to_maturity_2 !== undefined && module18Data.existig_home_mortgage_months_to_maturity_2 !== '') {
      module18Data.existingHomeMortgageAnnualPayment2 = module18Data.existingHomeMortgageMonthlyPayment2 * module18Data.existig_home_mortgage_months_to_maturity_2;
    } else {
      module18Data.existingHomeMortgageAnnualPayment2 = 0;
    }
  }
  return module18Data.existingHomeMortgageAnnualPayment2;
}

// Existing Home Mortgage Total Interest
export function existingHomeMortgageTotalInterest() {
  module18Data.existingHomeMortgageTotalInterest = 0;
  if (existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Total Interest Paid'] !== undefined && existingHomeMortgageData['Total Interest Paid'] !== '') {
    module18Data.existingHomeMortgageTotalInterest = existingHomeMortgageData['Total Interest Paid'];
  }
  return module18Data.existingHomeMortgageTotalInterest;
}
export function existingHomeMortgageTotalInterest2() {
  module18Data.existingHomeMortgageTotalInterest2 = 0;
  if (existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Total Interest Paid2'] !== undefined && existingHomeMortgageData['Total Interest Paid2'] !== '') {
    module18Data.existingHomeMortgageTotalInterest2 = existingHomeMortgageData['Total Interest Paid2'];
  }
  return module18Data.existingHomeMortgageTotalInterest2;
}

// Existing Home Mortgage Total Interest
export function existingHomeMortgageTotalPrincipalInterest() {
  module18Data.existingHomeMortgageTotalPrincipalInterest = 0;
  if (existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Total Paid'] !== undefined && existingHomeMortgageData['Total Paid'] !== '') {
    module18Data.existingHomeMortgageTotalPrincipalInterest = existingHomeMortgageData['Total Paid'];
  }
  return module18Data.existingHomeMortgageTotalPrincipalInterest;
}
export function existingHomeMortgageTotalPrincipalInterest2() {
  module18Data.existingHomeMortgageTotalPrincipalInterest2 = 0;
  if (existingHomeMortgageData !== undefined && existingHomeMortgageData !== '' && existingHomeMortgageData['Total Paid2'] !== undefined && existingHomeMortgageData['Total Paid2'] !== '') {
    module18Data.existingHomeMortgageTotalPrincipalInterest2 = existingHomeMortgageData['Total Paid2'];
  }
  return module18Data.existingHomeMortgageTotalPrincipalInterest2;
}

// Existing Home Sales Price Year
export function existingHomeSalesPriceYear() {
  module18Data.existingHomeSalesPriceYear = 0;
  if (module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Buying Expenses'] !== undefined && existingHomeData['Buying Expenses']['Selling Price'] !== '') {
    module18Data.existingHomeSalesPriceYear = existingHomeData['Buying Expenses']['Selling Price'];
  }
  return module18Data.existingHomeSalesPriceYear;
}

export function existingHomeSalesPriceYear2() {
  module18Data.existingHomeSalesPriceYear2 = 0;
  if (module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 !== '' && existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Buying Expenses'] !== undefined && existingHomeData['Buying Expenses']['Selling Price2'] !== '') {
    module18Data.existingHomeSalesPriceYear2 = existingHomeData['Buying Expenses']['Selling Price2'];
  }
  return module18Data.existingHomeSalesPriceYear2;
}

// Sale Year Calcs
export function saleYearCalcs() {
  module18Data.saleYearCalcs = 0;
  if (module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year > 0 && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    module18Data.saleYearCalcs = `${parseInt(module18Data.existing_home_sale_year, 10) - parseInt(module1Data.start_year, 10) + 1} years`;
  }
  return module18Data.saleYearCalcs;
}
export function saleYearCalcs2() {
  module18Data.saleYearCalcs2 = 0;
  if (module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 > 0 && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    module18Data.saleYearCalcs2 = `${parseInt(module18Data.existing_home_sale_year_2, 10) - parseInt(module1Data.start_year, 10) + 1} years`;
  }
  return module18Data.saleYearCalcs2;
}

// Selling Costs Calcs
export function existigHomeSellingCostsCalcs() {
  module18Data.existigHomeSellingCostsCalcs = 0;
  if (module18Data.existing_home_selling_costs !== undefined && module18Data.existing_home_selling_costs !== '' && module18Data.existingHomeSalesPriceYear !== undefined && module18Data.existingHomeSalesPriceYear !== '') {
    module18Data.existigHomeSellingCostsCalcs = (module18Data.existingHomeSalesPriceYear / 100) * module18Data.existing_home_selling_costs;
  }
  return module18Data.existigHomeSellingCostsCalcs;
}
export function existigHomeSellingCostsCalcs2() {
  module18Data.existigHomeSellingCostsCalcs2 = 0;
  if (module18Data.existing_home_selling_costs_2 !== undefined && module18Data.existing_home_selling_costs_2 !== '' && module18Data.existingHomeSalesPriceYear2 !== undefined && module18Data.existingHomeSalesPriceYear2 !== '') {
    module18Data.existigHomeSellingCostsCalcs2 = (module18Data.existingHomeSalesPriceYear2 / 100) * module18Data.existing_home_selling_costs_2;
  }
  return module18Data.existigHomeSellingCostsCalcs2;
}

// Mortgage Balance Remaining at Sale
export function existingHomeMortgageBalance() {
  module18Data.existingHomeMortgageBalance = 0;
  if (existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Buying Expenses'] !== undefined && existingHomeData['Buying Expenses']['Mortgage Balance Remaining'] !== undefined && existingHomeData['Buying Expenses']['Mortgage Balance Remaining'] !== '') {
    module18Data.existingHomeMortgageBalance = existingHomeData['Buying Expenses']['Mortgage Balance Remaining'];
  }
  return module18Data.existingHomeMortgageBalance;
}
export function existingHomeMortgageBalance2() {
  module18Data.existingHomeMortgageBalance2 = 0;
  if (existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Buying Expenses'] !== undefined && existingHomeData['Buying Expenses']['Mortgage Balance Remaining2'] !== undefined && existingHomeData['Buying Expenses']['Mortgage Balance Remaining2'] !== '') {
    module18Data.existingHomeMortgageBalance2 = existingHomeData['Buying Expenses']['Mortgage Balance Remaining2'];
  }
  return module18Data.existingHomeMortgageBalance2;
}

// Existing Home Net Cash Flow from Sale (before taxes)
export function existingHomeNetCashFlowFromSale() {
  module18Data.existingHomeNetCashFlowFromSale = 0;
  if (existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Buying Expenses'] !== undefined && existingHomeData['Buying Expenses']['Net Cash Flow from Sale'] !== undefined && existingHomeData['Buying Expenses']['Net Cash Flow from Sale'] !== '') {
    module18Data.existingHomeNetCashFlowFromSale = existingHomeData['Buying Expenses']['Net Cash Flow from Sale'];
  }
  return module18Data.existingHomeNetCashFlowFromSale;
}
export function existingHomeNetCashFlowFromSale2() {
  module18Data.existingHomeNetCashFlowFromSale2 = 0;
  if (existingHomeData !== undefined && existingHomeData !== '' && existingHomeData['Buying Expenses'] !== undefined && existingHomeData['Buying Expenses']['Net Cash Flow from Sale2'] !== undefined && existingHomeData['Buying Expenses']['Net Cash Flow from Sale2'] !== '') {
    module18Data.existingHomeNetCashFlowFromSale2 = existingHomeData['Buying Expenses']['Net Cash Flow from Sale2'];
  }
  return module18Data.existingHomeNetCashFlowFromSale2;
}

// Existing Home Sale Selling Price
export function existingHomeSaleSellingPrice() {
  module18Data.existingHomeSaleSellingPrice = 0;
  if (module18Data.existingHomeSalesPriceYear !== undefined && module18Data.existingHomeSalesPriceYear !== '') {
    module18Data.existingHomeSaleSellingPrice = module18Data.existingHomeSalesPriceYear;
  }
  return module18Data.existingHomeSaleSellingPrice;
}
export function existingHomeSaleSellingPrice2() {
  module18Data.existingHomeSaleSellingPrice2 = 0;
  if (module18Data.existingHomeSalesPriceYear2 !== undefined && module18Data.existingHomeSalesPriceYear2 !== '') {
    module18Data.existingHomeSaleSellingPrice2 = module18Data.existingHomeSalesPriceYear2;
  }
  return module18Data.existingHomeSaleSellingPrice2;
}

// Selling Costs
export function sellingCosts() {
  module18Data.sellingCosts = 0;
  if (module18Data.existigHomeSellingCostsCalcs !== undefined && module18Data.existigHomeSellingCostsCalcs !== '') {
    module18Data.sellingCosts = module18Data.existigHomeSellingCostsCalcs;
  }
  return module18Data.sellingCosts;
}
export function sellingCosts2() {
  module18Data.sellingCosts2 = 0;
  if (module18Data.existigHomeSellingCostsCalcs2 !== undefined && module18Data.existigHomeSellingCostsCalcs2 !== '') {
    module18Data.sellingCosts2 = module18Data.existigHomeSellingCostsCalcs2;
  }
  return module18Data.sellingCosts2;
}

// Net Selling Price
export function netSellingPrice() {
  module18Data.netSellingPrice = 0;
  if (module18Data.existingHomeSaleSellingPrice !== undefined && module18Data.existingHomeSaleSellingPrice !== '' && module18Data.sellingCosts !== undefined && module18Data.sellingCosts !== '') {
    module18Data.netSellingPrice = module18Data.existingHomeSaleSellingPrice - module18Data.sellingCosts;
  }
  return module18Data.netSellingPrice;
}
export function netSellingPrice2() {
  module18Data.netSellingPrice2 = 0;
  if (module18Data.existingHomeSaleSellingPrice2 !== undefined && module18Data.existingHomeSaleSellingPrice2 !== '' && module18Data.sellingCosts2 !== undefined && module18Data.sellingCosts2 !== '') {
    module18Data.netSellingPrice2 = module18Data.existingHomeSaleSellingPrice2 - module18Data.sellingCosts2;
  }
  return module18Data.netSellingPrice2;
}

// Basis Value
export function existingLoansBasis() {
  module18Data.existingLoansBasis = 0;
  if (module18Data.existig_home_basis_costs !== undefined && module18Data.existig_home_basis_costs !== '') {
    module18Data.existingLoansBasis = module18Data.existig_home_basis_costs;
  }
  return module18Data.existingLoansBasis;
}
export function existingLoansBasis2() {
  module18Data.existingLoansBasis2 = 0;
  if (module18Data.existig_home_basis_costs_2 !== undefined && module18Data.existig_home_basis_costs_2 !== '') {
    module18Data.existingLoansBasis2 = module18Data.existig_home_basis_costs_2;
  }
  return module18Data.existingLoansBasis2;
}

// Gain on Sale
export function gainOnSale() {
  module18Data.gainOnSale = 0;
  if (module18Data.netSellingPrice !== undefined && module18Data.netSellingPrice !== '' && module18Data.existingLoansBasis !== undefined && module18Data.existingLoansBasis !== '') {
    module18Data.gainOnSale = module18Data.netSellingPrice - module18Data.existingLoansBasis;
  }
  return module18Data.gainOnSale;
}
export function gainOnSale2() {
  module18Data.gainOnSale2 = 0;
  if (module18Data.netSellingPrice2 !== undefined && module18Data.netSellingPrice2 !== '' && module18Data.existingLoansBasis2 !== undefined && module18Data.existingLoansBasis2 !== '') {
    module18Data.gainOnSale2 = module18Data.netSellingPrice2 - module18Data.existingLoansBasis2;
  }
  return module18Data.gainOnSale2;
}

export function taxableGainUp2() {
  module18Data.taxableGainUp2 = 0;
  if (module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 !== '' && module18Data.primary_residence_2 !== undefined && module18Data.primary_residence_2 === 'Yes' && module18Data.married_status_2 !== undefined && module18Data.married_status_2 === 'Yes' && databaseData !== undefined && databaseData !== '' && databaseData['Data']['Home Sale - Single Profit Cap'] !== undefined) {
    module18Data.taxableGainUp2 = databaseData['Data']['Home Sale - Married Profit Cap'];
  } else {
    if (module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 !== '' && module18Data.primary_residence_2 !== undefined && module18Data.primary_residence_2 === 'Yes' && module18Data.married_status_2 !== undefined && module18Data.married_status_2 === 'No' && databaseData !== undefined && databaseData !== '' && databaseData['Data']['Home Sale - Single Profit Cap'] !== undefined) {
      module18Data.taxableGainUp2 = databaseData['Data']['Home Sale - Single Profit Cap'];
    } else {
      if (module18Data.existing_home_sale_year_2 !== undefined && module18Data.existing_home_sale_year_2 !== '' && module18Data.primary_residence_2 !== undefined && module18Data.primary_residence_2 === 'No' && module18Data.married_status_2 !== undefined && module18Data.married_status_2 === 'No') {
        module18Data.taxableGainUp2 = 0;
      } else {
        module18Data.taxableGainUp2 = 0;
      }
    }
  }
  return module18Data.taxableGainUp2;
}

// Debt Income Ration for Existing home
export function existingHomedebtIncomeRatio() {
  module18Data.existingHomedebtIncomeRatio = 0;
  let totalInterestExpensesDebt5 = 0;
  if (incomeStatementData !== undefined && incomeStatementData[module1Data.start_year] !== undefined
    && incomeStatementData[module1Data.start_year]['Total Interest Expenses'] !== '') {
    totalInterestExpensesDebt5 = incomeStatementData[module1Data.start_year]['Total Interest Expenses'];
  }

  if (incomeStatementData[module1Data.start_year] !== undefined
    && incomeStatementData[module1Data.start_year]['Total Income'] !== undefined
    && incomeStatementData[module1Data.start_year]['Total Income'] !== '' && incomeStatementData[module1Data.start_year]['Total Income'] !== 0) {
    module18Data.existingHomedebtIncomeRatio = (-(parseFloat(totalInterestExpensesDebt5)) / parseFloat(incomeStatementData[module1Data.start_year]['Total Income'])) * 100;
  }
  return module18Data.existingHomedebtIncomeRatio;
}

// Taxable Gainup Label
export function taxableGainUpLabel() {
  let gainUpLabel = '';
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && module18Data.existing_home_sale_year >= module10Data.marriege_year) {
    gainUpLabel = 'Taxable Gain (Up to $500,000 is Tax-Free if Married)';
  } else {
    gainUpLabel = 'Taxable Gain (Up to $250,000 is Tax-Free if Single)';
  }
  module18Data.gainUpLabel = gainUpLabel;
  return module18Data.gainUpLabel;
}

export function incomeNeededExistingStudentLoans() {
  module18Data.incomeNeededExistingStudentLoans = 0;
  if (module18Data.existingStudentLoansSummaryMonthlyPayment !== undefined) {
    module18Data.incomeNeededExistingStudentLoans = (module18Data.existingStudentLoansSummaryMonthlyPayment * 12 * 100) / 12.5;
  }
  return module18Data.incomeNeededExistingStudentLoans;
}

// Taxable Gainup Value
/*export function taxableGainUp() {
  module18Data.taxableGainUp = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== '' && module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && module18Data.existing_home_sale_year >= module10Data.marriege_year) {
    if (module18Data.gainOnSale !== undefined && module18Data.gainOnSale !== '' && databaseData !== undefined && databaseData !== '' && databaseData['Home Sale - Married Profit Cap'] !== undefined && module18Data.gainOnSale > databaseData['Home Sale - Married Profit Cap']) {
      module18Data.taxableGainUp = module18Data.gainOnSale - databaseData['Home Sale - Married Profit Cap'];
    } else {
      module18Data.taxableGainUp = 0;
    }
  } else if (module18Data.gainOnSale !== undefined && module18Data.gainOnSale !== '' && databaseData !== undefined && databaseData !== '' && databaseData['Home Sale - Single Profit Cap'] !== undefined && module18Data.gainOnSale > databaseData['Home Sale - Single Profit Cap']) {
    module18Data.taxableGainUp = module18Data.gainOnSale - databaseData['Home Sale - Single Profit Cap'];
  } else {
    module18Data.taxableGainUp = 0;
  }
  return module18Data.taxableGainUp;
}*/

export function taxableGainUp() {
  module18Data.taxableGainUp = 0;
  if (module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && module18Data.primary_residence !== undefined && module18Data.primary_residence === 'Yes' && module18Data.married_status !== undefined && module18Data.married_status === 'Yes' && databaseData !== undefined && databaseData !== '' && databaseData['Data']['Home Sale - Single Profit Cap'] !== undefined) {
    module18Data.taxableGainUp = databaseData['Data']['Home Sale - Married Profit Cap'];
  } else {
    if (module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && module18Data.primary_residence !== undefined && module18Data.primary_residence === 'Yes' && module18Data.married_status !== undefined && module18Data.married_status === 'No' && databaseData !== undefined && databaseData !== '' && databaseData['Data']['Home Sale - Single Profit Cap'] !== undefined) {
      module18Data.taxableGainUp = databaseData['Data']['Home Sale - Single Profit Cap'];
    } else {
      if (module18Data.existing_home_sale_year !== undefined && module18Data.existing_home_sale_year !== '' && module18Data.primary_residence !== undefined && module18Data.primary_residence === 'No' && module18Data.married_status !== undefined && module18Data.married_status === 'No') {
        module18Data.taxableGainUp = 0;
      } else {
        module18Data.taxableGainUp = 0;
      }
    }
  }
  return module18Data.taxableGainUp;
}

export function taxableGainUp1() {
  module18Data.taxableGainUp1 = 0;
  let differnece = 0;
  if (module18Data.gainOnSale !== undefined && module18Data.gainOnSale !== '' && module18Data.taxableGainUp !== undefined && module18Data.taxableGainUp !== '') {
    differnece = module18Data.gainOnSale - module18Data.taxableGainUp;
  }
  if (differnece < 0) {
    module18Data.taxableGainUp1 = 0;
  } else {
    module18Data.taxableGainUp1 = differnece;
  }
  return module18Data.taxableGainUp1;
}

// Tax On Gain Value
export function taxOnGain() {
  module18Data.taxOnGain = 0;
  if (module18Data.taxableGainUp1 !== undefined && module18Data.taxableGainUp1 > 0) {
    module18Data.taxOnGain = (module18Data.taxableGainUp1 / 100) * 15;
  }
  return module18Data.taxOnGain;
}
export function taxOnGain2() {
  module18Data.taxOnGain2 = 0;
  if (module18Data.taxableGainUp12 !== undefined && module18Data.taxableGainUp12 > 0) {
    module18Data.taxOnGain2 = (module18Data.taxableGainUp12 / 100) * 15;
  }
  return module18Data.taxOnGain2;
}
export function taxableGainUp12() {
  module18Data.taxableGainUp12 = 0;
  let differnece = 0;
  if (module18Data.gainOnSale2 !== undefined && module18Data.gainOnSale2 !== '' && module18Data.taxableGainUp2 !== undefined && module18Data.taxableGainUp2 !== '') {
    differnece = module18Data.gainOnSale2 - module18Data.taxableGainUp2;
  }
  if (differnece < 0) {
    module18Data.taxableGainUp12 = 0;
  } else {
    module18Data.taxableGainUp12 = differnece;
  }
  return module18Data.taxableGainUp12;
}
// Tax On Gain Value
export function netGainOnSale() {
  module18Data.netGainOnSale = 0;
  if (module18Data.gainOnSale !== undefined && module18Data.gainOnSale !== '' && module18Data.taxOnGain !== undefined) {
    module18Data.netGainOnSale = module18Data.gainOnSale - module18Data.taxOnGain;
  }
  return module18Data.netGainOnSale;
}

export function currentNetWorthInvestments() {
  module18Data.currentNetWorthInvestments = 0;
  if (module18Data.existingTotalInvestments !== undefined && module18Data.existingTotalOtherInvestments !== undefined) {
    module18Data.currentNetWorthInvestments = module18Data.existingTotalInvestments + module18Data.existingTotalOtherInvestments;
  }
  return module18Data.currentNetWorthInvestments;
}
export function currentNetWorthRetirementAccounts() {
  module18Data.currentNetWorthRetirementAccounts = 0;
  if (module18Data.existingTotalRetirementAccounts !== undefined && module18Data.existingTotalRetirementAccounts !== '') {
    module18Data.currentNetWorthRetirementAccounts = module18Data.existingTotalRetirementAccounts;
  }
  return module18Data.currentNetWorthRetirementAccounts;
}
export function currentNetWorthLifeInsuranceCash() {
  module18Data.currentNetWorthLifeInsuranceCash = 0;
  if (module18Data.existing_cash_value_life_insurance_policies !== undefined && module18Data.existing_cash_value_life_insurance_policies !== '') {
    module18Data.currentNetWorthLifeInsuranceCash = module18Data.existing_cash_value_life_insurance_policies;
  }
  return module18Data.currentNetWorthLifeInsuranceCash;
}
export function currentNetWorthBusiness() {
  module18Data.currentNetWorthBusiness = 0;
  if (module18Data.existing_value_of_business !== undefined && module18Data.existing_value_of_business !== '') {
    module18Data.currentNetWorthBusiness = module18Data.existing_value_of_business;
  }
  return module18Data.currentNetWorthBusiness;
}
export function currentNetWorthOtherValuables() {
  module18Data.currentNetWorthOtherValuables = 0;
  if (module18Data.existingTotalValuables !== undefined && module18Data.existingTotalValuables !== '') {
    module18Data.currentNetWorthOtherValuables = module18Data.existingTotalValuables;
  }
  return module18Data.currentNetWorthOtherValuables;
}

export function currentNetWorthVehicles() {
  module18Data.currentNetWorthVehicles = 0;
  let vehicleValue = 0;
  let vehicleValue1 = 0;
  let vehicleValue2 = 0;
  let vehicleValue3 = 0;
  let vehicleValue4 = 0;

  if (module18Data.existing_car_current_value !== undefined && module18Data.existing_car_current_value !== '') {
    vehicleValue1 = module18Data.existing_car_current_value;
  }
  if (module18Data.existing_car_current_value_2 !== undefined && module18Data.existing_car_current_value_2 !== '') {
    vehicleValue2 = module18Data.existing_car_current_value_2;
  }
  if (module18Data.existing_car_current_value_3 !== undefined && module18Data.existing_car_current_value_3 !== '') {
    vehicleValue3 = module18Data.existing_car_current_value_3;
  }
  if (module18Data.existing_car_current_value_4 !== undefined && module18Data.existing_car_current_value_4 !== '') {
    vehicleValue4 = module18Data.existing_car_current_value_4;
  }

  vehicleValue = vehicleValue1 + vehicleValue2 + vehicleValue3 + vehicleValue4;
  module18Data.currentNetWorthVehicles = vehicleValue;
  return module18Data.currentNetWorthVehicles;
}

export function currentNetWorthHomes() {
  module18Data.currentNetWorthHomes = 0;
  let homeValue = 0;
  let homeValue1 = 0;
  let homeValue2 = 0;

  if (module18Data.existig_home_current_value !== undefined && module18Data.existig_home_current_value !== '') {
    homeValue1 = module18Data.existig_home_current_value;
  }
  if (module18Data.existig_home_current_value_2 !== undefined && module18Data.existig_home_current_value_2 !== '') {
    homeValue2 = module18Data.existig_home_current_value_2;
  }

  homeValue = homeValue1 + homeValue2;
  module18Data.currentNetWorthHomes = homeValue;
  return module18Data.currentNetWorthHomes;
}

export function exisitngHomeSummary(home) {
  module18Data.exisitngHomeSummary = 0;
  if (home === 1) {
    module18Data.exisitngHomeSummary = module18Data.existig_home_current_value - module18Data.existig_home_current_mortgage_balance;
  } else if (home === 2) {
    module18Data.exisitngHomeSummary = module18Data.existig_home_current_value_2 - module18Data.existig_home_current_mortgage_balance_2;
  }

  return module18Data.exisitngHomeSummary;
}

export function currentNetWorthCreditCardDebt() {
  module18Data.currentNetWorthCreditCardDebt = 0;
  if (module18Data.existing_credit_card_balance !== undefined && module18Data.existing_credit_card_balance !== '') {
    module18Data.currentNetWorthCreditCardDebt = module18Data.existing_credit_card_balance;
  }
  return module18Data.currentNetWorthCreditCardDebt;
}

export function currentNetWorthPersonalLoans() {
  module18Data.currentNetWorthPersonalLoans = 0;
  if (module18Data.personal_loan_current_personal_balance !== undefined && module18Data.personal_loan_current_personal_balance !== '') {
    module18Data.currentNetWorthPersonalLoans = module18Data.personal_loan_current_personal_balance;
  }
  return module18Data.currentNetWorthPersonalLoans;
}

export function currentNetWorthStudentLoans() {
  module18Data.currentNetWorthStudentLoans = 0;
  let studentLoansValue = 0;
  let studentLoansValue1 = 0;
  let studentLoansValue2 = 0;
  let studentLoansValue3 = 0;

  if (module18Data.fed_sub_current_balance !== undefined && module18Data.fed_sub_current_balance !== '') {
    studentLoansValue1 = module18Data.fed_sub_current_balance;
  }
  if (module18Data.existingStudentLoansFedUnSubCurrentLoan !== undefined && module18Data.existingStudentLoansFedUnSubCurrentLoan !== '') {
    studentLoansValue2 = module18Data.existingStudentLoansFedUnSubCurrentLoan;
  }
  if (module18Data.existingStudentLoansPrivateCurrentLoan !== undefined && module18Data.existingStudentLoansPrivateCurrentLoan !== '') {
    studentLoansValue3 = module18Data.existingStudentLoansPrivateCurrentLoan;
  }

  studentLoansValue = studentLoansValue1 + studentLoansValue2 + studentLoansValue3;
  module18Data.currentNetWorthStudentLoans = studentLoansValue;
  return module18Data.currentNetWorthStudentLoans;
}

export function currentNetWorthAutoLoans() {
  module18Data.currentNetWorthAutoLoans = 0;
  let autoLoansValue = 0;
  let autoLoansValue1 = 0;
  let autoLoansValue2 = 0;
  let autoLoansValue3 = 0;
  let autoLoansValue4 = 0;

  if (module18Data.existing_car_current_loan_balance !== undefined && module18Data.existing_car_current_loan_balance !== '') {
    autoLoansValue1 = module18Data.existing_car_current_loan_balance;
  }
  if (module18Data.existing_car_current_loan_balance_2 !== undefined && module18Data.existing_car_current_loan_balance_2 !== '') {
    autoLoansValue2 = module18Data.existing_car_current_loan_balance_2;
  }
  if (module18Data.existing_car_current_loan_balance_3 !== undefined && module18Data.existing_car_current_loan_balance_3 !== '') {
    autoLoansValue3 = module18Data.existing_car_current_loan_balance_3;
  }
  if (module18Data.existing_car_current_loan_balance_4 !== undefined && module18Data.existing_car_current_loan_balance_4 !== '') {
    autoLoansValue4 = module18Data.existing_car_current_loan_balance_4;
  }

  autoLoansValue = autoLoansValue1 + autoLoansValue2 + autoLoansValue3 + autoLoansValue4;
  module18Data.currentNetWorthAutoLoans = autoLoansValue;
  return module18Data.currentNetWorthAutoLoans;
}

export function currentNetWorthMortgages() {
  module18Data.currentNetWorthMortgages = 0;
  let mortgagesValue = 0;
  let mortgagesValue1 = 0;
  let mortgagesValue2 = 0;

  if (module18Data.existig_home_current_mortgage_balance !== undefined && module18Data.existig_home_current_mortgage_balance !== '') {
    mortgagesValue1 = module18Data.existig_home_current_mortgage_balance;
  }
  if (module18Data.existig_home_current_mortgage_balance_2 !== undefined && module18Data.existig_home_current_mortgage_balance_2 !== '') {
    mortgagesValue2 = module18Data.existig_home_current_mortgage_balance_2;
  }

  mortgagesValue = mortgagesValue1 + mortgagesValue2;
  module18Data.currentNetWorthMortgages = mortgagesValue;
  return module18Data.currentNetWorthMortgages;
}

export function currentNetWorthCash() {
  module18Data.currentNetWorthCash = 0;
  if (module18Data.existingCurrentCashBalance !== undefined && module18Data.existingCurrentCashBalance !== '') {
    module18Data.currentNetWorthCash = module18Data.existingCurrentCashBalance;
  }
  return module18Data.currentNetWorthCash;
}
export function currentNetWorthTotalAssetsLiabilities() {
  module18Data.currentNetWorthTotalAssetsLiabilities = 0;
  let totalAssetsLiabilitiesValue = 0;
  let totalAssetsLiabilitiesValue1 = 0;
  let totalAssetsLiabilitiesValue2 = 0;
  let totalAssetsLiabilitiesValue3 = 0;
  let totalAssetsLiabilitiesValue4 = 0;
  let totalAssetsLiabilitiesValue5 = 0;
  let totalAssetsLiabilitiesValue6 = 0;
  let totalAssetsLiabilitiesValue7 = 0;
  let totalAssetsLiabilitiesValue8 = 0;

  if (module18Data.currentNetWorthCash !== undefined && module18Data.currentNetWorthCash !== '') {
    totalAssetsLiabilitiesValue1 = module18Data.currentNetWorthCash;
  }
  if (module18Data.currentNetWorthInvestments !== undefined && module18Data.currentNetWorthInvestments !== '') {
    totalAssetsLiabilitiesValue2 = module18Data.currentNetWorthInvestments;
  }
  if (module18Data.currentNetWorthRetirementAccounts !== undefined && module18Data.currentNetWorthRetirementAccounts !== '') {
    totalAssetsLiabilitiesValue3 = module18Data.currentNetWorthRetirementAccounts;
  }
  if (module18Data.currentNetWorthLifeInsuranceCash !== undefined && module18Data.currentNetWorthLifeInsuranceCash !== '') {
    totalAssetsLiabilitiesValue4 = module18Data.currentNetWorthLifeInsuranceCash;
  }
  if (module18Data.currentNetWorthBusiness !== undefined && module18Data.currentNetWorthBusiness !== '') {
    totalAssetsLiabilitiesValue5 = module18Data.currentNetWorthBusiness;
  }
  if (module18Data.currentNetWorthOtherValuables !== undefined && module18Data.currentNetWorthOtherValuables !== '') {
    totalAssetsLiabilitiesValue6 = module18Data.currentNetWorthOtherValuables;
  }
  if (module18Data.currentNetWorthVehicles !== undefined && module18Data.currentNetWorthVehicles !== '') {
    totalAssetsLiabilitiesValue7 = module18Data.currentNetWorthVehicles;
  }
  if (module18Data.currentNetWorthHomes !== undefined && module18Data.currentNetWorthHomes !== '') {
    totalAssetsLiabilitiesValue8 = module18Data.currentNetWorthHomes;
  }

  totalAssetsLiabilitiesValue = totalAssetsLiabilitiesValue1 + totalAssetsLiabilitiesValue2 + totalAssetsLiabilitiesValue3 + totalAssetsLiabilitiesValue4 + totalAssetsLiabilitiesValue5 + totalAssetsLiabilitiesValue6 + totalAssetsLiabilitiesValue7 + totalAssetsLiabilitiesValue8;
  module18Data.currentNetWorthTotalAssetsLiabilities = totalAssetsLiabilitiesValue;
  return module18Data.currentNetWorthTotalAssetsLiabilities;
}

export function currentNetWorthTotalAssetsLiabilities2() {
  module18Data.currentNetWorthTotalAssetsLiabilities2 = 0;
  let totalAssetsLiabilitiesValue1 = 0;
  let totalAssetsLiabilitiesValue11 = 0;
  let totalAssetsLiabilitiesValue12 = 0;
  let totalAssetsLiabilitiesValue13 = 0;
  let totalAssetsLiabilitiesValue14 = 0;
  let totalAssetsLiabilitiesValue15 = 0;

  if (module18Data.currentNetWorthCreditCardDebt !== undefined && module18Data.currentNetWorthCreditCardDebt !== '') {
    totalAssetsLiabilitiesValue11 = module18Data.currentNetWorthCreditCardDebt;
  }
  if (module18Data.currentNetWorthPersonalLoans !== undefined && module18Data.currentNetWorthPersonalLoans !== '') {
    totalAssetsLiabilitiesValue12 = module18Data.currentNetWorthPersonalLoans;
  }
  if (module18Data.currentNetWorthStudentLoans !== undefined && module18Data.currentNetWorthStudentLoans !== '') {
    totalAssetsLiabilitiesValue13 = module18Data.currentNetWorthStudentLoans;
  }
  if (module18Data.currentNetWorthAutoLoans !== undefined && module18Data.currentNetWorthAutoLoans !== '') {
    totalAssetsLiabilitiesValue14 = module18Data.currentNetWorthAutoLoans;
  }
  if (module18Data.currentNetWorthMortgages !== undefined && module18Data.currentNetWorthMortgages !== '') {
    totalAssetsLiabilitiesValue15 = module18Data.currentNetWorthMortgages;
  }

  totalAssetsLiabilitiesValue1 = totalAssetsLiabilitiesValue11 + totalAssetsLiabilitiesValue12 + totalAssetsLiabilitiesValue13 + totalAssetsLiabilitiesValue14 + totalAssetsLiabilitiesValue15;

  module18Data.currentNetWorthTotalAssetsLiabilities2 = totalAssetsLiabilitiesValue1;
  return module18Data.currentNetWorthTotalAssetsLiabilities2;
}

export function currentNetWorthTotalCurrentNetWorth() {
  module18Data.currentNetWorthTotalCurrentNetWorth = 0;
  let currentNEtWorthValue = 0;
  let currentNEtWorthValue1 = 0;
  let currentNEtWorthValue2 = 0;

  if (module18Data.currentNetWorthTotalAssetsLiabilities !== undefined && module18Data.currentNetWorthTotalAssetsLiabilities !== '') {
    currentNEtWorthValue1 = module18Data.currentNetWorthTotalAssetsLiabilities;
  }
  if (module18Data.currentNetWorthTotalAssetsLiabilities2 !== undefined && module18Data.currentNetWorthTotalAssetsLiabilities2 !== '') {
    currentNEtWorthValue2 = module18Data.currentNetWorthTotalAssetsLiabilities2;
  }


  currentNEtWorthValue = currentNEtWorthValue1 - currentNEtWorthValue2;
  module18Data.currentNetWorthTotalCurrentNetWorth = currentNEtWorthValue;
  return module18Data.currentNetWorthTotalCurrentNetWorth;
}

/** ******************** Module 18  Existing and Liabilities Starts *********************** */

/** ******************** Module 10 Family Starts *********************** */

export function careerSpouseYearDiff() {
  module10Data.careerSpouseYearDiff = 0;
  if (module10Data.start_year !== undefined && module10Data.start_year > 0 && module10Data.end_year !== undefined && module10Data.end_year > 0) {
    module10Data.careerSpouseYearDiff = (module10Data.end_year - module10Data.start_year) + 1;
  }
  return module10Data.careerSpouseYearDiff;
}

export function careerAdvancementSpouseYearDiff() {
  module10Data.careerAdvancementSpouseYearDiff = 0;
  if (module10Data.second_start_year !== undefined && module10Data.second_start_year > 0 && module10Data.second_end_year !== undefined && module10Data.second_end_year > 0) {
    module10Data.careerAdvancementSpouseYearDiff = (module10Data.second_end_year - module10Data.second_start_year) + 1;
  }
  return module10Data.careerAdvancementSpouseYearDiff;
}

export function MaritalIncomeStatementSummary() {
  module10Data.MaritalIncomeStatementSummary = '';
  if (module10Data !== undefined && module1Data !== undefined && module1Data !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    module10Data.MaritalIncomeStatementSummary = `Years ${module10Data.marriege_year} to ${module1Data.start_year + 14}`;
  } else {
    module10Data.MaritalIncomeStatementSummary = 'Years';
  }
  return module10Data.MaritalIncomeStatementSummary;
}

export function childrenAverageCost() {
  if (module10Data.number_of_children !== undefined && module10Data.number_of_children > 0 && module10Data.marriege_year !== undefined && module10Data.marriege_year > 0) {
    if (module10Data !== undefined && module10Data !== ''
      && module10Data.first_born !== undefined && module10Data.first_born !== '' && module10Data.first_born > 0) {
      if (databaseData !== undefined && databaseData !== ''
        && databaseData['Children Info'] !== undefined && databaseData['Children Info'] !== ''
        && databaseData['Children Info'][module10Data.first_born] !== undefined && databaseData['Children Info'][module10Data.first_born] !== ''
        && databaseData['Children Info'][module10Data.first_born].value !== undefined && databaseData['Children Info'][module10Data.first_born].value !== '') {
        return databaseData['Children Info'][module10Data.first_born].value;
      }
      return assumptionsData.Total;
    }
    return assumptionsData.Total;
  } else {
    return 0;
  }
}

// Pre-Tax Expected Income
export function preTaxExpectedIncome() {
  module10Data.preTaxExpectedIncome = 0;
  if (module10Data.spouse_income !== undefined && module10Data.spouse_income !== '' && module10Data.spouse_bonus_or_tips_or_commission !== undefined && module10Data.spouse_bonus_or_tips_or_commission !== '') {
    module10Data.preTaxExpectedIncome = module10Data.spouse_income
      + module10Data.spouse_bonus_or_tips_or_commission;
  }
  return module10Data.preTaxExpectedIncome;
}
// Total Pre-Tax Expected Income
export function totalPreTaxExpectedIncome() {
  module10Data.totalPreTaxExpectedIncome = 0;
  if (module10Data.preTaxExpectedIncome !== undefined && module10Data.preTaxExpectedIncome !== ''
    && module10Data.careerSpouseYearDiff !== undefined && module10Data.careerSpouseYearDiff !== '') {
    module10Data.totalPreTaxExpectedIncome = module10Data.preTaxExpectedIncome * module10Data.careerSpouseYearDiff;
  }
  return module10Data.totalPreTaxExpectedIncome;
}
// Income Earned
export function incomeEarnedSpouse() {
  module10Data.incomeEarnedSpouse = 0;
  if (module10Data.preTaxExpectedIncome !== undefined && module10Data.preTaxExpectedIncome !== '' && module10Data.yearsDiff !== undefined && module10Data.yearsDiff !== '') {
    module10Data.incomeEarnedSpouse = parseFloat(module10Data.preTaxExpectedIncome) * parseFloat(module10Data.yearsDiff);
  }
  return module10Data.incomeEarnedSpouse;
}
// Estimated Tax Rate
export function spouseEstimatedTaxRate() {
  module10Data.spouseEstimatedTaxRate = 0;
  if (module10Data.preTaxExpectedIncome !== undefined
    && module10Data.preTaxExpectedIncome !== ''
    && module10Data.preTaxExpectedIncome > 0) {
    // module10Data.spouseEstimatedTaxRate = estimatedIncomeTaxes.marriedIteration5();
  }
  return module10Data.spouseEstimatedTaxRate;
}
// Estimated Taxes Paid
export function spouseEstimatedTaxesPaid() {
  module10Data.spouseEstimatedTaxesPaid = 0;
  if (module10Data.incomeEarnedSpouse !== undefined && module10Data.incomeEarnedSpouse !== '' && module10Data.spouseEstimatedTaxRate !== undefined && module10Data.spouseEstimatedTaxRate !== '') {
    module10Data.spouseEstimatedTaxesPaid = parseFloat(module10Data.incomeEarnedSpouse) * (parseFloat(module10Data.spouseEstimatedTaxRate) / 100);
  }
  return module10Data.spouseEstimatedTaxesPaid;
}
// Estimated Income after Taxes
export function spouseEstimatedIncomeafterTaxes() {
  module10Data.spouseEstimatedIncomeafterTaxes = 0;
  if (module10Data.incomeEarnedSpouse !== undefined && module10Data.incomeEarnedSpouse !== '' && module10Data.spouseEstimatedTaxesPaid !== undefined && module10Data.spouseEstimatedTaxesPaid !== '') {
    module10Data.spouseEstimatedIncomeafterTaxes = parseFloat(module10Data.incomeEarnedSpouse) - parseFloat(module10Data.spouseEstimatedTaxesPaid);
  }
  return module10Data.spouseEstimatedIncomeafterTaxes;
}
// Pre-Tax Expected Income
export function morePreTaxExpectedIncome() {
  module10Data.morePreTaxExpectedIncome = 0;
  if (module10Data.second_spouse_income !== undefined && module10Data.second_spouse_income !== '' && module10Data.second_spouse_bonus_or_tips_or_commission !== undefined && module10Data.second_spouse_bonus_or_tips_or_commission !== '') {
    module10Data.morePreTaxExpectedIncome = module10Data.second_spouse_income + module10Data.second_spouse_bonus_or_tips_or_commission;
  }
  return module10Data.morePreTaxExpectedIncome;
}
// Total Pre-Tax Expected Income
export function totalMorePreTaxExpectedIncome() {
  module10Data.totalMorePreTaxExpectedIncome = 0;
  if (module10Data.morePreTaxExpectedIncome !== undefined && module10Data.morePreTaxExpectedIncome !== ''
    && module10Data.moreYearsDiff !== undefined && module10Data.moreYearsDiff !== '') {
    module10Data.totalMorePreTaxExpectedIncome = module10Data.morePreTaxExpectedIncome * module10Data.moreYearsDiff;
  }
  return module10Data.totalMorePreTaxExpectedIncome;
}
// Income Earned
export function moreIncomeEarnedSpouse() {
  module10Data.moreIncomeEarnedSpouse = 0;
  if (module10Data.preTaxExpectedIncome !== undefined && module10Data.preTaxExpectedIncome !== '' && module10Data.yearsDiff !== undefined && module10Data.yearsDiff !== ''
    && module10Data.morePreTaxExpectedIncome !== undefined && module10Data.morePreTaxExpectedIncome !== '' && module10Data.moreYearsDiff !== undefined && module10Data.moreYearsDiff !== '') {
    module10Data.moreIncomeEarnedSpouse = (parseFloat(module10Data.morePreTaxExpectedIncome) * parseFloat(module10Data.moreYearsDiff)) + (parseFloat(module10Data.preTaxExpectedIncome) * parseFloat(module10Data.yearsDiff));
  }
  return module10Data.moreIncomeEarnedSpouse;
}
// Estimated Tax Rate
export function moreSpouseEstimatedTaxRate() {
  module10Data.moreSpouseEstimatedTaxRate = 0;
  if (module10Data.morePreTaxExpectedIncome !== undefined && module10Data.morePreTaxExpectedIncome !== '' && module10Data.morePreTaxExpectedIncome > 0) {
    // module10Data.moreSpouseEstimatedTaxRate = estimatedIncomeTaxes.marriedIteration6();
  }
  return module10Data.moreSpouseEstimatedTaxRate;
}
// Estimated Taxes Paid
export function moreSpouseEstimatedTaxesPaid() {
  module10Data.moreSpouseEstimatedTaxesPaid = 0;
  if (module10Data.moreIncomeEarnedSpouse !== undefined && module10Data.moreIncomeEarnedSpouse !== '' && module10Data.moreSpouseEstimatedTaxRate !== undefined && module10Data.moreSpouseEstimatedTaxRate !== '') {
    module10Data.moreSpouseEstimatedTaxesPaid = parseFloat(module10Data.moreIncomeEarnedSpouse) * (parseFloat(module10Data.moreSpouseEstimatedTaxRate) / 100);
  }
  return module10Data.moreSpouseEstimatedTaxesPaid;
}
// Living Expenses Paid
export function moreSpouseLivingExpensesPaid() {
  module10Data.moreSpouseLivingExpensesPaid = 0;
  return module10Data.moreSpouseLivingExpensesPaid;
}
// Financing Expenses Paid
export function moreSpouseFinancingExpensesPaid() {
  module10Data.moreSpouseFinancingExpensesPaid = 0;
  return module10Data.moreSpouseFinancingExpensesPaid;
}
// Estimated Income after Taxes
export function moreSpouseEstimatedIncomeafterTaxes() {
  module10Data.moreSpouseEstimatedIncomeafterTaxes = 0;
  if (module10Data.moreIncomeEarnedSpouse !== undefined && module10Data.moreIncomeEarnedSpouse !== '' && module10Data.moreSpouseEstimatedTaxesPaid !== undefined && module10Data.moreSpouseEstimatedTaxesPaid !== '') {
    module10Data.moreSpouseEstimatedIncomeafterTaxes = parseFloat(module10Data.moreIncomeEarnedSpouse) - parseFloat(module10Data.moreSpouseEstimatedTaxesPaid);
  }
  return module10Data.moreSpouseEstimatedIncomeafterTaxes;
}

// Marrital Spouse's Income
export function marritalSpouseIncome() {
  module10Data.marritalSpouseIncome = 0;
  if (incomeStatementData !== undefined
    && incomeStatementData['Spouses Total Income'] !== '') {
    module10Data.marritalSpouseIncome = incomeStatementData['Spouses Total Income'];
  }
  return module10Data.marritalSpouseIncome;
}
// Marrital Spouse's Income Main
export function marritalSpouseIncomeMain() {
  module10Data.marritalSpouseIncomeMain = 0;

  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== ''
    && incomeStatementData[module10Data.marriege_year]['Gross Income']['Spouses Income'] !== '') {
    module10Data.marritalSpouseIncomeMain = incomeStatementData[module10Data.marriege_year]['Gross Income']['Spouses Income'];
  }
  return module10Data.marritalSpouseIncomeMain;
}

// Marrital Other Income
export function marritalOtherIncome() {
  module10Data.marritalOtherIncome = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let totalIncomeVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            totalIncomeVal += data[year]['Total Income'];
          }
        }
      });
    }

    module10Data.marritalOtherIncome = totalIncomeVal - module10Data.marritalSpouseIncomeMain;
  }
  return module10Data.marritalOtherIncome;
}

// Marrital Other Income
export function marritalOtherIncomeMain() {
  module10Data.marritalOtherIncomeMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== ''
    && incomeStatementData[module10Data.marriege_year]['Total Income'] !== '') {
    module10Data.marritalOtherIncomeMain = incomeStatementData[module10Data.marriege_year]['Total Income'] - module10Data.marritalSpouseIncomeMain;
  }
  return module10Data.marritalOtherIncomeMain;
}

// Marrital Total Income
export function marritalTotalIncome() {
  module10Data.marritalTotalIncome = 0;
  if (module10Data.marritalSpouseIncome !== undefined
    && module10Data.marritalOtherIncome !== undefined) {
    module10Data.marritalTotalIncome = module10Data.marritalSpouseIncome + module10Data.marritalOtherIncome;
  }
  return module10Data.marritalTotalIncome;
}
// Marrital Total Income Main
export function marritalTotalIncomeMain() {
  module10Data.marritalTotalIncomeMain = 0;
  if (module10Data.marritalSpouseIncomeMain !== undefined
    && module10Data.marritalOtherIncomeMain !== undefined) {
    module10Data.marritalTotalIncomeMain = module10Data.marritalSpouseIncomeMain + module10Data.marritalOtherIncomeMain;
  }
  return module10Data.marritalTotalIncomeMain;
}
// Marrital Taxes Paid
export function marritalTaxesPaid() {
  module10Data.marritalTaxesPaid = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let taxesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            taxesPaidVal += data[year]['Total Taxes'];
          }
        }
      });
    }

    module10Data.marritalTaxesPaid = taxesPaidVal;
  }
  return module10Data.marritalTaxesPaid;
}
// Marrital Taxes Paid Main
export function marritalTaxesPaidMain() {
  module10Data.marritalTaxesPaidMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== ''
    && incomeStatementData[module10Data.marriege_year]['Total Taxes'] !== '') {
    module10Data.marritalTaxesPaidMain = incomeStatementData[module10Data.marriege_year]['Total Taxes'];
  }
  return module10Data.marritalTaxesPaidMain;
}
// Marrital Tax Rate
export function marritalTaxesRate() {
  module10Data.marritalTaxesRate = 0;
  if (module10Data.marritalTaxesPaid !== undefined && -(module10Data.marritalTaxesPaid) > 0
    && module10Data.marritalTotalIncome !== undefined) {
    module10Data.marritalTaxesRate = (-(module10Data.marritalTaxesPaid) / module10Data.marritalTotalIncome) * 100;
  }
  return module10Data.marritalTaxesRate;
}
// Marrital Tax Rate Main
export function marritalTaxesRateMain() {
  module10Data.marritalTaxesRateMain = 0;
  if (module10Data.marritalTaxesPaidMain !== undefined && -(module10Data.marritalTaxesPaidMain) > 0
    && module10Data.marritalTotalIncomeMain !== undefined) {
    module10Data.marritalTaxesRateMain = (-(module10Data.marritalTaxesPaidMain) / module10Data.marritalTotalIncomeMain) * 100;
  }
  return module10Data.marritalTaxesRateMain;
}

// Marrital Living Expense Paid
export function marritalExpensesPaid() {
  module10Data.marritalExpensesPaid = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let rentVal = 0;
    let utilitiesVal = 0;
    let foodVal = 0;
    let clothingVal = 0;
    let entertainmentVal = 0;
    let technologyVal = 0;
    let transportationVal = 0;
    let miscellaneousVal = 0;
    let healthCareVal = 0;
    let healthInsuranceVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            rentVal += data[year].livingExpenses.Rent;
            utilitiesVal += data[year].livingExpenses.Utilities;
            foodVal += data[year].livingExpenses.Food;
            clothingVal += data[year].livingExpenses.Clothing;
            entertainmentVal += data[year].livingExpenses.Entertainment;
            technologyVal += data[year].livingExpenses.Technology;
            transportationVal += data[year].livingExpenses.Transportation;
            miscellaneousVal += data[year].livingExpenses.Miscellaneous;
            healthCareVal += data[year].livingExpenses['Healthcare Costs other than Premium'];
            healthInsuranceVal += data[year].livingExpenses['Health Insurance Premium'];
          }
        }
      });
    }

    module10Data.marritalExpensesPaid = rentVal + utilitiesVal + foodVal + clothingVal + entertainmentVal + technologyVal + transportationVal + miscellaneousVal + healthCareVal + healthInsuranceVal;
  }
  return module10Data.marritalExpensesPaid;
}
// Marrital Living Expense Paid Main
export function marritalExpensesPaidMain() {
  module10Data.marritalExpensesPaidMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    module10Data.marritalExpensesPaidMain = incomeStatementData[module10Data.marriege_year].livingExpenses.Rent
      + incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities
      + incomeStatementData[module10Data.marriege_year].livingExpenses.Food
      + incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing
      + incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment
      + incomeStatementData[module10Data.marriege_year].livingExpenses.Technology
      + incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation
      + incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous
      + incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium']
      + incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'];
  }
  return module10Data.marritalExpensesPaidMain;
}

// Higher education expenses
export function higherEducationExpenses() {
  module10Data.higherEducationExpenses = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let higherEducationTuitionFeesVal = 0;
    let higherEducationRoomBoardVal = 0;
    let offCampusFood = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            higherEducationTuitionFeesVal += data[year].livingExpenses['Higher Education Tuition and Fees'];
            higherEducationRoomBoardVal += data[year].livingExpenses['Higher Education Room & Board'];
            offCampusFood += data[year].livingExpenses['Off Campus Food'];
          }
        }
      });
    }

    module10Data.higherEducationExpenses = higherEducationTuitionFeesVal + higherEducationRoomBoardVal + offCampusFood;
  }
  return module10Data.higherEducationExpenses;
}

// Higher education expenses Main
export function higherEducationExpensesMain() {
  module10Data.higherEducationExpensesMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    module10Data.higherEducationExpensesMain = incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Tuition and Fees']
      + incomeStatementData[module10Data.marriege_year].livingExpenses['Higher Education Room & Board']
      + incomeStatementData[module10Data.marriege_year].livingExpenses['Off Campus Food'];
  }
  return module10Data.higherEducationExpensesMain;
}

// Children expenses
export function childrenExpenses() {
  module10Data.childrenExpenses = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let childrenVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            childrenVal += data[year].livingExpenses.Children;
          }
        }
      });
    }

    module10Data.childrenExpenses = childrenVal;
  }
  return module10Data.childrenExpenses;
}

// Children expenses Main
export function childrenExpensesMain() {
  module10Data.childrenExpensesMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    module10Data.childrenExpensesMain = incomeStatementData[module10Data.marriege_year].livingExpenses.Children;
  }
  return module10Data.childrenExpensesMain;
}

// Pet expenses
export function petExpenses() {
  module10Data.petExpenses = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let petVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            petVal += data[year].livingExpenses.Pet;
          }
        }
      });
    }

    module10Data.petExpenses = petVal;
  }
  return module10Data.petExpenses;
}

// Children expenses Main
export function petExpensesMain() {
  module10Data.petExpensesMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    module10Data.petExpensesMain = incomeStatementData[module10Data.marriege_year].livingExpenses.Pet;
  }
  return module10Data.petExpensesMain;
}

// Marrital Car Expense Paid
export function marritalCarPaid() {
  module10Data.marritalCarPaid = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let carPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            carPaidVal += data[year]['Total Car Expenses'];
          }
        }
      });
    }

    module10Data.marritalCarPaid = carPaidVal;
  }
  return module10Data.marritalCarPaid;
}

// Marrital Car Expense Paid Main
export function marritalCarPaidMain() {
  module10Data.marritalCarPaidMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    module10Data.marritalCarPaidMain = incomeStatementData[module10Data.marriege_year]['Total Car Expenses'];
  }
  return module10Data.marritalCarPaidMain;
}

// Marrital Realestate Expense Paid
export function marritalRealEstatePaid() {
  module10Data.marritalRealEstatePaid = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let realEstatePaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            realEstatePaidVal += data[year]['Total Real Estate Expenses'];
          }
        }
      });
    }

    module10Data.marritalRealEstatePaid = realEstatePaidVal;
  }
  return module10Data.marritalRealEstatePaid;
}

// Marrital Realestate Expense Paid Main
export function marritalRealEstatePaidMain() {
  module10Data.marritalRealEstatePaidMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    module10Data.marritalRealEstatePaidMain = incomeStatementData[module10Data.marriege_year]['Total Real Estate Expenses'];
  }
  return module10Data.marritalRealEstatePaidMain;
}

// Marrital Financing Expense Paid
export function marritalFinancingExpensesPaid() {
  module10Data.marritalFinancingExpensesPaid = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let financingExpensesPaidVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            financingExpensesPaidVal += data[year]['Total Interest Expenses'];
          }
        }
      });
    }

    module10Data.marritalFinancingExpensesPaid = financingExpensesPaidVal;
  }
  return module10Data.marritalFinancingExpensesPaid;
}

// Marrital Financing Expense Paid Main
export function marritalFinancingExpensesPaidMain() {
  module10Data.marritalFinancingExpensesPaidMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module10Data.marriege_year] !== undefined
    && incomeStatementData[module10Data.marriege_year] !== '') {
    module10Data.marritalFinancingExpensesPaidMain = incomeStatementData[module10Data.marriege_year]['Total Interest Expenses'];
  }
  return module10Data.marritalFinancingExpensesPaidMain;
}

// Total Annual Expenses
export function totalAnnualExpenses() {
  module10Data.totalAnnualExpenses = 0;
  if (module10Data.marritalTaxesPaid !== undefined
    && module10Data.marritalExpensesPaid !== undefined
    && module10Data.higherEducationExpenses !== undefined
    && module10Data.childrenExpenses !== undefined
    && module10Data.petExpenses !== undefined
    && module10Data.marritalCarPaid !== undefined
    && module10Data.marritalRealEstatePaid !== undefined
    && module10Data.marritalFinancingExpensesPaid !== undefined) {
    module10Data.totalAnnualExpenses = module10Data.marritalTaxesPaid
      + module10Data.marritalExpensesPaid
      + module10Data.higherEducationExpenses
      + module10Data.childrenExpenses
      + module10Data.petExpenses
      + module10Data.marritalCarPaid
      + module10Data.marritalRealEstatePaid
      + module10Data.marritalFinancingExpensesPaid;
  }
  return module10Data.totalAnnualExpenses;
}

// Total Annual Expenses Main
export function totalAnnualExpensesMain() {
  module10Data.totalAnnualExpensesMain = 0;
  if (module10Data.marritalTaxesPaidMain !== undefined
    && module10Data.marritalExpensesPaidMain !== undefined
    && module10Data.higherEducationExpensesMain !== undefined
    && module10Data.childrenExpensesMain !== undefined
    && module10Data.petExpensesMain !== undefined
    && module10Data.marritalCarPaidMain !== undefined
    && module10Data.marritalRealEstatePaidMain !== undefined
    && module10Data.marritalFinancingExpensesPaidMain !== undefined) {
    module10Data.totalAnnualExpensesMain = module10Data.marritalTaxesPaidMain
      + module10Data.marritalExpensesPaidMain
      + module10Data.higherEducationExpensesMain
      + module10Data.childrenExpensesMain
      + module10Data.petExpensesMain
      + module10Data.marritalCarPaidMain
      + module10Data.marritalRealEstatePaidMain
      + module10Data.marritalFinancingExpensesPaidMain;
  }
  return module10Data.totalAnnualExpensesMain;
}

// Annual Cash Flow before Retirement Contributions
export function annualCashFlowBeforeRetirementContributions() {
  module10Data.annualCashFlowBeforeRetirementContributions = 0;
  if (module10Data.marritalTotalIncome !== undefined
    && module10Data.totalAnnualExpenses !== undefined) {
    module10Data.annualCashFlowBeforeRetirementContributions = module10Data.marritalTotalIncome
      + module10Data.totalAnnualExpenses;
  }
  return module10Data.annualCashFlowBeforeRetirementContributions;
}

// Annual Cash Flow before Retirement Contributions Main
export function annualCashFlowBeforeRetirementContributionsMain() {
  module10Data.annualCashFlowBeforeRetirementContributionsMain = 0;
  if (module10Data.marritalTotalIncomeMain !== undefined
    && module10Data.totalAnnualExpensesMain !== undefined) {
    module10Data.annualCashFlowBeforeRetirementContributionsMain = module10Data.marritalTotalIncomeMain
      + module10Data.totalAnnualExpensesMain;
  }
  return module10Data.annualCashFlowBeforeRetirementContributionsMain;
}

// Retirement Contributions
export function retirementContributions() {
  module10Data.retirementContributions = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = cashFlowData;
    let retirementContributionsVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            retirementContributionsVal += data[year]['Cash from Investing Activities']['Retirement Contributions'];
          }
        }
      });
    }

    module10Data.retirementContributions = -retirementContributionsVal;
  }
  return module10Data.retirementContributions;
}

// Retirement Contributions Main
export function retirementContributionsMain() {
  module10Data.retirementContributionsMain = 0;
  if (module10Data.marriege_year !== undefined
    && module10Data.marriege_year !== ''
    && cashFlowData !== undefined
    && cashFlowData[module10Data.marriege_year] !== undefined
    && cashFlowData[module10Data.marriege_year] !== '') {
    module10Data.retirementContributionsMain = -cashFlowData[module10Data.marriege_year]['Cash from Investing Activities']['Retirement Contributions'];
  }
  return module10Data.retirementContributionsMain;
}

// Net Cash Flow for Savings and Investments
export function netCashFlowForSavingsAndInvestments() {
  module10Data.netCashFlowForSavingsAndInvestments = 0;
  if (module10Data.annualCashFlowBeforeRetirementContributions !== undefined
    && module10Data.retirementContributions !== undefined) {
    module10Data.netCashFlowForSavingsAndInvestments = module10Data.annualCashFlowBeforeRetirementContributions
      - module10Data.retirementContributions;
  }
  return module10Data.netCashFlowForSavingsAndInvestments;
}

// Net Cash Flow for Savings and Investments Main
export function netCashFlowForSavingsAndInvestmentsMain() {
  module10Data.netCashFlowForSavingsAndInvestmentsMain = 0;
  if (module10Data.annualCashFlowBeforeRetirementContributionsMain !== undefined
    && module10Data.retirementContributionsMain !== undefined) {
    module10Data.netCashFlowForSavingsAndInvestmentsMain = module10Data.annualCashFlowBeforeRetirementContributionsMain
      - module10Data.retirementContributionsMain;
  }
  return module10Data.netCashFlowForSavingsAndInvestmentsMain;
}

// Marrital Income After
export function marritalIncomeAfter() {
  module10Data.marritalIncomeAfter = 0;
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const start_year = parseInt(module10Data.marriege_year, 10);
    const endYear = parseInt(module1Data.start_year, 10) + 14;
    const data = incomeStatementData;
    let netIncomeVal = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          if (year >= start_year && year <= endYear) {
            netIncomeVal += data[year]['Net Income'];
          }
        }
      });
    }

    module10Data.marritalIncomeAfter = netIncomeVal;
  }
  return module10Data.marritalIncomeAfter;
}

// Monthly Payment (Principal & Interest)
export function spouseMonthlyPaymentInterest() {
  module10Data.spouseMonthlyPaymentInterest = 0;
  if (spouseStudentLoanData !== undefined && spouseStudentLoanData !== '' && spouseStudentLoanData['Original Payment'] !== undefined) {
    module10Data.spouseMonthlyPaymentInterest = spouseStudentLoanData['Original Payment'];
  }
  return module10Data.spouseMonthlyPaymentInterest;
}
// Monthly Payment (Principal & Interest)
export function spouseAnnualPaidInterest() {
  module10Data.spouseAnnualPaidInterest = 0;
  if (module10Data.spouse_ideal_repayment_schedule !== undefined && module10Data.spouse_ideal_repayment_schedule > 12) {
    module10Data.spouseAnnualPaidInterest = module10Data.spouseMonthlyPaymentInterest * 12;
  } else {
    module10Data.spouseAnnualPaidInterest = module10Data.spouseMonthlyPaymentInterest * module10Data.spouse_ideal_repayment_schedule;
  }
  return module10Data.spouseAnnualPaidInterest;
}
// Total Interest
export function spouseTotalInterest() {
  module10Data.spouseTotalInterest = 0;
  if (spouseStudentLoanData !== undefined && spouseStudentLoanData !== '' && spouseStudentLoanData['Total Interest Paid'] !== undefined) {
    module10Data.spouseTotalInterest = spouseStudentLoanData['Total Interest Paid'];
  }
  return module10Data.spouseTotalInterest;
}
// Total Interest
export function spouseTotalPrincipalInterest() {
  module10Data.spouseTotalPrincipalInterest = 0;
  if (spouseStudentLoanData !== undefined && spouseStudentLoanData !== '' && spouseStudentLoanData['Total Paid'] !== undefined) {
    module10Data.spouseTotalPrincipalInterest = spouseStudentLoanData['Total Paid'];
  }
  return module10Data.spouseTotalPrincipalInterest;
}

// Number of Childrens
export function childernYearDropdowns() {
  if (module10Data.number_of_children !== undefined) {
    // $('.child').addClass('hidden');
    let i = 1;
    for (i; i <= 7; i += 1) {
      if (i <= module10Data.number_of_children) {
        // $('.child-' + i).removeClass('hidden');
      } else {
        switch (i) {
          case 1:
            module10Data.first_born = 0;
            break;
          case 2:
            module10Data.second_born = 0;
            break;
          case 3:
            module10Data.third_born = 0;
            break;
          case 4:
            module10Data.fourth_born = 0;
            break;
          case 5:
            module10Data.fifth_born = 0;
            break;
          case 6:
            module10Data.sixth_born = 0;
            break;
          case 7:
            module10Data.seventh_born = 0;
            break;
          default:
            break;
        }
      }
    }
  }
}

// Average Cost of Raising a Child
export function averageCostRaisingChild() {
  module10Data.averageCostRaisingChild = childrenAverageCost(); // Database Value

  return module10Data.averageCostRaisingChild;
}
// Annual Cost of Owning a Pet
export function annualCostOfOwingPet() {
  module10Data.annualCostOfOwingPet = 0;
  if (module10Data.year_of_purchase !== undefined
    && module10Data.year_of_purchase !== '') {
    if (expenseCalculationsData !== undefined
      && expenseCalculationsData[module10Data.year_of_purchase] !== undefined) {
      module10Data.annualCostOfOwingPet = expenseCalculationsData[module10Data.year_of_purchase]['Initial Costs'].Total;
    }
  }
  return module10Data.annualCostOfOwingPet;
}

// Annual Cost of Owning a Pet
export function totalYearlyCostOfOwningAPet() {
  module10Data.totalYearlyCostOfOwningAPet = 0;
  if (module10Data.year_of_purchase !== undefined
    && module10Data.year_of_purchase !== '') {
    if (expenseCalculationsData !== undefined
      && expenseCalculationsData[module10Data.year_of_purchase] !== undefined) {
      module10Data.totalYearlyCostOfOwningAPet = expenseCalculationsData[module10Data.year_of_purchase]['Yearly Costs'].Total;
    }
  }
  return module10Data.totalYearlyCostOfOwningAPet;
}

// Rent Multiplier
export function familyRentMultiplier() {
  module10Data.familyRentMultiplier = 0;
  let rentValue = 0;
  if (module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== '') {
      rentValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Rent / 12) / module10Data.rent_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Rent !== '') {
    rentValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Rent / 12);
  }
  module10Data.familyRentMultiplier = rentValue;
  return module10Data.familyRentMultiplier;
}

// Rent Multiplier Calc
export function familyRentMultiplierCalc() {
  module10Data.familyRentMultiplierCalc = 0;
  if (module10Data.familyRentMultiplier !== undefined && module10Data.familyRentMultiplier !== '' && module10Data.rent_multiplier !== undefined && module10Data.rent_multiplier !== '') {
    module10Data.familyRentMultiplierCalc = module10Data.familyRentMultiplier * module10Data.rent_multiplier;
  }
  return module10Data.familyRentMultiplierCalc;
}

// Utilities Multiplier
export function familyUtilitiesMultiplier() {
  module10Data.familyUtilitiesMultiplier = 0;
  let UtilitiesValue = 0;
  if (module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== '') {
      UtilitiesValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities / 12) / module10Data.utilities_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities !== '') {
    UtilitiesValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Utilities / 12);
  }

  module10Data.familyUtilitiesMultiplier = UtilitiesValue;
  return module10Data.familyUtilitiesMultiplier;
}

// Utilities Multiplier Calc
export function familyUtilitiesMultiplierCalc() {
  module10Data.familyUtilitiesMultiplierCalc = 0;
  if (module10Data.familyUtilitiesMultiplier !== undefined && module10Data.familyUtilitiesMultiplier !== '' && module10Data.utilities_multiplier !== undefined && module10Data.utilities_multiplier !== '') {
    module10Data.familyUtilitiesMultiplierCalc = module10Data.familyUtilitiesMultiplier * module10Data.utilities_multiplier;
  }
  return module10Data.familyUtilitiesMultiplierCalc;
}

// Food Multiplier
export function familyFoodMultiplier() {
  module10Data.familyFoodMultiplier = 0;
  let FoodValue = 0;
  if (module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== '') {
      FoodValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Food / 12) / module10Data.food_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Food !== '') {
    FoodValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Food / 12);
  }

  module10Data.familyFoodMultiplier = FoodValue;
  return module10Data.familyFoodMultiplier;
}

// Food Multiplier Calc
export function familyFoodMultiplierCalc() {
  module10Data.familyFoodMultiplierCalc = 0;
  if (module10Data.familyFoodMultiplier !== undefined && module10Data.familyFoodMultiplier !== '' && module10Data.food_multiplier !== undefined && module10Data.food_multiplier !== '') {
    module10Data.familyFoodMultiplierCalc = module10Data.familyFoodMultiplier * module10Data.food_multiplier;
  }
  return module10Data.familyFoodMultiplierCalc;
}

// Clothing Multiplier
export function familyClothingMultiplier() {
  module10Data.familyClothingMultiplier = 0;
  let ClothingValue = 0;
  if (module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== '') {
      ClothingValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing / 12) / module10Data.clothing_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing !== '') {
    ClothingValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Clothing / 12);
  }

  module10Data.familyClothingMultiplier = ClothingValue;
  return module10Data.familyClothingMultiplier;
}

// Clothing Multiplier Calc
export function familyClothingMultiplierCalc() {
  module10Data.familyClothingMultiplierCalc = 0;
  if (module10Data.familyClothingMultiplier !== undefined && module10Data.familyClothingMultiplier !== '' && module10Data.clothing_multiplier !== undefined && module10Data.clothing_multiplier !== '') {
    module10Data.familyClothingMultiplierCalc = module10Data.familyClothingMultiplier * module10Data.clothing_multiplier;
  }
  return module10Data.familyClothingMultiplierCalc;
}

// Entertainment Multiplier
export function familyEntertainmentMultiplier() {
  module10Data.familyEntertainmentMultiplier = 0;
  let EntertainmentValue = 0;
  if (module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== '') {
      EntertainmentValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment / 12) / module10Data.entertainment_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment !== '') {
    EntertainmentValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Entertainment / 12);
  }

  module10Data.familyEntertainmentMultiplier = EntertainmentValue;
  return module10Data.familyEntertainmentMultiplier;
}

// Entertainment Multiplier Calc
export function familyEntertainmentMultiplierCalc() {
  module10Data.familyEntertainmentMultiplierCalc = 0;
  if (module10Data.familyEntertainmentMultiplier !== undefined && module10Data.familyEntertainmentMultiplier !== '' && module10Data.entertainment_multiplier !== undefined && module10Data.entertainment_multiplier !== '') {
    module10Data.familyEntertainmentMultiplierCalc = module10Data.familyEntertainmentMultiplier * module10Data.entertainment_multiplier;
  }
  return module10Data.familyEntertainmentMultiplierCalc;
}

// Technology Multiplier
export function familyTechnologyMultiplier() {
  module10Data.familyTechnologyMultiplier = 0;
  let TechnologyValue = 0;
  if (module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== '') {
      TechnologyValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Technology / 12) / module10Data.technology_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Technology !== '') {
    TechnologyValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Technology / 12);
  }

  module10Data.familyTechnologyMultiplier = TechnologyValue;
  return module10Data.familyTechnologyMultiplier;
}

// Technology Multiplier Calc
export function familyTechnologyMultiplierCalc() {
  module10Data.familyTechnologyMultiplierCalc = 0;
  if (module10Data.familyTechnologyMultiplier !== undefined && module10Data.familyTechnologyMultiplier !== '' && module10Data.technology_multiplier !== undefined && module10Data.technology_multiplier !== '') {
    module10Data.familyTechnologyMultiplierCalc = module10Data.familyTechnologyMultiplier * module10Data.technology_multiplier;
  }
  return module10Data.familyTechnologyMultiplierCalc;
}

// Transportation Multiplier
export function familyTransportationMultiplier() {
  module10Data.familyTransportationMultiplier = 0;
  let TransportationValue = 0;
  if (module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== '') {
      TransportationValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation / 12) / module10Data.transportation_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation !== '') {
    TransportationValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Transportation / 12);
  }

  module10Data.familyTransportationMultiplier = TransportationValue;
  return module10Data.familyTransportationMultiplier;
}

// Transportation Multiplier Calc
export function familyTransportationMultiplierCalc() {
  module10Data.familyTransportationMultiplierCalc = 0;
  if (module10Data.familyTransportationMultiplier !== undefined && module10Data.familyTransportationMultiplier !== '' && module10Data.transportation_multiplier !== undefined && module10Data.transportation_multiplier !== '') {
    module10Data.familyTransportationMultiplierCalc = module10Data.familyTransportationMultiplier * module10Data.transportation_multiplier;
  }
  return module10Data.familyTransportationMultiplierCalc;
}

// Miscellaneous Multiplier
export function familyMiscellaneousMultiplier() {
  module10Data.familyMiscellaneousMultiplier = 0;
  let MiscellaneousValue = 0;
  if (module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== '') {
      MiscellaneousValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous / 12) / module10Data.miscellaneous_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous !== '') {
    MiscellaneousValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses.Miscellaneous / 12);
  }

  module10Data.familyMiscellaneousMultiplier = MiscellaneousValue;
  return module10Data.familyMiscellaneousMultiplier;
}

// Miscellaneous Multiplier Calc
export function familyMiscellaneousMultiplierCalc() {
  module10Data.familyMiscellaneousMultiplierCalc = 0;
  if (module10Data.familyMiscellaneousMultiplier !== undefined && module10Data.familyMiscellaneousMultiplier !== '' && module10Data.miscellaneous_multiplier !== undefined && module10Data.miscellaneous_multiplier !== '') {
    module10Data.familyMiscellaneousMultiplierCalc = module10Data.familyMiscellaneousMultiplier * module10Data.miscellaneous_multiplier;
  }
  return module10Data.familyMiscellaneousMultiplierCalc;
}

// Health Insurance Outside Premium
export function familyHealthInsuranceOutsidePremiumMultiplier() {
  module10Data.familyHealthInsuranceOutsidePremiumMultiplier = 0;
  let HealthInsuranceValue = 0;
  if (module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] !== '') {
      HealthInsuranceValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] / 12) / module10Data.health_insurance_costs_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] !== '') {
    HealthInsuranceValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses['Healthcare Costs other than Premium'] / 12);
  }

  module10Data.familyHealthInsuranceOutsidePremiumMultiplier = HealthInsuranceValue;
  return module10Data.familyHealthInsuranceOutsidePremiumMultiplier;
}

// Health Insurance Outside Premium Calc
export function familyHealthInsuranceOutsidePremiumMultiplierCalc() {
  module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc = 0;
  if (module10Data.familyHealthInsuranceOutsidePremiumMultiplier !== undefined && module10Data.familyHealthInsuranceOutsidePremiumMultiplier !== '' && module10Data.health_insurance_costs_multiplier !== undefined && module10Data.health_insurance_costs_multiplier !== '') {
    module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc = module10Data.familyHealthInsuranceOutsidePremiumMultiplier * module10Data.health_insurance_costs_multiplier;
  }
  return module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc;
}

// Health Insurance Premium
export function familyHealthInsurancePremiumMultiplier() {
  module10Data.familyHealthInsurancePremiumMultiplier = 0;
  let HealthInsuranceValue = 0;
  if (module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] !== '') {
      HealthInsuranceValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] / 12) / module10Data.health_insurance_premium_multiplier;
    }
  } else if (incomeStatementData !== undefined && incomeStatementData !== '' && incomeStatementData[module10Data.marriege_year] !== undefined && incomeStatementData[module10Data.marriege_year] !== '' && incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] !== undefined && incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] !== '') {
    HealthInsuranceValue = -(incomeStatementData[module10Data.marriege_year].livingExpenses['Health Insurance Premium'] / 12);
  }

  module10Data.familyHealthInsurancePremiumMultiplier = HealthInsuranceValue;
  return module10Data.familyHealthInsurancePremiumMultiplier;
}

// Health Insurance Outside Premium Calc
export function familyHealthInsurancePremiumMultiplierCalc() {
  module10Data.familyHealthInsurancePremiumMultiplierCalc = 0;
  if (module10Data.familyHealthInsurancePremiumMultiplier !== undefined && module10Data.familyHealthInsurancePremiumMultiplier !== '' && module10Data.health_insurance_premium_multiplier !== undefined && module10Data.health_insurance_premium_multiplier !== '') {
    module10Data.familyHealthInsurancePremiumMultiplierCalc = module10Data.familyHealthInsurancePremiumMultiplier * module10Data.health_insurance_premium_multiplier;
  }
  return module10Data.familyHealthInsurancePremiumMultiplierCalc;
}
/* Sum of Multiplier Values */
export function sumOfFamilyMultiplier() {
  module10Data.sumOfFamilyMultiplier = 0;
  if (module10Data.familyRentMultiplier !== undefined
    && module10Data.familyUtilitiesMultiplier !== undefined
    && module10Data.familyFoodMultiplier !== undefined
    && module10Data.familyClothingMultiplier !== undefined
    && module10Data.familyEntertainmentMultiplier !== undefined
    && module10Data.familyTechnologyMultiplier !== undefined
    && module10Data.familyTransportationMultiplier !== undefined
    && module10Data.familyMiscellaneousMultiplier !== undefined
    && module10Data.familyHealthInsuranceOutsidePremiumMultiplier !== undefined
    && module10Data.familyHealthInsurancePremiumMultiplier !== undefined) {
    module10Data.sumOfFamilyMultiplier = module10Data.familyRentMultiplier
      + module10Data.familyUtilitiesMultiplier
      + module10Data.familyFoodMultiplier
      + module10Data.familyClothingMultiplier
      + module10Data.familyEntertainmentMultiplier
      + module10Data.familyTechnologyMultiplier
      + module10Data.familyTransportationMultiplier
      + module10Data.familyMiscellaneousMultiplier
      + module10Data.familyHealthInsuranceOutsidePremiumMultiplier
      + module10Data.familyHealthInsurancePremiumMultiplier;
  }
  return module10Data.sumOfFamilyMultiplier;
}

/* Sum of Multiplier Values Calc */
export function sumOfFamilyMultiplierCalc() {
  module10Data.sumOfFamilyMultiplierCalc = 0;
  if (module10Data.familyRentMultiplierCalc !== undefined
    && module10Data.familyUtilitiesMultiplierCalc !== undefined
    && module10Data.familyFoodMultiplierCalc !== undefined
    && module10Data.familyClothingMultiplierCalc !== undefined
    && module10Data.familyEntertainmentMultiplierCalc !== undefined
    && module10Data.familyTechnologyMultiplierCalc !== undefined
    && module10Data.familyTransportationMultiplierCalc !== undefined
    && module10Data.familyMiscellaneousMultiplierCalc !== undefined
    && module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc !== undefined
    && module10Data.familyHealthInsurancePremiumMultiplierCalc !== undefined) {
    module10Data.sumOfFamilyMultiplierCalc = module10Data.familyRentMultiplierCalc
      + module10Data.familyUtilitiesMultiplierCalc
      + module10Data.familyFoodMultiplierCalc
      + module10Data.familyClothingMultiplierCalc
      + module10Data.familyEntertainmentMultiplierCalc
      + module10Data.familyTechnologyMultiplierCalc
      + module10Data.familyTransportationMultiplierCalc
      + module10Data.familyMiscellaneousMultiplierCalc
      + module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc
      + module10Data.familyHealthInsurancePremiumMultiplierCalc;
  }
  return module10Data.sumOfFamilyMultiplierCalc;
}

// Get marriage difference year
export function differenceYear() {
  module10Data.differenceYear = 0;
  if (module1Data.start_year !== undefined && module1Data.start_year !== '' && module10Data.marriege_year !== undefined && module10Data.marriege_year !== '') {
    module10Data.differenceYear = parseFloat(module1Data.start_year) + 14;
  }
  return module10Data.differenceYear;
}

export function familyExpensesMarriageYearLable() {
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module4Data.start_year !== undefined && module4Data.start_year !== ''
    && module4Data.end_year !== undefined && module4Data.end_year !== ''
    && parseInt(module10Data.marriege_year, 10) >= parseInt(module4Data.start_year, 10)
    && parseInt(module10Data.marriege_year, 10) <= parseInt(module4Data.end_year, 10)) {
    return 'module4';
  }
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module5Data.start_year !== undefined && module5Data.start_year !== ''
    && module5Data.end_year !== undefined && module5Data.end_year !== ''
    && parseInt(module10Data.marriege_year, 10) >= parseInt(module5Data.start_year, 10)
    && parseInt(module10Data.marriege_year, 10) <= parseInt(module5Data.end_year, 10)) {
    return 'module5';
  }
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module8Data.start_year !== undefined && module8Data.start_year !== ''
    && module8Data.end_year !== undefined && module8Data.end_year !== ''
    && parseInt(module10Data.marriege_year, 10) >= parseInt(module8Data.start_year, 10)
    && parseInt(module10Data.marriege_year, 10) <= parseInt(module8Data.end_year, 10)) {
    return 'module8';
  }
  if (module10Data.marriege_year !== undefined && module10Data.marriege_year !== ''
    && module17Data.start_year !== undefined && module17Data.start_year !== ''
    && module17Data.end_year !== undefined && module17Data.end_year !== ''
    && parseInt(module10Data.marriege_year, 10) >= parseInt(module17Data.start_year, 10)
    && parseInt(module10Data.marriege_year, 10) <= parseInt(module17Data.end_year, 10)) {
    return 'module17';
  }

  return 'empty';
}
/** ******************** Module 10 Family Ends *********************** */

/** ******************** Module 12 Buying a Car Starts *********************** */

// Asking Price
export function askingPrice() {
  module12Data.askingPrice = 0;
  if (module12Data.base_price !== undefined
    && module12Data.destination_charges !== undefined
    && module12Data.car_options !== undefined) {
    module12Data.askingPrice = module12Data.base_price
      + module12Data.destination_charges
      + module12Data.car_options;
  }
  return module12Data.askingPrice;
}
// Discount Cals
export function discountCalcs() {
  module12Data.discountCalcs = 0;
  if (module12Data.discount_in_percentage !== undefined && module12Data.discount_in_percentage > 0
    && module12Data.askingPrice !== undefined && module12Data.askingPrice !== '') {
    module12Data.discountCalcs = (parseFloat(module12Data.discount_in_percentage) * module12Data.askingPrice) / 100;
  }
  return module12Data.discountCalcs;
}
// Rebate Cals
export function rebateCalcs() {
  module12Data.rebateCalcs = 0;
  if (module12Data.rebate !== undefined && module12Data.rebate > 0
    && module12Data.askingPrice !== undefined && module12Data.askingPrice !== '') {
    module12Data.rebateCalcs = (parseFloat(module12Data.rebate) * module12Data.askingPrice) / 100;
  }
  return module12Data.rebateCalcs;
}
// Net Purchase Price
export function netPurchasePrice() {
  module12Data.netPurchasePrice = 0;
  if (module12Data.askingPrice !== undefined
    && module12Data.discountCalcs !== undefined
    && module12Data.rebateCalcs !== undefined) {
    module12Data.netPurchasePrice = parseFloat(module12Data.askingPrice) - parseFloat(module12Data.discountCalcs) - parseFloat(module12Data.rebateCalcs);
  }
  return module12Data.netPurchasePrice;
}

// Trade In Car Value
export function tradeInValue() {
  module12Data.tradeInValue = '0';
  let carsValue = 0;
  let existingCarLoanValue = 0;
  let finalTradeInVal = 0;
  if (module12Data.tradein_existing_car !== undefined && module12Data.tradein_existing_car === 'Yes') {
    if (module12Data.saveChangeExistingCarTradeValue !== undefined && module12Data.saveChangeExistingCarTradeValue === 'Yes') {
      module12Data.tradeInValue = module12Data.tradein_existing_car;
      return module12Data.tradeInValue;
    }
    if (module12Data.purchase_year !== undefined
      && module12Data.purchase_year !== ''
      && depreciationSheduleData !== undefined
      && depreciationSheduleData !== ''
      && depreciationSheduleData[module12Data.purchase_year - 1] !== undefined
      && depreciationSheduleData[parseFloat(module12Data.purchase_year) - 1] !== ''
      && depreciationSheduleData[module12Data.purchase_year - 1]['Automobile Straight-Line Depreciation Second'] !== undefined) {
      carsValue = depreciationSheduleData[module12Data.purchase_year - 1]['Automobile Straight-Line Depreciation Second']['Ending Value'];
    }
    if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.purchase_year] !== undefined) {
      existingCarLoanValue = existingCarLoanData['Summation Data'][module12Data.purchase_year].BeginningBalance;
    }
    finalTradeInVal = carsValue - existingCarLoanValue;
  } else {
    finalTradeInVal = 0;
  }
  module12Data.tradeInValue = finalTradeInVal;
  return module12Data.tradeInValue;
}
//Trade In Car Value
export function tradeInValue2() {
  // module12Data.saveChangeExistingCarTradeValue2 = 'Yes';
  let carsValue2 = 0;
  let existingCarLoanValue2 = 0;
  let finalTradeInVal2 = 0;
  if (module12Data.tradein_existing_car_2 !== undefined && module12Data.tradein_existing_car_2 === 'Yes') {
    if (module12Data.purchase_year !== undefined &&
      module12Data.purchase_year !== '' &&
      carsExistingVehicleDepreciationData !== undefined &&
      carsExistingVehicleDepreciationData !== '' &&
      carsExistingVehicleDepreciationData[module12Data.purchase_year - 1] !== undefined &&
      carsExistingVehicleDepreciationData[parseFloat(module12Data.purchase_year) - 1] !== '' &&
      carsExistingVehicleDepreciationData[module12Data.purchase_year - 1]['Existing Vehicle 2'] !== undefined) {
      carsValue2 = carsExistingVehicleDepreciationData[module12Data.purchase_year - 1]['Existing Vehicle 2']['Ending Balance'];
    }
    if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.purchase_year] !== undefined) {
      existingCarLoanValue2 = existingCarLoanData['Summation Data'][module12Data.purchase_year]['BeginningBalance2'];
    }
    finalTradeInVal2 = carsValue2 - existingCarLoanValue2;
  }
  else {
    finalTradeInVal2 = 0;
  }
  module12Data.tradeInValue2 = finalTradeInVal2;
  return module12Data.tradeInValue2;
}
export function tradeInValue3() {
  // module12Data.saveChangeExistingCarTradeValue2 = 'Yes';
  let carsValue3 = 0;
  let existingCarLoanValue3 = 0;
  let finalTradeInVal3 = 0;
  if (module12Data.tradein_existing_car_3 !== undefined && module12Data.tradein_existing_car_3 === 'Yes') {
    if (module12Data.purchase_year !== undefined &&
      module12Data.purchase_year !== '' &&
      carsExistingVehicleDepreciationData !== undefined &&
      carsExistingVehicleDepreciationData !== '' &&
      carsExistingVehicleDepreciationData[module12Data.purchase_year - 1] !== undefined &&
      carsExistingVehicleDepreciationData[parseFloat(module12Data.purchase_year) - 1] !== '' &&
      carsExistingVehicleDepreciationData[module12Data.purchase_year - 1]['Existing Vehicle 3'] !== undefined) {
      carsValue3 = carsExistingVehicleDepreciationData[module12Data.purchase_year - 1]['Existing Vehicle 3']['Ending Balance'];
    }
    if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.purchase_year] !== undefined) {
      existingCarLoanValue3 = existingCarLoanData['Summation Data'][module12Data.purchase_year]['BeginningBalance3'];
    }
    finalTradeInVal3 = carsValue3 - existingCarLoanValue3;
  }
  else {
    finalTradeInVal3 = 0;
  }
  module12Data.tradeInValue3 = finalTradeInVal3;
  return module12Data.tradeInValue3;
}
export function tradeInValue4() {
  // module12Data.saveChangeExistingCarTradeValue2 = 'Yes';
  let carsValue4 = 0;
  let existingCarLoanValue4 = 0;
  let finalTradeInVal4 = 0;
  if (module12Data.tradein_existing_car_4 !== undefined && module12Data.tradein_existing_car_4 === 'Yes') {
    if (module12Data.purchase_year !== undefined &&
      module12Data.purchase_year !== '' &&
      carsExistingVehicleDepreciationData !== undefined &&
      carsExistingVehicleDepreciationData !== '' &&
      carsExistingVehicleDepreciationData[module12Data.purchase_year - 1] !== undefined &&
      carsExistingVehicleDepreciationData[parseFloat(module12Data.purchase_year) - 1] !== '' &&
      carsExistingVehicleDepreciationData[module12Data.purchase_year - 1]['Existing Vehicle 4'] !== undefined) {
      carsValue4 = carsExistingVehicleDepreciationData[module12Data.purchase_year - 1]['Existing Vehicle 4']['Ending Balance'];
    }
    if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.purchase_year] !== undefined) {
      existingCarLoanValue4 = existingCarLoanData['Summation Data'][module12Data.purchase_year]['BeginningBalance4'];
    }
    finalTradeInVal4 = carsValue4 - existingCarLoanValue4;
  }
  else {
    finalTradeInVal4 = 0;
  }
  module12Data.tradeInValue4 = finalTradeInVal4;
  return module12Data.tradeInValue4;
}
// Adjusted Purchase Price
export function adjustedPurchasePrice() {
  module12Data.adjustedPurchasePrice = 0;
  if (module12Data.netPurchasePrice !== undefined) {
    module12Data.adjustedPurchasePrice = module12Data.netPurchasePrice;
  }
  if (module12Data.tradeInValue !== undefined) {
    module12Data.adjustedPurchasePrice -= module12Data.tradeInValue;
  }
  if (module12Data.tradeInValue2 !== undefined) {
    module12Data.adjustedPurchasePrice -= module12Data.tradeInValue2;
  }
  if (module12Data.tradeInValue3 !== undefined) {
    module12Data.adjustedPurchasePrice -= module12Data.tradeInValue3;
  }
  if (module12Data.tradeInValue4 !== undefined) {
    module12Data.adjustedPurchasePrice -= module12Data.tradeInValue4;
  }
  return module12Data.adjustedPurchasePrice;
}
// Sales Tax Cals
export function salesTaxCalcs() {
  module12Data.salesTaxCalcs = 0;
  if (module12Data.sales_tax_in_percentage !== undefined && module12Data.sales_tax_in_percentage > 0
    && module12Data.adjustedPurchasePrice !== undefined && module12Data.adjustedPurchasePrice !== '') {
    module12Data.salesTaxCalcs = (parseFloat(module12Data.sales_tax_in_percentage) * parseFloat(module12Data.adjustedPurchasePrice)) / 100;
  }
  return module12Data.salesTaxCalcs;
}
// Origination Cals
export function originationFeesCalculations() {
  module12Data.originationFeesCalculations = 0;
  if (module12Data.origination_fees !== undefined && module12Data.origination_fees > 0
    && module12Data.carLoan !== undefined && module12Data.carLoan !== '') {
    module12Data.originationFeesCalculations = (parseFloat(module12Data.origination_fees) * parseFloat(module12Data.carLoan)) / 100;
  }
  return module12Data.originationFeesCalculations;
}
// Adjusted Purchase Price
export function downPaymentCar() {
  module12Data.downPaymentCar = 0;
  if (module12Data.adjustedPurchasePrice !== undefined && module12Data.adjustedPurchasePrice !== ''
    && module12Data.down_payment_in_percentage !== undefined && module12Data.down_payment_in_percentage !== '') {
    module12Data.downPaymentCar = (parseFloat(module12Data.adjustedPurchasePrice) * module12Data.down_payment_in_percentage) / 100;
  }
  return module12Data.downPaymentCar;
}
// Total Purchase Price
export function totalPurchasePrice() {
  module12Data.totalPurchasePrice = 0;
  if (module12Data.registration_and_documentation_fees !== undefined
    && module12Data.salesTaxCalcs !== undefined
    && module12Data.downPaymentCar !== undefined) {
    module12Data.totalPurchasePrice = module12Data.registration_and_documentation_fees
      + parseFloat(module12Data.salesTaxCalcs)
      + parseFloat(module12Data.downPaymentCar);
  }
  return module12Data.totalPurchasePrice;
}

// Total Buying Costs
export function totalBuyingCosts() {
  module12Data.totalBuyingCosts = 0;
  if (module12Data.registration_and_documentation_fees !== undefined && module12Data.registration_and_documentation_fees !== ''
    && module12Data.salesTaxCalcs !== undefined && module12Data.salesTaxCalcs !== '') {
    module12Data.totalBuyingCosts = parseFloat(module12Data.salesTaxCalcs) + module12Data.registration_and_documentation_fees;
  }
  return module12Data.totalBuyingCosts;
}

// Car Loan
export function carLoan() {
  module12Data.carLoan = 0;
  if (module12Data.adjustedPurchasePrice !== undefined && module12Data.adjustedPurchasePrice !== ''
    && module12Data.downPaymentCar !== undefined && module12Data.downPaymentCar !== '') {
    module12Data.carLoan = parseFloat(module12Data.adjustedPurchasePrice) - parseFloat(module12Data.downPaymentCar);
  }
  return module12Data.carLoan;
}

// Term of the Loan in Years
export function termOfTheLoanYears() {
  module12Data.termOfTheLoanYears = 0;
  if (module12Data.term_of_the_loan !== undefined && module12Data.term_of_the_loan !== '') {
    module12Data.termOfTheLoanYears = module12Data.term_of_the_loan / 12;
  }
  return module12Data.termOfTheLoanYears;
}

// Monthly Payment
export function monthlyPayment() {
  module12Data.monthlyPayment = 0;
  if (carLoan1Data['Car Loan1'] !== undefined && carLoan1Data['Car Loan1']['Original Payment'] !== undefined && carLoan1Data['Car Loan1']['Original Payment'] !== '') {
    module12Data.monthlyPayment = carLoan1Data['Car Loan1']['Original Payment'];
  }
  return module12Data.monthlyPayment;
}
// Yearly Payment
export function carYearlyPayment() {
  module12Data.yearlyPayment = 0;
  if (module12Data.monthlyPayment !== undefined && module12Data.monthlyPayment !== '') {
    module12Data.yearlyPayment = parseFloat(module12Data.monthlyPayment) * 12;
  }
  return module12Data.yearlyPayment;
}
// Total Interest Value
export function totalCarInterest() {
  module12Data.totalCarInterest = 0;
  if (carLoan1Data['Total Interest Paid'] !== undefined && carLoan1Data['Total Interest Paid'] !== '') {
    module12Data.totalCarInterest = carLoan1Data['Total Interest Paid'];
  }
  return module12Data.totalCarInterest;
}
// Total Interest Value
export function totalPrincipalAndInterest() {
  module12Data.totalPrincipalAndInterest = 0;
  if (carLoan1Data['Total Paid'] !== undefined && carLoan1Data['Total Paid'] !== '') {
    module12Data.totalPrincipalAndInterest = carLoan1Data['Total Paid'];
  }
  return module12Data.totalPrincipalAndInterest;
}
// First Car Maintanance Value Calcs
export function firstCarMaintenanceCalcs() {
  module12Data.firstCarMaintenanceCalcs = 0;
  if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && module12Data.first_car_maintenance !== undefined && module12Data.first_car_maintenance !== '') {
    module12Data.firstCarMaintenanceCalcs = module12Data.first_car_maintenance * 12;
  }
  return module12Data.firstCarMaintenanceCalcs;
}
// First Car Insurance Value Calcs
export function firstCarInsuranceCalcs() {
  module12Data.firstCarInsuranceCalcs = 0;
  if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && module12Data.first_car_insurance !== undefined && module12Data.first_car_insurance !== '') {
    module12Data.firstCarInsuranceCalcs = module12Data.first_car_insurance * 12;
  }
  return module12Data.firstCarInsuranceCalcs;
}
// First Car Gas Value Calcs
export function firstCarGasCalcs() {
  module12Data.firstCarGasCalcs = 0;
  if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && module12Data.first_car_gas !== undefined && module12Data.first_car_gas !== '') {
    module12Data.firstCarGasCalcs = module12Data.first_car_gas * 12;
  }
  return module12Data.firstCarGasCalcs;
}
// First Car Monthly Expenses
export function totalMonthlyExpensesFirstCar() {
  module12Data.totalMonthlyExpensesFirstCar = 0;
  if (module12Data.first_car_maintenance !== undefined && module12Data.first_car_maintenance !== '' && module12Data.first_car_insurance !== undefined && module12Data.first_car_gas !== undefined) {
    module12Data.totalMonthlyExpensesFirstCar = module12Data.first_car_maintenance + module12Data.first_car_insurance + module12Data.first_car_gas;
  }
  return module12Data.totalMonthlyExpensesFirstCar;
}
// First Car Annual Expenses
export function totalAnnualExpensesFirstCar() {
  module12Data.totalAnnualExpensesFirstCar = 0;
  if (module12Data.totalMonthlyExpensesFirstCar !== undefined && module12Data.totalMonthlyExpensesFirstCar !== '') {
    module12Data.totalAnnualExpensesFirstCar = module12Data.totalMonthlyExpensesFirstCar * 12;
  }
  return module12Data.totalAnnualExpensesFirstCar;
}
// Destination Charges
export function destinationChargesSummary() {
  module12Data.destinationChargesSummary = 0;
  if (module12Data.destination_charges !== undefined && module12Data.destination_charges !== '') {
    module12Data.destinationChargesSummary = module12Data.destination_charges;
  }
  return module12Data.destinationChargesSummary;
}
// First car Options
export function getCarOptions() {
  module12Data.getCarOptions = 0;
  if (module12Data.car_options !== undefined && module12Data.car_options !== '') {
    module12Data.getCarOptions = module12Data.car_options;
  }
  return module12Data.getCarOptions;
}
// Investment Analysis PurchasePrice
export function investmentAnalysisPurchasePrice() {
  module12Data.investmentAnalysisPurchasePrice = 0;
  if (module12Data.base_price !== undefined && module12Data.base_price !== '') {
    module12Data.investmentAnalysisPurchasePrice = module12Data.base_price;
  }
  return module12Data.investmentAnalysisPurchasePrice;
}
// Investment Analysis Discount
export function investmentAnalysisDiscount() {
  module12Data.investmentAnalysisDiscount = 0;
  if (module12Data.discountCalcs !== undefined && module12Data.discountCalcs !== '') {
    module12Data.investmentAnalysisDiscount = -(parseFloat(module12Data.discountCalcs));
  }
  return module12Data.investmentAnalysisDiscount;
}
// Investment Analysis Rebate
export function investmentAnalysisRebate() {
  module12Data.investmentAnalysisRebate = 0;
  if (module12Data.rebateCalcs !== undefined && module12Data.rebateCalcs !== '') {
    module12Data.investmentAnalysisRebate = -(parseFloat(module12Data.rebateCalcs));
  }
  return module12Data.investmentAnalysisRebate;
}
// Investment Analysis Trade-In Value
export function investmentAnalysisTradeIn() {
  module12Data.investmentAnalysisTradeIn = 0;
  if (module12Data.tradeInValue !== undefined && module12Data.tradeInValue !== '') {
    module12Data.investmentAnalysisTradeIn = -module12Data.tradeInValue;
  }
  return module12Data.investmentAnalysisTradeIn;
}
// Investment Analysis Sales Tax Value
export function investmentAnalysisSalesTax() {
  module12Data.investmentAnalysisSalesTax = 0;
  if (module12Data.salesTaxCalcs !== undefined && module12Data.salesTaxCalcs !== '') {
    module12Data.investmentAnalysisSalesTax = module12Data.salesTaxCalcs;
  }
  return module12Data.investmentAnalysisSalesTax;
}
// Investment Analysis Registration Fees Value
export function investmentAnalysisRegistrationFees() {
  module12Data.investmentAnalysisRegistrationFees = 0;
  if (module12Data.registration_and_documentation_fees !== undefined && module12Data.registration_and_documentation_fees !== '') {
    module12Data.investmentAnalysisRegistrationFees = module12Data.registration_and_documentation_fees;
  }
  return module12Data.investmentAnalysisRegistrationFees;
}
// Investment Analysis Total Purchase Price Value
export function investmentAnalysisTotalPurchasePrice() {
  module12Data.investmentAnalysisTotalPurchasePrice = 0;
  module12Data.investmentAnalysisTotalPurchasePrice = module12Data.investmentAnalysisPurchasePrice
    + module12Data.destinationChargesSummary
    + module12Data.investmentAnalysisDiscount
    + module12Data.investmentAnalysisRebate
    + module12Data.investmentAnalysisTradeIn
    + module12Data.getCarOptions;
  return module12Data.investmentAnalysisTotalPurchasePrice;
}
// Investment Analysis Adjusted Purchase Price
export function investmentAnalysisAdjustedPurchasePrice() {
  module12Data.investmentAnalysisAdjustedPurchasePrice = 0;
  if (module12Data.adjustedPurchasePrice !== undefined && module12Data.adjustedPurchasePrice !== '') {
    module12Data.investmentAnalysisAdjustedPurchasePrice = parseFloat(module12Data.adjustedPurchasePrice);
  }
  return module12Data.investmentAnalysisAdjustedPurchasePrice;
}
// Down Payment
export function carDownPayment() {
  module12Data.carDownPayment = 0;
  if (module12Data.downPaymentCar !== undefined && module12Data.downPaymentCar !== '') {
    module12Data.carDownPayment = parseFloat(module12Data.downPaymentCar);
  }
  return module12Data.carDownPayment;
}
// Loan Points
export function carLoanPoints() {
  module12Data.carLoanPoints = 0;
  if (module12Data.origination_fees !== undefined && parseFloat(module12Data.origination_fees) > 0) {
    module12Data.carLoanPoints = module12Data.carLoan * (parseFloat(module12Data.origination_fees) / 100);
  }
  return module12Data.carLoanPoints;
}
// Cash Needed at Purchase
export function cashNeededAtPurchase() {
  module12Data.cashNeededAtPurchase = module12Data.carDownPayment
    + module12Data.investmentAnalysisSalesTax
    + module12Data.investmentAnalysisRegistrationFees
    + module12Data.carLoanPoints;
  return module12Data.cashNeededAtPurchase;
}
// Total Cash Available
export function totalCashAvailable() {
  module12Data.totalCashAvailable = 0;
  //  && module1Data.current_cash_balance !== undefined && module1Data.current_cash_balance !== ''
  if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '' && module1Data.start_year !== undefined && module12Data.purchase_year === module1Data.start_year) {
    module12Data.totalCashAvailable = module1Data.current_cash_balance;
  } else if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== ''
    && cashFlowData[module12Data.purchase_year - 1] !== undefined) {
    module12Data.totalCashAvailable = cashFlowData[module12Data.purchase_year - 1]['Beginning Cash Balance'];
  } else {
    module12Data.totalCashAvailable = 0;
  }
  return module12Data.totalCashAvailable;
}

// Total Liquid Assets Available
export function totalLiquid() {
  module12Data.totalLiquid = 0;
  if (module12Data.purchase_year !== undefined
    && module1Data.start_year !== undefined
    && module12Data.purchase_year === module1Data.start_year) {
    if (module9Data.short_term_begining_balance !== undefined
      && module9Data.long_term_begining_balance !== undefined) {
      module12Data.totalLiquid = module9Data.short_term_begining_balance
        + module9Data.long_term_begining_balance;
    }
  } else if (module12Data.purchase_year !== undefined && module12Data.purchase_year !== '') {
    if (balanceSheetData[parseInt(module12Data.purchase_year, 10) - 1] !== undefined
      && balanceSheetData[parseInt(module12Data.purchase_year, 10) - 1].Investments['Short-Term Investments'] !== undefined
      && balanceSheetData[parseInt(module12Data.purchase_year, 10) - 1].Investments['Long-Term Investments'] !== undefined) {
      module12Data.totalLiquid = parseFloat(balanceSheetData[parseInt(module12Data.purchase_year, 10) - 1].Investments['Short-Term Investments'])
        + parseFloat(balanceSheetData[parseInt(module12Data.purchase_year, 10) - 1].Investments['Long-Term Investments']);
    }
  }
  return module12Data.totalLiquid;
}

// Total Cash Available
export function totalCashAvailableYear(year) {
  module12Data.totalCashAvailableYear = 0;
  if (year !== undefined && year !== '') {
    // if (module1Data.start_year !== undefined && year === module1Data.start_year
    //   && module1Data.current_cash_balance !== undefined && module1Data.current_cash_balance !== '') {
    //   module12Data.totalCashAvailableYear = module1Data.current_cash_balance;
    // } else
    if (cashFlowData[year] !== undefined && cashFlowData[year]['Beginning Cash Balance'] !== undefined) {
      module12Data.totalCashAvailableYear = cashFlowData[year]['Beginning Cash Balance'];
    } else {
      module12Data.totalCashAvailableYear = 0;
    }
  }
  return module12Data.totalCashAvailableYear;
}

// Total Liquid Assets Available
export function totalLiquidYear(year) {
  module12Data.totalLiquidYear = 0;
  if (year !== undefined
    && module1Data.start_year !== undefined && year === module1Data.start_year) {
    if (module9Data.short_term_begining_balance !== undefined
      && module9Data.long_term_begining_balance !== undefined) {
      module12Data.totalLiquidYear = module9Data.short_term_begining_balance
        + module9Data.long_term_begining_balance;
    }
  } else if (year !== undefined && year !== '') {
    if (balanceSheetData[year] !== undefined
      && balanceSheetData[year].Investments['Short-Term Investments'] !== undefined
      && balanceSheetData[year].Investments['Long-Term Investments'] !== undefined) {
      module12Data.totalLiquidYear = parseFloat(balanceSheetData[year].Investments['Short-Term Investments'])
        + parseFloat(balanceSheetData[year].Investments['Long-Term Investments']);
    }
  }
  return module12Data.totalLiquidYear;
}

// Outstanding Car Loan
export function outstandingCarLoan() {
  module12Data.outstandingCarLoan = 0;
  if (module12Data.carLoan !== undefined && module12Data.carLoan !== '' && module12Data.carLoan > 0) {
    module12Data.outstandingCarLoan = parseFloat(module12Data.carLoan);
  }
  return module12Data.outstandingCarLoan;
}
// Total Principal & Interest
export function investmentAnalysisTotalPrincipal() {
  module12Data.investmentAnalysisTotalPrincipal = 0;
  if (module12Data.carLoan !== undefined && module12Data.carLoan !== '' && module12Data.carLoan > 0) {
    if (carLoan1Data['Total Paid'] !== undefined && carLoan1Data['Total Paid'] !== '') {
      module12Data.investmentAnalysisTotalPrincipal = carLoan1Data['Total Paid'];
    }
  }
  return module12Data.investmentAnalysisTotalPrincipal;
}

/** **** */
// More Asking Price
export function moreAskingPrice() {
  module12Data.moreAskingPrice = 0;
  if (module12Data.second_base_price !== undefined && module12Data.second_base_price !== ''
    && module12Data.second_destination_charges !== undefined && module12Data.second_destination_charges !== '') {
    module12Data.moreAskingPrice = module12Data.second_base_price
      + module12Data.second_destination_charges
      + module12Data.second_options;
  }
  return module12Data.moreAskingPrice;
}
// Discount Cals
export function moreDiscountCalcs() {
  module12Data.moreDiscountCalcs = 0;
  if (module12Data.second_discount !== undefined && module12Data.second_discount > 0 && module12Data.moreAskingPrice !== undefined && module12Data.moreAskingPrice !== '') {
    module12Data.moreDiscountCalcs = (parseFloat(module12Data.second_discount) * module12Data.moreAskingPrice) / 100;
  }
  return module12Data.moreDiscountCalcs;
}
// Rebate Cals
export function moreRebateCalcs() {
  module12Data.moreRebateCalcs = 0;
  if (module12Data.second_rebate !== undefined && module12Data.second_rebate > 0 && module12Data.moreAskingPrice !== undefined && module12Data.moreAskingPrice !== '') {
    module12Data.moreRebateCalcs = (parseFloat(module12Data.second_rebate) * module12Data.moreAskingPrice) / 100;
  }
  return module12Data.moreRebateCalcs;
}
// More Net Purchase Price
export function moreNetPurchasePrice() {
  module12Data.moreNetPurchasePrice = 0;
  if (module12Data.moreAskingPrice !== undefined
    && module12Data.moreDiscountCalcs !== undefined
    && module12Data.moreRebateCalcs !== undefined) {
    module12Data.moreNetPurchasePrice = parseFloat(module12Data.moreAskingPrice) - parseFloat(module12Data.moreDiscountCalcs) - parseFloat(module12Data.moreRebateCalcs);
  }
  return module12Data.moreNetPurchasePrice;
}

export function moreTradeInValue() {

  let carsValue = 0;
  let carLoanValue = 0;
  let finalTradeValue = 0;
  if (module12Data.moretradein_existing_car !== undefined && module12Data.moretradein_existing_car === 'Yes') {
    if (module12Data.second_purchase_year !== undefined &&
      module12Data.second_purchase_year !== '' &&
      carsExistingVehicleDepreciationData !== undefined &&
      carsExistingVehicleDepreciationData !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1] !== undefined &&
      carsExistingVehicleDepreciationData[parseFloat(module12Data.second_purchase_year) - 1] !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 1'] !== undefined) {
      carsValue = carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 1']['Ending Balance'];
    }
    if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.second_purchase_year] !== undefined) {
      carLoanValue = existingCarLoanData['Summation Data'][module12Data.second_purchase_year]['BeginningBalance'];
    }
    finalTradeValue = carsValue - carLoanValue;
  }
  else {
    finalTradeValue = 0;
  }
  module12Data.moreTradeInValue = finalTradeValue;
  return module12Data.moreTradeInValue;
}

export function moreTradeInValue2() {

  let carsValue = 0;
  let carLoanValue = 0;
  let finalTradeValue = 0;
  if (module12Data.moretradein_existing_car_2 !== undefined && module12Data.moretradein_existing_car_2 === 'Yes') {
    if (module12Data.second_purchase_year !== undefined &&
      module12Data.second_purchase_year !== '' &&
      carsExistingVehicleDepreciationData !== undefined &&
      carsExistingVehicleDepreciationData !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1] !== undefined &&
      carsExistingVehicleDepreciationData[parseFloat(module12Data.second_purchase_year) - 1] !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 2'] !== undefined) {
      carsValue = carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 2']['Ending Balance'];
    }
    if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.second_purchase_year] !== undefined) {
      carLoanValue = existingCarLoanData['Summation Data'][module12Data.second_purchase_year]['BeginningBalance2'];
    }
    finalTradeValue = carsValue - carLoanValue;
  }
  else {
    finalTradeValue = 0;
  }
  module12Data.moreTradeInValue2 = finalTradeValue;
  return module12Data.moreTradeInValue2;
}
export function moreTradeInValue3() {
  let carsValue = 0;
  let carLoanValue = 0;
  let finalTradeValue = 0;
  if (module12Data.moretradein_existing_car_3 !== undefined && module12Data.moretradein_existing_car_3 === 'Yes') {
    if (module12Data.second_purchase_year !== undefined &&
      module12Data.second_purchase_year !== '' &&
      carsExistingVehicleDepreciationData !== undefined &&
      carsExistingVehicleDepreciationData !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1] !== undefined &&
      carsExistingVehicleDepreciationData[parseFloat(module12Data.second_purchase_year) - 1] !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 3'] !== undefined) {
      carsValue = carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 3']['Ending Balance'];
    }
    if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.second_purchase_year] !== undefined) {
      carLoanValue = existingCarLoanData['Summation Data'][module12Data.second_purchase_year]['BeginningBalance3'];
    }
    finalTradeValue = carsValue - carLoanValue;
  }
  else {
    finalTradeValue = 0;
  }
  module12Data.moreTradeInValue3 = finalTradeValue;
  return module12Data.moreTradeInValue3;
}

export function moreTradeInValue4() {

  let carsValue = 0;
  let carLoanValue = 0;
  let finalTradeValue = 0;
  if (module12Data.moretradein_existing_car_4 !== undefined && module12Data.moretradein_existing_car_4 === 'Yes') {
    if (module12Data.second_purchase_year !== undefined &&
      module12Data.second_purchase_year !== '' &&
      carsExistingVehicleDepreciationData !== undefined &&
      carsExistingVehicleDepreciationData !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1] !== undefined &&
      carsExistingVehicleDepreciationData[parseFloat(module12Data.second_purchase_year) - 1] !== '' &&
      carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 4'] !== undefined) {
      carsValue = carsExistingVehicleDepreciationData[module12Data.second_purchase_year - 1]['Existing Vehicle 4']['Ending Balance'];
    }
    if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && existingCarLoanData !== undefined && existingCarLoanData !== '' && existingCarLoanData['Summation Data'] !== undefined && existingCarLoanData['Summation Data'] !== '' && existingCarLoanData['Summation Data'][module12Data.second_purchase_year] !== undefined) {
      carLoanValue = existingCarLoanData['Summation Data'][module12Data.second_purchase_year]['BeginningBalance4'];
    }
    finalTradeValue = carsValue - carLoanValue;
  }
  else {
    finalTradeValue = 0;
  }
  module12Data.moreTradeInValue4 = finalTradeValue;
  return module12Data.moreTradeInValue4;
}

export function moreTradeInValue5() {
  module12Data.moreTradeInValue = '0';
  let carsValue = 0;
  let carLoanValue = 0;
  let finalTradeValue = 0;
  if (module12Data.second_purchase_year !== undefined
    && module12Data.second_purchase_year !== ''
    && depreciationSheduleData !== undefined
    && depreciationSheduleData !== ''
    && depreciationSheduleData[module12Data.second_purchase_year - 1] !== undefined
    && depreciationSheduleData[parseFloat(module12Data.second_purchase_year) - 1] !== ''
    && depreciationSheduleData[module12Data.second_purchase_year - 1]['Understanding Depreciation'] !== undefined) {
    carsValue = depreciationSheduleData[module12Data.second_purchase_year - 1]['Understanding Depreciation']['Ending Value'];
  }
  if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && carLoan1Data !== undefined && carLoan1Data !== '' && carLoan1Data['Summation Data'] !== undefined && carLoan1Data['Summation Data'] !== '' && carLoan1Data['Summation Data'][module12Data.second_purchase_year] !== undefined) {
    carLoanValue = carLoan1Data['Summation Data'][module12Data.second_purchase_year].BeginningBalance;
  }
  finalTradeValue = carsValue - carLoanValue;
  module12Data.moreTradeInValue5 = finalTradeValue;
  return module12Data.moreTradeInValue5;
}
// Adjusted Purchase Price
export function moreAdjustedPurchasePrice() {
  module12Data.moreAdjustedPurchasePrice = 0;
  if (module12Data.moreNetPurchasePrice !== undefined
    && module12Data.moreTradeInValue !== undefined) {
    module12Data.moreAdjustedPurchasePrice = module12Data.moreNetPurchasePrice
      - parseFloat(module12Data.moreTradeInValue);
  }
  return module12Data.moreAdjustedPurchasePrice;
}
// Sales Tax Cals
export function moreSalesTaxCalcs() {
  module12Data.moreSalesTaxCalcs = 0;
  if (module12Data.second_sales_tax !== undefined && module12Data.second_sales_tax > 0 && module12Data.moreAdjustedPurchasePrice !== undefined && module12Data.moreAdjustedPurchasePrice !== '') {
    module12Data.moreSalesTaxCalcs = (parseFloat(module12Data.second_sales_tax) * parseFloat(module12Data.moreAdjustedPurchasePrice)) / 100;
  }
  return module12Data.moreSalesTaxCalcs;
}
// Down Payment
export function moreDownPaymentCar() {
  module12Data.moreDownPaymentCar = 0;
  if (module12Data.moreAdjustedPurchasePrice !== undefined && module12Data.moreAdjustedPurchasePrice !== ''
    && module12Data.second_down_payment_in_percentage !== undefined && module12Data.second_down_payment_in_percentage !== '') {
    module12Data.moreDownPaymentCar = (parseFloat(module12Data.moreAdjustedPurchasePrice) * module12Data.second_down_payment_in_percentage) / 100;
  }
  return module12Data.moreDownPaymentCar;
}

// Total Purchase Price
export function moreTotalPurchasePrice() {
  module12Data.moreTotalPurchasePrice = 0;
  if (module12Data.second_registration_and_documentation_fees !== undefined
    && module12Data.moreSalesTaxCalcs !== undefined
    && module12Data.moreDownPaymentCar !== undefined) {
    module12Data.moreTotalPurchasePrice = module12Data.second_registration_and_documentation_fees
      + parseFloat(module12Data.moreSalesTaxCalcs)
      + parseFloat(module12Data.moreDownPaymentCar);
  }
  return module12Data.moreTotalPurchasePrice;
}

// Second car Origination Cals
export function secondOriginationFeesCalculations() {
  module12Data.secondOriginationFeesCalculations = 0;
  if (module12Data.second_origination_fees !== undefined && module12Data.second_origination_fees > 0
    && module12Data.moreCarLoan !== undefined && module12Data.moreCarLoan !== '') {
    module12Data.secondOriginationFeesCalculations = (parseFloat(module12Data.second_origination_fees) * parseFloat(module12Data.moreCarLoan)) / 100;
  }
  return module12Data.secondOriginationFeesCalculations;
}
// Total Buying Costs
export function moreTotalBuyingCosts() {
  module12Data.moreTotalBuyingCosts = 0;
  if (module12Data.second_registration_and_documentation_fees !== undefined && module12Data.second_registration_and_documentation_fees !== ''
    && module12Data.moreSalesTaxCalcs !== undefined && module12Data.moreSalesTaxCalcs !== '') {
    module12Data.moreTotalBuyingCosts = parseFloat(module12Data.moreSalesTaxCalcs) + module12Data.second_registration_and_documentation_fees;
  }
  return module12Data.moreTotalBuyingCosts;
}
// Car Loan
export function moreCarLoan() {
  module12Data.moreCarLoan = 0;
  if (module12Data.moreAdjustedPurchasePrice !== undefined && module12Data.moreAdjustedPurchasePrice !== ''
    && module12Data.moreDownPaymentCar !== undefined && module12Data.moreDownPaymentCar !== '') {
    module12Data.moreCarLoan = parseFloat(module12Data.moreAdjustedPurchasePrice) - parseFloat(module12Data.moreDownPaymentCar);
  }
  return module12Data.moreCarLoan;
}
// Term of the Loan in Years
export function moreTermOfTheLoanYears() {
  module12Data.moreTermOfTheLoanYears = 0;
  if (module12Data.second_term_of_the_loan !== undefined && module12Data.second_term_of_the_loan !== '') {
    module12Data.moreTermOfTheLoanYears = module12Data.second_term_of_the_loan / 12;
  }
  return module12Data.moreTermOfTheLoanYears;
}
// Monthly Payment
export function moreMonthlyPayment() {
  module12Data.moreMonthlyPayment = 0;
  if (carLoan2Data !== undefined && carLoan2Data['Car Loan2'] !== undefined && carLoan2Data['Car Loan2']['Original Payment'] !== undefined && carLoan2Data['Car Loan2']['Original Payment'] !== '') {
    module12Data.moreMonthlyPayment = carLoan2Data['Car Loan2']['Original Payment'];
  }
  return module12Data.moreMonthlyPayment;
}
// Yearly Payment
export function moreYearlyPayment() {
  module12Data.moreYearlyPayment = 0;
  if (module12Data.moreMonthlyPayment !== undefined && module12Data.moreMonthlyPayment !== '') {
    module12Data.moreYearlyPayment = parseFloat(module12Data.moreMonthlyPayment) * 12;
  }
  return module12Data.moreYearlyPayment;
}
// Total Interest Value
export function moreTotalCarInterest() {
  module12Data.moreTotalCarInterest = 0;
  if (carLoan2Data['Total Interest Paid'] !== undefined && carLoan2Data['Total Interest Paid'] !== '') {
    module12Data.moreTotalCarInterest = carLoan2Data['Total Interest Paid'];
  }
  return module12Data.moreTotalCarInterest;
}
// Total Interest Value
export function moreTotalPrincipalAndInterest() {
  module12Data.moreTotalPrincipalAndInterest = 0;
  if (carLoan2Data['Total Paid'] !== undefined && carLoan2Data['Total Paid'] !== '') {
    module12Data.moreTotalPrincipalAndInterest = carLoan2Data['Total Paid'];
  }
  return module12Data.moreTotalPrincipalAndInterest;
}

// First Car Maintanance Value Calcs
export function secondCarMaintenanceCalcs() {
  module12Data.secondCarMaintenanceCalcs = 0;
  if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && module12Data.second_car_maintenance !== undefined && module12Data.second_car_maintenance !== '') {
    module12Data.secondCarMaintenanceCalcs = module12Data.second_car_maintenance * 12;
  }
  return module12Data.secondCarMaintenanceCalcs;
}
// First Car Insurance Value Calcs
export function secondCarInsuranceCalcs() {
  module12Data.secondCarInsuranceCalcs = 0;
  if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && module12Data.second_car_insurance !== undefined && module12Data.second_car_insurance !== '') {
    module12Data.secondCarInsuranceCalcs = module12Data.second_car_insurance * 12;
  }
  return module12Data.secondCarInsuranceCalcs;
}
// First Car Gas Value Calcs
export function secondCarGasCalcs() {
  module12Data.secondCarGasCalcs = 0;
  if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && module12Data.second_car_gas !== undefined && module12Data.second_car_gas !== '') {
    module12Data.secondCarGasCalcs = module12Data.second_car_gas * 12;
  }
  return module12Data.secondCarGasCalcs;
}

// First Car Monthly Expenses
export function totalMonthlyExpensesSecondCar() {
  module12Data.totalMonthlyExpensesSecondCar = 0;
  if (module12Data.second_car_maintenance !== undefined && module12Data.second_car_insurance !== undefined && module12Data.second_car_gas !== undefined) {
    module12Data.totalMonthlyExpensesSecondCar = module12Data.second_car_maintenance + module12Data.second_car_insurance + module12Data.second_car_gas;
  }
  return module12Data.totalMonthlyExpensesSecondCar;
}
// First Car Annual Expenses
export function totalAnnualExpensesSecondCar() {
  module12Data.totalAnnualExpensesSecondCar = 0;
  if (module12Data.totalMonthlyExpensesSecondCar !== undefined && module12Data.totalMonthlyExpensesSecondCar !== '') {
    module12Data.totalAnnualExpensesSecondCar = module12Data.totalMonthlyExpensesSecondCar * 12;
  }
  return module12Data.totalAnnualExpensesSecondCar;
}

// Investment Analysis PurchasePrice
export function moreInvestmentAnalysisPurchasePrice() {
  module12Data.moreInvestmentAnalysisPurchasePrice = 0;
  if (module12Data.second_base_price !== undefined && module12Data.second_base_price !== '') {
    module12Data.moreInvestmentAnalysisPurchasePrice = module12Data.second_base_price;
  }
  return module12Data.moreInvestmentAnalysisPurchasePrice;
}
// Second car Destination Charges
export function moreDestinationChargesSummary() {
  module12Data.moreDestinationChargesSummary = 0;
  if (module12Data.second_destination_charges !== undefined && module12Data.second_destination_charges !== '') {
    module12Data.moreDestinationChargesSummary = module12Data.second_destination_charges;
  }
  return module12Data.moreDestinationChargesSummary;
}
// Second car Options
export function getMoreCarOptions() {
  module12Data.getMoreCarOptions = 0;
  if (module12Data.second_options !== undefined && module12Data.second_options !== '') {
    module12Data.getMoreCarOptions = module12Data.second_options;
  }
  return module12Data.getMoreCarOptions;
}
// Investment Analysis Discount
export function moreInvestmentAnalysisDiscount() {
  module12Data.moreInvestmentAnalysisDiscount = 0;
  if (module12Data.moreDiscountCalcs !== undefined && module12Data.moreDiscountCalcs !== '') {
    module12Data.moreInvestmentAnalysisDiscount = -(parseFloat(module12Data.moreDiscountCalcs));
  }
  return module12Data.moreInvestmentAnalysisDiscount;
}
// Investment Analysis Rebate
export function moreInvestmentAnalysisRebate() {
  module12Data.moreInvestmentAnalysisRebate = 0;
  if (module12Data.moreRebateCalcs !== undefined && module12Data.moreRebateCalcs !== '') {
    module12Data.moreInvestmentAnalysisRebate = -(parseFloat(module12Data.moreRebateCalcs));
  }
  return module12Data.moreInvestmentAnalysisRebate;
}
// Investment Analysis Trade-In Value
export function moreInvestmentAnalysisTradeIn() {
  module12Data.moreInvestmentAnalysisTradeIn = 0;
  if (module12Data.moreTradeInValue !== undefined && module12Data.moreTradeInValue !== '') {
    module12Data.moreInvestmentAnalysisTradeIn = -module12Data.moreTradeInValue;
  }
  return module12Data.moreInvestmentAnalysisTradeIn;
}

// Investment Analysis Sales Tax Value
export function moreInvestmentAnalysisSalesTax() {
  module12Data.moreInvestmentAnalysisSalesTax = 0;
  if (module12Data.moreSalesTaxCalcs !== undefined && module12Data.moreSalesTaxCalcs !== '') {
    module12Data.moreInvestmentAnalysisSalesTax = module12Data.moreSalesTaxCalcs;
  }
  return module12Data.moreInvestmentAnalysisSalesTax;
}
// Investment Analysis Registration Fees Value
export function moreInvestmentAnalysisRegistrationFees() {
  module12Data.moreInvestmentAnalysisRegistrationFees = 0;
  if (module12Data.second_registration_and_documentation_fees !== undefined && module12Data.second_registration_and_documentation_fees !== '') {
    module12Data.moreInvestmentAnalysisRegistrationFees = module12Data.second_registration_and_documentation_fees;
  }
  return module12Data.moreInvestmentAnalysisRegistrationFees;
}

// Investment Analysis Trade-In Value
export function moreInvestmentAnalysisTotalPurchasePrice() {
  module12Data.moreInvestmentAnalysisTotalPurchasePrice = 0;

  module12Data.moreInvestmentAnalysisTotalPurchasePrice = module12Data.moreInvestmentAnalysisPurchasePrice
    + module12Data.moreDestinationChargesSummary
    + module12Data.getMoreCarOptions
    + module12Data.moreInvestmentAnalysisDiscount
    + module12Data.moreInvestmentAnalysisRebate
    + module12Data.moreInvestmentAnalysisTradeIn;

  return module12Data.moreInvestmentAnalysisTotalPurchasePrice;
}
// Investment Analysis Adjusted Purchase Price
export function moreInvestmentAnalysisAdjustedPurchasePrice() {
  module12Data.moreInvestmentAnalysisAdjustedPurchasePrice = 0;
  if (module12Data.moreAdjustedPurchasePrice !== undefined && module12Data.moreAdjustedPurchasePrice !== '') {
    module12Data.moreInvestmentAnalysisAdjustedPurchasePrice = parseFloat(module12Data.moreAdjustedPurchasePrice);
  }
  return module12Data.moreInvestmentAnalysisAdjustedPurchasePrice;
}
// Down Payment
export function moreCarDownPayment() {
  module12Data.moreCarDownPayment = 0;
  if (module12Data.moreDownPaymentCar !== undefined && module12Data.moreDownPaymentCar !== '') {
    module12Data.moreCarDownPayment = parseFloat(module12Data.moreDownPaymentCar);
  }
  return module12Data.moreCarDownPayment;
}
// Loan Points
export function moreCarLoanPoints() {
  module12Data.moreCarLoanPoints = 0;
  if (module12Data.second_origination_fees !== undefined && parseFloat(module12Data.second_origination_fees) > 0) {
    module12Data.moreCarLoanPoints = module12Data.moreCarLoan * (parseFloat(module12Data.second_origination_fees) / 100);
  }
  return module12Data.moreCarLoanPoints;
}
// Cash Needed at Purchase
export function moreCashNeededAtPurchase() {
  module12Data.moreCashNeededAtPurchase = module12Data.moreCarDownPayment
    + module12Data.moreInvestmentAnalysisSalesTax
    + module12Data.moreInvestmentAnalysisRegistrationFees
    + module12Data.moreCarLoanPoints;
  return module12Data.moreCashNeededAtPurchase;
}
// Total Cash Available
export function moreTotalCashAvailable() {
  module12Data.moreTotalCashAvailable = 0;
  // if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && module12Data.second_purchase_year === module1Data.start_year && module1Data.current_cash_balance !== undefined && module1Data.current_cash_balance !== '') {
  //   module12Data.moreTotalCashAvailable = module1Data.current_cash_balance;
  // } else 
  if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
    && cashFlowData[module12Data.second_purchase_year - 1] !== undefined && cashFlowData[module12Data.second_purchase_year] !== '') {
    module12Data.moreTotalCashAvailable = cashFlowData[module12Data.second_purchase_year - 1]['Beginning Cash Balance'];
  } else {
    module12Data.moreTotalCashAvailable = 0;
  }
  return module12Data.moreTotalCashAvailable;
}
// Total Liquid Assets Available
export function moreTotalLiquid() {
  module12Data.moreTotalLiquid = 0;
  if (module12Data.second_purchase_year !== undefined
    && module1Data.start_year !== undefined
    && module12Data.second_purchase_year === module1Data.start_year) {
    if (module9Data.short_term_begining_balance !== undefined
      && module9Data.long_term_begining_balance !== undefined) {
      module12Data.moreTotalLiquid = module9Data.short_term_begining_balance
        + module9Data.long_term_begining_balance;
    }
  } else if (module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '') {
    if (balanceSheetData[parseInt(module12Data.second_purchase_year, 10) - 1] !== undefined
      && balanceSheetData[parseInt(module12Data.second_purchase_year, 10) - 1].Investments['Short-Term Investments'] !== undefined
      && balanceSheetData[parseInt(module12Data.second_purchase_year, 10) - 1].Investments['Long-Term Investments'] !== undefined) {
      module12Data.moreTotalLiquid = parseFloat(balanceSheetData[parseInt(module12Data.second_purchase_year, 10) - 1].Investments['Short-Term Investments'])
        + parseFloat(balanceSheetData[parseInt(module12Data.second_purchase_year, 10) - 1].Investments['Long-Term Investments']);
    }
  }
  return module12Data.moreTotalLiquid;
}
// Outstanding Car Loan
export function moreOutstandingCarLoan() {
  module12Data.moreOutstandingCarLoan = 0;
  if (module12Data.moreCarLoan !== undefined && module12Data.moreCarLoan !== '' && module12Data.moreCarLoan > 0) {
    module12Data.moreOutstandingCarLoan = parseFloat(module12Data.moreCarLoan);
  }
  return module12Data.moreOutstandingCarLoan;
}
// Total Principal & Interest
export function moreInvestmentAnalysisTotalPrincipal() {
  module12Data.moreInvestmentAnalysisTotalPrincipal = 0;
  if (module12Data.moreCarLoan !== undefined && module12Data.moreCarLoan !== '' && module12Data.moreCarLoan > 0) {
    if (carLoan2Data['Total Paid'] !== undefined && carLoan2Data['Total Paid'] !== '') {
      module12Data.moreInvestmentAnalysisTotalPrincipal = carLoan2Data['Total Paid'];
    }
  }
  return module12Data.moreInvestmentAnalysisTotalPrincipal;
}

/* Leasing A Car */
// Leasing first car Car End Year
export async function leasingCarEndYearVal() {
  module12Data.leasingCarEndYear = 0;
  if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData['lease1 End Year Round'] !== undefined && depreciationSheduleData['lease1 End Year Round'] !== '') {
    module12Data.leasingCarEndYear = depreciationSheduleData['lease1 End Year Round'];
  }
  return await module12Data.leasingCarEndYear;
}

// Cash Needed to Start Lease
export function cashNeededToStartLease() {
  module12Data.cashNeededToStartLease = 0;

  if (module12Data.leasing_car_down_payment !== undefined && module12Data.leasing_car_drive_off_fees !== undefined) {
    module12Data.cashNeededToStartLease = module12Data.leasing_car_down_payment + module12Data.leasing_car_drive_off_fees;
  }
  return module12Data.cashNeededToStartLease;
}

// Annual Payment
export function leasingCarAnnualPayment() {
  module12Data.leasingCarAnnualPayment = 0;
  if (module12Data.leasing_car_drive_off_fees !== undefined && module12Data.leasing_car_drive_off_fees !== '') {
    module12Data.leasingCarAnnualPayment = module12Data.leasing_car_drive_off_fees * 12;
  }
  return module12Data.leasingCarAnnualPayment;
}

// Maintenance Calc
export function leasingCarLeasePaymentCalc() {
  module12Data.leasingCarLeasePaymentCalc = 0;
  if (module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasing_car_lease_payment !== undefined && module12Data.leasing_car_lease_payment !== '') {
    module12Data.leasingCarLeasePaymentCalc = module12Data.leasing_car_lease_payment * 12;
  }
  return module12Data.leasingCarLeasePaymentCalc;
}

// Maintenance Calc
export function leasingCarMaintenanceCalc() {
  module12Data.leasingCarMaintenanceCalc = 0;
  if (module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasing_car_maintenance !== undefined && module12Data.leasing_car_maintenance !== '') {
    module12Data.leasingCarMaintenanceCalc = module12Data.leasing_car_maintenance * 12;
  }
  return module12Data.leasingCarMaintenanceCalc;
}

// Insurance Calc
export function leasingCarInsuranceCalc() {
  module12Data.leasingCarInsuranceCalc = 0;
  if (module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasing_car_insurance !== undefined && module12Data.leasing_car_insurance !== '') {
    module12Data.leasingCarInsuranceCalc = module12Data.leasing_car_insurance * 12;
  }
  return module12Data.leasingCarInsuranceCalc;
}

// Gas Calc
export function leasingCarGasCalc() {
  module12Data.leasingCarGasCalc = 0;
  if (module12Data.leasing_car_start_year !== undefined && module12Data.leasing_car_start_year !== '' && module12Data.leasing_car_gas !== undefined && module12Data.leasing_car_gas !== '') {
    module12Data.leasingCarGasCalc = module12Data.leasing_car_gas * 12;
  }
  return module12Data.leasingCarGasCalc;
}

// Total Monthly Expenses Calc
export function leasingCarTotalMonthlyExpenses() {
  module12Data.leasingCarTotalMonthlyExpenses = 0;
  if (module12Data.leasing_car_gas !== undefined
    && module12Data.leasing_car_insurance !== undefined
    && module12Data.leasing_car_maintenance !== undefined
    && module12Data.leasing_car_lease_payment !== undefined) {
    module12Data.leasingCarTotalMonthlyExpenses = module12Data.leasing_car_gas
      + module12Data.leasing_car_insurance
      + module12Data.leasing_car_maintenance
      + module12Data.leasing_car_lease_payment;
  }
  return module12Data.leasingCarTotalMonthlyExpenses;
}
// Total Annual Expenses Calc
export function leasingCarTotalAnnualExpenses() {
  module12Data.leasingCarTotalAnnualExpenses = 0;
  if (module12Data.leasingCarTotalMonthlyExpenses !== undefined && module12Data.leasingCarTotalMonthlyExpenses !== '') {
    module12Data.leasingCarTotalAnnualExpenses = (module12Data.leasingCarTotalMonthlyExpenses) * 12;
  }
  return module12Data.leasingCarTotalAnnualExpenses;
}

// DownPayment summary
export function leasingCarDownPaymentSummary() {
  module12Data.leasingCarDownPaymentSummary = 0;
  if (module12Data.cashNeededToStartLease !== undefined) {
    module12Data.leasingCarDownPaymentSummary = module12Data.cashNeededToStartLease;
  }
  return module12Data.leasingCarDownPaymentSummary;
}

// Total Payments
export function leasingCarTotalPaymentSummary() {
  module12Data.leasingCarTotalPaymentSummary = 0;
  let leasingCarTotalpayments = 0;
  if (depreciationSheduleData !== undefined && depreciationSheduleData !== '') {
    if (Object.keys(depreciationSheduleData).length > 0) {
      Object.keys(depreciationSheduleData).forEach((year) => {
        if (depreciationSheduleData[year] !== undefined && depreciationSheduleData[year]['Lease1 Expenses'] !== undefined) {
          leasingCarTotalpayments += depreciationSheduleData[year]['Lease1 Expenses']['Car Payment'];
        }
      });
    }
  }
  module12Data.leasingCarTotalPaymentSummary = leasingCarTotalpayments;
  return module12Data.leasingCarTotalPaymentSummary;
}

// Total Expenses
export function leasingCarTotalExpensesSummary() {
  module12Data.leasingCarTotalExpensesSummary = 0;
  let totalExpensesValue = 0;

  if (depreciationSheduleData !== undefined && depreciationSheduleData !== '') {
    if (Object.keys(depreciationSheduleData).length > 0) {
      Object.keys(depreciationSheduleData).forEach((year) => {
        if (depreciationSheduleData[year] !== undefined && depreciationSheduleData[year]['Lease1 Expenses'] !== undefined) {
          totalExpensesValue += depreciationSheduleData[year]['Lease1 Expenses'].Maintenance;
          totalExpensesValue += depreciationSheduleData[year]['Lease1 Expenses'].Insurance;
          totalExpensesValue += depreciationSheduleData[year]['Lease1 Expenses'].Gas;
        }
      });
    }
  }
  module12Data.leasingCarTotalExpensesSummary = totalExpensesValue;
  return module12Data.leasingCarTotalExpensesSummary;
}

// Total Costs
export function leasingCarTotalCostsSummary() {
  module12Data.leasingCarTotalCostsSummary = 0;
  if (module12Data.leasingCarDownPaymentSummary !== undefined && module12Data.leasingCarTotalPaymentSummary !== undefined && module12Data.leasingCarTotalExpensesSummary !== undefined) {
    module12Data.leasingCarTotalCostsSummary = module12Data.leasingCarDownPaymentSummary + module12Data.leasingCarTotalPaymentSummary + module12Data.leasingCarTotalExpensesSummary;
  }
  return module12Data.leasingCarTotalCostsSummary;
}

/* Leasing A Second Car */
// Leasing Secound car Car End Year
export function leasingSecondCarEndYearVal() {
  module12Data.leasingSecondCarEndYear = 0;
  if (depreciationSheduleData !== undefined && depreciationSheduleData !== '' && depreciationSheduleData['lease2 End Year Round'] !== undefined && depreciationSheduleData['lease2 End Year Round'] !== '') {
    module12Data.leasingSecondCarEndYear = depreciationSheduleData['lease2 End Year Round'];
  }
  return module12Data.leasingSecondCarEndYear;
}
// Annual Payment
export function leasingSecondCarAnnualPayment() {
  module12Data.leasingSecondCarAnnualPayment = 0;
  if (module12Data.leasing_second_car_drive_off_fees !== undefined && module12Data.leasing_second_car_drive_off_fees !== '') {
    module12Data.leasingSecondCarAnnualPayment = module12Data.leasing_second_car_drive_off_fees * 12;
  }
  return module12Data.leasingSecondCarAnnualPayment;
}
// Cash Needed to Start Lease
export function cashNeededToSecondStartLease() {
  module12Data.cashNeededToSecondStartLease = 0;

  if (module12Data.leasing_second_car_down_payment !== undefined && module12Data.leasing_second_car_drive_off_fees !== undefined) {
    module12Data.cashNeededToSecondStartLease = module12Data.leasing_second_car_down_payment + module12Data.leasing_second_car_drive_off_fees;
  }
  return module12Data.cashNeededToSecondStartLease;
}

// Maintenance Calc
export function leasingSecondCarLeasePaymentCalc() {
  module12Data.leasingSecondCarLeasePaymentCalc = 0;
  if (module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasing_second_car_lease_payment !== undefined && module12Data.leasing_second_car_lease_payment !== '') {
    module12Data.leasingSecondCarLeasePaymentCalc = module12Data.leasing_second_car_lease_payment * 12;
  }
  return module12Data.leasingSecondCarLeasePaymentCalc;
}

// Maintenance Calc
export function leasingSecondCarMaintenanceCalc() {
  module12Data.leasingSecondCarMaintenanceCalc = 0;
  if (module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasing_second_car_maintenance !== undefined && module12Data.leasing_second_car_maintenance !== '') {
    module12Data.leasingSecondCarMaintenanceCalc = module12Data.leasing_second_car_maintenance * 12;
  }
  return module12Data.leasingSecondCarMaintenanceCalc;
}

// Insurance Calc
export function leasingSecondCarInsuranceCalc() {
  module12Data.leasingSecondCarInsuranceCalc = 0;
  if (module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasing_second_car_insurance !== undefined && module12Data.leasing_second_car_insurance !== '') {
    module12Data.leasingSecondCarInsuranceCalc = module12Data.leasing_second_car_insurance * 12;
  }
  return module12Data.leasingSecondCarInsuranceCalc;
}

// Gas Calc
export function leasingSecondCarGasCalc() {
  module12Data.leasingSecondCarGasCalc = 0;
  if (module12Data.leasing_second_car_start_year !== undefined && module12Data.leasing_second_car_start_year !== '' && module12Data.leasing_second_car_gas !== undefined && module12Data.leasing_second_car_gas !== '') {
    module12Data.leasingSecondCarGasCalc = module12Data.leasing_second_car_gas * 12;
  }
  return module12Data.leasingSecondCarGasCalc;
}

// Total Monthly Expenses Calc
export function leasingSecondCarTotalMonthlyExpenses() {
  module12Data.leasingSecondCarTotalMonthlyExpenses = 0;
  if (module12Data.leasing_second_car_gas !== undefined && module12Data.leasing_second_car_insurance !== undefined && module12Data.leasing_second_car_maintenance !== undefined && module12Data.leasing_second_car_lease_payment !== undefined) {
    module12Data.leasingSecondCarTotalMonthlyExpenses = module12Data.leasing_second_car_gas
      + module12Data.leasing_second_car_insurance
      + module12Data.leasing_second_car_maintenance
      + module12Data.leasing_second_car_lease_payment;
  }
  return module12Data.leasingSecondCarTotalMonthlyExpenses;
}
// Total Annual Expenses Calc
export function leasingSecondCarTotalAnnualExpenses() {
  module12Data.leasingSecondCarTotalAnnualExpenses = 0;
  if (module12Data.leasingSecondCarTotalMonthlyExpenses !== undefined && module12Data.leasingSecondCarTotalMonthlyExpenses !== '') {
    module12Data.leasingSecondCarTotalAnnualExpenses = (module12Data.leasingSecondCarTotalMonthlyExpenses) * 12;
  }
  return module12Data.leasingSecondCarTotalAnnualExpenses;
}

// DownPayment summary
export function leasingSecondCarDownPaymentSummary() {
  module12Data.leasingSecondCarDownPaymentSummary = 0;
  if (module12Data.cashNeededToSecondStartLease !== undefined) {
    module12Data.leasingSecondCarDownPaymentSummary = module12Data.cashNeededToSecondStartLease;
  }
  return module12Data.leasingSecondCarDownPaymentSummary;
}

// Total Payments
export function leasingSecondCarTotalPaymentSummary() {
  module12Data.leasingSecondCarTotalPaymentSummary = 0;
  let leasingSecondCarTotalpayments = 0;
  if (depreciationSheduleData !== undefined && depreciationSheduleData !== '') {
    if (Object.keys(depreciationSheduleData).length > 0) {
      Object.keys(depreciationSheduleData).forEach((year) => {
        if (depreciationSheduleData[year] !== undefined && depreciationSheduleData[year]['Lease2 Expenses'] !== undefined) {
          leasingSecondCarTotalpayments += depreciationSheduleData[year]['Lease2 Expenses']['Car Payment'];
        }
      });
    }
  }
  module12Data.leasingSecondCarTotalPaymentSummary = leasingSecondCarTotalpayments;
  return module12Data.leasingSecondCarTotalPaymentSummary;
}

// Total Expenses
export function leasingSecondCarTotalExpensesSummary() {
  module12Data.leasingSecondCarTotalExpensesSummary = 0;
  let totalExpensesValue = 0;
  if (depreciationSheduleData !== undefined && depreciationSheduleData !== '') {
    if (Object.keys(depreciationSheduleData).length > 0) {
      Object.keys(depreciationSheduleData).forEach((year) => {
        if (depreciationSheduleData[year] !== undefined && depreciationSheduleData[year]['Lease2 Expenses'] !== undefined) {
          totalExpensesValue += depreciationSheduleData[year]['Lease2 Expenses'].Maintenance;
          totalExpensesValue += depreciationSheduleData[year]['Lease2 Expenses'].Insurance;
          totalExpensesValue += depreciationSheduleData[year]['Lease2 Expenses'].Gas;
        }
      });
    }
  }
  module12Data.leasingSecondCarTotalExpensesSummary = totalExpensesValue;
  return module12Data.leasingSecondCarTotalExpensesSummary;
}

// Total Costs
export function leasingSecondCarTotalCostsSummary() {
  module12Data.leasingSecondCarTotalCostsSummary = 0;
  if (module12Data.leasingSecondCarDownPaymentSummary !== undefined && module12Data.leasingSecondCarTotalPaymentSummary !== undefined && module12Data.leasingSecondCarTotalExpensesSummary !== undefined) {
    module12Data.leasingSecondCarTotalCostsSummary = module12Data.leasingSecondCarDownPaymentSummary + module12Data.leasingSecondCarTotalPaymentSummary + module12Data.leasingSecondCarTotalExpensesSummary;
  }
  return module12Data.leasingSecondCarTotalCostsSummary;
}

/** ********** Module 12 Buying a Car Ends ************ */
/** ******************** Module 9 Investments Start *********************** */

/** Portion 1 * */
// Annual Net Income in Year 2022
export function shortAnnualNetIncomeInYear() {
  module9Data.shortAnnualNetIncomeInYear = 0;
  if (inputSheetData !== undefined && inputSheetData !== '' && inputSheetData[module9Data.short_term_start_year] !== undefined && inputSheetData[module9Data.short_term_start_year] !== '' && inputSheetData[module9Data.short_term_start_year]['Monthly Data']['Monthly Retirement Contributions'] !== undefined) {
    module9Data.shortAnnualNetIncomeInYear = inputSheetData[module9Data.short_term_start_year]['Monthly Data']['Monthly Retirement Contributions'];
  }
  return module9Data.shortAnnualNetIncomeInYear;
}

// Initial Cash Balance at the End of Year 2017
export function initialCashBalance() {
  // value Calculation
  module9Data.initialCashBalance = 0;
  let cashBalanceYear = 0;
  const cashFlowdata = cashFlowData;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module9Data.short_term_start_year === module1Data.start_year && module1Data.current_cash_balance !== undefined && module1Data.current_cash_balance !== '') {
    // module1Data.current_cash_balance
    module9Data.initialCashBalance = 0;
  } else {
    if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== '') {
      cashBalanceYear = parseInt(module9Data.short_term_start_year, 10) - 1;
      if (cashFlowdata[cashBalanceYear] !== undefined && cashFlowdata[cashBalanceYear]['Ending Cash Balance'] !== undefined) {
        module9Data.initialCashBalance = cashFlowdata[cashBalanceYear]['Ending Cash Balance'];
      } else {
        module9Data.initialCashBalance = 0;
      }
    }
  }
  return module9Data.initialCashBalance;
}

// Cash Balance at the End of Year 2018
export function currentYearCashBalance() {
  module9Data.currentYearCashBalance = 0;
  const cashFlowdata = cashFlowData;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== '') {
    if (cashFlowdata !== undefined && cashFlowdata !== '' && cashFlowdata[module9Data.short_term_start_year] !== undefined && cashFlowdata[module9Data.short_term_start_year] !== '' && cashFlowdata[module9Data.short_term_start_year]['Ending Cash Balance'] !== undefined) {
      module9Data.currentYearCashBalance = cashFlowdata[module9Data.short_term_start_year]['Ending Cash Balance'];
    } else {
      module9Data.currentYearCashBalance = 0;
    }
  }
  return module9Data.currentYearCashBalance;
}

// Short-Term Investments Period
export function shortTermInvestmentsPeriod() {
  module9Data.shortTermInvestmentsPeriod = 0;
  const d = new Date();
  const currentYear = d.getFullYear();
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    module9Data.shortTermInvestmentsPeriod = `${module9Data.short_term_start_year <= 0 ? currentYear : module9Data.short_term_start_year}-${parseInt(module1Data.start_year, 10) + 14}`;
  } else {
    module9Data.shortTermInvestmentsPeriod = currentYear;
  }
  return module9Data.shortTermInvestmentsPeriod;
}

// Short-Term Investments Period Years
export function shortTermInvestmentsPeriodYears() {
  module9Data.shortTermInvestmentsPeriodYears = 0;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== '' && module9Data.short_term_start_year > 0) {
    if (module1Data.start_year !== undefined && module1Data.start_year !== '') {
      module9Data.shortTermInvestmentsPeriodYears = (parseInt(module1Data.start_year, 10) + 14) - parseInt(module9Data.short_term_start_year, 10) + 1;
    } else {
      module9Data.shortTermInvestmentsPeriodYears = 0;
    }
  }
  return module9Data.shortTermInvestmentsPeriodYears;
}

// Short Term Beginning Balance
export function shortTermSummaryBeginningBalance() {
  module9Data.shortTermSummaryBeginningBalance = 0;
  if (module18Data.existing_total_short_term_investments !== undefined && module18Data.existing_total_short_term_investments !== '') {
    module9Data.shortTermSummaryBeginningBalance = module18Data.existing_total_short_term_investments;
  }
  return module9Data.shortTermSummaryBeginningBalance;
}

// Short Term Contributions
export function shortTermSummaryContributions() {
  module9Data.shortTermSummaryContributions = 0;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== ''
    && investmentsData['Short-Term Cash Contributions Total'] !== undefined
    && investmentsData['Short-Term Cash Contributions Total'] !== undefined) {
    module9Data.shortTermSummaryContributions = investmentsData['Short-Term Cash Contributions Total'];
  }
  return module9Data.shortTermSummaryContributions;
}
// Short Term Earnings
export function shortTermSummaryEarnings() {
  module9Data.shortTermSummaryEarnings = 0;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== ''
    && investmentsData['Short-Term Earnings Total'] !== undefined
    && investmentsData['Short-Term Earnings Total'] !== undefined) {
    module9Data.shortTermSummaryEarnings = investmentsData['Short-Term Earnings Total'];
  }
  return module9Data.shortTermSummaryEarnings;
}

// Short-Term Investments Balance before Transfers and Withdrawals
export function shortTermInvestmentsBeforeTransfers() {
  module9Data.shortTermInvestmentsBeforeTransfers = 0;
  if (module9Data.shortTermSummaryBeginningBalance !== undefined
    && module9Data.shortTermSummaryContributions !== undefined
    && module9Data.shortTermSummaryEarnings !== undefined) {
    module9Data.shortTermInvestmentsBeforeTransfers = module9Data.shortTermSummaryBeginningBalance + module9Data.shortTermSummaryContributions + module9Data.shortTermSummaryEarnings;
  }
  return module9Data.shortTermInvestmentsBeforeTransfers;
}

// Short Term Excess Cash
export function shortTermSummaryExcessCash() {
  module9Data.shortTermSummaryExcessCash = 0;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== ''
    && investmentsData['Short-Term Contributions Total'] !== undefined
    && investmentsData['Short-Term Contributions Total'] !== undefined) {
    module9Data.shortTermSummaryExcessCash = investmentsData['Short-Term Contributions Total'];
  }
  return module9Data.shortTermSummaryExcessCash;
}
// Short Term Cash Withdrawn
export function shortTermSummaryCashWithDrawn() {
  module9Data.shortTermSummaryCashWithDrawn = 0;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== ''
    && investmentsData['Short-Term Cash Transfer Total'] !== undefined
    && investmentsData['Short-Term Cash Transfer Total'] !== undefined) {
    module9Data.shortTermSummaryCashWithDrawn = -(investmentsData['Short-Term Cash Transfer Total']);
  }
  return module9Data.shortTermSummaryCashWithDrawn;
}

// Short Term Cash Deposited
export function shortTermSummaryCashDeposited() {
  module9Data.shortTermSummaryCashDeposited = 0;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== ''
    && investmentsData['Short-Term Excess Total'] !== undefined
    && investmentsData['Short-Term Excess Total'] !== undefined) {
    module9Data.shortTermSummaryCashDeposited = -(investmentsData['Short-Term Excess Total']);
  }
  return module9Data.shortTermSummaryCashDeposited;
}

// Short Term Ending Balance
export function shortTermSummaryEndingBalance() {
  module9Data.shortTermSummaryEndingBalance = 0;
  if (module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const cashBalanceYear = parseInt(module1Data.start_year, 10) + 14;
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Short-Term Investments'] !== undefined && investmentsData['Short-Term Investments'] !== '' && investmentsData['Short-Term Investments'][cashBalanceYear] !== undefined && investmentsData['Short-Term Investments'][cashBalanceYear] !== '' && investmentsData['Short-Term Investments'][cashBalanceYear]['Ending Balance'] !== undefined) {
      module9Data.shortTermSummaryEndingBalance = investmentsData['Short-Term Investments'][cashBalanceYear]['Ending Balance'];
    }
  }
  return module9Data.shortTermSummaryEndingBalance;
}
// Long Annual Net Income in Year 2022
export function longAnnualNetIncomeInYear() {
  module9Data.longAnnualNetIncomeInYear = 0;
  if (inputSheetData !== undefined && inputSheetData !== '' && inputSheetData[module9Data.long_term_start_year] !== undefined && inputSheetData[module9Data.long_term_start_year] !== '' && inputSheetData[module9Data.long_term_start_year]['Monthly Data']['Monthly Retirement Contributions'] !== undefined) {
    module9Data.longAnnualNetIncomeInYear = inputSheetData[module9Data.long_term_start_year]['Monthly Data']['Monthly Retirement Contributions'];
  }
  return module9Data.longAnnualNetIncomeInYear;
}

/** Portion 2 * */
// Long Term
export function longTermInitialCashBalance() {
  // Value Calculation
  module9Data.longTermInitialCashBalance = 0;
  const cashFlowdata = cashFlowData;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== '') {
    const cashBalanceYear = parseInt(module9Data.long_term_start_year, 10) - 1;
    if (cashFlowdata[cashBalanceYear] !== undefined && cashFlowdata[cashBalanceYear]['Ending Cash Balance'] !== undefined) {
      module9Data.longTermInitialCashBalance = cashFlowdata[cashBalanceYear]['Ending Cash Balance'];
    }
  }
  return module9Data.longTermInitialCashBalance;
}

// Cash Balance at the End of Year 2018
export function longTermCashBalance() {
  module9Data.longTermCashBalance = 0;
  const cashFlowdata = cashFlowData;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== '') {
    if (cashFlowdata[module9Data.long_term_start_year] !== undefined && cashFlowdata[module9Data.long_term_start_year]['Ending Cash Balance'] !== undefined) {
      module9Data.longTermCashBalance = cashFlowdata[module9Data.long_term_start_year]['Ending Cash Balance'];
    }
  }
  return module9Data.longTermCashBalance;
}

export function longTermInvestmentsPeriod() {
  module9Data.longTermInvestmentsPeriod = 0;
  const d = new Date();
  const currentYear = d.getFullYear();
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    module9Data.longTermInvestmentsPeriod = `${module9Data.long_term_start_year <= 0 ? currentYear : module9Data.long_term_start_year}-${parseInt(module1Data.start_year, 10) + 14}`;
  } else {
    module9Data.shortTermInvestmentsPeriod = currentYear;
  }
  return module9Data.longTermInvestmentsPeriod;
}

// Long-Term Investments Period Years
export function longTermInvestmentsPeriodYears() {
  module9Data.longTermInvestmentsPeriodYears = 0;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== '' && module9Data.long_term_start_year > 0) {
    if (module1Data.start_year !== undefined && module1Data.start_year !== '') {
      module9Data.longTermInvestmentsPeriodYears = (parseInt(module1Data.start_year, 10) + 14) - parseInt(module9Data.long_term_start_year, 10) + 1;
    } else {
      module9Data.longTermInvestmentsPeriodYears = 0;
    }
  }
  return module9Data.longTermInvestmentsPeriodYears;
}

// Long Term Beginning Balance
export function longTermSummaryBeginningBalance() {
  module9Data.longTermSummaryBeginningBalance = 0;
  if (module18Data.existing_total_long_term_investments !== undefined && module18Data.existing_total_long_term_investments !== '') {
    module9Data.longTermSummaryBeginningBalance = module18Data.existing_total_long_term_investments;
  }
  return module9Data.longTermSummaryBeginningBalance;
}

// Long Term Contributions
export function longTermSummaryContributions() {
  module9Data.longTermSummaryContributions = 0;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== ''
    && investmentsData['Long-Term Cash Contributions Total'] !== undefined
    && investmentsData['Long-Term Cash Contributions Total'] !== undefined) {
    module9Data.longTermSummaryContributions = investmentsData['Long-Term Cash Contributions Total'];
  }
  return module9Data.longTermSummaryContributions;
}
// Long Term Earnings
export function longTermSummaryEarnings() {
  module9Data.longTermSummaryEarnings = 0;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== ''
    && investmentsData['Long-Term Earnings Total'] !== undefined
    && investmentsData['Long-Term Earnings Total'] !== undefined) {
    module9Data.longTermSummaryEarnings = investmentsData['Long-Term Earnings Total'];
  }
  return module9Data.longTermSummaryEarnings;
}

// Before transfers
export function longTermInvestmentsBeforeTransfers() {
  module9Data.longTermInvestmentsBeforeTransfers = 0;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== '') {
    if (module9Data.longTermSummaryBeginningBalance !== undefined
      && module9Data.longTermSummaryContributions !== undefined
      && module9Data.longTermSummaryEarnings !== undefined) {
      module9Data.longTermInvestmentsBeforeTransfers = module9Data.longTermSummaryBeginningBalance + module9Data.longTermSummaryContributions + module9Data.longTermSummaryEarnings;
    } else {
      module9Data.longTermInvestmentsBeforeTransfers = 0;
    }
  }
  return module9Data.longTermInvestmentsBeforeTransfers;
}

// Long Term Excess Cash
export function longTermSummaryExcessCash() {
  module9Data.longTermSummaryExcessCash = 0;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== ''
    && investmentsData['Long-Term Contributions Total'] !== undefined
    && investmentsData['Long-Term Contributions Total'] !== undefined) {
    module9Data.longTermSummaryExcessCash = investmentsData['Long-Term Contributions Total'];
  }
  return module9Data.longTermSummaryExcessCash;
}
// Long Term Cash WithDrawn
export function longTermSummaryCashWithDrawn() {
  module9Data.longTermSummaryCashWithDrawn = 0;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== ''
    && investmentsData['Long-Term Cash Transfer Total'] !== undefined
    && investmentsData['Long-Term Cash Transfer Total'] !== undefined) {
    module9Data.longTermSummaryCashWithDrawn = -(investmentsData['Long-Term Cash Transfer Total']);
  }
  return module9Data.longTermSummaryCashWithDrawn;
}

// Long Term Ending Balance
export function longTermSummaryEndingBalance() {
  module9Data.longTermSummaryEndingBalance = 0;
  if (module1Data.start_year !== undefined && module1Data.start_year !== '') {
    const cashBalanceYear = parseInt(module1Data.start_year, 10) + 14;
    if (investmentsData !== undefined && investmentsData !== '' && investmentsData['Long-Term Investments'] !== undefined && investmentsData['Long-Term Investments'] !== '' && investmentsData['Long-Term Investments'][cashBalanceYear] !== undefined && investmentsData['Long-Term Investments'][cashBalanceYear] !== '' && investmentsData['Long-Term Investments'][cashBalanceYear]['Ending Balance'] !== undefined) {
      module9Data.longTermSummaryEndingBalance = investmentsData['Long-Term Investments'][cashBalanceYear]['Ending Balance'];
    }
  }
  return module9Data.longTermSummaryEndingBalance;
}

// Total Financial Gift
export function totalFinancialGift() {
  module9Data.totalFinancialGift = 0;
  if (module9Data.yearly_financial_gift !== undefined && module9Data.yearly_financial_gift !== '' && module9Data.financial_gift_end_year !== '' && module9Data.financial_gift_start_year !== '') {
    module9Data.totalFinancialGift = module9Data.yearly_financial_gift * (parseInt(module9Data.financial_gift_end_year, 10) - parseInt(module9Data.financial_gift_start_year, 10));
  }
  return module9Data.totalFinancialGift;
}

// Total Income in Year
export function charitableAnnualContributionTotalIncome() {
  module9Data.charitableAnnualContributionTotalIncome = 0;
  if (module9Data.charitable_start_year !== undefined && module9Data.charitable_start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData !== ''
    && incomeStatementData[module9Data.charitable_start_year] !== undefined
    && incomeStatementData[module9Data.charitable_start_year] !== ''
    && incomeStatementData[module9Data.charitable_start_year]['Total Income'] !== undefined) {
    module9Data.charitableAnnualContributionTotalIncome = incomeStatementData[module9Data.charitable_start_year]['Total Income'];
  }
  return module9Data.charitableAnnualContributionTotalIncome;
}

// Charitable Annual Contribution
export function charitableAnnualContribution() {
  module9Data.charitableAnnualContribution = 0;
  if (module9Data.charitable_yearly_contribution !== undefined && module9Data.charitable_yearly_contribution !== ''
    && module9Data.charitable_start_year !== undefined && module9Data.charitable_start_year !== ''
    && incomeStatementData !== undefined
    && incomeStatementData[module9Data.charitable_start_year] !== undefined) {
    module9Data.charitableAnnualContribution = (incomeStatementData[module9Data.charitable_start_year]['Total Income']) * (parseFloat(module9Data.charitable_yearly_contribution) / 100);
  }
  return module9Data.charitableAnnualContribution;
}

// Charitable Total Contribution
export function charitableTotalContribution() {
  module9Data.charitableTotalContribution = 0;
  if (cashFlowData !== undefined && cashFlowData !== ''
    && cashFlowData['Charitable Contributions Total'] !== undefined && cashFlowData['Charitable Contributions Total'] !== '') {
    module9Data.charitableTotalContribution = -(cashFlowData['Charitable Contributions Total']);
  }
  return module9Data.charitableTotalContribution;
}

// Charitable Tax Savings
export function charitableTaxSavings() {
  module9Data.charitableTaxSavings = 0;
  if (module9Data.charitableTotalContribution !== undefined && module9Data.charitableTotalContribution !== '') {
    module9Data.charitableTaxSavings = module9Data.charitableTotalContribution * (20 / 100);
  }
  return module9Data.charitableTaxSavings;
}

export function beginning529BalanceAnalysis() {
  module9Data.beginning529BalanceAnalysis = 0;
  if (module9Data.beginning529Balance !== undefined && module9Data.beginning529Balance !== '') {
    module9Data.beginning529BalanceAnalysis = module9Data.beginning529Balance;
  }
  return module9Data.beginning529BalanceAnalysis;
}

//added by phrudhvi

export function shortTermBeginningBalance() {
  module9Data.shortTermBeginningBalance = 0;
  if (module9Data.short_term_start_year !== undefined && module9Data.short_term_start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['Short-Term Investments'][module9Data.short_term_start_year] !== undefined && investmentsData['Short-Term Investments'][module9Data.short_term_start_year] !== '' && investmentsData['Short-Term Investments'][module9Data.short_term_start_year]['Beg Balance'] !== undefined && investmentsData['Short-Term Investments'][module9Data.short_term_start_year]['Beg Balance'] !== '') {
    module9Data.shortTermBeginningBalance = investmentsData['Short-Term Investments'][module9Data.short_term_start_year]['Beg Balance'];
  }
  return module9Data.shortTermBeginningBalance;
}

export function afterTaxIncomeInYearShortTerm() {
  module9Data.afterTaxIncomeInYearShortTerm = 0;
  if (incomeStatementData !== undefined &&
    incomeStatementData !== '' &&
    incomeStatementData[module9Data.short_term_start_year] !== undefined &&
    incomeStatementData[module9Data.short_term_start_year] !== '' &&
    incomeStatementData[module9Data.short_term_start_year]['Income After Taxes'] !== undefined) {
    module9Data.afterTaxIncomeInYearShortTerm = incomeStatementData[module9Data.short_term_start_year]['Income After Taxes'];
  }
  return module9Data.afterTaxIncomeInYearShortTerm;
}

//Annual Contribution Short Term
export function shortTermAnnualContribution() {
  module9Data.shortTermAnnualContribution = 0;
  if (module9Data.afterTaxIncomeInYearShortTerm !== undefined && module9Data.afterTaxIncomeInYearShortTerm !== '' && module9Data.short_term_annual_contribution_growth_in_percentage !== undefined && module9Data.short_term_annual_contribution_growth_in_percentage !== '') {
    module9Data.shortTermAnnualContribution = (module9Data.afterTaxIncomeInYearShortTerm / 100) * module9Data.short_term_annual_contribution_growth_in_percentage;
  }
  return module9Data.shortTermAnnualContribution;
}

//Beginning Long-Term Investments Balance
export function longTermBeginningBalance() {
  module9Data.longTermBeginningBalance = 0;
  if (module9Data.long_term_start_year !== undefined && module9Data.long_term_start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['Long-Term Investments'][module9Data.long_term_start_year] !== undefined && investmentsData['Long-Term Investments'][module9Data.long_term_start_year] !== '' && investmentsData['Long-Term Investments'][module9Data.long_term_start_year]['Beg Balance'] !== undefined && investmentsData['Long-Term Investments'][module9Data.long_term_start_year]['Beg Balance'] !== '') {
    module9Data.longTermBeginningBalance = investmentsData['Long-Term Investments'][module9Data.long_term_start_year]['Beg Balance'];
  }
  return module9Data.longTermBeginningBalance;
}
//After-Tax Income in Year
export function afterTaxIncomeInYearLongTerm() {
  module9Data.afterTaxIncomeInYearLongTerm = 0;
  if (incomeStatementData !== undefined &&
    incomeStatementData !== '' &&
    incomeStatementData[module9Data.long_term_start_year] !== undefined &&
    incomeStatementData[module9Data.long_term_start_year] !== '' &&
    incomeStatementData[module9Data.long_term_start_year]['Income After Taxes'] !== undefined) {
    module9Data.afterTaxIncomeInYearLongTerm = incomeStatementData[module9Data.long_term_start_year]['Income After Taxes'];
  }
  return module9Data.afterTaxIncomeInYearLongTerm;
}
//Annual Contribution Short Term
export function longTermAnnualContribution() {
  module9Data.longTermAnnualContribution = 0;
  if (module9Data.afterTaxIncomeInYearLongTerm !== undefined && module9Data.afterTaxIncomeInYearLongTerm !== '' && module9Data.long_term_annual_contribution_in_percentage_growth !== undefined && module9Data.long_term_annual_contribution_in_percentage_growth !== '') {
    module9Data.longTermAnnualContribution = (module9Data.afterTaxIncomeInYearLongTerm / 100) * module9Data.long_term_annual_contribution_in_percentage_growth;
  }
  return module9Data.longTermAnnualContribution;
}

export function beginning529Balance() {
  module9Data.beginning529Balance = 0;
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['529 Plan'][module9Data.plan_529_start_year] !== undefined && investmentsData['529 Plan'][module9Data.plan_529_start_year] !== '' && investmentsData['529 Plan'][module9Data.plan_529_start_year]['Beg Balance'] !== undefined && investmentsData['529 Plan'][module9Data.plan_529_start_year]['Beg Balance'] !== '') {
    module9Data.beginning529Balance = investmentsData['529 Plan'][module9Data.plan_529_start_year]['Beg Balance'];
  }
  return module9Data.beginning529Balance;
}
//After-Tax Income in Year
export function afterTaxIncomeInYear529Balance() {
  module9Data.afterTaxIncomeInYear529Balance = 0;
  if (incomeStatementData !== undefined &&
    incomeStatementData[module9Data.plan_529_start_year] !== undefined &&
    incomeStatementData[module9Data.plan_529_start_year] !== '' &&
    incomeStatementData[module9Data.plan_529_start_year]['Income After Taxes'] !== '') {
    module9Data.afterTaxIncomeInYear529Balance = incomeStatementData[module9Data.plan_529_start_year]['Income After Taxes'];
  }
  return module9Data.afterTaxIncomeInYear529Balance;
}

export function annualNetCashFlowDorSavingsAndInvestments529() {
  module9Data.annualNetCashFlowDorSavingsAndInvestments529 = 0;
  if (inputSheetData !== undefined && inputSheetData !== '' && inputSheetData[module9Data.plan_529_start_year] !== undefined && inputSheetData[module9Data.plan_529_start_year] !== '' && inputSheetData[module9Data.plan_529_start_year]['Monthly Data']['Monthly Net Cash Flow for Savings and Investments'] !== undefined) {
    module9Data.annualNetCashFlowDorSavingsAndInvestments529 = inputSheetData[module9Data.plan_529_start_year]['Monthly Data']['Monthly Net Cash Flow for Savings and Investments'];
  }
  return module9Data.annualNetCashFlowDorSavingsAndInvestments529;
}

export function afterTaxIncomeInYearInsurenceBalance() {
  module9Data.afterTaxIncomeInYear529Balance = 0;
  if (incomeStatementData !== undefined &&
    incomeStatementData[module9Data.life_insurance_start_year] !== undefined &&
    incomeStatementData[module9Data.life_insurance_start_year] !== '' &&
    incomeStatementData[module9Data.life_insurance_start_year]['Income After Taxes'] !== '') {
    module9Data.afterTaxIncomeInYear529Balance = incomeStatementData[module9Data.life_insurance_start_year]['Income After Taxes'];
  }
  return module9Data.afterTaxIncomeInYear529Balance;
}

//Annual Contribution in Year 2024
export function annualContributionInYear529() {
  module9Data.annualContributionInYear529 = 0;
  if (module9Data.afterTaxIncomeInYear529Balance !== undefined && module9Data.afterTaxIncomeInYear529Balance !== '' && module9Data.plan_529_annual_contribution_percentage !== undefined && module9Data.plan_529_annual_contribution_percentage !== '') {
    module9Data.annualContributionInYear529 = (module9Data.afterTaxIncomeInYear529Balance / 100) * module9Data.plan_529_annual_contribution_percentage;
  }
  return module9Data.annualContributionInYear529;
}

export function cashBalanceAtTheEndOfPreviourYear529() {
  module9Data.cashBalanceAtTheEndOfPreviourYear529 = 0;
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module9Data.plan_529_start_year === module1Data.start_year && module18Data.existingCurrentCashBalance !== undefined && module18Data.existingCurrentCashBalance !== '') {
    module9Data.cashBalanceAtTheEndOfPreviourYear529 = module18Data.existingCurrentCashBalance;
  } else {

    if (cashFlowData !== undefined &&
      cashFlowData !== '' &&
      cashFlowData[parseInt(module9Data.plan_529_start_year) - 1] !== undefined &&
      cashFlowData[parseInt(module9Data.plan_529_start_year) - 1] !== '') {
      module9Data.cashBalanceAtTheEndOfPreviourYear529 = cashFlowData[parseInt(module9Data.plan_529_start_year) - 1]['Ending Cash Balance'];
    } else {
      module9Data.cashBalanceAtTheEndOfPreviourYear529 = 0;
    }
  }
  return module9Data.cashBalanceAtTheEndOfPreviourYear529;
}
//Cash Balance at the Current Year 2023
export function cashBalanceAtTheEndOfCurrentYear529() {
  module9Data.cashBalanceAtTheEndOfCurrentYear529 = 0;
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '' && cashFlowData !== undefined &&
    cashFlowData !== '' &&
    cashFlowData[module9Data.plan_529_start_year] !== undefined &&
    cashFlowData[module9Data.plan_529_start_year - 1] !== '') {
    module9Data.cashBalanceAtTheEndOfCurrentYear529 = cashFlowData[module9Data.plan_529_start_year]['Ending Cash Balance'];
  } else {
    module9Data.cashBalanceAtTheEndOfCurrentYear529 = 0;
  }
  return module9Data.cashBalanceAtTheEndOfCurrentYear529;
}
//529 years label
export function longTermInvestmentsPeriod529() {
  module9Data.longTermInvestmentsPeriod529 = 0;
  const d = new Date();
  const currentYear = d.getFullYear();
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    module9Data.longTermInvestmentsPeriod529 = module9Data.plan_529_start_year + '-' + (parseInt(module1Data.start_year) + 14);
  }
  return module9Data.longTermInvestmentsPeriod529;
}
//Long-Term Investments Period Years
export function longTermInvestmentsPeriodYears529() {
  module9Data.longTermInvestmentsPeriodYears529 = 0;
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '') {
    if (module1Data.start_year !== undefined && module1Data.start_year !== '' && module9Data.plan_529_start_year >= 0) {
      module9Data.longTermInvestmentsPeriodYears529 = (parseInt(module1Data.start_year) + 14) - parseInt(module9Data.plan_529_start_year) + 1;
    } else {
      module9Data.longTermInvestmentsPeriodYears529 = 0;
    }
  }
  return module9Data.longTermInvestmentsPeriodYears529;
}

//Total Contributions
export function totalContribution529() {
  module9Data.totalContribution529 = 0;
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '') {
      let start_year = parseInt(module1Data.start_year);
      let endYear = parseInt(module1Data.start_year) + 14;
      let data = investmentsData['529 Plan'];
      let totalContribution529CalVal = 0;
      if (Object.keys(data).length !== 0) {
        Object.keys(data).forEach((year) => {
          if (year >= start_year && year <= endYear) {
            totalContribution529CalVal += data[year]['Cash Contibution'];
          }
        })
      }
      module9Data.totalContribution529 = totalContribution529CalVal;
    }
  }
  return module9Data.totalContribution529;
}
//Total Earnings
export function totalEarnings529() {
  module9Data.totalEarnings529 = 0;
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '') {
      let start_year = parseInt(module1Data.start_year);
      let endYear = parseInt(module1Data.start_year) + 14;
      let data = investmentsData['529 Plan'];
      let totalEarnings529CalVal = 0;
      if (Object.keys(data).length !== 0) {
        Object.keys(data).forEach((year) => {
          if (year >= start_year && year <= endYear) {
            totalEarnings529CalVal += data[year]['Earnings'];
          }
        })
      }
      module9Data.totalEarnings529 = totalEarnings529CalVal;
    }
  }
  return module9Data.totalEarnings529;
}
//Ending 529 Balance
export function endingBalance529() {
  module9Data.endingBalance529 = 0;
  if (module1Data.start_year !== undefined && module1Data.start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['529 Plan'][parseInt(module1Data.start_year) + 14] !== undefined && investmentsData['529 Plan'][parseInt(module1Data.start_year) + 14] !== '' && investmentsData['529 Plan'][parseInt(module1Data.start_year) + 14]['Balance2'] !== undefined) {
    module9Data.endingBalance529 = investmentsData['529 Plan'][parseInt(module1Data.start_year) + 14]['Balance2'];
  } else {
    module9Data.endingBalance529 = 0;
  }
  return module9Data.endingBalance529;
}

//Life Insurance
export function beginningLifeInsBalance() {
  module9Data.beginningLifeInsBalance = 0;
  if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '' && investmentsData !== undefined && investmentsData !== '' && investmentsData['Life Insurance'][module9Data.life_insurance_start_year] !== undefined && investmentsData['Life Insurance'][module9Data.life_insurance_start_year] !== '' && investmentsData['Life Insurance'][module9Data.life_insurance_start_year]['Beg Balance'] !== undefined && investmentsData['Life Insurance'][module9Data.life_insurance_start_year]['Beg Balance'] !== '') {
    module9Data.beginningLifeInsBalance = investmentsData['Life Insurance'][module9Data.life_insurance_start_year]['Beg Balance'];
  }
  return module9Data.beginningLifeInsBalance;
}
export function monthlyPremiumCalcs() {
  module9Data.monthlyPremiumCalcs = 0;
  if (module9Data.monthlyPremium !== undefined && module9Data.monthlyPremium !== '') {
    module9Data.monthlyPremiumCalcs = module9Data.monthlyPremium * 12;
  }
  return module9Data.monthlyPremiumCalcs;
}
export function monthlyContributionCashBuilder() {
  module9Data.monthlyContributionCashBuilder = 0;
  if (module9Data.monthly_premium_percentage !== undefined && module9Data.monthly_premium_percentage !== '' && module9Data.monthly_premium !== undefined && module9Data.monthly_premium !== '') {
    module9Data.monthlyContributionCashBuilder = ((module9Data.monthly_premium) / 100) * (module9Data.monthly_premium_percentage);
  }
  return module9Data.monthlyContributionCashBuilder;
}
export function monthlyContributionCashBuilderAnnualVal() {
  module9Data.monthlyContributionCashBuilderAnnualVal = 0;
  if (module9Data.monthlyContributionCashBuilder !== undefined && module9Data.monthlyContributionCashBuilder !== '') {
    module9Data.monthlyContributionCashBuilderAnnualVal = module9Data.monthlyContributionCashBuilder * 12;
  }
  return module9Data.monthlyContributionCashBuilderAnnualVal;
}
export function annualNetCashFlowForSavingsLifeIns() {
  module9Data.annualNetCashFlowForSavingsLifeIns = 0;
  if (module9Data.life_insurance_start_year !== undefined &&
    module9Data.life_insurance_start_year !== '' &&
    inputSheetData !== undefined &&
    inputSheetData !== '' &&
    inputSheetData[module9Data.life_insurance_start_year] !== undefined &&
    inputSheetData[module9Data.life_insurance_start_year] !== '' &&
    inputSheetData[module9Data.life_insurance_start_year]['Monthly Data']['Monthly Retirement Contributions'] !== undefined) {
    module9Data.annualNetCashFlowForSavingsLifeIns = inputSheetData[module9Data.life_insurance_start_year]['Monthly Data']['Monthly Retirement Contributions'];
  }
  return module9Data.annualNetCashFlowForSavingsLifeIns;
}

//Cash Balance at the End of Year 2023
export function cashBalanceAtTheEndYearLifeIns() {
  module9Data.cashBalanceAtTheEndYearLifeIns = 0;
  if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module9Data.life_insurance_start_year === module1Data.start_year && module18Data.existingCurrentCashBalance !== undefined && module18Data.existingCurrentCashBalance !== '') {
    module9Data.cashBalanceAtTheEndYearLifeIns = module18Data.existingCurrentCashBalance;
  } else {
    if (cashFlowData !== undefined &&
      cashFlowData !== '' &&
      cashFlowData[parseInt(module9Data.life_insurance_start_year, 10) - 1] !== undefined &&
      cashFlowData[parseInt(module9Data.life_insurance_start_year, 10) - 1] !== '') {
      module9Data.cashBalanceAtTheEndYearLifeIns = cashFlowData[parseInt(module9Data.life_insurance_start_year, 10) - 1]['Ending Cash Balance'];
    } else {
      module9Data.cashBalanceAtTheEndYearLifeIns = 0;
    }
  }
  return module9Data.cashBalanceAtTheEndYearLifeIns;
}
//Cash Balance at the Current Year 2023
export function cashBalanceAtTheCurrentYearLifeIns() {
  module9Data.cashBalanceAtTheCurrentYearLifeIns = 0;
  if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '' && cashFlowData !== undefined &&
    cashFlowData !== '' &&
    cashFlowData[module9Data.life_insurance_start_year] !== undefined &&
    cashFlowData[module9Data.life_insurance_start_year - 1] !== '') {
    module9Data.cashBalanceAtTheCurrentYearLifeIns = cashFlowData[module9Data.life_insurance_start_year]['Ending Cash Balance'];
  } else {
    module9Data.cashBalanceAtTheCurrentYearLifeIns = 0;
  }
  return module9Data.cashBalanceAtTheCurrentYearLifeIns;
}

//Life Insurance years label
export function longTermInvestmentsPeriodLife() {
  module9Data.longTermInvestmentsPeriodLife = 0;
  const d = new Date();
  const currentYear = d.getFullYear();
  if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '') {
    module9Data.longTermInvestmentsPeriodLife = module9Data.life_insurance_start_year + '-' + (parseInt(module1Data.start_year, 10) + 14);
  }
  return module9Data.longTermInvestmentsPeriodLife;
}
//Life Insurance Period Years
export function longTermInvestmentsPeriodYearsLife() {
  module9Data.longTermInvestmentsPeriodYearsLife = 0;
  if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '' && module9Data.life_insurance_start_year >= 0) {
    if (module1Data.start_year !== undefined && module1Data.start_year !== '') {
      module9Data.longTermInvestmentsPeriodYearsLife = (parseInt(module1Data.start_year, 10) + 14) - parseInt(module9Data.life_insurance_start_year, 10) + 1;
    } else {
      module9Data.longTermInvestmentsPeriodYearsLife = 0;
    }
  }
  return module9Data.longTermInvestmentsPeriodYearsLife;
}
//Life Insurance Summary section
export function beginningLifeInsBalanceAnalysis() {
  module9Data.beginningLifeInsBalanceAnalysis = 0;
  if (module18Data.existing_cash_value_life_insurance_policies !== undefined && module18Data.existing_cash_value_life_insurance_policies !== '') {
    module9Data.beginningLifeInsBalanceAnalysis = module18Data.existing_cash_value_life_insurance_policies;
  }
  return module9Data.beginningLifeInsBalanceAnalysis;
}
//Total Contributions
export function totalContributionLifeIns() {
  module9Data.totalContributionLifeIns = 0;
  if (module9Data.life_insurance_start_year !== undefined && module9Data.life_insurance_start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '') {
      let start_year = parseInt(module1Data.start_year, 10);
      let endYear = parseInt(module1Data.start_year, 10) + 14;
      let data = investmentsData['Life Insurance'];
      let totalContributionLifeInsCalVal = 0;
      if (Object.keys(data).length !== 0) {
        Object.keys(data).forEach((year) => {
          if (year >= start_year && year <= endYear) {
            totalContributionLifeInsCalVal += data[year]['Cash Contibution'];
          }
        });
      }
      module9Data.totalContributionLifeIns = totalContributionLifeInsCalVal;
    }
  }
  return module9Data.totalContributionLifeIns;
}
//Total Earnings
export function totalEarningsLifeIns() {
  module9Data.totalEarningsLifeIns = 0;
  if (module9Data.plan_529_start_year !== undefined && module9Data.plan_529_start_year !== '') {
    if (investmentsData !== undefined && investmentsData !== '') {
      let starYear = parseInt(module1Data.start_year, 10);
      let endYear = parseInt(module1Data.start_year, 10) + 14;
      let data = investmentsData['Life Insurance'];
      let totalEarningsLifeInsCalVal = 0;
      if (Object.keys(data).length !== 0) {
        Object.keys(data).forEach((year) => {
          if (year >= starYear && year <= endYear) {
            totalEarningsLifeInsCalVal += data[year]['Earnings'];
          }
        })
      }
      module9Data.totalEarningsLifeIns = totalEarningsLifeInsCalVal;
    }
  }
  return module9Data.totalEarningsLifeIns;
}
//Ending Life Ins Balance
export function endingBalanceLifeIns() {
  module9Data.endingBalanceLifeIns = 0;
  if (module1Data.start_year !== undefined && module1Data.start_year !== '' &&
    investmentsData !== undefined &&
    investmentsData !== '' &&
    investmentsData['Life Insurance'][parseInt(module1Data.start_year, 10) + 1] !== undefined &&
    investmentsData['Life Insurance'][parseInt(module1Data.start_year, 10) + 1] !== '' &&
    investmentsData['Life Insurance'][parseInt(module1Data.start_year, 10) + 1]['Balance2'] !== undefined) {
    module9Data.endingBalanceLifeIns = investmentsData['Life Insurance'][parseInt(module1Data.start_year, 10) + 1]['Balance2'];
  } else {
    module9Data.endingBalanceLifeIns = 0;
  }
  return module9Data.endingBalanceLifeIns;
}

/** ******************** Module 9 Investments Ends *********************** */

export function get5thYearNetworth() {
  if (module1Data.start_year > 0) {
    const year = module1Data.start_year + 4;
    if (balanceSheetData[year] !== undefined && balanceSheetData[year] !== '' && balanceSheetData[year]['Net Worth'] !== undefined && balanceSheetData[year]['Net Worth'] !== '') {
      return balanceSheetData[year]['Net Worth'];
    }
    return 0;
  }
  return 0;
}
export function get10thYearNetworth() {
  if (module1Data.start_year > 0) {
    const year = module1Data.start_year + 9;
    if (balanceSheetData[year] !== undefined && balanceSheetData[year] !== '' && balanceSheetData[year]['Net Worth'] !== undefined && balanceSheetData[year]['Net Worth'] !== '') {
      return balanceSheetData[year]['Net Worth'];
    }
    return 0;
  }
  return 0;
}
export function get15thYearNetworth() {
  if (module1Data.start_year > 0) {
    const year = module1Data.start_year + 14;
    if (balanceSheetData[year] !== undefined && balanceSheetData[year] !== '' && balanceSheetData[year]['Net Worth'] !== undefined && balanceSheetData[year]['Net Worth'] !== '') {
      return balanceSheetData[year]['Net Worth'];
    }
    return 0;
  }
  return 0;
}
export function getCurrentYearNetworth() {
  if (module1Data.start_year > 0) {
    const year = module1Data.start_year;
    if (balanceSheetData[year] !== undefined && balanceSheetData[year] !== '' && balanceSheetData[year]['Net Worth'] !== undefined && balanceSheetData[year]['Net Worth'] !== '') {
      return balanceSheetData[year]['Net Worth'];
    }
    return 0;
  }
  return 0;
}

// Investment Analysis Tuition Cost
export function investmentAnalysisTuitionCostsCal() {
  module2Data.investmentAnalysisTuitionCostsCal = 0;
  if (module2Data.tuition !== undefined
    && module2Data.tuition !== '' && module2Data.tuition > 0) {
    if (incomeStatementData !== undefined && incomeStatementData !== '') {
      const start_year = parseInt(module2Data.start_year, 10);
      const endYear = parseInt(module2Data.graduation_year, 10);
      const tuitionCostData = incomeStatementData;
      let investmentAnalysisTuitionCostsCalVal = 0;
      if (Object.keys(tuitionCostData).length !== 0) {
        Object.keys(tuitionCostData).forEach((year) => {
          if (year >= start_year && year <= endYear) {
            investmentAnalysisTuitionCostsCalVal += tuitionCostData[year].livingExpenses['Higher Education Tuition and Fees'];
          }
        });
      }
      module2Data.investmentAnalysisTuitionCostsCal = investmentAnalysisTuitionCostsCalVal;
    } else {
      module2Data.investmentAnalysisTuitionCostsCal = 0;
    }
  }
  return module2Data.investmentAnalysisTuitionCostsCal;
}

// Investment Analysis Cash Excess
export function investmentAnalysisCashExcess() {
  module2Data.investmentAnalysisCashExcess = 0;
  if (module2Data.investmentAnalysisTotalCashAvailable !== undefined && module2Data.investmentAnalysisTotalCashAvailable !== ''
    && module2Data.investmentAnalysisTuitionCostsCal !== undefined && module2Data.investmentAnalysisTuitionCosts !== '') {
    module2Data.investmentAnalysisCashExcess = module2Data.investmentAnalysisTotalCashAvailable
      + module2Data.investmentAnalysisTuitionCostsCal;
  }
  return module2Data.investmentAnalysisCashExcess;
}
// Investment Analysis Total Living
export function investmentAnalysisTotalLiving() {
  module2Data.investmentAnalysisTotalLiving = 0;
  const start_year = parseInt(module2Data.start_year, 10);
  const endYear = parseInt(module2Data.graduation_year, 10);
  const data = incomeStatementData;
  let Val1 = 0;
  let Val2 = 0;
  if (Object.keys(data).length !== 0) {
    Object.keys(data).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        if (data[year] !== undefined) {
          Val1 += data[year]['Total Living Expenses'];
          Val2 += data[year].InterestExpenses['Student Loans'];
        }
      }
    });
  }

  const cashFlowdata = cashFlowData;
  let Val3 = 0;
  if (Object.keys(cashFlowdata).length !== 0) {
    Object.keys(cashFlowdata).forEach((year) => {
      if (year >= start_year && year <= endYear) {
        if (cashFlowdata[year] !== undefined
          && cashFlowdata[year]['Cash from Financing Activities'] !== undefined) {
          Val3 += cashFlowdata[year]['Cash from Financing Activities']['Student Loans Principal Payback'];
        }
      }
    });
  }

  module2Data.investmentAnalysisTotalLiving = Val1 + Val2 - module2Data.investmentAnalysisTuitionCostsCal + Val3;
  return module2Data.investmentAnalysisTotalLiving;
}
// Investment Analysis Cash Excess After Living
export function investmentAnalysisCashExcessAfterLiving() {
  module2Data.investmentAnalysisCashExcessAfterLiving = parseFloat(module2Data.investmentAnalysisCashExcessBeforeLiving) + parseFloat(module2Data.investmentAnalysisTotalLiving);
  return module2Data.investmentAnalysisCashExcessAfterLiving;
}
// Investment Analysis Cash Excess Before Living
export function investmentAnalysisCashExcessBeforeLiving() {
  module2Data.investmentAnalysisCashExcessBeforeLiving = 0;
  if (module2Data.investmentAnalysisCashExcess !== undefined
    && module2Data.investmentAnalysisCashExcess !== '') {
    module2Data.investmentAnalysisCashExcessBeforeLiving = module2Data.investmentAnalysisCashExcess;
  }
  return module2Data.investmentAnalysisCashExcessBeforeLiving;
}

export function familyContriYearsDiff() {
  module2Data.familyContriYearsDiff = 0;
  if (module2Data.family_end_year !== '' && module2Data.family_start_year !== '' && module2Data.family_end_year > 0 && module2Data.family_start_year > 0) {
    module2Data.familyContriYearsDiff = (module2Data.family_end_year - module2Data.family_start_year) + 1;
  }
  return module2Data.familyContriYearsDiff;
}
// Investment Analysis Family Contribution
export function investmentAnalysisFamilyContribution() {
  module2Data.investmentAnalysisFamilyContribution = 0;
  if (module2Data.yearly_contribution !== undefined && module2Data.yearly_contribution !== ''
    && module2Data.familyContriYearsDiff !== undefined && module2Data.familyContriYearsDiff !== '') {
    module2Data.investmentAnalysisFamilyContribution = module2Data.yearly_contribution * module2Data.familyContriYearsDiff;
  }
  return module2Data.investmentAnalysisFamilyContribution;
}
export function aidYearsDiff() {
  module2Data.aidYearsDiff = 0;
  if (module2Data.year_aid_begins !== '' && module2Data.year_aid_ends !== '' && module2Data.year_aid_begins && module2Data.year_aid_ends > 0) {
    module2Data.aidYearsDiff = (module2Data.year_aid_ends) - (module2Data.year_aid_begins) + 1;
  }
  return module2Data.aidYearsDiff;
}

// Investment Analysis Financial Aid
export function investmentAnalysisFinancialAid() {
  module2Data.investmentAnalysisFinancialAid = 0;
  if (module2Data.year_aid_begins !== undefined && module2Data.year_aid_begins !== ''
    && module2Data.aidYearsDiff !== undefined && module2Data.aidYearsDiff !== '') {
    module2Data.investmentAnalysisFinancialAid = module2Data.year_aid_begins * module2Data.aidYearsDiff;
  }
  return module2Data.investmentAnalysisFinancialAid;
}

// Investment Grants
export function investmentAnalysisGrants() {
  module2Data.investmentAnalysisGrants = 0;
  if (module2Data.yearly_grant_amount !== undefined && module2Data.yearly_grant_amount !== ''
    && module2Data.years_diff !== undefined && module2Data.years_diff !== '') {
    module2Data.investmentAnalysisGrants = module2Data.yearly_grant_amount * module2Data.years_diff;
  }
  return module2Data.investmentAnalysisGrants;
}

// Investment Analysis Outstanding Loan
export function investmentAnalysisOutstandingLoan() {
  module2Data.investmentAnalysisOutstandingLoan = 0;
  module2Data.investmentAnalysisOutstandingLoan = module2Data.fedSubBeginningBalance
    + module2Data.fedUnSubPrincipal
    + module2Data.privatePrincipal;
  return module2Data.investmentAnalysisOutstandingLoan;
}

// Investment Analysis Scholarships
export function investmentAnalysisScholships() {
  module2Data.investmentAnalysisScholships = 0;
  if (module2Data.yearly_scholarship_amount !== undefined && module2Data.yearly_scholarship_amount !== ''
    && module2Data.years_diff !== undefined && module2Data.years_diff !== '') {
    module2Data.investmentAnalysisScholships = module2Data.yearly_scholarship_amount * module2Data.years_diff;
  }
  return module2Data.investmentAnalysisScholships;
}

// Investment Analysis Total Principal & Interest
export function investmentAnalysisTotalPrincipalInterest() {
  module2Data.investmentAnalysisTotalPrincipalInterest = 0;
  if (module2Data.start_year !== undefined && module2Data.start_year !== ''
    && parseInt(module2Data.start_year, 10) > 0) {
    module2Data.investmentAnalysisTotalPrincipalInterest = module2Data.fedSubTotalPrincipalInterest
      + module2Data.fedUnSubTotalPrincipalInterest
      + module2Data.privateTotalPrincipalInterest;
  }
  return module2Data.investmentAnalysisTotalPrincipalInterest;
}

export function monthlyNetCashFlow(year) {
  module9Data.monthlyNetCashFlow = 0;
  if (year !== undefined && year !== '') {
    if (inputSheetData !== undefined && inputSheetData !== '' && inputSheetData[year] !== undefined && inputSheetData[year] !== ''
      && inputSheetData[year]['Monthly Data'] !== undefined && inputSheetData[year]['Monthly Data'] !== ''
      && inputSheetData[year]['Monthly Data']['Monthly Net Cash Flow for Savings and Investments'] !== undefined
      && inputSheetData[year]['Monthly Data']['Monthly Net Cash Flow for Savings and Investments'] !== '') {
      module9Data.monthlyNetCashFlow = inputSheetData[year]['Monthly Data']['Monthly Net Cash Flow for Savings and Investments'];
    }
  }
  return module9Data.monthlyNetCashFlow;
}

export function totalMonthlyHomeExpenses() {
  module18Data.totalMonthlyHomeExpenses = 0;
  module18Data.totalMonthlyHomeExpenses =
    module18Data.existing_home_monthly_property_taxes +
    module18Data.existing_home_monthly_homeowner_insurance +
    module18Data.existing_home_monthly_private_mortgage_insurance +
    module18Data.existing_home_monthly_maintenance +
    module18Data.existing_home_monthly_association_fees +
    module18Data.existing_home_monthly_utilities +
    module18Data.existingHomeMortgageMonthlyPayment
    ;
  return module18Data.totalMonthlyHomeExpenses;
}
export function totalMonthlyHomeExpenses2() {
  module18Data.totalMonthlyHomeExpenses2 = 0;
  module18Data.totalMonthlyHomeExpenses2 = module18Data.existing_home_monthly_property_taxes_2 + module18Data.existing_home_monthly_homeowner_insurance_2 + module18Data.existing_home_monthly_private_mortgage_insurance_2 + module18Data.existing_home_monthly_maintenance_2 + module18Data.existing_home_monthly_association_fees_2 + module18Data.existing_home_monthly_utilities_2
    + module18Data.existingHomeMortgageMonthlyPayment2
    ;
  return module18Data.totalMonthlyHomeExpenses2;
}


//Personal Contribution (Max of $00 by law)
export function careerAdv401PersonalContribution_401k() {
  module5Data.careerAdv401PersonalContribution_401k = 0;
  if (module5Data.start_year !== undefined &&
    module5Data.start_year !== '' &&
    databaseData !== undefined &&
    databaseData !== '' &&
    databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year] !== undefined &&
    databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year]['Max Personal Limit'] !== undefined) {
    module5Data.careerAdv401PersonalContribution_401k = databaseData['401(k) 2018 Contribution Limits'][module5Data.start_year]['Max Personal Limit'];
  }
  else {
    module5Data.careerAdv401PersonalContribution_401k = 0;
  }
  return module5Data.careerAdv401PersonalContribution_401k;
}


export function advCareerRothIRAPersonalContributionCalcsRoth() {
  module5Data.advCareerRothIRAPersonalContributionCalcsRoth = 0;
  var firstEle = 0;
  var firstEle1 = 0;
  var firstEle2 = 0;
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.roth_personal_contribution !== undefined && module5Data.roth_personal_contribution !== '') {
    firstEle1 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.roth_personal_contribution;
  }
  if (module5Data.advanceTotalPreTaxExpected_val !== undefined && module5Data.advanceTotalPreTaxExpected_val !== '' && module5Data.ira_roth_personal_contribution !== undefined && module5Data.ira_roth_personal_contribution !== '') {
    firstEle2 = (module5Data.advanceTotalPreTaxExpected_val / 100) * module5Data.ira_roth_personal_contribution;
  }
  if (firstEle2 > 0) {
    firstEle = firstEle1 + firstEle2;
    if (module5Data.Contribute401k !== undefined && module5Data.Contribute401k === 'Yes' && module5Data.advCareerRothIRAPersonalContribution !== undefined && module5Data.careerAdv401PersonalContribution !== '' && firstEle <= module5Data.advCareerRothIRAPersonalContribution) {
      module5Data.advCareerRothIRAPersonalContributionCalcsRoth = firstEle2;
    } else {
      var roth_personal_contribution = 0;
      if (module5Data.roth_personal_contribution !== undefined && module5Data.roth_personal_contribution !== '') {
        roth_personal_contribution = parseFloat(module5Data.roth_personal_contribution);
      }
      var ira_roth_personal_contribution = 0;
      if (module5Data.ira_roth_personal_contribution !== undefined && module5Data.ira_roth_personal_contribution !== '') {
        ira_roth_personal_contribution = parseFloat(module5Data.ira_roth_personal_contribution);
      }
      if (module5Data.advCareerRothIRAPersonalContribution !== undefined && module5Data.advCareerRothIRAPersonalContribution !== '') {
        if (roth_personal_contribution > 0 || ira_roth_personal_contribution > 0) {
          module5Data.advCareerRothIRAPersonalContributionCalcsRoth = (ira_roth_personal_contribution / (roth_personal_contribution + ira_roth_personal_contribution)) * module5Data.advCareerRothIRAPersonalContribution;
        } else {
          module5Data.advCareerRothIRAPersonalContributionCalcsRoth = 0;
        }
      } else {
        module5Data.advCareerRothIRAPersonalContributionCalcsRoth = 0;
      }
    }
  }
  return module5Data.advCareerRothIRAPersonalContributionCalcsRoth;
}

export function careerAdvHSAContributions() {
  module5Data.careerAdvHSAContributions = 0;
  if (cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[module5Data.start_year] !== undefined && cashFlowData[module5Data.start_year] !== '' && cashFlowData[module5Data.start_year]['Cash from Investing Activities'] !== undefined && cashFlowData[module5Data.start_year]['Cash from Investing Activities'] !== '' && cashFlowData[module5Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined) {
    module5Data.careerAdvHSAContributions = -(cashFlowData[module5Data.start_year]['Cash from Investing Activities']['HSA Contributions']);
  }
  return module5Data.careerAdvHSAContributions;
}

//Retirement Contributions
export function finalcareerAdvHSAContributions() {
  module5Data.finalcareerAdvHSAContributions = 0;

  if (module5Data.start_year !== undefined && module5Data.start_year !== '' &&
    module5Data.incomeEndYear !== undefined && module5Data.incomeEndYear !== '') {
    var start_year = parseInt(module5Data.start_year);
    var endYear = parseInt(module5Data.incomeEndYear);
    var data = cashFlowData;
    var retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['HSA Contributions'];
        }
      });
    }
    module5Data.finalcareerAdvHSAContributions = -retirementContributionValue;
  } else {
    module5Data.finalcareerAdvHSAContributions = 0;
  }
  return module5Data.finalcareerAdvHSAContributions;
}
export function careerAdditionalHSAContributions() {
  module8Data.careerAdditionalHSAContributions = 0;
  if (cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[module8Data.start_year] !== undefined && cashFlowData[module8Data.start_year] !== '' && cashFlowData[module8Data.start_year]['Cash from Investing Activities'] !== undefined && cashFlowData[module8Data.start_year]['Cash from Investing Activities'] !== '' && cashFlowData[module8Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined) {
    module8Data.careerAdditionalHSAContributions = -(cashFlowData[module8Data.start_year]['Cash from Investing Activities']['HSA Contributions']);
  }
  return module8Data.careerAdditionalHSAContributions;
}

//Retirement Contributions
export function finalAdditionalcareerHSAContributions() {
  module8Data.finalAdditionalcareerHSAContributions = 0;

  if (module8Data.start_year !== undefined && module8Data.start_year !== '' &&
    module8Data.endYear !== undefined && module8Data.endYear !== '') {
    var start_year = parseInt(module8Data.start_year);
    var endYear = parseInt(module8Data.endYear);
    var data = cashFlowData;
    var retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['HSA Contributions'];
        }
      });
    }
    module8Data.finalAdditionalcareerHSAContributions = -retirementContributionValue;
  } else {
    module8Data.finalAdditionalcareerHSAContributions = -retirementContributionValue;
  }
  return module8Data.finalAdditionalcareerHSAContributions;
}


export function careerPathHSAContributions() {
  module4Data.careerPathHSAContributions = 0;
  if (cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[module4Data.start_year] !== undefined && cashFlowData[module4Data.start_year] !== '' && cashFlowData[module4Data.start_year]['Cash from Investing Activities'] !== undefined && cashFlowData[module4Data.start_year]['Cash from Investing Activities'] !== '' && cashFlowData[module4Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined) {
    module4Data.careerPathHSAContributions = -(cashFlowData[module4Data.start_year]['Cash from Investing Activities']['HSA Contributions']);
  }
  return module4Data.careerPathHSAContributions;
}

//Retirement Contributions
export function finalCareerPathHSAContributions() {
  module4Data.finalCareerPathHSAContributions = 0;

  if (module4Data.start_year !== undefined && module4Data.start_year !== '' &&
    module4Data.incomeEndYear !== undefined && module4Data.incomeEndYear !== '') {
    var start_year = parseInt(module4Data.start_year);
    var endYear = parseInt(module4Data.incomeEndYear);
    var data = cashFlowData;
    var retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['HSA Contributions'];
        }
      });
    }
    module4Data.finalCareerPathHSAContributions = -retirementContributionValue;
  } else {
    module4Data.finalCareerPathHSAContributions = 0;
  }
  return module4Data.finalCareerPathHSAContributions;
}


export function careerNewHSAContributions() {
  module17Data.careerNewHSAContributions = 0;
  if (cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[module17Data.start_year] !== undefined && cashFlowData[module17Data.start_year] !== '' && cashFlowData[module17Data.start_year]['Cash from Investing Activities'] !== undefined && cashFlowData[module17Data.start_year]['Cash from Investing Activities'] !== '' && cashFlowData[module17Data.start_year]['Cash from Investing Activities']['HSA Contributions'] !== undefined) {
    module17Data.careerNewHSAContributions = -(cashFlowData[module17Data.start_year]['Cash from Investing Activities']['HSA Contributions']);
  }
  return module17Data.careerNewHSAContributions;
}

//Retirement Contributions
export function finalcareerNewHSAContributions() {
  module17Data.finalcareerNewHSAContributions = 0;

  if (module17Data.start_year !== undefined && module17Data.start_year !== '' &&
    module17Data.endYear !== undefined && module17Data.endYear !== '') {
    var start_year = parseInt(module17Data.start_year);
    var endYear = parseInt(module17Data.endYear);
    var data = cashFlowData;
    var retirementContributionValue = 0;
    if (Object.keys(data).length !== 0) {
      Object.keys(data).forEach((year) => {
        if (year >= start_year && year <= endYear) {
          retirementContributionValue += data[year]['Cash from Investing Activities']['HSA Contributions'];
        }
      });
    }
    module17Data.finalcareerNewHSAContributions = -retirementContributionValue;
  } else {
    module17Data.finalcareerNewHSAContributions = 0;
  }
  return module17Data.finalcareerNewHSAContributions;
}