import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-aca/styles';
import CareerSketchCards from '../../career-sketch/cards/index';

const CareerExploration = ( props ) => {
  const {
    classes,
  } = props;

  return (
    <div>
      <div className={ classes.contentBlock }>
        <p>
          Not sure of your next move? Use the links below to raise your awareness to different career opportunities. Ever wondered what income you might earn? What kind of work lifestyle you might have? What training, certification, or education you might need? Discover careers that relate to your interests and learn what it takes to become a master in your chosen career.
        </p>
        <p>
          <a href="/career-sketch" target="_blank" rel="noopener noreferrer">Go to Inkwiry Career Sketches</a>
        </p>

      </div>
      <CareerSketchCards fromPage="career-path" />

      <div>
        <p className={ classes.addResource }>
          Here are some additional resources to help you grow your awareness to career opportunities and explore your interests:
        </p>
        <ul className={ classes.squareList }>
          <li><a href="https://www.mynextmove.org/" target="_blank" rel="noopener noreferrer">My Next Move</a></li>
          <li><a href="https://www.careeronestop.org/" target="_blank" rel="noopener noreferrer">CareerOneStop</a></li>
          <li><a href="https://www.bls.gov/ooh/" target="_blank" rel="noopener noreferrer">Occupational Outlook Handbook</a></li>
          <li><a href="https://www.payscale.com/research/US/Country=United_States/Salary" target="_blank" rel="noopener noreferrer">Payscale</a></li>
          <li><a href="https://www.truity.com/test/career-personality-profiler-test" target="_blank" rel="noopener noreferrer">Career Personality Profiler</a></li>
        </ul>
      </div>
    </div>

  );
};

CareerExploration.propTypes = {
  classes: PropTypes.object.isRequired,

};

export default withStyles( styles )( CareerExploration );
