import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button } from '@material-ui/core';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
// import SwipeableViews from 'react-swipeable-views';
// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import styles from './styles';
import ScrollToTop from '../../../components/ui/scroll-to-top/scrollToTop';
import classNames from 'classnames';
import { Link } from 'react-scroll';
// import pricingBanner from '../../../assets/img/pricing/pricing_banner_img_new.png';
import triangleImage from '../../../assets/img/pricing/pricingcards_bg.png';
// import tickMark from '../../../assets/img/pricing/tick_mark.png';
// import powerUp from '../../../assets/img/pricing/print_power_up_cover.jpg';
// import pf101 from '../../../assets/img/pricing/powerup-101-gold-wlogo.png';
// import careerSketch from '../../../assets/img/pricing/my-career-sketch-blue.png';
// import pwiLogo from '../../../assets/img/pricing/puzzle-icon.png';

import pwiLogo1 from '../../../assets/img/pricing/my-career-sketch-blue-new.png';
import pwiLogo2 from '../../../assets/img/pricing/plan-wit.png';
import pwiLogo3 from '../../../assets/img/pricing/powerup-101-gold.png';
import downloadBrochure from '../../../assets/img/pricing/download-brochure.png';
import NineByTen from '../../../assets/img/pricing/nine_by_ten.png';
import quoteTwo from '../../../assets/img/pricing/quote2.png';
import quotesWhite from '../../../assets/img/students-parents/quotes_img_white.png';

import inkwiryForEducation from '../../../assets/img/Inkwiry_for_Education_2021.pdf';

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`,
//   };
// }

const Pricing = (props) => {
  const { classes, history } = props;
  // const theme = useTheme();

  let referalId = history.location.pathname;
  referalId = referalId.split('/');
  referalId = referalId[2];

  // const [value, setValue] = React.useState(0);
  const [expanded, setExpanded] = React.useState(false);
  const frequentAskedQuestions = [
    {
      id: 1, scroll: useRef(null),
    },
    {
      id: 2, scroll: useRef(null),
    },
    {
      id: 3, scroll: useRef(null),
    },
    {
      id: 4, scroll: useRef(null),
    },
    {
      id: 5, scroll: useRef(null),
    },
  ];
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const buyNow = (plan) => {
    localStorage.setItem('inkwiry_pricing_plan', plan);
    localStorage.setItem('referal_key_id', referalId);
    history.push('/join');
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };

  const handleAccordianChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleScrollToPoint = (askedQutn) => {
    scrollToComponent(askedQutn, {
      offset: -500,
      align: 'top',
      duration: 200,
      behavior: 'smooth',
    });
  };
  return (
    <Typography variant="body1" component="div">
      <div className={classes.pricingPage}>
        <div className={classes.pricingBanner}>
          <div className={classes.container}>
            <div className={classes.pricingContent}>
              {/*<div className={ classes.pricingImage }>
                <img src={ pricingBanner } alt="pricingBanner" />
              </div>*/}
              <Typography variant="h2" component="h2">Financial literacy, career readiness<br />and tools to make smart money moves</Typography>
              <Typography variant="h3" component="h3">Prepare your students for life.</Typography>
              <div className={classes.studentsLifeGrid}>
                <Grid container spacing={4} className={classes.studentsLifeGridContainer}>
                  <Grid item xs={12} md={3}>
                    <div className={classes.studentsBox}>
                      <p><span><b>79% of graduates</b></span> <span>wish they knew</span> <span>how to <b>make a</b></span> <span><b>budget</b></span></p>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <div className={classes.studentsBox}>
                      <div className={classes.studentsBoxFlex}>
                        <span>
                          <img src={quotesWhite} alt="" />
                        </span>
                        <p><i>To be successful, most kids ... need to know how to open a bank account, how much they need to save each month to reach their goals and, if they borrow this amount of money, <b>how much money they will need to earn</b> to pay it back."</i></p>
                      </div>
                      <font>
                        <span>– Nan Morrison, president and CEO, </span>
                        <span>Council for Economic Education </span>
                      </font>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <div className={classes.studentsBox}>
                      <p><span><b>50% of graduates</b></span> <span>pursue a career</span> <span><b>unrelated to their major.</b></span></p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={classes.powerUpImages}>
                <Grid container spacing={4} className={classes.powerUpImagesContainer}>
                  <Grid item xs={12} md={4}>
                    <img src={pwiLogo3} alt="" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <img src={pwiLogo2} alt="" />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <img src={pwiLogo1} alt="" />
                  </Grid>


                </Grid>
              </div>

            </div>
          </div>
          {/* <div className={ classes.downTriangle }>
            <div />
          </div> */}
        </div>
        <div className={classes.affordablePlanSec}>
          <div className={classes.container}>
            <div className={classes.affordablePlan}>
              <Typography variant="h4" component="h4">Inkwiry makes it easy</Typography>
              <p>It’s easy to add Inkwiry’s financial literacy program to your curriculum:</p>
              <ul className={classes.affordablePlanListNew}>
                <li>Program can be completed in 10 hours or less</li>
                <li>Digital, interactive platform can be accessed anywhere and at anytime</li>
                <li>Self-paced, outcome-focused courses offer real life learning and experience</li>
                <li>Standards aligned</li>
                <li>Work directly with Inkwiry team to develop custom solutions that fit your needs</li>
                <li>Secure admin tool provides easy onboarding, tracking and reporting.</li>
              </ul>
            </div>
            <div className={ classes.peaceRow }>
              <div className={classes.downloadBroucher}>
                <a href={inkwiryForEducation} download>
                  <figure>
                    <img src={downloadBrochure} alt="" />
                  </figure>
                  <p><a href={inkwiryForEducation} download>Download <br />Brochure</a></p>
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={ classes.affordablePlanSec }>
          <div className={ classes.container }>
            
          </div>
          <div className={ classes.downTriangle }>
            <div />
          </div>  
        </div> */}
     
        <div className={ classes.yourChoice }>
          <div className={ classes.container }>
            <Typography variant="h2" component="h2">Give your students the financial skills and knowledge <span className={classes.textBlock}>they’ll use for a lifetime.</span></Typography>
            <p>Provide Inkwiry to your students and their parents. Personal Finance 101 builds a foundation of financial skills and knowledge in a gamified experience, My Career Sketch jumpstarts higher education and career planning and Plan With Inkwiry offers powerful tools to chart a path to financial independence. In addition, for no extra cost, we provide administrators with a secure, custom admin tool to streamline management of the entire program.</p>
          </div>
        </div>
        <div className={classes.pricingTabs}>
          <div className={classes.container}>
            <div className={classNames(classes.planPrices, classes.planPricesBox)}>
              <div className={classes.pricingBlock}>
                {/* <div className={ classes.pricingTitle }>
                  Plan With Inkwiry
                </div> */}
                <div className={classes.pricingBody}>
                  <p className={classes.planPrice}>$15</p>
                  <p className={classes.planText}>
                    per student per year
                  </p>
                  <Button onClick={() => buyNow('monthly')}>Start Now</Button>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.triangleImg}>
            <img src={triangleImage} alt="triangleImage" />
          </div>
          <div className={classes.priceTabs}>
            <div className={classes.container}>
              <div className={classes.pricingTableOverflow}>
                <Grid container spacing={4} className={classes.powerUpImagesContainer}>
                  <Grid item xs={12} md={6} className={classes.powerUpImagesLeft}>
                    <div className={classes.tableAdmin}>
                      <table>
                        <thead>
                          <th>As an administrator, you'll appreciate:</th>
                        </thead>
                        <tbody>
                          <tr className={classes.blueRowNew}>
                            <td>Clear, measurable ROI:</td>
                          </tr>
                          <tr>
                            <td>Instantly irnplement a fully digital financial literacy and career readiness platform </td>
                          </tr>
                          <tr>
                            <td>Evidenced based performance tracking with pre and post benchmarking </td>
                          </tr>
                          <tr>
                            <td>Personal finance activities rooted in real life experience enhanced with gamification </td>
                          </tr>
                          <tr>
                            <td>Career awareness, discovery, exploration and selection personalized to each student</td>
                          </tr>
                          <tr>
                            <td>Attract new students with the ultimate higher education planning experience </td>
                          </tr>
                          <tr>
                            <td>Self-paced, guided course structure that simplifies learning and produces greater outcomes</td>
                          </tr>
                          <tr className={classes.blueRowNew}>
                            <td>Efficient admin tools:</td>
                          </tr>
                          <tr>
                            <td>Onboard, manage, communicate with students in real time from branded custom dashboard</td>
                          </tr>
                          <tr>
                            <td>Monitor student progress including activity completion status, keeping students on track</td>
                          </tr>
                          <tr>
                            <td>Measure performance with pre and post benchmarking</td>
                          </tr>
                          <tr>
                            <td>Review custom reports on activity in real time</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} className={classes.powerUpImagesRight}>
                    <div className={classes.tableAdmin}>
                      <table>
                        <thead>
                          <th>Every student has access to:</th>
                        </thead>
                        <tbody>
                          <tr className={classes.blueRowNew}>
                            <td>Online Personal Finance Courses</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Personal Finance PowerUp 101</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>My Career Sketch</td>
                          </tr>
                          <tr className={classes.blueRowNew}>
                            <td>Plan With Inkwiry</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Inkwiry Financial Configurator + Simulator Engine</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Career Sketching</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}> Career Sketch Stories</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Advanced analytics, visualizations, reporting</td>
                          </tr>
                          <tr className={classes.blueRowNew}>
                            <td>Plus:</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Finance Explained</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Walk-thrus to guide users</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Sharing and collaboration tools</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>Tutorials: 2.5 hours and counting</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>300 quick links to resources</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>200 financial terms defined</td>
                          </tr>
                          <tr>
                            <td className={classes.leftPadding}>A growing library of financial calculators</td>
                          </tr>
                          <tr>
                            <td>Immediate access to new features as they roll out</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>

                </Grid>
              </div>
            </div>

          </div>
        </div>
        <div className={classes.studentSee}>
          <div className={classes.container}>
            <Grid container spacing={2} className={classes.studentSeeGrid}>
              <Grid item xs={12} md={2}>
                <img src={NineByTen} alt="" />
              </Grid>
              <Grid item xs={12} md={10} className={classes.pl30}>
                <h3>Students see the value in Inkwiry</h3>
                <p>We're seeing consistent <span className={classes.textStrong}>9/10 ratings and enthusiastic reviews</span> from high school and college students. Of 2,500 student reviews:</p>
                <ul>
                  <li>9/10 rated Inkwiry <span className={classes.textStrong}>easy to use and engaging</span></li>
                  <li>9/10 found Inkwiry <span className={classes.textStrong}>improved their financial skills and knowledge</span></li>
                  <li>9/10 said <span className={classes.textStrong}>other students would benefit</span> from taking Personal Finance 101.</li>
                </ul>
                <p><span className={classes.textStrong}>Deliver the value of Inkwiry to <span className={classes.textItalic}>your</span> students - <Link to="/" className={classes.textAnchor}>start now!</Link></span></p>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.personalFinanceSec}>
          <div className={classes.container}>
            <div className={classes.personalFinance}>
              <h3>Personal Finance 101 “is fantastic!”</h3>
              <div className={classes.questionBoxRow}>
                <div className={classes.questionBoxFlex}>
                  <div className={classes.lightBlueBox}>
                    <span><img src={quoteTwo} alt="" /> </span>
                    <div>
                      <p>The sections were brief, yet full of information. It made it easy to follow and I was never confused about the content. I would not change anything about this. I enjoyed every part of it. It is fantastic!”</p>
                      <font>
                        <span>– Honors student, Villanova School </span>
                        <span>of Business, Villanova, PA</span>
                      </font>
                    </div>
                  </div>
                  <div className={classes.darkBlueBox}>

                    <span><img src={quoteTwo} alt="" /> </span>
                    <div>
                      <p>The students get excited about the ‘competition’ ... Lots of chatter on income taxes! Classes end up in discussions as the students are really interested in a subject.”</p>
                      <font>
                        <span>– Karen, Associate Director of CollegeGuidance, Holy Ghost Preparatory </span>
                        <span>School, Bensalem, PA</span>
                      </font>
                    </div>
                  </div>

                </div>
                <div className={classes.questionBoxFlex}>
                  <div className={classes.darkBlueBox}>
                    <span><img src={quoteTwo} alt="" /> </span>
                    <div>
                      <p>It taught me how to properly save money and to avoid things like impulse spending. It also taught me the difference between PayPal and Venmo, and other important things when it comes to banks.”</p>
                      <font>
                        <span>– Student, Devon Preparatory</span>
                        <span>School, Devon, PA</span>
                      </font>
                    </div>
                  </div>
                  <div className={classes.lightBlueBox}>
                    <span><img src={quoteTwo} alt="" /> </span>
                    <div>
                      <p>Personal Finance 101 is really beneficial. Quite frankly I found it more helpful than the Bloomberg terminal. It was super easy to use, easy to understand, and it kept me engaged."</p>
                      <font>
                        <span>– Tanner, Villanova University</span>
                        <span>Student, Villanova, PA</span>
                      </font>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.planPrices}>
            <div className={classNames(classes.planPrices, classes.planPricesBox)}>
              <div className={classes.pricingBlock}>
                <div className={classes.pricingTitle}>
                  School Package
                </div>
                <div className={classes.pricingBody}>
                  <p className={classes.planPrice}>$15</p>
                  <p className={classes.planText}>
                    <span>per student per year</span>
                  </p>
                  <Button onClick={() => buyNow('monthly')}>Start Now</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.pricingFaq}>
          <Typography variant="h2" component="h2">Frequently Asked Questions</Typography>
          <div className={classes.container}>
            <div className={classes.faqContent}>
              <div className={classes.faqBlock}>
                <Accordion expanded={expanded === 'panel1'} onChange={handleAccordianChange('panel1')}>
                  <div className={classes.faqTitle}>
                    <AccordionSummary
                      expandIcon={expanded === 'panel1' ? <i style={{ color: '#f89b22', border: '1px solid #f89b22' }} className="las la-minus" /> : <i style={{ color: '#0069aa', border: '1px solid #0069aa' }} className="las la-plus" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      disableRipple
                      onClick={() => { handleScrollToPoint(frequentAskedQuestions[0].scroll); }}
                    >
                      <Typography variant="h4" component="h4" className={classes.faqQuestion}>Are there any additional costs to the program?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={classes.faqDescription} ref={(section) => { frequentAskedQuestions[0].scroll = section; }}>
                    <AccordionDetails>
                      <p className={classes.faqDesc}>
                        No. The $15 per student per year price includes 365 days of unlimited access to Inkwiry as well as the admin tool. There are no hidden fees to the program.

                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={classes.faqBlock}>
                <Accordion expanded={expanded === 'panel2'} onChange={handleAccordianChange('panel2')}>
                  <div className={classes.faqTitle}>
                    <AccordionSummary
                      expandIcon={expanded === 'panel2' ? <i style={{ color: '#f89b22', border: '1px solid #f89b22' }} className="las la-minus" /> : <i style={{ color: '#0069aa', border: '1px solid #0069aa' }} className="las la-plus" />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={() => { handleScrollToPoint(frequentAskedQuestions[1].scroll); }}
                    >
                      <Typography variant="h4" component="h4" className={classes.faqQuestion}>How long does it take to onboard students and launch the program?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={classes.faqDescription} ref={(section) => { frequentAskedQuestions[1].scroll = section; }}>
                    <AccordionDetails>
                      <p className={classes.faqDesc}>
                        As fast as five minutes. The admin tool makes it easy to onboard your students and implement the program. After you upload your students to the admin tool, Inkwiry automatically sends each student a unique activation link. Once a student clicks on the link, the account goes live. The process is fast, safe and secure.

                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={classes.faqBlock}>
                <Accordion expanded={expanded === 'panel3'} onChange={handleAccordianChange('panel3')}>
                  <div className={classes.faqTitle}>
                    <AccordionSummary
                      expandIcon={expanded === 'panel3' ? <i style={{ color: '#f89b22', border: '1px solid #f89b22' }} className="las la-minus" /> : <i style={{ color: '#0069aa', border: '1px solid #0069aa' }} className="las la-plus" />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={() => { handleScrollToPoint(frequentAskedQuestions[2].scroll); }}
                    >
                      <Typography variant="h4" component="h4" className={classes.faqQuestion}>How does Inkwiry secure student information?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={classes.faqDescription} ref={(section) => { frequentAskedQuestions[2].scroll = section; }}>
                    <AccordionDetails>
                      <p className={classes.faqDesc}>
                        Inkwiry does not store any of your students’ personally identifiable information. First name, last name and email address are the only requirements for an account. We take safeguarding student information very seriously. We do not sell your students' information to third parties. We run all onboarding and management of the students through a secure admin tool protected by two factor authentication at all times. Please visit our security page at inkwiry.com/security to learn more about the best practices Inkwiry has put in place to protect your data.

                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={classes.faqBlock}>
                <Accordion expanded={expanded === 'panel4'} onChange={handleAccordianChange('panel4')}>
                  <div className={classes.faqTitle}>
                    <AccordionSummary
                      expandIcon={expanded === 'panel4' ? <i style={{ color: '#f89b22', border: '1px solid #f89b22' }} className="las la-minus" /> : <i style={{ color: '#0069aa', border: '1px solid #0069aa' }} className="las la-plus" />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={() => { handleScrollToPoint(frequentAskedQuestions[3].scroll); }}
                    >
                      <Typography variant="h4" component="h4" className={classes.faqQuestion}>Does Inkwiry provide anything for the parents?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={classes.faqDescription} ref={(section) => { frequentAskedQuestions[3].scroll = section; }}>
                    <AccordionDetails>
                      <p className={classes.faqDesc}>
                        Of course. Parents are an integral part of the planning process for students. Parents have complete access to Inkwiry – all the content, courses and financial life planning tools. Also, to kick off the program, our CEO James DeLuca will give a personal demonstration of Inkwiry to your parents. All parents also receive our Higher Education toolkit.

                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={classes.faqBlock}>
                <Accordion expanded={expanded === 'panel5'} onChange={handleAccordianChange('panel5')}>
                  <div className={classes.faqTitle}>
                    <AccordionSummary
                      expandIcon={expanded === 'panel5' ? <i style={{ color: '#f89b22', border: '1px solid #f89b22' }} className="las la-minus" /> : <i style={{ color: '#0069aa', border: '1px solid #0069aa' }} className="las la-plus" />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={() => { handleScrollToPoint(frequentAskedQuestions[4].scroll); }}
                    >
                      <Typography variant="h4" component="h4" className={classes.faqQuestion}>Would Inkwiry work in a blended learning environment?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={classes.faqDescription} ref={(section) => { frequentAskedQuestions[4].scroll = section; }}>
                    <AccordionDetails>
                      <p className={classes.faqDesc}>
                        Yes. Inkwiry's solution is totally customizable. We work directly with you to develop a curriculum that suits your needs, including combining online educational materials and opportunities for interaction online with traditional classroom methods. Providing a teacher with Inkwiry's digital platform plus additional learning content makes outcomes even stronger.

                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Pricing);
