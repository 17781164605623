import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const WalkThruDots = (props) => {
  const {
    classes, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrus, walkThruNo
  } = props;

  return (
    <ul className={classes.checkmarksScroll}>
      {walkThruNo === 1 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 2 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 3 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 4 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 5 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 6 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 7 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 8 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 9 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 10 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 11 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 12 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 13 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 14 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
      {walkThruNo === 15 ?
        [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((data) => (
          <li className={classNames(data + 1 === activeWalkThruSlide && classes.currentTab, completedWalkThruSlides[data] === true && classes.activeList)} aria-hidden="true" onClick={() => { handleWalkThrus(data); }} />
        ))
        :
        ''
      }
    </ul>
  );
};

WalkThruDots.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWalkThrus: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  activeWalkThruSlide: PropTypes.string.isRequired,
  walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruDots);

