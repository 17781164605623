import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const Correction = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Correction</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/correction.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Correction
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/correction-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The prices of stocks, bonds, indexes, and financial
                          markets do not always go up. No one can pinpoint the
                          exact movement of securities. But, over time, assets
                          tend to increase in value.{' '}
                          <a
                            href="https://www.cnbc.com/2017/06/18/the-sp-500-has-already-met-its-average-return-for-a-full-year.html#:~:text=Menu-,The%20S%26P%20500%20has%20already%20met%20its%20average%20return%20for,expect%20it%20to%20stay%20here&text=The%20average%20annualized%20total%20return,percent%2C%20according%20to%20LPL%20Financial."
                            target="_blank"
                            rel="noopener noreferrer">
                            According to CNBC
                          </a>
                          , the{' '}
                          <Link
                            to="/finance-explained/sandp-500"
                            target="_blank">
                            S&P 500
                          </Link>{' '}
                          has returned an average of 9.8% per year over the last
                          90 years.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          When a stock, bond, index, commodity, or any other
                          security declines in price by 10% or more, that is
                          called a correction. The decline is measured from the
                          security&apos;s most recent highest price. For
                          example, if the price of ABC stock declined from $100
                          to $85, or 15%, that stock would be considered in
                          correction territory.
                        </p>
                        <p>
                          Corrections are normal in investing and usually last a
                          couple months. While damaging in the short-term and
                          potentially scary for investors, corrections are
                          healthy. Lower prices are more attractive in terms of
                          buying opportunities.
                        </p>
                        <p>
                          But buyer beware as corrections can sometimes lead to{' '}
                          <Link
                            to="/finance-explained/bull-vs-bear-market"
                            target="_blank">
                            bear markets
                          </Link>
                          , which is a decline greater than 20%. Bear markets
                          typically last longer than corrections and are more
                          severe.
                        </p>
                        <p>
                          Again, prices of assets don&apos;t always go up. The
                          term correction was developed because, as the name
                          suggests, the price corrects (drops) and then recovers
                          shortly thereafter.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Correction.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Correction);
