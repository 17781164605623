import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import Picture1 from '../../../assets/img/pwi-he/Picture1.png';
import Picture2 from '../../../assets/img/pwi-he/Picture2.png';
import Picture3 from '../../../assets/img/pwi-he/Picture3.png';
import Picture4 from '../../../assets/img/pwi-he/Picture4.png';

const ChooseYourPath = ( props ) => {
  const {
    classes, inputData, updateData, handleSlide
  } = props;

  const [educationType, setEducationType] = React.useState(inputData.type_of_higher_education !== undefined ? inputData.type_of_higher_education : 0);

  useEffect(()=>{
    if(inputData.type_of_higher_education !== undefined && inputData.type_of_higher_education !== null && inputData.type_of_higher_education > 0 && inputData.type_of_higher_education !== educationType) {
      setEducationType(inputData.type_of_higher_education);
    }
  })
  const handleEducationType = ( type ) => {
    setEducationType(type);
    updateData('type_of_higher_education',type);
  };

  const handleEducationTypeDouble = (type) => {
    handleSlide(2,'next')
  }

  return (
    <div className={ classes.contentBlock }>
      <p>
        Planning a third move in education? You must be on a path to acquire great skills and knowledge through school. When planning higher education moves, make sure that the higher education you are pursuing will best prepare you for your career.
      </p>
      <div className={ `${classes.fourIconsBlock} ${classes.selectCollegeBox}` }>
        <ul>
          <li aria-hidden="true" className={ educationType === 2 && classes.activeEducationType } onClick={ () => handleEducationType( 2 ) } onDoubleClick={ () => handleEducationTypeDouble( 'college' ) }>
            <figure>
              <img src={ Picture1 } alt="" />
            </figure>
            <span>College</span>
          </li>
          <li aria-hidden="true" className={ educationType === 1 && classes.activeEducationType } onClick={ () => handleEducationType( 1 ) } onDoubleClick={ () => handleEducationTypeDouble( 'career' ) }>
            <figure>
              <img src={ Picture2 } alt="" />
            </figure>
            <span>Career School</span>
          </li>
          <li aria-hidden="true" className={ educationType === 4 && classes.activeEducationType } onClick={ () => handleEducationType( 4 ) } onDoubleClick={ () => handleEducationTypeDouble( 'military' ) }>
            <figure>
              <img src={ Picture3 } alt="" />
            </figure>
            <span>Military School</span>
          </li>
          <li aria-hidden="true" className={ educationType === 3 && classes.activeEducationType } onClick={ () => handleEducationType( 3 ) } onDoubleClick={ () => handleEducationTypeDouble( 'graduate' ) }>
            <figure>
              <img src={ Picture4 } alt="" />
            </figure>
            <span>Graduate School</span>
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module15"/>
    </div>
  );
};

ChooseYourPath.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleSlide: PropTypes.func.isRequired,
};

export default withStyles( styles )( ChooseYourPath );
