import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import clearIcon from '../../../assets/img/pwi-home/clear-timeline.png';
import * as commonFunctions from '../../../utilities/commonFunctions';

const ComparativeMarketAnalysis = ( props ) => {
  const { classes, inputData, updateData } = props;

  const [cmaFullData, setCmaFullData] = React.useState( [] );

  const cmaCalcalculations = ( data ) => {
    const singleCmaData = data;
    if ( singleCmaData.cma_purchase_price > 0 && singleCmaData.cma_total_feet > 0 ) {
      singleCmaData.cma_per_feet = singleCmaData.cma_purchase_price / singleCmaData.cma_total_feet;
    } else {
      singleCmaData.cma_per_feet = 0;
    }

    singleCmaData.cma_monthly_p_i = commonFunctions.PMT( ( 4 / 100 ) / 12, 360, -( ( singleCmaData.cma_purchase_price / 100 ) * 80 ), 0, 0 );
    singleCmaData.cma_monthly_homeowner_insurance = ( singleCmaData.cma_purchase_price / 1000 ) * ( 3.5 / 12 );

    singleCmaData.cma_total_expenses = singleCmaData.cma_monthly_p_i + singleCmaData.cma_monthly_property_taxes + singleCmaData.cma_monthly_homeowner_insurance + singleCmaData.cma_hoa + singleCmaData.cma_utilities;
    return singleCmaData;
  };

  const initializingCmaData = () => {
    const cmaData = [];
    for ( let i = 0; i < 3; i += 1 ) {
      cmaData[i] = {};
      if ( i === 0 ) {
        cmaData[i].cma_purchase_price = inputData.affordability_home_value;
        cmaData[i].cma_name_of_home = 'Buckingham Palace';
        cmaData[i].cma_location = 'Buckingham Twp, PA';
        cmaData[i].cma_rating = 'A';
        cmaData[i].cma_system_rating = 'A+';
        cmaData[i].cma_total_feet = 2800;
        cmaData[i].cma_per_feet = '';
        cmaData[i].cma_monthly_p_i = '';
        cmaData[i].cma_monthly_property_taxes = 335;
        cmaData[i].cma_monthly_homeowner_insurance = 0;
        cmaData[i].cma_hoa = 0;
        cmaData[i].cma_utilities = 400;
        cmaData[i].cma_total_expenses = '';
        cmaData[i].cma_home_type = 'Existing';
        cmaData[i].cma_bedrooms = '4.0';
        cmaData[i].cma_bathrooms = '2.0';
        cmaData[i].cma_kitchen_rating = 'B';
        cmaData[i].cma_basement = 'Yes';
        cmaData[i].cma_finished_basement = 'Yes';
        cmaData[i].cma_garage = 'Yes';
        cmaData[i].cma_garage_spots = 1;
        cmaData[i].cma_central_air = 'Yes';
      } else {
        cmaData[i].cma_purchase_price = 0;
        cmaData[i].cma_name_of_home = '';
        cmaData[i].cma_location = '';
        cmaData[i].cma_rating = '';
        cmaData[i].cma_system_rating = '';
        cmaData[i].cma_total_feet = 0;
        cmaData[i].cma_per_feet = '';
        cmaData[i].cma_monthly_p_i = '';
        cmaData[i].cma_monthly_property_taxes = 0;
        cmaData[i].cma_monthly_homeowner_insurance = 0;
        cmaData[i].cma_hoa = 0;
        cmaData[i].cma_utilities = 0;
        cmaData[i].cma_total_expenses = '';
        cmaData[i].cma_home_type = '';
        cmaData[i].cma_bedrooms = '';
        cmaData[i].cma_bathrooms = '';
        cmaData[i].cma_kitchen_rating = '';
        cmaData[i].cma_basement = '';
        cmaData[i].cma_finished_basement = '';
        cmaData[i].cma_garage = '';
        cmaData[i].cma_garage_spots = '';
        cmaData[i].cma_central_air = '';
      }
      cmaData[i] = cmaCalcalculations( cmaData[i] );
    }
    setCmaFullData( cmaData );
    updateData( 'cmaData', cmaData );
  };

  useEffect( () => {
    if ( inputData.cmaData === undefined || (inputData.cmaData !== undefined && inputData.cmaData.length === 0) ) {
      initializingCmaData();
    } else {
      setCmaFullData( inputData.cmaData );
    }
    // eslint-disable-next-line
  }, [] );

  const updateInputData = ( e, index, field, type ) => {
    const cmaData = [...cmaFullData];
    if ( type === 'number' ) {
      cmaData[index][field] = e.floatValue !== undefined ? e.floatValue : 0;
    } else {
      cmaData[index][field] = e.target.value;
    }
    cmaData[index] = cmaCalcalculations( cmaData[index] );
    setCmaFullData( cmaData );
    updateData( 'cmaData', cmaData );
  };

  const resetInputs = ( index ) => {
    const cmaData = [...cmaFullData];
    cmaData[index].cma_purchase_price = 0;
    cmaData[index].cma_name_of_home = '';
    cmaData[index].cma_location = '';
    cmaData[index].cma_rating = '';
    cmaData[index].cma_system_rating = '';
    cmaData[index].cma_total_feet = 0;
    cmaData[index].cma_per_feet = '';
    cmaData[index].cma_monthly_p_i = '';
    cmaData[index].cma_monthly_property_taxes = 0;
    cmaData[index].cma_monthly_homeowner_insurance = 0;
    cmaData[index].cma_hoa = 0;
    cmaData[index].cma_utilities = 0;
    cmaData[index].cma_total_expenses = '';
    cmaData[index].cma_home_type = '';
    cmaData[index].cma_bedrooms = '';
    cmaData[index].cma_bathrooms = '';
    cmaData[index].cma_kitchen_rating = '';
    cmaData[index].cma_basement = '';
    cmaData[index].cma_finished_basement = '';
    cmaData[index].cma_garage = '';
    cmaData[index].cma_garage_spots = '';
    cmaData[index].cma_central_air = '';
    cmaData[index] = cmaCalcalculations( cmaData[index] );
    setCmaFullData( cmaData );
    updateData( 'cmaData', cmaData );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>When buying a home, a comparative market analysis (CMA) is a great tool that helps you determine the value of a home and compare properties of interest. By detailing properties and looking at those details side-by-side, you can see if a home could be overpriced or a great buy.</p>
      <p>Use the interactive table below and compare similar homes that you like. Enter your details and compare homes to see which home looks the most attractive. The two most important fields are shown in blue below.</p>
      <ul className={ classes.tickList }>
        <li>
          <b>Price / Square Foot:</b>
          {' '}
          allows you to compare the value of differently priced homes. The higher the price per square foot, the more expensive the home.
        </li>
        <li>
          <b>Total Monthly Living Expenses:</b>
          {' '}
          total monthly expenses to own the home.
        </li>
      </ul>

      <div className={ classes.cmaCalculator }>
        <table>
          <tbody>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                Name of Home
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span>
                      <input type="text" className="" value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_name_of_home : '' } placeholder="Name your home!" onChange={ ( e ) => updateInputData( e, data, 'cma_name_of_home', 'input' ) } />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                Location (City, ST)
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span>
                      <input type="text" className="" value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_location : '' } placeholder="Langhorne, PA" onChange={ ( e ) => updateInputData( e, data, 'cma_location', 'input' ) } />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="When rating the location, think about: the condition of and the people in the neighborhood, safety and security of the area, access to major highways and transportation hubs, distance to nearest cities, and distance to nearest shopping and fun." />
              </td>
              <td>
                Location Rating
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_rating : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_rating', 'input' ) }>
                      <option value="" />
                      <option value="A+">A+</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="TUse niche.com and check the rating of the public school system for the home's location." />
              </td>
              <td>
                Public School System Rating
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_system_rating : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_system_rating', 'input' ) }>
                      <option value="" />
                      <option value="A+">A+</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                Purchase Price
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span className={ classes.dollarSym }>$</span>
                    <span className={ classes.inputDiv }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_purchase_price : 0 } onFocus={ ( e ) => e.target.select() } onValueChange={ ( e ) => updateInputData( e, data, 'cma_purchase_price', 'number' ) } />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                Total Square Feet
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span className={ classes.inputDiv }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_total_feet : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_total_feet', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr className={ classes.blueBgrow }>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use this metric as a value comparison. The higher the price per square foot, the more expensive the home. When using price per square foot to compare homes, try and justify the differences based on the characteristics of the home." />
              </td>
              <td>
                Price / Square Foot
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScal value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_per_feet !== '' ) ? cmaFullData[data].cma_per_feet : 0 } displayType="text" thousandSeparator prefix="$" />
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Mortgage payment calculated with the following assumptions: 30-yr term, 4.00% interest rate, and 80% of purchase price as down payment." />
              </td>
              <td>
                Est. Monthly Mortgage Payment (P&I)
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_monthly_p_i !== '' ) ? cmaFullData[data].cma_monthly_p_i : 0 } displayType="text" thousandSeparator prefix="$" />
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use zillow.com to find the estimated property taxes on the home." />
              </td>
              <td>
                Monthly Property Taxes
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span className={ classes.dollarSym }>$</span>
                    <span className={ classes.inputDiv }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_monthly_property_taxes : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_monthly_property_taxes', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Monthly homeowner's insurance calculated with the following assumptions: $3.50 for every $1,000 of purchase price divided by 12." />
              </td>
              <td>
                Est. Monthly Homeowner&apos;s Insurance
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_monthly_homeowner_insurance !== '' ) ? cmaFullData[data].cma_monthly_homeowner_insurance : 0 } displayType="text" thousandSeparator prefix="$" />
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use zillow.com to find the estimated association fees on the home." />
              </td>
              <td>
                Monthly Association Fees (HOA)
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span className={ classes.dollarSym }>$</span>
                    <span className={ classes.inputDiv }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_hoa : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_hoa', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use zillow.com to find the estimated utilities on the home." />
              </td>
              <td>
                Monthly Utilities
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span className={ classes.dollarSym }>$</span>
                    <span className={ classes.inputDiv }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_utilities : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_utilities', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr className={ classes.blueBgrow }>
              <td>
                &nbsp;
              </td>
              <td>
                Est. Total Monthly Expenses
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <span>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_total_expenses !== '' ) ? cmaFullData[data].cma_total_expenses : 0 } displayType="text" thousandSeparator prefix="$" />
                    </span>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Are you buying or building a brand new home? Or, buying an existing home? Existing homes typically require more maintenance than new homes and should be factored into the buying equation." />
              </td>
              <td>
                New or Existing Home
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_home_type : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_home_type', 'input' ) }>
                      <option value="" />
                      <option value="New">New</option>
                      <option value="Existing">Existing</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                # of Bedrooms
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_bedrooms : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_bedrooms', 'input' ) }>
                      <option value="" />
                      <option value="1.0">1</option>
                      <option value="2.0">2</option>
                      <option value="3.0">3</option>
                      <option value="4.0">4</option>
                      <option value="5.0+">5+</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                # of Bathrooms
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_bathrooms : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_bathrooms', 'input' ) }>
                      <option value="" />
                      <option value="1.0">1.0</option>
                      <option value="1.5">1.5</option>
                      <option value="2.0">2.0</option>
                      <option value="2.5">2.5</option>
                      <option value="3.0">3.0</option>
                      <option value="3.5">3.5</option>
                      <option value="4.0">4.0</option>
                      <option value="4.5">4.5</option>
                      <option value="5.0+">5.0+</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Is the kitchen old or new? Good cabinet space? Is there an island countertop? Walk-in pantry? Old or new appliances? Large or multiple sinks? The kitchen will be the busiest place in the home and is an important factor in home buying." />
              </td>
              <td>
                Kitchen Rating
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_kitchen_rating : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_kitchen_rating', 'input' ) }>
                      <option value="" />
                      <option value="A+">A+</option>
                      <option value="A">A</option>
                      <option value="B">B</option>
                      <option value="C">C</option>
                      <option value="D">D</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                Basement (Y/N)
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_basement : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_basement', 'input' ) }>
                      <option value="" />
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Is there livable space in the basement?" />
              </td>
              <td>
                Finished Basement (Y/N)
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_finished_basement : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_finished_basement', 'input' ) }>
                      <option value="" />
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="A garage is an enclosed space for housing vehicles. This does not include a covered area that is not fully enclosed." />
              </td>
              <td>
                Garage (Y/N)
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_garage : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_garage', 'input' ) }>
                      <option value="" />
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                &nbsp;
              </td>
              <td>
                # of Garage Spots
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_garage_spots : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_garage_spots', 'input' ) }>
                      <option value="" />
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4+">4+</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>
                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Centrail air cools and heats the entire house versus having air conditioning units or heating units in individual rooms." />
              </td>
              <td>
                Central Air (Y/N)
              </td>
              {
                [0, 1, 2].map( ( data ) => (
                  <td>
                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_central_air : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_central_air', 'input' ) }>
                      <option value="" />
                      <option value="Yes" selected>Yes</option>
                      <option value="No">No</option>
                    </select>
                  </td>
                ) )
              }
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td className={ classes.clearIcon }>
                <span className={ classes.resetIcon } data-for="definationTitle" data-tip="Clear Inputs" role="button" aria-hidden="true" onClick={ () => resetInputs( 0 ) }>
                  <img src={ clearIcon } alt="" />
                </span>
              </td>
              <td className={ classes.clearIcon }>
                <span className={ classes.resetIcon } data-for="definationTitle" data-tip="Clear Inputs" role="button" aria-hidden="true" onClick={ () => resetInputs( 1 ) }>
                  <img src={ clearIcon } alt="" />
                </span>
              </td>
              <td className={ classes.clearIcon }>
                <span className={ classes.resetIcon } data-for="definationTitle" data-tip="Clear Inputs" role="button" aria-hidden="true" onClick={ () => resetInputs( 2 ) }>
                  <img src={ clearIcon } alt="" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ReactTooltip id="tooltip" place="top" effect="solid" className={ classNames( classes.tooltipInfo, classes.infoTooltip ) } />
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

ComparativeMarketAnalysis.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( ComparativeMarketAnalysis );
