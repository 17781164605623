import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const FederalIncomeTaxes = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Federal Income Taxes</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/federal-income-taxes.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      Federal Income Taxes
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/federal-inc-taxes-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Federal income taxes are calculated by using
                          progressive tax rates, which means that as your income
                          increases, you are taxed at higher rates. There are
                          seven tax brackets ranging from 10% to 37%. It&apos;s
                          important to note that portions of your income are
                          taxed at different rates -{' '}
                          <strong>
                            your entire income is not taxed at one rate!
                          </strong>{' '}
                          Federal income taxes are easiest to understand with an
                          example. Let&apos;s take a look at two examples.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          <b>Example 1:</b>
                          <br />
                          John Smith is a single man who earns $50,000 and has
                          no children or other people who rely on him for money.
                          His federal income taxes are calculated below.
                        </p>
                        <div className={classes.galleryImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/income_taxes1.png"
                            alt=""
                          />
                        </div>
                        <p>
                          <b>Example 2:</b>
                          <br />
                          Jane Doe is a married woman who, including her
                          spouse&apos;s income, earns $120,000 and has no
                          children or other people who rely on her for money.
                          Jane and her spouse&apos;s federal income taxes are
                          calculated below.
                        </p>
                        <div className={classes.galleryImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/income_taxes2.png"
                            alt=""
                          />
                        </div>
                        <p>
                          The above examples show just how federal income taxes
                          are calculated in simplified scenarios. Remember, the
                          standard deduction is a portion of your income that is
                          not subject to federal income taxes. The next table
                          allows you to compare side-by-side to see the
                          different tax brackets for single and married and the
                          different standard deductions as well.
                        </p>
                        <div className={classes.galleryImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/income_taxes3.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FederalIncomeTaxes.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FederalIncomeTaxes);
