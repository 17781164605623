import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';

import styles from './styles';

const CreditScoreMyths = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>5 Credit Score Myths</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score-myths.png"
                      alt=" "
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>5 Credit Score Myths
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score-myths-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Credit scores shouldn&apos;t be a mystery. Check out 5
                          common credit score myths debunked!
                        </p>
                        <p className={classes.smallText}>
                          <b>
                            Myth #1 – Checking your credit score hurts your
                            score
                          </b>
                          <br />
                          You can check your credit score as much as you want
                          using free services like{' '}
                          <a
                            href="https://www.creditkarma.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Credit Karma
                          </a>{' '}
                          or{' '}
                          <a
                            href="https://www.annualcreditreport.com/foreignLocation.action"
                            target="_blank"
                            rel="noopener noreferrer">
                            annualcreditreport.com
                          </a>
                          . If a lender or creditor, such as a mortgage lender,
                          checks your score, that can slightly lower your credit
                          score as it signals you are applying for more debt.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          <b>
                            Myth #2 – You need a high income to have a great
                            credit score
                          </b>
                          <br />
                          Repaying your debts on time and using credit
                          responsibly is what goes into your credit score. Your
                          income does not impact your credit score. Check out
                          the{' '}
                          <Link
                            to="/finance-explained/credit-score-calculated"
                            target="_blank">
                            five variables
                          </Link>{' '}
                          that are used to calculate your credit score.
                        </p>
                        <p>
                          <b>
                            Myth #3 – Cancelling and closing out credit cards
                            will improve your score
                          </b>
                          <br />
                          Closing a credit card changes your{' '}
                          <Link
                            to="/finance-explained/credit-score-calculated"
                            target="_blank">
                            utilization rate
                          </Link>{' '}
                          and can potentially hurt your credit score. For
                          example, let&apos;s say you have two credit cards and
                          those cards each have a $2,500 limit. You have $1,000
                          outstanding on the first card and $0 on the second
                          card, which means you are using $1,000 of your $5,000
                          total available credit or 20%. You cancel and close
                          the second card.
                        </p>
                        <p>
                          Now you are using $1,000 of your $2,500 total
                          available credit or 40%. Your utilization rate has
                          doubled and will most likely have a negative impact on
                          your credit score! Always try and keep your
                          utilization rate at 30% or below.
                        </p>
                        <p>
                          <b>
                            Myth #4 – There is only one way to calculate a
                            credit score
                          </b>
                          <br />
                          There isn&apos;t just one way to calculate a credit
                          score. The three credit bureaus – TransUnion, Experian
                          and Equifax – all use different scoring models that
                          result in different scores. Having different credit
                          scores is normal.
                        </p>
                        <p>
                          <b>Myth #5 – All debt is equal</b>
                          <br />
                          Not all debt is equal. There is good debt and bad
                          debt. If you have $150,000 in credit card debt, that
                          is not good debt. But, if you have a $150,000 mortgage
                          on your home that you are repaying on time, that would
                          be good debt.
                        </p>
                        <p>
                          Now that you know some insider tips about your credit,
                          you have the tools to maintain and improve your credit
                          score!
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CreditScoreMyths.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreditScoreMyths);
