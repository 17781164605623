import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import { converyIntoThousandsOneDecimal, numFormatterHour, numFormatter } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import CareerOccupationList from './occupation-list';

const followersMarks = [
  {
    value: 0,
    label: '$0k',
  },
  {
    value: 50000,
    label: '$50k',
  },
  {
    value: 100000,
    label: '$100k',
  },
  {
    value: 150000,
    label: '$150k',
  },
  {
    value: 200000,
    label: '$200k',
  },
  {
    value: 250000,
    label: '$250k',
  },
];

const hourMarks = [
  {
    value: 0,
    label: '$0',
  },
  {
    value: 25,
    label: '$25',
  },
  {
    value: 50,
    label: '$50',
  },
  {
    value: 75,
    label: '$75',
  },
  {
    value: 100,
    label: '$100',
  },
  {
    value: 125,
    label: '$125',
  },
];

const hourFirstYearMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 500,
    label: '500',
  },
  {
    value: 1000,
    label: '1,000',
  },
  {
    value: 1500,
    label: '1,500',
  },
  {
    value: 2000,
    label: '2,000',
  },
  {
    value: 2500,
    label: '2,500',
  },
  {
    value: 3000,
    label: '3,000',
  },
];


const SpouseCareerAdvancementSecond = ( props ) => {
  const { classes, handleDefinationPopup, inputData, updateData } = props;

  const [salaryType, setSalaryType] = React.useState('salary')
  const [annualIncome, setAnnualIncome] = React.useState(0);
  const [bonusTipsCommision, setBonusTipsCommision] = React.useState(0);
  const [hourlyWage, setHourlyWage] = React.useState(18);
  const [firstYearsHours, setFirstYearsHours] = React.useState(2000);
  const [isFocus, setIsFocus] = React.useState('');

  useEffect(()=>{
    let inputDetails = {...inputData};
    if(inputDetails.second_salary_type !== undefined) {
      setSalaryType(inputDetails.second_salary_type);
    } else {
      inputDetails.second_salary_type = 'salary';
    }
    if(inputDetails.second_salary_type === 'salary'){
      if(inputDetails.second_spouse_income !== undefined) {
        setAnnualIncome(inputDetails.second_spouse_income);
      }
    } else if(inputDetails.second_salary_type === 'hourly') {
      if(inputData.second_hourly_wage !== undefined) {
        setHourlyWage(inputData.second_hourly_wage);
      }
      if(inputData.second_first_years_hours !== undefined) {
        setFirstYearsHours(inputData.second_first_years_hours);
      }
    }
    if(inputDetails.second_spouse_bonus_or_tips_or_commission !== undefined) {
      setBonusTipsCommision(inputDetails.second_spouse_bonus_or_tips_or_commission);
    }

    updateData('total_obj',inputDetails);
  },[])

  const handleSalaryType = (e) => {
    let inputDetails = {...inputData};
    inputDetails.second_salary_type = e.target.value;
    if(inputDetails.second_salary_type === 'hourly') {
      inputDetails['second_spouse_income'] = hourlyWage*firstYearsHours;
      if(inputData.second_hourly_wage === undefined) {
        inputDetails['second_hourly_wage'] = hourlyWage;
      }
      if(inputData.second_first_years_hours === undefined) {
        inputDetails['second_first_years_hours'] = firstYearsHours;
      }
    } else {
      inputDetails['second_spouse_income'] = annualIncome;
    } 
    setSalaryType(inputDetails.second_salary_type);
    updateData('total_obj',inputDetails);
  }

  const updateValue = ( e, value, field, type ) => {

    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    let inputDetails = {...inputData};
    inputDetails[field] = newvalue;

    switch ( field ) {
      case 'second_spouse_income':
        setAnnualIncome(newvalue);
        break;
      case 'second_spouse_bonus_or_tips_or_commission':
        setBonusTipsCommision(newvalue);
        break;
      case 'second_hourly_wage':
        setHourlyWage(newvalue);
        break;
      case 'second_first_years_hours':
        setFirstYearsHours(newvalue);
        break;
      default:
        break;
    }
    if(salaryType === 'hourly') {
      inputDetails['second_spouse_income'] = inputDetails.second_hourly_wage*inputDetails.second_first_years_hours;
    }
    updateData('total_obj',inputDetails);
  };

  let totalIncome = 0;
  if(salaryType === 'hourly')  {
    totalIncome =  hourlyWage*firstYearsHours;
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Discover what your spouse’s occupation and other careers might pay by using the data in the table below. The table shows the average earnings by occupation in both annual salary and hourly wage.</p>
      <p>For this career move, how much do you expect your spouse to make in the first year in total hourly wages or salary? Will this career move pay an annual, quarterly, or monthly bonus? Maybe you’ll be earning tips as you work? What about commissions on sales?</p>
      <p>Knowing your spouse&apos;s potential earnings is crucial to developing a solid plan for starting a family. Use the data below to find your spouse’s career and discover what that career move might earn.</p>
      <p className={ classes.TwoGapsNew }>
        <i>
          Occupation:
          {inputData.second_occupation !== undefined ? inputData.second_occupation : ''}
        </i>
      </p>
      <div className={ classes.salaryBlock }>
        <span className={ classes.salaryHourly }>Salary / Hourly</span>
        <div>
          <label htmlFor="salary">
            <input type="checkbox" id="salary" name="salary" value="salary" checked={ salaryType === 'salary' } onChange={ handleSalaryType } />
            Salary
          </label>
        </div>
        <div>
          <label htmlFor="hourly">
            <input type="checkbox" id="hourly" name="hourly" value="hourly" checked={ salaryType === 'hourly' } onChange={ handleSalaryType } />
            Hourly
          </label>
        </div>
      </div>
      <div className={ classes.innnerFlowGroupMain }>
        {salaryType === 'salary' && (
          <div>
            <h3 className={ classes.annuvalRightSpace }>Annual</h3>

            <div className={ classes.innnerFlowGroup }>
              <div className={ classes.graphLeft }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew}` }>
                  <div>
                    <p>Annual Income</p>
                    <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                      <PwiSlider
                        value={ annualIncome }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ converyIntoThousandsOneDecimal }
                        min={ 0 }
                        max={ 250000 }
                        step={ 500 }
                        marks={ followersMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ ( e, value ) => updateValue( e, value, 'second_spouse_income', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ annualIncome } onValueChange={ ( e ) => updateValue( e, '', 'second_spouse_income', 'number' ) }  onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {salaryType === 'hourly' && (
          <React.Fragment>
          <div className={ classes.innnerFlowGroup }>
            <div className={ classes.graphLeft }>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew}` }>
                <div>
                  <p>Hourly Wage</p>
                  <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                    <PwiSlider
                      value={ hourlyWage }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={ numFormatterHour }
                      min={ 0 }
                      max={ 125 }
                      step={ 0.05 }
                      marks={ hourMarks }
                      ValueLabelComponent={ ValueLabelComponent }
                      onChange={ ( e, value ) => updateValue( e, value, 'second_hourly_wage', 'slider' ) }
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className={ classes.graphRight }>
              <div>

                <div className={ classes.inputRightBlock }>
                  $
                  <NumberFormat
                    allowNegative={ false }
                    customInput={ TextField }
                    value={ hourlyWage }
                    decimalScale={isFocus!=='second_hourly_wage'?2:5}
                    fixedDecimalScale={isFocus!=='second_hourly_wage'}
                    onValueChange={ ( e ) => updateValue( e, '', 'second_hourly_wage', 'number' ) }
                    onFocus={ ( e ) => { e.target.select(); setIsFocus('second_hourly_wage') }}
                    onBlur={emptyIsFocus} />
                </div>
              </div>
            </div>
          </div>

          <div className={ classes.innnerFlowGroup }>
            <div className={ classes.graphLeft }>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew}` }>
                <div>
                  <p>Hours Worked in first year</p>
                  <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                    <PwiSlider
                      value={ firstYearsHours }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      valueLabelFormat={ numFormatter }
                      min={ 0 }
                      max={ 3000 }
                      step={ 1 }
                      marks={ hourFirstYearMarks }
                      ValueLabelComponent={ ValueLabelComponent }
                      onChange={ ( e, value ) => updateValue( e, value, 'second_first_years_hours', 'slider' ) }
                    />
                  </div>

                </div>
              </div>
            </div>
            <div className={ classes.graphRight }>
              <div>
                <div className={ classes.inputRightBlock }>
                  $
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ firstYearsHours } onValueChange={ ( e ) => updateValue( e, '', 'second_first_years_hours', 'number' ) }  onFocus={ ( e ) => e.target.select() } />
                </div>
              </div>
            </div>
          </div>
          <div className={ `${classes.totalIncomeBox} ${classes.marginTopGap}` }>
            <span>Annual Income</span>
            <div className={ classes.inputRightBlock }>
              <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ totalIncome } prefix="$" />
            </div>
          </div>
          </React.Fragment>
        )}

        <div className={ classes.innnerFlowGroup }>
          <div className={ classes.graphLeft }>
            <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew}` }>
              <div>
                <p><span className={ classes.underLineSpace } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Bonus / Tips / Commission' ) }>Bonus / Tips / Commission</span></p>
                <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
                  <PwiSlider
                    value={ bonusTipsCommision }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    valueLabelFormat={ converyIntoThousandsOneDecimal }
                    min={ 0 }
                    max={ 250000 }
                    step={ 500 }
                    marks={ followersMarks }
                    ValueLabelComponent={ ValueLabelComponent }
                    onChange={ ( e, value ) => updateValue( e, value, 'second_spouse_bonus_or_tips_or_commission', 'slider' ) }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.graphRight }>
            <div>
              <div className={ classes.inputRightBlock }>
                $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ bonusTipsCommision } onValueChange={ ( e ) => updateValue( e, '', 'second_spouse_bonus_or_tips_or_commission', 'number' ) }  onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
        </div>
        <div className={ `${classes.totalIncomeBox} ${classes.marginTopGap}` }>
          <span>Total Pre-Tax Base Income</span>
          <div className={ classes.inputRightBlock }>
            <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } displayType="text" thousandSeparator value={ (inputData.second_spouse_income !== undefined ? inputData.second_spouse_income : 0) + (inputData.second_spouse_bonus_or_tips_or_commission !== undefined ? inputData.second_spouse_bonus_or_tips_or_commission : 0) } prefix="$" />
          </div>
        </div>
        <CareerOccupationList />
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

SpouseCareerAdvancementSecond.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};
export default withStyles( styles )( SpouseCareerAdvancementSecond );
