import testimonialBg from '../../assets/img/spaces/testimonails_bg_new.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bannerCaption: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    '& h3': {
      fontSize: '54px',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
      },
    },
  },
  bannerCaptionTitle: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: '999',
    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1',
    },
  },
  spacesImage: {
    maxHeight: '557px',
    overflow: 'hidden',
    position: 'relative',
    padding: '0',
    margin: '0',
    top: 'auto',
    boxSizing: 'border-box',
    '& img': {
      width: '100%',
      height: 'auto',
    },
    '&:before': {
      background: 'rgba(51, 51, 51, 0.5) none repeat scroll 0 0',
      content: '""',
      height: '100%',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: '999',
    },
    [theme.breakpoints.down( 'sm' )]: {
      maxHeight: '420px',
    },
  },
  sevenDimensions: {
    textAlign: 'center',
    color: theme.palette.common.blue,
    '& h2': {
      fontSize: '48px',
      lineHeight: '48px',
      fontFamily: '"MuseoSans-100"',
      margin: '0 0 38px 0',
      fontWeight: '300',
      [theme.breakpoints.down( 'md' )]: {
        margin: '0 0 15px 0',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '36px',
        lineHeight: '38px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '24px',
        marginBottom: '0px',
      },
    },
    '& h3': {
      fontSize: '36px',
      lineHeight: '58px',
      fontFamily: '"MuseoSans-100"',
      color: '#0069aa',
      fontWeight: '300',
      marginTop: '10px',
      marginBottom: '10px',
      '& span': {
        padding: '0px 20px',
        [theme.breakpoints.down( 'sm' )]: {
          padding: '0px 10px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          padding: '0px 5px',
        },
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '28px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '13px',
      },
    },
  },
  spacesContent: {
    padding: '50px 15px 0 15px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 15px 0 15px',
    },
  },
  innovateBlock: {
    boxSizing: 'border-box',
    marginTop: '45px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '25px',
    },
    '& h4': {
      fontSize: '36px',
      lineHeight: '36px',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-300"',
      marginBottom: '16px',
      fontWeight: '500',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '33px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '22px',
        lineHeight: '30px',
        marginBottom: '10px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '25px',
        marginBottom: '10px',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '34px',
      fontFamily: '"MuseoSans-100"',
      color: '#333333',
      marginBottom: '25px',
      fontWeight: '300',
      minHeight: '120px',
      marginRight: '5px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '15px',
        lineHeight: '24px',
        margin: '0 0 10px 0',
        minHeight: 'initial',
      },
      [theme.breakpoints.down( 'xs' )]: {
        minHeight: 'initial',
        fontSize: '16px',
      },
    },
  },
  innovateRow: {
    [theme.breakpoints.down( 'sm' )]: {
      margin: '20px 0px',
    },
  },
  buttonRow: {
    textAlign: 'center',
    '& a': {
      backgroundColor: '#fa9c22',
      borderColor: '#b9bdc3',
      color: theme.palette.common.white,
      minWidth: '322px',
      marginRight: '0',
      marginTop: '75px',
      fontSize: '28px',
      lineHeight: '30px',
      marginBottom: '60px',
      boxShadow: 'none',
      textTransform: 'none',
      padding: '12px 22px',
      borderRadius: '10px',
      display: 'inline-block',
      fontWeight: '400',
      textAlign: 'center',
      verticalAlign: 'middle',
      cursor: 'pointer',
      textDecoration: 'none',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: '#e4860c',
        borderColor: '#b9bdc3',
      },
      [theme.breakpoints.down( 'sm' )]: {
        margin: '30px auto 40px auto',
      },
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '100%',
        fontSize: '20px',
      },
    },
  },
  testimonialBlock: {
    backgroundSize: 'cover',
    padding: '118px 0 72px 0',
    position: 'relative',
    backgroundImage: `url(${testimonialBg})`,
    backgroundPosition: 'center top',
    marginTop: '20px',
    textAlign: 'center',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '70px 0 70px 0',
      backgroundPosition: 'left -540px bottom',
    },
    '& h4': {
      fontWeight: '300',
      padding: '0',
      color: theme.palette.common.white,
      fontSize: '35px',
      lineHeight: '50px',
      position: 'relative',
      zIndex: '99',
      marginTop: '10px',
      marginBottom: '10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '30px',
      },
      '& i': {
        display: 'block',
        fontStyle: 'normal',
        fontFamily: '"MuseoSans-100"',
        '& span': {
          display: 'inline-block',
          color: '#f89b22',
          fontWeight: '300',
          fontSize: '29px',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '20px',
          },
        },
      },
    },
  },
} );

export default styles;
