import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BuyingSecondCarTableBottom from './buying-second-car-table-bottom';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import styles from '../../screens/pwi-car/styles';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../calculations/modules-services';

const SecondCarDownpayment = ( props ) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, activeStep, handleUpdatedObject,
  } = props;
  const [basePrice, setBasePrice] = React.useState( handleBuyingCarObject.second_down_payment_in_percentage === undefined ? 20 : handleBuyingCarObject.second_down_payment_in_percentage );
  const [destination, setDestination] = React.useState( 100 - basePrice );
  const [downPaymentValue, setDownPayInputValue] = React.useState( ( moduleServices.module12Data.moreAdjustedPurchasePrice * ( basePrice / 100 ) ) );
  const [carLoan, setCarLoan] = React.useState( moduleServices.module12Data.moreAdjustedPurchasePrice * ( ( 100 - basePrice ) / 100 ) );

  useEffect( () => {

    let netIncome = moduleServices.moreAdjustedPurchasePrice();
    if (handleBuyingCarObject.second_down_payment_in_percentage !== undefined) {
      setBasePrice(handleBuyingCarObject.second_down_payment_in_percentage);
      setDestination(100 - handleBuyingCarObject.second_down_payment_in_percentage);
      if (netIncome > 0) {
        let downPaymentAmount = (netIncome / 100) * handleBuyingCarObject.second_down_payment_in_percentage;
        setDownPayInputValue(downPaymentAmount);
        setCarLoan(netIncome - downPaymentAmount);
      } else {
        setBasePrice(0);
        setCarLoan(0);
        setDestination(100);
        setCarLoan(0);
      }
    } else {
      if (netIncome > 0) {
        if (netIncome > 0) {
          let downPaymentAmount = (netIncome / 100) * 20;
          setDownPayInputValue(downPaymentAmount);
          setCarLoan(netIncome - downPaymentAmount);
        } else {
          setBasePrice(0);
          setCarLoan(0);
          setDestination(100);
          setCarLoan(0);
        }
      } else {
        setBasePrice(20);
        setDownPayInputValue(0);
        setDestination(80);
        setCarLoan(0);
      }
    }

    // eslint-disable-next-line
  }, [])

  const handleBasePriceSlider = ( event, newValue ) => {
    setDownPayInputValue( moduleServices.module12Data.moreAdjustedPurchasePrice * ( newValue / 100 ) );
    setBasePrice( newValue );
    setDestination( 100 - newValue );
    setCarLoan( moduleServices.module12Data.moreAdjustedPurchasePrice * ( ( 100 - newValue ) / 100 ) );
    handleBuyingCarObject.second_down_payment_in_percentage = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleBasePriceInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setDownPayInputValue( updatedValue );
    setBasePrice( updatedValue > 0 ? ( updatedValue * 100 ) / moduleServices.module12Data.moreAdjustedPurchasePrice : 0 );
    handleBuyingCarObject.second_down_payment_in_percentage = updatedValue > 0 ? ( updatedValue * 100 ) / moduleServices.module12Data.moreAdjustedPurchasePrice : 0;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleDestinationSlider = ( event, newValue ) => {
    setCarLoan( moduleServices.module12Data.moreAdjustedPurchasePrice * ( newValue / 100 ) );
    setDestination( newValue );
    setBasePrice( 100 - newValue );
    setDownPayInputValue( moduleServices.module12Data.moreAdjustedPurchasePrice * ( ( 100 - newValue ) / 100 ) );
    handleBuyingCarObject.second_car_loan = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  const handleDestInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setCarLoan( updatedValue );
    setDestination( ( updatedValue * 100 ) / moduleServices.module12Data.moreAdjustedPurchasePrice );
    handleBuyingCarObject.second_car_loan = ( updatedValue * 100 ) / moduleServices.module12Data.moreAdjustedPurchasePrice;
    handleUpdatedObject( handleBuyingCarObject );
  };

  return (
    <div className={ classes.contentBlock }>
      <div>
        <p>
          The typical
          {' '}
          {' '}
          <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Down Payment' ) }>down payment</span>
          {' '}
          on a car ranges from 10 to 20%. The higher the
          {' '}
          {' '}
          <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Down Payment' ) }>down payment</span>
          , the lower your loan and the lower your monthly payment.
        </p>
        <p>Adjust your down payment and check the summary tables to make sure you have enough cash and investments to make the purchase.</p>
        <CareerSketchTimeline />
        <div className={ classes.priceSliders }>
          <div className={ classes.priceText }>
            <p>&nbsp;</p>
          </div>
          <div className={ classes.slideGraphNew }>
            <p><b>Purchase Year</b></p>
          </div>
          <div className={ classes.priceValue }>
            <span className={ classes.inputValue }><b>{handleBuyingCarObject.second_purchase_year}</b></span>
          </div>
        </div>
        <div className={ classes.priceSliders }>
          <div className={ classes.priceText }>
            <p>&nbsp;</p>
          </div>
          <div className={ classes.slideGraphNew }>
            <p><b>Net Purchase Price</b></p>
          </div>
          <div className={ classes.priceValue }>
            <span className={ classes.inputValue }>
              <b>
                {moduleServices.module12Data.moreAdjustedPurchasePrice !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module12Data.moreAdjustedPurchasePrice } prefix="$" />
                ) : ( '$0' )}
              </b>
            </span>
          </div>
        </div>
        <div className={ classes.priceSlidersScroll }>
          <div className={ classes.priceSlidersScrollDiv }>
            <div className={ classes.AnnualIncomeGrowth }>
              <div>
                <p><span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Down Payment' ) }>Down Payment</span></p>
                <div className={ classes.annuvalIncomeGrowthGraph }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ sliderValues.DownPayment() }
                    min={ 0 }
                    max={ 100 }
                    step={ 1 }
                    value={ basePrice }
                    valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( basePrice ).toFixed( 2 ) )}` }
                    onChange={ handleBasePriceSlider }
                  />
                </div>
                <div className={ classes.annuvalIncomeGrowthInput }>
                  $
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ downPaymentValue === '' ? 0 : parseInt( downPaymentValue, 10 ) } onValueChange={ handleBasePriceInput } onFocus={ ( e ) => { e.target.select(); } } />
                </div>
              </div>
            </div>
            <div className={ classes.AnnualIncomeGrowth }>
          <div>
            <p><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Car Loan' ) }>Car Loan</span></p>
            <div className={ classes.annuvalIncomeGrowthGraph }>
              <PwiSlider
                ValueLabelComponent={ ValueLabelComponent }
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={ sliderValues.DownPayment() }
                min={ 0 }
                max={ 100 }
                step={ 1 }
                value={ destination }
                valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( destination ).toFixed( 2 ) )}` }
                onChange={ handleDestinationSlider }
              />
            </div>
            <div className={ classes.annuvalIncomeGrowthInput }>
              $
              <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ carLoan === '' ? 0 : parseInt( carLoan, 10 ) } onValueChange={ handleDestInput } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
          </div>
        </div>
          </div>
        </div>
        <div className={ classes.borderDivider } />
      </div>
      <BuyingSecondCarTableBottom handleBuyingCarObject={ handleBuyingCarObject } activeStep={ activeStep } />
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

SecondCarDownpayment.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  activeStep: PropTypes.string.isRequired,
};

export default withStyles( styles )( SecondCarDownpayment );
