import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField, Button, Dialog, DialogContent,
} from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from './styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import { formatDollar } from '../../../utilities/chartCommonFunctions';
import logo from '../../../assets/img/logo.png';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );

HCExporting( Highcharts );

const StandardLoanPayment = ( props ) => {
  const { classes } = props;

  const loanRepaymentDefaultValues = {
    loanAmount: 50000,
    termOfTheLoan: 10,
    interestRate: 5,
  };

  const [loanRepaymentValues, setLoanRepaymentValues] = useState( loanRepaymentDefaultValues );
  const [showTablePopup, setShowTablePopup] = useState( false );
  const [highchartGraphView, setHighchartGraphView] = useState( {} );

  useEffect( () => {
    // Start Sheet Calculations
    let refinancedStudentLoanData = {};
    let summationData = {};
    let loanAmount = 0;
    if ( typeof loanRepaymentValues.loanAmount !== 'undefined' && loanRepaymentValues.loanAmount !== '' ) {
      loanAmount = parseInt( loanRepaymentValues.loanAmount, 10 );
    }

    // Beginning Balance
    const beginningBalance = loanAmount;

    // Terms in Years
    let termsOfYear = 0;
    if ( beginningBalance > 0 ) {
      termsOfYear = parseFloat( loanRepaymentValues.termOfTheLoan );
    }

    // Annual Interest Rate
    let annualInterestRate = 0;
    if ( typeof loanRepaymentValues.interestRate !== 'undefined' && loanRepaymentValues.interestRate !== '' ) {
      annualInterestRate = parseFloat( loanRepaymentValues.interestRate );
    }

    // this.annualInterestRate = 4.50;

    // Original Payment
    let originalPayment = 0;
    if ( beginningBalance > 0
      && termsOfYear > 0
      && annualInterestRate > 0 ) {
      originalPayment = commonFunctions.PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( beginningBalance ), 0, 0 );
    }
    loanRepaymentValues.originalPaymentValue = originalPayment;
    loanRepaymentValues.originalPaymentAnnualValue = originalPayment * 12;
    // Interest Accrued
    // - not used anywhere so commentented --- mahananda
    // let interestAccrued = 0;
    // let paymentPlanIdentifier = 0;
    // if(paymentPlanIdentifier === 3 &&
    //     beginningBalance > 0 &&
    //     loanAmount > 0) {
    //     interestAccrued = beginningBalance - loanAmount;
    // }

    // Total Interest Paid
    let totalInterestPaid = 0;

    // Total Paid
    let totalPaid = 0;

    // Total Points Pais
    // let totalPointsPaid = 0;

    // Tabular Data
    let graduationYear = 0;
    /* moduleServices.fedSubPaymentYearBegins();
    if(typeof module18Data.fedSubPaymentYearBegins !== 'undefined') {
        graduationYear = module18Data.fedSubPaymentYearBegins;
    } */
    graduationYear = 2021;
    if ( graduationYear > 0 ) {
      let year = parseInt( graduationYear, 10 );
      const yearLimit = year + termsOfYear;

      // Student Loan Data
      refinancedStudentLoanData = {};

      let Balance = 0;
      let Payment = 0;
      let Interest = 0;
      let Principal = 0;
      let Equity = 0;
      let TotalInterest = 0;
      let TotalPrincipal = 0;
      let TotalPayment = 0;

      const totalInterestPaidArray = [];
      const totalPaidArray = [];
      // eslint-disable-next-line
      let period = 1;
      let k = 0;
      let finalSubmonth = 1;

      // For Loop from graduation year to +10 years
      for ( year; year <= yearLimit; year += 1 ) {
        refinancedStudentLoanData[year] = {};

        refinancedStudentLoanData[year].LaggingInterest = 0;
        refinancedStudentLoanData[year].LaggingPrincipal = 0;
        refinancedStudentLoanData[year]['Months Total Balance'] = 0;
        // For Loop for 12 months
        let condition = 12;
        if ( k === 0 ) {
          condition = 12;
        }
        k += 1;

        for ( let month = 1; month <= condition; month += 1 ) {
          refinancedStudentLoanData[year][month] = {};

          if ( year === graduationYear && month === 1 ) {
            refinancedStudentLoanData[year][month].YearIdentifier = year;

            Balance = beginningBalance;
            refinancedStudentLoanData[year][month].Balance = Balance;
            Payment = originalPayment;
            refinancedStudentLoanData[year][month].Payment = originalPayment;
            refinancedStudentLoanData[year][month].Month = k * month;

            if ( Balance > 0 && annualInterestRate > 0 ) {
              Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              Interest = 0;
            }
            refinancedStudentLoanData[year][month].Interest = Interest;

            if ( Balance > 0 ) {
              Principal = Payment - Interest;
            } else {
              Principal = 0;
            }
            refinancedStudentLoanData[year][month].Principal = Principal;

            if ( Balance > 0 ) {
              TotalPrincipal = Principal;
            } else {
              TotalPrincipal = 0;
            }
            refinancedStudentLoanData[year][month].TotalPrincipal = TotalPrincipal;

            if ( Balance > 0 ) {
              Equity = Principal;
            } else {
              Equity = 0;
            }
            refinancedStudentLoanData[year][month].Equity = Equity;

            if ( Balance > 0 ) {
              TotalInterest = Interest;
            } else {
              TotalInterest = 0;
            }
            refinancedStudentLoanData[year][month].TotalInterest = TotalInterest;

            if ( Balance > 0 ) {
              TotalPayment = Equity + TotalInterest;
            } else {
              TotalPayment = 0;
            }
            refinancedStudentLoanData[year][month].TotalPayment = TotalPayment;
          } else { // Year Identifier
            refinancedStudentLoanData[year][month].YearIdentifier = year;

            // Balance
            let tempBalance;
            if ( Balance === 0 ) {
              tempBalance = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempBalance = ( Balance - Principal );
            } else {
              tempBalance = 0;
            }
            refinancedStudentLoanData[year][month].Balance = tempBalance;

            if ( tempBalance > 0 ) {
              finalSubmonth += 1;
              refinancedStudentLoanData['Final Month'] = finalSubmonth;
              refinancedStudentLoanData['Final Year'] = year;
            } else {
              finalSubmonth = 0;
            }

            refinancedStudentLoanData[year][month].Month = finalSubmonth;

            // Payment
            refinancedStudentLoanData[year][month].Payment = Payment;

            // Interest
            let tempInterest;
            if ( Balance === 0 ) {
              tempInterest = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              tempInterest = 0;
            }
            refinancedStudentLoanData[year][month].Interest = tempInterest;

            // Principal
            let tempPrincipal;

            if ( Balance === 0 ) {
              tempPrincipal = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempPrincipal = Payment - tempInterest;
            } else {
              tempPrincipal = 0;
            }
            refinancedStudentLoanData[year][month].Principal = tempPrincipal;

            // Total Interest
            let tempTotalPrinciple;
            if ( Balance === 0 ) {
              tempTotalPrinciple = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempTotalPrinciple = TotalPrincipal + tempPrincipal;
            } else {
              tempTotalPrinciple = 0;
            }
            refinancedStudentLoanData[year][month].TotalPrincipal = tempTotalPrinciple;

            // Equity
            let tempEquity;
            if ( Balance === 0 ) {
              tempEquity = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempEquity = Equity + tempPrincipal;
            } else {
              tempEquity = 0;
            }
            refinancedStudentLoanData[year][month].Equity = tempEquity;

            // Total Interest
            let tempTotalInterest;
            if ( Balance === 0 ) {
              tempTotalInterest = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempTotalInterest = TotalInterest + tempInterest;
            } else {
              tempTotalInterest = 0;
            }
            refinancedStudentLoanData[year][month].TotalInterest = tempTotalInterest;

            // Total Payments
            let tempTotalPayment;
            if ( Balance === 0 ) {
              tempTotalPayment = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempTotalPayment = tempEquity + tempTotalInterest;
            } else {
              tempTotalPayment = 0;
            }
            refinancedStudentLoanData[year][month].TotalPayment = tempTotalPayment;

            // Assign Values
            Balance = tempBalance;
            Principal = tempPrincipal;
            Equity = tempEquity;
            TotalInterest = tempTotalInterest;
            TotalPrincipal = tempTotalPrinciple;
          }

          // Total Interest Paid Array
          totalInterestPaidArray.push( refinancedStudentLoanData[year][month].TotalInterest );

          // Total Principle Paid Array
          // totalInterestPaidArray.push( refinancedStudentLoanData[year][month].TotalPrincipal );

          // Total Paid Array
          totalPaidArray.push( refinancedStudentLoanData[year][month].TotalPayment );

          refinancedStudentLoanData[year].LaggingInterest = parseFloat( refinancedStudentLoanData[year].LaggingInterest ) + parseFloat( refinancedStudentLoanData[year][month].Interest );
          refinancedStudentLoanData[year].LaggingPrincipal = parseFloat( refinancedStudentLoanData[year].LaggingPrincipal ) + parseFloat( refinancedStudentLoanData[year][month].Principal );
          refinancedStudentLoanData[year]['Months Total Balance'] = parseFloat( refinancedStudentLoanData[year]['Months Total Balance'] ) + parseFloat( refinancedStudentLoanData[year][month].Balance );

          // Total Interest Paid
          if ( totalInterestPaidArray.length > 0 ) {
            totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
          }
          loanRepaymentValues.totalInterestPaidValue = totalInterestPaid;

          // Total Paid
          if ( totalPaidArray.length > 0 ) {
            totalPaid = Math.max.apply( null, totalPaidArray );
          }
          loanRepaymentValues.totalPaidValue = totalPaid;

          period += 1;
        }
      }
    }

    // Summation Data
    let summationYear = 2021;
    const firstModuleYear = 2021;
    const summationYearLimit = summationYear + termsOfYear;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;

    const endingBalanceGraph = [];
    const paymentGraph = [];
    const interestGraph = [];
    const principalPaybackGraph = [];
    const yearsListGraph = [];

    // For Loop from Start year to +14 years
    for ( summationYear; summationYear < summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // Beginning Balance
      if ( summationYear === firstModuleYear && loanAmount > 0 ) {
        summationBeginningBalance = refinancedStudentLoanData[summationYear][1].Balance;
      } else if ( summationYear > firstModuleYear ) {
        if ( loanAmount > 0 && typeof summationData[summationYear - 1] !== 'undefined' && typeof summationData[summationYear - 1].EndingBalance !== 'undefined' && summationData[summationYear - 1].EndingBalance > 0 ) {
          summationBeginningBalance = summationData[summationYear - 1].EndingBalance;
        } else {
          summationBeginningBalance = 0;
        }
      } else {
        summationBeginningBalance = 0;
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof refinancedStudentLoanData[summationYear] !== 'undefined' && typeof refinancedStudentLoanData[summationYear].LaggingInterest !== 'undefined' ) {
          LaggingVal1 = refinancedStudentLoanData[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof refinancedStudentLoanData[summationYear] !== 'undefined' && typeof refinancedStudentLoanData[summationYear].LaggingPrincipal !== 'undefined' ) {
          summationLaggingPrincipal = refinancedStudentLoanData[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      // Total Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }
      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = summationData[summationYear].BeginningBalance - summationData[summationYear].LaggingPrincipal;
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;

      // Graph Data
      if ( summationEndingBalance > 0 || summationLaggingInterest > 0 || summationLaggingPrincipal > 0 || ( summationLaggingInterest + summationLaggingPrincipal ) > 0 ) {
        endingBalanceGraph.push( summationEndingBalance );
        interestGraph.push( summationLaggingInterest );
        principalPaybackGraph.push( summationLaggingPrincipal );
        paymentGraph.push( summationLaggingInterest + summationLaggingPrincipal );
        yearsListGraph.push( summationYear );
      }
    }
    // Display the Graph
    // let windowHeight = 0;
    const endingBalanceGraphData = endingBalanceGraph;
    const paymentGraphData = paymentGraph;
    const principalPaybackGraphData = principalPaybackGraph;
    const interestGraphData = interestGraph;
    // windowHeight    = $(window).height();
    // if( windowHeight >= 600){
    // 	windowHeight = 580;
    // }
    // var graphMainWidth = $('.right-panel').width();
    const graphView = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: windowHeight,
        // width: graphMainWidth
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      xAxis: {
        categories: yearsListGraph,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      },
      yAxis: [{ // Primary yAxis
        min: 0,
        // tickInterval: 10000,
        labels: {
          formatter() {
            const chart = this;
            if ( chart.value < 0 ) {
              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
            }
            return formatDollar( Math.round( chart.value ) );
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: 'Income & Expenses',
          enabled: false,
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }, { // Tertiary yAxis
        /* min: 0,
        tickInterval: 10000,
        gridLineWidth: 0, */
        title: {
          text: 'Net Worth',
          enabled: false,
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        labels: {
          formatter() {
            const chart = this;
            if ( chart.value < 0 ) {
              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
            }
            return formatDollar( Math.round( chart.value ) );
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        opposite: true,
      }],
      tooltip: {
        formatter() {
          const chart = this;
          if ( chart.y < 0 ) {
            return `${chart.series.name}<br/>${chart.x}: <span style="color:red; font-weight:bold;">(${formatDollar( -Math.round( chart.y ) )})</span>`;
          }

          return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar( Math.round( chart.y ) )}</b>`;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        name: 'Ending Balance',
        yAxis: 0,
        data: endingBalanceGraphData,
        stack: 'male',
        color: '#0069AA',
      }, {
        name: 'Payment',
        yAxis: 0,
        data: paymentGraphData,
        stack: 'female',
        color: '#A6A6A6',
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 1,
        data: interestGraphData,
        stack: 'female',
        color: '#5B9BD5',
      }, {
        type: 'spline',
        name: 'Principal Payback',
        yAxis: 1,
        data: principalPaybackGraphData,
        color: '#ED7D31',
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    };
    loanRepaymentValues.refinancedStudentLoanData = refinancedStudentLoanData;
    setHighchartGraphView( graphView );

    // eslint-disable-next-line
  }, [loanRepaymentValues]);

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...loanRepaymentValues };
    if ( field === 'interestRate' || field === 'termOfTheLoan' ) {
      newValue = e.target.value;
    } else {
      newValue = e.value ? e.value : 0;
    }

    valuesUpdated[field] = newValue;
    setLoanRepaymentValues( valuesUpdated );
  };

  const handleShowTable = () => {
    setShowTablePopup( true );

    loanRepaymentValues.moduleSubName = 'Loan Repayment';
    loanRepaymentValues.modulesName = 'Loan Repayment';
    loanRepaymentValues.fedSubDataLoopData = loanRepaymentValues.refinancedStudentLoanData;

    // loanRepaymentValues.loanAmount = loanRepaymentValues.loanAmount;
    loanRepaymentValues.termInYears = loanRepaymentValues.termOfTheLoan;
    loanRepaymentValues.annualInterestRate = loanRepaymentValues.interestRate;
    loanRepaymentValues.originalPayment = loanRepaymentValues.originalPaymentValue;
    loanRepaymentValues.totalInterestPaid = loanRepaymentValues.totalInterestPaidValue;
    loanRepaymentValues.totalPaid = loanRepaymentValues.totalPaidValue;
  };

  const tableData = [];
  // eslint-disable-next-line
  loanRepaymentValues.refinancedStudentLoanData && Object.keys(loanRepaymentValues.refinancedStudentLoanData).forEach((k) => {
    const val = loanRepaymentValues.refinancedStudentLoanData[k];
    const tdData = [];

    tableData.push(
      <tr className={ classes.tableMainData }>
        <td colSpan="9">
          <table>
            <tbody>

              {
                Object.keys( val ).forEach( ( data, index ) => {
                  const displayData = val[data];
                  tdData.push(
                    <tr>
                      {index === 0 ? <td>{k}</td> : <td>&nbsp;</td>}
                      <td>{displayData.Month}</td>
                      <td>
                        <span>
                          <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ Math.round( displayData.Balance ) } displayType="text" prefix="$" />

                        </span>
                      </td>
                      <td>
                        <span>
                          <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ Math.round( displayData.Payment ) } displayType="text" prefix="$" />
                        </span>
                      </td>
                      <td>
                        <span>
                          <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ Math.round( displayData.Principal ) } displayType="text" prefix="$" />
                        </span>
                      </td>
                      <td>
                        <span>
                          <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ Math.round( displayData.Interest ) } displayType="text" prefix="$" />
                        </span>
                      </td>
                      <td>
                        <span>
                          <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ Math.round( displayData.TotalPrincipal ) } displayType="text" prefix="$" />
                        </span>
                      </td>
                      <td>
                        <span>
                          <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ Math.round( displayData.TotalInterest ) } displayType="text" prefix="$" />
                        </span>
                      </td>
                      <td>
                        <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ Math.round( displayData.TotalPayment ) } displayType="text" prefix="$" />

                      </td>
                    </tr>,
                  );
                } )
              }
              {tdData}

            </tbody>
          </table>
        </td>
      </tr>,
    );
  } );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Standard Loan Repayment</li>
            </ul>
            <div className={ classes.csBox }>
              <div className={ classes.blueBorder }>
                <div className={ classes.mortgageRow }>
                  <div className={ classes.leftPanel }>
                    <table>
                      <tbody>
                        <tr>
                          <td>Loan Amount</td>
                        </tr>
                        <tr>
                          <td className={ classes.dollarField }>
                            <span>$</span>
                            <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ loanRepaymentValues.loanAmount } onValueChange={ ( e ) => updateValue( e, 'loanAmount' ) } onFocus={ ( e ) => e.target.select() } />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Interest Rate</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.percentField ) }>
                            <TextField className={ classes.formInput } value={ loanRepaymentValues.interestRate } onChange={ ( e ) => updateValue( e, 'interestRate' ) } onFocus={ ( e ) => e.target.select() } />
                            <span className={ classes.currencySym }>%</span>
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Term of the Loan</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.percentField ) }>
                            <TextField className={ classes.formInput } value={ loanRepaymentValues.termOfTheLoan } onChange={ ( e ) => updateValue( e, 'termOfTheLoan' ) } onFocus={ ( e ) => e.target.select() } />
                            <span className={ classes.currencySym }>years</span>
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td className={ classes.blueDiv } />
                        </tr>
                        <tr>
                          <td>Monthly Payment</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ loanRepaymentValues.originalPaymentValue } displayType="text" prefix="$" />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>
                            Annual Paid
                            <span className={ classes.piText }>(Principal & Interest)</span>
                          </td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ loanRepaymentValues.originalPaymentAnnualValue } displayType="text" prefix="$" />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Total Interest Paid</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ loanRepaymentValues.totalInterestPaidValue } displayType="text" prefix="$" />
                          </td>
                        </tr>
                        <tr>
                          <td className={ classes.heightDiv } />
                        </tr>
                        <tr>
                          <td>Total Repayment Amount (Principal & Interest)</td>
                        </tr>
                        <tr>
                          <td className={ classNames( classes.dollarField, classes.noField ) }>
                            <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ loanRepaymentValues.totalPaidValue } displayType="text" prefix="$" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={ classes.rightPanel }>
                    <div className={ classes.loanHead }>
                      <div className={ classes.showButton }>
                        <span aria-hidden="true" onClick={ handleShowTable }>
                          Show Table
                        </span>
                      </div>
                      <h3>Standard Loan Repayment</h3>
                    </div>
                    <hr className={ classes.hrLine } />
                    {highchartGraphView && highchartGraphView.series && (
                      <div className={ classes.graphImage }>
                        <HighchartsReact
                          highcharts={ Highcharts }
                          options={ highchartGraphView }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Dialog
            open={ showTablePopup }
            onClose={ () => { setShowTablePopup( false ); } }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={ classes.showTable }
          >
            <DialogContent className={ classes.videoPopup }>
              <div className={ classes.modalHeader }>
                <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
                <span className={ classes.popupClose } aria-hidden="true" onClick={ () => { setShowTablePopup( false ); } }>
                  X
                </span>
              </div>
              <div className={ classes.modalBody }>
                <div className={ classes.tableResponsive }>
                  <p>{loanRepaymentValues.modulesName}</p>
                  <div className={ classes.federalLoanTable }>
                    <div className={ classes.loanRepayTable }>
                      <table className={ classes.bottomBorder }>
                        <tbody>
                          <tr className={ classes.loanRow }>
                            <td colSpan="9" className={ classes.noPadding }>{loanRepaymentValues.moduleSubName}</td>
                          </tr>
                          <tr>
                            <td colSpan="9">&nbsp;</td>
                          </tr>
                          <tr className={ classes.loanRetailTable }>
                            <td colSpan="4" className={ classes.valueOne }>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Loan Amount ($)</td>
                                    <td>
                                      <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ loanRepaymentValues.loanAmount } displayType="text" prefix="$" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Term in Years (#)</td>
                                    <td>
                                      <NumberFormat allowNegative fixedDecimalScale value={ loanRepaymentValues.termOfTheLoan } displayType="text" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Annual Interest Rate (%)</td>
                                    <td>
                                      {loanRepaymentValues.interestRate}
                                      %

                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Original Payment ($)</td>
                                    <td>
                                      <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ loanRepaymentValues.originalPayment } displayType="text" prefix="$" />

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td colSpan="1" className={ classes.middleRow } />
                            <td colSpan="4" className={ classes.intrestTable }>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Total Interest Paid</td>
                                    <td>
                                      <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ loanRepaymentValues.totalInterestPaidValue } displayType="text" prefix="$" />

                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Total Paid</td>
                                    <td>
                                      <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ loanRepaymentValues.totalPaidValue } displayType="text" prefix="$" />

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="9">&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className={ classes.fixedData }>
                        <table>
                          <thead>
                            <tr>
                              <td colSpan="9" className={ classes.fixedTable }>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Year </td>
                                      <td>Month </td>
                                      <td>Beginning Balance</td>
                                      <td>Payment</td>
                                      <td>Principal</td>
                                      <td>Interest</td>
                                      <td>Total Principal</td>
                                      <td>Total Interest</td>
                                      <td>Total Payments</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr className={ classes.borderLine }>
                              <td colSpan="9">&nbsp;</td>
                            </tr>
                          </thead>
                          <tbody className={ classes.leftBorder }>

                            {tableData}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={ classes.modalFooter }>
                <Button className={ classes.closeButton } onClick={ () => { setShowTablePopup( false ); } }>Close</Button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </Typography>
  );
};

StandardLoanPayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( StandardLoanPayment );
