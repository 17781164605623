import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Table,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();
const Loan101 = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/loan.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>What&apos;s a Loan?</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/loan-300px.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          A loan is money that you borrow from a bank, financial institution, individual, or government. In exchange for the bank giving you money today, you agree to pay the money back, plus interest and fees, in the future. Before considering taking a loan, ask yourself:
                        </p>
                        <ul className={ classes.pfList }>
                          <li>Am I buying something truly necessary?</li>
                          <li>Do I have enough room in my budget to cover the monthly payments?</li>
                          <li>Could I save for a couple of months and buy in cash instead?</li>
                          <li>Is it worth paying all the interest on the loan to make the purchase?</li>
                        </ul>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        Most people don&apos;t have enough cash on hand to pay for expensive assets such as a car, a home, or attending higher education. A loan provides the cash needed to purchase that asset.
                        <p className={ classes.noMarginBottom }>
                          For example, let&apos;s say you want to buy a house for $500,000 and have $100,000 in cash. You apply for a $400,000 loan (which is called a mortgage).
                          If you&apos;re approved for the loan, the bank will lend you $400,000 so you can purchase the home. You repay the $400,000 plus interest and fees to the bank over the next 30 years in the form of a monthly payment. How much interest will you pay over the term of that $400,000 loan, assuming a 4.50% interest rate? Review the table below and find out.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.loanTable }>
                      <Table>
                        <tbody>
                          <tr>
                            <td>Loan Amount (Principal)</td>
                            <td>$400,000.00</td>
                          </tr>
                          <tr>
                            <td>Term of the Loan</td>
                            <td>30 Years</td>
                          </tr>
                          <tr className={ classes.bottomblackBorder }>
                            <td>Interest Rate</td>
                            <td>4.50%</td>
                          </tr>
                          <tr className={ classes.dashedBorder }>
                            <td>
                              Monthly Payment
                              <br />
                              {' '}
                              (Principal & Interest)
                            </td>
                            <td>$2,026.74</td>
                          </tr>
                          <tr className={ classes.dashedBorder }>
                            <td>
                              Total Paid Annually
                              <br />
                              {' '}
                              (Principal & Interest)
                            </td>
                            <td>$24,320.89</td>
                          </tr>
                          <tr className={ classes.dashedBorder }>
                            <td>
                              Total Repayment Amount
                              <br />
                              {' '}
                              (Principal & Interest)
                            </td>
                            <td>$729,626.85</td>
                          </tr>
                          <tr className={ classes.dashedBorder }>
                            <td>Total Interest</td>
                            <td>$329,626.85</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        When considering buying an asset, you have to consider the cost of using a loan to buy that asset. In the example above, the actual cost of the home is over $800,000. Why is the cost of the home more than the $500,000 sale price? Check out the formula below:
                      </p>
                    </div>
                    <div className={ classes.grayBox }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>Actual Cost of Buying an Asset = Total Price Paid + Total Interest Paid</p>
                          <p>Actual Cost of Buying the Home = $500,000.00 + $329,626.85 = $829,626.85</p>
                        </div>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        <b>Types of Loans</b>
                        <br />
                        There are a couple common types of loans. Review the table below to see their similarities and their differences:
                      </p>
                    </div>
                    <div className={ classes.typesofLoans }>
                      <Table>
                        <thead>
                          <tr>
                            <th />
                            <th>Credit Card</th>
                            <th>Personal Loan</th>
                            <th>Student Loan</th>
                            <th>Auto Loan</th>
                            <th>Mortgage</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Purpose</td>
                            <td>Pay for day-to-day living expenses</td>
                            <td>Commonly used to repay expensive credit card debt</td>
                            <td>Pay for higher education</td>
                            <td>Fund the purchase of a car, truck, or other vehicle</td>
                            <td>Fund the purchase of a home or other real estate</td>
                          </tr>
                          <tr>
                            <td>Interest Rate (APR)</td>
                            <td>14-30%</td>
                            <td>3-25%</td>
                            <td>4-14%</td>
                            <td>4-17%</td>
                            <td>3-8%</td>
                          </tr>
                          <tr>
                            <td>Term of the Loan</td>
                            <td>None</td>
                            <td>1-5 Years Typically</td>
                            <td>10 Years</td>
                            <td>2-4 Years Typically</td>
                            <td>15-30 Years Typically</td>
                          </tr>
                          <tr>
                            <td>Repayment Details</td>
                            <td>Payment changes month-by-month based on spending</td>
                            <td>Same monthly payment each month</td>
                            <td>Same monthly payment each month</td>
                            <td>Same monthly payment each month</td>
                            <td>Same monthly payment each month</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        <p>
                          <b>What&apos;s an Interest Rate?</b>
                          <br />
                          An interest rate is the amount a lender charges you for using their money, typically expressed as an annual percentage of the principal – often referred to as Annual Percentage Rate (APR). But, an interest rate and APR are actually different. APR is the interest rate plus fees that the lender charges for providing you with the loan. If you want to learn more about the difference, click
                          {' '}
                          <a className={ classes.anchorStyle } href="https://www.thebalance.com/differences-between-apr-and-interest-rate-4687016" target="_blank" rel="budget-fy2020 noopener noreferrer">here</a>
                          .
                        </p>
                        <p>
                          Think of borrowing money just like renting an apartment. In exchange for giving you a place to live, you pay your landlord a fee, which is a monthly rent payment. When you borrow money from a bank, it&apos;s very similar to renting an apartment.
                        </p>
                        <p>
                          Instead of borrowing a place to live, you are borrowing money. In exchange for the money, you pay your bank a fee, which is called interest. To calculate your interest each month, the bank typically uses the interest rate in the formula below:
                        </p>
                        <p>
                          Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest
                        </p>
                        <p>
                          Interest rates determine how much it costs to borrow money. And, your credit score determines the interest rate you will receive. How does a lower or higher interest rate impact you when borrowing money?
                        </p>
                      </div>
                    </div>
                    <div className={ classes.repayTable }>
                      <Table>
                        <thead>
                          <tr>
                            <th>Loan Amount</th>
                            <th>Repayment Time</th>
                            <th>Interest Rate</th>
                            <th>Monthly Payment</th>
                            <th>Total Repayment Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>$20,000</td>
                            <td>10 Years</td>
                            <td>4.0%</td>
                            <td>$202.49</td>
                            <td>$24,298.83</td>
                          </tr>
                          <tr className={ classes.bluebgRow }>
                            <td>$20,000</td>
                            <td>10 Years</td>
                            <td>6.0%</td>
                            <td>$222.04</td>
                            <td>$26,644.92</td>
                          </tr>
                          <tr className={ classes.pinkbgRow }>
                            <td>$20,000</td>
                            <td>10 Years</td>
                            <td>8.0%</td>
                            <td>$242.66</td>
                            <td>$29,118.62</td>
                          </tr>
                          <tr>
                            <td>$40,000</td>
                            <td>10 Years</td>
                            <td>4.0%</td>
                            <td>$404.98</td>
                            <td>$48,597.67</td>
                          </tr>
                          <tr className={ classes.bluebgRow }>
                            <td>$40,000</td>
                            <td>10 Years</td>
                            <td>6.0%</td>
                            <td>$444.08</td>
                            <td>$53,289.84</td>
                          </tr>
                          <tr className={ classes.pinkbgRow }>
                            <td>$40,000</td>
                            <td>10 Years</td>
                            <td>8.0%</td>
                            <td>$485.31</td>
                            <td>$58,237.25</td>
                          </tr>
                          <tr>
                            <td>$60,000</td>
                            <td>10 Years</td>
                            <td>4.0%</td>
                            <td>$607.47</td>
                            <td>$72,896.50</td>
                          </tr>
                          <tr className={ classes.bluebgRow }>
                            <td>$60,000</td>
                            <td>10 Years</td>
                            <td>6.0%</td>
                            <td>$666.12</td>
                            <td>$79,934.76</td>
                          </tr>
                          <tr className={ classes.pinkbgRow }>
                            <td>$60,000</td>
                            <td>10 Years</td>
                            <td>8.0%</td>
                            <td>$727.97</td>
                            <td>$87,355.87</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level6/loan-101/exercise" startTime={ startTime } level={ 6 } exercise={ 3 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Loan101.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Loan101 );
