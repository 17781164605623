import React from 'react';
import PropTypes from 'prop-types';
import {
  Link,
} from '@material-ui/core';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import styles from './styles';
import AssetsLiabilities from '../../assets/img/pwi-fi/assets_liabilities.png';
import WorkSheet from '../../assets/img/pwi-fi/worksheet-icon.png';
import fixMenuImage from '../../assets/img/pwi-fi/mega-menu-pricing_img.png';
import Simulator from '../../assets/img/pwi-fi/simulator-engine-blue.png';
import TimeLine from '../../assets/img/pwi-fi/timeline_icon.png';
import Dollar from '../../assets/img/pwi-fi/dollar-icon.png';
import Dashboard from '../../assets/img/pwi-fi/dashboard.png';
import Clear from '../../assets/img/pwi-fi/clear-timeline.png';
import Load from '../../assets/img/pwi-fi/load.png';
import Download from '../../assets/img/pwi-fi/download.png';
import Save from '../../assets/img/pwi-fi/save.png';
import DocumentsList from '../../components/pwi/pwi-left-menu/documents-list';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const Sidebar = (props) => {
  const {
    classes, menu, debts, handleMenu, handleSideMenu, openBeforeLeavePopup, setOpenPopup, setOpenDocsPopup, clearScenario, saveScenario, sketchName, activeScenario,completedSlides 
  } = props;

  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = React.useState(false);
  // const dropdownIntialValues = {
  //   loans: false,
  //   card: false,
  //   car: false,
  //   home: false,
  // };
  // const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);

  const usePathname = (event, url, moduleName) => {
    event.preventDefault();
    openBeforeLeavePopup(url, moduleName);
  }

  // const handleToggle = (type) => {
  //   const dropValues = dropdownIntialValues;
  //   if (type === 'loans') {
  //     dropValues.loans = !dropdownvalues.loans;
  //   } else if (type === 'card') {
  //     dropValues.card = !dropdownvalues.card;
  //   } else if (type === 'car') {
  //     dropValues.car = !dropdownvalues.car;
  //   } else if (type === 'home') {
  //     dropValues.home = !dropdownvalues.home;
  //   }
  //   setDropdownValues(dropValues);
  // };

  return (
    <div className={menu ? classes.fixedMenuDisplay : classes.fixedMenu}>
      <ReactTooltip id="worksheetView" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.worksheetToolTip)} />
      <div className={classes.fixedMenuHeader}>
        <Link href="/plan-with-inkwiry/fi" onClick={(event) => { usePathname(event, '/worksheet', 'Worksheet') }}>
          <img src={WorkSheet} alt="" data-for="topTooltipTopIcon" data-tip="Switch to Worksheet view" />
        </Link>
        <i className="la la-close" aria-hidden="true" onClick={() => handleMenu(false)} />
      </div>
      <div className={classes.planWithInkwiry}>
        <div className={classes.powerUpLogo}>
          <img src={fixMenuImage} alt="" />
        </div>
        <h1>Plan With Inkwiry</h1>
      </div>
      <div className={classes.powerUpCnt}>
        <h4>
          <figure>
            <img src={AssetsLiabilities} alt="" />
          </figure>
          Existing Assets & Liabilities
            </h4>
        <ul className={classes.homeMenuList}>
          <li className={classes.activeMenu} aria-hidden="true" onClick={() => handleSideMenu(1)}>
            <span>01</span>
            <font>Getting started</font>
            <span className={classes.menuCheck}>
              {completedSlides[1] === true && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => handleSideMenu(2)}>
            <span>02</span>
            <font>Cash</font>
            <span className={classes.menuCheck}>
              {completedSlides[3] === true && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => handleSideMenu(4)}>
            <span>03</span>
            <font>Investments</font>
            <span className={classes.menuCheck}>
              {completedSlides[10] === true && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => handleSideMenu(10)}>
            <span>04</span>
            <font>Retirement Accounts</font>
            <span className={classes.menuCheck}>
              {completedSlides[12] === true && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => handleSideMenu(13)}>
            <span>05</span>
            <font>Business</font>
            <span className={classes.menuCheck}>
              {completedSlides[14] === true && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => handleSideMenu(14)}>
            <span>06</span>
            <font>Other Values</font>
            <span className={classes.menuCheck}>
              {(completedSlides[15] === true) && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => {
            handleSideMenu(15)
          }}>
            <span>07</span>
            <font>Vehicles</font>
            <span className={classes.menuCheck}>
              {(completedSlides[18] === true) && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => handleSideMenu(29)}>
            <span>08</span>
            <font>Home</font>
            <span className={classes.menuCheck}>
              {(completedSlides[29] === true) && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => debts[0].status ? handleSideMenu(41) :  handleSideMenu(49)}>
            <span>09</span>
            <font>Credit Card Debt</font>
            <span className={classes.menuCheck}>
              {(completedSlides[41] === true) && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => debts[1].status ? handleSideMenu(42) :  handleSideMenu(49)}>
            <span>10</span>
            <font>Personal Loan</font>
            <span className={classes.menuCheck}>
              {(completedSlides[42] === true) && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => debts[2].status ? handleSideMenu(43) :  handleSideMenu(49)}>
            <span>11</span>
            <font>Student Loan</font>
            <span className={classes.menuCheck}>
              {(completedSlides[43] === true) && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li aria-hidden="true" onClick={() => handleSideMenu(51)}>
            <span>12</span>
            <font>Net Worth</font>
            <span className={classes.menuCheck}>
              {(completedSlides[51] === true) && <CheckIcon fontSize="small" />}
            </span>
          </li>
          <li>
            <div>
              {activeScenario.id !== undefined && activeScenario.id !== '' && (
                <div className={classes.scenarioNameBlock}>
                  <span>Active Scenario</span>
                  <span>{activeScenario.name}</span>
                </div>
              )}
              {sketchName !== '' && sketchName !== 'Active Selection' && (
                <div className={classes.scenarioNameBlock}>
                  <span>Active Career Sketch</span>
                  <span>{sketchName}</span>
                </div>
              )}
            </div>
          </li>

        </ul>
        <div className={classes.footerMenuFixed}>
          <ul>
            <li>
              <Link component="button" onClick={() => { handleMenu(false); setOpenPopup('simulator'); }} data-for="bottomTooltip" data-tip="Simulator Panel">
                <img src={Simulator} alt="" />
              </Link>
            </li>
            <li>
              <Link href="/timeline-sketcher" onClick={(event) => { usePathname(event, '/timeline-sketcher', 'Career Sketch Timeline') }} data-for="bottomTooltip" data-tip="Go to Timeline">
                <img src={TimeLine} alt="" />
              </Link>
            </li>
            <ClickAwayListener
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
              onClickAway={() => { setSummaryDocumentsOpen(false) }}
            >
              <li className={classes.documentsMenu} >
                <Link component="button" onClick={() => { setSummaryDocumentsOpen(!summaryDocumentsOpen); }} data-for="bottomTooltip" data-tip="Summary Documents">
                  <img src={Dollar} alt="" />
                </Link>
                {summaryDocumentsOpen && (
                  <DocumentsList hideMenu={handleMenu} setOpenDocsPopup={setOpenDocsPopup} setSummaryDocumentsOpen={setSummaryDocumentsOpen} />
                )}
              </li>
            </ClickAwayListener>
            <li>
              <Link href="/dashboard" onClick={(event) => { usePathname(event, '/dashboard', 'Dashboard') }} data-for="bottomTooltip" data-tip="Go to Dashboard">
                <img src={Dashboard} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={clearScenario} data-for="bottomTooltip" data-tip="Clear Inputs">
                <img src={Clear} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={() => openBeforeLeavePopup('', 'loadScenario', 'load')} data-for="bottomTooltip" data-tip="Load">
                <img src={Load} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" data-for="bottomTooltip" data-tip="Download">
                <img src={Download} alt="" />
              </Link>
            </li>
            <li>
              <Link component="button" onClick={saveScenario} data-for="bottomTooltip" data-tip="Save">
                <img src={Save} alt="" />
              </Link>
            </li>
          </ul>
        </div>
        <ReactTooltip id="topTooltipTopIcon" place="right" type="info" effect="solid" className={ classNames( classes.tooltipInfoTopNew, classes.worksheetToolTip ) } />
        <ReactTooltip id="bottomTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.moduleToolTip)} />
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  menu: PropTypes.bool.isRequired,
  handleMenu: PropTypes.func.isRequired,
  handleSideMenu: PropTypes.func.isRequired,
  completedSlides: PropTypes.object.isRequired,
  openBeforeLeavePopup: PropTypes.func.isRequired,
  setOpenPopup: PropTypes.func.isRequired,
  setOpenDocsPopup: PropTypes.func.isRequired,
  clearScenario: PropTypes.func.isRequired,
  saveScenario: PropTypes.func.isRequired,
  sketchName: PropTypes.string.isRequired,
  activeScenario: PropTypes.object.isRequired,
  // pointerEvent: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Sidebar);
