import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import { TextField, Link } from '@material-ui/core';
// import classNames from 'classnames';
// import NumberFormat from 'react-number-format';
// import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-investments/styles';
import AnnualTable from './annual-return-table';
// import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
// import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';



const LongTermAnnualReturn = (props) => {
  const {
    classes,  getInvestmentObject,  setOpenWorkSheet
  } = props;

  return (
    <div className={classes.contentBlock}>
      <p>Investing consistently over time and letting compounding interest go to work for you is a winning strategy. Commit to your strategy and grow your wealth.</p>
      <AnnualTable setOpenWorkSheet={setOpenWorkSheet}  getInvestmentObject={getInvestmentObject} />
    </div>
  );
}

LongTermAnnualReturn.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  // handleInvestmentData: PropTypes.func.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles(styles)(LongTermAnnualReturn);
