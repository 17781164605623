import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import styles from '../../screens/pwi-investments/styles';
import * as modulesServices from '../../calculations/modules-services';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';

const annualOptions = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 12,
    label: '12%',
  },
  {
    value: 24,
    label: '24%',
  },
  {
    value: 36,
    label: '36%',
  },
  {
    value: 48,
    label: '48%',
  },
  {
    value: 60,
    label: '60%',
  },
];

const CharitableContribution = ( props ) => {
  const {
    classes, getInvestmentObject, handleInvestmentData,
  } = props;

  const d = new Date();
  let currentYear = modulesServices.module1Data.start_year;
  if(currentYear === undefined || currentYear === '' || currentYear <= 0) {
    currentYear = d.getFullYear();
  }
  const yearOptions = [];

  for ( let year = currentYear; year < ( currentYear + 15 ); year += 2 ) {
    yearOptions.push( { value: year, label: year } );
  }
  const [startYearValue, setStartYearValue] = React.useState(  );
  const [endYearValue, setEndYearValue] = React.useState(  );
  const [giftValue, setGiftValue] = React.useState( getInvestmentObject.charitable_yearly_contribution === undefined ? 0 : getInvestmentObject.charitable_yearly_contribution );
  const [isFocus, setIsFocus] = React.useState(''); 
  useEffect( () => {
    const investData = { ...getInvestmentObject };
    const d = new Date();
    let sliderStartYear = parseInt( modulesServices.module1Data.start_year, 10 );
    if ( sliderStartYear === '' || sliderStartYear <= 0) {
      sliderStartYear = d.getFullYear();
    }
    const giftStartYear = sliderStartYear;
    const giftEndYear = giftStartYear;

    if ( getInvestmentObject.charitable_start_year === undefined || getInvestmentObject.charitable_start_year === '' || getInvestmentObject.charitable_start_year <= 0 ) {
      investData.charitable_start_year = giftStartYear;
    }
    if ( getInvestmentObject.charitable_end_year === undefined || getInvestmentObject.charitable_end_year === '' || getInvestmentObject.charitable_end_year <= 0 ) {
      investData.charitable_end_year = giftEndYear;
    }
    if(investData.charitable_end_year < investData.charitable_start_year) {
      investData.charitable_end_year = investData.charitable_start_year;
    }
    setStartYearValue(investData.charitable_start_year);
    setEndYearValue(investData.charitable_end_year);
    if ( getInvestmentObject.charitable_yearly_contribution === undefined ) {
      investData.charitable_yearly_contribution = 0;
      setGiftValue( 0 );
    }
    handleInvestmentData( investData );
    // eslint-disable-next-line
    },[]);

  const updateValue = ( e, value, field, type ) => {
    const beginingBalanceObj = { ...getInvestmentObject };
    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    beginingBalanceObj[field] = newvalue;
    switch ( field ) {
      case 'charitable_start_year': {
        setStartYearValue( newvalue );
        if ( newvalue >= endYearValue ) {
          beginingBalanceObj.charitable_end_year = newvalue;
          setEndYearValue( newvalue );
        }
        break;
      }
      case 'charitable_end_year': {
        if ( parseInt( newvalue, 10 ) >= startYearValue ) {
          setEndYearValue( newvalue );
          beginingBalanceObj.charitable_end_year = newvalue;
        } else {
          beginingBalanceObj.charitable_end_year = startYearValue;
        }
        break;
      }
      case 'charitable_yearly_contribution': {
        setGiftValue( parseFloat( newvalue ) > 100 ? 100 : newvalue );
        break;
      }
      default: {
        break;
      }
    }
    handleInvestmentData( beginingBalanceObj );
  };
  
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        Not only are charitable contributions a great investment, but they also have tax benefits as well. If you want to learn more about how charitable giving could potentially lower your taxes, click
        {' '}
        {' '}
        <a href="https://www.thebalance.com/tax-deduction-for-charity-donations-3192983" target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </p>
      <div>
        {startYearValue !== undefined && (
          <div className={ classes.giftSlider }>
            <div className={ classes.lefBlock }>
              <div className={ classes.lefSlider }>
                <div className={ classes.sliderName }><p>Start Year</p></div>
                <div className={ classes.rzSlider }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ yearOptions }
                    min={ currentYear }
                    max={ currentYear + 14 }
                    step={ 1 }
                    value={ startYearValue }
                    onChange={ ( e, value ) => { updateValue( e, value, 'charitable_start_year', 'slider' ); } }
                  />
                </div>
              </div>
            </div>
            <div className={ classes.gitInputs }>
              <div className={ classes.annualInput }>
                <p>{startYearValue}</p>
              </div>
            </div>
          </div>  
        )}
        {endYearValue !== undefined && (
          <div className={ classes.giftSlider }>
            <div className={ classes.lefBlock }>
              <div className={ classes.lefSlider }>
                <div className={ classes.sliderName }>
                  {' '}
                  <p>End Year</p>
                </div>
                <div className={ classes.rzSlider }>
                  <PwiSlider
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={ yearOptions }
                    min={ currentYear }
                    max={ currentYear + 14 }
                    step={ 1 }
                    value={ endYearValue }
                    onChange={ ( e, value ) => { updateValue( e, value, 'charitable_end_year', 'slider' ); } }
                  />
                </div>
              </div>
            </div>
            <div className={ classes.gitInputs }>
              <div className={ classes.annualInput }>
                <p>{endYearValue}</p>
              </div>
            </div>
          </div>
        )}
        <div className={ classes.giftSlider }>
          <div className={ classes.lefBlock }>
            <div className={ classes.lefSlider }>
              <div className={ classes.sliderName }>
                {' '}
                <p>&nbsp;</p>
              </div>
              <div className={ classes.rzSlider }>
                <div className={ classes.emptyTitle }>
                  Total Income in Year
                  {' '}
                  {' '}
                  {startYearValue}
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.gitInputs }>
            <div className={ classes.annualInput }>
              <span>
                {modulesServices.module9Data.charitableAnnualContributionTotalIncome !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module9Data.charitableAnnualContributionTotalIncome } prefix={ modulesServices.module9Data.charitableAnnualContributionTotalIncome >= 0 ? '$' : '($' } suffix={ modulesServices.module9Data.charitableAnnualContributionTotalIncome < 0 && ')' } /> ) : ( '$0' )}

              </span>
            </div>
          </div>
        </div>
        <div className={ classes.giftSlider }>
          <div className={ classes.lefBlock }>
            <div className={ classes.lefSlider }>
              <div className={ classes.sliderName }>
                {' '}
                <p>Annual Contribution as a % of Total Income</p>
              </div>
              <div className={ classes.rzSlider }>
                <PwiSlider
                  ValueLabelComponent={ ValueLabelComponent }
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={ annualOptions }
                  min={ 0 }
                  max={ 60 }
                  step={ 1 }
                  value={ giftValue }
                  onChange={ ( e, value ) => { updateValue( e, value, 'charitable_yearly_contribution', 'slider' ); } }
                  valueLabelFormat={ percentFormatWithTwoDecimal }
                />
              </div>
            </div>
          </div>
          <div className={ classes.gitInputs }>
            <div className={ classes.annualInput }>
              <NumberFormat
                decimalScale={isFocus!=='charitable_yearly_contribution'?2:5}
                fixedDecimalScale={isFocus!=='charitable_yearly_contribution'}
                customInput={ TextField }
                value={ giftValue }
                onValueChange={ ( e ) => updateValue( e, '', 'charitable_yearly_contribution', 'number' ) }
                onFocus={ ( e ) => { e.target.select(); setIsFocus('charitable_yearly_contribution') } }
                onBlur={emptyIsFocus}/>
              <span className={ classes.percentSymbl }>%</span>
            </div>
          </div>
        </div>
        <div className={ classes.giftSlider }>
          <div className={ classes.lefBlock }>
            <div className={ classes.lefSlider }>
              <div className={ classes.sliderName }>
                {' '}
                <p>&nbsp;</p>
              </div>
              <div className={ classes.rzSlider }>
                <div className={ classes.emptyTitle }>
                  <b>
                    Annual Contribution in the Year
                    {' '}
                    {' '}
                    {startYearValue}
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div className={ classNames( classes.gitInputs, classes.charitableInput ) }>
            <div className={ classes.annualInput }>
              <span>
                <b>
                  {modulesServices.module9Data.charitableAnnualContribution !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module9Data.charitableAnnualContribution } prefix={ modulesServices.module9Data.charitableAnnualContribution >= 0 ? '$' : '($' } suffix={ modulesServices.module9Data.charitableAnnualContribution < 0 && ')' } /> ) : ( '$0' )}
                </b>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={ `${classes.borderDivider} ${classes.borderDividerMT30}` }/>    
    </div>
  );
};

CharitableContribution.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,

};

export default withStyles( styles )( CharitableContribution );
