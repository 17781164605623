import powerUpBanner from '../../assets/img/get-powerup-lite/powerup_lite_sales_banner.jpg';
import powerupliteCheck from '../../assets/img/get-powerup-lite/poweruplite_sales_check.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
  },
  powerBanner: {
    backgroundImage: `url(${powerUpBanner})`,
    backgroundRepeat: 'no-repeat',
    padding: '21.4% 0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
  },
  bannerCaption: {
    position: 'absolute',
    transform: 'translateY(-25%)',
    mozTransform: 'translateY(-25%)',
    webkitTransform: 'translateY(-25%)',
    width: '100%',
    [theme.breakpoints.up( 'md' )]: {
      top: '-5px',
      left: '0px',
    },
    [theme.breakpoints.down( 'md' )]: {
      transform: 'translateY(-44%)',
      mozTransform: 'translateY(-44%)',
      webkitTransform: 'translateY(-44%)',
    },
    [theme.breakpoints.down( 'sm' )]: {
      transform: 'translateY(-40%)',
      mozTransform: 'translateY(-40%)',
      webkitTransform: 'translateY(-40%)',
    },
    '& h3': {
      margin: '0 0 60px',
      fontSize: '48px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down( 'md' )]: {
        margin: '0 0px 30px 0px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '32px',
        margin: '0 0px 20px 0px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        margin: '0 0px 10px 0px',
      },
    },
    '& p': {
      fontSize: '30px',
      color: '#fff',
      textAlign: 'center',
      lineHeight: '45px',
      margin: '0 0 60px',
      [theme.breakpoints.down( 'md' )]: {
        margin: '0 0px 30px 0px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        margin: '0 0px 20px 0px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        margin: '0 0px 20px 0px',
        lineHeight: '20px',
      },
    },
    '& a': {
      fontSize: '24px',
      color: theme.palette.common.white,
      margin: 'auto',
      display: 'table',
      background: theme.palette.common.greenlight,
      padding: '12px 22px',
      borderRadius: '10px',
      lineHeight: '30px',
      fontFamily: '"MuseoSans-300"',
      cursor: 'pointer',
      transition: 'all 0.5s ease 0s',
      MozTransition: 'all 0.5s ease 0s',
      WebkitTransition: 'all 0.5s ease 0s',
      border: '2px solid #84a84d',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '12px',
        lineHeight: '20px',
        padding: '6px 22px',
      },
      '&:hover': {
        background: theme.palette.common.greenDark,
        textDecoration: 'none',
        border: '2px solid #799e41',
      },
      '& i': {
        marginLeft: '5px',
      },
    },
  },
  powerUpSmartMoves: {
    margin: '50px 0 0px 0px',
    overflow: 'hidden',
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      lineHeight: '54px',
      '& span': {
        display: 'inline-block',
        width: '100%',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'inline',
          width: 'auto',
        },
      },
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '34px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '39px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '17px',
        lineHeight: '32px',
      },
    },
  },
  leftImg: {
    [theme.breakpoints.up( 'md' )]: {
      padding: '0px 15px',
    },
    '& figure': {
      width: '100%',
      maxWidth: '240px',
      float: 'right',
      margin: '0px',
      '& img': {
        width: '100%',
      },
      [theme.breakpoints.down( 'sm' )]: {
        float: 'none',
        margin: '0px auto',
      },
    },
  },
  rightContent: {
    [theme.breakpoints.up( 'md' )]: {
      padding: '0px 15px',
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: 'MuseoSans-100',
      padding: '0 50px',
      margin: '0px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '30px',
        padding: '10px',
      },
    },
  },
  bluesSpanColor: {
    color: theme.palette.common.blue,
    fontFamily: '"MuseoSans-500"',
  },
  blockSpan: {
    display: 'inline-block',
    width: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'inline',
      width: 'auto',
    },
  },
  financialFuture: {
    maxWidth: '860px',
    margin: '50px auto',
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: 'MuseoSans-300',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
        lineHeight: '37px',
      },
    },
  },
  skillsKnowledge: {
    fontSize: '21px',
    fontFamily: '"museosans-100"',
    lineHeight: '30px',
    color: theme.palette.common.black,
    '& img': {
      float: 'left',
      maxWidth: '150px',
      marginRight: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100px',
        marginRight: '15px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  financialList: {
    '& h3': {
      fontFamily: '"MuseoSans-300"',
      textAlign: 'left',
      margin: '30px 0',
      fontSize: '30px',
      fontWeight: 'normal',
      color: theme.palette.common.blue,
    },
    '& ul': {
      paddingLeft: '10px',
      '& li': {
        listStyle: 'none',
        paddingLeft: '40px',
        marginBottom: '10px',
        lineHeight: '33px',
        fontSize: '22px',
        '&:before': {
          content: '""',
          width: '60px',
          height: '40px',
          display: 'inline-block',
          verticalAlign: 'middle',
          backgroundImage: `url(${powerupliteCheck})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          marginLeft: '-40px',
          backgroundPosition: 'left center',
          float: 'left',
          clear: 'both',
          [theme.breakpoints.down( 'xs' )]: {
            width: '25px',
          },
        },
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '19px',
          lineHeight: '30px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
  },
  powerUpSalesFive: {
    lineHeight: '54px',
    margin: '70px auto 20px',
    display: 'table',
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.blue,
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
      '& blockSpan': {
        display: 'inline-block',
        width: '100%',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'inline',
          width: 'auto',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
        lineHeight: '37px',
      },
    },
  },
  powerUpSalesCntnt: {
    display: 'table',
    width: '100%',
    '& h3': {
      fontFamily: '"MuseoSans-300"',
      textAlign: 'center',
      margin: '30px 0',
      fontSize: '30px',
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '24px',
        lineHeight: '35px',
      },
    },
  },
  infoLesson: {
    maxWidth: '720px',
    margin: '25px auto',
    overflow: 'hidden',
    display: 'flex',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  lessonLeft: {
    width: '220px',
    padding: '20px 15px 20px 30px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      padding: '20px 15px 20px 15px',
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '0px',
      borderTopRightRadius: '10px',
    },
    '& h3': {
      margin: '10px 0 10px',
      textAlign: 'left',
      color: '#fff',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '13px',
        lineHeight: '24px',
      },
    },
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  lessonDesc: {
    padding: '30px 15px 20px 30px',
    fontSize: '18px',
    lineHeight: '27px',
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    border: '1px solid #ccc',
    borderLeft: '0',
    width: 'calc(100% - 220px)',
    color: theme.palette.common.black,
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      padding: '30px 15px 20px 15px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '& span': {
      fontSize: '24px',
      fontFamily: '"MuseoSans-500"',
      marginBottom: '10px',
      display: 'inline-block',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    '& ul': {
      paddingLeft: '15px',
      marginBottom: '10px',
      marginTop: '0px',
    },
    '& li': {
      marginBottom: '5px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '13px',
        lineHeight: '24px',
      },
    },
  },
  lessonOne: {
    background: '#e5f0f6',
  },
  firstLesson: {
    background: '#0069aa',
  },
  secondLesson: {
    background: '#bf4821',
  },
  thirdLesson: {
    background: '#84a84d',
  },
  fourthLesson: {
    background: '#fd9840',
  },
  fifthLesson: {
    background: '#1f96e0',
  },
  lessonTwo: {
    background: '#f8ece8',
  },
  lessonThree: {
    background: '#f2f6ed',
  },
  lessonFour: {
    background: '#fff4ec',
  },
  lessonFive: {
    background: '#e8f4fc',
  },
  salesCertificate: {
    background: '#f7f1e3',
  },
  powerToday: {
    position: 'relative',
    padding: '60px 0 80px',
    margin: '80px auto 0',
    maxWidth: '960px',
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '30px auto 0',
    },
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
        lineHeight: '37px',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: 'MuseoSans-100',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      '& span': {
        fontFamily: '"MuseoSans-300"',
      },
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
    },
  },
  completeCertificate: {
    display: 'flex',
    verticalAlign: 'middle',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '& p': {
      paddingRight: '10%',
      marginBottom: '30px',
      fontSize: '24px',
      lineHeight: '36px',
      marginTop: '0px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
  certificateRight: {
    minWidth: ' 280px',
    maxWidth: ' 280px',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: ' 230px',
      maxWidth: ' 230px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto',
    },
  },
  powerLite: {
    fontSize: '36px',
    height: '70px',
    lineHeight: '68px',
    padding: '0 30px',
    fontFamily: '"MuseoSans-300"',
    margin: '20px auto 0',
    display: 'table',
    background: '#84a84d',
    border: '2px solid #636363',
    color: '#ffffff',
    borderRadius: '10px',
    textDecoration: 'none',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '24px',
      height: '40px',
      lineHeight: '50px',
    },
    '&:hover': {
      background: '#799e41',
      borderColor: '#799e41',
    },
  },
  powerUpSaleslast: {
    margin: '50px auto',
    maxWidth: '960px',
    fontSize: '24px',
    lineHeight: '36px',
    overflow: 'hidden',
  },
  powerUpSalesBoxes: {
    '& p': {
      padding: '40px 90px',
      textAlign: 'center',
      borderRadius: '50%',
      fontSize: '20px',
      lineHeight: '30px',
      display: 'table',
      position: 'relative',
      color: theme.palette.common.black,
      '&:before': {
        content: '"“"',
        position: 'absolute',
        height: '90px',
        lineHeight: '100px',
        fontSize: '110px',
        fontFamily: 'fontawesome',
        fontStretch: 'condensed',
        fontWeight: '900',
        top: '0',
        left: '30px',
        bottom: '0',
        margin: 'auto',
        color: theme.palette.common.black,
        [theme.breakpoints.down( 'xs' )]: {
          left: '0px',
          width: '100%',
          display: 'block',
          height: 'auto',
        },
      },
      '& span': {
        display: 'block',
        width: '100%',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'inline',
          width: 'auto',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '15px',
        lineHeight: '25px',
        padding: '60px 50px 40px 50px',
      },
    },
  },
  authorQuote: {
    fontSize: '16px',
    display: 'block',
    marginTop: '15px',
    lineHeight: '24px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '13px',
      lineHeight: '20px',
    },
  },
  salesBoxOne: {
    background: ' #e5b6a6',
  },
  salesBoxTwo: {
    background: '#fbdfc3',
    margin: 'auto',
    float: 'right',
    clear: 'both',
    marginTop: '-40px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '0px',
      marginBottom: '20px',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'inline',
        width: 'auto',
      },
    },
  },
  salesBoxThree: {
    background: '#efe3c7',
    clear: 'both',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loseNothing: {
    margin: '70px 0 50px',
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
        lineHeight: '37px',
        margin: '0px 0px 20px',
      },
    },
    '& h3': {
      color: theme.palette.common.black,
      fontFamily: '"MuseoSans-300"',
      fontSize: '30px',
      textAlign: 'center',
      margin: '30px 0px',
      fontWeight: 'normal',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '35px',
      },
    },
    '& p': {
      fontFamily: '"MuseoSans-100"',
      marginBottom: '30px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '15px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '30px 0 30px',
    },
  },
  fewMoves: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.black,
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  movesImg: {
    '& img': {
      maxWidth: '250px',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '150px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '10px auto',
    },
  },
  fewMovesRight: {
    padding: '0 50px',
    '& h2': {
      fontFamily: '"MuseoSans-300"',
      fontSize: '36px',
      textAlign: 'center',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      lineHeight: '1.1',
      '& span': {
        display: 'block',
        fontFamily: '"MuseoSans-500"',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '35px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
        margin: '0px 0px 20px',
      },
    },
    '& p': {
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '30px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 20px',
    },
  },
  bottommargin: {
    marginBottom: '30px',
  },
  noMargin: {
    margin: '0px',
  },
  powerUpLite: {
    margin: '70px auto 50px',
    fontSize: '36px',
    height: '70px',
    lineHeight: '68px',
    padding: '0 30px',
    fontFamily: '"MuseoSans-300"',
    display: 'table',
    backgroundColor: '#84a84d',
    borderRadius: '10px',
    border: '2px solid #636363',
    textDecoration: 'none',
    color: theme.palette.common.white,
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    '&:hover': {
      backgroundColor: '#799e41',
      borderColor: '#799e41',
      color: '#ffffff',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '24px',
      height: '50px',
      lineHeight: '48px',
      margin: '20px auto 10px',
    },
  },
  circlesBlock: {
    position: 'relative',
    minHeight: '960px',
    padding: '220px 0 80px',
    boxSizing: 'border-box',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: '#f2e8d1',
      zIndex: '-1',
      left: '0',
      borderRadius: '50%',
      margin: 'auto',
      right: '0',
      top: '0',
      bottom: '0',
    },
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '50px',
      minHeight: '850px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '100px 0 80px',
    },
  },
  circlesSection: {
    maxWidth: '1000px',
    display: 'flex',
    margin: 'auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  roundCircle: {
    width: '280px',
    height: '280px',
    borderRadius: '50%',
    padding: '15px',
    textAlign: 'center',
    margin: '0 auto',
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.common.black,
    position: 'relative',
    boxSizing: 'border-box',
    '&:before': {
      content: 'open-quote',
      position: 'absolute',
      top: '35px',
      fontSize: '110px',
      fontFamily: 'fontawesome',
      fontStretch: 'condensed',
      fontWeight: '900',
      left: '0',
      right: '0',
      margin: 'auto',
    },
    '&:after': {
      content: 'close-quote',
      position: 'absolute',
      fontFamily: 'fontawesome',
      top: 'auto',
      bottom: '0',
      fontSize: '110px',
      fontStretch: 'condensed',
      fontWeight: '900',
      left: '0',
      right: '0',
      margin: 'auto',
    },
    '& span': {
      display: 'inline-block',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'inline',
        width: 'auto',
      },
    },
  },
  circleOne: {
    fontSize: '28px',
    lineHeight: '40px',
    background: '#dea88b',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '20px',
      lineHeight: '30px',
      height: '220px',
      width: '220px',
    },
  },
  circletwo: {
    width: '310px',
    height: '310px',
    fontSize: '22px',
    padding: '10px',
    lineHeight: '32px',
    top: '-165px',
    background: '#f8c088',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px',
      lineHeight: '26px',
      fontSize: '17px',
      width: '250px',
      height: '250px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      top: '20px',
    },
  },
  circleThree: {
    fontSize: '24px',
    lineHeight: '36px',
    background: '#e0c790',
    [theme.breakpoints.down( 'sm' )]: {
      lineHeight: '25px',
      fontSize: '17px',
      width: '220px',
      height: '220px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '40px',
    },
  },
  circlesBottom: {
    background: '#84a84d',
    height: '220px',
    fontSize: '31px',
    maxWidth: '860px',
    padding: '50px 90px',
    color: '#fff',
    borderRadius: '10px',
    margin: '100px auto 0',
    fontFamily: '"MuseoSans-500"',
    lineHeight: '46px',
    boxSizing: 'border-box',
    '& span': {
      display: 'block',
      float: 'right',
      fontSize: '20px',
      marginTop: '10px',
      fontStyle: 'italic',
      fontFamily: '"MuseoSans-300"',
      lineHeight: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
        marginTop: '20px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '24px',
      margin: '10px auto 0',
      lineHeight: '36px',
      height: '180px',
      maxWidth: '590px',
      padding: '35px 30px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '24px',
      height: 'auto',
    },
  },
  skillsContent: {
    display: 'flex',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
} );

export default styles;
