import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';

import styles from './styles';

const CreditScoreCalculated = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>How Your Credit Score Is Calculated</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/calculate-credit-score_v2.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      How Your Credit Score Is Calculated
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/credit-score.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The three major credit reporting agencies use the FICO
                          formula in calculating their own proprietary credit
                          scores. The exact details of how a FICO score is
                          calculated is kept secret, but the general formula is
                          made available to the public. Review the graph below
                          to see the factors that go into calculating your
                          credit score. It&apos;s important to monitor and
                          improve your credit score as a better number usually
                          results in{' '}
                          <Link
                            to="/finance-explained/credit-score"
                            target="_blank">
                            lower interest rates on your loans
                          </Link>
                          .
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <div className={classes.galleryImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/breakdown_pie.png"
                            alt=""
                          />
                        </div>
                        <p>
                          <b>Payment History:</b> the most important factor in
                          your credit score at 35%, payment history includes
                          which of your debts were paid on time, how much you
                          owed, and if you missed any payments. It’s essential
                          to pay your debts and bills on time to maintain good
                          credit.
                        </p>
                        <p>
                          <b>Utilization Rate:</b> you never want your
                          utilization rate to be higher than 30% on any account.
                          For example, if you have a credit card with a $5,000
                          limit, you never want to carry a balance on that
                          credit card greater than $1,500. The formula for
                          utilization rate is below:
                        </p>
                        <p className={classes.textCenter}>
                          Utilization Rate = Sum of all Credit Balances / Sum of
                          all Credit Limits
                        </p>
                        <p>
                          For example, if you have two credit cards that both
                          have $5,000 credit limits and you have a $2,000
                          balance on one credit card and $0 on the other, your
                          utilization rate would be:
                        </p>
                        <p className={classes.textCenter}>
                          Utilization Rate = $2,000 / $10,000 = 20%
                        </p>
                        <p>
                          <b>Length of Credit History:</b> the length of time
                          each debt account has been open and how long it has
                          been since you last used that account.
                        </p>
                        <p>
                          <b>Credit Mix:</b> the different kinds of accounts you
                          have such as credit cards, student loans, car loans
                          and mortgages.
                        </p>
                        <p>
                          <b>New Credit:</b> this includes new accounts and
                          recent accounts you have repaid. Opening multiple new
                          accounts at the same time such as applying for three
                          credit cards will have a negative impact on your
                          score.
                        </p>
                        <p>
                          This formula results in a three-digit number ranging
                          from 300 – 850 (the higher, the better) that relates
                          to how likely you are to repay debt. According to{' '}
                          <a
                            href="https://www.creditkarma.com/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Credit Karma
                          </a>
                          , a good credit score is 700 and higher.
                        </p>
                        <p>
                          Now that you know the formula, you know the secrets to
                          maintaining and improving your credit score!
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CreditScoreCalculated.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreditScoreCalculated);
