import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { withRouter} from 'react-router-dom';
import { Button } from '@material-ui/core';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import Grid from '@material-ui/core/Grid';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import pricingBanner from '../../assets/img/pricing/pricing_banner_img_new.png';
import triangleImage from '../../assets/img/pricing/pricingcards_bg.png';
import tickMark from '../../assets/img/pricing/tick_mark.png';
import powerUp from '../../assets/img/pricing/print_power_up_cover.jpg';
import pf101 from '../../assets/img/pricing/powerup-101-gold-wlogo.png';
import careerSketch from '../../assets/img/pricing/my-career-sketch-blue.png';
import pwiLogo from '../../assets/img/pricing/puzzle-icon.png';
import inkwiryForEducation from '../../assets/img/Inkwiry_for_Education_2021.pdf';

function TabPanel( props ) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={ value !== index }
      id={ `full-width-tabpanel-${index}` }
      aria-labelledby={ `full-width-tab-${index}` }
      { ...other }
    >
      {value === index && (
        <Box p={ 3 }>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps( index ) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Pricing = ( props ) => {
  const { classes, history } = props;
  const theme = useTheme();

  let referalId = history.location.pathname;
  referalId = referalId.split('/');
  referalId = referalId[2]; 

  const [value, setValue] = React.useState( 0 );
  const [expanded, setExpanded] = React.useState( false );
  const frequentAskedQuestions = [
    {
      id: 1, scroll: useRef( null ),
    },
    {
      id: 2, scroll: useRef( null ),
    },
    {
      id: 3, scroll: useRef( null ),
    },
    {
      id: 4, scroll: useRef( null ),
    },
    {
      id: 5, scroll: useRef( null ),
    },
  ];
  const handleChange = ( event, newValue ) => {
    setValue( newValue );
  };

  const buyNow = ( plan ) => {
    localStorage.setItem( 'inkwiry_pricing_plan', plan );
    localStorage.setItem('referal_key_id', referalId);
    history.push( '/join' );
  };

  const handleChangeIndex = ( index ) => {
    setValue( index );
  };

  const handleAccordianChange = ( panel ) => ( event, isExpanded ) => {
    setExpanded( isExpanded ? panel : false );
  };

  const handleScrollToPoint = ( askedQutn ) => {
    scrollToComponent( askedQutn, {
      offset: -500,
      align: 'top',
      duration: 200,
      behavior: 'smooth',
    } );
  };
  return (
    <Typography variant="body1" component="div">
      <div className={ classes.pricingPage }>
        <div className={ classes.pricingBanner }>
          <div className={ classes.container }>
            <div className={ classes.pricingContent }>
              <div className={ classes.pricingImage }>
                <img src={ pricingBanner } alt="pricingBanner" />
              </div>
              <Typography variant="h2" component="h2">Unlock the power of planning with Inkwiry. </Typography>
              <Typography variant="h3" component="h3">Build a sound strategy, achieve more, and live the life you want.</Typography>
              <div className={ classes.affordablePlan }>
                <Typography variant="h4" component="h4">We&apos;ve made Inkwiry super affordable.</Typography>
                <p>Did you know a traditional financial plan costs between $1,500 and $5,000?</p>
                <p>
                  With Inkwiry, for $9, you can quickly build a
                  <b> dynamic financial life plan</b>
                  {' '}
                  (and adjust it yourself as needed) while growing your personal finance skills and knowledge.
                </p>
              </div>
            </div>
          </div>
          <div className={ classes.downTriangle }>
            <div />
          </div>
        </div>
        <div className={ classes.peaceOfMind }>
          <div className={ classes.container }>
            <div className={ classes.peaceRow }>
              <Typography variant="h2" component="h2">
                Peace of mind doesn&apos;t need to cost a fortune.
                <span>Try Inkwiry today.</span>
              </Typography>
            </div>
          </div>
        </div>
        <div className={ classes.yourChoice }>
          <div className={ classes.container }>
            <Typography variant="h2" component="h2">Month-to-month or membership – it&apos;s your choice.</Typography>
            <p>Get Plan With Inkwiry for 30 days, no strings attached. You can renew as many times as you want with no obligation. Join as a Gold or Platinum Member to Plan With Inkwiry and gain full access to our PowerUp personal finance courses that start with the basics and empower you to become your own financial planner. To top it off, all levels are backed by our 7-day money-back guarantee.</p>
          </div>
        </div>
        <div className={ classes.pricingTabs }>
          <div className={ classes.container }>
            <div className={ classes.planPrices }>
              <Grid container spacing={ 4 } className={ classes.priceContainer }>
                <Grid item xs={ 12 } md={ 4 }>
                  <div className={ classes.pricingBlock }>
                    <div className={ classes.pricingTitle }>
                      Plan With Inkwiry
                    </div>
                    <div className={ classes.pricingBody }>
                      <p className={ classes.planPrice }>$9</p>
                      <p className={ classes.planText }>
                        one month
                        <span>no automatic renewal</span>
                      </p>
                      <Button onClick={ () => buyNow( 'monthly' ) }>Buy Now</Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                  <div className={ classes.pricingBlock }>
                    <div className={ classes.pricingTitle }>
                      GOLD Member
                    </div>
                    <div className={ classes.pricingBody }>
                      <p className={ classes.planPrice }>$7</p>
                      <p className={ classes.planText }>
                        per month
                        <span>billed $42 every six months</span>
                      </p>
                      <Button onClick={ () => buyNow( 'semi_year' ) }>Buy Now</Button>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={ 12 } md={ 4 }>
                  <div className={ classes.pricingBlock }>
                    <div className={ classes.pricingTitle }>
                      PLATINUM Member
                    </div>
                    <div className={ classes.pricingBody }>
                      <p className={ classes.planPrice }>$5</p>
                      <p className={ classes.planText }>
                        per month
                        <span>billed $60 annually</span>
                      </p>
                      <Button onClick={ () => buyNow( 'year' ) }>Buy Now</Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={ classes.triangleImg }>
            <img src={ triangleImage } alt="triangleImage" />
          </div>
          <div className={ classes.priceTabs }>
            <AppBar position="static" color="default">
              <div className={ classes.container }>
                <Tabs
                  value={ value }
                  onChange={ handleChange }
                  variant="fullWidth"
                  aria-label="full width tabs example"
                  className={ classes.tabsIndvi }
                >

                  <Tab className={ classes.tabHeader } label="Individuals" { ...a11yProps( 0 ) } />
                  <Tab label="Schools + Universities" { ...a11yProps( 1 ) } />

                </Tabs>
              </div>
            </AppBar>
            <SwipeableViews
              axis={ theme.direction === 'rtl' ? 'x-reverse' : 'x' }
              index={ value }
              className={ classes.SwipeTabs }
              onChangeIndex={ handleChangeIndex }
            >
              <TabPanel className={ classes.SwipeTabsPanel } value={ value } index={ 0 } dir={ theme.direction }>
                <div className={ classes.container }>
                  <div className={ classes.pricingTableOverflow }>
                    <table className={ classes.pricingTable }>
                      <thead>
                        <tr className={ classes.headerRow }>
                          <th />
                          <th>
                            Plan With Inkwiry
                            <span>for 30 days</span>
                          </th>
                          <th>
                            GOLD
                            <span>Membership</span>
                          </th>
                          <th>
                            PLATINUM
                            <span>Membership</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Plan With Inkwiry</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr>
                          <td>Inkwiry Financial Configurator + Simulator Engine</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr>
                          <td>Career Sketching</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr>
                          <td>Career Sketch Stories</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr>
                          <td>Advanced analytics, visualizations, reporting</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.coloredRow }>
                          <td>Online Personal Finance Courses:</td>
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td>Personal Finance PowerUp 101</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td> My Career Sketch</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.coloredRow }>
                          <td>Plus:</td>
                          <td />
                          <td />
                          <td />
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td>Walk-thrus to guide you</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td>Sharing and collaboration tools</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td>Tutorials: 2.5 hours and counting</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td>Finance Explained</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td>300 quick links to resources</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr className={ classes.leftPadding }>
                          <td>200 financial terms defined</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr>
                          <td>Immediate access to new features as they roll out</td>
                          <td />
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                        <tr>
                          <td>7-day money-back guarantee</td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                          <td><img src={ tickMark } alt="tickMark" /></td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr className={ classes.buyButtons }>
                          <td> </td>
                          <td><Button onClick={ () => buyNow( 'monthly' ) }>Buy Now</Button></td>
                          <td><Button onClick={ () => buyNow( 'semi_year' ) }>Buy Now</Button></td>
                          <td><Button onClick={ () => buyNow( 'year' ) }>Buy Now</Button></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </TabPanel>
              <TabPanel className={ classes.SwipeTabsPanel } value={ value } index={ 1 } dir={ theme.direction }>
                <div className={ classes.container }>
                  <div className={ classes.universeRow }>
                    <div className={ classes.leftBlock }>
                      <p className={ classes.pricingPara }>
                        {' '}
                        <span>Inkwiry + Schools:</span>
                        {' '}
                        Through financial literacy, we have the power to transform lives. Together.
                        {' '}
                      </p>
                      <div className={ classes.pricingOnline }>
                        <img src={ powerUp } alt="powerUp" />
                        <div className={ classes.courseContnt }>
                          <h2>
                            Inkwiry Personal Finance PowerUp
                            <sup>TM</sup>
                          </h2>
                          <p><em>What if ... your students could map out a detailed career path and plan 5, 10, 15 years into the future?</em></p>
                          <p>What if ... your students had the financial life skills and knowledge they need for a lifetime of success?</p>
                          <p>
                            Students gain fundamental personal finance skills and knowledge and achieve career readiness. Using Plan With Inkwiry, students develop a 5, 10, 15 year plan - or as we call it, a Career Sketch
                            <sup>TM</sup>
                            {' '}
                            - where they explore and compare higher education options, discover the return on investment of different careers, and much more.
                            {' '}
                          </p>
                          <p>Contact us today to learn more about our custom solutions for schools and universities.</p>
                          <div className={ classes.brochureBtn }>
                            <a href={inkwiryForEducation} download>Download The Brochure</a>
                          </div>
                          <p className={ classes.contactAddress }>
                            <span className={ classes.blueColor }>For details, contact:</span>
                            <span>James DeLuca, CEO</span>
                            <a className={ classes.blueColor } href="/">jgdeluca@inkwiry.com</a>
                          </p>
                          <div className={ classes.quotesBlock }>
                            {' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={ classes.rightBlock }>
                      <h4>$15</h4>
                      <p>Per student per year</p>
                      <div className={ classes.pricingLogos }>
                        <img src={ pf101 } alt="pf101" />
                      </div>
                      <div className={ classes.pricingLogos }>
                        <img src={ careerSketch } alt="careerSketch" />
                      </div>
                      <div className={ classes.pricingLogos }>
                        <img src={ pwiLogo } alt="pwiLogo" />
                        <p>Plan With Inkwiry</p>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </SwipeableViews>
          </div>
        </div>
        <div className={ classes.pricingFaq }>
          <Typography variant="h2" component="h2">Frequently Asked Questions</Typography>
          <div className={ classes.container }>
            <div className={ classes.faqContent }>
              <div className={ classes.faqBlock }>
                <Accordion expanded={ expanded === 'panel1' } onChange={ handleAccordianChange( 'panel1' ) }>
                  <div className={ classes.faqTitle }>
                    <AccordionSummary
                      expandIcon={ expanded === 'panel1' ? <i style={ { color: '#f89b22', border: '1px solid #f89b22' } } className="las la-minus" /> : <i style={ { color: '#0069aa', border: '1px solid #0069aa' } } className="las la-plus" /> }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      disableRipple
                      onClick={ () => { handleScrollToPoint( frequentAskedQuestions[0].scroll ); } }
                    >
                      <Typography variant="h4" component="h4" className={ classes.faqQuestion }>Can I try Inkwiry before I become a member?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={ classes.faqDescription } ref={ ( section ) => { frequentAskedQuestions[0].scroll = section; } }>
                    <AccordionDetails>
                      <p className={ classes.faqDesc }>
                        Yes. Not only do we offer many free apps and tools accessible from the home page, we also invite guests to take a test drive of Plan With Inkwiry for free. Try the
                        {' '}
                        <a href="/plan-with-inkwiry/he">higher education</a>
                        {' '}
                        and
                        <a href="/plan-with-inkwiry/cp"> career path</a>
                        {' '}
                        modules.
                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={ classes.faqBlock }>
                <Accordion expanded={ expanded === 'panel2' } onChange={ handleAccordianChange( 'panel2' ) }>
                  <div className={ classes.faqTitle }>
                    <AccordionSummary
                      expandIcon={ expanded === 'panel2' ? <i style={ { color: '#f89b22', border: '1px solid #f89b22' } } className="las la-minus" /> : <i style={ { color: '#0069aa', border: '1px solid #0069aa' } } className="las la-plus" /> }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={ () => { handleScrollToPoint( frequentAskedQuestions[1].scroll ); } }
                    >
                      <Typography variant="h4" component="h4" className={ classes.faqQuestion }>Are there planning limits? Meaning, can I only create a certain number of plans?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={ classes.faqDescription } ref={ ( section ) => { frequentAskedQuestions[1].scroll = section; } }>
                    <AccordionDetails>
                      <p className={ classes.faqDesc }>
                        No. As a member, you can create an unlimited number of financial life plans. Inkwiry believes in the power of asking  &quot;What if?&quot; and seeing results instantly. Build as many plans as you wish and discover your own best future.
                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={ classes.faqBlock }>
                <Accordion expanded={ expanded === 'panel3' } onChange={ handleAccordianChange( 'panel3' ) }>
                  <div className={ classes.faqTitle }>
                    <AccordionSummary
                      expandIcon={ expanded === 'panel3' ? <i style={ { color: '#f89b22', border: '1px solid #f89b22' } } className="las la-minus" /> : <i style={ { color: '#0069aa', border: '1px solid #0069aa' } } className="las la-plus" /> }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={ () => { handleScrollToPoint( frequentAskedQuestions[2].scroll ); } }
                    >
                      <Typography variant="h4" component="h4" className={ classes.faqQuestion }>Will my membership be renewed automatically?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={ classes.faqDescription } ref={ ( section ) => { frequentAskedQuestions[2].scroll = section; } }>
                    <AccordionDetails>
                      <p className={ classes.faqDesc }>
                        Plan With Inkwiry for 30 days will not renew automatically, although you can renew it manually as many times as you want. Gold and Platinum memberships will renew every six months and one year, respectively.
                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={ classes.faqBlock }>
                <Accordion expanded={ expanded === 'panel4' } onChange={ handleAccordianChange( 'panel4' ) }>
                  <div className={ classes.faqTitle }>
                    <AccordionSummary
                      expandIcon={ expanded === 'panel4' ? <i style={ { color: '#f89b22', border: '1px solid #f89b22' } } className="las la-minus" /> : <i style={ { color: '#0069aa', border: '1px solid #0069aa' } } className="las la-plus" /> }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={ () => { handleScrollToPoint( frequentAskedQuestions[3].scroll ); } }
                    >
                      <Typography variant="h4" component="h4" className={ classes.faqQuestion }>How do I cancel my membership?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={ classes.faqDescription } ref={ ( section ) => { frequentAskedQuestions[3].scroll = section; } }>
                    <AccordionDetails>
                      <p className={ classes.faqDesc }>
                        Use
                        {' '}
                        <a href="/">this link</a>
                        {' '}
                        and go to the Billing menu in the My Account page. If you have any issues cancelling your membership, contact us.
                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
              <div className={ classes.faqBlock }>
                <Accordion expanded={ expanded === 'panel5' } onChange={ handleAccordianChange( 'panel5' ) }>
                  <div className={ classes.faqTitle }>
                    <AccordionSummary
                      expandIcon={ expanded === 'panel5' ? <i style={ { color: '#f89b22', border: '1px solid #f89b22' } } className="las la-minus" /> : <i style={ { color: '#0069aa', border: '1px solid #0069aa' } } className="las la-plus" /> }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      onClick={ () => { handleScrollToPoint( frequentAskedQuestions[4].scroll ); } }
                    >
                      <Typography variant="h4" component="h4" className={ classes.faqQuestion }>If I don&apos;t like Inkwiry, can I request a refund?</Typography>
                    </AccordionSummary>
                  </div>
                  <div className={ classes.faqDescription } ref={ ( section ) => { frequentAskedQuestions[4].scroll = section; } }>
                    <AccordionDetails>
                      <p className={ classes.faqDesc }>
                        Yes. All membership levels come standard with a 7-day money-back guarantee. That means if you are unhappy with Inkwiry&apos;s products and services in the first 7 days of your new membership, you can send us an email and request a full refund.
                      </p>
                    </AccordionDetails>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Pricing.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Pricing );
