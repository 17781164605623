import powerUpBanner from '../../assets/img/get-powerup-lite/powerup_lite_sales_banner.jpg';
// import powerupliteCheck from '../../assets/img/get-powerup-lite/poweruplite_sales_check.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    position: 'relative',
  },
  powerBanner: {
    backgroundImage: `url(${powerUpBanner})`,
    backgroundRepeat: 'no-repeat',
    padding: '21.4% 0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'relative',
    '@media (max-width: 767px)': {
      padding: '35.4% 0',
    }
  },
  bannerCaption: {
    position: 'absolute',
    transform: 'translateY(-25%)',
    mozTransform: 'translateY(-25%)',
    webkitTransform: 'translateY(-25%)',
    width: 'calc(100% - 30px)',
    padding: '0px 15px',
    [theme.breakpoints.up( 'md' )]: {
      top: '-5px',
      left: '0px',
    },
    [theme.breakpoints.down( 'md' )]: {
      transform: 'translateY(-44%)',
      mozTransform: 'translateY(-44%)',
      webkitTransform: 'translateY(-44%)',
      left: '0px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      transform: 'translateY(-40%)',
      mozTransform: 'translateY(-40%)',
      webkitTransform: 'translateY(-40%)',
    },
    '& h3': {
      margin: '0 0 60px',
      fontSize: '48px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down( 'md' )]: {
        margin: '0 0px 30px 0px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '32px',
        margin: '0 0px 20px 0px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        margin: '0 0px 10px 0px',
      },
    },
    '& p': {
      fontSize: '30px',
      color: '#fff',
      textAlign: 'center',
      lineHeight: '45px',
      margin: '0 0 60px',
      [theme.breakpoints.down( 'md' )]: {
        margin: '0 0px 30px 0px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        margin: '0 0px 20px 0px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        margin: '0 0px 20px 0px',
        lineHeight: '20px',
      },
    },
    '& a': {
      fontSize: '24px',
      color: theme.palette.common.white,
      margin: 'auto',
      display: 'table',
      background: '#799e41',
      padding: '12px 22px',
      borderRadius: '10px',
      lineHeight: '30px',
      fontFamily: '"MuseoSans-300"',
      cursor: 'pointer',
      transition: 'all 0.5s ease 0s',
      MozTransition: 'all 0.5s ease 0s',
      WebkitTransition: 'all 0.5s ease 0s',
      border: '2px solid #799e41',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '12px',
        lineHeight: '20px',
        padding: '6px 22px',
      },
      '&:hover': {
        background: '#f69a34',
        borderColor: '#f69a34',
        textDecoration: 'none',
      },
      '& i': {
        marginLeft: '5px',
      },
    },
  },
  powerUpSmartMoves: {
    margin: '50px 0 0px 0px',
    overflow: 'hidden',
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.blue,
      lineHeight: '54px',
      '& span': {
        display: 'inline-block',
        width: '100%',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'inline',
          width: 'auto',
        },
        '& b':{
          fontFamily: '"MuseoSans-500"',
          fontWeight: 'normal',
        }
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
  },
  leftImg: {
    [theme.breakpoints.up( 'md' )]: {
      padding: '0px 15px',
    },
    '& figure': {
      width: '100%',
      maxWidth: '240px',
      float: 'right',
      margin: '0px',
      '& img': {
        width: '100%',
      },
      [theme.breakpoints.down( 'sm' )]: {
        float: 'none',
        margin: '0px auto',
      },
    },
  },
  rightContent: {
    [theme.breakpoints.up( 'md' )]: {
      padding: '0px 15px',
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: 'MuseoSans-100',
      padding: '0 50px 0px 30px',
      margin: '0px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '30px',
        padding: '10px',
      },
    },
  },
  bluesSpanColor: {
    color: theme.palette.common.blue,
    fontFamily: '"MuseoSans-500"',
  },
  blockSpan: {
    display: 'inline-block',
    width: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'inline',
      width: 'auto',
    },
  },
  financialFuture: {
    maxWidth: '860px',
    margin: '50px auto',
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: 'MuseoSans-300',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
        lineHeight: '37px',
      },
    },
  },
  skillsKnowledge: {
    fontSize: '21px',
    fontFamily: '"museosans-100"',
    lineHeight: '30px',
    color: theme.palette.common.black,
    '& img': {
      float: 'left',
      maxWidth: '150px',
      marginRight: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100px',
        marginRight: '15px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  financialList: {
    '& h3': {
      fontFamily: '"MuseoSans-300"',
      textAlign: 'left',
      margin: '30px 0',
      fontSize: '30px',
      fontWeight: 'normal',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '25px',
        lineHeight: '32px',
      },
    },
    '& ul': {
      paddingLeft: '0px',
      '& li': {
        listStyle: 'none',
        paddingLeft: '0px',
        marginBottom: '10px',
        lineHeight: '33px',
        fontSize: '22px',
        display: 'flex',
        // '&:before': {
        //   content: '""',
        //   width: '50px',
        //   height: '30px',
        //   display: 'inline-block',
        //   verticalAlign: 'middle',
        //   backgroundImage: `url(${powerupliteCheck})`,
        //   backgroundRepeat: 'no-repeat',
        //   backgroundSize: '30px',
        //   marginLeft: '-40px',
        //   backgroundPosition: 'left center',
        //   float: 'left',
        //   clear: 'both',
        //   [theme.breakpoints.down( 'xs' )]: {
        //     width: '25px',
        //   },
        // },
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '19px',
          lineHeight: '30px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
          lineHeight: '24px',
        },
      },
    },
  },
  listIcon:{
    '& img':{
      width: '30px',
      marginRight: '20px',
    }
  },
  salesCertificate: {
    background: '#f7f1e3',
  },
  powerToday: {
    position: 'relative',
    marginTop: '0',
    maxWidth: '860px',
    padding: '40px 0 25px',
    margin: '80px auto 0',
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px auto 0',
    },
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '23px',
        lineHeight: '37px',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '36px',
      fontFamily: 'MuseoSans-100',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '26px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      '& span': {
        fontFamily: '"MuseoSans-300"',
      },
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
    },
  },
  completeCertificate: {
    display: 'flex',
    verticalAlign: 'middle',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '& p': {
      paddingRight: '10%',
      marginBottom: '30px',
      fontSize: '24px',
      lineHeight: '36px',
      marginTop: '0px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        lineHeight: '24px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
  certificateRight: {
    minWidth: ' 300px',
    maxWidth: ' 300px',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down( 'sm' )]: {
      minWidth: ' 230px',
      maxWidth: ' 230px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0 auto',
    },
  },
  powerLite: {
    fontSize: '36px',
    height: '70px',
    lineHeight: '68px',
    padding: '0 30px',
    fontFamily: '"MuseoSans-300"',
    margin: '20px auto 0',
    display: 'table',
    background: '#84a84d',
    border: '2px solid #636363',
    color: '#ffffff',
    borderRadius: '10px',
    textDecoration: 'none',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '24px',
      height: '40px',
      lineHeight: '50px',
    },
    '&:hover': {
      background: '#799e41',
      borderColor: '#799e41',
    },
  },
  powerUpSaleslast: {
    margin: '50px auto',
    fontSize: '24px',
    lineHeight: '36px',
    overflow: 'hidden',
  },
  powerUpSalesBoxes: {
    '& p': {
      padding: '40px 90px',
      textAlign: 'center',
      borderRadius: '50%',
      fontSize: '20px',
      lineHeight: '30px',
      display: 'table',
      position: 'relative',
      margin: '0 0 10px',
      color: theme.palette.common.black,
      '& span': {
        display: 'block',
        width: '100%',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'inline',
          width: 'auto',
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '15px',
        lineHeight: '25px',
        padding: '20px 50px 40px 50px',
      },
    },
    '@media (min-width: 600px) and (max-width: 767px)': {
      padding: '60px 90px',
    }
  },
  authorQuote: {
    fontSize: '16px',
    display: 'block',
    marginTop: '15px',
    lineHeight: '24px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '13px',
      lineHeight: '20px',
    },
  },
  salesBoxOne: {
    background: ' #e5b6a6',
  },
  salesBoxOneFlex:{
    display: 'flex',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    }
  },
  salesBoxOneLeft:{
    position: 'absolute',
    left: '20px',
    height: '100%',
    display: 'flex',
    width: '55px',
    top: '0px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      maxWidth: '55px',
      margin: '0px auto 10px',
      position: 'initial',
    },
    '&:before': {
      content: '"“"',
      height: '90px',
      lineHeight: '100px',
      fontSize: '110px',
      fontFamily: 'fontawesome',
      fontStretch: 'condensed',
      fontWeight: '900',
      margin: 'auto',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        height: '55px',
      },
    },
  },
  salesBoxOneRight:{
    width: 'calc(100% - 0px)',
  },
  salesBoxTwo: {
    background: '#fbdfc3',
    margin: 'auto',
    float: 'right',
    clear: 'both',
    marginTop: '-40px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '0px',
      marginBottom: '20px',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'inline',
        width: 'auto',
      },
    },
  },
  salesBoxThree: {
    background: '#efe3c7',
    clear: 'both',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
  },
  loseNothing: {
    margin: '70px auto 50px',
    maxWidth: '820px',
    '& h2': {
      fontSize: '36px',
      textAlign: 'center',
      fontFamily: '"MuseoSans-300"',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      '& b': {
        fontFamily: '"MuseoSans-500"',
        fontWeight: 'normal',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
        lineHeight: '37px',
        margin: '0px 0px 20px',
      },
    },
    '& h3': {
      color: theme.palette.common.black,
      fontFamily: '"MuseoSans-300"',
      fontSize: '30px',
      textAlign: 'center',
      margin: '30px 0px',
      fontWeight: 'normal',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '35px',
      },
    },
    '& p': {
      fontFamily: '"MuseoSans-100"',
      marginBottom: '30px',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '15px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '30px 0 30px',
    },
  },
  fewMoves: {
    display: 'flex',
    color: theme.palette.common.black,
    maxWidth: '820px',
    margin: 'auto',
    marginTop: '100px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
      marginTop: '50px',
    },
  },
  movesImg: {
    '& img': {
      maxWidth: '250px',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '150px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      margin: '10px auto',
    },
  },
  fewMovesRight: {
    padding: '0 0px 0px 50px',
    '& h2': {
      fontFamily: '"MuseoSans-300"',
      fontSize: '36px',
      textAlign: 'center',
      margin: '0px 0px 40px',
      color: theme.palette.common.blue,
      fontWeight: 'normal',
      lineHeight: '46px',
      '& span': {
        display: 'block',
        fontFamily: '"MuseoSans-500"',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '35px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '30px',
        margin: '0px 0px 20px',
      },
    },
    '& p': {
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '30px',
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 20px',
    },
  },
  bottommargin: {
    marginBottom: '30px',
  },
  noMargin: {
    margin: '0px',
  },
  powerUpLite: {
    margin: '70px auto 50px',
    fontSize: '36px',
    height: '70px',
    lineHeight: '68px',
    padding: '0 30px',
    fontFamily: '"MuseoSans-300"',
    display: 'table',
    backgroundColor: '#84a84d',
    borderRadius: '10px',
    border: '2px solid #636363',
    textDecoration: 'none',
    color: theme.palette.common.white,
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    '&:hover': {
      backgroundColor: '#799e41',
      borderColor: '#799e41',
      color: '#ffffff',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '24px',
      height: '50px',
      lineHeight: '48px',
      margin: '20px auto 10px',
    },
  },
  circlesBlock: {
    position: 'relative',
    minHeight: '960px',
    padding: '220px 0 80px',
    boxSizing: 'border-box',
    margin: '0 -15px',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: '#e0e9d2',
      zIndex: '-1',
      left: '0',
      borderRadius: '50%',
      margin: 'auto',
      right: '0',
      top: '0',
      bottom: '0',
      [theme.breakpoints.down( 'sm' )]: {
        borderRadius: '0%',
      }
    },
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '50px',  
      minHeight: '850px',
      padding: '50px 20px 80px'
    },
  },
  circlesSection: {
    maxWidth: '1000px',
    display: 'flex',
    margin: 'auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0px 20px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
    },
  },
  roundCircle: {
    width: '280px',
    height: '280px',
    borderRadius: '50%',
    padding: '15px',
    textAlign: 'center',
    margin: '0 auto',
    alignItems: 'center',
    display: 'flex',
    color: theme.palette.common.black,
    position: 'relative',
    boxSizing: 'border-box',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '20px auto',
    },
    '& span': {
      display: 'inline-block',
      fontSize: '25px',
      lineHeight: '30px',
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down( 'sm' )]: {
        display: 'inline',
        width: 'auto',
        fontSize: '18px',
        lineHeight: '28px',
      },
    },
    '& img': {
      width: '25px',
      verticalAlign: 'middle',
    },
  },
  circleOne: {
    fontSize: '28px',
    lineHeight: '40px',
    background: '#dea88b',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '20px',
      lineHeight: '30px',
      height: '220px',
      width: '220px',
    },
  },
  circletwo: {
    width: '300px',
    height: '300px',
    fontSize: '22px',
    padding: '10px',
    lineHeight: '32px',
    top: '-165px',
    background: '#f8c088',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px',
      lineHeight: '26px',
      fontSize: '17px',
      width: '250px',
      height: '250px',
      top: '0',
    },
    [theme.breakpoints.down( 'xs' )]: {
      top: '20px',
    },
  },
  circleThree: {
    fontSize: '24px',
    lineHeight: '36px',
    background: '#e0c790',
    [theme.breakpoints.down( 'sm' )]: {
      lineHeight: '25px',
      fontSize: '17px',
      width: '220px',
      height: '220px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '40px',
    },
  },
  circlesBottom: {
    background: '#014372',
    height: '220px',
    fontSize: '31px',
    maxWidth: '860px',
    padding: '0px',
    color: '#fff',
    borderRadius: '10px',
    margin: '100px auto 0',
    fontFamily: '"MuseoSans-500"',
    lineHeight: '46px',
    boxSizing: 'border-box',
    position: 'relative',
    '& span': {
      display: 'block',
      float: 'right',
      fontSize: '20px',
      marginTop: '10px',
      fontStyle: 'italic',
      fontFamily: '"MuseoSans-300"',
      lineHeight: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '24px',
        marginTop: '20px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '24px',
      margin: '10px auto 0',
      lineHeight: '36px',
      height: 'auto',
      maxWidth: '590px',
      padding: '35px 30px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '24px',
      height: 'auto',
      marginTop: '30px',
    },
  },
  skillsContent: {
    display: 'flex',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  closeQuote: {
    transform: 'rotate(180deg)',
  },
  greenLine: {
    width: '70%',
    display: 'flex',
    margin: '0 auto',
    padding: '20px',
    verticalAlign: 'middle',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
    }
  },
  bestIntrest: {
    position: 'absolute',
    top: '0',
    padding: '50px 90px',
    [theme.breakpoints.down( 'sm' )]: {
      top: '0%',
      padding: '15% 40px',
      left: '0px',
    },
    '@media (max-width: 374px)': {
      top: '0',
    }
  },
  wealthText: {
    color: '#0069aa',
    fontFamily: '"MuseoSans-300"',
    marginBottom: '0',
    marginTop: '30px',
  },
  powerGold: {
    '& img': {
      maxWidth: '400px',
      margin: '0 auto 30px',
      textAlign: 'center',
      display: 'flex',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '250px',
      },
    },
  },
  titleText: {
    fontSize: '36px',
    fontFamily: '"MuseoSans-300"',
    lineHeight: '50px',
    color: '#fff',
    textAlign: 'center',
    margin: '0 0 10px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '26px',
      lineHeight: '40px',
    },
    '& span': {
      display: 'block',
    },
  },
  eightBlocks: {
    position: 'relative',
    maxWidth: '960px',
    margin: '50px auto',
    marginBottom: '0',
    padding: '70px 0 100px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '50px 0 50px',
    }
  },
  powerLife: {
    background: '#014372',
  },
  subTitle: {
    fontSize: '36px',
    fontFamily: 'MuseoSans-100',
    margin: '40px auto',
    textAlign: 'center',
    color: '#fff',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '28px',
    },
  },
  elBlocks: {
    display: 'flex',
    width: '87%',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
      maxWidth: '400px',
    },
  },
  csLevel: {
    width: '30%',
    margin: '30px 20px',
    position: 'relative',
    border: '3px solid #fff',
    borderRadius: '10px',
    padding: '0',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
    },
  },
  csLevelDesc: {
    color: '#fff',
    padding: '10px',
    textAlign: 'center',
    '& img': {
      width: '100%',
      maxWidth: '70px',
      verticalAlign: 'middle',
      border: '0',
    },
    '& p': {
      lineHeight: '1.42857143',
      fontSize: '13px',
      textTransform: 'uppercase',
      margin: '0',
      paddingTop: '20px',
      fontFamily: 'MuseoSans-300',
      fontWeight: '400',
    },
    '& h3': {
      fontSize: '23px',
      lineHeight: '1.1',
      marginBottom: '10px',
      textAlign: 'center',
      margin: '30px 0',
      color: '#fff',
      marginTop: '3px',
      fontFamily: 'MuseoSans-300',
      fontWeight: '400',
    },
  },
  goButton: {
    borderRadius: '10px',
    borderWidth: '2px',
    padding: '1px 22px',
    textTransform: 'uppercase',
    margin: '14px 0',
    fontSize: '13px',
    cursor: 'default',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    border: '2px solid #f0ad4e',
    lineHeight: '1.42',
  },
  progressLevel: {
    background: '#8ca659',
    padding: '25px 30px 40px',
    borderBottomLeftRadius: '7px',
    borderBottomRightRadius: '7px',
  },
  csProgress: {
    border: '2px solid #fff',
    borderRadius: '10px',
    height: '22px',
    background: '#fff',
    boxSizing: 'border-box',
  },
  progressBar: {
    width: '100%',
    borderRadius: '10px',
    height: '18px',
    background: '#0069aa',
  },
  progressValue: {
    textAlign: 'center',
    color: '#fff',
    margin: '0',
    paddingTop: '5px',
    fontSize: '12px',
  },
  plWorks: {
    minHeight: '50px',
  },
  hiddenDisplay: {
    visibility: 'hidden',
  },
  powerUpClaim: {
    maxWidth: '950px',
    margin: '50px auto 30px',
    display: 'table',
  },
  startPf101: {
    background: '#83a947',
    fontSize: '20px',
    lineHeight: '20px',
    padding: '16px 30px',
    color: '#fff',
    display: 'table',
    border: '2px solid #83a947',
    borderRadius: '8px',
    margin: '50px auto',
    textAlign: 'center',
    fontFamily: 'MuseoSans-300',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '15px',
    },
    '&:hover': {
      background: '#f69a34',
      borderColor: '#f69a34',
    },
    '& span': {
      paddingRight: '15px',
    },
  },
  showMoney: {
    paddingRight: '15px',
  },
} );

export default styles;
