import { PMT } from '../../utilities/excelLibrary';

function fedSubNewHigherEdLoanSheet( module1Data, module15Data ) {
  // Student Loan Excel Sheet
  let studentLoanData = {};
  let summationData = {};

  // Loan Amount
  let loanAmount = 0;
  if ( typeof module15Data.newFedSubBeginningBalance !== 'undefined' && module15Data.newFedSubBeginningBalance !== '' ) {
    loanAmount = module15Data.newFedSubBeginningBalance;
  }

  // Beginning Balance
  const beginningBalance = loanAmount;

  // Terms in Years
  let termsOfYear = 0;
  if ( beginningBalance > 0 ) {
    termsOfYear = parseFloat( module15Data.fed_sub_term );
  }

  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( typeof module15Data.fed_sub_interest_rate !== 'undefined' && module15Data.fed_sub_interest_rate !== '' ) {
    annualInterestRate = parseFloat( module15Data.fed_sub_interest_rate );
  }

  // Original Payment
  let originalPayment = 0;
  if ( beginningBalance > 0
    && termsOfYear > 0 ) {
    // code commented by mahananda
    originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( beginningBalance ), 0, 0 );
  }

  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let graduationYear = 0;

  if ( typeof module15Data.newFedSubPaymentYearBegins !== 'undefined' ) {
    graduationYear = module15Data.newFedSubPaymentYearBegins;
  }
  if ( graduationYear > 0 ) {
    let year = parseInt( graduationYear, 10 );
    const yearLimit = year + 29;

    // Student Loan Data
    studentLoanData = {};

    let Balance = beginningBalance;
    const Payment = originalPayment;
    let Interest = 0;
    if ( annualInterestRate > 0 ) {
      Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
    }
    let Principal = Payment - Interest;
    let Equity = Principal;
    let TotalInterest = Interest;
    const TotalPayment = Equity + TotalInterest;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];
    let k = 0;
    let finalSubmonth = 1;

    // For Loop from graduation year to +10 years
    for ( year; year <= yearLimit; year += 1 ) {
      studentLoanData[year] = {};

      studentLoanData[year].LaggingInterest = 0;
      studentLoanData[year].LaggingPrincipal = 0;
      studentLoanData[year]['Months Total Balance'] = 0;

      // For Loop for 12 months
      let condition = 12;
      if ( k === 0 ) {
        condition = 6;
      }
      k += 1;

      for ( let month = 1; month <= condition; month += 1 ) {
        studentLoanData[year][month] = {};
        if ( year === graduationYear && month === 1 ) {
          studentLoanData[year][month].YearIdentifier = year;
          studentLoanData[year][month].Balance = Balance;
          studentLoanData[year][month].Payment = Payment;
          studentLoanData[year][month].Interest = Interest;
          studentLoanData[year][month].Principal = Principal;
          studentLoanData[year][month].Equity = Equity;
          studentLoanData[year][month].TotalInterest = TotalInterest;
          studentLoanData[year][month].TotalPayment = TotalPayment;
          studentLoanData[year][month].Month = k * month;
        } else {
          // Year Identifier
          studentLoanData[year][month].YearIdentifier = year;

          // Balance
          let tempBalance = 0;
          if ( Balance === 0 ) {
            tempBalance = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempBalance = ( Balance - Principal );
          }
          studentLoanData[year][month].Balance = tempBalance;

          if ( tempBalance > 0 ) {
            finalSubmonth += 1;
            studentLoanData['Final Month'] = finalSubmonth;
            studentLoanData['Final Year'] = year;
          } else {
            finalSubmonth = 0;
          }

          studentLoanData[year][month].Month = finalSubmonth;

          // Payment
          studentLoanData[year][month].Payment = Payment;

          // Interest
          let tempInterest = 0;
          if ( Balance === 0 ) {
            tempInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          }
          studentLoanData[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal = 0;
          if ( Balance === 0 ) {
            tempPrincipal = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempPrincipal = Payment - tempInterest;
          }
          studentLoanData[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity = 0;
          if ( Balance === 0 ) {
            tempEquity = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempEquity = Equity + tempPrincipal;
          }
          studentLoanData[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest = 0;
          if ( Balance === 0 ) {
            tempTotalInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalInterest = TotalInterest + tempInterest;
          }
          studentLoanData[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment = 0;
          if ( Balance === 0 ) {
            tempTotalPayment = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          }
          studentLoanData[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( studentLoanData[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( studentLoanData[year][month].TotalPayment );

        studentLoanData[year].LaggingInterest = parseFloat( studentLoanData[year].LaggingInterest ) + parseFloat( studentLoanData[year][month].Interest );
        studentLoanData[year].LaggingPrincipal = parseFloat( studentLoanData[year].LaggingPrincipal ) + parseFloat( studentLoanData[year][month].Principal );
        studentLoanData[year]['Months Total Balance'] = parseFloat( studentLoanData[year]['Months Total Balance'] ) + parseFloat( studentLoanData[year][month].Balance );
      }
    }
  }

  // Summation Data
  if ( typeof module1Data.start_year !== 'undefined'
    && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 29;

    // Summation Data
    summationData = {};

    let summationAccrualValue = 0;
    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationCumulativeInterest = 0;
    let summationCumulativePrincipal = 0;
    let summationEndingBalance = 0;
    let summationPointsPaid = 0;

    // For Loop from Start year to +14 years
    for ( summationYear; summationYear <= summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // W/O Accrual
      summationAccrualValue = 0;
      if ( summationYear === module15Data.start_year ) {
        summationAccrualValue = module15Data.fed_sub_firstyear;
      } else if ( summationYear === module15Data.start_year + 1 ) {
        summationAccrualValue = module15Data.fed_sub_secondyear;
      } else if ( summationYear === module15Data.start_year + 2 ) {
        summationAccrualValue = module15Data.fed_sub_thirdyear;
      } else if ( summationYear === module15Data.start_year + 3 ) {
        summationAccrualValue = module15Data.fed_sub_fourthyear;
      } else if ( summationYear === module15Data.start_year + 4 ) {
        summationAccrualValue = module15Data.fed_sub_fifthyear;
      } else if ( summationYear === module15Data.start_year + 5 ) {
        summationAccrualValue = module15Data.fed_sub_sixthyear;
      } else {
        summationAccrualValue = 0;
      }
      summationData[summationYear]['W/O Accrual'] = summationAccrualValue;

      // Beginning Balance
      if ( typeof module15Data.start_year !== 'undefined' && module15Data.start_year !== ''
        && summationYear < module15Data.start_year ) {
        summationBeginningBalance = 0;
      } else if ( typeof module15Data.newFedSubPaymentYearBegins !== 'undefined' && module15Data.newFedSubPaymentYearBegins !== ''
          && typeof module15Data.start_year !== 'undefined' && module15Data.start_year !== '' ) {
        if ( summationYear >= module15Data.start_year && summationYear < module15Data.newFedSubPaymentYearBegins ) {
          summationBeginningBalance = summationAccrualValue + summationEndingBalance;
        } else if ( summationYear <= ( module15Data.newFedSubPaymentYearBegins + termsOfYear ) ) {
          if ( typeof studentLoanData !== 'undefined'
                && typeof studentLoanData[summationYear] !== 'undefined'
                && typeof studentLoanData[summationYear][1].Balance !== 'undefined' ) {
            summationBeginningBalance = studentLoanData[summationYear][1].Balance;
          } else {
            summationBeginningBalance = 0;
          }
        } else {
          summationBeginningBalance = 0;
        }
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      if ( summationData[summationYear].BeginningBalance > 1 && summationYear >= module15Data.newFedSubPaymentYearBegins ) {
        if ( typeof studentLoanData[summationYear] !== 'undefined' && typeof studentLoanData[summationYear].LaggingInterest !== 'undefined' ) {
          summationLaggingInterest = studentLoanData[summationYear].LaggingInterest;
        } else {
          summationLaggingInterest = 0;
        }
      } else {
        summationLaggingInterest = 0;
      }
      summationData[summationYear].LaggingInterest = summationLaggingInterest;
      totalInterestPaid += summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof studentLoanData[summationYear] !== 'undefined'
          && typeof studentLoanData[summationYear].LaggingPrincipal !== 'undefined' ) {
          summationLaggingPrincipal = studentLoanData[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;
      totalPaid = totalPaid + summationLaggingInterest + summationLaggingPrincipal;

      // Cumulative Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationCumulativeInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationCumulativeInterest = 0;
      }
      summationData[summationYear].CumulativeInterest = summationCumulativeInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationCumulativePrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationCumulativePrincipal = 0;
      }
      summationData[summationYear].CumulativePrincipal = summationCumulativePrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = summationData[summationYear].BeginningBalance - summationData[summationYear].LaggingPrincipal;
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;

      // Points Paid
      if ( summationAccrualValue > 0 ) {
        summationPointsPaid = summationAccrualValue * ( module15Data.fed_sub_origination_fee / 100 );
      } else {
        summationPointsPaid = 0;
      }
      summationData[summationYear]['Points Paid'] = summationPointsPaid;
      // Origination Fees
      summationData[summationYear]['Origination Fees'] = summationPointsPaid;
    }
  }

  function studentLoanDataFun() {
    const studentLoanCompleteData = {};
    studentLoanCompleteData['Summation Data'] = summationData;
    studentLoanCompleteData['Total Interest Paid'] = totalInterestPaid;
    studentLoanCompleteData['Total Paid'] = totalPaid;
    studentLoanCompleteData['Annual Interest Rate'] = annualInterestRate;
    studentLoanCompleteData['Fed Sub Higher Education'] = studentLoanData;
    studentLoanCompleteData['Beginning Balance'] = beginningBalance;
    studentLoanCompleteData['Terms In Year'] = termsOfYear;
    studentLoanCompleteData['Monthly Payment'] = originalPayment;

    return studentLoanCompleteData;
  }
  // console.log( 'studentLoanDataFun----', studentLoanDataFun() );
  return studentLoanDataFun();
}
export default fedSubNewHigherEdLoanSheet;
