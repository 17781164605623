import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const HighYieldSavingsAccount = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>High-Yield Savings Account</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/high-yield-savings.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      High-Yield Savings Account
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/high-yield-savings-vert.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          High-yield savings accounts offer a much higher
                          interest rate than standard savings accounts. How much
                          higher? The national average interest rate for a
                          standard savings account is{' '}
                          <a
                            href="https://www.fdic.gov/regulations/resources/rates/"
                            target="_blank"
                            rel="noopener noreferrer">
                            roughly 0.09%.
                          </a>{' '}
                          A high-yield savings account can be{' '}
                          <a
                            href="https://www.nerdwallet.com/best/banking/savings-accounts?trk=nw_gn2_4.0"
                            target="_blank"
                            rel="noopener noreferrer">
                            22x higher
                          </a>
                          , or almost 2.00% annually! Grow your money faster
                          without taking any additional risk.
                        </p>
                        <p className={classes.smallText}>
                          Just like a standard savings account, a high-yield
                          savings account stores money you don&apos;t need for
                          day-to-day expenses, still provides easy access to
                          your money and is insured by the federal government up
                          to $250,000. High-yield savings accounts are a better
                          way to safely save for financial emergencies and short
                          and long-term goals than standard savings accounts.
                        </p>
                        <p className={classes.smallText}>
                          The number one reason to keep money in a high-yield
                          savings account instead of a standard savings account
                          is to earn more interest on your money. Again, a
                          high-yield savings account can earn up to 22x more
                          interest, or 2.00% annually, while you save for
                          emergencies or longer-term needs. While you save for
                          emergencies or longer-term needs. That&apos;s a smart
                          money move.
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
HighYieldSavingsAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HighYieldSavingsAccount);
