import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Link, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { DropdownSection } from './Components';
import styles from './styles';
import bluePrint from '../../../../assets/img/menu/fc-blueprint.png';
import FinacialInde from '../../../../assets/img/menu/fi.png';
import Exiting from '../../../../assets/img/menu/ea.png';

import HEImg from '../../../../assets/img/menu/he.png';
import MHEImg from '../../../../assets/img/menu/mhe.png';
import FHEImg from '../../../../assets/img/menu/fhe.png';

import CPImg from '../../../../assets/img/menu/cp.png';
import CAImg from '../../../../assets/img/menu/ca.png';
import ACAImg from '../../../../assets/img/menu/aca.png';
import FCAImg from '../../../../assets/img/menu/fca.png';

import Family from '../../../../assets/img/menu/family.png';
import Vacation from '../../../../assets/img/menu/vacation.png';
import Car from '../../../../assets/img/menu/car.png';
import Home from '../../../../assets/img/menu/home.png';

import Investments from '../../../../assets/img/menu/investments.png';
import Rei from '../../../../assets/img/menu/rei.png';
import { compose } from 'recompose';

const ProductsDropdownEl = styled.div`
  width: 1105px;
  padding: 0;
  
  ul {
    padding: 0 !important;
  }
  li {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 5px 0;
    margin: 0;
  }
  ol {
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    padding: 0;   
    position: relative;
  }
  ol a {
    padding-left: 0 !important;
  }
  ol img {
    width: 30px;
    margin-right: 5px;
  }
  ol:hover div {
    display: block;
  }
  figure {
    margin: 0;
  }
  h3 {
    margin:0 0 0;
  }
`;

const ProductsSection = styled.ul`
  li {
    display: block;
    padding: 4px 0;
    font-size: 15px;
    font-weight: normal
  }
  li:hover {
    text-decoration: underline;
  }
`;

const ProductsDropdown = (props) => {
  const { classes, loginData, history } = props;
  return (
    <ProductsDropdownEl className={classes.marginLeftNew}>
      <DropdownSection data-first-dropdown-section>
        <ProductsSection>
          {loginData !== undefined && loginData.isLoggedIn === true ? (
            <Grid container className={classes.loginMain}>
              <Grid sm={4}>
                <Grid container className={classes.megamenuContainer}>
                  <Grid sm={12}>
                    <div className={`${classes.LeftMegamenu} ${classes.MegamenuPaddingSixty} ${classes.LeftMegamenuThree}`}>
                      <div className={classes.loginMegaMenu}>

                        <ul>
                          <li><Link to="/finance-explained">Finance Explained</Link></li>
                          <li><Link to="/pf101/dashboard">Personal Finance 101</Link></li>
                          <li><Link to="/my-career-sketch/dashboard">My Career Sketch</Link></li>
                          <li><Link to="/timeline-sketcher">Timeline Sketcher</Link></li>
                          {/* <li><Link to="/fc-blueprint">Plan With Inkwiry</Link></li> */}
                        </ul>

                        <ul>
                          <li><Link to="/desktop">My Desktop</Link></li>
                          <li><Link to="/dashboard">My Dashboard</Link></li>
                          <li><Link to="/sight">Inkwiry Sight</Link></li>
                          <li><Link to="/questions">Powerful Questions</Link></li>
                          <li><Link to="/sense">Inkwiry Sense</Link></li>
                          <li><Link to="/career-sketch">Career Sketch Stories</Link></li>

                          <li><Link to="/financial-calculators">Financial Calculators</Link></li>
                          <li><Link to="/tutorials">Tutorials</Link></li>
                        </ul>
                        
                        
                      </div>
                    </div>
                  </Grid>

                </Grid>
              </Grid>
              <Grid sm={8} className={`${classes.rightMegamenu} ${classes.MegamenuPaddingSixty}`}>
                <div className={classes.MegamenuPaddingSixtyLeft}>
                  <Grid container>
                    <Grid sm={6}>
                      <div className={classes.finacialMegaMenuMew}>
                        <h3>FC BASELINE</h3>
                        <ul>
                          <ol>
                            <figure>
                              <img alt="" src={bluePrint} />
                            </figure>
                            <span><Link to="/fc-blueprint">FC Blueprint</Link></span>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={FinacialInde} />
                              </figure>
                            </figure>
                            <span>
                              {' '}
                              <Link to="/plan-with-inkwiry/fi">Financial Independence</Link>
                            </span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/fi">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/financial-independence">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={Exiting} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/existing">Existing Assets + Liabilities</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/existing">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/existing-assets-liabilities">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                        </ul>
                      </div>
                    </Grid>
                    <Grid sm={6}>
                      <div className={classes.finacialMegaMenuMew}>
                        <h3>EDUCATION</h3>
                        <ul>
                          <ol>
                            <figure>
                              <img alt="" src={HEImg} />
                            </figure>
                            <span><Link to="/plan-with-inkwiry/he">Higher Education</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/he">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/higher-education">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={MHEImg} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/mhe">More Higher Education</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/mhe">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/more-higher-education">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={FHEImg} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/fhe">Further Higher Education</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/fhe">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/further-higher-education">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                        </ul>
                      </div>
                    </Grid>
                    <Grid sm={6}>
                      <div className={classes.finacialMegaMenuMew}>
                        <h3>CAREER</h3>
                        <ul>
                          <ol>
                            <figure>
                              <img alt="" src={CPImg} />
                            </figure>
                            <span><Link to="/plan-with-inkwiry/cp">Career Path</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/cp">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/career-path">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={CAImg} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/ca">Career Advancement</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/ca">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/career-advancement">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={ACAImg} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/aca">Additional Career Advancement</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/aca">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/additional-career-advancement">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={FCAImg} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/fca">Further Career Advancement</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/fca">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/further-career-advancement">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                        </ul>
                      </div>
                    </Grid>
                    <Grid sm={6}>
                      <div className={classes.finacialMegaMenuMew}>
                        <h3>PERSONAL</h3>
                        <ul>
                          <ol>
                            <figure>
                              <img alt="" src={Family} />
                            </figure>
                            <span><Link to="/plan-with-inkwiry/family">Family</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/family">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/family">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={Vacation} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/vacation">Vacation + Special Purchases</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/vacation">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/vacation">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={Car} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/car">Buying a Car</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/car">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/buying-a-car">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={Home} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/home">Buying a Home</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/home">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/buying-a-home">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                        </ul>
                      </div>
                    </Grid>
                    <Grid sm={6}>
                      <div className={classes.finacialMegaMenuMew}>
                        <h3>INVESTING</h3>
                        <ul>
                          <ol>
                            <figure>
                              <img alt="" src={Investments} />
                            </figure>
                            <span><Link to="/plan-with-inkwiry/investments">Investments</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/investments">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/investments">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>
                          <ol>
                            <figure>
                              <figure>
                                <img alt="" src={Rei} />
                              </figure>
                            </figure>
                            <span><Link to="/plan-with-inkwiry/rei">Real Estate Investment Property</Link></span>
                            <div className={classes.hoverSubMenu}>
                              <ul>
                                <li><Link to="/plan-with-inkwiry/rei">Plan With Inkwiry View</Link></li>
                                <li><span className={ classes.spanDivide }>/</span></li>
                                <li><Link to="/rei">Worksheet View</Link></li>
                              </ul>
                            </div>
                          </ol>

                        </ul>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.logOut}>

              <Grid sm={7}>
                <div className={classes.paddingTopFisty20} />
                <Grid container className={classes.megamenuContainer}>
                  <Grid sm={6}>
                    <div className={`${classes.LeftMegamenu} ${classes.LeftMegamenuFrst}`}>
                      <div className={classes.listGroup}>
                        <h3><Link to="/sense">Inkwiry Sense</Link></h3>
                        <ul>
                          <li><Link to="/questions">Powerful Questions</Link></li>
                          <li><Link to="/awareness">Awareness Challenge</Link></li>
                          <li><Link to="/discovery">Discovery Challenge</Link></li>
                        </ul>
                      </div>
                      <div className={classes.listGroup}>
                        <h3><Link to="/connect">Inkwiry Connect</Link></h3>
                        <ul>
                          <li><Link to="/finance-explained">Finance Explained</Link></li>
                          <li><Link to="/personal-finance-101">Personal Finance 101</Link></li>
                          <li><Link to="/my-career-sketch">My Career Sketch</Link></li>
                        </ul>
                      </div>
                      <div className={classes.listGroup}>
                        <h3><Link to="/career-sketch">Career Sketch</Link></h3>
                        <ul>
                          <li><Link to="/career-sketch">Explore Career Sketches</Link></li>
                          <li><Link to="/timeline-sketcher">Build My Career Sketch</Link></li>

                        </ul>
                      </div>
                    </div>
                  </Grid>
                  <Grid sm={6}>
                    <div className={`${classes.LeftMegamenu} ${classes.LeftMegamenuTwo}`}>
                      <div className={classes.listGroup}>
                        <h3><Link to="/financial-configurator">FINANCIAL CONFIGURATOR</Link></h3>
                        <ul>
                          <li><Link to="/fc-blueprint">Plan With Inkwiry</Link></li>
                           <li><Link to="/life-event-library">Life Event Library</Link></li>
                        </ul>
                      </div>
                      <div className={classes.listGroup}>
                        <h3><Link to="/spaces">QUICK START</Link></h3>
                        <ul>
                          <li><Link to="/financial-calculators/compare-schools">Compare Schools</Link></li>
                          <li><Link to="/financial-calculators/compare-careers">Compare Careers</Link></li>
                           <li><Link to="/financial-calculators">Financial Calculators</Link></li>
                          <li><Link to="/tutorials">Tutorials</Link></li>
                        </ul>
                      </div>
                      <div className={classes.listGroup}>
                        <ul>
                          <h3><Link to="/spaces">COMING SOON</Link></h3>
                          <li><Link to="/spaces">Inkwiry Spaces</Link></li>
                        </ul>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <div className={classes.paddingTopFisty} />
              </Grid>
              <Grid sm={5} className={classes.rightMegamenuforlogout}>
                <div className={classes.blueBoxMain}>
                  <div className={classes.blueBox}>
                    <h3>Think you know  <span className ={classes.tocenter } >  money? </span> </h3>
                    <p>Take our quick, 9-question quiz. It could change your life and the way you think about planning.</p>
                    <Button onClick={()=>{history.push('/inkwiry-challenge')}}className={classes.btnstartQuiz}>Take the Quiz</Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
        </ProductsSection>
      </DropdownSection>
    </ProductsDropdownEl>
  );
};
ProductsDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loginData: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(ProductsDropdown);
