import discoveryChallenge from '../../assets/img/challenges/discovery-challenge_bg.png';

const styles = ( theme ) => ( {
  header: {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px 0 20px',
      height: 'auto',
    },
  },
  ChallengesBody: {
    overflowX: 'hidden',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: 'calc(100% - 56px)',
      top: '0px',
      left: '0',
    },
  },
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  ChallengesQuizHead: {
    background: theme.palette.common.bluelight,
    padding: '8px',
    top: '75px',
    width: '100%',
    zIndex: '9',
    fontFamily: 'MuseoSans-500',
    [theme.breakpoints.down( 'sm' )]: {
      top: '70px',
    },
    '& h3': {
      textAlign: 'center',
      color: theme.palette.common.white,
      textTransform: 'uppercase',
      lineHeight: '36px',
      fontSize: '27px',
      fontFamily: 'MuseoSans-500',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
  },
  ChallengesQuizSub: {
    display: 'none',
    padding: '8px 0',
  },
  ChallengesNumbers: {
    position: 'fixed',
    top: '342px',
    left: '30px',
    zIndex: '999',
    transform: 'translateY(-50%)',
    WebkitTransform: 'translateY(-50%)',
    MozTransform: 'translateY(-50%)',
    MsTransform: 'translateY(-50%)',
    OTransform: 'translateY(-50%)',
    transition: 'all 0.5s ease-in-out',
    WebkitTransition: 'all 0.5s ease-in-out',
    MozTransition: 'all 0.5s ease-in-out',
    MsTransition: 'all 0.5s ease-in-out',
    OTransition: 'all 0.5s ease-in-out',

    [theme.breakpoints.down( 'sm' )]: {
      top: '55%',
      left: '15px !important',
    },
    '@media only screen and (min-device-width: 500px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      display: 'none',
    },
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      margin: '0',
      '& li': {
        display: 'block',
        padding: '0 0 5px 0',
        margin: '0',
        fontFamily: '"MuseoSans-100"',
        '& button': {
          padding: '0px',
          minWidth: 'auto',
          '&:hover': {
            background: 'transparent',
          },
        },
        '& span': {
          display: 'block',
          width: '33px',
          height: '33px',
          borderRadius: '100%',
          border: '1px solid #ffffff',
          textAlign: 'center',
          fontSize: '15px',
          transition: 'all 0.5s ease-in-out',
          WebkitTransition: 'all 0.5s ease-in-out',
          MozTransition: 'all 0.5s ease-in-out',
          MsTransition: 'all 0.5s ease-in-out',
          OTransition: 'all 0.5s ease-in-out',
          '& span': {
            border: '0px solid #ffffff',
          },
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
  feedbackImg: {
    '& button': {
      padding: '0px',
      '&:hover': {
        background: 'transparent',
      },
      '& span': {
        width: '33px',
        border: '1px solid #ffffff',
        height: '33px',
        margin: '0px auto',
        borderRadius: '50%',
        '& figure': {
          margin: '0px',
          '& img': {
            width: 'auto',
            border: '3px solid #0069aa',
            borderRadius: '50%',
            background: '#0069aa',
          },
        },
        '& span': {
          border: '0px solid #ffffff',
        },
        '&:nth-child(2)': {
          display: 'none',
        },
      },
    },
  },
  listNum: {
    '& span': {
      background: theme.palette.common.blue,
      color: theme.palette.common.white,
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'none',
        },
        '&:hover': {
          background: '#09314a',
        },
      },
    },
  },
  listNumRight: {
    '& span': {
      background: '#39b54a !important',
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'inline-block',
          fontSize: '10px',
          marginLeft: '1px',
        },
        '&:hover': {
          background: '#39b5 !important',
        },
      },
    },
  },
  listNumWrong: {
    '& span': {
      background: '#ff0000 !important',
      lineHeight: '33px',
      '& span': {
        '& i': {
          display: 'inline-block',
          fontSize: '10px',
          marginLeft: '1px',
        },
        '&:hover': {
          background: '#ff0000 !important',
        },
      },
    },
  },
  listNumActive: {
    '& span': {
      background: '#09314a',
      lineHeight: '33px',
      '& i': {
        display: 'inline-block',
        fontSize: '10px',
        marginLeft: '1px',
      },
    },
  },
  arrowUp: {
    '& span': {
      lineHeight: '37px',
      '& span': {
        '& i': {
          display: 'block',
          position: 'relative',
          top: '7px',
          filter: 'brightness(0) invert(1)',
        },
      },
      '& i': {
        fontSize: '28px',
        lineHeight: '18px',
      },
    },
  },
  questionRow: {
    backgroundImage: `url(${discoveryChallenge})`,
    backgroundPosition: 'top 60px left',
    backgroundRepeat: 'repeat',
    backgroundSize: '90%',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down( 'sm' )]: {
      marginLeft: '40px',
      marginTop: '70px',
    },
    '@media (max-width: 499px)': {
      marginLeft: '40px',     
    },
    '@media only screen and (min-device-width: 500px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      marginLeft: '0px', 
    }
  },
  questionBlock: {
    padding: '38px 0 15px 0',
    width: '100%',
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative',
    opacity: '0.3',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 0 15px 0',
    },
  },
  firstQuestionBlock: {
    opacity: '1',
  },
  scrollBlur: {
    opacity: '0.3',
  },
  questionNum: {
    display: 'inline-block',
    fontSize: '30px',
    lineHeight: '48px',
    verticalAlign: 'middle',
    background: theme.palette.common.blue,
    color: theme.palette.common.white,
    border: '1px solid #ffffff',
    borderRadius: '100%',
    width: '50px',
    height: '50px',
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'xs' )]: {
      width: '33px',
      height: '33px',
      lineHeight: '33px',
      fontSize: '14px',
    },
  },
  questionTitle: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '80%',
    fontSize: '26px',
    lineHeight: '30px',
    color: theme.palette.common.blue,
    padding: '0 0 0 15px',
    margin: '0',
    textAlign: 'left',
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
      lineHeight: '23px',
    },
  },
  optionList: {
    width: '80%',
    display: 'inline-block',
    verticalAlign: 'top',
    margin: '0',
    padding: '10px 0 0 0',
    textAlign: 'center',
    fontFamily: '"MuseoSans-100"',
  },
  formControl: {
    width: '100%',
  },
  radioGroup: {
    width: '100%',
    flexDirection: 'initial',
    '& span': {
      color: theme.palette.common.blue,

      '&:last-child': {
        display: 'block',
        fontSize: '24px',
        color: theme.palette.common.blue,
        margin: '0 auto',
        fontFamily: '"museo-sans", sans-serif',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',

        },
      },
      '& svg': {
        width: '40px',
        height: '40px',
        // border: '2px solid #7cb1d3',
        // borderRadius: '50%',
        [theme.breakpoints.down( 'xs' )]: {
          width: '25px',
          height: '25px',
        },
        '& path': {
          position: 'relative',
        },
      },
    },
  },
  cotrolLabel: {
    display: 'inline-block',
    verticalAlign: 'top',
    padding: '0',
    textAlign: 'center',
    width: '24%',
    minWidth: '24%',
    margin: '0 auto',
    '& > span:first-child': {
      '&:hover': {
        background: 'rgba(0, 105, 170, 0.1)',
      },
    },
    '&:last-child': {
      marginRight: '0px',
    },
  },
  ansWrong: {
    '& span': {
      '& svg': {
        color: theme.palette.common.red,
      },
    },
  },
  ansRight: {
    '& span': {
      '& svg': {
        color: '#39b54a',
      },
    },
  },
  ansRow: {
    margin: '0 auto',
    background: theme.palette.common.white,
    padding: '25px 40px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '25px 10px 25px 40px',
    },
  },
  ansTitle: {
    fontFamily: '"MuseoSans-500"',
    margin: '0 0 20px 0',
    textAlign: 'center',
    fontSize: '32px',
    lineHeight: '32px',
    fontWeight: '300',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '22px',
    },
  },
  ansCurrect: {
    color: '#39b54a',
  },
  answrong: {
    color: '#ff0000 ',
  },
  ansBox: {
    padding: '32px 70px 32px 70px',
    border: '1px solid #0069aa',
    textAlign: 'left', 
    '@media (max-width: 767px)': {
      padding: '15px 30px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '15px 15px 15px 15px',
    },
  },
  boxTitle: {
    fontSize: '30px',
    lineHeight: '30px',
    fontFamily: '"MuseoSans-500"',
    color: theme.palette.common.blue,
    margin: '0 0 20px 0',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '26px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '18px',
      lineHeight: '23px',
    },
  },
  boxPara: {
    fontSize: '19px',
    lineHeight: '24px',
    fontFamily: '"MuseoSans-300"',
    color: '#323232',
    margin: '0 0 13px 0',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  spanColor: {
    color: '#ed7d31',
    fontFamily: '"MuseoSans-500"',
    fontSize: '21px',
    fontWeight: 'normal',
  },
  textBlue: {
    color: theme.palette.common.blue,
    textDecoration: 'underline',
    fontWeight: '600',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.orange,
    },
  },
  spanStrong: {
    fontFamily: 'MuseoSans-500',
  },
  nextQuestion: {
    textAlign: 'right',
    '& a': {
      fontSize: '24px',
      lineHeight: '24px',
      color: theme.palette.common.blue,
      marginRight: '0',
      marginTop: '30px',
      fontFamily: '"MuseoSans-500"',
      display: 'inline-block',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'none',
      },
      [theme.breakpoints.down( 'xs' )]: {
        lineHeight: '22px',
        fontSize: '16px',
        marginTop: '10px',
      },
    },
  },
  graphBox: {
    '& img': {
      width: '100%',
    },
  },
  BoxBlue: {
    border: '1px solid #0069aa',
    padding: '5px 10px',
    color: theme.palette.common.blue,
    '& p': {
      fontFamily: '"MuseoSans-300"',
      marginTop: '5px',
      marginBottom: '5px',
      fontSize: '16px',
      '& span': {
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
      },
    },
  },
  BoxGreen: {
    border: '1px solid #39b54a',
    padding: '5px 10px',
    fontSize: '16px',
    color: theme.palette.common.green,
    margin: '20px 0',
    '& p': {
      fontFamily: '"MuseoSans-300"',
      marginTop: '5px',
      fontSize: '16px',
      marginBottom: '5px',
      '& span': {
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
      },
    },
  },
  BoxRed: {
    border: '1px solid #ff0000',
    padding: '5px 10px',
    color: theme.palette.common.red,
    [theme.breakpoints.down( 'sm' )]: {
      marginBottom: '20px',
    },
    '& p': {
      fontFamily: '"MuseoSans-300"',
      marginTop: '5px',
      fontSize: '16px',
      marginBottom: '5px',
      '& span': {
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
      },
    },
  },
  borderLine: {
    borderRight: '2px dashed #a6a6a6',
  },
  GridPad: {
    padding: '15px 0px 25px',
  },
  whatsMyScore: {
    padding: '70px 0',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      padding: '40px 0',
    },
    '& button': {
      display: 'inline-block',
      padding: '13px 24px',
      border: '2px solid #0069aa',
      color: theme.palette.common.blue,
      fontSize: '36px',
      lineHeight: '36px',
      borderRadius: '5px',
      verticalAlign: 'middle',
      fontFamily: '"museo-sans", sans-serif',
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.white,
        background: theme.palette.common.blue,
        textDecoration: 'none',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
  },
  progressBar: {
    background: theme.palette.common.blue,
    padding: '10px 0',
    width: '100%',
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    paddingLeft: '30px',
    '& p': {
      maxWidth: '350px',
      width: '100%',
      color: theme.palette.common.white,
      fontSize: '18px',
      lineHeight: '18px',
      margin: '0px 0px 8px',
    },
    '& div': {
      maxWidth: '336px',
      width: '100%',
      display: 'block',
      background: theme.palette.common.white,
      height: '15px',
      '& span': {
        background: '#39b54a',
        display: 'inline-block',
        height: '15px',
      },
    },
  },
  awernessScoreSec: {
    padding: '0 0 25px',
    position: 'relative',
  },
  awernessTitle: {
    margin: '0 0 30px 0',
    padding: '20px',
    textAlign: 'center',
    fontFamily: 'MuseoSans-500',
    color: theme.palette.common.white,
    background: theme.palette.common.bluelight,
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '22px',
    },
  },
  awernessBlock: {
    padding: '20px 0px',
  },
  awernessBlockLeft: {
    '& h3': {
      fontSize: '30px',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-500"',
      margin: '10px 0 25px 0',
      textAlign: 'left',
      fontWeight: '500',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
    '& span': {
      color: theme.palette.common.blue,
      fontSize: '40px',
      fontWeight: '600',
      textAlign: 'center',
      display: 'block',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
      '& span': {
        display: 'block',
      },
    },
    '& img': {
      width: '100%',
      padding: '30px 0',
    },
  },
  awernessBlockRight: {
    paddingLeft: '30px',
  },
  moneyfreeRow: {
    '& h3': {
      fontSize: '32px',
      fontFamily: '"MuseoSans-500"',
      margin: '10px 0 25px 0',
      color: '#323232',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
      },
    },
    '& p': {
      fontSize: '24px',
      paddingBottom: '23px',
      color: '#333',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '18px',
        paddingBottom: '0px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
      },
    },
    '& h4': {
      fontSize: '25px',
      fontFamily: '"MuseoSans-300"',
      paddingBottom: '23px',
      lineHeight: '1.42857143',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      },
    },
  },
  formInput: {
    marginBottom: '20px',
    maxWidth: '60%',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '100%',
    },
    '& > div': {
      width: '100%',
      '& label': {
        background: theme.palette.common.white,
        padding: '5px',
        width: 'auto',
        fontSize: '20px',
        transform: 'translate(15px, 10px) scale(1)',
        color: theme.palette.common.backlight,
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
        },
      },
      '& input': {
        fontSize: '20px',
        padding: '12px 10px',
        color: theme.palette.common.black,
        border: '1px solid #ccc',
        borderRadius: '0',
        outline: 'none',
        boxShadow: 'none',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '16px',
        },
      },
    },
    '& fieldset': {
      display: 'none',
    },
  },
  formBtn: {
    marginBottom: '20px',  
    '& button': {
      width: '100%',
      padding: '10px 24px',
      fontSize: '26px',
      lineHeight: '36px',
      borderRadius: '5px',
      display: 'block',
      background: '#f59a34',
      color: theme.palette.common.white,
      border: '2px solid #f59a34',
      boxShadow: 'none',
      '&:hover': {
        background: '#ea8d24',
        border: '2px solid #ea8d24',
        boxShadow: 'none',
      },
      '@media screen and (min-width: 768px) and (max-width: 1024px)': {
        fontSize: '14px',
        width: 'auto',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
        lineHeight: '23px',
        padding: '10px',
      },
    },
  },
  bottomText: {
    fontSize: '20px',
    paddingBottom: '23px',
    fontStyle: 'italic',
    color: '#333',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
    },
  },
  pendinglistPopup: {
    '& > div > div': {
      width: '615px',
    },
  },
  pendingNumList: {
    fontSize: '18px',
    padding: '15px 30px',
    lineHeight: '28px',
    textAlign: 'center',
    '& p': {
      margin: '0px',
    },
  },
  buttonOk: {
    padding: '10px 15px',
    borderTop: '1px solid #e5e5e5',
    '& button': {
      border: '2px solid #8c8c8c',
      '&:hover': {
        background: '#f9f9f9',
      },
    },
  },
  tooltipInfo: {
    width: '120px',
    padding: '0px',
    height: '27px',
    marginLeft: '10px',
    lineHeight: '27px',
    textTransform: 'none',
    fontSize: '15px',
    background: '#0069aa !important',
    opacity: '1',
    '&::after': {
      borderRightColor: '#0069aa !important',
    },
  },
  radioGroupMax: {
    fontSize: '16px',
    '& span:last-child': {
      maxWidth: '130px',
    },
    '& label': {
      maxWidth: '130px',
      margin: '0 auto',
    },
  },
  circleImages: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    margin: '30px 0',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  circleImage: {
    width: '200px',
    height: '200px',
    display: 'table-cell',
    background: '#4472c4',
    borderRadius: '50%',
    verticalAlign: 'middle',
    textAlign: 'center',
    color: '#fff',
    fontSize: '20px',
    '& p': {
      fontSize: '28px',
      padding: '0 29px',
      lineHeight: '35px',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '27px',
      },
    },
  },
  plusImage: {
    '& img': {
      maxWidth: '100px',
      margin: '0 25px',
    },
  },
  errorInput: {
    border: '1px solid red',
  },
  redoPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
      fontFamily: '"MuseoSans-500"',
      fontWeight: 'normal',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  buttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#0069aa',
        borderColor: '#0069aa',
        color: '#fff',
        marginRight: '6px',
      },
      // '&:last-child': {
      //   color: '#fff',
      //   '&:hover': {
      //     backgroundColor: '#e6e6e6',
      //   },
      // },
    },
  },
  interestTable:{
    width: '100%',
    maxWidth: '620px',
    marginBottom: '20px',
    borderCollapse: 'collapse',
    '& thead':{
      '& tr':{
        '& th':{
          padding: '8px',
          background: '#0069aa',
          border: '1px solid #000',
          color: '#fff',
        }
      }
    },
    '& tbody':{
      '& tr':{
        '& td':{
          padding: '8px',
          border: '1px solid #000',
          textAlign: 'right',
          color: '#000',
          '&:first-child':{
            textAlign: 'center',
          }
        }
      }
    }
  },
  flexImg:{
    '& img':{
      width: '100%',
      maxWidth: '540px',
    }
  },
  flexCnt:{
    paddingRight: '80px',
  },
  mtb40:{
    margin: '40px 0px !important',
  },
  mt40:{
    marginTop: '40px !important',
  }
} );

export default styles;
