const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  containerRow: {
    paddingBottom: '50px',
  },
  readmoreLftCnt: {
    background: '#ffffff',
    position: 'relative',
    textAlign: 'center',
    '& ul':{
      padding: '24px 0px 50px',
      '& li':{
        display: 'block',
        padding: '0',
        textAlign: 'left',
        position: 'relative',
        borderBottom: '6px solid #0069aa',
        '&:first-child':{
          borderTop: '6px solid #0069aa',
        },
        '& a':{
          display: 'block',
          fontFamily: '"MuseoSans-100"',
          color: '#333333',
          position: 'relative',
          padding: '0px 40px',
          minHeight: '45px',
          fontSize: '18px',
          textAlign: 'left',          
          lineHeight: '45px',
        }
      }
    }
  },
  active:{
    background: '#e7e8e8',
    borderTop: '1px solid #dbdbdb',
    borderBottom: '1px solid #dbdbdb',
  },
  securityRgt:{
    padding: '0 25px',
    background: '#fff',
    position: 'relative',
    zIndex: '9',
    '& b':{
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
    '& h2':{
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
      fontSize: '36px',
      lineHeight: '36px',
      margin: '0',
      padding: '20px 0',
      color: '#0069aa',
    },
    '& p':{
      fontFamily: '"MuseoSans-100"',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '24px',
      margin: '0',
      padding: '0 0 28px 0',
    }
  },
  listMain:{
    padding: '0 0 0 18px',
    display: 'block',
    margin: '0',
    '& li':{
      listStyleType: 'decimal',
      display: 'list-item',
      fontSize: '18px',
      lineHeight: '24px',
      padding: '0 0 18px 18px',
      '&:before':{
        content: '""',
      },
      '& em':{
        fontStyle: 'normal',
        textDecoration: 'underline',
      }
    }
  },
  listSub:{
    padding: '20px 0 0',
    display: 'block',
    margin: '0',
    '& li':{
      counterIncrement: 'section',
      position: 'relative',
      padding: '0 0 15px 32px',
      display: 'block',
      '&:before':{
        content: 'counter(section,lower-alpha) ") "',
        position: 'absolute',
        left: '0',
      },
    }
  },
  listSubTwo:{
    padding: '0 0 0 20px',
    display: 'block',
    counterReset: 'item',
    '& li':{
      position: 'relative',
      padding: '20px 0 0 30px',
      display: 'block',
      '&:before':{
        content: 'counters(item,".",lower-roman) " "',
        position: 'absolute',
        left: '0',
        counterIncrement: 'item',
      },
    },
  },
  textBlue: {
    outline: '0',
    textDecoration: 'none',
    color: '#337ab7',
    fontWeight: 'bold',
    '&:hover':{
      color: '#fd9840',
    }
  },
  agreePara:{
    width: '80%',
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: '16px !important',
    lineHeight: '22px',
    padding: '16px 0 0 !important',
    fontFamily: '"MuseoSans-100"',
    fontWeight: 'normal',
  },
  hrBorder: {
    borderBottom: '3px solid #ccc',
    margin: '30px 0 40px 0px',
  },
  mb20: {
    marginBottom: '20px',
    display: 'inline-block',
    fontFamily: '"MuseoSans-100"',
  },
  comdoLogoDiv:{
    display: 'flex',
    padding: '0 0 40px 0',
    alignItems: 'center',
    '& figure':{
      width: '18%',
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'center',
      margin: '0px',
      '& img':{
        width: '100%',
      }
    },
    '& p':{
      width: '80%',
      display: 'inline-block',
      verticalAlign: 'middle',
      fontSize: '16px',
      lineHeight: '22px',
      padding: '0 0 0 18px',
      '& span':{
        display: 'inline-block',
        marginBottom: '20px',
      }
    }
  }

 } );

export default styles;
