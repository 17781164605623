function CareerOutputBackendSheetData( module4Data, incomeStatementnetData, investmentsData, expenseCalculationsData, cashFlowStatementnetData ) {
  /** ******************** Student Loan 1 *********************** */
  let year = 0;
  let yearLimit = 0;
  let secondYear = 0;
  let thirdYear = 0;
  let foodVal = 0;
  let clothingVal = 0;
  let entertainmentVal = 0;
  let technologyVal = 0;
  let transportationVal = 0;
  let carMaintenanceVal = 0;
  let carInsuranceVal = 0;
  let carPaymentVal = 0;
  let gasVal = 0;
  let carVal = 0;
  let miscellaneousVal = 0;
  let healthInsuranceDeductibleVal = 0;
  let healthInsurancePremiumVal = 0;
  let healthInsuranceVal = 0;
  let offCampusFoodVal = 0;
  let childrenVal = 0;
  let petVal = 0;
  let homePropertyExpensesVal = 0;
  let propertyTaxesVal = 0;

  let totalTuitionValue = 0;
  let totalRoomBoardValue = 0;
  let totalRentValue = 0;
  let totalUtilitiesValue = 0;
  let totalFoodValue = 0;
  let totalClothingValue = 0;
  let totalEntertainmentValue = 0;
  let totalTechnologyValue = 0;
  let totalTransportationValue = 0;
  let totalGasValue = 0;
  let totalMiscellaneousValue = 0;
  let totalCarValue = 0;
  let totalHealthValue = 0;
  let totalChildrenValue = 0;
  let totalPetValue = 0;
  let totalHomePropertyExpensesValue = 0;
  let totalPropertyTaxesValue = 0;
  let totalValues = 0;
  let incomeBonusTipsVal = 0;
  let incomeSupplementaryIncomeVal = 0;
  let incomeTotalTaxesVal = 0;
  let retirementContributionsVal = 0;
  // let totalLivingExpensesVal = 0;
  let totalDepreciationExpensesVal = 0;
  let totalInterestExpensesVal = 0;
  let netIncomeVal = 0;

  let totalLoanPaymentsValue1 = 0;

  let totalLoanPaymentsValue = 0;
  let totalRealEstateExpensesValue = 0;
  let totalHigherEducationExpensesValue1 = 0;
  let totalHigherEducationExpensesValue2 = 0;
  let totalHigherEducationExpensesValue3 = 0;
  let totalHigherEducationExpensesValue = 0;
  let totalChildrenExpensesValue = 0;
  let totalPetExpensesValue = 0;
  let totalCarExpensesValue = 0;

  // For Annual Budget Graph
  let annualTotalTaxesPaid = 0;
  // const annualLivingExpensesPaid = 0;

  let annualRentPaid = 0;
  let annualUtilitiesPaid = 0;
  let annualFoodPaid = 0;
  let annualClothingPaid = 0;
  let annualEntertainmentPaid = 0;
  let annualTechnologyPaid = 0;
  let annualTransportationPaid = 0;
  let annualMiscellaneousPaid = 0;
  let annualHealthcarePaid = 0;

  let annualHigherEducationExpensesPaid = 0;
  let annualChildrenExpenses = 0;
  let annualPetExpenses = 0;
  let annualCarExpenses = 0;
  let annualHomeExpenses = 0;
  let annualLoanPayments = 0;
  let retirementContributionsValue = 0;

  let careerOutputBackend = {};

  year = parseInt( module4Data.start_year, 10 );
  yearLimit = parseInt( module4Data.start_year, 10 ) + 30;
  careerOutputBackend = {};

  totalTuitionValue = 0;
  totalRoomBoardValue = 0;
  totalRentValue = 0;
  totalUtilitiesValue = 0;
  totalFoodValue = 0;
  totalClothingValue = 0;
  totalEntertainmentValue = 0;
  totalTechnologyValue = 0;
  totalTransportationValue = 0;
  totalGasValue = 0;
  totalMiscellaneousValue = 0;
  totalCarValue = 0;
  totalHealthValue = 0;
  totalChildrenValue = 0;
  totalPetValue = 0;
  totalHomePropertyExpensesValue = 0;
  totalPropertyTaxesValue = 0;
  totalValues = 0;

  // For annual Budget array
  annualTotalTaxesPaid = 0;
  // annualLivingExpensesPaid = 0;
  annualRentPaid = 0;
  annualUtilitiesPaid = 0;
  annualFoodPaid = 0;
  annualClothingPaid = 0;
  annualEntertainmentPaid = 0;
  annualTechnologyPaid = 0;
  annualTransportationPaid = 0;
  annualMiscellaneousPaid = 0;
  annualHealthcarePaid = 0;
  annualHigherEducationExpensesPaid = 0;
  annualChildrenExpenses = 0;
  annualPetExpenses = 0;
  annualCarExpenses = 0;
  annualHomeExpenses = 0;
  annualLoanPayments = 0;
  retirementContributionsValue = 0;

  const bar2GraphYearsList = [];
  const baseIncomeValArr = [];
  const incomeBonusTipsArr = [];
  const incomeincomeSupplementaryIncomeArr = [];
  const spouseIncomeArr = [];
  const netRentalIncomeArr = [];
  const totalTaxesArr = [];
  const retirementContributionsArr = [];
  const totalLivingExpensesArr = [];

  const totalLoanPaymentsValueArr = [];
  const totalRealEstateExpensesArr = [];
  const totalHigherEducationExpensesArr = [];
  const totalChildrenExpensesArr = [];
  const totalPetExpensesArr = [];
  const totalCarExpensesArr = [];

  const totalDepreciationExpensesArr = [];
  const totalInterestExpensesArr = [];
  const netIncomeArr = [];
  const personalContributions401kYearsList = [];
  const personalContributions401k = [];
  const personalEarnings401k = [];
  const employerContributions401k = [];
  const employerEarnings401k = [];
  const employerVestingReductions401k = [];
  const endingBalance401k = [];
  const rothIraYearsList = [];
  const rothIraPersonalContributions = [];
  const rothIraPersonalEarnings = [];
  const rothIraEndingBalance = [];
  const sepIraYearsList = [];
  const sepIraPersonalContributions = [];
  const sepIraPersonalEarnings = [];
  const sepIraEndingBalance = [];
  const pensionYearsList = [];

  const pensionPersonalContributions = [];
  const pensionEmployerContributions = [];
  const pensionEarnings = [];
  const pensionEndingBalance = [];

  // let k = 0;
  let personalContribution401k = 0;
  let personalEarning401k = 0;
  let employerContribution401k = 0;
  let employerEarning401k = 0;
  let employerVestingReduction401k = 0;
  let employerEndingBalance401k = 0;

  let rothIraPersonalContribution = 0;
  let rothIraPersonalEarning = 0;
  let rothIraEndingBalanceVal = 0;

  let sepIraPersonalContribution = 0;
  let sepIraPersonalEarning = 0;
  let sepIraEndingBalanceVal = 0;

  let pensionPersonalContribution = 0;
  let pensionEmployerContribution = 0;
  let pensionEarning = 0;
  let pensionEndingBalanceVal = 0;

  let roomBoardVal = 0;
  let rentVal = 0;
  let tuitionVal = 0;
  let utilitiesVal = 0;
  for ( year; year <= yearLimit; year += 1 ) {
    careerOutputBackend[year] = {};
    // Second Section
    if ( module4Data.start_year !== undefined && module4Data.start_year !== '' ) {
      if ( year === module4Data.start_year ) {
        secondYear = module4Data.start_year;
      } else if ( module4Data.end_year !== undefined && module4Data.end_year !== '' ) {
        if ( ( parseFloat( secondYear ) + 1 ) <= module4Data.end_year ) {
          secondYear = parseFloat( secondYear ) + 1;
        } else {
          secondYear = '';
        }
      } else {
        secondYear = '';
      }
    } else {
      secondYear = '';
    }
    careerOutputBackend[year].Year = secondYear;

    // Tuition Value
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'] !== '' ) {
        tuitionVal = -incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'];
      } else {
        tuitionVal = 0;
      }
    } else {
      tuitionVal = 0;
    }
    careerOutputBackend[year]['Tuition Costs'] = tuitionVal;
    if ( tuitionVal > 0 ) {
      totalTuitionValue += tuitionVal;
    }

    // Room & Board
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined
                        && incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'] !== '' ) {
        roomBoardVal = -incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'];
      } else {
        roomBoardVal = 0;
      }
    } else {
      roomBoardVal = 0;
    }
    careerOutputBackend[year]['Room & Board '] = roomBoardVal;
    if ( roomBoardVal > 0 ) {
      totalRoomBoardValue += roomBoardVal;
    }

    // Rent Value
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Rent !== '' ) {
        rentVal = -incomeStatementnetData[year].livingExpenses.Rent;
      } else {
        rentVal = 0;
      }
    } else {
      rentVal = 0;
    }
    careerOutputBackend[year].Rent = rentVal;
    if ( rentVal > 0 ) {
      totalRentValue += rentVal;
    }

    // Calculation for Utilities
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Utilities !== '' ) {
        utilitiesVal = -incomeStatementnetData[year].livingExpenses.Utilities;
      } else {
        utilitiesVal = 0;
      }
    } else {
      utilitiesVal = 0;
    }
    careerOutputBackend[year].Utilities = utilitiesVal;
    if ( utilitiesVal > 0 ) {
      totalUtilitiesValue += utilitiesVal;
    }

    // k++;

    // Calculation for Off Campus Food
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Off Campus Food'] !== '' ) {
        offCampusFoodVal = -incomeStatementnetData[year].livingExpenses['Off Campus Food'];
      } else {
        offCampusFoodVal = 0;
      }
    } else {
      offCampusFoodVal = '';
    }
    careerOutputBackend[year]['Off Campus Food'] = offCampusFoodVal;

    // Calculation for Food
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Food !== '' ) {
        foodVal = -incomeStatementnetData[year].livingExpenses.Food;
      } else {
        foodVal = 0;
      }
    } else {
      foodVal = 0;
    }
    careerOutputBackend[year].Food = foodVal;
    if ( foodVal > 0 ) {
      totalFoodValue += foodVal;
    }

    // Calculation for Clothing
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Clothing !== '' ) {
        clothingVal = -incomeStatementnetData[year].livingExpenses.Clothing;
      } else {
        clothingVal = 0;
      }
    } else {
      clothingVal = '';
    }
    careerOutputBackend[year].Clothing = clothingVal;
    if ( clothingVal !== '' ) {
      totalClothingValue += clothingVal;
    }

    // Calculation for Entertainment
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Entertainment !== '' ) {
        entertainmentVal = -incomeStatementnetData[year].livingExpenses.Entertainment;
      } else {
        entertainmentVal = 0;
      }
    } else {
      entertainmentVal = '';
    }
    careerOutputBackend[year].Entertainment = entertainmentVal;
    if ( entertainmentVal !== '' ) {
      totalEntertainmentValue += entertainmentVal;
    }

    // Calculation for Technology
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Technology !== '' ) {
        technologyVal = -incomeStatementnetData[year].livingExpenses.Technology;
      } else {
        technologyVal = 0;
      }
    } else {
      technologyVal = '';
    }
    careerOutputBackend[year].Technology = technologyVal;
    if ( technologyVal !== '' ) {
      totalTechnologyValue += technologyVal;
    }

    // Calculation for Transportation
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Transportation !== '' ) {
        transportationVal = -incomeStatementnetData[year].livingExpenses.Transportation;
      } else {
        transportationVal = 0;
      }
    } else {
      transportationVal = '';
    }
    careerOutputBackend[year].Transportation = transportationVal;
    if ( transportationVal !== '' ) {
      totalTransportationValue += transportationVal;
    }

    // Calculation for Car Maintenance
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Car Maintenance'] !== '' ) {
        carMaintenanceVal = -incomeStatementnetData[year].livingExpenses['Car Maintenance'];
      } else {
        carMaintenanceVal = 0;
      }
    } else {
      carMaintenanceVal = '';
    }
    careerOutputBackend[year]['Car Maintenance'] = carMaintenanceVal;

    // Calculation for Car Insurance
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Car Insurance'] !== '' ) {
        carInsuranceVal = -incomeStatementnetData[year].livingExpenses['Car Insurance'];
      } else {
        carInsuranceVal = 0;
      }
    } else {
      carInsuranceVal = '';
    }
    careerOutputBackend[year]['Car Insurance'] = carInsuranceVal;

    // Calculation for Car payment
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Car Payment'] !== '' ) {
        carPaymentVal = -incomeStatementnetData[year].livingExpenses['Car Payment'];
      } else {
        carPaymentVal = 0;
      }
    } else {
      carPaymentVal = '';
    }
    careerOutputBackend[year]['Car Payment'] = carPaymentVal;

    // Calculation for Car
    if ( careerOutputBackend[year].Year !== '' ) {
      carVal = careerOutputBackend[year]['Car Maintenance'] + careerOutputBackend[year]['Car Insurance'] + careerOutputBackend[year]['Car Payment'];
    } else {
      carVal = '';
    }
    careerOutputBackend[year].Car = carVal;
    if ( carVal !== '' ) {
      totalCarValue += carVal;
    }

    // Calculation for Gas
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Gas !== '' ) {
        gasVal = -incomeStatementnetData[year].livingExpenses.Gas;
      } else {
        gasVal = 0;
      }
    } else {
      gasVal = '';
    }
    careerOutputBackend[year].Gas = gasVal;
    if ( gasVal !== '' ) {
      totalGasValue += gasVal;
    }

    // Calculation for Miscellaneous
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Miscellaneous !== '' ) {
        miscellaneousVal = -incomeStatementnetData[year].livingExpenses.Miscellaneous;
      } else {
        miscellaneousVal = 0;
      }
    } else {
      miscellaneousVal = '';
    }
    careerOutputBackend[year].Miscellaneous = miscellaneousVal;
    if ( miscellaneousVal !== '' ) {
      totalMiscellaneousValue += miscellaneousVal;
    }

    // Calculation for Health Insurance Deductible
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'] !== '' ) {
        healthInsuranceDeductibleVal = -incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'];
      } else {
        healthInsuranceDeductibleVal = 0;
      }
    } else {
      healthInsuranceDeductibleVal = '';
    }
    careerOutputBackend[year]['Health Insurance Deductible'] = healthInsuranceDeductibleVal;

    // Calculation for Health Insurance Premium
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Health Insurance Premium'] !== '' ) {
        healthInsurancePremiumVal = -incomeStatementnetData[year].livingExpenses['Health Insurance Premium'];
      } else {
        healthInsurancePremiumVal = 0;
      }
    } else {
      healthInsurancePremiumVal = '';
    }
    careerOutputBackend[year]['Health Insurance Premium'] = healthInsurancePremiumVal;

    // Calculation for Health Insurance
    healthInsuranceVal = 0;
    if ( careerOutputBackend[year].Year !== '' && healthInsuranceDeductibleVal !== '' && healthInsurancePremiumVal !== '' ) {
      healthInsuranceVal = careerOutputBackend[year]['Health Insurance Deductible'] + careerOutputBackend[year]['Health Insurance Premium'];
    } else {
      healthInsuranceVal = '';
    }
    careerOutputBackend[year]['Health Insurance'] = healthInsuranceVal;

    if ( healthInsuranceVal !== '' ) {
      totalHealthValue += healthInsuranceVal;
    }

    // Calculation for Children
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Children !== '' ) {
        childrenVal = -incomeStatementnetData[year].livingExpenses.Children;
      } else {
        childrenVal = 0;
      }
    } else {
      childrenVal = '';
    }
    careerOutputBackend[year].Children = childrenVal;
    if ( childrenVal !== '' ) {
      totalChildrenValue += childrenVal;
    }

    // Calculation for Pet
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Pet !== '' ) {
        petVal = -incomeStatementnetData[year].livingExpenses.Pet;
      } else {
        petVal = 0;
      }
    } else {
      petVal = '';
    }
    careerOutputBackend[year].Children = petVal;
    if ( petVal !== '' ) {
      totalPetValue += petVal;
    }

    // Calculation for Home property Expenses
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined ) {
        homePropertyExpensesVal = -( incomeStatementnetData[year].livingExpenses['Home Property Expenses'] + incomeStatementnetData[year].livingExpenses['Home Property Taxes'] );
      } else {
        homePropertyExpensesVal = 0;
      }
    } else {
      homePropertyExpensesVal = '';
    }
    careerOutputBackend[year]['Home Property Expenses'] = homePropertyExpensesVal;
    if ( homePropertyExpensesVal !== '' ) {
      totalHomePropertyExpensesValue += homePropertyExpensesVal;
    }

    // Calculation for Property Taxes
    if ( careerOutputBackend[year].Year !== '' ) {
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined ) {
        propertyTaxesVal = -( incomeStatementnetData[year].livingExpenses['Rental Operating Expenses'] + incomeStatementnetData[year].livingExpenses['Rental Property Taxes'] );
      } else {
        propertyTaxesVal = 0;
      }
    } else {
      propertyTaxesVal = '';
    }
    careerOutputBackend[year]['Property Taxes'] = propertyTaxesVal;
    if ( propertyTaxesVal !== '' ) {
      totalPropertyTaxesValue += propertyTaxesVal;
    }

    // Calculation Total value
    if ( careerOutputBackend[year].Year !== '' ) {
      totalValues = careerOutputBackend[year].Rent
                    + careerOutputBackend[year].Utilities
                    + careerOutputBackend[year].Food
                    + careerOutputBackend[year].Clothing
                    + careerOutputBackend[year].Entertainment
                    + careerOutputBackend[year].Technology
                    + careerOutputBackend[year].Transportation
                    + careerOutputBackend[year].Miscellaneous
                    + careerOutputBackend[year]['Health Insurance'];
    } else {
      totalValues = 0;
    }
    // console.log('totalValues',totalValues);
    careerOutputBackend[year].Total = parseFloat( totalValues );

    // Second Section
    if ( module4Data.start_year !== undefined && module4Data.start_year !== '' ) {
      if ( year === module4Data.start_year ) {
        thirdYear = module4Data.start_year;
      } else if ( module4Data.end_year !== undefined && module4Data.end_year !== '' && thirdYear !== undefined && thirdYear > 0 ) {
        if ( ( parseFloat( thirdYear ) + 1 ) <= module4Data.end_year ) {
          thirdYear = parseFloat( thirdYear ) + 1;
        } else {
          thirdYear = '';
        }
      } else {
        thirdYear = '';
      }
    } else {
      thirdYear = '';
    }

    careerOutputBackend[year]['Base Income'] = {};
    careerOutputBackend[year]['Bonus / Tips / Commission'] = {};
    careerOutputBackend[year]['Supplementary Income'] = {};
    careerOutputBackend[year].Taxes = {};
    careerOutputBackend[year]['Retirement Contributions'] = {};
    careerOutputBackend[year]['Living Expenses'] = {};
    careerOutputBackend[year].Depreciation = {};
    careerOutputBackend[year]['Interest Expenses'] = {};
    careerOutputBackend[year]['Net Income'] = {};

    if ( thirdYear !== '' ) {
      bar2GraphYearsList.push( year );

      // Calculate Scholarships
      // $rootScope.occupationTitle by sateesh
      let incomeBasicIncomeVal = 0;
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year]['Gross Income'] !== undefined
                         && incomeStatementnetData[year]['Gross Income'].occupationTitle !== undefined && incomeStatementnetData[year]['Gross Income'].occupationTitle !== '' ) {
        incomeBasicIncomeVal = incomeStatementnetData[year]['Gross Income'].occupationTitle;
      } else {
        incomeBasicIncomeVal = 0;
      }
      baseIncomeValArr.push( incomeBasicIncomeVal );

      // Calculate Bonus / Tips / Commission
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year]['Gross Income'] !== undefined
                         && incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined && incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'] !== '' ) {
        incomeBonusTipsVal = incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'];
      } else {
        incomeBonusTipsVal = 0;
      }
      incomeBonusTipsArr.push( incomeBonusTipsVal );

      // Calculate Supplementary Income Value
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year]['Gross Income'] !== undefined
                         && incomeStatementnetData[year]['Gross Income']['Supplementary Income'] !== undefined && incomeStatementnetData[year]['Gross Income']['Supplementary Income'] !== '' ) {
        incomeSupplementaryIncomeVal = incomeStatementnetData[year]['Gross Income']['Supplementary Income'];
      } else {
        incomeSupplementaryIncomeVal = 0;
      }
      incomeincomeSupplementaryIncomeArr.push( incomeSupplementaryIncomeVal );

      // Calculate Spouse Income
      let spouseIncomeVal = 0;
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year]['Gross Income'] !== undefined
                         && incomeStatementnetData[year]['Gross Income']['Spouses Income'] !== undefined && incomeStatementnetData[year]['Gross Income']['Spouses Income'] !== '' ) {
        spouseIncomeVal = incomeStatementnetData[year]['Gross Income']['Spouses Income'];
      } else {
        spouseIncomeVal = 0;
      }
      spouseIncomeArr.push( spouseIncomeVal );

      // Calculate Net Rental Income
      let netRentalIncomeVal = 0;
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year]['Gross Income'] !== undefined
                         && incomeStatementnetData[year]['Gross Income']['Rental Income'] !== undefined && incomeStatementnetData[year]['Gross Income']['Rental Income'] !== '' ) {
        netRentalIncomeVal = incomeStatementnetData[year]['Gross Income']['Rental Income'];
      } else {
        netRentalIncomeVal = 0;
      }
      netRentalIncomeArr.push( netRentalIncomeVal );

      // Calculate Taxes Value
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year]['Gross Income'] !== undefined
                         && incomeStatementnetData[year]['Total Taxes'] !== undefined && incomeStatementnetData[year]['Total Taxes'] !== '' ) {
        incomeTotalTaxesVal = -incomeStatementnetData[year]['Total Taxes'];
      } else {
        incomeTotalTaxesVal = 0;
      }
      totalTaxesArr.push( incomeTotalTaxesVal );

      // Calculate Retirement Contributions Value
      if ( cashFlowStatementnetData !== undefined && cashFlowStatementnetData !== '' && cashFlowStatementnetData[year] !== undefined && cashFlowStatementnetData[year]['Cash from Investing Activities'] !== undefined && cashFlowStatementnetData[year]['Cash from Investing Activities']['Retirement Contributions'] !== undefined && cashFlowStatementnetData[year]['Cash from Investing Activities']['Retirement Contributions'] !== '' ) {
        retirementContributionsVal = -cashFlowStatementnetData[year]['Cash from Investing Activities']['Retirement Contributions'];
      } else {
        retirementContributionsVal = 0;
      }
      retirementContributionsArr.push( retirementContributionsVal );

      totalLivingExpensesArr.push( careerOutputBackend[year].Total );

      // Loan Payments Value
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined
                         && incomeStatementnetData[year]['Total Interest Expenses'] !== undefined ) {
        totalLoanPaymentsValue1 = -incomeStatementnetData[year]['Total Interest Expenses'];
      } else {
        totalLoanPaymentsValue1 = 0;
      }

      totalLoanPaymentsValue = totalLoanPaymentsValue1;
      totalLoanPaymentsValueArr.push( totalLoanPaymentsValue );

      // Real Estate Expenses values
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined
                         && incomeStatementnetData[year]['Total Real Estate Expenses'] !== undefined ) {
        totalRealEstateExpensesValue = -incomeStatementnetData[year]['Total Real Estate Expenses'];
      } else {
        totalRealEstateExpensesValue = 0;
      }
      totalRealEstateExpensesArr.push( totalRealEstateExpensesValue );

      // Higher Education Expenses
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined
                         && incomeStatementnetData[year].livingExpenses !== undefined ) {
        if ( incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined ) {
          totalHigherEducationExpensesValue1 = incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'];
        }
        if ( incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'] !== undefined ) {
          totalHigherEducationExpensesValue2 = incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'];
        }
        if ( incomeStatementnetData[year].livingExpenses['Off Campus Food'] !== undefined ) {
          totalHigherEducationExpensesValue3 = incomeStatementnetData[year].livingExpenses['Off Campus Food'];
        }
      } else {
        totalHigherEducationExpensesValue1 = 0;
        totalHigherEducationExpensesValue2 = 0;
        totalHigherEducationExpensesValue3 = 0;
      }
      totalHigherEducationExpensesValue = -( totalHigherEducationExpensesValue1 + totalHigherEducationExpensesValue2 + totalHigherEducationExpensesValue3 );
      totalHigherEducationExpensesArr.push( totalHigherEducationExpensesValue );

      // Children Expenses
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined
                         && incomeStatementnetData[year].livingExpenses.Children !== undefined ) {
        totalChildrenExpensesValue = -incomeStatementnetData[year].livingExpenses.Children;
      } else {
        totalChildrenExpensesValue = 0;
      }
      totalChildrenExpensesArr.push( totalChildrenExpensesValue );

      // Pet Expenses
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined
                         && incomeStatementnetData[year].livingExpenses.Pet !== undefined ) {
        totalPetExpensesValue = -incomeStatementnetData[year].livingExpenses.Pet;
      } else {
        totalPetExpensesValue = 0;
      }
      totalPetExpensesArr.push( totalPetExpensesValue );

      // Car Expenses
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined
                         && incomeStatementnetData[year]['Total Car Expenses'] !== undefined ) {
        totalCarExpensesValue = -incomeStatementnetData[year]['Total Car Expenses'];
      } else {
        totalCarExpensesValue = 0;
      }
      totalCarExpensesArr.push( totalCarExpensesValue );

      // Calculate Depreciation Value
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined
                         && incomeStatementnetData[year]['Total Depreciation Expenses'] !== undefined && incomeStatementnetData[year]['Total Depreciation Expenses'] !== '' ) {
        totalDepreciationExpensesVal = -incomeStatementnetData[year]['Total Depreciation Expenses'];
      } else {
        totalDepreciationExpensesVal = 0;
      }
      totalDepreciationExpensesArr.push( totalDepreciationExpensesVal );

      // Calculate Interest Expenses Value
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined
                         && incomeStatementnetData[year]['Total Interest Expenses'] !== undefined && incomeStatementnetData[year]['Total Interest Expenses'] !== '' ) {
        totalInterestExpensesVal = -incomeStatementnetData[year]['Total Interest Expenses'];
      } else {
        totalInterestExpensesVal = 0;
      }
      totalInterestExpensesArr.push( totalInterestExpensesVal );

      // Calculate Net Income Value
      if ( incomeStatementnetData !== undefined && incomeStatementnetData !== ''
                         && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year].livingExpenses !== undefined
                         && incomeStatementnetData[year]['Net Income'] !== undefined && incomeStatementnetData[year]['Net Income'] !== '' ) {
        netIncomeVal = incomeStatementnetData[year]['Net Income'];
      } else {
        netIncomeVal = 0;
      }
      netIncomeArr.push( netIncomeVal );

      // 401k year list
      personalContributions401kYearsList.push( year );
      // Personal Contributions
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== ''
                        && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year] !== ''
                        && investmentsData['401k Second'][year]['Personal Contribution'] !== undefined && investmentsData['401k Second'][year]['Personal Contribution'] !== '' ) {
        personalContribution401k = investmentsData['401k Second'][year]['Personal Contribution'];
      } else {
        personalContribution401k = 0;
      }
      personalContributions401k.push( personalContribution401k );

      // Personal Earnings
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== ''
                        && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year] !== ''
                        && investmentsData['401k Second'][year]['Personal Earnings'] !== undefined && investmentsData['401k Second'][year]['Personal Earnings'] !== '' ) {
        personalEarning401k = investmentsData['401k Second'][year]['Personal Earnings'];
      } else {
        personalEarning401k = 0;
      }
      personalEarnings401k.push( Math.round( personalEarning401k ) );

      // Employer Contributions
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== ''
                        && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year] !== ''
                        && investmentsData['401k Second'][year]['Employer Contribution'] !== undefined && investmentsData['401k Second'][year]['Employer Contribution'] !== '' ) {
        employerContribution401k = investmentsData['401k Second'][year]['Employer Contribution'];
      } else {
        employerContribution401k = 0;
      }
      employerContributions401k.push( Math.round( employerContribution401k ) );

      // Employer Earnings
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== ''
                        && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year] !== ''
                        && investmentsData['401k Second'][year]['Employer Earnings'] !== undefined && investmentsData['401k Second'][year]['Employer Earnings'] !== '' ) {
        employerEarning401k = investmentsData['401k Second'][year]['Employer Earnings'];
      } else {
        employerEarning401k = 0;
      }
      employerEarnings401k.push( Math.round( employerEarning401k ) );

      // Employer Vesting Reductions
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== ''
                        && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year] !== ''
                        && investmentsData['401k Second'][year]['Vesting Reductions'] !== undefined && investmentsData['401k Second'][year]['Vesting Reductions'] !== '' ) {
        employerVestingReduction401k = investmentsData['401k Second'][year]['Vesting Reductions'];
      } else {
        employerVestingReduction401k = 0;
      }
      employerVestingReductions401k.push( Math.round( employerVestingReduction401k ) );

      // Employer Ending Balance
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['401k Second'] !== undefined && investmentsData['401k Second'] !== ''
                        && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year] !== ''
                        && investmentsData['401k Second'][year]['Ending Total Balance'] !== undefined && investmentsData['401k Second'][year]['Ending Total Balance'] !== '' ) {
        employerEndingBalance401k = investmentsData['401k Second'][year]['Ending Total Balance'];
      } else {
        employerEndingBalance401k = 0;
      }
      endingBalance401k.push( Math.round( employerEndingBalance401k ) );

      // Roth IRA year list
      rothIraYearsList.push( year );

      // Roth IRA Personal Contribution
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Roth IRA Second'] !== undefined && investmentsData['Roth IRA Second'] !== ''
                        && investmentsData['Roth IRA Second'][year] !== undefined && investmentsData['Roth IRA Second'][year] !== ''
                        && investmentsData['Roth IRA Second'][year]['Annual Contribution'] !== undefined && investmentsData['Roth IRA Second'][year]['Annual Contribution'] !== '' ) {
        rothIraPersonalContribution = investmentsData['Roth IRA Second'][year]['Annual Contribution'];
      } else {
        rothIraPersonalContribution = 0;
      }
      rothIraPersonalContributions.push( Math.round( rothIraPersonalContribution ) );

      // Roth IRA Earnings
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Roth IRA Second'] !== undefined && investmentsData['Roth IRA Second'] !== ''
                        && investmentsData['Roth IRA Second'][year] !== undefined && investmentsData['Roth IRA Second'][year] !== ''
                        && investmentsData['Roth IRA Second'][year].Earnings !== undefined && investmentsData['Roth IRA Second'][year].Earnings !== '' ) {
        rothIraPersonalEarning = investmentsData['Roth IRA Second'][year].Earnings;
      } else {
        rothIraPersonalEarning = 0;
      }
      rothIraPersonalEarnings.push( Math.round( rothIraPersonalEarning ) );

      // Roth IRA Ending Balance
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Roth IRA Second'] !== undefined && investmentsData['Roth IRA Second'] !== ''
                        && investmentsData['Roth IRA Second'][year] !== undefined && investmentsData['Roth IRA Second'][year] !== ''
                        && investmentsData['Roth IRA Second'][year]['Ending Balance'] !== undefined && investmentsData['Roth IRA Second'][year]['Ending Balance'] !== '' ) {
        rothIraEndingBalanceVal = investmentsData['Roth IRA Second'][year]['Ending Balance'];
      } else {
        rothIraEndingBalanceVal = 0;
      }
      rothIraEndingBalance.push( Math.round( rothIraEndingBalanceVal ) );

      // SEP ira year list
      sepIraYearsList.push( year );

      // SEP iraPersonal Contribution
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'] !== ''
                        && investmentsData['Seph IRA Second'][year] !== undefined && investmentsData['Seph IRA Second'][year] !== ''
                        && investmentsData['Seph IRA Second'][year]['Annual Contribution'] !== undefined && investmentsData['Seph IRA Second'][year]['Annual Contribution'] !== '' ) {
        sepIraPersonalContribution = investmentsData['Seph IRA Second'][year]['Annual Contribution'];
      } else {
        sepIraPersonalContribution = 0;
      }
      sepIraPersonalContributions.push( Math.round( sepIraPersonalContribution ) );

      // Seph IRA Earnings
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'] !== ''
                        && investmentsData['Seph IRA Second'][year] !== undefined && investmentsData['Seph IRA Second'][year] !== ''
                        && investmentsData['Seph IRA Second'][year].Earnings !== undefined && investmentsData['Seph IRA Second'][year].Earnings !== '' ) {
        sepIraPersonalEarning = investmentsData['Seph IRA Second'][year].Earnings;
      } else {
        sepIraPersonalEarning = 0;
      }
      sepIraPersonalEarnings.push( Math.round( sepIraPersonalEarning ) );

      // Seph IRA Ending Balance
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'] !== ''
                        && investmentsData['Seph IRA Second'][year] !== undefined && investmentsData['Seph IRA Second'][year] !== ''
                        && investmentsData['Seph IRA Second'][year]['Ending Balance'] !== undefined && investmentsData['Seph IRA Second'][year]['Ending Balance'] !== '' ) {
        sepIraEndingBalanceVal = investmentsData['Seph IRA Second'][year]['Ending Balance'];
      } else {
        sepIraEndingBalanceVal = 0;
      }
      sepIraEndingBalance.push( Math.round( sepIraEndingBalanceVal ) );

      // pension
      pensionYearsList.push( year );

      // Personal Contributation
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'] !== ''
                        && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year] !== ''
                        && investmentsData['Pension Second'][year]['Personal Contribution'] !== undefined && investmentsData['Pension Second'][year]['Personal Contribution'] !== '' ) {
        pensionPersonalContribution = investmentsData['Pension Second'][year]['Personal Contribution'];
      } else {
        pensionPersonalContribution = 0;
      }
      pensionPersonalContributions.push( Math.round( pensionPersonalContribution ) );

      // Employer Contributation
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'] !== ''
                        && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year] !== ''
                        && investmentsData['Pension Second'][year]['Employer Contribution'] !== undefined && investmentsData['Pension Second'][year]['Employer Contribution'] !== '' ) {
        pensionEmployerContribution = investmentsData['Pension Second'][year]['Employer Contribution'];
      } else {
        pensionEmployerContribution = 0;
      }
      pensionEmployerContributions.push( Math.round( pensionEmployerContribution ) );

      // Earnings
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'] !== ''
                        && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year] !== ''
                        && investmentsData['Pension Second'][year].Earnings !== undefined && investmentsData['Pension Second'][year].Earnings !== '' ) {
        pensionEarning = investmentsData['Pension Second'][year].Earnings;
      } else {
        pensionEarning = 0;
      }
      pensionEarnings.push( Math.round( pensionEarning ) );

      // Ending Balance
      if ( investmentsData !== undefined && investmentsData !== ''
                        && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'] !== ''
                        && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year] !== ''
                        && investmentsData['Pension Second'][year]['Ending Balance'] !== undefined && investmentsData['Pension Second'][year]['Ending Balance'] !== '' ) {
        pensionEndingBalanceVal = investmentsData['Pension Second'][year]['Ending Balance'];
      } else {
        pensionEndingBalanceVal = 0;
      }
      pensionEndingBalance.push( Math.round( pensionEndingBalanceVal ) );
    }
  }

  if ( module4Data.annualTotalTaxesPaidCareerPath !== undefined && module4Data.annualTotalTaxesPaidCareerPath !== '' ) {
    annualTotalTaxesPaid = -module4Data.annualTotalTaxesPaidCareerPath;
  }
  // if( typeof module4Data.careerPathLivingExpenses !== undefined && module4Data.careerPathLivingExpenses !== ''){
  //   annualLivingExpensesPaid = -module4Data.careerPathLivingExpenses;
  // }
  if ( module4Data.careerPathHigherEducationExpenses !== undefined && module4Data.careerPathHigherEducationExpenses !== '' ) {
    annualHigherEducationExpensesPaid = -module4Data.careerPathHigherEducationExpenses;
  }
  if ( module4Data.careerPathChildrenExpenses !== undefined && module4Data.careerPathChildrenExpenses !== '' ) {
    annualChildrenExpenses = -module4Data.careerPathChildrenExpenses;
  }
  if ( module4Data.careerPathPetExpenses !== undefined && module4Data.careerPathPetExpenses !== '' ) {
    annualPetExpenses = -module4Data.careerPathPetExpenses;
  }
  if ( module4Data.careerPathCarExpenses !== undefined && module4Data.careerPathCarExpenses !== '' ) {
    annualCarExpenses = -module4Data.careerPathCarExpenses;
  }
  if ( module4Data.careerPathHomeExpenses !== undefined && module4Data.careerPathHomeExpenses !== '' ) {
    annualHomeExpenses = -module4Data.careerPathHomeExpenses;
  }
  if ( module4Data.careerPathFinancingExpenses !== undefined && module4Data.careerPathFinancingExpenses !== '' ) {
    annualLoanPayments = -module4Data.careerPathFinancingExpenses;
  }
  if ( module4Data.annualRetirementContributionsCareerPath !== undefined && module4Data.annualRetirementContributionsCareerPath !== '' ) {
    retirementContributionsValue = module4Data.annualRetirementContributionsCareerPath;
  }

  if ( expenseCalculationsData !== undefined && expenseCalculationsData !== '' && expenseCalculationsData['Career Path'] !== undefined
    && expenseCalculationsData['Career Path'] !== '' && module4Data.start_year !== undefined && module4Data.start_year !== ''
    && expenseCalculationsData['Career Path'][module4Data.start_year] !== undefined && expenseCalculationsData['Career Path'][module4Data.start_year].Yearly !== undefined
    && expenseCalculationsData['Career Path'][module4Data.start_year].Yearly !== '' ) {
    const careerExpenses = expenseCalculationsData['Career Path'][module4Data.start_year].Yearly;
    if ( careerExpenses.Rent !== undefined ) {
      annualRentPaid = careerExpenses.Rent;
    }
    if ( careerExpenses.Utilities !== undefined ) {
      annualUtilitiesPaid = careerExpenses.Utilities;
    }
    if ( careerExpenses.Food !== undefined ) {
      annualFoodPaid = careerExpenses.Food;
    }
    if ( careerExpenses.Clothing !== undefined ) {
      annualClothingPaid = careerExpenses.Clothing;
    }
    if ( careerExpenses.Entertainment !== undefined ) {
      annualEntertainmentPaid = careerExpenses.Entertainment;
    }

    if ( careerExpenses.Technology !== undefined ) {
      annualTechnologyPaid = careerExpenses.Technology;
    }
    if ( careerExpenses.Transportation !== undefined ) {
      annualTransportationPaid = careerExpenses.Transportation;
    }
    if ( careerExpenses.Miscellaneous !== undefined ) {
      annualMiscellaneousPaid = careerExpenses.Miscellaneous;
    }
    if ( careerExpenses['Health Insurance Costs outside Premium'] !== undefined ) {
      annualHealthcarePaid += careerExpenses['Health Insurance Costs outside Premium'];
    }
    if ( careerExpenses['Health Insurance Premium'] !== undefined ) {
      annualHealthcarePaid += careerExpenses['Health Insurance Premium'];
    }
  }

  function carreOutputBackendData() {
    const graphvaluesData = {};
    graphvaluesData['Pie Chart'] = {};
    graphvaluesData['Pie Chart'].totalTuitionCostsValue = totalTuitionValue;
    graphvaluesData['Pie Chart'].totalRoomBoardValue = totalRoomBoardValue;
    graphvaluesData['Pie Chart'].totalRentValue = totalRentValue;
    graphvaluesData['Pie Chart'].totalUtilitiesValue = totalUtilitiesValue;
    graphvaluesData['Pie Chart'].totalFoodValue = totalFoodValue;
    graphvaluesData['Pie Chart'].totalClothingValue = totalClothingValue;
    graphvaluesData['Pie Chart'].totalEntertainmentValue = totalEntertainmentValue;
    graphvaluesData['Pie Chart'].totalTechnologyValue = totalTechnologyValue;
    graphvaluesData['Pie Chart'].totalTransportationValue = totalTransportationValue;
    graphvaluesData['Pie Chart'].totalCarValue = totalCarValue;
    graphvaluesData['Pie Chart'].totalGasValue = totalGasValue;
    graphvaluesData['Pie Chart'].totalMiscellaneousValue = totalMiscellaneousValue;
    graphvaluesData['Pie Chart'].totalHealthInsuranceValue = totalHealthValue;
    graphvaluesData['Pie Chart'].totalChildrenValue = totalChildrenValue;
    graphvaluesData['Pie Chart'].totalPetValue = totalPetValue;
    graphvaluesData['Pie Chart'].totalHomePropertyExpensesValue = totalHomePropertyExpensesValue;
    graphvaluesData['Pie Chart'].totalPropertyTaxesValue = totalPropertyTaxesValue;

    // Total Amount
    graphvaluesData['Pie Chart'].Total = parseFloat( totalRentValue )
                + parseFloat( totalUtilitiesValue )
                + parseFloat( totalFoodValue )
                + parseFloat( totalClothingValue )
                + parseFloat( totalEntertainmentValue )
                + parseFloat( totalTechnologyValue )
                + parseFloat( totalTransportationValue )
                + parseFloat( totalMiscellaneousValue )
                + parseFloat( totalHealthValue );

    graphvaluesData['Annual Chart'] = {};
    graphvaluesData['Annual Chart']['Total Taxes Paid'] = annualTotalTaxesPaid;
    graphvaluesData['Annual Chart'].Rent = annualRentPaid;
    graphvaluesData['Annual Chart'].Utilities = annualUtilitiesPaid;
    graphvaluesData['Annual Chart'].Food = annualFoodPaid;
    graphvaluesData['Annual Chart']['Clothing & Personal Care'] = annualClothingPaid;
    graphvaluesData['Annual Chart'].Entertainment = annualEntertainmentPaid;
    graphvaluesData['Annual Chart'].Technology = annualTechnologyPaid;
    graphvaluesData['Annual Chart'].Transportation = annualTransportationPaid;
    graphvaluesData['Annual Chart'].Miscellaneous = annualMiscellaneousPaid;
    graphvaluesData['Annual Chart']['Healthcare & Insurance'] = annualHealthcarePaid;
    graphvaluesData['Annual Chart']['Higher Education Expenses'] = annualHigherEducationExpensesPaid;
    graphvaluesData['Annual Chart']['Children Expenses'] = annualChildrenExpenses;
    graphvaluesData['Annual Chart']['Pet Expenses'] = annualPetExpenses;
    graphvaluesData['Annual Chart']['Car Expenses'] = annualCarExpenses;
    graphvaluesData['Annual Chart']['Real Estate Expenses'] = annualHomeExpenses;
    graphvaluesData['Annual Chart']['Loan Payments'] = annualLoanPayments;
    graphvaluesData['Annual Chart']['Retirement Contributions'] = retirementContributionsValue;
    graphvaluesData['Annual Chart'].Total = parseFloat( annualTotalTaxesPaid )
+ parseFloat( annualRentPaid )
+ parseFloat( annualUtilitiesPaid )
+ parseFloat( annualFoodPaid )
+ parseFloat( annualClothingPaid )
+ parseFloat( annualEntertainmentPaid )
+ parseFloat( annualTechnologyPaid )
+ parseFloat( annualTransportationPaid )
+ parseFloat( annualMiscellaneousPaid )
+ parseFloat( annualHealthcarePaid )
+ parseFloat( annualHigherEducationExpensesPaid )
+ parseFloat( annualChildrenExpenses )
+ parseFloat( annualPetExpenses )
+ parseFloat( annualCarExpenses )
+ parseFloat( annualHomeExpenses )
+ parseFloat( annualLoanPayments )
+ parseFloat( retirementContributionsValue );

    graphvaluesData['Bar Graph'] = {};
    graphvaluesData['Bar Graph'].YearsList = bar2GraphYearsList;
    graphvaluesData['Bar Graph']['Base Income'] = baseIncomeValArr;
    graphvaluesData['Bar Graph']['Bonus / Tips / Commission'] = incomeBonusTipsArr;
    graphvaluesData['Bar Graph']['Supplementary Income'] = incomeincomeSupplementaryIncomeArr;
    graphvaluesData['Bar Graph']['Net Rental Income'] = netRentalIncomeArr;
    graphvaluesData['Bar Graph']['Spouse Income'] = spouseIncomeArr;
    graphvaluesData['Bar Graph'].Taxes = totalTaxesArr;
    graphvaluesData['Bar Graph']['Retirement Contributions'] = retirementContributionsArr;
    graphvaluesData['Bar Graph']['Living Expenses'] = totalLivingExpensesArr;
    graphvaluesData['Bar Graph']['Loan Payments'] = totalLoanPaymentsValueArr;
    graphvaluesData['Bar Graph']['Real Estate Expenses'] = totalRealEstateExpensesArr;
    graphvaluesData['Bar Graph']['Higher Education Expenses'] = totalHigherEducationExpensesArr;
    graphvaluesData['Bar Graph']['Children Expenses'] = totalChildrenExpensesArr;
    graphvaluesData['Bar Graph']['Pet Expenses'] = totalPetExpensesArr;
    graphvaluesData['Bar Graph']['Car Expenses'] = totalCarExpensesArr;
    graphvaluesData['Bar Graph'].Depreciation = totalDepreciationExpensesArr;
    graphvaluesData['Bar Graph']['Interest Expenses'] = totalInterestExpensesArr;
    graphvaluesData['Bar Graph']['Net Income'] = netIncomeArr;

    graphvaluesData['401k Bar Graph'] = {};
    graphvaluesData['401k Bar Graph'].YearsList = personalContributions401kYearsList;
    graphvaluesData['401k Bar Graph']['Personal Contributions'] = personalContributions401k;
    graphvaluesData['401k Bar Graph']['Personal Earnings'] = personalEarnings401k;
    graphvaluesData['401k Bar Graph']['Employer Contributions'] = employerContributions401k;
    graphvaluesData['401k Bar Graph']['Employer Earnings'] = employerEarnings401k;
    graphvaluesData['401k Bar Graph']['Employer Vesting Reductions'] = employerVestingReductions401k;
    graphvaluesData['401k Bar Graph']['Ending Balance'] = endingBalance401k;

    // Roth IRA
    graphvaluesData['Roth IRA'] = {};
    graphvaluesData['Roth IRA'].YearsList = rothIraYearsList;
    graphvaluesData['Roth IRA']['Personal Contributions'] = rothIraPersonalContributions;
    graphvaluesData['Roth IRA'].Earnings = rothIraPersonalEarnings;
    graphvaluesData['Roth IRA']['Ending Balance'] = rothIraEndingBalance;

    // SEP IRA
    graphvaluesData['SEP IRA'] = {};
    graphvaluesData['SEP IRA'].YearsList = sepIraYearsList;
    graphvaluesData['SEP IRA']['Personal Contributions'] = sepIraPersonalContributions;
    graphvaluesData['SEP IRA'].Earnings = sepIraPersonalEarnings;
    graphvaluesData['SEP IRA']['Ending Balance'] = sepIraEndingBalance;

    // Pension
    graphvaluesData.Pension = {};
    graphvaluesData.Pension.YearsList = pensionYearsList;
    graphvaluesData.Pension['Personal Contributions'] = pensionPersonalContributions;
    graphvaluesData.Pension['Employer Contributions'] = pensionEmployerContributions;
    graphvaluesData.Pension.Earnings = pensionEarnings;
    graphvaluesData.Pension['Ending Balance'] = pensionEndingBalance;
    return graphvaluesData;
  }
  return carreOutputBackendData();
}
export default CareerOutputBackendSheetData;
