import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';
import * as moduleServices from '../../../calculations/modules-services';

const TotalMoneyNeeded = ( props ) => {
  const {
    classes, inputData, handleSlide
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>
      Other expenses can factor into paying for higher education. Check out the table below to see what other expenses need to be paid for during your higher education.
      </p>

      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ classes.higherEducationStepFour }>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className={ classes.trHead }>
                <td colSpan={ 2 } align="center">Money Needed</td>
              </tr>
              <tr aria-hidden="true" onClick={()=>handleSlide(6,'back')} className={classes.cursorPointer}>
                <td>Tuition & Fees</td>
                <td>
                  <div className={ classes.amountDollar }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newTotalTuitionAndFees } prefix={ moduleServices.module15Data.newTotalTuitionAndFees >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newTotalTuitionAndFees < 0 && ')' } />
                  </div>
                </td>
              </tr>
              {inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 && (
                <tr aria-hidden="true" onClick={()=>handleSlide(6,'back')} className={classes.cursorPointer}>
                  <td>Room & Board</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newTotalRoomAndBoard } prefix={ moduleServices.module15Data.newTotalRoomAndBoard >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newTotalRoomAndBoard < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              {inputData.type_of_higher_education !== 1 && inputData.type_of_higher_education !== 3 && (
                <tr aria-hidden="true" onClick={()=>handleSlide(8,'back')} className={classes.cursorPointer}>
                  <td>On-Campus Living Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newOnCampusLivingExpenses } prefix={ moduleServices.module15Data.newOnCampusLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newOnCampusLivingExpenses < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              <tr aria-hidden="true" onClick={()=>handleSlide(8,'back')} className={classes.cursorPointer}>
                <td>Off-Campus Living Expenses</td>
                <td>
                  <div className={ classes.amountDollar }>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newOffCampusLivingExpenses } prefix={ moduleServices.module15Data.newOffCampusLivingExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newOffCampusLivingExpenses < 0 && ')' } />
                  </div>
                </td>
              </tr>
              {moduleServices.module15Data.newHigherTotalStudentsLoanPayments > 0 && (
                <tr>
                  <td>Total Student Loan Payments during College</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newHigherTotalStudentsLoanPayments } prefix={ moduleServices.module15Data.newHigherTotalStudentsLoanPayments >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newHigherTotalStudentsLoanPayments < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}

              {moduleServices.module15Data.newHigherOtherLoanPaymentsonOtherDebt > 0 && (
                <tr>
                  <td>Other Interest Expenses on Other Debt</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newHigherOtherLoanPaymentsonOtherDebt } prefix={ moduleServices.module15Data.newHigherOtherLoanPaymentsonOtherDebt >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newHigherOtherLoanPaymentsonOtherDebt < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}

              {moduleServices.module15Data.newHigherCarExpense > 0 && (
                <tr>
                  <td>Car Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newHigherCarExpense } prefix={ moduleServices.module15Data.newHigherCarExpense >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newHigherCarExpense < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              {moduleServices.module15Data.newHigherRealEstateExpenses > 0 && (
                <tr>
                  <td>Real Estate Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newHigherRealEstateExpenses } prefix={ moduleServices.module15Data.newHigherRealEstateExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newHigherRealEstateExpenses < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}
              {moduleServices.module15Data.newHigherChildrenAndPetExpenses > 0 && (
                <tr>
                  <td>Children and Pet Expenses</td>
                  <td>
                    <div className={ classes.amountDollar }>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.newHigherChildrenAndPetExpenses } prefix={ moduleServices.module15Data.newHigherChildrenAndPetExpenses >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.newHigherChildrenAndPetExpenses < 0 && ')' } />
                    </div>
                  </td>
                </tr>
              )}

              <tr className={ classes.trFoot }>
                <td className={ classes.totalBoldText }>
                  Total Cost of Attendance
                </td>
                <td>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module15Data.educationTotalAttendance } prefix={ moduleServices.module15Data.educationTotalAttendance >= 0 ? '$' : '($' } suffix={ moduleServices.module15Data.educationTotalAttendance < 0 && ')' } />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

TotalMoneyNeeded.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  handleSlide: PropTypes.func.isRequired,
};

export default withStyles( styles )( TotalMoneyNeeded );
