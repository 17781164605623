function studentLoanTaxSheet( module1Data, module10Data, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, privateHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, privateMoreHigherEdLoanData, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, privateNewHigherEdLoanData, fedSubExistingLoanData, fedUnSubExistingLoanData, privateExistingLoanData, taxStatementData, spouseStudentLoanData ) {
  let year1;
  let studentLoanTaxesData = {};
  let interestPaidData = {};
  let studentLoanCompleteData = {};
  year1 = parseInt( module1Data.start_year, 10 );
  const yearLimit = parseInt( module1Data.start_year, 10 ) + 15;

  const maxDeduction = 2500;
  let interestPaid1 = 0;
  let interestPaid2 = 0;
  let interestPaid3 = 0;
  let interestPaid4 = 0;
  let interestPaid5 = 0;
  let interestPaid6 = 0;
  let interestPaid7 = 0;
  let interestPaid8 = 0;
  let interestPaid9 = 0;
  let interestPaid10 = 0;
  let interestPaid11 = 0;
  let interestPaid12 = 0;
  let interestPaid13 = 0;
  let interestPaid = 0;

  // Interest Array
  const interestArray = [0, 0, 0];

  // Single Range Arrays
  const singleRangeArray = [0, 65000, 80001];
  const singleRange2Array = [65000, 80000, 0];

  // Married Range Arrays
  const marriedRangeArray = [0, 135000, 165001];
  const marriedRange2Array = [135000, 165000, 0];

  // const singleIncome = 0;
  const singleDataArray = [];
  const singleIncome = 0;
  interestArray.forEach( ( interest, index ) => {
    const returnData = {};
    returnData.Interest = interest;
    returnData.Range = singleRangeArray[index];
    returnData.Range2 = singleRange2Array[index];
    if ( index < 2 ) {
      returnData.Difference = singleRange2Array[index] - singleRangeArray[index];
    } else {
      returnData.Difference = 0;
    }

    if ( index === 1 ) {
      returnData.Divisor = 15000;
    } else {
      returnData.Divisor = 0;
    }

    if ( index === 0 ) {
      returnData.Deduction = returnData.Interest;
    } else if ( index === 1 ) {
      if ( singleIncome < singleDataArray[index - 1].Range2 ) {
        returnData.Deduction = 0;
      } else if ( returnData.Difference !== 0 ) {
        returnData.Deduction = returnData.Interest - ( returnData.Interest * ( returnData.Difference / returnData.Divisor ) );
      } else {
        returnData.Deduction = 0;
      }
    } else {
      returnData.Deduction = 0;
    }
    singleDataArray.push( returnData );
  } );

  const marriedIncome = 0;
  const marriedDataArray = [];
  interestArray.forEach( ( interest, index ) => {
    const returnData = {};
    returnData.Interest = interest;
    returnData.Range = marriedRangeArray[index];
    returnData.Range2 = marriedRange2Array[index];
    if ( index === 0 ) {
      returnData.Difference = 9075;
    } else if ( index === 1 ) {
      returnData.Difference = marriedRange2Array[index] - marriedRangeArray[index];
    } else {
      returnData.Difference = 0;
    }

    if ( index === 1 ) {
      returnData.Divisor = 30000;
    } else {
      returnData.Divisor = 0;
    }

    if ( index === 0 ) {
      if ( returnData.Range2 > 130000 ) {
        returnData.Deduction = 0;
      } else {
        returnData.Deduction = returnData.Interest;
      }
    } else if ( index === 1 ) {
      if ( returnData.Range2 > 130000 ) {
        returnData.Deduction = 0;
      } else if ( marriedIncome < returnData[index - 1].Range2 ) {
        returnData.Deduction = 0;
      } else if ( returnData.Difference !== 0 ) {
        returnData.Deduction = returnData.Interest - ( returnData.Interest * ( returnData.Difference / returnData.Divisor ) );
      } else {
        returnData.Deduction = 0;
      }
    } else if ( marriedIncome >= returnData.Range ) {
      returnData.Deduction = 0;
    } else {
      returnData.Deduction = 0;
    }
    marriedDataArray.push( returnData );
  } );

  function singleCalculation( year, income ) {
    // Final Deduction
    let finalDeduction = 0;

    let singleIteration1Array = {};
    singleIteration1Array = interestArray.map( ( interest, index ) => {
      const returnData = {};
      returnData[index] = {};

      if ( typeof spouseStudentLoanData !== 'undefined' && typeof spouseStudentLoanData['Summation Data'] !== 'undefined'
        && typeof spouseStudentLoanData['Summation Data'][year] !== 'undefined' && typeof spouseStudentLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && spouseStudentLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid1 = spouseStudentLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid1 = 0;
      }
      if ( typeof fedSubExistingLoanData !== 'undefined' && typeof fedSubExistingLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubExistingLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubExistingLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubExistingLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid2 = fedSubExistingLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid2 = 0;
      }
      if ( typeof fedUnSubExistingLoanData !== 'undefined' && typeof fedUnSubExistingLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubExistingLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid3 = fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest + fedUnSubExistingLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid3 = 0;
      }
      if ( typeof privateExistingLoanData !== 'undefined' && typeof privateExistingLoanData['Summation Data'] !== 'undefined'
        && typeof privateExistingLoanData['Summation Data'][year] !== 'undefined' && typeof privateExistingLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateExistingLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid4 = privateExistingLoanData['Summation Data'][year].LaggingInterest + privateExistingLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid4 = 0;
      }
      if ( typeof fedSubHigherEdLoanData !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid5 = fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid5 = 0;
      }
      if ( typeof fedUnSubHigherEdLoanData !== 'undefined' && typeof fedUnSubHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid6 = fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid6 = 0;
      }
      if ( typeof privateHigherEdLoanData !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof privateHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid7 = privateHigherEdLoanData['Summation Data'][year].LaggingInterest + privateHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid7 = 0;
      }
      if ( typeof fedSubMoreHigherEdLoanData !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid8 = fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid8 = 0;
      }
      if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid9 = fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid9 = 0;
      }
      if ( typeof privateMoreHigherEdLoanData !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid10 = privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest + privateMoreHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid10 = 0;
      }
      if ( typeof fedSubNewHigherEdLoanData !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubNewHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid11 = fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid11 = 0;
      }
      if ( typeof fedUnSubNewHigherEdLoanData !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubNewHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid12 = fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubNewHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid12 = 0;
      }
      if ( typeof privateNewHigherEdLoanData !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof privateNewHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid13 = privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest + privateNewHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid13 = 0;
      }
	  
      interestPaid = interestPaid1 + interestPaid2 + interestPaid3 + interestPaid4 + interestPaid5 + interestPaid6 + interestPaid7 + interestPaid8 + interestPaid9 + interestPaid10 + interestPaid11 + interestPaid12 + interestPaid13;
	  returnData[index].Interest = interestPaid;

      // Range
      let range = 0;
      if ( income >= singleDataArray[index].Range ) {
        range = singleDataArray[index].Range;
      } else {
        range = 0;
      }
      returnData[index].Range = range;

      // Range2
      let range2 = 0;
      if ( index < 2 ) {
        if ( income >= singleDataArray[index].Range2 ) {
          range2 = singleDataArray[index].Range2;
        } else if ( income <= singleDataArray[index].Range2 && income >= singleDataArray[index].Range ) {
          range2 = income;
        } else {
          range2 = 0;
        }
      } else {
        range2 = 0;
      }
      returnData[index].Range2 = range2;

      // Difference
      returnData[index].Difference = range2 - range;

      // Divisor
      returnData[index].Divisor = singleDataArray[index].Divisor;

      // Deduction
      if ( index === 0 ) {
        if ( returnData[index].Difference >= singleDataArray[index].Range2 ) {
          returnData[index].Deduction = 0;
        } else {
          returnData[index].Deduction = returnData[index].Interest;
        }
      } else if ( index === 1 ) {
        if ( returnData[index].Difference >= singleDataArray[index].Difference ) {
          returnData[index].Deduction = 0;
        } else {
			if ( income < singleDataArray[index - 1].Range2 ) {
			  returnData[index].Deduction = 0;
			} else {
				if ( singleDataArray[index].Difference !== 0 ) {
				  returnData[index].Deduction = returnData[index].Interest - ( returnData[index].Interest * ( returnData[index].Difference / returnData[index].Divisor ) );
				} else {
				  returnData[index].Deduction = 0;
				}
			}
		}
      } else {
        returnData[index].Deduction = 0;
      }
      finalDeduction += returnData[index].Deduction;
      return returnData;
    } );

    let finalVal = 0;
    if ( typeof module10Data !== 'undefined' && typeof module10Data.marriege_year !== 'undefined' && module10Data.marriege_year > 0
      && year >= module10Data.marriege_year ) {
      finalVal = 0;
    } else {
      finalVal = finalDeduction;
    }

    const data = {};
    data.Income = income;
    data['Final Deduction'] = finalVal;
    data['Iteration Data'] = singleIteration1Array;
    data['Max Deduction'] = maxDeduction;

    return data;
  }
  function marriedCalculation( year, income ) {
    // Final Deduction
    let finalDeduction = 0;

    let marriedIteration1Array = {};
    marriedIteration1Array = interestArray.map( ( interest, index ) => {
      const returnData = {};

      // Interest
      // const InterestCalVal = 0;
      // const IncomeStatement = 0;
      // const studentLoan = 0;
      // const studentLoan1 = 0;
      // const studentLoan2 = 0;
      // const studentLoan3 = 0;
      // const studentLoan4 = 0;
      // const studentLoan5 = 0;
      // const studentLoan6 = 0;
      // const studentLoan7 = 0;
      // For Interest Paid Data
      if ( typeof spouseStudentLoanData !== 'undefined' && typeof spouseStudentLoanData['Summation Data'] !== 'undefined'
        && typeof spouseStudentLoanData['Summation Data'][year] !== 'undefined' && typeof spouseStudentLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && spouseStudentLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid1 = spouseStudentLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid1 = 0;
      }
      if ( typeof fedSubExistingLoanData !== 'undefined' && typeof fedSubExistingLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubExistingLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubExistingLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubExistingLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid2 = fedSubExistingLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid2 = 0;
      }
      if ( typeof fedUnSubExistingLoanData !== 'undefined' && typeof fedUnSubExistingLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubExistingLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid3 = fedUnSubExistingLoanData['Summation Data'][year].LaggingInterest + fedUnSubExistingLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid3 = 0;
      }
      if ( typeof privateExistingLoanData !== 'undefined' && typeof privateExistingLoanData['Summation Data'] !== 'undefined'
        && typeof privateExistingLoanData['Summation Data'][year] !== 'undefined' && typeof privateExistingLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateExistingLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid4 = privateExistingLoanData['Summation Data'][year].LaggingInterest + privateExistingLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid4 = 0;
      }
      if ( typeof fedSubHigherEdLoanData !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid5 = fedSubHigherEdLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid5 = 0;
      }
      if ( typeof fedUnSubHigherEdLoanData !== 'undefined' && typeof fedUnSubHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid6 = fedUnSubHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid6 = 0;
      }
      if ( typeof privateHigherEdLoanData !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof privateHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid7 = privateHigherEdLoanData['Summation Data'][year].LaggingInterest + privateHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid7 = 0;
      }
      if ( typeof fedSubMoreHigherEdLoanData !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid8 = fedSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid8 = 0;
      }
      if ( typeof fedUnSubMoreHigherEdLoanData !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid9 = fedUnSubMoreHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid9 = 0;
      }
      if ( typeof privateMoreHigherEdLoanData !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof privateMoreHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid10 = privateMoreHigherEdLoanData['Summation Data'][year].LaggingInterest + privateMoreHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid10 = 0;
      }
      if ( typeof fedSubNewHigherEdLoanData !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedSubNewHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid11 = fedSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest;
      } else {
        interestPaid11 = 0;
      }
      if ( typeof fedUnSubNewHigherEdLoanData !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof fedUnSubNewHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid12 = fedUnSubNewHigherEdLoanData['Summation Data'][year].LaggingInterest + fedUnSubNewHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid12 = 0;
      }
      if ( typeof privateNewHigherEdLoanData !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'] !== 'undefined'
        && typeof privateNewHigherEdLoanData['Summation Data'][year] !== 'undefined' && typeof privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== 'undefined' && privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest !== '' ) {
        interestPaid13 = privateNewHigherEdLoanData['Summation Data'][year].LaggingInterest + privateNewHigherEdLoanData['Summation Data'][year]['Capitalized Interest Tax Deduction'];
      } else {
        interestPaid13 = 0;
      }
      interestPaid = interestPaid1 + interestPaid2 + interestPaid3 + interestPaid4 + interestPaid5 + interestPaid6 + interestPaid7 + interestPaid8 + interestPaid9 + interestPaid10 + interestPaid11 + interestPaid12 + interestPaid13;
      returnData.Interest = interestPaid;

      // Range
      let range = 0;
      if ( income >= marriedDataArray[index].Range ) {
        range = marriedDataArray[index].Range;
      } else {
        range = 0;
      }
      returnData.Range = range;

      // Range2
      let range2 = 0;
      if ( index < 2 ) {
        if ( income >= marriedDataArray[index].Range2 ) {
          range2 = marriedDataArray[index].Range2;
        } else if ( income <= marriedDataArray[index].Range2 && income >= marriedDataArray[index].Range ) {
          range2 = income;
        } else {
          range2 = 0;
        }
      } else {
        range2 = 0;
      }
      returnData.Range2 = range2;

      // Difference
      returnData.Difference = range2 - range;

      // Divisor
      returnData.Divisor = marriedDataArray[index].Divisor;

      // Deduction
      if ( index === 0 ) {
        if ( returnData.Difference >= marriedDataArray[index].Range2 ) {
          returnData.Deduction = 0;
        } else {
          returnData.Deduction = returnData.Interest;
        }
      } else if ( index === 1 ) {
        if ( returnData.Difference >= marriedDataArray[index].Difference ) {
          returnData.Deduction = 0;
        } else if ( income < marriedDataArray[index - 1].Range2 ) {
          returnData.Deduction = 0;
        } else if ( marriedDataArray[index].Difference !== 0 ) {
          returnData.Deduction = returnData.Interest - ( returnData.Interest * ( returnData.Difference / returnData.Divisor ) );
        } else {
          returnData.Deduction = 0;
        }
      } else {
        returnData.Deduction = 0;
      }
      finalDeduction += returnData.Deduction;
      return returnData;
    } );

    let returnValue = 0;

    if ( typeof module10Data !== 'undefined' && typeof module10Data.marriege_year !== 'undefined' && module10Data.marriege_year > 0
      && year >= module10Data.marriege_year ) {
      returnValue = finalDeduction;
    }

    const data = {};
    data.Income = income;
    data['Final Deduction'] = returnValue;
    data['Iteration Data'] = marriedIteration1Array;
    data['Max Deduction'] = maxDeduction;

    return data;
  }

  /**
  * Student Loan Tax
  */

  studentLoanTaxesData = {};
  interestPaidData = {};
  studentLoanCompleteData = {};
  for ( year1; year1 < yearLimit; year1 += 1 ) {
    studentLoanTaxesData[year1] = {};
    interestPaidData[year1] = {};
    studentLoanCompleteData[year1] = {};

    let IncomeStatementValue = 0;
    if ( typeof taxStatementData !== 'undefined' && typeof taxStatementData[year1] !== 'undefined' && typeof taxStatementData[year1]['Gross Income'] !== 'undefined' && taxStatementData[year1]['Gross Income'] !== '' && typeof taxStatementData[year1]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined' && taxStatementData[year1]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== '' ) {
      IncomeStatementValue = parseFloat( taxStatementData[year1]['Gross Income']['Total Gross Income (excluding Rental Income)'] );
    }
    // Single
    const singleData = singleCalculation( year1, IncomeStatementValue );

    // Married
    const marriedData = marriedCalculation( year1, IncomeStatementValue );
	if ( ( singleData['Final Deduction'] + marriedData['Final Deduction'] ) > maxDeduction ) {
      studentLoanTaxesData[year1] = maxDeduction;
    } else {
      studentLoanTaxesData[year1] = singleData['Final Deduction'] + marriedData['Final Deduction'];
    }
    // Complete Data
    studentLoanCompleteData[year1].Single = singleData;
    studentLoanCompleteData[year1].Married = marriedData;
  }

  function studentLoanDataFun() {
    const studentLoanData = {};
    studentLoanData['Default Data'] = {};
    studentLoanData['Summation Data'] = studentLoanTaxesData;
    studentLoanData['Interest Paid'] = interestPaidData;
    studentLoanData['Complete Data'] = studentLoanCompleteData;
    studentLoanData['Default Data'].Single1 = singleRangeArray;
    studentLoanData['Default Data'].Single2 = singleRange2Array;
    studentLoanData['Default Data'].Married1 = marriedRangeArray;
    studentLoanData['Default Data'].Married2 = marriedRange2Array;
	return studentLoanData;
  }
  return studentLoanDataFun();
}
export default studentLoanTaxSheet;
