export function convertCurrencyToInteger( value ) {
  if ( value !== undefined && value !== '' ) {
    // eslint-disable-next-line
    return Number( value.toString().replace( /[^0-9\.]+/g, '' ) );
  }
  return 0;
}

export function currency(value) {
  return value
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
}
