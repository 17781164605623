import { IRRRATE } from '../../utilities/excelLibrary';

function invPropertyRoiSheetData( module1Data, module14Data, assumptionsData, mortgage2Data, reiOutputBackendData, taxStatementData, databaseData ) {
  let purchasePrice = 0;
  if ( module14Data.purchase_price !== undefined
&& module14Data.purchase_price !== '' ) {
    purchasePrice = module14Data.purchase_price;
  } else {
    purchasePrice = 0;
  }
  /* Closing Costs */
  const closingCostsData = {};

  if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase > 0 ) {
    // Appraisal Fee
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_appraisal_fee !== undefined && module14Data.closing_appraisal_fee !== null) {
        closingCostsData['Appraisal Fee'] = module14Data.closing_appraisal_fee;
    } else {
        closingCostsData['Appraisal Fee'] = 500;
    }

    // Credit Report Fee
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_credit_report_fee !== undefined && module14Data.closing_credit_report_fee !== null) {
        closingCostsData['Credit Report Fee'] = module14Data.closing_credit_report_fee;
    } else {
        closingCostsData['Credit Report Fee'] = 30;
    }

    // Closing Fee
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_escrow_account !== undefined && module14Data.closing_escrow_account !== null) {
        closingCostsData['Title Closing/Escrow Fee'] = module14Data.closing_escrow_account;
    } else {
        closingCostsData['Title Closing/Escrow Fee'] = 400;
    }

    // Lenders Title Insurance
    closingCostsData['Lenders Title Insurance'] = 0.004 * purchasePrice;

    // Flood Determination
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_flood_determination !== undefined && module14Data.closing_flood_determination !== null) {
        closingCostsData['Flood Determination'] = module14Data.closing_flood_determination;
    } else {
        closingCostsData['Flood Determination'] = 20;
    }

    // Tax Service Fee
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_tax_service_fee !== undefined && module14Data.closing_tax_service_fee !== null) {
        closingCostsData['Tax Service Fee'] = module14Data.closing_tax_service_fee;
    } else {
        closingCostsData['Tax Service Fee'] = 75;
    }

    // Owners Title Insurance
    let closingCostPolicyInsurance = 0;
    if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase > 0 && module14Data.reiTitleInsuranceCalcs !== undefined ) {
      closingCostPolicyInsurance = module14Data.reiTitleInsuranceCalcs;
    } else {
      closingCostPolicyInsurance = 0;
    }
    closingCostsData['Owners Title Insurance'] = closingCostPolicyInsurance;

    // Recording Mortgage
    let naturalHazard = 0;
    if(typeof module14Data.mortage !== 'undefined' && module14Data.mortage !== '') {
        if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' &&  typeof module14Data.closing_recording_mortgage !== 'undefined') {
            naturalHazard = module14Data.closing_recording_mortgage;
        } else {
            const downAmount = (parseFloat(module14Data.down_payment_in_percentage) * module14Data.purchase_price) / 100
           naturalHazard = module14Data.purchase_price - parseFloat(downAmount);
           naturalHazard = 0.0007 * naturalHazard;
        }
    }   
    closingCostsData['Recording Mortgage'] = naturalHazard;


    // Homeowners Insurance(3 months prepaid upfront)
    let closingCostOwnersInsurance = 0;
    if ( module14Data.realEstatehomeownerInsuranceCalcs !== undefined && module14Data.realEstatehomeownerInsuranceCalcs !== '' ) {
      closingCostOwnersInsurance = ( module14Data.realEstatehomeownerInsuranceCalcs ) * 0.25;
    }
    closingCostsData['Homeowners Insurance(3 months prepaid upfront)'] = closingCostOwnersInsurance;

    // Transfer Taxes
    let closingCostTransferTaxes = 0;
    if ( module14Data.transfer_taxes_in_percentage !== undefined && module14Data.transfer_taxes_in_percentage !== '' ) {
      closingCostTransferTaxes = purchasePrice * ( module14Data.transfer_taxes_in_percentage / 100 );
    }
    closingCostsData['Transfer Taxes'] = closingCostTransferTaxes;


    // Processing Fee
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_processing_fee !== undefined && module14Data.closing_processing_fee !== null) {
        closingCostsData['Processing Fee'] = module14Data.closing_processing_fee;
    } else {
        closingCostsData['Processing Fee'] = 500;
    }

    // UnderWriting Fee
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_underwriting_fee !== undefined && module14Data.closing_underwriting_fee !== null) {
        closingCostsData['Underwriting Fee'] = module14Data.closing_underwriting_fee;
    } else {
        closingCostsData['Underwriting Fee'] = 500;
    }

    // Loan Acquisition Points
    let closingCostLoanPoints = 0;
    if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase > 0 && module14Data.loanPointsCalculation !== undefined ) {
      closingCostLoanPoints = module14Data.loanPointsCalculation;
    } else {
      closingCostLoanPoints = 0;
    }
    closingCostsData['Loan Acquisition Points'] = closingCostLoanPoints;

    // Origination Points
    let originationPoints = 0;
    if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase > 0 && module14Data.originationFeesCalculation !== undefined ) {
      originationPoints = module14Data.originationFeesCalculation;
    } else {
      originationPoints = 0;
    }
    closingCostsData['Origination Points'] = originationPoints;

    // Mortgage Interest(1month prepaid upfront)
    let closingCostPrePaidInterest = 0;
    if ( mortgage2Data !== undefined
  && mortgage2Data['Inv Prepaid Interest'] !== undefined && mortgage2Data['Inv Prepaid Interest'] !== '' ) {
      closingCostPrePaidInterest = mortgage2Data['Inv Prepaid Interest'];
    }
    closingCostsData['Mortgage Interest(1month prepaid upfront)'] = closingCostPrePaidInterest;

    // Property Taxes(3 months prepaid upfront)
    let closingCostPropertyTax = 0;
    if ( module14Data.realEstatePropertyTaxesCalcs !== undefined && module14Data.realEstatePropertyTaxesCalcs !== '' ) {
      closingCostPropertyTax = ( module14Data.realEstatePropertyTaxesCalcs ) * 0.25;
    }
    closingCostsData['Property Taxes(3 months prepaid upfront)'] = closingCostPropertyTax;

    // Wood Destroying Pest Inspection
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_pest_Inspection !== undefined && module14Data.closing_pest_Inspection !== null) {
        closingCostsData['Wood Destroying Pest Inspection'] = module14Data.closing_pest_Inspection;
    } else {
        closingCostsData['Wood Destroying Pest Inspection'] = 250;
    }

    // Home Owners Association Transfer Fees
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_home_owners_association_transfer_fees !== undefined && module14Data.closing_home_owners_association_transfer_fees !== null) {
        closingCostsData['Home Owners Association Transfer Fees'] = module14Data.closing_home_owners_association_transfer_fees;
    } else {
        closingCostsData['Home Owners Association Transfer Fees'] = 200;
    }


    // Others
    // closingCostsData['Others'] = 0;
    if ( module14Data.closing_costs_others !== undefined && module14Data.reiClosingCostsNewValues !== undefined && module14Data.reiClosingCostsNewValues === 'Yes' ) {
      closingCostsData.Others = module14Data.closing_costs_others;
    } else if ( module14Data.defaultclosingCostsOthers !== undefined ) {
      closingCostsData.Others = module14Data.defaultclosingCostsOthers;
    } else {
      closingCostsData.Others = 0;
    }

    // Others
    if(typeof module14Data.save_rei_closing_costs_new_values !== undefined && module14Data.save_rei_closing_costs_new_values === 'Yes' 
      && module14Data.closing_costs_others !== undefined && module14Data.closing_costs_others !== null) {
        closingCostsData['Others'] = module14Data.closing_costs_others;
    } else {
        closingCostsData['Others'] = 0;
    }
	
	closingCostsData['Total Initial Costs'] = closingCostsData['Appraisal Fee']
  + closingCostsData['Credit Report Fee']
  + closingCostsData['Title Closing/Escrow Fee']
  + closingCostsData['Flood Determination']
  + closingCostsData['Tax Service Fee']
  + closingCostsData['Owners Title Insurance']
  + closingCostsData['Recording Mortgage']
  + closingCostsData['Homeowners Insurance(3 months prepaid upfront)']
  + closingCostsData['Transfer Taxes']
  + closingCostsData['Processing Fee']
  + closingCostsData['Underwriting Fee']
  + closingCostsData['Loan Acquisition Points']
  + closingCostsData['Origination Points']
  + closingCostsData['Mortgage Interest(1month prepaid upfront)']
  + closingCostsData['Property Taxes(3 months prepaid upfront)']
  + closingCostsData['Wood Destroying Pest Inspection']
  + closingCostsData['Home Owners Association Transfer Fees']
  + closingCostsData.Others;
  
  } else {
    closingCostsData['Total Initial Costs'] = 0;
  }
  
  let data1Year; let data1YearLimit; let data2Year; let data2YearLimit;
  if ( module1Data.start_year !== undefined
&& module1Data.start_year !== '' ) {
    data1Year = parseInt( module1Data.start_year, 10 );
    data1YearLimit = parseInt( module1Data.start_year, 10 ) + 30;

    data2Year = parseInt( module1Data.start_year, 10 );
    data2YearLimit = parseInt( module1Data.start_year, 10 ) + 30;
  }

  /* Buying Expenses */
  const buyingExpenses = {};

  // Purchase Year
  let purchaseYear = 0;
  if ( module14Data.year_of_purchase !== undefined
&& module14Data.year_of_purchase !== '' ) {
    purchaseYear = module14Data.year_of_purchase;
  }
  buyingExpenses['Purchase Year'] = purchaseYear;

  // Sell Year
  let buyingsellYear = 0;
  if ( module14Data.appreciation_in_percentage !== undefined
&& module14Data.year_of_sale !== undefined
&& module14Data.year_of_sale !== '' ) {
    buyingsellYear = module14Data.year_of_sale;
  }
  buyingExpenses['Sell Year'] = buyingsellYear;

  // Down Payment
  let downPayment = 0;
  if ( module14Data.realEstatedownPaymentCalcs !== undefined
&& module14Data.realEstatedownPaymentCalcs !== '' ) {
    downPayment = module14Data.realEstatedownPaymentCalcs;
  }
  buyingExpenses['Down Payment'] = downPayment;

  // Closing Costs
  const closingCosts = closingCostsData['Total Initial Costs'];
  buyingExpenses['Closing Costs'] = closingCosts;

  // Fit Out Costs
  let fitOfCosts = 0;
  if ( module14Data.external_and_internal_fit_out_costs !== undefined
&& module14Data.external_and_internal_fit_out_costs !== '' ) {
    fitOfCosts = module14Data.external_and_internal_fit_out_costs;
  }
  buyingExpenses['Fit Out Costs'] = fitOfCosts;

  if ( module14Data.year_of_purchase === undefined
|| module14Data.year_of_purchase === 0 ) {
    buyingExpenses['Sell Year'] = 0;
    buyingExpenses['Down Payment'] = 0;
    buyingExpenses['Closing Costs'] = 0;
    buyingExpenses['Fit Out Costs'] = 0;
  }
  /* Cash In */
  const cashIn = {};

  // Rental Income
  let cashInRentalIncome = 0;
  if ( module14Data.yearly_rental_income !== undefined
&& module14Data.yearly_rental_income !== ''
&& module14Data.vacancy_rate_in_percentage !== undefined ) {
    cashInRentalIncome = module14Data.annualRentalIncomeCalcs * ( 1 - ( parseFloat( module14Data.vacancy_rate_in_percentage ).toFixed( 2 ) / 100 ) );
  }
  cashIn['Rental Income'] = cashInRentalIncome;
  /* Cash Out */
  const cashOut = {};

  // Mortgage Payment
  let mortgagePayment = 0;
  if ( module14Data.realEstateYearlyPayment !== undefined
&& module14Data.realEstateYearlyPayment !== '' ) {
    mortgagePayment = module14Data.realEstateYearlyPayment;
  }
  cashOut['Mortgage Payment'] = mortgagePayment;

  // Insurance
  let insurance = 0;
  if ( module14Data.realEstatehomeownerInsuranceCalcs !== undefined
&& module14Data.realEstatehomeownerInsuranceCalcs !== '' ) {
    insurance = module14Data.realEstatehomeownerInsuranceCalcs;
  }
  cashOut['Homeowners Insurance'] = insurance;

  // Maintenance
  let maintenance = 0;
  if ( module14Data.realEstateYearlyMaintenanceCalcs !== undefined
&& module14Data.realEstateYearlyMaintenanceCalcs !== '' ) {
    maintenance = module14Data.realEstateYearlyMaintenanceCalcs;
  }
  cashOut['Property Maintenance'] = maintenance;

  // Property Taxes
  let propertyTaxes = 0;
  if ( module14Data.realEstatePropertyTaxesCalcs !== undefined
&& module14Data.realEstatePropertyTaxesCalcs !== '' ) {
    propertyTaxes = module14Data.realEstatePropertyTaxesCalcs;
  }
  cashOut['Property Taxes'] = propertyTaxes;

  // Association Fees
  let managementFees = 0;
  if ( module14Data.managementFeeCalcs !== undefined
&& module14Data.managementFeeCalcs !== '' ) {
    managementFees = module14Data.managementFeeCalcs;
  }
  cashOut['Management Fees'] = managementFees;

  // Association Fees
  let associationFees = 0;
  if ( module14Data.yearly_association_fees !== undefined
&& module14Data.yearly_association_fees !== '' ) {
    associationFees = module14Data.yearly_association_fees;
  }
  cashOut['Association Fees'] = associationFees;

  // Utilities
  let utilities = 0;
  if ( module14Data.monthlyUtilitiesCalcs !== undefined
&& module14Data.monthlyUtilitiesCalcs !== '' ) {
    utilities = module14Data.monthlyUtilitiesCalcs;
  }
  cashOut.Utilities = utilities;

  // New changes about percentages
  let annualHomeOwnersTaxValue = 0;
  if ( module14Data.home_owners_insurance_in_percentage !== undefined && module14Data.home_owners_insurance_in_percentage !== '' ) {
    annualHomeOwnersTaxValue = module14Data.home_owners_insurance_in_percentage;
  } else {
    annualHomeOwnersTaxValue = 0;
  }
  cashOut['Annual Homeowner Insurance'] = annualHomeOwnersTaxValue;

  // Annual Maintenance Tax
  let annualMaintenanceTaxValue = 0;
  if ( module14Data.yearly_maintanance_percantage !== undefined && module14Data.yearly_maintanance_percantage !== '' ) {
    annualMaintenanceTaxValue = module14Data.yearly_maintanance_percantage;
  } else {
    annualMaintenanceTaxValue = 0;
  }
  cashOut['Annual Maintenance'] = annualMaintenanceTaxValue;

  // Annual Property Taxes
  let annualPropertyTaxValue = 0;
  if ( module14Data.property_taxes_in_percentage !== undefined && module14Data.property_taxes_in_percentage !== '' ) {
    annualPropertyTaxValue = module14Data.property_taxes_in_percentage;
  } else {
    annualPropertyTaxValue = 0;
  }
  cashOut['Annual Property Tax'] = annualPropertyTaxValue;
  // Annual Income Growth
  let annualIncomeGrowthValue = 0;
  if ( module14Data.annual_income_growth_rate !== undefined && module14Data.annual_income_growth_rate !== '' ) {
    annualIncomeGrowthValue = module14Data.annual_income_growth_rate;
  } else {
    annualIncomeGrowthValue = 0;
  }
  cashOut['Annual Renatl Income Growth'] = annualIncomeGrowthValue;

  if ( module14Data.year_of_purchase === undefined
|| module14Data.year_of_purchase === 0 ) {
    cashOut['Mortgage Payment'] = 0;
    cashOut['Homeowners Insurance'] = 0;
    cashOut['Property Maintenance'] = 0;
    cashOut['Property Taxes'] = 0;
    cashOut['Management Fees'] = 0;
    cashOut['Association Fees'] = 0;
    cashOut.Utilities = 0;
    cashOut['Annual Homeowner Insurance'] = 0;
    cashOut['Annual Maintenance'] = 0;
    cashOut['Annual Property Tax'] = 0;
  }
  let year = parseInt( module1Data.start_year, 10 );
  const yearLimit = parseInt( module1Data.start_year, 10 ) + 30;

  /* Real Estate Value */
  const realEstateValue = {};
  let realEstateAssetValue = 0;

  // Depreciation
  const depreciation = 27.50;

  /* Data 2 */
  const data2 = {};

  let reiTotal = 0;

  //  Asset Value
  let assetValueCal = 0;
  assetValueCal = purchasePrice;

  // Cal Value
  let calVal = 0;
  if ( assetValueCal > 0 ) {
    calVal = ( assetValueCal / depreciation );
  }

  let sellYear = 0;
  if ( module14Data.appreciation_in_percentage !== undefined ) {
    sellYear = module14Data.year_of_sale;
  }

  let realEstatePropertyTax = 0;
  let realEstateAccumulated = 0;
  let m = 0;
  let reiStartYear = 0;
  let reiEndYear = 0;
  const yearsListArr = [];
  const ReturnYearsListArr = [];
  const netCashFlowBeforeTaxesValues = [];
  for ( year; year <= yearLimit; year += 1 ) {
    /* Real Estate Value */
    realEstateValue[year] = {};

    // Asset Value
    if ( year === purchaseYear ) {
      realEstateAssetValue = assetValueCal;
    } else if ( year >= purchaseYear && year <= sellYear ) {
      if ( module14Data.appreciation_in_percentage !== undefined ) {
        realEstateAssetValue *= ( 1 + ( module14Data.appreciation_in_percentage / 100 ) );
      } else {
        realEstateAssetValue = 0;
      }
    } else {
      realEstateAssetValue = 0;
    }
    realEstateValue[year]['Asset Value'] = realEstateAssetValue;

    // Property Tax
    if ( m === 0 ) {
      if ( year === purchaseYear ) {
        realEstatePropertyTax = cashOut['Property Taxes'];
      } else if ( year >= purchaseYear && year < sellYear ) {
        realEstatePropertyTax = cashOut['Property Taxes'];
      } else if ( year === sellYear ) {
        realEstatePropertyTax = parseFloat( cashOut['Property Taxes'] ) * 0.75;
      } else {
        realEstatePropertyTax = 0;
      }
    } else if ( year === purchaseYear ) {
      realEstatePropertyTax = cashOut['Property Taxes'];
    } else if ( year >= purchaseYear && year < sellYear ) {
      realEstatePropertyTax = realEstateValue[year]['Asset Value'] * ( cashOut['Annual Property Tax'] / 100 );
    } else if ( year === sellYear ) {
      realEstatePropertyTax = realEstateValue[year]['Asset Value'] * ( cashOut['Annual Property Tax'] / 100 ) * 0.75;
    } else {
      realEstatePropertyTax = 0;
    }
    realEstateValue[year]['Property Tax'] = realEstatePropertyTax;

    // REI
    let realEstateREI = 0;
    if ( realEstateValue[year]['Asset Value'] > 0 ) {
      realEstateREI = calVal;
    } else {
      realEstateREI = 0;
    }
    realEstateValue[year].Home = realEstateREI;
    reiTotal = parseFloat( reiTotal ) + parseFloat( realEstateValue[year].Home );

    // REI table Home
    let realEstateREITable = 0;
    if ( module14Data.year_of_purchase !== undefined && data1Year === parseInt( module14Data.year_of_purchase, 10 ) - 1
&& module1Data.start_year !== undefined && module14Data.year_of_purchase === module1Data.start_year ) {
      realEstateREITable = 0;
    } else {
      realEstateREITable = realEstateREI;
    }
    realEstateValue[year]['Home REI Table'] = realEstateREITable;

    // Accumulated
    if ( realEstateREI > 0 ) {
      if ( m === 0 ) {
        realEstateAccumulated = realEstateREI;
      } else {
        realEstateAccumulated += realEstateREI;
      }
    } else {
      realEstateAccumulated = 0;
    }
    realEstateValue[year].Accumulated = realEstateAccumulated;

    m += 1;
  }

  /* Selling Costs */
  const sellingCosts = {};

  // Selling Year
  sellingCosts['Selling Year'] = sellYear;

  // Selling Price
  let sellingPrice = 0;
  if ( realEstateValue !== undefined
&& sellingCosts['Selling Year'] !== undefined
&& realEstateValue[sellingCosts['Selling Year']] !== undefined ) {
    sellingPrice = realEstateValue[sellingCosts['Selling Year']]['Asset Value'];
  }
  sellingCosts['Selling Price'] = sellingPrice;

  // Selling Costs
  let sellingCostsVal = 0;
  if ( module14Data.selling_costs_in_percentage !== undefined
&& module14Data.selling_costs_in_percentage !== '' ) {
    sellingCostsVal = ( module14Data.selling_costs_in_percentage / 100 ) * sellingCosts['Selling Price'];
  }
  sellingCosts['Selling Costs'] = sellingCostsVal;

  // Mortgage Balance Remaining
  let mortgageBalanceRemaining = 0;
  if ( mortgage2Data !== undefined
&& mortgage2Data['Summation Data'] !== undefined
&& mortgage2Data['Summation Data'][sellYear] !== undefined
&& mortgage2Data['Summation Data'][sellYear].EndingBalance !== undefined ) {
    mortgageBalanceRemaining = mortgage2Data['Summation Data'][sellYear].EndingBalance;
  } else {
    mortgageBalanceRemaining = 0;
  }
  sellingCosts['Mortgage Balance Remaining'] = mortgageBalanceRemaining;

  // Net Cash FLow in Sale Year
  sellingCosts['Net Cash Flow in Sale Year'] = sellingCosts['Selling Price']
- sellingCosts['Selling Costs']
- sellingCosts['Mortgage Balance Remaining'];

  /* Data1 */
  let data1RentalIncome = 0;
  let dataAssociationFees = 0;
  let dataManagementFees = 0;
  let dataInsurance = 0;
  let dataMaintenance = 0;
  let dataUtilities = 0;
  const sumOfPVs = 0;

  let discountRate = 0;
  if ( module14Data.discount_rate_in_percantage !== undefined
&& module14Data.discount_rate_in_percantage !== '' ) {
    discountRate = module14Data.discount_rate_in_percantage;
  }

  let m1 = 0;
  const identifier = '';
  let ROIFirstValue = 0;
  let ROISum = 0;
  let rentalIncomeSum = 0;
  const propertyExpensesSum = 0;
  const data1 = {};
  let taxOnAccumulatedTotal = 0;
  let taxOnGainTotal = 0;
  let mortage2DataFirst = 0;
  let mortage2DataFirst1 = 0;
  let mortage2DataFirst2 = 0;
  let returnsYear = 0;
  let netCashFlowBeforeTaxes = 0;
  let netCashFlowBeforeTaxes1 = 0;
  let netCashFlowBeforeTaxes2 = 0;
  let yearIntegers = 0;
  const data1NetCashFlowArray = [];
  const data1NetCashFlowFromPurchaseArray = [];
  const netOperatingIncomeArray = [];
  let netOperatingIncome = 0;
  let reiTotalOperationgExpenses = 0;
  let REITotalFinancingExpenses = 0;
  let sumNetCashFlowBeforeTaxes = 0;
  let yearI = 0;
  for ( data1Year; data1Year <= data1YearLimit; data1Year += 1 ) {
    /* Data 1 */
    data1[data1Year] = {};

    // Year Integers
    if ( purchaseYear <= ( parseInt( data1Year, 10 ) + 1 ) && sellingCosts['Selling Year'] >= data1Year ) {
      if ( purchaseYear === ( parseInt( data1Year, 10 ) + 1 ) ) {
        yearIntegers = 0;
      } else {
        yearIntegers += 1;
      }
    } else {
      yearIntegers = '';
    }

    data1[data1Year]['Year Integers'] = yearIntegers;
    // Rental Income
    if ( purchaseYear <= data1Year && sellYear >= data1Year ) {
      if ( purchaseYear === data1Year ) {
        data1RentalIncome = cashIn['Rental Income'];
      } else if ( module14Data.annual_income_growth_rate !== undefined && module14Data.annual_income_growth_rate !== '' ) {
        // data1RentalIncome = data1RentalIncome * ((1 + converters.convertCurrencyToInteger(module14Data.annual_income_growth_rate)) / 100);
        data1RentalIncome *= ( 1 + ( module14Data.annual_income_growth_rate / 100 ) );
      } else {
        data1RentalIncome = 0;
      }
    } else {
      data1RentalIncome = 0;
    }
    data1[data1Year]['Rental Income'] = data1RentalIncome;
    rentalIncomeSum += data1RentalIncome;
    // Down Payment
    let dataDownPayment = 0;
    if ( purchaseYear === ( data1Year ) ) {
      dataDownPayment = downPayment;
    } else {
      dataDownPayment = 0;
    }
    data1[data1Year]['Down Payment'] = dataDownPayment;

    // Fit Out Costs
    let dataFitOut = 0;
    if ( purchaseYear === ( data1Year ) ) {
      dataFitOut = fitOfCosts;
    }
    data1[data1Year]['Fit Out Costs'] = dataFitOut;

    // Closing Costs
    let dataClosingCost = 0;
    if ( purchaseYear === ( data1Year ) ) {
      dataClosingCost = closingCosts;
    }
    data1[data1Year]['Closing Costs'] = dataClosingCost;

    // Mortgage Payment
    let dataMortgagePayment = 0;
    if ( purchaseYear <= data1Year && sellingCosts['Selling Year'] >= data1Year ) {
      if ( data1Year === buyingExpenses['Purchase Year'] ) {
        if ( reiOutputBackendData['REI Output Backend'] !== undefined && reiOutputBackendData['REI Output Backend'][data1Year] !== undefined
&& reiOutputBackendData['REI Output Backend'][data1Year].Payment !== undefined ) {
          dataMortgagePayment = reiOutputBackendData['REI Output Backend'][data1Year].Payment;
        } else {
          dataMortgagePayment = 0;
        }
      } else {
        dataMortgagePayment = cashOut['Mortgage Payment'];
      }
    } else {
      dataMortgagePayment = 0;
    }
    data1[data1Year]['Mortgage Payment'] = dataMortgagePayment;

    REITotalFinancingExpenses += dataMortgagePayment;

    // Association Fees
    if ( data1Year === purchaseYear ) {
      dataAssociationFees = associationFees;
    } else if ( data1Year >= purchaseYear && data1Year <= sellYear ) {
      if ( m1 === 0 ) {
        dataAssociationFees = associationFees;
      } else if ( assumptionsData !== undefined && assumptionsData[data1Year] !== undefined
&& assumptionsData[data1Year].Expenses !== undefined ) {
        dataAssociationFees *= ( assumptionsData[data1Year].Expenses / 100 );
      } else {
        dataAssociationFees = 0;
      }
    } else {
      dataAssociationFees = 0;
    }
    data1[data1Year]['Association Fees'] = dataAssociationFees;
    reiTotalOperationgExpenses += dataAssociationFees;

    // Management Fees
    if ( data1Year === purchaseYear ) {
      dataManagementFees = managementFees;
    } else if ( data1Year >= purchaseYear && data1Year <= sellYear ) {
      if ( m1 === 0 ) {
        dataManagementFees = managementFees;
      } else if ( assumptionsData !== undefined && assumptionsData[data1Year] !== undefined && assumptionsData[data1Year].Expenses !== undefined ) {
        dataManagementFees *= ( assumptionsData[data1Year].Expenses / 100 );
      } else {
        dataManagementFees = 0;
      }
    } else {
      dataManagementFees = 0;
    }
    reiTotalOperationgExpenses += dataManagementFees;
    data1[data1Year]['Management Fees'] = dataManagementFees;

    // Home Owners Insurance
    if ( data1Year === purchaseYear ) {
      dataInsurance = insurance;
    } else if ( m1 === 0 ) {
      dataInsurance = 0;
    } else if ( data1Year === ( purchaseYear + 1 ) ) {
      if ( realEstateValue !== undefined && realEstateValue[data1Year] !== undefined && realEstateValue[data1Year]['Asset Value'] !== undefined ) {
        dataInsurance = realEstateValue[data1Year]['Asset Value'] * ( cashOut['Annual Homeowner Insurance'] / 100 );
      } else {
        dataInsurance = 0;
      }
    } else if ( data1Year >= purchaseYear && data1Year < sellYear ) {
      if ( realEstateValue !== undefined && realEstateValue[data1Year] !== undefined && realEstateValue[data1Year]['Asset Value'] !== undefined ) {
        dataInsurance = realEstateValue[data1Year]['Asset Value'] * ( cashOut['Annual Homeowner Insurance'] / 100 );
      } else {
        dataInsurance = 0;
      }
    } else if ( data1Year === sellYear ) {
      if ( realEstateValue !== undefined && realEstateValue[data1Year] !== undefined && realEstateValue[data1Year]['Asset Value'] !== undefined ) {
        dataInsurance = realEstateValue[data1Year]['Asset Value'] * ( cashOut['Annual Homeowner Insurance'] / 100 ) * 0.75;
      } else {
        dataInsurance = 0;
      }
    } else {
      dataInsurance = 0;
    }
    reiTotalOperationgExpenses += dataInsurance;
    data1[data1Year]['Houseowners Insurance'] = dataInsurance;

    // Property Maintenance
    if ( data1Year === purchaseYear ) {
      dataMaintenance = maintenance;
    } else if ( data1Year >= purchaseYear && data1Year <= sellYear ) {
      if ( m1 === 0 ) {
        dataMaintenance = maintenance;
      } else if ( realEstateValue !== undefined && realEstateValue[data1Year] !== undefined
&& realEstateValue[data1Year]['Asset Value'] !== undefined ) {
        dataMaintenance = realEstateValue[data1Year]['Asset Value'] * ( cashOut['Annual Maintenance'] / 100 );
      } else {
        dataMaintenance = 0;
      }
    } else {
      dataMaintenance = 0;
    }
    reiTotalOperationgExpenses += dataMaintenance;
    data1[data1Year]['Property Maintenance'] = dataMaintenance;

    // Utilities
    if ( m1 === 0 ) {
      if ( data1Year === purchaseYear ) {
        dataUtilities = utilities;
      } else if ( data1Year >= purchaseYear && data1Year <= sellYear ) {
        dataUtilities = utilities;
      } else {
        dataUtilities = 0;
      }
    } else if ( data1Year === purchaseYear ) {
      dataUtilities = utilities;
    } else if ( data1Year >= purchaseYear && data1Year <= sellYear ) {
      if ( assumptionsData !== undefined && assumptionsData[data1Year] !== undefined
&& assumptionsData[data1Year].Expenses !== undefined ) {
        dataUtilities *= ( assumptionsData[data1Year].Expenses / 100 );
      } else {
        dataUtilities = 0;
      }
    } else {
      dataUtilities = 0;
    }
    reiTotalOperationgExpenses += dataUtilities;
    data1[data1Year].Utilities = dataUtilities;

    // Property Taxes
    let dataPropertyTaxes = 0;
    dataPropertyTaxes = realEstateValue[data1Year]['Property Tax'];

    reiTotalOperationgExpenses += dataPropertyTaxes;

    data1[data1Year]['Property Taxes'] = dataPropertyTaxes;

    // Sale Value
    let saleValue = 0;
    if ( sellYear === data1Year ) {
      saleValue = sellingCosts['Net Cash Flow in Sale Year'];
    } else {
      saleValue = 0;
    }
    data1[data1Year]['Net Cash Flow from Sale'] = saleValue;

    // Tax on Gain
    let taxOnGain = 0;
    if ( data1Year === module14Data.year_of_sale ) {
      if ( module14Data.moreREITaxCapital !== undefined && module14Data.moreREITaxCapital !== '' ) {
        taxOnGain = module14Data.moreREITaxCapital;
      } else {
        taxOnGain = 0;
      }
    } else {
      taxOnGain = 0;
    }
    data1[data1Year]['Tax on Gain'] = taxOnGain;
    taxOnGainTotal += taxOnGain;

    // Tax on Accumulated Depreciation
    let taxOnAccumulated = 0;
    if ( data1Year === module14Data.year_of_sale ) {
      taxOnAccumulated = parseFloat( reiTotal ) * ( 25 / 100 );
    } else {
      taxOnAccumulated = 0;
    }
    data1[data1Year]['Tax on Accumulated'] = taxOnAccumulated;
    taxOnAccumulatedTotal += taxOnAccumulated;

    // Net Cash Flow
    let data1NetCashFlow = 0;
    data1NetCashFlow = data1[data1Year]['Rental Income']
- ( data1[data1Year]['Mortgage Payment']
+ data1[data1Year]['Association Fees'] + data1[data1Year]['Management Fees'] + data1[data1Year]['Houseowners Insurance']
+ data1[data1Year]['Property Maintenance'] + data1[data1Year].Utilities
+ data1[data1Year]['Property Taxes'] );
    data1[data1Year]['Net Cash Flow'] = data1NetCashFlow;

    // Net Cash Flow only REI table
    data1[data1Year]['Net Cash Flow REI Table'] = data1[data1Year]['Rental Income'] - (
      data1[data1Year]['Down Payment'] + data1[data1Year]['Fit Out Costs'] + data1[data1Year]['Closing Costs'] + data1[data1Year]['Mortgage Payment'] + data1[data1Year]['Association Fees'] + data1[data1Year]['Management Fees'] + data1[data1Year]['Houseowners Insurance'] + data1[data1Year]['Property Maintenance'] + data1[data1Year].Utilities + data1[data1Year]['Property Taxes'] ) + data1[data1Year]['Net Cash Flow from Sale'];
    if ( data1NetCashFlow !== 0 ) {
      data1NetCashFlowArray.push( data1NetCashFlow );
    }

    // Net Cash Flow from Purchase / Sale

    let data1NetCashFromPurOrSale = 0;
    if ( data1Year === purchaseYear ) {
      data1NetCashFromPurOrSale = -(
        data1[data1Year]['Down Payment']
+ data1[data1Year]['Fit Out Costs']
+ data1[data1Year]['Closing Costs']
      );
    } else if ( data1Year === sellingCosts['Selling Year'] ) {
      data1NetCashFromPurOrSale = data1[data1Year]['Net Cash Flow from Sale'];
    }

    data1[data1Year]['Net Cash Flow from Purchase / Sale'] = data1NetCashFromPurOrSale;
    if ( yearIntegers > 0 ) {
      data1NetCashFlowFromPurchaseArray.push( data1NetCashFromPurOrSale );
    }

    // Net Income
    let data1NetIncome = 0;
    data1NetIncome = data1[data1Year]['Rental Income']
- data1[data1Year]['Association Fees']
- data1[data1Year]['Management Fees']
- data1[data1Year]['Houseowners Insurance']
- data1[data1Year]['Property Maintenance']
- data1[data1Year].Utilities
- data1[data1Year]['Property Taxes'];

    data1[data1Year]['Net Operating Income'] = data1NetIncome;
    netOperatingIncomeArray.push( data1NetIncome );

    let data1MortgageInterest = 0;
    if ( mortgage2Data !== undefined
&& mortgage2Data['Summation Data'] !== undefined
&& mortgage2Data['Summation Data'][data1Year] !== undefined
&& mortgage2Data['Summation Data'][data1Year].LaggingInterest !== undefined ) {
      data1MortgageInterest = ( mortgage2Data['Summation Data'][data1Year].LaggingInterest );
    } else {
      data1MortgageInterest = 0;
    }
    data1[data1Year]['Mortgage Interest'] = data1MortgageInterest;

    data1[data1Year]['Net Income REI Table'] = data1NetIncome - data1MortgageInterest;

    // Returns Calculation
    if ( yearIntegers === 0 || ( yearIntegers === 1 && data1Year === purchaseYear && netCashFlowBeforeTaxesValues === '' ) ) {
      netCashFlowBeforeTaxes = -( buyingExpenses['Down Payment'] + buyingExpenses['Closing Costs'] + buyingExpenses['Fit Out Costs'] );
      ROIFirstValue = netCashFlowBeforeTaxes;
      netCashFlowBeforeTaxesValues.push( netCashFlowBeforeTaxes );
      ReturnYearsListArr.push( data1Year );
    }
    if ( yearIntegers > 0 ) {
      if ( ( sellingCosts['Selling Year'] - buyingExpenses['Purchase Year'] + 1 ) >= yearIntegers ) {
        if ( data1NetCashFlowArray !== undefined && data1NetCashFlowArray !== '' && data1NetCashFlowArray[yearIntegers - 1] !== undefined
&& data1NetCashFlowArray[yearIntegers - 1] !== '' ) {
          netCashFlowBeforeTaxes1 = data1NetCashFlowArray[yearIntegers - 1];
        } else {
          netCashFlowBeforeTaxes1 = 0;
        }
      } else {
        netCashFlowBeforeTaxes1 = 0;
      }

      if ( ( sellingCosts['Selling Year'] - buyingExpenses['Purchase Year'] + 1 ) === yearIntegers ) {
        netCashFlowBeforeTaxes2 = data1NetCashFlowFromPurchaseArray[yearIntegers - 1];
      } else {
        netCashFlowBeforeTaxes2 = 0;
      }
      netCashFlowBeforeTaxes = netCashFlowBeforeTaxes1 + netCashFlowBeforeTaxes2;
      sumNetCashFlowBeforeTaxes += netCashFlowBeforeTaxes;
      ReturnYearsListArr.push( data1Year );
      netCashFlowBeforeTaxesValues.push( netCashFlowBeforeTaxes );
    }
    data1[data1Year]['Net Cash Flow (before taxes)'] = netCashFlowBeforeTaxes;

    // Net Operating Income
    if ( returnsYear === 0 ) {
      netOperatingIncome = 0;
    } else if ( ( sellingCosts['Selling Year'] - buyingExpenses['Purchase Year'] + 1 ) >= returnsYear ) {
      netOperatingIncome = netOperatingIncomeArray[returnsYear - 1];
    } else {
      netOperatingIncome = 0;
    }
    data1[data1Year]['Net Operating Income Second'] = netOperatingIncome;
    if ( data1NetCashFlowArray[returnsYear] > 0 ) {
      returnsYear += 1;
    }
    if ( ( purchaseYear <= ( data1Year ) ) && ( sellYear >= data1Year ) ) {
      if ( yearI === 0 ) {
        if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase > 0 ) {
          yearsListArr.push( data1Year );
        }
      } else if ( yearsListArr.length > 0 && module14Data.year_of_sale !== undefined && module14Data.year_of_sale > 0 ) {
        const mainYear = yearsListArr[yearsListArr.length - 1];
        if ( mainYear + 1 <= module14Data.year_of_sale ) {
          yearsListArr.push( mainYear + 1 );
        }
      }
      yearI += 1;
    } else {
      yearI = 0;
    }

    // First Value
    if ( identifier === 0 ) {
      ROIFirstValue = parseFloat( data1[data1Year]['Net Cash Flow'] );
      reiStartYear = data1Year;
      reiEndYear = data1Year;
      yearsListArr.push( data1Year );
    } else if ( identifier > 0 && identifier !== '' ) {
      // Sum of ROI
      ROISum = parseFloat( ROISum ) + parseFloat( data1[data1Year]['Net Cash Flow'] );
      yearsListArr.push( data1Year );
      reiEndYear = data1Year;
    }

    if ( module14Data.year_of_purchase !== undefined && data1Year === module14Data.year_of_purchase ) {
      if ( mortgage2Data !== undefined
&& mortgage2Data['Summation Data'] !== undefined
&& mortgage2Data['Summation Data'][data1Year] !== undefined
&& mortgage2Data['Summation Data'][data1Year].LaggingInterest !== undefined ) {
        mortage2DataFirst1 = -( mortgage2Data['Summation Data'][data1Year].LaggingInterest + closingCostsData['Mortgage Interest(1month prepaid upfront)'] );
      } else {
        mortage2DataFirst1 = 0;
      }
    } else if ( mortgage2Data !== undefined
&& mortgage2Data['Summation Data'] !== undefined
&& mortgage2Data['Summation Data'][data1Year] !== undefined
&& mortgage2Data['Summation Data'][data1Year].LaggingInterest !== undefined ) {
      mortage2DataFirst1 = -mortgage2Data['Summation Data'][data1Year].LaggingInterest;
    } else {
      mortage2DataFirst1 = 0;
    }
    if ( module14Data.year_of_purchase !== undefined && data1Year === parseInt( module14Data.year_of_purchase, 10 ) ) {
      mortage2DataFirst2 = -( closingCostsData['Loan Acquisition Points'] + closingCostsData['Origination Points'] );
    } else {
      mortage2DataFirst2 = 0;
    }
    mortage2DataFirst = mortage2DataFirst1 + mortage2DataFirst2;

    data1[data1Year]['Mortgage Interest, Loan Points, Origination Fees'] = mortage2DataFirst;

    // REI Property Taxable Income
    m1 += 1;
  }

  /* Data 3 */
  const data3 = {};
  let totalGrossIncomeValue = 0;
  let remainingPassiveLosses = 0;
  // Passive Loss Income Phaseout Range
  const passiveLossIncomePhaseoutRange = 100000;
  const passiveLossIncomePhaseoutRange1 = 150000;

  let p = 0;
  for ( data2Year; data2Year <= data2YearLimit; data2Year += 1 ) {
    /* Data 2 */
    data2[data2Year] = {};

    // Mortgage Interest
    let data2MortgageInterest = 0;
    if ( mortgage2Data !== undefined
&& mortgage2Data['Summation Data'] !== undefined
&& mortgage2Data['Summation Data'][data2Year] !== undefined
&& mortgage2Data['Summation Data'][data2Year].LaggingInterest !== undefined ) {
      data2MortgageInterest = mortgage2Data['Summation Data'][data2Year].LaggingInterest;
    } else {
      data2MortgageInterest = 0;
    }
    data2[data2Year]['Mortgage Interest'] = data2MortgageInterest;

    // Mortgage Points
    let data2MortgagePoints = 0;
    if ( data2Year === purchaseYear ) {
      data2MortgagePoints = closingCostsData['Loan Points'];
    } else {
      data2MortgagePoints = 0;
    }
    data2[data2Year]['Points of Mortgage'] = data2MortgagePoints;

    // Prepaid Insurance
    let data2PrepaidInsurance = 0;
    if ( data2Year === purchaseYear ) {
      data2PrepaidInsurance = closingCostsData['House Owners Insurance'];
    } else {
      data2PrepaidInsurance = 0;
    }
    data2[data2Year]['Prepaid Home Owners Insurance'] = data2PrepaidInsurance;

    // Property Depreciation
    let data2PropertyDepreciation;
    if ( realEstateValue[data2Year] !== undefined && realEstateValue[data2Year].Home !== undefined ) {
      data2PropertyDepreciation = realEstateValue[data2Year].Home;
    } else {
      data2PropertyDepreciation = 0;
    }
    data2[data2Year]['Property Depreciation'] = data2PropertyDepreciation;

    // Property Maintenance
    if ( data1 !== undefined && data1[data2Year] !== undefined ) {
      data2[data2Year]['Property Maintenance'] = data1[data2Year]['Property Maintenance'];
    } else {
      data2[data2Year]['Property Maintenance'] = 0;
    }

    // Property Taxes
    if ( data1 !== undefined && data1[data2Year] !== undefined ) {
      data2[data2Year]['Property Taxes'] = data1[data2Year]['Property Taxes'];
    } else {
      data2[data2Year]['Property Taxes'] = 0;
    }

    // Total Tax Deductions
    data2[data2Year]['Total Tax Deductions'] = parseFloat( data2[data2Year]['Mortgage Interest'] )
+ parseFloat( data2[data2Year]['Points of Mortgage'] )
+ parseFloat( data2[data2Year]['Prepaid Home Owners Insurance'] )
+ parseFloat( data2[data2Year]['Property Depreciation'] )
+ parseFloat( data2[data2Year]['Property Maintenance'] )
+ parseFloat( data2[data2Year]['Property Taxes'] );

    /* Data 3 */
    data3[data2Year] = {};

    // Total Gross Income (excluding Rental Income)
    if ( taxStatementData !== undefined && taxStatementData !== '' && taxStatementData[data2Year] !== undefined
&& taxStatementData[data2Year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined ) {
      totalGrossIncomeValue = taxStatementData[data2Year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
    } else {
      totalGrossIncomeValue = 0;
    }
    data3[data2Year]['Total Gross Income (excluding Rental Income)'] = totalGrossIncomeValue;

    // Net Rental Income
    data3[data2Year]['Net Rental Income'] = data1[data2Year]['Net Operating Income'];

    // Deductions Available
    let intemizedpointsmortgageValue = 0;
    let intemizedpointsmortgageValue1 = 0;

    if ( module14Data !== undefined && module14Data !== ''
&& module14Data.year_of_purchase !== undefined
&& closingCostsData['Loan Acquisition Points'] !== undefined
&& closingCostsData['Origination Points'] !== undefined
&& data2Year === module14Data.year_of_purchase ) {
      intemizedpointsmortgageValue1 = closingCostsData['Loan Acquisition Points'] + closingCostsData['Origination Points'];
    } else {
      intemizedpointsmortgageValue1 = 0;
    }
    let intemizedpointsmortgageValue2 = 0;
    if ( module14Data !== undefined && module14Data !== ''
&& module14Data.year_of_purchase !== undefined
&& mortgage2Data !== undefined
&& mortgage2Data['Summation Data'][data2Year] !== undefined
&& closingCostsData['Mortgage Interest(1month prepaid upfront)'] !== undefined
&& data2Year === parseFloat( module14Data.year_of_purchase ) ) {
      intemizedpointsmortgageValue2 = mortgage2Data['Summation Data'][data2Year].LaggingInterest
+ closingCostsData['Mortgage Interest(1month prepaid upfront)'];
    } else if ( mortgage2Data !== undefined && mortgage2Data['Summation Data'] !== undefined
&& mortgage2Data['Summation Data'][data2Year] !== undefined ) {
      intemizedpointsmortgageValue2 = mortgage2Data['Summation Data'][data2Year].LaggingInterest;
    } else {
      intemizedpointsmortgageValue2 = 0;
    }
    let intemizedpointsmortgageValue3 = 0;
    if ( realEstateValue !== undefined
&& realEstateValue[data2Year] !== undefined ) {
      intemizedpointsmortgageValue3 = realEstateValue[data2Year].Home;
    }
    intemizedpointsmortgageValue = parseFloat( intemizedpointsmortgageValue1 )
+ parseFloat( intemizedpointsmortgageValue2 )
+ parseFloat( intemizedpointsmortgageValue3 );
    data3[data2Year]['Deductions Available'] = intemizedpointsmortgageValue;

    // Deduction Taken
    if ( data3[data2Year]['Deductions Available'] > data3[data2Year]['Net Rental Income'] ) {
      data3[data2Year]['Deduction Taken'] = data3[data2Year]['Net Rental Income'];
    } else {
      data3[data2Year]['Deduction Taken'] = data3[data2Year]['Deductions Available'];
    }

    // Passive Loss Created
    if ( data3[data2Year]['Deduction Taken'] < data3[data2Year]['Deductions Available'] ) {
      data3[data2Year]['Passive Loss Created'] = data3[data2Year]['Deductions Available'] - data3[data2Year]['Deduction Taken'];
    } else {
      data3[data2Year]['Passive Loss Created'] = 0;
    }

    // Passive Losses Beginning Balance
    if ( p === 0 ) {
      data3[data2Year]['Passive Losses Beginning Balance'] = 0;
    } else {
      data3[data2Year]['Passive Losses Beginning Balance'] = remainingPassiveLosses;
    }
    p += 1;
    // Passive Loss Created Second
    data3[data2Year]['Passive Loss Created Second'] = data3[data2Year]['Passive Loss Created'];

    // Passive Losses Balance
    data3[data2Year]['Passive Losses Balance'] = data3[data2Year]['Passive Losses Beginning Balance'] + data3[data2Year]['Passive Loss Created Second'];

    // REI Property Taxable Income
    if ( taxStatementData !== undefined && taxStatementData !== '' && taxStatementData[data2Year] !== undefined && taxStatementData[data2Year] !== ''
&& taxStatementData[data2Year]['REI Property Taxable Income (Passive Loss Created)'] !== undefined && taxStatementData[data2Year]['REI Property Taxable Income (Passive Loss Created)'] > 0 ) {
      data3[data2Year]['REI Property Taxable Income'] = taxStatementData[data2Year]['REI Property Taxable Income (Passive Loss Created)'];
    } else {
      data3[data2Year]['REI Property Taxable Income'] = 0;
    }

    data3[data2Year]['Total Taxable Income to offset with Passive Losses'] = data3[data2Year]['Total Gross Income (excluding Rental Income)'] + data3[data2Year]['REI Property Taxable Income'];

    // Passive Losses Available, Max Loss Allowed, Max Loss Allowed after Phaseouts, Passive Losses Taken
    data3[data2Year]['Passive Losses Available'] = data3[data2Year]['Passive Losses Balance'];
    if ( databaseData !== undefined && databaseData !== '' && databaseData['Passive Loss Max Limit'] !== undefined && databaseData['Passive Loss Max Limit'][data2Year] !== undefined && databaseData['Passive Loss Max Limit'][data2Year] !== '' && databaseData['Passive Loss Max Limit'][data2Year]['Passive Loss Max Limit'] !== undefined ) {
      data3[data2Year]['Max Loss Allowed'] = databaseData['Passive Loss Max Limit'][data2Year]['Passive Loss Max Limit'];
    } else {
      data3[data2Year]['Max Loss Allowed'] = 0;
    }
    if ( data3[data2Year]['Total Gross Income (excluding Rental Income)'] >= passiveLossIncomePhaseoutRange1 ) {
      data3[data2Year]['Max Loss Allowed after Phaseouts'] = 0;
    } else if ( data3[data2Year]['Total Gross Income (excluding Rental Income)'] > passiveLossIncomePhaseoutRange && data3[data2Year]['Total Gross Income (excluding Rental Income)'] < passiveLossIncomePhaseoutRange1 ) {
      const minusValue = data3[data2Year]['Total Gross Income (excluding Rental Income)'] - passiveLossIncomePhaseoutRange;
      const devideValue = minusValue / ( passiveLossIncomePhaseoutRange1 - passiveLossIncomePhaseoutRange );
      data3[data2Year]['Max Loss Allowed after Phaseouts'] = ( 1 - devideValue ) * data3[data2Year]['Max Loss Allowed'];
    } else {
      data3[data2Year]['Max Loss Allowed after Phaseouts'] = data3[data2Year]['Max Loss Allowed'];
    }

    // Passive Losses Taken new changes based on 3.17.2019
    if ( data3[data2Year]['Passive Losses Available'] > 0 && data2Year === buyingExpenses['Sell Year'] ) {
      data3[data2Year]['Passive Losses Taken1'] = data3[data2Year]['Passive Losses Available'];
    } else if ( data3[data2Year]['Passive Losses Available'] > data3[data2Year]['Max Loss Allowed after Phaseouts'] ) {
      data3[data2Year]['Passive Losses Taken1'] = data3[data2Year]['Max Loss Allowed after Phaseouts'];
    } else {
      data3[data2Year]['Passive Losses Taken1'] = data3[data2Year]['Passive Losses Available'];
    }
    data3[data2Year]['Passive Losses Taken'] = data3[data2Year]['Passive Losses Taken1'];
    // Remaining Passive Losses
    remainingPassiveLosses = data3[data2Year]['Passive Losses Beginning Balance']
+ data3[data2Year]['Passive Loss Created Second']
- data3[data2Year]['Passive Losses Taken'];
    data3[data2Year]['Remaining Passive Losses'] = remainingPassiveLosses;

    // Mortgage Interest
    let mortgageInterest1 = 0;
    let mortgageInterest2 = 0;
    if ( module14Data !== undefined && module14Data !== ''
&& module14Data.year_of_purchase !== undefined
&& mortgage2Data !== undefined
&& mortgage2Data['Summation Data'][data2Year] !== undefined
&& closingCostsData['Mortgage Interest(1month prepaid upfront)'] !== undefined
&& data2Year === parseFloat( module14Data.year_of_purchase ) ) {
      mortgageInterest1 = mortgage2Data['Summation Data'][data2Year].LaggingInterest
+ closingCostsData['Mortgage Interest(1month prepaid upfront)'];
    } else if ( mortgage2Data !== undefined
&& mortgage2Data['Summation Data'][data2Year] !== undefined ) {
      mortgageInterest1 = mortgage2Data['Summation Data'][data2Year].LaggingInterest;
    } else {
      mortgageInterest1 = 0;
    }
    if ( module14Data !== undefined && module14Data !== ''
&& module14Data.year_of_purchase !== undefined
&& closingCostsData['Loan Acquisition Points'] !== undefined
&& closingCostsData['Origination Points'] !== undefined
&& data2Year === parseFloat( module14Data.year_of_purchase ) - 1 ) {
      mortgageInterest2 = -( closingCostsData['Loan Acquisition Points'] + closingCostsData['Origination Points'] );
    } else {
      mortgageInterest2 = 0;
    }
    data3[data2Year]['Mortgage Interest'] = -mortgageInterest1 + mortgageInterest2;
  }

  // Sum of Pvs
  data1['SUM of PVs'] = sumOfPVs;

  // Discount Rate
  data1['Discount Rate'] = discountRate;

  // ROI
  if ( sumNetCashFlowBeforeTaxes > 0 && ROIFirstValue !== 0 ) {
	  
    data1.ROI = parseFloat( ( ( sumNetCashFlowBeforeTaxes + ROIFirstValue ) / -( ROIFirstValue ) ) * 100 ).toFixed( 1 );
  } else {
    data1.ROI = 0.0;
  }

  // MoM
  if ( ROIFirstValue !== 0 && ROIFirstValue !== '' ) {
    data1.MoM = parseFloat( sumNetCashFlowBeforeTaxes / -( ROIFirstValue ) ).toFixed( 2 );
  } else {
    data1.MoM = 0;
  }
  // CoC if( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase > 0) {
  if ( module14Data.year_of_purchase !== undefined && module14Data.year_of_purchase > 0 && ROIFirstValue !== 0 ) {
    data1.CoC = parseFloat( ( data1[module14Data.year_of_purchase]['Net Income REI Table'] / -ROIFirstValue ) * 100 ).toFixed( 1 );
  } else {
    data1.CoC = 0;
  }

  // IRR
  if ( sellYear > 0 && purchaseYear > 0 ) {
    data1.IRR = parseFloat( ( IRRRATE( ( sellYear - purchaseYear + 1 ), 0, -1, parseFloat( data1.MoM ) ) ) * 100 ).toFixed( 1 );
  } else {
    data1.IRR = 0.0;
  }

  // Rental Income Sum
  data1['Rental Income Sum'] = rentalIncomeSum;
  data1['REI Total Operating Expenses'] = reiTotalOperationgExpenses;
  data1['REI Total Financing Expenses'] = REITotalFinancingExpenses;
  data1['Net Cash Flow Before Taxes Sum'] = sumNetCashFlowBeforeTaxes;

  // Property Expenses Sum
  data1['Property Expenses Sum'] = propertyExpensesSum;

  // To return Student Loan Complete Data
  function invPropertyRoiCompleteData() {
    const invPropertyRoiInfo = {};
    invPropertyRoiInfo['Closing Cost'] = closingCostsData;
    invPropertyRoiInfo['Buying Expenses'] = buyingExpenses;
    invPropertyRoiInfo['Cash In'] = cashIn;
    invPropertyRoiInfo['Cash Out'] = cashOut;
    invPropertyRoiInfo['Real Estate'] = realEstateValue;
    invPropertyRoiInfo.Data2 = data2;
    invPropertyRoiInfo.Data3 = data3;
    invPropertyRoiInfo.Data1 = data1;
    invPropertyRoiInfo['SUM of PVs'] = data1['SUM of PVs'];
    invPropertyRoiInfo['Discount Rate'] = data1['Discount Rate'];
    invPropertyRoiInfo.ROI = data1.ROI;
    invPropertyRoiInfo.MoM = data1.MoM;
    invPropertyRoiInfo.CoC = data1.CoC;
    invPropertyRoiInfo.IRR = data1.IRR;
    invPropertyRoiInfo['Rental Income Sum'] = data1['Rental Income Sum'];
    invPropertyRoiInfo['Property Expenses Sum'] = data1['Property Expenses Sum'];

    invPropertyRoiInfo['Selling Costs'] = sellingCosts;

    invPropertyRoiInfo['Total on Accumulated'] = taxOnAccumulatedTotal;
    invPropertyRoiInfo['Total on Gain'] = taxOnGainTotal;
    invPropertyRoiInfo['REI Total'] = reiTotal;

    invPropertyRoiInfo['Rei Start Year'] = reiStartYear;
    invPropertyRoiInfo['Rei End Year'] = reiEndYear;
    invPropertyRoiInfo['Years List'] = yearsListArr;
    invPropertyRoiInfo['Return Calculations Year List'] = ReturnYearsListArr;
    invPropertyRoiInfo['Real Estate Asset Value'] = assetValueCal;
    invPropertyRoiInfo.netCashFlowBeforeTaxesValues = netCashFlowBeforeTaxesValues;
	return invPropertyRoiInfo; 

  }
  return invPropertyRoiCompleteData();
}
export default invPropertyRoiSheetData;
