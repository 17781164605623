import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import {
  Typography,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import CareerBottom from './career-bottom';
import styles from '../../screens/pwi-career-path/styles';
import * as modulesServices from '../../calculations/modules-services';
import { formatDollar } from '../../utilities/chartCommonFunctions';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  colors: ['#389BD2', '#FF7322', '#A7A39E', '#FFBE00', '#2A70BF', '#58AD4B', '#12668D', '#B04400', '#63625D', '#636363'],
});
HCExporting(Highcharts);

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const CareerBottomTabs = (props) => {
  const {
    classes, handleCareerObject
  } = props;
  const [value, setValue] = useState(0);
  const [annualPieChart, setAnnualPieChart] = useState({});
  const [barGraphData, setBarGraphData] = useState({});
  const [yearsList, setYearsList] = useState([]);

  console.log('annualPieChart----',annualPieChart);
  console.log('barGraphData----',barGraphData);


  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    const careerAdvOutputData = modulesServices.careerOutputGraphData();
    let annualCareerOutputPieChartData = {};
    annualCareerOutputPieChartData = careerAdvOutputData['Annual Chart'];
    setAnnualPieChart(annualCareerOutputPieChartData);

    let careerOutputBarGraphYearslist = [];
    let careerOutputBarGraphData = {};
    careerOutputBarGraphData = careerAdvOutputData['Bar Graph'];
    careerOutputBarGraphYearslist = careerOutputBarGraphData === undefined ? [] : careerOutputBarGraphData.YearsList;
    setBarGraphData(careerOutputBarGraphData);
    setYearsList(careerOutputBarGraphYearslist);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.contentBlock}>
      <div className={classes.tabSection}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          className={classes.tabButtons}
          aria-label="full width tabs example"
        >
          <Tab className={value === 0 ? classes.activeTab : ''} label="Table View " {...a11yProps(0)} />
          <Tab className={value === 1 ? classes.activeTab : ''} label="Annual Budget" {...a11yProps(1)} />
          <Tab className={value === 2 ? classes.activeTab : ''} label="Cash Flow Summary" {...a11yProps(2)} />
        </Tabs>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          className={classes.tabsContent}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <CareerBottom handleCareerObject={handleCareerObject} />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <div className={classes.borderDiv}>
              <h3>My Annual Budget</h3>
              <div className={classes.blueHr} />
              {(annualPieChart && annualPieChart.Total !== 0) ? (
                <HighchartsReact 
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      plotBackgroundColor: '#ffffff',
                      plotShadow: false,
                      type: 'pie',
                    },
                    title: {
                      text: '',
                    },
                    navigation: {
                      menuItemStyle: {
                        fontSize: '14px',
                        textAlign: 'left',
                      },
                      menuItemHoverStyle: {
                        background: '#f5f5f5',
                        color: '#4c4c4c',
                        fontSize: '14px',
                      },
                      buttonOptions: {
                        height: 40,
                        width: 48,
                        symbolSize: 24,
                        symbolX: 24,
                        symbolY: 21,
                        symbolStrokeWidth: 2,
                        _titleKey: 'y',
                      },
                    },
                    exporting: {
                      buttons: {
                        contextButton: {
                          menuItems: [{
                            textKey: 'downloadPNG',
                            onclick() {
                              const chart = this;
                              chart.exportChart();
                            },
                          }, {
                            textKey: 'downloadJPEG',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/jpeg',
                              });
                            },
                          }, {
                            textKey: 'downloadPDF',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'application/pdf',
                              });
                            },
                          }, {
                            textKey: 'downloadSVG',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/svg+xml',
                              });
                            },
                          }],
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    tooltip: {
                      // eslint-disable-next-line
                      pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%',
                    },
                    plotOptions: {
                      pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                          enabled: true,
                          // eslint-disable-next-line
                          format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
                          style: {
                            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                            fontSize: '14px',
                          },
                        },
                      },
                    },
                    series: [{
                      type: 'pie',
                      data: [
                        ['Total Taxes Paid', annualPieChart['Total Taxes Paid']],
                        ['Rent', annualPieChart.Rent],
                        ['Utilities', annualPieChart.Utilities],
                        ['Food', annualPieChart.Food],
                        ['Clothing & Personal Care', annualPieChart['Clothing & Personal Care']],
                        ['Entertainment', annualPieChart.Entertainment],
                        ['Technology', annualPieChart.Technology],
                        ['Transportation', annualPieChart.Transportation],
                        ['Miscellaneous', annualPieChart.Miscellaneous],
                        ['Healthcare & Insurance', annualPieChart['Healthcare & Insurance']],
                        ['Higher Education Expenses', annualPieChart['Higher Education Expenses']],
                        ['Children Expenses', annualPieChart['Children Expenses']],
                        ['Pet Expenses', annualPieChart['Pet Expenses']],
                        ['Car Expenses', annualPieChart['Car Expenses']],
                        ['Real Estate Expenses', annualPieChart['Real Estate Expenses']],
                        ['Loan Payments', annualPieChart['Loan Payments']],
                        ['Retirement Contributions', annualPieChart['Retirement Contributions']],
                      ].filter((d) => d[1] > 0),
                    }],
                  }}
                />
              ): <span className={classes.noData}>No Graph data found</span>}

            </div>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <div className={classes.borderDiv}>
              <h3>Cash Flow Summary</h3>
              <div className={classes.blueHr} />
              {barGraphData && barGraphData.YearsList && barGraphData.YearsList.length !== 0 ? 
              <HighchartsReact
                highcharts={Highcharts}
                options={
                  {
                    chart: {
                      plotBackgroundColor: '#ffffff',
                      plotBorderColor: '#cccccc',
                      plotBorderWidth: 1,
                      plotShadow: false,
                      type: 'column',
                    },
                    navigation: {
                      menuItemStyle: {
                        fontSize: '14px',
                        textAlign: 'left',
                      },
                      menuItemHoverStyle: {
                        background: '#f5f5f5',
                        color: '#4c4c4c',
                        fontSize: '14px',
                      },
                      buttonOptions: {
                        height: 40,
                        width: 48,
                        symbolSize: 24,
                        symbolX: 24,
                        symbolY: 21,
                        symbolStrokeWidth: 2,
                        verticalAlign: 'bottom',
                        _titleKey: 'y',
                      },
                    },
                    exporting: {
                      buttons: {
                        contextButton: {
                          menuItems: [{
                            textKey: 'downloadPNG',
                            onclick() {
                              const chart = this;
                              chart.exportChart();
                            },
                          }, {
                            textKey: 'downloadJPEG',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/jpeg',
                              });
                            },
                          }, {
                            textKey: 'downloadPDF',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'application/pdf',
                              });
                            },
                          }, {
                            textKey: 'downloadSVG',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/svg+xml',
                              });
                            },
                          }],
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    title: {
                      text: '',
                    },
                    xAxis: {
                      categories: yearsList,
                      labels: {
                        style: {
                          color: '#000000',
                          fontSize: '14px',
                        },
                      },
                    },
                    yAxis: [{ // Primary yAxis
                      labels: {
                        formatter() {
                          const chart = this;
                          if (chart.value < 0) {
                            return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                          }
                          return formatDollar(Math.round(chart.value));
                        },
                        style: {
                          color: '#000000',
                          fontSize: '14px',
                        },
                      },
                      title: {
                        text: '',
                        style: {
                          color: '#000000',
                        },
                      },
                    }],
                    tooltip: {
                      formatter() {
                        const chart = this;
                        return `<b>${chart.x}</b><br/>${chart.series.name}: $${chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}<br/>`
                          + `Total: $${chart.point.stackTotal.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
                      },
                    },
                    legend: {
                      itemStyle: {
                        fontSize: '14px',
                      },
                    },
                    plotOptions: {
                      column: {
                        stacking: 'normal',
                      },
                    },
                    series: [{
                      name: 'Rental Income',
                      data: barGraphData === undefined ? [] : barGraphData['Net Rental Income'],
                      stack: 'male',
                      color: '#9DC3E6',
                    }, {
                      name: 'Spouse\'s Income',
                      data: barGraphData === undefined ? [] : barGraphData['Spouse Income'],
                      stack: 'male',
                      color: '#FFC000',
                    }, {
                      name: 'Supplementary Income',
                      data: barGraphData === undefined ? [] : barGraphData['Supplementary Income'],
                      stack: 'male',
                      color: '#C5E0B4',
                    }, {
                      name: 'Bonus / Tips / Commission',
                      data: barGraphData === undefined ? [] : barGraphData['Bonus / Tips / Commission'],
                      stack: 'male',
                      color: '#70AD47',
                    }, {
                      name: 'Base Income',
                      data: barGraphData === undefined ? [] : barGraphData['Base Income'],
                      stack: 'male',
                      color: '#0070c0',
                    }, {
                      name: 'Living Expenses',
                      data: barGraphData === undefined ? [] : barGraphData['Living Expenses'],
                      stack: 'female',
                      color: '#C55A11',
                    }, {
                      name: 'Retirement Contributions',
                      data: barGraphData === undefined ? [] : barGraphData['Retirement Contributions'],
                      stack: 'female',
                      color: '#203864',
                    }, {
                      name: 'Estimated Taxes',
                      data: barGraphData === undefined ? [] : barGraphData.Taxes,
                      stack: 'female',
                      color: '#7C7C7C',
                    }, {
                      name: 'Loan Payments',
                      data: barGraphData === undefined ? [] : barGraphData['Loan Payments'],
                      stack: 'female',
                      color: '#997300',
                    }, {
                      name: 'Real Estate Expenses',
                      data: barGraphData === undefined ? [] : barGraphData['Real Estate Expenses'],
                      stack: 'female',
                      color: '#F8CBAD',
                    }, {
                      name: 'Higher Education Expenses',
                      data: barGraphData === undefined ? [] : barGraphData['Higher Education Expenses'],
                      stack: 'female',
                      color: '#7030A0',
                    }, {
                      name: 'Children Expenses',
                      data: barGraphData === undefined ? [] : barGraphData['Children Expenses'],
                      stack: 'female',
                      color: '#00FFCC',
                    }, {
                      name: 'Pet Expenses',
                      data: barGraphData === undefined ? [] : barGraphData['Pet Expenses'],
                      stack: 'female',
                      color: '#C00000',
                    }, {
                      name: 'Car Expenses',
                      data: barGraphData === undefined ? [] : barGraphData['Car Expenses'],
                      stack: 'female',
                      color: '#9999FF',
                    }],
                  }
                }
              /> :
              <span className={classes.noData}>No Graph data found</span>
              }
            </div>
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
};

CareerBottomTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
};

export default withStyles(styles)(CareerBottomTabs);
