import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import infoIcon from '../../../assets/img/pwi-rei/info-icon.svg';
import * as modulesServices from '../../../calculations/modules-services';

const marks = [];
const costmarks = [];
for (let i = 0; i <= 10; i += 2) {
  marks.push({
    value: i,
    label: i === 0 ? '0% of Purchase value' : `${i}%`,
  });
}

for (let i = 0; i <= 7; i += 1) {
  costmarks.push({
    value: i,
    label: i === 0 ? '0% of Sales Price' : `${i}%`,
  });
}

const SalePriceAppreciation = (props) => {
  const {
    classes, inputData, updateData, handleDefinationPopup,
  } = props;
  const [appreciation, setAppreciation] = React.useState(0);
  const [sellingYearMarks, setSellingYearMarks] = React.useState([]);
  const [sellingYear, setSellingYear] = React.useState(0);
  const [selling, setSelling] = React.useState(0);
  const [sellingValue, setSellingValue] = React.useState(0);
  const [isFocus, setIsFocus] = React.useState('');

  let purchaseYear = new Date().getFullYear();
  let currentYear = new Date().getFullYear();
  if (inputData.year_of_purchase !== undefined && inputData.year_of_purchase !== '') {
    purchaseYear = inputData.year_of_purchase;
  }

  useEffect(() => {
    const sellingMarks = [];
    for (let j = 0; j <= 30; j += 3) {
      sellingMarks.push({
        value: j + purchaseYear,
        label: j + purchaseYear,
      });
    }
    setSellingYearMarks(sellingMarks);
    let inputDetails = { ...inputData };
    if (inputData.year_of_sale !== undefined) {
      setSellingYear(inputData.year_of_sale);
    } else {
      inputDetails['year_of_sale'] = purchaseYear + 5;
      setSellingYear(purchaseYear + 5);
    }

    let appreciationVal = 0;
    if (inputData.appreciation_in_percentage !== undefined) {
      appreciationVal = inputData.appreciation_in_percentage;
    } else {
      appreciationVal = 0.5;
      inputDetails['appreciation_in_percentage'] = appreciationVal;
    }
    setAppreciation(appreciationVal);
    const sellingPrice = modulesServices.module14Data.salesPrice;
    if (inputData.selling_costs_in_percentage !== undefined) {
      setSelling(inputData.selling_costs_in_percentage);
      setSellingValue((sellingPrice / 100) * inputData.selling_costs_in_percentage);
    } else {
      setSelling(1.5);
      setSellingValue((sellingPrice / 100) * 1.5);
      inputDetails['selling_costs_in_percentage'] = 1.5;
    }
    updateData('total_obj', inputDetails);

    // eslint-disable-next-line
  }, []);

  const updateValue = (e, value, field, mainField, type) => {
    let newvalue = 0;
    if (type === 'input') {
      newvalue = e.target.value;
    } else if (type === 'slider') {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    let inputDetails = { ...inputData };
    const sellingPrice = modulesServices.module14Data.salesPrice;
    switch (field) {
      case 'appreciation': {
        if (newvalue > 100) {
          newvalue = 100;
        }
        setAppreciation(newvalue);
        inputDetails[mainField] = newvalue;
        updateData('total_obj', inputDetails);
        break;
      }
      case 'selling_year': {
        setSellingYear(newvalue);
        inputDetails[mainField] = newvalue;
        updateData('total_obj', inputDetails);
        break;
      }
      case 'selling': {
        if (newvalue > 100) {
          newvalue = 100;
        }
        setSelling(newvalue);
        inputDetails[mainField] = newvalue;
        updateData('total_obj', inputDetails);

        setSellingValue((sellingPrice / 100) * newvalue);
        break;
      }
      case 'selling_value': {
        if (newvalue > sellingPrice) {
          newvalue = sellingPrice;
        }
        setSellingValue(newvalue);
        let sellingVal = 0;
        if (newvalue > 0 && sellingPrice > 0) {
          sellingVal = (newvalue / sellingPrice) * 100;
        }
        setSelling(sellingVal);
        inputDetails[mainField] = sellingVal;
        updateData('total_obj', inputDetails);
        break;
      }
      default: {
        break;
      }
    }
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>
      <p>Appreciation is the increase in a property&apos;s value over time. How much the property appreciates each year depends on several factors including the location and any improvements made to the property.</p>
      <p>

        <a className={classes.anchorStyle} href="https://www.zillow.com/home-values/" target="_blank" rel="budget-fy2020 noopener noreferrer">According to Zillow</a>
        ,
        {' '}
        home prices increased 4.9% over the last year and are estimated to appreciate 2.2% next year. Use Zillow to research the property you want and discover the appreciation in your area. Then, input that below and see how your property value grows over time and see how that affects your returns.
        {' '}
      </p>
      <div className={classes.purchaseSlideScroll}>
        <div className={classes.transferSlide}>
          <div className={classNames(classes.flexDisplayNew, classes.transferSlider)}>
            <div className={classes.sliderFlex}>
              <div>
                <p>
                  <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Appreciation: Real Estate')} className={classes.dottedText}>Appreciation</span>
                </p>
                <div className={classes.pwisliderInputs}>
                  <PwiSlider
                    value={appreciation}
                    ValueLabelComponent={ValueLabelComponent}
                    valueLabelFormat={percentFormatWithTwoDecimal}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={0}
                    max={10}
                    step={0.1}
                    onChange={(e, value) => updateValue(e, value, 'appreciation', 'appreciation_in_percentage', 'slider')}
                    marks={marks}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(classes.sliderValues, classes.appreciationValue)}>
              <div>
                <NumberFormat
                  decimalScale={isFocus!=='appreciation'?2:5}
                  fixedDecimalScale={isFocus!=='appreciation'}
                  allowNegative={false}
                  customInput={TextField}
                  thousandSeparator
                  value={appreciation}
                  onValueChange={(e) => updateValue(e, '', 'appreciation', 'appreciation_in_percentage', 'number')}
                  onFocus={(e) => {e.target.select(); setIsFocus('appreciation')}}
                  onBlur={emptyIsFocus} 
                />
                <span className={classes.percentSymbol}>%</span>
              </div>
            </div>
          </div>
          <div className={classes.flexDisplayNew}>
            <div className={classes.sliderFlex}>
              <div>
                <p>Sale Year</p>
                <div className={classes.pwisliderInputs}>
                  <PwiSlider
                    value={sellingYear}
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={purchaseYear}
                    max={purchaseYear + 30}
                    step={1}
                    onChange={(e, value) => updateValue(e, value, 'selling_year', 'year_of_sale', 'slider')}
                    marks={sellingYearMarks}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(classes.sliderValues, classes.appreciationValue)}>
              <div>
                {sellingYear > 0 ? sellingYear : currentYear}
              </div>
            </div>
          </div>
          <div className={classNames(classes.flexDisplayNew, classes.sliderHeightNew)}>
            <div className={classNames(classes.sliderFlex, classes.fullwidthSec)}>
              <div>
                <p>&nbsp;</p>
                <div className={classes.pwisliderInputs}>
                  <p>
                    <b>
                      Sale Price in Year
                      {' '}
                      {sellingYear > 0 ? sellingYear : currentYear}
                    </b>
                  </p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.sliderValues, classes.appreciationValue)}>
              <div>
                <b>
                  <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module14Data.salesPrice} displayType="text" thousandSeparator prefix={modulesServices.module14Data.salesPrice >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.salesPrice < 0 && ')'} />
                </b>
              </div>
            </div>
          </div>
          <div className={classes.flexDisplayNew}>
            <div className={classes.sliderFlex}>
              <div>
                <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Selling Costs')} className={classes.dottedText}>Selling Costs</span></p>
                <div className={classes.pwisliderInputs}>
                  <PwiSlider
                    value={selling}
                    ValueLabelComponent={ValueLabelComponent}
                    valueLabelFormat={percentFormatWithTwoDecimal}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={0}
                    max={7}
                    step={0.1}
                    onChange={(e, value) => updateValue(e, value, 'selling', 'selling_costs_in_percentage', 'slider')}
                    marks={costmarks}
                  />
                </div>
              </div>
            </div>
            <div className={classNames(classes.sliderValues, classes.appreciationValue)}>
              <div>
                <span className={classes.dollerSymbol}>$</span>
                <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={sellingValue} onValueChange={(e) => updateValue(e, '', 'selling_value', 'selling_costs_in_percentage', 'number')} onFocus={(e) => e.target.select()} />
              </div>
            </div>
          </div>
          <div className={classes.flexDisplayNew}>
            <div className={classNames(classes.sliderFlex, classes.fullwidthSec)}>
              <div>
                <p>&nbsp;</p>
                <div className={classes.pwisliderInputs}>
                  <p><b>Mortgage Balance Remaining in Year {sellingYear > 0 ? sellingYear : currentYear}</b></p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.sliderValues, classes.appreciationValue)}>
              <div>
                <b>
                  <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module14Data.mortgageBalanceRemaining} displayType="text" thousandSeparator prefix={modulesServices.module14Data.mortgageBalanceRemaining >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.mortgageBalanceRemaining < 0 && ')'} />
                </b>
              </div>
            </div>
          </div>
          <div className={classes.flexDisplayNew}>
            <div className={classNames(classes.sliderFlex, classes.fullwidthSec)}>
              <div>
                <p>&nbsp;</p>
                <div className={classes.pwisliderInputs}>
                  <p>
                    <span className={classes.infoIcon}><img aria-hidden="true" data-for="normalTitle" data-tip="Selling price of the rental property minus selling cost minus mortgage balance remaining at time of sale equals net cash flow from sale. Click on the summary worksheet button above to see more details." src={infoIcon} alt="" /></span>
                    {' '}
                    Net Cash Flow from Sale
                  </p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.sliderValues, classes.appreciationValue)}>
              <div>
                <b>
                  <NumberFormat decimalScale={0} fixedDecimalScale value={modulesServices.module14Data.netCashFlowFromSale} displayType="text" thousandSeparator prefix={modulesServices.module14Data.netCashFlowFromSale >= 0 ? '$' : '($'} suffix={modulesServices.module14Data.netCashFlowFromSale < 0 && ')'} />
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
      <ReactTooltip id="normalTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.tooltipInfoTopSelling)} />
    </div>
  );
};

SalePriceAppreciation.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles(styles)(SalePriceAppreciation);
