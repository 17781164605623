import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruEight = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack, walkThruNo
    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruEight)}>
            {walkThruNo === 1 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. In the Financial Independence module, the Show Me section transforms your data for 5, 10, 15 years. After you build and save multiple scenarios or career sketches, come back to the Financial Independence module's Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 2 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 3 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 4 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 5 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 6 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 7 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 8 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts, graphs, and tables that help you analyze your data. Click the 'SHOW TABLE' button for a downloadable PDF of your sources and uses, annual cash flows, returns and more.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 9 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 10 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 11 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 12 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 14 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts and graphs that help you analyze your data. Click the 'REFRESH' button if you change your data or load a different scenario.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 15 ?
                <div>
                    <h4>Show Me</h4>
                    <p>The Show Me section transforms your data into visualizations, so you can understand your data at a deeper level. After you build and save a scenario, scroll down to the Show Me section to see interactive charts, graphs, and tables that help you analyze your data. Click the 'SHOW TABLE' button for a downloadable PDF of your sources and uses, annual cash flows, returns and more.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

WalkThruEight.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruEight);