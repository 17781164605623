
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import * as commonFunctions from '../../../utilities/commonFunctions';
import * as moduleServices from '../../../calculations/modules-services';
// import CareerBottomTabs from './career-bottom-tab-graphs';
import styles from '../../../screens/pwi-aca/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const arrayValuesMatching = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3%',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6%',
  },
  {
    value: 9,
    scaledValue: 9,
    label: '9%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 15,
    scaledValue: 15,
    label: '15%',
  },
];
const HSASummary = ( props ) => {
  const {
    classes, handleCareerObject, handleUpdatedObject,
  } = props;
  const [personalValue, setPeronalValue] = React.useState( 7.00 );
  // const [macthingValue, setMatchingValue] = React.useState( 7 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.ira_annual_expected_pre_tax !== undefined ) {
      setPeronalValue( handleCareerObject.ira_annual_expected_pre_tax );
    } else {
      updatedValues['ira_annual_expected_pre_tax'] = 7.00;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue;
      newvalue = newvalue > 100 ? 100 : newvalue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    }

    setPeronalValue( newvalue );
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>How much do you expect to earn on your SEP IRA contributions? Enter your annual return below.</p>
      <div>
        <div className={ classes.AnnualIncomeGrowth }>
          <div>
            <p><span className={ classes.noDottedText }>Annual Expected Pre-Tax Return</span></p>
            <div className={ classes.annuvalIncomeGrowthGraph }>
              <PwiSlider
                ValueLabelComponent={ ValueLabelComponent }
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={ arrayValuesMatching }
                min={ 0 }
                max={ 15 }
                step={ 0.1 }
                value={ personalValue }
                valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( personalValue ).toFixed( 2 ) )}` }
                onChange={ ( e, value ) => updateValue( e, value, 'ira_annual_expected_pre_tax', 'slider' ) }
              />
            </div>
            <div className={ classes.annuvalIncomeGrowthInput }>
              <NumberFormat
                customInput={ TextField }
                thousandSeparator
                decimalScale = { isFocus!=='ira_annual_expected_pre_tax'?2:5 }
                value={ personalValue }
                fixedDecimalScale={isFocus!=='ira_annual_expected_pre_tax'}
                onValueChange={ ( e ) => updateValue( e, '', 'ira_annual_expected_pre_tax', 'number' ) }
                onFocus={ ( e ) => {
                  e.target.select();
                  setIsFocus('ira_annual_expected_pre_tax');
                } }
                onBlur={emptyIsFocus}
              />
              %
            </div>
          </div>
        </div>
      </div>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70New}` }>
          <table cellPadding="0" cellSpacing="0">
            <thead class="">
              <tr className={ classes.trHead }>
                <td colspan="2">SEP IRA Retirement Account Summary</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Beginning SEP IRA Balance
                </td>
                <td>
                  {moduleServices.module8Data.addCareerSEPIRABeginningBalance !== undefined ? (
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerSEPIRABeginningBalance} prefix={moduleServices.module8Data.addCareerSEPIRABeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerSEPIRABeginningBalance < 0 && ')'} />
                  ) : ('$0')}
                </td>
              </tr>
              <tr>
                <td>
                  Total Contributions
                </td>
                <td>
                  <span>
                    {moduleServices.module8Data.addCareerSEPIRATotalContribution !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerSEPIRATotalContribution} prefix={moduleServices.module8Data.addCareerSEPIRATotalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerSEPIRATotalContribution < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Total Earnings
                </td>
                <td>
                  <span>
                    {moduleServices.module8Data.addCareerSEPIRATotalEarnings !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerSEPIRATotalEarnings} prefix={moduleServices.module8Data.addCareerSEPIRATotalEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerSEPIRATotalEarnings < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                <td>
                  Ending SEP IRA Balance
                </td>
                <td>
                  <span>                                            
                    {moduleServices.module8Data.addCareerEndingSEPIRABalance !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.addCareerEndingSEPIRABalance} prefix={moduleServices.module8Data.addCareerEndingSEPIRABalance >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.addCareerEndingSEPIRABalance < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

HSASummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( HSASummary );
