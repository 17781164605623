import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from './styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );

HCExporting( Highcharts );

const Budget = ( props ) => {
  const { classes } = props;

  const budgetDefaultValues = {
    salary: 0,
    side_job: 0,
    other_income: 0,
    taxes: 0,
    rent_utilities: 0,
    food_drink: 0,
    clothing_personal: 0,
    entertainment_tech: 0,
    transportation_gas: 0,
    car_lease_payment: 0,
    car_insurance_maintenance: 0,
    home_expenses: 0,
    health_insurance_premium: 0,
    other_healthcare_costs: 0,
    pet: 0,
    miscellaneous: 0,
    credit_card_debt: 0,
    student_loans: 0,
    car_loans: 0,
    mortgages: 0,
    rent_utilities_percentage: 0,
    food_drink_percentage: 0,
    clothing_personal_percentage: 0,
    entertainment_tech_percentage: 0,
    transportation_gas_percentage: 0,
    car_lease_payment_percentage: 0,
    car_insurance_maintenance_percentage: 0,
    home_expenses_annual_percentage: 0,
    health_insurance_premium_percentage: 0,
    other_healthcare_costs_percentage: 0,
    pet_percentage: 0,
    miscellaneous_percentage: 0,
    credit_card_debt_percentage: 0,
    student_loans_percentage: 0,
    car_loans_percentage: 0,
    mortgages_percentage: 0,

  };

  const [graphInputValues, setGraphInputValues] = useState( { monthlySavings: 0, annualReturn: 0 } );
  const [budgetValues, setBudgetValues] = useState( budgetDefaultValues );
  const [graphView, setGraphView] = useState( {} );
  const [yearsValues, setYearsValues] = useState( {} );

  const updateValue = ( e, field ) => {
    if ( field === 'monthlySavings' || field === 'annualReturn' ) {
      const valuesUpdated = { ...graphInputValues };
      valuesUpdated[field] = e.value ? e.floatValue : 0;
      setGraphInputValues( valuesUpdated );
    } else {
      const valuesUpdated = { ...budgetValues };
      valuesUpdated[field] = e.value ? e.floatValue : 0;
      setBudgetValues( valuesUpdated );
    }
  };

  const handleCalculate = ( monthlySavings, annualRate ) => {
    const graphArrayValues = [];
    const graphArrayValues1 = [];
    const graphArrayValues2 = [];
    const graphArrayYearsValues = [];
    const yearDataValues = {};
    for ( let y = 1; y <= 30; y += 1 ) {
      // Default Return(5%)
      const endingValue = commonFunctions.FV( ( annualRate / 12 ) / 100, y * 12, -monthlySavings, 0, 0 );
      graphArrayValues.push( endingValue );
      if ( y === 5 ) {
        yearDataValues.fifthValue = endingValue;
      }
      if ( y === 15 ) {
        yearDataValues.tenthValue = endingValue;
      }
      if ( y === 30 ) {
        yearDataValues.fifteenthValue = endingValue;
      }

      budgetValues.firstReturn = annualRate;
      budgetValues.firstReturnLegend = parseFloat( budgetValues.firstReturn ).toFixed( 2 );

      // Second Return(annualRate/2)
      budgetValues.secondReturn = parseInt( annualRate, 10 ) / 2;
      budgetValues.secondReturnLegend = parseFloat( budgetValues.secondReturn ).toFixed( 2 );
      const endingValue1 = commonFunctions.FV( ( budgetValues.secondReturn / 12 ) / 100, y * 12, -monthlySavings, 0, 0 );
      graphArrayValues1.push( endingValue1 );

      // Second Return(annualRate + annualRate/2)
      budgetValues.thirdReturn = parseInt( annualRate, 10 ) + ( parseInt( annualRate, 10 ) / 2 );
      budgetValues.thirdReturnLegend = parseFloat( budgetValues.thirdReturn ).toFixed( 2 );
      const endingValue2 = commonFunctions.FV( ( budgetValues.thirdReturn / 12 ) / 100, y * 12, -monthlySavings, 0, 0 );
      graphArrayValues2.push( endingValue2 );

      graphArrayYearsValues.push( y );
    }
    setYearsValues( yearDataValues );
    const graphNew = {
      chart: {
        type: 'spline',
        scrollablePlotArea: {
          width: 600,
          scrollPositionX: 0,
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
        style: {
          color: '#000000',
          fontSize: '14px',
        },
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left',
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px',
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y',
        },
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'image/jpeg',
                } );
              },
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'application/pdf',
                } );
              },
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart( {
                  type: 'image/svg+xml',
                } );
              },
            }],
          },
        },
      },
      xAxis: [{
        tickInterval: 1,
        categories: graphArrayYearsValues,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: 'Years',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if ( chart.value < 0 ) {
              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
            }
            return formatDollar( Math.round( chart.value ) );
          },
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
        title: {
          text: '',
          style: {
            color: '#000000',
            fontSize: '14px',
          },
        },
      }],
      tooltip: {
        headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
        crosshairs: true,
        shared: true,
        valueDecimals: 0,
        valuePrefix: '$',
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 2,
          states: {
            hover: {
              lineWidth: 2,
            },
          },
          marker: {
            enabled: false,
          },

        },
      },
      legend: {
        itemStyle: {
          fontSize: '14px',
        },
      },
      series: [{
        name: `${budgetValues.secondReturnLegend}% return`,
        data: graphArrayValues1,
        color: '#A5A5A5',
      }, {
        name: `${budgetValues.firstReturnLegend}% return`,
        data: graphArrayValues,
        color: '#Ed7D31',
      }, {
        name: `${budgetValues.thirdReturnLegend}% return`,
        data: graphArrayValues2,
        color: '#4472C4',
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      },
    };
    setGraphView( graphNew );
  };

  useEffect( () => {
    if ( typeof budgetValues.salary !== 'undefined' && budgetValues.salary !== '' ) {
      budgetValues.salary_annual = ( budgetValues.salary ) * 12;
    }
    if ( typeof budgetValues.side_job !== 'undefined' && budgetValues.side_job !== '' ) {
      budgetValues.side_job_annual = ( budgetValues.side_job ) * 12;
    }
    if ( typeof budgetValues.other_income !== 'undefined' && budgetValues.other_income !== '' ) {
      budgetValues.other_income_annual = ( budgetValues.other_income ) * 12;
    }
    if ( typeof budgetValues.taxes !== 'undefined' && budgetValues.taxes !== '' ) {
      budgetValues.taxes_annual = ( budgetValues.taxes ) * 12;
    }
    // Income analysis
    if ( typeof budgetValues.salary !== 'undefined' && typeof budgetValues.side_job !== 'undefined' && typeof budgetValues.other_income !== 'undefined' && typeof budgetValues.taxes !== 'undefined' ) {
      budgetValues.total_after_tax_income = ( budgetValues.salary )
        + ( budgetValues.side_job )
        + ( budgetValues.other_income )
        - ( budgetValues.taxes );
      budgetValues.total_after_tax_income_annual = ( budgetValues.total_after_tax_income ) * 12;
    } else {
      budgetValues.total_after_tax_income_annual = 0;
    }
    if ( typeof budgetValues.rent_utilities !== 'undefined' && budgetValues.rent_utilities !== '' ) {
      budgetValues.rent_utilities_annual = ( budgetValues.rent_utilities ) * 12;
      if ( budgetValues.rent_utilities_annual ) {
        budgetValues.rent_utilities_percentage = ( budgetValues.rent_utilities_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.food_drink !== 'undefined' && budgetValues.food_drink !== '' ) {
      budgetValues.food_drink_annual = ( budgetValues.food_drink ) * 12;
      if ( budgetValues.food_drink_annual ) {
        budgetValues.food_drink_percentage = ( budgetValues.food_drink_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.clothing_personal !== 'undefined' && budgetValues.clothing_personal !== '' ) {
      budgetValues.clothing_personal_annual = ( budgetValues.clothing_personal ) * 12;
      if ( budgetValues.clothing_personal_annual ) {
        budgetValues.clothing_personal_percentage = ( budgetValues.clothing_personal_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.entertainment_tech !== 'undefined' && budgetValues.entertainment_tech !== '' ) {
      budgetValues.entertainment_tech_annual = ( budgetValues.entertainment_tech ) * 12;
      if ( budgetValues.entertainment_tech_annual ) {
        budgetValues.entertainment_tech_percentage = ( budgetValues.entertainment_tech_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.transportation_gas !== 'undefined' && budgetValues.transportation_gas !== '' ) {
      budgetValues.transportation_gas_annual = ( budgetValues.transportation_gas ) * 12;
      if ( budgetValues.transportation_gas_annual ) {
        budgetValues.transportation_gas_percentage = ( budgetValues.transportation_gas_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.car_lease_payment !== 'undefined' && budgetValues.car_lease_payment !== '' ) {
      budgetValues.car_lease_payment_annual = ( budgetValues.car_lease_payment ) * 12;
      if ( budgetValues.car_lease_payment_annual ) {
        budgetValues.car_lease_payment_percentage = ( budgetValues.car_lease_payment_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.car_insurance_maintenance !== 'undefined' && budgetValues.car_insurance_maintenance !== '' ) {
      budgetValues.car_insurance_maintenance_annual = ( budgetValues.car_insurance_maintenance ) * 12;
      if ( budgetValues.car_insurance_maintenance_annual ) {
        budgetValues.car_insurance_maintenance_percentage = ( budgetValues.car_insurance_maintenance_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.home_expenses !== 'undefined' && budgetValues.home_expenses !== '' ) {
      budgetValues.home_expenses_annual = ( budgetValues.home_expenses ) * 12;
      if ( budgetValues.home_expenses_annual ) {
        budgetValues.home_expenses_annual_percentage = ( budgetValues.home_expenses_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.health_insurance_premium !== 'undefined' && budgetValues.health_insurance_premium !== '' ) {
      budgetValues.health_insurance_premium_annual = ( budgetValues.health_insurance_premium ) * 12;
      if ( budgetValues.health_insurance_premium_annual ) {
        budgetValues.health_insurance_premium_percentage = ( budgetValues.health_insurance_premium_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.other_healthcare_costs !== 'undefined' && budgetValues.other_healthcare_costs !== '' ) {
      budgetValues.other_healthcare_costs_annual = ( budgetValues.other_healthcare_costs ) * 12;
      if ( budgetValues.other_healthcare_costs_annual ) {
        budgetValues.other_healthcare_costs_percentage = ( budgetValues.other_healthcare_costs_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.pet !== 'undefined' && budgetValues.pet !== '' ) {
      budgetValues.pet_annual = ( budgetValues.pet ) * 12;
      if ( budgetValues.pet_annual ) {
        budgetValues.pet_percentage = ( budgetValues.pet_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.miscellaneous !== 'undefined' && budgetValues.miscellaneous !== '' ) {
      budgetValues.miscellaneous_annual = ( budgetValues.miscellaneous ) * 12;
      if ( budgetValues.miscellaneous_annual ) {
        budgetValues.miscellaneous_percentage = ( budgetValues.miscellaneous_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }

    if ( typeof budgetValues.rent_utilities !== 'undefined'
      && typeof budgetValues.food_drink !== 'undefined'
      && typeof budgetValues.clothing_personal !== 'undefined'
      && typeof budgetValues.entertainment_tech !== 'undefined'
      && typeof budgetValues.transportation_gas !== 'undefined'
      && typeof budgetValues.car_lease_payment !== 'undefined'
      && typeof budgetValues.car_insurance_maintenance !== 'undefined'
      && typeof budgetValues.home_expenses !== 'undefined'
      && typeof budgetValues.health_insurance_premium !== 'undefined'
      && typeof budgetValues.other_healthcare_costs !== 'undefined'
      && typeof budgetValues.pet !== 'undefined'
      && typeof budgetValues.miscellaneous !== 'undefined' ) {
      budgetValues.total_living_expenses = ( budgetValues.rent_utilities )
        + ( budgetValues.food_drink )
        + ( budgetValues.clothing_personal )
        + ( budgetValues.entertainment_tech )
        + ( budgetValues.transportation_gas )
        + ( budgetValues.car_lease_payment )
        + ( budgetValues.car_insurance_maintenance )
        + ( budgetValues.home_expenses )
        + ( budgetValues.health_insurance_premium )
        + ( budgetValues.other_healthcare_costs )
        + ( budgetValues.pet )
        + ( budgetValues.miscellaneous );
      budgetValues.total_living_expenses_annual = budgetValues.total_living_expenses * 12;
    }

    // After tax Income
    if ( typeof budgetValues.total_after_tax_income !== 'undefined' && typeof budgetValues.total_living_expenses !== 'undefined' && budgetValues.total_after_tax_income > 0 ) {
      budgetValues.after_tax_income = ( budgetValues.total_living_expenses / budgetValues.total_after_tax_income ) * 100;
    } else {
      budgetValues.after_tax_income = 0;
    }
    if ( typeof budgetValues.total_after_tax_income_annual !== 'undefined' && typeof budgetValues.total_living_expenses_annual !== 'undefined' && budgetValues.total_after_tax_income_annual > 0 ) {
      budgetValues.after_tax_income_annual = ( budgetValues.total_living_expenses_annual / budgetValues.total_after_tax_income_annual ) * 100;
    } else {
      budgetValues.after_tax_income_annual = 0;
    }

    // Loan Payments
    if ( typeof budgetValues.credit_card_debt !== 'undefined' && budgetValues.credit_card_debt !== '' ) {
      budgetValues.credit_card_debt_annual = ( budgetValues.credit_card_debt ) * 12;
      if ( budgetValues.credit_card_debt_annual ) {
        budgetValues.credit_card_debt_percentage = ( budgetValues.credit_card_debt_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.student_loans !== 'undefined' && budgetValues.student_loans !== '' ) {
      budgetValues.student_loans_annual = ( budgetValues.student_loans ) * 12;
      if ( budgetValues.student_loans_annual ) {
        budgetValues.student_loans_percentage = ( budgetValues.student_loans_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.car_loans !== 'undefined' && budgetValues.car_loans !== '' ) {
      budgetValues.car_loans_annual = ( budgetValues.car_loans ) * 12;
      if ( budgetValues.car_loans_annual ) {
        budgetValues.car_loans_percentage = ( budgetValues.car_loans_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }
    if ( typeof budgetValues.mortgages !== 'undefined' && budgetValues.mortgages !== '' ) {
      budgetValues.mortgages_annual = ( budgetValues.mortgages ) * 12;
      if ( budgetValues.mortgages_annual ) {
        budgetValues.mortgages_percentage = ( budgetValues.mortgages_annual / budgetValues.total_after_tax_income_annual ) * 100;
      }
    }

    // After tax Income
    // Total Loan Payments
    if ( typeof budgetValues.credit_card_debt !== 'undefined'
      && typeof budgetValues.student_loans !== 'undefined'
      && typeof budgetValues.car_loans !== 'undefined'
      && typeof budgetValues.mortgages !== 'undefined' ) {
      budgetValues.total_loan_payments = ( budgetValues.credit_card_debt )
        + ( budgetValues.student_loans )
        + ( budgetValues.car_loans )
        + ( budgetValues.mortgages );
      budgetValues.total_loan_payments_annual = budgetValues.total_loan_payments * 12;
    }

    if ( typeof budgetValues.total_loan_payments !== 'undefined' && typeof budgetValues.total_after_tax_income !== 'undefined' && budgetValues.total_after_tax_income > 0 ) {
      budgetValues.after_tax_income_loans = ( budgetValues.total_loan_payments / budgetValues.total_after_tax_income ) * 100;
    } else {
      budgetValues.after_tax_income_loans = 0;
    }
    if ( typeof budgetValues.total_loan_payments_annual !== 'undefined' && typeof budgetValues.total_after_tax_income_annual !== 'undefined' && budgetValues.total_after_tax_income_annual > 0 ) {
      budgetValues.after_tax_income_loans_annual = ( budgetValues.total_loan_payments_annual / budgetValues.total_after_tax_income_annual ) * 100;
    } else {
      budgetValues.after_tax_income_loans_annual = 0;
    }

    // Total Expenses
    if ( typeof budgetValues.total_loan_payments !== 'undefined' && typeof budgetValues.total_living_expenses !== 'undefined' ) {
      budgetValues.total_expenses = budgetValues.total_loan_payments + budgetValues.total_living_expenses;
    }
    if ( typeof budgetValues.total_loan_payments_annual !== 'undefined' && typeof budgetValues.total_living_expenses_annual !== 'undefined' ) {
      budgetValues.total_expenses_annual = budgetValues.total_loan_payments_annual + budgetValues.total_living_expenses_annual;
    }

    // Total Expenses - % of After-Tax Income
    if ( typeof budgetValues.total_expenses !== 'undefined' && typeof budgetValues.total_after_tax_income !== 'undefined' && budgetValues.total_after_tax_income > 0 ) {
      budgetValues.after_tax_expenses_loans = ( budgetValues.total_expenses / budgetValues.total_after_tax_income ) * 100;
    } else {
      budgetValues.after_tax_expenses_loans = 0;
    }
    if ( typeof budgetValues.total_expenses_annual !== 'undefined' && typeof budgetValues.total_after_tax_income_annual !== 'undefined' && budgetValues.total_after_tax_income_annual > 0 ) {
      budgetValues.after_tax_expenses_loans_annual = ( budgetValues.total_expenses_annual / budgetValues.total_after_tax_income_annual ) * 100;
    } else {
      budgetValues.after_tax_expenses_loans_annual = 0;
    }

    // Cash Left Over for Savings / Invest
    if ( typeof budgetValues.total_after_tax_income !== 'undefined' && typeof budgetValues.total_expenses !== 'undefined' ) {
      budgetValues.cash_left_over_savings = budgetValues.total_after_tax_income - budgetValues.total_expenses;
    }
    if ( typeof budgetValues.total_after_tax_income_annual !== 'undefined' && typeof budgetValues.total_expenses_annual !== 'undefined' ) {
      budgetValues.cash_left_over_savings_annual = budgetValues.total_after_tax_income_annual - budgetValues.total_expenses_annual;
    }

    budgetValues.monthlySavings = budgetValues.cash_left_over_savings;
    budgetValues.monthlySavings = parseInt( budgetValues.monthlySavings, 0 );
    budgetValues.annualReturn = 5;

    setGraphInputValues( { monthlySavings: budgetValues.monthlySavings, annualReturn: budgetValues.annualReturn } );

    handleCalculate( budgetValues.monthlySavings, budgetValues.annualReturn );
    // eslint-disable-next-line
  }, [budgetValues] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.budgetValuesPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Build My Budget</li>
            </ul>
            <div className={ classes.powerContent }>
              <div className={ classes.mainRow }>
                <div className={ classes.powerRight }>
                  <div className={ classes.wonderRight }>
                    <div className={ classes.contentWon }>
                      <div className={ classes.budgetTable }>
                        <table cellPadding="20" cellSpacing="20">
                          <thead>
                            <tr>
                              <th className={ classes.lightText }><em>My Budget</em></th>
                              <th><u>Monthly</u></th>
                              <th><u>Annual</u></th>
                              <th>&nbsp;</th>
                            </tr>
                            <tr>
                              <th><u>Income</u></th>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Salary / Wage</td>
                              <td className={ classes.textBlackInput }>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.salary } onValueChange={ ( e ) => updateValue( e, 'salary' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.salary_annual !== undefined ? budgetValues.salary_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Side Job</td>
                              <td className={ classes.textBlackInput }>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.side_job } onValueChange={ ( e ) => updateValue( e, 'side_job' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.side_job_annual !== undefined ? budgetValues.side_job_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td>Other Income</td>
                              <td className={ classes.textBlackInput }>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.other_income } onValueChange={ ( e ) => updateValue( e, 'other_income' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.other_income_annual !== undefined ? budgetValues.other_income_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Taxes</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.taxes } onValueChange={ ( e ) => updateValue( e, 'taxes' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {/* <td className={ classes.textRed }>
                                {budgetValues.taxes_annual === 0
                                  & <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.taxes_annual !== undefined ? budgetValues.taxes_annual : 0 } displayType="text" prefix="$" />}
                              </td> */}
                              {budgetValues.taxes_annual > 0
                                  ? (
                                  <td className={ classes.textRed }>
                                    (
                                    <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.taxes_annual !== undefined ? budgetValues.taxes_annual : 0 } displayType="text" prefix="$" />
                                    )                                    
                                  </td>)
                                  :
                                 ( <td className={ classes.textRed }>                                    
                                    <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.taxes_annual !== undefined ? budgetValues.taxes_annual : 0 } displayType="text" prefix="$" />
                                                                       
                                  </td>
                                  )}

                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classNames( classes.topBorderRow, classes.spaceRow ) }>
                              <td>Total After-Tax Income</td>
                              {budgetValues.total_after_tax_income < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_after_tax_income !== undefined ? -( budgetValues.total_after_tax_income ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_after_tax_income >= 0 && (
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_after_tax_income !== undefined ? budgetValues.total_after_tax_income : 0 } displayType="text" prefix="$" />
                              </td>
                              )}

                              {budgetValues.total_after_tax_income_annual < 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_after_tax_income_annual !== undefined ? -( budgetValues.total_after_tax_income_annual ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_after_tax_income_annual >= 0 && (
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_after_tax_income_annual !== undefined ? budgetValues.total_after_tax_income_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>&nbsp;</td>
                            </tr>
                            <tr className={ classes.emptyRow } />
                            <tr>
                              <th colSpan="3" className={ classes.bottomText }><u>Living Expenses</u></th>
                              <th className={ classes.italicLightText }>
                                <i>
                                  % of After-
                                  <br />
                                  Tax Income
                                </i>
                              </th>
                            </tr>
                            <tr>
                              <td>Rent and Utilities</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.rent_utilities } onValueChange={ ( e ) => updateValue( e, 'rent_utilities' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.rent_utilities_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.rent_utilities_annual !== undefined ? budgetValues.rent_utilities_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.rent_utilities_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.rent_utilities_annual !== undefined ? budgetValues.rent_utilities_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.rent_utilities_percentage !== undefined ? budgetValues.rent_utilities_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Food and Drink</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.food_drink } onValueChange={ ( e ) => updateValue( e, 'food_drink' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.food_drink_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.food_drink_annual !== undefined ? budgetValues.food_drink_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.food_drink_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.food_drink_annual !== undefined ? budgetValues.food_drink_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.food_drink_percentage !== undefined ? budgetValues.food_drink_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Clothing and Personal Care</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.clothing_personal } onValueChange={ ( e ) => updateValue( e, 'clothing_personal' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.clothing_personal_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.clothing_personal_annual !== undefined ? budgetValues.clothing_personal_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.clothing_personal_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.clothing_personal_annual !== undefined ? budgetValues.clothing_personal_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.clothing_personal_percentage !== undefined ? budgetValues.clothing_personal_percentage : 0 } displayType="text" />

                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Entertainment and Tech</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.entertainment_tech } onValueChange={ ( e ) => updateValue( e, 'entertainment_tech' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.entertainment_tech_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.entertainment_tech_annual !== undefined ? budgetValues.entertainment_tech_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.entertainment_tech_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.entertainment_tech_annual !== undefined ? budgetValues.entertainment_tech_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.entertainment_tech_percentage !== undefined ? budgetValues.entertainment_tech_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Transportation and Gas</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.transportation_gas } onValueChange={ ( e ) => updateValue( e, 'transportation_gas' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.transportation_gas_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.transportation_gas_annual !== undefined ? budgetValues.transportation_gas_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.transportation_gas_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.transportation_gas_annual !== undefined ? budgetValues.transportation_gas_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.transportation_gas_percentage !== undefined ? budgetValues.transportation_gas_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Car Lease Payment</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.car_lease_payment } onValueChange={ ( e ) => updateValue( e, 'car_lease_payment' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.car_lease_payment_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_lease_payment_annual !== undefined ? budgetValues.car_lease_payment_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.car_lease_payment_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_lease_payment_annual !== undefined ? budgetValues.car_lease_payment_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_lease_payment_percentage !== undefined ? budgetValues.car_lease_payment_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Car Insurance and Maintenance</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.car_insurance_maintenance } onValueChange={ ( e ) => updateValue( e, 'car_insurance_maintenance' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.car_insurance_maintenance_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_insurance_maintenance_annual !== undefined ? budgetValues.car_insurance_maintenance_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.car_insurance_maintenance_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_insurance_maintenance_annual !== undefined ? budgetValues.car_insurance_maintenance_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_insurance_maintenance_percentage !== undefined ? budgetValues.car_insurance_maintenance_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Home Expenses</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.home_expenses } onValueChange={ ( e ) => updateValue( e, 'home_expenses' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.home_expenses_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.home_expenses_annual !== undefined ? budgetValues.home_expenses_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.home_expenses_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.home_expenses_annual !== undefined ? budgetValues.home_expenses_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.home_expenses_annual_percentage !== undefined ? budgetValues.home_expenses_annual_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Health Insurance Premium</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.health_insurance_premium } onValueChange={ ( e ) => updateValue( e, 'health_insurance_premium' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.health_insurance_premium_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.health_insurance_premium_annual !== undefined ? budgetValues.health_insurance_premium_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.health_insurance_premium_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.health_insurance_premium_annual !== undefined ? budgetValues.health_insurance_premium_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.health_insurance_premium_percentage !== undefined ? budgetValues.health_insurance_premium_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Other Healthcare Costs</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.other_healthcare_costs } onValueChange={ ( e ) => updateValue( e, 'other_healthcare_costs' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.other_healthcare_costs_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.other_healthcare_costs_annual !== undefined ? budgetValues.other_healthcare_costs_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.other_healthcare_costs_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.other_healthcare_costs_annual !== undefined ? budgetValues.other_healthcare_costs_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.other_healthcare_costs_percentage !== undefined ? budgetValues.other_healthcare_costs_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Pet</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.pet } onValueChange={ ( e ) => updateValue( e, 'pet' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.pet_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.pet_annual !== undefined ? budgetValues.pet_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.pet_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.pet_annual !== undefined ? budgetValues.pet_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.pet_percentage !== undefined ? budgetValues.pet_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Miscellaneous</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.miscellaneous } onValueChange={ ( e ) => updateValue( e, 'miscellaneous' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.miscellaneous_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.miscellaneous_annual !== undefined ? budgetValues.miscellaneous_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.miscellaneous_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.miscellaneous_annual !== undefined ? budgetValues.miscellaneous_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.miscellaneous_percentage !== undefined ? budgetValues.miscellaneous_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.topBorderRow }>
                              <td>Total Living Expenses</td>
                              {budgetValues.total_living_expenses > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_living_expenses !== undefined ? budgetValues.total_living_expenses : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_living_expenses === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_living_expenses !== undefined ? budgetValues.total_living_expenses : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              {budgetValues.total_living_expenses_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_living_expenses_annual !== undefined ? budgetValues.total_living_expenses_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_living_expenses_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_living_expenses_annual !== undefined ? budgetValues.total_living_expenses_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.after_tax_income_annual !== undefined ? budgetValues.after_tax_income_annual : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.emptyRow } />
                            <tr>
                              <th><u>Loan Payments</u></th>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                              <th>&nbsp;</th>
                            </tr>
                            <tr>
                              <td>Credit Card Debt</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.credit_card_debt } onValueChange={ ( e ) => updateValue( e, 'credit_card_debt' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.credit_card_debt_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.credit_card_debt_annual !== undefined ? budgetValues.credit_card_debt_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.credit_card_debt_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.credit_card_debt_annual !== undefined ? budgetValues.credit_card_debt_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.credit_card_debt_percentage !== undefined ? budgetValues.credit_card_debt_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Student Loans</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.student_loans } onValueChange={ ( e ) => updateValue( e, 'student_loans' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.student_loans_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.student_loans_annual !== undefined ? budgetValues.student_loans_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.student_loans_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.student_loans_annual !== undefined ? budgetValues.student_loans_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.student_loans_percentage !== undefined ? budgetValues.student_loans_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr>
                              <td>Car Loans</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.car_loans } onValueChange={ ( e ) => updateValue( e, 'car_loans' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.car_loans_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                {' '}
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_loans_annual !== undefined ? budgetValues.car_loans_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.car_loans_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_loans_annual !== undefined ? budgetValues.car_loans_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.car_loans_percentage !== undefined ? budgetValues.car_loans_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.bottomRowSpace }>
                              <td>Mortgages</td>
                              <td>
                                <span>$</span>
                                <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ budgetValues.mortgages } onValueChange={ ( e ) => updateValue( e, 'mortgages' ) } onFocus={ ( e ) => e.target.select() } />
                              </td>
                              {budgetValues.mortgages_annual > 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.mortgages_annual !== undefined ? budgetValues.mortgages_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              {budgetValues.mortgages_annual === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.mortgages_annual !== undefined ? budgetValues.mortgages_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.mortgages_percentage !== undefined ? budgetValues.mortgages_percentage : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.topBorderRow }>
                              <td>Total Loan Payments</td>
                              {budgetValues.total_loan_payments > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_loan_payments !== undefined ? budgetValues.total_loan_payments : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_loan_payments === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_loan_payments !== undefined ? budgetValues.total_loan_payments : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              {budgetValues.total_loan_payments_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_loan_payments_annual !== undefined ? budgetValues.total_loan_payments_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_loan_payments_annual === 0 && (
                              <td className={ classes.textRed }>

                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_loan_payments_annual !== undefined ? budgetValues.total_loan_payments_annual : 0 } displayType="text" prefix="$" />

                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.after_tax_income_loans_annual !== undefined ? budgetValues.after_tax_income_loans_annual : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.emptyRow } />
                            <tr className={ classes.totalBorder }>
                              <td>Total Expenses</td>
                              {budgetValues.total_expenses > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_expenses !== undefined ? budgetValues.total_expenses : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_expenses === 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_expenses !== undefined ? budgetValues.total_expenses : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              {budgetValues.total_expenses_annual > 0 && (
                              <td className={ classes.textRed }>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_expenses_annual !== undefined ? budgetValues.total_expenses_annual : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.total_expenses_annual === 0 && (
                              <td className={ classes.textRed }>

                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.total_expenses_annual !== undefined ? budgetValues.total_expenses_annual : 0 } displayType="text" prefix="$" />

                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.after_tax_expenses_loans_annual !== undefined ? budgetValues.after_tax_expenses_loans_annual : 0 } displayType="text" />
                                %
                              </td>
                            </tr>
                            <tr className={ classes.emptyRow } />
                            <tr className={ classNames( classes.totalBorderRow, classes.totalBorder ) }>
                              <td>Cash Left Over for Savings / Invest</td>
                              {budgetValues.cash_left_over_savings >= 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.cash_left_over_savings !== undefined ? budgetValues.cash_left_over_savings : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              {budgetValues.cash_left_over_savings < 0 && (
                              <td>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.cash_left_over_savings !== undefined ? -( budgetValues.cash_left_over_savings ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              {budgetValues.cash_left_over_savings_annual >= 0 && (
                              <td className={ classes.textRed }>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.cash_left_over_savings_annual !== undefined ? budgetValues.cash_left_over_savings_annual : 0 } displayType="text" prefix="$" />
                              </td>
                              )}
                              {budgetValues.cash_left_over_savings_annual < 0 && (
                              <td>
                                (
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.cash_left_over_savings_annual !== undefined ? -( budgetValues.cash_left_over_savings_annual ) : 0 } displayType="text" prefix="$" />
                                )
                              </td>
                              )}
                              <td>
                                <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ budgetValues.after_tax_expenses_loans_annual !== undefined ? 100 - budgetValues.after_tax_expenses_loans_annual : 0 } displayType="text" />

                                %
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.loanProfitCalculator }>
              <div className={ classes.showMeContent }>
                <div className={ classes.showPortion }>
                  <div className={ classes.hiGraph }>
                    <h3 className={ classes.graphHead }><strong>Savings Growth</strong></h3>
                    <hr className={ classes.blueLine } />
                    <div className={ classes.careerInputs }>
                      <div className={ classes.cpBackground }>
                        <div className={ classes.cpInput }>
                          <label>
                            Monthly Savings:
                            <div className={ classNames( classes.inputGroup, classes.inputGroupLeft ) }>
                              <span className={ classes.dollarSymbol }>$</span>
                              <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ graphInputValues.monthlySavings } onValueChange={ ( e ) => updateValue( e, 'monthlySavings' ) } onFocus={ ( e ) => e.target.select() } />
                            </div>
                          </label>
                        </div>
                        <div className={ classes.cpInput }>
                          <label>
                            Annual Return:
                            <div className={ classNames( classes.inputGroup, classes.inputGroupRight ) }>
                              <NumberFormat allowNegative={ false } customInput={ TextField } thousandSeparator value={ graphInputValues.annualReturn } onValueChange={ ( e ) => updateValue( e, 'annualReturn' ) } onFocus={ ( e ) => e.target.select() } />
                              <span className={ classNames( classes.dollarSymbol, classes.percentSymbol ) }>%</span>
                            </div>
                          </label>
                        </div>
                        <div className={ classes.cpButton }>
                          <Button onClick={ () => { handleCalculate( graphInputValues.monthlySavings, graphInputValues.annualReturn ); } }>Calculate</Button>
                        </div>
                        <div className={ classes.listItems }>
                          <ul>
                            <li>
                              <p>
                                <span>5 Years</span>
                                <font>
                                  <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ yearsValues.fifthValue !== undefined ? yearsValues.fifthValue : 0 } displayType="text" prefix="$" />
                                </font>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>10 Years</span>
                                <font>
                                  <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ yearsValues.tenthValue !== undefined ? yearsValues.tenthValue : 0 } displayType="text" prefix="$" />
                                </font>
                              </p>
                            </li>
                            <li>
                              <p>
                                <span>15 Years</span>
                                <font>
                                  <NumberFormat allowNegative decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ yearsValues.fifteenthValue !== undefined ? yearsValues.fifteenthValue : 0 } displayType="text" prefix="$" />
                                </font>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {graphView && graphView.series && (
                    <div className={ classes.graphImage }>
                      <HighchartsReact
                        highcharts={ Highcharts }
                        options={ graphView }

                      />
                    </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

Budget.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Budget );
