import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Table } from '@material-ui/core';
import classNames from 'classnames';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CaresActTaxCredit = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>CARES Act Tax Credit</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/Cares-Tax-Credit.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      CARES Act Tax Credit
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/cares-tax-credit-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          <i>A Finance Explained Special Edition</i>
                        </p>
                        <p>
                          In addition to{' '}
                          <Link
                            to="/finance-explained/federal-student-loan-relief"
                            target="_blank">
                            federal student loan relief
                          </Link>
                          , the stimulus package provides a one-time tax credit.
                          Let&apos;s look at this one-time tax credit and
                          understand how much, who qualifies, and when you might
                          receive the payment.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The new law provides a $1,200 refundable tax credit -
                          fancy tax language for cash payment - for individuals
                          and $2,400 for taxpayers filing jointly, plus a flat
                          $500 for each child. The tax credit is paid based on
                          your filing status and income. Your income will be
                          determined from your latest tax return. If you have
                          not filed your 2019 tax return yet, the IRS will look
                          at your 2018 tax return to determine if you qualify
                          for the cash payment.
                        </p>
                        <p>
                          If you file your taxes as single and earned less than
                          $75,000 in{' '}
                          <Link
                            to="/finance-explained/personal-income-taxes"
                            target="_blank">
                            adjusted gross income
                          </Link>{' '}
                          on your latest tax return, you will receive $1,200. If
                          you earned between $75,000 and $99,000 you will
                          receive a portion of the $1,200. For every $1,000
                          earned over $75,000, the IRS will deduct $50 from the
                          credit. For example, if your adjusted gross income on
                          your latest tax return was $90,000, your tax credit
                          would be calculated as follows:
                        </p>
                        <p>
                          $90,000 - $75,000 = $15,000 / $1,000 = 15 x $50 = $750
                          deduction
                          <br />
                          $1,200- $750 deduction = $450 tax credit
                        </p>
                        <div className={classes.creditTax}>
                          <Table
                            className={classNames(
                              classes.taxTable,
                              classes.incomeTable,
                            )}>
                            <thead>
                              <tr>
                                <th>Tax Filing Status</th>
                                <th>Tax Credit Max Amount</th>
                                <th colSpan="2">Income Phaseout Range</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Single</td>
                                <td>$1,200</td>
                                <td>$75,000</td>
                                <td>$99,000</td>
                              </tr>
                              <tr>
                                <td>Married, No Children</td>
                                <td>$2,400</td>
                                <td>$150,000</td>
                                <td>$198,000</td>
                              </tr>
                              <tr>
                                <td>Married, One Child</td>
                                <td>$2,900</td>
                                <td>$150,000</td>
                                <td>$208,000</td>
                              </tr>
                              <tr>
                                <td>Married, Two Children</td>
                                <td>$3,400</td>
                                <td>$150,000</td>
                                <td>$218,000</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <p className={classes.noMargin}>
                          <i>
                            For every $1,000 earned over phaseout range, deduct
                            $50 from credit
                          </i>
                        </p>
                        <p>
                          It&apos;s important to note that the government will
                          actually look at your 2020 tax return to determine if
                          you should have qualified for a cash payment. I know
                          this is confusing, but it&apos;s easiest to understand
                          in an example.
                        </p>
                        <p>
                          Let&apos;s assume 2019 is your latest tax return and
                          you earned $150,000. You do not qualify for the tax
                          credit based on that year. However, in 2020 you earn
                          $50,000. The IRS would then give you a $1,200 tax
                          credit on your 2020 income tax returns.
                        </p>
                        <p>
                          On the other hand, let&apos;s say you earned $50,000
                          on your latest tax return in 2019. You receive a
                          $1,200 cash payment. In 2020, you earn $150,000.
                          Technically, you would not qualify for the tax credit,
                          but the government will not claw back that payment.
                          You will get to keep your $1,200 in that scenario.
                        </p>
                        <p>
                          It&apos;s important to note that if you do not have
                          your bank account details filed with the IRS, it could
                          take much longer to receive your cash payment. The IRS
                          is supposed to begin making direct deposits into bank
                          accounts as early as the week of April 13, 2020. If
                          the IRS doesn&apos;t have your bank account on file,
                          your payment could be delayed. Consult a tax
                          professional to make sure your direct deposit is set
                          up so you can receive your payment as soon as
                          possible.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CaresActTaxCredit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CaresActTaxCredit);
