import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/higher-education/styles';
import { percentFormatWithZeroDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';

const followersMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 80,
    label: '80%',
  },
  {
    value: 100,
    label: '100%',
  },
];

const FamilyContribution = ( props ) => {
  const {
    classes, inputData, updateData
  } = props;

  const [inputs, setInputs] = React.useState();
  const [investmentsMax, setInvestmentsMax] = React.useState();

  useEffect(()=>{
    let inputValues = {...inputs};
    inputValues['yearly_contribution_percentage'] =  inputData.yearly_contribution_percentage !== undefined ? inputData.yearly_contribution_percentage : 0;
    inputValues['yearly_contribution'] =  inputData.yearly_contribution !== undefined ? inputData.yearly_contribution : (inputData.efc_cal !== undefined ? inputData.efc_cal : 0);

    inputValues['cash_and_investments_percentage'] =  inputData.cash_and_investments_percentage !== undefined ? inputData.cash_and_investments_percentage : 0;
    inputValues['cash_and_investments'] =  inputData.cash_and_investments !== undefined ? inputData.cash_and_investments : 0;

    const totalAttendance =  moduleServices.module6Data.educationTotalAttendance/inputData.years_diff;
    if(inputData.yearly_contribution_percentage === undefined && (inputData.yearly_contribution !== undefined || inputValues['yearly_contribution'] > 0) && inputData.years_diff > 0) {
      if(totalAttendance > 0) {
        let scholarshipsSlider = (inputValues['yearly_contribution']/totalAttendance)*100;
        inputValues['yearly_contribution_percentage'] = scholarshipsSlider; 
      }
    }
    if(inputData.cash_and_investments_percentage === undefined && inputData.cash_and_investments !== undefined && inputData.years_diff > 0) {
      if(totalAttendance > 0) {
        let scholarshipsSlider = (inputValues['cash_and_investments']/totalAttendance)*100;
        inputValues['cash_and_investments_percentage'] = scholarshipsSlider; 
      }
    }
    setInputs(inputValues);

    if(inputData.yearly_contribution === undefined && inputData.efc_cal !== undefined &&  inputData.efc_cal  > 0) {
      inputValues['family_start_year'] = inputData.start_year;
      inputValues['family_end_year'] = inputData.graduation_year;
      updateData( 'total_obj' ,{...inputData,...inputValues} );
    }

    const availableCashAndInvestments = moduleServices.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
    setInvestmentsMax(availableCashAndInvestments);
    
  },[]);

  const updateValue = ( e, value, field, type ) => {
    let inputValues = {...inputs};
    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.target.value;
      if(newvalue === '') {
        newvalue = 0;
      }
      newvalue = convertCurrencyToInteger(newvalue);
    }
    inputValues[field] =  newvalue;
    const totalAttendance =  moduleServices.module6Data.educationTotalAttendance/inputData.years_diff; 
    if(field === 'yearly_contribution_percentage') {
      if(inputData.years_diff > 0) {
         inputValues['yearly_contribution'] = (totalAttendance/100)*inputValues[field];
      } else {
        inputValues['yearly_contribution'] = 0;
      }
    } else if(field === 'yearly_contribution') {
      if(totalAttendance < inputValues['yearly_contribution']) {
        inputValues['yearly_contribution'] = totalAttendance;
      }
      if(inputData.years_diff > 0 && totalAttendance > 0) {
         inputValues['yearly_contribution_percentage'] = (inputValues['yearly_contribution']/totalAttendance)*100;
      } else {
        inputValues['yearly_contribution_percentage'] = 0;
      }
    } else if(field === 'cash_and_investments_percentage') {
      
    //   const availableCashAndInvestments = moduleServices.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
    //   setInvestmentsMax(availableCashAndInvestments);
    //   let cashAndInvestmentsMaxAmount = 0;
    //   if(availableCashAndInvestments > 0 && inputData.years_diff > 0) {
    //     cashAndInvestmentsMaxAmount = availableCashAndInvestments/inputData.years_diff
    //   }
      
    //   if(cashAndInvestmentsMaxAmount > 0 && totalAttendance > 0) {
    //     const maxInputValue = (cashAndInvestmentsMaxAmount/totalAttendance)*100;
    //     if(maxInputValue < inputValues[field]) {
    //        inputValues[field] = maxInputValue; 
    //     }
    //   } else {
    //     inputValues[field] = 0;
    //   }

    //   if(inputData.years_diff > 0) {
    //      inputValues['cash_and_investments'] = (totalAttendance/100)*inputValues[field];
    //   } else {
    //     inputValues['cash_and_investments'] = 0;
    //   }

    // } else if(field === 'cash_and_investments') {

    //   const availableCashAndInvestments = moduleServices.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
    //   setInvestmentsMax(availableCashAndInvestments);
    //   let  cashAndInvestmentsMaxAmount = 0;
    //   if(availableCashAndInvestments > 0 && inputData.years_diff > 0 ){
    //     cashAndInvestmentsMaxAmount = availableCashAndInvestments/inputData.years_diff;
    //   }
    //   if(cashAndInvestmentsMaxAmount <= 0) {
    //     inputValues['cash_and_investments'] = 0;
    //   }
    //   if(cashAndInvestmentsMaxAmount < inputValues['cash_and_investments']) {
    //     inputValues['cash_and_investments'] = cashAndInvestmentsMaxAmount;
    //   }
    //   if(inputData.years_diff > 0 && totalAttendance > 0) {
    //      inputValues['cash_and_investments_percentage'] = (inputValues['cash_and_investments']/totalAttendance)*100;
    //   } else {
    //     inputValues['cash_and_investments_percentage'] = 0;
    //   }
    // } 
    // setInputs(inputValues);

    // if(field === 'yearly_contribution' || field === 'yearly_contribution_percentage') {
    //   inputValues['family_start_year'] = inputData.start_year;
    //   inputValues['family_end_year'] = inputData.graduation_year;
    // }

    if(inputData.years_diff > 0) {
    inputValues['cash_and_investments'] = (totalAttendance/100)*inputValues[field];
    } else {
    inputValues['cash_and_investments'] = 0;
    }
    } else if(field === 'cash_and_investments') {
    if(totalAttendance < inputValues['cash_and_investments']) {
    inputValues['cash_and_investments'] = totalAttendance;
    }
    if(inputData.years_diff > 0 && totalAttendance > 0) {
    inputValues['cash_and_investments_percentage'] = (inputValues['cash_and_investments']/totalAttendance)*100;
    } else {
    inputValues['cash_and_investments_percentage'] = 0;
    }
    }
    setInputs(inputValues);    

    updateData( 'total_obj' ,{...inputData,...inputValues} );
  };

  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.marginTopMoreNew }>
        How much will your family contribute to your education? Will you contribute some of your savings and investments to your education?
      </p>
      <p className={ classes.marginBottomZeroNew }>
        <i>
          Selected School:
          {' '}
          {inputData.school_details !== undefined && inputData.school_details.school_name !== undefined && inputData.school_details.school_name}
        </i>
      </p>
      <p>
        <i>
          EFC from the Net Price Calculator: 

          {inputData.efc_cal !== undefined ? (<NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputData.efc_cal } displayType="text" thousandSeparator prefix="$" />) : ('$0')}


        </i>
      </p>
      
      {inputs !== undefined &&  inputs !== '' && inputs.yearly_contribution_percentage !== undefined && (
        <div className={ classes.innnerFlowGroupMainFull }>
          <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMainFamily}` }>
            <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftFamily}` }>
                <h3>&nbsp;</h3>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthNewFamily}` }>
                  <div>
                    <p>Family Contribution</p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.yearly_contribution_percentage }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ percentFormatWithZeroDecimal }
                        min={ 0 }
                        max={ 100 }
                        step={ 1 }
                        marks={ followersMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ (e, value)=>updateValue( e, value, 'yearly_contribution_percentage', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.graphRightFamily}` }>
                <div>
                  <h3>Annual</h3>
                  <div className={ classes.inputRightBlock }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.yearly_contribution } onChange={ ( e ) => updateValue( e, '', 'yearly_contribution', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div>
                  <h3>
                    Total (
                    {inputData.years_diff}
                    {' '}
                    years)
                  </h3>
                  <div className={ classes.inputRightBlock }>
                    {(inputs.yearly_contribution !== undefined && inputData.years_diff !== undefined &&  inputs.yearly_contribution > 0 && inputData.years_diff > 0) ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputs.yearly_contribution*inputData.years_diff } displayType="text" thousandSeparator prefix="$" />
                    ): ('$0') 
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.innnerFlowGroup }>
              <div className={ `${classes.garphLeft} ${classes.garphLeftFamily}` }>
                <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthNewFamily}` }>
                  <div>
                    <p>Cash and Investments</p>
                    <div className={ classes.pwisliderInputs }>
                      <PwiSlider
                        value={ inputs.cash_and_investments_percentage }
                        aria-labelledby="discrete-slider-custom"
                        valueLabelDisplay="auto"
                        valueLabelFormat={ percentFormatWithZeroDecimal }
                        min={ 0 }
                        max={ 100 }
                        step={ 1 }
                        marks={ followersMarks }
                        ValueLabelComponent={ ValueLabelComponent }
                        onChange={ (e, value)=>updateValue( e, value, 'cash_and_investments_percentage', 'slider' ) }
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className={ `${classes.garphRight} ${classes.graphRightFamily}` }>
                <div>
                  <div className={ classes.inputRightBlock }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ inputs.cash_and_investments } onChange={ ( e ) => updateValue( e, '', 'cash_and_investments', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div>
                  <div className={ classes.inputRightBlock }>
                    {(inputs.cash_and_investments !== undefined && inputData.years_diff !== undefined &&  inputs.cash_and_investments > 0 && inputData.years_diff > 0) ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ inputs.cash_and_investments*inputData.years_diff } displayType="text" thousandSeparator prefix="$" />
                    ): ('$0') 
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className={ `${classes.totalCostNew} ${classes.totalCostNewSavings} ${classes.totalCostNewSavingsGap}` }>
        <span>Total Cash and Investments Available</span>
        <span>
          {(investmentsMax !== undefined && investmentsMax > 0 ) ? (
            <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ investmentsMax } displayType="text" thousandSeparator prefix="$" />
          ): ('$0')} 
        </span>
      </div>
    </div>
  );
};

FamilyContribution.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( FamilyContribution );
