import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import * as moduleServices from '../../../calculations/modules-services';
import styles from '../styles';

const PetOwning = ( props ) => {
  const {
    classes, updatedFamilyValues, setUpdatedFamilyValues, setPopupOpen,defaultData,
  } = props;

  const [familyValues, setFamilyValues] = useState(updatedFamilyValues);

  useEffect(() => {
    setFamilyValues(updatedFamilyValues);
  }, [updatedFamilyValues]);

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...familyValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
    }
    moduleServices.setModule10Data(valuesUpdated);
    setFamilyValues(valuesUpdated);
  };

  const resetToDefaultPet = () => {
    const valuesUpdated = { ...familyValues };
    valuesUpdated.default_annual_pet_vet_visits = defaultData.default_annual_pet_vet_visits;
    valuesUpdated.default_annual_pet_food = defaultData.default_annual_pet_food;
    valuesUpdated.default_annual_pet_toys = defaultData.default_annual_pet_toys;
    valuesUpdated.default_annual_pet_emergency_fund = defaultData.default_annual_pet_emergency_fund;
    valuesUpdated.default_annual_pet_miscellaneous = defaultData.default_annual_pet_miscellaneous;
    valuesUpdated.default_annual_pet_health_insurance = defaultData.default_annual_pet_health_insurance;
    valuesUpdated.default_annual_pet_registration = defaultData.default_annual_pet_registration;
    moduleServices.setModule10Data(valuesUpdated);
    setFamilyValues(valuesUpdated);
    setUpdatedFamilyValues(valuesUpdated);
    setPopupOpen(false);
  }

  const handleOk = () => {
    setUpdatedFamilyValues(familyValues);
    setPopupOpen(false);
  }

  return (
    <div>
      <div className={ classes.modalHeader }>
        <h3>Owning a Pet</h3>
      </div>
      <div className={ classes.modalBody }>
        <div className={ classes.moduleRowNew }>
          <Grid container>
            <Grid item sm={ 12 } xs={ 12 } className={ classes.inputBlock }>
              <div className={ classes.inputGroup }>
                <Grid container spacing={ 4 }>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classes.insideSpan }>
                      <div className={ classes.spanText }>
                        Vet Visits
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classNames( classes.inputRight, classes.fullInput ) }>
                      <span className={ classes.dollarSign }>$</span>
                      <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ familyValues.default_annual_pet_vet_visits } onValueChange={ ( e ) => updateValue( e, 'default_annual_pet_vet_visits', 'number' ) } onFocus={ ( e ) => e.target.select() } />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.moduleRowNew }>
          <Grid container>
            <Grid item sm={ 12 } xs={ 12 } className={ classes.inputBlock }>
              <div className={ classes.inputGroup }>
                <Grid container spacing={ 4 }>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classes.insideSpan }>
                      <div className={ classes.spanText }>
                        Food
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classNames( classes.inputRight, classes.fullInput ) }>
                      <span className={ classes.dollarSign }>$</span>
                      <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ familyValues.default_annual_pet_food } onValueChange={ ( e ) => updateValue( e, 'default_annual_pet_food', 'number' ) } onFocus={ ( e ) => e.target.select() } />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.moduleRowNew }>
          <Grid container>
            <Grid item sm={ 12 } xs={ 12 } className={ classes.inputBlock }>
              <div className={ classes.inputGroup }>
                <Grid container spacing={ 4 }>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classes.insideSpan }>
                      <div className={ classes.spanText }>
                        Toys
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classNames( classes.inputRight, classes.fullInput ) }>
                      <span className={ classes.dollarSign }>$</span>
                      <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ familyValues.default_annual_pet_toys } onValueChange={ ( e ) => updateValue( e, 'default_annual_pet_toys', 'number' ) } onFocus={ ( e ) => e.target.select() } />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.moduleRowNew }>
          <Grid container>
            <Grid item sm={ 12 } xs={ 12 } className={ classes.inputBlock }>
              <div className={ classes.inputGroup }>
                <Grid container spacing={ 4 }>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classes.insideSpan }>
                      <div className={ classes.spanText }>
                        Emergency Fund
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classNames( classes.inputRight, classes.fullInput ) }>
                      <span className={ classes.dollarSign }>$</span>
                      <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ familyValues.default_annual_pet_emergency_fund } onValueChange={ ( e ) => updateValue( e, 'default_annual_pet_emergency_fund', 'number' ) } onFocus={ ( e ) => e.target.select() } />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.moduleRowNew }>
          <Grid container>
            <Grid item sm={ 12 } xs={ 12 } className={ classes.inputBlock }>
              <div className={ classes.inputGroup }>
                <Grid container spacing={ 4 }>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classes.insideSpan }>
                      <div className={ classes.spanText }>
                        Pet Health Insurance
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classNames( classes.inputRight, classes.fullInput ) }>
                      <span className={ classes.dollarSign }>$</span>
                      <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ familyValues.default_annual_pet_health_insurance } onValueChange={ ( e ) => updateValue( e, 'default_annual_pet_health_insurance', 'number' ) } onFocus={ ( e ) => e.target.select() } />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.moduleRowNew }>
          <Grid container>
            <Grid item sm={ 12 } xs={ 12 } className={ classes.inputBlock }>
              <div className={ classes.inputGroup }>
                <Grid container spacing={ 4 }>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classes.insideSpan }>
                      <div className={ classes.spanText }>
                        Miscellaneous
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classNames( classes.inputRight, classes.fullInput ) }>
                      <span className={ classes.dollarSign }>$</span>
                      <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ familyValues.default_annual_pet_miscellaneous } onValueChange={ ( e ) => updateValue( e, 'default_annual_pet_miscellaneous', 'number' ) } onFocus={ ( e ) => e.target.select() } />

                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={ classes.moduleRowNew }>
          <Grid container>
            <Grid item sm={ 12 } xs={ 12 } className={ classes.inputBlock }>
              <div className={ classes.inputGroup }>
                <Grid container spacing={ 4 }>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classes.insideSpan }>
                      <div className={ classes.spanText }>
                        Registration
                      </div>
                    </div>
                  </Grid>
                  <Grid item sm={ 6 } xs={ 6 } className={ classes.noSpace }>
                    <div className={ classNames( classes.inputRight, classes.fullInput ) }>
                      <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ familyValues.default_annual_pet_registration } onValueChange={ ( e ) => updateValue( e, 'default_annual_pet_registration', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                      <span className={ classes.dollarSign }>$</span>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.modalFooter }>
        <h3>
          Total Costs of Owning a Pet:
          {/* moduleServices.module10Data.totalYearlyCostOfOwningAPet */}
          {moduleServices.module10Data.totalYearlyCostOfOwningAPet !== undefined ? (
            <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale className={ classes.formInput } displayType="text" thousandSeparator value={ moduleServices.module10Data.totalYearlyCostOfOwningAPet } prefix={ moduleServices.module10Data.totalYearlyCostOfOwningAPet >= 0 ? '$' : '($' } suffix={ moduleServices.module10Data.totalYearlyCostOfOwningAPet < 0 && ')' } />
          ) : ( '$0' )}
        </h3>
        <div>
          <Button className={ classNames( classes.resetButton, classes.footerButton ) } onClick={resetToDefaultPet}>Reset to default values</Button>
          <Button className={ classNames( classes.okButton, classes.footerButton ) } onClick={handleOk}>Ok</Button>
          <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ () => { setPopupOpen( false ); } }>Cancel</Button>
        </div>
      </div>

    </div>
  );
};

PetOwning.propTypes = {
  classes: PropTypes.object.isRequired,
  updatedFamilyValues: PropTypes.object.isRequired,
  setUpdatedFamilyValues: PropTypes.func.isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  defaultData: PropTypes.object.isRequired,
};

export default withStyles( styles )( PetOwning );
