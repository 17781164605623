const data = [
  {
    title: 'Existing Federal Subsidized Student Loan',
    children: [
      {
        label: 'Beginning Loan Balance',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_sub_current_balance',
      },
      {
        label: 'Term of the Loan (in months) or Months to Maturity',
        type: 'date',
        prefix: '',
        suffix: '',
        key: 'fed_sub_term',
      },
      {
        label: 'Interest Rate',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'fed_sub_interest_rate',
      },
      {
        label: 'Monthly Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_sub_monthly_payment',
      },
      {
        label: 'Annual Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_sub_annual_payment',
      },
      {
        label: 'Total Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_sub_total_interest',
      },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_sub_total_principle_interest',
      },
    ],
  },
  {
    title: 'Existing Federal Unsubsidized Student Loan',
    children: [
      {
        label: 'Outstanding Loan Amount',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_unsub_loan_amount',
      },
      {
        label: 'Total Accrued Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_unsub_total_accrued_interest',
      },
      {
        label: 'Beginning Loan Balance',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_unsub_beginning_balance',
      },
      {
        label: 'Term of the Loan (in months) or Months to Maturity',
        type: 'date',
        prefix: '',
        suffix: '',
        key: 'fed_unsub_term',
      },
      {
        label: 'Interest Rate',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'fed_unsub_interest_rate',
      },
      {
        label: 'Monthly Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_unsub_monthly_payment',
      },
      {
        label: 'Annual Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_unsub_annual_payment',
      },
      {
        label: 'Total Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_unsub_total_interest',
      },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'fed_unsub_total_principle_interest',
      },
    ],
  },
  {
    title: 'Existing Private Student Loan',
    children: [
      {
        label: 'Outstanding Loan Amount',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'private_loan_amount',
      },
      {
        label: 'Total Accrued Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'private_total_accrued_interest',
      },
      {
        label: 'Beginning Loan Balance',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'private_beginning_balance',
      },
      {
        label: 'Term of the Loan (in months) or Months to Maturity',
        type: 'date',
        prefix: '',
        suffix: '',
        key: 'private_term',
      },
      {
        label: 'Interest Rate',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'private_interest_rate',
      },
      {
        label: 'Monthly Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'private_monthly_payment',
      },
      {
        label: 'Annual Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'private_annual_payment',
      },
      {
        label: 'Total Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'private_total_interest',
      },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'private_total_principle_interest',
      },
    ],
  },
  {
    title: 'Existing Student Loans Summary',
    children: [
      {
        label: 'Total Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_student_loans_summary_total_interest',
      },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_student_loans_summary_total_principal_interest',
      },
      {
        label: 'Debt Service Ratio',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'debt_service_ratio',
      },
    ],
  },
  {
    title: 'Existing Credit Card Summary',
    children: [
      {
        label: 'Current Credit Card Balance',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_credit_card_balance',
      },
      {
        label: 'Ideal Repayment Schedule (in Months)',
        type: 'date',
        prefix: '',
        suffix: '',
        key: 'ideal_repayment_schedule',
      },
      {
        label: 'Interest Rate',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'creditcard_interest_rate',
      },
      {
        label: 'Monthly Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'creditcard_monthly_payment',
      },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'creditcard_total_principal_interest',
      },
    ],
  },
  {
    title: 'Existing Car Summary',
    children: [
      {
        label: 'Current Car Value',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_car_current_value',
      },
      {
        label: 'Current Loan Balance',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_car_current_loan_balance',
      },
      {
        label: 'Months to Maturity',
        type: 'number',
        prefix: '',
        suffix: '',
        key: 'existing_car_months_to_maturity',
      },
      {
        label: 'Interest Rate',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'existing_car_interest_rate',
      },
      {
        label: 'Monthly Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_car_monthly_payment',
      },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_car_principal_interest',
      },
      {
        label: 'Total Monthly Expenses',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'monthly_car_expenses',
      },
      {
        label: 'Total Annual Expenses',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'monthly_annual_expenses',
      },
    ],
  },
  {
    title: 'Existing Home Summary',
    children: [
      {
        label: 'Current Home Value',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existig_home_current_value',
      },
      {
        label: 'Basis (Purchase Price, Closing Costs, Fit Out Costs)',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existig_home_basis_costs',
      },
      {
        label: 'Annual Appreciation',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'existing_home_annual_appreciation',
      },
      {
        label: 'Monthly Property Taxes',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_monthly_property_taxes',
      },
      {
        label: 'Monthly Homeowner&apos;s Insurance',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_monthly_homeowner_insurance',
      },
      {
        label: 'Monthly Private Mortgage Insurance (PMI)',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_monthly_private_mortgage_insurance',
      },
      {
        label: 'Taxable Gain (Up to $250,000 is Tax-Free if Single)',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_monthly_maintenance',
      },
      {
        label: 'Monthly Association Fees',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_monthly_association_fees',
      },
      {
        label: 'Monthly Utilities',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_monthly_utilities',
      },
      {
        label: 'Current Mortgage Balance',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existig_home_current_mortgage_balance',
      },
      {
        label: 'Months to Maturity',
        type: 'number',
        prefix: '',
        suffix: '',
        key: 'existig_home_mortgage_months_to_maturity',
      },
      {
        label: 'Interest Rate',
        type: 'ratio',
        prefix: '',
        suffix: '%',
        key: 'existig_home_mortgage_interest_rate',
      },
      {
        label: 'Monthly Payment',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_monthly_payment',
      },
      {
        label: 'Total Principal & Interest',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_total_principal_interest',
      },
      {
        label: 'Debt-to-Income Ratio',
        type: 'number',
        prefix: '',
        suffix: '%',
        key: 'existing_home_debt_ratio',
      },
    ],
  },
  {
    title: 'Existing Home Sale',
    children: [
      {
        label: 'Sale Year',
        type: 'date',
        prefix: '',
        suffix: '',
        key: 'existing_home_sale_year',
      },
      {
        label: 'Selling Price',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_selling_price',
      },
      {
        label: 'Selling Costs',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_selling_costs',
      },
      {
        label: 'Net Selling Price',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_net_selling_price',
      },
      {
        label: 'Basis',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_basis',
      },
      {
        label: 'Gain (loss) on Sale',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_basis',
      },
      {
        label: 'Taxable Gain (Up to $250,000 is Tax-Free if Single)',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_taxble_gain',
      },
      {
        label: 'Tax on Taxable Gain (15%)',
        type: 'number',
        prefix: '$',
        suffix: '',
        key: 'existing_home_taxon_taxble_gain',
      },
    ],
  },
];
export default data;
