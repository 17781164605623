import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StandardPoor = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>S&P 500</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/s&p500.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      S&P 500
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/s&p500-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The Standard & Poor&apos;s 500 Index, or the S&P 500
                          Index, is a{' '}
                          <Link
                            to="/finance-explained/market-capitalization"
                            target="_blank">
                            market capitalization
                          </Link>{' '}
                          weighted index that tracks the price activity of 500
                          large U.S. publicly traded companies. I know -
                          it&apos;s a mouthful. First, let&apos;s talk about how
                          the size of a company factors into the S&P 500.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Unlike the{' '}
                          <Link
                            to="/finance-explained/dow-jones-industrial-average"
                            target="_blank">
                            Dow Jones Industrial Average
                          </Link>{' '}
                          where the share price is the only factor, the S&P 500
                          takes into account the size of each company. For
                          example, let&apos;s say you have a market
                          capitalization weighted index of just two companies
                          called the Inkwiry index. Company A has a share price
                          of $100 and 100 shares outstanding, which equals a
                          $10,000 market capitalization. Company B has a share
                          price of $200, but only 10 shares outstanding or
                          $2,000 market cap. The Inkwiry index&apos;s price at
                          this time would be:
                        </p>
                        <p className={classes.spText}>
                          <span>
                            ((Company A Price * Company A Weight) + (Company B
                            Price * Company Weight)
                          </span>
                          <span>
                            Company Weight = Market Cap of Company / Total
                            Market Cap of Index
                          </span>
                          <span>
                            Company A Weight = $10,000 / $12,000 = 83.33%
                          </span>
                          <span>Company B Weight = 16.67%</span>
                        </p>
                        <p>
                          Inkwiry Index Price = ($100 * ($10,000 / $12,000)) +
                          ($200 * ($2,000 / $12,000))
                          <br />
                          <b>Inkwiry Index Price = $116.66</b>
                        </p>
                        <p>
                          The larger the company, the more that company&apos;s
                          share price affects the index&apos;s overall price.
                          The S&P 500 index tracks the price activity of 500
                          companies of all different sizes in real time. These
                          companies span all different industries:
                        </p>
                        <ul>
                          <li>Energy</li>
                          <li>Materials</li>
                          <li>Health care</li>
                          <li>Financials</li>
                          <li>Information technology</li>
                          <li>Industrials</li>
                          <li>Consumer discretionary</li>
                          <li>Telecommunications</li>
                          <li>Consumer staples</li>
                          <li>Utilities</li>
                        </ul>
                        <p>
                          The S&P 500 is widely regarded as the best indicator
                          of the U.S. economy. If the 500 largest U.S. companies
                          are doing well and their share prices are increasing,
                          then that is a good sign for the future of the U.S.
                          economy. If the price of the S&P 500 is decreasing,
                          that means investors don&apos;t think too highly of
                          the future.
                        </p>
                        <p>
                          What&apos;s the advantage of a market capitalization
                          weighted index like the S&P 500 versus a
                          price-weighted index like the{' '}
                          <Link
                            to="/finance-explained/dow-jones-industrial-average"
                            target="_blank">
                            Dow Jones Industrial Average
                          </Link>
                          ? Since the S&P 500 index takes into account the size
                          of a company by applying weights based on market cap,
                          the S&P 500 is looked at as a better indicator than
                          the Dow Jones Industrial Average.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StandardPoor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StandardPoor);
