import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
} from '@material-ui/core';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import carImage from '../../../assets/img/financial-calculators/buying_a_car.png';
import resetIcon from '../../../assets/img/financial-calculators/circle-arrow.png';
import styles from './styles';
import DefinationPopup from '../../../components/ui/pwi-sidebar/defination-popup';
import * as sliderValues from '../../../utilities/existingsliders';
import * as commonFunctions from '../../../utilities/commonFunctions';
import ValueLabelComponent, { CarValueLabelComponent, PwiSlider } from '../../../components/common/pwi/slider-custome-tool-tip';

const PWI_DEFINATION = loader( '../../../graphql/schema/financial-independence/getPWiDefination.graphql' );

const CarAffordability = ( props ) => {
  const { classes } = props;

  const CarArray = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 20000,
      scaledValue: 20000,
      label: '$20k',
    },
    {
      value: 40000,
      scaledValue: 40000,
      label: '$40k',
    },
    {
      value: 60000,
      scaledValue: 60000,
      label: '$60k',
    },
    {
      value: 80000,
      scaledValue: 80000,
      label: '$80k',
    },
    {
      value: 100000,
      scaledValue: 100000,
      label: '$100k',
    },
    {
      value: 120000,
      scaledValue: 120000,
      label: '$120k',
    },
    {
      value: 140000,
      scaledValue: 140000,
      label: '$140k',
    },
  ];

  const carAffordabilityDefaultValues = {
    purchase_price_amount: 50000,
    purchase_price: { value: 50000 },
    aff_interest_rate: { value: 5.00 },
    aff_debt_to_income: { value: 10 },
    aff_interest_rate_val: 5.00,
    aff_debt_to_income_val: 10.00,
    Other_Loan_Payments_Monthly: 0,
    Other_Loan_Payments_Annual: 0,
  };

  const [carAffordabilityValues, setCarAffordabilityValues] = useState( carAffordabilityDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );
  const [imageSliderValue, setImageSliderValue] = useState( 0 );
  const [interestRate, setInterestRate] = useState( 0 );
  const [incomeValue, setIncomeValue] = useState( 0 );
  const [type, setType] = useState( '' );
  const [titleDefination, setTitleDefination] = useState( '' );
  const [defination, setDefination] = useState( '' );
  const [definationPopup, setDefinationPopup] = useState( false );

  const { data: definationResult } = useQuery( PWI_DEFINATION, {
    variables: {
      title: defination,
    },
    fetchPolicy: 'network-only',
    skip: defination === '',
  } );

  const handleCarImageSlider = ( event, newValue ) => {
    setImageSliderValue( newValue );
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.purchase_price_amount = newValue;
    setCarAffordabilityValues( valuesUpdated );
    setType( 'aff_purchase_price' );
  };

  const handleCarImageInput = ( event ) => {
    setImageSliderValue( event.floatValue === undefined ? 0 : event.floatValue );
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.purchase_price_amount = event.floatValue === undefined ? 0 : event.floatValue;
    setCarAffordabilityValues( valuesUpdated );
    setType( 'aff_purchase_price' );
  };

  const handleInterestSlider = ( event, newValue ) => {
    setInterestRate( newValue.toFixed( 2 ) );
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.aff_interest_rate_val = newValue.toFixed( 2 );
    setCarAffordabilityValues( valuesUpdated );
    setType( 'aff_interest_rate' );
  };

  const handleInterestInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setInterestRate( parseFloat( updatedValue ) > 100 ? 100 : updatedValue );
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.aff_interest_rate_val = event.floatValue === undefined ? 0 : event.floatValue;
    setCarAffordabilityValues( valuesUpdated );
    setType( 'aff_interest_rate' );
  };

  const handleIncomeSlider = ( event, newValue ) => {
    setIncomeValue( newValue.toFixed( 2 ) );
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.aff_debt_to_income_val = newValue.toFixed( 2 );
    setCarAffordabilityValues( valuesUpdated );
    setType( 'aff_debt_to_income' );
  };

  const handleIncomeInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setIncomeValue( parseFloat( updatedValue ) > 100 ? 100 : updatedValue );
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.aff_debt_to_income_val = event.floatValue === undefined ? 0 : event.floatValue;
    setCarAffordabilityValues( valuesUpdated );
    setType( 'aff_debt_to_income' );
  };

  useEffect( () => {
    const affordableValues = {};
    const purchasePriceNew = carAffordabilityValues.purchase_price_amount;
    if ( type === 'aff_purchase_price' ) {
      carAffordabilityValues.purchase_price.value = parseInt( purchasePriceNew, 10 );
    } else if ( type === 'aff_interest_rate' ) {
      carAffordabilityValues.aff_interest_rate.value = parseFloat( carAffordabilityValues.aff_interest_rate_val ).toFixed( 2 );
    } else if ( type === 'aff_debt_to_income' ) {
      carAffordabilityValues.aff_debt_to_income.value = parseFloat( carAffordabilityValues.aff_debt_to_income_val ).toFixed( 2 );
    }

    affordableValues['Purchase Price'] = purchasePriceNew;

    // New Calcs
    affordableValues['Down Payment'] = ( parseInt( purchasePriceNew, 10 ) / 100 ) * 20;

    // Closing Costs
    affordableValues['Sales Tax'] = ( parseInt( purchasePriceNew, 10 ) / 100 ) * 5;

    // Fit Out Costs
    affordableValues['Registration Fees'] = 500;

    // Cash Needed at Purchase
    affordableValues['Cash Needed at Purchase'] = affordableValues['Down Payment'] + affordableValues['Sales Tax'] + affordableValues['Registration Fees'];

    // % of Home Value
    affordableValues['Of Home Value'] = ( affordableValues['Cash Needed at Purchase'] / parseInt( purchasePriceNew, 10 ) ) * 100;

    // Mortgage
    affordableValues.Mortgage = parseInt( purchasePriceNew, 10 ) - affordableValues['Down Payment'];

    // Interest Rate
    affordableValues['Interest Rate'] = parseFloat( carAffordabilityValues.aff_interest_rate_val ).toFixed( 2 );

    // Term of the Mortgage
    affordableValues['Term of the Mortgage'] = 4;

    /* console.log('1',affordableValues['Interest Rate']);
      console.log('1',affordableValues['Term of the Mortgage']);
      console.log('1',affordableValues['Mortgage']);
      */
    // below code commented because excelLibrary is not defined. --- mahananda
    affordableValues['Monthly Car Loan Payment PMT'] = commonFunctions.PMT( ( parseFloat( affordableValues['Interest Rate'] ) / 100 ) / 12, affordableValues['Term of the Mortgage'] * 12, -( affordableValues.Mortgage ), 0, 0 );
    // affordableValues['Monthly Car Loan Payment PMT'] = ( parseFloat( affordableValues['Interest Rate'] ) / 100 ) / 12;

    // Property Taxes
    affordableValues.Maintenance = 100;
    affordableValues['Car Insurance'] = 150;
    affordableValues.Gas = 100;

    // Property Taxes
    affordableValues['Property Taxes'] = ( ( parseInt( purchasePriceNew, 10 ) / 12 ) / 100 ) * 1.5;

    // Yearly Homeowners Insurance
    affordableValues['Monthly Homeowners Insurance'] = ( parseInt( purchasePriceNew, 10 ) / 1000 ) * ( 3.5 / 12 );

    // Total Monthly Home Payment (PITI)
    affordableValues['Total Monthly Car Expenses'] = affordableValues['Monthly Car Loan Payment PMT']
      + affordableValues.Maintenance
      + affordableValues['Car Insurance']
      + affordableValues.Gas;

    let otherLoanPaymentsMonthly = 0;
    let otherLoanPaymentsAnnual = 0;

    if ( type === 'month' ) {
      otherLoanPaymentsAnnual = parseInt( carAffordabilityValues.Other_Loan_Payments_Monthly, 10 ) * 12;
      carAffordabilityValues.Other_Loan_Payments_Annual = otherLoanPaymentsAnnual;
    } else if ( type === 'year' ) {
      otherLoanPaymentsMonthly = parseInt( carAffordabilityValues.Other_Loan_Payments_Annual, 10 ) / 12;
      carAffordabilityValues.Other_Loan_Payments_Monthly = otherLoanPaymentsMonthly;
    }

    // Total Loan Payments
    affordableValues['Total Loan Payments Monthly'] = affordableValues['Total Monthly Home Payment (PITI)'] + parseInt( carAffordabilityValues.Other_Loan_Payments_Monthly, 10 );

    // Total Loan Payments Annual
    affordableValues['Total Loan Payments Annual'] = ( affordableValues['Total Monthly Home Payment (PITI)'] * 12 ) + parseInt( carAffordabilityValues.Other_Loan_Payments_Annual, 10 );

    // Debt-to-Income
    affordableValues['Debt-to-Income'] = parseFloat( carAffordabilityValues.aff_debt_to_income_val ).toFixed( 2 );

    // Monthly
    // affordableValues['Monthly Income Before Taxes Monthly'] = ( affordableValues['Total Loan Payments Monthly'] / affordableValues['Debt-to-Income'] ) * 100;
    affordableValues['Monthly Income Needed'] = ( affordableValues['Total Monthly Car Expenses'] / affordableValues['Debt-to-Income'] ) * 100;

    carAffordabilityValues.affordableValuesData = affordableValues;
    setCalculatedValues( carAffordabilityValues );
    // eslint-disable-next-line
  }, [carAffordabilityValues]);

  const handleDefinationPopup = ( def, title ) => {
    setDefination( def );
    setTitleDefination( title );
    setDefinationPopup( true );
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup( false );
  };

  const handleIncomeReset = () => {
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.aff_debt_to_income_val = carAffordabilityDefaultValues.aff_debt_to_income_val;
    setCarAffordabilityValues( valuesUpdated );
  };

  const handleInterestReset = () => {
    const valuesUpdated = { ...carAffordabilityValues };
    valuesUpdated.aff_interest_rate_val = carAffordabilityDefaultValues.aff_interest_rate_val;
    setCarAffordabilityValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Car Affordability</li>
            </ul>
            <div className={ classes.carAffordability }>
              <div className={ classes.showMeContent }>
                <div className={ classes.showMePortion }>
                  <div className={ classes.carAffordableCalcs }>
                    <ReactTooltip id="tooltip" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                    <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                    <h3 className={ classes.graphHead }><strong>Car Affordability</strong></h3>
                    <hr className={ classes.blueLine } />
                    <h3 className={ classes.benchMark }>The 20-4-10 rule is a good benchmark to consider in the buying process. The 20-4-10 rule of car buying suggests that you can afford a car if:</h3>
                    <div className={ classes.homeOwner }>
                      <ul>
                        <li>20% down payment minimum</li>
                        <li>4-year term of the car loan maximum</li>
                        <li>10% of your total income for monthly car expenses maximum</li>
                      </ul>
                    </div>
                    <div className={ classes.affordableGraph }>
                      <div className={ classes.flexDisplayNew }>
                        <div className={ classes.sliderFlex }>                         
                            <p>
                              <span className={ classes.buyhomeImg }>
                                <img src={ carImage } alt="" />
                              </span>
                            </p>
                            <div className={ classes.pwisliderInputs }>
                              <PwiSlider
                                aria-labelledby="discrete-slider-custom"
                                ValueLabelComponent={ CarValueLabelComponent }
                                valueLabelDisplay="on"
                                marks={ CarArray }
                                min={ 0 }
                                max={ 140000 }
                                step={ 100 }
                                value={ carAffordabilityValues && carAffordabilityValues.purchase_price_amount }
                                valueLabelFormat={ `Car Value $${new Intl.NumberFormat( 'en-IN' ).format( carAffordabilityValues && carAffordabilityValues.purchase_price_amount )}` }
                                onChange={ handleCarImageSlider }
                              />
                            </div>                       
                           <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
                            <div>
                              <span className={ classes.dollarSymbol }>$</span>
                              <NumberFormat customInput={ TextField } thousandSeparator value={ imageSliderValue === '' ? 0 : carAffordabilityValues && carAffordabilityValues.purchase_price_amount } onValueChange={ handleCarImageInput } onFocus={ ( e ) => { e.target.select(); } } />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={ classes.flexDisplayNew }>
                        <div className={ classes.sliderFlex }>
                          
                            <p>
                              <span className={ classes.buyhomeImg }>
                                Interest Rate
                              </span>
                            </p>
                            <div className={ classes.pwisliderInputs }>
                              <PwiSlider
                                ValueLabelComponent={ ValueLabelComponent }
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="auto"
                                marks={ sliderValues.interestCarRate() }
                                min={ 0 }
                                max={ 10.5 }
                                step={ 0.01 }
                                value={ carAffordabilityValues && carAffordabilityValues.aff_interest_rate_val }
                                valueLabelFormat={ `${commonFunctions.perFormatter( carAffordabilityValues && carAffordabilityValues.aff_interest_rate_val )}` }
                                onChange={ handleInterestSlider }
                              />
                            </div>                     
                           <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
                            <div>
                              <NumberFormat customInput={ TextField } thousandSeparator value={ interestRate === '' ? 0 : parseFloat( carAffordabilityValues && carAffordabilityValues.aff_interest_rate_val ).toFixed( 2 ) } onValueChange={ handleInterestInput } onFocus={ ( e ) => { e.target.select(); } } />
                              <span className={ classes.percentSymbol }>%</span>
                              <span className={ classNames( classes.resetIcon, classes.resetImage ) } role="button" aria-hidden="true" onClick={ handleInterestReset }>
                                <img src={ resetIcon } alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={ classes.flexDisplayNew }>
                        <div className={ classes.sliderFlex }>                         
                            <p>
                              <span className={ classes.buyhomeImg }>
                                % of Income
                              </span>
                            </p>
                            <div className={ classes.pwisliderInputs }>
                              <PwiSlider
                                ValueLabelComponent={ ValueLabelComponent }
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="auto"
                                marks={ sliderValues.interestCarIncome() }
                                min={ 0 }
                                max={ 14 }
                                step={ 0.01 }
                                value={ carAffordabilityValues && carAffordabilityValues.aff_debt_to_income_val }
                                valueLabelFormat={ `${commonFunctions.perFormatter( carAffordabilityValues && carAffordabilityValues.aff_debt_to_income_val )}` }
                                onChange={ handleIncomeSlider }
                              />
                            </div>                        
                          <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
                            <div>
                              <NumberFormat customInput={ TextField } thousandSeparator value={ incomeValue === '' ? 0 : parseFloat( carAffordabilityValues && carAffordabilityValues.aff_debt_to_income_val ).toFixed( 2 ) } onValueChange={ handleIncomeInput } onFocus={ ( e ) => { e.target.select(); } } />
                              <span className={ classes.percentSymbol }>%</span>
                              <span className={ classNames( classes.resetIcon, classes.resetImage ) } role="button" aria-hidden="true" onClick={ handleIncomeReset }>
                                <img src={ resetIcon } alt="" />
                              </span>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className={ classes.affordableTables }>
                      <table className={ classNames( classes.affordableCashTable, classes.affordFirst ) }>
                        <thead>
                          <tr>
                            <td>&nbsp;</td>
                            <td colSpan="2">Cash Needed to Purchase</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Down payments on a car typically range from 10-20% of the purchase price. This calculator assumes 20% as an estimate. As you continue in the Buying a Car module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Car Down Payment: Buying', 'Down Payment' ) }>Down Payment</span></td>
                            <td>
                              {' '}
                              <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Down Payment'] : 0 } displayType="text" prefix="$" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Sales tax varies by state and ranges from 3-7% typically. This calculator assumes 5% as an estimate. As you continue in the Buying a Car module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Sales Tax', 'Sales Tax' ) }>Sales Tax</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Sales Tax'] : 0 } displayType="text" prefix="$" /></td>
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Registration fees are charged by the state to register your car. Documentation fees are charged by the car dealer to complete and process your paperwork. These fees can vary from $0 to $1,000. This calculator assumes $500 as an estimate. As you continue in the Buying a Car module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Documentation & Registration Fees: Buying a Car', 'Registration & Documentation Fees' ) }>Registration & Documentation Fees</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Registration Fees'] : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>Total Cash Needed</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Cash Needed at Purchase'] : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>% of Car Value</td>
                            <td>
                              {calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Of Home Value'].toFixed( 2 ) : 0}
                              %
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <table className={ classNames( classes.affordableIncomeTable, classes.affordableCashTable ) }>
                        <thead>
                          <tr>
                            <td>&nbsp;</td>
                            <td>Income Needed</td>
                            <td>Monthly</td>
                            <td>Annual</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Car value less down payment equals loan value. This calculator assumes a 5 year, fixed rate car loan with a 5% interest rate. As you continue in the Buying a Car module, you can change this input." />
                              </div>
                            </td>
                            <td>Car Loan Payment (P&I)</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Car Loan Payment PMT'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Car Loan Payment PMT'] * 12 ) : 0 } displayType="text" prefix="$" /></td>
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Owning a car requires constant maintenance from changing tires, detailing and washing, and regular service visits. This calculator assumes $100 per month in maintenance. As you continue in the Buying a Car module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Car Maintenance', 'Maintenance' ) }>Maintenance</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData.Maintenance ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData.Maintenance * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Your state mandates car insurance in case of accidents and injuries. Insurance can range from $100 to a few hundred dollars a month. This calculator assumes $150 per month in car insurance. As you continue in the Buying a Car module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Car Insurance', 'Car Insurance' ) }>Car Insurance</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Car Insurance'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Car Insurance'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Gas expense varies depending on your lifestyle and location as gas costs on the west coast are different from the northeast. This calculator assumes $100 per month to be conservative. As you continue in the Buying a Car module, you can change this input." />
                              </div>
                            </td>
                            <td>Gas</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData.Gas ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData.Gas * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr className={ classes.carExpenses }>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="PITI stands for principal, interest, taxes and insurance." />
                              </div>
                            </td>
                            <td>Total Monthly Car Expenses</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Total Monthly Car Expenses'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Total Monthly Car Expenses'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr className={ classes.italicFont }>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="As a planning benchmark, your monthly car expenses should be around 10% or less of your before-tax income. To make sure you can comfortably support buying a car while also paying for your other living expenses, other debt, saving, and investing, try and keep your car expenses less than 10% of your income." />
                              </div>
                            </td>
                            <td>% of Income</td>
                            <td>
                              {calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Debt-to-Income'] : 0}
                              %
                            </td>
                            <td>
                              {calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Debt-to-Income'] : 0}
                              %
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>Income Needed</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Income Needed'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Income Needed'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.bottomText }>
                      <p className={ classes.footText }><i>Assumptions: Affordability is calculated with the following details: 20% down payment, 5% sales tax, $500 registration and documentation fees, 80% loan value, 5% interest rate, 4 years term of the loan, $100 maintenance, $150 car insurance, $100 gas expense, and a 10% benchmark for car expenses as a percentage of total income.</i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {definationResult && definationResult.getPWiDefination
        && (
          <DefinationPopup
            definationPopup={ definationPopup }
            titleDefination={ titleDefination }
            handleDefinationPopupClose={ handleDefinationPopupClose }
            definationResult={ definationResult.getPWiDefination.description }
          />
        )}
    </Typography>
  );
};

CarAffordability.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( CarAffordability );
