const styles = ( theme ) => ( {
    container: {
      maxWidth: '1170px',
      padding: '0 15px',
      margin: '0px auto',
      [theme.breakpoints.down( 'md' )]: {
        padding: '0 25px',
      },
    },
    finishRegistrationRow:{
			width: '423px',
			margin: '15px auto 20px',
			[theme.breakpoints.down( 'xs' )]: {
				width: '100%',
				maxWidth: '423px',
			}
		},
		logoRow:{
			display: 'flex',
			padding: '15px',
			alignItems: 'center',
			'& a':{
				width: '50%',
				display: 'inline-block',
				'& img':{
					maxWidth: '160px',
					[theme.breakpoints.down( 'xs' )]: {
						maxWidth: '120px',
					}
				},
				'&:last-child':{
					float: 'right',
					textAlign: 'right',
					'& img':{
						maxWidth: '140px',
						[theme.breakpoints.down( 'xs' )]: {
							maxWidth: '100px',
						}
					},
				},
			}
		},
		finishRegistratioForm:{
			'& h3':{
				fontSize: '24px',
				textAlign: 'center',
				color: '#0069aa',
				margin: '20px 0px 15px',
				fontFamily: '"MuseoSans-500"',
				fontWeight: 'normal',
			},
		},
		accountInfoRow:{
			'& h4':{
				fontFamily: '"MuseoSans-500"',
				fontWeight: 'normal',
				fontSize: '20px',
				lineHeight: '20px',
				color: '#0069aa',
				margin: '10px 10px 10px 0',
			},
			'& fieldset':{
				display: 'none',
			},
			'& input':{
				width: 'calc(100% - 20px)',
				borderRadius: '4px',
				outline: 'none',
				border: '1px solid #d9d9d9',
				height: 'calc(40px - 18px)',
				padding: '8px 10px',
				background: 'transparent',
				zIndex: '9',
				position: 'relative',
				color: '#555',
				'&:focus':{
					borderColor: '#66afe9',
					outline: '0',
					WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
					boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
				}
			},
			'& select':{
				width: '100%',
				borderRadius: '4px',
				outline: 'none',
				border: '1px solid #d9d9d9',
				height: '100%',
				padding: '8px 10px',
				background: 'transparent',
				zIndex: '9',
				position: 'relative',
				color: '#555',
				'&:focus':{
					borderColor: '#66afe9',
					outline: '0',
					WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
					boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
				}
			}
		},
		formFlex:{
			padding: '0 0 8px 0',
			display: 'flex',
			margin: '0px -5px',
		},
		formInHalf:{
			position: 'relative',
			width: 'calc(50% - 10px)',
			padding: '0px 5px',
		},
		formInFull:{
			position: 'relative',
			width: '100%',
			padding: '0 0 8px 0',
		},
		nameError:{
			margin: '6px 0 0 0',
			color: '#bf0000',
			fontSize: '13.5px',
			fontFamily: '"MuseoSans-700"',
			fontWeight: 'normal',
		},
		passwordList:{
			position: 'absolute',
			width: 'calc(280px - 40px)',
			height: 'calc(155px - 16px)',
			padding: '8px 20px',
			top: '-60px',
			right: '-333px',
			border: '2px solid #0069aa',
			background: '#fff',
			zIndex: '99',
			borderRadius: '15px',
			opacity: '0',
			visibility: 'hidden',
			transition: 'all 0.3s ease-in-out',
			WebkitTransition: 'all 0.3s ease-in-out',
			MozTransition: 'all 0.3s ease-in-out',
			MsTransition: 'all 0.3s ease-in-out',
			OTransition: 'all 0.3s ease-in-out',
			'&:before':{
				content: '""',
				position: 'absolute',
				top: '58px',
				left: '-22px',
				background: '#fff',
				width: '40px',
				height: '40px',
				border: '2px solid #0069aa',
				transform: 'rotate(45deg) skew(-6deg, -6deg)',
				WebkitTransform: 'rotate(45deg) skew(-6deg, -6deg)',
				MozTransform: 'rotate(45deg) skew(-6deg, -6deg)',
				MsTransform: 'rotate(45deg) skew(-6deg, -6deg)',
				OTransform: 'rotate(45deg) skew(-6deg, -6deg)',
				borderRight: 'none',
				borderTop: 'none',
				zIndex: '-1',
			}
		},
		passwordListActive:{
			opacity: '1',
			visibility: 'visible',
		},
		condition:{
			padding: '17px 0 0 0',
			color: 'green',
			'& p':{
				lineHeight: '14px',
				fontSize: '14px',
				color: '#999',
				margin: '0 0px 7px 0',
				'& span':{
					marginRight: '6px',
					width: '12px',
    			height: '14px',
					position: 'static',
					display: 'inline-block',
					verticalAlign: 'middle',
					'& img':{
						width: '100%',
					}
				},
				'& small':{
					display: 'inline-block',
					verticalAlign: 'middle',
					width: '80%',
					fontSize: '85%',
				}
			}
		},
		formCheckBox:{
			padding: '8px 0 0 0',
			borderTop: '1px solid #ccc',
			margin: '20px 0 0 0',
			'& a':{
				display: 'inline-block',
				margin: '0 0 20px 0',
				textDecoration: 'underline',
				'&:hover':{
					color: '#f89b23',
				}
			}
		},
		formBtn:{
			textAlign: 'right',
			margin: '0px 0 0 0',
			'& button':{
				background: '#0069aa',
				border: 'none',
				outline: 'none',
				padding: '8px 20px',
				color: '#fff',
				fontSize: '16px',
				borderRadius: '4px',
				cursor: 'pointer',
			}
		},
		villanovaFooter:{
			width: '100%',
			padding: '0',
		},
		footerIn:{
			borderTop: '1px solid #ccc',
			padding: '18px 0',
			'& ul':{
				display: 'block',
				padding: '0',
				margin: '0',
				textAlign: 'center',
				'& li':{
					display: 'inline-block',
					verticalAlign: 'middle',
					margin: '0 0 0 8px',
					padding: '0 12px 0 0',
					position: 'relative',
					color: '#9c9c9c',
					fontSize: '14px',
					lineHeight: '1.42857143',
					'&:before':{
						content: '""',
						position: 'absolute',
						top: '0',
						right: '0',
						background: '#ccc',
						width: '2px',
						height: '100%',
					},
					'&:last-child':{
						'&:before':{
							display: 'none',
						}
					},
					'& a':{
						textDecoration: 'underline',
						color: '#9c9c9c',
						'&:hover':{
							color: '#f89b23',
						}
					}
				}
			}
		}
   } );
  
  export default styles;
  