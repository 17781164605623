import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const marks = [];
for ( let i = 0; i <= 6; i += 1 ) {
  marks.push( {
    value: i,
    label: i === 0 ? '0% of Purchase Price' : `${i}%`,
  } );
}

const TransferTaxesTitleInsurance = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup,
  } = props;

  const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;

  const [transferTax, setTransferTax] = React.useState( 0 );
  const [transferTaxValue, setTransferTaxValue] = React.useState( 0 );
  const [titleInsurance, setTitleInsurance] = React.useState( 0 );
  const [titleInsuranceValue, settitleInsuranceValue] = React.useState( 0 );

  useEffect( () => {
    let inputDetails = {...inputData};

    let transferVal = 0;
    if ( inputData.transfer_taxes_in_percentage !== undefined ) {
      transferVal = inputData.transfer_taxes_in_percentage;
    } else {
      transferVal = 1;
      inputDetails.transfer_taxes_in_percentage = transferVal;
    }

    setTransferTax( transferVal );
    setTransferTaxValue( ( purchasePrice / 100 ) * transferVal );

    let titleVal = 0;
    if ( inputData.title_insurance !== undefined ) {
      titleVal = inputData.title_insurance;
    } else {
      titleVal = 0.5;
      inputDetails.title_insurance = titleVal;
    }
    setTitleInsurance( titleVal );
    settitleInsuranceValue( ( purchasePrice / 100 ) * titleVal );
    updateData( 'total_obj', inputDetails );
    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    let inputDetails = {...inputData};
    switch ( field ) {
      case 'transfer': {
        setTransferTax( newvalue );
        setTransferTaxValue( ( purchasePrice / 100 ) * newvalue );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'transfer_input': {
        setTransferTaxValue( newvalue );
        const transferTaxVal = ( purchasePrice > 0 ? ( ( newvalue / purchasePrice ) * 100 ) : 0 );
        setTransferTax( transferTaxVal );
        inputDetails[mainField] = transferTaxVal;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'title': {
        setTitleInsurance( newvalue );
        settitleInsuranceValue( ( purchasePrice / 100 ) * newvalue );
        inputDetails[mainField] = newvalue;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'title_input': {
        settitleInsuranceValue( newvalue );
        const titleVal = ( purchasePrice > 0 ? ( ( newvalue / purchasePrice ) * 100 ) : 0 );
        setTitleInsurance( titleVal );
        inputDetails[mainField] = titleVal;
        updateData( 'total_obj', inputDetails );
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        When you buy real estate, the owner transfers the title of that property to you. What does that mean? A
        {' '}
        <a className={ classes.anchorStyle } href="https://www.bankrate.com/real-estate/how-should-you-title-your-home/" target="_blank" rel="budget-fy2020 noopener noreferrer">title</a>
        {' '}
        is a legal way of saying that you own something, meaning you have the rights to use that property.
      </p>
      <p>
        When the owner transfers the title to you at purchase, your city, state, or even county collects a tax on that transfer called
        {' '}
        <a className={ classes.anchorStyle } href="https://www.deeds.com/articles/real-estate-transfer-taxes/" target="_blank" rel="budget-fy2020 noopener noreferrer">transfer taxes</a>
        .
        {' '}
        Transfer taxes vary by city and state and are usually shown as a percentage of the purchase price.
        {' '}
        <a className={ classes.anchorStyle } href="http://midpointtitle.com/wp-content/uploads/2018/05/Copy-of-transfer-tax-by-state_rev.pdf" target="_blank" rel="budget-fy2020 noopener noreferrer">Click here</a>
        {' '}
        to see transfer taxes by state.
      </p>
      <p>
        Title insurance protects you and your mortgage lender with insurance in case there is an
        {' '}
        <a className={ classes.anchorStyle } href="https://www.thebalancesmb.com/title-insurance-tips-and-pitfalls-4124464" target="_blank" rel="budget-fy2020 noopener noreferrer">issue with the title</a>
        {' '}
        and typically costs 0.5% to 1.0% of the purchase price.
      </p>
      <div className={ classes.purchaseSlideScroll }>
        <div className={ classes.transferSlide }>
          <div className={ classes.flexDisplayNew }>
            <div className={ classes.sliderFlex }>
              <div>
                <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Transfer Taxes' ) } className={ classes.dottedBorder }>Transfer Taxes</span></p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ transferTax }
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={ 0 }
                    max={ 6 }
                    step={ 0.1 }
                    onChange={ ( e, value ) => updateValue( e, value, 'transfer', 'transfer_taxes_in_percentage', 'slider' ) }
                    marks={ marks }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ transferTaxValue } onValueChange={ ( e ) => updateValue( e, '', 'transfer_input', 'transfer_taxes_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
          <div className={ classNames( classes.flexDisplayNew, classes.transferSlider ) }>
            <div className={ classes.sliderFlex }>
              <div>
                <p><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Title Insurance: Real Estate' ) } className={ classes.dashedBorder }>Title Insurance</span></p>
                <div className={ classes.pwisliderInputs }>
                  <PwiSlider
                    value={ titleInsurance }
                    valueLabelFormat={ percentFormatWithTwoDecimal }
                    ValueLabelComponent={ ValueLabelComponent }
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    min={ 0 }
                    max={ 6 }
                    step={ 0.1 }
                    onChange={ ( e, value ) => updateValue( e, value, 'title', 'title_insurance', 'slider' ) }
                    marks={ marks }
                  />
                </div>
              </div>
            </div>
            <div className={ classNames( classes.sliderValues, classes.downValues ) }>
              <div>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ titleInsuranceValue } onValueChange={ ( e ) => updateValue( e, '', 'title_input', 'title_insurance', 'number' ) } onFocus={ ( e ) => e.target.select() } />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

TransferTaxesTitleInsurance.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( TransferTaxesTitleInsurance );
