import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
} from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import DefinationPopup from '../../../components/ui/pwi-sidebar/defination-popup';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import homeImage from '../../../assets/img/financial-calculators/existing_home.png';
import resetIcon from '../../../assets/img/financial-calculators/circle-arrow.png';
import styles from './styles';
import * as sliderValues from '../../../utilities/existingsliders';
import * as commonFunctions from '../../../utilities/commonFunctions';
import ValueLabelComponent, { HomeValueLabelComponent, PwiSlider } from '../../../components/common/pwi/slider-custome-tool-tip';

const PWI_DEFINATION = loader( '../../../graphql/schema/financial-independence/getPWiDefination.graphql' );

const RealEstateAffordability = ( props ) => {
  const { classes } = props;

  const HomeArray = [
    {
      value: 0,
      scaledValue: 0,
      label: '$0',
    },
    {
      value: 200000,
      scaledValue: 200000,
      label: '$200k',
    },
    {
      value: 400000,
      scaledValue: 400000,
      label: '$400k',
    },
    {
      value: 600000,
      scaledValue: 600000,
      label: '$600k',
    },
    {
      value: 800000,
      scaledValue: 800000,
      label: '$800k',
    },
    {
      value: 1000000,
      scaledValue: 1000000,
      label: '$1.0mm',
    },
    {
      value: 1200000,
      scaledValue: 1200000,
      label: '$1.2mm',
    },
    {
      value: 1400000,
      scaledValue: 1400000,
      label: '$1.4mm',
    },
  ];

  const homeAffordabilityDefaultValues = {
    purchase_price_amount: 400000,
    purchase_price: { value: 50000 },
    aff_interest_rate: { value: 5.00 },
    aff_debt_to_income: { value: 10 },
    aff_interest_rate_val: 3.50,
    aff_debt_to_income_val: 36.00,
    Other_Loan_Payments_Monthly: 0,
    Other_Loan_Payments_Annual: 0,
  };

  const [homeAffordabilityValues, setHomeAffordabilityValues] = useState( homeAffordabilityDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );
  const [imageSliderValue, setImageSliderValue] = useState( 0 );
  const [interestRate, setInterestRate] = useState( 0 );
  const [incomeValue, setIncomeValue] = useState( 0 );
  const [type, setType] = useState( '' );
  const [titleDefination, setTitleDefination] = useState( '' );
  const [defination, setDefination] = useState( '' );
  const [definationPopup, setDefinationPopup] = useState( false );

  const { data: definationResult } = useQuery( PWI_DEFINATION, {
    variables: {
      title: defination,
    },
    fetchPolicy: 'network-only',
    skip: defination === '',
  } );

  useEffect( () => {
    const affordableValues = {};
    const purchasePriceNew = homeAffordabilityValues.purchase_price_amount;
    if ( type === 'aff_purchase_price' ) {
      homeAffordabilityValues.purchase_price.value = parseInt( purchasePriceNew, 10 );
    } else if ( type === 'aff_interest_rate' ) {
      homeAffordabilityValues.aff_interest_rate.value = parseFloat( homeAffordabilityValues.aff_interest_rate_val ).toFixed( 2 );
    } else if ( type === 'aff_debt_to_income' ) {
      homeAffordabilityValues.aff_debt_to_income.value = parseFloat( homeAffordabilityValues.aff_debt_to_income_val ).toFixed( 2 );
    }

    affordableValues['Purchase Price'] = purchasePriceNew;

    // New Calcs
    affordableValues['Down Payment'] = ( parseInt( purchasePriceNew, 10 ) / 100 ) * 20;

    // Closing Costs
    affordableValues['Closing Costs'] = ( parseInt( purchasePriceNew, 10 ) / 100 ) * 3.5;

    // Fit Out Costs
    affordableValues['Fit Out Costs'] = ( parseInt( purchasePriceNew, 10 ) / 100 ) * 3;

    // Cash Needed at Purchase
    affordableValues['Cash Needed at Purchase'] = affordableValues['Down Payment'] + affordableValues['Closing Costs'] + affordableValues['Fit Out Costs'];

    // % of Home Value
    affordableValues['Of Home Value'] = ( affordableValues['Cash Needed at Purchase'] / parseInt( purchasePriceNew, 10 ) ) * 100;

    // Mortgage
    affordableValues.Mortgage = parseInt( purchasePriceNew, 10 ) - affordableValues['Down Payment'];

    // Interest Rate
    affordableValues['Interest Rate'] = parseFloat( homeAffordabilityValues.aff_interest_rate_val ).toFixed( 2 );

    // Term of the Mortgage
    affordableValues['Term of the Mortgage'] = 30;

    // commented below line because excelLibrary is not there ---- mahananda
    affordableValues['Monthly Mortgage Payment PMT'] = commonFunctions.PMT( ( parseFloat( affordableValues['Interest Rate'] ) / 100 ) / 12, affordableValues['Term of the Mortgage'] * 12, -( affordableValues.Mortgage ), 0, 0 );
    // affordableValues['Monthly Mortgage Payment PMT'] = parseInt( affordableValues['Interest Rate'], 10 ) / 100;

    // Property Taxes
    affordableValues['Property Taxes'] = ( ( parseInt( purchasePriceNew, 10 ) / 12 ) / 100 ) * 1.5;

    // Yearly Homeowners Insurance
    affordableValues['Monthly Homeowners Insurance'] = ( parseInt( purchasePriceNew, 10 ) / 1000 ) * ( 3.5 / 12 );

    // Total Monthly Home Payment (PITI)
    affordableValues['Total Monthly Home Payment (PITI)'] = affordableValues['Monthly Mortgage Payment PMT']
                                                                    + affordableValues['Property Taxes']
                                                                    + affordableValues['Monthly Homeowners Insurance'];

    let otherLoanPaymentsMonthly = 0;
    let otherLoanPaymentsAnnual = 0;

    if ( type === 'month' ) {
      otherLoanPaymentsAnnual = parseInt( homeAffordabilityValues.Other_Loan_Payments_Monthly, 10 ) * 12;
      // homeAffordabilityValues.Other_Loan_Payments_Annual = $filter('currency')(otherLoanPaymentsAnnual, '', 0);
      homeAffordabilityValues.Other_Loan_Payments_Annual = otherLoanPaymentsAnnual;
    } else if ( type === 'year' ) {
      otherLoanPaymentsMonthly = parseInt( homeAffordabilityValues.Other_Loan_Payments_Annual, 10 ) / 12;
      // homeAffordabilityValues.Other_Loan_Payments_Monthly = $filter('currency')(otherLoanPaymentsMonthly, '', 0);
      homeAffordabilityValues.Other_Loan_Payments_Monthly = otherLoanPaymentsMonthly;
    }

    // Total Loan Payments
    affordableValues['Total Loan Payments Monthly'] = affordableValues['Total Monthly Home Payment (PITI)'] + parseInt( homeAffordabilityValues.Other_Loan_Payments_Monthly, 10 );

    // Total Loan Payments Annual
    affordableValues['Total Loan Payments Annual'] = ( affordableValues['Total Monthly Home Payment (PITI)'] * 12 ) + parseInt( homeAffordabilityValues.Other_Loan_Payments_Annual, 10 );

    // Debt-to-Income
    affordableValues['Debt-to-Income'] = parseFloat( homeAffordabilityValues.aff_debt_to_income_val ).toFixed( 2 );

    // Monthly
    affordableValues['Monthly Income Before Taxes Monthly'] = ( affordableValues['Total Loan Payments Monthly'] / affordableValues['Debt-to-Income'] ) * 100;

    // Annual
    affordableValues['Monthly Income Before Taxes Annual'] = ( affordableValues['Total Loan Payments Annual'] / affordableValues['Debt-to-Income'] ) * 100;

    homeAffordabilityValues.affordableValuesData = affordableValues;
    setCalculatedValues( homeAffordabilityValues );
  }, [homeAffordabilityValues, type] );

  const handleDefinationPopup = ( def, title ) => {
    setDefination( def );
    setTitleDefination( title );
    setDefinationPopup( true );
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup( false );
  };

  const handleIncomeReset = () => {
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.aff_debt_to_income_val = homeAffordabilityDefaultValues.aff_debt_to_income_val;
    setHomeAffordabilityValues( valuesUpdated );
  };

  const handleInterestReset = () => {
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.aff_interest_rate_val = homeAffordabilityDefaultValues.aff_interest_rate_val;
    setHomeAffordabilityValues( valuesUpdated );
  };

  const handleCarImageSlider = ( event, newValue ) => {
    setImageSliderValue( newValue );
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.purchase_price_amount = newValue;
    setHomeAffordabilityValues( valuesUpdated );
    setType( 'aff_purchase_price' );
  };

  const handleCarImageInput = ( event ) => {
    setImageSliderValue( event.floatValue === undefined ? 0 : event.floatValue );
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.purchase_price_amount = event.floatValue === undefined ? 0 : event.floatValue;
    setHomeAffordabilityValues( valuesUpdated );
    setType( 'aff_purchase_price' );
  };

  const handleInterestSlider = ( event, newValue ) => {
    setInterestRate( newValue.toFixed( 2 ) );
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.aff_interest_rate_val = newValue.toFixed( 2 );
    setHomeAffordabilityValues( valuesUpdated );
    setType( 'aff_interest_rate' );
  };

  const handleInterestInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setInterestRate( parseFloat( updatedValue ) > 100 ? 100 : updatedValue );
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.aff_interest_rate_val = event.floatValue === undefined ? 0 : event.floatValue;
    setHomeAffordabilityValues( valuesUpdated );
    setType( 'aff_interest_rate' );
  };

  const handleIncomeSlider = ( event, newValue ) => {
    setIncomeValue( newValue.toFixed( 2 ) );
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.aff_debt_to_income_val = newValue.toFixed( 2 );
    setHomeAffordabilityValues( valuesUpdated );
    setType( 'aff_debt_to_income' );
  };

  const handleIncomeInput = ( event ) => {
    const updatedValue = event.floatValue === undefined ? 0 : event.floatValue;
    setIncomeValue( parseFloat( updatedValue ) > 100 ? 100 : updatedValue );
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.aff_debt_to_income_val = event.floatValue === undefined ? 0 : event.floatValue;
    setHomeAffordabilityValues( valuesUpdated );
    setType( 'aff_debt_to_income' );
  };

  const handleOtherLoanPaymentMonthy = ( event ) => {
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.Other_Loan_Payments_Monthly = event.floatValue === undefined ? 0 : event.floatValue;
    valuesUpdated.Other_Loan_Payments_Annual = event.floatValue === undefined ? 0 : event.floatValue * 12;
    setHomeAffordabilityValues( valuesUpdated );
  };

  const handleOtherLoanPaymentAnnual = ( event ) => {
    const valuesUpdated = { ...homeAffordabilityValues };
    valuesUpdated.Other_Loan_Payments_Monthly = event.floatValue === undefined ? 0 : parseInt( event.floatValue / 12, 10 );
    valuesUpdated.Other_Loan_Payments_Annual = event.floatValue === undefined ? 0 : event.floatValue;
    setHomeAffordabilityValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Real Estate Affordability</li>
            </ul>
            <div className={ classes.carAffordability }>
              <div className={ classes.showMeContent }>
                <div className={ classes.showMePortion }>
                  <div className={ classes.carAffordableCalcs }>
                    <ReactTooltip id="tooltip" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                    <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                    <h3 className={ classes.graphHead }><strong>Real Estate Affordability</strong></h3>
                    <hr className={ classes.blueLine } />
                    <div className={ classes.affordableGraph }>
                      <div className={ classes.flexDisplayNew }>
                        <div className={ classes.sliderFlex }>                         
                            <p>
                              <span className={ classes.buyhomeImg }>
                                <img src={ homeImage } alt="" />
                              </span>
                            </p>
                            <div className={ classes.pwisliderInputs }>
                              <PwiSlider
                                aria-labelledby="discrete-slider-custom"
                                ValueLabelComponent={ HomeValueLabelComponent }
                                valueLabelDisplay="on"
                                marks={ HomeArray }
                                min={ 0 }
                                max={ 1400000 }
                                step={ 1000 }
                                value={ homeAffordabilityValues && homeAffordabilityValues.purchase_price_amount }
                                valueLabelFormat={ `Home Value $${new Intl.NumberFormat( 'en-IN' ).format( homeAffordabilityValues && homeAffordabilityValues.purchase_price_amount )}` }
                                onChange={ handleCarImageSlider }
                              />
                            </div>                       
                            <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
                              <div className={ classes.affordableHome }>
                                <span className={ classes.dollarSymbol }>$</span>
                                <NumberFormat customInput={ TextField } thousandSeparator value={ imageSliderValue === '' ? 0 : homeAffordabilityValues && homeAffordabilityValues.purchase_price_amount } onValueChange={ handleCarImageInput } onFocus={ ( e ) => { e.target.select(); } } />
                              </div>
                            </div>
                        </div>
                      </div>
                      <div className={ classes.flexDisplayNew }>
                        <div className={ classes.sliderFlex }>
                          <p>
                              <span className={ classes.buyhomeImg }>
                                Interest Rate
                              </span>
                            </p>
                            <div className={ classes.pwisliderInputs }>
                              <PwiSlider
                                ValueLabelComponent={ ValueLabelComponent }
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="auto"
                                marks={ sliderValues.interestCarRate() }
                                min={ 0 }
                                max={ 10.5 }
                                step={ 0.01 }
                                value={ homeAffordabilityValues && homeAffordabilityValues.aff_interest_rate_val }
                                valueLabelFormat={ `${commonFunctions.perFormatter( homeAffordabilityValues && homeAffordabilityValues.aff_interest_rate_val )}` }
                                onChange={ handleInterestSlider }
                              />
                            </div>                         
                          <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
                          <div>
                            <NumberFormat customInput={ TextField } thousandSeparator value={ interestRate === '' ? 0 : parseFloat( homeAffordabilityValues && homeAffordabilityValues.aff_interest_rate_val ).toFixed( 2 ) } onValueChange={ handleInterestInput } onFocus={ ( e ) => { e.target.select(); } } />
                            <span className={ classes.percentSymbol }>%</span>
                            <span className={ classNames( classes.resetIcon, classes.resetImage ) } role="button" aria-hidden="true" onClick={ handleInterestReset }>
                              <img src={ resetIcon } alt="" />
                            </span>
                          </div>
                          </div>
                        </div>
                      </div>
                      <div className={ classes.flexDisplayNew }>
                        <div className={ classes.sliderFlex }>                         
                          <p>
                            <span className={ classes.buyhomeImg }>
                              Debt-to-Income
                            </span>
                          </p>
                          <div className={ classes.pwisliderInputs }>
                            <PwiSlider
                              ValueLabelComponent={ ValueLabelComponent }
                              aria-labelledby="discrete-slider-custom"
                              valueLabelDisplay="auto"
                              marks={ sliderValues.interestHomeIncome() }
                              min={ 0 }
                              max={ 42 }
                              step={ 0.01 }
                              value={ homeAffordabilityValues && homeAffordabilityValues.aff_debt_to_income_val }
                              valueLabelFormat={ `${commonFunctions.perFormatter( homeAffordabilityValues && homeAffordabilityValues.aff_debt_to_income_val )}` }
                              onChange={ handleIncomeSlider }
                            />
                          </div>                      
                          <div className={ classNames( classes.sliderValues, classes.affordableValues ) }>
                          <div>
                            <NumberFormat customInput={ TextField } thousandSeparator value={ incomeValue === '' ? 0 : parseFloat( homeAffordabilityValues && homeAffordabilityValues.aff_debt_to_income_val ).toFixed( 2 ) } onValueChange={ handleIncomeInput } onFocus={ ( e ) => { e.target.select(); } } />
                            <span className={ classes.percentSymbol }>%</span>
                            <span className={ classNames( classes.resetIcon, classes.resetImage ) } role="button" aria-hidden="true" onClick={ handleIncomeReset }>
                              <img src={ resetIcon } alt="" />
                            </span>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                    <div className={ classes.affordableTables }>
                      <table className={ classNames( classes.affordableCashTable, classes.affordFirst) }>
                        <thead>
                          <tr>
                            <td>&nbsp;</td>
                            <td colSpan="2">Cash Needed to Purchase</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Down payments on a home are usually 20% of the home price. As you continue in the Buying a Home module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Home Down Payment', 'Down Payment' ) }>Down Payment</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Down Payment'] : 0 } displayType="text" prefix="$" /></td>
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Closing costs can range from 2-5% of the home value and vary depending on location, credit score, and other factors. This calculator assumes 3.5% for closing costs. As you continue in the Buying a Home module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Closing Costs: Real Estate', 'Closing Costs' ) }>Closing Costs</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Closing Costs'] : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Furniture, window treatments, landscaping, and home improvements are examples of fit out costs when buying a home. This calculator assumes 3% for fit out costs. As you continue in the Buying a Home module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Fit Out Costs: Real Estate', 'Fit Out Costs' ) }>Fit Out Costs</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Fit Out Costs'] : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>Total Cash Needed</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Cash Needed at Purchase'] : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>% of Home Value</td>
                            <td>
                              <NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Of Home Value'] : 0 } displayType="text" />
                              %
                            </td>

                          </tr>
                        </tfoot>
                      </table>
                      <table className={ classNames( classes.affordableIncomeTable, classes.affordableCashTable ) }>
                        <thead>
                          <tr>
                            <td>&nbsp;</td>
                            <td>Income Needed</td>
                            <td>Monthly</td>
                            <td>Annual</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Home value less down payment equals mortgage value. This calculator assumes a 30 year, fixed rate mortgage with a 4.25% interest rate. As you continue in the Buying a Home module, you can change this input." />
                              </div>
                            </td>
                            <td>Mortgage Payment (P&I)</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Mortgage Payment PMT'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Mortgage Payment PMT'] * 12 ) : 0 } displayType="text" prefix="$" /></td>
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Property taxes, which is usually city and school taxes, vary widely by location. This calculator assumes 1.5% of the home value for property taxes. As you continue in the Buying a Home module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Property Taxes: Home', 'Property Taxes' ) }>Property Taxes</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Property Taxes'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Property Taxes'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Homeowner's insurance is estimated at 0.35% of the home value. As you continue in the Buying a Home module, you can change this input." />
                              </div>
                            </td>
                            <td><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Homeowner\'s Insurance', 'Homeowner\'s Insurance' ) }>Homeowner&apos;s Insurance</span></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Homeowners Insurance'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Homeowners Insurance'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr className={ classes.carExpenses }>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="PITI stands for principal, interest, taxes and insurance." />
                              </div>
                            </td>
                            <td>Total Payment (PITI)</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Total Monthly Home Payment (PITI)'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Total Monthly Home Payment (PITI)'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr className={ classes.italicFont }>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Input your other loan payments, such as student loan, auto loan, or other mortgages. Enter the total amount that you pay each month." />
                              </div>
                            </td>
                            <td>Other Loan Payments</td>
                            <td>
                              <div className={ classes.flexInput }>
                                <span className={ classes.dollarSymbol }>$</span>
                                <NumberFormat customInput={ TextField } thousandSeparator value={ homeAffordabilityValues.Other_Loan_Payments_Monthly === '' ? 0 : homeAffordabilityValues.Other_Loan_Payments_Monthly } onValueChange={ handleOtherLoanPaymentMonthy } onFocus={ ( e ) => { e.target.select(); } } />
                              </div>
                            </td>
                            <td>
                              <div className={ classes.flexInput }>
                                <span className={ classes.dollarSymbol }>$</span>
                                <NumberFormat customInput={ TextField } thousandSeparator value={ homeAffordabilityValues.Other_Loan_Payments_Annual === '' ? 0 : homeAffordabilityValues.Other_Loan_Payments_Annual } onValueChange={ handleOtherLoanPaymentAnnual } onFocus={ ( e ) => { e.target.select(); } } />
                              </div>
                            </td>
                          </tr>
                          <tr className={ classes.carExpenses }>
                            <td>
                              &nbsp;
                            </td>
                            <td>Total Loan Payments</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Total Loan Payments Monthly'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Total Loan Payments Monthly'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip=" Most mortgage lenders will use 36% debt-to-income ratio as the maximum level of debt for lending to individuals. To make sure you can comfortably support your home's monthly payment, that payment should not exceed 36% of your income." />
                              </div>
                            </td>
                            <td className={ classes.leftPadding }><span className={ classes.dottedText } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Debt-to-Income Ratio (KPI)', 'Debt-to-Income Ratio' ) }><i>Debt-to-Income-Ratio</i></span></td>
                            <td>
                              <i>
                                { calculatedValues && calculatedValues.affordableValuesData !== undefined ? calculatedValues.affordableValuesData['Debt-to-Income'] : 0 }
                                {' '}
                                %
                              </i>
                            </td>
                            <td>
                              <i>
                                { calculatedValues && calculatedValues.affordableValuesData !== undefined ? parseInt( calculatedValues.affordableValuesData['Debt-to-Income'], 10 ) : 0 }
                                {' '}
                                %
                              </i>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>Income Needed</td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Income Before Taxes Monthly'] ) : 0 } displayType="text" prefix="$" /></td>
                            <td><NumberFormat allowNegative fixedDecimalScale thousandSeparator value={ calculatedValues && calculatedValues.affordableValuesData !== undefined ? Math.round( calculatedValues.affordableValuesData['Monthly Income Before Taxes Monthly'] * 12 ) : 0 } displayType="text" prefix="$" /></td>

                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div className={ classes.bottomText }>
                      <p className={ classes.footText }><i>Assumptions: Affordability is calculated with the following details: 20% down payment, 3.5% closing costs, 3% fit out costs, 80% mortgage value, 4.00% interest rate, 30 years term of the mortgage, property taxes at 1.5%, homeowner&apos;s insurance at 0.35%, and a 36% debt-to-income ratio.</i></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {definationResult && definationResult.getPWiDefination
        && (
        <DefinationPopup
          definationPopup={ definationPopup }
          titleDefination={ titleDefination }
          handleDefinationPopupClose={ handleDefinationPopupClose }
          definationResult={ definationResult.getPWiDefination.description }
        />
        )}
    </Typography>
  );
};

RealEstateAffordability.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( RealEstateAffordability );
