import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import styles from './styles';
import logo from '../../../assets/img/logo.png';
import * as moduleServices from '../../../calculations/modules-services';
import NumberFormat from 'react-number-format';

const DOWNLOAD_PDF = loader( '../../../graphql/schema/fc/download-pdf.graphql' );

const ReiTable = ( props ) => {
  const {
    classes, closeWorkSheet, openWorkSheet
  } = props;

  const closePopup = () => {
    closeWorkSheet( false );
  };

  const reiCompleteData         = moduleServices.invPropertyRoiCompleteData();
  const reiYearsList     = reiCompleteData['Years List'];

  const netCashFlowVal = -(reiCompleteData['Buying Expenses']['Down Payment']+reiCompleteData['Buying Expenses']['Closing Costs']+reiCompleteData['Buying Expenses']['Fit Out Costs']);

  const totalSource = moduleServices.module14Data.yearly_rental_income+(-moduleServices.module14Data.vacancyRateCalcs);
  const totalUses =  -(reiCompleteData['Cash Out']['Mortgage Payment']+reiCompleteData['Cash Out']['Homeowners Insurance']+reiCompleteData['Cash Out']['Property Maintenance']+reiCompleteData['Cash Out']['Property Taxes']+reiCompleteData['Cash Out']['Management Fees']+reiCompleteData['Cash Out']['Association Fees']+reiCompleteData['Cash Out']['Utilities'])
  const netCashFlowVal2 = reiCompleteData['Selling Costs']['Selling Price']-reiCompleteData['Selling Costs']['Selling Costs']-reiCompleteData['Selling Costs']['Mortgage Balance Remaining'];

  const [donwloadPdf] = useMutation( DOWNLOAD_PDF, {
    onCompleted( response ) {
      if ( response.downloadPDF.status ) {
        var hiddenElement = document.createElement('a');
        hiddenElement.href = response.downloadPDF.url;
        hiddenElement.download = 'reitable-sheet.pdf';
        hiddenElement.target = '_blank';
        hiddenElement.click();    
      }
    },
  } );

  const handleDownloadPDF = () => {
    // window.open( response.downloadPDF.url );    
    donwloadPdf( {
      variables: {
        data: {type: 'summary-sheet', summary_financial: JSON.stringify(reiCompleteData)},
      },
    } );
  };

  return (
    <Typography variant="body1" component="div">
        
      <Dialog
        open={ openWorkSheet }
        onClose={ closePopup }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.worksheetModal }
        >
        <DialogTitle className={ classes.summayModalHeader }>
          <div >
            <div>
              <Grid container>
                <Grid item sm={ 4 }>
                  <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
                </Grid>
                <Grid item sm={ 4 }>
                 <Button className={ classes.downloadBtn } onClick={ handleDownloadPDF }>Download PDF</Button>
                 </Grid>
              </Grid>
              <Button className={ classes.incomePopupClose } onClick={ closePopup }>×</Button>             
            </div>
          </div>          
        </DialogTitle>
          <div className={ classes.borderTitle } ><p className={classes.borderStrip}>Real Estate Investment Property Analysis</p></div>
          <DialogContent className={ classes.modalBlock }>           
            <div className={ classNames( classes.modalBody, classes.modalRei ) }>            
              <table className={ classes.analiseTable }>
                <tr>
                  <td>
                    <table >
                        <tbody>
                          <tr className={ classes.heightTen }></tr>
                          <tr>
                            <td className={ classNames( classes.topTable, classes.buyingHomeOne, classes.reiTableData ) }>
                              <table>
                                <tr className={ classes.blueTableRow }>
                                  <td>Annual Sources of Cash</td>
                                  <td >
                                    Year&nbsp;{reiCompleteData['Buying Expenses']['Purchase Year'] !== undefined && reiCompleteData['Buying Expenses']['Purchase Year'] > 0 ? (
                                      reiCompleteData['Buying Expenses']['Purchase Year']
                                    ):("&nbsp;") }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Rental Income</td>
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module14Data.yearly_rental_income } prefix={ "$" } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Vacancy rate 
                                    (<NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module14Data.vacancy_rate_in_percentage } suffix={ "%" } />)
                                  </td>
                                  <td className={-moduleServices.module14Data.vacancyRateCalcs < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -moduleServices.module14Data.vacancyRateCalcs } prefix={ -moduleServices.module14Data.vacancyRateCalcs >= 0 ? '$' : '($' } suffix={ -moduleServices.module14Data.vacancyRateCalcs < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Total Sources</td>
                                  <td className={totalSource < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalSource } prefix={ totalSource >= 0 ? '$' : '($' } suffix={ totalSource < 0 && ')' } />
                                  </td>
                                </tr> 
                                <tr className={ classes.heightTwoZero }></tr>  
                                <tr className={ classes.blueTableRow }>
                                  <td>Annual Uses of Cash</td>
                                  <td >
                                    Year&nbsp;{reiCompleteData['Buying Expenses']['Purchase Year'] !== undefined && reiCompleteData['Buying Expenses']['Purchase Year'] > 0 ? (
                                      reiCompleteData['Buying Expenses']['Purchase Year']
                                    ):("&nbsp;") }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mortgage Payment</td>
                                  <td className={-reiCompleteData['Cash Out']['Mortgage Payment'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Mortgage Payment'] } prefix={ -reiCompleteData['Cash Out']['Mortgage Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Mortgage Payment'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Homeowner{"'"}s Insurance</td>
                                  <td className={-reiCompleteData['Cash Out']['Homeowners Insurance'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Homeowners Insurance'] } prefix={ -reiCompleteData['Cash Out']['Homeowners Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Homeowners Insurance'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Property Maintenance</td>
                                  <td className={-reiCompleteData['Cash Out']['Property Maintenance'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Property Maintenance'] } prefix={ -reiCompleteData['Cash Out']['Property Maintenance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Property Maintenance'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Property Taxes</td>
                                  <td className={-reiCompleteData['Cash Out']['Property Taxes'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Property Taxes'] } prefix={ -reiCompleteData['Cash Out']['Property Taxes'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Property Taxes'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Management Fees</td>
                                  <td className={-reiCompleteData['Cash Out']['Management Fees'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Management Fees'] } prefix={ -reiCompleteData['Cash Out']['Management Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Management Fees'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Association Fees</td>
                                  <td className={-reiCompleteData['Cash Out']['Association Fees'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Association Fees'] } prefix={ -reiCompleteData['Cash Out']['Association Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Association Fees'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Utilities</td>
                                  <td className={-reiCompleteData['Cash Out']['Utilities'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Cash Out']['Utilities'] } prefix={ -reiCompleteData['Cash Out']['Utilities'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Cash Out']['Utilities'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Total Uses</td>
                                  <td className={totalUses < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalUses } prefix={ totalUses >= 0 ? '$' : '($' } suffix={ totalUses < 0 && ')' } />
                                  </td>
                                </tr>                               
                                <tr className={ classes.heightTwoZero }></tr>
                                <tr className={ classes.blueTableRow }>
                                  <td>Other Assumptions</td>
                                  <td>
                                    &nbsp;
                                  </td>
                                </tr>
                                <tr>
                                  <td>Annual Rental Income Growth  </td>
                                  <td>
                                    <NumberFormat decimalScale={ 2 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Cash Out']['Annual Renatl Income Growth'] } suffix={ '%' } />
                                  </td>
                                </tr>
                              </table> 
                            </td>
                            <td className={ classNames( classes.topTable, classes.buyingHomeOne, classes.reiTableData ) }>
                              <table>
                                <tr className={ classes.blueTableRow }>
                                  <td>Upfront Costs</td>
                                  <td >
                                    Year&nbsp;{reiCompleteData['Buying Expenses']['Purchase Year'] !== undefined && reiCompleteData['Buying Expenses']['Purchase Year'] > 0 ? (
                                      reiCompleteData['Buying Expenses']['Purchase Year']
                                    ):("&nbsp;") }
                                  </td>
                                </tr>
                                <tr>
                                  <td>Down Payment</td>
                                  <td className={-reiCompleteData['Buying Expenses']['Down Payment'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Buying Expenses']['Down Payment'] } prefix={ -reiCompleteData['Buying Expenses']['Down Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Buying Expenses']['Down Payment'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Closing Costs</td>
                                  <td className={-reiCompleteData['Buying Expenses']['Closing Costs'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Buying Expenses']['Closing Costs'] } prefix={ -reiCompleteData['Buying Expenses']['Closing Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Buying Expenses']['Closing Costs'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Fit Out Costs</td>
                                  <td className={-reiCompleteData['Buying Expenses']['Fit Out Costs'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Buying Expenses']['Fit Out Costs'] } prefix={ -reiCompleteData['Buying Expenses']['Fit Out Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Buying Expenses']['Fit Out Costs'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Net Cash Flow in Purchase Year  </td>
                                  <td className={netCashFlowVal < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ netCashFlowVal } prefix={ netCashFlowVal >= 0 ? '$' : '($' } suffix={ netCashFlowVal < 0 && ')' } />
                                  </td>
                                </tr>                               
                                <tr className={ classes.heightTwoZero }></tr>
                                
                                <tr className={ classes.blueTableRow }>
                                  <td>Sale Proceeds</td>
                                  <td>
                                    Year&nbsp;{reiCompleteData['Buying Expenses']['Selling Year'] > 0 ? (reiCompleteData['Buying Expenses']['Selling Year']):("&nbsp")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Selling Price</td>
                                  <td className={reiCompleteData['Selling Costs']['Selling Price'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Selling Costs']['Selling Price'] } prefix={ reiCompleteData['Selling Costs']['Selling Price'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Selling Costs']['Selling Price'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Selling Cost
                                    (<NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module14Data.selling_costs_in_percentage } suffix={ "%" } />)
                                  </td>
                                  <td className={-reiCompleteData['Selling Costs']['Selling Costs'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Selling Costs']['Selling Costs'] } prefix={ -reiCompleteData['Selling Costs']['Selling Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Selling Costs']['Selling Costs'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mortgage balance Remaining</td>
                                  <td className={-reiCompleteData['Selling Costs']['Mortgage Balance Remaining'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Selling Costs']['Mortgage Balance Remaining'] } prefix={ -reiCompleteData['Selling Costs']['Mortgage Balance Remaining'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Selling Costs']['Mortgage Balance Remaining'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Net Cash Flow in Sale Year</td>
                                  <td className={netCashFlowVal2 < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ netCashFlowVal2 } prefix={ netCashFlowVal2 >= 0 ? '$' : '($' } suffix={ netCashFlowVal2 < 0 && ')' } />
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td className={ classNames( classes.topTable, classes.buyingHomeTwo, classes.reiTableData ) }>
                              <table>
                                <tr  className={ classes.blueTableRow }>
                                  <td>Closing Costs</td>
                                  <td>
                                    Year&nbsp;{(reiCompleteData['Buying Expenses']['Purchase Year'] !== undefined && reiCompleteData['Buying Expenses']['Purchase Year'] > 0) ? reiCompleteData['Buying Expenses']['Purchase Year'] : '&nbsp;'}
                                  </td>
                                </tr>

                                <tr>
                                  <td>Appraisal Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Appraisal Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Appraisal Fee'] } prefix={ -reiCompleteData['Closing Cost']['Appraisal Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Appraisal Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Credit Report Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Credit Report Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Credit Report Fee'] } prefix={ -reiCompleteData['Closing Cost']['Credit Report Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Credit Report Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Attorney Fee: Title Closing/Escrow Account</td>
                                  <td className={-reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] } prefix={ -reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Title Closing/Escrow Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Flood Determination</td>
                                  <td className={-reiCompleteData['Closing Cost']['Flood Determination'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Flood Determination'] } prefix={ -reiCompleteData['Closing Cost']['Flood Determination'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Flood Determination'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Tax Service Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Tax Service Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Tax Service Fee'] } prefix={ -reiCompleteData['Closing Cost']['Tax Service Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Tax Service Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Title Insurance</td>
                                  <td className={-reiCompleteData['Closing Cost']['Owners Title Insurance'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Owners Title Insurance'] } prefix={ -reiCompleteData['Closing Cost']['Owners Title Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Owners Title Insurance'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Recording Mortgage</td>
                                  <td className={-reiCompleteData['Closing Cost']['Recording Mortgage'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Recording Mortgage'] } prefix={ -reiCompleteData['Closing Cost']['Recording Mortgage'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Recording Mortgage'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Homeowner{"'"}s Insurance (3 months prepaid upfront)*</td>
                                  <td className={-reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] } prefix={ -reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Homeowners Insurance(3 months prepaid upfront)'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Transfer Taxes</td>
                                  <td className={-reiCompleteData['Closing Cost']['Transfer Taxes'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Transfer Taxes'] } prefix={ -reiCompleteData['Closing Cost']['Transfer Taxes'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Transfer Taxes'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Processing Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Processing Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Processing Fee'] } prefix={ -reiCompleteData['Closing Cost']['Processing Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Processing Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Underwriting Fee</td>
                                  <td className={-reiCompleteData['Closing Cost']['Underwriting Fee'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Underwriting Fee'] } prefix={ -reiCompleteData['Closing Cost']['Underwriting Fee'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Underwriting Fee'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Loan Points</td>
                                  <td className={-reiCompleteData['Closing Cost']['Loan Acquisition Points'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Loan Acquisition Points'] } prefix={ -reiCompleteData['Closing Cost']['Loan Acquisition Points'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Loan Acquisition Points'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Origination Fees</td>
                                  <td className={-reiCompleteData['Closing Cost']['Origination Points'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Origination Points'] } prefix={ -reiCompleteData['Closing Cost']['Origination Points'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Origination Points'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Mortgage Interest (1 month prepaid upfront)*</td>
                                  <td className={-reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] } prefix={ -reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Mortgage Interest(1month prepaid upfront)'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Property Taxes (3 months prepaid upfront)*</td>
                                  <td className={-reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] } prefix={ -reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Property Taxes(3 months prepaid upfront)'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Pest Inspection</td>
                                  <td className={-reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] } prefix={ -reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Wood Destroying Pest Inspection'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Home Owners Association Transfer Fees</td>
                                  <td className={-reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] } prefix={ -reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Home Owners Association Transfer Fees'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Other</td>
                                  <td className={-reiCompleteData['Closing Cost']['Others'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Others'] } prefix={ -reiCompleteData['Closing Cost']['Others'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Others'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr className={ classNames( classes.blackTopBorder, classes.strongText ) }>
                                  <td>Total Closing Costs</td>
                                  <td className={-reiCompleteData['Closing Cost']['Total Initial Costs'] < 0 && classes.redText}>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Closing Cost']['Total Initial Costs'] } prefix={ -reiCompleteData['Closing Cost']['Total Initial Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Closing Cost']['Total Initial Costs'] < 0 && ')' } />
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>
                          <tr className={ classes.heightFifty }></tr>
                        </tbody>
                    </table>                   
                    <table className={ classNames( classes.incomeCashTable, classes.annualReiTable ) }>
                      <thead>
                        <tr  className={ classNames( classes.cashFlowBlue, classes.leftSpaceData ) }>
                          <td>Annual Income & Cash Flow</td>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td>{year}</td>
                            ))
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <tr className={ classes.emptyRow }>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map(() => (
                              <td>&nbsp;</td>    
                            ))
                          }
                        </tr>
                        <tr className={ classes.blueRowBackground}>
                          <td>Annual Income (before taxes)</td>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td>{year}</td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Rental Income</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Rental Income'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Rental Income'] } prefix={ -reiCompleteData['Data1'][year]['Rental Income'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Rental Income'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Association Fees</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Association Fees'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Association Fees'] } prefix={ -reiCompleteData['Data1'][year]['Association Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Association Fees'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Management Fees</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Management Fees'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Management Fees'] } prefix={ -reiCompleteData['Data1'][year]['Management Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Management Fees'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Homeowner{"'"}s Insurance</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Houseowners Insurance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] } prefix={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Property Maintenance</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Property Maintenance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Property Maintenance'] } prefix={ -reiCompleteData['Data1'][year]['Property Maintenance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Property Maintenance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Utilities</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Utilities'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Utilities'] } prefix={ -reiCompleteData['Data1'][year]['Utilities'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Utilities'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Property Taxes</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Property Taxes'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Property Taxes'] } prefix={ -reiCompleteData['Data1'][year]['Property Taxes'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Property Taxes'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr className={ classNames( classes.borderLine, classes.leftSpaceData, classes.strongText  ) }>
                          <td className={ classes.subHeading }>Net Operating Income</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Net Operating Income'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Net Operating Income'] } prefix={ reiCompleteData['Data1'][year]['Net Operating Income'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Net Operating Income'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr className={classes.emptyRow}>
                          <td className={ classes.subHeading }></td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td >&nbsp;</td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Mortgage Interest** </td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Mortgage Interest'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Mortgage Interest'] } prefix={ reiCompleteData['Data1'][year]['Mortgage Interest'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Mortgage Interest'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr className={ classNames( classes.borderLine, classes.leftSpaceData, classes.strongText  ) }>
                          <td className={ classes.subHeading }>Net Income</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Net Income REI Table'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Net Income REI Table'] } prefix={ reiCompleteData['Data1'][year]['Net Income REI Table'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Net Income REI Table'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr className={classes.emptyRow}>
                          <td className={ classes.subHeading }></td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td >&nbsp;</td>
                            ))
                          }
                        </tr>
                        <tr className={ classes.blueRowBackground}>
                          <td>Annual Cash Flow (before taxes)</td>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td>{year}</td>
                            ))
                          }
                        </tr>

                        <tr>
                          <td className={ classes.subHeading }>Rental Income</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Rental Income'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Rental Income'] } prefix={ -reiCompleteData['Data1'][year]['Rental Income'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Rental Income'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Down Payment</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Down Payment'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Down Payment'] } prefix={ -reiCompleteData['Data1'][year]['Down Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Down Payment'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>External & Internal Fit Out Costs</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Fit Out Costs'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Fit Out Costs'] } prefix={ -reiCompleteData['Data1'][year]['Fit Out Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Fit Out Costs'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Closing Costs</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Closing Costs'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Closing Costs'] } prefix={ -reiCompleteData['Data1'][year]['Closing Costs'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Closing Costs'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Mortgage Payment**</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Mortgage Payment'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Mortgage Payment'] } prefix={ -reiCompleteData['Data1'][year]['Mortgage Payment'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Mortgage Payment'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Association Fees</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Association Fees'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Association Fees'] } prefix={ -reiCompleteData['Data1'][year]['Association Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Association Fees'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Management Fee</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Management Fees'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Management Fees'] } prefix={ -reiCompleteData['Data1'][year]['Management Fees'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Management Fees'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Homeowner's Insurance***</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Houseowners Insurance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] } prefix={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Houseowners Insurance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Property Maintenance</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Property Maintenance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Property Maintenance'] } prefix={ -reiCompleteData['Data1'][year]['Property Maintenance'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Property Maintenance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Utilities</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Utilities'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Utilities'] } prefix={ -reiCompleteData['Data1'][year]['Utilities'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Utilities'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Property Taxes***</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Property Taxes'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Property Taxes'] } prefix={ -reiCompleteData['Data1'][year]['Property Taxes'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Property Taxes'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Net Cash Flow from Sale</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Data1'][year]['Net Cash Flow from Sale'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Data1'][year]['Net Cash Flow from Sale'] } prefix={ -reiCompleteData['Data1'][year]['Net Cash Flow from Sale'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Data1'][year]['Net Cash Flow from Sale'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr className={ classNames( classes.borderLine, classes.leftSpaceData, classes.strongText  ) }>
                          <td className={ classes.subHeading }>Net Cash Flow (before taxes)</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Net Cash Flow REI Table'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Net Cash Flow REI Table'] } prefix={ reiCompleteData['Data1'][year]['Net Cash Flow REI Table'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Net Cash Flow REI Table'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>
                        <tr className={classes.emptyRow}>
                          <td className={ classes.subHeading }></td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td >&nbsp;</td>
                            ))
                          }
                        </tr>
                        <tr className={ classes.blueRowBackground}>
                          <td>Passive Losses - Tax Deduction****</td>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td>{year}</td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Net Operating Income</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Net Operating Income'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Net Operating Income'] } prefix={ reiCompleteData['Data1'][year]['Net Operating Income'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Net Operating Income'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Mortgage Interest, Loan Points, & Origination Fees</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Mortgage Interest, Loan Points, Origination Fees'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Mortgage Interest, Loan Points, Origination Fees'] } prefix={ reiCompleteData['Data1'][year]['Mortgage Interest, Loan Points, Origination Fees'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Mortgage Interest, Loan Points, Origination Fees'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Depreciation</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={-reiCompleteData['Real Estate'][year]['Home REI Table'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -reiCompleteData['Real Estate'][year]['Home REI Table'] } prefix={ -reiCompleteData['Real Estate'][year]['Home REI Table'] >= 0 ? '$' : '($' } suffix={ -reiCompleteData['Real Estate'][year]['Home REI Table'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr>


                        <tr>
                          <td className={ classes.subHeading }>REI Property Taxable Income</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data3'][year]['REI Property Taxable Income'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data3'][year]['REI Property Taxable Income'] } prefix={ reiCompleteData['Data3'][year]['REI Property Taxable Income'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data3'][year]['REI Property Taxable Income'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Passive Losses Created</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data3'][year]['Passive Loss Created'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data3'][year]['Passive Loss Created'] } prefix={ reiCompleteData['Data3'][year]['Passive Loss Created'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data3'][year]['Passive Loss Created'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr className={classes.emptyRow}>
                          <td className={ classes.subHeading }></td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td >&nbsp;</td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Passive Loss Max Annual Limit</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data3'][year]['Max Loss Allowed after Phaseouts'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data3'][year]['Max Loss Allowed after Phaseouts'] } prefix={ reiCompleteData['Data3'][year]['Max Loss Allowed after Phaseouts'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data3'][year]['Max Loss Allowed after Phaseouts'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr className={classes.emptyRow}>
                          <td className={ classes.subHeading }></td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td >&nbsp;</td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classes.subHeading }>Passive Losses Beginning Balance</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data3'][year]['Passive Losses Beginning Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data3'][year]['Passive Losses Beginning Balance'] } prefix={ reiCompleteData['Data3'][year]['Passive Losses Beginning Balance'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data3'][year]['Passive Losses Beginning Balance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Passive Losses Created</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data3'][year]['Passive Loss Created'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data3'][year]['Passive Loss Created'] } prefix={ reiCompleteData['Data3'][year]['Passive Loss Created'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data3'][year]['Passive Loss Created'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Passive Losses Balance</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data1'][year]['Passive Losses Balance'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1'][year]['Passive Losses Balance'] } prefix={ reiCompleteData['Data1'][year]['Passive Losses Balance'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1'][year]['Passive Losses Balance'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Passive Losses Taken</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data3'][year]['Passive Losses Taken'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data3'][year]['Passive Losses Taken'] } prefix={ reiCompleteData['Data3'][year]['Passive Losses Taken'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data3'][year]['Passive Losses Taken'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Remaining Passive Losses</td>
                          <td className={ classes.lightBlueBg }>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td className={reiCompleteData['Data3'][year]['Remaining Passive Losses'] < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data3'][year]['Remaining Passive Losses'] } prefix={ reiCompleteData['Data3'][year]['Remaining Passive Losses'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data3'][year]['Remaining Passive Losses'] < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr className={classes.emptyRow}>
                          <td></td>
                          <td>&nbsp;</td>  
                          {
                            reiYearsList.map((year) => (
                              <td >&nbsp;</td>
                            ))
                          }
                        </tr>
                        <tr className={ classes.cashFlowBlue }>
                          <td>Return Calculations</td>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map((year) => (
                              <td>&nbsp;</td>
                            ))
                          }
                        </tr> 
                        <tr className={ classes.emptyRow }>
                          <td>&nbsp;</td>
                          <td>&nbsp;</td>
                          {
                            reiYearsList.map(() => (
                              <td>&nbsp;</td>    
                            ))
                          }
                        </tr>
                        <tr className={ classes.blueRowBackground}>
                          <td>Return Calculations (before taxes)  </td>
                          {
                            reiCompleteData['netCashFlowBeforeTaxesValues'].map((year, index) => (
                              <td key={year}>{index}</td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Net Cash Flow</td>
                          {
                            reiCompleteData['netCashFlowBeforeTaxesValues'].map((value) => (
                              <td className={value < 0 && classes.redText}>
                                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ value } prefix={ value >= 0 ? '$' : '($' } suffix={ value < 0 && ')' } />
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classes.subHeading }>Total Cash Returned</td>
                          {
                            reiCompleteData['netCashFlowBeforeTaxesValues'].map((value, index) => (
                              <td className={reiCompleteData['Data1']['Net Cash Flow Before Taxes Sum'] < 0 && classes.redText}>
                                {index === 0 && (
                                  <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['Data1']['Net Cash Flow Before Taxes Sum'] } prefix={ reiCompleteData['Data1']['Net Cash Flow Before Taxes Sum'] >= 0 ? '$' : '($' } suffix={ reiCompleteData['Data1']['Net Cash Flow Before Taxes Sum'] < 0 && ')' } />
                                )}
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classNames( classes.subHeading, classes.italicLeft ) }><i>Return on Investment (ROI)</i></td>
                          {
                            reiYearsList.map((year, index) => (
                              <td>
                                {index === 0 &&
                                <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['ROI'] } suffix={ '%' } />
                                }
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classNames( classes.subHeading, classes.italicLeft ) }><i>Multiple of Money (MoM)</i></td>
                          {
                            reiYearsList.map((year, index) => (
                              <td>
                                {index === 0 &&
                                <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['MoM'] } suffix={ 'x' } />
                                }
                              </td>
                            ))
                          }
                        </tr>
                        <tr>
                          <td className={ classNames( classes.subHeading, classes.italicLeft ) }><i>Cash-on-Cash Return (CoC)</i></td>
                          {
                            reiYearsList.map((year, index) => (
                              <td>
                                {index === 0 &&
                                <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['CoC'] } suffix={ '%' } />
                                }
                              </td>
                            ))
                          }
                        </tr> 
                        <tr>
                          <td className={ classNames( classes.subHeading, classes.italicLeft ) }><i>Internal Rate of Return (IRR)</i></td>
                          {
                            reiYearsList.map((year, index) => (
                              <td>
                                {index === 0 &&
                                <NumberFormat decimalScale={ 1 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ reiCompleteData['IRR'] } suffix={ '%' } />
                                }
                              </td>
                            ))
                          }
                        </tr> 

                      </tbody>
                    </table>
                  </td>
                </tr>
              </table>
              <div className={ classes.notePara }>
                  <p className={ classes.noteRei }>*Prepayments are typically kept in an escrow account. To learn more about an escrow account and how it plays a role in real estate transactions, see the Boost My Awareness section for a deeper understanding.</p>
                  <p className={ classes.noteRei }>**The first year mortgage payment and mortgage interest is lower than all other years because 1 month of interest is paid upfront at close and is captured in closing costs.</p>
                  <p className={ classes.noteRei }>***3 months of Homeowner's Insurance and Property Taxes are typically paid at close. In the sale year, these expenses will be 3 months lower since they were paid upfront.</p>
                  <p className={ classes.noteRei }>****Check out the Boost My Awareness section for more details on how Passive Losses work in the FC.</p>
              </div>
            </div>
          </DialogContent>
        <DialogActions>
        <div className={ classes.modalFooter }>
        <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closePopup }>Close</Button>
        </div>
        </DialogActions>
      </Dialog>

    </Typography>
    );
};

ReiTable.propTypes = {
  classes: PropTypes.object.isRequired,
  closeWorkSheet:PropTypes.func.isRequired, 
  openWorkSheet:PropTypes.bool.isRequired,
};


export default withStyles( styles )( ReiTable );

