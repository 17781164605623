const globalStyles = ( ) => ( {
  defaultLinkButton: {
    border: 0,
    backgroundColor: 'transparent',
    color: '#000',
    padding: 0,
    minWidth: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
} );

export default globalStyles;
