import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  TextField,
  Typography, Button,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import styles from '../../screens/pwi-investments/styles';
import { FV } from '../../utilities/commonFunctions';
import { formatDollar } from '../../utilities/chartCommonFunctions';
import $ from 'jquery';
import * as commonFunctions from '../../utilities/commonFunctions';
import SwipeableViews from 'react-swipeable-views';
// import Grid from '@material-ui/core/Grid';

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const PowerOfSaving = (props) => {
  const {
    classes, getInvestmentObject, handleInvestmentData,
  } = props;

  const [monthlySavings, setMonthlySavings] = React.useState(getInvestmentObject.monthly_savings === undefined ? 500 : getInvestmentObject.monthly_savings);
  // const [migbeginningBalance] = React.useState(10000);
  const [annualReturn, setAnnualReturn] = React.useState(getInvestmentObject.return === undefined ? 5 : getInvestmentObject.return);
  const [fifthValue, setFifthValue] = React.useState(0);
  const [tenthValue, setTenthValue] = React.useState(0);
  const [fifteenthValue, setFifteenthValue] = React.useState(0);
  const [annualReturnGraphData, setAnnualReturnGraphData] = React.useState({});
  const [value, setValue] = React.useState(0);
  const [loanRepayment, setloanRepayment] = React.useState({});
  const [begningBal, setBeginingBal] = React.useState(getInvestmentObject.beginningBalance === undefined ? 10000 : getInvestmentObject.beginningBalance);
  const [isFocus, setIsFocus] = React.useState('');
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateGraph = () => {
    const graphArray = [];
    const graphArray1 = [];
    const graphArray2 = [];
    const graphArrayYears = [];
    const secondReturn = annualReturn / 2;
    const thirdReturn = annualReturn + secondReturn;
    for (let y = 1; y <= 30; y += 1) {
      const endingValue = FV((annualReturn / 12) / 100, y * 12, -monthlySavings, -begningBal, 0, 0);
      graphArray.push(endingValue);
      if (y === 5) {
        setFifthValue(endingValue);
      }
      if (y === 10) {
        setTenthValue(endingValue);
      }
      if (y === 15) {
        setFifteenthValue(endingValue);
      }
      const endingValue1 = FV((secondReturn / 12) / 100, y * 12, -monthlySavings, -begningBal, 0, 0);
      graphArray1.push(endingValue1);
      const endingValue2 = FV((thirdReturn / 12) / 100, y * 12, -monthlySavings, -begningBal, 0, 0);
      graphArray2.push(endingValue2);

      graphArrayYears.push(y);
    }
    setAnnualReturnGraphData(
      {
        list: graphArrayYears,
        data: [{
          name: `${parseFloat(thirdReturn).toFixed(2)}% return`,
          data: graphArray2,
          color: '#4472C4',
        }, {
          name: `${parseFloat(annualReturn).toFixed(2)}% return`,
          data: graphArray,
          color: '#Ed7D31',
        }, {
          name: `${parseFloat(secondReturn).toFixed(2)}% return`,
          data: graphArray1,
          color: '#A5A5A5',
        }],
      },
    );
  };

  var windowHeight = $(window).height();
  // var graphMainWidth = $('.graphwith_expenses_cp').width() - 250;


  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    const powerObj = { ...getInvestmentObject };
    if (getInvestmentObject.monthly_savings === undefined) {
      powerObj.monthly_savings = 500;
    }
    if (getInvestmentObject.return === undefined) {
      powerObj.return = 5;
    }
    handleInvestmentData(powerObj);

    updateGraph();
    // eslint-disable-next-line
  }, []);

  const updateInputValue = (e, inputType, field) => {
    const startObj = { ...getInvestmentObject };
    let newValue = e.floatValue !== undefined ? e.floatValue : 0;
    if (inputType === 'savings') {
      setMonthlySavings(newValue);
    } else if (inputType === 'balance') {
      setBeginingBal(newValue);
    } else {
      if (newValue > 100) {
        newValue = 100;
      }
      setAnnualReturn(newValue);
    }
    startObj[field] = newValue;
    handleInvestmentData(startObj);
  };

  const floanRepayment = {};
  floanRepayment.loanAmount = 1000000;
  floanRepayment.termOfTheLoan = 5;
  floanRepayment.interestRate = 7;
  floanRepayment.beginningBalance = 80000;

  useEffect(() => {
    setloanRepayment(floanRepayment);
  }, []);

  const updateFederalInputValue = (e, field) => {
    if (field === 'Investment_Goal') {
      const Investment_Goal = { ...loanRepayment };
      Investment_Goal.loanAmount = e.floatValue !== undefined ? e.floatValue : 0;
      setloanRepayment(Investment_Goal);
    }
    if (field === 'time') {
      const Investment_Goal = { ...loanRepayment };
      Investment_Goal.termOfTheLoan = e.floatValue !== undefined ? e.floatValue : 0;
      if (Investment_Goal.termOfTheLoan > 100) {
        Investment_Goal.termOfTheLoan = 100;
      }
      setloanRepayment(Investment_Goal);
    }
    if (field === 'expected_return') {
      const Investment_Goal = { ...loanRepayment };
      Investment_Goal.interestRate = e.floatValue !== undefined ? e.floatValue : 0;
      if (Investment_Goal.interestRate > 100) {
        Investment_Goal.interestRate = 100;
      }
      setloanRepayment(Investment_Goal);
    }
    if (field === 'beginning_balance') {
      const Investment_Goal = { ...loanRepayment };
      Investment_Goal.beginningBalance = e.floatValue !== undefined ? e.floatValue : 0;
      setloanRepayment(Investment_Goal);
    }
  }

  //graph values  
  var loanAmount = 0;
  if (loanRepayment.loanAmount !== undefined && loanRepayment.loanAmount !== '') {
    loanAmount = parseInt(loanRepayment.loanAmount);
  }

  // Beginning Balance
  var beginningBalance = 0;
  if (loanRepayment.beginningBalance !== undefined && loanRepayment.beginningBalance !== '') {
    beginningBalance = parseInt(loanRepayment.beginningBalance);
  }

  // Terms in Years
  // var termsOfYear = 0;            
  // if(beginningBalance > 0) {
  var termsOfYear = parseFloat(loanRepayment.termOfTheLoan);
  // }

  // Annual Interest Rate
  var annualInterestRate = 0;
  if (loanRepayment.interestRate !== undefined && loanRepayment.interestRate !== '') {
    annualInterestRate = parseFloat(loanRepayment.interestRate);
  }


  // var paymentPlanIdentifier = '';

  //annualInterestRate = 4.50;

  // Original Payment
  var originalPayment = 0;
  if (loanAmount < beginningBalance) {
    originalPayment = 0;
  } else {
    originalPayment = commonFunctions.PMT((parseFloat(annualInterestRate) / 100) / 12, termsOfYear * 12, beginningBalance, -loanAmount, 0);
    if (originalPayment === 'Infinity') {
      originalPayment = 0;
    }
  }
  loanRepayment.originalPaymentValue = originalPayment;
  loanRepayment.totalContributionsValue = (originalPayment * termsOfYear * 12) + beginningBalance;
  loanRepayment.totalEarningsValue = loanAmount - loanRepayment.totalContributionsValue;

  // Interest Accrued 
  // if (paymentPlanIdentifier !== undefined && paymentPlanIdentifier === 3 &&
  //   beginningBalance > 0 &&
  //   loanAmount > 0) {
  //   let interestAccrued = 0;
  //   interestAccrued = beginningBalance - loanAmount;
  // }


  // // Total Interest Paid
  // var totalInterestPaid = 0;

  // // Total Paid
  // var totalPaid = 0;

  // // Total Points Pais
  // var totalPointsPaid = 0;

  // // Tabular Data            
  // var graduationYear = 0;
  /*modulesServices.fedSubPaymentYearBegins();
  if(  module18Data.fedSubPaymentYearBegins != 'undefined') {
      graduationYear = module18Data.fedSubPaymentYearBegins;
  }*/
  var monthlyInvestmentGraph1 = [];
  var monthlyInvestmentGraph2 = [];
  var monthlyInvestmentGraph3 = [];
  var graphArrayYearsValues = [];

  var monthlyInvestmentGraphVal1 = 0;
  var monthlyInvestmentGraphVal2 = 0;
  var monthlyInvestmentGraphVal3 = 0;

  var roundedValue1 = 0;
  var roundedValue2 = 0;
  var roundedValue3 = 0;

  for (var y = 1; y <= 30; y++) {
    graphArrayYearsValues.push(y);
    monthlyInvestmentGraphVal1 = commonFunctions.FV((annualInterestRate / 12) / 100, y * 12, -(loanRepayment.originalPaymentValue / 100) * 75, -beginningBalance, 0);
    monthlyInvestmentGraph1.push(monthlyInvestmentGraphVal1);
    monthlyInvestmentGraphVal2 = commonFunctions.FV((annualInterestRate / 12) / 100, y * 12, - loanRepayment.originalPaymentValue, -beginningBalance, 0);
    monthlyInvestmentGraph2.push(monthlyInvestmentGraphVal2);
    monthlyInvestmentGraphVal3 = commonFunctions.FV((annualInterestRate / 12) / 100, y * 12, -(loanRepayment.originalPaymentValue / 100) * 125, -beginningBalance, 0);
    monthlyInvestmentGraph3.push(monthlyInvestmentGraphVal3);
  }
  //Display the Graph
  //  monthlyInvestmentGraph1 = monthlyInvestmentGraph1;
  //  monthlyInvestmentGraph2 = monthlyInvestmentGraph2;
  //  monthlyInvestmentGraph3 = monthlyInvestmentGraph3;
  // let yearsListGraph = yearsListGraph;

  roundedValue1 = formatDollar(Math.round((loanRepayment.originalPaymentValue / 100) * 75));
  roundedValue2 = formatDollar(Math.round(loanRepayment.originalPaymentValue));
  roundedValue3 = formatDollar(Math.round((loanRepayment.originalPaymentValue / 100) * 125));

  //Labels
  const label1 = roundedValue1 + ' monthly investment';
  const label2 = roundedValue2 + ' monthly investment';
  const label3 = roundedValue3 + ' monthly investment';

  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={classes.contentBlock}>

      <p className={classes.firstPara}>Investing small amounts of money over time can grow to large amounts of money due to the power of compounding interest. Benjamin Franklin defined compounding interest as, "Money makes money. And the money that money makes, makes money." </p>
      <p className={classes.firstPara}>  Understanding the power of compounding interest, Benjamin Franklin left $5,000 to his two favorite cities - Philadelphia and Boston - in 1790. After 100 years, his instructions were to withdraw $500,000 for public works. After 200 years, each city was allowed to withdraw the full balance. How much did each city have in 200 years after a $5,000 initial investment? Almost $5,000,000 for Boston and $2,000,000 for Philadelphia. <a href="https://www.historynet.com/ben-franklins-gift-keeps-giving.htm" target="_blank" rel="noreferrer">True story</a>.</p>
      <p className={classes.firstPara}>Use the two tools below, change the inputs and see the power of compounding interest firsthand. Then, let's get started on your investing strategy.</p>
      <div className={classes.tabSectionScroll}>
        <div className={classes.tabSection}>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabButtons}>
            <Tab label="Future Savings Target"
              {...a11yProps(0)}
              className={value === 0 ? classes.activeTab : ''} />
            <Tab label="Monthly Investment Growth"
              {...a11yProps(1)}
              className={value === 1 ? classes.activeTab : ''} />
          </Tabs>
          <SwipeableViews
            className={`${classes.tabsContent} ${classes.tabContentBaseIncome}`}
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <div className={classes.potentialGrowthTab}>
                <div className={classes.existingstudentloanFlex}>
                  <div className={classes.existingstudentloanGraphLeft}>
                    <table>
                      <tr>
                        <td>
                          Investment Goal
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className={classes.existingstudentInput}>
                            <span>$</span>
                            <NumberFormat id="beginning" type="text" decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={loanRepayment.loanAmount} onValueChange={(e) => updateFederalInputValue(e, 'Investment_Goal')} onFocus={(e) => e.target.select()} />
                          </div>
                        </td>
                      </tr>
                      <tr><td className={classes.h18}></td></tr>
                      <tr>
                        <td>
                          Time (yrs)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className={`${classes.existingstudentInput} ${classes.inputRight}`}>
                            <NumberFormat id="beginning" type="text" max={100} min={0} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={loanRepayment.termOfTheLoan} onValueChange={(e) => updateFederalInputValue(e, 'time')} onFocus={(e) => e.target.select()} />
                            <span>years</span>
                          </div>
                        </td>
                      </tr>
                      <tr><td className={classes.h18}></td></tr>
                      <tr>
                        <td>
                          Expected Return (annual)
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className={`${classes.existingstudentInput} ${classes.inputRight}`}>
                            <NumberFormat
                              id="beginning"
                              type="text"
                              min={0}
                              max={100}
                              decimalScale={isFocus!=='expected_return'?2:5}
                              fixedDecimalScale={isFocus!=='expected_return'}
                              customInput={TextField}
                              thousandSeparator
                              value={loanRepayment.interestRate}
                              onValueChange={(e) => updateFederalInputValue(e, 'expected_return')}
                              onFocus={(e) => {e.target.select(); setIsFocus('expected_return')}}
                              onBlur={emptyIsFocus} />
                            <span>%</span>
                          </div>
                        </td>
                      </tr>
                      <tr><td className={classes.h18}></td></tr>
                      <tr>
                        <td>
                          Beginning Balance
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className={classes.existingstudentInput}>
                            <span>$</span>
                            <NumberFormat id="beginning" type="text" min={0} max={100} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={loanRepayment.beginningBalance} onValueChange={(e) => updateFederalInputValue(e, 'beginning_balance')} onFocus={(e) => e.target.select()} />
                          </div>
                        </td>
                      </tr>
                      <tr><td className={classes.h18}></td></tr>
                      <tr><td className={classes.tdBorder}></td></tr>
                      <tr><td className={classes.h18}></td></tr>
                      <tr class="">
                        <td>
                          Monthly Investment
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.fieldText}>
                          <NumberFormat displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={loanRepayment.originalPaymentValue} prefix={'$'} />
                          {/* <NumberFormat id="beginning" type="text" min={0} max={100} decimalScale={0} fixedDecimalScale className={classNames(classes.formInput, classes.annlInput)} customInput={TextField} thousandSeparator value={loanRepayment.originalPaymentValue} prefix={'$'} /> */}
                        </td>
                      </tr>
                      <tr><td className={classes.h18}></td></tr>
                      <tr>
                        <td>
                          Total Contributions
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.fieldText}>
                          <NumberFormat displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={loanRepayment.totalContributionsValue} prefix={'$'} />
                          {/* <NumberFormat id="beginning" type="text" min={0} max={100} decimalScale={0} fixedDecimalScale className={classNames(classes.formInput, classes.annlInput)} customInput={TextField} thousandSeparator value={loanRepayment.totalContributionsValue} prefix={'$'} /> */}
                        </td>
                      </tr>
                      <tr><td className={classes.h18}></td></tr>
                      <tr>
                        <td>
                          Total Earnings
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.fieldText}>
                          <NumberFormat displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={loanRepayment.totalEarningsValue} prefix={'$'} />
                          {/* <NumberFormat id="beginning" type="text" min={0} max={100} decimalScale={0} fixedDecimalScale className={classNames(classes.formInput, classes.annlInput)} customInput={TextField} thousandSeparator value={loanRepayment.totalEarningsValue} prefix={'$'} /> */}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className={classes.existingstudentloanGraphRight}>
                    <Typography variant="h3" component="h3">Investment Target</Typography>
                    <hr className={classes.blueLine} />
                    <div>
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          chart: {
                            type: 'spline',
                            height: windowHeight,
                            scrollablePlotArea: {
                              scrollPositionX: 0
                            }
                          },
                          credits: {
                            enabled: false
                          },
                          title: {
                            text: '',
                            style: {
                              color: '#000000',
                              fontSize: '14px'
                            }
                          },
                          navigation: {
                            menuItemStyle: {
                              fontSize: '14px',
                              textAlign: 'left'
                            },
                            menuItemHoverStyle: {
                              background: '#f5f5f5',
                              color: '#4c4c4c',
                              fontSize: '14px'
                            },
                            buttonOptions: {
                              height: 40,
                              width: 48,
                              symbolSize: 24,
                              symbolX: 40,
                              symbolY: 40,
                              symbolStrokeWidth: 2,
                              verticalAlign: 'bottom',
                              _titleKey: 'y'
                            }
                          },
                          exporting: {
                            buttons: {
                              contextButton: {
                                menuItems: [{
                                  textKey: 'downloadPNG',
                                  onclick: function () {
                                    this.exportChart();
                                  },
                                }, {
                                  textKey: 'downloadJPEG',
                                  onclick: function () {
                                    this.exportChart({
                                      type: 'image/jpeg'
                                    });
                                  }
                                }, {
                                  textKey: 'downloadPDF',
                                  onclick: function () {
                                    this.exportChart({
                                      type: 'application/pdf'
                                    });
                                  }
                                }, {
                                  textKey: 'downloadSVG',
                                  onclick: function () {
                                    this.exportChart({
                                      type: 'image/svg+xml'
                                    });
                                  }
                                }]
                              }
                            }
                          },
                          xAxis: [{
                            min: 0,
                            tickInterval: 1,
                            max: 30,
                            categories: graphArrayYearsValues,
                            labels: {
                              style: {
                                color: '#000000',
                                fontSize: '14px'
                              }
                            },
                            title: {
                              text: 'Years',
                              style: {
                                color: '#000000',
                                fontSize: '14px'
                              }
                            }
                          }],
                          yAxis: [{ // Primary yAxis
                            labels: {
                              formatter: function () {
                                if (this.value < 0) {
                                  return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(this.value)) + ')</span>';
                                } else {
                                  return formatDollar(Math.round(this.value));
                                }
                              },
                              style: {
                                color: '#000000',
                                fontSize: '14px'
                              }
                            },
                            opposite: true,
                            title: {
                              text: 'Savings',
                              style: {
                                color: '#000000',
                                fontSize: '14px'
                              }
                            }
                          }],
                          tooltip: {
                            headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
                            crosshairs: true,
                            shared: true,
                            valueDecimals: 0,
                            valuePrefix: '$',
                            style: {
                              color: '#000000',
                              fontSize: '13px'
                            }
                          },
                          plotOptions: {
                            spline: {
                              lineWidth: 2,
                              states: {
                                hover: {
                                  lineWidth: 2
                                }
                              },
                              marker: {
                                enabled: false
                              },

                            }
                          },
                          legend: {
                            itemStyle: {
                              fontSize: '14px'
                            }
                          },
                          series: [{
                            name: label1,
                            data: monthlyInvestmentGraph1,
                            color: '#4472C4'
                          }, {
                            name: label2,
                            data: monthlyInvestmentGraph2,
                            color: '#Ed7D31'
                          }, {
                            name: label3,
                            data: monthlyInvestmentGraph3,
                            color: '#A5A5A5'
                          }]
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <div className={`${classes.potentialGrowth} ${classes.potentialGrowthFull}`}>
                <Typography variant="h3" component="h3">Investment Growth</Typography>
                <hr className={`${classes.blueLine} ${classes.blueLineNew}`} />
                <div className={classes.potentialInputs}>
                  <div className={classes.careerInput}>
                    <label htmlFor="month">
                      {' '}
                      Beginning Balance:
                    </label>
                    <div className={classes.inputGroup}>
                      <span className={classes.dollarSymbol}>$</span>
                      <NumberFormat id="beginning" decimalScale={0} fixedDecimalScale className={classNames(classes.formInput, classes.annlInput)} customInput={TextField} thousandSeparator value={begningBal} onValueChange={(e) => updateInputValue(e, 'balance', 'beginning_balance')} onFocus={(e) => e.target.select()} />
                    </div>
                  </div>
                  <div className={classes.careerInput}>
                    <label htmlFor="month">
                      {' '}
                      Monthly Savings:
                    </label>
                    <div className={classes.inputGroup}>
                      <span className={classes.dollarSymbol}>$</span>
                      <NumberFormat id="annual" decimalScale={0} fixedDecimalScale className={classNames(classes.formInput, classes.annlInput)} customInput={TextField} thousandSeparator value={monthlySavings} onValueChange={(e) => updateInputValue(e, 'savings', 'monthly_savings')} onFocus={(e) => e.target.select()} />
                    </div>
                  </div>
                  <div className={classes.careerInput}>
                    <label htmlFor="annual">
                      {' '}
                      Annual Return
                    </label>
                    <div className={classes.inputGroup}>
                      <NumberFormat
                        id="annual"
                        decimalScale={isFocus!=='return'?2:5}
                        fixedDecimalScale={isFocus!=='return'}
                        className={classNames(classes.formInput, classes.returnInput)}
                        customInput={TextField} thousandSeparator value={annualReturn}
                        onValueChange={(e) => updateInputValue(e, 'return', 'return')}
                        onFocus={(e) => {e.target.select(); setIsFocus('return') }}
                        onBlur={emptyIsFocus} />
                      <span className={classNames(classes.percentSymbol, classes.percentPadding)}>%</span>
                    </div>

                  </div>
                  <Button variant="contained" onClick={updateGraph} className={classes.calculateButton}>
                    Calculate
                  </Button>
                  <div className={classes.netWorth}>
                    <ul>
                      <li>
                        {' '}
                        <p>
                          {' '}
                          <span>5 Years</span>
                          {' '}
                          <font><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={fifthValue} prefix="$" /></font>
                          {' '}
                        </p>
                        {' '}
                      </li>
                      <li>
                        {' '}
                        <p>
                          {' '}
                          <span>10 Years</span>
                          {' '}
                          <font><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={tenthValue} prefix="$" /></font>
                          {' '}
                        </p>
                        {' '}
                      </li>
                      <li>
                        {' '}
                        <p>
                          {' '}
                          <span>15 Years</span>
                          {' '}
                          <font><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={fifteenthValue} prefix="$" /></font>
                          {' '}
                        </p>
                        {' '}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className={classes.graphImage}>
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: 'spline',
                        scrollablePlotArea: {
                          width: 600,
                          scrollPositionX: 0,
                        },
                      },
                      credits: {
                        enabled: false,
                      },
                      title: {
                        text: '',
                        style: {
                          color: '#000000',
                          fontSize: '14px',
                        },
                      },
                      exporting: {
                        enabled: false,
                      },
                      xAxis: [{
                        tickInterval: 1,
                        categories: annualReturnGraphData.list,
                        labels: {
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                        title: {
                          text: 'Years',
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                      }],
                      yAxis: [{
                        labels: {
                          formatter() {
                            const chart = this;
                            if (chart.value < 0) {
                              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                            }
                            return formatDollar(Math.round(chart.value));
                          },
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                        title: {
                          text: '',
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                      }],
                      tooltip: {
                        headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 0,
                        valuePrefix: '$',
                        style: {
                          color: '#000000',
                          fontSize: '13px',
                        },
                      },
                      plotOptions: {
                        spline: {
                          lineWidth: 2,
                          states: {
                            hover: {
                              lineWidth: 2,
                            },
                          },
                          marker: {
                            enabled: false,
                          },

                        },
                      },
                      legend: {
                        itemStyle: {
                          fontSize: '14px',
                        },
                      },
                      series: annualReturnGraphData.data,
                    }}
                  />
                </div>
              </div>
            </TabPanel>
          </SwipeableViews>
        </div>
      </div>
    </div>
  );
};

PowerOfSaving.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,
};

export default withStyles(styles)(PowerOfSaving);
