import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Pageloader from '../../components/ui/pageloader';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import { loader } from 'graphql.macro';
import scrollToComponent from 'react-scroll-to-component';
import PowerOfSaving from '../../components/pwi-investments/power-of-saving';
import ShortTermInvestmentType from '../../components/pwi-investments/short_term_investment_types';
import SettingTimelines from '../../components/pwi-investments/short-investment-setting-timelines';
import BeiginingMaxBalances from '../../components/pwi-investments/short-term-begining-and-max-balances';
import MonthlyContribution from '../../components/pwi-investments/short-term-monthly-contribution';
// import AnnualReturnSummary from '../../components/pwi-investments/annual-return-summary';
import LongTermInvestmentTypes from '../../components/pwi-investments/long-term-investment-types';
import LongInvestmentSettingTimeline from '../../components/pwi-investments/long-term-investment-setting-timeline';
import LongTermBeginingBalance from '../../components/pwi-investments/long-term-begining-balance';
import LongTermMonthlyContribution from '../../components/pwi-investments/long-term-monthly-contribution';
import LongTermAnnualReturn from '../../components/pwi-investments/long-term-annual-return';
import PlanSettingTimeline from '../../components/pwi-investments/plan-begining';
import PlanInvestments from '../../components/pwi-investments/plan-investments';
import LifeSettingTimeline from '../../components/pwi-investments/life -Insurance';
import LifeInvestments from '../../components/pwi-investments/life-insurance-investment';
import FinancialGift from '../../components/pwi-investments/financial-gift';
import CharitableContribution from '../../components/pwi-investments/charitable-contribution';
import CharitableContributionSummary from '../../components/pwi-investments/charitable-contribution-summary';
// import InvestmentsSummary from '../../components/pwi-investments/investments-summary';
import InvestmentWorksheetTable from '../../components/documents/worksheet-tables/investments-worksheet-table';
import PwiSave from '../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../components/common/save-popup-success';
import headerFI from '../../assets/img/pwi-investments/investments.png';
import LogoImage from '../../assets/img/logo.png';
import styles from './styles';
import Walk from '../../assets/img/pwi-investments/walk.png';
import ClearTime from '../../assets/img/pwi-investments/clear-timeline-blue.png';
import MaxBalance from '../../assets/img/pwi-investments/max-balance.png';
import DefinationPopup from '../../components/ui/pwi-sidebar/defination-popup';
import PwiLeftMenu from '../../components/pwi/pwi-left-menu';
import WalkThruOne from '../pwi/wallk-thru/walkThruOne';
import WalkThruTwo from '../pwi/wallk-thru/walkThruTwo';
import WalkThruThree from '../pwi/wallk-thru/walkThruThree';
import WalkThruFour from '../pwi/wallk-thru/walkThruFour';
import WalkThruFive from '../pwi/wallk-thru/walkThruFive';
import WalkThruSix from '../pwi/wallk-thru/walkThruSix';
import WalkThruSeven from '../pwi/wallk-thru/walkThruSeven';
import WalkThruEight from '../pwi/wallk-thru/walkThruEight';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import SimulatorPanel from '../../components/documents/simulator-panel';
import SummaryDocs from '../../components/documents/documents';
import * as modulesServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import * as moduleServices from '../../calculations/modules-services';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import messages from '../../utilities/pwi-popup-messages';
import Sidebar from './sidebar';
import save from '../../assets/img/build-career-sketch/icon-save-blue.png';

const PWI_DEFINATION = loader('../../graphql/schema/financial-independence/getPWiDefination.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const CHECK_MODULE_EXISTS = loader('../../graphql/schema/fc/check-module-exists.graphql');

const titleText = [
  "Power of Compound Interest",
  'Types of short-term investments',
  'Short-Term Investments: Setting Timelines',
  'Short-Term Investments: Investment contribution',
  'Historical performance of different investments',
  "Historical performance of different investments (cont'd)",
  'Long-Term Investments: Setting timelines',
  'Long-Term Investments: Investment contribution',
  'Long-Term Investments: Allocation and investment return',
  'Short and long-term investments summary',
  '529 Plan',
  '529 Plan: Investment contribution and return',
  'Life Insurance',
  'Life Insurance: Investment contribution and return',
  'Financial Gift',
  'Charitable Contributions',
  'Charitable Contributions Summary',
  'Investments Summary',
];

const Investments = (props) => {
  const { classes, history, } = props;
  let bodySection = useRef(null);

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = React.useState(0);
  const [titleDefination, setTitleDefination] = useState('');
  const [definationPopup, setDefinationPopup] = useState(false);
  const [popupClose, setPopupClose] = useState(false);
  const [completedSlides, setCompletedSlides] = useState({ 1: true });
  const [clearVacationInfo, setClearVacationInfo] = useState(false);
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: false });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [investmentObject, setInvestmentObject] = useState({});
  const [investmentParams, setInvestmentParams] = useState({});
  const [popupValue, setPopValue] = useState('');
  const [maxBalance, setMaxBalance] = useState(false);
  const [clearModule, setClearModule] = React.useState(false);
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [openWorkSheet, setOpenWorkSheet] = React.useState('');
  const [loadValue, setLoadValue] = React.useState(false);

  const [investmentsCat, setInvestmentsCat] = React.useState('');
  const [totals, setTotals] = React.useState('');


  const invObj ={
    'individual_stocks': {
      allocation: 0,
      expected_return: 0,
      weighted_return: 0,
    },
    'individual_bonds': {
      allocation: 0,
      expected_return: 0,
      weighted_return: 0,
    },
    'etfs': {
      allocation: 0,
      expected_return: 0,
      weighted_return: 0,
    },
    'mutual_funds': {
      allocation: 0,
      expected_return: 0,
      weighted_return: 0,
    },
    'reits': {
      allocation: 0,
      expected_return: 0,
      weighted_return: 0,
    },
    'managed_accounts': {
      allocation: 0,
      expected_return: 0,
      weighted_return: 0,
    },
    'other': {
      allocation: 0,
      expected_return: 0,
      weighted_return: 0,
    }
  };

  const invTotal = {
    allocation: parseFloat(invObj['individual_stocks']['allocation']) + parseFloat(invObj['individual_bonds']['allocation']) +
      parseFloat(invObj['etfs']['allocation']) + parseFloat(invObj['mutual_funds']['allocation']) +
      parseFloat(invObj['reits']['allocation']) + parseFloat(invObj['managed_accounts']['allocation']) + parseFloat(invObj['other']['allocation']),
    weighted_return: parseFloat(invObj['individual_stocks']['weighted_return']) + parseFloat(invObj['individual_bonds']['weighted_return']) +
      parseFloat(invObj['etfs']['weighted_return']) + parseFloat(invObj['mutual_funds']['weighted_return']) +
      parseFloat(invObj['reits']['weighted_return']) + parseFloat(invObj['managed_accounts']['weighted_return']) +
      parseFloat(invObj['other']['weighted_return'])
  }

 useEffect(()=>{   
    setInvestmentsCat(invObj);
    setTotals(invTotal);
 },[]);

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module9' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 1; c <= 33; c += 1) {
            completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setInvestmentObject(data);
        } else if (moduleServices.module1Data.start_year <= 0) {
          const popupdetails = messages('No Fi Module');
          let saveContinueBtn = true;
          setBeforeLeavePopup({ ...{ popupStatus: true, type: 'confirm', no_pwi_fi: '/plan-with-inkwiry/fi', saveContinueBtn, 'classVal': 'clearConfirmDialog' }, ...popupdetails })
        } else {
          setInvestmentObject({});
          setActiveScenarioData({});
        }
        setActiveStep(1);
        setLoading(false);
      });
    },
    onError() {
      setClearModule(false);
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setInvestmentObject({});
        }
        if (moduleServices.module1Data.start_year <= 0) {
          const popupdetails = messages('No Fi Module', 'Investments');
          let saveContinueBtn = true;
          setBeforeLeavePopup({...{popupStatus: true,type:'confirm',url: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        } 
        setActiveStep(1);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });

  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module9',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/investments' : url, saveContinueBtn }, ...popupdetails })
  }

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  }

  useEffect(() => {
    const progressBar = (100 / 16);
    // progressBar = progressBar;
    setProgress(activeStep * progressBar);
  }, [activeStep]);

  const handleSideMenu = (index) => {
    setActiveStep(index);
    setMenu(false);
    setMainMenu(false);
    const completedSlide = { ...completedSlides };
    completedSlide[index] = true;
    setCompletedSlides(completedSlide);
    window.scrollTo(0, 0);
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleMainMenu = () => {
    setMainMenu(!mainMenu);
  };

  const handleDefinationPopup = (def) => {
    if (def === 'maxBalance') {
      setMaxBalance(true);
      setPopupClose(true);
    } else {
      setTitleDefination(def);
      setDefinationPopup(true);
    }
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };

  const handleInvestmentData = (data) => {
    setInvestmentObject(data);
    modulesServices.setModule9Data(data);
  };

  const handleSaveandClear = (data) => {
    setClearVacationInfo(false);
    const investmentType = investmentObject;
    investmentType.type = 'save';
    investmentType.sketchName = 'Folder';
    investmentType.module = 'module9';
    investmentType.moduleNumber = 'module9Data';
    setInvestmentParams(investmentType);
    setPopValue(data);
    setPopupClose(true);
  };

  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleSaveandClear('save');
  }

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 20,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 3 || slide === 4) {
      setMenu(true);
    } else if (slide === 5) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };
  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module9";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module9', 'quick-save', activeScenarioData);
      finalObject['module9Data'] = pwiObject.getfilteredInvestObject(moduleServices.module9Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>
        <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleSideMenu} openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={setMenu} completedSlides={completedSlides} />
        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={headerFI} alt="" aria-hidden="true" onClick={handleMainMenu} />
                <h3>Investments</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" onClick={handleMenu} className={classes.menuIcon}>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                <h3>{titleText[activeStep - 1]}</h3>
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''}>
                <img src={Walk} alt="" data-for="walk" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>
          </div>

          {(activeStep === 1) && (
            <PowerOfSaving getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 2) && (
            <ShortTermInvestmentType handleDefinationPopup={handleDefinationPopup} />
          )}
          {(activeStep === 3) && (
            <SettingTimelines activeStep={activeStep} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 4) && (
            <BeiginingMaxBalances activeStep={activeStep} setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 5) && (
            <MonthlyContribution activeStep={activeStep} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {/* {(activeStep === 6) && (
            <AnnualReturnSummary setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} handleInvestmentData={handleInvestmentData} getInvestmentObject={investmentObject} />
          )} */}
          {(activeStep === 6) && (
            <LongTermInvestmentTypes activeStep={activeStep} handleDefinationPopup={handleDefinationPopup} />
          )}
          {(activeStep === 7) && (
            <LongInvestmentSettingTimeline activeStep={activeStep} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} setOpenWorkSheet={setOpenWorkSheet}/>
          )}
          {(activeStep === 8) && (
            <LongTermBeginingBalance activeStep={activeStep} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} setOpenWorkSheet={setOpenWorkSheet}/>
          )}
          {(activeStep === 9) && (
            <LongTermMonthlyContribution activeStep={activeStep} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} investmentsCat={investmentsCat} setInvestmentsCat={setInvestmentsCat} totals={totals} setTotals={setTotals}/>
          )}
          {(activeStep === 10) && (
            <LongTermAnnualReturn activeStep={activeStep} setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 11) && (
            <PlanSettingTimeline activeStep={activeStep} setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 12) && (
            <PlanInvestments activeStep={activeStep} setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 13) && (
            <LifeSettingTimeline activeStep={activeStep} setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 14) && (
            <LifeInvestments activeStep={activeStep} setOpenWorkSheet={setOpenWorkSheet} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 15) && (
            <FinancialGift activeStep={activeStep} handleDefinationPopup={handleDefinationPopup} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
          )}
          {(activeStep === 16) && (
            <div>
              <CharitableContribution activeStep={activeStep} getInvestmentObject={investmentObject} handleInvestmentData={handleInvestmentData} />
              <CharitableContributionSummary activeStep={activeStep} getInvestmentObject={investmentObject} />
            </div>
          )}

          {(activeStep === 2 || activeStep === 6) && (
            <div className={classes.bottomText}>
              <p><i>Note: The material presented above is purely educational and not meant to be investment advice.</i></p>
              <p><i>*Estimated returns are as of July 2019</i></p>
            </div>
          )}
          <div className={classes.btnGroup}>
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="invest" />)}
            {activeStep === 1 ? '' : <Button className={classes.cancel} onClick={() => handleSideMenu(activeStep - 1)}>Back</Button>}
            <Button className={classes.clearBtn} onClick={clearEntireScenario} aria-hidden="true" data-for="worksheet" data-tip="Clear your scenario and start fresh."><img src={ClearTime} alt="" /></Button>
            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <React.Fragment>
                <ReactTooltip id="walkclear" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                <Button onClick={handleQuickSave} className={classes.quickSave}><span><img src={save} alt="" data-for="walkclear" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page." /></span></Button>
              </React.Fragment>
            )}
            {activeStep === 16 ? <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleSaveandClear('save'); }}>Save</Button> : <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleSideMenu(activeStep + 1); }}>Next</Button>}

          </div>
          {(activeStep === 5) && (
            <div className={classes.bottomText}>
              <p><i>Source: The Measure of a Plan, Yahoo Finance, Vanguard, iShares, Libra Investment Management, and the Bogleheads forum</i></p>
            </div>
          )}
          {definationResult && definationResult.getPWiDefination
            && (
              <DefinationPopup
                definationPopup={definationPopup}
                handleDefinationPopupClose={handleDefinationPopupClose}
                getPWiDefination={definationResult.getPWiDefination}
              />
            )}
          <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        </div>
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose || clearVacationInfo}
            onClose={() => { setPopupClose(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={popupValue === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={clearVacationInfo ? classes.clearModule : ''}>
              {(popupValue === 'save' || maxBalance) && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={() => { setPopupClose(false); }}>
                      ×
                    </span>
                  </div>
                </div>
              )}
              {popupValue === 'save' && (
                <PwiSave setPopupOpen={setPopupClose} moduleParams={investmentParams} getSaveData={investmentObject} setLoadValue={setLoadValue} />
              )}
              {(popupClose && maxBalance) && (
                <div>
                  <div>
                    <h3>MaxBalance</h3>
                    <img src={MaxBalance} alt="" />
                  </div>
                </div>
              )}
            </DialogContent>
          </Dialog>
          <ReactTooltip id="worksheet" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module9' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      {openWorkSheet !== '' && <InvestmentWorksheetTable closeWorkSheet={setOpenWorkSheet} openWorkSheet={openWorkSheet} />}

      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};

Investments.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);
export default enhance(Investments);
