import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
// import IdleState from "./idle-state";

const styles = {
  root: {
    display: 'block',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.white,
    minHeight: '90vh',
    [theme.breakpoints.down( 'xs' )]: {
      overflowX: 'hidden',
    },
  },
};

const LayoutWithOutHeader = ( { classes, component: Component } ) => (
  <MuiThemeProvider theme={ theme }>
    <div className={ classes.root }>
      <main className={ classes.content }>
        {/* <IdleState/> */}
        <Component />
      </main>
    </div>
  </MuiThemeProvider>
);

LayoutWithOutHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
};

export default withStyles( styles )( LayoutWithOutHeader );
