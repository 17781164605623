const data = [
    {
      title: "Higher Education",
      children: [
        {
          label: 'Type of Higher Education',
          type: 'text',
          prefix: '',
          suffix: "",
          key: "type_of_higher_education"
        },
        {
          label: 'Name of Higher Education',
          type: 'text',
          prefix: '',
          suffix: "",
          key: "name_of_college"
        },
        {
            label: '# of Years',
            type: 'date',
            prefix: '',
            suffix: "",
            key: "number_of_years"
          },
          {
            label: 'Annual Tuition & Fees',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "annual_tution_fess"
          },

      ]
    },
    {
      title: "Total Expenses",
      children: [
        {
          label: 'Tuition & Fees',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "tution_fees_higher"
        },
        {
          label: 'Room & Board',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "room_board"
        },
        {
            label: 'On Campus Living Expenses',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "oncampus_living_expenses"
          },
          {
            label: 'Off Campus Living Expenses',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "offcampus_living_expenses"
          },
          {
            label: 'Total Student Loan Payments during College',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "total_student_loan_payments"
          },
          {
            label: 'Other Loan Payments on Other Debt',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "other_loan_payments_on_other_debt"
          },
          {
            label: 'Car Expenses',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "car_expenses_higher"
          },
          {
            label: 'Real Estate Expenses',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "real_estate_expenses_higher"
          },
          {
            label: 'Children and Pet Expenses',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "children_pet_expenses"
          },
          {
            label: 'Total Expenses',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "total_expenses_higher"
          },

      ]
    },

    {
        title: "Investment Analysis",
        children: [
          {
            label: 'Scholarships',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "scholarships_higher"
          },
          {
            label: 'Grants',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "grants_higher"
          },
          {
              label: 'Other Financial Aid',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "other_financial_aid"
            },
            {
              label: 'Income Earned after Taxes',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "income_earned_after_taxes"
            },
            {
              label: 'Family Contribution',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "family_contribution_higher"
            },
            {
              label: 'Federal Subsidized Student Loan',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "federal_subsidized_student_load"
            },
            {
              label: 'Federal Unsubsidized Student Loan',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "federal_unsubsidized_student_load"
            },
            {
              label: 'Private Student Loan',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "private_student_loan_higher"
            },
            {
              label: 'Origination Fees',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "origination_fees"
            },
            {
              label: 'Total Financing Sources',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "total_financing_sources"
            },
            {
              label: 'Total Expenses',
              type: 'number',
              prefix: '$',
              suffix: "",
              key: "total_expenses_higher_second"
            },
            {
                label: 'Cash Leftover',
                type: 'number',
                prefix: '$',
                suffix: "",
                key: "cash_leftover"
              },
  
        ]
      }


  ]; 
  
  export default data;