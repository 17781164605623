import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-investments/styles';

const ShortTermInvestmentType = (props) => {
  const {
    classes, handleDefinationPopup,
  } = props;

  return (
    <div className={classes.contentBlock}>
      <p>
        Short-term investments are, as their name suggests, investments made for the short-term, which can be defined as less than one year. When planning, think of your
        {' '}
        <span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Short-Term Investments')}>short-term investment</span>
        {' '}
        as money set aside for emergencies or near-term purchases. Because you count on that money to be there in case of a financial emergency or a future purchase, you typically want to make investments that are low risk.

      </p>
      <p>Review the side-by-side analysis below to learn about the most common short-term investment options.</p>
      <div className={classes.shortTermTableScroll}> 
        <table className={classes.shortTermTable}>
          <thead>
            <tr>
              <th>#</th>
              <th>Traditional Checking and Savings Accounts</th>
              <th>High-Yield Savings Accounts</th>
              <th>Certificates of Deposit</th>
              <th>Treasury Securities</th>
              <th>Money Market Accounts</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Level of Risk</td>
              <td>Very low; insured by FDIC up to $250k</td>
              <td>Very low; insured by FDIC up to $250k</td>
              <td>Very low; insured by FDIC up to $250k</td>
              <td>Very low; backed by the government</td>
              <td>Very low; insured by FDIC up to $250k</td>
            </tr>
            <tr>
              <td>Level of Reward</td>
              <td>Lowest</td>
              <td>Low, but higher than checking and savings</td>
              <td>Low to medium; higher than checking and savings</td>
              <td>Low to medium; higher than checking and savings</td>
              <td>Low to medium; higher than checking and savings</td>
            </tr>
            <tr>
              <td>Estimated Return* (Annual)</td>
              <td>0.0 - 0.1%</td>
              <td>0.4 - 0.5%</td>
              <td>0.4 - 1.0%</td>
              <td>0.1 - 2.3%</td>
              <td>0.3 - 0.6%</td>
            </tr>
            <tr>
              <td>Fees</td>
              <td>Low; maintain minimum balance and do not overdraft</td>
              <td>Low to none</td>
              <td>None; unless you withdraw your money early (10% fee)</td>
              <td>Low to none; fees to purchase the security</td>
              <td>Low</td>
            </tr>
            <tr>
              <td>Liquidity</td>
              <td>Checking: highly liquid Savings: low; limited to six transactions per month</td>
              <td>Low; limited to six transactions per month</td>
              <td>Not liquid; money is locked up for certain amount of time</td>
              <td>Highly liquid</td>
              <td>Low; limited to six transactions per month</td>
            </tr>
            <tr>
              <td>Taxes on Interest Earned</td>
              <td>Federal, state, and local</td>
              <td>Federal, state, and local</td>
              <td>Federal, state, and local</td>
              <td>Interest earned is federally taxable;<br />not state or local</td>
              <td>Federal, state, and local</td>
            </tr>
          </tbody>
        </table>
        <div>
          {/* <i>
            <p>
              Note: The material presented above is purely informational and not meant to be investment advice.
            </p>
            <p>*Estimated returns are as of April 2021.</p>
          </i> */}
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
    </div>
  );
};

ShortTermInvestmentType.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles(styles)(ShortTermInvestmentType);
