import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CheckingsAccount = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Checking Account</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/checking-account.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWordNew}>
                      Finance Explained
                      <span> | </span>
                      checking account
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/checking-account-left.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        className={classNames(
                          classes.feText,
                          classes.fePadding,
                        )}>
                        <p
                          className={classNames(
                            classes.bigText,
                            classes.noTopMargin,
                          )}>
                          A checking account, also known as a transactional
                          account, is a bank account that provides easy access
                          to your money and is used to write personal checks,
                          withdraw cash from an ATM and pay bills and other
                          day-to-day expenses.
                        </p>
                        <p className={classes.noBottomMargin}>
                          Checking accounts usually provide:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>Personal checks</li>
                          <li>Debit or ATM card</li>
                          <li>
                            Very low interest rates when compared to other bank
                            accounts such as savings accounts, money market
                            accounts and certificates of deposit
                          </li>
                        </ul>
                        <p className={classes.noBottomMargin}>
                          Unlike other bank accounts, checking accounts have
                          very few restrictions on how often you can access your
                          money. You can access your money every day by making
                          debit card purchases and withdrawing cash from an ATM
                          up to your daily limit.
                        </p>
                        <p
                          className={classNames(
                            classes.noBottomMargin,
                            classes.noTopMargin,
                          )}>
                          Properly managing your checking account will allow you
                          to avoid unnecessary fees. Some common fees are:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>
                            <b>Maintenance fees:</b> up to $20 monthly fee that
                            the bank charges for managing your account. This fee
                            can be avoided by maintaining the minimum balance,
                            setting up direct deposit for your paychecks or
                            selecting an account with no maintenance fees.
                          </li>
                          <li>
                            <b>Overdraft fees:</b> when you spend more than you
                            have in your account. Usually around $35 each time
                            you overdraft on your account.
                          </li>
                        </ul>
                        <p>
                          It&apos;s important to always know your checking
                          account balance when making purchases or writing
                          checks so that you don&apos;t spend more than you
                          have. If you spend more than you have, you will be
                          charged overdraft fees.
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CheckingsAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckingsAccount);
