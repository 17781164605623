import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import classNames from 'classnames';

const SecuritySetting = ( props ) => {
  const { classes, updateSaveSettings, myDetails } = props;

  const [checked, setChecked] = useState( !!( ( myDetails && myDetails.me.two_factor_auth === '1' ) ) );
  const [idleLogoutTime, setIdleLogoutTime] = useState( ( myDetails && myDetails.me.Idle_time ) || '' );

  const updateTwoFactorAuth = ( event ) => {
    setChecked( event.target.checked );
    updateSaveSettings( {
      variables: {
        data: {
          setting_type: 'security_setting',
          field_name: 'two_factor_auth',
          field_value: event.target.checked === true ? '1' : '0',
        },
      },
    } );
  };

  const handleIdleLogoutTime = ( event ) => {
    setIdleLogoutTime( event.target.value );
    updateSaveSettings( {
      variables: {
        data: {
          setting_type: 'security_setting',
          field_name: 'Idle_time',
          field_value: event.target.value,
        },
      },
    } );
  }; 

  return (
    <div className={ classes.billingInformation }>
      <div className={ classes.billingHead }>
        <h3>General Security Settings</h3>
      </div>
      <div className={ classes.billingContent }>
        <div>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox
              disableRipple
              checked={ checked }
              onChange={ updateTwoFactorAuth }
            />
            <p>Enable two-factor authentication via email. Every time you log in, you will be required to enter a unique code to authenticate your session.</p>
          </div>
        </div>
      </div>
      <div className={ classes.deviderNew } />
      <div className={classNames(classes.billingHistoryNew, classes.billingHistoryNew2)}>
        <div className={ classes.billingHead }>
          <h3>Idle Logout Time</h3>
          <p className={ classes.hidle }>How long unti Inkwiry logs you out due to inactivity. The default setting is 1 hour. You can adjust this setting below. Please note that this is a security feature meant to protect your privacy.</p>
          <select className={ classes.securitySelect } value={ idleLogoutTime } onChange={ handleIdleLogoutTime }>
            <option value="5">30 minutes</option>
            <option value="60">1 hour</option>
            <option value="120">2 hours</option>
            <option value="180">3 hours</option>
            <option value="720">12 hours</option>
            <option value="1d">1 day</option>
            <option value="never">Never</option>
          </select>
        </div>
      </div>

    </div>
  );
};

SecuritySetting.propTypes = {
  classes: PropTypes.object.isRequired,
  myDetails: PropTypes.object.isRequired,
  updateSaveSettings: PropTypes.func.isRequired,
};

export default withStyles( styles )( SecuritySetting );
