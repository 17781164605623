import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';

const FamilySummery = ( props ) => {
  const { classes } = props;

  return (
    <div className={ classes.contentBlock }>
      <p>Congratulations, you’ve incorporated your spouse, your kids, and your pet into your plan. Make sure the summary table below lines up with your expectations. Having a plan to start a family will bring you peace of mind and confidence in that big life move.</p>
    </div>
  );
};

FamilySummery.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles( styles )( FamilySummery );
