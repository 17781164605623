import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const liability = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Liability</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/finance-explained-liability-banner-image.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWordNew}>
                      Finance Explained
                      <span> | </span>
                      liability
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/finance-liability-image.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        className={classNames(
                          classes.feText,
                          classes.fePadding,
                        )}>
                        <p
                          className={classNames(
                            classes.feText,
                            classes.noTopMargin,
                          )}>
                          A liability is money you owe to another person, a
                          bank, or any other institution. For example, student
                          loans are liabilities. A mortgage is a liability.
                        </p>
                        <p className={classes.noBottomMargin}>
                          Your liabilities can be found on the balance sheet.
                          The most common liabilities are:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>Credit card balance</li>
                          <li>Student loan</li>
                          <li>Car loan</li>
                          <li>Mortgage</li>
                        </ul>
                        <p>
                          The opposite of a liability is an asset, which is
                          anything that you own that has value and can be
                          converted into cash. Assets and liabilities are used
                          to calculate your net worth:
                        </p>
                        <p>
                          Net Worth = Assets (what you own) - Liabilities (what
                          you owe)
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
liability.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(liability);
