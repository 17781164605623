import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { Link } from 'react-router-dom';

const ETF = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Exchange-Traded Fund (ETF)</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/etf.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Exchange-Traded Fund (ETF)
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/etf-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          You want to invest in US technology companies. But
                          you&apos;re not sure which one or which ones? A
                          technology exchange-traded fund (ETF) would allow you
                          to invest in a bunch of different tech companies
                          without actually having to pick individual stocks.
                          Pretty cool right?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          ETFs were created to simplify the process of
                          diversification for the average investor (for a
                          history of ETFs,{' '}
                          <a
                            href="https://www.investopedia.com/articles/exchangetradedfunds/12/brief-history-exchange-traded-funds.asp"
                            target="_blank"
                            rel="noopener noreferrer">
                            click here
                          </a>
                          .) Before ETFs, if you wanted to invest in a bunch of
                          different tech companies, you picked the companies and
                          executed individual trades for each one. If you wanted
                          to invest in 10 companies, you made 10 trades that
                          used to cost anywhere from $5 to $10 per trade. Then,
                          you&apos;d track each one of those investments on an
                          individual basis and, when you went to sell,
                          you&apos;d get hit with fees again. Not ideal for the
                          average investor.
                        </p>
                        <p>
                          Enter ETFs. An easy way to diversify your investments.
                          An ETF is just like a stock in that you can buy and
                          sell shares on a stock exchange on a day-to-day basis
                          every second of the day. The cool thing about an ETF
                          is that it tracks the price of a collection of stocks,
                          can be bought and sold like any other stock, and
                          usually has very low fees compared to other funds (see{' '}
                          <Link
                            to="/finance-explained/funds-alternative-investments"
                            target="_blank">
                            mutual funds
                          </Link>{' '}
                          for comparison, but note that mutual funds are
                          professionally managed.)
                        </p>
                        <p>
                          There are many ETFs that aim to track the performance
                          of the{' '}
                          <Link
                            to="/finance-explained/sandp-500"
                            target="_blank">
                            S&P 500
                          </Link>
                          , which is an index comprised of the 500 largest
                          publicly traded US companies. If you buy one share of
                          an ETF that tracks the S&P 500, you gain exposure to
                          500 different companies from different industries. All
                          you had to do was make one transaction and you can
                          sell at any time you want.
                        </p>
                        <p>
                          That&apos;s the power of ETFs. Low fees, easy to
                          diversify your investments, and you can buy and sell
                          just like any other stock.
                        </p>
                        <p>
                          <i>
                            Disclaimer: It&apos;s important to note that Inkwiry
                            does not provide investment advice. These examples
                            are academic and hypothetical. All investments carry
                            the risk of losing some or all your money and there
                            is no guarantee of earning a return. Consulting a
                            professional or doing your own due diligence is
                            essential before making investment decisions.
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
ETF.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ETF);
