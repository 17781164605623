import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const GracePeriod = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Grace Period (Student Loans)</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/grace-period.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Grace Period (Student Loans)
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay1-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          Understanding when you will have to start repaying
                          your student loans is essential to making sure you
                          don&apos;t miss a payment. If you don&apos;t know when
                          your first monthly payment will be due, you can
                          quickly fall behind on your student loans and hurt
                          your credit score. For federal student loans, you have
                          to start making monthly payments after your grace
                          period ends.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The grace period is the six months after you graduate,
                          leave school, or drop below half-time enrollment
                          status. Yes, you read that right. If you drop out of
                          school or drop classes and take fewer than 6 credits
                          per semester, the clock begins to tick for your
                          federal student loan grace period. Six months after
                          you leave school, whether you graduated or not, you
                          will be required to start making payments.
                        </p>
                        <p>
                          Let&apos;s look at an example timeline of a college
                          student who takes federal student loans and graduates
                          in four years.
                        </p>
                        <div className={classes.pfMargin}>
                          <div className={classes.pfImage}>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/grace-period-timeline-example.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <p>
                          Most federal student loans, including{' '}
                          <Link to="/finance-explained/subsidized-vs-unsubsidized-loans">
                            subsidized and unsubsidized loans
                          </Link>
                          , offer a six-month grace period. However, private
                          student loans vary by lender. Some lenders offer a
                          grace period. Other private student loan lenders do
                          not. Always ask if there is a grace period.
                        </p>
                        <p>
                          Even though you are not required to make payments
                          until after the grace period ends,{' '}
                          <Link
                            to="/finance-explained?type=definitions"
                            target="_blank">
                            interest accrues
                          </Link>{' '}
                          during the grace period for unsubsidized student loans
                          and private student loans.
                        </p>
                        <p>
                          The grace period is great for planning. Six months
                          gives you the time you need to get your financial plan
                          in place. With a plan, you make things happen. Try{' '}
                          <Link
                            to="/plan-with-inkwiry/he?from="
                            target="_blank">
                            Inkwiry&apos;s Higher Education
                          </Link>{' '}
                          module on a tablet, desktop, or laptop today.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
GracePeriod.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GracePeriod);
