import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import Pageloader from '../../components/ui/pageloader';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import Sidebar from './sidebar';
import DefinationPopup from '../../components/ui/pwi-sidebar/defination-popup';
import NotAboutNumbers from '../../components/pwi/additional-career-advancement/not-all-about-members';
import ChoosePath from '../../components/pwi/additional-career-advancement/choose-a-path';
import CareerExploration from '../../components/pwi/additional-career-advancement/career-exploration';
import CareerMove from '../../components/pwi/additional-career-advancement/career-move';
import SettingTimelines from '../../components/pwi/additional-career-advancement/setting-timelines';
import BaseIncome from '../../components/pwi/additional-career-advancement/base-income';
import BonusTips from '../../components/pwi/additional-career-advancement/bonus-tips';
import SupplementaryIncome from '../../components/pwi/additional-career-advancement/supplementary-income';
import IncomeAfterTaxes from '../../components/pwi/additional-career-advancement/income-after-taxes';
import BuildingBudget from '../../components/pwi/additional-career-advancement/building-a-budget';
import RentandUtilities from '../../components/pwi/additional-career-advancement/rent-and-utilities';
import Food from '../../components/pwi/additional-career-advancement/food';
import ClothingPersonalCare from '../../components/pwi/additional-career-advancement/clothing-and-personalcare';
import Entertainment from '../../components/pwi/additional-career-advancement/Entertainment';
import Technology from '../../components/pwi/additional-career-advancement/technology';
import Transportation from '../../components/pwi/additional-career-advancement/transportation';
import Miscellaneous from '../../components/pwi/additional-career-advancement/miscellaneous';
import HealthcareInsurance from '../../components/pwi/additional-career-advancement/healthcare-and-insurance';
import MyBudget from '../../components/pwi/additional-career-advancement/my-budget';
import RetirementPlanning from '../../components/pwi/additional-career-advancement/retirement-planning';

import RetirementAccount from '../../components/pwi/additional-career-advancement/retirement-accounts';
import HSAContributions from '../../components/pwi/additional-career-advancement/hsa-contributions';
import HSASummary from '../../components/pwi/additional-career-advancement/hsa-summary'

import RetirementPlanning401 from '../../components/pwi/additional-career-advancement/retirement-planning-401k';
import RetirementContributions from '../../components/pwi/additional-career-advancement/retirement-planning-contributions';
import RetirementSummary from '../../components/pwi/additional-career-advancement/retirement-summary';
import RothIra from '../../components/pwi/additional-career-advancement/roth-IRA';
import RothIRAContributions from '../../components/pwi/additional-career-advancement/roth-IRA-contributions';
import Pension from '../../components/pwi/additional-career-advancement/pension';
import PensionContributions from '../../components/pwi/additional-career-advancement/pension-contributions';
import MyCareerPath from '../../components/pwi/additional-career-advancement/my-career-path';
import SepIRA from '../../components/pwi/additional-career-advancement/sep-IRA';
import SepContributions from '../../components/pwi/additional-career-advancement/sep-contributions';
import styles from './styles';
import PwiLeftMenu from '../../components/pwi/pwi-left-menu';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import PwiSave from '../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../components/common/save-popup-success';
import LogoImage from '../../assets/img/logo.png';
import Walk from '../../assets/img/pwi-fi/walk.png';
import CareerAdvacement from '../../assets/img/career-path/aca.png';
import ClearTime from '../../assets/img/pwi-he/clear-timeline-blue.png';
import save from '../../assets/img/build-career-sketch/icon-save-blue.png';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as modulesServices from '../../calculations/modules-services';
import WalkThruOne from '../pwi/wallk-thru/walkThruOne';
import WalkThruTwo from '../pwi/wallk-thru/walkThruTwo';
import WalkThruThree from '../pwi/wallk-thru/walkThruThree';
import WalkThruFour from '../pwi/wallk-thru/walkThruFour';
import WalkThruFive from '../pwi/wallk-thru/walkThruFive';
import WalkThruSix from '../pwi/wallk-thru/walkThruSix';
import WalkThruSeven from '../pwi/wallk-thru/walkThruSeven';
import WalkThruEight from '../pwi/wallk-thru/walkThruEight';
import * as timeLineActions from '../../calculations/time-line';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import messages from '../../utilities/pwi-popup-messages';
import SimulatorPanel from '../../components/documents/simulator-panel';
import SummaryDocs from '../../components/documents/documents';
import defaultValues from "../../calculations/default-module-values"

const OCCUPATION_DATA = loader('../../graphql/schema/pwi-career-path/occupation-data.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');
const PWI_DEFINATION = loader('../../graphql/schema/financial-independence/getPWiDefination.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const CHECK_MODULE_EXISTS = loader('../../graphql/schema/fc/check-module-exists.graphql');

const titleText = [
  "It's Not All About the Numbers",
  'Choose a Path',
  'Career Exploration',
  'Career Move',
  'Setting Timelines',
  'Base Income',
  'Bonus / Tips / Commission',
  'Supplementary Income',
  'Income After Taxes',
  'Building a Budget',
  'Living Expenses: Rent and Utilities',
  'Living Expenses: Food',
  'Living Expenses: Clothing and Personal Care',
  'Living Expenses: Entertainment',
  'Living Expenses: Technology',
  'Living Expenses: Transportation',
  'Living Expenses: Miscellaneous',
  'Living Expenses: Healthcare Costs and Health Insurance',
  'My Budget',
  'Retirement Planning',
  'Retirement Accounts',
  'Retirement Planning: 401(k) Contributions',
  'Retirement Planning: 401(k)',
  'Retirement Planning: 401(k) Summary',
  'Retirement Planning: IRA Contributions',
  'Retirement Planning: IRA Accounts',
  'Retirement Planning: SEP IRA Contributions',
  'Retirement Planning: SEP IRA',
  // 'Retirement Planning: SEP IRA Summary',
  'Retirement Planning: Pension Contributions',
  'Retirement Planning: Pension',
  'Retirement Planning: HSA Contributions',
  'Retirement Planning: HSA',
  'My Career Path',
];

const PWIAdditionalCareerAdvancement = (props) => {
  const { classes, history } = props;
  let bodySection = useRef(null);

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [titleDefination, setTitleDefination] = useState('');
  const [definationPopup, setDefinationPopup] = useState(false);
  const [completedSlides, setCompletedSlides] = useState({ 1: true });
  const [careerPathData, setCareerPathData] = useState({...defaultValues.module8});
  const [saveKey, setSaveKey] = useState('');
  const [popupClose, setPopupClose] = useState(false);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: false });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);

  const [role, setRole] = useState('');
  const [sideGraphData, setSideGraphData] = useState({});
  const [occupationData, setOccupationData] = useState([]);
  const [educationOrder, setEducationOrder] = useState({ name: 'occupation', order: 'ASC' });
  const [selectType, setSelectType] = useState('all');
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [retirementConfirmation, setRetirementConfirmation] = useState(false);
  const [rothConfirmation, setRothConfirmation] = useState(false);
  const [pensionConfirmation, setPensionConfirmation] = useState(false);
  const [sepIraConfirmation, setSepIraConfirmation] = useState(false);
  const [selfEmployment, setSelfEmployment] = useState('No');
  const [loadValue, setLoadValue] = React.useState(false);


  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module8' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 1; c <= 32; c += 1) {
            completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setCareerPathData(data);
        } else {
          setCareerPathData({});
          setActiveScenarioData({});
        }
        setActiveStep(1);
        setLoading(false);
      });
    },
    onError() {
      setClearModule(false);
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setCareerPathData({});
        }
        setActiveStep(1);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });


  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module8',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });


  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/additional-career-advancement' : url, saveContinueBtn }, ...popupdetails })
  }

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  // eslint-disable-next-line
  useQuery(OCCUPATION_DATA, {
    variables: {
      occupation_type: selectType,
      order_column: educationOrder.name,
      order_type: educationOrder.order,
    },
    fetchPolicy: 'network-only',
    // skip: educationType === '',
    onCompleted(res) {
      setOccupationData(res && res.getOccupationsData);
    },
  });

  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  }

  useEffect(() => {
    let progressBar = (activeStep) * (100 / 32);
    progressBar = progressBar.toFixed(0);
    setProgress(progressBar);
  }, [activeStep]);

  const handleSlide = (viewNumber, slideType) => {
    const completedSlide = { ...completedSlides };
    switch (viewNumber) {
      case 1: {
        setActiveStep(viewNumber);
        break;
      }
      case 2: {
        setActiveStep(viewNumber);
        break;
      }
      case 3: {
        if (role === 'currentRole' || careerPathData.role === 'currentRole') {
          if (slideType === 'next') {
            handleSlide(viewNumber + 1, 'next');
          } else {
            handleSlide(viewNumber - 1, 'back');
          }
        } else {
          setActiveStep(viewNumber);
        }
        break;
      }
      case 4: {
        setActiveStep(viewNumber);
        break;
      }
      case 5: {
        setActiveStep(viewNumber);
        break;
      }
      case 6: {
        setActiveStep(viewNumber);
        break;
      }
      case 7: {
        setActiveStep(viewNumber);
        break;
      }
      case 8: {

        setActiveStep(viewNumber);
        break;
      }
      case 9: {

        setActiveStep(viewNumber);
        break;
      }
      case 10: {

        setActiveStep(viewNumber);
        break;
      }
      case 11: {
        setActiveStep(viewNumber);

        break;
      }
      case 12: {
        setActiveStep(viewNumber);

        break;
      }
      case 13: {
        setActiveStep(viewNumber);
        break;

      }
      case 14: {
        setActiveStep(viewNumber);

        break;
      }
      case 15: {
        setActiveStep(viewNumber);

        break;
      }
      case 16: {
        setActiveStep(viewNumber);

        break;
      }
      case 17: {
        setActiveStep(viewNumber);

        break;
      }
      case 18: {
        setActiveStep(viewNumber);

        break;
      }
      case 19: {
        setActiveStep(viewNumber);

        break;
      }
      case 20: {
        setActiveStep(viewNumber);
        break;
      }
      case 21: {
        setActiveStep(viewNumber);
        break;
      }
      case 22: {
        if (slideType === 'next') {
          if (selfEmployment === 'Yes' || careerPathData.contribute_401k === 'No') {
            handleSlide(25, 'next');
            return false;
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 23: {
        setActiveStep(viewNumber);
        break;
      }
      case 24: {
        if (slideType === 'back') {
          if (careerPathData.contribute_401k === 'No') {
            handleSlide(21, 'back');
            return false;
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 25: {
        if (slideType === 'next') {
          if (careerPathData.roth_contribute === 'No') {
            handleSlide(27, 'next');
            return false;
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 26: {
        if (slideType === 'back') {
          if (careerPathData.roth_contribute === undefined || careerPathData.roth_contribute === 'No') {
            handleSlide(24, 'back');
            return false;
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 27: {
        if (slideType === 'next') {
          if (careerPathData.self_employment === 'No' && careerPathData.ira_contribute === 'No') {
            handleSlide(29, 'next');
            return false;
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 28: {
        if (slideType === 'back') {
          if (careerPathData.self_employment === 'No') {
            handleSlide(26, 'back')
            return false;
          } else {
            if (careerPathData.ira_contribute === undefined || careerPathData.ira_contribute === 'No') {
              handleSlide(27, 'back')
              return false;
            }
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 29: {
        if (slideType === 'next') {
          if (careerPathData.self_employment === 'Yes' || careerPathData.pension_contribute === "No") {
            handleSlide(31, 'next');
            return false;
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 30: {
        if (slideType === 'next') {
          if (careerPathData.pension_contribute === 'No') {
            handleSlide(31, 'next')
            return false
          }
        }else if(slideType === "back"){
          if (careerPathData.pension_contribute === 'No' || careerPathData.self_employment === 'Yes') {
            handleSlide(28, 'next')
            return false
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      case 31: {
        setActiveStep(viewNumber);
        break;
      }
      case 32: {
        setActiveStep(viewNumber);
        break;
      }
      case 33: {
        if (slideType === 'back') {
          if (careerPathData.self_employment === 'Yes' && careerPathData.ira_contribute === 'Yes') {
            handleSlide(28, 'back');
            return false;
          } else if (careerPathData.self_employment === 'Yes' && careerPathData.ira_contribute === 'No') {
            handleSlide(24, 'back');
            return false;
          }else if (careerPathData.pension_contribute === undefined || careerPathData.pension_contribute === 'No') {
            handleSlide(26, 'back')
            return false
          }
        }
        setActiveStep(viewNumber);
        break;
      }
      default:
        break;
    }

    completedSlide[viewNumber] = true;
    setCompletedSlides(completedSlide);
    window.scrollTo(0, 0);
    setMenu(false);
  };

  const handleSideMenu = (index) => {
    setActiveStep(index);
    setMenu(false);
  };

  const handleDoubleClickRole = (type) => {
    setRole(type);
    if (type === 'currentRole') {
      setActiveStep(4);
    } else {
      setActiveStep(3);
    }
    const careerData = careerPathData;
    careerData.role = type;
    setCareerPathData(careerData);
  };

  // Get Expenses Graph
  const getExpensesGraph = () => {
    const incomeSheetData = modulesServices.incomeStatementCompleteData();
    const totalMonthlyLivingExpense = -(modulesServices.careerPathLivingExpenses());
    const sideGraphObj = {};
    sideGraphObj.incomeAfterTaxesValue = 0;
    sideGraphObj.totalMonthlyLivingExpense = totalMonthlyLivingExpense;
    sideGraphObj.percentageValue = 0;
    sideGraphObj.percentageValue1 = 0;
    let percentageValue = 0;
    let percentageValue1 = 100;
    if (incomeSheetData !== undefined && incomeSheetData !== '' && incomeSheetData[careerPathData.start_year] !== undefined && incomeSheetData[careerPathData.start_year] !== '' && incomeSheetData[careerPathData.start_year]['Income After Taxes'] !== undefined) {
      sideGraphObj.incomeAfterTaxesValue = incomeSheetData[careerPathData.start_year]['Income After Taxes'];
    }
    if (sideGraphObj.incomeAfterTaxesValue === 0) {
      percentageValue1 = 0;
      percentageValue = 100;
      sideGraphObj.percentageValue = percentageValue;
      sideGraphObj.percentageValue1 = percentageValue1;
    } else {
      percentageValue = (totalMonthlyLivingExpense / sideGraphObj.incomeAfterTaxesValue) * 100;
      sideGraphObj.percentageValue = percentageValue;
      sideGraphObj.percentageValue1 = percentageValue1;
      if (sideGraphObj.percentageValue >= 100) {
        percentageValue1 = (percentageValue1 / percentageValue) * 100;
        percentageValue = 100;
      }
    }
    sideGraphObj['activeStep'] = activeStep;
    setSideGraphData(sideGraphObj);
  };

  const handleUpdatedObject = (data) => {
    getExpensesGraph();
    modulesServices.setModule8Data(data);
    setCareerPathData(data);
  };

  useEffect(() => {
    getExpensesGraph();
  }, [careerPathData]);

  const handleCareerSave = (data) => {
    const objectParams = {};
    if (data === 'save') {
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = 'module8';
      objectParams.moduleNumber = 'module8Data';
      setmoduleObjectParams(objectParams);
    }
    setSaveKey(data);
    setPopupClose(true);
  };

  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleCareerSave('save');
  }

  const handlePopUpClose = () => {
    setPopupClose(false);
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleMainMenu = () => {
    setMainMenu(!mainMenu);
  };

  const handleDefinationPopup = (def) => {
    setTitleDefination(def);
    setDefinationPopup(true);
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };


  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 0,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 3 || slide === 4) {
      setMenu(true);
    } else if (slide === 5) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };
  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoading(false);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module8";
      finalObject['relation_year'] = modulesServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module8', 'quick-save', activeScenarioData);
      finalObject['module8Data'] = pwiObject.getfilteredCareerPath(modulesServices.module8Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>
        <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleSideMenu} openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={setMenu} completedSlides={completedSlides} selfEmployment={selfEmployment} />
        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={CareerAdvacement} alt="" aria-hidden="true" onClick={handleMainMenu} />
                <h3>Additional Career Advancement</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" onClick={handleMenu} className={classes.menuIcon}>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                <h3>{titleText[activeStep - 1]}</h3>
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''}>
                <img src={Walk} alt="" data-for="walk" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface." aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>
            {(activeStep === 1) && (
              <NotAboutNumbers />
            )}
            {(activeStep === 2) && (
              <ChoosePath
                handleCareerObject={careerPathData}
                handleDoubleClickRole={handleDoubleClickRole}
                handleUpdatedObject={handleUpdatedObject}
                setRole={setRole}
                role={role}
                moduleName="Career Path"
              />
            )}
            {(activeStep === 3) && (
              <CareerExploration />
            )}
            {(activeStep === 4) && (
              <CareerMove
                handleDefinationPopup={handleDefinationPopup}
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                setNextButtonDisabled={setNextButtonDisabled}
                setSelfEmployment={setSelfEmployment}
                selfEmployment={selfEmployment}
              />
            )}
            {(activeStep === 5) && (
              <SettingTimelines
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 6) && (
              <BaseIncome
                handleCareerObject={careerPathData}
                occupationData={occupationData}
                handleUpdatedObject={handleUpdatedObject}
                setEducationOrder={setEducationOrder}
                setSelectType={setSelectType}
              />
            )}
            {(activeStep === 7) && (
              <BonusTips
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 8) && (
              <SupplementaryIncome
                handleCareerObject={careerPathData}
                occupationData={occupationData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
                setEducationOrder={setEducationOrder}
                setSelectType={setSelectType}
              />
            )}
            {(activeStep === 9) && (
              <IncomeAfterTaxes
                handleCareerObject={careerPathData}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 10) && (
              <BuildingBudget handleCareerObject={careerPathData} handleUpdatedObject={handleUpdatedObject} />
            )}
            {(activeStep === 11) && (
              <RentandUtilities
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 12) && (
              <Food
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 13) && (
              <ClothingPersonalCare
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 14) && (
              <Entertainment
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 15) && (
              <Technology
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 16) && (
              <Transportation
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 17) && (
              <Miscellaneous
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 18) && (
              <HealthcareInsurance
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
                sideGraphData={sideGraphData}
              />
            )}
            {(activeStep === 19) && (
              <MyBudget handleCareerObject={careerPathData} />
            )}
            {(activeStep === 20) && (
              <RetirementPlanning />
            )}
            {(activeStep === 21) && (
              <RetirementAccount
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
              />
            )}
            {(activeStep === 22) && (
              <RetirementPlanning401
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                retirementConfirmation={retirementConfirmation}
                setRetirementConfirmation={setRetirementConfirmation}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 23) && (
              <RetirementContributions
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 24) && (
              <RetirementSummary 
              handleCareerObject={careerPathData}
              handleDefinationPopup={handleDefinationPopup} />
            )}
            {(activeStep === 25) && (
              <RothIra
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
                rothConfirmation={rothConfirmation}
                setRothConfirmation={setRothConfirmation}
              />
            )}
            {(activeStep === 26) && (
              <RothIRAContributions
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 27) && (
              <SepIRA
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                sepIraConfirmation={sepIraConfirmation}
                setSepIraConfirmation={setSepIraConfirmation}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 28) && (
              <SepContributions
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 29) && (
              <Pension
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
                pensionConfirmation={pensionConfirmation}
                setPensionConfirmation={setPensionConfirmation}
              />
            )}
            {(activeStep === 30) && (
              <PensionContributions
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 31) && (
              <HSAContributions
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 32) && (
              <HSASummary
                handleCareerObject={careerPathData}
                handleUpdatedObject={handleUpdatedObject}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}
            {(activeStep === 33) && (
              <MyCareerPath
                handleCareerObject={careerPathData}
              />
            )}
            {definationResult && definationResult.getPWiDefination
              && (
                <DefinationPopup
                  definationPopup={definationPopup}
                  handleDefinationPopupClose={handleDefinationPopupClose} 
                  getPWiDefination={definationResult.getPWiDefination}
                />
              )}
          </div>
          <div>
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="cp" />)}
          </div>
          <div className={classes.btnGroup}>
            {activeStep === 1 ? '' : <Button className={classes.cancel} onClick={() => { handleSlide(activeStep - 1, 'back'); }}>Back</Button>}
            <Button className={classes.clearBtn} onClick={clearScenario} aria-hidden="true" data-for="worksheet" data-tip="Clear your scenario and start fresh."><img src={ClearTime} alt="" /></Button>
            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <Button onClick={handleQuickSave} className={classes.quickSave}><span><img src={save} alt="" data-for="walk" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page." /></span></Button>
            )}
            {activeStep === 33 ? <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleCareerSave('save'); }}>Save</Button> : <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleSlide(activeStep + 1, 'next'); }} disabled={(activeStep === 4 && nextButtonDisabled) || (activeStep === 2 && !role)}>Next</Button>}
          </div>
          {(activeStep === 1) && (
            <div className={classes.bottomText}>
              <p className={classes.awarenessPlusText}><i> awareness + exploration + discovery + due diligence + strategies + financial modeling = solutions</i></p>
            </div>
          )}
          {(activeStep === 4) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Want to learn how your taxes are calculated? Download
                  {' '}
                  {' '}
                  {' '}
                  <a target="_blank" rel="noopener noreferrer" href="/external/images/news_pdfs/Inkwiry FC and Taxes_Sep 2019.pdf" download>Inkwiry&apos;s Financial Configurator and Taxes whitepaper.</a>
                </i>
              </p>
              <i>
                <p>
                  Additional Resource:
                  {' '}
                  {' '}
                  <a target="_blank" rel="noopener noreferrer" href="https://taxfoundation.org/local-income-taxes-2019">Local income taxes</a>
                  {' '}
                  by state in 2019
                </p>
              </i>
            </div>
          )}
          {(activeStep === 6 || activeStep === 8) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Source: U.S. Bureau of Labor Statistics (BLS)
                </i>
              </p>
              <p>
                <i>
                  Notes: Latest data published by BLS is as of May 2018
                </i>
              </p>
            </div>
          )}
          {(activeStep === 7) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Use
                  {' '}
                  <a href="https://www.payscale.com/research/US/Country=United_States/Salary" target="_blank" rel="noopener noreferrer">PayScale</a>
                  {' '}
                  to search careers and discover what bonus, tips and commission you might expect
                </i>
              </p>
            </div>
          )}
          {(activeStep === 21) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Source: Federal Reserve
                </i>
              </p>
            </div>
          )}
        </div>
        <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={saveKey === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={saveKey === 'clear' ? classes.clearModule : ''}>
              {saveKey === 'save' && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={handlePopUpClose}>
                      ×
                    </span>
                  </div>
                  <PwiSave setPopupOpen={setPopupClose} moduleParams={moduleObjectParams} getSaveData={careerPathData} setLoadValue={setLoadValue}
                  />
                </div>
              )}
            </DialogContent>
          </Dialog>
          <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module8' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
    
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
          <SavePopupSucess setLoadValue={setLoadValue}/>
           </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};


PWIAdditionalCareerAdvancement.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(PWIAdditionalCareerAdvancement);
