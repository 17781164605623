import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
// import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';

const VehicleSummary = ( props ) => {
  const { classes} = props;
  const [total, setTotal] = React.useState(0);
  const [vehicleExpenses, setVehicleExpenses] = React.useState(0)
  React.useEffect(() => {
    setTotal(modulesServices.module18Data.totalVehicleValues - modulesServices.module18Data.totalVehicleLoanValues);
    setVehicleExpenses(
      modulesServices.module18Data.totalVehicleLoanPayments 
      + modulesServices.module18Data.totalVehicleLeaseValues 
      + modulesServices.module18Data.totalVehicleMaintenanceValues
      + modulesServices.module18Data.totalVehicleInsuranceValues
      + modulesServices.module18Data.totalVehicleGasValues
    )
  }, [])
  return (
    <div className={ classes.contentBlock }>
      <div className={ `${classes.tableBox} ${classes.tableBoxPWINew} ${classes.mb0}` }>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Net Vehicle Value</th>
          </thead>
          <tbody>
            <tr>
              <td>Total Vehicle Values</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator value={ modulesServices.module18Data.totalVehicleValues }/>
                </div>
              </td>
            </tr>
            <tr>
              <td >Total Loan Values</td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator value={ modulesServices.module18Data.totalVehicleLoanValues}/>
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Net Vehicle Value</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    <NumberFormat value={ total } displayType="text" decimalScale={0} fixedDecimalScale thousandSeparator prefix="$" />
                  </span>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={ `${classes.tableBox} ${classes.tableBoxPWINew} ${classes.mbt30}` }>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Vehicle Expenses</th>
            <th colSpan={ 2 }>Monthly</th>
            <th colSpan={ 2 }>Annual</th>
          </thead>
          <tbody>
            <tr>
              <td colSpan={ 2 }>Loan Payments</td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleLoanPayments !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleLoanPayments / 12 } prefix={ modulesServices.module18Data.totalVehicleLoanPayments >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleLoanPayments < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleLoanPayments !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleLoanPayments } prefix={ modulesServices.module18Data.totalVehicleLoanPayments >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleLoanPayments < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={ 2 }>Lease Payments</td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleLeaseValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleLeaseValues } prefix={ modulesServices.module18Data.totalVehicleLeaseValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleLeaseValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleLeaseValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleLeaseValues / 12 } prefix={ modulesServices.module18Data.totalVehicleLeaseValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleLeaseValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={ 2 }>Maintenance</td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleMaintenanceValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleMaintenanceValues / 12 } prefix={ modulesServices.module18Data.totalVehicleMaintenanceValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleMaintenanceValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleMaintenanceValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleMaintenanceValues } prefix={ modulesServices.module18Data.totalVehicleMaintenanceValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleMaintenanceValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={ 2 }>Insurance</td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleInsuranceValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleInsuranceValues / 12 } prefix={ modulesServices.module18Data.totalVehicleInsuranceValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleInsuranceValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleInsuranceValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleInsuranceValues } prefix={ modulesServices.module18Data.totalVehicleInsuranceValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleInsuranceValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={ 2 }>Gas / Electricity</td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleGasValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleGasValues /12 } prefix={ modulesServices.module18Data.totalVehicleGasValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleGasValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollar }>
                  {
                    modulesServices.module18Data.totalVehicleGasValues !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.totalVehicleGasValues } prefix={ modulesServices.module18Data.totalVehicleGasValues >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.totalVehicleGasValues < 0 && ')' } />
                    ) : ( '$0' )
                  }
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Expenses</td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollarRight }>
                  <span>
                    {
                      vehicleExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ vehicleExpenses / 12 } prefix={ vehicleExpenses >= 0 ? '$' : '($' } suffix={ vehicleExpenses < 0 && ')' } />
                      ) : ( '$0' )
                    }
                  </span>
                </div>
              </td>
              <td colSpan={ 2 }>
                <div className={ classes.amountDollarRight }>
                  <span>
                    {
                      vehicleExpenses !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ vehicleExpenses } prefix={ vehicleExpenses >= 0 ? '$' : '($' } suffix={ vehicleExpenses < 0 && ')' } />
                      ) : ( '$0' )
                    }
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

VehicleSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( VehicleSummary );
