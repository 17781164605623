const styles = ( ) => ( {
  scrollTop: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '50px',
    right: '20px',
    zIndex: '9',
    '& i': {
      background: '#000',
      color: '#fff',
      width: '30px',
      height: '30px !important',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '16px',
      lineHeight: '26px',
      opacity: '0.5',
      cursor: 'pointer',
      '&:hover': {
        opacity: '1',
      },
    },
  },
} );

export default styles;
