import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import {
  TextField,
  Typography,
} from '@material-ui/core';

import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import clearIcon from '../../../assets/img/pwi-home/clear-timeline.png';
import logoIcon from '../../../assets/img/financial-calculators/buy_home.png';
import styles from './styles';
import * as commonFunctions from '../../../utilities/commonFunctions';

const RealEstateCMA = ( props ) => {
  const { classes } = props;

  const [cmaFullData, setCmaFullData] = React.useState( [] );

  const cmaCalcalculations = ( data ) => {
    const singleCmaData = data;
    if ( singleCmaData.cma_purchase_price > 0 && singleCmaData.cma_total_feet > 0 ) {
      singleCmaData.cma_per_feet = singleCmaData.cma_purchase_price / singleCmaData.cma_total_feet;
    } else {
      singleCmaData.cma_per_feet = 0;
    }

    singleCmaData.cma_monthly_p_i = commonFunctions.PMT( ( 4 / 100 ) / 12, 360, -( ( singleCmaData.cma_purchase_price / 100 ) * 80 ), 0, 0 );
    singleCmaData.cma_monthly_homeowner_insurance = ( singleCmaData.cma_purchase_price / 1000 ) * ( 3.5 / 12 );

    singleCmaData.cma_total_expenses = singleCmaData.cma_monthly_p_i + singleCmaData.cma_monthly_property_taxes + singleCmaData.cma_monthly_homeowner_insurance + singleCmaData.cma_hoa + singleCmaData.cma_utilities;
    return singleCmaData;
  };

  const initializingCmaData = () => {
    const cmaData = [];
    for ( let i = 0; i < 3; i += 1 ) {
      cmaData[i] = {};
      if ( i === 0 ) {
        cmaData[i].cma_purchase_price = 40000;
        cmaData[i].cma_name_of_home = 'Buckingham Palace';
        cmaData[i].cma_location = 'Buckingham Twp, PA';
        cmaData[i].cma_rating = 'A';
        cmaData[i].cma_system_rating = 'A+';
        cmaData[i].cma_total_feet = 2800;
        cmaData[i].cma_per_feet = '';
        cmaData[i].cma_monthly_p_i = '';
        cmaData[i].cma_monthly_property_taxes = 335;
        cmaData[i].cma_monthly_homeowner_insurance = 0;
        cmaData[i].cma_hoa = 0;
        cmaData[i].cma_utilities = 400;
        cmaData[i].cma_total_expenses = '';
        cmaData[i].cma_home_type = 'Existing';
        cmaData[i].cma_bedrooms = '4.0';
        cmaData[i].cma_bathrooms = '2.0';
        cmaData[i].cma_kitchen_rating = 'B';
        cmaData[i].cma_basement = 'Yes';
        cmaData[i].cma_finished_basement = 'Yes';
        cmaData[i].cma_garage = 'Yes';
        cmaData[i].cma_garage_spots = 1;
        cmaData[i].cma_central_air = 'Yes';
      } else {
        cmaData[i].cma_purchase_price = 0;
        cmaData[i].cma_name_of_home = '';
        cmaData[i].cma_location = '';
        cmaData[i].cma_rating = '';
        cmaData[i].cma_system_rating = '';
        cmaData[i].cma_total_feet = 0;
        cmaData[i].cma_per_feet = '';
        cmaData[i].cma_monthly_p_i = '';
        cmaData[i].cma_monthly_property_taxes = 0;
        cmaData[i].cma_monthly_homeowner_insurance = 0;
        cmaData[i].cma_hoa = 0;
        cmaData[i].cma_utilities = 0;
        cmaData[i].cma_total_expenses = '';
        cmaData[i].cma_home_type = '';
        cmaData[i].cma_bedrooms = '';
        cmaData[i].cma_bathrooms = '';
        cmaData[i].cma_kitchen_rating = '';
        cmaData[i].cma_basement = '';
        cmaData[i].cma_finished_basement = '';
        cmaData[i].cma_garage = '';
        cmaData[i].cma_garage_spots = '';
        cmaData[i].cma_central_air = '';
      }
      cmaData[i] = cmaCalcalculations( cmaData[i] );
    }
    setCmaFullData( cmaData );
  };

  useEffect( () => {
    initializingCmaData();
    // eslint-disable-next-line
  }, [] );

  const updateInputData = ( e, index, field, type ) => {
    const cmaData = [...cmaFullData];
    if ( type === 'number' ) {
      cmaData[index][field] = e.floatValue !== undefined ? e.floatValue : 0;
    } else {
      cmaData[index][field] = e.target.value;
    }
    cmaData[index] = cmaCalcalculations( cmaData[index] );
    setCmaFullData( cmaData );
  };

  const resetInputs = ( index ) => {
    const cmaData = [...cmaFullData];
    cmaData[index].cma_purchase_price = 0;
    cmaData[index].cma_name_of_home = '';
    cmaData[index].cma_location = '';
    cmaData[index].cma_rating = '';
    cmaData[index].cma_system_rating = '';
    cmaData[index].cma_total_feet = 0;
    cmaData[index].cma_per_feet = '';
    cmaData[index].cma_monthly_p_i = '';
    cmaData[index].cma_monthly_property_taxes = 0;
    cmaData[index].cma_monthly_homeowner_insurance = 0;
    cmaData[index].cma_hoa = 0;
    cmaData[index].cma_utilities = 0;
    cmaData[index].cma_total_expenses = '';
    cmaData[index].cma_home_type = '';
    cmaData[index].cma_bedrooms = '';
    cmaData[index].cma_bathrooms = '';
    cmaData[index].cma_kitchen_rating = '';
    cmaData[index].cma_basement = '';
    cmaData[index].cma_finished_basement = '';
    cmaData[index].cma_garage = '';
    cmaData[index].cma_garage_spots = '';
    cmaData[index].cma_central_air = '';
    cmaData[index] = cmaCalcalculations( cmaData[index] );
    setCmaFullData( cmaData );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Real Estate CMA</li>
            </ul>
            <div className={ classes.csBox }>
              <div className={ classes.blueBorder }>
                <div className={ classes.csBreadcrumbs }>
                  <div>
                    <h3 className={ classes.csTitle }>Comparative Market Analysis (CMA)</h3>
                  </div>
                  <div className={ classes.csLogo }>
                    <h3>
                      <span>
                        <a href="/">
                          {' '}
                          <img src={ logoIcon } alt="" />
                        </a>
                      </span>
                      &nbsp;&nbsp;
                      <a href="/">Real Estate</a>
                    </h3>
                  </div>
                </div>
                <div className={ classes.mainContainer }>
                  <div className={ classes.compareSchool }>
                    <h3>When buying real estate, a comparative market analysis (CMA) is a great tool that helps you compare properties of interest. By looking at these details side-by-side, you can determine if real estate could be overpriced, reasonably valued, or a great buy.</h3>
                    <h3>Use the interactive table below and compare similar properties that you like. Enter your details and compare those properties to see which looks the most attractive.</h3>
                    <div className={ classes.cmaAnalysis }>
                      <ReactTooltip id="tooltip" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
                      <table className={ classes.tableStriped }>
                        <tbody>
                          <tr>
                            <td>&nbsp;</td>
                            <td className={ classes.fontBig }>Name of Home</td>
                            {
                              [0, 1, 2].map( ( data ) => (
                                <td>
                                  <span>
                                    <input type="text" className="" value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_name_of_home : '' } placeholder="Name your home!" onChange={ ( e ) => updateInputData( e, data, 'cma_name_of_home', 'input' ) } />
                                  </span>
                                </td>
                              ) )
                            }

                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>Location (City, ST)</td>
                            {
                              [0, 1, 2].map( ( data ) => (
                                <td>
                                  <span>
                                    <input type="text" className="" value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_location : '' } placeholder={ data === 0 ? 'Langhorne, PA' : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_location', 'input' ) } />
                                  </span>
                                </td>
                              ) )
                            }
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="When rating the location, think about: the condition of and the people in the neighborhood, safety and security of the area, access to major highways and transportation hubs, distance to nearest cities, and distance to nearest shopping and fun." />
                              </div>
                            </td>
                            <td>Location Rating</td>
                            {
                              [0, 1, 2].map( ( data ) => (
                                <td>
                                  <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_rating : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_rating', 'input' ) }>
                                    <option value="" />
                                    <option value="A+">A+</option>
                                    <option value="A">A</option>
                                    <option value="B">B</option>
                                    <option value="C">C</option>
                                    <option value="D">D</option>
                                  </select>
                                </td>
                              ) )
                            }

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="TUse niche.com and check the rating of the public school system for the home's location." />
                              </div>
                            </td>
                            <td>Public School System Rating</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_system_rating : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_system_rating', 'input' ) }>
                                      <option value="" />
                                      <option value="A+">A+</option>
                                      <option value="A">A</option>
                                      <option value="B">B</option>
                                      <option value="C">C</option>
                                      <option value="D">D</option>
                                    </select>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>Purchase Price</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <span className={ classNames( classes.dollarSym, classes.dollarBlock ) }>$</span>
                                    <span className={ classNames( classes.inputDiv, classes.inputField ) }>
                                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_purchase_price : 0 } onFocus={ ( e ) => e.target.select() } onValueChange={ ( e ) => updateInputData( e, data, 'cma_purchase_price', 'number' ) } />
                                    </span>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>Total Square Feet</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <span className={ classes.inputDiv }>
                                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_total_feet : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_total_feet', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                                    </span>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr className={ classes.blueRow }>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use this metric as a value comparison. The higher the price per square foot, the more expensive the home. When using price per square foot to compare homes, try and justify the differences based on the characteristics of the home." />
                              </div>
                            </td>
                            <td>Price / Square Foot</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScal value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_per_feet !== '' ) ? cmaFullData[data].cma_per_feet : 0 } displayType="text" thousandSeparator prefix="$" />
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr className={ classes.spanRow }>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Mortgage payment calculated with the following assumptions: 30-yr term, 4.00% interest rate, and 80% of purchase price as down payment." />
                              </div>
                            </td>
                            <td>Est. Monthly Mortgage Payment (P&I)</td>
                            {
                              [0, 1, 2].map( ( data ) => (
                                <td>
                                  <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_monthly_p_i !== '' ) ? cmaFullData[data].cma_monthly_p_i : 0 } displayType="text" thousandSeparator prefix="$" />
                                </td>
                              ) )
                            }
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use zillow.com to find the estimated property taxes on the home." />
                              </div>
                            </td>
                            <td>Monthly Property Taxes</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <span className={ classNames( classes.dollarSym, classes.dollarBlock ) }>$</span>
                                    <span className={ classNames( classes.inputDiv, classes.inputField ) }>
                                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_monthly_property_taxes : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_monthly_property_taxes', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                                    </span>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr className={ classes.spanRow }>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Monthly homeowner's insurance calculated with the following assumptions: $3.50 for every $1,000 of purchase price divided by 12." />
                              </div>
                            </td>
                            <td>Est. Monthly Homeowner&apos;s Insurance</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_monthly_homeowner_insurance !== '' ) ? cmaFullData[data].cma_monthly_homeowner_insurance : 0 } displayType="text" thousandSeparator prefix="$" />
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use zillow.com to find the estimated association fees on the home." />
                              </div>
                            </td>
                            <td>Monthly Association Fees (HOA)</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <span className={ classNames( classes.dollarSym, classes.dollarBlock ) }>$</span>
                                    <span className={ classNames( classes.inputDiv, classes.inputField ) }>
                                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_hoa : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_hoa', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                                    </span>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Use zillow.com to find the estimated utilities on the home." />
                              </div>
                            </td>
                            <td>Monthly Utilities</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <span className={ classNames( classes.dollarSym, classes.dollarBlock ) }>$</span>
                                    <span className={ classNames( classes.inputDiv, classes.inputField ) }>
                                      <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_utilities : 0 } onValueChange={ ( e ) => updateInputData( e, data, 'cma_utilities', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                                    </span>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr className={ classes.blueRow }>
                            <td>
                              &nbsp;
                            </td>
                            <td>Est. Total Monthly Expenses</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ ( cmaFullData[data] !== undefined && cmaFullData[data].cma_total_expenses !== '' ) ? cmaFullData[data].cma_total_expenses : 0 } displayType="text" thousandSeparator prefix="$" />
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Are you buying or building a brand new home? Or, buying an existing home? Existing homes typically require more maintenance than new homes and should be factored into the buying equation." />
                              </div>
                            </td>
                            <td>New or Existing Home</td>
                            {
                              [0, 1, 2].map( ( data ) => (
                                <td>
                                  <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_home_type : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_home_type', 'input' ) }>
                                    <option value="" />
                                    <option value="New">New</option>
                                    <option value="Existing">Existing</option>
                                  </select>
                                </td>
                              ) )
                            }

                          </tr>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td># of Bedrooms</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_bedrooms : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_bedrooms', 'input' ) }>
                                      <option value="" />
                                      <option value="1.0">1</option>
                                      <option value="2.0">2</option>
                                      <option value="3.0">3</option>
                                      <option value="4.0">4</option>
                                      <option value="5.0+">5+</option>
                                    </select>
                                  </td>
                                ) )
                              }

                          </tr>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td># of Bathrooms</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_bathrooms : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_bathrooms', 'input' ) }>
                                      <option value="" />
                                      <option value="1.0">1.0</option>
                                      <option value="1.5">1.5</option>
                                      <option value="2.0">2.0</option>
                                      <option value="2.5">2.5</option>
                                      <option value="3.0">3.0</option>
                                      <option value="3.5">3.5</option>
                                      <option value="4.0">4.0</option>
                                      <option value="4.5">4.5</option>
                                      <option value="5.0+">5.0+</option>
                                    </select>
                                  </td>
                                ) )
                              }

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Is the kitchen old or new? Good cabinet space? Is there an island countertop? Walk-in pantry? Old or new appliances? Large or multiple sinks? The kitchen will be the busiest place in the home and is an important factor in home buying." />
                              </div>
                            </td>
                            <td>Kitchen Rating</td>
                            {
                                  [0, 1, 2].map( ( data ) => (
                                    <td>
                                      <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_kitchen_rating : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_kitchen_rating', 'input' ) }>
                                        <option value="" />
                                        <option value="A+">A+</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="C">C</option>
                                        <option value="D">D</option>
                                      </select>
                                    </td>
                                  ) )
                                }

                          </tr>
                          <tr>
                            <td>
                              &nbsp;
                            </td>
                            <td>Basement (Y/N)</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_basement : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_basement', 'input' ) }>
                                      <option value="" />
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </td>
                                ) )
                              }

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Is there livable space in the basement?" />
                              </div>
                            </td>
                            <td>Finished Basement (Y/N)</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_finished_basement : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_finished_basement', 'input' ) }>
                                      <option value="" />
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="A garage is an enclosed space for housing vehicles. This does not include a covered area that is not fully enclosed." />
                              </div>
                            </td>
                            <td>Garage (Y/N)</td>
                            {
                              [0, 1, 2].map( ( data ) => (
                                <td>
                                  <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_garage : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_garage', 'input' ) }>
                                    <option value="" />
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </select>
                                </td>
                              ) )
                            }
                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="The average net price for families who earn more than $110,000 per year. Net price is equal to the total cost of attendance (tuition and fees, room and board, books and supplies, and any other living expenses) for the entire year minus scholarships and grants." />
                              </div>
                            </td>
                            <td># of Garage Spots</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_garage_spots : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_garage_spots', 'input' ) }>
                                      <option value="" />
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4+">4+</option>
                                    </select>
                                  </td>
                                ) )
                              }

                          </tr>
                          <tr>
                            <td>
                              <div className={ classes.infoIcon }>
                                <img src={ infoIcon } alt="" data-for="tooltip" data-tip="Centrail air cools and heats the entire house versus having air conditioning units or heating units in individual rooms." />
                              </div>
                            </td>
                            <td>Central Air (Y/N)</td>
                            {
                                [0, 1, 2].map( ( data ) => (
                                  <td>
                                    <select value={ cmaFullData[data] !== undefined ? cmaFullData[data].cma_central_air : '' } onChange={ ( e ) => updateInputData( e, data, 'cma_central_air', 'input' ) }>
                                      <option value="" />
                                      <option value="Yes" selected>Yes</option>
                                      <option value="No">No</option>
                                    </select>
                                  </td>
                                ) )
                              }
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            {[0, 1, 2].map( ( n ) => (
                              <td className={ classes.clearIcon } aria-hidden="true" onClick={ () => resetInputs( n ) }>
                                <span className={ classes.resetIcon } data-for="definationTitle" data-tip="Clear Inputs" role="button" aria-hidden="true">
                                  <img src={ clearIcon } alt="" />
                                </span>
                                <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
                              </td>
                            ) )}

                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className={ classes.bottomText }>
                      <p>Quick Links: Search for homes on Zillow, your area on ADT&apos;s crime map, and your school on Niche.</p>
                      <p className={ classes.noMargin }>
                        Zillow:
                        <a href="https://www.zillow.com/" target="_blank" rel="noopener noreferrer">https://www.zillow.com/</a>
                      </p>
                      <p className={ classes.noMargin }>
                        ADT&apos;s crime map:
                        <a href="https://www.adt.com/crime" target="_blank" rel="noopener noreferrer">https://www.adt.com/crime</a>
                      </p>
                      <p>
                        Niche:
                        <a href="https://www.niche.com/" target="_blank" rel="noopener noreferrer"> https://www.niche.com/</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

RealEstateCMA.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( RealEstateCMA );
