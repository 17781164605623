import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import styles from '../../../screens/pwi/further-higher-education/styles';

const ChooseYourSchoolManual = ( props ) => {
  const {
    classes, listOfStates, allInputData,  updateData, handleDefinationPopup,
  } = props;

  const [inputData, setInputData] = React.useState({});

  const stateList = _.sortBy( listOfStates, 'state_name' );

  useEffect(()=>{
    
    let inputValues = {};
    inputValues['school_details'] = {};
    if(allInputData.school_details.school_name === undefined && allInputData.school_details.school_name !== '') {
      if(allInputData.name_of_college !== undefined) {
        inputValues.school_details.school_name = allInputData.name_of_college;  
      } else {
        inputValues.school_details.school_name = '';
      }
    } else {
      inputValues.school_details.school_name = allInputData.school_details.school_name;
    }
    if(allInputData.state_id !== undefined) {
      inputValues.school_details.state_id = allInputData.state_id; 
      const stateIndex = _.findIndex(stateList,{'id':allInputData.state_id});
      if(stateIndex >= 0) {
        inputValues.school_details.state_name = stateList[stateIndex]['state_name'];  
      } 
    } else {
      inputValues.school_details.state_id = '';
      inputValues.school_details.state_name = '';

    }

    if(allInputData.school_details.city_name === undefined) {
      inputValues.school_details.city_name = '';
    } else {
      inputValues.school_details.city_name = allInputData.school_details.city_name;
    }
    if(allInputData.tuition !== undefined) {
      inputValues.tuition = allInputData.tuition;  
    } else {
      inputValues.tuition = 0;
    }
    if(allInputData.room_board !== undefined) {
      inputValues.room_board = allInputData.room_board;  
    } else {
      inputValues.room_board = 0;
    }
    setInputData(inputValues);
  },[])


  const total = parseInt( inputData.tuition !== undefined ? inputData.tuition : 0, 10 ) + parseInt( inputData.room_board !== undefined ? inputData.room_board : 0, 10 );

  const handleTuitionFees = ( values ) => {
    let inputVal = {...inputData};
    inputVal.tuition = values.floatValue !== undefined ? values.floatValue : 0; 
    setInputData(inputVal);
    let outInputVale = {...allInputData};
    outInputVale.tuition = inputVal.tuition;
    updateData('total_obj',outInputVale);
  };

  const handleRoomBoard = ( values ) => {
    let inputVal = {...inputData};
    inputVal.room_board = values.floatValue !== undefined ? values.floatValue : 0; 
    setInputData(inputVal);
    let outInputVale = {...allInputData};
    outInputVale.room_board = inputVal.room_board;
    updateData('total_obj',outInputVale);
  };

  const handleSchoolName = ( e ) => {
    let inputVal = {...inputData};
    inputVal.school_details.school_name = e.target.value;
    setInputData(inputVal);
    let outInputVale = {...allInputData};
    outInputVale.school_details.school_name = e.target.value;
    outInputVale.name_of_college = e.target.value;
    updateData('total_obj',outInputVale);
  };
  const handleCityName = ( e ) => {
    let inputVal = {...inputData};
    inputVal.school_details.city_name = e.target.value;
    let outInputVale = {...allInputData};
    if(outInputVale.school_details.state_id !== undefined && outInputVale.school_details.state_id !== '' && inputVal.school_details.city_name !== '') {
      const stateIndex = _.findIndex(stateList,{'id':outInputVale.school_details.state_id});
      if(stateIndex >= 0 ) {
        inputVal.school_details.location = inputVal.school_details.city_name+', '+  stateList[stateIndex]['state_code'];
        outInputVale.school_details.location = inputVal.school_details.city_name+', '+  stateList[stateIndex]['state_code']; 
      }
    }
    setInputData(inputVal);
    outInputVale.school_details.city_name = e.target.value;
    updateData('total_obj',outInputVale);
  };

  const handleState = ( e ) => {
    let inputVal = {...inputData};
    const stateIndex = _.findIndex(stateList,{'id':e.target.value});
    let outInputVale = {...allInputData};
    if(stateIndex >= 0) {
      outInputVale.school_details.state_name = stateList[stateIndex]['state_name'];  
      inputVal.school_details.state_name = stateList[stateIndex]['state_name'];  
      if(inputVal.school_details.city_name !== undefined && inputVal.school_details.city_name !== '') {
        inputVal.school_details.location = inputVal.school_details.city_name+', '+  stateList[stateIndex]['state_code'];
        outInputVale.school_details.location = inputVal.school_details.city_name+', '+  stateList[stateIndex]['state_code'];
      }
    }  else {
      inputVal.school_details.location = ''; 
      outInputVale.school_details.location = ''; 
      outInputVale.school_details.state_name = '';
      inputVal.school_details.state_name = '';
    }
    outInputVale.school_details.state_id = e.target.value;
    outInputVale.state_id = parseInt(e.target.value,10);
    updateData('total_obj',outInputVale);

    inputVal.school_details.state_id = e.target.value;
    setInputData(inputVal);
  };

  return (
    <div className={ classes.contentBlock }>
      <div className={ classes.chooseYourSchoolBox }>
        <div className={ classes.topselectSchoolManuval }>Enter the name of your school and details below.</div>
        <div className={ `${classes.TextFieldGrooup} ${classes.TextFieldGrooupSpan}` }>
          <span>Name of School:</span>
          {' '}
          <TextField id="standard-basic" placeholder="ex. Inkwiry University" value={ (inputData.school_details !== undefined && inputData.school_details.school_name !== undefined) ? inputData.school_details.school_name : '' } onChange={ handleSchoolName } />
        </div>
        <div className={ `${classes.TextFieldGrooup} ${classes.TextFieldGrooupSpan}` }>
          <span>City:</span>
          {' '}
          <TextField id="standard-basic" placeholder="ex. Newtown" value={ (inputData.school_details !== undefined && inputData.school_details.city_name !== undefined) ? inputData.school_details.city_name : '' } onChange={ handleCityName } />
        </div>
        <div className={ `${classes.TextFieldGrooupSelect} ${classes.TextFieldGrooupSpanSelect} ${classes.TextFieldSchool}` }>
          <span>State:</span>
          {' '}
          <select value={ (inputData.school_details !== undefined && inputData.school_details.state_id !== undefined) ? inputData.school_details.state_id : '' } onChange={ handleState }>
            <option value="0">Please select</option>
            {
              stateList.map( ( list ) => <option value={ list.id }>{list.state_name}</option> )
            }
          </select>

        </div>
      </div>
      <div className={ classes.tableBox }>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>
              {(allInputData.type_of_higher_education === 2 || allInputData.type_of_higher_education === 4) ? 'First Year Tuition & Fees + Room & Board' : 'First Year Tuition & Fees' }
            </th>
          </thead>
          <tbody>
            <tr>
              <td><span className={ classes.definationTittle } aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Tuition and Fees' ) }>Tuition & Fees</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat allowNegative={ false } decimalScale={ 0 } fixedDecimalScale customInput={ TextField } thousandSeparator value={ inputData.tuition !== undefined ? inputData.tuition : 0 } onValueChange={ handleTuitionFees } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            {(allInputData.type_of_higher_education === 2 || allInputData.type_of_higher_education === 4) && (
              <tr>
                
                <td><ReactTooltip id="definationTitle1" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } /><span className={ classes.definationTittle } aria-hidden="true" data-for="definationTitle1" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Room and Board' ) }>Room & Board</span></td>
                <td>
                  <div className={ classes.amountDollar }>
                    <span>$</span>
                    <NumberFormat allowNegative={ false } fixedDecimalScale decimalScale={ 0 }  customInput={ TextField } thousandSeparator value={ inputData.room_board !== undefined ? inputData.room_board : 0 } onValueChange={ handleRoomBoard } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
            )}
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>
              {(allInputData.type_of_higher_education === 2 || allInputData.type_of_higher_education === 4) ? 'First Year Tuition & Fees + Room & Board' : 'First Year Tuition & Fees' }
              </td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ total } displayType="text" thousandSeparator prefix="$" />
                  </span>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />

    </div>
  );
};

ChooseYourSchoolManual.propTypes = {
  classes: PropTypes.object.isRequired,
  listOfStates: PropTypes.arrayOf( PropTypes.object ).isRequired,
  allInputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( ChooseYourSchoolManual );
