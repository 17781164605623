import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-investments/styles';
import InvestmentBottomThree from './Investment-bottomThree';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';

const marks = [
    {
        value: 0,
        scaledValue: 0,
        label: '0%',
    },
    {
        value: 20,
        scaledValue: 20,
        label: '20%',
    },
    {
        value: 40,
        scaledValue: 40,
        label: '40%',
    },
    {
        value: 60,
        scaledValue: 60,
        label: '60%',
    },
    {
        value: 80,
        scaledValue: 80,
        label: '80%',
    },
    {
        value: 100,
        scaledValue: 100,
        label: '100%',
    },
];

const marksTwo = [
    {
        value: 0,
        scaledValue: 0,
        label: '0%',
    },
    {
        value: 2,
        scaledValue: 2,
        label: '2%',
    },
    {
        value: 4,
        scaledValue: 4,
        label: '4%',
    },
    {
        value: 6,
        scaledValue: 6,
        label: '6%',
    },
    {
        value: 8,
        scaledValue: 8,
        label: '8%',
    },
    {
        value: 10,
        scaledValue: 10,
        label: '10%',
    },
];

const marksThree = [
    {
        value: 0,
        scaledValue: 0,
        label: '0',
    },
    {
        value: 500,
        scaledValue: 500,
        label: '500',
    },
    {
        value: 1000,
        scaledValue: 1000,
        label: '1K',
    },
    {
        value: 1500,
        scaledValue: 1500,
        label: '1.5k',
    },
    {
        value: 2000,
        scaledValue: 2000,
        label: '2k',
    },
    {
        value: 2500,
        scaledValue: 2500,
        label: '2.5k',
    },
];

const LifeInvestments = (props) => {
    const {
        classes, handleDefinationPopup, getInvestmentObject, handleInvestmentData, setOpenWorkSheet
    } = props;
    const [begBalPercentage, setBegBalPercentage] = React.useState(getInvestmentObject.monthly_premium_percentage !== undefined ? getInvestmentObject.monthly_premium_percentage : 0);
    const [monthlyPremium, setMonthlyPremium] = React.useState(getInvestmentObject.monthly_premium !== undefined ? getInvestmentObject.monthly_premium : 0);
    const [begBalValue, setBegBalValue] = React.useState(0);
    const [sliderValue, setSliderValue] = React.useState(getInvestmentObject.annual_expected_pre_tax_return_life_ins !== undefined ? getInvestmentObject.annual_expected_pre_tax_return_life_ins : 7);
    const [isFocus, setIsFocus] = React.useState('');

    React.useEffect(() => {
        let bignningBalance = getInvestmentObject.monthly_premium_percentage;
        var preamount = modulesServices.afterTaxIncomeInYearInsurenceBalance();
        let value = (preamount / 100) * bignningBalance;
        let sValue = 0;
        if (value >= 0) {
            sValue = value;
        } else {
            sValue = 0;
        }
        setBegBalValue(sValue/12);
    }, []);

    const updateValue = (e, value, field, type) => {
        const beginingBalanceObj = { ...getInvestmentObject };
        var preamount = modulesServices.afterTaxIncomeInYearInsurenceBalance();
        if (type === 'sliderTwo') {
            setMonthlyPremium(value);
            beginingBalanceObj['monthly_premium'] = value;
        } else if (type === 'input') {
            let Invalue = e.floatValue ? e.floatValue : 0;
            setMonthlyPremium(Invalue);
            beginingBalanceObj['monthly_premium'] = Invalue;
        } else if (type === 'slider') {
            let bignningBalance = begBalPercentage;
            if (bignningBalance > 0 && preamount > 0) {
                setBegBalValue(((preamount / 100) * bignningBalance)/12);
            }
            setBegBalPercentage(value);
            beginingBalanceObj['monthly_premium_percentage'] = value;
        } else if (type === 'inputTwo') {
            let Invalue = e.floatValue ? e.floatValue : 0;
            let bignningBalance = Invalue*12;
            if (bignningBalance >= 0 && preamount >= 0) {
                setBegBalPercentage((bignningBalance / preamount) * 100);
                beginingBalanceObj['monthly_premium_percentage'] = (bignningBalance / preamount) * 100;
            }
            setBegBalValue(Invalue);
        } else if (type === 'sliderThree') {
            setSliderValue(value);
            beginingBalanceObj['annual_expected_pre_tax_return_life_ins'] = value;
        } else if (type === 'inputThree') {
            let Invalue = e.floatValue ? e.floatValue : 0;
            let ivalue = 0;
            if (Invalue >= 100) {
                ivalue = 100;
            } else {
                ivalue = Invalue
            }
            setSliderValue(ivalue);
            beginingBalanceObj['annual_expected_pre_tax_return_life_ins'] = ivalue;
        }
        handleInvestmentData(beginingBalanceObj);
    };

    const emptyIsFocus = () => {
        setIsFocus('');
    };

    return (
        <div className={classes.contentBlock}>
            <p>
                There are two types of life insurance – term and whole life. Term life, as it sounds, provides insurance for a specific period of time such as 20 or 30 years. Term life insurance does not have a cash value component.
            </p>
            <p>Whole life insurance does have a cash value. A portion of your monthly payment will build a cash value and earn a small return over time. Life insurance policies typically do not earn a high rate of return as the focus is on low-risk investment strategies that keep your funds safe for the future.

            </p>
            <p>How much are you paying or do you plan to pay in life insurance? If you’re not sure, check out the latest <a href="https://www.nerdwallet.com/article/insurance/average-life-insurance-rates" target="_blank" rel="noreferrer">rates here</a>.</p>
            <div className={classes.balanceBox}>
                <p>As a rule of thumb, your life insurance policy should pay out 10 to 15 times your current annual income.</p>
                <Typography variant="h3" component="h3">
                    After-Tax Income in year {getInvestmentObject.life_insurance_start_year}&nbsp; : {''}
                    <span className={modulesServices.module9Data.afterTaxIncomeInYearInsurenceBalance < 0 ? classes.dangerText : ''}><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={modulesServices.module9Data.afterTaxIncomeInYearInsurenceBalance ? modulesServices.module9Data.afterTaxIncomeInYearInsurenceBalance : 0} prefix="$" /></span>                
                </Typography>
            </div>
            <div className={classes.innnerFlowGroupMainFull}>
                <div className={`${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}`}>
                    <div className={`${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}`}>
                        <div className={`${classes.garphLeft} ${classes.garphLeftScholor}`}>
                            <h3>&nbsp;</h3>
                            <div className={`${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants} ${classes.annuvalGrowthScholorBT0}`}>
                                <div>
                                    <p><span>Monthly Contribution</span></p>
                                    <div className={classes.pwisliderInputs}>
                                        <PwiSlider
                                            ValueLabelComponent={ValueLabelComponent}
                                            aria-labelledby="discrete-slider-custom"
                                            valueLabelDisplay="auto"
                                            marks={marksThree}
                                            min={0}
                                            max={2500}
                                            step={50}
                                            value={monthlyPremium}
                                            onChange={(e, value) => { updateValue(e, value, 'monthly_premium', 'sliderTwo'); }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}`}>
                            <div>
                                <h3>Monthly</h3>
                                <div className={classes.inputRightBlock}>
                                    <div className={classes.annualInput}>
                                        <span className={classes.dollarInput}>$</span>
                                        <NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={monthlyPremium} onValueChange={(e) => updateValue(e, '', 'monthlyPremium', 'input')} />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.rightBoxNogap}>
                                <h3>
                                    Annual
                                </h3>
                                <div className={classes.inputRightBlock}>
                                    <span className={classes.dollarInput}>$</span>
                                    <NumberFormat decimalScale={0} fixedDecimalScale displayType={'text'} customInput={TextField} thousandSeparator value={monthlyPremium * 12} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.innnerFlowGroup}>
                        <div className={`${classes.garphLeft} ${classes.garphLeftScholor}`}>
                            <div className={`${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}`}>
                                <div>
                                    <p><span >% for Cash Builder</span></p>
                                    <div className={classes.pwisliderInputs}>
                                        <PwiSlider
                                            ValueLabelComponent={ValueLabelComponent}
                                            valueLabelFormat={percentFormatWithTwoDecimal}
                                            aria-labelledby="discrete-slider-custom"
                                            valueLabelDisplay="auto"
                                            marks={marks}
                                            min={0}
                                            max={100}
                                            step={0.10}
                                            value={begBalPercentage}
                                            onChange={(e, value) => { updateValue(e, value, 'monthly_premium_percentage', 'slider'); }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={`${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}`}>
                            <div>
                                <div className={classes.inputRightBlock}>
                                    <div className={classes.annualInput}>
                                        <span className={classes.dollarInput}>$</span>
                                        <NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={begBalValue}  onValueChange={(e) => updateValue(e, '', 'monthly_premium_amount', 'inputTwo')}/>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.rightBoxNogap}>
                                <div className={classes.inputRightBlock}>
                                    <span className={classes.dollarInput}>$</span>
                                    <NumberFormat decimalScale={0} fixedDecimalScale displayType={'text'} customInput={TextField} thousandSeparator value={begBalValue * 12} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={classes.innnerFlowGroup}>
                        <div className={`${classes.garphLeft} ${classes.garphLeftScholor}`}>
                            <div className={`${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants}`}>
                                <div>
                                    <p><span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Expected Pre-Tax Return: Investments')}>Annual Expected Pre-Tax Return</span></p>
                                    <div className={classes.pwisliderInputs}>
                                        <PwiSlider
                                            ValueLabelComponent={ValueLabelComponent}
                                            valueLabelFormat={percentFormatWithTwoDecimal}
                                            aria-labelledby="discrete-slider-custom"
                                            valueLabelDisplay="auto"
                                            marks={marksTwo}
                                            min={0}
                                            max={10}
                                            step={0.1}
                                            value={sliderValue}
                                            onChange={(e, value) => { updateValue(e, value, 'annual_pre_tax_return_insurance', 'sliderThree'); }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className={`${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}`}>
                            <div>
                                <div className={classes.inputRightBlock}>
                                    <div className={classes.annualInput}>
                                        <NumberFormat
                                            customInput={TextField}
                                            decimalScale={isFocus!=='annual_pre_tax_return_insurance'?2:5}
                                            fixedDecimalScale={isFocus!=='annual_pre_tax_return_insurance'}
                                            value={sliderValue}
                                            onValueChange={(e) => updateValue(e, '', 'annual_pre_tax_return_insurance', 'inputThree')}
                                            onFocus={(e)=>{e.target.select(); setIsFocus('annual_pre_tax_return_insurance') }}
                                            onBlur={emptyIsFocus}
                                        />
                                        <span className={classes.percentSymbl}>%</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.rightBoxNogap}>
                                <div className={classes.inputRightBlock}>
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={classes.investSlider}>
                <div className={classes.lefBlock}>
                    <div className={classes.lefSlider}>
                      <div className={classes.sliderName}><span>Monthly Contribution</span></div>
                        <div className={classes.rzSlider}>
                            <PwiSlider
                                ValueLabelComponent={ValueLabelComponent}
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="auto"
                                marks={marksThree}
                                min={0}
                                max={2500}
                                step={50}
                                value={monthlyPremium}
                                onChange={(e, value) => { updateValue(e, value, 'monthly_premium', 'sliderTwo'); }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.annualSlider}>
                    <div className={classes.annualInput}>
                        <span className={classes.dollarInput}>$</span>
                        <NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={monthlyPremium !== null ? monthlyPremium : 0 } onValueChange={(e) => updateValue(e, '', 'monthlyPremium', 'input')} />
                    </div>
                </div>
                <div className={classes.annualSlider}>
                    <div className={classes.annualInput}>
                        <span className={classes.dollarInput}>$</span>
                     
                        <NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={monthlyPremium * 12} />
                    </div>
                </div>
            </div>
            <div className={classes.investSlider}>
                <div className={classes.lefBlock}>
                    <div className={classes.lefSlider}>
                        <div className={classes.sliderName}><span>% for Cash Builder</span></div>
                        <div className={classes.rzSlider}>
                            <PwiSlider
                                ValueLabelComponent={ValueLabelComponent}
                                valueLabelFormat={percentFormatWithTwoDecimal}
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="auto"
                                marks={marks}
                                min={0}
                                max={100}
                                step={0.10}
                                value={begBalPercentage}
                                onChange={(e, value) => { updateValue(e, value, 'monthly_premium_percentage', 'slider'); }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.annualSlider}>
                    <div className={classes.annualInput}>
                        <span className={classes.dollarInput}>$</span>
                        <NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={begBalValue} onValueChange={(e) => updateValue(e, '', 'monthly_premium_amount', 'inputTwo')} />
                    </div>
                </div>
                <div className={classes.annualSlider}>
                    <div className={classes.annualInput}>
                        <span className={classes.dollarInput}>$</span>
                  
                        <NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={begBalValue * 12} />
                    </div>
                </div>
            </div>
            <div className={classes.investSlider}>
                <div className={classes.lefBlock}>
                    <div className={classes.lefSlider}>
                        <div className={classes.sliderName}><span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Annual Expected Pre-Tax Return: Investments')}>Annual Expected Pre-Tax Return</span></div>
                        <div className={classes.rzSlider}>
                            <PwiSlider
                                ValueLabelComponent={ValueLabelComponent}
                                valueLabelFormat={percentFormatWithTwoDecimal}
                                aria-labelledby="discrete-slider-custom"
                                valueLabelDisplay="auto"
                                marks={marksTwo}
                                min={0}
                                max={10}
                                step={0.1}
                                value={sliderValue}
                                onChange={(e, value) => { updateValue(e, value, 'annual_pre_tax_return_insurance', 'sliderThree'); }}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.annualSlider}>
                    <div className={classes.annualInput}>
                        <NumberFormat
                            customInput={TextField}
                            decimalScale={2}
                            value={sliderValue !== null ? sliderValue : 0}
                            onValueChange={(e) => updateValue(e, '', 'annual_pre_tax_return_insurance', 'inputThree')}
                        />
                        <span className={classes.percentSymbl}>%</span>
                    </div>
                </div>
            </div> */}

            <div className={classes.borderDivider} />
            {getInvestmentObject &&
                <InvestmentBottomThree activeSlide={12} getInvestmentObject={getInvestmentObject} setOpenWorkSheet={setOpenWorkSheet} />
            }
            <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
        </div>
    );
};

LifeInvestments.propTypes = {
    classes: PropTypes.object.isRequired,
    handleDefinationPopup: PropTypes.func.isRequired,
    handleInvestmentData: PropTypes.func.isRequired,
    getInvestmentObject: PropTypes.object.isRequired,
    setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles(styles)(LifeInvestments);
