import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Picture1 from '../../assets/img/pwi-car/existing_car.png';
import styles from '../../screens/pwi-car/styles';
import BlueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png';

const Dashboard = ( props ) => {
  const {
    classes, setDashboardType, dashboardType, handleDoubleClickType, handleBuyingCarObject, handleUpdatedObject, firstBuyCar,
    firstLeaseCar,
    secondBuyCar,
    secondLeaseCar,
  } = props;

  useEffect( () => {
    const dashboardObj = { ...handleBuyingCarObject };

    if ( handleBuyingCarObject.existingDashType === undefined ) {
      setDashboardType( 'firstCar' );
      dashboardObj.existingDashType = 'firstCar';
      handleUpdatedObject( dashboardObj );
    }

    // eslint-disable-next-line
  }, []);

  const handleClickType = ( type ) => {
    setDashboardType( type );
    handleBuyingCarObject.existingDashType = type;
    handleUpdatedObject( handleBuyingCarObject );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>In the Buying a Car module, you can discover what it takes to purchase a car. You can plan for two car purchases and lease two cars as part of your financial life plan. Plan With Inkwiry will walk you through the process from start to finish so you have all the information you need to plan your next car purchase. And, discover all the details that go into buying and leasing a car. Click on one of the options below to get started!</p>
      <ul className={ classes.retirementList}>   
        <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'firstCar' ) } onClick={ () => handleClickType( 'firstCar' ) } className={ ( dashboardType === 'firstCar' || handleBuyingCarObject.existingDashType === 'firstCar' || firstBuyCar|| (handleBuyingCarObject.purchase_year !== undefined && handleBuyingCarObject.purchase_year > 0) ) ? classes.vehicleCheck : classes.vehicleUnCheck }>
          Buy a Car
          <span>
              {(firstBuyCar || (handleBuyingCarObject.purchase_year !== undefined && handleBuyingCarObject.purchase_year > 0)) && <img src={BlueTick} alt="" />}
          </span>
        </li>
        <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'firstLeaseCar' ) } onClick={ () => handleClickType( 'firstLeaseCar' ) } className={ ( dashboardType === 'firstLeaseCar' || handleBuyingCarObject.existingDashType === 'firstLeaseCar' || firstLeaseCar ||(handleBuyingCarObject.leasing_car_start_year !== undefined && handleBuyingCarObject.leasing_car_start_year > 0) ) ? classes.vehicleCheck : classes.vehicleUnCheck }>
          Lease a Car
          <span>
            {(firstLeaseCar  || (handleBuyingCarObject.leasing_car_start_year !== undefined && handleBuyingCarObject.leasing_car_start_year > 0))  && <img src={BlueTick} alt="" />}
          </span>
        </li>
        <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'SecondCar' ) } onClick={ () => handleClickType( 'SecondCar' ) } className={ ( dashboardType === 'SecondCar' || handleBuyingCarObject.existingDashType === 'SecondCar' || secondBuyCar|| (handleBuyingCarObject.second_purchase_year !== undefined && handleBuyingCarObject.second_purchase_year > 0) ) ? classes.vehicleCheck : classes.vehicleUnCheck }>
          Buy a Second Car
          <span>
            {(secondBuyCar  || (handleBuyingCarObject.second_purchase_year !== undefined && handleBuyingCarObject.second_purchase_year > 0))  && <img src={BlueTick} alt="" />}
          </span>
        </li>
        <li aria-hidden="true" onDoubleClick={ () => handleDoubleClickType( 'SecondLeaseCar' ) } onClick={ () => handleClickType( 'SecondLeaseCar' ) } className={ ( dashboardType === 'SecondLeaseCar' || handleBuyingCarObject.existingDashType === 'SecondLeaseCar' || secondLeaseCar || (handleBuyingCarObject.leasing_second_car_start_year !== undefined && handleBuyingCarObject.leasing_second_car_start_year > 0) ) ? classes.vehicleCheck : classes.vehicleUnCheck }>
          Leasing a Second Car
          <span>
            {(secondLeaseCar  || (handleBuyingCarObject.leasing_second_car_start_year !== undefined && handleBuyingCarObject.leasing_second_car_start_year > 0))  && <img src={BlueTick} alt="" />}
          </span>
        </li>
      </ul>
    </div>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDoubleClickType: PropTypes.object.isRequired,
  setDashboardType: PropTypes.func.isRequired,
  dashboardType: PropTypes.string.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
  firstBuyCar: PropTypes.string.isRequired,
  firstLeaseCar: PropTypes.string.isRequired,
  secondBuyCar: PropTypes.string.isRequired,
  secondLeaseCar: PropTypes.string.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
};

export default withStyles( styles )( Dashboard );
