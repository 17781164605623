import imagesCareerPathDown from '../../../../../assets/img/blu-print/stepping-stones-graphic-cylinder-colors.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& h3': {
        fontSize: '25px !important',
        lineHeight: '28px !important',
      },
      '& h2': {
        fontSize: '23px !important',
        lineHeight: '28px !important',
      },
      '& h4': {
        fontSize: '25px !important',
        lineHeight: '28px !important',
      },
      '& p': {
        fontSize: '17px !important',
        lineHeight: '28px !important',
      },
    },
  },
  containerRow: {
    maxWidth: '1250px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
    '& img': {
      maxWidth: '100%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& h3': {
        fontSize: '25px !important',
        lineHeight: '28px !important',
      },
      '& h2': {
        fontSize: '25px !important',
        lineHeight: '28px !important',
      },
      '& h4': {
        fontSize: '20px !important',
        lineHeight: '28px !important',
      },
      '& p': {
        fontSize: '17px !important',
        lineHeight: '28px !important',
      },
    },
  },
  assestBoxMain: {
    padding: '0 30px 0 15px',
  },
  BluePrintBanner: {
    background: '#014372',
    textAlign: 'center',
    padding: '20px 0',
    '& img': {
      maxWidth: '410px',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '330px',
      },
    },
  },
  financialLife: {
    padding: '50px 0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0px 50px 0px',
    },
    '& h3': {
      fontSize: '35px',
      paddingBottom: '10px',
      color: '#0069aa',
      margin: '20px 0px 10px 0px',
      fontWeight: 'normal',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '25px',
        lineHeight: '28px',
      },
    },
    '& p': {
      textAlign: 'center',
      fontSize: '22.5px',
      paddingTop: '25px',
      fontWeight: '100',
      fontFamily: 'MuseoSans-100',
      paddingRight: '25px',
      margin: '0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
        padding: '0',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      margin: '20px 0',
    },
  },
  leftLife: {
    '& p': {
      // paddingRight: '80px',
      '& span': {
        fontSize: '27px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '17px',
        padding: '15px 0 0',
      },
    },
  },
  financialLifeEvent: {
    '& h3': {
      fontSize: '35px',
      color: '#333',
      background: '#ebebeb',
      padding: '15px 0',
      fontWeight: 'normal',
      textAlign: 'center',
      marginBottom: '10px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',

      },
    },
  },
  financialBlockBox: {
    padding: '15px',
    border: '4px solid #016547',
    borderRadius: '10px',
    position: 'relative',
    marginTop: '30px',
    zIndex: '9',
    cursor: 'pointer',
    '&:hover': {
      background: '#016547',
      '& h4': {
        color: '#fff',
      },
    },
    '& h4': {
      fontSize: '34px',
      margin: '10px 0 0',
      lineHeight: '43px',
      fontWeight: 'normal',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '28px',
      },
    },
    '& img': {
      position: 'absolute',
      top: '-15%',
      maxWidth: '70px',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  financialBlockBoxActive: {
    background: '#016547',
    '& h4': {
      color: '#fff',
    },

  },
  begainBlock: {
    marginTop: '40px',
    paddingRight: '30px',
    '& h2': {
      fontFamily: 'MuseoSans-100',
      fontSize: '40px',
      fontWeight: 'normal',
      textAlign: 'left',
      color: '#333',
      lineHeight: '50px',
      marginBottom: '45px',
      marginTop: '30px',
      paddingTop: '30px',
    },
  },
  begainBlockBottom: {
    paddingRight: '0',
  },
  fancialGraphic: {
    margin: '30px 0px',
    '& img': {
      width: '100%',
      display: 'flex',
    },

  },
  financialBlockBoxRight: {
    '& p': {
      fontSize: '23px',
      paddingLeft: '45px',
      paddingRight: '0',
      lineHeight: '28px',
      margin: '0',
      color: '#333',
      paddingTop: '30px',
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '15px',
      },
    },
  },
  ExitingAssets: {
    paddingTop: '70px',
    background: '#ebebeb',
    color: '#333',
    '& img': {
      width: '100%',
    },
    '& h2': {
      fontSize: '34px',
      lineHeight: '43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      marginBottom: '15px',
    },
    '& p': {
      fontSize: '18px',
    },
  },
  ExitingAssetsRight: {
    '& img': {
      maxWidth: '200px',
      paddingTop: '30px',
      margin: '0 auto',
      display: 'inherit',
      paddingLeft: '25px',
      verticalAlign: 'middle',
    },
  },
  ExitingAssetsLeft: {
    '& img': {
      paddingTop: '40px',
      maxWidth: '85%',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'xs' )]: {
        paddingTop: '0',
        maxWidth: '100%',
      },
    },
  },
  imageMargin: {
    margin: '30px 0px',
  },
  assetsBox: {
    background: '#fff',
    borderColor: '#c7b48d',
    marginBottom: '30px',
    cursor: 'pointer',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#c7b48d',
      '& h4': {
        color: '#fff',
      },
    },
  },
  assetsBoxActive: {
    background: '#c7b48d',
    '& h4': {
      color: '#fff',
    },
  },
  careerPath: {
    paddingBottom: '50px',
    color: '#333',
    '& h3': {
      fontSize: '34.5px',
      margin: '20px 0 0',
      paddingBottom: '30px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
    },
  },
  careerPathLeft: {
    maxWidth: '650px',
    marginTop: '100px',
    '& img': {
      width: '100%',
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#0069aa',
        },
      },
    },
  },
  careerPathRight: {
    '& > div': {
      marginTop: '80px',
    },
  },
  careerPathBg: {
    backgroundImage: `url(${imagesCareerPathDown})`,
    backgroundSize: '65%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom 30px left',
    [theme.breakpoints.down( 'xs' )]: {
      backgroundSize: '100%',
      backgroundPosition: 'bottom 0 left',
      paddingBottom: '50%',
    },
    '@media (min-width: 768px) and (max-width: 1279px)': {
      backgroundSize: '50%',
    }
  },
  HigherEducation: {
    padding: '70px 0 20px',
    background: '#ebebeb',
  },
  HigherEducationLeft: {
    '& img': {
      width: '100%',
    },
  },
  HigherEducationMiddleBox: {
    background: '#fff',
    '& h4': {
      color: '#333',
      marginTop: '0',
    },
    '& img': {
      left: '-17%',
      top: '50%',
      maxWidth: '110px',
    },
    '&:hover': {
      '& h4': {
        color: '#fff',
      },
    },
  },
  HigherEducationMiddle: {
    paddingLeft: '40px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '80px',
    },
    '& > div': {
      margin: '57px 0 50px 15px',
      maxHeight: '120px',
      display: 'inline-block',
      '&:last-child': {
        margin: '35px 0 60px 15px',
      },
    },
  },
  HigherEducationRight: {
    paddingLeft: '100px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0',
    },
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontFamily: 'MuseoSans-100',
      margin: '0px',
      fontWeight: '300',
      color: '#333',
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#8ba659',
        },
      },
    },
  },
  investments: {
    padding: '60px 0 0',
  },
  investTop: {
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '55px',
      marginTop: '28px',
      color: '#333',
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '20px',
      },
    },
  },
  investBottom: {
    '& img': {
      marginTop: '-20%',
      display: 'flex',
      marginLeft: '45px',
      [theme.breakpoints.down( 'xs' )]: {
        marginLeft: '0px',
      },
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#c7a458',
        },
      },
    },
  },
  familyGroup: {
    backgroundColor: '#ebebeb',
    paddingTop: '75px',
    paddingBottom: '20px',
  },
  familyGroupTop: {
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '20px',
      paddingRight: '20px',
      color: '#000',
      marginTop: '0',
      margin: '0',
    },
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    },
    '& > div': {
      width: '34%',
      marginLeft: '50px',
      [theme.breakpoints.down( 'xs' )]: {
        width: 'auto',
        '& h3': {
          marginTop: '60px',
        },
      },
    },
  },
  investBottomRight: {
    maxWidth: '465px',
    margin: '50px 0 0 auto',
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#c7a458',
        },
      },
    },
  },
  VocationGroup: {
    padding: '20px 0 0',
    '& img': {
      display: 'flex',
    },
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '0',
      marginTop: '28px',
      margin: '0',
      color: '#333',
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#bf4821',
        },
      },
    },
  },
  familyGroupLeft: {
    '& > div': {
      paddingRight: '50px',
      [theme.breakpoints.down( 'sm' )]: {
        paddingRight: '0',
      },
    },
  },
  BuyingCar: {
    padding: '60px 0',
    backgroundColor: '#ebebeb',
    '& h3': {
      fontSize: '34.5px',
      lineHeight: ' 43px',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '0',
      marginTop: '28px',
      margin: '0',
      color: '#333',
    },
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#f59331',
        },
      },
    },
  },
  BuyingHome: {
    padding: '60px 0 10px',
    backgroundColor: '#fff',
    '& h2': {
      fontSize: '32.5px',
      lineHeight: ' 40px',
      fontWeight: '200',
      fontFamily: 'MuseoSans-100',
      paddingLeft: '0',
      marginTop: '28px',
      textAlign: 'center',
      margin: '0',
      color: '#333',
    },
    '& ul': {
      padding: '0 0 0 30px',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#ed6e36',
        },
      },
    },
  },
  BuyingHomeNew: {
    '& ul': {
      padding: '0 0 0 60px',
    },
  },
  BuyingHomeFirst: {
    paddingRight: '30px',
  },
  buyingInvset: {
    paddingBottom: '0',
    '& ul': {
      padding: '25px 0 0 0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#004372',
        },
      },
    },
  },
  buyingInvsetRight: {
    position: 'relative',
    '@media screen and (min-width: 600px) and (max-width: 1279px)': {
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
    },
    '& h3': {
      position: 'absolute',
      left: '30%',
      textAlign: 'center',
      maxWidth: '165px',
      color: '#333',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        left: '17%',
        bottom: '120px',
      },
      '@media screen and (min-width: 768px) and (max-width: 1279px)': {
        left: '24%',
        bottom: '130px',
      }
    },
    '& img': {
      maxWidth: '115%',
      marginLeft: '-30px',
      marginTop: '-75px',
      display: 'flex',

    },
  },
  assetsBoxOne: {
    borderColor: '#0069aa',
    '&:hover': {
      background: '#0069aa',
    },
  },
  careerBoxActive: {
    background: '#0069aa',
    '& h4': {
      color: '#fff',
    },
  },
  assetsBoxTwo: {
    borderColor: '#1e96e0',
    '&:hover': {
      background: '#1e96e0',
    },
  },
  careerAdvBoxActive: {
    background: '#1e96e0',
    '& h4': {
      color: '#fff',
    },
  },
  assetsBoxThree: {
    borderColor: '#79c3da',
    '&:hover': {
      background: '#79c3da',
    },
  },
  addCareerAdvBoxActive: {
    background: '#79c3da',
    '& h4': {
      color: '#fff',
    },
  },
  assetsBoxFour: {
    borderColor: '#8790b8',
    '&:hover': {
      background: '#8790b8',
    },
  },
  furtherCareerAdvBoxActive: {
    background: '#8790b8',
    '& h4': {
      color: '#fff',
    },
  },
  HigherEducationOne: {
    borderColor: '#84a84d',
    '&:hover': {
      background: '#84a84d',
      color: '#fff',
    },
  },
  HigherEducationOneActive: {
    background: '#84a84d',
    '& h4': {
      color: '#fff',
    },
  },
  HigherEducationTwo: {
    borderColor: '#489b71',
    '&:hover': {
      background: '#489b71',
      color: '#fff',
    },
  },
  HigherEducationTwoActive: {
    background: '#489b71',
    '& h4': {
      color: '#fff',
    },
  },
  HigherEducationThree: {
    borderColor: '#8fac81',
    '&:hover': {
      background: '#8fac81',
      color: '#fff',
    },
  },
  HigherEducationThreeActive: {
    background: '#8fac81',
    '& h4': {
      color: '#fff',
    },
  },
  investBoxNew: {
    borderColor: '#cda349',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#cda349',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  investBoxNewActive: {
    background: '#cda349',
    '& h4': {
      color: '#fff',
    },
  },
  familyBox: {
    borderColor: '#0299aa',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#0299aa',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  familyBoxActive: {
    background: '#0299aa',
    '& h4': {
      color: '#fff',
    },
  },
  vocationTwo: {
    borderColor: '#bf4821',
    background: '#fff',
    marginTop: '55px',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#bf4821',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  VocationTwoActive: {
    background: '#bf4821',
    '& h4': {
      color: '#fff',
    },
  },
  BuyingCarNew: {
    borderColor: '#f59331',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#f59331',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  BuyingCarNewActive: {
    background: '#f59331',
    '& h4': {
      color: '#fff',
    },
  },
  buyingCarIconBox: {
    maxWidth: '350px',
    margin: '0 auto',
  },
  BuyingHomeIcon: {
    borderColor: '#ed6e36',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#ed6e36',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  BuyingHomeIconActive: {
    background: '#ed6e36',
    '& h4': {
      color: '#fff',
    },
  },
  buyingInvsetIcon: {
    borderColor: '#004372',
    background: '#fff',
    '& h4': {
      color: '#333',
    },
    '&:hover': {
      background: '#004372',
      color: '#fff',
      '& h4': {
        color: '#fff',
      },
    },
  },
  buyingInvsetIconActive: {
    background: '#004372',
    '& h4': {
      color: '#fff',
    },
  },
  bluePrintClose: {
    padding: '15px 0',
    border: '2px solid #2b69a5',
    background: '#e1e1e1',
    '& > div': {
      '& > div': {
        alignItems: 'center',
      },
    },
    '& h4': {
      margin: '0',
      fontSize: '18px',
      fontWeight: '500',
      color: '#333',
    },
    '& p': {
      margin: '0',
      fontSize: '18px',
      color: '#333',
    },
    '& img': {
      width: '80px',
      float: 'right',
    },
    '& a': {
      fontSize: '23px',
      color: '#fff',
      border: '1px solid #eb9e42',
      backgroundColor: '#eb9e42',
      padding: '16px 28px',
      borderRadius: '10px',
      lineHeight: '30px',
      fontFamily: 'MuseoSans-300',
      float: 'right',
      '&:hover': {
        border: '1px solid #eb9e42',
        backgroundColor: '#eb9e42',
      },
    },
  },
  dragClickInner: {
    background: '#fff',
    minWidth: '1170px',
    maxWidth: '1170px',
    padding: '15px 30px 30px 30px',
    borderRadius: '10px',
    boxShadow: '0 0 5px rgba(0,0,0,.5)',
    position: 'relative',
    WebkitAnimation: 'fadeIn',
    WebkitAnimationDuration: '1s',
    animation: 'fadeIn',
    animationDuration: '1s',
    transition: 'ease-in-out .3s',
    opacity: '1',
    '& h3': {
      textAlign: 'center',
      margin: '0 0 10px',
      fontSize: '20px',
      color: '#0069aa',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      padding: '0 30px 25px',
      borderBottom: '1px solid #ccc',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '15px',
        height: '15px',
        background: '#ccc',
        left: '0',
        bottom: '-8px',
        borderRadius: '50%',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '15px',
        height: '15px',
        background: '#ccc',
        right: '0',
        bottom: '-8px',
        borderRadius: '50%',
      },
      '& li': {
        listStyle: 'none',
        position: 'relative',
        marginLeft: '-15px',
        '& img': {
          width: '80px',
          display: 'flex',
        },
        '&::before': {
          content: "''",
          width: '1px',
          height: '26px',
          bottom: '-26px',
          right: '0',
          background: '#ccc',
          left: '0',
          margin: 'auto',
          position: 'absolute',
        },
      },
    },
  },
  familyList: {
    '& ul': {
      padding: '0',
      '& li': {
        fontSize: '18px',
        paddingBottom: '8px',
        listStyle: 'none',
        position: 'relative',
        paddingLeft: '30px',
        color: '#333',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '0',
          width: '15px',
          marginTop: '4px',
          height: '15px',
          background: '#0299aa',
        },
      },
    },
  },
  taskComplete: {
    position: 'absolute',
    top: '0',
    right: '0',
    '& img': {
      width: '48px',
      height: '48px',
    },
  },
  lifeContainer: {
    paddingTop: '50px',
  },
} );

export default styles;
