import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const Diversification = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Diversification</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/diversification.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      What&apos;s Diversification?
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/diversification-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Diversification is a powerful investing strategy with
                          the primary goal of reducing risk and not maximizing
                          returns. Making an investment always carries the risk
                          that you might lose some or all your investment. How
                          does diversification reduce that risk? Let&apos;s look
                          at a hypothetical example.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          John invests 50% of his money in Worldwide
                          Prestige&apos;s stock and 50% in another stock. In 12
                          months, Worldwide Prestige&apos;s factory unexpectedly
                          burns down and the company goes bankrupt. John loses
                          50% of his money!
                        </p>
                        <p>
                          Jane diversifies her investments. She invests only 2%
                          of her money in Worldwide Prestige&apos;s stock while
                          the rest of her money is spread out across other
                          stocks, bonds, commodities, real estate and more. In
                          12 months, Worldwide Prestige goes bankrupt and Jane
                          loses 2% of her money. However, during those 12
                          months, Jane&apos;s other investments earned a
                          combined 7% return.
                        </p>
                        <p>
                          See the difference? When you spread out your
                          investments over multiple assets, you reduce the risk
                          of large decreases in your{' '}
                          <Link
                            to="/finance-explained/investment-portfolio"
                            target="_blank">
                            investment portfolio
                          </Link>
                          . In a diversified investment portfolio, just like
                          Jane&apos;s, risk is reduced because it&apos;s very
                          rare that a single economic event would cause all your
                          assets to fall in value at the same time. Some assets
                          will go up and some assets will go down.
                        </p>
                        <p>
                          Minimize risk by diversifying your{' '}
                          <Link
                            to="/finance-explained/investment-portfolio"
                            target="_blank">
                            investment portfolio
                          </Link>{' '}
                          with many different assets. In other words, don&apos;t
                          put all your eggs in one basket.
                        </p>
                        <p>
                          <i>
                            Disclaimer: It&apos;s important to note that Inkwiry
                            does not provide investment advice. These examples
                            are purely academic and hypothetical. All
                            investments carry the risk of losing some or all
                            your money and there is no guarantee of earning a
                            return. Always consult a professional before making
                            investment decisions.
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
Diversification.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Diversification);
