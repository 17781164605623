function database( module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module10Data, module18Data, assumptionsData, fedSubHigherEdLoanData, fedUnSubHigherEdLoanData, fedSubMoreHigherEdLoanData, fedUnSubMoreHigherEdLoanData, module15Data, module17Data, fedSubNewHigherEdLoanData, fedUnSubNewHigherEdLoanData, incomeStatementData, taxStatementData ) {
  const data = {};
  let year = 0;
  let yearLimit = 0;
  let childrenInfo = {};
  const studentLoanLimits = {};
  let personalExemptionsData = {};
  const deductionLimit = {};
  const passiveLossMaxLimit = {};
  const medicareTaxData = {};
  let americanOpportunityData = {};
  let lifetimeLearningData = {};
  let standardDeductions = {};
  let aotcTaxCreditPhaseOut = {};
  let lifetimeLearningCredit = {};

  // For Retirement Account Limits
  let kContributionsLimits = {};
  let rothIraContributionsLimits = {};
  let sepIraContributionsLimits = {};
  let pensionContributionsLimits = {};
  let rothIraIncomeLimits = {};
  let hsaIncomeLimits = {};

  let socialSecurityIncomeTaxLimit = {};
  let childTaxCredit = {};
  // let currentYear = new Date().getFullYear();
  const currentYear = 2018;
  let val = 0;
  let annualLoansTakenFedSub = 0;
  let higherEduDependentStatus = 2;
  let l = 0;
  let sumVal = 0;
  let annualLoansTakenFedUnsub = 0;
  studentLoanLimits['Existing Student Loan'] = {};
  studentLoanLimits['Existing Student Loan']['Fed Sub'] = {};
  studentLoanLimits['Existing Student Loan']['Fed Unsub'] = {};
  studentLoanLimits['Higher Education'] = {};
  studentLoanLimits['Higher Education']['Fed Sub'] = {};
  studentLoanLimits['Higher Education']['Fed Unsub'] = {};
  studentLoanLimits['More Higher Education'] = {};
  studentLoanLimits['More Higher Education']['Fed Sub'] = {};
  studentLoanLimits['More Higher Education']['Fed Unsub'] = {};
  studentLoanLimits['New Higher Education'] = {};
  studentLoanLimits['New Higher Education']['Fed Sub'] = {};
  studentLoanLimits['New Higher Education']['Fed Unsub'] = {};
  // Higher education
  studentLoanLimits['Higher Ed'] = {};
  studentLoanLimits['More Higher Ed'] = {};
  studentLoanLimits['Further Higher Ed'] = {};

  // Career
  studentLoanLimits['Career Path'] = {};
  studentLoanLimits['Career Adv'] = {};
  studentLoanLimits['Additional Career Adv'] = {};
  studentLoanLimits['Further Career Adv'] = {};

  data['Social Security Tax Limit'] = 128400;
  data.Inflation = 2;
  data['HSA Account Return']          = 5;
  data['Social Security Tax Rate']    = 6.20;
data['Tuition Tax Credit']          = 4000;
data['Gross Income Limit Single']   = 80000;
data['Gross Income Limit Married']  = 160000;
data['Roth IRA Limit']              = 5500;
data['Roth IRA Limit Rate']         = 7;
data['401k Limit']                  = 18500;
data['401k Limit Second']           = 55000;
data['401k Limit Rate']             = 7;

  // 401(k) Vesting Schedule
  data['401(k) Vesting Schedule'] = {};
  data['401(k) Vesting Schedule'][1] = 25;
  data['401(k) Vesting Schedule'][2] = 50;
  data['401(k) Vesting Schedule'][3] = 75;
  data['401(k) Vesting Schedule'][4] = 100;
  data['401(k) Vesting Schedule'][5] = 100;
  data['401(k) Vesting Schedule'][6] = 100;
  data['401(k) Vesting Schedule'][7] = 100;
  data['401(k) Vesting Schedule'][8] = 100;
  data['401(k) Vesting Schedule'][9] = 100;
  data['401(k) Vesting Schedule'][10] = 100;
  data['401(k) Vesting Schedule'][11] = 100;
  data['401(k) Vesting Schedule'][12] = 100;
  data['401(k) Vesting Schedule'][13] = 100;
  data['401(k) Vesting Schedule'][14] = 100;
  data['401(k) Vesting Schedule'][15] = 100;

  // Pension 2018 Limits
  data['Pension 2018 Limits'] = 220000;
  data['Pension 2018 Limits Rate'] = 7;

  // PMI Deduction
  data['PMI Deduction'] = 50000;
  data['PMI Deduction Second'] = 54500;
  data['PMI Deduction Third'] = 100000;
  data['PMI Deduction Fourth'] = 109000;

  // SEP IRA Limits
  data['SEP IRA Limits'] = 55000;
  data['SEP IRA Limits Rate'] = 25;
  data['SEP IRA Limits Second'] = 275000;
  data['SEP IRA Limits Rate Second'] = 5;

  // Mortgage Interest Cap
  data['Mortgage Interest Cap'] = 750000;
  data['Home Sale - Single Profit Cap'] = 250000;
  data['Home Sale - Married Profit Cap'] = 500000;
  data['Car Leasing Dropdown'] = {};
  data['Car Leasing Dropdown'][1] = 6;
  data['Car Leasing Dropdown'][2] = 12;
  data['Car Leasing Dropdown'][3] = 18;
  data['Car Leasing Dropdown'][4] = 24;
  data['Car Leasing Dropdown'][5] = 30;
  data['Car Leasing Dropdown'][6] = 36;
  data['Car Leasing Dropdown'][7] = 42;
  data['Car Leasing Dropdown'][8] = 48;
  data['Car Leasing Dropdown'][9] = 54;
  data['Car Leasing Dropdown'][10] = 60;
  data['Car Leasing Dropdown'][11] = 66;
  data['Car Leasing Dropdown'][12] = 72;

  // Roth IRA Income Limits
  data['Roth IRA Income Limits'] = 120000;
  data['Roth IRA Income Limits1'] = 135000;
  data['Roth IRA Income Limits2'] = 189000;
  data['Roth IRA Income Limits3'] = 199000;

  // Health Insurance Itemized Deduct
  data['Health Insurance Itemized Deduct Rate'] = 7.5;
  data['Health Insurance Itemized Deduct Year'] = 2018;
  data['Health Insurance Itemized Deduct Rate Second'] = 10;

  // SALT Limit
  data['SALT Limit'] = 10000;

  // Tuition Tax Adjustment
  data['Tuition Tax'] = {};
  data['Tuition Tax'][1] = 2000;
  data['Tuition Tax'][2] = 4000;
  data['Tuition Tax'][3] = 65000;
  data['Tuition Tax'][4] = 80000;
  data['Tuition Tax'][5] = 130000;
  data['Tuition Tax'][6] = 160000;

  // State Taxes
  data['State'] = {};
	data['State']['Alabama']    = 0.042843713;
	data['State']['Alaska']     = 0;
	data['State']['Arizona']    = 0.023857565;
	data['State']['Arkansas']   = 0.049605193;
	data['State']['California']   = 0.030432511;
	data['State']['Colorado']   = 0.034066053;
	data['State']['Connecticut']   = 0.028404106;
	data['State']['Delaware']   = 0.044215595;
	data['State']['Florida']   = 0;
	data['State']['Georgia']   = 0.048363624;
	data['State']['Hawaii']   = 0.058320038;
	data['State']['Idaho']   = 0.048074383;
	data['State']['Illinois']   = 0.038778591;
	data['State']['Indiana']   = 0.026268267;
	data['State']['Iowa']   = 0.05528406;
	data['State']['Kansas']   = 0.034494622;
	data['State']['Kentucky']   = 0.052432324;
	data['State']['Louisiana']   = 0.029843832;
	data['State']['Maine']   = 0.036735416;
	data['State']['Maryland']   = 0.040335244;
	data['State']['Massachusetts']   = 0.045298684;
	data['State']['Michigan']   = 0.038180821;
	data['State']['Minnesota']   = 0.042710801;
	data['State']['Mississippi']   = 0.036915429;
	data['State']['Missouri']   = 0.043277156;
	data['State']['Montana']   = 0.047394197;
	data['State']['Nebraska']   = 0.037492482;
	data['State']['Nevada']   = 0;
	data['State']['New Hampshire']   = 0;
	data['State']['New Jersey']   = 0.022795188;
	data['State']['New Mexico']   = 0.030230204;
	data['State']['New York']   = 0.04273418;
	data['State']['North Carolina']   = 0.042765136;
	data['State']['North Dakota']   = 0.008944381;
	data['State']['Ohio']   = 0.023842089;
	data['State']['Oklahoma']   = 0.036060062;
	data['State']['Oregon']   = 0.078630397;
	data['State']['Pennsylvania']   = 0.0307;
	data['State']['Rhode Island']   = 0.025804871;
	data['State']['South Carolina']   = 0.043066956;
	data['State']['South Dakota']   = 0;
	data['State']['Tennessee']   = 0;
	data['State']['Texas']   = 0;
	data['State']['Utah']   = 0.03646128;
	data['State']['Vermont']   = 0.029061819;
	data['State']['Virginia']   = 0.047397144;
	data['State']['Washington D.C.']   = 0.046527041;
	data['State']['Washington']   = 0;
	data['State']['West Virginia']   = 0.042977354;
	data['State']['Wisconsin']   = 0.039878848;
	data['State']['Wyoming']   = 0;
  // City Tax Details
  data.City = {};
  data.City.Average = 0.015;

  // PhaseOut Ranges
  data['Single Phaseout Ranges1'] = 261500;
  data['Single Phaseout Ranges2'] = 384000;

  data['Married Phaseout Ranges1'] = 313800;
  data['Married Phaseout Ranges2'] = 436300;

  data.Divisor = 2500;
  data.Return = 2;

  // Standard Deductions
  data['Standard Deductions'] = {};
  data['Standard Deductions'].Single = 12000;
  data['Standard Deductions'].Married = 24000;

  // Deduction Limit
  data['Deduction Limit'] = {};
  data['Deduction Limit'][0] = 2017;
  data['Deduction Limit'][1] = 50;
  data['Deduction Limit'][2] = 2018;
  data['Deduction Limit'][3] = 60;

  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;
  childrenInfo = {};
  let childValue = 0;
  let k = 0;
  for ( year; year < yearLimit; year += 1 ) {
    childrenInfo[year] = {};
    if ( k === 0 ) {
      if ( assumptionsData !== undefined && assumptionsData !== ''
        && assumptionsData.Total !== undefined && assumptionsData.Total !== '' ) {
        childValue = assumptionsData.Total;
      } else {
        childValue = 12794;
      }

      childrenInfo[year].value = childValue;
    } else {
      if ( assumptionsData !== undefined && assumptionsData[year] !== undefined ) {
        childValue *= ( ( assumptionsData[year].Expenses ) / 100 );
      }
      childrenInfo[year].value = childValue;
    }
    k += 1;
  }

  /** Existing Student Loans * */
  // Status
  let existingStudentLoanStatus = 2;
  if ( module18Data !== undefined
    && module18Data.fed_sub_type_of_higher_education !== undefined
    && module18Data.fed_sub_type_of_higher_education !== ''
    && module18Data.fed_sub_type_of_higher_education === 2 ) {
    existingStudentLoanStatus = 1;
  }

  annualLoansTakenFedSub = 0;
  let annualLoansTakenFedUnSub = 0;
  for ( let m = 1; m <= 6; m += 1 ) {
    // Federal Subsidized
    studentLoanLimits['Existing Student Loan']['Fed Sub'][m] = {};

    // Loans Taken
    val = 0;
    if ( module18Data.fed_sub_current_balance !== undefined
      && module18Data.fed_sub_current_balance > 0
      && m <= module18Data.fed_sub_years ) {
      val = module18Data.fed_sub_current_balance / module18Data.fed_sub_years;
    }
    studentLoanLimits['Existing Student Loan']['Fed Sub'][m]['Loans Taken'] = val;
    annualLoansTakenFedSub += val;

    // Federal UnSubsidized
    studentLoanLimits['Existing Student Loan']['Fed Unsub'][m] = {};

    // Loans Taken
    val = 0;
    if ( module18Data.fed_unsub_loan_amount !== undefined
      && module18Data.fed_unsub_loan_amount > 0
      && m <= module18Data.fed_unsub_years ) {
      val = module18Data.fed_unsub_loan_amount / module18Data.fed_unsub_years;
    }
    studentLoanLimits['Existing Student Loan']['Fed Unsub'][m]['Loans Taken'] = val;
    annualLoansTakenFedUnSub += val;
  }
  studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken'] = annualLoansTakenFedSub;
  studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken'] = annualLoansTakenFedUnSub;
  /** Higher Education Fed Sub * */
  year = parseInt( module2Data.start_year, 10 );
  yearLimit = parseInt( module2Data.start_year, 10 ) + 6;
  studentLoanLimits['Higher Education']['Fed Sub'] = {};
  higherEduDependentStatus = 2;
  if ( module2Data !== undefined && module2Data.dependent_status === 'Yes' ) {
    higherEduDependentStatus = 1;
  }
  let higherEduDependentStatus2 = 2;

  // below condition  is commented by chandrakala due to lack of rootscope functionality
  if ( module2Data.type_of_higher_education !== undefined && module2Data.type_of_higher_education !== ''
    && module2Data.type_of_higher_education === 2 ) {
    higherEduDependentStatus2 = 1;
  }
  annualLoansTakenFedSub = 0;

  studentLoanLimits['Higher Education']['Fed Sub']['Total Limit'] = 23000;
  l = 0;
  for ( year; year < yearLimit; year += 1 ) {
    studentLoanLimits['Higher Education']['Fed Sub'][year] = {};

    // Annual Limit
    if ( l === 0 ) {
      if ( existingStudentLoanStatus === 1 && module18Data.fed_sub_years === 1 && module18Data.fed_unsub_years === 1 ) {
        studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] = 4500;
      } else if ( existingStudentLoanStatus === 1 && module18Data.fed_sub_years >= 2 && module18Data.fed_unsub_years === 2 ) {
        studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
      } else {
        studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] = 3500;
      }
    } else if ( l === 1 ) {
      if ( existingStudentLoanStatus === 1 && module18Data.fed_sub_years === 1 && module18Data.fed_unsub_years === 1 ) {
        studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
      } else if ( existingStudentLoanStatus === 1 && module18Data.fed_sub_years >= 2 && module18Data.fed_unsub_years === 2 ) {
        studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
      } else {
        studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] = 4500;
      }
    } else {
      studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
    }
    l += 1;

    // Loans Taken
    val = 0;
    if ( fedSubHigherEdLoanData['Summation Data'] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][year] !== undefined
      && fedSubHigherEdLoanData['Summation Data'][year]['W/O Accrual'] !== undefined ) {
      val = fedSubHigherEdLoanData['Summation Data'][year]['W/O Accrual'];
    }
    studentLoanLimits['Higher Education']['Fed Sub'][year]['Loans Taken'] = val;
    if ( year === module2Data.start_year ) {
      annualLoansTakenFedSub += val;
    } else if ( year === parseInt( module2Data.start_year, 10 ) + 1 ) {
      const loanValue = annualLoansTakenFedSub;
      annualLoansTakenFedSub = loanValue;
    } else {
      annualLoansTakenFedSub += studentLoanLimits['Higher Education']['Fed Sub'][year - 1]['Loans Taken'];
    }

    // Cummulative Limits
    if ( higherEduDependentStatus2 === 2 ) {
      studentLoanLimits['Higher Education']['Fed Sub'][year]['Cummulative Limits'] = 0;
    } else if ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken'] + annualLoansTakenFedSub + studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'] ) > studentLoanLimits['Higher Education']['Fed Sub']['Total Limit'] ) {
      studentLoanLimits['Higher Education']['Fed Sub'][year]['Cummulative Limits'] = studentLoanLimits['Higher Education']['Fed Sub']['Total Limit'] - annualLoansTakenFedSub - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken'];
    } else {
      studentLoanLimits['Higher Education']['Fed Sub'][year]['Cummulative Limits'] = studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits'];
    }
  }
  studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'] = annualLoansTakenFedSub;

  /** Higher Education Fed Unsub * */
  year = parseInt( module2Data.start_year, 10 );
  yearLimit = parseInt( module2Data.start_year, 10 ) + 6;
  studentLoanLimits['Higher Education']['Fed Unsub'] = {};
  annualLoansTakenFedUnsub = 0;
  if ( higherEduDependentStatus === 1 && higherEduDependentStatus2 === 1 ) {
    studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] = 31000;
  } else if ( higherEduDependentStatus === 2 && higherEduDependentStatus2 === 1 ) {
    studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] = 57500;
  } else {
    studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] = 138500;
  }
  k = 0;
  sumVal = 0;
  for ( year; year < yearLimit; year += 1 ) {
    studentLoanLimits['Higher Education']['Fed Unsub'][year] = {};

    // Annual Limit
    if ( k === 0 ) {
      if ( higherEduDependentStatus2 === 2 ) {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 20500;
      } else if ( higherEduDependentStatus === 1 ) {
        if ( module18Data !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== ''
          && module18Data.fed_unsub_type_of_higher_education === 2
          && module18Data.fed_unsub_loan_amount !== undefined
          && module18Data.fed_unsub_loan_amount > 0
          && module18Data.fed_unsub_years === 1
          && module18Data.fed_sub_years === 1 ) {
          studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 6500;
        } else if ( module18Data !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== ''
          && module18Data.fed_unsub_type_of_higher_education === 2
          && module18Data.fed_unsub_loan_amount !== undefined
          && module18Data.fed_unsub_loan_amount > 0
          && module18Data.fed_unsub_years >= 2
          && module18Data.fed_sub_years === 2 ) {
          studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
        } else {
          studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 5500;
        }
      } else if ( module18Data !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== ''
        && module18Data.fed_unsub_type_of_higher_education === 2
        && module18Data.fed_unsub_loan_amount !== undefined
        && module18Data.fed_unsub_loan_amount > 0
        && module18Data.fed_unsub_years === 1
        && module18Data.fed_sub_years === 1 ) {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 10500;
      } else if ( module18Data !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== ''
        && module18Data.fed_unsub_type_of_higher_education === 2
        && module18Data.fed_unsub_loan_amount !== undefined
        && module18Data.fed_unsub_loan_amount > 0
        && module18Data.fed_unsub_years >= 2
        && module18Data.fed_sub_years === 2 ) {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
      } else {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 9500;
      }
    } else if ( k === 1 ) {
      if ( higherEduDependentStatus2 === 2 ) {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 20500;
      } else if ( higherEduDependentStatus === 1 ) {
        if ( module18Data !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== ''
          && module18Data.fed_unsub_type_of_higher_education === 2
          && module18Data.fed_unsub_loan_amount !== undefined
          && module18Data.fed_unsub_loan_amount > 0
          && module18Data.fed_unsub_years === 1
          && module18Data.fed_sub_years === 1 ) {
          studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
        } else if ( module18Data !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== undefined
          && module18Data.fed_unsub_type_of_higher_education !== ''
          && module18Data.fed_unsub_type_of_higher_education === 2
          && module18Data.fed_unsub_loan_amount !== undefined
          && module18Data.fed_unsub_loan_amount > 0
          && module18Data.fed_unsub_years >= 2
          && module18Data.fed_sub_years === 2 ) {
          studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
        } else {
          studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 6500;
        }
      } else if ( module18Data !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== ''
        && module18Data.fed_unsub_type_of_higher_education === 2
        && module18Data.fed_unsub_loan_amount !== undefined
        && module18Data.fed_unsub_loan_amount > 0
        && module18Data.fed_unsub_years === 1
        && module18Data.fed_sub_years === 1 ) {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
      } else if ( module18Data !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== ''
        && module18Data.fed_unsub_type_of_higher_education === 2
        && module18Data.fed_unsub_loan_amount !== undefined
        && module18Data.fed_unsub_loan_amount > 0
        && module18Data.fed_unsub_years >= 2
        && module18Data.fed_sub_years === 2 ) {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
      } else {
        studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 10500;
      }
    } else if ( higherEduDependentStatus2 === 2 ) {
      studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 20500;
    } else if ( higherEduDependentStatus === 1 ) {
      studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
    } else {
      studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
    }

    // Loans Taken
    val = 0;
    if ( fedUnSubHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][year] !== undefined
      && fedUnSubHigherEdLoanData['Summation Data'][year]['Loans Taken'] !== undefined ) {
      val = fedUnSubHigherEdLoanData['Summation Data'][year]['Loans Taken'];
    }
    studentLoanLimits['Higher Education']['Fed Unsub'][year]['Loans Taken'] = val;

    if ( year === module2Data.start_year ) {
      annualLoansTakenFedUnsub += val;
    } else if ( year === parseInt( module2Data.start_year, 10 ) + 1 ) {
      const fedUnsub = annualLoansTakenFedUnsub;
      annualLoansTakenFedUnsub = fedUnsub;
    } else {
      annualLoansTakenFedUnsub += studentLoanLimits['Higher Education']['Fed Unsub'][year - 1]['Loans Taken'];
    }

    // Cumulative Limits
    let cumulativeLimitVal = 0;
    if ( k === 0 ) {
      if ( studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'] < studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] ) {
        if ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits']
          - studentLoanLimits['Higher Education']['Fed Sub'][year]['Annual Limits']
          < studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] ) {
          cumulativeLimitVal = studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits']
            - studentLoanLimits['Higher Education']['Fed Sub'][year]['Loans Taken'];
        } else {
          cumulativeLimitVal = studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken'] - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken'] - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'];
        }
      }
    } else {
      if ( k >= 1 ) {
        sumVal += studentLoanLimits['Higher Education']['Fed Unsub'][year - 1]['Loans Taken'];
      }
      if ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + sumVal
        < studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] ) {
        if ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + sumVal
          + studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits']
          - studentLoanLimits['Higher Education']['Fed Sub'][year]['Loans Taken']
          < studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit'] ) {
          cumulativeLimitVal = studentLoanLimits['Higher Education']['Fed Unsub'][year]['Annual Limits']
            - studentLoanLimits['Higher Education']['Fed Sub'][year]['Loans Taken'];
        } else {
          cumulativeLimitVal = studentLoanLimits['Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - annualLoansTakenFedUnsub;
        }
      }
    }
    k += 1;
    studentLoanLimits['Higher Education']['Fed Unsub'][year]['Cummulative Limits'] = cumulativeLimitVal;
  }
  studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken'] = annualLoansTakenFedUnsub;

  /** More Higher Education Fed Sub * */
  year = parseInt( module6Data.start_year, 10 );
  yearLimit = parseInt( module6Data.start_year, 10 ) + 6;

  // Status
  higherEduDependentStatus = 2;
  if ( module6Data !== undefined && module6Data.dependentStatus === 'Yes' ) {
    higherEduDependentStatus = 1;
  }

  higherEduDependentStatus2 = 2;
  if ( module6Data.type_of_higher_education !== undefined && module6Data.type_of_higher_education !== ''
    && module6Data.type_of_higher_education === 2 ) {
    higherEduDependentStatus2 = 1;
  }

  // Fed Sub
  studentLoanLimits['More Higher Education']['Fed Sub'] = {};
  studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit'] = 23000;

  // Fed Unsub
  studentLoanLimits['More Higher Education']['Fed Unsub'] = {};
  if ( higherEduDependentStatus === 1 && higherEduDependentStatus2 === 1 ) {
    studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] = 31000;
  } else if ( higherEduDependentStatus === 2 && higherEduDependentStatus2 === 1 ) {
    studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] = 57500;
  } else {
    studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] = 138500;
  }

  annualLoansTakenFedSub = 0;
  l = 0;
  k = 0;
  sumVal = 0;
  for ( year; year < yearLimit; year += 1 ) {
    studentLoanLimits['More Higher Education']['Fed Sub'][year] = {};

    // Annual Limit
    if ( l === 0 ) {
      if ( module18Data !== undefined && module18Data.fed_sub_current_balance !== undefined
        && module18Data.fed_sub_current_balance > 0
        && module18Data.fed_sub_type_of_higher_education !== undefined
        && module18Data.fed_sub_type_of_higher_education === 2
        && module18Data.fed_sub_years !== undefined
        && module18Data.fed_sub_years === 1
        && module18Data.fed_unsub_years === 1 ) {
        studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
      } else if ( module2Data !== undefined && module2Data.years_diff === 1 ) {
        studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] = 4500;
      } else if ( module2Data !== undefined && module2Data.years_diff === 2 ) {
        studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
      } else {
        studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
      }
    } else {
      studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;
    }

    // Loans Taken
    val = 0;
    if ( fedSubMoreHigherEdLoanData['Summation Data'] !== undefined
      && fedSubMoreHigherEdLoanData['Summation Data'][year] !== undefined
      && fedSubMoreHigherEdLoanData['Summation Data'][year]['W/O Accrual'] !== undefined ) {
      val = fedSubMoreHigherEdLoanData['Summation Data'][year]['W/O Accrual'];
    }
    studentLoanLimits['More Higher Education']['Fed Sub'][year]['Loans Taken'] = val;
    // annualLoansTakenFedSub = annualLoansTakenFedSub + val;
    if ( year === module6Data.start_year ) {
      annualLoansTakenFedSub += val;
    } else if ( year === parseInt( module6Data.start_year, 10 ) + 1 ) {
      const fedSubValue = annualLoansTakenFedSub;
      annualLoansTakenFedSub = fedSubValue;
    } else {
      annualLoansTakenFedSub += studentLoanLimits['More Higher Education']['Fed Sub'][year - 1]['Loans Taken'];
    }

    // Cumulative Limits
    let cumulativeLimitVal = 0;
    if ( k === 0 ) {
      if ( higherEduDependentStatus2 === 2 ) {
        cumulativeLimitVal = 0;
      } else if (
        ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] )
          < studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit'] ) ) {
        cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'];
      } else if (
        ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < ( studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken'] ) )

        && ( ( studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'];
      } else if (
        ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken'] )
          > ( studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken'] ) )

        && ( ( studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken'];
      }
    } else if ( higherEduDependentStatus2 === 2 ) {
      cumulativeLimitVal = 0;
    } else {
      if ( k >= 1 ) {
        sumVal += studentLoanLimits['More Higher Education']['Fed Sub'][year - 1]['Loans Taken'];
      }
      if (
        ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits']
          + sumVal )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits']
          + sumVal )
          < studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit'] ) ) {
        cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'];
      } else if (
        ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + sumVal )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + sumVal )
          < studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal )
          < ( studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - sumVal ) )

        && ( ( studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal )
          < studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal;
      } else if (
        ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + sumVal )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + sumVal )
          < studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['More Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal )
          > ( studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - sumVal ) )

        && ( ( studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          - sumVal )
          < studentLoanLimits['More Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          - sumVal;
      }
    }
    k += 1;
    studentLoanLimits['More Higher Education']['Fed Sub'][year]['Cummulative Limits'] = cumulativeLimitVal;
  }
  studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'] = annualLoansTakenFedSub;

  /** More Higher Education Fed Unsub * */
  year = parseInt( module6Data.start_year, 10 );
  yearLimit = parseInt( module6Data.start_year, 10 ) + 6;
  annualLoansTakenFedUnsub = 0;
  k = 0;
  sumVal = 0;
  for ( year; year < yearLimit; year += 1 ) {
    studentLoanLimits['More Higher Education']['Fed Unsub'][year] = {};

    // Annual Limit
    if ( k === 0 ) {
      if ( higherEduDependentStatus2 === 2 ) {
        studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 20500;
      } else if ( higherEduDependentStatus === 1 && higherEduDependentStatus2 === 1
        && module18Data !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== undefined
        && module18Data.fed_unsub_type_of_higher_education === 2
        && module18Data.fed_unsub_loan_amount !== undefined
        && module18Data.fed_unsub_loan_amount > 0
        && module18Data.fed_unsub_years !== undefined
        && module18Data.fed_unsub_years === 1
        && module18Data.fed_sub_years === 1 ) {
        studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
      } else if ( higherEduDependentStatus === 1 && higherEduDependentStatus2 === 1
        && module2Data.years_diff === 1 ) {
        studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 6500;
      } else if ( higherEduDependentStatus === 2 && higherEduDependentStatus2 === 1
        && module18Data !== undefined
        && module18Data.fed_unsub_type_of_higher_education !== undefined
        && module18Data.fed_unsub_type_of_higher_education === 2
        && module18Data.fed_unsub_loan_amount !== undefined
        && module18Data.fed_unsub_loan_amount > 0
        && module18Data.fed_unsub_years !== undefined
        && module18Data.fed_unsub_years === 1
        && module18Data.fed_sub_years === 1 ) {
        studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
      } else if ( higherEduDependentStatus === 2 && higherEduDependentStatus2 === 1
        && module2Data.years_diff === 1 ) {
        studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 10500;
      } else if ( higherEduDependentStatus === 1 ) {
        studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
      } else {
        studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
      }
    } else if ( higherEduDependentStatus2 === 2 ) {
      studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 20500;
    } else if ( higherEduDependentStatus === 1 && higherEduDependentStatus2 === 1 && module2Data.years_diff === 1 ) {
      studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
    } else if ( higherEduDependentStatus === 2 && higherEduDependentStatus2 === 1 && module2Data.years_diff === 1 ) {
      studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
    } else if ( higherEduDependentStatus === 1 ) {
      studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
    } else {
      studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
    }

    // Loans Taken
    val = 0;
    if ( fedUnSubMoreHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubMoreHigherEdLoanData['Summation Data'][year] !== undefined
      && fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'] !== undefined ) {
      val = fedUnSubMoreHigherEdLoanData['Summation Data'][year]['Loans Taken'];
    }
    studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Loans Taken'] = val;
    // annualLoansTakenFedUnsub = annualLoansTakenFedUnsub + val;
    if ( year === module6Data.start_year ) {
      annualLoansTakenFedUnsub += val;
    } else if ( year === parseInt( module6Data.start_year, 10 ) + 1 ) {
      const loanFedSub = annualLoansTakenFedUnsub;
      annualLoansTakenFedUnsub = loanFedSub;
    } else {
      annualLoansTakenFedUnsub += studentLoanLimits['More Higher Education']['Fed Unsub'][year - 1]['Loans Taken'];
    }

    // Cumulative Limits
    let cumulativeLimitVal = 0;
    if ( k === 0 ) {
      if ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'] )
        < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] ) {
        if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits'] )
          - studentLoanLimits['More Higher Education']['Fed Sub'][year]['Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] ) {
          cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits']
            - studentLoanLimits['More Higher Education']['Fed Sub'][year]['Loans Taken'];
        } else {
          cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'];
        }
      }
    } else {
      if ( k >= 1 ) {
        sumVal += studentLoanLimits['More Higher Education']['Fed Unsub'][year - 1]['Loans Taken'];
      }

      if ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
        + sumVal )
        < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] ) {
        if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits']
          + sumVal )
          - studentLoanLimits['More Higher Education']['Fed Sub'][year]['Loans Taken'] )
          < studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit'] ) {
          cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Annual Limits']
            - studentLoanLimits['More Higher Education']['Fed Sub'][year]['Loans Taken'];
        } else {
          cumulativeLimitVal = studentLoanLimits['More Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
            - sumVal;
        }
      }
    }
    studentLoanLimits['More Higher Education']['Fed Unsub'][year]['Cummulative Limits'] = cumulativeLimitVal;
    k += 1;
  }
  studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken'] = annualLoansTakenFedUnsub;

  /** New Higher Education Fed Sub * */
  year = parseInt( module15Data.start_year, 10 );
  yearLimit = parseInt( module15Data.start_year, 10 ) + 6;

  // Status
  let newhigherEduDependentStatus = 2;
  if ( module15Data !== undefined && module15Data.dependentStatus === 'Yes' ) {
    newhigherEduDependentStatus = 1;
  }
  let newhigherEduDependentStatus2 = 2;
  if ( module15Data !== undefined && module15Data.type_of_higher_education !== undefined && module15Data.type_of_higher_education === 2 ) {
    newhigherEduDependentStatus2 = 1;
  }

  // Fed Sub
  studentLoanLimits['New Higher Education']['Fed Sub'] = {};
  studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit'] = 23000;

  // Fed Unsub
  studentLoanLimits['New Higher Education']['Fed Unsub'] = {};
  if ( newhigherEduDependentStatus === 1 && newhigherEduDependentStatus2 === 1 ) {
    studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] = 31000;
  } else if ( newhigherEduDependentStatus === 2 && newhigherEduDependentStatus2 === 1 ) {
    studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] = 57500;
  } else {
    studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] = 138500;
  }

  annualLoansTakenFedSub = 0;
  l = 0;
  k = 0;
  sumVal = 0;
  for ( year; year < yearLimit; year += 1 ) {
    studentLoanLimits['New Higher Education']['Fed Sub'][year] = {};

    // Annual Limit
    studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'] = 5500;

    // Loans Taken
    val = 0;
    if ( fedSubNewHigherEdLoanData['Summation Data'] !== undefined
      && fedSubNewHigherEdLoanData['Summation Data'][year] !== undefined
      && fedSubNewHigherEdLoanData['Summation Data'][year]['W/O Accrual'] !== undefined ) {
      val = fedSubNewHigherEdLoanData['Summation Data'][year]['W/O Accrual'];
    }
    studentLoanLimits['New Higher Education']['Fed Sub'][year]['Loans Taken'] = val;
    // annualLoansTakenFedSub = annualLoansTakenFedSub + val;
    if ( year === module15Data.start_year ) {
      annualLoansTakenFedSub += val;
    } else if ( year === parseInt( module15Data.start_year, 10 ) + 1 ) {
      const annualFedLoan = annualLoansTakenFedSub;
      annualLoansTakenFedSub = annualFedLoan;
    } else {
      annualLoansTakenFedSub
        += studentLoanLimits['New Higher Education']['Fed Sub'][year - 1]['Loans Taken'];
    }

    // Cumulative Limits
    let cumulativeLimitVal = 0;
    if ( k === 0 ) {
      if ( newhigherEduDependentStatus2 === 2 ) {
        cumulativeLimitVal = 0;
      } else if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'] )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'] )
          < studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit'] ) ) {
        cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'];
      } else if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken'] )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < ( studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken'] ) )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'];
      } else if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken'] )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'] )
          < studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken'] )
          > ( studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken'] ) )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken'] )
          < studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken'];
      }
    } else if ( newhigherEduDependentStatus2 === 2 ) {
      cumulativeLimitVal = 0;
    } else {
      if ( k >= 1 ) {
        sumVal += studentLoanLimits['New Higher Education']['Fed Sub'][year - 1]['Loans Taken'];
      }

      if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits']
        + sumVal )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits']
          + sumVal )
          < studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit'] ) ) {
        cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'];
      } else if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + sumVal )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + sumVal )
          < studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal )
          < ( studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
            - sumVal ) )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal )
          < studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal;
      } else if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + sumVal )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] )

        && ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + sumVal )
          < studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit'] )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - sumVal )
          > ( studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
            - sumVal ) )

        && ( ( studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
          - sumVal )
          < studentLoanLimits['New Higher Education']['Fed Sub'][year]['Annual Limits'] ) ) {
        cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Sub']['Total Limit']
          - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
          - sumVal;
      }
    }
    k += 1;
    studentLoanLimits['New Higher Education']['Fed Sub'][year]['Cummulative Limits'] = cumulativeLimitVal;
  }
  studentLoanLimits['New Higher Education']['Fed Sub']['Annual Loans Taken'] = annualLoansTakenFedSub;

  /** New Higher Education Fed Unsub * */
  year = parseInt( module15Data.start_year, 10 );
  yearLimit = parseInt( module15Data.start_year, 10 ) + 6;
  annualLoansTakenFedUnsub = 0;
  k = 0;
  sumVal = 0;
  for ( year; year < yearLimit; year += 1 ) {
    studentLoanLimits['New Higher Education']['Fed Unsub'][year] = {};
    // Annual Limit
    if ( newhigherEduDependentStatus2 === 2 ) {
      studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Annual Limits'] = 20500;
    } else if ( newhigherEduDependentStatus === 1 ) {
      studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Annual Limits'] = 7500;
    } else {
      studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Annual Limits'] = 12500;
    }

    // Loans Taken
    val = 0;
    if ( fedUnSubNewHigherEdLoanData['Summation Data'] !== undefined
      && fedUnSubNewHigherEdLoanData['Summation Data'][year] !== undefined
      && fedUnSubNewHigherEdLoanData['Summation Data'][year]['Loans Taken'] !== undefined ) {
      val = fedUnSubNewHigherEdLoanData['Summation Data'][year]['Loans Taken'];
    }
    studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Loans Taken'] = val;
    // annualLoansTakenFedUnsub = annualLoansTakenFedUnsub + val;
    if ( year === module15Data.start_year ) {
      annualLoansTakenFedUnsub += val;
    } else if ( year === parseInt( module15Data.start_year, 10 ) + 1 ) {
      const annualFedUnsub = annualLoansTakenFedUnsub;
      annualLoansTakenFedUnsub = annualFedUnsub;
    } else {
      annualLoansTakenFedUnsub += studentLoanLimits['New Higher Education']['Fed Unsub'][year - 1]['Loans Taken'];
    }

    // Cumulative Limits
    let cumulativeLimitVal = 0;
    if ( k === 0 ) {
      if ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['New Higher Education']['Fed Sub']['Annual Loans Taken'] )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] ) {
        if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['New Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Annual Limits'] )
          - studentLoanLimits['New Higher Education']['Fed Sub'][year]['Loans Taken'] )
          < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] ) {
          cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Annual Limits']
            - studentLoanLimits['New Higher Education']['Fed Sub'][year]['Loans Taken'];
        } else {
          cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['New Higher Education']['Fed Sub']['Annual Loans Taken'];
        }
      }
    } else {
      if ( k >= 1 ) {
        sumVal += studentLoanLimits['New Higher Education']['Fed Unsub'][year - 1]['Loans Taken'];
      }

      if ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
        + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
        + studentLoanLimits['New Higher Education']['Fed Sub']['Annual Loans Taken']
        + sumVal )
        < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] ) {
        if ( ( ( studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
          + studentLoanLimits['New Higher Education']['Fed Sub']['Annual Loans Taken']
          + studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Annual Limits']
          + sumVal )
          - studentLoanLimits['New Higher Education']['Fed Sub'][year]['Loans Taken'] )
          < studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit'] ) {
          cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Annual Limits']
            - studentLoanLimits['New Higher Education']['Fed Sub'][year]['Loans Taken'];
        } else {
          cumulativeLimitVal = studentLoanLimits['New Higher Education']['Fed Unsub']['Total Limit']
            - studentLoanLimits['Existing Student Loan']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Existing Student Loan']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Sub']['Annual Loans Taken']
            - studentLoanLimits['More Higher Education']['Fed Unsub']['Annual Loans Taken']
            - studentLoanLimits['New Higher Education']['Fed Sub']['Annual Loans Taken']
            - sumVal;
        }
      }
    }
    studentLoanLimits['New Higher Education']['Fed Unsub'][year]['Cummulative Limits'] = cumulativeLimitVal;
    k += 1;
  }
  studentLoanLimits['New Higher Education']['Fed Unsub']['Annual Loans Taken'] = annualLoansTakenFedUnsub;

  // Personal Exemptions Data
  year = parseInt( currentYear, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;
  let personalExemptionValue = 0;
  let marriageYearValue = 0;
  let childrenValue = 0;
  let childrenValue1 = 0;
  let childrenValue2 = 0;
  let childrenValue3 = 0;
  let childrenValue4 = 0;
  let childrenValue5 = 0;
  let childrenValue6 = 0;
  let childrenValue7 = 0;
  let exemptionReductionValue = 0;
  let exemptionReductionValue1 = 0;
  let exemptionReductionValue2 = 0;

  let countMax = 0;
  let countMaxTotal = 0;
  let collegeYes = 0;
  let independent = 0;
  let creditBefore = 0;
  let phaseOutReduction = 0;
  let availableTaxCredit = 0;
  let federalTaxableIncome = 0;
  let totalTaxes = 0;
  let taxCreditAppliedBeforeRefund = 0;
  let taxCreditAppliedOnRefund = 0;
  let taxCreditApplied = 0;

  let americanOpportunity = 1;
  let lifetimeLearningCreditBefore = 0;
  let lifetimePhaseOutReduction = 0;
  let lifetimeLearningAvailableTaxCredit = 0;
  let federalIncomeTaxes = 0;
  let lifetimeLearningTaxCreditApplied = 0;
  let singleValue = 0;
  let marriedValue = 0;
  let limitValue = 0;
  let aotcSingleRange = 0;
  let aotcSingleRange2 = 0;
  let aotcMarriedRange = 0;
  let aotcMarriedRange2 = 0;
  let lifetimeLearningSingleRange = 0;
  let lifetimeLearningSingleRange2 = 0;
  let lifetimeLearningMarriedRange = 0;
  let lifetimeLearningMarriedRange2 = 0;
  let kMaxLoanLimit = 0;
  let kTotalLimit = 0;
  let rothIraMaxPersonalLimit = 0;
  let sepIraMaxPersonalLimit = 0;
  let sepIraTotalLimit = 0;
  let pensionTotalLimit = 0;
  let rothIraIncomeLimitsSingleRange = 0;
  let rothIraIncomeLimitsSingleRange2 = 0;
  let rothIraIncomeLimitsMarriedRange = 0;
  let rothIraIncomeLimitsMarriedRange2 = 0;
  
  let hsaSingleRange = 0;
  let hsaMarriedRange = 0;

  let firstChildren = 0;
  let secondChildren = 0;
  let thirdChildren = 0;
  let fourthChildren = 0;
  let fifthChildren = 0;
  let sixthChildren = 0;
  let seventhChildren = 0;

  let totalGrossIncomeValue1 = 0;
  // const totalGrossIncomeValue2 = 0;
  let childPhaseoutReduction = 0;
  let childPhaseoutReduction1 = 0;
  let childPhaseoutReduction2 = 0;
  let childAvailableTaxCredit = 0;
  let childTotalTaxes = 0;
  let childTaxCreditAppliedBeforeRefund = 0;
  let childTaxCreditAppliedAsRefund = 0;
  let minimumIncomeThreshold = 0;
  let maximumRefundValues = 0;
  let conditionValuefifteen = 0;
  let conditionValueMath = 0;
  // let c = 0;
  let deductionLimitValue = 0;

  personalExemptionsData = {};
  americanOpportunityData = {};
  lifetimeLearningData = {};
  standardDeductions = {};
  aotcTaxCreditPhaseOut = {};
  lifetimeLearningCredit = {};
  socialSecurityIncomeTaxLimit = {};
  childTaxCredit = {};

  // For 401k RetiremtnAccounts Limit
  kContributionsLimits = {};
  rothIraContributionsLimits = {};
  sepIraContributionsLimits = {};
  pensionContributionsLimits = {};
  rothIraIncomeLimits = {};

  /** Personal Exemptions Ranges * */
  personalExemptionsData.Ranges = {};
  // Single
  personalExemptionsData.Ranges.Single = 261500;
  personalExemptionsData.Ranges.Single2 = 384000;
  // Married
  personalExemptionsData.Ranges.Married = 313800;
  personalExemptionsData.Ranges.Married2 = 436300;
  // Divisor
  personalExemptionsData.Ranges.Divisor = 2500;
  // Reduction
  personalExemptionsData.Ranges.Reduction = 2;

  /** American Opportunity Ranges * */
  americanOpportunityData.Ranges = {};
  // Single
  americanOpportunityData.Ranges.Single = 80000;
  americanOpportunityData.Ranges.Single2 = 90000;
  // Married
  americanOpportunityData.Ranges.Married = 160000;
  americanOpportunityData.Ranges.Married2 = 180000;

  /** Lifetime Learning Ranges * */
  lifetimeLearningData.Ranges = {};
  // Single
  lifetimeLearningData.Ranges.Single = 57000;
  lifetimeLearningData.Ranges.Single2 = 67000;
  // Married
  lifetimeLearningData.Ranges.Married = 114000;
  lifetimeLearningData.Ranges.Married2 = 134000;

  medicareTaxData.Single = 200000;
  medicareTaxData.Married = 250000;
  medicareTaxData['Not Self-Employed Rate'] = 1.45;
  medicareTaxData['Self-Employed Rate'] = 2.90;
  medicareTaxData['Not Self-Employed Addn Rate'] = 2.35;
  medicareTaxData['Self-Employed Addn Rate'] = 3.80;

  for ( year; year < yearLimit; year += 1 ) {
    // Charitable Contributions Limit % of AGI
    deductionLimit[year] = {};
    if ( year === data['Deduction Limit'][0] ) {
      deductionLimitValue = data['Deduction Limit'][1];
    } else {
      deductionLimitValue = data['Deduction Limit'][3];
    }
    deductionLimit[year]['Deduction Limit'] = deductionLimitValue;
    // Passive Loan Max Limits
    passiveLossMaxLimit[year] = {};
    passiveLossMaxLimit[year]['Passive Loss Max Limit'] = 25000;

    // Meicare Tax Data
    medicareTaxData[year] = {};
    if ( module10Data !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
      medicareTaxData[year]['Additional Medicare Tax Threshold'] = medicareTaxData.Married;
    } else {
      medicareTaxData[year]['Additional Medicare Tax Threshold'] = medicareTaxData.Single;
    }

    /** Personal Exemptions * */
    personalExemptionsData[year] = {};

    // Personal Exemption
    if ( module2Data !== undefined
      && year >= module2Data.start_year && year <= module2Data.graduation_year
      && module2Data.dependent_status === 'Yes' ) {
      personalExemptionValue = 0;
    } else if ( module6Data !== undefined
      && year >= module6Data.start_year && year <= module6Data.graduation_year
      && module6Data.dependent_status === 'Yes' ) {
      personalExemptionValue = 0;
    } else if ( module15Data !== undefined
      && year >= module15Data.start_year && year <= module15Data.graduation_year
      && module15Data.dependent_status === 'Yes' ) {
      personalExemptionValue = 0;
    } else {
      personalExemptionValue = 4050;
    }
    personalExemptionsData[year]['Personal Exemption'] = personalExemptionValue;

    // Marriage
    if ( module10Data !== undefined && year >= module10Data.marriege_year && module10Data.marriege_year > 0 ) {
      marriageYearValue = 1;
    } else {
      marriageYearValue = 0;
    }
    personalExemptionsData[year].Marriage = parseFloat( marriageYearValue ) * 4050;

    // Children
    if ( module10Data !== undefined && year >= module10Data.first_born && module10Data.first_born > 0 ) {
      childrenValue1 = 1;
    }
    if ( module10Data !== undefined && year >= module10Data.second_born && module10Data.second_born > 0 ) {
      childrenValue2 = 1;
    }
    if ( module10Data !== undefined && year >= module10Data.third_born && module10Data.third_born > 0 ) {
      childrenValue3 = 1;
    }
    if ( module10Data !== undefined && year >= module10Data.fourth_born && module10Data.fourth_born > 0 ) {
      childrenValue4 = 1;
    }
    if ( module10Data !== undefined && year >= module10Data.fifth_born && module10Data.fifth_born > 0 ) {
      childrenValue5 = 1;
    }
    if ( module10Data !== undefined && year >= module10Data.sixth_born && module10Data.sixth_born > 0 ) {
      childrenValue6 = 1;
    }
    if ( module10Data !== undefined && year >= module10Data.seventh_born && module10Data.seventh_born > 0 ) {
      childrenValue7 = 1;
    }
    childrenValue = childrenValue1 + childrenValue2 + childrenValue3 + childrenValue4 + childrenValue5 + childrenValue6 + childrenValue7;
    personalExemptionsData[year].Children = childrenValue * 4050;

    // Total Exemptions
    personalExemptionsData[year]['Total Exemptions1'] = personalExemptionsData[year]['Personal Exemption'] + personalExemptionsData[year].Marriage + personalExemptionsData[year].Children;

    // Adjusted Gross Income
    if ( incomeStatementData !== undefined && incomeStatementData[year] !== undefined && incomeStatementData[year]['Adjusted Gross Income'] !== undefined && incomeStatementData[year]['Adjusted Gross Income'] !== '' ) {
      personalExemptionsData[year]['Adjusted Gross Income'] = incomeStatementData[year]['Adjusted Gross Income'];
    }

    // Exemption Reduction
    if ( personalExemptionsData[year]['Adjusted Gross Income'] < data['Single Phaseout Ranges1'] ) {
      exemptionReductionValue = 0;
    } else if ( personalExemptionsData[year]['Adjusted Gross Income'] >= data['Single Phaseout Ranges1'] && personalExemptionsData[year]['Adjusted Gross Income'] < data['Single Phaseout Ranges2'] ) {
      exemptionReductionValue1 = -Math.floor( ( parseFloat( ( personalExemptionsData[year]['Adjusted Gross Income'] - data['Single Phaseout Ranges1'] ) / data.Divisor ) ) );
      exemptionReductionValue2 = ( personalExemptionsData[year]['Total Exemptions1'] / 100 ) * 2;
      exemptionReductionValue = exemptionReductionValue1 * exemptionReductionValue2;
    } else {
      exemptionReductionValue = -personalExemptionsData[year]['Total Exemptions1'];
    }
    personalExemptionsData[year]['Exemption Reduction'] = exemptionReductionValue;

    // Total Exemptions 2
    personalExemptionsData[year]['Total Exemptions2'] = personalExemptionsData[year]['Exemption Reduction'] + personalExemptionsData[year]['Total Exemptions1'];

    // Standard Deductions Array
    standardDeductions[year] = {};
    aotcTaxCreditPhaseOut[year] = {};
    lifetimeLearningCredit[year] = {};

    kContributionsLimits[year] = {};
    rothIraContributionsLimits[year] = {};
    sepIraContributionsLimits[year] = {};
    pensionContributionsLimits[year] = {};
    rothIraIncomeLimits[year] = {};
	hsaIncomeLimits[year] = {};

    socialSecurityIncomeTaxLimit[year] = {};
    if ( year === currentYear ) {
      limitValue = 128400;
      singleValue = 12000;
      marriedValue = 24000;

      aotcSingleRange = 80000;
      aotcSingleRange2 = 90000;
      aotcMarriedRange = 160000;
      aotcMarriedRange2 = 180000;
      lifetimeLearningSingleRange = 57000;
      lifetimeLearningSingleRange2 = 67000;
      lifetimeLearningMarriedRange = 114000;
      lifetimeLearningMarriedRange2 = 134000;
      // For 401 K
      kMaxLoanLimit = 18500;
      kTotalLimit = 55000;
      // For Roth IRA
      rothIraMaxPersonalLimit = 5500;
      // For Sep IRA
      sepIraMaxPersonalLimit = 55000;
      sepIraTotalLimit = 275000;
      // Pension
      pensionTotalLimit = 220000;
      // Roth IRA Income Limits
      rothIraIncomeLimitsSingleRange = 120000;
      rothIraIncomeLimitsSingleRange2 = 135000;
      rothIraIncomeLimitsMarriedRange = 189000;
      rothIraIncomeLimitsMarriedRange2 = 199000;
	  
	  //For HSA
		hsaSingleRange = 3450;
		hsaMarriedRange = 6900;
					
    } else{
		if ( year === ( currentYear + 1 ) ) {
      limitValue = 132900;

      singleValue = 12200;
      marriedValue = 24400;

      aotcSingleRange = 80000;
      aotcSingleRange2 = 90000;
      aotcMarriedRange = 160000;
      aotcMarriedRange2 = 180000;

      lifetimeLearningSingleRange = 58000;
      lifetimeLearningSingleRange2 = 68000;
      lifetimeLearningMarriedRange = 116000;
      lifetimeLearningMarriedRange2 = 136000;

      // For 401 K
      kMaxLoanLimit = 19000;
      kTotalLimit = 56000;
      // For Roth IRA
      rothIraMaxPersonalLimit = 6000;
      // For Sep IRA
      sepIraMaxPersonalLimit = 56000;
      sepIraTotalLimit = 280000;
      // Pension
      pensionTotalLimit = 225000;
      // Roth IRA Income Limits
      rothIraIncomeLimitsSingleRange = 122000;
      rothIraIncomeLimitsSingleRange2 = 137000;
      rothIraIncomeLimitsMarriedRange = 193000;
      rothIraIncomeLimitsMarriedRange2 = 203000;
	  
	  //For HSA
		hsaSingleRange = 3500;
		hsaMarriedRange = 7000;
						
    } else if ( year === ( currentYear + 2 ) ) {
      limitValue = 137700;
      singleValue = 12400;
      marriedValue = 24800;

      aotcSingleRange = 80000;
      aotcSingleRange2 = 90000;
      aotcMarriedRange = 160000;
      aotcMarriedRange2 = 180000;

      lifetimeLearningSingleRange = 59000;
      lifetimeLearningSingleRange2 = 69000;
      lifetimeLearningMarriedRange = 118000;
      lifetimeLearningMarriedRange2 = 138000;

      // For 401 K
      kMaxLoanLimit = 19500;
      kTotalLimit = 57000;
      // For Roth IRA
      rothIraMaxPersonalLimit = 6000;
      // For Sep IRA
      sepIraMaxPersonalLimit = 57000;
      sepIraTotalLimit = 285000;
      // Pension
      pensionTotalLimit = 230000;
      // Roth IRA Income Limits
      rothIraIncomeLimitsSingleRange = 124000;
      rothIraIncomeLimitsSingleRange2 = 139000;
      rothIraIncomeLimitsMarriedRange = 196000;
      rothIraIncomeLimitsMarriedRange2 = 206000;
	  
	  //For HSA
		hsaSingleRange = 3550;
		hsaMarriedRange = 7100;
						
    } else if(year === (currentYear+3)) {
			limitValue = 142800;
			
			singleValue = 12550;
			marriedValue = 25100;
		
			aotcSingleRange = 80000;
			aotcSingleRange2 = 90000;
			aotcMarriedRange = 160000;
			aotcMarriedRange2 = 180000;
		
			lifetimeLearningSingleRange = 59000;
			lifetimeLearningSingleRange2 = 69000;
			lifetimeLearningMarriedRange = 118000;
			lifetimeLearningMarriedRange2 = 138000;
		
			//For 401 K
			kMaxLoanLimit = 19500;
			kTotalLimit = 58000;
			//For Roth IRA
			rothIraMaxPersonalLimit = 6000;
			//For Sep IRA
			sepIraMaxPersonalLimit = 58000;
			sepIraTotalLimit = 290000;
			//Pension
			pensionTotalLimit = 230000;
			//Roth IRA Income Limits
			rothIraIncomeLimitsSingleRange = 125000;
			rothIraIncomeLimitsSingleRange2 = 140000;
			rothIraIncomeLimitsMarriedRange = 198000;
			rothIraIncomeLimitsMarriedRange2 = 208000;
			//For HSA
			hsaSingleRange = 3600;
			hsaMarriedRange = 7200;
		} else {
      singleValue *= ( 1 + ( 2 / 100 ) );
      limitValue *= ( 1 + ( 2 / 100 ) );
      marriedValue *= ( 1 + ( 2 / 100 ) );
      // For AOTC
      aotcSingleRange *= ( 1 + ( 2 / 100 ) );
      aotcSingleRange2 *= ( 1 + ( 2 / 100 ) );
      aotcMarriedRange *= ( 1 + ( 2 / 100 ) );
      aotcMarriedRange2 *= ( 1 + ( 2 / 100 ) );

      // For Lifetime Learning Credit
      lifetimeLearningSingleRange *= ( 1 + ( 2 / 100 ) );
      lifetimeLearningSingleRange2 *= ( 1 + ( 2 / 100 ) );
      lifetimeLearningMarriedRange *= ( 1 + ( 2 / 100 ) );
      lifetimeLearningMarriedRange2 *= ( 1 + ( 2 / 100 ) );

      // For 401 K
      kMaxLoanLimit *= ( 1 + ( 2 / 100 ) );
      kTotalLimit *= ( 1 + ( 2 / 100 ) );
      // For Roth IRA
      rothIraMaxPersonalLimit *= ( 1 + ( 2 / 100 ) );
      // For Sep IRA
      sepIraMaxPersonalLimit *= ( 1 + ( 2 / 100 ) );
      sepIraTotalLimit *= ( 1 + ( 2 / 100 ) );
      // Pension
      pensionTotalLimit *= ( 1 + ( 2 / 100 ) );
      // Roth IRA Income Limits
      rothIraIncomeLimitsSingleRange *= ( 1 + ( 2 / 100 ) );
      rothIraIncomeLimitsSingleRange2 *= ( 1 + ( 2 / 100 ) );
      rothIraIncomeLimitsMarriedRange *= ( 1 + ( 2 / 100 ) );
      rothIraIncomeLimitsMarriedRange2 *= ( 1 + ( 2 / 100 ) );
	  
	  //For HSA
		hsaSingleRange = hsaSingleRange * ( 1 + ( 2/100 ) );
		hsaMarriedRange = hsaMarriedRange * ( 1 + ( 2/100 ) );
		}
    }
    standardDeductions[year].Single = singleValue;
    standardDeductions[year].Married = marriedValue;
    socialSecurityIncomeTaxLimit[year].Limit = limitValue;

    // AOTC Tax Credit Phaseout
    aotcTaxCreditPhaseOut[year].Single = aotcSingleRange;
    aotcTaxCreditPhaseOut[year].Single2 = aotcSingleRange2;
    aotcTaxCreditPhaseOut[year].Married = aotcMarriedRange;
    aotcTaxCreditPhaseOut[year].Married2 = aotcMarriedRange2;

    // Lifetime Learning Credit
    lifetimeLearningCredit[year].Single = lifetimeLearningSingleRange;
    lifetimeLearningCredit[year].Single2 = lifetimeLearningSingleRange2;
    lifetimeLearningCredit[year].Married = lifetimeLearningMarriedRange;
    lifetimeLearningCredit[year].Married2 = lifetimeLearningMarriedRange2;

    // For 401 Retiremtn
    kContributionsLimits[year]['Max Personal Limit'] = kMaxLoanLimit;
    kContributionsLimits[year]['Total Limit'] = kTotalLimit;
    rothIraContributionsLimits[year]['Max Personal Limit'] = rothIraMaxPersonalLimit;

    sepIraContributionsLimits[year]['Max Personal Limit'] = sepIraMaxPersonalLimit;
    sepIraContributionsLimits[year]['Total Limit'] = sepIraTotalLimit;
    sepIraContributionsLimits[year].Return = 25;

    pensionContributionsLimits[year]['Total Limit'] = pensionTotalLimit;
    rothIraIncomeLimits[year].Single = rothIraIncomeLimitsSingleRange;
    rothIraIncomeLimits[year].Single1 = rothIraIncomeLimitsSingleRange2;
    rothIraIncomeLimits[year].Married = rothIraIncomeLimitsMarriedRange;
    rothIraIncomeLimits[year].Married1 = rothIraIncomeLimitsMarriedRange2;
	
	hsaIncomeLimits[year]['Single'] = hsaSingleRange;
	hsaIncomeLimits[year]['Married'] = hsaMarriedRange;

    /** American Opportunity * */
    americanOpportunityData[year] = {};

    // Count (Max4)
    if ( creditBefore > 0 ) {
      countMax = 1;
    } else {
      countMax = 0;
    }
    americanOpportunityData[year].Count = countMax;

    // College-Yes
    if ( module2Data !== undefined && module2Data.start_year !== undefined && module2Data.graduation_year !== undefined
        && year >= module2Data.start_year && year <= module2Data.graduation_year
        && module2Data.type_of_higher_education === 2 ) {
      collegeYes = 1;
    } else if ( module6Data !== undefined && module6Data.start_year !== undefined && module6Data.graduation_year !== undefined
            && year >= module6Data.start_year && year <= module6Data.graduation_year
            && module6Data.type_of_higher_education === 2 ) {
      collegeYes = 1;
    } else if ( module15Data !== undefined && module15Data.start_year !== undefined && module15Data.graduation_year !== undefined
                && year >= module15Data.start_year && year <= module15Data.graduation_year
                && module15Data.type_of_higher_education === 2 ) {
      collegeYes = 1;
    } else {
      collegeYes = 0;
    }
    americanOpportunityData[year].College = collegeYes;

    // Independent
    if ( module2Data !== undefined
      && year >= module2Data.start_year && year <= module2Data.graduation_year
      && module2Data.dependent_status === 'No' ) {
      independent = 1;
    } else if ( module6Data !== undefined
      && year >= module6Data.start_year && year <= module6Data.graduation_year
      && module6Data.dependent_status === 'No' ) {
      independent = 1;
    } else if ( module15Data !== undefined
      && year >= module15Data.start_year && year <= module15Data.graduation_year
      && module15Data.dependent_status === 'No' ) {
      independent = 1;
    } else {
      independent = 0;
    }
    americanOpportunityData[year].Independent = independent;

    // Credit before Phaseouts
    if ( countMaxTotal < 4
      && americanOpportunityData[year].College === 1
      && americanOpportunityData[year].Independent === 1 ) {
      if ( incomeStatementData !== undefined
        && incomeStatementData[year] !== undefined
        && incomeStatementData[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
        && incomeStatementData[year].livingExpenses['Higher Education Room & Board'] !== undefined ) {
        const Value = -( incomeStatementData[year].livingExpenses['Higher Education Tuition and Fees'] + incomeStatementData[year].livingExpenses['Higher Education Room & Board'] );
        if ( Value >= 4000 ) {
          creditBefore = 2500;
        } else if ( Value >= 2000 && Value < 4000 ) {
          creditBefore = 2000 + ( 25 / 100 ) * ( Value - 2000 );
        } else if ( Value > 0 && Value < 2000 ) {
          creditBefore = Value;
        } else {
          creditBefore = 0;
        }
      } else {
        creditBefore = 0;
      }
    } else {
      creditBefore = 0;
    }
    americanOpportunityData[year]['Credit before'] = creditBefore;
    countMaxTotal += countMax;

    // Phaseout Reduction on 1-29-19
    if ( module10Data !== undefined && module10Data.marriege_year !== undefined && module10Data.marriege_year > 0 && year >= module10Data.marriege_year ) {
      if ( taxStatementData !== undefined
        && taxStatementData !== ''
        && taxStatementData[year] !== undefined
        && taxStatementData[year] !== ''
        && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined
        && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] <= aotcTaxCreditPhaseOut[year].Married ) {
        phaseOutReduction = 0;
      } else if ( taxStatementData !== undefined
        && taxStatementData !== ''
        && taxStatementData[year] !== undefined
        && taxStatementData[year] !== ''
        && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined
        && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] > aotcTaxCreditPhaseOut[year].Married
        && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] < aotcTaxCreditPhaseOut[year].Married2
        && americanOpportunityData[year]['Credit before'] > 0 ) {
        phaseOutReduction = -( taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] - aotcTaxCreditPhaseOut[year].Married ) / ( 20000 * americanOpportunityData[year]['Credit before'] );
      } else {
        phaseOutReduction = -100 * ( americanOpportunityData[year]['Credit before'] );
      }
    } else if ( taxStatementData !== undefined
      && taxStatementData !== ''
      && taxStatementData[year] !== undefined
      && taxStatementData[year] !== ''
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] <= aotcTaxCreditPhaseOut[year].Single ) {
      phaseOutReduction = 0;
    } else if ( taxStatementData !== undefined
      && taxStatementData !== ''
      && taxStatementData[year] !== undefined
      && taxStatementData[year] !== ''
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] > aotcTaxCreditPhaseOut[year].Single
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] < aotcTaxCreditPhaseOut[year].Single2
      && americanOpportunityData[year]['Credit before'] > 0 ) {
      phaseOutReduction = -( taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] - aotcTaxCreditPhaseOut[year].Single ) / ( 10000 * americanOpportunityData[year]['Credit before'] );
    } else {
      phaseOutReduction = -100 * ( americanOpportunityData[year]['Credit before'] );
    }
    americanOpportunityData[year]['Phaseout Reduction'] = phaseOutReduction;

    // Available Tax Credit
    availableTaxCredit = americanOpportunityData[year]['Credit before']
      + americanOpportunityData[year]['Phaseout Reduction'];
    americanOpportunityData[year]['Available Tax Credit'] = availableTaxCredit;

    // Total Taxes
    totalTaxes = 0;
    if ( taxStatementData !== undefined
      && taxStatementData !== ''
      && taxStatementData[year] !== undefined
      && taxStatementData[year] !== ''
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined
      && taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'] !== undefined ) {
      totalTaxes = ( taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] / 100 ) * taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'];
    }
    americanOpportunityData[year]['Total Taxes'] = totalTaxes;

    // Federal Taxable Income
    federalTaxableIncome = 0;
    if ( taxStatementData !== undefined
      && taxStatementData[year] !== undefined
      && taxStatementData[year]['Federal Taxable Income'] !== undefined
      && taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'] !== undefined ) {
      federalTaxableIncome = ( taxStatementData[year]['Federal Taxable Income'] / 100 ) * taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'];
    }
    americanOpportunityData[year]['Federal Taxable Income'] = federalTaxableIncome;

    // Tax Credit Applied Before Refund
    if ( americanOpportunityData[year]['Available Tax Credit'] > americanOpportunityData[year]['Total Taxes'] ) {
      taxCreditAppliedBeforeRefund = americanOpportunityData[year]['Total Taxes'];
    } else {
      taxCreditAppliedBeforeRefund = americanOpportunityData[year]['Available Tax Credit'];
    }
    americanOpportunityData[year]['Tax Credit Applied Before Refund'] = taxCreditAppliedBeforeRefund;

    // Tax Credit Applied On Refund
    taxCreditAppliedOnRefund = 0;
    if ( availableTaxCredit > taxCreditAppliedBeforeRefund ) {
      const Value1 = ( availableTaxCredit - taxCreditAppliedBeforeRefund );
      const Value2 = ( 40 / 100 ) * availableTaxCredit;
      if ( Value1 > Value2 ) {
        taxCreditAppliedOnRefund = Value2;
      } else {
        taxCreditAppliedOnRefund = Value1;
      }
    }
    americanOpportunityData[year]['Tax Credit Applied On Refund'] = taxCreditAppliedOnRefund;

    // Tax Credit Applied
    taxCreditApplied = americanOpportunityData[year]['Tax Credit Applied Before Refund'] + americanOpportunityData[year]['Tax Credit Applied On Refund'];
    americanOpportunityData[year]['Tax Credit Applied'] = taxCreditApplied;

    /** Lifetime Learning * */
    lifetimeLearningData[year] = {};

    // Independent
    lifetimeLearningData[year].Independent = independent;

    // American Opportunity
    if ( americanOpportunityData[year]['Tax Credit Applied'] > 0 ) {
      americanOpportunity = 0;
    } else {
      americanOpportunity = 1;
    }
    lifetimeLearningData[year]['American Opportunity'] = americanOpportunity;

    // Credit before Phaseouts
    if ( lifetimeLearningData[year].Independent === 1
      && lifetimeLearningData[year]['American Opportunity'] === 1 ) {
      if ( incomeStatementData !== undefined
        && incomeStatementData[year] !== undefined
        && incomeStatementData[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined
        && incomeStatementData[year].livingExpenses['Higher Education Room & Board'] !== undefined ) {
        const Value = -( incomeStatementData[year].livingExpenses['Higher Education Tuition and Fees'] + incomeStatementData[year].livingExpenses['Higher Education Room & Board'] );
        if ( Value >= 10000 ) {
          lifetimeLearningCreditBefore = 10000 * ( 20 / 100 );
        } else if ( Value > 0 && Value < 10000 ) {
          lifetimeLearningCreditBefore = Value * ( 20 / 100 );
        } else {
          lifetimeLearningCreditBefore = 0;
        }
      } else {
        lifetimeLearningCreditBefore = 0;
      }
    } else {
      lifetimeLearningCreditBefore = 0;
    }
    lifetimeLearningData[year]['Credit before'] = lifetimeLearningCreditBefore;

    // Phaseout Reduction
    if ( module10Data !== undefined && module10Data.marriege_year !== undefined && module10Data.marriege_year > 0
      && year >= module10Data.marriege_year ) {
      if ( taxStatementData !== undefined
        && taxStatementData[year] !== undefined
        && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined ) {
        const Value = taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
        if ( Value <= lifetimeLearningCredit[year].Married ) {
          lifetimePhaseOutReduction = 0;
        } else if ( Value > lifetimeLearningCredit[year].Married
          && Value < lifetimeLearningCredit[year].Married2 ) {
          if ( lifetimeLearningData[year]['Credit before'] > 0 ) {
            lifetimePhaseOutReduction = -( Value - lifetimeLearningCredit[year].Married ) / ( 20000 * lifetimeLearningData[year]['Credit before'] );
          } else {
            lifetimePhaseOutReduction = 0;
          }
        } else {
          lifetimePhaseOutReduction = -( 100 / 100 ) * lifetimeLearningData[year]['Credit before'];
        }
      } else {
        lifetimePhaseOutReduction = 0;
      }
    } else if ( taxStatementData !== undefined
      && taxStatementData[year] !== undefined
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined ) {
      const Value = taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
      if ( Value <= lifetimeLearningCredit[year].Single ) {
        lifetimePhaseOutReduction = 0;
      } else if ( Value > lifetimeLearningCredit[year].Single
        && Value < lifetimeLearningCredit[year].Single2 ) {
        if ( lifetimeLearningData[year]['Credit before'] > 0 ) {
          lifetimePhaseOutReduction = -( Value - lifetimeLearningCredit[year].Single ) / ( 10000 * lifetimeLearningData[year]['Credit before'] );
        } else {
          lifetimePhaseOutReduction = 0;
        }
      } else {
        lifetimePhaseOutReduction = -( 100 / 100 ) * lifetimeLearningData[year]['Credit before'];
      }
    } else {
      lifetimePhaseOutReduction = 0;
    }
    lifetimeLearningData[year]['Phaseout Reduction'] = lifetimePhaseOutReduction;

    // Available Tax Credit
    lifetimeLearningAvailableTaxCredit = lifetimeLearningData[year]['Credit before']
      + lifetimeLearningData[year]['Phaseout Reduction'];
    lifetimeLearningData[year]['Available Tax Credit'] = lifetimeLearningAvailableTaxCredit;

    // Federal Income Taxes
    if ( taxStatementData !== undefined
      && taxStatementData[year] !== undefined
      && taxStatementData[year]['Federal Taxable Income'] !== undefined
      && taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'] !== undefined ) {
      federalIncomeTaxes = ( taxStatementData[year]['Federal Taxable Income'] / 100 ) * taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'];
    } else {
      federalIncomeTaxes = 0;
    }
    lifetimeLearningData[year]['Federal Income Taxes'] = federalIncomeTaxes;

    // Tax Credit Applied
    if ( lifetimeLearningData[year]['Available Tax Credit'] > lifetimeLearningData[year]['Federal Income Taxes'] ) {
      lifetimeLearningTaxCreditApplied = lifetimeLearningData[year]['Federal Income Taxes'];
    } else {
      lifetimeLearningTaxCreditApplied = lifetimeLearningData[year]['Available Tax Credit'];
    }
    lifetimeLearningData[year]['Tax Credit Applied'] = lifetimeLearningTaxCreditApplied;

    // Child Tax Credit Array
    childTaxCredit['Credit per Child'] = 2000;
    childTaxCredit['Minimum Income Threshold'] = 2500;
    childTaxCredit['Maximum Refund'] = 1400;
    childTaxCredit.Inflation = 2;
    childTaxCredit['Single Phaseout Ranges1'] = 200000;
    childTaxCredit['Single Phaseout Ranges2'] = 240000;
    childTaxCredit['Married Phaseout Ranges1'] = 400000;
    childTaxCredit['Married Phaseout Ranges2'] = 440000;
    childTaxCredit['Phasing Units'] = 1000;
    childTaxCredit['Reduction per unit'] = 50;

    childTaxCredit[year] = {};
    childTaxCredit[year]['Child Tax Credit'] = {};
	if ( module10Data.first_born !== undefined && module10Data.first_born > 0 && year >= module10Data.first_born ) {
      firstChildren = 1;
    }
    if ( module10Data.second_born !== undefined && module10Data.second_born > 0 && year >= module10Data.second_born ) {
      secondChildren = 1;
    }
    if ( module10Data.third_born !== undefined && module10Data.third_born > 0 && year >= module10Data.third_born ) {
      thirdChildren = 1;
    }
    if ( module10Data.fourth_born !== undefined && module10Data.fourth_born > 0 && year >= module10Data.fourth_born ) {
      fourthChildren = 1;
    }
    if ( module10Data.fifth_born !== undefined && module10Data.fifth_born > 0 && year >= module10Data.fifth_born ) {
      fifthChildren = 1;
    }
    if ( module10Data.sixth_born !== undefined && module10Data.sixth_born > 0 && year >= module10Data.sixth_born ) {
      sixthChildren = 1;
    }
    if ( module10Data.seventh_born !== undefined && module10Data.seventh_born > 0 && year >= module10Data.seventh_born ) {
      seventhChildren = 1;
    }
    childTaxCredit[year]['Child Tax Credit'].Children = firstChildren + secondChildren + thirdChildren + fourthChildren + fifthChildren + sixthChildren + seventhChildren;
    // Credit before Phaseouts
    childTaxCredit[year]['Child Tax Credit']['Credit before Phaseouts'] = childTaxCredit[year]['Child Tax Credit'].Children * childTaxCredit['Credit per Child'];

    // Total Gross Income (excluding Net Rental Income)
    if ( taxStatementData !== undefined
      && taxStatementData[year] !== undefined
      && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined ) {
      totalGrossIncomeValue1 = taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
    }
    childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] = totalGrossIncomeValue1;

    // Phaseout Reduction
    if ( childTaxCredit[year]['Child Tax Credit']['Credit before Phaseouts'] > 0 ) {
      if ( module10Data.marriege_year !== undefined && year >= module10Data.marriege_year ) {
        if ( childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] <= childTaxCredit['Married Phaseout Ranges1'] ) {
          childPhaseoutReduction = 0;
        } else if ( childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] > childTaxCredit['Married Phaseout Ranges1']
          && childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] <= childTaxCredit['Married Phaseout Ranges2'] ) {
          const coef = 10 ** -3;
          childPhaseoutReduction1 = -( Math.floor( ( childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] - childTaxCredit['Married Phaseout Ranges1'] ) * coef ) ) / coef;
          childPhaseoutReduction = ( childPhaseoutReduction1 / childTaxCredit['Phasing Units'] ) * childTaxCredit['Reduction per unit'] * childTaxCredit[year]['Child Tax Credit'].Children;
        } else {
          childPhaseoutReduction = -childTaxCredit[year]['Child Tax Credit']['Credit before Phaseouts'];
        }
      } else if ( childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] <= childTaxCredit['Single Phaseout Ranges1'] ) {
        childPhaseoutReduction = 0;
      } else if ( childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] > childTaxCredit['Single Phaseout Ranges1'] && childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] <= childTaxCredit['Single Phaseout Ranges2'] ) {
        const coef1 = 10 ** -3;
        childPhaseoutReduction2 = -( Math.floor( ( childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] - childTaxCredit['Single Phaseout Ranges1'] ) * coef1 ) ) / coef1;
        childPhaseoutReduction = ( childPhaseoutReduction2 / childTaxCredit['Phasing Units'] ) * childTaxCredit['Reduction per unit'] * childTaxCredit[year]['Child Tax Credit'].Children;
      } else {
        childPhaseoutReduction = -childTaxCredit[year]['Child Tax Credit']['Credit before Phaseouts'];
      }
    } else {
      childPhaseoutReduction = 0;
    }
    childTaxCredit[year]['Child Tax Credit']['Phaseout Reduction'] = childPhaseoutReduction;

    // Available Tax Credit
    childAvailableTaxCredit = childTaxCredit[year]['Child Tax Credit']['Credit before Phaseouts'] + childTaxCredit[year]['Child Tax Credit']['Phaseout Reduction'];
    childTaxCredit[year]['Child Tax Credit']['Available Tax Credit'] = childAvailableTaxCredit;

    // Total Taxes
    if ( taxStatementData !== undefined
      && taxStatementData !== ''
      && taxStatementData[year] !== undefined
      && taxStatementData[year] !== ''
      && taxStatementData[year]['Federal Taxable Income'] !== undefined
      && taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'] !== undefined ) {
      childTotalTaxes = ( taxStatementData[year]['Federal Taxable Income'] / 100 ) * taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'];
    }
    childTaxCredit[year]['Child Tax Credit']['Total Taxes'] = childTotalTaxes;

    // Tax Credit Applied before Refund
    if ( childAvailableTaxCredit > childTotalTaxes ) {
      childTaxCreditAppliedBeforeRefund = childTotalTaxes;
    } else {
      childTaxCreditAppliedBeforeRefund = childAvailableTaxCredit;
    }
    childTaxCredit[year]['Child Tax Credit']['Tax Credit Applied before Refund'] = childTaxCreditAppliedBeforeRefund;

    // Tax Credit Applied as Refund
    if ( childTaxCredit[year]['Child Tax Credit']['Total Gross Income'] >= childTaxCredit['Minimum Income Threshold'] ) {
      if ( childAvailableTaxCredit > childTaxCreditAppliedBeforeRefund ) {
        if ( ( childAvailableTaxCredit - childTaxCreditAppliedBeforeRefund ) > ( childTaxCredit['Maximum Refund'] * childTaxCredit[year]['Child Tax Credit'].Children ) ) {
          childTaxCreditAppliedAsRefund = childTaxCredit['Maximum Refund'] * childTaxCredit[year]['Child Tax Credit'].Children;
        } else {
          childTaxCreditAppliedAsRefund = childAvailableTaxCredit - childTaxCreditAppliedBeforeRefund;
        }
      } else {
        childTaxCreditAppliedAsRefund = 0;
      }
    } else {
      childTaxCreditAppliedAsRefund = 0;
    }
    childTaxCredit[year]['Child Tax Credit']['Tax Credit Applied as Refund'] = childTaxCreditAppliedAsRefund;

    // Total Tax Credit Applied
    childTaxCredit[year]['Child Tax Credit']['Total Tax Credit Applied'] = childTaxCreditAppliedBeforeRefund + childTaxCreditAppliedAsRefund;

    // Minimum Income Threshold
    if ( incomeStatementData !== undefined && incomeStatementData[year] !== undefined && incomeStatementData[year]['Total Income'] !== undefined && incomeStatementData[year]['Total Income'] !== '' ) {
      minimumIncomeThreshold = incomeStatementData[year]['Total Income'];
    } else {
      minimumIncomeThreshold = 0;
    }
    childTaxCredit[year]['Child Tax Credit']['Minimum Income Threshold'] = minimumIncomeThreshold;
    // Maximum Refund Values
    if ( childTaxCredit[year]['Child Tax Credit']['Minimum Income Threshold'] > childTaxCredit['Minimum Income Threshold'] ) {
      conditionValuefifteen = ( ( childTaxCredit[year]['Child Tax Credit']['Minimum Income Threshold'] - childTaxCredit['Minimum Income Threshold'] ) / 100 ) * 15;
      conditionValueMath = childTaxCredit['Maximum Refund'] * ( ( 1 + ( data.Inflation / 100 ) ) ** ( year - currentYear ) );
      if ( conditionValuefifteen > conditionValueMath ) {
        maximumRefundValues = conditionValueMath;
      } else {
        maximumRefundValues = conditionValuefifteen;
      }
    }
    childTaxCredit[year]['Child Tax Credit']['Maximum Refund Values'] = maximumRefundValues;
    // c += 1;
  }

  // -------------- Years List Start-----------------------------------//
  // -------------- Years List -----------------------------------//
  // -------------- Years List -----------------------------------//
  /* HIGHER ED First 1 Coloum years (Financial Aid) */
  studentLoanLimits['Higher Ed']['HE Other Financial Aid'] = [0];
  // below line is added by chandrakala to remove error in 2334 line cannot push of undefined
  studentLoanLimits['Higher Ed']['HE Family Contribution'] = [0];
  if ( module2Data.year_aid_begins !== undefined && module2Data.year_aid_begins !== '' && module2Data.years_diff !== undefined && module2Data.years_diff !== '' && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' ) {
    const years = parseInt( module2Data.year_aid_begins, 10 );
    // below commented line is not used anywhere so commented
    // const diff = parseInt(module2Data.years_diff, 10);
    for ( let HMi = years; HMi <= parseInt( module2Data.graduation_year, 10 ); HMi += 1 ) {
      studentLoanLimits['Higher Ed']['HE Other Financial Aid'].push( HMi );
      studentLoanLimits['Higher Ed']['HE Family Contribution'].push( HMi );
    }
  }

  /* HIGHER ED First 2 Coloum years (Income Earned) */
  studentLoanLimits['Higher Ed']['HE Income'] = [0];
  if ( module2Data.income_start_year !== undefined && module2Data.income_start_year !== '' && module2Data.years_diff !== undefined && module2Data.years_diff !== '' && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' ) {
    const InHyears = parseInt( module2Data.income_start_year, 10 );
    for ( let InHMi = InHyears; InHMi <= parseInt( module2Data.graduation_year, 10 ); InHMi += 1 ) {
      studentLoanLimits['Higher Ed']['HE Income'].push( InHMi );
    }
  }

  /* HIGHER ED First 3 Coloum years (Family Contribution) */
  studentLoanLimits['Higher Ed']['HE Family Contribution'] = [0];
  if ( module2Data.family_start_year !== undefined && module2Data.family_start_year !== '' && module2Data.years_diff !== undefined && module2Data.years_diff !== '' && module2Data.graduation_year !== undefined && module2Data.graduation_year !== '' ) {
    const FHyears = parseInt( module2Data.family_start_year, 10 );
    for ( let FHMi = FHyears; FHMi <= parseInt( module2Data.graduation_year, 10 ); FHMi += 1 ) {
      studentLoanLimits['Higher Ed']['HE Family Contribution'].push( FHMi );
    }
  }

  /* HIGHER ED First 4 Coloums years(On campus) */
  studentLoanLimits['Higher Ed']['HE On Campus'] = [0];
  if ( module2Data.off_campus_start_year !== undefined && module2Data.start_year !== undefined && module2Data.start_year !== ''
    && module2Data.years_diff !== undefined && module2Data.years_diff !== '' ) {
    const offCampusStartYear = parseInt( module2Data.off_campus_start_year, 10 );
    const HestartYear = parseInt( module2Data.start_year, 10 );
    const diff = parseInt( module2Data.years_diff, 10 );
    let insertYear = 0;
    for ( let HFi = 0; HFi < diff; HFi += 1 ) {
      if ( HFi === 0 ) {
        if ( offCampusStartYear > 0 && offCampusStartYear === HestartYear ) {
          insertYear = HestartYear + HFi + 1;
        } else {
          insertYear = HestartYear + HFi;
        }
      } else if ( offCampusStartYear > HestartYear || insertYear + 1 === offCampusStartYear || insertYear + 1 > parseInt( module2Data.graduation_year, 10 ) ) {
        insertYear = '';
        break;
      } else {
        insertYear += 1;
      }
      if ( insertYear !== '' ) {
        studentLoanLimits['Higher Ed']['HE On Campus'].push( insertYear );
      }
    }
  }

  /* HIGHER ED First 5 Coloums years(Off campus) */
  studentLoanLimits['Higher Ed']['HE Off Campus'] = [0];
  if ( module2Data.off_campus_start_year !== undefined && module2Data.start_year !== undefined && module2Data.start_year !== '' && module2Data.years_diff !== undefined && module2Data.years_diff !== '' ) {
    const onCampusStartYear = parseInt( module2Data.on_campus_start_year, 10 );
    const HestartYear = parseInt( module2Data.start_year, 10 );
    const diff = parseInt( module2Data.years_diff, 10 );
    let onHInsertYear = 0;
    for ( let Hoi = 0; Hoi < diff; Hoi += 1 ) {
      if ( Hoi === 0 ) {
        if ( onCampusStartYear > 0 && onCampusStartYear === HestartYear ) {
          onHInsertYear = HestartYear + Hoi + 1;
        } else {
          onHInsertYear = HestartYear + Hoi;
        }
      } else if ( onCampusStartYear > HestartYear || onHInsertYear + 1 === onCampusStartYear || onHInsertYear + 1 > parseInt( module2Data.graduation_year, 10 ) ) {
        onHInsertYear = '';
        break;
      } else {
        onHInsertYear += 1;
      }
      if ( onHInsertYear !== '' ) {
        studentLoanLimits['Higher Ed']['HE Off Campus'].push( onHInsertYear );
      }
    }
  }

  studentLoanLimits['More Higher Ed']['MHE Other Financial Aid'] = [0];
  if ( module6Data.year_aid_begins !== undefined && module6Data.year_aid_begins !== '' && module6Data.years_diff !== undefined && module6Data.years_diff !== '' && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' ) {
    const years = parseInt( module6Data.year_aid_begins, 10 );
    for ( let HMi = years; HMi <= parseInt( module6Data.graduation_year, 10 ); HMi += 1 ) {
      studentLoanLimits['More Higher Ed']['MHE Other Financial Aid'].push( HMi );
    }
  }

  /* HIGHER ED First 2 Coloum years (Income Earned) */
  studentLoanLimits['More Higher Ed']['MHE Income'] = [0];
  if ( module6Data.income_start_year !== undefined && module6Data.income_start_year !== '' && module6Data.years_diff !== undefined && module6Data.years_diff !== '' && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' ) {
    const InHyears = parseInt( module6Data.income_start_year, 10 );
    for ( let InHMi = InHyears; InHMi <= parseInt( module6Data.graduation_year, 10 ); InHMi += 1 ) {
      studentLoanLimits['More Higher Ed']['MHE Income'].push( InHMi );
    }
  }

  /* HIGHER ED First 3 Coloum years (Family Contribution) */
  studentLoanLimits['More Higher Ed']['MHE Family Contribution'] = [0];
  if ( module6Data.family_start_year !== undefined && module6Data.family_start_year !== '' && module6Data.years_diff !== undefined && module6Data.years_diff !== '' && module6Data.graduation_year !== undefined && module6Data.graduation_year !== '' ) {
    const FHyears = parseInt( module6Data.family_start_year, 10 );
    for ( let FHMi = FHyears; FHMi <= parseInt( module6Data.graduation_year, 10 ); FHMi += 1 ) {
      studentLoanLimits['More Higher Ed']['MHE Family Contribution'].push( FHMi );
    }
  }

  /* More HIGHER ED First 4 Coloums years(On campus) */
  studentLoanLimits['More Higher Ed']['MHE On Campus'] = [0];
  if ( module6Data.start_year !== undefined && module6Data.start_year !== undefined && module6Data.start_year !== '' && module6Data.years_diff !== undefined && module6Data.years_diff !== '' ) {
    const MoffCampusStartYear = parseInt( module6Data.off_campus_start_year, 10 );
    const MestartYear = parseInt( module6Data.start_year, 10 );
    const Mdiff = parseInt( module6Data.years_diff, 10 );
    let moreinsertYear = 0;
    for ( let MFi = 0; MFi < Mdiff; MFi += 1 ) {
      if ( MFi === 0 ) {
        if ( MoffCampusStartYear > 0 && MoffCampusStartYear === MestartYear ) {
          moreinsertYear = MestartYear + MFi + 1;
        } else {
          moreinsertYear = MestartYear + MFi;
        }
      } else if ( MoffCampusStartYear > MestartYear || moreinsertYear + 1 === MoffCampusStartYear || moreinsertYear + 1 > parseInt( module6Data.graduation_year, 10 ) ) {
        moreinsertYear = '';
        break;
      } else {
        moreinsertYear += 1;
      }
      if ( moreinsertYear !== '' ) {
        studentLoanLimits['More Higher Ed']['MHE On Campus'].push( moreinsertYear );
      }
    }
  }
    /* More HIGHER ED First 5 Coloums years(Off campus) */
    studentLoanLimits['More Higher Ed']['MHE Off Campus'] = [0];
    if ( module6Data.startYear !== undefined && module6Data.start_year !== undefined && module6Data.start_year !== '' && module6Data.years_diff !== undefined && module6Data.years_diff !== '' ) {
      const MOnCampusStartYear = parseInt( module6Data.on_campus_start_year, 10 );
      const MestartYear = parseInt( module6Data.start_year, 10 );
      const Mdiff = parseInt( module6Data.years_diff, 10 );
      let moreONinsertYear = 0;
      for ( let Moi = 0; Moi < Mdiff; Moi += 1 ) {
        if ( Moi === 0 ) {
          if ( MOnCampusStartYear > 0 && MOnCampusStartYear === MestartYear ) {
            moreONinsertYear = MestartYear + Moi + 1;
          } else {
            moreONinsertYear = MestartYear + Moi;
          }
        } else if ( MOnCampusStartYear > MestartYear || moreONinsertYear + 1 === MOnCampusStartYear || moreONinsertYear + 1 > parseInt( module6Data.graduation_year, 10 ) ) {
          moreONinsertYear = '';
          break;
        } else {
          moreONinsertYear += 1;
        }
        if ( moreONinsertYear !== '' ) {
          studentLoanLimits['More Higher Ed']['MHE Off Campus'].push( moreONinsertYear );
        }
      }
    }

  studentLoanLimits['Further Higher Ed']['FHE Other Financial Aid'] = [0];
  if ( module15Data.year_aid_begins !== undefined && module15Data.year_aid_begins !== '' && module15Data.years_diff !== undefined && module15Data.years_diff !== '' && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' ) {
    const years = parseInt( module15Data.year_aid_begins, 10 );
    for ( let HMi = years; HMi <= parseInt( module15Data.graduation_year, 10 ); HMi += 1 ) {
      studentLoanLimits['Further Higher Ed']['FHE Other Financial Aid'].push( HMi );
    }
  }

  /* HIGHER ED First 2 Coloum years (Income Earned) */
  studentLoanLimits['Further Higher Ed']['FHE Income'] = [0];
  if ( module15Data.income_start_year !== undefined && module15Data.income_start_year !== '' && module15Data.years_diff !== undefined && module15Data.years_diff !== '' && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' ) {
    const InHyears = parseInt( module15Data.income_start_year, 10 );
    for ( let InHMi = InHyears; InHMi <= parseInt( module15Data.graduation_year, 10 ); InHMi += 1 ) {
      studentLoanLimits['Further Higher Ed']['FHE Income'].push( InHMi );
    }
  }

  /* HIGHER ED First 3 Coloum years (Family Contribution) */
  studentLoanLimits['Further Higher Ed']['FHE Family Contribution'] = [0];
  if ( module15Data.family_start_year !== undefined && module15Data.family_start_year !== '' && module15Data.years_diff !== undefined && module15Data.years_diff !== '' && module15Data.graduation_year !== undefined && module15Data.graduation_year !== '' ) {
    const FHyears = parseInt( module15Data.family_start_year, 10 );
    for ( let FHMi = FHyears; FHMi <= parseInt( module15Data.graduation_year, 10 ); FHMi += 1 ) {
      studentLoanLimits['Further Higher Ed']['FHE Family Contribution'].push( FHMi );
    }
  }

  /* Further HIGHER ED First 5 Coloums years(On campus) */
  studentLoanLimits['Further Higher Ed']['FHE On Campus'] = [0];
  if ( module15Data.start_year !== undefined && module15Data.start_year !== undefined && module15Data.start_year !== '' && module15Data.years_diff !== undefined && module15Data.years_diff !== '' ) {
    const offCampusStartYear = parseInt( module15Data.off_campus_start_year, 10 );
    const HestartYear = parseInt( module15Data.start_year, 10 );
    const diff = parseInt( module15Data.years_diff, 10 );
    let FurtherOninsertYear = 0;
    for ( let Foi = 0; Foi < diff; Foi += 1 ) {
      if ( Foi === 0 ) {
        if ( offCampusStartYear > 0 && offCampusStartYear === HestartYear ) {
          FurtherOninsertYear = HestartYear + Foi + 1;
        } else {
          FurtherOninsertYear = HestartYear + Foi;
        }
      } else if ( offCampusStartYear > HestartYear || FurtherOninsertYear + 1 === offCampusStartYear || FurtherOninsertYear + 1 > parseInt( module15Data.graduation_year, 10 ) ) {
        FurtherOninsertYear = '';
        break;
      } else {
        FurtherOninsertYear += 1;
      }
      if ( FurtherOninsertYear !== '' ) {
        studentLoanLimits['Further Higher Ed']['FHE On Campus'].push( FurtherOninsertYear );
      }
    }
  }
    studentLoanLimits['Further Higher Ed']['FHE Off Campus'] = [0];
    if ( module15Data.startYear !== undefined && module15Data.start_year !== undefined && module15Data.start_year !== '' && module15Data.years_diff !== undefined && module15Data.years_diff !== '' ) {
      const FonCampusStartYear = parseInt( module15Data.on_campus_start_year, 10 );
      const HestartYear = parseInt( module15Data.start_year, 10 );
      const diff = parseInt( module15Data.years_diff, 10 );
      let FurtherinsertYear = 0;
      for ( let FFi = 0; FFi < diff; FFi += 1 ) {
        if ( FFi === 0 ) {
          if ( FonCampusStartYear > 0 && FonCampusStartYear === HestartYear ) {
            FurtherinsertYear = HestartYear + FFi + 1;
          } else {
            FurtherinsertYear = HestartYear + FFi;
          }
        } else if ( FonCampusStartYear > HestartYear || FurtherinsertYear + 1 === FonCampusStartYear || FurtherinsertYear + 1 > parseInt( module15Data.graduation_year, 10 ) ) {
          FurtherinsertYear = '';
          break;
        } else {
          FurtherinsertYear += 1;
        }
        if ( FurtherinsertYear !== '' ) {
          studentLoanLimits['Further Higher Ed']['FHE Off Campus'].push( FurtherinsertYear );
        }
      }
    }

  /* Career Path Supplementary income */
  studentLoanLimits['Career Path']['Supp Income'] = [0];
  if ( module4Data.income_end_year !== undefined && module4Data.income_end_year !== '' && module4Data.supplementary_start_year !== undefined
    && module4Data.supplementary_start_year !== '' ) {
    for ( let CSY = module4Data.supplementary_start_year; CSY <= module4Data.income_end_year; CSY += 1 ) {
      studentLoanLimits['Career Path']['Supp Income'].push( CSY );
    }
  }

  /* Career Advancement Supplementary income */
  studentLoanLimits['Career Adv']['Supp Income'] = [0];
  if ( module5Data.income_end_year !== undefined && module5Data.income_end_year !== ''
    && module5Data.supplementary_start_year !== undefined && module5Data.supplementary_start_year !== '' ) {
    for ( let CASY = module5Data.supplementary_start_year; CASY <= module5Data.income_end_year; CASY += 1 ) {
      studentLoanLimits['Career Adv']['Supp Income'].push( CASY );
    }
  }

  /* Additiona Career Advancement Supplementary income */
  studentLoanLimits['Additional Career Adv']['Supp Income'] = [0];
  if ( module8Data.end_year !== undefined && module8Data.end_year !== '' && module8Data.supplementaryIncomeStartYear !== undefined
    && module8Data.supplementaryIncomeStartYear !== '' ) {
    for ( let ACASY = module8Data.supplementaryIncomeStartYear; ACASY <= module8Data.end_year; ACASY += 1 ) {
      studentLoanLimits['Additional Career Adv']['Supp Income'].push( ACASY );
    }
  }

  /* Further Career Advancement Supplementary income Years */
  studentLoanLimits['Further Career Adv']['Supp Income'] = [0];
  if ( module17Data.end_year !== undefined && module17Data.end_year !== '' && module17Data.supplementaryIncomeStartYear !== undefined && module17Data.supplementaryIncomeStartYear !== '' ) {
    for ( let FCASY = module17Data.supplementaryIncomeStartYear; FCASY <= module17Data.end_year; FCASY += 1 ) {
      studentLoanLimits['Further Career Adv']['Supp Income'].push( FCASY );
    }
  }

  // -------------- Years List End -----------------------------------//\
  // -------------- Years List Conditions Completed -----------------------------------//
  function databaseCompleteData() {
    const databaseCompleteDataObj = {};
    databaseCompleteDataObj.Data = data;
    databaseCompleteDataObj['Children Info'] = childrenInfo;
    databaseCompleteDataObj['Student Loans Limit'] = studentLoanLimits;
    databaseCompleteDataObj['Personal Exemptions'] = personalExemptionsData;
    databaseCompleteDataObj['American Opportunity'] = americanOpportunityData;
    databaseCompleteDataObj['Lifetime Learning'] = lifetimeLearningData;
    databaseCompleteDataObj['Child Tax Credit'] = childTaxCredit;
    databaseCompleteDataObj['Deduction Limit'] = deductionLimit;
    databaseCompleteDataObj['Passive Loss Max Limit'] = passiveLossMaxLimit;
    databaseCompleteDataObj['Social Security Income Tax Limit'] = socialSecurityIncomeTaxLimit;
    databaseCompleteDataObj['Medicare Taxes'] = medicareTaxData;
    databaseCompleteDataObj['Standard Deductions'] = standardDeductions;
    databaseCompleteDataObj['AOTC Tax Credit Phaseout'] = aotcTaxCreditPhaseOut;
    databaseCompleteDataObj['Lifetime Learning Credit'] = lifetimeLearningCredit;
    databaseCompleteDataObj['401(k) 2018 Contribution Limits'] = kContributionsLimits;
    databaseCompleteDataObj['Roth IRA 2018 Contribution Limit'] = rothIraContributionsLimits;
    databaseCompleteDataObj['SEP IRA Contribution Limit'] = sepIraContributionsLimits;
    databaseCompleteDataObj['Pension 2018 Contribution Limit'] = pensionContributionsLimits;
    databaseCompleteDataObj['Roth IRA Income Limits'] = rothIraIncomeLimits;
	databaseCompleteDataObj['HSA Limits']   	= hsaIncomeLimits;
	return databaseCompleteDataObj;
  }
  // below function is added by chandrakala to console the output
  return databaseCompleteData();
}

export default database;