import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import * as commonFunctions from '../../utilities/commonFunctions';
import styles from '../../screens/pwi-investments/styles';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const giftOptions = [
  {
    value: 0,
    scaledValue: 0,
    label: '0k',
  },
  {
    value: 3000,
    scaledValue: 3000,
    label: '3k',
  },
  {
    value: 6000,
    scaledValue: 6000,
    label: '6k',
  },
  {
    value: 9000,
    scaledValue: 9000,
    label: '9k',
  },
  {
    value: 12000,
    scaledValue: 12000,
    label: '12k',
  },
  {
    value: 15000,
    scaledValue: 15000,
    label: '15k',
  },
];

const FinancialGift = (props) => {
  const {
    classes, getInvestmentObject, handleInvestmentData,
  } = props;

  let currentYear = modulesServices.module1Data.start_year; 
  const d = new Date();
  if (currentYear === undefined || currentYear === '' || currentYear <= 0) {
    currentYear = d.getFullYear();
  }
  const yearOptions = [];
  for (let year = currentYear; year < (currentYear + 15); year += 2) {
    yearOptions.push({ value: year, label: year });
  }
  const [startYearValue, setStartYearValue] = React.useState('');
  const [endYearValue, setEndYearValue] = React.useState('');
  const [giftValue, setGiftValue] = React.useState(getInvestmentObject.yearly_financial_gift === undefined ? 0 : getInvestmentObject.yearly_financial_gift);

  useEffect(() => {
    const investData = { ...getInvestmentObject };

    let sliderStartYear = parseInt(modulesServices.module1Data.start_year, 10);
    if (sliderStartYear === '') {
      sliderStartYear = d.getFullYear();
    }
    const giftStartYear = sliderStartYear;
    const giftEndYear = giftStartYear;
    if (getInvestmentObject.financial_gift_start_year === undefined || getInvestmentObject.financial_gift_start_year === '' || getInvestmentObject.financial_gift_start_year <= 0) {
      investData.financial_gift_start_year = giftStartYear;
    }
    if (getInvestmentObject.financial_gift_end_year === undefined || getInvestmentObject.financial_gift_end_year === '' || getInvestmentObject.financial_gift_end_year <= 0) {
      investData.financial_gift_end_year = giftEndYear;
    }
    if (investData.financial_gift_end_year < investData.financial_gift_start_year) {
      investData.financial_gift_end_year = investData.financial_gift_start_year;
    }
    setStartYearValue(investData.financial_gift_start_year);
    setEndYearValue(investData.financial_gift_end_year);
    if (getInvestmentObject.yearly_financial_gift === undefined) {
      investData.yearly_financial_gift = 0;
    }
    handleInvestmentData(investData);
    // eslint-disable-next-line
  }, []);

  const updateValue = (e, value, field, type) => {
    const beginingBalanceObj = { ...getInvestmentObject };

    let newvalue = 0;
    if (type === 'slider') {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    beginingBalanceObj[field] = newvalue;
    switch (field) {
      case 'financial_gift_start_year': {
        setStartYearValue(newvalue);
        if (newvalue > endYearValue) {
          setEndYearValue(newvalue);
          beginingBalanceObj.financial_gift_end_year = newvalue;
        }
        break;
      }
      case 'financial_gift_end_year': {
        if (parseInt(newvalue, 10) >= startYearValue) {
          setEndYearValue(newvalue);
          beginingBalanceObj.financial_gift_end_year = newvalue;
        } else {
          beginingBalanceObj.financial_gift_end_year = startYearValue;
        }
        break;
      }
      case 'yearly_financial_gift': {
        setGiftValue(newvalue);
        break;
      }
      default: {
        break;
      }
    }
    handleInvestmentData(beginingBalanceObj);
  };

  return (
    <div className={classes.contentBlock}>
      <p>A financial gift could be money received for graduation from family, a Christmas gift, or a birthday gift. If you receive some form of gift money each year or plan to receive gift money in the future, input that value below.</p>
      <p>
        When receiving financial gifts it&apos;s important to remember that there are tax implications. If you want to learn more about how taxes could impact financial gifts, click
        {' '}
        {' '}
        <a href="https://smartasset.com/retirement/gift-tax-limits" target="_blank" rel="noopener noreferrer">
          here
        </a>
        .
      </p>
      <div className={classes.sliderBottomSpace}>
        {startYearValue !== undefined && (
          <div className={classes.giftSlider}>
            <div className={classes.lefBlock}>
              <div className={classes.lefSlider}>
                <div className={classes.sliderName}><p>Start Year</p></div>
                <div className={classes.rzSlider}>
                  <PwiSlider
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={yearOptions}
                    min={currentYear}
                    max={currentYear + 14}
                    step={1}
                    value={startYearValue}
                    onChange={(e, value) => { updateValue(e, value, 'financial_gift_start_year', 'slider'); }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.gitInputs}>
              <div className={classes.annualInput}>
                <p>{Number.isNaN(parseInt(startYearValue, 10)) ? 0 : parseInt(startYearValue, 10)}</p>
              </div>
            </div>
          </div>
        )}
        {endYearValue !== undefined && (
          <div className={classes.giftSlider}>
            <div className={classes.lefBlock}>
              <div className={classes.lefSlider}>
                <div className={classes.sliderName}><p>End Year</p></div>
                <div className={classes.rzSlider}>
                  <PwiSlider
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={yearOptions}
                    min={currentYear}
                    max={currentYear + 14}
                    step={1}
                    value={endYearValue}
                    onChange={(e, value) => { updateValue(e, value, 'financial_gift_end_year', 'slider'); }}
                  />
                </div>
              </div>
            </div>
            <div className={classes.gitInputs}>
              <div className={classes.annualInput}>
                <p>{Number.isNaN(parseInt(endYearValue, 10)) ? 0 : parseInt(endYearValue, 10)}</p>
              </div>
            </div>
          </div>
        )}
        <div className={classes.giftSlider}>
          <div className={classes.lefBlock}>
            <div className={classes.lefSlider}>
              <div className={classes.sliderName}><p>Annual Financial Gift</p></div>
              <div className={classes.rzSlider}>
                <PwiSlider
                  ValueLabelComponent={ValueLabelComponent}
                  aria-labelledby="discrete-slider-custom"
                  valueLabelDisplay="auto"
                  marks={giftOptions}
                  min={0}
                  max={15000}
                  step={100}
                  value={giftValue}
                  onChange={(e, value) => { updateValue(e, value, 'yearly_financial_gift', 'slider'); }}
                  valueLabelFormat={`${commonFunctions.thousandFormatter(giftValue)}`}
                />
              </div>
            </div>
          </div>
          <div className={classes.gitInputs}>
            <div className={classes.annualInput}>
              $
              <NumberFormat decimalScale={0} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={giftValue} onValueChange={(e) => updateValue(e, '', 'yearly_financial_gift', 'number')} onFocus={(e) => { e.target.select(); }} />
            </div>
          </div>
        </div>
        <div className={classes.giftSlider}>
        <div className={classes.lefBlock}>
          <p className={classes.lefBlockPara}>Total Financial Gift</p>
        </div>
        <div className={classes.gitInputs}>
          <p className={classes.lefBlockCount}>
            <span>
              $
              <NumberFormat decimalScale={0} displayType={'text'} fixedDecimalScale allowNegative={false} customInput={TextField} thousandSeparator value={giftValue} />
            </span>
          </p>
        </div>
      </div>
      </div>
    
    </div>
  );
};

FinancialGift.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,
};

export default withStyles(styles)(FinancialGift);
