import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import { loader } from 'graphql.macro';
import { TextField } from '@material-ui/core';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import styles from '../../../screens/pwi-aca/styles';
import * as modulesServices from '../../../calculations/modules-services';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import CircleArrow from '../../../assets/img/career-path/circle-arrow.png';
import NumberFormat from 'react-number-format';

const GET_OCCUPATION_LIST = loader( '../../../graphql/schema/pwi-career-path/occupation-list.graphql');
const LIST_OF_STATES = loader( '../../../graphql/schema/auth/list-of-states.graphql');

const CareerMove = ( props ) => {
  const {
    classes, handleDefinationPopup, handleCareerObject, handleUpdatedObject, setNextButtonDisabled, setSelfEmployment, selfEmployment,
  } = props;
  const [searchOccupationText, setsearchOccupationText] = React.useState('');
  const [occupationList, setOccupationList] = React.useState([]);
  const [statesList, setStatesList] = React.useState([{ label: 'Please select', value: 0 }]);
  const [showOcc, setShowOcc] = React.useState(false);
  const [city, setCity] = React.useState('');
  const [stateValue, setStateValue] = React.useState({ label: 'Please select', value: 0 });
  const [estimatedStateTax, setEstimatedStateTax] = React.useState(0.00);
  const [estimatedLocaltax, setEstimatedLocalTax] = React.useState(1.50);
  const [clickOcc, setClickOcc] = React.useState(false);
  const [isFocus, setIsFocus] = React.useState('');

  useQuery(LIST_OF_STATES, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const statesListData = _.map(response.States ? response.States : [], (state) => ({ label: state.state_name, value: state.id, code: state.state_code}));
      setStatesList([...statesList, ...statesListData]);
      if (handleCareerObject.state_id !== undefined && handleCareerObject.state_id !== 0 && statesListData.length > 1) {
        let inputValues = {...handleCareerObject};
        setStateValue(statesListData[[handleCareerObject.state_id]-1]);
        inputValues.state_code = statesListData[[handleCareerObject.state_id]-1].code;
        if (modulesServices.databaseData.Data !== undefined) {
          const taxes = modulesServices.databaseData.Data.State;
          let stateName = statesListData[[handleCareerObject.state_id]-1].label
          if (taxes[stateName] !== undefined && stateName !== '') {
            setEstimatedStateTax(parseFloat(taxes[stateName] * 100).toFixed(2));
            inputValues.state_tax = parseFloat(taxes[stateName] * 100).toFixed(2);
          } else {
            setEstimatedStateTax(0);
            inputValues.state_tax = 0;
          }
        }
        handleUpdatedObject(inputValues);
      }
    },
  });
 

  const { data } = useQuery(GET_OCCUPATION_LIST, {
    variables: {
      name: searchOccupationText,
    },
    onCompleted() {
      if (clickOcc) {
        if (searchOccupationText.length > 3) {
          if (clickOcc === false) {
            setShowOcc(false);
          } else {
            setShowOcc(true);
          }
          setOccupationList(data.getOccupationsList);
        } else {
          setOccupationList([]);
          setShowOcc(false);
        }
      }
    },
    onError() {
      setOccupationList([]);
      setShowOcc(false);
    },
  });

  

  const handleOccupationSelect = (value) => {
    const updatedObj = { ...handleCareerObject };
    setsearchOccupationText(value);
    setShowOcc(false);
    setClickOcc(false);
    updatedObj.occupation = value;
    handleUpdatedObject(updatedObj);
  };

  const updateValue = (event, field) => {
    const updatedObj = { ...handleCareerObject };
    let newValue = '';
    if (field === 'self_employment') {
      setSelfEmployment(event);
      newValue = event;
    } else if (field === 'state_id') {
      updatedObj.state_code = event.code;
      if (modulesServices.databaseData.Data !== undefined) {
        const taxes = modulesServices.databaseData.Data.State;
        if (taxes[event.label] !== undefined && event.label !== '') {
          setEstimatedStateTax(parseFloat(taxes[event.label] * 100).toFixed(2));
          updatedObj.state_tax = parseFloat(taxes[event.label] * 100).toFixed(2);
        } else {
          setEstimatedStateTax(0);
          updatedObj.state_tax = 0;
        }
      }
      setStateValue(event);
      newValue = event.value;
    } else if (field === 'occupation') {
      setClickOcc(true);
      setsearchOccupationText(event.target.value);
      newValue = event.target.value;
    } else if (field === 'city') {
      setCity(event.target.value);
      newValue = event.target.value;
    } else if (field === 'state_tax') {
      newValue = event.target.value;
      if (event.target.value > 100) {
        newValue = 100;
      }
      if(event.target.value === ''){
        newValue = 0;
      }
      setEstimatedStateTax(newValue);
    } else if (field === 'city_taxes') {
      newValue = event.target.value;
      if (event.target.value > 100) {
        newValue = 100;
      }
      if(event.target.value === ''){
        newValue = 0;
      }
      setEstimatedLocalTax(newValue);
    }
    updatedObj[field] = newValue;
    handleUpdatedObject(updatedObj);
  };

  useEffect(() => {
    if (city && stateValue) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [city, stateValue]);

  useEffect(() => {
    const updatedObj = { ...handleCareerObject };
    if (handleCareerObject.occupation === undefined) {
      updatedObj.occupation = '';
    } else {
      setsearchOccupationText(handleCareerObject.occupation);
    }
    if (handleCareerObject.state_tax === undefined) {
      updatedObj.state_tax = 0;
    } else {
      setEstimatedStateTax(handleCareerObject.state_tax);
    }
    if (handleCareerObject.city_taxes === undefined) {
      updatedObj.city_taxes = 1.50;
    } else {
      setEstimatedLocalTax(handleCareerObject.city_taxes);
    }
    if (handleCareerObject.self_employment === undefined) {
      updatedObj.self_employment = 'No';
    } else {
      setSelfEmployment(handleCareerObject.self_employment);
    }
    if (handleCareerObject.city === undefined) {
      updatedObj.city = '';
    } else {
      setCity(handleCareerObject.city);
    }
    if (handleCareerObject.state_id === undefined) {
      updatedObj.state_id = 0;
      setStateValue(0);
    } else {
      setStateValue(handleCareerObject.state_id);
    }
    handleUpdatedObject(updatedObj);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.contentBlock}>
      {handleCareerObject.role === 'futureMove'
        && (
          <div>
            <p>
              What you plan to do and where you plan to do it are the first things to consider. Check out
              {' '}
              <a href="https://wallethub.com/edu/best-states-for-jobs/35641/" target="_blank" rel="noopener noreferrer">this link</a>
              {' '}
              to explore how each state stacks up for jobs.
            </p>
            <p>
              Will you be
              {' '}
              <span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Self-Employment')}>self-employed?</span>
              {' '}
              Enter the name of your planned career move. Then, select the state and enter the city you will be living in. State and city are important factors to think about for taxes and living expenses. Select different states and watch the state income tax rate change.
            </p>
            <p>
              If you&apos;re unsure of your career choice, or want to explore additional career options,
              {' '}
              <i className={ classes.italicNormal }>now is the time to take a second look.</i>
              {' '}
              Check out
              {' '}
              <a href="https://www.careerbuilder.com/insights" target="_blank" rel="noopener noreferrer">Career Builder</a>
              {' '}
              to become aware of career opportunities and discover a career path that interests you.
            </p>
          </div>
        )}
      {handleCareerObject.role === 'currentRole'
        && (
          <p>
            Are you
            {' '}
            <span className={classes.dottedText} aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Self-Employment')}>self-employed?</span>
            {' '}
            {' '}
            Enter the name of your current role. Then, select the state and enter the city you are living in. State and city are important factors to think about for taxes and living expenses. Select your state and watch the state income tax rate change.
          </p>
        )}
      <div className={classes.grayBox}>
        <div className={classes.formCarrerMove}>
          <div className={classes.careerMoveForm}>
            <div className={classes.careerFormGroup}>
              <span><font aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Occupation')}>Career Move:</font></span>

              <div className={classes.careerMoveDropdown}>
                <input type="text" placeholder="ex. Mechanical Engineer" onChange={(e) => { updateValue(e, 'occupation'); }} value={searchOccupationText} />
                {
                  (occupationList && occupationList.length > 0 && showOcc) && (
                    <div className={classes.moveDropdown}>
                      {
                        occupationList.map((list) => (<p aria-hidden="true" onClick={() => { handleOccupationSelect(list.occupation); }} >{list.occupation}</p>))
                      }
                    </div>

                  )
                }
              </div>
            </div>
            <div className={classes.careerFormGroup}>
              <span><font aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('Self-Employment')}>Self-Employment:</font></span>
              <ul>
                <li aria-hidden="true" className={selfEmployment === 'Yes' ? classes.active : classes.unactive} onClick={() => { updateValue('Yes', 'self_employment'); }}>Yes</li>
                <li aria-hidden="true" className={selfEmployment === 'No' ? classes.active : classes.unactive} onClick={() => { updateValue('No', 'self_employment'); }}>No</li>
              </ul>
            </div>
            <div className={classes.careerFormGroup}>
              <span>
                <font aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={() => handleDefinationPopup('FICA Taxes')}>
                  FICA Income Taxes:
                </font>
              </span>
              {selfEmployment === 'Yes'
                && (<span> 15.30%</span>)}
              {selfEmployment === 'No'
                && (<span> 7.65%</span>)}

            </div>
            <div className={classNames(classes.careerFormGroup, "formSelect")}>
              <span>State:</span>
              <Select onChange={(value) => { updateValue(value, 'state_id'); }}  value={stateValue}
              options={ statesList }
              placeholder="Please select" />
            </div>
            <div className={classes.careerFormGroup}>
              <span>City:</span>
              <TextField placeholder="ex. Philadelphia" value={city} onChange={(e) => { updateValue(e, 'city'); }} />
            </div>
            <div className={classNames(classes.careerFormGroup, classes.estimatedTaxes)}>
              <span>Estimated State Income Taxes:</span>
              <NumberFormat
                value={estimatedStateTax}
                decimalScale={ isFocus!=='state_tax'?2:5 }
                fixedDecimalScale={isFocus!=='state_tax'}
                allowNegative={false}
                onChange={(e) => { updateValue(e, 'state_tax'); }}
                onFocus={(e) => { e.target.select(); setIsFocus('state_tax') }}
                onBlur={(e) => { setEstimatedStateTax(parseFloat(e.target.value).toFixed(2)); }} />
              <strong>%</strong>
              <img aria-hidden="true" onClick={() => { setEstimatedStateTax(0); }} src={CircleArrow} alt="" data-for="refreshTitle" data-tip="Reset to default value." />
              <img src={infoIcon} alt="" data-for="refreshTitle" data-tip="To learn how Inkwiry estimates your state income taxes, check the footnotes below." />
            </div>
            <div className={classNames(classes.careerFormGroup, classes.estimatedTaxes)}>
              <span>Estimated Local Income Taxes:</span>
              <NumberFormat
                value={estimatedLocaltax}
                decimalScale={ isFocus!=='city_taxes'?2:5 }
                fixedDecimalScale={isFocus!=='city_taxes'}
                allowNegative={false}
                onChange={(e) => { updateValue(e, 'city_taxes'); }}
                onFocus={(e) => { e.target.select(); setIsFocus('city_taxes') }}
                onBlur={(e) => { setEstimatedLocalTax(parseFloat(e.target.value).toFixed(2)); }} />
              <strong>%</strong>
              <img aria-hidden="true" onClick={() => { setEstimatedLocalTax(1.5); }} src={CircleArrow} alt="" data-for="refreshTitle" data-tip="Reset to default value." />
              <img src={infoIcon} alt="" data-for="refreshTitle" data-tip="Inkwiry assumes a 1.50% tax rate for your local income taxes, which includes any county, city, or township tax that might be imposed on your income. Check the footnotes below for a resource to check if your county, city, or township charges income taxes." />
            </div>

            <div className={classes.careerFormGroup}>
              <span>Total State and Local Income Taxes:</span>
              <p>
                <NumberFormat displayType={'text'} value={parseFloat(estimatedStateTax) + parseFloat(estimatedLocaltax)} decimalScale={2} fixedDecimalScale suffix='%'/>                
              </p>
            </div>
            <ReactTooltip
              id="refreshTitle"
              place="left"
              type="info"
              effect="solid"
              className={classes.tooltipInfoTopNew}
            />
          </div>
        </div>
      </div>
      <ReactTooltip
        id="definationTitle"
        place="top"
        type="info"
        effect="solid"
        className={classes.tooltipInfoTop}
      />
    </div>

  );
};

CareerMove.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  setNextButtonDisabled: PropTypes.func.isRequired,
  setSelfEmployment: PropTypes.func.isRequired,
  selfEmployment: PropTypes.string.isRequired,
};

export default withStyles(styles)(CareerMove);
