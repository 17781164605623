const data = [
  { 
    title: "Investment Analysis",
    children: [
      {
        label: "Purchase Price",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "purchase_price",
      },{
        label: "Down Payment",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "downpayment_calcs",
      },{
        label: "Closing Costs",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "closing_costs",
      },{
        label: "Fit Out Costs",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "external_and_internal_fit_out_costs",
      },{
        label: "Annual Property Taxes",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "buyingahome_annual_property_taxes",
      },{
        label: "Private Mortgage Insurance (PMI)",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "buyingahome_private_mortgage_insurance",
      },{
        label: "Annual Homeowner&apos;s Insurance",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "buyingahome_annual_homeowners_insurance",
      },{
        label: "Annual Maintenance",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "buyingahome_annual_maintenance",
      },{
        label: "Annual Association Fees",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "buyingahome_annual_associal_fees",
      },{
        label: "Monthly Utilities",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "buyingahome_monthly_utilities",
      },{
        label: "Annual Appreciation",
        type: "ratio",
        prefix: "",
        suffix: "%",
        key: "buyingahome_annual_appreciation",
      },{
        label: "Cash Needed at Purchase",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "cashneeded_at_purchase",
      },{
        label: "Total Cash Available",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "total_cash_available",
      },{
        label: "Total Liquid Assets Available",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "total_liquid_assets",
      },{
        label: "Total Principal & Interest",
        type: "number",
        prefix: "$",
        suffix: "",
        key: "total_principal_interest",
      },{
        label: "Debt-to-Income Ratio",
        type: "ratio",
        prefix: "",
        suffix: "%",
        key: "debt_to_income_ratio",
      },
    ],
  },
]

export default data;