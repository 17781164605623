import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';

const StudentLoanRepaymentContinue = ( props ) => {
  const {
    classes,
  } = props;

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      <p>
        Repaying your loans on time is always a smart money move. If you repay on time, you minimize the cost of the loan and increase your net worth.
      </p>
      <p>
        What happens if you repay your loans in 20 years instead of 10 years? What if I repay my loans ahead of schedule in 7 years? Don&apos;t let the lower monthly payment fool you. The longer you take to repay, the higher the cost of the loan.
      </p>
      <div className={ classes.loanAmountTable }>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th>Loan<br />Amount</th>
            <th>Repayment<br />Time</th>
            <th>Interest<br />Rate
            </th>
            <th>Monthly<br />Payment
            </th>
            <th>Total Repayment<br />Amount</th>
          </thead>
          <tbody>
            <tr className={ classes.BgWhite }>
              <td>$20,000</td>
              <td>7 years</td>
              <td>5.0%</td>
              <td>$282.68</td>
              <td>$23,744.97</td>
            </tr>
            <tr>
              <td>$20,000</td>
              <td>10 years</td>
              <td>5.0%</td>
              <td>$212.13</td>
              <td>$25,455.72</td>
            </tr>
            <tr className={ classes.BgGray }>
              <td>$20,000</td>
              <td>20 years</td>
              <td>5.0%</td>
              <td>$131.99</td>
              <td>$31,677.88</td>
            </tr>
            <tr className={ classes.BgWhite }>
              <td>$40,000</td>
              <td>7 years</td>
              <td>5.0%</td>
              <td>$566.36</td>
              <td>$47,489.93</td>
            </tr>
            <tr>
               <td>$40,000</td>
                <td>10 years</td>
                <td>5.0%</td>
                <td>$424.26</td>
                <td>$50,911.45</td>
            </tr>
            <tr className={ classes.BgGray }>
              <td>$40,000</td>
              <td>20 years</td>
              <td>5.0%</td>
              <td>$263.98</td>
              <td>$63,355.75</td>
            </tr>
            <tr className={ classes.BgWhite }>
              <td>$60,000</td>
              <td>7 years</td>
              <td>5.0%</td>
              <td>$848.03</td>
              <td>$71,234.90</td>
            </tr>
            <tr>
              <td>$60,000</td>
              <td>10 years</td>
              <td>5.0%</td>
              <td>$636.39</td>
              <td>$76,367.17</td>
            </tr>
            <tr className={ classes.BgGray }>
              <td>$60,000</td>
              <td>20 years</td>
              <td>5.0%</td>
              <td>$395.97</td>
              <td>$95,033.63</td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  );
};

StudentLoanRepaymentContinue.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( StudentLoanRepaymentContinue );
