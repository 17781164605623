import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { loader } from 'graphql.macro';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import { confirmAlert } from 'react-confirm-alert';
import styles from './styles';
import PwiSave from '../../components/pwi-save/pwi-save';
import BuyingAHomeWorkSheet from '../../components/documents/worksheet-tables/buying-a-home-worksheet';
import sliderImageOne from '../../assets/img/family/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/buying-a-home/buyingAHome-slide1.jpg';
import sliderImageThree from '../../assets/img/buying-a-home/buyingAHome-slide2.jpg';
import buyingAHomeLoanpoints from '../../assets/img/buying-a-home/buying-a-home-loanpoints.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import infoIconWhite from '../../assets/img/higher-education/info-icon-contrast.png';
import * as moduleServices from '../../calculations/modules-services';
import MySavedNotes from './my-saved-notes';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import { defaultValues } from '../../calculations/default-module-values';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import SimulatorPanel from '../../components/documents/simulator-panel';
import alertIcon from '../../assets/img/alert_icon.png';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
import * as homeGraphData from '../../graph-services/home-show-me';

import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import WalkThruTable from './wallk-thru/walkThruTable';
import IconBar from './IconBar';

import scrollToComponent from 'react-scroll-to-component';

const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

const termLoanRange = [
  { value: 0, label: 0 },
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
  { value: 21, label: 21 },
  { value: 22, label: 22 },
  { value: 23, label: 23 },
  { value: 24, label: 24 },
  { value: 25, label: 25 },
  { value: 26, label: 26 },
  { value: 27, label: 27 },
  { value: 28, label: 28 },
  { value: 29, label: 29 },
  { value: 30, label: 30 },
];

const BuyingAHomeWorksheet = (props) => {
  const { classes, history } = props;

  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
  };

  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = useState(false);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [updatedBuyingAHomeValues, setupdatedBuyingAHomeValues] = useState(defaultValues.module13);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [modalType, setModalType] = useState('');
  const [imageURL, setImageURL] = useState('');
  const [imagePopup, setImagePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [loading, setLoading] = useState(true);
  const [yearRange, setYearRange] = React.useState([]);
  const [openPopup, setOpenPopup] = React.useState('');
  const [openInputPopupStatus, setOpenInputPopupStatus] = React.useState({ rate_of_mortgage_in_percentage: false });
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [showMeData, setShowMeData] = React.useState({});
  const [openWorkSheet, setOpenWorkSheet] = React.useState(false);
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [isFocus, setIsFocus] = useState('');

  useEffect(() => {
    setWalkThruNo(15);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      if (currentYear === year) {
        yearRange.push({ label: 0, value: 0 });
      }
      yearRange.push({ label: year, value: year });
    }
    setYearRange(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module13' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          setupdatedBuyingAHomeValues(data);
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
    onError() {
      setActiveScenarioData({});
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setupdatedBuyingAHomeValues(defaultValues.module13);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(() => {
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module13' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }
  }, [loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(() => {
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module13') {
      dropdownvalues.savedNotes = true;
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 });
    }
  }, [myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(homeGraphData.homeShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top' });
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleImagePopup = (url) => {
    setImagePopup(!imagePopup);
    setImageURL(url);
  };

  const handleEditPopup = () => {
    setEditPopup(!editPopup);
  };

  const setFixedDecimal = (field) => {
    setIsFocus('');
    if (field === 'rate_of_mortgage_in_percentage') {
      let text = '';
      const popmin = 3;
      const popmax = 8;
      if (openInputPopupStatus[field] === false) {
        const inputPopupStatus = { ...openInputPopupStatus };
        inputPopupStatus[field] = true;
        setOpenInputPopupStatus(inputPopupStatus);
        text = 'Mortgage loan interest rates typically vary from 3 - 8%. Make sure you can support an interest rate outside these boundaries with research.';
      }
      const tempValue = updatedBuyingAHomeValues[field];
      if (tempValue > 0 && text !== '' && (tempValue < popmin || tempValue > popmax)) {
        confirmAlert({
          // eslint-disable-next-line
          customUI: ({ onClose }) => (
            <div className={classes.redoPopup}>
              <div className={classes.redoPopupNew}>
                <h3>Important Tip</h3>
                <div className={classes.NoFiDiv}>
                  <p className={classes.zeroLeftPadding}>{text}</p>
                </div>
              </div>
              <div className={classes.buttonOkRedo}>
                <button
                  type="button"
                  onClick={onClose}
                >
                  OK
                </button>
                <button className={classes.hideBtn} type="button" onClick={onClose}>OK</button>
              </div>
            </div>
          ),
        });
      }
    } else if (field === 'down_payment_in_percentage') {
      const valuesUpdated = { ...updatedBuyingAHomeValues };
      if (valuesUpdated.down_payment_in_percentage >= 20) {
        valuesUpdated.private_mortgage_insurance = 0;
        setupdatedBuyingAHomeValues(valuesUpdated);
      }
    }
  };

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...updatedBuyingAHomeValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
      if (valuesUpdated[field] > 100 && (field !== 'purchase_price'
        && field !== 'closing_appraisal_fee'
        && field !== 'closing_credit_report_fee'
        && field !== 'closing_flood_determination'
        && field !== 'closing_tax_service_fee'
        && field !== 'closing_recording_mortgage'
        && field !== 'closing_escrow_account'
        && field !== 'external_and_internal_fit_out_costs'
        && field !== 'yearly_association_fees'
        && field !== 'monthly_utilities'
        && field !== 'closing_processing_fee'
        && field !== 'closing_underwriting_fee'
        && field !== 'closing_pest_Inspection'
        && field !== 'closing_home_owners_association_transfer_fees'
        && field !== 'closing_costs_others'
      )) {
        valuesUpdated[field] = 100;
      }
    } else if (fieldType === 'input') {
      valuesUpdated[field] = e.target.value;
    } else if (fieldType === 'select') {
      valuesUpdated[field] = e.value;
    }
    setupdatedBuyingAHomeValues(valuesUpdated);
    moduleServices.setModule13Data(valuesUpdated);   
  };

  const handleClosingCostReset = () => {
    const valuesUpdated = { ...updatedBuyingAHomeValues };
    valuesUpdated.closing_appraisal_fee = 0;
    valuesUpdated.closing_credit_report_fee = 0;
    valuesUpdated.closing_escrow_account = 0;
    valuesUpdated.closing_flood_determination = 0;
    valuesUpdated.closing_tax_service_fee = 0;
    valuesUpdated.closing_recording_mortgage = 0;
    valuesUpdated.closing_processing_fee = 0;
    valuesUpdated.closing_underwriting_fee = 0;
    valuesUpdated.closing_pest_Inspection = 0;
    valuesUpdated.closing_home_owners_association_transfer_fees = 0;
    valuesUpdated.closing_costs_others = 0;
    setupdatedBuyingAHomeValues(valuesUpdated);
  }

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module13';
    objectParams.moduleNumber = 'module13Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module13";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module13', 'quick-save', activeScenarioData);
      finalObject['module13Data'] = pwiObject.getfilteredBuyingHomeObject(moduleServices.module13Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -280, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-five'), { offset: -250, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  };

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -160, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 300, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 13) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >
      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 13 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.homeSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying a Home
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your buying a home details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen, classes.buttonGreenNew)}> Enter My Start Year </span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying a Home
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Know What You Can Afford
                              </Typography>
                              <ul>
                                <li>Choose your preferred home type and setting</li>
                                <li>Anticipate maintenance, repairs and/or improvements</li>
                                <li>Learn the lingo, understand how mortgages work</li>
                                <li>Employ competitive market analysis to assess value and compare pricing</li>
                                <li>Perform diligent research so you know when you&apos;re getting a good deal.</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonOrange)}> GET STARTED </span>

                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Buying a Home
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Spaces and Scenarios
                              </Typography>
                              <ul>
                                <li>Building equity in a house can provide financial benefits, but is home ownership for you?</li>
                                <li>Consider how your home choice might affect professional and personal priorities, and vice-versa</li>
                                <li>Experiment with your numbers to reduce risk and maximize reward</li>
                                <li>Compare different scenarios to find your best option.</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonOrange)}> CHECK MY SPACES </span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageThree} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)} */}
        <section className={classes.fiContentSection}>
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module13" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classes.leftIconPuzzle}>
                    <Link to="/plan-with-inkwiry/home" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.buyingahomeWalkThru1) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides}
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes={classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classNames(classes.scrollPortion, classes.scrollPortionInBaH)}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Buying a Home</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Purchase Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'year_of_purchase', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedBuyingAHomeValues.year_of_purchase, value: updatedBuyingAHomeValues.year_of_purchase }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you plan to buy a home. What kind of home will you be able to afford? Choose different years and see what kind of home you can afford by looking at your Total Cash Available and Other Liquid Assets Available.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Purchase Price</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.purchase_price} onValueChange={(e) => updateValue(e, 'purchase_price', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="What type of home do you want? How many bedrooms? Where do you want to live? All these questions will factor into the price of your home. Use the Boost My Awareness section to find how much your future home might cost.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.down_payment_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'down_payment_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('down_payment_in_percentage'); }}
                                            decimalScale = { isFocus!=='down_payment_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='down_payment_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('down_payment_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Percentage of the Purchase Price you expect to pay upfront. The rest of the Purchase Price will be financed through a loan and automatically calculated below. Typical down payments range between 5-20%. See the Boost My Awareness section for more info."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.downPaymentCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.downPaymentCalcs} prefix={moduleServices.module13Data.downPaymentCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.downPaymentCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Transfer Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.transfer_taxes_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'transfer_taxes_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('transfer_taxes_in_percentage'); }}
                                            decimalScale = { isFocus!=='transfer_taxes_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='transfer_taxes_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('transfer_taxes_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Fee paid for the transfer of the title of real estate to states, counties, and even some municipalities. In other words, you get charged a percentage based fee for changing the ownership of the home. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.transferTaxesCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.transferTaxesCalcs} prefix={moduleServices.module13Data.transferTaxesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.transferTaxesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Title Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.title_insurance}
                                            onValueChange={(e) => updateValue(e, 'title_insurance', 'number')}
                                            onBlur={() => { setFixedDecimal('title_insurance'); }}
                                            decimalScale = { isFocus!=='title_insurance'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='title_insurance'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('title_insurance'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee that protects you, as the buyer, and the lender from any problems with the title transfer process. You typically need two policies: owner's policy and lender's policy. Cost varies by state. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.titleInsuranceCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.titleInsuranceCalcs} prefix={moduleServices.module13Data.titleInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.titleInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Fit Out Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.external_and_internal_fit_out_costs} onValueChange={(e) => updateValue(e, 'external_and_internal_fit_out_costs', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="When you buy a home, there are additional costs such as furnshing the home or finishes in the kitchen among many more. Expect Fit Out Costs to run anywhere between 5-15% of the purchase price. Use the Boost My Awareness section for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Home Value</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.buyingHomeValue !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.buyingHomeValue} prefix={moduleServices.module13Data.buyingHomeValue >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.buyingHomeValue < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.property_tax_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'property_tax_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('property_tax_in_percentage'); }}
                                            decimalScale = { isFocus!=='property_tax_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='property_tax_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('property_tax_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Each year you will pay property taxes as a percentage of your Home's Value. Property Taxes vary by location, but typically range between 1-2%. See how Home Value impacts property taxes on the Income Statement. Use Boost My Awareness for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.propertyTaxesCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.propertyTaxesCalcs} prefix={moduleServices.module13Data.propertyTaxesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.propertyTaxesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Homeowner&apos;s Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.home_owners_insurance_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'home_owners_insurance_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('home_owners_insurance_in_percentage'); }}
                                            decimalScale = { isFocus!=='home_owners_insurance_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='home_owners_insurance_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('home_owners_insurance_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Just like owning a car, you need to have insurance on your important assets just in case of emergency. What if a tree falls on your home? Or a fire strikes? Insurance is a fee paid annually to protect against emergencies."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.homeownerInsuranceCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.homeownerInsuranceCalcs} prefix={moduleServices.module13Data.homeownerInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.homeownerInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.yearly_maintanance_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'yearly_maintanance_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('yearly_maintanance_in_percentage'); }}
                                            decimalScale = { isFocus!=='yearly_maintanance_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='yearly_maintanance_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('yearly_maintanance_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Each year your home will have some wear and tear and needed improvements. Plan for these costs with an Annual Maintenance expense based on a percentage of your Home Value. See Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.yearlyMaintenanceCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.yearlyMaintenanceCalcs} prefix={moduleServices.module13Data.yearlyMaintenanceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.yearlyMaintenanceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Association Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.yearly_association_fees} onValueChange={(e) => updateValue(e, 'yearly_association_fees', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Some residential communities charge Annual Association Fees for the general upkeep of the neighborhood or community. A company takes care of the lawn, trash, and so forth. Fees could range from $2,400 to $4,800 a year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.yearlyAssociationCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.yearlyAssociationCalcs} prefix={moduleServices.module13Data.yearlyAssociationCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.yearlyAssociationCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Monthly Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.monthly_utilities} onValueChange={(e) => updateValue(e, 'monthly_utilities', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much you expect to spend on utilities such as cable, internet, electricity, heat, water, etc. Utilities can range from $200 - $600 depending on the size and location of the home.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.homeMonthlyUtilitiesCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.homeMonthlyUtilitiesCalcs} prefix={moduleServices.module13Data.homeMonthlyUtilitiesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.homeMonthlyUtilitiesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Appreciation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.appreciation_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'appreciation_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('appreciation_in_percentage'); }}
                                            decimalScale = { isFocus!=='appreciation_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='appreciation_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('appreciation_in_percentage'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The rate you expect your home to appreciate each year. Real Estate typically rises in value. The FC uses Annual Appreciation to grow the value of your home every year. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>

                          </div>
                        </div>
                      </Grid>

                      <Grid item sm={6} xs={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Home Mortgage</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Mortgage</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.Mortage !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.Mortage} prefix={moduleServices.module13Data.Mortage >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.Mortage < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Private Mortgage Insurance (PMI)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.private_mortgage_insurance}
                                            onValueChange={(e) => updateValue(e, 'private_mortgage_insurance', 'number')}
                                            onBlur={() => { setFixedDecimal('private_mortgage_insurance'); }}
                                            disabled={updatedBuyingAHomeValues.down_payment_in_percentage >= 20}
                                            decimalScale = { isFocus!=='private_mortgage_insurance'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='private_mortgage_insurance'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('private_mortgage_insurance'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="When you buy a home, you will have to get approved for a mortgage. If you put less than 20% down on your home, the lender will require you to pay PMI to secure a mortgage. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.privateMortgageInsuranceCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.privateMortgageInsuranceCalcs} prefix={moduleServices.module13Data.privateMortgageInsuranceCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.privateMortgageInsuranceCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Term of the Loan</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'term_of_mortgage', 'select'); }}
                                              options={termLoanRange}
                                              value={{ label: updatedBuyingAHomeValues.term_of_mortgage, value: updatedBuyingAHomeValues.term_of_mortgage }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The # of years for repayment, typically 30 years for Mortgages. A shorter term will result in higher payments and vice versa holding all else equal. Check your Cash Flow Statement and see how quickly you can repay your mortgage.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />

                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Interest Rate</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.rate_of_mortgage_in_percentage}
                                            onValueChange={(e) => updateValue(e, 'rate_of_mortgage_in_percentage', 'number')}
                                            onBlur={() => { setFixedDecimal('rate_of_mortgage_in_percentage'); }}
                                            decimalScale = { isFocus!=='rate_of_mortgage_in_percentage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='rate_of_mortgage_in_percentage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('rate_of_mortgage_in_percentage'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="The interest rate you plan to get on your mortgage. Change the interest rate to see the effects on Total Interest paid. And, use the Boost My Awareness section for more details on current interest rates."
                                            />
                                          </div>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Loan Points</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.points_for_the_mortgage}
                                            onValueChange={(e) => updateValue(e, 'points_for_the_mortgage', 'number')}
                                            onBlur={() => { setFixedDecimal('points_for_the_mortgage'); }}
                                            decimalScale = { isFocus!=='points_for_the_mortgage'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='points_for_the_mortgage'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('points_for_the_mortgage'); }}
                                          />

                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee paid to the lender in exchange for a lower interest rate. Loan Points are calculated as a percentage of the mortgage. See the Boost My Awareness section for an example of how paying points could lead to savings over the life of the loan."
                                            />
                                          </div>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.buyingAhomeLoanPointsCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.buyingAhomeLoanPointsCalcs} prefix={moduleServices.module13Data.buyingAhomeLoanPointsCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.buyingAhomeLoanPointsCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Origination Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedBuyingAHomeValues.origination_fees}
                                            onValueChange={(e) => updateValue(e, 'origination_fees', 'number')}
                                            onBlur={() => { setFixedDecimal('origination_fees'); }}
                                            decimalScale = { isFocus!=='origination_fees'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='origination_fees'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('origination_fees'); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="One-time fee paid to the lender for administrative costs associated with your mortgage. Origination Fees are calculated as a percentage of the mortgage and are typically required for all mortgages. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classes.calcText}>
                                        {moduleServices.module13Data.originationFeesCalcs !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.originationFeesCalcs} prefix={moduleServices.module13Data.originationFeesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.originationFeesCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.lightBlack)}>Monthly Payment (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.moreHighermonthlyPayment !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.moreHighermonthlyPayment} prefix={moduleServices.module13Data.moreHighermonthlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.moreHighermonthlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.yearlyPayment !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.yearlyPayment} prefix={moduleServices.module13Data.yearlyPayment >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.yearlyPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.biyingaHomeTotalInterest !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.biyingaHomeTotalInterest} prefix={moduleServices.module13Data.biyingaHomeTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.biyingaHomeTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Repayment Amount (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.biyingaHomeTotalPrncipalInterest !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.biyingaHomeTotalPrncipalInterest} prefix={moduleServices.module13Data.biyingaHomeTotalPrncipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.biyingaHomeTotalPrncipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Closing Costs</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={9} xs={9} >
                                        <Grid item sm={6} xs={6} >
                                          <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                            <div className={classes.spanText}><strong>Closing Costs</strong></div>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} xs={6} >
                                          <div className={classes.inputRight}>
                                            <span className={classes.noInputText}>
                                              {moduleServices.closingCosts() !== undefined ? (
                                                <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.closingCosts()} prefix={moduleServices.closingCosts() >= 0 ? '$' : '($'} suffix={moduleServices.closingCosts() < 0 && ')'} />
                                              ) : ('$0')}
                                            </span>
                                            <div className={classes.infoIcon}>
                                              <img
                                                src={infoIcon}
                                                alt="infoicon"
                                                data-for="detailsTooltip"
                                                data-tip="Closing costs are calculated based on some of the inputs above and other costs. See the Show Table feature in the Show Me section below to view a detailed breakdown of closing costs. Closing costs must be paid to complete the purchase."
                                              />
                                            </div>
                                            <Button className={classes.editButton} onClick={handleEditPopup}>EDIT</Button>
                                          </div>
                                        </Grid>
                                      </Grid>
                                      <Grid item sm={3} xs={3} className={classes.calcBlock} />
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Buying a Home - Monthly Expenses</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Mortgage Payment (Principal & Interest)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.mortgagePaymentExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.mortgagePaymentExpenses} prefix={moduleServices.module13Data.mortgagePaymentExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.mortgagePaymentExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Property Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.propertyTaxesExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.propertyTaxesExpenses} prefix={moduleServices.module13Data.propertyTaxesExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.propertyTaxesExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Private Mortgage Insurance (PMI)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.privateMortgageInsExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.privateMortgageInsExpenses} prefix={moduleServices.module13Data.privateMortgageInsExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.privateMortgageInsExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Homeowner&apos;s Insurance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.homeOwnersInsExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.homeOwnersInsExpenses} prefix={moduleServices.module13Data.homeOwnersInsExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.homeOwnersInsExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>PITI (Principal, Interest, Taxes, & Insurance)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.pitiExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.pitiExpenses} prefix={moduleServices.module13Data.pitiExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.pitiExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Maintenance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.maintenanceExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.maintenanceExpenses} prefix={moduleServices.module13Data.maintenanceExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.maintenanceExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Association Fees</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.assiciationFeesExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.assiciationFeesExpenses} prefix={moduleServices.module13Data.assiciationFeesExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.assiciationFeesExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.utilitiesExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.utilitiesExpenses} prefix={moduleServices.module13Data.utilitiesExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.utilitiesExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.totalMonthlyExpenses !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.totalMonthlyExpenses} prefix={moduleServices.module13Data.totalMonthlyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.totalMonthlyExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>% of Total Income Before Taxes</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage !== undefined ? (
                                              <NumberFormat
                                                decimalScale={0}
                                                fixedDecimalScale
                                                className={classes.formInput}
                                                displayType="text"
                                                thousandSeparator
                                                value={moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage}
                                                prefix={moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage >= 0 ? '$' : '($'}
                                                suffix={moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomePercentage < 0 && ')'}
                                              />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Income Before Taxes</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue !== undefined ? (
                                              <NumberFormat
                                                decimalScale={0}
                                                fixedDecimalScale
                                                className={classes.formInput}
                                                displayType="text"
                                                thousandSeparator
                                                value={moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue}
                                                prefix={moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue >= 0 ? '$' : '($'}
                                                suffix={moduleServices.module13Data.totalIncomeBeforweTaxesBuyingHomeValue < 0 && ')'}
                                              />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Buying a Home - Investment Analysis</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Purchase Price</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.homePurchasePrice !== undefined ? (
                                              <NumberFormat
                                                decimalScale={0}
                                                fixedDecimalScale
                                                className={classes.formInput}
                                                displayType="text"
                                                thousandSeparator
                                                value={moduleServices.module13Data.homePurchasePrice}
                                                prefix={moduleServices.module13Data.homePurchasePrice >= 0 ? '$' : '($'}
                                                suffix={moduleServices.module13Data.homePurchasePrice < 0 && ')'}
                                              />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Down Payment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.homeDownPayment !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.homeDownPayment} prefix={moduleServices.module13Data.homeDownPayment >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.homeDownPayment < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Closing Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.closingCosts() !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.closingCosts()} prefix={moduleServices.closingCosts() >= 0 ? '$' : '($'} suffix={moduleServices.closingCosts() < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Fit Out Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.homeFitOutCosts !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.homeFitOutCosts} prefix={moduleServices.module13Data.homeFitOutCosts >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.homeFitOutCosts < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Cash Needed at Purchase</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.cashNeedsPurchase !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.cashNeedsPurchase} prefix={moduleServices.module13Data.cashNeedsPurchase >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.cashNeedsPurchase < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Total Cash Available</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.moreHigherTotalCashAvailable !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.moreHigherTotalCashAvailable} prefix={moduleServices.module13Data.moreHigherTotalCashAvailable >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.moreHigherTotalCashAvailable < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Other Liquid Assets Available</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module13Data.moreHighermoreTotalLiquid !== undefined ? (<NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.moreHighermoreTotalLiquid} prefix={moduleServices.module13Data.moreHighermoreTotalLiquid >= 0 ? '$' : '($'} suffix={moduleServices.module13Data.moreHighermoreTotalLiquid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>

                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />

                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Debt-to-Income Ratio</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classes.spanBlueText}>
                                          <span>
                                            {moduleServices.module13Data.debtToIncomeRatio !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module13Data.debtToIncomeRatio} prefix={moduleServices.module13Data.debtToIncomeRatio >= 0 ? '' : '('} suffix={moduleServices.module13Data.debtToIncomeRatio < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                          &nbsp;
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIconWhite}
                                              alt="infoicon"
                                              data-for="detailsTooltip"
                                              data-tip="Calculated by taking Total Debt Payments divided by Total Gross Income in a given year. The lower the ratio, the better the debt management state of an individual. A healthy ratio is no more than 36%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>

            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.buyingahomeWalkThru2) : classNames(classes.showMe, classes.showSection) && slidecount === 7 ? classNames(classes.showMe, classes.showMeScroll, classes.buyingahomeWalkThru3) : classNames(classes.showMe, classes.showSection)} id="slide-five">
              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ? classes.opacityZero : '' || slidecount === 7 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruTable walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                  <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                )}
                {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (<Button className={classNames(classes.graphButton, classes.graphSpace)} onClick={() => { setOpenWorkSheet(true) }}>Summary Worksheet</Button>)}
                {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton}>Refresh</Button>)}
                {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classNames(classes.graphButton, classes.graphSpace)} onClick={() => { setOpenWorkSheet(true) }}>Summary Worksheet</Button>)}
              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph1 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Home Value and Mortgage Repayment</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph1Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph2 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Upfront Costs of Buying a Home</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph2Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph3 === true && (
                    <div className={classes.showDescriptionCntnt}>
                      <div className={classes.graphBlock}>
                        <h3>Closing Costs Breakdown</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph3Data}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Buying a Home
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>Why is PMI automatically 0%?</h3>
                          <p>If your Down Payment is 20% or more, mortgage lenders will not require Private Mortgage Insurance (PMI). When Down Payment is 20% or more, the input for PMI will automatically go to 0%. If your Down Payment is less than 20%, PMI will be an input as mortgage lenders will require PMI. See PMI under the Definitions section for a deeper understanding of PMI and how it works in the FC.</p>
                        </div>

                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/h/hoa.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Association Fees</a>
                            {' '}
                          </h3>
                          <p>
                            Monthly fee that is paid by owners of certain types of residential properties for the general upkeep, maintenance, and improvement of properties such as lawn care, snow removal, trash removal, and any other maintenance activities of common areas. This fee varies based off size and amenities of the property as well as the type and location of the community. However, Annual Association Fees
                            {' '}
                            <a href="http://www.investopedia.com/articles/mortgages-real-estate/08/homeowners-associations-tips.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>
                              usually range
                              {' '}
                            </a>
                            {' '}
                            from $200 to $400 a month or $2,400 to $4,800 per year!
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Appreciation</h3>
                          <p>
                            The increase in a property’s value over time. How much the property appreciates each year depends on the local real estate market and any improvements made to the property. For example, a property that is purchased for $200,000.00 and appreciates at 2.5% annually would be worth $327,723.29 after 20 years. The
                            {' '}
                            <a href="https://www.cnbc.com/2017/06/23/how-much-housing-prices-have-risen-since-1940.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>
                              median home value
                            </a>
                            {' '}
                            in the U.S. has gone from
                            {' '}
                            <a href="https://www.census.gov/hhes/www/housing/census/historic/values.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>
                              {' '}
                              {' '}
                              $2,938 in 1940 to $119,600 in 2000
                            </a>
                            {' '}
                            to $199,200 in 2017! That would put historical appreciation from 1940 to 2017 at a staggering 5.63%. Now history is not always the best indicator of the future, but that does give you a reference as to the history of housing prices in the U.S.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="http://www.investopedia.com/terms/c/closingcosts.asp#ixzz4jL19p7o2" target="_blank" rel="noopener noreferrer">Closing Costs</a>
                          </h3>
                          <p>
                            Fees paid to third parties that help facilitate the sale of a home and can vary widely by location. These fees are paid at closing in cash. As a rule, you can estimate 2% to 7% of the home&apos;s purchase price in closing costs that must be paid to complete the purchase of the property. Check out these links by
                            {' '}
                            {' '}
                            <a href="https://www.zillow.com/mortgage-learning/closing-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Zillow</a>
                            {' '}
                            and
                            {' '}
                            {' '}
                            <a href="http://www.homeclosing101.org/the-closing-process/closing-costs-explained/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Homeclosing101.</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/d/dti.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Debt-to-Income Ratio</a>
                            {' '}
                          </h3>
                          <p>
                            Used by banks and mortgage companies in order to determine eligibility for loans, specifically mortgages, making this ratio as important as your credit score. Calculated by taking total debt payments (principal and interest) divided by total gross income in a given year. This ratio indicates the percentage of gross income being accounted for debt repayment and the percentage
                            of gross income left over for other household expenses and savings. You can find the debt-to-income ratio on the dashboard or on the summary financials.
                          </p>
                          <p>The lower the ratio, the better. A lower debt-to-income ratio means you have more money left over after debt repayment for living expenses, saving, and investing. A healthy ratio is typically defined as no more than 36%.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.zillow.com/mortgage-learning/down-payments/" target="_blank" rel="noopener noreferrer">Down Payment</a>

                          </h3>
                          <p>The amount of money you pay upfront to the seller expressed as a percentage of the Purchase Price. A bank or other financial institution covers the remainder of the Purchase Price through a Mortgage. The higher your Down Payment, the lower your monthly mortgage payment will be. Typically, you will need to save 5 to 25% of the Purchase Price in cash in order to qualify for a Mortgage.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.thebalance.com/how-escrow-relates-to-your-real-estate-transaction-1798810" target="_blank" rel="noopener noreferrer">Escrow Account</a>

                          </h3>
                          <p>
                            During a real estate transaction, some funds are placed into what&apos;s called an escrow account. The escrow account is typically money, but can also be important documents such as the property&apos;s title, held by a third party to be used at a later date for a specific purpose. Some expenses such as property taxes, insurance, and mortgage payments are placed into an escrow account. Click
                            {' '}
                            <a href="https://www.khanacademy.org/economics-finance-domain/core-finance/housing/home-buying-process/v/types-of-escrow-in-real-estate" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            {' '}
                            to learn more.
                          </p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>Fit Out Costs</h3>
                          <p>The amount of money that you intend to spend on making the property suitable for living, which includes furniture, artwork, electronics, bathroom needs, or repairs. Fit Out Costs could range anywhere from 5-15% of the Purchase Price depending on the size and quality of the property and quality of products you want in the property.</p>

                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="http://www.investopedia.com/terms/h/homeowners-insurance.asp#ixzz4jL52gtFj" target="_blank" rel="noopener noreferrer">Homeowner&apos;s Insurance</a>
                          </h3>
                          <p>Property insurance that covers losses and damages to your house and to assets in the property. Homeowner’s Insurance also provides liability coverage against accidents in or on the property. According to the Federal Reserve Bureau, Homeowner&apos;s Insurance can be estimated by multiplying the Purchase Price by 0.35%.</p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.investopedia.com/personal-finance/understanding-mortgage-payment-structure/" target="_blank" rel="noopener noreferrer">Interest</a>

                          </h3>
                          <p>
                            Monthly loan payments are typically made up of four parts: interest, principal repayment, taxes, and insurance, also known as PITI. In the FC, the monthly payment is broken up into only two parts, interest and principal repayment, while taxes and insurance are shown separately.
                          </p>
                          <p>
                            Interest is a loan expense calculated as a percentage of the outstanding Principal. Interest is the cost of borrowing the Principal. The other portion is the principal repayment. See an example of a typical loan amortization schedule, or in other words, loan repayment schedule
                            {' '}
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer">here.</a>
                          </p>
                          <p>
                            Interest is calculated by multiplying the Outstanding Balance by the Interest Rate. For example, let&apos;s say we have a $600,000 Mortgage outstanding with a 5% annual Interest Rate that we pay on a monthly basis. The calculation for next month&apos;s interest payment would be as follows:
                          </p>
                          <p>$600,000 Balance x 5% Interest Rate / 12 (to make the interest rate on a monthly basis) = $2,500</p>
                          <p>Don&apos;t forget, as you make monthly payments and start paying down your loan, your outstanding balance will decrease, which will decrease your interest portion of the payment.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.thebalance.com/what-are-interest-rates-and-how-do-they-work-3305855" target="_blank" rel="noopener noreferrer">Interest Rate</a>
                          </h3>
                          <p>The amount a lender charges you for using their money, typically expressed as an annual percentage of the principal – loan amount outstanding. Think of borrowing money just like renting an apartment. In exchange for giving you a place to live, you pay your landlord a fee, which is a monthly rent payment. When you borrow money from a bank, it’s very similar to renting an apartment.</p>
                          <p>Instead of borrowing a place to live, you are borrowing money usually from a bank. In exchange for the money, you pay your bank a fee, which is called interest. To calculate your interest each month, the bank uses the interest rate in the formula below:</p>
                          <p>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest</p>
                          <p>
                            To learn more about how mortgage interest works,
                            {' '}
                            <a href="https://www.investopedia.com/articles/personal-finance/061915/how-interest-rates-work-mortgage.asp" target="_blank" rel="noopener noreferrer">click here.</a>
                          </p>

                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.bankrate.com/glossary/d/discount-point/" target="_blank" rel="noopener noreferrer">Loan Points</a>
                          </h3>
                          <p>
                            One-time fee paid to the lender in exchange for a lower interest rate. Loan points are calculated as a percentage of the mortgage. If you can afford to pay loan points and plan to own your home for longer than the breakeven period (see below), the upfront fee could lead to savings over the life of the loan.
                            If you own your home for longer than the breakeven period, which is calculated as the cost of your points divided by monthly savings, each month after that will be savings since, after that period, you have recovered the cost of your points. Let&apos;s look at an example.
                          </p>
                          <div className={classes.heImage}>
                            <img src={buyingAHomeLoanpoints} alt="" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Maintenance</h3>
                          <p>
                            The costs incurred to keep a property in good condition and good working order. Maintenance includes
                            {' '}
                            <a href="https://www.houselogic.com/organize-maintain/diy-repair/home-repair-costs/" target="_blank" rel="noopener noreferrer">general repairs</a>
                            {' '}
                            such as fixing doors, leaky faucets, monthly cleaning service, lawncare and snow removal, maintaining paint, and can also include some major repairs such as replacing a refrigerator or roof. A good
                            {' '}
                            <a href="https://www.thebalance.com/home-maintenance-budget-453820" target="_blank" rel="noopener noreferrer">rule of thumb</a>
                            {' '}
                            is to take 1-2% of the Purchase Price as an estimate for Annual Maintenance or $1-$2 for every square foot. Most importantly, these fees are tax deductible.
                          </p>
                        </div>

                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="http://www.investopedia.com/terms/m/mortgage.asp" target="_blank" rel="noopener noreferrer">Mortgage</a>

                          </h3>
                          <p>
                            A loan that a bank or mortgage lender gives you to help finance the purchase of real estate that bears an Interest Rate and requires repayment. The Mortgage amount is calculated by taking the Purchase Price less the Down Payment. Mortgage interest is tax deductible on the first $750,000 of your mortgage. See the Tax Statement for more details on how the FC calculates this deduction and this link
                            {' '}
                            <a href="http://mazarsledger.com/article/the-home-mortgage-interest-deduction/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>here</a>
                            {' '}
                            for more information. Note, the FC does not take into account home equity loans, which is why the limit is $750,000.
                            {' '}
                          </p>
                          <p>
                            Prior to
                            {' '}
                            {' '}
                            <a href="https://taxfoundation.org/conference-report-tax-cuts-and-jobs-act/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>2018 tax reform,</a>
                            {' '}
                            the mortgage value limit was $1,000,000. Now, on all homes purchased in or later than 2018, the mortgage value limit was dropped to $750,000. However, for tax purposes, the FC assumes all homes are subject to the $750,000 limit.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.investopedia.com/terms/o/origination-fee.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer">Origination Fees</a>
                          </h3>
                          <p>One-time fee paid to the lender for administrative costs associated with your loan. Origination fees are calculated as a percentage of the loan and are typically required for all loans. For mortgages, origination fees are typically between 0.5% and 1.0%.</p>
                          <p>For example, if you have a $250,000 mortgage and 1.0% origination fees, the fee calculation would be 1.0% x $250,000 = $2,500</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Other Liquid Assets Available</h3>
                          <p>Calculated as Short-Term Investments plus Long-Term Investments at the end of the year before the Purchase Year. For example, if you plan to buy in year 2025, Other Liquid Assets Available would show you Short-Term and Long-Term Investments available at the end of year 2024.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.valuepenguin.com/mortgage-insurance-pmi" target="_blank" rel="noopener noreferrer">Private Mortgage Insurance (PMI)</a>
                          </h3>
                          <p>
                            When you make a Down Payment of less than 20% on real estate, the lender requires Private Mortgage Insurance, or PMI. The insurance protects the lender from losing money if you cannot repay your Mortgage. Once you have 20% equity of the real estate, you are no longer considered high risk and can request that the mortgage insurance policy be cancelled.
                            {' '}
                            <a href="http://www.bankrate.com/finance/home-equity/what-home-equity-debt-is-1.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Equity</a>
                            {' '}
                            is the difference between how much the real estate is worth and how much you owe on the Mortgage. Once your Mortgage balance is less than 80% of the real estate value - remember that your real estate value will go up every year by the Annual Appreciation rate you set - the FC will assume you no longer have to
                            pay insurance and stop those payments. By law, mortgage lenders must cancel PMI once you own 22% of the real estate. PMI typically ranges between 0.3% - 1.5% of the original Mortgage. These fees are
                            {' '}
                            <a href="https://www.houselogic.com/finances-taxes/taxes/deducting-private-mortgage-insurance/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>sometimes tax deductible</a>
                            {' '}
                            - see the Tax Statement for more details.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/p/propertytax.asp#ixzz4jL4PM7gn" target="_blank" rel="noopener noreferrer">Property Taxes</a>
                            {' '}
                          </h3>
                          <p>
                            The tax
                            {' '}
                            {' '}
                            <a href="http://www.investopedia.com/articles/tax/09/calculate-property-tax.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>assessed</a>
                            {' '}
                            on real estate each year based on the value of the property you own. Your local or municipal government will assess the value of your property and determine the amount owed. The average property tax in the U.S. is
                            {' '}
                            <a href="https://www.gobankingrates.com/mortgage-rates/heres-costs-1126-month-maintain-average-american-home/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>
                              approximately 1.31%
                              {' '}
                            </a>
                            of the property’s value.
                          </p>
                          <p>Property taxes are tax deductible. Due to 2018 tax reform, property taxes plus state and local income taxes are capped at a $10,000 max deduction. See the Tax Statement for more details.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Purchase Price</h3>
                          <p>The price that is paid to buy real estate excluding Closing Costs or Fit Out Costs.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="http://www.investopedia.com/terms/t/termtomaturity.asp" target="_blank" rel="noopener noreferrer">Term of the Loan</a>
                            {' '}
                          </h3>
                          <p>The length of time in years that you have to repay your loan. Mortgages are typically 15 or 30 years in length, but you can repay your loans early if you can afford to. Change your term of the loan and see how that affects total interest repaid. A shorter term will result in higher monthly payments, but less total interest paid. A longer term will result in lower monthly payments, but higher total interest paid. The less total interest paid, the better.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            {' '}
                            <a href="https://www.realtor.com/advice/buy/how-much-does-title-insurance-cost/" target="_blank" rel="noopener noreferrer">Title Insurance</a>
                            {' '}
                          </h3>
                          <p>One-time fee that protects you, as the buyer, and the lender from any problems with the title transfer process. You typically need two policies: owner&apos;s policy and lender&apos;s policy. Why do you need title insurance?</p>
                          <p>The home&apos;s title is arguably the most important document in the buying process. The document states who actually owns the house. If the title is somehow found to be invalid or fraudulent, that could lead to big issues.</p>
                          <p>To protect against this possibility, both the lender and the buyer typically purchase an insurance policy to cover the lender and the buyer in case the title is later found to be invalid or fraudulent. The cost varies, but a good estimate would be 0.5% - 1.0%, or a few hundred dollars to a few thousand dollars.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Total Principal & Interest</h3>
                          <p>Calculated by taking the principal, or total sum of money borrowed, plus the total interest, which is the total cost of borrowing the principal. In other words, the total principal and interest is the amount of money you would pay over the term of the loan to fully repay your credit card, student loan, car loan, or mortgage.</p>
                          <p>See the definitions for principal and interest above.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="http://www.ncsl.org/research/fiscal-policy/real-estate-transfer-taxes.aspx" target="_blank" rel="noopener noreferrer">Transfer Taxes</a>

                          </h3>
                          <p>
                            Taxes imposed by states, counties and municipalities on the transfer of the title of real estate from one individual to another. Transfer Taxes are more often paid by the seller, but can also be split between or entirely paid for by the buyer - Transfer Taxes typically become a negotiation point at sale. Transfer Taxes vary by location, but between 1-2% is a reasonable benchmark. Use this link
                            {' '}
                            <a href="http://www.ncsl.org/research/fiscal-policy/real-estate-transfer-taxes.aspx" target="_blank" rel="noopener noreferrer">here</a>
                            {' '}
                            to check out transfer taxes by state.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.nationwide.com/average-cost-of-utilities.jsp" target="_blank" rel="noopener noreferrer">Utilities</a>

                          </h3>
                          <p>The total price you pay for water, electricity and gas, trash and recycling, home phone, and internet and cable. Some or all utilities can be included in your rent when renting a home or an apartment. If you own real estate, you will be responsible for paying all utilities.</p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Buying Your New Home</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.zillow.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Find your new home with Zillow</a>
                            <a href="https://www.trulia.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Or find your new home with Trulia</a>
                            <a href="https://www.zillow.com/mortgage-learning/closing-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Closing costs</a>
                            <a href="http://www.homeclosing101.org/the-closing-process/closing-costs-explained/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Closing costs v2</a>
                            <a href="https://smartasset.com/mortgage/how-to-reduce-closing-costs" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Some tips for saving on closing costs</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Down Payment & Mortgage</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.zillow.com/mortgage-learning/down-payments/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What is a down payment and how much do I need to save?</a>
                            <a href="https://www.zillow.com/mortgage-learning/20-percent-down-payment/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Is 20% down payment ideal?</a>
                            <a href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How does a mortgage get repaid? What is amortization?</a>
                            <a href="https://www.thebalance.com/what-is-an-amortization-table-315520" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What does an example repayment schedule look like?</a>
                            <a href="http://www.bankrate.com/national-mortgage-rates/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Current mortgage rates by state</a>
                            <a href="https://www.moneyunder30.com/percentage-income-mortgage-payments" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much mortgage payments can you afford</a>
                            <a href="https://www.moneyunder30.com/get-approved-for-a-mortgage" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How to get approved for your first mortgage</a>
                            <a href="https://www.investopedia.com/terms/o/origination-fee.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>6 tips for getting approved for a mortgage</a>
                            <a href="https://www.bankrate.com/glossary/d/discount-point/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Loan points</a>
                            <a href="https://www.investopedia.com/terms/o/origination-fee.asp?lgl=myfinance-layout-no-ads" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Origination fees</a>
                            <a href="https://www.zillow.com/mortgage-learning/private-mortgage-insurance/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Private Mortgage Insurance (PMI): What is it?</a>
                            <a href="https://www.investopedia.com/ask/answers/081214/average-what-can-i-expect-my-private-mortgage-insurance-pmi-rate-be.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Private Mortgage Insurance (PMI): Why do I need it and what will it cost me?</a>
                            <a href="https://smartasset.com/credit-cards/what-is-a-good-debt-to-income-ratio" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Debt-to-Income ratio: Why it&apos;s important for buying real estate and financial health</a>

                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Appreciation</h3>
                          <p className={classes.quickLinksText}>
                            <a href="http://resources.point.com/8-biggest-factors-affect-real-estate-prices/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>8 factors that affect real estate prices</a>
                            <a href="https://www.zillow.com/home-values/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Appreciation data - search by state, city, and more</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Expenses of Owning a Home</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://wallethub.com/edu/states-with-the-highest-and-lowest-property-taxes/11585/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Property taxes by state</a>
                            <a href="https://www.deeds.com/articles/real-estate-transfer-taxes/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Transfer taxes by state</a>
                            <a href="https://www.gobankingrates.com/mortgage-rates/heres-costs-1126-month-maintain-average-american-home/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average monthly costs of owning a home</a>
                            <a href="https://www.thebalance.com/home-maintenance-budget-453820" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Estimating annual maintenance costs</a>
                            <a href="https://www.houselogic.com/organize-maintain/diy-repair/home-repair-costs/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Common DIY repair jobs and their costs</a>
                            <a href="https://www.nerdwallet.com/blog/insurance/understanding-homeowners-insurance/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Homeowner&apos;s Insurance in detail</a>
                            <a href="https://www.quickenloans.com/blog/what-do-hoa-fees-cover" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Association Fees (HOAs) and what they cover</a>
                            <a href="https://www.investopedia.com/articles/mortgages-real-estate/08/homeowners-associations-tips.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Some things you need to know about HOAs</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Taxes (See Your Income Statement for Detail)</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://turbotax.intuit.com/tax-tips/home-ownership/tax-breaks-and-home-ownership/L1EXrbgIg" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax breaks of home ownership</a>
                            <a href="https://www.nerdwallet.com/blog/mortgages/how-to-get-the-most-out-of-real-estate-tax-deductions/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Other home-related tax deductions</a>
                            <a href="https://www.nolo.com/legal-encyclopedia/determining-your-homes-tax-basis.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Determining your existing home&apos;s tax basis</a>
                            <a href="https://turbotax.intuit.com/tax-tips/home-ownership/tax-aspects-of-home-ownership-selling-a-home/L6tbMe3Dy" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Selling a home and the tax consequences</a>
                            <a href="https://taxfoundation.org/conference-report-tax-cuts-and-jobs-act/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax reform and how it affects home ownership</a>

                          </p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.buyingahomeWalkThru4) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 5 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Buying a Home" moduleSelected="module13" getModuleData={getModuleData} setUpdatedScenario={setupdatedBuyingAHomeValues} />
              )}
              {/* {dropdownvalues.compareSavedNotes && (
                <div className={ classes.sectionContent }>
                  <div className={ classes.panelRow }>
                    <div className={ classes.panelInfo }>
                      <div className={ classes.panelHeading }>
                        <div className={ classes.rowBlock }>
                          <div className={ classes.scenarioNotes }>
                            <div className={ classes.notesLeft }>
                              <ul className={ classes.listUnstyled }>
                                <li />
                              </ul>
                            </div>
                            <div className={ classNames( classes.notesRight, classes.scenarioNotesRight ) }>
                              <ul className={ classes.listUnstyled }>
                                <li><Button className={ classNames( classes.loadButton, classes.btnWarning ) }>Load</Button></li>
                                <li><Button className={ classNames( classes.saveButton, classes.btnWarning ) }>Save</Button></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={ classNames( classes.panelBody, classes.scenarioBlock ) }>
                        <Grid container>
                          <Grid item sm={ 3 } className={ classes.gridPadding } />
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario A</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Scenario B</h6>
                            <div className={ classes.scenarioPopup }>
                              <small>
                                <font>Select Scenario</font>
                              </small>
                            </div>
                          </Grid>
                          <Grid item sm={ 3 } className={ classes.gridPadding }>
                            <h6>Differences</h6>
                            <div>
                              <select>
                                <option value="Scenario A - Scenario B">Scenario A - Scenario B</option>
                                <option value="Scenario B - Scenario A">Scenario B - Scenario A</option>
                              </select>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 12 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>

      </div>
      <div>
        <Dialog
          open={popupOpen}
          onClose={() => { setPopupOpen(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={modalType === 'summary' ? classNames(classes.reiPopup, classes.SavePopup) : classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>

            <div>
              {modalType === 'save' && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedBuyingAHomeValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg={SetNotesSuccessMsg} />)}
            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>
      <div>
        <Dialog
          open={imagePopup}
          onClose={handleImagePopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.SavePopup}
        >
          <DialogContent className={classes.videoPopup}>

            <div>
              <span onClick={handleImagePopup} aria-hidden="true">close</span>
              <img src={imageURL} alt="" />
            </div>

            <div />
          </DialogContent>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={editPopup}
          onClose={handleEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.closingCostsModal}
        >
          <DialogContent className={classes.videoPopup}>
            <div className={classes.modalHeader}>
              <h2>Closing Costs</h2>
            </div>
            <div className={classes.modalBody}>
              <div className={classes.moduleRowNew}>
                <table className={classes.closingCosts}>
                  <tbody>
                    <tr>
                      <td>Appraisal Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_appraisal_fee} onValueChange={(e) => updateValue(e, 'closing_appraisal_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Credit Report Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_credit_report_fee} onValueChange={(e) => updateValue(e, 'closing_credit_report_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Attorney Fee: Title Closing/Escrow Account</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_escrow_account} onValueChange={(e) => updateValue(e, 'closing_escrow_account', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Flood Determination</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_flood_determination} onValueChange={(e) => updateValue(e, 'closing_flood_determination', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Tax Service Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_tax_service_fee} onValueChange={(e) => updateValue(e, 'closing_tax_service_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Title Insurance</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Recording Mortgage</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_recording_mortgage} onValueChange={(e) => updateValue(e, 'closing_recording_mortgage', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Homeowner&apos;s Insurance (3 months prepaid upfront)*</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Transfer Taxes</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Processing Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_processing_fee} onValueChange={(e) => updateValue(e, 'closing_processing_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Underwriting Fee</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_underwriting_fee} onValueChange={(e) => updateValue(e, 'closing_underwriting_fee', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Loan Points</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Origination Fees</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Mortgage Interest (1 month prepaid upfront)*</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Property Taxes (3 months prepaid upfront)*</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          0
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Pest Inspection</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_pest_Inspection} onValueChange={(e) => updateValue(e, 'closing_pest_Inspection', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Home Owners Association Transfer Fees</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_home_owners_association_transfer_fees} onValueChange={(e) => updateValue(e, 'closing_home_owners_association_transfer_fees', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td>
                        <span>$</span>
                        <span className={classes.numberInput}>
                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} thousandSeparator customInput={TextField} value={updatedBuyingAHomeValues.closing_costs_others} onValueChange={(e) => updateValue(e, 'closing_costs_others', 'number')} onFocus={(e) => e.target.select()} />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td><b>Total Closing Costs</b></td>
                      <td>                        
                        <span className={classes.numberInput}>
                          <b>{moduleServices.closingCosts() !== undefined ? (
                            <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.closingCosts()} prefix={moduleServices.closingCosts() >= 0 ? '$' : '($'} suffix={moduleServices.closingCosts() < 0 && ')'} />
                          ) : ('$0')}</b>
                        </span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <div>
                <Button className={classNames(classes.resetButton, classes.footerButton)} onClick={handleClosingCostReset}>Reset to default values</Button>
                <Button className={classNames(classes.okButton, classes.footerButton)} onClick={handleEditPopup}>Ok</Button>
                <Button className={classNames(classes.cancelButton, classes.footerButton)} onClick={handleEditPopup}>Cancel</Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module13' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      {openWorkSheet === true && <BuyingAHomeWorkSheet closeWorkSheet={setOpenWorkSheet} openWorkSheet={openWorkSheet} />}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={classes.modelPopupRow}
      >
        <DialogContent className={classes.modelPopup}>
          <div className={classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

BuyingAHomeWorksheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BuyingAHomeWorksheet);
