import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import styles from './styles';
import LifeEventImageOne from './life-event-image-one';
import LifeEventImageTwo from './life-event-image-two';
import LifeEventImageThree from './life-event-image-three';
import LifeEventImageFour from './life-event-image-four';
import LifeEventImageFive from './life-event-image-five';
import LifeEventImageSix from './life-event-image-six';
import LifeEventImageSeven from './life-event-image-seven';
import LifeEventImageEight from './life-event-image-eight';
import LifeEventImageNine from './life-event-image-nine';
import LifeEventImageTen from './life-event-image-ten';
import LifeEventImageEleven from './life-event-image-eleven';
import LifeEventImageTwelve from './life-event-image-tweleve';
import LifeEventImageThirteen from './life-event-image-thirteen';
import LifeEventImagefourteen from './life-event-image-fourteen';
import LifeEventImagefifteen from './life-event-image-fifteen';
import CommonDialoginfo from './common-dialog-info';
import libraryBanner from '../../assets/img/life-event-library/pricing_page_banner.jpg';
import e1 from '../../assets/img/life-event-library/modules_slider_img_1_new.png';
import e2 from '../../assets/img/life-event-library/existing_assets_life_eve_img_1.jpg';
import e3 from '../../assets/img/life-event-library/modules_slider_img_3_new_2.png';
import e4 from '../../assets/img/life-event-library/modules_slider_img_4.png';
import e5 from '../../assets/img/life-event-library/modules_slider_img_5.png';
import e6 from '../../assets/img/life-event-library/modules_slider_img_6_new.png';
import e7 from '../../assets/img/life-event-library/modules_slider_img_7.png';
import e8 from '../../assets/img/life-event-library/modules_slider_img_8.png';
import e9 from '../../assets/img/life-event-library/modules_slider_img_9.png';
import e10 from '../../assets/img/life-event-library/modules_slider_img_10.png';
import e11 from '../../assets/img/life-event-library/modules_slider_img_11.png';
import e12 from '../../assets/img/life-event-library/modules_slider_img_12.png';
import e13 from '../../assets/img/life-event-library/modules_slider_img_13.png';
import e14 from '../../assets/img/life-event-library/modules_slider_img_14.png';
import e15 from '../../assets/img/life-event-library/modules_slider_img_15.png';

const LifeEventLibrary = ( props ) => {
  const { classes } = props;
  const [imageValue, setImageValue] = React.useState( false );
  const [imageData, setImageData] = React.useState( '' );
  const [startYearPopup, setStartYearPopup] = React.useState( false );

  const handleLifeEventImage = ( value ) => {
    setImageValue( true );
    setImageData( value );
  };

  const handleCloseEvent = () => {
    setImageValue( false );
  };

  const openStartYear = ( status ) => {
    setStartYearPopup( status );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.libraryPage }>
        <div className={ classes.librarybanner }>
          <div className={ classes.libraryImage }>
            <img src={ libraryBanner } alt="banner" />
            <div className={ classes.bannerCaption }>
              <div className={ classes.bannerCaptionTitle }>
                <Typography variant="h3" component="h3">
                  Life Event Library
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.libraryContent }>
          <div className={ classes.libraryText }>
            <div className={ classes.container }>
              <Typography variant="h2" component="h2">
                Your life in Inkwiry – a global view.
              </Typography>
              <p>Consider 15 major life events.</p>
            </div>
          </div>
          <div className={ classes.eventsBlock }>
            <div className={ classes.container }>
              <div className={ classes.eventsRow }>
                <Grid container className={ classes.eventsGrid }>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e1 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image1' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e2 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image2' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e3 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image3' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={ classes.eventsRow }>
                <Grid container>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e4 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image4' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e5 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image5' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e6 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image6' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={ classes.eventsRow }>
                <Grid container>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e7 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image7' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e8 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image8' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e9 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image9' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={ classes.eventsRow }>
                <Grid container>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e10 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image10' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e11 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image11' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e12 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image12' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className={ classes.eventsRow }>
                <Grid container>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e13 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image13' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e14 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image14' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                  <Grid item sm={ 4 }>
                    <div className={ classes.eventsImage }>
                      <figure>
                        <img
                          src={ e15 }
                          alt="event"
                          aria-hidden="true"
                          onClick={ () => {
                            handleLifeEventImage( 'image15' );
                          } }
                        />
                      </figure>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={ classes.videoModalPopup }>
        <Dialog
          open={ imageValue }
          onClose={ handleCloseEvent }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.libraryModalPopup }
        >
          <DialogContent className={ classes.libraryPopup }>
            <div className={ classes.popupClose }>
              <span aria-hidden="true" onClick={ handleCloseEvent }>
                X
              </span>
            </div>
            <div id="life-event-library">
              {imageData === 'image1' && ( <LifeEventImageOne openNoAccessPopup={ openStartYear } name="image1" /> )}
              {imageData === 'image2' && ( <LifeEventImageTwo openNoAccessPopup={ openStartYear } name="image2" /> )}
              {imageData === 'image3' && ( <LifeEventImageThree openNoAccessPopup={ openStartYear } name="image3" /> )}
              {imageData === 'image4' && ( <LifeEventImageFour openNoAccessPopup={ openStartYear } name="image4" /> )}
              {imageData === 'image5' && ( <LifeEventImageFive openNoAccessPopup={ openStartYear } name="image5" /> )}
              {imageData === 'image6' && ( <LifeEventImageSix openNoAccessPopup={ openStartYear } name="image6" /> )}
              {imageData === 'image7' && ( <LifeEventImageSeven openNoAccessPopup={ openStartYear } name="image7" /> )}
              {imageData === 'image8' && ( <LifeEventImageEight openNoAccessPopup={ openStartYear } name="image8" /> )}
              {imageData === 'image9' && ( <LifeEventImageNine openNoAccessPopup={ openStartYear } name="image9" /> )}
              {imageData === 'image10' && ( <LifeEventImageTen openNoAccessPopup={ openStartYear } name="image10" /> )}
              {imageData === 'image11' && ( <LifeEventImageEleven openNoAccessPopup={ openStartYear } name="image11" /> )}
              {imageData === 'image12' && ( <LifeEventImageTwelve openNoAccessPopup={ openStartYear } name="image12" /> )}
              {imageData === 'image13' && ( <LifeEventImageThirteen openNoAccessPopup={ openStartYear } name="image13" /> )}
              {imageData === 'image14' && ( <LifeEventImagefourteen openNoAccessPopup={ openStartYear } name="image14" /> )}
              {imageData === 'image15' && ( <LifeEventImagefifteen openNoAccessPopup={ openStartYear } name="image15" /> )}
            </div>
          </DialogContent>
        </Dialog>

        <div className={ classes.getStartedPopup }>
          <Dialog
            open={ startYearPopup }
            onClose={ () => { openStartYear( false ); } }
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={ classes.getStartedPopupDialog }
          >
            <DialogContent className={ classes.getStartedContent }>
              <div className={ classes.popupMainLifeEvent }>
                <CommonDialoginfo />
              </div>

            </DialogContent>
          </Dialog>
        </div>

      </div>
    </Typography>
  );
};

LifeEventLibrary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( LifeEventLibrary );
