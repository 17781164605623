import { PMT, CUMIPMT } from '../../utilities/excelLibrary';

function fedUnSubNewHigherEdLoanSheet( module1Data, module15Data ) {
  // Student Loan Excel Sheet
  let studentLoanData = {};
  let summationData = {};
  const repaymentData = {};
  const repaymentData1 = {};
  let paymentPlanIdentifier = 3;
  if ( typeof module15Data.fed_unsub_paymentplan !== 'undefined'
    && module15Data.fed_unsub_paymentplan !== null && module15Data.fed_unsub_paymentplan !== 'null' ) {
    if ( module15Data.fed_unsub_paymentplan !== ''
      && module15Data.fed_unsub_paymentplan === 1 ) {
      paymentPlanIdentifier = 2;
    }
  }

  // Loan Amount
  let loanAmount = 0;
  if ( typeof module15Data.newFedUnSubPrincipal !== 'undefined' && module15Data.newFedUnSubPrincipal !== '' ) {
    loanAmount = module15Data.newFedUnSubPrincipal;
  }

  // Beginning Balance
  let beginningBalance = 0;
  if ( typeof summationData !== 'undefined'
    && typeof module15Data.newFedSubPaymentYearBegins !== 'undefined' && module15Data.newFedSubPaymentYearBegins !== ''
    && typeof summationData[module15Data.newFedSubPaymentYearBegins] !== 'undefined'
    && typeof summationData[module15Data.newFedSubPaymentYearBegins].BeginningBalance !== 'undefined' ) {
    beginningBalance = summationData[module15Data.newFedSubPaymentYearBegins].BeginningBalance;
  }

  // Terms in Years
  let termsOfYear = 0;
  if ( beginningBalance > 0 ) {
    termsOfYear = parseFloat( module15Data.fed_unsub_term );
  }

  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( typeof module15Data.fed_unsub_interest_rate !== 'undefined' && module15Data.fed_unsub_interest_rate !== '' ) {
    annualInterestRate = parseFloat( module15Data.fed_unsub_interest_rate );
  }

  // Original Payment
  let originalPayment = 0;
  if ( beginningBalance > 0
    && termsOfYear > 0 ) {
    originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( beginningBalance ), 0, 0 );
  
  }

  // Interest Accrued
  let interestAccrued = 0;
  if ( paymentPlanIdentifier === 3
    && beginningBalance > 0
    && loanAmount > 0 ) {
    interestAccrued = beginningBalance - loanAmount;
  }

  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let graduationYear = 0;

  if ( typeof module15Data.newFedSubPaymentYearBegins !== 'undefined' ) {
    graduationYear = module15Data.newFedSubPaymentYearBegins;
  }
  if ( graduationYear > 0 ) {
    let year = parseInt( graduationYear, 10 );
    const yearLimit = year + 29;

    // Student Loan Data
    studentLoanData = {};

    let Balance = beginningBalance;
    const Payment = originalPayment;
    let Interest = 0;
    if ( annualInterestRate > 0 ) {
      Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
    }
    let Principal = Payment - Interest;
    let Equity = Principal;
    let TotalInterest = Interest;
    const TotalPayment = Equity + TotalInterest;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];
    let k = 0;
    let finalSubmonth = 1;

    // For Loop from graduation year to +10 years
    for ( year; year <= yearLimit; year += 1 ) {
      studentLoanData[year] = {};

      studentLoanData[year].LaggingInterest = 0;
      studentLoanData[year].LaggingPrincipal = 0;
      studentLoanData[year]['Months Total Balance'] = 0;

      // For Loop for 12 months
      let condition = 12;
      if ( k === 0 ) {
        condition = 6;
      }
      k += 1;

      for ( let month = 1; month <= condition; month += 1 ) {
        studentLoanData[year][month] = {};
        if ( year === graduationYear && month === 1 ) {
          studentLoanData[year][month].YearIdentifier = year;
          studentLoanData[year][month].Balance = Balance;
          studentLoanData[year][month].Payment = Payment;
          studentLoanData[year][month].Interest = Interest;
          studentLoanData[year][month].Principal = Principal;
          studentLoanData[year][month].Equity = Equity;
          studentLoanData[year][month].TotalInterest = TotalInterest;
          studentLoanData[year][month].TotalPayment = TotalPayment;
          studentLoanData[year][month].Month = k * month;
        } else {
          // Year Identifier
          studentLoanData[year][month].YearIdentifier = year;

          // Balance
          let tempBalance = 0;
          if ( Balance === 0 ) {
            tempBalance = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempBalance = ( Balance - Principal );
          }
          studentLoanData[year][month].Balance = tempBalance;

          if ( tempBalance > 0 ) {
            finalSubmonth += 1;
            studentLoanData['Final Month'] = finalSubmonth;
            studentLoanData['Final Year'] = year;
          } else {
            finalSubmonth = 0;
          }

          studentLoanData[year][month].Month = finalSubmonth;

          // Payment
          studentLoanData[year][month].Payment = Payment;

          // Interest
          let tempInterest = 0;
          if ( Balance === 0 ) {
            tempInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          }
          studentLoanData[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal = 0;
          if ( Balance === 0 ) {
            tempPrincipal = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempPrincipal = Payment - tempInterest;
          }
          studentLoanData[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity = 0;
          if ( Balance === 0 ) {
            tempEquity = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempEquity = Equity + tempPrincipal;
          }
          studentLoanData[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest = 0;
          if ( Balance === 0 ) {
            tempTotalInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalInterest = TotalInterest + tempInterest;
          }
          studentLoanData[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment = 0;
          if ( Balance === 0 ) {
            tempTotalPayment = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 0 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          }
          studentLoanData[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( studentLoanData[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( studentLoanData[year][month].TotalPayment );

        studentLoanData[year].LaggingInterest = parseFloat( studentLoanData[year].LaggingInterest ) + parseFloat( studentLoanData[year][month].Interest );
        studentLoanData[year].LaggingPrincipal = parseFloat( studentLoanData[year].LaggingPrincipal ) + parseFloat( studentLoanData[year][month].Principal );
        studentLoanData[year]['Months Total Balance'] = parseFloat( studentLoanData[year]['Months Total Balance'] ) + parseFloat( studentLoanData[year][month].Balance );
      }
    }
  }

  // Summation Data
  if ( typeof module1Data.start_year !== 'undefined'
    && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 29;

    // Summation Data
    summationData = {};

    let summationLoansTakenValue = 0;
    let summationCumulativeLoansTakenValue = 0;
    let summationBeginningBalance = 0;
    let summationBeginningBalance1 = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationCumulativeInterest = 0;
    let summationCumulativePrincipal = 0;
    let summationEndingBalance = 0;
    let summationEndingBalance1 = 0;
    let summationAccruedInterest = 0;
    let summationCapitalizedInterest = 0;
    let summationCapitalizedInterestTaxDeduction = 0;
    let summationRemainingCapitalizedInterest = 0;
    let summationPointsPaid = 0;
    let interestPaidBeforeRepayment = 0;
    let totalInterestPaidBeforeRepayment = 0;
    let wouldBeAccruedInterest = 0;
    let totalWouldBeAccruedInterest = 0;

    // For Loop from Start year to +14 years
    for ( summationYear; summationYear <= summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // Loans Taken
      summationLoansTakenValue = 0;
      if ( summationYear === module15Data.start_year ) {
        summationLoansTakenValue = module15Data.fed_unsub_firstyear;
      } else if ( summationYear === module15Data.start_year + 1 ) {
        summationLoansTakenValue = module15Data.fed_unsub_secondyear;
      } else if ( summationYear === module15Data.start_year + 2 ) {
        summationLoansTakenValue = module15Data.fed_unsub_thirdyear;
      } else if ( summationYear === module15Data.start_year + 3 ) {
        summationLoansTakenValue = module15Data.fed_unsub_fourthyear;
      } else if ( summationYear === module15Data.start_year + 4 ) {
        summationLoansTakenValue = module15Data.fed_unsub_fifthyear;
      } else if ( summationYear === module15Data.start_year + 5 ) {
        summationLoansTakenValue = module15Data.fed_unsub_sixthyear;
      } else {
        summationLoansTakenValue = 0;
      }
      summationData[summationYear]['Loans Taken'] = summationLoansTakenValue;

      // Cumulative Loans Taken
      summationCumulativeLoansTakenValue += summationLoansTakenValue;
      summationData[summationYear]['Cumulative Loans Taken'] = summationCumulativeLoansTakenValue;

      // Accrued Interest
      if ( paymentPlanIdentifier === 3
        && summationYear >= module15Data.start_year
        && summationYear <= module15Data.newFedSubPaymentYearBegins ) {
        if ( summationYear === module15Data.newFedSubPaymentYearBegins ) {
          // summationAccruedInterest = ( ( summationCumulativeLoansTakenValue / 100 ) * this.annualInterestRate ) * 6;
          summationAccruedInterest = ( ( ( summationCumulativeLoansTakenValue / 100 ) * annualInterestRate ) / 12 ) * 6;
        } else {
          summationAccruedInterest = ( summationCumulativeLoansTakenValue / 100 ) * annualInterestRate;
        }
      } else {
        summationAccruedInterest = 0;
      }
      summationData[summationYear]['Accrued Interest'] = summationAccruedInterest;

      // Beginning Balance 1
      if ( typeof module15Data.start_year !== 'undefined' && module15Data.start_year !== ''
        && summationYear === module15Data.start_year ) {
        summationBeginningBalance1 = summationLoansTakenValue;
      } else if ( summationYear >= module15Data.start_year
          && summationYear <= module15Data.newFedSubPaymentYearBegins ) {
        if ( summationYear === module15Data.newFedSubPaymentYearBegins ) {
          summationBeginningBalance1 = summationEndingBalance1 + summationAccruedInterest + summationLoansTakenValue;
        } else {
          summationBeginningBalance1 = summationEndingBalance1 + summationLoansTakenValue;
        }
      } else {
        summationBeginningBalance1 = 0;
      }
      summationData[summationYear]['BeginningBalance 1'] = summationBeginningBalance1;

      // Ending Balance 1
      if ( summationYear === module15Data.newFedSubPaymentYearBegins ) {
        summationEndingBalance1 = summationBeginningBalance1;
      } else {
        summationEndingBalance1 = summationBeginningBalance1 + summationAccruedInterest;
      }
      summationData[summationYear]['EndingBalance 1'] = summationEndingBalance1;

      // Capitalized Interest
      if ( summationAccruedInterest > 0 ) {
        summationCapitalizedInterest += summationAccruedInterest;
      } else {
        summationCapitalizedInterest = summationRemainingCapitalizedInterest;
      }
      summationData[summationYear]['Capitalized Interest'] = summationCapitalizedInterest;

      // Beginning Balance
      if ( summationYear < module15Data.startYear ) {
        summationBeginningBalance = 0;
      } else if ( summationYear >= module15Data.startYear
          && summationYear <= module15Data.newFedSubPaymentYearBegins ) {
        summationBeginningBalance = summationBeginningBalance1;
      } else {
        summationBeginningBalance = summationEndingBalance;
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      let val1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof studentLoanData[summationYear] !== 'undefined' && typeof studentLoanData[summationYear].LaggingInterest !== 'undefined' ) {
          val1 = studentLoanData[summationYear].LaggingInterest;
        } else {
          val1 = 0;
        }
      }
      let val2 = 0;
      if ( paymentPlanIdentifier === 2
        && summationYear < module15Data.newFedSubPaymentYearBegins ) {
        val2 = summationBeginningBalance * ( annualInterestRate / 100 );
      }
      let val3 = 0;
      if ( paymentPlanIdentifier === 2
        && summationYear === module15Data.newFedSubPaymentYearBegins ) {
        val3 = summationBeginningBalance * ( ( annualInterestRate / 100 ) / 2 );
      }

      summationLaggingInterest = val1 + val2 + val3;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;
      totalInterestPaid = totalInterestPaid + summationLaggingInterest + summationAccruedInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof studentLoanData[summationYear] !== 'undefined'
          && typeof studentLoanData[summationYear].LaggingPrincipal !== 'undefined' ) {
          summationLaggingPrincipal = studentLoanData[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;
      totalPaid = totalPaid + summationLaggingInterest + summationLaggingPrincipal;

      // Capitalized Interest Tax Deduction
      if ( summationLaggingPrincipal > 0 ) {
        if ( summationCapitalizedInterest > summationLaggingPrincipal ) {
          summationCapitalizedInterestTaxDeduction = summationLaggingPrincipal;
        } else if ( summationCapitalizedInterest > 0 ) {
          summationCapitalizedInterestTaxDeduction = summationCapitalizedInterest;
        } else {
          summationCapitalizedInterestTaxDeduction = 0;
        }
      } else {
        summationCapitalizedInterestTaxDeduction = 0;
      }
      summationData[summationYear]['Capitalized Interest Tax Deduction'] = summationCapitalizedInterestTaxDeduction;

      // Remaining Capitalized Interest
      summationRemainingCapitalizedInterest = summationCapitalizedInterest - summationCapitalizedInterestTaxDeduction;
      summationData[summationYear]['Remaining Capitalized Interest'] = summationRemainingCapitalizedInterest;

      // Cumulative Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationCumulativeInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationCumulativeInterest = 0;
      }
      summationData[summationYear].CumulativeInterest = summationCumulativeInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationCumulativePrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationCumulativePrincipal = 0;
      }
      summationData[summationYear].CumulativePrincipal = summationCumulativePrincipal;

      // Ending Balance
      if ( summationYear < module15Data.newFedSubPaymentYearBegins ) {
        summationEndingBalance = summationEndingBalance1;
      } else {
        summationEndingBalance = summationBeginningBalance - summationLaggingPrincipal;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;

      // Points Paid
      if ( summationLoansTakenValue > 0 ) {
        summationPointsPaid = summationLoansTakenValue * ( module15Data.fed_unsub_origination_fee / 100 );
      } else {
        summationPointsPaid = 0;
      }
      summationData[summationYear]['Points Paid'] = summationPointsPaid;
      // Origination Fees
      summationData[summationYear]['Origination Fees'] = summationPointsPaid;

      // Interest Paid before Repayment
      if ( paymentPlanIdentifier === 2 ) {
        if ( typeof module15Data.newFedSubPaymentYearBegins !== 'undefined' && module15Data.newFedSubPaymentYearBegins !== '' && summationYear === module15Data.newFedSubPaymentYearBegins ) {
          interestPaidBeforeRepayment = ( ( ( summationData[summationYear].BeginningBalance * annualInterestRate ) / 12 ) / 100 ) * 6;
        } else if ( typeof module15Data.newFedSubPaymentYearBegins !== 'undefined' && module15Data.newFedSubPaymentYearBegins !== '' && summationYear < module15Data.newFedSubPaymentYearBegins ) {
          interestPaidBeforeRepayment = summationData[summationYear].LaggingInterest;
        } else {
          interestPaidBeforeRepayment = 0;
        }
      } else {
        interestPaidBeforeRepayment = 0;
      }
      totalInterestPaidBeforeRepayment += interestPaidBeforeRepayment;
      summationData[summationYear]['Interest Paid before Repayment'] = interestPaidBeforeRepayment;

      // Would Be Accrued Interest
      if ( summationYear < module15Data.newFedSubPaymentYearBegins ) {
        wouldBeAccruedInterest = ( ( ( summationCumulativeLoansTakenValue / 100 ) * annualInterestRate ) / 12 ) * 12;
      } else if ( summationYear === module15Data.newFedSubPaymentYearBegins ) {
        wouldBeAccruedInterest = ( ( ( summationCumulativeLoansTakenValue / 100 ) * annualInterestRate ) / 12 ) * 6;
      } else {
        wouldBeAccruedInterest = 0;
      }
      summationData[summationYear]['Would Be Accrued Interest'] = wouldBeAccruedInterest;
      totalWouldBeAccruedInterest += wouldBeAccruedInterest;
    }
    // To get Repayment Year Values
    repaymentData['Interest Rate'] = annualInterestRate;
    repaymentData['Payment Period'] = 'Monthly';
    repaymentData['Term of the Loan (years)'] = termsOfYear;
    repaymentData['Loan Amount'] = loanAmount;
    if ( paymentPlanIdentifier === 2 ) {
      repaymentData['Interest Paid before Repayment'] = totalInterestPaidBeforeRepayment;
    } else {
      repaymentData['Interest Paid before Repayment'] = 0;
    }
    if ( paymentPlanIdentifier === 3 ) {
      repaymentData['Accrued Interest'] = interestAccrued;
    } else {
      repaymentData['Accrued Interest'] = 0;
    }
    repaymentData['Beginning Balance after Grace Period'] = beginningBalance;
    repaymentData['Monthly Payment'] = originalPayment;
    
    repaymentData['Total Interest Paid'] = totalInterestPaid;
    repaymentData['Total Repayment Amount (Principal & Interest)'] = totalPaid;

    // To get New Repayment Year Values
    repaymentData1['Interest Rate'] = annualInterestRate;
    repaymentData1['Payment Period'] = 'Monthly';
    repaymentData1['Term of the Loan (years)'] = termsOfYear;
    repaymentData1['Loan Amount'] = loanAmount;
    if ( paymentPlanIdentifier === 3 ) {
      repaymentData1['Interest Paid before Repayment'] = totalWouldBeAccruedInterest;
    } else {
      repaymentData1['Interest Paid before Repayment'] = 0;
    }
    if ( paymentPlanIdentifier === 2 ) {
      repaymentData1['Accrued Interest'] = totalInterestPaidBeforeRepayment;
    } else {
      repaymentData1['Accrued Interest'] = 0;
    }
    repaymentData1['Beginning Balance after Grace Period'] = loanAmount + repaymentData1['Accrued Interest'];
	if( termsOfYear !== 0 ){
    repaymentData1['Monthly Payment'] = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, repaymentData1['Term of the Loan (years)'] * 12, -( repaymentData1['Beginning Balance after Grace Period'] ), 0, 0 );
	} else{
	    repaymentData1['Monthly Payment'] = 0;		
	}
    repaymentData1['Total Interest Paid'] = -CUMIPMT( ( annualInterestRate / 100 ) / 12, repaymentData1['Term of the Loan (years)'] * 12, repaymentData1['Beginning Balance after Grace Period'], 1, repaymentData1['Term of the Loan (years)'] * 12, 0 ) + repaymentData1['Interest Paid before Repayment'] + repaymentData1['Accrued Interest'];

    repaymentData1['Total Repayment Amount (Principal & Interest)'] = loanAmount + repaymentData1['Total Interest Paid'];
  }

  function studentLoanDataFun() {
    const studentLoanCompleteData = {};
    studentLoanCompleteData['Summation Data'] = summationData;
    studentLoanCompleteData['Total Interest Paid'] = totalInterestPaid;
    studentLoanCompleteData['Total Paid'] = totalPaid;
    studentLoanCompleteData['Annual Interest Rate'] = annualInterestRate;
    studentLoanCompleteData['Fed Sub Higher Education'] = studentLoanData;
    studentLoanCompleteData['Loan Amount'] = loanAmount;
    studentLoanCompleteData['Accrued Interest'] = interestAccrued;
    studentLoanCompleteData['Beginning Balance'] = beginningBalance;
    studentLoanCompleteData['Terms In Year'] = termsOfYear;
    studentLoanCompleteData['Annual Interest Rate'] = annualInterestRate;
    studentLoanCompleteData['Monthly Payment'] = originalPayment;
    studentLoanCompleteData['Repayment Data'] = repaymentData;
    studentLoanCompleteData['Repayment Data New'] = repaymentData1;

    return studentLoanCompleteData;
  }
  return studentLoanDataFun();
}
export default fedUnSubNewHigherEdLoanSheet;
