import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';

const CollaborationSetting = ( props ) => {
  const { classes, updateSaveSettingsData, myDetails } = props;

  const [state, setState] = useState( {
    allChecked: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_details === '1' ) ) ),
    checkedUsername: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_username === '1' ) ) ),
    checkedFullName: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_fullname === '1' ) ) ),
    checkedTitle: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_title === '1' ) ) ),
    checkedEmail: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_email === '1' ) ) ),
    checkedAddress: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_address === '1' ) ) ),
    checkedCity: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_city === '1' ) ) ),
    checkedState: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_state === '1' ) ) ),
    checkedZipCode: ( !!( ( myDetails && myDetails.me.account_setting && myDetails.me.account_setting.search_zipcode === '1' ) ) ),
  } );

  const handleChecked = ( event ) => {
    if ( event.target.name === 'allChecked' && event.target.checked === false ) {
      setState( {
        allChecked: false,
        checkedUsername: false,
        checkedFullName: false,
        checkedTitle: false,
        checkedEmail: false,
        checkedAddress: false,
        checkedCity: false,
        checkedState: false,
        checkedZipCode: false,
      } );
    } else {
      setState( { ...state, [event.target.name]: event.target.checked } );
    }
    updateSaveSettingsData( event.target.id, event.target.checked );
  };

  return (
    <div className={ classes.billingInformation }>
      <div className={ classes.billingHistoryNewOne }>
        <div className={ classes.billingHead }>
          <h3>Collaboration Settings</h3>
          <p className={ classes.hidle }>
            In order to collaborate in Inkwiry, you must check the
            first box below. This allows other users to find your contact information,
            add you to their contacts, and start sharing scenarios and sketches.
            The settings that follow will allow other users to search the Inkwiry
            universe for only the specific pieces of information that you elect to share.
            If you don&apos;t want to collaborate, do not check the first box below. You are in
            total control of the information that you allow other users to see with the
            check boxes below.
          </p>
        </div>
        <div className={ `${classes.checkBoxBlock} ${classes.collbrationCheckGap}` }>
          <div className={ classes.formGroupCheckBox }>
            <Checkbox checked={ state.allChecked } id="search_details" onChange={ handleChecked } name="allChecked" />
            <p>Allow other Inkwiry users to add me to their contacts by searching the following details</p>
          </div>
          <div className={ classes.borderLine } />
          <div className={ !state.allChecked && classes.disableCheckbox }>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedUsername } id="search_username" onChange={ handleChecked } name="checkedUsername" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my username</p>
            </div>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedFullName } id="search_fullname" onChange={ handleChecked } name="checkedFullName" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my full name</p>
            </div>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedTitle } id="search_title" onChange={ handleChecked } name="checkedTitle" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my title</p>
            </div>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedEmail } id="search_email" onChange={ handleChecked } name="checkedEmail" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my email</p>
            </div>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedAddress } id="search_address" onChange={ handleChecked } name="checkedAddress" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my street address</p>
            </div>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedCity } id="search_city" onChange={ handleChecked } name="checkedCity" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my city</p>
            </div>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedState } id="search_state" onChange={ handleChecked } name="checkedState" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my state</p>
            </div>
            <div className={ classes.formGroupCheckBox }>
              <Checkbox checked={ state.checkedZipCode } id="search_zipcode" onChange={ handleChecked } name="checkedZipCode" disabled={ state.allChecked !== true } />
              <p>Allow other Inkwiry users to search for my zip code</p>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

CollaborationSetting.propTypes = {
  classes: PropTypes.object.isRequired,
  myDetails: PropTypes.object.isRequired,
  updateSaveSettingsData: PropTypes.func.isRequired,
};

export default withStyles( styles )( CollaborationSetting );
