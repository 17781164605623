import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NumberFormat from 'react-number-format';
import styles from './styles';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
  colors: ['#389BD2', '#FF7322', '#A7A39E', '#FFBE00', '#2A70BF', '#58AD4B', '#005F89', '#B04400', '#63625D', '#A46E00', '#1A466F', '#366731', '#66AFDB', '#FF8F4E', '#BBB7B0', '#FFCB30']
} );

const SummaryOutput = (props) => {
  const {
    classes, dispalyObj
  } = props;


  return (
    <div className={classes.modalBodyData}>
        <div>
          <div className={classes.summaryGraphSection}>
            {/*Net worth chart*/}
            {dispalyObj.graph1 === true && (
              <div>
                  <h4>Grow Your Net Worth: What's Your 5, 10, 15 Year Number?</h4>    
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph1Data}
                  />
              </div>
            )}
            
            {/*Picasso chart*/}
            {dispalyObj.graph2 === true && (
              <div>
                <hr/>
                <div>
                  <h4>What Does Your Picasso Look Like?</h4>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph2Data}
                  />
                </div>
              </div>
            )}

            {/*Pie chart 1*/}
            {dispalyObj.graph3 === true && (
              <div>
                  <hr/>
                  <div>
                    <h4>Taxes Breakdown: Total Taxes by Category</h4>
                    <HighchartsReact
                      highcharts={ Highcharts }
                      options={dispalyObj.graph3Data}
                    />
                    <div className={classes.totalValuesDiv}>Total 15-Year Taxes Paid:&nbsp;
                      <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ dispalyObj.graph3Value } prefix={ dispalyObj.graph3Value >= 0 ? '$' : '($' } suffix={ dispalyObj.graph3Value < 0 && ')' } />
                    </div>
                  </div>
               </div>
            )}

            {/*Pie chart 2*/}
            {dispalyObj.graph4 === true && (
              <div>
                  <hr/>
                  <div>
                    <h4>Spending Breakdown: Total Expenses by Category</h4>
                    <HighchartsReact
                      highcharts={ Highcharts }
                      options={dispalyObj.graph4Data}
                    />
                    <div className={classes.totalValuesDiv}>Total 15-Year Living Expenses:&nbsp;
                      <NumberFormat decimalScale={ 0 } allowNegative={ false } fixedDecimalScale  displayType="text" thousandSeparator value={ dispalyObj.graph4Value } prefix={ dispalyObj.graph4Value >= 0 ? '$' : '($' } suffix={ dispalyObj.graph4Value < 0 && ')' } />
                    </div>
                  </div>
               </div>
            )}

            {/*Short-Term Investments Gra*/}
            {dispalyObj.graph5 === true && (
              <div>
                <hr/>
                <div>
                  <h4>Managing Cash Flow: Short-Term Investments</h4>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph5Data}
                  />
                </div>
              </div>
            )}

            {/*Long-Term Investments Graph*/}
            {dispalyObj.graph6 === true && (
              <div>
                <hr/>
                <div>
                  <h4>Managing Cash Flow: Long-Term Investments</h4>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph6Data}
                  />
                </div>
              </div>
            )}

            {/*401(k) Retirement Account Graph*/}
            {dispalyObj.graph7 === true && (
              <div>
                <hr/>
                <div>
                  <h4>401(k) Retirement Account: Sensitivity Analysis</h4>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph7Data}
                  />
                  <span>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                </div>
              </div>
            )} 

            {/*Roth IRA Retirement Account: Sensitivity Analysis*/}
            {dispalyObj.graph8 === true && (
              <div>
                <hr/>
                <div>
                  <h4>Roth IRA Retirement Account: Sensitivity Analysis</h4>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph8Data}
                  />
                  <span>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                </div>
              </div>
            )}

            {/*SEP IRA Retirement Account: Sensitivity Analysis*/}
            {dispalyObj.graph9 === true && (
              <div>
                <hr/>
                <div>
                  <h4>Roth IRA Retirement Account: Sensitivity Analysis</h4>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph9Data}
                  />
                  <span>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                </div>
              </div>
            )}

            {/*Pension Retirement Account: Sensitivity Analysis*/}
            {dispalyObj.graph10 === true && (
              <div>
                <hr/>
                <div>
                  <h4>Roth IRA Retirement Account: Sensitivity Analysis</h4>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={dispalyObj.graph10Data}
                  />
                  <span>Note: In years where there are no contributions, the FC assumes a 5% Annual Pre-Tax Return</span>
                </div>
              </div>
            )}
          </div>
        </div>
    </div>
  );
};

SummaryOutput.propTypes = {
  classes: PropTypes.object.isRequired,
  dispalyObj: PropTypes.object.isRequired,
};

export default withStyles( styles )( SummaryOutput );
