import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {
  Dialog, DialogContent,DialogTitle,DialogActions
} from '@material-ui/core';
import logo1 from '../../assets/img/logo-footer.png';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Slider from 'react-slick';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import classNames from 'classnames';
import styles from './styles';
import Slide1 from '../../assets/img/santiago-story/santigo_story_img_1.jpg';
import mikeStory3 from '../../assets/img/santiago-story/santigo_story_img_3.jpg';
import higherEducationIcon from '../../assets/img/mike-story/calvin_m_green_icon.png';
import MikeGreen from '../../assets/img/mike-story/calvin_m_yellow_icon.png';
import MikeBlue from '../../assets/img/mike-story/calvin_m_blue_icon.png';
import MikeBlue2 from '../../assets/img/mike-story/calvin_m_light_blue_icon.png';
import MikeSlide5 from '../../assets/img/santiago-story/santigo_story_img_5.jpg';
import mikeStory4 from '../../assets/img/santiago-story/santigo_story_img_4.jpg';
import MikeSlide6 from '../../assets/img/santiago-story/santigo_story_img_6.jpg';
import MikeSlide7 from '../../assets/img/santiago-story/santigo_story_img_7.jpg';
import MikeSlide6Inner from '../../assets/img/mike-story/calvin_m_light_blue_icon_cloud.png';
import MikeSlide7Inner2 from '../../assets/img/mike-story/calvin_m_red_icon.png';
import MikeSlide8 from '../../assets/img/santiago-story/santigo_story_img_8.jpg';
import MikeSlide8Inner2 from '../../assets/img/mike-story/calvin_m_yellow_car_icon.png';
import MikeSlide9 from '../../assets/img/santiago-story/santigo_story_img_9.jpg';
import MikeSlide9Inner from '../../assets/img/mike-story/calvin_m_yellow_home_icon.png';
import MikeSlide12 from '../../assets/img/santiago-story/santigo_story_img_10.jpg';
import MikeSlide7Inner from '../../assets/img/mike-story/calvin_m_light_green_icon.png';
import forwordIcon from '../../assets/img/mike-story/share_icon.png';
import replayIcon from '../../assets/img/mike-story/dollar-icon.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import TimeLineList from '../../components/time-line/time-line-list';
import NetworthRangeGraph from '../../components/time-line/networth-range-graph';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import getCalculatedValues from '../../calculations/calculated-values';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SummaryDocs from '../../components/documents/documents';
import Summarytable from '../../components/documents/summary-tables';

const MODULE_DATA = loader( '../../graphql/schema/fc/story-module-data.graphql' );

const settings = {
  dots: true,
  infinite: true,
  speed: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Santiago = ( props ) => {
  const { classes, history } = props;

  const [forwardPopup, setForwordPopup] = useState( false );
  const [replyPopup, setReplyPopup] = useState( false );
  const [SummaryPopup, setSummaryPopup] = useState( false );
  const [linkId, setLinkId] = useState( false );
  // const [higherEducationPopup, setHigherEducationPopup] = useState( false );
  // const [careerPathPopup, setCareerPathPopup] = useState( false );
  // const [investmentsPopup, setInvestmentsPopup] = useState( false );
  // const [careerAdvancementPopup, setCareerAdvancementPopup] = useState( false );
  // const [additionalCareerAdvancementPopup, setAdditionalCareerAdvancementPopup] = useState( false );
  // const [vacationPopup, setVacationPopup] = useState( false );
  // const [buyingCarPopup, setBuyingCarPopup] = useState( false );
  // const [financeIndependencePopup, setFinanceIndependencePopup] = useState( false );
  // const [moreHigherEducationPopup, setMoreHigherEducationPopup] = useState( false );
  // const [familyPopup, setFamilyPopup] = useState( false );
  // const [buyingHomePopup, setBuyingHomePopup] = useState( false );
  const [loading, setLoading] = useState( false );
  const [moduleTimeLine, setModuleTimeLine] = React.useState( [] );
  const [openDocsPopup, setOpenDocsPopup] = React.useState( '' );
  const [rangeSliderValues, setRangeSliderValues] = React.useState( { income: 0, expenses: 0 } );
  const [loadedValues, setLoadedValues] = React.useState( {} );
  const [activeSlide, setActiveSlide] = React.useState( 0 );

  const placeTimeLine = ( type, status = '' ) => {
    if ( type === 'default' ) {
      const displayTimeLine = [];
      if ( status === 'not-empty' ) {
        const order = timeLineActions.moduleTimelineData;
        order.forEach( ( value ) => {
          if ( parseInt( value.scenario_id, 10 ) > 0 || value.action === 'CS' ) {
            displayTimeLine.push( value );
          }
        } );
      } else {
        displayTimeLine.push( {
          action: 'CS',
          module_time_line_order: 0,
          scenario_id: 0,
          selected_module: 'module1',
          time_line_order: 0,
        } );
      }
      setModuleTimeLine( displayTimeLine );
    }
  };

  useQuery( MODULE_DATA, {
    fetchPolicy: 'no-cache',
    variables: {
      story: 'santiago',
    },
    onCompleted( response ) {
      timeLineActions.assignModulesData( response.getStoryModuleData, ( data ) => {
        placeTimeLine( 'default', 'not-empty' );
        setLoading( false );
        setLoadedValues( getCalculatedValues() );
      } );
    },
    onError() {
      timeLineActions.assignModulesData( '', ( data ) => {
        placeTimeLine( 'default', 'empty' );
        setLoading( false );
      } );
    },
  } );

  const handleClose = () => {
    history.push( '/' );
  };

  // const handleFinanceIndependencePopup = () => {
  //   setFinanceIndependencePopup( !financeIndependencePopup );
  // };

  // const handleFamilyPopup = () => {
  //   setFamilyPopup( !familyPopup );
  // };

  // const handleBuyingHomePopup = () => {
  //   setBuyingHomePopup( !buyingHomePopup );
  // };

  // const handleMoreHigherEducationPopup = () => {
  //   setMoreHigherEducationPopup( !moreHigherEducationPopup );
  // };

  const handleForwordPopup = () => {
   
    navigator.clipboard.writeText(`${ (window.location.href).split('career-story')[0] }?email=santiago`);
    setForwordPopup( !forwardPopup );
  };

  const handleReplyPopup = () => {
    setReplyPopup( !replyPopup );
  };

  const handleSummaryPopup = (id) => {
    setSummaryPopup( !SummaryPopup );
    setLinkId(id);
  };

  // const handleHigherEducationPopup = () => {
  //   setHigherEducationPopup( !higherEducationPopup );
  // };

  // const handleCareerPathPopup = () => {
  //   setCareerPathPopup( !careerPathPopup );
  // };

  // const handleInvestmentsPopup = () => {
  //   setInvestmentsPopup( !investmentsPopup );
  // };

  // const handleCareerAdvancementPopup = () => {
  //   setCareerAdvancementPopup( !careerAdvancementPopup );
  // };

  // const handleAdditionalCareerAdvancementPopup = () => {
  //   setAdditionalCareerAdvancementPopup( !additionalCareerAdvancementPopup );
  // };

  // const handleVacationPopup = () => {
  //   setVacationPopup( !vacationPopup );
  // };

  // const handleBuyingCarPopup = () => {
  //   setBuyingCarPopup( !buyingCarPopup );
  // };

  useEffect( () => {
    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line
  } );

  const updateRangeValue = ( e, type ) => {
    const rangeSlider = { ...rangeSliderValues };
    rangeSlider[type] = e.target.value;
    setRangeSliderValues( rangeSlider );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } />}
      <div className={ classes.sliderStory }>
        <div className={ classes.mikeStoryIcons }>
          <div role="button" onClick={ handleForwordPopup } tabIndex={ -1 } aria-hidden="true">
            <img src={ forwordIcon } alt="" data-for="mikeStoryTool" data-tip="Click to copy share link" />
          </div>
          <div role="button" onClick={ handleReplyPopup } tabIndex={ 0 } aria-hidden="true">
            <img src={ replayIcon } alt="" data-for="mikeStoryTool" data-tip="Summary Documents" />
            {replyPopup && (
            <ul className={ classes.newText }>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-output' ); handleReplyPopup(); } }>Summary Output</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'inputsheet-statement' ); handleReplyPopup(); } }>Summary Financials</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'summary-table' ); handleReplyPopup(); } }>Summary Tables</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'income-statement' ); handleReplyPopup(); } }>Income Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'tax-statement' ); handleReplyPopup(); } }>Tax Statement</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'balance-statement' ); handleReplyPopup(); } }>Balance Sheet</p></li>
              <li><p aria-hidden="true" onClick={ () => { setOpenDocsPopup( 'cashflow-statement' ); handleReplyPopup(); } }>Cash Flow Statement</p></li>
            </ul>
            )}
          </div>
        </div>
        <Slider { ...settings } afterChange={ ( e ) => { setActiveSlide( e ); } }>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ Slide1 } />
                  <div className={ classes.InnerStoryLEft }>
                    <h2>SANTIAGO</h2>
                    <h2>Wall Street Banker</h2>
                    <p>15-Year Net Worth: $912,814*</p>
                  </div>
                  <span className={ classes.bottomSpanTextLeft }>
                    *Net worth: What you own (assets) minus what you owe (liabilities). Because Santiago gets married, this figure includes his partner’s income/expenses.
                  </span>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightBlock }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.imageBlock }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/career_sketch_stories/santigo_story_img_2.png" alt="" />
                  </div>
                  <div className={ classes.rightBlockNew }>
                    <h3>Santiago&apos;s Life</h3>
                    <p>Santiago graduates from Villanova University, begins as an investment banking analyst in New York City and advances to Vice President. He saves early, invests early, and settles down in New Jersey with his wife and kids.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.blueBox }>
                  <h3>Santiago&apos;s Start Year – 2019</h3>
                  <h4>(18 years old)</h4>
                  <div className={ classes.careerSave }>
                    <p>
                      <span>START:</span>
                      {' '}
                      $1,500 in savings
                    </p>
                    <ul>
                      <li>
                        <p>2019 – 2022: Attends and graduates from Villanova School of Business at Villanova University</p>
                      </li>
                      <li>
                        <p>2023 – 2025: Starts career as an Investment Banking Analyst in New York City</p>
                      </li>
                      <li>
                        <p>2023: Begins short-term and long-term investing</p>
                      </li>
                      <li>
                        <p>2023: Starts taking an annual vacation</p>
                      </li>
                      <li>
                        <p>2024: Invests in golf equipment</p>
                      </li>
                      <li>
                        <p>2026 – 2028: Changes firms and is promoted to Investment Banking Associate in NYC</p>
                      </li>
                      <li>
                        <p>2026: Gets married, moves to Hoboken, NJ, brings a puppy home</p>
                      </li>
                      <li>
                        <p>2029: The couple’s first child is born</p>
                      </li>
                      <li>
                        <p>2029 – 2033: Promoted to Investment Banking Vice President</p>
                      </li>
                      <li>
                        <p>2030: Purchases a safe SUV in preparation for twins</p>
                      </li>
                      <li>
                        <p>2031: Purchases a $1.1 million home in northern New Jersey</p>
                      </li>
                    </ul>
                    <h3>2033 Net Worth: $912,814</h3>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Introducing Santiago</h3>
                    <p>
                      Santiago aspires to become a banker, and after completing high school in his home state of Michigan, he attends Villanova School of Business at Villanova University from 2019-2022. To earn a Bachelor’s Degree in Finance, he finances his education with $60k in federal and private student loans, $80k in scholarships and grants,
                      and receives $140k in family contributions. Santiago supplements these resources by earning $10k annually through Villanova’s on-campus work-study program and completing summer internships.
                    </p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Higher Education" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(2)} }>
                      <img alt="" src={ higherEducationIcon } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Weigh risk vs. reward when examining your options, such as evaluating the risk of carrying large loans vs. the reward of potentially higher income after graduation.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Santiago explores many universities and attends a school that places him on his chosen path of working on Wall Street. In order to follow his personal dream, Santiago is willing to accept moderate risk and he feels encouraged by VU’s high rate of post-graduation job placement in his chosen field.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory3 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>An Investment Banking Analyst</h3>
                    <p>
                      After college, Santiago applies and is hired as an investment banking analyst in New York City where he earns $140k for the next three years. He lives with a past college roommate in a two-bedroom apartment in the Manhattan neighborhood of Murray Hill, which minimizes his commute to work. Looking to separate himself from the competitive analyst pool,
                      Santiago strives to be the first person in the office and the last to leave.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Path" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(4)} }>
                      <img alt="" src={ MikeBlue } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>When getting started in your career, it always helps to save on expenses when possible.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Santiago saves time and money by selecting a nearby neighborhood so he can enjoy a short commute to and from his office. He shares the apartment to dramatically reduce his living expenses.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ mikeStory4 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Santiago invests in trips, golf clubs</h3>
                    <p>
                      Santiago welcomes the intense work environment and steep learning curve as he immerses himself in his career. His first few years consist of long hours in the office during the week and on weekends as he manages multiple projects and new deadlines. When work permits vacation time, Santiago plans a couple of weekends
                      of relaxation, usually away at a golf course or beach with family and friends. In his second year, he invests in a new set of custom irons and wedges.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Vacation" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(11)} }>
                      <img alt="" src={ MikeSlide7Inner2 } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Take your own initiative and become a self-learner. Every new opportunity presents a new learning curve to overcome. Always remember the importance of work-life balance.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Despite the long hours, Santiago spends an hour of every day working on his skills and knowledge. When work permits, he takes well-earned vacation time to unwind and enjoy some relaxation from his fast-paced work environment.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide5 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Santiago prioritizes investments</h3>
                    <p>
                      Santiago’s business education instilled in him the importance of investing early. In 2023, he starts contributing to his 401(k) as well as making $100 monthly contributions to short-term investments and $500 to long-term investments.
                      He intends to own an environmentally friendly home and start a family someday.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Path" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(4)} }>
                      <img alt="" src={ MikeBlue } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Investments" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(9)} }>
                      <img alt="" src={ MikeGreen } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Invest early and capitalize on the long-term effects of compounding interest.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Santiago wastes no time and automates his savings and investments. He recognizes that investing in this manner will help him achieve his personal goals of family and homeownership.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide6 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Santiago accepts new position</h3>
                    <p>
                      In 2026, Santiago accepts a position as an Investment Banking Associate with a new firm in New York and receives a pay raise to $190k, a $135k base salary plus $55k annual bonus. He marries his long-time girlfriend,
                      who works in New York as a Marketing Manager. She brings $20k in student loans to their financial picture. The couple moves to a two-bedroom apartment in Hoboken with their new puppy.
                    </p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Career Advancement" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(5)} }>
                      <img alt="" src={ MikeBlue2 } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Family" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(10)} }>
                      <img alt="" src={ MikeSlide7Inner } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Work towards mastery, make well-calculated career moves, and have a strategy for how marriage can grow savings and fast-track long-term goals.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Santiago and his wife move just outside of the city to lower living expenses while saving for their dream of owning a home and starting a family.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide7 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>Santiago is promoted</h3>
                    <p>After three years as an associate specializing in leveraged finance products with a focus on technology and consumer companies, Santiago is promoted to Vice President and his pay jumps to $160k base salary with a $90k annual bonus. The couple welcomes their first baby girl to the family in 2029 and upgrades to a roomier two bedroom apartment in Hoboken.</p>

                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Additional Career Advancement" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(8)} }>
                      <img alt="" src={ MikeSlide6Inner } />
                    </figure>
                    <figure data-for="mikeStoryTool" data-tip="Family" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(10)} }>
                      <img alt="" src={ MikeSlide7Inner } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Always strive for mastery of your career. Allocate your own time to professional development and continue to grow your skills and knowledge.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Santiago&apos;s specialization and industry knowledge continue to push him up the corporate ladder and firmly establish him as an integral part of the leveraged finance team.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide8 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>A safe SUV for twins on the way</h3>
                    <p>In 2030, when the couple discovers a pregnancy with twins, they decide that Santiago will continue working while his wife takes leave from work after the babies are born. They select and purchase what they consider to be the safest SUV on the market.</p>

                  </div>
                  <div className={ classes.iconBlock }>

                    <figure data-for="mikeStoryTool" data-tip="Buying a Car" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(12)} }>
                      <img alt="" src={ MikeSlide8Inner2 } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Saving early leads to financial agility and the ability to adapt quickly to new situations.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>The couple’s early saving habits helped them build a strong net worth, which allows them to quickly make a strategic move for their growing family.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide9 } />
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.rightFullContentTop }>
                    <h3>The couple buys a home</h3>
                    <p className={ classes.paddingBottomPara }>
                      In the following year, Santiago and his partner purchase a modern four-bedroom home in northern New Jersey. The $1.1 million property keeps Santiago’s commute to the city an easy train ride away.
                      The couple invests $50k in fit-out costs to make the home newborn-safe as twins join the family in 2031. They also decide to invest in transforming the home with the most energy efficient products and applications.
                    </p>
                    <p>What happens next?</p>
                  </div>
                  <div className={ classes.iconBlock }>
                    <figure data-for="mikeStoryTool" data-tip="Buying a Home" aria-hidden="true" onClick={ ()=>{handleSummaryPopup(13)} }>
                      <img alt="" src={ MikeSlide9Inner } />
                    </figure>
                  </div>
                  <div className={ classes.bottomTextRight }>
                    <h3>Valuable Insight:</h3>
                    <p>Hard work coupled with vision will lead to reaching your targets.</p>
                  </div>
                  <div className={ classes.bottomTextRightLast }>
                    <h3>Powerful Strategy:</h3>
                    <p>Santiago continues to make strategic moves towards his long-term goal of mastering his career while enjoying his achievements.</p>

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>Santiago&apos;s Net Worth</h3>
                      <ul>
                        <li>What happens if Santiago earns 10% more income in his plan?</li>
                        <li>What happens if he spends 5% less?</li>
                        <li>Drag the income and expenses sliders and see the impact on his net worth.</li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.income } step="5" onChange={ ( e ) => { updateRangeValue( e, 'income' ); } } />
                      {rangeSliderValues.income}
                      <input type="range" min="-20" max="20" value={ rangeSliderValues.expenses } step="5" onChange={ ( e ) => { updateRangeValue( e, 'expenses' ); } } />
                      {rangeSliderValues.expenses}
                      {/* <img src={ MikeSlideGraph2 } alt="" />
                      <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to career income. Then, the FC reruns all calculations, including taxes, and displays the updated figures in the chart below.. All at the speed of thought.">
                        <img src={ infoIcon } alt="infoicon" />
                      </div> */}
                    </div>
                  </div>
                  <div className={ classes.graphBlock }>
                    <NetworthRangeGraph graphSlide={ 9 } activeSlide={ activeSlide } defaultValues={ loadedValues } rangeValues={ rangeSliderValues } />
                    {/* <img src={ MikeSlideGraph } alt="" /> */}
                    {/* <div className={ classes.infoIcon } data-for="mikeStoryTool" data-tip="When you move the slider above, the FC is automatically updating each input in every module related to living expenses. Then, the FC reruns all calculations and displays the updated figures in the chart below. All at the speed of thought.">
                      <img src={ infoIcon } alt="infoicon" />
                    </div> */}
                  </div>

                </div>
              </Grid>
            </Grid>
          </div>

          <div className={ classes.testimonialInner }>

            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 12 }>
                <div className={ classes.blueBoxLeftBorder }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryFullTop }>
                    <div className={ classes.mikestoryFullTopLeft }>
                      <h3>
                        Santiago&apos;s Timeline
                        <img className={ classes.infoIcon } data-for="mikeStoryToolBottom" data-tip="The timeline on this page is live! Scroll left and right to view the summary details that make up this sketch. Double click on any module icon to go to that module and view all the inputs for that scenario." src={ infoIcon } alt="infoicon" />
                      </h3>

                      <ul>
                        <li>Scroll over the life events and discover the details in this career sketch.</li>
                        <li>
                          Explore Santiago’s financial life plan by module to see how he acheives his goals
                        </li>
                      </ul>
                    </div>
                    <div className={ classes.rangeBlock }>
                      <h4>Santiago - Wall Street Banker</h4>
                      <h4>Net Worth:  $912,814</h4>
                    </div>
                  </div>
                  <div className={ classNames( classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock ) }>
                    <TimeLineList timeLineData={ moduleTimeLine } page="story" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={ classes.testimonialInner }>
            <Grid Container className={ classes.gridContainer }>
              <Grid sm={ 6 }>
                <div className={ classes.leftBlock }>
                  <img alt="" src={ MikeSlide12 } />
                  <div className={ classes.ovelayBoxLast }>
                    <h3>
                      Santiago&apos;s Life:
                      <br />
                      As of 2033
                      {'   '}
                      <span>(32 years old)</span>
                    </h3>
                    <ul>
                      <li>Cash Savings</li>
                      <li>Investments</li>
                      <li>Education</li>
                      <li>Work Experience</li>
                      <li>A Career</li>
                      <li>A Family</li>
                      <li>A Dog</li>
                      <li>A Safe SUV</li>
                      <li>A Home</li>
                      <li>Annual Vacations</li>
                      <li>A Great Future</li>
                    </ul>
                    <p>2033 Net Worth: $912,814</p>
                  </div>
                </div>
              </Grid>
              <Grid sm={ 6 }>
                <div className={ classes.rightFullContent }>
                  <div className={ classes.closeIcon } aria-hidden="true" onClick={ handleClose }>X</div>
                  <div className={ classes.mikestoryLast }>
                    <h3>Santiago&apos;s KPIs in 2033</h3>
                    <ul>
                      <li>Debt-to-Income: 18.6%</li>
                      <li>Monthly Liquidity Ratio: 1.0x</li>
                      <li>Current Ratio: 0.3x</li>
                      <li>Savings Rate: 2.6%</li>
                      <li>Investment Assets to Total Assets: 30.5%</li>
                      <li>Total Assets to Total Debt: 2.1x</li>
                      <li>Solvency Ratio: 0.5x</li>
                    </ul>
                    <a href="https://inkwiry.com/kpi-glossary" target="_blank" rel="noopener noreferrer">KPI Reference Guide</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

        </Slider>
      </div>
      <ReactTooltip id="mikeStoryTool" place="top" effect="solid" className={ classes.tooltipInfoTopNewText2 } />
      <ReactTooltip id="mikeStoryToolBottom" place="bottom" effect="solid" className={ classes.tooltipInfoTopNewTextBottom } />
      <div>
        <Dialog
          open={ forwardPopup }
          onClose={ handleForwordPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={ classes.replayPopup }
        >
          <DialogContent className={ classes.replayPopupInner }>
            <div aria-hidden="true" onClick={ handleForwordPopup } className={ classes.closeIconPop }><i className="la la-close" /></div>
            <div className={ classes.paymentPoupIcon }>
              <span />
              <p>Link successfully copied.</p>
            </div>

          </DialogContent>
        </Dialog>
      </div>
      {/* <div>
        <Dialog
          open={ replyPopup }
          onClose={ handleReplyPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <div aria-hidden="true" onClick={ handleReplyPopup }>close</div>
            Reply popup
          </DialogContent>
        </Dialog>
      </div> */}
      <div>
        <Dialog
          open={ SummaryPopup }
          onClose={ handleSummaryPopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.summaryPopup}
        >
                  <DialogTitle className={classes.modalHeader1}>
          <h4>Summary Tables</h4>
          <img src={logo1} alt="logo" />
          <button type="button" onClick={()=>{setSummaryPopup(false)}} className={classes.closePopup}>×</button>
        </DialogTitle>
          <DialogContent className={classes.modalBody1}>
          <Summarytable  linkId= {linkId}/>
          </DialogContent>
          <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ ()=>{setSummaryPopup(false)} }>Close</Button>
          </div>
        </DialogActions>
        </Dialog>
      </div>

      {openDocsPopup !== '' && ( <SummaryDocs setOpenDocsPopup={ setOpenDocsPopup } popupStatus={ openDocsPopup } /> )}
    </Typography>
  );
};

Santiago.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Santiago );
