import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const StudentLoanRepaymentPlans = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Student Loan Repayment Plans</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay1.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Student Loan Repayment Plans
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/student-loan-repay1-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          When repaying{' '}
                          <Link
                            to="/finance-explained/student-loan"
                            target="_blank">
                            student loans
                          </Link>
                          , the main goals are to repay on time or ahead of
                          schedule, minimize total repayment amount when
                          possible, and find a repayment plan that works for
                          your plan. There are four main types of repayment
                          plans available to you when repaying federal student
                          loans: standard, graduated, extended, and
                          income-based. This Finance Explained will cover the
                          first three.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Let&apos;s use an example to walk through the
                          difference between standard, graduated, and extended
                          repayment options. Kyle graduated with $30,000 of
                          federal student loans, has 30 days remaining in his{' '}
                          <Link
                            to="/finance-explained/grace-period"
                            target="_blank">
                            grace period
                          </Link>{' '}
                          before repayment begins, and has a 5% interest rate on
                          all loans. There are three things to consider when
                          comparing repayment options:
                        </p>
                        <p className={classes.grayBoxNew}>
                          1. Monthly payment
                          <br />
                          2. Total repayment amount
                          <br />
                          3. Payoff time
                        </p>
                        <p>
                          <strong>Standard Repayment Plan</strong>
                          <br />
                          The standard repayment plan allows Kyle to repay his
                          loans with fixed monthly payments over 10 years. This
                          plan almost always results in the lowest total
                          repayment amount of all options.
                        </p>
                        <div
                          className={classNames(
                            classes.grayBoxNew,
                            classes.paymentBlock,
                          )}>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$318</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>$38,184</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>10 years</span>
                          </div>
                        </div>
                        <p>
                          <strong>Graduated Repayment Plan</strong>
                          <br />
                          The graduated repayment plan allows Kyle to repay his
                          loans in 10 years, but his monthly payments start low
                          and increase every two years. As the name sounds, your
                          payments graduate - or increase - every two years.
                        </p>
                        <div
                          className={classNames(
                            classes.grayBoxNew,
                            classes.paymentBlock,
                          )}>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$180 to $540</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>$40,294 (+$2,110)</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>10 years</span>
                          </div>
                        </div>
                        <p>
                          <strong>Extended Repayment Plan</strong>
                          <br />
                          There are two types of extended repayment plans:
                          extended fixed and extended graduated. Both options
                          allow Kyle to repay his loan in 25 years, which
                          results in much higher total repayment amounts.
                        </p>
                        <p>
                          <strong>Extended fixed</strong> allows Kyle to repay
                          his loans with fixed monthly payments over 25 years:
                        </p>
                        <div
                          className={classNames(
                            classes.grayBoxNew,
                            classes.paymentBlock,
                          )}>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$175</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>$52,613 (+$14,429)</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>25 years</span>
                          </div>
                        </div>
                        <p>
                          <strong>Extended graduated</strong> allows Kyle to
                          repay his loans over 25 years, but monthly payments
                          start low and increase every two years:
                        </p>
                        <div
                          className={classNames(
                            classes.grayBoxNew,
                            classes.paymentBlock,
                          )}>
                          <div className={classes.paymentRow}>
                            <span>Monthly Payment:</span>
                            <span>$125 to $185</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Total Repayment Amount:</span>
                            <span>$57,463 (+$19,279)</span>
                          </div>
                          <div className={classes.paymentRow}>
                            <span>Payoff Time:</span>
                            <span>25 years</span>
                          </div>
                        </div>
                        <p>
                          There&apos;s a repayment option that fits every
                          financial plan. The first step is to educate yourself
                          on those options. Then, you can make a good decision
                          and repay your loans on time while minimizing total{' '}
                          <Link
                            to="/finance-explained/interest"
                            target="_blank">
                            interest
                          </Link>{' '}
                          paid!
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StudentLoanRepaymentPlans.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StudentLoanRepaymentPlans);
