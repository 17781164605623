import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-career-path/styles';

const GreyBox = ( props ) => {
  const {
    classes, handleCareerObject,
  } = props;

  return (
    <div className={ classes.grayBox }>
      {handleCareerObject.occupation && (
      <p className={ classes.marginBottomZero }>
        <i>
          Career Move:
          {' '}
          {' '}
          {handleCareerObject.occupation}

        </i>
      </p>
      )}
      <p className={ classes.marginBottomZero }>
        <i>
          Location:
          {' '}
          {handleCareerObject.city}
          ,
          {' '}
          {handleCareerObject.state_code}
        </i>
      </p>
      <p className={ classes.marginBottomZero }>
        <i className={ classes.colorBlack }>
          Learn more about this career by
          {' '}
          <a
            href="https://www.mynextmove.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            searching My Next Move
          </a>
          .
        </i>
      </p>
      <p className={ classes.marginBottomZero }>
        <i className={ classes.colorBlack }>
          Discover this career&apos;s average pay by
          {' '}
          <a
            href="https://www.payscale.com/research/US/Country=United_States/Salary"
            target="_blank"
            rel="noopener noreferrer"
          >
            searching PayScale
          </a>
        </i>
      </p>
    </div>

  );
};

GreyBox.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,

};

export default withStyles( styles )( GreyBox );
