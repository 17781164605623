import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { Link, withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import styles from './styles';
import mcsTopImage from '../../../assets/img/mcs/level6_bg.png';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import levelIcon from '../../../assets/img/mcs/MCS-level06.png';
import iconsImage from '../../../assets/img/mcs/icons-fi-eal-cp.png';
import progressImage from '../../../assets/img/mcs/100.png';
import iconsImageNew from '../../../assets/img/mcs/icons-he.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Pageloader from '../../../components/ui/pageloader';

const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
// const SAVEMYCAREERSKETCH = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');

const staticLevelData = [
  {
    "id": "7",
    "user_id": 214,
    "level": 2,
    "option_id": 1,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:29:33"
  },
  {
    "id": "8",
    "user_id": 214,
    "level": 2,
    "option_id": 2,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:30:01"
  },
  {
    "id": "8",
    "user_id": 214,
    "level": 2,
    "option_id": 3,
    "status": 0,
    "completed_date": "09/03/2020 at 6:59pm EST",
    "created_date": "2020-09-03 13:30:01"
  }
]

const McsLevelFour = (props) => {
  const { classes, history } = props;
  const [firstActivityData, setFirstActivityData] = React.useState("");
  const [secondActivityData, setSecondActivityData] = React.useState("");
  const [thirdActivityData, setThirdActivityData] = React.useState("");
  // const [errorMessage, setErrorMessage] = React.useState("");
  const [levelPercentage, setLevelPercentage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  // const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
  //   onCompleted({
  //     saveMyCareerSketch: {
  //       status,
  //     },
  //   }) {
  //     if (status) {
  //       setLoading(false);
  //       history.push('./dashboard');
  //     }
  //   },
  //   onError(errors) {
  //     const formatedErrors = errors.graphQLErrors.map(({ message }) => (
  //       message
  //     ));
  //     setErrorMessage(formatedErrors);
  //     setLoading(false);
  //   },
  // });

  const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "4",
    },
    fetchPolicy: 'network-only',
    onCompleted() {
      setLevelPercentage(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_percent);
      setFirstActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 1)[0] : staticLevelData.filter(obj => obj.option_id === 1)[0]);
      setSecondActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 2)[0] : staticLevelData.filter(obj => obj.option_id === 2)[0]);
      setThirdActivityData(mcsLevelWiseData && mcsLevelWiseData.getMCSLevelActivityData.level_activity.length > 0 ? mcsLevelWiseData.getMCSLevelActivityData.level_activity.filter(obj => obj.option_id === 3)[0] : staticLevelData.filter(obj => obj.option_id === 3)[0]);
    },
    onError() {
      setLoading(false);
    },
  });
  React.useEffect(() => {
    if (mcsLevelWiseData) {
      setLoading(false);
    }
  }, [mcsLevelWiseData]);

  const handleStartThirdActivity = () => {
    history.push('/timeline-sketcher?type=mcs4#activity=3');
    // setLoading(true);
    // submitFirstActivity({
    //   variables: {
    //     data: {
    //       level: "4",
    //       option_id: "3",
    //     },
    //   },
    // });
  }
  const handleGotoNextLevel = () => {
    history.push('./level5');
  }
  const handleStartFirstActivity = () => {
    history.push('/plan-with-inkwiry/fi?type=mcs4#activity=1');
  }
  const handleStartSecondActivity = () => {
    history.push('/plan-with-inkwiry/existing?type=mcs4#activity=2');
  }

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.mcsLevelOne}>
        <div className={classes.topImage}>
          <img src={mcsTopImage} alt="mcs" />
        </div>
        <div className={classes.container}>
          <div className={classes.mcsRow}>
            <div className={classes.mcsLogoImage}>
              <Link to="/my-career-sketch/dashboard">
                <img src={logoImage} alt="logo" />
              </Link>
            </div>
            <div className={classes.mcsLevelNum}>
              <h3>LEVEL 04</h3>
              <img src={levelIcon} alt="info" className={classes.iconImage} />
            </div>
          </div>
          <div className={classes.mcsBodyContent}>
            <Grid container>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.leftBlock}>
                  <h1 className={classes.mcsLevelTitle}>Capture your details</h1>
                  <div className={classes.qPosition}>
                    <div className={classes.questionBlock}>
                      <p>We’re now going to dive into Plan With Inkwiry to start your career sketch. The first thing we’ll do is capture your basic details to create a financial picture of where you are right now. </p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p className={classes.noMarginBottom}>You’ll return to your timeline and enter information in: </p>
                      <ul className={classes.tickList}>
                        <li>Financial Independence</li>
                        <li>Existing Assets + Liabilities</li>
                        <li>Your first stepping stone.</li>
                      </ul>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>The Financial Independence module will walk you through basic settings for your career sketch. For example, when you enter a Start Year and answer two short questions about your plan, it tells Inkwiry where to start your sketch on the timeline and how to configure details like inflation rate. If you wish, you can click right through and use the default settings.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>To complete your financial picture, the Existing Assets + Liabilities module will ask you to enter cash on hand, savings, and other things of value that you own (assets) like a car. You’ll also be asked to enter things you owe (liabilities), like a student loan, auto loan and credit cards you don’t pay in full each month.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Your first stepping stone might be Higher Education or Career Path. If you’re already in college or career school, enter your current details. If you are planning to start higher education, enter details of the school you wish to attend. If your plan is to go right to work, use Career Path and enter details of the job you’re angling for.</p>
                    </div>
                    <div className={classes.questionBlock}>
                      <p>Let’s start career sketching.</p>
                    </div>
                    {thirdActivityData && thirdActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleGotoNextLevel}>NEXT LEVEL {'>'}</button>
                    </div> : secondActivityData && secondActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleStartThirdActivity}>continue</button>
                    </div> : firstActivityData && firstActivityData.status === 1 ? <div className={classes.startButton}>
                      <button type="button" onClick={handleStartSecondActivity}>continue</button>
                    </div> : <div className={classes.startButton}>
                      <button type="button" onClick={handleStartFirstActivity}>start</button>
                    </div>}
                    {/* {errorMessage && <span>{errorMessage}</span>} */}
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className={classes.rightBlock}>
                  <div className={classes.timelineImage}>
                    <video id="bgvid" preload="auto" muted autoPlay="true" loop playsinline>
                      <source src="https://inkwiry-videos.s3.us-east-2.amazonaws.com/mcs-levels/Level-01.mp4" type="video/mp4" />
                    </video>
                  </div>
                  <div className={classes.iconsImage}>
                    <img src={iconsImage} alt="icons" />
                  </div>
                  <div className={classNames(classes.iconsImage, classes.noDisplay)}>
                    <img src={iconsImageNew} alt="icons" />
                  </div>
                  <div className={classes.activeRow}>
                    <div className={classes.activities}>
                      <h4>Level 04 Activities</h4>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_1" className={firstActivityData && firstActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_1" />
                          Complete the Financial Independence module
                        </label>
                        {firstActivityData && firstActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={handleStartFirstActivity}> Review</button> : ""}
                      </div>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_2" className={secondActivityData && secondActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_2" />
                          Complete the Existing Assets + Liabilities module
                        </label>
                        {secondActivityData && secondActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={handleStartSecondActivity}>Review</button> : ""}
                      </div>
                      <div className={classes.checkboxRadio}>
                        <label htmlFor="2_3" className={thirdActivityData && thirdActivityData.status === 1 ? classes.checkedEmployment : ""}>
                          <input type="checkbox" name="equation" id="2_3" />
                          Build a scenario for Stepping Stone #1
                        </label>
                        {thirdActivityData && thirdActivityData.status === 1 ? <button type="button" className={classes.goButton} onClick={handleStartThirdActivity}>Review </button> : ""}
                      </div>
                    </div>
                    <div className={classes.myLevelProgress}>
                      <h4>My Level 04 Progress</h4>
                    </div>
                    <div className={classes.progressPuzzle}>
                      <span className={classes.progressPercent}>{levelPercentage}%</span>
                      <p className={classes.puzzleBar}>
                        <img src={progressImage} alt="info" />
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.mcsFooter}>
          <div className={classes.container}>
            <div className={classes.footerRow}>
              <div className={classes.copyRight}>
                <p>Copyright © 2022 // All rights reserved</p>
              </div>
              <div className={classNames(classes.copyRight, classes.informedText)}>
                <p>
                  Informed and Powered by
                  <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                </p>
              </div>
            </div>
          </div>
          <div className={classes.mcsFluid}>
            <p className={classes.termLinks}>
              <a href="/">Terms of Service</a>
              &nbsp;//&nbsp;
              <a href="/">Privacy Policy</a>
              &nbsp;//&nbsp;
              <a href="/">Security</a>
            </p>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsLevelFour.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsLevelFour);