import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const BullBearMarket = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Bull vs Bear Market</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div
              className={classNames(classes.pfBudgetPage, classes.blockTable)}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/bull-vs-bear.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Bull vs Bear Market
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/bull-vs-bear-horiz-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Bulls and bears. If you have ever watched business
                          news or read a business-focused newspaper, you&apos;ve
                          probably heard or read these words before. What do
                          they mean and where did they get their names?
                        </p>
                      </div>
                    </div>
                    <div
                      className={classNames(classes.pfRow, classes.pfBlockRow)}>
                      <div className={classes.feText}>
                        <p>
                          A bull market is when an asset price rises over time.{' '}
                          <Link
                            to="/finance-explained/stocks-bonds"
                            target="_blank">
                            Stocks
                          </Link>{' '}
                          are most talked about, so we&apos;ll use stocks in
                          this example. Yes, the definition of a bull market is
                          that vague because there is no universal understanding
                          or definable metric of a bull market. However, most
                          investors consider a bull market a 20% gain or more
                          from the low point in the market.
                        </p>
                        <p className={classes.noBottomMargin}>
                          A bear market is the opposite. A 20% decline in stock
                          prices from the recent high point is considered a bear
                          market. The{' '}
                          <Link
                            to="/finance-explained/sandp-500"
                            target="_blank">
                            S&P 500 index
                          </Link>{' '}
                          year-to-date price action can be seen in the chart
                          below:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>
                            The most recent market high was made on 2/19/2020
                            when the price of the S&P 500 index rose to
                            $3,393.52
                          </li>
                          <li>
                            The S&P 500 index entered a bear market when the
                            price declined by 20% from that high on 3/11/2020
                            with an end of day price of $2,741.38
                          </li>
                        </ul>

                        <HighchartsReact
                          highcharts={Highcharts}
                          options={{
                            chart: {
                              type: 'spline',
                              scrollablePlotArea: {
                                scrollPositionX: 0,
                              },
                              marginTop: 40,
                            },

                            exporting: {
                              enabled: false,
                            },
                            credits: {
                              enabled: false,
                            },
                            title: {
                              text: 'Price of S&P 500 Year-to-Date',
                            },
                            xAxis: [
                              {
                                tickInterval: 11,
                                categories: [
                                  '1/2/2020',
                                  '1/3/2020',
                                  '1/6/2020',
                                  '1/7/2020',
                                  '1/8/2020',
                                  '1/9/2020',
                                  '1/10/2020',
                                  '1/13/2020',
                                  '1/14/2020',
                                  '1/15/2020',
                                  '1/16/2020',
                                  '1/17/2020',
                                  '1/21/2020',
                                  '1/22/2020',
                                  '1/23/2020',
                                  '1/24/2020',
                                  '1/27/2020',
                                  '1/28/2020',
                                  '1/29/2020',
                                  '1/30/2020',
                                  '1/31/2020',
                                  '2/3/2020',
                                  '2/4/2020',
                                  '2/5/2020',
                                  '2/6/2020',
                                  '2/7/2020',
                                  '2/10/2020',
                                  '2/11/2020',
                                  '2/12/2020',
                                  '2/13/2020',
                                  '2/14/2020',
                                  '2/18/2020',
                                  '2/19/2020',
                                  '2/20/2020',
                                  '2/21/2020',
                                  '2/24/2020',
                                  '2/25/2020',
                                  '2/26/2020',
                                  '2/27/2020',
                                  '2/28/2020',
                                  '3/2/2020',
                                  '3/3/2020',
                                  '3/4/2020',
                                  '3/5/2020',
                                  '3/6/2020',
                                  '3/9/2020',
                                  '3/10/2020',
                                  '3/11/2020',
                                  '3/12/2020',
                                  '3/13/2020',
                                  '3/16/2020',
                                  '3/17/2020',
                                  '3/18/2020',
                                  '3/19/2020',
                                  '3/20/2020',
                                  '3/23/2020',
                                  '3/24/2020',
                                  '3/25/2020',
                                  '3/26/2020',
                                  '3/27/2020',
                                  '3/30/2020',
                                  '3/31/2020',
                                  '4/1/2020',
                                  '4/2/2020',
                                  '4/3/2020',
                                  '4/6/2020',
                                  '4/7/2020',
                                  '4/8/2020',
                                  '4/9/2020',
                                  '4/13/2020',
                                  '4/14/2020',
                                  '4/15/2020',
                                  '4/16/2020',
                                  '4/17/2020',
                                  '4/20/2020',
                                  '4/21/2020',
                                  '4/22/2020',
                                  '4/23/2020',
                                  '4/24/2020',
                                  '4/27/2020',
                                  '4/28/2020',
                                  '4/29/2020',
                                  '4/30/2020',
                                  '5/1/2020',
                                  '5/4/2020',
                                  '5/5/2020',
                                  '5/6/2020',
                                  '5/7/2020',
                                  '5/8/2020',
                                  '5/11/2020',
                                  '5/12/2020',
                                  '5/13/2020',
                                  '5/14/2020',
                                  '5/15/2020',
                                  '5/18/2020',
                                  '5/19/2020',
                                  '5/20/2020',
                                  '5/21/2020',
                                  '5/22/2020',
                                  '5/26/2020',
                                  '5/27/2020',
                                  '5/28/2020',
                                  '5/29/2020',
                                  '6/1/2020',
                                  '6/2/2020',
                                  '6/3/2020',
                                  '6/4/2020',
                                  '6/5/2020',
                                  '6/8/2020',
                                  '6/9/2020',
                                  '6/10/2020',
                                  '6/11/2020',
                                  '6/12/2020',
                                  '6/15/2020',
                                ],
                                labels: {
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            yAxis: [
                              {
                                // Primary yAxis
                                labels: {
                                  formatter() {
                                    const chart = this;
                                    if (chart.value < 0) {
                                      return `<span style="color:#bf0000;">(${formatDollar(
                                        -Math.round(chart.value),
                                      )})</span>`;
                                    }
                                    return formatDollar(
                                      Math.round(chart.value),
                                    );
                                  },
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                                title: {
                                  text: '',
                                  style: {
                                    color: '#000000',
                                    fontSize: '14px',
                                  },
                                },
                              },
                            ],
                            tooltip: {
                              headerFormat:
                                '<span style="font-size:14px">{point.key}</span><br/>',
                              crosshairs: true,
                              shared: true,
                              valueDecimals: 0,
                              valuePrefix: '$',
                              style: {
                                color: '#000000',
                                fontSize: '13px',
                              },
                            },
                            plotOptions: {
                              spline: {
                                lineWidth: 2,
                                states: {
                                  hover: {
                                    lineWidth: 2,
                                  },
                                },
                                marker: {
                                  enabled: false,
                                },
                              },
                            },
                            legend: {
                              itemStyle: {
                                fontSize: '14px',
                              },
                            },
                            series: [
                              {
                                name: 'S&P 500 Closing Price',
                                data: [
                                  3258, 3235, 3246, 3237, 3253, 3275, 3265,
                                  3288, 3283, 3289, 3317, 3330, 3321, 3322,
                                  3326, 3295, 3244, 3276, 3273, 3284, 3226,
                                  3249, 3298, 3335, 3346, 3328, 3352, 3358,
                                  3379, 3374, 3380, 3370, 3386, 3373, 3338,
                                  3226, 3128, 3116, 2979, 2954, 3090, 3003,
                                  3130, 3024, 2972, 2747, 2882, 2741, 2481,
                                  2711, 2386, 2529, 2398, 2409, 2305, 2237,
                                  2447, 2476, 2630, 2541, 2627, 2585, 2471,
                                  2527, 2489, 2664, 2659, 2750, 2790, 2762,
                                  2846, 2783, 2800, 2875, 2823, 2737, 2799,
                                  2798, 2837, 2878, 2863, 2940, 2912, 2831,
                                  2843, 2868, 2848, 2881, 2930, 2930, 2870,
                                  2820, 2853, 2864, 2954, 2923, 2972, 2949,
                                  2955, 2992, 3036, 3030, 3044, 3056, 3081,
                                  3123, 3112, 3194, 3232, 3207, 3190, 3002,
                                  3041, 3067,
                                ],
                                color: '#0070c0',
                              },
                              {
                                name: 'Market High',
                                data: [
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394, 3394, 3394, 3394, 3394, 3394,
                                  3394, 3394,
                                ],
                                color: 'green',
                                zones: [
                                  {
                                    dashStyle: 'dash',
                                  },
                                ],
                              },
                              {
                                name: 'Bear Market Level',
                                data: [
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715, 2715, 2715, 2715, 2715, 2715,
                                  2715, 2715,
                                ],
                                color: '#808080',
                                zones: [
                                  {
                                    dashStyle: 'dash',
                                  },
                                ],
                              },
                              {
                                name: 'Market Low',
                                data: [
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192, 2192, 2192, 2192, 2192, 2192,
                                  2192, 2192,
                                ],
                                color: '#ffc000',
                                zones: [
                                  {
                                    dashStyle: 'dash',
                                  },
                                ],
                              },
                            ],
                          }}
                        />

                        <p className={classes.noBottomMargin}>
                          Now that you understand what defines a bull and bear
                          market, how did they get those names? Honestly,
                          history isn&apos;t definitive on the legendary bull
                          and bear market. There are two mainstream stories to
                          support the animal names that define our markets
                          today:
                        </p>
                        <ul className={classes.noTopMargin}>
                          <li>
                            Bulls attack by thrusting their horns upwards while
                            bears swipe their claws down on their prey. Bulls go
                            up and bears go down.
                          </li>
                          <li>
                            Middlemen in the bear skin trading industry in the
                            18th century sold bear skins before they bought
                            them, hoping the price would go down. If the price
                            went down, they would make a profit on the price
                            they agreed to sell them for and the new lower price
                            they can buy them for. Bear skin traders and bear
                            markets.
                          </li>
                        </ul>
                        <p>
                          Next time someone talks about bulls and bears, or you
                          get asked about the origin of bear markets at this
                          week&apos;s trivia, you&apos;ve got the answer.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
BullBearMarket.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BullBearMarket);
