import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi-aca/styles';
import * as moduleServices from '../../../calculations/modules-services';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  TextField, Typography,
} from '@material-ui/core';
// import {PMT, FV} from "../../../utilities/excelLibrary"
import { formatDollar } from '../../../utilities/chartCommonFunctions';
import * as commonFunctions from '../../../utilities/commonFunctions';
import $ from 'jquery';

const RetirementPlanning = ( props ) => {
  const {
    classes,
  } = props;

  const [loanRepayment, setLoanRepayment] = React.useState({
    currentAge: 30,
    targetRetirementAge: 60,
    lifeExpectancy: 95,
    incomeInRetirement: 85000,
    investmentReturn: 7,
    currentRetirementBalance: 15000,
    nestEggForRetirement: 1773325,
    monthylContributionNeeded: 0,
    totalContributions: 0,
    totalEarnings: 0,
    inflation: 0,
    yearsInRetirement: 0,
    yearsToRetirement: 0,
  });
  const [isFocus, setIsFocus] = React.useState('');

  const handleChange = (e, field) => {
    const v = { ...loanRepayment };
    let value = e.floatValue !== "" && e.floatValue !== undefined ? e.floatValue : 0;
    if(field === 'investmentReturn' && value > 100){
      value = 100
    }
    if(value <= 0) {
      v.monthylContributionNeeded = 0;
      v.nestEggForRetirement = 0;
      v.totalEarnings = 0;
      v.totalContributions = 0;
    }
    v[field] = value;
    setLoanRepayment({ ...v });
  };


  var incomeInRetirementArray = [];
  var incomeInRetirementValue = 0;

  var avarageValue = 0;
  loanRepayment.inflation = 2;
  loanRepayment.yearsInRetirement = loanRepayment.lifeExpectancy - loanRepayment.targetRetirementAge;
  loanRepayment.yearsToRetirement = loanRepayment.targetRetirementAge - loanRepayment.currentAge;

  var totalIncomeValue = 0;
  for (let y = 0; y <= parseInt(loanRepayment.yearsInRetirement) + 1; y++) {
    incomeInRetirementValue = (loanRepayment.incomeInRetirement) * Math.pow((1 + (loanRepayment.inflation / 100)), (y));
    totalIncomeValue = totalIncomeValue + incomeInRetirementValue;
    incomeInRetirementArray.push(incomeInRetirementValue);
  }

  avarageValue = totalIncomeValue / (parseInt(loanRepayment.yearsInRetirement) + 2);

  loanRepayment.nestEggForRetirement = (100 / loanRepayment.investmentReturn) * avarageValue;

  loanRepayment.monthylContributionNeeded = -commonFunctions.PMT((parseFloat(loanRepayment.investmentReturn) / 100) / 12, loanRepayment.yearsToRetirement * 12, -(loanRepayment.currentRetirementBalance), loanRepayment.nestEggForRetirement, 0);

  loanRepayment.totalContributions = loanRepayment.monthylContributionNeeded * loanRepayment.yearsToRetirement * 12;

  loanRepayment.totalEarnings = loanRepayment.nestEggForRetirement - (loanRepayment.currentRetirementBalance) - loanRepayment.totalContributions;


  var monthlyInvestmentGraph1 = [];
  var monthlyInvestmentGraph2 = [];
  var monthlyInvestmentGraph3 = [];
  var graphArrayYearsValues = [];

  var monthlyInvestmentGraphVal1 = 0;
  var monthlyInvestmentGraphVal2 = 0;
  var monthlyInvestmentGraphVal3 = 0;

  var roundedValue1 = 0;
  var roundedValue2 = 0;
  var roundedValue3 = 0;

  for (var y = 1; y <= loanRepayment.yearsToRetirement; y++) {
    graphArrayYearsValues.push(y);
    monthlyInvestmentGraphVal1 = commonFunctions.FV((loanRepayment.investmentReturn / 12) / 100, y * 12, -(loanRepayment.monthylContributionNeeded / 100) * 85, -(loanRepayment.currentRetirementBalance), 0);
    monthlyInvestmentGraph1.push(monthlyInvestmentGraphVal1);

    monthlyInvestmentGraphVal2 = commonFunctions.FV((loanRepayment.investmentReturn / 12) / 100, y * 12, -loanRepayment.monthylContributionNeeded, -(loanRepayment.currentRetirementBalance), 0);
    monthlyInvestmentGraph2.push(monthlyInvestmentGraphVal2);

    monthlyInvestmentGraphVal3 = commonFunctions.FV((loanRepayment.investmentReturn / 12) / 100, y * 12, -(loanRepayment.monthylContributionNeeded / 100) * 115, -(loanRepayment.currentRetirementBalance), 0);
    monthlyInvestmentGraph3.push(monthlyInvestmentGraphVal3);

  }

  roundedValue1 = formatDollar(Math.round((loanRepayment.monthylContributionNeeded / 100) * 85));
  roundedValue2 = formatDollar(Math.round(loanRepayment.monthylContributionNeeded));
  roundedValue3 = formatDollar(Math.round((loanRepayment.monthylContributionNeeded / 100) * 115));

  //Labels
  const label1 = roundedValue1 + ' monthly investment';
  const label2 = roundedValue2 + ' monthly investment';
  const label3 = roundedValue3 + ' monthly investment';

  var windowHeight = $(window).height();
  if (windowHeight >= 600) {
    windowHeight = 580;
  }
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>You&apos;re probably wondering how much you should have saved for retirement along the way. Let&apos;s take a look at some benchmarks that can help you think about retirement planning.</p>
      <p>
        According to
        {' '}
        {' '}
        <Link href="https://www.fidelity.com/viewpoints/retirement/how-much-do-i-need-to-retire" target="_blank">Fidelity,</Link>
        {' '}
        assuming you want to retire by age 67, how much should you try and save along the way?
      </p>
      <div className={ `${classes.grayBox} ${classes.grayBoxRetire}` }>
        <p>
          <i>
            Annual Pre-Tax Income:
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val } prefix="$" />
              : "$0"
            }
          </i>
        </p>
      </div>
      <div className={ classes.retirementPlanning }>
        <ul>
          <li>
            <span>By age 30: 1x your salary</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText }  decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val } prefix="$" />
              : "$0"
            }
          </li>
          <li>
            <span>By age 35: 2x</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText }  decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val } prefix="$" />
              : "$0"
            }
          </li>
          <li>
            <span>By age 40: 3x</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText } decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val * 3 } prefix="$" />
              : "$0"
            }
          </li>
          <li>
            <span>By age 45: 4x</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText } decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val * 4 } prefix="$" />
              : "$0"
            }
          </li>
          <li>
            <span>By age 50: 6x</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText } decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val * 6 } prefix="$" />
              : "$0"
            }
          </li>
          <li>
            <span>By age 55: 7x</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText } decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val * 7 } prefix="$" />
              : "$0"
            }
          </li>
          <li>
            <span>By age 60: 8x</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText } decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val * 8 } prefix="$" />
              : "$0"
            }
          </li>
          <li>
            <span>By age 67: 10x</span>
            {' '}
            {
              moduleServices.module8Data.jobtotalPreTaxExpected_val !== undefined ?
              <NumberFormat className={ classes.planningText } decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module8Data.jobtotalPreTaxExpected_val * 10 } prefix="$" />
              : "$0"
            }
          </li>
        </ul>
      </div>
      <p>These are good benchmarks to help you think about long-term planning.</p>
      <p> Let&apos;s get started with retirement accounts.</p>
      <div className={ classes.tabSectionScroll }>
        <div className={ `${classes.existingstudentloanFlex} ${classes.existingstudentloanFlexBig}` }>
        <div className={classes.existingstudentloanGraphLeft}>
            <table>
              <tr>
                <td>Current Age</td>
              </tr>
              <tr>
                <td>
                  <div className={`${classes.existingstudentInput}`}>
                    <NumberFormat
                      name="currentAge"
                      value={loanRepayment.currentAge}
                      onValueChange={(e) => handleChange(e, 'currentAge')}
                      decimalScale={0}
                      onFocus={ (e) => e.target.select() }
                      fixedDecimalScale
                      customInput={TextField}
                      allowNegative={false}
                      thousandSeparator
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr>
                <td>Target Retirement Age</td>
              </tr>
              <tr>
                <td>
                  <div className={`${classes.existingstudentInput}`}>
                    <NumberFormat
                      name="targetRetirementAge"
                      value={loanRepayment.targetRetirementAge}
                      onValueChange={(e) =>
                        handleChange(e, 'targetRetirementAge')
                      }
                      onFocus={ (e) => e.target.select() }
                      decimalScale={0}
                      fixedDecimalScale
                      customInput={TextField}
                      allowNegative={false}
                      thousandSeparator
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr>
                <td>Life Expectancy</td>
              </tr>
              <tr>
                <td>
                  <div className={`${classes.existingstudentInput}`}>
                    <span>$</span>
                    <NumberFormat
                      name="lifeExpectancy"
                      value={loanRepayment.lifeExpectancy}
                      onValueChange={(e) => handleChange(e, 'lifeExpectancy')}
                      decimalScale={0}
                      onFocus={ (e) => e.target.select() }
                      fixedDecimalScale
                      customInput={TextField}
                      allowNegative={false}
                      thousandSeparator
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr>
                <td>Desired Income in Retirement</td>
              </tr>
              <tr>
                <td>
                  <div className={classes.existingstudentInput}>
                    <span>$</span>
                    <NumberFormat
                      name="incomeInRetirement"
                      value={loanRepayment.incomeInRetirement}
                      onValueChange={(e) =>
                        handleChange(e, 'incomeInRetirement')
                      }
                      onFocus={ (e) => e.target.select() }
                      decimalScale={0}
                      fixedDecimalScale
                      customInput={TextField}
                      allowNegative={false}
                      thousandSeparator
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr class="">
                <td>Nest Egg Needed for Retirement</td>
              </tr>
              <tr>
                <td className={classes.fieldText}>
                  {
                    !isNaN(loanRepayment.nestEggForRetirement) && loanRepayment.nestEggForRetirement !== undefined && loanRepayment.nestEggForRetirement !== Infinity ?
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale
                      displayType={'text'}
                      allowNegative={false}
                      thousandSeparator
                      value={loanRepayment.nestEggForRetirement}
                      prefix={'$'}
                    /> : "$0"
                  }
                </td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr>
                <td>Investment Return</td>
              </tr>
              <tr>
                <td>
                  <div
                    className={`${classes.existingstudentInput} ${classes.inputRight}`}
                  >
                    <NumberFormat
                      name="investmentReturn"
                      value={loanRepayment.investmentReturn}
                      onValueChange={(e) => handleChange(e, 'investmentReturn')}
                      decimalScale={isFocus!=='investmentReturn'?2:5}
                      onFocus={ (e) => {e.target.select(); setIsFocus('investmentReturn')} }
                      fixedDecimalScale={isFocus!=='investmentReturn'}
                      customInput={TextField}
                      allowNegative={false}
                      thousandSeparator
                      onBlur={emptyIsFocus}
                    />
                    <span>%</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr>
                <td>Current Retirement Balance</td>
              </tr>
              <tr>
                <td>
                  <div className={classes.existingstudentInput}>
                    <span>$</span>
                    <NumberFormat
                      name="currentRetirementBalance"
                      value={loanRepayment.currentRetirementBalance}
                      onValueChange={(e) =>
                        handleChange(e, 'currentRetirementBalance')
                      }
                      onFocus={ (e) => e.target.select() }
                      decimalScale={0}
                      fixedDecimalScale
                      customInput={TextField}
                      allowNegative={false}
                      thousandSeparator
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr>
                <td className={classes.tdBorder}></td>
              </tr>
              <tr>
                <td className={classes.h18}></td>
              </tr>
              <tr class="">
                <td>Monthly Contribution Needed</td>
              </tr>
              <tr>
                <td className={classes.fieldText}>
                  {
                    !isNaN(loanRepayment.monthylContributionNeeded) && loanRepayment.monthylContributionNeeded !== undefined && loanRepayment.monthylContributionNeeded !== Infinity ?
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale
                      displayType={'text'}
                      allowNegative={false}
                      thousandSeparator
                      value={loanRepayment.monthylContributionNeeded}
                      prefix={'$'}
                    />
                    : "$0"
                  }
                </td>
              </tr>

              <tr class="">
                <td>Total Contributions</td>
              </tr>
              <tr>
                <td className={classes.fieldText}>
                  {
                    !isNaN(loanRepayment.totalContributions) && loanRepayment.totalContributions !== undefined && loanRepayment.totalContributions !== Infinity ?
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale
                      displayType={'text'}
                      allowNegative={false}
                      thousandSeparator
                      value={loanRepayment.totalContributions}
                      prefix={'$'}
                    />
                    : "$0"
                  }
                </td>
              </tr>

              <tr class="">
                <td>Total Earnings</td>
              </tr>
              <tr>
                <td className={classes.fieldText}>
                  {
                    !isNaN(loanRepayment.totalEarnings) && loanRepayment.totalEarnings !== undefined && loanRepayment.totalEarnings !== Infinity ?
                    <NumberFormat
                      decimalScale={0}
                      fixedDecimalScale
                      displayType={'text'}
                      allowNegative={false}
                      thousandSeparator
                      value={loanRepayment.totalEarnings}
                      prefix={'$'}
                    />
                    : "$0"
                  }
                </td>
              </tr>
            </table>
          </div>
          <div className={classes.existingstudentloanGraphRight}>
            <Typography variant="h3" component="h3">Investment Growth</Typography>
            <hr className={ `${classes.blueLine} ${classes.blueLineShort}` } /><br/>
            <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: 'spline',
                    height: windowHeight,
                    scrollablePlotArea: {
                      scrollPositionX: 0
                    }
                  },
                  credits: {
                    enabled: false
                  },
                  title: {
                    text: '',
                    style: {
                      color: '#000000',
                      fontSize: '14px'
                    }
                  },
                  navigation: {
                    menuItemStyle: {
                      fontSize: '14px',
                      textAlign: 'left'
                    },
                    menuItemHoverStyle: {
                      background: '#f5f5f5',
                      color: '#4c4c4c',
                      fontSize: '14px'
                    },
                    buttonOptions: {
                      height: 40,
                      width: 48,
                      symbolSize: 20,
                      symbolX: 24,
                      symbolY: 21,
                      symbolStrokeWidth: 2,
                      verticalAlign: 'bottom',
                      _titleKey: 'y'
                    }
                  },
                  exporting: {
                    buttons: {
                      contextButton: {
                        menuItems: [{
                          textKey: 'downloadPNG',
                          onclick: function () {
                            this.exportChart();
                          },
                        }, {
                          textKey: 'downloadJPEG',
                          onclick: function () {
                            this.exportChart({
                              type: 'image/jpeg'
                            });
                          }
                        }, {
                          textKey: 'downloadPDF',
                          onclick: function () {
                            this.exportChart({
                              type: 'application/pdf'
                            });
                          }
                        }, {
                          textKey: 'downloadSVG',
                          onclick: function () {
                            this.exportChart({
                              type: 'image/svg+xml'
                            });
                          }
                        }]
                      }
                    }
                  },
                  xAxis: [{
                    // min:0,
                    // tickInterval: 1,
                    // max:parseInt(loanRepayment.currentAge)-1,
                    categories: graphArrayYearsValues,
                    labels: {
                      style: {
                        color: '#000000',
                        fontSize: '14px'
                      }
                    },
                    title: {
                      text: 'Years',
                      style: {
                        color: '#000000',
                        fontSize: '14px'
                      }
                    }
                  }],
                  yAxis: [{ // Primary yAxis
                    labels: {
                      formatter: function () {
                        if (this.value < 0) {
                          return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(this.value)) + ')</span>';
                        } else {
                          return formatDollar(Math.round(this.value));
                        }
                      },
                      style: {
                        color: '#000000',
                        fontSize: '14px'
                      }
                    },
                    title: {
                      text: 'Savings',
                      style: {
                        color: '#000000',
                        fontSize: '14px'
                      }
                    }
                  }],
                  tooltip: {
                    headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
                    crosshairs: true,
                    shared: true,
                    valueDecimals: 0,
                    valuePrefix: '$',
                    style: {
                      color: '#000000',
                      fontSize: '13px'
                    }
                  },
                  plotOptions: {
                    spline: {
                      lineWidth: 2,
                      states: {
                        hover: {
                          lineWidth: 2
                        }
                      },
                      marker: {
                        enabled: false
                      },

                    }
                  },
                  legend: {
                    itemStyle: {
                      fontSize: '14px'
                    }
                  },
                  series: [{
                    name: label1,
                    data: monthlyInvestmentGraph1,
                    color: '#4472C4'
                  }, {
                    name: label2,
                    data: monthlyInvestmentGraph2,
                    color: '#Ed7D31'
                  }, {
                    name: label3,
                    data: monthlyInvestmentGraph3,
                    color: '#A5A5A5'
                  }]
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

RetirementPlanning.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( RetirementPlanning );
