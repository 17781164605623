import searchIcon from '../../assets/img/sight/explorer_search_icon.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  containerFluild: {
    // maxWidth: '90%',
    padding: '0 15px',
    margin: '0px auto',
  },
  explorerHead: {
    background: '#84aa47',
    margin: '0',
    padding: '10px 0',
    color: theme.palette.common.white,
    '& container': {
      maxWidth: '90%',
    },
  },
  explorerTitle: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.1',
    flexWrap: 'wrap',
    '& h2': {
      margin: '0',
      padding: '0',
      color: theme.palette.common.white,
      position: 'relative',
      fontSize: '30px',
      fontWeight: '500',
      fontFamily: '"MuseoSans-300"',
      width: '50%',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
        textAlign: 'center',
      },
      '@media (max-width: 767px)': {
        fontSize: '25px',
      }
    },
  },
  relationYear: {
    width: '50%',
    textAlign: 'right',
    fontSize: '20px',
    fontWeight: '500',
    fontFamily: '"MuseoSans-300"',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      textAlign: 'center',
      fontSize: '18px',
      marginTop: '10px',
      justifyContent: 'center',
    },
    '@media (max-width: 479px)': {
      textAlign: 'left',
    },
    '& p': {
      margin: '0px',
      marginLeft: '5px',
      '@media (max-width: 767px)': {
        fontSize: '14px',
      }
    },
  },
  infoIcon: {
    '& img': {
      marginTop: '3px',
      maxWidth: '20px',
      cursor: 'pointer',
    },
  },
  breadCrumbs: {
    background: '#f1f1f1',
    padding: '8px',
    display: 'inline-block',
    width: '100%',
    boxSizing: 'border-box',
  },
  breadCrumbsLeft: {
    background: theme.palette.common.white,
    padding: '8px',
    float: 'left',
    width: '98%',
    verticalAlign: 'middle',
    display: 'inline-block',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      float: 'none',
      display: 'flex',
    },
  },
  folderIcon: {
    width: '5%',
    float: 'left',
    '& ul': {
      display: 'inline-block',
      float: 'left',
      width: '100%',
      margin: '0',
      padding: '0',
      '& li': {
        position: 'relative',
        padding: '0 15px 0 3px',
        verticalAlign: 'middle',
        display: 'inline-block',
        '& p': {
          display: 'block',
          color: '#000',
          fontSize: '18px',
          lineHeight: '18px',
          border: '0',
          outline: '0',
          textDecoration: 'none',
          cursor: 'default',
          '&:after': {
            position: 'absolute',
            marginTop: '-5px',
            borderTop: '5px solid transparent',
            borderLeft: '8px solid #5a5a5a',
            borderBottom: '5px solid transparent',
            top: '50%',
            right: '0',
            content: '""',
          },
          '& img': {
            width: 'auto',
            height: '18px',
          },
        },
      },
    },
  },
  breadcrumbsList: {
    width: 'calc(100% - 50px)',
    float: 'left',
    verticalAlign: 'middle',
    display: 'inline-block',
    '& ul': {
      display: 'inline-block',
      float: 'left',
      width: '100%',
      margin: '0',
      padding: '0',
      paddingLeft: '0',
      marginLeft: '-3px',
      '& li': {
        position: 'relative',
        padding: '0 20px 0 3px',
        verticalAlign: 'middle',
        display: 'inline-block',
        '& p': {
          display: 'block',
          color: '#000',
          fontSize: '18px',
          lineHeight: '18px',
          border: '0',
          outline: '0',
          textDecoration: 'none',          
          '&:after': {
            position: 'absolute',
            marginTop: '-5px',
            marginRight: '5px',
            borderTop: '5px solid transparent',
            borderLeft: '8px solid #5a5a5a',
            borderBottom: '5px solid transparent',
            top: '50%',
            right: '0',
            content: '""',
          },
          [theme.breakpoints.down( 'md' )]: {
            fontSize: '12px',
            paddingLeft: '5px',
          },
        },
        '&:last-child': {
          '& a': {
            cursor: 'default',
            '&:after': {
              display: 'none',
            },
          },
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '20px',
      },
    },
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
    },
  },
  backgroundBlur: {
    filter: 'grayscale(100%) contrast(0) brightness(100%) opacity(50%)',
  },
  greyBackground: {
    color: 'grey',
  },
  breadCrumbsRight: {
    display: 'inline-block',
    float: 'right',
    width: '25%',
    position: 'relative',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      float: 'none',
      margin: '20px 0px',
    },
  },
  searchInput: {
    width: '100%',
    height: '36px',
    lineHeight: '32px',
    color: '#000',
    border: '2px solid #dcdcdc',
    outline: '0',
    padding: '0 10px',
    fontStyle: 'italic',
    boxSizing: 'border-box',
    fontSize: '14px',
    '&::placeholder': {
      color: '#999',
    },
  },
  searchButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    width: '40px',
    height: '100%',
    backgroundImage: `url(${searchIcon})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '25px',
    border: 'none',
    outline: '0',
    backgroundColor: 'transparent',
  },
  explorerMainContent: {
    padding: '8px',
    background: '#f7f7f7',
    [theme.breakpoints.down( 'md' )]: {
      overflowX: 'auto',
    },
    '& > div': {
      display: 'table',
      width: '100%',
      [theme.breakpoints.down( 'md' )]: {
        minWidth: '1250px',
      },
    },
  },
  explorerContent: {
    width: '17.83%',
    position: 'relative',
    background: '#f7f7f7',
    borderRight: '1px solid #ebebeb',
    display: 'inline-block',
    float: 'left',
    paddingBottom: '12px',
    height: 'auto',
    boxSizing: 'border-box',   
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 'auto',
      right: '-1px',
      width: '1px',
      height: '100%',
      background: '#ebebeb',
      bottom: '0px',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: '0px',
      left: '-1px',
      width: '1px',
      height: '100%',
      background: '#ebebeb',
    },
    '& h2': {
      background: '#5686d2',
      padding: '8px 20px',
      color: '#fff',
      margin: '0',
      fontSize: '20px',
      lineHeight: '20px',
      fontWeight: '500',
      fontFamily: 'inherit',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '18px',
      },
    },
    '& ul': {
      display: 'block',
      width: '100%',
      margin: '0',
      padding: '0',
      '& li': {
        display: 'block',
        padding: '0',
        // background: '#fff',
        borderBottom: '1px solid #ebebeb',
        position: 'relative',
        '& p': {
          display: 'flex',
          color: '#000',
          fontSize: '14px',
          lineHeight: '15px',
          padding: '8px',
          border: '2px solid transparent',
          minHeight: '38px',
          margin: '0',
          outline: '0',
          textDecoration: 'none',
          boxSizing: 'border-box',
          '& img': {
            width: '18px',
            height: '18px',
            margin: '0 7px 0 0',
            verticalAlign: 'middle',
          },
        },
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  },
  borderIcon: {
    borderRadius: '50%',
  },
  sketchContent: {
    '& li': {
      '& a': {
        '& img': {
          width: '30px',
          height: '30px',
          margin: '-8px 7px -8px 0',
          borderRadius: '50%',
          maxWidth: 'inherit',
        },
      },
    },
    '& ul': {
      padding: '0px !important',
    },
  }, 
  scenariosContent: {
    border: 'none',
    width: '23.7%',   
  },
  infoImage: {
    width: '18px',
    height: '18px',
    verticalAlign: 'top',
    margin: '2px 0 0',
    display: 'inline-block',
    marginLeft: '5px',
    '& img': {
      marginTop: '0',
      maxWidth: 'inherit',
      cursor: 'pointer',
      width: '100%',
      height: 'auto',
      verticalAlign: 'top',
    },
  },
  addNewBtn: {
    background: '#f7f7f7',
    padding: '20px 0px 0px',
    boxSizing: 'border-box',
    '& ul': {
      overflow: 'inherit',
      float: 'none',
      position: 'relative',
      display: 'inline-block',
      width: '100%',
      verticalAlign: 'top',
      height: 'auto',
      margin: '0',
      padding: '0',
      '& li': {
        display: 'inline-block',
        float: 'left',
        width: '50%',
        textAlign: 'left',
        background: '0 0',
        border: 'none',
        position: 'relative',
        padding: '0',
        '& a': {
          borderRadius: '100%',
          color: '#fff',
          fontSize: '20px',
          lineHeight: '28px',
          display: 'inline-block',
          width: '30px',
          height: '30px',
          border: 'none',
          textAlign: 'center',
          padding: '0',
          position: 'relative',
          fontFamily: '"MuseoSans-500"',
          minHeight: 'inherit',
        },
        '&:last-child': {
          textAlign: 'right',
        },
      },
    },
  },
  minusBtn: {
    background: '#d9534f',
  },
  loadBtn: {
    textTransform: 'uppercase',
    fontSize: '13px',
    padding: '7px 8px',
    backgroundColor: '#006aaa',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    outline: '0',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#398439',
    },
  },
  smallText: {
    right: '0px',
    position: 'absolute',
    top: '50%',
    lineHeight: '14px',
    marginTop: '-16px',
    fontSize: '14px',
    zIndex: '99',
    cursor: 'pointer',
  },
  modalHeader: {
    padding: '10px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      margin: '0',
      lineHeight: '1.42857143',
      fontSize: '24px',
      color: '#0069aa',
      fontWeight: 'normal',
    },
  },
  modalBody: {
    position: 'relative',
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '14px',
    },
  },
  modalFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: '1px solid #e5e5e5',
  },
  videoPopup: {
    padding: '0px',
  },
  btn: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 5px',
    minWidth: '64px',
    textTransform: 'uppercase',
  },
  footerLoad: {
    backgroundColor: '#337ab7',
    borderColor: '#337ab7',
    color: '#fff',
    outline: '0',
    border: '0px',
    '&:hover': {
      backgroundColor: '#285e8c',
      borderColor: '#285e8c',
      outline: '0',
    },
  },
  footerCancel: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    marginLeft: '10px',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  tooltipInfo: {
    background: '#0069aa',
    fontSize: '14px !important',
    padding: '5px 10px !important',
    maxWidth: '325px',
    lineHeight: '18px',
    textAlign: 'left',
    zIndex: '9999',
    borderRadius: '4px',
    fontWeight: '400',
    opacity: '1 !Important',
    whiteSpace: 'pre-wrap',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '250px',
    },
  },
  senseBody: {
    backgroundColor: '#f7f7f7',
    padding: '0px',
    paddingBottom: '50px',
    margin: '15px 15px 0px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    }
  },
  activeTab: {
    borderColor: ' #99d9ea',
    background: '#99d9ea',   
  },
  SavePopup: {
    padding: '0px',
    '& div:nth-child(3)': {
      '& div:nth-child(2)': {
        padding: '0px',
      },
    },
  },
  sightBreadCrumbs: {
    width: '100%',
    boxSizing: 'border-box',
    '& p': {
      margin: '0px',
    },
  },
  newFolder: {
    background: 'transparent',
    borderBottom: 'none !important',
    '& div': {
      margin: '0',
      padding: '0 23px !important',
      position: 'relative',
      '& input': {
        width: '100%',
        border: 'none',
        borderBottom: '1px solid #dcdcdc',
        background: '0 0',
        margin: '0 auto 11px',
        display: 'block',
        height: '50px !important',     
        lineHeight: '50px',
        padding: '0 15px !important',
        outline: '0',
        fontSize: '16px',
        fontFamily: '"MuseoSans-300"',
        boxSizing: 'border-box',
        '&::placeholder': {
          color: '#0069aa',
        },
      },
    },
  },
  sketchFolders: {
    height: '235px',
    // overflowY: 'auto',
    '& li': {
      '& p': {
        '&:hover': {
          borderColor: '#99d9ea',
          background: '#99d9ea',
          cursor: 'pointer',
        },
      },
      // '& div': {
      //   padding: '0px !important',
      // },
    },
  },
  saveSketchBtn: {
    '& li': {
      width: '100% !important',
      boxSizing: 'border-box',
      float: 'right',
      '& button': {
        background: '#0069aa',
        fontSize: '14px',
        minWidth: 'auto',
        lineHeight: '1.42857143',
        '&:hover': {
          background: '#0069aa',
        },
      },
    },
  },
  sightFooter: {
    padding: '20px 0px 0px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',
    textTransform: 'uppercase',
  },
  closeButton: {
    color: '#9d9595',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#9d9595',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  newScenario: {
    borderBottom: 'none !important',
    '& div': {
      margin: '0',
      padding: '0 23px !important',
      position: 'relative',
      '& input': {
        width: '100%',
        border: 'none',
        borderBottom: '1px solid #dcdcdc',
        background: '0 0',
        margin: '0 auto 11px',
        display: 'block',
        height: '50px !important',
        lineHeight: '50px',
        padding: '0 15px !important',
        outline: '0',
        boxSizing: 'border-box',
        fontSize: '14px',
        fontFamily: '"MuseoSans-300"',        
        '&:focus': {
          boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        },
        '&::placeholder': {
          color: '#0069aa',
        },
      },
    },
  },
  folderSection: {
    width: '50px',
  },
  saveScenarioBtn: {
    '& div': {
      boxSizing: 'border-box',
      float: 'right',
      '& button': {
        background: '#0069aa',
        fontSize: '14px',
        minWidth: 'auto',
        lineHeight: '1.42857143',
        '&:hover': {
          background: '#0069aa',
        },
      },
    },
  },
  scenarioFormat: {
    fontFamily: '"MuseoSans-300"',
    fontSize: '16px',
    color: '#000',
    padding: '0 23px !important',
    borderBottom: 'none !important',
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    '& ul': {
      // padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '14px',
          marginTop: '0px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  csSaveBody:{
    overflow: 'auto',
    paddingBottom: '0',
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
    '@media (max-width: 479px)': {
      flexWrap: 'wrap',
    },
  },
  csSaveImage: {
    width: '45%',
    padding: '50px 50px 30px',
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      padding: '10px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      padding: '30px 20px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 50px 10px',
    },
  },
  greenCircleImage: {
    '& img': {
      maxWidth: '200px',
      marginBottom: '95px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',
        marginBottom: '30px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '120px',  
        marginBottom: '70px',     
      }, 
      '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '120px',  
        marginBottom: '50px',     
      },     
      '@media (max-width: 420px)': {
        maxWidth: '75px',
        marginBottom: '20px',
      },
    },
  },
  pwLogo: {
    '& img': {
      maxWidth: '160px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',       
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '100px',       
      },     
    },
  },
  puzzleText: {
    fontSize: '26px',
    color: '#0069aa',
    fontFamily: 'MuseoSans-100',
    paddingTop: '10px',
    margin: '0 0 10px',
    '@media (max-width: 767px)': {
      fontSize: '23px',   
    }, 
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '23px', 
    },
    '@media (max-width: 420px)': {
      fontSize: '20px',
    },
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'flex-start',
      margin: '30px auto',
      '@media (max-width: 767px)': {
        margin: '30px 15px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        margin: '30px 15px',
      },
      '@media (max-width: 420px)': {
        margin: '30px 15px',
      },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  csSaveMessage: {
    width: '55%',
    padding: '50px 50px 30px',
    background: '#0069aa',
    color: '#fff',
    boxSizing: 'border-box',
    position: 'relative',
    border: '1px solid #0069aa',
    '@media (max-width: 767px)': {
      padding: '20px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {   
      padding: '30px 10px 17px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 10px 10px',
    },   
    '& h1': {
      fontSize: '40px',
      paddingTop: '60px',
      marginBottom: '50px',
      color: '#fff',
      fontWeight: '400',
      marginTop: '20px',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-300',
      '@media (max-width: 767px)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '0px',
        marginBottom: '10px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '50px',
        marginBottom: '20px',
      },
      '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '20px',
      },
      '@media (max-width: 420px)': {
        paddingTop: '10px',
        marginBottom: '10px',
        fontSize: '30px',
      },    
    },
    '& h3': {
      fontSize: '27px',
      marginBottom: '20px',
      color: '#fff',
      fontWeight: '400',
      fontFamily: 'MuseoSans-300',
      marginTop: '20px',
      lineHeight: '1.1',
      '@media (max-width: 767px)': {
        paddingTop: '0px',
        fontSize: '22px',
        marginTop: '10px',
        marginBottom: '10px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '22px',
        marginTop: '20px',
        marginBottom: '20px',
      },
      '@media (max-width: 420px)': {
        margin: '15px auto',
        fontSize: '20px',
      },
    },   
  },
  timeText: {
    paddingTop: '5px',
    fontSize: '18px',
    fontFamily: 'MuseoSans-300',
    margin: '0 0 10px', 
    '@media (max-width: 767px)': {
      fontSize: '15px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '15px',
    },  
  },
  backToModule: {
    paddingTop: '5px',
    fontSize: '18px',
    fontFamily: 'MuseoSans-300',
    margin: '0 0 10px',
    '& a': {
      color: '#fff',
      textTransform: 'uppercase',
      fontSize: '14px',
      textDecoration: 'underline',
      paddingTop: '0',
      '&:hover': {
        color: '#f69a34',
      },
    },
  },
  goTimeline: {
    marginTop: '90px',
    color: '#fff',
    display: 'table',
    fontSize: '20px',
    fontFamily: 'MuseoSans-300',
    lineHeight: 'normal',
    margin: '25px auto',
    textTransform: 'uppercase',
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '10px',
    marginBottom: '5px', 
    '@media (max-width: 767px)': {
      marginTop: '20px',
      fontSize: '15px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      marginTop: '25px',
      fontSize: '15px',
    }, 
    '@media (max-width: 420px)': {
      marginTop: '20px',
    },
    '&:hover': {
      background: '#f69a34',
      borderColor: '#f69a34',
    },   
  },
  closeSaveBtn: {
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    position: 'absolute',
    right: '10px',
    top: '10px',
    left: 'auto',
    float: 'right',
    color: '#fff',
    opacity: '1',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontWeight: '700',
    textTransform: 'none',
    fontFamily: 'MuseoSans-300',
    minWidth: 'auto',
    boxSizing: 'border-box',
    zIndex: '9',
    '@media (max-width: 420px)': {
      color:'#333',
    },
    '&:hover, &:focus': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
    },
  },
  exploreOne: {
    width: '13.5%',   
  },
  exploreTwo: {
    width: '13.5%',   
  },
  exploreThird: {
    width: '13.5%',   
  },
  scrollMenu: {
    minHeight:'233px',
    '& ul': {
      '& li': {
        marginBottom: '0px',
      },
    },
  },
  sightPopupBlock: {
    paddingBottom: '0px',
  },
  pwiFooter: {
    padding: '15px',
  },
  careerListItems: {
    paddingLeft: '26px',
    listStylePosition:'outside',
    textAlign: 'left',
    marginBottom: '10px',
    marginTop: '0',
    '& li': {
      marginBottom: '10px',
      fontSize: '18px',
      fontFamily: '"MuseoSans-300"',
      lineHeight: '1.42857143',
      '@media screen and (min-width: 500px) and (max-width: 767px)': {
        fontSize: '14px',  
        marginBottom: '8px',     
      },
      '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        fontSize: '16px',
        marginBottom: '5px', 
      },
      '@media (max-width: 320px)': {
        fontSize: '15px',  
      }, 
    },
  },
  sketchSaveMessage:{
    width: '55%',
    padding: '50px 50px 30px',
    background: '#0069aa',
    color: '#fff',
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      padding: '20px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {   
      padding: '30px 10px 17px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 10px 10px',
    },   
    '& h1': {
      paddingTop: '60px',
      marginBottom: '50px',
      color: '#fff',
      fontWeight: '400',
      fontFamily: '"MuseoSans-300"',
      fontSize: '36px',
      marginTop: '20px',
      boxSizing: 'border-box',
      lineHeight: '1.1',
      '@media (max-width: 767px)': {
        fontSize: '28px',
        paddingTop: '0px',
      },   
      '@media screen and (min-width: 500px) and (max-width: 600px)': {        
        margin: '0px auto 10px auto',       
      },
      '@media screen and (min-width: 601px) and (max-width: 700px)': {       
        margin: '20px auto 20px auto',      
      },
      '@media screen and (min-width: 701px) and (max-width: 767px)': {
        margin: '40px auto 10px auto',
      },
      '@media (max-width: 420px)': {
        paddingTop: '10px',
        marginBottom: '10px',
        fontSize: '30px',
      },
      '@media (max-width: 320px)': {
        marginTop: '0px', 
      }, 
      '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        margin: '10px auto 10px auto',
        fontSize: '28px',
        paddingTop: '0px',
      },    
    },
    '& h3': {
      marginBottom: '20px',
      marginTop: '20px',
      fontSize: '23px',
      color: '#fff',
      fontWeight: '400',
      fontFamily: '"MuseoSans-300"',
      lineHeight: '1.1',
      '@media screen and (min-width: 500px) and (max-width: 767px)': {
        fontSize: '18px',
        paddingTop: '0px',
      },
      '@media screen and (min-width: 500px) and (max-width: 700px)': {       
        margin: '10px auto 10px auto',        
      },
      '@media screen and (min-width: 701px) and (max-width: 767px)': {
        margin: '20px auto 20px auto',        
      },
      '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        margin: '20px auto 20px auto',
        fontSize: '18px',
        paddingTop: '0px',
      },
      '@media (max-width: 420px)': {
        margin: '15px auto',        
      },
      '@media (max-width: 320px)': {
        fontSize: '20px',
      }, 
    },
  },
  gotoDashboard: {
    color: '#fff',
    display: 'table',
    fontSize: '20px',
    fontFamily: '"MuseoSans-300"',
    lineHeight: 'normal',
    margin: '25px auto',
    textTransform: 'uppercase',
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '10px',
    outline: '0',
    textDecoration: 'none',
    '@media screen and (min-width: 500px) and (max-width: 767px)': {
      fontSize: '14px',
      margin: '10px auto 0px auto',
    },
    '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '16px',
      margin: '10px auto 0px auto',
    },
    '@media (max-width: 320px)': {
      margin: '10px auto 10px auto',  
      fontSize: '20px',      
    },
    '&:hover': {
      background: '#f69a34',
      borderColor: '#f69a34',
    },
  },
  sketchBody:{
    overflow: 'auto',
    paddingBottom: '0',
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
    '@media (max-width: 420px)': {
      flexWrap: 'wrap',
    },
  },
  sketchSaveImage: {
    width: '45%',
    padding: '50px 50px 30px',
    boxSizing: 'border-box',
    '@media (max-width: 767px)': {
      padding: '20px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      padding: '30px 20px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 50px 10px',
    },
  },
  greenCheckImage: {
    '& img': {
      maxWidth: '200px',
      marginBottom: '95px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',
        marginBottom: '28px',
      },
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        marginBottom: '67px',
      },
      '@media only screen and (min-device-width: 800px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '100px',
        marginBottom: '70px',     
      },     
      '@media (max-width: 420px)': {
        maxWidth: '75px',
        marginBottom: '20px',
      },
    },
  },
  puzzleLogo: {
    '& img': {
      maxWidth: '160px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',       
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '100px',       
      },     
    },
  },
  puzzleDesc: {
    fontSize: '26px',
    color: '#0069aa',
    fontFamily: 'MuseoSans-100',
    paddingTop: '10px',
    margin: '0 0 10px',
    '@media (max-width: 767px)': {
      fontSize: '23px',   
    }, 
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '23px', 
    },
    '@media (max-width: 420px)': {
      fontSize: '20px',
    },
  },
  impPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    width: '520px',
    borderRadius: '6px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: '0 auto',
    },
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  impDiv: {
    padding: '15px 0 5px',
    display: 'flex',
    '& p': {
      padding: '0 0 0 24px',
      marginTop: 0,
      fontSize: '16px',
    },
    '& span': {
      paddingTop: '6px',
    },
  },
  impbuttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#337ab7',
        borderColor: '#337ab7',
        color: '#fff',
        marginRight: '6px',
        '&:hover': {
          background: '#285e8c',
          borderColor: '#285e8c',
        },
      },
    },
  },
} );

export default styles;
