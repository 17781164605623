import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-career-path/styles';
import * as moduleServices from '../../calculations/modules-services';
import CareerBottomTabs from './career-bottom-tab-graphs';

const RothIra = ( props ) => {
  const {
    classes, handleCareerObject,  handleUpdatedObject, 
  } = props;
  const [iraPersonalValue, setIRAPeronalValue] = React.useState( 5.00 );
  const [iraPersonalRothValue, setIRAPeronalRothValue] = React.useState( 5.00 );
  const [isFocus, setIsFocus] = React.useState('');
  
  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.roth_personal_contribution !== undefined ) {
      setIRAPeronalValue( handleCareerObject.roth_personal_contribution );
    } else {
      updatedValues['roth_personal_contribution'] = 5.00;
    }
    if ( handleCareerObject.ira_roth_personal_contribution !== undefined ) {
      setIRAPeronalRothValue( handleCareerObject.ira_roth_personal_contribution );
    } else {
      updatedValues['ira_roth_personal_contribution'] = 5.00;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
      newvalue = newvalue > 100 ? 100 : newvalue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    }

    switch ( field ) {
      case 'roth_personal_contribution': {
        newvalue = newvalue > 100 ? 100 : newvalue;
        setIRAPeronalValue( newvalue );
        break;
      }
      case 'ira_roth_personal_contribution': {
        newvalue = newvalue > 100 ? 100 : newvalue;
        setIRAPeronalRothValue( newvalue );
        break;
      }
      default:
        break;
    }
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
      <p>
        There are two types of IRAs – traditional IRA and a Roth IRA. A traditional IRA is a tax-deductible retirement account while a Roth IRA is an after-tax account. How much do you plan to contribute to IRAs?
      </p>
      <p>
        Change the percentage in the table below to contribute to an IRA or Roth IRA and the contribution numbers will update automatically.
      </p>
      <div className={ `${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}` }>
        <p>
          Total Pre-Tax Base Income: $
          {moduleServices.module4Data.totalPreTaxExpected_val !== undefined ? (
            <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={moduleServices.module4Data.totalPreTaxExpected_val} /> ) : ( '$0' )}<br/>
          IRA Balance: $
          {moduleServices.module4Data.rothBeginningBalance !== undefined ? (
            <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={moduleServices.module4Data.rothBeginningBalance} /> ) : ( '$0' )}<br/>
          Roth IRA Balance: $
          {moduleServices.module4Data.rothBeginningBalance !== undefined ? (
            <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={moduleServices.module4Data.rothBeginningBalanceIra} /> ) : ( '$0' )}
        </p>
      </div>
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70}` }>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className={ classes.trHead }>
                <td>IRA Accounts</td>
                <td>IRA</td>
                <td>Roth IRA</td>
              </tr>
              <tr>
                <td>Personal Contribution (% of Base Income)</td>
                <td>
                  <div className={ classes.amountDollar }>
                    <NumberFormat
                      customInput={ TextField }
                      value={ iraPersonalValue }
                      decimalScale={isFocus!=='roth_personal_contribution'?2:5}
                      fixedDecimalScale={isFocus!=='roth_personal_contribution'}
                      onValueChange={ ( e ) => updateValue( e , '','roth_personal_contribution', 'number')}
                      onFocus={ ( e ) => { e.target.select(); setIsFocus('roth_personal_contribution') } }
                      onBlur={emptyIsFocus} />
                    %
                  </div>
                </td>
                <td>
                  <div className={ classes.amountDollar }>
                    <NumberFormat
                      customInput={ TextField }
                      value={ iraPersonalRothValue }
                      decimalScale={isFocus!=='ira_roth_personal_contribution'?2:5}
                      fixedDecimalScale={isFocus!=='ira_roth_personal_contribution'}
                      onValueChange={ ( e ) => updateValue( e , '','ira_roth_personal_contribution', 'number')}
                      onFocus={ ( e ) => { e.target.select(); setIsFocus('ira_roth_personal_contribution') } }
                      onBlur={emptyIsFocus} />
                    %
                  </div>
                </td>
              </tr>
              <tr>
                <td>Annual Contribution</td>
                <td>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      ${moduleServices.module4Data.careerPathRothIRAPersonalContributionCalcs !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={moduleServices.module4Data.careerPathRothIRAPersonalContributionCalcs} /> ) : ( '$0' )}
                    </span>
                  </div>
                </td>
                <td>
                  <div className={ classes.amountDollarRight }>
                    <span>
                      ${moduleServices.module4Data.careerPathRothIRAPersonalContributionCalcsRoth !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={moduleServices.module4Data.careerPathRothIRAPersonalContributionCalcsRoth  } /> ) : ( '$0' )}
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Max Personal Contribution by Law to 401(k) Accounts per Year</td>
                {/* <td></td> */}
                <td colspan="2">
                  <div className={ classes.amountDollarCenter }>
                    <span>
                      ${moduleServices.module4Data.careerPathRothIRAPersonalContribution !== undefined ? (
                        <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={moduleServices.module4Data.careerPathRothIRAPersonalContribution} /> ) : ( '$0' )}
                    </span>
                  </div>
                </td>
                {/* <td></td> */}
              </tr>
              <tr className={classes.trFoot}>
                <td>Total Personal Contribution</td>
                {/* <td>
                  <div className={ classes.amountDollarRight }></div>
                </td> */}
                <td colspan="2">
                  <div className={ classes.amountDollarCenter }>
                    <span>
                      ${moduleServices.module4Data.careerPathTotalIraContributions !== undefined ? (
                        <NumberFormat decimalScale={ 0 } displayType="text" thousandSeparator value={moduleServices.module4Data.careerPathTotalIraContributions}/>) : "$0"}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <CareerBottomTabs handleCareerObject={ handleCareerObject } />
    </div>
  );
};

RothIra.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  // setRothConfirmation: PropTypes.func.isRequired,
  // rothConfirmation: PropTypes.bool.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,

};

export default withStyles( styles )( RothIra );
