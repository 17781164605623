import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  TextField,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import compose from 'recompose/compose';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { confirmAlert } from 'react-confirm-alert';
import Pageloader from '../../../../../components/ui/pageloader';
import { xpPoints, getTotalTime, getUserXPRank } from '../../pf101-service';
import styles from './styles';
import Pf101Popups from '../../popups';
import headerLogo from '../../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import book from '../../../../../assets/img/pf101/icon-book-blue.png';
import resetIcon from '../../../../../assets/img/pf101/circle-arrow.png';
import { FV } from '../../../../../utilities/commonFunctions';
import { formatDollar } from '../../../../../utilities/chartCommonFunctions';

const startTime = new Date();

/* eslint react/prop-types: 0 */

const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );

const BudgetingGuide = ( props ) => {
  const { classes, history } = props;
  const defaultCorrectAnswer = ['1', '1', '3', '1'];
  const defaultQuestionList = [];

  for ( let i = 0; i <= 3; i += 1 ) {
    defaultQuestionList.push( {
      id: i + 1,
      question_attempts: 1,
      xp_earned: 25,
      correct_answer: defaultCorrectAnswer[i],
      status: '',
      answer: '',
    } );
  }

  const defaultInputData = [
    [
      {
        status: 'W', input: 1200, default: 1200, correct: 1400,
      },
      {
        status: 'W', input: 1100, default: 1100, correct: 1150,
      },
    ],
    [
      {
        status: 'W', input: 700, default: 700, correct: 650,
      },
      {
        status: 'W', input: 300, default: 300, correct: 250,
      },
      {
        status: 'W', input: 100, default: 100, correct: 50,
      },
    ],
    [
      {
        status: 'W', input: 50, default: 50, correct: 50,
      },
    ],
    [
      {
        status: '', input: 0, default: 0, correct: 135856,
      },
    ],
  ];

  let bodySection = useRef( null );
  const [loading, setLoading] = React.useState( true );
  const [activeSlide, setActiveSlide] = React.useState( 1 );
  const [questionList, setQuestionList] = React.useState( defaultQuestionList );
  const [progress, setProgress] = React.useState( 1 );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  const [inputData, setInputData] = React.useState( defaultInputData );
  const [budgetData, setBudgetData] = React.useState( {} );
  const [annualReturnGraphData, setAnnualReturnGraphData] = React.useState( {} );

  const budgetCalculation = () => {
    const budget = {};
    budget.salary = 4000;
    budget.salaryAnnual = budget.salary * 12;
    budget.sideJobAnnual = inputData[0][0].input * 12;
    budget.otherIncome = 0;
    budget.otherIncomeAnnual = budget.otherIncome * 12;
    budget.taxesAnnual = inputData[0][1].input * 12;
    budget.totalAfterTaxIncome = budget.salary + inputData[0][0].input - inputData[0][1].input;
    if ( budget.totalAfterTaxIncome === 0 ) {
      budget.totalAfterTaxIncomeStatus = '';
    } else if ( budget.totalAfterTaxIncome === 4250 ) {
      budget.totalAfterTaxIncomeStatus = 'C';
    } else {
      budget.totalAfterTaxIncomeStatus = 'W';
    }
    budget.totalAfterTaxIncomeAnnual = budget.totalAfterTaxIncome * 12;
    if ( budget.totalAfterTaxIncomeAnnual > 0 ) {
      budget.taxesAnnualPercentage = ( ( budget.taxesAnnual / ( budget.salaryAnnual + budget.sideJobAnnual + budget.otherIncomeAnnual ) ) * 100 );
    } else {
      budget.taxesAnnualPercentage = 0;
    }
    budget.rentUtilities = 700;
    budget.rentUtilitiesAnnual = budget.rentUtilities * 12;
    budget.rentUtilitiesPercentage = ( budget.rentUtilitiesAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;

    budget.foodDrinkAnnual = inputData[1][0].input * 12;
    budget.foodDrinkAnnualPercentage = ( budget.foodDrinkAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.clothingPersonalAnnual = inputData[1][1].input * 12;
    budget.clothingPersonalAnnualPercentage = ( budget.clothingPersonalAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;

    budget.entertainmentTech = 300;
    budget.entertainmentTechAnnual = budget.entertainmentTech * 12;
    budget.entertainmentTechAnnualPercentage = ( budget.entertainmentTechAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.transportationGas = 200;
    budget.transportationGasAnnual = budget.transportationGas * 12;
    budget.transportationGasAnnualPercentage = ( budget.transportationGasAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.carLeasePayment = 200;
    budget.carLeasePaymentAnnual = budget.carLeasePayment * 12;
    budget.carLeasePaymentAnnualPercentage = ( budget.carLeasePaymentAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.carInsuranceMaintenance = 300;
    budget.carInsuranceMaintenanceAnnual = budget.carInsuranceMaintenance * 12;
    budget.carInsuranceMaintenanceAnnualPercentage = ( budget.carInsuranceMaintenanceAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.healthInsurancePremium = 150;
    budget.healthInsurancePremiumAnnual = budget.healthInsurancePremium * 12;
    budget.healthInsurancePremiumAnnualPercentage = ( budget.healthInsurancePremiumAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.otherHealthcareCosts = 100;
    budget.otherHealthcareCostsAnnual = budget.otherHealthcareCosts * 12;
    budget.otherHealthcareCostsAnnualPercentage = ( budget.otherHealthcareCostsAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.pet = 100;
    budget.petAnnual = budget.pet * 12;
    budget.petAnnualPercentage = ( budget.petAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.miscellaneousAnnual = inputData[1][2].input * 12;
    budget.miscellaneousAnnualPercentage = ( budget.miscellaneousAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;

    budget.totalLivingExpenses = inputData[1][0].input + inputData[1][1].input + inputData[1][2].input + budget.rentUtilities + budget.entertainmentTech + budget.transportationGas + budget.carLeasePayment + budget.carInsuranceMaintenance + budget.healthInsurancePremium + budget.otherHealthcareCosts + budget.pet;
    budget.totalLivingExpensesAnnual = budget.totalLivingExpenses * 12;
    budget.totalLivingExpensesPercentage = ( budget.totalLivingExpensesAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;

    if ( budget.totalLivingExpenses === 0 ) {
      budget.totalLivingExpensesStatus = '';
    } else if ( budget.totalLivingExpenses === 3000 ) {
      budget.totalLivingExpensesStatus = 'C';
    } else {
      budget.totalLivingExpensesStatus = 'W';
    }

    budget.afterTaxIncome = ( budget.totalLivingExpenses / budget.totalAfterTaxIncome ) * 100;
    budget.afterTaxIncomeAnnual = ( budget.totalLivingExpensesAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.creditCardDebt = 150;
    budget.creditCardDebtAnnual = budget.creditCardDebt * 12;
    budget.creditCardDebtPercentage = ( budget.creditCardDebtAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.studentLoans = 300;
    budget.studentLoansAnnual = budget.studentLoans * 12;
    budget.studentLoansPercentage = ( budget.studentLoansAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.carLoansAnnual = 0;
    budget.carLoansPercentage = 0;
    budget.mortgagesAnnual = 0;
    budget.mortgagesPercentage = 0;
    budget.totalLoanPayments = budget.creditCardDebt + budget.studentLoans;
    if ( budget.totalLoanPayments === 0 ) {
      budget.totalLoanPaymentsStatus = '';
    } else if ( budget.totalLoanPayments === 450 ) {
      budget.totalLoanPaymentsStatus = 'C';
    } else {
      budget.totalLoanPaymentsStatus = 'W';
    }
    budget.totalLoanPaymentsAnnual = budget.totalLoanPayments * 12;
    budget.totalLoanPaymentsPercentage = ( budget.totalLoanPaymentsAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.afterTaxIncomeLoans = ( budget.totalLoanPayments / budget.totalAfterTaxIncome ) * 100;
    budget.afterTaxIncomeLoansAnnual = ( budget.totalLoanPaymentsAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.totalExpenses = budget.totalLoanPayments + budget.totalLivingExpenses;
    if ( budget.totalExpenses === 3450 ) {
      budget.totalExpensesStatus = 'C';
    } else {
      budget.totalExpensesStatus = 'W';
    }
    budget.totalExpensesAnnual = budget.totalLoanPaymentsAnnual + budget.totalLivingExpensesAnnual;
    budget.totalExpensesPercentage = ( budget.totalExpensesAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.afterTaxExpensesLoans = ( budget.totalExpenses / budget.totalAfterTaxIncome ) * 100;
    budget.afterTaxExpensesLoansAnnual = ( budget.totalExpensesAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;
    budget.cashLeftOverSavings = budget.totalAfterTaxIncome - budget.totalExpenses;
    if ( budget.cashLeftOverSavings === 800 ) {
      budget.cashLeftOverSavingsStatus = 'C';
    } else {
      budget.cashLeftOverSavingsStatus = 'W';
    }
    budget.cashLeftOverSavingsAnnual = budget.totalAfterTaxIncomeAnnual - budget.totalExpensesAnnual;
    budget.cashLeftOverSavingsPercentage = ( budget.cashLeftOverSavingsAnnual / budget.totalAfterTaxIncomeAnnual ) * 100;

    setBudgetData( budget );
  };

  useQuery( GET_EXERCISE, {
    variables: {
      level: 3,
      exercise: 2,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questions = [...questionList];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questions[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questions[data.question_id - 1].xp_earned = xpPoints( questions[data.question_id - 1].question_attempts );
          questions[data.question_id - 1].status = 'C';
          questions[data.question_id - 1].answer = questions[data.question_id - 1].correct_answer;
        } );

        let inputDataDetails = [...inputData];
        inputDataDetails = inputDataDetails.map( ( data ) => {
          let data1 = data;
          data1 = data1.map( ( data2 ) => {
            const data3 = data2;
            data3.status = 'C';
            data3.input = data3.correct;
            return data3;
          } );
          return data1;
        } );
        setInputData( inputDataDetails );
        setQuestionList( questions );
        setExerciseStatus( 'review' );
        budgetCalculation();
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const backToDashBoard = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Back to Dashboard</h3>
            <p>If you go back to the Dashboard, your progress will be lost. Are you sure you want to exit the activity and go back to the Dashboard?</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => { onClose(); history.push( '/pf101/dashboard' ); } }
            >
              CONTINUE
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  const updateGraph = () => {
    const monthlySavings = '500';
    const annualRate = 6;
    const monthlySavings1 = '829';

    const graphArray = [];
    const graphArray1 = [];
    const graphArrayYears = [];

    for ( let y = 1; y <= 30; y += 1 ) {
      const endingValue = FV( ( annualRate / 12 ) / 100, y * 12, -monthlySavings, 0, 0 );
      graphArray.push( endingValue );
      const endingValue1 = FV( ( annualRate / 12 ) / 100, y * 12, -monthlySavings1, 0, 0 );
      graphArray1.push( endingValue1 );
      graphArrayYears.push( y );
    }

    setAnnualReturnGraphData(
      {
        list: graphArrayYears,
        data: [{
          name: `$${monthlySavings1} savings per month`,
          data: graphArray1,
          color: '#4472C4',
        }, {
          name: `$${monthlySavings} savings per month`,
          data: graphArray,
          color: '#Ed7D31',
        }],
      },
    );
  };

  useEffect( () => {
    const questionsData = questionList;

    scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } );

    setProgress( parseFloat( ( 100 / questionsData.length ) * activeSlide ) );
    budgetCalculation();

    if ( activeSlide === 4 ) {
      updateGraph();
    }
    // eslint-disable-next-line
  }, [activeSlide] );

  useEffect( () => {
    budgetCalculation();
    // eslint-disable-next-line
  },[inputData])

  const checkAnswer = ( question, status = '' ) => {
    const questions = [...questionList];
    if ( status !== '' ) {
      if ( status === 'C' ) {
        questions[question - 1].answer = questions[question - 1].correct_answer;
      } else {
        questions[question - 1].answer = '';
      }
    }

    if ( questions[question - 1].answer === questions[question - 1].correct_answer ) {
      questions[question - 1].status = 'C';
    } else {
      questions[question - 1].status = 'W';
    }
    questions[question - 1].xp_earned = 20;
    setQuestionList( questions );
  };

  const answerQuestion = ( e ) => {
    const questions = [...questionList];
    questions[activeSlide - 1].answer = e.target.value;
    questions[activeSlide - 1].status = '';
    setQuestionList( questions );
  };

  const resetInputValue = ( index ) => {
    const inputDetails = [...inputData];
    inputDetails[activeSlide - 1][index].input = inputDetails[activeSlide - 1][index].default;
    inputDetails[activeSlide - 1][index].status = 'W';
    setInputData( inputDetails );
    checkAnswer( activeSlide, 'W' );
  };

  const handleInputChange = ( e, index ) => {
    const inputDetails = [...inputData];
    inputDetails[activeSlide - 1][index].input = e.floatValue !== undefined ? e.floatValue : 0;
    if ( inputDetails[activeSlide - 1][index].input === 0 ) {
      inputDetails[activeSlide - 1][index].status = '';
    } else if ( parseFloat( inputDetails[activeSlide - 1][index].input ) === parseFloat( inputDetails[activeSlide - 1][index].correct ) ) {
      inputDetails[activeSlide - 1][index].status = 'C';
    } else {
      inputDetails[activeSlide - 1][index].status = 'W';
    }
    if ( activeSlide === 1 ) {
      if ( inputDetails[activeSlide - 1][0].status === 'C' && inputDetails[activeSlide - 1][1].status === 'C' ) {
        checkAnswer( activeSlide, 'C' );
      } else {
        checkAnswer( activeSlide, 'W' );
      }
    } else if ( activeSlide === 2 ) {
      if ( inputDetails[activeSlide - 1][0].status === 'C' && inputDetails[activeSlide - 1][1].status === 'C' && inputDetails[activeSlide - 1][2].status === 'C' ) {
        checkAnswer( activeSlide, 'C' );
      } else {
        checkAnswer( activeSlide, 'W' );
      }
    } else if ( activeSlide === 4 ) {
      if ( inputDetails[activeSlide - 1][0].status === 'C' ) {
        checkAnswer( activeSlide, 'C' );
      } else {
        checkAnswer( activeSlide, 'W' );
      }
    }
    setInputData( inputDetails );
  };

  const finishActivity = () => {
    if ( exerciseStatus === 'review' ) {
      history.push( '/pf101/dashboard' );
      return false;
    }
    setLoading( true );
    const popupValue = {};

    const totalTime = getTotalTime( startTime );
    let totalPoints = 100;
    const totalAttempts = 4;
    const questionData = questionList.map( ( questions ) => _.pick( questions, ['id', 'question_attempts', 'xp_earned'] ) );

    popupValue.exercisePoints = totalPoints;

    popupValue.precision = false;
    popupValue.precisionPoints = 0;

    popupValue.level = true;
    popupValue.levelPoints = 500;
    popupValue.levelNumber = 3;

    totalPoints += 500;

    const presentRank = getUserXPRank( pf101Points );
    const nextRank = getUserXPRank( pf101Points + totalPoints );
    if ( presentRank !== nextRank ) {
      popupValue.rankChangeStatus = true;
      popupValue.rank = nextRank;
    } else {
      popupValue.rankChangeStatus = false;
      popupValue.rank = '';
    }

    setPopupDetails( popupValue );

    saveExercise( {
      variables: {
        data: {
          level: 3,
          exercise: 2,
          exercise_time: totalTime,
          points: totalPoints,
          total_attempts: totalAttempts,
          questionData,
        },
      },
    } );
    return true;
  };

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.pfHeader }>
        <div className={ classes.container }>
          <div className={ classes.pfHeaderInner }>
            <h3>
              <span className={ classes.breadcrumbTitle }>Level 3:</span>
              {' '}
              Budgeting and Saving
            </h3>
            <Button onClick={ () => { backToDashBoard(); } }>
              <img src={ headerLogo } alt="" />
            </Button>
          </div>
        </div>
      </div>
      <div className={ classes.pfContent }>
        <div className={ classes.stepOne }>
          <div className={ classes.pfContentHeader }>
            <div>
              <h3>
                <span>Activity:</span>
                {' '}
                Budgeting Guidelines (
                {activeSlide}
                {' '}
                of
                {' '}
                {defaultQuestionList.length}
                )
              </h3>
            </div>
            <Link to="/pf101/level3/budgeting-guide" target="_blank">
              <figure>
                <img
                  src={ book }
                  alt="infoicon"
                  data-for="infoLeftIcon"
                  data-tip="Click here to open the activity's reading in a new tab."
                  data-offset="{'left':10}"
                />
                <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
              </figure>
            </Link>
          </div>
          <div className={ classes.ProgressBar }>
            <div className={ classes.ProgressBarInner } style={ { width: `${progress}%` } } />
          </div>

          { activeSlide === 1 && (
            <div className={ classes.contentBlock }>
              <div className={ classes.grayBox }>
                <h3>
                  To complete this activity, use the information to change the monthly details in the input boxes below. There are unlimited number of tries to get it right, but only one right answer. A green check mark will appear once you answer correctly and the Next button will turn blue once you&apos;ve correctly updated each input.
                </h3>
              </div>
              <h3>Great job building Mike&apos;s budget. But, does Mike meet the budgeting guidelines? Let&apos;s explore some simple financial life moves that Mike can make and see the impact on his budget.</h3>
              <h3>Mike wants to earn additional income at his side job:</h3>
              <h3 className={ classes.pfbudgetDetails }>
                <ul>
                  <li>He decides to ref a few more games and earns $200 more per month</li>
                  <li>His additional income will cost him another $50 per month in taxes</li>
                </ul>
              </h3>
              <div className={ classes.pfbudgetTable }>
                <table cellPadding="20" cellSpacing="20">
                  <thead>
                    <tr>
                      <th><em>Mike&apos;s Budget</em></th>
                      <th><u>Monthly</u></th>
                      <th><u>Annual</u></th>
                      <th>&nbsp;</th>
                    </tr>
                    <tr>
                      <th><u>Income</u></th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Salary / Wage</td>
                      <td>
                        <NumberFormat value={ budgetData.salary } displayType="text" thousandSeparator prefix="$" />
                        <span className={ classes.greenCheck }>
                          <i className="las la-check" />
                        </span>
                      </td>
                      <td><NumberFormat value={ budgetData.salaryAnnual } displayType="text" thousandSeparator prefix="$" /></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Side Job</td>
                      <td className={ classes.pfFlexRow }>
                        <span className={ classes.resetIcon } role="button" aria-hidden="true" onClick={ () => resetInputValue( 0 ) }>
                          <img src={ resetIcon } alt="" />
                        </span>
                        <span className={ classes.dollarSymbol }>$</span>
                        <NumberFormat disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[0][0].input } onValueChange={ ( e ) => handleInputChange( e, 0 ) } onFocus={ ( e ) => e.target.select() } />
                        {inputData[0][0].status === 'W' && (
                          <span className={ classes.redTimes }>
                            <i className="las la-times" />
                          </span>
                        )}
                        {inputData[0][0].status === 'C' && (
                          <span className={ classes.greenCheck }>
                            <i className="las la-check" />
                          </span>
                        )}
                      </td>
                      <td><NumberFormat value={ budgetData.sideJobAnnual } displayType="text" thousandSeparator prefix="$" /></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Other Income</td>
                      <td>$0</td>
                      <td>$0</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Taxes</td>
                      <td className={ classes.pfFlexRow }>
                        <span className={ classes.resetIcon } role="button" aria-hidden="true" onClick={ () => resetInputValue( 1 ) }>
                          <img src={ resetIcon } alt="" />
                        </span>
                        <span className={ classes.dollarSymbol }>$</span>
                        <NumberFormat className={ classes.redText } disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[0][1].input } onValueChange={ ( e ) => handleInputChange( e, 1 ) } onFocus={ ( e ) => e.target.select() } />
                        {inputData[0][1].status === 'W' && (
                          <span className={ classes.redTimes }>
                            <i className="las la-times" />
                          </span>
                        )}
                        {inputData[0][1].status === 'C' && (
                          <span className={ classes.greenCheck }>
                            <i className="las la-check" />
                          </span>
                        )}
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.taxesAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.taxesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.taxesAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td className={ classes.redText } />
                    </tr>
                    <tr className={ classes.emptyRow } />
                    <tr className={ classNames( classes.topBorder, classes.boldText, classes.tableRow ) }>
                      <td>Total After-Tax Income</td>
                      <td className={ classes.flexNumber }>
                        <div>
                          <NumberFormat value={ budgetData.totalAfterTaxIncome } displayType="text" thousandSeparator prefix="$" />
                        </div>
                        {budgetData.totalAfterTaxIncomeStatus === 'C' && (
                          <span className={ classNames( classes.greenCheck ) }>
                            <i className="las la-check" />
                          </span>
                        )}
                        {budgetData.totalAfterTaxIncomeStatus === 'W' && (
                          <span className={ classNames( classes.redTimes ) }>
                            <i className="las la-times" />
                          </span>
                        )}
                      </td>
                      <td>
                        <NumberFormat value={ budgetData.totalAfterTaxIncomeAnnual } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          { activeSlide === 2 && (
            <div className={ classes.contentBlock }>
              <h3>Just by reffing a couple more games a month, Mike earns an additional $1,800 per year of after-tax income. Let&apos;s look at his living expenses and find areas he can improve:</h3>
              <div className={ classes.grayBox }>
                <h3 className={ classes.pfbudgetDetails }>
                  <ul>
                    <li>Mike saves $50 per month on food and drink by eating a few more meals at home and making coffee at home</li>
                    <li>He also saves $50 per month on clothing as he buys one less item</li>
                    <li>Lastly, Mike reviews his subscriptions and realizes he has multiple music subscriptions and streaming services that he consolidates and saves another $50 per month</li>
                  </ul>
                </h3>
              </div>
              <div className={ classes.pfbudgetTable }>
                <table cellPadding="20" cellSpacing="20">
                  <thead>
                    <tr>
                      <th><em>Mike&apos;s Budget</em></th>
                      <th><u>Monthly</u></th>
                      <th><u>Annual</u></th>
                      <th>&nbsp;</th>
                    </tr>
                    <tr>
                      <th><u>Income</u></th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Salary / Wage</td>
                      <td>
                        <NumberFormat value={ budgetData.salary } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td><NumberFormat value={ budgetData.salaryAnnual } displayType="text" thousandSeparator prefix="$" /></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Side Job</td>
                      <td>
                        <NumberFormat displayType="text" thousandSeparator value={ inputData[0][0].input } prefix="$" />

                      </td>
                      <td><NumberFormat value={ budgetData.sideJobAnnual } displayType="text" thousandSeparator prefix="$" /></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Other Income</td>
                      <td>$0</td>
                      <td>$0</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Taxes</td>
                      <td className={ classes.redText }>
                        <NumberFormat displayType="text" thousandSeparator value={ inputData[0][1].input } prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.taxesAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.taxesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.taxesAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td className={ classes.redText } />
                    </tr>
                    <tr className={ classes.emptyRow } />
                    <tr className={ classNames( classes.topBorder, classes.boldText, classes.tableRow ) }>
                      <td>Total After-Tax Income</td>
                      <td>
                        <NumberFormat value={ budgetData.totalAfterTaxIncome } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td>
                        <NumberFormat value={ budgetData.totalAfterTaxIncomeAnnual } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className={ classNames( classes.bottomAlign, classes.tableRow ) }>
                      <td colSpan="3" className={ classes.boldText }><u>Living Expenses</u></td>
                      <td>
                        <i>
                          % of After-
                          <br />
                          Tax Income
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <td>Rent and Utilities</td>
                      <td>
                        <NumberFormat value={ budgetData.rentUtilities } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.rentUtilitiesAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.rentUtilitiesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.rentUtilitiesAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.rentUtilitiesPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Food and Drink</td>
                      <td className={ classes.pfFlexRow }>
                        <span className={ classes.resetIcon } role="button" aria-hidden="true" onClick={ () => resetInputValue( 0 ) }>
                          <img src={ resetIcon } alt="" />
                        </span>
                        <span className={ classes.dollarSymbol }>$</span>
                        <NumberFormat className={ classes.redText } disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[1][0].input } onValueChange={ ( e ) => handleInputChange( e, 0 ) } onFocus={ ( e ) => e.target.select() } />
                        {inputData[1][0].status === 'W' && (
                        <span className={ classes.redTimes }>
                          <i className="las la-times" />
                        </span>
                        )}
                        {inputData[1][0].status === 'C' && (
                        <span className={ classes.greenCheck }>
                          <i className="las la-check" />
                        </span>
                        )}
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.foodDrinkAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.foodDrinkAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.foodDrinkAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.foodDrinkAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Clothing and Personal Care</td>
                      <td className={ classes.pfFlexRow }>
                        <span className={ classes.resetIcon } role="button" aria-hidden="true" onClick={ () => resetInputValue( 1 ) }>
                          <img src={ resetIcon } alt="" />
                        </span>
                        <span className={ classes.dollarSymbol }>$</span>
                        <NumberFormat className={ classes.redText } disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[1][1].input } onValueChange={ ( e ) => handleInputChange( e, 1 ) } onFocus={ ( e ) => e.target.select() } />
                        {inputData[1][1].status === 'W' && (
                        <span className={ classes.redTimes }>
                          <i className="las la-times" />
                        </span>
                        )}
                        {inputData[1][1].status === 'C' && (
                        <span className={ classes.greenCheck }>
                          <i className="las la-check" />
                        </span>
                        )}
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.clothingPersonalAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.clothingPersonalAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.clothingPersonalAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.clothingPersonalAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Entertainment and Tech</td>
                      <td>
                        <NumberFormat value={ budgetData.entertainmentTech } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.entertainmentTechAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.entertainmentTechAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.entertainmentTechAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.entertainmentTechAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Transportation and Gas</td>
                      <td>
                        <NumberFormat value={ budgetData.transportationGas } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.transportationGasAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.transportationGasAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.transportationGasAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.transportationGasAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                    <tr>
                      <td>Car Lease Payment</td>
                      <td>
                        <NumberFormat value={ budgetData.carLeasePayment } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.carLeasePaymentAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.carLeasePaymentAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.carLeasePaymentAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.carLeasePaymentAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Car Insurance and Maintenance</td>
                      <td>
                        <NumberFormat value={ budgetData.carInsuranceMaintenance } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.carInsuranceMaintenanceAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.carInsuranceMaintenanceAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.carInsuranceMaintenanceAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.carInsuranceMaintenanceAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Home Expenses</td>
                      <td>
                        $0
                      </td>
                      <td>
                        $0
                      </td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Health Insurance Premium</td>
                      <td>
                        <NumberFormat value={ budgetData.healthInsurancePremium } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.healthInsurancePremiumAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.healthInsurancePremiumAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.healthInsurancePremiumAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.healthInsurancePremiumAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Other Healthcare Costs</td>
                      <td>
                        <NumberFormat value={ budgetData.otherHealthcareCosts } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.otherHealthcareCostsAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.otherHealthcareCostsAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.otherHealthcareCostsAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.otherHealthcareCostsAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Pet</td>
                      <td>
                        <NumberFormat value={ budgetData.pet } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.petAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.petAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.petAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.petAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Miscellaneous</td>
                      <td className={ classes.pfFlexRow }>
                        <span className={ classes.resetIcon } role="button" aria-hidden="true" onClick={ () => resetInputValue( 2 ) }>
                          <img src={ resetIcon } alt="" />
                        </span>
                        <span className={ classes.dollarSymbol }>$</span>
                        <NumberFormat className={ classes.redText } disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[1][2].input } onValueChange={ ( e ) => handleInputChange( e, 2 ) } onFocus={ ( e ) => e.target.select() } />
                        {inputData[1][2].status === 'W' && (
                        <span className={ classes.redTimes }>
                          <i className="las la-times" />
                        </span>
                        )}
                        {inputData[1][2].status === 'C' && (
                        <span className={ classes.greenCheck }>
                          <i className="las la-check" />
                        </span>
                        )}
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.miscellaneousAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.miscellaneousAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.miscellaneousAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.miscellaneousAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr className={ classes.emptyRow } />
                    <tr className={ classNames( classes.topBorder, classes.boldText, classes.tableRow ) }>
                      <td>Total Living Expenses</td>
                      <td className={ classNames( classes.redText, classes.flexNumber ) }>
                        <div>
                          (
                          <NumberFormat value={ budgetData.totalLivingExpenses } displayType="text" thousandSeparator prefix="$" />
                          )
                        </div>
                        {budgetData.totalLivingExpensesStatus === 'C' && (
                        <span className={ classNames( classes.greenCheck ) }>
                          <i className="las la-check" />
                        </span>
                        )}
                        {budgetData.totalLivingExpensesStatus === 'W' && (
                          <span className={ classNames( classes.redTimes ) }>
                            <i className="las la-times" />
                          </span>
                        )}
                      </td>
                      <td className={ classes.redText }>
                        (
                        <NumberFormat value={ budgetData.totalLivingExpensesAnnual } displayType="text" thousandSeparator prefix="$" />
                        )
                      </td>
                      <td>
                        {parseFloat( budgetData.totalLivingExpensesPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                    <tr className={ classes.emptyRow } />
                    <tr className={ classNames( classes.boldText, classes.tableRow ) }>
                      <td><u><i>Loan Payments</i></u></td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Credit Card Debt</td>
                      <td>
                        <NumberFormat value={ budgetData.creditCardDebt } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.creditCardDebtAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.creditCardDebtAnnual } displayType="text" thousandSeparator prefix="$" />
                        ) : (
                          <NumberFormat value={ budgetData.creditCardDebtAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.creditCardDebtPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Student Loans</td>
                      <td>
                        <NumberFormat value={ budgetData.studentLoans } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.studentLoansAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.studentLoansAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.studentLoansAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.studentLoansPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Car Loans</td>
                      <td>$0</td>
                      <td>$0</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Mortgages</td>
                      <td>$0</td>
                      <td>$0</td>
                      <td>0%</td>
                    </tr>

                    <tr className={ classNames( classes.boldText, classes.tableRow, classes.topBorder ) }>
                      <td>Total Loan Payments</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalLoanPayments } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalLoanPaymentsAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td>
                        {' '}
                        {parseFloat( budgetData.totalLoanPaymentsPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                    <tr className={ classNames( classes.totalBorder, classes.boldText, classes.tableRow ) }>
                      <td>Total Expenses</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalExpenses } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        {budgetData.totalExpensesStatus === 'C' && (
                          <span className={ classNames( classes.greenCheck ) }>
                            <i className="las la-check" />
                          </span>
                        )}
                        {budgetData.totalExpensesStatus === 'W' && (
                          <span className={ classNames( classes.redTimes ) }>
                            <i className="las la-times" />
                          </span>
                        )}
                      </td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalExpensesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td>
                        {' '}
                        {parseFloat( budgetData.totalExpensesPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr className={ classes.emptyRow } />

                    <tr className={ classNames( classes.totalBorder, classes.boldText, classes.blueText, classes.tableRow ) }>
                      <td>Cash Left Over for Savings / Invest</td>
                      <td>
                        <NumberFormat value={ budgetData.cashLeftOverSavings } displayType="text" thousandSeparator prefix="$" />
                        {budgetData.cashLeftOverSavingsStatus === 'C' && (
                          <span className={ classNames( classes.greenCheck ) }>
                            <i className="las la-check" />
                          </span>
                        )}
                        {budgetData.cashLeftOverSavingsStatus === 'W' && (
                          <span className={ classNames( classes.redTimes ) }>
                            <i className="las la-times" />
                          </span>
                        )}
                      </td>
                      <td>
                        <NumberFormat value={ budgetData.cashLeftOverSavingsAnnual } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td>
                        {' '}
                        {parseFloat( budgetData.cashLeftOverSavingsPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          )}

          { activeSlide === 3 && (
            <div className={ classes.contentBlock }>
              <h3>Just a few simple moves have saved Mike $150 per month. He didn&apos;t have to change his lifestyle drastically and move back in with his parents. By making a budget, he can see all his income, taxes, and expenses and make adjustments to meet his savings goals.</h3>
              <h3>With Mike&apos;s new and improved budget, he&apos;s saving 19% of his after-tax income for savings and investments. How much more does Mike have to save per month to hit his target of saving 20% of his after-tax income? Use the input fields below to guess and test and find the right answer.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        $39
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        $34
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        $29
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        $24
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
              <div className={ classes.buttonBlock }>
                <Button className={ classNames( classes.checkAnswer, classes.activeButton ) } disabled={ questionList[activeSlide - 1].answer === '' } onClick={ () => checkAnswer( activeSlide ) }>Check Answer</Button>
              </div>
              <div className={ classes.answerStatus }>
                <div className={ classes.answerStatus }>
                  {questionList[activeSlide - 1].status === 'C' && (
                  <h2 className={ classes.correctAnswer }>Correct! Go to the next page</h2>
                  )}
                  {questionList[activeSlide - 1].status === 'W' && <h2 className={ classes.wrongAnswer }>Incorrect. Try again!</h2>}
                </div>
              </div>
              <div className={ classes.pfbudgetTable }>
                <table cellPadding="20" cellSpacing="20">
                  <thead>
                    <tr>
                      <th><em>Mike&apos;s Budget</em></th>
                      <th><u>Monthly</u></th>
                      <th><u>Annual</u></th>
                      <th>&nbsp;</th>
                    </tr>
                    <tr>
                      <th><u>Income</u></th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Salary / Wage</td>
                      <td>
                        <NumberFormat value={ budgetData.salary } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td><NumberFormat value={ budgetData.salaryAnnual } displayType="text" thousandSeparator prefix="$" /></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Side Job</td>
                      <td>
                        <NumberFormat displayType="text" thousandSeparator value={ inputData[0][0].input } prefix="$" />

                      </td>
                      <td><NumberFormat value={ budgetData.sideJobAnnual } displayType="text" thousandSeparator prefix="$" /></td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Other Income</td>
                      <td>$0</td>
                      <td>$0</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Taxes</td>
                      <td className={ classes.redText }>
                        <NumberFormat displayType="text" thousandSeparator value={ inputData[0][1].input } prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.taxesAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.taxesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.taxesAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td className={ classes.redText } />
                    </tr>
                    <tr className={ classes.emptyRow } />
                    <tr className={ classNames( classes.topBorder, classes.boldText, classes.tableRow ) }>
                      <td>Total After-Tax Income</td>
                      <td>
                        <NumberFormat value={ budgetData.totalAfterTaxIncome } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td>
                        <NumberFormat value={ budgetData.totalAfterTaxIncomeAnnual } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className={ classNames( classes.bottomAlign, classes.tableRow ) }>
                      <td colSpan="3" className={ classes.boldText }><u>Living Expenses</u></td>
                      <td>
                        <i>
                          % of After-
                          <br />
                          Tax Income
                        </i>
                      </td>
                    </tr>
                    <tr>
                      <td>Rent and Utilities</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.rentUtilities } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.rentUtilitiesAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.rentUtilitiesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.rentUtilitiesAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.rentUtilitiesPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Food and Drink</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ inputData[1][0].input } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.foodDrinkAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.foodDrinkAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.foodDrinkAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.foodDrinkAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Clothing and Personal Care</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ inputData[1][1].input } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.clothingPersonalAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.clothingPersonalAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.clothingPersonalAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.clothingPersonalAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Entertainment and Tech</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.entertainmentTech } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.entertainmentTechAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.entertainmentTechAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.entertainmentTechAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.entertainmentTechAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Transportation and Gas</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.transportationGas } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.transportationGasAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.transportationGasAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.transportationGasAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.transportationGasAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                    <tr>
                      <td>Car Lease Payment</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.carLeasePayment } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.carLeasePaymentAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.carLeasePaymentAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.carLeasePaymentAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.carLeasePaymentAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Car Insurance and Maintenance</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.carInsuranceMaintenance } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.carInsuranceMaintenanceAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.carInsuranceMaintenanceAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.carInsuranceMaintenanceAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.carInsuranceMaintenanceAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Home Expenses</td>
                      <td className={ classes.redText }>
                        $0
                      </td>
                      <td className={ classes.redText }>
                        $0
                      </td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Health Insurance Premium</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.healthInsurancePremium } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.healthInsurancePremiumAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.healthInsurancePremiumAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.healthInsurancePremiumAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.healthInsurancePremiumAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Other Healthcare Costs</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.otherHealthcareCosts } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.otherHealthcareCostsAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.otherHealthcareCostsAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.otherHealthcareCostsAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.otherHealthcareCostsAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Pet</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.pet } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.petAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.petAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.petAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.petAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Miscellaneous</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ inputData[1][2].input } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.miscellaneousAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.miscellaneousAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.miscellaneousAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.miscellaneousAnnualPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr className={ classes.emptyRow } />
                    <tr className={ classNames( classes.topBorder, classes.boldText, classes.tableRow ) }>
                      <td>Total Living Expenses</td>
                      <td className={ classes.redText }>

                        <NumberFormat value={ budgetData.totalLivingExpenses } displayType="text" thousandSeparator prefix="($" suffix=")" />

                      </td>
                      <td className={ classes.redText }>

                        <NumberFormat value={ budgetData.totalLivingExpensesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />

                      </td>
                      <td>
                        {parseFloat( budgetData.totalLivingExpensesPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                    <tr className={ classes.emptyRow } />
                    <tr className={ classNames( classes.boldText, classes.tableRow ) }>
                      <td><u><i>Loan Payments</i></u></td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Credit Card Debt</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.creditCardDebt } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.creditCardDebtAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.creditCardDebtAnnual } displayType="text" thousandSeparator prefix="$" />
                        ) : (
                          <NumberFormat value={ budgetData.creditCardDebtAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.creditCardDebtPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Student Loans</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.studentLoans } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        {budgetData.studentLoansAnnual !== 0 ? (
                          <NumberFormat value={ budgetData.studentLoansAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                        ) : (
                          <NumberFormat value={ budgetData.studentLoansAnnual } displayType="text" thousandSeparator prefix="$" />
                        )}
                      </td>
                      <td>
                        {parseFloat( budgetData.studentLoansPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr>
                      <td>Car Loans</td>
                      <td className={ classes.redText }>$0</td>
                      <td className={ classes.redText }>$0</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Mortgages</td>
                      <td className={ classes.redText }>$0</td>
                      <td className={ classes.redText }>$0</td>
                      <td>0%</td>
                    </tr>

                    <tr className={ classNames( classes.boldText, classes.tableRow, classes.topBorder ) }>
                      <td>Total Loan Payments</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalLoanPayments } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalLoanPaymentsAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td>
                        {' '}
                        {parseFloat( budgetData.totalLoanPaymentsPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                    <tr className={ classNames( classes.totalBorder, classes.boldText, classes.topBorder ) }>
                      <td>Total Expenses</td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalExpenses } displayType="text" thousandSeparator prefix="($" suffix=")" />

                      </td>
                      <td className={ classes.redText }>
                        <NumberFormat value={ budgetData.totalExpensesAnnual } displayType="text" thousandSeparator prefix="($" suffix=")" />
                      </td>
                      <td>
                        {' '}
                        {parseFloat( budgetData.totalExpensesPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>
                    <tr className={ classes.emptyRow } />

                    <tr className={ classNames( classes.totalBorder, classes.boldText, classes.blueText, classes.topBorder ) }>
                      <td>Cash Left Over for Savings / Invest</td>
                      <td>
                        <NumberFormat value={ budgetData.cashLeftOverSavings } displayType="text" thousandSeparator prefix="$" />

                      </td>
                      <td>
                        <NumberFormat value={ budgetData.cashLeftOverSavingsAnnual } displayType="text" thousandSeparator prefix="$" />
                      </td>
                      <td>
                        {' '}
                        {parseFloat( budgetData.cashLeftOverSavingsPercentage ).toFixed( 0 )}
                        %
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          )}

          { activeSlide === 4 && (
            <div className={ classes.contentBlock }>
              <h3>Mike is now saving $829 per month. What If Mike invests the $829 each month and earned a 6% return, how much cash will he have in ten years? (use the graph below to find the correct answer)</h3>
              <div className={ classes.pfSkills }>
                <span className={ classes.dollarSymbol }>$</span>
                <NumberFormat disabled={ exerciseStatus === 'review' } customInput={ TextField } thousandSeparator value={ inputData[3][0].input } onValueChange={ ( e ) => handleInputChange( e, 0 ) } onFocus={ ( e ) => e.target.select() } />
                {inputData[3][0].status === 'W' && (
                  <span className={ classes.redTimes }>
                    <i className="las la-times" />
                  </span>
                )}
                {inputData[3][0].status === 'C' && (
                  <span className={ classes.checkMark }>
                    <i className="las la-check" />
                  </span>
                )}
              </div>
              <div className={ classes.potentialGrowth }>
                <Typography variant="h3" component="h3">Savings Growth at $500/month VS $829/month</Typography>
                <hr className={ classes.blueLine } />
                <div className={ classes.graphImage }>
                  <HighchartsReact
                    highcharts={ Highcharts }
                    options={ {
                      chart: {
                        type: 'spline',
                        scrollablePlotArea: {
                          width: 600,
                          scrollPositionX: 0,
                        },
                      },
                      credits: {
                        enabled: false,
                      },
                      title: {
                        text: '',
                        style: {
                          color: '#000000',
                          fontSize: '14px',
                        },
                      },
                      exporting: {
                        enabled: false,
                      },
                      xAxis: [{
                        tickInterval: 1,
                        categories: annualReturnGraphData.list,
                        labels: {
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                        title: {
                          text: 'Years',
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                      }],
                      yAxis: [{
                        labels: {
                          formatter() {
                            const chart = this;
                            if ( chart.value < 0 ) {
                              return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                            }
                            return formatDollar( Math.round( chart.value ) );
                          },
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                        title: {
                          text: '',
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                      }],
                      tooltip: {
                        headerFormat: '<span style="font-size:14px">Year {point.key}</span><br/>',
                        crosshairs: true,
                        shared: true,
                        valueDecimals: 0,
                        valuePrefix: '$',
                        style: {
                          color: '#000000',
                          fontSize: '13px',
                        },
                      },
                      plotOptions: {
                        spline: {
                          lineWidth: 2,
                          states: {
                            hover: {
                              lineWidth: 2,
                            },
                          },
                          marker: {
                            enabled: false,
                          },

                        },
                      },
                      legend: {
                        itemStyle: {
                          fontSize: '14px',
                        },
                      },
                      series: annualReturnGraphData.data,
                    } }
                  />
                </div>
              </div>
              <h3>A few simple financial life moves can make all the difference. When you build your budget, look for a few ways to save or earn more. A few hundred dollars a month saved over time has a huge impact as you can see below.</h3>
            </div>
          )}

          <div className={ classes.btnGroup }>
            {activeSlide !== 1 && <Button className={ classNames( classes.manageButton, classes.backButton ) } onClick={ () => { setActiveSlide( activeSlide - 1 ); } }>Back</Button>}

            {defaultQuestionList.length === activeSlide ? (
              <Button className={ classNames( classes.manageButton, classes.nextButton ) } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => finishActivity() }>FINISH</Button>
            ) : (
              <Button className={ classNames( classes.manageButton, classes.nextButton ) } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => { setActiveSlide( activeSlide + 1 ); } }>Next</Button>
            )}
          </div>
        </div>
      </div>
      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}
    </Typography>
  );
};

BudgetingGuide.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( BudgetingGuide );
