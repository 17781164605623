function incomeTaxesSheet( module1Data, module2Data, module4Data, module5Data, module6Data, module8Data, module15Data, module17Data, databaseData, taxStatementData ) {
  let singleDataArray = {};
  let medicareDataArray = {};
  let marriedDataArray = {};
  let medicare2DataArray = {};
  let year;
  const currentYear = 2018;

  // let yearPrevious;
  // yearPrevious = parseInt( module1Data.startYear, 10 )-1;
  const yearLimit = parseInt( module1Data.start_year, 10 ) + 15;
  
  const incomeTaxesData = {};
  let grossIncome = 0;
  let federalIncome = 0;
  let medicareIncome = 0;
  let state = '';
  let stateTax = 0;
  let city = '';
  let cityTax = 0;
  let totalTaxes = 0;
  const taxInflation = 2;

  // For Medicare Taxes
  let totalGrossIncomeExclRentalIncome = 0;
  let addnMedicareThreshold = 0;
  let medicareTaxRateValue = 0;
  let selfEmployedRate = 0;
  let notSelfEmployedRate = 0;
  let addnMedicareTaxRate = 0;
  let selfEmployedAddnRate = 0;
  let notSelfEmployedAddnRate = 0;
  let medicareTaxesValue = 0;
  let addnMedicareTaxesValue = 0;

  // Percentage Array
  const percentageArray = [10, 12, 22, 24, 32, 35, 37];
  const medicarePercentageArray = [1.45, 2.35];

  // Single Range Arrays
  const singleRangeArray = [0,9525,38700,82500,157500,200000,500000];
  const singleRange2Array = [9525,38700,82500,157500,200000,500000,0];

  // New Single Range Array
  const singleRangeArrayNew = [0,9700,39475,84200,160725,204100,510300];
  const singleRange2ArrayNew = [9700,39475,84200,160725,204100,510300,0];

  // New Single Range Second Array
  const singleRangeArrayNewSecond = [0,9875,40125,85525,163300,207350,518400];
  const singleRange2ArrayNewSecond =[9875,40125,85525,163300,207350,518400,0];

  // Medicare Range Arrays
  const medicareRangeArray = [0, 200000];
  const medicareRange2Array = [200000, 0];

  // Medicare2 Range Arrays
  const medicare2RangeArray = [0, 250000];
  const medicare2Range2Array = [250000, 0];

  // Married Range Arrays
  const marriedRangeArray = [0,19050,77400,165000,315000,400000,600000];
	const marriedRange2Array = [19050,77400,165000,315000,400000,600000,0];
	
	// New Married Range Arrays
	const marriedRangeArrayNew = [0,19400,78950,168400,321450,408200,612350];
	const marriedRange2ArrayNew = [19400,78950,168400,321450,408200,612350,0];
	
	// Second New Married Range Arrays
	const marriedRangeArrayNewSecond = [0,19750,80250,171050,326600,414700,622050];
	const marriedRange2ArrayNewSecond =[19750,80250,171050,326600,414700,622050,0];

  singleDataArray = percentageArray.map( ( percentage, index ) => {
    const returnData = {};
    returnData.Percentage = percentage;
    returnData.Range = singleRangeArray[index];
    returnData.Range2 = singleRange2Array[index];
    if ( singleRange2Array[index] > 0 ) {
      returnData.Difference = singleRange2Array[index] - singleRangeArray[index];
      returnData.Tax = Math.round( returnData.Difference * ( percentage / 100 ) );
    } else {
      returnData.Difference = 0;
      returnData.Tax = 0;
    }
    return returnData;
  } );

  medicareDataArray = medicarePercentageArray.map( ( percentage, index ) => {
    const returnData = {};
    returnData.Percentage = percentage;
    returnData.Range = medicareRangeArray[index];
    returnData.Range2 = medicareRange2Array[index];
    if ( index === 0 ) {
      returnData.Difference = returnData.Range2 - returnData.Range;
      returnData.Tax = Math.round( returnData.Difference * ( percentage / 100 ) );
    } else {
      returnData.Difference = 0;
      returnData.Tax = 0;
    }
    return returnData;
  } );

  medicare2DataArray = medicarePercentageArray.map( ( percentage, index ) => {
    const returnData = {};
    returnData.Percentage = percentage;
    returnData.Range = medicare2RangeArray[index];
    returnData.Range2 = medicare2Range2Array[index];
    if ( index === 0 ) {
      returnData.Difference = returnData.Range2 - returnData.Range;
      returnData.Tax = Math.round( returnData.Difference * ( percentage / 100 ) );
    } else {
      returnData.Difference = 0;
      returnData.Tax = 0;
    }

    return returnData;
  } );

  marriedDataArray = percentageArray.map( ( percentage, index ) => {
    const returnData = {};
    returnData.Percentage = percentage;
    returnData.Range = marriedRangeArray[index];
    returnData.Range2 = marriedRange2Array[index];
    if ( index === 0 ) {
      returnData.Difference = 9075;
      returnData.Tax = 908;
    } else if ( index >= 1 && index < 6 ) {
      returnData.Difference = marriedRange2Array[index] - marriedRangeArray[index];
      returnData.Tax = Math.round( returnData.Difference * ( percentage / 100 ) );
    } else if ( index === 6 ) {
      returnData.Difference = 0;
      returnData.Tax = 0;
    }

    return returnData;
  } );

  function singleCalculation( income, year, inflationData ) {
    // Total Difference
    let totalDifference = 0;

    // Total Taxes
    let inTotalTaxes = 0;

    const singleIteration1Array = percentageArray.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
      let range = 0;
      if ( income >= inflationData[index].Range ) {
        range = inflationData[index].Range;
      } else {
        range = 0;
      }
      returnData.Range = range;

      // Range 2
      let range2 = 0;

      if ( income >= inflationData[index].Range2 ) {
        range2 = inflationData[index].Range2;
      } else if ( income <= inflationData[index].Range2 && income >= inflationData[index].Range ) {
        range2 = income;
      } else {
        range2 = 0;
      }
      returnData.Range2 = range2;

      // Difference
      returnData.Difference = range2 - range;
      totalDifference += returnData.Difference;

      // Tax
      returnData.Tax = returnData.Difference * ( percentage / 100 );
      inTotalTaxes += returnData.Tax;
      return returnData;
    } );

    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( inTotalTaxes > 0 ) {
      federalIncomeTax = ( inTotalTaxes / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity;

    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo.Income = income;
    taxInfo['Iteration Data'] = singleIteration1Array;
    taxInfo['Total Difference'] = totalDifference;
    taxInfo['Total Taxes'] = inTotalTaxes;
    taxInfo['Federal Income Tax'] = federalIncomeTax;

    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;
    return taxInfo;
  }

  function taxBreakDownsingleCalculation( year, inflationData ) {
    const taxBreakDownsingleArray = percentageArray.map( ( percentage, index ) => {
      const returnData = {};
      // Percentage
      returnData.Percentage = percentage;
      // Range 1
      let range = 0;
      let range2 = 0;
      range = inflationData.Single['Iteration Data'][index].Range;
      range2 = inflationData.Single['Iteration Data'][index].Range2;
      returnData.Range = range;
      returnData.Range2 = range2;
      return returnData;
    } );
    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo['Iteration Data'] = taxBreakDownsingleArray;
    return taxInfo;
  }

  function taxBreakDownMarriedCalculation( year, inflationData ) {
    const taxBreakDownsingleArray = percentageArray.map( ( percentage, index ) => {
      const returnData = {};
      // Percentage
      returnData.Percentage = percentage;
      // Range 1
      let range = 0;
      let range2 = 0;
      range = inflationData.Married['Iteration Data'][index].Range;
      range2 = inflationData.Married['Iteration Data'][index].Range2;
      returnData.Range = range;
      returnData.Range2 = range2;
      return returnData;
    } );
    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo['Iteration Data'] = taxBreakDownsingleArray;
    return taxInfo;
  }

  function medicareCalculation( income, year ) {
    // Total Difference
    let totalDifference = 0;

    // Total Taxes
    let inTotalTaxe = 0;

    const medicareIteration1Array = medicarePercentageArray.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
      let range = 0;
      if ( income >= medicareDataArray[index].Range ) {
        range = medicareDataArray[index].Range;
      } else {
        range = 0;
      }
      returnData.Range = range;

      // Range 2
      let range2 = 0;
      if ( index === 1 ) {
        if ( income >= medicareDataArray[index].Range ) {
          range2 = income;
        } else {
          range2 = 0;
        }
      } else if ( income >= medicareDataArray[index].Range2 ) {
        range2 = medicareDataArray[index].Range2;
      } else if ( income <= medicareDataArray[index].Range2
        && income >= medicareDataArray[index].Range ) {
        range2 = income;
      } else {
        range2 = 0;
      }
      returnData.Range2 = range2;

      // Difference
      returnData.Difference = range2 - range;
      totalDifference += returnData.Difference;

      // Tax
      returnData.Tax = returnData.Difference * ( percentage / 100 );
      inTotalTaxe += returnData.Tax;

      return returnData;
    } );

    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( inTotalTaxe > 0 ) {
      federalIncomeTax = ( inTotalTaxe / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity;

    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo.Income = income;
    taxInfo['Iteration Data'] = medicareIteration1Array;
    taxInfo['Total Difference'] = totalDifference;
    taxInfo['Total Taxes'] = inTotalTaxe;
    taxInfo['Federal Income Tax'] = federalIncomeTax;

    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;

    return taxInfo;
  }

  function medicare2Calculation( income, year ) {
    // Total Difference
    let totalDifference = 0;

    // Total Taxes
    let inTotalTaxes = 0;

    const medicareIteration1Array = medicarePercentageArray.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
      let range = 0;
      if ( income >= medicare2DataArray[index].Range ) {
        range = medicare2DataArray[index].Range;
      } else {
        range = 0;
      }
      returnData.Range = range;

      // Range 2
      let range2 = 0;
      if ( index === 1 ) {
        if ( income >= medicare2DataArray[index].Range ) {
          range2 = income;
        } else {
          range2 = 0;
        }
      } else if ( income >= medicare2DataArray[index].Range2 ) {
        range2 = medicare2DataArray[index].Range2;
      } else if ( income <= medicare2DataArray[index].Range2
        && income >= medicare2DataArray[index].Range ) {
        range2 = income;
      } else {
        range2 = 0;
      }
      returnData.Range2 = range2;

      // Difference
      returnData.Difference = range2 - range;
      totalDifference += returnData.Difference;

      // Tax
      returnData.Tax = returnData.Difference * ( percentage / 100 );
      inTotalTaxes += returnData.Tax;
      return returnData;
    } );

    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( inTotalTaxes > 0 ) {
      federalIncomeTax = ( inTotalTaxes / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity;

    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo.Income = income;
    taxInfo['Iteration Data'] = medicareIteration1Array;
    taxInfo['Total Difference'] = totalDifference;
    taxInfo['Total Taxes'] = inTotalTaxes;
    taxInfo['Federal Income Tax'] = federalIncomeTax;

    taxInfo['Medicare Tax Rate'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;
    return taxInfo;
  }

  function marriedCalculation( income, year, inflationData ) {
    // Total Difference
    let totalDifference = 0;

    // Total Taxes
    let inTotalTaxes = 0;

    const marriedIteration1Array = percentageArray.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
      let range = 0;
      if ( income >= inflationData[index].Range ) {
        range = inflationData[index].Range;
      } else {
        range = 0;
      }
      returnData.Range = range;

      // Range 2
      let range2 = 0;

      if ( income >= inflationData[index].Range2 ) {
        range2 = inflationData[index].Range2;
      } else if ( income <= inflationData[index].Range2 && income >= inflationData[index].Range ) {
        range2 = income;
      } else {
        range2 = 0;
      }
      returnData.Range2 = range2;

      // Difference
      returnData.Difference = range2 - range;
      totalDifference += returnData.Difference;

      // Tax
      returnData.Tax = returnData.Difference * ( percentage / 100 );
      inTotalTaxes += returnData.Tax;
      return returnData;
    } );
    // Federal Income Tax
    let federalIncomeTax = 0;
    if ( inTotalTaxes > 0 ) {
      federalIncomeTax = ( inTotalTaxes / income ) * 100;
    }
    // Social Security
    const socialSecurity = 7.65;
    // Total Tax
    const totalTax = federalIncomeTax + socialSecurity;

    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo.Income = income;
    taxInfo['Iteration Data'] = marriedIteration1Array;
    taxInfo['Total Difference'] = totalDifference;
    taxInfo['Total Taxes'] = inTotalTaxes;
    taxInfo['Federal Income Tax'] = federalIncomeTax;

    taxInfo['Effective Tax Rate'] = federalIncomeTax;
    taxInfo['Social Security'] = socialSecurity;
    taxInfo['Total Tax'] = totalTax;

    return taxInfo;
  }

  /* For Inflation Adjusted tax brackets */
  function singleCalculationInflation( income, year, loopData ) {
    let range = 0;
    const singleIteration1Array = percentageArray.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
	  
      if ( year === ( currentYear + 1 ) ) {
        if ( index === 0 ) {
          if ( year === currentYear ) {
            range = 0;
          } else {
            range = singleDataArray[index].Range * ( ( 1 + ( taxInflation / 100 ),( year - currentYear ) ) ** ( 1 + ( taxInflation / 100 ), ( year - currentYear ) ) );
          }
        } else {
          range = singleRangeArrayNew[index];
        }
      } else {
		  if ( year === ( currentYear + 2 ) ) {
			if ( index === 0 ) {
			  if ( year === currentYear ) {
				range = 0;
			  } else {
				range = singleDataArray[index].Range * ( ( 1 + ( taxInflation / 100 ), ( year - currentYear ) ) ** ( 1 + ( taxInflation / 100 ), ( year - currentYear ) ) );
			  }
			} else {
			  range = singleRangeArrayNewSecond[index];
			}
		  } 
		  else {
			  if ( typeof loopData !== 'undefined' && loopData !== '' && year !== 0 ) {
				range = loopData.Single['Iteration Data'][index].Range * ( 1 + 2 / 100 );
			  }
		  }
	  }
      returnData.Range = range;

      // Range 2
      let range2 = 0;
      if ( year === ( currentYear + 1 ) ) {
        range2 = singleRange2ArrayNew[index];
      } else if ( year === ( currentYear + 2 ) ) {
        range2 = singleRange2ArrayNewSecond[index];
      } else if ( typeof loopData !== 'undefined' && loopData !== '' && year !== 0 ) {
        range2 = loopData.Single['Iteration Data'][index].Range2 * ( 1 + 2 / 100 );
      }
      returnData.Range2 = range2;

      // Difference
      if ( index === 6 ) {
        returnData.Difference = 0;
      } else {
        returnData.Difference = range2 - range;
      }

      // Tax
      if ( index === 6 ) {
        returnData.Tax = 0;
      } else {
        returnData.Tax = returnData.Difference * ( percentage / 100 );
      }
      return returnData;
    } );

    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo.Income = income;
    taxInfo['Iteration Data'] = singleIteration1Array;

    return taxInfo;
  }

  function marriedCalculationInflation( income, year, loopData ) {
    const marriedIteration1Array = percentageArray.map( ( percentage, index ) => {
      const returnData = {};

      // Percentage
      returnData.Percentage = percentage;

      // Range 1
      let range = 0;
      if ( year === ( currentYear + 1 ) ) {
        if ( index === 0 ) {
          if ( year === currentYear ) {
            range = 0;
          } else {
            range = marriedDataArray[index].Range * ( ( 1 + ( taxInflation / 100 ), ( year - currentYear ) ) ** ( 1 + ( taxInflation / 100 ), ( year - currentYear ) ) );
          }
        } else {
          range = marriedRangeArrayNew[index];
        }
      } else if ( year === ( currentYear + 2 ) ) {
        if ( index === 0 ) {
          if ( year === currentYear ) {
            range = 0;
          } else {
            range = marriedDataArray[index].Range * ( ( 1 + ( taxInflation / 100 ), ( year - currentYear ) ) ** ( 1 + ( taxInflation / 100 ), ( year - currentYear ) ) );
          }
        } else {
          range = marriedRangeArrayNewSecond[index];
        }
      } else if ( typeof loopData !== 'undefined' && loopData !== '' && year !== 0 ) {
        range = loopData.Married['Iteration Data'][index].Range * ( 1 + 2 / 100 );
      }
      returnData.Range = range;

      // Range 2
      let range2 = 0;
      if ( year === ( currentYear + 1 ) ) {
        range2 = marriedRange2ArrayNew[index];
      } else if ( year === ( currentYear + 2 ) ) {
        range2 = marriedRange2ArrayNewSecond[index];
      } else if ( typeof loopData !== 'undefined' && loopData !== '' && year !== 0 ) {
        range2 = loopData.Married['Iteration Data'][index].Range2 * (  1 + 2 / 100 );
      }
      returnData.Range2 = range2;

      // Difference
      if ( index === 6 ) {
        returnData.Difference = 0;
      } else {
        returnData.Difference = range2 - range;
      }
      returnData.Difference = range2 - range;

      // Tax
      if ( index === 6 ) {
        returnData.Tax = 0;
      } else {
        returnData.Tax = returnData.Difference * ( percentage / 100 );
      }

      return returnData;
    } );

    const taxInfo = {};
    taxInfo.Year = year;
    taxInfo.Income = income;
    taxInfo['Iteration Data'] = marriedIteration1Array;

    return taxInfo;
  }

  incomeTaxesData['State Local Taxes'] = {};
  incomeTaxesData['Medicare Taxes'] = {};
  incomeTaxesData['Federal Income'] = {};
  incomeTaxesData['Inflation Adjusted Tax Brackets'] = {};
  incomeTaxesData['Tax Breakdown for the Above Year'] = {};

  incomeTaxesData['Federal Income'] = {};
  incomeTaxesData['Federal Income']['Single Data'] = singleDataArray;
  incomeTaxesData['Federal Income']['Medicare Data'] = medicareDataArray;
  incomeTaxesData['Federal Income']['Medicare2 Data'] = medicare2DataArray;
  incomeTaxesData['Federal Income']['Married Data'] = marriedDataArray;

  //let year = parseInt( module1Data.start_year, 10 );
  year = 2018;
  for ( year; year < yearLimit; year += 1 ) {
    /*
     * State & Local Taxes
     */
    incomeTaxesData['State Local Taxes'][year] = {};

    // Gross Income
	if ( typeof taxStatementData !== 'undefined'
      && typeof taxStatementData[year] !== 'undefined'
      && typeof taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined' ) {
      grossIncome = taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
    } else {
      grossIncome = 0;
    }
    incomeTaxesData['State Local Taxes'][year]['Adjustment Gross Income'] = grossIncome;

    // State
    state = false;
	if ( typeof module2Data !== 'undefined'
      && typeof module2Data.start_year !== 'undefined' && typeof module2Data.graduation_year !== 'undefined'
      && year >= parseInt( module2Data.start_year, 10 ) && year <= parseInt( module2Data.graduation_year, 10 ) ) {
      //state = ( typeof module2Data.state_id !== 'undefined' && module2Data.state_id !== '' && module2Data.state_id > 0 ) ? module2Data.state_id : true;
	  state = (typeof module2Data.state !== 'undefined' && module2Data.state !== '')?module2Data.state:true;
    } else {
		if ( typeof module4Data !== 'undefined'
		  && typeof module4Data.start_year !== 'undefined' && typeof module4Data.end_year !== 'undefined'
		  && year >= parseInt( module4Data.start_year, 10 ) && year <= parseInt( module4Data.end_year, 10 ) ) {
		  //state = ( typeof module4Data.state_id !== 'undefined' && module4Data.state_id !== '' && module4Data.state_id > 0 ) ? module4Data.state_id : true;
		  state = (typeof module4Data.state !== 'undefined' && module4Data.state !== '' )?module4Data.state:true;
		} else {
			if ( typeof module5Data !== 'undefined'
			  && typeof module5Data.start_year !== 'undefined' && typeof module5Data.end_year !== 'undefined'
			  && year >= parseInt( module5Data.start_year, 10 ) && year <= parseInt( module5Data.end_year, 10 ) ) {
			  //state = ( typeof module5Data.state_id !== 'undefined' && module5Data.state_id !== '' && module5Data.state_id > 0 ) ? module5Data.state_id : true;
			  state = (typeof module5Data.state !== 'undefined' && module5Data.state  !== '' )?module5Data.state:true;
			} else {
				if ( typeof module6Data !== 'undefined'
				  && typeof module6Data.start_year !== 'undefined' && module6Data.start_year !== '' && typeof module6Data.graduation_year !== 'undefined'
				  && year >= parseInt( module6Data.start_year, 10 ) && year <= parseInt( module6Data.graduation_year, 10 ) ) {
				 // state = ( typeof module6Data.state_id !== 'undefined' && module6Data.state_id !== '' && module6Data.state_id > 0 ) ? module6Data.state_id : true;
				  state = (typeof module6Data.state !== 'undefined' && module6Data.state !== '')?module6Data.state:true;
				} else {
					if ( typeof module8Data !== 'undefined'
					  && typeof module8Data.start_year !== 'undefined' && typeof module8Data.end_year !== 'undefined'
					  && year >= parseInt( module8Data.start_year, 10 ) && year <= parseInt( module8Data.end_year, 10 ) ) {
					  state = (typeof module8Data.state !== 'undefined' && module8Data.state !== '' )?module8Data.state:true;
					} else {
						if ( typeof module15Data !== 'undefined'
						  && typeof module15Data.start_year !== 'undefined' && typeof module15Data.graduation_year !== 'undefined'
						  && year >= parseInt( module15Data.start_year, 10 ) && year <= parseInt( module15Data.graduation_year, 10 ) ) {
						  state = (typeof module15Data.state !== 'undefined' && module15Data.state !== '')?module15Data.state:true;
						} else {
							if ( typeof module17Data !== 'undefined'
							  && typeof module17Data.start_year !== 'undefined' && typeof module17Data.end_year !== 'undefined'
							  && year >= parseInt( module17Data.start_year, 10 ) && year <= parseInt( module17Data.end_year, 10 ) ) {
							  state = (typeof module17Data.state !== 'undefined' && module17Data.state !== '')?module17Data.state:true;
							} else{
								state = false;		
							}
						}
					}
				}
			} 
		}
	}
	
    incomeTaxesData['State Local Taxes'][year].State = state;

    // City
    city = false;
    if ( typeof module2Data !== 'undefined'
      && typeof module2Data.start_year !== 'undefined' && typeof module2Data.graduation_year !== 'undefined'
      && year >= parseInt( module2Data.start_year, 10 ) && year <= parseInt( module2Data.graduation_year, 10 ) ) {
      city = ( typeof module2Data.educationName !== 'undefined' && module2Data.educationName.city !== '' ) ? module2Data.educationName.city : true;
    } else {
		if ( typeof module4Data !== 'undefined'
		  && typeof module4Data.start_year !== 'undefined' && typeof module4Data.end_year !== 'undefined'
		  && year >= parseInt( module4Data.start_year, 10 ) && year <= parseInt( module4Data.end_year, 10 ) ) {
		  city = ( typeof module4Data.city !== 'undefined' && module4Data.city !== '' ) ? module4Data.city : true;
		} else {
			if ( typeof module5Data !== 'undefined'
			  && typeof module5Data.start_year !== 'undefined' && typeof module5Data.end_year !== 'undefined'
			  && year >= parseInt( module5Data.start_year, 10 ) && year <= parseInt( module5Data.end_year, 10 ) ) {
			  city = ( typeof module5Data.city !== 'undefined' && module5Data.city !== '' ) ? module5Data.city : true;
			} else {
				if ( typeof module6Data !== 'undefined'
				  && typeof module6Data.start_year !== 'undefined' && typeof module6Data.graduation_year !== 'undefined'
				  && year >= parseInt( module6Data.start_year, 10 ) && year <= parseInt( module6Data.graduation_year, 10 ) ) {
				  city = ( typeof module6Data.educationName !== 'undefined' && module6Data.educationName.city !== '' ) ? module6Data.educationName.city : true;
				} else {
					if ( typeof module8Data !== 'undefined'
					  && typeof module8Data.start_year !== 'undefined' && typeof module8Data.end_year !== 'undefined'
					  && year >= parseInt( module8Data.start_year, 10 ) && year <= parseInt( module8Data.end_year, 10 ) ) {
					  city = ( typeof module8Data.city !== 'undefined' && module8Data.city !== '' ) ? module8Data.city : true;
					} else {
						if ( typeof module15Data !== 'undefined'
						  && typeof module15Data.start_year !== 'undefined' && typeof module15Data.graduation_year !== 'undefined'
						  && year >= parseInt( module15Data.start_year, 10 ) && year <= parseInt( module15Data.graduation_year, 10 ) ) {
						  city = ( typeof module15Data.educationName !== 'undefined' && module15Data.educationName.city !== '' ) ? module15Data.educationName.city : true;
						} else {
							if ( typeof module17Data !== 'undefined'
							  && typeof module17Data.start_year !== 'undefined' && typeof module17Data.end_year !== 'undefined'
							  && year >= parseInt( module17Data.start_year, 10 ) && year <= parseInt( module17Data.end_year, 10 ) ) {
							  city = ( typeof module17Data.city !== 'undefined' && module17Data.city !== '' ) ? module17Data.city : true;
							} else{
								city = false;	
							}
						} 
					}
				}
			}
		} 
	}
    incomeTaxesData['State Local Taxes'][year].City = city;

	// State Tax
    stateTax = 0.0322;
	if( typeof module2Data !== 'undefined' && module2Data !== '' && 
		typeof module2Data.state !== 'undefined' && module2Data.state !== 'Please select' && 
		typeof databaseData !== 'undefined' && 
		databaseData !== '' &&
		typeof databaseData['Data']['State'][module2Data.state] !== 'undefined' ){
		stateTax = databaseData['Data']['State'][module2Data.state];
		
	} else{
		if ( typeof module4Data !== 'undefined'
		  && typeof module4Data.state !== 'undefined' && module4Data.state !== '' && typeof databaseData !== 'undefined' 
		  && databaseData !== '' 
		  && typeof databaseData['Data']['State'][module4Data.state] != 'undefined' ){
			stateTax = databaseData['Data']['State'][module4Data.state];
		} else{
			if(typeof module5Data !== 'undefined' &&
			   typeof module5Data.state !== 'undefined' && module5Data.state !== '' && module5Data.state !== '' &&
			   typeof databaseData !== 'undefined' 
			   && databaseData !== '' 
			   && typeof databaseData['Data']['State'][module5Data.state] != 'undefined') {
				stateTax = databaseData['Data']['State'][module5Data.state];
				
			} else {
				if( typeof module6Data !== 'undefined' && module6Data !== '' && 
					typeof module6Data.state !== 'undefined' && module6Data.state !== 'Please select' && 
					typeof databaseData !== 'undefined' && 
					databaseData !== '' &&
					typeof databaseData['Data']['State'][module6Data.state] != 'undefined' ){
					stateTax = databaseData['Data']['State'][module6Data.state];
				} else {
					if ( typeof module8Data !== 'undefined'
					  && typeof module8Data.state !== 'undefined' && module8Data.state !== 0 ) {
						  stateTax = databaseData['Data']['State'][module8Data.state];
					} else {
						if( typeof module15Data !== 'undefined' && module15Data !== '' && 
							typeof module15Data.state !== 'undefined' && module15Data.state !== 'Please select' && 
							typeof databaseData !== 'undefined' && 
							databaseData !== '' &&
							typeof databaseData['Data']['State'][module15Data.state] != 'undefined' ){
							stateTax = databaseData['Data']['State'][module15Data.state];
						} else{ 
							if ( typeof module17Data !== 'undefined'
							  && typeof module17Data.state !== 'undefined' && module17Data.state !== '' ) {
							  stateTax = databaseData['Data']['State'][module17Data.state];
                }
                      }
					}
				}
			}
		}
	}
	stateTax = 0.0322;
    incomeTaxesData['State Local Taxes'][year]['State tax'] = stateTax;

    // City Tax
    if ( typeof module4Data !== 'undefined'
      && typeof module4Data.start_year !== 'undefined'
      && typeof module4Data.end_year !== 'undefined'
      && year >= parseInt( module4Data.start_year, 10 )
      && year <= parseInt( module4Data.end_year, 10 )
      && typeof module4Data.city_taxes !== 'undefined' ) {
      cityTax = module4Data.city_taxes / 100;
    } else if ( typeof module5Data !== 'undefined'
      && typeof module5Data.start_year !== 'undefined'
      && typeof module5Data.end_year !== 'undefined'
      && year >= parseInt( module5Data.start_year, 10 )
      && year <= parseInt( module5Data.end_year, 10 )
      && typeof module5Data.city_taxes !== 'undefined' ) {
      cityTax = module5Data.city_taxes / 100;
    } else if ( typeof module8Data !== 'undefined'
      && typeof module8Data.start_year !== 'undefined'
      && typeof module8Data.end_year !== 'undefined'
      && year >= parseInt( module8Data.start_year, 10 )
      && year <= parseInt( module8Data.end_year, 10 )
      && typeof module8Data.city_taxes !== 'undefined' ) {
      cityTax = module8Data.city_taxes / 100;
    } else if ( typeof module17Data !== 'undefined'
      && typeof module17Data.start_year !== 'undefined'
      && typeof module17Data.end_year !== 'undefined'
      && year >= parseInt( module17Data.start_year, 10 )
      && year <= parseInt( module17Data.end_year, 10 )
      && typeof module17Data.city_taxes !== 'undefined' ) {
      cityTax = module17Data.city_taxes / 100;
    } else {
      cityTax = databaseData.Data && databaseData.Data.City.Average;
    }
    if ( !city ) {
      cityTax = 0;
    }
	cityTax = 0.015;
    incomeTaxesData['State Local Taxes'][year]['City tax'] = cityTax;

    // Total Taxes
    totalTaxes = ( ( grossIncome * stateTax ) + ( grossIncome * cityTax ) );
    incomeTaxesData['State Local Taxes'][year]['Total Taxes'] = totalTaxes;

    // Medicare Taxes
    incomeTaxesData['Medicare Taxes'][year] = {};

    if ( typeof taxStatementData !== 'undefined'
      && typeof taxStatementData[year] !== 'undefined'
      && typeof taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined' ) {
      totalGrossIncomeExclRentalIncome = taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
    } else {
      totalGrossIncomeExclRentalIncome = 0;
    }
    incomeTaxesData['Medicare Taxes'][year]['Total Gross Income (excl Rental Income)'] = totalGrossIncomeExclRentalIncome;

    // Addn Medicare Threshold
    if ( typeof databaseData !== 'undefined' && typeof databaseData['Medicare Taxes'] !== 'undefined'
      && typeof databaseData['Medicare Taxes'][year] !== 'undefined'
      && typeof databaseData['Medicare Taxes'][year]['Additional Medicare Tax Threshold'] !== 'undefined' ) {
      addnMedicareThreshold = databaseData['Medicare Taxes'][year]['Additional Medicare Tax Threshold'];
    } else {
      addnMedicareThreshold = 0;
    }
    incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Threshold'] = addnMedicareThreshold;

    // Medicare Tax Rate
    // Database B366 and B335
    if ( typeof databaseData !== 'undefined' && typeof databaseData['Medicare Taxes'] !== 'undefined'
      && typeof databaseData['Medicare Taxes']['Self-Employed Rate'] !== 'undefined' ) {
      selfEmployedRate = databaseData['Medicare Taxes']['Self-Employed Rate'];
      notSelfEmployedRate = databaseData['Medicare Taxes']['Not Self-Employed Rate'];
    } else {
      selfEmployedRate = 0;
      notSelfEmployedRate = 0;
    }
	if ( typeof module4Data !== 'undefined' && typeof module4Data.start_year !== 'undefined'
      && typeof module4Data.end_year !== 'undefined'
      && year >= module4Data.start_year && year <= module4Data.end_year
      && typeof module4Data.self_employment !== 'undefined' && module4Data.self_employment === 'Yes' ) {
      medicareTaxRateValue = selfEmployedRate;
    } else {
		if ( typeof module5Data !== 'undefined' && typeof module5Data.start_year !== 'undefined'
		  && typeof module5Data.end_year !== 'undefined'
		  && year >= module5Data.start_year && year <= module5Data.end_year
		  && typeof module5Data.self_employment !== 'undefined' && module5Data.self_employment === 'Yes' ) {
		  medicareTaxRateValue = selfEmployedRate;
		} else {
			if ( typeof module8Data !== 'undefined' && typeof module8Data.start_year !== 'undefined' && typeof module8Data.end_year !== 'undefined'
			  && year >= module8Data.start_year && year <= module8Data.end_year
			  && typeof module8Data.self_employment !== 'undefined' && module8Data.self_employment === 'Yes' ) {
			  medicareTaxRateValue = selfEmployedRate;
			} else {
				if ( typeof module17Data !== 'undefined' && typeof module17Data.start_year !== 'undefined' && typeof module17Data.end_year !== 'undefined'
				  && year >= module17Data.start_year && year <= module17Data.end_year
				  && typeof module17Data.self_employment !== 'undefined' && module17Data.self_employment === 'Yes' ) {
				  medicareTaxRateValue = selfEmployedRate;
				} else {
				  medicareTaxRateValue = notSelfEmployedRate;
				}
			}
		}
	}
    incomeTaxesData['Medicare Taxes'][year]['Medicare Tax Rate'] = medicareTaxRateValue;

    // Addn Medicare Tax Rate
    if ( typeof databaseData !== 'undefined' && databaseData !== '' && typeof databaseData['Medicare Taxes'] !== 'undefined'
      && typeof databaseData['Medicare Taxes']['Self-Employed Addn Rate'] !== 'undefined' ) {
      selfEmployedAddnRate = databaseData['Medicare Taxes']['Self-Employed Addn Rate'];
      notSelfEmployedAddnRate = databaseData['Medicare Taxes']['Not Self-Employed Addn Rate'];
    } else {
      selfEmployedAddnRate = 0;
      notSelfEmployedAddnRate = 0;
    }

    if ( incomeTaxesData['Medicare Taxes'][year]['Total Gross Income (excl Rental Income)'] <= incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Threshold'] ) {
      addnMedicareTaxRate = 0;
    } else {
		if ( typeof module4Data !== 'undefined' && typeof module4Data.start_year !== 'undefined'
		  && typeof module4Data.end_year !== 'undefined'
		  && year >= module4Data.start_year && year <= module4Data.end_year
		  && typeof module4Data.self_employment !== 'undefined' && module4Data.self_employment === 'Yes' ) {
		  addnMedicareTaxRate = selfEmployedAddnRate;
		} else {
			if ( typeof module5Data !== 'undefined' && typeof module5Data.start_year !== 'undefined'
			  && typeof module5Data.end_year !== 'undefined'
			  && year >= module5Data.start_year && year <= module5Data.end_year
			  && typeof module5Data.self_employment !== 'undefined' && module5Data.self_employment === 'Yes' ) {
			  addnMedicareTaxRate = selfEmployedAddnRate;
			} else { 
				if ( typeof module8Data !== 'undefined' && typeof module8Data.start_year !== 'undefined' && typeof module8Data.end_year !== 'undefined'
				  && year >= module8Data.start_year && year <= module8Data.end_year
				  && typeof module8Data.self_employment !== 'undefined' && module8Data.self_employment === 'Yes' ) {
				  addnMedicareTaxRate = selfEmployedAddnRate;
				} else { 
					if ( typeof module17Data !== 'undefined' && typeof module17Data.start_year !== 'undefined' && typeof module17Data.end_year !== 'undefined'
					  && year >= module17Data.start_year && year <= module17Data.end_year
					  && typeof module17Data.self_employment !== 'undefined' && module17Data.self_employment === 'Yes' ) {
					  addnMedicareTaxRate = selfEmployedAddnRate;
					} else {
					  addnMedicareTaxRate = notSelfEmployedAddnRate;
					}
				}
			}
		}
	}
    incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Tax Rate'] = addnMedicareTaxRate;

    // Medicare Taxes
    if ( incomeTaxesData['Medicare Taxes'][year]['Total Gross Income (excl Rental Income)'] > incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Threshold'] ) {
      medicareTaxesValue = ( incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Threshold'] / 100 ) * incomeTaxesData['Medicare Taxes'][year]['Medicare Tax Rate'];
    } else {
      medicareTaxesValue = ( incomeTaxesData['Medicare Taxes'][year]['Total Gross Income (excl Rental Income)'] / 100 ) * incomeTaxesData['Medicare Taxes'][year]['Medicare Tax Rate'];
    }
    incomeTaxesData['Medicare Taxes'][year]['Medicare Taxes'] = medicareTaxesValue;

    // Addn Medicare Taxes
    if ( incomeTaxesData['Medicare Taxes'][year]['Total Gross Income (excl Rental Income)'] > incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Threshold'] ) {
      addnMedicareTaxesValue = ( ( incomeTaxesData['Medicare Taxes'][year]['Total Gross Income (excl Rental Income)'] - incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Threshold'] ) / 100 ) * incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Tax Rate'];
    } else {
      addnMedicareTaxesValue = 0;
    }
    incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Taxes'] = addnMedicareTaxesValue;

    // Total Medicare Taxes
    incomeTaxesData['Medicare Taxes'][year]['Total Medicare Taxes'] = incomeTaxesData['Medicare Taxes'][year]['Medicare Taxes'] + incomeTaxesData['Medicare Taxes'][year]['Addn Medicare Taxes'];

    /**
     * Federal Income & Social Security and Medicare Taxes
     */
    // Federal Income
    if ( typeof taxStatementData !== 'undefined'
      && typeof taxStatementData[year] !== 'undefined'
      && typeof taxStatementData[year]['Federal Taxable Income'] !== 'undefined' ) {
      federalIncome = taxStatementData[year]['Federal Taxable Income'];
    } else {
      federalIncome = 0;
    }

    // Medicare Income
    if ( typeof taxStatementData !== 'undefined'
      && typeof taxStatementData[year] !== 'undefined'
      && typeof taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== 'undefined' ) {
      medicareIncome = taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'];
    } else {
      medicareIncome = 0;
    }

    // Inflation Adjusted Tax Brackets for single
    incomeTaxesData['Inflation Adjusted Tax Brackets'][year] = {};
    incomeTaxesData['Inflation Adjusted Tax Brackets'][year].Single = {};
    incomeTaxesData['Inflation Adjusted Tax Brackets'][year].Single = singleCalculationInflation( federalIncome, year, incomeTaxesData['Inflation Adjusted Tax Brackets'][year - 1] );
    incomeTaxesData['Inflation Adjusted Tax Brackets'][year].Married = {};
    incomeTaxesData['Inflation Adjusted Tax Brackets'][year].Married = marriedCalculationInflation( federalIncome, year, incomeTaxesData['Inflation Adjusted Tax Brackets'][year - 1] );

    // incomeTaxesData['Tax Breakdown for the Above Year']
    incomeTaxesData['Tax Breakdown for the Above Year'][year] = {};
    incomeTaxesData['Tax Breakdown for the Above Year'][year].Single = taxBreakDownsingleCalculation( year, incomeTaxesData['Inflation Adjusted Tax Brackets'][year] );
    incomeTaxesData['Tax Breakdown for the Above Year'][year].Married = taxBreakDownMarriedCalculation( year, incomeTaxesData['Inflation Adjusted Tax Brackets'][year] );

    incomeTaxesData['Federal Income'][year] = {};
    // Single
    incomeTaxesData['Federal Income'][year].Single = {};
    incomeTaxesData['Federal Income'][year].Single = singleCalculation( federalIncome, year, incomeTaxesData['Tax Breakdown for the Above Year'][year].Single['Iteration Data'] );

    // Medicare
    incomeTaxesData['Federal Income'][year].Medicare = {};
    incomeTaxesData['Federal Income'][year].Medicare = medicareCalculation( medicareIncome, year );
    // Medicare2
    incomeTaxesData['Federal Income'][year].Medicare2 = {};
    incomeTaxesData['Federal Income'][year].Medicare2 = medicare2Calculation( medicareIncome, year );

    // Married
    incomeTaxesData['Federal Income'][year].Married = {};
    incomeTaxesData['Federal Income'][year].Married = marriedCalculation( federalIncome, year, incomeTaxesData['Inflation Adjusted Tax Brackets'][year].Married['Iteration Data'] );
    incomeTaxesData.percentageArray = percentageArray;
    incomeTaxesData.singleRangeArray = singleRangeArray;
    incomeTaxesData.singleRange2Array = singleRange2Array;
    incomeTaxesData.marriedRangeArray = marriedRangeArray;
    incomeTaxesData.marriedRange2Array = marriedRange2Array;
  }

  function incomeTaxesCompleteData() {
	return incomeTaxesData;
  }

  return incomeTaxesCompleteData();
}
export default incomeTaxesSheet;