import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruSeven = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruSeven)}>
            {walkThruNo === 1 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Financial Independence module, you will begin your journey to financial independence by entering your start year. The start year sets the 5, 10, 15 year period that you will plan for. Then, enter your base assumptions. If you are unsure of an input and need more information, hover on the information icons next to the inputs for more details. Don't forget to save your data as you build your scenarios.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 2 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Existing Assets & Liabilities module, you can develop a plan to effectively manage your existing assets and liabilities as you look to build a complete financial life plan. On the left side, you can enter your details for existing federal and private student loans. And, on the right side, you can build a plan to repay existing credit card debt and account for your existing car, car expenses, and car loan. Plus, if you own a home, make sure to get all of your details into the existing home section. These inputs are crucial to calculating your net worth accurately.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 3 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Career Path module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 4 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Career Advancement module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 5 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Additional Career Advancement module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 6 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Further Career Advancement module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 7 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Investments module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 8 ?
                <div>
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the REI module, you can develop a purchasing strategy and see the effects of putting more or less money down. Then, finance your purchase with a mortgage and see exactly how much you’ll need to pay monthly for principal, interest, taxes, and insurance (PITI). Enter your income and expenses assumptions and the REI will automatically develop monthly and annual cash flows. On the right side, input your expected sale details and instantly see how your sale affects your returns. Develop multiple buying, managing, and selling strategies that results in solid returns.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 9 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the My Details section, you can build an unlimited number of scenarios for this module. Begin in the left panel and start working through some inputs. Use the scroll bar on the right of each window to scroll down or simply hover your mouse in the window and use your trackpad. If you are unsure of an input and need more information, hover on the information icons next to the inputs for more details. As you start entering data, the FC will simultaneously transform your data. Don't forget to save your data as you build your scenarios.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots  walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 10 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the My Details section, you can build an unlimited number of scenarios for this module. Begin in the left panel and start working through some inputs. Use the scroll bar on the right of each window to scroll down or simply hover your mouse in the window and use your trackpad. If you are unsure of an input and need more information, hover on the information icons next to the inputs for more details. As you start entering data, the FC will simultaneously transform your data. Don’t forget to save your data as you build your scenarios.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 11 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the My Details section, you can build an unlimited number of scenarios for this module. Begin in the left panel and start working through some inputs. Use the scroll bar on the right of each window to scroll down or simply hover your mouse in the window and use your trackpad. If you are unsure of an input and need more information, hover on the information icons next to the inputs for more details. As you start entering data, the FC will simultaneously transform your data. Don't forget to save your data as you build your scenarios.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 12 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Family module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 13 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Vacation module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 14 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Buying a Car module, you can develop a monthly and yearly budget for your income and expenses. Plus, learn about and strategize on contributing to retirement accounts. On the left side, you can enter your income and expenses. The FC will instantly calculate your monthly budget and net income. Positive net income is the goal. And, on the right side, you can input your current retirement account balances and build a plan to contribute a portion of your income to retirement. Developing a strategy for your current or next career move that results in positive net income is crucial to growing your net worth.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 15 ?
                < div >
                    <h4>Create Scenarios by Inputting Your Details</h4>
                    <p>In the Buying a Home module, you can develop a purchasing strategy and see the effects of putting more or less money down. Then, finance your purchase with a mortgage and see exactly how much you’ll need to pay monthly for principal, interest, taxes, and insurance (PITI) and your debt-to-income ratio. Enter your monthly expenses and the FC will automatically develop monthly cash flows. Develop an unlimited number of scenarios for the homes you are looking to buy and find the best home for your plan.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(7) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(7); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div >
    );
};

WalkThruSeven.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruSeven);