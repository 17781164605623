const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  inkwiryBenfitsBannerOne: {
    position: 'relative',
    textAlign: 'center',
    padding: '0',
    '& img': {
      width: '100%',
      display: 'flex',
    },
    '& h3': {
      position: 'absolute',
      top: '0',
      bottom: '0',
      margin: 'auto',
      left: '0',
      right: '0',
      zIndex: '999',
      letterSpacing: '1.3px',
      color: theme.palette.common.white,
      fontSize: '53px',
      background: ' rgba(0, 105, 170, 0.2)',
      height: 'calc(100% - 0)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-100',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& h3': {
        fontSize: '25px',
      },
    },
  },
  Categories: {
    padding: '90px 0',
    background: '#f2f2f2',
    minHeight: '500px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0',
    },
    '@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait)  and (-webkit-min-device-pixel-ratio: 2)': {
      minHeight: '775px',
    },
  },
  CategoriesList: {
    display: 'flex',
    borderBottom: '1px solid #ccc',
    padding: '0 15px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0',
    },
    '& p': {
      fontSize: '15px',
      color: theme.palette.common.pureBlack,
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '13px',
      },
    },
    '& ul': {
      listStyle: 'none',
      display: 'flex',
      paddingBottom: '30px',
      [theme.breakpoints.down( 'sm' )]: {
        paddingLeft: '0px',
      },
      '& li': {
        '& span': {
          fontSize: '15px',
          color: theme.palette.common.grey,
          cursor: 'pointer',
          padding: '5px 20px',
          background: theme.palette.common.white,
          margin: '0 5px',
          textDecoration: 'none',
          '@media screen and (min-width: 600px) and (max-width: 992px)': {
            fontSize: '14px',
            padding: '5px 15px',
          },
          [theme.breakpoints.down( 'xs' )]: {
            padding: '5px 16px',
          },
          '&:hover': {
            color: theme.palette.common.blue,
            borderColor: theme.palette.common.blue,
          },
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        flexWrap: 'wrap',
        padding: '0',
        '& li': {
          display: 'inline-block',
          marginBottom: '15px',
        },
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& p': {
        margin: '0',
      },
      flexWrap: 'wrap',
      padding: '0',
    },
  },
  CategoryNormal: {
    '& span': {
      border: '2px solid #fff',
      color: theme.palette.common.black,
    },
  },
  activeClass: {
    '& span': {
      border: '2px solid #fff',
      color: '#0069aa !important',
      borderColor: theme.palette.common.blue,
    },
  },
  newsBox: {
    background: theme.palette.common.white,
    padding: '10px 20px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 10px',
    },
    '& h3': {
      height: '75px',
      color: theme.palette.common.black,
      textDecoration: 'none',
      fontSize: '20px',
      fontFamily: 'MuseoSans-100',
      margin: '12px 0 37px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '17px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '15px',
        height: 'auto',
      },       
    },
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      margin: '0',
      '& font': {
        float: 'right',
      },
      '@media screen and (min-width: 600px) and (max-width: 768px)': {
        fontSize: '13px',
      },
    },
  },
  newsBoxMain: {
    padding: '25px 25px',
    '& figure': {
      margin: '0',
      '& img': {
        width: '100%',
        display: 'flex',
      },
    },   
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px',
    },
    '@media (max-width: 767px)': {
      padding: '20px 5px',
    },     
  },
  categoriesListAll: {
    padding: '50px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '10px 0',
    },
    [theme.breakpoints.down( 'xs' )]: {
      justifyContent: 'center',
      padding: '10px',
    },
  },

} );

export default styles;
