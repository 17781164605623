import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import NumberFormat from 'react-number-format';
import { loader } from 'graphql.macro';
import { useMutation, useQuery, useApolloClient } from '@apollo/react-hooks';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Pageloader from '../../../components/ui/pageloader';
import styles from './styles';
import logoImage from '../../../assets/img/pf101-dashboard/dashboard-logo.png';
// import inivestLogoImage from '../../../assets/img/mcs/inkwiry-univest-rev.png';
import powerUpLogo from '../../../assets/img/pf101-dashboard/powerup.png';
import finance from '../../../assets/img/pf101-dashboard/personal-finance.png';
import incomeExpenses from '../../../assets/img/pf101-dashboard/income-expenses.png';
import budgetSaving from '../../../assets/img/pf101-dashboard/budgeting.png';
import challenge from '../../../assets/img/pf101-dashboard/challenge1.png';
import bankCards from '../../../assets/img/pf101-dashboard/banking-cards.png';
import mobileBanking from '../../../assets/img/pf101-dashboard/online-mobile-banking.png';
import creditScore from '../../../assets/img/pf101-dashboard/credit-score.png';
import investing from '../../../assets/img/pf101-dashboard/investing.png';
import insurance from '../../../assets/img/pf101-dashboard/insurance.png';
import goals from '../../../assets/img/pf101-dashboard/goals.png';
import review from '../../../assets/img/pf101-dashboard/review.png';
import greenTick from '../../../assets/img/pf101-dashboard/circlearrow.png';

import beginner from '../../../assets/img/pf101-dashboard/pf101-rank-beginner.png';
import intermediate from '../../../assets/img/pf101-dashboard/pf101-icons-intermediate.png';
import expert from '../../../assets/img/pf101-dashboard/pf101-rank-expert.png';
import legend from '../../../assets/img/pf101-dashboard/pf101-rank-legend.png';
import master from '../../../assets/img/pf101-dashboard/pf101-rank-master.png';
import xpGraphic from '../../../assets/img/pf101-dashboard/xp-graphic.png';
import user from '../../../assets/img/user.png';
import checkMark from '../../../assets/img/pf101-dashboard/checkmark.png';
import graphOne from '../../../assets/img/pf101-dashboard/graph_1.png';
import graphThree from '../../../assets/img/pf101-dashboard/graph_3.png';
import challengeImg from '../../../assets/img/pf101-dashboard/pf101-challenge-baseline.png';
import univestLogo from '../../../assets/img/inkwiry-univest-rev.png';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import sheet1 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-1.pdf';
import sheet2 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-2.pdf';
import sheet3 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-3.pdf';
import sheet4 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-4.pdf';
import sheet5 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-5.pdf';
import sheet6 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-6.pdf';
import sheet7 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-7.pdf';
import sheet8 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-8.pdf';
import sheet9 from '../../../assets/img/pf101-dashboard/sheets/Inkwiry-PF101-Worksheet-Level-9.pdf';

const DASHBOARDDETAILS = loader('../../../graphql/schema/pf101/dashboard.graphql');
const MY_ACCOUNT = loader('../../../graphql/schema/auth/my-account.graphql');
const MY_DETAILS = loader('../../../graphql/schema/auth/me.graphql');
const SAVE_UNIVEST_COUNT = loader('../../../graphql/schema/ui/save-univest-count.graphql');

const Pf101Dashboard = (props) => {
  const { classes, history } = props;
  const tasksLevels = [1, 4, 2, 3, 3, 6, 5, 3, 2, 2, 1, 1, 1];

  useEffect(() => {
    document.body.classList.add('blue_background');
    return () => {
      document.body.classList.remove('blue_background');
    };
  }, []);

  const innerPages = {};
  innerPages['11'] = { pathname: '/pf101/level1/personal-finance-101', search: '?type=audio' };

  innerPages['21'] = { pathname: '/pf101/level2/3-types-of-income' };
  innerPages['22'] = { pathname: '/pf101/level2/income-taxes-101' };
  innerPages['23'] = { pathname: '/pf101/level2/federal-income-taxes', search: '?type=audio' };
  innerPages['24'] = { pathname: '/pf101/level2/filing-taxes' };
  innerPages['31'] = { pathname: '/pf101/level3/budgeting101' };
  innerPages['32'] = { pathname: '/pf101/level3/budgeting-guide' };
  innerPages['41'] = { pathname: '/pf101/level4/checking-savings' };
  innerPages['42'] = { pathname: '/pf101/level4/debit-credit' };
  innerPages['43'] = { pathname: '/pf101/level4/bank-responsibly', search: '?type=audio' };

  innerPages['51'] = { pathname: '/pf101/level5/online-banking' };
  innerPages['52'] = { pathname: '/pf101/level5/mobile-banking-apps', search: '?type=audio' };
  innerPages['53'] = { pathname: '/pf101/level5/safe-online-banking-tips', search: '?type=audio' };

  innerPages['61'] = { pathname: '/pf101/level6/credit-score-101' };
  innerPages['62'] = { pathname: '/pf101/level6/debunking-credit-score-myths' };
  innerPages['63'] = { pathname: '/pf101/level6/loan-101' };
  innerPages['64'] = { pathname: '/pf101/level6/student-loans' };
  innerPages['65'] = { pathname: '/pf101/level6/loan-repayment', search: '?type=audio' };
  innerPages['66'] = { pathname: '/pf101/level6/lower-cost-of-loan' };

  innerPages['71'] = { pathname: '/pf101/level7/investing-101' };
  innerPages['72'] = { pathname: '/pf101/level7/stocks-and-bonds', search: '?type=audio' };
  innerPages['73'] = { pathname: '/pf101/level7/funds-alternative-investments', search: '?type=audio' };
  innerPages['74'] = { pathname: '/pf101/level7/retirement-accounts' };
  innerPages['75'] = { pathname: '/pf101/level7/how-to-start-investing', search: '?type=audio' };

  innerPages['81'] = { pathname: '/pf101/level8/health-insurance', search: '?type=audio' };
  innerPages['82'] = { pathname: '/pf101/level8/life-insurance' };
  innerPages['83'] = { pathname: '/pf101/level8/asset-insurance' };

  innerPages['91'] = { pathname: '/pf101/level9/smart-goals' };
  innerPages['92'] = { pathname: '/pf101/level9/personal-finance-with-a-purpose' };

  innerPages['101'] = { pathname: '/pf101/level10/financial-life-toolkit' };
  innerPages['102'] = { pathname: '/pf101/level10/financial-life-toolkit' };

  const apolloClient = useApolloClient();

  const [viewNumber, setViewNumber] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [isSchool, setIsSchool] = React.useState(false);
  const [topFive, setTopFive] = React.useState([]);
  const [myRank, setMyRank] = React.useState(null);
  const [aliasName, setAliasName] = React.useState(null);
  const [aliasSave, setAliasSave] = React.useState('');
  // const [displayName, setDisplayName] = React.useState('');
  const [activity, setActivity] = React.useState({});
  const [openAccount, setOpenAccount] = React.useState(false);
  const [openLevel, setOpenLevel] = React.useState('');
  const [sideBox, SetSideBox] = React.useState(false);

  const { data: myDetails } = useQuery(MY_DETAILS);

  const [updateMyAccount] = useMutation(MY_ACCOUNT, {
    onCompleted() {
      setLoading(false);
      if (aliasSave === 1) {
        setViewNumber(3);
      } else {
        setAliasSave('');
      }
    },
    onError() {
      return false;
    },
  });

  const [saveUnivestCount] = useMutation(SAVE_UNIVEST_COUNT, {
    onCompleted() {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'https://www.univest.net/';
      hiddenElement.target = '_blank';
      hiddenElement.click();
    }
  });

  useQuery(DASHBOARDDETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setLoading(false);
      setIsSchool(response.pf101DashboardDetails.school);
      console.log(response.pf101DashboardDetails);
      setTopFive(response.pf101DashboardDetails.top_five);
      setMyRank(response.pf101DashboardDetails.my_rank);
      setAliasName(response.pf101DashboardDetails.alias_name);
      // setDisplayName(response.pf101DashboardDetails.name);
      if (response.pf101DashboardDetails.school === true) {
        if (response.pf101DashboardDetails.alias_name === '' || response.pf101DashboardDetails.alias_name === null || response.pf101DashboardDetails.alias_name === undefined) {
          setViewNumber(1);
        } else if (response.pf101DashboardDetails.challenge === false) {
          setViewNumber(3);
        } else {
          setViewNumber(4);
        }
      } else if ((response.pf101DashboardDetails.alias_name !== '' && response.pf101DashboardDetails.alias_name !== null && response.pf101DashboardDetails.alias_name !== undefined) || (response.pf101DashboardDetails.alias_status === 1)) {
        setViewNumber(4);
      } else {
        setViewNumber(1);
      }
      if (response.pf101DashboardDetails.activities.length > 0) {
        const updateActivity = {};
        let totalXPPoints = 0;
        response.pf101DashboardDetails.activities.forEach((data) => {
          if (updateActivity[data.level] !== undefined) {
            updateActivity[data.level][data.exercise] = {};
            updateActivity[data.level][data.exercise].status = true;
          } else {
            updateActivity[data.level] = {};
            updateActivity[data.level][data.exercise] = {};
            updateActivity[data.level][data.exercise].status = true;
          }
          updateActivity[data.level].start = true;
          updateActivity[data.level].per = 20;

          const totalTasks = tasksLevels[data.level - 1];
          const completedTasks = Object.keys(updateActivity[data.level]).length - 2;
          const percentage = (completedTasks / totalTasks) * 100;
          updateActivity[data.level].per = parseInt(percentage, 10);
          totalXPPoints += data.points;
        });
        updateActivity['Total Points'] = totalXPPoints;
        if (updateActivity['Total Points'] >= 0 && updateActivity['Total Points'] <= 1999) {
          updateActivity.Rank = 'Beginner';
          updateActivity['Next Rank'] = 'Intermediate';
          updateActivity['Needed XP'] = 2000;
          updateActivity.rank_img = beginner;
          updateActivity.next_rank_img = intermediate;
        } else if (updateActivity['Total Points'] >= 2000 && updateActivity['Total Points'] <= 3999) {
          updateActivity.Rank = 'Intermediate';
          updateActivity['Next Rank'] = 'Pro';
          updateActivity['Needed XP'] = 4000;
          updateActivity.rank_img = intermediate;
          updateActivity.next_rank_img = expert;
        } else if (updateActivity['Total Points'] >= 4000 && updateActivity['Total Points'] <= 6499) {
          updateActivity.Rank = 'Pro';
          updateActivity['Next Rank'] = 'Expert';
          updateActivity['Needed XP'] = 6500;
          updateActivity.rank_img = expert;
          updateActivity.next_rank_img = master;
        } else if (updateActivity['Total Points'] >= 6500 && updateActivity['Total Points'] <= 8999) {
          updateActivity.Rank = 'Expert';
          updateActivity['Next Rank'] = 'Legend';
          updateActivity['Needed XP'] = 9000;
          updateActivity.rank_img = master;
          updateActivity.next_rank_img = legend;
        } else {
          updateActivity.Rank = 'Legend';
          updateActivity['Next Rank'] = '';
          updateActivity.rank_img = legend;
          updateActivity.next_rank_img = '';
          updateActivity['Needed XP'] = '';
        }
        setActivity(updateActivity);
      } else {
        const updateActivity = {};
        updateActivity.Rank = 'Beginner';
        updateActivity.rank_img = beginner;
        updateActivity.next_rank_img = intermediate;
        updateActivity['Next Rank'] = 'Intermediate';
        updateActivity['Needed XP'] = 2500;
        updateActivity['Total Points'] = 0;
        setActivity(updateActivity);
      }
    },
    onError() {
      window.location.href = '/';
    },
  });

  const changeView = (value) => {
    if (isSchool === true) {
      setViewNumber(value);
      setAliasSave(1);
    } else {
      setViewNumber(4);
      updateMyAccount({
        variables: {
          data: {
            type: 'pf101_alias',
            alias_name: '',
            alias_status: 1,
          },
        },
      });
    }
  };

  const onSubmit = (values) => {
    setAliasName(values.alias_name);
    setLoading(true);
    updateMyAccount({
      variables: {
        data: {
          type: 'pf101_alias',
          alias_name: values.alias_name,
          alias_status: 1,
        },
      },
    });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.alias_name) {
      errors.alias_name = 'alias Name is required';
    }
    return errors;
  };

  const openLevels = (level) => {
    setOpenLevel(openLevel === level ? '' : level);
  };

  const goTONextLevel = (type) => {
    if (activity[type] !== undefined && activity[type] !== '') {
      for (let i = 1; i <= tasksLevels[type - 1]; i += 1) {
        if (!(activity[type][i] !== undefined && activity[type][i] !== '')) {
          history.push(innerPages[`${type}${i}`]);
          break;
        }
      }
    } else {
      history.push(innerPages[`${type}1`]);
    }
  };

  const logoOut = () => {
    localStorage.removeItem('app-token');
    apolloClient.resetStore();
    history.push('/');
  };

  const handleUnivest = () => {
    saveUnivestCount({
      variables: {
        school_id: myDetails && myDetails.me.institution_id
      },
    });
  }

  useScrollPosition(({ currPos }) => {
    console.log(currPos);
    if (currPos.y < -1790) {
      SetSideBox(true);
    } else {
      SetSideBox(false);
    }
  })

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.dashboardPage}>
        <div className={classes.containerFluid}>
          <div className={classes.container}>
            {myDetails && myDetails.me.institution_id !== null ?
              <span aria-hidden="true" className={classes.univestSpan}>
                <img src={univestLogo} alt="inkwiry-logo-univest" />
                <Link to='/' className={classes.inwiryLink}></Link>
                <span onClick={handleUnivest} target="_blank" className={classes.univestLink}></span>
              </span>
              :
              <div className={classes.dashboardLogo}>
                <Link to="/desktop">
                  <img src={logoImage} alt="logo" />
                </Link>
              </div>
            }
          </div>
          <div className={classes.container}>
            {viewNumber === 1 && (
              <Grid container>
                <Grid item xs={12} md={12}>
                  <div className={classes.welcomeScreen}>
                    <div className={classes.mainLogo}>
                      <img src={powerUpLogo} alt="logo" />
                    </div>
                    <div className={classes.introScreen1}>
                      <h3>Welcome to Personal Finance 101!</h3>
                      <p>
                        You&apos;re about to acquire essential personal finance skills
                        <br />
                        {' '}
                        and knowledge that you can
                        use right now and for the rest of your life.
                        <br />
                        {' '}
                        Let&apos;s get started!
                      </p>
                      <Button onClick={() => { changeView(2); }} className={classes.nextButton}>NEXT</Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {viewNumber === 2 && (
              <Grid container>
                <Grid item xs={12} md={12}>
                  <div className={classes.row}>
                    <div className={classes.welcomePageTwo}>
                      <div className={classes.mainLogo}>
                        <img src={powerUpLogo} alt="logo" />
                      </div>
                      <div className={classes.welcomeContent}>
                        <div className={classes.leftPadding}>
                          <div className={classes.titleOne}>
                            <p>
                              Just because we&apos;re serious about the importance of learning personal finance,
                              it doesn&apos;t mean we can&apos;t have some serious fun along the way!
                            </p>
                            <div className={classes.graphImage}>
                              <img src={graphOne} alt="logo" />
                            </div>
                            <div className={classes.graphSecond}>
                              <img src={graphOne} alt="logo" />
                            </div>
                          </div>
                          <div className={classes.titleTwo}>
                            <p>
                              Get familiar with our game interface, start racking up XP (experience points) and rise in the ranks. Before you know it, you might be a Legend in the ranks of Personal Finance 101.
                            </p>
                            <div className={classes.graphThree}>
                              <img src={graphThree} alt="logo" />
                            </div>
                          </div>
                          <p className={classes.inputUser}>Start by entering a special username or alias:</p>
                        </div>
                        <div className={classes.inputBlock}>
                          <Form
                            validate={validate}
                            onSubmit={onSubmit}
                            render={({ handleSubmit }) => (
                              <form
                                className={classes.formContact}
                                onSubmit={handleSubmit}
                                noValidate
                              >
                                <div className={classes.formGroup}>
                                  <div className={classes.leftInput}>
                                    <Field
                                      id="first_name"
                                      placeholder="Username"
                                      type="text"
                                      name="alias_name"
                                      margin="normal"
                                      variant="outlined"
                                      component={TextField}
                                    />
                                  </div>
                                </div>
                                <Button
                                  variant="contained"
                                  className={classes.nextButton}
                                  type="submit"
                                >
                                  NEXT
                                </Button>
                              </form>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {viewNumber === 3 && (
              <Grid container>
                <Grid item xs={12} md={12}>
                  <div className={classes.row}>
                    <div className={classes.welcomePageThree}>
                      <div className={classes.mainLogo}>
                        <img src={powerUpLogo} alt="logo" />
                      </div>
                      <h3 className={classes.screenTitle}>Test Your Money Skills</h3>
                      <div className={classes.certificateScreen}>
                        <div className={classes.challengeImg}>
                          <img src={challengeImg} alt="logo" />
                        </div>
                        <div>
                          <p>How well do you know money?</p>
                          <p>Put your knowledge to the test in the Baseline Challenge. And, no pressure because your answers won&apos;t count toward your overall score. Everything you see in the challenge will be covered in Personal Finance 101, so you&apos;re going to learn all of this and a lot more.</p>
                        </div>
                      </div>
                      <div className={classes.challengeBtn}>
                        <Link className={classes.nextButton} to="/pf101/baseline-challenge">START</Link>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {viewNumber === 4 && (
              <Grid container className={classes.containerFlex}>
                <Grid item xs={12} md={9} className={classes.leftSection}>
                  <div className={classes.leftBlock}>
                    <div className={classes.row}>
                      <div className={classes.powerUpLogo}>
                        <img src={powerUpLogo} alt="logo" />
                      </div>
                    </div>
                    <div className={classes.dashboardRow}>
                      <Grid item md={4} className={classes.budgetModule}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(1); }}>
                          <img src={finance} alt="logo" />
                          {activity[1] !== undefined && activity[1].per !== undefined && activity[1].per >= 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[1] !== undefined && activity[1].per !== undefined) && parseInt(activity[1].per) >= 100 ? 100 : 0} />
                            <span className={classNames(classes.progressValue, classes.progressMobile)}>
                              {(activity[1] !== undefined && activity[1].per !== undefined) ? activity[1].per === 100 || activity[1].per > 100 ? 100 : 0 : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>What is Personal Finance?</h3>
                        {openLevel === 1 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 1 -
                                {' '}
                                {(activity[1] !== undefined && activity[1].per !== undefined) ? activity[1].per === 100 || activity[1].per > 100 ? 100 : 0 : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[1] !== undefined && activity[1][1] !== undefined && activity[1][1].status !== undefined && activity[1][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[1] !== undefined && activity[1][1] !== undefined && activity[1][1].status !== undefined && activity[1][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level1/personal-finance-101', search: '?revisit=yes&type=audio' }}>What is Personal Finance?</Link>
                                  ) : (
                                    <span className={classes.incomeText}>What is Personal Finance?</span>
                                  )}
                                </li>
                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[1] !== undefined && activity[1].per !== undefined && activity[1].per >= 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(1)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet1} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </div>
                    <div className={classes.dashboardRow}>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[1] !== undefined && activity[1].per !== undefined && activity[1].per >= 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(2); }}>
                          <img src={incomeExpenses} alt="logo" />
                          {activity[2] !== undefined && activity[2].per !== undefined && activity[2].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[2] !== undefined && activity[2].per !== undefined) ? activity[2].per : 0} />
                            <span className={classes.progressValue}>
                              {(activity[2] !== undefined && activity[2].per !== undefined) ? activity[2].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Income + Taxes</h3>
                        {openLevel === 2 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 2 -
                                {' '}
                                {(activity[2] !== undefined && activity[2].per !== undefined) ? activity[2].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[2] !== undefined && activity[2][1] !== undefined && activity[2][1].status !== undefined && activity[2][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[2] !== undefined && activity[2][1] !== undefined && activity[2][1].status !== undefined && activity[2][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level2/3-types-of-income', search: '?revisit=yes' }}>3 Types of Income</Link>
                                  ) : (
                                    <span className={classes.incomeText}>3 Types of Income</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[2] !== undefined && activity[2][2] !== undefined && activity[2][2].status !== undefined && activity[2][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[2] !== undefined && activity[2][2] !== undefined && activity[2][2].status !== undefined && activity[2][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level2/income-taxes-101', search: '?revisit=yes' }}>Personal Income Taxes 101</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Personal Income Taxes 101</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[2] !== undefined && activity[2][3] !== undefined && activity[2][3].status !== undefined && activity[2][3].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[2] !== undefined && activity[2][3] !== undefined && activity[2][3].status !== undefined && activity[2][3].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level2/federal-income-taxes', search: '?revisit=yes&type=audio' }}>Federal Income Taxes</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Federal Income Taxes</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[2] !== undefined && activity[2][4] !== undefined && activity[2][4].status !== undefined && activity[2][4].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[2] !== undefined && activity[2][4] !== undefined && activity[2][4].status !== undefined && activity[2][4].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level2/filing-taxes', search: '?revisit=yes' }}>Filing Your Taxes</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Filing Your Taxes</span>
                                  )}
                                </li>

                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[2] !== undefined && activity[2].per !== undefined && activity[2].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(2)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet2} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[2] !== undefined && activity[2].per !== undefined && activity[2].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(3); }}>
                          <img src={budgetSaving} alt="logo" />
                          {activity[3] !== undefined && activity[3].per !== undefined && activity[3].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[3] !== undefined && activity[3].per !== undefined) ? activity[3].per : 0} />
                            <span className={classes.progressValue}>
                              {(activity[3] !== undefined && activity[3].per !== undefined) ? activity[3].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Budgeting + Saving</h3>
                        {openLevel === 3 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 3 -
                                {' '}
                                {(activity[3] !== undefined && activity[3].per !== undefined) ? activity[3].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[3] !== undefined && activity[3][1] !== undefined && activity[3][1].status !== undefined && activity[3][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[3] !== undefined && activity[3][1] !== undefined && activity[3][1].status !== undefined && activity[3][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level3/budgeting101', search: '?revisit=yes' }}>Budgeting 101</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Budgeting 101</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[3] !== undefined && activity[3][2] !== undefined && activity[3][2].status !== undefined && activity[3][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[3] !== undefined && activity[3][2] !== undefined && activity[3][2].status !== undefined && activity[3][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level3/budgeting-guide', search: '?revisit=yes' }}>Budgeting Guidelines</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Budgeting Guidelines</span>
                                  )}
                                </li>
                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[3] !== undefined && activity[3].per !== undefined && activity[3].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(3)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet3} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </div>
                    <div className={classNames(classes.dashboardRow, classes.challengeRow)}>
                      <Grid item md={6} className={classNames(classes.budgetModule, !(activity[3] !== undefined && activity[3].per !== undefined && activity[3].per === 100) && classes.inActiveModule)}>
                        <Link to="/pf101/challenge01">
                          <div className={classes.challengeModule}>
                            <img src={challenge} alt="logo" />
                            {activity[11] !== undefined && activity[11].per !== undefined && activity[11].per === 100 && (
                              <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                            )}
                          </div>
                        </Link>
                        <h3>Challenge 01</h3>
                      </Grid>
                    </div>
                    <div className={classes.dashboardRow}>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[11] !== undefined && activity[11].per !== undefined && activity[11].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(4); }}>
                          <img src={bankCards} alt="logo" />
                          {activity[4] !== undefined && activity[4].per !== undefined && activity[4].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[4] !== undefined && activity[4].per !== undefined) ? activity[4].per : 0} />
                            <span className={classNames(classes.progressValue, classes.progressMobile)}>
                              {(activity[4] !== undefined && activity[4].per !== undefined) ? activity[4].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Bank Accounts + Bank Cards</h3>
                        {openLevel === 4 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 4 -
                                {' '}
                                {(activity[4] !== undefined && activity[4].per !== undefined) ? activity[4].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[4] !== undefined && activity[4][1] !== undefined && activity[4][1].status !== undefined && activity[4][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[4] !== undefined && activity[4][1] !== undefined && activity[4][1].status !== undefined && activity[4][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level4/checking-savings', search: '?revisit=yes' }}>Checking v Savings Accounts</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Checking v Savings Accounts</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[4] !== undefined && activity[4][2] !== undefined && activity[4][2].status !== undefined && activity[4][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[4] !== undefined && activity[4][2] !== undefined && activity[4][2].status !== undefined && activity[4][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level4/debit-credit', search: '?revisit=yes' }}>Debit v Credit Cards</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Debit v Credit Cards</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[4] !== undefined && activity[4][3] !== undefined && activity[4][3].status !== undefined && activity[4][3].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[4] !== undefined && activity[4][3] !== undefined && activity[4][3].status !== undefined && activity[4][3].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level4/bank-responsibly', search: '?revisit=yes&type=audio' }}>7 Tips to Avoid Fees and Bank Responsibly</Link>
                                  ) : (
                                    <span className={classes.incomeText}>7 Tips to Avoid Fees and Bank Responsibly</span>
                                  )}
                                </li>
                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[4] !== undefined && activity[4].per !== undefined && activity[4].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(4)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet4} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </div>
                    <div className={classes.dashboardRow}>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[4] !== undefined && activity[4].per !== undefined && activity[4].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(5); }}>
                          <img src={mobileBanking} alt="logo" />
                          {activity[5] !== undefined && activity[5].per !== undefined && activity[5].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[5] !== undefined && activity[5].per !== undefined) ? activity[5].per : 0} />
                            <span className={classes.progressValue}>
                              {(activity[5] !== undefined && activity[5].per !== undefined) ? activity[5].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Online + Mobile Banking</h3>
                        {openLevel === 5 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 5 -
                                {' '}
                                {(activity[5] !== undefined && activity[5].per !== undefined) ? activity[5].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[5] !== undefined && activity[5][1] !== undefined && activity[5][1].status !== undefined && activity[5][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[5] !== undefined && activity[5][1] !== undefined && activity[5][1].status !== undefined && activity[5][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level5/online-banking', search: '?revisit=yes' }}>Online Banking</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Online Banking</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[5] !== undefined && activity[5][2] !== undefined && activity[5][2].status !== undefined && activity[5][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[5] !== undefined && activity[5][2] !== undefined && activity[5][2].status !== undefined && activity[5][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level5/mobile-banking-apps', search: '?revisit=yes&type=audio' }}>Mobile Banking</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Mobile Banking</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[5] !== undefined && activity[5][3] !== undefined && activity[5][3].status !== undefined && activity[5][3].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[5] !== undefined && activity[5][3] !== undefined && activity[5][3].status !== undefined && activity[5][3].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level5/safe-online-banking-tips', search: '?revisit=yes&type=audio' }}>Safe Online Banking Tips</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Safe Online Banking Tips</span>
                                  )}
                                </li>
                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[5] !== undefined && activity[5].per !== undefined && activity[5].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(5)}>GO</Button>
                                  </div>
                                )}

                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet5} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[5] !== undefined && activity[5].per !== undefined && activity[5].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(6); }}>
                          <img src={creditScore} alt="logo" />
                          {activity[6] !== undefined && activity[6].per !== undefined && activity[6].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[6] !== undefined && activity[6].per !== undefined) ? activity[6].per : 0} />
                            <span className={classes.progressValue}>
                              {(activity[6] !== undefined && activity[6].per !== undefined) ? activity[6].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Credit Score + Debt</h3>
                        {openLevel === 6 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 6 -
                                {' '}
                                {(activity[6] !== undefined && activity[6].per !== undefined) ? activity[6].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[6] !== undefined && activity[6][1] !== undefined && activity[6][1].status !== undefined && activity[6][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[6] !== undefined && activity[6][1] !== undefined && activity[6][1].status !== undefined && activity[6][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level6/credit-score-101', search: '?revisit=yes' }}>Credit Score 101</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Credit Score 101</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[6] !== undefined && activity[6][2] !== undefined && activity[6][2].status !== undefined && activity[6][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[6] !== undefined && activity[6][2] !== undefined && activity[6][2].status !== undefined && activity[6][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level6/debunking-credit-score-myths', search: '?revisit=yes' }}>Debunking Credit Score Myths</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Debunking Credit Score Myths</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[6] !== undefined && activity[6][3] !== undefined && activity[6][3].status !== undefined && activity[6][3].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[6] !== undefined && activity[6][3] !== undefined && activity[6][3].status !== undefined && activity[6][3].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level6/loan-101', search: '?revisit=yes' }}>Loans 101</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Loans 101</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[6] !== undefined && activity[6][4] !== undefined && activity[6][4].status !== undefined && activity[6][4].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[6] !== undefined && activity[6][4] !== undefined && activity[6][4].status !== undefined && activity[6][4].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level6/student-loans', search: '?revisit=yes' }}>Student Loans</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Student Loans</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[6] !== undefined && activity[6][5] !== undefined && activity[6][5].status !== undefined && activity[6][5].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[6] !== undefined && activity[6][5] !== undefined && activity[6][5].status !== undefined && activity[6][5].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level6/loan-repayment', search: '?revisit=yes&type=audio' }}>Loan Repayment</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Loan Repayment</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[6] !== undefined && activity[6][6] !== undefined && activity[6][6].status !== undefined && activity[6][6].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[6] !== undefined && activity[6][6] !== undefined && activity[6][6].status !== undefined && activity[6][6].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level6/lower-cost-of-loan', search: '?revisit=yes' }}>How to Lower the Cost of a Loan</Link>
                                  ) : (
                                    <span className={classes.incomeText}>How to Lower the Cost of a Loan</span>
                                  )}
                                </li>

                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[6] !== undefined && activity[6].per !== undefined && activity[6].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(6)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet6} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </div>
                    <div className={classNames(classes.dashboardRow, classes.challengeRow)}>
                      <Grid item md={6} className={classNames(classes.budgetModule, !(activity[6] !== undefined && activity[6].per !== undefined && activity[6].per === 100) && classes.inActiveModule)}>
                        <Link to="/pf101/challenge02">
                          <div className={classes.challengeModule}>
                            <img src={challenge} alt="logo" />
                            {activity[12] !== undefined && activity[12].per !== undefined && activity[12].per === 100 && (
                              <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                            )}
                          </div>
                        </Link>
                        <h3>Challenge 02</h3>
                      </Grid>
                    </div>
                    <div className={classes.dashboardRow}>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[12] !== undefined && activity[12].per !== undefined && activity[12].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(7); }}>
                          <img src={investing} alt="logo" />
                          {activity[7] !== undefined && activity[7].per !== undefined && activity[7].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[7] !== undefined && activity[7].per !== undefined) ? activity[7].per : 0} />
                            <span className={classNames(classes.progressValue, classes.progressMobile)}>
                              {(activity[7] !== undefined && activity[7].per !== undefined) ? activity[7].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Basics of Investing</h3>
                        {openLevel === 7 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 7 -
                                {' '}
                                {(activity[7] !== undefined && activity[7].per !== undefined) ? activity[7].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[7] !== undefined && activity[7][1] !== undefined && activity[7][1].status !== undefined && activity[7][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[7] !== undefined && activity[7][1] !== undefined && activity[7][1].status !== undefined && activity[7][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level7/investing-101', search: '?revisit=yes' }}>Investing 101</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Investing 101</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[7] !== undefined && activity[7][2] !== undefined && activity[7][2].status !== undefined && activity[7][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[7] !== undefined && activity[7][2] !== undefined && activity[7][2].status !== undefined && activity[7][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level7/stocks-and-bonds', search: '?revisit=yes&type=audio' }}>Stocks and Bonds</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Stocks and Bonds</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[7] !== undefined && activity[7][3] !== undefined && activity[7][3].status !== undefined && activity[7][3].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[7] !== undefined && activity[7][3] !== undefined && activity[7][3].status !== undefined && activity[7][3].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level7/funds-alternative-investments', search: '?revisit=yes&type=audio' }}>Funds and Alternative Investments</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Funds and Alternative Investments</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[7] !== undefined && activity[7][4] !== undefined && activity[7][4].status !== undefined && activity[7][4].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[7] !== undefined && activity[7][4] !== undefined && activity[7][4].status !== undefined && activity[7][4].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level7/retirement-accounts', search: '?revisit=yes' }}>Retirement Accounts</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Retirement Accounts</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[7] !== undefined && activity[7][5] !== undefined && activity[7][5].status !== undefined && activity[7][5].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[7] !== undefined && activity[7][5] !== undefined && activity[7][5].status !== undefined && activity[7][5].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level7/how-to-start-investing', search: '?revisit=yes&type=audio' }}>How to Start Investing</Link>
                                  ) : (
                                    <span className={classes.incomeText}>How to Start Investing</span>
                                  )}
                                </li>
                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[7] !== undefined && activity[7].per !== undefined && activity[7].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(7)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet7} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </div>
                    <div className={classes.dashboardRow}>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[7] !== undefined && activity[7].per !== undefined && activity[7].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(8); }}>
                          <img src={insurance} alt="logo" />
                          {activity[8] !== undefined && activity[8].per !== undefined && activity[8].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[8] !== undefined && activity[8].per !== undefined) ? activity[8].per : 0} />
                            <span className={classes.progressValue}>
                              {(activity[8] !== undefined && activity[8].per !== undefined) ? activity[8].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Basics of Insurance</h3>
                        {openLevel === 8 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 8 -
                                {' '}
                                {(activity[8] !== undefined && activity[8].per !== undefined) ? activity[8].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[8] !== undefined && activity[8][1] !== undefined && activity[8][1].status !== undefined && activity[8][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[8] !== undefined && activity[8][1] !== undefined && activity[8][1].status !== undefined && activity[8][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level8/health-insurance', search: '?revisit=yes&type=audio' }}>What is Health Insurance?</Link>
                                  ) : (
                                    <span className={classes.incomeText}>What is Health Insurance?</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[8] !== undefined && activity[8][2] !== undefined && activity[8][2].status !== undefined && activity[8][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[8] !== undefined && activity[8][2] !== undefined && activity[8][2].status !== undefined && activity[8][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level8/life-insurance', search: '?revisit=yes' }}>Life Insurance 101</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Life Insurance 101</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[8] !== undefined && activity[8][3] !== undefined && activity[8][3].status !== undefined && activity[8][3].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[8] !== undefined && activity[8][3] !== undefined && activity[8][3].status !== undefined && activity[8][3].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level8/asset-insurance', search: '?revisit=yes' }}>Asset Insurance</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Asset Insurance</span>
                                  )}
                                </li>
                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[8] !== undefined && activity[8].per !== undefined && activity[8].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(8)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet8} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[8] !== undefined && activity[8].per !== undefined && activity[8].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(9); }}>
                          <img src={goals} alt="logo" />
                          {activity[9] !== undefined && activity[9].per !== undefined && activity[9].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <div className={classes.progressBlock}>
                          <div className={classes.progressSec}>
                            <LinearProgress variant="determinate" value={(activity[9] !== undefined && activity[9].per !== undefined) ? activity[9].per : 0} />
                            <span className={classes.progressValue}>
                              {(activity[9] !== undefined && activity[9].per !== undefined) ? activity[9].per : 0}
                              %
                            </span>
                          </div>
                        </div>
                        <h3>Goals + Purpose</h3>
                        {openLevel === 9 && (
                          <div className={classes.incomeBlock}>
                            <div className={classNames(classes.incomePopup)}>
                              <h4>
                                Level 9 -
                                {' '}
                                {(activity[9] !== undefined && activity[9].per !== undefined) ? activity[9].per : 0}
                                % Complete
                              </h4>
                              <ul>
                                <li>
                                  <span className={activity[9] !== undefined && activity[9][1] !== undefined && activity[9][1].status !== undefined && activity[9][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[9] !== undefined && activity[9][1] !== undefined && activity[9][1].status !== undefined && activity[9][1].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level9/smart-goals', search: '?revisit=yes' }}>SMART Goals</Link>
                                  ) : (
                                    <span className={classes.incomeText}>SMART Goals</span>
                                  )}
                                </li>
                                <li>
                                  <span className={activity[9] !== undefined && activity[9][2] !== undefined && activity[9][2].status !== undefined && activity[9][2].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                  {activity[9] !== undefined && activity[9][2] !== undefined && activity[9][2].status !== undefined && activity[9][2].status === true ? (
                                    <Link className={classes.incomeText} to={{ pathname: '/pf101/level9/personal-finance-with-a-purpose', search: '?revisit=yes' }}>Personal Finance With a Purpose</Link>
                                  ) : (
                                    <span className={classes.incomeText}>Personal Finance With a Purpose</span>
                                  )}
                                </li>
                              </ul>
                              <div className={classes.popupButton}>
                                {!(activity[9] !== undefined && activity[9].per !== undefined && activity[9].per === 100) && (
                                  <div className={classes.goButton}>
                                    <Button onClick={() => goTONextLevel(9)}>GO</Button>
                                  </div>
                                )}
                                <div className={classes.downloadWorksheet}>
                                  <a href={sheet9} target="_blank" download rel="noopener noreferrer">DOWNLOAD WORKSHEET</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Grid>
                    </div>
                    <div className={classNames(classes.dashboardRow, classes.challengeRow)}>
                      <Grid item md={6} className={classNames(classes.budgetModule, !(activity[9] !== undefined && activity[9].per !== undefined && activity[9].per === 100) && classes.inActiveModule)}>
                        <Link to="/pf101/challenge03">
                          <div className={classes.challengeModule}>
                            <img src={challenge} alt="logo" />
                            {activity[13] !== undefined && activity[13].per !== undefined && activity[13].per === 100 && (
                              <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                            )}
                          </div>
                        </Link>
                        <h3>Challenge 03</h3>
                      </Grid>
                    </div>
                    <div className={classes.dashboardRow}>
                      <Grid item md={4} className={classNames(classes.budgetModule, !(activity[13] !== undefined && activity[13].per !== undefined && activity[13].per === 100) && classes.inActiveModule)}>
                        <div className={classes.financeModule} role="button" aria-hidden="true" onClick={() => { openLevels(10); }}>
                          <img src={review} alt="logo" />
                          {activity[10] !== undefined && activity[10].per !== undefined && activity[10].per === 100 && (
                            <span className={classes.taskComplete}><img src={greenTick} alt="logo" /></span>
                          )}
                        </div>
                        <h3>Wrapup + Review</h3>
                        {
                          openLevel === 10 && (
                            <div className={classes.incomeBlock}>
                              <div className={classNames(classes.incomePopup)}>
                                <h4>
                                  Level 10 -
                                  {' '}
                                  {(activity[10] !== undefined && activity[10].per !== undefined) ? activity[10].per : 0}
                                  % Complete
                                </h4>
                                <ul>
                                  <li>
                                    <span className={activity[10] !== undefined && activity[10][1] !== undefined && activity[10][1].status !== undefined && activity[10][1].status === true ? classes.greenCheckImg : classes.greenCheckImgVisibleNon}><img src={checkMark} alt="logo" /></span>
                                    {activity[10] !== undefined && activity[10][1] !== undefined && activity[10][1].status !== undefined && activity[10][1].status === true ? (
                                      <Link className={classes.incomeText} to={{ pathname: '/pf101/level10/financial-life-toolkit', search: '?revisit=yes' }}>Wrapup + Review</Link>
                                    ) : (
                                      <span className={classes.incomeText}>Wrapup + Review</span>
                                    )}
                                  </li>
                                </ul>
                                <div className={classes.popupButton}>
                                  {!(activity[10] !== undefined && activity[10].per !== undefined && activity[10].per === 100) && (
                                    <div className={classes.goButton}>
                                      <Button onClick={() => goTONextLevel(10)}>GO</Button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </Grid>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3} className={classes.sideBlock}>
                  {/* <div className={classes.sideBlockHead}>
                    <h3 className={classes.memberName}>
                      Hi
                      {' '}
                      {displayName}
                    </h3>
                    <div className={classes.bellIcon}>
                      <a href="/">
                        <i className="fa fa-bell-o" aria-hidden="true" />
                        <span className={classes.notificationCount}>10</span>
                      </a>
                      <ul className={classes.notificationDropDown}>
                        <li className={classes.notificationList}>
                          {' '}
                          Notifications
                          <span>See All</span>
                          <span>Clear All</span>
                        </li>
                        <li className={classes.notificationContent}>
                          <ul>
                            <li>
                              <div className={classes.notificationDate}> Wed, Nov 2019 </div>
                              <div className={classes.notificationText}>
                                <div className={classes.notificationMessage}>
                                  <div className={classes.notifyProfile}>
                                    <span><img src={user} alt="logo" /></span>
                                  </div>
                                  <div className={classes.notificationDesc}>
                                    <span>Congratulations on successfully completing Part 2 of the Villanova Inkwiry Program. You will have access to Inkwiry until the end of the year so feel free to leverage our Apps + Tools as you see fit. Thank you!</span>
                                    <span className={classes.notificationTime}>a year ago</span>
                                  </div>
                                </div>
                              </div>
                              <div className={classes.notificationText}>
                                <div className={classes.notificationMessage}>
                                  <div className={classes.notifyProfile}>
                                    <span><img src={user} alt="logo" /></span>
                                  </div>
                                  <div className={classes.notificationDesc}>
                                    <span>Congratulations on successfully completing Part 2 of the Villanova Inkwiry Program. You will have access to Inkwiry until the end of the year so feel free to leverage our Apps + Tools as you see fit. Thank you!</span>
                                    <span className={classes.notificationTime}>a year ago</span>
                                  </div>
                                </div>
                              </div>
                              <div className={classes.notificationText}>
                                <div className={classes.notificationMessage}>
                                  <div className={classes.notifyProfile}>
                                    <span><img src={user} alt="logo" /></span>
                                  </div>
                                  <div className={classes.notificationDesc}>
                                    <span>Congratulations on successfully completing Part 2 of the Villanova Inkwiry Program. You will have access to Inkwiry until the end of the year so feel free to leverage our Apps + Tools as you see fit. Thank you!</span>
                                    <span className={classes.notificationTime}>a year ago</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className={classes.notificationDate}> Wed, Nov 2019 </div>
                              <div className={classes.notificationText}>
                                <div className={classes.notificationMessage}>
                                  <div className={classes.notifyProfile}>
                                    <span><img src={user} alt="logo" /></span>
                                  </div>
                                  <div className={classes.notificationDesc}>
                                    <span>Appminds has sent you a new message </span>
                                    <span className={classes.notificationTime}>a year ago</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                
                  </div> */}
                  <div className={!sideBox ? classNames(classes.currentStats, classes.fixed) : classNames(classes.currentStats)}>
                    <div className={classes.panelHeader}>
                      <h3>Current Stats
                        <div className={classes.profileIcon}>
                          <div onClick={() => { setOpenAccount(!openAccount); }} role="button" aria-hidden="true" className={classes.profileMenu}>
                            <img src={user} alt="logo" />
                          </div>
                          {openAccount === true && (
                            <ul className={classes.profileDropdown}>
                              <li><Link to="/my-account">My Account</Link></li>
                              <li><span role="button" aria-hidden="true" onClick={() => { setAliasSave(2); setOpenAccount(false); }}>Change Alias</span></li>
                              <li><span role="button" aria-hidden="true" onClick={logoOut}>Logout</span></li>
                            </ul>
                          )}
                        </div>
                      </h3>
                    </div>
                    <div className={classes.panelBody}>
                      <div className={classes.wellBlock}>
                        <p className={classes.blockTitle}>Rank:</p>
                        <h3 className={classes.blockDesc}>
                          {activity.Rank}
                        </h3>
                        <div className={classes.xpRank}>
                          {activity.rank_img !== '' && (
                            <img src={activity.rank_img} alt="logo" />
                          )}
                        </div>
                      </div>
                      <div className={classes.wellBlock}>
                        <p className={classes.blockTitle}>Next Rank:</p>
                        <h3 className={classes.blockDesc}>
                          {activity['Next Rank']}
                        </h3>
                        <div className={classes.xpRank}>
                          {activity.next_rank_img !== '' && (
                            <img src={activity.next_rank_img} alt="logo" />
                          )}
                        </div>
                      </div>
                      <div className={classes.wellBlock}>
                        <p className={classes.blockTitle}>PowerUp XP:</p>
                        <h3 className={classes.blockDesc}>
                          <NumberFormat value={activity['Total Points']} displayType="text" thousandSeparator suffix=" XP" />
                        </h3>
                        <div className={classes.xpGraphic}>
                          <img src={xpGraphic} alt="logo" />
                        </div>
                      </div>
                      <div className={classes.wellBlock}>
                        <p className={classes.blockTitle}>XP Needed to Rank Up:</p>
                        <h3 className={classes.blockDesc}>
                          {activity['Needed XP'] !== '' && (
                            <NumberFormat value={activity['Needed XP']} displayType="text" thousandSeparator suffix=" XP" />
                          )}

                        </h3>
                        <div className={classes.xpGraphic}>
                          <img src={xpGraphic} alt="logo" />
                        </div>
                      </div>
                      {/* <div className={`${classes.wellBlock} ${classes.mb0}`}>
                        <p className={classes.blockTitle}>The Top Five:</p>
                        {(topFive && topFive.length > 0) && topFive.map((data, index) => (
                          <div className={classes.topFiveRow}>
                            <span className={classes.countOne}>{index + 1}</span>
                            <span className={classes.countTwo}>{(data.user.alias_name !== undefined && data.user.alias_name !== '' && data.user.alias_name !== null) ? data.user.alias_name : data.user.user_fname}</span>
                            <span className={classes.countThree}><NumberFormat value={data.total_points} displayType="text" thousandSeparator suffix=" XP" /></span>
                          </div>
                        ))}
                        <div className={classes.divedeLine}></div>
                        {myRank !== null && myRank !== '' && (
                        <div className={classes.topFiveRow}>
                          <span className={classes.countOne}>{myRank !== null && myRank !== '' ? myRank : ''}</span>
                          <span className={classes.countTwo}>{aliasName}</span>
                          <span className={classes.countThree}><NumberFormat value={activity['Total Points']} displayType="text" thousandSeparator suffix=" XP" /></span>
                        </div>
                        )}
                      </div> */}
                      {
                        isSchool === true && (
                          <div className={classes.wellBlock}>
                            <p className={classes.topTitle}>The Top Five:</p>
                            {(topFive && topFive.length > 0) && topFive.map((data, index) => (
                              <div className={classes.flexDisplay}>
                                <div className={classes.personNumber}>
                                  {index + 1}
                                  .
                                </div>
                                <div className={classes.personName}>{(data.user.alias_name !== undefined && data.user.alias_name !== '' && data.user.alias_name !== null) ? data.user.alias_name : data.user.user_fname}</div>
                                <div className={classes.personXp}><NumberFormat value={data.total_points} displayType="text" thousandSeparator suffix=" XP" /></div>
                              </div>
                            ))}
                            <hr className={classes.topDivider} />
                            <div className={classes.flexDisplay}>
                              <div className={classes.personNumber}>
                                {myRank !== null && myRank !== '' ? myRank : ''}
                                .
                              </div>
                              <div className={classes.personName}>{aliasName}</div>
                              <div className={classes.personXp}><NumberFormat value={activity['Total Points']} displayType="text" thousandSeparator suffix=" XP" /></div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
          <Dialog
            open={aliasSave === 2}
            onClose={() => { setAliasSave(''); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.aliasPopup}
          >
            <DialogTitle>
              Change Alias
              <span className={classes.closeAlias} role="button" aria-hidden="true" onClick={() => { setAliasSave(''); }}>×</span>
            </DialogTitle>
            <DialogContent>
              <div>
                <Form
                  validate={validate}
                  onSubmit={onSubmit}
                  initialValues={{ alias_name: aliasName }}
                  render={({ handleSubmit }) => (
                    <form
                      className={classes.formContact}
                      onSubmit={handleSubmit}
                      noValidate
                    >
                      <div className={classNames(classes.formGroup, classes.aliasInput)}>
                        <div className={classes.leftInput}>
                          <Field
                            id="first_name"
                            placeholder="Username"
                            type="text"
                            name="alias_name"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            component={TextField}
                          />
                        </div>
                      </div>
                      <Button
                        variant="contained"
                        className={classes.aliasBtn}
                        type="submit"
                      >
                        SAVE CHANGES
                      </Button>
                    </form>
                  )}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </Typography>
  );
};

Pf101Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Pf101Dashboard);
