import TickMarkBlack from '../../../assets/img/pwi-existing/tick_mark.png';
import tickMark from '../../../assets/img/pwi-home/black-tick-icon.png';
import TickMark from '../../../assets/img/pwi-rei/tick_mark_blue.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1140px',
    padding: '0 15px',
    margin: '0px auto',
  },
  fiHeader: {
    position: 'relative',
  },
  grayBox: {
    background: '#f5f5f5',
    padding: '30px',
    border: '1px solid #f0f0f0',
    borderTop: '1px solid #f0f0f0',
    marginTop: '10px',
    marginBottom: '30px',
    position: 'relative',
    '& p': {
      margin: '0',
    },
  },
  secondPercentageBarColor: {
    background: '#0069aa !important',
  },
  secondPercentageTextColor: {
    color: '#0069aa !important',
  },
  fiHeaderInner: {
    display: 'flex',
    marginTop: '25px',
    marginBottom: '0',
    padding: '10px 2px',
    alignItems: 'center',
    borderBottom: '4px solid #134376',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      margin: '0',
      fontFamily: 'MuseoSans-300',
      fontSize: '27px',
      fontWeight: 'normal',
      marginLeft: '29px',
      lineHeight: '1.1',
      color: theme.palette.common.black,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '24px',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      width: '100%',
      maxWidth: '500px',
      [theme.breakpoints.down( 'xs' )]: {
        marginBottom: '10px',
       },
      '& > img': {
        height: '50px',
        cursor: 'pointer',
        [theme.breakpoints.down( 'xs' )]: {
          height: '30px',
        },
      },
    },
    '& > img': {
      margin: '0 0 0 auto',
      maxWidth: '140px',
      height: '38px',
    },
  },
  menuIcon: {
    width: '30px',
    lineHeight: 'inherit',
    cursor: 'pointer',
    '& span': {
      height: '4px',
      width: '30px',
      display: 'block',
      background: '#0069aa',
      marginBottom: '4px',
      borderRadius: '5px',
    },
  },
  fiContent: {
    maxWidth: '950px',
    margin: '0 auto',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px',
    },
  },
  fiContentHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    position: 'relative',
    paddingBottom: '1px',
    '@media (max-width: 767px)': {
      marginTop: '5px',
    },
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '25px',
      color: '#0069aa',
    },
    '& i': {
      fontSize: '40px',
    },
    '& h3': {
      margin: '0 0 0 15px',
      fontWeight: 'normal',
      fontSize: '26px',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '19px',
        lineHeight: '19px',  
      },
    },
    '& figure': {
      margin: '0 0 0 auto',
      padding: '7px 10px',
      transition: 'all ease-in-out 0.3s',
      '&:hover': {
        background: '#333',
        borderRadius: '5px',
        cursor: 'pointer',
      },
      '& img': {
        maxWidth: '30px',
        height: 'auto',
        display: 'flex',
      },
    },

  },
  ProgressBar: {
    width: '100%',
    height: '5px',
    backgroundColor: '#d5d5d5',
    margin: '10px auto 0',
    position: 'relative',
  },
  ProgressBarInner: {
    width: '25%',
    backgroundColor: '#337ab7',
    height: '5px',
    position: 'relative',
    backgroundImage: 'linear-gradient(to right,#337ab7,#003b69)',
    '&::before': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '1.5px solid transparent',
      borderLeft: '2px solid #fbfdfd',
      content: "''",
      position: 'absolute',
    },
    '&::after': {
      width: '0',
      height: '0',
      borderTop: '2.5px solid transparent',
      borderBottom: '2.5px solid transparent',
      borderLeft: '2px solid #003b69',
      content: "''",
      position: 'absolute',
      right: '0',
      marginRight: '-2px',
    },
  },
  contentBlock: {
    fontSize: '21px',
    color: '#333',
    lineHeight: '35px',
    marginTop: '15px',
    '@media (max-width: 767px)': {
      fontSize: '16px',
    },
    '& p': {
      margin: '0 0 20px 0',
      lineHeight: '30px',
      color: '#333',
      '@media (max-width: 767px)': {
        lineHeight: '24px',
        margin: '0 0 10px 0',
      },
    },
    '& a': {
      color: '#337ab7',
      textDecoration: 'underline',
      '&:hover': {
        color: '#f89b23',
      },
    },
    '& i': {
      color: '#1674b1',
      display: 'block',
    },
    '& font': {
      color: '#1674b1',
    },
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  btnGroup: {
    display: 'flex',
    marginTop: '20px',
    paddingBottom: '20px',
    position: 'relative',
    '& button': {
      borderRadius: '3px',
      padding: '5px 20px',
      textTransform: 'uppercase',
      fontSize: '18px',
      [theme.breakpoints.down( 'xs' )]: {
        padding: '5px 15px',
        fontSize: '16px',
      },
    },
  },
  next: {
    backgroundColor: '#0069aa',
    border: '2px solid #0069aa',
    color: '#fff',
    margin: '0 0 0 auto',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#0069aa',
    },
  },
  cancel: {
    border: '2px solid #d5d5d5',
    '&:hover': {
      border: '2px solid #d5d5d5',
      backgroundColor: '#d5d5d5',
    },
  },
  potentialGrowth: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& h3': {
      textAlign: 'center',
      fontSize: '22px',
      marginTop: '0',
      lineHeight: '30px',
      color: theme.palette.common.black,
      marginBottom: '0',
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  blueLine: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
  },
  clearBtn: {
    margin: '0 15px',
    padding: '1px 6px !important',
    minWidth: 'auto',
    borderRadius: '0px !important',
    '&:hover': {
      background: '#0d97ec',
    },
    '& img': {
      maxWidth: '30px',
    },
  },
  tooltipInfoTop: {
    width: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    }
  },
  blueText: {
    color: '#0069aa',
  },
  fourIconsBlock: {
    '& ul': {
      padding: '0',
      listStyle: 'none',
      width: '57%',
      margin: '20px auto',
      '& li': {
        width: 'calc(50% - 56px)',
        display: 'inline-block',
        background: 'transparent',
        padding: '12px 10px 10px',
        textAlign: 'center',
        border: '3px solid #d5d5d5',
        borderRadius: '25px',
        margin: '8px 15px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-500',
        cursor: 'pointer',
        '& h4': {
          fontSize: '30px',
          fontWeight: 'normal',
          color: '#000',
          margin: '0 0 20px',

        },
        '& h2': {
          color: '#333',
          fontWeight: 'normal',
          fontSize: '25px',
          lineHeight: '30px',
        },
        '&:hover': {
          background: '#d5d5d5',
        },
        '&:first-child': {
          color: '#84a84d',
        },
        '&:nth-child(2)': {
          color: '#1f96e0',
        },
        '&:nth-child(3)': {
          color: '#0069aa',
        },
        '&:nth-child(4)': {
          color: '#0069aa',
        },
        '& figure': {
          margin: '0',
        },
      },
    },
  },
  twoIconsBlock: {
    '& ul': {
      padding: '0',
      listStyle: 'none',
      width: '57%',
      margin: '20px auto',
      '& li': {
        width: 'calc(50% - 80px)',
        display: 'inline-block',
        background: 'transparent',
        padding: '12px 10px 10px',
        textAlign: 'center',
        border: '3px solid #d5d5d5',
        borderRadius: '25px',
        margin: '8px 15px',
        fontSize: '18px',
        fontFamily: 'MuseoSans-500',
        cursor: 'pointer',
        '& h4': {
          fontSize: '30px',
          fontWeight: 'normal',
          color: '#000',
          margin: '0 0 20px',

        },
        '& h2': {
          color: '#333',
          fontWeight: 'normal',
          fontSize: '25px',
          lineHeight: '30px',
        },
        '&:hover': {
          background: '#d5d5d5',
        },
        '&:first-child': {
          color: '#84a84d',
        },
        '&:nth-child(2)': {
          color: '#1f96e0',
        },
        '&:nth-child(3)': {
          color: '#0069aa',
        },
        '&:nth-child(4)': {
          color: '#0069aa',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '120px',
            height: '111px',
          },
        },
      },
    },
  },
  marriageSection: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    padding: '30px 0px',
  },
  marriageYear: {
    maxWidth: '170px',
    minWidth: '170px',
    paddingRight: '20px',
    '& p': {
      fontSize: '20px',
      marginBottom: '0px',
    },
  },
  yearNumber: {
    padding: '0px 30px',
    '& span': {
      fontSize: '18px',
    },
  },
  federalStudent: {
    marginBottom: '5px',
    marginTop: '20px',
    fontSize: '19px',
    '& b': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
  },
  dottedText: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
    display: 'inline-block',
  },
  federalStudentLoans: {
    paddingLeft: '0px',
    listStyle: 'none',
    marginTop: '10px',
    '& li': {
      width: '100%',
      fontSize: '19px',
      textAlign: 'left',
      listStyle: 'none',
      backgroundImage: `url(${TickMarkBlack})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '11px',
      backgroundPosition: 'left top 8px',
      display: 'flex',
      paddingLeft: '35px',
      alignItems: 'center',
      paddingBottom: '13px',
      lineHeight: '1.42857143',
       '@media (max-width: 767px)': {
         fontSize: '16px',
         lineHeight: '24px',
       }
    },
  },
  studentAnalytics: {
    width: '75%',
    margin: '0 auto',
    padding: '50px 40px 26px',
    '& table': {
      borderSpcing: '0px',
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '100%',
      marginBottom: '20px',
      '& tr': {
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          borderTop: '1px solid #fff',
          fontSize: '20px',
          verticalAlign: 'middle',
          backgroundColor: '#f2f2f2',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& th': {
            textAlign: 'left',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  leftPadding: {
    '& td': {
      paddingLeft: '45px !important',
      '& i': {
        color: '#333',
      },
    },
  },
  creditSummary: {
    width: '60%',
    margin: '0 auto',
    padding: '50px 40px 26px',
    '& table': {
      borderSpcing: '0px',
      borderCollapse: 'collapse',
      width: '100%',
      maxWidth: '100%',
      marginBottom: '20px',
      '& tr': {
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          backgroundColor: '#dae3f3',
          borderBottom: '3px solid #fff',
          fontSize: '20px',
          textAlign: 'center',
          fontWeight: 'normal',
          fontFamily: 'MuseoSans-500',
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          borderTop: '1px solid #fff',
          fontSize: '20px',
          verticalAlign: 'middle',
          backgroundColor: '#f2f2f2',
          '&:last-child': {
            textAlign: 'right',
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& th': {
            textAlign: 'left',
            borderTop: '2px solid #5686da',
            borderBottom: 'none',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
    },
  },
  homeSummary: {
    display: 'flex',
    width: '100%',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  homeTable: {
    width: '50%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '15px 10px 0 0',
    '& table': {
      width: '100%',
    },
  },
  loanSummary: {
    width: '60%',
  },
  amountDollar: {
    textAlign: 'right',
    '& input': {
      border: 'none',
      borderBottom: '1px solid #5686da',
      fontSize: '20px',
      display: 'inline-block',
      width: '85px',
      textAlign: 'right',
      background: 'transparent',
      fontFamily: 'MuseoSans-300',
      outline: 'none',
    },
  },
  annualAppreciation: {
    display: 'flex',
  },
  annualGraph: {
    '& img': {
      width: '100%',
    },
  },
  annualInput: {
    '& input': {
      width: '80px',
      border: 'none',
      margin: '0 0 0 auto',
      outline: 'none',
      fontSize: '18px',
      textAlign: 'right',
      borderBottom: '1px solid #0069aa',
    },
  },
  annualTxt: {
    maxWidth: '200px',
    minWidth: '200px',
    paddingRight: '20px',
    fontSize: '20px',
    boxSizing: 'border-box',
  },
  homeValue: {
    '& img': {
      width: '100%',
    },
  },
  livingExpenses: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      right: '130px',
    },
  },
  expensesGroup: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
  },
  leftBlock: {
    width: '70%',
    '& h3': {
      marginBottom: '0',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
      textAlign: 'right',
    },
    '& b': {
      fontFamily: 'MuseoSans-500',
    },
  },
  rightBlock: {
    display: 'flex',
    margin: '0 0 0 auto',
    width: '30%',
    '& h3': {
      marginBottom: '10px',
      fontSize: '18px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      '&:last-child': {
        textALign: 'right',
      },
    },
    '& > div': {
      width: '50%',
      margin: '0 25px',
    },
  },
  expensesGrowth: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    marginTop: '10px',
    '& p': {
      margin: '0',
      maxWidth: '200px',
      minWidth: '200px',
      '& span': {
        borderBottom: '1px dashed #0069aa',
        fontSize: '20px',
        display: 'initial',
      },
    },
    '& input': {
      width: '80px',
      border: 'none',
      borderBottom: '1px solid #0069aa',
      textAlign: 'right',
      margin: '0 0 0 auto',
      outline: 'none',
      fontSize: '18px',
    },
  },
  expensesGrowthNew: {
    '& > div': {
      margin: '0',
      width: '100%',
    },
  },
  expensesGraphNew: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
    '& h3': {
      fontFamily: 'MuseoSans-500',
      width: '100%',
      textAlign: 'right',
      maxWidth: '100%',
      marginTop: '0',
    },
  },
  graphInputBlock: {
    display: 'flex',
    fontSize: '16px',
    justifyContent: 'flex-end',
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
      textAlign: 'right',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  loanBalance: {
    display: 'flex',
    position: 'relative',
  },
  loanText: {
    '& p': {
      minWidth: '200px',
      maxWidth: '200px',
      fontSize: '20px',
    },
  },
  loanInput: {
    position: 'relative',
    '& input': {
      width: '80px',
      border: 'none',
      borderBottom: '1px solid #0069aa',
      textAlign: 'right',
      margin: '0 0 0 auto',
      outline: 'none',
      fontSize: '18px',
    },
  },
  inputDollar: {
    lineHeight: '25px',
    position: 'relative',
    left: '0',
    fontSize: '18px',
    minWidth: '10px',
    display: 'inline-block',
  },
  inputPercent: {
    position: 'absolute',
    right: '0px',
    left: '100%',
  },
  principalValue: {
    display: 'flex',
  },
  principleText: {
    '& p': {
      width: '50px',
      minWidth: '50px',
      maxWidth: '50px',
    },
  },
  principleGraph: {
    textAlign: 'right',
    fontSize: '18px',
    width: '70%',
    paddingRight: '20px',
  },
  principleInput: {
    position: 'relative',
    '& p': {
      width: '80px',
      border: 'none',
      textAlign: 'right',
      margin: '0 0 0 auto',
      outline: 'none',
      fontSize: '18px',
    },
  },
  newInput: {
    position: 'absolute',
    right: '0',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '4px',
      height: '100%',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      left: '-7px',
    },
  },
  loanNumber: {
    width: '80px',
    border: 'none',
    textAlign: 'right',
    margin: '0 0 0 auto',
    outline: 'none',
    fontSize: '18px',
    display: 'inline-block',
  },
  principalIntrest: {
    textAlign: 'right',
    fontSize: '18px',
    width: '100%',
    paddingRight: '20px',
    maxWidth: '470px',
  },
  fixedMenu: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'none',
  },
  fixedMenuDisplay: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    }
  },
  fixedMenuDisplayMain: {
    left: '0',
    background: '#fff',
    zIndex: '9999',
    position: 'fixed',
    height: '100vh',
    overflow: 'hidden',
    boxShadow: '0 0 10px #e4dbdb',
    padding: '0.5vh .5vh 0 0',
    width: '50.3vh',
    top: '0',
    display: 'block',
    opacity: '1',
    transition: 'all 0.3s',
    boxSizing: 'border-box',
    '@media (max-width: 3500px)': {
      width: '38.38vh',
    },
    '@media (max-width: 1400px)': {
      width: '50.3vh',
    },
    '@media (max-width: 991px)': {
      width: '100%',
      maxWidth: '400px',
    },
  },
  hidden: {
    visibility: 'hidden',
  },
  fixedMenuHeader: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      width: '20px',
      display: 'flex',
      marginLeft: '8px',
      cursor: 'pointer',
    },
    '& i': {
      margin: '0 0 0 auto',
      fontSize: '22px',
      color: '#000',
      cursor: 'pointer',
      padding: '10px',
    },
  },
  powerUpLogo: {
    width: '30%',
    margin: '0 auto',
    '& img': {
      width: '100%',
    },
  },
  planWithInkwiry: {
    '& h1': {
      marginTop: '0',
      marginBottom: '10px',
      fontSize: '28px',
      textAlign: 'center',
      fontWeight: '300',
      color: '#0069aa',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      }
    },
  },
  powerUpCnt: {
    padding: '0 .5vh 0 4.9vh',
    '@media (max-width: 767px)': {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 185px)',
    },
    '& h4': {
      display: 'flex',
      alignItems: 'center',
      margin: '0',
      color: '#333',
      marginTop: '25px',
      marginBottom: '15px',
      fontWeight: '300',
      fontSize: '16px',
      '@media (max-width: 767px)': {
        fontSize: '14px',
        marginTop: '15px',
      },
      '& figure': {
        margin: '0 8px 0 0',
        '& img': {
          width: '30px',
          height: '30px',
          display: 'flex',
        },
      },
    },
    '& ul': {
      padding: '0.7vh 0 0vh 35px',
      listStyle: 'none',
      '& li': {
        padding: ' 0 0 .7vh',
        fontSize: '2.08vh',
        lineHeight: '3vh',
        display: 'flex',
        cursor: 'pointer',
        color: 'gray',
        flexWrap: 'wrap',
        '@media (max-width: 3500px)': {
          fontSize: '1.58vh',
          lineHeight: '2.3vh',
        },
        '@media (max-width: 1400px)': {
          fontSize: '2.08vh',
          lineHeight: '3vh',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
          fontSize: '12px',
          lineHeight: '16px',
        },
        '& ul': {
          width: '100%',
        },
        '&:hover': {
          color: '#eca041',
        },
        '& span': {
          marginRight: '10px',
        },
      },
    },
  },
  footerMenuFixed: {
    display: 'block',
    background: '#0069aa',
    position: 'absolute',
    width: 'calc(100% - 3vh)',
    bottom: '0',
    left: '0',
    padding: '1vh 1.5vh',
    textAlign: 'right',
    margin: '0',
    '& ul': {
      display: 'flex',
      padding: '0',
      margin: '0',
      justifyContent: 'center',
      '& li': {
        padding: '5px 6px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        verticalAlign: 'middle',
        position: 'relative',
        '&:hover': {
          background: '#0d97ec',
        },
        '& button': {
          padding: 0,
          margin: 0,
          minWidth: 'auto',
          '& span': {
            marginRight: 0,
            paddingLeft: 0,
          },
        },
        '& img': {
          width: '3.8vh',
          display: 'flex',
          verticalAlign: 'middle',
          '@media (max-width: 3500px)': {
            width: '2.6vh',
          },
          '@media (max-width: 1400px)': {
            width: '3.8vh',
          },
        },
      },
    },
  },
  AnnualIncomeGrowth: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      marginBottom: '30px',
      marginTop: '30px',
      '& p': {
        margin: '0',
        minWidth: '210px',
        maxWidth: '210px',
        '& span': {
          borderBottom: '1px dashed #0069aa',
          fontSize: '20px',
          display: 'initial',
        },
      },
      '& input': {
        width: '80px',
        border: 'none',
        borderBottom: '1px solid #0069aa',
        textAlign: 'right',
        margin: '0 0 0 auto',
        outline: 'none',
        fontSize: '18px',
      },
    },

  },
  middleAnnual: {
    width: 'calc(100% - 380px)',
    marginTop: '30px',
    '& img': {
      marginLeft: '25px',
      width: 'calc(100% - 25px)',
    },
  },
  fixedMenuDisplayMainNone: {
    display: 'none',
  },
  active: {
    background: '#d9d9d9 !important',
  },
  unactive: {
    background: '#f0f0f0 !important',
  },
  unactiveBox: {
    background: '#fffff',
  },
  MainMenuNew: {
    maxHeight: 'calc(100vh - 210px)',
    overflow: 'auto',
    marginTop: '20px',
    '& ul': {
      padding: '0 26px',
      listStyle: 'none',
      margin: '0',
      '&  div': {
        '& li': {
          padding: '0 10px',
        },
      },
      '& li': {
        marginBottom: '10px',
        '& a': {
          display: 'flex',
          alignItems: 'center',
          color: '#333',
          cursor: 'pointer',
          transiction: 'all 0.3s',
        },
        '& span': {
          paddingLeft: '10px',
          display: 'inline-block',
          width: '100%',
        },
        '& figure': {
          margin: '0',
          '& img': {
            width: '30px',
            height: '30px',
            display: 'flex',
          },
        },
        '&:hover': {
          '& a': {
            color: 'orange',
            textDecoration: 'none',
          },
        },
      },
    },
  },
  menuBoxNew: {
    background: '#f2f6ed',
    padding: '0 0 2px',
    borderRadius: '15px',
    margin: '5px 0',
    '& h5': {
      background: '#84a84d',
      borderRadius: '15px',
      color: '#fff',
      padding: '5px 10px',
      margin: '0 0 5px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      fontSize: '14px',
    },

  },
  menuBoxNewBg: {
    background: '#e5f0f6',
  },
  annuvalIncomeGrowthGraph: {
    width: '50%',
    margin: '0 25px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
    },
  },
  annuvalIncomeGraph: {
    width: '100%',
    margin: '0 25px',
    '& span': {
      marginBottom: '0',
      display: 'inline-flex',
    },
  },
  mainBlock: {
    width: '100%',
  },
  annuvalIncomeGrowthInput: {
    width: '100px',
    margin: '0 25px 0 auto',
    display: 'flex',

    '& > div': {
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      borderRadius: '6px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      margin: '17px',
      border: '1px solid rgba(0,0,0,.2)',
    },
    '& > div > div > div': {
      padding: '0px !important',
    }
  },
popupClose: {
    maxWidth: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)',
    borderRadius: '0px',
    borderBottom: '1px solid #e5e5e5',
    textAlign: 'right',
    padding: '8px 15px',
    '& span': {
      fontSize: '20px',
      width: '28px',
      display: 'inline-block',
      border: '1px solid',
      paddingBottom: '2px',
      textAlign: 'center',
      textShadow: '0 1px 0 #fff',
      color: '#000',
      opacity: '0.2',
      lineHeight: '22px',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.5',
      }
    },
  },
  resetIcon: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
    width: '34px',
    cursor: 'pointer',
    '& img': {
      maxWidth: '20px',
      cursor: 'pointer',
      width: '100%',
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: '#8db2f3',
    },
  },
  tickList: {
    paddingLeft: '0px',
    marginTop: '0px',
    marginBottom: '20px',
    '& li': {
      listStyle: 'none',
      background: `url(${tickMark}) no-repeat left 8px`,
      backgroundSize: '16px',
      paddingLeft: '30px',
      boxSizing: 'border-box',
      lineHeight: '1.42857143',
      marginBottom: '10px',
    },
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  flexDisplay: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingTop: '25px',
  },
  sliderName: {
    width: '16.66666667%',
    paddingRight: '15px',
    paddingLeft: '15px',
    boxSizing: 'border-box',
    '& p': {
      height: '60px',
      display: 'flex',
      padding: '0px 0 0',
      alignItems: 'center',
      fontSize: '19px',
      marginBottom: '0px',
    },
  },
  sliderNoSpace: {
    width: '100%',
    height: '60px',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingTop: '25px',
  },
  sliderRow: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
  },
  pwisliderInputs: {
    width: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    '& > span': {
      margin: '0',
    },
    '& p': {
      fontSize: '18px !important',
      textAlign: 'right',
      width: '100%',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
      },
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
    },
  },
  sliderValues: {
    // display: 'inline-table',
    marginTop: '0',
    fontSize: '18px',
    textAlign: 'right',
    paddingTop: '0px',
    width: '15%',
    '& > div': {
      margin: '0 0px 0px 25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '10px',
      position: 'relative',
    },
    '& input': {
      float: 'right',
      fontSize: '18px',
      width: '100%',
      textAlign: 'right',
      background: '0 0',
      padding: '2px 2px',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '17px',
      },
      '&:disabled': {
        background: '#ebebe4',
        cursor: 'not-allowed',
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
    '& div': {
      '&:before': {
        border: 'none !important',
        outline: 'none',
      },
      '&:after': {
        border: 'none',
        outline: 'none',
      },
    },
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 10px',
      cursor: 'pointer',
      alignItems: 'center',
      marginTop: '0',
      position: 'relative',
      fontSize: '18px',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
    },
  },
  boldValues: {
    '& > div': {
      paddingBottom: '0px',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      '& b': {
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
      // '& span': {
      //   width: '95px',
      // },
    },
  },
  noBottomMargin: {
    marginBottom: '0px !important',
  },
  topPadding: {
    paddingTop: '30px',
  },
  fontSmall: {
    fontSize: '19px !important',
  },
  buyhomeImg: {
    '& img': {
      display: 'flex',
      margin: '0 auto',
      marginTop: '-23px',
    },
  },
  dollarSymbol: {
    marginLeft: '2%',
    position: 'relative',
    left: '0',
    fontSize: '18px',
  },
  percentSymbol: {
    position: 'absolute',
    fontSize: '18px',
    right: '-10px',  
    '@media (max-width: 430px)': {     
      right: '-6px',
    },
  },
  pricePercent: {
    position: 'absolute',
    fontSize: '18px',
  },
  resetPicture: {
    cursor: 'pointer',
    position: 'absolute',
    padding: '7px',
    width: 'auto',
    '& img': {
      maxWidth: '20px',
      cursor: 'pointer',
      width: '100%',
      verticalAlign: 'middle',
      display: 'block',
    },
    '&:hover': {
      background: '#8db2f3',
    },
  },
  sliderPadding: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  tableRows: {
    display: 'flex',
    '& table': {
      textAlign: 'right',
      marginTop: '30px',
    },
  },
  leftTable: {
    width: '40%',
    marginRight: '3%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      marginRight: '0',
    },
  },
  tableRow: {
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down( 'sm' )]: {
      flexWrap: 'wrap',
    },
  },
  rightTable: {
    width: '57%',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
      overflow: 'auto',
    },
  },
  cashTables: {
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    width: '100%',
    whiteSpace: 'nowrap',
    marginTop: '30px',
    '& thead': {
      '& tr': {
        '& th': {
          background: '#dae3f3',
          borderBottom: '2px solid #fff',
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
          fontSize: '19px',
          padding: '7px 10px',
          textAlign: 'right',
          lineHeight: '1.42857143',
          '@media (max-width: 767px)': { 
            fontSize: '16px',
            padding: '7px 4px',
          },
          '&:nth-child(2)': {
            textAlign: 'left',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          '&:first-child': {
            width: '20px',
          },
          fontSize: '19px',
          padding: '7px 10px',
          background: '#f2f2f2',
          borderBottom: '1px solid #fff',
          lineHeight: '1.42857143',
          textAlign: 'right',
          whiteSpace: 'nowrap',
          '@media (max-width: 767px)': {
            fontSize: '16px',
            padding: '7px 4px',
          },
          '&:nth-child(2)': {
            textAlign: 'left',
          },
          '& i': {
            color: '#333',
          },
          '& img': {
            cursor: 'pointer',
            verticalAlign: 'middle',
            maxWidth: '20px',
            display: 'flex',
          },
          '& input': {
            border: '0',
            boxShadow: 'none',
            borderBottom: '1px solid #0069aa',
            background: '0 0',
            width: '75px',
            textAlign: 'right',
            fontFamily: 'inherit',
            fontSize: '19px',
            lineHeight: '1.42857143',
          },
          '& span': {
            display: 'inline-block',
          },
        },
      },
    },
    '& tfoot': {
      '& tr': {
        '& th': {
          background: '#dae3f3',
          borderBottom: '1px solid #fff',
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
          fontSize: '19px',
          padding: '7px 10px',
          textAlign: 'right',
          lineHeight: '1.42857143',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '14px',
            padding: '7px 4px',
          },
          '&:nth-child(2)': {
            textAlign: 'left',
          },
          '& i': {
            color: '#333',
          },
        },
      },
    },
  },
  dashedBorder: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
  },
  blueBottomBorder: {
    '& th': {
      borderBottom: '2px solid #0069aa !important',
    },
    '& td': {
      borderBottom: '2px solid #0069aa !important',
    },
  },
  boldText: {
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
  },
  infoTooltip: {
    maxWidth: '200px',
    padding: '3px 8px !important',
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: '#0069aa !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px !important',
    boxSizing: 'border-box',
    textALign: 'left',
    width: 'auto',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
  },
  definitionTooltips: {
    maxWidth: '200px',
    padding: '3px 8px',
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: '#0069aa !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'left',
    width: 'auto',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    },
  },
  tooltipInfoTopSelling: {
    lineHeight: '1.42857143',
  },
  cmaCalculator: {
    marginBottom: '25px',
    marginTop: '25px',
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    },
    '& table': {
      width: '100%',
      maxWidth: '100%',
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#333',
      '& tbody': {
        '& tr': {
          '&:nth-child(odd)': {
            backgroundColor: 'transparent',
          },
          '&:nth-child(even)': {
            backgroundColor: '#f2f2f2',
          },
          '&:first-child': {
            '& td': {
              borderTop: 'none',
            },
          },
          '& td': {
            borderTop: '1px solid #ddd',
            textAlign: 'left',
            verticalAlign: 'middle',
            height: '38px',
            padding: '0 8px',
            lineHeight: '1.2',
            position: 'relative',
            '@media (max-width: 1199px)': { 
              minWidth: '100px',
            },
            '&:nth-child(1)': {
              '@media (max-width: 1199px)': { 
                minWidth: 'initial',
              }
            },
            '&:nth-child(2)': {
              '@media (max-width: 1199px)': { 
                minWidth: '200px',
              },
            },
            '& input': {
              fontSize: '14px',
              lineHeight: '1.42857143',
              color: '#555',
              display: 'block',
              width: '100%',
              height: '34px',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '4px',
              WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
              boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
              WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
              OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
              transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
              padding: '6px 12px',
              backgroundImage: 'none',
              boxSizing: 'border-box',
              outline: 'none',
              fontFamily: 'MuseoSans-300',
              '&:focus': {
                borderColor: '#66afe9',
                outline: '0',
                WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
                boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
              },
            },
            '& select': {
              fontSize: '14px',
              lineHeight: '1.42857143',
              color: '#555',
              display: 'block',
              width: '100%',
              height: '34px',
              backgroundColor: '#fff',
              border: '1px solid #ccc',
              borderRadius: '4px',
              WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
              boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
              WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
              OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
              transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
              padding: '6px 12px',
              backgroundImage: 'none',
              boxSizing: 'border-box',
              outline: 'none',
              paddingLeft: '8px',
              cursor: 'pointer',
              fontFamily: 'MuseoSans-300',
              '&:focus': {
                borderColor: '#66afe9',
                outline: '0',
                WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
                boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
              },
            },
            '& img': {
              cursor: 'pointer',
              verticalAlign: 'middle',
              maxWidth: '20px',
              display: 'flex',
            },
          },
        },
      },
    },
  },
  blueBgrow: {

    '& td': {
      backgroundColor: '#ddebf7',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  dollarSym: {
    position: 'absolute',
    display: 'inline-flex',
    alignItems: 'center',
    height: '35px',
    marginLeft: '5px',
    zIndex: '9999',
  },
  inputDiv: {
    width: '100%',
    display: 'inline-block',
    '& div': {
      width: '100%',
      display: 'inline-block',
      '&:before': {
        border: 'none',
        outline: 'none',
      },
      '&:after': {
        border: 'none',
        outline: 'none',
      },
      '&:hover': {
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
  },
  clearIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
  },
  whitebgRow: {
    '& td': {
      backgroundColor: '#fff !important',
    },
  },
  flexInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  sliderNameNew: {
    minWidth: '170px',
    boxSizing: 'border-box',
    lineHeight: '1.42857143',
    '& p': {
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px',
      paddingRight: '20px',
      marginBottom: '0px',
    },
  },
  flexDisplayNew: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
    '@media (max-width: 1199px)': {
      marginBottom: '35px',
    }
  },
  flexDisplayNewFull:{
    // [theme.breakpoints.down( 'xs' )]: {
    //   display: 'block',
    //   margin: '0px 20px',
    //   width: 'calc(100% - 40px)',
    //   paddingBottom: '30px',
    // }
  },
  sliderFlex: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '85%',
    boxSizing: 'border-box',
    // [theme.breakpoints.down( 'xs' )]: {
    //   width: '100%',
    // },
    '& > div': {
      display: 'flex',
      alignItems: 'inherit',
      width: '98%',
      marginRight: '2%',
      position: 'relative',
      '& p': {
        minWidth: '170px',
        boxSizing: 'border-box',
        fontSize: '20px',
        [theme.breakpoints.down( 'xs' )]: {
         fontSize: '17px !important',
        },
        '& span': {
          margin: '0',
        },
      },
    },
  },
  noTopPadding: {
    paddingTop: '0px',
  },
  summaryWorksheet: {
    padding: '6px 13px',
    borderRadius: '4px',
    marginBottom: '19px',
    borderWidth: '2px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    userSelect: 'none',
    border: '1px solid transparent',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
      outline: 'none',
    },
  },
  summaryBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '0px',
    cursor: 'pointer',
  },
  worksheetButton: {
    padding: '6px 13px',
    borderRadius: '4px',
    marginBottom: '19px',
    borderWidth: '2px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    userSelect: 'none',
    border: '1px solid transparent',
    display: 'inline-block',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    '&:hover': {
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
    },
  },
  worksheetButton1: {
    marginTop: '10px',
  },
  noSlider: {
    display: 'block',
    width: '100%',
    height: '60px',
  },
  reiData: {
    paddingTop: '29px',
    display: 'flex',
  },
  reiLessSpace: {
    paddingTop: '10px',
  },
  repaySlide: {
    height: '30px',
    display: 'block',
    width: '100%',
  },
  repayData: {
    paddingTop: '10px',
    display: 'flex',
  },
  yearSymbol: {
    right: '-18px',
    position: 'absolute',
    fontFamily: 'MuseoSans-300',
    fontWeight: 'normal',
  },
  sliderDesc: {
    width: '83%',
    display: 'flex',
    fontSize: '18px',
    justifyContent: 'flex-end',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  sliderData: {
    float: 'right',
    width: '15%',
    border: '0',
    outline: '0',
    background: '0 0',
    // marginLeft: '20px',
    textAlign: 'right',
    fontSize: '18px',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
  },
  termLoan: {
    display: 'block',
    justifyContent: 'flex-end',
    textAlign: 'right',
    width: '100%',
    fontFamily: 'MuseoSans-300',
    paddingRight: '7px',
  },
  sliderChart: {
    width: '25%',
    display: 'inherit',
    padding: '0 15px',
  },
  utilitiesChart: {
    tableLayout: 'fixed',
    width: '100%',
    height: '415px',
    margin: '54px auto 0',
    verticalAlign: 'bottom',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {     
      paddingLeft: '0px',      
    },
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '200px',
      paddingLeft: '0px',
      marginTop: '100px',
    },
  },
  chartBar: {
    position: 'relative',
    display: 'table-cell',
    verticalAlign: 'bottom',
    height: 'inherit',
    borderBottom: '1px solid #ccc',
    width: '50%',
  },
  chartText: {
    position: 'relative',
    top: '0',
    textAlign: 'center',
    fontSize: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    lineHeight: '1.4',
  },
  chartSpan: {
    color: '#a6a6a6',
    fontWeight: '500',
    fontSize: '16px',
  },
  flexRow: {
    display: 'flex',
  },
  mortgageFlexRow: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  mortgagereiRowScroll:{
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    }
  },
  mortgagereiRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '50px',
    '@media (max-width: 1199px)': { 
      minWidth: 'calc(950px - 50px)',
      flexWrap: 'initial',
      padding: '0px 25px',
    }
  },
  percentageBar: {
    margin: '0 16px',
    display: 'block',
    background: '#a6a6a6',
    animation: 'draw 1s ease-in-out',
    height: '100%',
    '&:before': {
      position: 'absolute',
      left: '0',
      right: '0',
      top: '0',
      padding: '5px 1em 0',
      display: 'block',
      textAlign: 'center',
      content: 'attr(title)',
    },
  },
  sliderRightSpace: {
    paddingRight: '30px',
    margin: '0 0px 0px 25px',
    '& > div': {
      marginLeft: '0px',
    },
  },
  graphPercentage: {
    position: 'absolute',
    width: '100%',
    justifyContent: 'center',
    color: '#a6a6a6',
    paddingTop: '3px',
    fontSize: '14px',
    textAlign: 'center',
    fontWeight: 700,
  },
  sliderLeftBlock: {
    width: '75%',
    padding: '0  15px',
    margin: '0 -15px',
  },
  reiLeftBlock: {
    width: '75%',
    padding: '0px 15px 0px 0px',
  },
  sliderSection: {
    width: '100%',
  },
  borderDivider: {
    width: '100%',
    height: '3px',
    backgroundImage: 'linear-gradient(to right,#7f7f7f 50%,transparent 50%)',
    backgroundSize: '30px 100%',
    marginTop: '50px',
    marginBottom: '50px',
  },
  worksheetTables: {
    display: 'flex',
    margin: '0 -15px',    
  },
  yearTable: {
    width: '100%',
    marginBottom: '30px',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    '& tr': {
      '& th': {
        backgroundColor: '#dae3f3',
        borderBottom: '3px solid #fff',
        fontSize: '17px',
        textAlign: 'left',
        padding: '8px',
        lineHeight: '1.42857143',
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
        '@media (max-width: 767px)': { 
          fontSize: '16px',
        },
        '&:last-child': {
          textAlign: 'right',
        },
        '& select': {
          color: '#337ab7',
          textAlign: 'center',
          padding: '0 20px',
          height: '29px',
          outline: '0',
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          backgroundColor: '#f2f2f2',
          borderTop: '1px solid #fff',
          fontSize: '17px',
          padding: '8px',
          lineHeight: '1.42857143',
          '@media (max-width: 767px)': { 
            fontSize: '16px',
          },
          '&:last-child': {
            textAlign: 'right',
          },
          '& i': {
            color: '#333',
          },
          '& p': {
            marginBottom: '0px',
          },
        },
      },
    },
  },
  worksheetTable: {
    width: '58.33333333%',
    padding: '0 15px',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',      
    },
  },
  grayData: {
    background: 'gray !important',
    color: '#fff',
  },
  redData: {
    background: 'red !important',
    color: '#fff',
  },
  greenData: {
    background: 'green !important',
    color: '#fff',
  },
  leftSpace: {
    paddingLeft: '20px',
  },
  redText: {
    color: '#f00',
  },
  hoverRow: {
    '&:hover': {
      '& td': {
        backgroundColor: '#d5d5d5',
        cursor: 'pointer',
      },
    },
  },
  expensesTable: {
    width: '41.66666667%',
    padding: '0 15px',
    [theme.breakpoints.down( 'sm' )]: {
      width: '100%',
    },
  },
  worksheetRow: {
    display: 'flex',
    margin: '0 -15px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
  },
  topMargin: {
    marginTop: '30px',
  },
  loanPoints: {
    background: '#f2f2f2',
    border: '1px solid #000',
    width: '60%',
    padding: '20px 20px 5px',
    marginTop: '30px',
    marginBottom: '20px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      overflow: 'auto',
    },
    '& table': {
      borderSpacing: '0px',
      borderCollapse: 'collapse',
      tableLayout: 'auto',
      width: '100%',
      maxWidth: '100%',
      marginBottom: '20px',
      boxSizing: 'border-box',
      '& thead': {
        '& tr': {
          '& td': {
            fontSize: '19px',
            lineHeight: '1.42857143',
            verticalAlign: 'top',
            textAlign: 'center',
            padding: '4px',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '15px',
            },
            '&:nth-child(1)': {
              width: '35%',
              textAlign: 'left',
              whiteSpace: 'nowrap',
            },
            '&:nth-child(2)': {
              textAlign: 'center',
              justifyContent: 'center',
              width: '100%',
            },
            '&:nth-child(3)': {
              width: '20%',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          '& td': {
            borderTop: '2px solid #ccc',
            verticalAlign: 'middle',
            fontSize: '16px',
            padding: '8px',
            lineHeight: '1.42857143',
            textAlign: 'center',
            [theme.breakpoints.down( 'xs' )]: {
              fontSize: '15px',
            },
            '&:first-child': {
              fontFamily: 'MuseoSans-500',
              textAlign: 'left',
              fontWeight: '500',
            },
            '&:nth-child(2)': {
              textAlign: 'center',
              justifyContent: 'center',
            },
          },
          '&:first-child': {
            '& td': {
              borderTop: '0px',
              whiteSpace: 'nowrap',
            },
          },
          '&:nth-child(2)': {
            '& td': {
              borderTop: '0px',
            },
          },
          '&:last-child': {
            '& td': {
              borderBottom: '2px solid #ccc',
            },
          },
        },
      },
    },
  },
  tdBottomBorder: {
    borderBottom: '2px solid #000',
  },
  dottedBorder: {
    borderBottom: '1px dashed #0069aa',
    cursor: 'pointer',
  },
  dollarInput: {
    textAlign: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    '& input': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      width: '100px',
      height: '34px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      lineHeight: '1.42857143',
      color: '#555',
      // display: 'block',
      padding: '6px 12px',
      backgroundImage: 'none',
      boxSizing: 'border-box',
      fontSize: '19px',
    },
    '& div': {
      '&:before': {
        border: 'none',
        outline: 'none',
      },
      '&:after': {
        border: 'none',
        outline: 'none',
      },
      '&:hover': {
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
  },
  percentSymbl: {
    position: 'relative',
    right: '20px',
    left: 'auto',
    top: '4px',
  },
  resetImage: {
    display: 'flex',
    alignItems: 'center',
    padding: '7px',
    width: '34px',
    cursor: 'pointer',
    position: 'absolute',
    left: 'auto',
    boxSizing: 'border-box',
    right: '-50px',
    '& img': {
      maxWidth: '20px',
      cursor: 'pointer',
      width: '100%',
      verticalAlign: 'middle',
    },
    '&:hover': {
      background: '#8db2f3',
    },
  },
  annualRow: {
    display: 'flex',
    alignItems: 'center',
  },
  vacancyRowScroll:{
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    }
  },
  vacancyRow: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '30px',
    '@media (max-width: 1199px)': { 
      minWidth: 'calc(950px - 50px)',
      padding: '0px 25px',
    }
  },
  homeMainRowScroll:{
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    }
  },
  homeMainRow: {
    display: 'flex',
    alignItems: 'flex-end',
    '@media (max-width: 1199px)': { 
      minWidth: 'calc(950px - 50px)',
      padding: '0px 25px',
    }
  },
  leftSection: {
    width: '75%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  pmileftSection: {
    width: '75%',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0px 15px',
    position: 'relative',
    margin: '0 -15px',
    '&:before': {
      left: 'auto',
      right: '135px',
      width: '4px',
      height: '100%',
      content: '" "',
      position: 'absolute',
      background: 'linear-gradient(to top,#7db9e8 0,#003b69 0,#197abe 100%)',
      [theme.breakpoints.down( 'sm' )]: {
        right: '95px',
      },      
    },
  },
  graphLeft: {
    width: '65%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  sliderBar: {
    width: '100%',
    textAlign: 'right',
  },
  sliderHead: {
    width: 'auto',
    boxSizing: 'border-box',
    '& p': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '19px',
    },
  },
  titleNew: {
    fontSize: '18px',
    marginBottom: '15px',
    fontWeight: '700',
    textAlign: 'center',
    width: '50%',
    justifyContent: 'flex-end',
    display: 'flex',
    '& span': {
      borderBottom: '1px solid',
      lineHeight: '1.4',
    },
    '&:first-child': {
      justifyContent: 'center',
    },
  },
  emptySlider: {
    width: '100%',
    textALign: 'right',
    fontFamily: 'MuseoSans-500',
    fontWeight: '500',
  },
  heightHundred: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  heightThirty: {
    height: '30px',
  },
  emptyValue: {
    display: 'flex',
    marginTop: '0',
    fontSize: '18px',
    marginLeft: '20px',
    position: 'relative',
    textAlign: 'right',
    paddingTop: '0px',
    width: '50%',
    justifyContent: 'flex-end',
  },
  sliderGraph: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    height: '60px',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  graphRight: {
    width: '35%',
    display: 'flex',
    margin: '0 0 0 auto',
    '& h3': {
      marginBottom: '20px',
      marginTop: '0',
      fontSize: '18px',
      textDecoration: 'underline',
      textAlign: 'center',
      color: '#000',
      whiteSpace: 'nowrap',
    },
    '& > div': {
      width: '50%',
      margin: '0 0px 0px 25px',
      position: 'relative',
      paddingBottom: '10px',
      '&:first-child': {
        width: '40%',
        marginLeft: '10px',
      },
    },
  },
  pmiGraph: {
    height: '50px !important',
    paddingBottom: '20px',
  },
  utilitiesPMI: {
    height: '200px',
    [theme.breakpoints.down( 'sm' )]: {     
      paddingLeft: '0px',      
    },
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '200px',
      paddingLeft: '0px',
      marginTop: '100px',
    },
  },
  closingCosts: {
    width: '67%',
    margin: '20px auto',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      width: '100%',
      '& thead': {
        '& tr': {
          '& th': {
            background: '#dae3f3',
            fontSize: '20px',
            borderBottom: '3px solid #fff',
            padding: '8px',
            lineHeight: '1.42857143',
            textAlign: 'left',
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
            '&:last-child': {
              textAlign: 'right',
            },
          },
        },
      },
      '& tbody': {
        '& tr': {
          background: '#f2f2f2',
          borderBottom: '2px solid #fff',
          height: '43px',
          '& td': {
            fontSize: '16px',
            padding: '8px',
            lineHeight: '1.42857143',
            textAlign: 'left',
            '& input': {
              width: '85px',
              border: 'none',
              outline: '0',
              background: '#f2f2f2',
              borderBottom: '1px solid #0069aa',
              textAlign: 'right',
              '&:before': {
                border: 'none',
                outline: 'none',
              },
            },
            '&:last-child': {
              textAlign: 'right',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            },
            '& img': {
              cursor: 'pointer',
              verticalAlign: 'middle',
              maxWidth: '20px',
              display: 'flex',
            },
          },
        },
      },
      '& tfoot': {
        '& tr': {
          '& th': {
            background: '#dae3f3',
            fontSize: '16px',
            padding: '8px',
            lineHeight: '1.42857143',
            textAlign: 'left',
            borderBottom: '2px solid #fff',
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
            '&:last-child': {
              textAlign: 'right',
            },
            '& i': {
              color: '#333',
            },
          },
          '&:last-child': {
            '& th': {
              borderBottom: '2px solid #4472c4',
            },
          },
        },
      },
    },
  },
  fitoutCosts: {
    '& table': {
      '& thead': {
        '& tr': {
          '& th:last-child': {
            textAlign: 'center',
          },
        },
      },
      '& tfoot': {
        '& tr:last-child': {
          '& th:first-child': {
            paddingLeft: '20px',
          },
        },
      },
    },
  },
  higraphDetails: {
    border: '1px solid #2f75b5',
    margin: '20px 0',
    padding: '15px 0',
    '& h3': {
      textAlign: 'center',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '22px',
      marginTop: '0',
    },
    '& img': {
      width: '100%',
    },
  },
  blueBar: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
  },
  emptyDiv: {
    height: '50px',
  },
  mortgageHeight: {
    height: '130px',
  },
  purchaseSlideScroll:{
    '@media (max-width: 1199px)': {
      overflowX: 'auto',
      paddingBottom: '40px',
    }
  },
  purchaseSlide: {
    paddingRight: '30px',
    paddingTop: '15px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingRight: '0px',
    },
    '@media (max-width: 1199px)': {
      minWidth: '950px',
    }
  },
  graphHeader: {
    minWidth: '170px',
    boxSizing: 'border-box',
    paddingLeft: '15px',
    '& p': {
      height: '60px',
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px',
      paddingRight: '20px',
      marginBottom: '0px',
    },
  },
  rzSlider: {
    width: '100%',
    height: '60px',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    paddingTop: '0px',
  },
  emptyrzSlider: {
    paddingTop: '29px',
  },
  mortgageSlider: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    height: '100px',
    marginBottom: '10px',
  },
  mortgageAmount: {
    height: '30px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  transferSlider: {
    height: '130px',
  },
  sliderHeight: {
    height: '40px',
    '&  div': {
      paddingBottom: '0px !important',
    },
  },
  sliderHeightNew: {
    height: '60px',
    '&  div': {
      paddingTop: '10px !important',
      paddingBottom: '0px !important',
    },
  },
  transferSlide: {
    paddingRight: '40px',
    paddingTop: '10px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingRight: '0px',
    },
    '@media (max-width: 479px)': {
      minWidth: '950px',
    }
  },
  flexRightSpace: {
    paddingRight: '30px',
  },
  feesRow: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  feesGraph: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    height: '100px',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      fontSize: '18px',
    },
    '& sliderValues': {
      height: '100% !important',
    },
  },
  maintenanceGraph: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    boxSizing: 'border-box',
    alignItems: 'center',
    height: '40px',
    '& b': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
      fontSize: '18px',
    },
  },
  utilitiesHome: {
    height: '295px',
  },
  mainGraph: {
    height: '130px',
  },
  hemptyValue: {
    display: 'flex',
    marginTop: '0',
    fontSize: '18px',
    marginLeft: '20px',
    position: 'relative',
    textAlign: 'right',
    paddingTop: '10px',
    width: '50%',
    justifyContent: 'flex-end',
  },
  feesValues: {
    display: 'flex',
    marginTop: '0',
    fontSize: '18px',
    marginLeft: '20px',
    position: 'relative',
    height: 'inherit',
    textAlign: 'right',
    paddingTop: '10px',
    paddingRight: '10px',
    '& input': {
      float: 'right',
      fontSize: '18px',
      width: '95px',
      textAlign: 'right',
      background: '0 0',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
      '&:disabled': {
        background: '#ebebe4',
        cursor: 'not-allowed',
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
    '& div': {
      '&:before': {
        border: 'none !important',
        outline: 'none',
      },
      '&:after': {
        border: 'none',
        outline: 'none',
      },
    },
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 10px',
      height: '29px',
      cursor: 'pointer',
      alignItems: 'center',
      marginTop: '0',
      position: 'relative',
      fontSize: '19px',
    },
  },
  yearsList: {
    listStyle: 'none',
    margin: '0px',
    [theme.breakpoints.down( 'sm' )]: {
      paddingLeft: '0px',     
    },
    '& li': {
      backgroundImage: `url(${TickMark})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '11px',
      backgroundPosition: 'left top 8px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 0 30px 40px',
      fontSize: '21px',
      '&:last-child': {
        paddingBottom: '0px',
      },
      '@media (max-width: 1024px)': {
        flexWrap: 'wrap',
        display: 'inline-block',
        lineHeight: '22px',
      },
      '@media (max-width: 767px)': {
        flexWrap: 'wrap',
        display: 'inline-block',
        lineHeight: '22px',
        fontSize: '16px',
      },
      '& span': {
        marginRight: '6px',
      },
    },
    '& select': {
      background: '#fbfdfd',
      border: 'none',
      outline: '0',
      borderBottom: '2px solid #337ab7',
      color: '#337ab7',
      textAlign: 'center',
      padding: '0 20px',
      height: '29px',
      cursor: 'pointer',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-300',
      fontSize: '21px',
      '@media (max-width: 767px)': {
        fontSize: '16px',
        padding: '0 10px',
        minWidth: '90px',
      },
    },
  },
  investList: {
    padding: '0px',
    margin: '0px',
    '& li': {
      padding: '0 0 30px 40px',
      backgroundSize: '11px',
      fontSize: '21px',
      lineHeight: '1.42857143',
      '&:last-child': {
        paddingBottom: '0px',
      },
      '@media (max-width: 1024px)': {
        flexWrap: 'wrap',
        display: 'inline-block',
        lineHeight: '22px',
      },
      '@media (max-width: 767px)': {
        flexWrap: 'wrap',
        display: 'inline-block',
        lineHeight: '22px',
        fontSize: '16px',
      },
    },
  },
  reiMortgage: {
    height: '150px',
  },
  reiSliderSpace: {
    paddingRight: '30px',
  },
  sliderRange: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '83%',
    height: '100px',
    position: 'relative',
    '& p': {
      marginBottom: '0px',
    },
  },
  rentalSlider: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '65%',
    height: '100px',
    position: 'relative',
    '& p': {
      marginBottom: '0px',
    },
  },
  utilitySlider: {
    height: '150px',
  },
  reiGraph: {
    alignItems: 'center',
    width: '100%',
  },
  reiValues: {
    height: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  priceSlider: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '85%',
    height: '60px',
    boxSizing: 'border-box',
  },
  priceRange: {
    width: '100%',
  },
  appreciationSlider: {
    height: '130px !important',
  },
  infoIcon: {
    width: '25px',
    display: 'inline-block',
    '& img': {
      marginTop: '-4px',
      maxWidth: '20px',
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
  },
  lefBlock: {
    width: '70%',
    display: 'inline-block',
    float: 'left',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
      marginBottom: '20px',
    },
    '@media (min-width: 600px) and (max-width: 767px)': {
      width: 'calc(100% - 110px)',
    }
  },
  lefSlider: {
    display: 'flex',
    alignItems: 'center',
    height: '70px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '25px',
    }
  },
  reiTitle: {
    fontSize: '20px',
    paddingRight: '20px',
    minWidth: '150px',
    maxWidth: '150px',
    boxSizing: 'border-box',
    lineHeight: '1.42857143',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginBottom: '0px',
    },
  },
  saleValues: {
    display: 'flex',
    fontSize: '18px',
    position: 'relative',
    textAlign: 'right',
    paddingRight: '30px',
    width: 'calc(15% - 5px)',
    height: '60px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& input': {
      float: 'right',
      fontSize: '18px',
      width: '80px',
      textAlign: 'right',
      background: '0 0',
      border: '0',
      borderBottom: '1px solid #0069aa',
      outline: '0',
      '&:disabled': {
        background: '#ebebe4',
        cursor: 'not-allowed',
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
    '& div': {
      '&:before': {
        border: 'none !important',
        outline: 'none',
      },
      '&:after': {
        border: 'none',
        outline: 'none',
      },
    },
  },
  saleTitle: {
    fontSize: '18px',
    textAlign: 'right',
    width: '100%',
    marginBottom: '0px !important',
  },
  saleInput: {
    padddingRight: '10px',
    textAlign: 'right',
  },
  flexSlider: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    position: 'relative',
    paddingTop: '0px',
    width: '80%',
    boxSizing: 'border-box',
  },
  priceSliderInput: {
    width: '96%',
    margin: '0 2%',
    boxSizing: 'border-box',
    '& span': {
      marginBottom: '0px !important',
    },
  },
  appreciationValues: {
    height: '130px',
  },
  appreciationTitle: {
    height: '130px',
  },

  annualTable: {
    width: '100%',
    marginBottom: '30px',
    borderSpacing: '0px',
    borderCollapse: 'collapse',
    '& thead': {
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          borderBottom: '2px solid #fff',
          fontSize: '17px',
          textAlign: 'left',
          padding: '8px',
          lineHeight: '1.42857143',
          fontFamily: 'MuseoSans-500',
          fontWeight: 'normal',
          '&:nth-child(3)': {
            textAlign: 'right',
          },
          '&:nth-child(4)': {
            textAlign: 'right',
          },
          '& select': {
            color: '#337ab7',
            textAlign: 'center',
            padding: '0 20px',
            height: '29px',
            outline: '0',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        '& td': {
          backgroundColor: '#f2f2f2',
          borderTop: '1px solid #fff',
          fontSize: '17px',
          padding: '8px',
          lineHeight: '1.42857143',
          '&:nth-child(3)': {
            textAlign: 'right',
          },
          '&:nth-child(4)': {
            textAlign: 'right',
          },
          '& i': {
            color: '#333',
          },
          '& img': {
            display: 'flex',
            verticalAlign: 'middle',
            cursor: 'pointer',
            maxWidth: '20px',
          },
          '& p': {
            marginBottom: '0px',
          },
        },
      },
    },
    '& tfoot': {
      '& tr': {
        '& th': {
          backgroundColor: '#dae3f3',
          height: '38px',
          padding: '0 8px',
          lineHeight: '1.2',
          borderTop: '1px solid #fff',
          fontFamily: 'MuseoSans-500',
          textAlign: 'left',
          fontWeight: '500',
          fontSize: '17px',
          '&:nth-child(2)': {
            fontStyle: 'italic',
          },
          '&:nth-child(3)': {
            textAlign: 'right',
          },
        },
        '&:last-child': {
          '& th': {
            borderBottom: '2px solid #0069aa',
          },
        },
      },
    },
  },
  blueBoldText: {
    '& td': {
      borderBottom: '2px solid #0069aa',
      fontFamily: 'MuseoSans-500',
      fontWeight: '500',
    },
  },
  leftData: {
    paddingLeft: '25px !important',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '15px !important',
    },
  },
  cashFlow: {
    '& tr': {
      '& th': {
        '&:last-child': {
          minWidth: '100px',
          textAlign: 'right',
        },
        '&:nth-child(3)': {
          minWidth: '100px',
          textAlign: 'right',
        },
      },
      '& td': {
        '&:nth-child(3)': {
          minWidth: '100px',
          textAlign: 'right',
        },
      },
    },
    '& tbody:last-child': {
      '  & tr:last-child': {
        '& td': {
          backgroundColor: '#dae3f3',
        },
      },
    },
  },
  reiTables: {
    '& table': {
      '& tr': {
        '& th': {
          '&:first-child': {
            display: 'none !important',
          },
        },
        '& td': {
          '&:first-child': {
            display: 'none !important',
          },
        },
      },
    },
  },
  summaryGraphs: {
    marginTop: '50px',
    '& img': {
      width: '100%',
    },
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  hideFirstColumn: {
    '& tr': {
      '& td:first-child, & th:first-child': {
        display: 'none',
      },
    },
  },
  calcPaddingScroll:{
    '@media (max-width: 1199px)': { 
      overflow: 'auto',
    }
  },
  calcPadding: {
    padding: '20px 15px 0px 15px',
    '@media (max-width: 1199px)': { 
      minWidth: '950px',
    }
  },
  tabButtons: {
    paddingTop: '20px',
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      marginRight: '10px',
      fontSize: '16px',
      color: '#000',
      textTransform: 'inherit',
      minHeight: 'inherit',
      minWidth: '170px',
      [theme.breakpoints.down( 'xs' )]: {
        minWidth: '130px',
      },
      '& span': {
        border: 'none !important',
      },
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      '@media (max-width: 767px)': {
        overflowX: 'auto',
        overflowY: 'hidden',
      }
    },
    '& > div > div + span': {
      display: 'none',
    },

  },
  tabsContent: {
    '& img': {
      maxWidth: '100%',
    },
  },
  activeTab: {
    position: 'relative',
    top: '1px',
    background: '#fff',
    borderColor: '#000 !important',
  },
  piText: {
    display: 'inline-block',
    width: '100%',
  },
  bottomText: {
    color: '#0069aa',
    fontSize: '16px',
    borderTop: '1px solid #ccc',
    paddingTop: '15px',
    paddingBottom: '25px',
    '& p': {
      margin: '0 0 10px',
    },
    '& a': {
      borderBottom: '1px solid #0069aa',
      '&:hover': {
        color: '#f89b23',
        borderBottom: '1px solid #f89b23',
      },
    },
  },
  blueBgTr: {
    '& td': {
      background: '#dae3f3',
    },
  },
  debtToIncome: {
    '& tbody:last-child': {
      '& tr': {
        '& td': {
          background: '#dae3f3',
        },
      },
    },
  },
  pmiSlider: {
    width: '65%',
    '& > div': {
      width: '100%',
      marginRight: '0px',
    },
  },
  pmiRange: {
    '& > div': {
      width: '95%',
      marginRight: '5%',
    },
  },
  wrapDisplay: {
    '& > div': {
      flexWrap: 'wrap',
      '& p': {
        width: '100%',
        display: 'block',
        marginBottom: '0px',
        paddingRight: '0px',
      },
    },
  },
  fullwidthDiv: {
    '& > div': {
      width: '100%',
      marginRight: '0px',
    },
  },
  fullwidthSec: {
    '& > div': {
      width: '100%',
      marginRight: '8px',
    },
  },
  topTen: {
    paddingTop: '10px',
  },
  hiddenData: {
    visibility: 'hidden',
    marginBottom: '0px !important',
  },
  inputRight: {
    display: 'flex',
    fontSize: '18px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& input': {
      width: '100%',
      border: 'none',
      fontSize: '18px',
      borderBottom: '1px solid #0069aa',
      outline: 'none',
      textAlign: 'right',
    },
  },
  lastDiv: {
    marginLeft: ' 0 !important',
    paddingLeft: '30px',
  },
  clearModule: {
    maxWidth: '100%',
    width: '600px',
    boxSizing: 'border-box',
    padding: '0px !important',
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  buttonOne: {
    backgroundColor: '#caa336',
    color: '#fff',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    borderWidth: '2px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    borderColor: '#caa336',
    '&:hover': {
      backgroundColor: '#caa336',
      borderColor: '#caa336',
      color: '#fff',
    },
  },
  buttonTwo: {
    backgroundColor: 'transparent',
    color: '#333',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    marginLeft: '5px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid #ccc',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      border: '2px solid #adadad',
    },
  },
  affordableValues: {
    '& > div': {
      paddingRight: '10px',
      marginRight: '5px',
    },
  },
  downValues: {
    '& > div': {
      paddingRight: '10px',
    },
  },
  appreciationValue: {
    '& > div': {
      marginRight: '10px',
      paddingLeft: '20px',
      paddingRight: '5px',
    },
  },
  homeMenuList: {
    position: 'relative',
    paddingLeft: '0px !important',
    maxHeight: 'calc(100vh - 280px)',
    overflow: 'auto',
    '& li': {
      position: 'relative !important',
      paddingLeft: '35px !important',
      '@media (min-width: 768px) and (max-width: 1199px)': { 
        paddingLeft: '50px !important',
      }
    },
  },
  menuCheck: {
    left: '0',
    color: 'green',
    transition: 'all .2s',
    borderRadius: '100%',
    width: '4vh',
    marginRight: '13px',
    marginLeft: '10px',
    visibility: 'visible',
    position: 'absolute',
    top: '0',
    right: 'auto',
    transform: 'rotate(-6deg)',
    '& svg': {
      fontSize: '1rem !important',
      '@media (min-width: 768px) and (max-width: 1199px)': {
        fontSize: '25px !important',
      }
    },
  },
  bigModule: {
    fontSize: '16px !important',
  },
  moduleToolTip: {
    padding: '3px 8px',
    marginTop: '-20px !important',
    width: 'auto !important',
    maxWidth: '200px',
  },
  pwiWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    display: 'none',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      color: '#0069aa',
      lineHeight: '1.1',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000',
      fontSize: '16px',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0px 0px 10px',
      boxSizing: 'border-box',
    },
    '&::before': {
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
    '&::after': {
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
      content: '""',
      width: '0',
      height: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
    },
  },
  checkmarksScroll: {
    listStyle: 'none',
    padding: '0',
    display: 'inline-block',
    marginLeft: '0',
    width: '100%',
    textAlign: 'left',
    position: 'relative',
    paddingInlinStart: '35px',
    marginBottom: '10px',
    marginTop: '0px',
    boxSizing: 'border-box',
    '& li': {
      width: '15px',
      height: '15px',
      background: '#0069aa',
      display: 'inline-block',
      margin: '1px 2px',
      borderRadius: '50%',
      position: 'relative',
      lineHeight: '20px',
      float: 'left',
      cursor: 'pointer',
      fontSize: '18px',
      paddingBottom: '8px',
      listStyle: 'none',
      marginLeft: '2px',
      boxSizing: 'border-box',
      '&:last-child': {
        '&::before': {
          background: '0 0!important',
        },
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '0',
        margin: 'auto',
        left: 'auto',
        marginTop: 'auto',
        width: '7px',
        height: '3px',
        background: '#0069aa',
        display: 'inline-block',
        right: '-5px',
        zIndex: '-1',
        bottom: '0',
        boxSizing: 'border-box',
      },
      '&:hover': {
        opacity: '.8',
      },
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    '@media (max-width: 479px)': {
      display: 'block',
    }
  },
  closeWalkThru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  pwiWalkthruOne: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'display !important',
    '@media (max-width: 1199px)': {
      right: '0px',
    },
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  nomarginBottom: {
    marginBottom: '0px !important',
  },
  pwiWalkthruTwo: {
    top: 'auto',
    bottom: '90px',
    right: '-30px',
    left: 'auto',
    display: 'none',
    '&::before': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '30px 30px 0 32px',
      borderColor: '#fff transparent transparent',
      bottom: '-27px!important',
    },
    '&::after': {
      left: 'auto',
      right: '40px',
      top: 'auto',
      transform: 'none',
      borderWidth: '31px 30px 0 32px',
      borderColor: '#0069aa transparent transparent',
      bottom: '-32px!important',
    },
  },
  pwiWalkthruThree: {
    top: '80px',
    left: '-56px',
    right: 'auto',
    display: 'none',
    '&::before': {
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      top: '-29px',
      left: '39px',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  pwiWalkthruFour: {
    top: '77px',
    left: '335px',
    right: 'auto',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      top: '0',
      bottom: '0',
    },
  },
  pwiWalkthruFive: {
    top: 'auto',
    left: '338px',
    right: 'auto',
    bottom: '0',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '52px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '50px',
      top: 'auto',
    },
  },
  pwiWalkthruSix: {
    top: '107px',
    left: '-45px',
    right: 'auto',
    display: 'none',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
    },
  },
  pwiWalkthruSeven: {
    top: '25px',
    left: '325px',
    right: 'auto',
    position: 'fixed',
    display: 'none',
    zIndex: '9999',
    '&::before': {
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '84px',
      top: 'auto',
    },
    '&::after': {
      left: '-51px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      bottom: '82px',
      top: 'auto',
    },
  },
  pwiWalkthruEight: {
    top: '73px',
    left: 'auto',
    right: '-72px',
    display: 'none !important',
    '&::before': {
      top: '-30px',
      right: '64px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: 'auto',
      right: '60px',
    },
  },
  activeList: {
    background: '#84aa47 !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#84aa47',
      },
    },
    '&::after': {
      position: 'absolute',
      top: '0',
      margin: 'auto',
      content: '""',
      height: '5px',
      width: '8px',
      borderWidth: '0 0 2px 2px',
      borderStyle: 'solid',
      borderColor: '#fff',
      bottom: '2px',
      left: '-1px',
      right: '0',
      transform: 'rotate(-45deg)',
      boxSizing: 'border-box',
    },
  },
  currentTab: {
    background: '#f7941e !important',
    '&:not(:last-child)': {
      '&::before': {
        background: '#f7941e',
      },
    },
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  pwiActiveWalkthru: {
    display: 'block !important',
    zIndex: '9999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  pwiActiveBlock: {
    pointerEvents: 'none',
    '& img': {
      position: 'relative',
      zIndex: '9999',
      cursor: 'none',
    },
  },
  pwiWalkThruButton: {
    pointerEvents: 'none',
    position: 'relative',
    zIndex: '9999',
    cursor: 'none',
  },
  pwiWalkTitle: {
    background: '#fff',
    zIndex: '9999',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    top: '0',
    padding: '3px 0px 3px 7px',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
    width: '98%',
  },
  pwiActiveModule: {
    background: '#fff',
    padding: '5px',
    borderRadius: '4px',
    position: 'relative',
    zIndex: '9999',
    width: '50%',
    '& img': {
      cursor: 'none',
      pointerEvents: 'none',
    },
    '& h3': {
      cursor: 'none',
      pointerEvents: 'none',
    },
  },
  worksheetToolTip: {
    minWidth: '186px',
    maxWidth: '186px',
    boxSizing: 'border-box',
    opacity: '0.9 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    '&::after': {
      borderRightStyle: 'hidden !important',
    },
    '&::before': {
      borderRight: '6px solid #0069aa !important',
      left: '-6px !important',
    },
  },
  logoLink: {
    margin: '0 0 0 auto',
    '& img': {
      maxWidth: '140px',
      height: '38px',
    },
  },
  screenHght: {
    height: '110vh',
  },
  percentIcon: {
    [theme.breakpoints.down( 'xs' )]: {
      right:'-16px',
    },
  },
  quickSave:{
    position: 'absolute',
    right: '110px',
    padding: '1px 6px !important',
    margin: '0px !important',
    minWidth: 'initial',
    borderRadius: '0px !important',
    height: '45px',
    [theme.breakpoints.down( 'xs' )]: {
      right: '85px',
    },
    '&:hover':{
      background: '#0d97ec !important',
    },
    '& img':{
      width:'37px',
      position: 'relative',
      top: '5px',
    }
  },
  quickSavePopupBtn: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        borderColor: '#e6e6e6',
        color: '#333',
        marginRight: '6px',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  scenarioNameBlock: {
    color: '#0069aa',
    marginBottom: '20px',
    marginTop: '20px',
    '& span': {
      display: 'block',
      fontSize: '15px',
      '&:last-child': {
        fontSize: '14px',
        paddingTop:'5px',
        fontStyle:'italic'
      }
    }
  },
  documentsMenu: {
    position:'relative'
  },
  activeMenu: {
    color: '#000 !important',
    '&:hover': {
      color: '#eca041 !important'
    }
  },
  disableActiveMenu: {
    color: 'gray !important',
    pointerEvents: 'none',
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
} );

export default styles;
