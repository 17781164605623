import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import {useMutation} from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import ReactTooltip from 'react-tooltip';
import Link from '@material-ui/core/Link';
// import Graph from '../../../assets/img/career-sketch/graph.png';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import { Dialog } from '@material-ui/core';
import CommonDialogInfo from '../../../screens/life-event-library/common-dialog-info';
import DavidPopup1 from '../../../assets/img/career-sketch/Army-Physician-card.jpg';
import styles from './card-style';
import LogoPopup from '../../../assets/img/career-sketch/logo.png';
import CircleOne from '../../../assets/img/career-sketch/s1.png';
import CircleTwo from '../../../assets/img/career-sketch/s2.png';
import CircleThree from '../../../assets/img/career-sketch/s3.png';
import CircleFour from '../../../assets/img/career-sketch/s4.png';
import CircleSix from '../../../assets/img/career-sketch/s6.png';
import ArrowPop from '../../../assets/img/career-sketch/arrow.png';
import one from '../../../assets/img/career-sketch/1.png';
import Two from '../../../assets/img/career-sketch/2.png';
import Three from '../../../assets/img/career-sketch/3.png';
import Four from '../../../assets/img/career-sketch/4.png';
import Five from '../../../assets/img/career-sketch/5.png';
import * as NetworthServices from '../../../calculations/net-worth-service';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );
const DavidCard = ( props ) => {
  const { classes, history } = props;
  const [cardType, setCardType] = React.useState( 'card' );
  const [open, setOpen] = React.useState(false);
  const UPDATE_CAREERSKETCH_VIEWERSHIP  = loader('../../../graphql/schema/career-sketch/updateCareerSketchViewerShip.graphql');

  const [ updateCareerSketchViewerShip ] = useMutation(UPDATE_CAREERSKETCH_VIEWERSHIP);

  const closeCard = ( ) => {
    props.closeCard( '' );
  };

  const openAllSteepingStones = ( ) => {
    props.openAllSteepingStones( true );
  };

  const handleAllPopupClose = () => {
    props.closeCard( '' );
  };

  const redirectToFullStory = () => {
    const token = localStorage.getItem('app-token');
    if(token)  {
       updateCareerSketchViewerShip({
        variables: {
          data: {
            column:'david_story',
          },
        },
      });
      history.push( '/career-story-david' );
    } else {
      setOpen(true);
    }
  };

  return (
    <div>
      {cardType === 'card' ? (
        <div>
          <Button className={ classes.closeBtn } onClick={ closeCard }><i className="la la-close" /></Button>
          <div className={ classes.careerPopup }>
            <div className={ classes.careerPopupLeft }>
              <img src={ DavidPopup1 } alt="" />
            </div>
            <div className={ classes.careerPopupMiddle }>
              <div className={ classes.careerMidTop }>
                <h3>David, Navy Physician</h3>
                <p>
                  Leveraging a Navy scholarship to earn his medical degree with minimal debt, David pursues a naval career in underwater medicine, serves four years active duty, and lands a residency in Boston as he looks to earn his license.
                </p>
              </div>
              <div className={ classes.careerMidGraph }>
                <h3>15-Year Net Worth: $230,300</h3>
                {/* <img src={ Graph } alt="" /> */}
                <HighchartsReact
                  highcharts={ Highcharts }
                  options={ NetworthServices.graphCommonObject }
                />
              </div>
              <div className={ classes.careerMidBottomFooter }>
                <img src={ LogoPopup } alt="logo" />
                <Link To="/" className={ classes.inkiryLink }>www.inkwiry.com</Link>
              </div>
            </div>
            <div className={ classes.careerPopupRight }>
              <h3>David&apos;s Life:</h3>
              <p>As of 2033 (32 years old)</p>
              <ul>
                <li>Cash Savings</li>
                <li>Investments  </li>
                <li>Education</li>
                <li>Medical Degree</li>
                <li>Military Experience</li>
                <li>Work Experience</li>
                <li>A Career</li>
                <li>A Hybrid Car</li>
                <li>Annual Vacations</li>
                <li>A Great Future</li>
              </ul>
              <div className={ classes.careerPopupRightBottom }>
                <h3>David&apos;s KPIs:</h3>
                <ul>
                  <li>Debt-to-Income: 4.1%</li>
                  <li>Current Ratio: 10.0x </li>
                  <li>Savings Rate: 22.6%</li>
                </ul>
                <div className={ classes.btnGroupPopup }>
                  <Button className={ classes.viewCareerBtn } onClick={ () => { setCardType( 'steeping' ); } }>
                    VIEW CAREER STEPPING STONES
                    <i className="la la-greater-than" />
                  </Button>
                  <Button className={ classes.viewHistoryBtn } onClick={ redirectToFullStory }>
                    READ HIS FULL STORY
                    <i className="la la-greater-than" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Button className={ classes.backCard } onClick={ () => { setCardType( 'card' ); } }><i className="las la-long-arrow-alt-left" /></Button>
          <Button className={ `${classes.backCard} ${classes.backcardClose}` } onClick={ handleAllPopupClose }><i className="las la-times" /></Button>
          <div className={ classes.careerPopup }>
            <div className={ classes.careerPopupLeft }>
              <img src={ DavidPopup1 } alt="" />
            </div>
            <div className={ classes.careerPopupRightTwo }>
              <div>
                <div className={ classes.careerMidTop }>
                  <h3>David&apos;s Stepping Stones to Navy Physician</h3>
                  <div className={ classes.circlePopup }>
                    <div className={ classes.popGroup }>
                      <div className={ classes.circle }>
                        <img src={ CircleOne } alt="" />
                        <font className={ classes.circleTittle }>
                          HIGHER
                          <br />
                          EDUCATION
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleSix } alt="" />
                        <font className={ classes.circleTittle }>
                          MORE HIGHER
                          <br />
                          EDUCATION
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleTwo } alt="" />
                        <font className={ classes.circleTittle }>
                          CAREER
                          <br />
                          PATH
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleThree } alt="" />
                        <font className={ classes.circleTittle }>
                          CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleFour } alt="" />
                        <font className={ classes.circleTittle }>
                          ADD&apos;L CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                    </div>
                    <div className={ classes.steppingStonesCard }>
                      <div className={ classes.steppingStoneOne }>
                        <h4>Bachelor&apos;s Degree in Biology</h4>
                        <p>
                          University of Maine
                          <br />
                          {' '}
                          Tuition: $11,100
                          <br />
                          {' '}
                          <span
                            data-for="spanTool"
                            data-tip="The total amount to attend school, which includes tuition, fees, room and board, and other living expenses."
                          >
                            Total CoA
                          </span>
                          : $120,469
                          <ReactTooltip id="spanTool" place="left" type="light" effect="solid" className={ `${classes.tooltipInfo} ${classes.tooltipInfoBorderLeft}` } />
                          <br />
                          Student Loans: $20,000
                          <br />
                          {' '}
                          4 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ one } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneTwo }>
                        <h4>Medical School Funded by Naval Scholarship</h4>
                        <p>
                          Boston University
                          <br />
                          {' '}
                          Tuition: $63,000
                          <br />
                          {' '}
                          <span
                            data-for="spanTool"
                            data-tip="The total amount to attend school, which includes tuition, fees, room and board, and other living expenses."
                          >
                            Total CoA
                          </span>
                          : $356,529
                          <br />
                          Student Loans: $0
                          <br />
                          {' '}
                          4 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Two } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneThree }>
                        <h4>Four Years Active Duty in Undersea Medicine</h4>
                        <p>
                          Portsmouth, VA
                          <br />
                          {' '}
                          Income: $60,000
                          <br />
                          Living Exp: $920/mo
                          <br />
                          {' '}
                          1 year
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Three } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneFour }>
                        <h4>Joins Navy Reserves and Starts Residency</h4>
                        <p>
                          Portsmouth, VA
                          <br />
                          {' '}
                          Income: $67,000
                          <br />
                          Living Exp: $938/mo
                          <br />
                          {' '}
                          3 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Four } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneFive }>
                        <h4>Aspiring Licensed Physician</h4>
                        <p>
                          Boston, MA
                          <br />
                          {' '}
                          Income: $70,000
                          <br />
                          Living Exp: $3,080/mo
                          <br />
                          {' '}
                          3 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Five } alt="" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className={ classes.careerMidBottomFooter }>
                  <img src={ LogoPopup } alt="logo" />
                  <p>See and build your best tomorrows now</p>
                </div>
              </div>
              <div className={ classes.btnGroupPopupNew }>
                <Button className={ classes.viewCareerBtn } onClick={ openAllSteepingStones }>
                  VIEW STEPPING STONES FOR ALL SKETCHES
                  <i className="la la-greater-than" />
                </Button>
                <Button className={ classes.viewHistoryBtn } onClick={ redirectToFullStory }>
                  READ HIS FULL STORY
                  <i className="la la-greater-than" />
                </Button>
              </div>

            </div>
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        className={ classes.getStartedPopupDialog }
      >
        <div className={classes.btnClose}>
          <i className="la la-times" onClick={()=>{setOpen(false)}}/> 
        </div>
        <CommonDialogInfo />
      </Dialog>
    </div>
  );
};

DavidCard.propTypes = {
  classes: PropTypes.object.isRequired,
  closeCard: PropTypes.func.isRequired,
  openAllSteepingStones: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( DavidCard );
