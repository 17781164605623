import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import ReactGA  from 'react-ga';


ReactDOM.render( <App />, document.getElementById( 'root' ) );

ReactGA.initialize('G-J62CD4ENBH', {
    gaOptions: {
      create: 'G-J62CD4ENBH', 
      sampleRate: 100,
      transport: 'true',
      setSSessionTimeoutDuration: 16666666,
      //sessionControl: 'start',
      cookieName: 'nowgofigure',
      cookieDomain: 'nowgofigure.com',
      cookieExpires: 63072000,
      siteSpeedSampleRate: 100
    }
  });
  ReactGA.pageview(window.location.pathname + window.location.search);
 // ReactGA.pageview('/');
  //ReactGA.pageview('/new-member-welcome');
  ReactGA.event({
    category: 'user',
    action: 'Clicked',
    label: 'FOR PARENTS'
  });
  ReactGA.event({
    category: 'sign in',
    action: 'user signed in',
    label: 'SIGN IN'
  });
  ReactGA.event({
    category: 'user',
    action: 'Clicked',
    label: 'FOR EMPLOYERS'
  });
  ReactGA.event({
    category: 'user',
    action: 'Clicked',
    label: 'WATCH THE INTRO >'
  });
  ReactGA.event({
    category: 'Navigation',
    action: 'navigated',
    label: 'No thanks>',
    nonInteraction: 'true'
  });

