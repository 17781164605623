import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { loader } from 'graphql.macro';
import {useMutation} from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Button from '@material-ui/core/Button';
import ReactTooltip from 'react-tooltip';
import Link from '@material-ui/core/Link';
// import Graph from '../../../assets/img/career-sketch/graph.png';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import { Dialog } from '@material-ui/core';
import CommonDialogInfo from '../../../screens/life-event-library/common-dialog-info';
import KatelynPopup1 from '../../../assets/img/career-sketch/Katelyn-Apparel-Designer-card.jpg';
import styles from './card-style';
import LogoPopup from '../../../assets/img/career-sketch/logo.png';
import CircleOne from '../../../assets/img/career-sketch/s1.png';
import CircleTwo from '../../../assets/img/career-sketch/s2.png';
import CircleThree from '../../../assets/img/career-sketch/s3.png';
import CircleFour from '../../../assets/img/career-sketch/s4.png';
import CircleFive from '../../../assets/img/career-sketch/s5.png';
import ArrowPop from '../../../assets/img/career-sketch/arrow.png';
import one from '../../../assets/img/career-sketch/1.png';
import Two from '../../../assets/img/career-sketch/2.png';
import Three from '../../../assets/img/career-sketch/3.png';
import Four from '../../../assets/img/career-sketch/4.png';
import Five from '../../../assets/img/career-sketch/5.png';
import infoIcon from '../../../assets/img/info-icon.svg';
import * as NetworthServices from '../../../calculations/net-worth-service';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );
const KatelynCard = ( props ) => {
  const { classes, history } = props;
  const [cardType, setCardType] = React.useState( 'card' );
  const [open, setOpen] = React.useState(false);
  const UPDATE_CAREERSKETCH_VIEWERSHIP  = loader('../../../graphql/schema/career-sketch/updateCareerSketchViewerShip.graphql');

  const [ updateCareerSketchViewerShip ] = useMutation(UPDATE_CAREERSKETCH_VIEWERSHIP);

  const closeCard = ( ) => {
    props.closeCard( '' );
  };

  const openAllSteepingStones = ( ) => {
    props.openAllSteepingStones( true );
  };

  const handleAllPopupClose = () => {
    props.closeCard( '' );
  };

  const redirectToFullStory = () => {
    const token = localStorage.getItem('app-token');
    if(token)  {
       updateCareerSketchViewerShip({
        variables: {
          data: {
            column:'katelyn_story',
          },
        },
      });
      history.push( '/career-story-katelyn' );
    } else {
      setOpen(true);
    }
  };

  return (
    <div>
      {cardType === 'card' ? (
        <div>
          <Button className={ classes.closeBtn } onClick={ closeCard }><i className="la la-close" /></Button>
          <div className={ classes.careerPopup }>
            <div className={ classes.careerPopupLeft }>
              <img src={ KatelynPopup1 } alt="" />
            </div>
            <div className={ classes.careerPopupMiddle }>
              <div className={ classes.careerMidTop }>
                <h3>Katelyn, Fashion Designer</h3>
                <p>
                  Katelyn majors in Fashion Design at Rhode Island School of Design, launches her career in New York City, works two jobs, repays her student loans early and moves to the Los Angeles area to advance her career.
                </p>
              </div>
              <div className={ classes.careerMidGraph }>
                <h3>
                  15-Year Net Worth: $382,938
                  <span className={ classes.infoIconNew }>
                    <img
                      src={ infoIcon }
                      alt="infoicon"
                      data-for="infoIcon"
                      data-tip="Net worth: What you own (assets) minus what you owe (liabilities). Because Katelyn gets married, this figure includes her partner's income/expenses."
                    />
                    <ReactTooltip id="infoIcon" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />

                  </span>
                </h3>
                {/* <img src={ Graph } alt="" /> */}
                <HighchartsReact
                  highcharts={ Highcharts }
                  options={ NetworthServices.graphCommonObject }
                />
              </div>
              <div className={ classes.careerMidBottomFooter }>
                <img src={ LogoPopup } alt="logo" />
                <Link To="/" className={ classes.inkiryLink }>www.inkwiry.com</Link>
              </div>
            </div>
            <div className={ classes.careerPopupRight }>
              <h3>Katelyn&apos;s Life:</h3>
              <p>As of 2033 (32 years old)</p>
              <ul>
                <li>Cash Savings</li>
                <li>Investments  </li>
                <li>Education</li>
                <li>Work Experience</li>
                <li>Personal Shoe Designs</li>
                <li>A Career</li>
                <li>A Family</li>
                <li>A Cat</li>
                <li>A Car Lease</li>
                <li>Annual Vacations</li>
                <li>A Great Future</li>
              </ul>
              <div className={ classes.careerPopupRightBottom }>
                <h3>Katelyn&apos;s KPIs:</h3>
                <ul>
                  <li>Debt-to-Income: 1.4%</li>
                  <li>Current Ratio: 15.1x  </li>
                  <li>Savings Rate: 43.2%</li>
                </ul>
                <div className={ classes.btnGroupPopup }>
                  <Button className={ classes.viewCareerBtn } onClick={ () => { setCardType( 'steeping' ); } }>
                    VIEW CAREER STEPPING STONES
                    <i className="la la-greater-than" />
                  </Button>
                  <Button className={ classes.viewHistoryBtn } onClick={ redirectToFullStory }>
                    READ HER FULL STORY
                    <i className="la la-greater-than" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Button className={ classes.backCard } onClick={ () => { setCardType( 'card' ); } }><i className="las la-long-arrow-alt-left" /></Button>
          <Button className={ `${classes.backCard} ${classes.backcardClose}` } onClick={ handleAllPopupClose }><i className="las la-times" /></Button>

          <div className={ classes.careerPopup }>
            <div className={ classes.careerPopupLeft }>
              <img src={ KatelynPopup1 } alt="" />
            </div>
            <div className={ classes.careerPopupRightTwo }>
              <div>
                <div className={ classes.careerMidTop }>
                  <h3>Katelyn&apos;s Stepping Stones to Fashion Designer</h3>
                  <div className={ classes.circlePopup }>
                    <div className={ classes.popGroup }>
                      <div className={ classes.circle }>
                        <img src={ CircleOne } alt="" />
                        <font className={ classes.circleTittle }>
                          HIGHER
                          <br />
                          EDUCATION
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleTwo } alt="" />
                        <font className={ classes.circleTittle }>
                          CAREER
                          <br />
                          PATH
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleThree } alt="" />
                        <font className={ classes.circleTittle }>
                          CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleFour } alt="" />
                        <font className={ classes.circleTittle }>
                          ADD&apos;L CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                      <div className={ classes.arrow }>
                        <img src={ ArrowPop } alt="" />
                      </div>
                      <div className={ classes.circle }>
                        <img src={ CircleFive } alt="" />
                        <font className={ classes.circleTittle }>
                          FURTHER CAREER
                          <br />
                          ADVANCEMENT
                        </font>
                      </div>
                    </div>
                    <div className={ classes.steppingStonesCard }>
                      <div className={ classes.steppingStoneOne }>
                        <h4>Bachelor&apos;s Degree in Apparel Design</h4>
                        <p>
                          Rhode Island School of Design
                          <br />
                          {' '}
                          Tuition: $52,860
                          <br />
                          <span
                            data-for="spanTool"
                            data-tip="The total amount to attend school, which includes tuition, fees, room and board, and other living expenses."
                          >
                            Total CoA
                            <ReactTooltip id="spanTool" place="left" type="light" effect="solid" className={ `${classes.tooltipInfo} ${classes.tooltipInfoBorderLeft}` } />
                          </span>
                          : $310,647
                          <br />
                          {' '}
                          Student Loans: $75,000
                          <br />
                          {' '}
                          4 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ one } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneTwo }>
                        <h4>Entry-Level Apparel Designer</h4>
                        <p>
                          Astoria, NY
                          <br />
                          {' '}
                          Income: $53,000
                          <br />
                          {' '}
                          Supp. Income: $10,000
                          <br />
                          Living Exp: $2,560/mo
                          <br />
                          {' '}
                          4 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Two } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneThree }>
                        <h4>Associate Apparel Designer</h4>
                        <p>
                          Los Angeles, CA
                          <br />
                          {' '}
                          Income:  $70,000
                          <br />
                          Supp. Income: $10,000
                          <br />
                          {' '}
                          Living Exp: $3,320/mo
                          <br />
                          {' '}
                          3 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Three } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneFour }>
                        <h4>Senior Apparel Designer</h4>
                        <p>
                          Los Angeles, CA
                          <br />
                          {' '}
                          Income: $102,500
                          <br />
                          Living Exp: $4,510/mo
                          <br />
                          {' '}
                          4 years
                        </p>
                        <div className={ classes.popupNumber }>
                          <img src={ Four } alt="" />
                        </div>
                      </div>
                      <div className={ classes.steppingStoneFive }>
                        <h4>Launch Apparel Startup</h4>
                        <div className={ classes.popupNumber }>
                          <img src={ Five } alt="" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div className={ classes.careerMidBottomFooter }>
                  <img src={ LogoPopup } alt="logo" />
                  <p>See and build your best tomorrows now</p>
                </div>
              </div>
              <div className={ classes.btnGroupPopupNew }>
                <Button className={ classes.viewCareerBtn } onClick={ openAllSteepingStones }>
                  VIEW STEPPING STONES FOR ALL SKETCHES
                  <i className="la la-greater-than" />
                </Button>
                <Button className={ classes.viewHistoryBtn } onClick={ redirectToFullStory }>
                  READ HER FULL STORY
                  <i className="la la-greater-than" />
                </Button>
              </div>

            </div>
          </div>
        </div>
      )}
      <Dialog
        open={open}
        onClose={() => { setOpen(false) }}
        className={ classes.getStartedPopupDialog }
      >
        <div className={classes.btnClose}>
          <i className="la la-times" onClick={()=>{setOpen(false)}}/> 
        </div>
        <CommonDialogInfo />
      </Dialog>
    </div>
  );
};

KatelynCard.propTypes = {
  classes: PropTypes.object.isRequired,
  closeCard: PropTypes.func.isRequired,
  openAllSteepingStones: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( KatelynCard );
