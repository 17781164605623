import LeftArrow from '../../assets/img/testimonial-left-arrow.png';
import RightArrow from '../../assets/img/testimonial-right-arrow.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bannerCaption: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    '& h3': {
      fontSize: '54px',
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      width: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '27px',
      },
    },
  },
  bannerCaptionTitle: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: '999',
    '&:before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1',
    },
  },
  libraryImage: {
    maxHeight: '557px',
    overflow: 'hidden',
    position: 'relative',
    padding: '0',
    margin: '0',
    top: 'auto',
    boxSizing: 'border-box',
    '& img': {
      width: '100%',
      height: 'auto',
      display: 'flex',
      [theme.breakpoints.down( 'xs' )]: {
        minHeight: '200px',
        objectFit: 'cover',
      },
    },
    '&:before': {
      background: '#0069aa',
      content: '""',
      height: '100%',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
      zIndex: '999',
      opacity: '0.4',
    },
    [theme.breakpoints.down( 'sm' )]: {
      maxHeight: '420px',
    },
  },
  libraryText: {
    textAlign: 'center',
    '& h2': {
      margin: '0',
      padding: '0 0 38px 0',
      fontSize: '48px',
      lineHeight: '48px',
      fontFamily: '"MuseoSans-100"',
      color: theme.palette.common.blue,
      fontWeight: '500',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '35px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        padding: '0 0 20px 0',
      },
    },
    '& p': {
      margin: '0',
      padding: '0 0 60px 0',
      fontSize: '36px',
      lineHeight: '36px',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '25px',
      },
      [theme.breakpoints.down( 'sm' )]: {
        padding: '0 0 30px 0',
        fontSize: '20px',
      },
    },
  },
  libraryContent: {
    padding: '80px 0px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '40px 0px',
    },
  },
  eventsBlock: {
    '& figure': {
      margin: '0px',
      display: 'block',
      cursor: 'pointer',
      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
        transition: 'all 0.3s ease-in-out',
        WebkitTransition: 'all 0.3s ease-in-out',
        MozTransition: 'all 0.3s ease-in-out',
        MsTransition: 'all 0.3s ease-in-out',
        OTransition: 'all 0.3s ease-in-out',
        transform: 'scale(1, 1)',
        WebkitTransform: 'scale(1, 1)',
        MozTransform: 'scale(1, 1)',
        MsTransform: 'scale(1, 1)',
        Otransform: 'scale(1, 1)',
        '&:hover': {
          transform: 'scale(1.1, 1.1)',
          WebkitTransform: 'scale(1.1, 1.1)',
          MozTransform: 'scale(1.1, 1.1)',
          MsTransform: 'scale(1.1, 1.1)',
          Otransform: 'scale(1.1, 1.1)',
        },
      },
    },
  },
  eventsImage: {
    display: 'block',
    minHeight: '228px',
    padding: '0 20px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'md' )]: {
      minHeight: '185px',
      padding: '0 18px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      minHeight: 'inherit',
      padding: '0 13px',
      paddingBottom: '15px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      minHeight: 'inherit',
      width: '100%',
      paddingBottom: '22px',
      padding: '0 0px',
    },
  },
  eventsGrid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
    },
  },
  testimonialInner: {
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
    '& > div': {
      alignItems: 'center',
      position: 'static',
      '& ul': {
        bottom: '20px',
        left: '0',
        [theme.breakpoints.down( 'xs' )]: {
          bottom: '20px',
        },
      },
      '& li': {
        top: '0',
        margin: ' 0 1px',
        [theme.breakpoints.down( 'xs' )]: {
          top: '11px',
        },
        '& button': {
          background: '#fff',
          borderRadius: '50%',
          height: '13px',
          width: '13px',
          border: '1.5px solid #fff',
          '&::before': {
            display: 'none',
          },
        },
      },
      '& button + div + button': {
        backgroundImage: `url(${RightArrow})`,
        height: '100px',
        width: '50px',
        right: '0px',
        borderBottomLeftRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '0',
        backgroundSize: '30px',
        backgroundColor: '#fff !important',
        [theme.breakpoints.down( 'xs' )]: {
          left: '-110px',
        },
        '&:hover,  &:focus': {
          backgroundImage: `url(${RightArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '0',
          backgroundSize: '30px',
          backgroundColor: '#fffff',
        },
        '&::before': {
          display: 'none',
        },
      },

      '& >button:first-child': {
        backgroundImage: `url(${LeftArrow})`,
        height: '100px',
        width: '50px',
        left: '0px',
        borderBottomRightRadius: '25px',
        borderTopRightRadius: '25px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '0',
        backgroundSize: '30px',
        backgroundColor: '#fff',
        [theme.breakpoints.down( 'xs' )]: {
          left: '-110px',
        },
        '&:hover,  &:focus': {
          backgroundImage: `url(${LeftArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '0',
          backgroundSize: '30px',
          backgroundColor: '#fffff',
        },
        '&::before': {
          display: 'none',
        },
      },

    },
    '& h3': {
      fontFamily: 'MuseoSans-500',
      fontSize: '30px',
      position: 'relative',
      '& img': {
        display: 'inline-block',
        position: 'absolute',
        left: '-65px',
        [theme.breakpoints.down( 'xs' )]: {
          width: '20px',
          left: '-30px',
        },
      },
    },
    '& p': {
      fontFamily: 'MuseoSans-300',
      fontSize: '24px',
      paddingBottom: '30px',
      marginBottom: '10px',
    },
    '& h5': {
      fontFamily: 'MuseoSans-500',
      fontSize: '18px',
      textTransform: 'uppercase',
    },
    '& figure img': {
      width: '175px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& > div': {
        display: 'block',
      },
      '& li': {
        top: '11px',
      },
      '& h3': {
        fontSize: '20px',
      },
      '& p': {
        fontSize: '14px',
      },
      '& h5': {
        fontSize: '14px',
      },
      '& figure img': {
        margin: '0 auto',
      },
    },
  },
  tstimonialContent: {
    padding: '30px 60px 80px',
    width: 'calc(100% - 130px)',
    '& h1': {
      fontSize: '60px',
      color: theme.palette.common.white,
      marginTop: '0px',
      marginBottom: '20px',
      fontFamily: 'MuseoSans-300',
      paddingTop: '20px',
      fontWeight: '500',
    },
  },
  libraryPopup: {
    position: 'relative',
    maxWidth: '100%',
    padding: '0px !important',
    boxSizing: 'border-box',
  },
  libraryModalPopup: {
    overflow: 'auto',
    '& > div + div + div': {
      alignItems: 'flex-start',
    },
    '& > div > div': {
      maxWidth: '80%',
      width: '80%',
      marginTop: '70px',
      maxHeight: 'inherit',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
    },
  },
  popupClose: {
    zIndex: '99',
    float: 'none',
    position: 'absolute',
    right: '10px',
    top: '10px',
    opacity: '1',
    cursor: 'pointer',
    '& span': {
      color: theme.palette.common.white,
      fontWeight: 'normal',
      fontSize: '21px',
    },
  },
  sliderContent: {
    boxSizing: 'border-box',
    '& h2': {
      fontSize: '44px',
      marginBottom: '35px',
      marginTop: '10px',
      color: theme.palette.common.white,
      fontWeight: '500',
    },
    '& ul': {
      padding: '0 0 0 20px',
      marginBottom: '10px',
      marginTop: '0px',
      '& li': {
        color: theme.palette.common.white,
        fontSize: '20px',
        textAlign: 'left',
        listStyleType: 'disc',
        lineHeight: '1.42857143',
      },
    },
  },
  sliderImage: {
    border: '2px solid #ffffff',
    maxWidth: '425px',
    width: '100%',
    boxsizing: 'border-box',
    float: 'right',
    '& img': {
      width: '100%',
    },
  },
  startYear: {
    backgroundColor: '#F7941E',
    float: 'left',
    fontSize: '28px',
    marginTop: '45px',
    maxWidth: '424px',
    width: '100%',
    textTransform: 'uppercase',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
    padding: '12px 22px',
    borderRadius: '6px',
    border: '1px solid #fff',
    lineHeight: 'normal',
    textDecoration: 'none',
    textAlign: 'center',
    color: theme.palette.common.white,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  greenBtn: {
    backgroundColor: '#83A947',
    float: 'left',
    fontSize: '28px',
    marginTop: '45px',
    maxWidth: '424px',
    width: '424px',
    textTransform: 'uppercase',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
    padding: '12px 22px',
    borderRadius: '6px',
    border: '1px solid #fff',
    lineHeight: 'normal',
    textDecoration: 'none',
    textAlign: 'center',
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
  blueBtn: {
    backgroundColor: '#509BCB',
    float: 'left',
    fontSize: '28px',
    marginTop: '45px',
    maxWidth: '424px',
    width: '100%',
    textTransform: 'uppercase',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
    padding: '12px 22px',
    borderRadius: '6px',
    border: '1px solid #fff',
    lineHeight: 'normal',
    textDecoration: 'none',
    textAlign: 'center',
    color: theme.palette.common.white,
    cursor: 'pointer',
  },
  eventTwoColor: {
    background: 'linear-gradient(to bottom, #cab488 0%, #cab487 100%)',
  },
  eventOneColor: {
    background: 'linear-gradient(to bottom, #0a6446 0%, #0a6445 100%)',
  },
  eventThreeColor: {
    background: 'linear-gradient(to bottom, #84aa46 0%, #84aa45 100%)',
  },
  eventFourColor: {
    background: 'linear-gradient(to bottom, #3368ac 0%, #3367ac 100%)',
  },
  eventFiveColor: {
    background: 'linear-gradient(to bottom, #4b95e2 0%, #4b95e3 100%)',
  },
  eventSixColor: {
    background: 'linear-gradient(to bottom, #4a9c70 0%, #4a9c69 100%)',
  },
  eventSevenColor: {
    background: 'linear-gradient(to bottom, #6cc8e4 0%, #6cc8e5 100%)',
  },
  eventEightColor: {
    background: 'linear-gradient(to bottom, #cea440 0%, #cea430 100%)',
  },
  eventNineColor: {
    background: 'linear-gradient(to bottom,  #4b9aac 0%, #4b9aab 100%)',
  },
  eventTenColor: {
    background: 'linear-gradient(to bottom,  #c14722 0%, #c14720 100%)',
  },
  eventElevenColor: {
    background: 'linear-gradient(to bottom,  #ed9421 0%, #ed9420 100%)',
  },
  eventTwelveColor: {
    background: 'linear-gradient(to bottom,  #e96e2a 0%, #e96e2b 100%)',
  },
  eventThirteenColor: {
    background: 'linear-gradient(to bottom,  #3368ac 0%, #3368ab 100%)',
  },
  eventFourteenColor: {
    background: 'linear-gradient(to bottom,  #8eae7f 0%, #8eae7e 100%)',
  },
  eventFifteenColor: {
    background: 'linear-gradient(to bottom,  #878fb9 0%, #878fb8 100%)',
  },
  getStartedPopupDialog: {

    '& > div > div': {
      width: '100%',
      maxWidth: '1000px',
      maxHeight: 'inherit',
      '@media (min-width: 1500px)': {
        height: 'auto',
      },
    },
    '& > div > div > div': {
      padding: '0',
      '&:first-child': {
        paddingTop: '0',
      },
    },
  },
  popupMainLifeEvent: {
    height: '100%',
  },
  containerNew: {
    paddingLeft: '0',
  },
  additionalCareAdvanceTittle: {
    fontSize: '60px !important',
  },
} );

export default styles;
