import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-investments/styles';
import * as modulesServices from '../../calculations/modules-services';

const CharitableContributionSummary = ( props ) => {
  const {
    classes, getInvestmentObject,
  } = props;

  return (
    <div className={ classes.contentBlock }>
      <div className={ classes.charitableTable }>
        <table className={ classes.contributionTable }>
          <thead>
            <tr className={ classes.trHead }>
              <th colSpan="2">Charitable Contributions Summary</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Annual Contribution in Year
                {' '}
                {' '}
                {getInvestmentObject.charitable_start_year}
              </td>
              <td>
                {modulesServices.module9Data.charitableAnnualContribution !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module9Data.charitableAnnualContribution } prefix={ modulesServices.module9Data.charitableAnnualContribution >= 0 ? '$' : '($' } suffix={ modulesServices.module9Data.charitableAnnualContribution < 0 && ')' } /> ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td className={ classes.leftPadding }>
                Total Contributions After
                {' '}
                {' '}
                {getInvestmentObject.charitable_end_year - getInvestmentObject.charitable_start_year + 1}
                {' '}
                Years
              </td>
              <td>
                {modulesServices.module9Data.charitableTotalContribution !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module9Data.charitableTotalContribution } prefix={ modulesServices.module9Data.charitableTotalContribution >= 0 ? '$' : '($' } suffix={ modulesServices.module9Data.charitableTotalContribution < 0 && ')' } /> ) : ( '$0' )}

              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr className={ classes.trFoot }>
              <td>Estimated Tax Savings at 20% Tax Rate</td>
              <td>
             
                {modulesServices.module9Data.charitableTaxSavings !== undefined ? (
                  <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module9Data.charitableTaxSavings } prefix={ modulesServices.module9Data.charitableTaxSavings >= 0 ? '$' : '($' } suffix={ modulesServices.module9Data.charitableTaxSavings < 0 && ')' } /> ) : ( '$0' )}
                  
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

CharitableContributionSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,

};

export default withStyles( styles )( CharitableContributionSummary );
