import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import Pageloader from '../../../../../components/ui/pageloader';
import styles from './styles';
import eachQuestionXp, { xpPoints, getTotalTime, getUserXPRank } from '../../pf101-service';
import Pf101Popups from '../../popups';
import headerLogo from '../../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import book from '../../../../../assets/img/pf101/icon-book-blue.png';

const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );

/* eslint react/prop-types: 0 */
const startTime = new Date();
const MobileBanking = ( props ) => {
  const { classes, history } = props;
  const defaultCorrectAnswer = ['3', '4', '2', '1', '1'];
  const defaultQuestionList = [];

  for ( let i = 0; i <= 4; i += 1 ) {
    defaultQuestionList.push( {
      id: i + 1,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: defaultCorrectAnswer[i],
      status: '',
      answer: '',
    } );
  }

  let bodySection = useRef( null );

  const [loading, setLoading] = React.useState( true );
  const [activeSlide, setActiveSlide] = React.useState( 1 );
  const [questionList, setQuestionList] = React.useState( defaultQuestionList );
  const [progress, setProgress] = React.useState( 1 );
  const [questionProgressData, setQuestionProgressData] = React.useState( {
    questions_progress_val: eachQuestionXp, questions_progress: 100, total_progress: 0, total_progress_val: 0,
  } );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  useQuery( GET_EXERCISE, {
    variables: {
      level: 5,
      exercise: 2,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questions = [...questionList];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questions[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questions[data.question_id - 1].xp_earned = xpPoints( questions[data.question_id - 1].question_attempts );
          questions[data.question_id - 1].status = 'C';
          questions[data.question_id - 1].answer = questions[data.question_id - 1].correct_answer;
        } );
        setQuestionList( questions );
        setExerciseStatus( 'review' );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const backToDashBoard = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Back to Dashboard</h3>
            <p>If you go back to the Dashboard, your progress will be lost. Are you sure you want to exit the activity and go back to the Dashboard?</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => { onClose(); history.push( '/pf101/dashboard' ); } }
            >
              CONTINUE
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  useEffect( () => {
    const questionsData = questionList;

    scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } );

    setProgress( parseFloat( ( 100 / questionsData.length ) * activeSlide ) );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questionsData[activeSlide - 1].xp_earned;
    progressData.questions_progress = ( questionsData[activeSlide - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questionsData, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( questionsData.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
    // eslint-disable-next-line
  }, [activeSlide, exerciseStatus] );

  const answerQuestion = ( e ) => {
    const questions = [...questionList];
    questions[activeSlide - 1].answer = e.target.value;
    questions[activeSlide - 1].status = '';
    setQuestionList( questions );
  };

  const checkAnswer = ( question ) => {
    const questions = [...questionList];
    if ( questions[question - 1].answer === questions[question - 1].correct_answer ) {
      questions[question - 1].status = 'C';
    } else {
      questions[question - 1].status = 'W';
      questions[question - 1].question_attempts = questions[question - 1].question_attempts + 1;
    }
    questions[question - 1].xp_earned = xpPoints( questions[question - 1].question_attempts );
    setQuestionList( questions );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questions[question - 1].xp_earned;
    progressData.questions_progress = ( questions[question - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questions, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( defaultQuestionList.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
  };

  const finishActivity = () => {
    if ( exerciseStatus === 'review' ) {
      history.push( '/pf101/dashboard' );
      return false;
    }
    setLoading( true );
    const popupValue = {};

    const totalTime = getTotalTime( startTime );
    let totalPoints = _.sumBy( questionList, 'xp_earned' );
    const totalAttempts = _.sumBy( questionList, 'question_attempts' );
    const questionData = questionList.map( ( questions ) => _.pick( questions, ['id', 'question_attempts', 'xp_earned'] ) );

    popupValue.exercisePoints = totalPoints;

    // Adding 50 points for all questions answered single attempt
    if ( totalAttempts === defaultQuestionList.length ) {
      totalPoints += 50;
      popupValue.precision = true;
      popupValue.precisionPoints = 50;
    } else {
      popupValue.precision = false;
      popupValue.precisionPoints = 0;
    }

    popupValue.level = false;
    popupValue.levelPoints = 0;
    popupValue.levelNumber = 1;

    const presentRank = getUserXPRank( pf101Points );
    const nextRank = getUserXPRank( pf101Points + totalPoints );
    if ( presentRank !== nextRank ) {
      popupValue.rankChangeStatus = true;
      popupValue.rank = nextRank;
    } else {
      popupValue.rankChangeStatus = false;
      popupValue.rank = '';
    }

    setPopupDetails( popupValue );

    saveExercise( {
      variables: {
        data: {
          level: 5,
          exercise: 2,
          exercise_time: totalTime,
          points: totalPoints,
          total_attempts: totalAttempts,
          questionData,
        },
      },
    } );
    return true;
  };

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.pfHeader }>
        <div className={ classes.container }>
          <div className={ classes.pfHeaderInner }>
            <h3>
              <span className={ classes.breadcrumbTitle }>Level 5:</span>
              {' '}
              Online + Mobile Banking
            </h3>
            <Button onClick={ () => { backToDashBoard(); } }>
              <img src={ headerLogo } alt="" />
            </Button>
          </div>
        </div>
      </div>
      <div className={ classes.pfContent }>
        <div className={ classes.stepOne }>
          <div className={ classes.pfContentHeader }>
            <div>
              <h3>
                <span>Activity:</span>
                {' '}
                Mobile Banking (
                {activeSlide}
                {' '}
                of
                {' '}
                {defaultQuestionList.length}
                )
              </h3>
            </div>
            <Link to="/pf101/level5/mobile-banking" target="_blank">
              <figure>
                <img
                  src={ book }
                  alt="infoicon"
                  data-for="infoLeftIcon"
                  data-tip="Click here to open the activity's reading in a new tab."
                  data-offset="{'left':10}"
                />
                <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
              </figure>
            </Link>

          </div>
          <div className={ classes.ProgressBar }>
            <div className={ classes.ProgressBarInner } style={ { width: `${progress}%` } } />
          </div>
          { activeSlide === 1 && (
            <div className={ classes.contentBlock }>
              <div className={ classes.grayBox }>
                <h3>
                  To complete this activity, answer the following questions. Each question is worth
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP.</span>
                  {' '}
                  If you get the question right on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP</span>
                  . On the second try,
                  {' '}
                  <span className={ classes.bonusXp }>+15 XP</span>
                  . Third try,
                  {' '}
                  <span className={ classes.bonusXp }>+12 XP</span>
                  . And fourth try,
                  {' '}
                  <span className={ classes.bonusXp }>+10 XP</span>
                  .
                </h3>
                <h3>
                  <span className={ classes.bonusXp }>BONUS XP:</span>
                  {' '}
                  If you answer all questions correctly on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+50 XP</span>
                  {' '}
                  at the end of the activity. Good luck!
                </h3>
                <h3>A green check mark will appear once you answer correctly and the Next button will turn blue once you&apos;ve answered all correctly.</h3>
              </div>
              <h3>Mary and her friends went out to dinner. Mary’s friend, Jade, paid and all the others including Mary used Venmo to pay Jade back.</h3>
              <h3>Venmo is an example of a ________.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Digital wallet
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Mobile bank
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        Person-to-Person (P2P) payment
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        Cash advance
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 2 && (
            <div className={ classes.contentBlock }>
              <h3>Jade transferred the funds from Venmo to her checking account after dinner last night. She woke up the next morning and used her mobile banking app to review her checking account activity and was surprised when she didn&apos;t see the money from Venmo. What happened?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Venmo transfers usually aren&apos;t processed overnight
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Jade did not pay the extra fee to process her Venmo transfer immediately
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        P2P payments are fast, but usually take 1-3 business days to process
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        All of the above
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 3 && (
            <div className={ classes.contentBlock }>
              <h3>Mary&apos;s portion of the bill from dinner was $40. She used Venmo to send Jade $40. She reviews her checking account via her mobile banking app the next day and sees that $40.80 was moved from her account.</h3>
              <h3>Why was $40.80 moved instead of $40? All P2P payments are free, right?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Sales tax was added to her Venmo
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Mary links her credit card to Venmo and Venmo charges 2-3% on credit card transactions
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        There&apos;s no explanation for this and Mary should call Venmo immediately
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        Venmo charges you an $0.80 fee every time you send money
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 4 && (
            <div className={ classes.contentBlock }>
              <h3>The woman in front of you at the coffee shop didn&apos;t swipe her credit card or pay cash to pay for her muffin and caramel latte. You&apos;re wondering, &quot;What type of magic did she use to pay for her food and drink?&quot; Then, you remember your Personal Finance 101 training.</h3>
              <h3>The woman in front of you at the coffee shop used a different way to pay for day-to-day expenses. You don&apos;t need to have cash, your debit card, or your credit card on you to pay for stuff anymore. With a ________, all you need is your phone.</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Digital wallet
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Mobile bank
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        Person-to-Person (P2P) payment
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        Cash advance
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
          { activeSlide === 5 && (
            <div className={ classes.contentBlock }>
              <h3>What is the biggest barrier to mobile payments taking over regular payments such as swiping your credit card?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classNames( classes.exerciseOptions, classes.optionsFullWidth ) }>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '1' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="firstOption">
                        Most people still carry a wallet with a credit card
                        <input type="radio" name="equation" value="1" id="firstOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === '2' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="secondOption">
                        Mobile payments are less secure than regular payments
                        <input type="radio" name="equation" value="2" id="secondOption" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '3' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="thirdOption">
                        There are too many kinds of mobile payments
                        <input type="radio" name="equation" value="3" disabled={ questionList[activeSlide - 1].status === 'C' } id="thirdOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.fullRadio, classes.spanRadio, questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="fourthOption">
                        Restaurants refuse to accept mobile payments
                        <input type="radio" name="equation" value="4" disabled={ questionList[activeSlide - 1].status === 'C' } id="fourthOption" onChange={ ( e ) => answerQuestion( e ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
        <div className={ classes.answerStatus }>
          {questionList[activeSlide - 1].status === 'C' && (
          <h2 className={ classes.correctAnswer }>
            Correct! You earned +
            {questionList[activeSlide - 1].xp_earned}
            {' '}
            XP.
          </h2>
          )}
          {questionList[activeSlide - 1].status === 'W' && <h2 className={ classes.wrongAnswer }>Incorrect. Try again!</h2>}
        </div>
        <div className={ classes.btnGroup }>
          {activeSlide !== 1 && <Button className={ classNames( classes.manageButton, classes.backButton ) } onClick={ () => { setActiveSlide( activeSlide - 1 ); } }>Back</Button>}
          <div className={ classes.progressXp }>
            <div className={ classes.questionXp }>
              <p>
                <span>Question XP:</span>
                {' '}
                +
                {questionList[activeSlide - 1].xp_earned}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.questions_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp}
                {' '}
                XP
              </span>
            </div>
            <div className={ classNames( classes.questionXp, classes.totalQuestion ) }>
              <p>
                <span>Total XP:</span>
                {' '}
                +
                {questionProgressData.total_progress_val}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.total_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp * defaultQuestionList.length}
                {' '}
                XP
              </span>
            </div>
          </div>
          <Button className={ `${classes.manageButton} ${classes.checkAnswer}` } disabled={ questionList[activeSlide - 1].answer === '' } onClick={ () => checkAnswer( activeSlide ) }>Check Answer</Button>
          {defaultQuestionList.length === activeSlide ? (
            <Button className={ classes.manageButton } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => finishActivity() }>FINISH</Button>
          ) : (
            <Button className={ classes.manageButton } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => { setActiveSlide( activeSlide + 1 ); } }>Next</Button>
          )}

        </div>
      </div>
      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}
    </Typography>
  );
};

MobileBanking.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( MobileBanking );
