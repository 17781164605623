import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruTwo = ( props ) => {
  const {
    classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, moduleName,
  } = props;

  return (
    <div className={ ( moduleName === 'fi' || moduleName === 'invest' )
      ? classNames( classes.pwiNextWalkthru, classes.pwiWalkthru, classes.pwiNextWalkthru, classes.pwiActiveWalkthru )
      : classNames( classes.pwiWalkthru, classes.pwiActiveWalkthru, ( moduleName === 'cp' || moduleName === 'aca' || moduleName === 'ca' || moduleName === 'fca' || moduleName === 'car' ) && classes.pwiCpWalkThru,
        ( moduleName !== 'cp' && moduleName !== 'aca' && moduleName !== 'ca' && moduleName !== 'fca' && moduleName !== 'car' ) && classes.pwiWalkthruTwo ) }
    >
      <h4>Step-by-Step Guidance</h4>
      <p>
        Plan With Inkwiry demystifies the planning process by giving you the information you need, right when you need it. Click NEXT and BACK to navigate page by page.
        Read the content, build your financial knowledge and a plan at the same time, and keep moving forward toward your goals.
      </p>
      <div className={ classes.walkthruBottom }>
        <WalkThruDots completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } handleWalkThrus={ handleWalkThrus } />
        <div className={ classes.walkThruBtns }>
          <Button className={ classes.startWalkThru } onClick={ () => { handleWalkThrus( 2 ); } }>Next</Button>
          <Button className={ classes.closeWalkThru } onClick={ handleWalkThruClose }>Close</Button>
        </div>
      </div>
    </div>
  );
};

WalkThruTwo.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWalkThrus: PropTypes.func.isRequired,
  handleWalkThruClose: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  activeWalkThruSlide: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
};

export default withStyles( styles )( WalkThruTwo );
