const data = [
  {
    title: "Assumptions",
    children: [
      {
        label: 'Annual Income Growth',
        type: 'ratio',
        prefix: '',
        suffix: "%",
        key: "long_term_growth_in_percentage"
      },
      {
        label: 'Annual Inflation',
        type: 'ratio',
        prefix: '',
        suffix: "%",
        key: "inflation_in_percentage"
      }
    ]
  },
  // {
  //   title: "Current Financial Situation",
  //   children: [
  //     {
  //       label: 'Current Cash Balance',
  //       type: 'number',
  //       prefix: '$',
  //       suffix: "",
  //       key: "current_cash_balance"
  //     },{
  //       label: 'Max Cash Balance before Investment',
  //       type: 'number',
  //       prefix: '$',
  //       suffix: "",
  //       key: "max_cash_balance"
  //     },
  //   ]
  // }
]; 

export default data;