import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const MarketCapitalization = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Market Capitalization</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/market-cap.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Market Capitalization
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/market-cap-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Market capitalization, or more commonly &quot;market
                          cap,&quot; is an especially useful concept to
                          understand when it comes to investing and is easy to
                          calculate. The formula for market cap:
                        </p>
                        <p className={classes.sharePrice}>
                          Share Price x{' '}
                          <a
                            href="https://www.thebalance.com/stock-market-capitalization-101-357337"
                            target="_blank"
                            rel="noopener noreferrer">
                            Total Shares Outstanding
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Market cap refers to the total dollar value of a
                          company&apos;s shares of stock. For example, Microsoft
                          has{' '}
                          <a
                            href="https://finance.yahoo.com/quote/msft/key-statistics?ltr=1"
                            target="_blank"
                            rel="noopener noreferrer">
                            7.58B shares outstanding and the price of one share
                            is $183.51
                          </a>{' '}
                          as of this newsletter (5/25/20). Microsoft&apos;s
                          current market cap can be calculated as follows:
                        </p>
                        <p>7,580,000,000 x $183.51 = $1,391,005,800,000</p>
                        <p>
                          Yes, you read that right. One trillion, three hundred
                          and ninety-one billion, five million, and eight
                          hundred thousand dollars. But what does that even mean
                          and why is market cap important?
                        </p>
                        <p>
                          Market cap allows you to understand the relative size
                          of one company compared to another. For example,
                          let&apos;s look at the market cap of another company -
                          Yeti Holdings. Yeti has 86.9 million shares
                          outstanding and the price of one share is $30.44 as of
                          this newsletter (5/25/20). Yeti&apos;s current market
                          cap can be calculated as follows:
                        </p>
                        <p>86,900,000 x $30.44 = $2,645,236,000</p>
                        <p className={classes.noBottomMargin}>
                          Microsoft&apos;s market cap is almost $1.4 trillion.
                          Yeti&apos;s market cap is $2.6 billion. The value of
                          Microsoft is more than 500x the value of Yeti
                          Holdings. There are three levels of market cap:
                        </p>
                        <div className={classes.companiesMarket}>
                          <p>
                            1) Large cap: dominant, large, and longstanding
                            companies with a market
                            <span>
                              value greater than $10 billion and typically the
                              least risky
                            </span>
                          </p>
                          <p>
                            2) Mid cap: established companies that are
                            successful and could grow rapidly
                            <span>
                              in the near future with a market value between $2
                              billion and $10 billion
                            </span>
                          </p>
                          <p>
                            3) Small cap: smaller companies with a market value
                            between $300 million
                            <span>
                              and $2 billion and typically the most risky of the
                              group
                            </span>
                          </p>
                        </div>
                        <p>
                          Check the market cap of Microsoft and Yeti Holdings
                          today. Has the market cap gone up or down? If the
                          market cap has gone up, what does that tell you about
                          how investors feel about that stock? What if it went
                          down?
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
MarketCapitalization.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MarketCapitalization);
