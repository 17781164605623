import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';

const startTime = new Date();
const FundsAlternativeInvestments = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>Funds and Alternative Investments</h3>
                    </div>
                    <div className={ classes.pfVideo }>
                      <YouTube videoId="LU8tubkz_Fg" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/funds-alternative-investments.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>Funds and Alternative Investments</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/funds-alternative-inv-300px.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            Other than stocks, bonds, savings accounts, and cash, there are many different investments you can make. Rather than picking individual stocks and bonds, you can invest in mutual funds or exchange-traded funds.
                          </p>
                          <p>
                            Think of funds as a basket. In that basket can be a mix of stocks, bonds and alternative investments. You invest in the basket rather than one stock or one bond to lower your risk while gaining exposure to many different investments. This is called
                            {' '}
                            <a href="https://inkwiry.com/finance-explained/diversification" className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" target="_blank">diversifying your investments</a>
                            {' '}
                            to lower your risk. Put simply, diversifying is the same as saying, &quot;don&apos;t put all your eggs in one basket.&quot;
                          </p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p className={ classes.noMarginBottom }>
                            <b>Mutual funds</b>
                            {' '}

                          </p>
                          <p className={ classes.noMargin }>
                            A mutual fund is a pool of money that is actively managed by a fund manager. The fund manager receives cash from investors and invests that cash into stocks, bonds, and other financial assets that hopefully earn a return. A fund&apos;s goal could be to take more risk looking for greater reward or
                            take very little risk and earn a lower, but more predictable return. Make sure you know the fund&apos;s goal before investing and the historical performance of the fund.
                          </p>
                          <p>The benefit of mutual funds is that they are actively managed by a professional. You don&apos;t have to spend time researching different stocks and bonds and managing your investments daily. The fund manager does this for you in exchange for a fee. Before you invest in any mutual fund, make sure you know the fees associated with that investment!</p>

                          <p className={ classes.noMarginBottom }>
                            <b>Exchange-traded funds (ETFs)</b>
                          </p>
                          <p className={ classes.noMargin }>ETFs are similar to mutual funds in that they are a basket of investments, but they are bought and sold like a stock. ETFs can be bought on a stock exchange, trade every second of every trading day, and change value by the second just like a stock. On the other hand, mutual funds can only be bought and sold at the end of each trading day and are bought and sold directly from the fund manager or from a financial institution.</p>

                          <p>
                            ETFs are diversified like a mutual fund and are easier to trade like a stock. Usually, ETFs have lower fees than mutual funds. The average ETF has a
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.napa-net.org/news-info/daily-news/mutual-fund-expense-ratios-continue-descent" target="_blank" rel="budget-fy2020 noopener noreferrer"> 0.20% expense ratio</a>
                            , which means you pay $2.00 in annual fees for every $1,000 you invest. The average actively managed mutual fund has a
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.napa-net.org/news-info/daily-news/mutual-fund-expense-ratios-continue-descent" target="_blank" rel="budget-fy2020 noopener noreferrer">0.76% expense ratio</a>
                            , or $7.60 in annual fees for every $1,000 you invest. Mutual funds usually require a minimum amount of money to invest and charge higher fees than ETFs because they are actively managed by an investment professional, which could lead to better returns.
                          </p>
                          <p className={ classes.noMarginBottom }>
                            <b>Alternative investments</b>
                          </p>
                          <p className={ classes.noMargin }>
                            Alternative investments include financial assets that are not stocks, bonds, or cash. Since alternative investments are not traditional assets, they tend to be more complex and are riskier. For example, investing in a hedge fund that trades oil and gas futures or placing a value on and selling a
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.motor1.com/news/29456/rare-1955-mercedes-benz-300-sl-alloy-gullwing-sells-for-462m/" target="_blank" rel="budget-fy2020 noopener noreferrer">1955 Mercedes 300SL Gullwing</a>
                            {' '}
                            or a
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.goodhousekeeping.com/life/money/g3844/most-expensive-barbies-ever/" target="_blank" rel="budget-fy2020 noopener noreferrer">1999 De Beers 40th Anniversary Barbie</a>
                            {' '}
                            would be difficult for the average investor. Alternative investments are harder to buy and sell and trade much less often than stocks, bonds, or funds.
                          </p>
                          <div className={ classes.noMarginBottom }>Some alternative investments are:</div>
                          <ul className={ classes.noMargin }>
                            <li>Real estate</li>
                            <li>Hedge funds</li>
                            <li>Private equity funds</li>
                            <li>Commodities such as gold, coffee, or crude oil</li>
                            <li>Art, antiques, and collectibles</li>
                          </ul>
                          <p>Alternative investments can have high rewards and help diversify your investments. But they usually require large minimum investments, charge much higher fees, and can be very complex for the average investor. Make sure you do your research or consult professional guidance before considering alternative investments.</p>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level7/funds-alternative-investments/exercise" startTime={ startTime } level={ 7 } exercise={ 3 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
FundsAlternativeInvestments.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( FundsAlternativeInvestments );
