import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const FederalIncomeTaxBrackets = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Federal Income Tax Brackets</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/tax-brackets.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Federal Income Tax Brackets
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/tax-brackets-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Federal income tax brackets determine how much you pay
                          in taxes to the government in any given year.
                          Typically, each year, these tax brackets are adjusted
                          for{' '}
                          <Link
                            to="/finance-explained?type=definitions"
                            target="_blank">
                            inflation
                          </Link>
                          .
                        </p>
                        <p>
                          Use Inkwiry&apos;s{' '}
                          <Link to="/financial-calculators/federal-income-tax">
                            Federal Income Tax Calculator
                          </Link>{' '}
                          to see how much you might pay in taxes for 2020. While
                          this tax calculator doesn&apos;t take into account all
                          the different scenarios that could affect your taxes,
                          it gives you a good estimate.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          If you are single, the 2020 federal income tax
                          brackets were updated as follows:
                        </p>
                        <Table className={classes.federalTable}>
                          <thead>
                            <tr>
                              <th>Rate</th>
                              <th colSpan="2">Income Ranges (Single)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>10.00%</td>
                              <td>$0.00</td>
                              <td>$9,875.00</td>
                            </tr>
                            <tr>
                              <td>12.00%</td>
                              <td>$9,875.00</td>
                              <td>$40,125.00</td>
                            </tr>
                            <tr>
                              <td>22.00%</td>
                              <td>$40,125.00</td>
                              <td>$85,525.00</td>
                            </tr>
                            <tr>
                              <td>24.00%</td>
                              <td>$85,525.00</td>
                              <td>$163,300.00</td>
                            </tr>
                            <tr>
                              <td>32.00%</td>
                              <td>$163,300.00</td>
                              <td>$207,350.00</td>
                            </tr>
                            <tr>
                              <td>35.00%</td>
                              <td>$207,350.00</td>
                              <td>$518,400.00</td>
                            </tr>
                            <tr>
                              <td>37.00%</td>
                              <td>$518,400.00</td>
                              <td />
                            </tr>
                          </tbody>
                        </Table>
                        <p>If you are married, the brackets change:</p>
                        <Table className={classes.federalTable}>
                          <thead>
                            <tr>
                              <th>Rate</th>
                              <th colSpan="2">Income Ranges (Married)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>10.00%</td>
                              <td>$0.00</td>
                              <td>$19,750.00</td>
                            </tr>
                            <tr>
                              <td>12.00%</td>
                              <td>$19,750.00</td>
                              <td>$80,250.00</td>
                            </tr>
                            <tr>
                              <td>22.00%</td>
                              <td>$80,250.00</td>
                              <td>$171,050.00</td>
                            </tr>
                            <tr>
                              <td>24.00%</td>
                              <td>$171,050.00</td>
                              <td>$326,600.00</td>
                            </tr>
                            <tr>
                              <td>32.00%</td>
                              <td>$326,600.00</td>
                              <td>$414,700.00</td>
                            </tr>
                            <tr>
                              <td>35.00%</td>
                              <td>$414,700.00</td>
                              <td>$622,050.00</td>
                            </tr>
                            <tr>
                              <td>37.00%</td>
                              <td>$622,050.00</td>
                              <td />
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          How do the brackets actually work? Check out this
                          simplified example of a single $60,000 earner filing
                          their taxes for 2020.
                        </p>
                        <div className={classes.pfImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/tax-bracets-calculator.png"
                            alt=""
                          />
                          <p>
                            As you can see in this example, your income is not
                            taxed at one rate. You should be asking why, if you
                            make the same amount, your taxes would be lower next
                            year than this year. That&apos;s a good question.
                          </p>
                          <p>
                            The income tax brackets generally increase each year
                            for inflation. Inflation is a financial way of
                            saying the cost of goods such as food, clothing,
                            cars and services get a little more expensive each
                            year. If you make $60,000 in 2019 and $60,000 in
                            2020, your taxes in 2020 will be lower to offset the
                            increased cost of goods and services.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FederalIncomeTaxBrackets.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FederalIncomeTaxBrackets);
