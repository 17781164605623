import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const KeepingUpWithInflation = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Keeping Up With Inflation </li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />

                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/inflation-keeping-up.png "
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Keeping Up With Inflation
                    </h3>
                  </div>

                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/keep-up-with-inflation.jpg "
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          If you missed last week&apos;s Finance Explained, you
                          also missed inflation climbing to a 39-year record of
                          6.8% over the last 12 months. Alarming? Yes. Even more
                          alarming would be not understanding the impact
                          inflation has on your money. In part 2 of our series
                          on inflation, I'll explain how inflation impacts your
                          purchasing power. How do you know if your earnings are
                          keeping up with inflation?{' '}
                        </p>
                      </div>
                    </div>

                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          First, there are two ways to measure earnings growth -
                          nominal and real. Nominal wage growth is the amount
                          your earnings increase, typically expressed as a
                          percentage over the last 12 months. For example, last
                          year you earned $60,000. This year, you earned
                          $63,000. Your nominal wage growth is 5.0%. Easy
                          enough, right?
                        </p>
                        <p>
                          <div className={classes.pfRowGrayBox}>
                            <p>Year 1: $60,000 earnings</p>
                            <p>Year 2: $63,000 earnings</p>
                            <p>Difference: +$3,000</p>
                            <p>
                              Difference / Year 1 * 100 = 5.0% nominal growth
                            </p>
                          </div>
                        </p>
                        <p>
                          But what if inflation year-over-year for the same
                          period was 4.5%? Did you keep up with inflation?
                          Meaning, can you purchase more goods and services than
                          you could last year? What was your real earnings
                          growth?
                        </p>
                        <p>
                          Real earnings growth is the amount your earnings
                          increased, just like nominal, but takes into account
                          inflation. In the above example, your real earnings
                          would be 5.0% minus the 4.5% inflation rate for a 0.5%
                          real wage growth. In this example, your wages outpaced
                          inflation. Bravo ... but did you keep up with
                          inflation?
                        </p>
                        <p>
                          Think about it for a second. When you buy goods and
                          services, you pay with after-tax dollars. When you buy
                          groceries each week, you spend $150 of your income
                          after taxes are automatically deducted. When you buy
                          gas, a new car, a phone, or pay for a service, you
                          spend after-tax dollars. To know if your purchasing
                          power increased year-over-year, we need to account not
                          only for inflation but also for taxes.
                        </p>
                        <p>
                          Continuing with the above example, you earned 5.0%
                          more. Let&apos;s assume your marginal tax rate is 25%.
                          Marginal is fancy finance language for saying if you
                          earn one more dollar, what rate would that dollar be
                          taxed at? Did you keep up with inflation?
                        </p>
                        <p>
                          <div className={classes.pfRowGrayBox}>
                            <p>
                              <span>Nominal Wage Growth: </span>
                              <span>5.0%</span>
                            </p>
                            <p>
                              <span>Marginal Tax Rate: </span>
                              <span>25.0%</span>
                            </p>
                            <p>
                              <span>Wage Growth Net of Taxes:</span>
                              <span>
                                Nominal Wage Growth * (1 - Tax Rate) 5.0% * (1 -
                                25.0%)
                              </span>
                            </p>
                            <p>
                              <span>&nbsp;</span>
                              <span>3.75%</span>
                            </p>
                          </div>
                        </p>

                        <p>
                          Even though you had real positive wage growth, which
                          is great by the way, you did not keep up with
                          inflation. Your purchasing power decreased by 0.75% as
                          you can see below
                        </p>
                        <p>
                          <div className={classes.pfRowGrayBox}>
                            <p>
                              <span>Wage Growth Net of Taxes: </span>
                              <span>3.75%</span>
                            </p>
                            <p>
                              <span>Purchasing Power:</span>
                              <span>Wage Growth Net of Taxes - Inflation</span>
                            </p>
                            <p>
                              <span>Purchasing Power:</span>
                              <span>3.75% - 4.50%</span>
                            </p>
                            <p>
                              <span>Purchasing Power: </span>
                              <span className={classes.textRed}>-0.75%</span>
                            </p>
                          </div>
                        </p>
                        <p>
                          The next time someone talks about inflation and
                          purchasing power, you know the true analysis to
                          determine your real earnings growth and if you're
                          keeping up with inflation
                        </p>
                      </div>
                    </div>

                    <div className={classes.hitTapRow}>
                      <h3>Just hit the tape</h3>
                      <ul>
                        <li>
                          {' '}
                          <a
                            href="https://awealthofcommonsense.com/2021/01/its-ok-to-build-wealth-slowly/"
                            target="_blank"
                            rel="noopener noreferrer">
                            It&apos;s OK to build wealth slowly
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://www.gq.com/story/get-fit-from-just-walking"
                            target="_blank"
                            rel="noopener noreferrer">
                            How fit can you get from walking?
                          </a>{' '}
                        </li>
                        <li>
                          {' '}
                          <a
                            href="https://taxfoundation.org/2022-tax-brackets/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Yes, the IRS adjusts federal income tax brackets
                            each year
                          </a>{' '}
                        </li>
                      </ul>
                      <p>
                        Get smarter about your money. Explore more financial
                        terms on our website in{' '}
                        <Link to="/finance-explained">Finance Explained.</Link>
                      </p>
                    </div>

                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>

                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
KeepingUpWithInflation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(KeepingUpWithInflation);
