import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import YouTube from 'react-youtube';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import seperatordots from '../../../../assets/img/pf101/dots.png';
import loanpayment from '../../../../assets/img/pf101/loan_payment.png';

const startTime = new Date();
const LoanRepayment = ( props ) => {
  const youtubeOpts = {
    host: 'https://www.youtube-nocookie.com',
    width: '640',
    height: '390',
    playerVars: {
      autoplay: 0,
      rel: '0',
      disablekb: '0',
      showinfo: '0',
      ccLoadPolicy: '0',
      ivLoadPolicy: '0',
      modestbranding: '1',
    },
  };

  let bodySection = useRef( null );

  const { classes, location } = props;
  const params = queryString.parse( location.search );

  const [section, setSection] = React.useState( ( params.type !== undefined && params.type === 'audio' ) ? 0 : 1 );
  const [buttonStatus, setButtonStatus] = React.useState( !( ( params.revisit !== undefined && params.revisit === 'yes' ) ) );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      <div className={ classes.containerFluid }>
        {section === 0 ? (
          <div className={ classes.videoPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>What&apos;s in a Loan Payment?</h3>
                    </div>
                    <div className={ classes.pfVideo }>
                      <YouTube videoId="88mQCgynwjk" opts={ youtubeOpts } onPlay={ () => { setButtonStatus( false ); } } />
                    </div>
                    <div className={ classes.kickStart }>
                      <p>
                        <span>Kickstart this activity with a short video! Once you&nbsp;</span>
                        <span>watch the video in full, click continue to keep going&nbsp;</span>
                        <span> on this activity.</span>
                      </p>
                    </div>
                    <div className={ classes.seperatorDots }>
                      <img src={ seperatordots } alt="logo" />
                    </div>
                    <Button className={ classes.continueButton } disabled={ buttonStatus } onClick={ () => { setSection( 1 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } }>Continue</Button>
                    <p className={ classes.accessLink }>
                      Can&apos;t access this video?&nbsp;
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 2 ); scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } ); } } className={ classes.continueLink }>Click here to continue.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={ classes.contentPage }>
            <div className={ classes.mainSection }>
              <div className={ classes.pfBudgetPage }>
                <div>
                  <ul className={ classes.breadCrumbs }>
                    <li>
                      <span>&lt;&nbsp;</span>
                      <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                    </li>
                    <li>
                      <span>&nbsp;&lt;&nbsp;</span>
                      <span role="button" aria-hidden="true" onClick={ () => { setSection( 0 ); } } className={ classes.breadCrumbVideoLink }>Video</span>
                    </li>
                  </ul>
                  <div className={ classes.activityRow }>
                    <div className={ classes.blueRow } />
                    <div className={ classes.logoBlock }>
                      <img src={ goldLogo } alt="logo" />
                    </div>
                    <div className={ classes.pfImage }>
                      <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/loan-payment.png" alt="template" />
                    </div>
                    <div className={ classes.pfHeading }>
                      <h3>What&apos;s in a Loan Payment?</h3>
                    </div>
                    <div className={ classes.pfContent }>
                      <div className={ classes.pfRow }>
                        <div className={ classes.pfSlideImage }>
                          <img src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/loan-payment-left.jpg" alt="template" />
                        </div>
                        <div className={ classes.pfText }>
                          <p className={ classes.coverAnything }>
                            Other than credit cards, most loans are amortized.
                            {' '}
                            <b>Amortization</b>
                            {' '}
                            {' '}
                            is the process of repaying your loans with fixed payments over a specific period of time. For example, mortgages are repaid by making the same payment each month typically over a 15- or 30-year period.
                          </p>
                          <p className={ classes.noBottomMargin }>A monthly loan payment is made up of two parts: 1) interest, and 2) principal repayment. When you borrow money, you typically repay the loan by making a monthly payment. The amount stays the same each month, but the interest and principal portion of the payment change over time.</p>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          <b>Key Terms: Principal and Interest</b>
                          <p className={ classes.noBottomMargin }>
                            <b>Principal</b>
                            {' '}
                            has two meanings when talking about loans:
                          </p>
                          <ol className={ classes.orderList }>
                            <li>the total sum of money originally borrowed. For example, if you borrow $20,000 of federal student loans during college, your principal would be $20,000.</li>
                            <li>the amount that you still owe on a loan. If you fast-forward five years into the future and repay $10,000 of your $20,000 federal student loans, your principal would then be $10,000.</li>
                          </ol>
                          <p>Principal is important to track and understand because the interest you pay each month is calculated based on your outstanding loan amount.</p>
                          <p>
                            <b>Interest</b>
                            {' '}
                            is the cost of borrowing money from a bank, person, or other entity. When you take out a loan, you agree to repay the loan amount plus interest. The amount of interest you pay depends on the interest rate, principal (loan amount), the term of the loan (time to repay), and how interest accrues on your loan. The best way to understand how interest is calculated is to view an example.
                          </p>
                          <p>Let&apos;s say you use $5,000 of your cash and borrow $25,000 to buy a $30,000 car with a four-year term (48 monthly payments), a 4% annual interest rate, and interest that accrues on a monthly basis. Given these loan terms, you would owe $564.48 each month. But what portion of that monthly payment is interest? How much does that $30,000 car actually cost?</p>
                        </p>
                      </div>
                      <div className={ classes.grayBox }>
                        <div className={ classes.pfRow }>
                          <div className={ classes.creditText }>
                            <p><b>Outstanding Loan Amount x Interest Rate ÷ 12 (to make the interest rate on a monthly basis) = Monthly Interest</b></p>
                            <p>$25,000 x 4% / 12 = $83.33 interest expense</p>
                          </div>
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <div className={ classes.creditText }>
                          <p>Interest is calculated as a percentage of the outstanding loan amount. Since the loan amount decreases with every monthly payment, the amount of interest you pay each month also decreases. As more time passes, more of each monthly payment goes toward repaying your loan balance and less goes toward interest.</p>
                          <p>
                            Check out the difference between the first month payment and the last month payment broken down by principal and interest for a $25,000 car loan with a four-year term and a 4% interest rate. The table below is an example of an
                            {' '}
                            <a className={ classes.anchorStyle } href="https://www.thebalance.com/how-amortization-works-315522" target="_blank" rel="budget-fy2020 noopener noreferrer"><b>Amortization Table.</b></a>
                          </p>
                        </div>
                      </div>
                      <div className={ classes.fiImage }>
                        <div className={ classes.lightGallery }>
                          <img src={ loanpayment } alt="table" />
                        </div>
                      </div>
                      <div className={ classes.pfRow }>
                        <p className={ classes.creditText }>
                          The actual cost of the car is $32,094. Why is the cost of the car more than $30,000? The car costs $30,000 plus the $2,094 of interest you have to pay for the car loan.
                        </p>
                      </div>
                      <div className={ classes.grayBox }>
                        <div className={ classes.pfRow }>
                          <div className={ classes.creditText }>
                            <p>Actual Cost of Buying an Asset = Total Price Paid + Total Interest Paid</p>
                            <p>In life, you will most likely take a few loans such as a mortgage to buy a house, a car loan to buy a car, or a student loan to attend higher education. Before you take those loans, make sure to review how you are going to repay that loan and remember these two important factors:</p>
                            <ul>
                              <li>Total interest paid is more important than the size of the monthly payment</li>
                              <li>A smaller monthly payment may look appetizing, but usually means that you will pay more interest and take longer to repay the loan</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <BottomContent />
                      <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level6/loan-repayment/exercise" startTime={ startTime } level={ 6 } exercise={ 5 } />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Typography>
  );
};
LoanRepayment.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( LoanRepayment );
