import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
// import ReactTooltip from 'react-tooltip';
// import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import * as moduleServices from '../../calculations/modules-services';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-car/styles';
import blueTick from '../../assets/img/pwi-existing/blue_circle_arrow.png'
const FirstCarTradeIn = ( props ) => {
  const {
    classes, handleBuyingCarObject, handleUpdatedObject,
  } = props;

  React.useEffect( () => {
    if(moduleServices.module18Data.existing_car_current_value < 0) handleBuyingCarObject.tradein_existing_car = "No";
    if(moduleServices.module18Data.existing_car_current_value_2 < 0) handleBuyingCarObject.tradein_existing_car_2 = "No";
    if(moduleServices.module18Data.existing_car_current_value_3 < 0) handleBuyingCarObject.tradein_existing_car_3 = "No";
    if(moduleServices.module18Data.existing_car_current_value_4 < 0) handleBuyingCarObject.tradein_existing_car_4 = "No";
  },[])

  const handleExistingType = (field) => {
    const updatedValue = {...handleBuyingCarObject};
    console.log("field",field)
    handleBuyingCarObject[field] === "No" ? updatedValue[field] = "Yes" :  updatedValue[field] = "No";
    handleUpdatedObject(updatedValue);
  }
  return (
    <div className={ classes.contentBlock }>
      <p>If you have an existing car, you can trade that car or multiple cars in when purchasing a new car. For example, if you own a car that's worth $15,000 and have a current loan balance of $5,000, that would be a $10,000 net trade-in value.</p>
      <p>Inkwiry estimates your trade-in value based on your Existing Assets + Liabilities module. Select which car or cars you plan on trading in for this purchase.</p>
      <p>
        Want to check the value of your car? Use
        {' '}
        {' '}
        <a href="https://www.kbb.com/whats-my-car-worth/" target="_blank" rel="noopener noreferrer">Kelley Blue Book</a>
      </p>
      <div className={classes.tradeInTableScroll}>
        <table className={classes.tradeInTable}> 
          <thead> 
            <tr> 
              <th>&nbsp;</th> 
              <th><u>Trade-In Values</u></th> 
            </tr> 
          </thead> 
          <tbody> 
            <tr> 
              <td onClick={ () => { moduleServices.module18Data.existing_car_current_value > 0 && handleExistingType("tradein_existing_car") } }> 
                <div className={classes.selectRetireAccount}> 
                  <span className={ classNames(moduleServices.module18Data.existing_car_current_value <= 0 ? `${classes.selectRetireAccountSpan} ${classes.cursorDisable}`: handleBuyingCarObject.tradein_existing_car === "Yes" ? classes.selectRetireAccountSpan : `${classes.selectRetireAccountSpan} ${classes.deselectRetireAccountSpan}`)}> Trade In Existing Car 1 {" "} 
                    <span>{ handleBuyingCarObject.tradein_existing_car === "Yes" && <img src={blueTick}  alt="Blue tick"/>}</span> 
                  </span> 
                </div> 
              </td> 
              <td> 
                <span>
                  {handleBuyingCarObject.tradein_existing_car === "Yes" ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue} prefix={moduleServices.module12Data.tradeInValue >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td> 
            </tr> 

            <tr> 
              <td onClick={ () => { moduleServices.module18Data.existing_car_current_value_2 > 0 && handleExistingType("tradein_existing_car_2") } }> 
                <div className={classes.selectRetireAccount}> 
                  <span className={ classNames( moduleServices.module18Data.existing_car_current_value_2 <= 0 ? `${classes.selectRetireAccountSpan} ${classes.cursorDisable}`: handleBuyingCarObject.tradein_existing_car_2 === "Yes" ? `${classes.selectRetireAccountSpan}`: `${classes.selectRetireAccountSpan} ${classes.deselectRetireAccountSpan}`)}> Trade In Existing Car 2 {" "} 
                    <span>{ handleBuyingCarObject.tradein_existing_car_2 === "Yes" && <img src={blueTick}  alt="Blue tick"/>}</span> 
                  </span> 
                </div> 
              </td> 
              <td> 
                <span>
                  {handleBuyingCarObject.tradein_existing_car_2 === "Yes" ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue2} prefix={moduleServices.module12Data.tradeInValue2 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue2 < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td> 
            </tr> 

            <tr> 
              <td onClick={ () => { moduleServices.module18Data.existing_car_current_value_3 > 0 && handleExistingType("tradein_existing_car_3") } }> 
                <div className={classes.selectRetireAccount}> 
                  <span className={ moduleServices.module18Data.existing_car_current_value_3 <= 0 ? `${classes.selectRetireAccountSpan} ${classes.cursorDisable}`: handleBuyingCarObject.tradein_existing_car_3 === "Yes" ? classes.selectRetireAccountSpan : `${classes.selectRetireAccountSpan} ${classes.deselectRetireAccountSpan}`}> Trade In Existing Car 3 {" "} 
                    <span>{ handleBuyingCarObject.tradein_existing_car_3 === "Yes" && <img src={blueTick}  alt="Blue tick"/>}</span> 
                  </span> 
                </div> 
              </td> 
              <td> 
                <span>
                  {handleBuyingCarObject.tradein_existing_car_3 === "Yes" ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue3} prefix={moduleServices.module12Data.tradeInValue3 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue3 < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td> 
            </tr> 

            <tr> 
              <td onClick={ () => { moduleServices.module18Data.existing_car_current_value_4 > 0 && handleExistingType("tradein_existing_car_4") } }> 
                <div className={classes.selectRetireAccount}> 
                  <span className={ moduleServices.module18Data.existing_car_current_value_4 <= 0 ? `${classes.selectRetireAccountSpan} ${classes.cursorDisable}`: handleBuyingCarObject.tradein_existing_car_4 === "Yes" ? classes.selectRetireAccountSpan : `${classes.selectRetireAccountSpan} ${classes.deselectRetireAccountSpan}`}> Trade In Existing Car 4 {" "} 
                    <span>{ handleBuyingCarObject.tradein_existing_car_4 === "Yes" && <img src={blueTick}  alt="Blue tick"/>}</span> 
                  </span> 
                </div> 
              </td> 
              <td> 
                <span>
                  {handleBuyingCarObject.tradein_existing_car_4 === "Yes" ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.tradeInValue4} prefix={moduleServices.module12Data.tradeInValue4 >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.tradeInValue4 < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td> 
            </tr> 

            <tr className={classes.textRightBold}> 
              <td>Total Trade-In Value</td> 
              <td>
                <span>$0</span>
              </td> 
            </tr> 
            <tr className={classes.textRightLight}> 
              <td> Adjusted Purchase Price </td> 
              <td>
                <span>
                  {moduleServices.module12Data.adjustedPurchasePrice !== undefined ? (
                    <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module12Data.adjustedPurchasePrice} prefix={moduleServices.module12Data.adjustedPurchasePrice >= 0 ? '$' : '($'} suffix={moduleServices.module12Data.adjustedPurchasePrice < 0 && ')'} />
                  ) : ('$0')}
                </span>
              </td> 
            </tr> 
            <tr className={classes.textRightBold}> 
              <td>Net Purchase Price after Trade Ins</td> 
              <td> 
                <span>$0</span>
              </td> 
            </tr> 
          </tbody> 
        </table>
      </div>
    </div>
  );
};

FirstCarTradeIn.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( FirstCarTradeIn );
