import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

const SliderBlueTooltip = withStyles({
  tooltip: {
    color: '#fff',
    backgroundColor: '#0069aa',
    padding: '4px 8px',
    borderRadius: '5px',
    fontSize: '18px',
  },
})(Tooltip);

function ValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <SliderBlueTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}>
      {children}
    </SliderBlueTooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

const SliderTransparentTooltip = withStyles({
  tooltip: {
    color: '#0069aa',
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: '.1%',
    padding: '4px 8px',
    borderRadius: '2px',
    fontSize: '16px',
    textAlign: 'center',
    '& span':{
      minWidth: '100px',
      display: 'inline-block',
    }
  },
})(Tooltip);

function HomeValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <SliderTransparentTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <span>
          Home Value
          <br />
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </span>
      }>
      {children}
    </SliderTransparentTooltip>
  );
}

HomeValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

function CarValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <SliderTransparentTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <span className="carValueSpan">
          Car Value
          <br />
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </span>
      }>
      {children}
    </SliderTransparentTooltip>
  );
}

CarValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

function XpLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <SliderTransparentTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <span>
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
          XP
        </span>
      }>
      {children}
    </SliderTransparentTooltip>
  );
}

XpLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

function ReiValueLabelComponent(props) {
  const { children, open, value } = props;
  return (
    <SliderTransparentTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={
        <span>
          Property Value
          <br />
          <NumberFormat
            value={value}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </span>
      }>
      {children}
    </SliderTransparentTooltip>
  );
}

ReiValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

const PwiSlider = withStyles({
  root: {
    color: '#0069aa',
    height: 4,
  },
  disabled: {
    '& .MuiSlider-markActive': {
      background: '#808080  !important',
    },
    '& .MuiSlider-track': {
      color: '#808080  !important',
    },
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#0069aa',
    border: '2px solid currentColor',
    marginTop: -5,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
    '&.Mui-disabled': {
      color: '#808080',
      backgroundColor: '#808080',
      height: 20,
      width: 20,
      marginTop: -5,
      marginLeft: -12,
    },
  },
  mark: {
    background: '#d8e0f3 !important',
  },
  markActive: {
    backgroundColor: '#0069aa !important',
    opacity: 1,
  },
  markLabel: {
    color: '#bbbcbf',
    fontSize: '14px',
    paddingLeft: '10px',
    paddingTop: '5px',
    // width: '70px',
    width: '80px',
    whiteSpace: 'initial',
    textAlign: 'center',
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 2,
  },
  rail: {
    opacity: 1,
    height: 4,
    borderRadius: 2,
    color: '#d8e0f3',
  },
})(Slider);

export default ValueLabelComponent;

export {
  HomeValueLabelComponent,
  CarValueLabelComponent,
  ReiValueLabelComponent,
  PwiSlider,
};
