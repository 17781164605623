import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import * as modulesServices from '../../../calculations/modules-services';
import circleArrow from '../../../assets/img/career-path/circle-arrow.png';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';

const ClosingCosts = ( props ) => {
  const { classes, inputData, updateData } = props;

  const [closingData, setClosingData] = React.useState( {} );
  const [defaultClosingData, setDefaultClosingData] = React.useState( {} );
  const [showRestIcon, setShowRestIcon] = React.useState(false);

  useEffect(()=>{
    if(closingData['closing_appraisal_fee'] !== defaultClosingData['closing_appraisal_fee'] || closingData['closing_credit_report_fee'] !== defaultClosingData['closing_credit_report_fee'] || closingData['closing_escrow_account'] !== defaultClosingData['closing_escrow_account']
      || closingData['closing_flood_determination'] !== defaultClosingData['closing_flood_determination'] || closingData['closing_tax_service_fee'] !== defaultClosingData['closing_tax_service_fee'] || closingData['closing_recording_mortgage'] !== defaultClosingData['closing_recording_mortgage']
      || closingData['closing_processing_fee'] !== defaultClosingData['closing_processing_fee'] || closingData['closing_underwriting_fee'] !== defaultClosingData['closing_underwriting_fee'] || closingData['closing_pest_Inspection'] !== defaultClosingData['closing_pest_Inspection']
      || closingData['closing_home_owners_association_transfer_fees'] !== defaultClosingData['closing_home_owners_association_transfer_fees'] || closingData['closing_costs_others'] !== defaultClosingData['closing_costs_others']) {
      setShowRestIcon(true);
    } else {
      setShowRestIcon(false);
    }
  },[closingData])

  useEffect( () => {
    const buyingData = modulesServices.buyingAHomeCompleteData();
    const closingCostData = buyingData['Closing Cost'];
    const closing = {};
    closing.closing_appraisal_fee = closingCostData['Appraisal Fee'];
    closing.closing_credit_report_fee = closingCostData['Credit Report Fee'];
    closing.closing_escrow_account = closingCostData['Title Closing/Escrow Fee'];
    closing.closing_flood_determination = closingCostData['Flood Determination'];
    closing.closing_tax_service_fee = closingCostData['Tax Service Fee'];
    closing.closing_title_insurance = closingCostData['Owners Title Insurance'];
    closing.closing_recording_mortgage = closingCostData['Recording Mortgage'];
    closing.closing_homeowners_insurance = closingCostData['Homeowners Insurance(3 months prepaid upfront)'];
    closing.closing_transfer_taxes = closingCostData['Transfer Taxes'];
    closing.closing_processing_fee = closingCostData['Processing Fee'];
    closing.closing_underwriting_fee = closingCostData['Underwriting Fee'];
    closing.closing_loan_points = closingCostData['Loan Acquisition Points'];
    closing.closing_origination_fees = closingCostData['Origination Points'];
    closing.closing_mortgage_interest = closingCostData['Mortgage Interest(1month prepaid upfront)'];
    closing.closing_property_taxes = closingCostData['Property Taxes(3 months prepaid upfront)'];
    closing.closing_pest_Inspection = closingCostData['Wood Destroying Pest Inspection'];
    closing.closing_home_owners_association_transfer_fees = closingCostData['Home Owners Association Transfer Fees'];
    closing.closing_costs_others = closingCostData['Others'];
    closing.save_home_closing_costs_new_values = 'Yes';
    setClosingData( closing );
    setDefaultClosingData( closing );
    updateData('total_obj',{...inputData,...closing});
    // eslint-disable-next-line
  },[])

  const resetInputs = () => {
    updateData('total_obj',{...inputData,...defaultClosingData});
    setClosingData(defaultClosingData);
    setShowRestIcon(false)
  }

  const updateValue = ( e, field ) => {
    const closing = { ...closingData };
    let newvalue = e.target.value;
    if(newvalue === '') {
      newvalue = 0;
    }
    newvalue = convertCurrencyToInteger(newvalue);
    closing[field] = newvalue;
    setClosingData( closing );
    updateData( field, newvalue );
  };  

  const totalClosingClost = _.sum(Object.values(closingData)); 
  return (
    <div className={ classes.contentBlock }>
      <p>
        Closing costs are fees associated with your home purchase that are paid at the closing of a real estate transaction. We automatically calculate these costs for you, but you can change them as you see fit. Click
        {' '}
        <a className={ classes.anchorStyle } href="https://www.zillow.com/mortgage-learning/closing-costs/" target="_blank" rel="budget-fy2020 noopener noreferrer">here</a>
        {' '}
        to learn more.
      </p>
      <div className={ classes.closingCosts }>
        <table>
          <thead>
            <tr>
              <th>Closing Costs</th>
              <th >
                  {showRestIcon && (
                    <span className={ classes.resetIcon }>
                      <ReactTooltip id="reset1" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
                      <img src={ circleArrow } alt=""  aria-hidden="true" onClick={resetInputs} data-for="reset1" data-tip="Reset to default values" />
                    </span>
                  )}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Appraisal Fee
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_appraisal_fee !== undefined ? closingData.closing_appraisal_fee : 0 } onChange={ ( e ) => updateValue( e, 'closing_appraisal_fee' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Credit Report Fee
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_credit_report_fee !== undefined ? closingData.closing_credit_report_fee : 0 } onChange={ ( e ) => updateValue( e, 'closing_credit_report_fee' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Attorney Fee: Title Closing/Escrow Account
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_escrow_account !== undefined ? closingData.closing_escrow_account : 0 } onChange={ ( e ) => updateValue( e, 'closing_escrow_account' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Flood Determination
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_flood_determination !== undefined ? closingData.closing_flood_determination : 0 } onChange={ ( e ) => updateValue( e, 'closing_flood_determination' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Tax Service Fee
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_tax_service_fee !== undefined ? closingData.closing_tax_service_fee : 0 } onChange={ ( e ) => updateValue( e, 'closing_tax_service_fee' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Title Insurance
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ closingData.closing_title_insurance === undefined ? 0 : closingData.closing_title_insurance } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Recording Mortgage
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_recording_mortgage !== undefined ? closingData.closing_recording_mortgage : 0 } onChange={ ( e ) => updateValue( e, 'closing_recording_mortgage' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Homeowner&apos;s Insurance (3 months prepaid upfront)
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ closingData.closing_homeowners_insurance === undefined ? 0 : closingData.closing_homeowners_insurance } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Transfer Taxes
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ closingData.closing_transfer_taxes === undefined ? 0 : closingData.closing_transfer_taxes } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Processing Fee
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_processing_fee !== undefined ? closingData.closing_processing_fee : 0 } onChange={ ( e ) => updateValue( e, 'closing_processing_fee' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Underwriting Fee
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_underwriting_fee !== undefined ? closingData.closing_underwriting_fee : 0 } onChange={ ( e ) => updateValue( e, 'closing_underwriting_fee' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Loan Points
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ closingData.closing_loan_points === undefined ? 0 : closingData.closing_loan_points } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Origination Fees
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ closingData.closing_origination_fees === undefined ? 0 : closingData.closing_origination_fees } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Mortgage Interest (1 month prepaid upfront)
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ closingData.closing_mortgage_interest === undefined ? 0 : closingData.closing_mortgage_interest } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Property Taxes (3 months prepaid upfront)
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ closingData.closing_property_taxes === undefined ? 0 : closingData.closing_property_taxes } displayType="text" thousandSeparator prefix="$" />
              </td>
            </tr>
            <tr>
              <td>
                Pest Inspection
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_pest_Inspection !== undefined ? closingData.closing_pest_Inspection : 0 } onChange={ ( e ) => updateValue( e, 'closing_pest_Inspection' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Homeowners Association Transfer Fees
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_home_owners_association_transfer_fees !== undefined ? closingData.closing_home_owners_association_transfer_fees : 0 } onChange={ ( e ) => updateValue( e, 'closing_home_owners_association_transfer_fees' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
            <tr>
              <td>
                Other
              </td>
              <td>
                $
                <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ closingData.closing_costs_others !== undefined ? closingData.closing_costs_others : 0 } onChange={ ( e ) => updateValue( e, 'closing_costs_others' ) } onFocus={ ( e ) => e.target.select() } />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>
                Closing Costs
              </th>
              <th>
                {totalClosingClost !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalClosingClost } prefix="$" /> ) : ( '$0' )}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

ClosingCosts.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};

export default withStyles( styles )( ClosingCosts );
