import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruNine = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide, handleWalkThrusBack, walkThruNo

    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruNine)}>
            {walkThruNo === 1 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 2 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 3 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance? Explore the Quick Links section to research how much you might earn in different career moves.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 4 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 5 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 6 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 7 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 8 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance? Explore the Quick Links section to research the inputs needed to make a solid strategy for your real estate investment property.</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 9 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 10 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 11 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 12 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 13 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(8) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(8); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 14 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance?</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(9) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(9); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
            {walkThruNo === 15 ?
                <div>
                    <h4>Boost My Awareness</h4>
                    <p>Packed with learning resources, leverage the Boost My Awareness section for FAQ, Definitions, and Quick Links and improve your financial acumen. Ready to learn the language of finance? Explore the Quick Links section to research the inputs needed to make a solid strategy for your home purchase</p>
                    <div className={classes.walkthruBottom}>
                        <WalkThruDots walkThruNo={walkThruNo}  completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />
                        <div className={classes.walkThruBtns}>
                            <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(10) }}>Back</Button>
                            <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(10); }}>Next</Button>
                            <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                        </div>
                    </div>
                </div>
                :
                ''
            }
        </div>
    );
};

WalkThruNine.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,

};

export default withStyles(styles)(WalkThruNine);