import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
// import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const ShortTermInvestment = ( props ) => {
  const { classes, handleAssestObject,handleUpdatedObject, handleDefinationPopup } = props;
  const [shortTermInvestments, setShortTermInvestments] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  
  React.useEffect(()=>{
    if(handleAssestObject.short_term_other >= 0 && handleAssestObject.short_term_traditional_savings_account >= 0 && handleAssestObject.short_term_high_yield_savings_account >= 0 && handleAssestObject.short_term_certificates_of_deposit >= 0 && handleAssestObject.short_term_money_market_funds >= 0){
      const inputValues = {...handleAssestObject};
      inputValues.existing_total_short_term_investments = 
      (handleAssestObject.short_term_traditional_savings_account) +
      (handleAssestObject.short_term_high_yield_savings_account) +
      (handleAssestObject.short_term_certificates_of_deposit) +
      (handleAssestObject.short_term_money_market_funds) +
      (handleAssestObject.short_term_other);
  
      handleUpdatedObject( inputValues );
      setTotal(inputValues.existing_total_short_term_investments);
    }
  }, [shortTermInvestments])

  const handleChanges = (e, name) => {
    const inputValues = {...handleAssestObject};
    const v = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[name] = v;
    setShortTermInvestments({...shortTermInvestments, [name]: v})
    handleUpdatedObject( inputValues );
  }

  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." className={ classes.definationTittle } onClick={ () => handleDefinationPopup( 'Short-Term Investments' ) }>Short-term investments</span>
        {' '}are accounts that you intend to use in the short term, which is usually considered to be less than one year. 
        For example, your savings account or money market funds are examples of short-term investments as they could be used within a year for 
        a special occasion.
      </p>
      <p className={ classes.existingSpace }>
        Think of short-term investments as your emergency fund. 
        You need money set aside that earns{' '}
        <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." className={ classes.definationTittle } onClick={ () => handleDefinationPopup( 'Annual Expected Pre-Tax Return: Investments' ) }>interest</span> 
        {' '}each month and can be accessed easily. 
        {' '}
        <b>Enter the value for each of your accounts below</b>.
      </p>
      
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Short-Term Investments</th>
          </thead>
          <tbody>
            <tr>
              <td><span >Traditional Savings Account</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.short_term_traditional_savings_account } name="short_term_traditional_savings_account" onValueChange={ (e)=> handleChanges(e, 'short_term_traditional_savings_account') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><span>High-Yield Savings Account</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.short_term_high_yield_savings_account } name= "short_term_high_yield_savings_account" onValueChange={ (e)=> handleChanges(e, 'short_term_high_yield_savings_account') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><span>Certificates of Deposit (CDs)</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.short_term_certificates_of_deposit } name= "short_term_certificates_of_deposit" onValueChange={ (e)=> handleChanges(e, 'short_term_certificates_of_deposit') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><span>Money Market Funds</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.short_term_money_market_funds } name= "short_term_money_market_funds" onValueChange={ (e)=> handleChanges(e, 'short_term_money_market_funds') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr>
              <td><span>Other</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ handleAssestObject.short_term_other } name= "short_term_other" onValueChange={ (e)=> handleChanges(e, 'short_term_other') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Short-Term Investments</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    <NumberFormat value={ total } displayType="text" thousandSeparator prefix="$" />
                  </span>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

ShortTermInvestment.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( ShortTermInvestment );
