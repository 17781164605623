import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-investments/styles';
import * as modulesServices from '../../calculations/modules-services';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import InvestmentBottomTwo from './investmment-bottomTwo';

const PlanSettingTimeline = (props) => {
  const {
    classes, getInvestmentObject, handleInvestmentData,
  } = props;

  const [startYear, setStartYear] = React.useState();

  const d = new Date();
  const currentYear = d.getFullYear();
  const startYearOptions = [];
  const loopYear = modulesServices.module1Data.start_year > 0 ? modulesServices.module1Data.start_year : currentYear;
  for (let year = 0; year <= 14; year += 1) {
    startYearOptions.push(loopYear + year);
  }

  const handleStartYear = (event) => {
    let InvObj = {...getInvestmentObject};
    let start_year = 0;
    start_year = parseInt(event.target.value);
    setStartYear(start_year);
    InvObj['plan_529_start_year']=parseInt(event.target.value, 10);
    handleInvestmentData(InvObj);
  };

  useEffect(() => {
    if(getInvestmentObject.plan_529_start_year !== undefined && getInvestmentObject.plan_529_start_year !== '' && getInvestmentObject.plan_529_start_year !== null){
    setStartYear(getInvestmentObject.plan_529_start_year);
    }else{
      setStartYear(currentYear);
    }
  }, [getInvestmentObject]);

  return (
    <div className={classes.contentBlock}>
      <p>A <a target="_blank" href="https://www.investopedia.com/terms/1/529plan.asp" rel="noreferrer">529 Plan</a> is an investment account that provides tax-free earnings growth – just like a 401(k). But, there's a big difference. If you withdraw money from your 529 account to pay for qualified education expense, you don't have to pay taxes on those withdrawals.</p>
      <p>When do you plan on contributing to a 529 Plan? If you already are contributing, enter today's year. If not, enter the year you plan to start.</p>
      <div className={classNames(classes.grayBox, classes.investGrayDesign)}>
        <p>Make sure your net cash flow is positive in the year you plan to start making investments.</p>
        <ul className={classes.yearsList}>
          <li>
            <span>I plan to start making contributions to a 529 Plan in year&nbsp;</span>
            <select value={startYear} onChange={handleStartYear}>
              {startYearOptions.map((year) => <option value={year} key={year}>{year}</option>)}
            </select>
            .
          </li>
          <li>Beginning 529 Plan balance in year &nbsp;
            {startYear}:
            {modulesServices.module9Data.beginning529Balance ?
              <NumberFormat id="beginning" displayType={'text'} decimalScale={0} fixedDecimalScale thousandSeparator value={modulesServices.module9Data.beginning529Balance} prefix={'$'} />
              :
              '$0'
            }.
          </li>
        </ul>
      </div>
      <CareerSketchTimeline moduleName="module9" />
      <div className={classNames(classes.borderDivider, classes.borderDividerMT30)} />
      {startYear !== '' && startYear !== undefined &&
        <InvestmentBottomTwo activeSlide={11} getInvestmentObject={getInvestmentObject} startYear={startYear} />
      }
    </div>
  );
};

PlanSettingTimeline.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,
};

export default withStyles(styles)(PlanSettingTimeline);
