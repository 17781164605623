import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { confirmAlert } from 'react-confirm-alert';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import styles from './styles';
import * as fcaGraphData from '../../graph-services/fca-show-me';
import alertIcon from '../../assets/img/alert_icon.png';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as timeLineActions from '../../calculations/time-line';
import sliderImageOne from '../../assets/img/career-path/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/further-career-advancement/further-career-slide1.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
// import Simulator from '../../assets/img/financial-independence/simulator-engine-blue.png';
// import TimeLine from '../../assets/img/financial-independence/timeline_icon.png';
// import Dollar from '../../assets/img/financial-independence/dollar-icon.png';
// import Dashboard from '../../assets/img/financial-independence/dashboard.png';
// import Clear from '../../assets/img/financial-independence/clear-timeline.png';
// import Load from '../../assets/img/financial-independence/load.png';
import familyImage from '../../assets/img/higher-education/family_icon_modules.png';
// import Download from '../../assets/img/financial-independence/download.png';
// import Save from '../../assets/img/financial-independence/save.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import estimatedTax from '../../assets/img/career-path/careerpath_image_after.png';
import powerInt from '../../assets/img/career-path/Compounding Interest.png';
import phaseoutsSingle from '../../assets/img/career-path/Roth_IRA Phaseouts_Single.png';
import phaseoutsMarried from '../../assets/img/career-path/Roth_IRA_Phaseouts_Married.png';
import careerpathBill from '../../assets/img/career-path/career_path_bill_img.png';
import infoIconWhite from '../../assets/img/higher-education/info-icon-contrast.png';
import * as moduleServices from '../../calculations/modules-services';
import PwiSave from '../../components/pwi-save/pwi-save';
import MySavedNotes from './my-saved-notes';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import { defaultValues } from '../../calculations/default-module-values';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';
// import shareIcon from '../../assets/img/financial-independence/share_icon.png';

import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
// import WalkThruFour from './wallk-thru/walkThruFour';
// import WalkThruFive from './wallk-thru/walkThruFive';
// import WalkThruSix from './wallk-thru/walkThruSix';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import IconBar from './IconBar';

import scrollToComponent from 'react-scroll-to-component';

const LIST_OF_STATES = loader('../../graphql/schema/auth/list-of-states.graphql');
const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  colors: ['#5B9BD5', '#A5A5A5', '#FFC000', '#4472C4', '#70AD47', '#255E91', '#9E480E', '#636363', '#997300', '#264478', '#43682B', '#7CAFDD', '#F1975A', '#B7B7B7', '#FFCD33', '#698ED0'],
});

HCExporting(Highcharts);

const FurtherCareerAdvancementWorksheet = (props) => {
  const { classes, history } = props;

  const dropdownIntialValues = {
    showMe: false,
    awareness: false,
    compareSavedNotes: false,
    savedNotes: false,
    faq: false,
    definations: false,
    quickLinks: false,
  };

  const [updatedFcadvancementValues, setupdatedFcadvancementValues] = useState(defaultValues.module17);
  const [dropdownvalues, setDropdownValues] = React.useState(dropdownIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = React.useState(false);
  const [awareHoverActive, setAwareHoverActive] = React.useState(false);
  const [compareHoverActive, setCompareHoverActive] = React.useState(false);
  const [savedHoverActive, setSavedHoverActive] = React.useState(false);
  const [moduleObjectParams, setmoduleObjectParams] = React.useState({});
  const [loadScenarioName, setLoadScenarioName] = React.useState({});
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [updatedOptions, setUpdatedOptions] = React.useState([{ label: 0, value: 0 }]);
  const [supplementaryEndOptions, setSupplementaryEndOptions] = React.useState([{ label: 0, value: 0 }]);
  const [loading, setLoading] = useState(true);
  const [showMeData, setShowMeData] = React.useState({});
  const [openPopup, setOpenPopup] = React.useState('');
  const [statesList, setStatesList] = useState([{ label: 'Please select', value: 0 }]);
  const [yearRange, setYearRange] = React.useState([]);
  const [endYearRange, setEndYearRange] = React.useState([]);
  const totalCharitableYear = ((updatedFcadvancementValues.end_year !== 0 ? updatedFcadvancementValues.end_year - updatedFcadvancementValues.start_year + 1 : 0));
  // const totalFinanceGiftYear = ( updatedFcadvancementValues.second_end_year - ( ( updatedFcadvancementValues.second_end_year !== 0 ) ? updatedFcadvancementValues.second_start_year : 0 ) ) + 1;
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);

  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [isFocus, setIsFocus] = useState('');

  useEffect(() => {
    setWalkThruNo(6);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    const yearRange1 = [{ label: 0, value: 0 }];
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      yearRange1.push({ label: year, value: year });
    }
    setYearRange(yearRange1);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const generateSupplementaryIncomeYears = (data, type = 'edit') => {
    const valuesUpdated = data;
    const options = [{ label: 0, value: 0 }];
    if (data.start_year > 0 && data.end_year > 0) {
      for (let year = data.start_year; year <= data.end_year; year += 1) {
        if (options.length === 0) {
          options.push({ label: 0, value: 0 }, { label: year, value: year });
        } else {
          options.push({ label: year, value: year });
        }
      }
    }
    setUpdatedOptions(options);
    if (type === 'edit' && data.start_year > 0 && data.second_start_year > 0 && (data.start_year > data.second_start_year || data.end_year < data.second_start_year)) {
      valuesUpdated.second_start_year = valuesUpdated.start_year;
    }

    const endOptions = [{ label: 0, value: 0 }];
    if (data.second_start_year > 0 && data.end_year > 0) {
      for (let year = data.second_start_year; year <= data.end_year; year += 1) {
        if (endOptions.length === 0) {
          endOptions.push({ label: 0, value: 0 }, { label: year, value: year });
        } else {
          endOptions.push({ label: year, value: year });
        }
      }
    }
    setSupplementaryEndOptions(endOptions);

    if (type === 'edit' && data.second_end_year > 0) {
      const yearIndex = _.findIndex(endOptions, { value: data.second_end_year });
      if (yearIndex < 0) {
        valuesUpdated.second_end_year = data.second_start_year;
      }
    }
    return valuesUpdated;
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module17' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          if (data.start_year > 0) {
            const _endYearRange = [{ label: 0, value: 0 }];
            for (let year = data.start_year; year < (data.start_year + 14); year += 1) {
              _endYearRange.push({ label: year, value: year });
            }
            setEndYearRange(_endYearRange);
          }else{
            setEndYearRange([{ label: 0, value: 0 }]);
          } 
          setupdatedFcadvancementValues(data);
          generateSupplementaryIncomeYears(data)
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
    onError() {
      setActiveScenarioData({});
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setupdatedFcadvancementValues(defaultValues.module17);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(()=>{
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module17' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }     
  },[loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(()=>{     
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module17' ) {
      dropdownvalues.savedNotes = true;    
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 }); 
    }
  },[myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  useQuery(LIST_OF_STATES, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const statesListData = _.map(response.States ? response.States : [], (state) => ({ label: state.state_name, value: parseInt(state.id, 10) }));
      setStatesList([...statesList, ...statesListData]);
    },
  });

  const updateValue = (e, field, fieldType) => {
    let valuesUpdated = { ...updatedFcadvancementValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
      if (valuesUpdated[field] > 100 && (field === 'pension_employer_contribution' || field === 'pension_personal_contribution' || field === 'pension_annual_expected_pre_tax'
        || field === 'ira_personal_contribution' || field === 'ira_annual_expected_pre_tax' || field === 'roth_personal_contribution'
        || field === 'roth_annual_expected_pre_tax' || field === 'base_income' || field === 'base_roth_income' || field === 'ira_roth_personal_contribution' || field === 'hsa_personal_contribution'
        || field === 'vested_amount_at_end_year' || field === 'hsa_annual_expected' || field === 'employer_matching_limit' || field === 'Annual_expected401k')) {
        valuesUpdated[field] = 100;
      } else if (valuesUpdated[field] > 200 && (field === 'employer_matching_rate')) {
        valuesUpdated[field] = 200;
      }
    } else if (field === 'occupation' || field === 'city' || fieldType === 'input') {
      valuesUpdated[field] = e.target.value;
    } else if (fieldType === 'select') {
      if (field === 'state_id') {
        valuesUpdated[field] = e.value;
      } else if (field === 'start_year' || field === 'end_year') {
        valuesUpdated[field] = e.value;
        if(field === 'start_year' && e.value > 0) {
          const _endYearRange = [{ label: 0, value: 0 }];
          for (let year = e.value; year < (e.value + 14); year += 1) {
            _endYearRange.push({ label: year, value: year });
          }
          setEndYearRange(_endYearRange);
        }else{
          setEndYearRange([{ label: 0, value: 0 }]);
        } 
        if (valuesUpdated.start_year <= 0) {
          valuesUpdated.end_year = 0;
        }
        if (valuesUpdated.end_year > 0 && valuesUpdated.start_year > valuesUpdated.end_year) {
          valuesUpdated.end_year = valuesUpdated.start_year;
        }
        valuesUpdated = generateSupplementaryIncomeYears(valuesUpdated);
      } else if (field === 'second_start_year' || field === 'second_end_year') {
        valuesUpdated[field] = e.value;
        if (valuesUpdated.second_start_year <= 0) {
          valuesUpdated.second_end_year = 0;
        }
        if (valuesUpdated.second_end_year > 0 && valuesUpdated.second_start_year > valuesUpdated.second_end_year) {
          valuesUpdated.second_end_year = valuesUpdated.second_start_year;
        }
        valuesUpdated = generateSupplementaryIncomeYears(valuesUpdated);
      } else {
        valuesUpdated[field] = e.value;
      }
    } else if (fieldType === 'radio') {
      valuesUpdated[field] = e.target.value;
      if (valuesUpdated.self_employment === 'Yes') {
        valuesUpdated.contribute_401k = 'No';
        valuesUpdated.pension_contribute = 'No';
      } else {
        valuesUpdated.ira_contribute = 'No';
      }

      if (valuesUpdated.contribute_401k === 'No') {
        valuesUpdated.base_income = 0;
        valuesUpdated.employer_matching_rate = 0;
        valuesUpdated.employer_matching_limit = 0;
        valuesUpdated.Annual_expected401k = 0;
      }
      if (valuesUpdated.roth_contribute === 'No') {
        valuesUpdated.roth_personal_contribution = 0;
        valuesUpdated.roth_annual_expected_pre_tax = 0;
      }
      if (valuesUpdated.ira_contribute === 'No') {
        valuesUpdated.ira_personal_contribution = 0;
        valuesUpdated.ira_annual_expected_pre_tax = 0;
      }

      if (valuesUpdated.pension_contribute === 'No') {
        valuesUpdated.pension_employer_contribution = 0;
        valuesUpdated.pension_personal_contribution = 0;
        valuesUpdated.pension_annual_expected_pre_tax = 0;
      }
    }
    setupdatedFcadvancementValues(valuesUpdated);
    moduleServices.setModule17Data(valuesUpdated);
  };
  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(fcaGraphData.fcaShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top'});
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module17';
    objectParams.moduleNumber = 'module17Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    // setModalType( 'save' );
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module17";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module17', 'quick-save', activeScenarioData);
      finalObject['module17Data'] = pwiObject.getfilteredCareerPath(moduleServices.module17Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -250, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  }

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -130, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 450, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >
      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 12 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.furthercaSlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Further Career Advancement
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your further career advancement details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>START WITH A WALK-THRU</span>

                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Further Career Advancement
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Your Subsequent Moves
                              </Typography>
                              <ul>
                                <li>As your career advances, you may have the opportunity for further promotions and job changes</li>
                                <li>Project where you see yourself in the next stage of your career</li>
                                <li>Forecast your new estimated income</li>
                                <li>Manage your risk and maximize your reward.</li>
                              </ul>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classes.sliderImage}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <section className={classes.fiContentSection}>
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module17" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classes.leftIconPuzzle}>
                    <Link to="/plan-with-inkwiry/fca" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.furtherCareerWalk1) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} 
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes = {classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Further Career Advancement</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Self-Employment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="Employment1" className={updatedFcadvancementValues.self_employment === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Employment1"
                                                  name="Employment1"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.self_employment === 'Yes'}
                                                  onChange={(e) => updateValue(e, 'self_employment', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="Employment2" className={updatedFcadvancementValues.self_employment === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Employment2"
                                                  name="Employment2"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.self_employment === 'No'}
                                                  onChange={(e) => updateValue(e, 'self_employment', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classNames(classes.infoIcon, classes.checkInfoIcon)} data-for="detailsTooltip" data-tip="Self-employment could be owning your own business, being an independent contractor such as a real estate broker, or a freelance worker. Self-employment has an effect on your taxes. See the Definitions in the Boost My Awareness section below for further info as well as the tax implications of self-employment.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Job Title</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} id="standard-basic" value={updatedFcadvancementValues.occupation} onChange={(e) => updateValue(e, 'occupation', 'input')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter your planned occupation, which could be your job title or any title that you would like to associate with this career move.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>State</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'state_id', 'select'); }}
                                              value={_.findIndex(statesList, { value: updatedFcadvancementValues.state_id }) >= 0 ? statesList[_.findIndex(statesList, { value: updatedFcadvancementValues.state_id })] : statesList[0]}
                                              options={statesList}
                                              placeholder="Please select"
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>City</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} value={updatedFcadvancementValues.city} onChange={(e) => updateValue(e, 'city', 'input')} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Where you work makes all the difference. Will you work in a city with high living costs such as San Francisco or New York City, or will you work in a city with a lower cost of living? How does your income change depending on the city you live in? Where's the best place for you to live and work?">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>
                                  {updatedFcadvancementValues.occupation === '' ? 'Other' : updatedFcadvancementValues.occupation}
                                  {' '}
                                  - Income
                                </h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'start_year', 'select'); }}
                                              options={yearRange}
                                              value={{ label: updatedFcadvancementValues.start_year, value: updatedFcadvancementValues.start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter the year you expect to start this career move.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>Years</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'end_year', 'select'); }}
                                              options={endYearRange}
                                              value={{ label: updatedFcadvancementValues.end_year, value: updatedFcadvancementValues.end_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter the year you expect to end this career move.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>{totalCharitableYear}</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFcadvancementValues.yearly_income} onValueChange={(e) => updateValue(e, 'yearly_income', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div
                                            className={classes.infoIcon}
                                            data-for="detailsTooltip"
                                            data-tip="Enter how much income you will earn from this job excluding any bonus, tips, and commission. If you're planning to make a salary, enter your salary. If you plan to be paid hourly, calculate your annual income by multiplying your hourly wage by expected number of hours worked.
                                              Not sure how much you might make? Check out the Boost My Awareness section below for resources on expected income of different careers."
                                          >
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Bonus / Tips / Commission</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFcadvancementValues.bonus_or_tips_or_commission} onValueChange={(e) => updateValue(e, 'bonus_or_tips_or_commission', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much income you will earn from bonus, tips, and commission. Depending on your career move, there might be an opportunity to earn bonus, tips, and commission. Not sure how much you might make? Check out the Boost My Awareness section below for resources on expected income.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Pre-Tax Base Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.jobtotalPreTaxExpected_val !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module17Data.jobtotalPreTaxExpected_val} prefix={moduleServices.module17Data.jobtotalPreTaxExpected_val >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.jobtotalPreTaxExpected_val < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>
                                  Supplementary Income
                                  <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Do you plan on working multiple jobs or having a side hustle during this career move? Are you planning to earn any supplementary income outside of your main career move? Once you enter a start and end year above, this section will become available.">
                                    <img src={infoIcon} alt="infoicon" />
                                  </div>
                                </h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'second_start_year', 'select'); }}
                                              options={updatedOptions}
                                              value={{ label: updatedFcadvancementValues.second_start_year, value: updatedFcadvancementValues.second_start_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter the year you expect to start earning supplementary income.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>Years</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'second_end_year', 'select'); }}
                                              options={supplementaryEndOptions}
                                              value={{ label: updatedFcadvancementValues.second_end_year, value: updatedFcadvancementValues.second_end_year }}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter the year you expect to stop earning supplementary income.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>
                                          {updatedFcadvancementValues.second_end_year !== 0 ? (updatedFcadvancementValues.second_end_year - updatedFcadvancementValues.second_start_year) + 1: 0}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Annual Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFcadvancementValues.second_yearly_income} onValueChange={(e) => updateValue(e, 'second_yearly_income', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Enter how much supplementary income you expect to earn. Not sure how much you might make? Check out the Boost My Awareness section below for resources on expected income.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>
                                  {' '}
                                  {updatedFcadvancementValues.occupation === '' ? 'Other' : updatedFcadvancementValues.occupation}
                                  {' '}
                                  - Monthly Living Expenses
                                </h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classes.inputEmpty}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>{updatedFcadvancementValues.start_year}</span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} />
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            {moduleServices.module17Data.furtherCareerMarriageYearVal && (
                              <div className={classes.moduleRow}>
                                <Grid container>
                                  <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                    <div className={classes.inputGroup}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                          <div>
                                            <div className={classes.spanText}>&nbsp;</div>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} xs={6} className={classes.noSpace}>
                                          <Grid container>
                                            <Grid item sm={8} xs={8} >
                                              <div>
                                                &nbsp;
                                              </div>
                                            </Grid>
                                            <Grid item sm={4} xs={4} >
                                              <div className={classes.inputRight}>
                                                &nbsp;
                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                    {moduleServices.module17Data.furtherCareerMarriageYearVal !== '' && (
                                      <div className={classes.formMainRow}>
                                        <div className={classes.labelText}>
                                          <div className={classes.calcText}>
                                            <span className={classes.familyImage}>
                                              <img src={familyImage} alt="familyImage" />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            {moduleServices.module17Data.furtherCareerMarriageYearVal !== '' && (
                              <div className={classes.moduleRow}>
                                <Grid container>
                                  <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                    <div className={classes.inputGroup}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                          <div>
                                            <div className={classes.spanText}>&nbsp;</div>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} xs={6} className={classes.noSpace}>
                                          <Grid container>
                                            <Grid item sm={8} xs={8} >
                                              <div>
                                                &nbsp;
                                              </div>
                                            </Grid>
                                            <Grid item sm={4} xs={4} >
                                              <div className={classes.inputRight}>
                                                <span className={classes.exampleText}>
                                                  X
                                                </span>
                                                <div
                                                  className={classes.infoIcon}
                                                  data-for="detailsTooltip"
                                                  data-tip="If your Marriage year overlaps with the time you live off-campus, the Marriage Multiples that grow your expenses to account for your partner will show in this section here. To adjust the multiples, which will adjust your expenses go to the Family Module."
                                                >
                                                  <img src={infoIcon} alt="infoicon" />
                                                </div>

                                              </div>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Grid>
                                  <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          <span>
                                            {moduleServices.module17Data.furtherCareerMarriageYearVal}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Rent</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.rent} onValueChange={(e) => updateValue(e, 'rent', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much you expect to spend on rent. This input depends on your location and will require a little research to figure out rent costs in your planned living area. Check out the Boost My Awareness section below for links to research average rents by location."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerRentMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerRentMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerRentMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerRentExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRentExpenses} prefix={moduleServices.module17Data.furtherCareerRentExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRentExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.utilities} onValueChange={(e) => updateValue(e, 'utilities', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much you expect to spend on utilities such as cable, internet, electricity, heat, water, etc. Check the Quick Links in the Boost My Awareness section below for tips on estimating your monthly utilities bill."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerUtilitiesMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerUtilitiesMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerUtilitiesMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerUtilitiesExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerUtilitiesExpenses} prefix={moduleServices.module17Data.furtherCareerUtilitiesExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerUtilitiesExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Food</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.food} onValueChange={(e) => updateValue(e, 'food', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much money you expect to spend on food on a monthly basis. Factor in things such as grocery shopping, dining out, delivery, take out, and any other food. Don't forget, you can use Inkwiry's calculator to do some quick math to get to your total monthly food expense when living off campus."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerFoodMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerFoodMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerFoodMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerFoodExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerFoodExpenses} prefix={moduleServices.module17Data.furtherCareerFoodExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerFoodExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Clothing & Personal Care</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.clothing} onValueChange={(e) => updateValue(e, 'clothing', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much money you expect to spend on clothing and personal care. This input should account for all clothes such as shoes, shirts, pants, accesories, jewelry, etc. And, any personal care items such as toothbrushes, hair and body washes, facial care, etc. If you don't buy clothes and personal care items every month, just estimate what you might spend each year and divide that number by 12 using Inkwiry's calculator above."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerClothingMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerClothingMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerClothingMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerClothingExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerClothingExpenses} prefix={moduleServices.module17Data.furtherCareerClothingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerClothingExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Entertainment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.entertainment} onValueChange={(e) => updateValue(e, 'entertainment', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much money you expect to spend on entertainment activities such as movies, books, golfing, going out at night with friends, concerts, or just about anything you do for fun that costs money."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerEntertainmentMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerEntertainmentMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerEntertainmentMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerEntertainmentExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerEntertainmentExpenses} prefix={moduleServices.module17Data.furtherCareerEntertainmentExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerEntertainmentExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Technology</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.technology} onValueChange={(e) => updateValue(e, 'technology', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much you expect to spend on technology such as a smartphone bill, laptop / desktop / tablet expenses, streaming services, Inkwiry subscription, other subscriptions, or any other dollars you spend on technology."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerTechnologyMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerTechnologyMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                     
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerTechnologyMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerTechnologyExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerTechnologyExpenses} prefix={moduleServices.module17Data.furtherCareerTechnologyExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerTechnologyExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Transportation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.transportation} onValueChange={(e) => updateValue(e, 'transportation', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much you expect to spend on transportation such as ride sharing services, taxis, subway rides, trains, buses, or any other dollars you spend on transportation. Do not include any expenses related to your own car. Those expenses will be captured in the 'Existing Assets & Liabilities' or 'Buying a Car' modules."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerTransportationMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerTransportationMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerTransportationMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerTransportationExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerTransportationExpenses} prefix={moduleServices.module17Data.furtherCareerTransportationExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerTransportationExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Miscellaneous</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.miscellaneous} onValueChange={(e) => updateValue(e, 'miscellaneous', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much you expect to spend on miscellaneous items such as costs of household items and other supplies, gym memberships, monthly parking, or any repeat monthly expense that might not be captured in the above categories."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerMiscellaneousMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerMiscellaneousMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerMiscellaneousMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerMiscellaneousExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerMiscellaneousExpenses} prefix={moduleServices.module17Data.furtherCareerMiscellaneousExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerMiscellaneousExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Healthcare Costs other than Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.medical_costs_before_deductible} onValueChange={(e) => updateValue(e, 'medical_costs_before_deductible', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much you expect to spend on healthcare outside of your monthly premium. Some expenses that you can plan for are copay payments for routine checkups, pharmacy costs, or any other out of pocket healthcare costs that are expected. Check out the Boost My Awareness section below for definitions of a premium and costs outside the premium."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerOutsidePremiumMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerOutsidePremiumMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerOutsidePremiumMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerOutsidePremiumExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerOutsidePremiumExpenses} prefix={moduleServices.module17Data.furtherCareerOutsidePremiumExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerOutsidePremiumExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Health Insurance Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <Grid container>
                                          <Grid item sm={8} xs={8} >
                                            <div className={classNames(classes.inputRight, classes.smallInput)}>
                                              <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale thousandSeparator customInput={TextField} value={updatedFcadvancementValues.health_insurance_premium} onValueChange={(e) => updateValue(e, 'health_insurance_premium', 'number')} onFocus={(e) => e.target.select()} />
                                              <span className={classes.dollarSign}>$</span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="detailsTooltip"
                                                  data-tip="Enter how much your health insurance premium costs each month. If your parents pay for your health insurance, you can leave this as $0. If not, a good estimate of what health insurance might cost can be found in Quick Links in the Boost My Awareness section below."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                          <Grid item sm={4} xs={4} >
                                            {moduleServices.module17Data.furtherCareerPremiumMultiplier && (
                                              <div className={classes.inputRight}>
                                                <span className={classes.exText}>
                                                  {moduleServices.module17Data.furtherCareerPremiumMultiplier}
                                                  x
                                                </span>
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerPremiumMultiplier && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerPremiumExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPremiumExpenses} prefix={moduleServices.module17Data.furtherCareerPremiumExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPremiumExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classes.horizontalSmall} />
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Total Monthly Living Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classes.inputEmpty}>
                                        <div className={classes.inputRight}>
                                          <span className={classNames(classes.noInputText, classes.noLeftPadding)}>
                                            {moduleServices.module17Data.livingTotalMonthlyLivingExpenses_val !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.livingTotalMonthlyLivingExpenses_val} prefix={moduleServices.module17Data.livingTotalMonthlyLivingExpenses_val >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.livingTotalMonthlyLivingExpenses_val < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  {moduleServices.module17Data.furtherCareerTotalMonthlyLivingExpenses && (
                                    <div className={classes.formMainRow}>
                                      <div className={classes.labelText}>
                                        <div className={classes.calcText}>
                                          {moduleServices.module17Data.furtherCareerTotalMonthlyLivingExpenses !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerTotalMonthlyLivingExpenses} prefix={moduleServices.module17Data.furtherCareerTotalMonthlyLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerTotalMonthlyLivingExpenses < 0 && ')'} />
                                          ) : ('$0')}
                                          {' '}

                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Monthly Budget</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Monthly Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.monthlyIncomeFromFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.monthlyIncomeFromFurCareerAdv} prefix={moduleServices.module17Data.monthlyIncomeFromFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.monthlyIncomeFromFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Other Monthly Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.otherMonthlyIncomeFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.otherMonthlyIncomeFurCareerAdv} prefix={moduleServices.module17Data.otherMonthlyIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.otherMonthlyIncomeFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Total Monthly Income</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv} prefix={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalMonthlyIncomeFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.totalTaxesPaidFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalTaxesPaidFurCareerAdv} prefix={moduleServices.module17Data.totalTaxesPaidFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalTaxesPaidFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.livingExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.livingExpensesFurCareerAdv} prefix={moduleServices.module17Data.livingExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.livingExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.higherEducationExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.higherEducationExpensesFurCareerAdv} prefix={moduleServices.module17Data.higherEducationExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.higherEducationExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.childrenExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.childrenExpensesFurCareerAdv} prefix={moduleServices.module17Data.childrenExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.childrenExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.petExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.petExpensesFurCareerAdv} prefix={moduleServices.module17Data.petExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.petExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.carExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.carExpensesFurCareerAdv} prefix={moduleServices.module17Data.carExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.carExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.realEstateExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.realEstateExpensesFurCareerAdv} prefix={moduleServices.module17Data.realEstateExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.realEstateExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.internetExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.internetExpensesFurCareerAdv} prefix={moduleServices.module17Data.internetExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.internetExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Monthly Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv} prefix={moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalMonthlyExpensesFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Monthly Cash Flow before Retirement Contributions</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.monthlyNetIncomeFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.monthlyNetIncomeFurCareerAdv} prefix={moduleServices.module17Data.monthlyNetIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.monthlyNetIncomeFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.retirementContributionsFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.retirementContributionsFurCareerAdv} prefix={moduleServices.module17Data.retirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.retirementContributionsFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.monthlyNetCashFlowFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.monthlyNetCashFlowFurCareerAdv} prefix={moduleServices.module17Data.monthlyNetCashFlowFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.monthlyNetCashFlowFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Annual Budget</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        &nbsp;
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        &nbsp;
                                      </Grid>
                                      <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                        {moduleServices.module17Data.furCareerAdvIconCalcs !== '' && (
                                          <div className={classes.formMainRow}>
                                            <div className={classes.labelText}>
                                              <div className={classes.calcText}>
                                                <span className={classes.familyImage}>
                                                  <img src={familyImage} alt="familyImage" />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)} />
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            <u>
                                              Year
                                              {' '}
                                              {updatedFcadvancementValues.start_year}
                                              {' '}

                                            </u>
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {(moduleServices.module17Data.furCareerAdvIconCalcs !== 0 && moduleServices.module17Data.furCareerAdvIconCalcs !== undefined) && (
                                              <span>
                                                {moduleServices.module17Data.furCareerAdvIconCalcs}
                                              </span>
                                            )}

                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.totalIncomeFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalIncomeFurCareerAdv} prefix={moduleServices.module17Data.totalIncomeFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalIncomeFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.totalIncomeFurCareerAdvCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.totalIncomeFurCareerAdvCalcs} prefix={moduleServices.module17Data.totalIncomeFurCareerAdvCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.totalIncomeFurCareerAdvCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv} prefix={moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.annualTotalTaxesPaidFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.annualTotalExpensesFurCareerAdvCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.annualTotalExpensesFurCareerAdvCalcs} prefix={moduleServices.module17Data.annualTotalExpensesFurCareerAdvCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.annualTotalExpensesFurCareerAdvCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvLivingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvLivingExpenses} prefix={moduleServices.module17Data.furCareerAdvLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvLivingExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvLivingExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvLivingExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvLivingExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvLivingExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvHigherEducationExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvHigherEducationExpenses} prefix={moduleServices.module17Data.furCareerAdvHigherEducationExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvHigherEducationExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvHigherEducationExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvHigherEducationExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvHigherEducationExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvHigherEducationExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvChildrenExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvChildrenExpenses} prefix={moduleServices.module17Data.furCareerAdvChildrenExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvChildrenExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvChildrenExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvChildrenExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvChildrenExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvChildrenExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvPetExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvPetExpenses} prefix={moduleServices.module17Data.furCareerAdvPetExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvPetExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvPetExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvPetExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvPetExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvPetExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvCarExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvCarExpenses} prefix={moduleServices.module17Data.furCareerAdvCarExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvCarExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvCarExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvCarExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvCarExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvCarExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvHomeExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvHomeExpenses} prefix={moduleServices.module17Data.furCareerAdvHomeExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvHomeExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvHomeExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvHomeExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvHomeExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvHomeExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvFinancingExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvFinancingExpenses} prefix={moduleServices.module17Data.furCareerAdvFinancingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvFinancingExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvFinancingExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvFinancingExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvFinancingExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvFinancingExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Annual Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvTotalAnnualExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvTotalAnnualExpenses} prefix={moduleServices.module17Data.furCareerAdvTotalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvTotalAnnualExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs} prefix={moduleServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvTotalAnnualExpensesCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Annual Cash Flow before Retirement Contributions</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvAnnualNetIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvAnnualNetIncome} prefix={moduleServices.module17Data.furCareerAdvAnnualNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvAnnualNetIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvAnnualNetIncomeCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvAnnualNetIncomeCalcs} prefix={moduleServices.module17Data.furCareerAdvAnnualNetIncomeCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvAnnualNetIncomeCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.annualRetirementContributionsFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.annualRetirementContributionsFurCareerAdv} prefix={moduleServices.module17Data.annualRetirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.annualRetirementContributionsFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
								  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>HSA Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.annualHsaContributionsFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.annualHsaContributionsFurCareerAdv} prefix={moduleServices.module17Data.annualHsaContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.annualHsaContributionsFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.annualNetCashFlowFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.annualNetCashFlowFurCareerAdv} prefix={moduleServices.module17Data.annualNetCashFlowFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.annualNetCashFlowFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                              <ReactTooltip id="investTooltip" place="left" type="info" effect="solid" className={classes.tooltipInfoTop} />
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>
                                        {(updatedFcadvancementValues.start_year > 0 && updatedFcadvancementValues.end_year > 0) && (
                                          <span>
                                            {' '}
                                            Budget Summary in years
                                            {' '}
                                            {updatedFcadvancementValues.start_year}
                                            {' '}
                                            to
                                            {' '}
                                            {updatedFcadvancementValues.end_year}
                                          </span>
                                        )}
                                        {(updatedFcadvancementValues.start_year === 0 || updatedFcadvancementValues.end_year === 0) && (
                                          <span>Budget Summary</span>
                                        )}
                                        <div
                                          className={classes.infoIcon}
                                          data-for="investTooltip"
                                          data-tip="Net income is income that is left over after all expenses are paid. Positive net income allows you to make investments, contribute to charity, or save for a car and a home. Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Financial Statements via the Summary Output option in the right menu bar for more details."
                                        >
                                          <img src={infoIconWhite} alt="infoicon" />
                                        </div>
                                      </h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherincomeEarned !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherincomeEarned} prefix={moduleServices.module17Data.furthermoreHigherincomeEarned >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherincomeEarned < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Total Taxes Paid</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid} prefix={moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherEstimatedTaxesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Living Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherlivingExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherlivingExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherlivingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherlivingExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Higher Education Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherEducationExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherEducationExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherEducationExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherEducationExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Children Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherChildrenExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Pet Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherPetExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherPetExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherPetExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherPetExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Car Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherCarExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherCarExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherCarExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherCarExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Real Estate Expenses</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherRealEstateExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Loan Payments</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid} prefix={moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherfinancingExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Total Expenses</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furCareerAdvTotalExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furCareerAdvTotalExpenses} prefix={moduleServices.module17Data.furCareerAdvTotalExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furCareerAdvTotalExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Cash Flow before Retirement Contributions</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furthermoreHigherNetIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furthermoreHigherNetIncome} prefix={moduleServices.module17Data.furthermoreHigherNetIncome >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furthermoreHigherNetIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>Retirement Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.finalRetirementContributionsFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.finalRetirementContributionsFurCareerAdv} prefix={moduleServices.module17Data.finalRetirementContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.finalRetirementContributionsFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
								  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>HSA Contributions</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.finalHsaContributionsFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.finalHsaContributionsFurCareerAdv} prefix={moduleServices.module17Data.finalHsaContributionsFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.finalHsaContributionsFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText)}><strong>Net Cash Flow for Savings and Investments</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.finalNetCashFlowFurCareerAdv !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.finalNetCashFlowFurCareerAdv} prefix={moduleServices.module17Data.finalNetCashFlowFurCareerAdv >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.finalNetCashFlowFurCareerAdv < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsRightBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>401(k) Retirement Account</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Contribute to a 401(k)?</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="contribute" className={updatedFcadvancementValues.contribute_401k === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="contribute"
                                                  name="contribute"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.contribute_401k === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'contribute_401k', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="contribute1" className={updatedFcadvancementValues.contribute_401k === 'No' && classes.checkedEmployment}>
                                                <input
                                                  type="radio"
                                                  id="contribute1"
                                                  name="contribute1"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.contribute_401k === 'No'}
                                                  onClick={(e) => updateValue(e, 'contribute_401k', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Specify 'Yes' if you plan to contribute to a 401(k). Your 401(k) contributions reduce taxable income and reduce your taxes in the short term. See the Boost My Awareness section for more details on contributing to a 401(k) Retirement Account.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div>
                                          {updatedFcadvancementValues.contribute_401k === 'No' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance 401(k)
                                              {' '}
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.contribute_401k === 'Yes' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance 401(k) in Year
                                              {' '}
                                              {updatedFcadvancementValues.start_year}
                                            </div>
                                          )}

                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                          <Grid item sm={6} xs={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module17Data.beginningBalanceInYearFourth !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.beginningBalanceInYearFourth} prefix={moduleServices.module17Data.beginningBalanceInYearFourth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.beginningBalanceInYearFourth < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="career"
                                                  data-tip="The money you currently have in your 401(k). If you don't have any money in a 401(k) or if this Career Path does not begin in the Start Year, make this $0."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                        </div>
                                      </Grid>
                                      
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Roth Contribute to a 401(k)?</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="roth_contribute" className={updatedFcadvancementValues.roth_contribute_401k === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="roth_contribute"
                                                  name="roth_contribute"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.roth_contribute_401k === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'roth_contribute_401k', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="roth_contribute1" className={updatedFcadvancementValues.roth_contribute_401k === 'No' && classes.checkedEmployment}>
                                                <input
                                                  type="radio"
                                                  id="roth_contribute1"
                                                  name="roth_contribute1"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.roth_contribute_401k === 'No'}
                                                  onClick={(e) => updateValue(e, 'roth_contribute_401k', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Specify 'Yes' if you plan to contribute to a 401(k). Your 401(k) contributions reduce taxable income and reduce your taxes in the short term. See the Boost My Awareness section for more details on contributing to a 401(k) Retirement Account.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div>
                                          Beginning Balance 401(k)
                                          {/* {updatedFcadvancementValues.roth_contribute_401k === 'No' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance 401(k)
                                              {' '}
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.roth_contribute_401k === 'Yes' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance 401(k) in Year
                                              {' '}
                                              {updatedFcadvancementValues.start_year}
                                            </div>
                                          )} */}

                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                          <Grid item sm={6} xs={6} className={classes.noSpace}>
                                            <div className={classes.inputRight}>
                                              <span className={classes.noInputText}>
                                                {moduleServices.module17Data.beginningBalanceInYearFourthRoth !== undefined ? (
                                                  <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.beginningBalanceInYearFourthRoth} prefix={moduleServices.module17Data.beginningBalanceInYearFourthRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.beginningBalanceInYearFourthRoth < 0 && ')'} />
                                                ) : ('$0')}
                                              </span>
                                              <div className={classes.infoIcon}>
                                                <img
                                                  src={infoIcon}
                                                  alt="infoicon"
                                                  data-for="career"
                                                  data-tip="The money you currently have in your 401(k). If you don't have any money in a 401(k) or if this Career Path does not begin in the Start Year, make this $0."
                                                />
                                              </div>
                                            </div>
                                          </Grid>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>401(k) Contribution as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            disabled={updatedFcadvancementValues.contribute_401k === 'No' || updatedFcadvancementValues.self_employment === 'Yes'}
                                            value={updatedFcadvancementValues.base_income}
                                            onValueChange={(e) => updateValue(e, 'base_income', 'number')}
                                            decimalScale = { isFocus!=='base_income'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='base_income'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('base_income'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="Contributions to your 401(k) are calculated as % of your Base Income. 401(k) contributions reduce your taxable income. See how varying %s can impact your Ending 401(k) Balance and your Net Worth. Use the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs} prefix={moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Roth 401(k) Contribution as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            disabled={updatedFcadvancementValues.roth_contribute_401k === 'No' || updatedFcadvancementValues.self_employment === 'Yes'}
                                            value={updatedFcadvancementValues.base_roth_income}
                                            onValueChange={(e) => updateValue(e, 'base_roth_income', 'number')}
                                            decimalScale = { isFocus!=='base_roth_income'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='base_roth_income'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('base_roth_income'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="Contributions to your 401(k) are calculated as % of your Base Income. 401(k) contributions reduce your taxable income. See how varying %s can impact your Ending 401(k) Balance and your Net Worth. Use the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth} prefix={moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPersonal401ContributionBaseIncomeCalcsRoth < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Max 401(k) Contributions by Law
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareer401PersonalContributionRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401PersonalContributionRoth} prefix={moduleServices.module17Data.furtherCareer401PersonalContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401PersonalContributionRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Personal Contribution
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareer401PersonalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401PersonalContribution} prefix={moduleServices.module17Data.furtherCareer401PersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401PersonalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Employer Matching Rate on Personal Contribution</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            disabled={updatedFcadvancementValues.contribute_401k === 'No' || updatedFcadvancementValues.self_employment === 'Yes'}
                                            value={updatedFcadvancementValues.employer_matching_rate}
                                            onValueChange={(e) => updateValue(e, 'employer_matching_rate', 'number')}
                                            decimalScale = { isFocus!=='employer_matching_rate'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='employer_matching_rate'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('employer_matching_rate'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The rate at which your employer will match your contributions. An employer that matches 100%, or dollar-for-dollar, would contribute $1.00 to your 401(k) for every $1.00 you contribute. See Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.furtherCareer401EmployerMatchingrateCalcs() !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.furtherCareer401EmployerMatchingrateCalcs()} prefix={moduleServices.furtherCareer401EmployerMatchingrateCalcs() >= 0 ? '$' : '($'} suffix={moduleServices.furtherCareer401EmployerMatchingrateCalcs() < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Employer Matching Limit as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            disabled={updatedFcadvancementValues.contribute_401k === 'No' || updatedFcadvancementValues.self_employment === 'Yes'}
                                            value={updatedFcadvancementValues.employer_matching_limit}
                                            onValueChange={(e) => updateValue(e, 'employer_matching_limit', 'number')}
                                            decimalScale = { isFocus!=='employer_matching_limit'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='employer_matching_limit'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('employer_matching_limit'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage limit of your base income you can contribute to a 401(k) plan and receive matching funds from your employer. If this threshold is reached, the FC will only contribute the matching limit. See the Boost My Awareness section for an example."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.furtherCareer401EmployerMatchingLimitCalcs() !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.furtherCareer401EmployerMatchingLimitCalcs()} prefix={moduleServices.furtherCareer401EmployerMatchingLimitCalcs() >= 0 ? '$' : '($'} suffix={moduleServices.furtherCareer401EmployerMatchingLimitCalcs() < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Employer Contribution (Lesser or Matching Rate and Limit)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareer401EmployerContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401EmployerContribution} prefix={moduleServices.module17Data.furtherCareer401EmployerContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401EmployerContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Employer Contribution is capped at the Employer Matching Limit shown above. If the Personal Contribution plus the Employer Contribution is greater than the Total Annual Contribution limit, the FC will lower the Employer Contribution to meet this limit.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Combined Contribution (Max of $55,000 by law)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareer401TotalAnnualContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401TotalAnnualContribution} prefix={moduleServices.module17Data.furtherCareer401TotalAnnualContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401TotalAnnualContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Annual Expected Pre-Tax Return</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            disabled={updatedFcadvancementValues.contribute_401k === 'No'}
                                            value={updatedFcadvancementValues.Annual_expected401k}
                                            onValueChange={(e) => updateValue(e, 'Annual_expected401k', 'number')}
                                            decimalScale = { isFocus!=='Annual_expected401k'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='Annual_expected401k'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('Annual_expected401k'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage return you expect to receive each year on your 401(k). A typical range would be between 5-10%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Vested Amount at End of Career Move</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            disabled={updatedFcadvancementValues.contribute_401k === 'No'}
                                            value={updatedFcadvancementValues.vested_amount_at_end_year}
                                            onValueChange={(e) => updateValue(e, 'vested_amount_at_end_year', 'number')}
                                            decimalScale = { isFocus!=='vested_amount_at_end_year'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='vested_amount_at_end_year'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('vested_amount_at_end_year'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage return you expect to receive each year on your 401(k). A typical range would be between 5-10%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>401(k) Retirement Account Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.contribute_401k === 'Yes' && (

                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning 401(k) Balance in Year
                                                {' '}
                                                {updatedFcadvancementValues.start_year}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.contribute_401k === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning 401(k) Balance
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.beginningBalanceInYearFourth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.beginningBalanceInYearFourth} prefix={moduleServices.module17Data.beginningBalanceInYearFourth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.beginningBalanceInYearFourth < 0 && ')'} /> 
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.beginningBalanceInYearFourthRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.beginningBalanceInYearFourthRoth} prefix={moduleServices.module17Data.beginningBalanceInYearFourthRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.beginningBalanceInYearFourthRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Personal Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryPersonalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryPersonalContribution} prefix={moduleServices.module17Data.further401kSummaryPersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryPersonalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryPersonalContributionRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryPersonalContributionRoth} prefix={moduleServices.module17Data.further401kSummaryPersonalContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryPersonalContributionRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>


                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Earnings on Personal Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution} prefix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth} prefix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnPersonalContributionRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Total Personal Contributions & Earnings</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings} prefix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarnings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth} prefix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalPersonalContributionsAndEarningsRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Employer Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryEmployerContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEmployerContribution} prefix={moduleServices.module17Data.further401kSummaryEmployerContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEmployerContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryEmployerContributionRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEmployerContributionRoth} prefix={moduleServices.module17Data.further401kSummaryEmployerContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEmployerContributionRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Earnings on Employer Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution} prefix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth} prefix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryEarningsOnEmployerContributionRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Total Employer Contributions & Earnings</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings} prefix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarnings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth} prefix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.further401kSummaryTotalEmployerContributionsAndEarningsRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>401(k) Balance before Vesting Reductions</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherBalanceBeforeVestingReductions !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherBalanceBeforeVestingReductions} prefix={moduleServices.module17Data.furtherBalanceBeforeVestingReductions >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherBalanceBeforeVestingReductions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherBalanceBeforeVestingReductionsRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherBalanceBeforeVestingReductionsRoth} prefix={moduleServices.module17Data.furtherBalanceBeforeVestingReductionsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.balanceBeforeVestingReductionsAdd < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            % Vested after
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            years at 25%/yr Schedule
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur} prefix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur >= 0 ? '' : '('} suffix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFur < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The FC vests your 401(k) contributions at 25% per year. Vesting means how much of your 401(k) funds, that were contributed by your employer, you can take with you when you leave. Your Personal Contributions & Earnings are 100% vested (yours to take).">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth !== undefined ? (
                                              <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth} prefix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth >= 0 ? '' : '('} suffix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedFurRoth < 0 ? '%)' : '%'} />
                                            ) : ('0%')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The FC vests your 401(k) contributions at 25% per year. Vesting means how much of your 401(k) funds, that were contributed by your employer, you can take with you when you leave. Your Personal Contributions & Earnings are 100% vested (yours to take).">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  {/* <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.contribute_401k === 'Yes' && (
                                            <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                              % Not Vested Returns to Employer
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.contribute_401k === 'No' && (
                                            <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                              Vesting Reductions
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.employerContributionsAndEarningsNotVestedAdd !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.employerContributionsAndEarningsNotVestedAdd} prefix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedAdd >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.employerContributionsAndEarningsNotVestedAdd < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="For example, if you are 50% vested, 50% of your Total Employer Contributions & Earnings will be returned to your employer when you leave this job. See the Boost My Awareness section for more details on vesting.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div> */}
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.contribute_401k === 'Yes' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending 401(k) Balance in Year
                                                {' '}
                                                {updatedFcadvancementValues.end_year}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.contribute_401k === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending 401(k) Balance

                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareer401kEndingBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401kEndingBalance} prefix={moduleServices.module17Data.furtherCareer401kEndingBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401kEndingBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareer401kEndingBalanceRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareer401kEndingBalanceRoth} prefix={moduleServices.module17Data.furtherCareer401kEndingBalanceRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareer401kEndingBalanceRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>IRA Retirement Account</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Contribute to a Traditional IRA?</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="roth" className={updatedFcadvancementValues.roth_contribute === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="roth"
                                                  name="roth"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.roth_contribute === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'roth_contribute', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="roth1" className={updatedFcadvancementValues.roth_contribute === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="roth1"
                                                  name="roth1"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.roth_contribute === 'No'}
                                                  onClick={(e) => updateValue(e, 'roth_contribute', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Specify 'Yes' if you plan to contribute to Roth IRA. Roth IRA contributions do not reduce your taxable income as they are funded with after-tax money. When you go to retire, you don't pay taxes on Roth IRA. Use Boost My Awareness section for more details.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Beginning Balance Traditional IRA
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRABeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRABeginningBalance} prefix={moduleServices.module17Data.furtherCareerRothIRABeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRABeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Contribute to a Roth IRA</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="ira_roth" className={updatedFcadvancementValues.ira_roth_contribute === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="ira_roth"
                                                  name="ira_roth"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.ira_roth_contribute === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'ira_roth_contribute', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="ira_roth1" className={updatedFcadvancementValues.ira_roth_contribute === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="ira_roth1"
                                                  name="ira_roth1"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.ira_roth_contribute === 'No'}
                                                  onClick={(e) => updateValue(e, 'ira_roth_contribute', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Specify Yes if you plan to contribute to Roth IRA. Roth IRA contributions do not reduce your taxable income as they are funded with after-tax money. When you go to retire, you don't pay taxes on Roth IRA. Use Boost My Awareness section for more details.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Beginning Balance Roth IRA
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.AddCareer401EmployerMatchingLimitCalcs !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.AddCareer401EmployerMatchingLimitCalcs} prefix={moduleServices.module17Data.AddCareer401EmployerMatchingLimitCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.AddCareer401EmployerMatchingLimitCalcs < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Traditional IRA Contribution as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.roth_personal_contribution}
                                            disabled={updatedFcadvancementValues.roth_contribute === 'No'}
                                            onValueChange={(e) => updateValue(e, 'roth_personal_contribution', 'number')}
                                            decimalScale = { isFocus!=='roth_personal_contribution'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='roth_personal_contribution'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('roth_personal_contribution'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="Contributions to your Roth IRA are calculated as a % of your Base Income. See how varying %s can impact your Ending Roth IRA Balance and your Net Worth. See Boost My Awareness for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.furtherCareerRothIRAPersonalContributionCalcs !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRAPersonalContributionCalcs} prefix={moduleServices.module17Data.furtherCareerRothIRAPersonalContributionCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRAPersonalContributionCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Roth IRA Contribution as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.ira_roth_personal_contribution}
                                            disabled={updatedFcadvancementValues.ira_roth_contribute === 'No'}
                                            onValueChange={(e) => updateValue(e, 'ira_roth_personal_contribution', 'number')}
                                            decimalScale = { isFocus!=='ira_roth_personal_contribution'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='ira_roth_personal_contribution'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('ira_roth_personal_contribution'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="Contributions to your Roth IRA are calculated as a % of your Base Income. See how varying %s can impact your Ending Roth IRA Balance and your Net Worth. See Boost My Awareness for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.furtherCareerRothIRAPersonalContributionCalcsRoth !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.addCareerRothIRAPersonalContributionCalcs} prefix={moduleServices.module17Data.addCareerRothIRAPersonalContributionCalcs >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.addCareerRothIRAPersonalContributionCalcs < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Max IRA Contributions by Law
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRAPersonalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRAPersonalContribution} prefix={moduleServices.module17Data.furtherCareerRothIRAPersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRAPersonalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Total Contribution
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module8Data.furtherCareerRothIRATotalContributionIra !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module8Data.furtherCareerRothIRATotalContributionIra} prefix={moduleServices.module8Data.furtherCareerRothIRATotalContributionIra >= 0 ? '$' : '($'} suffix={moduleServices.module8Data.furtherCareerRothIRATotalContributionIra < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Annual Expected Pre-Tax Return</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.roth_annual_expected_pre_tax}
                                            disabled={updatedFcadvancementValues.roth_contribute === 'No'}
                                            onValueChange={(e) => updateValue(e, 'roth_annual_expected_pre_tax', 'number')}
                                            decimalScale = { isFocus!=='roth_annual_expected_pre_tax'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='roth_annual_expected_pre_tax'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('roth_annual_expected_pre_tax'); }}
                                            onBlur={() => { setIsFocus(''); }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage return you expect to receive each year on your Roth IRA. A typical range would be between 5-10%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Roth IRA Retirement Account Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.roth_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning Roth IRA Balance in Year
                                                {' '}
                                                {updatedFcadvancementValues.start_year}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.roth_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning Roth IRA Balance
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRABeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRABeginningBalance} prefix={moduleServices.module17Data.furtherCareerRothIRABeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRABeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRABeginningBalanceRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRABeginningBalanceRoth} prefix={moduleServices.module17Data.furtherCareerRothIRABeginningBalanceRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRABeginningBalanceRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRATotalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRATotalContribution} prefix={moduleServices.module17Data.furtherCareerRothIRATotalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRATotalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRATotalContributionRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRATotalContributionRoth} prefix={moduleServices.module17Data.furtherCareerRothIRATotalContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRATotalContributionRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Roth IRA Contributions are subject to phaseout reductions depending on marriage status and income level. Sounds confusing, but if you check out the Definition for Roth IRA in the Boost My Awareness section, we walk you through a simple example.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Earnings After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRATotalEarnings !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRATotalEarnings} prefix={moduleServices.module17Data.furtherCareerRothIRATotalEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRATotalEarnings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRATotalEarningsRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRATotalEarningsRoth} prefix={moduleServices.module17Data.furtherCareerRothIRATotalEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRATotalEarningsRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.roth_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending Roth IRA Balance in Year
                                                {' '}
                                                {updatedFcadvancementValues.end_year}
                                                {' '}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.roth_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending Roth IRA Balance
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRAEndingBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRAEndingBalance} prefix={moduleServices.module17Data.furtherCareerRothIRAEndingBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRAEndingBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>

                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerRothIRAEndingBalanceRoth !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerRothIRAEndingBalanceRoth} prefix={moduleServices.module17Data.furtherCareerRothIRAEndingBalanceRoth >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerRothIRAEndingBalanceRoth < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>SEP IRA Retirement Account</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Contribute to a SEP IRA?</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="sep" className={updatedFcadvancementValues.ira_contribute === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="sep"
                                                  name="sep"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.ira_contribute === 'Yes'}
                                                  onChange={(e) => updateValue(e, 'ira_contribute', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="sep1" className={updatedFcadvancementValues.ira_contribute === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="sep1"
                                                  name="sep1"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.ira_contribute === 'No'}
                                                  onChange={(e) => updateValue(e, 'ira_contribute', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classNames(classes.infoIcon, classes.checkInfoIcon)} data-for="detailsTooltip" data-tip="Specify 'Yes' if you plan to contribute to a SEP IRA. Your SEP IRA contributions reduce taxable income and reduce your taxes in the short term. See the Boost My Awareness section for more details on contributing to a SEP IRA Retirement Account.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.ira_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.ira_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance in Year
                                              {' '}
                                              {updatedFcadvancementValues.start_year}
                                              {' '}
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight)}>
                                          {moduleServices.module17Data.furtherCareerSEPIRABeginningBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerSEPIRABeginningBalance} prefix={moduleServices.module17Data.furtherCareerSEPIRABeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerSEPIRABeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Personal Contribution as a % of Base Income (25% Limit)</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.ira_personal_contribution}
                                            disabled={updatedFcadvancementValues.ira_contribute === 'No' || updatedFcadvancementValues.self_employment === 'No'}
                                            onValueChange={(e) => updateValue(e, 'ira_personal_contribution', 'number')}
                                            decimalScale = { isFocus!=='ira_personal_contribution'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='ira_personal_contribution'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('ira_personal_contribution'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="Contribute to your SEP IRA a % of your Base Income. The law permits up to 25% of your income with a $275,000 income cap. See how varying %s can impact your Ending SEP IRA Balance and your Net Worth. See Boost My Awareness for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.furtherCareerSEPIRAPersonalContribution !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerSEPIRAPersonalContribution} prefix={moduleServices.module17Data.furtherCareerSEPIRAPersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerSEPIRAPersonalContribution < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Personal Contribution (Max of the Lesser of 25% of Income or
                                            {moduleServices.module17Data.advCareerSEPIRAPersonalContribution_sepIra !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.advCareerSEPIRAPersonalContribution_sepIra} prefix={moduleServices.module17Data.advCareerSEPIRAPersonalContribution_sepIra >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.advCareerSEPIRAPersonalContribution_sepIra < 0 && ')'} />
                                            ) : ('$0')}
                                            by law)
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.addCareerSEPIRAPersonalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.addCareerSEPIRAPersonalContribution} prefix={moduleServices.module17Data.addCareerSEPIRAPersonalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.addCareerSEPIRAPersonalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If the Personal Contribution is greater than the $55,000 Contribution limit or 25% of your Base Income (up to $275,000 if the limit used in that calculation), the FC will lower your Personal Contribution to meet the lower of the two.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Annual Expected Pre-Tax Return</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.ira_annual_expected_pre_tax}
                                            disabled={updatedFcadvancementValues.ira_contribute === 'No'}
                                            onValueChange={(e) => updateValue(e, 'ira_annual_expected_pre_tax', 'number')}
                                            decimalScale = { isFocus!=='ira_annual_expected_pre_tax'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='ira_annual_expected_pre_tax'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('ira_annual_expected_pre_tax'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage return you expect to receive each year on your SEP IRA. A typical range would be between 5-10%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>SEP IRA Retirement Account Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.ira_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning SEP IRA Balance in Year
                                                {updatedFcadvancementValues.start_year}
                                                {' '}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.ira_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning SEP IRA Balance
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.addCareerSEPIRABeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.addCareerSEPIRABeginningBalance} prefix={moduleServices.module17Data.addCareerSEPIRABeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.addCareerSEPIRABeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerSEPIRATotalContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerSEPIRATotalContribution} prefix={moduleServices.module17Data.furtherCareerSEPIRATotalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerSEPIRATotalContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Earnings After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerSEPIRATotalEarnings !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerSEPIRATotalEarnings} prefix={moduleServices.module17Data.furtherCareerSEPIRATotalEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerSEPIRATotalEarnings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.ira_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending SEP IRA Balance in Year
                                                {' '}
                                                {updatedFcadvancementValues.end_year}
                                                {' '}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.ira_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending SEP IRA Balance
                                                {' '}
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerEndingSEPIRABalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerEndingSEPIRABalance} prefix={moduleServices.module17Data.furtherCareerEndingSEPIRABalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerEndingSEPIRABalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Pension</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Contribute to a Pension?</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="Pension" className={updatedFcadvancementValues.pension_contribute === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Pension"
                                                  name="Pension"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.pension_contribute === 'Yes'}
                                                  onChange={(e) => updateValue(e, 'pension_contribute', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="Pension1" className={updatedFcadvancementValues.pension_contribute === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="Pension1"
                                                  name="Pension1"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.pension_contribute === 'No'}
                                                  onChange={(e) => updateValue(e, 'pension_contribute', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classNames(classes.infoIcon, classes.checkInfoIcon)} data-for="detailsTooltip" data-tip="Specify 'Yes' if you plan to contribute to a Pension. Your Pension contributions reduce taxable income and reduce your taxes in the short term. See the Boost My Awareness section for more details on contributing to a Pensions.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          {updatedFcadvancementValues.pension_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.pension_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              Beginning Balance in Year
                                              {' '}
                                              {updatedFcadvancementValues.start_year}
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.inputCa)}>
                                          {moduleServices.module17Data.furtherCareerPensionBeginningBalance !== undefined ? (
                                            <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionBeginningBalance} prefix={moduleServices.module17Data.furtherCareerPensionBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionBeginningBalance < 0 && ')'} />
                                          ) : ('$0')}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Personal Contribution as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.pension_employer_contribution}
                                            disabled={updatedFcadvancementValues.pension_contribute === 'No' || updatedFcadvancementValues.self_employment === 'Yes'}
                                            onValueChange={(e) => updateValue(e, 'pension_employer_contribution', 'number')}
                                            decimalScale = { isFocus!=='pension_employer_contribution'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='pension_employer_contribution'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('pension_employer_contribution'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="Contributions to your Pension are calculated as % of your Base Income. Pension contributions reduce your taxable income. See how varying %s can impact your Ending Pension Balance and your Net Worth. Use the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.furtherCareerPensionPersonalContributionCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionPersonalContributionCalc} prefix={moduleServices.module17Data.furtherCareerPensionPersonalContributionCalc >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionPersonalContributionCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Employer Contribution as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.pension_personal_contribution}
                                            disabled={updatedFcadvancementValues.pension_contribute === 'No' || updatedFcadvancementValues.self_employment === 'Yes'}
                                            onValueChange={(e) => updateValue(e, 'pension_personal_contribution', 'number')}
                                            decimalScale = { isFocus!=='pension_personal_contribution'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='pension_personal_contribution'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('pension_personal_contribution'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage of your base income that your employer will contribute to your Pension. This can vary depending on your planned career. See the Boost My Awareness section for more details and some links to possible Pension contributions."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.furtherCareerPensionEmployerContributionCalc !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionEmployerContributionCalc} prefix={moduleServices.module17Data.furtherCareerPensionEmployerContributionCalc >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionEmployerContributionCalc < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>Personal Contribution</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerPensionEmployerContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionEmployerContribution} prefix={moduleServices.module17Data.furtherCareerPensionEmployerContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionEmployerContribution < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If the Personal Contribution plus the Employer Contribution is greater than the Total Annual Contribution limit, the FC will lower your Personal Contribution to meet this limit.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={6} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Total Annual Contribution (Max of
                                            {moduleServices.module17Data.furtherCareerPensionEmployerContribution !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionEmployerContribution} prefix={moduleServices.module17Data.furtherCareerPensionEmployerContribution >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionEmployerContribution < 0 && ')'} />
                                            ) : ('$0')}
                                            by law)
                                          </div>
                                        </div>
                                      </Grid>                                      
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Annual Expected Pre-Tax Return</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.pension_annual_expected_pre_tax}
                                            disabled={updatedFcadvancementValues.pension_contribute === 'No'}
                                            onValueChange={(e) => updateValue(e, 'pension_annual_expected_pre_tax', 'number')}
                                            decimalScale = { isFocus!=='pension_annual_expected_pre_tax'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='pension_annual_expected_pre_tax'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('pension_annual_expected_pre_tax'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage return you expect to receive each year on your Pension funds. A typical pension target would be around 7%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>Pension Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.pension_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning Pension Balance in Year
                                                {' '}
                                                {updatedFcadvancementValues.start_year}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.pension_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Beginning Pension Balance
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerPensionBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionBeginningBalance} prefix={moduleServices.module17Data.furtherCareerPensionBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Personal Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerPensionTotalPersonalContributions !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionTotalPersonalContributions} prefix={moduleServices.module17Data.furtherCareerPensionTotalPersonalContributions >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionTotalPersonalContributions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Employer Contributions After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerPensionTotalPersonalContributions !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionTotalEmployerContributions} prefix={moduleServices.module17Data.furtherCareerPensionTotalEmployerContributions >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionTotalEmployerContributions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Earnings After
                                            {' '}
                                            {totalCharitableYear}
                                            {' '}
                                            Years
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerPensionTotalEarnings !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerPensionTotalEarnings} prefix={moduleServices.module17Data.furtherCareerPensionTotalEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerPensionTotalEarnings < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.pension_contribute === 'Yes' && (

                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending Pension Balance in Year
                                                {' '}
                                                {updatedFcadvancementValues.end_year}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.pension_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending Pension Balance
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerEndingPensionBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerEndingPensionBalance} prefix={moduleServices.module17Data.furtherCareerEndingPensionBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerEndingPensionBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>

                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Health Savings Account (HSA)</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Contribute to Health Savings Account</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.checkboxBlock}>
                                          <div className={classNames(classes.radioGroup, classes.displayRadio)}>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="hsa" className={updatedFcadvancementValues.contribute_hsa_savings_account === 'Yes' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="hsa"
                                                  name="hsa"
                                                  value="Yes"
                                                  checked={updatedFcadvancementValues.contribute_hsa_savings_account === 'Yes'}
                                                  onClick={(e) => updateValue(e, 'contribute_hsa_savings_account', 'radio')}
                                                />
                                                Yes
                                              </label>
                                            </div>
                                            <div className={classNames(classes.checkboxRadio, classes.radioCheckbox)}>
                                              <label htmlFor="hsa1" className={updatedFcadvancementValues.contribute_hsa_savings_account === 'No' ? classes.checkedEmployment : ''}>
                                                <input
                                                  type="radio"
                                                  id="hsa1"
                                                  name="hsa1"
                                                  value="No"
                                                  checked={updatedFcadvancementValues.contribute_hsa_savings_account === 'No'}
                                                  onClick={(e) => updateValue(e, 'contribute_hsa_savings_account', 'radio')}
                                                />
                                                No
                                              </label>
                                            </div>
                                            <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Specify 'Yes' if you plan to contribute to Roth IRA. Roth IRA contributions do not reduce your taxable income as they are funded with after-tax money. When you go to retire, you don't pay taxes on Roth IRA. Use Boost My Awareness section for more details.">
                                              <img src={infoIcon} alt="infoicon" />
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Beginning Balance
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerHsaBeginningBalance !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaBeginningBalance} prefix={moduleServices.module17Data.furtherCareerHsaBeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaBeginningBalance < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew, classes.cpBigInputbox)}>
                                          <div className={classes.spanText}>Personal Contribution as a % of Base Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew, classes.cpBigInput)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.hsa_personal_contribution}
                                            disabled={updatedFcadvancementValues.contribute_hsa_savings_account === 'No'}
                                            onValueChange={(e) => updateValue(e, 'hsa_personal_contribution', 'number')}
                                            decimalScale = { isFocus!=='hsa_personal_contribution'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='hsa_personal_contribution'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('hsa_personal_contribution'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="Contribute to your SEP IRA a % of your Base Income. The law permits up to 25% of your income with a $275,000 income cap. See how varying %s can impact your Ending SEP IRA Balance and your Net Worth. See Boost My Awareness for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock}>
                                  <div className={classes.formMainRow}>
                                    <div className={classes.labelText}>
                                      <div className={classNames(classes.calcText, classes.calcTextNew)}>
                                        {moduleServices.module17Data.maxHsaContributionByLawFurtherCareer !== undefined ? (
                                          <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.maxHsaContributionByLawFurtherCareer} prefix={moduleServices.module17Data.maxHsaContributionByLawFurtherCareer >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.maxHsaContributionByLawFurtherCareer < 0 && ')'} />
                                        ) : ('$0')}
                                      </div>
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.spanInherit)}>
                                            Max HSA Contribution by Law
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.maxHsaContributionByLawFurtherCareer !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.maxHsaContributionByLawFurtherCareer} prefix={moduleServices.module17Data.maxHsaContributionByLawFurtherCareer >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.maxHsaContributionByLawFurtherCareer < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="If the Personal Contribution is greater than the $55,000 Contribution limit or 25% of your Base Income (up to $275,000 if the limit used in that calculation), the FC will lower your Personal Contribution to meet the lower of the two.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={9} xs={9} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classNames(classes.insideSpan, classes.bigInputBoxnew)}>
                                          <div className={classes.spanText}>Annual Expected Pre-Tax Return</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.bigInputNew)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            thousandSeparator
                                            customInput={TextField}
                                            value={updatedFcadvancementValues.hsa_annual_expected}
                                            disabled={updatedFcadvancementValues.contribute_hsa_savings_account === 'No'}
                                            onValueChange={(e) => updateValue(e, 'hsa_annual_expected', 'number')}
                                            decimalScale = { isFocus!=='hsa_annual_expected'? 2: 5 }
                                            fixedDecimalScale={isFocus!=='hsa_annual_expected'}
                                            onFocus={ ( e ) => { e.target.select(); setIsFocus('hsa_annual_expected'); }}
                                            onBlur={(e) => { 
                                              setIsFocus('');
                                            }}
                                          />
                                          <span className={classes.percentSymbol}>%</span>
                                          <div className={classes.infoIcon}>
                                            <img
                                              src={infoIcon}
                                              alt="infoicon"
                                              data-for="career"
                                              data-tip="The percentage return you expect to receive each year on your 401(k). A typical range would be between 5-10%. See the Boost My Awareness section for more details."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={3} xs={3} className={classes.calcBlock} />
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      <h3>HSA Summary</h3>
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          Beginning HSA Balance
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary} prefix={moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaBeginningBalanceSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Contributions
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerHsaTotalContributionSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaTotalContributionSummary} prefix={moduleServices.module17Data.furtherCareerHsaTotalContributionSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaTotalContributionSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            Total Earnings
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary} prefix={moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaTotalEarningsSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          {updatedFcadvancementValues.ira_contribute === 'Yes' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending HSA Balance
                                                {' '}
                                                {updatedFcadvancementValues.end_year}
                                                {' '}
                                              </strong>
                                            </div>
                                          )}
                                          {updatedFcadvancementValues.ira_contribute === 'No' && (
                                            <div className={classes.spanText}>
                                              <strong>
                                                Ending HSA Balance
                                                {' '}
                                              </strong>
                                            </div>
                                          )}
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary} prefix={moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary >= 0 ? '$' : '($'} suffix={moduleServices.module17Data.furtherCareerHsaTotalEndingBalanceSummary < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p><strong>Cash Flow Check</strong></p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
              {/* {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} /> )} */}
            </div>
            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.furtherCareerWalk2) : classNames(classes.showMe, classes.showSection)} id="slide-five">
              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                {dropdownvalues.showMe && (
                  <div>
                    {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                      <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                    )}
                    {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Refresh</Button>)}
                  </div>
                )}
              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph3 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>{moduleServices.module17Data.occupation}: Cash Flow Summary</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph3Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph2 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>My Annual Budget in Year {moduleServices.module17Data.start_year}</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph2Data}
                        />
                      </div>
                      <div>Total: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.annualTotal} prefix='$' /></div>
                    </div>
                  )}
                  {showMeData.graph1 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>{moduleServices.module17Data.occupation}: Living Expenses Summary</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph1Data}
                        />
                      </div>
                      <div>Total Living Expenses: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.pieTotal} prefix='$' /></div>
                    </div>
                  )}
                  {(showMeData.graph4 === true && updatedFcadvancementValues.contribute_401k === 'Yes') && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>401(k) Retirement Account</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph4Data}
                        />
                      </div>
                    </div>
                  )}
                  {(showMeData.graph5 === true && updatedFcadvancementValues.roth_contribute === 'Yes') && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Roth IRA Retirement Account</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph5Data}
                        />
                      </div>
                    </div>
                  )}
                  {(showMeData.graph6 === true && updatedFcadvancementValues.ira_contribute === 'Yes') && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>SEP IRA Retirement Account</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph6Data}
                        />
                      </div>
                    </div>
                  )}
                  {(showMeData.graph7 === true && updatedFcadvancementValues.pension_contribute === 'Yes') && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Pension Retirement Account</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph7Data}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Career Advancement
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>How do I know what I could expect to make in a Career? How do I know if I will be making any Bonus / Tips / Commission?</h3>
                          <p>
                            Research your planned career move on
                            {' '}
                            <a href="https://www.payscale.com/research/US/Country=United_States/Salary" target="_blank" rel="noopener noreferrer" className={classes.noLink}>PayScale</a>
                            {' '}
                            or
                            {' '}
                            <a href="https://www.glassdoor.com/Salaries/index.htm" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Glassdoor</a>
                            {' '}
                            to find out how much you can expect to make. Location is an important factor for salary so don&apos;t forget when researching a career to input the city you plan to live in! Check out Quick Links for some great resources on this topic.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC estimate my taxes?</h3>
                          <p>
                            An example of how the FC estimates taxes is shown below for a single person (for both single and married tax brackets, click
                            {' '}
                            <a href="https://taxfoundation.org/2017-tax-brackets/" target="_blank" rel="noopener noreferrer">here</a>
                            ) making $50,000. For the Career modules, the FC applies a simpler equation to estimate taxes so you have an idea of what you might pay as you build a scenario. It&apos;s important to note that taxes are estimated with much greater accuracy on the Income Statement.
                          </p>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={estimatedTax} alt="table" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Do I still input Utilities if I have already bought a home? What happens if I buy a home after the year my new career move starts?</h3>
                          <p>
                            If you are planning a career move and have already bought a home, you would leave your Utilities at 0 as the Utilities that you input in the Buying a Home module would override the Career module. If, for example, you plan on making a career move for 2025 to 2028 and also plan on buying a home in 2026, the FC would use your Utilities inputted in the Career module in 2025.
                            Then, the Utilities from the Buying a Home module would override the Career module and begin in 2026.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Do I still input Car Payment if I have already bought a car? What happens if I buy a car after the year my new career move starts?</h3>
                          <p>
                            If you are planning a career move and have already bought a car, you would leave your Car Payment at 0. The FC assumes that once you plan to purchase a car, you will no longer be making Car Payments. If, for example, you plan on making a career move for 2025 to 2028 and also plan on buying a car in 2026, the FC would automatically update your Car Payment to 0 in year 2026.
                            Car Maintenance and Car Insurance will continue as these are expenses you have whether leasing or owning a car.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the beginning balance for retirement accounts work? How come in the Career Path module I can input my beginning balance but in other Career modules I can&apos;t?</h3>
                          <p>
                            The beginning balance for retirement accounts is only available as an input in the Career Path module, which is the first Career module of the set. Why? Well a beginning balance means that you have a balance today. Since this is the first of the Career moves, only this module is used to capture the beginning balances.
                          </p>
                          <p>If you have a current balance in a retirement account, then you should input that balance in the Career Path module. Even if you are not contributing to that retirement account at this time, that money is still invested and earning a return. By inputting a beginning balance and the expected return, the FC will account for those earnings.</p>
                          <p>
                            For example, let&apos;s say we have $5,000 in a 401(k) and $2,500 in a Roth IRA today. In our current job, which we are inputting into the Career Path module, we are only contributing to the 401(k) and not the Roth IRA. We would enter $5,000 for beginning balance of 401(k) and $2,500 for Roth IRA and check &quot;Yes&quot; for both accounts. For 401(k), we would complete the rest of the inputs.
                            For Roth IRA, the only inputs needed would be to check &quot;Yes&quot;, input the beginning balance, and input a return value. The FC will automatically turn on and off these inputs to guide you.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Some inputs are not available for 401(k) and Pension? And, sometimes for SEP IRA. Why is that?</h3>
                          <p>Great question.. If you are Self-Employed, the FC assumes that you cannot contribute to a 401(k) or a Pension fund as these are employer-sponsored retirement plans. If you are Not Self-Employed, the FC assumes that you cannot contribute to a SEP IRA as this is most commonly used in self-employment.</p>
                          <p>If you have any of these accounts, make sure to input your beginning balances (see question above).</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC tackle Pensions?</h3>
                          <p>The FC assumes all contributions to a Pension are pre-tax and therefore reduce your taxable income (just like a 401(k) contribution). The FC will track your contributions, your employer contributions, and total earnings over the years to give you an idea of how much money has been put towards your retirement.</p>
                          <p>Your pension balance is not what you should expect in retirement. The FC simply tracks the total balance of yours and your company&apos;s contributions plus earnings. To get a better idea of what to expect in retirement, check out this page here.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How do you calculate the return on 401(k) and Roth IRA Retirement Accounts? What happens to my earnings?</h3>
                          <p>
                            The return on 401(k) and Roth IRA Retirement Accounts is compounded on a monthly basis.
                            {' '}
                            <a href="https://www.investopedia.com/terms/c/compoundinterest.asp" className={classes.noLink}>Watch this video</a>
                            {' '}
                            to understand how compounding works. The FC takes the average balance in a given month and multiplies that amount by the Annual Expected Pre-Tax Return divided by 12 (so that it is a monthly return) to calculate your monthly earnings.
                            For example, let&apos;s say you had $10,000 in your 401(k) Retirement Account at the beginning of a month, an Annual Contribution of $1,200 or $100 monthly contribution, and an Annual Expected Pre-Tax Return of 6.0% or a 0.5% monthly return. The average balance in that month would equal $10,000 plus the $100 monthly contribution divided by 2, which equals $10,050. Multiply the $10,050 average balance by 0.5% monthly return and that equals $50.25 in earnings.
                            The balance at the end of that month would equal $10,000 plus $100 monthly contribution plus $50.25 in earnings, or $10,150.25.
                          </p>
                          <p>Compounding Interest is a very powerful force and time plays a crucial factor. See the image below demonstrating the power of Compounding Interest and how to make it work for you.</p>
                        </div>
                        <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                          <img src={powerInt} alt="table" />
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>What is the maximum amount I can contribute to my 401(k) and Roth IRA Retirement Accounts? How does this work in the FC?</h3>
                          <p>
                            As of 2017, the Internal Revenue Service (IRS), which is a U.S. government agency responsible for the collection of taxes and enforcement of tax laws, set the maximum amount you can contribute to your 401(k) in a given year at $18,000. For Roth IRA, the maximum amount is $5,500. In the FC, you contribute to your Retirement Accounts as a % of Gross Income.
                            If the contribution ends up being higher than the maximum amount allowed, the FC only allows you to contribute the maximum amount in any given year. If you do reach the max amount for either 401(k) or Roth IRA, the year that you do will be shown above in the My Details section.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.investopedia.com/terms/1/401kplan.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>401(k) Retirement Account</a>
                          </h3>
                          <p>
                            A retirement savings
                            {' '}
                            <a href="https://www.investopedia.com/articles/personal-finance/091515/best-strategies-maximize-your-401k.asp" className={classes.noLink}>plan</a>
                            {' '}
                            sponsored by an employer. It lets workers save and invest a piece of their paycheck before taxes are taken out. Taxes aren&apos;t paid until the money is withdrawn from the account. Money in this account cannot be withdrawn before the age of 59 1/2 without paying a 10% penalty plus taxes.
                          </p>
                          <p>The 2018 maximum contribution for 401(k) is $18,500 of personal contributions per year; does not include employer contributions.</p>
                          <p>Experts say that if your tax rate will be higher now than when you retire, you should contribute to a 401(k) over a Roth IRA since 401(k) contributions help you save on taxes. Plus, with 401(k), there is usually an employer matching program allowing you to save more. However, If your taxes will be higher in retirement, contributing to a Roth IRA now will provide you with tax-free income later.</p>
                          <p>
                            A quick analysis of 401(k) or Roth IRA can be found
                            {' '}
                            <a href="https://www.investopedia.com/articles/personal-finance/091515/best-strategies-maximize-your-401k.asp" target="_blank" rel="noopener noreferrer">here</a>
                            .
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.investopedia.com/terms/a/annual-return.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Annual Expected Pre-Tax Return</a>
                          </h3>
                          <p>
                            The amount of profit an investment is
                            {' '}
                            <a href="https://www.investopedia.com/terms/e/expectedreturn.asp#ixzz4jFhmjzsQ" target="_blank" rel="noopener noreferrer" className={classes.noLink}>expected</a>
                            {' '}
                            to return each year on a pre-tax basis. A typical return for each retirement account can be found in the My Details section above. But, anywhere from 5-10% would be reasonable. For example, if you have $10,000 invested and expect that investment to return 5% in a year, you will earn $511.62 before taxes. Why is this not an even $500? The FC
                            {' '}
                            <a href="https://www.investopedia.com/terms/e/expectedreturn.asp#ixzz4jFhmjzsQ" target="_blank" rel="noopener noreferrer" className={classes.noLink}>compounds</a>
                            {' '}
                            your return on Retirement Accounts on a monthly basis.
                          </p>
                          <p>In the years that you are not contributing to a retirement account, but have a balance, the FC will assume a 5% return. For example, if I contribute to a 401(k) for 3 years and then stop, that money will still be invested and will still earn a return. So, the FC uses a 5% return to make sure that money is still earning and to be conservative.</p>
                          <p>Use the What If Investments Calculator to build different scenarios and see the effect returns can have on growing an investment. Compounding Interest is a very powerful force and time plays a crucial factor. See the image below demonstrating the power of Compounding Interest and how to make it work for you.</p>
                        </div>
                        <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                          <img src={powerInt} alt="table" />
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Annual Income</h3>
                          <p>
                            Base income earned from your main employment on a salary or hourly basis, including expected overtime. This does not include any Bonus, Tips, Commission, or Supplementary
                            {' '}
                            <a href="https://www.investopedia.com/terms/p/personalincome.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Income</a>
                            . Check out
                            {' '}
                            <a href="https://www.payscale.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>PayScale</a>
                            {' '}
                            for expected income ranges as you explore different careers.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Beginning Balance</h3>
                          <p>The amount of money you currently have invested into any Retirement Account. If you have no money currently invested and plan on contributing to a Retirement Account, make sure to set your beginning balance to $0. Only in the Career Path module can you set your beginning balances.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Bonus / Tips / Commission</h3>
                          <p>
                            Additional compensation given to an employee above his/her base income and is awarded based on performance. Bonus is usually a one-time payment for exceptional individual or company performance. Tips are compensation over and above payment due for service such as a waiter receiving tips for good service.
                            Commission is additional compensation for completing a sale or providing a service and is usually calculated as a percentage of that sale or service like a real estate agent making a commission on a home sale.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Contribution as a % of Gross Income</h3>
                          <p>The percentage of your Gross Income that you choose to contribute to a Retirement Account each year. Many financial experts recommend contributing 10-15% if possible, but adjust the rate and check out your Financial Statements to see what contribution levels you can afford. Make sure that you stay cash flow positive!</p>
                          <p>The 2018 maximum contributions for each retirement account is below:</p>
                          <p>
                            <b>401(k) -</b>
                            {' '}
                            $18,500 of personal contributions per year; does not include employer contributions.
                          </p>
                          <p>
                            <b>Roth IRA -</b>
                            {' '}
                            $5,500 of personal contributions per year; subject to phaseouts (see definition for Roth IRA Phaseouts below).
                          </p>
                          <p>
                            <b>SEP IRA -</b>
                            {' '}
                            the lesser of 25% of Annual Income plus Bonus / Tips / Commission (also known as &quot;Total Compensation&quot;) or $55,000. For the 25% of Total Compensation limit, Total Compensation used in that calculation is capped at $275,000. In other words, if you make $350,000 in Total Compensation, your limit would be $275,000 x 25% = $68,750. Since $68,750 is greater than $55,000, the $55,000 would be your max contribution in that year.
                          </p>
                          <p>
                            <b>Pension -</b>
                            {' '}
                            $220,000 of total contributions per year; includes employer contributions. If your total annual contribution is higher than $220,000 in any given year, the FC will automatically lower your personal contribution to meet this limit.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.thebalance.com/the-5-most-important-401-k-terms-you-should-know-357111" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Employer Matching Rate on Personal Contribution</a>
                          </h3>
                          <p>
                            The rate, expressed as a percentage, at which your employer will match your 401(k) contributions. Easiest to understand with an example. Let&apos;s say you contribute $1,000 per month to your 401(k). If your Employer Matching Rate is 50%, your employer would contribute $500 per month. The expression you will usually here is &quot;dollar-for-dollar&quot;, which means for every dollar you contribute, your employer will contribute $1 as well.
                            The Employer Matching Rate can only be fully understood with the Employer Matching Limit as a % of Base Income. See that definition below to get a full grasp on what your employer would contribute to your 401(k).
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.thebalance.com/the-5-most-important-401-k-terms-you-should-know-357111" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Employer Matching Limit as a % of Base Income</a>
                          </h3>
                          <p>Company matching on a 401(k) can mean a good amount of money being added to your account. However, every company will have a limit on the amount they will contribute to your 401(k) in a given year. This amount is based on a percentage of your Base Income. The FC calculates Base Income as your Annual Income plus any Bonus / Tips / Commission that you plan to receive.</p>
                          <p>How does the limit come into play? Let&apos;s continue on our example from the definition above and assume that Base Income is $120,000 per year and our personal contribution is 10%. Our annual contribution would be $12,000 to our 401(k). And, let&apos;s assume that our Employer Matching Rate is dollar-for-dollar (or 100%) with an Employer Matching Limit of 5%.</p>
                          <p>Employer Contribution would be equal to: 100% x $12,000 Personal Contribution = $12,000</p>
                          <p>But, the Employer Matching Limit is equal to: 5% x $120,000 Base Income = $6,000</p>
                          <p>The Employer Contribution would be maxed out at $6,000. The FC would automatically make this adjustment and you can see the calculation in the My Details section for your 401(k).</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://smartasset.com/taxes/all-about-the-fica-tax" target="_blank" rel="noopener noreferrer" className={classes.noLink}>FICA Income Taxes</a>
                          </h3>
                          <p>
                            Together, social security tax and medicare tax are called FICA taxes, which stands for the Federal Insurance Contributions Act. It&apos;s important to understand the tax implications of being self-employed. When you work for someone else, that company pays half of your FICA tax - also known as social security and medicare taxes. The company pays 7.65% of the 15.30% FICA tax.
                            When you are self-employed, you pay the entire 15.30%. Switch from self-employed and not self-employed and see how your estimated taxes change.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.thebalance.com/how-does-health-insurance-work-3306069" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Health Insurance Costs outside Premium</a>
                          </h3>
                          <p>
                            These costs include paying your
                            {' '}
                            <a href="https://www.healthcare.gov/glossary/deductible/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>deductible</a>
                            {' '}
                            And
                            {' '}
                            <a href="https://www.investopedia.com/terms/c/copay.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>co-pay</a>
                            . A deductible is the amount of money an individual pays for expenses before their insurance plan starts to pay. A co-pay is an out of pocket expense for health care services that is charged at the time the service is rendered.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.verywellhealth.com/health-insurance-premiums-1738769" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Health Insurance Premium</a>
                          </h3>
                          <p>Just like a monthly car insurance premium or monthly home insurance premium, your health insurance premium is the monthly fee that you pay for your health insurance coverage. Even if you do not require any health services such as a doctor&apos;s visit or a surgery, you have to pay your monthly health insurance premium to maintain your coverage.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Net Income</h3>
                          <p>Total income minus taxes, living expenses, car expenses, real estate expenses, and interest expenses equals net income. Net income is cash left over for retirement contributions, investing in cars and real estate, vacations, charity, and paying down the principal on your loans. Positive net income is the goal for each career move.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Occupation</h3>
                          <p>
                            A person&apos;s primary form of work that pays some form of
                            {' '}
                            <a href="https://www.investopedia.com/terms/p/personalincome.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>income</a>
                            {' '}
                            such as a doctor, lawyer, nurse, plumber, or electrician.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.investopedia.com/terms/p/pensionplan.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Pension</a>
                          </h3>
                          <p>
                            A retirement savings plan that requires an employer to make contributions to its worker&apos;s retirement fund. Typically, both the employer and employee can contribute to the pension fund. If you are contributing to your own pension, contributions are typically pre-tax, which means a portion of your income will be allocated to your pension BEFORE that income is taxed.
                            The FC assumes all contributions to pensions are pre-tax. But, one thing is for sure, you can&apos;t escape Uncle Sam. Taxes will be paid once you start receiving income from your pension when you retire.
                          </p>
                          <p>Personal contributions to your pension are an adjustment to your income. Meaning, every dollar you contribute to your pension fund will lower your taxable income. See the Adjustments section on the Tax Statement for more details.</p>
                          <p>The great thing about a Pension is that the contribution limits are significantly higher than all other Retirement Accounts and the employer is required to make contributions. See &quot;Contribution as a % of Gross Income&quot; for a comparative analysis of Retirement Accounts and their limits.</p>
                          <p>The 2018 maximum contribution for Pension is $220,000 of total contributions per year; includes employer contributions. If your total annual contribution is higher than $220,000 in any given year, the FC will automatically lower your personal contribution to meet this limit.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>
                            <a href="https://www.investopedia.com/terms/r/rothira.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Roth IRA Retirement Account</a>
                          </h3>
                          <p>
                            A
                            {' '}
                            <a href="https://www.investopedia.com/terms/r/rothira.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>retirement</a>
                            {' '}
                            savings plan that requires you to be earning an income to make contributions. Unlike a 401(k) Retirement Account, contributions to Roth IRA are made with after-tax dollars. When you make withdrawals in retirement, you will pay no taxes on your Roth IRA contributions and earnings. Money in this account cannot be withdrawn before the age of 59 1/2 without paying a 10% penalty.
                          </p>
                          <p>The 2018 maximum contribution for Roth IRA is $5,500 of personal contributions per year; subject to phaseouts. The phaseouts are applied depending on income. See the image below for how phaseouts work.</p>
                          <p>Experts say that if your tax rate will be higher now than when you retire, you should contribute to a 401(k) over a Roth IRA since 401(k) contributions help you save on taxes. Plus, with 401(k), there is usually an employer matching program allowing you to save more. However, If your taxes will be higher in retirement, contributing to a Roth IRA now will provide you with tax-free income later.</p>
                          <p>
                            A quick analysis of 401(k) or Roth IRA can be found
                            {' '}
                            <a href="https://www.investopedia.com/terms/r/rothira.asp" target="_blank" rel="noopener noreferrer">here</a>
                            .
                          </p>
                        </div>
                        <div className={classes.galleryImage}>
                          <img src={phaseoutsSingle} alt="table" />
                        </div>
                        <div className={classes.galleryImage}>
                          <img src={phaseoutsMarried} alt="table" />
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/s/self-employed.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Self-Employment</a></h3>
                          <p>
                            An individual that works for himself or herself instead of working for an employer. A self-employed individual earns income through conducting trade or business operations that he/she operates directly. For example, if you own a bakery and work as an employee of that bakery as well, you are self-employed. It&apos;s important to understand the tax implications of being self-employed.
                            When you work for someone else, that company pays half of your FICA tax - also known as Social Security and Medicare taxes. The company pays 7.65% of the 15.30% FICA tax. When you are self-employed, you pay the entire 15.30%. Switch from self-employed and not self-employed and see how your estimated taxes change.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://turbotax.intuit.com/tax-tips/self-employment-taxes/the-self-employment-tax/L8xXjolB4" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Self-Employment Tax Adjustment</a></h3>
                          <p>
                            So you pay more taxes if you&apos;re self-employed? Doesn&apos;t seem fair does it? Well good thing is a portion of those additional taxes are an adjustment to your income, which lowers your Federal Taxable Income. You can see this adjustment on the Tax Statement. The calculation for the Self-Employment Tax Adjustment is simple - 50% of the 15.30% FICA tax (see Self-Employment definition above) is an adjustment.
                            For example, say you make $100,000 in a year owning your own bakery. Your FICA tax would be $15,300 or 15.30% times your $100,000. However, your Federal Taxable Income would be reduced by $7,650 or 50% of the $15,300.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.investopedia.com/terms/s/sensitivityanalysis.asp#ixzz4jFkkQnp1" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Sensitivity Analysis</a></h3>
                          <p>
                            A technique used to determine how different values of a specific variable can impact an analysis. FC uses this technique when looking at the annual expected return of investments and retirement accounts. We do this by looking at the future value of investments by adjusting the annual expected return to a higher and lower number.
                            For example, if your Retirement Account returns an average of 6.00%, we analyze the future value of that investment at a 3.00% return (1/2 the expected return), 6.00% (the expected return), and 9.00% (1/2 the expected return + the expected return) to show you what might happen in the future.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/sep-ira-options-2894580" target="_blank" rel="noopener noreferrer" className={classes.noLink}>SEP IRA</a></h3>
                          <p>
                            A retirement savings plan for self-employed individuals or small business owners. &quot;SEP&quot; stands for Simplified Employee Pension. In the FC, we assume you will only contribute to a SEP IRA if you check &quot;Yes&quot; to being self-employed. Why do we make that assumption? There is a lot of added complexity when a small business goes to open a SEP IRA and contribute to employees accounts.
                            And, it is less common. So, to make sure that we operate under proper assumptions, we limit this to self-employed individuals.
                          </p>
                          <p>Like a 401(k) Retirement Account, contributions to SEP IRA are made with pre-tax dollars. Taxes aren&apos;t paid until the money is withdrawn from the account. Money in this account cannot be withdrawn before the age of 59 1/2 without paying a 10% penalty plus taxes.</p>
                          <p>The great thing about a SEP IRA is that the contribution limits are much higher than a 401(k) or Roth IRA. See &quot;Contribution as a % of Gross Income&quot; for a comparative analysis of Retirement Accounts and their limits.</p>
                          <p>
                            The 2018 maximum contribution for SEP IRA is the lesser of 25% of Annual Income plus Bonus / Tips / Commission (also known as &quot;Total Compensation&quot;) or $55,000. For the 25% of Total Compensation limit, Total Compensation used in that calculation is capped at $275,000.
                            In other words, if you make $350,000 in Total Compensation, your limit would be $275,000 x 25% = $68,750. Since $68,750 is greater than $55,000, the $55,000 would be your max contribution in that year.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Supplementary Income</h3>
                          <p>Income earned outside of your main employment such as a second job or freelance work. For example, if your main employment is being a High School Spanish Teacher and on weekends you also tutor, the income earned from tutoring would be Supplementary Income.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.nationwide.com/lc/resources/personal-finance/articles/average-cost-of-utilities" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Utilities</a></h3>
                          <p>The total price you pay for water, electricity and gas, trash and recycling, home phone, and internet and cable. Some or all utilities can be included in your rent when renting a home or an apartment. If you own real estate, you will be responsible for paying all utilities.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/what-is-a-vesting-schedule-and-how-does-it-work-4047274" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Vesting</a></h3>
                          <p>
                            When you contribute to your 401(k), you own that money meaning if you left your job tomorrow, you can take that money with you as you go. When your employer contributes to your 401(k), you don&apos;t immediately own that money. There is a vesting schedule that determines how much of your Employer Contributions and Earnings would be yours if you left your job at certain time periods.
                            A typical vesting schedule could be 25% per year. Let&apos;s show an example to see how this works in the FC where an employer&apos;s contributions and earnings total $5,000 per year.
                          </p>
                        </div>
                        <div className={classes.galleryImage}>
                          <img src={careerpathBill} alt="table" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Estimated Taxes</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://taxfoundation.org/conference-report-tax-cuts-and-jobs-act/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>2018 tax reform and how it affects you</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Self-Employment</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalancesmb.com/what-does-it-mean-to-be-self-employed-398471" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What it means to be self-employed</a>
                            <a href="https://www.thebalancesmb.com/self-employment-tax-and-fica-tax-explained-399037" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Self-employment and what it means for your taxes</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Occupation</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.onetonline.org/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Your tool for career exploration and job analysis</a>
                            <a href="https://www.careeronestop.org/toolkit/careers/occupations/occupation-profile.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Explore different occupations with this comprehensive list of career opportunities</a>
                            <a href="https://www.careerbuilder.com/jobs" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Explore different careers by industry, location, salaries, and more</a>
                            <a href="https://wallethub.com/edu/best-cities-for-jobs/2173/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Best and worst cities for jobs from expert research</a>
                            <a href="https://www.hamiltonproject.org/charts/where_work_pays_interactive" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Compare wages adjusted by income taxes and cost of living by state and regions</a>
                            <a href="https://www.bloomberg.com/news/articles/2017-10-24/health-care-stem-jobs-among-fastest-growing-u-s-occupations" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Fastest growing and most rapidly declining jobs</a>
                            <a href="https://www.bls.gov/emp/ep_table_103.htm" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Median annual wage for the fastest growing jobs in the link above</a>
                            <a href="https://www.payscale.com/research/US/Job" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much can you expect to make? Search for your career move and find out</a>
                            <a href="https://www.indeed.com/career/salaries" target="_blank" rel="noopener noreferrer" className={classes.noLink}>A second resource to research salaries by company and/or career</a>
                            <a href="https://www.glassdoor.com/Salaries/index.htm" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Another resource to find out how much money you could make with your next career move</a>
                            <a href="https://www.glassdoor.com/Award/Best-Places-to-Work-LST_KQ0,19.htm" target="_blank" rel="noopener noreferrer" className={classes.noLink}>2019 best places to work by Glassdoor</a>
                            <a href="https://www.indeed.com/lead/best-jobs-united-states" target="_blank" rel="noopener noreferrer" className={classes.noLink}>25 growing careers and how much they might pay</a>
                            <a href="https://www.usatoday.com/story/money/careers/2018/07/02/highest-paying-jobs-you-can-get-without-a-college-degree/36032749/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Highest paying jobs you can get without a college degree</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Trade Careers</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://careerschoolnow.org/careers" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Resources to help you pick your career</a>
                            <a href="https://www.trade-schools.net/articles/trade-school-jobs" target="_blank" rel="noopener noreferrer" className={classes.noLink}>High paying career school jobs</a>
                            <a href="https://www.thebalancecareers.com/best-trade-school-graduate-jobs-4125189" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Other high paying career school jobs with good future job growth</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Military Careers</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalancecareers.com/what-the-recruiter-never-told-you-3332707" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Deciding which military service to join</a>
                            <a href="https://militarypay.defense.gov/Calculators/RMC-Calculator/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Military compensation calculator by grade and years of service</a>
                            <a href="https://www.goarmy.com/benefits/total-compensation.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Military compensation compared to civilian compensation</a>
                            <a href="https://www.goarmy.com/benefits.html" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Military benefits</a>
                            <a href="https://www.thebalancecareers.com/what-the-recruiter-never-told-you-3332709" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Promotions in the military</a>
                            <a href="https://www.thebalancecareers.com/u-s-military-special-operations-forces-3354124" target="_blank" rel="noopener noreferrer" className={classes.noLink}>U.S. military special operations forces</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Supplementary Income</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.businessinsider.com/why-you-should-have-multiple-streams-of-income-2016-6?IR=T" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Benefits of multiple income streams</a>
                            <a href="https://www.nerdwallet.com/blog/finance/how-to-make-money-at-home-and-online/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>25 ways to make money on the side</a>
                            <a href="https://www.marketwatch.com/story/25-fun-ways-to-make-extra-money-2017-06-15?mg=prod/accounts-mw" target="_blank" rel="noopener noreferrer" className={classes.noLink}>25 other ways to make some extra cash</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Bonus / Tips / Commission</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalancecareers.com/what-is-bonus-pay-1918069" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What is a bonus</a>
                            <a href="https://www.thebalancecareers.com/what-is-commission-pay-2061954" target="_blank" rel="noopener noreferrer" className={classes.noLink}>What is a commission</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Living Expenses</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.businessinsider.com/heres-what-the-typical-1-bedroom-apartment-costs-in-50-us-cities-2016-6?IR=T#-1" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average rent for a one bedroom apartment in 50 U.S. cities</a>
                            <a href="https://www.businessinsider.in/personal-finance/heres-how-much-you-need-to-earn-to-rent-a-2-bedroom-apartment-in-15-of-americas-biggest-cities/articleshow/59551596.cms#15-phoenix-arizona-1" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average rent for a two bedroom apartment in 15 major U.S. cities</a>
                            <a href="https://www.apartmentguide.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Search for available apartments by location</a>
                            <a href="https://www.apartments.com/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Another resource to search for available apartments by location</a>
                            <a href="https://www.zillow.com/blog/rules-of-thumb-for-estimating-apartment-utility-costs-100024/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Estimating monthly utilities</a>
                            <a href="https://www.moneyunder30.com/understanding-your-health-insurance" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Health insurance costs explained</a>
                            <a href="https://www.kff.org/health-reform/state-indicator/marketplace-average-benchmark-premiums/?currentTimeframe=0&selectedDistributions=2014--2015--2016--2017--2018--2019&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average monthly health insurance premium by state, 2014-2019</a>
                            <a href="https://www.kff.org/health-reform/state-indicator/average-marketplace-premiums-by-metal-tier/?currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average Marketplace Premiums by Metal Tier, 2017-2019</a>
                            <a href="https://www.kff.org/other/state-indicator/single-coverage/?dataView=0&currentTimeframe=0&sortModel=%7B%22colId%22:%22Location%22,%22sort%22:%22asc%22%7D" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average monthly health insurance premium by state for employer-based plans</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Retirement Accounts</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.thebalance.com/how-401k-plans-work-4135451" target="_blank" rel="noopener noreferrer" className={classes.noLink}>401k: The basics and more</a>
                            <a href="https://www.thebalance.com/the-5-most-important-401-k-terms-you-should-know-357111" target="_blank" rel="noopener noreferrer" className={classes.noLink}>401k: 5 terms to know</a>
                            <a href="https://www.thebalance.com/what-is-a-vesting-schedule-and-how-does-it-work-4047274" target="_blank" rel="noopener noreferrer" className={classes.noLink}>401k: Vesting and how it impacts your funds</a>
                            <a href="https://www.investopedia.com/ask/answers/041015/what-rate-return-should-i-expect-my-401k.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>401k: Expected returns</a>
                            <a href="https://www.investopedia.com/terms/r/rothira.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Roth IRA: What is it and more</a>
                            <a href="https://www.bankrate.com/finance/retirement/advantages-of-roth-iras-1.aspx" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Roth IRA: 6 advantages when saving</a>
                            <a href="https://www.fidelity.com/viewpoints/retirement/spender-or-saver" target="_blank" rel="noopener noreferrer" className={classes.noLink}>401k or Roth IRA: Quick tips for choosing your retirement account</a>
                            <a href="https://www.bankrate.com/investing/ira/roth-ira-vs-roth-401k/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>401 or Roth IRA: Key differences</a>
                            <a href="https://www.thebalance.com/how-much-should-i-put-in-my-401k-453991" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much should you contribute towards retirement</a>
                            <a href="https://www.thebalance.com/sep-ira-options-2894580" target="_blank" rel="noopener noreferrer" className={classes.noLink}>SEP IRA: What is it and how much can I contribute?</a>
                            <a href="https://www.nerdwallet.com/article/investing/what-is-a-sep-ira" target="_blank" rel="noopener noreferrer" className={classes.noLink}>SEP IRA: Pros and cons</a>
                            <a href="https://www.thebalance.com/what-is-a-pension-plan-2385771" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Pension: What is it and more</a>
                            <a href="https://www.bloomberg.com/news/articles/2017-08-02/5-is-the-new-8-for-reliable-returns-for-pension-funds" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Pension: Expected returns</a>
                            <a href="https://www.gobankingrates.com/money/jobs/jobs-with-pensions/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Pension: 8 careers that still offer pension plans</a>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.furtherCareerWalk3) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 5 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>

              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Further Career Advancement" moduleSelected="module17" getModuleData={getModuleData} setUpdatedScenario={setupdatedFcadvancementValues} />
              )}
            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>
        <div>
          <Dialog
            open={popupOpen}
            onClose={() => { setPopupOpen(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.SavePopup}
          >
            <DialogContent className={classes.videoPopup}>
              <div>
                <div className={classes.popupClose}>
                  <span aria-hidden="true" onClick={() => { setPopupOpen(false); }}>
                    X
                  </span>
                </div>
                <PwiSave moduleParams={moduleObjectParams} getSaveData={updatedFcadvancementValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg={SetNotesSuccessMsg} />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module17' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={classes.modelPopupRow}
      >
        <DialogContent className={classes.modelPopup}>
          <div className={classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

FurtherCareerAdvancementWorksheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(FurtherCareerAdvancementWorksheet);
