import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { confirmAlert } from 'react-confirm-alert';
import $ from 'jquery';
import combineStyles from '../../utilities/combineStyles';
import TimeLineList from '../../components/time-line/time-line-list';
import styles from './styles';
import confirmStyles from '../../common-styles/confirm-styles';
import walkthru from '../../assets/img/build-career-sketch/walk.png';
import hamburger from '../../assets/img/build-career-sketch/hamburger-menu-blue.png';
import arrow from '../../assets/img/build-career-sketch/timeline_arrow.png';
import simulator from '../../assets/img/build-career-sketch/simulator-engine-blue.png';
import summary from '../../assets/img/build-career-sketch/financial-doc-icon-blue.png';
import dashboard from '../../assets/img/build-career-sketch/icon-dashboard-blue.png';
import load from '../../assets/img/build-career-sketch/icon-load-blue.png';
import save from '../../assets/img/build-career-sketch/icon-save-blue.png';
import clear from '../../assets/img/build-career-sketch/clear-timeline-blue.png';
import WalkThruDotsAll from '../pwi/wallk-thru/walkThruDotsAll';
import SimulatorPanel from '../../components/documents/simulator-panel';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as timeLineActions from '../../calculations/time-line';
import Pageloader from '../../components/ui/pageloader';
import SummaryDocs from '../../components/documents/documents';
import * as modulesServices from '../../calculations/modules-services';
import 'jquery-ui/ui/widgets/sortable';
import 'jquery-ui/ui/widgets/draggable';
import messages from '../../utilities/pwi-popup-messages';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';

const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');

const CLEAR_TIMELINE = loader('../../graphql/schema/pwi/clear-timeline.graphql');
const CLEAR_SCENARIO = loader('../../graphql/schema/pwi/remove-scenario.graphql');
const SAVE_TIMELINE = loader('../../graphql/schema/fc/save-timeline.graphql');
const SAVEMYCAREERSKETCH = loader('../../graphql/schema/career-sketch/save-careersketch.graphql');

const LOADSCENARIOAPI = loader('../../graphql/schema/pwi/load-scenario-sketch.graphql');

const totalWalkthru = [];
for (let i = 0; i <= 11; i += 1) {
  totalWalkthru.push(i);
}

const displayWalkThruData = [
  {
    action: "FC",
    endYear: 2033,
    module_time_line_order: 0,
    routePath: "/plan-with-inkwiry/fi",
    scenario_id: "921",
    selected_module: "module1",
    startYear: 2019,
    time_line_order: 2019,
  },
  {
    action: "FC",
    endYear: "",
    module_time_line_order: 17,
    routePath: "/plan-with-inkwiry/existing",
    scenario_id: "1167",
    selected_module: "module18",
    startYear: 2019,
    time_line_order: 2019,
  },
  {
    action: "FC",
    endYear: 2024,
    module_time_line_order: 3,
    routePath: "/plan-with-inkwiry/cp",
    scenario_id: "923",
    selected_module: "module4",
    startYear: 2020,
    time_line_order: 2020,
  },
  {
    action: "FC",
    endYear: "",
    module_time_line_order: 8,
    routePath: "/plan-with-inkwiry/investments",
    scenario_id: "928",
    selected_module: "module9",
    startYear: 2022,
    time_line_order: 2022,
  },
  {
    action: "FC",
    endYear: 2029,
    module_time_line_order: 4,
    routePath: "/plan-with-inkwiry/ca",
    scenario_id: "924",
    selected_module: "module5",
    startYear: 2025,
    time_line_order: 2025,
  },
  {
    action: "FC",
    endYear: "",
    module_time_line_order: 9,
    routePath: "/plan-with-inkwiry/family",
    scenario_id: "929",
    selected_module: "module10",
    startYear: 2026,
    time_line_order: 2026,
  },
  {
    action: "FC",
    endYear: "",
    module_time_line_order: 10,
    routePath: "/plan-with-inkwiry/vacation",
    scenario_id: "930",
    selected_module: "module11",
    startYear: 2027,
    time_line_order: 2027,
  },
  {
    action: "FC",
    endYear: 2033,
    module_time_line_order: 7,
    routePath: "/plan-with-inkwiry/aca",
    scenario_id: "927",
    selected_module: "module8",
    startYear: 2030,
    time_line_order: 2030,
  },
  {
    action: "FC",
    endYear: "",
    module_time_line_order: 11,
    routePath: "/plan-with-inkwiry/car",
    scenario_id: "931",
    selected_module: "module12",
    startYear: 2031,
    time_line_order: 2031,
  },
  {
    action: "FC",
    endYear: "",
    module_time_line_order: 12,
    routePath: "/plan-with-inkwiry/home",
    scenario_id: "932",
    selected_module: "module13",
    startYear: 2031,
    time_line_order: 2031,
  },
  {
    action: "FC",
    endYear: "",
    module_time_line_order: 1,
    routePath: "/plan-with-inkwiry/he",
    scenario_id: "922",
    selected_module: "module2",
    startYear: "",
    time_line_order: 2080,
  }
];

const BuildCareerSketch = (props) => {
  const { classes, history } = props;
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [summaryDocOpen, setSummaryDocOpen] = React.useState(false);
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [openPopup, setOpenPopup] = React.useState('');
  const [activeWalkThru, setActiveWalkThru] = React.useState('');
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [moduleTimeLine, setModuleTimeLine] = React.useState([]);
  const [dragDisable, setDragDisable] = React.useState({});
  const [showStartYearInfo, setShowStartYearInfo] = React.useState(false);
  const [otherData, setOtherData] = React.useState({});
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [type, setType] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState("");
  const [scenariosId, setScenarioId] = React.useState('');
  const [sketchId, setSketchId] = React.useState('');
  const [walkThru, setWalkThru] = React.useState(false);
  let currentURL = window.location ? window.location.search.split('=')[1] : "";

  useEffect(() => {
    const id = JSON.parse(localStorage.getItem('scenario-data'));
    const sketch_id = JSON.parse(localStorage.getItem('sketch-data'));
    if (id !== '' && id !== undefined && id !== null) {
      setScenarioId(JSON.parse(id.selected_modules_data)[0].scenarioId);
      localStorage.removeItem('scenario-data');
    } else {
      setScenarioId('');
    }
    if (sketch_id !== '' && sketch_id !== undefined && sketch_id !== null) {
      setSketchId(JSON.parse(sketch_id.selected_modules_data));
      localStorage.removeItem('sketch-data');
    } else {
      setSketchId('');
    }
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThru(0);
    setWalkThru(true)
    localStorage.removeItem('walkthruopen');
  }


  useEffect(() => {
    if (scenariosId !== '' && scenariosId !== undefined) {
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3><b>Load Shared Scenarios</b></h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <div>
                  <p>Would you like to clear your Simulator Panel and load the shared scenarios?</p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
              <button
                type="button"
                onClick={() => { onClose(); setLoading(true); loadScenarioData({ variables: { id: scenariosId } }); }}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
    }
  }, [scenariosId]);

  useEffect(() => {
    if (sketchId !== '' && sketchId !== undefined && sketchId !== null) {
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classes.impPopup}>
            <div>
              <h3><b>Load Career Sketch</b></h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <div>
                  <p>Would you like to clear your Simulator Panel and load the Career Sketch?</p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
              <button
                type="button"
                onClick={() => { onClose(); setLoading(true); saveTimeLine({ variables: { data: { name: 'Active Selection', order: sketchId.map((module) => _.pick(module, ['action', 'module_time_line_order', 'selected_module', 'time_line_order', 'scenario_id'])) } } }) }}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
    }
  }, [sketchId])

  const [saveTimeLine] = useMutation(SAVE_TIMELINE, {
    onCompleted(response) {
      getModuleData();
    },
    onError(errors) {
      getModuleData();
    },
  });

  useEffect(() => {
    setType(currentURL);
    if (currentURL === 'mcs4') {
      const popupdetails = messages('mcsBuildAScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
    if (currentURL === 'mcs5') {
      setActiveWalkThru(0);
    }
    if (Object.keys(dragDisable).length > 0) {
      for (let i = 1; i <= 18; i += 1) {
        if (i !== 3 && i !== 7 && i !== 16) {
          if (dragDisable[`module${i}`] !== undefined && dragDisable[`module${i}`] === true) {
            $(`.life_events_block li[data-module="module${i}"]`).draggable({
              disabled: true,
            });
          } else {
            $(`.life_events_block li[data-module="module${i}"]`).draggable({
              disabled: false,
            });
          }
        }
      }
      const mainWidth = ($('#drop-area li').width()) * ($('#drop-area li').length) - (($('#drop-area li').length - 1) * 42) + 50;
      $('#drop-area').css('width', mainWidth);
    }
    // eslint-disable-next-line
  }, [dragDisable])

  const placeTimeLine = (type, status = '') => {
    if (type === 'default') {
      const displayTimeLine = [];
      const dragDisableData = {};
      if (status === 'not-empty') {
        const order = timeLineActions.moduleTimelineData;
        order.forEach((value) => {
          if (parseInt(value.scenario_id, 10) > 0 || value.action === 'CS') {
            displayTimeLine.push(value);
            dragDisableData[value.selected_module] = true;
          }
        });
      } else {
        dragDisableData.module1 = true;
        displayTimeLine.push({
          action: 'CS',
          module_time_line_order: 0,
          scenario_id: 0,
          selected_module: 'module1',
          time_line_order: 0,
        });
      }
      setDragDisable(dragDisableData);
      setModuleTimeLine( displayTimeLine);
    }
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        placeTimeLine('default', 'not-empty');
        setLoading(false);
        if (!(modulesServices.module1Data.start_year !== undefined && parseInt(modulesServices.module1Data.start_year)) > 0) {
          setShowStartYearInfo(true);
        }
      });
    },
    onError() {
      timeLineActions.assignModulesData('', (data) => {
        placeTimeLine('default', 'empty');
        setLoading(false);
        setShowStartYearInfo(true);
      });
    },
  });

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const [clearTimeLineData] = useLazyQuery(CLEAR_TIMELINE, {
    fetchPolicy: 'network-only',
    onCompleted() {
      recallDataApi('clear-timeline');
    },
  });

  const saveTimeLineData = (data) => {
    const timeLineVal = data.map((module) => _.pick(module, ['action', 'module_time_line_order', 'selected_module', 'time_line_order', 'scenario_id']));
    setLoading(true);
    saveTimeLine({
      variables: {
        data: {
          name: 'Active Selection',
          order: timeLineVal,
        },
      },
    });
  };

  useEffect(() => {
    document.body.classList.remove('pwiAnimation');
    document.body.classList.remove('pwiAnimationActive');
    getModuleData();

    $('.life_events_block li[data-module]').draggable({
      connectToSortable: '#drop-area',
      helper: 'clone',
      revert: 'invalid',
      containment: 'window',
      scroll: true,
      scrollSensitivity: 80,
      scrollSpeed: 150,
      stop(event, ui) {
        const mainWidth = ($('#drop-area li').width()) * ($('#drop-area li').length) - (($('#drop-area li').length - 1) * 42) + 50;
        $('#drop-area').css('width', mainWidth);
      },
    });

    $('#drop-area').sortable({
      scroll: true,
      refreshPositions: true,
      revert: true,
      hoverClass: 'ui-state-active',
      cursor: 'move',
      tolerance: 'intersect',
      items: 'li:not(.fixed)',
      scrollSpeed: 100,
      scrollSensitivity: 80,
      over(event, ui) {
        // Drag it here image placeholder
        $('.ui-sortable-placeholder').html('<span class="drop_here">Drop Here</span>').css('visibility', 'visible').css('z-index', '2');

        const mainWidth = ($('#drop-area li').width()) * ($('#drop-area li').length) - (($('#drop-area li').length - 1) * 42) + 50;

        $('#drop-area').css('width', mainWidth);
        // Hide dragging component
        $(ui.helper).css('display', 'none').removeClass('active').removeClass('component')
          .addClass('component-sorting');
      },
      stop(event, ui) {
        // Hide Drag Item
        ui.item.hide();
        const selectedModule = ui.item.attr('data-module');
        const dropIndex = ui.item.index();
        const dropList = [];
        $('#drop-area').find('li').each((n, module) => {
          const moduleInformation = {
            action: $(module).data('actionfrom'),
            module_time_line_order: n,
            time_line_order: n,
            selected_module: $(module).data('module'),
            scenario_id: $(module).data('scenarioid'),
            startYear: $(module).data('startYear'),
            endYear: $(module).data('endYear'),
          };
          if ($(module).hasClass('component-sorting')) {
            moduleInformation.action = 'CS';
            moduleInformation.scenario_id = 0;
            moduleInformation.selected_module = selectedModule;
            moduleInformation.module_time_line_order = dropIndex;
            moduleInformation.time_line_order = dropIndex;
            moduleInformation.startYear = '';
            moduleInformation.endYear = '';
          }
          dropList.push(moduleInformation);
        });
        ui.item.remove();
        saveTimeLineData(dropList);
      },
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
    // eslint-disable-next-line    
  });

  useEffect(() => {
    if (activeWalkThru !== '') {
      const walkthrus = { ...completedWalkThruSlides };
      walkthrus[activeWalkThru] = true;
      setCompletedWalkThruSlides(walkthrus);
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
    if (activeWalkThru === 9 || activeWalkThru === 10) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
    if (activeWalkThru === 0 || activeWalkThru === 1) {
      document.body.classList.add('pwiAnimationActive');
    } else {
      document.body.classList.remove('pwiAnimationActive');
    }

    switch (activeWalkThru) {
      case 0:
      case 3:
      case 4:
      case 5:
      case 11:
        scrollToComponent(document.getElementById('time_line_life_events'), { offset: -56, align: 'top', duration: 500 });
        break;
      case 1:
      case 7:
      case 8:
      case 9:
      case 10:
        scrollToComponent(document.getElementById('time_line_drag_events'), { offset: -100, align: 'top', duration: 500 });
        break;
      case 2:
      case 6:
        scrollToComponent(document.getElementById('cs_time_line'), { offset: -100, align: 'top', duration: 500 });
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [activeWalkThru]);

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleSummaryDocuments = () => {
    setSummaryDocOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setSummaryDocOpen(false);
  };

  const [clearScenario] = useMutation(CLEAR_SCENARIO, {
    onCompleted({
      removeScenario: {
        status,
      },
    }) {
      if (status === true) {
        setLoading(true);
        getModuleData();
      }
    },
  });

  const confirmClearTimeLine = () => {
    handleCloseMenu();
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classNames(classes.impPopup, classes.minPopupDiv)}>
          <div>
            <h3><b>Clear</b></h3>
            <div className={classNames(classes.impDiv, classes.noPadding)}>
              <div>
                <p>Are you sure you want to clear complete timeline order?</p>
              </div>
            </div>
          </div>
          <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
            <button
              type="button"
              onClick={() => { onClose(); setLoading(true); clearTimeLineData(); }}
            >
              YES
            </button>
            <button type="button" onClick={onClose}>NO</button>
          </div>
        </div>
      ),
    });
  };

  const manageModule = (moduleName, type) => {
    if (type === 'clear') {
      confirmAlert({
        // eslint-disable-next-line
        customUI: ({ onClose }) => (
          <div className={classNames(classes.impPopup, classes.minPopupDiv)}>
            <div>
              <h3><b>Clear Scenario</b></h3>
              <div className={classNames(classes.impDiv, classes.noPadding)}>
                <div>
                  <p>Are you sure you want to clear the scenario from the timeline? This won't delete your scenario. It will just remove the scenario from your timeline.</p>
                </div>
              </div>
            </div>
            <div className={classNames(classes.impbuttonOkRedo, classes.impbuttonOkDefault)}>
              <button
                type="button"
                onClick={() => {
                  onClose(); setLoading(true); clearScenario({
                    variables: {
                      module: moduleName,
                    },
                  });
                }}
              >
                OK
              </button>
              <button type="button" onClick={onClose}>CANCEL</button>
            </div>
          </div>
        ),
      });
    } else {
      setOtherData({ module: moduleName });
      setOpenPopup('sight-load-scenario');
    }
  };
  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  const current_year = modulesServices.getCurrentYearNetworth();
  const year_5th = modulesServices.get5thYearNetworth();
  const year_10th = modulesServices.get10thYearNetworth();
  const year_15th = modulesServices.get15thYearNetworth();

  const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
    onCompleted({
      saveMyCareerSketch: {
        status,
      },
    }) {
      if (status) {
        setLoading(false);
        history.push(moduleTimeLine[2].routePath + '?type=mcs5#activity=1');
      }
    },
    onError(errors) {
      const formatedErrors = errors.graphQLErrors.map(({ message }) => (
        message
      ));
      setErrorMessage(formatedErrors);
      setLoading(false);
    },
  });
  const handleClose = (activity_id) => {
    submitFirstActivity({
      variables: {
        data: {
          level: "5",
          option_id: activity_id,
        },
      },
    });
  }

  const [loadScenarioData] = useMutation(LOADSCENARIOAPI, {
    onCompleted(res) {
      setLoading(false)
    },
    onError() {
      setLoading(false)
    }
  });

  return (
    <Typography variant="body1" component="div" className={classNames(activeWalkThru !== '' && classes.disablePointerevents)}>
      {loading && <Pageloader loading={loading} />}
      <div className={classes.timelineSketcher}>
        <div className={classes.timelineBanner}>
          <div className={classes.container}>
            <p>Timeline Sketcher</p>
          </div>
        </div>
        <div className={classes.lifeEventsBlock} id="time_line_life_events">
          <ReactTooltip id="modulesTooltip" place="bottom" type="info" effect="solid" className={classNames(classes.tooltipInfo, classes.timelineInfo)} />
          <div className={classes.container}>
            <div className={classes.lifeEventsContent}>
              <div className={classes.lifeEveentScroll}>
                <ul className={classNames(classes.lifeEventsImages, 'life_events_block')}>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Financial Independence"
                    data-offset="{'bottom': -2}"
                    data-module="module1"
                  >
                    <font className={classes.noDropFi}><span /></font>
                  </li>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Existing Assets & Liabilities"
                    data-offset="{'bottom': -2}"
                    data-module="module18"
                  >
                    <font className={(dragDisable.module18 !== undefined && dragDisable.module18 === true) ? classes.noDropExisting : classes.existingEvent}><span /></font>
                  </li>
                  <li>
                    <ul className={classes.heLifeEventsList}>
                      {!(dragDisable.module2 !== undefined && dragDisable.module2 === true) && (
                        <div className={classes.higherEducation}>
                          <li
                            data-for="modulesTooltip"
                            data-tip="Higher Education"
                            data-offset="{'bottom': -2}"
                            data-module="module2"
                          >
                            <font className={(dragDisable.module2 !== undefined && dragDisable.module2 === true) ? classes.noDropHe : classes.heLifeEvent}><span /></font>
                          </li>
                        </div>
                      )}
                      {!(dragDisable.module6 !== undefined && dragDisable.module6 === true) && (
                        <div className={classes.moreHigherEducation}>
                          <li
                            data-for="modulesTooltip"
                            data-tip="More Higher Education"
                            data-offset="{'bottom': -2}"
                            data-module="module6"
                          >
                            <font className={(dragDisable.module6 !== undefined && dragDisable.module6 === true) ? classes.noDropMhe : classes.heLifeEvent}><span /></font>
                          </li>
                        </div>
                      )}
                      <div className={classes.furtherHigherEducation}>
                        <li
                          data-for="modulesTooltip"
                          data-tip="Further Higher Education"
                          data-offset="{'bottom': -2}"
                          data-module="module15"
                        >
                          <font className={(dragDisable.module15 !== undefined && dragDisable.module15 === true) ? classes.noDropFhe : classes.heLifeEvent}><span /></font>
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li>
                    <ul className={classes.careerPathList}>
                      {!(dragDisable.module4 !== undefined && dragDisable.module4 === true) && (
                        <div className={classes.careerPath}>
                          <li
                            data-for="modulesTooltip"
                            data-tip="Career Path"
                            data-offset="{'bottom': -2}"
                            data-module="module4"
                          >
                            <font className={(dragDisable.module4 !== undefined && dragDisable.module4 === true) ? classes.noDropCp : classes.cpEvent}><span /></font>
                          </li>
                        </div>
                      )}
                      {!(dragDisable.module5 !== undefined && dragDisable.module5 === true) && (
                        <div className={classes.careerAdvancement}>
                          <li
                            data-for="modulesTooltip"
                            data-tip="Career Advancement"
                            data-offset="{'bottom': -2}"
                            data-module="module5"
                          >
                            <font className={(dragDisable.module5 !== undefined && dragDisable.module5 === true) ? classes.noDropCa : classes.cpEvent}><span /></font>
                          </li>
                        </div>
                      )}
                      {!(dragDisable.module8 !== undefined && dragDisable.module8 === true) && (
                        <div className={classes.addAdvancement}>
                          <li
                            data-for="modulesTooltip"
                            data-tip="Additional Career Advancement"
                            data-offset="{'bottom': -2}"
                            data-module="module8"
                          >
                            <font className={(dragDisable.module8 !== undefined && dragDisable.module8 === true) ? classes.noDropAca : classes.cpEvent}><span /></font>
                          </li>
                        </div>
                      )}
                      <div className={classes.furtherAdvancement}>
                        <li
                          data-for="modulesTooltip"
                          data-tip="Further Career Advancement"
                          data-offset="{'bottom': -2}"
                          data-module="module17"
                        >
                          <font className={(dragDisable.module17 !== undefined && dragDisable.module17 === true) ? classes.noDropFca : classes.cpEvent}><span /></font>
                        </li>
                      </div>

                    </ul>
                  </li>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Investments"
                    data-offset="{'bottom': -2}"
                    data-module="module9"
                  >
                    <font className={(dragDisable.module9 !== undefined && dragDisable.module9 === true) ? classes.noDropInv : classes.investEvent}><span /></font>
                  </li>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Family"
                    data-offset="{'bottom': -2}"
                    data-module="module10"
                  >
                    <font className={(dragDisable.module10 !== undefined && dragDisable.module10 === true) ? classes.noDropFamily : classes.familyEvent}><span /></font>
                  </li>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Vacation + special Purchases"
                    data-offset="{'bottom': -2}"
                    data-module="module11"
                  >
                    <font className={(dragDisable.module11 !== undefined && dragDisable.module11 === true) ? classes.noDropVacation : classes.vacationEvent}><span /></font>
                  </li>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Buying a Car"
                    data-offset="{'bottom': -2}"
                    data-module="module12"
                  >
                    <font className={(dragDisable.module12 !== undefined && dragDisable.module12 === true) ? classes.noDropCar : classes.carEvent}><span /></font>
                  </li>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Buying a Home"
                    data-offset="{'bottom': -2}"
                    data-module="module13"
                  >
                    <font className={(dragDisable.module13 !== undefined && dragDisable.module13 === true) ? classes.noDropHome : classes.homeEvent}><span /></font>
                  </li>
                  <li
                    data-for="modulesTooltip"
                    data-tip="Real Estate Investment"
                    data-offset="{'bottom': -2}"
                    data-module="module14"
                  >
                    <font className={(dragDisable.module14 !== undefined && dragDisable.module14 === true) ? classes.noDropRei : classes.reiEvent}><span /></font>
                  </li>
                </ul>
              </div>
              {activeWalkThru === 3 && (
                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruFour)}>
                  <h4>Step 1: Drag and drop life events to the timeline</h4>
                  <p>Step 1: Think career first. What education will best prepare you for your career? How many education moves are you planning to make? For example, if you plan to go to college or career school, that would be one higher education module. Graduate school? Drag another higher education module to the timeline.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(4); }} className={classes.startWalkThru}>Next</Button>
                      {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                    </div>
                  </div>
                </div>
              )}
              {activeWalkThru === 4 && (
                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruFive)}>
                  <h4>Step 1: Drag and drop life events to the timeline</h4>
                  <p>Step 1: How many career moves will you make in the next 5, 10, 15 years? You don’t plan to stay in the same job for 10 years, do you? Create a map to your future professional self. Inkwiry calls this building your Stepping Stones. Drag and drop as many career modules as needed for your 15 year professional self and Inkwiry will help you find your Stepping Stones.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(5); }} className={classes.startWalkThru}>Next</Button>
                      {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                    </div>
                  </div>
                </div>
              )}
              {activeWalkThru === 5 && (
                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruSix)}>
                  <h4>Step 1: Drag and drop life events to the timeline</h4>
                  <p>Step 1: Now that you have your education and career moves on the timeline, fill in the pieces. Drag and drop the other life events into place and build your future. Ask yourself, “When do I want to own a home, buy a car, start investing, and more?” Build your timeline chronologically as best as you can, and we’ll handle the details in Step 2.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(6); }} className={classes.startWalkThru}>Next</Button>
                      {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={classes.dragBlock} id="time_line_drag_events">
          <div className={classes.container}>
            <div className={classes.timelineArrow}>
              <img src={arrow} alt="" />
            </div>
            <Grid container className={classes.relativeBlock}>
              <ReactTooltip id="walkThruToolTip" place="top" type="info" effect="solid" className={classes.walkThruIcon} />
              <Grid item sm={1}>
                <div className={classNames(classes.walkThru, activeWalkThru === 0 && classes.pwiActiveWalkIcon)} >
                  <span aria-hidden="true" >
                    <div
                      onClick={() => { setActiveWalkThru(0); setWalkThru(true) }}
                      className={classes.infoIcon}
                      data-for="walkThruToolTip"
                      data-tip="Click here for a Walk-Thru of the Timeline Sketcher."
                    >
                      <img src={walkthru} alt="" />
                    </div>
                  </span>
                </div>
              </Grid>
              {activeWalkThru === 0 && (
                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruOne)}>
                  <h4>Get Started in the Timeline Sketcher with an Inkwiry Walk-Thru</h4>
                  <p>Welcome to the Timeline Sketcher, a complete picture of your career sketch. This Walk-Thru will detail the tools available to you as you build your best future. To get started, click through these quick tips to help you navigate the sketcher with confidence!</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(1); }} className={classes.startWalkThru}>Start</Button>
                      {type !== 'mcs5' && (<Button onClick={() => { setActiveWalkThru(''); setWalkThru(false)}} className={classes.skipWalkthru}>Skip walk-thru</Button>)}

                    </div>
                  </div>
                </div>
              )}
              {activeWalkThru === 11 && (
                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruTwelve)}>
                  <h4>Timeline Sketcher Walk-Thru Complete!</h4>
                  <p>Now you know the tools, time to get to planning! If you want to access this Walk-Thru again, just click on this module icon. Now, get started, drag and drop to build your timeline, double click on life events to capture your details, set goals in Plan With Inkwiry, and illuminate your best path into the future.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(''); handleClose('1'); setWalkThru(false) }} className={classes.skipWalkthru}>Close</Button>
                    </div>
                    {errorMessage && <span>{errorMessage}</span>}
                  </div>
                </div>
              )}

              <Grid item sm={10} xs={12} className={classes.timelineSpacing}>
                <div className={classNames(classes.timelineEvents, activeWalkThru === 1 && classes.pwiActiveModule)}>
                  <h3>Drag and drop life events to the timeline.</h3>
                  <p>
                    Then, double-click on the life event and enter details to create
                    <span>your roadmap to the future.</span>
                  </p>
                  {activeWalkThru === 1 && (
                    <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruTwo)}>
                      <h4>Building your Career Sketch is as easy as 1, 2, 3</h4>
                      <p>Step 1: Drag and drop life events to your timeline. Step 2: Double click on modules and capture your details. Step 3: Inkwiry’s Simulator Engine builds your career sketch</p>
                      <div className={classes.walkthruBottom}>
                        <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                        <div className={classes.walkThruBtns}>
                          <Button onClick={() => { setActiveWalkThru(2); }} className={classes.startWalkThru}>Next</Button>
                          {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item sm={1} xs={12}>
                <div className={classes.summaryDocs}>
                  {/* {menuOpen === true && (
                  <div className={ classes.timelineClose }>
                    <span aria-hidden="true" onClick={ handleCloseMenu } />
                  </div>
                  )} */}
                  <div className={classNames(classes.summaryDocsLinks, (currentURL === "mcs4" || currentURL === "mcs5") && classes.handburgNone)}>
                    <img src={hamburger} alt="" aria-hidden="true" onClick={handleOpenMenu} />
                    {
                      menuOpen === true && (
                        <div className={classes.timelineDropdown}>
                          <div className={classes.timelineClose}>
                            <span aria-hidden="true" onClick={handleCloseMenu} />
                          </div>
                          <ul className={classes.dropdownList}>
                            <li>
                              <div className={classes.listName} aria-hidden="true" onClick={() => { setOpenPopup('simulator'); handleCloseMenu(); }}>
                                <span className={classes.dropdownImage}><img src={simulator} alt="" /></span>
                                <span>Simulator Panel</span>
                              </div>
                            </li>
                            <li aria-hidden="true" onClick={handleSummaryDocuments}>
                              <div className={classes.listName}>
                                <span className={classes.dropdownImage}><img src={summary} alt="" /></span>
                                <span>Summary Documents</span>
                              </div>
                              {
                                summaryDocOpen === true && (
                                  <div className={classes.summaryTimelinemenu}>
                                    <ul className={classes.summaryTimeline}>
                                      <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('summary-output'); handleCloseMenu(); }}>Summary Output</p></li>
                                      <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('inputsheet-statement'); handleCloseMenu(); }}>Summary Financials</p></li>
                                      <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('summary-table'); handleCloseMenu(); }}>Summary Tables</p></li>
                                      <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('income-statement'); handleCloseMenu(); }}>Income Statement</p></li>
                                      <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('tax-statement'); handleCloseMenu(); }}>Tax Statement</p></li>
                                      <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('balance-statement'); handleCloseMenu(); }}>Balance Sheet</p></li>
                                      <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('cashflow-statement'); handleCloseMenu(); }}>Cash Flow Statement</p></li>
                                    </ul>
                                  </div>
                                )
                              }
                            </li>
                            <li>
                              <div className={classes.listName} aria-hidden="true" onClick={() => { history.push('/dashboard'); }}>
                                <span className={classes.dropdownImage}><img src={dashboard} alt="" /></span>
                                <span>Dashboard</span>
                              </div>
                            </li>
                            <li>
                              <div className={classes.listName} aria-hidden="true" onClick={() => { setOpenPopup('sight-load-career'); handleCloseMenu(); }}>
                                <span className={classes.dropdownImage}><img src={load} alt="" /></span>
                                <span>Load Career Sketch</span>
                              </div>
                            </li>
                            <li>
                              <div className={classes.listName} aria-hidden="true" onClick={() => { setOpenPopup('sight-save-career'); handleCloseMenu(); }}>
                                <span className={classes.dropdownImage}><img src={save} alt="" /></span>
                                <span>Save Career Sketch</span>
                              </div>
                            </li>
                            <li>
                              <div className={classes.listName} aria-hidden="true" onClick={confirmClearTimeLine}>
                                <span className={classes.dropdownImage}><img src={clear} alt="" /></span>
                                <span>Clear Timeline</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )
                    }
                  </div>
                </div>
              </Grid>
              {activeWalkThru === 9 && (
                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruTen)}>
                  <h4>Menu options</h4>
                  <p>Click on the menu to access your Simulator Panel, Summary Documents, and Dashboard. The Simulator Panel allows you to collaborate with family, friends, or trusted colleagues. The Summary Documents includes your Income Statement, Balance Sheet, and more. The Dashboard transforms your data into intelligent visualizations so you can understand your data at a deeper level.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(10); }} className={classes.startWalkThru}>Next</Button>
                      {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false) }} className={classes.closeWalkThru}>Close</Button>}
                    </div>
                  </div>
                </div>
              )}
              {activeWalkThru === 10 && (
                <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruEleven)}>
                  <h4>Menu options</h4>
                  <p>A Career Sketch is a complete 5, 10, 15 year financial life plan. Once you have multiple scenarios built, save them as a career sketch so that you can reload those scenarios together. Want to start fresh? Use the Clear Timeline and build a new career sketch. That&apos;s the beauty of planning at the speed of thought.</p>
                  <div className={classes.walkthruBottom}>
                    <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                    <div className={classes.walkThruBtns}>
                      <Button onClick={() => { setActiveWalkThru(11); }} className={classes.startWalkThru}>Next</Button>
                      {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                    </div>
                  </div>
                </div>
              )}
            </Grid>
            {activeWalkThru === '' ? (
              <div className={classes.activeSelection}>
                <p>
                  {timeLineActions.careerSketchName !== undefined && timeLineActions.careerSketchName !== '' ? timeLineActions.careerSketchName : 'Active Selection'}
                </p>
                <div className={classes.netWorthValues}>
                  <div className={classes.activeButton}>
                    {modulesServices.cashFlowCheckVal <= 0 && (
                      <Button onClick={() => { setOpenDocsPopup('cashflow-statement'); }}>Cash Positive</Button>
                    )}
                    {modulesServices.cashFlowCheckVal > 0 && (
                      <Button onClick={() => { setOpenDocsPopup('cashflow-statement'); }} className={classes.redButton}>
                        Cash Negative
                        {' '}
                        {modulesServices.cashFlowCheckVal}
                      </Button>
                    )}
                  </div>
                  <p>
                    <span>Current Net Worth:</span>
                    <span className={current_year < 0 && classes.redText}>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={current_year} prefix={current_year >= 0 ? '$' : '($'} suffix={current_year < 0 && ')'} />
                    </span>
                  </p>
                  <p>
                    <span>5-year Net Worth:</span>
                    <span className={year_5th < 0 && classes.redText}>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_5th} prefix={year_5th >= 0 ? '$' : '($'} suffix={year_5th < 0 && ')'} />
                    </span>
                  </p>
                  <p>
                    <span>10-year Net Worth:</span>
                    <span className={year_10th < 0 && classes.redText}>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_10th} prefix={year_10th >= 0 ? '$' : '($'} suffix={year_10th < 0 && ')'} />
                    </span>
                  </p>
                  <p>
                    <span>15-year Net Worth:</span>
                    <span className={year_15th < 0 && classes.redText}>
                      <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={year_15th} prefix={year_15th >= 0 ? '$' : '($'} suffix={year_15th < 0 && ')'} />
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              <div className={classes.activeSelection}>
                <p>Monica - Executive Chef</p>
                <div className={classes.netWorthValues}>
                  <div className={classes.activeButton}><Button>Cash Flow Positive</Button></div>
                  <p>Net Worth</p>
                  <p>
                    <span>5-Year</span>
                    <span>$77,639</span>
                  </p>
                  <p>
                    <span>10-Year</span>
                    <span>$91,953</span>
                  </p>
                  <p>
                    <span>15-Year</span>
                    <span>$453,644</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={classes.csTimeline} id="cs_time_line">
          <div className={classNames(classes.container, classes.careerSketchTimelineInCnt, classes.relativeBlock)}>

            <TimeLineList manageModule={manageModule} timeLineData={walkThru ? displayWalkThruData : moduleTimeLine} page="time-line" showStartYearInfo={showStartYearInfo} type={type} />

            {activeWalkThru === 8 && (<div className={classNames(classes.timelineDesc, classes.timelineDescActive)} />)}

            {activeWalkThru === 2 && (
              <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruThree)}>
                <h4>Set your plan in motion with a start year</h4>
                <p>The Financial Independence module will always be on the timeline because that module sets your plan in motion with an essential detail – the start year of your career sketch. If you have an existing asset such as a car or home, or existing debt such as student loans or car loans, drag the Existing Assets + Liabilities module to the timeline, too! These two modules handle all your current data.</p>
                <div className={classes.walkthruBottom}>
                  <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                  <div className={classes.walkThruBtns}>
                    <Button onClick={() => { setActiveWalkThru(3); }} className={classes.startWalkThru}>Next</Button>
                    {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                  </div>
                </div>
              </div>
            )}
            {activeWalkThru === 6 && (
              <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruSeven)}>
                <h4>Step 2: Double-click on life events and capture your details</h4>
                <p>Step 2: With your timeline fully sketched out, you can move to Step 2. Before you start on future modules, capture your current details in the Financial Independence and Existing Assets + Liabilities modules. Then, come back to your timeline and plan your next move.</p>
                <div className={classes.walkthruBottom}>
                  <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                  <div className={classes.walkThruBtns}>
                    <Button onClick={() => { setActiveWalkThru(7); }} className={classes.startWalkThru}>Next</Button>
                    {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                  </div>
                </div>
              </div>
            )}
            {activeWalkThru === 7 && (
              <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruEight)}>
                <h4>Step 2: Double-click on life events and capture your details</h4>
                <p>Once you enter your start year in the FI module and capture your existing assets and liabilities, start moving through your timeline module by module and build a roadmap to your future. As you build scenarios, the timeline will always rearrange itself in chronological order.</p>
                <div className={classes.walkthruBottom}>
                  <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                  <div className={classes.walkThruBtns}>
                    <Button onClick={() => { setActiveWalkThru(8); }} className={classes.startWalkThru}>Next</Button>
                    {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                  </div>
                </div>
              </div>
            )}
            {activeWalkThru === 8 && (
              <div className={classNames(classes.pwiWalkthru, classes.pwiActiveWalkthru, classes.pwiWalkthruNine)}>
                <h4>Step 2: Double-click on life events and capture your details</h4>
                <p>After you build a scenario, hover on that module to see the key details. In the bottom right corner of the box, you’ll see two icons. Click to load a different scenario or to clear the current scenario and remove the module from your timeline.</p>
                <div className={classes.walkthruBottom}>
                  <WalkThruDotsAll totalSlides={totalWalkthru} setActiveWalkThru={setActiveWalkThru} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThru} />
                  <div className={classes.walkThruBtns}>
                    <Button onClick={() => { setActiveWalkThru(9); }} className={classes.startWalkThru}>Next</Button>
                    {type !== 'mcs5' && <Button onClick={() => { setActiveWalkThru(''); setWalkThru(false); }} className={classes.closeWalkThru}>Close</Button>}
                  </div>
                </div>
              </div>
            )}

          </div>

        </div>
      </div>
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={otherData} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />

    </Typography>
  );
};

BuildCareerSketch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(combineStyles(styles, confirmStyles)),
  withRouter,
);

export default enhance(BuildCareerSketch);
