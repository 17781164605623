import LeftArrow from '../../assets/img/testimonial-left-arrow.png';
import RightArrow from '../../assets/img/testimonial-right-arrow.png';
import selectDrop from '../../assets/img/financial-independence/select_drop.png';
import leftArrow from '../../assets/img/career-path/arrow_img_2.png';
import rightArrow from '../../assets/img/career-path/arrow_img_1.png';
import fidummyImage from '../../assets/img/financial-independence/financialindependence-dummy-img.jpg';
import showImage from '../../assets/img/financial-independence/financialindependence-dummy-show.jpg';
import saveWalkthru from '../../assets/img/financial-independence/financialindependence-dummy-compare.jpg';

import existingassets2 from '../../assets/img/financial-independence/existingassets2.jpg';
import dummyImgShowmeCareerpath from '../../assets/img/financial-independence/dummy-img-showme_careerpath.jpg';
import reiWalkThru2 from '../../assets/img/financial-independence/rei-walk-thru-2.png';
import dummyImgShowme from '../../assets/img/financial-independence/dummy-img-showme.jpg';
import dummyImgShowmeExisting from '../../assets/img/financial-independence/dummy-img-showme_existing.jpg';
import whatIfExisting from '../../assets/img/financial-independence/what-if-existing.jpg';
import dummyImgShowmeMoresavings from '../../assets/img/financial-independence/dummy-img-showme_moresavings.jpg';
import careeradVancementShowMe from '../../assets/img/financial-independence/careerad_vancement_show_me.jpg';
import dummyImgCompareExisting from '../../assets/img/financial-independence/dummy-img-compare_existing.jpg';
import dummyImgCareerpath1 from '../../assets/img/financial-independence/dummy-img-careerpath1.jpg';
import dummyImgCompareScenarios from '../../assets/img/financial-independence/dummy-img-compare_scenarios.jpg';
import additionalCareerWalk1 from '../../assets/img/financial-independence/additional_career_walk_1.jpg';
import additionalCareerWalk2 from '../../assets/img/financial-independence/additional_career_walk_2.jpg';
import additionalCareerWalk3 from '../../assets/img/financial-independence/additional_career_walk_3.jpg';
import furtherCareerWalk1 from '../../assets/img/financial-independence/further_career_walk_1.jpg';
import furtherCareerWalk2 from '../../assets/img/financial-independence/further_career_walk_2.jpg';
import furtherCareerWalk3 from '../../assets/img/financial-independence/further_career_walk_3.jpg';
import careerAdvancementMydetails from '../../assets/img/financial-independence/career_advancement_mydetails.jpg';
import careerAdvancementSavedScenareos from '../../assets/img/financial-independence/career_advancement_saved_scenareos.jpg';
import investmentsDetails from '../../assets/img/financial-independence/investments_details.jpg';
import investmentsShowMe from '../../assets/img/financial-independence/investments_show_me.jpg';
import reiWalkThru1 from '../../assets/img/financial-independence/rei-walk-thru-1.png';
import reiWalkThru3 from '../../assets/img/financial-independence/rei-walk-thru-3.png';
import reiWalkThru4 from '../../assets/img/financial-independence/rei-walk-thru-4.png';
import dummyImg from '../../assets/img/financial-independence/dummy-img.jpg';
import dummyImgCompare from '../../assets/img/financial-independence/dummy-img-compare.jpg';
import familyDetails from '../../assets/img/financial-independence/family_details.jpg';
import familyShowMe from '../../assets/img/financial-independence/family_show_me.jpg';
import vactionDetails from '../../assets/img/financial-independence/vaction_details.jpg';
import carDetails from '../../assets/img/financial-independence/car_details.jpg';
import carShowMe from '../../assets/img/financial-independence/car_show_me.jpg';
import buyingahomeWalkThru1 from '../../assets/img/financial-independence/buyingahome_walk_thru_1.png';
import buyingahomeWalkThru2 from '../../assets/img/financial-independence/buyingahome_walk_thru_2.png';
import buyingahomeWalkThru3 from '../../assets/img/financial-independence/buyingahome_walk_thru-3.png';
import buyingahomeWalkThru4 from '../../assets/img/financial-independence/buyingahome_walk_thru_4.png';


const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  testimonialInner: {
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
    '& button': {
      zIndex: '1',
    },
    '& > div': {
      alignItems: 'center',
      position: 'static',
      '& ul': {
        bottom: '0px',
        left: '0',
        marginBottom: '15px',
        [theme.breakpoints.down( 'xs' )]: {
          bottom: '0px',
        },
      },
      '& li': {
        top: '0',
        margin: ' 0 3px 0px 3px',
        [theme.breakpoints.down( 'xs' )]: {
          top: '11px',
        },
        '& button': {
          background: '#fff',
          borderRadius: '50%',
          height: '13px',
          width: '13px',
          border: '2px solid #fff',
          '&::before': {
            display: 'none',
          },
        },
        '&:active, &:focus, &:visited, &:hover': {
          '& button': {
            backgroundColor: '#fa9c22 !important',
            borderColor: '#fff !important',
          },
        },
      },
      '& button + div + button': {
        backgroundImage: `url(${RightArrow})`,
        height: '101px',
        width: '50px',
        right: '0px',
        borderBottomLeftRadius: '25px',
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '0',
        backgroundSize: '30px',
        backgroundColor: '#fff !important',
        top: '47% !important',
        boxSizing: 'border-box',
        [theme.breakpoints.down( 'sm' )]: {
          width: '40px',
          height: '90px',
          backgroundSize: '20px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          left: '-110px',
        },
        '&:hover,  &:focus': {
          backgroundImage: `url(${RightArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '0',
          backgroundSize: '30px',
          backgroundColor: '#fffff',
          opacity: '0.9',
          [theme.breakpoints.down( 'sm' )]: {           
            backgroundSize: '20px',
          },
        },
        '&::before': {
          display: 'none',
        },
      },

      '& >button:first-child': {
        backgroundImage: `url(${LeftArrow})`,
        height: '101px',
        width: '50px',
        left: '0px',
        borderBottomRightRadius: '25px',
        borderTopRightRadius: '25px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '0',
        backgroundSize: '30px',
        backgroundColor: '#fff',
        top: '47% !important',
        boxSizing: 'border-box',
        [theme.breakpoints.down( 'sm' )]: {
          width: '40px',
          height: '90px',
          backgroundSize: '20px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          left: '-110px',
        },
        '&:hover,  &:focus': {
          backgroundImage: `url(${LeftArrow})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          marginTop: '0',
          backgroundSize: '30px',
          backgroundColor: '#fffff',
          opacity: '0.9',
          [theme.breakpoints.down( 'sm' )]: {           
            backgroundSize: '20px',
          },
        },
        '&::before': {
          display: 'none',
        },
      },
    },
    '& figure img': {
      width: '175px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      '& > div': {
        display: 'block',
      },
      '& figure img': {
        margin: '0 auto',
      },
    },
  },
  tstimonialContent: {
    paddingBottom: '55px',
    boxSizing: 'border-box',
    // marginBottom: '35px',
    '& h1': {
      fontSize: '70px',
      color: theme.palette.common.white,
      marginTop: '0px',
      marginBottom: '20px',
      fontFamily: 'MuseoSans-300',
      paddingTop: '40px',
      fontWeight: '500',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '30px',
        marginBottom: '0px',
        paddingTop: '20px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '24px',
        paddingTop: '20px',
        marginBottom: '10px',
      },
    },
    '& h2': {
      fontSize: '44px',
      marginBottom: '35px',
      marginTop: '10px',
      color: theme.palette.common.white,
      textAlign: 'left',
      fontWeight: '500',
      lineHeight: '1.1',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down( 'md' )]: {
        fontSize: '25px',
        marginBottom: '15px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '21px',
        marginBottom: '10px',
        marginTop: '0px',
      },
    },
    '& ul': {
      padding: '0 0 0 20px',
      marginBottom: '10px !important',
      marginTop: '0px',
      '& li': {
        color: theme.palette.common.white,
        fontSize: '22px',
        textAlign: 'left',
        listStyleType: 'disc',
        lineHeight: '1.42857143',
        margin: '0px !important',
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '15px',         
        },
      },
    },
  },
  financeSlider: {
    background: 'linear-gradient(to bottom,#0a6446 0,#0a6445 100%)',
  },
  startYear: {
    backgroundColor: '#F7941E',
    float: 'left',
    fontSize: '28px',
    marginTop: '45px',
    maxWidth: '424px',
    width: '100%',
    textTransform: 'uppercase',
    fontFamily: 'MuseoSans-500 !important',
    fontWeight: 'normal',
    padding: '11px 22px',
    borderRadius: '6px',
    border: '1px solid #fff',
    lineHeight: 'normal',
    textDecoration: 'none',
    textAlign: 'center',
    color: theme.palette.common.white,
    boxSizing: 'border-box',
    cursor: 'pointer',
    marginBottom: '10px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '18px',
      marginTop: '20px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '19px',
      marginTop: '10px',      
    },
  },
  greenBtn: {
    backgroundColor: '#84a84d',
    float: 'left',
    fontSize: '25px',
    marginTop: '0px',
    maxWidth: '424px',
    textTransform: 'uppercase',
    fontFamily: 'MuseoSans-300',
    fontWeight: '400',
    padding: '0px 22px',
    borderRadius: '6px',
    border: '1px solid #fff',
    lineHeight: '45px',
    textDecoration: 'none',
    textAlign: 'center',
    color: theme.palette.common.white,
    cursor: 'pointer',
    height: '45px',
    boxSizing: 'border-box',
    transition: 'all .5s ease 0s',
    Webkittransition: 'all .5s ease 0s',
    width: '100%',
    '&:hover': {
      backgroundColor: '#759840',
    },
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
    },
  },
  sliderImage: {
    width: '100%',
    boxsizing: 'border-box',
    float: 'right',
    minHeight: '510px',
    [theme.breakpoints.down( 'sm' )]: {
      minHeight: 'auto',
    },
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
      maxWidth: '490px',
      float: 'right',
      boxSizing: 'border-box',
      border: '2px solid #ffffff',
    },
  },
  fiWorksheet: {
    background: '#e5f0f6',
  },
  fiRow: {
    background: '#fff',
    margin: '35px 0 20px',
    padding: '25px 35px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '25px 0px',
    },
  },
  fiModuleIcons: {
    '& > ul': {
      paddingLeft: '0px',
      textAlign: 'center',
      marginTop: '0px',
      marginBottom: '25px',
      '& > li': {
        display: 'inline-block',
        paddingRight: '5px',
        paddingLeft: '5px',
        margin: '2px',
        verticalAlign: 'top',
        '& a': {
          display: 'block',
          position: 'relative',
          '& img': {
            maxWidth: '45px',
            width: '100%',
            verticalAlign: 'middle',
            '@media (min-width: 992px)': {
              maxWidth: '40px',             
            },           
            '@media (max-width: 768px)': {
              maxWidth: '25px',
              paddingLeft: '1px',
              paddingRight: '1px',
            },
            '@media (max-width: 600px)': {
              maxWidth: '22px',             
            },
          },
        },
      },
    },
  },
  fiLeftArrow: {
    '& a': {
      display: 'block',
      position: 'relative',
      width: '35px',
      height: '35px',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        width: '100%',
        height: '2px',
        left: '2px',
        background: '#bcbec0',
        marginTop: '-2px',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '8px',
        width: '18px',
        height: '18px',
        borderTop: '2px solid #bcbec0',
        borderLeft: '2px solid #bcbec0',
        transform: 'rotate(-42deg) skew(10deg)',
        left: '5px',
      },
    },
  },
  timelineBar: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    color: '#898d8f',
    fontSize: '12px',
    '& li': {
      margin: '2px',
      verticalAlign: 'top',
      textAlign: 'center',
    },
  },
  isDisabled: {
    '&:hover': {
      cursor: 'not-allowed !important',
    },
  },
  detailsSection: {
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0 15px',
    },
    '& h1': {
      fontSize: '36px',
      marginTop: '20px',
      marginBottom: '10px',
      color: '#0069aa',
      fontWeight: '500',
      fontFamily: 'MuseoSans-300',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
        marginTop: '0px',
      },
      '& span': {
        display: 'inline-block',
        width: '100%',
        [theme.breakpoints.down( 'sm' )]: {
          display: 'inline',
        },
      },
    },
  },
  leftIconPuzzle: {
    marginTop: '28px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '0px',
    },
    '& img': {
      maxWidth: '50px',
      cursor: 'pointer',
      float: 'right',
      verticalAlign: 'middle',
    },
  },
  detailsButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f1f1f1',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '3px 10px',
    '@media (max-width: 767px)': {
      flexWrap:'wrap',
    },
  },
  scenarioLeft: {
    width: '50%',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    [theme.breakpoints.down( 'sm' )]: {      
      flexWrap:'wrap',
      width: '100%',
      paddingRight: '10px',      
    },
    '@media (min-width: 992px)': {
      width: '47%',
    },
    [theme.breakpoints.down( 'md' )]: {    
      justifyContent: 'flex-start',
      paddingRight: '0px', 
    },
  },
  scenarioName: {
    fontSize: '13px',
    color: '#898d8f',
    fontWeight: '700',
    marginRight: '2px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '9.5px',     
    }, 
  },
  leftButtons: {
    float: 'right',
    marginRight: '15px',
    [theme.breakpoints.down( 'sm' )]: {
     display: 'flex',
    },
    '& button': {
      padding: '5px 10px',
      marginBottom: '0',
      fontSize: '13px',
      lineHeight: '1.42857143',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      userSelect: 'none',
      backgroundImage: 'none',
      border: '1px solid transparent',
      borderRadius: '4px',
      textAlign: 'center',
      cursor: 'pointer',
      fontWeight: '400',
      color: '#fff',
      backgroundColor: '#337ab7',
      borderColor: '#2e6da4',
      width: '45px',
      height: '38px',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 'inherit',
      margin: '0 0 0 5px',
      [theme.breakpoints.down( 'sm' )]: {
        width: 'auto',
        height: 'auto',
        padding: '5px 5px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: 'auto',
        height: 'auto',
        padding: '5px 8px',
       },
      '& img': {
        width: '22px',
        marginTop: '0',
        verticalAlign: 'middle',
        [theme.breakpoints.down( 'sm' )]: {
          width: '18px',
        },
      },
      '& i': {
        fontSize: '23px',      
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '20px',
        },
        '@media (max-width: 768px)': {
          fontSize: '18px',
        },
      },
      '&:hover': {
        color: '#fff',
        backgroundColor: '#286090',
        borderColor: '#204d74',
      },
    },
  },
  scenarioRight: {
    width: '50%',
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      flexWrap:'wrap',
      width: '100%',     
      justifyContent: 'center',
      float: 'none',
      marginTop: '10px',
    },
    '@media (min-width: 480px) and (max-width: 767px)': {
      justifyContent: 'flex-start',
    }
  },
  cfPositive: {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 479px)': {
     margin: '0px 0px 10px 0px',
     height: '30px',
    },
    '& span': {
      backgroundColor: '#85a94d',
      color: '#fff',
      verticalAlign: 'middle',
      position: 'relative',
      fontSize: '13px',
      borderRadius: '4px',
      border: 'none',
      lineHeight: '.428571',
      top: 'auto',
      left: 'auto',
      marginRight: '3px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '12px 10px',
      boxSizing: 'border-box',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '10px',
       },
    },
  },
  cfNegative: {
    height: '38px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 500px)': {
      margin: '0px 0px 10px 0px',     
     },
    '& span': {
      backgroundColor: '#bf0000',
      color: '#fff',
      verticalAlign: 'middle',
      position: 'relative',
      fontSize: '13px',
      borderRadius: '4px',
      border: 'none',
      lineHeight: '14px !important',
      top: 'auto',
      left: 'auto',
      marginRight: '3px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '12px 10px',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '10px',
       },
      '@media (min-width: 800px)': {
        whiteSpace: 'nowrap',
      },
    },
  },
  hePwiIcons: {
    float: 'right',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#0069aa',
    borderRadius: '4px',
    position: 'relative',
    '@media (max-width: 479px)': {
      width: '100%',
    },
    '& a, & span': {
      borderRadius: '4px',
      marginRight: '3px',
      width: '44px',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0',
      display: 'flex',
      height: '38px',
      cursor: 'pointer',     
      [theme.breakpoints.down( 'sm' )]: {
        width: '25px',
      },    
      '@media (max-width: 768px)': {
        width: '22px',
      }, 
      '@media (max-width: 414px)': {
        width: '30px',
      },
      '& img': {
        width: '25px',
        verticalAlign: 'middle',
        [theme.breakpoints.down( 'sm' )]: {
          width: '16px',
        },
        '@media (max-width: 414px)': {
          width: '20px',
        },
      },
      '&:hover': {
        backgroundColor: '#0d97ec',
        borderRadius: '0',
      },
    },
  },
  detailsPortion: {
    padding: '10px 15px',
    marginBottom: '15px',
    border: '1px solid #b3b3b3',
    height: 'auto',
    overflowY: 'hidden',
    overflowX: 'scroll',
    position:'relative',
    // margin: '0 -15px',
  },
  scalePortion: {
    width: '100%',
    margin: '0 auto',
  },
  detailsBlock: {
    padding: '0 15px !important',
    boxSizing: 'border-box',
    margin: '0 auto',
    position:'relative',
    [theme.breakpoints.down( 'sm' )]: {
      width:'98%',
      maxWidth: '98%',
      flexBasis: '98%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width:'100%',
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  scrollPortion: {
    border: '1px solid #b3b3b3',
    maxHeight: '720px',
    height: 'auto',
    overflowX: 'hidden',
    overflowY: 'scroll',
    padding: '10px',
    marginBottom: '10px',
    position:'relative',
  },
  scrollPortionInFI: {
    border: '1px solid #b3b3b3',
    padding:'5px',
    overflow:'hidden'
  },
  scrollPortionInBaH: {
    border: '1px solid #b3b3b3',
    padding:'5px',
    overflow:'hidden'
  },
  detailsRow: {
    margin: '0px -15px',
    boxSizing: 'border-box',
  },
  leftTitle: {
    backgroundColor: 'transparent',
    border: '0',
    display: 'block',
    fontSize: '14px',
    padding: '0 9px',
    height: '40px',
    verticalAlign: 'middle',
    color: '#898d8f',
    lineHeight: '1',
    float: 'left',
    width: '100%',
  },
  currencyText: {
    fontSize: '13px',
    display: 'table-cell',
    height: '38px',
    verticalAlign: 'middle',
    color: '#333',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },
    '& strong': {
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-500',
    },
  },
  inputText: {
    padding: '10px 9px 10px 15px',
    width: '100%',
    border: '0',
    boxShadow: 'none',
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
    height: '40px',
    lineHeight: '20px',
    borderRadius: '0',
    float: 'left',
    backgroundColor: '#fff',
    display: 'block',
    marginBottom: '0',
    boxSizing: 'border-box',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },
  },
  leftGrayBox: {
    backgroundColor: '#e7e7e7',
    border: '1px solid #898d8f',
    color: '#898d8f',
    lineHeight: '1',
    float: 'left',
    width: '100%',
    display: 'block',
    fontSize: '14px',
    padding: '0 9px',
    height: '40px',
    verticalAlign: 'middle',
    boxSizing: 'border-box',
  },
  currencyCntnt: {
    display: 'table-cell',
    height: '38px',
    verticalAlign: 'middle',
    fontSize: '13px',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },
    '& p': {
      margin: '0px',
      '& strong': {
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-300',
      },
    },
  },
  inputRight: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& div': {
      '&::before': {
        border: 'none !important',
        outline: 'none !important',
      },
      '&::after': {
        border: 'none',
      },
    },
    '& input': {
      width: '100%',
      position: 'relative',
      color: '#0069aa',
      borderColor: '#898d8f',
      paddingLeft: '15px',
      fontSize: '13px',
      fontWeight: '600',
      height: '40px',
      lineHeight: '20px',
      borderRadius: '0',
      borderLeft: '0',
      float: 'left',
      display: 'block',
      padding: '0 9px',
      verticalAlign: 'middle',
      border: '1px solid #ccc',
      boxShadow: '0 2px 2px rgba(0,0,0,.075) inset',
      marginBottom: '0',
      backgroundImage: 'none',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      margin: '0',
      font: 'inherit',
      boxSizing: 'border-box',
      outline: '0',
      '@media (max-width: 600px)': {
        fontSize: '11px',
      },
      '&:focus': {
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
      '&:disabled': {
        backgroundColor: '#eee',
        opacity: 1,
      },
    },
  },
  formInput: {
    width: '85%',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },  
  },
  infoIcon: {
    margin: '9px 1px',
    textAlign: 'center',
    display: 'inline-block',
    width: '25px !important',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '20px',
      position: 'relative',
      left: '2px',
     },
    '& img': {
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      border: '0',
      verticalAlign: 'middle',
      [theme.breakpoints.down( 'sm' )]: {
       maxWidth: '15px',
      },
    },
  },
  formTitleRow: {
    paddingTop: '1px',
    '& h3': {
      margin: '20px 0px',
      fontSize: '16px',
      lineHeight: '23px',
      color: '#0069aa',
      fontFamily: 'MuseoSans-300',
      fontWeight: 'normal',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '15px',
        margin: '0px 0px 5px 0px',
       },
       [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '5px',
      }, 
    },
  },
  percentSymbol: {
    float: 'right',
    left: '75%',
    paddingLeft: '5px',
    position: 'absolute',
    top: '11px',
    color: '#0069aa',
    fontFamily: 'MuseoSans-500',
    [theme.breakpoints.down( 'md' )]: {
      left: '73%',
      top: 'auto',
    },
    [theme.breakpoints.down( 'sm' )]: {
      left: '74%',
      lineHeight: '1.4',
    },
    '@media (max-width: 600px)': {
      left: 'auto',
      right: '40px',
      fontSize: '11px',
    },
    '@media (max-width: 500px)': {
      left: 'auto',
      right: '30px',
    },   
  },
  formGroup: {
    marginBottom: '15px',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '10px',
    },   
  },
  showMe: {
    transition: 'all .3s ease-in-out',
    background: '#fff',
  },
  hideText: {
    display: 'none !important',
  },
  sectionHeader: {
    position: 'relative',    
    marginBottom: '0',
    transition: 'all .3s ease-in-out',
    margin: '35px 0 20px',
    padding: '25px 35px',
    '@media (max-width: 768px)': {
      margin: '20px 0 20px',
      padding: '15px 15px',
      marginBottom: '0px',     
    },
    '&:hover': {
      '& showText': {
        display: 'none !important',
      },
    },
  },
  fiContentSection: {
    paddingBottom: '40px',
  },

  graphButton: {
    padding: '7px 10px',
    borderRadius: '4px',
    border: 'none',
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '11px',
    },
    '&:hover': {
      backgroundColor: '#d58512',
      borderColor: '#985f0d',
    },
  },
  sectionTitle: {
    '& h1': {
      fontSize: '36px',
      marginTop: '20px',
      marginBottom: '10px',
      display: 'table',
      width: '100%',
      fontWeight: '500',
      color: '#0069aa',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '23px',
        margin:'0px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        margin:'0px',
      },
      '& span': {
        cursor: 'pointer',
        height: '48px',
        display: 'inline-block',
        [theme.breakpoints.down( 'sm' )]: {
         height: 'auto',
        },
        '& i': {
          marginRight: '0',
          paddingRight: '.3em',
          float: 'none',
          display: 'table-cell',
          verticalAlign: 'middle',
        },
      },
      '& strong': {
        fontWeight: '400',
        display: 'table-cell',
        verticalAlign: 'middle',
        float: 'none',
        width: '100%',
        fontFamily: 'MuseoSans-500',
      },
    },
  },
  sectionContent: {
    padding: '0 35px 35px 35px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '0 15px 35px 15px',
    },
  },
  showDescriptionCntnt: {
    padding: '10px 0',
    border: '1px solid #2f75b5',
    margin: '0',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },
  },
  graphBlock: {
    '& h3': {
      marginBottom: '0',
      color: '#000',
      fontWeight: 'normal',
      fontSize: '22px',
      textAlign: 'center',
      fontFamily: 'MuseoSans-500',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '15px',
       },
    },
  },
  blueRow: {
    borderTop: '2px solid #2f75b5',
    margin: '10px',
    boxSizing: 'content-box',
  },
  awarenessHeading: {
    color: '#333',
    fontSize: '28px',
    marginTop: '0',
    fontWeight: '500',
    marginBottom: '10px',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '20px',
    },
    '& sup': {
      fontSize: '12px',
      fontFamily: 'MuseoSans-500',
    },
  },
  panelTitle: {
    padding: '10px 0px',
    '& h1': {
      fontWeight: '400',
      fontFamily: 'MuseoSans-300',
      fontSize: '30px',
      color: '#333',
      margin: '0px',
      cursor: 'pointer',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
      '& i': {
        color: '#f3923d',
        marginRight: '.3em',
        cursor: 'pointer',
      },
    },
  },
  panelRow: {
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '5px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '&:nth-child(1)': {
      marginTop: '0px',
    },
  },
  panelMargin: {
    marginTop: '0px',
  },
  panelBlock: {
    '& h3': {
      borderLeft: '3px solid #0069aa',
      color: '#333',
      fontSize: '26px',
      paddingLeft: '10px',
      fontFamily: 'MuseoSans-300',
      fontWeight: '500',
      margin: '20px 0px 10px 0px',
      lineHeight: '1.1',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '18px',
      },
      '& a': {
        color: '#337ab7',
        fontFamily: 'MuseoSans-500',
        '&:hover': {
          textDecoration: 'underline',
          color: '#23527c',
        },
      },
    },
    '& p': {
      fontSize: '18px',
      margin: '0 0 10px',
      color: '#000',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
      '& span': {
        display: 'block',
      },
      '& b': {
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
      },
      '& a': {
        textDecoration: 'underline',
        [theme.breakpoints.down( 'sm' )]: {
         marginBottom: '10px',
        },
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
    '& spaceRow': {
      margin: '15px 0px 0px',
    },
  },
  fcTerms: {
    '& span': {
      margin: '15px 0 0',
      display: 'block',
      fontSize: '18px',
      color: '#000',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '15px',
      },
      '& b': {
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-500',
      },
      '& a': {
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
  },
  menuImage: {
    marginTop: '20px',
    '& img': {
      width: '100%',
    },
  },
  tabButtons: {
    paddingTop: '20px',
    marginTop: '30px',
    marginBottom: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '20px',
      marginBottom: '10px',
     },
    '& button': {
      borderRadius: '15px 15px 0 0',
      border: '1px solid #b7b7b7',
      borderBottom: 'none',
      fontSize: '14px',
      color: '#0069aa',
      textTransform: 'inherit',
      minHeight: 'inherit',
      minWidth: '180px',
      padding: '0 16px',
      height: '40px',
      margin: '0 20px 0 0',
      lineHeight: '40px',
      cursor: 'pointer',
      display: 'inline-block',
      textAlign: 'center',
      '@media (max-width: 992px)': {
        fontSize: '13px',
        padding: '0 8px',
        minWidth: 'auto',
        marginRight: '10px',       
       },
      '@media (max-width: 768px)': {
        fontSize: '11px',
        padding: '0 7px',
        minWidth: 'auto',
        marginRight: '10px',       
       },
      '@media (max-width: 700px)': {
        fontSize: '10.5px',
        padding: '0 6px',
        minWidth: 'auto',
        marginRight: '5px',
       },
       '@media (max-width: 650px)': {
        fontSize: '10px',       
       },
      [theme.breakpoints.down( 'xs' )]: {
       borderRadius: '0px',
       minWidth: '100%',
       fontSize: '12px',
      },
      '& span': {
        border: 'none !important',
      },
    },
    '& > div > div': {
      borderBottom: '1px solid #000',
      display: 'inherit',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'block',
        whiteSpace:'pre-wrap',
        borderBottomColor: '#b7b7b7',
      },
    },
    '& > div > div + span': {
      display: 'none',
    },

  },
  tabsContent: {
    '& img': {
      maxWidth: '100%',
    },
    '& > div > div > div > div': {
      paddingTop: '0px',
    },
  },
  activeTab: {
    position: 'relative',
    top: '1px',
    background: '#fff',
    borderColor: '#000 !important',
  },
  graphImage: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  bottomText: {
    color: '#0069aa',
    fontStyle: 'italic',
    padding: '30px 0',
    borderTop: '1px solid #d5d5d5',
    textAlign: 'left',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '15px 0px',
    },
    '& p': {
      margin: '0 0 10px',
      color: '#0069aa',
      fontSize: '14px',
    },
  },
  galleryImage: {
    margin: '30px 0',
    '& img': {
      width: 'auto',
      verticalAlign: 'middle',
      display: 'block',
      margin: '0 auto',
      [theme.breakpoints.down( 'md' )]: {
        width: '100%',
      },
    },
  },
  centerText: {
    justifyContent: 'center',
  },
  quickLinksText: {
    '& a': {
      display: 'block',
      textDecoration: 'none !important',
      '&:hover': {
        color: '#23527c !important',
        textdecoration: 'underline !important',
      },
    },
  },
  activeFi: {
    borderTop: '2px solid #1c6645',
    paddingTop: '2px',
  },

  tooltipInfoTop: {
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '300',
    lineHeight: '1.42857143',
    borderRadius: '4px',
    '&::after': {
      opacity: '0.9 !important',
      borderTopColor: '#0069aa !important',
      borderLeftWidth: '6px !important',
      borderRightWidth: '6px !important',
    },
  },
  leftTooltip: {
    '&:after': {
      borderTopColor: 'transparent !important',
      borderLeftColor: '#0069aa !important',
    },
  },
  panelInfo: {
    marginBottom: '20px',
    backgroundColor: '#fff',
    border: '1px solid #0069aa',
    borderRadius: '4px',
    WebkitBoxShadow: '6px 10px 10px #dfdfdf',
    boxShadow: '6px 10px 10px #dfdfdf',
    display: 'block',
  }, 
  panelHeading: {
    padding: '10px 15px',
    borderBottom: '1px solid transparent',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderColor: '#bce8f1',
    backgroundColor: '#b2d2e5',
    color: '#31708f',
  },
  rowBlock: {
    lineHeight: '18px',
    margin: '0 -15px',
  },
  notesBlock: {
    padding: '0 70px',
    display: 'flex',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 10px',
    },
  },
  scenarioNotes: {
    padding: '0px 15px',
    display: 'flex',
  },
  notesLeft: {
    display: 'inline-block',
    float: 'left',
    padding: '0',
    width: '50%',
    '& ul': {
      display: 'inline-block',
      verticalAlign: 'top',
      padding: '0',
      margin: '0',
      '& li': {
        textAlign: 'center',
        margin: '0',
        display: 'inline-block',
        width: '100%',
        '& span': {
          color: '#000',
          fontSize: '20px',
          lineHeight: '20px',
          textTransform: 'capitalize',
          fontWeight: '700',
        },
      },
    },
  },
  listUnstyled: {
    paddingLeft: '0',
    listStyle: 'none',
  },
  notesRight: {
    display: 'inline-block',
    padding: '0',
    float: 'right',
    width: '50%',
    '& ul': {
      textAlign: 'center',
      margin: '0',
      display: 'flex',
      width: '100%',
      paddingLeft: '0',
      listStyle: 'none',
      justifyContent: 'flex-end',
      '& li': {
        display: 'inline-block',
        margin: '0 0px 0px 10px',
        verticalAlign: 'top',
        lineHeight: '18px',
      },
    },
  },
  scenarioNotesRight: {
    padding: '0px 13px 0px 0px',
  },
  btnWarning: {
    padding: '7px 10px',
    borderRadius: '4px',
    border: 'none',
    transition: 'all .5s ease 0s',
    color: '#fff',
    textTransform: 'uppercase',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    minWidth: 'auto',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '12px',
    },
  },
  loadButton: {
    background: '#84a84d',
    '&:hover': {
      background: '#82a251',
    },
  },
  saveButton: {
    backgroundColor: '#337ab7',
    '&:hover': {
      backgroundColor: '#286090',
    },
  },
  notesRow: {
    display: 'flex',
  },
  notesEditor: {
    backgroundColor: '#f4f4f4',
    padding: '0 70px',
    border: '0',
    outline: '0',
    margin: '0',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 10px',
    },
  },
  notesEditorRow: {
    backgroundColor: '#fff!important',
    paddingTop: '2px',
  },
  textRow: {
    width: '100%',
    padding: '0 15px',
    boxSizing: 'border-box',
    '& textarea': {
      resize: 'none',
      width: '100%',
      height: '300px',
      fontSize: '20px',
      border: 'none',
      outline: 'none',
      color: '#333',
      fontFamily: 'MuseoSans-300',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '15px',
      },
      '&::placeholder': {
        color: '#333 !important',
      },
    },
  },
  noBottomSpace: {
    marginBottom: '0px',
  },
  cashFlowDiv: {
    padding: '10px 0',
    border: '1px solid #0069aa',
    margin: '0 0 15px',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'nowrap',
      margin: '0 -5px',
    },
  },
  whiteLeftBox: {
    backgroundColor: 'transparent',
    border: '0',
    display: 'block',
    fontSize: '14px',
    padding: '0 9px',
    height: '40px',
    verticalAlign: 'middle',
    color: '#898d8f',
    lineHeight: '1',
    float: 'left',
    width: '100%',
  },
  cashFlowRight: {
    height: '40px',
    '&  > div': {
      width: '70%',
      fontSize: '12px',
      border: '0',
      boxShadow: 'none',
      boxSizing: 'border-box',
      display: 'inline-block',
      '& span': {
        backgroundColor: '#73ab43',
        color: '#fff',
        width: '100%',
        fontWeight: '600',
        height: '40px',
        lineHeight: '20px',
        borderRadius: '0',
        paddingLeft: '15px',
        float: 'left',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  cashFlowRed: {
    height: '40px',
    '&  > div': {
      width: '70%',
      fontSize: '12px',
      border: '0',
      boxShadow: 'none',
      boxSizing: 'border-box',
      display: 'inline-block',
      '@media (max-width: 600px)': {
        fontSize: '11px',
      },
      '& span': {
        backgroundColor: '#bf0000',
        color: '#fff',
        width: '100%',
        fontWeight: '600',
        height: '40px',
        lineHeight: '20px',
        borderRadius: '0',
        paddingLeft: '15px',
        float: 'left',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  cashFlowGreen: {
    height: '40px',
    [theme.breakpoints.down( 'xs' )]: {
      marginRight: '5px',
    },
    '&  > div': {
      width: '70%',
      fontSize: '12px',
      border: '0',
      boxShadow: 'none',
      boxSizing: 'border-box',
      display: 'inline-block',
      '@media (max-width: 600px)': {
        fontSize: '11px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',      
      },
      '& span': {
        backgroundColor: '#73ab43',
        color: '#fff',
        width: '100%',
        fontWeight: 'normal',
        fontFamily: 'MuseoSans-700',
        height: '40px',
        lineHeight: '20px',
        borderRadius: '0',
        paddingLeft: '15px',
        float: 'left',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down( 'xs' )]: {
          justifyContent: 'center',
          paddingLeft: '0px',
        },
      },
    },
  },
  currencyTextNew: {
    fontSize: '14px',
    color: '#333',
    display: 'table-cell',
    height: '38px',
    verticalAlign: 'middle',
    '& strong': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '11px',
    },
  },
  scenarioBlock: {
    padding: '25px 0px',
    '& h6': {
      color: '#0069aa',
      fontSize: '25px',
      marginBottom: '15px',
      marginTop: '10px',
      fontWeight: '500',
      lineHeight: '1.1',
      textAlign: 'center',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
    },
    '& select': {
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#555',
      display: 'block',
      height: '34px',
      margin: '0 auto 5px',
      width: '90%',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      padding: '6px 12px',
      backgroundImage: 'none',
      boxSizing: 'border-box',
      outline: 'none',
      paddingLeft: '8px',
      cursor: 'pointer',
      fontFamily: 'MuseoSans-300',
      [theme.breakpoints.down( 'xs' )]: {
        margin: '0px',
        width: '100%',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '12px',
        width: '100%',
       },
      '@media (max-width: 420px)': {
        fontSize: '12px',
      },
      '&:focus': {
        borderColor: '#66afe9',
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
    },
  },
  formMainRow: {
    marginLeft: '-15px',
    marginRight: '-15px',
    position: 'relative',
    top: '-5px',
    [theme.breakpoints.down( 'xs' )]: {
      marginLeft: '-10px',
      marginRight: '-10px',
    },
    '& > div': {
      flexWrap: 'nowrap',
    },
  },
  leftSection: {
    paddingLeft: '15px',
    paddingRight: '0px',
  },
  gridPadding: {
    padding: '0 15px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  scenarioPopup: {
    position: 'relative',
    '& small': {
      border: '1px solid #ccc',
      borderRadius: '4px',
      height: '32px',
      lineHeight: '28px',
      fontSize: '14px',
      backgroundSize: '8px',
      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '99',
      padding: '0 27px 0 15px',
      width: '100%',
      display: 'block',
      overflow: 'hidden',
      wordBreak: 'break-all',
      backgroundPosition: 'right 8px center',
      backgroundRepeat: 'no-repeat',
      fontFamily: 'MuseoSans-300',
      color: '#000',
      boxSizing: 'border-box',
      backgroundImage: `url(${selectDrop})`,
      [theme.breakpoints.down( 'sm' )]: {
       fontSize: '12px',
      },
      '@media (max-width: 420px)': {
        fontSize: '12px',
      },
    },
  },
  heSlider: {
    background: 'linear-gradient(to bottom,#84aa47 0,#84aa47 100%)',
  },
  mheSlider: {
    background: 'linear-gradient(to bottom,#5c9c6d 0,#5c9c6d 100%)',
  },
  activeHe: {
    borderTop: '2px solid #86A940',
    paddingTop: '2px',
  },
  heImage: {
    margin: '30px auto',
    '& img': {
      margin: '0 auto',
      display: 'block',
      cursor: 'pointer',
      [theme.breakpoints.down( 'md' )]: {
        width: '100%',
      },
    },
  }, 
  yearRepay: {
    margin: '30px auto',
    '& img': {
      margin: '0 auto',
      display: 'block',
      width: '100%',
      cursor: 'pointer',
    },
  },
  graphTitle: {
    textAlign: 'center',
  },
  higherImage: {
    marginTop: '20px',
    '& img': {
      margin: '0 auto',
      display: 'block',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
        marginBottom: '30px',
      },
    },
  },
  paybackRow: {
    padding: '0 25px',
    marginTop: '-20px',
    '& p': {
      fontSize: '14px',
      margin: '0 0 10px',
    },
  },
  orderPoints: {
    margin: '15px 0px 0px',
    '& span': {
      display: 'block',
      fontSize: '18px',
      color: '#000',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '14px',
      },
    },
  },
  noTopmargin: {
    marginTop: '0px !important',
  },
  fullWidth: {
    '& img': {
      width: '100%',
    },
  },
  noLink: {
    color: '#337ab7',
    textDecoration: 'none !important',
    '&:hover': {
      color: '#337ab7 !important',
      textDecoration: 'underline !important',
    },
  },
  moduleTitle: {
    '& h3': {
      margin: '20px 0px 10px 0px',
      fontSize: '16px',
      lineHeight: '23px',
      color: '#0069aa',
      fontFamily: 'MuseoSans-300',
      fontWeight: '500',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
        whiteSpace: 'nowrap',
        fontSize: '14px',
        margin: '15px 0px 5px 0px',
        lineHeight: '15px',
        paddingLeft: '15px',
      }, 
      [theme.breakpoints.down( 'xs' )]: {       
        whiteSpace: 'pre-wrap',       
      },         
    },
  },
  moduleTitleNew: {
    padding: '0 15px',
    '@media (max-width: 420px)': {
      padding: '0 10px',
    }, 
    '& h3': {
      margin: '20px 0px',
      fontSize: '16px',
      lineHeight: '23px',
      color: '#0069aa',
      fontFamily: 'MuseoSans-300',
      fontWeight: '500',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',      
        margin: '0px 0px 5px 0px',
        fontSize: '14px',
        lineHeight: '16px',
      },
    },
  },
  insideSpan: {
    display: 'block',
    fontSize: '14px',
    padding: '0 9px',
    height: '40px',
    verticalAlign: 'middle',
    backgroundColor: '#e7e7e7',
    border: '1px solid #898d8f',
    color: '#898d8f',
    lineHeight: '1',
    float: 'left',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {   
      marginBottom: '5px',
    }, 
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },   
  },
  insideSpanTextAuto:{
    height: 'auto',
  },
  blueColorRow: {
    background: '#e6f2f8',
    '& > div': {
      color: '#333',
    },
  },
  spanText: {
    display: 'table-cell',
    height: '38px',
    verticalAlign: 'middle',
    fontSize: '13px',
    fontFamily: 'MuseoSans-300',  
    '@media (max-width: 600px)': {
      fontSize: '11px',
      // display: '-webkit-box',
      // WebkitLineClamp: '3',
      // WebkitBoxOrient: 'vertical',
      // overflow: 'hidden',
      // height: '34px',
    },   
    '& strong': {
      fontFamily: 'MuseoSans-500',
      color: ' #333',
      fontWeight: 'normal',
    },
  },
  spanTextSmall:{
    fontSize: '11px',
  },
  noSpace: {
    paddingRight: '0px !important',
    paddingLeft: '0px !important',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px !important',
      width: '50%',
    },
  },
  noLeftSpace: {
    paddingRight: '15px !important',
    paddingLeft: '0px !important',
  },
  noRightSpace: {
    paddingRight: '0px !important',
    paddingLeft: '15px !important',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px !important',
      width: '50%',
    },
  },
  calcBlock: {
    background: '#eee',
    padding: '0 15px',
    [theme.breakpoints.down( 'xs' )]: {    
      width: '25%',
    },
  },
  labelText: {
    position: 'relative',
    paddingRight: '15px',
    paddingLeft: '15px',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {    
      paddingLeft: '0px',
      paddingRight: '3px',
    },
  },
  labelTextAnual:{
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    fontWeight: '600',
    color: '#000',
    position: 'relative',
    top: '-5px',
    paddingLeft: '0px',
  },
  calcText: {
    lineHeight: '36px',
    height: 'auto',
    paddingLeft: '10px',
    fontSize: '13px',
    marginBottom: '0',
    paddingTop: '4px',
    verticalAlign: 'top',
    display: 'inline-block',
    maxWidth: '100%',
    fontWeight: '700',
    color: '#000',
    textAlign: 'center',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },
  },
  calcTextNew: {
    height: '40px',
    // lineHeight: '60px',
    paddingLeft: '10px',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'self-end',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '0px',
      whiteSpace: 'pre-wrap',
    },
  },
  calcTextNew2:{
    position: 'relative',
    top: '10px',
  },
  insideSpanText: {
    background: 'transparent',
    border: '0',
  },
  insideInputText: {
    border: '0',
    boxShadow: 'none',
    paddingLeft: '9px',
  },
  calculatedValue: {
    marginBottom: '15px',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '10px',
    },   
    '& div': {
      height: 'auto',
      paddingTop: '0 !important',
      margin: '0',
      paddingBottom: '0px !important',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      color: '#333',
      // '@media (max-width: 1400px)': {
      //   flexWrap: 'wrap',
      // },  
    },
  }, 
  noInputText: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
    paddingLeft: '9px',
    paddingRight: '3px',
    '@media (max-width: 600px)': {
      fontSize: '11px',
      paddingRight: '5px',
    },  
    '@media (max-width: 768px)': {     
      paddingRight: '1px',
    },    
  },
  noPadding: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  noMargin: {
    margin: '0px !important',
  },
  horizontalSmall: {
    width: '80%',
    borderBottom: '2px solid #0069aa',
    margin: '0px 0 10px',
    boxSizing: 'border-box',
  },
  grayBgDiv: {
    width: '40%',
    background: '#eee',
  },
  smallInput: {
    display: 'flex',
    alignItems: 'center',
    '& input': {
      width: '100%',
    },
  },
  analysisPortion: {
    border: '1px solid #0069aa',
    backgroundColor: '#e5f0f6',
    margin: '10px 0',
    width: '100%',
    boxSizing: 'border-box',
  },
  analysisHeading: {
    backgroundColor: '#0069aa',
    display: 'inline-block',
    width: '100%',
    marginBottom: '15px',
    '& h3': {
      fontSize: '16px',
      lineHeight: '23px',
      marginBottom: '20px',
      color: '#fff',
      fontWeight: 'normal',
      fontFamily: 'MuseoSans-700',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '15px',   
        margin: '10px 0px',    
      },
      '@media (max-width: 400px)': {
        margin: '10px 0px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '12px',
        lineHeight: '15px',        
      },
    },
  },
  analysisBlock: {
    padding: '0px 15px',
  },
  analysisSection: {
    padding: '0 15px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0 10px',
    },
  },
  independenceGroup: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  horizontalLine: {
    margin: '0 0 10px',
    borderBottom: '2px solid #0069AA',
    boxSizing: 'border-box',
  },
  inputBlock: {
    padding: '0 15px',
    marginBottom: '15px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0 10px',
      marginBottom: '5px',
    },
  },
  inputBlockSpacing:{
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px -16px',
    }
  },
  moduleRow: {
    margin: '0px -15px 0px -15px',   
  },
  noBottomMargin: {
    marginBottom: '0px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
  },
  emptyBlock: {
    padding: '0 15px',
  },
  spanBlueText: {
    maxWidth: '70%',
    background: '#0069aa',
    color: '#fff !important',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    lineHeight: '34px',
    paddingBottom: '3px',
    paddingTop: '3px',
    top: '0',
    boxSizing: 'border-box',
    paddingLeft: '9px',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },   
  },
  spanGreenText: {
    maxWidth: '70%',
    background: '#73ab43',
    color: '#fff',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '40px !important',
    lineHeight: '34px',
    paddingBottom: '3px',
    paddingTop: '3px',
    top: '0',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '50%',
      marginLeft: '10px !important',
      marginTop: '5px !Important',
    },
    '& span': {
      color: '#fff',
    },
  },
  spanGreenBtn: {
    [theme.breakpoints.down( 'xs' )]: {
      maxWidth: '100%',    
    },
  },
  showGraphContent: {
    border: '1px solid #2f75b5',
    margin: '0px 0',
    padding: '10px 0px',
  },
  leftPanel: {
    background: '#f2f2f2',
    display: 'block',
    padding: '0 14px',
    margin: '0 10px',
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      '& tbody': {
        '& tr': {
          '& td': {
            fontSize: '16px',
            padding: '0',
            color: '#000',
            lineHeight: '1.42857143',
            '& h3': {
              fontSize: '15px',
              marginTop: '28px',
              marginBottom: '0',
              color: '#000',
              fontWeight: '600',
            },
            '& valueField': {
              padding: '5px !important',
            },
          },
        },
      },
    },
  },
  blueLine: {
    margin: '10px 0',
    paddingLeft: '10px',
    borderTop: '2px solid #2f75b5',
  },
  heightField: {
    height: '10px',
  },
  inputsTitle: {
    marginBottom: '0',
    color: '#000',
    fontWeight: '600',
    fontSize: '22px',
    marginTop: '30px',
  },
  whiteInputField: {
    background: '#fff',
    position: 'relative',
    padding: '5px 5px 5px 5px !important',
    fontFamily: 'MuseoSans-500',
    fontWeight: 'normal',
    '& span': {
      fontFamily: 'MuseoSans-700',
    },
    '& div': {
      width: '100%',
      border: 'none',
      '& input': {
        fontFamily: 'MuseoSans-500',
        fontSize: '16px',
        border: 'none',
      },
      '&::before': {
        border: 'none',
      },
      '&::after': {
        border: 'none',
      },
      '&:hover': {
        '&::before': {
          border: 'none !important',
        },
        '&::after': {
          border: 'none !important',
        },
      },
    },
  },
  infoIconNew: {
    margin: '0px 1px',
    textAlign: 'center',
    display: 'inline-block',
    width: '25px',
    position: 'relative',
    '& img': {
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      border: '0',
      verticalAlign: 'middle',
    },
  },
  percentSymbolNew: {
    fontSize: '13px',
    right: '6px',
    textAlign: 'right',
    color: '#333',
    marginTop: '-1px',
    left: '75%',
    float: 'right',
    paddingLeft: '5px',
    position: 'absolute',
    top: '11px',
  },
  dollarSymbol: {
    fontSize: '13px',
    marginTop: '-2px',
    color: '#333',
    float: 'right',
    left: '0',
    paddingLeft: '5px',
    position: 'absolute',
    top: '11px',
    [theme.breakpoints.down( 'xs' )]: {
      top: '10px',
    },
  },
  inputsData: {
    padding: '0 6px 6px 0 !important',
  },
  yearText: {
    paddingTop: '7px',
    marginBottom: '0',
    textAlign: 'right',
    fontSize: '16px',
    fontFamily: 'MuseoSans-300',
    color: '#000',
  },
  yearBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  expensesText: {
    color: '#333',
    fontWeight: '400',
    textAlign: 'center',
  },
  expensesLabel: {
    textAlign: 'center',
  },
  showGraph: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  checkboxBlock: {
    padding: '0px 0px 0px 18px',
    position: 'relative',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 0px 0px 6px',
    },
  },
  radioGroup: {
    fontSize: '16px',
    fontWeight: '400',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    '& label': {
      margin: '2px 0px',
    },
  },
  radioGroupFull:{
    display: 'block',
    '& > div':{
      margin: '0px',
    }
  },
  checkInfoIcon: {
    position: 'absolute',
    right: '11px',
    top: '0',
    '@media (max-width: 460px)': {
      right: '8px',
      left: '100%',
      marginLeft: '8px',
    },
  },
  activeHover: {
    background: '#0069aa',
    '& span': {
      display: 'flex',
      color: '#fff',
      '&:hover': {
        '&:hideText': {
          display: 'block !important',
          fontSize: '22px',
          opacity: '1',
          visibility: 'visible',
          verticalAlign: 'middle',
          width: '100%',
        },
      },
    },
  },
  activeShow: {
    display: 'table-cell !important',
    fontSize: '22px',
    opacity: '1',
    visibility: 'visible',
    verticalAlign: 'middle',
    width: '100%',
    color: '#fff',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '19px',
      lineHeight: '1.4',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '1.4',
    },
  },
  activeHide: {
    display: 'none !important',
  },
  // pendinglistPopup: {
  //   padding: '0px',
  //   paddingTop: '0px',
  //   boxShadow: 'none',
  //   '& div': {
  //     maxWidth: '100%',
  //     padding: '0px',
  //     paddingTop: '0px',
  //     boxShadow: 'none',
  //     overflowY: 'inherit',
  //   },
  //   '& > div > div': {
  //     background: 'transparent',
  //     boxShadow: 'none',
  //     overflow: 'inherit',
  //   },
  // },
  pendinglistPopup: {
    '& > div > div': {
      maxWidth: '100%',
      '& > div': {
        padding: '0 !important',
      },
      '& span':{
        top: '0px',
      }
    },
  },
  instructionsPopup: {
    width: '600px',
    padding: '0px !important',
    maxWidth: '100%',
    boxSizing: 'border-box',
    margin: '0 auto',
  },
  iframe: {
    border: '0px',
  },
  videoPopup: {
    paddingTop: '0px',
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  popupClose: {
    top: '10px',
    background: '#fff',
    borderColor: '#fff',
    color: '#000',
    width: '26px',
    right: '10px',
    height: '26px',
    fontSize: '16px',
    lineHeight: '26px',
    outline: 'none',
    zIndex: '99',
    float: 'none',
    position: 'absolute',
    opacity: '1',
    border: '1px solid #f59331',
    textShadow: 'none',
    borderRadius: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  SavePopup: {
    '& > div > div': {
      maxWidth: '100%',
      width: '100%',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      [theme.breakpoints.down( 'sm' )]: {
        margin: '15px',
        maxHeight: 'calc(100% - 30px)',
      },
    },
  },
  clearHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '21px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  clearFooter: {
    padding: '15px',
    borderTop: '1px solid #e5e5e5',
    textAlign: 'right',
  },
  clearContent: {
    padding: '15px',
    '& p': {
      margin: '0px',
      fontSize: '15px',
    },
  },
  buttonOne: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    color: '#fff',
    padding: '8px 14px',
    lineHeight: '17px',
    fontSize: '17px',
    textTransform: 'capitalize',
    borderRadius: '4px',
    minWidth: '64px',
    height: 'auto',
    borderWidth: '2px',
    display: 'inline-block',
    marginBottom: '0',
    fontWeight: '400',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#286090',
      borderColor: '#204d74',
      color: '#fff',
    },
  },
  familySlider: {
    background: 'linear-gradient(to bottom,#4599ad 0,#4599ad 100%)',
  },
  vacationSlider: {
    background: 'linear-gradient(to bottom,#b24500 0,#b24500 100%)',
  },
  investmentSlider: {
    background: 'linear-gradient(to bottom,#c6a229 0,#c6a229 100%)',
  },
  existingSlider: {
    background: 'linear-gradient(to bottom,#cab488 0,#cab488 100%)',
  },
  buyACarSlider: {
    background: 'linear-gradient(to bottom,#e89200 0,#e89200 100%)',
  },
  reiSlider: {
    background: 'linear-gradient(to bottom,#0069aa 0,#0069aa 100%)',
  },
  buttonGreen: {
    backgroundColor: '#84a84d',
    fontFamily: 'MuseoSans-300 !important',
    padding: '12px 22px',
    lineHeight: '1.42857143',
    '&:hover': {
      backgroundColor: '#759840',
    },
  },
  buttonOrange: {
    backgroundColor: '#F7941E',
    fontFamily: 'MuseoSans-300',
    padding: '11px 22px',
    '&:hover': {
      backgroundColor: '#F7941E',
    },
  },
  selectBox: {
    minHeight: 'auto',
    height: '40px',
    display: 'flex',
    '& input': {
      height: 'auto',
      color: '#0069aa !important',
      width: '0px !important',
      '& focus': {
        '& ::placeholder': {
          color: 'transparent !important',
        },
      },
    },
  },
  selectBlock: {
    width: '85%',
    '& > div > div': {
      borderColor: '#898d8f',
      borderLeft: '0',
      width: '100%',
      boxShadow: 'none',
      borderRadius: '0',
      cursor: 'pointer',
      fontSize: '13px',
      height: '40px',
      '@media (max-width: 600px)': {
        fontSize: '11px',
      },    
      '&:hover': {
        borderColor: '#898d8f',
      },
      '&:nth-child(3)':{
        margin: '0px',
        '& div':{
          padding: '3px 10px',
          fontSize: '14px',
        }
      }
    },
    '& > div > div:nth-child(1) > div:nth-child(1)': {
      paddingLeft: '15px',
      height: 'inherit',
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '5px',
      },     
    },
    '& > div div:nth-child(1) > div:nth-child(1)': {
      paddingLeft: '10px',
      height: 'inherit',
      lineHeight: '40px',
      [theme.breakpoints.down( 'xs' )]: {
        paddingLeft: '5px',
      },     
    },
    '& > div > div > div > div': {
      padding: '0px',
      margin: '0px',
    },
    '& > div > div:first-child': {
      '& > div > div:first-child': {
        color: '#0069aa',
        font: 'inherit',
        fontSize: '13px',
        fontWeight: '600',
        '@media (max-width: 600px)': {
          fontSize: '11px',
        },    
        '&:focus': {
          color: '#0069aa',
          font: 'inherit',
          fontSize: '13px',
          fontWeight: '600',
          '@media (max-width: 600px)': {
            fontSize: '11px',
          }, 
        },
      },
    },
    '& div': {
      // margin: '0px',
      // padding: '3px 0px',
      // fontSize: '14px',
      '&:hover': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
  selectBlockLayer:{
    zIndex: '11', 
  },
  activeFamily: {
    borderTop: '2px solid #3299AC',
    paddingTop: '2px',
  },
  optionsBlock: {
    width: '65%',
    '& > div > div':{
      '&:nth-child(3)':{
        margin: '0px',
        '& div':{
          padding: '3px 10px',
          fontSize: '14px',
        }
      }
    },
  },
  rightText: {
    display: 'inline-block',
    fontSize: '14px',
    paddingTop: '0px',
    marginBottom: '0',
    textAlign: 'right',
    color: '#000',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },
  },
  inputEmpty: {
    paddingLeft: '9px !important',
  },
  leftBorder: {
    borderLeft: '1px solid #898d8f',
    '& input': {
      textAlign: 'center',
      padding: '0 8px !important',
    },
  },
  leftBluepadding: {
    paddingLeft: '10px',
  },
  Bluepadding: {
    paddingLeft: '20px',
  },
  editButton: {
    backgroundColor: 'orange',
    borderRadius: '4px',
    border: 'none',
    padding: '4px',
    marginLeft: '0',
    fontSize: '14px',
    fontFamily: 'MuseoSans-100',
    fontWeight: '400',
    display: 'inline-block',
    height: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    color: '#fff',
    textTransform: 'uppercase',
    width: 'auto',
    minWidth: 'auto',
    lineHeight: '1.42857143',
    '&:hover': {
      backgroundColor: 'orange',
    },
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  autoCursor:{
    cursor: 'auto !important',
  },
  disabledInput: {
    '& > div:first-child': {
      backgroundColor: '#eee',
      opacity: '1',
    },
    // '& input': {
    //   backgroundColor: '#eee',
    // },
  },
  modalHeader: {
    position: 'relative',
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    boxSizing: 'border-box',
    '& h3': {
      fontSize: '16px',
      lineHeight: '23px',
      marginBottom: '20px',
      color: '#0069aa',
      fontWeight: '500',
      marginTop: '20px',
    },
  },
  editPopup: {
    '& > div > div': {
      maxWidth: '55%',
      width: '55%',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '65%',
        width: '65%',       
      },
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
        margin:'32px 15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  modalBody: {
    maxHeight: '407px',
    overflowY: 'auto',
    position: 'relative',
    padding: '15px',
    boxSizing: 'border-box',
    '& formInput': {
      width: '100%',
    },
  },
  modalFooter: {
    borderTop: '1px solid #e5e5e5',
    position: 'relative',
    padding: '15px',
    textAlign: 'right',
    boxSizing: 'border-box',
    '& h3': {
      color: '#0069aa',
      fontSize: '14px',
      display: 'inline-block',
    },
  },
  formInputCount:{
    fontSize: '16px',
    color: '#0069aa',
    fontFamily: '"MuseoSans-700"',
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '6px 12px',
    textTransform: 'uppercase',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '10px',
    },
  },
  resetButton: {
    color: '#fff',
    backgroundColor: '#d58512',
    borderColor: '#985f0d',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
    },
  },
  okButton: {
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    margin: '0 5px',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#286090',
      borderColor: '#204d74',
    },
  },
  cancelButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    '@media (max-width: 320px)': {
      marginTop: '10px',
    },
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  fullInput: {
    '& div': {
      width: '100%',
    },
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  showWorksheet: {
    backgroundColor: '#f0ad4e',
    borderRadius: '4px',
    border: '1px solid #eea236',
    padding: '6px 12px',
    marginLeft: '0',
    fontSize: '14px',
    fontFamily: 'MuseoSans-100',
    fontWeight: '400',
    display: 'inline-block',
    height: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    color: '#fff',
    textTransform: 'uppercase',
    width: 'auto',
    minWidth: 'auto',
    lineHeight: '1.42857143',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '10px',
      marginBottom: '10px',
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: '#ec971f',
      borderColor: '#d58512',
    },
  },
  headerButton: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    },
    '& h3': {
      width: 'calc(89% - 120px)',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',
      },
    },
  },
  maxModule: {
    '& > div': {
      alignItems: 'baseline',
    },
    '& > div > div ': {
      width: '80%',
      maxWidth: '100%',
      verticalAlign: 'top',
      maxHeight: '100%',
      overflow: 'auto',
      margin: '30px auto',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  maxModalBody: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  maxHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      color: '#0069aa',
      fontSize: '18px',
      margin: '0px',
      fontWeight: '500',
      fontFamily: 'MuseoSans-500',
    },
  },
  modalClose: {
    textAlign: 'right',
    lineHeight: '1',
    fontSize: '20px',
    zIndex: '9',
    cursor: 'pointer',
    '&:hover': {
      opacity: '.5',
    },

  },
  maxClose: {
    fontSize: '21px',
    lineHeight: '1',
    color: '#000',
    textShadow: '0 1px 0 #fff',
    opacity: '.5',
    fontWeight: '700',
    top: '15px',
  },
  redoPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  buttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#0069aa',
        borderColor: '#0069aa',
        color: '#fff',
        marginRight: '6px',
      },
      '&:last-child': {
        color: '#333',
        '&:hover': {
          backgroundColor: '#e6e6e6',
        },
      },
    },
  },
  backButton: {
    backgroundColor: '#fff !important',
    border: '2px solid #d5d5d5 !important',
    color: '#333 !important',
    '&:hover': {
      backgroundColor: ' #d5d5d5 !important',
    },
  },
  NoFiDiv: {
    padding: '15px 0 5px',
    display: 'flex',
    '& p': {
      padding: '0 0 0 24px',
      marginTop: 0,
      fontSize: '16px',
    },
    '& span': {
      paddingTop: '6px',
    },
  },
  closingCosts: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '100%',
    '& tbody': {
      '& tr': {
        background: '#F2F2F2',
        borderBottom: '2px solid #fff',
        height: '43px',
        '& td': {
          fontSize: '16px',
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          borderTop: '1px solid #ddd',
          position: 'relative',
          color: '#000',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '14px',
          },
          '&:nth-child(2)': {
            textAlign: 'right',
            verticalAlign: 'bottom',
          },
          '& input': {
            width: '85px',
            border: 'none',
            outline: '0',
            background: '#f2f2f2',
            borderBottom: '1px solid #0069aa',
            textAlign: 'right',
            boxSizing: 'border-box',
            height: '25px',
            fontSize: '16px',
            color: '#000',
            '&:focus': {
              borderBottom: '1px solid #f89b22 !important',
            },
          },
          '& div': {
            '&::before': {
              border: 'none !important',
            },
            '&::after': {
              border: 'none !important',
            },
          },
        },
      },
    },
    '& tfoot': {
      '& tr': {
        background: '#dae3f3',
        fontWeight: '700',
        fontSize: '16px',
        borderBottom: '2px solid #4472c4',
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '14px',
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          borderTop: '1px solid #ddd',
          '&:nth-child(2)': {
            textAlign: 'right',
            verticalAlign: 'bottom',
          },
        },
      },
    },
  },
  closingCostsModal: {
    '& > div > div': {
      maxWidth: '600px',
      width: '600px',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: '100%',
      [theme.breakpoints.down( 'xs' )]: {
        marginLeft: '15px',
        marginRight: '15px',
       },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
    '& h2': {
      fontSize: '22px',
      margin: '0px',
      color: '#0069aa',
      fontWeight: '500',
    },
  },
  homeSlider: {
    background: 'linear-gradient(to bottom,#de6d03 0,#de6d03 100%)',
  },
  summaryListMenu: {
    position: 'absolute',
    left: 'auto',
    zIndex: '999!important',
    top: 'auto',
    bottom: '100%',
    marginBottom: '8px',
    display: 'block',
    minWidth: '160px',
    padding: '5px 0',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ccc',
    borderRadius: '4px',
    WebkitBoxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxShadow: '0 6px 12px rgba(0,0,0,.175)',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      width: '200px',
    },
    '& li': {
      '& p': {
        padding: '4px 20px',
        width: '100%',
        textAlign: 'left',
        background: '0 0',
        border: 'none',
        outline: '0',
        clear: 'both',
        fontWeight: '400',
        color: '#333',
        boxSizing: 'border-box',
        display: 'block',
        height: 'auto',
        margin: '0px',
        cursor: 'pointer',
        '&:hover': {
          color: '#262626',
          textDecoration: 'none',
          backgroundColor: '#f5f5f5',
        },
      },
    },
    '&::before': {
      top: '100%',
      borderTop: '11px solid #fff',
      left: '44%',
      borderBottom: '11px solid transparent',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      content: '""',
      height: '0',
      position: 'absolute',
      width: '0',
    },
  },
  logoPic: {
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  downloadPdf: {
    borderRadius: '4px',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    transition: 'all .5s ease 0s',
    background: '#efefef',
    textTransform: 'none',
    padding: '6px 12px',
    [theme.breakpoints.down( 'xs' )]: {
     margin: '10px',
     },
    '&:hover': {
      color: '#333',
      textDecoration: 'none',
      backgroundImage: 'none',
      outline: '0',
      background: '#efefef',
    },
  },
  incomeStatementPopup: {
    padding: '10px',
    '& > div > div': {
      maxWidth: '98.5%',
      width: '98.5%',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: '100%',
      transform: 'translate(0,0)',
      overflow: 'hidden',
      margin: 'auto',
      boxSizing: 'border-box',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  popupTable: {
    maxHeight: '450px',
    height: '100%',
    overflow: 'auto',
    paddingRight: '17px',
    boxSizing: 'border-box',
  },
  topIncomeBg: {
    color: 'white',
    background: 'rgb(0, 51, 86)',
    height: '28px',
  },
  incomeSheetTable: {
    borderCollapse: 'collapse',
    borderSpacing: '0px',
    margin: '0',
    outline: 'none',
    color: '#333333',
    width: '100%',
    fontFamily: 'MuseoSans-300',
    '& thead': {
      '& tr': {
        '& th': {
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
          fontWeight: 'normal',
          lineHeight: '1.42857143',
          '&:nth-child(1)': {
            padding: '3px',
            width: '382px',
            textAlign: 'left',
          },
          '&:nth-child(2)': {
            width: '115px',
            textAlign: 'right',
          },
        },
      },
    },
    '& tbody': {
      '& tr': {
        border: '1px solid #dddddd',
        '& td': {
          verticalAlign: 'bottom',
          borderBottom: '1px solid #DDDAD6',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          cursor: 'pointer',
          textAlign: 'right',
          borderCollapse: 'collapse',
          lineHeight: '1.42857143',
          '&:nth-child(1)': {
            textAlign: 'left',
          },
        },
      },
    },
  },
  headerTable: {
    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: '0px',
      margin: '0',
      outline: 'none',
      width: '100%',
      fontFamily: 'MuseoSans-300',
    },
  },
  blueBorderLine: {
    '& td': {
      borderBottom: '2px solid #006FBB !important',
    },
  },
  blankSpace: {
    background: '#fff',
    '& td': {
      fontSize: '0px',
      height: '15px',
      background: '#fff',
    },
  },
  blueRowData: {
    '& td': {
      backgroundColor: ' #006FBB',
      color: '#FFF',
      fontWeight: '700',
    },
  },
  subHeadingData: {
    backgroundColor: '#DDDAD6',
    color: '#006FBB',
    fontWeight: '700',
    fontSize: '17px',
    borderBottom: '2px solid transparent ',
    width: '350px',
    paddingLeft: '30px !important',
  },
  headingData: {
    backgroundColor: '#DDDAD6',
    color: '#006FBB',
    fontWeight: '700',
    fontSize: '16px',
    borderBottom: '1px solid transparent',
  },
  lightBlueBg: {
    backgroundColor: '#DDDAD6',
    borderBottom: '1px solid transparent',
  },
  emptyRowdata: {
    '& td': {
      height: '13px',
      lineHeight: '13px !important',
    },
  },
  modalBodyData: {
    padding: '15px',
    position: 'relative',
    boxSizing: 'border-box',
    overflowY: 'hidden',
  },
  closeButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  modalFooterNew: {
    padding: '10px 15px',
  },
  incomePopupClose: {
    position: 'absolute',
    right: '15px',
    top: '18px',
    marginTop: '-2px',
    padding: '0',
    background: 'transparent',
    border: '0',
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontWeight: '700',
    fontFamily: 'MuseoSans-300',
    minWidth: 'auto',
    '&:hover': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
      background: 'transparent',
    },
  },
  whiteInputSmall: {
    '& input': {
      padding: '1px 2px',
      height: 'auto',
      minHeight: '24px',
      boxSizing: 'border-box',
    },
  },
  rightPanel: {
    paddingLeft: '15px',
    paddingRight: '15px',
    '& h3': {
      marginBottom: '0',
      color: '#000',
      fontWeight: '600',
      fontSize: '22px',
      textAlign: 'center',
      marginTop: '20px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
      },
    },
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      backgroundColor: 'transparent',
      color: '#000',
      '& tr': {
        '& td': {
          fontWeight: '600',
          padding: '0',
          lineHeight: '1.42857143',
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
        },
      },
    },
  },
  greenBox: {
    background: '#58AD4B',
  },
  squareBox: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: 'transparent',
    fontSize: '11px',
    border: '1px solid #3475b0',
    marginRight: '5px',
    boxSizing: 'border-box',
    width: '21px',
  },
  blueBox: {
    background: '#0074B0',
  },
  yellowBox: {
    background: '#FFBE00',
  },
  heightOneEight: {
    height: '18px',
  },
  symCurrency: {
    paddingLeft: '17px !important',
  },
  piBreakdown: {
    '& tbody': {
      '& tr': {
        '& td': {
          '& small': {
            fontSize: '16px',
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '177px',
          },
        },
      },
    },
  },
  containerRowNew: {
    margin: '0 -15px',
  },
  rowSpace: {
    padding: '0 15px',
  },
  orangeBox: {
    background: '#FF7322',
  },
  tooltipInfoNew: {
    width: '400px',
    fontSize: '16px',
    textAlign: 'left',
    maxWidth: '400px',
  },
  inputsTitleNew: {
    fontSize: '22px',
  },
  valueField: {
    padding: '5px !important',
  },
  chartImage: {
    // marginTop: '200px',
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  activeCP: {
    borderTop: '2px solid #2268AE',
    paddingTop: '2px',
  },
  cpSlider: {
    background: 'linear-gradient(to bottom,#3169b0 0,#3169b0 100%)',
  },
  activeCareerAdvancement: {
    borderTop: '2px solid #3795e6',
    paddingTop: '2px',
  },
  caSlider: {
    background: 'linear-gradient(to bottom,#4a96e9 0,#4a96e9 100%)',
  },
  activeAdditionalCareerAdvancement: {
    borderTop: '2px solid #81c3dc',
    paddingTop: '2px',
  },
  addcaSlider: {
    background: 'linear-gradient(to bottom,#8ac3dd 0,#8ac3dd 100%)',
  },
  activeFurtherCareerAdvancement: {
    borderTop: '2px solid #8790b8',
    paddingTop: '2px',
  },
  furthercaSlider: {
    background: 'linear-gradient(to bottom,#8790b8 0,#8790b8 100%)',
  },
  graphColumn: {
    padding: '0px 10px',
  },
  refinancedData: {
    padding: '0px',
    margin: '0px',
    background: '0 0',
  },
  refinancedTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    marginBottom: '15px',
    width: '100%',
    backgroundColor: 'transparent',
    position: 'relative',
    [theme.breakpoints.down( 'sm' )]: {
     display: 'flex',
    },
    '& tbody': {
      [theme.breakpoints.down( 'sm' )]: {
        display: 'block',
        width: '100%',
        boxSizing: 'border-box',
       },
      '& tr': {
        [theme.breakpoints.down( 'sm' )]: {
          display: 'block',
          width: '100%',
         },
        '& td': {
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
          padding: '0 15px',
          lineHeight: '28px',
          [theme.breakpoints.down( 'sm' )]: {
            display: 'block',
            width: '100%',
            boxSizing: 'border-box',
           },
          '& h2': {
            marginBottom: '0',
            color: '#000',
            fontWeight: '600',
            fontSize: '22px',
            fontFamily: 'MuseoSans-500',
            textAlign: 'center',
            width: '100%',
            [theme.breakpoints.down( 'sm' )]: {
              fontSize: '13px',
            },
          },
          '& h3': {
            fontSize: '18px!important',
            fontFamily: 'MuseoSans-500',
            marginTop: '28px',
            marginBottom: '0',
            color: '#000',
            fontWeight: '600',
            [theme.breakpoints.down( 'sm' )]: {
              fontSize: '13px !important',
              marginTop: '0px',
            },
            '& span': {
              fontSize: '26px', 
              [theme.breakpoints.down( 'sm' )]: {
                fontSize: '20px', 
              },             
            },
          },
        },
        '&:nth-child(3)': {
          '& td': {
            '& table': {
              '&::before': {
                content: '""',
                width: 'calc(100% - 0px)',
                position: 'absolute',
                top: '350px',
                height: '2px',
                background: '#2f75b5',
                left: '0',
                right: '0',
                margin: 'auto',
                [theme.breakpoints.down( 'sm' )]: {
                  display: 'none',
                },   
              },
            },
          },
        },
      },
    },
  },
  blueRule: {
    borderTop: '2px solid #2f75b5',
    margin: '0 0 10px',
  },
  esGraph: {
    padding: '0px !important',
    '& table': {
      width: '100%',
      marginBottom: '15px',
      borderSpacing: '0',
      borderCollapse: 'collapse',
      background: '#f2f2f2',
      '& tr': {
        '& td': {
          fontSize: '16px',
          fontFamily: 'MuseoSans-300',
          padding: '0 15px',
          lineHeight: '28px',
          color: '#000',
          [theme.breakpoints.down( 'md' )]: {
            fontSize: '15px',
          },
          [theme.breakpoints.down( 'sm' )]: {
            fontSize: '14px',
          },
          '& b': {
            fontFamily: 'MuseoSans-500',
            fontWeight: 'normal',
          },
        },
      },
    },
  },
  showTooltip: {
    width: '300px',
    fontSize: '16px',
    textAlign: 'left',
    maxWidth: '300',
  },
  rsLoans: {
    '& table': {
      background: '#e7f1f9',
    },
  },
  rsInput: {
    position: 'relative',
    '& input': {
      fontFamily: 'MuseoSans-500',
      padding: '0 5px',
      width: '100%',
      border: 'none',
      background: '#fff',
      height: 'inherit',
      lineHeight: 'inherit',
      [theme.breakpoints.down( 'sm' )]: {
        height: '40px',
      },
    },
  },
  rsPercent: {
    fontSize: '13px',
    right: '25px',
    marginTop: '-11px',
    textAlign: 'right',
    color: '#333',
    float: 'right',
    paddingLeft: '5px',
    position: 'absolute',
    top: '11px',
    fontFamily: 'MuseoSans-700',
    [theme.breakpoints.down( 'sm' )]: {
      top: '50%',
    },
  },
  downArrow: {
    '& img': {
      height: '207px',
      padding: '20px 20px 20px 0',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
    },
  },
  leftMinusMargin: {
    '& h3': {
      marginLeft: '-15px',
      marginRight: '-15px',
      [theme.breakpoints.down( 'xs' )]: {
       marginLeft: '0px',
       marginRight: '0px',
      },
    },
    '& hr': {
      marginLeft: '-15px',
      marginRight: '-15px',
    },
  },
  radioBlock: {
    display: 'inline-block',
    padding: '0px 0px 0px 18px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px 0px 0px 10px',
    },
  },
  graphHead: {
    '& h3': {
      color: '#70ad47',
      fontSize: '40px',
      fontWeight: '700',
      marginBottom: '0',
      marginTop: '20px',
      textAlign: 'center',
      boxSizing: 'border-box',
      lineheight: '1.1',
      '@media (max-width: 767px)': {
        fontSize: '25px',
      }
    },
  },
  blueDivider: {
    margin: '10px',
    borderTop: '2px solid #2f75b5',
  },
  moreSavings: {
    '& h3': {
      color: '#000',
      fontSize: '18px',
      fontWeight: '700',
      marginTop: '20px',
      textAlign: 'center',
      '& span': {
        display: 'block',
      },
    },
    '& ul': {
      paddingLeft: '0',
      listStyle: 'none',
      marginTop: '0px',
      marginBottom: '0px',
      '& li': {
        textAlign: 'center',
        border: '2px solid #ccc',
        fontSize: '13px',
        float: 'left',
        wordBreak: 'break-word',
        width: '18%',
        margin: '1% 1% 2%',
        color: '#000',
        boxSizing: 'border-box',
        [theme.breakpoints.down( 'sm' )]: {
          fontSize: '10px',
        },
        [theme.breakpoints.down( 'xs' )]: {
          fontSize: '11px',
          width: '100%',
        },
        '@media (min-width: 600px) and (max-width: 767px)': {
          minHeight: '80px',
        },
        '& > span': {
          display: 'block',
          padding: '3px',
          [theme.breakpoints.down( 'sm' )]: {
            padding: '7px',
          },
          '&:first-child': {
            height: '58px',
            background: '#f2f2f2',
          },
          '& i': {
            display: 'block',
          },
        },
      },
    },
  },
  whatIfScenario: {
    padding: '5px 10px',
    width: '30%',
    margin: '0 auto',
    fontWeight: '600',
    background: '#2f75b5',
    color: '#fff',
    textAlign: 'center',
    fontSize: '30px',
    lineHeight: '1.1',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      minWidth: '180px',
      marginTop: '15px',
    },
    '@media (min-width: 600px)': {
      minWidth: '261px',
    }
  },
  blockListRow: {
    display: 'flex',
    '& ul': {
      border: '2px solid #ccc',
      width: '98%',
      margin: '30px auto',
      paddingLeft: '0px',
      listStyle: 'none',
      [theme.breakpoints.down( 'xs' )]: {
       display: 'block',
      },
      '& li': {
        width: '30%',
        margin: '2% 10%',
        [theme.breakpoints.down( 'xs' )]: {
          width: '90%',
          margin: '2% 5%',
        },
        '& span': {
          padding: '8px',
        },
        '& input[type="range"]': {
          maxWidth: '290px',
          width: '100%',
          height: '35px',
          padding: '0',
          cursor: 'pointer',
          margin: '0 auto',
        },
      },
    },
  },
  titleSpan: {
    background: '#2f75b5 !important',
    color: '#fff',
    height: 'auto !important',
  },
  greyColorSpan: {
    background: '#f2f2f2',
    display: 'block',
  },
  blockListRowFour: {
    width: '98%',
    margin: '0 auto',
    paddingLeft: '0',
    listStyle: 'none',
    display: 'flex',
    '& ul': {
      '& li': {
        position: 'relative',
        margin: '0 3.6% 0 0',
        width: '22% !important',
        [theme.breakpoints.down( 'xs' )]: {
          width: '100% !important',
          marginBottom: '20px',
        },
        '&:nth-child(1)': {
          '&::before': {
            position: 'absolute',
            bottom: '0',
            width: '250px',
            height: '65px',
            marginBottom: '-65px',
            content: '""',
            left: '90px',
            backgroundSize: '185px 55px',
            backgroundImage: `url(${leftArrow})`,
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.down( 'sm' )]: {
             marginBottom: '-75px',
            },
            [theme.breakpoints.down( 'xs' )]: {
              width: '100% !important',
              marginBottom: '20px',
              bottom: '-90px',
              left: '50px',
              // display: 'none',
            },
            '@media (min-width: 600px) and (max-width: 960px)': {
              left: '15px',
            }
          },
        },
        '&:nth-child(2)': {
          '& span':{
            '&:last-child':{
              padding: '12px 3px 8px !important',
            }
          },
          [theme.breakpoints.down( 'xs' )]: {
            marginTop: '40px',
          },
        },
        '&:nth-child(3)': {
          '& span':{
            '&:last-child':{
              padding: '12px 3px 8px !important',
            }
          },
        },
        '&:nth-child(4)': {
          marginRight: '0px',
          '&::before': {
            position: 'absolute',
            bottom: '0',
            width: '250px',
            height: '65px',
            marginBottom: '-65px',
            content: '""',
            left: '-90px',
            backgroundSize: '225px 54px',
            backgroundImage: `url(${rightArrow})`,
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.down( 'sm' )]: {
              marginBottom: '-75px',
            },
            [theme.breakpoints.down( 'xs' )]: {
              left: '10px',
              // display: 'none',
            },
          },
        },
      },
    },
  },
  moneySpan: {
    padding: '12px 3px !important',
    display: 'block',
    background: 'transparent',
  },
  borderInput: {
    border: '1px solid #ccc',
    padding: '4px',
    [theme.breakpoints.down( 'sm' )]: {
      width: 'auto',
      display: 'flex',
      height: '20px',
    },
    '& input': {
      color: '#000',
      border: 'none',
      // margin: '10px 3px 6px',
      margin: '0px',
      padding: '0px',
      textAlign: 'center',
      marginBottom: '6px',
      [theme.breakpoints.down( 'sm' )]: {
        width: '100%',       
      },
      '&:focus':{
        outline: '0',
      }
    },
    '& div':{
      '&:before':{
        borderBottom: '0px',
      },
      '&:after':{
        borderBottom: '0px',
      },
      '&:hover':{
        '&:before':{
          borderBottom: '0px !important',
        }
      }
    }
  },
  versusRow: {
    display: 'block',
    '& ul': {
      width: '90%',
      margin: '44px auto',
      [theme.breakpoints.down( 'sm' )]: {
        width: '95%',       
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
      '& li': {
        width: '42%',
        margin: '1%',
        [theme.breakpoints.down( 'sm' )]: {
          width: '40%',       
        },
         [theme.breakpoints.down( 'xs' )]: {
          width: '100%',       
        },
        '&:nth-child(2)': {
          width: '70px',
          height: '70px',
          border: '2px solid #2f75b5',
          borderRadius: '100%',
          fontSize: '25px',
          fontWeight: '600',
          padding: '14px',
          marginTop: '23px',
          [theme.breakpoints.down( 'xs' )]: {
            marginTop: '5px',    
            marginLeft: 'calc(50% - 35px)', 
          },         
        },
        '& span': {
          height: 'auto !important',
        },
      },
    },
  },
  savingsTitle: {
    color: '#a6a6a6',
    fontSize: '34px !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    marginBottom: '0',
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '30px',
    lineHeight: '1.1',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '25px !important',
    },
  },
  blackBorder: {
    borderBottom: 'solid 2px #000',
  },
  currentTable: {
    width: '100%',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    color: '#000',
    '& tbody': {
      '& tr': {
        '& th': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          fontSize: '17px',
          borderTop: 'none',
          textAlign: 'left',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '12px',
            padding: '4px',
          },
        },
        '& td': {
          padding: '8px',
          lineHeight: '1.42857143',
          verticalAlign: 'top',
          fontSize: '17px',
          borderTop: 'none',
          position: 'relative',
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '11px',
            padding: '4px',
          },
        },
      },
    },
  },
  heightThirty: {
    height: '30px',
  },
  borderBlueDash: {
    border: '2px dashed #337ab7!important',
  },
  currentBlock: {
    padding: '0px 10px',
    '@media (max-width: 960px)': { 
      overflowX: 'auto',
    }
  },
  flexDisplay: {
    display: 'flex',
  },
  boldText: {
    '& td': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  clearFix: {
    clear: 'both',
  },
  translateList: {
    width: '100%',
  },
  curvesTitle: {
    marginBottom: '0',
    color: '#000',
    fontWeight: '600',
    fontSize: '22px',
    paddingTop: '30px',
    textAlign: 'center',
    marginTop: '20px',
  },
  graphSpace: {
    marginLeft: '5px',
  },
  topTable: {
    verticalAlign: 'top',
    width: '30%',
    backgroundColor: '#fff',
    padding: '0 3px',
    position: 'static',
    fontSize: '17px',
    cursor: 'default',
    '& table': {
      borderSpacing: '0',
      borderCollapse: 'collapse',
      width: '100%',
      '& tr': {
        '& td': {
          verticalAlign: 'bottom',
          padding: '0 3px',
          position: 'static',
          fontSize: '17px',
          cursor: 'pointer',
          lineHeight: '1.42857143',
          color: '#000',
        },
      },
    },
  },
  blueTableRow: {
    '& td': {
      backgroundColor: '#006fbb',
      color: '#fff !important',
      fontWeight: '700',
      fontSize: '16px !important',
      borderBottom: '1px solid transparent',
    },
  },
  blackTopBorder: {
    '& td': {
      borderTop: '2px solid #000',
      fontFamily: 'MuseoSans-700',
      fontWeight: 'normal',
    },
  },
  borderStrip: {
    position: 'relative',
    margin: '0 0 10px',
    fontSize: '17px',
    lineHeight: '1.42857143',
    color: '#000',
    '&::before': {
      borderBottom: '2px solid #337ab7',
      bottom: '0',
      content: '""',
      left: '0',
      position: 'absolute',
      width: '100%',
      zIndex: '999',
    },
  },
  heightTen: {
    height: '10px',
  },
  heightFifty: {
    height: '50px',
  },
  noteRei: {
    fontStyle: 'italic',
    margin: '0 0 10px',
  },
  blueTableRowNew: {
    backgroundColor: '#003356',
    color: '#fff',
    fontWeight: '700',
    fontSize: '17px',
    borderBottom: '1px solid transparent',
  },
  greyBackgroundRow: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        backgroundColor: '#dddad6',
        color: '#006fbb',
        fontWeight: '700',
        fontSize: '17px',
        lineHeight: '1.42857143',
        borderBottom: '1px solid #dddad6',
        boxSizing: 'border-box',
        '&:first-child': {
          width: '435px',
          position: 'relative',
          zIndex: '1',
        },
      },
    },
  },
  cashFlowBlue: {
    '& td': {
      backgroundColor: '#003356 !important',
      color: '#fff !important',
      fontWeight: '700',
      fontSize: '17px !important',
      borderBottom: '1px solid transparent !important',
      lineHeight: '1.42857143',
    },
  },
  incomeCashTable: {
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& tr': {
      '& td': {
        padding: '0px 30px',
      },
    },
  },
  emptySpaceRow: {
    '& td': {
      height: '13px',
      lineHeight: '13px !important',
    },
  },
  blueTopLine: {
    '& td': {
      borderBottom: '2px solid #006fbb !important',
    },
  },
  leftSpaceData: {
    '& td': {
      padding: '0px 3px !important',
    },
  },
  blueRowBackground: {
    '& td': {
      backgroundColor: '#006fbb !important',
      color: '#fff !important',
      fontWeight: '700 !important',
      fontSize: '16px !important',
      borderBottom: '1px solid transparent  !important',
      lineHeight: '1.42857143',
    },
  },
  emptySpaceRowNew: {
    backgroundColor: '#fff',
    '& td': {
      backgroundColor: '#fff !important',
    },
  },
  leftItalic: {
    paddingLeft: '40px !important',
    '& i': {
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal !important',
    },
  },
  notePara: {
    marginTop: '20px',
  },
  reiModal: {
    maxHeight: 'auto',
    overflowY: 'hidden',
  },
  reiPopup: {
    '& > div > div': {
      borderRadius: '6px',
      maxHeight: 'calc(100% - 20px)',
      [theme.breakpoints.down( 'xs' )]: {
       marginLeft: '15px',
       marginRight: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px',
    },
  },
  buyingHomeOne: {
    width: '32%',
  },
  buyingHomeTwo: {
    width: '43%',
  },
  blueRei: {
    borderTop: '2px solid #2f75b5',
    marginBottom: '10px',
    marginTop: '0',
    height: '0',
    boxSizing: 'content-box',
  },
  borderLine: {
    borderBottom: '2px solid #006fbb',
  },
  upFronttable: {
    width: '100%',
    boxSizing: 'border-box',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  homeModalBody: {
    position: 'relative',
    padding: '15px',
    boxSizing: 'border-box',   
  },
  tableResponsive: {
    minHeight: '0.01%',
    overflowy: 'auto',
    [theme.breakpoints.down( 'md' )]: {
    minWidth: '1300px',
    },
  },
  annualCashTable: {
    width: '100%',
    maxWidth: '350px',
  },
  summaryFooterNew: {
    padding: '10px 15px',
    bordertop: 'none',
  },
  familyImage: {
    '& img': {
      width: 'auto',
      height: '35px',
      display: 'block',
      margin: '6px 0 0',
      verticalAlign: 'middle',
    },
  },
  exText: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
    lineHeight: '20px',
    padding: '10px 9px',
    display: 'flex',
    alignItems: 'center',
    // visibility: 'hidden',
  },
  dollarSign: {
    float: 'right',
    left: '0',
    paddingLeft: '5px',
    position: 'absolute',
    color: '#0069aa',
    fontFamily: 'MuseoSans-700',
    [theme.breakpoints.down( 'sm' )]: {      
      fontSize: '13px',  
      paddingLeft: '7px',  
      lineHeight: 'inherit', 
    },
    '@media (max-width: 600px)': {
      fontSize: '11px',
      paddingLeft: '9px', 
      lineHeight: '1.4', 
    }, 
  },
  calcTextSpace: {
    paddingLeft: '0px',
    lineHeight: '60px',
    [theme.breakpoints.down( 'sm' )]: {
      lineHeight: 'inherit',
    },
  },
  exampleText: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
  },
  checkboxRadio: {
    // margin: '3px 0px',
    margin: '7px 0px 3px',
    width: '40%',
    [theme.breakpoints.down( 'xs' )]: {
      width: '45%',
    },
     '@media (max-width: 374px)': {
      width: '50%',
    },
    '& label': {
      display: 'inline-block',
      maxWidth: '100%',
      position: 'relative',
      fontWeight: '400',
      padding: '0 0 0 25px',
      margin: '0',
      color: '#000',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '12px',
        padding: '0 0 0 17px',
      },
      '@media (max-width: 374px)': {
        fontSize: '11px',
      },
      '&::after': {
        background: '#fff',
        border: '1px solid #898d8f',
        content: '""',
        height: '20px',
        left: '0',
        position: 'absolute',
        top: '0%',
        width: '20px',
        boxSizing: 'border-box',
        [theme.breakpoints.down( 'xs' )]: {         
          width: '15px',
          height: '15px',
        },
      },
      '& input[type=radio]': {
        display: 'none',
      },
    },
  },
  checkedEmployment: {
    '&::before': {
      borderColor: '#0069aa',
      borderImage: 'none',
      borderStyle: 'solid',
      borderWidth: '0 2px 2px 0',
      content: '""',
      display: 'block',
      height: '14px',
      left: '7px',
      opacity: '1',
      position: 'absolute',
      top: '2px',
      transform: 'rotate(45deg)',
      visibility: 'visible',
      width: '6px',
      zIndex: '9',
      boxSizing: 'border-box',
      [theme.breakpoints.down( 'xs' )]: {
        left: '6px',
        width: '5px',
        height: '10px',
      },
    },
  },
  tooltipModules: {
    fontSize: '12px',
    boxSizing: 'border-box',
    '&::after': {
      opacity: '0.9 !important',
      borderTopColor: '#0069aa !important',
      borderLeftWidth: '6px !important',
      borderRightWidth: '6px !important',
    },
  },
  leftPuzzleTooltip: {
    '&::after': {
      borderTopColor: 'transparent !important',
      borderLeftColor: '#0069aa !important',
    },
  },
  activeExisting: {
    borderTop: '2px solid #cab488',
    paddingTop: '2px',
  },
  activeInvestments: {
    borderTop: '2px solid #CAA336',
    paddingTop: '2px',
  },
  activeRei: {
    borderTop: '2px solid #134376',
    paddingTop: '2px',
  },
  withoutBullet: {
    marginLeft: '-15px !important',
  },
  activemHe: {
    borderTop: '2px solid #529C6F',
    paddingTop: '2px',
  },
  activeVacation: {
    borderTop: '2px solid #b94606',
    paddingTop: '2px',
  },
  fheSlider: {
    background: 'linear-gradient(to bottom,#8fad81 0,#8fad81 100%)',
  },
  buttonGreenNew: {
    fontFamily: 'MuseoSans-300 !important',
  },
  existingSliderNew: {
    '& > div': {
      '& ul': {
        bottom: '2%',
      },
    },
  },
  existingLeftIcon: {
    marginTop: '62px',
    [theme.breakpoints.down( 'sm' )]: {
      marginTop: '0px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      marginTop: '0px',
    },
  },
  boldData: {
    fontWeight: '700',
    zIndex: '1',
    fontFamily: '"MuseoSans-700"',
  },
  investImage: {
    minHeight: '510px',
    [theme.breakpoints.down( 'sm' )]: {
      minHeight: 'auto',
    },
  },
  leftPadding: {
    paddingLeft: '10px',
  },
  investText: {
    width: 'auto !important',
    display: 'inline-block !important',
    minWidth: '70%',
  },
  noInputTextNew: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
    paddingLeft: '9px',
    paddingRight: '3px',
    float: 'right',
    '@media (max-width: 600px)': {
      fontSize: '11px',
    },  
  },
  lightBlack: {
    color: '#333',
  },
  multipleText: {
    paddingLeft: '15px !important',
    '& > div': {
      padding: '10px 0px',
    },
    '& span': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  calcHeight: {
    lineHeight: '60px',
  },
  yearLeftText: {
    textAlign: 'left',
    float: 'none',
    display: 'table-cell',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '12px',
    },
  },
  dependentFlex: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  activeCar: {
    borderTop: '2px solid #ef9300',
    paddingTop: '2px',
  },
  newInput: {
    width: '65% !important',
  },
  percentSign: {
    left: '55%',
    [theme.breakpoints.down( 'xs' )]: {
      left: 'auto',
      right: '45px',
    },
  },
  displayRadio: {
    display: 'flex',
    alignItems: 'center',   
  },
  radioCheckbox: {
    width: '33.33%',
    marginRight: '4px',
    '@media (max-width: 500px)': {
      width: '40%',
    },
    '@media (max-width: 768px)': {
      width: 'auto',
    },
    '@media (min-width: 1024px)': {
      width: '36%',
    },
  },
  noLeftPadding: {
    paddingLeft: '0px',
  },
  noBottomPadding: {
    paddingBottom: '0px',
    '& div': {
      '& img': {
        minHeight: '480px',
        [theme.breakpoints.down( 'sm' )]: {
         minHeight: 'auto',
        },
      },
    },
  },
  activeHome: {
    borderTop: '2px solid #df6e04',
    paddingTop: '2px',
  },
  careerTooltip: {
    fontSize: '12px !important',
  },
  activeFhe: {
    borderTop: '2px solid #86a940',
    paddingTop: '2px',
  },
  sightPopup: {
    padding: '0px !important',
  },
  sightPopupClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 15px',
    boxSizing: 'border-box',
    borderBottom: '1px solid #e5e5e5',
    textTransform: 'lowercase',
    '& span': {
      fontSize: '20px',
      lineHeight: '22px',
      width: '28px',
      margin: '0',
      border: '1px solid',
      paddingBottom: '2px',
      display: 'block',
      textAlign: 'center',
      color: ' #000',
      textShadow: '0 1px 0 #fff',
      filter: 'alpha(opacity=20)',
      opacity: '.2',
      fontFamily: 'MuseoSans-300',
      fontWeight: '700',
      borderRadius: '2px',
      '&:hover': {
        cursor: 'pointer',
        filter: 'alpha(opacity=50)',
        opacity: '.5',
      },
    },
  },
  hideBtn: {
    display: 'none',
  },
  zeroLeftPadding: {
    paddingLeft: '0 !important',
  },
  notesPopup: {
    padding: '0px',
    width: '40%',
  },

  compareScenario: {
    '& h2': {
      background: '#f4f4f4',
      fontSize: '20px',
      fontWeight: '700',
      marginTop: '45px',
      padding: '10px 18px',
      color: '#0069aa',
      marginBottom: '10px',
    },
  },
  incomeLabels: {
    tableLayout: 'fixed',
    width: '100%',
    borderSpacing: '0px',
    borderCollpase: 'collapse',
    '& tr': {
      '& td': {
        '&:nth-child(1)': {
          textAlign: 'left',
          color: '#000',
          fontWeight: '300',
          paddingLeft: '30px',
        },
        textAlign: 'center',
        color: '#0069aa',
        fontWeight: '700',
        fontStyle: 'normal',
        '& span': {
          margin: '0 0 0 8px',
          fontSize: '15px',
          padding: '5px 0',
          textTransform: 'none',
          display: 'inline-block',
          fontFamily: 'MuseoSans-300',
          height: 'auto',
          verticalAlign: 'text-bottom',
          lineHeight: 'normal',
          maxWidth: '100%',
          minWidth: '100%',
        },
      },
    },
  },
  bluedataRow: {
    background: '#e5f0f6 !important',
  },
  incomeLabelsBtm: {
    marginBottom: '50px',
  },
  labelColor: {
    color: 'red',
  },
  primaryNotes: {
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    backgroundImage: 'none',
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    marginLeft: '5px',
  },
  saveNotesPopup: {
    padding: '0px !important',
    width: '530px',
    margin: '0 auto',
  },
  saveMesage: {
    textAlign: 'center',
    fontSize: '13.5px',
    marginBottom: '5px',
    marginTop: '-5px',
    color: 'green',
  },
  smallFont: {
    fontSize: '14px',
  },
  inputGroup: {
    '& > div': {
      flexWrap: 'nowrap',      
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
        margin: '0px',
      },
    },
  },
  cfButton: {
    width: '50%',
  },
  fundsImage: {
    [theme.breakpoints.down( 'md' )]: {
      width: '100%',     
    },
  },
  scenarioDiv: {
    [theme.breakpoints.down( 'sm' )]: {
      display: 'none',
    },
  },
  scenarioGrid: {
    [theme.breakpoints.down( 'sm' )]: {
      width: '33%',
      maxWidth: '33%',
      flexBasis: '33%',
    },
    [theme.breakpoints.down( 'xs' )]: {
      width: '70%',
      maxWidth: '70%',
      flexBasis: '70%',
      margin: '0 auto',
    },
    '@media (max-width: 400px)': {
      width: '100%',
      maxWidth: '100%',
      flexBasis: '100%',
      margin: '0 auto',
    },
  },
  spanInherit: {       
      display: 'block !important',  
      width: 'auto',  
    '& span': {
      '@media (max-width: 1400px)': {
        padding: '0px 2px',        
      }, 
    },
  },
  cfRedText: {
    whiteSpace: 'nowrap',
  },
  noiText: {
    height: '40px',
    paddingLeft: '9px',
  },
  puzzleTooltip: {
    '&::after': {
      borderTopColor: '#337AB7 !important',
      borderLeftColor: 'transparent !important',
    },
  },
  inputFamily: {
    '& > div': {
      [theme.breakpoints.down( 'xs' )]: {
        padding: '0px !important',
        paddingRight: '5px !important',
      },
    },
    '& div': {
      alignItems:' center',
    },
  },
  totalValues: {
    border: '1px solid #2f75b5',
    margin: '13px auto 0',
    padding: '10px',
    textAlign: 'center',
    width: '575px',
    fontWeight: 'bold',
    fontSize: '22px',
    color: '#000000',
    [theme.breakpoints.down( 'xs' )]: {
      width: 'auto',
    },
  },
  graphText: {
    display: 'block',
    textAlign: 'left',
    fontStyle: 'italic',
    padding: '0 0 0 20px',
    color: '#000000',
  },
  bigInput: {
    '& input': {
      [theme.breakpoints.down( 'xs' )]: {
       height: '50px'
      },
      '@media (max-width: 320px)': {
        height: '60px',      
      },
    },   
  },
  bigInputBox: {
    [theme.breakpoints.down( 'xs' )]: {
      height: '50px',
      '& > div': {
        height: '50px',
      },
    },
    '@media (max-width: 320px)': {
      height: '60px',
      '& > div': {
        height: '60px',
      },
    },
  },
  leftGraphBlock: {
    '@media (max-width: 767px)': {
      width: '100%',  
      maxWidth: '100%',
      flexBasis: '100%',    
    },
  },
  reImage: {
    '& img': {
      [theme.breakpoints.down( 'md' )]: {
        width: 'auto',
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
    },
  },
  femImage: {
    '& img': {
      [theme.breakpoints.down( 'md' )]: {
        width: 'auto',
      },
      [theme.breakpoints.down( 'xs' )]: {
        width: '100%',
      },
    },
  },
  bigInputNew: {
    '& input': {     
      '@media (max-width: 320px)': {
        height: '50px',      
      },
    },   
  },
  bigInputBoxnew: {    
    '@media (max-width: 320px)': {
      height: '50px',
      '& > div': {
        height: '50px',
      },
    },
  },
  cpBigInputbox: {
    '@media (max-width: 420px)': {
      height: '60px',
      '& > div': {
        height: '60px',
      },
    },   
  },
  cpBigInput: {
    '& input': {
      '@media (max-width: 420px)': {
       height: '60px'
      },     
    },   
  },
  inputCa: {
    height: '100%',
    paddingLeft: '5px',
  },
  cpBigSpan: {
  '@media (max-width: 420px)': {
    height: '60px',
    alignItems: 'center',
    display: 'flex',
    },  
  },
  selectInputNew: {    
    '@media (max-width: 420px)': {
      height: '60px',
      '& > div > div': {
        height: '60px',      
      },
      '& > div > div >div': {
        height: '60px',      
      },
    },   
  },
  tempNone: {
    display: 'none',
  },
  savePanleInfo: {
    border: '2px solid #0069aa',
  },
  rowhSpace: {
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    },
  },
  leftMobile: {
    [theme.breakpoints.down( 'xs' )]: {
      margin: '0px',
    },
  },
  cpBigInputboxNew: {
    '@media (max-width: 420px)': {
      height: '70px',
      '& > div': {
        height: '70px',
      },
    },   
  },
  cpBigInputNew: {
    '& input': {
      '@media (max-width: 420px)': {
       height: '70px'
      },     
    }, 
    '& > span': {
      '@media (max-width: 420px)': {
       height: '70px'
      },     
    },   
  },
  additionalOrange: {
    fontFamily: 'MuseoSans-300',
    fontWeight: '700',
  },
  sightButton: {
    fontSize: '20px',
    lineHeight: '22px',
    width: '28px',
    margin: '0',
    border: '1px solid',
    paddingBottom: '2px',
    display: 'block',
    textAlign: 'center',
    color: ' #000',
    textShadow: '0 1px 0 #fff',
    filter: 'alpha(opacity=20)',
    opacity: '.2',
    fontFamily: 'MuseoSans-300',
    fontWeight: '700',
    borderRadius: '2px',
    '&:hover': {
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
    },
  },
  compareFooter: {
    width: '100%',
    '& button': {
      textTransform: 'none',
    },
  },
  compareModalBlock: {
    padding: '0px',
    '& > div > div': {
      paddingTop: '0px',
    },
  },
  compareHeader: {
    padding: '15px 0px',
    boxSizing: 'border-box',
  },
  refTitleNew: {
    display: 'none',
    '@media (max-width: 960px)': {
      display: 'block !important',
      paddingLeft: '0px !important',
     },  
  },
  refTitle: {    
    '@media (max-width: 960px)': {
      display: 'none !important',
     },  
  },
  worksheetWalkthru: {
    position: 'absolute',
    maxWidth: '640px',
    background: '#fff',
    zIndex: '999',
    border: '3px solid #0069aa',
    borderRadius: '40px',
    top: '135px',
    right: '60px',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    '& h4': {
      width: '100%',
      padding: '10px 20px',
      borderBottom: '1px solid #ccc',
      fontSize: '20px',
      textAlign: 'left',
      marginTop: '10px',
      marginBottom: '10px',
      color: '#0069aa',
      fontWeight: 'normal',
      boxSizing: 'border-box',
    },
    '& p': {
      color: '#000!important',
      fontSize: '16px!important',
      padding: '10px 20px',
      display: 'inline-block',
      lineHeight: '24px',
      margin: '0 0 10px',
      boxSizing: 'border-box',
    },
    '&::before, &::after':{
      content: '""',
      top: '0',
      width: '0',
      height: '0',
      transform: 'rotate(90deg)',
      bottom: '0',
      margin: 'auto',
      position: 'absolute',
      borderStyle: 'solid',
    },
  },
  walkthruBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    padding: '0 25px 0 15px',
    boxSizing: 'border-box',
    justifyContent: 'flex-end',
    '@media (max-width: 767px)': {
      flexWrap: 'wrap',
    },
  },
  walkThruBtns: {
    maxWidth: '230px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    textAlign: 'right',
    marginBottom: '10px',
    float: 'right',
    paddingRight: '10px',
    '@media (max-width: 767px)': {
      maxWidth: '200px',
    },
    '@media (max-width: 479px)': {
      display: 'block',
      float: 'left',
      textAlign: 'left',
    },
    '& button': {
      margin: '0px 2px',
      display: 'inline-block',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      touchAction: 'manipulation',
      cursor: 'pointer',
      fontSize: '14px',
      userSelect: 'none',
      borderWidth: '1px',
      '@media (max-width: 767px)': {
        fontSize: '11px',
        padding: '6px',
      },
    },
  },
  startWalkThru: {
    backgroundColor: '#337ab7',
    borderColor: '#2e6da4',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#337ab7 !important',
      borderColor: '#204d74 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  }, 
  closeWalkThru: {
    backgroundColor: '#fff !important',
    borderColor: '#ccc !important',
    color: '#333 !important',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  skipWalkthru: {
    backgroundColor: '#fff',
    borderColor: '#ccc',
    color: '#333',
    padding: '6px 12px',
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#e6e6e6 !important',
      borderColor: '#adadad !important',
    },
  },
  worksheetActiveWalkthru: {
   // display: 'block',
    zIndex: '999999',
    pointerEvents: 'all',
    cursor: 'auto',
  },
  backWalkThru: {
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    padding: '6px 12px',
    borderRadius: '4px',
    color: '#fff !important',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: 'uppercase',
    border: '2px solid transparent',
    '&:hover': {
      backgroundColor: '#ec971f !important',
      borderColor: '#d58512 !important',
    },
    '& span': {
      color: '#fff !important',
    },
  },
  walkthruOne: {
    // left: 'auto',
    maxWidth: '770px',
    // top:'80px',
    top: '50px',
    left: '497px',
   // display: 'none !important',
    '&::before': {
      display:'none',
    },
    '&::after': {
      display: 'none',
    },
  },
  walkthruTwo: {
    top: '101px',
    right: '-261px',
    maxWidth: '600px',
    left: 'auto',
    transform: 'translateY(-50%)',
    //display: 'none !important',
    '&::before': {
      top: '71px',
      right: '-44px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      left:'auto',
      borderWidth: '0 31px 32px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '69px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      right: '-51px',
      left: 'auto',
      borderWidth: '0 34px 36px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  relativeBlock: {
    position: 'relative',
  },
  walkthruThree: {
    left: '30px',
    // top: '190px',
    top: '150px',
    //display: 'none !important',
    '&::before': {
      top: '-29px',
      left: '39px',
      bottom: 'auto',
      transform: 'none',
      right: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '-34px',
      bottom: 'auto',
      transform: 'none',
      left: '35px',
      right: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  detailsHeader: {
    position: 'relative',
  },
  walkthruFour: {
    top: '-65px',
    left: '85px',
    right: 'auto',
    maxWidth: '570px',
    //display:'none !important',
    '&::before': {
      top: '71px',
      right: '-44px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      left: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '69px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      right: '-51px',
      left: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruFive: {
    left: 'auto',
    top: '-65px',
    right: '225px',
    maxWidth: '540px',
    //display:'none !important',
    '&::before': {
      top: '71px',
      right: '-44px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      left: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after': {
      top: '69px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      right: '-51px',
      left: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruSix:{
    maxWidth: '640px',
    right: '325px',
    left: 'auto',
    top: '-65px',
   // display:'none !important',
    '&::before':{
      top: '71px',
      right: '-44px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      left: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after':{
      top: '69px',
      bottom: 'auto',
      transform: 'rotate(90deg)',
      right: '-51px',
      left: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruSeven: {
    right: '5px',
    // maxWidth: '660px',
    maxWidth: '770px',
    top: '-245px',
    left: 'auto',
    //display: 'none!important',
    '&::before': {
      left: '44px',
      bottom: '-30px',
      transform: 'rotate(180deg)',
      top: 'auto',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after':{
      transform: 'rotate(180deg)',
      left: '40px',
      bottom: '-34px',
      top: 'auto',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruEight:{
    maxWidth: '680px',
    top: 'auto',
    // bottom: '-32px',
    bottom: '-8px',
    // left: '240px',
    left: '260px',
    right: '-630px',
    //display:'none !important',
    '&::before':{
      left: '-44px',
      bottom: '57px',
      top: 'auto',
      right: 'auto',
      transform: 'rotate(-90deg)',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after':{
      top: 'auto',
      left: '-51px',
      bottom: '55px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruNine:{
    maxWidth: '550px',
    right: '120px',
    left: 'auto',
    top: '-100px',
   // display:'none !important',
    '&::before':{
      top: '70px',
      left: '-44px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after':{
      left: '-51px',
      top: '70px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruTen:{
    left: 'auto',
    maxWidth: '580px',
    top: '-130px',
    right: '0',
    //display:'none !important',
    '&::before':{
      left: '-44px',
      bottom: '47px',
      top: 'auto',
      right: 'auto',
      transform: 'rotate(-90deg)',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after':{
      top: 'auto',
      bottom: '45px',
      right: 'auto',
      transform: 'rotate(-90deg)',
      left: '-51px',
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruEleven:{
    left: 'auto',
    right: '200px',
    maxWidth: '550px',
    top: 'auto',
    bottom: '-32px',
   // display:'none !important',
    '&::before':{
      left: '-44px',
      bottom: '57px',
      top: 'auto',
      right: 'auto',
      transform: 'rotate(-90deg)',
      borderWidth: '0 30px 30px',
      borderColor: 'transparent transparent #fff',
      zIndex: '9999',
    },
    '&::after':{
      top: 'auto',
      left: '-51px',
      bottom: '55px',
      right: 'auto',
      transform: 'rotate(-90deg)',     
      borderWidth: '0 34px 34px',
      borderColor: 'transparent transparent #0069aa',
      zIndex: '9998',
    },
  },
  walkthruTwelve:{
    left: '380px',
    maxWidth: '750px',
    right: '0',
    margin: 'auto',
    boxSizing:'border-box',
   // display:'none !important',
    top:'30px',
    '&::before':{
      display:'none',
    },
    '&::after':{
      display:'none',
    },
  },
  pwiAnimation: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'fixed',
      left: '0',
      top: '0',
      width: '100%',
      height: '100vh',
      background: 'rgba(0,0,0,.7)',
      zIndex: '9999',
      display: 'none',
      boxSizing: 'border-box',
    },
  },
  pwiAnimationActive: {
    '&::before': {
      display: 'block',
    },
  },
  detailsPopupScroll:{
    backgroundImage: `url(${fidummyImage})`,  
    backgroundSize: 'cover',
    backgroundPosition:'top center',
    backgroundRepeat:'no-repeat',
    borderRadius: '15px',
    maxHeight: '800px',
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    pointerEvents: 'none',
    // display:'none !important',
  },
  scaleOpacity:{
    // opacity:'0',
  },
  scaleOpacityNew:{
    opacity:'0',
  },
  scaleOpacityHeight:{
    opacity:'0',
    height: '540px',
  },
  detailsMainBlock:{
    position:'relative',
  },
  showmeWalkthru:{
    backgroundImage: `url(${showImage})`, 
    maxHeight: '1210px', 
    backgroundSize: 'cover',
    backgroundPosition:'top center',
    backgroundRepeat:'no-repeat',
    backgroundColor:'#fff',
    borderRadius: '15px',
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    pointerEvents: 'none',
  },
  bootWalkthru:{
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    pointerEvents: 'none',
    borderRadius: '15px',
  },
  compareWalkthru:{
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    pointerEvents: 'none',  
    backgroundImage: `url(${saveWalkthru})`, 
    backgroundRepeat:'no-repeat',
    backgroundColor:'#fff',
    maxHeight: '670px',
  },
  notesWalkthru:{
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    pointerEvents: 'none',    
  },
  intrestModal: {
    '& > div > div': {
      width: '100%',
      maxWidth: '600px',
    },
    '& > div > div > div': {
      padding: '0px',
    },
  },
  intrestModalHeader: {
    padding: '15px !important',
    borderBottom: '1px solid #e5e5e5',
    '& h2': {
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '20px',
      margin: '0',
      color: '#0069aa',
    },
  },
  intrestModalFooter: {
    padding: '15px !important',
    '& > div':{
      borderTop: '0px',
    }
  },
  intrestModalBody: {
    overflow: 'auto',
    boxSizing: 'border-box',
    '& p':{
      padding: '0px 20px',
    }
  },
  okBtn: {
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    color: '#fff',
    margin: '0px 5px',
    '&:hover': {
      backgroundColor: '#799e41',
      borderColor: '#799e41',
    },
  },
  csaveScenario: {
    boxSizing: 'border-box',   
    '& > div': {
      alignItems: 'center',
      margin: '0px auto',
      display: 'flex',
      // '@media (max-width: 767px)': {
      //   margin: '30px 15px',
      // },
      // '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      //   margin: '30px 15px',
      // },
      // '@media (max-width: 420px)': {
      //   margin: '30px 15px',
      // },
    },
    '& > div > div ': {
      width: '750px',
      margin: '0 auto',
      maxWidth: '100%',
      borderRadius: '0px',
      background: '#fff',
      '@media screen and (min-width: 600px) and (max-width: 767px)': {
        width: '85%',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        width: '70%',
      },
      '@media (max-width: 599px)': {
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
      },
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  closeSaveBtn: {
    padding: '0',
    cursor: 'pointer',
    background: '0 0',
    border: '0',
    position: 'absolute',
    right: '10px',
    top: '10px',
    left: 'auto',
    float: 'right',
    color: '#fff',
    opacity: '1',
    fontSize: '21px',
    lineHeight: '1',
    textShadow: '0 1px 0 #fff',
    fontWeight: '700',
    textTransform: 'none',
    fontFamily: 'MuseoSans-300',
    minWidth: 'auto',
    boxSizing: 'border-box',
    zIndex: '9',
    '@media (max-width: 420px)': {
      color:'#333',
    },
    '&:hover, &:focus': {
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer',
      filter: 'alpha(opacity=50)',
      opacity: '.5',
    },
  },
  csSaveBody:{
    overflow: 'auto',
    paddingBottom: '0',
    display: 'flex',
    textAlign: 'center',
    position: 'relative',
    height: 'calc(100vh - 200px)',
    alignItems: 'center',
    '@media (max-width: 420px)': {
      flexWrap: 'wrap',
    },
  },
  csSaveImage: {
    width: '45%',
    padding: '50px 50px 30px',
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      padding: '10px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      padding: '30px 20px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 50px 10px',
    },
    '& > div':{
      width: '100%',
    }
  },
  greenCircleImage: {
    '& img': {
      maxWidth: '200px',
      marginBottom: '95px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',
        marginBottom: '30px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '120px',  
        marginBottom: '70px',     
      }, 
      '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '120px',  
        marginBottom: '50px',     
      },     
      '@media (max-width: 420px)': {
        maxWidth: '75px',
        marginBottom: '20px',
      },
    },
  },
  pwLogo: {
    '& img': {
      maxWidth: '160px',
      verticalAlign: 'middle',
      border: '0',
      '@media (max-width: 767px)': {
        maxWidth: '100px',       
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        maxWidth: '100px',       
      },     
    },
  },
  puzzleText: {
    fontSize: '26px',
    color: '#0069aa',
    fontFamily: 'MuseoSans-100',
    paddingTop: '10px',
    margin: '0 0 10px',
    '@media (max-width: 767px)': {
      fontSize: '23px',   
    }, 
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      fontSize: '23px', 
    },
    '@media (max-width: 420px)': {
      fontSize: '20px',
    },
  },
  csSaveMessage: {
    width: '55%',
    padding: '50px 50px 30px',
    background: '#0069aa',
    color: '#fff',
    boxSizing: 'border-box',
    position: 'relative',
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    '@media (max-width: 767px)': {
      padding: '20px 20px 10px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {   
      padding: '30px 10px 17px 10px',
    },
    '@media (max-width: 420px)': {
      width: '100%',
      padding: '10px 10px 10px',
    }, 
    '& > div':{
      height: '100%',
    }, 
    '& h1': {
      fontSize: '40px',
      paddingTop: '60px',
      marginBottom: '50px',
      color: '#fff',
      fontWeight: '400',
      marginTop: '20px',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-300',
      '@media (max-width: 767px)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '0px',
        marginBottom: '10px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 800px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '50px',
        marginBottom: '20px',
      },
      '@media only screen and (min-device-width: 801px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '30px',
        marginTop: '20px',
        marginBottom: '20px',
      },
      '@media (max-width: 420px)': {
        paddingTop: '10px',
        marginBottom: '10px',
        fontSize: '30px',
      },    
    },
    '& h3': {
      fontSize: '27px',
      marginBottom: '20px',
      color: '#fff',
      fontWeight: '400',
      fontFamily: 'MuseoSans-300',
      marginTop: '20px',
      lineHeight: '1.1',
      '@media (max-width: 767px)': {
        paddingTop: '0px',
        fontSize: '22px',
        marginTop: '10px',
        marginBottom: '10px',
      },
      '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
        paddingTop: '0px',
        fontSize: '22px',
        marginTop: '20px',
        marginBottom: '20px',
      },
      '@media (max-width: 420px)': {
        margin: '15px auto',
        fontSize: '20px',
      },
    },   
  },
  goTimeline: {
    marginTop: '90px',
    color: '#fff',
    display: 'table',
    fontSize: '20px',
    fontFamily: 'MuseoSans-300',
    lineHeight: 'normal',
    margin: '25px auto',
    textTransform: 'uppercase',
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    cursor: 'pointer',
    padding: '10px 20px',
    borderRadius: '10px',
    marginBottom: '5px', 
    '@media (max-width: 767px)': {
      marginTop: '20px',
      fontSize: '15px',
    },
    '@media only screen and (min-device-width: 700px) and (max-device-width: 850px) and (orientation: landscape)  and (-webkit-min-device-pixel-ratio: 2)': {
      marginTop: '25px',
      fontSize: '15px',
    }, 
    '@media (max-width: 420px)': {
      marginTop: '20px',
    },
    '&:hover': {
      background: '#f69a34',
      borderColor: '#f69a34',
    },   
  },
  backToModule: {
    paddingTop: '5px',
    fontSize: '18px',
    fontFamily: 'MuseoSans-300',
    margin: '0 0 10px',
    '& a': {
      color: '#fff',
      textTransform: 'uppercase',
      fontSize: '14px',
      textDecoration: 'underline',
      paddingTop: '0',
      '&:hover': {
        color: '#f69a34',
      },
    },
  },
  fiModuleIconsVisible:{
    borderRadius: '15px',
    background: '#fff',
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    padding: '8px',
  },
  detailsMainBlockVisible:{
    position: 'relative',
    backgroundImage: `url(${fidummyImage})`,
    marginTop: '-40px',
    backgroundSize: 'cover',
    borderRadius: '15px',
    maxHeight: '800px',
    zIndex: '999999',
    cursor: 'none',
  },
  showMeScroll:{
    backgroundImage: `url(${showImage})`,
    // backgroundSize: 'cover',
    backgroundSize: '100%',
    maxHeight: '1210px',
    height: '100vh',
    borderRadius: '15px',
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    transition: 'all .3s ease-in-out',
    backgroundRepeat: 'no-repeat',
  },
  showMeScrollSix:{
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    transition: 'all .3s ease-in-out',
    borderRadius: '15px',
  },
  showMeScrollSeven:{
    backgroundImage: `url(${saveWalkthru})`,
    backgroundColor: '#fff',
    maxHeight: '670px',
    borderRadius: '15px',
    height: '100vh',
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
    transition: 'all .3s ease-in-out',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  showMeScrollEight:{
    position: 'relative',
    zIndex: '999999',
    cursor: 'none',
  },
  existingassetsImg:{
    backgroundImage: `url(${existingassets2})`,
  },
  dummyImgShowmeCareerpath:{
    backgroundImage: `url(${dummyImgShowmeCareerpath})`,
  },
  opacityZero:{
    opacity:'0',
  },
  reiWalkThru2:{
    backgroundImage: `url(${reiWalkThru2})`,
  },
  dummyImgShowme:{
    backgroundImage: `url(${dummyImgShowme})`,
  },
  dummyImgShowmeExisting:{
    backgroundImage: `url(${dummyImgShowmeExisting})`,
  },
  whatIfExisting:{
    backgroundImage: `url(${whatIfExisting})`,
  },
  dummyImgShowmeMoresavings:{
    backgroundImage: `url(${dummyImgShowmeMoresavings})`,
  },
  careeradVancementShowMe:{
    backgroundImage: `url(${careeradVancementShowMe})`,
  },
  dummyImgCompareExisting:{
    backgroundImage: `url(${dummyImgCompareExisting})`,
  },
  dummyImgCareerpath1:{
    backgroundImage: `url(${dummyImgCareerpath1})`,
  },
  dummyImgCompareScenarios:{
    backgroundImage: `url(${dummyImgCompareScenarios})`,
  },
  additionalCareerWalk1:{
    backgroundImage: `url(${additionalCareerWalk1})`,
  },
  additionalCareerWalk2:{
    backgroundImage: `url(${additionalCareerWalk2})`,
  },
  additionalCareerWalk3:{
    backgroundImage: `url(${additionalCareerWalk3})`,
  },
  furtherCareerWalk1:{
    backgroundImage: `url(${furtherCareerWalk1})`,
  },
  furtherCareerWalk2:{
    backgroundImage: `url(${furtherCareerWalk2})`,
  },
  furtherCareerWalk3:{
    backgroundImage: `url(${furtherCareerWalk3})`,
  },
  careerAdvancementMydetails:{
    backgroundImage: `url(${careerAdvancementMydetails})`,
  },
  careerAdvancementSavedScenareos:{
    backgroundImage: `url(${careerAdvancementSavedScenareos})`,
  },
  investmentsDetails:{
    backgroundImage: `url(${investmentsDetails})`,
  },
  investmentsShowMe:{
    backgroundImage: `url(${investmentsShowMe})`,
  },
  reiWalkThru1:{
    backgroundImage: `url(${reiWalkThru1})`,
  },
  reiWalkThru3:{
    backgroundImage: `url(${reiWalkThru3})`,
  },
  reiWalkThru4:{
    backgroundImage: `url(${reiWalkThru4})`,
  },
  dummyImg:{
    backgroundImage: `url(${dummyImg})`,
  },
  dummyImgCompare:{
    backgroundImage: `url(${dummyImgCompare})`,
  },
  familyDetails:{
    backgroundImage: `url(${familyDetails})`,
  },
  familyShowMe:{
    backgroundImage: `url(${familyShowMe})`,
  },
  vactionDetails:{
    backgroundImage: `url(${vactionDetails})`,
  },
  carDetails:{
    backgroundImage: `url(${carDetails})`,
  },
  carShowMe:{
    backgroundImage: `url(${carShowMe})`,
  },
  buyingahomeWalkThru1:{
    backgroundImage: `url(${buyingahomeWalkThru1})`,
  },
  buyingahomeWalkThru2:{
    backgroundImage: `url(${buyingahomeWalkThru2})`,
  },
  buyingahomeWalkThru3:{
    backgroundImage: `url(${buyingahomeWalkThru3})`,
  },
  buyingahomeWalkThru4:{
    backgroundImage: `url(${buyingahomeWalkThru4})`,
  },
  impPopup: {
    padding: '0',
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    background: theme.palette.common.white,
    maxWidth: '600px',
    borderRadius: '6px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
      margin: '0 auto',
    },
    '& > div': {
      padding: '15px',
    },
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      fontWeight: '500',
      borderBottom: '1px solid #e2e2e2',
      paddingBottom: '10px',
      margin: ' 0',
      lineHeight: '1.42857143',
    },
    '& p': {
      marginBottom: '0',
      color: '#333',
      lineHeight: '24px',
    },
  },
  impDiv: {
    padding: '15px 0 5px',
    display: 'flex',
    '& p': {
      padding: '0 0 0 24px',
      marginTop: 0,
      fontSize: '16px',
    },
    '& span': {
      paddingTop: '6px',
    },
  },
  impbuttonOkRedo: {
    borderTop: '1px solid #e2e2e2',
    textAlign: 'right',
    '& button': {
      padding: '6px 8px',
      minWidth: '64px',
      borderRadius: '4px',
      fontSize: '14px',
      background: '#fff',
      border: '2px solid #ccc',
      cursor: 'pointer',
      outline: 'none',
      fontFamily: 'MuseoSans-300',
      '&:first-child': {
        background: '#337ab7',
        borderColor: '#337ab7',
        color: '#fff',
        marginRight: '6px',
        '&:hover': {
          background: '#285e8c',
          borderColor: '#285e8c',
        },
      },
    },
  },
  modelPopupRow:{
    overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
    },
    '& > div > div': {
      minWidth: '600px',
      width: '600px',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      }
    },
  },
  modelPopup:{
    // WebkitBoxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
    // boxShadow: '0 5px 15px rgb(0 0 0 / 50%)',
    // position: 'relative',
    // backgroundColor:'#fff',
    // WebkitBackgroundClip: 'padding-box',
    // backgroundClip: 'padding-box',
    // border: '1px solid #999',
    // border: '1px solid rgba(0,0,0,.2)',
    // borderRadius: '6px',
    // outline: '0',
     padding: '0px !important',
  },
  modelHeader:{
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    position: 'relative',
    height: '20px',
    '& h3': {
      fontSize: '18px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& button': {
      fontSize: '18px',
      lineHeight: '22px',
      width: '28px',
      margin: '0',
      border: '1px solid',
      paddingBottom: '2px',
      background: 'transparent',     
      float: 'right',
      opacity: '0.6',
      color: 'rgba(0, 0, 0, 0.87)',
      cursor: 'pointer',
      '&:hover':{
        opacity: '0.8',
      }
    },
  },
  modelBody:{
    position: 'relative',
    padding: '15px',
    overflow: 'auto',
    paddingBottom: '0',
    textAlign: 'center',
    '& p':{
      fontSize: '13.5px',
      marginBottom: '10px',
      marginTop: '-5px',
      color: 'green',
    }
  },
  modelFooter:{
    padding: '15px',
    textAlign: 'right',
    borderTop: '1px solid #e5e5e5',
    '& button':{
      display: 'inline-block',
      padding: '6px 12px',
      marginBottom: '0',
      fontSize: '14px',
      lineHeight: '1.42857143',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      MsTouchAction: 'manipulation',
      touchAction: 'manipulation',
      cursor: 'pointer',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      MsUserSelect: 'none',
      userSelect: 'none',
      borderRadius: '4px',
      marginLeft: '5px',
      color: '#333',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      '&:hover':{
        backgroundColor: '#e6e6e6',
        border: '1px solid #adadad',
      }
    }
  },
  labelTextCenter:{
    textAlign: 'center',
    '& label':{
      fontSize: '13px',
      fontWeight: '700',
      color: '#000',
      margin: '20px 0px 10px 0px',
      display: 'inline-block',
    }
  },
  // labelText:{
  //   '& label':{
  //     fontSize: '13px',
  //     fontWeight: '700',
  //     color: '#000',
  //     margin: '20px 0px 10px 0px',
  //     display: 'inline-block',  
  //   }
  // },
  displayCenter:{
    display: 'flex',
    alignItems: 'center',
  },
  noSpaceDoller:{
    background: '#eee',
  },
  dollerRight:{
    padding: '0px 15px',
    height: '40px',
    lineHeight: '40px',
  },
  dollerRightHeight:{
    lineHeight: 'initial',
  },
  dollerCount:{
    display: 'block',
    fontWeight: '700',
    color: '#000',
  },
  dollerCountLast:{
    position: 'relative',
    top: '-10px',
  },
  moduleTitleTop:{
    '& h3':{
      marginTop: '0px',
    }
  },
  moduleTitleNowrap:{
    '& h3':{
      whiteSpace: 'nowrap',
      [theme.breakpoints.down( 'sm' )]: {
        whiteSpace: 'initial',
        lineHeight: '15px',
        paddingLeft: '15px',
        marginTop: '15px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        minHeight: '50px',
      }
    }
  },
  dollarSignInput:{
    top: '9px',
  },
  displayEnd:{
    display: 'flex',
    alignItems: 'end',
  },
  pl0:{
    paddingLeft: '0',
  },
  calculatedValueTotal:{
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0px',
  },
  widthHalf:{
    [theme.breakpoints.down( 'xs' )]: {
      width: '50% !important',
    }
  },
  textControl:{
    [theme.breakpoints.down( 'xs' )]: {
       display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      height: '34px',
    }
  }
} );

export default styles;
