import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import styles from './styles';
import c1 from '../../assets/img/life-event-library/career-path-slider.jpg';
import c2 from '../../assets/img/life-event-library/career-path-slider2.jpg';
import c3 from '../../assets/img/life-event-library/career-path-slider3.jpg';

const LifeEventImageFour = ( props ) => {
  const { classes } = props;

  const getStartYear = () => {
    props.openNoAccessPopup( true );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={ `${classes.containerNew} ${classes.eventFourColor}` }>
      <div className={ classes.testimonilas }>
        <div className={ classes.testimonialInner }>
          <Slider { ...settings }>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Career Path
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Plan Your Career
                        </Typography>
                        <ul>
                          <li>Choose an occupation</li>
                          <li>Forecast estimated income from all sources</li>
                          <li>Know your ability to cover living expenses while paying off higher education debt</li>
                          <li>If needed, go back and adjust the numbers to balance things out.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Get Started</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ c1 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Career Path
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Enter Your Expenses
                        </Typography>
                        <ul>
                          <li>Estimate your living expenses</li>
                          <li>Use collective intelligence to forecast costs</li>
                          <li>Inkwiry populates your financing expenses and creates an income statement</li>
                          <li>Whether you are single or married, Inkwiry figures your taxes and net income.</li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Get Started</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ c2 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
            <div className={ classes.testimonialInner }>
              <div>
                <div className={ classes.tstimonialContent }>
                  <div className={ classes.sliderHeader }>
                    <Typography variant="h1" component="h1">
                      Career Path
                    </Typography>
                  </div>
                  <div className={ classes.sliderContent }>
                    <Grid container spacing={ 4 }>
                      <Grid item sm={ 7 }>
                        <Typography variant="h2" component="h2">
                          Compare Scenarios
                        </Typography>
                        <ul>
                          <li>
                            Build on your best options:
                            <ul>
                              <li>See how your &quot;what if&quot; possibilities stack up against one another</li>
                              <li>Compare and contrast your options</li>
                              <li>Adjust your plan to minimize risk and maximize income.</li>
                            </ul>
                          </li>
                        </ul>
                        <span aria-hidden="true" onClick={ getStartYear } className={ classes.greenBtn }>Find Best Scenario</span>
                      </Grid>
                      <Grid item sm={ 5 }>
                        <div className={ classes.sliderImage }>
                          <img src={ c3 } alt="banner" />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

LifeEventImageFour.propTypes = {
  classes: PropTypes.object.isRequired,
  openNoAccessPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LifeEventImageFour );
