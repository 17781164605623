import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../pwi/financial-independence/styles';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );
HCExporting( Highcharts );

const Inflation = ( props ) => {
  const {
    classes, incomeGrowthGraphData,
  } = props;

  const inflationGraphData = incomeGrowthGraphData && incomeGrowthGraphData.getIncomeGrowthGraphData.data[4];
  return (
    <div className={ classes.graphInflection }>
      <div className={ classes.graphBox }>
        <HighchartsReact
          highcharts={ Highcharts }
          options={ {
            chart: {
              type: 'spline',
              scrollablePlotArea: {
                scrollPositionX: 0,
              },
            },
            navigation: {
              menuItemStyle: {
                fontSize: '14px',
                textAlign: 'left',
              },
              menuItemHoverStyle: {
                background: '#f5f5f5',
                color: '#4c4c4c',
                fontSize: '14px',
              },
              buttonOptions: {
                height: 40,
                width: 48,
                symbolSize: 24,
                symbolX: 24,
                symbolY: 21,
                symbolStrokeWidth: 2,
                verticalAlign: 'bottom',
                _titleKey: 'y',
              },
            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                },
              },
            },
            credits: {
              enabled: false,
            },
            title: {
              text: 'Inflation Rate (12-month percentage change)',
            },
            xAxis: [{
              tickInterval: 24,
              categories: inflationGraphData.dates ? inflationGraphData.dates : '',
              labels: {
                formatter() {
                  const chart = this;
                  return chart.value.split( ' ' )[1];
                },
                style: {
                  color: '#000000',
                  fontSize: '13px',
                },
              },
            }],
            yAxis: [{ // Primary yAxis
              tickInterval: 1,
              labels: {
                format: '{value:.1f}%',
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
              title: {
                text: '',
                style: {
                  color: '#000000',
                  fontSize: '14px',
                },
              },
            }],
            tooltip: {
              headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
              crosshairs: true,
              shared: true,
              valueDecimals: 1,
              valueSuffix: '%',
              style: {
                color: '#000000',
                fontSize: '14px',
              },
            },
            legend: {
              itemStyle: {
                fontSize: '14px',
              },
            },
            plotOptions: {
              spline: {
                lineWidth: 2,
                states: {
                  hover: {
                    lineWidth: 2,
                  },
                },
                marker: {
                  enabled: false,
                },

              },
            },
            series: inflationGraphData.details,
          } }
        />
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};

Inflation.propTypes = {
  classes: PropTypes.object.isRequired,
  incomeGrowthGraphData: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

export default withStyles( styles )( Inflation );
