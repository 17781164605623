import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const CarLease = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Car Lease</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/car-lease.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Car Lease
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/car-lease-300px.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          Think of a car lease like renting an apartment. When
                          you rent a place to live, you make monthly payments in
                          exchange for the right to live in the apartment. But
                          you don&apos;t own the apartment.
                        </p>
                        <p>
                          The same thing goes for a car lease. You trade a
                          consistent monthly payment in exchange for the right
                          to use a car. But you don’t own the car. That’s the
                          main takeaway for a car lease.
                        </p>
                        <p>
                          Think of a car lease like renting an apartment. When
                          you rent a place to live, you make monthly payments in
                          exchange for the right to live in the apartment. But
                          you don&apos;t own the apartment.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p className={classes.noBottomMargin}>
                          <b>New car every few years</b>
                        </p>
                        <p className={classes.noTopMargin}>
                          A car lease typically ranges from 24 months to 60
                          months. That means every couple of years, you can
                          drive a new vehicle with very little hassle. For
                          example, you won’t have to sell your car or make a big
                          down payment for a new car.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <b>Less cash upfront</b>
                        </p>
                        <p className={classes.noTopMargin}>
                          If you don&apos;t have enough cash to make a down
                          payment on a new car, a car lease offers an affordable
                          option. Typically, a lease requires less cash upfront
                          to start than buying a new car.
                        </p>
                        <p>There are some disadvantages...</p>
                        <p className={classes.noBottomMargin}>
                          <b>Annual mileage limit</b>
                        </p>
                        <p className={classes.noTopMargin}>
                          When you lease a car, you agree to a max number of
                          miles driven in any year. This max limit typically
                          ranges from 10,000 to 15,000 miles per year. What
                          happens if you exceed that limit you might ask?
                          Penalties and fees on a per mile basis.
                        </p>
                        <p className={classes.noBottomMargin}>
                          <b>You need to take good care of the car</b>
                        </p>
                        <p className={classes.noTopMargin}>
                          When the lease is done, the owner of the car will
                          perform a very detailed and thorough inspection inside
                          and out. If you don’t plan on taking good care of your
                          car – meaning cleaning any spills, avoiding nicks and
                          dings, etc. – then leasing might not be right for you.
                          When you go to return your vehicle, the owner of the
                          car will charge you penalties and fees for damage to
                          the car.
                        </p>
                        <p>Is leasing right for you?</p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
CarLease.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CarLease);
