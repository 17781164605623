import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const WhatCarLeasePayment = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>What&apos;s in a Car Lease Payment?</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/whats-in-car-lease.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      What&apos;s in a Car Lease Payment?
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/lease-payment.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.noTopMargin}>
                          A car lease payment has three parts: depreciation,
                          financing costs and sales tax. Let&apos;s look at an
                          example where you lease a $50,000 car for 36 months
                          (three years) in Pennsylvania with a residual value of
                          60% and a money factor of 0.00225.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The first, and the largest portion of the payment, is
                          depreciation. What is depreciation? It&apos;s the loss
                          in value of the car during the lease period. At the
                          beginning of your lease, the car is worth $50,000.
                          But, after three years of driving, the car won&apos;t
                          be worth $50,000 because, as it&apos;s driven, that
                          car will depreciate or lose its value over time. The
                          dealership will determine what&apos;s called a
                          residual value, which is the value of the car after
                          the lease is over. With a 60% residual value, the car
                          would be worth $30,000 at the end of the lease,
                          according to the dealership.
                        </p>
                        <div className={classes.netWorthLink}>
                          <span className={classes.leftBlock}>
                            Depreciation Monthly Fee
                          </span>
                          <span className={classes.rightBlock}>
                            <span>
                              = (Final Cost of the Vehicle - Residual Value) / #
                            </span>
                            <span className={classes.monthsSpace}>
                              of Months
                            </span>
                            <span>= ($50,000 - $30,000) / 36 months</span>
                            <span>= $555.56</span>
                          </span>
                        </div>
                        <p>
                          <b className={classes.diduKnow}>DID YOU KNOW:</b> Even
                          if you&apos;re leasing a car, it&apos;s important to
                          negotiate the price of the vehicle. As you can see in
                          the formula above, the lower the cost of the vehicle,
                          the lower the depreciation monthly fee. Always try and
                          negotiate the lowest price when leasing.
                        </p>
                        <p>
                          The next part is the financing cost. In exchange for
                          providing you with a car for 36 months, the dealer
                          charges you a financing fee – think like an interest
                          rate on a loan. The dealer has to make money on the
                          transaction, or it would make no sense to lease
                          vehicles. Financing is where the dealer earns a return
                          on loaning you a vehicle.
                        </p>
                        <p>
                          Instead of using an annual interest rate, which would
                          be easier for us to understand, the dealer uses
                          what&apos;s called a money factor. The money factor,
                          just like any loan, is determined by your credit
                          score. A higher credit score even saves you money on
                          leasing a car! Here&apos;s a simple formula to convert
                          a money factor to an estimated interest rate, which
                          makes for easy comparison:
                        </p>
                        <div className={classes.netWorthLink}>
                          <span className={classes.leftBlock}>
                            Annual Interest Rate
                          </span>
                          <span className={classes.rightBlock}>
                            <span>= (Money Factor x 2,400)</span>
                            <span>= (0.00225 x 2,400)</span>
                            <span>= 5.40%</span>
                          </span>
                        </div>
                        <p>
                          So, how does the money factor figure into the monthly
                          lease payment?
                        </p>
                        <div className={classes.netWorthLink}>
                          <span className={classes.leftBlock}>
                            Financing Monthly Fee
                          </span>
                          <span className={classes.rightBlock}>
                            <span>
                              = (Final Cost of the Vehicle + Residual Value) x
                            </span>
                            <span className={classes.monthsSpace}>
                              Money Factor
                            </span>
                            <span>= ($50,000 + $30,000) x 0.00225</span>
                            <span>= $180.00</span>
                          </span>
                        </div>
                        <p>
                          Last, but not least, sales tax. In Pennsylvania, the
                          sales tax rate is 6.0%
                        </p>
                        <div className={classes.netWorthLink}>
                          <span className={classes.leftBlock}>
                            Monthly Sales Tax
                          </span>
                          <span className={classes.rightBlock}>
                            <span>
                              = (Depreciation Fee + Financing Fee) x Local
                            </span>
                            <span className={classes.monthsSpace}>
                              Sales Tax Rate
                            </span>
                            <span>= ($555.56 + $180.00) x 6.0%</span>
                            <span>= $44.13</span>
                          </span>
                        </div>

                        <p className={classes.netWorthLink}>
                          <span className={classes.leftBlock}>
                            <strong>Total Monthly Payment</strong>
                          </span>
                          <span className={classes.rightBlock}>
                            <span>
                              <strong>= $555.56 + $180.00 + $44.13</strong>
                            </span>
                            <span>
                              <strong>= $779.69</strong>
                            </span>
                          </span>
                        </p>
                        <p>
                          Thinking about leasing a car? Ask your dealer to break
                          it down into the three parts I just showed you for
                          easy comparison. A higher residual percentage means
                          the car holds its value better over time, which is
                          always something to consider when leasing a car.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
WhatCarLeasePayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhatCarLeasePayment);
