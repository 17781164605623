import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  Link,
  Grid
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import _ from 'lodash';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import Pageloader from '../../../components/ui/pageloader';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import Sidebar from './sidebar';
import DefinationPopup from '../../../components/ui/pwi-sidebar/defination-popup';
import ChooseYourPath from '../../../components/pwi/higher-education/chooseYourPath';
import ChooseYourSchool from '../../../components/pwi/higher-education/chooseYourSchool';
import ChooseYourSchoolManual from '../../../components/pwi/higher-education/chooseYourSchoolManual';
import CompareSchool from '../../../components/pwi/higher-education/compareSchool';
import FirstYearTuitionRoomBoard from '../../../components/pwi/higher-education/firstYearTuitionRoomBoard';
import YearsToGraduation from '../../../components/pwi/higher-education/yearToGraduation';
import LivingExpenses from '../../../components/pwi/higher-education/livingExpenses';
import LivingExpensesBreakdown from '../../../components/pwi/higher-education/livingExpensesBreakdown';
import TotalCostAttendance from '../../../components/pwi/higher-education/totalCostAttendance';
import TotalMoneyNeeded from '../../../components/pwi/higher-education/totalMoneyNeeded';
import NavigatingFinancialAid from '../../../components/pwi/higher-education/navigatingFinancialAid';
import NavigatingFinancialAidContinueFirst from '../../../components/pwi/higher-education/navigatingFinancialAidContinueFirst';
import NavigatingFinancialAidContinueSecond from '../../../components/pwi/higher-education/navigatingFinancialAidContinueSecond';
import ScholarshipGrants from '../../../components/pwi/higher-education/scholarshipGrants';
import StateTuitionFees from '../../../components/pwi/higher-education/stateTuitionFees';
import FamilyContribution from '../../../components/pwi/higher-education/familyContribution';
import EarningIncome from '../../../components/pwi/higher-education/earningIncome';
import FederalStudentLoans from '../../../components/pwi/higher-education/federalStudentLoans';
import FederalStudentLoansFirst from '../../../components/pwi/higher-education/federalStudentLoansFirst';
import UnsubsidizedStudentLoan from '../../../components/pwi/higher-education/unsubsidizedStudentLoan';
import PrivateStudentLoans from '../../../components/pwi/higher-education/privateStudentLoans';
import PrivateStudentLoansContinue from '../../../components/pwi/higher-education/privateStudentLoansContinue';
import PrivateStudentLoansWithInterestStudent from '../../../components/pwi/higher-education/privateStudentLoansWithInterestStudent';
import StudentLoanRepayment from '../../../components/pwi/higher-education/studentLoanRepayment';
import StudentLoanRepaymentContinue from '../../../components/pwi/higher-education/studentLoanRepaymentContinue';
import Summery from '../../../components/pwi/higher-education/summery';
import MoneyNeeded from '../../../components/pwi/higher-education/moneyNeeded';
import FundingSources from '../../../components/pwi/higher-education/fundingSources';
import styles from './styles';
import PwiLeftMenu from '../../../components/pwi/pwi-left-menu';
import LeaveDialogPopup from '../../../components/pwi/pwi-left-menu/leave-dialog-popup';
import PwiSave from '../../../components/pwi-save/pwi-save';
import SavePopupSucess from '../../../components/common/save-popup-success';
import LogoImage from '../../../assets/img/logo.png';
import Walk from '../../../assets/img/pwi-fi/walk.png';
import HigherEducation from '../../../assets/img/pwi-fi/higher_education.png';
import save from '../../../assets/img/build-career-sketch/icon-save-blue.png';
import * as timeLineActions from '../../../calculations/time-line';
import * as moduleServices from '../../../calculations/modules-services';
import { initializeExcelSheetsData, workSheetCalc } from '../../../calculations';
import WalkThruOne from '../../pwi/wallk-thru/walkThruOne';
import WalkThruTwo from '../../pwi/wallk-thru/walkThruTwo';
import WalkThruThree from '../../pwi/wallk-thru/walkThruThree';
import WalkThruFour from '../../pwi/wallk-thru/walkThruFour';
import WalkThruFive from '../../pwi/wallk-thru/walkThruFive';
import WalkThruSix from '../../pwi/wallk-thru/walkThruSix';
import WalkThruSeven from '../../pwi/wallk-thru/walkThruSeven';
import WalkThruEight from '../../pwi/wallk-thru/walkThruEight';
import * as pwiObject from '../../../utilities/pwiObjectFormat';
import messages from '../../../utilities/pwi-popup-messages';
import SimulatorPanel from '../../../components/documents/simulator-panel';
import StudentLoanPopups from '../../../components/documents/student-loan-popups';
import SummaryDocs from '../../../components/documents/documents';
import ClearTime from '../../../assets/img/pwi-he/clear-timeline-blue.png';
import collegeList from '../../../json_data/college_data';
import careerList from '../../../json_data/career_school_data';
import graduateList from '../../../json_data/graduate_data';
import militaryList from '../../../json_data/military_school_data';
import compareSchoolNames from '../../../json_data/school_comparison';
import CommonDialoginfo from '../../../screens/life-event-library/common-dialog-info'

const SAVESCENARIO = loader('../../../graphql/schema/pwi/save.graphql');
const PWI_DEFINATION = loader('../../../graphql/schema/financial-independence/getPWiDefination.graphql');
const MODULE_DATA = loader('../../../graphql/schema/fc/moduleData.graphql');
const CHECK_MODULE_EXISTS = loader('../../../graphql/schema/fc/check-module-exists.graphql');
const LIST_OF_STATES = loader('../../../graphql/schema/auth/list-of-states.graphql');
const LIVING_EXPENSES = loader('../../../graphql/schema/auth/living-expenses.graphql');


const titleText = {
  1: 'Choose Your Path',
  2: 'Choose Your School',
  3: 'Choose Your School',
  4: 'In-State vs Out-of-State Tuition & Fees',
  5: 'Compare Schools Side-By-Side',
  6: 'First Year Tuition, Room and Board',
  7: 'Years to Graduation',
  8: 'Living Expenses',
  9: 'Living Expenses Breakdown',
  10: 'Total Cost of Attendance',
  11: 'Total Money Needed',
  12: 'Navigating Financial Aid',
  13: "Navigating Financial Aid (cont'd)",
  14: "Navigating Financial Aid (cont'd)",
  15: 'Scholarships & Grants',
  16: 'Family Contribution and Savings',
  17: 'Earning Income',
  18: 'Federal Student Loans',
  19: 'Federal Student Loans',
  20: 'Unsubsidized Student Loan Interest during School',
  21: 'Private Student Loans',
  22: 'Private Student Loans',
  23: 'Private Student Loan Interest during School',
  24: 'Student Loan Repayment',
  25: 'Student Loan Repayment',
  26: 'Summary',
};

const PWIHigherEducation = (props) => {
  const { classes, history } = props;
  let bodySection = useRef(null);

  const date = new Date();

  const module_data = {
    name: "Active Selection",
    scenarios: [
      {
        directory_id: "",
        end_year_relation: 0,
        id: "",
        inkwiry_scenario: 0,
        name: "",
        relation_year: date.getFullYear(),
        selected_module: "module1",
        start_year_relation: 0,
        __typename: "ScenarioData",
        data: {
          module1: {
            inflation_in_percentage: 0,
            long_term_growth_in_percentage: 0,
            scenario_id: "",
            start_year: date.getFullYear(),
            __typename: "module1Output"
          },
          module2: null,
          module4: null,
          module5: null,
          module6: null,
          module8: null,
          module9: null,
          module10: null,
          module11: null,
          module12: null,
          module13: null,
          module14: null,
          module15: null,
          module17: null,
          module18: null,
        }
      }
    ],
    timeLine: [
      {
        action: "FC",
        module_time_line_order: 0,
        scenario_id: "",
        selected_module: "module1",
        time_line_order: 0,
        __typename: "TimeLineOrderOutPut"
      }
    ],
    __typename: "ModuleData"
  }

  const [menu, setMenu] = useState(false);
  const [mainMenu, setMainMenu] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [titleDefination, setTitleDefination] = useState('');
  const [definationPopup, setDefinationPopup] = useState(false);
  const [completedSlides, setCompletedSlides] = useState({});
  const [inputData, setInputData] = React.useState({});
  const [saveKey, setSaveKey] = useState('');
  const [popupClose, setPopupClose] = useState(false);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [completedWalkThruSlides, setCompletedWalkThruSlides] = useState({ 0: true });
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [openPopup, setOpenPopup] = React.useState('');
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [studentLoanType, setStudentLoanType] = React.useState('');
  const [loadValue, setLoadValue] = React.useState(false);
  const [openCommonDialoginfo, setOpenCommonDialoginfo] = React.useState(false);

  const { data: listOfStates } = useQuery(LIST_OF_STATES);
  const { data: livingExpensesList } = useQuery(LIVING_EXPENSES);
  // const apolloClient = useApolloClient();

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module2' : ''
    },
    onCompleted(response) {
      setClearModule(false);
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          let completedSlide = {};
          for (let c = 1; c <= 15; c += 1) {
            completedSlide[c] = true;
          }
          setCompletedSlides(completedSlide);
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
          const responseData = data;

          if (responseData.type_of_higher_education !== undefined && responseData.type_of_higher_education > 0) {
            let collegeListData = {};
            if (responseData.type_of_higher_education === 1) {
              collegeListData = careerList;
            } else if (responseData.type_of_higher_education === 2) {
              collegeListData = collegeList;
            } else if (responseData.type_of_higher_education === 3) {
              collegeListData = graduateList;
            } else if (responseData.type_of_higher_education === 4) {
              collegeListData = militaryList;
            }
            const schoolIndex = _.findIndex(collegeListData, { 'school_name': responseData.name_of_college })

            if (schoolIndex >= 0) {
              responseData['school'] = collegeListData[schoolIndex]['id'];
              responseData['school_details'] = collegeListData[schoolIndex];
              if (responseData.type_of_higher_education === 1) {
                responseData['state_type'] = 'tuition_fee';
              } else {
                if (collegeListData[schoolIndex]['in_state_tuition_fee'] === collegeListData[schoolIndex]['out_state_tuition_fee']) {
                  responseData['state_type'] = 'in_state_tuition_fee';
                } else if (responseData['tuition'] === collegeListData[schoolIndex]['in_state_tuition_fee']) {
                  responseData['state_type'] = 'in_state_tuition_fee';
                } else if (responseData['tuition'] === collegeListData[schoolIndex]['out_state_tuition_fee']) {
                  responseData['state_type'] = 'out_state_tuition_fee';
                } else {
                  responseData['state_type'] = 'in_state_tuition_fee';
                }
              }
            }
            if (schoolIndex === -1) {
              responseData.school = 'new';
              responseData.school_details = {};
              responseData.school_details.school_name = responseData.name_of_college;
              responseData.school_details.city_name = '';
              responseData.school_details.state_id = responseData.state_id;
            }
          }

          let campusStartYear = responseData.on_campus_start_year;
          let offcampusStartYear = responseData.off_campus_start_year;

          let on_campus_year = '';
          if (typeof campusStartYear !== 'undefined' && campusStartYear !== '' && campusStartYear !== '0' && campusStartYear > 0) {
            on_campus_year = parseInt(campusStartYear, 10);
          }
          let off_campus_year = '';
          if (typeof offcampusStartYear !== 'undefined' && offcampusStartYear !== '' && offcampusStartYear !== '0' && offcampusStartYear > 0) {
            off_campus_year = parseInt(offcampusStartYear, 10);
          }

          if (on_campus_year !== '' && off_campus_year !== '') {
            if (on_campus_year > off_campus_year) {
              let diff = on_campus_year - off_campus_year;
              let remeaning = parseInt(responseData.years_diff) - diff;
              responseData.on_campus_year = remeaning;
              responseData.off_campus_year = diff;
            } else {
              let diff = off_campus_year - on_campus_year;
              let remeaning = parseInt(responseData.years_diff) - diff;
              responseData.off_campus_year = remeaning;
              responseData.on_campus_year = diff;
            }
            let years = [0];
            for (let i = 1; i <= parseInt(responseData.years_diff); i += 1) {
              years.push(i);
            }
            responseData.on_campus_years = years;
            responseData.off_campus_years = years;
          } else if (on_campus_year !== '' || off_campus_year !== '') {
            if (on_campus_year !== '') {
              responseData.on_campus_year = responseData.years_diff;
              responseData.off_campus_year = 0;
            } else {
              responseData.off_campus_year = responseData.years_diff;
              responseData.on_campus_year = 0;
            }
            let years = [0];
            for (let i = 1; i <= parseInt(responseData.years_diff); i += 1) {
              years.push(i);
            }
            responseData.on_campus_years = years;
            responseData.off_campus_years = years;
          }

          setInputData(responseData);
        }
        else if (moduleServices.module1Data.start_year <= 0) {
          const popupdetails = messages('No Fi Module', 'Higher education');
          let saveContinueBtn = true;
          setBeforeLeavePopup({ ...{ popupStatus: true, type: 'confirm', no_pwi_fi: '/plan-with-inkwiry/fi', saveContinueBtn, 'classVal': 'clearConfirmDialog' }, ...popupdetails })
        }
        else {
          setInputData({ start_year: moduleServices.module1Data.start_year });
          setActiveScenarioData({});
        }
        setActiveStep(1);
        setLoading(false);
      });
    },
    onError() {
      setClearModule(false);
      timeLineActions.assignModulesData(module_data, (data) => {
        // if(moduleServices.module1Data.start_year <= 0) {
        //   const popupdetails = messages('No Fi Module','Higher education');
        //   let saveContinueBtn = true;
        //   setBeforeLeavePopup({...{popupStatus: true,type:'no_pwi_fi',url: '/plan-with-inkwiry/fi',saveContinueBtn,'classVal':'clearConfirmDialog'},...popupdetails})
        // }
        // setActiveScenarioData();
        // const date = new Date();
        // moduleServices.setModule1Data({ start_year: date.getFullYear(), long_term_growth_in_percentage: 0, inflation_in_percentage: 0 });
        setInputData({ start_year: moduleServices.module1Data.start_year });
        setActiveStep(1);
        setActiveScenarioData({});
        setLoading(false);
      });
    },
  });

  useQuery(CHECK_MODULE_EXISTS, {
    variables: {
      module: 'module2',
      page: 'pwi'
    },
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response.checkModuleExists.status === true) {
        if (response.checkModuleExists.message === 'success') {
          setLoading(false);
          const popupdetails = messages('clearData');
          setBeforeLeavePopup({
            ...{
              popupStatus: true,
              type: 'default_clear',
            }, ...popupdetails
          })
        } else {
          getModuleData()
        }
      } else {
        if (response.checkModuleExists.message === 'unauthenticated') {
          history.push('/login')
        } else {
          getModuleData()
        }
      }
    }
  });

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }
  const openBeforeLeavePopup = (url, moduleName, type = 'page') => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages(moduleName);
    let saveContinueBtn = true;
    if (window.location.pathname === url) {
      saveContinueBtn = false;
    }
    setBeforeLeavePopup({ ...{ popupStatus: true, type: type === 'page' ? 'confirm' : 'load', url: moduleName === 'Worksheet' ? '/higher-education' : url, saveContinueBtn }, ...popupdetails })
  }

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });



  const { data: definationResult } = useQuery(PWI_DEFINATION, {
    variables: {
      title: titleDefination,
    },
    fetchPolicy: 'network-only',
    skip: titleDefination === '',
  });


  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    setMenu(false);
    setMainMenu(false);
    const popupdetails = messages('clearScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear',
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  useEffect(() => {
    let progressBar = (activeStep) * (100 / 26);
    progressBar = progressBar.toFixed(0);
    setProgress(progressBar);
  }, [activeStep]);

  const updateInputValue = (field, value) => {
    let inputDetails = { ...inputData };
    if (field === 'total_obj') {
      inputDetails = value;
    } else {
      inputDetails[field] = value;
    }

    if (inputDetails.on_campus_year !== undefined && parseInt(inputDetails.on_campus_year, 10) > 0) {
      inputDetails.on_campus_start_year = parseInt(inputDetails.start_year, 10);

      if (inputDetails.off_campus_year !== undefined && parseInt(inputDetails.off_campus_year, 10) > 0) {
        inputDetails.off_campus_start_year = parseInt(inputDetails.on_campus_start_year, 10) + parseInt(inputDetails.on_campus_year, 10);
      } else {
        inputDetails.off_campus_start_year = 0;
      }
    } else {
      inputDetails.on_campus_start_year = 0;
      if (inputDetails.off_campus_year !== undefined && parseInt(inputDetails.off_campus_year, 10) > 0) {
        inputDetails.off_campus_start_year = inputDetails.start_year;
      } else {
        inputDetails.off_campus_start_year = 0;
      }
    }

    if (inputData.start_year !== undefined && inputData.start_year > 0 && inputData.years_diff !== undefined && inputData.years_diff > 0) {
      inputDetails['graduation_year'] = inputData.start_year + inputData.years_diff - 1;
    } else {
      inputDetails['graduation_year'] = 0;
    }

    if (field === 'type_of_higher_education') {
      setCompletedSlides({ ...completedSlides, ...{ 1: true } })
    }

    setInputData(inputDetails);
  };

  useEffect(() => {
    moduleServices.setModule2Data(inputData);
  }, [inputData])

  const handleSlide = (viewNumber, goType, fromType) => {
    switch (viewNumber) {
      case 1: {
        setActiveStep(viewNumber);
        break;
      }
      case 2: {
        if (inputData.type_of_higher_education !== undefined && inputData.type_of_higher_education > 0) {
          if (inputData.type_of_higher_education === 1) {
            setInputData({ ...inputData, ...{ room_board: 0 } });
          }
          setActiveStep(viewNumber);
        }
        break;
      }
      case 3: {
        if ((inputData.school !== undefined && inputData.school === 'new') || goType === 'new') {
          if (inputData.school !== 'new') {
            let inputValues = { ...inputData };
            inputValues.school = 'new';
            inputValues.school_details = {};
            inputValues.school_details.school_name = '';
            inputValues.school_details.city_name = '';
            inputValues.school_details.state_name = '';
            inputValues.tuition = 0;
            inputValues.room_board = 0;
            inputValues.school_details.grant_aid_percentage = 'new';
            inputValues.school_details.grant_aid_average_amount = 'new';
            inputValues.school_details.federal_loans_percentage = 'new';
            inputValues.school_details.federal_loans_average_amount = 'new';
            setInputData(inputValues);
          }
          setActiveStep(viewNumber);
        } else {
          if (goType === 'back') {
            handleSlide(viewNumber - 1, 'back');
            return false;
          } else {
            handleSlide(viewNumber + 1, 'next');
            return false;
          }
        }
        break;
      }
      case 4: {
        if (goType !== 'back') {
          setCompletedSlides({ ...completedSlides, ...{ 2: true } })
        }
        if (inputData.school === 'new') {
          let inputValues = { ...inputData };
          inputValues.state_type = 'in_state_tuition_fee';
          setInputData(inputValues);
          if (goType === 'back') {
            handleSlide(viewNumber - 1, 'back');
          } else {
            handleSlide(viewNumber + 1, 'next');
          }
          return false;
        } else if (inputData.school !== undefined && inputData.school !== 'new' && inputData.school !== '') {
          if (inputData.type_of_higher_education === 1) {
            if (goType === 'back') {
              handleSlide(viewNumber - 2, 'back')
              return false;
            } else {
              let inputValues = { ...inputData };
              inputValues.state_type = 'tuition_fee';
              setInputData(inputValues);
              handleSlide(viewNumber + 1, 'next')
              return false;
            }
          } else if (inputData.school_details.in_state_tuition_fee === inputData.school_details.out_state_tuition_fee) {
            if (goType === 'back') {
              handleSlide(viewNumber - 2, 'back')
              return false;
            } else {
              let inputValues = { ...inputData };
              inputValues.state_type = 'in_state_tuition_fee';
              setInputData(inputValues);
              handleSlide(viewNumber + 1, 'next');
              return false;
            }
          } else {
            setActiveStep(viewNumber);
          }
        }
        break
      }
      case 5: {
        if (inputData.type_of_higher_education === 1 || inputData.type_of_higher_education === 2) {
          setActiveStep(viewNumber);
        } else {
          if (goType === 'back') {
            handleSlide(viewNumber - 1, 'back');
          } else {
            handleSlide(viewNumber + 1, 'next');
          }
          return false;
        }
        break;
      }
      case 6: {
        if (goType !== 'back') {
          setCompletedSlides({ ...completedSlides, ...{ 3: true } })
        }
        if (inputData.school === 'new') {
          if (fromType === 'side') {
            handleSlide(viewNumber - 3, 'back');
            return false;
          } else if (goType === 'back') {
            handleSlide(viewNumber - 2, 'back');
            return false;
          } else {
            handleSlide(viewNumber + 1, 'next');
            return false;
          }
        } else {
          setActiveStep(viewNumber);
        }
        break;
      }
      case 7: {
        setCompletedSlides({ ...completedSlides, ...{ 4: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 8: {
        setCompletedSlides({ ...completedSlides, ...{ 5: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 9: {
        setCompletedSlides({ ...completedSlides, ...{ 6: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 10: {
        setCompletedSlides({ ...completedSlides, ...{ 7: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 11: {
        if (moduleServices.module2Data.totalStudentsLoanPayments === 0 && moduleServices.module2Data.otherLoanPaymentsonOtherDebt === 0 &&
          moduleServices.module2Data.higherCarExpense === 0 && moduleServices.module2Data.higherRealEstateExpenses === 0 &&
          moduleServices.module2Data.higherChildrenAndPetExpenses === 0) {
          if (goType === 'back') {
            handleSlide(viewNumber - 1, 'back');
          } else {
            handleSlide(viewNumber + 1, 'next');
          }
          return false;
        } else {
          setActiveStep(viewNumber);
        }
        break;
      }
      case 12: {
        setCompletedSlides({ ...completedSlides, ...{ 8: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 13: {
        setActiveStep(viewNumber);
        break;
      }
      case 14: {
        setActiveStep(viewNumber);
        break;
      }
      case 15: {
        setCompletedSlides({ ...completedSlides, ...{ 9: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 16: {
        setCompletedSlides({ ...completedSlides, ...{ 10: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 17: {
        setCompletedSlides({ ...completedSlides, ...{ 11: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 18: {
        setActiveStep(viewNumber);
        break;
      }
      case 19: {
        setCompletedSlides({ ...completedSlides, ...{ 12: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 20: {
        if (inputData['unsubsidized_loans'] !== undefined && inputData['unsubsidized_loans'] !== null && inputData['unsubsidized_loans'] > 0) {
          setActiveStep(viewNumber);
        } else {
          if (goType === 'back') {
            handleSlide(viewNumber - 1, 'back');
          } else {
            handleSlide(viewNumber + 1, 'next');
          }
          return false;
        }
        break;
      }
      case 21: {
        setActiveStep(viewNumber);
        break;
      }
      case 22: {
        setCompletedSlides({ ...completedSlides, ...{ 13: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 23: {
        if (inputData['private_loans'] !== undefined && inputData['private_loans'] !== null && inputData['private_loans'] > 0) {
          setActiveStep(viewNumber);
        } else {
          if (goType === 'back') {
            handleSlide(viewNumber - 1, 'back');
          } else {
            handleSlide(viewNumber + 1, 'next');
          }
          return false;
        }
        break;
      }
      case 24: {
        setCompletedSlides({ ...completedSlides, ...{ 14: true } })
        setActiveStep(viewNumber);
        break;
      }
      case 25: {
        setActiveStep(viewNumber);
        break;
      }
      case 26: {
        setCompletedSlides({ ...completedSlides, ...{ 15: true } })
        setActiveStep(viewNumber);
        break;
      }
      default:
        break;
    }
    window.scrollTo(0, 0);
    setMenu(false);
  };

  const handleHeSave = (data) => {
    const objectParams = { ...inputData };
    if (data === 'save') {
      objectParams.start_year = (inputData.year_of_purchase !== undefined) ? inputData.year_of_purchase : '';
      objectParams.type = 'save';
      objectParams.sketchName = 'Folder';
      objectParams.module = 'module2';
      objectParams.moduleNumber = 'module2Data';
      setmoduleObjectParams(objectParams);
    }
    if (localStorage.getItem('app-token')) {
      setPopupClose(true);
      setSaveKey(data);
    } else {
      const date = new Date();
      const finalObject = {
        goTOBuildCareer: false,
        module1: { start_year: date.getFullYear(), long_term_growth_in_percentage: 0, inflation_in_percentage: 0 },
        module2: pwiObject.getfilteredPWIhigherEducation(inputData),
        module4: "",
      };
      moduleServices.setBeforeLoginData(finalObject);
      setOpenCommonDialoginfo(true)
      setSaveKey("saveGuest");
    }
    setMenu(false);
    setMainMenu(false);
  };

  const saveScenarioCallBack = () => {
    setMenu(false);
    setMainMenu(false);
    handleHeSave('save');
  }

  const handlePopUpClose = () => {
    setPopupClose(false);
  };

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleMainMenu = () => {
    setMainMenu(!mainMenu);
  };


  const handleDefinationPopup = (def) => {
    setTitleDefination(def);
    setDefinationPopup(true);
  };

  const handleDefinationPopupClose = () => {
    setTitleDefination('');
    setDefinationPopup(false);
  };


  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    if (slide === 1) {
      window.scrollTo(0, bodySection.scrollHeight);
      scrollToComponent(bodySection, {
        offset: 20,
        align: 'bottom',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 3 || slide === 4) {
      setMenu(true);
    } else if (slide === 5) {
      setMenu(false);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else if (slide === 6) {
      setMenu(false);
      setMainMenu(true);
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
    } else {
      scrollToComponent(bodySection, {
        offset: -20,
        align: 'top',
        duration: 500,
        overflowY: 'hidden',
      });
      setMenu(false);
      setMainMenu(false);
    }
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setBackgroundBlur(true);
  };
  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setBackgroundBlur(false);
    setMenu(false);
    setMainMenu(false);
  };
  useEffect(() => {
    if (backgroundBlur) {
      document.body.classList.add('pwiAnimation');
    } else {
      document.body.classList.remove('pwiAnimation');
    }
  }, [backgroundBlur]);

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);       
        setTimeout(() => {
          setLoadValue(false);
          setLoading(false);
        }, 1000);
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module2";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module2', 'quick-save', activeScenarioData);
      finalObject['module2Data'] = pwiObject.getfilteredPWIhigherEducation(inputData);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }


  let disableNextButton = false;
  if (activeStep === 1) {
    if (inputData.type_of_higher_education === undefined || inputData.type_of_higher_education === '' || inputData.type_of_higher_education <= 0) {
      disableNextButton = true;
    }
  } else if (activeStep === 2) {
    if (inputData.school === undefined || inputData.school === '') {
      disableNextButton = true;
    }
  } else if (activeStep === 3) {
    if (!(inputData.school_details !== undefined && inputData.school_details.school_name !== 'undefined' && inputData.school_details.school_name !== ''
      && inputData.school_details.state_id !== 'undefined' && inputData.school_details.state_id !== '')) {
      disableNextButton = true;
    }
  } else if (activeStep === 4) {
    if (!(inputData.state_type !== undefined && inputData.state_type !== '')) {
      disableNextButton = true;
    }
  }

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} ref={(body) => { bodySection = body; }}>
      {loading && <Pageloader loading={loading} />}
      <div className={document.body.scrollHeight < 700 ? classes.screenHght : ''}>

        <Sidebar activeScenario={activeScenarioData} sketchName={timeLineActions.careerSketchName !== undefined ? timeLineActions.careerSketchName : ''} saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} menu={menu} handleSideMenu={handleSlide} openBeforeLeavePopup={openBeforeLeavePopup} handleMenu={setMenu} completedSlides={completedSlides} inputData={inputData} />

        {activeWalkThruSlide === 4 && (<WalkThruFour handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 5 && (<WalkThruFive handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={mainMenu ? classes.fixedMenuDisplayMain : classes.fixedMenuDisplayMainNone}>
          <PwiLeftMenu saveScenario={saveScenarioCallBack} clearScenario={clearEntireScenario} setOpenPopup={setOpenPopup} setOpenDocsPopup={setOpenDocsPopup} openBeforeLeavePopup={openBeforeLeavePopup} setMainMenu={setMainMenu} />
        </div>
        {activeWalkThruSlide === 7 && (<WalkThruSeven handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.fiHeader}>
          <div className={classes.container}>
            <div className={classes.fiHeaderInner}>
              <span className={activeWalkThruSlide === 6 ? classes.pwiActiveModule : ''}>
                <img src={HigherEducation} alt="" aria-hidden="true" onClick={handleMainMenu} />
                <h3>Higher Education</h3>
              </span>
              <Link href="/" onClick={(event) => { event.preventDefault(); openBeforeLeavePopup('/', 'Home'); }} className={classes.logoLink}>
                <img src={LogoImage} alt="" />
              </Link>
              {activeWalkThruSlide === 6 && (<WalkThruSix handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
          </div>
        </div>
        <div className={classes.fiContent}>
          <div className={classes.stepOne}>
            <div className={classes.fiContentHeader}>
              <div className={activeWalkThruSlide === 3 ? classes.pwiWalkTitle : ''}>
                <div aria-hidden="true" onClick={handleMenu} className={classes.menuIcon}>
                  <span />
                  <span />
                  <span />
                </div>
                {activeWalkThruSlide === 3 && (<WalkThruThree handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                <h3>{titleText[activeStep]}</h3>
              </div>
              <figure className={backgroundBlur && (activeWalkThruSlide === 1 || activeWalkThruSlide === 8) ? classes.pwiActiveBlock : ''} data-for="walkNew" data-tip="Click here for a Walk-Thru of the Plan With Inkwiry interface.">
                <img src={Walk} alt="" aria-hidden="true" onClick={() => { handleWalkThrus(0); }} />
              </figure>
              {activeWalkThruSlide === 1 && (<WalkThruOne handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              {activeWalkThruSlide === 8 && (<WalkThruEight handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            </div>
            <div className={classes.ProgressBar}>
              <div className={classes.ProgressBarInner} style={{ width: `${progress}%` }} />
            </div>
            {(activeStep === 1) && (
              <ChooseYourPath
                inputData={inputData} updateData={updateInputValue} handleSlide={handleSlide}
              />
            )}
            {(activeStep === 2) && (
              <ChooseYourSchool
                inputData={inputData} listOfStates={(listOfStates && listOfStates.States.length > 0) ? listOfStates.States : []} collegeList={{ 'college': collegeList, 'career': careerList, 'military': militaryList, 'graduate': graduateList }} updateData={updateInputValue} handleSlide={handleSlide}
              />
            )}
            {(activeStep === 3) && (
              <ChooseYourSchoolManual
                allInputData={inputData}
                updateData={updateInputValue}
                handleDefinationPopup={handleDefinationPopup}
                listOfStates={(listOfStates && listOfStates.States.length > 0) ? listOfStates.States : []}
              />
            )}
            {(activeStep === 4) && (
              <StateTuitionFees
                inputData={inputData}
                updateData={updateInputValue}
                handleSlide={handleSlide}
              />
            )}

            {(activeStep === 5) && (
              <CompareSchool
                inputData={inputData}
                updateData={updateInputValue}
                setLoading={setLoading}
                compareCollegeList={(compareSchoolNames.length > 0) ? compareSchoolNames : []}
              />
            )}

            {(activeStep === 6) && (
              <FirstYearTuitionRoomBoard
                inputData={inputData}
                updateData={updateInputValue}
                handleDefinationPopup={handleDefinationPopup}
              />
            )}

            {(activeStep === 7) && (
              <YearsToGraduation
                inputData={inputData}
                updateData={updateInputValue}
              />
            )}

            {(activeStep === 8) && (
              <LivingExpenses
                handleDefinationPopup={handleDefinationPopup}
                inputData={inputData}
                listOfStates={(listOfStates && listOfStates.States.length > 0) ? listOfStates.States : []}
                livingExpensesData={livingExpensesList}
                updateData={updateInputValue}
              />
            )}

            {(activeStep === 9) && (
              <LivingExpensesBreakdown inputData={inputData} />
            )}

            {(activeStep === 10) && (
              <TotalCostAttendance
                inputData={inputData}
              />
            )}

            {(activeStep === 11) && (
              <TotalMoneyNeeded inputData={inputData} handleSlide={handleSlide} />
            )}

            {(activeStep === 12) && (
              <NavigatingFinancialAid handleDefinationPopup={handleDefinationPopup} />
            )}

            {(activeStep === 13) && (
              <NavigatingFinancialAidContinueFirst updateData={updateInputValue} inputData={inputData} />
            )}

            {(activeStep === 14) && (
              <NavigatingFinancialAidContinueSecond />
            )}

            {(activeStep === 15) && (
              <ScholarshipGrants updateData={updateInputValue} inputData={inputData} handleDefinationPopup={handleDefinationPopup} />
            )}
            {(activeStep === 16) && (
              <FamilyContribution updateData={updateInputValue} inputData={inputData} />
            )}

            {(activeStep === 17) && (
              <EarningIncome updateData={updateInputValue} inputData={inputData} />
            )}

            {(activeStep === 18) && (
              <FederalStudentLoans handleDefinationPopup={handleDefinationPopup} />
            )}

            {(activeStep === 19) && (
              <FederalStudentLoansFirst setStudentLoanType={setStudentLoanType} handleDefinationPopup={handleDefinationPopup} updateData={updateInputValue} inputData={inputData} />
            )}

            {(activeStep === 20) && (
              <UnsubsidizedStudentLoan handleDefinationPopup={handleDefinationPopup} updateData={updateInputValue} inputData={inputData} />
            )}
            {(activeStep === 21) && (
              <PrivateStudentLoans handleDefinationPopup={handleDefinationPopup} />
            )}

            {(activeStep === 22) && (
              <PrivateStudentLoansContinue setStudentLoanType={setStudentLoanType} handleDefinationPopup={handleDefinationPopup} updateData={updateInputValue} inputData={inputData} />
            )}

            {(activeStep === 23) && (
              <PrivateStudentLoansWithInterestStudent handleDefinationPopup={handleDefinationPopup} updateData={updateInputValue} inputData={inputData} />
            )}


            {(activeStep === 24) && (
              <StudentLoanRepayment />
            )}

            {(activeStep === 25) && (
              <StudentLoanRepaymentContinue />
            )}

            {(activeStep === 26) && (
              <Summery handleSlide={handleSlide} inputData={inputData} />
            )}
            {((activeStep >= 15 && activeStep <= 17) || activeStep === 19 || activeStep === 20 || activeStep === 22 || activeStep === 23) && (
              <React.Fragment>
                <div className={classes.borderDevider} />
                <div className={classes.totalBoxTable}>
                  <Grid container className={classes.gridSpacingImages}>
                    <MoneyNeeded handleSlide={handleSlide} />
                    <FundingSources
                      inputData={inputData}
                      handleSlide={handleSlide}
                    />
                  </Grid>
                </div>
              </React.Fragment>
            )}

          </div>

          <div className={classes.btnGroup}>
            {activeWalkThruSlide === 2 && (<WalkThruTwo handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} moduleName="rei" />)}
            {activeStep === 1 ? '' : <Button className={classes.cancel} onClick={() => handleSlide(activeStep - 1, 'back')}>Back</Button>}
            <Button className={classes.clearBtn} onClick={clearEntireScenario} aria-hidden="true" data-for="walk" data-tip="Clear your scenario and start fresh."><img src={ClearTime} alt="" /></Button>
            <ReactTooltip id="clearBtntoolTip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTopNew, classes.worksheetToolTip)} />

            {activeScenarioData.id !== undefined && activeScenarioData.id !== '' && (
              <React.Fragment>
                <ReactTooltip id="quicksaveBtntoolTip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.tooltipInfoTopSave)} />
                <Button onClick={handleQuickSave} className={classes.quickSave}><span><img src={save} alt="" data-for="quicksaveBtntoolTip" data-tip="Quick save your changes. To save a new scenario, use the menu in the top left of the page." /></span></Button>
              </React.Fragment>
            )}
            {activeStep === 26
              ? (
                <Button className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => { handleHeSave('save'); }}>Save</Button>

              ) : <Button disabled={disableNextButton} className={activeWalkThruSlide === 2 ? classNames(classes.next, classes.pwiWalkThruButton) : classes.next} onClick={() => handleSlide(activeStep + 1, 'next')}>Next</Button>}
          </div>

          {(activeStep === 2) && (
            <div className={classes.bottomText}>
              <p><i>Source: IPEDS (Integrated Postsecondary Education Data System), NCES (National Center for Education Statistics), U.S. Department of Education</i></p>
              <p><i>Assumptions: Latest data approved and published by IPEDS is 2018-19. To provide up-to-date, estimated tuition and fees, Inkwiry has grown the figures by 4% each year to estimate 2019-20 tuition and fees. For room and board, Inkwiry has grown the 2017-18 figures by 2% each year to estimate 2019-20 room and board.</i></p>
            </div>
          )}
          {(activeStep === 3) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Not sure what the tuition and fees, plus room and board are for your school? Use&nbsp;<a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://nces.ed.gov/collegenavigator/">College Navigator</a>&nbsp;to search for your school and find out.
                </i>
              </p>
            </div>
          )}
          {(activeStep === 5) && (
            <div className={classes.bottomText}>
              <p><i>Source: IPEDS (Integrated Postsecondary Education Data System), NCES (National Center for Education Statistics), U.S. Department of Education, College Scorecard Data</i></p>
              <p><i>Assumptions: Latest data approved and published by IPEDS is 2018-19. To provide up to date, estimated tuition and fees and average annual cost, Inkwiry has grown the 2018-19 figures by 4% each year to estimate 2019-20 tuition and fees and average annual cost. For room and board and median earnings, Inkwiry has grown the 2018-19 figures by 2% each year to estimate 2019-20 room and board and median earnings. Assume all other data shown is as of 2018-19.</i></p>
            </div>
          )}
          {(activeStep === 6) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Want to learn more about your school? Use&nbsp;<a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://nces.ed.gov/collegenavigator/">College Navigator</a>&nbsp;to search for your school and explore more details.
                </i>
              </p>
            </div>
          )}
          {(activeStep === 8) && (
            <div className={classes.bottomText}>
              <p><i>Assumptions: Monthly rent expense is calculated by taking the average monthly rent for a 2 bedroom apartment per state divided by two.</i></p>
              <p><i>*Miscellaneous includes the cost of books and supplies needed for the school year.</i></p>
              <p><i>**Annual Living Expenses + Room & Board represents the total cost of living on campus.</i></p>
            </div>
          )}
          {(activeStep === 15) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  Check out the&nbsp;<a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://studentaid.ed.gov/sa/types/grants-scholarships">federal student aid website</a>&nbsp;for more info on scholarships and grants. And, go to this link to&nbsp;
                  <a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://myscholly.com/">search for scholarships.</a>
                </i>
              </p>
            </div>
          )}
          {(activeStep === 19) && (
            <div className={classes.bottomText}>
              <p>
                <i>
                  <a className={classes.navigatorHover} target="_blank" rel="college_navigator noopener noreferrer" href="https://inkwiry.com/#/finance-explained?type=definitions&letter=f&id=">Click here</a>&nbsp;to learn more about federal student loan limits in Inkwiry&apos;s Finance Explained.
                </i>
              </p>
            </div>
          )}
        </div>
        {definationResult && definationResult.getPWiDefination
          && (
            <DefinationPopup
              definationPopup={definationPopup}
              handleDefinationPopupClose={handleDefinationPopupClose}
              getPWiDefination={definationResult.getPWiDefination}
            />
          )}
        <ReactTooltip id="walk" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        <ReactTooltip id="walkNew" place="top" type="info" effect="solid" className={`${classes.tooltipInfoTop} ${classes.tooltipInfoTextWalk}`} />
        <ReactTooltip id="clear" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
        <div className={classes.videoModalPopup}>
          <Dialog
            open={popupClose}
            onClose={handlePopUpClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={saveKey === 'save' ? classes.SavePopup : classes.pendinglistPopup}
          >
            <DialogContent className={saveKey === 'clear' ? classes.clearModule : ''}>
              {saveKey === 'save' && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={handlePopUpClose}>
                      ×
                    </span>
                  </div>
                  <PwiSave setPopupOpen={setPopupClose} moduleParams={moduleObjectParams} getSaveData={inputData} setLoadValue={setLoadValue} />
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className={classes.videoModalPopup}>
        <Dialog
          open={openCommonDialoginfo}
          onClose={() => { setOpenCommonDialoginfo(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            {
              saveKey === "saveGuest" && (
                <div>
                  <div className={classes.popupClose}>
                    <span aria-hidden="true" onClick={() => setOpenCommonDialoginfo(false)}>
                      ×
                    </span>
                  </div>
                  <CommonDialoginfo />
                </div>
              )
            }
          </DialogContent>
        </Dialog>
      </div>

      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} saveScenarioCallBack={saveScenarioCallBack} setLoading={setLoading} history={history} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel otherData={{ 'module': 'module2' }} setOpenPopup={setOpenPopup} popupStatus={openPopup} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      {studentLoanType !== '' && (<StudentLoanPopups setStudentLoanType={setStudentLoanType} studentLoanType={studentLoanType} moduleName={'module2'} />)}

      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
    </Typography>
  );
};

PWIHigherEducation.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(PWIHigherEducation);