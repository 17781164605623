import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography, TextField,
} from '@material-ui/core';
import { PMT, nper } from '../../../utilities/excelLibrary';
import styles from './styles';
import { formatDollar } from '../../../utilities/chartCommonFunctions';

Highcharts.setOptions( {
  lang: {
    thousandsSep: ',',
  },
} );

HCExporting( Highcharts );

const ExtraLoanPayment = ( props ) => {
  const { classes } = props;

  const extraLoanPaymentDefaultValues = {
    loanAmount: 300000,
    termOfTheLoan: 30,
    interestRate: 4.00,
    extraPayment: 200,
  };

  const [extraLoanPaymentValues, setExtraLoanPaymentValues] = useState( extraLoanPaymentDefaultValues );
  const [calculatedValues, setCalculatedValues] = useState( {} );

  useEffect( () => {
    const loanRepayment = {};
    // Start Sheet Calculations
    const { loanAmount } = extraLoanPaymentValues;
    loanRepayment.loanAmountValue = loanAmount;

    // Beginning Balance
    const beginningBalance = loanAmount;

    // Terms in Years
    const termsOfYear = extraLoanPaymentValues.termOfTheLoan;

    // Annual Interest Rate
    const annualInterestRate = extraLoanPaymentValues.interestRate;

    // Original Payment
    let originalPayment = 0;
    if ( beginningBalance > 0
        && termsOfYear > 0
        && annualInterestRate > 0 ) {
      originalPayment = PMT( ( annualInterestRate / 100 ) / 12, termsOfYear * 12, -( beginningBalance ), 0, 0 );
    }
    const extraPayment = originalPayment + extraLoanPaymentValues.extraPayment;

    loanRepayment.newExtraPaymentValue = extraPayment;
    loanRepayment.originalPaymentValue = originalPayment;
    loanRepayment.originalPaymentAnnualValue = originalPayment * 12;

    // Total Interest Paid
    let totalInterestPaid = 0;
    let totalInterestPaid1 = 0;
    // Total Paid
    let totalPaid = 0;
    let totalPaid1 = 0;

    const refinancedStudentLoanData = {};

    const graduationYear = 2019;
    if ( graduationYear > 0 ) {
      let year = graduationYear;
      let yearLimit = year + termsOfYear;

      let Balance = 0;
      let Payment = 0;
      let Interest = 0;
      let Principal = 0;
      let Equity = 0;
      let TotalInterest = 0;
      let TotalPayment = 0;

      const totalInterestPaidArray = [];

      const totalPaidArray = [];

      // For Loop from graduation year to +10 years
      for ( year; year <= yearLimit; year += 1 ) {
        refinancedStudentLoanData[year] = {};

        refinancedStudentLoanData[year].LaggingInterest = 0;
        refinancedStudentLoanData[year].LaggingPrincipal = 0;
        refinancedStudentLoanData[year]['Months Total Balance'] = 0;

        for ( let month = 1; month <= 12; month += 1 ) {
          refinancedStudentLoanData[year][month] = {};

          if ( year === graduationYear && month === 1 ) {
            refinancedStudentLoanData[year][month].YearIdentifier = year;

            Balance = beginningBalance;
            refinancedStudentLoanData[year][month].Balance = Balance;

            Payment = extraPayment;
            refinancedStudentLoanData[year][month].Payment = extraPayment;

            if ( Balance > 0 && annualInterestRate > 0 ) {
              Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              Interest = 0;
            }
            refinancedStudentLoanData[year][month].Interest = Interest;

            if ( Balance > 0 ) {
              Principal = Payment - Interest;
            } else {
              Principal = 0;
            }
            refinancedStudentLoanData[year][month].Principal = Principal;

            if ( Balance > 0 ) {
              Equity = Principal;
            } else {
              Equity = 0;
            }
            refinancedStudentLoanData[year][month].Equity = Equity;

            if ( Balance > 0 ) {
              TotalInterest = Interest;
            } else {
              TotalInterest = 0;
            }
            refinancedStudentLoanData[year][month].TotalInterest = TotalInterest;

            if ( Balance > 0 ) {
              TotalPayment = Equity + TotalInterest;
            } else {
              TotalPayment = 0;
            }
            refinancedStudentLoanData[year][month].TotalPayment = TotalPayment;
          } else { // Year Identifier
            refinancedStudentLoanData[year][month].YearIdentifier = year;
            // Balance
            let tempBalance;
            if ( Balance === 0 ) {
              tempBalance = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempBalance = ( Balance - Principal );
            } else {
              tempBalance = 0;
            }
            refinancedStudentLoanData[year][month].Balance = tempBalance;

            // Interest
            let tempInterest;
            if ( Balance === 0 ) {
              tempInterest = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              tempInterest = 0;
            }
            refinancedStudentLoanData[year][month].Interest = tempInterest;

            // Payment
            if ( ( Payment - tempInterest ) > tempBalance ) {
              Payment = tempBalance + tempInterest;
            } else {
              Payment = extraPayment;
            }
            refinancedStudentLoanData[year][month].Payment = Payment;

            // Principal
            let tempPrincipal;

            if ( Balance === 0 ) {
              tempPrincipal = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempPrincipal = Payment - tempInterest;
            } else {
              tempPrincipal = 0;
            }
            refinancedStudentLoanData[year][month].Principal = tempPrincipal;

            // Equity
            let tempEquity;
            if ( Balance === 0 ) {
              tempEquity = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempEquity = Equity + tempPrincipal;
            } else {
              tempEquity = 0;
            }
            refinancedStudentLoanData[year][month].Equity = tempEquity;

            // Total Interest
            let tempTotalInterest;
            if ( Balance === 0 ) {
              tempTotalInterest = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempTotalInterest = TotalInterest + tempInterest;
            } else {
              tempTotalInterest = 0;
            }
            refinancedStudentLoanData[year][month].TotalInterest = tempTotalInterest;

            // Total Payments
            let tempTotalPayment;
            if ( Balance === 0 ) {
              tempTotalPayment = 0;
            } else if ( ( Balance - Principal ) > 1 ) {
              tempTotalPayment = tempEquity + tempTotalInterest;
            } else {
              tempTotalPayment = 0;
            }
            refinancedStudentLoanData[year][month].TotalPayment = tempTotalPayment;

            // Assign Values
            Balance = tempBalance;
            Principal = tempPrincipal;
            Equity = tempEquity;
            TotalInterest = tempTotalInterest;
          }

          // Total Interest Paid Array
          totalInterestPaidArray.push( refinancedStudentLoanData[year][month].TotalInterest );

          // Total Paid Array
          totalPaidArray.push( refinancedStudentLoanData[year][month].TotalPayment );

          refinancedStudentLoanData[year].LaggingInterest = parseFloat( refinancedStudentLoanData[year].LaggingInterest ) + parseFloat( refinancedStudentLoanData[year][month].Interest );
          refinancedStudentLoanData[year].LaggingPrincipal = parseFloat( refinancedStudentLoanData[year].LaggingPrincipal ) + parseFloat( refinancedStudentLoanData[year][month].Principal );
          refinancedStudentLoanData[year]['Months Total Balance'] = parseFloat( refinancedStudentLoanData[year]['Months Total Balance'] ) + parseFloat( refinancedStudentLoanData[year][month].Balance );

          // Total Interest Paid
          if ( totalInterestPaidArray.length > 0 ) {
            totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
          }
          loanRepayment.totalInterestPaidValue = totalInterestPaid;

          // Total Paid
          if ( totalPaidArray.length > 0 ) {
            totalPaid = Math.max.apply( null, totalPaidArray );
          }
          loanRepayment.totalPaidValue = totalPaid;
        }
      }

      // Student Loan Data
      const refinancedStudentLoanData1 = {};

      let Balance1 = 0;
      let Payment1 = 0;
      let Interest1 = 0;
      let Principal1 = 0;
      let Equity1 = 0;
      let TotalInterest1 = 0;
      let TotalPayment1 = 0;

      const totalInterestPaidArray2 = [];
      const totalPaidArray2 = [];

      year = graduationYear;
      yearLimit = year + termsOfYear;

      for ( year; year <= yearLimit; year += 1 ) {
        refinancedStudentLoanData1[year] = {};

        refinancedStudentLoanData1[year].LaggingInterest = 0;
        refinancedStudentLoanData1[year].LaggingPrincipal = 0;
        refinancedStudentLoanData1[year]['Months Total Balance'] = 0;

        for ( let month = 1; month <= 12; month += 1 ) {
          refinancedStudentLoanData1[year][month] = {};

          if ( year === graduationYear && month === 1 ) {
            refinancedStudentLoanData1[year][month].YearIdentifier = year;

            Balance1 = beginningBalance;
            refinancedStudentLoanData1[year][month].Balance = Balance1;

            Payment1 = originalPayment;
            refinancedStudentLoanData1[year][month].Payment = originalPayment;

            if ( Balance1 > 0 && annualInterestRate > 0 ) {
              Interest1 = Balance1 * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              Interest1 = 0;
            }
            refinancedStudentLoanData1[year][month].Interest = Interest1;

            if ( Balance1 > 0 ) {
              Principal1 = Payment1 - Interest1;
            } else {
              Principal1 = 0;
            }
            refinancedStudentLoanData1[year][month].Principal = Principal1;

            if ( Balance1 > 0 ) {
              Equity1 = Principal1;
            } else {
              Equity1 = 0;
            }
            refinancedStudentLoanData1[year][month].Equity = Equity1;

            if ( Balance1 > 0 ) {
              TotalInterest1 = Interest1;
            } else {
              TotalInterest1 = 0;
            }
            refinancedStudentLoanData1[year][month].TotalInterest = TotalInterest1;

            if ( Balance1 > 0 ) {
              TotalPayment1 = Equity1 + TotalInterest1;
            } else {
              TotalPayment1 = 0;
            }
            refinancedStudentLoanData1[year][month].TotalPayment = TotalPayment1;
          } else { // Year Identifier
            refinancedStudentLoanData1[year][month].YearIdentifier = year;
            // Balance
            let tempBalance1;
            if ( Balance1 === 0 ) {
              tempBalance1 = 0;
            } else if ( ( Balance1 - Principal1 ) > 1 ) {
              tempBalance1 = ( Balance1 - Principal1 );
            } else {
              tempBalance1 = 0;
            }
            refinancedStudentLoanData1[year][month].Balance = tempBalance1;

            // Interest
            let tempInterest1;
            if ( Balance1 === 0 ) {
              tempInterest1 = 0;
            } else if ( ( Balance1 - Principal1 ) > 1 ) {
              tempInterest1 = tempBalance1 * ( ( annualInterestRate / 100 ) / 12 );
            } else {
              tempInterest1 = 0;
            }
            refinancedStudentLoanData1[year][month].Interest = tempInterest1;

            // Payment
            if ( Balance1 === 0 ) {
              Payment1 = 0;
            } else {
              Payment1 = originalPayment;
            }
            refinancedStudentLoanData1[year][month].Payment = Payment1;

            // Principal
            let tempPrincipal1;

            if ( Balance1 === 0 ) {
              tempPrincipal1 = 0;
            } else if ( ( Balance1 - Principal1 ) > 1 ) {
              tempPrincipal1 = Payment1 - tempInterest1;
            } else {
              tempPrincipal1 = 0;
            }
            refinancedStudentLoanData1[year][month].Principal = tempPrincipal1;

            // Equity
            let tempEquity1;
            if ( Balance1 === 0 ) {
              tempEquity1 = 0;
            } else if ( ( Balance1 - Principal1 ) > 1 ) {
              tempEquity1 = Equity1 + tempPrincipal1;
            } else {
              tempEquity1 = 0;
            }
            refinancedStudentLoanData1[year][month].Equity = tempEquity1;

            // Total Interest
            let tempTotalInterest1;
            if ( Balance1 === 0 ) {
              tempTotalInterest1 = 0;
            } else if ( ( Balance1 - Principal1 ) > 1 ) {
              tempTotalInterest1 = TotalInterest1 + tempInterest1;
            } else {
              tempTotalInterest1 = 0;
            }
            refinancedStudentLoanData1[year][month].TotalInterest = tempTotalInterest1;

            // Total Payments
            let tempTotalPayment1;
            if ( Balance1 === 0 ) {
              tempTotalPayment1 = 0;
            } else if ( ( Balance1 - Principal1 ) > 1 ) {
              tempTotalPayment1 = tempEquity1 + tempTotalInterest1;
            } else {
              tempTotalPayment1 = 0;
            }
            refinancedStudentLoanData1[year][month].TotalPayment = tempTotalPayment1;

            // Assign Values
            Balance1 = tempBalance1;
            Principal1 = tempPrincipal1;
            Equity1 = tempEquity1;
            TotalInterest1 = tempTotalInterest1;
          }

          // Total Interest Paid Array
          totalInterestPaidArray2.push( refinancedStudentLoanData1[year][month].TotalInterest );

          // Total Paid Array
          totalPaidArray2.push( refinancedStudentLoanData1[year][month].TotalPayment );

          refinancedStudentLoanData1[year].LaggingInterest = parseFloat( refinancedStudentLoanData1[year].LaggingInterest ) + parseFloat( refinancedStudentLoanData1[year][month].Interest );
          refinancedStudentLoanData1[year].LaggingPrincipal = parseFloat( refinancedStudentLoanData1[year].LaggingPrincipal ) + parseFloat( refinancedStudentLoanData1[year][month].Principal );
          refinancedStudentLoanData1[year]['Months Total Balance'] = parseFloat( refinancedStudentLoanData1[year]['Months Total Balance'] ) + parseFloat( refinancedStudentLoanData1[year][month].Balance );

          // Total Interest Paid
          if ( totalInterestPaidArray2.length > 0 ) {
            totalInterestPaid1 = Math.max.apply( null, totalInterestPaidArray2 );
          }
          loanRepayment.totalInterestPaidValue1 = totalInterestPaid1;

          // Total Paid
          if ( totalPaidArray2.length > 0 ) {
            totalPaid1 = Math.max.apply( null, totalPaidArray2 );
          }
          loanRepayment.totalPaidValue1 = totalPaid1;
        }
      }
    }

    // Summation Data
    let summationYear = 2019;
    const firstModuleYear = 2019;
    const summationYearLimit = summationYear + termsOfYear;

    // Summation Data
    const summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;

    const endingBalanceGraph = [];
    const paymentGraph = [];
    const interestGraph = [];
    const principalPaybackGraph = [];
    const yearsListGraph = [];

    // For Loop from Start year to +14 years
    for ( summationYear; summationYear < summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      // Beginning Balance
      if ( summationYear === firstModuleYear && loanAmount > 0 ) {
        summationBeginningBalance = refinancedStudentLoanData[summationYear][1].Balance;
      } else if ( summationYear > firstModuleYear ) {
        if ( loanAmount > 0 && typeof summationData[summationYear - 1] !== 'undefined' && typeof summationData[summationYear - 1].EndingBalance !== 'undefined' && summationData[summationYear - 1].EndingBalance > 0 ) {
          summationBeginningBalance = summationData[summationYear - 1].EndingBalance;
        } else {
          summationBeginningBalance = 0;
        }
      } else {
        summationBeginningBalance = 0;
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof refinancedStudentLoanData[summationYear] !== 'undefined' && typeof refinancedStudentLoanData[summationYear].LaggingInterest !== 'undefined' ) {
          LaggingVal1 = refinancedStudentLoanData[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( typeof refinancedStudentLoanData[summationYear] !== 'undefined' && typeof refinancedStudentLoanData[summationYear].LaggingPrincipal !== 'undefined' ) {
          summationLaggingPrincipal = refinancedStudentLoanData[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }

      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      // Total Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }
      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = Math.round( summationData[summationYear].BeginningBalance, 2 ) - Math.round( summationData[summationYear].LaggingPrincipal, 2 );
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;

      // Graph Data

      if ( summationEndingBalance > 1 || summationLaggingInterest > 1 || summationLaggingPrincipal > 1 || ( summationLaggingInterest + summationLaggingPrincipal ) > 1 ) {
        endingBalanceGraph.push( Math.round( summationEndingBalance, 2 ) );
        interestGraph.push( Math.round( summationLaggingInterest, 2 ) );
        principalPaybackGraph.push( Math.round( summationLaggingPrincipal, 2 ) );
        paymentGraph.push( summationLaggingInterest + summationLaggingPrincipal );
        yearsListGraph.push( summationYear );
      }
    }
    loanRepayment.secondParameter = loanRepayment.originalPaymentValue + extraLoanPaymentValues.extraPayment;
    loanRepayment.repaymentTime = nper( annualInterestRate / 12, loanRepayment.secondParameter, -( loanRepayment.loanAmountValue ), 0 ) / 12;
    loanRepayment.timeSaving = termsOfYear - loanRepayment.repaymentTime;

    loanRepayment.yearsListGraph = yearsListGraph;
    loanRepayment.endingBalanceGraphData = endingBalanceGraph;
    loanRepayment.paymentGraphData = paymentGraph;
    loanRepayment.interestGraphData = interestGraph;
    loanRepayment.principalPaybackGraphData = principalPaybackGraph;

    setCalculatedValues( loanRepayment );
    // eslint-disable-next-line
  }, [extraLoanPaymentValues]);

  const updateValue = ( e, field ) => {
    let newValue = 0;
    const valuesUpdated = { ...extraLoanPaymentValues };
    if ( field === 'interestRate' ) {
      newValue = ( e.value !== undefined && e.value !== '' ) ? e.value : 0;
    } else {
      newValue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    valuesUpdated[field] = newValue;
    setExtraLoanPaymentValues( valuesUpdated );
  };

  const setFixedDecimal = ( field ) => {
    const valuesUpdated = { ...extraLoanPaymentValues };
    valuesUpdated[field] = parseFloat( valuesUpdated[field] ).toFixed( 2 );
    setExtraLoanPaymentValues( valuesUpdated );
  };

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.bondProfitPage }>
        <div className={ classes.formHorizontal }>
          <div className={ classes.container }>
            <ul className={ classes.breadCrumbs }>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Extra Loan Payment</li>
            </ul>
            <div className={ classes.csBox }>
              <div className={ classes.blueBorder }>
                <div className={ classes.leftPanel }>
                  <table className={ classes.extraLoanTable }>
                    <tbody>
                      <tr>
                        <td className={ classNames( classes.extstingTable, classes.repaymentTable ) }>
                          <table>
                            <tbody>
                              <tr>
                                <th>
                                  <h3>Original Repayment Scenario</h3>
                                </th>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Loan Amount</td>
                              </tr>
                              <tr>
                                <td className={ classes.fieldData }>
                                  <div className={ classes.inputData }>
                                    <span>$</span>
                                    <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ extraLoanPaymentValues.loanAmount } onValueChange={ ( e ) => updateValue( e, 'loanAmount' ) } onFocus={ ( e ) => e.target.select() } />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Interest Rate</td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.percentField ) }>
                                  <div className={ classes.inputData }>
                                    <NumberFormat
                                      className={ classes.formInput }
                                      allowNegative={ false }
                                      customInput={ TextField }
                                      value={ extraLoanPaymentValues.interestRate }
                                      onValueChange={ ( e ) => updateValue( e, 'interestRate' ) }
                                      onBlur={ () => { setFixedDecimal( 'interestRate' ); } }
                                      onFocus={ ( e ) => e.target.select() }
                                    />
                                    <span>%</span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Term of the Loan</td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.percentField ) }>
                                  <div className={ classes.inputData }>
                                    <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ extraLoanPaymentValues.termOfTheLoan } onValueChange={ ( e ) => updateValue( e, 'termOfTheLoan' ) } onFocus={ ( e ) => e.target.select() } />
                                    <span>years</span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td>&nbsp;</td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classNames(classes.fieldData, classes.emptyInput ) }>&nbsp;</td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.borderTop }>
                                <td> Monthly Payment </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.originalPaymentValue } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td> Annual Paid (Principal &amp; Interest) </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.originalPaymentValue * 12 } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Total Interest Paid </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalInterestPaidValue1 } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>
                                  Total Repayment Amount
                                  <br />
                                  (Principal & Interest)
                                  {' '}
                                </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalPaidValue1 } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td className={ classes.extstingTable }>
                          <table className={ classes.makeTable }>
                            <tbody>
                              <tr>
                                <th><h3>Making Extra Payments Scenario</h3></th>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td> Loan Amount </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.loanAmountValue } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td> Interest Rate </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b>
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale value={ extraLoanPaymentValues.interestRate } displayType="text" suffix="%" />
                                  </b>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td> Repayment Time </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b>
                                    {Number.isNaN( calculatedValues.repaymentTime ) ? 0 : Number( calculatedValues.repaymentTime ).toFixed( 1 )}
                                    {' '}
                                    years
                                  </b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td> Extra Payment </td>
                              </tr>
                              <tr>
                                <td className={ classes.fieldData }>
                                  <div className={ classes.inputData }>
                                    <span>$</span>
                                    <NumberFormat className={ classes.formInput } allowNegative={ false } customInput={ TextField } thousandSeparator value={ extraLoanPaymentValues.extraPayment } onValueChange={ ( e ) => updateValue( e, 'extraPayment' ) } onFocus={ ( e ) => e.target.select() } />
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.borderTop }>
                                <td>New Monthly Payment</td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.newExtraPaymentValue } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Annual Paid (Principal & Interest)</td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.newExtraPaymentValue * 12 } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Total Interest Paid</td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalInterestPaidValue } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>
                                  Total Repayment Amount
                                  <br />
                                  (Principal & Interest)
                                </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ calculatedValues.totalPaidValue } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td className={ classNames( classes.extstingTable, classes.timeSavings ) }>
                          <table>
                            <tbody>
                              <tr>
                                <th><h3>Time and Money Savings</h3></th>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }> </tr>
                              <tr className={ classes.emptyInput }>
                                <td>&nbsp;</td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td>&nbsp;</td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td> Time Savings </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b>
                                    {Number.isNaN( calculatedValues.timeSaving ) ? 0 : Number( calculatedValues.timeSaving ).toFixed( 1 )}
                                    {' '}
                                    years
                                  </b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td>&nbsp;</td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  &nbsp;
                                </td>
                              </tr>
                              <tr>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.borderTop }>
                                <td> Monthly Payment Difference </td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField, classes.negativeValue ) }>
                                  <b>
                                    (
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ -( calculatedValues.originalPaymentValue - calculatedValues.newExtraPaymentValue ) } displayType="text" prefix="$" />
                                    )
                                  </b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Annual Paid Difference</td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField, classes.negativeValue ) }>
                                  <b>
                                    (
                                    <NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ -( ( calculatedValues.originalPaymentValue * 12 ) - ( calculatedValues.newExtraPaymentValue * 12 ) ) } displayType="text" prefix="$" />
                                    )

                                  </b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr>
                                <td>Total Interest Savings</td>
                              </tr>
                              <tr>
                                <td className={ classNames( classes.fieldData, classes.noField ) }>
                                  <b><NumberFormat allowNegative decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ ( calculatedValues.totalInterestPaidValue1 - calculatedValues.totalInterestPaidValue ) } displayType="text" prefix="$" /></b>
                                </td>
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                              <tr className={ classes.emptyInput }>
                                <td className={ classes.emptyData } />
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={ classes.graphRow }>
                  <h3>Lowering the Cost of a Loan with Extra Payments</h3>
                  <div className={ classes.graphImage }>
                    <HighchartsReact
                      highcharts={ Highcharts }
                      options={ {
                        chart: {
                          plotBackgroundColor: '#ffffff',
                          plotBorderColor: '#cccccc',
                          plotBorderWidth: 1,
                          plotShadow: false,
                          type: 'column',
                          // height: windowHeight,
                          // width: graphMainWidth
                        },
                        credits: {
                          enabled: false,
                        },
                        navigation: {
                          menuItemStyle: {
                            fontSize: '14px',
                            textAlign: 'left',
                          },
                          menuItemHoverStyle: {
                            background: '#f5f5f5',
                            color: '#4c4c4c',
                            fontSize: '14px',
                          },
                          buttonOptions: {
                            height: 40,
                            width: 48,
                            symbolSize: 24,
                            symbolX: 24,
                            symbolY: 21,
                            symbolStrokeWidth: 2,
                            verticalAlign: 'bottom',
                            _titleKey: 'y',
                          },
                        },
                        exporting: {
                          buttons: {
                            contextButton: {
                              menuItems: ['downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                            },
                          },
                        },
                        title: {
                          text: '',
                          enabled: true,
                        },
                        xAxis: {
                          categories: calculatedValues.yearsListGraph,
                          labels: {
                            style: {
                              color: '#000000',
                              fontSize: '14px',
                            },
                          },
                        },
                        yAxis: [{ // Primary yAxis
                          min: 0,
                          // tickInterval: 10000,
                          labels: {
                            formatter() {
                              const chart = this;
                              if ( chart.value < 0 ) {
                                return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart.value ) )})</span>`;
                              }
                              return formatDollar( Math.round( chart.value ) );
                            },
                            style: {
                              color: '#000000',
                              fontSize: '14px',
                            },
                          },
                          title: {
                            text: 'Income & Expenses',
                            enabled: false,
                            style: {
                              color: '#000000',
                              fontSize: '14px',
                            },
                          },
                        }, { // Tertiary yAxis
                          /* min: 0,
                          tickInterval: 10000,
                          gridLineWidth: 0, */
                          title: {
                            text: 'Net Worth',
                            enabled: false,
                            style: {
                              color: '#000000',
                              fontSize: '14px',
                            },
                          },
                          labels: {
                            formatter() {
                              const chart1 = this;
                              if ( chart1.value < 0 ) {
                                return `<span style="color:#bf0000;">(${formatDollar( -Math.round( chart1.value ) )})</span>`;
                              }
                              return formatDollar( Math.round( chart1.value ) );
                            },
                            style: {
                              color: '#000000',
                              fontSize: '14px',
                            },
                          },
                          opposite: true,
                        }],
                        tooltip: {
                          headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                          crosshairs: true,
                          shared: true,
                          valueDecimals: 1,
                          valueSuffix: '%',
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                        plotOptions: {
                          column: {
                            stacking: 'normal',
                          },
                        },
                        legend: {
                          itemStyle: {
                            fontSize: '14px',
                          },
                        },
                        series: [{
                          name: 'Ending Balance',
                          yAxis: 0,
                          data: calculatedValues.endingBalanceGraphData,
                          stack: 'male',
                          color: '#0069AA',
                        }, {
                          name: 'Payment',
                          yAxis: 0,
                          data: calculatedValues.paymentGraphData,
                          stack: 'female',
                          color: '#A6A6A6',
                        }, {
                          name: 'Interest',
                          type: 'spline',
                          yAxis: 1,
                          data: calculatedValues.interestGraphData,
                          stack: 'female',
                          color: '#FFC000',
                        }, {
                          type: 'spline',
                          name: 'Principal Payback',
                          yAxis: 1,
                          data: calculatedValues.principalPaybackGraphData,
                          color: '#ED7D31',
                        }],
                         responsive: {
                          rules: [{
                            condition: {
                              maxWidth: 500
                            },
                            chartOptions: {
                              legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                              }
                            }
                          }]
                        },
                      } }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

ExtraLoanPayment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( ExtraLoanPayment );
