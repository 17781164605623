import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import aboutGraph from '../../assets/img/about-us/green-hand-drawn-line-login.png';
import aboutLogo from '../../assets/img/about-us/inkwiry-final-rev.png';
import pwi from '../../assets/img/about-us/pwi.jpg';
import PF101IconSquare from '../../assets/img/about-us/PF101-icon-square.png';
import MCSIconSquare from '../../assets/img/about-us/MCS-icon-square.png';
import financeExplainedGreen from '../../assets/img/about-us/finance-explained-green.png';
import dashboardPhone from '../../assets/img/about-us/dashboard-phone.png';

const Aboutus = ( props ) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.aboutPage }>
        <div className={ classes.aboutBanner }>
          <div className={ classes.bannerContent }>
            <figure>
              <img src={ aboutGraph } alt="" />
            </figure>
            <div className={ classes.bannerCaption }>
              <div className={ classes.container }>
                <div className={ classes.aboutLogo }>
                  <img src={ aboutLogo } alt="" />
                </div>
                <div className={ classes.bannerTitle }>
                  <Typography variant="h2" component="h2">We want you to be the <span> CEO of your own life</span></Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={ classes.betterLives }>
          <div className={ classes.container }>
            <Typography variant="h3" component="h3">
              <span>Inkwiry is a financial technology company</span>
              <span>that builds applications and tools to make</span>
              <span>personal financial life planning fast, easy and</span>
              <span>affordable, changing lives for the better.</span>
            </Typography>
          </div>
        </div>
        <div className={ classes.businessPlan }>
          <div className={ classes.container }>
            <Typography variant="h2" component="h2">Businesses plan, and people should, too </Typography>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 5 }>
                  <p>Yet most Americans have no savings for emergencies or retirement. In fact, 63% of Americans don't have enough savings to pay for a $500 to $1,000 emergency.</p>
                  <p>Inkwiry brings business intelligence to personal financial life planning via integrated applications and tools so young people can make smart moves in life and reach their individual goals.</p>
                  <p>Inkwiry takes you on a virtual journey to the future so you can map out your best course today.</p>
                </Grid>
                <Grid item xs={ 12 } md={ 7 }>
                  <div className={ classes.quoteCover }>
                    <video width="100%" preload="auto" muted autoPlay="true" loop playsinline> 
                      <source src="https://inkwiry-videos.s3.us-east-2.amazonaws.com/about/quick-tour-0-21.mp4" type="video/mp4" />
                    </video>
                  </div>
                </Grid>
              </Grid>
          </div>
        </div>
        <div className={ classes.weTeach }>
          <div className={ classes.container }>
            <Grid container spacing={ 2 } className={ classes.gridWidth }>
              <Grid item xs={ 12 } md={ 3 }>
                <div className={ classes.weteachImages }>
                  <div className={ classes.teachImages }>
                    <img src={ pwi } alt="" />
                  </div>
                  <div className={ classes.teachImages }>
                    <img src={ PF101IconSquare } alt="" />
                  </div>
                  <div className={ classes.teachImages }>
                    <img src={ MCSIconSquare } alt="" />
                  </div>
                  <div className={ classes.teachImages }>
                    <img src={ financeExplainedGreen } alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={ 12 } md={ 9 }>
                <div className={ classes.weteachCnt }>
                  <Typography variant="h2" component="h2">And we teach you how to do it</Typography>
                  <p>Financial literacy skills and knowledge are rarely taught in schools or in the home. Sadly, the same is true of financial life planning. To work toward a solution, Inkwiry steps in with four offerings:</p>
                  <p><b>Plan With Inkwiry</b>  facilitates financial life planning 5, 10, 15 years into the future. Users enter their data and the Financial Configurator does the rest. </p>
                  <p><b>In Personal Finance 101,</b>  users gain fundamental money skills and knowledge for life via an online, gamified experience. </p>
                  <p><b>My Career Sketch</b>  jumpstarts a financial life plan and reveals surprising strategies that can supercharge financial moves, helping users add $100k to their 15-year net worth.</p>
                  <p><b>Finance Explained</b>  is a free email newsletter that tackles one topic per week, defining terms and helping readers get smarter about their money.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={ classes.beganWithQuestion }>
          <div className={ classes.container }>
            <Typography variant="h2" component="h2">Inkwiry began with a question</Typography>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 5 }>
                <div className={ classes.fiveBrothers }>
                  <Typography variant="h3" component="h3">
                    <span>Five brothers. A scrum of tough</span>
                    <span>competition, brutal criticism,</span>
                    <span>mutual respect, close</span>
                    <span>collaboration, everlasting support</span>
                    <span>and unconditional love.</span>
                  </Typography>
                  <p>One day, Chris, a junior majoring in architecture and second oldest of the five, aired an observation about students: "We don't understand the world. We don't understand finance, and we have no idea how to connect our education to the real world." This sparked the question that became a topic at many dinner table discussions: How can we fix this?</p>
                </div>
              </Grid>
              <Grid item xs={ 12 } md={ 7 }>
                <div className={ classes.homeBuyers }>
                  <p>Nick, the oldest, with boots on the ground helping homebuyers obtain mortgages, gained special insight into applicants' financial details. His real world experience confirmed something was wrong — most young people lacked basic financial literacy and an actionable plan.</p>
                  <p><Link to="/">James,</Link>  a finance major from Villanova's School of Business who worked as a Leveraged Finance Investment Banker in New York City, took the lead in developing the Financial Configurator.</p>
                  <p>Ultimately, it required a team of more than a dozen people of diverse skills and backgrounds to strategize, design, code and bring the application to life, providing a platform to help those with a mindset for achievement and a desire for financial freedom.</p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={ classes.csBluePrint }>
          <div className={ classes.container }>
            <div className={ classes.sketchBluePrint }>
              <video width="100%" preload="auto" muted autoPlay="true" loop playsinline> 
                <source src="https://inkwiry-videos.s3.us-east-2.amazonaws.com/about/quick-tour-53-109.mp4" type="video/mp4" />
              </video>
            </div>
            <Typography variant="h2" component="h2">Complex planning, simplified</Typography>
            <Typography variant="h3" component="h3">
              <span>No prior financial knowledge is needed. Inkwiry delivers choice bits of</span>
              <span>info right when you need them during the planning process. Just enter</span>
              <span>your details and Inkwiry's Financial Configurator runs the data.</span>
            </Typography>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 6 } className={ classes.blueprintLeft }>
                <p>
                  <span>At Inkwiry, we not only have groundbreaking ideas,</span>
                  <span>we provide revolutionary technology, shaped by collaboration and crafted to deliver a unique experience to each individual.</span>
                  
                </p>
                <p>
                  <span>Your experience is powered by Inkwiry's Simulator Engine, running an enormity of code to generate a matrix of data that reacts to your inputs at the speed </span>
                  <span>of thought. This is the strength of an engine</span>
                </p>
              </Grid>
              <Grid item xs={ 12 } md={ 6 } className={ classes.blueprintRight }>
                <p>you will never see, yet it is working with you, side-by-side, to help you simulate and model your assumptions in real time to develop a viable financial life plan</p>
                <p>Technically, this is now known as "Dynamic Modular Finance with Active Data Output." Practically, it simplifies and powers your planning at the speed of thought.</p>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={ classes.intrestInSucceed }>
          <div className={ classes.container }>
            <Typography variant="h2" component="h2">Our interest is in helping you succeed</Typography>
            <Grid container spacing={ 2 } className={ classes.intrestSection }>
              <Grid item xs={ 12 } md={ 8 } className={ classes.gridPad }>
                <div className={ classes.intrestSectionLeft }>
                  <img src={ dashboardPhone } alt="" />
                </div>
              </Grid>
              <Grid item xs={ 12 } md={ 4 } className={ classes.gridPad }>
                <div className={ classes.intrestSectionRight }>
                  <Typography variant="h4" component="h4">Inkwiry is independent, so you can be. We are free of any conflicts of intrest, autonomous, and show no favoritism to any educational or financial institution.</Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 6 } className={ classes.rightSpace }>
                <p>We do not allow advertising on our site, and you will never find Inkwiry giving special preference to any institution. We have no vested interest in the outcome of the strategies and plans you build in Inkwiry, as long as they place you and your priorities first. We believe that all information, costs and benefits should be evaluated fairly, and a true qualitative outlook requires an evenhanded assessment of all options.</p>
              </Grid>
              <Grid item xs={ 12 } md={ 6 } className={ classes.leftSpace }>
                <p>Our staff adheres to a clear policy prohibiting the presence or even the appearance of conflicts-of-interest in our procedures. We respect and protect our clients' privacy, and we will never sell private data or share any personal information with third parties.</p>
                <p>It's your best interest that we have in mind. </p>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={ classes.leadershipTeam }>
          <div className={ classes.container }>
            <div className={ classes.leaderRow }>
              <Typography variant="h2" component="h2">Learn more about our leadership team</Typography>
              <Grid container spacing={ 2 } className={ classes.gridFlex }>
                <Grid item xs={ 12 } md={ 6 }>
                  <p className={ classes.leaderRowPara }>Curious about who's behind this revolutionary new application, especially James? We pull back the curtain on our team page.</p>
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  <div className={ classes.goTeam }>
                    <Link to="/">Go Team</Link>
                  </div>
                </Grid>
              </Grid>
              <p className={ classes.seeText }>See and build your best tomorrows now. </p>
            </div>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </Typography>
  );
};

Aboutus.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles( styles )( Aboutus );
