import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';
import Highcharts from 'highcharts';



export function familyShowMeGraphData() {
  let graphsData = {};
  const familyOutputData = moduleServices.familyOutputGraphData();
  graphsData['advValues'] = 0;
  if (familyOutputData !== undefined && familyOutputData['Pie Chart'] !== undefined && familyOutputData['Pie Chart']['Total'] !== undefined) {
    graphsData['advValues'] = familyOutputData['Pie Chart']['Total'];
    graphsData['totalValues'] = 191910;

  }
  //Bar Graph1

  if (familyOutputData['Bar Graph'] !== undefined && familyOutputData['Bar Graph']['YearsList'] !== undefined && familyOutputData['Bar Graph']['YearsList'].length > 0) {
    graphsData['graph1'] = true;
    graphsData['graph1Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      title: {
        text: ''
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: familyOutputData['Bar Graph']['YearsList'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        }
      }],
      tooltip: {
        formatter() {
          const chart = this;
          return '<b>' + chart.x + '</b><br/>' +
            chart.series.name + ': $' + chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + '<br/>' +
            'Total: $' + chart.point.stackTotal.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        }
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Rental Income',
        data: familyOutputData['Bar Graph']['Net Rental Income'],
        stack: 'male',
        color: '#DEECF6'
      }, {
        name: 'Spouse\'s Bonus / Tips / Commission',
        data: familyOutputData['Bar Graph']['Spouse Bonus / Tips / Commission'],
        stack: 'male',
        color: '#FFC000'
      }, {
        name: 'Spouse\'s Income',
        data: familyOutputData['Bar Graph']['Spouse Income'],
        stack: 'male',
        color: '#8FC5E4'
      }, {
        name: 'Supplementary Income',
        data: familyOutputData['Bar Graph']['Supplementary Income'],
        stack: 'male',
        color: '#C2E2AF'
      }, {
        name: 'Bonus / Tips / Commission',
        data: familyOutputData['Bar Graph']['Bonus / Tips / Commission'],
        stack: 'male',
        color: '#58AD4B'
      }, {
        name: 'Base Income',
        data: familyOutputData['Bar Graph']['Base Income'],
        stack: 'male',
        color: '#006FBB'
      }, {
        name: 'Loan Payments',
        data: familyOutputData['Bar Graph']['Interest Expenses'],
        stack: 'female',
        color: '#636363'
      }, {
        name: 'Living Expenses',
        data: familyOutputData['Bar Graph']['Living Expenses'],
        stack: 'female',
        color: '#C55A11'
      }, {
        name: 'Retirement Contributions',
        data: familyOutputData['Bar Graph']['Retirement Contributions'],
        stack: 'female',
        color: '#C3C0B9'
      }, {
        name: 'Taxes',
        data: familyOutputData['Bar Graph']['Taxes'],
        stack: 'female',
        color: '#7C7974'
      }]
    }
  } else {
    graphsData['graph1'] = false;
  }

  if (familyOutputData['Pie Chart'] !== undefined) {

    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotShadow: false,
        type: 'pie',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      title: {
        text: ''
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      tooltip: {
        // eslint-disable-next-line
        pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
        // eslint-disable-next-line
        format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              fontSize: '14px'
            }
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: moduleServices.module2Data.name_of_college,
        type: 'pie',
        data: [
          ['Tuition Costs', familyOutputData['Pie Chart']['totalTuitionCostsValue']],
          ['Rent', familyOutputData['Pie Chart']['totalRentValue']],
          ['Utilities', familyOutputData['Pie Chart']['totalUtilitiesValue']],
          ['Food', familyOutputData['Pie Chart']['totalFoodValue']],
          ['Clothing', familyOutputData['Pie Chart']['totalClothingValue']],
          ['Entertainment', familyOutputData['Pie Chart']['totalEntertainmentValue']],
          ['Technology', familyOutputData['Pie Chart']['totalTechnologyValue']],
          ['Transportation', familyOutputData['Pie Chart']['totalTransportationValue']],
          ['Car', familyOutputData['Pie Chart']['totalCarValue']],
          ['Gas', familyOutputData['Pie Chart']['totalGasValue']],
          ['Miscellaneous', familyOutputData['Pie Chart']['totalMiscellaneousValue']],
          ['Health Insurance', familyOutputData['Pie Chart']['totalHealthInsuranceValue']],
          ['Children', familyOutputData['Pie Chart']['totalChildrenValue']],
          ['Pet', familyOutputData['Pie Chart']['totalPetValue']],
          ['Home Expenses', familyOutputData['Pie Chart']['totalHomePropertyExpensesValue']],
          ['Rental Property Expenses', familyOutputData['Pie Chart']['totalPropertyTaxesValue']]
        ].filter( (d)=> {
          return d[1] > 0
        })
      }]
    }
  } else {
    graphsData['graph2'] = false;
  }
  let familyPieChartData = [55200, 33030, 28680, 10770, 17820, 31770, 14640];

  graphsData['graph3'] = true;
  graphsData['graph3Data'] = {
    chart: {
      plotBackgroundColor: '#ffffff',
      plotShadow: false,
      type: 'pie',
      // width: graphMainWidth,
      // height: moduleGraphHeight
    },
    title: {
      text: ''
    },
    navigation: {
      menuItemStyle: {
        fontSize: '14px',
        textAlign: 'left'
      },
      menuItemHoverStyle: {
        background: '#f5f5f5',
        color: '#4c4c4c',
        fontSize: '14px'
      },
      buttonOptions: {
        height: 40,
        width: 48,
        symbolSize: 24,
        symbolX: 24,
        symbolY: 21,
        symbolStrokeWidth: 2,
        _titleKey: 'y'
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [{
            textKey: 'downloadPNG',
            onclick() {
              const chart = this;
              chart.exportChart();
            },
          }, {
            textKey: 'downloadJPEG',
            onclick() {
              const chart = this;
            chart.exportChart({
                type: 'image/jpeg'
              });
            }
          }, {
            textKey: 'downloadPDF',
            onclick() {
              const chart = this;
            chart.exportChart({
                type: 'application/pdf'
              });
            }
          }, {
            textKey: 'downloadSVG',
            onclick() {
              const chart = this;
            chart.exportChart({
                type: 'image/svg+xml'
              });
            }
          }]
        }
      }
    },
    credits: {
      enabled: false
    },
    tooltip: {
        // eslint-disable-next-line
        pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
        // eslint-disable-next-line
        format: '{point.name}, ${point.y:,.0f}',
          style: {
            color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
            fontSize: '14px',
            width: '150px'
          }
        }
      }
    },
    series: [{
      name: moduleServices.module2Data.name_of_college,
      type: 'pie',
      data: [
        ['Housing', familyPieChartData[0]],
        ['Food', familyPieChartData[1]],
        ['Transportation', familyPieChartData[2]],
        ['Clothing', familyPieChartData[3]],
        ['Healthcare', familyPieChartData[4]],
        ['Child Care and Education', familyPieChartData[5]],
        ['Other', familyPieChartData[6]],
      ].filter( (d)=> {
        return d[1] > 0
      })
    }]
  }


  if (familyOutputData['Spouse Bar Graph'] !== undefined && familyOutputData['Spouse Bar Graph']['YearsList'] !== undefined && familyOutputData['Spouse Bar Graph']['YearsList'].length > 0) {
    graphsData['graph4'] = true;
    let spouseMonthlyPaymentDataValue = moduleServices.spouseMonthlyPaymentInterest();
    spouseMonthlyPaymentDataValue = spouseMonthlyPaymentDataValue.toFixed(0);
    graphsData['graph4Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        zoomType: false,
        // height: moduleGraphHeight,
        width: 1050,
        events: {
          load() {
            const chart = this;
            const text = chart.renderer.text(
              '<strong>Monthly Payment: ' + formatDollar(Math.round(spouseMonthlyPaymentDataValue)) + '</strong>',
              chart.plotLeft + 30,
              chart.plotTop + 35
            ).attr({
              zIndex: 5
            }).add(),
              box = text.getBBox();

            chart.renderer.rect(box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0)
              .attr({
                fill: '#FFFFFF',
                stroke: '#2f75b5',
                'stroke-width': 1,
                zIndex: 4
              })
              .add();
          },
        },
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
              chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: [{
        categories: familyOutputData['Spouse Bar Graph']['YearsList'],
        crosshair: true,
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      yAxis: [{ // Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Principal and Interest',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: 'Student Loan Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }],
      tooltip: {},
      credits: {
        enabled: false
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      series: [{
        name: 'Student Loan Balance',
        type: 'column',
        yAxis: 1,
        data: familyOutputData['Spouse Bar Graph']['Student Loan Balance'],
        tooltip: {
          valuePrefix: '$'
        }
      }, {
        name: 'Payment',
        type: 'column',
        yAxis: 1,
        data: familyOutputData['Spouse Bar Graph']['Payment'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#ed7d31'
      }, {
        name: 'Principal Payback',
        type: 'spline',
        yAxis: 0,
        data: familyOutputData['Spouse Bar Graph']['Principal Payback'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Interest',
        type: 'spline',
        yAxis: 0,
        data: familyOutputData['Spouse Bar Graph']['Interest'],
        marker: {
          enabled: false
        },
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }]
    }
  } else {
    graphsData['graph4'] = false;
  }

  return graphsData;
}


