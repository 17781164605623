import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import queryString from 'query-string';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import styles from './styles';
import powerUpLogo from '../../../../../assets/img/pf101/powerup.png';
import logoImage from '../../../../../assets/img/pf101/dashboard-logo.png';
import reviewImage from '../../../../../assets/img/pf101/review.png';
import surveyImage from '../../../../../assets/img/pf101/pf101-wrapup-survey2.png';
import certificateImage from '../../../../../assets/img/pf101/PF101-certificate-image.png';
import finalImage from '../../../../../assets/img/pf101/pf101-challenge-final.png';
import Pageloader from '../../../../../components/ui/pageloader';
import pf101FinancialTookit from '../../../../../assets/img/pf101/PF101_Financial_Tookit.pdf';

const WRAPUPDETAILS = loader( '../../../../../graphql/schema/pf101/get-wrap-up-details.graphql' );
const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const DOWNLOAD_CERTIFICATE = loader( '../../../../../graphql/schema/pf101/download-certificate.graphql' );

const Pf101WrapUp = ( props ) => {
  const { classes, location } = props;

  const params = queryString.parse( location.search );

  const [loading, setLoading] = React.useState( true );
  const [step, setStep] = React.useState( 0 );
  const [school, setSchool] = React.useState( false );
  const [downloadToolkit, setDownloadToolkit] = React.useState( false );
  const [surveyCompleted, setSurveyCompleted] = React.useState( false );
  const [challengeCompleted, setChallengeCompleted] = React.useState( false );
  const [certificateDownladed, setCertificateDownladed] = React.useState( false );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( ) {
      return false;
    },
    onError( ) {
      return false;
    },
  } );

  const saveExerciseDetails = ( number ) => {
    saveExercise( {
      variables: {
        data: {
          level: 10,
          exercise: number,
          exercise_time: 0,
          points: 0,
          total_attempts: 1,
          questionData: [{ id: 1, question_attempts: 1, xp_earned: 0 }],
        },
      },
    } );
  };

  const [DOWNLOADCERTIFICATE] = useLazyQuery( DOWNLOAD_CERTIFICATE, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if ( response.downloadPf101Certificate.status === true ) {
        setCertificateDownladed( true );
        window.open( response.downloadPf101Certificate.url );
      }
      setLoading( false );
      saveExerciseDetails( 2 );
    },
    onError( ) {
      setLoading( false );
      return false;
    },
  } );

  useQuery( WRAPUPDETAILS, {
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      if ( params.revisit !== undefined && params.revisit !== '' ) {
        if ( params.revisit === 'final' ) {
          if ( response.pf101FinanciaLifeToolkitDetails.downloadToolKit !== true ) {
            setStep( 1 );
          } else if ( response.pf101FinanciaLifeToolkitDetails.surveyCompleted !== true ) {
            setStep( 2 );
          } else if ( response.pf101FinanciaLifeToolkitDetails.school === true ) {
            setStep( 3 );
          } else {
            setStep( 4 );
          }
        } else if ( params.revisit === 'download' ) {
          if ( response.pf101FinanciaLifeToolkitDetails.downloadToolKit !== true ) {
            setStep( 1 );
          } else if ( response.pf101FinanciaLifeToolkitDetails.surveyCompleted !== true ) {
            setStep( 2 );
          } else if ( response.pf101FinanciaLifeToolkitDetails.school === true && response.pf101FinanciaLifeToolkitDetails.challengeCompleted !== true ) {
            setStep( 3 );
          } else {
            setStep( 4 );
          }
        } else {
          setStep( 1 );
        }
      } else {
        setStep( 1 );
      }
      setSchool( response.pf101FinanciaLifeToolkitDetails.school );
      setDownloadToolkit( response.pf101FinanciaLifeToolkitDetails.downloadToolKit );
      setSurveyCompleted( response.pf101FinanciaLifeToolkitDetails.surveyCompleted );
      setChallengeCompleted( response.pf101FinanciaLifeToolkitDetails.challengeCompleted );
      setCertificateDownladed( response.pf101FinanciaLifeToolkitDetails.certificateDownladed );
      setLoading( false );
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const nextSlide = ( number ) => {
    if ( number === 2 ) {
      setStep( 2 );
    } else if ( number === 3 && school === true ) {
      setStep( 3 );
    } else {
      setStep( 4 );
    }
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.dashboardPage }>
        <div className={ classes.containerFluid }>
          <div className={ classes.container }>
            <div className={ classes.dashboardLogo }>
              <Link to="/desktop">
                <img src={ logoImage } alt="logo" />
              </Link>
            </div>
          </div>
          <div className={ classes.container }>
            {step === 1 && (
              <Grid container>
                <Grid item xs={ 12 } md={ 12 }>
                  <div className={ classes.row }>
                    <div className={ classes.welcomePageThree }>
                      <div className={ classNames( classes.mainLogo, classes.nologoPadding ) }>
                        <Link to="/pf101/dashboard">
                          <img src={ powerUpLogo } alt="logo" />
                        </Link>
                      </div>
                      <h3 className={ classes.screenTitle }>Download Your Financial Life Toolkit</h3>
                      <div className={ classes.certificateScreen }>
                        <div className={ classNames( classes.challengeImg, classes.imageTopSpace ) }>
                          <img src={ reviewImage } alt="review" />
                        </div>
                        <div>
                          <p>Congratulations on growing your personal finance skills and knowledge and beginning your journey to financial independence. To make sure you are properly equipped for the future, we&apos;ve developed a Financial Life Toolkit you can take with you wherever you go.</p>
                          <p>Personal finance may be one of the few skills you will use every day. These 25 financial life strategies, tips, and reminders will always be by your side as you take your next steps in Life.</p>
                        </div>
                      </div>
                      <div className={ classes.challengeBtn }>
                        <a href={ pf101FinancialTookit } download className={ classes.nextButton } onClick={ () => { setDownloadToolkit( true ); saveExerciseDetails( 1 ); } }>Download My Toolkit</a>
                        {downloadToolkit === true && <Button className={ classes.nextButton } onClick={ () => { nextSlide( 2 ); } }>NEXT</Button>}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {step === 2 && (
              <Grid container>
                <Grid item xs={ 12 } md={ 12 }>
                  <div className={ classes.row }>
                    <div className={ classes.welcomePageThree }>
                      <div className={ classes.mainLogo }>
                        <Link to="/pf101/dashboard">
                          <img src={ powerUpLogo } alt="logo" />
                        </Link>
                      </div>
                      <h3 className={ classes.screenTitle }>Take a Short Survey</h3>
                      <div className={ classes.certificateScreen }>
                        <div className={ classes.challengeImg }>
                          <img src={ surveyImage } alt="review" />
                        </div>
                        <div>
                          <p>Congratulations on finishing Personal Finance 101 and beginning your journey to financial freedom.</p>
                          <p>This survey will take less than three minutes to complete. We appreciate your honest feedback. Rest assured that all answers you provide will be strictly anonymous.</p>
                        </div>
                      </div>
                      <div className={ classes.challengeBtn }>
                        {school === true ? ( <Link className={ classes.nextButton } to="/pf101/student/survey">START</Link> ) : ( <Link className={ classes.nextButton } to="/pf101/member/survey">START</Link> )}
                        {surveyCompleted === true && <Button className={ classes.nextButton } onClick={ () => { nextSlide( 3 ); } }>NEXT</Button>}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {step === 3 && (
              <Grid container>
                <Grid item xs={ 12 } md={ 12 }>
                  <div className={ classes.row }>
                    <div className={ classes.welcomePageThree }>
                      <div className={ classes.mainLogo }>
                        <Link to="/pf101/dashboard">
                          <img src={ powerUpLogo } alt="logo" />
                        </Link>
                      </div>
                      <h3 className={ classes.screenTitle }>Take the Final Challenge</h3>
                      <div className={ classes.certificateScreen }>
                        <div className={ classes.challengeImg }>
                          <img src={ finalImage } alt="review" />
                        </div>
                        <div>
                          <p>You completed Personal Finance 101 and now have a financial life toolkit filled with skills and knowledge.</p>
                          <p>But how much better do you know money than when you started on this journey?</p>
                          <p>Let&apos;s find out in the Final Challenge. </p>
                        </div>
                      </div>
                      <div className={ classes.challengeBtn }>
                        <Link className={ classes.nextButton } to="/pf101/student-final-challenge">Start</Link>
                        {challengeCompleted === true && <Button className={ classes.nextButton } onClick={ () => { nextSlide( 4 ); } }>NEXT</Button>}
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {
              step === 4 && (
                <Grid container>
                  <Grid item xs={ 12 } md={ 12 }>
                    <div className={ classes.row }>
                      <div className={ classNames( classes.welcomePageThree, classes.finalToolkit ) }>
                        <div className={ classes.mainLogo }>
                          <Link to="/pf101/dashboard">
                            <img src={ powerUpLogo } alt="logo" />
                          </Link>
                        </div>
                        <h3 className={ classes.screenTitle }>Download Your Certificate of Completion</h3>
                        <div className={ classNames( classes.certificateScreen, classes.newCertificate ) }>
                          <div className={ classNames( classes.challengeImg, classes.newChallengeImage ) }>
                            <div className={ classes.galleryImage }>
                              <img src={ certificateImage } alt="review" />
                            </div>
                          </div>
                          <div>
                            <p>Financial literacy has the power to change your life. 87% of Americans agree that nothing makes them happier or more confident than feeling like their finances are in order. Money can&apos;t buy happiness but having your personal finances in order can bring confidence and peace of mind.</p>
                            <p>Your Certificate of Completion is evidence of your financial life achievements. Please download your certificate - congratulations and good luck on your journey.</p>
                          </div>
                        </div>
                        <div className={ classes.challengeBtn }>
                          <Button className={ classes.nextButton } onClick={ () => { DOWNLOADCERTIFICATE(); setLoading( true ); } }>DOWNLOAD MY CERTIFICATE</Button>
                          {certificateDownladed === true && <Link className={ classes.nextButton } to="/pf101/dashboard">FINISH</Link>}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )
            }
          </div>
        </div>
      </div>
    </Typography>
  );
};

Pf101WrapUp.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Pf101WrapUp );
