import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import styles from './styles';
import logoImage from '../../../../assets/img/mcs/my-career-sketch.png';
import logoInkwiry from '../../../../assets/img/mcs/logo.png';
import Pageloader from '../../../../components/ui/pageloader';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import tickMark from '../../../../assets/img/mcs/green-check-discovery.png';
import bookImage from '../../../../assets/img/mcs/icon-book-rev.png';
import stepStones from '../../../../assets/img/mcs/stepstones.png';
const MCS_LEVEL_ACTIVITY_DATA = loader('../../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const MY_DETAILS = loader('../../../../graphql/schema/auth/me.graphql');

const McsLevelThreeActivityOne = (props) => {
    const { classes } = props;
    const { data: myDetails } = useQuery(MY_DETAILS);

    const [loading] = React.useState(false);
    const [bookSection, setBookSection]=React.useState(false);

    const [levelList, setLevelList] = React.useState([]);

    const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
        variables: {
            level_id: "3",
        },
        fetchPolicy: 'network-only',
        onCompleted() {
            setLevelList(mcsLevelWiseData.getMCSLevelActivityData.level_activity);
        },
        onError() {
        },
      });

    return (
        <Typography variant="body1" component="div">
            {loading && <Pageloader loading={loading} />}
            <div>                
                <div className={classes.mcsDevelopVision }>                    
                    <div className={classes.topRow }>
                        <div className={classes.mcsLogoImage}>
                            <Link to="/my-career-sketch/dashboard">
                                <img src={logoImage} alt="logo" />
                            </Link>
                        </div>
                        <div className={ classes.activityStatus }>
                            <h4><span className={ classes.greenTick }><img src={ tickMark } alt="tick" /></span>ACTIVITY COMPLETE! BACK TO <a href="/my-career-sketch/level3">LEVEL 03</a></h4>
                        </div>
                        <ReactTooltip id="book" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
                        <div className={ classes.bookBlock }>                            
                            <div className={ classes.activityImage } aria-hidden="true" onClick={()=>{setBookSection(true)}} data-for="book" data-tip="Activity Tracker">
                                <img src={ bookImage } alt="book" />                                                        
                            </div>
                            {bookSection && (
                            <div className={ classes.reviewLevels }>
                                <i className="fa fa-times" onClick={()=>{setBookSection(false)}} />
                                <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>1.</span>
                                            <span>Find your career path</span>
                                        </span>
                                        {levelList[0] && levelList[0].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}                                          
                                    </div>
                                    </div>
                                    <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>                                       
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>2.</span>
                                            <span>Place your stepping stones</span>
                                        </span>
                                        {levelList[1] && levelList[1].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}     
                                    </div>
                                </div>
                            </div>  
                            )} 
                        </div>
                    </div>                   
                </div>
                <div className={classes.fiContent}>
                    <div className={classes.mcsRow}>
                        <div className={ classes.backtoLevel }>
                            <Link to="/my-career-sketch/level3">
                                <span> {'<'} Back to level 03</span>
                            </Link>
                        </div>
                    </div>  
                    <div className={classes.fiHeader}>                   
                        <div className={classes.fiHeaderInner}>
                            <span className={classes.pwiActiveModule}>
                                <h3>Level 03: Identify your stepping stones</h3>
                            </span>
                        </div>                   
                    </div>
                    <div className={classes.stepOne}>
                        <div className={classes.fiContentHeader}>
                            <div className={classes.pwiWalkTitle}>
                                <div aria-hidden="true" className={classes.menuIcon}>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                                <h3>Find your career path</h3>
                            </div>
                        </div>
                        <div className={classes.ProgressBar}>
                            <div className={classes.ProgressBarInner} style={{ width: '100%' }} />
                        </div>
                        <div className={classes.contentBlock}>
                        {myDetails && myDetails.me.user_identity === "student" ?
                           <div>
                                <p className={classes.marginTopZero}>
                                Even if you believe your stepping stones are obvious, do some research to make sure – you might gain new insights and be able to improve on your initial plan. 
                                </p>
                                <p className={ classes.marginBottomZero }>
                                Inkwiry created two tools to help you find the information you need easily:
                                </p>
                                <ul className={ classes.userList }>
                                    <li><a href="/">Compare Schools:</a> To broaden your awareness, select seven schools and compare stats like the graduation rate, tuition and fees, median earnings of graduates in 10 years, amount of gift aid available through grants and scholarships.</li>
                                    <li><a href="/">Compare Careers:</a> Examine careers that align with your vision – aim to compare seven careers because within your main area of interest, there may be several potential and related career paths. Using this tool, you quickly can compare projected job growth, salary range, level of education needed and many other factors.</li>
                                </ul>
                                <p className={ classes.marginBottomZero }>
                                        And there are two external tools that can help solidify your stepping stones:
                                    </p>                           
                                <ul className={ classes.userList }>
                                        <li>Visit <a href="/">Payscale&apos;s Career Path Planner</a> to explore the many paths your career might take.</li>
                                        <li> Check out <a href="/">MyNextMove</a> to learn the technology, skills and knowledge needed for your career. This resource also helps you see the specifics behind a career title, like the tasks and work activities you can expect to perform in a certain position.</li>
                                </ul>                                
                                <div className={ classes.stepStones }>
                                        <img src={ stepStones } alt="steps" />
                                        <div className={ classes.stepContent }>
                                            <p>Stepping stones to</p>
                                            <p>OWNER OF AN ENGINEERING FIRM</p>
                                            <p>(Principal Engineer, Structural)</p>
                                            <p><i>Stepping stone options by Payscale Career Path Planner</i></p>
                                        </div>
                                </div>
                                <p className={ classes.marginBottomZero }>Here are two ways to identify the stepping stones needed to reach your career goal. </p>
                                <ul className={ classes.methodList }>
                                    <li><span>1.</span> Choose the direct method: find a starting point and then see what potential future stepping stones will lead you to your goal. In the example above, becoming a civil engineer is a stepping stone to structural engineer, and opens doors to more opportunities, including being a principal enginner and owning your own engineering firm.</li>
                                    <li><span>2.</span>  Reverse engineer your stepping stones: start from your end goal, see what is needed to get there, and work backward to create stepping stones that lead to your goal. Maybe you already know where you want to be, for example owner of an engineering firm. You trace back the steps to structural engineer, and see your previous stepping stone is to become a civil engineer.</li>
                                </ul>
                                <p>In both of the examples, your very first stepping stone would be Higher Education (college).</p>
                                <p>Your career path might not require a colllege degree. Perhaps you’re headed to career school to learn a trade or specialty. Or you might want to jump right into the workforce. Wherever you’re headed, your first stepping stone will be either Higher Education or Career Path.</p>
                                <p>As you add your stepping stones to the timeline, you’ll be asked for a few specifics. For education, Inkwiry wants to know which school you wish to attend (or are already attending). You’ll also be asked about specific career titles, potential earnings and where you’ll want to live and work. </p>
                                <p>When you’re ready, let’s place stepping stones on your timeline.</p>
                            </div>
                            :  <div>
                            <p className={classes.marginTopZero}>
                            Even if you believe your stepping stones are obvious, do some research to make sure – you might gain new insights and be able to improve on your initial plan. 
                            </p>
                            <p className={ classes.marginBottomZero }>
                                Inkwiry created two tools to help you find the information you need easily:
                            </p>
                            <ul className={ classes.userList }>
                                <li><a href="/">Compare Careers:</a> Examine careers that align with your vision – aim to compare seven careers because within your main area of interest, there may be several potential career paths. Using this tool, you can compare projected job growth, salary range, level of education needed and many other factors.</li>
                                <li><a href="/">Compare Schools:</a> This tool compares undergraduate schools and career schools. View and compare stats like graduation rate, tuition and fees, median earnings of graduates in 10 years, amount of gift aid available through grants and scholarships.</li>
                            </ul>
                            <p className={ classes.marginBottomZero }>
                                And there are two external tools that can help solidify your stepping stones:
                            </p>                           
                            <ul className={ classes.userList }>
                                <li>Visit <a href="/">Payscale&apos;s Career Path Planner</a> to explore the many paths your career might take.</li>
                                <li> Check out <a href="/">MyNextMove</a> o learn the technology, skills and knowledge needed for your career. This resource also helps you see the specifics behind a career title, like the tasks and work activities you can expect to perform in a certain position.</li>
                            </ul>                                
                            <div className={ classes.stepStones }>
                                    <img src={ stepStones } alt="steps" />
                                    <div className={ classes.stepContent }>
                                        <p>Stepping stones to</p>
                                        <p>OWNER OF AN ENGINEERING FIRM</p>
                                        <p>(Principal Engineer, Structural)</p>
                                        <p><i>Stepping stone options by Payscale Career Path Planner</i></p>
                                    </div>
                            </div>
                            <p className={ classes.marginBottomZero }>Here are two ways to identify the stepping stones needed to reach your career goal. </p>
                            <ul className={ classes.methodList }>
                                <li><span>1.</span> Choose the direct method: find a starting point and then see what potential future stepping stones will lead you to your goal. In the example above, becoming a civil engineer is a stepping stone to structural engineer, and opens doors to more opportunities, including owning your own engineering firm.</li>
                                <li><span>2.</span>Reverse engineer your stepping stones: start from your end goal, see what is needed to get there, and work backward to create stepping stones that lead to your goal. Maybe you already know where you want to be, for example owner of an engineering firm. You trace back the steps to structural engineer, and see your previous stepping stone is to become a civil engineer.</li>
                            </ul>
                            <p>In both of the examples, the very first stepping stone would be Higher Education (college).</p>
                            <p>You may have already completed higher education of some type (college or career school) and entered the workforce. Your first stepping stone will be Career Path and contain details of the  position you’re working in right now.</p>
                            <p>As you add stepping stones to your timeline, you’ll be asked for a few specifics. Inkwiry wants to  know about specific career titles, potential earnings and where you’ll want to live and work. If you wish to pursue more education, you’ll be asked which school you wish to attend. </p>
                            <p>When you’re ready, let’s place stepping stones on your timeline.</p>
                        </div>}
                        </div>
                    </div>                    
                    <div className={classes.Buttons}>
                        <div className={classes.startButton}>
                            <Link to="/my-career-sketch/level3">
                                <button type="button">Back To Level 03</button>
                            </Link>
                        </div>
                        <div className={classes.nextButton}>
                            <Link to="/my-career-sketch/level3/activity2/timeline">
                                <button type="button">Next</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={classes.mcsFooter}>
                    <div className={classes.container}>
                        <div className={classes.footerRow}>
                            <div className={classes.copyRight}>
                                <p>Copyright © 2022 // All rights reserved</p>
                            </div>
                            <div className={classNames(classes.copyRight, classes.informedText)}>
                                <p>
                                    Informed and Powered by
                                    <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.mcsFluid}>
                        <p className={classes.termLinks}>
                            <a href="/">Terms of Service</a>
                            &nbsp;//&nbsp;
                            <a href="/">Privacy Policy</a>
                            &nbsp;//&nbsp;
                            <a href="/">Security</a>
                        </p>
                    </div>
                </div>
            </div>
        </Typography>
    );
};

McsLevelThreeActivityOne.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(McsLevelThreeActivityOne);