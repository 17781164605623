import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { Link } from 'react-router-dom';

const FederalReserve = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Federal Reserve</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/federal-reserve.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Federal Reserve
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/federal-reserve-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          If you ever watch business news or any type of news
                          for that matter, you have probably heard of the
                          &quot;Fed,&quot; which is short for the Federal
                          Reserve. The Fed provides services that every American
                          relies on but might not notice on a day-to-day basis.
                          The Fed has the ability to affect the rate on your
                          mortgage or the price of a stock you own.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          The Federal Reserve is the central bank of the United
                          States. The job of the Fed is to keep our financial
                          systems safe, promote a stable economy and provide a
                          flexible and more stable money supply. The Fed was
                          created in 1913 by Congress and signed into law by
                          then-President Woodrow Wilson. The Fed is actually a
                          system comprised of the following parts:
                        </p>
                        <ul className={classes.checkList}>
                          <li>
                            <span>
                              <strong>√</strong>
                              12 regional Federal Reserve banks across the
                              nation that govern a
                            </span>
                            <span className={classes.liLeftPadding}>
                              specific area of the United States,
                            </span>
                          </li>
                          <li>
                            <span>
                              <strong>√</strong>A Federal Reserve Board of
                              Governors comprised of seven members
                            </span>
                            <span className={classes.liLeftPadding}>
                              who oversee the whole system, and
                            </span>
                          </li>
                          <li>
                            <span>
                              <strong>√</strong>
                              The Federal Open Market Committee (FOMC) comprised
                              of the Board of
                            </span>
                            <span className={classes.liLeftPadding}>
                              Governors plus five of the regional bank
                              presidents. The FOMC&apos;s job is to{' '}
                            </span>
                            <span className={classes.liLeftPadding}>
                              set monetary policy.
                            </span>
                          </li>
                        </ul>
                        <p>
                          According to Board of Governors of The Federal Reserve
                          System, the Fed performs five functions for the US
                          economy and for the public interest:
                        </p>
                        <ol>
                          <li>
                            conduct the nation&apos;s monetary policy to achieve
                            its dual mandate of maximum employment and stable
                            prices;
                          </li>
                          <li>
                            promote the stability of the financial system;
                          </li>
                          <li>
                            promote the safety and soundness of individual
                            financial institutions and monitor their impact on
                            the financial system as a whole;
                          </li>
                          <li>
                            foster payment and settlement system safety and
                            efficiency through services to the banking industry
                            and the U.S. government that facilitate U.S.-dollar
                            transactions and payments; and
                          </li>
                          <li>
                            promote consumer protection and community
                            development.
                          </li>
                        </ol>
                        <p>
                          Before we can break into these five things, first
                          understand how a bank creates and earns money.
                          Let&apos;s look at a very simplified example. John Doe
                          walks into a bank to open a savings account with a
                          $100,000 deposit. The bank currently pays John 1% on
                          his savings account. By law, the bank is required to
                          hold 10% of John&apos;s deposit in cash reserves. But
                          what about the other 90%? The bank can use the other
                          90% to create loans.
                        </p>
                        <p>
                          The next day Susie Thompson walks into the bank
                          looking for a $90,000 mortgage to help buy her new
                          home. After checking her credit, the bank agrees to
                          give her a $90,000 loan with an interest rate of 4.0%.
                          The bank uses the $90,000 from John Doe&apos;s deposit
                          to create that loan for Susie. If the bank is paying
                          John 1% on $100,000 and Susie is paying the bank 5% on
                          $90,000, how much does the bank make each year?
                        </p>
                        <p>
                          Payments to John from the bank = $100,000 x 1% =
                          $1,000
                          <br />
                          Payments to the bank from Susie = $90,000 x 5% =
                          $4,500
                          <br />
                          Net interest earned by the bank = $4,500 - $1,000 =
                          $3,500 earned
                        </p>
                        <p>
                          How does the Federal Reserve play a role in this
                          equation? The Fed sets the reserve requirement. In
                          other words, the Fed dictates the amount of loans
                          banks can create from deposits. The reserve
                          requirement is just one of the tools at the Fed&apos;s
                          disposal.
                        </p>
                        <p>
                          In the next Finance Explained, we&apos;ll explore the
                          monetary policy tools of the Fed, which includes
                          interest rates, and how they affect our everyday
                          lives.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FederalReserve.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FederalReserve);
