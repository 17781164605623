import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-car/styles';
import * as sliderValues from '../../utilities/existingsliders';
import * as commonFunctions from '../../utilities/commonFunctions';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';

const SecondCarLeasingDownPayment = ( props ) => {
  const {
    classes, handleDefinationPopup, handleBuyingCarObject, handleUpdatedObject,
  } = props;
  const [basePrice, setBasePrice] = React.useState( handleBuyingCarObject.leasing_second_car_down_payment === undefined ? 0 : handleBuyingCarObject.leasing_second_car_down_payment );
  const [destination, setDestination] = React.useState( handleBuyingCarObject.leasing_second_car_drive_off_fees === undefined ? 500 : handleBuyingCarObject.leasing_second_car_drive_off_fees );

  useEffect( () => {
    const leasingObj = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.leasing_second_car_down_payment === undefined ) {
      leasingObj.leasing_second_car_down_payment = 0;
      setBasePrice( 0 );
    }
    if ( handleBuyingCarObject.leasing_second_car_drive_off_fees === undefined ) {
      leasingObj.leasing_second_car_drive_off_fees = 500;
      setDestination( 500 );
    }
    handleUpdatedObject( leasingObj );
    // eslint-disable-next-line
  }, []);

  const updateValue = ( e, value, field, type ) => {
    const totalPurchaseObj = { ...handleBuyingCarObject };

    let newvalue = 0;
    if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    totalPurchaseObj[field] = newvalue;
    switch ( field ) {
      case 'leasing_second_car_down_payment': {
        setBasePrice( newvalue );
        break;
      }
      case 'leasing_second_car_drive_off_fees': {
        setDestination( newvalue );
        break;
      }
      default: {
        break;
      }
    }
    handleUpdatedObject( totalPurchaseObj );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>When you plan to lease a car, don&apos;t tell the dealership that you want to lease the vehicle until you determine and negotiate the dealership&apos;s best price. Your lease is based on the final agreed upon price so getting the best price will save you money on your lease!</p>
      <p>
        Although a down payment on a lease is not always required, making a
        {' '}
        {' '}
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Car Down Payment: Leasing' ) }>down payment</span>
        {' '}
        can lower your lease payment. If you know your lease details, enter your down payment below. Leverage
        {' '}
        {' '}
        <a href="https://www.edmunds.com/calculators/car-lease.html" target="_blank" rel="noopener noreferrer">Edmunds.com&apos;s free lease payment calculator</a>
        {' '}
        and see how down payment can change your lease payment.
      </p>
      <p>
        <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Drive-off fees' ) }>Drive-off fees</span>
        {' '}
        are other fees that you need to pay to start your lease. These fees include the cost of registering your vehicle with the state and other documentation fees from processing your lease.
      </p>
      <div className={ classes.groupAnnualBox }>
        <div className={ classes.AnnualIncomeGrowth }>
          <div>
            <p  data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( 'Car Down Payment: Leasing' ) }><span className={ classes.dottedText }>Down Payment</span></p>
            <div className={ classes.annuvalIncomeGrowthGraph }>
              <PwiSlider
                ValueLabelComponent={ ValueLabelComponent }
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={ sliderValues.DownPaymentArraay() }
                min={ 0 }
                max={ 3500 }
                step={ 50 }
                value={ basePrice }
                valueLabelFormat={ `${commonFunctions.numFormatter( basePrice )}` }
                onChange={ ( e, value ) => { updateValue( e, value, 'leasing_second_car_down_payment', 'slider' ); } }
              />
            </div>
            <div className={ classes.annuvalIncomeGrowthInput }>
              $
              <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ basePrice } onValueChange={ ( e ) => updateValue( e, '', 'leasing_second_car_down_payment', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
          </div>
        </div>
        <div className={ classes.AnnualIncomeGrowth }>
          <div>
            <p  aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Drive-off fees' ) }><span className={ classes.dottedText }>Drive-Off Fees</span></p>
            <div className={ classes.annuvalIncomeGrowthGraph }>
              <PwiSlider
                ValueLabelComponent={ ValueLabelComponent }
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                marks={ sliderValues.DestinationValues() }
                min={ 0 }
                max={ 1400 }
                step={ 10 }
                value={ destination }
                valueLabelFormat={ `${commonFunctions.numFormatter( destination )}` }
                onChange={ ( e, value ) => { updateValue( e, value, 'leasing_second_car_drive_off_fees', 'slider' ); } }
              />
            </div>
            <div className={ classes.annuvalIncomeGrowthInput }>
              $
              <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale thousandSeparator value={ destination } onValueChange={ ( e ) => updateValue( e, '', 'leasing_second_car_drive_off_fees', 'number' ) } onFocus={ ( e ) => { e.target.select(); } } />
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
    </div>
  );
};
SecondCarLeasingDownPayment.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( SecondCarLeasingDownPayment );
