const data = [
    {
      title: "Family",
      children: [
        {
          label: 'Marriage Year',
          type: 'date',
          prefix: '',
          suffix: "",
          key: "marriege_year"
        },
      

          {
            label: 'Number of Children',
            type: 'number',
            prefix: '',
            suffix: "",
            key: "number_of_children"
          }
      ]
    },
    {
      title: "Marriage Multiples - Monthly Living Expenses",
      children: [
        {
          label: 'Rent',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "rent_multiplier"
        },
        {
          label: 'Utilities',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "utilities_multiplier"
        },
        {
          label: 'Food',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "food_multiplier"
        },
        {
          label: 'Clothing & Personal Care',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "clothing_multiplier"
        },
        {
          label: 'Entertainment',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "entertainment_multiplier"
        },
        {
          label: 'Technology',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "technology_multiplier"
        },
        {
          label: 'Transportation',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "transportation_multiplier"
        },
        {
          label: 'Miscellaneous',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "miscellaneous_multiplier"
        },
        {
          label: 'Health Insurance Costs outside Premium',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "health_insurance_costs_multiplier"
        },
        {
          label: 'Health Insurance Premium',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "health_insurance_premium_multiplier"
        },



      ]
    },
    {
    title : "Income Statement Summary",
    children : [
      {
        label: 'Spouse&apos;s Income Earned',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_spouse_income"
      },
      {
        label: 'Other Income Earned',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_other_income"
      },
      {
        label: 'Total Income Earned',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_total_income"
      }, 
       {
        label: 'Taxes Paid',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_taxes_paid"
      },
      {
        label: 'Total Tax Rate',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_taxes_rate"
      },
      {
        label: 'Living Expenses Paid',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_expenses_paid"
      },

      {
        label: 'Financing Expenses Paid',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_financing_expenses_paid"
      },
      {
        label: 'Income after Living Expenses, Financing Expenses, & Taxes',
        type: 'number',
        prefix: '$',
        suffix: "",
        key: "marrital_income_after_expenses"
      }

    ]
    }

  ]; 
  
  export default data;