import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import fillingtaxes from '../../../../assets/img/pf101/filling-taxes_720.png';

const startTime = new Date();

const FilingTaxes = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );

  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/filing-your-taxes.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Filing Your Taxes</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/tax-filing-left.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          Every year you earn income, you need to file your taxes. Tax Day in the United States is April 15th.
                          If that day is a national holiday or falls on a weekend, Tax Day will most likely be the next business day.
                          If you don&apos;t file your taxes by this date, you might get penalized and have to pay extra fees.
                          Always plan to file your taxes before April. That way, you avoid unneeded fees and give yourself enough time for
                          any last-minute complications.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.fiImage }>
                      <div className={ classes.lightGallery }>
                        <img src={ fillingtaxes } alt="img" />
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        The first thing you need to do is gather the tax information you&apos;ll need to file:
                        <ul>
                          <li>
                            <b>Social security number</b>
                          </li>
                          <li>
                            <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.thebalance.com/form-1040a-3192754" target="_blank"><b>1040 form</b></a>
                            <b> from previous year:</b>
                            {' '}
                            the comprehensive income tax form that is divided into sections and details every number in your income taxes calculations. This form will be a great starting point for filing this year&apos;s taxes.
                          </li>
                          <li>
                            <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.thebalance.com/form-1040a-3192754" target="_blank"><b>W-4 form</b></a>
                            <b>: </b>
                            details how many allowances you are claiming for your taxes. The number of allowances you claim determines how much taxes your employer withholds from each paycheck for the year. When you start a new job, you will be asked to fill out this form.
                          </li>
                          <li>
                            <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.thebalance.com/form-1040a-3192754" target="_blank"><b>W-2 form</b></a>
                            <b>: </b>
                            details exactly how much you earned in the past year and how much you have already paid in taxes. Each job you have requires a W-2 form.
                          </li>
                          <li>
                            <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.thebalance.com/form-1040a-3192754" target="_blank"><b>1099 forms</b></a>
                            <b>: </b>
                            a record that details payment to you from another company or person that is not your employer. For example, savings accounts, investments, freelance work and more requires a 1099 form.
                          </li>
                          <li>
                            <b>Retirement account contributions: </b>
                            contributing to retirement accounts can lower your taxes. You need to know how much you contributed to file your taxes.
                          </li>
                          <li>
                            <b>Education expenses: </b>
                            if you&apos;re paying for higher education, you may qualify for tax benefits. You&apos;ll need receipts of your expenses such as tuition and fees.
                          </li>
                          <li>
                            <b>Property taxes and mortgage interest</b>
                          </li>
                          <li>
                            <b>Charitable donations</b>
                          </li>
                          <li>
                            <b>State and local taxes</b>
                          </li>
                        </ul>
                        <p>
                          After you gather your tax information, you have two options for filing: do it yourself via online resources or hire a tax professional to do it for you. If you are comfortable with tax forms, confident in your ability to use online software, and have the time to devote to your taxes,
                          you can save a little bit of money by filing your taxes online. If you&apos;re not comfortable or don&apos;t have the time, services of a tax professional are relatively inexpensive and will help make sure you file correctly.
                        </p>
                        {' '}
                        <p>
                          According to the
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://connect.nsacct.org/blogs/nsa-blogger/2017/01/27/national-society-of-accountants-reports-on-average-tax-return-preparation-fees" target="_blank">National Society of Accountants in January 2017</a>
                          , the average fee for a tax professional to file basic federal and state returns is $176. More complicated returns that include investments or real estate property cost more. Online resources such as
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://turbotax.intuit.com/" target="_blank">TurboTax</a>
                          ,
                          {' '}
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.hrblock.com/" target="_blank">H&R Block</a>
                          , and
                          {' '}
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://www.taxact.com/" target="_blank">TaxAct</a>
                          {' '}
                          can cost anywhere from $0 to $100 or even more for complicated returns.
                          {' '}
                          <b>Most Americans opt for a tax professional to make sure taxes are filed correctly and without hassle.</b>
                        </p>
                        {' '}
                        <p>
                          Online software or your tax professional will let you know anything else you might need to properly file your taxes. Want more details on taxes?
                          {' '}
                          <a className={ classes.anchorStyle } rel="budget-fy2020 noopener noreferrer" href="https://connect.nsacct.org/blogs/nsa-blogger/2017/01/27/national-society-of-accountants-reports-on-average-tax-return-preparation-fees" target="_blank">Click here</a>
                          {' '}
                          to download Inkwiry&apos;s 2019 tax document.
                        </p>
                      </p>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level2/filing-taxes/exercise" startTime={ startTime } exercise={ 4 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
FilingTaxes.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( FilingTaxes );
