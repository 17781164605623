import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import CareerSketchTimeline from '../../ui/career-sketch-timeline/career-sketch-timeline';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as moduleServices from '../../../calculations/modules-services';
import { timeline, sortTimeLine } from '../../../calculations/time-line';
import _ from 'lodash';

const MarriageYear = ( props ) => {
  const { classes, inputData, updateData } = props;


  const [marriageYearSlider, setMarriageYearSlider] = React.useState( 0 );
  const [yearsList, setYearsList] =  React.useState([])


  const updateTimeLine = (startYearVal) => {
    let timelineData = [...timeline];
    const index = _.findIndex( timeline, { selectedModule: 'module10' } );
    if(index >= 0) {
      timelineData[index].startYear = startYearVal;
      timelineData[index].endYear = '';
      timelineData[index].imageSlug = 'active';
      timelineData[index].timelineOrder = startYearVal;
      if(startYearVal <= 0) {
          timelineData[index].timelineOrder = parseInt(moduleServices.module1Data.start_year, 10)+60+parseInt(12);
      }
      sortTimeLine(timelineData);
    }
  }  

  useEffect(()=>{
    const module1StartYear = parseInt(moduleServices.module1Data.start_year, 10);
    const yearsListTicksValues = [];
    for ( let j = 0; j <= 14; j += 1 ) {
      if(j%2 === 0) {
        yearsListTicksValues.push( {
          value: module1StartYear+j,
          label: module1StartYear+j,
        } );
      }
    }
    setYearsList(yearsListTicksValues);

    if(inputData.marriege_year !== undefined && inputData.marriege_year !== null && inputData.marriege_year !== '' && inputData.marriege_year > 0) {
      setMarriageYearSlider(inputData.marriege_year);
      updateTimeLine(inputData.marriege_year)
    } else {
      setMarriageYearSlider(module1StartYear);
      updateData( 'marriege_year', module1StartYear );
      updateTimeLine(module1StartYear) 
    }

  // eslint-disable-next-line 
  },[])

  const handleMarriageYearSlider = ( event, newValue ) => {
    setMarriageYearSlider( newValue );
    updateData( 'marriege_year', newValue );
    updateTimeLine(newValue)
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Review your current timeline below. When do you plan on getting married? How does that fit into your financial life picture?</p>
      <p>Enter your marriage year below after reviewing your current timeline. Keep in mind your other life events and plan accordingly.</p>

      <CareerSketchTimeline moduleName="module10"/>

      <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor} ${classes.marrigeYear}` }>
        <div>
          <p>Marriage Year</p>
          <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor} ${classes.marrigeGraph}` }>
            <PwiSlider
              value={ marriageYearSlider }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              min={ yearsList.length > 0 && yearsList[0]['value'] }
              max={ yearsList.length > 0 && yearsList[yearsList.length - 1]['value'] }
              step={ 1 }
              marks={ yearsList }
              ValueLabelComponent={ ValueLabelComponent }
              onChange={ handleMarriageYearSlider }
            />
          </div>
          <div className={ classes.yearNumber }>
            <span>{marriageYearSlider}</span>
          </div>

        </div>
      </div>
    </div>
  );
};

MarriageYear.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};
export default withStyles( styles )( MarriageYear );
