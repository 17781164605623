import React from 'react'
import shareIcon from '../../assets/img/financial-independence/share_icon.png';
import Simulator from '../../assets/img/financial-independence/simulator-engine-blue.png';
import TimeLine from '../../assets/img/financial-independence/timeline_icon.png';
import Dollar from '../../assets/img/financial-independence/dollar-icon.png';
import Dashboard from '../../assets/img/financial-independence/dashboard.png';
import Clear from '../../assets/img/financial-independence/clear-timeline.png';
import Load from '../../assets/img/financial-independence/load.png';
import Save from '../../assets/img/financial-independence/save.png';
import * as moduleServices from '../../calculations/modules-services';
import ReactTooltip from 'react-tooltip';
import WalkThruFour from './wallk-thru/walkThruFour';
import WalkThruFive from './wallk-thru/walkThruFive';
import WalkThruSix from './wallk-thru/walkThruSix';
import classNames from 'classnames';
import {Button} from '@material-ui/core';

function IconBar(props) {
    const {slidecount, activeWalkThruSlide, walkThruNo, handleWalkThrusBack,
        handleWalkThrus, handleWalkThruClose, completedWalkThruSlides,
        activeScenarioData, handleQuickSave, setOpenPopup,setSummaryDocumentsOpen, handleSaveScenario,
        summaryDocumentsOpen, setOpenDocsPopup,clearEntireScenario, classes, history, 
    } = props;
    const ref = React.useRef(null);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
        setSummaryDocumentsOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
        document.removeEventListener('click', handleClickOutside, true);
        };
    });
    
    return (
        <div className={slidecount === 2 ? classNames(classes.detailsHeader, classes.scaleOpacityNew) : classes.detailsHeader} >
            {activeWalkThruSlide === 4 && (<WalkThruFour walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            {activeWalkThruSlide === 5 && (<WalkThruFive walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            {activeWalkThruSlide === 6 && (<WalkThruSix walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
            <div className={classes.detailsButtons}>
            <ReactTooltip id="bottomTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
            <div className={classes.scenarioLeft}>
                <span className={classes.scenarioName}>
                {activeScenarioData.name !== undefined && activeScenarioData.name !== '' && (activeScenarioData.name)}
                </span>
                {activeScenarioData.id !== undefined && (
                <div className={classes.leftButtons}>
                    <ReactTooltip id="bottomTooltip1" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Button data-for="bottomTooltip1" data-tip="Quick Save" onClick={handleQuickSave}>
                    {' '}
                    <i className="fa fa-floppy-o" aria-hidden="true" />
                    {' '}
                    </Button>
                    <Button data-for="bottomTooltip1" data-tip="Share Scenario" onClick={() => { setOpenPopup('share'); }}>
                    <img src={shareIcon} alt="shareIcon" />
                    </Button>
                </div>
                )}
            </div>
            <div className={classes.scenarioRight}>
                {moduleServices.cashFlowCheckVal < 0 && (
                <div className={classes.cfPositive}>
                    <span>Cash Flow Positive</span>
                </div>
                )}
                {moduleServices.cashFlowCheckVal > 0 && (
                <div className={classes.cfNegative}>
                    <span>
                    Cash Flow Negative {' '}
                    {moduleServices.cashFlowCheckVal}
                    </span>
                </div>
                )}
                <div className={classes.hePwiIcons}>
                <span data-for="bottomTooltip" data-tip="Simulator Panel" aria-hidden="true" onClick={() => { setOpenPopup('simulator'); }}>
                    <img src={Simulator} alt="" />
                </span>

                <span aria-hidden="true" onClick={() => { history.push('/timeline-sketcher'); }} data-for="bottomTooltip" data-tip="Timeline Sketcher">
                    <img src={TimeLine} alt="" />
                </span>

                <span data-for="bottomTooltip" data-tip="Summary Documents">
                    <img src={Dollar} alt="" aria-hidden="true" onClick={() => { setSummaryDocumentsOpen(!summaryDocumentsOpen); }} />
                    {summaryDocumentsOpen && (
                    <ul ref = {ref} className={classes.summaryListMenu}>
                        <li aria-hidden="true" onClick={() => { setOpenDocsPopup('summary-output'); setSummaryDocumentsOpen(false); }}><p>Summary Output</p></li>
                        <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('inputsheet-statement'); setSummaryDocumentsOpen(false); }}>Summary Financials</p></li>
                        <li aria-hidden="true" onClick={() => { setOpenDocsPopup('summary-table'); setSummaryDocumentsOpen(false); }}><p>Summary Tables</p></li>
                        <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('income-statement'); setSummaryDocumentsOpen(false); }}>Income Statement</p></li>
                        <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('tax-statement'); setSummaryDocumentsOpen(false); }}>Tax Statement</p></li>
                        <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('balance-statement'); setSummaryDocumentsOpen(false); }}>Balance Sheet</p></li>
                        <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('cashflow-statement'); setSummaryDocumentsOpen(false); }}>Cash Flow Statement</p></li>
                        <li><p aria-hidden="true" onClick={() => { setOpenDocsPopup('total-download'); setSummaryDocumentsOpen(false); }}>Download PDF</p></li>
                    </ul>
                    )}
                </span>
                <span aria-hidden="true" onClick={() => { history.push('/dashboard'); }} data-for="bottomTooltip" data-tip="Dashboard">
                    <img src={Dashboard} alt="" />
                </span>
                <span data-for="bottomTooltip" data-tip="Clear Inputs" aria-hidden="true" onClick={clearEntireScenario}>
                    <img src={Clear} alt="" />
                </span>
                <span data-for="bottomTooltip" data-tip="Load Scenario" aria-hidden="true" onClick={() => setOpenPopup('sight-load-scenario')}>
                    <img src={Load} alt="" />
                </span>
                <span data-for="bottomTooltip" data-tip="Save New Scenario" aria-hidden="true" onClick={() => { handleSaveScenario('saveScenario'); }} >
                    <img src={Save} alt="" />
                </span>
                </div>
            </div>
        </div>
    </div>
    )
}

export default IconBar
