import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const InvestmentPortfolio = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Investment Portfolio</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investment-portfolio.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Investment Portfolio
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/investment-portfolio-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Think of an investment portfolio like a pie that is
                          cut into many pieces that are different sizes. The
                          whole pie represents your invested money and the
                          pieces of the pie represent the types of assets you
                          are invested in. The pieces of an investment portfolio
                          could consist of{' '}
                          <Link
                            to="/finance-explained/stocks-bonds"
                            target="_blank">
                            stocks and bonds
                          </Link>
                          ,{' '}
                          <Link
                            to="/finance-explained/funds-alternative-investments"
                            target="_blank">
                            mutual funds and Exchange-Traded Funds (ETFs)
                          </Link>
                          ,{' '}
                          <Link
                            to="/finance-explained/funds-alternative-investments"
                            target="_blank">
                            alternative investments such as real estate or
                            commodities
                          </Link>
                          ,{' '}
                          <Link
                            to="/finance-explained/investing-101"
                            target="_blank">
                            Certificates of Deposit (CDs), money market funds
                          </Link>
                          , cash and more.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          What&apos;s the purpose of an investment portfolio?
                          The purpose of an investment portfolio is to custom
                          tailor an investment strategy that minimizes risk
                          while meeting your investment goals. Let&apos;s look
                          at two hypothetical examples.
                        </p>
                        <p>
                          Tyler is a 25-year-old civil engineer living and
                          working in the Austin, TX area and has saved $25,000
                          that he wants to invest. Tyler is willing to{' '}
                          <Link
                            to="/finance-explained/investing-101"
                            target="_blank">
                            take more risk looking for higher returns
                          </Link>{' '}
                          since he is planning for the long term. Tyler&apos;s
                          portfolio would allocate (finance term meaning put
                          money in a specific asset) more money to{' '}
                          <Link
                            to="/finance-explained/investing-101"
                            target="_blank">
                            riskier assets
                          </Link>
                          , such as stocks and alternative investments as
                          compared to bonds.
                        </p>
                        <div className={classes.pfMargin}>
                          <div className={classes.pfImage}>
                            <img
                              src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/tyler-investments.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <p>
                          Jade is a 65-year-old real estate broker living and
                          working in the Pittsburgh, PA area who is looking
                          forward to her retirement. Since Jade is planning to
                          retire soon, she is not willing to take big risks and
                          is looking to earn lower, but more predictable and
                          stable returns. Jade&apos;s portfolio would consist
                          mostly of less risky assets such as CDs and bonds.
                        </p>
                        <div className={classes.pfImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/Jade-investments.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Remember, an investment portfolio is a collection of
                          financial assets custom built to achieve your goals.
                          In other words, a pie that was baked and cut just for
                          you.
                        </p>
                        <p>
                          <em>
                            Disclaimer: It&apos;s important to note that Inkwiry
                            does not provide investment advice. These examples
                            are purely academic and hypothetical. All
                            investments carry the risk of losing some or all
                            your money and there is no guarantee of earning a
                            return. Always consult a professional before making
                            investment decisions.
                          </em>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
InvestmentPortfolio.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InvestmentPortfolio);
