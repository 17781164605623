const styles = ( theme ) => ( {
  intrestModal: {
    '& > div > div': {
      width: '100%',
      maxWidth: '1080px',
    },
    '& > div > div > div': {
      padding: '0px',
    },
  },
  intrestModalHeader: {
    padding: '15px !important',
    borderBottom: '1px solid #e5e5e5',
    '& h2': {
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      fontSize: '20px',
      margin: '0',
      color: '#0069aa',
    },
  },
  intrestModalFooter: {
    padding: '15px !important',
  },
  intrestModalBody: {
    overflow: 'auto',
    boxSizing: 'border-box',
  },
  clearData: {
    color: '#fff',
    backgroundColor: '#f0ad4e',
    borderColor: '#eea236',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f0ad4e',
      borderColor: '#eea236',
    },
  },
  okBtn: {
    backgroundColor: '#84a84d',
    borderColor: '#84a84d',
    color: '#fff',
    margin: '0px 5px',
    '&:hover': {
      backgroundColor: '#799e41',
      borderColor: '#799e41',
    },
  },
  cancelBtn: {
    color: '#333',
    backgroundColor: '#e6e6e6',
    borderColor: '#adadad',
    '&:hover': {
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  loanHead: {
    display: 'inline-block',
    boxSizing: 'border-box',
    padding: '0px 15px',
    '& h3': {
      textAlign: 'center',
      fontSize: '20px',
      fontFamily: 'MuseoSans-500',
      color: '#333',
      fontWeight: 'normal',
    },
    '&:nth-child(5)': {
      width: '25%',
      float: 'right',
      textAlign: 'right',
    },
    '&:first-child': {
      textAlign: 'center',
    },
    '&:nth-child(3)': {
      paddingRight: '55px',
      float: 'none',
      textAlign: 'right',
    },
  },
  textAlignRight: {
    '& h3': {
      textAlign: 'right !important'
    }
  },
  loanBlock: {
    color: '#333',
    fontSize: '18px',
    fontFamily: 'MuseoSans-100',
    marginTop: '7px',
    display: 'inline-block',
    boxSizing: 'border-box',
    fontWeight: '700',
    padding: '0px 15px',
    '&:nth-child(5)': {
      width: '25%',
      float: 'right',
      textAlign: 'right',
      '& img': {
        marginRight: '-12px'
      }
    },
    '&:first-child': {
      textAlign: 'center',
    },
    '& input': {
      borderRadius: '0',
      fontSize: '18px',
      height: '38px',
      textAlign: 'right',
      padding: '6px 12px',
      paddingRight: '10px',
      paddingLeft: '15px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      boxSizing: 'border-box',
    },
    '&:nth-child(3)': {
      paddingRight: '55px',
      float: 'none',
      textAlign: 'right',
    },
    '& div': {
      '&::before': {
        border: 'none !important',
      },
      '&::after': {
        border: 'none !important',
      },
    },
    '& span': {
      position: 'relative',
      '& span': {
        fontSize: '16px',
        left: '5px',
        position: 'absolute',
        top: '8px',
        color: '#555',
        fontFamily: 'MuseoSans-100',
        zIndex: 9,
      },
      '& div + span':{
        top: '9px',
        left: 'auto',
        right: '8px'
      }
    }
  },
  percentageInput: {
    '& input': {
      paddingRight: '25px !important',
      paddingLeft: '12px !important'
    }
  },
  totalRow: {
    borderTop: '2px solid #333',
    padding: '10px 0',
    fontSize: '20px',
    fontFamily: 'MuseoSans-500',
    color: '#333',
    textAlign: 'right',
    '& div': {
      fontFamily: 'MuseoSans-300',
    }
  },
  modalBodyRow: {
    paddingBottom: '25px',
  },
  arrowImage: {
    '& img': {
      width: '100%',
      verticalAlign: 'middle',
    },
  },
  modalAverage: {
    padding: '10px 0',
    fontSize: '20px',
    fontFamily: 'MuseoSans-500',
    color: '#333',
    textAlign: 'right',
    '& p': {
      paddingRight: '96px',
      width: '100%',
      fontSize: '19px',
      boxSizing: 'border-box',
      '& span': {
        paddingLeft: '32px'
      }
    },
  },
} );

export default styles;
