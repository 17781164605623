import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from './styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { loader } from 'graphql.macro';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
// import userIcon from '../../assets/img/notifications/user.png';
import arrowIcon from '../../assets/img/notifications/Spin-1s-200px.gif';
import dummyProfile from '../../assets/img/addressbook/user.png';
import compose from 'recompose/compose';
import classNames from 'classnames';
import moment from 'moment';
import Pageloader from '../../components/ui/pageloader';

const USER_NOTIFICATION = loader('../../graphql/schema/notification/user-notification.graphql');
const ACCEPT_REJECT_FRIENDREQUEST = loader('../../graphql/schema/notification/accept-reject.graphql');
const ALL_USER_NOTIFICATION = loader('../../graphql/schema/notification/all-notifications.graphql');

const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;

const Notifications = (props) => {
  const { classes, history } = props;
  const [loading, setLoading] = React.useState(true);
  const [notificationData, setNotificationData] = useState('');
  // const [acceptStatus, setAcceptStatus] = useState('');
  const [acceptPopUp, setAcceptPopUp] = useState(false);
  const [rejectPopUp, setRejectPopUp] = useState(false);
  const [limitTo, setLimitTo] = useState(10);
  const [limitFrom] = useState(0);

  const [allNotification] = useLazyQuery(ALL_USER_NOTIFICATION, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        limitTo: limitTo,
        limitFrom: limitFrom
      },
    },
    onCompleted(response) {
      setLoading(false);
      setNotificationData(response && response.getAllNotifications);
    }
  });
  const [userNotification] = useLazyQuery(USER_NOTIFICATION);

  useEffect(() => {
    allNotification();
  }, []);

  const [acceptrejectrequest] = useMutation(ACCEPT_REJECT_FRIENDREQUEST, {
    onCompleted({
      acceptFriendInvitation: {
        message
      }
    }) {
      if (message === 'accepted') {
        setAcceptPopUp(true);
        setLoading(false);
        allNotification();
        userNotification();
      } else if (message === 'rejected') {
        setRejectPopUp(true);
        setLoading(false);
        allNotification();
        userNotification();
      } else {
        setLoading(false);
        allNotification();
      }
    }
  });

  const handleNo = (d) => {
    setLoading(true);
    acceptrejectrequest({
      variables: {
        data: {
          member_id: d.sent_user_id,
          notification_id: d.id,
          status: 'reject'
        }
      },
    });
  }

  const handleYes = (d) => {
    setLoading(true);
    acceptrejectrequest({
      variables: {
        data: {
          member_id: d.sent_user_id,
          notification_id: d.id,
          status: 'accept'
        }
      },
    });
  }

  useEffect(() => {
    if (acceptPopUp === true) {
      setTimeout(() => {
        setAcceptPopUp(false);
      }, 10000);
    }
  }, [acceptPopUp]);

  useEffect(() => {
    if (rejectPopUp === true) {
      setTimeout(() => {
        setRejectPopUp(false);
      }, 10000);
    }
  }, [rejectPopUp]);

  const handleScenario = (d) => {
    history.push('/timeline-sketcher');
    localStorage.setItem('scenario-data', JSON.stringify(d));
  }

  const handleSketch = (d) => {
    history.push('/timeline-sketcher');
    localStorage.setItem('sketch-data', JSON.stringify(d));
  }

  const handleCount = () => {
    setLimitTo(limitTo + 10);
  }
  

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.notificationsPage}>
        <div className={classes.container}>
          <div className={classes.notificationHead}>
            <h2>Notifications</h2>
          </div>
          {notificationData && notificationData.length > 0 ?
            <div className={classes.notificationContent}>
              {notificationData.map((d, index) => {
                return (
                  <div key={index}>
                    {d.notification_type === 'FR' ?
                      <div className={classes.notificationRow}>
                        <div className={classes.userImage}>
                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="user" />
                        </div>
                        <div className={classes.mainContent}>
                          <div className={classes.notificationText}>
                            <p>{d.content}</p>
                            <div className={classes.notificationSpanButton}>
                              <button onClick={() => { handleYes(d) }}>yes</button>
                              <button onClick={() => { handleNo(d) }}>no</button>
                            </div>
                          </div>
                          <span className={classes.notificationDate}>{moment(d.created_date).fromNow()}</span>
                        </div>

                      </div>
                      :
                      ''
                    }
                    {d.notification_type === 'FRA' ?
                      <div className={classes.notificationRow}>
                        <div className={classes.userImage}>
                          {/* <img src={d.profile_image ? d.profile_image : userIcon} alt="user" /> */}
                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="user" />
                        </div>
                        <div className={classes.mainContent}>
                          <div className={classes.notificationText}>
                            <p>{d.content}</p>
                          </div>
                          <span className={classes.notificationDate}>{moment(d.created_date).fromNow()}</span>
                        </div>
                      </div>
                      :
                      ''
                    }
                    {d.notification_type === 'S' ?
                      <div className={classes.notificationRow}>
                        <div className={classes.userImage}>
                          {/* <img src={d.profile_image ? d.profile_image : userIcon} alt="user" /> */}
                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="user" />
                        </div>
                        <div className={classes.mainContent}>
                          <div className={classes.notificationText}>
                            <span dangerouslySetInnerHTML={{ __html: d.content ? d.content : 'N/A' }} />.<span>Click&nbsp;<span className={classes.anchorSpan} onClick={() => { handleScenario(d) }}>here</span> to load the scenario(s)</span>
                          </div>
                          <div className={classes.notificationDate}>{moment(d.created_date).fromNow()}</div>
                        </div>
                      </div>
                      :
                      ''
                    }
                    {d.notification_type === 'CS' ?
                      <div className={classes.notificationRow}>
                        <div className={classes.userImage}>
                          {/* <img src={d.profile_image ? d.profile_image : userIcon} alt="user" /> */}
                          <img src={d.profile_image ? IMAGE_PATH + d.profile_image : dummyProfile} alt="user" />
                        </div>
                        <div className={classes.mainContent}>
                          <div className={classes.notificationText}>
                            <span dangerouslySetInnerHTML={{ __html: d.content ? d.content : 'N/A' }} />.<span>{''}Click&nbsp;<span className={classes.anchorSpan} onClick={() => { handleSketch(d) }}>here</span> to load the scenario(s)</span>
                          </div>
                          <div className={classes.notificationDate}>{moment(d.created_date).fromNow()}</div>
                        </div>
                      </div>
                      :
                      ''
                    }
                  </div>
                );
              })
              }
              {notificationData && notificationData.length < limitTo ?
                ''
                :
                <div
                  className={classes.allNotifications}
                  data-for="modulesTooltip"
                  data-tip="Click to see more notification history"
                  data-offset="{'right': 3}"
                  onClick={handleCount}
                >

                  <a href="/"><img src={arrowIcon} alt="user" /></a>

                </div>
              }
              <ReactTooltip id="modulesTooltip" place="right" type="info" effect="solid" className={classNames(classes.tooltipInfo, classes.tooltipInfoNotification)} />
            </div>
            :
            <div className={classes.noNoitificationText}>
              <h3>No notifications found</h3>
            </div>
          }
        </div>
      </div>
      <Dialog
        open={acceptPopUp}
        onClose={() => { setAcceptPopUp(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}
      >
        <DialogContent className={classes.messagePopup}>
          <div className={classes.popupClose}>
            <span aria-hidden="true" onClick={() => { setAcceptPopUp(false) }}>X</span>
          </div>
          <div className={classes.popupBody}>
            <span className={classes.popupCheck}>
              <i className="las la-check" />
            </span>
            <p>Invitation accepted!</p>
          </div>
          <div />
        </DialogContent>
      </Dialog>

      <Dialog
        open={rejectPopUp}
        onClose={() => { setRejectPopUp(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}
      >
        <DialogContent className={classes.messagePopup}>
          <div className={classes.popupClose}>
            <span aria-hidden="true" onClick={() => { setRejectPopUp(false) }}>X</span>
          </div>
          <div className={classes.popupBody}>
            <span className={classes.popupRejectCheck}>
              <i className="las la-check" />
            </span>
            <p>Invitation Rejected!</p>
          </div>
          <div />
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

Notifications.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Notifications);
