import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruFour = ( props ) => {
  const {
    classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide,
  } = props;

  return (
    <div className={ classNames( classes.pwiWalkthru, classes.pwiWalkthruFour, classes.pwiActiveWalkthru ) }>
      <h4>Navigation Menu</h4>
      <p>The navigation menu shows you each step in this module. Want to change a previous input? Click and go directly to that page using the navigation menu.</p>
      <div className={ classes.walkthruBottom }>
        <WalkThruDots completedWalkThruSlides={ completedWalkThruSlides } activeWalkThruSlide={ activeWalkThruSlide } handleWalkThrus={ handleWalkThrus } />
        <div className={ classes.walkThruBtns }>
          <Button className={ classes.startWalkThru } onClick={ () => { handleWalkThrus( 4 ); } }>Next</Button>
          <Button className={ classes.skipWalkthru } onClick={ handleWalkThruClose }>Close</Button>
        </div>
      </div>
    </div>
  );
};

WalkThruFour.propTypes = {
  classes: PropTypes.object.isRequired,
  handleWalkThrus: PropTypes.func.isRequired,
  handleWalkThruClose: PropTypes.func.isRequired,
  completedWalkThruSlides: PropTypes.object.isRequired,
  activeWalkThruSlide: PropTypes.string.isRequired,
};

export default withStyles( styles )( WalkThruFour );
