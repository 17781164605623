function outputBackendSheetBackendData( module1Data, module4Data, module5Data, module8Data, module9Data, module17Data, incomeStatementnetData, balanceSheetData, cashFlowData, investmentsData, databaseData, taxStatementData ) {
  /** ******************** Output Backend *********************** */
  let year = 0;
  let yearLimit = 0;

  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;

  const outputBackendData = {};

  // let i = 0;
  let avgTuitionValue = 0;
  let avgRoomBoardValue = 0;
  let avgChildernValue = 0;
  let avgEntertainmentValue = 0;
  let avgFoodValue = 0;
  let avgRentValue = 0;
  let avgUtilitiesValue = 0;
  let avgInsuranceValue = 0;
  let avgLoansValue = 0;
  let avgMiscellaneousValue = 0;
  let avgPetValue = 0;
  let avgTransportationValue = 0;
  let avgCarValue = 0;
  let avgTechnologyValue = 0;
  let avgClothingValue = 0;
  let avgHomeValue = 0;
  let avgRentalPropertyValue = 0;
  let socialSecurityValue = 0;
  let totalFederalIncomeValue = 0;
  let totalSocialSecurityValue = 0;
  let totalMedicareValue = 0;
  let totalStateLocalValue = 0;
  let federalIncomeValue = 0;
  let actualEmployerReduction2 = 0;
  let cumulativeEmployerContributionEarnings = 0;
  let cumulativeEmployerContributionEarnings1 = 0;
  let cumulativeEmployerContributionEarnings2 = 0;

  const grossIncomeGraphData = [];
  const incomeAfterTaxesGraphData = [];
  const totalExpensesGraphData = [];
  const netWorthGraphData = [];

  let averageTotal = 0;
  const dropDownYearsList = [];
  // 40k Years List
  const yearsList401k = [];
  const yearsListRothIra = [];
  const yearsListSephIra = [];
  const yearsListPension = [];

  const investmentsShortTermBalance = [];
  const investmentsLongTermBalance = [];
  const investmentsShortTermFundsFlow = [];
  const investmentsLongTermFundsFlow = [];
  const investmentsCashBalance = [];
  let lowEmployerVestingReductions = 0;
  let lowEndingBalance = 0;
  let highEndingBalance = 0;
  let lowCheckValue = 0;
  // let sensitivityAnalysisLow = 0;
  // Roth IRA Low Check
  let rothIraLowCheckValue = 0;
  let sephIraLowCheckValue = 0;
  let pensionLowCheckValue = 0;
  // Sensitivity Analysis Graph Array
  const sensitivityAnalysisLow = [];
  const sensitivityAnalysisHigh = [];
  const sensitivityAnalysisAvg = [];
  const sensitivityAnalysisPersonalContribution = [];
  const sensitivityAnalysisPersonalEarnings = [];
  const sensitivityAnalysisEmployerContributions = [];
  const sensitivityAnalysisEmployerEarnings = [];
  const sensitivityAnalysisEmployerVestingReductions = [];

  // Sensitivity Analysis Graph Array for Roth IRA
  const rothIRASensitivityAnalysisLow = [];
  const rothIRASensitivityAnalysisHigh = [];
  const rothIRASensitivityAnalysisAvg = [];
  const rothIRASensitivityAnalysisPersonalContribution = [];
  const rothIRASensitivityAnalysisPersonalEarnings = [];

  // Sensitivity Analysis Graph Array for Seph IRA
  const sephIRASensitivityAnalysisLow = [];
  const sephIRASensitivityAnalysisHigh = [];
  const sephIRASensitivityAnalysisAvg = [];
  const sephIRASensitivityAnalysisPersonalContribution = [];
  const sephIRASensitivityAnalysisPersonalEarnings = [];

  // Sensitivity Analysis Graph Array for Pension
  const pensionSensitivityAnalysisLow = [];
  const pensionSensitivityAnalysisHigh = [];
  const pensionSensitivityAnalysisAvg = [];
  const pensionSensitivityAnalysisPersonalContribution = [];
  const pensionSensitivityAnalysisEmployerContribution = [];
  const pensionSensitivityAnalysisPersonalEarnings = [];

  // const databaseSocialSecurityValue = 0;

  for ( year; year < yearLimit; year += 1 ) {
    // Get Years List
    dropDownYearsList.push( year );

    outputBackendData[year] = {};
    outputBackendData[year]['Back End Data'] = {};
    outputBackendData[year]['Lifetime Earnings Analyis'] = {};
    outputBackendData[year].Calculations = {};
    outputBackendData[year].Investments = {};
    outputBackendData[year]['Tax Summary Back End Data'] = {};

    /** Back end Data * */
    if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[year] !== undefined && incomeStatementnetData[year] !== '' ) {
      const grossIncomeValue = incomeStatementnetData[year]['Total Income'];
      outputBackendData[year]['Back End Data']['Gross Income'] = grossIncomeValue;

      // Graph
      grossIncomeGraphData.push( grossIncomeValue );

      const totalExpensesValue = -( incomeStatementnetData[year]['Total Expenses'] );
      outputBackendData[year]['Back End Data']['Total Expenses'] = totalExpensesValue;

      // Graph
      totalExpensesGraphData.push( totalExpensesValue );

      let supplementaryIncomeValue = 0;
      if ( incomeStatementnetData[year]['Gross Income'] !== undefined ) {
        if ( incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined ) {
          supplementaryIncomeValue += incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Supplementary Income'] !== undefined ) {
          supplementaryIncomeValue += incomeStatementnetData[year]['Gross Income']['Supplementary Income'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== undefined ) {
          supplementaryIncomeValue += incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Second'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Supplementary Income - Second'] !== undefined ) {
          supplementaryIncomeValue += incomeStatementnetData[year]['Gross Income']['Supplementary Income - Second'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== undefined ) {
          supplementaryIncomeValue += incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Third'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Supplementary Income - Third'] !== undefined ) {
          supplementaryIncomeValue += incomeStatementnetData[year]['Gross Income']['Supplementary Income - Third'];
        }
      }

      outputBackendData[year]['Back End Data']['Supplementary Income'] = supplementaryIncomeValue;

      const incomeAfterTaxesValue = incomeStatementnetData[year]['Income After Taxes'] + incomeStatementnetData[year]['Total Investment Property Sale'];
      // outputBackendData[year]['Back End Data']['Income After Taxes'] = incomeAfterTaxesValue ;

      outputBackendData[year]['Back End Data']['Income After Taxes'] = incomeAfterTaxesValue;

      const taxesValue = -( incomeStatementnetData[year]['Total Taxes'] );
      outputBackendData[year]['Back End Data'].Taxes = taxesValue;

      // Graph
      incomeAfterTaxesGraphData.push( taxesValue );

      const livingValue = incomeStatementnetData[year]['Total Living Expenses'];
      outputBackendData[year]['Back End Data'].Living = livingValue;

      const interestValue = incomeStatementnetData[year]['Total Interest Expenses'];
      outputBackendData[year]['Back End Data'].Interest = interestValue;

      const depreciationValue = incomeStatementnetData[year]['Total Depreciation Expenses'];
      outputBackendData[year]['Back End Data'].Depreciation = depreciationValue;

      // Tax Summary back end data
      if ( taxStatementData !== undefined && taxStatementData !== ''
                         && taxStatementData[year] !== undefined && taxStatementData[year] !== '' ) {
        federalIncomeValue = ( taxStatementData[year]['Federal Taxable Income'] / 100 ) * taxStatementData[year].taxes['Federal Income Tax (x Federal Taxable Income)'];
      }
      outputBackendData[year]['Tax Summary Back End Data']['Federal Income'] = federalIncomeValue;
      totalFederalIncomeValue += federalIncomeValue;
      if ( databaseData !== undefined && databaseData['Social Security Income Tax Limit'][year] !== undefined
                        && databaseData['Social Security Income Tax Limit'][year].Limit !== undefined
                         && taxStatementData !== undefined && taxStatementData !== ''
                         && taxStatementData[year] !== undefined && taxStatementData[year] !== ''
                         && taxStatementData[year]['Gross Income'] !== undefined && taxStatementData[year]['Gross Income'] !== ''
                         && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined
                        && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] > databaseData['Social Security Income Tax Limit'][year].Limit ) {
        socialSecurityValue = ( databaseData['Social Security Income Tax Limit'][year].Limit / 100 ) * taxStatementData[year].taxes['Social Security Tax (x Total Gross Income with limits)'];
      } else if ( taxStatementData !== undefined && taxStatementData !== '' && taxStatementData[year] !== undefined && taxStatementData[year] !== '' && taxStatementData[year]['Gross Income'] !== undefined && taxStatementData[year]['Gross Income'] !== ''
                         && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined ) {
        socialSecurityValue = ( taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] / 100 ) * taxStatementData[year].taxes['Social Security Tax (x Total Gross Income with limits)'];
      }
      outputBackendData[year]['Tax Summary Back End Data']['Social Security'] = socialSecurityValue;
      totalSocialSecurityValue += socialSecurityValue;

      let medicareValue = 0;
      if ( taxStatementData !== undefined && taxStatementData !== ''
                         && taxStatementData[year] !== undefined && taxStatementData[year] !== ''
                         && taxStatementData[year]['Gross Income'] !== undefined && taxStatementData[year]['Gross Income'] !== ''
                         && taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] !== undefined ) {
        medicareValue = ( taxStatementData[year]['Gross Income']['Total Gross Income (excluding Rental Income)'] / 100 ) * taxStatementData[year].taxes['Medicare Tax (x Total Gross Income with limits)'];
      }
      // let medicareValue = (incomeStatementnetData[year]['Total Income'] / 100 ) * incomeStatementnetData[year]['taxes']['Medicare Tax (x Total Gross Income)'] ;
      outputBackendData[year]['Tax Summary Back End Data'].Medicare = medicareValue;
      totalMedicareValue += medicareValue;
      let stateLocalValue = 0;
      if ( taxStatementData !== undefined && taxStatementData !== ''
                         && taxStatementData[year] !== undefined && taxStatementData[year] !== ''
                         && taxStatementData[year]['Adjusted Gross Income'] !== undefined ) {
        stateLocalValue = ( taxStatementData[year]['Adjusted Gross Income'] / 100 ) * taxStatementData[year].taxes['State & Local Taxes (x Adjusted Gross Income)'];
      }
      // let stateLocalValue = (incomeStatementnetData[year]['Adjusted Gross Income'] / 100 ) * incomeStatementnetData[year]['taxes']['State & Local Taxes (x Adjusted Gross Income)'] ;
      outputBackendData[year]['Tax Summary Back End Data']['State & Local'] = stateLocalValue;
      totalStateLocalValue += stateLocalValue;
    } else {
      outputBackendData[year]['Back End Data']['Gross Income'] = 0;
      // outputBackendData[year]['Back End Data']['Income After Taxes'] = {} ;
      outputBackendData[year]['Back End Data']['Total Expenses'] = 0;
      outputBackendData[year]['Back End Data']['Supplementary Income'] = {};
      outputBackendData[year]['Back End Data'].Taxes = 0;
      outputBackendData[year]['Back End Data']['Income After Taxes'] = {};
      outputBackendData[year]['Back End Data'].Living = {};
      outputBackendData[year]['Back End Data'].Interest = {};
      outputBackendData[year]['Back End Data'].Depreciation = {};
      outputBackendData[year]['Tax Summary Back End Data']['Federal Income'] = {};
      outputBackendData[year]['Tax Summary Back End Data']['Social Security'] = {};
      outputBackendData[year]['Tax Summary Back End Data'].Medicare = {};
      outputBackendData[year]['Tax Summary Back End Data']['State & Local'] = {};
    }

    if ( balanceSheetData !== undefined && balanceSheetData !== '' ) {
      const netWorthValue = balanceSheetData[year]['Net Worth'];
      outputBackendData[year]['Back End Data']['Net Worth'] = netWorthValue;

      // Graph
      netWorthGraphData.push( netWorthValue );

      const tangibleAssetsValue = balanceSheetData[year]['Total Tangible Assets'];
      outputBackendData[year]['Back End Data']['Tangible Assets'] = tangibleAssetsValue;

      const assetsValue = balanceSheetData[year]['Total Assets'];
      outputBackendData[year]['Back End Data'].Assets = assetsValue;

      const liabilitiesValue = balanceSheetData[year]['Total Liabilities'];
      outputBackendData[year]['Back End Data'].Liabilities = liabilitiesValue;

      const netWorthSecondValue = outputBackendData[year]['Back End Data']['Net Worth'];
      outputBackendData[year]['Back End Data']['Net Worth Second'] = netWorthSecondValue;

      const incomeValue = outputBackendData[year]['Back End Data']['Gross Income'];
      outputBackendData[year]['Back End Data'].Income = incomeValue;

      const shortTermValue = balanceSheetData[year].Investments['Short-Term Investments'];
      outputBackendData[year]['Back End Data']['Short-Term'] = shortTermValue;

      const longTermValue = balanceSheetData[year].Investments['Long-Term Investments'];
      outputBackendData[year]['Back End Data']['Long-Term'] = longTermValue;

      const retirementValue = parseFloat( balanceSheetData[year].Investments.for401k )
                    + parseFloat( balanceSheetData[year].Investments['Roth IRA'] );
      outputBackendData[year]['Back End Data'].Retirement = retirementValue;

      const totalInvestmentsValue = parseFloat( balanceSheetData[year].Investments['Short-Term Investments'] )
                    + parseFloat( balanceSheetData[year].Investments['Long-Term Investments'] );
      outputBackendData[year]['Back End Data']['Total Investments'] = totalInvestmentsValue;

      const studentLoanValue = balanceSheetData[year].Liabilities['Student Loans'];
      outputBackendData[year]['Back End Data']['Student Loan'] = studentLoanValue;

      const carLoanValue = balanceSheetData[year].Liabilities['Car Loans'];
      outputBackendData[year]['Back End Data']['Car Loan'] = carLoanValue;

      const mortgageValue = balanceSheetData[year].Liabilities.Mortgages;
      outputBackendData[year]['Back End Data'].Mortgage = mortgageValue;
    } else {
      outputBackendData[year]['Back End Data']['Net Worth'] = {};
      outputBackendData[year]['Back End Data']['Tangible Assets'] = {};
      outputBackendData[year]['Back End Data'].Assets = {};
      outputBackendData[year]['Back End Data'].Liabilities = {};
      outputBackendData[year]['Back End Data']['Net Worth Second'] = {};
      outputBackendData[year]['Back End Data'].Income = {};
      outputBackendData[year]['Back End Data']['Short-Term'] = {};
      outputBackendData[year]['Back End Data']['Long-Term'] = {};
      outputBackendData[year]['Back End Data'].Retirement = {};
      outputBackendData[year]['Back End Data']['Total Investments'] = {};
      outputBackendData[year]['Back End Data']['Student Loan'] = {};
      outputBackendData[year]['Back End Data']['Car Loan'] = {};
      outputBackendData[year]['Back End Data'].Mortgage = {};
    }

    if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[year] !== undefined && cashFlowData[year]['Cash from Financing Activities'] !== undefined ) {
      const principalValue = parseFloat( cashFlowData[year]['Cash from Financing Activities']['Car Loans Principal Payback'] )
                    + parseFloat( cashFlowData[year]['Cash from Financing Activities']['Student Loans Principal Payback'] )
                    + parseFloat( cashFlowData[year]['Cash from Financing Activities']['Mortgages Principal Payback'] );
      outputBackendData[year]['Back End Data'].Principal = principalValue;
    } else {
      outputBackendData[year]['Back End Data'].Principal = {};
    }
    /** End of Back End Data * */

    /** Life Time Earnings Analaysis * */
    if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[year] !== undefined ) {
      const grossIncomeSecondValue = incomeStatementnetData[year]['Total Income'];
      outputBackendData[year]['Lifetime Earnings Analyis']['Total Income'] = grossIncomeSecondValue;

      let bonusesValue = 0;
      if ( incomeStatementnetData[year]['Gross Income'] !== undefined ) {
        if ( incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'] !== undefined ) {
          bonusesValue += incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Second'] !== undefined ) {
          bonusesValue += incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Second'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Third'] !== undefined ) {
          bonusesValue += incomeStatementnetData[year]['Gross Income']['Bonus / Tips / Commission - Third'];
        }
      }
      outputBackendData[year]['Lifetime Earnings Analyis'].Bonuses = bonusesValue;

      let supplementaryIncomeSecondValue = 0;
      if ( incomeStatementnetData[year]['Gross Income'] !== undefined ) {
        if ( incomeStatementnetData[year]['Gross Income']['Supplementary Income'] !== undefined ) {
          supplementaryIncomeSecondValue += incomeStatementnetData[year]['Gross Income']['Supplementary Income'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Supplementary Income - Second'] !== undefined ) {
          supplementaryIncomeSecondValue += incomeStatementnetData[year]['Gross Income']['Supplementary Income - Second'];
        }
        if ( incomeStatementnetData[year]['Gross Income']['Supplementary Income - Third'] !== undefined ) {
          supplementaryIncomeSecondValue += incomeStatementnetData[year]['Gross Income']['Supplementary Income - Third'];
        }
      }
      outputBackendData[year]['Lifetime Earnings Analyis']['Supplementary Income'] = supplementaryIncomeSecondValue;

      const taxesSecondValue = outputBackendData[year]['Back End Data'].Taxes;
      outputBackendData[year]['Lifetime Earnings Analyis'].Taxes = taxesSecondValue;

      const expensesValue = -parseFloat( outputBackendData[year]['Back End Data']['Total Expenses'] );
      outputBackendData[year]['Lifetime Earnings Analyis'].Expenses = expensesValue;

      const netIncomeValue = incomeStatementnetData[year]['Net Income'];
      outputBackendData[year]['Lifetime Earnings Analyis']['Net Income'] = netIncomeValue;
    } else {
      outputBackendData[year]['Lifetime Earnings Analyis']['Total Income'] = {};
      outputBackendData[year]['Lifetime Earnings Analyis'].Bonuses = {};
      outputBackendData[year]['Lifetime Earnings Analyis']['Supplementary Income'] = {};
      outputBackendData[year]['Lifetime Earnings Analyis'].Taxes = {};
      outputBackendData[year]['Lifetime Earnings Analyis'].Expenses = {};
      outputBackendData[year]['Lifetime Earnings Analyis']['Net Income'] = {};
    }
    /** End of Lifetime Earnings Analysis * */

    /** Average Calculations Data * */
    if ( incomeStatementnetData !== undefined && incomeStatementnetData !== '' && incomeStatementnetData[year] !== undefined ) {
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'] !== undefined ) {
        outputBackendData[year].Calculations.Tuition = incomeStatementnetData[year].livingExpenses['Higher Education Tuition and Fees'];
      } else {
        outputBackendData[year].Calculations.Tuition = 0;
      }

      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'] !== undefined ) {
        outputBackendData[year].Calculations['Room Board'] = incomeStatementnetData[year].livingExpenses['Higher Education Room & Board'];
      } else {
        outputBackendData[year].Calculations['Room Board'] = 0;
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Children !== undefined ) {
        outputBackendData[year].Calculations.Children = incomeStatementnetData[year].livingExpenses.Children;
      } else {
        outputBackendData[year].Calculations.Children = 0;
      }

      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Entertainment !== undefined ) {
        outputBackendData[year].Calculations.Entertainment = incomeStatementnetData[year].livingExpenses.Entertainment;
      } else {
        outputBackendData[year].Calculations.Entertainment = 0;
      }

      let foodValue = 0;
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Off Campus Food'] !== undefined ) {
        foodValue += incomeStatementnetData[year].livingExpenses['Off Campus Food'];
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Food !== undefined ) {
        foodValue += incomeStatementnetData[year].livingExpenses.Food;
      }
      outputBackendData[year].Calculations.Food = foodValue;

      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Rent !== undefined ) {
        outputBackendData[year].Calculations.Rent = incomeStatementnetData[year].livingExpenses.Rent;
      } else {
        outputBackendData[year].Calculations.Rent = 0;
      }

      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Utilities !== undefined ) {
        outputBackendData[year].Calculations.Utilities = incomeStatementnetData[year].livingExpenses.Utilities;
      } else {
        outputBackendData[year].Calculations.Utilities = 0;
      }

      let insuranceValue = 0;
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'] !== undefined ) {
        insuranceValue += incomeStatementnetData[year].livingExpenses['Healthcare Costs other than Premium'];
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Health Insurance Premium'] !== undefined ) {
        insuranceValue += incomeStatementnetData[year].livingExpenses['Health Insurance Premium'];
      }
      outputBackendData[year].Calculations.Insurance = insuranceValue;

      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Total Interest Expenses'] !== undefined ) {
        outputBackendData[year].Calculations.Loans = incomeStatementnetData[year].livingExpenses['Total Interest Expenses'];
      } else {
        outputBackendData[year].Calculations.Loans = 0;
      }

      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Miscellaneous !== undefined ) {
        outputBackendData[year].Calculations.Miscellaneous = incomeStatementnetData[year].livingExpenses.Miscellaneous;
      } else {
        outputBackendData[year].Calculations.Miscellaneous = 0;
      }

      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Pet !== undefined ) {
        outputBackendData[year].Calculations.Pet = incomeStatementnetData[year].livingExpenses.Pet;
      } else {
        outputBackendData[year].Calculations.Pet = 0;
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Retirement Contributions'] !== undefined ) {
        outputBackendData[year].Calculations.Retirement = incomeStatementnetData[year].livingExpenses['Retirement Contributions'];
      } else {
        outputBackendData[year].Calculations.Retirement = 0;
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Transportation !== undefined ) {
        outputBackendData[year].Calculations.Transportation = incomeStatementnetData[year].livingExpenses.Transportation;
      } else {
        outputBackendData[year].Calculations.Transportation = 0;
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Total Car Expenses'] !== undefined ) {
        outputBackendData[year].Calculations.Car = incomeStatementnetData[year].livingExpenses['Total Car Expenses'];
      } else {
        outputBackendData[year].Calculations.Car = 0;
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Technology !== undefined ) {
        outputBackendData[year].Calculations.Technology = incomeStatementnetData[year].livingExpenses.Technology;
      } else {
        outputBackendData[year].Calculations.Technology = 0;
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses.Clothing !== undefined ) {
        outputBackendData[year].Calculations.Clothing = incomeStatementnetData[year].livingExpenses.Clothing;
      } else {
        outputBackendData[year].Calculations.Clothing = 0;
      }

      let homeValue = 0;
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Home Property Expenses'] !== undefined ) {
        homeValue += incomeStatementnetData[year].livingExpenses['Home Property Expenses'];
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Home Property Taxes'] !== undefined ) {
        homeValue += incomeStatementnetData[year].livingExpenses['Home Property Taxes'];
      }
      outputBackendData[year].Calculations.Home = homeValue;

      let rentalPropertyValue = 0;
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Rental Operating Expenses'] !== undefined ) {
        rentalPropertyValue += incomeStatementnetData[year].livingExpenses['Rental Operating Expenses'];
      }
      if ( incomeStatementnetData[year].livingExpenses !== undefined && incomeStatementnetData[year].livingExpenses['Rental Property Taxes'] !== undefined ) {
        rentalPropertyValue += incomeStatementnetData[year].livingExpenses['Rental Property Taxes'];
      }
      outputBackendData[year].Calculations['Rental Property'] = rentalPropertyValue;

      avgTuitionValue += outputBackendData[year].Calculations.Tuition;
      avgRoomBoardValue += outputBackendData[year].Calculations['Room Board'];
      avgChildernValue += outputBackendData[year].Calculations.Children;
      avgEntertainmentValue += outputBackendData[year].Calculations.Entertainment;
      avgFoodValue += outputBackendData[year].Calculations.Food;
      avgRentValue += outputBackendData[year].Calculations.Rent;
      avgUtilitiesValue += outputBackendData[year].Calculations.Utilities;
      avgInsuranceValue += outputBackendData[year].Calculations.Insurance;
      avgLoansValue += outputBackendData[year].Calculations.Loans;
      avgMiscellaneousValue += outputBackendData[year].Calculations.Miscellaneous;
      avgPetValue += outputBackendData[year].Calculations.Pet;
      // avgRetirementValue      += outputBackendData[year]['Calculations']['Retirement'] ;
      avgTransportationValue += outputBackendData[year].Calculations.Transportation;
      avgCarValue += outputBackendData[year].Calculations.Car;
      avgTechnologyValue += outputBackendData[year].Calculations.Technology;
      avgClothingValue += outputBackendData[year].Calculations.Clothing;
      avgHomeValue += outputBackendData[year].Calculations.Home;
      avgRentalPropertyValue += outputBackendData[year].Calculations['Rental Property'];
    }
    // i++;

    /** Investments * */
    outputBackendData[year].Investments['Short-Term Balance'] = 0;
    if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year] !== undefined ) {
      outputBackendData[year].Investments['Short-Term Balance'] = balanceSheetData[year].Investments['Short-Term Investments'];
    }
    investmentsShortTermBalance.push( outputBackendData[year].Investments['Short-Term Balance'] );

    outputBackendData[year].Investments['Long-Term Balance'] = 0;
    if ( balanceSheetData !== undefined && balanceSheetData !== '' && balanceSheetData[year] !== undefined ) {
      outputBackendData[year].Investments['Long-Term Balance'] = balanceSheetData[year].Investments['Long-Term Investments'];
    }
    investmentsLongTermBalance.push( outputBackendData[year].Investments['Long-Term Balance'] );

    outputBackendData[year].Investments['Short-Term Funds Flow'] = 0;
    if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[year] !== undefined ) {
      let value1 = 0;
      if ( cashFlowData[year]['Cash Injection from Short-Term Investments'] !== undefined ) {
        value1 = cashFlowData[year]['Cash Injection from Short-Term Investments'];
      }

      let module1MaxCashBalance = 0;
      // if ( module1Data.max_cash_balance !== undefined && module1Data.max_cash_balance !== '' ) {
      //   module1MaxCashBalance = module1Data.max_cash_balance;
      // } else {
      //   module1MaxCashBalance = 0;
      // }
      let value2 = 0;
      if ( cashFlowData[year]['Excess Cash Put into STI'] !== undefined && cashFlowData[year]['Excess Cash Put into STI'][module1MaxCashBalance] !== undefined ) {
        value2 = cashFlowData[year]['Excess Cash Put into STI'][module1MaxCashBalance];
      }

      const subTotal = -value1 - value2;

      let value3 = 0;
      if ( investmentsData !== undefined
                         && investmentsData['Short-Term Investments'] !== undefined
                         && investmentsData['Short-Term Investments'][year] !== undefined
                         && investmentsData['Short-Term Investments'][year]['Cash Contibution'] !== undefined ) {
        value3 = investmentsData['Short-Term Investments'][year]['Cash Contibution'];
      }

      let ltiInvestment = 0;
      if ( module9Data.max_sti_balance_before_lt_investment !== undefined && module9Data.max_sti_balance_before_lt_investment !== '' ) {
        ltiInvestment = module9Data.max_sti_balance_before_lt_investment;
      }
      let value4 = 0;
      if ( cashFlowData[year] !== undefined && cashFlowData[year]['Excess STI Put into LTI'] !== undefined && cashFlowData[year]['Excess STI Put into LTI'][ltiInvestment] !== undefined ) {
        value4 = cashFlowData[year]['Excess STI Put into LTI'][ltiInvestment];
      }
      const finalTotal = subTotal + value3 + value4;
      outputBackendData[year].Investments['Short-Term Funds Flow'] = finalTotal;
    }
    investmentsShortTermFundsFlow.push( outputBackendData[year].Investments['Short-Term Funds Flow'] );

    outputBackendData[year].Investments['Long-Term Funds Flow'] = 0;
    if ( cashFlowData !== undefined && cashFlowData !== '' && cashFlowData[year] !== undefined ) {
      let value1 = 0;
      if ( cashFlowData[year] !== undefined && cashFlowData[year]['Cash Injection from Long-Term Investments'] !== undefined ) {
        value1 = cashFlowData[year]['Cash Injection from Long-Term Investments'];
      }

      let ltiInvestment = 0;
      if ( module9Data.max_sti_balance_before_lt_investment !== undefined && module9Data.max_sti_balance_before_lt_investment !== '' ) {
        ltiInvestment = module9Data.max_sti_balance_before_lt_investment;
      }
      let value2 = 0;
      if ( cashFlowData[year]['Excess STI Put into LTI'] !== undefined && cashFlowData[year]['Excess STI Put into LTI'][ltiInvestment] !== undefined ) {
        value2 = cashFlowData[year]['Excess STI Put into LTI'][ltiInvestment];
      }

      const subTotal = -value1 - value2;

      let value3 = 0;
      if ( investmentsData !== undefined
                         && investmentsData['Long-Term Investments'] !== undefined
                         && investmentsData['Long-Term Investments'][year] !== undefined
                         && investmentsData['Long-Term Investments'][year]['Cash Contibution'] !== undefined ) {
        value3 = investmentsData['Long-Term Investments'][year]['Cash Contibution'];
      }
      const finalTotal = subTotal + value3;
      outputBackendData[year].Investments['Long-Term Funds Flow'] = finalTotal;
    }
    investmentsLongTermFundsFlow.push( outputBackendData[year].Investments['Long-Term Funds Flow'] );

    outputBackendData[year].Investments['Cash Balance'] = 0;
    if ( balanceSheetData !== undefined && balanceSheetData !== '' ) {
      outputBackendData[year].Investments['Cash Balance'] = balanceSheetData[year].Assets.Cash;
    }
    investmentsCashBalance.push( outputBackendData[year].Investments['Cash Balance'] );
  }
  // Retirement Accounts
  // 401(k)
  year = parseInt( module1Data.start_year, 10 );
  yearLimit = parseInt( module1Data.start_year, 10 ) + 15;

  /* const earnings401KTotal = 0;
  const contributions401KTotal = 0;
  const endingBalance401kLastVal = 0;

  const rothContributionsTotal = 0;
  const earningsRothTotal = 0;
  const rothEndingBalanceLastVal = 0;
  const devideRothVal = 0;

  // Seph IRA
  const sephContributionsTotal = 0;
  const earningsSephTotal = 0;
  const sephEndingBalanceLastVal = 0;
  const devideSephVal = 0;

  // Pension
  const pensionContributionsTotal = 0;
  const earningsPensionTotal = 0;
  const pensionEndingBalanceLastVal = 0;
  const devidePensionVal = 0; */

  const k = 0;

  let begBalance401k = 0;
  let begBalance401k1 = 0;
  let begBalanceRothIra = 0;
  let begBalanceSephIra = 0;
  let begBalancePension = 0;
  let begBalanceRothIraHigh = 0;
  let begBalanceSephIraHigh = 0;
  let begBalancePensionHigh = 0;

  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year === module1Data.start_year && module4Data.beginning_balance_of401k !== undefined && module4Data.beginning_balance_of401k !== '' ) {
    begBalance401k = module4Data.beginning_balance_of401k;
  }

  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year === module1Data.start_year && module4Data.roth_beginning_balance !== undefined && module4Data.roth_beginning_balance !== '' ) {
    begBalanceRothIra = module4Data.roth_beginning_balance;
  }

  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year === module1Data.start_year && module4Data.ira_beginning_balance !== undefined && module4Data.ira_beginning_balance !== '' ) {
    begBalanceSephIra = module4Data.ira_beginning_balance;
  }

  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year === module1Data.start_year && module4Data.pension_beginning_balance !== undefined && module4Data.pension_beginning_balance !== '' ) {
    begBalancePension = module4Data.pension_beginning_balance;
  }

  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year === module1Data.start_year && module4Data.roth_beginning_balance !== undefined && module4Data.roth_beginning_balance !== '' ) {
    begBalanceRothIraHigh = module4Data.roth_beginning_balance;
  }

  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year === module1Data.start_year && module4Data.ira_beginning_balance !== undefined && module4Data.ira_beginning_balance !== '' ) {
    begBalanceSephIraHigh = module4Data.ira_beginning_balance;
  }

  if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module1Data.start_year !== undefined && module1Data.start_year !== '' && module4Data.start_year === module1Data.start_year && module4Data.pension_beginning_balance !== undefined && module4Data.pension_beginning_balance !== '' ) {
    begBalancePensionHigh = module4Data.pension_beginning_balance;
  }

  let contribution401k = 0;
  let earnings401k = 0;
  let endBalance401k = 0;
  let contribution401k1 = 0;
  let earnings401k1 = 0;
  let endBalance401k1 = 0;
  let contributionRothIRA = 0;
  let contributionRothIRAHigh = 0;
  let earningsRothIRA = 0;
  let earningsRothIRAHigh = 0;
  let endBalanceRothIRA = 0;
  let endBalanceRothIRAHigh = 0;
  // Seph IRA
  let contributionSephIRA = 0;
  let contributionSephIRAHigh = 0;
  let earningsSephIRA = 0;
  let earningsSephIRAHigh = 0;
  let endBalanceSephIRA = 0;
  let endBalanceSephIRAHigh = 0;

  // Pension
  let contributionPension = 0;
  let contributionPensionHigh = 0;
  let earningsPension = 0;
  let earningsPensionHigh = 0;
  let endBalancePension = 0;
  let endBalancePensionHigh = 0;

  // const begBalanceRoth = 0;

  /* const contributionRoth = 0;
  const earningsRoth = 0;
  const endBalanceRoth = 0; */
  let avarage401k;
  let avarageRothIRA;
  let avarageRothIRAHigh;
  let avarageSephIRA;
  let avarageSephIRAHigh;
  let avaragePensionHigh;
  let firstlet = 0;
  let firstVarRothIRA = 0;

  let firstVarSephIRA = 0;

  let firstVarPension = 0;
  let firstVarPensionHigh = 0;
  // const firstRothlet = 0;
  let firstVarRothIRAHigh = 0;
  let firstVarSephIRAHigh = 0;

  // const firstRothVarHigh = 0;

  let firstVar1 = 0;
  let avarage401k1;

  // Seph IRA
  // const begBalanceSeph = 0;

  /* const contributionSeph = 0;
  const earningsSeph = 0;
  const endBalanceSeph = 0; */

  let avaragePension;
  // const firstSephlet = 0;

  // Pension

  // let avaragePensionIRA;
  // const firstPensionlet = 0;

  let begBalanceEmployer = 0;
  let begBalanceEmployer1 = 0;
  let employerContribution = 0;
  let employerContribution1 = 0;
  let employerlet = 0;
  let employerVar1 = 0;
  let avarageEmployer;
  let avarageEmployer1;
  let employerEarnings = 0;
  let employerEarnings1 = 0;
  let balanceBeforeVesting = 0;
  let balanceBeforeVesting1 = 0;
  // const vestingReductions = 0;
  // const endBalanceEmployer = 0;
  let totalEndingBalance = 0;
  let totalEndingBalance1 = 0;

  // const middleAvarage401k = 0;
  // const totalMiddleAvarage401k = 0;
  let middleAvarage401kPercentage = 0;
  let lowAvarage401kPercentage = 0;
  let highAvarage401kPercentage = 0;

  let middleAvarageRothIRAPercentage = 0;
  let lowAvarageRothIRAPercentage = 0;
  let highAvarageRothIRAPercentage = 0;

  let middleAvarageSephIRAPercentage = 0;
  let lowAvarageSephIRAPercentage = 0;
  let highAvarageSephIRAPercentage = 0;

  let middleAvaragePensionPercentage = 0;
  let lowAvaragePensionPercentage = 0;
  let highAvaragePensionPercentage = 0;

  const outputBackendRetirementData = {};
  let employerContributionsVal = 0;
  let employerContributionsVal1 = 0;

  // 401K Personal Contributions
  outputBackendRetirementData['401k'] = {};
  // High Return
  outputBackendRetirementData['401k1'] = {};

  // Low Returb
  outputBackendRetirementData['401k Employer Comtributions'] = {};
  // High Return
  outputBackendRetirementData['401k1 Employer Comtributions'] = {};

  outputBackendRetirementData['401k Actual'] = {};
  outputBackendRetirementData['Roth IRA Actual'] = {};
  outputBackendRetirementData['Roth IRA Low'] = {};
  outputBackendRetirementData['Roth IRA High'] = {};
  outputBackendRetirementData['Seph IRA Actual'] = {};
  outputBackendRetirementData['Seph IRA Low'] = {};
  outputBackendRetirementData['Seph IRA High'] = {};
  outputBackendRetirementData['Pension Actual'] = {};
  outputBackendRetirementData['Pension Low'] = {};
  outputBackendRetirementData['Pension High'] = {};

  let actualPersonalbalance = 0;
  let actualPersonalEarnings = 0;
  let actualEmployerContributions = 0;
  let actualEmployerEarnings = 0;
  let actualEmployerVestingReductions = 0;
  let actualEmployerReduction = 0;
  let actualEmployerEndingBalance = 0;

  outputBackendRetirementData['401k Low'] = {};
  outputBackendRetirementData['401k High'] = {};
  outputBackendRetirementData['401k Sensitivity Analysis'] = {};

  // Roth IRA
  outputBackendRetirementData['Roth IRA Low Month'] = {};
  outputBackendRetirementData['Roth IRA High Month'] = {};
  outputBackendRetirementData['Roth IRA Sensitivity Analysis'] = {};

  // Seph IRA
  outputBackendRetirementData['Seph IRA Low Month'] = {};
  outputBackendRetirementData['Seph IRA High Month'] = {};
  outputBackendRetirementData['Seph IRA Sensitivity Analysis'] = {};

  // Pension
  outputBackendRetirementData['Pension Low Month'] = {};
  outputBackendRetirementData['Pension High Month'] = {};
  outputBackendRetirementData['Pension Sensitivity Analysis'] = {};

  // const lowEmployerEarnings = 0;

  // 401k Sensitivity Analysis Value
  if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Total Annual PreTax Expected Return'] !== undefined ) {
    middleAvarage401kPercentage = investmentsData['Total Annual PreTax Expected Return'] / 15;
  } else {
    middleAvarage401kPercentage = 0;
  }
  // Low Value
  lowAvarage401kPercentage = middleAvarage401kPercentage - ( middleAvarage401kPercentage / 2 );
  // High Values
  highAvarage401kPercentage = middleAvarage401kPercentage + ( middleAvarage401kPercentage / 2 );

  // Roth IRA Sensitivity Analysis
  if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Total Annual PreTax Expected Return Roth IRA'] !== undefined ) {
    middleAvarageRothIRAPercentage = investmentsData['Total Annual PreTax Expected Return Roth IRA'] / 15;
  } else {
    middleAvarageRothIRAPercentage = 0;
  }
  // Low Value
  lowAvarageRothIRAPercentage = middleAvarageRothIRAPercentage - ( middleAvarageRothIRAPercentage / 2 );
  // High Values
  highAvarageRothIRAPercentage = middleAvarageRothIRAPercentage + ( middleAvarageRothIRAPercentage / 2 );

  // Seph IRA Sensitivity Analysis
  if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Total Annual PreTax Expected Return Seph IRA'] !== undefined ) {
    middleAvarageSephIRAPercentage = investmentsData['Total Annual PreTax Expected Return Seph IRA'] / 15;
  } else {
    middleAvarageSephIRAPercentage = 0;
  }
  // Low Value
  lowAvarageSephIRAPercentage = middleAvarageSephIRAPercentage - ( middleAvarageSephIRAPercentage / 2 );
  // High Values
  highAvarageSephIRAPercentage = middleAvarageSephIRAPercentage + ( middleAvarageSephIRAPercentage / 2 );

  // Pension Sensitivity Analysis
  if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Total Annual PreTax Expected Return Pension'] !== undefined ) {
    middleAvaragePensionPercentage = investmentsData['Total Annual PreTax Expected Return Pension'] / 15;
  } else {
    middleAvaragePensionPercentage = 0;
  }
  // Low Value
  lowAvaragePensionPercentage = middleAvaragePensionPercentage - ( middleAvaragePensionPercentage / 2 );
  // High Values
  highAvaragePensionPercentage = middleAvaragePensionPercentage + ( middleAvaragePensionPercentage / 2 );

  // Roth IRA Actual value
  let rothIraBeginningBalance = 0;
  let rothIraPersonalContribution = 0;
  let rothIraEarnings = 0;
  let rothIraEndingBalance = 0;
  // Roth IRA Low values
  let rothIRALowEndingBalance = 0;
  let rothIRAHighEndingBalance = 0;

  // Seph IRA Actual value
  let sephIraBeginningBalance = 0;
  let sephIraPersonalContribution = 0;
  let sephIraEarnings = 0;
  let sephIraEndingBalance = 0;
  // Seph IRA Low values
  let sephIRALowEndingBalance = 0;
  let sephIRAHighEndingBalance = 0;

  // Pension Actual value
  let pensionBeginningBalance = 0;
  let pensionPersonalContribution = 0;
  let pensionEmployerContribution = 0;
  let pensionEarnings = 0;
  let pensionEndingBalance = 0;
  // pension Low values
  let pensionLowEndingBalance = 0;
  let pensionHighEndingBalance = 0;

  for ( year; year < yearLimit; year += 1 ) {
    /** 401K Personal Comtributions* */
    outputBackendRetirementData['401k'][year] = {};
    outputBackendRetirementData['401k1'][year] = {};
    outputBackendRetirementData['401k Employer Comtributions'][year] = {};
    outputBackendRetirementData['401k1 Employer Comtributions'][year] = {};
    outputBackendRetirementData['401k Actual'][year] = {};
    outputBackendRetirementData['401k Low'][year] = {};
    outputBackendRetirementData['401k High'][year] = {};
    outputBackendRetirementData['401k Sensitivity Analysis'][year] = {};
    outputBackendRetirementData['Roth IRA Actual'][year] = {};
    outputBackendRetirementData['Seph IRA Actual'][year] = {};

    // Roth IRA Values
    outputBackendRetirementData['Roth IRA Low'][year] = {};
    outputBackendRetirementData['Roth IRA High'][year] = {};
    outputBackendRetirementData['Roth IRA Low Month'][year] = {};
    outputBackendRetirementData['Roth IRA High Month'][year] = {};
    outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year] = {};

    // Seph IRA Values
    outputBackendRetirementData['Seph IRA Low'][year] = {};
    outputBackendRetirementData['Seph IRA High'][year] = {};
    outputBackendRetirementData['Seph IRA Low Month'][year] = {};
    outputBackendRetirementData['Seph IRA High Month'][year] = {};
    outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year] = {};

    // Pension Values
    outputBackendRetirementData['Pension Actual'][year] = {};
    outputBackendRetirementData['Pension Low'][year] = {};
    outputBackendRetirementData['Pension High'][year] = {};
    outputBackendRetirementData['Pension Low Month'][year] = {};
    outputBackendRetirementData['Pension High Month'][year] = {};
    outputBackendRetirementData['Pension Sensitivity Analysis'][year] = {};

    // Actual Values for 401k
    if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Personal Contribution'] !== '' ) {
      actualPersonalbalance = investmentsData['401k Second'][year]['Personal Contribution'];
    } else {
      actualPersonalbalance = 0;
    }
    outputBackendRetirementData['401k Actual'][year]['Personal Contributions'] = actualPersonalbalance;

    // Personal Earnings
    if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Personal Earnings'] !== '' ) {
      actualPersonalEarnings = investmentsData['401k Second'][year]['Personal Earnings'];
    } else {
      actualPersonalEarnings = 0;
    }
    outputBackendRetirementData['401k Actual'][year]['Personal Earnings'] = actualPersonalEarnings;

    // Employer Contributions
    if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Employer Contribution'] !== '' ) {
      actualEmployerContributions = investmentsData['401k Second'][year]['Employer Contribution'];
    } else {
      actualEmployerContributions = 0;
    }
    outputBackendRetirementData['401k Actual'][year]['Employer Contributions'] = actualEmployerContributions;

    // Employer Earnings
    if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Employer Earnings'] !== '' ) {
      actualEmployerEarnings = investmentsData['401k Second'][year]['Employer Earnings'];
    } else {
      actualEmployerEarnings = 0;
    }
    outputBackendRetirementData['401k Actual'][year]['Employer Earnings'] = actualEmployerEarnings;

    // Employer Vesting Reductions
    if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Vesting Reductions'] !== '' ) {
      actualEmployerVestingReductions = investmentsData['401k Second'][year]['Vesting Reductions'];
    } else {
      actualEmployerVestingReductions = 0;
    }
    outputBackendRetirementData['401k Actual'][year]['Employer Vesting Reductions'] = actualEmployerVestingReductions;

    // Reduction %
    if ( module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year && module4Data.vestedAfterFewyears !== undefined ) {
      actualEmployerReduction = 100 - module4Data.vestedAfterFewyears;
    } else if ( module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year && module5Data.vestedAfteryearAtSchedule !== undefined ) {
      actualEmployerReduction = 100 - module5Data.vestedAfteryearAtSchedule;
    } else if ( module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year && module8Data.vestedAfterFewYearsScheduleAdd !== undefined ) {
      actualEmployerReduction = 100 - module8Data.vestedAfterFewYearsScheduleAdd;
    } else if ( module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year && module17Data.vestedAfterFewYearsScheduleFurther !== undefined ) {
      actualEmployerReduction = 100 - module17Data.vestedAfterFewYearsScheduleFurther;
    } else {
      actualEmployerReduction = 0;
    }
    outputBackendRetirementData['401k Actual'][year]['% Reductions'] = actualEmployerReduction;

    // Ending Balance
    if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Ending Total Balance'] !== '' ) {
      actualEmployerEndingBalance = investmentsData['401k Second'][year]['Ending Total Balance'];
    } else {
      actualEmployerEndingBalance = 0;
    }
    outputBackendRetirementData['401k Actual'][year]['Ending Balance'] = actualEmployerEndingBalance;

    // let begBalanceInitialEmployer = 0;
    // let begBalanceInitialEmployer1 = 0;
    // let contributionTotalEmployer = 0;
    // let contributionTotalEmployer1 = 0;
    let earningsTotalEmployer = 0;
    let earningsTotalEmployer1 = 0;

    for ( let month = 1; month <= 12; month += 1 ) {
      // let begBalanceInitial401k = 0;
      // let contributionTotal401k = 0;
      // let contributionTotalRothIRA = 0;
      // let contributionTotalRothIRAHigh = 0;
      // let contributionTotalSephIRA = 0;
      // let contributionTotalSephIRAHigh = 0;
      /* let contributionTotalPension = 0;
      let contributionTotalPensionHigh = 0; */
      // let earningsTotal401k = 0;
      // let begBalanceInitial401k1 = 0;
      // let contributionTotal401k1 = 0;
      // let earningsTotal401k1 = 0;

      /* let begBalanceInitialRothIra = 0;
      let begBalanceInitialRothIraHigh = 0; */
      /* let earningsTotalRothIRA = 0;
      let earningsTotalRothIRAHigh = 0; */

      /* let begBalanceInitialSephIra = 0;
      let begBalanceInitialSephIraHigh = 0;
      let earningsTotalSephIRA = 0;
      let earningsTotalSephIRAHigh = 0; */

      /* let begBalanceInitialPension = 0;
      let begBalanceInitialPensionHigh = 0;
      let earningsTotalPension = 0;
      let earningsTotalPensionHigh = 0; */

      // const begBalanceInitialRoth = 0;
      // const contributionTotalRoth = 0;
      // const earningsTotalRoth = 0;
      let totalBegBalance = 0;
      let totalBegBalance1 = 0;

      outputBackendRetirementData['401k'][year][month] = {};
      outputBackendRetirementData['401k1'][year][month] = {};
      // Roth IRA Values
      outputBackendRetirementData['Roth IRA Low Month'][year][month] = {};
      outputBackendRetirementData['Roth IRA High Month'][year][month] = {};
      // Seph IRA Values
      outputBackendRetirementData['Seph IRA Low Month'][year][month] = {};
      outputBackendRetirementData['Seph IRA High Month'][year][month] = {};
      // Pension Values
      outputBackendRetirementData['Pension Low Month'][year][month] = {};
      outputBackendRetirementData['Pension High Month'][year][month] = {};

      // Beg Balance
      outputBackendRetirementData['Roth IRA Low Month'][year][month]['Beg Balance'] = begBalanceRothIra;
      if ( month === 1 ) {
        // begBalanceInitialRothIra = begBalanceRothIra;
      }
      // Beg Balance
      outputBackendRetirementData['Roth IRA High Month'][year][month]['Beg Balance'] = begBalanceRothIraHigh;
      if ( month === 1 ) {
        // begBalanceInitialRothIraHigh = begBalanceRothIraHigh;
      }

      // Beg Balance
      outputBackendRetirementData['Seph IRA Low Month'][year][month]['Beg Balance'] = begBalanceSephIra;
      if ( month === 1 ) {
        // begBalanceInitialSephIra = begBalanceSephIra;
      }

      // Beg Balance Pension
      outputBackendRetirementData['Pension Low Month'][year][month]['Beg Balance'] = begBalancePension;
      if ( month === 1 ) {
        // begBalanceInitialPension = begBalancePension;
      }

      // Beg Balance
      outputBackendRetirementData['Seph IRA High Month'][year][month]['Beg Balance'] = begBalanceSephIraHigh;
      if ( month === 1 ) {
        // begBalanceInitialSephIraHigh = begBalanceSephIraHigh;
      }

      // Beg Balance  Pension High
      outputBackendRetirementData['Pension High Month'][year][month]['Beg Balance'] = begBalancePensionHigh;
      if ( month === 1 ) {
        // begBalanceInitialPensionHigh = begBalancePensionHigh;
      }

      // Contribution Roth IRA
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth IRA'][year] !== undefined && investmentsData['Roth IRA'][year][month].Contribution !== '' ) {
        contributionRothIRA = investmentsData['Roth IRA'][year][month].Contribution;
      } else {
        contributionRothIRA = 0;
      }
      outputBackendRetirementData['Roth IRA Low Month'][year][month].Contribution = contributionRothIRA;
      // contributionTotalRothIRA += contributionRothIRA;

      // Contribution Seph IRA
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Seph IRA'][year] !== undefined && investmentsData['Seph IRA'][year][month].Contribution !== '' ) {
        contributionSephIRA = investmentsData['Seph IRA'][year][month].Contribution;
      } else {
        contributionSephIRA = 0;
      }
      outputBackendRetirementData['Seph IRA Low Month'][year][month].Contribution = contributionSephIRA;
      // contributionTotalSephIRA += contributionSephIRA;

      // Contribution Pension
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData.Pension[year] !== undefined && investmentsData.Pension[year][month].Contribution !== '' ) {
        contributionPension = investmentsData.Pension[year][month].Contribution;
      } else {
        contributionPension = 0;
      }
      outputBackendRetirementData['Pension Low Month'][year][month].Contribution = contributionPension;
      // contributionTotalPension += contributionPension;

      // Contribution Roth IRA High
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth IRA'][year] !== undefined && investmentsData['Roth IRA'][year][month].Contribution !== '' ) {
        contributionRothIRAHigh = investmentsData['Roth IRA'][year][month].Contribution;
      } else {
        contributionRothIRAHigh = 0;
      }
      outputBackendRetirementData['Roth IRA High Month'][year][month].Contribution = contributionRothIRAHigh;
      // contributionTotalRothIRAHigh += contributionRothIRAHigh;

      // Contribution Seph IRA High
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['Roth IRA'][year] !== undefined && investmentsData['Seph IRA'][year][month].Contribution !== '' ) {
        contributionSephIRAHigh = investmentsData['Seph IRA'][year][month].Contribution;
      } else {
        contributionSephIRAHigh = 0;
      }
      outputBackendRetirementData['Seph IRA High Month'][year][month].Contribution = contributionSephIRAHigh;
      // contributionTotalSephIRAHigh += contributionSephIRAHigh;

      // Contribution Pension High
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData.Pension[year] !== undefined && investmentsData.Pension[year][month].Contribution !== '' ) {
        contributionPensionHigh = investmentsData.Pension[year][month].Contribution;
      } else {
        contributionPensionHigh = 0;
      }
      outputBackendRetirementData['Pension High Month'][year][month].Contribution = contributionPensionHigh;
      // contributionTotalPensionHigh += contributionPensionHigh;

      // Earnings Roth IRA
      firstVarRothIRA = begBalanceRothIra + contributionRothIRA;
      avarageRothIRA = ( firstVarRothIRA + begBalanceRothIra ) / 2;
      if ( avarageRothIRA < begBalanceRothIra ) {
        avarageRothIRA = begBalanceRothIra;
      }
      if ( lowAvarageRothIRAPercentage > 0 ) {
        earningsRothIRA = avarageRothIRA * ( ( lowAvarageRothIRAPercentage / 100 ) / 12 );
      } else {
        earningsRothIRA = 0;
      }
      outputBackendRetirementData['Roth IRA Low Month'][year][month].Earnings = earningsRothIRA;
      // earningsTotalRothIRA += earningsRothIRA;

      // Earnings Seph IRA
      firstVarSephIRA = begBalanceSephIra + contributionSephIRA;
      avarageSephIRA = ( firstVarSephIRA + begBalanceSephIra ) / 2;
      if ( avarageSephIRA < begBalanceSephIra ) {
        avarageSephIRA = begBalanceSephIra;
      }
      if ( lowAvarageSephIRAPercentage > 0 ) {
        earningsSephIRA = avarageSephIRA * ( ( lowAvarageSephIRAPercentage / 100 ) / 12 );
      } else {
        earningsSephIRA = 0;
      }
      outputBackendRetirementData['Seph IRA Low Month'][year][month].Earnings = earningsSephIRA;
      // earningsTotalSephIRA += earningsSephIRA;

      // Earnings Seph IRA
      firstVarPension = begBalancePension + contributionPension;
      avaragePension = ( firstVarPension + begBalancePension ) / 2;
      if ( avaragePension < begBalancePension ) {
        avaragePension = begBalancePension;
      }
      if ( lowAvaragePensionPercentage > 0 ) {
        earningsPension = avaragePension * ( ( lowAvaragePensionPercentage / 100 ) / 12 );
      } else {
        earningsPension = 0;
      }
      outputBackendRetirementData['Pension Low Month'][year][month].Earnings = earningsPension;
      // earningsTotalPension += earningsPension;

      // Earnings Roth IRA High
      firstVarRothIRAHigh = begBalanceRothIraHigh + contributionRothIRAHigh;
      avarageRothIRAHigh = ( firstVarRothIRAHigh + begBalanceRothIraHigh ) / 2;
      if ( avarageRothIRAHigh < begBalanceRothIraHigh ) {
        avarageRothIRAHigh = begBalanceRothIraHigh;
      }
      if ( highAvarageRothIRAPercentage > 0 ) {
        earningsRothIRAHigh = avarageRothIRA * ( ( highAvarageRothIRAPercentage / 100 ) / 12 );
      } else {
        earningsRothIRAHigh = 0;
      }
      outputBackendRetirementData['Roth IRA High Month'][year][month].Earnings = earningsRothIRAHigh;
      // earningsTotalRothIRAHigh += earningsRothIRAHigh;

      // Earnings Seph IRA High
      firstVarSephIRAHigh = begBalanceSephIraHigh + contributionSephIRAHigh;
      avarageSephIRAHigh = ( firstVarSephIRAHigh + begBalanceSephIraHigh ) / 2;
      if ( avarageSephIRAHigh < begBalanceSephIraHigh ) {
        avarageSephIRAHigh = begBalanceSephIraHigh;
      }
      if ( highAvarageSephIRAPercentage > 0 ) {
        earningsSephIRAHigh = avarageSephIRA * ( ( highAvarageSephIRAPercentage / 100 ) / 12 );
      } else {
        earningsSephIRAHigh = 0;
      }
      outputBackendRetirementData['Seph IRA High Month'][year][month].Earnings = earningsSephIRAHigh;
      // earningsTotalSephIRAHigh += earningsSephIRAHigh;

      // Earnings Pension High
      firstVarPensionHigh = begBalancePensionHigh + contributionPensionHigh;
      avaragePensionHigh = ( firstVarPensionHigh + begBalancePensionHigh ) / 2;
      if ( avaragePensionHigh < begBalancePensionHigh ) {
        avaragePensionHigh = begBalancePensionHigh;
      }
      if ( highAvaragePensionPercentage > 0 ) {
        earningsPensionHigh = avaragePension * ( ( highAvaragePensionPercentage / 100 ) / 12 );
      } else {
        earningsPensionHigh = 0;
      }
      outputBackendRetirementData['Pension High Month'][year][month].Earnings = earningsPensionHigh;
      // earningsTotalPensionHigh += earningsPensionHigh;

      // End Balance Roth IRA
      endBalanceRothIRA = begBalanceRothIra + contributionRothIRA + earningsRothIRA;
      outputBackendRetirementData['Roth IRA Low Month'][year][month]['End Balance'] = endBalanceRothIRA;
      begBalanceRothIra = endBalanceRothIRA;

      // End Balance Seph IRA
      endBalanceSephIRA = begBalanceSephIra + contributionSephIRA + earningsSephIRA;
      outputBackendRetirementData['Seph IRA Low Month'][year][month]['End Balance'] = endBalanceSephIRA;
      begBalanceSephIra = endBalanceSephIRA;

      // End Balance Pension
      endBalancePension = begBalancePension + contributionPension + earningsPension;
      outputBackendRetirementData['Pension Low Month'][year][month]['End Balance'] = endBalancePension;
      begBalancePension = endBalancePension;

      // End Balance Roth IRA High
      endBalanceRothIRAHigh = begBalanceRothIraHigh + contributionRothIRAHigh + earningsRothIRAHigh;
      outputBackendRetirementData['Roth IRA High Month'][year][month]['End Balance'] = endBalanceRothIRAHigh;
      begBalanceRothIraHigh = endBalanceRothIRAHigh;

      // End Balance Seph IRA High
      endBalanceSephIRAHigh = begBalanceSephIraHigh + contributionSephIRAHigh + earningsSephIRAHigh;
      outputBackendRetirementData['Seph IRA High Month'][year][month]['End Balance'] = endBalanceSephIRAHigh;
      begBalanceSephIraHigh = endBalanceSephIRAHigh;

      // End Balance Seph IRA High
      endBalancePensionHigh = begBalancePensionHigh + contributionPensionHigh + earningsPensionHigh;
      outputBackendRetirementData['Pension High Month'][year][month]['End Balance'] = endBalancePensionHigh;
      begBalancePensionHigh = endBalancePensionHigh;

      // Total Beg Balance
      if ( module1Data.start_year !== undefined && year === module1Data.start_year && month === 1 ) {
        totalBegBalance = begBalance401k;
      } else {
        totalBegBalance = totalEndingBalance;
      }
      outputBackendRetirementData['401k'][year][month]['Total Beg Balance'] = totalBegBalance;

      // Total Beg Balance High Returns 401k
      if ( module1Data.start_year !== undefined && year === module1Data.start_year && month === 1 ) {
        totalBegBalance1 = begBalance401k1;
      } else {
        totalBegBalance1 = totalEndingBalance1;
      }
      outputBackendRetirementData['401k1'][year][month]['Total Beg Balance'] = totalBegBalance1;

      // Beg Balance
      outputBackendRetirementData['401k'][year][month]['Beg Balance'] = begBalance401k;
      if ( month === 1 ) {
        // begBalanceInitial401k = begBalance401k;
      }
      // Beg Balance High Returns 401k
      outputBackendRetirementData['401k1'][year][month]['Beg Balance'] = begBalance401k1;
      if ( month === 1 ) {
        // begBalanceInitial401k1 = begBalance401k1;
      }

      // Contribution
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k'][year] !== undefined && investmentsData['401k'][year][month].Contribution !== '' ) {
        contribution401k = investmentsData['401k'][year][month].Contribution;
      } else {
        contribution401k = 0;
      }
      outputBackendRetirementData['401k'][year][month].Contribution = contribution401k;
      // contributionTotal401k += contribution401k;

      // Contribution High Returns 401k
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k'][year] !== undefined && investmentsData['401k'][year][month].Contribution !== '' ) {
        contribution401k1 = investmentsData['401k'][year][month].Contribution;
      } else {
        contribution401k1 = 0;
      }
      outputBackendRetirementData['401k1'][year][month].Contribution = contribution401k1;
      // contributionTotal401k1 += contribution401k1;

      // Earnings
      firstlet = begBalance401k + contribution401k;
      avarage401k = ( firstlet + begBalance401k ) / 2;
      if ( avarage401k < begBalance401k ) {
        avarage401k = begBalance401k;
      }
      if ( lowAvarage401kPercentage > 0 ) {
        earnings401k = avarage401k * ( ( lowAvarage401kPercentage / 100 ) / 12 );
      } else {
        earnings401k = 0;
      }

      outputBackendRetirementData['401k'][year][month].Earnings = earnings401k;
      // earningsTotal401k += earnings401k;

      // Earnings High Returns 401k
      firstVar1 = begBalance401k1 + contribution401k1;
      avarage401k1 = ( firstVar1 + begBalance401k1 ) / 2;
      if ( avarage401k1 < begBalance401k1 ) {
        avarage401k1 = begBalance401k1;
      }
      if ( highAvarage401kPercentage > 0 ) {
        earnings401k1 = avarage401k1 * ( ( highAvarage401kPercentage / 100 ) / 12 );
      } else {
        earnings401k1 = 0;
      }

      outputBackendRetirementData['401k1'][year][month].Earnings = earnings401k1;
      // earningsTotal401k1 += earnings401k1;

      // End Balance
      endBalance401k = begBalance401k + contribution401k + earnings401k;
      outputBackendRetirementData['401k'][year][month]['End Balance'] = endBalance401k;
      begBalance401k = endBalance401k;

      // End Balance High Returns 401k
      endBalance401k1 = begBalance401k1 + contribution401k1 + earnings401k1;
      outputBackendRetirementData['401k1'][year][month]['End Balance'] = endBalance401k1;
      begBalance401k1 = endBalance401k1;

      // Employer Contributions
      outputBackendRetirementData['401k Employer Comtributions'][year][month] = {};
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month] = {};

      // Beg Balance
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['Beg Balance'] = begBalanceEmployer;
      if ( month === 1 ) {
        // begBalanceInitialEmployer = begBalanceEmployer;
      }
      if ( month === 1 ) {
        outputBackendRetirementData['401k Employer Comtributions'][year][month].Year = year;
      } else {
        outputBackendRetirementData['401k Employer Comtributions'][year][month].Year = '';
      }

      // Beg Balance High Returns 401k
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Beg Balance'] = begBalanceEmployer1;
      if ( month === 1 ) {
        // begBalanceInitialEmployer1 = begBalanceEmployer1;
      }
      if ( month === 1 ) {
        outputBackendRetirementData['401k1 Employer Comtributions'][year][month].Year = year;
      } else {
        outputBackendRetirementData['401k1 Employer Comtributions'][year][month].Year = '';
      }

      // Contribution
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Employer Comtributions'][year] !== undefined && investmentsData['401k Employer Comtributions'][year][month] !== undefined && investmentsData['401k Employer Comtributions'][year][month].Contribution !== '' ) {
        employerContribution = investmentsData['401k Employer Comtributions'][year][month].Contribution;
      } else {
        employerContribution = 0;
      }
      outputBackendRetirementData['401k Employer Comtributions'][year][month].Contribution = employerContribution;
      // contributionTotalEmployer += employerContribution;

      // Contribution High Returns 401k
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Employer Comtributions'][year] !== undefined && investmentsData['401k Employer Comtributions'][year][month] !== undefined && investmentsData['401k Employer Comtributions'][year][month].Contribution !== '' ) {
        employerContribution1 = investmentsData['401k Employer Comtributions'][year][month].Contribution;
      } else {
        employerContribution1 = 0;
      }
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month].Contribution = employerContribution1;
      // contributionTotalEmployer1 += employerContribution1;

      // Earnings
      employerlet = begBalanceEmployer + employerContribution;
      avarageEmployer = ( employerlet + begBalanceEmployer ) / 2;
      if ( avarageEmployer < begBalanceEmployer ) {
        avarageEmployer = begBalanceEmployer;
      }
      employerEarnings = avarageEmployer * ( ( lowAvarage401kPercentage / 100 ) / 12 );
      outputBackendRetirementData['401k Employer Comtributions'][year][month].Earnings = employerEarnings;
      earningsTotalEmployer += employerEarnings;

      // Earnings High Returns 401k
      employerVar1 = begBalanceEmployer1 + employerContribution1;
      avarageEmployer1 = ( employerVar1 + begBalanceEmployer1 ) / 2;
      if ( avarageEmployer1 < begBalanceEmployer1 ) {
        avarageEmployer1 = begBalanceEmployer1;
      }
      employerEarnings1 = avarageEmployer1 * ( ( highAvarage401kPercentage / 100 ) / 12 );
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month].Earnings = employerEarnings1;
      earningsTotalEmployer1 += employerEarnings1;

      // Balance before Vesting
      balanceBeforeVesting = begBalanceEmployer + employerContribution + employerEarnings;
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['Balance before Vesting'] = balanceBeforeVesting;

      // Balance before Vesting High Returns 401k
      balanceBeforeVesting1 = begBalanceEmployer1 + employerContribution1 + employerEarnings1;
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Balance before Vesting'] = balanceBeforeVesting1;

      // Employer Contributions
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Employer Contribution'] !== '' ) {
        employerContributionsVal = investmentsData['401k Second'][year]['Employer Contribution'];
      } else {
        employerContributionsVal = 0;
      }
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['Employer Contributions'] = employerContributionsVal;

      // Employer Contributions High Returns 401k
      if ( investmentsData !== undefined && investmentsData !== '' && investmentsData['401k Second'][year] !== undefined && investmentsData['401k Second'][year]['Employer Contribution'] !== '' ) {
        employerContributionsVal1 = investmentsData['401k Second'][year]['Employer Contribution'];
      } else {
        employerContributionsVal1 = 0;
      }
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Employer Contributions'] = employerContributionsVal1;

      // Earnings Value  401k
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['Total Earnings'] = earningsTotalEmployer;

      // Earnings Value  401k High Returns 401k
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Total Earnings'] = earningsTotalEmployer1;

      // Cumulative Employer Contributions & Earnings per Career Module
      if ( month === 12 ) {
        if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year ) {
          const startYear = parseInt( module4Data.start_year, 10 );
          const endYear = parseInt( module4Data.end_year, 10 );
          if ( outputBackendRetirementData['401k Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( year1 => {
              if ( year1 >= startYear && year1 <= endYear ) {
                cumulativeEmployerContributionEarnings1 += lowData[year1][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings1 += lowData[year1][month]['Total Earnings'];
              }
            } );
          }
        } else if ( module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year ) {
          const startYear = parseInt( module5Data.start_year, 10 );
          const endYear = parseInt( module5Data.end_year, 10 );
          if ( outputBackendRetirementData['401k Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( ( year2 ) => {
              if ( year2 >= startYear && year2 <= endYear ) {
                cumulativeEmployerContributionEarnings1 += lowData[year2][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings1 += lowData[year2][month]['Total Earnings'];
              }
            } );
          }
        } else if ( module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year ) {
          const startYear = parseInt( module8Data.start_year, 10 );
          const endYear = parseInt( module8Data.end_year, 10 );
          if ( outputBackendRetirementData['401k Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( ( year3 ) => {
              if ( year3 >= startYear && year3 <= endYear ) {
                cumulativeEmployerContributionEarnings1 += lowData[year3][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings1 += lowData[year3][month]['Total Earnings'];
              }
            } );
          }
        } else if ( module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year ) {
          const startYear = parseInt( module17Data.start_year, 10 );
          const endYear = parseInt( module17Data.end_year, 10 );
          if ( outputBackendRetirementData['401k Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( ( year4 ) => {
              if ( year4 >= startYear && year4 <= endYear ) {
                cumulativeEmployerContributionEarnings1 += lowData[year4][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings1 += lowData[year4][month]['Total Earnings'];
              }
            } );
          }
        } else {
          cumulativeEmployerContributionEarnings1 = 0;
        }
      } else {
        cumulativeEmployerContributionEarnings1 = 0;
      }
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['Cumulative Employer Contributions And Earnings'] = cumulativeEmployerContributionEarnings1;

      // Cumulative Employer Contributions & Earnings per Career Module High Returns 401k
      if ( month === 12 ) {
        if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year ) {
          const startYear = parseInt( module4Data.start_year, 10 );
          const endYear = parseInt( module4Data.end_year, 10 );
          if ( outputBackendRetirementData['401k1 Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k1 Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( ( year5 ) => {
              if ( year5 >= startYear && year5 <= endYear ) {
                cumulativeEmployerContributionEarnings2 += lowData[year5][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings2 += lowData[year5][month]['Total Earnings'];
              }
            } );
          }
        } else if ( module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year ) {
          const startYear = parseInt( module5Data.start_year, 10 );
          const endYear = parseInt( module5Data.end_year, 10 );
          if ( outputBackendRetirementData['401k1 Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k1 Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( ( year6 ) => {
              if ( year6 >= startYear && year6 <= endYear ) {
                cumulativeEmployerContributionEarnings2 += lowData[year6][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings2 += lowData[year6][month]['Total Earnings'];
              }
            } );
          }
        } else if ( module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year ) {
          const startYear = parseInt( module8Data.start_year, 10 );
          const endYear = parseInt( module8Data.end_year, 10 );
          if ( outputBackendRetirementData['401k1 Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k1 Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( ( year7 ) => {
              if ( year7 >= startYear && year7 <= endYear ) {
                cumulativeEmployerContributionEarnings2 += lowData[year7][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings2 += lowData[year7][month]['Total Earnings'];
              }
            } );
          }
        } else if ( module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year ) {
          const startYear = parseInt( module17Data.start_year, 10 );
          const endYear = parseInt( module17Data.end_year, 10 );
          if ( outputBackendRetirementData['401k1 Employer Comtributions'] !== undefined ) {
            const lowData = outputBackendRetirementData['401k1 Employer Comtributions'];
            // eslint-disable-next-line
            Object.keys( lowData ).forEach( ( year8 ) => {
              if ( year8 >= startYear && year8 <= endYear ) {
                cumulativeEmployerContributionEarnings2 += lowData[year8][month]['Employer Contributions'];
                cumulativeEmployerContributionEarnings2 += lowData[year8][month]['Total Earnings'];
              }
            } );
          }
        } else {
          cumulativeEmployerContributionEarnings2 = 0;
        }
      } else {
        cumulativeEmployerContributionEarnings2 = 0;
      }
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Cumulative Employer Contributions And Earnings'] = cumulativeEmployerContributionEarnings2;

      // Reduction Value
      let actualEmployerReduction1 = 0;
      if ( module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year && module4Data.vestedAfterFewyears !== undefined ) {
        actualEmployerReduction1 = 100 - module4Data.vestedAfterFewyears;
      } else if ( module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year && module5Data.vestedAfteryearAtSchedule !== undefined ) {
        actualEmployerReduction1 = 100 - module5Data.vestedAfteryearAtSchedule;
      } else if ( module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year && module8Data.vestedAfterFewYearsScheduleAdd !== undefined ) {
        actualEmployerReduction1 = 100 - module8Data.vestedAfterFewYearsScheduleAdd;
      } else if ( module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year && module17Data.vestedAfterFewYearsScheduleFurther !== undefined ) {
        actualEmployerReduction1 = 100 - module17Data.vestedAfterFewYearsScheduleFurther;
      } else {
        actualEmployerReduction1 = 0;
      }
      outputBackendRetirementData['401k Employer Comtributions'][year][month].Reductions = actualEmployerReduction1;

      // Reduction Value High Returns 401k

      if ( module4Data.contribute_401k !== undefined && module4Data.contribute_401k === 'Yes' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year && module4Data.vestedAfterFewyears !== undefined ) {
        actualEmployerReduction2 = 100 - module4Data.vestedAfterFewyears;
      } else if ( module5Data.contribute_401k !== undefined && module5Data.contribute_401k === 'Yes' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year && module5Data.vestedAfteryearAtSchedule !== undefined ) {
        actualEmployerReduction2 = 100 - module5Data.vestedAfteryearAtSchedule;
      } else if ( module8Data.contribute_401k !== undefined && module8Data.contribute_401k === 'Yes' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year && module8Data.vestedAfterFewYearsScheduleAdd !== undefined ) {
        actualEmployerReduction2 = 100 - module8Data.vestedAfterFewYearsScheduleAdd;
      } else if ( module17Data.contribute_401k !== undefined && module17Data.contribute_401k === 'Yes' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year && module17Data.vestedAfterFewYearsScheduleFurther !== undefined ) {
        actualEmployerReduction2 = 100 - module17Data.vestedAfterFewYearsScheduleFurther;
      } else {
        actualEmployerReduction2 = 0;
      }
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month].Reductions = actualEmployerReduction2;

      // Vesting Reductions
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['Vesting Reductions'] = -( cumulativeEmployerContributionEarnings1 / 100 ) * actualEmployerReduction1;

      // Vesting Reductions High Returns 401k
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Vesting Reductions'] = -( cumulativeEmployerContributionEarnings2 / 100 ) * actualEmployerReduction2;

      // End Balance
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['End Balance'] = outputBackendRetirementData['401k Employer Comtributions'][year][month]['Balance before Vesting'] + outputBackendRetirementData['401k Employer Comtributions'][year][month]['Vesting Reductions'];
      begBalanceEmployer = outputBackendRetirementData['401k Employer Comtributions'][year][month]['End Balance'];

      // End Balance High Returns 401k
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['End Balance'] = outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Balance before Vesting'] + outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Vesting Reductions'];
      begBalanceEmployer1 = outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['End Balance'];

      // Total Ending Balance
      totalEndingBalance = outputBackendRetirementData['401k'][year][month]['End Balance'] + outputBackendRetirementData['401k Employer Comtributions'][year][month]['End Balance'];
      outputBackendRetirementData['401k Employer Comtributions'][year][month]['Total Ending Balance'] = totalEndingBalance;

      // Total Ending Balance High Returns 401k
      totalEndingBalance1 = outputBackendRetirementData['401k1'][year][month]['End Balance'] + outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['End Balance'];
      outputBackendRetirementData['401k1 Employer Comtributions'][year][month]['Total Ending Balance'] = totalEndingBalance1;
    }

    // Earnings Value  401k
    outputBackendRetirementData['401k Employer Comtributions'][year].Earnings = earningsTotalEmployer;
    if ( k <= 14 ) {
      earningsTotalEmployer += outputBackendRetirementData['401k Employer Comtributions'][year].Earnings;
    }

    // Earnings Value High Returns 401k
    outputBackendRetirementData['401k1 Employer Comtributions'][year].Earnings = earningsTotalEmployer1;
    if ( k <= 14 ) {
      earningsTotalEmployer1 += outputBackendRetirementData['401k1 Employer Comtributions'][year].Earnings;
    }

    // LOW Values
    outputBackendRetirementData['401k Low'][year]['Employer Contributions'] = actualEmployerContributions;

    // Employer Earnings
    outputBackendRetirementData['401k Low'][year]['Employer Earnings'] = outputBackendRetirementData['401k Employer Comtributions'][year].Earnings;

    // Cumulative Employer Contributions & Earnings per Career Module
    if ( module4Data.start_year !== undefined && module4Data.start_year !== '' && module4Data.end_year !== undefined && module4Data.end_year !== '' && year === module4Data.end_year ) {
      const startYear = parseInt( module4Data.start_year, 10 );
      const endYear = parseInt( module4Data.end_year, 10 );
      if ( outputBackendRetirementData['401k Low'] !== undefined ) {
        const lowData = outputBackendRetirementData['401k Low'];
        // eslint-disable-next-line
        Object.keys( lowData ).forEach( ( year11 ) => {
          if ( year11 >= startYear && year11 <= endYear ) {
            cumulativeEmployerContributionEarnings += lowData[year11]['Employer Contributions'];
            cumulativeEmployerContributionEarnings += lowData[year11]['Employer Earnings'];
          }
        } );
      }
    } else if ( module5Data.start_year !== undefined && module5Data.start_year !== '' && module5Data.end_year !== undefined && module5Data.end_year !== '' && year === module5Data.end_year ) {
      const startYear = parseInt( module5Data.start_year, 10 );
      const endYear = parseInt( module5Data.end_year, 10 );
      if ( outputBackendRetirementData['401k Low'] !== undefined ) {
        const lowData = outputBackendRetirementData['401k Low'];
        // eslint-disable-next-line
        Object.keys( lowData ).forEach( ( year12 ) => {
          if ( year12 >= startYear && year12 <= endYear ) {
            cumulativeEmployerContributionEarnings += lowData[year12]['Employer Contributions'];
            cumulativeEmployerContributionEarnings += lowData[year12]['Employer Earnings'];
          }
        } );
      }
    } else if ( module8Data.start_year !== undefined && module8Data.start_year !== '' && module8Data.end_year !== undefined && module8Data.end_year !== '' && year === module8Data.end_year ) {
      const startYear = parseInt( module8Data.start_year, 10 );
      const endYear = parseInt( module8Data.end_year, 10 );
      if ( outputBackendRetirementData['401k Low'] !== undefined ) {
        const lowData = outputBackendRetirementData['401k Low'];
        // eslint-disable-next-line
        Object.keys( lowData ).forEach( ( year13 ) => {
          if ( year13 >= startYear && year13 <= endYear ) {
            cumulativeEmployerContributionEarnings += lowData[year13]['Employer Contributions'];
            cumulativeEmployerContributionEarnings += lowData[year13]['Employer Earnings'];
          }
        } );
      }
    } else if ( module17Data.start_year !== undefined && module17Data.start_year !== '' && module17Data.end_year !== undefined && module17Data.end_year !== '' && year === module17Data.end_year ) {
      const startYear = parseInt( module17Data.start_year, 10 );
      const endYear = parseInt( module17Data.end_year, 10 );
      if ( outputBackendRetirementData['401k Low'] !== undefined ) {
        const lowData = outputBackendRetirementData['401k Low'];
        // eslint-disable-next-line
        Object.keys( lowData ).forEach( ( year14 ) => {
          if ( year14 >= startYear && year14 <= endYear ) {
            cumulativeEmployerContributionEarnings += lowData[year14]['Employer Contributions'];
            cumulativeEmployerContributionEarnings += lowData[year14]['Employer Earnings'];
          }
        } );
      }
    } else {
      cumulativeEmployerContributionEarnings = 0;
    }
    outputBackendRetirementData['401k Low'][year]['Cumulative Employer Contributions And Earnings'] = cumulativeEmployerContributionEarnings;

    // Reduction %
    outputBackendRetirementData['401k Low'][year].Reduction = outputBackendRetirementData['401k Actual'][year]['% Reductions'];

    // Employer Vesting Reductions
    lowEmployerVestingReductions = -( outputBackendRetirementData['401k Low'][year]['Cumulative Employer Contributions And Earnings'] / 100 ) * outputBackendRetirementData['401k Low'][year].Reduction;
    outputBackendRetirementData['401k Low'][year]['Employer Vesting Reductions'] = lowEmployerVestingReductions;

    // Low Ending Balance
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['401k Employer Comtributions'][year][12]['Total Ending Balance'] !== undefined ) {
      lowEndingBalance = outputBackendRetirementData['401k Employer Comtributions'][year][12]['Total Ending Balance'];
    } else {
      lowEndingBalance = 0;
    }
    outputBackendRetirementData['401k Low'][year]['Ending Balance'] = lowEndingBalance;

    // Low Check
    if ( module1Data.start_year !== undefined && module1Data.start_year !== '' && year === module1Data.start_year ) {
      lowCheckValue = 112;
    } else {
      lowCheckValue += 12;
    }
    outputBackendRetirementData['401k Low'][year].Check = lowCheckValue;

    // High Values
    // Employer Contributions
    outputBackendRetirementData['401k High'][year]['Employer Contributions'] = actualEmployerContributions;

    // Employer Earnings
    outputBackendRetirementData['401k High'][year]['Employer Earnings'] = outputBackendRetirementData['401k1 Employer Comtributions'][year].Earnings;

    // Cumulative Employer Contributions & Earnings per Career Module
    outputBackendRetirementData['401k High'][year]['Cumulative Employer Contributions And Earnings'] = cumulativeEmployerContributionEarnings2;

    // Reduction %
    outputBackendRetirementData['401k High'][year].Reduction = actualEmployerReduction2;

    // Employer Vesting Reductions
    outputBackendRetirementData['401k High'][year]['Employer Vesting Reductions'] = -( cumulativeEmployerContributionEarnings2 / 100 ) * actualEmployerReduction2;

    // Ending Balance
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['401k1 Employer Comtributions'][year][12]['Total Ending Balance'] !== undefined ) {
      highEndingBalance = outputBackendRetirementData['401k1 Employer Comtributions'][year][12]['Total Ending Balance'];
    } else {
      highEndingBalance = 0;
    }
    outputBackendRetirementData['401k High'][year]['Ending Balance'] = highEndingBalance;

    // High Check
    outputBackendRetirementData['401k High'][year].Check = lowCheckValue;

    // 401k Sensitivity Analysis
    // Low value
    outputBackendRetirementData['401k Sensitivity Analysis'][year].Low = outputBackendRetirementData['401k Low'][year]['Ending Balance'];
    sensitivityAnalysisLow.push( outputBackendRetirementData['401k Sensitivity Analysis'][year].Low );
    // Actual Avarage
    outputBackendRetirementData['401k Sensitivity Analysis'][year]['Actual Average'] = outputBackendRetirementData['401k Actual'][year]['Ending Balance'];
    sensitivityAnalysisAvg.push( outputBackendRetirementData['401k Sensitivity Analysis'][year]['Actual Average'] );
    // High Value
    outputBackendRetirementData['401k Sensitivity Analysis'][year].High = outputBackendRetirementData['401k High'][year]['Ending Balance'];
    sensitivityAnalysisHigh.push( outputBackendRetirementData['401k Sensitivity Analysis'][year].High );
    // Personal Contributions
    outputBackendRetirementData['401k Sensitivity Analysis'][year]['Personal Contributions'] = outputBackendRetirementData['401k Actual'][year]['Personal Contributions'];
    sensitivityAnalysisPersonalContribution.push( outputBackendRetirementData['401k Sensitivity Analysis'][year]['Personal Contributions'] );
    // Personal Earnings
    outputBackendRetirementData['401k Sensitivity Analysis'][year]['Personal Earnings'] = outputBackendRetirementData['401k Actual'][year]['Personal Earnings'];
    sensitivityAnalysisPersonalEarnings.push( outputBackendRetirementData['401k Sensitivity Analysis'][year]['Personal Earnings'] );
    // Employer Contributions
    outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Contributions'] = outputBackendRetirementData['401k Actual'][year]['Employer Contributions'];
    sensitivityAnalysisEmployerContributions.push( outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Contributions'] );
    // Employer Earnings
    outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Earnings'] = outputBackendRetirementData['401k Actual'][year]['Employer Earnings'];
    sensitivityAnalysisEmployerEarnings.push( outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Earnings'] );
    // Employer Vesting Reductions
    outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Vesting Reductions'] = outputBackendRetirementData['401k Actual'][year]['Employer Vesting Reductions'];
    sensitivityAnalysisEmployerVestingReductions.push( outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Vesting Reductions'] );

    if ( outputBackendRetirementData['401k Sensitivity Analysis'][year].Low !== 0
      || outputBackendRetirementData['401k Sensitivity Analysis'][year]['Actual Average'] !== 0
      || outputBackendRetirementData['401k Sensitivity Analysis'][year].High !== 0
      || outputBackendRetirementData['401k Sensitivity Analysis'][year]['Personal Contributions'] !== 0
      || outputBackendRetirementData['401k Sensitivity Analysis'][year]['Personal Earnings'] !== 0
      || outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Contributions'] !== 0
      || outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Earnings'] !== 0
      || outputBackendRetirementData['401k Sensitivity Analysis'][year]['Employer Vesting Reductions'] !== 0 ) {
      yearsList401k.push( year );
    }
    // Roth IRA Actual Values
    // Beginning Balance
    if ( investmentsData !== undefined
                     && investmentsData['Roth IRA Second'] !== undefined
                     && investmentsData['Roth IRA Second'][year] !== undefined
                     && investmentsData['Roth IRA Second'][year]['Beginning Balance'] !== undefined ) {
      rothIraBeginningBalance = investmentsData['Roth IRA Second'][year]['Beginning Balance'];
    } else {
      rothIraBeginningBalance = 0;
    }
    outputBackendRetirementData['Roth IRA Actual'][year]['Beginning Balance'] = rothIraBeginningBalance;
    // Personal Contribution
    if ( investmentsData !== undefined
                     && investmentsData['Roth IRA Second'] !== undefined
                     && investmentsData['Roth IRA Second'][year] !== undefined
                     && investmentsData['Roth IRA Second'][year]['Annual Contribution'] !== undefined ) {
      rothIraPersonalContribution = investmentsData['Roth IRA Second'][year]['Annual Contribution'];
    } else {
      rothIraPersonalContribution = 0;
    }
    outputBackendRetirementData['Roth IRA Actual'][year]['Personal Contribution'] = rothIraPersonalContribution;
    // Earnings
    if ( investmentsData !== undefined
                     && investmentsData['Roth IRA Second'] !== undefined
                     && investmentsData['Roth IRA Second'][year] !== undefined
                     && investmentsData['Roth IRA Second'][year].Earnings !== undefined ) {
      rothIraEarnings = investmentsData['Roth IRA Second'][year].Earnings;
    } else {
      rothIraEarnings = 0;
    }
    outputBackendRetirementData['Roth IRA Actual'][year].Earnings = rothIraEarnings;
    // Ending Balance
    if ( investmentsData !== undefined
                     && investmentsData['Roth IRA Second'] !== undefined
                     && investmentsData['Roth IRA Second'][year] !== undefined
                     && investmentsData['Roth IRA Second'][year]['Ending Balance'] !== undefined ) {
      rothIraEndingBalance = investmentsData['Roth IRA Second'][year]['Ending Balance'];
    } else {
      rothIraEndingBalance = 0;
    }
    outputBackendRetirementData['Roth IRA Actual'][year]['Ending Balance'] = rothIraEndingBalance;

    // Roth IRA Low Values
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['Roth IRA Low Month'][year] !== undefined ) {
      rothIRALowEndingBalance = outputBackendRetirementData['Roth IRA Low Month'][year][12]['End Balance'];
    } else {
      rothIRALowEndingBalance = 0;
    }
    outputBackendRetirementData['Roth IRA Low'][year]['Ending Balance'] = rothIRALowEndingBalance;

    // Low Check
    if ( module1Data.start_year !== undefined && module1Data.start_year !== '' && year === module1Data.start_year ) {
      rothIraLowCheckValue = 308;
    } else {
      rothIraLowCheckValue += 12;
    }
    outputBackendRetirementData['Roth IRA Low'][year].Check = rothIraLowCheckValue;

    // Roth IRA High Values
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['Roth IRA High Month'][year] !== undefined ) {
      rothIRAHighEndingBalance = outputBackendRetirementData['Roth IRA High Month'][year][12]['End Balance'];
    } else {
      rothIRAHighEndingBalance = 0;
    }
    outputBackendRetirementData['Roth IRA High'][year]['Ending Balance'] = rothIRAHighEndingBalance;
    // Low Check
    outputBackendRetirementData['Roth IRA Low'][year].Check = rothIraLowCheckValue;

    // Roth IRA Sensitivity Analysis values
    // Low value
    outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].Low = rothIRALowEndingBalance;
    rothIRASensitivityAnalysisLow.push( outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].Low );
    // Average Value
    outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].Average = rothIraEndingBalance;
    rothIRASensitivityAnalysisAvg.push( outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].Average );
    // High Value
    outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].High = rothIRAHighEndingBalance;
    rothIRASensitivityAnalysisHigh.push( outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].High );
    // Personal Contributions
    outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year]['Personal Contributions'] = rothIraPersonalContribution;
    rothIRASensitivityAnalysisPersonalContribution.push( outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year]['Personal Contributions'] );
    // Personal Earnings
    outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year]['Personal Earnings'] = rothIraEarnings;
    rothIRASensitivityAnalysisPersonalEarnings.push( outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year]['Personal Earnings'] );

    if ( outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].Low !== 0
|| outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].Average !== 0
|| outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year].High !== 0
|| outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year]['Personal Contributions'] !== 0
|| outputBackendRetirementData['Roth IRA Sensitivity Analysis'][year]['Personal Earnings'] !== 0 ) {
      yearsListRothIra.push( year );
    }
    // Seph IRA Actual Values
    // Beginning Balance
    if ( investmentsData !== undefined && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'][year] !== undefined && investmentsData['Seph IRA Second'][year]['Beginning Balance'] !== undefined ) {
      sephIraBeginningBalance = investmentsData['Seph IRA Second'][year]['Beginning Balance'];
    } else {
      sephIraBeginningBalance = 0;
    }
    outputBackendRetirementData['Seph IRA Actual'][year]['Beginning Balance'] = sephIraBeginningBalance;
    // Personal Contribution
    if ( investmentsData !== undefined && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'][year] !== undefined && investmentsData['Seph IRA Second'][year]['Annual Contribution'] !== undefined ) {
      sephIraPersonalContribution = investmentsData['Seph IRA Second'][year]['Annual Contribution'];
    } else {
      sephIraPersonalContribution = 0;
    }
    outputBackendRetirementData['Seph IRA Actual'][year]['Personal Contribution'] = sephIraPersonalContribution;
    // Earnings
    if ( investmentsData !== undefined && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'][year] !== undefined && investmentsData['Seph IRA Second'][year].Earnings !== undefined ) {
      sephIraEarnings = investmentsData['Seph IRA Second'][year].Earnings;
    } else {
      sephIraEarnings = 0;
    }
    outputBackendRetirementData['Seph IRA Actual'][year].Earnings = sephIraEarnings;
    // Ending Balance
    if ( investmentsData !== undefined && investmentsData['Seph IRA Second'] !== undefined && investmentsData['Seph IRA Second'][year] !== undefined && investmentsData['Seph IRA Second'][year]['Ending Balance'] !== undefined ) {
      sephIraEndingBalance = investmentsData['Seph IRA Second'][year]['Ending Balance'];
    } else {
      sephIraEndingBalance = 0;
    }
    outputBackendRetirementData['Seph IRA Actual'][year]['Ending Balance'] = sephIraEndingBalance;

    // Pension Actual Values
    // Beginning Balance
    if ( investmentsData !== undefined && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year]['Beginning Balance'] !== undefined ) {
      pensionBeginningBalance = investmentsData['Pension Second'][year]['Beginning Balance'];
    } else {
      pensionBeginningBalance = 0;
    }
    outputBackendRetirementData['Pension Actual'][year]['Beginning Balance'] = pensionBeginningBalance;
    // Personal Contribution
    if ( investmentsData !== undefined && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year]['Personal Contribution'] !== undefined ) {
      pensionPersonalContribution = investmentsData['Pension Second'][year]['Personal Contribution'];
    } else {
      pensionPersonalContribution = 0;
    }
    outputBackendRetirementData['Pension Actual'][year]['Personal Contribution'] = pensionPersonalContribution;
    // Employer Contribution
    if ( investmentsData !== undefined && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year]['Employer Contribution'] !== undefined ) {
      pensionEmployerContribution = investmentsData['Pension Second'][year]['Employer Contribution'];
    } else {
      pensionEmployerContribution = 0;
    }
    outputBackendRetirementData['Pension Actual'][year]['Employer Contribution'] = pensionEmployerContribution;
    // Earnings
    if ( investmentsData !== undefined && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year].Earnings !== undefined ) {
      pensionEarnings = investmentsData['Pension Second'][year].Earnings;
    } else {
      pensionEarnings = 0;
    }
    outputBackendRetirementData['Pension Actual'][year].Earnings = pensionEarnings;
    // Ending Balance
    if ( investmentsData !== undefined && investmentsData['Pension Second'] !== undefined && investmentsData['Pension Second'][year] !== undefined && investmentsData['Pension Second'][year]['Ending Balance'] !== undefined ) {
      pensionEndingBalance = investmentsData['Pension Second'][year]['Ending Balance'];
    } else {
      pensionEndingBalance = 0;
    }
    outputBackendRetirementData['Pension Actual'][year]['Ending Balance'] = pensionEndingBalance;

    // Seph IRA Low Values
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['Seph IRA Low Month'][year] !== undefined ) {
      sephIRALowEndingBalance = outputBackendRetirementData['Seph IRA Low Month'][year][12]['End Balance'];
    } else {
      sephIRALowEndingBalance = 0;
    }
    outputBackendRetirementData['Seph IRA Low'][year]['Ending Balance'] = sephIRALowEndingBalance;

    // Low Check
    if ( module1Data.start_year !== undefined && module1Data.start_year !== '' && year === module1Data.start_year ) {
      sephIraLowCheckValue = 499;
    } else {
      sephIraLowCheckValue += 12;
    }
    outputBackendRetirementData['Seph IRA Low'][year].Check = sephIraLowCheckValue;

    // Pension Low Values
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['Pension Low Month'][year] !== undefined ) {
      pensionLowEndingBalance = outputBackendRetirementData['Pension Low Month'][year][12]['End Balance'];
    } else {
      pensionLowEndingBalance = 0;
    }
    outputBackendRetirementData['Pension Low'][year]['Ending Balance'] = pensionLowEndingBalance;

    // Low Check
    if ( module1Data.start_year !== undefined && module1Data.start_year !== '' && year === module1Data.start_year ) {
      pensionLowCheckValue = 692;
    } else {
      pensionLowCheckValue += 12;
    }
    outputBackendRetirementData['Pension Low'][year].Check = sephIraLowCheckValue;

    // Seph IRA High Values
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['Seph IRA High Month'][year] !== undefined ) {
      sephIRAHighEndingBalance = outputBackendRetirementData['Seph IRA High Month'][year][12]['End Balance'];
    } else {
      sephIRAHighEndingBalance = 0;
    }
    outputBackendRetirementData['Seph IRA High'][year]['Ending Balance'] = sephIRAHighEndingBalance;
    // Low Check
    outputBackendRetirementData['Seph IRA High'][year].Check = sephIraLowCheckValue;

    // Seph IRA High Values
    if ( outputBackendRetirementData !== undefined && outputBackendRetirementData !== '' && outputBackendRetirementData['Pension High Month'][year] !== undefined ) {
      pensionHighEndingBalance = outputBackendRetirementData['Pension High Month'][year][12]['End Balance'];
    } else {
      pensionHighEndingBalance = 0;
    }
    outputBackendRetirementData['Pension High'][year]['Ending Balance'] = pensionHighEndingBalance;
    // Low Check
    outputBackendRetirementData['Pension High'][year].Check = pensionLowCheckValue;

    // Seph IRA Sensitivity Analysis values
    // Low value
    outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].Low = sephIRALowEndingBalance;
    sephIRASensitivityAnalysisLow.push( outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].Low );
    // Average Value
    outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].Average = sephIraEndingBalance;
    sephIRASensitivityAnalysisAvg.push( outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].Average );
    // High Value
    outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].High = sephIRAHighEndingBalance;
    sephIRASensitivityAnalysisHigh.push( outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].High );
    // Personal Contributions
    outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year]['Personal Contributions'] = sephIraPersonalContribution;
    sephIRASensitivityAnalysisPersonalContribution.push( outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year]['Personal Contributions'] );
    // Personal Earnings
    outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year]['Personal Earnings'] = sephIraEarnings;
    sephIRASensitivityAnalysisPersonalEarnings.push( outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year]['Personal Earnings'] );

    if ( outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].Low !== 0
     || outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].Average !== 0
     || outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year].High !== 0
     || outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year]['Personal Contributions'] !== 0
     || outputBackendRetirementData['Seph IRA Sensitivity Analysis'][year]['Personal Earnings'] !== 0
    ) {
      yearsListSephIra.push( year );
    }
    // Pension Sensitivity Analysis values
    // Low value
    outputBackendRetirementData['Pension Sensitivity Analysis'][year].Low = pensionLowEndingBalance;
    pensionSensitivityAnalysisLow.push( outputBackendRetirementData['Pension Sensitivity Analysis'][year].Low );
    // Average Value
    outputBackendRetirementData['Pension Sensitivity Analysis'][year].Average = pensionEndingBalance;
    pensionSensitivityAnalysisAvg.push( outputBackendRetirementData['Pension Sensitivity Analysis'][year].Average );
    // High Value
    outputBackendRetirementData['Pension Sensitivity Analysis'][year].High = pensionHighEndingBalance;
    pensionSensitivityAnalysisHigh.push( outputBackendRetirementData['Pension Sensitivity Analysis'][year].High );
    // Personal Contributions
    outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Personal Contributions'] = pensionPersonalContribution;
    pensionSensitivityAnalysisPersonalContribution.push( outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Personal Contributions'] );
    // Employer Contributions
    outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Employer Contributions'] = pensionEmployerContribution;
    pensionSensitivityAnalysisEmployerContribution.push( outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Employer Contributions'] );
    // Personal Earnings
    outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Personal Earnings'] = pensionEarnings;
    pensionSensitivityAnalysisPersonalEarnings.push( outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Personal Earnings'] );

    if ( outputBackendRetirementData['Pension Sensitivity Analysis'][year].Low !== 0
     || outputBackendRetirementData['Pension Sensitivity Analysis'][year].Average !== 0
     || outputBackendRetirementData['Pension Sensitivity Analysis'][year].High !== 0
     || outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Personal Contributions'] !== 0
     || outputBackendRetirementData['Pension Sensitivity Analysis'][year]['Employer Contributions'] !== 0 ) {
      yearsListPension.push( year );
    }
  }

  outputBackendData['Average Calculations'] = {};
  outputBackendData['Tax Summary'] = {};
  // Average
  outputBackendData['Average Calculations']['Tuition Average'] = -( avgTuitionValue );
  outputBackendData['Average Calculations']['Room Board Average'] = -( avgRoomBoardValue );
  outputBackendData['Average Calculations']['Children Average'] = -( avgChildernValue );
  outputBackendData['Average Calculations']['Entertainment Average'] = -( avgEntertainmentValue );
  outputBackendData['Average Calculations']['Food Average'] = -( avgFoodValue );
  outputBackendData['Average Calculations']['Rent Average'] = -( avgRentValue );
  outputBackendData['Average Calculations']['Utilities Average'] = -( avgUtilitiesValue );
  outputBackendData['Average Calculations']['Insurance Average'] = -( avgInsuranceValue );
  outputBackendData['Average Calculations']['Loans Average'] = -( avgLoansValue );
  outputBackendData['Average Calculations']['Miscellaneous Average'] = -( avgMiscellaneousValue );
  outputBackendData['Average Calculations']['Pet Average'] = -( avgPetValue );
  outputBackendData['Average Calculations']['Transportation Average'] = -( avgTransportationValue );
  outputBackendData['Average Calculations']['Car Average'] = -( avgCarValue );
  outputBackendData['Average Calculations']['Technology Average'] = -( avgTechnologyValue );
  outputBackendData['Average Calculations']['Clothing Average'] = -( avgClothingValue );
  outputBackendData['Average Calculations']['Home Average'] = -( avgHomeValue );
  outputBackendData['Average Calculations']['Rental Property Average'] = -( avgRentalPropertyValue );

  // Average Total
  averageTotal = outputBackendData['Average Calculations']['Tuition Average']
            + outputBackendData['Average Calculations']['Room Board Average']
            + outputBackendData['Average Calculations']['Children Average']
            + outputBackendData['Average Calculations']['Entertainment Average']
            + outputBackendData['Average Calculations']['Food Average']
            + outputBackendData['Average Calculations']['Rent Average']
            + outputBackendData['Average Calculations']['Utilities Average']
            + outputBackendData['Average Calculations']['Insurance Average']
            + outputBackendData['Average Calculations']['Loans Average']
            + outputBackendData['Average Calculations']['Miscellaneous Average']
            + outputBackendData['Average Calculations']['Pet Average']
            + outputBackendData['Average Calculations']['Transportation Average']
            + outputBackendData['Average Calculations']['Car Average']
            + outputBackendData['Average Calculations']['Technology Average']
            + outputBackendData['Average Calculations']['Clothing Average']
            + outputBackendData['Average Calculations']['Home Average']
            + outputBackendData['Average Calculations']['Rental Property Average'];

  outputBackendData['Average Calculations'].Total = averageTotal;

  // Graph
  outputBackendData.grossIncomeGraphData = grossIncomeGraphData;
  outputBackendData.incomeAfterTaxesGraphData = incomeAfterTaxesGraphData;
  outputBackendData.totalExpensesGraphData = totalExpensesGraphData;
  outputBackendData.netWorthGraphData = netWorthGraphData;

  outputBackendData.yearsList = dropDownYearsList;

  outputBackendData.investmentsShortTermBalance = investmentsShortTermBalance;
  outputBackendData.investmentsLongTermBalance = investmentsLongTermBalance;
  outputBackendData.investmentsShortTermFundsFlow = investmentsShortTermFundsFlow;
  outputBackendData.investmentsLongTermFundsFlow = investmentsLongTermFundsFlow;
  outputBackendData.investmentsCashBalance = investmentsCashBalance;

  // 401k Sensitivity Analysis Graph
  outputBackendData['401k years List'] = yearsList401k;
  outputBackendData.Low = sensitivityAnalysisLow;
  outputBackendData['Actual Average'] = sensitivityAnalysisAvg;
  outputBackendData.High = sensitivityAnalysisHigh;
  outputBackendData['Personal Contributions'] = sensitivityAnalysisPersonalContribution;
  outputBackendData['Personal Earnings'] = sensitivityAnalysisPersonalEarnings;
  outputBackendData['Employer Contributions'] = sensitivityAnalysisEmployerContributions;
  outputBackendData['Employer Earnings'] = sensitivityAnalysisEmployerEarnings;
  outputBackendData['Employer Vesting Reductions'] = sensitivityAnalysisEmployerVestingReductions;
  outputBackendData['Low Value'] = `Low (${parseFloat( lowAvarage401kPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['Middle Value'] = `Actual Average (${parseFloat( middleAvarage401kPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['High Value'] = `High (${parseFloat( highAvarage401kPercentage ).toFixed( 1 )}% Return)`;

  // Roth IRA Sensitivity Analysis Graph Array
  outputBackendData['Roth IRA years List'] = yearsListRothIra;
  outputBackendData['Roth IRA Low'] = rothIRASensitivityAnalysisLow;
  outputBackendData['Roth IRA Actual Average'] = rothIRASensitivityAnalysisAvg;
  outputBackendData['Roth IRA High'] = rothIRASensitivityAnalysisHigh;
  outputBackendData['Roth IRA Personal Contributions'] = rothIRASensitivityAnalysisPersonalContribution;
  outputBackendData['Roth IRA Personal Earnings'] = rothIRASensitivityAnalysisPersonalEarnings;
  outputBackendData['Roth IRA Low Value'] = `Low (${parseFloat( lowAvarageRothIRAPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['Roth IRA Middle Value'] = `Actual Average (${parseFloat( middleAvarageRothIRAPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['Roth IRA High Value'] = `High (${parseFloat( highAvarageRothIRAPercentage ).toFixed( 1 )}% Return)`;

  // Seph IRA Sensitivity Analysis Graph Array
  outputBackendData['Seph IRA years List'] = yearsListSephIra;
  outputBackendData['Seph IRA Low'] = sephIRASensitivityAnalysisLow;
  outputBackendData['Seph IRA Actual Average'] = sephIRASensitivityAnalysisAvg;
  outputBackendData['Seph IRA High'] = sephIRASensitivityAnalysisHigh;
  outputBackendData['Seph IRA Personal Contributions'] = sephIRASensitivityAnalysisPersonalContribution;
  outputBackendData['Seph IRA Personal Earnings'] = sephIRASensitivityAnalysisPersonalEarnings;

  outputBackendData['Seph IRA Low Value'] = `Low (${parseFloat( lowAvarageSephIRAPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['Seph IRA Middle Value'] = `Actual Average (${parseFloat( middleAvarageSephIRAPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['Seph IRA High Value'] = `High (${parseFloat( highAvarageSephIRAPercentage ).toFixed( 1 )}% Return)`;

  // Pension Sensitivity Analysis Graph Array
  outputBackendData['Pension years List'] = yearsListPension;
  outputBackendData['Pension Low'] = pensionSensitivityAnalysisLow;
  outputBackendData['Pension Actual Average'] = pensionSensitivityAnalysisAvg;
  outputBackendData['Pension High'] = pensionSensitivityAnalysisHigh;
  outputBackendData['Pension Personal Contributions'] = pensionSensitivityAnalysisPersonalContribution;
  outputBackendData['Pension Employer Contributions'] = pensionSensitivityAnalysisEmployerContribution;
  outputBackendData['Pension Personal Earnings'] = pensionSensitivityAnalysisPersonalEarnings;
  outputBackendData['Pension Low Value'] = `Low (${parseFloat( lowAvaragePensionPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['Pension Middle Value'] = `Actual Average (${parseFloat( middleAvaragePensionPercentage ).toFixed( 1 )}% Return)`;
  outputBackendData['Pension High Value'] = `High (${parseFloat( highAvaragePensionPercentage ).toFixed( 1 )}% Return)`;

  // Tax summary return Data
  outputBackendData['Tax Summary']['Federal Income'] = totalFederalIncomeValue;
  outputBackendData['Tax Summary']['Social Security'] = totalSocialSecurityValue;
  outputBackendData['Tax Summary'].Medicare = totalMedicareValue;
  outputBackendData['Tax Summary']['State & Local'] = totalStateLocalValue;
  outputBackendData['Tax Summary'].Total = parseFloat( outputBackendData['Tax Summary']['Federal Income'] )
            + parseFloat( outputBackendData['Tax Summary']['Social Security'] )
            + parseFloat( outputBackendData['Tax Summary'].Medicare )
            + parseFloat( outputBackendData['Tax Summary']['State & Local'] );
  // To return Output backend Complete Data
  function outputBackendSheetData() {
    // Short Term Investments
    const shortOne = ( /[^0]/ ).exec( outputBackendData.investmentsShortTermBalance.join( '' ) );
    const shortTwo = ( /[^0]/ ).exec( outputBackendData.investmentsShortTermFundsFlow.join( '' ) );
    const shortThree = ( /[^0]/ ).exec( outputBackendData.investmentsCashBalance.join( '' ) );

    if ( shortOne === null && shortTwo === null && shortThree === null ) {
      outputBackendData.shortTermInvestmentsGraphDisplay = 'hide';
    } else {
      outputBackendData.shortTermInvestmentsGraphDisplay = 'show';
    }

    // Long Term Investments
    const longOne = ( /[^0]/ ).exec( outputBackendData.investmentsLongTermBalance.join( '' ) );
    const longTwo = ( /[^0]/ ).exec( outputBackendData.investmentsLongTermFundsFlow.join( '' ) );
    const longThree = ( /[^0]/ ).exec( outputBackendData.investmentsCashBalance.join( '' ) );

    if ( longOne === null && longTwo === null && longThree === null ) {
      outputBackendData.longTermInvestmentsGraphDisplay = 'hide';
    } else {
      outputBackendData.longTermInvestmentsGraphDisplay = 'show';
    }
    return outputBackendData;
  }
  return outputBackendSheetData();
}
export default outputBackendSheetBackendData;
