import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import styles from './styles';
import logoImage from '../../../assets/img/mcs/my-career-sketch.png';
import logoInkwiry from '../../../assets/img/mcs/logo.png';
import Pageloader from '../../../components/ui/pageloader';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import tickMark from '../../../assets/img/mcs/green-check-discovery.png';
import bookImage from '../../../assets/img/mcs/icon-book-rev.png';
const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const MY_DETAILS = loader('../../../graphql/schema/auth/me.graphql');

const McsLevelTwoActivityOne = (props) => {
    const { classes } = props;
    const { data: myDetails } = useQuery(MY_DETAILS);

    const [loading] = React.useState(false);
    const [bookSection, setBookSection]=React.useState(false);
    const [levelList, setLevelList] = React.useState([]);

    const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
        variables: {
            level_id: "2",
        },
        fetchPolicy: 'network-only',
        onCompleted() {
            setLevelList(mcsLevelWiseData.getMCSLevelActivityData.level_activity);
        },
        onError() {
        },
      });
    return (
        <Typography variant="body1" component="div">
            {loading && <Pageloader loading={loading} />}
            <div>
                <div className={classes.mcsDevelopVision }>                    
                    <div className={classes.topRow }>
                        <div className={classes.mcsLogoImage}>
                            <Link to="/my-career-sketch/dashboard">
                                <img src={logoImage} alt="logo" />
                            </Link>
                        </div>
                        <div className={ classes.activityStatus }>
                            <h4><span className={ classes.greenTick }><img src={ tickMark } alt="tick" /></span>ACTIVITY COMPLETE! BACK TO <a href="/my-career-sketch/level2">LEVEL 02</a></h4>
                        </div>
                        <ReactTooltip id="book" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
                        <div className={ classes.bookBlock }>                            
                            <div className={ classes.activityImage } aria-hidden="true" onClick={()=>{setBookSection(true)}} data-for="book" data-tip="Activity Tracker">
                                <img src={ bookImage } alt="book" />                                                        
                            </div>
                            {bookSection && (
                            <div className={ classes.reviewLevels }>
                                <i className="fa fa-times" onClick={()=>{setBookSection(false)}} />
                                <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>1.</span>
                                            <span>Write your own story</span>
                                        </span>
                                        {levelList[0] && levelList[0].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}                                        
                                    </div>
                                </div>                                  
                            </div>  
                            )} 
                        </div>
                    </div>                   
                </div>               
                <div className={classes.fiContent}>               
                    <div className={classes.mcsRow}>
                        <div className={ classes.backtoLevel }>
                            <Link to="/my-career-sketch/level2">
                                <span> {'<'} Back to level 02</span>
                            </Link>
                        </div>
                    </div>                
                    <div className={classes.fiHeader}>                    
                        <div className={classes.fiHeaderInner}>
                            <span className={classes.pwiActiveModule}>
                                <h3>Level 02: Develop your vision</h3>
                            </span>
                        </div>                    
                    </div>
                    <div className={classes.stepOne}>
                        <div className={classes.fiContentHeader}>
                            <div className={classes.pwiWalkTitle}>
                                <div aria-hidden="true" className={classes.menuIcon}>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                                <h3>Write your own story</h3>
                            </div>
                        </div>
                        <div className={classes.ProgressBar}>
                            <div className={classes.ProgressBarInner} style={{ width: '100%' }} />
                        </div>
                        <div className={classes.contentBlock}>
                            <div className={ classes.imageRow}>
                                <div className={ classes.leftRow }>
                                    <p className={classes.marginTopZero}>
                                        Imagine yourself 10 years into the future. What will you want to be doing? What would you like your future self to look like? What will your future self need and want?
                                    </p>
                                    <p className={classes.marginBottomZero}>
                                        Start now: take out a piece of paper and write your story as it might unfold over the next 10 years. Your story doesn&apos;t have to be detailed, but make sure it includes your overall vision and at least two stepping stones. This is a great time to let your imagination run. 
                                    </p>
                                </div>
                                <div className={ classes.rightRow }>
                                    <p className={ classes.storyPaper }>HERE&apos;S WHY WE WANT YOU TO WRITE YOUR STORY ON PAPER:</p>
                                    <p className={ classes.writingDown }>Just by <b>writing down</b> your goals on a regular basis, you’re <b>42% more likely</b> to achieve them.</p>
                                </div>
                            </div>                           
                            <p className={classes.MarginTopZero}>
                            Here&apos;s an example:
                            </p>                        
                            {myDetails && myDetails.me.user_identity === "student" ?
                                <div>
                                    <p className={ classes.visionText }>"My vision is to help create a more healthy environment. As an environmental scientist, I will help identify and eliminate sources of pollutants and hazards that affect our world and develop programs to promote sustainability. I&apos;ll start by getting my bachelor&apos;s degree, work for a year or two, and then go back to school for my master&apos;s degree."</p>
                                    <p>If you&apos;re not quite sure where you want to be in 10 years, and even if you have no clue, try some of these methods to spark ideas:</p>
                                    <ul className={ classes.sparkIdeas }>
                                        <li>Review additional career sketch stories</li>
                                        <li>Re-read and consider the Big Question</li>
                                        <li>Have fun with your vision: pick any career path that interests you, or one that blends a combination of your interests and talents</li>
                                        <li>Take <a href="/" className={ classes.textUnderline }>Truity&apos;s Career Personality Profiler</a>, which matches your personality, interests and strengths to real-world careers</li>
                                        <li>Explore careers in <a href="/" className={ classes.textUnderline }>MyNextMove</a></li>
                                        <li>Find inspiration by talking with your family and friends</li>
                                        <li>Dream big: If you can dream it, you can do it.</li>                                
                                    </ul>
                                </div>
                                : 
                                <div>
                                    <p className={ classes.visionText }>
                                        My vision is to design walkable residential communities with a mix of housing types and a village center. Starting where I am as an entry-level architect, my next move will be to become a registered architect. Next I’ll find a position at a firm that works on this type of project. Eventually I will establish my own architecture firm specializing in village residential communities.”</p>
                                    <p>If you&apos;re not quite sure where you want to be in 10 years, and even if you have no clue, try some of these methods to spark ideas:</p>
                                    <ul className={ classes.sparkIdeas }>
                                        <li>Review additional career sketch stories</li>
                                        <li>Re-read and consider the Big Question</li>
                                        <li>Have fun with your vision: pick any career path that interests you, or one that blends a combination of your interests and talents</li>                                
                                        <li>Explore careers in <a href="/" className={ classes.textUnderline }>MyNextMove</a></li>
                                        <li>Find inspiration by talking with your family and friends</li>
                                        <li>Dream big: If you can dream it, you can do it.</li>                                
                                    </ul>
                                </div>
                            }   
                        </div>
                    </div>
                    <div className={classes.startButton}>
                        <Link to="/my-career-sketch/level2">
                            <button type="button">Back To Level 02</button>
                        </Link>
                    </div>
                </div>
                <div className={classes.mcsFooter}>
                    <div className={classes.container}>
                        <div className={classes.footerRow}>
                            <div className={classes.copyRight}>
                                <p>Copyright © 2022 // All rights reserved</p>
                            </div>
                            <div className={classNames(classes.copyRight, classes.informedText)}>
                                <p>
                                    Informed and Powered by
                                    <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.mcsFluid}>
                        <p className={classes.termLinks}>
                            <a href="/">Terms of Service</a>
                            &nbsp;//&nbsp;
                            <a href="/">Privacy Policy</a>
                            &nbsp;//&nbsp;
                            <a href="/">Security</a>
                        </p>
                    </div>
                </div>
            </div>
        </Typography>
    );
};

McsLevelTwoActivityOne.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(McsLevelTwoActivityOne);