import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import totalfederal from '../../../../assets/img/pf101/total_federal_student_loan.png';

const startTime = new Date();
const StudentLoans = (props) => {
  const { classes, location } = props;
  const params = queryString.parse(location.search);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.contentPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <ul className={classes.breadCrumbs}>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/student-loan-v2.png" alt="template" />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>What&apos;s a Student Loan?</h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/student-loan-300px.jpg" alt="template" />
                      </div>
                      <div className={classes.pfText}>
                        <p className={classes.coverAnything}>
                          A student loan is money provided by the federal government or a financial institution to help finance your education that you agree to pay back with fees and interest. Among the Class of 2020, 55% of college students took out student loans - both federal and private - and they graduated with an average debt of $28,400 and an average monthly payment of $300, according to
                          {' '}
                          <a href="https://studentloanhero.com/student-loan-debt-statistics/" target="_blank" rel="budget-fy2020 noopener noreferrer" className={classes.anchorStyle}>Student Loan Hero.</a>
                        </p>
                        <p>
                          Before you choose to attend college or other higher education, it&apos;s crucial to understand student loans and how they will play a part in funding your education. Check out the following graphic on student loans.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfImage}>
                      <div className={classes.debtImage}>
                        <img className={classes.pfImageBorder} src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/student-loan-text.png" alt="template" />
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.creditText}>
                        <p>
                          <b>Student loans are common for all age groups</b>
                          {' '}
                          <br />
                          Student loans are common and they&apos;re not bad! Just like a mortgage helps to buy a house, a student loan helps you pay for higher education. And, just like buying a house, student loans and higher education require a plan before you get there. Without a plan, you can get yourself into financial trouble after graduation. Take note of the following details so you&apos;ll be prepared to manage your student loans like an expert.
                        </p>
                        <p>
                          <b>Federal Student Loans</b>
                          <br />
                          A loan provided by the federal government. There are two types of federal loans: subsidized and unsubsidized.
                          {' '}
                          <b>Subsidized loans</b>
                          {' '}
                          do not charge interest during school or in your grace period and are the cheapest of all student loans.
                          {' '}
                          <b>Unsubsidized loans</b>
                          {' '}
                          charge interest from the day you receive the money, which means interest will start to
                          {' '}
                          <a className={classes.anchorStyle} href="https://studentloanhero.com/featured/how-student-loan-interest-works/" target="_blank" rel="budget-fy2020 noopener noreferrer">accrue</a>
                          {' '}
                          during school. An easy way to think of accrued interest is if you don&apos;t make monthly payments on your loan including while you are in school, the amount that you owe will increase each month.
                        </p>
                        <p>
                          Both subsidized and unsubsidized federal student loans share these terms:
                        </p>
                        <ul>
                          <li>
                            Federal loans are received by filling out the
                            {' '}
                            <a className={classes.anchorStyle} href="https://studentaid.gov/h/apply-for-aid/fafsa" target="_blank" rel="budget-fy2020 noopener noreferrer">Free Application for Federal Student Aid (FAFSA)</a>
                            {' '}
                            each year
                          </li>
                          <li>
                            <a className={classes.anchorStyle} href="https://studentaid.gov/understand-aid/types/loans/interest-rates" target="_blank" rel="budget-fy2020 noopener noreferrer">Current interest rates</a>
                            {' '}
                            are 2.75% for undergraduate students and 4.30% for graduate or professional students
                          </li>
                          <li>
                            Repayment starts after the
                            {' '}
                            <a className={classes.anchorStyle} href="https://studentaid.gov/manage-loans/repayment" target="_blank" rel="budget-fy2020 noopener noreferrer">grace</a>
                            {' '}
                            period – a six-month period after you graduate, leave school, or drop below half-time status
                          </li>
                          <li>
                            Making payments during school:
                            <ul>
                              <li>
                                Subsidized loans do not charge interest until after the grace period ends. The amount you borrow during school is the amount you will repay after school
                              </li>
                              <li>
                                For unsubsidized loans, you have the option to make interest payments during school so that you don&apos;t accrue interest and your loan balance does not grow
                              </li>
                            </ul>
                          </li>
                          <li>
                            Repayment plans after grace period:
                            <ul>
                              <li>
                                <b>Standard repayment plan:</b>
                                {' '}
                                pay a fixed amount each month for 10 years or 120 months of payments. With this plan, you&apos;ll pay the least interest on your loan.
                              </li>
                              <li>
                                <b>Extended repayment plan:</b>
                                {' '}
                                pay a smaller fixed amount each month for 20 years or 240 months of payments. Don&apos;t be fooled by the smaller monthly payment! With this plan, you&apos;ll pay more interest than the standard repayment plan.
                              </li>
                              <li>
                                <b>Graduated repayment plan:</b>
                                {' '}
                                payments start slow and increase gradually over time. This plan is best for professional degrees (think doctor or lawyer) where you expect your salary to rise significantly down the road.
                              </li>
                            </ul>
                            <li>
                              Federal student loans have limits to how much you can borrow:
                            </li>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className={classes.fiImage}>
                      <div className={classNames(classes.lightGallery, classes.federalPic)}>
                        <img src={totalfederal} alt="table" />
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <p className={classes.creditText}>
                        <b>Private Student Loans</b>
                        <br />
                        A loan provided by a bank or other financial institution. Private loans charge interest from the day you receive the money, which means interest will start to
                        {' '}
                        <a className={classes.anchorStyle} href="https://studentloanhero.com/featured/how-student-loan-interest-works/" target="_blank" rel="budget-fy2020 noopener noreferrer">accrue</a>
                        {' '}
                        during school.
                        <ul>
                          <li>Private loans are received by applying at a bank or other financial institution each year</li>
                          <li>
                            Repayment starts after the
                            {' '}
                            <a className={classes.anchorStyle} href="https://studentaid.gov/manage-loans/repayment" target="_blank" rel="budget-fy2020 noopener noreferrer">grace period</a>
                            {' '}
                            - a six-month period after you graduate, leave school, or drop below half-time status
                          </li>
                          <li>Current interest rates can vary from 3-14%</li>
                          <li>You have the option to make interest payments during school so that you don&apos;t accrue interest and your loan balance does not grow</li>
                          <li>Repayment is typically a fixed amount each month for 10 years or 120 months of payments</li>
                          <li>Private loans also have borrowing limits, but the limit is usually the total cost of attendance minus any other funding such as scholarships, grants, and federal loans you receive</li>
                        </ul>
                      </p>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={params.revisit !== undefined && params.revisit} url="/pf101/level6/student-loans/exercise" startTime={startTime} level={6} exercise={4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
StudentLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(StudentLoans);
