import { PMT } from '../../utilities/excelLibrary';

function carLoan2( module1Data, module12Data ) {
  /** ******************** Student Loan 1 *********************** */
  // Student Loan Excel Sheet
  let carLoanDataTwo = {};
  let summationData = {};

  // Identifier
  let identifier = 0;
  if ( module12Data.moreCarLoan !== undefined && module12Data.moreCarLoan > 0 ) {
    identifier = 1;
  } else {
    identifier = 0;
  }

  // Loan Amount
  let loanAmount = 0;
  if ( identifier === 1 && module12Data.moreCarLoan !== undefined && module12Data.moreCarLoan !== '' ) {
    loanAmount = module12Data.moreCarLoan;
  } else {
    loanAmount = 0;
  }
  // Terms in Years
  let termsOfYear = 0;
  if ( identifier === 1 && module12Data.second_term_of_the_loan !== undefined && module12Data.second_term_of_the_loan !== '' ) {
    termsOfYear = module12Data.second_term_of_the_loan / 12;
  } else {
    termsOfYear = 0;
  }

  // Annual Interest Rate
  let annualInterestRate = 0;
  if ( identifier === 1 && module12Data.second_interest_rate !== undefined && module12Data.second_interest_rate !== '' ) {
    annualInterestRate = module12Data.second_interest_rate;
  } else {
    annualInterestRate = 0;
  }

  // Original Payment
  let originalPayment = 0;
  if ( loanAmount > 0
      && termsOfYear > 0 ) {
    originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear * 12, -( loanAmount ), 0, 0 );
  }

  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let startYear = 0;
  if ( module12Data.second_purchase_year !== undefined
        && module12Data.second_purchase_year !== '' ) {
    startYear = parseInt( module12Data.second_purchase_year, 10 );
  }
  if ( startYear > 0 ) {
    let year = parseInt( startYear, 10 );
    const yearLimit = year + parseFloat( termsOfYear );

    // Existing Student Loan Data
    carLoanDataTwo = {};
    carLoanDataTwo.Identifier = identifier;
    carLoanDataTwo['Loan Amount'] = loanAmount;
    carLoanDataTwo['Term in Years'] = termsOfYear;
    carLoanDataTwo['Annual Interest Rate'] = annualInterestRate;
    carLoanDataTwo['Original Payment'] = originalPayment;
    let Balance = loanAmount;

    const Payment = originalPayment;
    let Interest = 0;
    if ( annualInterestRate > 0 ) {
      Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
    }
    let Principal = Payment - Interest;
    let Equity = Principal;
    let TotalInterest = Interest;
    const TotalPayment = Equity + TotalInterest;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];

    // For Loop from graduation year to +10 years
    for ( year; year <= yearLimit; year += 1 ) {
      carLoanDataTwo[year] = {};

      carLoanDataTwo[year].LaggingInterest = 0;
      carLoanDataTwo[year].LaggingPrincipal = 0;
      carLoanDataTwo[year]['Months Total Balance'] = 0;

      // For Loop for 12 months
      for ( let month = 1; month <= 12; month += 1 ) {
        carLoanDataTwo[year][month] = {};
        if ( year === startYear && month === 1 ) {
          carLoanDataTwo[year][month].YearIdentifier = year;
          carLoanDataTwo[year][month].Balance = Balance;
          carLoanDataTwo[year][month].Payment = Payment;
          carLoanDataTwo[year][month].Interest = Interest;
          carLoanDataTwo[year][month].Principal = Principal;
          carLoanDataTwo[year][month].Equity = Equity;
          carLoanDataTwo[year][month].TotalInterest = TotalInterest;
          carLoanDataTwo[year][month].TotalPayment = TotalPayment;
        } else {
          // Year Identifier
          carLoanDataTwo[year][month].YearIdentifier = year;

          // Balance
          let tempBalance;
          if ( Balance === 0 ) {
            tempBalance = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempBalance = ( Balance - Principal );
          } else {
            tempBalance = 0;
          }
          carLoanDataTwo[year][month].Balance = tempBalance;

          // Payment
          carLoanDataTwo[year][month].Payment = Payment;

          // Interest
          let tempInterest;
          if ( Balance === 0 ) {
            tempInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          } else {
            tempInterest = 0;
          }
          carLoanDataTwo[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal;
          if ( Balance === 0 ) {
            tempPrincipal = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempPrincipal = Payment - tempInterest;
          } else {
            tempPrincipal = 0;
          }
          carLoanDataTwo[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity;
          if ( Balance === 0 ) {
            tempEquity = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempEquity = Equity + tempPrincipal;
          } else {
            tempEquity = 0;
          }
          carLoanDataTwo[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest;
          if ( Balance === 0 ) {
            tempTotalInterest = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempTotalInterest = TotalInterest + tempInterest;
          } else {
            tempTotalInterest = 0;
          }
          carLoanDataTwo[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment;
          if ( Balance === 0 ) {
            tempTotalPayment = 0;
          } else if ( ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          } else {
            tempTotalPayment = 0;
          }
          carLoanDataTwo[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( carLoanDataTwo[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( carLoanDataTwo[year][month].TotalPayment );

        carLoanDataTwo[year].LaggingInterest = parseFloat( carLoanDataTwo[year].LaggingInterest ) + parseFloat( carLoanDataTwo[year][month].Interest );
        carLoanDataTwo[year].LaggingPrincipal = parseFloat( carLoanDataTwo[year].LaggingPrincipal ) + parseFloat( carLoanDataTwo[year][month].Principal );
        carLoanDataTwo[year]['Months Total Balance'] = parseFloat( carLoanDataTwo[year]['Months Total Balance'] ) + parseFloat( carLoanDataTwo[year][month].Balance );
      }
    }

    // Total Interest Paid
    if ( totalInterestPaidArray.length > 0 ) {
      totalInterestPaid = Math.max.apply( null, totalInterestPaidArray );
    }

    // Total Paid
    if ( totalPaidArray.length > 0 ) {
      totalPaid = Math.max.apply( null, totalPaidArray );
    }
  } else {
    carLoanDataTwo = {};
    carLoanDataTwo.Identifier = identifier;
    carLoanDataTwo['Loan Amount'] = loanAmount;
    carLoanDataTwo['Term in Years'] = termsOfYear;
    carLoanDataTwo['Annual Interest Rate'] = annualInterestRate;
    carLoanDataTwo['Original Payment'] = originalPayment;
  }
  // Summation Data
  if ( module1Data.start_year !== undefined
        && module1Data.start_year !== '' && parseInt( module1Data.start_year, 10 ) > 0 ) {
    let summationYear = parseInt( module1Data.start_year, 10 );
    const summationYearLimit = summationYear + 30;

    // Summation Data
    summationData = {};

    let summationBeginningBalance = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationTotalInterest = 0;
    let summationTotalPrincipal = 0;
    let summationEndingBalance = 0;
    let yearwithTerm = 0;

    // For Loop from Start year to +14 years
    for ( summationYear; summationYear < summationYearLimit; summationYear += 1 ) {
      summationData[summationYear] = {};

      if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== '' && module12Data.second_term_of_the_loan !== undefined && module12Data.second_term_of_the_loan !== '' ) {
        yearwithTerm = parseFloat( module12Data.second_purchase_year ) + termsOfYear;
      } else {
        yearwithTerm = 0;
      }
      // Beginning Balance
      if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
                && summationYear < module12Data.second_purchase_year ) {
        summationBeginningBalance = 0;
      } else if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
                    && summationYear >= module12Data.second_purchase_year ) {
        if ( module12Data.second_purchase_year !== undefined && module12Data.second_purchase_year !== ''
                        && summationYear <= yearwithTerm ) {
          if ( carLoanDataTwo !== undefined
                             && carLoanDataTwo[summationYear] !== undefined
                             && carLoanDataTwo[summationYear][1].Balance !== undefined ) {
            summationBeginningBalance = carLoanDataTwo[summationYear][1].Balance;
          } else {
            summationBeginningBalance = 0;
          }
        } else {
          summationBeginningBalance = 0;
        }
      } else {
        summationBeginningBalance = 0;
      }
      summationData[summationYear].BeginningBalance = summationBeginningBalance;

      // Lagging 12Mo. Interest
      let LaggingVal1 = 0;
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( carLoanDataTwo[summationYear] !== undefined && carLoanDataTwo[summationYear].LaggingInterest !== undefined ) {
          LaggingVal1 = carLoanDataTwo[summationYear].LaggingInterest;
        } else {
          LaggingVal1 = 0;
        }
      } else {
        LaggingVal1 = 0;
      }
      summationLaggingInterest = LaggingVal1;
      summationData[summationYear].LaggingInterest = summationLaggingInterest;

      // Lagging 12Mo. Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        if ( carLoanDataTwo[summationYear] !== undefined && carLoanDataTwo[summationYear].LaggingPrincipal !== undefined ) {
          summationLaggingPrincipal = carLoanDataTwo[summationYear].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[summationYear].LaggingPrincipal = summationLaggingPrincipal;

      // Total Interest
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalInterest += summationData[summationYear].LaggingInterest;
      } else {
        summationTotalInterest = 0;
      }
      summationData[summationYear].TotalInterest = summationTotalInterest;

      // Cumulative Principal
      if ( summationData[summationYear].BeginningBalance > 1 ) {
        summationTotalPrincipal += summationData[summationYear].LaggingPrincipal;
      } else {
        summationTotalPrincipal = 0;
      }
      summationData[summationYear].TotalPrincipal = summationTotalPrincipal;

      // Ending Balance
      if ( summationData[summationYear].BeginningBalance > summationData[summationYear].LaggingPrincipal ) {
        summationEndingBalance = summationData[summationYear].BeginningBalance - summationData[summationYear].LaggingPrincipal;
      } else {
        summationEndingBalance = 0;
      }
      summationData[summationYear].EndingBalance = summationEndingBalance;
    }
  }
  // To return Car Loan Complete Data
  function carLoan2Data() {
    const CarLoanTwoFullData = {};
    CarLoanTwoFullData['Car Loan2'] = carLoanDataTwo;
    CarLoanTwoFullData['Summation Data'] = summationData;
    CarLoanTwoFullData['Total Paid'] = totalPaid;
    CarLoanTwoFullData['Total Interest Paid'] = totalInterestPaid;
    CarLoanTwoFullData['Annual Interest Rate'] = annualInterestRate;
    return CarLoanTwoFullData;
  }
  return carLoan2Data();
}

export default carLoan2;
