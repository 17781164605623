import { PMT, CUMIPMT } from '../../utilities/excelLibrary';
// eslint-disable-next-line
let summationData = {};

function PrivateExistingLoan( module1Data, module18Data ) {
  // Student Loan Excel Sheet
  let studentLoanData = {};
  
  const repaymentData = {};
  const repaymentData1 = {};
  let originalPayment = 0;
  let annualInterestRate = 0;
  let interestAccrued = 0;
  let termsOfYear = 0;
  let paymentPlanIdentifier = 3;
  if ( typeof module18Data.private_payment_plan !== 'undefined'
    && module18Data.private_payment_plan !== null && module18Data.private_payment_plan !== 'null' ) {
    if ( module18Data.private_payment_plan !== ''
      && module18Data.private_payment_plan === 1 ) {
      paymentPlanIdentifier = 2;
    }
  }

  // Loan Amount
  let loanAmount = 0;
  if ( typeof module18Data.private_loan_amount !== 'undefined' && module18Data.private_loan_amount !== '' ) {
    loanAmount = module18Data.private_loan_amount;
  }

  // Year Repayment Begin
  const yearRepaymentBegin = parseInt( module1Data.start_year, 10 ) + parseInt( module18Data.private_months, 10 ) / 12;

  // Year repayment begin in month
  let yearRepaymentBeginMonth = parseInt( module18Data.private_months, 10 );

  // Intrayear Accrual
  const intraYearAccrual = yearRepaymentBegin - parseInt( yearRepaymentBegin, 10 );

  // Beginning Balance
  let beginningBalance = 0;

  // Total Interest Paid
  let totalInterestPaid = 0;

  // Total Paid
  let totalPaid = 0;

  // Tabular Data
  let graduationYear = 0;
  graduationYear = parseInt( module1Data.start_year, 10 );
  if ( graduationYear > 0 ) {
    let year = parseInt( graduationYear, 10 );
    const yearLimit = year + 29;

    // Student Loan Data
    studentLoanData = {};
    summationData = {};

    let Balance = 0;
    let Payment = 0;
    let Interest = 0;
    let Principal = 0;
    let Equity = 0;
    let TotalInterest = 0;
    let TotalPayment = 0;

    const totalInterestPaidArray = [];
    const totalPaidArray = [];
    let period = 1;
    let summationCurrentBalanceValue = 0;
    let summationBeginningBalance = 0;
    let summationBeginningBalance1 = 0;
    let summationLaggingInterest = 0;
    let summationLaggingPrincipal = 0;
    let summationCumulativeInterest = 0;
    let summationCumulativePrincipal = 0;
    let summationEndingBalance = 0;
    let summationEndingBalance1 = 0;
    let summationAccruedInterest = 0;
    let summationCapitalizedInterest = 0;
    let summationCapitalizedInterestTaxDeduction = 0;
    let summationRemainingCapitalizedInterest = 0;
    let k = 0;
    let accruedInterest = 0;
    let interestPaidBeforeRepayment = 0;
    let totalInterestPaidBeforeRepayment = 0;
    let wouldBeAccruedInterest = 0;
    let totalWouldBeAccruedInterest = 0;

    let privateMonthValue = 1;
    yearRepaymentBeginMonth = parseInt( module18Data.private_months, 10 );

    // For Loop from graduation year to +10 years
    for ( year; year <= yearLimit; year += 1 ) {
      studentLoanData[year] = {};
      summationData[year] = {};
      studentLoanData[year].LaggingInterest = 0;
      studentLoanData[year].LaggingPrincipal = 0;
      studentLoanData[year]['Months Total Balance'] = 0;

      // Current Balance
      summationCurrentBalanceValue = 0;
      if ( module18Data.existingStudentLoansPrivateCurrentLoan > 0 ) {
        summationCurrentBalanceValue = module18Data.existingStudentLoansPrivateCurrentLoan;
      }
      summationData[year]['Current Balance'] = summationCurrentBalanceValue;

      // Accrued Interest
	  if ( typeof module18Data.private_interest_rate !== 'undefined' && module18Data.private_interest_rate !== '' ) {
        annualInterestRate = parseFloat( module18Data.private_interest_rate );
      }
      if ( paymentPlanIdentifier === 3 ) {
        if ( year <= yearRepaymentBegin
          && year + 1 > yearRepaymentBegin ) {
          summationAccruedInterest = ( ( summationCurrentBalanceValue / 100 ) * annualInterestRate ) * intraYearAccrual;
        } else{
			if ( year < yearRepaymentBegin ) {
			  summationAccruedInterest = ( summationCurrentBalanceValue / 100 ) * annualInterestRate;
			} else {
			  summationAccruedInterest = 0;
			}
		}
      }
      summationData[year]['Accrued Interest'] = summationAccruedInterest;
      accruedInterest += summationAccruedInterest;

      // Beginning Balance 1
      if ( k === 0 ) {
        if ( year <= yearRepaymentBegin
          && year + 1 > yearRepaymentBegin ) {
          summationBeginningBalance1 = summationCurrentBalanceValue + summationAccruedInterest;
        } else if ( year < yearRepaymentBegin ) {
          summationBeginningBalance1 = summationCurrentBalanceValue;
        }
      } else if ( year <= yearRepaymentBegin
          && year + 1 > yearRepaymentBegin ) {
        summationBeginningBalance1 = summationEndingBalance1 + summationAccruedInterest;
      } else if ( year < yearRepaymentBegin ) {
        summationBeginningBalance1 = summationEndingBalance1;
      }
      summationData[year]['BeginningBalance 1'] = summationBeginningBalance1;

      // Ending Balance 1
      if ( year <= yearRepaymentBegin
        && year + 1 > yearRepaymentBegin ) {
        summationEndingBalance1 = 0;
      } else if ( year < yearRepaymentBegin ) {
        summationEndingBalance1 = summationBeginningBalance1 + summationAccruedInterest;
      } else {
        summationEndingBalance1 = 0;
      }
      summationData[year]['EndingBalance 1'] = summationEndingBalance1;

      // Capitalized Interest
      if ( summationAccruedInterest > 0 ) {
        // removed converters form below code----mahananda
        summationCapitalizedInterest = accruedInterest + module18Data.private_accrued_interest;
      } else {
        summationCapitalizedInterest = summationRemainingCapitalizedInterest;
      }
      summationData[year]['Capitalized Interest'] = summationCapitalizedInterest;

      // Beginning Balance

      if ( k === 0 ) {
        if ( year <= yearRepaymentBegin ) {
          summationBeginningBalance = summationData[year]['BeginningBalance 1'];
        } else {
          summationBeginningBalance = 0;
        }
      } else{
		  if( year < yearRepaymentBegin && ( year + 1 ) > yearRepaymentBegin ){
			  summationBeginningBalance = summationBeginningBalance1;
		  } else{
			  summationBeginningBalance = summationEndingBalance;
		  }
	  }
	  
	  /*else if ( year < yearRepaymentBegin ) {
        summationBeginningBalance = summationBeginningBalance1;
      } else if ( year <= yearRepaymentBegin
            && year + 1 > yearRepaymentBegin ) {
        summationBeginningBalance = summationEndingBalance;
      } else {
        summationBeginningBalance = summationEndingBalance;
      }*/
      summationData[year].BeginningBalance = summationBeginningBalance;
	  
      if ( typeof summationData !== 'undefined'
        && typeof summationData[parseInt( yearRepaymentBegin, 10 )] !== 'undefined'
        && typeof summationData[parseInt( yearRepaymentBegin, 10 )].BeginningBalance !== 'undefined' ) {
        beginningBalance = summationData[parseInt( yearRepaymentBegin, 10 )].BeginningBalance;
      } else {
        beginningBalance = 0;
      }
	  if ( beginningBalance > 0 ) {
        termsOfYear = parseFloat( module18Data.private_term );
      }

      // Annual Interest Rate

      if ( typeof module18Data.private_interest_rate !== 'undefined' && module18Data.private_interest_rate !== '' ) {
        annualInterestRate = parseFloat( module18Data.private_interest_rate );
      }

      // Original Payment

      if ( beginningBalance > 0
        && termsOfYear > 0 ) {
        originalPayment = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, termsOfYear, -( beginningBalance ), 0, 0 );
      }

      // Interest Accrued
      if ( beginningBalance > 0
        && loanAmount > 0 ) {
        interestAccrued = beginningBalance - loanAmount;
      }

      k += 1;

      for ( let month = 1; month <= 12; month += 1 ) {
        studentLoanData[year][month] = {};

        if ( year === graduationYear && month === 1 ) {
          studentLoanData[year][month].YearIdentifier = year;

          if ( period === parseInt( module18Data.private_months, 10 ) + 1 ) {
            Balance = beginningBalance;
          } else {
            Balance = 0;
          }
          studentLoanData[year][month].Balance = Balance;

          studentLoanData[year][month].Month = privateMonthValue;
          privateMonthValue += 1;

          if ( Balance > 0 ) {
            Payment = originalPayment;
          } else {
            Payment = 0;
          }
          studentLoanData[year][month].Payment = Payment;

          if ( Balance > 0 && annualInterestRate > 0 ) {
            Interest = Balance * ( ( annualInterestRate / 100 ) / 12 );
          } else {
            Interest = 0;
          }
          studentLoanData[year][month].Interest = Interest;

          if ( Balance > 0 ) {
            Principal = Payment - Interest;
          } else {
            Principal = 0;
          }
          studentLoanData[year][month].Principal = Principal;

          if ( Balance > 0 ) {
            Equity = Principal;
          } else {
            Equity = 0;
          }
          studentLoanData[year][month].Equity = Equity;

          if ( Balance > 0 ) {
            TotalInterest = Interest;
          } else {
            TotalInterest = 0;
          }
          studentLoanData[year][month].TotalInterest = TotalInterest;

          if ( Balance > 0 ) {
            TotalPayment = Equity + TotalInterest;
          } else {
            TotalPayment = 0;
          }
          studentLoanData[year][month].TotalPayment = TotalPayment;
        } else {
          // Year Identifier
          studentLoanData[year][month].YearIdentifier = year;

          // Balance
          let tempBalance = 0;
          if ( period === parseInt( module18Data.private_months, 10 ) + 1 ) {
            tempBalance = beginningBalance;
          } else if ( ( year >= parseInt( module18Data.private_months, 10 ) + 1 ) && ( Balance.toFixed( 6 ) - Principal.toFixed( 6 ) ) > 1 ) {
            tempBalance = ( Balance - Principal );
          }
          studentLoanData[year][month].Balance = tempBalance;

          studentLoanData[year][month].Month = privateMonthValue;
          if ( tempBalance > 0 || yearRepaymentBeginMonth > 0 ) {
            studentLoanData['Final Month'] = privateMonthValue;
            studentLoanData['Final Year'] = year;
            yearRepaymentBeginMonth -= 1;
            privateMonthValue += 1;
          } else {
            privateMonthValue = 0;
          }

          // Payment
          let tempPayment = 0;
          if ( tempBalance > 0 ) {
            tempPayment = originalPayment;
          } else {
            tempPayment = 0;
          }
          studentLoanData[year][month].Payment = tempPayment;

          // Interest
          let tempInterest = 0;
          if ( tempBalance > 0 && annualInterestRate > 0 ) {
            tempInterest = tempBalance * ( ( annualInterestRate / 100 ) / 12 );
          } else {
            tempInterest = 0;
          }
          studentLoanData[year][month].Interest = tempInterest;

          // Principal
          let tempPrincipal = 0;
          if ( tempBalance > 0 ) {
            tempPrincipal = tempPayment - tempInterest;
          } else {
            tempPrincipal = 0;
          }
          studentLoanData[year][month].Principal = tempPrincipal;

          // Equity
          let tempEquity = 0;
          if ( period === parseInt( module18Data.private_months, 10 ) + 1 ) {
            tempEquity = tempPrincipal;
          } else if ( tempBalance > 0 && ( year >= parseInt( module18Data.private_months, 10 ) + 1 ) ) {
            tempEquity = Equity + tempPrincipal;
          }
          studentLoanData[year][month].Equity = tempEquity;

          // Total Interest
          let tempTotalInterest = 0;
          if ( period === parseInt( module18Data.private_months, 10 ) + 1 ) {
            tempTotalInterest = tempInterest;
          } else if ( tempBalance > 0 && ( year >= parseInt( module18Data.private_months, 10 ) + 1 ) ) {
            tempTotalInterest = TotalInterest + tempInterest;
          }
          studentLoanData[year][month].TotalInterest = tempTotalInterest;

          // Total Payments
          let tempTotalPayment = 0;
          if ( period >= parseInt( module18Data.private_months, 10 ) + 1 ) {
            tempTotalPayment = tempEquity + tempTotalInterest;
          } else {
            tempTotalPayment = 0;
          }
          studentLoanData[year][month].TotalPayment = tempTotalPayment;

          // Assign Values
          Balance = tempBalance;
          Principal = tempPrincipal;
          Equity = tempEquity;
          TotalInterest = tempTotalInterest;
        }

        // Total Interest Paid Array
        totalInterestPaidArray.push( studentLoanData[year][month].TotalInterest );

        // Total Paid Array
        totalPaidArray.push( studentLoanData[year][month].TotalPayment );

        studentLoanData[year].LaggingInterest = parseFloat( studentLoanData[year].LaggingInterest ) + parseFloat( studentLoanData[year][month].Interest );
        studentLoanData[year].LaggingPrincipal = parseFloat( studentLoanData[year].LaggingPrincipal ) + parseFloat( studentLoanData[year][month].Principal );
        studentLoanData[year]['Months Total Balance'] = parseFloat( studentLoanData[year]['Months Total Balance'] ) + parseFloat( studentLoanData[year][month].Balance );

        period += 1;
      }

      // Lagging 12Mo. Interest
      let val1 = 0;
      if ( summationData[year].BeginningBalance > 1 ) {
        if ( typeof studentLoanData[year] !== 'undefined'
          && typeof studentLoanData[year].LaggingInterest !== 'undefined' ) {
          val1 = studentLoanData[year].LaggingInterest;
        } else {
          val1 = 0;
        }
      }
      let val2 = 0;
      if ( paymentPlanIdentifier === 2 ) {
        if ( year <= yearRepaymentBegin
          && year + 1 > yearRepaymentBegin ) {
          val2 = summationBeginningBalance * ( annualInterestRate / 100 ) * intraYearAccrual;
        } else if ( year < yearRepaymentBegin ) {
          val2 = summationBeginningBalance * ( annualInterestRate / 100 );
        } else {
          val2 = 0;
        }
      }
      summationLaggingInterest = val1 + val2;
      summationData[year].LaggingInterest = summationLaggingInterest;
      totalInterestPaid = totalInterestPaid + summationLaggingInterest + summationAccruedInterest;

      // Lagging 12Mo. Principal
      if ( summationData[year].BeginningBalance > 1 ) {
        if ( typeof studentLoanData[year] !== 'undefined'
          && typeof studentLoanData[year].LaggingPrincipal !== 'undefined' ) {
          summationLaggingPrincipal = studentLoanData[year].LaggingPrincipal;
        } else {
          summationLaggingPrincipal = 0;
        }
      } else {
        summationLaggingPrincipal = 0;
      }
      summationData[year].LaggingPrincipal = summationLaggingPrincipal;
      totalPaid = totalPaid + summationLaggingInterest + summationLaggingPrincipal;

      // Capitalized Interest Tax Deduction
      if ( summationLaggingPrincipal > 0 ) {
        if ( summationCapitalizedInterest > summationLaggingPrincipal ) {
          summationCapitalizedInterestTaxDeduction = summationLaggingPrincipal;
        } else if ( summationCapitalizedInterest > 0 ) {
          summationCapitalizedInterestTaxDeduction = summationCapitalizedInterest;
        } else {
          summationCapitalizedInterestTaxDeduction = 0;
        }
      } else {
        summationCapitalizedInterestTaxDeduction = 0;
      }
      summationData[year]['Capitalized Interest Tax Deduction'] = summationCapitalizedInterestTaxDeduction;

      // Remaining Capitalized Interest
      summationRemainingCapitalizedInterest = summationCapitalizedInterest - summationCapitalizedInterestTaxDeduction;
      summationData[year]['Remaining Capitalized Interest'] = summationRemainingCapitalizedInterest;

      // Cumulative Interest
      if ( summationData[year].BeginningBalance > 1 ) {
        summationCumulativeInterest += summationData[year].LaggingInterest;
      } else {
        summationCumulativeInterest = 0;
      }
      summationData[year].CumulativeInterest = summationCumulativeInterest;

      // Cumulative Principal
      if ( summationData[year].BeginningBalance > 1 ) {
        summationCumulativePrincipal += summationData[year].LaggingPrincipal;
      } else {
        summationCumulativePrincipal = 0;
      }
      summationData[year].CumulativePrincipal = summationCumulativePrincipal;

      // Ending Balance
      if ( year <= yearRepaymentBegin
        && year + 1 > yearRepaymentBegin ) {
        summationEndingBalance = summationBeginningBalance - summationLaggingPrincipal;
      } else if ( year < yearRepaymentBegin ) {
        summationEndingBalance = summationEndingBalance1;
      } else {
        summationEndingBalance = summationBeginningBalance - summationLaggingPrincipal;
      }
      summationData[year].EndingBalance = summationEndingBalance;

      // Interest Paid before Repayment
      if ( paymentPlanIdentifier === 2 ) {
        if ( year === Math.floor( yearRepaymentBegin, 0 ) ) {
          interestPaidBeforeRepayment = ( ( ( summationData[year].BeginningBalance * annualInterestRate ) / 12 ) / 100 ) * ( 12 * intraYearAccrual );
        } else if ( year < yearRepaymentBegin ) {
          interestPaidBeforeRepayment = summationData[year].LaggingInterest;
        } else {
          interestPaidBeforeRepayment = 0;
        }
      } else {
        interestPaidBeforeRepayment = 0;
      }

      totalInterestPaidBeforeRepayment += interestPaidBeforeRepayment;
      summationData[year]['Interest Paid before Repayment'] = interestPaidBeforeRepayment;

      // Would Be Accrued Interest
      if ( year <= yearRepaymentBegin && year + 1 > yearRepaymentBegin ) {
        wouldBeAccruedInterest = ( ( summationCurrentBalanceValue / 100 ) * annualInterestRate ) * intraYearAccrual;
      } else if ( year < yearRepaymentBegin ) {
        wouldBeAccruedInterest = ( ( summationCurrentBalanceValue / 100 ) * annualInterestRate );
      } else {
        wouldBeAccruedInterest = 0;
      }
      summationData[year]['Would Be Accrued Interest'] = wouldBeAccruedInterest;
      totalWouldBeAccruedInterest += wouldBeAccruedInterest;
    }
    // To get Repayment Year Values
    repaymentData['Interest Rate'] = annualInterestRate;
    repaymentData['Payment Period'] = 'Monthly';
    repaymentData['Term of the Loan (years)'] = termsOfYear / 12;
    repaymentData['Loan Amount'] = loanAmount;
    if ( paymentPlanIdentifier === 2 ) {
      repaymentData['Interest Paid before Repayment'] = totalInterestPaidBeforeRepayment;
    } else {
      repaymentData['Interest Paid before Repayment'] = 0;
    }

    if ( paymentPlanIdentifier === 3 ) {
      repaymentData['Accrued Interest'] = interestAccrued;
    } else {
      repaymentData['Accrued Interest'] = 0;
    }
    repaymentData['Beginning Balance after Grace Period'] = beginningBalance;
    repaymentData['Monthly Payment'] = originalPayment;
    repaymentData['Total Interest Paid'] = totalInterestPaid;
    repaymentData['Total Repayment Amount (Principal & Interest)'] = totalPaid;

    // To get New Repayment Year Values
    repaymentData1['Interest Rate'] = annualInterestRate;
    repaymentData1['Payment Period'] = 'Monthly';
    repaymentData1['Term of the Loan (years)'] = termsOfYear / 12;
    repaymentData1['Loan Amount'] = loanAmount;
    if ( paymentPlanIdentifier === 3 ) {
      repaymentData1['Interest Paid before Repayment'] = totalWouldBeAccruedInterest;
    } else {
      repaymentData1['Interest Paid before Repayment'] = 0;
    }
    if ( paymentPlanIdentifier === 2 ) {
      repaymentData1['Accrued Interest'] = totalWouldBeAccruedInterest;
    } else {
      repaymentData1['Accrued Interest'] = 0;
    }
    repaymentData1['Beginning Balance after Grace Period'] = loanAmount + repaymentData1['Accrued Interest'];

    repaymentData1['Monthly Payment'] = PMT( ( parseFloat( annualInterestRate ) / 100 ) / 12, repaymentData1['Term of the Loan (years)'] * 12, -( repaymentData1['Beginning Balance after Grace Period'] ), 0, 0 );

    repaymentData1['Total Interest Paid'] = -CUMIPMT( ( annualInterestRate / 100 ) / 12, repaymentData1['Term of the Loan (years)'] * 12, repaymentData1['Beginning Balance after Grace Period'], 1, repaymentData1['Term of the Loan (years)'] * 12, 0 ) + repaymentData1['Interest Paid before Repayment'] + repaymentData1['Accrued Interest'];

    repaymentData1['Total Repayment Amount (Principal & Interest)'] = loanAmount + repaymentData1['Total Interest Paid'];
  }

  function studentLoanDataFun() {
    const studentLoanCompleteData = {};

    studentLoanCompleteData['Summation Data'] = summationData;
    studentLoanCompleteData['Total Interest Paid'] = totalInterestPaid;
    studentLoanCompleteData['Total Paid'] = totalPaid;
    studentLoanCompleteData['Annual Interest Rate'] = annualInterestRate;
    studentLoanCompleteData['Fed Sub Higher Education'] = studentLoanData;
    studentLoanCompleteData['Year Repayment Begin Month'] = yearRepaymentBeginMonth;
    studentLoanCompleteData['Loan Amount'] = loanAmount;
    studentLoanCompleteData['Accrued Interest'] = interestAccrued;
    studentLoanCompleteData['Beginning Balance'] = beginningBalance;
    studentLoanCompleteData['Terms In Year'] = termsOfYear;
    studentLoanCompleteData['Original Payment'] = originalPayment;
    studentLoanCompleteData['Monthly Payment'] = originalPayment;
    studentLoanCompleteData['Year Repayment Begin'] = yearRepaymentBegin;
    studentLoanCompleteData['Intra Year Accrual'] = intraYearAccrual;
    studentLoanCompleteData['Repayment Data'] = repaymentData;
    studentLoanCompleteData['Repayment Data New'] = repaymentData1;
	studentLoanCompleteData['PaymentPlan Identifier'] = paymentPlanIdentifier;
	return studentLoanCompleteData;
  }
  return studentLoanDataFun();
}
export default PrivateExistingLoan;
