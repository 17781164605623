import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import NumberFormat from 'react-number-format';
// import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Dialog
} from '@material-ui/core';
import infoIcon from '../../../assets/img/pwi-he/info-icon.svg';
import logoIcon from '../../../assets/img/financial-calculators/higher_education.png';
import styles from './styles';
import Pageloader from '../../../components/ui/pageloader';
import iconDesc from '../../../assets/img/icon_description.png';
import iconRange from '../../../assets/img/icon_range.png';
import NationEarning from './nationEarning';
import StateEarning from './stateEarning';
import TENeeded from './teNeeded';

const SEARCH_DATA_COMPARE_DATA = loader('../../../graphql/schema/compare-careers/searchCompare-career.graphql');
const COMPARE_CAREER_DETAILS = loader('../../../graphql/schema/compare-careers/getCompare-careers.graphql');

const CompareSchools = (props) => {
  const { classes, history } = props;



  // const collegeObj = {
  //   act_average_scores: '--',
  //   admission_rate: '67%',
  //   after_federal_loans: '$19,159',
  //   avg_annual_cost: '$16,395 ',
  //   avg_federal_loans_amount: '$4,925',
  //   avg_gift_aid_amount: '$7,278',
  //   created_date: '2020-09-24 06:02:10',
  //   enrollment: '4,187',
  //   expected_monthly_payment: '$199',
  //   federal_loans_default_rate: '10.1%',
  //   first_federal_loans: '47.3%',
  //   first_gift_aid: '49%',
  //   freshman_retention_rate: '69%',
  //   future_earnings: '2.3x',
  //   graduation_rate: '45%',
  //   id: '2020',
  //   in_state_fee: '$16,917',
  //   location: '--',
  //   median_earnings: '$38,463',
  //   out_state_fee: '$20,194 ',
  //   range1: '$13,570',
  //   range2: '$14,121',
  //   range3: '$16,320',
  //   range4: '$18,965',
  //   range5: '$21,891',
  //   room_board: '$5,934 ',
  //   sat_averate_math_scores: '--',
  //   sat_averate_reading_scores: '--',
  //   school_name: 'All Colleges',
  //   school_type: 'college',
  // };

  // const careerSchoolObj = {
  //   act_average_scores: '--',
  //   admission_rate: '77%',
  //   after_federal_loans: '$9,864',
  //   avg_annual_cost: '$16,227',
  //   avg_federal_loans_amount: '$5,623',
  //   avg_gift_aid_amount: '$672',
  //   created_date: '2020-09-24 06:02:10',
  //   enrollment: '219',
  //   expected_monthly_payment: '$102',
  //   federal_loans_default_rate: '13.4%',
  //   first_federal_loans: '58.9%',
  //   first_gift_aid: '8.9%',
  //   freshman_retention_rate: '73%',
  //   future_earnings: '1.5x',
  //   graduation_rate: '69%',
  //   id: '2020',
  //   in_state_fee: '$16,411',
  //   location: '--',
  //   median_earnings: '$25,091',
  //   out_state_fee: '$16,411',
  //   range1: '$15,734',
  //   range2: '$16,703',
  //   range3: '$18,550',
  //   range4: '$20,745',
  //   range5: '$21,056',
  //   room_board: '$93',
  //   sat_averate_math_scores: '--',
  //   sat_averate_reading_scores: '--',
  //   school_name: 'All Career Schools',
  //   school_type: 'Career Schools',
  // };

  const [hideSearch, setHideSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hideSearchCollege, setHideSearchCollege] = useState(false);
  const [collegeSearchList, setCollegeSearchList] = useState([]);
  const [collegeList, setCollegeList] = useState([]);
  const [state, setState] = useState([]);
  const [stateValue, setSatateValue] = useState('Alabama');
  const [data1, setData1] = useState('');
  const [data2, setData2] = useState('');
  const [dataCode1, setDataCode1] = useState('');
  const [dataCode2, setDataCode2] = useState('');
  const [careerDesc, setCareerDesc] = useState(false);
  const [jobZoneDesc, setJobZoneDesc] = useState(false);
  const [relatedDesc, setRelatedDesc] = useState(false);
  const [data1Career, setData1Career] = useState('');
  const [data1State, setData1State] = useState('');
  const [data2Career, setData2Career] = useState('');
  const [data2State, setData2State] = useState('');
  const [stateAvg, setStateAvg] = useState('');
  const [nationalEarning, setNationalEarning] = useState(false);
  const [stateEarning, setStateEarning] = useState(false);
  const [teNeeded, setTENeeded] = useState(false);
  const [resultData, setResulstData] = useState('');
  // const [typeOfHe, setTypeOfHE] = useState( 'college' );

  const [compareCareers] = useLazyQuery(COMPARE_CAREER_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      setLoading(false);
      setState(response.getCompairCareerData.states)
      setCollegeSearchList(response.getCompairCareerData.jobs)
      setCollegeList(response.getCompairCareerData.jobs);

    },
  },
  )



  const [searchCompareCareers] = useLazyQuery(SEARCH_DATA_COMPARE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        data1_code: dataCode1,
        data1_job_title: data1,
        data2_code: dataCode2,
        data2_job_title: data2,
        state: stateValue
      },
    },
    onCompleted(response) {
      setLoading(false);
      setData1Career(response.searchDataCompareData.data1.career);
      setData1State(response.searchDataCompareData.data1.state);
      setData2Career(response.searchDataCompareData.data2.career);
      setData2State(response.searchDataCompareData.data2.state);
      setStateAvg(response.searchDataCompareData.average);
      setResulstData(response.searchDataCompareData);
    },
  },
  );

  useEffect(() => {
    compareCareers();
  }, []);

  const handleSearch = (e) => {
    setData1(e.target.value);
    setHideSearch(e.target.value !== '');
    setHideSearchCollege(false);
    const dataFilter = collegeList.filter((user) => user.job_title.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
    setCollegeSearchList(dataFilter);
  };

  const handleSearchCollege = (e) => {
    setData2(e.target.value);
    setHideSearchCollege(e.target.value !== '');
    setHideSearch(false);
    const dataFilter = collegeList.filter((user) => user.job_title.toLowerCase().search(e.target.value.toLowerCase()) !== -1);
    setCollegeSearchList(dataFilter);
  };

  const handleSearchSelect = (list) => {
    setData1(list.job_title);
    setDataCode1(list.code);
    setHideSearch(false);
    setLoading(true);
    searchCompareCareers();
  };

  const handleSearchSelectCollege = (dataList) => {
    setData2(dataList.job_title);
    setDataCode2(dataList.code);
    setHideSearchCollege(false);
    setLoading(true);
    searchCompareCareers();
  };

  const redirectToHigherEducation = () => {
    history.push('/plan-with-inkwiry/he');
  };

  const handleChange = (e) => {
    setSatateValue(e.target.value);
    setLoading(true);
    searchCompareCareers();
  }



  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div className={classes.bondProfitPage}>
        <div className={classes.formHorizontal}>
          <div className={classes.container}>
            <ul className={classes.breadCrumbs}>
              <li><Link to="/financial-calculators">Financial Calculators</Link></li>

              <span> &gt; </span>
              <li>Compare Careers</li>
            </ul>
            <div className={classes.csBox}>
              <div className={classes.blueBorder}>
                <div className={classes.csBreadcrumbs}>
                  <div>
                    <h3 className={classes.csTitle}>Compare Careers</h3>
                  </div>
                  <div className={classes.csLogo} onClick={redirectToHigherEducation} aria-hidden="true">
                    <h3>
                      <span>
                        <img src={logoIcon} alt="" />
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      Career Path
                    </h3>
                  </div>
                </div>
                <div className={classes.mainContainer}>
                  <div className={classes.compareSchool}>
                    <h3>Leverage Inkwiry&apos;s career comparison tool to explore hundreds of career and see how they stack up side-by-side.</h3>
                    <div className={classes.typeOfHe}>
                      <div className={classes.labelHe}>
                        <span>State:</span>
                        <div className={classes.statesOption}>
                          <select onChange={handleChange}>
                            {state.length > 0 && state.map((value, index) =>
                              <option key={index} value={value}>{value}</option>
                            )}
                          </select>
                        </div>
                      </div>
                      {/* <div className={ classes.radioWrapper }>
                        <div className={ typeOfHe === 'college' && classes.activeClass } onClick={ () => handleTypeOfHE( 'college' ) } aria-hidden="true">
                          College
                        </div>
                        <div className={ typeOfHe === 'school' && classes.activeClass } onClick={ () => handleTypeOfHE( 'school' ) } aria-hidden="true">
                          Career School
                        </div>
                      </div> */}
                    </div>
                    <div className={classes.selectSection}>
                      <div className={classes.titleOne} />
                      <div className={classes.titleTwo}>
                        <input type="text" placeholder="Start typing a career name here" value={data1} onClick={() => { setHideSearch(true) }} onChange={handleSearch} />
                        {hideSearch && (
                          <div className={classes.searchList}>
                            <ul>
                              {collegeSearchList && collegeSearchList.map((list) => <li aria-hidden="true" onClick={() => handleSearchSelect(list)}>{list.job_title}</li>)}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className={classes.titleFour}>&nbsp;</div>
                      <div className={classes.titleThree}>
                        <input type="text" placeholder="Start typing a career name here" value={data2} onClick={() => { setHideSearchCollege(true) }} onChange={handleSearchCollege} />
                        {hideSearchCollege && (
                          <div className={classes.searchList}>
                            <ul>
                              {collegeSearchList && collegeSearchList.map((dataList) => <li aria-hidden="true" onClick={() => handleSearchSelectCollege(dataList)}>{dataList.job_title}</li>)}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={classes.federalFlow}>

                      <table className={classes.tableStriped}>
                        <tbody>
                          <tr className={classes.schoolName}>
                            <td>&nbsp;</td>
                            <td>Career Name</td>
                            <td>{data1State && data1State.title ? data1State.title : ''}</td>
                            <td>All Careers</td>
                            <td>{data2State && data2State.title ? data2State.title : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Click to see a longer description of your selected career." /></td>
                            <td>Description&nbsp;&nbsp;<span className={classes.iconRange} onClick={() => setCareerDesc(true)}><img src={iconDesc} alt=''/></span></td>
                            <td>{data1Career && data1Career.display_description ? data1Career.display_description : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.display_description ? data2Career.display_description : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The percentage of the number of jobs that are self-employed for your selected career." /></td>
                            <td>% Self-Employed</td>
                            <td>{data1Career && data1Career.self_emp ? <NumberFormat value={data1Career.self_emp} displayType="text" thousandSeparator suffix="%" /> : ''}</td>
                            <td>5.8%</td>
                            <td>{data2Career && data2Career.self_emp ? <NumberFormat value={data2Career.self_emp} displayType="text" thousandSeparator suffix="%" /> : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Total number of jobs for your selected career in the country." /></td>
                            <td>National Number of Jobs</td>
                            <td>{data1Career && data1Career.number_of_jobs ? <NumberFormat value={data1Career.number_of_jobs} displayType="text" thousandSeparator /> : ''}</td>
                            <td>162,795,600</td>
                            <td>{data2Career && data2Career.number_of_jobs ? <NumberFormat value={data2Career.number_of_jobs} displayType="text" thousandSeparator /> : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Annual growth in the number of jobs for your selected career." /></td>
                            <td>% Projected Job Growth (2019-29)</td>
                            <td>{data1Career && data1Career.projected_job_growth ? <NumberFormat value={data1Career.projected_job_growth} displayType="text" thousandSeparator suffix="%" /> : ''}</td>
                            <td>4.0%</td>
                            <td>{data2Career && data2Career.projected_job_growth ? <NumberFormat value={data2Career.projected_job_growth} displayType="text" thousandSeparator suffix="%" /> : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The median is the middle value of all the data available on all careers." /></td>
                            <td>National Median Earnings</td>
                            <td>{data1Career && data1Career.national_median_earnings ? <NumberFormat value={data1Career.national_median_earnings} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            <td>$39,810</td>
                            <td>{data2Career && data2Career.national_median_earnings ? <NumberFormat value={data2Career.national_median_earnings} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Click on National Earnings Range to see a graphic of the earnings range for your selected career." /></td>
                            <td>National Earnings Range<span className={classes.iconRange}><img onClick={() => { setNationalEarning(true) }} src={iconRange} alt=''/></span></td>
                            <td>{data1Career && data1Career.national_marnings_eange ? data1Career.national_marnings_eange : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.national_marnings_eange ? data2Career.national_marnings_eange : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Total number of jobs for your selected career in the state." /></td>
                            <td>State Number of Jobs</td>
                            <td>{data1State && data1State.total_employed ? <NumberFormat value={data1State.total_employed} displayType="text" thousandSeparator /> : ''}</td>
                            <td>{stateAvg && stateAvg.total_employed ?<NumberFormat value={stateAvg.total_employed} displayType="text" thousandSeparator />  : ''}</td>
                            <td>{data2State && data2State.total_employed ? <NumberFormat value={data2State.total_employed} displayType="text" thousandSeparator /> : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="How does your selected state earnings compare to the national earnings?" /></td>
                            <td>State Median Earnings</td>
                            <td>{data1State && data1State.a_median ? <NumberFormat value={data1State.a_median} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            <td>{stateAvg && stateAvg.a_median ? <NumberFormat value={stateAvg.a_median} displayType="text" thousandSeparator /> : ''}</td>
                            <td>{data2State && data2State.a_median ? <NumberFormat value={data2State.a_median} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Click on State Earnings Range to see a graphic of the earnings range for your selected career." /></td>
                            <td>State Earining Range<span className={classes.iconRange}><img onClick={() => { setStateEarning(true) }} src={iconRange} alt='' /></span></td>
                            <td>
                              {data1State && data1State.a_pct25 && data1State.a_pct75 ?
                                <span>
                                  <NumberFormat value={data1State.a_pct25} displayType="text" thousandSeparator prefix="$" />
                                  &nbsp;to&nbsp;
                                  <NumberFormat value={data1State.a_pct75} displayType="text" thousandSeparator prefix="$" />
                                </span>
                                :
                                ''
                              }
                            </td>
                            <td>---</td>
                            <td>
                              {data2State && data2State.a_pct25 && data2State.a_pct75 ?
                                <span>
                                  <NumberFormat value={data2State.a_pct25} displayType="text" thousandSeparator prefix="$" />
                                  &nbsp;to&nbsp;
                                  <NumberFormat value={data2State.a_pct75} displayType="text" thousandSeparator prefix="$" />
                                </span>
                                :
                                ''
                              }
                            </td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Click on Typical Education Need to see a percentage of breakdown of your selected career by education." /></td>
                            <td>Typical Education Needed<span className={classes.iconRange}><img onClick={() => { setTENeeded(true) }} src={iconRange}  alt=''/></span></td>
                            <td>{data1Career && data1Career.typical_education_needed ? data1Career.typical_education_needed : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.typical_education_needed ? data2Career.typical_education_needed : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Average cost of the typical education needed assuming the degree is completed on time." /></td>
                            <td>Average Cost of Typical Education</td>
                            <td>{data1Career && data1Career.average_cost_of_typical_education ? <NumberFormat value={data1Career.average_cost_of_typical_education} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.average_cost_of_typical_education ? <NumberFormat value={data2Career.average_cost_of_typical_education} displayType="text" thousandSeparator prefix="$" /> : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The number of years of experience typically needed for this career." /></td>
                            <td>Typical Work Experience</td>
                            <td>{data1Career && data1Career.typical_work_experience ? data1Career.typical_work_experience : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.typical_work_experience ? data2Career.typical_work_experience : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="The amount of on-the-job training typically needed to be proficient in your selected career." /></td>
                            <td>On-the-Job Training Level</td>
                            <td>{data1Career && data1Career.on_the_job_training_level ? data1Career.on_the_job_training_level : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.on_the_job_training_level ? data2Career.on_the_job_training_level : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Some careers require more preparation than others. Zone 1 is the least amount of preparation needed and zone 5 the highest amount of prep needed. Click on Job Zone to see a description of what each level means for your selected career." /></td>
                            <td>Job Zone<span className={classes.iconRange} onClick={() => { setJobZoneDesc(true) }}><img src={iconDesc} alt='' /></span></td>
                            <td>{data1Career && data1Career.job_zone ? data1Career.job_zone : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.job_zone ? data2Career.job_zone : ''}</td>
                          </tr>
                          <tr>
                            <td><img src={infoIcon} alt="" data-for="compareSchool" data-tip="Click to see additional related careers." /></td>
                            <td>Related Careers<span className={classes.iconRange} onClick={() => { setRelatedDesc(true) }}><img src={iconDesc}  alt=''/></span></td>
                            <td>{data1Career && data1Career.related_career_text ? data1Career.related_career_text : ''}</td>
                            <td>---</td>
                            <td>{data2Career && data2Career.related_career_text ? data2Career.related_career_text : ''}</td>
                          </tr>
                        </tbody>
                        <ReactTooltip id="compareSchool" place="top" effect="solid" className={classes.tooltipInfoTop} />
                      </table>
                    </div>
                    <div className={classes.bottomText}>
                      <p>
                        Additional Resources: &nbsp;
                        <a href="https://nces.ed.gov/collegenavigator/" target="_blank" rel="noopener noreferrer">MyNextMove,</a>
                        {' '}

                        <a href="https://collegescorecard.ed.gov/" target="_blank" rel="noopener noreferrer">CareerOneStop,</a>
                        {' '}
                        <a href="https://www.niche.com/colleges/search/best-colleges/" target="_blank" rel="noopener noreferrer">Occupational Outlook Handbook</a>

                      </p>
                      <p>Source: U.S. Bureau of Labor Statistics, Occupational Information Network (O*NET), U.S. Department of Labor, Employment and Training Administration</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        className="popup-model popup-model-careers"
        open={careerDesc}
        onClose={() => { setCareerDesc(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.modalHeader}>
          <button onClick={() => { setCareerDesc(false) }} >X</button>
          <h3>Job Description</h3>
        </div>

        {data1Career && data1Career.display_description ?
          <div className={classes.modelBodyPopup}>
            <h3>{data1State.title}</h3>
            <p>{data1Career.description}</p>
          </div>
          :
          ''
        }
        {data2Career && data2Career.display_description ?
          <div className={classes.modelBodyPopup}>
            <h3>{data2State.title}</h3>
            <p>{data2Career.description}</p>
          </div>
          :
          ''
        }
        <div className={classes.modelFooter}>
          <button onClick={() => { setCareerDesc(false) }} className="close-btn">Cancel</button>
        </div>
      </Dialog>
      <Dialog
        className="popup-model popup-model-careers75"
        open={nationalEarning}
        onClose={() => { setNationalEarning(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.modalHeader}>
          <button onClick={() => { setNationalEarning(false) }} >X</button>
          <h3>National Earnings Range</h3>
        </div>
        <div className={classes.modelBodyPopup}>
          {data1Career || data2Career ?
            <NationEarning resultData={resultData} />
            :
            ''
          }
        </div>
        <div className={classes.modelFooter}>
          <button onClick={() => { setNationalEarning(false) }} className="close-btn">Cancel</button>
        </div>
      </Dialog>
      <Dialog
        className="popup-model popup-model-careers75"
        open={stateEarning}
        onClose={() => { setStateEarning(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.modalHeader}>
          <button onClick={() => { setStateEarning(false) }} >X</button>
          <h3>State Earnings Range</h3>
        </div>
        <div className={classes.modelBodyPopup}>
          {data1Career || data2Career ?
            <StateEarning resultData={resultData} />
            :
            ''
          }
        </div>
        <div className={classes.modelFooter}>
          <button onClick={() => { setStateEarning(false) }} className="close-btn">Cancel</button>
        </div>
      </Dialog>
      <Dialog
        className="popup-model popup-model-careers75"
        open={teNeeded}
        onClose={() => { setTENeeded(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <div className={classes.modalHeader}>
          <button onClick={() => { setTENeeded(false) }} >X</button>
          <h3>Career by Education Level</h3>
        </div>
        <div className={classes.modelBodyPopup}>
          {data2Career || data1Career ?
            <TENeeded resultData={resultData} />
            :
            ''
          }
        </div>
        <div className={classes.modelFooter}>
          <button onClick={() => { setTENeeded(false) }} className="close-btn">Cancel</button>
        </div>
      </Dialog>
      <Dialog
        className="popup-model popup-model-careers-table"
        open={jobZoneDesc}
        onClose={() => { setJobZoneDesc(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.modalHeader}>
          <button onClick={() => { setJobZoneDesc(false) }} >X</button>
          <h3>Job Zone Details</h3>
        </div>
        <div className={classes.modelBodyPopup}>
          <table className={classes.zoneDetailsTable}>
            <thead>
              <tr>
                <th>Job Zone</th>
                <th>Name</th>
                <th>Experience</th>
                <th>Education</th>
                <th>Job Training</th>
                <th>Examples</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Job Zone One: Little or No Preparation Needed</td>
                <td>Little or no previous work-related skill, knowledge, or experience is needed for these occupations. For example, a person can become a waiter or waitress even if he/she has never worked before.</td>
                <td>Some of these occupations may require a high school diploma or GED certificate.</td>
                <td>Employees in these occupations need anywhere from a few days to a few months of training. Usually, an experienced worker could show you how to do the job.</td>
                <td>These occupations involve following instructions and helping others. Examples include food preparation workers, dishwashers, sewing machine operators, landscaping and groundskeeping workers, logging equipment operators, and baristas.</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Job Zone Two: Some Preparation Needed</td>
                <td>Some previous work-related skill, knowledge, or experience is usually needed. For example, a teller would benefit from experience working directly with the public.</td>
                <td>These occupations usually require a high school diploma.</td>
                <td>Employees in these occupations need anywhere from a few months to one year of working with experienced employees. A recognized apprenticeship program may be associated with these occupations.</td>
                <td>These occupations often involve using your knowledge and skills to help others. Examples include orderlies, counter and rental clerks, customer service representatives, security guards, upholsterers, and tellers.</td>
              </tr>
              <tr>
                <td>3</td>
                <td>Job Zone Three: Medium Preparation Needed</td>
                <td>Previous work-related skill, knowledge, or experience is required for these occupations. For example, an electrician must have completed three or four years of apprenticeship or several years of vocational training, and often must have passed a licensing exam, in order to perform the job.</td>
                <td>Most occupations in this zone require training in vocational schools, related on-the-job experience, or an associate's degree.</td>
                <td>Employees in these occupations usually need one or two years of training involving both on-the-job experience and informal training with experienced workers. A recognized apprenticeship program may be associated with these occupations.</td>
                <td>These occupations usually involve using communication and organizational skills to coordinate, supervise, manage, or train others to accomplish goals. Examples include hydroelectric production managers, travel guides, electricians, agricultural technicians, barbers, court reporters, and medical assistants.</td>
              </tr>
              <tr>
                <td>4</td>
                <td>Job Zone Four: Considerable Preparation Needed</td>
                <td>A considerable amount of work-related skill, knowledge, or experience is needed for these occupations. For example, an accountant must complete four years of college and work for several years in accounting to be considered qualified.</td>
                <td>Most of these occupations require a four-year bachelor's degree, but some do not.</td>
                <td>Employees in these occupations usually need several years of work-related experience, on-the-job training, and/or vocational training.</td>
                <td>Many of these occupations involve coordinating, supervising, managing, or training others. Examples include real estate brokers, sales managers, database administrators, graphic designers, chemists, art directors, and cost estimators.</td>
              </tr>
              <tr>
                <td>5</td>
                <td>Job Zone Five: Extensive Preparation Needed</td>
                <td>Extensive skill, knowledge, and experience are needed for these occupations. Many require more than five years of experience. For example, surgeons must complete four years of college and an additional five to seven years of specialized medical training to be able to do their job.</td>
                <td>Most of these occupations require graduate school. For example, they may require a master's degree, and some require a Ph.D., M.D., or J.D. (law degree).</td>
                <td>Employees may need some on-the-job training, but most of these occupations assume that the person will already have the required skills, knowledge, work-related experience, and/or training.</td>
                <td>These occupations often involve coordinating, training, supervising, or managing the activities of others to accomplish goals. Very advanced communication and organizational skills are required. Examples include pharmacists, lawyers, astronomers, biologists, clergy, neurologists, and veterinarians.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.modelFooter}>
          <button onClick={() => { setJobZoneDesc(false) }} className="close-btn">Cancel</button>
        </div>
      </Dialog>
      <Dialog
        className="popup-model popup-model-careers2"
        open={relatedDesc}
        onClose={() => { setRelatedDesc(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.modalHeader}>
          <button onClick={() => { setRelatedDesc(false) }} >X</button>
          <h3>Job Zone Details</h3>
        </div>
        <div className={classes.modelBodyPopup}>
          <table className={classes.relatedTable}>
            <thead>
              <tr>
                <td></td>
                <td>
                  <p><b><u>Selected Career 1</u></b></p>
                  <p>
                    <span >
                      {data1State && data1State.title}
                    </span>
                  </p>
                </td>
                <td>
                  <p><b><u>Selected Career 2</u></b></p>
                  <p>
                    <span >
                      {data2State && data2State.title}
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                </td>
              </tr>
              <tr>
                <td colspan="3">
                </td>
              </tr>
              <tr>
                <td></td>
                {/* <td class="text-center">
                  <p>
                    <b><span ng-if="resultData['data1'] != undefined && resultData['data1']['career'] != undefined && resultData['data1']['career']['job_title'] != undefined">
                      {{ resultData['data1']['career']['job_title'] }}
                    </span>
                      <span ng-if="!(resultData['data1'] != undefined && resultData['data1']['career'] != undefined && resultData['data1']['career']['job_title'] != undefined)">
                        &nbsp;
                      </span></b>
                  </p>
                </td> */}
                {/* <td class="text-center">
                  <p>
                    <b><span ng-if="resultData['data2'] != undefined && resultData['data2']['career'] != undefined && resultData['data2']['career']['job_title'] != undefined">
                      {{ resultData['data2']['career']['job_title'] }}
                    </span>
                      <span ng-if="!(resultData['data2'] != undefined && resultData['data2']['career'] != undefined && resultData['data2']['career']['job_title'] != undefined)">
                        &nbsp;
                      </span></b>
                  </p>
                </td> */}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_1 ? data1Career.related_career_1 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_1 ? data2Career.related_career_1 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_2 ? data1Career.related_career_2 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_2 ? data2Career.related_career_2 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_3 ? data1Career.related_career_3 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_3 ? data2Career.related_career_3 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>4</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_4 ? data1Career.related_career_4 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_4 ? data2Career.related_career_4 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>5</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_5 ? data1Career.related_career_5 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_5 ? data2Career.related_career_5 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_6 ? data1Career.related_career_6 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_6 ? data2Career.related_career_6 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>7</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_7 ? data1Career.related_career_7 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_7 ? data2Career.related_career_7 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>8</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_8 ? data1Career.related_career_8 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_8 ? data2Career.related_career_8 : ''}
                  </span>
                </td>
              </tr>
              <tr>
                <td>9</td>
                <td>
                  <span>
                    {data1Career && data1Career.related_career_9 ? data1Career.related_career_9 : ''}
                  </span>
                </td>
                <td>
                  <span >
                    {data2Career && data2Career.related_career_9 ? data2Career.related_career_9 : ''}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={classes.modelFooter}>
          <button onClick={() => { setRelatedDesc(false) }} className="close-btn">Cancel</button>
        </div>
      </Dialog>
    </Typography>
  );
};

CompareSchools.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(CompareSchools);
