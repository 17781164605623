import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import classNames from 'classnames';
import { useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import styles from './styles';
import logo from '../../../assets/img/logo.png';
import downloadAllSpinner from '../../../assets/img/download_all_spinner.gif';
import logo1 from '../../../assets/img/logo-footer.png';
import * as moduleServices from '../../../calculations/modules-services';
import SummaryOutput from './summary-output';
import IncomeStatement from './income-sheet';
import TaxStatement from './tax-sheet';
import BalanceStatement from './balance-sheet';
import CashFlowStatement from './cash-flow-sheet';
import InputSheet from './input-sheet';
import Summarytable from '../summary-tables';
import { loader } from 'graphql.macro';
import Pageloader from '../../../components/ui/pageloader';
import summaryOutputGraph from '../../../graph-services/summary-output';
import incomeStatementGraph from '../../../graph-services/income-statement';
import taxStatementGraph from '../../../graph-services/tax-statement';
import balanceSheetGraph from '../../../graph-services/balance-sheet';
import cashFlowStatementGraph from '../../../graph-services/cash-flow-statement';


const DOWNLOAD_PDF = loader( '../../../graphql/schema/fc/download-pdf.graphql' );


const rangeList = [];
for ( let i = 0; i <= 14; i += 1 ) {
  rangeList.push( i );
}

const SimulatorPanel = ( props ) => {
  const {
    classes, popupStatus, setOpenDocsPopup,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [openIncomeStatement, setOpenIncomeStatement] = React.useState( '' );
  const [dispalyObj, setDispalyObj] = React.useState( {} );


  const [donwloadPdf] = useMutation( DOWNLOAD_PDF, {
    onCompleted( response ) {
        setLoading(false);
      if ( response.downloadPDF.status ) {
        window.open( response.downloadPDF.url );
        if(openIncomeStatement === 'total-download') {
          setOpenDocsPopup( '' );
        }
      }
    },
    onError( ) {
      setLoading(false)
      if(openIncomeStatement === 'total-download') {
        setOpenDocsPopup( '' );      
      }
    },
  } );

  useEffect(()=>{
    if(openIncomeStatement === 'total-download') {
      donwloadPdf( {
        variables: {
          data: dispalyObj,
        },
      } );
    }
    // eslint-disable-next-line
  },[openIncomeStatement])

  const download = (type) => {
    let data = {};
    if(type === 'summary-output') {
      data = {type: 'summary-output', graph_data: JSON.stringify(dispalyObj)};
    } else if(type === 'summary-sheet') {
      data = {type: 'summary-sheet', summary_financial: JSON.stringify(dispalyObj)};
    } else {
      data = {type: type, [type]: JSON.stringify(dispalyObj)};
    }
    setLoading(true);
    donwloadPdf( {
      variables: {
        data: data,
      },
    } );
  }

  const getPopupDetails = (popupValue, type='') => {
    if ( popupValue === 'income-statement' ) {
      const incomeStatementCompleteData = moduleServices.incomeStatementData;
      if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year > 0 ) {
        const dispalyObjData = {
          year: moduleServices.module1Data.start_year,
          incomeData: incomeStatementCompleteData,
          incomeStatementGrossIncomeHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year]['Gross Income'],
          incomeStatementAdjustmentsHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year].Adjustments,
          incomeStatementItemizedDeductionsHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year]['Itemized Deductions'],
          incomeStatementREIPropertyDeductionsHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year]['REI Property Deductions'],
          incomeStatementTaxesHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year].taxes,
          incomeStatementTaxCreditsHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year]['Tax Credits'],
          incomeStatementLivingExpensesHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year].livingExpenses,
          incomeStatementDepreciationExpensesHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year].DepreciationExpenses,
          incomeStatementInvestmentPropertySaleHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year]['Investment Property Sale'],
          incomeStatementInterestExpensesHeadings: incomeStatementCompleteData[moduleServices.module1Data.start_year].InterestExpenses,
          graph_data: incomeStatementGraph()
        };
        if(type === 'download') {
           return dispalyObjData; 
        }
        setDispalyObj( dispalyObjData );
        setOpenIncomeStatement( 'income-statement' );
      }
    } else if ( popupValue === 'tax-statement' ) {
      const taxStatementCompleteData = moduleServices.taxStatementCompleteData();
      if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year > 0 ) {
        const dispalyObjData = {
          year: moduleServices.module1Data.start_year,
          taxData: taxStatementCompleteData,
          taxStatementGrossIncomeHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year]['Gross Income'],
          taxStatementAdjustmentsHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year].Adjustments,
          taxStatementItemizedDeductionsHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year]['Itemized Deductions'],
          taxStatementREIPropertyDeductionsHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year]['REI Property Deductions'],
          taxStatementREIPropertyHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year]['REI Property'],
          taxStatementREIPropertyHeadings1: taxStatementCompleteData[moduleServices.module1Data.start_year]['REI Property1'],
          taxStatementTaxesHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year].taxes,
          taxStatementTaxCreditsHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year]['Tax Credits'],
          taxStatementLivingExpensesHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year].livingExpenses,
          taxStatementDepreciationExpensesHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year].DepreciationExpenses,
          taxStatementInvestmentPropertySaleHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year]['Investment Property Sale'],
          taxStatementInterestExpensesHeadings: taxStatementCompleteData[moduleServices.module1Data.start_year].InterestExpenses,
          graph_data: taxStatementGraph()
        };
        if(type === 'download') {
           return dispalyObjData; 
        }
        setDispalyObj( dispalyObjData );
        setOpenIncomeStatement( 'tax-statement' );
      }
    } else if ( popupValue === 'balance-statement' ) {
      const balanceSheetCompleteData = moduleServices.balanceSheetCompleteData();
      if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year > 0 ) {
        const dispalyObjData = {
          year: moduleServices.module1Data.start_year,
          balanceData: balanceSheetCompleteData,
          balanceSheetAssetsHeadings: balanceSheetCompleteData[moduleServices.module1Data.start_year].Assets,
          balanceSheetInvestmentsHeadings: balanceSheetCompleteData[moduleServices.module1Data.start_year].Investments,
          balanceSheetLiabilitiesHeadings: balanceSheetCompleteData[moduleServices.module1Data.start_year].Liabilities,
          graph_data: balanceSheetGraph()
        };
        if(type === 'download') {
           return dispalyObjData; 
        }
        setDispalyObj( dispalyObjData );
        setOpenIncomeStatement( 'balance-statement' );
      }
    } else if ( popupValue === 'cashflow-statement' ) {
      const cashFlowCompleteData = moduleServices.cashFlowCompleteData();
      if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year > 0 ) {
        const dispalyObjData = {
          year: moduleServices.module1Data.start_year,
          cashflowData: cashFlowCompleteData,
          cashFlowCashOperatingHeadings: cashFlowCompleteData[moduleServices.module1Data.start_year]['Cash from Operating Activities'],
          cashFlowCashInvestingHeadings: cashFlowCompleteData[moduleServices.module1Data.start_year]['Cash from Investing Activities'],
          cashFlowCashFinancingHeadings: cashFlowCompleteData[moduleServices.module1Data.start_year]['Cash from Financing Activities'],
          cashFlowCashSTIHeadings: cashFlowCompleteData[moduleServices.module1Data.start_year]['Excess Cash Put into STI'],
          cashFlowCashLIIHeadings: cashFlowCompleteData[moduleServices.module1Data.start_year]['Excess STI Put into LTI'],
          graph_data: cashFlowStatementGraph()
        };
        if(type === 'download') {
           return dispalyObjData; 
        }
        setDispalyObj( dispalyObjData );
        setOpenIncomeStatement( 'cashflow-statement' );
      }
    } else if ( popupValue === 'inputsheet-statement' ) {
      const inputSheetCompleteData = moduleServices.inputSheetCompleteData();
      if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year > 0 ) {
        const dispalyObjData = {
          year: moduleServices.module1Data.start_year,
          yearsList: Object.keys( inputSheetCompleteData ),
          inputData: inputSheetCompleteData,
          'Balance Sheet': inputSheetCompleteData[moduleServices.module1Data.start_year]['Balance Sheet'],
          'Income Statement': inputSheetCompleteData[moduleServices.module1Data.start_year]['Income Statement'],
          'Cash Flow': inputSheetCompleteData[moduleServices.module1Data.start_year]['Cash Flow'],
          'Tax Statement': inputSheetCompleteData[moduleServices.module1Data.start_year]['Tax Statement'],
          'Tax - Interest Expenses': inputSheetCompleteData[moduleServices.module1Data.start_year]['Tax - Interest Expenses'],
        };
        if(type === 'download') {
           return dispalyObjData; 
        }
        setDispalyObj( dispalyObjData );
        setOpenIncomeStatement( 'input-sheet' );
      }
    } else if(popupValue === 'summary-table') {
        setOpenIncomeStatement( 'summary-table' );
    } else if(popupValue === 'summary-output') {
      if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year > 0 ) {
        const graphData = summaryOutputGraph();
        if(type === 'download') {
           return graphData; 
        }
        setDispalyObj( graphData );
        setOpenIncomeStatement( 'summary-output' );
      }
    } else if(popupValue === 'total-download'){
      if ( moduleServices.module1Data.start_year !== undefined && moduleServices.module1Data.start_year > 0 ) {
        const dispalyObjData = {
          type: 'total',
          summary_output: JSON.stringify(getPopupDetails('summary-output','download')),
          summary_financial: JSON.stringify(getPopupDetails('inputsheet-statement','download')),
          income_statement: JSON.stringify(getPopupDetails('income-statement','download')),
          tax_statement: JSON.stringify(getPopupDetails('tax-statement','download')),
          balance_statement:JSON.stringify(getPopupDetails('balance-statement','download')),
          cash_statement: JSON.stringify(getPopupDetails('cashflow-statement','download')),
        }
        setDispalyObj( dispalyObjData );
        setOpenIncomeStatement( 'total-download' );
      }
    }
  } 

  useEffect( () => {
    getPopupDetails(popupStatus);
    // eslint-disable-next-line
  }, [popupStatus] );

  const closeSummarDoc = () => {
    setOpenDocsPopup( '' );
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <Dialog
        open={ openIncomeStatement === 'summary-output' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.summayModalBlock }
      >
        <DialogTitle className={ classes.summayModalHeader }>
          <div >
            <div>
              <Grid container>
                <Grid item sm={ 4 }>
                  <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
                </Grid>
                <Grid item sm={ 4 }>
                  <div><Button className={ classes.downloadPdf } onClick={()=>download('summary-output')}>Download PDF</Button></div>
                </Grid> 
                <Grid item sm={ 4 } />
              </Grid>
              <Button className={ classes.incomePopupClose } onClick={ closeSummarDoc }>×</Button>
            </div>
          </div>
          <p>Summary Output - Financial Configurator<sup>TM</sup></p>
        </DialogTitle>
        <DialogContent>
          <SummaryOutput dispalyObj={dispalyObj}/>
        </DialogContent>
        <DialogActions className={ classes.modalFooterDiv }>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closeSummarDoc }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={ openIncomeStatement === 'income-statement' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.fullWidthModal }
      >
        <DialogTitle className={ classes.modalHeader }>
          <div>
            <Grid container>
              <Grid item sm={ 4 }>
                <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
              </Grid>
              <Grid item sm={ 4 }>
                <div><Button className={ classes.downloadPdf } onClick={()=>download('income_statement')}>Download PDF</Button></div>
              </Grid>
              <Grid item sm={ 4 } />
            </Grid>
            <Button className={ classes.incomePopupClose } onClick={ closeSummarDoc }>×</Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <IncomeStatement dispalyObj={ dispalyObj } rangeList={ rangeList } />
        </DialogContent>
        <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closeSummarDoc }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ openIncomeStatement === 'tax-statement' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.fullWidthModal }
      >
        <DialogTitle>
          <div className={ classes.modalHeader }>
            <Grid container>
              <Grid item sm={ 4 }>
                <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
              </Grid>
              <Grid item sm={ 4 }>
                <div><Button className={ classes.downloadPdf } onClick={()=>download('tax_statement')}>Download PDF</Button></div>
              </Grid>
              <Grid item sm={ 4 } />
            </Grid>
            <Button className={ classes.incomePopupClose } onClick={ closeSummarDoc }>×</Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <TaxStatement dispalyObj={ dispalyObj } rangeList={ rangeList } />
        </DialogContent>
        <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closeSummarDoc }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ openIncomeStatement === 'balance-statement' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.fullWidthModal }
      >
        <DialogTitle className={ classes.modalHeader }>
          <div >
            <Grid container>
              <Grid item sm={ 4 }>
                <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
              </Grid>
              <Grid item sm={ 4 }>
                <div><Button className={ classes.downloadPdf } onClick={()=>download('balance_statement')}>Download PDF</Button></div>
              </Grid>
              <Grid item sm={ 4 } />
            </Grid>
            <Button className={ classes.incomePopupClose } onClick={ closeSummarDoc }>×</Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <BalanceStatement dispalyObj={ dispalyObj } rangeList={ rangeList } />
        </DialogContent>
        <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closeSummarDoc }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={ openIncomeStatement === 'cashflow-statement' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.fullWidthModal }
      >
        <DialogTitle className={ classes.modalHeader }>
          <div >
            <Grid container>
              <Grid item sm={ 4 }>
                <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
              </Grid>
              <Grid item sm={ 4 }>
                <div><Button className={ classes.downloadPdf } onClick={()=>download('cash_statement')}>Download PDF</Button></div>
              </Grid>
              <Grid item sm={ 4 } />
            </Grid>
            <Button className={ classes.incomePopupClose } onClick={ closeSummarDoc }>×</Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <CashFlowStatement dispalyObj={ dispalyObj } rangeList={ rangeList } />
        </DialogContent>
        <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closeSummarDoc }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ openIncomeStatement === 'input-sheet' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        className={ classes.fullWidthModal }
      >
        <DialogTitle className={ classes.modalHeader }>
          <div >
            <Grid container>
              <Grid item sm={ 4 }>
                <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
              </Grid>
              <Grid item sm={ 4 }>
                <div><Button className={ classes.downloadPdf } onClick={()=>download('summary-sheet')}>Download PDF</Button></div>
              </Grid>
              <Grid item sm={ 4 } />
            </Grid>
            <Button className={ classes.incomePopupClose } onClick={ closeSummarDoc }>×</Button>
          </div>
        </DialogTitle>
        <DialogContent>
          <InputSheet dispalyObj={ dispalyObj } rangeList={ rangeList } />
        </DialogContent>
        <DialogActions>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closeSummarDoc }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ openIncomeStatement === 'summary-table' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.summaryPopup}
      >
        <DialogTitle className={classes.modalHeader1}>
          <h4>Summary Tables</h4>
          <img src={logo1} alt="logo" />
          <button type="button" onClick={closeSummarDoc} className={classes.closePopup}>×</button>
        </DialogTitle>
        <DialogContent className={classes.modalBody1}>
          <Summarytable />
        </DialogContent>
        <DialogActions className={classes.modalFooterDiv}>
          <div className={ classes.modalFooter }>
            <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closeSummarDoc }>Close</Button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ openIncomeStatement === 'total-download' }
        onClose={ closeSummarDoc }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      >
        <DialogContent className={classes.downloadAllPdf}>
          <div>
            <span><img alt="loading icon" src={downloadAllSpinner} width=""/></span>
            <p>
              The Download PDF is wrapping all of your summary documents into a single PDF. This will only take 10-15 seconds. Thanks for your patience.
            </p>
          </div>
        </DialogContent>
      </Dialog>

    </Typography>
  );
};

SimulatorPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  popupStatus: PropTypes.string.isRequired,
  setOpenDocsPopup: PropTypes.func.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( SimulatorPanel );
