import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import GreyBox from './grey-common-box';
import * as moduleServices from '../../../calculations/modules-services';
import styles from '../../../screens/pwi-career-advancement/styles';
import financialConfiguratorFamilyPDF from '../../../assets/img/news/news_pdfs/Inkwiry_FC_and_Taxes_Sep_2019.pdf';

const IncomeAfterTaxes = ( props ) => {
  const { classes, handleCareerObject } = props;

const totalVal = (handleCareerObject.totalPreTaxExpected + handleCareerObject.bonus_or_tips_or_commission + handleCareerObject.second_yearly_income) - (moduleServices.module5Data.totalTaxesPaidCareerAdv);
  return (
    <div className={ classes.contentBlock }>
      <p>
        Based on your inputs, we&apos;ve calculated your total annual and monthly income before taxes and after taxes. Click on the annual taxes number to see exactly how Inkwiry estimates your taxes. Review the figures below before continuing.
      </p>
      <p>
        Want to learn how Inkwiry estimates your taxes?
        {' '}
        {' '}
        <a rel="noopener noreferrer" href={financialConfiguratorFamilyPDF} download target="_blank">Click here</a>
        {' '}
        to download Inkwiry&apos;s Financial Configurator and Taxes whitepaper.
      </p>
      <div className={ classes.garyBoxSeventy }><GreyBox handleCareerObject={ handleCareerObject } /></div>

      <div className={ classes.heigherEducationSearch }>
        <div className={ `${classes.higherEducationStepFour} ${classes.higherEducationStepSix}  ${classes.incomeTaxTableNew} ${classes.incomeAfterTax}` }>
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <th>Income Before Taxes</th>
              <th>Annual</th>
              <th>Monthly</th>
            </thead>
            <tbody>
              <tr>
                <td>Base Income</td>
                <td>
                  {handleCareerObject.totalPreTaxExpected !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ handleCareerObject.totalPreTaxExpected } prefix={ handleCareerObject.totalPreTaxExpected >= 0 ? '$' : '($' } suffix={ handleCareerObject.totalPreTaxExpected < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {handleCareerObject.totalPreTaxExpected !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ handleCareerObject.totalPreTaxExpected / 12 } prefix={ handleCareerObject.totalPreTaxExpected >= 0 ? '$' : '($' } suffix={ handleCareerObject.totalPreTaxExpected < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Bonus / Tips / Commission</td>
                <td>
                  {handleCareerObject.bonus_or_tips_or_commission !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ handleCareerObject.bonus_or_tips_or_commission } prefix={ handleCareerObject.bonus_or_tips_or_commission >= 0 ? '$' : '($' } suffix={ handleCareerObject.bonus_or_tips_or_commission < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {handleCareerObject.bonus_or_tips_or_commission !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ handleCareerObject.bonus_or_tips_or_commission / 12 } prefix={ handleCareerObject.bonus_or_tips_or_commission >= 0 ? '$' : '($' } suffix={ handleCareerObject.bonus_or_tips_or_commission < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr>
                <td>Supplementary Income</td>
                <td>
                  {handleCareerObject.second_yearly_income !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ handleCareerObject.second_yearly_income } prefix={ handleCareerObject.second_yearly_income >= 0 ? '$' : '($' } suffix={ handleCareerObject.second_yearly_income < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {handleCareerObject.second_yearly_income !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ handleCareerObject.second_yearly_income / 12 } prefix={ handleCareerObject.second_yearly_income >= 0 ? '$' : '($' } suffix={ handleCareerObject.second_yearly_income < 0 && ')' } />
                  ) : ( '$0' )}

                </td>
              </tr>
              <tr className={ classes.totalCost }>
                <td><b>Annual Income Before Taxes</b></td>
                <td>
                  <b>
                  {handleCareerObject.totalPreTaxExpected !== undefined && handleCareerObject.second_yearly_income !== undefined && handleCareerObject.bonus_or_tips_or_commission !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ handleCareerObject.totalPreTaxExpected + handleCareerObject.bonus_or_tips_or_commission + handleCareerObject.second_yearly_income } prefix="$" />
                  ) : ( '$0' )}
                  </b>
                  {' '}
                </td>
                <td>
                  <b>
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ ( handleCareerObject.totalPreTaxExpected + handleCareerObject.bonus_or_tips_or_commission + handleCareerObject.second_yearly_income ) / 12 } prefix="$" />
                  </b>
                  {' '}
                </td>
              </tr>
              <tr className={ classes.noBorder }>
                <td>Taxes</td>
                <td>
                  {moduleServices.module5Data.totalTaxesPaidCareerAdv !== undefined && moduleServices.module5Data.totalTaxesPaidCareerAdv !== '' && moduleServices.module5Data.totalTaxesPaidCareerAdv !== null? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ moduleServices.module5Data.totalTaxesPaidCareerAdv < 0 ? classes.textDanger : '' } displayType="text" allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.totalTaxesPaidCareerAdv * 12 } prefix={ moduleServices.module5Data.totalTaxesPaidCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.totalTaxesPaidCareerAdv < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
                <td>
                  {moduleServices.module5Data.totalTaxesPaidCareerAdv !== undefined ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" className={ moduleServices.module5Data.totalTaxesPaidCareerAdv < 0 ? classes.textDanger : '' } allowNegative={ false } thousandSeparator value={ moduleServices.module5Data.totalTaxesPaidCareerAdv } prefix={ moduleServices.module5Data.totalTaxesPaidCareerAdv >= 0 ? '$' : '($' } suffix={ moduleServices.module5Data.totalTaxesPaidCareerAdv < 0 && ')' } />
                  ) : ( '$0' )}
                </td>
              </tr>
              <tr className={ classes.totalCost }>
                <td><b>Income After Taxes</b></td>
                <td>
                  <b>
                    {' '}
                    {totalVal !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ totalVal * 12 } prefix={ totalVal >= 0 ? '$' : '($' } suffix={ totalVal < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                  {' '}
                </td>
                <td>
                  <b>
                    {' '}
                    {totalVal !== undefined ? (
                      <NumberFormat decimalScale={ 0 } fixedDecimalScale displayType="text" allowNegative={ false } thousandSeparator value={ totalVal } prefix={ totalVal >= 0 ? '$' : '($' } suffix={ totalVal < 0 && ')' } />
                    ) : ( '$0' )}
                  </b>
                  {' '}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>

  );
};

IncomeAfterTaxes.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,

};

export default withStyles( styles )( IncomeAfterTaxes );
