import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import scrollToComponent from 'react-scroll-to-component';
import { loader } from 'graphql.macro';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import Pageloader from '../../../../../components/ui/pageloader';
import styles from './styles';
import eachQuestionXp, { xpPoints, getTotalTime, getUserXPRank } from '../../pf101-service';
import headerLogo from '../../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import book from '../../../../../assets/img/pf101/icon-book-blue.png';
import Pf101Popups from '../../popups';

/* eslint react/prop-types: 0 */

const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );
const startTime = new Date();
const PersonalFinance101 = ( props ) => {
  const { classes, history } = props;
  const defaultQuestionList = [
    {
      id: 1,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: 'Net Worth',
      status: '',
      answer: '',
    },
    {
      id: 2,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: '401(k) Retirement Account',
      status: '',
      answer: '',
    },
    {
      id: 3,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: 'A credit card',
      status: '',
      answer: '',
    },
    {
      id: 4,
      question_attempts: 1,
      xp_earned: eachQuestionXp,
      correct_answer: '9500',
      status: '',
      answer: '',
    },
  ];
  let bodySection = useRef( null );

  const [loading, setLoading] = React.useState( true );
  const [activeSlide, setActiveSlide] = React.useState( 1 );
  const [questionList, setQuestionList] = React.useState( defaultQuestionList );
  const [progress, setProgress] = React.useState( 1 );
  const [questionProgressData, setQuestionProgressData] = React.useState( {
    questions_progress_val: eachQuestionXp, questions_progress: 100, total_progress: 0, total_progress_val: 0,
  } );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  useQuery( GET_EXERCISE, {
    variables: {
      level: 1,
      exercise: 1,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        const questions = [...questionList];
        response.getPf101ExerciseDetails.questions.forEach( ( data ) => {
          questions[data.question_id - 1].question_attempts = data.question_attempts > 0 ? data.question_attempts : 1;
          questions[data.question_id - 1].xp_earned = xpPoints( questions[data.question_id - 1].question_attempts );
          questions[data.question_id - 1].status = 'C';
          questions[data.question_id - 1].answer = questions[data.question_id - 1].correct_answer;
        } );
        setQuestionList( questions );
        setExerciseStatus( 'review' );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const backToDashBoard = () => {
    confirmAlert( {
      customUI: ( { onClose } ) => (
        <div className={ classes.redoPopup }>
          <div className={ classes.redoPopupNew }>
            <h3>Back to Dashboard</h3>
            <p>If you go back to the Dashboard, your progress will be lost. Are you sure you want to exit the activity and go back to the Dashboard?</p>
          </div>
          <div className={ classes.buttonOkRedo }>
            <button
              type="button"
              onClick={ () => { onClose(); history.push( '/pf101/dashboard' ); } }
            >
              CONTINUE
            </button>
            <button type="button" onClick={ onClose }>CANCEL</button>
          </div>
        </div>
      ),
    } );
  };

  useEffect( () => {
    const questionsData = questionList;

    scrollToComponent( bodySection, { offset: -56, align: 'top', duration: 500 } );

    setProgress( parseFloat( ( 100 / questionsData.length ) * activeSlide ) );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questionsData[activeSlide - 1].xp_earned;
    progressData.questions_progress = ( questionsData[activeSlide - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questionsData, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( questionsData.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
    // eslint-disable-next-line
  }, [activeSlide, exerciseStatus] );

  const answerQuestion = ( e, question ) => {
    const questions = [...questionList];
    questions[question - 1].answer = e.target.value;
    questions[question - 1].status = '';
    setQuestionList( questions );
  };

  const checkAnswer = ( question ) => {
    const questions = [...questionList];
    if ( questions[question - 1].answer === questions[question - 1].correct_answer ) {
      questions[question - 1].status = 'C';
    } else {
      questions[question - 1].status = 'W';
      questions[question - 1].question_attempts = questions[question - 1].question_attempts + 1;
    }
    questions[question - 1].xp_earned = xpPoints( questions[question - 1].question_attempts );
    setQuestionList( questions );

    // Updating bottom progress
    const progressData = {};
    progressData.questions_progress_val = questions[question - 1].xp_earned;
    progressData.questions_progress = ( questions[question - 1].xp_earned / eachQuestionXp ) * 100;
    const correctAnswer = _.filter( questions, { status: 'C' } );
    if ( correctAnswer.length > 0 ) {
      progressData.total_progress_val = _.sumBy( correctAnswer, 'xp_earned' );
      progressData.total_progress = ( progressData.total_progress_val / ( defaultQuestionList.length * eachQuestionXp ) ) * 100;
    } else {
      progressData.total_progress = 0;
      progressData.total_progress_val = 0;
    }
    setQuestionProgressData( progressData );
  };

  const finishActivity = () => {
    if ( exerciseStatus === 'review' ) {
      history.push( '/pf101/dashboard' );
      return false;
    }
    setLoading( true );
    const popupValue = {};

    const totalTime = getTotalTime( startTime );
    let totalPoints = _.sumBy( questionList, 'xp_earned' );
    const totalAttempts = _.sumBy( questionList, 'question_attempts' );
    const questionData = questionList.map( ( questions ) => _.pick( questions, ['id', 'question_attempts', 'xp_earned'] ) );

    popupValue.exercisePoints = totalPoints;

    // Adding 50 points for all questions answered single attempt
    if ( totalAttempts === defaultQuestionList.length ) {
      totalPoints += 50;
      popupValue.precision = true;
      popupValue.precisionPoints = 50;
    } else {
      popupValue.precision = false;
      popupValue.precisionPoints = 0;
    }
    // Adding 500 ponits for completing level
    totalPoints += 500;

    popupValue.level = true;
    popupValue.levelPoints = 500;
    popupValue.levelNumber = 1;

    const presentRank = getUserXPRank( pf101Points );
    const nextRank = getUserXPRank( pf101Points + totalPoints );
    if ( presentRank !== nextRank ) {
      popupValue.rankChangeStatus = true;
      popupValue.rank = nextRank;
    } else {
      popupValue.rankChangeStatus = false;
      popupValue.rank = '';
    }

    setPopupDetails( popupValue );

    saveExercise( {
      variables: {
        data: {
          level: 1,
          exercise: 1,
          exercise_time: totalTime,
          points: totalPoints,
          total_attempts: totalAttempts,
          questionData,
        },
      },
    } );
    return true;
  };
  return (
    <Typography variant="body1" component="div" ref={ ( body ) => { bodySection = body; } }>
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.pfHeader }>
        <div className={ classes.container }>
          <div className={ classes.pfHeaderInner }>
            <h3>
              <span className={ classes.breadcrumbTitle }>Level 1:</span>
              {' '}
              What is Personal Finance?
            </h3>
            <Button onClick={ () => { backToDashBoard(); } }>
              <img src={ headerLogo } alt="" />
            </Button>
          </div>
        </div>
      </div>
      <div className={ classes.pfContent }>
        <div className={ classes.stepOne }>
          <div className={ classes.pfContentHeader }>
            <div>
              <h3>
                <span>Activity:</span>
                {' '}
                Personal Finance 101 (
                {activeSlide}
                {' '}
                of 4)
              </h3>
            </div>
            <Link to="/pf101/level1/personal-finance-101" target="_blank">
              <figure>
                <img
                  src={ book }
                  alt="infoicon"
                  data-for="infoLeftIcon"
                  data-tip="Click here to open the activity's reading in a new tab."
                  data-offset="{'left':10}"
                />
                <ReactTooltip id="infoLeftIcon" place="left" type="info" effect="solid" className={ classes.tooltipInfo } />
              </figure>
            </Link>

          </div>
          <div className={ classes.ProgressBar }>
            <div className={ classes.ProgressBarInner } style={ { width: `${progress}%` } } />
          </div>
          { activeSlide === 1 && (
            <div className={ classes.contentBlock }>
              <div className={ classes.grayBox }>
                <h3>
                  To complete this activity, answer the following questions. Each question is worth
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP.</span>
                  {' '}
                  If you get the question right on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+20 XP</span>
                  . On the second try,
                  {' '}
                  <span className={ classes.bonusXp }>+15 XP</span>
                  . Third try,
                  {' '}
                  <span className={ classes.bonusXp }>+12 XP</span>
                  . And fourth try,
                  {' '}
                  <span className={ classes.bonusXp }>+10 XP</span>
                  .
                </h3>
                <h3>
                  <span className={ classes.bonusXp }>BONUS XP:</span>
                  {' '}
                  If you answer all questions correctly on the first try, you will earn
                  {' '}
                  <span className={ classes.bonusXp }>+50 XP</span>
                  {' '}
                  at the end of the activity. Good luck!
                </h3>
                <h3>A green check mark will appear once you answer correctly and the Next button will turn blue once you&apos;ve answered all correctly.</h3>
              </div>
              <h3>Complete the following equation:</h3>
              <h3>Assets - Liabilities = ?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'Net Worth' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Net Worth' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="NetWorth">
                        Net Worth
                        <input type="radio" name="equation" value="Net Worth" id="NetWorth" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 1 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, ( questionList[activeSlide - 1].answer === 'Net Income' && questionList[activeSlide - 1].status === '' && classes.ageChecked ), ( questionList[activeSlide - 1].answer === 'Net Income' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="NetIncome">
                        Net Income
                        <input type="radio" name="equation" value="Net Income" id="NetIncome" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 1 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'Adjusted Gross Income' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Adjusted Gross Income' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="income">
                        Adjusted Gross Income
                        <input type="radio" name="equation" value="Adjusted Gross Income" disabled={ questionList[activeSlide - 1].status === 'C' } id="income" onChange={ ( e ) => answerQuestion( e, 1 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'Net Change in Cash' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Net Change in Cash' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="Cash">
                        Net Change in Cash
                        <input type="radio" name="equation" value="Net Change in Cash" disabled={ questionList[activeSlide - 1].status === 'C' } id="Cash" onChange={ ( e ) => answerQuestion( e, 1 ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 2 && (
            <div className={ classes.contentBlock }>
              <h3>Which of the follow is an asset?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'Student loans' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Student loans' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="studentloan">
                        Student loans
                        <input type="radio" name="asset" value="Student loans" id="studentloan" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 2 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'Car loans' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Car loans' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="carloan">
                        Car loans
                        <input type="radio" name="asset" value="Car loans" id="carloan" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 2 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'Mortgages' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'Mortgages' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="mortgage">
                        Mortgages
                        <input type="radio" name="asset" value="Mortgages" id="mortgage" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 2 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '401(k) Retirement Account' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '401(k) Retirement Account' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) } htmlFor="401k">
                        401(k) Retirement Account
                        <input type="radio" name="asset" value="401(k) Retirement Account" id="401k" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 2 ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 3 && (
            <div className={ classes.contentBlock }>
              <h3>Which of the follow is a liability?</h3>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label htmlFor="car" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'A car' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'A car' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        A car
                        <input type="radio" name="liablity" value="A car" id="car" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 3 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label htmlFor="house" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'A house' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'A house' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        A house
                        <input type="radio" name="liablity" value="A house" id="house" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 3 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label htmlFor="investment" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'An investment' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'An investment' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        An investment
                        <input type="radio" name="liablity" value="An investment" id="house" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 3 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label htmlFor="creditcard" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === 'A credit card' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === 'A credit card' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        A credit card
                        <input type="radio" name="liablity" value="A credit card" id="creditcard" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 3 ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          { activeSlide === 4 && (
            <div className={ classes.contentBlock }>
              <h3>Use the following details to calculate Mike&apos;s net worth:</h3>
              <div>
                <table className={ classes.mikeNetTable }>
                  <thead>
                    <tr>
                      <th>Mike&apos;s Assets and Liabilities</th>
                      <th>Year 2019</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Car Loan</td>
                      <td>$5,000</td>
                    </tr>
                    <tr>
                      <td>Checking Account</td>
                      <td>$8,500</td>
                    </tr>
                    <tr>
                      <td>Savings Account</td>
                      <td>$12,000</td>
                    </tr>
                    <tr>
                      <td>Student Loans</td>
                      <td>$10,000</td>
                    </tr>
                    <tr>
                      <td>Investments</td>
                      <td>$5,000</td>
                    </tr>
                    <tr>
                      <td>Credit Card Debt</td>
                      <td>$1,000</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={ classes.assetsOptions }>
                <ul className={ classes.exerciseOptions }>
                  <li>
                    <p>
                      <label htmlFor="9500" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '9500' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '9500' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        $9,500
                        <input type="radio" name="net_value" value="9500" id="9500" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 4 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label htmlFor="19500" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '19500' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '19500' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        $19,500
                        <input type="radio" name="net_value" value="19500" id="19500" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 4 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label htmlFor="4500" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '4500' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '4500' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        $4,500
                        <input type="radio" name="net_value" value="4500" id="4500" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 4 ) } />
                      </label>
                    </p>
                  </li>
                  <li>
                    <p>
                      <label htmlFor="25500" className={ classNames( classes.spanRadio, questionList[activeSlide - 1].answer === '25500' && questionList[activeSlide - 1].status === '' && classes.ageChecked, ( questionList[activeSlide - 1].answer === '25500' && questionList[activeSlide - 1].status !== '' && ( questionList[activeSlide - 1].status === 'C' ? classes.radioGreen : classes.radioRed ) ) ) }>
                        $25,500
                        <input type="radio" name="net_value" value="25500" id="25500" disabled={ questionList[activeSlide - 1].status === 'C' } onChange={ ( e ) => answerQuestion( e, 4 ) } />
                      </label>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

        </div>
        <div className={ classes.answerStatus }>
          {questionList[activeSlide - 1].status === 'C' && (
          <h2 className={ classes.correctAnswer }>
            Correct! You earned +
            {questionList[activeSlide - 1].xp_earned}
            {' '}
            XP.
          </h2>
          )}
          {questionList[activeSlide - 1].status === 'W' && <h2 className={ classes.wrongAnswer }>Incorrect. Try again!</h2>}
        </div>
        <div className={ classes.btnGroup }>
          {activeSlide !== 1 && <Button className={ classNames( classes.manageButton, classes.backButton ) } onClick={ () => { setActiveSlide( activeSlide - 1 ); } }>Back</Button>}
          <div className={ classes.progressXp }>
            <div className={ classes.questionXp }>
              <p>
                <span>Question XP:</span>
                {' '}
                +
                {questionList[activeSlide - 1].xp_earned}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.questions_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp}
                {' '}
                XP
              </span>
            </div>
            <div className={ classNames( classes.questionXp, classes.totalQuestion ) }>
              <p>
                <span>Total XP:</span>
                {' '}
                +
                {questionProgressData.total_progress_val}
                {' '}
                XP
              </p>
              <div className={ classes.progressBlock }>
                <div className={ classes.progressBar } style={ { width: `${questionProgressData.total_progress}%` } } role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" />
              </div>
              <span>
                {eachQuestionXp * defaultQuestionList.length}
                {' '}
                XP
              </span>
            </div>
          </div>
          <Button className={ `${classes.manageButton} ${classes.checkAnswer}` } disabled={ questionList[activeSlide - 1].answer === '' } onClick={ () => checkAnswer( activeSlide ) }>Check Answer</Button>
          {defaultQuestionList.length === activeSlide ? (
            <Button className={ classes.manageButton } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => finishActivity() }>FINISH</Button>
          ) : (
            <Button className={ classes.manageButton } disabled={ questionList[activeSlide - 1].status !== 'C' } onClick={ () => { setActiveSlide( activeSlide + 1 ); } }>Next</Button>
          )}
        </div>
      </div>
      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}
    </Typography>
  );
};

PersonalFinance101.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( PersonalFinance101 );
