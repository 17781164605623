import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withRouter, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import _ from 'lodash';
import Pageloader from '../../../../../components/ui/pageloader';
import styles from './styles';
import financialGraphic from '../../../../../assets/img/blu-print/financial-independence-graphic.png';
import financialInde from '../../../../../assets/img/blu-print/financial-independence.png';
import existingAssets from '../../../../../assets/img/blu-print/existing-collage.png';
import existingAssetsNew from '../../../../../assets/img/blu-print/existing-assets-liabilities-graphic.png';
import existingIcon from '../../../../../assets/img/blu-print/existing.png';
import heduBlocks from '../../../../../assets/img/blu-print/hedu_blocks.png';
import educationGraphic from '../../../../../assets/img/blu-print/education-graphic.png';
import investmentGraphic from '../../../../../assets/img/blu-print/investment-graphic.png';
import familyGraphic from '../../../../../assets/img/blu-print/family-graphic.png';
import vacayGraphic from '../../../../../assets/img/blu-print/vacay-graphic.png';
import buyCarGraphic from '../../../../../assets/img/blu-print/buy-car-graphic.png';
import reiGraphic from '../../../../../assets/img/blu-print/rei-graphic.png';
import Career1 from '../../../../../assets/img/blu-print/career-path-2.png';
import Career2 from '../../../../../assets/img/blu-print/career-adv-2.png';
import Career3 from '../../../../../assets/img/blu-print/addl-career-adv-2.png';
import Career4 from '../../../../../assets/img/blu-print/further-career-adv-2.png';
import Higher1 from '../../../../../assets/img/blu-print/higher-education-2.png';
import Higher2 from '../../../../../assets/img/blu-print/more-higher-education-2.png';
import Higher3 from '../../../../../assets/img/blu-print/further-higher-ed-2.png';
import investmentsNew from '../../../../../assets/img/blu-print/investments-2.png';
import FamilyTwo from '../../../../../assets/img/blu-print/family-2.png';
import VocationTwo from '../../../../../assets/img/blu-print/vacation-2.png';
import BuyingCarNewIcon from '../../../../../assets/img/blu-print/buy-car-2.png';
import BuyingHomeNewIcon from '../../../../../assets/img/blu-print/buy-home-2.png';
import reiTwo from '../../../../../assets/img/blu-print/rei-2.png';
import ArrowIcon from '../../../../../assets/img/blu-print/arrow_icon.png';
import BuyingHome from '../../../../../assets/img/blu-print/buying-a-home.png';
import powerUpLogo from '../../../../../assets/img/pf101-dashboard/powerup.png';
import greenTick from '../../../../../assets/img/pf101-dashboard/circlearrow.png';
import InkwiryBlueprintAug2019 from '../../../../../assets/img/pf101/Inkwiry-Blueprint-Aug2019.pdf';
import { getTotalTime, getUserXPRank } from '../../pf101-service';
import Pf101Popups from '../../popups';

/* eslint react/prop-types: 0 */

const SAVE_EXERCISE = loader( '../../../../../graphql/schema/pf101/save-exercise.graphql' );
const GET_EXERCISE = loader( '../../../../../graphql/schema/pf101/get-exercise-details.graphql' );
const startTime = new Date();

const Pf101BluePrint = ( props ) => {
  const { classes, history } = props;
  const [loading, setLoading] = React.useState( false );
  const [doubleClckValues, setDoubleClckValues] = React.useState( {} );
  const [singleEventArray, setSingleEventArray] = React.useState( {} );
  const [popupDetails, setPopupDetails] = React.useState( {} );
  const [pf101Points, setPf101Points] = React.useState( 0 );
  const [exerciseStatus, setExerciseStatus] = React.useState( 'submit' );

  const defaultQuestionList = [];
  for ( let i = 0; i < 1; i += 1 ) {
    defaultQuestionList.push( {
      id: i + 1,
      question_attempts: 1,
      xp_earned: 100,
      correct_answer: 1,
      status: 'C',
      answer: 1,
    } );
  }

  const singleClickEvent = ( value ) => {
    const singleEventData = { ...singleEventArray };
    if ( singleEventData[value] === undefined ) {
      singleEventData[value] = true;
    } else {
      singleEventData[value] = !singleEventData[value];
    }
    setSingleEventArray( singleEventData );
  };

  const doubleClickEvent = ( value ) => {
    const doubleClckData = { ...doubleClckValues };
    if ( doubleClckData[value] === undefined ) {
      doubleClckData[value] = true;
    } else {
      doubleClckData[value] = !doubleClckData[value];
    }
    setDoubleClckValues( doubleClckData );
  };

  useQuery( GET_EXERCISE, {
    variables: {
      level: 9,
      exercise: 2,
    },
    fetchPolicy: 'network-only',
    onCompleted( response ) {
      setLoading( false );
      setPf101Points( response.getPf101ExerciseDetails.total_points );
      if ( response.getPf101ExerciseDetails.questions.length > 0 ) {
        if ( response.getPf101ExerciseDetails.input_data !== undefined && response.getPf101ExerciseDetails.input_data !== '' ) {
          const inputData = JSON.parse( response.getPf101ExerciseDetails.input_data );
          if ( inputData[0] !== undefined ) {
            setSingleEventArray( inputData[0] );
          }
          if ( inputData[1] !== undefined ) {
            setDoubleClckValues( inputData[1] );
          }
        }
        setExerciseStatus( 'review' );
      }
    },
    onError( ) {
      window.location.href = '/';
    },
  } );

  const [saveExercise] = useMutation( SAVE_EXERCISE, {
    onCompleted( response ) {
      setLoading( false );
      if ( response.savePf101ExerciseDetails.status === true ) {
        setPopupDetails( ( prevState ) => ( {
          ...prevState,
          popupOpen: true,
        } ) );
      }
    },
    onError( ) {
      return false;
    },
  } );

  const finishActivity = () => {
    if ( exerciseStatus === 'review' ) {
      history.push( '/pf101/dashboard' );
      return false;
    }
    setLoading( true );
    const popupValue = {};

    const totalTime = getTotalTime( startTime );
    let totalPoints = _.sumBy( defaultQuestionList, 'xp_earned' );
    const totalAttempts = _.sumBy( defaultQuestionList, 'question_attempts' );
    const questionData = defaultQuestionList.map( ( questions ) => _.pick( questions, ['id', 'question_attempts', 'xp_earned'] ) );

    popupValue.exercisePoints = 100;

    popupValue.precision = false;
    popupValue.precisionPoints = 0;

    popupValue.level = true;
    popupValue.levelPoints = 500;
    popupValue.levelNumber = 9;
    totalPoints += 500;

    const presentRank = getUserXPRank( pf101Points );
    const nextRank = getUserXPRank( pf101Points + totalPoints );
    if ( presentRank !== nextRank ) {
      popupValue.rankChangeStatus = true;
      popupValue.rank = nextRank;
    } else {
      popupValue.rankChangeStatus = false;
      popupValue.rank = '';
    }

    setPopupDetails( popupValue );

    saveExercise( {
      variables: {
        data: {
          level: 9,
          exercise: 2,
          exercise_time: totalTime,
          points: totalPoints,
          total_attempts: totalAttempts,
          questionData,
          input_data: JSON.stringify( [singleEventArray, doubleClckValues] ),
        },
      },
    } );
    return true;
  };

  return (
    <Typography variant="body1" component="div">
      { loading && <Pageloader loading={ loading } /> }
      <div className={ classes.BluePrintBanner }>
        <Link to="/pf101/dashboard">
          <img src={ powerUpLogo } alt="" />
        </Link>
      </div>
      <div className={ classes.financialLife }>
        <div className={ classes.container }>
          <h3>A financial life plan is the key to living life on your own terms</h3>
          <Grid container>
            <Grid sm={ 12 } xs={ 12 } className={ classes.leftLife }>
              <p>
                When you have a financial life plan in place,
                you have the blueprint to living your dream – you’ve identified and planned for a higher education and career path you’re passionate about, one that rewards you financially, personally and gives you the freedom to pursue all the interests you value.
              </p>
            </Grid>
            <Grid sm={ 12 } xs={ 12 }>
              <p>To complete this activity, review the Blueprint and discover what goes into a financial life plan. Then, single click on life events (ex. Financial Independence, Career Path) that will be a part of your 5, 10, 15 year plan. Double click on the life events that are most important to you. Lastly, click to download the blueprint to complete this activity.</p>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.financialLifeEvent }>
        <h3>MAJOR LIFE EVENTS</h3>
        <div className={ classNames( classes.container, classes.lifeContainer ) }>
          <Grid container>
            <Grid sm={ 5 } xs={ 12 }>
              <div className={ classes.begainBlock }>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, singleEventArray['1'] === true && classes.financialBlockBoxActive ) } onClick={ () => singleClickEvent( '1' ) } onDoubleClick={ () => doubleClickEvent( 'module1' ) }>
                  <img src={ financialInde } alt="" />
                  <h4>Financial Independence</h4>
                  {doubleClckValues.module1 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <h2>Begin your journey ... You&apos;ve got this!</h2>
              </div>
            </Grid>
            <Grid sm={ 5 } xs={ 12 }>
              <div className={ classes.fancialGraphic }>
                <img src={ financialGraphic } alt="" />
              </div>
            </Grid>
            <Grid sm={ 2 } xs={ 12 }>
              <div className={ classes.financialBlockBoxRight }>
                {/* <p>In this module, you will enter your start year and answer a few questions to set the Simulator Engine in motion.</p> */}
                <p>Enter your start year and answer a few questions to set the Simulator Engine in motion.</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.ExitingAssets }>
        <div className={ classes.containerRow }>
          <Grid container>
            <Grid sm={ 4 } xs={ 12 }>
              <div className={ classes.ExitingAssetsLeft }>
                <div className={ classes.imageMargin }>
                  <img src={ existingAssets } alt="" />
                </div>
              </div>
            </Grid>
            <Grid sm={ 5 } xs={ 12 }>
              <div className={ classes.assestBoxMain }>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.assetsBox, singleEventArray['18'] === true && classes.assetsBoxActive ) } onClick={ () => singleClickEvent( '18' ) } onDoubleClick={ () => doubleClickEvent( 'module18' ) }>
                  <img src={ existingIcon } alt="" />
                  <h4>Existing Assets + Liabilities</h4>
                  {doubleClckValues.module18 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <h2>What you own, what you owe</h2>
                <p>Develop a plan to effectively manage your existing assets and liabilities such as outstanding student loans, credit card debt, a car or a home.</p>
              </div>
            </Grid>
            <Grid sm={ 3 } xs={ 12 }>
              <div className={ classes.ExitingAssetsRight }>
                <div className={ classes.imageMargin }>
                  <img src={ existingAssetsNew } alt="" />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.careerPath }>
        <div className={ classes.container }>
          <Grid container className={ classes.careerPathBg }>
            <Grid sm={ 8 } xs={ 12 }>
              <div className={ classes.careerPathLeft }>
                <h3>Plan your career with stepping stones to achieve your goals</h3>
                <ul>
                  <li>Consider your options for advanced positions, promotions, job changes</li>
                  <li>Forecast estimated income for each position </li>
                  <li>Know your ability to cover living expenses while paying off higher education debt</li>
                  <li>Manage your risks and maximize your rewards</li>
                </ul>
                {/* <img src={imagesCareerPathDown} alt="" /> */}
              </div>
            </Grid>
            <Grid sm={ 4 } xs={ 12 }>
              <div className={ classes.careerPathRight }>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.assetsBox, classes.assetsBoxOne, singleEventArray['4'] === true && classes.careerBoxActive ) } onClick={ () => singleClickEvent( '4' ) } onDoubleClick={ () => doubleClickEvent( 'module4' ) }>
                  <img src={ Career1 } alt="" />
                  <h4>Career Path</h4>
                  {doubleClckValues.module4 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.assetsBox, classes.assetsBoxTwo, singleEventArray['5'] === true && classes.careerAdvBoxActive ) } onClick={ () => singleClickEvent( '5' ) } onDoubleClick={ () => doubleClickEvent( 'module5' ) }>
                  <img src={ Career2 } alt="" />
                  <h4>Career Advancement</h4>
                  {doubleClckValues.module5 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.assetsBox, classes.assetsBoxThree, singleEventArray['8'] === true && classes.addCareerAdvBoxActive ) } onClick={ () => singleClickEvent( '8' ) } onDoubleClick={ () => doubleClickEvent( 'module8' ) }>
                  <img src={ Career3 } alt="" />
                  <h4>
                    Additional
                    <br />
                    Career Advancement
                  </h4>
                  {doubleClckValues.module8 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.assetsBox, classes.assetsBoxFour, singleEventArray['17'] === true && classes.furtherCareerAdvBoxActive ) } onClick={ () => singleClickEvent( '17' ) } onDoubleClick={ () => doubleClickEvent( 'module17' ) }>
                  <img src={ Career4 } alt="" />
                  <h4>
                    Further
                    <br />
                    Career Advancement
                  </h4>
                  {doubleClckValues.module17 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.HigherEducation }>
        <div className={ classes.container }>
          <Grid container>
            <Grid sm={ 3 } xs={ 12 }>
              <div className={ classes.HigherEducationLeft }>
                <img src={ heduBlocks } alt="" />
              </div>
            </Grid>
            <Grid sm={ 4 } xs={ 12 }>
              <div className={ classes.HigherEducationMiddle }>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.HigherEducationMiddleBox, classes.HigherEducationOne, singleEventArray['2'] === true && classes.HigherEducationOneActive ) } onClick={ () => singleClickEvent( '2' ) } onDoubleClick={ () => doubleClickEvent( 'module2' ) }>
                  <img src={ Higher1 } alt="" />
                  <h4>Higher Education</h4>
                  {doubleClckValues.module2 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.HigherEducationMiddleBox, classes.HigherEducationTwo, singleEventArray['6'] === true && classes.HigherEducationTwoActive ) } onClick={ () => singleClickEvent( '6' ) } onDoubleClick={ () => doubleClickEvent( 'module6' ) }>
                  <img src={ Higher2 } alt="" />
                  <h4>
                    More
                    <br />
                    Higher Education
                  </h4>
                  {doubleClckValues.module6 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.HigherEducationMiddleBox, classes.HigherEducationThree, singleEventArray['15'] === true && classes.HigherEducationThreeActive ) } onClick={ () => singleClickEvent( '15' ) } onDoubleClick={ () => doubleClickEvent( 'module15' ) }>
                  <img src={ Higher3 } alt="" />
                  <h4>
                    Further
                    <br />
                    Higher Education
                  </h4>
                  {doubleClckValues.module15 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
              </div>
            </Grid>
            <Grid sm={ 5 } xs={ 12 }>
              <div className={ classes.HigherEducationRight }>
                <h3>Acquire the skills and knowledge you need</h3>
                <ul>
                  <li>Choose your school</li>
                  <li>Identify sources of funding</li>
                  <li>Know your ability to repay student loans</li>
                  <li>Advance your career with additional skills, degrees, certifications</li>
                  <li>Expand your knowledge and open new horizons</li>
                </ul>
                <div className={ classes.imageMargin }>
                  <img src={ educationGraphic } alt="" />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.investments }>
        <div className={ classes.container }>
          <Grid container className={ classes.investTop }>
            <Grid sm={ 4 } xs={ 12 }>
              <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.assetsBoxx, classes.investBoxNew, singleEventArray['9'] === true && classes.investBoxNewActive ) } onClick={ () => singleClickEvent( '9' ) } onDoubleClick={ () => doubleClickEvent( 'module9' ) }>
                <img src={ investmentsNew } alt="" />
                <h4>Investments</h4>
                {doubleClckValues.module9 === true && (
                  <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                )}
              </div>
            </Grid>
            <Grid sm={ 7 } xs={ 12 }>
              <h3>Start early to build more equity over your lifetime</h3>
            </Grid>
          </Grid>
          <Grid container className={ classes.investBottom }>
            <Grid sm={ 6 } xs={ 12 }>
              <ul>
                <li>See how much income you can regularly invest over the short and long term</li>
                <li>Maximize the value of compounding interest</li>
                <li>Learn about different type of investments</li>
                <li>Understand the inherent risks vs. rewards of investing</li>
              </ul>
            </Grid>
            <Grid sm={ 5 } xs={ 12 }>
              <div className={ classes.imageMargin }>
                <img src={ investmentGraphic } alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.familyGroup }>
        <div className={ classes.container }>
          <Grid container className={ classes.familyGroupNew }>
            <Grid sm={ 5 } xs={ 12 }>
              <div className={ classes.imageMargin }>
                <img src={ familyGraphic } alt="" />
              </div>
            </Grid>
            <Grid sm={ 7 } xs={ 12 }>
              <div className={ classes.familyGroupTop }>
                <h3>Plan and prepare</h3>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.familyBox, singleEventArray['10'] === true && classes.familyBoxActive ) } onClick={ () => singleClickEvent( '10' ) } onDoubleClick={ () => doubleClickEvent( 'module10' ) }>
                  <img src={ FamilyTwo } alt="" />
                  <h4>Family</h4>
                  {doubleClckValues.module10 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>

              </div>
              <div container className={ `${classes.investBottomRight} ${classes.familyList}` }>
                <ul>
                  <li>How might a family change your financial situation?</li>
                  <li>Weigh the financial benefits and requirements</li>
                  <li>Know what to expect</li>
                  <li>See how envisioning your ideal scenario can help align your goals and make them reality.</li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.VocationGroup }>
        <div className={ classes.container }>
          <Grid container className={ classes.familyGroupGrid }>
            <Grid sm={ 6 } xs={ 12 } className={ classes.familyGroupLeft }>
              <div className={ classes.VocationGroupTop }>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.vocationTwo, singleEventArray['11'] === true && classes.VocationTwoActive ) } onClick={ () => singleClickEvent( '11' ) } onDoubleClick={ () => doubleClickEvent( 'module11' ) }>
                  <img src={ VocationTwo } alt="" />
                  <h4>Vacation + Special Purchases</h4>
                  {doubleClckValues.module11 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
              </div>
              <div container className={ classes.VocationGroupGrid }>
                <h3>Plan for your relaxation and joy</h3>
                <ul>
                  <li>Budget for regular vacations, special trips and travel</li>
                  <li>If there&apos;s something special that you must have, create a plan to make it happen</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={ 6 } xs={ 12 }>
              <div className={ classes.imageMargin }>
                <img src={ vacayGraphic } alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.BuyingCar }>
        <div className={ classes.container }>
          <Grid container className={ classes.familyGroupGrid }>
            <Grid sm={ 6 } xs={ 12 } className={ classes.familyGroupLeft }>
              <div container className={ classes.VocationGroupGrid }>
                <h3>Buy or lease? Find your best deal</h3>
                <ul>
                  <li>Enter your purchase price, down payment and the value of any rebate, discount, trade-in</li>
                  <li>Specify your loan terms and rate, understand the details</li>
                  <li>Learn the difference between buying and leasing</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={ 6 } xs={ 12 }>
              <div className={ classes.buyingCarIconBox }>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.BuyingCarNew, singleEventArray['12'] === true && classes.BuyingCarNewActive ) } onClick={ () => singleClickEvent( '12' ) } onDoubleClick={ () => doubleClickEvent( 'module12' ) }>
                  <img src={ BuyingCarNewIcon } alt="" />
                  <h4>Buying a Car</h4>
                  {doubleClckValues.module12 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
              </div>
            </Grid>
            <div className={ classes.imageMargin }>
              <img src={ buyCarGraphic } alt="" />
            </div>
          </Grid>
        </div>
      </div>
      <div className={ `${classes.financialLifeEvent} ${classes.BuyingHome}  ${classes.BuyingHomeNew}` }>
        <div className={ classes.containerRow }>
          <Grid container>

            <Grid sm={ 5 } xs={ 12 }>
              <div className={ `${classes.fancialGraphic} ${classes.BuyingHomeFirst}` }>
                <div className={ classes.imageMargin }>
                  <img src={ BuyingHome } alt="" />
                </div>
              </div>
            </Grid>
            <Grid sm={ 3 } xs={ 12 }>
              <div className={ `${classes.begainBlock} ${classes.begainBlockBottom}` }>
                <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.BuyingHomeIcon, singleEventArray['13'] === true && classes.BuyingHomeIconActive ) } onClick={ () => singleClickEvent( '13' ) } onDoubleClick={ () => doubleClickEvent( 'module13' ) }>
                  <img src={ BuyingHomeNewIcon } alt="" />
                  <h4>Buying a Home</h4>
                  {doubleClckValues.module13 === true && (
                    <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
                  )}
                </div>
                <h2>Know what you can afford and when</h2>
              </div>
            </Grid>
            <Grid sm={ 4 } xs={ 12 }>
              <ul>
                <li>Walk through the home buying process step by step</li>
                <li>Anticipate maintenance, repairs and/or improvements</li>
                <li>Learn the lingo, understand how mortgages work</li>
                <li>Employ competitive market analysis to assess value and compare pricing</li>
                <li>Perform diligent research so you know when you&apos;re getting a good deal.</li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ `${classes.BuyingCar} ${classes.buyingInvset}` }>
        <div className={ classes.container }>
          <Grid sm={ 6 } xs={ 12 }>
            <div role="button" aria-hidden="true" className={ classNames( classes.financialBlockBox, classes.buyingInvsetIcon, singleEventArray['14'] === true && classes.buyingInvsetIconActive ) } onClick={ () => singleClickEvent( '14' ) } onDoubleClick={ () => doubleClickEvent( 'module14' ) }>
              <img src={ reiTwo } alt="" />
              <h4>Buying an Investment Property</h4>
              {doubleClckValues.module14 === true && (
                <span className={ classes.taskComplete }><img src={ greenTick } alt="logo" /></span>
              )}
            </div>
          </Grid>
          <Grid container className={ classes.familyGroupGrid }>

            <Grid sm={ 5 } xs={ 12 } className={ classes.familyGroupLeft }>
              <div container className={ classes.VocationGroupGrid }>
                <ul>
                  <li>Run a financial model in minutes</li>
                  <li>Forecast monthly and yearly cash flow</li>
                  <li>Adjust your numbers to reduce risk and maximize reward</li>
                  <li>Compare different scenarios to find your best option</li>
                </ul>
              </div>
            </Grid>
            <Grid sm={ 7 } xs={ 12 }>
              <div className={ classes.buyingInvsetRight }>
                <h3>Explore real estate</h3>
                <div className={ classes.imageMargin }>
                  <img src={ reiGraphic } alt="" />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={ classes.bluePrintClose }>
        <div className={ classes.container }>
          <Grid container>
            <Grid sm={ 6 } xs={ 12 }>
              <h4>Keep your financial life blueprint close at hand.</h4>
              <p>Download the PDF, share, and collaborate with family and friends.</p>
            </Grid>
            <Grid sm={ 2 } xs={ 12 }>
              <img src={ ArrowIcon } alt="" />
            </Grid>
            <Grid sm={ 4 } xs={ 12 }>
              <a href={ InkwiryBlueprintAug2019 } download="download" onClick={ () => finishActivity() } target="_blank" rel="noopener noreferrer">Download BluePrint</a>
            </Grid>
          </Grid>
        </div>
      </div>
      {( popupDetails && popupDetails.popupOpen === true ) && (
        <Pf101Popups details={ popupDetails } />
      )}
    </Typography>
  );
};

Pf101BluePrint.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( Pf101BluePrint );
