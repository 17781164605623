const data = [
    {
      title: "Short-Term Investments",
      children: [
        {
          label: 'Short-Term Investments Period',
          type: 'date',
          prefix: '',
          suffix: " Years",
          key: "short_term_investments_period"
        },
        {
          label: 'Beginning Short-Term Investments Balance',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "begining_balance"
        },
        {
          label: 'Total Annual Contributions',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "short_term_summary_contributions"
        },
        {
          label: 'Total Earnings',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "short_term_summary_earnings"
        },
        {
          label: 'Short-Term Investments Balance before Transfers and Withdrawals',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "short_term_investments_before_transfers"
        },
        {
          label: 'Excess Cash Moved into Short-Term Investments',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "short_term_summary_excess_cash"
        },
        {
          label: 'Total Cash Withdrwan for Cash Need',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "short_term_summary_cash_with_drawn"
        },
        {
          label: 'Excess Short-Term Investments Moved into Long-Term Investments',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "short_term_summary_cash_deposited"
        },
        {
          label: 'Ending Short-Term Investments Balance',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "short_term_summary_ending_balance"
        }
      ]
    },
    {
      title: "Long-Term Investments",
      children: [
        {
          label: 'Long-Term Investments Period',
          type: 'date',
          prefix: '',
          suffix: " Years",
          key: "long_term_investments_period"
        },
        {
          label: 'Beginning Long-Term Investments Balance',
          type: 'number',
          prefix: '$',
          suffix: "",
          key: "second_begining_balance"
        },
        {
            label: 'Total Annual Contributions',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "long_term_summary_contributions"
          },
          {
            label: 'Total Earnings',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "long_term_summary_earnings"
          },
          {
            label: 'Long-Term Investments Balance before Transfers and Withdrawals',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "long_term_investments_balance_before_transfers"
          },
          {
            label: 'Excess Short-Term Investments Moved into Long-Term Investments',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "long_term_summary_excess_cash"
          },
          {
            label: 'Total Withdrawals for Cash Need',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "long_term_summary_cash_with_drawn"
          },
          {
            label: 'Ending Long-Term Investments Balance',
            type: 'number',
            prefix: '$',
            suffix: "",
            key: "long_term_summary_ending_balance"
          },
      ]
    }
  ]; 
  
  export default data;