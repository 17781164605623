import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Tabs, Tab, Box, Button
} from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import NumberFormat from 'react-number-format';
import styles from '../../screens/pwi-investments/styles';
// import infoIcon from '../../assets/img/pwi-he/info-icon.svg';
import * as modulesServices from '../../calculations/modules-services';
import { formatDollar } from '../../utilities/chartCommonFunctions';


function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const AnnualTable = (props) => {
  const {
    classes, setOpenWorkSheet
  } = props;
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [investmentsOutputData, setInvestmentsOutputData] = useState(modulesServices.investmentsOutputGraphData());
  const [outputSheetCompleteData, setOutputSheetCompleteData] = useState(modulesServices.outputSheetCompleteData());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    const investmentsOutput = modulesServices.investmentsOutputGraphData();
    setInvestmentsOutputData(investmentsOutput);
    const outputSheetComplete = modulesServices.outputSheetCompleteData();
    setOutputSheetCompleteData(outputSheetComplete);

    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${classes.tabSection} ${classes.tabSectionNew}`}>

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={`${classes.tabButtons} ${classes.tabButtonsHistoryInvestmentsNew}`}
        aria-label="full width tabs example"
      >
        <Tab
          className={value === 0 ? classes.activeTab : ''}
          label="Short-Term Investments Graph"
          {...a11yProps(0)}
        />
        <Tab
          className={value === 1 ? classes.activeTab : ''}
          label="Short-Term Investments Table"
          {...a11yProps(1)}
        />

        <Tab
          className={value === 2 ? classes.activeTab : ''}
          label="Long-Term Investments Graph"
          {...a11yProps(2)}
        />

        <Tab
          className={value === 3 ? classes.activeTab : ''}
          label="Long-Term Investments Table"
          {...a11yProps(3)}
        />
        {/* <Tab
          className={ classes.showWorkSheet }
          label="Show WorkSheet"
          { ...a11yProps( 1 ) }
        /> */}
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        className={`${classes.tabsContent} ${classes.tabsContentShortLong}`}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} >
          <div className={classes.graphView}>
            <div className={classNames(classes.potentialGrowth, classes.potentialGrowthNew)}>
              <Typography variant="h3" component="h3">Short-Term Investments</Typography>
              <hr className={classes.blueLine} />
              <div className={classes.graphImage}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={
                    {
                      chart: {
                        plotBackgroundColor: '#ffffff',
                        plotBorderColor: '#cccccc',
                        plotBorderWidth: 1,
                        plotShadow: false,
                      },
                      navigation: {
                        menuItemStyle: {
                          fontSize: '14px',
                          textAlign: 'left',
                        },
                        menuItemHoverStyle: {
                          background: '#f5f5f5',
                          color: '#4c4c4c',
                          fontSize: '14px',
                        },
                        buttonOptions: {
                          height: 40,
                          width: 48,
                          symbolSize: 24,
                          symbolX: 24,
                          symbolY: 21,
                          symbolStrokeWidth: 2,
                          verticalAlign: 'bottom',
                          _titleKey: 'y',
                        },
                      },
                      exporting: {
                        buttons: {
                          contextButton: {
                            menuItems: [{
                              textKey: 'downloadPNG',
                              onclick() {
                                const chart = this;
                                chart.exportChart();
                              },
                            }, {
                              textKey: 'downloadJPEG',
                              onclick() {
                                const chart = this;
                                chart.exportChart({
                                  type: 'image/jpeg',
                                });
                              },
                            }, {
                              textKey: 'downloadPDF',
                              onclick() {
                                const chart = this;
                                chart.exportChart({
                                  type: 'application/pdf',
                                });
                              },
                            }, {
                              textKey: 'downloadSVG',
                              onclick() {
                                const chart = this;
                                chart.exportChart({
                                  type: 'image/svg+xml',
                                });
                              },
                            }],
                          },
                        },
                      },
                      credits: {
                        enabled: false,
                      },
                      title: {
                        text: '',
                      },
                      xAxis: [{
                        categories: investmentsOutputData['Years List'],
                        crosshair: true,
                        labels: {
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                      }],
                      yAxis: [{ // Primary yAxis
                        labels: {
                          formatter() {
                            const chart = this;
                            if (chart.value < 0) {
                              return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                            }
                            return formatDollar(Math.round(chart.value));
                          },
                          style: {
                            color: '#000000',
                            fontSize: '14px',
                          },
                        },
                        title: {
                          text: 'Short-Term Investments',
                          style: {
                            color: '#000000',
                            fontSize: '16px',
                          },
                        },
                        opposite: true,
                      }],
                      /* tooltip: {
                                  pointFormat: '{series.name} <b>${point.y:,.0f}</b><br />'
                              }, */
                      tooltip: {
                        formatter() {
                          const chart = this;
                          if (chart.y < 0) {
                            return `${chart.series.name}<br/>${chart.x}: <span style="color:#bf0000; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
                          }

                          return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
                        },

                      },
                      legend: {
                        itemStyle: {
                          fontSize: '14px',
                        },
                      },
                      series: [{
                        name: 'Short-Term Funds Flow',
                        type: 'column',
                        yAxis: 0,
                        data: outputSheetCompleteData.investmentsShortTermFundsFlow,
                        tooltip: {
                          valuePrefix: '$',
                        },
                        color: '#FFBE00',
                        formatter() {
                          const chart = this;
                          if (chart.value < 0) {
                            return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                          }
                          return formatDollar(Math.round(chart.value));
                        },
                      }, {
                        name: 'Short-Term Investments Balance',
                        type: 'spline',
                        yAxis: 0,
                        data: outputSheetCompleteData.investmentsShortTermBalance,
                        tooltip: {
                          valuePrefix: '$',
                        },
                        color: '#FFBE50',
                        formatter() {
                          const chart = this;
                          if (chart.value < 0) {
                            return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                          }
                          return formatDollar(Math.round(chart.value));
                        },
                      }, {
                        name: 'Cash Balance',
                        type: 'spline',
                        yAxis: 0,
                        data: outputSheetCompleteData.investmentsCashBalance,
                        tooltip: {
                          valuePrefix: '$',
                        },
                        color: '#00AC6C',
                        formatter() {
                          const chart = this;
                          if (chart.value < 0) {
                            return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                          }
                          return formatDollar(Math.round(chart.value));
                        },
                      }],
                    }

                  }
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} >
          <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips, classes.tableTooltip)} />

          <div className={classNames(classes.totalAttendance, classes.investTable, classes.isummaryTable, classes.isummaryTablePlan, classes.mt0)}>
            <table>
              <thead>
                <tr className={classes.mainRow}>
                  <th>Short-Term Investments Summary</th>
                  <th>
                    <Button onClick={() => { setOpenWorkSheet('short') }} className={classes.showWorkSheet}>SHOW TABLE</Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.grayRow}>
                  <td>
                    <i>Investment Period</i>
                  </td>
                  <td>
                    <i>
                      {' '}
                      {modulesServices.module9Data.shortTermInvestmentsPeriod}
                    </i>
                  </td>
                </tr>
                <tr className={classes.grayRow}>
                  <td>
                    <span>
                      Beginning Short-Term Investments Balance
                    </span>
                  </td>
                  <td>
                  {modulesServices.module9Data.shortTermSummaryBeginningBalance !== undefined && modulesServices.module9Data.shortTermSummaryBeginningBalance !== null && modulesServices.module9Data.shortTermSummaryBeginningBalance !== ''  && modulesServices.module9Data.shortTermSummaryBeginningBalance === isNaN ? (
														<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryBeginningBalance} prefix={modulesServices.module9Data.shortTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryBeginningBalance < 0 && ')'} />) : ('$0')}
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
                  <td>
                    <span>Total Annual Contributions</span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.shortTermSummaryContributions !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryContributions} prefix={modulesServices.module9Data.shortTermSummaryContributions >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryContributions < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.bottomBlueBorder, classes.leftdataPadding)}>
                  <td>
                    <span>Total Earnings</span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.shortTermSummaryEarnings !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryEarnings} prefix={modulesServices.module9Data.shortTermSummaryEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryEarnings < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classes.grayRow}>
                  <td>
                    <b><span>Short-Term Investments Balance before Transfers and Withdrawals</span></b>
                  </td>
                  <td>
                    <b><span>
                      {modulesServices.module9Data.shortTermInvestmentsBeforeTransfers !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers} prefix={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermInvestmentsBeforeTransfers < 0 && ')'} />) : ('$0')}
                    </span></b>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
                  {/* <td>
                      <div className={classes.pointerRow}>
                        <img src={infoIcon} alt="" data-for="definationTitle" data-tip="Just like your cash account, when your short-term investments exceed your max balance you set, that excess amount is automatically moved into your long-term investments. Your short-term investments should only be a safety net of 3-6x months of expenses." />
                      </div>
                    </td> */}
                  <td>
                    <span>

                      Excess Cash Moved Into Short-Term Investments for
                      {' '}
                      {modulesServices.module9Data.shortTermInvestmentsPeriodYears !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsPeriodYears} />) : ('$0')}
                      {' '}
                      Years
                    </span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.shortTermSummaryExcessCash !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryExcessCash} prefix={modulesServices.module9Data.shortTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryExcessCash < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
                  {/* <td>
                      <div className={classes.pointerRow}>
                        <img src={infoIcon} alt="" data-for="definationTitle" data-tip="When you make big purchases such as a house or a car, you might run cash flow negative. When you need cash, the Financial Configurator will withdraw money from your short-term and long-term investments." />
                      </div>
                    </td> */}
                  <td>
                    <span>
                      Total Withdrawals for Cash Need for
                      {' '}
                      {modulesServices.module9Data.shortTermInvestmentsPeriodYears !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsPeriodYears} />) : ('$0')}
                      {' '}
                      Years
                    </span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.shortTermSummaryCashWithDrawn !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryCashWithDrawn} prefix={modulesServices.module9Data.shortTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryCashWithDrawn < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
                  {/* <td>
                      <div className={classes.pointerRow}>
                        <img src={infoIcon} alt="" data-for="definationTitle" data-tip="Just like your cash account, when your short-term investments exceed your max balance you set, that excess amount is automatically moved into your long-term investments. Your short-term investments should only be a safety net of 3-6x months of expenses." />
                      </div>
                    </td> */}
                  <td>
                    <span>
                      Excess Short-Term Investments Moved into Long-Term Investments for
                      {' '}
                      {modulesServices.module9Data.shortTermInvestmentsPeriodYears !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermInvestmentsPeriodYears} />) : ('$0')}
                      {' '}
                      Years
                    </span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.shortTermSummaryCashDeposited !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryCashDeposited} prefix={modulesServices.module9Data.shortTermSummaryCashDeposited >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryCashDeposited < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.bottomBlueBorder)}>
                  <td>
                    <b><span>Ending Short-Term Investments Balance</span></b>
                  </td>
                  <td>
                    <b><span>
                    {modulesServices.module9Data.shortTermSummaryEndingBalance !== undefined && modulesServices.module9Data.shortTermSummaryEndingBalance !== null && modulesServices.module9Data.shortTermSummaryEndingBalance !== '' && modulesServices.module9Data.shortTermSummaryEndingBalance === isNaN ? (
															<NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.shortTermSummaryEndingBalance} prefix={modulesServices.module9Data.shortTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.shortTermSummaryEndingBalance < 0 && ')'} />) : ('$0')}
                    </span></b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} >
          <div className={classNames(classes.potentialGrowth, classes.potentialGrowthNew)}>
            <Typography variant="h3" component="h3">Long-Term Investments</Typography>
            <hr className={classes.blueLine} />
            <div className={classes.graphImage}>
              <HighchartsReact
                highcharts={Highcharts}
                options={
                  {
                    chart: {
                      plotBackgroundColor: '#ffffff',
                      plotBorderColor: '#cccccc',
                      plotBorderWidth: 1,
                      plotShadow: false,
                    },
                    navigation: {
                      menuItemStyle: {
                        fontSize: '14px',
                        textAlign: 'left',
                      },
                      menuItemHoverStyle: {
                        background: '#f5f5f5',
                        color: '#4c4c4c',
                        fontSize: '14px',
                      },
                      buttonOptions: {
                        height: 40,
                        width: 48,
                        symbolSize: 24,
                        symbolX: 24,
                        symbolY: 21,
                        symbolStrokeWidth: 2,
                        verticalAlign: 'bottom',
                        _titleKey: 'y',
                      },
                    },
                    exporting: {
                      buttons: {
                        contextButton: {
                          menuItems: [{
                            textKey: 'downloadPNG',
                            onclick() {
                              const chart = this;
                              chart.exportChart();
                            },
                          }, {
                            textKey: 'downloadJPEG',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/jpeg',
                              });
                            },
                          }, {
                            textKey: 'downloadPDF',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'application/pdf',
                              });
                            },
                          }, {
                            textKey: 'downloadSVG',
                            onclick() {
                              const chart = this;
                              chart.exportChart({
                                type: 'image/svg+xml',
                              });
                            },
                          }],
                        },
                      },
                    },
                    credits: {
                      enabled: false,
                    },
                    title: {
                      text: '',
                    },
                    xAxis: [{
                      categories: investmentsOutputData['Years List'],
                      crosshair: true,
                      labels: {
                        style: {
                          color: '#000000',
                          fontSize: '14px',
                        },
                      },
                    }],
                    yAxis: [{ // Primary yAxis
                      labels: {
                        formatter() {
                          const chart = this;
                          if (chart.value < 0) {
                            return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                          }
                          return formatDollar(Math.round(chart.value));
                        },
                        style: {
                          color: '#000000',
                          fontSize: '14px',
                        },
                      },
                      title: {
                        text: 'Long-Term Investments',
                        style: {
                          color: '#000000',
                          fontSize: '16px',
                        },
                      },
                      opposite: true,
                    }],
                    /* tooltip: {
                    pointFormat: '{series.name} <b>${point.y:,.0f}</b><br />'
                }, */
                    tooltip: {
                      formatter() {
                        const chart = this;
                        if (chart.y < 0) {
                          return `${chart.series.name}<br/>${chart.x}: <span style="color:#bf0000; font-weight:bold;">(${formatDollar(-Math.round(chart.y))})</span>`;
                        }

                        return `${chart.series.name}<br/>${chart.x}: <b>${formatDollar(Math.round(chart.y))}</b>`;
                      },
                    },
                    legend: {
                      itemStyle: {
                        fontSize: '14px',
                      },
                    },
                    series: [{
                      name: 'Long-Term Funds Flow',
                      type: 'column',
                      yAxis: 0,
                      data: outputSheetCompleteData.investmentsLongTermFundsFlow,
                      tooltip: {
                        valuePrefix: '$',
                      },
                      color: '#004E71',
                      formatter() {
                        const chart = this;
                        if (chart.value < 0) {
                          return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                        }
                        return formatDollar(Math.round(chart.value));
                      },
                    }, {
                      name: 'Long-Term Investments Balance',
                      type: 'spline',
                      yAxis: 0,
                      data: outputSheetCompleteData.investmentsLongTermBalance,
                      tooltip: {
                        valuePrefix: '$',
                      },
                      color: '#416C83',
                      formatter() {
                        const chart = this;
                        if (chart.value < 0) {
                          return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                        }
                        return formatDollar(Math.round(chart.value));
                      },
                    }, {
                      name: 'Cash Balance',
                      type: 'spline',
                      yAxis: 0,
                      data: outputSheetCompleteData.investmentsCashBalance,
                      tooltip: {
                        valuePrefix: '$',
                      },
                      color: '#00AC6C',
                      formatter() {
                        const chart = this;
                        if (chart.value < 0) {
                          return `<span style="color:#bf0000;">(${formatDollar(-Math.round(chart.value))})</span>`;
                        }
                        return formatDollar(Math.round(chart.value));
                      },
                    }],
                  }

                }
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className={classNames(classes.totalAttendance, classes.investTable, classes.isummaryTable, classes.isummaryTablePlan, classes.mt0)}>
            <table>
              <thead>
                <tr className={classes.mainRow}>
                  <th>Long-Term Investments Summary</th>
                  <th>
                    <Button onClick={() => { setOpenWorkSheet('long') }} className={classes.showWorkSheet}>SHOW WORKSHEET</Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className={classes.grayRow}>
                  <td>
                    <i>Investment Period</i>
                  </td>
                  <td>
                    <i>
                      {modulesServices.module9Data.longTermInvestmentsPeriod}
                    </i>
                  </td>
                </tr>
                <tr className={classes.grayRow}>
                  <td>
                    <span>Beginning Long-Term Investments Balance</span>
                  </td>
                  <td>
                    {modulesServices.module9Data.longTermSummaryBeginningBalance !== undefined && modulesServices.module9Data.longTermSummaryBeginningBalance !== null && modulesServices.module9Data.longTermSummaryBeginningBalance !== '' && modulesServices.module9Data.longTermSummaryBeginningBalance === isNaN ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryBeginningBalance} prefix={modulesServices.module9Data.longTermSummaryBeginningBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryBeginningBalance < 0 && ')'} />) : ('$0')}
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
                  <td>
                    <span>Total Annual Contributions</span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.longTermSummaryContributions !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryContributions} prefix={modulesServices.module9Data.longTermSummaryContributions >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryContributions < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.bottomBlueBorder, classes.leftdataPadding)}>

                  <td>
                    <span>Total Earnings</span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.longTermSummaryEarnings !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryEarnings} prefix={modulesServices.module9Data.longTermSummaryEarnings >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryEarnings < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classes.grayRow}>
                  <td>
                    <b><span>Long-Term Investments Balance before Transfers and Withdrawals</span></b>
                  </td>
                  <td>
                    <b>
                      <span>
                        {modulesServices.module9Data.longTermInvestmentsBeforeTransfers !== undefined ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermInvestmentsBeforeTransfers} prefix={modulesServices.module9Data.longTermInvestmentsBeforeTransfers >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermInvestmentsBeforeTransfers < 0 && ')'} />) : ('$0')}
                      </span>
                    </b>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
                  {/* <td>
                      <div className={classes.pointerRow}>
                        <img src={infoIcon} alt="" data-for="definationTitle" data-tip="Just like your cash account, when your short-term investments exceed your max balance you set, that excess amount is automatically moved into your long-term investments. Your short-term investments should only be a safety net of 3-6x months of expenses." />
                      </div>
                    </td> */}
                  <td>
                    <span>
                      Excess Short-Term Investments Moved Into Long-Term Investments for
                      {' '}
                      {modulesServices.module9Data.longTermInvestmentsPeriodYears !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} value={modulesServices.module9Data.longTermInvestmentsPeriodYears} />) : ('$0')}
                      {' '}
                      Years
                    </span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.longTermSummaryExcessCash !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryExcessCash} prefix={modulesServices.module9Data.longTermSummaryExcessCash >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryExcessCash < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>
                <tr className={classNames(classes.grayRow, classes.leftdataPadding)}>
                  {/* <td>
                      <div className={classes.pointerRow}>
                        <img src={infoIcon} alt="" data-for="definationTitle" data-tip="When you make big purchases such as a house or a car, you might run cash flow negative. When you need cash, the Financial Configurator will withdraw money from your short-term and long-term investments." />
                      </div>
                    </td> */}
                  <td>
                    <span>
                      {' '}
                      Total Withdrawals for Cash Need for
                      {' '}
                      {modulesServices.module9Data.longTermInvestmentsPeriodYears !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} value={modulesServices.module9Data.longTermInvestmentsPeriodYears} />) : ('$0')}
                      {' '}
                      Years
                    </span>
                  </td>
                  <td>
                    <span>
                      {modulesServices.module9Data.longTermSummaryCashWithDrawn !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryCashWithDrawn} prefix={modulesServices.module9Data.longTermSummaryCashWithDrawn >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryCashWithDrawn < 0 && ')'} />) : ('$0')}
                    </span>
                  </td>
                </tr>

                <tr className={classNames(classes.grayRow, classes.bottomBlueBorder)}>

                  <td>
                    <b><span>Ending Long-Term Investments Balance</span></b>
                  </td>
                  <td>
                    <b>
                      <span>
                        {(modulesServices.module9Data.longTermSummaryEndingBalance !== undefined && modulesServices.module9Data.longTermSummaryEndingBalance !== null && modulesServices.module9Data.longTermSummaryEndingBalance !== '' && modulesServices.module9Data.longTermSummaryEndingBalance === isNaN && modulesServices.module9Data.longTermSummaryEndingBalance < 0) || (modulesServices.module9Data.longTermSummaryEndingBalance >= 0) ? (
                          <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={modulesServices.module9Data.longTermSummaryEndingBalance} prefix={modulesServices.module9Data.longTermSummaryEndingBalance >= 0 ? '$' : '($'} suffix={modulesServices.module9Data.longTermSummaryEndingBalance < 0 && ')'} />) : ('$0')}
                      </span>
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </TabPanel>
      </SwipeableViews>
    </div>

  );
};

AnnualTable.propTypes = {
  classes: PropTypes.object.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired
};

export default withStyles(styles)(AnnualTable);
