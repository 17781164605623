import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
// import Link from '@material-ui/core/Link';
import {Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
// import { Elements, StripeProvider } from 'react-stripe-elements';
// import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import styles from './styles';
import { withRouter } from 'react-router-dom';
import inkwiryFinalRev from '../../assets/img/students-parents/inkwiry-final-rev.png';
import IntelligentTool from '../../assets/img/students-parents/Intelligent-tool.png';
import careerPlanning from '../../assets/img/students-parents/career-planning.png';
import enhancementsPowerup from '../../assets/img/students-parents/enhancements-powerup.png';

const studentsParentsNew = ( props ) => {
  const { classes, history } = props;
    return (
      <Typography variant="body1" component="div" className={ classes.pageBody }>
        <div className={ classes.HeaderRow }>
          <div className={ classes.container }>
            <Link to="/">
              <img src={ inkwiryFinalRev } alt="Logo" />
            </Link>
          </div>
        </div>
        <div className={ classes.parentSectionOne }>
          <div className={ classes.container }>
            <Grid container spacing={ 2 } >
              <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                <div className={ classes.parentSectionOneLeft }>
                  <div className={ classes.sectionMax }>
                    <Typography variant="h3" component="h3">Teaching kids about money and the power of life planning is the best way to give them - and yourself - a lifetime of financial freedom.</Typography>
                    <p>In 15 minutes or less, make a plan for your student’s higher education without breaking the bank.</p>
                    <p>Inkwiry gives you the skills, knowledge and tools for life planning, and <span className={ classes.textStrong }>shows you the financial outcome of your decisions before you make them.</span> Did we say it’s also super affordable?</p>
                    <p>Join Inkwiry for 30 days for $9 and we’ll give you a <span className={ classes.textStrong }>second month free!</span> You’ll get direct email support, plus the first 100 to sign up get a 15-minute, 1:1 introductory call with co-founder James DeLuca.</p>
                    <div className={classes.clickBtn}>
                      <Link to="/join?section=parents">Get Inkwiry</Link>
                      <p>There’s nothing to lose: our 100% satisfaction guarantee offers a full refund in the first 7 days.</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 12 } md={ 6 }>
                <div className={ classes.parentSectionOneRight }>
                  <div className={ classes.videoBox }>
                    <iframe id="seeItFirst" width="800" height="450" allow="autoplay; encrypted-media" ng-onload="uploadDone();" allowfullscreen src="https://www.youtube-nocookie.com/embed/AwghHcuDP7k?modestbranding=1&showinfo=0&autohide=1&rel=0&autoplay=1" ></iframe>
                  </div>
                  <div className={ classes.videoBoxBlue }>
                    <p><span className={ classes.textStrong }>Your $9 investment can</span>  help you avoid making a common higher education mistake and <span className={ classes.textStrong }>save you $150,000 or more.</span></p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={ classes.parentSectionTwo }>
          <div className={ classes.container }>
            <div className={ classes.inkwriryHelpText }>
              <Typography variant="h3" component="h3">Inkwiry helps you and your student</Typography>
              <p>understand how education, career and investment decisions shape your personal happiness and financial well being. Then, Inkwiry helps you put knowledge into action so you can start your journey to financial freedom.</p>
            </div>
          </div>
        </div>
        <div className={ classes.parentSectionThree }>
          <div className={ classes.container }>
            <Grid container spacing={ 3 } >
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.guideBox }>
                  <figure>
                    <img src={ IntelligentTool } alt="Logo" />
                  </figure>
                  <div className={ classes.boxCnt }>
                    <Typography variant="h5" component="h5">Intelligent Tools</Typography>
                    <ul>
                      <li>Get bite-sized financial insights and strategies in the weekly edition of Finance Explained </li>
                      <li>Compare 5,700+ colleges and career schools side-by-side</li>
                      <li>Compare 1,000+ careers side-by-side</li>
                      <li>Learn how much car or house you can afford and when</li>
                      <li>Learn how much you need to invest and earn to reach $1 million, and how it’s totally doable</li>
                      <li>And more, much more!</li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.guideBox }>
                  <figure>
                    <img src={ careerPlanning } alt="Logo" />
                  </figure>
                  <div className={ classes.boxCnt }>
                    <Typography variant="h5" component="h5">Career Planning Jumpstart</Typography>
                    <p>Think career first when planning for higher education. My Career Sketch helps you prepare to become the CEO of your own life.</p>
                    <p>Get to know the 11 essential principles of smarter tomorrows and deploy them together so you can accelerate your journey to financial freedom.</p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                <div className={ classes.guideBox }>
                  <figure>
                    <img src={ enhancementsPowerup } alt="Logo" />
                  </figure>
                  <div className={ classes.boxCnt }>
                    <Typography variant="h5" component="h5">Essentials of Personal Finance</Typography>
                    <p>Invest four hours in Inkwiry’s Personal Finance 101. You and your student will gain fundamental money skills and knowledge for life via an online, gamified experience.</p>
                    <p>Personal Finance 101 is enthusiastically rated 9/10 by more than 2,500 users.</p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className={classes.clickBtn}>
              <Link to="/"><span>Click here to</span>TAKE A TEST DRIVE</Link>
              <p>Try the Higher Edcuation module absolutely free.</p>
            </div>
          </div>
        </div>
        <div className={ classes.parentSectionFour }>
          <div className={ classes.container }>
            <div className={ classes.oneWordRow }>
              <Typography variant="h3" component="h3">Inkwiry in one word:</Typography>
                <Grid container spacing={ 3 } >
                  <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <div className={ classes.oneWordBox }>
                      <Typography variant="h4" component="h4">"Brilliant."</Typography>
                      <p>(Partner at one of the top 70 largest accounting firms in the US.)</p>
                    </div>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <div className={ classes.oneWordBox }>
                      <Typography variant="h4" component="h4">"Phenomenal."</Typography>
                      <p>(COO of a $200MM+ revenue company with around 3,000 employees.)</p>
                    </div>
                  </Grid>
                  <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <div className={ classes.oneWordBox }>
                      <Typography variant="h4" component="h4">"Fantastic."</Typography>
                      <p>(Honors student, Villanova University School of Business.)</p>
                    </div>
                  </Grid>
                </Grid>
            </div>
          </div>
        </div>
        <div className={ classes.footerRow }>
          <div className={ classes.container }>
            <Grid container spacing={ 2 } >
              <Grid item xs={ 12 } sm={ 3 } md={ 3 }>
                <figure className={ classes.footerLeft }>
                  <img src={ inkwiryFinalRev } alt="Logo" />
                </figure>
              </Grid>
              <Grid item xs={ 12 } sm={ 9 } md={ 9 } className={ classes.footerRightCol }>
                <div className={ classes.footerRight }>
                  <span>Copyright ©2022 All rights reserved  //</span>
                  { ' ' }
                  <Link to="/privacy">Privacy Policy</Link>
                  { ' ' }
                  <span>/</span>
                  { ' ' }
                  <Link to="/termsofservice">Terms of Service</Link>
                  { ' ' }
                  <span>/</span>
                  { ' ' }
                  <Link to="/security">Security</Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Typography>
    );
  };
  studentsParentsNew.propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  
  const enhance = compose(
    withStyles( styles ),
    withRouter,
  );

  export default enhance( studentsParentsNew );
