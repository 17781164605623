import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const Cash = ( props ) => {
  const { classes, handleAssestObject,handleUpdatedObject } = props;
  const [cash, setCash] = React.useState({
    existing_cash: 0,  existing_checking_accounts: 0,
  });
  // const [total,setTotal] = React.useState(0);

  useEffect(()=>{
    if(handleAssestObject.existing_cash !== undefined && handleAssestObject.existing_checking_accounts !== undefined){
      setCash({existing_cash: handleAssestObject.existing_cash,  existing_checking_accounts: handleAssestObject.existing_checking_accounts})
    }
    // const Total = handleAssestObject.existing_cash+handleAssestObject.existing_checking_accounts;
    // setTotal(Total);
  },[])
  const handleChanges = (e, field) => {
    const inputValues = {...handleAssestObject};
    const v = e.floatValue !== undefined ? e.floatValue : 0;
    inputValues[field] = v;
    handleUpdatedObject( inputValues );
    setCash({...cash, [field]: v});    
    // const Total = inputValues.existing_cash+inputValues.existing_checking_accounts;
    // setTotal(Total);
  }

  return (
    <div className={ classes.contentBlock }>
      <p className={ classes.existingSpace }>
        Cash allows us to purchase products and services. Buying groceries or filling up your gas tank? You need cash to make those purchases. 
        It's important to have enough cash in your checking account to pay for your monthly living expenses.
      </p>
      <p className={ classes.existingSpace }>
        Cash includes the money in your wallet, under the bed or in the safe. Plus, the balance in your checking account. 
        {' '}
        <b>Enter the values for your cash on hand and cash in your checking account.</b>
      </p>
      
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Current Cash Balance</th>
          </thead>
          <tbody>
            <tr>
              <td><span >Cash</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale allowNegative={false} thousandSeparator value={ cash.existing_cash } name="existing_cash" onValueChange={ (e)=> handleChanges(e, 'existing_cash') }  />
                </div>
              </td>
            </tr>
            <tr>

              <td><span>Checking Account</span></td>
              <td>
                <div className={ classes.amountDollar }>
                  <span>$</span>
                  <NumberFormat customInput={ TextField } decimalScale={0} fixedDecimalScale allowNegative={false} thousandSeparator value={ cash.existing_checking_accounts } name= "existing_checking_accounts" onValueChange={ (e)=> handleChanges(e, 'existing_checking_accounts') } onFocus={ ( e ) => e.target.select() } />
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Cash Balance</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  <span>
                    {modulesServices.existingCurrentCashBalance() !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale  className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={modulesServices.existingCurrentCashBalance()} prefix={modulesServices.existingCurrentCashBalance() >= 0 ? '$' : '($'} suffix={modulesServices.existingCurrentCashBalance() < 0 && ')'} />
                    ) : ('$0')}
                  </span>

                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  );
};

Cash.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( Cash );
