import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();
const OnlineBanking = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/online-banking.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Online Banking</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/online-banking-300px.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          Whether it&apos;s for your checking and savings account, credit card or investment account, your bank or financial institution most likely offers online banking services. Online banking allows you to manage your money from your phone or computer on your own time rather than having to visit a bank.
                        </p>
                        <p>
                          How do most people manage their money?
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfImage }>
                      <img className={ classes.pfImageBorder } src="https://www.aba.com/-/media/images/infographics/preferredbankingmethods.jpg?rev=c0e74cfae44747f3b04077da84484120" alt="template" />
                    </div>
                    <div className={ classes.pfRow }>
                      <div className={ classes.creditText }>
                        <p>
                          <b>4 in 10 Americans bank online.</b>
                          What banking services can you access online?
                        </p>
                        <p>
                          <b>Pay Bills: </b>
                          rather than having to write and mail a check each month for your expenses such as rent, utilities or credit card statements, you can pay your bills online. Many companies will ask you to do
                          {' '}
                          <b>recurring payments,</b>
                          {' '}
                          which are automatic payments each month. This is convenient and will make sure you don&apos;t miss a payment.
                          {' '}
                          {' '}
                          <b>But you can easily forget recurring payments so always set reminders with your bank or on your own to make sure you have enough money in your account to pay those bills.</b>
                        </p>
                        <p>
                          <b>Online Bill Pay:</b>
                          {' '}
                          rather than paying your bills on different websites, your bank or financial institution typically offers online bill pay. Login to your bank account, find the online bill pay feature and select the company you are trying to pay. Your bank provides this service for free so you can pay all your bills in one place and simplify your financial life.
                        </p>
                        <p>
                          <b>Open New Accounts:</b>
                          {' '}
                          rather than going to the bank and sitting with a banker, you can open a checking, savings, investment account, and more online in a short period of time. Opening new accounts online is convenient, fast and easy to do.
                        </p>
                        <p>
                          <b>Transfer Money:</b>
                          {' '}
                          rather than going to the bank, you can transfer money online from one account to another.
                        </p>
                        <p>
                          <b>Deposit Checks:</b>
                          {' '}
                          when you want to deposit a check, instead of going to the bank or ATM, you can take a picture of the check on your phone with your mobile banking app at any time.
                        </p>
                        <p>
                          <b>Review Your Account Activity:</b>
                          {' '}
                          the best part of online banking is that you have full control and transparency of your account history. Want to see if your paycheck was deposited yet? Or, make sure your Venmo to your friend went through? With online banking, you can view all of your account activity whenever you wish.
                        </p>
                        <p>
                          <b>Direct Deposit:</b>
                          {' '}
                          rather than having your employer send your paycheck in the mail, there&apos;s a feature of online banking called direct deposit, which is much safer and faster. Exactly as it sounds, your employer can directly deposit your paycheck into your bank account. This is much safer as it eliminates the chances of somebody stealing your check and getting your information. Plus, the money is in your account faster.
                        </p>
                        <p>
                          Online banking offers fewer fees, more convenience and more control of your money.
                        </p>
                      </div>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level5/online-banking/exercise" startTime={ startTime } level={ 5 } exercise={ 1 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
OnlineBanking.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( OnlineBanking );
