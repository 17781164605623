import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import {
  Container,
} from '@material-ui/core';
import { loader } from 'graphql.macro';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import ReactTooltip from 'react-tooltip';
import 'slick-carousel/slick/slick-theme.css';
import YouTube from 'react-youtube';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
import styles from './styles';
// import Video from '../../assets/img/home-video.mp4';
import StudentParent from '../../assets/img/home_page/students-parents.png';
import homeBanner from '../../assets/img/home_page/banner-update.png';
import homeBannerMobile from '../../assets/img/home_page/mobile-banner.png';
import Parent from '../../assets/img/home_page/young_professionals_new.png';
import Proffessionals from '../../assets/img/home_page/schools_new.png';
import BuildOne from '../../assets/img/home_page/questions-graphic.png';
import BuildTwo from '../../assets/img/home_page/PF101-icon-square.png';
import BuildThree from '../../assets/img/home_page/net-worth-homepage.png';
import BuildFour from '../../assets/img/home_page/MCS-icon-square.png';
import BuildFive from '../../assets/img/home_page/finance-explained-green.png';
import BluePrint from '../../assets/img/home_page/home-blueprint.png';
import UserOne from '../../assets/img/home_page/emma.png';
import JamesDeluca from '../../assets/img/home_page/james_rounded.jpg';
import TestimonialQuote from '../../assets/img/home_page/testimonial_quote.png';
import TestimonialRilly from '../../assets/img/home_page/testimonial-riley.png';
import TestimonialKaren from '../../assets/img/home_page/testimonial-karen.png';
import TestimonialSaving from '../../assets/img/home_page/timesaving.png';
import CareerSketchCards from '../career-sketch/cards';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useEffect } from 'react';

const youtubeOpts = {
  host: 'https://www.youtube-nocookie.com',
  playerVars: {
    autoplay: 1,
    rel: '0',
    disablekb: '0',
    showinfo: '0',
    ccLoadPolicy: '0',
    ivLoadPolicy: '0',
    modestbranding: '1',
  },
};

const GET_AUTH_INFO = loader('../../graphql/schema/auth/auth-info.graphql');

const Home = (props) => {
  const { classes, history,location } = props;
  const {search} = location;
  
  const apolloClient = useApolloClient();

  const [watchIntro, setWatchIntro] = useState(false);
  const [ceoMessagePopUp, setCeoMessagePopUp] = React.useState(true);
  const [employersVideo, setEmployersVideo] = React.useState(false);
  const [partnerVideo, setPartnerVideo] = React.useState(false);
  const [sideBox, setSideBox] = React.useState(true);


  const { data } = useQuery(GET_AUTH_INFO, {
    onCompleted() {
      const token = localStorage.getItem('app-token');
      if (token) {
        apolloClient.writeData({ data: { isLoggedIn: true } });
      }
    },
  });


  const handleCEONotesPopUp = (status, type) => {
    if (status === 'open') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      switch (type) {
        case 'partner':
          setWatchIntro(false);
          setPartnerVideo(true);
          setEmployersVideo(false);
          break;
        case 'employers':
          setWatchIntro(false);
          setEmployersVideo(true);
          setPartnerVideo(false);
          break;
        default:
          break;
      }
    } else {
      setCeoMessagePopUp(false);
    }
  }
  const handleWatchIntro = () => {
    setWatchIntro(true);
  };

  const handleWatchIntroClose = () => {
    setWatchIntro(false);
    setEmployersVideo(false);
    setPartnerVideo(false);
  };

  const redirectToInkiwryChallenge = () => {
    history.push('/inkwiry-challenge');
  };

  const redirectToStudentParents = () => {
    history.push('/students-parents');
  };

  const redirectToSchools = () => {
    history.push('/why-inkwiry/schools');
  };

  const redirectToYourProfessionals = () => {
    history.push('/young-pros');
  };

  const redirectToQuestions = () => {
    history.push('/questions');
  };

  const redirectToPowerUpNow = () => {
    history.push('/personal-finance-101');
  };

  const redirectToChalleges = () => {
    history.push('/discovery');
  };

  const redirectToLightUp = () => {
    history.push('/my-career-sketch');
  };

  const redirectToGetSmarter = () => {
    history.push('/finance-explained');
  };

  const redirectToBluePrint = () => {
    history.push('/fc-blueprint');
  };

  const redirectToPricing = () => {
    if (!data.isLoggedIn) {
      history.push('/pricing');
    } else {
      history.push('/timeline-sketcher');
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -300) {
      setSideBox(true);
    }
    if (currPos.y < -3500) {
      setSideBox(false);
    }
    if (currPos.y > -300) {
      setSideBox(false);
    }
  });  

  useEffect(() => {
    setSideBox(false);
  }, []);

  return (
    <Typography variant="body1" component="div">
      <div className={classes.bannerVideo}>
        {/* <div className={ classes.videoWrapper }>
          <video id="bgvid" preload="auto" muted autoPlay="true" loop playsinline>
            <source src={ Video } type="video/mp4" />
          </video>
          <iframe src={ Video } title="IntroVideo" type="video/mp4" loop autoPlay />
        </div> */}
        <div className={classes.canvasBanner}>
          <img src={homeBanner} alt="" />
        </div>
        <div className={classes.canvasBannerMobile}>
          <img src={homeBannerMobile} alt="" />
        </div>
        <div className={classes.BannerVideoContent}>
          <div>
            <h3>Plan for financial freedom.</h3>
            <p>It’s never been this easy.</p>
            <button type="button" onClick={handleWatchIntro}>
              Watch The Intro
              <span>
                {' '}
                {'>'}
                {' '}
              </span>
            </button>
          </div>
        </div>
        <div className={`${classes.popupContent} ${(watchIntro || partnerVideo || employersVideo) && `${classes.popupContent} ${classes.watchIntroPopup}`}`}>
          <div className={classes.modalVideoInner}>
            <button type="button" onClick={handleWatchIntroClose} className={classes.closeIcon}><i className="la la-close" /></button>
            {employersVideo && <YouTube videoId="RFVcLEtfjkg" opts={youtubeOpts} />}
            {partnerVideo && <YouTube videoId="AwghHcuDP7k" opts={youtubeOpts} />}
            {watchIntro && <YouTube videoId="V6nRimAMD9c" opts={youtubeOpts} />}
          </div>
        </div>
      </div>
      <div className={classes.whatIsAwareness}>
        <div className={classes.awarenessInner}>
          <Typography variant="h2" component="h3">Think you know money?</Typography>
          <p>
            Take our quick 9-question quiz.
            <br />
            {' '}
            It could change your life, and the way you think about planning.
          </p>
          <span aria-hidden="true" onClick={redirectToInkiwryChallenge}>
            TAKE THE 9-QUESTION QUIZ
            &nbsp;&nbsp;
            {'>'}
            {' '}
          </span>
        </div>
      </div>
      <div className={classes.planWithInkwiry}>
        <Container className={classes.container}>
          <Grid container spacing={3}>
            <Grid item sm={4}>
              <div className={classes.planInkwiryBox}>
                <figure aria-hidden="true" onClick={redirectToStudentParents}>
                  <img className={classes.cursorPointer} src={StudentParent} alt="" />
                </figure>
                <Typography variant="h4" component="h4">For students and parents</Typography>
                <p>To quickly clarify your options and plan higher education and career paths</p>
                <span aria-hidden="true" onClick={redirectToStudentParents}>
                  TELL ME MORE
                  {' >'}
                </span>
              </div>
            </Grid>
            <Grid item sm={4}>
              <div className={classes.planInkwiryBox}>
                <figure aria-hidden="true" onClick={redirectToYourProfessionals}>
                  <img className={classes.cursorPointer} src={Parent} alt="" />
                </figure>
                <Typography variant="h4" component="h4">For young professionals</Typography>
                <p>To help determine your next best move and manage your debt</p>
                <span aria-hidden="true" onClick={redirectToYourProfessionals}>
                  TELL ME MORE
                  {' >'}
                </span>
              </div>
            </Grid>
            <Grid item sm={4}>
              <div className={classes.planInkwiryBox}>
                <figure aria-hidden="true" onClick={redirectToSchools}>
                  <img className={classes.cursorPointer} src={Proffessionals} alt="" />
                </figure>
                <Typography variant="h4" component="h4">For schools</Typography>
                <p>To easily boost offerings and promote financial literacy and personal planning</p>
                <span aria-hidden="true" onClick={redirectToSchools}>
                  TELL ME MORE
                  {' >'}
                </span>
              </div>
            </Grid>

          </Grid>
        </Container>
      </div>

      <CareerSketchCards fromPage="home-page" toPopUp ={search.split('=')[1]} />

      <div className={classes.buildTomorrow}>

        <Container className={classes.containerNew}>
          <Typography variant="h1">
            See and build your best tomorrows now
            <sub>TM</sub>
            {' '}
            with Inkwiry.
          </Typography>
          <ul>
            <li>
              <div className={classes.buildBox}>
                <figure aria-hidden="true" onClick={redirectToQuestions}>
                  <img src={BuildOne} alt="" />
                </figure>

                <p>Find your answers to these 10 important questions</p>
                <span aria-hidden="true" onClick={redirectToQuestions}>
                  EXPLORE NOW
                  {' >'}
                </span>
              </div>
            </li>
            <li>
              <div className={classes.buildBox}>
                <figure onClick={redirectToPowerUpNow}>
                  <img src={BuildTwo} alt="" />
                </figure>
                <p>Gain money knowledge and skills in a gamified experience</p>
                <span aria-hidden="true" onClick={redirectToPowerUpNow}>
                  POWER UP NOW
                  {' >'}
                </span>
              </div>
            </li>
            <li>
              <div className={classes.buildBox}>
                <figure aria-hidden="true" onClick={redirectToChalleges}>
                  <img src={BuildThree} alt="" />
                </figure>
                <p>Are you up for the challenge? Test your personal finance knowledge</p>
                <span aria-hidden="true" onClick={redirectToChalleges}>
                  TAKE THE CHALLENGE
                  {' >'}
                </span>
              </div>
            </li>
            <li>
              <div className={classes.buildBox}>
                <figure onClick={redirectToLightUp}>
                  <img src={BuildFour} alt="" />
                </figure>
                <p>Where else can you earn $25,000+ per hour?</p>
                <span aria-hidden="true" onClick={redirectToLightUp}>
                  LIGHT UP YOUR FUTURE
                  {' >'}
                </span>
              </div>
            </li>
            <li>
              <div className={classes.buildBox}>
                <figure aria-hidden="true" onClick={redirectToGetSmarter}>
                  <img src={BuildFive} alt="" />
                </figure>
                <p>Get smarter about your money as Inkwiry explains financial terms</p>
                <span aria-hidden="true" onClick={redirectToGetSmarter}>
                  GET SMARTER
                  {' >'}
                </span>
              </div>
            </li>
          </ul>
        </Container>
      </div>
      <div className={classes.bluePrint}>
        <Container className={classes.container}>
          <div className={classes.bluePrintBox}>
            <img src={BluePrint} alt="" />
            <div className={classes.bluePrintContent}>
              <div>
                <Typography variant="h2">
                  With a plan, you make things happen.
                </Typography>
                <p>Design your own blueprint and build a financial plan for living life on your own terms.</p>
                <span aria-hidden="true" onClick={redirectToBluePrint}>
                  START MY BLUEPRINT&nbsp;
                  {' >'}
                </span>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={classes.testimonilas}>
        <Container className={classes.container}>
          <div className={classes.testimonialInner}>
            <Slider {...settings}>
              <div className={classes.testimonialInner}>
                <div>
                  <figure>
                    <img src={UserOne} alt="user1" />
                  </figure>
                  <div className={classes.tstimonialContent}>
                    <Typography variant="h3">
                      <img src={TestimonialQuote} alt="user" />
                      In control of her future
                    </Typography>
                    <p>
                      After graduating high school, I felt a little lost and didn&apos;t know what I wanted to do or how to
                      get there. Once I joined Inkwiry, everything began falling into place.
                      I built a career sketch using the Inkwiry apps and tools.
                      Inkwiry helped me clearly see options I never knew existed.
                      I feel like I&apos;m in control of my future. I was able to set
                      goals and build plans to become financially independent.
                      Best of all I can see a clear path to my future working in a career I love.
                      It&apos;s like having a personal coach always there, forever.&rdquo;
                    </p>
                    <Typography variant="h5">EMMA, FUTURE VETERINARY ASSISTANT</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.testimonialInner}>
                <div>
                  <figure>
                    <img src={TestimonialRilly} alt="user1" />
                  </figure>
                  <div className={classes.tstimonialContent}>
                    <Typography variant="h3">
                      <img src={TestimonialQuote} alt="user" />
                      This course was excellent
                    </Typography>
                    <p>
                      I would rate Personal Finance 101 a 9/10. I completed all of PF101 and found it easy to use.
                      It was really helpful knowledge, and it was certainly worthwhile.
                      For learning the basics of personal finance, this course was excellent.
                      The course will be very helpful in my future. And,
                      I think future students would benefit greatly from it.&rdquo;
                    </p>
                    <Typography variant="h5">RILEY, HONORS STUDENT, VILLANOVA SCHOOL OF BUSINESS</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.testimonialInner}>
                <div>
                  <figure>
                    <img src={TestimonialKaren} alt="user1" />
                  </figure>
                  <div className={classes.tstimonialContent}>
                    <Typography variant="h3">
                      <img src={TestimonialQuote} alt="user" />
                      The students are really interested
                    </Typography>
                    <p>
                      The guys get excited about the &quot;competition,&rdquo; and of course, guess what the most popular question
                      is &quot;What is the prize given to the winner?&rdquo; But I really think most of them are actually
                      learning something. Lots of chatter on income taxes! Classes end up in discussions as the
                      students are really interested in a subject.&rdquo;

                    </p>
                    <Typography variant="h5">KAREN, ASSOCIATE DIRECTOR OF COLLEGE GUIDANCE, HOLY GHOST PREPARATORY SCHOOL</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.testimonialInner}>
                <div>
                  <figure>
                    <img src={TestimonialSaving} alt="user1" />
                  </figure>
                  <div className={classes.tstimonialContent}>
                    <Typography variant="h3">
                      <img src={TestimonialQuote} alt="user" />
                      Time-saving and practical
                    </Typography>
                    <p>
                      The (Personal Finance 101) modules perfectly align with the book I&apos;m using,
                      Financial Literacy for Millennials. Great way to hammer it in as they read/hear it
                      from someone who is not me! And Inkwiry&apos;s practical use of the concepts is
                      something that definitely saves me time as a teacher. Originating scenarios
                      and coming up with hypotheticals to put these concepts
                      into use would be a huge time burden.&rdquo;
                    </p>
                    <Typography variant="h5">SHANE, DEVON PREP, ECONOMICS AND PROFESSIONAL STUDIES TEACHER</Typography>
                  </div>
                </div>
              </div>

            </Slider>
          </div>
        </Container>
      </div>
      <div className={classes.buildCareerSketch}>
        <Container className={classes.container}>
          <Typography variant="h3">Before you decide. Before you borrow.</Typography>
          <Typography variant="h3">BUILD A CAREER SKETCH</Typography>
          <p>Let your imagination run, write your own story. With Inkwiry, you have the power to create your own plan and achieve financial independence.</p>
          <span aria-hidden="true" onClick={redirectToPricing}>
            GET STARTED &nbsp;&nbsp;
            {'>'}
          </span>
        </Container>
      </div>
      {
        ceoMessagePopUp && (
          <div>
            {sideBox && (
              <div className={classNames(classes.jamesMessage)}>
                <div className={classNames(classes.messageSec)}>
                  <div className={classNames(classes.ceoImage)}>
                    <img src={JamesDeluca} alt="JamesDeluca" />
                  </div>
                </div>
                <p>Watch video messages from our<span> CEO, James Deluca</span></p>
                <div className={classNames(classes.messageButtons)}>
                  <div className={classNames(classes.watchVideoBtn, classes.videoMargin)}>
                    <button type="button" onClick={() => handleCEONotesPopUp('open', 'partner')}>For Parents</button>
                  </div>
                  <div className={classNames(classes.watchVideoBtn)}>
                    <button type="button" onClick={() => handleCEONotesPopUp('open', 'employers')}>For Employers</button>
                  </div>
                </div>
                <div className={classNames(classes.noThanksBtn)}>
                  <Link to="#" onClick={() => handleCEONotesPopUp('close')}>No thanks&nbsp;&gt;</Link>
                </div>
              </div>
            )}
          </div>
        )
      }
      <ScrollToTop />
      {/* <div className="scrollTop">
        <i className="fa fa-chevron-up" onClick={scrollTop} style={{height: 40, display: showScroll ? 'flex' : 'none'}}/>
      </div> */}
      <ReactTooltip id="infoIcon" place="right" type="info" effect="solid" className={classes.tooltipInfo} />
    </Typography>
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(Home);
