import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { Link } from 'react-router-dom';

const ShortSellingStocks = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Short Selling Stocks</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/short-selling.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Short Selling Stocks
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/short-selling-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          The most common investment strategy for making money
                          off stocks is pretty straightforward. You buy a{' '}
                          <Link
                            to="/finance-explained/stocks-bonds"
                            target="_blank"
                            rel="noopener noreferrer">
                            stock
                          </Link>{' '}
                          with the expectation that the price of that stock will
                          rise over time. Then, you sell the stock at a higher
                          price and the difference is your profit. But a stock
                          rising in price is not the only way investors can make
                          a profit.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Short selling is an investment strategy where you
                          profit when a stock price falls. How does that exactly
                          happen? Short selling is more commonly known as
                          &ldquo;shorting&rdquo; a stock. Let&apos;s say Company
                          A is trading at $55 per share and you believe very
                          strongly that the price of the stock is going to drop
                          in the near term. The process for shorting a stock and
                          potentially making a profit are below:{' '}
                        </p>
                        <ol>
                          <li>
                            Borrow one share of Company A stock from a{' '}
                            <a
                              href="https://www.investopedia.com/terms/b/brokerageaccount.asp"
                              target="_blank"
                              rel="noopener noreferrer"
                              className={classes.linkText}>
                              brokerage firm
                            </a>{' '}
                            (in finance terms, a brokerage firm is a company
                            that provides access to the stock market),
                          </li>
                          <li>
                            Immediately sell that one borrowed share for $55
                            cash,
                          </li>
                          <li>Company A stock price drops to $30,</li>
                          <li>
                            Buy the one share back, which costs $30 or $25 less,
                            and
                          </li>
                          <li>
                            Return the shares to the brokerage firm and pocket
                            the $25 as profit before taxes.
                          </li>
                        </ol>
                        <div className={classes.pfImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/short-selling-graph.png"
                            alt=""
                          />
                        </div>
                        <p>
                          Short selling a stock is extremely risky. When you buy
                          a stock with the hope that it&apos;s going to rise in
                          price, what is your risk? If you buy the stock for
                          $100, your risk is that you lose $100. That risk
                          changes when you short a stock. If I short that same
                          stock expecting the price to fall, but it goes up,
                          what&apos;s my risk then? Technically, infinite. The
                          stock could rise to $5,000 in the future. You have to
                          return the stock you borrowed whether the price goes
                          up or down.
                        </p>
                        <p>
                          Shorting a stock takes a very good understanding of
                          trading mechanics and due diligence on the stock you
                          plan to short. Considering the high risk, an
                          investment professional might be your best bet.
                        </p>
                        <p>
                          <i>
                            Disclaimer: It&apos;s important to note that Inkwiry
                            does not provide investment advice. These examples
                            are academic and hypothetical. All investments carry
                            the risk of losing some or all your money and there
                            is no guarantee of earning a return. Consulting a
                            professional or doing your own due diligence is
                            essential before making investment decisions.
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
ShortSellingStocks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShortSellingStocks);
