import lftArrw from '../../assets/img/career-sketch/lft_arrw.png';
import rgtArrw from '../../assets/img/career-sketch/rgt_arrw.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  '*': {
    boxSizing: 'border-box',
  },
  careerBannerSec: {
    position: 'relative',
  },
  careerBannerImg: {
    '& figure': {
      margin: '0px',
      '& img': {
        width: '100%',
      },
    },
  },
  careerBannerCnt: {
    top: 'auto',
    bottom: '58px',
    transform: 'translateY(0)',
    WebkitTransform: 'translateY(0)',
    MozTransform: 'translateY(0)',
    MsTransform: 'translateY(0)',
    OTransform: 'translateY(0)',
    zIndex: '99',
    position: 'absolute',
    width: '100%',
    overflow: 'hidden',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      top: 'auto',
      bottom: '25px',
    },
    '@media (max-width: 479px)': {
      bottom: '18px',
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      bottom: '30px',
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
      bottom: '40px',
    },
    '& h3': {
      lineHeight: '81px',
      fontSize: '72px',
      fontFamily: '"MuseoSans-100"',
      letterSpacing: '1.5px',
      color: theme.palette.common.white,
      '@media (max-width: 767px)': {
        fontSize: '30px',
        lineHeight: '45px',
      },
      '@media (max-width: 479px)': {
        fontSize: '16px',
        lineHeight: '30px',
      },
      '@media (min-width: 768px) and (max-width: 991px)': {
        fontSize: '40px',
        lineHeight: '45px',
      },
      '& span': {
        top: '-50px',
        position: 'relative',
        fontSize: '15px',
        paddingLeft: '1px',
        '@media (max-width: 767px)': {
          top: '-10px',
        },
        '@media (max-width: 479px)': {
          fontSize: '12px',
        },
        '@media (min-width: 768px) and (max-width: 991px)': {     
          top: '-20px',
        }
      },
    },
  },
  seeItAction: {
    fontSize: '29px',
    lineHeight: '24px',
    padding: '20px 28px',
    color: theme.palette.common.white,
    display: 'inline-block',
    border: '2px solid #ffffff',
    borderRadius: '10px',
    margin: '28px 0 0 0',
    cursor: 'pointer',
    fontFamily: '"MuseoSans-300"',
    transition: 'all 0.5s ease 0s',
    MozTransition: 'all 0.5s ease 0s',
    WebkitTransition: 'all 0.5s ease 0s',
    '@media (max-width: 767px)': {
      padding: '7px 15px',
      margin: '4px 0 0 0',
      fontSize: '16px',
      lineHeight: '16px',
    },
    '@media (max-width: 479px)': {
      padding: '3px 10px',
      fontSize: '12px',
    },
    '@media (min-width: 768px) and (max-width: 991px)': {    
      margin: '15px 0 0 0',
      padding: '10px 20px',
      fontSize: '20px',
    },
    '@media (min-width: 992px) and (max-width: 1199px)': {
      margin: '10px 0 0 0',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.greenlight,
      border: '2px solid #84a84d',
      textDecoration: 'none',
    },
    '& i': {
      fontSize: '24px',
      marginLeft: '10px',
      '@media (max-width: 479px)': {
        fontSize: '14px',
        marginLeft: '5px',
      },
      '@media screen and (min-width: 480px) and (max-width: 767px)': {       
        fontSize: '18px',
      },
    },
  },
  carrerSketchInfo: {
    padding: '50px 0px',
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px 0px',
    },
    '& h3': {
      fontSize: '48px',
      lineHeight: '50px',
      margin: '0 0 18px 0',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-100"',
      '@media (max-width: 767px)': {
        fontSize: '30px',
        lineHeight: '40px',
      },
      '@media screen and (min-width: 768px) and (max-width: 992px)': {       
        fontSize: '35px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '28px',
        lineHeight: '30px',
      },      
    },
    '& p': {
      fontSize: '36px',
      lineHeight: '50px',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-100"',
      margin: '0px',
      [theme.breakpoints.down( 'sm' )]: {     
        fontSize: '24px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '30px',
      },     
    },
  },
  careerSketchDiscover: {
    padding: '50px 0 30px 0',
    textAlign: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '30px 0 0px 0',
    },
  },
  discoverRow: {
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      margin: '0',
      fontSize: '48px',
      lineHeight: '48px',
      color: theme.palette.common.blue,
      padding: '0 0 25px 0',
      [theme.breakpoints.down( 'sm' )]: {       
        lineHeight: '40px',
        fontSize: '30px',  
        paddingBottom: '15px', 
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '26px',
        lineHeight: '35px',
      },      
      '& span': {
        fontSize: '14px',
        top: '-20px',
        position: 'relative',
        left: '-8px',
      },
    },
  },
  discoverRowCnt: {
    fontSize: '24px',
    lineHeight: '34px',
    color: theme.palette.common.pureBlack,
    margin: '0px',
    '& span': {
      display: 'block',
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '25px',
    },
  },
  discoverBestNumCnt: {
    display: 'block',
    padding: '30px 30px',
    textAlign: 'left',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '15px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px 0 0',
    },
    '& ul': {
      display: 'flex',
      listStyle: 'none',
      padding: '0px',
      '& li': {
        width: '50%',
        padding: '0 15px',
        '& p': {
          fontSize: '20px',
          lineHeight: '30px',
          fontFamily: '"MuseoSans-300"',
          color: theme.palette.common.black,
          margin: '0 0 28px 0',
          '& span': {
            color: theme.palette.common.blue,
            fontFamily: '"MuseoSans-500"',
          },
        },
      },
      [theme.breakpoints.down( 'xs' )]: {
        display: 'block',
        '& li': {
          width: '100%',
          padding: '0',
        },
      },
    },
  },
  buildCarrerSketch: {
    marginBottom: '60px',
  },
  buildCarrerSketchCnt: {
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '48px',
      lineHeight: '48px',
      color: theme.palette.common.blue,
      margin: '0 0 40px 0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '33px',
        marginBottom: '20px',       
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '32px',
        lineHeight: '35px',
        margin: '0 0 20px 0',
      },
    },
    '& p': {
      fontSize: '24px',
      lineHeight: '35px',
      color: theme.palette.common.pureBlack,
      margin: '0 0 35px 0',
      fontFamily: '"MuseoSans-100"',
      [theme.breakpoints.down( 'sm' )]: {       
        marginBottom: '20px',       
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
        margin: '0 0 20px 0',
      },
    },
    '& figure': {
      margin: '0px auto 15px',
      textAlign: 'center',
      '& img': {
        width: '100%',
        display: 'flex',
        maxWidth: '1043px',
        margin: '0 auto',
      },
    },
  },
  buildText: {
    fontSize: '36px',
    lineHeight: '36px',
    color: theme.palette.common.blue,
    fontFamily: '"MuseoSans-100"',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '30px',      
    },
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '22px',
      lineHeight: '30px',
    },   
  },
  buildSlider: {
    margin: '10px auto 20px',
    maxWidth: '1080px',
    '& > div': {
      '& button': {
        backgroundSize: 'cover',
        width: '32px',
        height: '50px',
        left: '-50px',
        zIndex: '9',
        '@media (max-width: 1199px)': {
          left: '0px',
        }, 
        [theme.breakpoints.down( 'sm' )]: {
          width: '25px',
          height: '40px',
          backgroundSize: '25px',
          backgroundRepeat: 'no-repeat',
        },  
        [theme.breakpoints.down( 'xs' )]: {
          width: '20px',
          height: '30px',
          backgroundSize: '15px',
          backgroundRepeat: 'no-repeat',
        },        
        '&:hover': {
          backgroundSize: 'cover',
          width: '32px',
          height: '50px',
        },
        '&:focus': {
          backgroundSize: 'cover',
          width: '32px',
          height: '50px',
        },
        '&:before': {
          display: 'none',
        },
        '&:nth-child(1)': {
          backgroundImage: `url(${lftArrw})`,
          '&:hover': {
            backgroundImage: `url(${lftArrw})`,
          },
        },
      },
      '& button + div + button': {
        backgroundImage: `url(${rgtArrw})`,
        right: '-50px',
        left: 'inherit',
        '@media (max-width: 1199px)': {
          right: '0px',
        }, 
        [theme.breakpoints.down( 'xs' )]: {
          width: '20px',
          height: '30px',
          backgroundSize: '15px',
          backgroundRepeat: 'no-repeat',
        },
        '&:hover': {
          backgroundImage: `url(${rgtArrw})`,
        },
        '&:focus': {
          backgroundImage: `url(${rgtArrw})`,
        },
      },
      '& ul': {
        fontSize: '0',
        lineHeight: '0',
        display: 'none !important',
        '& li': {
          '& button:nth-child(1)': {
            backgroundImage: 'none',
            width: '0',
            height: '0',
            fontSize: '0',
          },
        },
      },
    },
    '& figure': {
      margin: '0px 5px',
      '& img': {
        width: '100%',
      },
    },
  },
  careerSearchDiv: {
    textAlign: 'center',
  },
  careerSearchBtn: {
    display: 'inline-block',
    padding: '16px 22px',
    fontSize: '29px',
    lineHeight: '29px',
    color: theme.palette.common.greenlight,
    border: '1px solid #84a84d',
    borderRadius: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '9px 15px',
      fontSize: '18px',
      lineHeight: '20px',
    },
    '&:hover': {
      background: theme.palette.common.greenlight,
      color: theme.palette.common.white,
    },
    '& i': {
      marginLeft: '10px',
      fontSize: '24px',
      [theme.breakpoints.down( 'xs' )]: {
        marginLeft: '5px',
        fontSize: '18px',
      },
    },
  },
  pendinglistPopup: {
    padding: '0px',
    paddingTop: '0px',
    boxShadow: 'none',
    '& div': {
      maxWidth: '100%',
      padding: '0px',
      paddingTop: '0px',
      boxShadow: 'none',
      overflowY: 'inherit',
    },
  },
  iframe: {
    border: '0px',
  },
  videoPopup: {
    paddingTop: '0px',
    '&:first-child': {
      paddingTop: '0px',
    },
  },
  popupClose: {
    top: '-10px',
    background: '#fff',
    borderColor: '#fff',
    color: '#000',
    width: '26px',
    right: '-10px',
    height: '26px',
    fontSize: '13px',
    lineHeight: '26px',
    outline: 'none',
    zIndex: '99',
    float: 'none',
    position: 'absolute',
    opacity: '1',
    border: '1px solid #f59331',
    textShadow: 'none',
    borderRadius: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  popupContent: {
    position: 'absolute',
    width: '100%',
    bottom: '0',
    zIndex: '99',
    transition: ' height ease-in-out 0.5s',
    height: '0',
    overflow: 'hidden',
    background: '#000',
    '& > div': {
      height: '100%',
      width: '100%',
      '& > div': {
        height: '100%',
        '& iframe': {
          width: '100%',
          height: '0',
          transition: ' height ease-in-out 0.5s',
        },
      },

    },
  },
  watchIntroPopup: {
    height: '100%',
    position: 'absolute',
    width: '100%',
    bottom: '0',
    '& > div': {
      height: '100%',
      maxWidth: '80%',
      margin: '0 auto',
      '& > div': {
        height: '100%',
        '& iframe': {
          width: '100%',
          height: '100%',
        },
      },

    },
  },
  closeIcon: {
    right: '10px',
    position: 'absolute',
    background: 'transparent',
    border: 'none',
    top: '10px',
    color: theme.palette.common.orange,
    fontSize: '30px',
    cursor: 'pointer',
    outLine: 'none',
  },

} );

export default styles;
