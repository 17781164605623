import selectDrop from '../../../assets/img/select_drop.png';

const styles = ( theme ) => ( {
 modalHeader: {
  background: '#0f6aac',
  padding: '20px',
  borderBottom: '1px solid #e5e5e5',
  position: 'relative',
  '& h4': {
    float: 'right',
    color: '#fff',
    fontSize: '35px',
    marginRight: '45px',
    margin: '0',
    lineHeight: '1.42857143',
  },
  '& img': {
    width: '150px',
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    verticalAlign: 'middle',
    border: '0px',
  },
 },
 summaryPopup: {
  '& > div > div': {
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down( 'sm' )]: {
      margin: '15px',
    },
    '& h4': {
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '25px',
        marginBottom:'10px',
      },
    }
  },
},
closePopup: {
  right: '15px',
  top: '9px',
  color: '#fff',
  position: 'absolute',
  padding: '0',
  cursor: 'pointer',
  background: '0 0',
  border: '0',
  marginTop: '2px',
  float: 'right',
  fontSize: '21px',
  lineHeight: '1',
  textShadow: '0 1px 0 #fff',
  opacity: '0.4',
  fontWeight: '700',
  '&:hover':{
    opacity: '0.8',
  }
},
modalBody: {
  position: 'relative',
  padding: '15px',
},
tableResponsive: {
  minHeight: '.01%',
  overflowX: 'auto',
},
// summaryBlock: {
//   height: '100%',
//   overflowY: 'scroll',
//   maxHeight: '442px',
// },
popupTable:{
  width: '100%',
  borderSpacing: '0',
  borderCollapse: 'collapse',
  boxSizing: 'border-box',
  '& tr': {
    '& td': {
      padding: '8px 10px',
    },
  },
},
moduleHeading: {
  padding: '15px',
  margin: '10px 0',
  color: '#fff',
  fontSize: '25px',
  lineHeight: '25px',
  fontWeight: '500',
  boxSizing: 'border-box',
},
selectHeading: {
  color: '#0069aa',
  marginTop: '15px',
  marginBottom: '10px',
  fontSize: '16px',
},
summarySelect: {
  position: 'relative',
  marginBottom: '40px',
  '& small': {
      lineHeight: '41px',
      fontSize: '14px', 
      backgroundSize: '12px',
      backgroundImage: `url(${selectDrop})`,
      backgroundPosition: 'right 8px center',
      backgroundRepeat: 'no-repeat',
      height: '43px',
      overflow: 'hidden',
      wordBreak: 'break-all',
      display: 'block',
      padding: '0 15px',
      width: '100%',
      border: '1px solid #969696',
      borderRadius: '0',
      cursor: 'pointer',
      position: 'relative',
      zIndex: '99',
      boxSizing: 'border-box',
      color: '#000',
  }
},
formGroup: {
  marginBottom: '15px',
},
insideSpan: {
  display: 'block',
  fontSize: '14px',
  padding: '0 9px',
  height: '40px',
  verticalAlign: 'middle',
  backgroundColor: '#e7e7e7',
  border: '1px solid #898d8f',
  color: '#898d8f',
  lineHeight: '1',
  float: 'left',
  width: '100%',  
  boxSizing: 'border-box',
},
summaryValue: {
  borderLeft: 'none',
  background: '#fff !important',
  '& > span': {
    color: '#0069aa !important',
  },
},
currencyContent: {  
  display: 'table-cell',
  height: '38px',
  verticalAlign: 'middle',
  fontSize: '13px',
},
cancelButton: {
  color: '#333',
  backgroundColor: '#fff',
  borderColor: '#ccc !important',
  '&:hover': {
    color: '#333',
    backgroundColor: '#e6e6e6',
    borderColor: '#adadad',
  },
},
footerButton: {
  display: 'inline-block',
  marginBottom: '0',
  fontSize: '17px',
  fontWeight: '400',
  lineHeight: '17px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  verticalAlign: 'middle',
  touchAction: 'manipulation',
  cursor: 'pointer',
  userSelect: 'none',
  border: '1px solid transparent',
  borderRadius: '4px',
  padding: '8px 14px',
  textTransform: 'capitalize',
  '&:last-child': {
    marginLeft: '5px',
  },
},
summaryTableGraph:{
  marginBottom: '0',
  color: '#000',
  fontWeight: '600',
  fontSize: '22px',
  textAlign: 'center',
  marginTop: '20px',
},
blueLine: {
  marginTop: '10px',
  marginBottom: '10px',
  borderTop: '2px solid #2f75b5',
  boxSizing: 'content-box',
},
moduleRow:{
  width: '100%',
},
analysisSection: {
    // padding: '0 15px',
    padding: '0 0px',
  },
  analysisPortion: {
    border: '1px solid #0069aa',
    backgroundColor: '#e5f0f6',
    margin: '10px 0',
    width: '100%',
    boxSizing: 'border-box',
  },
  analysisHeading: {
    backgroundColor: '#0069aa',
    display: 'inline-block',
    width: '100%',
    marginBottom: '15px',
    '& h3': {
      fontSize: '16px',
      lineHeight: '23px',
      marginBottom: '20px',
      color: '#fff',
      fontWeight: '700',
    },
  },
  analysisBlock: {
    padding: '0px 15px',
  },
  calculatedValue: {
    marginBottom: '15px',
    '& div': {
      height: 'auto',
      paddingTop: '0 !important',
      margin: '0',
      paddingBottom: '0px !important',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      color: '#333',
      position: 'initial',
    },
  },
  independenceGroup: {
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  insideSpanText: {
    background: 'transparent',
    border: '0',
  },
  spanText: {
    display: 'table-cell',
    height: '38px',
    verticalAlign: 'middle',
    fontSize: '13px',
    fontFamily: 'MuseoSans-300',
    '& strong': {
      fontFamily: 'MuseoSans-500',
      color: ' #333',
      fontWeight: 'normal',
    },
  },
  inputRight: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '38px',
    '& div': {
      '&::before': {
        border: 'none !important',
        outline: 'none !important',
      },
      '&::after': {
        border: 'none',
      },
    },
    '& input': {
      width: '100%',
      position: 'relative',
      color: '#0069aa',
      borderColor: '#898d8f',
      paddingLeft: '15px',
      fontSize: '13px',
      fontWeight: '600',
      height: '40px',
      lineHeight: '20px',
      borderRadius: '0',
      borderLeft: '0',
      float: 'left',
      display: 'block',
      padding: '0 9px',
      verticalAlign: 'middle',
      border: '1px solid #ccc',
      boxShadow: '0 2px 2px rgba(0,0,0,.075) inset',
      marginBottom: '0',
      backgroundImage: 'none',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      margin: '0',
      font: 'inherit',
      boxSizing: 'border-box',
      outline: '0',
      '&:focus': {
        outline: '0',
        WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
      },
      '&:disabled': {
        backgroundColor: '#eee',
        opacity: 1,
      },
    },
  },
  noInputText: {
    color: '#0069aa',
    fontSize: '13px',
    fontWeight: '600',
    paddingLeft: '9px',
    paddingRight: '3px',
  },
  formInput: {
    width: '85%',
  },
  cashFlowDiv: {
    // padding: '10px 0',
    // border: '1px solid #0069aa',
    margin: '0 0 15px',
    width: '100%',
  },
  whiteLeftBox: {
    backgroundColor: 'transparent',
    border: '0',
    display: 'block',
    fontSize: '14px',
    padding: '0 9px',
    height: '40px',
    verticalAlign: 'middle',
    color: '#898d8f',
    lineHeight: '1',
    float: 'left',
    width: '100%',
  },
  currencyTextNew: {
    fontSize: '14px',
    color: '#333',
    display: 'table-cell',
    height: '38px',
    verticalAlign: 'middle',
    '& strong': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
    },
  },
  cashFlowGreen: {
    height: '40px',
    '&  > div': {
      width: '100%',
      fontSize: '12px',
      border: '0',
      boxShadow: 'none',
      boxSizing: 'border-box',
      display: 'inline-block',
      maxWidth: '125px',
      '& span': {
        backgroundColor: '#73ab43',
        color: '#fff',
        width: '100%',
        fontWeight: '600',
        height: '40px',
        lineHeight: '20px',
        borderRadius: '0',
        // paddingLeft: '15px',
        padding: '0px 10px',
        float: 'left',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  cashFlowRed: {
    height: '40px',
    '&  > div': {
      width: '70%',
      fontSize: '12px',
      border: '0',
      boxShadow: 'none',
      boxSizing: 'border-box',
      display: 'inline-block',
      '& span': {
        backgroundColor: '#bf0000',
        color: '#fff',
        width: '100%',
        fontWeight: '600',
        height: '40px',
        lineHeight: '20px',
        borderRadius: '0',
        paddingLeft: '15px',
        float: 'left',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
    horizontalLine: {
    margin: '0 0 10px',
    borderBottom: '2px solid #0069AA',
    boxSizing: 'border-box',
  },
  financeHeading:{
  background: '#0069aa',
  },
  higherHeading:{
  background: '#86A940',
  },
  pathHeading:{
  background: '#0069aa',
  },
  careerAdvHeading:{
  background: '#3795e6',
  },
  moreHeader:{
  background: '#529c6f',
  },
  addHigherHeader:{
  background: '#81c3dc',
  },
  invHeader:{
  background: '#caa336',
  },
  familyHeader:{
  background: '#3299ac',
  },
  vacationHeader:{
  background: '#b94606',
  },
  carHeader:{
  background: '#ef9300',
  },
  homeHeader:{
  background: '#e66e1d',
  },
  reiHeader:{
  background: '#134376',
  },
  furtherHighHeader:{
  background: '#529c6f',
  },
  furtherCareerHeader:{
  background: '#81c3dc',
  },
  tableSummaryResponsive: {
    boxSizing: 'border-box',
    width: '100%',
    [theme.breakpoints.down( 'md' )]: {
      minWidth: '1300px', 
      '& table': {
        minWidth: '1300px', 
      },   
    },
  },
  summaryGrid:{
    padding: '0px 15px',
  },
  infoIcon:{
    display: 'inline-block !important',
    width: 'auto !important',
    position: 'relative',
    top: '4px',
    left: '5px',
  },
  tooltipInfoTop: {
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px !important',
    padding: '3px 8px !important',
    textAlign: 'initial',
    opacity: '1 !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    textShadow: 'none',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
    },
    '@media (max-width: 399px)': { 
      maxWidth: '130px',
    }
  },
} );

export default styles;
