
import parentsBanner from '../../assets/img/parents/toolkit-banner.png';

const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  bannerBlock:{
    height: '100%',
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: '21% 0',
    backgroundImage: `url(${parentsBanner})`,
    marginBottom: '56px',
    position: 'relative',
  },
  bannerCaptionNew:{
    top: '34%',
    transform: 'translateY(-34%)',
    MozTransform: 'translateY(-34%)',
    WebkitTransform: 'translateY(-34%)',
    position: 'absolute',
    width: '100%',
  },
  bannerIntro:{
    padding: '60px 0',
    background: 'rgba(0,105,170,.73)',
    width: '92%',
    margin: '0 4%',
    borderRadius: '10px',
    [theme.breakpoints.down( 'sm' )]: {
      padding: '20px 0',
    },
    '& h3':{
      fontFamily: '"MuseoSans-300"',
      color: theme.palette.common.white,
      margin: '0',
      fontSize: '58px',
      textShadow: '1px 1px 6px #7c7c7c',
      textAlign: 'center',
      fontWeight: 'normal',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '5vw',
      }
    }
  },
  toolkitContent:{
    textAlign: 'center',
    paddingBottom: '60px',
    '& h4':{
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
      color: '#333',
      lineHeight: '55px',
      margin: '10px 0px',
      fontSize: '34px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '26px',
        lineHeight: '36px',
      },
      '& span':{
        display: 'block',
      }
    }
  },
  toolkitP1:{
    margin: '0 0 10px',
    fontSize: '28px',
    padding: '40px 0',
    color: '#333',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '22px',
    }
  },
  toolkitP2:{
    margin: '0 0 40px',
    fontSize: '24px',
    color: '#333',
    [theme.breakpoints.down( 'sm' )]: {
      fontSize: '18px',
    }
  },
  toolkitBtn:{
    textAlign: 'center',
    '& a':{
      display: 'inline-block',
      backgroundColor: '#83a947',
      fontSize: '24px',
      lineHeight: '35px',
      padding: '16px 40px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      fontWeight: 'normal',
      borderRadius: '10px',
      '&:hover':{
        backgroundColor: '#f69a34',
      },
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
        lineHeight: '40px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '28px',
        padding: '10px 40px',
      },
      '& span':{
        display: 'block',
        '& i':{
          marginLeft: '15px',
        }
      }
    }
  },
  toolkitFooter:{
    marginTop: '50px',
    textAlign: 'center',
    '& p':{
      fontSize: '24px',
      margin: '0 0 10px',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '20px',
      },
      '& a':{
        color: '#333',
        textDecoration: 'underline',
        '&:hover':{
          color: '#f69a34',
        }
      }
    }
  }
} );

export default styles;
