import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-car/styles';
import classNames from 'classnames';
import CareerSketchTimeline from '../ui/career-sketch-timeline/career-sketch-timeline';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import * as modulesServices from '../../calculations/modules-services';

const SettingTimelinesOne = ( props ) => {

  const yearsList = [];
  const yearsListTicksValues = [];
  const year = modulesServices.module1Data.start_year !== undefined ? modulesServices.module1Data.start_year : new Date().getFullYear();
  for ( let j = 0; j <= 14; j += 1 ) {
    yearsList.push( year + j );
    if ( j % 3 === 0 ) {
      yearsListTicksValues.push( {
        value: year + j,
        label: year + j,
      } );
    }
  }
  const { classes, handleDefinationPopup, handleBuyingCarObject,handleUpdatedObject } = props;

  const [sliderValue, setSliderValue] = React.useState('');

  useEffect( () => {
    const timelineObj = { ...handleBuyingCarObject };
    if ( handleBuyingCarObject.purchase_year !== undefined && handleBuyingCarObject.purchase_year > 0 ) {
      setSliderValue( handleBuyingCarObject.purchase_year );
    } else {
      setSliderValue( year );
      timelineObj.purchase_year = year;
      handleUpdatedObject( timelineObj );
    }
    // eslint-disable-next-line
  },[]);

  const handleSliderChange = ( event, newValue ) => {
    setSliderValue( newValue );
    handleBuyingCarObject.purchase_year = newValue;
    handleUpdatedObject( handleBuyingCarObject );
  };

  return (
    <div className={ classes.contentBlock }>
      <p>Equipped with a well-researched plan and an understanding of the numbers, you will be confident when you go to buy a car and have peace of mind with your finances. Let&apos;s explore car buying and build a strategy to buy your next car.</p>
      <div className={ classes.grayBox }>
        <p>
          The
          {' '}
          {' '}
          <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( '20/4/10 Rule' ) }>20-4-10 rule</span>
          {' '}
          is a good benchmark to consider in the buying process. The
          {' '}
          <span className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." aria-hidden="true" onClick={ () => handleDefinationPopup( '20/4/10 Rule' ) }>20-4-10 </span>
          {' '}
          rule of car buying suggests that you can afford a car if:
        </p>
        <ul className={ classes.downPayment }>
          <li>20% down payment minimum</li>
          <li>4-year term of the car loan maximum</li>
          <li>10% of your total income for monthly car expenses maximum</li>
        </ul>
      </div>
      <p>Buying a car starts with asking a few questions. Why do you plan on buying a car? What type of car do you need? How much does that car cost? When can I afford to buy that car? Let&apos;s find out and start with when you expect to buy your first car..</p>
      <CareerSketchTimeline />
      <div className={ classes.groupAnnualBox }>
        <div className={ classes.AnnualIncomeGrowth }>
          <div>
            <p>
              <span>
                Purchase Year
              </span>
            </p>
            <div className={ classNames( classes.annuvalIncomeGrowthGraph, classes.annualIgraph ) }>
              <PwiSlider
                value={ sliderValue }
                aria-labelledby="discrete-slider-custom"
                valueLabelDisplay="auto"
                min={ yearsList[0] }
                max={ yearsList[yearsList.length - 1] }
                step={ 1 }
                marks={ yearsListTicksValues }
                ValueLabelComponent={ ValueLabelComponent }
                onChange={ handleSliderChange }
              />
            </div>
            <div className={ classes.slideValue }>
              <span>{sliderValue}</span>
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />

    </div>
  );
};

SettingTimelinesOne.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleBuyingCarObject: PropTypes.object.isRequired,
};

export default withStyles( styles )( SettingTimelinesOne );
