const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  benfitsBanner: {
    background: theme.palette.common.blue,
    padding: '60px 0',
    position: 'relative',
    zIndex: '9',
    '@media (max-width: 767px)': {
      padding: '40px 0px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '30px 0px',
    },
    '&:before': {
      content: '""',
      background: 'rgba(51, 51, 51, 0.2)',
      position: 'absolute',
      left: '0',
      top: '0',
      width: '100%',
      height: '100%',
      zIndex: '-1',
    },
  },
  justyfyCenter: {
    justifyContent: 'center',
  },
  bannerLeft: {
    '& img': {
      width: '100%',
      padding: '0px 35px',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
    },
  },
  bannerRight: {
    padding: '0 7px',
    '& h1': {
      lineHeight: '60px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      fontSize: '36px',    
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
        lineHeight: '40px',
      },
      '@media (max-width: 767px)': {
        fontSize: '20px',
        lineHeight: '30px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
      },
    },
  },
  planInkwirySec: {
    padding: '60px 0px',
    '@media (max-width: 767px)': {
      padding: '30px 0px',
    },
  },
  whyInkwryTitle: {
    textAlign: 'center',
    '& img': {
      maxWidth: '100px',
      paddingBottom: '20px',
    },
    '& h2': {
      fontSize: '44px',
      padding: '0 0 28px 0',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-100"',
      '@media (max-width: 767px)': {
        fontSize: '35px',       
        paddingBottom: '15px',
      },  
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
        paddingBottom: '15px',
      },        
    },
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '30px',
      lineHeight: '30px',
      margin: '0',
      padding: '0 0 55px 0',
      textAlign: 'center',
      color: theme.palette.common.blue, 
      '@media (max-width: 767px)': {
        paddingBottom: '30px',
        fontSize: '25px',
      },  
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
      },     
    },
  },
  whyInkwrySeeif: {
    background: theme.palette.common.greenlight,
    padding: '60px 0px',
    color: theme.palette.common.white,
    maxWidth: '800px',
    margin: '0 auto',
    textAlign: 'center',
    '@media (max-width: 767px)': {
      padding: '30px 10px',
    },
    '& h2': {
      fontSize: '40px',
      color: theme.palette.common.white,
      padding: '0 0 28px 0',
      fontFamily: '"MuseoSans-100"',
      '@media (max-width: 767px)': {
        fontSize: '35px',
        padding: '0 0 28px 0',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '23px',
        lineHeight: '35px',
        padding: '0 0 15px 0',
      },
    },
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '30px',
      lineHeight: '30px',
      padding: '0 0 55px 0',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
      '@media (max-width: 767px)': {
        paddingBottom: '30px',
      },
    },
    '& button': {
      fontSize: '30px',
      color: theme.palette.common.white,
      padding: '14px 45px',
      borderRadius: '5px',
      lineHeight: '24px',
      fontFamily: '"MuseoSans-300"',
      background: '#fd9840',
      cursor: 'pointer',
      transition: 'all 0.5s ease 0s',
      MozTransition: 'all 0.5s ease 0s',
      WebkitTransition: 'all 0.5s ease 0s',
      '@media (max-width: 767px)': {
        padding: '14px 35px',
        fontSize: '20px',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '20px',
      },
      '&:hover': {
        background: theme.palette.common.awareBtn,
        textDecoration: 'none',
      },
      '& i': {
        marginLeft: '5px',
      },
    },
  },
  whyInkwrySeeifList: {
    listStyle: 'none',
    color: theme.palette.common.blue,
    fontSize: '18px',
    lineHeight: '36px',
    fontFamily: '"MuseoSans-300"',
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '800px',
    margin: '0 auto',
    justifyContent: 'center',
    padding: '0 0 50px',
    [theme.breakpoints.down( 'sm' )]: {
      display: 'block',
      padding: '0 0 0px',
      textAlign: 'center',
    },
    '& li': {
      padding: '6px 26px',
      fontSize: '19px',
      [theme.breakpoints.down( 'sm' )]: {
        padding: '3px 10px',
        fontSize: '16px',
        display: 'inline-block',
      },
      [theme.breakpoints.down( 'xs' )]: {      
        fontSize: '15px',       
      },
    },
  },
  whyInkwryPowertools: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '50px 0',    
    '@media (max-width: 992px)': {
       padding: '30px 0px',
    },
    '@media screen and (min-width: 993px) and (max-width: 1024px)': {
      padding: '40px 0',
    },  
    '@media (max-width: 700px)': {
      padding: '20px 0px',
    },
    [theme.breakpoints.down( 'xs' )]: {
      padding: '0px',
    },
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '30px',
      lineHeight: '30px',
      margin: '0',
      padding: '0 0 30px 0',
      textAlign: 'center',
      color: theme.palette.common.blue,
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '22px',
        lineHeight: '30px',
      },
      '@media (max-width: 767px)': {
        padding: '25px 0px',
      },
    },
  },
  powertoolsRow: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: '0px',
      [theme.breakpoints.down( 'xs' )]: {
        display: 'block',
        textAlign: 'center',
        marginTop: '0px',
      },
      '& li': {
        width: '18.66666667%',
        padding: '0px 7px',
        listStyle: 'none',
        [theme.breakpoints.down( 'xs' )]: {
          width: 'calc(50% - 15px)',
          display: 'inline-block',
          marginBottom: '20px',
        },
        '& img': {
          width: '100%',
          paddingBottom: '5px',
        },
        '& span': {
          fontSize: '20px',
          textAlign: 'center',
          lineHeight: '27px',
          padding: '6px 0 0 0',
          display: 'block',
          color: theme.palette.common.black,
          [theme.breakpoints.down( 'xs' )]: {
            fontSize: '18px',
          },
        },
      },
    },
  },
  whyInkwryTogether: {
    padding: '70px 0',
    textAlign: 'center',
    '@media (max-width: 992px)': {
      padding: '30px 0',
    },
    '@media screen and (min-width: 993px) and (max-width: 1024px)': {
      padding: '40px 0',
    },  
    [theme.breakpoints.down( 'xs' )]: {
      padding: '20px 0',
    },
    '& h2': {
      fontSize: '44px',
      padding: '0 0 28px 0',
      color: theme.palette.common.blue,
      fontFamily: '"MuseoSans-100"',
      '@media (max-width: 768px)': {
        fontSize: '38px',
        padding: '0 0 15px 0',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      },
    },
    '& h3': {
      fontFamily: '"MuseoSans-100"',
      fontSize: '30px',
      lineHeight: '30px',
      margin: '0',
      padding: '0 0 55px 0',
      color: theme.palette.common.blue,
      '@media (max-width: 767px)': {
        fontSize: '22px',
        lineHeight: '30px',
        paddingBottom: '20px',
      },
    },
    '& button': {
      fontSize: '36px',
      lineHeight: '36px',
      borderRadius: '5px',
      color: theme.palette.common.white,
      padding: '40px 50px',
      background: '#f48726',
      cursor: 'pointer',
      display: 'inline-block',
      transition: 'all 0.5s ease 0s',
      MozTransition: 'all 0.5s ease 0s',
      WebkitTransition: 'all 0.5s ease 0s',
      textTransform: 'inherit',     
      '@media screen and (min-width: 700px) and (max-width: 992px)': {
        fontSize: '20px',
        padding: '10px 30px',
        display: 'inline-block !important',
        margin: '0px 10px 0px 10px !important',
      },   
      '@media screen and (min-width: 993px) and (max-width: 1024px)': {
          padding: '25px 40px',
          fontSize: '30px',
          display: 'inline-block !important',
          margin: '0px 10px 0px 10px !important',
        },   
        '@media (max-width: 767px)': {
          fontSize: '18px',
          lineHeight: '20px',
          padding: '15px 20px',
          display: 'block',
        },    
      [theme.breakpoints.down( 'md' )]: {
        display: 'block',
        maxWidth: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '&:hover': {
        background: '#fd9840',
        textDecoration: 'none',
      },
      '& i': {
        marginLeft: '20px',
      },
    },
  },
  studentBtn: {
    margin: '20px 30px 0px 0px',
    [theme.breakpoints.down( 'md' )]: {
      margin: '20px 0px 0px 0px',
    },
  },
  parentstBtn: {
    margin: '20px 0px 0px 30px',
    [theme.breakpoints.down( 'md' )]: {
      margin: '20px 0px 0px 0px',
    },
  },

} );

export default styles;
