import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, MuiThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import AppBar from '../ui/header/header';
import Footer from '../ui/auth-footer/footer';
// import IdleState from "./idle-state";
const styles = {
  root: {
    display: 'block',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.white,
    minHeight: 'calc(100vh - 190px)',
  },
};

const LayoutWithSidebar = ( { classes, component: Component, loggedInUser } ) => {
  
  return(
  <MuiThemeProvider theme={ theme }>
    <AppBar loggedInUser={ loggedInUser } />
    <div className={ classes.root }>
      <main className={ classes.content }>
        {/* <IdleState/> */}
        <Component />
      </main>
    </div>
    <Footer />
  </MuiThemeProvider>
);}

LayoutWithSidebar.defaultProps = {
  loggedInUser: null,
};

LayoutWithSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
  loggedInUser: PropTypes.object,
};

export default withStyles( styles )( LayoutWithSidebar );
