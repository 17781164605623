// import empowerBg from '../../assets/img/about/empowering_bg.jpg';
// import builtinkwiryBg from '../../assets/img/about/about_built_inkwiry_bg.jpg';
// import beganQuestion from '../../assets/img/about/began_question_bg.jpg';
// import advanceTechnology from '../../assets/img/about/advanced_technology_bg.jpg';
// import ourIntrest from '../../assets/img/about/our_interest_bg.jpg';

const styles = ( theme ) => ( {
  container: {
    maxWidth: 'calc(1170px - 30px)',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  aboutBanner:{
    backgroundColor: '#0069aa',
    padding: '50px 0',
    textAlign: 'center',
    position: 'relative',
  },
  bannerContent:{
    position: 'relative',
    '& figure':{
      width: '89%',
      margin: '0 auto',
      '& img':{
        marginTop: '-30px',
        paddingLeft: '20px',
        width: '100%',
        [theme.breakpoints.down( 'sm' )]: {
          marginTop: '0px',
          paddingLeft: '0px',
        }
      }
    }
  },
  bannerCaption:{
    position: 'absolute',
    width: '100%',
    left: '0',
    top: '0',
    transform: 'translateY(20%)',
    [theme.breakpoints.down( 'sm' )]: {
      transform: 'translateY(0%)',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    }
  },
  aboutLogo:{
    marginBottom: '30px',
    [theme.breakpoints.down( 'sm' )]: {
      marginBottom: '0px',
    },
    '& img':{
      maxWidth: '200px',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '150px',
      }
    }
  },
  bannerTitle:{
    '& h2':{
      margin: '50px 0px',
      fontSize: '54px',
      color: '#fff',
      lineHeight: '70px',
      fontWeight: '400',
      marginRight: '0',
      [theme.breakpoints.down( 'sm' )]: {
        fontSize: '30px',
        lineHeight: '40px',
        margin: '10px 0px',
      },
      '@media (max-width: 374px)': {
        fontSize: '24px',
        lineHeight: '30px',
      },
      '& span':{
        display: 'block',
        [theme.breakpoints.down( 'xs' )]: {
          display: 'initial',
        }
      }
    }
  },
  betterLives:{
    padding: '50px 0px',
    '& h3':{
      color: '#333',
      fontSize: '30px',
      lineHeight: '45px',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
      '& span':{
        display: 'block',
        '@media (max-width: 767px)': {
          display: 'initial',
        }
      }
    }
  },
  businessPlan:{
    marginBottom: '70px',
    '& h2':{
      fontSize: '36px',
      lineHeight: '40px',
      color: '#333',
      marginTop: '0',
      marginBottom: '50px',
      fontWeight: '400',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      }
    },
    '& p':{
      fontSize: '21px',
      marginBottom: '20px',
      marginTop: '0px',
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      }
    }
  },
  quoteCover:{
    '& video':{
      boxShadow: '0 0 30px #ccc',
    }
  },
  weTeach:{
    padding: '0 0 70px',
  },
  weteachImages:{
    boxShadow: '0 0 30px #ccc',
    background: '#fff',
    padding: '5px 0 30px',
    marginRight: '30px',
    borderRadius: '14px',
    marginTop: '20px',
    '@media (max-width: 959px)': {
      maxWidth: '230px',
      margin: '0px auto',
    }
  },
  teachImages:{
    textAlign: 'center',
    margin: '0 auto 20px',
    '&:last-child':{
      marginBottom: '0',
    },
    '& img':{
      width: '120px',
    }
  },
  weteachCnt:{
    '@media (max-width: 959px)': {
      marginTop: '50px',
    },
    '& h2':{
      fontSize: '36px',
      lineHeight: '40px',
      color: '#333',
      fontWeight: '400',
      margin: '0 auto 40px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      }
    },
    '& p':{
      margin: '0px 0px 30px',
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      },
      '& b':{
        fontWeight: '400',
      }
    }
  },
  gridWidth:{
    width: '88%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  beganWithQuestion:{
    background: '#d2eaf9',
    marginBottom: '100px',
    padding: '50px 0',
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '50px',
    },
    '& h2':{
      fontSize: '36px',
      lineHeight: '40px',
      color: '#333',
      fontWeight: '400',
      margin: '0 auto 40px',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      }
    }
  },
  fiveBrothers:{
    padding: '0px 15px',
    '& h3':{
      fontSize: '24px',
      color: '#333',
      lineHeight: '35px',
      fontWeight: '400',
      marginTop: '0',
      marginBottom: '15px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '28px',
      },
      '& span':{
        display: 'block',
        '@media (max-width: 399px)': {
          display: 'initial',
        }
      }
    },
    '& p':{
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      margin: '0px 0px 38px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      }
    }
  },
  homeBuyers:{
    paddingLeft: '40px',     
    paddingRight: '15px',
    [theme.breakpoints.down( 'xs' )]: {
      paddingLeft: '15px',     
    },
    '& p':{
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      margin: '0px 0px 38px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      },
      '& a':{
        color: '#333',
        textDecoration: 'underline',
        transition: 'all 0.5s ease',
        '&:hover':{
          color: '#f89b22',
        }
      }
    }                                 
  },
  csBluePrint:{
    marginBottom: '70px',
    '& h2':{
      fontSize: '36px',
      lineHeight: '40px',
      color: '#333',
      fontWeight: '400',
      margin: '0 auto 30px',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      }
    },
    '& h3':{
      textAlign: 'center',
      lineHeight: '34px',
      marginBottom: '30px',
      [theme.breakpoints.down( 'md' )]: {
        textAlign: 'left',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
        lineHeight: '30px',
      },
      '& span':{
        display: 'block',
        [theme.breakpoints.down( 'md' )]: {
          display: 'initial',
        }
      }
    }
  },
  sketchBluePrint:{
    width: '78%',
    margin: '0 auto 50px',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& video':{
      boxShadow: '0 0 30px #ccc',
    }
  },
  blueprintLeft:{
    padding: '0px 15px',
   '& p':{
    fontSize: '21px',
    fontFamily: 'MuseoSans-100',
    margin: '0px 0px 20px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '23px',
    }
   }
  },
  blueprintRight:{
    padding: '0px 15px',
    '& p':{
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      margin: '0px 0px 20px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      }
     }
  },
  intrestInSucceed:{
    marginBottom: '70px', 
    [theme.breakpoints.down( 'xs' )]: {
      marginBottom: '20px', 
    },
    '& h2':{
      fontSize: '36px',
      lineHeight: '40px',
      color: '#333',
      fontWeight: '400',
      margin: '0 auto 30px',
      textAlign: 'center',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
      }
    } 
  },
  intrestSection:{
    margin: '0 -25px',
    alignItems: 'center',
    [theme.breakpoints.down( 'md' )]: {
      margin: '0 -25px 20px',
    }
  },
  intrestSectionLeft:{
    '& img':{
      width: '100%',
    }
  },
  intrestSectionRight:{
    '& h4':{
      lineHeight: '30px',
      fontSize: '24px',
      color: '#333',
      fontWeight: '400',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
      }
    }
  },
  rightSpace:{
    paddingRight: '25px',
    '& p':{
      color: '#333',
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      fontWeight: '400',
      margin: '0px 0px 30px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      }
    }
  },
  leftSpace:{
    paddingLeft: '25px',
    '& p':{
      color: '#333',
      fontSize: '21px',
      fontFamily: 'MuseoSans-100',
      fontWeight: '400',
      margin: '0px 0px 30px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '16px',
        lineHeight: '23px',
      }
    }
  },
  leadershipTeam:{
    marginBottom: '70px',
  },
  leaderRow:{
    width: '70%',
    margin: '0 auto',
    flexWrap: 'wrap',
    [theme.breakpoints.down( 'xs' )]: {
      width: '100%',
    },
    '& h2':{
      fontSize: '36px',
      lineHeight: '40px',
      color: '#333',
      margin: '0 15px 30px',
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '30px',
        lineHeight: '35px',
        margin: '0 0px 10px',
      }
    }
  },
  gridFlex:{
    display: 'flex',
    alignItems: 'center',
  },
  leaderRowPara:{
    color: '#333',
    fontSize: '21px',
    fontFamily: 'MuseoSans-100',
    fontWeight: '400',
    paddingRight: '15px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '16px',
      lineHeight: '23px',
    },
    [theme.breakpoints.down( 'sm' )]: {
      paddingRight: '0px',
    }
  },
  goTeam:{
    '& a':{
      fontSize: '23px',
      color: '#fff',
      border: '1px solid #84a84d',
      backgroundColor: '#84a84d',
      padding: '16px 60px',
      borderRadius: '10px',
      lineHeight: '36px',
      textTransform: 'uppercase',
      transition: 'all 0.5s ease',
      '&:hover':{
        color: '#fff',
        border: '1px solid #f89b22',
        backgroundColor: '#f89b22',
      },
      [theme.breakpoints.down( 'xs' )]: {
        fontSize: '20px',
      }
    }
  },
  seeText:{
    lineHeight: '40px',
    color: '#0069aa',
    fontWeight: '400',
    margin: '50px 15px 0',
    fontSize: '36px',
    [theme.breakpoints.down( 'xs' )]: {
      fontSize: '30px',
      lineHeight: '35px',
    }
  },
  gridPad:{
    padding: '0px 20px !important',
  }
} );

export default styles;
