import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const RiskTolerance = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Risk Tolerance</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/risk-tolerance.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span> | </span>
                      Risk Tolerance
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/risk-tolerance-left.png"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          Understanding your risk tolerance is the first place
                          to begin when thinking about making investments. What
                          would you do if your investment portfolio declined in
                          value by 30%? Would you panic and sell or hold on
                          knowing that, over the long run, your investments
                          should rebound?
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          Take a look at the graph below of the{' '}
                          <a
                            href="https://www.investopedia.com/terms/s/sp500.asp"
                            target="_blank"
                            rel="noopener noreferrer">
                            S&P 500
                          </a>
                          . This stock market index reflects the price movement
                          of 500 large U.S. companies and is widely considered
                          to be the best representation of the U.S. stock
                          market.
                        </p>
                        <div className={classes.pfImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/risk-tolerance-graph.png"
                            alt=""
                          />
                        </div>
                        <p>
                          As you can see, over the last 30 years the{' '}
                          <a
                            href="https://www.investopedia.com/terms/s/sp500.asp"
                            target="_blank"
                            rel="noopener noreferrer">
                            S&P 500
                          </a>{' '}
                          has gone up and down in value. In the last 30 years,
                          the worst year on record was a -36.6% return.
                          That&apos;s why risk tolerance is so important. How
                          much of the ups and downs of investing can you
                          stomach? Let&apos;s find out.
                        </p>
                        <p>
                          What would you do if the{' '}
                          <Link
                            to="/finance-explained/stock_market"
                            target="_blank">
                            stock market
                          </Link>{' '}
                          fell 30% over the next three months?
                        </p>
                        <ol type="a">
                          <li>Sell all my stocks</li>
                          <li>Wait a few months and see what happens</li>
                          <li>Buy more of stocks</li>
                        </ol>
                        <p>
                          A conservative investor would likely sell all stocks
                          and invest in safer investments like bonds. A moderate
                          investor would likely wait a few months and see what
                          happens before adjusting the investing strategy. An
                          aggressive investor would likely look at the stock
                          market&apos;s 30% drop in price as an opportunity to
                          buy stocks when they&apos;re cheap and buy more shares
                        </p>
                        <p>What type of investor are you?</p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
RiskTolerance.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RiskTolerance);
