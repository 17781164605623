import * as moduleServices from '../calculations/modules-services';
import { formatDollar } from '../utilities/chartCommonFunctions';
import Highcharts from 'highcharts';

export function acaShowMeGraphData() {
  let graphsData = {};
  const careerAdvOutputData = moduleServices.addnCareerAdvOutputGraphData();

  graphsData['pieTotal'] = 0;
  if (careerAdvOutputData !== undefined && careerAdvOutputData['Pie Chart'] !== undefined && careerAdvOutputData['Pie Chart']['Total'] !== undefined) {
    graphsData['pieTotal'] = careerAdvOutputData['Pie Chart']['Total'];
  }

  graphsData['annualTotal'] = 0;
  if (careerAdvOutputData !== undefined && careerAdvOutputData['Annual Chart'] !== undefined && careerAdvOutputData['Annual Chart']['Total'] !== undefined) {
    graphsData['annualTotal'] = careerAdvOutputData['Annual Chart']['Total'];
  }

  let incomeStatementGraphCondition = true;

  if (careerAdvOutputData['Bar Graph'] !== undefined && careerAdvOutputData['Bar Graph']['YearsList'].length === 0) {
    incomeStatementGraphCondition = false;
  }


  let firstPieChartCondition = true;
  if (graphsData['pieTotal'] === 0) {
    firstPieChartCondition = false;
  }

  let annualPieChartCondition = true;
  if (graphsData['annualTotal'] === 0) {
    annualPieChartCondition = false;
  }

  let retirementGraphCondition = true;
  if (careerAdvOutputData['401k Bar Graph'] !== undefined && careerAdvOutputData['401k Bar Graph']['YearsList'].length === 0) {
    retirementGraphCondition = false;
  }

  let rothIraGraphCondition = true;
  if (careerAdvOutputData['Roth IRA'] !== undefined && careerAdvOutputData['Roth IRA']['YearsList'].length === 0) {
    rothIraGraphCondition = false;
  }

  let sepIraGraphCondition = true;
  if (careerAdvOutputData['SEP IRA'] !== undefined && careerAdvOutputData['SEP IRA']['YearsList'].length === 0) {
    sepIraGraphCondition = false;
  }

  let pensionGraphCondition = true;
  if (careerAdvOutputData['Pension'] !== undefined && careerAdvOutputData['Pension']['YearsList'].length === 0) {
    pensionGraphCondition = false;
  }

  //Bar Graph1

  if (firstPieChartCondition === true && careerAdvOutputData['Pie Chart'] !== undefined) {
    graphsData['graph1'] = true;
    graphsData['graph1Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotShadow: false,
        type: 'pie',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        // eslint-disable-next-line
        pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            // eslint-disable-next-line
            format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              fontSize: '14px'
            }
          }
        }
      },
      series: [{
        name: moduleServices.module2Data.name_of_college,
        type: 'pie',
        data: [
          ['Rent', careerAdvOutputData['Pie Chart']['totalRentValue']],
          ['Utilities', careerAdvOutputData['Pie Chart']['totalUtilitiesValue']],
          ['Food', careerAdvOutputData['Pie Chart']['totalFoodValue']],
          ['Clothing & Personal Care', careerAdvOutputData['Pie Chart']['totalClothingValue']],
          ['Entertainment', careerAdvOutputData['Pie Chart']['totalEntertainmentValue']],
          ['Technology', careerAdvOutputData['Pie Chart']['totalTechnologyValue']],
          ['Transportation', careerAdvOutputData['Pie Chart']['totalTransportationValue']],
          ['Miscellaneous', careerAdvOutputData['Pie Chart']['totalMiscellaneousValue']],
          ['Healthcare & Insurance', careerAdvOutputData['Pie Chart']['totalHealthInsuranceValue']],
        ].filter((d) => {
          return d[1] > 0
        })
      }]
    }
  } else {
    graphsData['graph1'] = false;
  }

  if (annualPieChartCondition === true && careerAdvOutputData !== undefined && careerAdvOutputData['Annual Chart'] !== undefined) {

    graphsData['graph2'] = true;
    graphsData['graph2Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotShadow: false,
        type: 'pie',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      title: {
        text: ''
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        // eslint-disable-next-line
        pointFormat: '${point.y:,.0f} , {point.percentage:.0f}%'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            // eslint-disable-next-line
            format: '{point.name},<br />${point.y:,.0f} , {point.percentage:.0f}%',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
              fontSize: '14px'
            }
          }
        }
      },
      series: [{
        type: 'pie',
        data: [
          ['Total Taxes Paid', careerAdvOutputData['Annual Chart']['Total Taxes Paid']],
          // ['Living Expenses',   careerAdvOutputData['Annual Chart']['Living Expenses']],
          ['Rent', careerAdvOutputData['Annual Chart']['Rent']],
          ['Utilities', careerAdvOutputData['Annual Chart']['Utilities']],
          ['Food', careerAdvOutputData['Annual Chart']['Food']],
          ['Clothing & Personal Care', careerAdvOutputData['Annual Chart']['Clothing & Personal Care']],
          ['Entertainment', careerAdvOutputData['Annual Chart']['Entertainment']],
          ['Technology', careerAdvOutputData['Annual Chart']['Technology']],
          ['Transportation', careerAdvOutputData['Annual Chart']['Transportation']],
          ['Miscellaneous', careerAdvOutputData['Annual Chart']['Miscellaneous']],
          ['Healthcare & Insurance', careerAdvOutputData['Annual Chart']['Healthcare & Insurance']],
          ['Higher Education Expenses', careerAdvOutputData['Annual Chart']['Higher Education Expenses']],
          ['Children Expenses', careerAdvOutputData['Annual Chart']['Children Expenses']],
          ['Pet Expenses', careerAdvOutputData['Annual Chart']['Pet Expenses']],
          ['Car Expenses', careerAdvOutputData['Annual Chart']['Car Expenses']],
          ['Real Estate Expenses', careerAdvOutputData['Annual Chart']['Real Estate Expenses']],
          ['Loan Payments', careerAdvOutputData['Annual Chart']['Loan Payments']],
          ['Retirement Contributions', careerAdvOutputData['Annual Chart']['Retirement Contributions']]
        ].filter((d) => {
          return d[1] > 0
        })
      }]
    }
  } else {
    graphsData['graph2'] = false;
  }

  if (incomeStatementGraphCondition === true && careerAdvOutputData['Bar Graph'] !== undefined && careerAdvOutputData['Bar Graph']['YearsList'] !== undefined) {
    graphsData['graph3'] = true;
    graphsData['graph3Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: careerAdvOutputData['Bar Graph']['YearsList'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{// Primary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: '',
          style: {
            color: Highcharts.getOptions().colors[2]
          }
        }
      }],
      tooltip: {
        formatter() {
          const chart = this;
          return '<b>' + chart.x + '</b><br/>' +
            chart.series.name + ': $' + chart.y.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + '<br/>' +
            'Total: $' + chart.point.stackTotal.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
        }
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Rental Income',
        data: careerAdvOutputData['Bar Graph']['Net Rental Income'],
        stack: 'male',
        color: '#9DC3E6'
      }, {
        name: 'Spouse\'s Income',
        data: careerAdvOutputData['Bar Graph']['Spouse Income'],
        stack: 'male',
        color: '#FFC000'
      }, {
        name: 'Supplementary Income',
        data: careerAdvOutputData['Bar Graph']['Supplementary Income'],
        stack: 'male',
        color: '#C5E0B4'
      }, {
        name: 'Bonus / Tips / Commission',
        data: careerAdvOutputData['Bar Graph']['Bonus / Tips / Commission'],
        stack: 'male',
        color: '#70AD47'
      }, {
        name: 'Base Income',
        data: careerAdvOutputData['Bar Graph']['Base Income'],
        stack: 'male',
        color: '#0070c0'
      }, {
        name: 'Living Expenses',
        data: careerAdvOutputData['Bar Graph']['Living Expenses'],
        stack: 'female',
        color: '#C55A11'
      }, {
        name: 'Retirement Contributions',
        data: careerAdvOutputData['Bar Graph']['Retirement Contributions'],
        stack: 'female',
        color: '#203864'
      }, {
        name: 'Estimated Taxes',
        data: careerAdvOutputData['Bar Graph']['Taxes'],
        stack: 'female',
        color: '#7C7C7C'
      }, {
        name: 'Loan Payments',
        data: careerAdvOutputData['Bar Graph']['Loan Payments'],
        stack: 'female',
        color: '#997300'
      }, {
        name: 'Real Estate Expenses',
        data: careerAdvOutputData['Bar Graph']['Real Estate Expenses'],
        stack: 'female',
        color: '#F8CBAD'
      }, {
        name: 'Higher Education Expenses',
        data: careerAdvOutputData['Bar Graph']['Higher Education Expenses'],
        stack: 'female',
        color: '#7030A0'
      }, {
        name: 'Children Expenses',
        data: careerAdvOutputData['Bar Graph']['Children Expenses'],
        stack: 'female',
        color: '#00FFCC'
      }, {
        name: 'Pet Expenses',
        data: careerAdvOutputData['Bar Graph']['Pet Expenses'],
        stack: 'female',
        color: '#C00000'
      }, {
        name: 'Car Expenses',
        data: careerAdvOutputData['Bar Graph']['Car Expenses'],
        stack: 'female',
        color: '#9999FF'
      }]
    }
  } else {
    graphsData['graph3'] = false;

  }

  if (retirementGraphCondition === true && careerAdvOutputData['401k Bar Graph'] !== undefined && careerAdvOutputData['401k Bar Graph']['YearsList'] !== undefined) {
    graphsData['graph4'] = true;

    graphsData['graph4Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: careerAdvOutputData['401k Bar Graph']['YearsList'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Ending Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }

      }, {// Secoundary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Total Annual Contribution & Earnings',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }],
      tooltip: {
        formatter() {
          const chart = this;
          var graphVal = chart.y;
          if (graphVal < 0) {
            graphVal = '<span style="color:#bf0000;">($' + (-graphVal).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") + ')</span>';
          } else {
            graphVal = '$' + graphVal.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
          }
          return '<b>' + chart.x + '</b><br/>' +
            chart.series.name + ': ' + graphVal + '<br/>';

        }
      },
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Employer Earnings',
        data: careerAdvOutputData['401k Bar Graph']['Employer Earnings'],
        stack: 'male',
        yAxis: 1,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#C5E0B4'
      }, {
        name: 'Employer Contributions',
        data: careerAdvOutputData['401k Bar Graph']['Employer Contributions'],
        stack: 'male',
        yAxis: 1,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Personal Earnings',
        data: careerAdvOutputData['401k Bar Graph']['Personal Earnings'],
        stack: 'male',
        yAxis: 1,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#BDD7EE'
      }, {
        name: 'Personal Contributions',
        data: careerAdvOutputData['401k Bar Graph']['Personal Contributions'],
        stack: 'male',
        yAxis: 1,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#2E75B6'
      }, {
        name: 'Employer Vesting Reductions',
        data: careerAdvOutputData['401k Bar Graph']['Employer Vesting Reductions'],
        stack: 'male',
        yAxis: 1,
        tooltip: {
          valuePrefix: '$',
        },
        color: '#9E480E',
        formatter() {
          const chart = this;
          if (chart.value < 0) {
            return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
          } else {
            return formatDollar(Math.round(chart.value));
          }
        }
      }, {
        name: 'Ending Balance',
        type: 'spline',
        yAxis: 0,
        data: careerAdvOutputData['401k Bar Graph']['Ending Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#D17E32'
      }]
    }
  } else {
    graphsData['graph4'] = false;
  }

  // Bar Graph 2

  if (rothIraGraphCondition === true && careerAdvOutputData['Roth IRA'] !== undefined && careerAdvOutputData['Roth IRA']['YearsList'] !== undefined) {
    graphsData['graph5'] = true;
    graphsData['graph5Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: careerAdvOutputData['Roth IRA']['YearsList'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{// Secoundary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Total Annual Contribution & Earnings',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }, {
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Ending Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }],
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Earnings',
        data: careerAdvOutputData['Roth IRA']['Earnings'],
        stack: 'male',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#BDD7EE'
      }, {
        name: 'Personal Contributions',
        data: careerAdvOutputData['Roth IRA']['Personal Contributions'],
        stack: 'male',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#2E75B6'
      }, {
        name: 'Ending Balance',
        type: 'spline',
        yAxis: 1,
        data: careerAdvOutputData['Roth IRA']['Ending Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#D17E32'
      }]
    }
  }
  else {
    graphsData['graph5'] = false;
  }

  if (sepIraGraphCondition === true && careerAdvOutputData['SEP IRA'] !== undefined && careerAdvOutputData['SEP IRA']['YearsList'] !== undefined) {

    graphsData['graph6'] = true;
    graphsData['graph6Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: careerAdvOutputData['SEP IRA']['YearsList'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{// Secoundary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Total Annual Contribution & Earnings',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }, {
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Ending Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }],
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Earnings',
        data: careerAdvOutputData['SEP IRA']['Earnings'],
        stack: 'male',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#BDD7EE'
      }, {
        name: 'Personal Contributions',
        data: careerAdvOutputData['SEP IRA']['Personal Contributions'],
        stack: 'male',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#2E75B6'
      }, {
        name: 'Ending Balance',
        type: 'spline',
        yAxis: 1,
        data: careerAdvOutputData['SEP IRA']['Ending Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#D17E32'
      }]
    }
  }
  else {
    graphsData['graph6'] = false;
  }

  if (pensionGraphCondition === true && careerAdvOutputData['Pension'] !== undefined && careerAdvOutputData['Pension']['YearsList'] !== undefined) {

    graphsData['graph7'] = true;
    graphsData['graph7Data'] = {
      chart: {
        plotBackgroundColor: '#ffffff',
        plotBorderColor: '#cccccc',
        plotBorderWidth: 1,
        plotShadow: false,
        type: 'column',
        // height: moduleGraphHeight,
        // width: graphMainWidth
      },
      navigation: {
        menuItemStyle: {
          fontSize: '14px',
          textAlign: 'left'
        },
        menuItemHoverStyle: {
          background: '#f5f5f5',
          color: '#4c4c4c',
          fontSize: '14px'
        },
        buttonOptions: {
          height: 40,
          width: 48,
          symbolSize: 24,
          symbolX: 24,
          symbolY: 21,
          symbolStrokeWidth: 2,
          verticalAlign: 'bottom',
          _titleKey: 'y'
        }
      },
      exporting: {
        buttons: {
          contextButton: {
            menuItems: [{
              textKey: 'downloadPNG',
              onclick() {
                const chart = this;
                chart.exportChart();
              },
            }, {
              textKey: 'downloadJPEG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/jpeg'
                });
              }
            }, {
              textKey: 'downloadPDF',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'application/pdf'
                });
              }
            }, {
              textKey: 'downloadSVG',
              onclick() {
                const chart = this;
                chart.exportChart({
                  type: 'image/svg+xml'
                });
              }
            }]
          }
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: careerAdvOutputData['Pension']['YearsList'],
        labels: {
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      },
      yAxis: [{// Secoundary yAxis
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Total Annual Contribution & Earnings',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        }
      }, {
        labels: {
          formatter() {
            const chart = this;
            if (chart.value < 0) {
              return '<span style="color:#bf0000;">(' + formatDollar(-Math.round(chart.value)) + ')</span>';
            } else {
              return formatDollar(Math.round(chart.value));
            }
          },
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        title: {
          text: 'Ending Balance',
          style: {
            color: '#000000',
            fontSize: '14px'
          }
        },
        opposite: true
      }],
      legend: {
        itemStyle: {
          fontSize: '14px'
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal'
        }
      },
      series: [{
        name: 'Earnings',
        data: careerAdvOutputData['Pension']['Earnings'],
        stack: 'male',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#BDD7EE'
      }, {
        name: 'Employer Contributions',
        data: careerAdvOutputData['Pension']['Employer Contributions'],
        stack: 'male',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#548235'
      }, {
        name: 'Personal Contributions',
        data: careerAdvOutputData['Pension']['Personal Contributions'],
        stack: 'male',
        yAxis: 0,
        tooltip: {
          valuePrefix: '$'
        },
        color: '#2E75B6'
      }, {
        name: 'Ending Balance',
        type: 'spline',
        yAxis: 1,
        data: careerAdvOutputData['Pension']['Ending Balance'],
        tooltip: {
          valuePrefix: '$'
        },
        color: '#D17E32'
      }]
    }
  }
  else {
    graphsData['graph7'] = false;
  }

  return graphsData;
}


