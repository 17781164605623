import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Typography, Table } from '@material-ui/core';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const WhatBudget = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>What&apos;s a Budget?</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/budget_FE_image.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      What&apos;s a Budget?
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/budget.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          A budget is a detailed plan for your income and
                          expenses divided into categories such as rent,
                          utilities, food, and more and covering a specific
                          amount of time such as a month or a year. A plan for
                          the future helps you take charge of your finances and
                          a budget is a part of that plan. A budget helps you
                          repay debt, save, invest and grow your net worth.
                        </p>
                        <p className={classes.smallText}>
                          The most effective strategy to building wealth is
                          actually a simple rule:{' '}
                          <b>spend less than you earn</b>. How can you
                          consistently spend less than you earn? Build a budget
                          so you can see exactly where you are spending and make
                          smarter choices about your money. Check out an example
                          of a budget below:
                        </p>
                        <div className={classes.galleryImage}>
                          <img
                            src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/whats_budget_new.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p
                          className={classNames(
                            classes.noBottomMargin,
                            classes.catText,
                          )}>
                          Use the below categories and percentages as guidelines
                          when building your own budget:
                        </p>
                        <Table className={classes.breakdownTable}>
                          <thead>
                            <tr>
                              <th colSpan="2">Budgeting Guidelines</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Rent & Utilities / Home Expenses</td>
                              <td>20% of After-Tax Income</td>
                            </tr>
                            <tr>
                              <td>Food & Drink</td>
                              <td>15%</td>
                            </tr>
                            <tr>
                              <td>Transportation & Car</td>
                              <td>12%</td>
                            </tr>
                            <tr>
                              <td>Clothing & Personal Care</td>
                              <td>7%</td>
                            </tr>
                            <tr>
                              <td>Entertainment & Technology</td>
                              <td>7%</td>
                            </tr>
                            <tr>
                              <td>Health Insurance & Healthcare</td>
                              <td>5%</td>
                            </tr>
                            <tr>
                              <td>Pet</td>
                              <td>2%</td>
                            </tr>
                            <tr>
                              <td>Miscellaneous</td>
                              <td>2%</td>
                            </tr>
                            <tr>
                              <td>Loan Payments</td>
                              <td>15-20%</td>
                            </tr>
                            <tr>
                              <td>Cash Left Over for Savings / Investments</td>
                              <td>10-20%</td>
                            </tr>
                          </tbody>
                        </Table>
                        <p>
                          Want to save for college or graduate school, pay down
                          debt, invest, buy a car or buy a home? You need a
                          solid budget to make sure you don’t spend more money
                          than you make.
                        </p>
                        <p>
                          Developing a budget might seem like a lot of work.
                          Inkwiry makes it easy by walking you, step by step,
                          through planning and budgeting. Once you have a plan
                          and a budget, you&apos;ll enjoy confidence and peace
                          of mind about your money.
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
WhatBudget.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhatBudget);
