import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import styles from './styles';
import { loader } from 'graphql.macro';
import { Grid } from "@material-ui/core";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Droppable } from 'react-drag-and-drop';
import {useLazyQuery} from '@apollo/react-hooks';
const INCOME_GROWTH_GRAPH_DATA = loader('../../../../graphql/schema/financial-independence/getIncomeGrowthGraphData.graphql')


const BitCoinAnswerPopups = (props) => {
  const { classes, challengeIndex } = props;
  const [graphData, setGraphData] = React.useState([]);

  const [getGraphData] = useLazyQuery(INCOME_GROWTH_GRAPH_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      from: "stocks_bonds"
    },
    onCompleted(response) {
      if(response && response.getIncomeGrowthGraphData) {
        setGraphData(response.getIncomeGrowthGraphData.data[0]);
      }
    },
    onError() {
    },
  });

  React.useEffect(()=>{
    getGraphData();
  },[])

  const dropZoneData = [
    {
      class: `${classes.risklist} ${classes.risklist1}`, text: 'Copayment', dropText: `${"A fixed amount that you pay for a regular checkup, other common doctor visits, filling a prescription or seeing a specialist."}`, answer: '4', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist2}`, text: 'Coinsurance', dropText: `${"After you pay your deductible for the year, this percentage determines how much of your additional medical bills you share with your insurance provider."}`, answer: '1', correct: false,
    },
    {
      class: `${classes.risklist} ${classes.risklist3}`, text: 'Deductible', dropText: `${"The amount of money you would pay in a year before your health insurance provider steps in and covers your expenses."}`, answer: '2', correct: false,
    },
  ];

  return (
    <Typography variant="body1" component="div">

      { /* bitcoin XP questions  Popup */}
     
        <div>
          {challengeIndex === 1 && (
            <div className={classes.completePopupChallengeTable}>
              <h3>Mary's investment strategy</h3>
              <div className={classes.dragTextRight}>
                <p>Mary is looking to advance her career by attaining additional education. Since her timeline is relatively short – only 3 years away – she would most likely look to diversify her investments with a chance to earn her a small, but less volatile return.</p>
                <p>Traditional savings accounts typically don’t pay any interest. If they do, it’s less than 0.10% usually. A traditional savings account guarantees your money will be there tomorrow, but it doesn’t give you a real opportunity to grow your savings and investments.</p>
                <p>Certificates of Deposit (CDs) offer a safe way to earn a smaller return. Only problem is Mary is investing monthly and CDs typically only let you make one investment at the beginning.</p>
                <p>
                  Stock ETFs and bond ETFs are diversified funds, meaning they invest in many things. Rather than investing in just one company stock or buying one company bond, Mary diversifies her investments by using ETFS, lowers her risk, and still has a good chance to earn a return.
                </p>
	            </div>
            </div>
          )}

          {challengeIndex === 2 && (
            <div className={classes.completePopupChallengeTable}>
              <h3>Health insurance defined</h3>
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <div className={classNames(classes.listedInvestmentsLeft, classes.listedInvestmentsLeftNew)}>
                    <ul className={classes.riskList}>
                      {dropZoneData.map((data, index) => (
                        <div className={classes.dragDiv}>
                          <label>{data.text}</label>
                          <li>
                            <Droppable
                              types={['appropriate']}
                              
                            >
                             
                          <span className={classes.dragTextNew}>{data.dropText}</span>
                            </Droppable>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </Grid>
               
              </Grid>
            </div>
          )}

          {challengeIndex === 3 && (
          <div>
             <div className={classes.completePopupChallengeTable}>
              {/* <h1>POWERUP CHALLENGE EXPLANATION</h1> */}
               <h3>Risk v reward</h3>
            </div>
          <Grid container spacing={2} className={classes.gridRow}>
            <Grid item md={8} sm={8} xs={12} className={classes.gridCol}>
              <div className={classes.assetsOptionsGraph}>
              <HighchartsReact
                      highcharts={ Highcharts }
                      options={ {
                        chart: {
                          type: 'spline',
                          scrollablePlotArea: {
                            scrollPositionX: 0
                          }
                        },
                        credits: {
                          enabled: false
                        },
                        title: {
                          text:  'Stocks v Bonds: Annual Returns',
                    style: {
                            color: '#000'
                          }
                        },
                  exporting: {
                    enabled: false
                    },
                        xAxis: [{
                          tickInterval: 5,
                          categories: graphData.dates,
                          labels: {
                            formatter: function () {
                              var date = new Date(this.value);
                              return date.getFullYear();
                            },
                            style: {
                              color: '#000',
                              fontSize: '13px'
                            }
                          }
                        }],
                        yAxis: [{ // Primary yAxis
                          labels: {
                            format: '{value:.1f}%',
                            style: {
                              color: '#000',
                              fontSize: '14px'
                            }
                          },
                          title: {
                            text: '',
                            style: {
                              color: '#000',
                              fontSize: '14px'
                            }
                          }
                        }],
                        tooltip: {
                    enabled: true,
                          headerFormat: '<span style="font-size:14px">{point.key}</span><br/>',
                          crosshairs: true,
                          shared: true,
                          valueDecimals: 1,
                          valueSuffix: '%',
                          style: {
                            color: '#000',
                            fontSize: '14px'
                          }
                        },
                        legend: {
                    enabled: true,
                        },
                        plotOptions: {
                          spline: {
                            lineWidth: 2,
                            states: {
                              hover: {
                                lineWidth: 2
                              }
                            },
                            marker: {
                              enabled: false
                            },
            
                          }
                        },
                        series: graphData.details
                      }
                    }
                    />
                </div>
                <p className={classes.popupPowerupPara}>Source: <a href="/">Aswath Damodaran, NYU Stern School of Business</a></p>
            </Grid>
            <Grid item md={4} sm={4} xs={12} className={classes.gridCol}>
             
                  <div className={classes.dragTextRight}>
                    <p>
                      Recall the relationship between risk and reward. The higher the risk typically means a higher potential reward.
                    </p>
                    <p>
                      The blue line fluctuates – goes up and down – more than the orange line. That means that investment is riskier as there are big swings in returns. Given the context clues of the graph and the fact that stocks are riskier than bonds, you can see that the blue line is stocks and the orange line is bonds.
                    </p>
                  </div>
            </Grid>
          </Grid>

          </div>
          )}
        </div>
      
    </Typography>
  );
};

BitCoinAnswerPopups.propTypes = {
  classes: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(BitCoinAnswerPopups);
