import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Component from './UI/component';
import data from './constants/existing-assets-liabilities.js'


const CompareExistingAssetsLiabilities = (props) => {
  const { classes, scenarioOne, scenarioTwo } = props;
  return (
    <div>
      {(scenarioOne.fed_sub_type_of_higher_education !== '0' ||
        scenarioTwo.fed_sub_type_of_higher_education !== '0') && (
        <div>
          { 
            data.map(parent => (
              <Component parent={parent} scenarioTwo={ scenarioTwo}  scenarioOne={ scenarioOne } classes= {classes }/>
            ))
          }
          </div>
        )}
    </div>
  );
};

CompareExistingAssetsLiabilities.propTypes = {
  classes: PropTypes.object.isRequired,
  scenarioOne: PropTypes.object.isRequired,
  scenarioTwo: PropTypes.object.isRequired
};
export default withStyles(styles)(CompareExistingAssetsLiabilities);
