import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Typography, Button, TextField, Dialog, DialogContent,
} from '@material-ui/core';
import classNames from 'classnames';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import ReactTooltip from 'react-tooltip';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { withStyles } from '@material-ui/core/styles';
import Select from 'react-select';
import Grid from '@material-ui/core/Grid';
import NumberFormat from 'react-number-format';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import compose from 'recompose/compose';
import { confirmAlert } from 'react-confirm-alert';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import styles from './styles';
import * as familyGraphData from '../../graph-services/family-show-me';
import sliderImageOne from '../../assets/img/family/financial-slider_new.jpg';
import sliderImageTwo from '../../assets/img/family/family-slide1.jpg';
import sliderImageThree from '../../assets/img/family/family-slide2.jpg';
import megamenu from '../../assets/img/financial-independence/mega-menu-pricing_img.png';
import infoIcon from '../../assets/img/financial-independence/info-icon.svg';
import infoIconWhite from '../../assets/img/higher-education/info-icon-contrast.png';
import childTax from '../../assets/img/family/child_tax_credit.png';
import MySavedNotes from './my-saved-notes';
import { initializeExcelSheetsData, workSheetCalc } from '../../calculations';
import * as moduleServices from '../../calculations/modules-services';
import * as timeLineActions from '../../calculations/time-line';
import PwiSave from '../../components/pwi-save/pwi-save';
import Pageloader from '../../components/ui/pageloader';
import SavePopupSucess from '../../components/common/save-popup-success';
import SimulatorPanel from '../../components/documents/simulator-panel';
import ModuleNavBar from '../../components/documents/module-nav-bar';
import { defaultValues } from '../../calculations/default-module-values';
import alertIcon from '../../assets/img/alert_icon.png';
import CostRaisingChild from './family-module-popup/cost-raising-child';
import PetBuyingCosts from './family-module-popup/pet-buying-cost';
import PetOwning from './family-module-popup/pet-owning';
import CompareScenarios from './compare-scenarios/compare-scenarios';
import messages from '../../utilities/pwi-popup-messages';
import * as pwiObject from '../../utilities/pwiObjectFormat';
import SummaryDocs from '../../components/documents/documents';
import LeaveDialogPopup from '../../components/pwi/pwi-left-menu/leave-dialog-popup';


import WalkThruOne from './wallk-thru/walkThruOne';
import WalkThruTwo from './wallk-thru/walkThruTwo';
import WalkThruThree from './wallk-thru/walkThruThree';
import WalkThruSeven from './wallk-thru/walkThruSeven';
import WalkThruEight from './wallk-thru/walkThruEight';
import WalkThruNine from './wallk-thru/walkThruNine';
import WalkThruTen from './wallk-thru/walkThruTen';
import WalkThruEleven from './wallk-thru/walkThruEleven';
import WalkThruTwelve from './wallk-thru/walkThruTwelve';
import IconBar from './IconBar';

import scrollToComponent from 'react-scroll-to-component';

const MODULE_DATA = loader('../../graphql/schema/fc/moduleData.graphql');
const SAVESCENARIO = loader('../../graphql/schema/pwi/save.graphql');

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
  colors: ['#5B9BD5', '#A5A5A5', '#FFC000', '#4472C4', '#70AD47', '#255E91', '#9E480E', '#636363', '#997300', '#264478', '#43682B', '#7CAFDD', '#F1975A', '#B7B7B7', '#FFCD33', '#698ED0'],
});

const childCountOption = [];
for (let c = 0; c <= 7; c += 1) {
  childCountOption.push({ label: c, value: c });
}
const dropdownIntialValues = {
  showMe: false,
  awareness: false,
  compareSavedNotes: false,
  savedNotes: false,
  faq: false,
  definations: false,
  quickLinks: false,
  showmeGraph: false,
};

HCExporting(Highcharts);

const FamilyWorksheet = (props) => {
  const { classes, history } = props;
  const [dropdownvalues, setDropdownValues] = useState(dropdownIntialValues);
  const [showMeHoverActive, setShowMeHoverActive] = useState(false);
  const [awareHoverActive, setAwareHoverActive] = useState(false);
  const [compareHoverActive, setCompareHoverActive] = useState(false);
  const [savedHoverActive, setSavedHoverActive] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [summaryDocumentsOpen, setSummaryDocumentsOpen] = useState(false);
  const [loadScenarioName, setLoadScenarioName] = useState({});
  const [updatedFamilyValues, setUpdatedFamilyValues] = useState(defaultValues.module10);
  const [modalType, setModalType] = useState('');
  const [childrenCountData, setChildrenCountData] = useState([]);
  const [moduleObjectParams, setmoduleObjectParams] = useState({});
  const [loading, setLoading] = React.useState(true);
  const [yearOption, setYearOption] = React.useState([]);
  const [openPopup, setOpenPopup] = React.useState('');
  const [occupationYears, setOccupationYears] = React.useState([{ label: 0, value: 0 }]);
  const [showMeData, setShowMeData] = React.useState({});
  const [moreOccupationYears, setMoreOccupationYears] = React.useState([{ label: 0, value: 0 }]);
  const ordinalText = ['', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh'];
  const ordinalTextDash = ['', 'first_born', 'second_born', 'third_born', 'fourth_born', 'fifth_born', 'sixth_born', 'seventh_born'];
  const [openDocsPopup, setOpenDocsPopup] = React.useState('');
  const [beforeLeavePopup, setBeforeLeavePopup] = React.useState({ 'popupStatus': false });
  const [activeScenarioData, setActiveScenarioData] = React.useState({});
  const [clearModule, setClearModule] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState(defaultValues.module10);

  const [completedWalkThruSlides, setCompletedWalkThruSlides] = React.useState({});
  const [activeWalkThruSlide, setActiveWalkThruSlide] = useState(0);
  const [backgroundBlur, setBackgroundBlur] = useState(false);
  const [slidecount, setSlideCount] = useState('');
  const [walkThruNo, setWalkThruNo] = useState('');
  const [loadValue, setLoadValue] = React.useState(false);
  const [notesSuccessMsg, SetNotesSuccessMsg] = useState(false);

  useEffect(() => {
    setWalkThruNo(12);
  }, []);

  if (localStorage.getItem('walkthruopen') === 'true' || localStorage.getItem('walkthruopen') === true) {
    setActiveWalkThruSlide(1);
    setBackgroundBlur(true);
    localStorage.removeItem('walkthruopen');
  }

  const generateYearRange = () => {
    const startYear = moduleServices.module1Data.start_year;
    let currentYear = new Date().getFullYear();
    if (startYear > 0) {
      currentYear = startYear;
    }
    const yearRange = [];
    for (let year = currentYear; year < (currentYear + 14); year += 1) {
      if (currentYear === year) {
        yearRange.push({ label: 0, value: 0 });
      }
      yearRange.push({ label: year, value: year });
    }
    setYearOption(yearRange);
  };

  const openFiPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>Important Tip</h3>
            <div className={classes.NoFiDiv}>
              <span><img src={alertIcon} alt="" /></span>
              <p>Please complete the Financial Independence module before moving to any other modules. The Financial Independence module will set the 5, 10, 15 year timeline for all other modules.</p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => { onClose(); history.push('/financial-independence'); }}
            >
              Go to Financial Independence module
            </button>
            <button type="button" onClick={onClose}>Explore this module</button>
          </div>
        </div>
      ),
    });
  };

  const [getModuleData] = useLazyQuery(MODULE_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      clear: clearModule === true ? 'module10' : ''
    },
    onCompleted(response) {
      timeLineActions.assignModulesData(response.getModuleData, (data) => {
        if (data !== '' && data.scenario_id !== undefined) {
          setUpdatedFamilyValues(data);
          setDefaultData(data);
          if (data.number_of_children > 0) {
            const countData = [];
            for (let i = 1; i <= parseInt(data.number_of_children, 10); i += 1) {
              countData.push({ id: i, value: ordinalTextDash[i] });
            }
            setChildrenCountData(countData);
          }
          const startYear = moduleServices.module1Data.start_year;
          if (data.marriege_year > 0) {
            const yearRange = [];
            yearRange.push({ label: 0, value: 0 });
            for (let year = data.marriege_year; year < (startYear + 14); year += 1) {
              yearRange.push({ label: year, value: year });
            }
            setOccupationYears(yearRange);
          }
          if (data.end_year > 0) {
            const yearRange = [];
            yearRange.push({ label: 0, value: 0 });
            for (let year = data.end_year; year < (startYear + 14); year += 1) {
              yearRange.push({ label: year, value: year });
            }
            setMoreOccupationYears(yearRange);
          }
          const index = _.findIndex(response.getModuleData.scenarios, { id: data.scenario_id });
          if (index >= 0) {
            setActiveScenarioData(response.getModuleData.scenarios[index]);
          } else {
            setActiveScenarioData({});
          }
        } else if (moduleServices.module1Data.start_year <= 0) {
          openFiPopup();
        } else {
          setActiveScenarioData({});
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
        localStorage.removeItem('compareScenario_data');
      });
    },
    onError() {
      timeLineActions.assignModulesData('', (data) => {
        if (data !== '') {
          setUpdatedFamilyValues(defaultValues.module10);
        }
        generateYearRange();
        setLoadValue(false);
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    getModuleData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    initializeExcelSheetsData();
    workSheetCalc();
  });

  const loadCompareScenario = JSON.parse(localStorage.getItem('compareScenario_data'));
  React.useEffect(() => {
    if (loadCompareScenario !== '' && loadCompareScenario !== undefined && loadCompareScenario !== null && loadCompareScenario.selected_module === 'module10' && loadCompareScenario.relation_year !== undefined) {
      dropdownvalues.compareSavedNotes = true;
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
    }
  }, [loadCompareScenario]);

  const myNotes = JSON.parse(localStorage.getItem('notes_data'));
  React.useEffect(() => {
    if (myNotes !== '' && myNotes !== undefined && myNotes !== null && myNotes.data.selected_module === 'module10') {
      dropdownvalues.savedNotes = true;
      scrollToComponent(document.getElementById('slide-eight'), { offset: -200, align: 'top', duration: 200 });
    }
  }, [myNotes]);

  const recallDataApi = (type) => {
    if (type === 'sketch-load' || type === 'clear-timeline' || type === 'scenario-load' || type === 'scenario-clear') {
      setLoading(true);
      getModuleData();
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleToggle = (type, subType) => {
    const dropValues = dropdownIntialValues;
    if (type === 'showme') {
      if (subType === 'showmegraph') {
        dropValues.showMe = true;
        dropValues.showmeGraph = true;
      } else {
        dropValues.showMe = !dropdownvalues.showMe;
      }
      if (dropValues.showmeGraph === true) {
        setShowMeData(familyGraphData.familyShowMeGraphData());
      }
    } else if (type === 'awareness') {
      if (subType === 'faq') {
        dropValues.awareness = true;
        dropValues.faq = !dropdownvalues.faq;
      } else if (subType === 'quickLinks') {
        dropValues.awareness = true;
        dropValues.quickLinks = !dropdownvalues.quickLinks;
        setTimeout(() => {
          scrollToComponent(document.getElementById('quickLinks'), { offset: -150, align: 'top' });
        }, 100);
      } else if (subType === 'definations') {
        dropValues.awareness = true;
        dropValues.definations = !dropdownvalues.definations;
      } else {
        dropValues.awareness = !dropdownvalues.awareness;
      }
    } else if (type === 'compareSavedNotes') {
      dropValues.compareSavedNotes = !dropdownvalues.compareSavedNotes;
    } else if (type === 'savedNotes') {
      dropValues.savedNotes = !dropdownvalues.savedNotes;
    }
    setDropdownValues(dropValues);
  };

  const handleEditPopup = (data) => {
    let changeAnnualCost = {};
    const valuesUpdated = { ...updatedFamilyValues };
    if (data === 'costraising') {
      changeAnnualCost = moduleServices.assumptionsData;
      if (typeof changeAnnualCost.Average !== 'undefined' && changeAnnualCost.Average !== '') {
        // valuesUpdated.averageCostRaisingChildNewValues = 'Yes';
        // console.log(changeAnnualCost);
        valuesUpdated.housing_cost = changeAnnualCost.Average.Housing;
        valuesUpdated.clothing_cost = changeAnnualCost.Average.Clothing;
        valuesUpdated.food_cost = changeAnnualCost.Average.Food;
        valuesUpdated.transportation_cost = changeAnnualCost.Average.Transportation;
        valuesUpdated.health_care_cost = changeAnnualCost.Average['Health Care'];
        valuesUpdated.child_care_and_education = changeAnnualCost.Average['Child Care and Education'];
        valuesUpdated.other_costs = changeAnnualCost.Average.Other;
      }
    } else if (data === 'petCost') {
      changeAnnualCost = moduleServices.expenseCalculationsData;
      // valuesUpdated.buyingPetNewValues = 'Yes';
      valuesUpdated.default_pet_first_vet_visit = changeAnnualCost['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['First Vet Visit'].Costs;
      valuesUpdated.default_pet_new_supplies = changeAnnualCost['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['New Supplies'].Costs;
      valuesUpdated.default_pet_miscellaneous = changeAnnualCost['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs']['Apartment Pet Fee'].Costs;
    } else if (data === 'petowning') {
      changeAnnualCost = moduleServices.expenseCalculationsData;
      // valuesUpdated.annualPetNewValues = 'Yes';
      valuesUpdated.default_annual_pet_vet_visits = changeAnnualCost['Yearly Costs']['Vet Visits'].Costs;
      valuesUpdated.default_annual_pet_food = changeAnnualCost['Yearly Costs'].Food.Costs;
      valuesUpdated.default_annual_pet_toys = changeAnnualCost['Yearly Costs'].Toys.Costs;
      valuesUpdated.default_annual_pet_emergency_fund = changeAnnualCost['Yearly Costs']['Emergency Fund'].Costs;
      valuesUpdated.default_annual_pet_health_insurance = changeAnnualCost['Yearly Costs']['Pet Health Insurance'].Costs;
      valuesUpdated.default_annual_pet_registration = changeAnnualCost['Yearly Costs'].Registration.Costs;
      valuesUpdated.default_annual_pet_miscellaneous = changeAnnualCost['Yearly Costs'].Miscellaneous.Costs;
    }
    // setUpdatedFamilyValues(valuesUpdated);
    setPopupOpen(true);
    setModalType(data);
  };

  const updateValue = (e, field, fieldType) => {
    const valuesUpdated = { ...updatedFamilyValues };
    if (fieldType === 'number') {
      valuesUpdated[field] = e.floatValue !== undefined ? e.floatValue : 0;
      if (field === 'spouse_ideal_repayment_schedule' && valuesUpdated[field] > 360) {
        valuesUpdated[field] = 360;
      } else if (field === 'spouses_interest_rate' && valuesUpdated[field] > 100) {
        valuesUpdated[field] = 100;
      }
    } else if (fieldType === 'input' || field === 'occupation') {
      valuesUpdated[field] = e.target.value;
    } else if (fieldType === 'input') {
      valuesUpdated[field] = e.floatValue;
    } else if (fieldType === 'select') {
      if (field === 'number_of_children') {
        valuesUpdated[field] = e.value;
        const countData = [];
        for (let i = 1; i <= parseInt(e.value, 10); i += 1) {
          countData.push({ id: i, value: ordinalTextDash[i] });
        }
        setChildrenCountData(countData);
      } else {
        valuesUpdated[field] = e.value;
        if (field === 'marriege_year') {
          if (e.value === 0) {
            valuesUpdated.occupation = '';
            valuesUpdated.start_year = 0;
            valuesUpdated.end_year = 0;
            valuesUpdated.spouse_income = 0;
            valuesUpdated.spouse_bonus_or_tips_or_commission = 0;
            valuesUpdated.second_occupation = '';
            valuesUpdated.second_start_year = 0;
            valuesUpdated.second_end_year = 0;
            valuesUpdated.second_spouse_income = 0;
            valuesUpdated.second_spouse_bonus_or_tips_or_commission = 0;
            setOccupationYears([{ label: 0, value: 0 }]);
          } else {
            const startYear = moduleServices.module1Data.start_year;
            const yearRange = [];
            for (let year = e.value; year < (startYear + 14); year += 1) {
              if (e.value === year) {
                yearRange.push({ label: 0, value: 0 });
              }
              yearRange.push({ label: year, value: year });
            }
            setOccupationYears(yearRange);
          }
        } else if (field === 'start_year' || field === 'end_year') {
          if (field === 'start_year' && valuesUpdated.end_year > 0 && valuesUpdated.start_year > valuesUpdated.end_year) {
            valuesUpdated.end_year = valuesUpdated.start_year;
          } else if (valuesUpdated.start_year <= 0) {
            valuesUpdated.end_year = 0;
          }
          if (valuesUpdated.end_year > 0) {
            const startYear = moduleServices.module1Data.start_year;
            const yearRange = [];
            yearRange.push({ label: 0, value: 0 });
            for (let year = valuesUpdated.end_year; year < (startYear + 14); year += 1) {
              yearRange.push({ label: year, value: year });
            }
            setMoreOccupationYears(yearRange);
            if (yearRange.length <= 1) {
              valuesUpdated.second_occupation = '';
              valuesUpdated.second_start_year = 0;
              valuesUpdated.second_end_year = 0;
              valuesUpdated.second_spouse_income = 0;
              valuesUpdated.second_spouse_bonus_or_tips_or_commission = 0;
            }
          } else {
            valuesUpdated.second_occupation = '';
            valuesUpdated.second_start_year = 0;
            valuesUpdated.second_end_year = 0;
            valuesUpdated.second_spouse_income = 0;
            valuesUpdated.second_spouse_bonus_or_tips_or_commission = 0;
            setMoreOccupationYears([{ label: 0, value: 0 }]);
          }
        }
      }
    }
    // console.log('v',valuesUpdated);
    moduleServices.setModule10Data(valuesUpdated);
    setUpdatedFamilyValues(valuesUpdated);
  };

  const handleResetValue = () => {
    const resetObject = { ...updatedFamilyValues };
    resetObject.housing_cost = 3680;
    resetObject.food_cost = 2202;
    resetObject.transportation_cost = 1912;
    resetObject.clothing_cost = 718;
    resetObject.health_care_cost = 1188;
    resetObject.child_care_and_education = 2118;
    resetObject.other_costs = 976;
    setUpdatedFamilyValues(resetObject);
  };

  const handleSaveScenario = (data) => {
    const objectParams = {};
    objectParams.type = (data === 'loadNotes') ? 'load' : 'save';
    objectParams.sketchName = 'Folder';
    objectParams.module = 'module10';
    objectParams.moduleNumber = 'module10Data';
    objectParams.categeory = (data === 'saveNotes') || (data === 'loadNotes') ? 'My Saved Notes' : 'My Scenarios';
    objectParams.filesHeader = (data === 'saveNotes') || (data === 'loadNotes') ? 'Note Name' : 'Scenarios';
    if (data === 'saveNotes') {
      objectParams.content = loadScenarioName ? loadScenarioName.content : '';
    }
    setmoduleObjectParams(objectParams);
    setModalType('save');
    setPopupOpen(true);
  };

  const closeBeforeLeavePopup = () => {
    setBeforeLeavePopup({ 'popupStatus': false })
  }

  /*
    **
      Clear scenario Start
    **
  */
  const clearEntireScenario = () => {
    const popupdetails = messages('clearworksheetScenario');
    setBeforeLeavePopup({
      ...{
        popupStatus: true,
        type: 'clear-worksheet',
        classVal: 'clearConfirmDialog'
      }, ...popupdetails
    })
  }

  const clearScenario = () => {
    setLoading(true);
    closeBeforeLeavePopup();
    setClearModule(true);
    getModuleData()
  }
  /*
    **
      Clear scenario End
    **
  */

  const [saveScenarios] = useMutation(SAVESCENARIO, {
    onCompleted({
      saveScenario: {
        id,
        status,
        message,
      },
    }) {
      if (status) {
        setLoadValue(true);
        getModuleData();
      }
    },
    onError(errors) {
      setLoading(false)
    },
  });

  const handleQuickSave = () => {
    if (activeScenarioData && activeScenarioData.inkwiry_scenario === 0) {
      let finalObject = {};
      finalObject['folder_id'] = activeScenarioData.directory_id;
      finalObject['id'] = activeScenarioData.id;
      finalObject['module'] = "module10";
      finalObject['relation_year'] = moduleServices.module1Data.start_year;
      finalObject['scenario_name'] = timeLineActions.generateScenarioName('module10', 'quick-save', activeScenarioData);
      finalObject['module10Data'] = pwiObject.getfilteredPWIFamily(moduleServices.module10Data);
      setLoading(true)
      saveScenarios({
        variables: {
          data: finalObject,
        },
      });
    } else {
      const popupdetails = messages('quickSaveInkwiryScenario');
      setBeforeLeavePopup({
        ...{
          popupStatus: true,
          type: 'save-inkwiry-scenario',
        }, ...popupdetails
      })
    }
  }

  useEffect(() => {
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 200 });
    }
  }, [activeWalkThruSlide]);

  const handleWalkThrus = (slide) => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    let updatedValue = 0;
    updatedValue = activeWalkThruSlide - 1;
    setActiveWalkThruSlide(slide + 1);
    completedWalkSlides[updatedValue] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    if (activeWalkThruSlide === 1) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -250, align: 'top', duration: 500 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 2) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4 && activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -400, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 700, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-six'), { offset: -120, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 480, align: 'middle', duration: 500 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: -420, align: 'middle', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setSlideCount('');
    }
  }

  const handleWalkThruClose = () => {
    const completedWalkSlides = { ...completedWalkThruSlides };
    completedWalkSlides[activeWalkThruSlide - 1] = true;
    setCompletedWalkThruSlides(completedWalkSlides);
    setActiveWalkThruSlide(0);
    setSlideCount('');
    setBackgroundBlur(false);
    const dropValues = dropdownIntialValues;
    dropValues.awareness = false;
    dropValues.savedNotes = false;
    setDropdownValues(dropValues);

  };

  const handleWalkThrusBack = (slide) => {
    setActiveWalkThruSlide(slide - 1);
    setBackgroundBlur(true);
    if (activeWalkThruSlide === 2) {
      scrollToComponent(document.getElementById('slide-three'), { offset: -50, align: 'top', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 3) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 4) {
      setBackgroundBlur(true);
      setSlideCount(1);
    }
    if (activeWalkThruSlide === 5) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 6) {
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 7) {
      scrollToComponent(document.getElementById('slide-one'), { offset: -130, align: 'bottom', duration: 0 });
      setBackgroundBlur(false);
      setSlideCount('');
    }
    if (activeWalkThruSlide === 8) {
      scrollToComponent(document.getElementById('slide-four'), { offset: -410, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(2);
    }
    if (activeWalkThruSlide === 9) {
      scrollToComponent(document.getElementById('slide-five'), { offset: 450, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(3);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 10) {
      scrollToComponent(document.getElementById('slide-six'), { offset: 460, align: 'bottom', duration: 500 });
      setBackgroundBlur(true);
      setSlideCount(4);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = true;
      dropValues.quickLinks = !dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 11) {
      scrollToComponent(document.getElementById('slide-seven'), { offset: 440, align: 'bottom', duration: 200 });
      setSlideCount(5);
      setBackgroundBlur(true);
      const dropValues = dropdownIntialValues;
      dropValues.awareness = false;
      dropValues.quickLinks = dropdownvalues.quickLinks;
      setDropdownValues(dropValues);
    }
    if (activeWalkThruSlide === 12) {
      scrollToComponent(document.getElementById('slide-eight'), { offset: 500, align: 'bottom', duration: 200 });
      const dropValues = dropdownIntialValues;
      dropValues.savedNotes = !dropdownvalues.savedNotes;
      setDropdownValues(dropValues);
      setBackgroundBlur(true);
      setSlideCount(6);
    }
  };

  // console.log('md',moduleServices.module10Data);

  return (
    <Typography variant="body1" component="div" className={backgroundBlur ? classNames(classes.pwiAnimation, classes.pwiAnimationActive) : ''} >
      {loading && <Pageloader loading={loading} />}
      <div className={classes.fiWorksheet}>
        {activeWalkThruSlide === 1 && (<WalkThruOne walkThruNo={walkThruNo} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        {activeWalkThruSlide === 12 && (<WalkThruTwelve walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
        <div className={classes.familySlider} id="slide-three">
          <div className={classes.testimonilas}>
            <div className={classes.testimonialInner}>
              <Slider {...settings}>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Family
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Review your details
                              </Typography>
                              <ul>
                                <li>Review all your family details in one place</li>
                                <li>Change inputs and see results instantly</li>
                                <li>Gain a visual understanding of your details with intelligent charts, graphs and interactive tables</li>
                                <li>Compare scenarios side-by-side and find your best move</li>
                                <li>Collaborate with family, friends and trusted colleagues by sharing your scenario</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>ENTER MY START YEAR</span>

                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageOne} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Family
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Plan and Prepare
                              </Typography>
                              <ul>
                                <li>How might a family change your financial situation?</li>
                                <li>Weigh the financial benefits and requirements</li>
                                <li>Know what to expect</li>
                                <li>See how envisioning your ideal scenario can help align your goals and make them reality.</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>Get Started</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageTwo} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
                <div className={classes.testimonialInner}>
                  <Container className={classes.container}>
                    <div>
                      <div className={classes.tstimonialContent}>
                        <div className={classes.sliderHeader}>
                          <Typography variant="h1" component="h1">
                            Family
                          </Typography>
                        </div>
                        <div className={classes.sliderContent}>
                          <Grid container spacing={4}>
                            <Grid item sm={6}>
                              <Typography variant="h2" component="h2">
                                Check Spaces, Awareness
                              </Typography>
                              <ul>
                                <li>How would your preferred family scenario affect your personal and professional priorities?</li>
                                <li>Understand the huge responsibilities that come with family life</li>
                                <li>What is most important to you, and how can you make it a reality?</li>
                              </ul>
                              <span aria-hidden="true" className={classNames(classes.startYear, classes.buttonGreen)}>SEE MY SPACES</span>
                            </Grid>
                            <Grid item sm={6}>
                              <div className={classNames(classes.sliderImage, classes.investImage)}>
                                <img src={sliderImageThree} alt="banner" />
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <section className={classes.fiContentSection}>
          <Container className={classes.container}>
            <div className={classes.fiRow}>
              <ReactTooltip id="leftTooltip" place="left" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.leftTooltip)} />
              <ReactTooltip id="puzzleTooltip" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.puzzleTooltip)} />
              <ModuleNavBar moduleName="module10" slidecount={slidecount} />
              {activeWalkThruSlide === 3 && (<WalkThruThree walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              <div className={classes.detailsSection}>
                <Grid container spacing={4}>
                  <Grid item sm={8} xs={8} className={classes.relativeBlock}>
                    <Typography variant="h1" component="h1">
                      My Details
                    </Typography>
                    {activeWalkThruSlide === 2 && (<WalkThruTwo walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                  </Grid>
                  <Grid item sm={4} xs={4} className={classes.leftIconPuzzle}>
                    <Link to="/plan-with-inkwiry/family" ><img src={megamenu} alt="existing" data-for="puzzleTooltip" data-tip="Switch to Plan With Inkwiry" /></Link>
                  </Grid>
                </Grid>
              </div>
              <div className={slidecount === 2 ? classNames(classes.detailsMainBlock, classes.detailsPopupScroll, classes.familyDetails) : classes.detailsMainBlock} id="slide-one">
                <IconBar slidecount={slidecount} activeWalkThruSlide={activeWalkThruSlide} walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides}
                  activeScenarioData={activeScenarioData} handleQuickSave={handleQuickSave} setOpenPopup={setOpenPopup}
                  setSummaryDocumentsOpen={setSummaryDocumentsOpen} summaryDocumentsOpen={summaryDocumentsOpen} setOpenDocsPopup={setOpenDocsPopup}
                  clearEntireScenario={clearEntireScenario} classes={classes} history={history} handleSaveScenario={handleSaveScenario}
                />
                <div className={slidecount === 2 ? classNames(classes.detailsPortion, classes.scaleOpacityHeight) : classes.detailsPortion} id="slide-four">
                  <div className={classes.detailsRow}>
                    <ReactTooltip id="detailsTooltip" place="top" type="info" effect="solid" className={classes.tooltipInfoTop} />
                    <Grid container spacing={4} className={classes.scalePortion}>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsLeftBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Family</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Marriage Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'marriege_year', 'select'); }}
                                              value={{ value: updatedFamilyValues.marriege_year, label: updatedFamilyValues.marriege_year }}
                                              options={yearOption}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Marriage is a big step and here's where you can plan for that milestone in your life. Input the year you plan on getting married. If already married, just input today's year. See what kind of resources you'll have when you go to begin this journey.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Wedding Costs</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} decimalScale={0} allowNegative={false} customInput={TextField} thousandSeparator value={updatedFamilyValues.wedding_costs} onValueChange={(e) => updateValue(e, 'wedding_costs', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The amount of money you expect to spend for your wedding or honeymoon or even on an engagement ring.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>
                                  Spouse&apos;s Career
                                  <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="To add Spouse's Career to your scenario, you have to enter a marriage year. Once you enter a marriage year, Spouse's Career will become available.">
                                    <img src={infoIcon} alt="infoicon" />
                                  </div>
                                </h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Occupation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} disabled={updatedFamilyValues.marriege_year <= 0} id="standard-basic" value={updatedFamilyValues.occupation} onChange={(e) => updateValue(e, 'occupation', 'input')} onFocus={(e) => e.target.select()} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'start_year', 'select'); }}
                                              value={{ value: updatedFamilyValues.start_year, label: updatedFamilyValues.start_year }}
                                              options={occupationYears}
                                              placeholder="Please select"
                                              isDisabled={updatedFamilyValues.marriege_year <= 0}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect your spouse to start the planned career move. If you plan for your spouse to already be working, then this input should be the same as the marriage year.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>Years</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'end_year', 'select'); }}
                                              value={{ label: updatedFamilyValues.end_year, value: updatedFamilyValues.end_year }}
                                              options={occupationYears}
                                              placeholder="Please select"
                                              isDisabled={updatedFamilyValues.marriege_year <= 0}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect your spouse to stop working this job.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>{moduleServices.module10Data.careerSpouseYearDiff}</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Spouse&apos;s Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} decimalScale={0} disabled={updatedFamilyValues.marriege_year <= 0} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFamilyValues.spouse_income} onValueChange={(e) => updateValue(e, 'spouse_income', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much income your spouse will receive from this job excluding any Bonus / Tips / Commission. This would be the salary or hourly wage multiplied by expected hours of work.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Spouse&apos;s Bonus / Tips / Commission</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} decimalScale={0} disabled={updatedFamilyValues.marriege_year <= 0} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFamilyValues.spouse_bonus_or_tips_or_commission} onValueChange={(e) => updateValue(e, 'spouse_bonus_or_tips_or_commission', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Depending on the career, there may be an opportunity to earn Bonus / Tips / Commission. Use Inkwiry's calculator to run some quick calculations. See the Boost My Awareness section for more information on this input.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Pre-Tax Expected Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.preTaxExpectedIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.preTaxExpectedIncome} prefix={moduleServices.module10Data.preTaxExpectedIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.preTaxExpectedIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Pre-Tax Expected Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.totalPreTaxExpectedIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.totalPreTaxExpectedIncome} prefix={moduleServices.module10Data.totalPreTaxExpectedIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.totalPreTaxExpectedIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>
                                  Spouse&apos;s Career Advancement
                                  <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Spouse's Career Advancement is dependent on Spouse's Career inputs above. If Spouse's Career either a) spans every year from marriage to the end of the 15 year period or b) is not active yet, Spouse's Career Advancement will not be available.">
                                    <img src={infoIcon} alt="infoicon" />
                                  </div>
                                </h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Occupation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} disabled={moreOccupationYears.length < 2} id="standard-basic" value={updatedFamilyValues.second_occupation} onChange={(e) => updateValue(e, 'second_occupation', 'input')} onFocus={(e) => e.target.select()} />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Start Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'second_start_year', 'select'); }}
                                              value={{ value: updatedFamilyValues.second_start_year, label: updatedFamilyValues.second_start_year }}
                                              options={moreOccupationYears}
                                              placeholder="Please select"
                                              isDisabled={moreOccupationYears.length < 2}
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect your spouse to start this planned career advancement.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>Years</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>End Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classNames(classes.selectBlock, classes.optionsBlock)}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'second_end_year', 'select'); }}
                                              value={{ value: updatedFamilyValues.second_end_year, label: updatedFamilyValues.second_end_year }}
                                              options={moreOccupationYears}
                                              placeholder="Please select"
                                              isDisabled={moreOccupationYears.length < 2}
                                            />
                                            {' '}

                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you expect your spouse to stop working this job.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <span className={classes.rightText}>{moduleServices.module10Data.careerAdvancementSpouseYearDiff}</span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Spouse&apos;s Income</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} disabled={moreOccupationYears.length < 2} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFamilyValues.second_spouse_income} onValueChange={(e) => updateValue(e, 'second_spouse_income', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="How much income your spouse will receive from this job excluding any Bonus / Tips / Commission. This would be the salary or hourly wage multiplied by expected hours of work.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Spouse&apos;s Bonus / Tips / Commission</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} disabled={moreOccupationYears.length < 2} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFamilyValues.second_spouse_bonus_or_tips_or_commission} onValueChange={(e) => updateValue(e, 'second_spouse_bonus_or_tips_or_commission', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Depending on the career, there may be an opportunity to earn Bonus / Tips / Commission. Use Inkwiry's calculator to run some quick calculations. See the Boost My Awareness section for more information on this input. ">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Pre-Tax Expected Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>

                                            {moduleServices.module10Data.morePreTaxExpectedIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.morePreTaxExpectedIncome} prefix={moduleServices.module10Data.morePreTaxExpectedIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.morePreTaxExpectedIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Pre-Tax Expected Income</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.totalMorePreTaxExpectedIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.totalMorePreTaxExpectedIncome} prefix={moduleServices.module10Data.totalMorePreTaxExpectedIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.totalMorePreTaxExpectedIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <div className={classes.moduleTitleNew}>
                                <h3>Marriage Multiples - Monthly Living Expenses</h3>
                              </div>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Start Year</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>{moduleServices.module10Data.marriege_year}</span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.centerText)}>
                                          <span className={classes.noInputText}>X </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Starting in the Marriage Year, these multiples grow your expenses from your Career / Higher Education modules to account for your partner and you can see the effects in those modules or the Financial Statements. See the Boost My Awareness for more info.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>Married</div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Rent</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyRentMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyRentMultiplier} prefix={moduleServices.module10Data.familyRentMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyRentMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.rent_multiplier}
                                            onValueChange={(e) => updateValue(e, 'rent_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyRentMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyRentMultiplierCalc} prefix={moduleServices.module10Data.familyRentMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyRentMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Utilities</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyUtilitiesMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyUtilitiesMultiplier} prefix={moduleServices.module10Data.familyUtilitiesMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyUtilitiesMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.utilities_multiplier}
                                            onValueChange={(e) => updateValue(e, 'utilities_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyUtilitiesMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyUtilitiesMultiplierCalc} prefix={moduleServices.module10Data.familyUtilitiesMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyUtilitiesMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Food</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyFoodMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyFoodMultiplier} prefix={moduleServices.module10Data.familyFoodMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyFoodMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.food_multiplier}
                                            onValueChange={(e) => updateValue(e, 'food_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyFoodMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyFoodMultiplierCalc} prefix={moduleServices.module10Data.familyFoodMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyFoodMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Clothing & Personal Care</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyClothingMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyClothingMultiplier} prefix={moduleServices.module10Data.familyClothingMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyClothingMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                            {' '}

                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.clothing_multiplier}
                                            onValueChange={(e) => updateValue(e, 'clothing_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyClothingMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyClothingMultiplierCalc} prefix={moduleServices.module10Data.familyClothingMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyClothingMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Entertainment</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyEntertainmentMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyEntertainmentMultiplier} prefix={moduleServices.module10Data.familyEntertainmentMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyEntertainmentMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.entertainment_multiplier}
                                            onValueChange={(e) => updateValue(e, 'entertainment_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyEntertainmentMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyEntertainmentMultiplierCalc} prefix={moduleServices.module10Data.familyEntertainmentMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyEntertainmentMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Technology</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyTechnologyMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyTechnologyMultiplier} prefix={moduleServices.module10Data.familyTechnologyMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyTechnologyMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.technology_multiplier}
                                            onValueChange={(e) => updateValue(e, 'technology_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyTransportationMultiplier !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyTransportationMultiplier} prefix={moduleServices.module10Data.familyTransportationMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyTransportationMultiplier < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Transportation</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyTransportationMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyTransportationMultiplier} prefix={moduleServices.module10Data.familyTransportationMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyTransportationMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.transportation_multiplier}
                                            onValueChange={(e) => updateValue(e, 'transportation_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyTransportationMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyTransportationMultiplierCalc} prefix={moduleServices.module10Data.familyTransportationMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyTransportationMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Miscellaneous</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyMiscellaneousMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyMiscellaneousMultiplier} prefix={moduleServices.module10Data.familyMiscellaneousMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyMiscellaneousMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.miscellaneous_multiplier}
                                            onValueChange={(e) => updateValue(e, 'miscellaneous_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyMiscellaneousMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyMiscellaneousMultiplierCalc} prefix={moduleServices.module10Data.familyMiscellaneousMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyMiscellaneousMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Healthcare Costs other than Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplier} prefix={moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.health_insurance_costs_multiplier}
                                            onValueChange={(e) => updateValue(e, 'health_insurance_costs_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc} prefix={moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyHealthInsuranceOutsidePremiumMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4} className={classNames(classes.noSpace, classes.inputFamily)}>
                                      <Grid item sm={7} xs={7} >
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Health Insurance Premium</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.familyHealthInsurancePremiumMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyHealthInsurancePremiumMultiplier} prefix={moduleServices.module10Data.familyHealthInsurancePremiumMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyHealthInsurancePremiumMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classNames(classes.inputRight, classes.leftBorder)}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.health_insurance_premium_multiplier}
                                            onValueChange={(e) => updateValue(e, 'health_insurance_premium_multiplier', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={1}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.familyHealthInsurancePremiumMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.familyHealthInsurancePremiumMultiplierCalc} prefix={moduleServices.module10Data.familyHealthInsurancePremiumMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.familyHealthInsurancePremiumMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={10} xs={10} className={classes.inputBlock}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue, classes.calculatedValueTotal)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={7} xs={7} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Total Monthly Living Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={2} xs={2} className={classNames(classes.inputEmpty, classes.multipleText)}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.sumOfFamilyMultiplier !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.sumOfFamilyMultiplier} prefix={moduleServices.module10Data.sumOfFamilyMultiplier >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.sumOfFamilyMultiplier < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} />
                                    </Grid>
                                  </div>
                                </Grid>
                                <Grid item sm={2} xs={2} className={classes.calcBlock}>
                                  <div className={classNames(classes.calcText, classes.pl0)}>
                                    {moduleServices.module10Data.sumOfFamilyMultiplierCalc !== undefined ? (
                                      <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.sumOfFamilyMultiplierCalc} prefix={moduleServices.module10Data.sumOfFamilyMultiplierCalc >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.sumOfFamilyMultiplierCalc < 0 && ')'} />
                                    ) : ('$0')}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container className={classes.analysisSection}>
                                <div className={classes.analysisPortion}>
                                  <div className={classes.analysisHeading}>
                                    <div className={classes.analysisBlock}>
                                      {updatedFamilyValues.marriege_year > 0 && (
                                        <div>
                                          <h3>Marital Budget Summary Year
                                            {' '}
                                            {updatedFamilyValues.marriege_year}
                                            <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Don't forget that in the Financial Independence module you set Income Growth and Inflation assumptions that grow income and expenses each year. Check out the Income Statement for more details on the summary below.">
                                              <img src={infoIconWhite} alt="infoicon" />
                                            </div>
                                            {' '}
                                            {moduleServices.module10Data.MaritalIncomeStatementSummary}
                                          </h3>
                                        </div>
                                      )}
                                      {updatedFamilyValues.marriege_year <= 0 && (
                                        <h3>Marital Budget Summary</h3>
                                      )}
                                    </div>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}><strong>Spouse&apos;s Income Earned</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalSpouseIncomeMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalSpouseIncomeMain} prefix={moduleServices.module10Data.marritalSpouseIncomeMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalSpouseIncomeMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalSpouseIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalSpouseIncome} prefix={moduleServices.module10Data.marritalSpouseIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalSpouseIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}><strong>My Income Earned</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalOtherIncomeMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalOtherIncomeMain} prefix={moduleServices.module10Data.marritalOtherIncomeMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalOtherIncomeMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalOtherIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalOtherIncome} prefix={moduleServices.module10Data.marritalOtherIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalOtherIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Income Earned</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalTotalIncomeMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalTotalIncomeMain} prefix={moduleServices.module10Data.marritalTotalIncomeMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalTotalIncomeMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalTotalIncome !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalTotalIncome} prefix={moduleServices.module10Data.marritalTotalIncome >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalTotalIncome < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}><strong>Total Taxes Paid</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalTaxesPaidMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalTaxesPaidMain} prefix={moduleServices.module10Data.marritalTaxesPaidMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalTaxesPaidMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalTaxesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalTaxesPaid} prefix={moduleServices.module10Data.marritalTaxesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalTaxesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.Bluepadding)}><strong><i>Total Tax Rate</i></strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            <i>
                                              {moduleServices.module10Data.marritalTaxesRateMain !== undefined ? (
                                                <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalTaxesRateMain} prefix={moduleServices.module10Data.marritalTaxesRateMain >= 0 ? '' : '('} suffix={moduleServices.module10Data.marritalTaxesRateMain < 0 ? '%)' : '%'} />
                                              ) : ('0%')}
                                            </i>
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            <i>
                                              {moduleServices.module10Data.marritalTaxesRate !== undefined ? (
                                                <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalTaxesRate} prefix={moduleServices.module10Data.marritalTaxesRate >= 0 ? '' : '('} suffix={moduleServices.module10Data.marritalTaxesRate < 0 ? '%)' : '%'} />
                                              ) : ('0%')}
                                            </i>
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Living Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalExpensesPaidMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalExpensesPaidMain} prefix={moduleServices.module10Data.marritalExpensesPaidMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalExpensesPaidMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalExpensesPaid} prefix={moduleServices.module10Data.marritalExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Higher Education Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.higherEducationExpensesMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.higherEducationExpensesMain} prefix={moduleServices.module10Data.higherEducationExpensesMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.higherEducationExpensesMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.higherEducationExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.higherEducationExpenses} prefix={moduleServices.module10Data.higherEducationExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.higherEducationExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Children Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.childrenExpensesMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.childrenExpensesMain} prefix={moduleServices.module10Data.childrenExpensesMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.childrenExpensesMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.childrenExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.childrenExpenses} prefix={moduleServices.module10Data.childrenExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.childrenExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Pet Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.petExpensesMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.petExpensesMain} prefix={moduleServices.module10Data.petExpensesMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.petExpensesMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.petExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.petExpenses} prefix={moduleServices.module10Data.petExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.petExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Car Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalCarPaidMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalCarPaidMain} prefix={moduleServices.module10Data.marritalCarPaidMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalCarPaidMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalCarPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalCarPaid} prefix={moduleServices.module10Data.marritalCarPaid >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalCarPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Real Estate Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalRealEstatePaidMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalRealEstatePaidMain} prefix={moduleServices.module10Data.marritalRealEstatePaidMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalRealEstatePaidMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalRealEstatePaid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalRealEstatePaid} prefix={moduleServices.module10Data.marritalRealEstatePaid >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalRealEstatePaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Loan Payments</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalFinancingExpensesPaidMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalFinancingExpensesPaidMain} prefix={moduleServices.module10Data.marritalFinancingExpensesPaidMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalFinancingExpensesPaidMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.marritalFinancingExpensesPaid !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.marritalFinancingExpensesPaid} prefix={moduleServices.module10Data.marritalFinancingExpensesPaid >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.marritalFinancingExpensesPaid < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Total Annual Expenses</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.totalAnnualExpensesMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.totalAnnualExpensesMain} prefix={moduleServices.module10Data.totalAnnualExpensesMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.totalAnnualExpensesMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.totalAnnualExpenses !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.totalAnnualExpenses} prefix={moduleServices.module10Data.totalAnnualExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.totalAnnualExpenses < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Annual Cash Flow before Retirement Contributions</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.annualCashFlowBeforeRetirementContributionsMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.annualCashFlowBeforeRetirementContributionsMain} prefix={moduleServices.module10Data.annualCashFlowBeforeRetirementContributionsMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.annualCashFlowBeforeRetirementContributionsMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.annualCashFlowBeforeRetirementContributions !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.annualCashFlowBeforeRetirementContributions} prefix={moduleServices.module10Data.annualCashFlowBeforeRetirementContributions >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.annualCashFlowBeforeRetirementContributions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classNames(classes.spanText, classes.leftBluepadding)}>
                                            <strong>Retirement Contributions</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.retirementContributionsMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.retirementContributionsMain} prefix={moduleServices.module10Data.retirementContributionsMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.retirementContributionsMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.retirementContributions !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.retirementContributions} prefix={moduleServices.module10Data.retirementContributions >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.retirementContributions < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <div className={classes.horizontalLine} />
                                  <div className={classNames(classes.calculatedValue, classes.independenceGroup)}>
                                    <Grid container>
                                      <Grid item sm={6} xs={6} >
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            <strong>Net Cash Flow for Savings and Investments</strong>
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.netCashFlowForSavingsAndInvestmentsMain !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.netCashFlowForSavingsAndInvestmentsMain} prefix={moduleServices.module10Data.netCashFlowForSavingsAndInvestmentsMain >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.netCashFlowForSavingsAndInvestmentsMain < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                      <Grid item sm={3} xs={3} >
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.netCashFlowForSavingsAndInvestments !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.netCashFlowForSavingsAndInvestments} prefix={moduleServices.module10Data.netCashFlowForSavingsAndInvestments >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.netCashFlowForSavingsAndInvestments < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>

                                </div>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item sm={6} className={classes.detailsBlock}>
                        <div className={classes.detailsRightBlock}>
                          <div className={classes.scrollPortion}>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={12} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Spouse&apos;s Student Loan Debt</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Student Loan Balance</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFamilyValues.spouse_student_loan} onValueChange={(e) => updateValue(e, 'spouse_student_loan', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Estimate how much student loan debt might your spouse have when you get married.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>
                                            Ideal Repayment Schedule (in Months)
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFamilyValues.spouse_ideal_repayment_schedule} onValueChange={(e) => updateValue(e, 'spouse_ideal_repayment_schedule', 'number')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The amount of months that you would like to repay this student loan debt in. See how different repayment schedules can affect total interest. Check out the Boost My Awareness section for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>
                                            Interest Rate
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat
                                            className={classes.formInput}
                                            allowNegative={false}
                                            customInput={TextField}
                                            value={updatedFamilyValues.spouses_interest_rate}
                                            onValueChange={(e) => updateValue(e, 'spouses_interest_rate', 'number')}
                                            onFocus={(e) => e.target.select()}
                                            decimalScale={2}
                                          />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Estimate the interest rate on this student loan debt. Adjust this interest rate and see how that impacts the monthly payment. For more information on an interest rate, check out the Boost My Awareness section.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Monthly Payment (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.spouseMonthlyPaymentInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.spouseMonthlyPaymentInterest} prefix={moduleServices.module10Data.spouseMonthlyPaymentInterest >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.spouseMonthlyPaymentInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Annual Paid (Principal & Interest)</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.spouseAnnualPaidInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.spouseAnnualPaidInterest} prefix={moduleServices.module10Data.spouseAnnualPaidInterest >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.spouseAnnualPaidInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.spouseTotalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.spouseTotalInterest} prefix={moduleServices.module10Data.spouseTotalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.spouseTotalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Principal & Interest</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.spouseTotalPrincipalInterest !== undefined ? (
                                              <NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale className={classes.formInput} displayType="text" thousandSeparator value={moduleServices.module10Data.spouseTotalPrincipalInterest} prefix={moduleServices.module10Data.spouseTotalPrincipalInterest >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.spouseTotalPrincipalInterest < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Children</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Number of Children</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'number_of_children', 'select'); }}
                                              value={{ value: updatedFamilyValues.number_of_children, label: updatedFamilyValues.number_of_children }}
                                              options={childCountOption}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Ever dreamed of having a family? How many children will you have? What kind of life do you want for them? Input the years you plan on having each child below. If you already have children, input Today's Year below.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            {childrenCountData && childrenCountData.map((data) => (
                              <div className={classes.moduleRow}>
                                <Grid container>
                                  <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                    <div className={classes.inputGroup}>
                                      <Grid container spacing={4}>
                                        <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                          <div className={classes.insideSpan}>
                                            <div className={classes.spanText}>
                                              {ordinalText[data.id]}
                                              {' '}
                                              Born
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item sm={6} xs={6} className={classes.noSpace}>
                                          <div className={classNames(classes.inputRight, classes.selectBox)}>
                                            <div className={classes.selectBlock}>
                                              <Select
                                                onChange={(value) => { updateValue(value, data.value, 'select'); }}
                                                value={{ value: updatedFamilyValues[data.value], label: updatedFamilyValues[data.value] }}
                                                options={occupationYears}
                                                placeholder="Please select"
                                              />
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            ))}
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}>
                                            {updatedFamilyValues.first_born > 0 && (
                                              <strong>
                                                Annual Cost of Raising a Child in year
                                                {updatedFamilyValues.first_born}
                                              </strong>
                                            )}
                                            {updatedFamilyValues.first_born === 0 && (
                                              <strong>Annual Cost of Raising a Child</strong>
                                            )}
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.averageCostRaisingChild !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module10Data.averageCostRaisingChild} prefix={moduleServices.module10Data.averageCostRaisingChild >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.averageCostRaisingChild < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The average cost of housing, food, healthcare, transportation, clothing, child care and education, and other miscellaneous costs of raising a child to 18 years old. These costs automatically start in the years inputted above for children and are captured on the Income Statement. To edit these costs, click on the 'EDIT' button and change the assumptions.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <Button className={classes.editButton} onClick={() => { handleEditPopup('costraising'); }}>Edit</Button>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={6} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={12} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.moduleTitle}>
                                          <h3>Buying a Pet</h3>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>Purchase Year</div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.inputRight, classes.selectBox)}>
                                          <div className={classes.selectBlock}>
                                            <Select
                                              onChange={(value) => { updateValue(value, 'year_of_purchase', 'select'); }}
                                              value={{ label: updatedFamilyValues.year_of_purchase, value: updatedFamilyValues.year_of_purchase }}
                                              options={yearOption}
                                              placeholder="Please select"
                                            />
                                          </div>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="The year you want to purchase your new pet.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>
                                            What Type of Pet?
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} id="standard-basic" value={updatedFamilyValues.type_of_pet} onChange={(e) => updateValue(e, 'type_of_pet', 'input')} onFocus={(e) => e.target.select()} />

                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Want to own a pet some day? Here's where you can make that happen. What dog breed will you buy? Cat? Maybe something else? Whatever it is, put it down here and start planning for that dream.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>
                                            Name of Pet
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <TextField className={classes.formInput} id="standard-basic" value={updatedFamilyValues.pet_name} onChange={(e) => updateValue(e, 'pet_name', 'input')} onFocus={(e) => e.target.select()} />
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Going to need a name for that new member of the family.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classes.inputBlock}>
                                  <div className={classes.inputGroup}>
                                    <Grid container spacing={4}>
                                      <Grid item sm={6} xs={6} className={classes.noRightSpace}>
                                        <div className={classes.insideSpan}>
                                          <div className={classes.spanText}>
                                            Purchase Price
                                          </div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <NumberFormat className={classes.formInput} allowNegative={false} decimalScale={0} fixedDecimalScale customInput={TextField} thousandSeparator value={updatedFamilyValues.purchase_price} onValueChange={(e) => updateValue(e, 'purchase_price', 'number')} onFocus={(e) => e.target.select()} />
                                          <span className={classes.dollarSign}>$</span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Pets come in different shapes, colors, and sizes. Do a little research to find out what your dream pet might cost and if you're financially equipped to handle the cost of owning a pet. See the Income Statement for annual costs.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Pet Buying Costs</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.annualCostOfOwingPet !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module10Data.annualCostOfOwingPet} prefix={moduleServices.module10Data.annualCostOfOwingPet >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.annualCostOfOwingPet < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Purchase price plus the average cost of the following: first vet visit, new supplies, and other miscellaneous costs. See the Cash Flow Statement for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <Button className={classes.editButton} onClick={() => { handleEditPopup('petCost'); }}>Edit</Button>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.moduleRow}>
                              <Grid container>
                                <Grid item sm={12} xs={12} className={classNames(classes.inputBlock, classes.noBottomMargin)}>
                                  <div className={classNames(classes.inputGroup, classes.calculatedValue)}>
                                    <Grid container spacing={4} className={classes.noSpace}>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classNames(classes.insideSpan, classes.insideSpanText)}>
                                          <div className={classes.spanText}><strong>Total Yearly Cost of Owning a Pet</strong></div>
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} xs={6} className={classes.noSpace}>
                                        <div className={classes.inputRight}>
                                          <span className={classes.noInputText}>
                                            {moduleServices.module10Data.totalYearlyCostOfOwningAPet !== undefined ? (
                                              <NumberFormat decimalScale={0} fixedDecimalScale className={classes.formInput} displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module10Data.totalYearlyCostOfOwningAPet} prefix={moduleServices.module10Data.totalYearlyCostOfOwningAPet >= 0 ? '$' : '($'} suffix={moduleServices.module10Data.totalYearlyCostOfOwningAPet < 0 && ')'} />
                                            ) : ('$0')}
                                          </span>
                                          <div className={classes.infoIcon} data-for="detailsTooltip" data-tip="Purchase price plus the average cost of the following: first vet visit, new supplies, and other miscellaneous costs. See the Cash Flow Statement for more details.">
                                            <img src={infoIcon} alt="infoicon" />
                                          </div>
                                          <Button className={classes.editButton} onClick={() => { handleEditPopup('petowning'); }}>Edit</Button>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.cashFlowDiv}>
                              <Grid container spacing={0}>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  <div className={classes.whiteLeftBox}>
                                    <div className={classes.currencyTextNew}>
                                      <p>Cash Flow Check</p>
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item sm={6} xs={6} spacing={0}>
                                  {moduleServices.cashFlowCheckVal < 0 && (
                                    <div className={classes.cashFlowGreen}>
                                      <div>
                                        <span>Cash Flow Positive</span>
                                      </div>
                                    </div>
                                  )}
                                  {moduleServices.cashFlowCheckVal > 0 && (
                                    <div className={classes.cashFlowRed}>
                                      <div>
                                        <span>{moduleServices.cashFlowCheckVal}</span>
                                      </div>
                                    </div>
                                  )}
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                {activeWalkThruSlide === 7 && (<WalkThruSeven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
              </div>
            </div>
            <div className={slidecount === 3 ? classNames(classes.showMe, classes.showMeScroll, classes.familyShowMe) : classNames(classes.showMe, classes.showSection)} id="slide-five">
              <div className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 3 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('showme'); }} onMouseEnter={() => { setShowMeHoverActive(true); }} onMouseLeave={() => { setShowMeHoverActive(false); }}>
                      <i className={dropdownvalues.showMe ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Show Me</strong>
                      <strong className={(showMeHoverActive && !dropdownvalues.showMe) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Gain a Visual Understanding of Your Details with Charts, Graphs, and Tables</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 8 && (<WalkThruEight walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
                {(dropdownvalues.showMe && !dropdownvalues.showmeGraph) && (
                  <Button className={classes.graphButton} onClick={() => { handleToggle('showme', 'showmegraph'); }}>Show Graph</Button>
                )}
                {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (<Button className={classes.graphButton}>Refresh</Button>)}

              </div>
              {(dropdownvalues.showMe && dropdownvalues.showmeGraph) && (
                <div className={classes.sectionContent}>
                  {showMeData.graph1 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Marital Income Statement Summary</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph1Data}
                        />
                      </div>
                    </div>
                  )}
                  {showMeData.graph2 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Marital Living Expenses Summary</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph2Data}
                        />
                      </div>
                      <div className={classes.totalValues}>Total Marital Living Expenses: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.advValues} prefix='$' /></div>
                    </div>
                  )}
                  {showMeData.graph3 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Average Costs of Raising a Child to 15</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph3Data}
                        />
                      </div>
                      <div className={classes.totalValues}>Total Cost of Raising a Child to 15: <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={showMeData.totalValues} prefix='$' /></div>
                    </div>
                  )}
                  {showMeData.graph4 === true && (
                    <div className={classes.descriptionRow}>
                      <div className={classes.graphBlock}>
                        <h3>Spouse Student Loan</h3>
                        <hr className={classes.blueRow} />
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={showMeData.graph4Data}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={slidecount === 4 ? classNames(classes.showMe, classes.showMeScrollSix) : classNames(classes.showMe, classes.boostAwareness)} id="slide-six">
              <div className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('awareness'); }} onMouseEnter={() => { setAwareHoverActive(true); }} onMouseLeave={() => { setAwareHoverActive(false); }}>
                      <i className={dropdownvalues.awareness ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Boost My Awareness</strong>
                      <strong className={(awareHoverActive && !dropdownvalues.awareness) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Not sure of your details or seem overwhelmed? Don&apos;t worry - Inkwiry&apos;s QI, FAQs, Definitions, & Quick Links will provide all the information you need and more</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 9 && (<WalkThruNine walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.awareness && (
                <div className={classes.sectionContent}>
                  <div className={classes.awarenessBlock}>
                    <h3 className={classes.awarenessHeading}>
                      Inkwiry Sense
                      <sup>TM</sup>
                      {' '}
                      on Family
                    </h3>
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'faq'); }}>
                          <i className={dropdownvalues.faq ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>FAQ</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.faq && (
                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>How does the &apos;Marriage Multiplier for Monthly Living Expenses&apos; work?</h3>
                          <p>
                            When you get married, the FC enables you to increase your expenses to account for your partner. The FC assumes that starting in your marriage year, your monthly living expenses are increased or decreased by sharing expenses. So, we give you multiples to increase or decrease your monthly living expenses that you have already inputted in your career or higher education modules.
                            Remember, career or higher education modules drive your monthly living expenses and those inputs are independent from the family module. Easiest to explain all of this through an example.
                          </p>
                          <p>
                            Let&apos;s assume that in 2025, your food expense is $1,000 a month. And, let&apos;s assume that $1,000 a month is feeding from the Career Advancement module. You are planning on getting married in 2025.
                            What will that mean for your now combined monthly food expense? Most likely, it will go up. But, by how much? And, what about all the years after 2025? Do I have to go back and adjust every career or higher education module that happens after I start a family? The answer is no! And, here&apos;s where the beauty of a multiple comes into play.
                          </p>
                          <p>In the family module, you assign a 1.5x multiple to your monthly food expense. Beginning in your marriage year and continuing to the end of your 5, 10, 15 year plan, your monthly food expense will be multiplied by 1.5x. In our example, the 2025 calculation for annual food expense would be as follows:</p>
                          <p>$1,000 Monthly Food Expense * 1.5x Marriage Multiplier = $1,500 Monthly Food Expense * 12 Months = $18,000 Annual Food Expense</p>
                          <p>Enter your marriage multiples for your monthly living expenses. Those multiples will automatically update your expenses in all years that you are married.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC calculate taxes after Marriage? Does it take into account having kids?</h3>
                          <p>The FC assumes that you file jointly, as a married couple, for taxes starting the year you get married. You can see this by going to the Income Statement and clicking on your Federal Income Tax Rate in the year of your marriage or any year thereafter. The FC understands the tax brackets when single and when married and applies your Federal Taxable Income accordingly</p>
                          <p>The FC takes into account having children when calculating taxes. Depending on your household income, you may qualify for a child tax credit for each child you have. Check out the definitions to learn more about the child tax credit. Or, access the &apos;FC + Taxes&apos; document on the news page for a detailed walkthrough of how the FC estimates your taxes.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>How does the FC calculate the cost of having a child each year?</h3>
                          <p>The FC uses USDA data to estimate the current and future costs of raising a child. Each year, the FC grows the cost of having a child by taking the current estimated cost and multiplying it by annual inflation. Meaning, if you go to have a child in 10 years, the current estimated cost will have grown by the annual inflation rate for 10 years (just like all other expenses in the FC).</p>
                          <p>The FC makes these assumptions to help you in the planning process. If you want to change the default costs of having a child, just click on the &apos;EDIT&apos; button and make your own adjustments.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Do I need to raise my monthly living expenses to account for having children?</h3>
                          <p>You do not need to raise your monthly living expenses because you plan on having children or you do have children. Children have their own expense line on the Income Statement.</p>
                          <p>If you currently have kids, enter how many kids you have and the start year. Then, you can use the &apos;EDIT&apos; button to adjust the cost associated with your children.</p>
                          <p>If you don&apos;t have kids now, but plan on having them in the future, enter how many kids you&apos;d like to have and the proper start years. Then, you can use the &apos;EDIT&apos; button to adjust the cost associated with your children.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>What if my monthly expenses already include the costs of my children? What do I do then?</h3>
                          <p>
                            If your monthly living expenses in your career modules already include the costs of your children, then you need to use the &apos;EDIT&apos; button to change the costs of having a child to $0. It&apos;s important to note, you still need to input the number of children that you have in the My Details section. And, if you currently have children, you need to input the start year as well. That way,
                            the FC knows how many children you have and can more accurately estimate your taxes
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'definations'); }}>
                          <i className={dropdownvalues.definations ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Definitions</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.definations && (

                      <div className={classes.panelBody}>
                        <div className={classes.panelBlock}>
                          <h3>Bonus / Tips / Commission</h3>
                          <p>Additional compensation given to an employee above his/her base income and is awarded based on performance. Tips are compensation over and above payment due for service such as a waiter receiving tips for good service. Commission is additional compensation for completing a sale or providing a service and is usually calculated as a percentage of that sale or service like a real estate agent making a commission on a home sale.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Child Tax Credit</h3>
                          <p>A tax credit is a dollar-for-dollar reduction of the Federal Income Taxes that you owe. For example, if you owe $10,000 in Federal Income Taxes and qualify for a $2,000 tax credit, your income taxes would be reduced dollar-for-dollar and you would owe $8,000</p>
                          <p>
                            The Child Tax Credit is a $2,000 tax credit available for each dependent child. The FC assumes all children are dependent for this tax credit (see the higher education module&apos;s definitions for what qualifies as &apos;dependent&apos;). In addition to the tax credit, you may be entitled to a refund of up to 15% of your income in excess of $2,500 with a maximum refund set at $1,400.
                            Refundable means that if your child tax credits are greater than your federal income taxes, you might receive a cash refund. Let&apos;s take a look at three examples.
                            In example 1, you have 2 dependent children, $50,000 of income and $10,000 of federal income taxes. In example 2, you have 2 dependent children, $50,000 of income and $2,000 of federal income taxes. In example 3, you have 2 dependent children, $10,000 of income and $1,000 of federal income taxes. Review the table to see how each example differs in terms of taxes.
                          </p>
                          <div className={classNames(classes.galleryImage, classes.fullWidth)}>
                            <img src={childTax} alt="table" />
                          </div>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.thebalance.com/how-does-health-insurance-work-3306069" target="_blank" rel="noopener noreferrer">Health Insurance Costs outside Premium</a></h3>
                          <p>
                            These costs include paying your
                            {' '}
                            {' '}
                            <a href="https://www.healthcare.gov/glossary/deductible/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>deductible</a>
                            {' '}
                            and
                            {' '}
                            {' '}
                            <a href="https://www.investopedia.com/terms/c/copay.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>co-pay</a>
                            . A
                            {' '}
                            <a href="https://www.healthcare.gov/glossary/deductible/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>deductible</a>
                            {' '}
                            is the amount of money an individual pays for expenses before their insurance plan starts to pay. A
                            {' '}
                            <a href="https://www.investopedia.com/terms/c/copay.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>co-pay</a>
                            {' '}
                            is an out of pocket expense for health care services that is charged at the time the service is rendered.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.verywellhealth.com/health-insurance-premiums-1738769" target="_blank" rel="noopener noreferrer">Health Insurance Premium</a></h3>
                          <p>Just like a monthly car insurance premium or monthly home insurance premium, your health insurance premium is the monthly fee that you pay for your health insurance coverage. Even if you do not require any health services such as a doctor&apos;s visit or a surgery, you have to pay your monthly health insurance premium to maintain your coverage.</p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Occupation</h3>
                          <p>
                            A person&apos;s primary form of work that pays some form of
                            {' '}
                            {' '}
                            <a href="http://www.investopedia.com/terms/p/personalincome.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>income</a>
                            {' '}
                            such as a doctor, lawyer, nurse, plumber, or electrician.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Spouse&apos;s Income</h3>
                          <p>
                            Base
                            {' '}
                            {' '}
                            <a href="http://www.investopedia.com/terms/p/personalincome.asp" target="_blank" rel="noopener noreferrer" className={classes.noLink}>income</a>
                            {' '}
                            earned from your Spouse&apos;s employment on a salary or hourly basis, including expected overtime. This does not include any Bonus, Tips, Commission, or Supplementary Income.
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3><a href="https://www.costofwedding.com/" target="_blank" rel="noopener noreferrer">Wedding Costs</a></h3>
                          <p>
                            The amount of money you anticipate that you will
                            {' '}
                            {' '}
                            <a href="https://www.tripsavvy.com/wedding-statistics-and-honeymoon-facts-1860546" target="_blank" rel="noopener noreferrer" className={classes.noLink}>spend on your wedding, including the honeymoon.</a>
                            {' '}
                            These costs do not include the amount your parents, family, or anyone else may contribute - only the amount you expect to pay out of your own pocket.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className={classes.panelRow}>
                    <div className={classes.panelTitle}>
                      <h1 className={classes.pointerCursor}>
                        <span aria-hidden="true" onClick={() => { handleToggle('awareness', 'quickLinks'); }}>
                          <i className={dropdownvalues.quickLinks ? 'fa fa-minus' : 'fa fa-plus'} />
                          <span>Quick Links</span>
                        </span>
                      </h1>
                    </div>
                    {dropdownvalues.quickLinks && (
                      <div className={classes.panelBody} id="quickLinks">
                        <div className={classes.panelBlock}>
                          <h3>Family</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.gobankingrates.com/saving-money/average-wedding-cost-actually-nowhere-near-25000/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Wedding costs and what to expect</a>
                            <a href="https://taxfoundation.org/conference-report-tax-cuts-and-jobs-act/" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax reform and how it affects a family</a>
                            <a href="https://www.marketwatch.com/story/have-kids-5-ways-the-new-tax-law-affects-you-2018-02-21#false" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Tax deductions and credits for families</a>
                            <a href="https://www.usda.gov/media/blog/2017/01/13/cost-raising-child" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Average cost of raising a child</a>
                          </p>
                        </div>
                        <div className={classes.panelBlock}>
                          <h3>Occupation</h3>
                          <p className={classes.quickLinksText}>
                            <a href="https://www.npr.org/2018/10/01/649701669/the-american-dream-is-harder-to-find-in-some-neighborhoods" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How does your location affect the potential earnings of your children? Explore this interactive visualization to find out</a>
                            <a href="https://www.payscale.com/hub/US/Job" target="_blank" rel="noopener noreferrer" className={classes.noLink}>How much can you expect to make? Search for your career move and find out</a>
                            <a href="https://www.glassdoor.com/Salaries/index.htm" target="_blank" rel="noopener noreferrer" className={classes.noLink}>Another resource to find out how much money you could make with your next career move</a>
                          </p>
                        </div>
                      </div>

                    )}
                  </div>
                </div>
              )}
            </div>
            <div className={slidecount === 5 ? classNames(classes.showMe, classes.showMeScrollSeven, classes.careerAdvancementSavedScenareos) : classNames(classes.showMe, classes.saveScenario)} id="slide-seven">
              <div className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1 className={slidecount === 5 ? classes.opacityZero : ''}>
                    <span aria-hidden="true" onClick={() => { handleToggle('compareSavedNotes'); }} onMouseEnter={() => { setCompareHoverActive(true); }} onMouseLeave={() => { setCompareHoverActive(false); }}>
                      <i className={dropdownvalues.compareSavedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>Compare Saved Scenarios</strong>
                      <strong className={(compareHoverActive && !dropdownvalues.compareSavedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Analyze Scenarios Side by Side to Weigh Risk and Maximize Reward - See What Scenario Works Best For You</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 10 && (<WalkThruTen walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.compareSavedNotes && (
                <CompareScenarios moduleName="Family" moduleSelected="module10" getModuleData={getModuleData} setUpdatedScenario={setUpdatedFamilyValues} />
              )}

            </div>
            <div className={slidecount === 6 ? classNames(classes.showMe, classes.showMeScrollEight) : classNames(classes.showMe, classes.savedNotes)} id="slide-eight">
              <div className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.sectionHeader, classes.activeHover) : classes.sectionHeader}>
                <div className={classes.sectionTitle}>
                  <h1>
                    <span aria-hidden="true" onClick={() => { handleToggle('savedNotes'); }} onMouseEnter={() => { setSavedHoverActive(true); }} onMouseLeave={() => { setSavedHoverActive(false); }}>
                      <i className={dropdownvalues.savedNotes ? 'fa fa-minus' : 'fa fa-plus'} />
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.showText, classes.activeHide) : classes.showText}>My Saved Notes</strong>
                      <strong className={(savedHoverActive && !dropdownvalues.savedNotes) ? classNames(classes.hideText, classes.activeShow) : classes.hideText}>Your Journal to Write and Save Notes. Don&apos;t Let That Thought Go Until Next Time - Write It Down in Your Notes</strong>
                    </span>
                  </h1>
                  {activeWalkThruSlide === 11 && (<WalkThruEleven walkThruNo={walkThruNo} handleWalkThrusBack={handleWalkThrusBack} handleWalkThrus={handleWalkThrus} handleWalkThruClose={handleWalkThruClose} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} />)}
                </div>
              </div>
              {dropdownvalues.savedNotes && (
                <MySavedNotes handleSaveScenario={handleSaveScenario} loadScenarioName={loadScenarioName} setLoadScenarioName={setLoadScenarioName} />
              )}
            </div>
          </Container>
        </section>
        <div>
          <Dialog
            open={popupOpen}
            onClose={() => { setPopupOpen(false); }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={(modalType === 'save') ? classes.SavePopup : classNames(modalType === 'save' && classes.SavePopup, (modalType !== 'save') && classes.editPopup)}
          >
            <DialogContent className={classes.videoPopup}>
              {(modalType !== 'inflation' && modalType !== 'incomegrowth') && (
                <div className={classes.popupClose}>
                  <span aria-hidden="true" onClick={() => { setPopupOpen(false); }}>
                    X
                  </span>
                </div>
              )}

              <div>
                {(modalType === 'save') && (<PwiSave moduleParams={moduleObjectParams} getSaveData={updatedFamilyValues} setLoadScenarioName={setLoadScenarioName} setPopupOpen={setPopupOpen} setLoadValue={setLoadValue} SetNotesSuccessMsg={SetNotesSuccessMsg} />)}
                {modalType === 'costraising' && (<CostRaisingChild updatedFamilyValues={updatedFamilyValues} setUpdatedFamilyValues={setUpdatedFamilyValues} setModalType={setModalType} setPopupOpen={setPopupOpen} handleResetValue={handleResetValue} />)}
                {modalType === 'petCost' && (<PetBuyingCosts defaultData={defaultData} updatedFamilyValues={updatedFamilyValues} setUpdatedFamilyValues={setUpdatedFamilyValues} updateValue={updateValue} setModalType={setModalType} setPopupOpen={setPopupOpen} />)}
                {modalType === 'petowning' && (<PetOwning defaultData={defaultData} updatedFamilyValues={updatedFamilyValues} setUpdatedFamilyValues={setUpdatedFamilyValues} updateValue={updateValue} setModalType={setModalType} setPopupOpen={setPopupOpen} />)}
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <LeaveDialogPopup beforeLeavePopup={beforeLeavePopup} closeBeforeLeavePopup={closeBeforeLeavePopup} clearScenario={clearScenario} setLoading={setLoading} getModuleData={getModuleData} setOpenPopup={setOpenPopup} />
      {openPopup !== '' && (<SimulatorPanel setOpenPopup={setOpenPopup} popupStatus={openPopup} otherData={{ 'module': 'module10' }} recallDataApi={recallDataApi} />)}
      {openDocsPopup !== '' && (<SummaryDocs setOpenDocsPopup={setOpenDocsPopup} popupStatus={openDocsPopup} />)}
      <div className={classes.videoModalPopup}>
        <Dialog
          open={loadValue}
          onClose={() => { setLoadValue(false) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classNames(classes.pendinglistPopup, classes.csaveScenario)}
        >
          <DialogContent className={classes.videoPopup} >
            <SavePopupSucess setLoadValue={setLoadValue} />
          </DialogContent>
        </Dialog>
      </div>
      <Dialog
        open={notesSuccessMsg}
        onClose={() => { SetNotesSuccessMsg(false) }}
        className={classes.modelPopupRow}
      >
        <DialogContent className={classes.modelPopup}>
          <div className={classes.modelHeader}>
            <h3>My Saved Notes</h3>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>X</button>
          </div>
          <div className={classes.modelBody}>
            <p>Notes Saved Successfully</p>
          </div>
          <div className={classes.modelFooter}>
            <button onClick={() => { SetNotesSuccessMsg(false) }}>Cancel</button>
          </div>
        </DialogContent>
      </Dialog>
    </Typography>
  );
};

FamilyWorksheet.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(FamilyWorksheet);
