const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    boxSizing: 'border-box',
  },
  bondProfitPage: {
    background: '#e5f0f6',
    display: 'block',
  },
  breadCrumbs: {
    listStyle: 'none',
    display: 'inline-flex',
    padding: '20px 0',
    marginBottom: '0',
    width: '100%',
    fontFamily: 'MuseoSans-100',
    fontSize: '14px',
    marginTop: '0',
    lineHeight: '1.42857143',
    '& li': {
      color: '#000',
      '& a': {
        color: '#333',
        transition: 'all 0.5s ease 0s',
        mozTransition: 'all 0.5s ease 0s',
        webkitTransition: 'all 0.5s ease 0s',
        border: '0',
        outline: '0',
        textDecoration: 'underline',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
    '& span': {
      padding: '0 5px',
      fontSize: '18px',
      lineHeight: '18px',
      boxSizing: 'border-box',
    },
  },
  b: {
    fontWeight: '700',
    fontFamily: '"MuseoSans-500"',
  },
  infoIcon: {
    display: 'inline-block',
    width: '25px',
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '20px',
    }, 
    '& img': {
      verticalAlign: 'middle',
      marginTop: '0',
      maxWidth: '20px',
      cursor: 'pointer',
      [theme.breakpoints.down( 'sm' )]: {
        maxWidth: '18px',
      }, 
    },
  },
  csBox: {
    background: '#fff',
    padding: '35px',
    boxSizing: 'border-box',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '10px',
    },   
  },
  blueBorder: {
    border: '2px solid #0069aa',
    padding: '3px 20px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '3px 12px',
    },  
  },
  csBreadcrumbs: {
    borderBottom: '4px solid #84a84d',
    position: 'relative',
    marginTop: '0',
    marginBottom: '0',
    padding: '10px 2px',
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down( 'xs' )]: {
      flexWrap: 'wrap',
    }, 
    '& div': {
      width: '50%',
      '&:first-child': {
        '@media (max-width: 767px)': {
          width: '60%',
        },
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
          marginBottom: '10px',
        }, 
      },
      '&:last-child': {
        '@media (max-width: 767px)': {
          width: '40%',
        },
        [theme.breakpoints.down( 'xs' )]: {
          width: '100%',
        }, 
      },      
    },
  },
  csTitle: {
    color: '#0069aa',
    fontSize: '26px',
    margin: '0',
    paddingBottom: '0',
    fontWeight: '300',
    lineHeight: '1.1',
    fontFamily: 'MuseoSans-300',
    '@media (max-width: 767px)': {
      fontSize: '18px',
    }, 
  },
  csLogo: {
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down( 'xs' )]: {
      float: 'none',
      justifyContent: 'center',
    }, 
    '& h3': {
      fontSize: '27px',
      fontWeight: '300',
      lineHeight: '1.1',
      fontFamily: 'MuseoSans-300',
      margin: '0px',
      '@media (max-width: 767px)': {
        fontSize: '20px',
      }, 
    },
    '& img': {
      height: '50px',
      verticalAlign: 'middle',
      cursor: 'pointer',
      [theme.breakpoints.down( 'xs' )]: {
        height: '35px',
      }, 
    },
  },
  mainContainer: {
    maxWidth: '950px',
    margin: '0 auto',
    fontWeight: '300',
    position: 'relative',
    boxSizing: 'border-box',
    fontFamily: 'MuseoSans-300',
  },
  compareSchool: {
    '& h3': {
      color: '#333',
      textAlign: 'left',
      lineHeight: '30px',
      fontWeight: '300',
      margin: '20px 0px 10px 0px',
      fontSize: '21px',
      '@media (max-width: 767px)': {
        fontSize: '18px',
      }, 
    },
  },
  typeOfHe: {
    padding: '30px 40px 0 0',
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    '& label': {
      display: 'inline-block',
      maxWidth: '100%',
      marginBottom: '5px',
      fontSize: '20px',
      fontFamily: 'MuseoSans-300',
      fontWeight: '300',
      boxSizing: 'border-box',
      color: '#000',
    },
  },
  radioWrapper: {
    marginLeft: '3%',
    width: '330px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    cursor: 'pointer',
    color: '#333',
    textAlign: 'center',
    display: 'flex',
    '& div': {
      width: '50%',
      padding: '0px',
      '& a': {
        color: '#333',
        textDecoration: 'none',
        width: '100%',
        display: 'block',
      },
    },
  },
  activeClass: {
    '& a': {
      backgroundColor: '#d9d9d9',
      fontWeight: '500',
    },
  },
  selectSection: {
    margin: '25px 0px',
    display: 'flex',
    '& input': {
      width: '100%',
      height: '34px',
      backgroundColor: '#fff',
      border: '1px solid #ccc',
      borderRadius: '4px',
      WebkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
      WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
      oTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
      padding: '6px 12px',
      backgroundImage: 'none',
      fontSize: '14px',
      lineHeight: '1.42857143',
      color: '#555',
      display: 'block',
      fontFamily: 'inherit',
      boxSizing: 'border-box',
      '&:focus': {
        borderColor: '#66afe9',
        outline: '0',
        webkitBoxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
        boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
      },
    },
  },
  titleOne: {
    width: '38%',
  },
  titleTwo: {
    width: '28%',
    paddingRight: '15px',
  },
  titleThree: {
    width: '28%',
    marginLeft: '6%',
    padding: '0px 15px',
  },
  tableStriped: {
    marginBottom: '0',
    borderSpacing: '0',
    marginTop: '20px',
    borderCollapse: 'collapse',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'transparent',
    [theme.breakpoints.down( 'xs' )]: {
      minWidth: '700px',
     },   
    '& tbody': {
      '& tr': {
        '&:nth-child(odd)': {
          backgroundColor: 'transparent',
        },
        '&:nth-child(even)': {
          backgroundColor: '#f2f2f2',
        },
        '&:nth-child(1)': {
          '& td': {
            verticalAlign: 'middle',
            borderTop: 'none',
          },
        },
        '& td': {
          height: '38px',
          padding: '0 8px',
          lineHeight: '1.2',
          verticalAlign: 'middle',
          borderTop: '1px solid #ddd',
          fontSize: '14px',
          color: theme.palette.common.pureBlack,
          position: 'relative',
          '@media (max-width: 767px)': {
            padding: '0 5px',
          },
          '&:nth-child(1)': {
            width: 'auto',
            paddingRight: '0',
          },
          '& input': {
            fontSize: '14px',
            lineHeight: '1.42857143',
            color: '#555',
            display: 'block',
            width: '100%',
            height: '34px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
            boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
            WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
            OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
            transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
            padding: '6px 12px',
            backgroundImage: 'none',
            boxSizing: 'border-box',
            outline: 'none',
            fontFamily: 'MuseoSans-300',
            '&:focus': {
              borderColor: '#66afe9',
              outline: '0',
              WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
              boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
            },
          },
          '& select': {
            fontSize: '14px',
            lineHeight: '1.42857143',
            color: '#555',
            display: 'block',
            width: '100%',
            height: '34px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '4px',
            WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
            boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075)',
            WebkitTransition: 'border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s',
            OTransition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
            transition: 'border-color ease-in-out .15s,box-shadow ease-in-out .15s',
            padding: '6px 12px',
            backgroundImage: 'none',
            boxSizing: 'border-box',
            outline: 'none',
            paddingLeft: '8px',
            cursor: 'pointer',
            fontFamily: 'MuseoSans-300',
            '&:focus': {
              borderColor: '#66afe9',
              outline: '0',
              WebkitBoxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
              boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
            },
          },
          '& img': {
            cursor: 'pointer',
            verticalAlign: 'middle',
            maxWidth: '20px',
            display: 'flex',
          },
        },
      },
    },
  },
  leftPadding: {
    paddingLeft: '30px !important',
  },
  bottomText: {
    color: '#0069aa',
    fontStyle: 'italic',
    padding: '30px 0 10px 0px',
    borderTop: '1px solid #d5d5d5',
    fontSize: '16px',
    '& p': {
      margin: '0 0 10px',
      '& a': {
        textDecoration: 'underline',
        paddingLeft: '5px',
        '&:hover': {
          color: '#f89b23',
        },
      },
    },
  },
  tooltipInfoTop: {
    width: 'auto',
    maxWidth: '200px',
    background: '#0069aa !important',
    fontSize: '14px',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    padding: '3px 8px',
    textAlign: 'initial',
    opacity: '1 !important',
    zIndex: '99',
    '&::after': {
      opacity: '1 !important',
      borderTopColor: '#0069aa !important',
      [theme.breakpoints.down( 'sm' )]: {
        borderTopColor: 'transparent !important',
      },
    },
  },
  inputDiv: {
    width: '100%',
    display: 'inline-block',
    '& div': {
      width: '100%',
      display: 'inline-block',
      '&:before': {
        border: 'none',
        outline: 'none',
      },
      '&:after': {
        border: 'none',
        outline: 'none',
      },
      '&:hover': {
        '&:before': {
          border: 'none !important',
          outline: 'none',
        },
      },
    },
  },
  definitionTooltips: {
    maxWidth: '200px',
    padding: '3px 8px !important',
    color: '#fff',
    borderRadius: '4px',
    backgroundColor: '#0069aa !important',
    fontFamily: 'helvetica neue,Helvetica,Arial,sans-serif',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '1.42857143',
    fontSize: '14px',
    boxSizing: 'border-box',
    textAlign: 'left',
    width: 'auto',
    '&::after': {
      borderTopColor: '#0069aa !important',
    },
  },
  clearIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
    },
  },
  fontBig: {
    fontSize: '16px !important',
  },
  blueRow: {
    backgroundColor: '#ddebf7 !important',
    '& td': {
      fontWeight: 'normal',
      fontFamily: '"MuseoSans-500"',
      '& span': {
        paddingLeft: '13px',
      },
    },
  },
  spanRow: {
    '& td': {
      '& span': {
        paddingLeft: '13px',
      },
    },
  },
  noMargin: {
    margin: '0px !important',
  },
  formHorizontal: {
    paddingBottom: '20px',
  },
  cmaAnalysis: {
    paddingTop: '2px',
    [theme.breakpoints.down( 'xs' )]: {
     overflow: 'auto',
    },   
  },
  dollarSym: {
    height: '35px',
    display: 'inline-flex',
    zIndex: '999',
    position: 'absolute',
    alignItems: 'center',
    marginLeft: '5px',
    fontSize: 'inherit',
    fontFamily: 'inherit',
  },
  dollarBlock: {
    marginLeft: '13px',
  },
  inputField: {
    '& input': {
      paddingLeft: '20px !important',
    },
  },
} );

export default styles;
