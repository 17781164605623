const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
    [theme.breakpoints.down( 'md' )]: {
      padding: '0 25px',
    },
  },
  MyAccount: {
    background: '#e9eff3',
    [theme.breakpoints.down( 'xs' )]: {
      '& > div': {
        padding: '0',
      },
    },
  },
  myAccountLeft: {
    background: '#f4f4f4',
    paddingTop: '20px',
    minHeight: 'calc(100vh - 86px)',
    [theme.breakpoints.down( 'xs' )]: {
      minHeight: 'auto',
    },
    '& h3': {
      margin: '0 20px 10px',
      color: '#000',
      fontSize: '30px',
    },
  },
  tabs: {
    '& > div > div + span': {
      display: 'none',
    },
    '&$tabSelected': {
      color: 'red',
      fontWeight: theme.typography.fontWeightMedium,
      background: '#e9e9e9',
    },
    padding: '0',
    selectedBackgroundColor: '#000000',
    '& button': {
      textAlign: 'left',
      minHeight: 'inherit',
      padding: '10px 20px',
      color: '#000',
      fontSize: '15px',
      opacity: '1',
      lineHeight: 'inherit',
      maxWidth: 'inherit',
      textTransform: 'inherit',
      borderBottom: '1px solid #ccc',
      '& :hover': {
        background: '#e9e9e9',
      },
      '& span': {
        alignItems: 'flex-start',
      },
      '&:hover': {
        background: '#e9e9e9',

      },
    },
  },
  accountHead: {
    display: 'flex',
    alignItems: 'center',
    background: '#016aab',
    justifyContent: 'center',
    color: '#fff',
    '& h3': {
      fontSize: '30px',
      fontWeight: '100',
      fontFamily: 'MuseoSans-100',
    },
    '& figure': {
      margin: '0',
      maxWidth: '60px',
      marginRight: '10px',
      position: 'relative',
      '& img': {
        width: '100%',
      },
    },
  },
  tabPanelContent: {
    '& > div': {
      padding: '40px 0',
      '& > p': {
        boxShadow: '0 2px 2px rgba(0,0,0,.25)',
      },
    },

  },
  accountInformationContent: {
    background: '#fff',
    padding: '20px',
  },
  informationNewHead: {
    display: 'flex',
    marginBottom: '15px',
    '& h3': {
      fontSize: '20px',
      color: '#0069aa',
    },
    '& a': {
      margin: '0 0 0 auto',
      background: '#f89b23',
      border: 'none',
      borderBottom: '2px solid #cb7002',
      color: '#fff',
      lineHeight: 'normal',
      paddingBottom: '5px',
      paddingTop: '8px',
      borderRadius: '0',
      textTransform: 'uppercase',
      fontSize: '14px',
      fontFamily: 'MuseoSans-500',
      paddingLeft: '15px',
      paddingRight: '15px',
      textDecoration: 'none',
    },
  },
  accountBox: {
    maxWidth: '600px',
  },
  formGroup: {
    marginBottom: '15px',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      width: '175px',
      display: 'block',
      fontFamily: 'MuseoSans-700',
      fontWeight: 'normal',
      fontSize: '16px',
      color: '#000',
      lineHeight: '17px',
      paddingRight: '25px',
    },
    '& select': {
      background: '#eee',
      color: '#555',
      width: 'calc(100% - 140px)',
      borderRadius: '4px',
      padding: '9px 15px',
      border: '1px solid #aaa',
      outline: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
    '& input': {
      background: '#ebebe4',
      width: '100%',
      color: '#555',
      maxWidth: '100%',
      borderRadius: '4px',
      padding: '9px 15px',
      border: '1px solid #aaa',
      outline: 'none',
    },
  },
  billingContentNew: {
    background: '#fff',
    marginTop: '40px',
    marginBottom: '40px',
    boxShadow: '0 2px 2px rgba(0,0,0,.25)',
    borderRadius: '5px',
    '& h3': {
      margin: '0 0 38px',
      fontWeight: 'normal',
      padding: '0 0 15px',
      fontSize: '30px',
      lineHeight: '30px',
      color: '#0069aa',
      borderBottom: '1px solid #bbb',
    },
  },
  billingContent: {
    display: 'table',
    width: '100%',
    marginBottom: '15px',
    '& > div': {
      display: 'table-cell',
      width: '33.33%',
      '& h5': {
        margin: '0 0 8px',
        fontSize: '18px',
        lineHeight: '18px',
        color: '#0069aa',
        fontFamily: 'MuseoSans-500',
        fontWeight: 'normal',
      },
      '& p': {
        margin: '0',
      },
    },
  },
  paymentDetails: {
    background: '#f2f2f2',
    padding: '15px',
    '& h5': {
      color: '#000 !important',
    },
  },
  billingHistoryNew: {
    marginTop: '25px',
    '& h4': {
      margin: '0 0 8px',
      fontSize: '18px',
      lineHeight: '18px',
      color: '#0069aa',
      fontFamily: 'MuseoSans-500',
      fontWeight: 'normal',
    },
  },
  billingHead: {
    '& table': {
      width: '100%',
      textAlign: 'left',
      '& thead': {
        borderBottom: '1px solid #000',
        '& th': {
          padding: '10px 15px',
          fontFamily: 'MuseoSans-700',
          fontSize: '16px',
          color: '#000',
          fontWeight: 'normal',
          '&:first-child': {
            minWidth: '250px',
          },
        },
      },

      '& tr': {
        borderBottom: '1px solid #000',

        '& td': {
          padding: '10px 15px',
        },
      },
    },
  },
  notFound: {
    background: '#f2f2f2',
  },
  formGroupCheckBox: {
    display: 'flex',
    alignItems: 'flex-start',
    '& span': {
      padding: '0 5px 0 0',
    },
    '& p': {
      color: theme.palette.common.black,
      margin: '0',
    },
  },
  hidle: {
    fontSize: '20px',
    lineHeight: '26px',
    color: theme.palette.common.black,
  },
  borderLine: {
    background: '#046cab',
    height: '3px',
    width: '100%',
  },
  checkBoxBlock: {
    '& > div': {
      margin: '10px 0',
    },
  },
  billingContentNewHead: {
    '& h3': {
      marginBottom: '0',
      border: 'none',
    },
    '& p': {
      margin: '0',
    },
  },
  deviderNew: {
    margin: '20px -25px 0',
    height: '1px',
    background: '#ccc',
  },
  informationNewHeadMargin: {
    marginTop: '35px',
  },
  sticky: {
    position: 'sticky',
    top: '80px',
  },
  activeTab: {
    background: '#e9e9e9',
  },

} );

export default styles;
