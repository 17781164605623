const styles = ( theme ) => ( {
  container: {
    padding: '0 15px',
    margin: '0px auto',
  },
  addressCntnt: {
    padding: '0 45px',
    '@media (max-width: 767px)': {
      padding: '0 0px',
    }
  },
  addressTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '30px',
    '& h2': {
      color: '#000',
      fontSize: '30px',
      marginTop: '0px',
      marginBottom: '10px',
    },
  },
  infoIcon: {
    marginLeft: '10px',
  },
  addressList: {
    display: 'block',
    padding: '0',
    margin: '0',
    '@media (max-width: 767px)': {
      padding: '0 0 50px',
    },
    '& li': {
      listStyleType: 'none',
      borderBottom: '1px solid #e9e9e9',
      float: 'none',
      display: 'block',
      cursor: 'pointer',
      padding: '10px 15px',
      fontFamily: '"MuseoSans-500"',
      alignItems: 'center',
      fontSize: '16px',
      color: '#fff',
      textDecoration: 'none',
      '@media (max-width: 767px)': {
        padding: '10px 10px',
        fontSize: '13px',
      },
      '& span': {
        fontSize: '16px',
        margin: '0 4px 0 0',
      },
      '& activeClass': {
        background: '#eca041',
      },
      '&:hover': {
        background: '#eca041',
      },
    },
  },
  activeClass: {
    background: '#eca041',
  },
  tabsBlock: {
    background: theme.palette.common.blue,
    height: '100%',
    position: 'relative',
    '@media (max-width: 767px)': {
      minWidth: '140px',
    }
  },
  addressScroll: {
    maxHeight: 'auto',
    '& ul': {
      display: 'block',
      padding: '0',
      margin: '0',
      listStyle: 'none',
    },
  },
  addressBtn: {
    padding: '0px 15px 15px 10px',
    background: theme.palette.common.blue,
    boxSizing: 'border-box',
    position: 'absolute',
    bottom: '0',
  },
  addAddress: {
    bottom: '14px',
    left: '10px',
    width: '25px',
    height: '25px',
    background: '#fff',
    color: theme.palette.common.blue,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'center',
    '& i': {
      fontSize: '12px',
      lineHeight: '27px',
    },
  },
  searchBlock: {
    background: '#f4f4f4',
    borderBottomLeftRadius: '3px',
    padding: '0px 15px',
  },
  searchRow: {
    position: 'relative',
    margin: '15px 0 10px',
    overflow: 'hidden',
    width: '100%',
  },
  formGroup: {
    width: '100%',
    marginTop: '15px',
    color: '#000',
    '& > div': {
      width: '100%',
      color: '#000',
    },
    '& label': {
      display: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
    '& input': {
      width: '100%',
      padding: '8px 15px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      background: '#fff',
      textAlign: 'center',
      color: '#000',
      '&:focus': {
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        borderColor: '#66afe9',
      },
    },
  },
  popupInput: {
    width: '100%',
    marginTop: '15px',
    color: '#000',
    '& > div': {
      width: '100%',
      color: '#000',
      fontFamily: '"MuseoSans-500"',
    },
    '& fieldset': {
      display: 'none',
    },
    '& input': {
      width: '100%',
      padding: '8px 15px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      background: '#fff',
      color: '#000',
      '&:focus': {
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        borderColor: '#66afe9',
      },
    },
  },
  userDetails: {
    listStyle: 'none',
    borderRight: '1px solid #e9e9e9',
    height: '382px',
    padding: '0px',
    '@media (max-width: 767px)': {
      maxWidth: '300px',
      margin: '20px auto',
    },
    '& li': {
      fontFamily: '"MuseoSans-300"',
      padding: '10px 15px',
      color: '#000',
      '@media (max-width: 1279px)': {
        display: 'flex',
        padding: '10px 0px',
      },
      '& span': {
        fontSize: '14px',
        lineHeight: '14px',
        margin: '0',
        display: 'inline-block',
        verticalAlign: 'middle',
        '@media (max-width: 1279px)': {
          width: 'calc(100% - 110px)',
          wordBreak: 'break-word',
        },
        '&:first-child': {
          color: theme.palette.common.blue,
          width: '150px',
          textAlign: 'right',
          fontWeight: '700',
          marginRight: '5px',
          '@media (max-width: 1279px)': {
            width: '110px',
          }
        },
      },
    },
  },
  profileTopBlock: {
    height: '80px',
    background: '#fcfcfc',
  },
  userProfile: {
    background: '#0069aa',
    width: '100%',
    padding: '15px 0',
    borderRight: '1px solid #e9e9e9',
    boxSizing: 'border-box',
    position: 'relative',
  },
  profileImageBlock: {
    display: 'flex',
    width: '50%',
    margin: '0 auto',
    alignitems: 'center',
    '@media (max-width: 1279px)': {
      width: '80%',
    }
  },
  profileImage: {
    width: '50px',
    height: '50px',
    '& img': {
      width: '100%',
      height: '100%',
    },
  },
  profileContent: {
    padding: '0 10px',
    '& p': {
      display: 'block',
      margin: '0 0 4px',
      padding: '0',
      fontSize: '20px',
      lineHeight: '20px',
      color: theme.palette.common.white,
      fontFamily: '"MuseoSans-300"',
      textTransform: 'capitalize',
    },
    '& span': {
      display: 'block',
      fontSize: '13px',
      lineHeight: '13px',
      fontFamily: '"MuseoSans-300"',
      margin: '0',
      padding: '0',
      color: theme.palette.common.white,
    },
  },
  addAddressBook: {
    background: '#0069aa',
    bottom: '14px',
    left: '10px',
    width: '25px',
    height: '25px',
    color: theme.palette.common.white,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'block',
    position: 'absolute',
    textAlign: 'center',
    '& i': {
      fontSize: '12px',
      lineHeight: '27px',
    },
  },
  userDescription: {
    position: 'relative',

  },
  searchScroll: {
    display: 'inline-block',
    float: 'left',
    width: '100%',
    listStyle: 'none',
    padding: '0px 0px 0px 25px',
    height: 'auto',
    boxSizing: 'border-box',
    marginTop: '0px',
    '& li': {
      cursor: 'pointer',
      margin: '0',
      padding: '5px 10px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      lineHeight: '20px',
      boxSizing: 'border-box',
      '& p': {
        verticalAlign: 'middle',
        margin: '0',
        lineHeight: '15px',
        fontSize: '15px',
        padding: '4px 5px 0',
        textTransform: 'capitalize',
      },
    },
  },
  activeUser: {
    background: theme.palette.common.blue,
    color: '#fff',
  },
  userImage: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    '& img': {
      width: '100%',
      height: '100%',
      verticalAlign: 'middle',
    },
  },
  searchKey: {
    boxSizing: 'border-box',
    paddingLeft: '0px',
    marginBottom: '0px',
    '& li': {
      padding: '0 18px 0 0',
      display: 'block',
      borderBottom: '1px solid #d9d9d9',
      color: '#9e9494',
      textTransform: 'uppercase',
      fontFamily: '"MuseoSans-500"',
      margin: '0 0 6px',
    },
  },
  addGroup: {
    // overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
      maxHeight: 'calc(100vh - 50px)',
    },
    '& > div > div': {
      minWidth: '600px',
      width: '600px',
      maxHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      }
    },
  },
  addMember: {
    overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
    },
    '& > div > div': {
      minWidth: '600px',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      }
    },
  },
  popupHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h4': {
      fontSize: '18px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& i': {
      opacity: '0.3',
      float: 'right',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  popupContent: {
    padding: '15px',
    '& p': {
      padding: '0 0 18px 0',
      fontSize: '16px',
      margin: '0',
      color: '#000',
      fontFamily: 'MuseoSans-300',
    },
    '& img': {
      width: '100%',
    },
    '& label': {
      fontWeight: '700',
      color: '#000',
    },
  },
  popupFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: ' 1px solid #e5e5e5',
    '& p': {
      margin: '0',
      maxWidth: '640px',
      fontSize: '16px',
      lineHeight: '26px',
    },
    '& button': {
      margin: '0 0 0 auto',
      background: theme.palette.common.white,
      border: '2px solid #ccc',
      padding: '6px 12px',
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '1.4',
      textTransform: 'capitalize',
      borderRadius: '4px',
      color: theme.palette.common.black,
      outline: 'none',
      '&:hover': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:focus': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:first-child': {
        color: theme.palette.common.white,
        backgroundColor: '#337ab7',
        border: '1px solid transparent',
        marginRight: '5px',
        '&:hover': {
          backgroundColor: '#204d74',
          border: '1px solid transparent',
        },
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      '& button': {
        margin: '15px auto',
      },
    },
  },
  GridInput: {
    '& > div': {
      width: '100%',
    },
  },
  tooltipInfo: {
    width: '300px',
    background: '#0069aa !important',
    fontSize: '14px',
    fontWeight: '500',
    padding: '7px',
    textAlign: 'initial',
    opacity: '1 !important',
  },
  pullRight: {
    float: 'right',
  },
  tooltipInfoNew: {
    color: '#0069aa !important',
    opacity: '1 !important',
    boxShadow: '0 0 3px #666',
    fontFamily: 'MuseoSans-500',
    '&:after':{
      '@media (max-width: 767px)': {
        borderRightColor: '#fff !important',
      },
      '@media (min-width: 768px)': {
        borderTopColor: '#fff !important',
      }
    }
  },
  addressBookContainer: {
    // height: 'calc(100vh - 140px)',
    overflow: 'hidden',
    marginBottom: '10px',
  },
  addressBlocks: {
    '& > div': {
      background: '#fcfcfc',  
      '@media (min-width: 768px) and (max-width: 959px)': {
        flexWrap: 'initial',
      },
      '& > div': {
        minHeight: 'calc(100vh - 200px)',
        '@media (max-width: 767px)': {
          minHeight: 'calc(100vh - 600px)',
          width: '100%',
        },
        '@media (min-width: 768px) and (max-width: 959px)': {
          '&:nth-child(1)':{
            minWidth: '170px',
          },
        },
        '@media (min-width: 960px) and (max-width: 1279px)': {
          '&:nth-child(2)':{
            maxWidth: '35%',
            flexBasis: '35%',
          },
          '&:nth-child(3)':{
            maxWidth: '40%',
            flexBasis: '40%',
          }
        }
      },
    },
  },
  textSuccessPopup: {
    textAlign: 'center',
    width: '100%',
    margin: '10px 0',
    color: 'green',
    paddingLeft: '60px',
  },
  textDangerPopup: {
    textAlign: 'center',
    width: '100%',
    margin: '0 0',
    color: 'red',
    paddingLeft: '0',
  },
} );

export default styles;
