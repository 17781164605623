import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/buying-a-home/styles';
import resetIcon from '../../../assets/img/pf101/circle-arrow.png';
import * as excelLibrary from '../../../utilities/excelLibrary';

const LoanPoints = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup,
  } = props;
  const mortgageAmount = ( inputData.purchase_price !== undefined && inputData.purchase_price > 0 && inputData.down_payment_in_percentage !== undefined ) ? ( ( inputData.purchase_price / 100 ) * ( 100 - inputData.down_payment_in_percentage ) ) : 0;
  const initialTermsOfloan = ( inputData.term_of_mortgage !== undefined ) ? inputData.term_of_mortgage : 0;
  const [initiaInterest, setInitiaInterest] = React.useState( 0 );
  const [loanPoints, setLoanPoints] = React.useState( 0 );
  const [interestRate, setInterestRate] = React.useState( 0 );
  
  const [loanCostPoints, setLoanCostPoints] = React.useState( 0 );
  const [monthlyPaymentFirst, setMonthlyPaymentFirst] = React.useState( 0 );
  const [monthlyPaymentSecond, setMonthlyPaymentSecond] = React.useState( 0 );
  const [totalInterestPaidFirst, setTotalInterestPaidFirst] = React.useState( 0 );
  const [totalInterestPaidSecond, setTotalInterestPaidSecond] = React.useState( 0 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    let inputDetails = {...inputData};
    if ( inputData.points_for_the_mortgage !== undefined ) {
      setLoanPoints( inputData.points_for_the_mortgage );
    } else {
      setLoanPoints( 0.25 );
      inputDetails['points_for_the_mortgage'] = 0.25;
    }
    setInitiaInterest( ( inputData.rate_of_mortgage_in_percentage !== undefined ) ? inputData.rate_of_mortgage_in_percentage : 0 );
    if ( inputData.rate_of_mortgage_in_percentage !== undefined ) {
      setInterestRate( inputData.rate_of_mortgage_in_percentage );
    } else {
      setInterestRate( 4 );
      inputDetails['rate_of_mortgage_in_percentage'] = 4;
    }
    setLoanCostPoints( ( mortgageAmount / 100 ) * loanPoints );

    // Monthly Payment (Principal & Interest)
    if ( inputData.term_of_mortgage > 0 ) {
      setMonthlyPaymentFirst( excelLibrary.PMT( ( initiaInterest / 100 ) / 12, inputData.term_of_mortgage * 12, -( mortgageAmount ), 0, 0 ) );
      setMonthlyPaymentSecond( excelLibrary.PMT( ( interestRate / 100 ) / 12, inputData.term_of_mortgage * 12, -( mortgageAmount ), 0, 0 ) );
    } else {
      setMonthlyPaymentFirst( 0 );
      setMonthlyPaymentSecond( 0 );
    }
    let totalInterest = 0;
    totalInterest = -excelLibrary.CUMIPMT( ( initiaInterest / 100 ) / 12, initialTermsOfloan * 12, ( mortgageAmount ), 1, initialTermsOfloan * 12, 0 );
    setTotalInterestPaidFirst( totalInterest );
    let totalInterest2 = 0;
    totalInterest2 = -excelLibrary.CUMIPMT( ( interestRate / 100 ) / 12, initialTermsOfloan * 12, ( mortgageAmount ), 1, initialTermsOfloan * 12, 0 );
    setTotalInterestPaidSecond( totalInterest2 );
    updateData('total_obj',inputDetails);
    // eslint-disable-next-line
  }, [] );

  useEffect(()=>{
    if ( inputData.term_of_mortgage > 0 ) {
      setMonthlyPaymentFirst( excelLibrary.PMT( ( initiaInterest / 100 ) / 12, inputData.term_of_mortgage * 12, -( mortgageAmount ), 0, 0 ) );
      setMonthlyPaymentSecond( excelLibrary.PMT( ( interestRate / 100 ) / 12, inputData.term_of_mortgage * 12, -( mortgageAmount ), 0, 0 ) );
    } else {
      setMonthlyPaymentFirst( 0 );
      setMonthlyPaymentSecond( 0 );
    }
    let totalInterest1 = 0;
    totalInterest1 = -excelLibrary.CUMIPMT( ( initiaInterest / 100 ) / 12, initialTermsOfloan * 12, ( mortgageAmount ), 1, initialTermsOfloan * 12, 0 );
    setTotalInterestPaidFirst( totalInterest1 );
    let totalInterest2 = 0;
    totalInterest2 = -excelLibrary.CUMIPMT( ( interestRate / 100 ) / 12, initialTermsOfloan * 12, ( mortgageAmount ), 1, initialTermsOfloan * 12, 0 );
    setTotalInterestPaidSecond( totalInterest2 );
    // eslint-disable-next-line
  },[loanPoints,interestRate])

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    if ( newvalue > 100 ) {
      newvalue = 100;
    }
    switch ( field ) {
      case 'loan_points': {
        setLoanPoints( newvalue );
        let inputDetails = {...inputData};
        inputDetails[mainField] = newvalue;
        let updateinterestRate = initiaInterest - ( ( 0.25 * ( newvalue / 100 ) ) * 100 );
        if ( updateinterestRate < 0 ) {
          updateinterestRate = 0;
        }
        updateinterestRate = parseFloat( updateinterestRate ).toFixed( 2 );

        setLoanCostPoints( ( mortgageAmount / 100 ) * newvalue );
        setInterestRate( updateinterestRate );
        inputDetails['rate_of_mortgage_in_percentage'] = updateinterestRate;
        updateData( 'total_obj', inputDetails );
        break;
      }
      case 'interest_rate': {
        if ( inputData.term_of_mortgage > 0 ) {
          setMonthlyPaymentFirst( excelLibrary.PMT( ( initiaInterest / 100 ) / 12, inputData.term_of_mortgage * 12, -( mortgageAmount ), 0, 0 ) );
          setMonthlyPaymentSecond( excelLibrary.PMT( ( interestRate / 100 ) / 12, inputData.term_of_mortgage * 12, -( mortgageAmount ), 0, 0 ) );
        } else {
          setMonthlyPaymentFirst( 0 );
          setMonthlyPaymentSecond( 0 );
        }
        setInterestRate( newvalue );
        updateData( mainField, newvalue );
        break;
      }
      default:
        break;
    }
    
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        <a className={ classes.anchorStyle } href="https://www.bankrate.com/glossary/d/discount-point/" target="_blank" rel="budget-fy2020 noopener noreferrer">Loan points</a>
        , also known as buying down the rate, is a one-time fee paid at close to your mortgage lender in exchange for a lower interest rate. Paying for points reduces the overall cost of the mortgage. When does it make sense to pay for points?
        <ul className={ classes.tickList }>
          <li>If you have enough cash to afford the fees at close, and</li>
          <li>You plan on living in the home until after the breakeven period</li>
        </ul>
      </p>
      <p>Find out if loan points are worth paying for by adjusting the inputs below:</p>

      <div className={ classes.loanPoints }>
        <table>
          <thead>
            <tr>
              <td>
                Mortgage Amount:
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ mortgageAmount } displayType="text" thousandSeparator prefix="$" />
              </td>
              <td>
              &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                Interest Rate:
              </td>
              <td>
                {parseFloat( initiaInterest ).toFixed( 2 )}
                %
              </td>
              <td>
              &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                Term of the Loan:
              </td>
              <td>
                {initialTermsOfloan}
              </td>
              <td>
              &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <p aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Loan Points' ) }><span className={ classes.dottedBorder }>Loan Points:</span></p>
              </td>
              <td className={ classes.dollarInput }>
                <NumberFormat
                  decimalScale={ isFocus!=='loan_points'?2:5 }
                  fixedDecimalScale={isFocus!=='loan_points'}
                  disabled={ mortgageAmount <= 0 }
                  allowNegative={ false }
                  customInput={ TextField }
                  thousandSeparator
                  value={ loanPoints }
                  onValueChange={ ( e ) => updateValue( e, '', 'loan_points', 'points_for_the_mortgage', 'number' ) }
                  onFocus={ ( e ) => {e.target.select(); setIsFocus('loan_points')} } 
                  onBlur={emptyIsFocus}
                />
                <span className={ classes.percentSymbl }>%</span>
              </td>
              <td>
              &nbsp;
              </td>
            </tr>
            <tr>
              <td>
                <p aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Loan Points' ) }><span className={ classes.dottedBorder }>New Interest Rate:</span></p>
              </td>
              <td className={ classes.dollarInput }>
                <NumberFormat
                  decimalScale={ isFocus!=='interest_rate'?2:5 }
                  fixedDecimalScale={isFocus!=='interest_rate'}
                  disabled={ loanPoints <= 0 }
                  allowNegative={ false }
                  customInput={ TextField }
                  thousandSeparator
                  value={ interestRate }
                  onValueChange={ ( e ) => updateValue( e, '', 'interest_rate', 'rate_of_mortgage_in_percentage', 'number' ) }
                  onFocus={ ( e ) => {e.target.select(); setIsFocus('interest_rate')} }
                  onBlur={emptyIsFocus} 
                />
                <span className={ classes.percentSymbl }>%</span>
                <span data-for="definationTitle1" data-tip="Inkwiry assumes that 1 Loan Point equals a 0.25% decrease in the interest rate. Click to reset to this default formula." className={ classes.resetPicture } role="button" aria-hidden="true" onClick={ ( e ) => updateValue( e, loanPoints, 'loan_points', 'points_for_the_mortgage', 'slider' ) }>
                  <img src={ resetIcon } alt="" />
                </span>
              </td>
              <td>
              &nbsp;
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
              &nbsp;
              </td>
              <td>
                <span className={ classes.tdBottomBorder }><b>No Points</b></span>
              </td>
              <td>
                <span className={ classes.tdBottomBorder }><b>With Points</b></span>
              </td>
            </tr>
            <tr>
              <td>
                Cost of Loan Points
              </td>
              <td>
                $0
              </td>
              <td>
                {loanCostPoints !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ loanCostPoints } prefix={ loanCostPoints >= 0 ? '$' : '($' } suffix={ loanCostPoints < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>
                Interest Rate
              </td>
              <td>
                {parseFloat( initiaInterest ).toFixed( 2 )}
                %
              </td>
              <td>
                {parseFloat( interestRate ).toFixed( 2 )}
                %
              </td>
            </tr>
            <tr>
              <td>
                Monthly Payment
                <span className={ classes.piText }>(Principal & Interest)</span>
              </td>
              <td>
                {monthlyPaymentFirst !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ monthlyPaymentFirst } prefix={ monthlyPaymentFirst >= 0 ? '$' : '($' } suffix={ monthlyPaymentFirst < 0 && ')' } />
                ) : ( '$0' )}
              </td>
              <td>
                {monthlyPaymentSecond !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ monthlyPaymentSecond } prefix={ monthlyPaymentSecond >= 0 ? '$' : '($' } suffix={ monthlyPaymentSecond < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>
                Monthly Payment Savings
              </td>
              <td>
                --
              </td>
              <td>
                {monthlyPaymentSecond !== undefined && monthlyPaymentFirst !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ monthlyPaymentFirst-monthlyPaymentSecond  } prefix={ (monthlyPaymentFirst-monthlyPaymentSecond) >= 0 ? '$' : '($' } suffix={ (monthlyPaymentFirst-monthlyPaymentSecond) < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>
                Breakeven Period
              </td>
              <td>
                --
              </td>
              <td>
                {
                  (monthlyPaymentFirst - monthlyPaymentSecond) > 0 ? (
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ loanCostPoints / ( monthlyPaymentFirst - monthlyPaymentSecond ) }  />
                  ):(
                    0
                  )
                }{' '}
                months
              </td>
            </tr>
            <tr>
              <td>
                Total Interest Paid
              </td>
              <td>
                {totalInterestPaidFirst !== undefined && monthlyPaymentFirst !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalInterestPaidFirst } prefix={ totalInterestPaidFirst >= 0 ? '$' : '($' } suffix={ totalInterestPaidFirst < 0 && ')' } />
                ) : ( '$0' )}
              </td>
              <td>
                {totalInterestPaidSecond !== undefined && monthlyPaymentFirst !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalInterestPaidSecond } prefix={ totalInterestPaidSecond >= 0 ? '$' : '($' } suffix={ totalInterestPaidSecond < 0 && ')' } />
                ) : ( '$0' )}
              </td>
            </tr>
            <tr>
              <td>
                Total Savings on Interest Paid
              </td>
              <td>
                --
              </td>
              <td>
                <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ totalInterestPaidFirst - totalInterestPaidSecond } prefix={ (totalInterestPaidFirst - totalInterestPaidSecond) >= 0 ? '$' : '($' } suffix={ (totalInterestPaidFirst - totalInterestPaidSecond) < 0 && ')' } />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
      <ReactTooltip id="definationTitle1" place="right" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

LoanPoints.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( LoanPoints );
