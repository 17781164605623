import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../screens/pwi-existing/styles';
import NumberFormat from 'react-number-format';
// import TextField from '@material-ui/core/TextField';
import * as modulesServices from '../../calculations/modules-services';
import classNames from 'classnames';

const CashSummary = ( props ) => {
  const { classes, setActiveStep, handleAssestObject} = props;
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    setTotal(
      parseInt(modulesServices.module18Data.existing_cash) + 
      parseInt(modulesServices.module18Data.existing_checking_accounts) +
      modulesServices.module18Data.existingTotalOtherInvestments + 
      modulesServices.module18Data.existing_total_short_term_investments +
      modulesServices.module18Data.existing_total_long_term_investments
    );
  }, [])
  return (
    <div className={ classes.contentBlock }>
      <div className={classNames(classes.tableBox, classes.tableBoxPWINew)}>
        <table cellPadding="0" cellSpacing="0">
          <thead>
            <th colSpan={ 2 }>Cash and Investments</th>
          </thead>
          <tbody>
            <tr>
              <td onClick={() =>{setActiveStep(2)}}>Cash</td>
              <td>
                <div className={ classes.amountDollar }>
                  {(parseInt(handleAssestObject.existing_cash) + parseInt(handleAssestObject.existing_checking_accounts)) !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ parseInt(handleAssestObject.existing_cash) + parseInt(handleAssestObject.existing_checking_accounts) } prefix={ (parseInt(handleAssestObject.existing_cash) + parseInt(handleAssestObject.existing_checking_accounts)) >= 0 ? '$' : '($' } suffix={ (parseInt(handleAssestObject.existing_cash) + parseInt(handleAssestObject.existing_checking_accounts)) < 0 && ')' } />
                  ) : ( '$0' )}
                </div>
              </td>
            </tr>
            <tr>
              <td onClick={() =>{setActiveStep(4)}}>Short-Term Investments</td>
              <td>
                <div className={ classes.amountDollar }>
                  {handleAssestObject.existing_total_short_term_investments !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ handleAssestObject.existing_total_short_term_investments } prefix={ handleAssestObject.existing_total_short_term_investments >= 0 ? '$' : '($' } suffix={ handleAssestObject.existing_total_short_term_investments < 0 && ')' } />
                  ) : ( '$0' )}
                </div>
              </td>
            </tr>
            <tr>
              <td onClick={() =>{setActiveStep(6)}}>Long-Term Investments</td>
              <td>
                <div className={ classes.amountDollar }>
                  {handleAssestObject.existing_total_long_term_investments !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ handleAssestObject.existing_total_long_term_investments } prefix={ handleAssestObject.existing_total_long_term_investments >= 0 ? '$' : '($' } suffix={ handleAssestObject.existing_total_long_term_investments < 0 && ')' } />
                  ) : ( '$0' )}
                </div>
              </td>
            </tr>
            <tr>
              <td onClick={() =>{setActiveStep(7)}}>Other Investments</td>
              <td>
                <div className={ classes.amountDollar }>
                  {modulesServices.module18Data.existingTotalOtherInvestments !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module18Data.existingTotalOtherInvestments } prefix={ modulesServices.module18Data.existingTotalOtherInvestments >= 0 ? '$' : '($' } suffix={ modulesServices.module18Data.existingTotalOtherInvestments < 0 && ')' } />
                  ) : ( '$0' )}
                </div>
              </td>
            </tr>
            <tr className={ classes.trFoot }>
              <td className={ classes.firstYearTutuin }>Total Investments Balance</td>
              <td>
                <div className={ classes.amountDollarRight }>
                  {total !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ total } prefix={ total >= 0 ? '$' : '($' } suffix={ total < 0 && ')' } />
                  ) : ( '$0' )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

CashSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,

};

export default withStyles( styles )( CashSummary );
