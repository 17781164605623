import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';
import { Link } from 'react-router-dom';

const MutualFund = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Mutual Fund</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/mutual-fund.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3>
                      Finance Explained
                      <span>|</span>
                      Mutual Fund
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://inkwiry-images.s3.us-east-2.amazonaws.com/email_templates/mutual-fund-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          You want to make investments, but you don&apos;t know
                          what to invest in. Sure, you could pick individual
                          stocks. But in reality, that&apos;s very difficult.
                          And if you&apos;re not an educated and trained
                          investment professional, managing your investments on
                          a day-to-day basis would be pretty challenging.
                          That&apos;s where a mutual fund can add value to your
                          financial life.
                        </p>
                      </div>
                    </div>
                    <div className={classes.pfRow}>
                      <div className={classes.feText}>
                        <p>
                          A mutual fund is a pool of money that is provided by
                          individual investors, companies and other
                          organizations. A fund manager - that&apos;s the
                          educated and trained investment professional - is
                          hired to invest that pool of money with a specific
                          investment objective. A mutual fund is a great way to
                          diversify your investments without having to pick
                          individual stocks or bonds. But, since a mutual fund
                          is actively managed, there are extra fees to pay for
                          the fund manager.
                        </p>
                        <p>
                          Expense ratio is a fancy word for fees in the world of
                          funds. When you look at a fund - whether it&apos;s an{' '}
                          <Link to="/finance-explained/etf" target="_blank">
                            exchange traded fund (ETF)
                          </Link>{' '}
                          or mutual fund - the expense ratio is one of the most
                          important factors to consider. For example, mutual
                          fund A and B have the same investment objective, but
                          mutual fund A carries a 1.0% expense ratio versus the
                          0.5% expense ratio of mutual fund B. A higher expense
                          ratio may be justified if mutual fund A is
                          consistently outperforming mutual fund B.
                        </p>
                        <p>
                          Mutual funds are actively managed, which means a
                          professional is trading stocks, bonds and other
                          securities on a day-to-day basis. When the fund
                          manager sells securities at a profit, the fund and the
                          fund owners are taxed on those gains. Even if you
                          haven&apos;t sold your shares of the mutual fund, you
                          are taxed on those gains.
                        </p>
                        <p>
                          One of the disadvantages of a mutual fund when
                          compared to an ETF is that a mutual fund trades one
                          time per day at the end of the day. An ETF trades
                          every second of every trading day just like a regular
                          stock.
                        </p>
                        <p>
                          Mutual funds are a great way to diversify your
                          investments while taking a calculated risk with a
                          specific investment objective. Make sure you
                          understand the objective of the fund, the historical
                          performance of the fund manager and the expense ratio.
                        </p>
                        <p>
                          <i>
                            Disclaimer: It&apos;s important to note that Inkwiry
                            does not provide investment advice. These examples
                            are academic and hypothetical. All investments carry
                            the risk of losing some or all your money and there
                            is no guarantee of earning a return. Consulting a
                            professional or doing your own due diligence is
                            essential before making investment decisions.
                          </i>
                        </p>
                      </div>
                    </div>
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
MutualFund.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MutualFund);
