const styles = ( theme ) => ( {
  simulatorClose: {
    position: 'absolute',
    right: '15px',
    top: '18px',
    marginTop: '-2px',
    padding: '0',
    background: '0 0',
    border: '0',   
    float: 'right',
    fontSize: '21px',
    lineHeight: '1',
    opacity: '.2',
    color: '#000',
    textShadow: '0 1px 0 #fff',
    fontWeight: '700',
    fontFamily: '"MuseoSans-300"', 
    boxSizing: 'border-box',
    textTransform: 'lowercase',  
  },
  modalHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    paddingBottom: '10px',
    paddingTop: '10px',
    position: 'relative',
    '& img': {
      width: '150px',
      display: 'block',
      maxWidth: '100%',
      height: 'auto',
      verticalAlign: 'middle',
    },
  },
  modalBody: {
    padding: '0 15px',
    position: 'relative',
  },
  tableResponsive: {
    minHeight: '.01%',
    overflowX: 'auto',
    fontFamily: '"MuseoSans-300"',
  },
  borderStrip: {
    fontSize: '17px',
    margin: '0 0 10px',
    position: 'relative',
    '&::before': {
      borderBottom: '2px solid #337ab7',
      bottom: '0',
      content: '""',
      left: '0',
      position: 'absolute',
      width: '100%',
      zIndex: '999',
    },
  },
  paybackTables: {
    maxHeight: '457px',
    height: '100%',
    overflowY: 'scroll',
  },
  paybackPopup: {
    padding: '0 15px 20px',
    borderLeft: '15px solid #0070c0',
    borderRight: '15px solid #0070c0',
    borderBottom: '4px solid #b5afaf',
    boxSizing: 'border-box',
    '& td' : {
      verticalAlign: 'bottom',
      backgroundColor: '#FFF',   
      padding: '0 3px',
      position: 'static',
      fontSize: '17px',
      lineHeight: '1.42857143',
    },
  }, 
  firstTable: {
    borderBottom: '2px solid #000',
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '100%',
    boxSizing: 'border-box',
    '& tr': {
      '& td': {
        verticalAlign: 'bottom',
        backgroundColor: '#FFF',
        padding: '0 3px',
        position: 'static',
      }
    }
  },
  tableHead:{
    borderBottom: '2px solid #000',
    color: '#000',
    fontSize: '20px !important',
    fontWeight: '700',
    textAlign: 'center',
  },
  valueOne: {
    padding: '0',
    borderRight: '0 none',
    width: '33.33%',
  },
  loansTaken: {
    border: '2px solid #000',
    width: 'auto',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    '& td': {
      '&:last-child': {
        textAlign: 'right',
        width: 'auto',
        paddingLeft: '25px',
      },
    },
  }, 
  borderLine: {
    borderBottom: '2px solid #000',
  },
  payBackModal: {    
    '& > div > div': {
      maxWidth: '76%',
      width: '76%',
      boxShadow: '0 5px 15px rgba(0,0,0,.5)',
      maxHeight: 'calc(100% - 30px)',
      transform: 'translate(0,0)',
      margin: '0 auto',
      boxSizing: 'border-box',
      overflowY: 'hidden',
      borderRadius: '6px',
    },
    '& > div > div > div': {
      padding: '0px !important',
    },
  },
  valueTwo: {
    width: '4%',
  },
  valueThree:{
    width: '25%',
    padding: '0 !important',
    verticalAlign: 'top !important',
    '& table': {
      border: '2px solid #000',
      float: 'right',
      '& td': {
        '&:last-child': {
          textAlign: 'right',
          width: 'auto',
          paddingLeft: '25px',
        },
      },
    },
  },
  fixedData: {
    '& table': {
      width: '100%',
      borderSpacing: '0',
      borderCollapse: 'collapse',
    },
  },
  blueRow: {
    '& td': {
      background: '#0070c0',
      color: '#fff',
      textAlign: 'center',
    }
  },
  firstChild: {
    width: '2%',
  },
  secondChild: {
    width: '4%',
  },
  thirdChild: {
    width: '11%',
  },
  commonChild: {
    width: '8%'
  },
  fifthChild: {
    width: '10%'
  },
  sixChild: {
    width: '12%'
  },
  blackBorder: {
    borderBottom: '2px solid #000',
  },
  valuesRow: {
    borderColor: '#000',    
    borderStyle: 'solid solid none',
    borderWidth: '2px 2px medium',
    borderTop: 'none',
  },
  repeatTable: {
    backgroundColor: 'transparent',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '100%',
    boxSizing: 'border-box',
    '& tr': {
      '& td': {
        textAlign: 'right',
        '&:first-child':{
          textAlign: 'left',
          verticalAlign: 'top',
        },
        '&:nth-child(2)': {
          width: '3%',
        },
        '&:nth-child(3)': {
          width: '7%',
        },
        '&:nth-child(4)': {
          width: '5%',
        },
        '&:nth-child(5)': {
          width: '6%',
        },
        '&:nth-child(6)': {
          width: '7%',
        },
        '&:nth-child(7)': {
          width: '8%',
        },
        '&:nth-child(8)': {
          width: '8%',
        },
        '&:nth-child(9)': {
          width: '8%',
        },
      },
    },
  },
  cancelButton: {
    color: '#333',
    backgroundColor: '#fff',
    borderColor: '#ccc !important',
    '&:hover': {
      color: '#333',
      backgroundColor: '#e6e6e6',
      borderColor: '#adadad',
    },
  },
  footerButton: {
    display: 'inline-block',
    marginBottom: '0',
    fontSize: '17px',
    fontWeight: '400',
    lineHeight: '17px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    touchAction: 'manipulation',
    cursor: 'pointer',
    userSelect: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    padding: '8px 14px',
    textTransform: 'capitalize',
    '&:last-child': {
      marginLeft: '5px',
    },
  },
  modalFooter: {
    borderTop: '1px solid #e5e5e5',
    position: 'relative',
    padding: '15px',
    textAlign: 'right',
    boxSizing: 'border-box',   
  },
} );

export default styles;
