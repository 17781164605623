import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
// import { Slider } from '@material-ui/core';
import styles from '../../screens/pwi-existing/styles';
import * as commonFunctions from '../../utilities/commonFunctions';

const marks = [
  {
    value: 1,
    scaledValue: 1,
    label: '1 yr',
  },
  {
    value: 2,
    scaledValue: 2,
    label: '2 yrs',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3 yrs',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4 yrs',
  },
  {
    value: 5,
    scaledValue: 5,
    label: '5 yrs',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6 yrs',
  },
];
const CalculationHighereducation = ( props ) => {
  const {
    classes, handleAssestObject, setPointerEvent, handleUpdatedObject,
  } = props;

  const [sliderValue, setSliderValue] = React.useState( 0 );

  const handleSliderChange = ( event, newValue ) => {
    const inputValues = {...handleAssestObject};
    setSliderValue( newValue );
    inputValues['fed_sub_years'] = newValue;
    handleUpdatedObject( inputValues );
  };

  useEffect( () => {
    const inputValues = {...handleAssestObject};
    setPointerEvent( false );
    if ( handleAssestObject.fed_sub_years === undefined ) {
      setSliderValue( 4 );
      inputValues['fed_sub_years'] = 4;
    } else {
      setSliderValue( handleAssestObject.fed_sub_years );
    }
    handleUpdatedObject( inputValues );
    // eslint-disable-next-line
  }, [] );

  return (
    <div className={ classes.contentBlock }>
      <p>
        How many years have you gone to school for? (If more than six years,
        just put six years) This detail allows the Financial Configurator (FC)
        to accurately calculate your federal student loan limits in the higher
        education modules.
      </p>
      <div className={ `${classes.AnnualIncomeGrowth} ${classes.ofYearEducation}` }>
        <div>
          <p><span className={ classes.schoolYears }># of Years in School</span></p>
          <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphHigher}` }>
            <PwiSlider
              ValueLabelComponent={ ValueLabelComponent }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              marks={ marks }
              min={ 1 }
              max={ 6 }
              step={ 1 }
              value={ sliderValue }
              valueLabelFormat={ `${commonFunctions.yearFormatter( sliderValue )}` }
              onChange={ handleSliderChange }
            />
          </div>
          <div className={ classes.yearNumber }>
            <span>
              {sliderValue}
              {' '}
              Years
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

CalculationHighereducation.propTypes = {
  classes: PropTypes.object.isRequired,
  handleAssestObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  setPointerEvent: PropTypes.func.isRequired,
};

export default withStyles( styles )( CalculationHighereducation );
