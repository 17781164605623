import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import compose from 'recompose/compose';
import styles from './styles';
import logoImage from '../../../../assets/img/mcs/my-career-sketch.png';
import logoInkwiry from '../../../../assets/img/mcs/logo.png';
import Pageloader from '../../../../components/ui/pageloader';
import scrollToComponent from 'react-scroll-to-component';
import ReactHtmlParser from 'react-html-parser';
import ReactTooltip from 'react-tooltip';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import quickstartCircleImg from '../../../../assets/img/questions/quickstart_circle_img.png';
import qiIconNew from '../../../../assets/img/questions/qi_icon_new.png';
import feedbackIcon from '../../../../assets/img/awareness/feedback-icon.png';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import tickMark from '../../../../assets/img/mcs/green-check-discovery.png';
import bookImage from '../../../../assets/img/mcs/icon-book-rev.png';

const SAVEMYCAREERSKETCH = loader('../../../../graphql/schema/career-sketch/save-careersketch.graphql');
const SAVE_QUESTIONS = loader('../../../../graphql/schema/inkwiry-questions/save-quesions.graphql');
const GET_QUESTIONS = loader('../../../../graphql/schema/inkwiry-questions/get-quesions.graphql');

const McsLevelOneActivityTwoQuestions = (props) => {
    const { classes, history } = props;
    const questionListTab = [
        {
            id: 1,
            className1: classes.circleOne,
            className2: classes.circleOneActive,
            circleSpan: classes.circleSpanOne,
            title: `Will my <span class=${classes.spanBold}>career </span><span class=${classes.spanBlock}>support the life </span><span class=${classes.spanBlock}>I want to live?</span>`,
            titleCircle: `Will my <span class=${classes.spanBoldTwo}>career </span><span class=${classes.spanBlockTwo}>support the life </span><span class=${classes.spanBlockTwo}>I want to live?</span>`,
            active: false,
            answerCorrect: false,
            answerWrong: false,
            showFeedback: false,
        },
        {
            id: 2,
            className1: classes.circleTwo,
            className2: classes.circleTwoActive,
            circleSpan: classes.circleSpanTwo,
            title: `How do I acquire <span class=${classes.spanBlock}> the <span class=${classes.spanBold}>higher </span></span><span class=${classes.spanBlock}><span class=${classes.spanBold}>education </span></span><span class=${classes.spanBlock}>I need?</span>`,
            titleCircle: `How do I acquire <span class=${classes.spanBlockTwo}> the <span class=${classes.spanBoldTwo}>higher </span></span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>education </span></span><span class=${classes.spanBlockTwo}>I need?</span>`,
            active: false,
            answerCorrect: false,
            answerWrong: false,
            showFeedback: false,
        },
        {
            id: 3,
            className1: classes.circleThree,
            className2: classes.circleThreeActive,
            circleSpan: classes.circleSpanThree,
            title: `Where is the <span class=${classes.spanBlock}><span class= ${classes.spanBold}>best place </span></span><span class=${classes.spanBlock} >for me to <span class=${classes.spanBold}>live </span></span><span class= ${classes.spanBlock}>and <span class=${classes.spanBold} >work?</span></span>`,
            titleCircle: `Where is the <span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>best place </span></span><span class=${classes.spanBlockTwo} >for me to <span class=${classes.spanBoldTwo}>live </span></span><span class= ${classes.spanBlockTwo}>and <span class=${classes.spanBoldTwo} >work?</span></span>`,
            active: false,
            answerCorrect: false,
            answerWrong: false,
            showFeedback: false,
        },
				// {
				// 	id: 4,
				// 	className1: classes.circleFour,
				// 	className2: classes.circleFourActive,
				// 	circleSpan: classes.circleSpanFour,
				// 	title: `<span class=${classes.spanBlock}>What type of </span><span class=${classes.spanBlock}><span class=${classes.spanBold}>home </span>will I be </span><span class=${classes.spanBlock}>able to afford and when?</span>`,
				// 	titleCircle: `<span class=${classes.spanBlockTwo}>What type of </span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>home </span>will I be </span><span class=${classes.spanBlockTwo}>able to afford and when?</span>`,
				// 	active: false,
				// 	answerCorrect: false,
				// 	answerWrong: false,
				// 	showFeedback: false,
				// },
				// {
				// 	id: 5,
				// 	className1: classes.circleFive,
				// 	className2: classes.circleFiveActive,
				// 	circleSpan: classes.circleSpanFive,
				// 	title: `Should I get <span class=${classes.spanBlock}><span class=${classes.spanBold}>my own wheels </span></span><span class=${classes.spanBlock}>or find another way </span><span class=${classes.spanBlock}>to get around? </span>`,
				// 	titleCircle: `Should I get <span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>my own wheels </span></span><span class=${classes.spanBlockTwo}>or find another way </span><span class=${classes.spanBlockTwo}>to get around? </span>`,
				// 	active: false,
				// 	answerCorrect: false,
				// 	answerWrong: false,
				// 	showFeedback: false,
				// },
				// {
				// 	id: 6,
				// 	className1: classes.circleSix,
				// 	className2: classes.circleSixActive,
				// 	circleSpan: classes.circleSpanSix,
				// 	title: `How much can I <span class=${classes.spanBlock}><span class=${classes.spanBold}>spend</span> on </span><span class=${classes.spanBlock}><span class=${classes.spanBold}>vacations? </span></span>`,
				// 	titleCircle: `How much can I <span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>spend</span> on </span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>vacations? </span></span>`,
				// 	active: false,
				// 	answerCorrect: false,
				// 	answerWrong: false,
				// 	showFeedback: false,
				// },
				// {
				// 	id: 7,
				// 	className1: classes.circleSeven,
				// 	className2: classes.circleSevenActive,
				// 	circleSpan: classes.circleSpanSeven,
				// 	title: `Will I have the time <span class=${classes.spanBlock}>and money for </span><span class=${classes.spanBlock}><span class=${classes.spanBold}>family and </span></span><span class=${classes.spanBlock}><span class=${classes.spanBold}>friends?</span></span>`,
				// 	titleCircle: `Will I have the time <span class=${classes.spanBlockTwo}>and money for </span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>family and </span></span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>friends?</span></span>`,
				// 	active: false,
				// 	answerCorrect: false,
				// 	answerWrong: false,
				// 	showFeedback: false,
				// },
				// {
				// 	id: 8,
				// 	className1: classes.circleEight,
				// 	className2: classes.circleEightActive,
				// 	circleSpan: classes.circleSpanEight,
				// 	title: `If I marry, <span class=${classes.spanBlock} >how will that </span><span class=${classes.spanBlock}>affect my </span><span class=${classes.spanBlock}><span class=${classes.spanBoldNew}>financial life?</span></span>`,
				// 	titleCircle: `If I marry, <span class=${classes.spanBlockTwo} >how will that </span><span class=${classes.spanBlockTwo}>affect my </span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>financial life?</span></span>`,
				// 	active: false,
				// 	answerCorrect: false,
				// 	answerWrong: false,
				// 	showFeedback: false,
				// },
				// {
				// 	id: 9,
				// 	className1: classes.circleNine,
				// 	className2: classes.circleNineActive,
				// 	circleSpan: classes.circleSpanNine,
				// 	title: `Will I be able to <span class=${classes.spanBlock}>buy those <span class=${classes.spanBold}>special </span></span><span class=${classes.spanBlock}><span class=${classes.spanBold}>things </span>I&apos;ve </span><span class=${classes.spanBlock}>always wanted? </span>`,
				// 	titleCircle: `Will I be able to <span class=${classes.spanBlockTwo}>buy those <span class=${classes.spanBoldTwo}>special </span></span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>things </span>I&apos;ve </span><span class=${classes.spanBlockTwo}>always wanted? </span>`,
				// 	active: false,
				// 	answerCorrect: false,
				// 	answerWrong: false,
				// 	showFeedback: false,
				// },
				// {
				// 	id: 10,
				// 	className1: classes.circleTen,
				// 	className2: classes.circleTenActive,
				// 	circleSpan: classes.circleSpanTen,
				// 	title: `Will I be able to <span class=${classes.spanBlock}><span class=${classes.spanBold}>save enough </span></span><span class=${classes.spanBlock}><span class=${classes.spanBold}>to retire </span>to my </span><span class=${classes.spanBlock}>dream location?</span>`,
				// 	titleCircle: `Will I be able to <span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>save enough </span></span><span class=${classes.spanBlockTwo}><span class=${classes.spanBoldTwo}>to retire </span>to my </span><span class=${classes.spanBlockTwo}>dream location?</span>`,
				// 	active: false,
				// 	answerCorrect: false,
				// 	answerWrong: false,
				// 	showFeedback: false,
				// },
    ];

    const mainOption = [
        { id: '1', title: 'I have a good idea' },
        { id: '2', title: 'I have a solid plan' },
        { id: '3', title: 'I know where this is' },
				// { id: '4', title: 'I have a good idea' },
				// { id: '5', title: 'I have this figured out' },
				// { id: '6', title: 'I\'m planning on it' },
				// { id: '7', title: 'I\'m pretty sure' },
				// { id: '8', title: 'Yes' },
				// { id: '9', title: 'I\'m pretty confident' },
				// { id: '10', title: 'I have a retirement game plan' },
    ];

    const subQuestionList = [
        {
            id: 1,
            first: 'That\'s great! Will your career support the life you want to live? Explore the career-related questions in this list and check off your own best answers as you prepare to build your career sketch. ',
            second: 'Will your career support the life you want to live? Let\'s explore together so you can figure out your own best answers to these career-related questions, which are vital to your future success and your ability to create a viable career sketch.',
        },
        {
            id: 2,
            first: 'Great! How do you acquire the higher education you need? Explore the education-related questions in this list and check off your own best answers as you prepare to build your career sketch.',
            second: 'How do you acquire the higher education you need? You probably know more than you think you do. Let\'s explore together so you can figure out your own best answers to these questions, which are vital to your future success and your ability to create a viable career sketch.',
        },
        {
            id: 3,
            first: 'Cool. Where\'s the best place for you to live and work? Take a look at these questions and check off your own best answers as you prepare to build your career sketch.',
            second: 'Where\'s the best place for you to live and work? Let\'s explore together so you can find your own best answers to these questions, which are vital to your future success and your ability to create a viable career sketch.',
        },
				// {
				// 	id: 4,
				// 	first: 'Nice. What type of home will you be able to afford and when? Explore all the things to think about as you consider your future home and surroundings. Check off your own best answers as you prepare to build your career sketch.',
				// 	second: 'What type of home will you be able to afford and when? Let\'s explore together so you can find your own best answers to these questions, which are vital to your future success and your ability to create a viable career sketch.',
				// },
				// {
				// 	id: 5,
				// 	first: 'Should I get my own wheels or find another way to get around? Yeah – you might already have a car or be comfortable using public transportation. But explore the transportation-related questions in the list and check off your own best answers as you prepare to build your career sketch.',
				// 	second: 'Should I get my own wheels or find another way to get around? These questions may be easier than you think. Let\'s explore together so you can figure out your own best answers, which are vital to your future success and your ability to create a viable career sketch.',
				// },
				// {
				// 	id: 6,
				// 	first: 'Will you enjoy beautiful beaches and travel the world? Wait – don\'t pack yet. Explore the vacation and travel-related questions in the following list and check off your own best answers as you prepare to build your career sketch.',
				// 	second: 'Will you enjoy beautiful beaches and travel the world? Do you value vacation time? Let\'s explore together so you can find your own best answers to these vacation-related questions, which are vital to your future success and your ability to create a viable career sketch.',
				// },
				// {
				// 	id: 7,
				// 	first: 'Do you love to spend time with family and friends? Will you have the time and money to do so? Explore questions in the list and check off your own best answers as you prepare to build your career sketch.',
				// 	second: 'Let\'s zero in on your personal life. Will you have the time and money for family and friends? You might find these answers easier than you originally thought. Let\'s explore together so you can find your own best answers to these questions, so they are represented in your viable career sketch.',
				// },
				// {
				// 	id: 8,
				// 	first: 'Will you get married? Will you have kids? If you are inclined to get married and start a family, now is the time to make that assumption in your plan. Explore the family-related questions in the list and check off your own best answers as you prepare to build your career sketch.',
				// 	second: 'Will you get married? Will you have kids? Of course, no one can be sure about this! Let\'s do some exploration together so you can find your own best answers to these questions, which are vital to your future success and your ability to create a viable career sketch.',
				// },
				// {
				// 	id: 9,
				// 	first: 'Great. Will you be able to buy those special things you\'ve always wanted? Think about the big-ticket purchases you\'re dreaming about. Check off your own best answers as you prepare to build your career sketch.',
				// 	second: 'Will you be able to buy those special things you\'ve always wanted? When you plan, you’ll be able to see if and when you\'ll be able to afford the major purchases you’re dreaming about. Let\'s explore together so you can figure out your own best answers to these questions, which are vital to your future success and your ability to create a viable career sketch.',
				// },
				// {
				// 	id: 10,
				// 	first: 'You are amazing! Will you be able to save enough to retire to your dream location? Explore the retirement-related questions in the list and check off your own best answers as you prepare to build your career sketch.',
				// 	second: 'Haven\'t thought about retirement? Will you be able to save enough to retire to your dream location? Let\'s explore together so you can figure out your own best answers to these questions, which are vital to your future success and your ability to create a viable career sketch.',
				// },
    ];

    const subQuestionOptionList = [
        {
            id: 1,
            main: '',
            count: '',
            height: '',
            options: [
                { id: 1, answer: '', question: 'Do I have a vision for my career?' },
                { id: 2, answer: '', question: 'Do I know what it\'ll take to become a leader in my career?' },
                { id: 3, answer: '', question: 'Do I know the best companies in my chosen field?' },
                { id: 4, answer: '', question: 'Do I know how much money I will earn 10 years into my career?' },
            ],
        },
        {
            id: 2,
            main: '',
            count: '',
            height: '',
            options: [
                { id: 1, answer: '', question: 'Do I know the level of education, major and/or certification that best prepares me for my career?' },
                { id: 2, answer: '', question: 'Do I have a plan to graduate on time, and do I know the total cost of attendance for that plan?' },
                { id: 3, answer: '', question: 'Do I have a financing strategy mapped out for higher education, including scholarships, grants and student loans?' },
                { id: 4, answer: '', question: 'Do I know what my resume needs to look like at graduation to jumpstart my career?' },
            ],
        },
        {
            id: 3,
            main: '',
            count: '',
            height: '',
            options: [
                { id: 1, answer: '', question: 'Do I know where my career is thriving in terms of job opportunities and earnings potential?' },
                { id: 2, answer: '', question: 'Do I know the tax implications and living standards in my places of interest?' },
                { id: 3, answer: '', question: 'Would I plan a short-term move to a different location to advance my career?' },
                { id: 4, answer: '', question: 'If I could pick anywhere to live and work, do I know where that would be?' },
            ],
        },
				// {
				// 	id: 4,
				// 	main: '',
				// 	count: '',
				// 	height: '',
				// 	options: [
				// 		{ id: 1, answer: '', question: 'Do I have a plan to save and invest for my home?' },
				// 		{ id: 2, answer: '', question: 'Do I know how much debt and other expenses I will carry as a homeowner?' },
				// 		{ id: 3, answer: '', question: 'Do I know how much my dream home will cost?' },
				// 		{ id: 4, answer: '', question: 'Do I know when I will be able to afford my dream home?' },
				// 	],
				// },
				// {
				// 	id: 5,
				// 	main: '',
				// 	count: '',
				// 	height: '',
				// 	options: [
				// 		{ id: 1, answer: '', question: 'Do I have a plan to save and invest for a car?' },
				// 		{ id: 2, answer: '', question: 'Do I understand the difference between leasing and buying a car?' },
				// 		{ id: 3, answer: '', question: 'Do I know how much debt and other expenses I will carry as a car owner?' },
				// 		{ id: 4, answer: '', question: 'Do I know how much my dream car costs and when I’ll be able to afford the car I want?' },
				// 	],
				// },
				// {
				// 	id: 6,
				// 	main: '',
				// 	count: '',
				// 	height: '',
				// 	options: [
				// 		{ id: 1, answer: '', question: 'Are there destinations or places I want to visit?' },
				// 		{ id: 2, answer: '', question: 'Is there a local destination where I plan to spend vacation days?' },
				// 		{ id: 3, answer: '', question: 'Do I know how much my dream and local vacations will cost?' },
				// 		{ id: 4, answer: '', question: 'Do I know how much I can spend on vacations and still reach my financial life goals?' },
				// 	],
				// },
				// {
				// 	id: 7,
				// 	main: '',
				// 	count: '',
				// 	height: '',
				// 	options: [
				// 		{ id: 1, answer: '', question: 'Do I see myself going places and spending time with family and friends?' },
				// 		{ id: 2, answer: '', question: 'Do I want to build an environment that fosters the growth of family and friends?' },
				// 		{ id: 3, answer: '', question: 'Do I want to budget money to spend with family and friends?' },
				// 		{ id: 4, answer: '', question: 'Do I know how much time I\'ll be able to spend with family and friends?' },
				// 	],
				// },
				// {
				// 	id: 8,
				// 	main: '',
				// 	count: '',
				// 	height: '',
				// 	options: [
				// 		{ id: 1, answer: '', question: 'Do I see myself getting married, having kids and starting a family?' },
				// 		{ id: 2, answer: '', question: 'Do I know how much it costs to raise a child?' },
				// 		{ id: 3, answer: '', question: 'Do I know the tax implications of getting married and having children?' },
				// 		{ id: 4, answer: '', question: 'Do I have a plan to save and invest for my family\'s future?' },
				// 	],
				// },
				// {
				// 	id: 9,
				// 	main: '',
				// 	count: '',
				// 	height: '',
				// 	options: [
				// 		{ id: 1, answer: '', question: 'Are there things I have always wanted in my life?' },
				// 		{ id: 2, answer: '', question: 'Do I know how much those items cost?' },
				// 		{ id: 3, answer: '', question: 'Do I know when I will be able to afford them?' },
				// 		{ id: 4, answer: '', question: 'Do I want to know how much money I can spend on my special things and still reach my financial life goals?' },
				// 	],
				// },
				// {
				// 	id: 10,
				// 	main: '',
				// 	count: '',
				// 	height: '',
				// 	options: [
				// 		{ id: 1, answer: '', question: 'Do I know when I want to retire and have a vision of my retirement life?' },
				// 		{ id: 2, answer: '', question: 'Do I know how much income I will need each year in retirement to live the life I want to live?' },
				// 		{ id: 3, answer: '', question: 'Do I know how much I\'ll need to save and invest each year to reach my retirement goals?' },
				// 		{ id: 4, answer: '', question: 'If I could pick anywhere to retire, do I know where that would be?' },
				// 	],
				// },
    ];
    const [loading, setLoading] = useState(false);
    const [subQuestionOption, setSubQuestionOption] = useState(subQuestionOptionList);
    const [questionList] = useState(questionListTab);
    const [mainQuestion, setMainQuestion] = useState('');
    const [solveQuestions, setsSolveQuestions] = React.useState([]);
    const [feedbackView, setFeedbackView] = React.useState(false);
    const [showQuestionFeedback, setShowQuestionFeedback] = useState(false);
    const [openFeedbackSection, setOpenFeedbackSection] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [bookSection, setBookSection]=React.useState(false);

    let mainQuestionSection = useRef(null);
    let scrollTop = useRef(null);
    let scrollQuestionFeedbackSection = useRef(null);
    let scrollFeedbackSection = useRef(null);

    const [saveQuestion] = useMutation(SAVE_QUESTIONS, {
        onError() {
            return false;
        },
    });

    useQuery(GET_QUESTIONS, {
        fetchPolicy: 'network-only',
        onCompleted(response) {
            const question = [...subQuestionOption];
            response.getInkwiryQuestionsDetails.forEach((questionData) => {
                const data = questionData;
                if (data.sub_question === 'main') {
                    question[data.main_question - 1].main = data.answer;
                } else {
                    question[data.main_question - 1].options[data.sub_question - 1].answer = parseInt(data.answer, 10);
                }

                const feedbackCount = _.filter(_.uniq(question, (item) => item.main), (element) => element.main);

                const total = question[data.main_question - 1].options.map((data1) => data1.answer).join('');
                questionList[data.main_question - 1].answerCorrect = total.length === 4;
                questionList[data.main_question - 1].showFeedback = total === '1111';

                if (total.length === 4) {
                    const solveQue = solveQuestions[data.main_question - 1] = total;
                    setsSolveQuestions([...solveQuestions], solveQue);
                    const filtered = solveQuestions.filter((el) => el != null);
                    setsSolveQuestions(filtered);
                }
                question[data.main_question - 1].count = (total.match(/1/g) || []).length * 25;

                let height = 326 - 30;
                height = ((height / 100) * question[data.main_question - 1].count) + 30;

                question[data.main_question - 1].height = height;
                setFeedbackView(!!((feedbackCount.length === 10 && total.length === 4)));
            });
            setSubQuestionOption(question);
            setLoading(false);
        },
        onError() {
            setLoading(false);
        },
    });

    const [submitFirstActivity] = useMutation(SAVEMYCAREERSKETCH, {
        onCompleted({
            saveMyCareerSketch: {
                status,
            },
        }) {
            if (status) {
                setLoading(false);
                history.push('/my-career-sketch/level1');
            }
        },
        onError(errors) {
            const formatedErrors = errors.graphQLErrors.map(({ message }) => (
                message
            ));
            setErrorMessage(formatedErrors);
            setLoading(false);
        },
    });
    const handleQuestionTab = (question, type) => {
        if (type === 'direct' && question === mainQuestion) {
            setMainQuestion('');
        } else {
            setMainQuestion(question);
        }

        if (type === undefined && question === mainQuestion) {
            scrollToComponent(mainQuestionSection, {
                offset: -75,
                align: 'top',
                duration: 500,
            });
        }
    };

    const handleArrowScrollToTop = () => {
        scrollToComponent(scrollTop, {
            offset: -56,
            align: 'top',
            duration: 500,
        });
    };
    const handleMainQuestionRadio = (event, index, type) => {

        const question = [...subQuestionOption];
        if (type === 'main') {
            question[mainQuestion - 1].main = event.target.value;
        } else {
            question[mainQuestion - 1].options[index].answer = parseInt(event.target.value, 4);
        }

        const feedbackCount = _.filter(_.uniq(question, (item) => item.main), (element) => element.main);

        const total = question[mainQuestion - 1].options.map((data) => data.answer).join('');
        questionList[mainQuestion - 1].answerCorrect = total.length === 4;
        questionList[mainQuestion - 1].showFeedback = total === '1111';

        if (total.length === 4) {
            const solveQue = solveQuestions[mainQuestion - 1] = total;

            setsSolveQuestions([...solveQuestions], solveQue);
            const filtered = solveQuestions.filter((el) => el != null);
            setsSolveQuestions(filtered);
            setShowQuestionFeedback(true);
        }
        question[mainQuestion - 1].count = (total.match(/1/g) || []).length * 25;

        let height = 326 - 30;
        height = ((height / 100) * question[mainQuestion - 1].count) + 30;

        question[mainQuestion - 1].height = height;
        setSubQuestionOption(question);
        setFeedbackView(!!((feedbackCount.length === 3 && total.length === 4)));

        if (type === 'sub' && questionList[mainQuestion - 1].answerCorrect === true && showQuestionFeedback === true) {
            scrollToComponent(scrollQuestionFeedbackSection, { offset: -90, align: 'top', duration: 500 });
        }
        saveQuestion({
            variables: {
                data: {
                    main_question: mainQuestion,
                    sub_question: (type === 'main') ? 'main' : index + 1,
                    answer: event.target.value,
                },
            },
        });
    };
    const playTheVideo = () => {
        document.getElementById('bgvid').play();
    };
    const handleOpenFeedbackSection = () => {
        setOpenFeedbackSection(true);
        if (openFeedbackSection === true) {
            scrollToComponent(scrollFeedbackSection, {
                offset: -60,
                align: 'top',
                duration: 500,
            });
        }
    };
    const handleCompletedSecondActivity = (activity_id) => {
        setLoading(true);
        submitFirstActivity({
            variables: {
                data: {
                    level: "1",
                    option_id: activity_id,
                },
            },
        });
    }
    return (
        <Typography variant="body1" component="div">
            {loading && <Pageloader loading={loading} />}
            <div>               
                <div className={classes.mcsDevelopVision }>                    
                    <div className={classes.topRow }>
                        <div className={classes.mcsLogoImage}>
                            <Link to="/my-career-sketch/dashboard">
                                <img src={logoImage} alt="logo" />
                            </Link>
                        </div>
                        <div className={ classes.activityStatus }>
                            <h4><span className={ classes.greenTick }><img src={ tickMark } alt="tick" /></span>ACTIVITY COMPLETE! BACK TO <a href="/my-career-sketch/level1">LEVEL 01</a></h4>
                        </div>
                        <ReactTooltip id="book" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
                        <div className={ classes.bookBlock }>                            
                            <div className={ classes.activityImage } aria-hidden="true" onClick={()=>{setBookSection(true)}} data-for="book" data-tip="Activity Tracker">
                                <img src={ bookImage } alt="book" />                                                        
                            </div>
                            {bookSection && (
                            <div className={ classes.reviewLevels }>
                                <i className="fa fa-times" onClick={()=>{setBookSection(false)}} />
                                <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>1.</span>
                                            <span>Career Path + Higher Education questions</span>
                                        </span>
                                        <span className={ classes.greentickMark }>
                                         <img src={ tickMark } alt="tickmark" />    
                                        </span>                                       
                                    </div>
                                    </div>
                                    <div className={ classes.panelDefault }>
                                        <div className={ classes.panelBody }>                                       
                                            <span className={ classes.titleName }>
                                                <span className={ classes.mcsNumber}>2.</span>
                                                <span>SMART Goal setting</span>
                                            </span>
                                            <span className={ classes.greentickMark }>
                                            <img src={ tickMark } alt="tickmark" />    
                                            </span>    
                                        </div>
                                </div>
                            </div>  
                            )} 
                        </div>
                    </div>                   
                </div> 
                <div className={classes.questionsBody}>
                    <div className={classes.bannerVideo}>
                        <div className={classes.questionVideoSec}>
                            <video
                                id="bgvid"
                                width="100%"
                                height="100%"
                                muted
                                autoPlay
                                src="https://inkwiry-videos.s3.us-east-2.amazonaws.com/big_question.mp4"
                                preload="auto"
                                type="video/mp4"
                                onLoadEnd={playTheVideo}
                            />

                        </div>
                        <div aria-hidden="true" className={classes.videoDownArrow} onClick={handleArrowScrollToTop}>
                            <i className="fa fa-chevron-down" />
                        </div>
                    </div>
										{/* three quetions row*/}
                    <div className={classNames(classes.discoveryCnt)} ref={(section) => { scrollTop = section; }}>
                        <div className={classes.container}>
                            <div className={classNames(classes.quickstartMainCircle, mainQuestion && classes.quickstartMainCircleActive)}>
                                <figure>
                                    <img src={quickstartCircleImg} alt="" />
                                </figure>
                            </div>

                            <div id="sticky_numbers" className={classes.ChallengesNumbers}>
                                <ul>
                                    {
                                        questionList.map((data) => (
                                            <li aria-hidden="true" onClick={() => handleQuestionTab(data.id)} className={classNames(classes.listNum, data.answerCorrect && (classes.listNumWright))}>
                                                <span>
                                                    {data.id}
                                                    {data.answerCorrect && <i className="las la-check" />}
                                                </span>
                                            </li>
                                        ))
                                    }
                                    <li aria-hidden="true" onClick={() => handleQuestionTab(questionList[0].id)} className={classNames(classes.listNum, classes.arrowUp)}>
                                        {' '}
                                        <span>
                                            <i
                                                className="las la-arrow-up"
                                                data-for="returnStart"
                                                data-tip="Return to start"
                                                data-offset="{'right': 3}"
                                            />
                                        </span>
                                        <ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={classes.tooltipInfo} />
                                    </li>
                                    {(feedbackView === true || solveQuestions.length === 3)
                                        && (
                                            <div className={classes.feedbackImg} >
                                                <Button onClick={handleOpenFeedbackSection} className={`${classes.listNumArrow} ${classes.arrowUp}`}>
                                                    <span>
                                                        <figure>
                                                            <img
                                                                src={feedbackIcon}
                                                                alt="Feedback"
                                                                data-for="returnFeedback"
                                                                data-tip="Go to Feedback"
                                                            />
                                                            <ReactTooltip id="returnFeedback" place="right" type="info" effect="solid" className={classes.tooltipInfo} />
                                                        </figure>
                                                    </span>
                                                </Button>
                                            </div>
                                        )}
                                </ul>
                            </div>

                            <div className={classes.quickstartCriclesBlock}>
                                {mainQuestion === '' && (
                                    <div className={classes.tooltipPopup}>
                                        <p>Click on question 1 to get started!</p>
                                    </div>
                                )}
                                <ul className={classes.quickstartCircle}>
                                    {
                                        questionList.map((data) => (
                                            <li aria-hidden="true" onClick={() => handleQuestionTab(data.id, 'direct')} className={classNames(data.className1, mainQuestion === data.id && data.className2, mainQuestion === '' && classes.questionsAnimation)}>
                                                <Link to="#">
                                                    <Typography variant="h3" component="h3">{data.id}</Typography>
                                                    <Typography variant="h4" component="h4">{ReactHtmlParser(data.title)}</Typography>
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                                <div className={ classes.tenQuestions }>
                                        <p>See all 10 questions?</p>                             
                                </div>
                            </div>                           
                            {mainQuestion !== '' && (
                                <div className={classes.quickstartCriclesBtm} ref={(section) => { scrollTop = section; }}>
                                    <div className={classes.quickstartCareer}>
                                        <Typography variant="h3" component="h3">
                                            {ReactHtmlParser(questionList[mainQuestion - 1].titleCircle)}
                                        </Typography>
                                        <figure>
                                            <img src={qiIconNew} alt="" />
                                        </figure>
                                    </div>
                                    <div className={classes.quickstartCricleBbtmCnt}>
                                        <div className={classes.quickstartInput}>
                                            <p className={classNames(classes.qestionInputTop, subQuestionOption[mainQuestion - 1].main !== '' && classes.stopAnimationClass)}>
                                                <label htmlFor="iknowwherethisis" className={subQuestionOption[mainQuestion - 1].main === 'first' && classes.mainOptionSelect}>
                                                    <input id="iknowwherethisis" type="radio" name="first" value="first" checked={subQuestionOption[mainQuestion - 1].main === 'first'} onChange={(e) => handleMainQuestionRadio(e, 'first', 'main')} />
                                                </label>
                                                <span>{mainQuestion && mainOption[mainQuestion - 1].title}</span>
                                            </p>
                                            <p className={classNames(classes.qestionInputTop, subQuestionOption[mainQuestion - 1].main !== '' && classes.stopAnimationClass)}>
                                                <label htmlFor="iamnotsure" className={subQuestionOption[mainQuestion - 1].main === 'second' && classes.mainOptionSelect}>
                                                    <input id="iamnotsure" type="radio" name="second" value="second" checked={subQuestionOption[mainQuestion - 1].main === 'second'} onChange={(e) => handleMainQuestionRadio(e, 'second', 'main')} />
                                                </label>
                                                <span>I&apos;m not sure</span>
                                            </p>
                                        </div>
                                        {mainQuestion && <Typography className={classes.largeStripeBar} variant="h4" component="h4" />}
                                        {subQuestionOption[mainQuestion - 1].main !== '' && (
                                            <React.Fragment>
                                                <div className={classes.questionNumberDis}>
                                                    <span className={questionList[mainQuestion - 1] && questionList[mainQuestion - 1].circleSpan}>{questionList[mainQuestion - 1] && questionList[mainQuestion - 1].id}</span>
                                                    <Typography variant="h4" component="h4">{subQuestionList[mainQuestion - 1][subQuestionOption[mainQuestion - 1].main]}</Typography>
                                                </div>
                                                <ul className={classes.quickstartCriclesBtmCnt}>
                                                    <li>
                                                        <p className={classes.qestionInputHeading}>
                                                            <span>YES</span>
                                                            <span>NO</span>
                                                            <span>MAYBE</span>
                                                        </p>
                                                    </li>
                                                    {
                                                        subQuestionOption[mainQuestion - 1].options.map((option, subindex) => (
                                                            <li>
                                                                <p className={classes.qestionInputTopTwo}>
                                                                    <label htmlFor={`question1a_${subindex}`} className={option.answer === 1 && classes.subOptionSelect}>
                                                                        <input id={`question1a_${subindex}`} type="radio" name="gender" value="1" checked={option.answer === 1} onChange={(e) => handleMainQuestionRadio(e, subindex, 'sub')} />
                                                                        <i />
                                                                    </label>
                                                                    <label htmlFor={`question1b_${subindex}`} className={option.answer === 2 && classes.subOptionSelect}>
                                                                        <input id={`question1b_${subindex}`} type="radio" name="gender" value="2" checked={option.answer === 2} onChange={(e) => handleMainQuestionRadio(e, subindex, 'sub')} />
                                                                        <i />
                                                                    </label>
                                                                    <label htmlFor={`question1c_${subindex}`} className={option.answer === 3 && classes.subOptionSelect}>
                                                                        <input id={`question1c_${subindex}`} type="radio" name="gender" value="3" checked={option.answer === 3} onChange={(e) => handleMainQuestionRadio(e, subindex, 'sub')} />
                                                                        <i />
                                                                    </label>
                                                                </p>
                                                                <p className={classes.qestionInCnt}>{option.question}</p>
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </React.Fragment>
                                        )}
                                    </div>                                   
                                    {(showQuestionFeedback === true || (mainQuestion !== '' && questionList[mainQuestion - 1].answerCorrect === true))
                                        && (
                                            <div ref={(section) => { scrollQuestionFeedbackSection = section; }}>
                                                {(mainQuestion === 1 && questionList[0].answerCorrect === true) && (
                                                    subQuestionOption[mainQuestion - 1].options[0].answer === 1 ? (
                                                        <div className={classes.quickstartCriclesBtmCntTwo}>
                                                            <p className={classes.largeStripeBarTwo} />
                                                            <div className={classes.feedbckSec}>
                                                                <div className={classes.feedbckSecDiv}>
                                                                    <Typography variant="h4" component="h4">Great job!</Typography>
                                                                    <div className={classes.emojiBlock}>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">🌱</span>
                                                                            {' '}
                                                                            Cool that you have a vision for your career.
                                                                        </Typography>
                                                                        <p>Because visualizing your future can be one of the most difficult obstacles to building the life you love. Now that you have a vision, you can start planning. Planning is “bringing the future into the present so that you can do something about it now.” (Credit: Alan Lakein).</p>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">😄</span>
                                                                            {' '}
                                                                            Did you know: when you write down your goals on a regular basis, you are 42% more likely to achieve them.
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.lookNextQustn}>
                                                                        <Button onClick={() => handleQuestionTab(mainQuestion + 1)}>
                                                                            LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
                                                                            <i className="las la-greater-than" />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={classes.quickstartCriclesBtmCntTwo}>
                                                            <p className={classes.largeStripeBarTwo} />
                                                            <div className={classes.feedbckSec}>
                                                                <div className={classes.feedbckSecDiv}>
                                                                    <Typography variant="h4" component="h4">What will your future look like?</Typography>
                                                                    <div className={classes.emojiBlock}>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">🌱</span>
                                                                            {' '}
                                                                            It&apos;s okay that you don&apos;t have a vision just yet.
                                                                        </Typography>
                                                                        <p>Visualizing your future can be one of the most difficult obstacles to building a path to the life you love. Did you know: when you write down your goals on a regular basis, you are 42% more likely to achieve them? And writing down your goals helps you craft a vision to your future.</p>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">🔍</span>
                                                                            {' '}
                                                                            Inkwiry will help you investigate the questions above - and more - and bring clarity to your vision.
                                                                        </Typography>
                                                                    </div>
                                                                    <div className={classes.lookNextQustn}>
                                                                        <Button onClick={() => handleQuestionTab(mainQuestion + 1)}>
                                                                            LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
                                                                            <i className="las la-greater-than" />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                {(mainQuestion === 2 && questionList[1].answerCorrect === true) && (
                                                    subQuestionOption[mainQuestion - 1].options[1].answer === 1 ? (
                                                        <div className={classes.quickstartCriclesBtmCntTwo}>
                                                            <p className={classes.largeStripeBarTwo} />
                                                            <div className={classes.feedbckSec}>
                                                                <div className={classes.feedbckSecDiv}>
                                                                    <Typography variant="h4" component="h4">Nice job!</Typography>
                                                                    <div className={classes.emojiBlock}>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">🎓</span>
                                                                            {' '}
                                                                            What has a great return on investment? Acquiring an education that prepares you for your career.
                                                                        </Typography>
                                                                        <p>
                                                                            <span role="img" aria-label="Panda">🤔 </span>
                                                                            But did you know more than half of all students end up not using their major in their career? This can be a big waste of time and money, so choose wisely.
                                                                        </p>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">💰</span>
                                                                            {' '}
                                                                            Six out of 10 students don&apos;t graduate on time.
                                                                        </Typography>
                                                                        <p>Each extra year costs up to $150,000 in additional tuition, interest on loans, forgone income and retirement savings. By graduating on time, you save yourself some serious time and money!</p>
                                                                    </div>
                                                                    <div className={classes.lookNextQustn}>
                                                                        <Button onClick={() => handleQuestionTab(mainQuestion + 1)}>
                                                                            LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
                                                                            <i className="las la-greater-than" />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={classes.quickstartCriclesBtmCntTwo}>
                                                            <p className={classes.largeStripeBarTwo} />
                                                            <div className={classes.feedbckSec}>
                                                                <div className={classes.feedbckSecDiv}>
                                                                    <Typography variant="h4" component="h4">Make a plan for higher education</Typography>
                                                                    <div className={classes.emojiBlock}>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">🤔</span>
                                                                            {' '}
                                                                            Acquire an education that prepares you for your career.
                                                                        </Typography>
                                                                        <p>Did you know more than half of all students end up not using their major in their career? This can be a big waste of time and money, so choose wisely.</p>
                                                                        <Typography variant="h5" component="h5">
                                                                            <span role="img" aria-label="Panda">💰</span>
                                                                            {' '}
                                                                            Six out of 10 students don&apos;t graduate on time.
                                                                        </Typography>
                                                                        <p>Each extra year costs up to $150,000 in additional tuition, interest on loans, forgone income and retirement savings. You can make a plan in Inkwiry to graduate on time and know all your costs up front.</p>
                                                                    </div>
                                                                    <div className={classes.lookNextQustn}>
                                                                        <Button onClick={() => handleQuestionTab(mainQuestion + 1)}>
                                                                            LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
                                                                            <i className="las la-greater-than" />
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                                {(mainQuestion === 3 && questionList[2].answerCorrect === true) && (
                                                    <div className={classes.quickstartCriclesBtmCntTwo}>
                                                        <p className={classes.largeStripeBarTwo} />
                                                        <div className={classes.feedbckSec}>
                                                            <div className={classes.feedbckSecDiv}>
                                                                <Typography variant="h4" component="h4">Did you know...</Typography>
                                                                <div className={classes.emojiBlock}>
                                                                    <Typography variant="h5" component="h5">Scottsdale, Arizona is the best place to live and work in the US based on 31 key indicators of job market strength.</Typography>
                                                                    <p>
                                                                        <span role="img" aria-label="Panda">🕵 ♀</span>
                                                                        {' '}
                                                                        Of 180 cities surveyed by Wallethub in the US, the most job opportunities are in Charleston, South Carolina. Highest employment growth? Reno, Nevada.
                                                                    </p>
                                                                    <Typography variant="h5" component="h5">
                                                                        🗺
                                                                        {' '}
                                                                        Where you live and work can make all the difference in your career. These are just some examples.
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                </div>
                            )}
                            {(feedbackView || solveQuestions.length === 3) && (<div className={classes.whatsMyScore} id="my-score-button">
                                <Button onClick={() => handleCompletedSecondActivity("2")}>Go To Level 01</Button>
                            </div>
                            )}
                            {errorMessage && <span>{errorMessage}</span>}
                        </div>
                    </div>
											{/* ten  quetions row*/}
											 <div className={classNames(classes.discoveryCnt, classes.displayNone)}>
												<div className={ classes.container }>
													<div className={ classNames( classes.quickstartMainCircle, mainQuestion && classes.quickstartMainCircleActive ) }>
														<figure>
															<img src={ quickstartCircleImg } alt="" />
														</figure>
													</div>

													<div id="sticky_numbers" className={ classes.ChallengesNumbers }>
														<ul>
															{
																questionList.map( ( data ) => (
																	<li aria-hidden="true" onClick={ () => handleQuestionTab( data.id ) } className={ classNames( classes.listNum, data.answerCorrect && ( classes.listNumWright ) ) }>
																		<span>
																			{data.id}
																			{data.answerCorrect && <i className="las la-check" />}
																		</span>
																	</li>
																) )
															}
															<li aria-hidden="true" onClick={ () => handleQuestionTab( questionList[0].id ) } className={ classNames( classes.listNum, classes.arrowUp ) }>
																{' '}
																<span>
																	<i
																		className="las la-arrow-up"
																		data-for="returnStart"
																		data-tip="Return to start"
																		data-offset="{'right': 3}"
																	/>
																</span>
																<ReactTooltip id="returnStart" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
															</li>
															{( feedbackView === true || solveQuestions.length === 10 )
																&& (
																<div className={ classes.feedbackImg }>
																	<Button onClick={ handleOpenFeedbackSection } className={ `${classes.listNumArrow} ${classes.arrowUp}` }>
																		<span>
																			<figure>
																				<img
																					src={ feedbackIcon }
																					alt="Feedback"
																					data-for="returnFeedback"
																					data-tip="Go to Feedback"
																				/>
																				<ReactTooltip id="returnFeedback" place="right" type="info" effect="solid" className={ classes.tooltipInfo } />
																			</figure>
																		</span>
																	</Button>
																</div>
																)}
														</ul>
													</div>

													<div className={ classes.quickstartCriclesBlock }>
														{mainQuestion === '' && (
														<div className={classNames(classes.tooltipPopup, classes.tooltipPopupTen)}>
															<p>Click on question 1 to get started!</p>
														</div>
														)}
														<ul className={classNames(classes.quickstartCircle, classes.quickstartCircleTen)}>
															{
																questionList.map( ( data ) => (
																	<li aria-hidden="true" onClick={ () => handleQuestionTab( data.id, 'direct' ) } className={ classNames( data.className1, mainQuestion === data.id && data.className2, mainQuestion === '' && classes.questionsAnimation ) }>
																		<Link to="/">
																			<Typography variant="h3" component="h3">{data.id}</Typography>
																			<Typography variant="h4" component="h4">{ReactHtmlParser( data.title )}</Typography>
																		</Link>
																	</li>
																) )
															}
														</ul>
													</div>

													{mainQuestion !== '' && (
													<div className={ classes.quickstartCriclesBtm } ref={ ( section ) => { mainQuestionSection = section; } }>
														<div className={ classes.quickstartCareer }>
															<Typography variant="h3" component="h3">
																{ReactHtmlParser( questionList[mainQuestion - 1].titleCircle )}
															</Typography>
															<figure>
																<img src={ qiIconNew } alt="" />
															</figure>
														</div>
														<div className={ classes.quickstartCricleBbtmCnt }>
															<div className={ classes.quickstartInput }>
																<p className={ classNames( classes.qestionInputTop, subQuestionOption[mainQuestion - 1].main !== '' && classes.stopAnimationClass ) }>
																	<label htmlFor="iknowwherethisis" className={ subQuestionOption[mainQuestion - 1].main === 'first' && classes.mainOptionSelect }>
																		<input id="iknowwherethisis" type="radio" name="first" value="first" checked={ subQuestionOption[mainQuestion - 1].main === 'first' } onChange={ ( e ) => handleMainQuestionRadio( e, 'first', 'main' ) } />
																	</label>
																	<span>{mainQuestion && mainOption[mainQuestion - 1].title}</span>
																</p>
																<p className={ classNames( classes.qestionInputTop, subQuestionOption[mainQuestion - 1].main !== '' && classes.stopAnimationClass ) }>
																	<label htmlFor="iamnotsure" className={ subQuestionOption[mainQuestion - 1].main === 'second' && classes.mainOptionSelect }>
																		<input id="iamnotsure" type="radio" name="second" value="second" checked={ subQuestionOption[mainQuestion - 1].main === 'second' } onChange={ ( e ) => handleMainQuestionRadio( e, 'second', 'main' ) } />
																	</label>
																	<span>I&apos;m not sure</span>
																</p>
															</div>
															{mainQuestion && <Typography className={ classes.largeStripeBar } variant="h4" component="h4" />}
															{subQuestionOption[mainQuestion - 1].main !== '' && (
															<React.Fragment>
																<div className={ classes.questionNumberDis }>
																	<span className={ questionList[mainQuestion - 1] && questionList[mainQuestion - 1].circleSpan }>{questionList[mainQuestion - 1] && questionList[mainQuestion - 1].id}</span>
																	<Typography variant="h4" component="h4">{subQuestionList[mainQuestion - 1][subQuestionOption[mainQuestion - 1].main]}</Typography>
																</div>
																<ul className={ classes.quickstartCriclesBtmCnt }>
																	<li>
																		<p className={ classes.qestionInputHeading }>
																			<span>YES</span>
																			<span>NO</span>
																			<span>MAYBE</span>
																		</p>
																	</li>
																	{
																		subQuestionOption[mainQuestion - 1].options.map( ( option, subindex ) => (
																			<li>
																				<p className={ classes.qestionInputTopTwo }>
																					<label htmlFor={ `question1a_${subindex}` } className={ option.answer === 1 && classes.subOptionSelect }>
																						<input id={ `question1a_${subindex}` } type="radio" name="gender" value="1" checked={ option.answer === 1 } onChange={ ( e ) => handleMainQuestionRadio( e, subindex, 'sub' ) } />
																						<i />
																					</label>
																					<label htmlFor={ `question1b_${subindex}` } className={ option.answer === 2 && classes.subOptionSelect }>
																						<input id={ `question1b_${subindex}` } type="radio" name="gender" value="2" checked={ option.answer === 2 } onChange={ ( e ) => handleMainQuestionRadio( e, subindex, 'sub' ) } />
																						<i />
																					</label>
																					<label htmlFor={ `question1c_${subindex}` } className={ option.answer === 3 && classes.subOptionSelect }>
																						<input id={ `question1c_${subindex}` } type="radio" name="gender" value="3" checked={ option.answer === 3 } onChange={ ( e ) => handleMainQuestionRadio( e, subindex, 'sub' ) } />
																						<i />
																					</label>
																				</p>
																				<p className={ classes.qestionInCnt }>{option.question}</p>
																			</li>
																		) )
																	}
																</ul>
															</React.Fragment>
															)}
														</div>
														{ ( showQuestionFeedback === true || ( mainQuestion !== '' && questionList[mainQuestion - 1].answerCorrect === true ) )
															&& (
															<div ref={ ( section ) => { scrollQuestionFeedbackSection = section; } }>
																{( mainQuestion === 1 && questionList[0].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[0].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Great job!</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🌱</span>
																							{' '}
																							Cool that you have a vision for your career.
																						</Typography>
																						<p>Because visualizing your future can be one of the most difficult obstacles to building the life you love. Now that you have a vision, you can start planning. Planning is “bringing the future into the present so that you can do something about it now.” (Credit: Alan Lakein).</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">😄</span>
																							{' '}
																							Did you know: when you write down your goals on a regular basis, you are 42% more likely to achieve them.
																						</Typography>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">What will your future look like?</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🌱</span>
																							{' '}
																							It&apos;s okay that you don&apos;t have a vision just yet.
																						</Typography>
																						<p>Visualizing your future can be one of the most difficult obstacles to building a path to the life you love. Did you know: when you write down your goals on a regular basis, you are 42% more likely to achieve them? And writing down your goals helps you craft a vision to your future.</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🔍</span>
																							{' '}
																							Inkwiry will help you investigate the questions above - and more - and bring clarity to your vision.
																						</Typography>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 2 && questionList[1].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[1].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Nice job!</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🎓</span>
																							{' '}
																							What has a great return on investment? Acquiring an education that prepares you for your career.
																						</Typography>
																						<p>
																							<span role="img" aria-label="Panda">🤔 </span>
																							But did you know more than half of all students end up not using their major in their career? This can be a big waste of time and money, so choose wisely.
																						</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">💰</span>
																							{' '}
																							Six out of 10 students don&apos;t graduate on time.
																						</Typography>
																						<p>Each extra year costs up to $150,000 in additional tuition, interest on loans, forgone income and retirement savings. By graduating on time, you save yourself some serious time and money!</p>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Make a plan for higher education</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Acquire an education that prepares you for your career.
																						</Typography>
																						<p>Did you know more than half of all students end up not using their major in their career? This can be a big waste of time and money, so choose wisely.</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">💰</span>
																							{' '}
																							Six out of 10 students don&apos;t graduate on time.
																						</Typography>
																						<p>Each extra year costs up to $150,000 in additional tuition, interest on loans, forgone income and retirement savings. You can make a plan in Inkwiry to graduate on time and know all your costs up front.</p>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 3 && questionList[2].answerCorrect === true ) && (
																<div className={ classes.quickstartCriclesBtmCntTwo }>
																	<p className={ classes.largeStripeBarTwo } />
																	<div className={ classes.feedbckSec }>
																		<div className={ classes.feedbckSecDiv }>
																			<Typography variant="h4" component="h4">Did you know...</Typography>
																			<div className={ classes.emojiBlock }>
																				<Typography variant="h5" component="h5">Scottsdale, Arizona is the best place to live and work in the US based on 31 key indicators of job market strength.</Typography>
																				<p>
																					<span role="img" aria-label="Panda">🕵 ♀</span>
																					{' '}
																					Of 180 cities surveyed by Wallethub in the US, the most job opportunities are in Charleston, South Carolina. Highest employment growth? Reno, Nevada.
																				</p>
																				<Typography variant="h5" component="h5">
																					🗺
																					{' '}
																					Where you live and work can make all the difference in your career. These are just some examples.
																				</Typography>
																			</div>
																			<div className={ classes.lookNextQustn }>
																				<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																					LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																					<i className="las la-greater-than" />
																				</Button>
																			</div>
																		</div>
																	</div>
																</div>
																)}
																{( mainQuestion === 4 && questionList[3].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[0].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Great job!</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🏡</span>
																							{' '}
																							You’re on the right track by saving and investing for your home.
																						</Typography>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🌠</span>
																							{' '}
																							Home ownership is a goal that many Americans aspire to achieve. That’s why it’s called the American Dream.
																						</Typography>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Nine out of 10 millennial renters want to purchase a home, but 48% have zero savings for a down payment. Only 11 percent have saved $10,000 or more.
																						</p>

																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Is owning a home one of your goals?</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🏡</span>
																							{' '}
																							Home ownership is a goal many Americans aspire to achieve. That&apos;s why it&apos;s called the American Dream.
																						</Typography>

																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Nine out of 10 millennial renters want to purchase a home, but 48% have zero savings for a down payment. Only 11 percent have saved $10,000 or more.
																						</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🤯</span>
																							{' '}
																							Remember, writing down your goals regularly makes you 42% more likely to achieve them. You just took one step closer to home ownership by realizing you need a plan today!
																						</Typography>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 5 && questionList[4].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[0].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Nice work!</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🚗</span>
																							{' '}
																							You’re on the right track by saving and investing for your car.
																						</Typography>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know the average annual cost of car ownership is $9,282 per year, or $773.50 per month including depreciation, insurance, maintenance, taxes, finance charges and tires?
																						</p>
																						<Typography variant="h5" component="h5">
																							Whether you’re trying to get to and from work or visit your parents for the weekend, transportation is what connects us. The latest technology can now avoid accidents. The best investment you can make is a vehicle that keeps you safe.
																						</Typography>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">How will you get around?</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">Whether you&apos;re trying to get to and from work or visit your parents for the weekend, transportation is what connects us.</Typography>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know the average annual cost of car ownership is $9,282 per year, or $773.50 per month including depreciation, insurance, maintenance, taxes, finance charges, and tires?
																						</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🚗</span>
																							{' '}
																							How will you get around? Will you buy or lease a vehicle, take public transportation or use ride sharing services? Research to find your best option.
																						</Typography>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 6 && questionList[5].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[3].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">You’re ahead of the game</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">😎</span>
																							{' '}
																							When you know how much you can spend on vacations and still reach your financial goals, you are way ahead of the game.
																						</Typography>
																						<p>
																							Vacations and spending time away from work are important aspects of a financial life plan. You need to make time for fun and budget for it, too.
																						</p>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							According to Bankrate, the average amount an American over age 18 spends per year on summer vacations is $1,979.
																						</p>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Vacation time is important!</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🚢</span>
																							{' '}
																							Vacations and spending time away from work are important aspects of a financial life plan. You need to make time for fun and budget for it, too.
																						</Typography>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							According to Bankrate, the average amount an American over age 18 spends per year on summer vacations is $1,979.
																						</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">😎</span>
																							{' '}
																							A great plan balances vacations with your other financial life goals.
																							{' '}
																						</Typography>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 7 && questionList[6].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[2].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">With a plan, you make things happen</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">It&apos;s great you&apos;re budgeting money to spend with family and friends.</Typography>
																						<p>You&apos;re setting aside important resources – your time and money – to make sure you can continue to enjoy relationships with friends and family members.</p>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know: 78% of Americans live paycheck-to-paycheck. Spending time with family and friends becomes a little more difficult without a safety net of savings.
																						</p>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">With a plan, you make things happen</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">Consider budgeting money to spend with family and friends.</Typography>
																						<p>When you set aside important resources – your time and money – you make sure you can continue to enjoy relationships with friends and family members.</p>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know: 78% of Americans live paycheck-to-paycheck. Spending time with family and friends becomes a little more difficult without a safety net of savings.
																						</p>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 8 && questionList[7].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[3].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Good planning!</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🚉</span>
																							{' '}
																							You&apos;re on track to building a solid financial picture for you and your family. Keep planning for your future!
																						</Typography>
																						<p>Starting a family is not only a big life change, it affects your financial life as well. For example, depending on your spouse&apos;s income, you may qualify for a lower tax bracket. And, two living under one roof costs less money!</p>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know the average cost of raising a child to age 17 is $12,980 per year, or $233,610?
																						</p>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Not so sure?</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">😕</span>
																							{' '}
																							It’s okay to be unsure – there’s a lot to consider.
																						</Typography>
																						<p>Starting a family is not only a big life change, it affects your financial life as well. For example, depending on your spouse’s income, you may qualify for a lower tax bracket.</p>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know the average cost of raising a child to age 17 is $12,980 per year, or $233,610?
																						</p>
																						<h5>
																							<span role="img" aria-label="Panda">👶</span>
																							{' '}
																							If your goal is starting a family, this requires good planning and preparation in your life and in your financial life.
																						</h5>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 9 && questionList[8].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[3].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">That’s impressive</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">😎</span>
																							{' '}
																							Budgeting for stuff that makes you happy is key to a solid financial life plan.
																						</Typography>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know 75% of Americans are just winging it when it comes to their financial future? How can they ever expect to buy those special things they&apos;ve always wanted without planning for them?
																						</p>

																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🗺</span>
																							{' '}
																							A plan is a roadmap to achieving your goals and dreams.
																						</Typography>
																						<p>Like an efficient navigation system, a plan helps you reach your destination in the shortest amount of time, saving you money.</p>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>
																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Plan for it</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">👋</span>
																							{' '}
																							A good plan should include your special things.
																						</Typography>
																						<p>A plan is a roadmap to achieving your goals and dreams. Like an efficient navigation system, a plan helps you reach your destination in the shortest amount of time, saving you money.</p>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know 75% of Americans are winging it when it comes to their financial future? How can they ever expect to acquire the special things they&apos;ve always wanted without planning for them?
																						</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🏔</span>
																							{' '}
																							You can acquire your dreams – you just need a solid plan.
																						</Typography>
																					</div>
																					<div className={ classes.lookNextQustn }>
																						<Button onClick={ () => handleQuestionTab( mainQuestion + 1 ) }>
																							LET&apos;S TAKE A LOOK AT THE NEXT QUESTION
																							<i className="las la-greater-than" />
																						</Button>
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{( mainQuestion === 10 && questionList[9].answerCorrect === true ) && (
																	subQuestionOption[mainQuestion - 1].options[2].answer === 1 ? (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>

																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Keep up the good work!</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">😎</span>
																							{' '}
																							Knowing how much you need to save and invest is the first step to retiring on your own terms.
																						</Typography>
																						<p>46% of Americans have $0 saved for retirement. Let that sink in for a moment. That is almost half!</p>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Did you know people with financial plans accumulate nearly 250% more retirement savings over their lifetime? That&apos;s not surprising considering it&apos;s 44% more likely you&apos;ll exceed your savings goals each year when you have a financial plan.
																						</p>
																					</div>
																				</div>
																			</div>

																		</div>
																	) : (
																		<div className={ classes.quickstartCriclesBtmCntTwo }>

																			<p className={ classes.largeStripeBarTwo } />
																			<div className={ classes.feedbckSec }>
																				<div className={ classes.feedbckSecDiv }>
																					<Typography variant="h4" component="h4">Make smart money moves now</Typography>
																					<div className={ classes.emojiBlock }>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">😱</span>
																							{' '}
																							46% of Americans have $0 saved for retirement. Let that sink in for a moment. That’s almost half!
																						</Typography>
																						<p>
																							<span role="img" aria-label="Panda">🤔</span>
																							{' '}
																							Imagine being one of the Americans aged 55 to 73 who hasn’t planned ahead and needs to save an average of $142,000 per year to have enough money for retirement.
																							{' '}
																						</p>
																						<p>
																							On the other hand, those with financial plans accumulate nearly 250% more retirement savings over their lifetime. That’s not surprising considering it’s 44% more likely you’ll exceed your savings goals each year when you have a financial plan. Contributing $300 per month to your retirement plan at 8% can get you to $1 million in 40 years.
																						</p>
																						<Typography variant="h5" component="h5">
																							<span role="img" aria-label="Panda">🙂</span>
																							{' '}
																							Make smart money moves now that will pay big dividends later.
																						</Typography>
																					</div>
																				</div>
																			</div>

																		</div>
																	)
																)}
															</div>
															)}
													</div>
													)}

													{( feedbackView || solveQuestions.length === 10 ) && (
														<div className={ classes.whatsMyScore } id="my-score-button">
															<Button onClick={ handleOpenFeedbackSection }>Feedback</Button>
														</div>
													)}

												</div>
											</div>
                </div>
                <div className={classes.mcsFooter}>
                    <div className={classes.container}>
                        <div className={classes.footerRow}>
                            <div className={classes.copyRight}>
                                <p>Copyright © 2022 // All rights reserved</p>
                            </div>
                            <div className={classNames(classes.copyRight, classes.informedText)}>
                                <p>
                                    Informed and Powered by
                                    <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.mcsFluid}>
                        <p className={classes.termLinks}>
                            <a href="/">Terms of Service</a>
                            &nbsp;//&nbsp;
                            <a href="/">Privacy Policy</a>
                            &nbsp;//&nbsp;
                            <a href="/">Security</a>
                        </p>
                    </div>
                </div>
            </div>
        </Typography>
    );
};

McsLevelOneActivityTwoQuestions.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(McsLevelOneActivityTwoQuestions);