import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';
import personalPurpose from '../../../../assets/img/pf101/personal_purpose.png';

const startTime = new Date();

const PersonalFinanceWithAPurpose = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src={ personalPurpose } alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Personal Finance with a Purpose</h3>
                  </div>
                  <div className={ classes.pfRow }>
                    <div className={ classes.creditText }>
                      What&apos;s the purpose of personal finance? Look at the question below and ask yourself, &quot;What if I had an answer to this question?&quot; This might be the most important question in your life. Inkwiry calls it The Big Question.
                      <p>Very important question wouldn’t you agree? If you had an answer to that question, wouldn’t you feel more confident everyday knowing you’re working towards your purpose?</p>
                      <p>One of the best ways to solve the Big &quot;Q&quot; is by designing, building, and launching your financial life plan. You&apos;ve learned the finer details of personal finance. What’s a loan, a credit card, an investment and so forth. But how do all those things come together?</p>
                      <p>With a financial life plan in hand, you will have answered all 10 of these important life questions.</p>
                    </div>
                  </div>
                  <div className={ classes.quickCirclesSection }>
                    <div className={ classes.quickCircles }>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleOne ) }>
                        <h2>1</h2>
                        <h3>
                          <span>
                            Will my
                            <b>Career</b>
                          </span>
                          <span>support the life</span>
                          <span>I want to live?</span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleTwo ) }>
                        <h2>2</h2>
                        <h3>
                          <span>How do I acquire</span>
                          <span>
                            the
                            <b>higher</b>
                          </span>
                          <span><b>education</b></span>
                          <span>I need?</span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleThree ) }>
                        <h2>3</h2>
                        <h3>
                          <span>Where is the</span>
                          <span><b>best place</b></span>
                          <span>
                            for me to
                            <b>live</b>
                          </span>
                          <span>
                            and
                            <b>work?</b>
                          </span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleFour ) }>
                        <h2>4</h2>
                        <h3>
                          <span>What type of</span>
                          <span>
                            <b>home</b>
                            {' '}
                            will I be
                          </span>
                          <span>able to afford and</span>
                          <span>when?</span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleFive ) }>
                        <h2>5</h2>
                        <h3>
                          <span>Should I get</span>
                          <span><b>my own wheels</b></span>
                          <span>or find another way</span>
                          <span>to get around?</span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleSix ) }>
                        <h2>6</h2>
                        <h3>
                          <span>How much can I</span>
                          <span>
                            <b>Spend</b>
                            {' '}
                            on
                          </span>
                          <span>Vacations?</span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleSeven ) }>
                        <h2>7</h2>
                        <h3>
                          <span>Will i have the time</span>
                          <span>and money for</span>
                          <span><b>family and</b></span>
                          <span>friends?</span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleEight ) }>
                        <h2>8</h2>
                        <h3>
                          <span>If i marry</span>
                          <span>how will that</span>
                          <span>affect my</span>
                          <span><b>financial life?</b></span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleNine ) }>
                        <h2>9</h2>
                        <h3>
                          <span>Will I be able to</span>
                          <span>
                            buy those
                            <b>Special</b>
                          </span>
                          <span>
                            <b>things</b>
                            {' '}
                            I&apos;ve
                          </span>
                          <span>always wanted?</span>
                        </h3>
                      </a>
                      <a href="/" className={ classNames( classes.quickCircle, classes.circleTen ) }>
                        <h2>10</h2>
                        <h3>
                          <span>Will I be able to</span>
                          <span><b>save enough</b></span>
                          <span>
                            <b>to retire</b>
                            {' '}
                            to my
                          </span>
                          <span>dream location?</span>
                        </h3>
                      </a>
                    </div>
                  </div>
                  <div className={ classes.pfRow }>
                    <p className={ classes.creditText }>
                      <b className={ classes.didYouKnow }>DID YOU KNOW:</b>
                      {' '}
                      <a className={ classes.anchorStyle } href="https://www.forbes.com/sites/jamiehopkins/2014/08/28/not-enough-people-have-financial-advisers-and-new-research-shows-they-should/?sh=7ef64b7452e5" target="_blank" rel="budget-fy2020 noopener noreferrer">According to HSBC</a>
                      , people with financial plans accumulated nearly 250% more retirement savings than those without a plan. And, just by writing down your goals on a regular basis, you increase your chances of achieving those goals by 42%.
                      <p>A plan is crucial and vital to your future. In this activity, Inkwiry will walk you through the Blueprint and demonstrate how you can build a 5, 10, 15 year Financial Life Plan that brings your future to life.</p>
                    </p>
                  </div>
                  <BottomContent />
                  <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level9/personal-finance-purpose/exercise" startTime={ startTime } level={ 9 } exercise={ 2 } />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
PersonalFinanceWithAPurpose.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( PersonalFinanceWithAPurpose );
