import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import WalkThruDots from './walkThruDots';
import styles from './styles';

const WalkThruFour = (props) => {
    const {
        classes, handleWalkThrus, handleWalkThruClose, completedWalkThruSlides, activeWalkThruSlide,handleWalkThrusBack,walkThruNo
    } = props;

    return (
        <div className={classNames(classes.worksheetWalkthru, classes.worksheetActiveWalkthru, classes.walkthruFour)}>
            <h4>Simulator Panel</h4>
            <p>The Simulator Panel is the interface to the Financial Configurator (FC) that enables you to change scenarios in any module, create new career sketches or load existing career sketches, and collaborate with family, friends, and trusted colleagues. It's a great tool to see your big picture vision from a different perspective while having full control of the FC.</p>
            <div className={classes.walkthruBottom}>
                <WalkThruDots walkThruNo={walkThruNo} completedWalkThruSlides={completedWalkThruSlides} activeWalkThruSlide={activeWalkThruSlide} handleWalkThrus={handleWalkThrus} />

                <div className={classes.walkThruBtns}>
                    <Button className={classes.backWalkThru} onClick={() => { handleWalkThrusBack(4) }}>Back</Button>
                    <Button className={classes.startWalkThru} onClick={() => { handleWalkThrus(4); }}>Next</Button>
                    <Button className={classes.skipWalkthru} onClick={handleWalkThruClose}>Close</Button>
                </div>
            </div>
        </div>
    );
};

WalkThruFour.propTypes = {
    classes: PropTypes.object.isRequired,
    handleWalkThrus: PropTypes.func.isRequired,
    handleWalkThruClose: PropTypes.func.isRequired,
    completedWalkThruSlides: PropTypes.object.isRequired,
    activeWalkThruSlide: PropTypes.string.isRequired,
    handleWalkThrusBack: PropTypes.func.isRequired,
    walkThruNo: PropTypes.string.isRequired,
};

export default withStyles(styles)(WalkThruFour);