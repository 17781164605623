import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import * as commonFunctions from '../../../utilities/commonFunctions';
import * as moduleServices from '../../../calculations/modules-services';
import styles from '../../../screens/pwi-career-advancement/styles';

import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const beginingArray = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 3,
    scaledValue: 3,
    label: '3%',
  },
  {
    value: 6,
    scaledValue: 6,
    label: '6%',
  },
  {
    value: 9,
    scaledValue: 9,
    label: '9%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 15,
    scaledValue: 15,
    label: '15%',
  },
];
const RothIRAContributions = ( props ) => {
  const {
    classes, handleCareerObject, handleDefinationPopup, handleUpdatedObject,
  } = props;
  const [personalValue, setPeronalValue] = React.useState( 7.00 );
  const [isFocus, setIsFocus] = React.useState('');

  useEffect( () => {
    const updatedValues = {...handleCareerObject};
    if ( handleCareerObject.roth_annual_expected_pre_tax !== undefined ) {
      setPeronalValue( handleCareerObject.roth_annual_expected_pre_tax );
    } else {
      updatedValues['roth_annual_expected_pre_tax'] = 7.00;
    }
    handleUpdatedObject(updatedValues);
    // eslint-disable-next-line
    }, [] );

  const updateValue = ( e, value, field, type ) => {
    const updatedValues = {...handleCareerObject};
    let newvalue = 0;
    if ( type === 'number' ) {
      newvalue = e.floatValue > 100 ? 100 : e.floatValue;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }
    setPeronalValue( newvalue );
    updatedValues[field] = newvalue;
    handleUpdatedObject( updatedValues );
  };
  const emptyIsFocus = () => {
    setIsFocus('');
  };

  return (
    <div className={ classes.contentBlock }>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classes.tooltipInfoTop } />
      <p>How much do you expect to earn on your pension contributions? Enter your annual return below.</p>
      {/* <div className={ classes.livingExpencesRow }>
        <div className={ classes.livingExpencesDiv }>
          <div className={ classes.livingExpences }>
            <div className={ `${classes.mainBlock} ${classes.livingExpencesRangeFlex} ${classes.mainFullBlock} ${classes.mainFullBlockFull}` }> */}
              {/* <div className={ `${classes.annuvalIncomeTittle} ${classes.annuvalIncomeTittleSpace} ${classes.annuvalIncomeTittleFull}` }>
                {' '}
                <span>Monthly</span>
                {' '}
              </div> */}
              <div className={ classes.AnnualIncomeGrowth }>
                <div>
                  <p>
                    <span
                      className={ classes.dottedText }
                      aria-hidden="true"
                      data-for="definationTitle"
                      data-tip="Click for a definition."
                      onClick={ () => handleDefinationPopup(
                        'Annual Expected Pre-Tax Return: Retirement Account',
                      ) }
                    >

                      Annual Expected Pre-Tax Return
                    </span>
                  </p>
                  <div className={ classes.annuvalIncomeGrowthGraph }>
                    <PwiSlider
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={ beginingArray }
                      min={ 0 }
                      max={ 15 }
                      step={ 0.1 }
                      value={ personalValue}
                      valueLabelFormat={ `${commonFunctions.perFormatter( parseFloat( handleCareerObject.roth_annual_expected_pre_tax ).toFixed( 2 ) )}` }
                      onChange={ ( e, value ) => updateValue( e, value, 'roth_annual_expected_pre_tax', 'slider' ) }
                    />
                  </div>
                  <div className={ classes.annuvalIncomeGrowthInput }>
                    <span className={ classes.opacityZero }>$</span>
                    <NumberFormat
                      customInput={ TextField }
                      value={personalValue}
                      thousandSeparator
                      decimalScale={isFocus!=='roth_annual_expected_pre_tax'?2:5}
                      fixedDecimalScale={isFocus!=='roth_annual_expected_pre_tax'}
                      onValueChange={ ( e ) => updateValue( e, '', 'roth_annual_expected_pre_tax', 'number' ) }
                      onFocus={ ( e ) => {
                        e.target.select();
                        setIsFocus('roth_annual_expected_pre_tax')
                      } }
                      onBlur={emptyIsFocus}
                    />
                    {' '}
                    {' '}
                    <span className={ classes.paddingLeftNew }>%</span>
                  </div>
                </div>
              </div>
            {/* </div>
          </div>
        </div>
      </div> */}
      <div className={ `${classes.heigherEducationSearch} ${classes.tutionFeesTable}` }>
        <div className={ `${classes.higherEducationStepFour} ${classes.tutionFeesTable70New}` }>
          <table cellPadding="0" cellSpacing="0">
              <thead class="">
                  <tr className={ classes.trHead }>
                      <td>IRA Retirement Accounts Summary</td>
                      <td>IRA</td>
                      <td>Roth IRA</td>
                  </tr>
              </thead>
            <tbody>
              <tr>
                <td>
                  <b>
                    Beginning Roth IRA Balance
                  </b>
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRABeginningBalance !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRABeginningBalance} prefix={moduleServices.module5Data.advCareerRothIRABeginningBalance >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRABeginningBalance < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRABeginningBalanceIra !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRABeginningBalanceIra} prefix={moduleServices.module5Data.advCareerRothIRABeginningBalanceIra >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRABeginningBalanceIra < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Total Contributions
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRATotalContribution !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRATotalContribution} prefix={moduleServices.module5Data.advCareerRothIRATotalContribution >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRATotalContribution < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRATotalContributionRoth !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRATotalContributionRoth} prefix={moduleServices.module5Data.advCareerRothIRATotalContributionRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRATotalContributionRoth < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  Total Earnings
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRATotalEarnings !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRATotalEarnings} prefix={moduleServices.module5Data.advCareerRothIRATotalEarnings >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRATotalEarnings < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRATotalEarningsRoth !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRATotalEarningsRoth} prefix={moduleServices.module5Data.advCareerRothIRATotalEarningsRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRATotalEarningsRoth < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
              <tr className={ `${classes.trFoot} ${classes.unBoldText}` }>
                <td>
                  Ending Roth IRA Balance
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRAEndingBalance !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRAEndingBalance} prefix={moduleServices.module5Data.advCareerRothIRAEndingBalance >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRAEndingBalance < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
                <td>
                  <span>
                    {moduleServices.module5Data.advCareerRothIRAEndingBalanceRoth !== undefined ? (
                      <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={moduleServices.module5Data.advCareerRothIRAEndingBalanceRoth} prefix={moduleServices.module5Data.advCareerRothIRAEndingBalanceRoth >= 0 ? '$' : '($'} suffix={moduleServices.module5Data.advCareerRothIRAEndingBalanceRoth < 0 && ')'} />
                    ) : ('$0')}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  );
};

RothIRAContributions.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( RothIRAContributions );
