import _ from 'lodash';

export function getfilteredCareerPath( updatedObj ) {
  const definedValues = [
    'roth_contribute_401k',
    'base_roth_income',
    'vested_amount_at_end_year',
    'ira_roth_contribute',
    'ira_roth_personal_contribution',
    'contribute_hsa_savings_account',
    'hsa_personal_contribution',
    'hsa_annual_expected',

    'self_employment',
    'occupation',
    'state_id',
    'state',
    'state_tax',
    'city',
    'city_taxes',
    'start_year',
    'end_year',
    'yearly_income',
    'bonus_or_tips_or_commission',
    'second_start_year',
    'second_end_year',
    'second_yearly_income',
    'rent',
    'rent_insurance',
    'rent_total',
    'utilities',
    'food',
    'grocery_shopping',
    'meals_eaten_not_at_home',
    'other_food',
    'clothing',
    'entertainment',
    'technology',
    'transportation',
    'miscellaneous',
    'medical_costs_before_deductible',
    'health_insurance_premium',
    'contribute_401k',
    'base_income',
    'employer_matching_rate',
    'employer_matching_limit',
    'Annual_expected401k',
    'roth_contribute',
    'roth_personal_contribution',
    'roth_annual_expected_pre_tax',
    'ira_contribute',
    'ira_beginning_balance',
    'ira_personal_contribution',
    'ira_annual_expected_pre_tax',
    'pension_contribute',
    'pension_employer_contribution',
    'pension_personal_contribution',
    'pension_annual_expected_pre_tax',
  ];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredInvestObject( updatedObj ) {
  const definedValues = [
    'short_term_start_year',
    'short_term_begining_balance',
    'short_term_max_sti_balance_before_lt_investment',
    'short_term_annual_contribution',
    'short_term_annual_contribution_growth_in_percentage',
    'short_term_annual_expected_return_in_percentage',
    'long_term_start_year',
    'long_term_begining_balance',
    'long_term_annual_contribution',
    'long_term_annual_contribution_in_percentage_growth',
    'long_term_annual_expected_return_in_percentage',
    'financial_gift_start_year',
    'financial_gift_end_year',
    'yearly_financial_gift',
    'charitable_start_year',
    'charitable_end_year',
    'charitable_yearly_contribution',
    'plan_529_start_year',
    'plan_529_annual_contribution_percentage',
    'plan_529_annual_expected_pre_tax_return',
    'life_insurance_start_year',
    'monthly_premium',
    'monthly_premium_percentage',
    'annual_expected_pre_tax_return_life_ins',
  ];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredVacationObject( updatedObj ) {
  const definedValues = [
    'end_year',
    'yearly_cost',
    'purchase_item',
    'second_start_year',
    'second_end_year',
    'second_yearly_cost',
    'second_purchase_item',
    'third_start_year',
    'third_end_year',
    'third_yearly_cost',
    'start_year',
  ];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredBuyingCarObject( updatedObj ) {
  const definedValues = [
    'leasing_car_start_year',
    'leasing_car_number_of_years',
    'leasing_car_down_payment',
    'leasing_car_drive_off_fees',
    'leasing_car_lease_payment',
    'leasing_car_maintenance',
    'leasing_car_insurance',
    'leasing_car_gas',
    'purchase_year',
    'base_price',
    'destination_charges',
    'car_options',
    'discount_in_percentage',
    'rebate',
    'tradein_existing_car',
    'save_change_existing_car_trade_value',
    'existing_car_trade_value',
    'down_payment_in_percentage',
    'sales_tax_in_percentage',
    'registration_and_documentation_fees',
    'term_of_the_loan',
    'interest_rate',
    'origination_fees',
    'first_car_maintenance',
    'first_car_insurance',
    'first_car_gas',
    'leasing_second_car_start_year',
    'leasing_second_car_number_of_years',
    'leasing_second_car_down_payment',
    'leasing_second_car_drive_off_fees',
    'leasing_second_car_lease_payment',
    'leasing_second_car_maintenance',
    'leasing_second_car_insurance',
    'leasing_second_car_gas',
    'second_purchase_year',
    'second_base_price',
    'second_destination_charges',
    'second_options',
    'second_discount',
    'second_rebate',
    'moretradein_existing_car',
    'tradein_existing_car_2',
    'tradein_existing_car_3',
    'tradein_existing_car_4',
    'moretradein_existing_car_2',
    'moretradein_existing_car_3',
    'moretradein_existing_car_4',
    'save_change_first_car_trade_value',
    'first_car_trade_value',
    'second_down_payment_in_percentage',
    'second_sales_tax',
    'second_registration_and_documentation_fees',
    'second_term_of_the_loan',
    'second_interest_rate',
    'second_origination_fees',
    'second_car_maintenance',
    'second_car_insurance',
    'second_car_gas',
  ];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredPWIFI( updatedObj ) {
  const definedValues = ['start_year', 'long_term_growth_in_percentage', 'inflation_in_percentage'];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredPWIFamily( updatedObj ) {
  const definedValues = ['marriege_year', 'wedding_costs', 'occupation', 'start_year', 'end_year', 'spouse_income',
    'spouse_bonus_or_tips_or_commission', 'second_occupation', 'second_start_year', 'second_end_year', 'second_spouse_income',
    'second_spouse_bonus_or_tips_or_commission', 'rent_multiplier', 'utilities_multiplier', 'food_multiplier', 'clothing_multiplier',
    'entertainment_multiplier', 'technology_multiplier', 'transportation_multiplier', 'miscellaneous_multiplier', 'health_insurance_costs_multiplier',
    'health_insurance_premium_multiplier', 'spouse_student_loan', 'spouse_ideal_repayment_schedule', 'spouses_interest_rate', 'number_of_children',
    'first_born', 'second_born', 'third_born', 'fourth_born', 'fifth_born', 'sixth_born', 'seventh_born',
    'save_average_cost_raising_child', 'housing_cost', 'food_cost', 'transportation_cost', 'clothing_cost', 'health_care_cost',
    'child_care_and_education', 'other_costs', 'year_of_purchase', 'type_of_pet', 'pet_name', 'purchase_price',
    'save_annual_pet_new_values', 'default_annual_pet_vet_visits', 'default_annual_pet_food', 'default_annual_pet_toys', 'default_annual_pet_emergency_fund', 'default_annual_pet_health_insurance',
    'default_annual_pet_registration', 'default_annual_pet_miscellaneous', 'save_buying_pet_new_values', 'default_pet_first_vet_visit',
    'default_pet_new_supplies', 'default_pet_miscellaneous',
  ];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredPWIhigherEducation( updatedObj ) {
  const definedValues = ['type_of_higher_education', 'name_of_college', 'state_id', 'state', 'start_year', 'years_diff', 'graduation_year',
    'tuition', 'room_board', 'yearly_scholarship_amount', 'yearly_grant_amount', 'year_aid_begins', 'year_aid_ends',
    'expected_yearly_finance_aid', 'income_start_year', 'income_end_year', 'yearly_income',
    'family_start_year', 'family_end_year', 'yearly_contribution', 'dependent_status', 'fed_sub_firstyear',
    'fed_sub_secondyear', 'fed_sub_thirdyear', 'fed_sub_fourthyear', 'fed_sub_fifthyear', 'fed_sub_sixthyear',
    'fed_sub_term', 'fed_sub_interest_rate', 'fed_sub_origination_fee', 'fed_unsub_firstyear', 'fed_unsub_secondyear',
    'fed_unsub_thirdyear', 'fed_unsub_fourthyear', 'fed_unsub_fifthyear', 'fed_unsub_sixthyear', 'fed_unsub_term',
    'fed_unsub_interest_rate', 'fed_unsub_paymentplan', 'fed_unsub_origination_fee', 'private_firstyear',
    'private_secondyear', 'private_thirdyear', 'private_fourthyear', 'private_fifthyear',
    'private_sixthyear', 'private_term', 'private_interest_rate', 'private_paymentplan', 'private_origination_fee', 'on_campus_start_year',
    'off_campus_food', 'clothing', 'entertainment', 'technology', 'transportation', 'miscellaneous',
    'medical_costs_before_deductible', 'health_insurance_premium', 'off_campus_start_year', 'rent', 'utilities', 'food',
    'second_clothing', 'second_entertainment', 'second_technology', 'second_transportation', 'second_miscellaneous',
    'second_medical_costs_before_deductible', 'second_health_insurance_premium',
  ];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredBuyingHomeObject( updatedObj ) {
  const definedValues = ['year_of_purchase', 'purchase_price', 'down_payment_in_percentage', 'transfer_taxes_in_percentage', 'title_insurance',
    'external_and_internal_fit_out_costs', 'property_tax_in_percentage', 'home_owners_insurance_in_percentage', 'yearly_maintanance_in_percentage',
    'yearly_association_fees', 'monthly_utilities', 'appreciation_in_percentage', 'private_mortgage_insurance', 'term_of_mortgage', 'rate_of_mortgage_in_percentage',
    'points_for_the_mortgage', 'origination_fees', 'save_home_closing_costs_new_values', 'closing_appraisal_fee', 'closing_credit_report_fee', 'closing_escrow_account',
    'closing_flood_determination', 'closing_tax_service_fee', 'closing_recording_mortgage', 'closing_processing_fee', 'closing_underwriting_fee', 'closing_pest_Inspection',
    'closing_home_owners_association_transfer_fees', 'closing_costs_others'];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredBuyingReiObject( updatedObj ) {
  const definedValues = ['year_of_purchase', 'year_of_sale', 'purchase_price', 'down_payment_in_percentage', 'transfer_taxes_in_percentage',
    'title_insurance', 'property_taxes_in_percentage', 'external_and_internal_fit_out_costs', 'term_of_mortgage', 'rate_of_mortgage_prcentage',
    'points_of_mortgage', 'origination_fees', 'yearly_rental_income', 'vacancy_rate_in_percentage', 'annual_income_growth_rate',
    'yearly_association_fees', 'yearly_maintanance_percantage', 'managemtnt_fee_in_percentage', 'monthly_utilities', 'home_owners_insurance_in_percentage',
    'appreciation_in_percentage', 'selling_costs_in_percentage', 'save_rei_closing_costs_new_values', 'closing_appraisal_fee', 'closing_credit_report_fee',
    'closing_escrow_account', 'closing_flood_determination', 'closing_tax_service_fee', 'closing_recording_mortgage', 'closing_processing_fee',
    'closing_underwriting_fee', 'closing_pest_Inspection', 'closing_home_owners_association_transfer_fees', 'closing_costs_others'];
  return _.pick( updatedObj, definedValues );
}

export function getfilteredExistingObject( updatedObj ) {
  const definedValues = [
    // # New in 'pwi',
    'short_term_traditional_savings_account',
    'short_term_high_yield_savings_account',
    'short_term_certificates_of_deposit',
    'short_term_money_market_funds',
    'short_term_other',
    'max_term_balance_val',
    'long_term_managed_accounts',
    'long_term_stocks_and_bonds',
    'long_term_etfs',
    'long_term_mutual_funds',
    'long_term_reits',
    'long_term_other',
    // # Done
    'personal_loan_current_personal_balance',
    'personal_loan_months_remaining_on_loan',
    'personal_loans_interest_rate',
    'existing_cash',
    'existing_checking_accounts',
    'existing_max_balance',
    'existing_total_short_term_investments',
    'existing_total_long_term_investments',
    'existing_max_short_term_before_long_term',
    'existing_total_value_of_hsa',
    'existing_529_plan',
    'existing_cash_value_life_insurance_policies',
    'existing_traditional_ra',
    'existing_401k_403b',
    'existing_roth_ira',
    'existing_roth_401k_403b',
    'existing_sep_ira',
    'existing_pension',
    'existing_art',
    'existing_jewelry',
    'existing_other',
    'existing_value_of_business', 
    'existing_expected_growth_rate',  
    'vehicle_1_type', 
    'existing_other_expected_growth_rate',  
    'existing_car_current_value', 
    'existing_car_current_loan_balance',  
    'existing_car_months_to_maturity',  
    'existing_car_interest_rate', 
    'existing_car_loans_lease_months_remaining',  
    'existing_car_loans_lease_maintenance', 
    'existing_car_loans_lease_insurance', 
    'existing_car_loans_lease_gas',  
    'existing_car_loans_own_maintenance', 
    'existing_car_loans_own_insurance', 
    'existing_car_loans_own_gas', 
    'existing_car_loans_own_maintenance_2', 
    'existing_car_loans_own_insurance_2', 
    'existing_car_loans_own_gas_2',   
    'existing_car_loans_own_maintenance_3', 
    'existing_car_loans_own_insurance_3', 
    'existing_car_loans_own_gas_3',  
    'existing_car_loans_own_maintenance_4', 
    'existing_car_loans_own_insurance_4', 
    'existing_car_loans_own_gas_4', 
    'vehicle_2_type', 
    'existing_other_expected_growth_rate_2',  
    'existing_car_current_value_2', 
    'existing_car_current_loan_balance_2',  
    'existing_car_months_to_maturity_2',  
    'existing_car_interest_rate_2', 
    'existing_car_loans_lease_months_remaining_2',  
    'existing_car_loans_lease_maintenance_2', 
    'existing_car_loans_lease_insurance_2', 
    'existing_car_loans_lease_gas_2', 
    'vehicle_3_type', 
    'existing_other_expected_growth_rate_3',  
    'existing_car_current_value_3', 
    'existing_car_current_loan_balance_3',  
    'existing_car_months_to_maturity_3',  
    'existing_car_interest_rate_3', 
    'existing_car_loans_lease_months_remaining_3',  
    'existing_car_loans_lease_maintenance_3', 
    'existing_car_loans_lease_insurance_3', 
    'existing_car_loans_lease_gas_3', 
    'vehicle_4_type', 
    'existing_other_expected_growth_rate_4',  
    'existing_car_current_value_4', 
    'existing_car_current_loan_balance_4',  
    'existing_car_months_to_maturity_4',  
    'existing_car_interest_rate_4',
    'existing_car_loans_lease_months_remaining_4',  
    'existing_car_loans_lease_maintenance_4', 
    'existing_car_loans_lease_insurance_4', 
    'existing_car_loans_lease_gas_4', 
    'existing_car_loans_lease_payment',
    'existing_car_loans_lease_payment_2',
    'existing_car_loans_lease_payment_3',
    'existing_car_loans_lease_payment_4',
    'married_status', 
    'primary_residence', 
    'fed_sub_type_of_higher_education',
    'fed_sub_years',
    'fed_sub_months',
    'fed_sub_current_balance',
    'fed_sub_term',
    'fed_sub_interest_rate',
    'fed_unsub_type_of_higher_education',
    'fed_unsub_years',
    'fed_unsub_months',
    'fed_unsub_loan_amount',
    'fed_unsub_accrued_interest',
    'fed_unsub_term',
    'fed_unsub_interest_rate',
    'fed_unsub_payment_plan',
    'private_type_of_higher_education',
    'private_years',
    'private_months',
    'private_loan_amount',
    'private_accrued_interest',
    'private_term',
    'private_interest_rate',
    'private_payment_plan',
    'existing_credit_card_balance',
    'ideal_repayment_schedule',
    'creditcard_interest_rate',
    'existing_car_current_value',
    'existing_car_current_loan_balance',
    'existing_car_months_to_maturity',
    'existing_car_interest_rate',
    'existing_car_loans_maintenance',
    'existing_car_loans_lnsurance',
    'existing_car_loans_gas',
    'existig_home_current_value',
    'existig_home_basis_costs',
    'existing_home_annual_appreciation',
    'existig_home_current_mortgage_balance',
    'existig_home_mortgage_months_to_maturity',
    'existig_home_mortgage_interest_rate',
    'existing_home_monthly_property_taxes',
    'existing_home_monthly_homeowner_insurance',
    'existing_home_monthly_private_mortgage_insurance',
    'existing_home_monthly_maintenance',
    'existing_home_monthly_association_fees',
    'existing_home_monthly_utilities',
    'existing_home_sale_year',
    'existing_home_selling_costs',
    'existing_home_selling_costs_2',
    'married_status_2',
    'primary_residence_2',
    'existing_home_sale_year_2',
    'existing_home_monthly_utilities_2',
    'existing_home_monthly_private_mortgage_insurance_2',
    'existig_home_mortgage_interest_rate_2',
    'existig_home_mortgage_months_to_maturity_2',
    'existing_home_annual_appreciation_2',
    'existig_home_current_mortgage_balance_2',
    'existig_home_basis_costs_2',
    'existig_home_current_value_2',
    'existing_home_monthly_association_fees_2',
    'existing_home_monthly_maintenance_2',
    'existing_home_monthly_homeowner_insurance_2',
    'existing_home_monthly_property_taxes_2',
    'current_home_sale_in_15_years_1',
    'current_home_sale_in_15_years_2',
    'credit_card_status',
    'personal_loans_status',
    'federal_student_loans_status',
    'private_student_loans_status',
    'existig_home_current_status',
    'existig_home_current_status_2',
  ];

  return _.pick( updatedObj, definedValues );
}
