import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Table,
} from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import queryString from 'query-string';
import ContinueButton from '../continue-button';
import BottomContent from '../bottom-content';
import styles from '../styles';
import goldLogo from '../../../../assets/img/pf101/powerup-101-gold-wlogo.png';

const startTime = new Date();

const BudgetingGuide = ( props ) => {
  const { classes, location } = props;
  const params = queryString.parse( location.search );
  useEffect( () => {
    window.scrollTo( 0, 0 );
  }, [] );

  return (
    <Typography variant="body1" component="div">
      <div className={ classes.containerFluid }>
        <div className={ classes.contentPage }>
          <div className={ classes.mainSection }>
            <div className={ classes.pfBudgetPage }>
              <div>
                <ul className={ classes.breadCrumbs }>
                  <li>
                    <span>&lt;&nbsp;</span>
                    <Link to="/pf101/dashboard">Back to PF101 Dashboard</Link>
                  </li>
                </ul>
                <div className={ classes.activityRow }>
                  <div className={ classes.blueRow } />
                  <div className={ classes.logoBlock }>
                    <img src={ goldLogo } alt="logo" />
                  </div>
                  <div className={ classes.pfImage }>
                    <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/budgeting-guidelines.png" alt="template" />
                  </div>
                  <div className={ classes.pfHeading }>
                    <h3>Budgeting Guidelines</h3>
                  </div>
                  <div className={ classes.pfContent }>
                    <div className={ classes.pfRow }>
                      <div className={ classes.pfSlideImage }>
                        <img src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/budget-guidelines-left.jpg" alt="template" />
                      </div>
                      <div className={ classes.pfText }>
                        <p className={ classes.coverAnything }>
                          When building a budget, there are a few simple guidelines to follow and benchmarks to consider as you analyze your spending and set financial goals. The right mindset about budgeting can make all the difference.
                        </p>
                        <p>
                          When you drive from New York City to Florida, you use a map or navigation app so you don&apos;t get lost and get there on time. The same thing goes for your finances. You&apos;re trying to achieve financial life goals and you need a road map to get there. Budgeting is a crucial part of that road map.
                        </p>
                        <p>
                          Budgeting doesn&apos;t have to be complex or difficult. Keep it simple by categorizing your expenses and be honest with yourself about your finances.
                        </p>
                      </div>
                    </div>
                    <div className={ classes.pfRow }>
                      <p className={ classes.creditText }>
                        Use the categories and percentages below as guidelines when building your own budget:
                      </p>
                    </div>
                    <div className={ classes.budgetingTable }>
                      <Table>
                        <thead>
                          <tr>
                            <th colSpan="2">Budgeting Guidelines</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Rent & Utilities / Home Expenses</td>
                            <td>20% of After-Tax Income</td>
                          </tr>
                          <tr>
                            <td>Food & Drink</td>
                            <td>15%</td>
                          </tr>
                          <tr>
                            <td>Transportation & Car</td>
                            <td>12%</td>
                          </tr>
                          <tr>
                            <td>Clothing & Personal Care</td>
                            <td>7%</td>
                          </tr>
                          <tr>
                            <td>Entertainment & Technology</td>
                            <td>7%</td>
                          </tr>
                          <tr>
                            <td>Health Insurance & Healthcare</td>
                            <td>5%</td>
                          </tr>
                          <tr>
                            <td>Pet</td>
                            <td>2%</td>
                          </tr>
                          <tr>
                            <td>Miscellaneous</td>
                            <td>2%</td>
                          </tr>
                          <tr>
                            <td>Loan Payments</td>
                            <td>15-20%</td>
                          </tr>
                          <tr>
                            <td>Cash Left Over for Savings / Investments</td>
                            <td>10-20%</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <BottomContent />
                    <ContinueButton revisit={ params.revisit !== undefined && params.revisit } url="/pf101/level3/budgeting-guide/exercise" startTime={ startTime } level={ 3 } exercise={ 2 } />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

BudgetingGuide.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const enhance = compose(
  withStyles( styles ),
  withRouter,
);

export default enhance( BudgetingGuide );
