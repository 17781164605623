import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, Button,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';

import CareerBottomTabs from './career-bottom-tab-graphs';
import * as commonFunctions from '../../utilities/commonFunctions';
import * as moduleServices from '../../calculations/modules-services';
import styles from '../../screens/pwi-career-path/styles';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import infoIcon from '../../assets/img/pwi-he/info-icon.svg';
import SideGraphDetails from './career-path-side-graph';

const FoodValues = [
  {
    value: 0,
    scaledValue: 0,
    label: '$0',
  },
  {
    value: 400,
    scaledValue: 400,
    label: '$400',
  },
  {
    value: 800,
    scaledValue: 800,
    label: '$800',
  },
  {
    value: 1200,
    scaledValue: 1200,
    label: '$1.2k',
  },
  {
    value: 1600,
    scaledValue: 1600,
    label: '$1.6k',
  },
  {
    value: 2000,
    scaledValue: 2000,
    label: '$2k',
  },
];

const Food = (props) => {
  const {
    classes, handleCareerObject, handleUpdatedObject, sideGraphData,
  } = props;
  const [grocery, setGrocery] = React.useState(0);
  const [meals, setMeals] = React.useState(0);
  const [foodOther, setFoodOther] = React.useState(0);

  useEffect(() => {
    const updatedValues = { ...handleCareerObject };
    if (handleCareerObject.grocery_shopping !== undefined) {
      setGrocery(handleCareerObject.grocery_shopping);
    } else {
      updatedValues['grocery_shopping'] = 0;
    }

    if (handleCareerObject.meals_eaten_not_at_home !== undefined) {
      setMeals(handleCareerObject.meals_eaten_not_at_home);
    } else {
      updatedValues['meals_eaten_not_at_home'] = 0;
    }

    if (handleCareerObject.food !== undefined) {
      setFoodOther(handleCareerObject.food);
    } else {
      updatedValues['food'] = 0;
    }
    handleUpdatedObject(updatedValues);

    // eslint-disable-next-line
  }, []);

  const updateValue = (e, value, field, type) => {
    const updatedValues = { ...handleCareerObject };
    let newvalue = 0;
    if (type === 'input') {
      newvalue = e.target.value;
    } else if (type === 'slider') {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch (field) {
      case 'grocery_shopping': {
        setGrocery(newvalue);
        break;
      }
      case 'meals_eaten_not_at_home': {
        setMeals(newvalue);
        break;
      }
      case 'food': {
        setFoodOther(newvalue);
        break;
      }
      default:
        break;
    }
    updatedValues[field] = newvalue;
    handleUpdatedObject(updatedValues);


  };

  const handleBenchMarks = () => {
    const incomeSheetData = moduleServices.incomeStatementCompleteData();
    let incomeAfterTaxesValue = 0;
    if (incomeSheetData !== undefined && incomeSheetData !== '' && incomeSheetData[handleCareerObject.start_year] !== undefined && incomeSheetData[handleCareerObject.start_year] !== '' && incomeSheetData[handleCareerObject.start_year]['Income After Taxes'] !== undefined) {
      incomeAfterTaxesValue = incomeSheetData[handleCareerObject.start_year]['Income After Taxes'];
    }  
    setGrocery(((incomeAfterTaxesValue / 12) / 100) * 10);
    setMeals(((incomeAfterTaxesValue / 12) / 100) * 5);
    setFoodOther(0);
    handleCareerObject.grocery_shopping = ((incomeAfterTaxesValue / 12) / 100) * 10;
    handleCareerObject.meals_eaten_not_at_home = ((incomeAfterTaxesValue / 12) / 100) * 5;
    handleCareerObject.food = 0;
  };

  return (
    <div className={classes.contentMainBlock}>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classes.definitionTooltips} />
      <p>
        How much do you spend on food each month? Enter your details using the categories below.
      </p>
      <div className={`${classes.grayBox} ${classes.grayBoxRent} ${classes.grayBoxRentNew}`}>
        <p>
          <font>FINANCE EXPLAINED TIP:</font>
          {' '}
          As a benchmark, food should be 15.0% or less of your income after taxes. If you're not sure what your expenses might be for this career move, click on 'Use Benchmark Values &gt;' to automate your budget.
        </p>
        <Button onClick={handleBenchMarks} className={classes.BahanceBtn}>USE BENCHMARK VALUE</Button>
      </div>
      <div className={`${classes.livingExpences} ${classes.livingExpencesFlexEnd}`}>
        <div className={classes.mainBlockRow}>
          <div className={classes.mainBlock}>
            <div className={classes.rentGraphBlock}>
              <div className={classes.livingExpencesRange}>
                <h3 className={classes.opacityZero}>Monthly</h3>
                <span>Grocery Shopping</span>
                <div className={classes.annuvalIncomeGraph}>
                  <PwiSlider
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={FoodValues}
                    decimalScale={2}
                    min={0}
                    max={2000}
                    step={10}
                    value={grocery}
                    valueLabelFormat={`${commonFunctions.numFormatter(grocery)}`}
                    onChange={(e, value) => updateValue(e, value, 'grocery_shopping', 'slider')}
                  />
                </div>
              </div>
              <div className={classes.livingExpencesMothly}>
                <h3 className={classes.livingTitle}><span>Monthly</span></h3>
                <div className={classes.annualInput}>
                  <span>$</span>
                  <NumberFormat
                    customInput={TextField}
                    thousandSeparator
                    value={grocery}
                    decimalScale={0}
                    onValueChange={(e) => updateValue(e, '', 'grocery_shopping', 'number')}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                  />
                </div>
              </div>
              <div className={classes.livingExpencesMothly}>
                <h3 className={classes.livingTitle}><span>Annual</span></h3>
                <div className={classes.annualInput}>
                  <span>
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={grocery * 12} prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.rentGraphBlock}>
              <div className={classes.livingExpencesRange}>
                <span>Meals Eaten Not at Home</span>
                <div className={classes.annuvalIncomeGraph}>
                  <PwiSlider
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={FoodValues}
                    decimalScale={2}
                    min={0}
                    max={2000}
                    step={10}
                    value={meals}
                    valueLabelFormat={`${commonFunctions.numFormatter(meals)}`}
                    onChange={(e, value) => updateValue(e, value, 'meals_eaten_not_at_home', 'slider')}
                  />
                </div>
              </div>
              <div className={`${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}`}>
                <div className={classes.annualInput}>
                  <span>$</span>
                  <NumberFormat
                    customInput={TextField}
                    thousandSeparator
                    value={meals}
                    decimalScale={0}
                    onValueChange={(e) => updateValue(e, '', 'meals_eaten_not_at_home', 'number')}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                  />
                </div>
              </div>
              <div className={`${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}`}>
                <div className={classes.annualInput}>
                  <span>
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={meals * 12} prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={classes.rentGraphBlock}>
              <div className={classes.livingExpencesRange}>
                <span>
                  Other
                  <img className={classes.InfoIconNew} src={infoIcon} alt="" data-for="definationTitle" data-tip="Coffee, take out food, drinks with friends, or anything else not covered by the first two categories should go into this bucket." />
                </span>
                <div className={classes.annuvalIncomeGraph}>
                  <PwiSlider
                    ValueLabelComponent={ValueLabelComponent}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    marks={FoodValues}
                    decimalScale={2}
                    min={0}
                    max={2000}
                    step={10}
                    value={foodOther}
                    valueLabelFormat={`${commonFunctions.numFormatter(foodOther)}`}
                    onChange={(e, value) => updateValue(e, value, 'food', 'slider')}
                  />
                </div>
              </div>
              <div className={`${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}`}>
                <div className={classes.annualInput}>
                  <span>$</span>
                  <NumberFormat
                    customInput={TextField}
                    thousandSeparator
                    value={foodOther}
                    decimalScale={0}
                    onValueChange={(e) => updateValue(e, '', 'food', 'number')}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                  />
                </div>
              </div>
              <div className={`${classes.livingExpencesMothly} ${classes.livingExpencesMothlyXtra}`}>
                <div className={classes.annualInput}>
                  <span>
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={foodOther * 12} prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={`${classes.rentGraphBlock} ${classes.rentGraphBlockNew} ${classes.rentPlusUntils}`}>
              <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}`}>
                <p className={classes.textRight}>Total Food Expense</p>
              </div>
              <div className={classes.livingExpencesMothly}>
                <div className={classes.annualInput}>
                  <span>
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={grocery + meals + foodOther} prefix="$" />
                  </span>
                </div>
              </div>
              <div className={classes.livingExpencesMothly}>
                <div className={classes.annualInput}>
                  <span>
                    <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" allowNegative={false} thousandSeparator value={(grocery + meals + foodOther) * 12} prefix="$" />
                  </span>
                </div>
              </div>
            </div>
            <div className={`${classes.rentGraphBlock} ${classes.rentGraphBlockItalic}`}>
              <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}`}>
                <p className={classes.textRight}>% of Income after Taxes</p>
              </div>
              <div className={classes.livingExpencesMothly}>

                <div className={classes.annualInput}>
                  <span><i>
                    {(sideGraphData.incomeAfterTaxesValue !== undefined && sideGraphData.incomeAfterTaxesValue !== 0) ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={((grocery + meals + foodOther) * 12) / sideGraphData.incomeAfterTaxesValue} prefix={sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '('} suffix={sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%'} />
                    ) : ('0.00%')}
                  </i></span>
                </div>
              </div>
              <div className={classes.livingExpencesMothly}>
                <div className={classes.annualInput}>
                  <span><i>
                    {(sideGraphData.incomeAfterTaxesValue !== undefined && sideGraphData.incomeAfterTaxesValue !== 0) ? (
                      <NumberFormat decimalScale={2} fixedDecimalScale allowNegative={false} displayType="text" thousandSeparator value={(grocery + meals + foodOther) / sideGraphData.incomeAfterTaxesValue} prefix={sideGraphData.incomeAfterTaxesValue >= 0 ? '' : '('} suffix={sideGraphData.incomeAfterTaxesValue < 0 ? '%)' : '%'} />
                    ) : ('0.00%')}
                  </i></span>
                </div>
              </div>
            </div>
            <div className={`${classes.rentGraphBlock} ${classes.rentGraphBlockNew}`}>
              <div className={`${classes.livingExpencesRange} ${classes.livingExpencesRangePlain}`}>
                <p className={classes.textRight}>Total Living Expenses</p>
              </div>
              <div className={classes.livingExpencesMothly}>

                <div className={classes.annualInput}>
                  <span>
                    <b>
                      {moduleServices.module4Data.livingExpensesCareerPath !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" className={moduleServices.module4Data.livingExpensesCareerPath < 0 ? classes.textDanger : ''} allowNegative={false} thousandSeparator value={moduleServices.module4Data.livingExpensesCareerPath} prefix={moduleServices.module4Data.livingExpensesCareerPath >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.livingExpensesCareerPath < 0 && ')'} />
                      ) : ('$0')}

                    </b>
                  </span>
                </div>
              </div>
              <div className={classes.livingExpencesMothly}>
                <div className={classes.annualInput}>
                  <span>
                    <b>
                      {moduleServices.module4Data.careerPathLivingExpenses !== undefined ? (
                        <NumberFormat decimalScale={0} fixedDecimalScale displayType="text" className={moduleServices.module4Data.careerPathLivingExpenses < 0 ? classes.textDanger : ''} allowNegative={false} thousandSeparator value={moduleServices.module4Data.careerPathLivingExpenses * 12} prefix={moduleServices.module4Data.careerPathLivingExpenses >= 0 ? '$' : '($'} suffix={moduleServices.module4Data.careerPathLivingExpenses < 0 && ')'} />
                      ) : ('$0')}
                    </b>
                  </span>
                </div>
              </div>
            </div>

          </div>

          <SideGraphDetails sideGraphData={sideGraphData} />
        </div>
      </div>
      <div className={classes.borderDevider} />
      <CareerBottomTabs handleCareerObject={handleCareerObject} />

    </div>

  );
};

Food.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCareerObject: PropTypes.object.isRequired,
  handleUpdatedObject: PropTypes.func.isRequired,
  sideGraphData: PropTypes.object.isRequired,

};

export default withStyles(styles)(Food);
