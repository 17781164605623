import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { TextField, Typography } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import ReactTooltip from 'react-tooltip';
import styles from '../../screens/pwi-investments/styles';
import InvestmentBottomThree from './Investment-bottomThree';
import * as modulesServices from '../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../common/pwi/slider-custome-tool-tip';
import { percentFormatWithTwoDecimal } from '../common/pwi/slider-functions';

const marks = [
  {
    value: 0,
    scaledValue: 0,
    label: '0%',
  },
  {
    value: 4,
    scaledValue: 4,
    label: '4%',
  },
  {
    value: 8,
    scaledValue: 8,
    label: '8%',
  },
  {
    value: 12,
    scaledValue: 12,
    label: '12%',
  },
  {
    value: 16,
    scaledValue: 16,
    label: '16%',
  },
  {
    value: 20,
    scaledValue: 20,
    label: '20%',
  },
];

const LongTermBeginingBalance = (props) => {
  const {
    classes,  getInvestmentObject, handleInvestmentData, setOpenWorkSheet
  } = props;
  const [begBalPercentage, setBegBalPercentage] = React.useState(getInvestmentObject.long_term_annual_contribution_in_percentage_growth !== undefined ? getInvestmentObject.long_term_annual_contribution_in_percentage_growth : 0)
  const [begBalValue, setBegBalValue] = React.useState('');

  React.useEffect(() => {
    let bignningBalance = getInvestmentObject.long_term_annual_contribution_in_percentage_growth;
    var preamount = modulesServices.afterTaxIncomeInYearLongTerm();
    let value = (preamount/100)*bignningBalance;
    let sValue = 0;
    if (value >= 0) {
      sValue = value;
    } else {
      sValue = 0;
    }
    setBegBalValue(sValue/12);
  }, []);


  const updateValue = (e, value, field, type) => {
    const beginingBalanceObj = { ...getInvestmentObject };
    let Invalue = e.floatValue ? e.floatValue : 0;  
    let newValue = value;      
    var preamount = modulesServices.afterTaxIncomeInYearLongTerm();
    if (type === 'slider') {
      let lvalue = 0;
      const bignningBalance = begBalPercentage;
      if (bignningBalance > 0 && preamount > 0) {
        lvalue = (preamount/100)*bignningBalance;
      } else {
        lvalue = 0;
      }
      setBegBalPercentage(newValue);
      setBegBalValue(lvalue/12);
      beginingBalanceObj['long_term_annual_contribution_in_percentage_growth'] = newValue;
    }
    if (type === 'inputTwo') {      
      let bignningBalance = Invalue*12;
      if (bignningBalance > 0 && preamount > 0) {
        setBegBalPercentage((bignningBalance/preamount) * 100);
        beginingBalanceObj['long_term_annual_contribution_in_percentage_growth'] = (bignningBalance/preamount) * 100;
      }else{
        setBegBalPercentage(0);
        beginingBalanceObj['long_term_annual_contribution_in_percentage_growth'] = 0;
      }
      setBegBalValue(Invalue);
    }
    handleInvestmentData(beginingBalanceObj);
  };


  return (
    <div className={classes.contentBlock}>
      <p>
        How much are you contributing to long-term investments as a percentage of after-tax income? A good benchmark to aim for would be contributing 10% of your after-tax income.
      </p>
      <p>How much do you expect to earn on those investments?</p>
      <div className={classes.balanceBox}>
        <Typography variant="h3" component="h3">
          After-Tax Income in year {getInvestmentObject.long_term_start_year}&nbsp; : {''}
          <span className={modulesServices.module9Data.afterTaxIncomeInYearLongTerm < 0 ? classes.dangerText : ''}><NumberFormat decimalScale={0} allowNegative={false} fixedDecimalScale displayType="text" thousandSeparator value={modulesServices.module9Data.afterTaxIncomeInYearLongTerm ? modulesServices.module9Data.afterTaxIncomeInYearLongTerm : 0} prefix="$" /></span>          
        </Typography>
      </div>
      <div className={ classes.innnerFlowGroupMainFull }>
        <div className={ `${classes.innnerFlowGroupMain} ${classes.innnerFlowGroupMaingrants}` }>
          <div className={ `${classes.innnerFlowGroup} ${classes.innnerFlowGroupScholor}` }>
            <div className={ `${classes.garphLeft} ${classes.garphLeftScholor}` }>
              <h3>&nbsp;</h3>
              <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholorScolorAndGrants} ${classes.annuvalGrowthScholorBT0}` }>
                <div>
                  <p><span>Contribution as a % of After-Tax Income</span></p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      ValueLabelComponent={ValueLabelComponent}
                      valueLabelFormat={percentFormatWithTwoDecimal}
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      marks={marks}
                      min={0}
                      max={20}
                      step={0.60}
                      value={begBalPercentage}
                      onChange={(e, value) => { updateValue(e, value, 'long_term_annual_contribution_in_percentage_growth', 'slider'); }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={ `${classes.garphRight} ${classes.garphRightScholor} ${classes.garphRightScholorNewP}` }>
                <div>
                  <h3>Monthly</h3>
                  <div className={ classes.inputRightBlock }>
                    <div className={classes.annualInput}>
                      <span className={classes.dollarInput}>$</span>
                      <NumberFormat decimalScale={0} fixedDecimalScale type="text" customInput={TextField} thousandSeparator value={begBalValue === Infinity ? 0 : begBalValue  } onValueChange={(e) => updateValue(e, '', 'long_term_annual_contribution', 'inputTwo')} />
                    </div>
                  </div>
                </div>
                <div className={ classes.rightBoxNogap }>
                  <h3>
                    Annual
                  </h3>
                  <div className={ classes.inputRightBlock }>
                    <span className={classes.dollarInput}>$</span>
                    <NumberFormat displayType={'text'}  decimalScale={0} fixedDecimalScale type="text" thousandSeparator value={begBalValue  === Infinity ? 0 : begBalValue*12 } />
                  </div>
                </div>
              </div>
          </div>
        
        </div>
      </div>
    

      <div className={classes.borderDivider} />
      {getInvestmentObject &&
        <InvestmentBottomThree activeSlide={8} getInvestmentObject={getInvestmentObject} setOpenWorkSheet={setOpenWorkSheet} />
      }
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={classNames(classes.tooltipInfoTop, classes.definitionTooltips)} />
    </div>
  );
};

LongTermBeginingBalance.propTypes = {
  classes: PropTypes.object.isRequired,
  // handleDefinationPopup: PropTypes.func.isRequired,
  handleInvestmentData: PropTypes.func.isRequired,
  getInvestmentObject: PropTypes.object.isRequired,
  setOpenWorkSheet: PropTypes.func.isRequired,
};

export default withStyles(styles)(LongTermBeginingBalance);
