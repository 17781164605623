import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { SnackbarProvider } from 'notistack';
import client from './apollo/apollo-client';
import Router from './router';
import Pageloader from './components/ui/pageloader';

const App = () => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(()=>{
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [])
  return (
    <>
      <ApolloProvider client={ client }>
        <SnackbarProvider maxSnack={ 3 }>
          { loading ? <Pageloader loading={loading} /> : <Router /> }
        </SnackbarProvider>
      </ApolloProvider>
    </>
  );
}

export default App;
