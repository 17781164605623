import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import goldLogo from '../../../assets/img/finance-explained/email-templates/logo2.png';
import seperatordots from '../../../assets/img/finance-explained/email-templates/dots.png';
import twitter from '../../../assets/img/finance-explained/email-templates/twitter.png';
import instagram from '../../../assets/img/finance-explained/email-templates/insta.png';
import mail from '../../../assets/img/finance-explained/email-templates/mail.png';
import styles from './styles';

const SavingsAccount = (props) => {
  const { classes } = props;

  return (
    <Typography variant="body1" component="div">
      <div className={classes.containerFluid}>
        <div className={classes.container}>
          <ul className={classes.breadCrumbs}>
            <li>
              <Link to="/">Home</Link>
              <span>&gt;</span>
              <Link to="/finance-explained">Finance Explained</Link>
              <span>&gt;</span>
            </li>
            <li>Savings Account</li>
          </ul>
        </div>
        <div className={classes.videoPage}>
          <div className={classes.mainSection}>
            <div className={classes.pfBudgetPage}>
              <div>
                <div className={classes.activityRow}>
                  <div className={classes.blueRow} />
                  <div className={classes.logoBlock}>
                    <img src={goldLogo} alt="logo" />
                  </div>
                  <div className={classes.pfImage}>
                    <img
                      src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/savings-account.png"
                      alt=""
                    />
                  </div>
                  <div className={classes.pfHeading}>
                    <h3 className={classes.bigWord}>
                      Finance Explained
                      <span> | </span>
                      savings account
                    </h3>
                  </div>
                  <div className={classes.pfContent}>
                    <div className={classes.pfRow}>
                      <div className={classes.pfSlideImage}>
                        <img
                          src="https://s3.us-east-2.amazonaws.com/inkwiry-images/email_templates/savings-account-left.jpg"
                          alt=""
                        />
                      </div>
                      <div className={classes.pfText}>
                        <p>
                          A bank account that stores money you don&apos;t need
                          for day-to-day expenses, still provides easy access to
                          your money and earns more interest than a checking
                          account. Savings accounts are typically used to save
                          for financial emergencies and short and long-term
                          goals.
                        </p>
                        <p
                          className={classNames(
                            classes.smallText,
                            classes.noBottomMargin,
                          )}>
                          Savings accounts provide:
                        </p>
                        <ul
                          className={classNames(
                            classes.smallText,
                            classes.noTopMargin,
                          )}>
                          <li>A safe place to store money</li>
                          <li>
                            Up to six transfers or withdrawals a month before
                            fees (varies by bank, but federal law limits the
                            number of transactions to six)
                          </li>
                          <li>
                            Higher interest rates when compared to checking
                            accounts
                          </li>
                        </ul>
                        <p
                          className={classNames(
                            classes.smallText,
                            classes.noBottomMargin,
                          )}>
                          Unlike a checking account, a savings account usually
                          doesn&apos;t provide a debit card, personal checks or
                          the ability to access your money at ATMs. Savings
                          accounts are for financial emergencies and short and
                          long-term goals, but not for day-to-day expenses.
                        </p>
                        <p
                          className={classNames(
                            classes.smallText,
                            classes.noBottomMargin,
                            classes.noTopMargin,
                          )}>
                          Properly managing your savings account will allow you
                          to avoid unnecessary fees. Some common fees are:
                        </p>
                        <ul
                          className={classNames(
                            classes.smallText,
                            classes.noTopMargin,
                          )}>
                          <li>
                            <b>Annual fee:</b> one-time fee of $25 that the bank
                            charges for managing your account. This fee can be
                            avoided by selecting an account with no annual fee
                            or meeting a minimum balance requirement.
                          </li>
                          <li>
                            <b>Monthly maintenance fees:</b> in addition to the
                            annual fee, up to $10 monthly fee that the bank
                            charges for managing your account. This fee can be
                            avoided by maintaining the minimum balance, also
                            having a checking account with your bank, or
                            selecting an account with no maintenance fees.
                          </li>
                          <li>
                            <b>Transaction fees:</b> after you make more than
                            six transfers or withdrawals in a month, the bank
                            will usually charge you a fee for your next transfer
                            or withdrawal.
                          </li>
                        </ul>
                        <p className={classes.smallText}>
                          A smart goal is to save at least five to six months of
                          living expenses in your savings account in case of
                          financial emergencies. Then, once you build your
                          emergency fund, you can look to make higher earning
                          investments.
                        </p>
                      </div>
                    </div>
                    {/* <div className={ classes.pfRow }>
                      <div className={ classes.feText }>
                      </div>
                    </div> */}
                    <div className={classes.seperatorDotBlock}>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.connectFinance}>
                        <p>Connect with Inkwiry</p>
                        <ul>
                          <li>
                            <a
                              href="https://twitter.com/inkwiry"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={twitter} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/inkwiryfc/"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={instagram} alt="logo" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="mailto:support@inkwiry.com"
                              target="_blank"
                              rel="noopener noreferrer">
                              <img src={mail} alt="logo" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className={classes.seperatorDots}>
                        <img src={seperatordots} alt="logo" />
                      </div>
                      <div className={classes.financialBuild}>
                        <h2>See and build your best tomorrows now.</h2>
                        <Link to="/">www.inkwiry.com</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};
SavingsAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SavingsAccount);
