import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {
  TextField,
} from '@material-ui/core';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import styles from '../../../screens/pwi/rei/styles';
import { percentFormatWithTwoDecimal } from '../../common/pwi/slider-functions';
import * as modulesServices from '../../../calculations/modules-services';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';

const marks = [];
for ( let i = 0; i <= 2.5; i += 0.5 ) {
  marks.push( {
    value: i,
    label: i === 0 ? '0% of Purchase Price' : `${i}%`,
  } );
}

const PropertyTaxes = ( props ) => {
  const {
    classes, inputData, updateData, handleDefinationPopup, percentageFirstVal, percentageSecondVal
  } = props;

  const purchasePrice = inputData.purchase_price !== undefined ? inputData.purchase_price : 0;
  const [insurance, setInsurance] = React.useState( 0 );
  const [insuranceValue, setInsuranceValue] = React.useState( 0 );

  useEffect( () => {
    let insuranceVal = 0;
    if ( inputData.home_owners_insurance_in_percentage !== undefined ) {
      insuranceVal = inputData.home_owners_insurance_in_percentage;
    } else {
      insuranceVal = 0;
      updateData( 'home_owners_insurance_in_percentage', insuranceVal );
    }
    setInsurance( insuranceVal );
    setInsuranceValue( (( purchasePrice / 100 ) * insuranceVal)/12 );

    // eslint-disable-next-line
  }, [] );

  const updateValue = ( e, value, field, mainField, type ) => {
    let newvalue = 0;
    if ( type === 'input' ) {
      newvalue = e.target.value;
    } else if ( type === 'slider' ) {
      newvalue = value;
    } else {
      newvalue = e.floatValue !== undefined ? e.floatValue : 0;
    }

    switch ( field ) {
      case 'insurance': {
        setInsurance( newvalue );
        setInsuranceValue( (( purchasePrice / 100 ) * newvalue)/12 );
        updateData( mainField, newvalue );
        break;
      }
      case 'insurance_input': {
        setInsuranceValue( newvalue );
        let propertyVal = 0.0;
        if ( newvalue >= 0 && purchasePrice >= 0 ) {
          propertyVal = ( newvalue*12 / purchasePrice ) * 100;
        }
        setInsurance( propertyVal );
        updateData( mainField, propertyVal );
        break;
      }
      default:
        break;
    }
  };

  return (
    <div className={ classes.contentBlock }>
      <p>
        <span aria-hidden="true" data-for="definationTitle" className={ classes.dottedText } data-tip="Click for a definition." onClick={ () => handleDefinationPopup( "Homeowner's Insurance" ) }>Homeowner&apos;s insurance</span>
        {' '}
        is always required by your mortgage provider. Home insurance covers your property and the assets in your property from losses and damages in case of accidents or natural disasters.
      </p>
      <p>
        A quick way to estimate
        {' '}
        <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( "Homeowner's Insurance" ) }>homeowner&apos;s insurance</span>
        {' '}
        is to take $3.50 for every $1,000 of property value. For example,
        {' '}
        <span aria-hidden="true" className={ classes.dottedText } data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( "Homeowner's Insurance" ) }>homeowner&apos;s insurance</span>
        {' '}
        for a $400,000 property would cost roughly:
      </p>
      <p>$400,000 property ÷ 1,000 x $3.50 = $1,400 annual premium</p>
      <div className={ classes.homeMainRowScroll }>
        <div className={ classes.homeMainRow }>
          <div className={ classes.pmileftSection }>
            <div className={ classNames( classes.sliderGraph, classes.pmiGraph ) }>
              <div className={ classes.graphLeft }>
                <div className={ classes.sliderBar }>
                  <span>&nbsp;</span>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.titleNew }><span>Monthly</span></div>
                <div className={ classes.titleNew }><span>Annual</span></div>
              </div>
            </div>
            <div className={ classes.flexDisplayNew }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider, classes.pmiRange ) }>
                <div>
                  <p>
                    <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( "Homeowner's Insurance" ) } className={ classes.dottedText }>Homeowner&apos;s Insurance</span>
                  </p>
                  <div className={ classes.pwisliderInputs }>
                    <PwiSlider
                      value={ insurance }
                      valueLabelFormat={ percentFormatWithTwoDecimal }
                      ValueLabelComponent={ ValueLabelComponent }
                      aria-labelledby="discrete-slider-custom"
                      valueLabelDisplay="auto"
                      min={ 0 }
                      max={ 2.5 }
                      step={ 0.05 }
                      onChange={ ( e, value ) => updateValue( e, value, 'insurance', 'home_owners_insurance_in_percentage', 'slider' ) }
                      marks={ marks }
                    />
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classes.firstDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ insuranceValue } onValueChange={ ( e ) => updateValue( e, '', 'insurance_input', 'home_owners_insurance_in_percentage', 'number' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </div>
                <div className={ classes.lastDiv }>
                  <p className={ classes.hiddenData }>&nbsp;</p>
                  <div className={ classes.inputRight }>
                    <NumberFormat value={ insuranceValue * 12 } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" />
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeightNew ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Other Property Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses - insuranceValue } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ (-modulesServices.module14Data.reiTotalMonthlyExpenses - insuranceValue)*12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
              </div>
            </div>
            <div className={ classNames( classes.flexDisplayNew, classes.noBottomMargin, classes.sliderHeightNew ) }>
              <div className={ classNames( classes.sliderFlex, classes.wrapDisplay, classes.pmiSlider ) }>
                <div>
                  <div className={ classes.pwisliderInputs }>
                    <p><b>Total Property Expenses</b></p>
                  </div>
                </div>
              </div>
              <div className={ classes.graphRight }>
                <div className={ classNames( classes.firstDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
                <div className={ classNames( classes.lastDiv, classes.boldValues ) }>
                  <div className={ classes.inputRight }>
                    {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses*12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={ classes.sliderChart }>
            <ul className={ classNames( classes.utilitiesChart, classes.utilitiesPMI ) }>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                    Monthly Property Expenses
                    <span className={ classes.chartSpan }>
                      {modulesServices.module14Data.reiTotalMonthlyExpenses !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ -modulesServices.module14Data.reiTotalMonthlyExpenses } prefix="$" /> ) : ( <span>$0</span> )}
                    </span>
                  </div>
                <span className={ classes.percentageBar } style={{height: `calc(${percentageFirstVal}% - 72px)`}} />
                <div className={ classes.graphPercentage }>
                  {percentageFirstVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageFirstVal } prefix={ percentageFirstVal >= 0 ? '' : '(' } suffix={ percentageFirstVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
              <li className={ classes.chartBar }>
                <div className={ classes.chartText }>
                  Monthly Adjusted Rental Income
                  <span className={ classNames( classes.chartSpan, classes.secondPercentageTextColor ) }>
                    {modulesServices.module14Data.adjustedRentalIncomeForGraph !== undefined ? ( <NumberFormat decimalScale={ 0 } fixedDecimalScale className={ classes.formInput } displayType="text" allowNegative={ false } thousandSeparator value={ modulesServices.module14Data.adjustedRentalIncomeForGraph/12 } prefix="$" /> ) : ( <span>$0</span> )}
                  </span>
                </div>
                <span className={ classNames( classes.percentageBar, classes.secondPercentageBarColor ) } style={{height: `calc(${percentageSecondVal}% - 72px)`}} />
                <div className={ classNames( classes.graphPercentage, classes.secondPercentageTextColor ) }>
                  {percentageSecondVal !== undefined ? (
                    <NumberFormat decimalScale={ 2 } fixedDecimalScale allowNegative={ false } className={ classes.formInput } displayType="text" thousandSeparator value={ percentageSecondVal } prefix={ percentageSecondVal >= 0 ? '' : '(' } suffix={ percentageSecondVal < 0 ? '%)' : '%' } />
                  ) : ( '0.00%' )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ classNames( classes.tooltipInfoTop, classes.definitionTooltips ) } />
    </div>
  );
};

PropertyTaxes.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
  percentageFirstVal: PropTypes.string.isRequired,
  percentageSecondVal: PropTypes.string.isRequired,
};

export default withStyles( styles )( PropertyTaxes );
