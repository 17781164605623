import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi-family/styles';
import ValueLabelComponent, { PwiSlider } from '../../common/pwi/slider-custome-tool-tip';
import * as modulesServices from '../../../calculations/modules-services';
import circleArrow from '../../../assets/img/career-path/circle-arrow.png';
import { convertCurrencyToInteger } from '../../../utilities/FcCommonFunctions';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

const AddPetFamily = ( props ) => {
  const {
    classes, inputData, updateData,
  } = props;

  const [typeOfPet, setTypeOfPet] = useState( inputData.type_of_pet !== undefined ? inputData.type_of_pet : '' );
  const [nameOfPet, setNameOfPet] = useState( inputData.pet_name !== undefined ? inputData.pet_name : '' );
  const [yearOfPurchaseSlider, setYearsOfPurchaseSlider] = useState( 0 );

  const [buyingaPetInputs, setBuyingaPetInputs] = React.useState({});
  const [costData, setCostData] = React.useState( {} );
  const [defaultCostData, setDefaultCostData] = React.useState( {} );
  const [showRestIcon, setShowRestIcon] = React.useState(false);

  useEffect(()=>{
    if(costData['default_annual_pet_vet_visits'] !== defaultCostData['default_annual_pet_vet_visits'] || costData['default_annual_pet_food'] !== defaultCostData['default_annual_pet_food'] 
      || costData['default_annual_pet_toys'] !== defaultCostData['default_annual_pet_toys']
      || costData['default_annual_pet_emergency_fund'] !== defaultCostData['default_annual_pet_emergency_fund'] || costData['default_annual_pet_health_insurance'] !== defaultCostData['default_annual_pet_health_insurance'] 
      || costData['default_annual_pet_registration'] !== defaultCostData['default_annual_pet_registration']
      || costData['default_annual_pet_miscellaneous'] !== defaultCostData['default_annual_pet_miscellaneous']) {
      setShowRestIcon(true);
    } else {
      setShowRestIcon(false);
    }
  },[costData])
 
  useEffect(()=>{
    let inputDetails = {...inputData};
    if(inputData.year_of_purchase !== undefined && inputData.year_of_purchase !== null && inputData.year_of_purchase > 0) {
      setYearsOfPurchaseSlider(inputData.year_of_purchase);
    } else {
      setYearsOfPurchaseSlider(modulesServices.module1Data.start_year);
      inputDetails['year_of_purchase'] = modulesServices.module1Data.start_year;
    }

    inputDetails['save_annual_pet_new_values'] = 'Yes'; 
    inputDetails['save_buying_pet_new_values'] = 'Yes';

     const changeAnnualCost = modulesServices.expenseCalculationsData;
     const buyingPetCost = changeAnnualCost['One-Time Acquisitions']['Owning a Pet - Build Sheet']['Initial Costs'];

     let buyingaPet = {};
     buyingaPet['default_pet_first_vet_visit'] = buyingPetCost['First Vet Visit'].Costs; 
     buyingaPet['default_pet_new_supplies'] = buyingPetCost['New Supplies'].Costs;
     buyingaPet['default_pet_miscellaneous'] = buyingPetCost['Apartment Pet Fee'].Costs;
     buyingaPet['purchase_price'] = (inputData.purchase_price !== undefined && inputData.purchase_price !== '') ? inputData.purchase_price : 0 ;
     setBuyingaPetInputs(buyingaPet);


     let owningaPet = {};
     owningaPet['default_annual_pet_vet_visits'] = changeAnnualCost['Yearly Costs']['Vet Visits'].Costs; 
     owningaPet['default_annual_pet_food'] = changeAnnualCost['Yearly Costs'].Food.Costs;
     owningaPet['default_annual_pet_toys'] = changeAnnualCost['Yearly Costs'].Toys.Costs;
     owningaPet['default_annual_pet_emergency_fund'] = changeAnnualCost['Yearly Costs']['Emergency Fund'].Costs;
     owningaPet['default_annual_pet_health_insurance'] = changeAnnualCost['Yearly Costs']['Pet Health Insurance'].Costs;
     owningaPet['default_annual_pet_registration'] = changeAnnualCost['Yearly Costs'].Registration.Costs;
     owningaPet['default_annual_pet_miscellaneous'] = changeAnnualCost['Yearly Costs'].Miscellaneous.Costs;
    setCostData( owningaPet );
    setDefaultCostData( owningaPet );

    updateData('total_obj',{...inputDetails,...buyingaPet, ...owningaPet});


  },[])


  const yearsListTicksValues = [];
  const year = modulesServices.module1Data.start_year;
  for ( let j = 0; j <= 14; j += 1 ) {
    if ( j % 2 === 0 ) {
      yearsListTicksValues.push( {
        value: year + j,
        label: year + j,
      } );
    }
  }


  const handleMarriageYearSlider = ( event, newValue ) => {
    setYearsOfPurchaseSlider( newValue );
    updateData( 'year_of_purchase', newValue );
  };


  const handleTextInput = (event, label) => {
    if(label === 'type_of_pet') {
      setTypeOfPet(event.target.value);
    } else {
      setNameOfPet(event.target.value);
    }
    updateData( label, event.target.value );
  }


  const updateValue = ( e, typeName, field ) => {
    let newvalue = e.target.value;
    if(newvalue === '') {
      newvalue = 0;
    }
    newvalue = convertCurrencyToInteger(newvalue);

    if(typeName === 'own') {
      const cost = { ...costData };
      cost[field] = newvalue;
      setCostData( cost );
      updateData( field, newvalue );  
    } else {
      const cost = { ...buyingaPetInputs };
      cost[field] = newvalue;
      setBuyingaPetInputs( cost );
      updateData( field, newvalue );
    }
  }; 

  const resetInputs = () => {
    updateData('total_obj',{...inputData,...defaultCostData});
    setCostData(defaultCostData);
    setShowRestIcon(false)
  }

  const petTotalCostBuying = _.sum(Object.values(buyingaPetInputs));
  const owningTotalCostPet = _.sum(Object.values(costData));

  return (
    <div className={ classes.contentBlock }>
      <p>Pets are family. And just like family, pets come with additional expenses like food, toys and annual vet visits. Plus, buying a pet can be expensive as well. If you already own a pet, enter the start year and update the expenses in the tables below.</p>
      <div className={ `${classes.AnnualIncomeGrowth} ${classes.annuvalGrowthNew} ${classes.annuvalGrowthScholor} ${classes.marrigeYear} ${classes.marrigeYearMargin}` }>
        <div>
          <p>Purchase Year</p>
          <div className={ `${classes.annuvalIncomeGrowthGraph} ${classes.annuvalIncomeGrowthGraphScholor}` }>
            <PwiSlider
              value={ yearOfPurchaseSlider }
              aria-labelledby="discrete-slider-custom"
              valueLabelDisplay="auto"
              min={ yearsListTicksValues[0]['value'] }
              max={ yearsListTicksValues[yearsListTicksValues.length - 1]['value'] }
              step={ 1 }
              marks={ yearsListTicksValues }
              ValueLabelComponent={ ValueLabelComponent }
              onChange={ handleMarriageYearSlider }
            />
          </div>
          <div className={ classes.yearNumber }>
            <span>{yearOfPurchaseSlider}</span>
          </div>
        </div>
      </div>
      <div className={ classes.inputBox }>
        <span className={ classes.petType }>Type of Pet:</span>
        <input type="text" placeholder="ex. Golden Retriever" value={ typeOfPet } onChange={ (e)=>{handleTextInput(e, 'type_of_pet')} } />
      </div>
      <div className={ classes.inputBox }>
        <span className={ classes.petType }>Name of Pet:</span>
        <input type="text" placeholder="ex. Frosto" value={ nameOfPet } onChange={ (e)=>{handleTextInput(e, 'pet_name')} } />
      </div>
      <div className={ classes.raisingPet }>
        <div className={ classes.buyingPet }>
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>Buying a Pet</th>
                <th>Year{' '}{yearOfPurchaseSlider}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Purchase Price</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ buyingaPetInputs.purchase_price !== undefined ? buyingaPetInputs.purchase_price : 0 } onChange={ ( e ) => updateValue( e, 'buy', 'purchase_price' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>First Vet Visit</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ buyingaPetInputs.default_pet_first_vet_visit !== undefined ? buyingaPetInputs.default_pet_first_vet_visit : 0 } onChange={ ( e ) => updateValue( e, 'buy' ,'default_pet_first_vet_visit' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                  
                </td>
              </tr>
              <tr>
                <td>New Supplies</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ buyingaPetInputs.default_pet_new_supplies !== undefined ? buyingaPetInputs.default_pet_new_supplies : 0 } onChange={ ( e ) => updateValue( e, 'buy', 'default_pet_new_supplies' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Miscellaneous</td>
                <td>

                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ buyingaPetInputs.default_pet_miscellaneous !== undefined ? buyingaPetInputs.default_pet_miscellaneous : 0 } onChange={ ( e ) => updateValue( e, 'buy', 'default_pet_miscellaneous' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total Pet Buying Costs</th>
                <th><NumberFormat value={ petTotalCostBuying } displayType="text" decimalScale={ 0 } fixedDecimalScale prefix="$" /></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div className={ classes.owningPet }>
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <th>Owning a Pet</th>
                <th>Year{' '}{yearOfPurchaseSlider}
                {showRestIcon && (
                  <span className={ classes.resetIcon }>
                    <ReactTooltip id="reset1" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
                    <img src={ circleArrow } alt=""  aria-hidden="true" onClick={resetInputs} data-for="reset1" data-tip="Reset to default values" />
                  </span>
                )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Vet Visits</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.default_annual_pet_vet_visits !== undefined ? costData.default_annual_pet_vet_visits : 0 } onChange={ ( e ) => updateValue( e, 'own' ,'default_annual_pet_vet_visits' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Food</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.default_annual_pet_food !== undefined ? costData.default_annual_pet_food : 0 } onChange={ ( e ) => updateValue( e, 'own' ,'default_annual_pet_food' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Toys</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.default_annual_pet_toys !== undefined ? costData.default_annual_pet_toys : 0 } onChange={ ( e ) => updateValue( e, 'own' ,'default_annual_pet_toys' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Emergency Fund</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.default_annual_pet_emergency_fund !== undefined ? costData.default_annual_pet_emergency_fund : 0 } onChange={ ( e ) => updateValue( e, 'own' ,'default_annual_pet_emergency_fund' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Pet Health Insurance</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.default_annual_pet_health_insurance !== undefined ? costData.default_annual_pet_health_insurance : 0 } onChange={ ( e ) => updateValue( e, 'own' ,'default_annual_pet_health_insurance' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Miscellaneous</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.default_annual_pet_miscellaneous !== undefined ? costData.default_annual_pet_miscellaneous : 0 } onChange={ ( e ) => updateValue( e, 'own' ,'default_annual_pet_miscellaneous' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Registration</td>
                <td>
                  <div className={ classes.amountDollar }>
                    $
                    <NumberFormat decimalScale={ 0 } fixedDecimalScale allowNegative={ false } customInput={ TextField } thousandSeparator value={ costData.default_annual_pet_registration !== undefined ? costData.default_annual_pet_registration : 0 } onChange={ ( e ) => updateValue( e, 'own' ,'default_annual_pet_registration' ) } onFocus={ ( e ) => e.target.select() } />
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>Total Costs of Owning a Pet</th>
                <th><NumberFormat value={ owningTotalCostPet } decimalScale={0} fixedDecimalScale displayType="text" thousandSeparator prefix="$" /></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

AddPetFamily.propTypes = {
  classes: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired,
};
export default withStyles( styles )( AddPetFamily );
