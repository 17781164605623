import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import styles from './styles';
import logo from '../../../assets/img/logo.png';
import * as moduleServices from '../../../calculations/modules-services';
import { formatDollar } from '../../../utilities/chartCommonFunctions';
import NumberFormat from 'react-number-format';

HCExporting( Highcharts );

const StudentLoanPopups = ( props ) => {
  const {
    classes, studentLoanType, setStudentLoanType, moduleName
  } = props;


  const [openPopup, setOpenPopup] = React.useState( '' );
  const [dispalyObj, setDispalyObj] = React.useState( {} );

  useEffect( () => {
    let dispalyObjData = {};
    if(studentLoanType === 'subsidized-graph' || studentLoanType === 'unsubsidized-graph' || studentLoanType === 'private-graph') {
      if(moduleName === 'module2') {
        const higherEducationOutputData = moduleServices.investmentOutputBackendCompleteData();

        let higherEducationOutputBarGraph1Data = {};
        if(studentLoanType === 'subsidized-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Federal Subsidized Graph'];
          dispalyObjData['graphName'] = 'Federal Subsidized Student Loan';
          let subPaymentDataValue = moduleServices.module2Data.fedSubMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        } else if(studentLoanType === 'unsubsidized-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Federal Unsubsidized Graph'];
          dispalyObjData['graphName'] = 'Federal Unsubsidized Student Loan'; 
          let subPaymentDataValue = moduleServices.module2Data.fedUnSubMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        } else if(studentLoanType === 'private-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Private Graph'];
          dispalyObjData['graphName'] = 'Private Student Loan'; 
          let subPaymentDataValue = moduleServices.module2Data.privateMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        }
        dispalyObjData['graphData'] = higherEducationOutputBarGraph1Data;
      } else if(moduleName === 'module6') {
        const higherEducationOutputData = moduleServices.moreInvestmentOutputBackendCompleteData();

        let higherEducationOutputBarGraph1Data = {};
        if(studentLoanType === 'subsidized-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Federal Subsidized Graph'];
          dispalyObjData['graphName'] = 'Federal Subsidized Student Loan';
          let subPaymentDataValue = moduleServices.module6Data.moreFedSubMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        } else if(studentLoanType === 'unsubsidized-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Federal Unsubsidized Graph'];
          dispalyObjData['graphName'] = 'Federal Unsubsidized Student Loan'; 
          let subPaymentDataValue = moduleServices.module6Data.moreFedUnSubMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        } else if(studentLoanType === 'private-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Private Graph'];
          dispalyObjData['graphName'] = 'Private Student Loan'; 
          let subPaymentDataValue = moduleServices.module6Data.morePrivateMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        }
        dispalyObjData['graphData'] = higherEducationOutputBarGraph1Data;
      }else if(moduleName === 'module15') {
        const higherEducationOutputData = moduleServices.newInvestmentOutputBackendCompleteData();

        let higherEducationOutputBarGraph1Data = {};
        if(studentLoanType === 'subsidized-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Federal Subsidized Graph'];
          dispalyObjData['graphName'] = 'Federal Subsidized Student Loan';
          let subPaymentDataValue = moduleServices.module15Data.newFedSubMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        } else if(studentLoanType === 'unsubsidized-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Federal Unsubsidized Graph'];
          dispalyObjData['graphName'] = 'Federal Unsubsidized Student Loan'; 
          let subPaymentDataValue = moduleServices.module15Data.newFedUnSubMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        } else if(studentLoanType === 'private-graph') {
          higherEducationOutputBarGraph1Data = higherEducationOutputData['Private Graph'];
          dispalyObjData['graphName'] = 'Private Student Loan'; 
          let subPaymentDataValue = moduleServices.module15Data.newPrivateMonthlyPayment;
          if( subPaymentDataValue === '-Infinity' ){
            subPaymentDataValue = '-';    
          }
          dispalyObjData['monthlyPayment'] = subPaymentDataValue;
        }
        dispalyObjData['graphData'] = higherEducationOutputBarGraph1Data;
      }
      setDispalyObj(dispalyObjData);
      setOpenPopup('graph');
    } else if(studentLoanType === 'subsidized-table' || studentLoanType === 'unsubsidized-table' || studentLoanType === 'private-table') {
      if(moduleName === 'module2') {
        dispalyObjData['main_title'] = 'Higher Education - Student Loan Payback Schedule';
        dispalyObjData['isnotmodule18'] = true;
        if(studentLoanType === 'subsidized-table') {
          dispalyObjData['isNotSubLoans'] = false;
          dispalyObjData['moduleSubName'] = 'Federal Subsidized Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.fedSubHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.fedSubHigherEdLoanData['Fed Sub Higher Education'];
        } else if(studentLoanType === 'unsubsidized-table') {
          dispalyObjData['isNotSubLoans'] = true;
          dispalyObjData['moduleSubName'] = 'Federal Unsubsidized Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.fedUnSubHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.fedUnSubHigherEdLoanData['Fed Sub Higher Education'];
        } else if(studentLoanType === 'private-table') {
          dispalyObjData['isNotSubLoans'] = true;
          dispalyObjData['moduleSubName'] = 'Private Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.privateHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.privateHigherEdLoanData['Fed Sub Higher Education'];
        }
      } else if(moduleName === 'module6') {
        dispalyObjData['main_title'] = 'More Higher Education - Student Loan Payback Schedule';
        dispalyObjData['isnotmodule18'] = true;
        if(studentLoanType === 'subsidized-table') {
          dispalyObjData['isNotSubLoans'] = false;
          dispalyObjData['moduleSubName'] = 'Federal Subsidized Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.fedSubMoreHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.fedSubMoreHigherEdLoanData['Fed Sub Higher Education'];
        } else if(studentLoanType === 'unsubsidized-table') {
          dispalyObjData['isNotSubLoans'] = true;
          dispalyObjData['moduleSubName'] = 'Federal Unsubsidized Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.fedUnSubMoreHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.fedUnSubMoreHigherEdLoanData['Fed Sub Higher Education'];
        } else if(studentLoanType === 'private-table') {
          dispalyObjData['isNotSubLoans'] = true;
          dispalyObjData['moduleSubName'] = 'Private Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.privateMoreHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.privateMoreHigherEdLoanData['Fed Sub Higher Education'];
        }
      } else if(moduleName === 'module15') {
        // console.log("dispalyObjData", dispalyObjData)
        dispalyObjData['main_title'] = 'Further Higher Education - Student Loan Payback Schedule';
        dispalyObjData['isnotmodule18'] = true;
        if(studentLoanType === 'subsidized-table') {
          dispalyObjData['isNotSubLoans'] = false;
          dispalyObjData['moduleSubName'] = 'Federal Subsidized Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.fedSubNewHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.fedSubNewHigherEdLoanData['Fed Sub Higher Education'];
        } else if(studentLoanType === 'unsubsidized-table') {
          dispalyObjData['isNotSubLoans'] = true;
          dispalyObjData['moduleSubName'] = 'Federal Unsubsidized Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.fedUnSubNewHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.fedUnSubNewHigherEdLoanData['Fed Sub Higher Education'];
        } else if(studentLoanType === 'private-table') {
          dispalyObjData['isNotSubLoans'] = true;
          dispalyObjData['moduleSubName'] = 'Private Student Loan';
          dispalyObjData['defaultfedSubData'] = moduleServices.privateNewHigherEdLoanData;
          dispalyObjData['fedSubDataLoopData'] = moduleServices.privateNewHigherEdLoanData['Fed Sub Higher Education'];
        }
      } 
      setDispalyObj(dispalyObjData);
      setOpenPopup('table');
    }
    // eslint-disable-next-line
  }, [studentLoanType] );

  const closePopup = () => {
    setStudentLoanType( '' );
  };

  const displayTableData = () => {
    const tableData = [];
    Object.keys(dispalyObj.fedSubDataLoopData).forEach((k) => {
      const tdData = [];
      const val = dispalyObj.fedSubDataLoopData[k];
    // if(k <= dispalyObj['fedSubDataLoopData']['Final Year']) {
        tableData.push(
          <tr className={ classes.tableMainData }>
            <td colSpan="9">
              <table>
                <tbody>
                  {
                    Object.keys( val ).forEach( ( data, index ) => {
                      const displayData = val[data];
                      // if(displayData.Month <= dispalyObj['fedSubDataLoopData']['Final Month'] && displayData.Month !== 0) {
                        tdData.push(
                          <tr>
                            {index === 0 ? <td>{k}</td> : <td>&nbsp;</td>}
                            <td>{displayData.Month}</td>
                            <td>
                              <span>
                                <NumberFormat  decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ displayData.Balance } displayType="text" prefix="$" />

                              </span>
                            </td>
                            <td>
                              <span>
                                <NumberFormat  decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ displayData.Payment } displayType="text" prefix="$" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <NumberFormat  decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ displayData.Principal } displayType="text" prefix="$" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <NumberFormat  decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ displayData.Interest } displayType="text" prefix="$" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <NumberFormat  decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ displayData.Equity } displayType="text" prefix="$" />
                              </span>
                            </td>
                            <td>
                              <span>
                                <NumberFormat  decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ displayData.TotalInterest } displayType="text" prefix="$" />
                              </span>
                            </td>
                            <td>
                              <NumberFormat  decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ displayData.TotalPayment } displayType="text" prefix="$" />

                            </td>
                          </tr>
                        )
                      // }
                      })
                  }
                  {tdData}
                </tbody>
              </table>
            </td>
          </tr>
        );
      // }
    })
    return tableData;
  }

  return (
    <Typography variant="body1" component="div">
        <Dialog
          open={ openPopup === 'graph' }
          onClose={ closePopup }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
        >

        <DialogTitle className={ classes.modalHeader }>
          <div>
            <Grid container>
              <Grid item sm={ 8 }>
                <h3>{dispalyObj.graphName}</h3>
              </Grid>
              
              <Grid item sm={ 4 } />
            </Grid>
            <Button className={ classes.incomePopupClose } onClick={ closePopup }>×</Button>
          </div>
        </DialogTitle>
        <DialogContent>
        {dispalyObj.graphData !== undefined && (
          <HighchartsReact
          highcharts={ Highcharts }
          options={
            {
              chart: {
                plotBackgroundColor: '#ffffff',
                plotBorderColor: '#cccccc',
                plotBorderWidth: 1,
                plotShadow: false,
                zoomType: false,
                events: {
                  load() {
                    const chart = this;
                    const text = chart.renderer.text(
                      `<strong>Monthly Payment: ${formatDollar( dispalyObj.monthlyPayment )}</strong>`,
                      chart.plotLeft + 30,
                      chart.plotTop + 35,
                    ).attr( {
                      zIndex: 5,
                    } ).add();
                    const box = text.getBBox();

                    chart.renderer.rect( box.x - 5, box.y - 5, box.width + 10, box.height + 10, 0 )
                      .attr( {
                        fill: '#FFFFFF',
                        stroke: '#2f75b5',
                        'stroke-width': 1,
                        zIndex: 4,
                      } )
                      .add();
                  },
                },
              },
              navigation: {
                menuItemStyle: {
                  fontSize: '14px',
                  textAlign: 'left'
                },
                menuItemHoverStyle: {
                  background: '#f5f5f5',
                  color: '#4c4c4c',
                  fontSize: '14px'
                },
                buttonOptions: {
                  height: 40,
                  width: 48,
                  symbolSize: 24,
                  symbolX: 24,
                  symbolY: 21,
                  symbolStrokeWidth: 2,
                  verticalAlign: 'bottom',
                  _titleKey: 'y'
                }
              },
              exporting: {
                buttons: {
                  contextButton: {
                    menuItems: [{
                      textKey: 'downloadPNG',
                      onclick: function () {
                        const chart = this;
                        chart.exportChart();
                      },
                    }, {
                      textKey: 'downloadJPEG',
                      onclick: function () {
                        const chart = this;
                        chart.exportChart({
                          type: 'image/jpeg'
                        });
                      }
                    },{
                      textKey: 'downloadPDF',
                      onclick: function () {
                        const chart = this;
                        chart.exportChart({
                          type: 'application/pdf'
                        });
                      }
                    }, {
                      textKey: 'downloadSVG',
                      onclick: function () {
                        const chart = this;
                        chart.exportChart({
                          type: 'image/svg+xml'
                        });
                      }
                    }]
                  }
                }
              },
              credits: {
                enabled: false
              },
              title: {
                text: ''
              },
              xAxis: [{
                categories: dispalyObj['graphData']['yearsList'],
                crosshair: true,
                labels: {
                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }
                }
              }],
              yAxis: [{ // Primary yAxis
                labels: {
                  formatter: function() {
                    const chart = this;
                    if( chart.value < 0 ) {
                      return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                    } else { 
                      return formatDollar(Math.round(chart.value));
                    }
                  },
                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }
                },
                title: {
                  text: 'Principal and Interest',
                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }
                },
                opposite: true
              }, { // Secondary yAxis
                gridLineWidth: 0,
                title: {
                  text: 'Student Loan Balance and Payment',
                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }
                },
                labels: {
                  formatter: function() {
                    const chart = this;
                    if( chart.value < 0 ) {
                      return '<span style="color:#bf0000;">('+formatDollar(-Math.round(chart.value))+')</span>';
                    } else { 
                      return formatDollar(Math.round(chart.value));
                    }
                  },
                  style: {
                    color: '#000000',
                    fontSize: '14px'
                  }
                }
              }],
              tooltip: {},
              legend: {
                itemStyle: {
                  fontSize: '14px'
                }
              },
              series: [{
                name: 'Student Loan Balance',
                type: 'column',
                yAxis: 1,
                data: dispalyObj['graphData']['Student Loan Balance'],
                tooltip: {
                  valuePrefix: '$'
                }
              },{
                name: 'Payment',
                type: 'column',
                yAxis: 1,
                data: dispalyObj['graphData']['Payment'],
                tooltip: {
                  valuePrefix: '$'
                },
                color: '#FFBE00'
              }, {
                name: 'Principal Payback',
                type: 'spline',
                yAxis: 0,
                data: dispalyObj['graphData']['Principal Payback'],
                tooltip: {
                  valuePrefix: '$'
                },
                color: '#548235'
              }, {
                name: 'Interest',
                type: 'spline',
                yAxis: 0,
                data: dispalyObj['graphData']['Interest'],
                marker: {
                  enabled: false
                },
                dashStyle: 'shortdot',
                tooltip: {
                  valuePrefix: '$'
                },
                color: '#70AD47'
              }]
            }
          }
          />
        )}
        </DialogContent>
        <DialogActions>
        <div className={ classes.modalFooter }>
        <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closePopup }>Close</Button>
        </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={ openPopup === 'table' }
        onClose={ closePopup }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
        >
        <DialogTitle className={ classes.summayModalHeader }>
          <div >
            <div>
              <Grid container>
                <Grid item sm={ 4 }>
                  <div className={ classes.logoPic }><img src={ logo } alt="logo" /></div>
                </Grid>
              </Grid>
              <Button className={ classes.incomePopupClose } onClick={ closePopup }>×</Button>
            </div>
          </div>
          <p>{dispalyObj.main_title}</p>
        </DialogTitle>
        {dispalyObj['defaultfedSubData'] !== undefined && dispalyObj['defaultfedSubData'] !== '' && (
          <DialogContent>
            <div className={ classes.modalBody }>
              <div className={ classes.tableResponsive }>
                <div className={ classes.federalLoanTable }>
                  <div className={ classes.loanRepayTable }>
                    <table className={ classes.bottomBorder }>
                      <tbody>
                        <tr className={ classes.loanRow }>
                          <td colSpan="9" className={ classes.noPadding }>{dispalyObj.moduleSubName}</td>
                        </tr>
                        <tr>
                          <td colSpan="9">&nbsp;</td>
                        </tr>
                        <tr className={ classes.loanRetailTable }>
                          <td colSpan="4" className={ classes.valueOne }>
                            <table>
                              <tbody>
                                {
                                  dispalyObj['isNotSubLoans'] === true && (
                                  <tr>
                                    <td>Loan Amount ($)</td>
                                    <td>
                                      <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Loan Amount'] >= 0 ? dispalyObj['defaultfedSubData']['Loan Amount'] : -dispalyObj['defaultfedSubData']['Loan Amount'] } displayType="text" prefix={ dispalyObj['defaultfedSubData']['Loan Amount'] >= 0 ? '$' : '($' } suffix={ dispalyObj['defaultfedSubData']['Loan Amount'] < 0 && ')' } />
                                    </td>
                                  </tr>
                                )}
                                {
                                  dispalyObj['isNotSubLoans'] === true && (
                                    <tr>
                                      <td>Accrued Interest ($)</td>
                                      <td>
                                        <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Accrued Interest'] >= 0 ? dispalyObj['defaultfedSubData']['Accrued Interest'] : -dispalyObj['defaultfedSubData']['Accrued Interest'] } displayType="text" prefix={ dispalyObj['defaultfedSubData']['Accrued Interest'] >= 0 ? '$' : '($' } suffix={ dispalyObj['defaultfedSubData']['Accrued Interest'] < 0 && ')' } />
                                      </td>
                                    </tr>
                                  )
                                }
                                <tr>
                                  <td>Beginning Balance ($)</td>
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Beginning Balance'] >= 0 ? dispalyObj['defaultfedSubData']['Beginning Balance'] : -dispalyObj['defaultfedSubData']['Beginning Balance'] } displayType="text" prefix={ dispalyObj['defaultfedSubData']['Beginning Balance'] >= 0 ? '$' : '($' } suffix={ dispalyObj['defaultfedSubData']['Beginning Balance'] < 0 && ')' } />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Term in Years (#)</td>
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale value={ dispalyObj['defaultfedSubData']['Terms In Year'] >=0 ? dispalyObj['defaultfedSubData']['Terms In Year'] : -dispalyObj['defaultfedSubData']['Terms In Year'] }  displayType="text" />
                                  </td>
                                </tr>
                                {
                                  !(dispalyObj['isnotmodule18'] === true) && (
                                    <tr>
                                      <td>Months until Repayment Begins (#)</td>
                                      <td>
                                        <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Year Repayment Begin Month'] >=0 ? dispalyObj['defaultfedSubData']['Year Repayment Begin Month'] : -dispalyObj['defaultfedSubData']['Year Repayment Begin Month'] } displayType="text" prefix="" />
                                      </td>
                                    </tr>
                                  )
                                }
                                <tr>
                                  <td>Annual Interest Rate (%)</td>
                                  <td>
                                  <NumberFormat decimalScale={ 2 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Annual Interest Rate'] >= 0 ? dispalyObj['defaultfedSubData']['Annual Interest Rate'] : -dispalyObj['defaultfedSubData']['Annual Interest Rate'] } displayType="text" suffix="%" />

                                  </td>
                                </tr>
                                <tr>
                                  <td>Monthly Payment ($)</td>
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Monthly Payment'] >=0 ? dispalyObj['defaultfedSubData']['Monthly Payment'] : -dispalyObj['defaultfedSubData']['Monthly Payment'] } displayType="text"  prefix={ dispalyObj['defaultfedSubData']['Monthly Payment'] >= 0 ? '$' : '($' } suffix={ dispalyObj['defaultfedSubData']['Monthly Payment'] < 0 && ')' } />

                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td colSpan="1" className={ classes.middleRow } />
                          <td colSpan="4" className={ classes.intrestTable }>
                            <table>
                              <tbody>
                                <tr>
                                  <td>Total Interest Paid</td>
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Total Interest Paid'] >= 0 ? dispalyObj['defaultfedSubData']['Total Interest Paid'] : -dispalyObj['defaultfedSubData']['Total Interest Paid'] } displayType="text" prefix={ dispalyObj['defaultfedSubData']['Total Interest Paid'] >= 0 ? '$' : '($' } suffix={ dispalyObj['defaultfedSubData']['Total Interest Paid'] < 0 && ')' }  />
                                  </td>
                                </tr>
                                <tr>
                                  <td>Total Paid</td>
                                  <td>
                                    <NumberFormat decimalScale={ 0 } fixedDecimalScale thousandSeparator value={ dispalyObj['defaultfedSubData']['Total Paid'] >= 0 ? dispalyObj['defaultfedSubData']['Total Paid'] : -dispalyObj['defaultfedSubData']['Total Paid'] } displayType="text" prefix={ dispalyObj['defaultfedSubData']['Total Paid'] >= 0 ? '$' : '($' } suffix={ dispalyObj['defaultfedSubData']['Total Paid'] < 0 && ')' }  />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="9">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                    
                    <div className={ classes.fixedData }>
                      <table>
                        <thead>
                          <tr>
                            <td colSpan="9" className={ classes.fixedTable }>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Year </td>
                                    <td>Month </td>
                                    <td>Beginning Balance</td>
                                    <td>Payment</td>
                                    <td>Principal</td>
                                    <td>Interest</td>
                                    <td>Total Principal</td>
                                    <td>Total Interest</td>
                                    <td>Total Payments</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr className={ classes.borderLine }>
                            <td colSpan="9">&nbsp;</td>
                          </tr>
                        </thead>
                        <tbody className={ classes.leftBorder }>
                          {displayTableData()}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        )}
        <DialogActions>
        <div className={ classes.modalFooter }>
        <Button className={ classNames( classes.cancelButton, classes.footerButton ) } onClick={ closePopup }>Close</Button>
        </div>
        </DialogActions>
      </Dialog>

    </Typography>
    );
};

StudentLoanPopups.propTypes = {
  classes: PropTypes.object.isRequired,
  studentLoanType: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  setStudentLoanType: PropTypes.func.isRequired,
};


export default withStyles( styles )( StudentLoanPopups );

