import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import compose from 'recompose/compose';
import styles from './styles';
import { loader } from 'graphql.macro';
import logoImage from '../../../../assets/img/mcs/my-career-sketch.png';
import logoInkwiry from '../../../../assets/img/mcs/logo.png';
import Pageloader from '../../../../components/ui/pageloader';
import quickstartCircleImg from '../../../../assets/img/questions/quickstart_circle_img.png';
import tickMark from '../../../../assets/img/mcs/green-check-discovery.png';
import bookImage from '../../../../assets/img/mcs/icon-book-rev.png';
import { useQuery } from '@apollo/react-hooks';
const MCS_LEVEL_ACTIVITY_DATA = loader('../../../../graphql/schema/career-sketch/mcs-level-activity.graphql');

const McsLevelOneActivityTwo = (props) => {
    const { classes } = props;

    const [loading] = React.useState(false);
    const [bookSection, setBookSection]=React.useState(false);
    const [levelList, setLevelList] = React.useState([]);

    const { data: mcsLevelWiseData } = useQuery(MCS_LEVEL_ACTIVITY_DATA, {
        variables: {
            level_id: "1",
        },
        fetchPolicy: 'network-only',
        onCompleted() {
            setLevelList(mcsLevelWiseData.getMCSLevelActivityData.level_activity);
        },
        onError() {
        },
      });

    return (
        <Typography variant="body1" component="div">
            {loading && <Pageloader loading={loading} />}
            <div>                
                <div className={classes.mcsDevelopVision }>                    
                    <div className={classes.topRow }>
                        <div className={classes.mcsLogoImage}>
                            <Link to="/my-career-sketch/dashboard">
                                <img src={logoImage} alt="logo" />
                            </Link>
                        </div>
                        <div className={ classes.activityStatus }>
                            <h4><span className={ classes.greenTick }><img src={ tickMark } alt="tick" /></span>ACTIVITY COMPLETE! BACK TO <a href="/my-career-sketch/level1">LEVEL 01</a></h4>
                        </div>
                        <ReactTooltip id="book" place="bottom" type="info" effect="solid" className={classes.tooltipInfoTop} />
                        <div className={ classes.bookBlock }>                            
                            <div className={ classes.activityImage } aria-hidden="true" onClick={()=>{setBookSection(true)}} data-for="book" data-tip="Activity Tracker">
                                <img src={ bookImage } alt="book" />                                                        
                            </div>
                            {bookSection && (
                            <div className={ classes.reviewLevels }>
                                <i className="fa fa-times" onClick={()=>{setBookSection(false)}} />
                                <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>1.</span>
                                            <span>Understanding career sketches</span>
                                        </span>
                                        {levelList[0] && levelList[0].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}                                        
                                    </div>
                                </div>
                                <div className={ classes.panelDefault }>
                                    <div className={ classes.panelBody }>                                       
                                        <span className={ classes.titleName }>
                                            <span className={ classes.mcsNumber}>2.</span>
                                            <span>Four Powerful questions</span>
                                        </span>
                                        {levelList[1] && levelList[1].status === 1 && ( <span className={ classes.greentickMark }>
                                        <img src={ tickMark } alt="tickmark" />    
                                        </span>)}     
                                    </div>
                                </div>
                            </div>  
                            )} 
                        </div>
                    </div>                   
                </div>                 
                <div className={classes.fiContent}>
                    <div className={classes.mcsRow}>
                        <div className={ classes.backtoLevel }>
                        <Link to="/my-career-sketch/level1">
                                <span> {'<'} Back to level 01</span>
                            </Link>
                        </div>
                    </div>                
                    <div className={classes.fiHeader}>                    
                        <div className={classes.fiHeaderInner}>
                            <span className={classes.pwiActiveModule}>
                                <h3>Level 01: Smarter tomorrows</h3>
                            </span>
                        </div>                    
                    </div>
                    <div className={classes.stepOne}>
                        <div className={classes.fiContentHeader}>
                            <div className={classes.pwiWalkTitle}>
                                <div aria-hidden="true" className={classes.menuIcon}>
                                    <span />
                                    <span />
                                    <span />
                                </div>
                                <h3>Four Powerful questions</h3>
                            </div>
                        </div>
                        <div className={classes.ProgressBar}>
                            <div className={classes.ProgressBarInner} style={{ width: '100%' }} />
                        </div>
                        <div className={classes.contentBlock}>
                            <div className={ classes.circleRow }>
                                <div className={ classes.leftImage }>
                                    <img src={quickstartCircleImg} alt="inkwiry" />
                                </div>                           
                                <div className={ classes.descContent }>
                                    <p className={classes.MarginTopZero}>                                
                                        Discovering a vision for your future begins by asking some really good questions. 
                                    </p>
                                    <p>
                                    When you move to the next page, you&apos;ll see the Big Question. Don&apos;t worry if it seems impossible to answer right now. It&apos;s completely understandable! But it WILL be possible to solve this important question as you build a full career sketch with more life events. Right now, think about how you can use career sketching to get closer to your dreams.
                                    </p>
                                </div>  
                            </div>                         
                            <p className={ classes.marginBottomZero }>Next we&apos;ll explore three questions that focus on career and education - topics that hold some of the keys to answering the Big Question:</p>
                            <ul className={ classes.marginTopZero }>
                                <li>Will my career support the life I want to live?</li>
                                <li>How do I acquire the higher education I need?</li>
                                <li>Where&apos;s the best place for me to live and work?</li>
                            </ul>
                            <p><i>You&apos;ll see Inkwiry has seven more questions about additional life events. You can peek ahead at them if you wish, but we won&apos;t be covering them in My Career Sketch. </i></p>
                            <p>You aren&apos;t expected to have any of the answers right now. These important questions require thinking, good strategy and perhaps even a bit of research to solve. Let&apos;s begin to explore the questions. </p>
                        </div>
                    </div>
                    <div className={classes.startButton}>
                        <Link to="/my-career-sketch/level1/activity2/questions">
                            <button type="button">Next</button>
                        </Link>
                    </div>
                </div>
                <div className={classes.mcsFooter}>
                    <div className={classes.container}>
                        <div className={classes.footerRow}>
                            <div className={classes.copyRight}>
                                <p>Copyright © 2022 // All rights reserved</p>
                            </div>
                            <div className={classNames(classes.copyRight, classes.informedText)}>
                                <p>
                                    Informed and Powered by
                                    <a href="/"><img src={logoInkwiry} alt="inkwiry" /></a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.mcsFluid}>
                        <p className={classes.termLinks}>
                            <a href="/">Terms of Service</a>
                            &nbsp;//&nbsp;
                            <a href="/">Privacy Policy</a>
                            &nbsp;//&nbsp;
                            <a href="/">Security</a>
                        </p>
                    </div>
                </div>
            </div>
        </Typography>
    );
};

McsLevelOneActivityTwo.propTypes = {
    classes: PropTypes.object.isRequired,
};
const enhance = compose(
    withStyles(styles),
    withRouter,
);

export default enhance(McsLevelOneActivityTwo);