import _ from 'lodash';
import { defaultValues } from './default-module-values';
import * as modulesServices from './modules-services';
import * as locationData from './locations';
// eslint-disable-next-line
export let timeline = [];

// eslint-disable-next-line
export let careerSketchName = '';

// eslint-disable-next-line
export let moduleTimelineData = [];

export const selectedModules = ['module1', 'module2', 'module4', 'module5', 'module6', 'module8', 'module9', 'module10', 'module11', 'module12', 'module13', 'module14', 'module15', 'module17', 'module18'];

export function setCareerSketchName(name) {
  careerSketchName = name;
}

function arrangeTimeline(a, b) {
  if (a.timelineOrder < b.timelineOrder) return -1;
  if (a.timelineOrder > b.timelineOrder) return 1;
  return 0;
}
function timelienarrangeTimeline(a, b) {
  if (a.time_line_order < b.time_line_order) return -1;
  if (a.time_line_order > b.time_line_order) return 1;
  return 0;
}

export function setModuleTimeLineData(data) {
  moduleTimelineData = data;
  moduleTimelineData.sort(timelienarrangeTimeline);
  const module18Index = _.findIndex(moduleTimelineData, { selected_module: 'module18' });
  if (module18Index !== 1) {
    const module18TimeLine = moduleTimelineData[module18Index];
    if (module18TimeLine.action === 'CS' || module18TimeLine.scenario_id !== 0) {
      moduleTimelineData.splice(module18Index, 1);
      moduleTimelineData.splice(1, 0, module18TimeLine);
    }
  }
}

export function sortTimeLine(data) {
  let timelineData = data;
  timelineData.sort(arrangeTimeline);
  const module18Index = _.findIndex(timeline, { selectedModule: 'module18' });
  if (module18Index !== 1) {
    const module18TimeLine = timeline[module18Index];
    if (module18TimeLine.actionFrom === 'CS' || module18TimeLine.scenarioId !== 0) {
      timelineData.splice(module18Index, 1);
      timelineData.splice(1, 0, module18TimeLine);
    }
  }
  timeline = timelineData
  setModuleTimeLineData(moduleTimelineData);
}

function pushTimeline(moduleActionFrom, moduleTimelineOrder, selectedModule, scenarioId, startYear, endYear, module1StartYear, imageSlug, scenarioName,routePath) {
  let timelineOrder = moduleTimelineOrder;
  if (startYear !== '' && parseInt(startYear, 10) > 0) {
    timelineOrder = parseInt(startYear, 10);
  } else if (moduleActionFrom === 'CS') {
    timelineOrder = parseInt(module1StartYear, 10) + 40 + parseInt(moduleTimelineOrder, 10);
  } else {
    timelineOrder = parseInt(module1StartYear, 10) + 60 + parseInt(moduleTimelineOrder, 10);
  }
  const data = {
    actionFrom: moduleActionFrom,
    timelineOrder,
    selectedModule,
    scenarioId,
    startYear,
    endYear,
    imageSlug,
    scenarioName,
    routePath
  };
  
  timeline.push(data);
  const moduleInformation = {
    action: moduleActionFrom,
    time_line_order: timelineOrder,
    module_time_line_order: moduleTimelineOrder,
    selected_module: selectedModule,
    scenario_id: scenarioId,
    startYear,
    endYear,
    routePath
  };
  moduleTimelineData.push(moduleInformation);

  if (selectedModule === 'module18') {
    sortTimeLine(timeline);
    setModuleTimeLineData(moduleTimelineData);
  }
}

function arrangeModuleData(data, module) {
  const defaultModuleData = defaultValues[module];
  if(data !== undefined && data !== null) {
    let returnModuleData = {...data};
    const loopKeys = Object.keys(defaultModuleData);
    loopKeys.forEach(function(value){
      if(defaultModuleData[value] === 0 || defaultModuleData[value] === 0.00 || defaultModuleData[value] === 1.0) {
        if(returnModuleData[value] === null 
        || returnModuleData[value] === undefined 
        || isNaN(parseInt(returnModuleData[value],10))) {
          returnModuleData[value] = defaultModuleData[value];
        }  
      }
    })
    return returnModuleData;
  }
  return defaultModuleData;
}

export function assignModulesData(data, returnRes) {
  careerSketchName = data.name;
  const presentModule = locationData.getLocationKey();
  let module1StartYear = 0;
  let presentModuleData = '';
  timeline = [];
  moduleTimelineData = [];
  // eslint-disable-next-line
  selectedModules.forEach((module, moduleIndex) => {
    let imageSlug = ''; let scenarioId = 0; let startYear = ''; let scenarioName; let endYear = ''; let moduleActionFrom = 'FC'; let timelineOrder = moduleIndex;let routePath = '';
    const scenarioIndex = _.findIndex(data.scenarios, { selected_module: module });
    const orderIndex = _.findIndex(data.timeLine, { selected_module: module });
    let loopModuleData = {};
    if(scenarioIndex >= 0) {
      loopModuleData = data.scenarios[scenarioIndex].data[module];
      loopModuleData = arrangeModuleData(loopModuleData, module);
    }
    if (scenarioIndex >= 0 && orderIndex >= 0) {
      if (presentModule === module) {
        presentModuleData = {...loopModuleData};
        if (presentModuleData !== null && presentModuleData !== '') {
          presentModuleData['folder_id'] = data.scenarios[scenarioIndex].directory_id;
          presentModuleData['id'] = data.scenarios[scenarioIndex].id;
          presentModuleData['inkwiry_scenario'] = data.scenarios[scenarioIndex].inkwiry_scenario;
        } else {
          presentModuleData = '';
        }
      }
      scenarioName = data.scenarios[scenarioIndex].name;
    }
    
    switch (module) {
      case 'module1': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
            scenarioId = data.timeLine[orderIndex].scenario_id;
            routePath = '/plan-with-inkwiry/fi';
          }
          modulesServices.setModule1Data(loopModuleData);

        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          modulesServices.setModule1Data(defaultValues.module1);
          routePath = '/plan-with-inkwiry/fi';
        } else {
          modulesServices.setModule1Data(defaultValues.module1);
        }
        // Start Year & End Year
        if (parseInt(modulesServices.module1Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module1Data.start_year, 10);
          endYear = modulesServices.module1Data.start_year + 14;

          module1StartYear = parseInt(modulesServices.module1Data.start_year, 10);
        }
        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module1') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module2': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
            routePath = '/plan-with-inkwiry/he';

            scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule2Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/he';
          modulesServices.setModule2Data(defaultValues.module2);
        } else {
          modulesServices.setModule2Data(defaultValues.module2);
        }

        // Start Year & End Year
        if (modulesServices.module2Data.start_year !== undefined && parseInt(modulesServices.module2Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module2Data.start_year, 10);
          endYear = modulesServices.module2Data.graduation_year;
        }
        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module2') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module4': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/cp';
            scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule4Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/cp';
          modulesServices.setModule4Data(defaultValues.module4);
        } else {
          modulesServices.setModule4Data(defaultValues.module4);
        }
        // Start Year & End Year
        if (modulesServices.module4Data.start_year !== undefined && parseInt(modulesServices.module4Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module4Data.start_year, 10);
          endYear = modulesServices.module4Data.end_year;
        }
        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module4') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module5': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/ca';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule5Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/ca';
          modulesServices.setModule5Data(defaultValues.module5);
        } else {
          modulesServices.setModule5Data(defaultValues.module5);
        }

        // Start Year & End Year
        if (modulesServices.module5Data.start_year !== undefined && parseInt(modulesServices.module5Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module5Data.start_year, 10);
          endYear = modulesServices.module5Data.end_year;
        }
        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module5') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module6': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/mhe';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule6Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/mhe';
          modulesServices.setModule6Data(defaultValues.module6);
        } else {
          modulesServices.setModule6Data(defaultValues.module6);
        }

        // Start Year & End Year
        if (modulesServices.module6Data.start_year !== undefined && parseInt(modulesServices.module6Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module6Data.start_year, 10);
          endYear = modulesServices.module6Data.graduation_year;
        }
        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module6') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module8': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/aca';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule8Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/aca';
          modulesServices.setModule8Data(defaultValues.module8);
        } else {
          modulesServices.setModule8Data(defaultValues.module8);
        }

        // Start Year & End Year
        if (modulesServices.module8Data.start_year !== undefined && parseInt(modulesServices.module8Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module8Data.start_year, 10);
          endYear = modulesServices.module8Data.end_year;
        }
        if (moduleActionFrom === 'FC' && (startYear === '' || startYear === 0) && presentModule !== 'module8') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module9': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/investments';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule9Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/investments';
          modulesServices.setModule9Data(defaultValues.module9);
        } else {
          modulesServices.setModule9Data(defaultValues.module9);
        }
        const investmentYears = [];
        if (modulesServices.module9Data.short_term_start_year !== undefined && parseInt(modulesServices.module9Data.short_term_start_year, 10) > 0) {
          investmentYears.push(parseInt(modulesServices.module9Data.short_term_start_year, 10));
        }
        if (modulesServices.module9Data.long_term_start_year !== undefined && parseInt(modulesServices.module9Data.long_term_start_year, 10) > 0) {
          investmentYears.push(parseInt(modulesServices.module9Data.long_term_start_year, 10));
        }
        if (modulesServices.module9Data.financial_gift_start_year !== undefined && parseInt(modulesServices.module9Data.financial_gift_start_year, 10) > 0) {
          investmentYears.push(parseInt(modulesServices.module9Data.financial_gift_start_year, 10));
        }
        if (modulesServices.module9Data.charitable_start_year !== undefined && parseInt(modulesServices.module9Data.charitable_start_year, 10) > 0) {
          investmentYears.push(parseInt(modulesServices.module9Data.charitable_start_year, 10));
        }

        if (investmentYears.length > 0) {
          startYear = Math.min.apply(null, investmentYears);
        }

        if (moduleActionFrom === 'FC' && (startYear === '' || startYear === 0) && presentModule !== 'module9') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module10': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/family';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule10Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/family';
          modulesServices.setModule10Data(defaultValues.module10);
        } else {
          modulesServices.setModule10Data(defaultValues.module10);
        }

        const familyYears = [];
        if (modulesServices.module10Data.marriage_year !== undefined && parseInt(modulesServices.module10Data.marriage_year, 10) > 0) {
          familyYears.push(parseInt(modulesServices.module10Data.marriage_year, 10));
        }
        if (modulesServices.module10Data.year_of_purchase !== undefined && parseInt(modulesServices.module10Data.year_of_purchase, 10) > 0) {
          familyYears.push(parseInt(modulesServices.module10Data.year_of_purchase, 10));
        }

        if (familyYears.length > 0) {
          startYear = Math.min.apply(null, familyYears);
        }

        if (moduleActionFrom === 'FC' && (startYear === '' || startYear === 0) && presentModule !== 'module10') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module11': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/vacation';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule11Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/vacation';
          modulesServices.setModule11Data(defaultValues.module11);
        } else {
          modulesServices.setModule11Data(defaultValues.module11);
        }

        const vacationYears = [];
        if (modulesServices.module11Data.start_year !== undefined && parseInt(modulesServices.module11Data.start_year, 10) > 0) {
          vacationYears.push(parseInt(modulesServices.module11Data.start_year, 10));
        }
        if (modulesServices.module11Data.second_start_year !== undefined && parseInt(modulesServices.module11Data.second_start_year, 10) > 0) {
          vacationYears.push(parseInt(modulesServices.module11Data.second_start_year, 10));
        }
        if (modulesServices.module11Data.third_start_year !== undefined && parseInt(modulesServices.module11Data.third_start_year, 10) > 0) {
          vacationYears.push(parseInt(modulesServices.module11Data.third_start_year, 10));
        }

        if (vacationYears.length > 0) {
          startYear = Math.min.apply(null, vacationYears);
        }

        if (moduleActionFrom === 'FC' && (startYear === '' || startYear === 0) && presentModule !== 'module11') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module12': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/car';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule12Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/car';
          modulesServices.setModule12Data(defaultValues.module12);
        } else {
          modulesServices.setModule12Data(defaultValues.module12);
        }

        const carYearsArray = [];
        if (modulesServices.module12Data.leasing_car_start_year > 0) {
          carYearsArray.push(parseInt(modulesServices.module12Data.leasing_car_start_year, 10));
        }
        if (modulesServices.module12Data.purchase_year > 0) {
          carYearsArray.push(parseInt(modulesServices.module12Data.purchase_year, 10));
        }
        if (modulesServices.module12Data.leasing_second_car_start_year > 0) {
          carYearsArray.push(parseInt(modulesServices.module12Data.leasing_second_car_start_year, 10));
        }
        if (modulesServices.module12Data.second_purchase_year > 0) {
          carYearsArray.push(parseInt(modulesServices.module12Data.second_purchase_year, 10));
        }

        if (carYearsArray.length > 0) {
          startYear = Math.min.apply(null, carYearsArray);
        }

        if (moduleActionFrom === 'FC' && (startYear === '' || startYear === 0) && presentModule !== 'module12') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module13': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/home';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule13Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/home';
          modulesServices.setModule13Data(defaultValues.module13);
        } else {
          modulesServices.setModule13Data(defaultValues.module13);
        }

        if (modulesServices.module13Data.year_of_purchase !== undefined && modulesServices.module13Data.year_of_purchase > 0) {
          startYear = parseInt(modulesServices.module13Data.year_of_purchase, 10);
        }

        if (moduleActionFrom === 'FC' && (startYear === '' || startYear === 0) && presentModule !== 'module13') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module14': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/rei';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule14Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/rei';
          modulesServices.setModule14Data(defaultValues.module14);
        } else {
          modulesServices.setModule14Data(defaultValues.module14);
        }

        if (modulesServices.module14Data.year_of_purchase !== undefined && modulesServices.module14Data.year_of_purchase > 0) {
          startYear = parseInt(modulesServices.module14Data.year_of_purchase, 10);
        }
        if (modulesServices.module14Data.year_of_sale !== undefined && modulesServices.module14Data.year_of_sale > 0) {
          endYear = modulesServices.module14Data.year_of_sale;
        }

        if (moduleActionFrom === 'FC' && (startYear === '' || startYear === 0) && presentModule !== 'module14') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module15': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/fhe';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule15Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/fhe';
          modulesServices.setModule15Data(defaultValues.module15);
        } else {
          modulesServices.setModule15Data(defaultValues.module15);
        }

        // Start Year & End Year
        if (modulesServices.module15Data.start_year !== undefined && parseInt(modulesServices.module15Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module15Data.start_year, 10);
          endYear = modulesServices.module15Data.graduation_year;
        }
        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module15') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module17': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/fca';
          scenarioId = data.timeLine[orderIndex].scenario_id;
          }
          modulesServices.setModule17Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/fca';
          modulesServices.setModule17Data(defaultValues.module17);
        } else {
          modulesServices.setModule17Data(defaultValues.module17);
        }

        // Start Year & End Year
        if (modulesServices.module17Data.start_year !== undefined && parseInt(modulesServices.module17Data.start_year, 10) > 0) {
          startYear = parseInt(modulesServices.module17Data.start_year, 10);
          endYear = modulesServices.module17Data.end_year;
        }
        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module17') {
          imageSlug = 'inactive';
        }
        break;
      }
      case 'module18': {
        imageSlug = 'active';
        if (scenarioIndex >= 0) {
          if (orderIndex >= 0) {
            moduleActionFrom = data.timeLine[orderIndex].action;
            timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/existing';
          scenarioId = data.timeLine[orderIndex].scenario_id;
            startYear = module1StartYear;
          }
          modulesServices.setModule18Data(loopModuleData);
        } else if (orderIndex >= 0) {
          moduleActionFrom = data.timeLine[orderIndex].action;
          timelineOrder = data.timeLine[orderIndex].module_time_line_order;
          routePath = '/plan-with-inkwiry/existing';
          modulesServices.setModule18Data(defaultValues.module18);
        } else {
          modulesServices.setModule18Data(defaultValues.module18);
        }

        if (moduleActionFrom === 'FC' && startYear === '' && presentModule !== 'module18') {
          imageSlug = 'inactive';
        }
        break;
      }
      default:
        break;
    }

    pushTimeline(moduleActionFrom, timelineOrder, module, scenarioId, startYear, endYear, module1StartYear, imageSlug, scenarioName,routePath);
    
    if (module === 'module18') {
      return returnRes(presentModuleData);
    }
  });
}

function generateScenarioNameStartValue(moduleName) {
  let start_year = '';
  let moduleYears = [];
  if (moduleName !== '') {
    switch (moduleName) {
      case 'module1':
        return [modulesServices.module1Data.start_year];
      case 'module18':
        return [];
      case 'module2'://Higher Education
      case 'module6'://More Education
      case 'module15'://Futher Education
        let years_diff = '';
        if (moduleName === 'module2') {
          start_year = modulesServices.module2Data.start_year;
          years_diff = modulesServices.module2Data.years_diff;
        } else if (moduleName === 'module6') {
          start_year = modulesServices.module6Data.start_year;
          years_diff = modulesServices.module6Data.years_diff;
        } else {
          start_year = modulesServices.module15Data.start_year;
          years_diff = modulesServices.module15Data.years_diff;
        }

        if (start_year !== '' && years_diff !== '' && parseInt(start_year) > 0 && parseInt(years_diff) > 0) {
          let end_year = parseInt(start_year) + parseInt(years_diff) - 1;
          return [start_year, end_year];
        } else {
          if (start_year !== '' && parseInt(start_year) > 0) {
            return [start_year, start_year];
          } else {
            return [];
          }
        }
      case 'module4'://Career Path
      case 'module5'://Career Advancement
      case 'module8'://Additional Career Advancement
      case 'module17'://Further Career Advancement
        let end_year = '';
        if (moduleName === 'module4') {
          start_year = modulesServices.module4Data.start_year;
          end_year = modulesServices.module4Data.end_year
        } else if (moduleName === 'module5') {
          start_year = modulesServices.module5Data.start_year;
          end_year = modulesServices.module5Data.end_year
        } else if (moduleName === 'module8') {
          start_year = modulesServices.module8Data.start_year;
          end_year = modulesServices.module8Data.end_year
        } else {
          start_year = modulesServices.module17Data.start_year;
          end_year = modulesServices.module17Data.end_year
        }

        if (start_year !== '' && end_year !== '' && parseInt(start_year) > 0 && parseInt(end_year) > 0) {
          return [start_year, end_year];
        } else {
          if (start_year !== '' && parseInt(start_year) > 0) {
            return [start_year, start_year];
          } else {
            return [];
          }
        }
      case 'module9'://Investment
        if (modulesServices.module9Data.start_year !== '' && parseInt(modulesServices.module9Data.start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module9Data.start_year));
        }
        if (modulesServices.module9Data.long_term_start_year !== '' && parseInt(modulesServices.module9Data.long_term_start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module9Data.long_term_start_year));
        }
        if (modulesServices.module9Data.financial_gift_start_year !== '' && parseInt(modulesServices.module9Data.financial_gift_start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module9Data.financial_gift_start_year));
        }
        if (modulesServices.module9Data.financial_gift_end_year !== '' && parseInt(modulesServices.module9Data.financial_gift_end_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module9Data.financial_gift_end_year));
        }
        if (modulesServices.module9Data.charitable_start_year !== '' && parseInt(modulesServices.module9Data.charitable_start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module9Data.charitable_start_year));
        }
        if (modulesServices.module9Data.charitable_end_year !== '' && parseInt(modulesServices.module9Data.charitable_end_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module9Data.charitable_end_year));
        }
        if (moduleYears.length > 0) {
          return [Math.min.apply(Math, moduleYears), Math.max.apply(Math, moduleYears)];
        } else {
          return [0, 0];
        }
      case 'module10'://Family
        if (modulesServices.module10Data.marriege_year !== '' && parseInt(modulesServices.module10Data.marriege_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.marriege_year));
        }
        if (modulesServices.module10Data.start_year !== '' && parseInt(modulesServices.module10Data.start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.start_year));
        }
        if (modulesServices.module10Data.end_year !== '' && parseInt(modulesServices.module10Data.end_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.end_year));
        }
        if (modulesServices.module10Data.second_start_year !== '' && parseInt(modulesServices.module10Data.second_start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.second_start_year));
        }
        if (modulesServices.module10Data.second_end_year !== '' && parseInt(modulesServices.module10Data.second_end_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.second_end_year));
        }
        if (modulesServices.module10Data.first_born !== '' && parseInt(modulesServices.module10Data.first_born) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.first_born));
        }
        if (modulesServices.module10Data.second_born !== '' && parseInt(modulesServices.module10Data.second_born) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.second_born));
        }
        if (modulesServices.module10Data.third_born !== '' && parseInt(modulesServices.module10Data.third_born) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.third_born));
        }
        if (modulesServices.module10Data.fourth_born !== '' && parseInt(modulesServices.module10Data.fourth_born) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.fourth_born));
        }
        if (modulesServices.module10Data.fifth_born !== '' && parseInt(modulesServices.module10Data.fifth_born) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.fifth_born));
        }
        if (modulesServices.module10Data.sixth_born !== '' && parseInt(modulesServices.module10Data.sixth_born) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.sixth_born));
        }
        if (modulesServices.module10Data.seventh_born !== '' && parseInt(modulesServices.module10Data.seventh_born) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.seventh_born));
        }
        if (modulesServices.module10Data.year_of_purchase !== '' && parseInt(modulesServices.module10Data.year_of_purchase) > 0) {
          moduleYears.push(parseInt(modulesServices.module10Data.year_of_purchase));
        }
        if (moduleYears.length > 0) {
          return [Math.min.apply(Math, moduleYears), Math.max.apply(Math, moduleYears)];
        } else {
          return [0, 0];
        }
      case 'module11':
        if (modulesServices.module11Data.start_year !== '' && parseInt(modulesServices.module11Data.start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module11Data.start_year));
        }
        if (modulesServices.module11Data.end_year !== '' && parseInt(modulesServices.module11Data.end_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module11Data.end_year));
        }
        if (modulesServices.module11Data.second_start_year !== '' && parseInt(modulesServices.module11Data.second_start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module11Data.second_start_year));
        }
        if (modulesServices.module11Data.second_end_year !== '' && parseInt(modulesServices.module11Data.second_end_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module11Data.second_end_year));
        }
        if (modulesServices.module11Data.third_start_year !== '' && parseInt(modulesServices.module11Data.third_start_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module11Data.third_start_year));
        }
        if (modulesServices.module11Data.third_end_year !== '' && parseInt(modulesServices.module11Data.third_end_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module11Data.third_end_year));
        }
        if (moduleYears.length > 0) {
          return [Math.min.apply(Math, moduleYears), Math.max.apply(Math, moduleYears)];
        } else {
          return [0, 0];
        }
      case 'module12':
        let lease1Year = modulesServices.module12Data.leasing_car_start_year;
        // let lease1Number = modulesServices.module12Data.leasing_car_number_of_years;
        if (lease1Year !== '' && parseInt(lease1Year) > 0) {
          moduleYears.push(parseInt(lease1Year));
        }

        let lease2Year = modulesServices.module12Data.leasing_second_car_start_year;
        // let lease2Number = modulesServices.module12Data.leasing_second_car_number_of_years;
        if (lease2Year !== '' && parseInt(lease2Year) > 0) {
          moduleYears.push(parseInt(lease2Year));
        }
        if (modulesServices.module12Data.purchase_year !== '' && parseInt(modulesServices.module12Data.purchase_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module12Data.purchase_year));
        }

        if (modulesServices.module12Data.second_purchase_year !== '' && parseInt(modulesServices.module12Data.second_purchase_year) > 0) {
          moduleYears.push(parseInt(modulesServices.module12Data.second_purchase_year));
        }

        if (moduleYears.length > 0) {
          return [Math.min.apply(Math, moduleYears), Math.max.apply(Math, moduleYears)];
        } else {
          return [0, 0];
        }
      case 'module13':
        if (modulesServices.module13Data.year_of_purchase !== '' && parseInt(modulesServices.module13Data.year_of_purchase) > 0) {
          return [modulesServices.module13Data.year_of_purchase]
        }
        break;
      case 'module14':
        if (modulesServices.module14Data.year_of_purchase !== '' && parseInt(modulesServices.module14Data.year_of_purchase) > 0) {
          return [modulesServices.module14Data.year_of_purchase];
        }
        break;
      default:
        break;
    }
  } else {
    return [];
  }
}

//A function for formatting a date to MMddyy
function formatDate(d) {
  //get the month
  let month = d.getMonth();
  //get the day
  let day = d.getDate();
  //get the year
  let year = d.getFullYear();

  //pull the last two digits of the year
  year = year.toString().substr(2, 2);

  //increment month by 1 since it is 0 indexed
  month = month + 1;
  //converts month to a string
  month = month + "";

  //if month is 1-9 pad right with a 0 for two digits
  if (month.length === 1) {
    month = "0" + month;
  }

  //convert day to string
  day = day + "";

  //if day is between 1-9 pad right with a 0 for two digits
  if (day.length === 1) {
    day = "0" + day;
  }

  //return the string "MMddyy"
  return month + '.' + day + '.' + year;
}

function getScenarioName(scenarioData) {

  let name = scenarioData['name'].split('-');
  name.pop();
  if (scenarioData.selected_module !== 'module18') {
    if (scenarioData.selected_module === 'module1') {
      name.shift();
    } else {
      if (!isNaN(parseInt(name[0], 10))) {
        if (scenarioData.selected_module === 'module13' || scenarioData.selected_module === 'module14') {
          if (parseInt(name[0], 10) === parseInt(scenarioData.start_year_relation, 10)) {
            name.shift();
          }
        } else {
          if (parseInt(name[0], 10) === parseInt(scenarioData.start_year_relation, 10)) {
            name.shift();
          }
          if (parseInt(name[0], 10) === parseInt(scenarioData.end_year_relation, 10)) {
            name.shift();
          }
        }
      }
    }
  }
  return name.join('-');
}

// Generate Scenario Name
export function generateScenarioName(module, type, data) {
  let years = generateScenarioNameStartValue(module);
  let startYear = '';
  if (years.length >= 1) {
    startYear = startYear + years[0] + '-';
    if (years.length >= 2) {
      startYear = startYear + years[1] + '-'
    }
  }

  let userDefined = '';
  if (type === 'quick-save') {
    userDefined = getScenarioName(data);
  } else {
    userDefined = data.name;
  }
  return startYear + userDefined + '-' + formatDate(new Date());
}

export function workSheetData() {

  let workSheetData = {};

  // Cash Flow Check Value
  workSheetData.cashFlowCheckVal = modulesServices.cashFlowCheckVal;

  // Module 1 Data
  workSheetData.module1Info = modulesServices.module1Data;

  // modulesServices.calculateYear();

  if (window.location.pathname !== '/higher-education') {
    // Module 2 Data
    // modulesServices.loanFields();
    // Scholarships
    modulesServices.investmentAnalysisScholships();
    // Grants
    modulesServices.investmentAnalysisGrants();
    // Financial Aid
    modulesServices.investmentAnalysisFinancialAid();
    modulesServices.incomeEarnedAfterTaxesVal();
    // Federal Sub
    modulesServices.fedSubBeginningBalance();
    // Federal Unsub
    modulesServices.fedUnSubPrincipal();
    modulesServices.fedUnSubTotalInterestPaid();
    // Private
    modulesServices.privatePrincipal();
    modulesServices.privateTotalInterestPaid();
    modulesServices.privateAccruedInterest();
    // modulesServices.customTuitionVal();
    modulesServices.customRoomBoardVal();
    // Loan Points
    modulesServices.fedSubOrganizationValue();
    modulesServices.fedSubTotalInterestPaid();
    modulesServices.fedUnSubOrganizationValue();
    modulesServices.fedPrivateOrganizationValue();
    modulesServices.summaryLoanPoints();

    modulesServices.fedUnSubAccruedInterest();
    modulesServices.fedUnSubTotalPrincipalInterest();

    // Income Earned
    // modulesServices.calEstimateTaxRate();
    modulesServices.calEstimateTaxPaid();
    modulesServices.calEstimateIncomeAfterTax();
    // modulesServices.investmentAnalysisIncomeEarned();
    // Family Contribution
    modulesServices.investmentAnalysisFamilyContribution();
    // Cash Available
    modulesServices.investmentAnalysisTotalCashAvailable();
    // Tuition Costs
    modulesServices.investmentAnalysisTuitionCostsCal();
    // Cash Excess (Calculated in view page)
    // Other Liquid Assets
    modulesServices.investmentAnalysisTotalLiquidAssetsAvailable();
    // Outstanding Student Loan

    modulesServices.existingStudentLoansBalance();
    modulesServices.investmentAnalysisOutstandingLoan();
    // Total Principal & Interest
    modulesServices.fedSubTotalPrincipalInterest();
    modulesServices.investmentAnalysisCashExcess();
    modulesServices.fedUnSubTotalPrincipalInterest();
    modulesServices.privateTotalPrincipalInterest();
    modulesServices.investmentAnalysisTotalPrincipalInterest();
    modulesServices.higherEducationOffCampusRentMultiplier();
    modulesServices.higherEducationOffCampusUtilitiesMultiplier();
    modulesServices.higherEducationOffCampusFoodMultiplier();
    modulesServices.higherEducationOffCampusClothingMultiplier();
    modulesServices.higherEducationOffCampusEntertainmentMultiplier();
    modulesServices.higherEducationOffCampusTechnologyMultiplier();
    modulesServices.higherEducationOffCampusTransportationMultiplier();
    modulesServices.higherEducationOffCampusMiscellaneousMultiplier();
    modulesServices.higherEducationOffCampusHealthInsuranceMultiplier();
    modulesServices.higherEducationOffCampusHealthInsurancePremiumMultiplier();
    modulesServices.higherEducationOffCampusRentExpenses();
    modulesServices.higherEducationOffCampusUtilitiesExpenses();
    modulesServices.higherEducationOffCampusFoodExpenses();
    modulesServices.higherEducationOffCampusClothingExpenses();
    modulesServices.higherEducationOffCampusEntertainmentExpenses();
    modulesServices.higherEducationOffCampusTechnologyExpenses();
    modulesServices.higherEducationOffCampusTransportationExpenses();
    modulesServices.higherEducationOffCampusMiscellaneousExpenses();
    modulesServices.higherEducationOffCampusOutsidePremiumExpenses();
    modulesServices.higherEducationOffCampusPremiumExpenses();
    modulesServices.higherEducationOffCampusTotalExpenses();

    modulesServices.totalStudentLoanBalance();
    modulesServices.totalMonthlyPayment();
    modulesServices.totalAnnualPaymentPaid();
    modulesServices.incomeNeedToSupportLoans();
    modulesServices.totalTuitionAndFees();
    modulesServices.totalRoomAndBoard();
    modulesServices.higherOnCampusLivingExpenses();
    modulesServices.higherOffCampusLivingExpenses();
    modulesServices.totalCostOfAttendance();
    modulesServices.privateBeginningBalance();
    modulesServices.privateMonthlyPayment();
    modulesServices.privateYearlyPayment();
    modulesServices.fedSubBeginningBalance();
    modulesServices.fedSubMonthlyPayment();
    modulesServices.fedSubYearlyPayment();
    modulesServices.fedUnSubBeginningBalance();
    modulesServices.fedUnSubMonthlyPayment();
    modulesServices.fedUnSubYearlyPayment();

  }
  // modulesServices.totalBeforeTaxIncome();
  // modulesServices.estimatedTaxRateConversation();
  // modulesServices.federalLoanMaxAmount();

  workSheetData.module2Info = modulesServices.module2Data;

  // Module 3 Data
  // Cash Excess (This value already calculated in module2)
  // Total Living Expenes     
  if (window.location.pathname !== '/higher-education') {
    // modulesServices.onCampusStudentLoanPayment();
    modulesServices.onCampusTotalExpenses();
    // modulesServices.offCampusStudentLoanPayment();
    modulesServices.offCampusTotalExpenses();
    modulesServices.onCampusMonthlyExpensesYears();
    modulesServices.offCampusYearDiff();
    modulesServices.investmentAnalysisTotalLiving();
    modulesServices.monthlyPriceOffCampus();
    modulesServices.monthlyPercentageOffCampus();
    modulesServices.investmentAnalysisCashExcessBeforeLiving();
    modulesServices.investmentAnalysisTotalLiving();
    modulesServices.investmentAnalysisCashExcessAfterLiving();
    modulesServices.existingSubLoanPayments();
    modulesServices.totalStudentsLoanPayments();
    modulesServices.collegeStudentLoanPayments();
    modulesServices.otherLoanPaymentsonOtherDebt();
    modulesServices.higherCarExpense();
    modulesServices.higherRealEstateExpenses();
    modulesServices.higherChildrenAndPetExpenses();
    modulesServices.higherTotalExpenses();
    modulesServices.higherTotalExpensesFinal();
  }
  workSheetData.module3Info = modulesServices.module2Data;
  // Cash Excess
  workSheetData.module3Info.investmentAnalysisCashExcessAfterLivingVal = workSheetData.module2Info.investmentAnalysisCashExcess + workSheetData.module3Info.investmentAnalysisTotalLiving;

  // Module 4 Data
  if (window.location.pathname !== '/career-path') {

    modulesServices.existingCreditCardInterestInterest();
    modulesServices.higherEducationExpensesPaid();
    modulesServices.childrenExpensesPaid();
    modulesServices.petExpensesPaid();
    modulesServices.totalMonthlyLivingExpenses();
    modulesServices.studentLoanPrincipalInterest();
    modulesServices.carLoanInterest();
    modulesServices.mortgageInterest();
    modulesServices.totalFinancingExpenses();
    modulesServices.incomeEarned();
    modulesServices.livingExpensesPaid();
    modulesServices.financingExpensesPaid();
    modulesServices.singleEstimatedTaxRate();
    modulesServices.singleEstimatedTaxesPaid();
    modulesServices.singleIncomeAfterLivingExpenses();
    modulesServices.marriedEstimatedTaxRate();
    modulesServices.marriedEstimatedTaxesPaid();
    modulesServices.marriedIncomeAfterLivingExpenses();
    modulesServices.personalContributionBaseIncomeCalcs();
    modulesServices.employerMatchingrateCalcs();
    modulesServices.employerMatchingLimitCalcs();
    modulesServices.careerPathPersonalContribution();
    modulesServices.employerContribution();
    modulesServices.totalAnnualContribution();
    modulesServices.beginningBalanceInYear();
    modulesServices.personalContribuitionafterYears();
    modulesServices.earningOnPersonalContribution();
    modulesServices.totalPersonalContributionEarnings();
    modulesServices.employerContributionAfterYears();
    modulesServices.earningsOnEmployerContributionAfterYears();
    modulesServices.totalEmployersContributionsEarnings();
    modulesServices.balanceBeforeVestingReductionsCareerPath();
    modulesServices.vestedAfterFewyears();
    modulesServices.employerContributionsEarningsNotVested();
    modulesServices.ending401Balance();
    modulesServices.careerPathRothIRAPersonalContributionCalcs();
    modulesServices.careerPathRothIRAPersonalContribution();
    modulesServices.careerPathRothIRATotalContribution();
    modulesServices.careerPathRothIRATotalEarnings();
    modulesServices.careerPathRothIRAEndingBalance();
    modulesServices.careerPathSEPIRAPersonalContributionCalcs();
    modulesServices.careerPathSEPIRAPersonalContribution();
    modulesServices.careerPathSEPIRABeginningBalance();
    modulesServices.careerPathSEPIRATotalContribution();
    modulesServices.careerPathSEPIRATotalEarnings();
    modulesServices.careerPathEndingSEPIRABalance();
    modulesServices.careerPathPensionEmployerContributionCalc();
    modulesServices.careerPathPensionPersonalContributionCalc();
    modulesServices.careerPathPensionPersonalContribution();
    modulesServices.careerPathPensionTotalContribution();
    modulesServices.careerPathPensionBeginningBalance();
    modulesServices.careerPathPensionTotalEmployerContributions();
    modulesServices.careerPathPensionTotalPersonalContributions();
    modulesServices.careerPathPensionTotalEarnings();
    modulesServices.careerPathEndingPensionBalance();
    modulesServices.careerPathRentExpenses();
    modulesServices.careerPathUtilitiesExpenses();
    modulesServices.careerPathFoodExpenses();
    modulesServices.careerPathClothingExpenses();
    modulesServices.careerPathEntertainmentExpenses();
    modulesServices.careerPathTechnologyExpenses();
    modulesServices.careerPathTransportationExpenses();
    modulesServices.careerPathMiscellaneousExpenses();
    modulesServices.careerPathOutsidePremiumExpenses();
    modulesServices.careerPathPremiumExpenses();
    modulesServices.careerPathExpensesRentMultiplier();
    modulesServices.careerPathExpensesUtilitiesMultiplier();
    modulesServices.careerPathExpensesFoodMultiplier();
    modulesServices.careerPathExpensesClothingMultiplier();
    modulesServices.careerPathExpensesEntertainmentMultiplier();
    modulesServices.careerPathExpensesTechnologyMultiplier();
    modulesServices.careerPathExpensesTransportationMultiplier();
    modulesServices.careerPathExpensesMiscellaneousMultiplier();
    modulesServices.careerPathExpensesMedicalMultiplier();
    modulesServices.careerPathExpensesHealthMultiplier();
    modulesServices.totalPreTaxExpected();
    modulesServices.careerPathIconCalcs();
    modulesServices.careerPathLivingExpenses();
    modulesServices.careerPathLivingExpensesCalcs();
    modulesServices.careerPathHigherEducationExpenses();
    modulesServices.careerPathHigherEducationExpensesCalcs();
    modulesServices.careerPathCarExpenses();
    modulesServices.careerPathCarExpensesCalcs();
    modulesServices.careerPathChildrenExpenses();
    modulesServices.careerPathChildrenExpensesCalcs();
    modulesServices.careerPathPetExpenses();
    modulesServices.careerPathPetExpensesCalcs();
    modulesServices.careerPathHomeExpenses();
    modulesServices.careerPathHomeExpensesCalcs();
    modulesServices.careerPathFinancingExpenses()
    modulesServices.careerPathFinancingExpensesCalcs();
    modulesServices.careerPathTotalAnnualExpenses();
    modulesServices.careerPathTotalAnnualExpensesCalcs();
    modulesServices.careerPathAnnualNetIncome();
    modulesServices.careerPathAnnualNetIncomeCalcs();
    modulesServices.retirementContributionsCareerPath();
    modulesServices.monthlyNetCashFlowCareerPath();
    modulesServices.annualRetirementContributionsCareerPath();
    modulesServices.annualNetCashFlowCareerPath();
    modulesServices.finalRetirementContributionsCareerPath();
    modulesServices.finalNetCashFlowCareerPath();

    modulesServices.carExpensesPaid();
    modulesServices.realEstateExpensesPaid();
    modulesServices.estimatedTaxesPaid();
    modulesServices.netIncomePaid();
    modulesServices.monthlyIncomeFromCareerPath();
    modulesServices.otherMonthlyIncomeCareerPath();
    modulesServices.totalMonthlyIncomeCareerPath();
    modulesServices.totalTaxesPaidCareerPath();
    modulesServices.livingExpensesCareerPath();
    modulesServices.higherEducationExpensesCareerPath();
    modulesServices.childrenExpensesCareerPath();
    modulesServices.petExpensesCareerPath();
    modulesServices.carExpensesCareerPath();
    modulesServices.realEstateExpensesCareerPath();
    modulesServices.internetExpensesCareerPath();
    modulesServices.totalMonthlyExpensesCareerPath();
    modulesServices.monthlyNetIncomeCareerPath();
    modulesServices.totalIncomeCareerPath();
    modulesServices.totalIncomeCareerPathCalcs();
    modulesServices.annualTotalTaxesPaidCareerPath();
    modulesServices.annualTotalExpensesCareerPathCalcs();
    modulesServices.totalExpensesPaid();

  }

  workSheetData.module4Info = modulesServices.module4Data;

  if (window.location.pathname !== '/career-advancement') {
    // Module 5 Data
    modulesServices.advanceExistingCreditCardInterestInterest();
    modulesServices.careerAdvpersonal401ContributionBaseIncomeCalcs();
    modulesServices.careerAdv401EmployerMatchingrateCalcs();
    modulesServices.careerAdv401EmployerMatchingLimitCalcs();
    modulesServices.careerAdv401PersonalContribution();
    modulesServices.careerAdv401EmployerContribution();

    modulesServices.advanceHigherEducationExpensesPaid();
    modulesServices.advanceChildrenExpensesPaid();
    modulesServices.advancePetExpensesPaid();

    modulesServices.advanceStudentLoanPrincipalInterest();
    modulesServices.advanceCarLoanInterest();
    modulesServices.advanceMortgageInterest();
    modulesServices.advanceTotalFinancingExpenses();
    modulesServices.advanceIncomeEarned();
    modulesServices.advanceLivingExpensesPaid();
    modulesServices.advanceFinancingExpensesPaid();
    modulesServices.advanceSingleEstimatedTaxRate();
    modulesServices.advanceSingleEstimatedTaxesPaid();
    modulesServices.advanceSingleIncomeAfterLivingExpenses();
    modulesServices.advanceMarriedEstimatedTaxRate();
    modulesServices.advanceMarriedEstimatedTaxesPaid();
    modulesServices.advanceMarriedIncomeAfterLivingExpenses();
    modulesServices.beginningBalanceInYearSecond();
    modulesServices.beginningBalanceInYearNext();
    modulesServices.personalContributionAetrFewYears();
    modulesServices.earningsOnPersonalContributions();
    modulesServices.totalPersonalContributionsAndEarningsCareerAdv();
    modulesServices.employerContributionAfterFewYears();
    modulesServices.earningsOnEmployerContributionsAfter();
    modulesServices.totalEmployerContributionAndEarnings();
    modulesServices.balanceBeforeVestingReductionsCareerAdv();
    modulesServices.vestedAfteryearAtSchedule();
    modulesServices.employerContributionsAndEarningsNotVested();
    modulesServices.endingBalanceInyearLast();
    modulesServices.advCareerRothIRABeginningBalance();
    modulesServices.advCareerRothIRAPersonalContributionCalcs();
    modulesServices.advCareerRothIRAPersonalContribution();
    modulesServices.advCareerRothIRATotalContribution();
    modulesServices.advCareerRothIRATotalEarnings();
    modulesServices.advCareerRothIRAEndingBalance();
    modulesServices.advCareerSEPIRABeginningBalance();
    modulesServices.advCareerSEPIRAPersonalContributionCalcs();
    modulesServices.advCareerSEPIRAPersonalContribution();
    modulesServices.advCareerSEPIRATotalContribution();
    modulesServices.advCareerSEPIRATotalEarnings();
    modulesServices.advCareerEndingSEPIRABalance();
    modulesServices.advCareerPensionBeginningBalance();
    modulesServices.advCareerPensionEmployerContributionCalc();
    modulesServices.advCareerPensionPersonalContributionCalc();
    modulesServices.advCareerPensionEmployerContribution();
    modulesServices.advCareerPensionTotalContribution();
    modulesServices.advCareerPensionTotalEmployerContributions();
    modulesServices.advCareerPensionTotalPersonalContributions();
    modulesServices.advCareerPensionTotalEarnings();
    modulesServices.advCareerEndingPensionBalance();
    modulesServices.careerAdvRentMultiplier();
    modulesServices.careerAdvUtilitiesMultiplier();
    modulesServices.careerAdvFoodMultiplier();
    modulesServices.careerAdvClothingMultiplier();
    modulesServices.careerAdvEntertainmentMultiplier();
    modulesServices.careerAdvTechnologyMultiplier();
    modulesServices.careerAdvTransportationMultiplier();
    modulesServices.careerAdvMiscellaneousMultiplier();
    modulesServices.careerAdvMedicalMultiplier();
    modulesServices.careerAdvHealthMultiplier();
    modulesServices.careerAdvanceRentExpenses();
    modulesServices.careerAdvanceUtilitiesExpenses();
    modulesServices.careerAdvanceFoodExpenses();
    modulesServices.careerAdvanceClothingExpenses();
    modulesServices.careerAdvanceEntertainmentExpenses();
    modulesServices.careerAdvanceTechnologyExpenses();
    modulesServices.careerAdvanceTransportationExpenses();
    modulesServices.careerAdvanceMiscellaneousExpenses();
    modulesServices.careerAdvanceOutsidePremiumExpenses();
    modulesServices.careerAdvancePremiumExpenses();
    modulesServices.advanceTotalPreTaxExpected();
    modulesServices.totalPersonalContributionsAndEarningsCareerAdv();
    modulesServices.careerAdvIconCalcs();
    modulesServices.careerAdvLivingExpenses();
    modulesServices.careerAdvLivingExpensesCalcs();
    modulesServices.careerAdvHigherEducationExpenses();
    modulesServices.careerAdvHigherEducationExpensesCalcs();
    modulesServices.careerAdvCarExpenses();
    modulesServices.careerAdvCarExpensesCalcs();
    modulesServices.careerAdvChildrenExpenses();
    modulesServices.careerAdvChildrenExpensesCalcs();
    modulesServices.careerAdvPetExpenses();
    modulesServices.careerAdvPetExpensesCalcs();
    modulesServices.careerAdvHomeExpenses();
    modulesServices.careerAdvHomeExpensesCalcs();
    modulesServices.careerAdvFinancingExpenses();
    modulesServices.careerAdvFinancingExpensesCalcs();
    modulesServices.careerAdvTotalAnnualExpenses();
    modulesServices.careerAdvTotalAnnualExpensesCalcs();
    modulesServices.advanceCarExpensesPaid();
    modulesServices.advanceRealEstateExpensesPaid();
    modulesServices.advanceEstimatedTaxesPaid();
    modulesServices.advanceNetIncome();
    modulesServices.monthlyIncomeFromCareerAdv();
    modulesServices.otherMonthlyIncomeCareerAdv();
    modulesServices.totalMonthlyIncomeCareerAdv();
    modulesServices.totalTaxesPaidCareerAdv();
    modulesServices.livingExpensesCareerAdv();
    modulesServices.higherEducationExpensesCareerAdv();
    modulesServices.childrenExpensesCareerAdv();
    modulesServices.petExpensesCareerAdv();
    modulesServices.carExpensesCareerAdv();
    modulesServices.realEstateExpensesCareerAdv();
    modulesServices.interestExpensesCareerAdv();
    modulesServices.totalMonthlyExpensesCareerAdv();
    modulesServices.monthlyNetIncomeCareerAdv();
    modulesServices.totalIncomeCareerAdv();
    modulesServices.totalIncomeCareerAdvCalcs();
    modulesServices.annualTotalTaxesPaidCareerAdv();
    modulesServices.annualTotalTaxesPaidCareerAdvCalcs();
    modulesServices.annualNetIncomeCareerAdv();
    modulesServices.annualNetIncomeCareerAdvCalcs();
    modulesServices.totalExpensesCareerAdv();
    modulesServices.monthlyRetirementContributionsCareerAdv();
    modulesServices.monthlyNetCashFlowCareerAdv();
    modulesServices.annualRetirementContributionsCareerAdv();
    modulesServices.annualNetCashFlowCareerAdv();
    modulesServices.finalRetirementContributionsCareerAdv();
    modulesServices.finalNetCashFlowCareerAdv();
    modulesServices.advanceTotalMonthlyLivingExpenses();
    modulesServices.careerAdv401PersonalContribution();
    modulesServices.careerAdv401EmployerContribution();
    modulesServices.careerAdv401TotalAnnualContribution();

  }
  workSheetData.module5Info = modulesServices.module5Data;

  modulesServices.moreCashLeftover();
  if (window.location.pathname !== '/more-higher-education') {

    modulesServices.moremonthlyPriceOffCampus();
    modulesServices.moremonthlyPercentageOffCampus();
    modulesServices.moreFedSubTotalInterestPaid();
    modulesServices.moreFedUnSubAccruedInterest();
    modulesServices.moreFedUnSubTotalInterestPaid();

    modulesServices.morePrivateAccruedInterest();
    modulesServices.morePrivateTotalInterestPaid();

    // Module 6 Data
    // modulesServices.moreLoanFields();
    // Scholarships
    modulesServices.moreHigherinvestmentAnalysisScholships();
    // Grants
    modulesServices.moreHigherinvestmentAnalysisGrants();
    // Financial Aid
    modulesServices.moreHigherinvestmentAnalysisFinancialAid();
    // Federal Sub
    modulesServices.moreFedSubBeginningBalance();

    modulesServices.moreFedSubBeginningBalance()
    modulesServices.moreFedSubMonthlyPayment()
    modulesServices.moreFedSubYearlyPayment()

    modulesServices.moreFedUnSubBeginningBalance();
    modulesServices.moreFedUnSubMonthlyPayment();
    modulesServices.moreFedUnSubYearlyPayment();

    modulesServices.morePrivateBeginningBalance();
    modulesServices.morePrivateMonthlyPayment();
    modulesServices.morePrivateYearlyPayment();
    // Federal Unsub
    modulesServices.moreFedUnSubPrincipal();
    // Private
    modulesServices.morePrivatePrincipal();
    // Loan Points
    modulesServices.moreFedSubOrganizationValue();
    modulesServices.moreFedUnSubOrganizationValue();
    modulesServices.morePrivateOrganizationValue();
    modulesServices.moreSummaryLoanPoints();
    // Income Earned
    modulesServices.moreHigherEarnedEstimatedTaxRate();
    modulesServices.moreHigherestimatedTaxesPaid();
    modulesServices.moreHigherEstimatedIncomeafterTaxes();
    modulesServices.earnedYearsDiff1();
    modulesServices.moreHigherinvestmentAnalysisIncomeEarned();
    // Family Contribution
    modulesServices.moreHigherinvestmentAnalysisFamilyContribution();
    // Cash Available
    modulesServices.moreHigherinvestmentAnalysisTotalCashAvailable();
    // Tuition Costs                
    modulesServices.moreHigherinvestmentAnalysisTuitionCosts();
    // Cash Excess (Calculated in view page)
    modulesServices.moreHigherinvestmentAnalysisCashExcess();
    // Other Liquid Assets
    modulesServices.moreHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
    // Outstanding Student Loan
    modulesServices.moreHigherinvestmentAnalysisOutstandingLoan();
    // Total Principal & Interest
    modulesServices.moreFedSubTotalPrincipalInterest();
    modulesServices.moreFedUnSubTotalPrincipalInterest();
    modulesServices.morePrivateTotalPrincipalInterest();
    modulesServices.moreHigherStudentLoanTotalPrincipalInterest();
    modulesServices.moreHigherOffCampusRentMultiplier();
    modulesServices.moreHigherOffCampusUtilitiesMultiplier();
    modulesServices.moreHigherOffCampusFoodMultiplier();
    modulesServices.moreHigherOffCampusClothingMultiplier();
    modulesServices.moreHigherOffCampusEntertainmentMultiplier();
    modulesServices.moreHigherOffCampusTechnologyMultiplier();
    modulesServices.moreHigherOffCampusTransportationMultiplier();
    modulesServices.moreHigherOffCampusMiscellaneousMultiplier();
    modulesServices.moreHigherOffCampusHealthInsuranceMultiplier();
    modulesServices.moreHigherOffCampusHealthInsurancePremiumMultiplier();
    modulesServices.moreHigherOffCampusRentExpenses();
    modulesServices.moreHigherOffCampusUtilitiesExpenses();
    modulesServices.moreHigherOffCampusFoodExpenses();
    modulesServices.moreHigherOffCampusClothingExpenses();
    modulesServices.moreHigherOffCampusEntertainmentExpenses();
    modulesServices.moreHigherOffCampusTechnologyExpenses();
    modulesServices.moreHigherOffCampusTransportationExpenses();
    modulesServices.moreHigherOffCampusMiscellaneousExpenses();
    modulesServices.moreHigherOffCampusOutsidePremiumExpenses();
    modulesServices.moreHigherOffCampusPremiumExpenses();


    modulesServices.moreTotalTuitionAndFees();
    modulesServices.moreTotalRoomAndBoard();
    modulesServices.moreHigherOnCampusLivingExpenses();
    modulesServices.moreHigherOffCampusLivingExpenses();
    modulesServices.moreHigherTotalStudentsLoanPayments();
    modulesServices.moreHighertTotalCostOfAttendance();

    modulesServices.moreHigherOtherLoanPaymentsonOtherDebt();
    modulesServices.moreHigherCarExpense();
    modulesServices.moreHigherRealEstateExpenses();
    modulesServices.moreHigherChildrenAndPetExpenses();
    modulesServices.moreHigherTotalExpenses();

    modulesServices.moreHigherTotalStudentLoanBalance();
    modulesServices.moreHigherTotalMonthlyPayment();
    modulesServices.moreHigherTotalAnnualPaymentPaid();
    modulesServices.moreHigherIncomeNeedToSupportLoans();
  }
  modulesServices.moreTotalBeforeTaxIncome();
  modulesServices.moreEstimatedTaxRateConversation();
  workSheetData.module6Info = modulesServices.module6Data;

  if (window.location.pathname !== '/more-higher-education') {
    // Module 7 Data
    modulesServices.livingExpensesCashExcess();
    // On Campus Value
    modulesServices.moreOnCampusMonthlyExpensesYears();
    modulesServices.moreFetchRoomBoard();
    modulesServices.studentLoanPayment();
    modulesServices.livingExpensestotal();
    // Off Campus Value
    modulesServices.moreOffCampusMonthlyExpensesYears();
    modulesServices.morelivingExpensesStudentLoanPayment();
    modulesServices.morelivingExpensestotal();
    modulesServices.moreHigherlivingExpensesTotal();
    // Cash Excess
    modulesServices.moreHigherCashExcess();
  }
  workSheetData.module7Info = modulesServices.module6Data;

  if (window.location.pathname !== '/additional-career-advancement') {
    // Module 8 Data
    modulesServices.moreHigherstudentLoanPrincipalInterest();
    modulesServices.moreHighercarLoanInterest();
    modulesServices.moreHighermortgageInterest();
    modulesServices.moreHighertotalFinancingExpenses();
    modulesServices.moreHigherExistingCreditCardInterestInterest();
    modulesServices.moreHigherincomeEarned();
    modulesServices.moreHigherlivingExpensesPaid();
    modulesServices.moreHigherfinancingExpensesPaid();
    modulesServices.moreHighersingleEstimatedTaxRate();
    modulesServices.moreHighersingleEstimatedTaxesPaid();
    modulesServices.moreHighersingleIncomeAfterLivingExpenses();
    modulesServices.moreHighermarriedEstimatedTaxRate();
    modulesServices.moreHighermarriedEstimatedTaxesPaid();
    modulesServices.moreHighermarriedIncomeAfterLivingExpenses();

    modulesServices.addCareerpersonal401ContributionBaseIncomeCalcs();
    modulesServices.AddCareer401EmployerMatchingrateCalcs();
    modulesServices.AddCareer401EmployerMatchingLimitCalcs();
    modulesServices.addCareer401PersonalContribution();
    modulesServices.AddCareer401EmployerContribution();
    modulesServices.addCareer401TotalAnnualContribution();
    modulesServices.beginningBalanceInYearThird();
    modulesServices.beginningBalanceInYearThird();
    modulesServices.beginningBalanceYearThird();

    //By Akhil
    modulesServices.beginningBalanceYearThirdRoth();
    modulesServices.personalContributionAddCareerAdv();
    modulesServices.earningsOnPersonalContributionsAfter();
    modulesServices.totalPersonalContributionsAndEarnings();
    modulesServices.employerContributionAddCarrerAdv();
    modulesServices.earningsOnEmployerContributionsAdd();
    modulesServices.totalEmployerContributionsAndEarnings();
    modulesServices.balanceBeforeVestingReductionsAdd();
    modulesServices.vestedAfterFewYearsScheduleAdd();
    modulesServices.employerContributionsAndEarningsNotVestedAdd();
    modulesServices.endingBalanceInYearAddCareerAdv();
    modulesServices.addCareerRothIRABeginningBalance();
    modulesServices.addCareerRothIRAPersonalContributionCalcs();
    modulesServices.addCareerRothIRAPersonalContribution();
    modulesServices.addCareerRothIRATotalContribution();
    modulesServices.addCareerRothIRATotalEarnings();
    modulesServices.addCareerRothIRAEndingBalance();
    modulesServices.addCareerSEPIRABeginningBalance();
    modulesServices.addCareerSEPIRAPersonalContributionCalcs();
    modulesServices.addCareerSEPIRAPersonalContribution();
    modulesServices.addCareerSEPIRATotalContribution();
    modulesServices.addCareerSEPIRATotalEarnings();
    modulesServices.addCareerEndingSEPIRABalance();
    modulesServices.addCareerPensionBeginningBalance();
    modulesServices.addCareerPensionPersonalContributionCalc();
    modulesServices.addCareerPensionEmployerContributionCalc();
    modulesServices.addCareerPensionEmployerContribution();
    modulesServices.addCareerPensionTotalContribution();
    modulesServices.addCareerPensionTotalEmployerContributions();
    modulesServices.addCareerPensionTotalPersonalContributions();
    modulesServices.addCareerPensionTotalEarnings();
    modulesServices.addCareerEndingPensionBalance();
    modulesServices.additionalCareerRentMultiplier();
    modulesServices.additionalCareerUtilitiesMultiplier();
    modulesServices.additionalCareerFoodMultiplier();
    modulesServices.additionalCareerClothingMultiplier();
    modulesServices.additionalCareerEntertainmentMultiplier();
    modulesServices.additionalCareerTechnologyMultiplier();
    modulesServices.additionalCareerTransportationMultiplier();
    modulesServices.additionalCareerMiscellaneousMultiplier();
    modulesServices.additionalCareerOutsidePremiumMultiplier();
    modulesServices.additionalCareerPremiumMultiplier();
    modulesServices.additionalCareerRentExpenses();
    modulesServices.additionalCareerUtilitiesExpenses();
    modulesServices.additionalCareerFoodExpenses();
    modulesServices.additionalCareerClothingExpenses();
    modulesServices.additionalCareerEntertainmentExpenses();
    modulesServices.additionalCareerTechnologyExpenses();
    modulesServices.additionalCareerTransportationExpenses();
    modulesServices.additionalCareerMiscellaneousExpenses();
    modulesServices.additionalCareerOutsidePremiumExpenses();
    modulesServices.additionalCareerPremiumExpenses();
    modulesServices.jobtotalPreTaxExpected();
    modulesServices.addCareerAdvIconCalcs();
    modulesServices.addCareerAdvLivingExpenses()
    modulesServices.addCareerAdvLivingExpensesCalcs()
    modulesServices.addCareerAdvHigherEducationExpenses()
    modulesServices.addCareerAdvHigherEducationExpensesCalcs()
    modulesServices.addCareerAdvCarExpenses()
    modulesServices.addCareerAdvCarExpensesCalcs()
    modulesServices.addCareerAdvChildrenExpenses()
    modulesServices.addCareerAdvChildrenExpensesCalcs()
    modulesServices.addCareerAdvPetExpenses()
    modulesServices.addCareerAdvPetExpensesCalcs()
    modulesServices.addCareerAdvHomeExpenses()
    modulesServices.addCareerAdvHomeExpensesCalcs()
    modulesServices.addCareerAdvFinancingExpenses()
    modulesServices.addCareerAdvFinancingExpensesCalcs()
    modulesServices.addCareerAdvTotalAnnualExpenses()
    modulesServices.addCareerAdvTotalAnnualExpensesCalcs()
    modulesServices.moreHigherNetIncome();
    modulesServices.moreHigherEstimatedTaxesPaid();
    modulesServices.moreHigherRealEstateExpensesPaid();
    modulesServices.moreHigherCarExpensesPaid();
    modulesServices.monthlyIncomeFromAddCareerAdv();
    modulesServices.totalMonthlyIncomeAddCareerAdv();
    modulesServices.otherMonthlyIncomeAddCareerAdv();

    modulesServices.totalTaxesPaidAddCareerAdv();
    modulesServices.livingExpensesAddCareerAdv();
    modulesServices.higherEducationExpensesAddCareerAdv();
    modulesServices.childrenExpensesAddCareerAdv();
    modulesServices.petExpensesAddCareerAdv();
    modulesServices.carExpensesAddCareerAdv();
    modulesServices.realEstateExpensesAddCareerAdv();
    modulesServices.internetExpensesAddCareerPathAdv();
    modulesServices.totalMonthlyExpensesAddCareerAdv();
    modulesServices.monthlyNetIncomeAddCareerAdv();
    modulesServices.totalIncomeAddCareerAdv();
    modulesServices.totalIncomeAddCareerAdvCalcs();
    modulesServices.annualTotalTaxesPaidAddCareerAdv();
    modulesServices.annualTotalExpensesAddCareerAdvCalcs();
    modulesServices.addCareerAdvAnnualNetIncome();
    modulesServices.AddCareerAdvAnnualNetIncomeCalcs();
    modulesServices.addCareer401PersonalContribution();
    modulesServices.totalExpensesPaidFromAddCareerAdv();
    modulesServices.addCareerRothIRAPersonalContribution();

    modulesServices.moreHigherEducationExpensesPaid();
    modulesServices.moreChildrenExpensesPaid()
    modulesServices.morePetExpensesPaid();

    modulesServices.retirementContributionsAddCareerAdv();
    modulesServices.monthlyNetCashFlowAddCareerAdv();
    modulesServices.annualRetirementContributionsAddCareerAdv();
    modulesServices.annualNetCashFlowAddCareerAdv();
    modulesServices.finalRetirementContributionsAddCareerAdv();
    modulesServices.finalNetCashFlowAddCareerAdv();
    modulesServices.livingTotalMonthlyLivingExpenses();
  }

  workSheetData.module8Info = modulesServices.module8Data

  if (window.location.pathname !== '/investments') {
    // Module 9 Data
    modulesServices.shortTermSummaryBeginningBalance();
    modulesServices.shortTermSummaryContributions();
    modulesServices.shortTermSummaryExcessCash();
    // modulesServices.shortTermSummaryTotalCash();
    modulesServices.shortTermSummaryEarnings();
    // modulesServices.shortTermSummaryTotalInvestments();
    modulesServices.shortTermSummaryCashWithDrawn();
    modulesServices.shortTermSummaryCashDeposited();
    modulesServices.shortTermSummaryEndingBalance();
    modulesServices.longTermSummaryBeginningBalance();
    modulesServices.longTermSummaryContributions();
    modulesServices.longTermSummaryExcessCash();
    // modulesServices.longTermSummaryTotalCash();
    modulesServices.longTermSummaryEarnings();
    // modulesServices.longTermSummaryTotalInvestments();
    modulesServices.longTermSummaryCashWithDrawn();
    modulesServices.longTermSummaryEndingBalance();
    // modulesServices.beginningBalance401KCal();
    // modulesServices.contributions401k();
    // modulesServices.earnings401k();
    // modulesServices.endingBalance401k();
    // modulesServices.rothBeginningBalanceCal();
    // modulesServices.rothContributions();
    // modulesServices.rothEarnings();
    // modulesServices.rothEndingBalance();
    modulesServices.charitableAnnualContribution();
    modulesServices.charitableTotalContribution();
    modulesServices.charitableTaxSavings();
    //modulesServices.investmentHeadings();
    modulesServices.shortAnnualNetIncomeInYear();
    modulesServices.totalFinancialGift();
    modulesServices.currentYearCashBalance();
    modulesServices.longAnnualNetIncomeInYear();
    modulesServices.longTermInitialCashBalance();
    modulesServices.longTermCashBalance();
    modulesServices.charitableAnnualContributionTotalIncome();
    modulesServices.shortTermInvestmentsPeriod();
    modulesServices.shortTermInvestmentsPeriodYears();
    modulesServices.shortTermInvestmentsBeforeTransfers();
    modulesServices.longTermInvestmentsPeriod();
    modulesServices.longTermInvestmentsPeriodYears();
    modulesServices.longTermInvestmentsBeforeTransfers();
    modulesServices.initialCashBalance();

  }
  // modulesServices.investmentHeadings();
  workSheetData.module9Info = modulesServices.module9Data;

  if (window.location.pathname !== '/family') {
    // Module 10 Data
    modulesServices.marritalSpouseIncome();
    modulesServices.marritalSpouseIncomeMain();
    modulesServices.marritalOtherIncome();
    modulesServices.marritalOtherIncomeMain();
    modulesServices.marritalTotalIncome();
    modulesServices.marritalTotalIncomeMain();
    modulesServices.marritalTaxesPaid();
    modulesServices.marritalTaxesPaidMain();
    modulesServices.marritalTaxesRate();
    modulesServices.marritalTaxesRateMain();
    modulesServices.marritalExpensesPaid();
    modulesServices.marritalExpensesPaidMain();
    modulesServices.higherEducationExpenses();
    modulesServices.higherEducationExpensesMain();
    modulesServices.childrenExpenses();
    modulesServices.childrenExpensesMain();
    modulesServices.petExpenses();
    modulesServices.petExpensesMain();
    modulesServices.marritalIncomeAfter();
    modulesServices.marritalCarPaidMain();
    modulesServices.marritalRealEstatePaidMain();
    modulesServices.marritalFinancingExpensesPaidMain();
    modulesServices.totalAnnualExpenses();
    modulesServices.totalAnnualExpensesMain();
    modulesServices.annualCashFlowBeforeRetirementContributions();
    modulesServices.annualCashFlowBeforeRetirementContributionsMain();
    modulesServices.retirementContributions();
    modulesServices.retirementContributionsMain();
    modulesServices.netCashFlowForSavingsAndInvestments();
    modulesServices.netCashFlowForSavingsAndInvestmentsMain();

    modulesServices.marritalFinancingExpensesPaid();
    modulesServices.marritalIncomeAfter();
    modulesServices.familyRentMultiplier();
    modulesServices.familyRentMultiplierCalc();
    modulesServices.familyUtilitiesMultiplier();
    modulesServices.familyUtilitiesMultiplierCalc();
    modulesServices.familyFoodMultiplier();
    modulesServices.familyFoodMultiplierCalc();
    modulesServices.familyClothingMultiplier();
    modulesServices.familyClothingMultiplierCalc();
    modulesServices.familyEntertainmentMultiplier();
    modulesServices.familyEntertainmentMultiplierCalc();
    modulesServices.familyTechnologyMultiplier();
    modulesServices.familyTechnologyMultiplierCalc();
    modulesServices.familyTransportationMultiplier();
    modulesServices.familyTransportationMultiplierCalc();
    modulesServices.familyMiscellaneousMultiplier();
    modulesServices.familyMiscellaneousMultiplierCalc();
    modulesServices.familyHealthInsuranceOutsidePremiumMultiplier();
    modulesServices.familyHealthInsuranceOutsidePremiumMultiplierCalc();
    modulesServices.familyHealthInsurancePremiumMultiplier();
    modulesServices.familyHealthInsurancePremiumMultiplierCalc();
    modulesServices.sumOfFamilyMultiplier();
    modulesServices.sumOfFamilyMultiplierCalc();
    modulesServices.spouseMonthlyPaymentInterest();
    modulesServices.spouseAnnualPaidInterest();
    modulesServices.spouseTotalInterest();
    modulesServices.spouseTotalPrincipalInterest();
    modulesServices.totalMorePreTaxExpectedIncome();
    modulesServices.preTaxExpectedIncome();
    modulesServices.annualCostOfOwingPet();
    modulesServices.totalPreTaxExpectedIncome();
    modulesServices.morePreTaxExpectedIncome();
    modulesServices.totalYearlyCostOfOwningAPet();
    modulesServices.marritalCarPaid();
    modulesServices.marritalRealEstatePaid();
  }
  workSheetData.module10Info = modulesServices.module10Data;

  // Module 11 Data
  modulesServices.vacationTotalAmountSpent();
  workSheetData.module11Info = modulesServices.module11Data;

  if (window.location.pathname !== '/buying-a-car') {

    // Module 12 Data
    modulesServices.investmentAnalysisPurchasePrice();
    modulesServices.destinationChargesSummary();
    modulesServices.investmentAnalysisDiscount();
    modulesServices.investmentAnalysisRebate();
    modulesServices.investmentAnalysisTradeIn();
    modulesServices.investmentAnalysisSalesTax();
    modulesServices.investmentAnalysisRegistrationFees();
    modulesServices.investmentAnalysisTotalPurchasePrice();
    modulesServices.carDownPayment();
    modulesServices.carLoanPoints();
    modulesServices.totalPurchasePrice();
    modulesServices.downPaymentCar();
    modulesServices.salesTaxCalcs();
    modulesServices.netPurchasePrice();
    modulesServices.adjustedPurchasePrice();
    modulesServices.carLoan();
    modulesServices.originationFeesCalculations();
    modulesServices.cashNeededAtPurchase();
    modulesServices.totalCashAvailable();
    modulesServices.totalLiquid();
    modulesServices.tradeInValue();
    modulesServices.outstandingCarLoan();
    modulesServices.investmentAnalysisTotalPrincipal();
    modulesServices.moreInvestmentAnalysisPurchasePrice();
    modulesServices.moreDestinationChargesSummary();
    modulesServices.moreInvestmentAnalysisDiscount();
    modulesServices.moreInvestmentAnalysisRebate();
    modulesServices.moreInvestmentAnalysisTradeIn();
    modulesServices.moreInvestmentAnalysisSalesTax();
    modulesServices.moreInvestmentAnalysisRegistrationFees();
    modulesServices.moreInvestmentAnalysisTotalPurchasePrice();
    modulesServices.moreCarDownPayment();
    modulesServices.moreCarLoanPoints();
    modulesServices.moreCashNeededAtPurchase();
    modulesServices.moreTotalCashAvailable();
    modulesServices.moreTotalLiquid();
    modulesServices.moreOutstandingCarLoan();
    modulesServices.moreInvestmentAnalysisTotalPrincipal();
    modulesServices.leasingCarEndYearVal();
    modulesServices.cashNeededToStartLease();
    modulesServices.leasingCarAnnualPayment();
    modulesServices.leasingCarLeasePaymentCalc();
    modulesServices.leasingCarMaintenanceCalc();
    modulesServices.leasingCarInsuranceCalc();
    modulesServices.leasingCarGasCalc();
    modulesServices.leasingCarTotalMonthlyExpenses();
    modulesServices.leasingCarTotalAnnualExpenses();
    modulesServices.leasingCarDownPaymentSummary();
    modulesServices.leasingCarTotalPaymentSummary();
    modulesServices.leasingCarTotalExpensesSummary();
    modulesServices.leasingCarTotalCostsSummary();
    modulesServices.leasingSecondCarEndYearVal();
    modulesServices.cashNeededToSecondStartLease();
    modulesServices.leasingSecondCarLeasePaymentCalc();
    modulesServices.leasingSecondCarAnnualPayment();
    modulesServices.leasingSecondCarInsuranceCalc();
    modulesServices.leasingSecondCarGasCalc();
    modulesServices.leasingSecondCarTotalMonthlyExpenses();
    modulesServices.leasingSecondCarTotalAnnualExpenses();
    modulesServices.leasingSecondCarDownPaymentSummary();
    modulesServices.leasingSecondCarTotalPaymentSummary();
    modulesServices.leasingSecondCarTotalExpensesSummary();
    modulesServices.leasingSecondCarTotalCostsSummary();
    modulesServices.leasingSecondCarMaintenanceCalc();
    modulesServices.firstCarMaintenanceCalcs();
    modulesServices.firstCarInsuranceCalcs();
    modulesServices.firstCarGasCalcs();
    modulesServices.secondCarMaintenanceCalcs();
    modulesServices.secondCarInsuranceCalcs();
    modulesServices.secondCarGasCalcs();
    modulesServices.askingPrice();
    modulesServices.discountCalcs();
    modulesServices.rebateCalcs();
    modulesServices.moreAskingPrice();
    modulesServices.moreDiscountCalcs();
    modulesServices.moreTradeInValue();
    modulesServices.moreRebateCalcs();
    modulesServices.moreNetPurchasePrice();
    modulesServices.moreAdjustedPurchasePrice();
    modulesServices.moreSalesTaxCalcs();
    modulesServices.moreTotalPurchasePrice();
    modulesServices.moreDownPaymentCar();
    modulesServices.moreCarLoan();
    modulesServices.secondOriginationFeesCalculations();
    modulesServices.moreCarLoanPoints();
    modulesServices.totalBuyingCosts();
    modulesServices.moreTotalBuyingCosts();
    modulesServices.termOfTheLoanYears();
    modulesServices.carYearlyPayment();
    modulesServices.totalCarInterest();
    modulesServices.totalPrincipalAndInterest();
    modulesServices.totalAnnualExpensesFirstCar();
    modulesServices.moreTermOfTheLoanYears();
    modulesServices.moreMonthlyPayment();
    modulesServices.moreYearlyPayment();
    modulesServices.moreTotalCarInterest();
    modulesServices.moreTotalPrincipalAndInterest();
    modulesServices.totalMonthlyExpensesSecondCar();
    modulesServices.getCarOptions();
    modulesServices.getMoreCarOptions();
    modulesServices.totalAnnualExpensesSecondCar();
  }

  workSheetData.module12Info = modulesServices.module12Data;

  // Module 13 Data
  if (window.location.pathname !== '/buying-a-home') {
    modulesServices.homePurchasePrice();
    modulesServices.homeDownPayment();
    modulesServices.closingCosts();
    modulesServices.homeClosingCosts();
    modulesServices.homeFitOutCosts();
    modulesServices.cashNeedsPurchase();
    modulesServices.moreHigherTotalCashAvailable();
    modulesServices.moreHighermoreTotalLiquid();
    modulesServices.homeOutstandingMortgage();
    // modulesServices.homeTotalPrincipal();
    modulesServices.debtToIncomeRatio();
    modulesServices.downPaymentCalcs();
    modulesServices.Mortage();
    modulesServices.yearlyMaintenanceCalcs();
    modulesServices.yearlyAssociationCalcs();
    modulesServices.homeMonthlyUtilitiesCalcs();
    modulesServices.buyingAhomeLoanPointsCalcs();
    modulesServices.originationFeesCalcs();
    modulesServices.privateMortgageInsuranceCalcs();
    modulesServices.homeownerInsuranceCalcs();
    modulesServices.propertyTaxesCalcs();
    modulesServices.buyingHomeValue();
    modulesServices.titleInsuranceCalcs();
    modulesServices.mortgagePaymentExpenses();
    modulesServices.propertyTaxesExpenses();
    modulesServices.privateMortgageInsExpenses();
    modulesServices.homeOwnersInsExpenses();
    modulesServices.pitiExpenses();
    modulesServices.maintenanceExpenses();
    modulesServices.assiciationFeesExpenses();
    modulesServices.utilitiesExpenses();
    modulesServices.totalMonthlyExpenses();
    modulesServices.biyingaHomeTotalPrncipalInterest();
    modulesServices.biyingaHomeTotalInterest();
    modulesServices.transferTaxesCalcs();
    modulesServices.totalIncomeBeforweTaxesBuyingHomePercentage();
    modulesServices.totalIncomeBeforweTaxesBuyingHomeValue();

  }
  workSheetData.module13Info = modulesServices.module13Data;

  if (window.location.pathname !== '/rei') {

    // Module 14 Data
    modulesServices.reiPurchasePrice();
    modulesServices.realEstatePropertyTaxesCalcs();
    modulesServices.realEstateYearlyPayment();
    modulesServices.reiDownPayment();
    modulesServices.sellingCostCalcs();
    modulesServices.reiClosingCosts();
    modulesServices.reiFitOutCosts();
    // modulesServices.realEstateCashNeeded();
    modulesServices.realEstatecashNeededAtPurchase();
    modulesServices.realEstatetotalCashAvailable();
    modulesServices.realEstatetotalLiquid();
    modulesServices.reiOutstandingMortgage();
    modulesServices.reiTotalPrincipal();
    modulesServices.reiDebt();
    modulesServices.investmentSummaryTotalRental();
    modulesServices.investmentSummaryTotalProperty();
    modulesServices.investmentSummaryTotalFinancingExpenses();
    modulesServices.investmentSummaryNetRental();
    modulesServices.investmentSummarySalePrice();
    modulesServices.totalCashInvested();
    modulesServices.totalCashReturned();
    modulesServices.ROICal();
    modulesServices.MoMCal();
    modulesServices.IRRCal();
    modulesServices.salesPrice();
    modulesServices.moreREISellingPrice();
    modulesServices.moreREISellingCost();
    modulesServices.moreREINetSellingPrice();
    modulesServices.moreREIPurchasePrice();
    modulesServices.moreREIClosingCost();
    modulesServices.moreREIGain();
    modulesServices.moreREITaxCapital();
    modulesServices.moreREITaxAccumulated();
    modulesServices.moreREINetGain();
    modulesServices.realEstateFitOutCostsCalcs();
    modulesServices.realEstatehomeownerInsuranceCalcs();
    modulesServices.netCashFlowFromSale();
    modulesServices.realEstateMortage();
    modulesServices.originationFeesCalculation();
    modulesServices.loanPointsCalculation();
    modulesServices.reiTitleInsuranceCalcs();

    modulesServices.managementFeeCalcs();
    modulesServices.realEstateYearlyMaintenanceCalcs();
    // modulesServices.realEstateYearlyAssociationFeesCalcs();
    modulesServices.vacancyRateCalcs();
    modulesServices.monthlyUtilitiesCalcs();
    modulesServices.realEstateAdjustedRentalIncome();
    modulesServices.realEstateMonthlyPayment();
    modulesServices.realEstatepropertyTaxesRentingVal();
    // modulesServices.realEstatePropertyTaxesExpensesCalcs();
    modulesServices.mortgagepaymentInterest();
    modulesServices.propertytaxesValue();
    modulesServices.homeOwnersInsuranceValue();
    modulesServices.reiPitiValue();
    modulesServices.managementValue();
    modulesServices.maintenanceValue();
    modulesServices.associationFeesValue();
    modulesServices.utilitiesExpenseValue();
    modulesServices.reiTotalMonthlyExpenses();
    modulesServices.totalInterest();
    modulesServices.totalPrincipalInterest();
    // modulesServices.annualRentalIncomeCalcs();
    modulesServices.realEstatedownPaymentCalcs();
    modulesServices.realEstateFinancedAmount();
    modulesServices.realEstateTransferTaxesCalcs();
    modulesServices.annualRentalIncomeGrowthRateCalcs();
    modulesServices.totalyearlyExpenses();
    modulesServices.appreciationCalcs();
    modulesServices.mortgageBalanceRemaining();
    modulesServices.annualRentalIncomeGrowthRateCalcs();
    modulesServices.adjustedRentalIncome();
    modulesServices.monthlyNetIncomeExpenses();
    modulesServices.cashOnCashReturn();

  }
  modulesServices.realEstateClosingCosts();
  workSheetData.module14Info = modulesServices.module14Data;
  // $rootScope.moreREITaxCapitalLabel = 'Tax on Gain (15%)';
  // let propertyCalVal = modulesServices.moreREITaxAccumulatedValue('convert');            
  // $rootScope.moreREITaxAccumulatedLabel = 'Tax on $'+propertyCalVal+' of Depreciation (25%)';

  if (window.location.pathname !== '/further-higher-education') {
    // Module 15 Data
    // modulesServices.newLoanFields();
    // Scholarships
    modulesServices.newHigherinvestmentAnalysisScholships();
    // Grants
    modulesServices.newHigherinvestmentAnalysisGrants();
    // Financial Aid
    modulesServices.newHigherinvestmentAnalysisFinancialAid();
    // Federal Sub
    modulesServices.newFedSubBeginningBalance();
    // Federal Unsub
    modulesServices.newFedUnSubPrincipal();
    // Private
    modulesServices.newPrivatePrincipal();
    // Loan Points
    modulesServices.furFedSubOrganizationValue();
    modulesServices.furFedUnSubOrganizationValue();
    modulesServices.furPrivateOrganizationValue();
    modulesServices.newSummaryLoanPoints();
    // Income Earned
    modulesServices.newHigherEarnedEstimatedTaxRate();
    modulesServices.newHigherestimatedTaxesPaid();
    modulesServices.newHigherEstimatedIncomeafterTaxes();
    modulesServices.newHigherinvestmentAnalysisIncomeEarned();
    // Family Contribution
    modulesServices.newHigherinvestmentAnalysisFamilyContribution();
    // Cash Available
    modulesServices.newHigherinvestmentAnalysisTotalCashAvailable();
    // Tuition Costs
    modulesServices.newHigherinvestmentAnalysisTuitionCosts();
    // Cash Excess (Calculated in view page)
    modulesServices.newHigherinvestmentAnalysisCashExcess();
    // Other Liquid Assets
    modulesServices.newHigherinvestmentAnalysisTotalLiquidAssetsAvailable();
    // Outstanding Student Loan
    modulesServices.newHigherinvestmentAnalysisOutstandingLoan();
    // Total Principal & Interest
    modulesServices.newFedSubTotalPrincipalInterest();
    modulesServices.newFedUnSubTotalPrincipalInterest();
    modulesServices.newPrivateTotalPrincipalInterest();
    modulesServices.newHigherStudentLoanTotalPrincipalInterest();
    modulesServices.furtherHigherOffCampusRentMultiplier();
    modulesServices.furtherHigherOffCampusUtilitiesMultiplier();
    modulesServices.furtherHigherOffCampusFoodMultiplier();
    modulesServices.furtherHigherOffCampusClothingMultiplier();
    modulesServices.furtherHigherOffCampusEntertainmentMultiplier();
    modulesServices.furtherHigherOffCampusTechnologyMultiplier();
    modulesServices.furtherHigherOffCampusTransportationMultiplier();
    modulesServices.furtherHigherOffCampusMiscellaneousMultiplier();
    modulesServices.furtherHigherOffCampusHealthInsuranceMultiplier();
    modulesServices.furtherHigherOffCampusHealthInsurancePremiumMultiplier();
    modulesServices.furtherHigherOffCampusRentExpenses();
    modulesServices.furtherHigherOffCampusUtilitiesExpenses();
    modulesServices.furtherHigherOffCampusFoodExpenses();
    modulesServices.furtherHigherOffCampusClothingExpenses();
    modulesServices.furtherHigherOffCampusEntertainmentExpenses();
    modulesServices.furtherHigherOffCampusTechnologyExpenses();
    modulesServices.furtherHigherOffCampusTransportationExpenses();
    modulesServices.furtherHigherOffCampusMiscellaneousExpenses();
    modulesServices.furtherHigherOffCampusOutsidePremiumExpenses();
    modulesServices.furtherHigherOffCampusPremiumExpenses();

    modulesServices.newTotalTuitionAndFees();
    modulesServices.newTotalRoomAndBoard();
    modulesServices.newOnCampusLivingExpenses();
    modulesServices.newOffCampusLivingExpenses();
    modulesServices.newHigherTotalStudentsLoanPayments();
    modulesServices.newTotalCostOfAttendance();

    modulesServices.newHigherOtherLoanPaymentsonOtherDebt();
    modulesServices.newHigherCarExpense();
    modulesServices.newHigherRealEstateExpenses();
    modulesServices.newHigherChildrenAndPetExpenses();
    modulesServices.newHigherTotalExpenses();

    modulesServices.newHigherTotalStudentLoanBalance();
    modulesServices.newHigherTotalMonthlyPayment();
    modulesServices.newHigherTotalAnnualPaymentPaid();
    modulesServices.newHigherIncomeNeedToSupportLoans();

    modulesServices.newPrivateBeginningBalance()
    modulesServices.newPrivateMonthlyPayment()
    modulesServices.newPrivateYearlyPayment()
    modulesServices.newFedUnSubBeginningBalance()
    modulesServices.newSummaryLoanPoints()
    modulesServices.newFedUnSubMonthlyPayment()
    modulesServices.newFedUnSubYearlyPayment()
    modulesServices.newFedSubBeginningBalance()
    modulesServices.newFedSubMonthlyPayment()
    modulesServices.newFedSubYearlyPayment()

    modulesServices.newmonthlyPriceOffCampus();
    modulesServices.newmonthlyPercentageOffCampus();
    modulesServices.newFedSubTotalInterestPaid();
    modulesServices.newFedUnSubAccruedInterest();
    modulesServices.newFedUnSubTotalInterestPaid();
    modulesServices.newPrivateAccruedInterest();
    modulesServices.newPrivateTotalInterestPaid();
  }
  modulesServices.newTotalBeforeTaxIncome();
  modulesServices.newEstimatedTaxRateConversation();
  workSheetData.module15Info = modulesServices.module15Data;

  if (window.location.pathname !== '/further-higher-education') {
    // Module 16 Data
    modulesServices.newlivingExpensesCashExcess();
    // On Campus Value
    modulesServices.newOnCampusMonthlyExpensesYears();
    modulesServices.newFetchRoomBoard();
    // modulesServices.newstudentLoanPayment();
    modulesServices.newlivingExpensestotal();
    // Off Campus Value
    modulesServices.newOffCampusMonthlyExpensesYears();
    modulesServices.newlivingExpensesStudentLoanPayment();
    modulesServices.newmorelivingExpensestotal();
    // Total
    modulesServices.newHigherlivingExpensesTotal();
    // Cash Excess
    modulesServices.newHigherCashExcess();
  }
  workSheetData.module16Info = modulesServices.module15Data;

  if (window.location.pathname !== '/further-career-advancement') {

    // Module 17 Data
    modulesServices.furthermoreHigherExistingCreditCardInterestInterest();
    modulesServices.furthermoreHigherstudentLoanPrincipalInterest();
    modulesServices.furthermoreHighercarLoanInterest();
    modulesServices.furthermoreHighermortgageInterest();
    modulesServices.furthermoreHigherEducationExpensesPaid();
    modulesServices.furthermoreHigherChildrenExpensesPaid();
    modulesServices.furthermoreHigherPetExpensesPaid();
    modulesServices.furthermoreHighertotalFinancingExpenses();
    modulesServices.furthermoreHigherincomeEarned();
    modulesServices.furthermoreHigherlivingExpensesPaid();
    modulesServices.furthermoreHigherfinancingExpensesPaid();
    modulesServices.furthermoreHighersingleEstimatedTaxRate();
    modulesServices.furthermoreHighersingleEstimatedTaxesPaid();
    modulesServices.furthermoreHighersingleIncomeAfterLivingExpenses();
    modulesServices.furthermoreHighermarriedEstimatedTaxRate();
    modulesServices.furthermoreHighermarriedEstimatedTaxesPaid();
    modulesServices.furthermoreHighermarriedIncomeAfterLivingExpenses();
    modulesServices.beginningBalanceInYearFourth();
    modulesServices.furtherCareerPersonal401ContributionBaseIncomeCalcs();
    modulesServices.furtherCareer401EmployerMatchingrateCalcs();
    modulesServices.furtherCareer401EmployerMatchingLimitCalcs();
    modulesServices.furtherCareer401PersonalContribution();
    modulesServices.furtherCareer401EmployerContribution();
    modulesServices.furtherCareer401TotalAnnualContribution();
    modulesServices.further401kSummaryPersonalContribution();
    modulesServices.further401kSummaryEarningsOnPersonalContribution();
    modulesServices.further401kSummaryTotalPersonalContributionsAndEarnings();
    modulesServices.further401kSummaryEmployerContribution();
    modulesServices.further401kSummaryEarningsOnEmployerContribution();
    modulesServices.further401kSummaryTotalEmployerContributionsAndEarnings();
    modulesServices.furtherBalanceBeforeVestingReductions();
    modulesServices.vestedAfterFewYearsScheduleFurther();
    modulesServices.furtherCareerRothIRABeginningBalance();
    modulesServices.furtherCareerRothIRAPersonalContributionCalcs();
    modulesServices.furtherCareerRothIRAPersonalContribution();
    modulesServices.furtherCareerRothIRATotalContribution();
    modulesServices.furtherCareerRothIRATotalEarnings();
    modulesServices.furtherCareerRothIRAEndingBalance();
    modulesServices.furtherCareerSEPIRABeginningBalance();
    modulesServices.furtherCareerSEPIRAPersonalContributionCalcs();
    modulesServices.furtherCareerSEPIRAPersonalContribution();
    modulesServices.furtherCareerSEPIRATotalContribution();
    modulesServices.furtherCareerSEPIRATotalEarnings();
    modulesServices.furtherCareerEndingSEPIRABalance();
    modulesServices.furtherCareerPensionBeginningBalance();
    modulesServices.furtherCareerPensionEmployerContributionCalc();
    modulesServices.furtherCareerPensionPersonalContributionCalc();
    modulesServices.furtherCareerPensionEmployerContribution();
    modulesServices.furtherCareerPensionTotalContribution();
    modulesServices.furtherCareerPensionTotalEmployerContributions();
    modulesServices.furtherCareerPensionTotalPersonalContributions();
    modulesServices.furtherCareerPensionTotalEarnings();
    modulesServices.furtherCareerEndingPensionBalance();
    modulesServices.furtherCareerRentMultiplier();
    modulesServices.furtherCareerUtilitiesMultiplier();
    modulesServices.furtherCareerFoodMultiplier();
    modulesServices.furtherCareerClothingMultiplier();
    modulesServices.furtherCareerEntertainmentMultiplier();
    modulesServices.furtherCareerTechnologyMultiplier();
    modulesServices.furtherCareerTransportationMultiplier();
    modulesServices.furtherCareerMiscellaneousMultiplier();
    modulesServices.furtherCareerOutsidePremiumMultiplier();
    modulesServices.furtherCareerPremiumMultiplier();
    modulesServices.furtherCareerRentExpenses();
    modulesServices.furtherCareerUtilitiesExpenses();
    modulesServices.furtherCareerFoodExpenses();
    modulesServices.furtherCareerClothingExpenses();
    modulesServices.furtherCareerEntertainmentExpenses();
    modulesServices.furtherCareerTechnologyExpenses();
    modulesServices.furtherCareerTransportationExpenses();
    modulesServices.furtherCareerMiscellaneousExpenses();
    modulesServices.furtherCareerOutsidePremiumExpenses();
    modulesServices.furtherCareerPremiumExpenses();
    modulesServices.employerContributionsAndEarningsNotVestedFur();
    modulesServices.furtherjobtotalPreTaxExpected();
    modulesServices.furtherCareer401kEndingBalance();
    modulesServices.furCareerAdvIconCalcs();
    modulesServices.furCareerAdvLivingExpenses();
    modulesServices.furCareerAdvLivingExpensesCalcs();
    modulesServices.furCareerAdvHigherEducationExpenses();
    modulesServices.furCareerAdvHigherEducationExpensesCalcs();
    modulesServices.furCareerAdvCarExpenses();
    modulesServices.furCareerAdvCarExpensesCalcs();
    modulesServices.furCareerAdvChildrenExpenses();
    modulesServices.furCareerAdvChildrenExpensesCalcs();
    modulesServices.furCareerAdvPetExpenses();
    modulesServices.furCareerAdvPetExpensesCalcs();
    modulesServices.furCareerAdvHomeExpenses();
    modulesServices.furCareerAdvHomeExpensesCalcs();
    modulesServices.furCareerAdvFinancingExpenses();
    modulesServices.furCareerAdvFinancingExpensesCalcs();
    modulesServices.furCareerAdvTotalAnnualExpenses();
    modulesServices.furCareerAdvTotalAnnualExpensesCalcs();
    modulesServices.furthermoreHigherEstimatedTaxesPaid();
    modulesServices.furthermoreHigherNetIncome();
    modulesServices.furthermoreHigherRealEstateExpensesPaid();
    modulesServices.furthermoreHigherCarExpensesPaid();

    modulesServices.monthlyIncomeFromFurCareerAdv();
    modulesServices.otherMonthlyIncomeFurCareerAdv();
    modulesServices.totalMonthlyIncomeFurCareerAdv();
    modulesServices.totalTaxesPaidFurCareerAdv();
    modulesServices.livingExpensesFurCareerAdv();
    modulesServices.higherEducationExpensesFurCareerAdv();
    modulesServices.childrenExpensesFurCareerAdv();
    modulesServices.petExpensesFurCareerAdv();
    modulesServices.carExpensesFurCareerAdv();
    modulesServices.realEstateExpensesFurCareerAdv();
    modulesServices.internetExpensesFurCareerAdv();
    modulesServices.totalMonthlyExpensesFurCareerAdv();
    modulesServices.monthlyNetIncomeFurCareerAdv();
    modulesServices.totalIncomeFurCareerAdv();
    modulesServices.totalIncomeFurCareerAdvCalcs();
    modulesServices.annualTotalTaxesPaidFurCareerAdv();
    modulesServices.furCareerAdvAnnualNetIncome();
    modulesServices.furCareerAdvAnnualNetIncomeCalcs();
    modulesServices.annualTotalExpensesFurCareerAdvCalcs();
    modulesServices.furCareerAdvTotalExpenses();

    modulesServices.retirementContributionsFurCareerAdv();
    modulesServices.monthlyNetCashFlowFurCareerAdv();
    modulesServices.annualRetirementContributionsFurCareerAdv();
    modulesServices.annualNetCashFlowFurCareerAdv();
    modulesServices.finalRetirementContributionsFurCareerAdv();
    modulesServices.finalNetCashFlowFurCareerAdv();
    modulesServices.furtherlivingTotalMonthlyLivingExpenses();

  }
  workSheetData.module17Info = modulesServices.module17Data;

  if (window.location.pathname !== '/existing-assets-liabilities') {
    // Module 18 Data
    modulesServices.existingStudentLoansFedSubBeginningBalance();
    modulesServices.existingStudentLoansFedSubMonthlyPayment();
    modulesServices.existingStudentLoansFedSubAnnualPayment();
    modulesServices.existingStudentLoansFedSubTotalInterest();
    modulesServices.existingStudentLoansFedSubTotalPrincipalInterest();

    modulesServices.existingStudentLoansFedUnSubTotalPrincipal();
    modulesServices.existingStudentLoansFedUnSubBeginningBalance();
    modulesServices.existingStudentLoansFedUnSubTotalAccruedInterest();
    modulesServices.existingStudentLoansFedUnSubMonthlyPayment();
    modulesServices.existingStudentLoansFedUnSubAnnualPayment();
    modulesServices.existingStudentLoansFedUnSubTotalInterest();
    modulesServices.existingStudentLoansFedUnSubTotalPrincipalInterest();

    modulesServices.existingStudentLoansPrivateTotalPrincipal();
    modulesServices.existingStudentLoansPrivateBeginningBalance();
    modulesServices.existingStudentLoansPrivateMonthlyPayment();
    modulesServices.existingStudentLoansPrivateTotalAccruedInterest();
    modulesServices.existingStudentLoansPrivateAnnualPayment();
    modulesServices.existingStudentLoansPrivateTotalInterest();
    modulesServices.existingStudentLoansPrivateTotalPrincipalInterest();

    modulesServices.existingStudentLoansSummaryTotalInterest();
    modulesServices.existingStudentLoansSummaryTotalPrincipalInterest();
    modulesServices.existingStudentLoansSummaryIncomeRatio();
    modulesServices.existingCreditCardMonthlyPayment();
    modulesServices.existingCreditCardAnnualPayment();
    modulesServices.existingCreditCardTotalInterest();
    modulesServices.existingCreditCardTotalPrincipalInterest();
    modulesServices.existingCarLoansMonthlyPayment();
    modulesServices.existingCarLoansAnnualPayment();
    modulesServices.existingCarLoansTotalInterest();
    modulesServices.existingCarLoansTotalPrincipalInterest();
    modulesServices.existingCarLoansMaintenanceCalcs();
    modulesServices.existingCarLoansInsuranceCalcs();
    modulesServices.existingCarLoansGasCalcs();
    modulesServices.existingCarLoansTotalMonthlyExpenses();
    modulesServices.existingCarLoansTotalAnnualExpenses();
    modulesServices.existingHomeMonthlyPropertyTaxesCalcs();
    modulesServices.mortgagePaymentPrincipalInterest();
    modulesServices.mortgagetotalMonthlyExpenses();
    modulesServices.existingHomeMonthlyHomeownerInsuranceCalcs();
    modulesServices.existingHomeMonthlyPrivateMortgageInsuranceCalcs();
    modulesServices.existingHomedebtIncomeRatio();
    modulesServices.taxableGainUpLabel();
    modulesServices.existingHomeMonthlyMaintenanceCalcs();
    modulesServices.existingHomeMonthlyAssociationFeesCalcs();
    modulesServices.existingHomeMonthlyUtilitiesCalcs();
    modulesServices.existigHomeCurrentMortgageBalanceCalcs();
    modulesServices.existingHomeMortgageMonthlyPayment();
    modulesServices.existingHomeMortgageAnnualPayment();
    modulesServices.existingHomeMortgageTotalInterest();
    modulesServices.existingHomeMortgageTotalPrincipalInterest();
    modulesServices.existingHomeSalesPriceYear();
    modulesServices.saleYearCalcs();
    modulesServices.existigHomeSellingCostsCalcs();
    modulesServices.existingHomeMortgageBalance();
    modulesServices.existingHomeNetCashFlowFromSale();
    modulesServices.existingHomeSaleSellingPrice();
    modulesServices.sellingCosts();
    modulesServices.netSellingPrice();
    modulesServices.existingLoansBasis();
    modulesServices.existingLoansBasis();
    modulesServices.gainOnSale();
    modulesServices.taxOnGain();
    // modulesServices.netGainOnSale();
    modulesServices.existingStudentLoansFedSubBeginningBalance();
    modulesServices.existingStudentLoansFedUnSubCurrentLoan();
    modulesServices.existingStudentLoansPrivateCurrentLoan();
    modulesServices.existingStudentLoansSummaryMonthlyPayment();
    modulesServices.existingStudentLoansSummaryTotalAnnualPayment();
    modulesServices.taxableGainUp();
    modulesServices.incomeNeededExistingStudentLoans();
  }
  workSheetData.module18Info = modulesServices.module18Data;

  modulesServices.simPanelCalcsInitialize();
  modulesServices.cashFlowCheck();
  // $rootScope.summaryTablesData = workSheetData;
  return workSheetData;
};
