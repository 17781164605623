import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import { withStyles } from '@material-ui/core/styles';
import User from '../../assets/img/my-account/user.png';
import Fail from '../../assets/img/login/fail.png';
import Pass from '../../assets/img/login/pass.png';
import styles from './styles';
import * as commonFunctions from '../../utilities/commonFunctions';

const IMAGE_PATH = process.env.REACT_APP_IMAGE_URL;

const AccountDetails = ( props ) => {
  const {
    classes, updateProfileImage, imgData, updateMyAccount, myDetails, listOfStates,
  } = props;


  const d = new Date();
  const currentYear = d.getFullYear();
  const yearOption = [];
  for ( let year = currentYear; year >= 1930; year -= 1 ) {
    yearOption.push( year );
  }

  const [editAccountButton, setAccountEditButton] = useState( false );
  const [editUserButton, setUserEditButton] = useState( false );
  const [passCheck, setPassCheck] = useState( false );
  const [passwordLength, setPasswordLength] = useState( false );
  const [alphabetsExist, setAlphabetsExist] = useState( false );
  const [numericExist, setNumericExist] = useState( false );
  const [passwordMatch, setPasswordMatch] = useState( false );

  const handleAccountEditButton = () => {
    setAccountEditButton( true );
    setUserEditButton( false );
  };

  const handleAccountCancel = () => {
    setAccountEditButton( false );
    setUserEditButton( false );
  };

  const handleUserEditButton = () => {
    setUserEditButton( true );
    setAccountEditButton( false );
  };

  const handleUserCancel = () => {
    setUserEditButton( false );
    setAccountEditButton( false );
  };

  const onSubmitAcountDetails = ( values ) => {
    updateMyAccount( {
      variables: {
        data: {
          type: 'details',
          user_fname: values.first_name,
          email: values.email,
          user_lname: values.last_name,
          user_identity: values.type,
          school: values.school,
          birth_year: values.birth_year,
          streetaddress: values.street_address,
          streetaddress2: values.street_address2,
          city: values.city,
          state: values.state,
          zip_code: values.zip_code,
        },
      },
    } );
    setAccountEditButton( false );
  };

  const validate = ( values ) => {
    const errors = {};

    if ( !values.current_password ) {
      errors.current_password = 'Current password is required.';
    }

    if ( !values.new_password ) {
      errors.new_password = 'New password is required.';
    }

    if ( values.new_password ) {
      if ( values.new_password.length > 8 ) {
        setPasswordLength( true );
      } else {
        setPasswordLength( false );
      }
      if ( !commonFunctions.validateAlpha( values.new_password ) ) {
        setAlphabetsExist( true );
      } else {
        setAlphabetsExist( false );
      }
      if ( !commonFunctions.validateNumeric( values.new_password ) ) {
        setNumericExist( true );
      } else {
        setNumericExist( false );
      }
    }

    if ( !values.confirm_new_password ) {
      errors.confirm_new_password = 'Confirm new password is required.';
    } else if ( values.confirm_new_password !== values.new_password ) {
      errors.confirm_new_password = 'Password and confirm password should be same.';
    }

    if ( values.confirm_new_password ) {
      if ( values.new_password === values.confirm_new_password ) {
        setPasswordMatch( true );
      } else {
        setPasswordMatch( false );
      }
    }

    return errors;
  };

  const onSubmitUsernamePassword = ( values ) => {
    updateMyAccount( {
      variables: {
        data: {
          type: 'username_password',
          user_name: values.username,
          old_password: values.current_password,
          new_password: values.new_password,
        },
      },
    } );
    setUserEditButton( false );
  };

  return (
    <div className={ classes.accountInformation }>
      <div className={ classes.accountHead }>
        <figure>
          <img src={ ( myDetails && myDetails.me.profile_image ? IMAGE_PATH + myDetails.me.profile_image : imgData || User ) } alt="" />
          <span className={ classes.uploadPhoto }>
            <input type="file" name="profile" onChange={ updateProfileImage } id="profile" />
            <span className={ classes.EditIcon }><i className="fa fa-pencil-square-o " /></span>
          </span>
        </figure>
        <h3>My Account</h3>
      </div>
      <div className={ classes.accountInformationContent }>
        <div className={ classes.accountBox }>
          <div className={ classes.informationNewHead }>
            <Typography variant="h3">Account Information</Typography>
            {!editAccountButton && <span aria-hidden="true" onClick={ handleAccountEditButton }>Edit</span>}
          </div>
          <div className={ classes.boxFormGroup }>
            <Form
              onSubmit={ onSubmitAcountDetails }
              initialValues={ {
                type: myDetails && myDetails.me.user_identity,
                birth_year: myDetails && myDetails.me.birth_year,
                state: myDetails && myDetails.me.state,
                first_name: myDetails && myDetails.me.user_fname,
                email: myDetails && myDetails.me.email,
                last_name: myDetails && myDetails.me.user_lname,
                school: myDetails && myDetails.me.school,
                street_address: myDetails && myDetails.me.streetaddress,
                street_address2: myDetails && myDetails.me.streetaddress2,
                city: myDetails && myDetails.me.city,
                zip_code: myDetails && myDetails.me.zip_code,
              } }
              render={ ( { handleSubmit, submitting } ) => (
                <form
                  className={ classes.formContact }
                  onSubmit={ handleSubmit }
                  noValidate
                >
                  <div className={ classes.formGroup }>
                    <span>Type</span>
                    <Field
                      name="type"
                      className={ classes.formSelect }
                      disabled={ !editAccountButton }
                      component="select"
                    >
                      <option value="">Select Type</option>
                      <option value="student">Student</option>
                      <option value="parent">Parent</option>
                      <option value="employee">Young Professional</option>
                      <option value="student">Other</option>
                    </Field>
                  </div>
                  <div className={ classes.formGroup }>
                    <span>First Name</span>
                    <Field
                      id="first_name"
                      placeholder="ex. Mike"
                      type="text"
                      name="first_name"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.user_fname }
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Last Name</span>
                    <Field
                      id="last_name"
                      placeholder="ex. Inkwiry"
                      type="text"
                      name="last_name"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.user_lname }
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Birth Year</span>
                    <Field
                      name="birth_year"
                      className={ classes.formSelect }
                      disabled={ !editAccountButton }
                      component="select"
                    >
                      <option value="">Select Year</option>
                      {yearOption.map( ( year ) => <option value={ year }>{year}</option> )}

                    </Field>
                  </div>
                  <div className={ classes.formGroup }>
                    <span>School</span>
                    <Field
                      id="school"
                      placeholder="ex. Inkwiry University"
                      type="text"
                      name="school"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.school }
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Email</span>
                    <Field
                      id="email"
                      placeholder="ex. mike@inkwiry.com"
                      type="text"
                      name="email"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.email }
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Street Address</span>
                    <Field
                      id="street_address"
                      placeholder="ex. 123 Inkwiry Way"
                      type="text"
                      name="street_address"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.streetaddress }
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Apartment, suite, unit, floor, etc.</span>
                    <Field
                      id="street_address2"
                      placeholder="ex. Apt. 2"
                      type="text"
                      name="street_address2"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.streetaddress2 }
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>City</span>
                    <Field
                      id="city"
                      placeholder="ex. Philadelphia"
                      type="text"
                      name="city"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.city }
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>State</span>
                    <Field
                      name="state"
                      className={ classes.formSelect }
                      disabled={ !editAccountButton }
                      component="select"
                    >
                      <option value="">Select state</option>
                      {
                        listOfStates.map( ( state ) => <option value={ state.id }>{state.state_name}</option> )
                      }

                    </Field>
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Zip Code</span>
                    <Field
                      id="zip_code"
                      placeholder="ex. 12345"
                      type="text"
                      name="zip_code"
                      fullWidth
                      disabled={ !editAccountButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.city }
                    />
                  </div>
                  <div className={ classes.btnGrouop }>
                    {editAccountButton && <Button type="submit" className={ classes.saveChanges } disabled={ submitting }>Save Changes</Button>}
                    {editAccountButton && <Button className={ classes.CancelBtn } onClick={ handleAccountCancel }>Cancel</Button>}
                  </div>
                </form>
              ) }
            />
            <Form
              onSubmit={ onSubmitUsernamePassword }
              validate={ validate }
              render={ ( { handleSubmit, submitting } ) => (
                <form
                  className={ classes.formContact }
                  onSubmit={ handleSubmit }
                  noValidate
                >
                  <div className={ `${classes.informationNewHead} ${classes.informationNewHeadMargin}` }>
                    <Typography variant="h3">Username and Password</Typography>
                    {!editUserButton && <span aria-hidden="true" onClick={ handleUserEditButton }>Edit</span>}
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Username</span>
                    <Field
                      id="username"
                      placeholder="Username"
                      type="text"
                      name="username"
                      fullWidth
                      disabled={ !editUserButton }
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      defaultValue={ myDetails && myDetails.me.user_name }
                    />
                  </div>
                  {editUserButton
                && (
                <React.Fragment>
                  <div className={ classes.formGroup }>
                    <span>Current Password</span>
                    <Field
                      id="current_password"
                      placeholder="Current Password"
                      type="password"
                      name="current_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                  
                    />
                  </div>
                  <div className={ classes.formGroup }>
                    <span>New Password</span>
                    <Field
                      id="new_password"
                      placeholder="New Password"
                      type="password"
                      name="new_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      onFocus={ () => { setPassCheck( true ); } }
                      onBlur={ () => { setPassCheck( false ); } }
                    />
                    {passCheck === true && (
                    <ul className={ classes.passwordValidation }>
                      <li>
                        <img src={ passwordLength ? Pass : Fail } alt="" />
                        Include at least 8 characters
                      </li>
                      <li>
                        <img src={ alphabetsExist ? Pass : Fail } alt="" />
                        Include upper and lower case characters
                      </li>
                      <li>
                        <img src={ numericExist ? Pass : Fail } alt="" />
                        Include at least 1 number
                      </li>
                      <li>
                        <img src={ passwordMatch ? Pass : Fail } alt="" />
                        Passwords match
                      </li>
                    </ul>
                    )}
                  </div>
                  <div className={ classes.formGroup }>
                    <span>Confirm New Password</span>
                    <Field
                      id="confirm_new_password"
                      placeholder="Confirm New Password"
                      type="password"
                      name="confirm_new_password"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      component={ TextField }
                      onFocus={ () => { setPassCheck( true ); } }
                      onBlur={ () => { setPassCheck( false ); } }
                    />

                  </div>

                </React.Fragment>
                ) }
                  <div className={ classes.btnGrouop }>
                    {editUserButton && <Button type="submit" disabled={ submitting } className={ classes.saveChanges }>Save Changes</Button>}
                    {editUserButton && <Button className={ classes.CancelBtn } onClick={ handleUserCancel }>Cancel</Button>}
                  </div>
                </form>
              ) }
            />

          </div>
        </div>
      </div>
    </div>
  );
};

AccountDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  updateProfileImage: PropTypes.func.isRequired,
  imgData: PropTypes.string.isRequired,
  updateMyAccount: PropTypes.func.isRequired,
  myDetails: PropTypes.object.isRequired,
  listOfStates: PropTypes.arrayOf( PropTypes.object ).isRequired,
};

export default withStyles( styles )( AccountDetails );
