import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import styles from '../../../screens/pwi/further-higher-education/styles';

const FederalStudentLoans = ( props ) => {
  const {
    classes, handleDefinationPopup
  } = props;

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      <p>There are two types of student loans - federal and private student loans. There are also two types of federal student loans - federal subsidized and federal unsubsidized. Before you start strategizing and planning on how to repay your loans on time or even early after graduation, you need to learn the difference between subsidized and unsubsidized.</p>
      
      <p className={ classes.headStudentLoan }><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Federal Subsidized Student Loan' ) }>Federal Subsidized Student Loans</span></p>
      <div>
        <ul>
          <li>Issued by the government</li>
          <li>
            Use the
            {' '}
            {' '}
            <a rel="noopener noreferrer federal" href="https://studentaid.ed.gov/sa/fafsa" target="_blank">Free Application for Federal Student Aid (FAFSA)</a>
            {' '}
            {' '}
            to apply for federal student loans
          </li>
          <li>
            The government pays the
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }> interest</span>
            {' '}
            while you are in school and in
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Grace Period' ) }>grace period</span>
          </li>
          <li>
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Student Loan' ) }>Term of the loan</span>
            {' '}
            is typically 10 years (120 months)
          </li>
          <li>
            No interest will
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Accrued Interest' ) }>accrue</span>
            {' '}
            on this loan until repayment period begins
          </li>
          <li>Repayment begins six months after graduation or if you stop going to school</li>
          <li>Example: if you borrow $10,000 in subsidized loans, you will have to repay $10,000 after the grace period ends and repayment begins</li>
        </ul>
      </div>
      <p className={ classes.headStudentLoan }><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Federal Unsubsidized Student Loan' ) }>Federal Unsubsidized Student Loans</span></p>
      <div>
        <ul>
          <li>Issued by the government</li>
          <li>
            Use the
            <a rel="noopener noreferrer federal" href="https://studentaid.gov/h/apply-for-aid/fafsa" target="_blank">Free Application for Federal Student Aid (FAFSA)</a>
            {' '}
            to apply for federal student loans
          </li>
          <li>
            The government does not pay the interest while you are in school and in
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Grace Period' ) }>grace period</span>
          </li>
          <li>
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Student Loan' ) }>Term of the loan</span>
            {' '}
            is typically 10 years (120 months)
          </li>
          <li>
            Interest will
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Accrued Interest' ) }>accrue</span>
            {' '}
            on this loan while you are in school and in grace period
          </li>
          <li>Repayment begins six months after graduation or if you stop going to school</li>
          <li>Example: if you borrow $27,000 in 4.5% unsubsidized loans over four years and make no payments during school, your balance will be roughly $30,700 when the grace period ends and repayment begins</li>

        </ul>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
    </div>
  );
};

FederalStudentLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( FederalStudentLoans );
