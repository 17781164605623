import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { loader } from 'graphql.macro';
import { useQuery, useApolloClient, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { Link, withRouter } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import logoImage from '../../../assets/img/mcs/logo.png';
import mcsLogoImage from '../../../assets/img/mcs/my-career-sketch.png';
import MCSTargetIcon from '../../../assets/img/mcs/MCS-target-icon.png';
import MCSLevelIcon from '../../../assets/img/mcs/MCS-level04-icon.png';
// import inivestLogoImage from '../../../assets/img/mcs/inkwiry-univest-rev.png';
import styles from './styles';
import levelOne from '../../../assets/img/mcs/MCS-level08.png';
import taskComplete from '../../../assets/img/mcs/circle_arrow.png';
import levelTwo from '../../../assets/img/mcs/MCS-level03.png';
import levelThree from '../../../assets/img/mcs/MCS-level05.png';
import levelFour from '../../../assets/img/mcs/MCS-level06.png';
import levelFive from '../../../assets/img/mcs/level5.png';
import levelSix from '../../../assets/img/mcs/MCS-level04.png';
import levelSeven from '../../../assets/img/mcs/MCS-level09.png';
import profileImage from '../../../assets/img/mcs/user.png';
import Pageloader from '../../../components/ui/pageloader';
import univestLogo from '../../../assets/img/inkwiry-univest-rev.png';

const MY_DETAILS = loader('../../../graphql/schema/auth/me.graphql');
const MY_CAREER_SKETCH_DATA = loader('../../../graphql/schema/career-sketch/my-career-sketch.graphql');
const MCS_LEVEL_ACTIVITY_DATA = loader('../../../graphql/schema/career-sketch/mcs-level-activity.graphql');
const SAVE_MCS_DATA = loader('../../../graphql/schema/career-sketch/save-careersketch.graphql');

const SAVE_UNIVEST_COUNT = loader('../../../graphql/schema/ui/save-univest-count.graphql');

const McsDahboard = (props) => {
  const { classes, history } = props;
  const [openMenu, setOpenMenu] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [sketchData, setSketchData] = React.useState("");
  const [stepNumber, setStepNumber] = React.useState(1);
  // const [levelActivityData, setLevelActivityData] = React.useState();

  const ref = React.useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenMenu(false)
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });


  const apolloClient = useApolloClient();

  const { data: myDetails } = useQuery(MY_DETAILS);

  const [mcsLevelWiseData] = useLazyQuery(MCS_LEVEL_ACTIVITY_DATA, {
    variables: {
      level_id: "0",
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res) {
        setStepNumber(res.getMCSLevelActivityData.level_activity[0].option_id);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const [getSketchData] = useLazyQuery(MY_CAREER_SKETCH_DATA, {
    fetchPolicy: 'network-only',
    variables: {
    },
    onCompleted(response) {
      if (response) {
        setSketchData(response && response.getMyCareerSketchData);
        setLoading(false);
      }
    },
    onError() {
      setLoading(false);
    },
  });

  const [saveMcsData] = useMutation(SAVE_MCS_DATA, {
    onCompleted(response) {
    mcsLevelWiseData();
    },
    onError() {
      return false;
    },
  });

  React.useEffect(() => {
    getSketchData();
    mcsLevelWiseData();
  }, []);

  const [saveUnivestCount] = useMutation(SAVE_UNIVEST_COUNT, {
    onCompleted() {
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'https://www.univest.net/';
      hiddenElement.target = '_blank';
      hiddenElement.click();
    }
  });

  const handleLogout = () => {
    localStorage.removeItem('app-token');
    apolloClient.resetStore();
    history.push('/');
  };
  const handleRedirectToLevel = (level) => {
    history.push('./level' + level);
  };

  const handleUnivest = () => {
    saveUnivestCount({
      variables: {
        school_id: myDetails && myDetails.me.institution_id
      },
    });
  }

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}

      <div className={classes.dashboardPage}>
        <div className={classes.containerFluid}>
          <div className={classes.container}>
            {myDetails && myDetails.me.institution_id !== null ?
              <span aria-hidden="true" className={classes.univestSpan}>
                <img src={univestLogo} alt="inkwiry-logo-univest" />
                <Link to='/' className={classes.inwiryLink}></Link>
                <span onClick={handleUnivest} className={classes.univestLink}></span>
              </span>
              :
              <div className={classes.dashboardLogo}>
                <Link to="/">
                  <img src={logoImage} alt="logo" />
                </Link>
              </div>
            }
          </div>
          <div className={classes.container}>
            <div className={classes.mcsDashboardRow}>
              <div className={classes.mcsLogo}>
                <Link to="#">
                  <img src={mcsLogoImage} alt="logo" />
                </Link>
              </div>
              {/* slide 1 */}
              {stepNumber === 1 && (
                <div className={classes.mcsIntroBlock}>
                  <Grid container spacing={2} className={classes.gridRow}>
                    <Grid item xs={12} sm={3} md={3}>
                      <div className={classes.mcsTargetIcon}>
                        <img src={MCSTargetIcon} alt="" />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <div className={classes.mcsTargetRight}>
                        <p className={classes.pageTitle}>Welcome to My Career Sketch by Inkwiry!</p>
                        <p className={classes.pageDesc}>I'm James DeLuca, CEO & Co-Founder of Inkwiry, and I'll be your guide as you explore your next moves in life.</p>
                        <p className={classes.pageSubDesc}>My Career Sketch is about achieving financial freedom faster with the power of planning. We're not talking about that boring, archaic planning from yesterday. We're talking about planning at the speed of thought anywhere and at any time.</p>
                        <p className={classes.pageSubDesc}>Inkwiry allows you to see the financial outcome of your decisions before you even make them. No one can predict the future. But you can plan for it. </p>
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.mcsNextBtn}>
                    <button onClick={() => {
                      saveMcsData({
                        variables: {
                          data: {
                            level: 0,
                            option_id: 1,
                          },
                        },
                      })
                    }}>NEXT</button>
                  </div>
                </div>
              )}
              {/* slide 2 */}
              {stepNumber === 2 && (
                <div className={classes.mcsIntroBlock}>
                  <Grid container spacing={2} className={classes.gridRow}>
                    <Grid item xs={12} sm={3} md={3}>
                      <div className={classes.mcsTargetIcon}>
                        <img src={MCSLevelIcon} alt="" />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={9} md={9}>
                      <div className={classes.mcsTargetRight}>
                        <p className={classes.pageTitle}>With a plan, you make things happen. <span>Without a plan, things happen to you. </span></p>
                        <p className={classes.pageSubDesc}>You're 42% more like to achieve your goals just by writing them down. That's how powerful planning really is. When it comes to your education, your career, your wealth and your happiness, are you really OK with just leaving it up to chance?</p>
                        <p className={classes.pageSubDesc}>Planning may seem annoying, boring or intimidating. You know what isn't boring? Achieving your goals and finding success in your life.</p>
                        <p className={classes.pageSubDesc}>My Career Sketch empowers you to build a path to financial freedom and a life you love. Ready to get started?</p>
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.mcsNextBtn}>
                    <button onClick={() => {
                      saveMcsData({
                        variables: {
                          data: {
                            level: 0,
                            option_id: 2,
                          },
                        },
                      })
                    }}>NEXT</button>
                  </div>
                </div>
              )}
              {/* slide 3 */}
              {stepNumber === 3 && (
                <>
                  <div className={classes.rowLevels}>
                    <div className={classes.titleBlock}>
                      <h3>{myDetails && myDetails.me.user_fname}
                        {' '}
                        {myDetails && myDetails.me.user_lname}</h3>
                      <div className={classes.profileMenu} onClick={() => setOpenMenu(true)}>
                        <span>
                          <img src={profileImage} alt="logo" />
                        </span>
                        {
                          openMenu &&
                          <ul ref={ref} className={classes.profileDropdown}>
                            <li><Link to="/desktop">My Desktop</Link></li>
                            <li><Link to="/my-account">My Account</Link></li>
                            <li onClick={handleLogout}><Link to="#">Logout</Link></li>
                          </ul>
                        }
                      </div>
                    </div>
                    <div className={classes.csBlock}>
                      <div className={classes.csLevel}>
                        <div className={classNames(classes.textCenter, classes.levelDesc)}>
                          <img src={levelOne} alt="logo" />
                          <p>Level 01</p>
                          <h3>
                            Smarter
                            <span>Tomorrows</span>
                          </h3>
                          <button type="button" className={classes.goButton} onClick={() => handleRedirectToLevel("1")}>{sketchData && sketchData.level_1 === 100 ? "Review" : "Go"}</button>
                        </div>
                        <div className={classes.progressLevels}>
                          <div className={classes.csProgress}>
                            <LinearProgress className={classes.csProgressBar} variant="determinate" value={sketchData && sketchData.level_1} />
                            {/* <div className={classNames(classes.csProgressBar, classes.csWidthHundred)} /> */}
                            <p className={classes.progressValue}>{sketchData && sketchData.level_1 > 0 ? sketchData.level_1 : 0}%</p>
                          </div>
                        </div>
                        {sketchData && sketchData.level_1 === 100 ? <span className={classes.taskComplete}>
                          <img src={taskComplete} alt="logo" />
                        </span> : ""}
                      </div>
                      <div className={sketchData && sketchData.level_1 === 100 ? classes.csLevel : classNames(classes.csLevel, classes.inactiveModule)}>
                        <div className={classNames(classes.textCenter, classes.levelDesc)}>
                          <img src={levelTwo} alt="logo" />
                          <p>Level 02</p>
                          <h3>
                            Develop
                            <span>Your Vision</span>
                          </h3>
                          <button type="button" className={classes.goButton} onClick={() => handleRedirectToLevel("2")}>{sketchData && sketchData.level_2 === 100 ? "Review" : "Go"}</button>
                        </div>
                        <div className={classes.progressLevels}>
                          <div className={classes.csProgress}>
                            <LinearProgress className={classes.csProgressBar} variant="determinate" value={sketchData && sketchData.level_2} />
                            {/* <div className={classNames(classes.csProgressBar, classes.csWidthHundred)} /> */}
                            <p className={classes.progressValue}>{sketchData && sketchData.level_2 > 0 ? sketchData.level_2 : 0}%</p>
                          </div>
                        </div>
                        {sketchData && sketchData.level_2 === 100 ? <span className={classes.taskComplete}>
                          <img src={taskComplete} alt="logo" />
                        </span> : ""}
                      </div>
                      <div className={sketchData && sketchData.level_2 === 100 ? classes.csLevel : classNames(classes.csLevel, classes.inactiveModule)}>
                        <div className={classNames(classes.textCenter, classes.levelDesc)}>
                          <img src={levelThree} alt="logo" />
                          <p>Level 03</p>
                          <h3>
                            Identify Your
                            <span>Stepping Stones</span>
                          </h3>
                          <button type="button" className={classes.goButton} onClick={() => handleRedirectToLevel("3")}>{sketchData && sketchData.level_3 === 100 ? "Review" : "Go"}</button>
                        </div>
                        <div className={classes.progressLevels}>
                          <div className={classes.csProgress}>
                            <LinearProgress className={classes.csProgressBar} variant="determinate" value={sketchData && sketchData.level_3} />
                            {/* <div className={classNames(classes.csProgressBar, classes.csWidthHundred)} /> */}
                            <p className={classes.progressValue}>{sketchData && sketchData.level_3 > 0 ? sketchData.level_3 : 0}%</p>
                          </div>
                        </div>
                        {sketchData && sketchData.level_3 === 100 ? <span className={classes.taskComplete}>
                          <img src={taskComplete} alt="logo" />
                        </span> : ""}
                      </div>
                      <div className={sketchData && sketchData.level_3 === 100 ? classNames(classes.csLevel, classes.csFinal) : classNames(classes.csLevel, classes.csFinal, classes.inactiveModule)}>
                        <div className={classNames(classes.textCenter, classes.levelDesc)}>
                          <img src={levelFour} alt="logo" />
                          <p>Level 04</p>
                          <h3>
                            Capture
                            <span>Your Details</span>
                          </h3>
                          <button type="button" className={classes.goButton} onClick={() => handleRedirectToLevel("4")}>{sketchData && sketchData.level_4 === 100 ? "Review" : "Go"}</button>
                        </div>
                        <div className={classes.progressLevels}>
                          <div className={classes.csProgress}>
                            <LinearProgress className={classes.csProgressBar} variant="determinate" value={sketchData && sketchData.level_4} />
                            {/* <div className={classNames(classes.csProgressBar, classes.csWidthHundred)} /> */}
                            <p className={classes.progressValue}>{sketchData && sketchData.level_4 > 0 ? sketchData.level_4 : 0}%</p>
                          </div>
                        </div>
                        {sketchData && sketchData.level_4 === 100 ? <span className={classes.taskComplete}>
                          <img src={taskComplete} alt="logo" />
                        </span> : ""}
                      </div>
                    </div>
                  </div>
                  <div className={classNames(classes.rowLevels, classes.rowTopMargin)}>
                    <div className={classes.csBlock}>
                      <div className={sketchData && sketchData.level_4 === 100 ? classes.csLevel : classNames(classes.csLevel, classes.inactiveModule)}>
                        <div className={classNames(classes.textCenter, classes.levelDesc)}>
                          <img src={levelFive} alt="logo" />
                          <p>Level 05</p>
                          <h3>
                            Build Your
                            <span>Stepping Stones</span>
                          </h3>
                          <button type="button" className={classes.goButton} onClick={() => handleRedirectToLevel("5")}>{sketchData && sketchData.level_5 === 100 ? "Review" : "Go"}</button>
                        </div>
                        <div className={classes.progressLevels}>
                          <div className={classes.csProgress}>
                            <LinearProgress className={classes.csProgressBar} variant="determinate" value={sketchData && sketchData.level_5} />
                            {/* <div className={classNames(classes.csProgressBar, classes.csWidthHundred)} /> */}
                            <p className={classes.progressValue}>{sketchData && sketchData.level_5 > 0 ? sketchData.level_5 : 0}%</p>
                          </div>
                        </div>
                        {sketchData && sketchData.level_5 === 100 ? <span className={classes.taskComplete}>
                          <img src={taskComplete} alt="logo" />
                        </span> : ""}
                      </div>
                      <div className={sketchData && sketchData.level_5 === 100 ? classes.csLevel : classNames(classes.csLevel, classes.inactiveModule)}>
                        <div className={classNames(classes.textCenter, classes.levelDesc)}>
                          <img src={levelSix} alt="logo" />
                          <p>Level 06</p>
                          <h3>
                            Power of
                            <span>Career Sketching</span>
                          </h3>
                          <button type="button" className={classes.goButton} onClick={() => handleRedirectToLevel("6")}>{sketchData && sketchData.level_6 === 100 ? "Review" : "Go"}</button>
                        </div>
                        <div className={classes.progressLevels}>
                          <div className={classes.csProgress}>
                            <LinearProgress className={classes.csProgressBar} variant="determinate" value={sketchData && sketchData.level_6} />
                            {/* <div className={classNames(classes.csProgressBar, classes.csWidthHundred)} /> */}
                            <p className={classes.progressValue}>{sketchData && sketchData.level_6 > 0 ? sketchData.level_6 : 0}%</p>
                          </div>
                        </div>
                        {sketchData && sketchData.level_6 === 100 ? <span className={classes.taskComplete}>
                          <img src={taskComplete} alt="logo" />
                        </span> : ""}
                      </div>
                      <div className={sketchData && sketchData.level_6 === 100 ? classNames(classes.csLevel, classes.csFinal) : classNames(classes.csLevel, classes.csFinal, classes.inactiveModule)}>
                        <div className={classNames(classes.textCenter, classes.levelDesc)}>
                          <img src={levelSeven} alt="logo" />
                          <p>Level 07</p>
                          <h3>
                            Checklist +
                            <span>Next Steps</span>
                          </h3>
                          <button type="button" className={classes.goButton} onClick={() => handleRedirectToLevel("7")}>{sketchData && sketchData.level_7 === 100 ? "Review" : "Go"}</button>
                        </div>
                        <div className={classes.progressLevels}>
                          <div className={classes.csProgress}>
                            <LinearProgress className={classes.csProgressBar} variant="determinate" value={sketchData && sketchData.level_7} />
                            {/* <div className={classNames(classes.csProgressBar, classes.csWidthHundred)} /> */}
                            <p className={classes.progressValue}>{sketchData && sketchData.level_7 > 0 ? sketchData.level_7 : 0}%</p>
                          </div>
                        </div>
                        {sketchData && sketchData.level_7 === 100 ? <span className={classes.taskComplete}>
                          <img src={taskComplete} alt="logo" />
                        </span> : ""}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Typography>
  );
};

McsDahboard.propTypes = {
  classes: PropTypes.object.isRequired,
};
const enhance = compose(
  withStyles(styles),
  withRouter,
);

export default enhance(McsDahboard);
