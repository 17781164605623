import { formatDollar } from '../utilities/chartCommonFunctions';
import { nunAbv } from '../utilities/commonFunctions';
// eslint-disable-next-line
export let graphCommonObject = {};

function showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 ) {
  graphCommonObject = {
    chart: {
      plotBackgroundColor: '#ffffff',
      plotBorderColor: '#cccccc',
      plotBorderWidth: 1,
      plotShadow: false,
      type: 'column',
      height: 280,
      // width: 900,
      spacingLeft: 0,
      spacingTop: 20,
      spacingRight: 0,
    },
    title: {
      text: '',
    },
    // navigation: {
    //   menuItemStyle: {
    //     fontSize: '14px',
    //     textAlign: 'left',
    //   },
    //   menuItemHoverStyle: {
    //     background: '#f5f5f5',
    //     color: '#4c4c4c',
    //     fontSize: '14px',
    //   },
    //   buttonOptions: {
    //     height: 40,
    //     width: 48,
    //     symbolSize: 24,
    //     symbolX: 24,
    //     symbolY: 21,
    //     symbolStrokeWidth: 2,
    //     verticalAlign: 'bottom',
    //     _titleKey: 'y',
    //   },
    // },
    // exporting: {
    //     buttons: {
    //         contextButton: {
    //           menuItems: [{
    //                 textKey: 'downloadPNG',
    //                 onclick: function () {
    //                     this.exportChart();
    //                 },
    //             }, {
    //                 textKey: 'downloadJPEG',
    //                 onclick: function () {
    //                     this.exportChart({
    //                         type: 'image/jpeg'
    //                     });
    //                 }
    //             },{
    //                 textKey: 'downloadPDF',
    //                 onclick: function () {
    //                     this.exportChart({
    //                         type: 'application/pdf'
    //                     });
    //                 }
    //             }, {
    //                 textKey: 'downloadSVG',
    //                 onclick: function () {
    //                     this.exportChart({
    //                         type: 'image/svg+xml'
    //                     });
    //                 }
    //             }]
    //         }
    //     }
    //   },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: yearsList,
      labels: {
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
    },
    yAxis: [{ // Primary yAxis
      min: minValue,
      tickInterval,
      max: maxValue,
      labels: {
        formatter() {
          if ( this.value === 0 ) {
            return formatDollar( Math.round( this.value ) );
          } if ( this.value < 0 ) {
            return `<span style="color:#bf0000;">(${formatDollar( nunAbv( -Math.round( this.value ) ) )}k)</span>`;
          }
          return `${formatDollar( nunAbv( Math.round( this.value ) ) )}k`;
        },
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
      title: {
        text: 'Income & Expenses',
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
    }, { // Tertiary yAxis
      min: minValue1,
      tickInterval: tickInterval1,
      max: maxValue1,
      title: {
        text: 'Net Worth',
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
      labels: {
        formatter() {
          if ( this.value === 0 ) {
            return formatDollar( Math.round( this.value ) );
          } if ( this.value < 0 ) {
            return `<span style="color:#bf0000;">(${formatDollar( nunAbv( -Math.round( this.value ) ) )}k)</span>`;
          }
          return `${formatDollar( nunAbv( Math.round( this.value ) ) )}k`;
        },
        style: {
          color: '#000000',
          fontSize: '13px',
        },
      },
      opposite: true,
    }],
    tooltip: {
      formatter() {
        if ( this.y < 0 ) {
          return `${this.series.name}<br/>${this.x}: <span style="color:red; font-weight:bold;">(${formatDollar( -Math.round( this.y ) )})</span>`;
        }

        return `${this.series.name}<br/>${this.x}: <b>${formatDollar( Math.round( this.y ) )}</b>`;
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        pointWidth: 9,
      },
    },
    legend: {
      itemStyle: {
        fontSize: '13px',
      },
    },
    series: [{
      name: 'Gross Income',
      yAxis: 0,
      data: grossIncomeGraphData,
      stack: 'male',
      color: '#5B9BD5',
    }, {
      name: 'Total Expenses',
      yAxis: 0,
      data: totalExpensesGraphData,
      stack: 'female',
      color: '#FF0000',
    }, {
      name: 'Estimated Taxes',
      yAxis: 0,
      data: incomeAfterTaxesGraphData,
      stack: 'female',
      color: '#F4B183',
    }, {
      type: 'spline',
      name: 'Net Worth',
      yAxis: 1,
      data: netWorthGraphData,
      color: '#00B050',
    }],
  };

  return graphCommonObject;
}

export function loadGraphs( sketchName ) {
  let grossIncomeGraphData;
  let incomeAfterTaxesGraphData;
  let totalExpensesGraphData;
  let netWorthGraphData;
  let minValue;
  let tickInterval;
  let maxValue;
  let yearsList;
  let minValue1;
  let tickInterval1;
  let maxValue1;

  switch ( sketchName ) {
    case 'monica':
      grossIncomeGraphData = [0, 47000, 48410, 49862.3, 51358.16900000001, 52898.914070000006, 55000, 56650, 178349.5, 183699.985, 189210.98455, 206127.24, 212311.05719999998, 218680.388916, 225240.80058347998];
      incomeAfterTaxesGraphData = [-0, 9685.328017, 9992.742857509998, 10309.717243235302, 10636.544702532361, 10973.52770444833, 11445.359911206398, 11807.329990480528, 43793.45006528205, 45168.57730029363, 46586.18482701663, 51193.992268115624, 52794.88927226493, 54445.11473126082, 54146.17452964318];
      totalExpensesGraphData = [3300, 18049.5, 18320.242499999997, 18595.046137499994, 18873.97182956249, 19157.08140700593, 41948.36277101071, 43792.86839220332, 76610.71126758357, 77460.11658135487, 78322.26297483273, 94340.77592937254, 105904.50522481484, 107786.2142532811, 124695.15673567285];
      netWorthGraphData = [10700, 29031.267908885562, 48334.876084448275, 68671.7320131773, 91008.75518656238, 115107.04075142235, 119916.82717848406, 123097.22363706134, 37803.610918201215, 108482.96595460438, 187001.81818789867, 265469.14162663923, 314013.2447379787, 395701.0301575852, 474647.2366977861];
      minValue = 0;
      tickInterval = 80000;
      maxValue = 240000;
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue1 = 0;
      tickInterval1 = 160000;
      maxValue1 = 480000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'mike':
      grossIncomeGraphData = [0, 0, 15000, 15450, 15913.5, 50000, 51500, 60000, 61800, 63654, 90000, 167700, 172731, 219567.5, 226154.52500000002];
      incomeAfterTaxesGraphData = [-0, -0, 1927.4643, 1994.8720148, 2079.7989210439996, 9896.98687782, 10225.7554062864, 12040.495853830527, 12434.306725097138, 12840.31155185328, 20890.389406098402, 39994.661399768745, 41269.51072796878, 54567.43014731589, 56272.15940817989];
      totalExpensesGraphData = [49604.5, 52402.91999999999, 59153.7424, 61582.932096000004, 64051.180579839995, 32661.912463894754, 35375.8649277895, 40602.82492778951, 41178.46492778951, 41764.4664477895, 54162.82492778951, 72226.30043081033, 81056.29747299926, 83575.73357467662, 92607.14155086706];
      netWorthGraphData = [-12715, -30234.41999999999, -41412.62669999999, -54637.4308108, -69934.91031168398, -53821.31796548789, -44661.060133983185, -30293.793719565198, -14079.171931262532, 1453.316526798444, 28936.260079063082, 87197.50784152895, 141029.60801808076, 237106.3009034756, 305923.01197383174];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 100000;
      maxValue = 300000;
      minValue1 = -250000;
      tickInterval1 = 250000;
      maxValue1 = 500000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'jeremy':
      grossIncomeGraphData = [12000, 12480, 12979.2, 13498.368, 65000, 67600, 70304, 80000, 83200, 86528, 110000, 179400, 186576, 255304, 265516.16000000003];
      incomeAfterTaxesGraphData = [1611.181632, 1683.62889728, 1775.7740531712002, 1872.101015298048, 14970.159896, 15743.99602752, 16551.7306687424, 23836.912838826367, 24946.655649676493, 26103.913498906564, 35227.510851458406, 53730.904019389294, 55859.968067221154, 76436.20896733088, 78721.0678353853];
      totalExpensesGraphData = [27435, 28374, 31864.608, 32693.99232, 19448.179037948816, 22961.558075897636, 23293.262075897634, 45496.35807589763, 46278.75807589763, 47076.806075897635, 53956.35807589763, 70605.75807589764, 73930.34607589763, 83810.95647589763, 95108.61017005643];
      netWorthGraphData = [-6326.181632, -14873.81052928, -27194.9925824512, -40612.71791774925, -10088.000657747169, 23024.988529494796, 59397.37981914654, 76679.1524431404, 98381.45235848468, 120361.5026075524, 141457.26902211944, 197568.31518646775, 255266.47321181232, 355094.52790229506, 440587.5956383085];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 100000;
      maxValue = 300000;
      minValue1 = -230000;
      tickInterval1 = 230000;
      maxValue1 = 460000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'jennifer':
      grossIncomeGraphData = [12000, 12720, 45000, 47700, 50562, 53595.72, 56811.463200000006, 70000, 74200, 100000, 106000, 112360, 170000, 180200, 191012];
      incomeAfterTaxesGraphData = [1431.6680000000001, 1484.92, 11354.211961323108, 12132.884818479357, 12962.571828969321, 13844.124758356891, 14780.75128855953, 18557.826983821047, 20044.173045268693, 29436.68721460628, 31528.047358898406, 33751.39229007637, 54944.89196536697, 55003.57728901906, 59057.038070794784];
      totalExpensesGraphData = [23335, 24630.5, 19629.21305258347, 20375.834552666933, 20644.205574379434, 20916.602161417613, 21193.084697261376, 35213.284096839234, 35704.21686171432, 46064.04564111922, 47709.42530856947, 48387.8001916205, 64205.565950333854, 80458.88031792338, 82541.43897917081];
      netWorthGraphData = [8637.332000000002, 6645.912, 23265.81307309987, 39872.49748950395, 58340.844317539006, 79595.45864792704, 103883.86877207871, 124461.4107034591, 148707.94613108152, 176894.32644720277, 200348.47418864953, 233111.1088039366, 290938.3694242266, 325015.42569406383, 398584.57991941756];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 80000;
      maxValue1 = 240000;
      minValue1 = 0;
      tickInterval1 = 140000;
      maxValue1 = 390000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, '', minValue1, tickInterval1, maxValue1 );
      break;
    case 'samsoft':
    case 'sam':
      grossIncomeGraphData = [123500, 129675, 136158.75, 140000, 147000, 154350, 162067.5, 170170.875, 178679.41875, 190000, 284500, 298725, 366101.25, 286008.1875, 400308.59687500005];
      incomeAfterTaxesGraphData = [30396.65, 32211.1575, 34088.610375, 35012.081399999995, 36986.3202072, 38847.068611343995, 40801.083083570884, 42853.031500242294, 45007.815222997146, 47877.25711628209, 62869.601221859324, 66457.42024629653, 70231.26700122247, 42793.76247552486, 73181.1536100659];
      totalExpensesGraphData = [60333.88550186507, 55841.06676115565, 56784.045721155635, 85638.38676115563, 87131.30676115563, 88657.07100115564, 78634.67217168, 80232.63495945698, 81865.75292856502, 71100, 88793.299746394, 90695.713744777, 135769.83787403148, 173481.87803570216, 173748.67060710044];
      netWorthGraphData = [1338.3480198357065, 49979.989610375196, 101424.35732822272, 130828.34871456065, 171404.77629127033, 219008.3652253763, 266161.56844950776, 327747.7619584294, 398559.8346185668, 486753.40314239793, 630080.1541994587, 813175.6978828766, 987449.3727181088, 1072986.0831224108, 1344292.55169199];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -700000;
      tickInterval1 = 700000;
      maxValue1 = 1400000;
      sketchName = 'sam';
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'frank':
      grossIncomeGraphData = [40780, 51600, 52786.799999999996, 54000.89639999998, 55242.91701719999, 56513.50410859558, 57813.314703093274, 60900, 62300.69999999999, 148733.61609999998, 152154.48927029996, 155654.04252351687, 159234.08550155774, 100000, 102299.99999999999];
      incomeAfterTaxesGraphData = [7765.952015619103, 10388.016531202422, 10648.566316672297, 10915.758996486822, 11189.783853024093, 11470.836741474159, 11759.120380419727, 12347.632056486407, 12648.29764290047, 34308.071103645634, 35197.348238963044, 34098.72635872593, 35011.483827261, 15288.215302112883, 15779.793543543798];
      totalExpensesGraphData = [15992.559944491195, 25832.559944491193, 26311.3599444912, 26799.7359444912, 27529.342664491196, 28042.078318891196, 28565.0686863792, 40530.66508093276, 39334.10513644157, 59343.01426670996, 60385.49506670996, 73518.45124786958, 89211.83905473938, 110533.85900341775, 112455.96877267775];
      netWorthGraphData = [19558.5140570167, 50170.50523468919, 84313.01635311337, 121018.6713198039, 159962.59778077033, 200022.98488828194, 243032.21938348142, 278274.82414223976, 319919.3016451834, 396553.79648207844, 498222.57958183764, 594626.0150971717, 655816.8496934865, 708375.829133546, 764152.2276852693];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = 0;
      tickInterval1 = 260000;
      maxValue1 = 780000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'santiago':
      grossIncomeGraphData = [10000, 10450, 10920.25, 11411.66125, 140000, 146300, 152883.5, 260000, 271700, 283926.5, 329881.62875, 344726.30204374995, 273006.25, 285291.53125, 298129.65015625];
      incomeAfterTaxesGraphData = [1222, 1276.9900000000002, 1334.4545500000002, 1394.50500475, 40450.781116, 42507.092190519994, 44607.86023111908, 67453.40826026011, 70850.64435416531, 74405.96772674013, 86191.94303043, 90538.36293625286, 57642.89510807642, 63133.668064526515, 66882.57884989154];
      totalExpensesGraphData = [75430, 78327.5, 80740.1728, 83625.979712, 50677.720931917436, 56778.44186383487, 57950.01686383487, 111796.92513116435, 114227.96540367101, 116719.78168299035, 130194.40846524085, 138725.38582109942, 209476.27734642453, 216387.5885201271, 215512.57487458817];
      netWorthGraphData = [-11147.5, -27298.990000000005, -46502.86735, -69203.69081675001, -13572.559830897604, 42595.04272242701, 100508.28431547599, 181820.06466777844, 297480.84197599743, 415758.5220159215, 575122.6545207319, 731908.9792602289, 709170.3198179795, 805483.1688142678, 912814.4398214892];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -550000;
      tickInterval1 = 550000;
      maxValue1 = 1100000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'katelyn':
      grossIncomeGraphData = [15000, 15450, 15913.5, 16390.905, 63000, 64890, 66836.70000000001, 68841.801, 80000, 82400, 84872, 202500, 208575, 214832.25, 221277.2175];
      incomeAfterTaxesGraphData = [1865.2918359197502, 1765.3480794500001, 1841.2688871585, 1919.7153190982547, 13523.761208999998, 14023.93071981, 14540.577848165101, 15074.22637323607, 18456.005929349296, 19089.38191885128, 19743.353104270755, 48546.19992426598, 50067.6631580998, 51636.07183367075, 53252.8603451254];
      totalExpensesGraphData = [73277.75, 77322.01999999999, 81978.126, 85959.79104, 39447.87920610504, 43494.49841221009, 44025.61649221009, 44565.76357957009, 52092.25841221008, 52769.53841221008, 53458.332172210095, 101213.46682527545, 96548.9651252385, 91909.31109280471, 93421.05634442836];
      netWorthGraphData = [-13198.04183591975, -31900.40991536974, -54881.30480252824, -81444.90616162648, -60679.2780046513, -45186.70853328796, -27823.743526730563, -8475.766076623295, 12720.981823079506, 36410.11239738006, 60012.744198684464, 115756.50017766647, 195636.30344003323, 283632.8849861013, 382938.1823861688];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -200000;
      tickInterval1 = 200000;
      maxValue1 = 400000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'aaron':
      grossIncomeGraphData = [10000, 10250, 10506.249999999998, 10768.906249999996, 52000, 53300, 54632.499999999985, 70000, 71749.99999999999, 73543.74999999999, 75382.34374999997, 80500, 82512.5, 134575.3125, 137939.69531249997];
      incomeAfterTaxesGraphData = [911.175, 930.225, 949.846875, 970.0549218749996, 8633.558915999998, 8840.06559432, 9052.470681206396, 12851.48336346414, 13272.051497216275, 13700.20668901289, 14132.85559008672, 15582.016714076371, 16013.854048357898, 23466.333494242663, 22105.814821162476];
      totalExpensesGraphData = [28913, 30159.340000000004, 31442.388980000003, 37072.445716040005, 42445.98196346076, 43680.519620766514, 44946.80165356108, 39892.564457754364, 36378.163353291755, 36998.48979329175, 37630.60243565176, 40227.44212215104, 40920.90485875937, 53900.97040736326, 78417.87699780775];
      netWorthGraphData = [2540.8250000000007, -2233.7400000000016, -8054.725855000008, -19263.320242915015, -15207.204946339229, -10724.231490054417, -5770.70469447365, 19383.308820852682, 46773.58110285878, 76736.5830033394, 109325.24016204817, 145167.12057555877, 184100.1612091462, 257050.76391927668, 304907.10354325804];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 50000;
      minValue1 = -180000;
      tickInterval1 = 180000;
      maxValue1 = 360000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'timmec':
    case 'tim':
      grossIncomeGraphData = [10000, 10550, 11130.25, 11742.41375, 17000, 17935, 90000, 94950, 100172.25, 116000, 122380, 139110.9, 146761.99949999998, 154833.90947249997, 160000];
      incomeAfterTaxesGraphData = [1209.1811500000001, 1261.2415999999998, 1314.6679000000001, 1371.99406025, 1972.8841000000002, 2077.407, 21946.1965233984, 23421.34127362637, 24982.981063945695, 29715.37501620628, 31634.234689586403, 37332.17471439248, 39765.01175869598, 42339.24618913641, 36224.51328819836];
      totalExpensesGraphData = [38456.5, 40145.84, 43074.87760000001, 44697.79270400001, 50179, 52579.880000000005, 39192.296714436474, 41906.793428872945, 42599.57742887295, 80360.00989512091, 56594.79342887295, 58942.832296565095, 59976.33707391894, 61030.51194681986, 86914.785912055];
      netWorthGraphData = [-3676.1811500000003, -9054.762749999998, -16846.558250000006, -25706.43126425001, -41012.31536425001, -57888.60236425001, -23146.82033763259, 14666.082855599707, 52807.63752819011, 90230.45583987523, 136252.59018044043, 190729.79684770803, 255785.8505955578, 331164.5300495983, 358079.6392541757];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 5000;
      minValue1 = -200000;
      tickInterval1 = 200000;
      maxValue1 = 400000;
      sketchName = 'tim';
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'taylor':
      grossIncomeGraphData = [40000, 41400, 42849, 57000, 58994.99999999999, 61059.82499999998, 63196.918874999974, 70000, 137450, 142260.75, 159239.87625, 179486.661875, 185768.695040625, 192270.59936704685, 199000.07034489344];
      incomeAfterTaxesGraphData = [8329.23284, 8651.2859894, 8979.363499029, 12713.511597, 13266.758930894996, 13841.535410036318, 14438.637864278784, 16664.342605146365, 32124.466522148592, 33487.91028528831, 39100.02618083521, 45127.14663616538, 46804.21262258993, 48541.92823562251, 48342.455258536065];
      totalExpensesGraphData = [17760, 18115.2, 18477.504, 26620.3488, 27152.755776, 27695.810891520003, 28249.727109350402, 36795.26840353741, 53378.533771608156, 50733.30444704032, 51747.970535981134, 60491.49486769214, 61701.324765045996, 94722.24810935535, 114399.78568252202];
      netWorthGraphData = [26996.513226013703, 41398.46919903809, 56761.12406569057, 74668.3530998906, 93831.92801093135, 114372.85112261688, 138561.5097498003, 157795.50479486556, 213621.4434719316, 278750.9893968975, 358469.96060177556, 441490.4641209554, 535677.0007342178, 588437.602600494, 664142.7888320923];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -350000;
      tickInterval1 = 350000;
      maxValue1 = 700000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'michelle':
      grossIncomeGraphData = [15000, 15600, 16224, 16872.96, 80000, 83200, 86528, 89989.12000000001, 70000, 72800, 110000, 114400, 118976, 123735.04000000001, 128684.44160000002];
      incomeAfterTaxesGraphData = [2010.131335, 2119.3365884, 2228.910051936, 2343.3624540134397, 18469.397448, 19355.426568000003, 20279.8419172416, 21244.237846183303, 12593.305978841177, 13431.18458188147, 23388.4477588984, 24489.81671407637, 25638.5570483579, 25528.848765400962, 27415.628045763548];
      totalExpensesGraphData = [42662, 44223.76, 42863.1296, 44227.73478399999, 40684.227440063456, 45162.894880126914, 45995.252560126915, 46845.922109086925, 65241.607249324356, 44304.41488012691, 49229.91400012691, 50151.746100766904, 51093.858507621, 63878.83207863128, 62259.805241566064];
      netWorthGraphData = [-9035.131334999998, -21418.7279234, -32704.767575336, -45598.404813349436, -21468.457660535296, 5367.18177670041, 35667.57834383803, 69721.50265625158, 88676.78975190918, 106944.52801645373, 157293.2677493794, 213649.80029122852, 276940.1079906674, 327496.5358087382, 402454.7087966517];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 50000;
      minValue1 = -180000;
      tickInterval1 = 210000;
      maxValue1 = 400000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'calvin':
      grossIncomeGraphData = [55000, 57200, 59488, 61867.520000000004, 66500, 69160, 71926.4, 74803.456, 77795.59424, 82500, 127300, 89232, 144801.28000000003, 150593.33120000002, 156617.06444800002];
      incomeAfterTaxesGraphData = [11560.73534, 12183.684753599999, 12809.792143743998, 13463.719029493765, 14867.968898, 15609.940876, 16384.536797561595, 17193.120521716097, 18037.111639881816, 19450.380224606277, 23972.672950196808, 14797.398025813673, 29153.309216956448, 28744.50282370038, 30406.245399475403];
      totalExpensesGraphData = [16320, 16646.4, 16979.328, 17318.91456, 31442.3505504, 33280.166040912, 33945.76936173024, 53108.69790513869, 35422.09986379749, 41889.218362641426, 85488.6994028979, 75315.83848609333, 76822.1552558152, 118600.43266129022, 122052.16230804271];
      netWorthGraphData = [41164.0167619221, 78538.17501466554, 118283.8220601108, 160547.58246762224, 193492.1018202737, 231653.9943045554, 276079.54587025323, 318277.1620030298, 368736.26507271995, 421395.93287566816, 482895.10638548096, 520014.69515285897, 601423.4846142008, 641590.8741420005, 710217.9575010269];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 60000;
      minValue1 = 0;
      tickInterval1 = 240000;
      maxValue1 = 720000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'anita':
      grossIncomeGraphData = [15000, 15750, 16537.5, 110000, 115500, 121275, 150000, 157500, 165375, 173643.75, 325000, 341250, 358312.5, 376228.125, 395039.53125];
      incomeAfterTaxesGraphData = [1335, 1403.625, 1475.68125, 25806.5814, 27374.588028, 29026.54853856, 37990.0451343312, 40033.720075242294, 42047.53822674714, 44162.28992878209, 74332.45122185934, 78493.41274629653, 80869.05912622246, 84665.84575832298, 90308.42036960933];
      totalExpensesGraphData = [63425, 66950.79999999999, 70542.128, 43738.81927863483, 46305.72794066406, 46940.98394066406, 57562.92794066406, 58423.327940664065, 60583.90296286154, 61504.7224633055, 142381.43035174554, 144545.32632954343, 162978.38574653052, 167725.57612856844, 150894.43354233043];
      netWorthGraphData = [-57420, -78284.42499999999, -102024.73424999998, -50937.66638712045, 5287.481711908753, 61377.58093533834, 134764.01687029877, 218148.58889712964, 309954.59712223907, 415106.8321202599, 541895.2343468119, 711390.000892693, 896979.3614478156, 1052412.5277497699, 1302483.9025049978];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -700000;
      tickInterval1 = 700000;
      maxValue1 = 1400000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'katrina':
      grossIncomeGraphData = [15000, 15450, 17500, 18025, 60000, 61800, 63654, 80000, 82400, 95000, 97850, 195785.5, 201659.065, 207708.83695000003, 213940.10205850002];
      incomeAfterTaxesGraphData = [2298.50434, 2384.0594702000003, 2932.8134200000004, 3033.4458225999997, 12978.015048, 13437.51951168, 13936.996512091198, 19445.611485546367, 20107.112978761295, 31266.22635718628, 32285.502875755803, 61302.17216100753, 63131.73390641724, 63015.99243580083, 64956.58485130976];
      totalExpensesGraphData = [21376, 22014.56, 60830, 63005.80000000001, 35132.89441753569, 38834.085369923436, 39276.06024992344, 48271.66418624343, 48883.515484937205, 57677.43018700744, 58458.585273714954, 83025.37172095348, 59705.89572008948, 93705.01429442446, 96337.17594063538];
      netWorthGraphData = [-2209.5043399999995, -5203.123810200001, -36808.4372302, -72320.18305280001, -53771.02836424866, -33555.318235269115, -12748.925632708604, 11470.446975800449, 35710.599561321345, 53212.80750953142, 73551.64386778804, 137883.69364619002, 219227.70208388614, 265311.34141771245, 346843.2497789976];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -210000;
      tickInterval1 = 220000;
      maxValue1 = 440000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'amber':
      grossIncomeGraphData = [12500, 37000, 37925, 38873.12499999999, 46000, 47149.99999999999, 48328.749999999985, 49536.96874999998, 50775.39296874998, 60000, 61500, 63037.499999999985, 64613.43749999998, 90000, 92250];
      incomeAfterTaxesGraphData = [1122.375, 5949.673024214282, 6129.074078626724, 6317.480078571323, 7814.213445655939, 8048.565018907165, 8290.951500308745, 8541.775562353323, 8797.497739423325, 10715.16811332068, 10992.921475587094, 11277.816155098833, 11570.034634450809, 24502.963429325056, 25159.532822911562];
      totalExpensesGraphData = [39526, 23543.45835131687, 24622.31670263374, 24947.522702633738, 33442.84136971723, 29311.61670263374, 29707.162202633743, 30108.640885133736, 29045.183396554356, 29770.11864955556, 30216.670429298898, 40347.185682762814, 42456.838969208395, 42049.29337850799, 42624.589494901236];
      netWorthGraphData = [-13648.375, -3343.8774744788607, 5565.412733018296, 15271.628398333267, 24029.869150065548, 34550.91191914669, 46568.56018506756, 60177.44394079884, 75499.802939658, 97127.1146526192, 121290.50207290014, 144851.67855405758, 150057.90884547343, 192526.97010095962, 238354.242447572];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -120000;
      tickInterval1 = 120000;
      maxValue1 = 240000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'brooke':
      grossIncomeGraphData = [5000, 5250, 5512.5, 5788.125, 40000, 42000, 60000, 63000, 66150, 72500, 76125, 79931.25, 110000, 185500, 194775];
      incomeAfterTaxesGraphData = [454.44, 474.255, 495.21374999999995, 517.3734374999999, 7633.51534019477, 8088.186697500552, 12369.053945165722, 13079.517094254086, 14011.51227173566, 16039.14730274035, 17117.89087596519, 18240.06224385351, 27464.649492357894, 43426.74190980272, 43897.19973416937];
      totalExpensesGraphData = [28136, 29320.48, 30541.4432, 31800.294208000003, 13387.928011207034, 14435.056022414068, 20551.36002241407, 39554.66207071404, 21416.752022414068, 23923.480022414067, 24353.15250241407, 24791.418432014067, 49058.41526899904, 87122.85113493726, 106960.30155482957];
      netWorthGraphData = [-2634.4399999999987, -6723.174999999999, -11791.33195, -17864.874595500005, -82.51352024178959, 18537.01573245381, 48600.749248946726, 76582.61012265181, 106781.25242529434, 145080.43149460046, 188548.5386871966, 234080.52734870574, 283662.32995922223, 347288.5517230982, 428479.61456840864];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 10000;
      minValue1 = -240000;
      tickInterval1 = 240000;
      maxValue1 = 480000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'robertcar':
    case 'robert':
      grossIncomeGraphData = [40000, 41400, 60000, 69600, 72036, 85000, 87975, 91054.12499999999, 94241.01937499997, 97539.45505312498, 100953.33597998435, 104486.70273928379, 125000, 129374.99999999999, 133903.1249999999];
      incomeAfterTaxesGraphData = [7755.6835599999995, 8057.6624846, 12267.239073, 15317.809508055001, 15962.207268836923, 19894.08436067, 20703.02602818465, 21542.53362836013, 22413.722028325537, 23317.746016749195, 24255.801719116316, 25229.128062901906, 31673.4671171079, 29759.667142098762, 31870.168974983113];
      totalExpensesGraphData = [12960, 13219.199999999999, 25376.112, 25883.634240000003, 26813.361600000004, 34317.6, 35003.952000000005, 35704.03104, 64711.14954909266, 37393.921180800004, 38141.79960441601, 38904.63559650433, 42876.9720576, 69682.47681748393, 72270.4523807923];
      netWorthGraphData = [18409.316440000002, 37056.45395540001, 60274.84570606489, 91162.62917657084, 121460.07706954083, 159730.02366602415, 202312.92652309412, 249584.3442729084, 287834.7566410466, 334089.9895413376, 388540.69083887676, 449167.8211846116, 521155.5800011649, 565270.1615132454, 635996.7182418846];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 60000;
      maxValue = 180000;
      minValue1 = -320000;
      tickInterval1 = 320000;
      maxValue1 = 620000;
      sketchName = 'robert';
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'david':
      grossIncomeGraphData = [13000, 13910, 14883.7, 15925.559000000001, 15000, 16050.000000000002, 17173.500000000004, 18375.645000000004, 60000, 67000, 71690, 76708.3, 70000, 74900, 80143];
      incomeAfterTaxesGraphData = [1747.0604079999998, 1934.7546365599999, 2132.1874611192, 2344.680583397544, 2051.9802600000003, 2195.6188782, 2349.312199674001, 2513.7640536511803, 12751.339222068338, 14304.25346892068, 15912.284209286501, 17883.17827201282, 15221.831804013786, 16811.731453230714, 18496.675258132957];
      totalExpensesGraphData = [29300, 30328, 31394.240000000005, 31505.990400000002, 84320, 87546.40000000001, 90898.928, 102486.432, 12680.312378514, 16516.624757028, 16781.344757027997, 17051.359157028, 69348.56184029354, 45202.62475702801, 46041.064757028005];
      netWorthGraphData = [-1323.5604079999976, -6179.315044559997, -11551.542505679201, -16432.654489076747, -10710.634749076744, -7308.653627276748, -6289.393826950742, -15819.944880601914, 18776.539467374518, 58341.37774469688, 106310.57209803924, 158000.01607729253, 176449.92022496814, 201880.19499848704, 230300.45133378543];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 32000;
      maxValue = 96000;
      minValue1 = -130000;
      tickInterval1 = 130000;
      maxValue1 = 260000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'laura':
      grossIncomeGraphData = [15000, 15450, 50000, 51500, 53045, 55000, 56650, 58349.5, 65000, 66950, 68958.5, 71027.25499999999, 80000, 82400, 84872];
      incomeAfterTaxesGraphData = [2248.9241353195002, 2290.0811160890003, 10449.195486172266, 10798.195821850773, 11161.014260370872, 11429.217548051354, 11812.534816494675, 12205.809460987617, 13547.984186327138, 13973.78480884518, 14412.74667197733, 14925.984371358312, 17266.4780795579, 17781.174909485173, 18494.850479111643];
      totalExpensesGraphData = [20300.5, 21062.36, 27487.102233256075, 28922.375012912147, 29398.030775547348, 34306.123990002656, 34878.68723422548, 34213.02918358825, 54040.74163627455, 36651.0332547551, 37310.7518533407, 37982.34538670083, 39411.673005131845, 45896.87950976811, 47331.07273924747];
      netWorthGraphData = [790.0758646805007, -4172.865251408501, 8308.686451456491, 22009.895479857852, 36106.2750526126, 48630.28973644219, 63497.89525292258, 78273.1879542644, 92283.33959629791, 111641.53663343209, 134663.92413338338, 160148.7081107186, 193644.53628241018, 221829.57220855498, 262445.0792763858];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 30000;
      maxValue = 90000;
      minValue1 = -140000;
      tickInterval1 = 140000;
      maxValue1 = 280000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'erica':
      grossIncomeGraphData = [10000, 10600, 11236, 11910.16, 12500, 13250, 14045, 14887.7, 65000, 68900, 85034, 90136.04000000001, 95544.20240000001, 100000, 300000];
      incomeAfterTaxesGraphData = [915, 969.9000000000001, 1028.0939999999998, 1089.77964, 1143.75, 1212.375, 1285.1175, 1362.2245500000001, 11690.418837849295, 12745.87721460628, 18196.5387588984, 19619.993174076375, 21135.488095957902, 22350.93842932506, 82099.42935007882];
      totalExpensesGraphData = [25588, 26455.52, 27354.620800000008, 29845.01376, 76188, 78823.92000000001, 81557.04480000002, 84391.09795200001, 41029.1736593647, 55245.5473187294, 55803.6913187294, 56372.998198729416, 56953.69121632942, 91085.81203300419, 83889.5473187294];
      netWorthGraphData = [3497, 3671.5800000000017, 3524.865199999993, 1500.2317999999905, -42671.518200000006, -92187.81320000002, -147104.97550000006, -207480.59800200007, -198896.29714071858, -187987.9509926495, -165586.48018013863, -138811.88446876017, -107346.1606810959, -86201.24359330372, 58149.750831822836];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 100000;
      maxValue = 300000;
      minValue1 = -220000;
      tickInterval1 = 110000;
      maxValue1 = 110000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    case 'jay':
      grossIncomeGraphData = [12000, 12480, 12979.2, 13498.368, 72000, 74880, 77875.2, 80990.208, 97000, 100880, 104915.2, 109111.808, 113476.28032000002, 140000, 145600];
      incomeAfterTaxesGraphData = [1614.803472, 1687.39561088, 1779.6914353152004, 1876.1750927278079, 16370.670545802472, 17196.430825984, 18431.15416754766, 19660.144979362787, 24505.334105049293, 25644.939092494278, 26833.316111901924, 28072.479801200036, 29364.526658966508, 37281.59028667431, 35913.24739788876];
      totalExpensesGraphData = [54320, 56365.600000000006, 60797.6448, 62816.750592000004, 50079.97671132353, 38514.2247492397, 39098.0727492397, 39693.59770923971, 47667.8235396397, 72233.86309030553, 50336.8407492397, 51157.1410692397, 51993.84739563969, 55776.4177092397, 72121.18824887276];
      netWorthGraphData = [-6154.803472, -19967.799082880003, -38825.9353181952, -60300.493002923016, -38647.362294690516, -15900.075032619876, 10277.821910637365, 39369.47577438729, 74815.11799834328, 100954.63984865564, 136734.5684712991, 180064.88470147728, 229251.2236807838, 300205.28912004764, 341907.57710374834];
      yearsList = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033];
      minValue = 0;
      tickInterval = 60000;
      maxValue = 180000;
      minValue1 = -180000;
      tickInterval1 = 180000;
      maxValue1 = 360000;
      showNetWorthGraph( grossIncomeGraphData, incomeAfterTaxesGraphData, totalExpensesGraphData, netWorthGraphData, yearsList, minValue, tickInterval, maxValue, minValue1, tickInterval1, maxValue1 );
      break;
    default: break;
  }
}

export default loadGraphs;
