import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import Radio from '@material-ui/core/Radio';
import { confirmAlert } from 'react-confirm-alert';
import { loader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/react-hooks';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import scrollToComponent from 'react-scroll-to-component';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';
import $ from 'jquery';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HCExporting from 'highcharts/modules/exporting';
import ReactTooltip from 'react-tooltip';
import * as commonFunctions from '../../utilities/commonFunctions';
import Pageloader from '../../components/ui/pageloader';
import highchartsData from '../../utilities/highchartsData';
import styles from './styles';
import ScrollToTop from '../../components/ui/scroll-to-top/scrollToTop';
// import q9 from '../../assets/img/challenges/q9.jpg';
import financeExplained from '../../assets/img/challenges/finance-explained.png';
// import PlusIcon from '../../assets/img/challenges/plus.png';
// import EqulIcon from '../../assets/img/challenges/equal.png';
import qFourImg from '../../assets/img/challenges/q4Img.png';

import feedbackIcon from '../../assets/img/awareness/feedback-icon.png';

const SAVE_QUESTIONS = loader(
  '../../graphql/schema/inkwiry-challenge/save-challenge.graphql',
);
const GET_QUESTIONS = loader(
  '../../graphql/schema/inkwiry-challenge/get-challenge.graphql',
);
const SUBSCRIPTION = loader(
  '../../graphql/schema/finanance-explained/subscription.graphql',
);
const INCOME_GROWTH_GRAPH_DATA = loader(
  '../../graphql/schema/financial-independence/getIncomeGrowthGraphData.graphql',
);

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
  },
});
HCExporting(Highcharts);

const Challenges = (props) => {
  const { classes, history } = props;

  const scrollOptions = {
    offset: -120,
    align: 'top',
    duration: 500,
  };

  const questionMainAns = [
    {
      qId: 1,
      answer: '',
      correctAns: '42',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 2,
      answer: '',
      correctAns: '250',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 3,
      answer: '',
      correctAns: 'Mike',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 4,
      answer: '',
      correctAns: 'More than $10,450',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 5,
      answer: '',
      correctAns: '12 years',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 6,
      answer: '',
      correctAns: '16',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 7,
      answer: '',
      correctAns: 'Assets - Liabilities',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 8,
      answer: '',
      correctAns: 'Less than today',
      scroll: useRef(null),
      status: '',
    },
    {
      qId: 9,
      answer: '',
      correctAns: 'ETF',
      scroll: useRef(null),
      status: '',
    },
  ];

  let myScoreSection = useRef(null);
  let quesionSection = useRef(null);
  const [questions, setQuestions] = useState(questionMainAns);
  const [progress, setProgress] = React.useState(0);
  const [myScorePopup, setMyScorePopup] = React.useState(false);
  const [totalScore, setTotalScore] = React.useState('');
  const [unselectedQuestions, setUnselectedQuestions] = React.useState('');
  const [myScoreContent, setMyscoreContent] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [errorInputName, setErrorInputName] = React.useState(false);
  const [errorInputEmail, setErrorInputEmail] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [homePage, setHomePage] = React.useState(false);

  const [saveQuestion] = useMutation(SAVE_QUESTIONS, {
    onError() {
      return false;
    },
  });

  const { data: incomeGrowthGraphData } = useQuery(INCOME_GROWTH_GRAPH_DATA, {
    onCompleted() {
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  useQuery(GET_QUESTIONS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      let updateQuestions = [...questions];
      const questionsData = response.getInkwiryChallengeQuestion;

      updateQuestions = updateQuestions.map((question) => {
        const data = question;
        const questionIndex = _.findIndex(questionsData, {
          question_id: data.qId,
        });
        if (questionIndex >= 0) {
          data.answer = questionsData[questionIndex].answer;
          if (data.answer === data.correctAns) {
            data.status = 'C';
          } else {
            data.status = 'W';
          }
        }
        return data;
      });
      setQuestions(updateQuestions);
      setLoading(false);
    },
    onError() {
      setLoading(false);
    },
  });

  const redirectToHome = () => {
    history.push('/');
  };

  const inflationGraphData =
    incomeGrowthGraphData &&
    incomeGrowthGraphData.getIncomeGrowthGraphData.data[4];

  const openSuccessPopup = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>success</h3>
            <div className={classes.NoFiDiv}>
              {homePage ? (
                <p>
                  In a few minutes, you&apos;ll receive an email message asking
                  you to verify your email address. To verify, all you need to
                  do is click on the link provided.
                </p>
              ) : (
                <p>This email has already been used to signup!</p>
              )}
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            {homePage ? (
              <button
                type="button"
                onClick={() => {
                  onClose();
                  redirectToHome();
                }}>
                OK
              </button>
            ) : (
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}>
                OK
              </button>
            )}
          </div>
        </div>
      ),
    });
  };

  const openSubscriptionNote = () => {
    confirmAlert({
      // eslint-disable-next-line
      customUI: ({ onClose }) => (
        <div className={classes.redoPopup}>
          <div className={classes.redoPopupNew}>
            <h3>That was a smart move for your money!</h3>
            <div className={classes.NoFiDiv}>
              <p>
                You&apos;re one step away from subscribing to Finance Explained
                by Inkwiry and getting smarter about your money. In a few
                minutes, you&apos;ll receive an email message asking you to
                verify your email address. To verify, all you need to do is
                click on the link provided. Then, check your email on Tuesday
                for your weekly Finance Explained by Inkwiry.
              </p>
            </div>
          </div>
          <div className={classes.buttonOkRedo}>
            <button
              type="button"
              onClick={() => {
                onClose();
                openSuccessPopup();
              }}>
              OK
            </button>
          </div>
        </div>
      ),
    });
  };

  const [subcriptionEmail] = useMutation(SUBSCRIPTION, {
    onCompleted({ Subscription: { status } }) {
      if (status) {
        setHomePage(true);
      } else {
        setHomePage(false);
      }
      openSubscriptionNote();
    },
    onError(errors) {
      // const formatedErrors = errors.graphQLErrors.map( ( { message } ) => (
      //   <span key={ `login-error-${Math.random()}` }>{message}</span>
      // ) );
      // setHomePage( false );
      openSubscriptionNote();
      // setErrorMessage( formatedErrors );
    },
  });

  useEffect(() => {
    const answerQuestions = _.reject(questions, ['answer', '']).length;
    let progressBar = answerQuestions * (100 / 9);
    progressBar = progressBar.toFixed(0);
    if (progressBar > 99) {
      progressBar = 100;
    }
    setProgress(progressBar);
  }, [questions]);

  useEffect(() => {
    if (myScoreContent === true) {
      scrollToComponent(myScoreSection, {
        offset: -56,
        align: 'top',
        duration: 500,
      });
    }
  }, [myScoreContent]);

  const handleAnswer = (event, index) => {
    setQuestions((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        answer: event.target.value,
        status: prevState[index].correctAns === event.target.value ? 'C' : 'W',
      },
    }));

    saveQuestion({
      variables: {
        data: {
          answer: event.target.value,
          question_id: index + 1,
        },
      },
    });
  };

  const handleMyScore = () => {
    const unAnswerQuestions = _.filter(questions, ['answer', '']);
    if (unAnswerQuestions.length > 0) {
      setUnselectedQuestions(_.map(unAnswerQuestions, 'qId').join(', '));
      setMyScorePopup(true);
    } else {
      setMyscoreContent(true);
      const correctAns = _.filter(questions, ['status', 'C']).length;
      const correctScore = correctAns * (100 / 9);
      setTotalScore(correctScore);
      if (myScoreContent) {
        scrollToComponent(myScoreSection, {
          offset: -56,
          align: 'top',
          duration: 500,
        });
      }
    }
  };

  const handleMyScoreClose = () => {
    setMyScorePopup(false);
  };

  const handleQuestionScroll = (questionNumber) => {
    scrollToComponent(questionNumber, scrollOptions);
  };

  const elementInViewport2 = (el) => {
    let element = el;
    if (element !== null && element.offsetTop !== undefined) {
      let top = element.offsetTop;
      let left = element.offsetLeft;
      const width = element.offsetWidth;
      const height = element.offsetHeight;

      while (element.offsetParent) {
        element = element.offsetParent;
        top += element.offsetTop;
        left += element.offsetLeft;
      }

      return (
        top < window.pageYOffset + window.innerHeight &&
        left < window.pageXOffset + window.innerWidth &&
        top + height > window.pageYOffset &&
        left + width > window.pageXOffset
      );
    }
    return false;
  };

  const elementInViewport = (el) => {
    let element = el;
    if (typeof $ === 'function' && element instanceof $) {
      element = element[0];
    }

    if (typeof element !== 'undefined' && element !== '') {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 75 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    return false;
  };

  useEffect(() => {
    const onScroll = () => {
      let feedBackSectionCondition = true;
      if (myScoreSection.current === undefined) {
        if (
          myScoreSection.getBoundingClientRect().top <
          window.innerHeight / 2
        ) {
          feedBackSectionCondition = false;
        }
      }
      if (
        quesionSection !== null &&
        quesionSection.getBoundingClientRect().top <= 65 &&
        feedBackSectionCondition === true
      ) {
        document.getElementById('awareness_title').style.position = 'fixed';
        document.getElementById('awareness_title_sub').style.display = 'block';
      } else {
        document.getElementById('awareness_title').style.position = '';
        document.getElementById('awareness_title_sub').style.display = 'none';
      }

      if (feedBackSectionCondition === true) {
        document.getElementById('sticky_numbers').style.left = '30px';
      } else {
        document.getElementById('sticky_numbers').style.left = '-11em';
      }

      if (
        (myScoreContent === true && elementInViewport2(myScoreSection)) ||
        elementInViewport2(document.getElementById('footer'))
      ) {
        document.getElementById('progress-bar').style.display = 'none';
      } else {
        document.getElementById('progress-bar').style.display = 'block';
      }

      for (let i = 0; i < 9; i += 1) {
        const element = $(questionMainAns[i].scroll);
        let subElement = false;
        if (questions[i].answer !== '') {
          subElement = true;
        }

        if (
          elementInViewport(element) === true ||
          (subElement === true &&
            (elementInViewport(element.find('>div:first-child')) ||
              elementInViewport(element.find('>div:last-child')) ||
              elementInViewport(element.find('>div:last-child').find('h3')) ||
              elementInViewport(element.find('>div:last-child').find('h2')) ||
              elementInViewport(element.find('>div:last-child').find('div')) ||
              elementInViewport(element.find('>div:last-child').find('p')) ||
              elementInViewport(
                element.find('>div:last-child').find('p:last-child'),
              )))
        ) {
          element.css('opacity', '1');
          // element.siblings( '.Challenges-questionBlock-27' ).css( 'opacity', '0.3' );
          element
            .siblings()
            .not('#progress-bar, #my-score-button')
            .css('opacity', '0.3');
          $('#sticky_numbers li').removeClass('stick_number_active_class');
          $(`#sticky_numbers ul:first-child li:nth-child(${i + 1})`).addClass(
            'stick_number_active_class',
          );
          break;
        }
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
    if (e.target.value !== '') {
      setErrorInputName(false);
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    if (e.target.value !== '') {
      setErrorInputEmail(false);
    }
  };

  const handleSubmit = () => {
    if (!firstName && !email) {
      setErrorInputName(true);
      setErrorInputEmail(true);
    } else if (!firstName) {
      setErrorInputName(true);
    } else if (!email) {
      setErrorInputEmail(true);
    } else if (!commonFunctions.validateEmail(email)) {
      // setErrorMessage( 'Please enter valid email.' );
      setErrorInputEmail(true);
    } else {
      subcriptionEmail({
        variables: {
          data: {
            first_name: firstName,
            email,
          },
        },
      });
    }
  };

  return (
    <Typography variant="body1" component="div">
      {loading && <Pageloader loading={loading} />}
      <div
        className={classes.ChallengesBody}
        ref={(section) => {
          quesionSection = section;
        }}>
        <div className={classes.ChallengesQuizHead} id="awareness_title">
          <div className={classes.container}>
            <Typography variant="h3" component="h3">
              INKWIRY AWARENESS QUIZ
            </Typography>
          </div>
        </div>

        <div
          className={classNames(
            classes.ChallengesQuizHead,
            classes.ChallengesQuizSub,
          )}
          id="awareness_title_sub">
          <div className={classes.container}>
            <Typography variant="h3" component="h3">
              INKWIRY AWARENESS QUIZ
            </Typography>
          </div>
        </div>
        <div className={classes.ChallengesBody}>
          <div id="sticky_numbers" className={classes.ChallengesNumbers}>
            <ul>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, index) => (
                <li key={value}>
                  <Button
                    onClick={() =>
                      handleQuestionScroll(questionMainAns[index].scroll)
                    }
                    className={classNames(
                      classes.listNum,
                      questions[index].answer === questions[index].correctAns
                        ? classes.listNumRight
                        : questions[index].answer !== '' &&
                            classes.listNumWrong,
                    )}>
                    <span>
                      {value}
                      <i className="las la-check" />
                    </span>
                  </Button>
                </li>
              ))}
              <li className={classNames(classes.listNum, classes.arrowUp)}>
                <Button
                  onClick={() =>
                    handleQuestionScroll(questionMainAns[0].scroll)
                  }>
                  <span>
                    <i
                      className="las la-arrow-up"
                      data-for="returnStart"
                      data-tip="Return to start"
                    />
                  </span>
                  <ReactTooltip
                    id="returnStart"
                    place="right"
                    type="info"
                    effect="solid"
                    className={classes.tooltipInfo}
                  />
                </Button>
              </li>
              {progress === 100 && (
                <li className={classes.feedbackImg}>
                  <Button
                    onClick={handleMyScore}
                    className={`${classes.listNumArrow} ${classes.arrowUp}`}>
                    <span>
                      <figure>
                        <img
                          src={feedbackIcon}
                          alt="Feedback"
                          data-for="returnFeedback"
                          data-tip="Go to Feedback"
                        />
                        <ReactTooltip
                          id="returnFeedback"
                          place="right"
                          type="info"
                          effect="solid"
                          className={classes.tooltipInfo}
                        />
                      </figure>
                    </span>
                  </Button>
                </li>
              )}
            </ul>
          </div>
          <div className={classes.questionRow}>
            <div className={classes.container}>
              <div
                ref={(section) => {
                  questionMainAns[0].scroll = section;
                }}
                className={classNames(
                  classes.questionBlock,
                  classes.firstQuestionBlock,
                )}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>1</span>
                  <p className={classes.questionTitle}>
                    Just by writing down your goals on a regular basis, you
                    increase your chances of success by how much?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[0].answer}
                        onChange={(e) => handleAnswer(e, 0)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[0].answer === '12' && classes.ansWrong,
                          )}
                          value="12"
                          control={<Radio />}
                          label="12%"
                          disabled={questions[0].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[0].answer === '28' && classes.ansWrong,
                          )}
                          value="28"
                          control={<Radio />}
                          label="28%"
                          disabled={questions[0].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[0].answer !== '' && classes.ansRight,
                          )}
                          value="42"
                          control={
                            <Radio checked={questions[0].answer !== ''} />
                          }
                          label="42%"
                          disabled={questions[0].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[0].answer === '60' && classes.ansWrong,
                          )}
                          value="60"
                          control={<Radio />}
                          label="60%"
                          disabled={questions[0].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[0].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[0].answer === questions[0].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[0].answer === questions[0].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        It&apos;s easy to increase your chances for success
                      </Typography>
                      <p className={classes.boxPara}>
                        You become{' '}
                        <span className={classes.spanColor}>
                          42% more likely
                        </span>{' '}
                        to achieve your goals by writing them down on a regular
                        basis. But what does that actually mean?
                      </p>
                      <p className={classes.boxPara}>
                        Imagine you have a 50/50, or 50%, chance of achieving
                        your goal. If you write down that goal on a regular
                        basis, your 50% jumps to 71%!
                      </p>
                      <p className={classes.boxPara}>
                        If you were making a bet and had the opportunity to
                        increase your chances - wouldn't you take it? Especially
                        if that bet is on{' '}
                        <span className={classes.spanColor}>your future.</span>
                      </p>
                    </div>
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[1].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {/* , classes.scrollBlur */}
              <div
                ref={(section) => {
                  questionMainAns[1].scroll = section;
                }}
                className={classNames(classes.questionBlock)}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>2</span>
                  <p className={classes.questionTitle}>
                    Planning is proven to yield more money. How much more could
                    your savings grow over your lifetime with a financial plan
                    in place?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[1].answer}
                        onChange={(e) => handleAnswer(e, 1)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[1].answer === '50' && classes.ansWrong,
                          )}
                          value="50"
                          control={<Radio />}
                          label="50%"
                          disabled={questions[1].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[1].answer === '125' && classes.ansWrong,
                          )}
                          value="125"
                          control={<Radio />}
                          label="125%"
                          disabled={questions[1].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[1].answer === '200' && classes.ansWrong,
                          )}
                          value="200"
                          control={<Radio />}
                          label="200%"
                          disabled={questions[1].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[1].answer !== '' && classes.ansRight,
                          )}
                          value="250"
                          control={
                            <Radio checked={questions[1].answer !== ''} />
                          }
                          label="250%"
                          disabled={questions[1].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[1].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[1].answer === questions[1].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[1].answer === questions[1].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        The power of planning
                      </Typography>
                      <p className={classes.boxPara}>
                        Planning may seem boring or intimidating. You know what
                        isn’t boring? Achieving your goals and finding success
                        in your life.
                      </p>
                      <p className={classes.boxPara}>
                        <span className={classes.spanColor}>
                          With a plan, individuals accumulated nearly 250% more
                          in savings than those without a financial plan in
                          place.
                        </span>
                      </p>
                      <p className={classes.boxPara}>
                        It really is that simple. Build your plan and, on
                        average, more than double your savings in your lifetime.
                      </p>
                      <p className={classes.boxPara}>
                        <span className={classes.spanColor}>
                          Without a plan, things happen to you. Like the nearly
                          8 out of 10 Americans that live paycheck-to-paycheck.
                        </span>
                      </p>
                    </div>
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[2].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                ref={(section) => {
                  questionMainAns[2].scroll = section;
                }}
                className={classes.questionBlock}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>3</span>
                  <p className={classes.questionTitle}>
                    Mike saves and invests $450 per month from age 21–32, and
                    then stops. He earns 6% annualized return. Mike contributes
                    a total of $64,800. Mary saves and invests $450 per month
                    from age 32–60. She earns 6% annualized return on her
                    savings. She contributes a total of $156,600. In 40 years,
                    who has more money saved?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[2].answer}
                        onChange={(e) => handleAnswer(e, 2)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[2].answer !== '' && classes.ansRight,
                          )}
                          value="Mike"
                          control={
                            <Radio checked={questions[2].answer !== ''} />
                          }
                          label="Mike"
                          disabled={questions[2].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[2].answer === 'Mary' && classes.ansWrong,
                          )}
                          value="Mary"
                          control={<Radio />}
                          label="Mary"
                          disabled={questions[2].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[2].answer ===
                              'Mike and Mary end up with the same amount' &&
                              classes.ansWrong,
                          )}
                          value="Mike and Mary end up with the same amount"
                          control={<Radio />}
                          label="Mike and Mary end up with the same amount"
                          disabled={questions[2].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[2].answer === 'Not enough information' &&
                              classes.ansWrong,
                          )}
                          value="Not enough information"
                          control={<Radio />}
                          label="Not enough information"
                          disabled={questions[2].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[2].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[2].answer === questions[2].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[2].answer === questions[2].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        The power of compounding interest and time
                      </Typography>
                      <div className={classes.graphBlock}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={8}>
                            <div className={classes.graphBox}>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={highchartsData[0].options}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <div className={classes.graphBoxRight}>
                              <div className={classes.BoxBlue}>
                                <p>
                                  Molly saves $500 per month from age 21–60 and
                                  earns a 6% return.
                                </p>
                                <p>
                                  <span>
                                    Molly contributes a total of $240,000 and
                                    grows her savings to $1,000,724!
                                  </span>
                                </p>
                              </div>
                              <div className={classes.BoxGreen}>
                                <p>
                                  Mike saves $450 per month from age 21–32 and
                                  then stops. He earns a 6% return.
                                </p>
                                <p>
                                  Mike contributes a total of $64,800 and grows
                                  his savings to $507,814!
                                </p>
                                <p>
                                  <span>Mary never catches up to Mike.</span>
                                </p>
                              </div>
                              <div className={classes.BoxRed}>
                                <p>
                                  Mary saves $450 per month from age 32–60 and
                                  earns a 6% return. Mary contributes a total of
                                  $156,600 and grows her savings to $422,645!
                                </p>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        <p className={classes.boxPara}>
                          <span className={classes.spanColor}>
                            The earlier you start saving, the better.
                          </span>{' '}
                          As you can see in the example above, Mike contributes
                          $90,000 less than Mary over the same period. But,
                          because he starts saving earlier, Mary never catches
                          up to Mike! That’s the power of compounding interest
                          and time.
                        </p>
                      </div>
                    </div>
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[3].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                ref={(section) => {
                  questionMainAns[3].scroll = section;
                }}
                className={classes.questionBlock}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>4</span>
                  <p className={classes.questionTitle}>
                    Imagine you invest $10,000 into a high-yield savings account
                    that earns 1.5% per year compounded monthly. After three
                    years, how much do you think you would have in your savings
                    account, assuming you did not make any withdrawals?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[3].answer}
                        onChange={(e) => handleAnswer(e, 3)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[3].answer === 'Less than $10,450' &&
                              classes.ansWrong,
                          )}
                          value="Less than $10,450"
                          control={<Radio />}
                          label="Less than $10,450"
                          disabled={questions[3].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[3].answer === 'Exactly $10,450' &&
                              classes.ansWrong,
                          )}
                          value="Exactly $10,450"
                          control={<Radio />}
                          label="Exactly $10,450"
                          disabled={questions[3].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[3].answer !== '' && classes.ansRight,
                          )}
                          value="More than $10,450"
                          control={
                            <Radio checked={questions[3].answer !== ''} />
                          }
                          label="More than $10,450"
                          disabled={questions[3].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[3].answer === 'Not enough information' &&
                              classes.ansWrong,
                          )}
                          value="Not enough information"
                          control={<Radio />}
                          label="Not enough information"
                          disabled={questions[3].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[3].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[3].answer === questions[3].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[3].answer === questions[3].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        Compound interest
                      </Typography>
                      <p className={classes.boxPara}>
                        Albert Einstein once described compound interest as the
                        "eighth wonder of the world," saying, "he who
                        understands it, earns it; he who doesn't, pays for it."
                      </p>
                      <p className={classes.boxPara}>
                        Your high-yield savings account pays 1.5% per year, but
                        the key words are "compounded monthly." Each month you
                        earn interest and then the next month you earn interest
                        on your initial $10,000 investment plus the interest
                        you’ve already earned. Easiest to see in a table.
                      </p>
                      <table className={classes.interestTable}>
                        <thead>
                          <tr>
                            <th>Month</th>
                            <th>Beginning Balance</th>
                            <th>Interest Rate (monthly)</th>
                            <th>Interest Earned</th>
                            <th>Ending Balance</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>$10,000.00</td>
                            <td>0.125%</td>
                            <td>$12.50</td>
                            <td>$10,012.50</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>$10,012.50</td>
                            <td>0.125%</td>
                            <td>$12.52</td>
                            <td>$10,025.02</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>$10,025.02</td>
                            <td>0.125%</td>
                            <td>$12.53</td>
                            <td>$10,037.55</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>$10,037.55</td>
                            <td>0.125%</td>
                            <td>$12.55</td>
                            <td>$10,050.09</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>$10,050.09</td>
                            <td>0.125%</td>
                            <td>$12.56</td>
                            <td>$10,062.66</td>
                          </tr>
                          <tr class="row-center">
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                            <td>--</td>
                          </tr>
                          <tr>
                            <td>32</td>
                            <td>$10,394.85</td>
                            <td>0.125%</td>
                            <td>$12.99</td>
                            <td>$10,407.85</td>
                          </tr>
                          <tr>
                            <td>33</td>
                            <td>$10,407.85</td>
                            <td>0.125%</td>
                            <td>$13.01</td>
                            <td>$10,420.86</td>
                          </tr>
                          <tr>
                            <td>34</td>
                            <td>$10,420.86</td>
                            <td>0.125%</td>
                            <td>$13.03</td>
                            <td>$10,433.88</td>
                          </tr>
                          <tr>
                            <td>35</td>
                            <td>$10,433.88</td>
                            <td>0.125%</td>
                            <td>$13.04</td>
                            <td>$10,446.93</td>
                          </tr>
                          <tr>
                            <td>36</td>
                            <td>$10,446.93</td>
                            <td>0.125%</td>
                            <td>$13.06</td>
                            <td>$10,459.98</td>
                          </tr>
                        </tbody>
                      </table>
                      <p className={classes.boxPara}>
                        Compound interest is the eighth wonder of the world.
                        Small amounts of savings earn interest on interest over
                        time and grow to large amounts of money.
                      </p>
                    </div>
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[4].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                ref={(section) => {
                  questionMainAns[4].scroll = section;
                }}
                className={classes.questionBlock}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>5</span>
                  <p className={classes.questionTitle}>
                    How long does it take for an investment that returns 6% per
                    year to double in value? For example, if you invested $5,000
                    in an investment that returned 6% per year, how long would
                    it take to grow to $10,000?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(
                          classes.radioGroup,
                          classes.radioGroupMax,
                        )}
                        name="questionOne"
                        value={questions[4].answer}
                        onChange={(e) => handleAnswer(e, 4)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[4].answer === '6 years' &&
                              classes.ansWrong,
                          )}
                          value="6 years"
                          control={<Radio />}
                          label="6 years"
                          disabled={questions[4].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[4].answer === '10 years' &&
                              classes.ansWrong,
                          )}
                          value="10 years"
                          control={<Radio />}
                          label="10 years"
                          disabled={questions[4].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[4].answer !== '' && classes.ansRight,
                          )}
                          value="12 years"
                          control={
                            <Radio checked={questions[4].answer !== ''} />
                          }
                          label="12 years"
                          disabled={questions[4].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[4].answer === '14 years' &&
                              classes.ansWrong,
                          )}
                          value="14 years"
                          control={<Radio />}
                          label="14 years"
                          disabled={questions[4].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[4].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        The Rule of 72
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={8} md={8}>
                          <div className={classes.flexImg}>
                            <img src={qFourImg} alt="" />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <div className={classes.flexCnt}>
                            <p className={classes.boxPara}>
                              The rule of 72 is a quick way to calculate the
                              number of years it will take for an investment to
                              double in value given an annual rate of return or
                              interest rate.
                            </p>
                            <p className={classes.boxPara}>
                              # of years to double in value = 72 / annual rate
                              of return
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    {/* <div >
                          <div >
                            <div >
                              <img src={qFourImg} alt="" />
                            </div>
                          </div>
                          <div >
                            <p>The rule of 72 is a quick way to calculate the number of years it will take for an investment to double in value given an annual rate of return or interest rate.</p>
                            <p># of years to double in value = 72 / annual rate of return</p>
                          </div>
                        </div> */}
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[5].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                ref={(section) => {
                  questionMainAns[5].scroll = section;
                }}
                className={classes.questionBlock}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>6</span>
                  <p className={classes.questionTitle}>
                    Americans believe they need $1.7 million, on average, to
                    retire, according to a recent survey from Charles Schwab.
                    What % of Americans have more than $100k saved for
                    retirement?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[5].answer}
                        onChange={(e) => handleAnswer(e, 5)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[5].answer !== '' && classes.ansRight,
                          )}
                          value="16"
                          control={
                            <Radio checked={questions[5].answer !== ''} />
                          }
                          label="16%"
                          disabled={questions[5].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[5].answer === '22' && classes.ansWrong,
                          )}
                          value="22"
                          control={<Radio />}
                          label="22%"
                          disabled={questions[5].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[5].answer === '40' && classes.ansWrong,
                          )}
                          value="40"
                          control={<Radio />}
                          label="40%"
                          disabled={questions[5].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[5].answer === '58' && classes.ansWrong,
                          )}
                          value="58"
                          control={<Radio />}
                          label="58%"
                          disabled={questions[5].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[5].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[5].answer === questions[5].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[5].answer === questions[5].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        Retirement savings are way off track
                      </Typography>
                      <p className={classes.boxPara}>
                        A 2019 survey illuminated some alarming statistics on
                        Americans and their retirement preparation.
                        <span className={classes.spanColor}>
                          Only 16% of Americans have more than $100k{' '}
                        </span>
                        saved for retirement.
                      </p>
                      <div className={classes.graphBlock}>
                        <Grid container spacing={1} className={classes.GridPad}>
                          <Grid item xs={12}>
                            <div className={classes.graphBox}>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={highchartsData[4].options}
                              />
                            </div>
                          </Grid>
                          <h4
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              width: '100%',
                            }}>
                            Retirement Savings
                          </h4>
                        </Grid>
                        {/* <p className={classes.boxPara}>
                              Only 16% have $100k or more saved for retirement. Fidelity estimates that a 65-year old couple, retiring in 2019, will need
                              {' '}
                              <span className={classes.spanColor}>$285,000 to cover health care and medical expenses</span>
                              {' '}
                              throughout retirement.
                            </p>
                            <p className={classes.boxPara}>Few Americans have saved enough for basic healthcare needs in retirement - what about fun and relaxation?</p> */}
                      </div>
                    </div>
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[6].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                ref={(section) => {
                  questionMainAns[6].scroll = section;
                }}
                className={classes.questionBlock}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>7</span>
                  <p className={classes.questionTitle}>
                    How is net worth calculated?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[6].answer}
                        onChange={(e) => handleAnswer(e, 6)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[6].answer === 'Savings Rate × Income' &&
                              classes.ansWrong,
                          )}
                          value="Savings Rate × Income"
                          control={<Radio />}
                          label="Savings Rate * Income"
                          disabled={questions[6].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[6].answer !== '' && classes.ansRight,
                          )}
                          value="Assets - Liabilities"
                          control={<Radio />}
                          label="Assets - Liabilities"
                          disabled={questions[6].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[6].answer === 'Income - Debt Payments' &&
                              classes.ansWrong,
                          )}
                          value="Income - Debt Payments"
                          control={<Radio />}
                          label="Income - Debt Payments"
                          disabled={questions[6].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[6].answer === '20' && classes.ansWrong,
                          )}
                          value="20"
                          control={<Radio />}
                          label="Assets-Monthly Expenses"
                          disabled={questions[6].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[6].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[6].answer === questions[6].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[6].answer === questions[6].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        Net worth
                      </Typography>
                      <p className={classes.boxPara}>
                        Net worth is a snapshot of your financial health at a
                        point in time. A positive net worth means your assets
                        are greater than your liabilities. The higher the net
                        worth, the more financial health you have.
                      </p>
                      <p className={classNames(classes.boxPara, classes.mtb40)}>
                        <span className={classes.spanColor}>
                          Net Worth = Assets (what you own) - Liabilities (what
                          you owe)
                        </span>
                      </p>
                      <p className={classes.boxPara}>
                        Knowing your net worth is important for two main
                        reasons:
                      </p>
                      <p className={classes.boxPara}>
                        1) Net worth shows your current financial situation in
                        one, clean number, and
                      </p>
                      <p className={classes.boxPara}>
                        2) Net worth provides a reference point for measuring
                        your future financial progress.
                      </p>

                      <p className={classNames(classes.boxPara, classes.mt40)}>
                        Growing your net worth starts with knowing your net
                        worth today. What's your net worth?
                      </p>
                    </div>
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[7].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                ref={(section) => {
                  questionMainAns[7].scroll = section;
                }}
                className={classes.questionBlock}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>8</span>
                  <p className={classes.questionTitle}>
                    The interest rate on your high-yield savings account is 1.5%
                    per year and inflation is 2.5% per year. After 1 year with
                    your savings account, would you be able to buy…
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[7].answer}
                        onChange={(e) => handleAnswer(e, 7)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[7].answer === 'More than today' &&
                              classes.ansWrong,
                          )}
                          value="More than today"
                          control={<Radio />}
                          label="More than today"
                          disabled={questions[7].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[7].answer ===
                              'Exactly the same as today' && classes.ansWrong,
                          )}
                          value="Exactly the same as today"
                          control={<Radio />}
                          label="Exactly the same as today"
                          disabled={questions[7].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[7].answer !== '' && classes.ansRight,
                          )}
                          value="Less than today"
                          control={
                            <Radio checked={questions[7].answer !== ''} />
                          }
                          label="Less than today"
                          disabled={questions[7].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[7].answer === 'Not enough info' &&
                              classes.ansWrong,
                          )}
                          value="Not enough info"
                          control={<Radio />}
                          label="Not enough info"
                          disabled={questions[7].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[7].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[7].answer === questions[7].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[7].answer === questions[7].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        Inflation hurts
                      </Typography>
                      <p className={classes.boxPara}>
                        <span className={classes.spanColor}>
                          Inflation is the rise in price of goods and services
                          expressed as a percentage over a specific period.
                        </span>
                        If the annual inflation rate is 2.5% but your savings
                        account only earns 1.5%, the cost of goods and services
                        has outpaced the return on your money.
                      </p>
                      <p className={classes.boxPara}>
                        If inflation outpaces your earnings, that's bad because
                        now you can afford less than the year before. That's
                        fewer dinners out, cars, phones, healthcare, and, most
                        importantly, less money for savings and investments
                      </p>
                      <div className={classes.graphBlock}>
                        <Grid container spacing={1} className={classes.GridPad}>
                          <Grid item xs={12}>
                            <div className={classes.graphBox}>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                  chart: {
                                    type: 'spline',
                                    scrollablePlotArea: {
                                      scrollPositionX: 0,
                                    },
                                  },
                                  navigation: {
                                    menuItemStyle: {
                                      fontSize: '14px',
                                      textAlign: 'left',
                                    },
                                    menuItemHoverStyle: {
                                      background: '#f5f5f5',
                                      color: '#4c4c4c',
                                      fontSize: '14px',
                                    },
                                    buttonOptions: {
                                      height: 40,
                                      width: 48,
                                      symbolSize: 24,
                                      symbolX: 24,
                                      symbolY: 21,
                                      symbolStrokeWidth: 2,
                                      verticalAlign: 'bottom',
                                      _titleKey: 'y',
                                    },
                                  },
                                  exporting: {
                                    buttons: {
                                      contextButton: {
                                        menuItems: [
                                          'downloadPNG',
                                          'downloadJPEG',
                                          'downloadPDF',
                                          'downloadSVG',
                                        ],
                                      },
                                    },
                                  },
                                  credits: {
                                    enabled: false,
                                  },
                                  title: {
                                    text: 'Inflation Rate (12-month percentage change)',
                                  },
                                  xAxis: [
                                    {
                                      tickInterval: 24,
                                      categories:
                                        inflationGraphData &&
                                        inflationGraphData.dates.slice(12),

                                      labels: {
                                        formatter() {
                                          const chart = this;
                                          return chart.value.split(' ')[1];
                                        },
                                        style: {
                                          color: '#000000',
                                          fontSize: '13px',
                                        },
                                      },
                                    },
                                  ],
                                  yAxis: [
                                    {
                                      // Primary yAxis
                                      tickInterval: 1,
                                      labels: {
                                        format: '{value:.1f}%',
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                      title: {
                                        text: '',
                                        style: {
                                          color: '#000000',
                                          fontSize: '14px',
                                        },
                                      },
                                    },
                                  ],
                                  tooltip: {
                                    headerFormat:
                                      '<span style="font-size:14px">{point.key}</span><br/>',
                                    crosshairs: true,
                                    shared: true,
                                    valueDecimals: 1,
                                    valueSuffix: '%',
                                    style: {
                                      color: '#000000',
                                      fontSize: '14px',
                                    },
                                  },
                                  legend: {
                                    itemStyle: {
                                      fontSize: '14px',
                                    },
                                  },
                                  plotOptions: {
                                    spline: {
                                      lineWidth: 2,
                                      states: {
                                        hover: {
                                          lineWidth: 2,
                                        },
                                      },
                                      marker: {
                                        enabled: false,
                                      },
                                    },
                                  },
                                  series: [
                                    {
                                      name: inflationGraphData.details[0].name,
                                      color:
                                        inflationGraphData.details[0].color,
                                      data: inflationGraphData.details[0].data.slice(
                                        12,
                                      ),
                                    },
                                    {
                                      name: inflationGraphData.details[1].name,
                                      color:
                                        inflationGraphData.details[1].color,
                                      data: inflationGraphData.details[1].data.slice(
                                        12,
                                      ),
                                    },
                                    // {
                                    //   name:'sir',
                                    //   data:[1,2,3,4,5,6,7,8,9],
                                    //   color:'red',
                                    // },
                                  ],

                                  // [
                                  //   {
                                  //     inflationGraphData.details[0].name
                                  //     inflationGraphData.details[0].color
                                  //     inflationGraphData.details[0].data.slice(5)
                                  //   },

                                  // ],
                                  // inflationGraphData.details.slice(5),
                                }}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    <div className={classes.nextQuestion}>
                      <Link
                        to="/"
                        onClick={() =>
                          handleQuestionScroll(questionMainAns[8].scroll)
                        }>
                        next question v
                      </Link>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                ref={(section) => {
                  questionMainAns[8].scroll = section;
                }}
                className={classes.questionBlock}>
                <div className={classes.question}>
                  <span className={classes.questionNum}>9</span>
                  <p className={classes.questionTitle}>
                    Mike thinks that technology companies are great investments.
                    He wants to invest in a basket of technology companies
                    rather than trying to pick individual stocks on his own. He
                    wants an investment with low fees that can be traded daily
                    because liquidity is important to him. What investment would
                    you suggest to Mike?
                  </p>
                  <ul className={classes.optionList}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}>
                      <RadioGroup
                        aria-label="gender"
                        className={classNames(classes.radioGroup)}
                        name="questionOne"
                        value={questions[8].answer}
                        onChange={(e) => handleAnswer(e, 8)}>
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[8].answer === '401(k)' &&
                              classes.ansWrong,
                          )}
                          value="401(k)"
                          control={<Radio />}
                          label="401(k)"
                          disabled={questions[8].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[8].answer !== '' && classes.ansRight,
                          )}
                          value="ETF"
                          control={
                            <Radio checked={questions[8].answer !== ''} />
                          }
                          label="ETF"
                          disabled={questions[8].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[8].answer === 'Mutual Fund' &&
                              classes.ansWrong,
                          )}
                          value="Mutual Fund"
                          control={<Radio />}
                          label="Mutual Fund"
                          disabled={questions[8].answer !== ''}
                        />
                        <FormControlLabel
                          className={classNames(
                            classes.cotrolLabel,
                            questions[8].answer === 'Hedge Fund' &&
                              classes.ansWrong,
                          )}
                          value="Hedge Fund"
                          control={<Radio />}
                          label="Hedge Fund"
                          disabled={questions[8].answer !== ''}
                        />
                      </RadioGroup>
                    </FormControl>
                  </ul>
                </div>
                {questions[8].answer !== '' ? (
                  <div className={classes.ansRow}>
                    <Typography
                      className={classNames(
                        classes.ansTitle,
                        questions[8].answer === questions[8].correctAns
                          ? classes.ansCurrect
                          : classes.answrong,
                      )}
                      variant="h2"
                      component="h2">
                      {questions[8].answer === questions[8].correctAns
                        ? 'Correct !'
                        : 'Incorrect.'}
                    </Typography>
                    <div className={classes.ansBox}>
                      <Typography
                        className={classes.boxTitle}
                        variant="h2"
                        component="h2">
                        Fundamental principles of investing
                      </Typography>
                      <p className={classes.boxPara}>
                        An ETF is just like a stock in that you can buy and sell
                        shares on a stock exchange on a day-to-day basis every
                        second of the day. An ETF tracks the price of a
                        collection of stocks, can be bought and sold like any
                        other stock, and usually has very low fees compared to
                        other funds such as mutual funds or hedge funds.
                      </p>
                      <p className={classes.boxPara}>
                        <span className={classes.spanColor}>
                          A technology exchange-traded fund (ETF) would allow
                          you to invest in a bunch of different tech companies
                          without having to pick individual stocks.
                        </span>
                      </p>
                      <p className={classes.boxPara}>
                        Investing requires fundamental financial skills and
                        knowledge before getting started. You have to understand
                        what investments are available to you, how each
                        investment works, the risk-to-reward profile, the
                        appropriate timeline, and your purpose for each
                        investment.
                      </p>
                      <p className={classes.boxPara}>Ready to get started?</p>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div className={classes.progressBar} id="progress-bar">
                <p>{progress}% complete</p>
                <div>
                  <span style={{ width: `${progress}%` }} />
                </div>
              </div>
              <div className={classes.whatsMyScore} id="my-score-button">
                <Button onClick={handleMyScore}>What&apos;s My Score?</Button>
              </div>
            </div>
          </div>
        </div>
        {myScoreContent && (
          <div
            ref={(section) => {
              myScoreSection = section;
            }}
            className={classes.awernessScoreSec}>
            <Typography
              className={classes.awernessTitle}
              variant="h2"
              component="h2">
              WHAT&apos;S MY AWARENESS SCORE?
            </Typography>
            <div className={classes.container}>
              <div className={classes.awernessBlock}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.awernessBlockLeft}>
                      <Typography variant="h3" component="h3">
                        YOUR SCORE:
                      </Typography>
                      <span>
                        {totalScore.toFixed(2)}%<span>Correct</span>
                      </span>
                      <img src={financeExplained} alt="Graph" />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <div className={classes.awernessBlockRight}>
                      <div className={classes.moneyfreeRow}>
                        <Typography variant="h3" component="h3">
                          Get Smarter About Your Money for Free
                        </Typography>
                        <p>
                          Get our FREE weekly series, Finance Explained by
                          Inkwiry, delivered to your inbox every Thursday. Each
                          week, Inkwiry explores a financial concept, phrase,
                          term or computation and demystifies the language of
                          finance so you can be smarter about your money.
                        </p>
                        <Typography variant="h4" component="h4">
                          What’s your best contact information so we can send
                          you Finance Explained?
                        </Typography>
                      </div>
                      <form
                        className={classes.awernessForm}
                        noValidate
                        autoComplete="off">
                        <div className={classes.formInput}>
                          <TextField
                            id="outlined-basic"
                            placeholder="First Name*"
                            variant="outlined"
                            className={errorInputName && classes.errorInput}
                            value={firstName}
                            onChange={handleFirstName}
                          />
                        </div>
                        <div className={classes.formInput}>
                          <TextField
                            id="outlined-basic"
                            placeholder="Email Address*"
                            variant="outlined"
                            className={errorInputEmail && classes.errorInput}
                            value={email}
                            onChange={handleEmail}
                          />
                        </div>
                        <div className={classes.formBtn}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}>
                            I WANT TO BE SMARTER ABOUT MY MONEY
                            &nbsp;&nbsp;&nbsp;
                            <i className="las la-greater-than" />
                          </Button>
                        </div>
                      </form>

                      <p className={classes.bottomText}>
                        We respect your privacy and will never share your email
                        address. You can unsubscribe at any time.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={myScorePopup}
        onClose={handleMyScoreClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.pendinglistPopup}>
        <DialogContent>
          <div className={classes.pendingNumList}>
            <p>Please answer all questions to access your score.</p>
            <p>Questions remaining: {unselectedQuestions}</p>
          </div>
          <div />
        </DialogContent>
        <DialogActions className={classes.buttonOk}>
          <Button onClick={handleMyScoreClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <ScrollToTop />
    </Typography>
  );
};

Challenges.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const enhance = compose(withStyles(styles), withRouter);

export default enhance(Challenges);
