const styles = ( theme ) => ( {
  container: {
    maxWidth: '1170px',
    padding: '0 15px',
    margin: '0px auto',
  },
  loginBg: {
    background: '#e9eff3',
    padding: '0px 0',
    height: '100%',
    minHeight: 'calc(100vh - 190px)',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down( 'sm' )]: {
      width: 'calc(100% - 30px)',
      padding: '20px 15px',
      '& > div':{
        width: '100%',
      }
    },
    '& > div':{
      width: '100%',
    }
  },
  loginForm: {
    // maxWidth: '33.33%',
    width: 'calc(33.33% - 30px)',
    margin: '0 auto 0',
    // minWidth: '417px',
    border: 'none',
    borderRadius: '.45rem',
    boxShadow: ' 0 2px 2px rgba(0,0,0,.25)',
    // minHeight: '250px',
    background: theme.palette.common.white,
    marginTop: '6%',
    '& h3': {
      fontSize: '20px',
      marginTop: '0',
      marginBottom: '0px',
      color: theme.palette.common.blue,
      lineHeight: '1.1',
    },
    [theme.breakpoints.down( 'sm' )]: {
      maxWidth: '400px',
      minWidth: 'initial',
      width: '100%',
    },
  },
  loginFormHeader: {
    background: theme.palette.common.blue,
    textAlign: 'center',
    padding: '20px 0 16px',
    '& img': {
      maxWidth: '140px',
    },
  },
  loginFields: {
    padding: '20px 20px 5px',
  },
  formGroup: {
    width: '100%',
    marginTop: '15px',
    '& > div': {
      width: '100%',
      margin: '0',
    },
    '& label': {
      display: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
    '& input': {
      width: '100%',
      padding: '8px 15px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      '&:focus': {
        boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
        borderColor: '#66afe9',
      },
      '&::placeholder': {
        opacity: '0.8',
      },

    },
    '& button': {
      background: '#f89b23',
      border: 'none',
      borderBottom: '2px solid #cb7002',
      color: '#fff',
      lineHeight: 'normal',
      margin: '0 auto 10px auto',
      paddingBottom: '7px',
      paddingTop: '7px',
      display: 'inherit',
      borderRadius: '0',
      textTransform: 'uppercase',
      fontSize: '14px',
      fontFamily: 'MuseoSans-500',
      // minWidth: '84px',
      paddingLeft: '12px',
      paddingRight: '12px',
      boxShadow: 'none',
      '&:hover': {
        background: '#e78c1e',
        color: theme.palette.common.white,
        boxShadow: 'none',
      },
    },
  },
  forgotPass: {
    textAlign: 'center',
    display: 'inline-block',
    fontWeight: '400',
    color: ' #337ab7',
    borderRadius: '0',
    fontSize: '16px',
    marginBottom: '15px',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: '#23527c',
      textDecoration: 'underline',
    },
  },
  already: {
    textAlign: 'center',
    fontSize: '16px',
    color: '#000',
    '& a': {
      fontWeight: '400',
      color: ' #337ab7',
      borderRadius: '0',
      marginBottom: '15px',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        color: '#23527c',
        textDecoration: 'underline',
      },
    },
  },
  resetPassFields: {
    display: 'flex',
    '& > div:first-child': {
      width: '55%',
    },
    '& > div:last-child': {
      width: '45%',
    },
    '& ul': {
      listStyle: 'none',
      paddingLeft: '30px',
      '& li': {
        fontSize: '13px',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '10px',
        '& img': {
          width: '12px',
          verticalAlign: 'middle',
          marginRight: '10px',
        },
      },
    },
  },
  resetPass: {
    maxWidth: '580px',
  },
  textDanger: {
    color: '#bf0000',
    float: 'left',
    fontSize: '13.5px',
    marginTop: '15px',
    marginBottom: '10px',
    width: '100%',
  },
  textSuccess: {
    color: 'green',
    float: 'left',
    fontSize: '13.5px',
    marginTop: '15px',
    marginBottom: '10px',
    width: '100%',
  },
  forgotPassNew: {
    display: 'block',
  },
  errorMain: {
    color: '#bf0000',
    fontSize: '13.5px',
  },
  resetPassword: {
    background: '#fff',
  },
  resetPasswordBtn: {
    marginTop: '25px',
    '& button:disabled': {
      color: '#fff',
      borderBottom: '2px solid #bab8b8',
    },
  },
  addGroup: {
    // overflow: 'auto',
    '& > div + div + div': {
      height: 'auto',
      minHeight: '100%',
      maxHeight: 'calc(100vh - 50px)',
    },
    '& > div > div': {
      minWidth: '600px',
      width: '600px',
      maxHeight: 'calc(100vh - 50px)',
      [theme.breakpoints.down( 'xs' )]: {
        maxWidth: '100%',
        width: '100%',
      },
      '@media (max-width: 767px)': {
        margin: '15px',
        minWidth: 'inherit',
      }
    },
  },
  popupHeader: {
    padding: '15px',
    borderBottom: '1px solid #e5e5e5',
    '& h3': {
      fontSize: '24px',
      color: '#0069aa',
      margin: '0',
      float: 'left',
      fontWeight: '500',
    },
    '& i': {
      opacity: '0.3',
      float: 'right',
      cursor: 'pointer',
      '&:hover': {
        opacity: '0.8',
      },
    },
  },
  successText: {
    padding: '15px',
    '& p':{
      margin: '0px',
      color: 'green',

    }
  },
  popupFooter: {
    padding: '15px',
    textAlign: 'right',
    borderTop: ' 1px solid #e5e5e5',
    '& p': {
      margin: '0',
      maxWidth: '640px',
      fontSize: '16px',
      lineHeight: '26px',
    },
    '& button': {
      margin: '0 0 0 auto',
      background: theme.palette.common.white,
      border: '2px solid #ccc',
      padding: '6px 12px',
      fontSize: '14px',
      cursor: 'pointer',
      lineHeight: '1.4',
      textTransform: 'capitalize',
      borderRadius: '4px',
      color: theme.palette.common.black,
      outline: 'none',
      '&:hover': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:focus': {
        backgroundColor: '#d4d4d4',
        borderColor: '#8c8c8c',
      },
      '&:first-child': {
        color: theme.palette.common.white,
        backgroundColor: '#337ab7',
        border: '1px solid transparent',
        marginRight: '5px',
        '&:hover': {
          backgroundColor: '#204d74',
          border: '1px solid transparent',
        },
      },
    },
    [theme.breakpoints.down( 'xs' )]: {
      display: 'block',
      '& button': {
        margin: '15px auto',
      },
    },
  },
  resetText:{
    padding: '15px',
    '& p':{
      margin: '0px',
    }
  }
} );

export default styles;
