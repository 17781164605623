import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from '../../../screens/pwi/further-higher-education/styles';

const PrivateStudentLoans = ( props ) => {
  const {
    classes, handleDefinationPopup,
  } = props;

  return (
    <div className={ `${classes.contentBlock} ${classes.forStufentLonas}` }>
      <p>Private student loans are almost always the most expensive type of student loan. What does that mean? They usually have the highest interest rate when compared to federal student loans, which makes them more expensive to repay.</p>
      <p className={ classes.headStudentLoan }><span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Private Student Loans' ) }>Private Student Loans</span></p>
      <div>
        <ul className={ classes.privateLoans }>
          <li>Should only be taken after all other funding sources are used</li>
          <li>
            Higher borrowing limits; usually can borrow up to 100% of your total cost of attendance

          </li>
          <li>
            Issued by a bank or other financial institution

          </li>
          <li>
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Term of the Loan: Student Loan' ) }>Term of the loan</span>
           &nbsp;is typically 10 years (120 months)
          </li>
          <li>
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Interest Rate' ) }>Interest </span>
            &nbsp;will&nbsp;
            <span aria-hidden="true" data-for="definationTitle" data-tip="Click for a definition." onClick={ () => handleDefinationPopup( 'Accrued Interest' ) }>accrue</span>
            &nbsp;on this loan while you are in school and in grace period
          </li>
          <li>Example: if you borrow $30,000 in 6.5% private loans over four years and make no payments during school, your balance will be roughly $36,500 when the grace period ends and repayment begins</li>
        </ul>
      </div>
      <ReactTooltip id="definationTitle" place="top" type="info" effect="solid" className={ `${classes.tooltipInfoTop} ${classes.tooltipInfoTopSmall}` } />
    </div>

  );
};

PrivateStudentLoans.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDefinationPopup: PropTypes.func.isRequired,
};

export default withStyles( styles )( PrivateStudentLoans );
